import sjcl from 'sjcl';

import { defaultSecretPhraseWord, encryptionOptions } from './config';

//
// Hook: Encryption
//
// Allows data black-encryption with pre-defined settings.
//
// @param {String} secretPhraseWord The phraseword to use when encrypting the data.
const useEncryption = (secretPhraseWord = defaultSecretPhraseWord) => {
  function encrypt(clearText) {
    return sjcl.encrypt(secretPhraseWord, clearText, encryptionOptions);
  }

  function decrypt(cipherText) {
    return sjcl.decrypt(secretPhraseWord, cipherText);
  }

  return {
    decrypt,
    encrypt,
  };
};

export default useEncryption;
