import { UserAgentUtils } from 'esp-globals';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import compatibilityCheckActions from './compatibilityCheckActions';
import compatibilityStatus from '../globals/compatibilityStatus';
import compatibilityTypes from '../globals/compatibilityTypes';

// this is a sigleton, this means this thunk can be called multiple times but it will only run one time
let singleton = null;

const getType = () => {
  if (UserAgentUtils.isIOSApp()) {
    return compatibilityTypes.FE_IOS;
  }

  if (UserAgentUtils.isAndroidApp()) {
    return compatibilityTypes.FE_ANDROID;
  }

  if (UserAgentUtils.isMacElectron()) {
    return compatibilityTypes.FE_DESKTOP_OSX;
  }

  if (UserAgentUtils.isWinElectron()) {
    return compatibilityTypes.FE_DESKTOP_WIN;
  }

  return null;
};

const checkCompatibility = ({ dispatch, getState }) =>
  new Promise((resolve, reject) => {
    const type = getType();
    const isElectronOrCordova =
      UserAgentUtils.isCordova() || UserAgentUtils.isElectron();
    if (!isElectronOrCordova) {
      resolve(); // nothing to do here, this only runs for cordova and electron
    }
    if (!type) {
      // if you saw this then we might need to improve user agent detection <3
      reject(new Error('agent type not found'));
    }
    const appVersion = getState().get('appVersion');
    const clientID = appVersion.get('clientId');
    const version = clientID || compatibilityStatus.UNKNOWN;

    // we don't know what version are we running
    if (version === compatibilityStatus.UNKNOWN) {
      return reject(new Error('no client version found'));
    }

    if (version === compatibilityStatus.LOCAL) {
      return resolve();
    }

    dispatch(compatibilityCheckActions.compatibilityCheckStart());
    return APIcall.get({
      error: (err) => {
        dispatch(compatibilityCheckActions.compatibilityCheckError(err));
        reject();
      },
      query: {
        type,
        version,
      },
      success: ({ body: { compatible, clients, backend } }) => {
        dispatch(
          compatibilityCheckActions.compatibilityCheckSuccess({
            backend,
            clients,
            compatible,
          })
        );
        // I need to disable this rule for this file because it's a recursive thunk that runs every hour
        setTimeout(
          () => dispatch(compatibilityCheckThunks.checkCompatibility(true)),
          360000
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('commons.version'),
    });
  });

const compatibilityCheckThunks = {
  checkCompatibility: (forceReload = false) => (dispatch, getState) => {
    if (!singleton || forceReload) {
      singleton = checkCompatibility({
        dispatch,
        getState,
      });
    }
    return singleton;
  },
};

export default compatibilityCheckThunks;
