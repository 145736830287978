import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';

// atoms
import AdminProductSpecsForm from '../atoms/AdminProductSpecsForm';

// controllers
import AdminProductSpecsController from '../controllers/AdminProductSpecsController';

class AdminProductSpecs extends PureComponent {
  static propTypes = {
    context: PropTypes.string,
    formValues: ImmutablePropTypes.map,
    isDirty: PropTypes.bool,
    isValid: PropTypes.bool,
    product: ImmutablePropTypes.mapContains({
      product_family: PropTypes.number,
      specifications: PropTypes.string,
    }),
    saveForm: PropTypes.func,
  };

  static defaultProps = {
    context: 'catalog',
    formValues: immutable.Map(),
    isDirty: false,
    isValid: false,
    product: immutable.Map(),
    saveForm: _.noop,
  };

  onSave = () => {
    const { saveForm, isDirty, isValid, formValues, product } = this.props;
    saveForm({
      formValues,
      isDirty,
      isValid,
      product,
    });
  };

  render() {
    const { product, context } = this.props;

    if (!product || product.isEmpty()) {
      return null;
    }

    return (
      <Segment attached='bottom'>
        <AdminProductSpecsForm
          context={context}
          enableReinitialize
          handleSave={this.onSave}
          initialValues={{
            specifications: product.get('specifications'),
          }}
          product={product}
        />
      </Segment>
    );
  }
}
const AdminProductSpecsTest = AdminProductSpecs;

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminProductSpecs = AdminProductSpecsController(AdminProductSpecs);
export { AdminProductSpecsTest };
export default AdminProductSpecs;
