import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addSingleTask = (tasks, newTask) => {
  const taskId = newTask.get('id');
  const isNotNew = tasks.has(taskId);

  if (isNotNew) {
    /**
     * mergeDeepIn() Merges two or more nested Maps together at a specific point in a nested Map.
     */
    return tasks.mergeDeepIn([taskId], newTask);
  }

  tasks = tasks.set(taskId, newTask);

  return tasks;
};

const addTasks = (tasks, newTasks) => {
  newTasks.forEach((newTask) => {
    tasks = addSingleTask(tasks, newTask);
  });

  return tasks;
};

const removeSingleTask = (tasks, taskId) => tasks.remove(taskId);

const pushNoteToTask = (tasks, taskId, currentNote) => {
  return tasks.update(taskId, (item) => {
    const isNoteAlreadyPresent = item
      .get('task_notes')
      .find((note) => note.get('id') === currentNote.get('id'));
    if (isNoteAlreadyPresent) {
      // returns the same data
      return item;
    }
    // add new item
    return item.set('task_notes', item.get('task_notes').push(currentNote));
  });
};

const tasksReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.RESET_TASKS: {
      return (state = INITIAL_STATE);
    }
    case actionTypes.CASE_FEED_SUCCESS: {
      // if (action.reset) { // when reset, we clear all the tasks and populate with whatever is new
      //   const newCasesEntity = fromJS({});
      //   return addTasks(newCasesEntity, fromJS(action.tasks));
      // }
      return addTasks(state, fromJS(action.tasks));
    }
    case actionTypes.APPROVAL_FEED_SUCCESS: {
      // if (action.reset) { // when reset, we clear all the tasks and populate with whatever is new
      //   const newCasesEntity = fromJS({});
      //   return addTasks(newCasesEntity, fromJS(action.tasks));
      // }
      return addTasks(state, fromJS(action.tasks));
    }

    // This ain't no typo, this reducer is meant for these three scenarios
    case actionTypes.CASE_TASK_SUCCESS:
    case actionTypes.CASE_TASK_UPDATE: {
      let newTask = fromJS(action.task);

      if (!newTask.has('external_task_link') && action.card) {
        newTask = newTask.set(
          'external_task_link',
          action.card?.card_meta?.external_task_link ?? null
        );
      }

      // Preserves task_conditions when the task gets updated
      if (
        state.hasIn([newTask.get('id'), 'task_conditions']) &&
        !newTask.has('task_conditions')
      ) {
        newTask = newTask.set(
          'task_conditions',
          state.getIn([newTask.get('id'), 'task_conditions'])
        );
      }

      return state.set(newTask.get('id'), newTask);
    }
    case actionTypes.CREATE_CASE_SUCCESS: {
      return addSingleTask(state, fromJS(action.task));
    }

    // When you post a note, the API just returns the note, so we have to append it to the case
    case actionTypes.POST_CASE_NOTE_SUCCESS: {
      return pushNoteToTask(state, action.taskId, fromJS(action.note));
    }

    case actionTypes.DELETE_CASE: {
      return removeSingleTask(state, action.taskId);
    }

    default: {
      return state;
    }
  }
};

export default tasksReducer;
