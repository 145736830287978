import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsGym extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M48 9c21.54 0 39 17.46 39 39S69.54 87 48 87 9 69.54 9 48 26.46 9 48 9zm0-5C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4z' />
          <path d='M70.92 60h-2.09c-1.105 0-2 .895-2 2v3H54l2-34 11.17 11.13c.775.75 2.005.75 2.78 0 .75-.775.75-2.005 0-2.78L56.8 26.2c-.76-.33-3.74-.52-7.08-.57v-1.12c2.444-.944 3.868-3.496 3.388-6.072-.48-2.575-2.728-4.443-5.348-4.443-2.62 0-4.868 1.868-5.348 4.443-.48 2.576.944 5.128 3.388 6.072v1.11c-3.34.05-6.32.24-7.08.57L25.58 39.35c-.75.775-.75 2.005 0 2.78.775.75 2.005.75 2.78 0L40 31l1.57 34H28.92v-3c0-1.105-.895-2-2-2h-2.09c-1.105 0-2 .895-2 2v8.33c0 1.105.895 2 2 2h2.08c1.105 0 2-.895 2-2v-3.2h12.78l.2 3.77v.06c0 1.364 1.106 2.47 2.47 2.47s2.47-1.106 2.47-2.47v-3.83h1.93v3.84c-.06.918.398 1.793 1.185 2.27.787.476 1.773.476 2.56 0 .787-.477 1.244-1.352 1.185-2.27v-.06l.25-3.77h12.89v3.21c0 1.105.895 2 2 2h2.08c1.105 0 2-.895 2-2V62c0-1.1-.89-1.994-1.99-2zM46.8 65V51.44c-.11-.407.046-.84.39-1.08.345-.243.805-.243 1.15 0 .344.24.5.673.39 1.08V65H46.8z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsGym;
