import APIcall from '../utils/APIcall';
import bootstrapActions from './bootstrapActions';
import endpointGenerator from '../utils/endpointGenerator';
const bootstrapThunks = {};

bootstrapThunks.appBootstrap = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espBootstrap.start');

    dispatch(bootstrapActions.appBootstrapStart());

    APIcall.get({
      error(e) {
        const errorMsg = 'Unable to boostrap application.';
        dispatch(bootstrapActions.appBootstrapFail(errorMsg));
        reject(e);
      },
      success(response) {
        const { body } = response;

        const { user_settings, current_user, ...rest } = body;

        /**
         * DON'T ADD ADDITIONAL THUNKS HERE
         * Use the appropiate helloActions to add whatever you want to happen
         * when bootstrap is done
         */
        // Note that the current_user we get from bootstrap is very minimal
        dispatch(
          bootstrapActions.appBootstrapSuccess(
            rest,
            current_user,
            user_settings
          )
        );
        resolve({ me: current_user, userSettings: user_settings });
      },
      token: true,
      url,
    });
  });

export default bootstrapThunks;
