import { Button, Form, Message } from 'semantic-ui-react';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import ValidatedField from '../molecules/ValidatedField';

const validate = (values) => {
  const errors = {};

  const recoveryCode = values.get('recoveryCode').trim();

  if (_.isEmpty(recoveryCode)) {
    errors.recoveryCode = 'Required';
  }

  return errors;
};

class ResetPasswordValidateCodeForm extends PureComponent {
  static propTypes = {
    errorMsg: PropTypes.string,
    fromLink: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    errorMsg: null,
    fromLink: false,
    handleSubmit: _.noop,
    isLoading: false,
  };

  render() {
    const { errorMsg, fromLink, handleSubmit, isLoading } = this.props;

    return (
      <Form error={Boolean(errorMsg)} onSubmit={handleSubmit}>
        <Message info size='tiny'>
          {fromLink
            ? 'Enter the verification code.'
            : 'Verification code has been sent. You should receive it within 5 minutes.'}
        </Message>

        <ValidatedField
          fluid
          forceFocus
          name='recoveryCode'
          placeholder='Verification Code'
          required
        />

        {errorMsg && (
          <Message error size='tiny'>
            {errorMsg}
          </Message>
        )}

        <Form.Field>
          <Button
            disabled={isLoading}
            fluid
            loading={isLoading}
            primary
            type='submit'
          >
            {'Submit'}
          </Button>
        </Form.Field>
      </Form>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
ResetPasswordValidateCodeForm = reduxForm({
  initialValues: Immutable.Map({
    recoveryCode: '',
  }),

  validate,
})(ResetPasswordValidateCodeForm);

export default ResetPasswordValidateCodeForm;
