import { getMyTeams } from '../../../../v1/selectors/getSelectedServiceTeam';
import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import csvCasesReportThunks from '../../../../v1/actions/csvCasesReportThunks';
import csvCasesReportActions from '../../../../v1/actions/csvCasesReportActions';

const mapStateToProps = (state) => ({
  downloadURL: state.getIn(['csvCasesReport', 'downloadURL']),
  isLoading: state.getIn(['csvCasesReport', 'isLoading']),
  message: state.getIn(['csvCasesReport', 'message']),
  myTeams: getMyTeams(state),
});

const mapDispatchToProps = (dispatch) => ({
  downloadReport(formValues) {
    dispatch(csvCasesReportThunks.downloadCsvCasesReport(formValues));
  },
  resetDownloadURL() {
    dispatch(csvCasesReportActions.resetDownloadURL());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
