import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string,
  isEven: PropTypes.bool,
};

const NBubble = ({ isEven, color }) => {
  const nBubbleStyle = {
    borderBottom: `2em solid ${color}`,
    borderLeft: '0.5em solid transparent',
    borderRight: '0.5em solid transparent',
    display: 'block',
    height: 0,
    position: 'absolute',
    top: '-1.25em',
    width: 0,
  };

  const evenStyle = {
    left: '1em',
    transform: 'rotate(45deg)',
    transformOrigin: 'bottom right',
  };

  const oddStyle = {
    right: '1em',
    transform: 'rotate(-45deg)',
    transformOrigin: 'bottom left',
  };

  return (
    <div
      style={{
        ...nBubbleStyle,
        ...(isEven ? oddStyle : evenStyle),
      }}
    />
  );
};

NBubble.propTypes = propTypes;

export default NBubble;
