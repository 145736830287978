import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isDirty, isPristine } from 'redux-form/immutable';

import taskActions from '../../actions/taskActions';
import taskThunks from '../../actions/taskThunks';

const mapStateToProps = (state, ownProps) => {
  // own props variables
  const workflowTaskID = Number(ownProps.params.workflowTaskID);
  const workflowID = Number(ownProps.params.workflowID);

  const editor = state.get('editor');

  return {
    conditionIsDirty: isDirty('ConditionForm')(state),
    conditionIsPristine: isPristine('ConditionForm')(state),
    currentEditingTask: editor.get('currentEditingTask'),
    saveStatus: {
      taskFormHasErrors: editor.getIn(['saveStatus', 'taskFormHasErrors']),
      taskFormIsChanged: editor.getIn(['saveStatus', 'taskFormIsChanged']),
      taskFormSaveInProgress: editor.getIn([
        'saveStatus',
        'taskFormSaveInProgress',
      ]),
    },
    showPreview: editor.getIn(['tasks', 'showPreview']),
    tasks: editor.get('tasks'),
    workflowID: workflowID,
    workflowTaskID: workflowTaskID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addBlock: (taskId, blockType, multiInputMapping) => {
    dispatch(taskActions.addBlock(taskId, blockType, multiInputMapping));
  },

  addOptionToBlockAttribute: (blockId, blockAttrName, newOption) => {
    dispatch(
      taskActions.addOptionToBlockAttribute(blockId, blockAttrName, newOption)
    );
  },

  createFinalCondition: (taskID) => {
    dispatch(taskThunks.createFinalCondition(taskID));
  },

  getTask: (workflowId, taskId) => {
    // TO-DO we might need to change this to load only 1 task at a time
    dispatch(taskThunks.getTasks(workflowId, taskId));
  },

  rearrangeBlocks: (ordering, taskId) => {
    dispatch(taskActions.rearrangeBlocks(ordering, taskId));
  },

  removeAttribute: (attributeName, attributeObject) => {
    dispatch(taskThunks.removeAttributeToTask(attributeName, attributeObject));
  },

  removeBlock: (taskId, blockIndex) => {
    dispatch(taskActions.removeBlock(taskId, blockIndex));
  },

  removeOptionFromBlockAttribute: (blockId, blockAttrName, optionIndex) => {
    dispatch(
      taskActions.removeOptionFromBlockAttribute(
        blockId,
        blockAttrName,
        optionIndex
      )
    );
  },

  saveConditions: (taskID) => {
    dispatch(taskThunks.saveConditions(taskID));
  },

  saveTask: (taskID) => {
    dispatch(taskThunks.saveTask(taskID));
  },

  selectCurrentEditingTask: (taskId) => {
    dispatch(taskThunks.loadConditionSet(taskId)).then(
      dispatch(taskActions.selectCurrentEditingTask(taskId))
    );
  },

  togglePreview: () => {
    dispatch(taskActions.togglePreview());
  },
});

const WorkflowTaskEditorPageController = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(component));
export default WorkflowTaskEditorPageController;
