import { getFormValues } from 'redux-form/immutable';
import { isBoolean } from 'lodash';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';
// Globals
import tenantMenu from '../../globals/tenantMenu';
// Selector
import getTenantMenuConfiguration from '../../selectors/getTenantMenuConfiguration';
// Thunks
import tenantThunks from '../../actions/tenantThunks';

const mapStateToProps = (state) => {
  const tenantState = state.get('tenant');

  const configurationTenantMenu = getTenantMenuConfiguration(state);
  const isLoading = tenantState.getIn(['updatetenant', 'isLoading']);

  const equipmentValueAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.my_equipment)
  );
  const fullEditProfilValueAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.full_edit_profile)
  );
  const publicEmployeeNbrValueAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.publicEmployeeNumber)
  );
  const requestsValueAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.my_requests)
  );
  const storeValueAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.store)
  );
  const casesMainMenuAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.casesMainMenu)
  );
  const approvalsMainMenuAlreadyExist = isBoolean(
    configurationTenantMenu.get(tenantMenu.approvalsMainMenu)
  );

  // These 3 settings must be turned on simultaneosly
  // to be considered for "Turn off" Contacts
  const isDisableContacts = // pf of these must be true
    configurationTenantMenu.get('disableDirectory_hideContacts') &&
    configurationTenantMenu.get('disableDirectory_hideMyMessages') &&
    configurationTenantMenu.get('disableDirectory_disableLinksToProfiles');
  const initialValues = {
    [tenantMenu.full_edit_profile]: fullEditProfilValueAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.full_edit_profile)
      : true,
    [tenantMenu.my_equipment]: equipmentValueAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.my_equipment)
      : true,
    [tenantMenu.my_requests]: requestsValueAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.my_requests)
      : true,
    [tenantMenu.publicEmployeeNumber]: publicEmployeeNbrValueAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.publicEmployeeNumber)
      : false,
    [tenantMenu.store]: storeValueAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.store)
      : true,
    [tenantMenu.casesMainMenu]: casesMainMenuAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.casesMainMenu)
      : false,
    [tenantMenu.approvalsMainMenu]: approvalsMainMenuAlreadyExist
      ? configurationTenantMenu.get(tenantMenu.approvalsMainMenu)
      : false,
    disable_contacts: isDisableContacts,
  };

  return {
    approvalsMainMenuAlreadyExist,
    casesMainMenuAlreadyExist,
    equipmentValueAlreadyExist,
    formValues: getFormValues('TenantMenuConfiguration')(state),
    fullEditProfilValueAlreadyExist,
    initialValues,
    isLoading,
    publicEmployeeNbrValueAlreadyExist,
    requestsValueAlreadyExist,
    storeValueAlreadyExist,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTenantMenuConfiguration(valuesArray) {
    dispatch(tenantThunks.setTenantMenuConfiguration(valuesArray));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
