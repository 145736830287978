const { hostname } = window.location;

const labels = {
  BASIC_SSO: 'Basic SSO',
  BASIC_SSO_TITLE: 'Setup Basic SSO',
  CANCEL: 'Cancel',
  CHECKBOX_DESCRIPTION:
    'Allow the Virtual Agent to remotely reset the password for Employees as a self-service. Requires additional setup.',
  CLIENT_ID: 'Client ID',
  CLIENT_SECRET: 'Client Secret',
  DELETE_BODY:
    'Removing SSO configuration will disable your users ability to log in using their SSO credentials. This will also remove all of your current configuration settings.',
  DELETE_TITLE: 'Remove SSO',
  DISABLED: 'DISABLED',
  DISABLE_SSO_BODY: 'Would you like to disable SSO now?',
  EDIT: 'Edit',
  ENABLED: 'ENABLED',
  ENABLE_EMPLOYEE_PASSWORD_RESET: 'Enable employee password reset',
  ENABLE_SSO: 'ENABLE SSO',
  ENABLE_SSO_BODY: 'Would you like to enable SSO now?',
  IDENTIFIER: 'Identifier',
  LOG_OUT_URL: 'Log out URL',
  NAME: 'Name',
  NO: 'No',
  REMOVE: 'Remove',
  REPLY_URL: 'Reply URL',
  SAVE: 'Save',
  SIGN_ON_URL: 'Sign on URL',
  SSO_WITH_SAML: 'SSO With SAML',
  SSO_WITH_SAML_MODAL_TITLE: 'Setup SSO with SAML',
  STATUS: 'STATUS',
  TENANT_ID: 'Tenant ID',
  TRIGGER_BUTTON_TITLE: 'Begin Setup',
  WELCOME_DESCRIPTION: 'Setup single sign-on by clicking the button below',
  YES: 'Yes',
};

const methods = {
  GET: 'GET',
  OPTIONS: 'OPTIONS',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'PUT',
};

const deleteType = {
  BASIC_SSO: 'BASIC_SSO',
  SSO_SAML: 'SSO_SAML',
};

const newHostname = hostname.replace('.espressive.com', '');

const replyURL = `https://${newHostname}.espressive.com/api/authentication/v0.1/saml/response/`;
const logoutURL = `https://${newHostname}.espressive.com/api/authentication/v0.1/saml/logout/`;
const identifierURL = `https://${newHostname}.saml.espressive.com/`;
const singOnURL = `https://${newHostname}.espressive.com/`;

const samlURLS = {
  hostname,
  identifierURL,
  logoutURL,
  replyURL,
  singOnURL,
};

export { labels, methods, deleteType, samlURLS };
