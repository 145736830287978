import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intl } from 'esp-util-intl';
import { Button, Checkbox, Form, Header, Select } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FieldArray } from 'redux-form/immutable';
import Immutable from 'immutable';
import { curry, isArray, isEmpty, map, memoize, noop } from 'lodash';
import moment from 'moment';
import SelectCategoryModal from '../../../v2/components/form/Announcement/SelectCategoryModal';
import ActionButtonInfo from '../../../v2/components/form/Announcement/ActionButtonInfo';

//  Component
import { Boundaries } from 'cascara-middleware';

// atoms
import ScrollArea from '../atoms/ScrollArea';
import EspMainPanel from '../atoms/EspMainPanel';
import EspMainPanelHeader from '../atoms/EspMainPanelHeader';
// molecules
import AnnouncementGroups from '../molecules/AnnouncementGroups';
import FormInputTextarea from '../molecules/FormInputTextarea';
import ValidatedField from '../molecules/ValidatedField';
import ValidatedForm from '../molecules/ValidatedForm';
// Controller
import AnnouncementManageController from '../controllers/AnnouncementManageController';
// Global
import AnnouncementState from '../../globals/AnnouncementState';
import AnnouncementCategories from '../..//globals/AnnouncementCategories';
import EspModal from '../../../v2/components/display/EspConfirmationModal/EspModal';
import BrowserPrompt, {
  OK_BUTTON_INDEX_NUMBER,
} from '../../globals/BrowserPrompt';
// Utils
import injectIntlToController from '../../utils/injectIntlToController';
import LocaleSingleton from '../../utils/LocaleSingleton';

import AnnouncementScheduling from './AnnouncementScheduling';
import AnnouncementSchedulingReadOnly from '../../../v2/components/display/AnnouncementSchedulingReadOnly';
import SurveyTypes, {
  isActionValid,
} from '../../../v2/components/form/Announcement/SurveyTypes';
import GenericAnnouncementContentSection from '../../../v2/components/form/Announcement/GenericAnnouncementContentSection';
import SurveyAnnouncementContentSection, {
  initialFormValuesForActions,
} from '../../../v2/components/form/Announcement/SurveyAnnouncementContentSection';
import SurveyInfo from '../../../v2/components/form/Announcement/SurveyInfo';
import {
  ACTION_TYPES,
  ANNOUNCEMENT_AUDIENCE_TYPES,
  ANNOUNCEMENT_TYPES,
} from '../../globals/announcementCustomActionConstants';
import getPublishMsg, {
  getResolveMsg,
} from '../../globals/announcementHelperMsg';
import FormInputText from '../molecules/FormInputText';
import AnnouncementTest from './AnnouncementTest';
import AnnouncementSection from './AnnouncementSection';
import SlackAudienceSection from './SlackAudienceSection';
import BodyClassName from '../../../globals/BodyClassName';
import DateTools from '../../utils/DateTools';

const generateISODateFormat = (date, time) =>
  date
    ? moment(
        `${date} ${time}`,
        `${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`
      ).toISOString()
    : void 0;

const formValidation = (values) => {
  const errors = {};

  if (!values.get('category')) {
    errors.category = 'category Error';
    // this message is not visible, we don't need translations for now
  }

  const subject = values.get('subject') || '';
  if (subject.trim().length === 0) {
    errors.subject = intl.formatMessage({
      id: 'label.subject_error',
    });
  }

  return errors;
};

const threeButtonStyle = {
  paddingLeft: '.5em',
  paddingRight: '.5em',
};

const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
const INPUT_TIME_FORMAT = 'HH:mm';
const BE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const customActionIndex = 0;

class AnnouncementManage extends PureComponent {
  static propTypes = {
    addNewGroup: PropTypes.func,
    announcement: ImmutablePropTypes.map,
    announcementId: PropTypes.string,
    changeFormFieldValue: PropTypes.func,
    createNewAnnouncement: PropTypes.func,
    deleteAnnouncement: PropTypes.func,
    formValues: ImmutablePropTypes.map,
    intentsList: ImmutablePropTypes.list,
    isAnnouncementSaving: PropTypes.bool,
    isDirty: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isLoadingIntent: PropTypes.bool,
    isUpdating: PropTypes.bool,
    loadAnnouncement: PropTypes.func,
    loadIntentByEid: PropTypes.func,
    loadSlackChannels: PropTypes.func.isRequired,
    recall: PropTypes.func,
    resetForm: PropTypes.func,
    saveAnnouncement: PropTypes.func,
    slackChannels: ImmutablePropTypes.map,
    totalRecipents: PropTypes.number,
    updateAnnouncement: PropTypes.func,
    userLanguage: PropTypes.string,
  };

  static defaultProps = {
    addNewGroup: noop,
    announcement: Immutable.Map(),
    announcementId: null,
    changeFormFieldValue: noop,
    createNewAnnouncement: noop,
    deleteAnnouncement: noop,
    formValues: Immutable.Map(),
    intentsList: Immutable.List(),
    isAnnouncementSaving: false,
    isDirty: false,
    isInvalid: false,
    isLoadingIntent: false,
    isUpdating: false,
    loadAnnouncement: noop,
    loadIntentByEid: noop,
    recall: noop,
    resetForm: noop,
    saveAnnouncement: noop,
    slackChannels: Immutable.Map(),
    totalRecipents: 0,
    updateAnnouncement: noop,
    userLanguage: '',
  };

  state = {
    audienceType: ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value,
    isActionEnabled: false,
    isETAEnabled: false,
    isExpireCardEnabled: false,
    isModalPublishNumber: false,
    isModalResolveNumber: false,
    isRecurringEnabled: false,
    isScheduleEnabled: false,
    isUpdating: false,
    numberOfTotalUsers: null,
    selectedType: ANNOUNCEMENT_TYPES.GENERAL.value,
    timeLimit: 0,
  };

  contentRef = React.createRef();

  componentDidMount() {
    const {
      announcementId,
      createNewAnnouncement,
      loadAnnouncement,
      loadSlackChannels,
      slackChannels,
    } = this.props;

    if (!slackChannels?.get('isSlackConfigured')) {
      loadSlackChannels().then(() => {
        this.initializeAudienceType();
      });
    } else {
      this.initializeAudienceType();
    }

    this.isScheduleFormInvalidInterval = setInterval(
      this.isScheduleFormInvalid,
      1000
    );

    // This need to be async in case of existing announcement
    // so i'm putting all together as one
    const startSetup = () => {
      this.initializeScheduleCheckboxes();
      this.initializeActionCheckbox();
      this.initializeETACheckbox();
      this.initializeSelectedAnnouncementType();
    };

    if (announcementId && announcementId !== 'new') {
      loadAnnouncement(announcementId).then(() => {
        startSetup();
      });
    }
    if (announcementId === 'new') {
      createNewAnnouncement();
      startSetup();
    }
  }

  componentDidUpdate(prevProps) {
    const { announcementId, formValues, changeFormFieldValue } = this.props;

    // Only do this if the category changes and they both were not empty
    if (
      prevProps.formValues.get('category') !== formValues.get('category') &&
      prevProps.formValues.get('category') &&
      formValues.get('category')
    ) {
      const category = formValues.get('category')
        ? formValues.get('category').toString()
        : '0';
      const categoryName = AnnouncementCategories.getIn([category, 'name']);

      changeFormFieldValue('categoryName', categoryName, announcementId);
    }
  }

  componentWillUnmount() {
    clearInterval(this.isScheduleFormInvalidInterval);
  }

  isScheduleFormInvalidInterval = null;

  initializeAudienceType = () => {
    const {
      slackChannels,
      changeFormFieldValue,
      announcementId,
      announcement,
    } = this.props;

    const isDraft =
      announcement.get('status') === AnnouncementState.STATUS_DRAFT;

    const channels = announcement.getIn(
      ['bot_channels_list', 'bot_channels_dict'],
      Immutable.List()
    );

    if (
      (slackChannels?.get('isSlackConfigured') && channels?.size > 0) ||
      (slackChannels?.get('isSlackConfigured') && isDraft)
    ) {
      if (channels?.size > 0) {
        const slackChannelsArray = channels.reduce((channels, workgroup) => {
          workgroup?.get('channels').forEach((channel) => {
            channels = channels.push(channel?.get('id'));
          });
          return channels;
        }, Immutable.List());

        if (slackChannelsArray.size > 0) {
          this.setState(
            {
              audienceType: ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.value,
            },
            () => {
              changeFormFieldValue(
                'slack_channels',
                slackChannelsArray,
                announcementId
              );
            }
          );
        }
      }
    }
  };

  initializeActionCheckbox = () => {
    const { announcement } = this.props;
    const isActionEnabled =
      announcement.get('action') && announcement.get('action').size > 0;

    this.setState({ isActionEnabled });
  };

  initializeETACheckbox = () => {
    const { announcement } = this.props;
    if (announcement.get('eta')) {
      this.setState({ isETAEnabled: true });
    }
  };

  initializeSelectedAnnouncementType = () => {
    const { formValues, announcement } = this.props;
    const selectedType =
      formValues.get('type') ||
      (announcement && announcement.get('type')) ||
      ANNOUNCEMENT_TYPES.GENERAL.value;
    this.setState({ selectedType });
  };

  initializeScheduleCheckboxes = () => {
    const { formValues, announcement } = this.props;
    const newState = {};
    if (
      formValues.getIn(['schedule', 'start_date']) ||
      announcement.getIn(['schedule', 'start_date'])
    ) {
      newState.isScheduleEnabled = true;
    }

    if (
      (formValues.getIn(['schedule', 'recurrence']) &&
        formValues.getIn(['schedule', 'recurrence']).size > 0) ||
      (announcement.getIn(['schedule', 'recurrence']) &&
        announcement.getIn(['schedule', 'recurrence']).size > 0)
    ) {
      newState.isRecurringEnabled = true;
    }
    this.setState({ ...newState });
  };

  onToggleScheduleCheckbox = (newState) => {
    this.setState(newState);
  };

  handleToggleETACheckbox = (e, { value }) => {
    const { announcementId } = this.props;
    const { isETAEnabled } = this.state;

    if (value) {
      this.handlerChangeFormFieldValue('eta_date', '', announcementId);
      this.handlerChangeFormFieldValue('eta_time', '', announcementId);
    }

    this.setState({
      isETAEnabled: !isETAEnabled,
    });
  };

  handleSaveFormConfirm = (newStatus) => {
    this.setState({
      isModalPublishNumber: false,
      isModalResolveNumber: false,
    });

    const {
      announcement,
      announcementId,
      changeFormFieldValue,
      saveAnnouncement,
      slackChannels,
    } = this.props;

    const currentStatus = announcement.get('status');

    saveAnnouncement(
      announcementId,
      currentStatus,
      newStatus,
      changeFormFieldValue,
      slackChannels?.get('channels').toJS()
    );
  };

  handleSaveForm = memoize(
    curry((newStatus, _ev) => {
      _ev.preventDefault();

      this.setState({
        isModalPublishNumber: false,
        isModalResolveNumber: false,
      });

      const {
        announcement,
        announcementId,
        changeFormFieldValue,
        saveAnnouncement,
        slackChannels,
      } = this.props;

      const currentStatus = announcement.get('status');

      saveAnnouncement(
        announcementId,
        currentStatus,
        newStatus,
        changeFormFieldValue,
        slackChannels?.get('channels').toJS()
      );
    })
  );

  handleUpdate = () => {
    this.setState({ isUpdating: true });
    this.contentRef.current.scrollIntoView();
  };

  handleCancelUpdate = () => {
    const { announcement, resetForm, formValues } = this.props;
    this.setState({
      isETAEnabled:
        formValues.getIn(['eta_date']) && formValues.getIn(['eta_time']),
      isUpdating: false,
    });
    resetForm(announcement.get('id'));
  };

  handleAnnouncementUpdate = () => {
    const {
      announcement,
      announcementId,
      formValues,
      updateAnnouncement,
      loadAnnouncement,
    } = this.props;

    const hasEta = Boolean(
      formValues.get('eta_date') && formValues.get('eta_time')
    );

    updateAnnouncement(announcementId, {
      body: formValues.get('body'),
      eta: hasEta
        ? generateISODateFormat(
            formValues.get('eta_date'),
            formValues.get('eta_time')
          )
        : null,
      has_eta: hasEta,
      subject: formValues.get('subject'),
    })
      .then(() => loadAnnouncement(announcement.get('id')))
      .finally(() => this.setState({ isUpdating: false }));
  };

  handlePublishConfirm = () => {
    this.setState({
      isModalPublishNumber: false,
    });
  };

  handleResolveConfirm = () => {
    this.setState({
      isModalResolveNumber: false,
    });
  };

  handleConfirmPublish = () => {
    this.handleSaveFormConfirm(AnnouncementState.STATUS_PUBLISHED);
  };

  handleConfirmResolve = (ev) => {
    this.handleSaveForm(AnnouncementState.STATUS_RESOLVED, ev);
  };

  handlePublish = (ev) => {
    const { announcement, formValues } = this.props;
    const { audienceType } = this.state;

    const isSlackAudienceTypeSelected =
      audienceType === ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.value;

    const askUserToConfirmPublishAction = () => {
      let realMembers = 0;
      let activeMembers = 0;
      const groups = formValues.get('groups');
      if (groups) {
        groups.forEach((group) => {
          activeMembers += group.get('user_count') || 0;
        });
      }

      if (groups) {
        groups.forEach((group) => {
          realMembers +=
            group.get('real_members') || group.get('user_count') || 0;
        });
      }

      let timeLimit = 0;
      const timeGroups = formValues.get('groups');
      if (timeGroups) {
        timeGroups.forEach((group) => {
          timeLimit += group.get('time_limit') || 0;
        });
      }

      this.setState({
        inactiveUsers: realMembers - activeMembers,
        isModalPublishNumber: true,
        numberOfTotalUsers: activeMembers,
        timeLimit: timeLimit || announcement.get('time_limit'),
      });
    };

    if (isSlackAudienceTypeSelected) {
      // we don't care about groups for slack channels
      this.handleSaveForm(AnnouncementState.STATUS_PUBLISHED, ev);
    } else {
      askUserToConfirmPublishAction();
    }
  };

  handleRecall = () => {
    const { announcementId, recall } = this.props;

    const confirmed = BrowserPrompt.confirm(
      intl.formatMessage({
        id: 'message.recall_msg',
      }),
      {
        buttons: [
          intl.formatMessage({
            id: 'label.cancel',
          }),
          intl.formatMessage({
            id: 'label.ok',
          }),
        ],
        callback: (action) => {
          // For Cordova
          if (action === OK_BUTTON_INDEX_NUMBER) {
            recall(announcementId);
          }
        },
        content: intl.formatMessage({
          id: 'message.recall_msg',
        }),
        title: intl.formatMessage({
          id: 'message.recall',
        }),
      }
    );

    // Dialogue was confirmed
    if (confirmed) {
      recall(announcementId);
    }
  };

  handleResolveAskFirst = (ev) => {
    const { announcement, formValues } = this.props;
    let realMembers = 0;
    let activeMembers = 0;
    const groups = formValues.get('groups');
    if (groups) {
      groups.forEach((group) => {
        activeMembers += group.get('user_count') || 0;
      });
    }

    if (groups) {
      groups.forEach((group) => {
        realMembers +=
          group.get('real_members') || group.get('user_count') || 0;
      });
    }

    let timeLimit = 0;
    const timeGroups = formValues.get('groups');
    if (timeGroups) {
      timeGroups.forEach((group) => {
        timeLimit += group.get('time_limit') || 0;
      });
    }

    this.setState({
      inactiveUsers: realMembers - activeMembers,
      isModalResolveNumber: true,
      numberOfTotalUsers: activeMembers,
      timeLimit: timeLimit || announcement.get('time_limit'),
    });
  };

  handleDelete = () => {
    const { announcementId, deleteAnnouncement } = this.props;

    deleteAnnouncement(announcementId);
  };

  handleSubmit = () => {
    // Do nothing
  };

  handlerChangeFormFieldValue = (fieldName, value, announcementId) => {
    const { changeFormFieldValue } = this.props;
    changeFormFieldValue(fieldName, value, announcementId);
  };

  handleAnnouncementTypeChange = (_, { value }) => {
    const { changeFormFieldValue, announcementId } = this.props;
    const keepAudienceType =
      announcementId === 'new' && value === ANNOUNCEMENT_TYPES.GENERAL.value
        ? ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value
        : null;

    this.setState(
      {
        audienceType: keepAudienceType,
        isActionEnabled: false,
        selectedType: value,
      },
      () => {
        changeFormFieldValue('type', value, announcementId);
      }
    );
  };

  handleActionCheckboxToggle = (newIsActionEnabled) => {
    const { changeFormFieldValue, announcementId } = this.props;

    this.setState({ isActionEnabled: newIsActionEnabled });
    // only reset when unchecking
    if (!newIsActionEnabled) {
      changeFormFieldValue('action.0', Immutable.fromJS({}), announcementId);
    }
  };

  isScheduleFormInvalid = () => {
    const { formValues } = this.props;

    const { isRecurringEnabled, isScheduleEnabled } = this.state;

    const startDate = formValues.getIn(['schedule', 'start_date']);
    const endDate = formValues.getIn(['schedule', 'end_date']);
    const repeatType = formValues.getIn(['schedule', 'repeat']);

    let isInvalid = false;
    if (isScheduleEnabled && !startDate) {
      isInvalid = true;
    }

    const isEveryFieldSetWhenRepeatTypeIsSet = () => {
      if (!formValues.getIn(['schedule', 'every'])) {
        isInvalid = true;
      }
    };

    const isWeekDaySelectedWhenWeeklyIsSelected = () => {
      if (
        repeatType === repeatTypes.WEEKLY &&
        formValues.getIn(['schedule', 'on_weekdays'])?.size === 0
      ) {
        isInvalid = true;
      }
    };

    const isEndDateSetWhenEndDateTypeIsSet = () => {
      if (
        formValues.getIn(['schedule', 'end_type']) === endTypes.ON &&
        !endDate
      ) {
        isInvalid = true;
      }
    };

    const isAutoExpireDaysSetWhenAfterIsSet = () => {
      if (
        formValues.getIn(['schedule', 'end_type']) === endTypes.AFTER &&
        !formValues.getIn(['schedule', 'auto_expire_days'])
      ) {
        isInvalid = true;
      }
    };

    const isDaySetWhenOnTypeDayIsSet = () => {
      if (
        (repeatType === repeatTypes.MONTHLY ||
          repeatType === repeatTypes.YEARLY) &&
        formValues.getIn(['schedule', 'on_type']) === onTypes.ON_DAY &&
        !formValues.getIn(['schedule', 'on_day'])
      ) {
        isInvalid = true;
      }
    };

    const isOrdinalDaySetWhenOnTypeOrdinalIsSet = () => {
      if (
        (repeatType === repeatTypes.MONTHLY ||
          repeatType === repeatTypes.YEARLY) &&
        formValues.getIn(['schedule', 'on_type']) === onTypes.ON_ORDINAL &&
        !formValues.getIn(['schedule', 'on_ordinal_number']) &&
        !formValues.getIn(['schedule', 'on_week_day'])
      ) {
        isInvalid = true;
      }
    };

    // if isRecurringEnabled is enabled we don't need to check for isScheduleEnabled
    if (isRecurringEnabled) {
      isEndDateSetWhenEndDateTypeIsSet();
      isEveryFieldSetWhenRepeatTypeIsSet();
      isWeekDaySelectedWhenWeeklyIsSelected();
      isAutoExpireDaysSetWhenAfterIsSet();
      isDaySetWhenOnTypeDayIsSet();
      isOrdinalDaySetWhenOnTypeOrdinalIsSet();
    }

    return isInvalid;
  };

  isCustomActionFormSectionValid = (action) => {
    const { isActionEnabled } = this.state;
    if (!isActionEnabled) {
      return true;
    }

    return !isEmpty(action.type) && isActionValid(action.type, action);
  };

  areCustomActionsInvalid = (formValues = Immutable.Map()) => {
    const { isActionEnabled } = this.state;

    switch (formValues.get('type')) {
      case ANNOUNCEMENT_TYPES.GENERAL.value:
        if (isActionEnabled) {
          const action = formValues.get('action');

          if (!action || action.isEmpty()) {
            return true;
          }

          const customAction = formValues.getIn(['action', customActionIndex]);

          if (
            customAction.get('type') === ACTION_TYPES.INTENT &&
            !customAction.get('intent_eid')
          ) {
            return true;
          }

          if (
            (customAction.get('type') !== ACTION_TYPES.INTENT &&
              !customAction.get('action_url')) ||
            !customAction.get('button_text')
          ) {
            return true;
          }
        }

        return false;
      case ANNOUNCEMENT_TYPES.SURVEY.value:
        if (formValues.get('actions')) {
          return !formValues
            .get('actions')
            .every((action) => action.get('isValid'));
        }
        return false;
      default:
        return false;
    }
  };

  handleSlackChannelSelection = (_, { value }) => {
    const { changeFormFieldValue, announcementId } = this.props;
    changeFormFieldValue(
      'slack_channels',
      Immutable.fromJS(value),
      announcementId
    );
  };

  handleAudienceSelection = (_, { value }) => {
    this.setState(
      {
        audienceType: value,
      },
      () => {
        // reset audience values when type is changed
        const { changeFormFieldValue, announcementId } = this.props;

        if (value === ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value) {
          // set default group
          changeFormFieldValue(
            'groups',
            Immutable.fromJS([
              {
                id: 'tmp',
                include_remote: false,
                notification: 'new',
                time_limit: 0,
                user_count: 0,
              },
            ]),
            announcementId
          );
        } else {
          changeFormFieldValue('groups', Immutable.List(), announcementId);
        }
        changeFormFieldValue(
          'slack_channels',
          Immutable.List(),
          announcementId
        );
      }
    );
  };

  renderFooter = () => {
    const {
      announcement,
      announcementId,
      formValues,
      isAnnouncementSaving,
      isDirty,
      isInvalid,
      isUpdating: isUpdatingPublishedAnnouncement,
      totalRecipents,
    } = this.props;
    const { audienceType, isETAEnabled, isUpdating } = this.state;

    const isScheduleSectionInvalid = this.isScheduleFormInvalid();

    let isETAFieldInvalid = false;
    const isNew = announcementId === 'new';
    const announcementStatus = announcement.get('status');
    const isGeneralType =
      announcement.get('type') === ANNOUNCEMENT_TYPES.GENERAL.value;
    const isSurveyType =
      announcement.get('type') === ANNOUNCEMENT_TYPES.SURVEY.value;

    if (
      isETAEnabled &&
      (!formValues.getIn(['eta_date']) || !formValues.getIn(['eta_time']))
    ) {
      isETAFieldInvalid = true;
    }

    // TODO: isActionEnabled is a feature flag. Remove as soon as the feature is stable.
    const isCustomActionFormInvalid = this.areCustomActionsInvalid(formValues);
    const currentStatus = announcement.get('status');
    const isStatusDraft = announcementStatus === AnnouncementState.STATUS_DRAFT;
    const isStatusPublished =
      announcementStatus === AnnouncementState.STATUS_PUBLISHED ||
      announcementStatus === AnnouncementState.STATUS_UPDATED;
    const isStatusResolved =
      announcementStatus === AnnouncementState.STATUS_RESOLVED;
    const isStatusUpdated = isDirty;

    const slackChannels = formValues.getIn(
      ['slack_channels'],
      Immutable.List()
    );

    const isSlackAudience =
      audienceType === ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.value;

    const shouldDisablePublishButton =
      isInvalid ||
      (audienceType === ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value &&
        !totalRecipents) ||
      (slackChannels?.size === 0 && isSlackAudience) ||
      isScheduleSectionInvalid ||
      isCustomActionFormInvalid ||
      isETAFieldInvalid;
    const shouldDisableTextButton =
      isInvalid || (slackChannels?.size === 0 && isSlackAudience);

    const buttonProps = {
      loading: isAnnouncementSaving,
      style: threeButtonStyle,
      type: 'button',
    };

    return isUpdating ? (
      <footer>
        <Button
          {...buttonProps}
          basic
          content={intl.formatMessage({
            id: 'label.cancel',
          })}
          disabled={isUpdatingPublishedAnnouncement}
          loading={isUpdatingPublishedAnnouncement}
          onClick={this.handleCancelUpdate}
        />

        <Button
          {...buttonProps}
          content={intl.formatMessage({
            id: 'label.send_update',
          })}
          disabled={
            !isDirty ||
            isInvalid ||
            isETAFieldInvalid ||
            isUpdatingPublishedAnnouncement
          }
          loading={isUpdatingPublishedAnnouncement}
          onClick={this.handleAnnouncementUpdate}
          primary
        />
      </footer>
    ) : (
      <footer>
        {!isStatusPublished && !isStatusResolved ? (
          <Button
            id='save_button'
            {...buttonProps}
            basic
            content={intl.formatMessage({
              id: 'label.save_draft',
            })}
            disabled={
              !isDirty ||
              isInvalid ||
              isScheduleSectionInvalid ||
              isCustomActionFormInvalid ||
              isETAFieldInvalid
            }
            onClick={this.handleSaveForm(currentStatus)}
            primary
          />
        ) : null}

        <AnnouncementTest
          announcement={announcement}
          disabled={shouldDisableTextButton}
          isNew={isNew}
          shouldDisplaySlackNote={slackChannels?.size > 0}
        />

        {isGeneralType && isStatusPublished && !isSlackAudience ? (
          <Button
            {...buttonProps}
            basic
            className='borderless'
            content={intl.formatMessage({
              id: 'label.update',
            })}
            onClick={this.handleUpdate}
          />
        ) : null}

        {isStatusDraft &&
        isStatusUpdated &&
        isStatusPublished &&
        !isSlackAudience ? (
          <Button
            id='update_button'
            {...buttonProps}
            basic
            className='borderless'
            content={intl.formatMessage({
              id: 'label.update',
            })}
            disabled={!isDirty || isInvalid || isAnnouncementSaving}
            onClick={this.handleSaveForm(AnnouncementState.STATUS_UPDATED)}
          />
        ) : null}

        {isStatusDraft ? (
          <Button
            id='publish_button'
            {...buttonProps}
            content={intl.formatMessage({
              id: 'label.publish',
            })}
            disabled={shouldDisablePublishButton}
            onClick={this.handlePublish}
            primary
          />
        ) : null}

        {isStatusPublished && !isSlackAudience ? (
          <Button
            id='recall_button'
            {...buttonProps}
            basic
            content={intl.formatMessage({
              id: 'label.recall',
            })}
            disabled={isAnnouncementSaving}
            onClick={this.handleRecall}
          />
        ) : null}

        {isStatusResolved || (isStatusPublished && isSurveyType) ? (
          <Button
            id='archive_button'
            {...buttonProps}
            basic={!isStatusResolved}
            content={intl.formatMessage({
              id: 'label.archive',
            })}
            disabled={isAnnouncementSaving}
            onClick={this.handleSaveForm(AnnouncementState.STATUS_ARCHIVED)}
            primary={isStatusResolved}
          />
        ) : null}

        {isStatusPublished && !isSurveyType ? (
          <Button
            id='resolve_button'
            {...buttonProps}
            content={intl.formatMessage({
              id: 'label.resolve',
            })}
            disabled={isInvalid || isAnnouncementSaving}
            onClick={this.handleResolveAskFirst}
            primary
          />
        ) : null}

        {isStatusDraft && announcementId !== 'new' ? (
          <Button
            id='delete_button'
            {...buttonProps}
            content={intl.formatMessage({
              id: 'label.delete',
            })}
            negative
            onClick={this.handleDelete}
          />
        ) : null}
      </footer>
    );
  };

  formatUpdateInfo = (author = 'N/A', time = '') => {
    let formattedInfo = author;
    if (time) {
      // only append time if it's not empty
      formattedInfo += ` - ${new DateTools(
        time
      ).getStandardDateFormatWithHours()}`;
    }
    return formattedInfo;
  };

  translatedValue = (status) =>
    intl.formatMessage({
      id: `label.${intl.prepareKey(status)}`,
    });

  translatedCategory = (category) =>
    intl.formatMessage({
      id: `category.${intl.prepareKey(category)}`,
    });

  render() {
    const {
      addNewGroup,
      announcement,
      announcementId,
      changeFormFieldValue,
      formValues,
      intentsList,
      isLoadingIntent,
      loadIntentByEid,
      slackChannels,
      userLanguage,
    } = this.props;

    const {
      audienceType,
      inactiveUsers,
      isActionEnabled,
      isETAEnabled,
      isExpireCardEnabled,
      isRecurringEnabled,
      isScheduleEnabled,
      isUpdating,
      selectedType,
    } = this.state;

    const audienceOptions = [
      {
        key: ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value,
        text: intl.formatMessage({
          id: ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.text,
        }),
        value: ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value,
      },
      {
        key: ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.value,
        text: intl.formatMessage({
          id: ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.text,
        }),
        value: ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.value,
      },
    ];

    const MIN_DATE = moment().format(INPUT_DATE_FORMAT);
    const labelStyle = {
      fontSize: '1.125em',
    };

    const audienceCount =
      formValues?.get('groups')?.reduce((totalAmount, group) => {
        totalAmount += group?.get('user_count');
        return totalAmount;
      }, 0) ?? 0;

    const audienceTitle =
      intl.formatMessage({
        id: 'label.audience',
      }) +
      (audienceType === ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value
        ? ` (${audienceCount} ${intl.formatMessage({
            id: 'label.user',
            value: audienceCount,
          })})`
        : '');

    const category = formValues.get('category')
      ? formValues.get('category').toString()
      : 'Generic Announcement';

    const currentStatus = announcement.get('status');
    const isGeneralType =
      announcement.get('type') === ANNOUNCEMENT_TYPES.GENERAL.value ||
      formValues.get('type') === ANNOUNCEMENT_TYPES.GENERAL.value;
    const isSurveyTypeSelected =
      selectedType === ANNOUNCEMENT_TYPES.SURVEY.value;
    const isDraft = currentStatus === AnnouncementState.STATUS_DRAFT;
    const shouldDisplaySurveySection =
      selectedType === ANNOUNCEMENT_TYPES.SURVEY.value;
    const shouldDisplayGeneralSection =
      selectedType === ANNOUNCEMENT_TYPES.GENERAL.value && isDraft;
    const shouldDisplayCategoryField =
      selectedType === ANNOUNCEMENT_TYPES.GENERAL.value;
    const isAnnouncementPublished =
      currentStatus === AnnouncementState.STATUS_PUBLISHED;
    const isAnnouncementUpdated =
      currentStatus === AnnouncementState.STATUS_UPDATED;
    const isAnnouncementUpdatable =
      isDraft ||
      (isGeneralType &&
        (isAnnouncementPublished || isAnnouncementUpdated) &&
        isUpdating);
    const shouldDisplayETAFields =
      isGeneralType && (isDraft || isAnnouncementUpdatable);

    const DATE_TYPE = 'date';
    const TIME_TYPE = 'time';
    const getBEFormattedDate = (date, type = DATE_TYPE) =>
      date
        ? moment
            .utc(date, BE_DATE_FORMAT)
            .local()
            .locale(LocaleSingleton.get())
            .format(type === DATE_TYPE ? INPUT_DATE_FORMAT : INPUT_TIME_FORMAT)
        : void 0;
    const getISOFormattedDate = (date, type = DATE_TYPE) =>
      date
        ? moment(date)
            .locale(LocaleSingleton.get())
            .format(type === DATE_TYPE ? INPUT_DATE_FORMAT : INPUT_TIME_FORMAT)
        : void 0;
    const parsedETA = announcement.get('eta')
      ? new DateTools(announcement.get('eta')).getStandardDateFormatWithHours()
      : void 0;

    // BE decided to determine the icon type by setting an attribute 'button_type' .
    // IMO that is notr extensible
    // But I have to get this working. So let's map the icon names from that attribute
    // @param {Object} action
    const mapBackendButtonTypeToIconNames = (action, index) => {
      const { buttons, value } = SurveyTypes[action.button_type];
      const { icon } = buttons[index];
      // const key = `${surveyType.value}/${button.text? button.text: index}`,
      const key = `${value}/${action.button_text}/${index}}`;
      return {
        ...action,
        icon,
        isValid: isActionValid(action.button_type, action),
        key,
      };
    };

    // this function exists only because there's a mismatch in the design for general and survey annoucement types.
    // BE decided to send back the property "action" to keep backward compatibility for the general announcement.
    // General Announcement uses a Map. Whereas Survey uses a List.
    // @param actionProp {Immutable.List|Immutable.Map}
    // @param typeProp {string} SURVEY|GENERAL
    // @return {false|Array}
    const hackActionsPropFromActionProp = (actionProp, typeProp) => {
      const isSurvey = typeProp === ANNOUNCEMENT_TYPES.SURVEY.value;
      // check that the immutable value exists
      const arrayFromList = actionProp && actionProp.toJS();
      // the original prop starts being a map and then changes to an array
      return isSurvey && isArray(arrayFromList)
        ? map(arrayFromList, mapBackendButtonTypeToIconNames)
        : false;
    };

    const renderCategoryField = () => {
      return isDraft ? (
        <SelectCategoryModal
          announcementId={announcementId}
          category={category}
          changeFormFieldValue={changeFormFieldValue}
          isDraft={isDraft}
        />
      ) : (
        <ValidatedField
          format={this.translatedCategory}
          label={intl.formatMessage({
            id: 'label.category',
          })}
          name='category'
          readOnly
          transparent
        />
      );
    };

    const renderAnnouncementTypeReadOnly = () => {
      return (
        <Form.Field>
          {this.translatedValue(announcement?.get('type'))}
        </Form.Field>
      );
    };
    const renderAudienceTypeReadOnly = () => {
      return (
        <Form.Field>
          <strong>{intl.formatMessage({ id: 'label.type' })}</strong>
          {': '}
          {announcement.get('groups')?.size > 0 ||
          (announcement.get('groups')?.size === 0 &&
            announcement.getIn(['bot_channels_list', 'bot_channels_dict'])
              ?.size === 0)
            ? intl.formatMessage({
                id: `${ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.text}`,
              })
            : intl.formatMessage({
                id: `${ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.text}`,
              })}
        </Form.Field>
      );
    };

    const shouldDisplayAudienceType =
      slackChannels?.get('isSlackConfigured') &&
      isDraft &&
      ANNOUNCEMENT_TYPES.GENERAL.value === selectedType;

    const shouldDisplaySlackAudience =
      audienceType === ANNOUNCEMENT_AUDIENCE_TYPES.SLACK.value;
    const shouldDisplayDirectToUserAudience =
      audienceType === ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER.value ||
      ANNOUNCEMENT_TYPES.SURVEY.value === selectedType;

    const initialSlackChannels = announcement
      .getIn(['bot_channels_list', 'bot_channels_dict'], Immutable.List())
      ?.reduce((channels, workgroup) => {
        workgroup?.get('channels').forEach((channel) => {
          channels = channels.push(channel?.get('id'));
        });
        return channels;
      }, Immutable.List());
    const { isModalPublishNumber, isModalResolveNumber, numberOfTotalUsers } =
      this.state;

    const { timeLimit } = this.state;

    const publishMsg = getPublishMsg(
      numberOfTotalUsers,
      inactiveUsers,
      timeLimit
    );

    const resolveMsg = getResolveMsg(
      numberOfTotalUsers,
      inactiveUsers,
      timeLimit
    );

    return !announcement.isEmpty() ? (
      <Boundaries>
        <BodyClassName className='main-active main-padding'>
          <EspMainPanel className='esp-announcement-manage'>
            {announcementId === 'new' ? (
              <EspMainPanelHeader
                title={intl.formatMessage({
                  id: 'label.create_announcement',
                })}
              />
            ) : (
              <EspMainPanelHeader
                title={intl.formatMessage({
                  id: 'label.manage_announcement',
                })}
              />
            )}
            <ValidatedForm
              enableReinitialize
              form={`AnnouncementManageForm_${announcementId}`}
              initialValues={{
                action:
                  announcement &&
                  announcement.get('type') === ANNOUNCEMENT_TYPES.GENERAL.value
                    ? announcement.get('action') &&
                      announcement.get('action').toJS()
                    : [{}],
                actions:
                  hackActionsPropFromActionProp(
                    announcement.get('action'),
                    announcement.get('type')
                  ) || initialFormValuesForActions,
                body: announcement.get('body'),
                category:
                  announcement.get('category') ?? 'Generic Announcement',
                created_info: this.formatUpdateInfo(
                  announcement.get('sys_created_by'),
                  announcement.get('sys_date_created')
                ),
                eta_date: getISOFormattedDate(announcement.get('eta')),
                eta_time: getISOFormattedDate(
                  announcement.get('eta'),
                  TIME_TYPE
                ),
                groups: announcement.get('groups'),
                schedule: {
                  auto_expire_days: announcement.getIn(
                    ['schedule', 'auto_expire_days'],
                    0
                  ),
                  end_date:
                    getBEFormattedDate(
                      announcement.getIn(['schedule', 'end_date'])
                    ) || '',
                  end_time: getBEFormattedDate(
                    announcement.getIn(['schedule', 'end_date']),
                    TIME_TYPE
                  ),
                  recurrence: announcement.getIn(
                    ['schedule', 'recurrence'],
                    []
                  ),
                  recurrence_schedule: announcement.getIn(
                    ['schedule', 'recurrence_schedule'],
                    {}
                  ),
                  slack_channels: initialSlackChannels,
                  start_date: getBEFormattedDate(
                    announcement.getIn(['schedule', 'start_date'])
                  ),
                  start_time: getBEFormattedDate(
                    announcement.getIn(['schedule', 'start_date']),
                    TIME_TYPE
                  ),
                },
                status: currentStatus,
                subject: announcement.get('subject'),
                // Note: since we were assigning different values before. Old announcements are corrupted in this field, that's why
                // we'll default those corrupted with the GENERAL value.
                type: [
                  ANNOUNCEMENT_TYPES.GENERAL.value,
                  ANNOUNCEMENT_TYPES.SURVEY.value,
                ].includes(announcement.get('type'))
                  ? announcement.get('type')
                  : ANNOUNCEMENT_TYPES.GENERAL.value,
                updated_info: this.formatUpdateInfo(
                  announcement.get('sys_updated_by'),
                  announcement.get('sys_date_updated')
                ),
              }}
              onSubmit={this.handleSubmit}
              validate={formValidation}
            >
              <ScrollArea>
                <AnnouncementSection
                  dividing
                  title={intl.formatMessage({ id: 'label.general' })}
                >
                  <Header
                    as='h4'
                    content={intl.formatMessage({
                      id: 'label.type',
                    })}
                  />
                  {isDraft ? (
                    <>
                      <Form.Group inline>
                        <Form.Radio
                          checked={
                            selectedType === ANNOUNCEMENT_TYPES.GENERAL.value
                          }
                          label={intl.formatMessage({
                            id: 'label.announcement',
                          })}
                          name='type'
                          onChange={this.handleAnnouncementTypeChange}
                          value={ANNOUNCEMENT_TYPES.GENERAL.value}
                        />

                        <Form.Radio
                          checked={
                            selectedType === ANNOUNCEMENT_TYPES.SURVEY.value
                          }
                          label={intl.formatMessage({
                            id: 'label.survey',
                          })}
                          name='type'
                          onChange={this.handleAnnouncementTypeChange}
                          value={ANNOUNCEMENT_TYPES.SURVEY.value}
                        />
                      </Form.Group>
                      {(selectedType === ANNOUNCEMENT_TYPES.SURVEY.value ||
                        formValues?.get('type') ===
                          ANNOUNCEMENT_TYPES.SURVEY.value) && (
                        <p>
                          <em>
                            <small>
                              {intl.formatMessage({
                                id: 'label.survey_note',
                              })}
                            </small>
                          </em>
                        </p>
                      )}
                    </>
                  ) : (
                    renderAnnouncementTypeReadOnly()
                  )}

                  <ValidatedField
                    fluid
                    format={this.translatedValue}
                    label={intl.formatMessage({ id: 'label.status' })}
                    name='status'
                    placeholder={intl.formatMessage({
                      id: 'label.status',
                    })}
                    readOnly
                    transparent
                  />
                  {!isDraft && (
                    <>
                      <Form.Field>
                        <label>
                          {intl.formatMessage({
                            id: 'label.created_by',
                          })}
                        </label>
                        <p>{formValues.get('created_info')}</p>
                      </Form.Field>

                      <Form.Field>
                        <label>
                          {intl.formatMessage({
                            id: 'label.updated_by',
                          })}
                        </label>
                        <p>{formValues.get('updated_info')}</p>
                      </Form.Field>
                    </>
                  )}

                  {shouldDisplayCategoryField && renderCategoryField()}
                  {announcementId !== 'new' &&
                    isSurveyTypeSelected &&
                    isAnnouncementPublished && (
                      <SurveyInfo
                        intentSurvey={announcement.get('survey_identifier')}
                      />
                    )}
                </AnnouncementSection>

                <AnnouncementSection
                  dividing
                  title={intl.formatMessage({ id: 'label.content' })}
                >
                  <span ref={this.contentRef} />
                  {isAnnouncementUpdatable ? (
                    <ValidatedField
                      label={intl.formatMessage({ id: 'label.title' })}
                      name='subject'
                      placeholder={intl.formatMessage({
                        id: 'label.subject',
                      })}
                      required={isDraft}
                    />
                  ) : (
                    <ValidatedField
                      label={intl.formatMessage({ id: 'label.title' })}
                      name='subject'
                      readOnly
                      transparent
                    />
                  )}
                  {isAnnouncementUpdatable ? (
                    <ValidatedField
                      component={FormInputTextarea}
                      label={intl.formatMessage({
                        id: 'label.summary',
                      })}
                      name='body'
                      placeholder={intl.formatMessage({
                        id: 'label.summary',
                      })}
                      rows={3}
                    />
                  ) : (
                    <ValidatedField
                      label={intl.formatMessage({
                        id: 'label.summary',
                      })}
                      name='body'
                      readOnly
                      transparent
                    />
                  )}
                  {/* ETA elements */}
                  {shouldDisplayETAFields ? (
                    <>
                      <Form.Field>
                        <Checkbox
                          checked={isETAEnabled}
                          disabled={isScheduleEnabled}
                          id='eta_checkbox'
                          label={
                            <label style={labelStyle}>
                              {intl.formatMessage({
                                id: 'label.announcement_has_eta',
                              })}
                            </label>
                          }
                          name='eta_checkbox'
                          onClick={this.handleToggleETACheckbox}
                          value='isETAEnabled'
                        />
                      </Form.Field>
                      <Form.Group>
                        <Form.Field>
                          <ValidatedField
                            component={FormInputText}
                            disabled={!isETAEnabled}
                            label={intl.formatMessage({
                              id: 'label.date',
                            })}
                            min={MIN_DATE}
                            name='eta_date'
                            required={isETAEnabled}
                            type='date'
                          />
                        </Form.Field>
                        <Form.Field>
                          <ValidatedField
                            component={FormInputText}
                            disabled={!isETAEnabled}
                            label={intl.formatMessage({
                              id: 'label.time',
                            })}
                            name='eta_time'
                            required={isETAEnabled}
                            type='time'
                          />
                        </Form.Field>
                      </Form.Group>
                    </>
                  ) : (
                    parsedETA && (
                      <Form.Field id='eta'>
                        <label>
                          {intl.formatMessage({
                            id: 'label.announcement_has_eta',
                          })}
                        </label>
                        <p>{parsedETA}</p>
                      </Form.Field>
                    )
                  )}

                  {shouldDisplayGeneralSection && (
                    <GenericAnnouncementContentSection
                      announcementId={announcementId}
                      changeFormFieldValue={changeFormFieldValue}
                      formValues={formValues}
                      isActionEnabled={isActionEnabled}
                      isDraft={isDraft}
                      onHandleActionCheckboxToggle={
                        this.handleActionCheckboxToggle
                      }
                    />
                  )}

                  {!isDraft &&
                    isActionEnabled &&
                    announcement
                      .get('action')
                      .map((action, i) => (
                        <ActionButtonInfo
                          action={action}
                          date={new DateTools(
                            announcement.get('sys_date_created')
                          ).getStandardDateFormatWithHours()}
                          intentsList={intentsList}
                          isLoadingIntent={isLoadingIntent}
                          key={i}
                          loadIntentByEid={loadIntentByEid}
                        />
                      ))}
                </AnnouncementSection>

                {shouldDisplaySurveySection && (
                  <SurveyAnnouncementContentSection
                    announcementId={announcementId}
                    changeFormFieldValue={changeFormFieldValue}
                    formValues={formValues}
                    isDraft={isDraft}
                  />
                )}

                <AnnouncementSection dividing title={audienceTitle}>
                  {shouldDisplayAudienceType && (
                    <Form.Field>
                      <label htmlFor='audience_type'>
                        {intl.formatMessage({
                          id: 'label.type',
                        })}
                      </label>
                      <Select
                        id='audience_type'
                        onChange={this.handleAudienceSelection}
                        options={audienceOptions}
                        value={audienceType}
                      />
                      <div>
                        <em>
                          <small>
                            {shouldDisplaySlackAudience &&
                              intl.formatMessage({
                                id: ANNOUNCEMENT_AUDIENCE_TYPES.SLACK
                                  .description,
                              })}

                            {shouldDisplayDirectToUserAudience &&
                              intl.formatMessage({
                                id: ANNOUNCEMENT_AUDIENCE_TYPES.DIRECT_TO_USER
                                  .description,
                              })}
                          </small>
                        </em>
                      </div>
                    </Form.Field>
                  )}

                  {slackChannels?.get('isSlackConfigured') &&
                    !isDraft &&
                    renderAudienceTypeReadOnly()}

                  {shouldDisplaySlackAudience && (
                    <SlackAudienceSection
                      onChange={this.handleSlackChannelSelection}
                      readOnly={!isDraft}
                      slackChannels={
                        !isDraft
                          ? initialSlackChannels
                          : formValues?.get('slack_channels')
                      }
                      slackChannelsOptions={slackChannels?.get(
                        'slackChannelsOptions'
                      )}
                    />
                  )}
                  {shouldDisplayDirectToUserAudience && (
                    <FieldArray
                      addNewGroup={addNewGroup}
                      announcement={announcement}
                      component={AnnouncementGroups}
                      formValues={formValues}
                      name='groups'
                      readOnly={!isDraft}
                    />
                  )}
                </AnnouncementSection>

                {isDraft ? (
                  <AnnouncementScheduling
                    announcementId={announcementId}
                    formValues={formValues}
                    handleToggleScheduleCheckbox={this.onToggleScheduleCheckbox}
                    handlerChangeFormFieldValue={
                      this.handlerChangeFormFieldValue
                    }
                    isDraft={isDraft}
                    isETAEnabled={isETAEnabled}
                    isExpireCardEnabled={isExpireCardEnabled}
                    isRecurringEnabled={isRecurringEnabled}
                    isScheduleEnabled={isScheduleEnabled}
                    userLanguage={userLanguage}
                  />
                ) : (
                  isScheduleEnabled && (
                    <AnnouncementSchedulingReadOnly
                      schedule={formValues.get('schedule')}
                      userLanguage={userLanguage}
                    />
                  )
                )}
              </ScrollArea>

              {this.renderFooter()}
              <EspModal
                cancelButton={intl.formatMessage({
                  id: 'label.cancel',
                })}
                confirmButton={intl.formatMessage({
                  id: 'label.ok',
                })}
                content={publishMsg}
                isOpen={isModalPublishNumber}
                onCancel={this.handlePublishConfirm}
                onConfirm={this.handleConfirmPublish}
              />

              <EspModal
                cancelButton={intl.formatMessage({
                  id: 'label.cancel',
                })}
                confirmButton={intl.formatMessage({
                  id: 'label.ok',
                })}
                content={resolveMsg}
                isOpen={isModalResolveNumber}
                onCancel={this.handleResolveConfirm}
                onConfirm={this.handleConfirmResolve}
              />
            </ValidatedForm>
          </EspMainPanel>
        </BodyClassName>
      </Boundaries>
    ) : null;
  }
}

const AnnouncementManageTest = AnnouncementManage;

const repeatTypes = {
  DAYLY: 'daily',
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
  YEARLY: 'yearly',
};

const endTypes = {
  AFTER: 'after',
  NEVER: 'never',
  ON: 'on',
};

const onTypes = {
  DAY: 'day',
  ORDINAL_WEEK_DAY: 'ordinal_week_day',
};

export { AnnouncementManageTest, repeatTypes, endTypes, onTypes };
export default injectIntlToController(
  AnnouncementManageController(AnnouncementManage)
);
