import { createSelector } from 'reselect';
import getAllBundles from './getAllBundles';

const getParentBundles = (bundles) => {
  if (bundles.isEmpty()) {
    return null;
  }
  return bundles.toList().filter((b) => b.get('role_bundle'));
};

const selector = createSelector([getAllBundles], getParentBundles);

export default selector;
