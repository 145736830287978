import async from 'async';
import { forEach } from 'lodash';
import {
  app as appTeams,
  authentication as teamsAuthentication,
} from '@microsoft/teams-js';

// Utils
import APIcall from '../utils/APIcall';
import browserHistory from '../utils/browserHistory';
import endpointGenerator from '../utils/endpointGenerator';
import uiPathGenerator from '../utils/uiPathGenerator';
// Selector
import getCurrentUser from '../selectors/getCurrentUser';
import isImpersonating from '../selectors/isImpersonating';
import getUserEid from '../selectors/getUserEid';
import {
  getLocale,
  getMultiLanguageConfig,
} from '../selectors/getIntlMessages';
// Actions
import sessionActions from './sessionActions';
import userEntitiesThunks from './userEntitiesThunks';
import bootstrapThunks from './bootstrapThunks';
import i18nActions from './i18nActions';

import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';
import { LoginUtil } from 'esp-util-login';
import { i18Locales } from 'esp-util-intl';
import {
  CLIENT_CHAT_V2,
  CLIENT_TEAMS_FRAME,
  CLIENT_WIDGET_FRAME,
} from '../globals/clientFrameKeys';

/**
 * Makes API call to whoami endpoint.
 * (This function is left alone to signal that it has no side effects)
 * @promise {object} EspUser object of the logged in user
 */
const doWhoami = () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espUser.whoAmI');
    APIcall.get({
      error(error) {
        error =
          error && error.response
            ? error.response.body
            : error.message || 'Unknown error';
        reject(error);
      },
      success(response) {
        const me = response.body;
        resolve(me);
      },
      token: true,
      url,
    });
  });

// Change this to 0 after a few releases
const postSSOLoginWait = 3000; // just temporaly, as there might be old native clients that do not inject the initial flag

/**
 * Session Thunks
 *
 */
const sessionThunks = {};

/**
 * Removes session from local storage
 * Makes API call endpoint to terminate the session, unless isLocalOnly is true
 * @param {boolean} isLocalOnly Whether use endpoint to finish the session
 * @promise
 */
sessionThunks.userLogout = (isLocalOnly) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // take snapshot before removing token
    const state = getState();
    const url = endpointGenerator.genPath('authentication.auth.logout');
    const sessionIndex = state.getIn(['session', 'sessionIndex']);
    const token = state.getIn(['session', 'accessToken']);

    // First, remove local storage data
    persistentStorage.remove(PersistentStorageKeys.SESSION);
    // Second, once removed, logout the user. Needs to be preserved in this order
    // since session reducer automatically tries to rehidrate with localStorage data if exists
    dispatch(sessionActions.userLogout()); // this will clear everything from redux memory

    // if we know auth token is invalid beforehand, we skip doing the backend logout
    // to avoid an infinite loop!
    if (!isLocalOnly && token) {
      APIcall.post({
        data: {
          session_index: sessionIndex,
        },
        error(error) {
          reject(error);
        },
        success(res) {
          resolve(res);
        },
        token,
        url,
      });
    } else {
      resolve();
    }
    // We no longer want to send them to login
    // browserHistory.push(uiPathGenerator.genPath('login'));
  });

/**
 * Checks for email and password credentials and performs whoami to load user into the session
 * @param {Immutable.Map} data {email, password, keep_me_logged}
 * @promise {object} EspUser object of the logged-in user
 */
sessionThunks.userLogin =
  (data, keepMeLogged = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const email = data.get('email');
      const password = data.get('password');
      const keepLogged = data.get('keep_me_logged') || keepMeLogged;

      dispatch(sessionActions.userLoginStart());

      async.waterfall(
        [
          // 1. Get the auth token
          (next) => {
            LoginUtil.doLogin(
              endpointGenerator.genPath('authentication.auth.login'),
              email,
              password
            )
              .then((accessToken) => {
                dispatch(sessionActions.setToken(accessToken));
                next(null);
              })
              .catch((error) => {
                next(error);
              });
          },

          // 2. Bootsrap the app
          (next) => {
            dispatch(bootstrapThunks.appBootstrap())
              .then(({ me }) => next(null, me))
              .catch((error) => next(error));
          },
        ],
        // On done
        (error, me) => {
          if (error) {
            dispatch(
              sessionActions.userLoginFailure({
                ...(error?.response?.body ?? {}),
                status: error?.response?.status,
              })
            );
            reject(error);
          } else {
            dispatch(sessionThunks.setSessionUser(me, keepLogged));
            resolve(me);
          }
        }
      );
    });

/**
 * Wrapper that has a side effect to set the session
 * @promise {object} EspUser object of the logged-in user
 */
sessionThunks.whoami = () => (dispatch) =>
  new Promise((resolve, reject) => {
    doWhoami()
      .then((me) => {
        dispatch(sessionThunks.setSessionUser(me));
        resolve(me);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Sets values of data as PATCH into the espUser entity of the current user
 * @param {object} data
 * @promise {object} EspUser object of the logged-in user
 */
sessionThunks.updateUserProfile = (data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(sessionActions.userLoginStart());

    const state = getState();
    const currentUser = getCurrentUser(state);

    APIcall.patch({
      data: data,
      error(error) {
        dispatch(sessionActions.userLoginFailure(error.message));
        reject(error);
      },
      success(res) {
        const currentUser = res.body;
        dispatch(sessionThunks.updateLocalCurrentUser(currentUser));
        if (
          getMultiLanguageConfig(state) &&
          res.body.preferred_language_id !== getLocale(state)
        ) {
          window.location.reload();
        } else {
          resolve(currentUser);
        }
      },
      token: true,
      url: currentUser.get('url'),
    });
  });

/**
 * Sets values of data as PATCH into the espUser entity of the current user
 * @param {object} data
 * @promise {object} EspUser object of the logged-in user
 */
sessionThunks.loadSupportedLanguages = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const query = {
      limit: 1000,
    };

    dispatch(i18nActions.loadSupportedLanguagesStart());

    APIcall.get({
      error(err) {
        dispatch(i18nActions.loadSupportedLanguagesFail());
        reject(err);
      },
      query,
      success({ body }) {
        const languages = body.results;

        const languagesSanitized = languages.map((r) => {
          let localeExist = false;
          forEach(i18Locales, (value) => {
            r.beCode = r.code; // Keep the BE compatible locale code
            if (r.code === value) {
              localeExist = true;
            }
          });

          if (!localeExist) {
            // need to take the default from i18
            forEach(i18Locales, (value) => {
              if (value.match(r.code)) {
                r.code = value;
              }
            });
          }
          return r;
        });

        dispatch(i18nActions.loadSupportedLanguagesSuccess(languagesSanitized));
        resolve(languagesSanitized);
      },
      token: true,
      url: endpointGenerator.genPath('commons.supportedLanguages'),
    });
  });

/**
 * Sets images into the appropiate endpoint
 * @param {object} data
 * @promise {object} EspUser object of the logged-in user
 */
sessionThunks.updteUserImages = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(sessionActions.userImgUploadStart());

    // Create and populate a formdata
    const finalData = data.data;

    let endPts;

    if (data.id) {
      endPts = endpointGenerator.genPath('espImage.profileImages.instance', {
        profileImageID: data.id,
      });
    } else {
      endPts = endpointGenerator.genPath('espImage.profileImages');
    }

    // New image or update ?
    const methodApi = data.id ? 'patch' : 'post';

    APIcall[methodApi]({
      data: finalData,
      error: (error) => {
        dispatch(sessionActions.userImgUploadFail(error.message));
        reject(error);
      },
      success: () => {
        dispatch(sessionActions.userImgUploadSuccess());

        dispatch(sessionThunks.whoami()).then((me) => {
          resolve(me);
        });
      },
      token: true,
      url: endPts,
    });
  });

/**
 * Deletes an image from the endpoint
 * @param {object} data
 * @promise {object} EspUser object of the logged-in user, without the deleted image
 */
sessionThunks.deleteUserImage = (idImage) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(sessionActions.userLoginStart());

    const endPts = endpointGenerator.genPath(
      'espImage.profileImages.instance',
      {
        profileImageID: idImage,
      }
    );
    const state = getState();

    APIcall.del({
      error(error) {
        dispatch(sessionActions.userLoginFailure(error.message));
        reject(error);
      },
      success() {
        // Delete the images deleted in the User's entitie
        const myId = state.getIn(['session', 'currentUser']);
        let me = state.getIn(['entities', 'users', myId]);
        const images = me
          .get('images')
          .filter((img) => img.get('id') !== idImage);
        me = me.set('images', images).toJS();

        dispatch(sessionThunks.updateLocalCurrentUser(me));
        resolve(me);
      },
      token: true,
      url: endPts,
    });
  });

/**
 * Overwrites the current user entity and data
 *
 */
sessionThunks.updateLocalCurrentUser = (newCurrentUserObject) => (dispatch) => {
  // update current user in the Redux state
  dispatch(sessionActions.whoami(newCurrentUserObject));
  dispatch(sessionActions.updateUserSuccess());
};

/**
 * Set the current user into local storage and session action reducer
 * @param {object} me - object to overwrite the current user
 */
sessionThunks.setSessionUser = (me) => (dispatch, getState) => {
  const timeStamp = Math.floor(Date.now() / 1000);
  dispatch(sessionActions.userLoginSuccess(me, timeStamp));

  const state = getState();
  const session = state.get('session').toJS();

  // Only save in persistent storage these fields
  persistentStorage.put(PersistentStorageKeys.SESSION, {
    accessToken: session.accessToken,
    currentUser: session.currentUser,
    impersonation: session.impersonation,
    previousUrl: session.previousUrl,
    sessionIndex: session.sessionIndex,
    tokenCreation: session.tokenCreation,
  });

  // DEV-12723
  // re-writing tenant url as it may get flushed out by other sources
  // on SSO login
  persistentStorage.put(
    PersistentStorageKeys.TENANT_URL,
    state.getIn(['tenant', 'entity', 'value', 'url'])
  );
};

/**
 * Assigns a user as delegate of currentUser
 * @param {Number} userId - id of the delegate
 * @promise {object} EspUser object of the logged-in user, with the new delegate updated
 */
sessionThunks.assignDelegate = (userId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    // Array with the userId if exist, or empty array otherwise
    const delegates = userId ? [userId] : [];
    const data = {
      delegates: delegates,
    };

    dispatch(sessionActions.assignDelegateStart());
    APIcall.patch({
      data: data,
      error(error) {
        dispatch(sessionActions.assignDelegateFail(error));
        reject(error);
      },
      success(res) {
        const me = res.body;
        dispatch(sessionActions.assignDelegateSuccess());
        dispatch(sessionThunks.updateLocalCurrentUser(me));
        resolve(me);
      },
      token: true,
      url: currentUser.get('url'),
    });
  });

/**
 * Does a GET call to the login endpoint to discover if an external login method is used
 * IT DOES HAVE A HARD SIDE EFFECT
 * If an external method is discovered, this will redirect the user's browser
 */
sessionThunks.getAuthLogin = () => (dispatch) => {
  APIcall.get({
    data: {},
    error() {
      dispatch(sessionActions.externalLoginChecked());
    },
    success(response) {
      const { body } = response;
      if (body && body.redirect_uri) {
        const externalLoginRedirectUri = body.redirect_uri;
        if (externalLoginRedirectUri) {
          dispatch(
            sessionActions.externalLoginChecked(externalLoginRedirectUri)
          );
        }
      } else {
        dispatch(sessionActions.externalLoginChecked());
      }
    },
    url: endpointGenerator.genPath('authentication.auth.login'),
  });
};

const redirectToTeamsApp = () => {
  const teamsPath = uiPathGenerator.genPath('teamsApp');
  window.location.replace(teamsPath);
};

const redirectToChatApp = () => {
  const chatPath = uiPathGenerator.genPath('chatV2App');
  window.location.replace(`${chatPath}/`);
};

/**
 *
 * @param {*}
 * state -- redux state (previous state)
 * getState - redux function (current state)
 * DEV-18331: The getState() should happen before the SSO workflows in this file
 * Otherwise this causes race conditions as actionTypes.USER_LOGIN_SUCCESS
 * clears previousUrl from the session reducer
 */
const redirectAfterSSOLogin = (previousState, getState) => {
  const previousUrl = previousState.getIn(['session', 'previousUrl']);

  // respects previous URl if exists in the session
  const redirectUrl = previousUrl
    ? previousUrl
    : uiPathGenerator.genLandingPath(getState());

  // eslint-disable-next-line no-console -- debugging
  console.warn('Browser redirecting to', redirectUrl);
  browserHistory.replace(redirectUrl);
};

async function handleTeamsAuthSuccess() {
  localStorage.removeItem(CLIENT_TEAMS_FRAME);

  try {
    // If the home feed launched the auth flow in popup notify success, so it can close it
    await appTeams.initialize();
    teamsAuthentication.notifySuccess('Teams authentication successful');
  } catch (e) {
    // eslint-disable-next-line no-console -- debugging
    console.warn('Could not notify teams sdk auth success', e.message);
  }

  // Redirect to the home feed app
  redirectToTeamsApp();
}

async function handleWidgetAuthSuccess() {
  localStorage.removeItem(CLIENT_WIDGET_FRAME);

  browserHistory.replace(uiPathGenerator.genPath('widgetAuthSuccess'));
}

async function handleChatV2AuthSuccess() {
  localStorage.removeItem(CLIENT_CHAT_V2);
  redirectToChatApp();
}

function isWidgetFrame() {
  return localStorage.getItem(CLIENT_WIDGET_FRAME);
}

function isTeamFrame() {
  return localStorage.getItem(CLIENT_TEAMS_FRAME);
}

function isChatV2() {
  return localStorage.getItem(CLIENT_CHAT_V2);
}

/**
 * Based on the url params received in /auth/oauth
 * it retrieves the user token
 * @param {String} queryParams - url query params received from azure
 */
sessionThunks.authenticateWithOAuth = (queryParams) => (dispatch, getState) => {
  const state = getState();
  // eslint-disable-next-line no-console -- debugging
  console.warn('sessionThunks.authenticateWithOAuth');
  dispatch(sessionActions.startSSOTokenRetrieval());

  setTimeout(() => {
    // must wait to prevent race conditions
    // This can be set either from SSOUtils.js (cordova) or ipcEvents.js (electron)
    async.waterfall(
      [
        // 1. Get the auth token
        (next) => {
          APIcall.get({
            error(error) {
              next(error);
            },
            query: queryParams,
            success({ body }) {
              if (body) {
                const accessToken = body.key;
                dispatch(sessionActions.setToken(accessToken));
                next(null);
              } else {
                next(
                  new Error('Unable to login with the credentials provided')
                );
              }
            },
            url: endpointGenerator.genPath(
              'authentication.auth.login.azureGetToken'
            ),
          });
        },

        // 2. Bootsrap the app
        (next) => {
          dispatch(bootstrapThunks.appBootstrap())
            .then(({ me }) => next(null, me))
            .catch((error) => next(error));
        },
      ],
      (error, me) => {
        dispatch(sessionActions.endSSOTokenRetrieval());

        if (error) {
          dispatch(sessionActions.userAuthFailure(error.message));
        } else {
          if (localStorage.getItem('isInAppBrowser')) {
            // Wait for electron to close browser
            return;
          }

          dispatch(sessionThunks.setSessionUser(me));
          if (isChatV2()) {
            handleChatV2AuthSuccess();
            return;
          }

          if (isTeamFrame()) {
            handleTeamsAuthSuccess();
            return;
          }
          if (isWidgetFrame()) {
            handleWidgetAuthSuccess();
            return;
          }
          redirectAfterSSOLogin(state, getState);
        }
      }
    );
  }, postSSOLoginWait);
};

/**
 * Based on the auth token set in local storage
 * it attempts to authenticate
 * @param {String} queryParams - url query params received from azure
 */
sessionThunks.authenticateWithSAML = () => (dispatch, getState) => {
  const state = getState();
  // eslint-disable-next-line no-console -- debugging
  console.warn('sessionThunks.authenticateWithSAML');
  dispatch(sessionActions.startSSOTokenRetrieval());

  async.waterfall(
    [
      // 1. Get the auth token
      (next) => {
        const authToken = localStorage.getItem('authToken');
        const sessionIndex = localStorage.getItem('session_index');
        if (authToken) {
          next(null, authToken, sessionIndex);
        } else {
          next(new Error('Unable to login with the credentials provided'));
        }
      },
      // Finally start session or report the error
    ],
    (error, authToken, sessionIndex) => {
      dispatch(sessionActions.endSSOTokenRetrieval());

      if (error) {
        dispatch(sessionActions.userAuthFailure(error.message));
        browserHistory.replace(uiPathGenerator.genPath('auth.saml'));
      } else {
        // only wait for enough time in the web version
        setTimeout(() => {
          dispatch(sessionActions.setSessionIndex(sessionIndex));

          // This could also be opened inside an in-app browser
          dispatch(sessionThunks.startSessionWithToken(authToken)).then(() => {
            // This can be set either from SSOUtils.js (cordova) or ipcEvents.js (electron)
            if (!localStorage.getItem('isInAppBrowser')) {
              localStorage.removeItem('authToken'); // Clean the token from here, so it's never used again
              localStorage.removeItem('session_index');
              if (isTeamFrame()) {
                handleTeamsAuthSuccess();
                return;
              }
              if (isWidgetFrame()) {
                handleWidgetAuthSuccess();
                return;
              }
              redirectAfterSSOLogin(state, getState);
            }
          });
        }, postSSOLoginWait); // Sadly there's no consistent way to determine if it's an in app-browser. So let's give it a time to let SSOUtils try to extract the token
      }
    }
  );
};

/**
 * If the user is the delegate of someone, it allows him to impesonate him
 * This will set a special header in every APICall
 * whoami will be performed as the new user
 * Current user will be the impersonated user
 * @param {Number} userId - id of the delegate
 * @promise {object} EspUser object of the impersonated user
 */
sessionThunks.impersonateUser = (userId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    async.waterfall(
      [
        // 1. Check if the impersonating user exists in entities
        (next) => {
          if (getUserEid(state, userId)) {
            next(null);
          } else {
            dispatch(userEntitiesThunks.addUserEntity(userId)).then(() =>
              next(null)
            );
          }
        },
        // 2. Impersonate the user
        (next) => {
          const selectedUser = getCurrentUser(state);
          const originalUserId = selectedUser.get('id');
          const originalDelegators = selectedUser.get('delegate_of');
          const eid = getUserEid(state, userId);

          // this will reset the reducer, don't move it
          // We need to get the origin user delegators first
          dispatch(sessionActions.impersonatingStart());

          if (!isImpersonating(state)) {
            dispatch(
              sessionActions.saveOriginalUser(
                originalUserId,
                originalDelegators
              )
            );
            dispatch(sessionActions.impersonatingSet(userId, eid));
          } else {
            dispatch(sessionActions.impersonatingSet(null));
          }

          next(null);
        },
        // Do who am i as a newly impersonated user
        (next) => {
          dispatch(sessionThunks.whoami())
            .then((me) => next(null, me))
            .catch((error) => next(error));
        },
      ],
      // On Done
      (error, me) => {
        if (error) {
          reject(error);
        } else {
          resolve(me);
        }
      }
    );
  });

/**
 * Allows to quickstart a new session only with the access token
 * @param {String} accessToken
 * @promise {object} EspUser object of the current user
 */
sessionThunks.startSessionWithToken = (accessToken) => (dispatch) =>
  new Promise((resolve, reject) => {
    async.waterfall(
      [
        // 1. set the auth token
        (next) => {
          dispatch(sessionActions.setToken(accessToken));
          next(null);
        },
        // 2. Bootsrap the app
        (next) => {
          dispatch(bootstrapThunks.appBootstrap())
            .then(({ me }) => next(null, me))
            .catch((error) => next(error));
        },
      ],
      (error, me) => {
        if (error) {
          dispatch(sessionActions.userLoginFailure(error));
          reject(error);
        } else {
          dispatch(sessionThunks.setSessionUser(me));
          resolve(me);
        }
      }
    );
  });

const doCallLoadActiveSessions = (userName = '') =>
  new Promise((resolve, reject) => {
    APIcall.get({
      error: ({ response: { body } }) => reject(body.errors),
      query: {
        detail: 'yes',
        esp_filters: `user.username__EQ=${userName}`,
      },
      success: ({ body }) => resolve(body),
      token: true,
      url: endpointGenerator.genPath('authentication.activeSessions'),
    });
  });

sessionThunks.loadActiveSessions =
  (userName = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (userName === '') {
        reject(['Username should not be empty']);
      } else {
        dispatch(sessionActions.loadUserAccessTokenStart(userName));
        doCallLoadActiveSessions(userName)
          .then((response) => {
            dispatch(
              sessionActions.loadUserAccessTokenSuccess(userName, response)
            );
            resolve(response);
          })
          .catch((error) => {
            dispatch(sessionActions.loadUserAccessTokenError(userName, error));
            reject(error);
          });
      }
    });

sessionThunks.removeAccessTokensFor = (user) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(sessionActions.loadUserAccessTokenStart(user.get('username')));
    APIcall.post({
      data: {
        user: user.get('id'),
      },
      error(err) {
        dispatch(
          sessionActions.loadUserAccessTokenError(user.get('username'), err)
        );
        reject(err);
      },
      success() {
        dispatch(
          sessionActions.loadUserAccessTokenSuccess(user.get('username'), [])
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('authentication.auth.logoutAllAsAdmin'),
    });
  });

sessionThunks.redirectToLandingPath = () => (dispatch, getState) => {
  const landing = uiPathGenerator.genLandingPath(getState());
  browserHistory.replace(landing);
};
export default sessionThunks;
