import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Header, Message } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
// Molecule
import ValidatedField from '../molecules/ValidatedField';
import ValidatedForm from '../molecules/ValidatedForm';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

class LoginForm extends PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    error: ImmutablePropTypes.map,
    formValidation: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      nickname: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    error: null,
    isLoading: false,
  };

  trimSpaces(value) {
    return value && value.trim();
  }

  render() {
    const {
      email,
      handleSubmit,
      initialValues,
      formValidation,
      error,
      isLoading,
    } = this.props;

    const renderError = () => {
      const errorStatus = error?.get('status');
      const BLOCKED_PASSWORD_STATUS = 401;

      if (errorStatus === BLOCKED_PASSWORD_STATUS) {
        return (
          <Message
            content={intl.formatMessage({
              id: 'message.login_error_blocked_password',
            })}
            error
            header={intl.formatMessage({
              id: 'message.login_error',
            })}
            size='tiny'
          />
        );
      }
      return (
        <Message
          content={intl.formatMessage({
            id: 'message.login_error_help',
          })}
          error
          header={intl.formatMessage({
            id: 'message.login_error',
          })}
          size='tiny'
        />
      );
    };

    return (
      <ValidatedForm
        form='LoginForm'
        header={<Header content='Profile' icon='user' />}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={formValidation}
      >
        {error && renderError()}

        <ValidatedField
          autoComplete='on'
          fluid
          icon='user'
          name='email'
          normalize={this.trimSpaces}
          placeholder='Email or username'
          type={'text'}
        />

        <ValidatedField
          autoComplete='on'
          fluid
          icon='lock'
          name='password'
          placeholder='Password'
          type='password'
        />

        <Form.Field widths='equal'>
          <Button
            content={intl.formatMessage({
              id: 'label.Login',
            })}
            disabled={isLoading}
            fluid
            loading={isLoading}
            primary
            type='submit'
          />
        </Form.Field>

        <Grid columns='equal' stackable={false}>
          <Grid.Column>
            <NavLink
              to={`${uiPathGenerator.genPath('resetPassword')}?email=${email}`}
            >
              {'Forgot Password?'}
            </NavLink>
          </Grid.Column>

          {window.cordova || window.electron ? (
            <Grid.Column textAlign='right'>
              <NavLink to={uiPathGenerator.genPath('noTenant')}>
                {intl.formatMessage({
                  id: 'label.select_tenant',
                })}
              </NavLink>
            </Grid.Column>
          ) : null}
        </Grid>
      </ValidatedForm>
    );
  }
}

export const LoginFormTest = LoginForm;

export default LoginForm;
