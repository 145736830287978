import { createSelector } from 'reselect';
import getServiceDepartmentTeamList from './getServiceDepartmentTeamList';
import Immutable from 'immutable';

const getCategoriesList = (state) =>
  state.getIn(['caseMgmt', 'serviceDepartmentCategories', 'list']);

const getConditionsList = (state) =>
  state.getIn(['caseMgmt', 'baristaSearchConditions', 'list']);

const getTaskTypesList = (state) =>
  state.getIn(['caseMgmt', 'taskTypes', 'list']);

const find = (list, eid) => list.find((element) => element.get('eid') === eid);

const findInCollectionOrEmptyMap = (object, collection) =>
  collection.empty ? Immutable.Map() : find(collection, object);

const buildConditionsList = (
  categories = Immutable.List(),
  conditions = Immutable.List(),
  teams = Immutable.List(),
  taskTypes = Immutable.List()
) => {
  const serviceDepartmentConditionsList = !conditions.isEmpty()
    ? conditions
        .map((condition) =>
          condition.set(
            'service_team',
            findInCollectionOrEmptyMap(condition.get('service_team'), teams)
          )
        )
        .map((condition) =>
          condition.set(
            'task_category',
            findInCollectionOrEmptyMap(
              condition.get('task_category'),
              categories
            )
          )
        )
        .map((condition) =>
          condition.set(
            'task_type',
            findInCollectionOrEmptyMap(condition.get('task_type'), taskTypes)
          )
        )
    : Immutable.List();

  return {
    serviceDepartmentCategoriesList: categories,
    serviceDepartmentConditionsList,
    serviceDepartmentTeamList: teams,
    taskTypesList: taskTypes,
  };
};

const getCaseManagementLists = createSelector(
  [
    getCategoriesList,
    getConditionsList,
    getServiceDepartmentTeamList,
    getTaskTypesList,
  ],
  buildConditionsList
);

export default getCaseManagementLists;
