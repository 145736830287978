import _ from 'lodash';
// Utils
import APIcall from './APIcall';
import endpointGenerator from './endpointGenerator';
// Global store
import store from '../stores/store';
// Actions
import appVersionActions from '../actions/appVersionActions';

const getUniqueDeviceId = () => {
  const appname = window.electronAPI?.getAppName();
  const platform = window.electronAPI?.getOSPlatform();
  const hostname = window.electronAPI?.getOSHostName();
  // unique id simply platform + hostname. e.g. : "[darwin] [Erick-Espressive.local] [Barista DEV]"
  const uniqueId = platform + hostname + appname;

  return btoa(uniqueId);
};
const ElectronUtils = {
  deRegisterClientNotification: () =>
    new Promise((resolve, reject) => {
      const uniqueId = getUniqueDeviceId();

      APIcall.delete({
        error(e) {
          reject(e);
        },
        preventShowError: true,
        success({ body }) {
          resolve(body);
          // eslint-disable-next-line no-console -- debugging
          console.warn('Desktop notification succesfully de-registered', body);
        },
        token: true,
        url: endpointGenerator.genPath(
          'espNotification.desktopRegistration.pk',
          {
            pk: uniqueId,
          }
        ),
      });
    }),

  // The main process already has an interval to check updates
  // but you can manually for a update check here
  forceCheckUpdate: () => {
    if (window.electron) {
      // console.log('Electron will update using', customUpdateServerUrl);
      window.electronAPI?.ipcSend('check-for-updates');
    }
  },

  registerClientNotification: () =>
    new Promise((resolve, reject) => {
      const uniqueId = getUniqueDeviceId();

      APIcall.post({
        data: {
          active: true,
          registration_id: uniqueId,
        },
        error(e) {
          reject(e);
        },
        preventShowError: true,
        success({ body }) {
          resolve(body);
          // eslint-disable-next-line no-console -- debugging
          console.warn('Desktop notification succesfully registered', body);
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.desktopRegistration'),
      });
    }),

  retrieveAppVersion: () => {
    const version = window.electronAPI?.getAppVersion();
    store.dispatch(appVersionActions.setNativeDesktopVersion(version));
  },

  setTenantUpdateServer: (tenant) => {
    // This is meant to affect only electron
    // Registering the tenant update server (if exists)
    if (window.electron) {
      let customUpdateServerUrl = null;
      // Check if a custom update server has been set
      if (_.hasIn(tenant, 'ui_config.desktopApp.updateServerUrl')) {
        customUpdateServerUrl = tenant.ui_config.desktopApp.updateServerUrl;
      }

      // comunicates the renderer (this web app with electron main process via messages
      // console.log('Electron will update using', customUpdateServerUrl);
      window.electronAPI?.ipcSend('set-update-server', customUpdateServerUrl);
    }
  },
};

export default ElectronUtils;
