import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import styles from './ListFilterTrigger.module.scss';

const stylesFactory = ({ open }) => ({
  action: {
    opacity: open ? 0 : 1,
    transform: open ? 'scale(0)' : 'scale(1)',
  },
});

const propTypes = {
  action: PropTypes.element,
  filteredCount: PropTypes.number,
  filteredCountLabel: PropTypes.string,
  label: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  open: PropTypes.bool,
};
const defaultProps = {
  action: null,
  filteredCount: 0,
  filteredCountLabel: '',
  onClick: _.noop,
  open: false,
};
const ListFilterTrigger = (props) => {
  const {
    action,
    filteredCount,
    filteredCountLabel,
    label,
    onClick,
    open,
  } = props;

  const filteredListCount = filteredCount ? (
    <span
      style={{ fontWeight: 'bold' }}
    >{` (${filteredCount} ${filteredCountLabel})`}</span>
  ) : null;

  const dependentActionStyles = {
    opacity: open ? 0 : 1,
    transform: open ? 'scale(0)' : 'scale(1)',
  };

  return (
    <div className={styles._} data-component='ListFilterTrigger'>
      {action && (
        <div className='action' data-open={open} style={dependentActionStyles}>
          {action}
        </div>
      )}
      {label && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions -- no easy way to fix this
        <div
          className='label'
          onClick={onClick}
          onKeyPress={onClick}
          style={stylesFactory({ open }).label}
        >
          {label}
          {filteredListCount}
          <Icon name='dropdown' />
        </div>
      )}
    </div>
  );
};

ListFilterTrigger.propTypes = propTypes;
ListFilterTrigger.defaultProps = defaultProps;

export default ListFilterTrigger;
