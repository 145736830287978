import actionTypes from './actionTypes';
import SelectMyGearStepTypes from '../globals/SelectMyGearStepTypes';

const bundleActions = {};

bundleActions.loadBundlesStart = (tag) => ({
  tag,
  type: actionTypes.LOAD_BUNDLES_START,
});

bundleActions.loadStartSearchBundles = (tag) => ({
  tag,
  type: actionTypes.LOAD_START_SEARCH_BUNDLES,
});

bundleActions.loadStartSearchRoleBundles = (tag) => ({
  tag,
  type: actionTypes.LOAD_START_SEARCH_ROLE_BUNDLES,
});

bundleActions.loadBundlesSuccess = (bundles, tag) => ({
  bundles,
  tag,
  type: actionTypes.LOAD_BUNDLES_SUCCESS,
});

bundleActions.resetBundlesList = () => ({
  type: actionTypes.RESET_BUNDLES_LIST,
});

bundleActions.loadBundlesFail = (error, tag) => ({
  error,
  tag,
  type: actionTypes.LOAD_BUNDLES_FAIL,
});

bundleActions.bundleSelected = (bundleId) => ({
  bundleId,
  type: actionTypes.BUNDLE_SELECTED,
});

bundleActions.addBundlesChildSuccess = (bundles) => ({
  bundles,
  type: actionTypes.LOAD_CHILD_BUNDLES_SUCCESS,
});

bundleActions.getHardwareProductsStart = () => ({
  type: actionTypes.GET_HARDWARE_BUNDLE_PRODUCTS_START,
});

bundleActions.getHardwareProductsSuccess = (
  topLevelBundle,
  hardwareBundle,
  hardwareBundleProducts
) => ({
  hardwareBundle,
  hardwareBundleProducts,
  topLevelBundle,
  type: actionTypes.GET_HARDWARE_BUNDLE_PRODUCTS_SUCCESS,
});

bundleActions.setActiveHardwareBundleProductImageIdx = (index) => ({
  index,
  type: actionTypes.SET_ACTIVE_HARDWARE_BUNDLE_PRODUCT_IMAGE_IDX,
});

bundleActions.selectHardwareBundleProduct = (productId) => ({
  productId,
  type: actionTypes.SELECT_HARDWARE_BUNDLE_PRODUCT,
});

bundleActions.setSearchTerm = (searchTerm) => ({
  searchTerm,
  type: actionTypes.BUNDLE_SET_SEARCH_TERM,
});

bundleActions.setSearchResultsBundles = (bundles) => ({
  bundles,
  type: actionTypes.BUNDLE_SET_SEARCH_BUNDLES_RESULTS,
});

bundleActions.setSearchResultsRoleBundles = (bundles) => ({
  bundles,
  type: actionTypes.BUNDLE_SET_SEARCH_ROLE_BUNDLES_RESULTS,
});

bundleActions.setlocationQuery = (query) => ({
  query,
  type: actionTypes.BUNDLE_SET_SEARCH_QUERY,
});

bundleActions.loadBundleAdminDetailStart = () => ({
  type: actionTypes.BUNDLE_ADMIN_DETAIL_START,
});

bundleActions.createNewBundleStart = () => ({
  type: actionTypes.BUNDLE_ADMIN_CREATE_START,
});

bundleActions.createNewBundleSuccess = (bundle) => ({
  bundle,
  type: actionTypes.BUNDLE_ADMIN_CREATE_SUCCESS,
});

bundleActions.createNewBundleError = () => ({
  type: actionTypes.BUNDLE_ADMIN_CREATE_ERROR,
});

bundleActions.loadBundleAdminDetailSuccess = (bundle) => ({
  bundle,
  type: actionTypes.BUNDLE_ADMIN_DETAIL_SUCCESS,
});

bundleActions.clearCurrentBundleDetail = () => ({
  type: actionTypes.BUNDLE_ADMIN_DETAIL_ClEAR,
});

bundleActions.loadBundleAdminDetailError = () => ({
  type: actionTypes.BUNDLE_ADMIN_DETAIL_ERROR,
});

bundleActions.setAddNewProductState = (status) => ({
  status,
  type: actionTypes.BUNDLE_ADMIN_SET_NEW_PRODUCT,
});

bundleActions.setAddNewBundleState = (status) => ({
  status,
  type: actionTypes.BUNDLE_ADMIN_SET_NEW_BUNDLE,
});

bundleActions.saveTmpImage = (img) => ({
  img,
  type: actionTypes.SAVE_TEMPORARY_BUNDLE_IMAGE,
});

bundleActions.clearTmpImage = () => ({
  type: actionTypes.DELETE_TEMPORARY_BUNDLE_IMAGES,
});

bundleActions.deleteTmpImage = (imgID) => ({
  imgID,
  type: actionTypes.DELETE_TEMPORARY_BUNDLE_IMAGE,
});

bundleActions.saveBundleItems = (bItems) => ({
  bItems,
  type: actionTypes.SAVE_BUNDLE_ITEMS,
});

bundleActions.setBundleJobRolePermissions = (job_role_permissions) => ({
  job_role_permissions,
  type: actionTypes.SET_BUNDLE_JOB_ROLE_PERMISSIONS,
});

bundleActions.setBundleJobRoleTemporaryPermissions = (permissions) => ({
  permissions,
  type: actionTypes.ADD_BUNDLE_TEMPORARY_PERMISSIONS,
});

bundleActions.clearBundleJobRoleTemporaryPermissions = () => ({
  type: actionTypes.CLEAR_BUNDLE_TEMPORARY_PERMISSIONS,
});

bundleActions.loadBundleJobRolePermissionsStart = () => ({
  type: actionTypes.LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_START,
});

bundleActions.loadBundleJobRolePermissionsSuccess = () => ({
  type: actionTypes.LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_SUCCESS,
});

bundleActions.loadBundleJobRolePermissionsError = () => ({
  type: actionTypes.LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_ERROR,
});

bundleActions.bundleImagesLoading = (productID) => ({
  productID,
  type: actionTypes.SET_BUNDLE_IMAGES_ISLOADING,
});

bundleActions.bundleImagesDone = (productID) => ({
  productID,
  type: actionTypes.SET_BUNDLE_IMAGES_DONE,
});

/**
 * Fake bundle item
 * this is for bundle items that haven't been created yet, since we can't assign a bundle item to a bundle that does
 * not exists yet we save them into the state
 * @see bundleReducer.js
 * @see state.getIn(['bundles', 'tmpBundleItems'])
 */

const validateTemporaryBundleActionHelper = ({
  bundleItemType,
  product,
  bundle,
  product_family,
}) => {
  // no bundle type defined
  if (!bundleItemType) {
    // base 64 message: "bundle item type is required"
    throw new Error('YnVuZGxlIGl0ZW0gdHlwZSBpcyByZXF1aXJlZA==');
  }
  if (
    bundleItemType !== SelectMyGearStepTypes.PRODUCT &&
    bundleItemType !== SelectMyGearStepTypes.PRODUCT_BUNDLE &&
    bundleItemType !== SelectMyGearStepTypes.PRODUCT_FAMILY
  ) {
    // base 64 message: "invalid bundle item type"
    throw new Error(
      'dHlwZSBwcm9kdWN0IGZhbWlseSBzZWxlY3RlZCBidXQgcHJvZHVjdCBpcyBudWxs'
    );
  }
  // one of this two values has to be declared
  if (!bundle && !product) {
    // base 64 message: "no bundle or product found, one needs to get Set"
    throw new Error(
      'bm8gYnVuZGxlIG9yIHByb2R1Y3QgZm91bmQsIG9uZSBuZWVkcyB0byBnZXQgU2V0'
    );
  }
  // can use bundle and product at the same time
  if (bundle && product) {
    // base 64 message: "please use bundle or product but not both"
    throw new Error('cGxlYXNlIHVzZSBidW5kbGUgb3IgcHJvZHVjdCBidXQgbm90IGJvdGg=');
  }
  // bundle type equals bundle but bundle id not passed
  if (bundleItemType === SelectMyGearStepTypes.PRODUCT_BUNDLE && !bundle) {
    // base 64 message: "type bundle, can not have a empty bundle"
    throw new Error('dHlwZSBidW5kbGUsIGNhbiBub3QgaGF2ZSBhIGVtcHR5IGJ1bmRsZQ==');
  }
  // bundle type equals bundle but bundle id not passed
  if (bundleItemType === SelectMyGearStepTypes.PRODUCT && !product) {
    // base 64 message: "type product selected but product is null"
    throw new Error('dHlwZSBwcm9kdWN0IHNlbGVjdGVkIGJ1dCBwcm9kdWN0IGlzIG51bGw=');
  }
  // bundle type equals bundle but bundle id not passed
  if (bundleItemType === SelectMyGearStepTypes.PRODUCT_FAMILY && !product) {
    // base 64 message: "type product family selected but product is null"
    throw new Error(
      'dHlwZSBwcm9kdWN0IGZhbWlseSBzZWxlY3RlZCBidXQgcHJvZHVjdCBpcyBudWxs'
    );
  }
  // bundle type equals bundle but bundle id not passed
  if (
    bundleItemType === SelectMyGearStepTypes.PRODUCT_FAMILY &&
    !product_family
  ) {
    // base 64 message: "product_family should not be null"
    throw new Error('cHJvZHVjdF9mYW1pbHkgc2hvdWxkIG5vdCBiZSBudWxs');
  }
  return true;
};

/**
 * Validated action,
 *
 * Using this method incorrectly will throw base 64 errors
 * @param product {Integer}
 * @param bundle {Integer}
 * @param bundleItemType {String} THIS FIELD IS REQUIRED
 * @returns {{type: string, bundleItemType: *, product: *, bundle: *}}
 */
bundleActions.addTemporaryBundleItem = ({
  product = null,
  bundle = null,
  bundleItemType = null,
  product_family = null,
}) => {
  // this method will throw an error if data is not correct
  validateTemporaryBundleActionHelper({
    bundle,
    bundleItemType,
    product,
    product_family,
  });
  return {
    bundle,
    bundleItemType,
    product,
    product_family,
    type: actionTypes.ADD_TEMPORARY_BUNDLE_ITEM,
  };
};

bundleActions.removeTemporaryBundleItem = (id) => ({
  id,
  type: actionTypes.REMOVE_TEMPORARY_BUNDLE_ITEM,
});

bundleActions.replaceAllTemporaryBundleItem = (items) => ({
  items,
  type: actionTypes.REPLACE_ALL_TEMPORARY_BUNDLE_ITEM,
});

bundleActions.clearTemporaryBundleItem = () => ({
  type: actionTypes.CLEAR_TEMPORARY_BUNDLE_ITEM,
});

bundleActions.replaceTemporaryBundleItem = ({
  id = null,
  product = null,
  bundle = null,
  bundleItemType = null,
  product_family = null,
}) => {
  // no bundle type defined
  if (!id) {
    // base 64 message: "bundle item id not found"
    throw new Error('YnVuZGxlIGl0ZW0gaWQgbm90IGZvdW5k');
  }
  validateTemporaryBundleActionHelper({
    bundle,
    bundleItemType,
    product,
    product_family,
  });
  return {
    bundle,
    bundleItemType,
    id,
    product,
    product_family,
    type: actionTypes.REPLACE_TEMPORARY_BUNDLE_ITEM,
  };
};

export default bundleActions;
