import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsHr extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(4 4)'>
          <circle cx='18.53' cy='32.78' r='3.91' />
          <path d='M22.54 37.76h-8c-.67-.008-1.317.253-1.794.724C12.27 38.956 12 39.6 12 40.27v9.07c0 1.386 1.124 2.51 2.51 2.51h.62v13.21c-.003.512.198 1.004.558 1.368.36.363.85.57 1.362.572H20c.514.003 1.007-.2 1.37-.562.365-.362.57-.854.57-1.368V51.86h.62c1.367-.038 2.45-1.163 2.44-2.53v-9.06c0-1.367-1.093-2.483-2.46-2.51z' />
          <circle cx='35.51' cy='32.78' r='3.91' />
          <path d='M39.52 37.76h-8c-.667-.003-1.308.26-1.78.732-.474.47-.74 1.11-.74 1.778v9.07c0 1.386 1.124 2.51 2.51 2.51h.62v13.21c-.006 1.047.824 1.907 1.87 1.94h3c.514.003 1.007-.2 1.37-.562.365-.362.57-.854.57-1.368V51.86h.62c1.367-.038 2.45-1.163 2.44-2.53v-9.06c0-1.375-1.105-2.494-2.48-2.51z' />
          <circle cx='52.49' cy='32.78' r='3.91' />
          <path d='M56.51 37.76h-8c-.666 0-1.304.264-1.775.735-.47.47-.735 1.11-.735 1.775v9.07c0 1.386 1.124 2.51 2.51 2.51h.62v13.21c0 1.045.826 1.902 1.87 1.94h3c1.068 0 1.934-.862 1.94-1.93V51.86h.62c1.367-.038 2.45-1.163 2.44-2.53v-9.06c0-1.378-1.112-2.5-2.49-2.51z' />
          <circle cx='69.48' cy='32.78' r='3.91' />
          <path d='M73.5 37.76h-8c-1.382.006-2.5 1.128-2.5 2.51v9.07c0 .666.264 1.304.735 1.775.47.47 1.11.735 1.775.735h.62v13.22c0 1.043.828 1.898 1.87 1.93h2.94c1.068 0 1.934-.862 1.94-1.93V51.86h.62c.667-.003 1.306-.27 1.775-.746.47-.475.73-1.117.725-1.784v-9.06c.003-.665-.26-1.303-.73-1.774-.468-.47-1.105-.736-1.77-.736z' />
          <circle cx='35.51' cy='17.15' r='3.91' />
          <circle cx='52.49' cy='17.15' r='3.91' />
          <circle cx='27.02' cy='24.97' r='3.91' />
          <circle cx='44' cy='24.97' r='3.91' />
          <circle cx='60.99' cy='24.97' r='3.91' />
          <path d='M44 0C19.7 0 0 19.7 0 44s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C66.86 4.637 55.67 0 44 0zm0 83C22.46 83 5 65.54 5 44S22.46 5 44 5s39 17.46 39 39c0 10.343-4.11 20.263-11.423 27.577C64.263 78.89 54.343 83 44 83z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsHr;
