import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Image, List } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { curry, memoize } from 'lodash';

// Block own controller
import BundleSelectorController from './BundleSelectorController';
// molecules
import EspProductHeader from '../../molecules/EspProductHeader';
// Globals
import TypeBundleSelect, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBundleSelect';

const bundleShape = ImmutablePropTypes.mapContains({
  id: PropTypes.number,
  images: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      caption: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      ordering: PropTypes.number,
      thumbnail: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  tags: PropTypes.string,
});

class BundleSelector01 extends Component {
  static propTypes = {
    ...TypeBundleSelect,
    bundles: ImmutablePropTypes.listOf(bundleShape),
    bundlesByTagLoaded: ImmutablePropTypes.map,
    loadBundles: PropTypes.func.isRequired,
    selectBundle: PropTypes.func.isRequired,
    selectedBundle: bundleShape,
  };

  static defaultProps = {
    ...TypeDefaults,
    bundles: [],
    bundlesByTagLoaded: new Map(),
    selectedBundle: new Map(),
  };

  componentDidMount() {
    const { bundlesByTagLoaded, loadBundles } = this.props;

    if (!bundlesByTagLoaded.get('parent')) {
      loadBundles('parent');
    }
  }

  handleBundleClick = memoize(
    curry((bundleId, e) => {
      const { selectBundle } = this.props;
      selectBundle(bundleId);
    })
  );

  render() {
    const { bundles, selectedBundle, template, type } = this.props;

    // const activeBundleClass = 'active';
    const activeBundleClass = ''; // TO-DO: use right class this after we receive styles

    return (
      <div
        className='block esp-catalog-detail'
        data-blocktype={type}
        data-template={template}
      >
        {bundles && (
          <List divided relaxed='very'>
            {bundles.map((bundle) => {
              const mainImage = bundle
                .get('images')
                .min((images) => images.get('ordering'));

              const otherTwoImages = bundle
                .get('images')
                .filter((image) => image.get('id') !== mainImage.get('id'))
                .sortBy((image) => image.get('ordering'))
                .slice(0, 2);

              return (
                <List.Item
                  as='a'
                  className={
                    selectedBundle &&
                    selectedBundle.get('id') === bundle.get('id')
                      ? activeBundleClass
                      : ''
                  }
                  key={bundle.get('id')}
                  onClick={this.handleBundleClick(bundle.get('id'))}
                >
                  {mainImage && (
                    <EspProductHeader title={mainImage.get('caption')} />
                  )}
                  <div className='details'>
                    {mainImage && (
                      <div className='product-image'>
                        <Image
                          alt={mainImage.get('description')}
                          src={mainImage.get('thumbnail')}
                        />
                      </div>
                    )}

                    <div className='description'>
                      <List>
                        {otherTwoImages &&
                          otherTwoImages.map((image) => (
                            <List.Item key={image.get('id')}>
                              <Image avatar src={image.get('thumbnail')} />
                              <List.Content>
                                <List.Description>
                                  {image.get('caption')}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          ))}
                      </List>
                    </div>
                  </div>
                </List.Item>
              );
            })}
          </List>
        )}
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
BundleSelector01 = BundleSelectorController(BundleSelector01)();

export default BundleSelector01;
