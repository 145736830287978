import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form/immutable';
import FormInputText from './FormInputText';

class EditorWorkflowTaskForm extends PureComponent {
  static propTypes = {
    formError: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formError: '',
  };

  render() {
    const { handleSubmit, formError } = this.props;

    return (
      <Form inverted onSubmit={handleSubmit}>
        <Field
          component={FormInputText}
          formError={formError}
          label='Task name'
          name='name'
          placeholder='Task name'
          showErrorsWithoutSubmitting
        />
      </Form>
    );
  }
}

const validateFormSync = (values) => {
  const errors = {};
  if (values.get('name') === '') {
    errors.name = 'Please add a value';
  }
  return errors;
};

export default reduxForm({
  form: 'WorkflowTaskForm',
  validate: validateFormSync,
})(EditorWorkflowTaskForm);
