const api_workflow_v0_1_condition = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      conditions: null,
      description: 'Condition adf04f3b-35b4-44e4-a59e-107cd1eed931',
      eid: '831f51ec-ffce-4564-88c8-3ef30c6e297a',
      id: 119,
      object_instances: null,
      ordering: 0,
      success_workflow: null,
      success_workflow_task:
        'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/111/',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2018-09-20T20:57:07.064595Z',
      sys_date_updated: '2018-09-20T20:57:07.064540Z',
      sys_updated_by: null,
      url: 'https://release.qa.espressive.com/api/workflow/v0.1/condition/119/',
      workflow_condition_set:
        'https://release.qa.espressive.com/api/workflow/v0.1/workflowconditionset/120/',
    },
  ],
};

export default api_workflow_v0_1_condition;
