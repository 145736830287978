import React from 'react';
import { get, has } from 'lodash';

// Globals
import TypeImage, { TypeDefaults } from '../../../globals/blocks/TypeImage';
import { ImageDefaults } from 'esp-assets';

const Image01 = ({
  image_mobile = TypeDefaults?.image_mobile,
  image_web = TypeDefaults?.image_web,
  template = TypeDefaults?.template,
  type = TypeDefaults?.type,
}) => {
  // Default image for mobile
  const imgMobile = image_mobile || image_web;

  // User required image of URL path
  const webImage = has(ImageDefaults, image_web)
    ? get(ImageDefaults, image_web)
    : image_web;

  const mobileImage = has(ImageDefaults, imgMobile)
    ? get(ImageDefaults, imgMobile)
    : imgMobile;

  return (
    <div className='block' data-blocktype={type} data-template={template}>
      <img alt='' className='image web' src={webImage} />

      <img alt='' className='image mobile' src={mobileImage} />
    </div>
  );
};

Image01.propTypes = TypeImage;

export default Image01;
