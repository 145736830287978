const api_case_mgmt_v0_1_service_department_20 = {
  classification: 1,
  created_in_elc: false,
  description: 'Bad bad',
  eid: 'a30cda4e-3f8d-42ac-8e8b-5df361074856',
  groups: [],
  id: 20,
  images: [
    {
      caption: '',
      description: '',
      eid: 'ceee65ca-a71f-4773-8b1d-7b1500d895c5',
      id: 6,
      image_480:
        'https://q.evisserp.se/media/release/base_images/4ecdf64e-b10c-4f12-9aae-2ad05c513cce/blob.jpg?Expires=1603812800&Signature=MEUCIEtrWWv0BR5asdf7NeBFIxySGOroImigONVs8ueijbNZAiEA4V4p~f~gjM0u0AtkkmpHYZ55p6dGhl8IsXJYy62QsdE_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      image_960:
        'https://q.evisserp.se/media/release/base_images/d11c6b2d-6750-43bc-a681-156144aafbf4/blob.jpg?Expires=1603812800&Signature=MEQCIHq-oBhF-p9gbPeSMTVZsPfRP2JV684PMSz6soenKEnpAiA72EGIAAG0ZesrbgXa9wuE~7Qd~ZL9RYkKkjEReLxaGg__&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      image_custom: null,
      ordering: 0,
      service_team: 20,
      service_team_name: 'QA',
      sys_created_by: 'mark@espressive.com',
      sys_custom_fields: null,
      sys_date_created: '2018-06-12T17:55:05.716584Z',
      sys_date_updated: '2018-06-12T17:55:05.716556Z',
      sys_updated_by: 'mark@espressive.com',
      thumbnail:
        'https://q.evisserp.se/media/release/base_images/44777a98-d28b-4b43-b3b3-81f34aa02df9/blob.jpg?Expires=1603812800&Signature=MEQCIBGtXQQzAns3Z15aq9bERzvZetSwve12Ehn5bfuO-b2cAiB1oeDn3S1E~JVx~NfBT6WittxOD7tovGyWOGDHAEIUGw__&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      url:
        'https://release.qa.espressive.com/api/case_mgmt/v0.1/service_team_image/6/',
    },
  ],
  is_protected: false,
  last_sync_time: null,
  name: 'QA',
  service_department: {
    auth_group_id: 27,
    created_in_elc: false,
    default_task_type_id: 'incident',
    description: 'Test second department!',
    display_name: 'IT',
    eid: '87561ee1-ed72-4757-9e49-80a41bb36a3b',
    id: 4,
    is_protected: false,
    last_sync_time: null,
    loc_display_preference: 'floor',
    service_department: 'IT',
    service_department_type_id: 'IT',
    src_active_status: true,
    sys_created_by: 'pascal@espressive.com',
    sys_custom_fields: null,
    sys_date_created: '2018-03-26T23:18:39.595248+00:00',
    sys_date_updated: '2020-10-07T22:17:51.947534+00:00',
    sys_updated_by: 'ruben@example.com',
  },
  src_active_status: true,
  sys_created_by: 'gg65464532181+manualtesting@gmail.com',
  sys_date_created: '2018-04-05T17:26:16.059204Z',
  sys_date_updated: '2020-02-06T18:55:08.595332Z',
  sys_updated_by: 'martin@espressive.com',
  team_auth_group: 596,
  team_lead: 1989,
  team_members: [
    {
      auth_group: 'SERVICE_AGENT',
      id: 306,
      user: 1246,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 321,
      user: 8,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 82,
      user: 44,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 193,
      user: 11232,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 78,
      user: 136,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 112,
      user: 5244,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 75,
      user: 3497,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 117,
      user: 30,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 84,
      user: 1672,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 88,
      user: 68,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 32,
      user: 1989,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 207,
      user: 3254,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 81,
      user: 1579,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 116,
      user: 5834,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 68,
      user: 85,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 124,
      user: 1283,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 125,
      user: 1528,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 134,
      user: 498,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 136,
      user: 10473,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 137,
      user: 10553,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 138,
      user: 10551,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 139,
      user: 7214,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 140,
      user: 6134,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 141,
      user: 7644,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 142,
      user: 3478,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 143,
      user: 3479,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 144,
      user: 3114,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 146,
      user: 7673,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 151,
      user: 1189,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 176,
      user: 2225,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 83,
      user: 63,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 299,
      user: 11409,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 255,
      user: 11472,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 350,
      user: 70,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 330,
      user: 27,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 311,
      user: 11751,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 351,
      user: 55,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 685,
      user: 219001,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 376,
      user: 11724,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 402,
      user: 218728,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 383,
      user: 63070,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 557,
      user: 211487,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 710,
      user: 219013,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 513,
      user: 218746,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 533,
      user: 1833,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 558,
      user: 218871,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 684,
      user: 219000,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 692,
      user: 11,
    },
  ],
  url: 'https://release.qa.espressive.com/api/case_mgmt/v0.1/service_team/20/',
};

export default api_case_mgmt_v0_1_service_department_20;
