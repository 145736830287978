const IntlUtils = {};

// https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#flatten-messages-object
IntlUtils.flattenMessages = function (nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, this.flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

IntlUtils.getShopCategoryTranslation = (intl, label = '') => {
  if (!intl) {
    return label;
  }
  const key = `category.${label.replace(/ /g, '_')}`.toLowerCase();
  const text = intl.formatMessage({
    id: key,
  });
  return text === key ? label : text;
};

IntlUtils.getButtonContent = (card, locale) => {
  const languageCode = locale ? locale.replace(/[-_][a-z]+$/i, '') : null;
  if (languageCode && card.hasIn(['frontend_blob', 'intl', languageCode])) {
    return card.getIn([
      'frontend_blob',
      'intl',
      languageCode,
      'cta_button',
      '0',
      'text',
    ]);
  }
  return card.getIn(['frontend_blob', 'cta_button', '0', 'text']);
};

export default IntlUtils;
