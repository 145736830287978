import { fromJS, List } from 'immutable';

import actionTypes from '../actions/actionTypes';

// This Initial state will change depending on the structure of the actual API
const INITIAL_STATE = fromJS({
  addingCatalogItemID: 0,
  assigned_to: null,
  cartItems: [],
  error: null,
  id: null,
  isLoading: false,
  isLoadingTaskCart: false,
  isLoadingTaskCartItems: false,
  isLoadingWFCart: false,
  loaded: false,
  num_items: null,
  productsToBeFulfilled: {},
  removeCatalogItemID: 0,
  requested_for: null,
  requester: null,
  status: null,
  tasksCarts: {},
  url: null,
});

const getQty = (items) => {
  let qty = 0;
  items.forEach((item) => {
    qty += item.get('qty');
  });
  return qty;
};

/**
 * Cart reducer
 * @param state
 * @param action
 * @returns {*}
 */
const cartReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_CART_WF_START: {
      return state.set('isLoadingWFCart', true);
    }

    case actionTypes.LOAD_CART_WF_STOP: {
      return state.set('isLoadingWFCart', false);
    }

    case actionTypes.LOAD_CART_START: {
      const cartItems = new List();
      return state
        .set('loaded', false)
        .set('isLoading', true)
        .set('cartItems', cartItems);
    }

    case actionTypes.LOAD_CART_ISLOADING: {
      return state.set('loaded', false).set('isLoading', true);
    }

    case actionTypes.LOAD_CART_ISLOADING_END: {
      return state.set('isLoading', false);
    }

    case actionTypes.LOAD_CART_SUCCESS: {
      const numItems = getQty(state.get('cartItems'));

      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('num_items', numItems)
        .set('error', null);
    }

    case actionTypes.LOAD_TASK_CART_START: {
      return state.set('isLoadingTaskCart', true);
    }

    case actionTypes.LOAD_TASK_CART_SUCCESS: {
      return state.set('isLoadingTaskCart', false);
    }

    case actionTypes.LOAD_TASK_CART_FAIL: {
      return state.set('isLoadingTaskCart', false);
    }

    case actionTypes.LOAD_TASK_CART_ITEMS_START: {
      return state.set('isLoadingTaskCartItems', true);
    }

    case actionTypes.LOAD_TASK_CART_ITEMS_SUCCESS: {
      return state
        .set('isLoadingTaskCartItems', false)
        .setIn(['tasksCarts', action.id], fromJS(action.cart));
    }

    case actionTypes.LOAD_TASK_CART_ITEMS_FAIL: {
      return state.set('isLoadingTaskCartItems', false);
    }

    case actionTypes.UPDATE_TASK_CART_STATUS: {
      return state.setIn(['tasksCarts', action.id, 'status'], action.status);
    }

    case actionTypes.UPDATE_CART_SUCCESS: {
      let cartItems = state.get('cartItems');

      cartItems = cartItems.update(
        cartItems.findIndex(
          (item) => item.get('citemId') === action.product.id
        ),
        (item) => item.set('qty', action.product.quantity)
      );

      const numItems = getQty(cartItems);

      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('cartItems', cartItems)
        .set('num_items', numItems)
        .set('error', null);
    }

    case actionTypes.LOAD_NO_CART_SUCCESS: {
      return state.set('loaded', true).set('isLoading', false);
    }

    case actionTypes.LOAD_CART_USER_SUCCESS: {
      const { userCart } = action;
      return state
        .set('id', userCart.id)
        .set('url', userCart.url)
        .set('requester', userCart.requester)
        .set('assigned_to', userCart.assigned_to)
        .set('requested_for', userCart.requested_for)
        .set('status', userCart.status)
        .set('num_items', userCart.num_sum_items)
        .set('isLoading', Boolean(action.stillLoading));
    }

    case actionTypes.FILL_CART_SUCCESS: {
      const cartItems = state.get('cartItems').push(fromJS(action.cartItem));

      const numItems = getQty(cartItems);

      return state.set('cartItems', cartItems).set('num_items', numItems);
    }

    case actionTypes.LOAD_CART_FAIL: {
      return state
        .set('loaded', false)
        .set('isLoading', false)
        .set('error', action.error)
        .set('removeCatalogItemID', 0);
    }

    case actionTypes.REMOVE_CART_ITEM: {
      const currentItem = state.get('cartItems');

      const cartItems = currentItem.remove(
        currentItem.findIndex((item) => item.get('citemId') === action.citemId)
      );

      const numItems = getQty(cartItems);
      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('num_items', numItems)
        .set('cartItems', cartItems)
        .set('removeCatalogItemID', 0);
    }

    case actionTypes.CLEAR_CART_ITEM: {
      const cartItems = fromJS([]);

      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('num_items', 0)
        .set('cartItems', cartItems);
    }

    case actionTypes.FULFILLMENT_QUESTIONS_STARTED: {
      return state.setIn(
        ['productsToBeFulfilled', action.productId],
        action.quantity
      );
    }

    case actionTypes.FULFILLMENT_QUESTIONS_ANSWERED: {
      let productsToBeFulfilled = state.get('productsToBeFulfilled');
      productsToBeFulfilled = productsToBeFulfilled.delete(action.productId);

      return state
        .set('isLoading', false)
        .setIn(['productsToBeFulfilled'], productsToBeFulfilled);
    }

    case actionTypes.RESET_CART_SUCCESS: {
      return INITIAL_STATE;
    }

    case actionTypes.REMOVE_CATALOG_ITEM_START: {
      return state
        .set('isLoading', true)
        .set('removeCatalogItemID', action.removeCatalogItemID);
    }

    case actionTypes.ADD_CATALOG_ITEM_START: {
      return (
        state
          .set('isLoading', true)
          // .set('loaded', false)// TODO: double check its use and purpose
          .set('addingCatalogItemID', action.addingCatalogItemID)
      );
    }

    case actionTypes.ADD_CATALOG_ITEM_SUCCESS: {
      return (
        state
          .set('isLoading', false)
          // .set('loaded', true)// TODO: double check its use and purpose
          .set('addingCatalogItemID', 0)
      );
    }

    case actionTypes.ADD_CATALOG_ITEM_FAILURE: {
      return (
        state
          .set('isLoading', false)
          // .set('loaded', true)// TODO: double check its use and purpose
          .set('addingCatalogItemID', 0)
      );
    }

    default: {
      return state;
    }
  }
};

export default cartReducer;
