import PropTypes from 'prop-types';

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list])
    .isRequired,
  className: PropTypes.string,
};

const EspMainPanel = ({ children, className = null }) => {
  return <div className={`esp-main-panel ${className}`}>{children}</div>;
};

EspMainPanel.propTypes = propTypes;

export default EspMainPanel;
