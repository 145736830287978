import { createSelector } from 'reselect';

// state entities
const jobRoles = (state) => state.getIn(['entities', 'jobRoles']);
const userRules = (state) => state.getIn(['entities', 'userRules']);

// helpers
const regExp = (name) => new RegExp(`user.job_role.name.*"${name}"`);
const findUserRule = (name, userRules) =>
  userRules.find((userRule) => userRule.get('rule').match(regExp(name)));

// selector
const getJobRolesWithNoUserRules = (jobRoles, userRules) =>
  jobRoles.filterNot((jobRole) => findUserRule(jobRole.get('name'), userRules));

const selector = createSelector(
  [jobRoles, userRules],
  getJobRolesWithNoUserRules
);

export default selector;
