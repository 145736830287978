import Background01 from '../components/blocks/background/Background01';
import Button01 from '../components/blocks/button/Button01';
import NextButton01 from '../components/blocks/button/NextButton01';
import Hero01 from '../components/blocks/hero/Hero01';
import Hero02 from '../components/blocks/hero/Hero02';
import Image01 from '../components/blocks/image/Image01';
import Loading01 from '../components/blocks/loading/Loading01';
import Espressive01 from '../components/blocks/logo/Espressive01';
import Radio01 from '../components/blocks/radio/Radio01';
import Skills01 from '../components/blocks/skills/Skills01';
import Spacer01 from '../components/blocks/spacer/Spacer01';
import Text01 from '../components/blocks/text/Text01';

import AddEntries01 from '../components/blocks/addEntries/AddEntries01';
import AddEntitySynonymValue01 from '../components/blocks/addEntitySynonymValue/AddEntitySynonymValue01';
import AddEntityValue01 from '../components/blocks/addEntityValue/AddEntityValue01';
import AddResponses01 from '../components/blocks/addResponses/AddResponses01';
import Barista01 from '../components/blocks/barista/Barista01';
import BeginChat01 from '../components/blocks/beginChat/BeginChat01';
import Birthday01 from '../components/blocks/birthday/Birthday01';
import BuildEditFaq01 from '../components/blocks/buildEditFaq/BuildEditFaq01';
import BuildExistingEmployeeData01 from '../components/blocks/buildExistingEmployeeData/BuildExistingEmployeeData01';
import BuildTeachBarista01 from '../components/blocks/buildTeachBarista/BuildTeachBarista01';
import BuildAndSearchFAQTeachBarista01 from '../components/blocks/buildTeachBarista/BuildAndSearchFAQTeachBarista01';
import BundleSelect01 from '../components/blocks/bundleSelect/BundleSelect01';
import BundleSelect02 from '../components/blocks/bundleSelect/BundleSelect02';
import BundleSelectorController from '../components/blocks/bundleSelect/BundleSelectorController';
import AddToCartButton01 from '../components/blocks/button/AddToCartButton01';
import CancelCartButton01 from '../components/blocks/button/CancelCartButton01';
import SubmitAndCancelCartButton01 from '../components/blocks/button/SubmitAndCancelCartButton01';
import SubmitCartButton01 from '../components/blocks/button/SubmitCartButton01';
import EspCard01 from '../components/blocks/card/EspCard01';
import Cart01 from '../components/blocks/cart/Cart01';
import changePassword01 from '../components/blocks/changePassword/changePassword01';
import Checkbox01 from '../components/blocks/checkbox/Checkbox01';
import CheckUserAsManager01 from '../components/blocks/checkbox/CheckUserAsManager01';
import Conversation01 from '../components/blocks/conversation/Conversation01';
import CreateNewFaq01 from '../components/blocks/createNewFaq/CreateNewFaq01';
import CreateNewUser01 from '../components/blocks/createNewUser/CreateNewUser01';
import EditComment01 from '../components/blocks/editComment/EditComment01';
import EditFAQTeachBarista01 from '../components/blocks/editFAQTeachBarista/EditFAQTeachBarista01';
import EquipmentCheck01 from '../components/blocks/equipmentCheck/EquipmentCheck01';
import ExitWorkflow01 from '../components/blocks/exitWorkflow/ExitWorkflow01';
import CopyField01 from '../components/blocks/field/CopyField01';
import Field01 from '../components/blocks/field/Field01';
import Field02 from '../components/blocks/field/Field02';
import Field03 from '../components/blocks/field/Field03';
import Form01 from '../components/blocks/form/Form01';
import ImageSelect01 from '../components/blocks/imageSelect/ImageSelect01';
import LocationDeptRolePicker01 from '../components/blocks/locationDeptRolePicker/LocationDeptRolePicker01';
import Login01 from '../components/blocks/login/Login01';
import Logo01 from '../components/blocks/logo/Logo01';
import Logo02 from '../components/blocks/logo/Logo02';
import resetPasswordCode01 from '../components/blocks/passwordReset/resetPasswordCode01';
import resetPasswordMethod01 from '../components/blocks/passwordReset/resetPasswordMethod01';
import phoneNumber01 from '../components/blocks/phoneNumber/phoneNumber01';
import PreviewProfile01 from '../components/blocks/previewProfile/PreviewProfile01';
import PreviewProfileController from '../components/blocks/previewProfile/PreviewProfileController';
import ProductConfiguration01 from '../components/blocks/productConfiguration/ProductConfiguration01';
import Radio02 from '../components/blocks/radio/Radio02';
import Radio03 from '../components/blocks/radio/Radio03';
import roleSelect01 from '../components/blocks/roleSelect/roleSelect01';
import SearchArchetypeEntity01 from '../components/blocks/searchArchetypeEntity/SearchArchetypeEntity01';
import SearchFAQArchetype01 from '../components/blocks/searchFAQArchetype/SearchFAQArchetype01';
import searchSelectFAQ01 from '../components/blocks/searchSelectFAQ/searchSelectFAQ01';
import selectFaq01 from '../components/blocks/selectFAQ/selectFAQ01';
import matchFAQFromArchetypeEntity01 from '../components/blocks/selectFAQ/matchFAQFromArchetypeEntity01';
import skipTeachBarista01 from '../components/blocks/searchSelectFAQ/skipTeachBarista01';
import SelectDepartment01 from '../components/blocks/selectDepartment/SelectDepartment01';
import SelectDepartmentCategory01 from '../components/blocks/selectDepartmentCategory/SelectDepartmentCategory01';
import SelectLanguage01 from '../components/blocks/selectLanguage/SelectLanguage01';
import SelectMobileType01 from '../components/blocks/selectMobileOSType/SelectMobileType01';
import SelectOSType01 from '../components/blocks/selectMobileOSType/SelectOSType01';
import SelectMyGear01 from '../components/blocks/selectMyGear/SelectMyGear01';
import SelectServiceTeam01 from '../components/blocks/selectServiceTeam/SelectServiceTeam01';
import SelectUserContactGroup01 from '../components/blocks/selectUserContactGroup/SelectUserContactGroup01';
import SendDownloadAppSMS01 from '../components/blocks/sendDownloadAppSMS/SendDownloadAppSMS01';
import SendReport01 from '../components/blocks/sendReport/SendReport01';
import SetValueFromKBSnowInFAQ01 from '../components/blocks/setValueFromKB/SetValueFromKBSnowInFAQ01';
import SkipTask01 from '../components/blocks/skipTask/SkipTask01';
import socialMedia01 from '../components/blocks/socialMedia/socialMedia01';
import SoftwareSelect01 from '../components/blocks/softwareSelect/SoftwareSelect01';
import SoftwareUtilization01 from '../components/blocks/softwareUtilization/SoftwareUtilization01';
import SystemAvatars01 from '../components/blocks/systemAvatar/SystemAvatars01';
import AddResponseTextArea01 from '../components/blocks/textarea/AddResponseTextArea01';
import TextArea01 from '../components/blocks/textarea/TextArea01';
import DescriptionFAQ01 from '../components/blocks/textarea/DescriptionFAQ01';
import UpdateEntityValueInstance01 from '../components/blocks/updateEntityValueInstance/UpdateEntityValueInstance01';
import UserSelect01 from '../components/blocks/userSelect/UserSelect01';
import WorkAddress01 from '../components/blocks/workAddress/WorkAddress01';

import {
  Background01 as NewBackground01,
  Button01 as NewButton01,
  Espressive01 as NewEspressive01,
  Hero01 as NewHero01,
  Hero02 as NewHero02,
  Image01 as NewImage01,
  Loading01 as NewLoading01,
  NextButton01 as NewNextButton01,
  Radio01 as NewRadio01,
  Skills01 as NewSkills01,
  Spacer01 as NewSpacer01,
  Text01 as NewText01,
} from 'esp-ui-blocks';

/**
 * OLD DESCRIPTION (KEEP IN CASE OF...)
 * getBlockComponentByName.js
 *
 * This file is autogenerated from 'BlocksPlugin' Webpack plugin during build.
 * Template for this is in '/webpackPlugins/templates/getBlockComponentByName.handlebars'
 *
 * Please don't modify it.
 */

/**
 * UPDATED DESCRIPTION
 * getBlockComponentByName.js
 *
 * This file is NOT autogenerated anymore from 'BlocksPlugin' Webpack plugin during build.
 * All new blocks must be added by hand here
 *
 */

const useNewBlocks = false;

// ES6 Map
const blocksByName = new Map();

blocksByName.set('addentries01', AddEntries01);
blocksByName.set('addentitysynonymvalue01', AddEntitySynonymValue01);
blocksByName.set('addentityvalue01', AddEntityValue01);
blocksByName.set('addresponses01', AddResponses01);
blocksByName.set('background01', useNewBlocks ? NewBackground01 : Background01);
blocksByName.set('barista01', Barista01);
blocksByName.set('beginchat01', BeginChat01);
blocksByName.set('birthday01', Birthday01);
blocksByName.set('buildeditfaq01', BuildEditFaq01);
blocksByName.set('buildexistingemployeedata01', BuildExistingEmployeeData01);
blocksByName.set('buildteachbarista01', BuildTeachBarista01);
blocksByName.set(
  'buildandsearchfaqteachbarista01',
  BuildAndSearchFAQTeachBarista01
);
blocksByName.set('bundleselect01', BundleSelect01);
blocksByName.set('bundleselect02', BundleSelect02);
blocksByName.set('bundleselectorcontroller', BundleSelectorController);
blocksByName.set('addtocartbutton01', AddToCartButton01);
blocksByName.set('button01', useNewBlocks ? NewButton01 : Button01);
blocksByName.set('cancelcartbutton01', CancelCartButton01);
blocksByName.set('nextbutton01', useNewBlocks ? NewNextButton01 : NextButton01);
blocksByName.set('submitandcancelcartbutton01', SubmitAndCancelCartButton01);
blocksByName.set('submitcartbutton01', SubmitCartButton01);
blocksByName.set('espcard01', EspCard01);
blocksByName.set('cart01', Cart01);
blocksByName.set('changepassword01', changePassword01);
blocksByName.set('checkbox01', Checkbox01);
blocksByName.set('checkuserasmanager01', CheckUserAsManager01);
blocksByName.set('conversation01', Conversation01);
blocksByName.set('createnewfaq01', CreateNewFaq01);
blocksByName.set('createnewuser01', CreateNewUser01);
blocksByName.set('editcomment01', EditComment01);
blocksByName.set('editfaqteachbarista01', EditFAQTeachBarista01);
blocksByName.set('equipmentcheck01', EquipmentCheck01);
blocksByName.set('exitworkflow01', ExitWorkflow01);
blocksByName.set('copyfield01', CopyField01);
blocksByName.set('field01', Field01);
blocksByName.set('field02', Field02);
blocksByName.set('field03', Field03);
blocksByName.set('form01', Form01);
blocksByName.set('hero01', useNewBlocks ? NewHero01 : Hero01);
blocksByName.set('hero02', useNewBlocks ? NewHero02 : Hero02);
blocksByName.set('image01', useNewBlocks ? NewImage01 : Image01);
blocksByName.set('imageselect01', ImageSelect01);
blocksByName.set('loading01', useNewBlocks ? NewLoading01 : Loading01);
blocksByName.set('locationdeptrolepicker01', LocationDeptRolePicker01);
blocksByName.set('login01', Login01);
blocksByName.set('espressive01', useNewBlocks ? NewEspressive01 : Espressive01);
blocksByName.set('logo01', Logo01);
blocksByName.set('logo02', Logo02);
blocksByName.set('resetpasswordcode01', resetPasswordCode01);
blocksByName.set('resetpasswordmethod01', resetPasswordMethod01);
blocksByName.set('phonenumber01', phoneNumber01);
blocksByName.set('previewprofile01', PreviewProfile01);
blocksByName.set('previewprofilecontroller', PreviewProfileController);
blocksByName.set('productconfiguration01', ProductConfiguration01);
blocksByName.set('radio01', useNewBlocks ? NewRadio01 : Radio01);
blocksByName.set('radio02', Radio02);
blocksByName.set('radio03', Radio03);
blocksByName.set('roleselect01', roleSelect01);
blocksByName.set('searcharchetypeentity01', SearchArchetypeEntity01);
blocksByName.set('searchfaqarchetype01', SearchFAQArchetype01);
blocksByName.set('searchselectfaq01', searchSelectFAQ01);
blocksByName.set('skipteachbarista01', skipTeachBarista01);
blocksByName.set('selectdepartment01', SelectDepartment01);
blocksByName.set('selectdepartmentcategory01', SelectDepartmentCategory01);
blocksByName.set('selectfaq01', selectFaq01);
blocksByName.set(
  'matchfaqfromarchetypeentity01',
  matchFAQFromArchetypeEntity01
);
blocksByName.set('selectlanguage01', SelectLanguage01);
blocksByName.set('selectmobiletype01', SelectMobileType01);
blocksByName.set('selectostype01', SelectOSType01);
blocksByName.set('selectmygear01', SelectMyGear01);
blocksByName.set('selectserviceteam01', SelectServiceTeam01);
blocksByName.set('selectusercontactgroup01', SelectUserContactGroup01);
blocksByName.set('senddownloadappsms01', SendDownloadAppSMS01);
blocksByName.set('sendreport01', SendReport01);
blocksByName.set('setvaluefromkbsnowinfaq01', SetValueFromKBSnowInFAQ01);
blocksByName.set('skills01', useNewBlocks ? NewSkills01 : Skills01);
blocksByName.set('skiptask01', SkipTask01);
blocksByName.set('socialmedia01', socialMedia01);
blocksByName.set('softwareselect01', SoftwareSelect01);
blocksByName.set('softwareutilization01', SoftwareUtilization01);
blocksByName.set('spacer01', useNewBlocks ? NewSpacer01 : Spacer01);
blocksByName.set('systemavatars01', SystemAvatars01);
blocksByName.set('text01', useNewBlocks ? NewText01 : Text01);
blocksByName.set('addresponsetextarea01', AddResponseTextArea01);
blocksByName.set('textarea01', TextArea01);
blocksByName.set('descriptionfaq01', DescriptionFAQ01);
blocksByName.set('userselect01', UserSelect01);
blocksByName.set('updateentityvalueinstance01', UpdateEntityValueInstance01);
blocksByName.set('workaddress01', WorkAddress01);

const getBlockComponentByName = (name) => {
  if (!blocksByName.has(name)) {
    // eslint-disable-next-line no-console -- debugging
    console.warn(
      `No React component found for template '${name}'. Run 'gulp dev' and try again. If you are still seeing this message after that, make sure '${name}' doesn't have a typo and that it matches a block template component's name, or add an exception in /app/js/v1/components/Block.js`
    );
  }

  return blocksByName.get(name, null);
};

export default getBlockComponentByName;
