import React, { useCallback } from 'react';
import pt from 'prop-types';
import { Popover } from '@espressive/cascara/private';
import classNames from 'classnames/bind';
import styles from './Filter.module.scss';
import { Icon } from '@iconify/react';
import { Flex } from '@espressive/cascara';
import baselineArrowDropDown from '@iconify/icons-ic/baseline-arrow-drop-down';
import roundClose from '@iconify/icons-ic/round-close';

import { FILTER_OPERATORS } from './__globals';

const cx = classNames.bind(styles);

const propTypes = {
  index: pt.number.isRequired,
  isAdvanced: pt.bool,
  isSearching: pt.bool,
  parameter: pt.string,
  removeOperator: pt.func.isRequired,
  setOperatorValue: pt.func.isRequired,
  setType: pt.func.isRequired,
  type: pt.oneOf([...Object.keys(FILTER_OPERATORS)]),
  value: pt.string,
};

const FilterOperator = ({
  index,
  isAdvanced = false,
  isSearching = false,
  parameter,
  removeOperator,
  setOperatorValue,
  setType,
  type,
  value,
}) => {
  // const [operator, handleSetOperator] = useInputStateCB(type);

  const handleSetType = useCallback(
    (e) => {
      setType(e.target.value, index);
    },
    [index, setType]
  );

  const handleSetValue = useCallback(
    (e) => {
      setOperatorValue(e.target.value, index);
    },
    [index, setOperatorValue]
  );

  const handleRemoveOperator = useCallback(
    (e) => {
      removeOperator(index);
    },
    [index, removeOperator]
  );

  const trigger = (
    <div
      className={cx({
        Operator: true,
        advanced: isAdvanced,
        searching: isSearching,
      })}
    >
      {isAdvanced && (
        <span className={styles.Type} data-type={type} title={type}>
          {/* For now we are using the operator characters until we can get icons for everything. */}
          {FILTER_OPERATORS[type]}
        </span>
      )}
      <span className={styles.Attribute}>{parameter}</span>
      {isAdvanced && (
        <>
          {value && <span className={styles.Value}>{value}</span>}
          <Icon icon={baselineArrowDropDown} inline />
        </>
      )}
    </div>
  );

  return isAdvanced ? (
    <Popover
      aria-label='operator'
      className={styles.OperatorMenu}
      trigger={trigger}
    >
      <Flex vAlign='center'>
        <h6>{parameter}</h6>
        <button onClick={handleRemoveOperator} type='button'>
          <Icon icon={roundClose} inline />
        </button>
      </Flex>
      <select defaultValue={type} id='' name='' onBlur={handleSetType}>
        {Object.entries(FILTER_OPERATORS).map(([key, value]) => (
          <option key={value} value={key}>
            {key}
          </option>
        ))}
      </select>
      <input defaultValue={value} onBlur={handleSetValue} type='text' />
    </Popover>
  ) : (
    trigger
  );
};

FilterOperator.propTypes = propTypes;

export default FilterOperator;
