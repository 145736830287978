import _ from 'lodash';

import BlockBase from '../../utils/BlockBase';
import { processPropDefaults, processPropTypes } from '../../utils/typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'loading01',
  },
});

// Block definition used by blobifier
const LoadingBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Loading',
  templates: ['loading01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, LoadingBlockDefinition, TypeDefaults };
