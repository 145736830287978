import { fromJS } from 'immutable';
// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import caseMgmtThunks from '../../actions/caseMgmtThunks';
// Action
import caseMgmtActions from '../../actions/caseMgmtActions';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Selector
import getServiceMemberList from '../../selectors/getServiceMemberList';

const mapStateToProps = (state) => {
  const serviceTeam = state.hasIn(['caseMgmt', 'serviceTeam'])
    ? state.getIn(['caseMgmt', 'serviceTeam'])
    : fromJS({});

  const teamRole = state.getIn(['caseMgmt', 'teamRole']);

  return {
    isLoadingMemberList: serviceTeam.get('isLoading'),
    membersList: getServiceMemberList(state),
    teamLead: serviceTeam.getIn(['list', '0', 'team_lead']),
    teamRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewMember(formName, teamID, msg) {
    dispatch(caseMgmtThunks.addNewMemberToTeam(formName, teamID)).then(
      ({ alreadyExist = false }) => {
        if (alreadyExist) {
          dispatch(
            toastNotificationsActions.error({
              message: 'This member already exists in this team',
              title: 'Error',
            })
          );
        } else {
          dispatch(
            toastNotificationsActions.success({
              message: msg.message,
              title: msg.header,
            })
          );
        }
      }
    );
  },

  deleteMember(relationshipID, teamID) {
    dispatch(caseMgmtThunks.deleteRelationMember(relationshipID, teamID));
  },

  loadMembersList(teamID) {
    dispatch(caseMgmtThunks.loadTeamMembers(teamID));
  },

  startAddUser() {
    dispatch(caseMgmtActions.startAddMember());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
