import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
// molecules
import EspListItem from '../../molecules/EspListItem';
// utils and globals
import getDueDate from '../../../utils/getDueDate';
import OnboardActivitiesCategory from '../../../globals/OnboardActivitiesCategory';
import uiPathGenerator from '../../../utils/uiPathGenerator';
import { fromJS } from 'immutable';

const propTypes = {
  active: PropTypes.bool,
  item: PropTypes.shape({}),
};

const OffboardingActivityTemplateListItem = ({ active, item = {} }) => {
  let categoryImage = OnboardActivitiesCategory?.hasIn([
    item?.category,
    'image',
  ])
    ? OnboardActivitiesCategory?.getIn([item.category, 'image'])
    : null;

  if (!categoryImage) {
    categoryImage = null;
  }

  const ribbon = item?.status === 'ARCHIVED' ? 'ARCHIVED' : null;

  const ImgElement = OnboardActivitiesCategory?.getIn([item?.category, 'image'])
    ? OnboardActivitiesCategory.getIn([item.category, 'image'])
    : null;

  return (
    <EspListItem
      action={
        <Button
          as={Link}
          basic
          content='view'
          to={uiPathGenerator.genPath(
            'admin.adminActivities.offboarding.templates',
            {
              templateID: item.id,
            }
          )}
        />
      }
      active={active}
      attachedHeader={item.private_notes}
      description={item.description}
      header={item.title}
      image={ImgElement && <ImgElement />}
      key={item.eid + item.id}
      meta={getDueDate(fromJS(item))}
      ribbon={ribbon}
    />
  );
};

OffboardingActivityTemplateListItem.propTypes = propTypes;

export default OffboardingActivityTemplateListItem;
