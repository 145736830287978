import React from 'react';
import PropTypes from 'prop-types';
import { Form, Segment } from 'semantic-ui-react';

const FormSectionContainer = ({ isDisabled, children, style }) => (
  <Form as={Segment} basic>
    {children}
  </Form>
);

FormSectionContainer.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  style: PropTypes.shape({}),
};

FormSectionContainer.defaultProps = {
  children: null,
  isDisabled: false,
  style: {},
};

export default FormSectionContainer;
