import React, { useContext } from 'react';
import pt from 'prop-types';

import tenantContext from '../../context/tenant/tenantContext';

// NOTE: These styles are shared with the other auth forms in this package
import styles from '../Auth.module.scss';

const propTypes = {
  children: pt.oneOfType([pt.node, pt.arrayOf(pt.node)]),
};

const Page = ({ children }) => {
  const { tenant } = useContext(tenantContext) || {};

  const bgStyles = {
    backgroundColor: tenant?.branding_obj?.color_primary,
    backgroundImage: tenant?.images?.background
      ? `url(${tenant?.images?.background})`
      : undefined,
  };

  return (
    <main className={styles.Page} style={bgStyles}>
      {children}
    </main>
  );
};

Page.propTypes = propTypes;

export default Page;
