import Nodes from '../../../Nodes';
import ObjectTypes from '../../../ObjectTypes';

const nodesRules = {
  serialize(obj) {
    if (obj.object !== ObjectTypes.inline) {
      return null;
    }

    switch (obj.type) {
      case Nodes.email: {
        const content = obj.getIn(['data', 'content']);
        const email = obj.getIn(['data', 'email']);
        return `[${content}](mailto:${email})`;
      }

      case Nodes.link: {
        const content = obj.getIn(['data', 'content']);
        const href = obj.getIn(['data', 'href']);
        const openInNewWindow = obj.getIn(['data', 'openInNewWindow']);
        return `[${content}](${href})${
          openInNewWindow ? '{:target="_blank"}' : ''
        }`;
      }

      case Nodes.tag: {
        return obj.getIn(['data', 'tag']);
      }
      default:
        return null;
    }
  },
};

export default nodesRules;
