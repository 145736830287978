import React from 'react';
import PropTypes from 'prop-types';
import WindowDimensionContext from './WindowDimensionContext';
import useWindowDimensions from './useWindowDimensions';

const propTypes = {
  children: PropTypes.element,
};

const WindowDimensionProvider = ({ children }) => {
  const { height, width } = useWindowDimensions();
  const screenBreakpoints = {
    IP5: 321,
    LARGE: 1024,
    MEDIUM: 640,
    XLARGE: 1200,
    XXLARGE: 1368,
  };

  return (
    <WindowDimensionContext.Provider
      value={{
        height,
        screenBreakpoints,
        width,
      }}
    >
      {children}
    </WindowDimensionContext.Provider>
  );
};

WindowDimensionProvider.propTypes = propTypes;

export default WindowDimensionProvider;
