const adminReportFilterOptions = {
  ALL: 'ALL',
  ALL_TIME: 'ALL_TIME',
  ASCENDING: 'ascending',
  A_TO_Z: 'A_TO_Z',
  CHITCHAT: 'CHITCHAT',
  DAILY: 'DAILY',
  DESCENDING: 'descending',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_60_DAYS: 'LAST_60_DAYS',
  MONTHLY: 'MONTHLY',
  NCC: 'NONCHITCHAT',
  SUMMARY: 'SUMMARY',
  VALUE_25: '25',
  VALUE_50: '50',
  WEEKLY: 'WEEKLY',
  Z_TO_A: 'Z_TO_A',
};

export const SortKeywordsByOptions = [
  {
    key: adminReportFilterOptions.DESCENDING,
    text: 'Most Popular',
    value: adminReportFilterOptions.DESCENDING,
  },
  {
    key: adminReportFilterOptions.ASCENDING,
    text: 'Least Popular',
    value: adminReportFilterOptions.ASCENDING,
  },
];

export const SortPhrasesByOptions = [
  {
    key: adminReportFilterOptions.DESCENDING,
    text: 'Most Popular',
    value: adminReportFilterOptions.DESCENDING,
  },
  {
    key: adminReportFilterOptions.ASCENDING,
    text: 'Least Popular',
    value: adminReportFilterOptions.ASCENDING,
  },
  {
    key: adminReportFilterOptions.A_TO_Z,
    text: 'Phrases A-Z',
    value: adminReportFilterOptions.A_TO_Z,
  },
  {
    key: adminReportFilterOptions.Z_TO_A,
    text: 'Phrases Z-A',
    value: adminReportFilterOptions.Z_TO_A,
  },
];

export const displayOptions = {
  daily: {
    key: adminReportFilterOptions.DAILY,
    text: 'Daily',
    value: adminReportFilterOptions.DAILY,
  },
  monthly: {
    key: adminReportFilterOptions.MONTHLY,
    text: 'Monthly',
    value: adminReportFilterOptions.MONTHLY,
  },
  weekly: {
    key: adminReportFilterOptions.WEEKLY,
    text: 'Weekly',
    value: adminReportFilterOptions.WEEKLY,
  },
};

export const scheduledSurveyDisplayOptions = [
  {
    key: adminReportFilterOptions.DAILY,
    text: 'Daily',
    value: adminReportFilterOptions.DAILY,
  },
  {
    key: adminReportFilterOptions.WEEKLY,
    text: 'Weekly',
    value: adminReportFilterOptions.WEEKLY,
  },
];

export const notScheduledSurveyDisplayOptions = [
  {
    key: adminReportFilterOptions.DAILY,
    text: 'Daily',
    value: adminReportFilterOptions.DAILY,
  },
  {
    key: adminReportFilterOptions.WEEKLY,
    text: 'Weekly',
    value: adminReportFilterOptions.WEEKLY,
  },
  {
    key: adminReportFilterOptions.ALL_TIME,
    text: 'All Time',
    value: adminReportFilterOptions.ALL_TIME,
  },
];

export const timeOptions = [
  {
    key: adminReportFilterOptions.LAST_30_DAYS,
    text: 'Last 30 days',
    value: adminReportFilterOptions.LAST_30_DAYS,
  },
  {
    key: adminReportFilterOptions.LAST_60_DAYS,
    text: 'Last 60 days',
    value: adminReportFilterOptions.LAST_60_DAYS,
  },
];

export const showKeywordOptions = [
  {
    key: adminReportFilterOptions.VALUE_25,
    text: 'Top 25 keywords',
    value: adminReportFilterOptions.VALUE_25,
  },
  {
    key: adminReportFilterOptions.VALUE_50,
    text: 'Top 50 keywords',
    value: adminReportFilterOptions.VALUE_50,
  },
  {
    key: adminReportFilterOptions.ALL,
    text: 'All keywords',
    value: adminReportFilterOptions.ALL,
  },
];

export const showIntentsOptions = [
  {
    key: adminReportFilterOptions.VALUE_25,
    text: 'Top 25 topics',
    value: adminReportFilterOptions.VALUE_25,
  },
  {
    key: adminReportFilterOptions.VALUE_50,
    text: 'Top 50 topics',
    value: adminReportFilterOptions.VALUE_50,
  },
  {
    key: adminReportFilterOptions.ALL,
    text: 'All intents',
    value: adminReportFilterOptions.ALL,
  },
];

export const showKeywordsOptions = [
  {
    key: adminReportFilterOptions.VALUE_25,
    text: 'Top 25 Keywords',
    value: adminReportFilterOptions.VALUE_25,
  },
  {
    key: adminReportFilterOptions.VALUE_50,
    text: 'Top 50 Keywords',
    value: adminReportFilterOptions.VALUE_50,
  },
  {
    key: adminReportFilterOptions.ALL,
    text: 'All Keywords',
    value: adminReportFilterOptions.ALL,
  },
];

export const intentsTypeOptions = [
  {
    key: adminReportFilterOptions.CHITCHAT,
    text: 'Chit Chat',
    value: adminReportFilterOptions.CHITCHAT,
  },
  {
    key: adminReportFilterOptions.NCC,
    text: 'Regular',
    value: adminReportFilterOptions.NCC,
  },
  {
    key: adminReportFilterOptions.ALL,
    text: 'All',
    value: adminReportFilterOptions.ALL,
  },
];

export const intentsDisplayOptions = {
  daily: {
    key: adminReportFilterOptions.DAILY,
    text: 'Yesterday VS Today',
    value: adminReportFilterOptions.DAILY,
  },
  monthly: {
    key: adminReportFilterOptions.MONTHLY,
    text: 'Last Month VS This Month',
    value: adminReportFilterOptions.MONTHLY,
  },
  weekly: {
    key: adminReportFilterOptions.WEEKLY,
    text: 'Last week VS This week',
    value: adminReportFilterOptions.WEEKLY,
  },
};

export default adminReportFilterOptions;
