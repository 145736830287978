import React, { useCallback } from 'react';
import pt from 'prop-types';
import { Button, Image, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { fromJS } from 'immutable';

import UserImg from '../../../../../v2/components/functional/UserImg/UserImgV2';

// Molecule
import MessageEmptyState from '../../../molecules/MessageEmptyState';
import OnboardProgressBar from '../../../molecules/OnboardProgressBar';

// Utils
import uiPathGenerator from '../../../../utils/uiPathGenerator';
import injectIntlToController from '../../../../utils/injectIntlToController';
import dateFormat from '../../../../utils/dateFormat';

// Global
import OnboardingActivityOverallState from '../../../../globals/OnboardingActivityOverallState';
import DepartingEmployeeStatus from '../../../../globals/DepartingEmployeeStatus';

const propTypes = {
  archiveOnePerformance: pt.func,
  escalateProgress: pt.func,
  isLoading: pt.bool,
  isUserIDEscalating: pt.number,
  loadingDeleteID: pt.number,
  query: pt.shape({
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    show: pt.any,
  }),
  tableList: pt.arrayOf(pt.shape({})),
};

const OffboardingPerformanceTableList = ({
  archiveOnePerformance,
  escalateProgress,
  isUserIDEscalating,
  isLoading,
  loadingDeleteID,
  query,
  tableList,
}) => {
  const renderBodyRow = useCallback(
    (
      {
        id,
        user,
        overall_state,
        delinquent_state_count,
        normal_state_count,
        overdue_state_count,
        scheduled_state_count,
        warning_state_count,
        complete_state_count,
        user_start_date_time,
        state,
      },
      i
    ) => {
      let linkTo = uiPathGenerator.genPath(
        'admin.adminActivities.offboarding.performance',
        {
          userID: user.id,
        }
      );
      linkTo = `${linkTo}?type=${query.show || DepartingEmployeeStatus}`;
      const username = `${user.nickname} ${user.last_name}`;

      const activitiesProgress = fromJS({
        completed: complete_state_count,
        total:
          delinquent_state_count +
          normal_state_count +
          overdue_state_count +
          scheduled_state_count +
          warning_state_count +
          complete_state_count,
      });

      const disableEscalate =
        overdue_state_count + delinquent_state_count === 0 ||
        overall_state === OnboardingActivityOverallState.ONTRACK ||
        activitiesProgress.total - activitiesProgress.complete_state_count ===
          0;

      const escalateLoading = user && user.id === isUserIDEscalating;

      const handleEscalate = (userID) => () => {
        escalateProgress(userID);
      };

      const handleDelete = (perfID) => () => {
        archiveOnePerformance(perfID);
      };

      const deletedLoading = loadingDeleteID === id;
      return {
        cells: [
          <Table.Cell key={id}>
            <Link to={linkTo}>
              <Image
                size='mini'
                style={{
                  marginRight: '.5em',
                }}
              >
                <UserImg user={user} />
              </Image>
              {username}
            </Link>
          </Table.Cell>,
          <Table.Cell key={`${id}progress`}>
            <OnboardProgressBar
              as={Link}
              key={user.id}
              label={overall_state}
              overallState={overall_state}
              progress={activitiesProgress}
              to={linkTo}
            />
          </Table.Cell>,
          user_start_date_time ? dateFormat(user_start_date_time) : '',
          <Table.Cell key={`${id}buttons`}>
            <Button.Group basic>
              <Button as={Link} content='View' to={linkTo} />
              <Button
                content='Escalate'
                disabled={disableEscalate}
                loading={escalateLoading}
                onClick={handleEscalate(id)}
              />
              <Button
                content='Archive'
                loading={deletedLoading}
                onClick={handleDelete(id)}
              />
            </Button.Group>
          </Table.Cell>,
        ],
        key: id || `row-${i}`,
      };
    },
    [
      archiveOnePerformance,
      escalateProgress,
      loadingDeleteID,
      isUserIDEscalating,
      query.show,
    ]
  );
  return (
    <section style={{ marginBottom: '1em' }}>
      {!isLoading && (!tableList || tableList.length === 0) ? (
        <MessageEmptyState
          content={'Try another filter'}
          header={'No performances available with this filters'}
        />
      ) : (
        <Table
          columns={4}
          compact
          headerRow={['Employee', 'Status', 'Start Date', '']}
          renderBodyRow={renderBodyRow}
          striped
          tableData={tableList}
        />
      )}
    </section>
  );
};

OffboardingPerformanceTableList.propTypes = propTypes;

export default injectIntlToController(OffboardingPerformanceTableList);
