// Globals

import { Button, Form } from 'semantic-ui-react';
import React, { useContext } from 'react';

import BodyClassName from '../../../../globals/BodyClassName';
import { Boundaries } from 'cascara-middleware';
import ChatBotIcon from '../../../../globals/ChatBotIcon';
// Molecules
import EspMetaVariables from '../../molecules/EspMetaVariables';
import FirstBubble from './FirstBubble';
import { Formik } from 'formik';
import GlobalContext from '../../../../globals/GlobalContext';
import NBubble from './NBubble';
import PropTypes from 'prop-types';

const propTypes = {
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleEditChange: PropTypes.func,
  isEditable: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
  noMetaText: PropTypes.bool,
  template: PropTypes.string,
  type: PropTypes.string,
};

const Barista01 = ({
  messages,
  noMetaText = false,
  template = 'barista01',
  type = 'barista',
  isEditable = false,
  handleDelete = () => {},
  handleEdit = () => {},
  handleEditChange = () => {},
}) => {
  const newMessages = [...messages];
  newMessages.splice(1, 0, {
    text: '',
  });

  const {
    tenant: { tenantInfo },
  } = useContext(GlobalContext);

  const bubbleColor = tenantInfo?.branding_obj?.help_message_color ?? '#d3f4ef';

  const formEditStyle = {
    position: 'absolute',
    right: '-5em',
    top: 0,
  };

  const editStyle = {
    float: 'right',
    overflow: 'hidden',
    position: 'relative',
    top: 0,
  };

  const getBody = (message) => {
    if (noMetaText) {
      return message.text;
    }
    if (message.isEditable) {
      return (
        <Formik
          enableReinitialize
          initialValues={{
            [`_${message.id}`]: message.text,
          }}
        >
          {({ values, handleChange }) => {
            return (
              <div>
                <Form as='div'>
                  <Form.TextArea
                    id={`_${message.id}`}
                    name={`_${message.id}`}
                    onChange={handleChange}
                    rows={6}
                    value={values[`_${message.id}`]}
                  />
                </Form>

                {isEditable && message.isEditable && (
                  <div style={formEditStyle}>
                    <Button
                      basic
                      data-bubbleid={message.id}
                      icon='cancel'
                      onClick={handleEdit}
                      type='button'
                    />

                    <Button
                      basic
                      data-bubbleid={message.id}
                      data-bubblevalue={values[`_${message.id}`]}
                      disabled={!values[`_${message.id}`]}
                      icon='check'
                      onClick={handleEditChange}
                      type='button'
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Formik>
      );
    }
    return <EspMetaVariables isBarista text={message.text} />;
  };

  return (
    <BodyClassName className='blocks-justify'>
      <div className='block' data-blocktype={type} data-template={template}>
        {newMessages.map((message, index) => {
          if (message.text === void 0) {
            // Do not try to render wrong data from package
            return null;
          }
          return (
            <Boundaries key={`${message.text}-${index}`}>
              <div className={message.text.length > 0 ? 'section' : ''}>
                {message.text.length > 0 ? (
                  <div>
                    <div
                      className='bubble'
                      data-testid={`bubble.${index}`}
                      style={{
                        backgroundColor: bubbleColor,
                      }}
                    >
                      {getBody(message)}
                      {index === 0 && <FirstBubble color={bubbleColor} />}
                      {index > 0 && (
                        <NBubble color={bubbleColor} isEven={index % 2 !== 0} />
                      )}
                    </div>

                    {isEditable && message.text && !message.isEditable && (
                      <div style={editStyle}>
                        <Button
                          basic
                          data-bubbleid={message.id}
                          icon='pencil'
                          onClick={handleEdit}
                        />
                        <Button
                          basic
                          data-bubbleid={message.id}
                          icon='trash'
                          negative
                          onClick={handleDelete}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <ChatBotIcon size='large' />
                )}
              </div>
            </Boundaries>
          );
        })}
      </div>
    </BodyClassName>
  );
};

Barista01.propTypes = propTypes;

export default Barista01;
