import { Map } from 'immutable';

let cache = Map();

// TODO Warning! this is very memory intensive and could cause memory leaks if abused, but keeps the directory rendering pretty fast.
// Probably we need to pass the user and isFavorite as separate props to avoid copying the user over and over.
const getDirectoryUser = (user, isFavorited) => {
  const id = user.get('id');
  const cacheEntry = cache.get(id, null);

  if (
    cacheEntry &&
    cacheEntry.get('user') === user &&
    cacheEntry.get('isFavorited') === isFavorited
  ) {
    return cacheEntry.get('directoryUser');
  }

  const directoryUser = user.set('isFavorited', isFavorited);

  const newCacheEntry = Map()
    .set('user', user)
    .set('isFavorited', isFavorited)
    .set('directoryUser', directoryUser);

  cache = cache.set(id, newCacheEntry);

  return directoryUser;
};

export default getDirectoryUser;
