import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import Immutableproptypes from 'react-immutable-proptypes';
import _ from 'lodash';
import immutable from 'immutable';

// Molecules
import EquipmentCheckItem from '../../molecules/EquipmentCheckItem';

// Globals
import TypeEquipmentCheck, {
  TypeDefaults,
} from '../../../globals/blocks/TypeEquipmentCheck';

// Controller
import EquipmentCheckController from '../../controllers/EquipmentCheckController';

let EquipmentCheck01 = class EquipmentCheck01 extends Component {
  static propTypes = {
    ...TypeEquipmentCheck,
    equipments: Immutableproptypes.list,
    /** Value of the different Contacts */
    formValues: Immutableproptypes.map,
    initialize: PropTypes.func,
    submitEquipment: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    formValues: immutable.Map(),
  };

  componentDidMount() {
    const { initialize, setAlternateSubmit, id } = this.props;
    initialize(id);
    setAlternateSubmit(this.handleSubmit);
  }

  handleSubmit = (cb = _.noop) => {
    const { equipments, formValues, id, submitEquipment } = this.props;
    submitEquipment(id, equipments, formValues, cb);
  };

  render() {
    const { equipments, id, template, type } = this.props;

    return (
      <div
        className='block esp-equipment-check'
        data-blocktype={type}
        data-template={template}
      >
        {equipments && (
          <List divided relaxed='very'>
            {equipments.map((equipment, i) => (
              <Field
                autoComplete='off'
                autoCorrect='off'
                component={EquipmentCheckItem}
                equipment={equipment}
                id={id + i}
                key={`fwf${equipment.id}`}
                label={id + i}
                name={id + i}
              />
            ))}
          </List>
        )}
      </div>
    );
  }
};
const EquipmentCheck01Test = EquipmentCheck01;

EquipmentCheck01 = EquipmentCheckController(EquipmentCheck01);
export { EquipmentCheck01Test };
export default EquipmentCheck01;
