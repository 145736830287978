import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Icon } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import EditorWorkflowTaskForm from '../molecules/EditorWorkflowTaskForm';
import ModalEsp from '../../../../../admin/js/v1/components/organisms/ModalEsp';
// Controllers
import WorkflowModalTaskController from '../controllers/WorkflowModalTaskController';
// Utils
import injectIntlToController from '../../utils/injectIntlToController';

class WorkflowTaskModal extends PureComponent {
  static propTypes = {
    form: ImmutablePropTypes.map,
    initialValues: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool,
    task: ImmutablePropTypes.map,
    updateEditingTaskName: PropTypes.func.isRequired,
  };

  static defaultProps = {
    form: null,
    isLoading: false,
    task: null,
  };

  handleAccept = (close) => {
    const { form, task, updateEditingTaskName } = this.props;
    const name = form.getIn(['values', 'name']);
    const errors = form.get('syncErrors');
    if (errors) {
      return;
    }
    if (name && name !== task.get('name')) {
      updateEditingTaskName(task, name);
    }
    close();
  };

  handleSubmit = (value, close) => {
    this.handleAccept(close);
  };

  render() {
    const { task, initialValues, isLoading } = this.props;

    const trigger = () => <Icon link name='write' />;

    return (
      <ModalEsp
        acceptContent='Save'
        headerContent='Edit task'
        headerIcon='list layout'
        isLoading={isLoading}
        onAccept={this.handleAccept}
        trigger={trigger()}
      >
        {task && task.get('name') ? (
          <ModalEsp.Form onSubmit={this.handleSubmit}>
            <EditorWorkflowTaskForm initialValues={initialValues} />
          </ModalEsp.Form>
        ) : null}
      </ModalEsp>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowTaskModal = WorkflowModalTaskController(WorkflowTaskModal);
// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowTaskModal = injectIntlToController(WorkflowTaskModal);

export default WorkflowTaskModal;
