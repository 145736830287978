import { noop } from 'lodash';
import faqThunks from '../../../../../actions/faqThunks';
import baristaSearchConditionsThunks from '../../../../../actions/caseMgmtThunks/baristaSearchConditionsThunks';
import buildCompositeController from '../../../../../utils/buildCompositeController';
import caseManagementThunks from '../../../../../actions/caseMgmtThunks';
import getCaseManagementLists from '../../../../../selectors/getCaseManagementLists';
import toastNotificationsActions from '../../../../../actions/toastNotificationsActions';

const LOAD_LIST_DEFAULT_PROPERTIES = {
  limit: 1000,
  offset: 0,
  orderBy: '-sys_date_created',
};

const mapDispatchToProps = (dispatch) => ({
  deleteCondition(conditionID, serviceDepartmentEID) {
    dispatch(
      baristaSearchConditionsThunks.deleteCondition(
        conditionID,
        serviceDepartmentEID
      )
    )
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: 'Condition succesfully deleted',
            title: 'Success',
          })
        );
      })
      .catch(({ message }) => {
        dispatch(
          toastNotificationsActions.error({
            message: message,
            title: 'Error',
          })
        );
      });
  },

  loadIntentsList(query = '') {
    return dispatch(
      faqThunks.loadTopFaqMatches({
        limit: 5,
        query,
      })
    );
  },

  loadServiceDepartmentCategoriesList(departmentID) {
    return dispatch(
      caseManagementThunks.loadTaskCategories({
        ...LOAD_LIST_DEFAULT_PROPERTIES,
        departmentID,
      })
    );
  },

  loadServiceDepartmentConditionsList(serviceDepartmentEID) {
    return dispatch(
      baristaSearchConditionsThunks.loadConditions(serviceDepartmentEID)
    );
  },

  loadServiceDepartmentTeamList(serviceDepartmentID) {
    return dispatch(
      caseManagementThunks.LoadTeamByDepartment({
        ...LOAD_LIST_DEFAULT_PROPERTIES,
        serviceDepartmentID,
      })
    );
  },

  loadTaskTypes() {
    return dispatch(caseManagementThunks.loadTaskTypes());
  },

  swapCondition(condition1, condition2) {
    dispatch(
      baristaSearchConditionsThunks.swapCondition(
        condition1.get('eid'),
        condition2.get('eid')
      )
    ).then(noop, (error) => {
      dispatch(
        toastNotificationsActions.error({
          message: error.message,
          title: 'An error has ocurred',
        })
      );
    });
  },

  toggleCondition(conditionID, isActive) {
    dispatch(
      baristaSearchConditionsThunks.toggleCondition(conditionID, isActive)
    );
  },
});

const mapStateToProps = (state) => ({
  ...getCaseManagementLists(state),
  conditionsServiceDepartmentEID: state.getIn([
    'caseMgmt',
    'baristaSearchConditions',
    'serviceDepartmentEID',
  ]),
  intentsList: state.getIn(['cases', 'baristaSearchResults']),
  serviceDepartmentList: state.getIn(['caseMgmt', 'serviceDepartment', 'list']),
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
