import { isString, upperFirst } from 'lodash';
import React, { PureComponent } from 'react';

import EspListItem from './EspListItem';
import { intl } from 'esp-util-intl';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextHighlight from '../atoms/TextHighlight';
import TextUtils from '../../utils/TextUtils';
import uiPathGenerator from '../../utils/uiPathGenerator';
import UserImageContainer from './UserImageContainer';

class UserSearchResult extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    result: ImmutablePropTypes.map.isRequired,
    routeToResult: PropTypes.string.isRequired,
    routeToResultIncludesQ: PropTypes.bool.isRequired,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    active: false,
    onClick: () => {},
  };

  handleClickUser = () => {
    const { onClick } = this.props;
    onClick();
  };

  // de la Sensualite
  pascalize(s) {
    const pascalized = s.split(/_+/).map(upperFirst).join(' ').trim();

    return pascalized;
  }

  render() {
    const { active, result, routeToResult, routeToResultIncludesQ, userInput } =
      this.props;

    const imageEl = <UserImageContainer avatar userId={result.get('pk')} />;

    // TODO Use UserName and TextHighlight components together here
    const name = `${result.get('nickname')} ${result.get('last_name')}`;

    const nameEl = <TextHighlight term={userInput} text={name} />;

    const title = result.get('title') || '';

    const titleEl = <TextHighlight term={userInput} text={title} />;

    const email = result.get('email');

    const emailEl = isString(email) && (
      <TextHighlight term={userInput} text={email} />
    );

    let descriptionEl = null;

    const nameAndTitleDontMatch =
      !TextUtils.hasStartsWithMatches(name, userInput) &&
      !TextUtils.hasStartsWithMatches(title, userInput);

    if (nameAndTitleDontMatch) {
      const department = result.getIn(['department', 'name']) || '';

      if (TextUtils.hasStartsWithMatches(department, userInput)) {
        descriptionEl = (
          <span>
            {intl.formatMessage({
              id: 'label.department',
            })}
            {': '}
            <TextHighlight as='span' term={userInput} text={department} />
          </span>
        );
      }

      const location = result.get('location');
      let locationAncestry = Immutable.Map();

      if (location && location.get('ancestry')) {
        locationAncestry = location.get('ancestry');
      }

      const locationMatch = locationAncestry.findEntry((locationName, key) => {
        // skip special 'ancestry_id_list' field from ancestry, BE uses it for performance reasons
        if (key === 'ancestry_id_list') {
          return false;
        }

        return TextUtils.hasStartsWithMatches(locationName, userInput);
      });

      if (!descriptionEl && locationMatch) {
        const [locationType, locationName] = locationMatch;

        descriptionEl = (
          <span>
            {this.pascalize(locationType)}
            {': '}
            <TextHighlight as='span' term={userInput} text={locationName} />
          </span>
        );
      }
    }

    const urlToResult = uiPathGenerator.genPath(routeToResult, {
      userID: result.get('pk'),
    });
    let toResult = urlToResult;

    if (routeToResultIncludesQ) {
      toResult = {
        pathname: urlToResult,
        search: `?q=${userInput}`,
      };
    }

    return (
      <EspListItem
        active={active}
        as={Link}
        content={titleEl}
        description={descriptionEl}
        email={emailEl}
        image={imageEl}
        isSearchResult
        name={nameEl}
        onClick={this.handleClickUser}
        to={toResult}
      />
    );
  }
}

export default UserSearchResult;
