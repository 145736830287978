import buildCompositeController from '../../utils/buildCompositeController';
import integrationThunks from '../../actions/integrationThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import csvParsingThunks from '../../actions/csvParsingThunks';

const mapStateToProps = (store, ownProps) => {
  const integrationStore = store.get('integration');
  const isLoadingImportHistory = integrationStore.get('isLoadingImportHistory');
  const importHistoryResults = integrationStore.get('importHistoryResults');
  const importHistoryResultsCount = integrationStore.get(
    'importHistoryResultsCount'
  );
  const invitesImportId = integrationStore.get('invitesImportId');
  const invitesError = integrationStore.get('invitesError');

  const csvParsingStore = store.get('integration');
  const isLoadingCancelParsing =
    csvParsingStore && csvParsingStore.get('isLoadingCVSParsing');
  const errorAtParsing =
    csvParsingStore && csvParsingStore.get('errorAtCSVParsing');

  const page =
    ownProps.params && ownProps.params.page ? Number(ownProps.params.page) : 1;

  return {
    errorAtParsing,
    importHistoryResults,
    importHistoryResultsCount,
    invitesError,
    invitesImportId,
    isLoadingCancelParsing,
    isLoadingImportHistory,
    isSendingInvites: store.getIn(['integration', 'isSendingInvites']),
    page,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelCSVParsing: (id) => {
    dispatch(csvParsingThunks.cancelCSVParsing(id))
      .then(({ body }) => {
        dispatch(
          toastNotificationsActions.success({
            message: body.result,
          })
        );
      })
      .catch((err) => {
        if (err) {
          dispatch(
            toastNotificationsActions.error({
              message: err,
            })
          );
        }
      });
  },
  loadHistory: (page = 1, limit = 24) => {
    /** Offset logic */
    const offset = (page - 1) * limit;
    dispatch(
      integrationThunks.loadHistory({
        limit,
        offset,
      })
    );
  },
  sendInvites: () => {
    dispatch(integrationThunks.sendInvites())
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: 'Invite notifications sent',
            title: 'Success!',
          })
        );
      })
      .catch(({ messages }) => {
        if (messages && messages[0]) {
          dispatch(
            toastNotificationsActions.error({
              message: messages[0],
              title: 'Error sending invites',
            })
          );
        }
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
