import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getAllCategories = (state) => state.getIn(['catalog', 'categories']);

const getCategoryIDToNameMapping = (categories) => {
  if (categories.isEmpty()) {
    return null;
  }

  // build index categoryID: Number -> categoryName: String
  const categoryIDToNameMapping = categories.reduce((mapping, category) => {
    const categoryID = category.get('id');
    const categoryName = category.get('name');

    return mapping.set(categoryID, categoryName);
  }, Immutable.Map());

  return categoryIDToNameMapping;
};

const selector = createSelector([getAllCategories], getCategoryIDToNameMapping);

export default selector;
