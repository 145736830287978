// Keys in localStorage that are used by the client frame
// for handling post login redirects and communication with the parent frame
// See sessionThunks.js for how these are handled
// See LoginController and LoginPage for how these are set up

const CLIENT_WIDGET_FRAME = 'client_widget_frame';
const CLIENT_WIDGET_FRAME_REDIRECT = 'client_widget_frame_redirect';

const CLIENT_TEAMS_FRAME = 'isMSTeamsFrame';

const CLIENT_CHAT_V2 = 'client_chat_v2';

// event name for communicating with the parent frame when app runs as a widget
const EVENT_WIDGET_AUTH_POPUP = 'event_widget_auth_popup';
const EVENT_WIDGET_AUTH_REDIRECT = 'event_widget_auth_redirect';

export {
  CLIENT_CHAT_V2,
  CLIENT_TEAMS_FRAME,
  CLIENT_WIDGET_FRAME,
  EVENT_WIDGET_AUTH_POPUP,
  EVENT_WIDGET_AUTH_REDIRECT,
  CLIENT_WIDGET_FRAME_REDIRECT,
};
