const getClientID = () =>
  new Promise((resolve) => {
    const FEVersion = {
      branch: document.querySelector('meta[name="branch"]')
        ? document.querySelector('meta[name="branch"]').getAttribute('content')
        : null,
      build: document.querySelector('meta[name="build"]')
        ? document.querySelector('meta[name="build"]').getAttribute('content')
        : null,
    };

    FEVersion.branch =
      FEVersion.branch !== '{{BRANCH}}' ? FEVersion.branch : null;
    FEVersion.build =
      FEVersion.build !== '{{BUILDID}}' ? FEVersion.build : null;
    resolve(
      FEVersion.branch && FEVersion.build
        ? `${FEVersion.branch}/${FEVersion.build}`
        : null
    );
  });

export default getClientID;
