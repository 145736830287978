import _ from 'lodash';
import { imagesResizeTools } from 'esp-globals';

import buildCompositeController from '../../utils/buildCompositeController';

// Redux
import catalogThunks from '../../actions/catalogThunks';
import catalogActions from '../../actions/catalogActions';
import adminBundleThunks from '../../actions/adminBundleThunks';
import bundleAction from '../../actions/bundleActions';

const mapDispatchToProps = (dispatch, { images }) => ({
  deleteBundleImage(imageID, bundleID) {
    dispatch(adminBundleThunks.deleteBundleImage(imageID, bundleID)).then(
      () => {
        dispatch(adminBundleThunks.loadBundleDetailAdmin(bundleID));
      }
    );
  },

  deleteBundleTmpImage(imageID) {
    dispatch(bundleAction.deleteTmpImage(imageID));
  },

  deleteProductImage(imageID, productID) {
    dispatch(catalogThunks.deleteProductImage(imageID, productID));
  },

  deleteTmpProductImage(imageID) {
    dispatch(catalogActions.deleteTmpImage(imageID));
  },

  loadProduct(productID) {
    const forceReload = true;
    dispatch(catalogThunks.loadOneProduct(productID, forceReload));
  },
  onAttachmentChanged(productID, e) {
    e.preventDefault();

    const [file] = e.target.files,
      reader = new FileReader();

    reader.onloadend = (img) => {
      const image = new window.Image();

      image.onload = function () {
        const img = {
          id: productID,
          ordering: images.size,
          picture_960: imagesResizeTools.createImg(this, 960),
        };

        const finalData = imagesResizeTools.convertFormData(img);

        dispatch(catalogThunks.addProductImage(finalData));
      };
      image.src = img.target.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },
  onAttachmentChangedBundle(bundleID, e) {
    e.preventDefault();

    const [file] = e.target.files,
      reader = new FileReader();

    reader.onloadend = (img) => {
      const image = new window.Image();

      image.onload = function () {
        const img = {
          id: bundleID,
          picture_960: imagesResizeTools.createImg(this, 480),
        };

        const finalData = imagesResizeTools.convertFormDataBundle(img);

        dispatch(
          adminBundleThunks.addBundleImage(finalData, () => {
            dispatch(adminBundleThunks.loadBundleDetailAdmin(bundleID));
          })
        );
      };
      image.src = img.target.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },
  onAttachmentChangedBundleTmp(e) {
    e.preventDefault();

    const [file] = e.target.files,
      reader = new FileReader();

    reader.onloadend = (img) => {
      dispatch(
        bundleAction.saveTmpImage({
          id: _.random(0, 99999),
          image_480: img.target.result,
        })
      );
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },

  onAttachmentChangedTmp(e) {
    e.preventDefault();

    const [file] = e.target.files,
      reader = new FileReader();

    reader.onloadend = (img) => {
      dispatch(
        catalogActions.saveTmpImage({
          id: _.random(0, 99999),
          image_480: img.target.result,
        })
      );
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },

  orderImages(productID = null, images) {
    if (images.size > 0) {
      dispatch(catalogThunks.orderImages(productID, images));
    }
  },

  orderTempImages(images) {
    if (images.size > 0) {
      dispatch(catalogActions.replaceTmpImage(images));
    }
  },
});

export default buildCompositeController(null, mapDispatchToProps);
