import Immutable from 'immutable';
import { createSelector } from 'reselect';

const getSchedule = (state, scheduleID) =>
  state
    .getIn(['onboardActivity', 'schedule', 'list'])
    .find((a) => a.get('id').toString() === scheduleID);

const getScheduleGroupOrdered = (schedule) => {
  if (!schedule || schedule.isEmpty()) {
    return Immutable.Map();
  }

  // Order Group by ID (the lower should be the older)
  const scheduleGrp = schedule.get('groups') || Immutable.List();
  const groups = scheduleGrp.sort((grpA, grpB) => {
    if (grpA > grpB) {
      return 1;
    } else if (grpA < grpB) {
      return -1;
    }
    return 0;
  });

  return schedule.set('groups', groups);
};

const selector = createSelector([getSchedule], getScheduleGroupOrdered);

export default selector;
