import async from 'async';
import { filter, find, isUndefined, map, noop, pick, some } from 'lodash';
import moment from 'moment';
import { change } from 'redux-form/immutable'; // The action creator
import { intl } from 'esp-util-intl';

// Global
import AnnouncementState from '../globals/AnnouncementState';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import announcementActions from './announcementActions';
import {
  ACTION_TYPES,
  ANNOUNCEMENT_TYPES,
} from '../globals/announcementCustomActionConstants';
import getUTCDays from '../utils/getUTCDays';
import { fetchSlackChannels } from '../components/organisms/SlackAudienceSection/api';
import { repeatTypes } from '../components/organisms/AnnouncementManage';

const announcementThunks = {};

const serializeAction = (action) => {
  const { button_text, text, type } = action;

  // backend is not cleaning garbage params and blows if you pass any other field than the
  // ones to pick
  const baseFieldsToPick = [
    'button_text',
    'button_type',
    'is_button_positive',
    'type',
  ];

  const addField = {
    [ACTION_TYPES.INTENT]: 'intent_eid',
    [ACTION_TYPES.URL]: 'action_url',
  }[type];

  const fieldsToPick = addField
    ? [...baseFieldsToPick, addField]
    : baseFieldsToPick;

  const result = pick(
    {
      ...action,
      button_text: button_text || text,
      // right now there is a mismatch in the properties for simple and multiple actions
    },
    fieldsToPick
  );
  return result;
};

announcementThunks.loadSlackChannels = () => (dispatch) =>
  new Promise((resolve, reject) => {
    /**
     * @typedef {Object} SlackChannelsOptions
     * @property {string} key
     * @property {string} text
     * @property {string} value
     */
    /**
     *
     * @param {Array<{Object}>} slackWorkgroups
     * @returns {Array<SlackChannelsOptions>}
     */
    const fromSlackWorkgroupsToArray = (slackWorkgroups = []) => {
      const channelMap = new Map();
      return slackWorkgroups.reduce((newChannels, workgroup) => {
        workgroup.channels
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
          .forEach((channel) => {
            const isNotDuplicated = !channelMap.get(channel.id);
            if (isNotDuplicated) {
              channelMap.set(channel.id, workgroup.name);
              newChannels.push({
                key: channel.id,
                text: `${workgroup.name}/${channel.name}`,
                value: channel.id,
              });
            }
          });
        return newChannels;
      }, []);
    };

    const fetchData = async () => {
      try {
        const { body } = await fetchSlackChannels();
        const channels = body?.slack_channels ?? [];
        dispatch(
          announcementActions.setSlackChannelsSuccess({
            channels,
            isSlackConfigured: channels?.length > 0,
            slackChannelsOptions: fromSlackWorkgroupsToArray(channels),
          })
        );
        dispatch(announcementActions.slackChannelsEnd());
        resolve();
      } catch (error) {
        // eslint-disable-next-line no-console -- debugging purposes
        console.error(error);
        dispatch(announcementActions.slackChannelsEnd());
        reject();
      }
    };
    dispatch(announcementActions.slackChannelsStart());
    fetchData();
  });

announcementThunks.testAnnouncement =
  ({ emails, id }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(announcementActions.testAnnouncementsStart());

      const url = endpointGenerator.genPath(
        'espNotification.notification.send',
        {
          notificationID: id,
        }
      );

      const data = {
        test_email_addresses: emails,
      };

      APIcall.post({
        data,
        error(error) {
          dispatch(announcementActions.testAnnouncementsFail());
          reject(error);
        },
        success({ body: { unknown_test_recipients = [] } }) {
          const validStatus = 200;
          const invalidStatus = 401;
          if (unknown_test_recipients.length > 0) {
            const actionData = {
              invalidEmails: unknown_test_recipients,
              status: invalidStatus,
            };
            dispatch(announcementActions.testAnnouncementsSuccess(actionData));
            resolve(actionData);
          } else {
            const actionData = {
              status: validStatus,
            };
            dispatch(announcementActions.testAnnouncementsSuccess(actionData));
            resolve(actionData);
          }
        },
        token: true,
        url,
      });
    });

announcementThunks.loadAnnouncementsByStatus =
  (status) => (dispatch, getState) => {
    const state = getState();
    const paginatorNext = state.getIn([
      'announcement',
      'pagination',
      status,
      'next',
    ]);

    const url = paginatorNext
      ? paginatorNext
      : endpointGenerator.genPath('espNotification.getAll');
    dispatch(announcementActions.loadAnnouncementsStart(status));
    return APIcall.get({
      error(error) {
        dispatch(
          announcementActions.loadAnnouncementsFail(error.message, status)
        );
      },
      query: {
        esp_filters: `status__EQ=${status}`,
        order_by: '-sys_date_updated', // Most recent updated first
      },
      success(res) {
        const { body } = res;
        const announcements = body.results;
        const pagination = {
          next: body.next,
          prev: body.previous,
        };
        const { count } = body;

        dispatch(
          announcementActions.loadAnnouncementsSuccess(
            announcements,
            status,
            pagination,
            count
          )
        );
      },
      token: true,
      url: url,
    });
  };

announcementThunks.loadOneAnnouncement = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const getAnnouncement = (next) => {
      dispatch(announcementActions.loadOneAnnouncementStart(id));
      return APIcall.get({
        error(error) {
          next(error);
        },
        success(res) {
          const announcement = res.body;
          next(null, announcement);
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.notification', {
          notificationID: id,
        }),
      });
    };
    const getProcessingTime = (annoucement, next) => {
      const url = endpointGenerator.genPath('espNotification.processingTime');
      let userCount = 0;
      const groups = annoucement?.groups;
      if (groups) {
        groups.forEach((group) => {
          userCount += group.user_count;
        });
      }
      if (userCount > 0) {
        APIcall.get({
          error(error) {
            next(error);
          },
          query: { user_count: userCount },
          success({ body }) {
            annoucement.time_limit = body;
            next(null, annoucement);
          },
          token: true,
          url,
        });
      } else {
        next(null, annoucement);
      }
    };
    const getAllUsersInGroups = (annoucement, next) => {
      const userGroups = [];
      const groups = annoucement?.groups;
      if (groups) {
        groups.forEach((group) => {
          if (group.adhoc_group > 0) {
            const membersCountURL = endpointGenerator.genPath(
              'espUserGroups.groups.instance.members_count',
              {
                group_id: group.adhoc_group,
              }
            );
            const membersCountAPI = APIcall.get({
              token: true,
              url: membersCountURL,
            });
            userGroups.push(membersCountAPI);
          }
        });
        if (userGroups.length > 0) {
          Promise.all(userGroups)
            .then((response) => {
              let realMembers = 0;
              let userCount = 0;
              response.forEach((userGroup) => {
                userCount += Number(userGroup?.body?.active_members) || 0;
                realMembers += Number(userGroup?.body?.real_members) || 0;
                const groupId = userGroup.req.url.split('/');
                const groupIndex = groups.findIndex(
                  (group) => group.adhoc_group === Number(groupId[7])
                );
                if (groupIndex !== -1) {
                  const newGroup = groups[groupIndex];
                  newGroup.real_members = Number(userGroup?.body?.real_members);
                  groups[groupIndex] = newGroup;
                }
              });

              groups.forEach((annoucementGroup) => {
                if (!annoucementGroup.adhoc_group) {
                  realMembers += annoucementGroup.user_count || 0;
                  userCount += annoucementGroup?.user_count || 0;
                }
              });
              annoucement.groups = groups;
              annoucement.real_members = realMembers;
              annoucement.user_count = userCount;
              next(null, annoucement);
            })
            .catch((error) => {
              next(error);
            });
        } else {
          next(null, annoucement);
        }
      } else {
        next(null, annoucement);
      }
    };
    const finallyHook = (error, announcement) => {
      if (error) {
        dispatch(announcementActions.loadOneAnnouncementFail(error.message));
        reject(error);
      } else {
        dispatch(announcementActions.loadOneAnnouncementSuccess(announcement));
        resolve(announcement);
      }
    };
    async.waterfall(
      [getAnnouncement, getProcessingTime, getAllUsersInGroups],
      finallyHook
    );
  });

announcementThunks.saveAnnouncement =
  (id, onCreate = noop, onUpdate = noop, onFail = noop, slackChannels) =>
  (dispatch, getState) => {
    const state = getState();
    const announcementFormValues = state.getIn([
      'form',
      `AnnouncementManageForm_${id}`,
      'values',
    ]);

    const generateFinalValues = () => {
      const formData = {};
      const mapKeys = announcementFormValues.keySeq().toArray();
      const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
      const INPUT_TIME_FORMAT = 'HH:mm';

      const generateBEDateFormat = (date) =>
        date
          ? `${moment(`${date}`, `${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`)
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss')}Z`
          : void 0;
      const generateISODateFormat = (date) =>
        date
          ? moment(
              `${date}`,
              `${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`
            ).toISOString()
          : void 0;

      mapKeys.forEach((formKey) => {
        // We need to explain why the switch clause looses context (AnnouncementTypes are undefined inside).
        // This works as a fix
        const announcTypes = ANNOUNCEMENT_TYPES;
        const customActionIndex = 0;
        switch (formKey) {
          case 'action': {
            const isActionTypeSelected = announcementFormValues.getIn([
              'action',
              customActionIndex,
              'type',
            ]);
            if (isActionTypeSelected) {
              // assume there is an action only if the property is not empty
              const action = serializeAction(
                announcementFormValues
                  .getIn(['action', customActionIndex])
                  .toJS()
              );
              formData.action = [action];
            } else {
              if (id !== 'new') {
                formData.action = [];
              }
            }
            break;
          }
          case 'actions':
            // For now, since backend is now working under the key 'action' , we need to swap the key for when multiple actions are set ('survey' type)
            if (
              announcementFormValues.get('type') === announcTypes.SURVEY.value
            ) {
              const serializedActions = announcementFormValues
                .get('actions')
                .toJS()
                .map(serializeAction);
              formData.action = serializedActions;
            }

            break;
          case 'eta_date':
            if (announcementFormValues.getIn(['eta_date'])) {
              formData.eta = generateISODateFormat(
                announcementFormValues.getIn(['eta_date']),
                announcementFormValues.getIn(['eta_time'])
              );
              formData.has_eta = true;
            }
            break;

          case 'groups':
            formData.groups = [];
            if (announcementFormValues.get(formKey)) {
              announcementFormValues
                .get(formKey)
                .toArray()
                .forEach((group) => {
                  if (!isUndefined(group.get('adhoc_group'))) {
                    formData.groups.push(group.toJS());
                  } else if (
                    !isUndefined(group.get('location')) ||
                    !isUndefined(group.get('department')) ||
                    !isUndefined(group.get('job_role'))
                  ) {
                    formData.groups.push(group);
                  }
                });
            }
            break;
          case 'slack_channels': {
            if (announcementFormValues.get('slack_channels').size > 0) {
              const finalWorkGroups = [];
              announcementFormValues
                .get('slack_channels')
                .forEach((channelID) => {
                  slackChannels.forEach((workgroup) => {
                    workgroup.channels.forEach((channel) => {
                      if (channel.id === channelID) {
                        const currentFinalGroupIndex =
                          finalWorkGroups.findIndex(
                            (finalWorkGroup) =>
                              finalWorkGroup.id === workgroup.id
                          );

                        if (currentFinalGroupIndex >= 0) {
                          finalWorkGroups[currentFinalGroupIndex].channels.push(
                            channel
                          );
                        } else {
                          finalWorkGroups.push({
                            channels: [channel],
                            id: workgroup.id,
                            name: workgroup.name,
                          });
                        }
                      }
                    });
                  });
                });
              formData.bot_channels_list = {
                bot_channels_dict: finalWorkGroups,
              };
            }

            break;
          }
          case 'status':
            if (
              announcementFormValues.get(formKey) ===
              AnnouncementState.STATUS_DRAFT
            ) {
              formData.status = announcementFormValues.get(formKey);
            }
            break;
          case 'subject':
            if (id === 'new') {
              formData.name = announcementFormValues.get(formKey);
            }
            formData.subject = announcementFormValues.get(formKey);
            break;
          case 'schedule': {
            const startDate = announcementFormValues.getIn([
              'schedule',
              'start_date',
            ]);
            const startTime = announcementFormValues.getIn([
              'schedule',
              'start_time',
            ]);
            const endDate = announcementFormValues.getIn([
              'schedule',
              'end_date',
            ]);
            if (startDate) {
              formData.schedule = {};

              if (
                announcementFormValues.getIn(['schedule', 'recurrence']) &&
                getUTCDays(
                  announcementFormValues
                    .getIn(['schedule', 'recurrence'])
                    .toArray(),
                  startDate,
                  startTime
                )
              ) {
                formData.schedule.recurrence =
                  announcementFormValues.getIn(['schedule', 'recurrence']) &&
                  getUTCDays(
                    announcementFormValues
                      .getIn(['schedule', 'recurrence'])
                      .toArray(),
                    startDate,
                    startTime
                  );
              }

              if (announcementFormValues.getIn(['schedule', 'repeat'])) {
                formData.schedule.recurrence_schedule = {};

                const every = Number(
                  announcementFormValues.getIn(['schedule', 'every'])
                );

                const onDay = Number(
                  announcementFormValues.getIn(['schedule', 'on_day'])
                );

                const onOrdinalNumber = announcementFormValues.getIn([
                  'schedule',
                  'on_ordinal_number',
                ]);

                const onWeekDays = announcementFormValues.getIn([
                  'schedule',
                  'on_week_day',
                ]);

                switch (announcementFormValues.getIn(['schedule', 'repeat'])) {
                  case repeatTypes.DAYLY:
                    formData.schedule.recurrence_schedule.daily = {
                      every: every,
                    };
                    break;
                  case repeatTypes.WEEKLY:
                    formData.schedule.recurrence_schedule.weekly = {
                      every: every,
                      on: announcementFormValues
                        .getIn(['schedule', 'on_weekdays'])
                        ?.toArray(),
                    };
                    break;

                  case repeatTypes.MONTHLY:
                    formData.schedule.recurrence_schedule.monthly = {
                      every: every,
                      on_date: onDay ? onDay : void 0,
                      on_nth: onOrdinalNumber ? onOrdinalNumber : void 0,
                      on_nth_type: onWeekDays ? onWeekDays : void 0,
                    };
                    break;
                  case repeatTypes.YEARLY:
                    formData.schedule.recurrence_schedule.yearly = {
                      on_date: onDay ? onDay : void 0,
                      on_month: announcementFormValues.getIn([
                        'schedule',
                        'every',
                      ]),
                      on_nth: onOrdinalNumber ? onOrdinalNumber : void 0,
                      on_nth_type: onWeekDays ? onWeekDays : void 0,
                    };
                    break;
                  default:
                    break;
                }
              }

              const autoExpireDays = (formData.schedule.auto_expire_days =
                announcementFormValues.getIn(['schedule', 'auto_expire_days']));

              const enDate = generateBEDateFormat(endDate);

              formData.schedule.auto_expire_days = autoExpireDays
                ? autoExpireDays
                : void 0;

              // eta is not located inside schedule object
              formData.schedule.start_date = generateBEDateFormat(startDate);
              formData.schedule.end_date = enDate ? enDate : void 0;
            } else {
              if (id !== 'new') {
                formData.schedule = {};
              }
            }

            break;
          }
          default:
            if (formKey !== 'eta_time') {
              formData[formKey] = announcementFormValues.get(formKey);
            }
            break;
        }
      });
      return formData;
    };

    const finalData = generateFinalValues();
    const keepStatus = announcementFormValues.get('status');

    dispatch(announcementActions.postOneAnnouncementStart(id));
    // Creating a new announcement
    if (id === 'new') {
      return APIcall.post({
        data: finalData,
        error(error) {
          dispatch(announcementActions.postOneAnnouncementEnd(id));
          dispatch(
            announcementActions.loadOneAnnouncementFail(error.message, id)
          );
          onFail();
        },
        success(res) {
          const announcement = res.body;
          announcement.status = ''; // Clearing the status, as it's managed by another API
          dispatch(
            announcementActions.loadOneAnnouncementSuccess(announcement)
          );
          onCreate(announcement, keepStatus);
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.getAll'),
      });
    } else {
      // Update existing
      return APIcall.patch({
        data: finalData,
        error(error) {
          dispatch(
            announcementActions.loadOneAnnouncementFail(error.message, id)
          );
        },
        success(res) {
          const announcement = res.body;
          announcement.status = ''; // Clearing the status, as it's managed by another API
          dispatch(
            announcementActions.loadOneAnnouncementSuccess(announcement)
          );
          // For saved announcements, groups are updated separately
          onUpdate(announcement, keepStatus, finalData.groups);
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.notification', {
          notificationID: id,
        }),
      });
    }
  };

announcementThunks.saveAllGroups =
  (formId, announcement, groupsInForm = [], cb = noop) =>
  (dispatch) => {
    const groupsInAnnouncement = announcement.groups;

    let groupstoBeDeleted = [];
    if (formId !== 'new') {
      // Delete all the groups that didn't remain in the form
      groupstoBeDeleted = filter(
        groupsInAnnouncement,
        (ga) => !find(groupsInForm, (gf) => gf.id === ga.id && gf.id !== 'new')
      );
    }

    async.series(
      [
        (next) => {
          // 1. Save all the groups in the form
          async.eachOf(
            groupsInForm,
            (group, key, done) => {
              dispatch(announcementThunks.saveGroup(group, announcement, done));
            },
            (err) => {
              if (!err) {
                next();
              }
            }
          );
        },
        // 2. delete the groups not in the form
        (next) => {
          async.eachOf(
            groupstoBeDeleted,
            (group, key, done) => {
              dispatch(announcementThunks.deleteGroup(group, done));
            },
            (err) => {
              if (!err) {
                next();
              }
            }
          );
        },
      ],
      (error) => {
        // in the end of the sequence invoking callback
        if (!error) {
          cb();
        }
      }
    );
  };

announcementThunks.loadJobRoles = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    /**
     * To-do: we're loading job roles from esp job roles users, but we need a dedicated API
     */

    const state = getState();
    if (!state.getIn(['announcement', 'loadStates', 'jobRoles', 'loading'])) {
      const url = endpointGenerator.genPath('espUser.jobRoles');
      dispatch(announcementActions.loadJobRolesStart());
      APIcall.get({
        error(error) {
          reject(dispatch(announcementActions.loadJobRolesFail(error.message)));
        },
        success(res) {
          const response = res.body.results;

          const initialRole = [
            {
              // Creates a role specifically to be used to select an empty option so we can clear the dropdown value and send job_role: null to the API
              key: 0,
              text: intl.formatMessage({
                id: 'label.none',
              }),
              value: null,
            },
          ];

          const roles = initialRole.concat(
            map(response, (role) => ({
              key: role.id,
              text: role.name,
              value: role.id,
            }))
          );

          resolve(dispatch(announcementActions.loadJobRolesSuccess(roles)));
        },
        token: true,
        url,
      });
    }
  });
announcementThunks.loadLocationByID = (locationID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espPlaces.locations.instance', {
      locationID,
    });
    APIcall.get({
      error() {
        dispatch(announcementActions.loadSingleLocationSuccess({}));
        reject();
      },
      success({ body }) {
        dispatch(announcementActions.loadSingleLocationSuccess(body));
        resolve(body);
      },
      token: true,
      url,
    });
  });

announcementThunks.loadDepartmentByID = (departmentID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espUser.department.instance', {
      departmentID,
    });
    APIcall.get({
      error() {
        dispatch(announcementActions.loadSingleDepartmentSuccess({}));
        reject();
      },
      success({ body }) {
        dispatch(announcementActions.loadSingleDepartmentSuccess(body));
        resolve(body);
      },
      token: true,
      url,
    });
  });

announcementThunks.loadDepartments = () => (dispatch, getState) => {
  /**
   * To-do: we're loading job roles from esp job roles users, but we need a dedicated API
   */

  const state = getState();
  if (!state.getIn(['announcement', 'loadStates', 'departments', 'loading'])) {
    const url = endpointGenerator.genPath('espUser.department');
    dispatch(announcementActions.loadDepartmentsStart());
    APIcall.get({
      error(error) {
        dispatch(announcementActions.loadDepartmentsFail(error.message));
      },
      success(res) {
        const response = res.body.results;
        const departments = map(response, (dpt) => ({
          key: dpt.id,
          text: dpt.name,
          value: dpt.id,
        }));
        dispatch(announcementActions.loadDepartmentsSuccess(departments));
      },
      token: true,
      url,
    });
  }
};

announcementThunks.saveGroup =
  (group, announcement, done = noop) =>
  (dispatch) => {
    const groupURL = group.url;
    const announcementId = announcement.id;
    if (group.notification === 'new') {
      // by now we now the id of the announcement
      group.notification = announcementId;
    }

    if (groupURL) {
      // Updating existing group
      dispatch(announcementActions.updateGroupStart());
      APIcall.patch({
        data: group,
        error(error) {
          dispatch(announcementActions.updateGroupFail(error.message));
        },
        success(res) {
          dispatch(announcementActions.updateGroupSavedSuccess(res.body));
          done();
        },
        token: true,
        url: groupURL,
      });
    } else {
      // Creating a new group
      APIcall.post({
        data: group,
        error(error) {
          dispatch(announcementActions.updateGroupFail(error.message));
        },
        success(res) {
          dispatch(announcementActions.updateGroupAddedSuccess(res.body));
          done();
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.notification.groups', {
          notificationID: announcementId,
        }),
      });
    }
  };

announcementThunks.deleteGroup =
  (group, done = noop) =>
  (dispatch) => {
    const groupURL = group.url;
    const groupId = group.id;
    const announcementId = group.notification;
    if (groupURL) {
      // Only update existing groups
      dispatch(announcementActions.updateGroupStart());
      APIcall.delete({
        error(error) {
          dispatch(announcementActions.updateGroupFail(error.message));
        },
        success() {
          dispatch(
            announcementActions.updateGroupDeletedSuccess(
              groupId,
              announcementId
            )
          );
          done();
        },
        token: true,
        url: groupURL,
      });
    }
  };

announcementThunks.saveStatus =
  (announcementId, status, previousStatus) => (dispatch) =>
    new Promise((resolve, reject) => {
      APIcall.post({
        data: {
          status: status,
        },
        error(err) {
          reject(err);
        },
        success() {
          dispatch(
            change(`AnnouncementManageForm_${announcementId}`, 'status', status)
          );
          dispatch(
            announcementActions.saveStatusSuccess(
              announcementId,
              status,
              previousStatus
            )
          );
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath(
          'espNotification.notification.changeStatus',
          {
            notificationID: announcementId,
          }
        ),
      });
    });

announcementThunks.updateAnnouncement =
  (announcementId, data) => (dispatch) => {
    dispatch(announcementActions.updateAnnouncementStart());
    return APIcall.patch({
      data,
      error() {
        dispatch(announcementActions.updateAnnouncementFail());
      },
      success() {
        dispatch(announcementActions.updateAnnouncementSuccess());
      },
      token: true,
      url: endpointGenerator.genPath(
        'espNotification.notification.updateNotification',
        {
          notificationID: announcementId,
        }
      ),
    });
  };

announcementThunks.recall = (announcementId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(announcementActions.startRecall(announcementId));
    APIcall.post({
      error(err) {
        dispatch(announcementActions.failRecall(announcementId));
        reject(err);
      },
      success() {
        dispatch(announcementActions.successRecall(announcementId));
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espNotification.notification.recall', {
        notificationID: announcementId,
      }),
    });
  });

announcementThunks.deleteAnnouncement =
  (announcementId, cb = noop) =>
  (dispatch) => {
    if (announcementId === 'new') {
      // No need for APi call as new exist only in memory
      dispatch(announcementActions.deleteAnnouncementSuccess(announcementId));
      cb();
    } else {
      announcementId = Number(announcementId);
      dispatch(announcementActions.deleteOneAnnouncementStart(announcementId));
      APIcall.delete({
        error() {},
        success() {
          dispatch(
            announcementActions.deleteAnnouncementSuccess(announcementId)
          );
          cb();
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.notification', {
          notificationID: announcementId,
        }),
      });
    }
  };

// Creates a new group object and sends it back to the callback
announcementThunks.createNewGroup =
  (announcementId, cb = noop) =>
  (dispatch) => {
    // by default remote workers aren't targeted by announcement
    const include_remote = false;
    dispatch(
      announcementThunks.getUserCount(
        {
          include_remote,
        },
        (userCount) => {
          const newGroup = {
            id: 'tmp',
            include_remote,
            notification: announcementId,
            time_limit: userCount.timeLimit,
            user_count: userCount.userCount, // Temporal id, will get a real one once we save
          };

          cb(newGroup);
        }
      )
    );
  };

announcementThunks.getUserCount =
  (
    {
      location = -1,
      department = -1,
      job_role = -1,
      include_remote = false,
      adhoc_group,
    },
    cb = noop
  ) =>
  () => {
    return new Promise((resolve, reject) => {
      const getUserCount = (next) => {
        if (adhoc_group) {
          const url = endpointGenerator.genPath(
            'espUserGroups.groups.instance.members_count',
            {
              group_id: adhoc_group,
            }
          );
          APIcall.get({
            error(error) {
              next(error);
            },
            success({ body }) {
              next(null, body);
            },
            token: true,
            url,
          });
        } else if (
          some([
            location && location !== -1,
            department && department !== -1,
            job_role && department !== -1,
            location === null,
            department === null,
            job_role === null,
          ])
        ) {
          APIcall.get({
            error(error) {
              next(error);
            },
            query: {
              department,
              include_remote,
              job_role,
              location,
            },
            success(res) {
              const count = res.body;
              next(null, count);
            },
            token: true,
            url: endpointGenerator.genPath('espUser.users.userCount'),
          });
        } else {
          next(null, 0);
        }
      };
      const getProcessingTime = (count, next) => {
        const { active_members, real_members } = count;
        const userCount = active_members || count;
        const url = endpointGenerator.genPath('espNotification.processingTime');
        if (userCount > 0) {
          APIcall.get({
            error(error) {
              next(error);
            },
            query: {
              user_count: userCount,
            },
            success({ body }) {
              const timeLimit = body;
              next(null, {
                realMembers: real_members,
                timeLimit,
                userCount,
              });
            },
            token: true,
            url,
          });
        } else {
          next(null, {
            timeLimit: 0,
            userCount: 0,
          });
        }
      };

      const finallyHook = (err, data) => {
        if (err) {
          reject(err);
          cb({
            timeLimit: 0,
            userCount: 0,
          });
        } else {
          resolve(data);
          cb(data);
        }
      };
      async.waterfall([getUserCount, getProcessingTime], finallyHook);
    });
  };

export { serializeAction };

export default announcementThunks;
