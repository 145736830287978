// Selectors
import { getTenantEntity, getTenantUIConfig } from '../selectors/getTenant';
import { head, isEmpty, noop } from 'lodash';
import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';

// Utils
import APIcall from '../utils/APIcall';
import async from 'async';
import buildUIConfig from '../utils/buildUIConfig';
// Globals
import customLabels from '../globals/customLabels';
import endpointGenerator from '../utils/endpointGenerator';
import EntitiesUtils from '../utils/EntitiesUtils';
import TenantImagesTypes from '../utils/TenantImageTypes';
// Packages
import EspFilters from 'esp-util-filters';
import { fromJS } from 'immutable';
import sessionActions from './sessionActions';
// Actions
import tenantActions from './tenantActions';

const getTenantImages = (onTenantImages = noop) => {
  const url = endpointGenerator.genPath('espImage.tenantImages');

  APIcall.get({
    error(error) {
      onTenantImages(error.response.body);
    },
    query: {
      limit: 1000,
    },
    success(response) {
      const tenantImages = response.body.results;
      onTenantImages(null, tenantImages);
    },
    url,
  });
};

const removeTenantImage = (tenantImageID, onRemoveTenantImage = noop) => {
  const url = endpointGenerator.genPath('espImage.tenantImages.instance', {
    tenantImageID,
  });

  APIcall.del({
    error(error) {
      onRemoveTenantImage(error.response.body);
    },
    success() {
      onRemoveTenantImage(null);
    },
    token: true,
    url,
  });
};

const uploadTenantImage = (imageType, imageFile, onUploadTenantImage = noop) =>
  new Promise((resolve, reject) => {
    const data = new FormData();

    data.append('type', imageType);

    if (imageFile.type === 'image/svg+xml') {
      data.append('svg', imageFile);
    } else {
      data.append('image', imageFile);
    }

    const url = endpointGenerator.genPath('espImage.tenantImages');

    APIcall.post({
      data,
      error(error) {
        const err = error.response ? error.response.body : error;
        onUploadTenantImage(err);
        reject(error);
      },
      success(response) {
        const tenantImage = response.body;
        onUploadTenantImage(null, tenantImage);
        resolve(tenantImage);
      },
      token: true,
      url,
    });
  });

const tenantThunks = {};

tenantThunks.checkTenantDomain = () => (dispatch) => {
  const domain = endpointGenerator.getDomain();

  const url = endpointGenerator.genPath('espTenant.domain');

  dispatch(tenantActions.getTenantDomainStart());

  APIcall.get({
    error(error) {
      dispatch(tenantActions.getTenantDomainFail(error));
    },
    preventShowError: true,
    query: {
      domain,
    },
    success(response) {
      const domains = response.body.results;

      if (isEmpty(domains)) {
        dispatch(tenantActions.getTenantDomainFail(null));
      } else {
        const domain = head(domains);
        dispatch(tenantActions.getTenantDomainSuccess(domain));
      }
    },
    url,
  });
};

tenantThunks.getTenant =
  (pathname, cb = noop) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const tenantName = endpointGenerator.tenant;

      const url = endpointGenerator.genPath('espTenant.tenants');

      dispatch(tenantActions.getTenantStart());

      APIcall.get({
        error(error) {
          dispatch(tenantActions.getTenantFail(error));

          cb(error);
          reject(error);
        },
        preventShowError: true,
        query: {
          name: tenantName,
        },
        success(response) {
          const tenants = response.body.results;

          if (isEmpty(tenants)) {
            dispatch(tenantActions.getTenantFail(null));
            reject();
          } else {
            const tenant = buildUIConfig(head(tenants));
            dispatch(tenantActions.getTenantSuccess(tenant));
            resolve(tenant);
          }
          cb();
        },
        url,
      });
    });

tenantThunks.getAllowedExtensions = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(tenantActions.loadAllowedExtensionStart());

    const url = endpointGenerator.genPath('espConfig');

    const filters = new EspFilters()
      .equalTo('key', 'attachments.allowed_extensions')
      .asQueryString();
    return APIcall.get({
      error(error) {
        dispatch(tenantActions.loadAllowedExtensionFail(error));
        reject();
      },
      query: {
        esp_filters: filters,
      },
      success({ body }) {
        if (body.results.length) {
          dispatch(
            tenantActions.loadAllowedExtensionSuccess(body.results[0].value)
          );
        } else {
          // Pass star to allow all of them
          dispatch(
            tenantActions.loadAllowedExtensionSuccess(
              'jpg,gif,jpeg,png,txt,doc,docx,xls,xlsx,ppt,pptx,numbers,keynote,pages,html,pdf,json'
            )
          );
        }
        resolve();
      },
      token: true,
      url,
    });
  });

tenantThunks.getTenantImages =
  (keepCurrentImages = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(tenantActions.getTenantImagesStart(keepCurrentImages));
      getTenantImages((error, tenantImages) => {
        if (error) {
          dispatch(tenantActions.getTenantImagesFail(error));
          reject(error);
        } else {
          dispatch(tenantActions.getTenantImagesSuccess(tenantImages));
          resolve();
        }
      });
    });

tenantThunks.getTenantUserGroupPermissions = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    // Check that we are log
    const token = state.hasIn(['session', 'accessToken'])
      ? state.getIn(['session', 'accessToken'])
      : null;
    if (!token) {
      resolve(); // User not log in - Avoid to run the API call
    } else {
      dispatch(tenantActions.loadUserGroupsStart());
      const query = {
        all: 'no',
        brief: 'yes',
      };

      let nextUrl = endpointGenerator.genPath('espRbac.group');
      const groupsMap = {};

      async.whilst(
        () => Boolean(nextUrl),
        (next) => {
          APIcall.get({
            error(err) {
              next(err);
            },
            query,
            success({ body }) {
              nextUrl = body.next;
              if (body.results) {
                // Build New Group permission Object
                body.results.forEach((r) => {
                  groupsMap[r.name] = r.name;
                });
              }
              next();
            },
            token: true,
            url: nextUrl,
          });
        },
        (err) => {
          if (err) {
            dispatch(tenantActions.loadUserGroupsFail(err));
            reject(err);
          } else {
            dispatch(tenantActions.loadUserGroupsSuccess(groupsMap));
            resolve(groupsMap);
          }
        }
      );
    }
  });

tenantThunks.getOneTenantImage =
  (type = 'empty', force) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const tenantImages = state.getIn(['tenant', 'images']);
      const tenantImagesValues = tenantImages.get('values');
      const isLoading = state.getIn([
        'containersStatus',
        'tenantImages',
        type,
        'loading',
      ]);
      const selectedImageType = tenantImagesValues
        ? tenantImagesValues.find((img) => img.get('type') === type)
        : null;

      if (isLoading) {
        resolve();
        return;
      }

      if (!tenantImages.get('loaded')) {
        // If the tenant images have not been loaded and are not loading, set noTenantImagesLoaded to true
        resolve({
          noTenantImagesLoaded: !tenantImages.get('isLoading'),
        });
      } else {
        // check if it has errors or its expired
        const imageTenantTimeStamp = state.hasIn([
          'containersStatus',
          'tenantImages',
          type,
          'timestamp',
        ])
          ? state.getIn(['containersStatus', 'tenantImages', type, 'timestamp'])
          : null;

        const hasErrors = state.getIn([
          'containersStatus',
          'tenantImages',
          type,
          'error',
        ]);

        let isExpired = true;
        if (imageTenantTimeStamp && !hasErrors) {
          // If the tenant images data have been already loaded before
          // we need to check for the image expiration time
          isExpired = EntitiesUtils.isTimestampExpired(
            imageTenantTimeStamp,
            state
          );
        }

        if (selectedImageType && (isExpired || force)) {
          dispatch(tenantActions.getTenantOneImageStart(type));
          APIcall.get({
            error(err) {
              dispatch(tenantActions.getTenantOneImageFail(err, type));
              reject(err);
            },
            preventShowError: true,
            success({ body }) {
              dispatch(tenantActions.getTenantOneImageSuccess(body, type));
              resolve();
            },
            url: selectedImageType.get('url'),
          });
        } else {
          resolve();
        }
      }
    });

tenantThunks.loadChatbotImage = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const type = TenantImagesTypes.BOT;
    dispatch(tenantActions.getTenantOneImageStart(type));
    APIcall.get({
      error(err) {
        dispatch(tenantActions.getTenantOneImageFail(err, type));
        reject(err);
      },
      preventShowError: true,
      query: {
        esp_filters: encodeURI(`type__EQ=${type}`),
      },
      success({ body }) {
        dispatch(
          tenantActions.getTenantOneImageSuccess(body?.results?.[0], type)
        );
        resolve();
      },
      url: endpointGenerator.genPath('espImage.tenantImages'),
    });
  });

/**
 * @param {number} tenantID
 * @param {Object} changes
 */
tenantThunks.updateTenant = (tenantID, changes) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espTenant.tenants.instance', {
      tenantID,
    });

    dispatch(tenantActions.updateTenantStart());

    APIcall.patch({
      data: changes,
      error(error) {
        dispatch(tenantActions.updateTenantFail(error.response.body));
        reject();
      },
      success(response) {
        const tenant = response.body;
        dispatch(tenantActions.updateTenantSuccess(tenant));
        resolve();
      },
      token: true,
      url,
    });
  });

/**
 * @param {string} imageType
 * @param {Object} imageFile
 */
tenantThunks.uploadTenantImage = (imageType, imageFile) => (dispatch) => {
  dispatch(tenantActions.uploadTenantImageStart());

  async.waterfall(
    [
      // 1. Get tenant images
      (next) => {
        getTenantImages(next);
      },

      // 2. Remove tenant images of same type to prevent them to stack up
      (tenantImages, next) => {
        // filter only images of the same type as the new one
        tenantImages = tenantImages.filter(
          (tenantImage) => tenantImage.type === imageType
        );

        async.each(
          tenantImages,
          (tenantImage, onRemove) => {
            const tenantImageID = tenantImage.id;
            removeTenantImage(tenantImageID, onRemove);
          },
          (err) => {
            if (err) {
              next(err);
            } else {
              next(null, tenantImages);
            }
          }
        );
      },

      // 3. Upload new tenant image
      (imagesToRemove, next) => {
        uploadTenantImage(imageType, imageFile)
          .then((tenantImage) => next(null, tenantImage, imagesToRemove))
          .catch((err) => next(err));
      },
    ],
    (error, tenantImage, imagesToRemove) => {
      if (error) {
        dispatch(tenantActions.uploadTenantImageFail(error));
      } else {
        dispatch(
          tenantActions.uploadTenantImageSuccess(tenantImage, imagesToRemove)
        );
      }
    }
  );
};

tenantThunks.uploadTenantFile = (file) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(tenantActions.uploadFileStart());

    const data = new FormData();

    data.append('file', file);
    data.append('file_location', 'AWSTF');
    data.append('file_path', 'training');

    const url = endpointGenerator.genPath('espTenantFiles');

    APIcall.post({
      data,
      error(error) {
        dispatch(tenantActions.uploadFileFail());
        reject(error);
      },
      success(response) {
        dispatch(tenantActions.uploadFileSuccess());
        resolve(response.body);
      },
      token: true,
      url,
    });
  });

tenantThunks.removeTenantImage = (tenantImageID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!tenantImageID) {
      reject('Error - No image ID has been passed');
    }

    dispatch(tenantActions.removeTenantImagesStart(tenantImageID));

    removeTenantImage(tenantImageID, (error) => {
      if (error) {
        dispatch(tenantActions.removeTenantImagesFail());
        reject(error);
      } else {
        dispatch(tenantActions.removeTenantImagesSuccess(tenantImageID));
        resolve();
      }
    });
  });

tenantThunks.changeTenant =
  (data = null, name = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      /**
       * Comment out to test this locally in cordova and tenant1.esp
       */
      // data;
      // name;
      // const url = 'http://tenant1.esp:8000/api/tenants/v0.1/tenant/';
      // endpointGenerator.setDomainResolution('http://tenant1.esp:8000');

      let tenantName = data ? data.get('tenantName').toLowerCase() : name;
      tenantName = tenantName.trim();
      const url = `https://${tenantName}.espressive.com/api/tenants/v0.1/tenant/`;
      endpointGenerator.setDomainResolution(
        `https://${tenantName}.espressive.com`
      );

      dispatch(tenantActions.getTenantStart());
      APIcall.get({
        error() {
          dispatch(tenantActions.changeTenantNotFound('Tenant not found'));
        },
        preventShowError: true,
        success(response) {
          const tenants = response.body.results;
          const tenant = buildUIConfig(head(tenants));
          dispatch(tenantActions.getTenantSuccess(tenant));

          const tenantURL = tenant.url;
          persistentStorage.put(PersistentStorageKeys.TENANT_URL, tenantURL);

          // Get the good image and tenant detail
          async.waterfall(
            [
              // Get tenant
              (next) => {
                dispatch(tenantThunks.getTenant('/', next));
              },
              // Get tenant image
              (next) => {
                dispatch(tenantThunks.getTenantImages())
                  .then(() => next())
                  .catch((err) => next(err));
              },
            ],
            (error) => {
              if (error) {
                dispatch(tenantActions.changeTenantNotFound(error));
                reject(error);
              } else {
                resolve(tenant);
              }
            }
          );
        },
        url,
      });
    });

tenantThunks.clearTenant = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch(tenantActions.clearTenant());
    dispatch(sessionActions.clearSession());
    resolve();
  });

tenantThunks.loadTenantLabelConfiguration = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // Make sure that the user is logged before to check the tenantMenu config in order to avoid permission error
    const store = getState();
    const isUserLogged = store.getIn(['session', 'accessToken']);

    if (!isUserLogged) {
      resolve();
      return; // User is not logged
    }

    dispatch(tenantActions.loadLabelConfigurationStart());

    const url = endpointGenerator.genPath('commons.localization');
    const query = {
      esp_filters: '',
    };

    customLabels.forEach((item) => {
      if (query.esp_filters !== '') {
        query.esp_filters += '&OR';
      }
      query.esp_filters += `key__EQ=${item.get('key')}`;
    });

    APIcall.get({
      error(error) {
        dispatch(tenantActions.loadLabelConfigurationFail(error));
        reject(error);
      },
      query,
      success(response) {
        const data = response.body.results;
        dispatch(tenantActions.loadLabelConfigurationSuccess(data));
        resolve();
      },
      token: true,
      url,
    });
  });

tenantThunks.setTenantNotificationsConfiguration =
  (notificationsConfig) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const tenant = getTenantEntity(state);
      const tenantUIConfig = getTenantUIConfig(state);

      const uiConfig = tenantUIConfig ? tenantUIConfig.toJS() : {};
      const newConfig = {
        ...uiConfig,
        notifications_config: notificationsConfig,
      };

      dispatch(
        tenantThunks.updateTenant(tenant.get('id'), {
          ui_config: newConfig,
        })
      )
        .then(resolve)
        .catch(reject);
    });
/**
 * Allow to set a Tenant configuration to hide a specific section (menu) to the users
 * @param valuesArray
 */
tenantThunks.setTenantMenuConfiguration =
  (valuesArray) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const tenant = getState().getIn(['tenant', 'entity', 'value']);

      const newUIConfig =
        tenant.has('ui_config') && tenant.get('ui_config')
          ? tenant.get('ui_config').toJS()
          : {};
      const tenantID = tenant.get('id');
      newUIConfig.displayMenu = {};

      valuesArray.forEach((value) => {
        newUIConfig.displayMenu[value.key] = Boolean(value.value);
      });

      dispatch(
        tenantThunks.updateTenant(tenantID, {
          ui_config: newUIConfig,
        })
      )
        .then(resolve)
        .catch(reject);
    });

/**
 * Allow to set a Tenant configuration to hide a specific section (menu) to the users
 * @param valuesArray
 */
tenantThunks.setTenantLabelConfiguration =
  (valuesArray) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(tenantActions.setTenantLabelConfigStart());

      const serieArray = valuesArray.map((value) => (next) => {
        let methodApi = value.update ? 'put' : 'post';

        let url = endpointGenerator.genPath('commons.localization');
        let index;
        if (value.update || value.delete) {
          methodApi = value.delete ? 'delete' : methodApi;

          const state = getState();
          const labelList = state.getIn(['tenant', 'localization', 'list']);
          index = labelList.findIndex((lab) => lab.get('key') === value.key);

          if (index > -1) {
            const id = labelList.getIn([index, 'id']);
            url = endpointGenerator.genPath('commons.localization.instance', {
              localizationID: id,
            });
          }
        }

        if (index === -1) {
          next();
        } else {
          const data = {
            application: value.application,
            key: value.key,
            language: value.language,
            text: value.text,
          };

          APIcall[methodApi]({
            data,
            error(error) {
              next(error);
            },
            success(response) {
              const resp = response.body;
              if (methodApi === 'delete') {
                dispatch(
                  tenantActions.deleteTenantOneLabelConfigSuccess(value.key)
                );
              } else {
                dispatch(tenantActions.setTenantOneLabelConfigSuccess(resp));
              }
              next();
            },
            token: true,
            url,
          });
        }
      });

      async.series(serieArray, (error) => {
        if (error) {
          dispatch(tenantActions.setTenantLabelConfigFail(error));
          reject(error);
        } else {
          dispatch(tenantActions.setTenantLabelConfigSuccess());
          resolve();
        }
      });
    });

tenantThunks.saveTenantLocale = (locale) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (!locale) {
      reject('Error - No values have been passed for the Locale Tenant data');
    }

    const tenant = getState().getIn(['tenant', 'entity', 'value']);

    const uiConfig = tenant.get('ui_config') || fromJS({});
    const tenantID = tenant.get('id');

    const newUIConfig = Object.assign({}, uiConfig.toJS(), {
      locale,
    });

    dispatch(
      tenantThunks.updateTenant(tenantID, {
        ui_config: newUIConfig,
      })
    )
      .then(resolve)
      .catch(reject);
  });

tenantThunks.saveTenantPrivacyStatement =
  (privacystatementsettings) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!privacystatementsettings) {
        reject('Error - No values have been passed for the Locale Tenant data');
      }

      const tenant = getState().getIn(['tenant', 'entity', 'value']);

      const uiConfig = tenant.get('ui_config') || fromJS({});
      const tenantID = tenant.get('id');

      const newUIConfig = Object.assign({}, uiConfig.toJS(), {
        privacystatementsettings,
      });

      dispatch(
        tenantThunks.updateTenant(tenantID, {
          ui_config: newUIConfig,
        })
      )
        .then(resolve)
        .catch(reject);
    });

export default tenantThunks;
