import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import casesActions from '../../actions/casesActions';
import casesThunks from '../../actions/casesThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

// Globals
import BrowserPrompt from '../../globals/BrowserPrompt';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import caseMgmtActions from '../../actions/caseMgmtActions';
import { getCasesSelectedServiceTeamId } from '../../selectors/getSelectedServiceTeam';

const mapStateToProps = (state) => ({
  defaultSubscribers: state.getIn([
    'cases',
    'subscribersSelect',
    'defaultSubscribers',
  ]),
  isModalOpen: state.getIn(['cases', 'subscribersSelect', 'isModalOpen']),
  serviceTeam: getCasesSelectedServiceTeamId(state),
  tmpSelectedLocationId: state.getIn([
    'cases',
    'subscribersSelect',
    'tmpSelectedLocationId',
  ]),
  tmpSelectedLocationName: state.getIn([
    'cases',
    'subscribersSelect',
    'tmpSelectedLocationName',
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  loadDefaultSubscribers: (caseTaskId) => {
    dispatch(casesThunks.loadDefaultSubscribers(caseTaskId));
  },

  loadServiceTeam: () =>
    new Promise((resolve) => {
      dispatch(caseMgmtThunks.getDefaultServiceTeam()).then((team) => {
        dispatch(caseMgmtActions.selectServiceTeam(team?.id));
        resolve(team);
      });
    }),

  loadSubscriptionScope: (caseTaskId) => {
    dispatch(casesThunks.loadSubscriptionScope(caseTaskId));
  },

  onCloseSubscribers: () => {
    dispatch(casesActions.closeAddSubscribers());
  },

  saveSubscriber: (subscriber, caseTaskId, name) => {
    const text = `You are about to add "${name}". This cannot be undone.`;
    const saveAfterConfirm = () => {
      dispatch(casesThunks.saveSubscriber(subscriber, caseTaskId))
        .then(() => {
          dispatch(
            toastNotificationsActions.success({
              message: 'Subscriber was saved',
            })
          );
        })
        .catch((error) => {
          dispatch(
            toastNotificationsActions.error({
              message: `Something went wrong: ${error}`,
            })
          );
        });
    };
    const shouldSubscribe = BrowserPrompt.confirm(text, {
      callback: saveAfterConfirm,
      title: text,
    });
    // only pass on web, because on mobile apps the function returns undefined
    if (shouldSubscribe) {
      saveAfterConfirm();
    }
  },

  saveSubscriberLocation: (
    subscriberLocationId,
    caseTaskId,
    subscriberLocationName
  ) => {
    const text = `You are about to add "${subscriberLocationName}". This cannot be undone.`;
    const saveAfterConfirm = () => {
      dispatch(
        casesThunks.saveSubscriptionScope(caseTaskId, {
          locationId: subscriberLocationId,
        })
      )
        .then(() => {
          dispatch(
            toastNotificationsActions.success({
              message: 'Subscribers were saved',
            })
          );
        })
        .catch((error) => {
          dispatch(
            toastNotificationsActions.error({
              message: `Something went wrong: ${error}`,
            })
          );
        });
    };
    const shouldSubscribe = BrowserPrompt.confirm(text, {
      callback: saveAfterConfirm,
      title: text,
    });
    if (shouldSubscribe) {
      saveAfterConfirm();
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
