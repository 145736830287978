const api_notification_v0_1_notification = {
  action: [{}],
  body: 'gfgfgfg',
  category: 'Generic Announcement',
  created_info: 'N/A',
  groups: [
    {
      id: 'tmp',
      include_remote: false,
      notification: 'new',
      user_count: 9152,
    },
  ],
  id: '111',
  schedule: {
    auto_expire_days: 0,
    recurrence: [],
  },
  status: 'DRAFT',
  subject: 'gggg',
  type: 'GENERAL',
  updated_info: 'N/A',
};

export default api_notification_v0_1_notification;
