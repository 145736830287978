import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Transition } from 'semantic-ui-react';
import _ from 'lodash';
// import {stripUnit}           from 'polished';

import ChatFormAttachmentDelete from './ChatFormAttachmentDelete';

const rule = (props) => {
  const { theme } = props;

  return {
    '> span': {},
    display: 'inline-block',
    lineHeight: 2,
    minHeight: theme.formHeight,
    paddingBottom: '.25em',
    paddingLeft: '.75em',
    paddingRight: '.25em',
    paddingTop: '.25em',
    verticalAlign: 'top',
    width: `calc(100% - ${theme.formHeight})`,
  };
};

class ChatFormFile extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    deleteAttachment: PropTypes.func,
    fileName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    deleteAttachment: _.noop,
  };

  // handleTextAreaFocus(e,data){
  //   const {onTextAreaFocus} = this.props;
  //
  //   onTextAreaFocus(e,data);
  // }

  render() {
    const { className, deleteAttachment, fileName } = this.props;

    return (
      <Transition transitionOnMount>
        <div className={`${className} inline`} data-component='ChatFormFile'>
          <span>{fileName}</span>
          <ChatFormAttachmentDelete onClick={deleteAttachment} />
        </div>
      </Transition>
    );
  }
}

export default createComponent(rule, ChatFormFile, [
  'deleteAttachment',
  'fileName',
]);
