import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
const propTypes = {
  activeImage: PropTypes.number.isRequired,
  images: ImmutablePropTypes.list.isRequired,
  onError: PropTypes.func.isRequired,
  productID: PropTypes.number.isRequired,
  setActiveImage: PropTypes.func.isRequired,
};

const ImageGallery = ({
  images,
  activeImage,
  setActiveImage,
  onError,
  productID,
}) => {
  const currentImage = images.find((i, k) => k === activeImage);

  return (
    <div className='esp-image-gallery'>
      <div className='image main'>
        <img
          alt={currentImage.get('caption')}
          src={
            currentImage.get('image_custom') || currentImage.get('image_480')
          }
        />
      </div>

      <div className='thumbs'>
        {images.size > 1 &&
          images.map((image, i) => (
            <div
              className='image'
              key={image.get('id')}
              style={{
                width: `calc(${100 / images.size}% - .${
                  100 - 100 / images.size
                }rem)`,
              }}
            >
              {
                <img
                  alt={image.get('caption')}
                  className={activeImage === i ? 'active' : null}
                  onClick={setActiveImage(productID, i)}
                  onError={onError}
                  onKeyPress={setActiveImage(productID, i)}
                  src={image.get('image_custom') || image.get('image_480')}
                />
              }
            </div>
          ))}
      </div>
    </div>
  );
};

ImageGallery.propTypes = propTypes;

export default ImageGallery;
