import PropTypes from 'prop-types';

import React, { Component } from 'react';
import _ from 'lodash';

/**
 * Transforms Form from redux-form, so when inside a ModalEsp, onSubmit prop is called with (values, close)
 * in the parent component.
 */
class ModalForm extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,

    close: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    close: _.noop,
    onSubmit: _.noop,
  };

  onFormSubmit = (values) => {
    const { close, onSubmit } = this.props;

    onSubmit(values, close);
  };

  render() {
    const { children } = this.props;
    const form = children;

    return React.cloneElement(form, {
      onSubmit: this.onFormSubmit,
    });
  }
}

export default ModalForm;
