import Nodes from './Nodes';
import Marks from './Marks';

const Regexes = {
  altTextInMarkDown: /\[(.*?)\]/g,
  findImageNameOnURL: /[\s\S]*(?=\.(gif|jpe?g|png|svg))/g,
  [Nodes.image]: /[\s\S]*(?=\.(gif|jpe?g|png|svg))/gi,
  [Marks.bold]: /\*\*(.+)\*\*/,
  [Nodes.email]: /(\[.+\])(\([\w.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\))/,
  [Nodes.link]: /((\[.+\])(\(([a-z]+:\/+)([^/\s]*)([a-z0-9\-@^=%&;/~+]*)[?]?([^ #]*)#?([^ #]*)\))({:target="_blank"})?)/,
  [Nodes.tag]: /(\{[!$%^@&*#+/].+\})/,
  [Nodes.embedVideo]: /(?:http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;))?|((http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?))|((http|https)?:\/\/player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)))/,
  [Nodes.markdownImage]: /!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g,
  [Nodes.markdownLink]: /^\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)$/g,
  [Nodes.nativeVideo]: /https?.*?\.(mp4|m4a|m4p|m4b|m4r|m4v)/,
  linkWithTarget: /(\[([\w\s\d]+)\]\((https?:\/\/\S+)\)({:target="_blank"}))/,
  urlInMarkdown: /\((.*?)\s*("(?:.*[^"])")?\s*\)/g,
};

export default Object.freeze(Regexes);
