import { isBoolean } from 'lodash';
import { fromJS } from 'immutable';
// Util
import UserUtils from './UserUtils';
// Global
import groupPermissions from '../globals/groupPermissions';
/**
 * Check if the user DOESN'T have the permission to see this menu
 * @param item {Object}
 * @param user {Immutable Map}
 * @param forceNoAdmin {boolean} Don't check if the user is an admin
 * @returns {boolean}
 */
const checkMenuNoPermission = (item, user, forceNoAdmin = false) => {
  const userPermissions = user.get('groups');
  const isAdmin = userPermissions.find(
    (perm) => perm === groupPermissions.ADMIN
  );

  if (!item.noPermissions || (isAdmin && !forceNoAdmin)) {
    return false; // The user by default can see the Menu
  }

  let userNoAllowed = false;

  userPermissions.forEach((perm) => {
    item.noPermissions.forEach((p) => {
      if (!userNoAllowed) {
        userNoAllowed = p === perm;
      }
    });
  });

  return userNoAllowed;
};

/**
 * Check user permission to display a menu
 * @param item {Object}
 * @param user {Immutable Map}
 * @param configurationTenantMenu {Immutable map}
 * @returns {boolean}
 */
const checkMenuPermissions = (item, user, configurationTenantMenu) => {
  let showMenu = true; // Options are visible by default
  if (item.web_menu && configurationTenantMenu) {
    const menuConfig = configurationTenantMenu.find(
      (menu, key) => key === item.web_menu
    ); // If the option is on ui_config, will be rendered based on ui_config value
    if (isBoolean(menuConfig) && !menuConfig) {
      // We specifically don't want to show this option, no need to continue the process
      return menuConfig;
    }
  }

  const userPermissions = user.get('groups', fromJS([]));
  const isAdmin = userPermissions.find(
    (perm) => perm === groupPermissions.ADMIN
  );
  const isManagerConfirmed = UserUtils.isManagerConfirmed(user);
  const forceNoAdmin = true;

  if (isAdmin && item.noPermissions) {
    if (!checkMenuNoPermission(item, user, forceNoAdmin)) {
      return true;
    }
    // If not, continue to the regular checking
  } else if (isAdmin) {
    return true;
  }

  if (item.permissions && !isManagerConfirmed) {
    showMenu = false;
  } else if (item.permissions) {
    showMenu = false;

    // Check if the user is not allow to see this menu like for the KIOSK group
    const userNoAllowed = checkMenuNoPermission(item, user);

    // User can see this menu
    if (!userNoAllowed) {
      // Check usr permissions
      userPermissions.forEach((perm) => {
        item.permissions.forEach((p) => {
          if (!showMenu) {
            showMenu = p === perm;
          }
        });
      });
    }
  } else if (item.noPermissions) {
    // Check if the user is not allow to see this menu like for the KIOSK group
    showMenu = !checkMenuNoPermission(item, user);
  }

  return showMenu;
};

export default checkMenuPermissions;
