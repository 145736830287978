import React from 'react';
// import PropTypes            from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';

import { Form, Segment } from 'semantic-ui-react';

import FormInputCheckbox from '../../../../../app/js/v1/components/molecules/FormInputCheckbox';
import FormInputText from '../../../../../app/js/v1/components/molecules/FormInputText';
import FormInputSelect from '../../../../../app/js/v1/components/molecules/FormInputSelect';

import { BackgroundBlockDefinition } from '../../../../../app/js/v1/globals/blocks/TypeBackground';

/**
 * Normalizer for fields such as 'hide', that shold be checked when its false
 */
const inverse = (value) => !value;

const propTypes = {
  formValues: ImmutablePropTypes.map,
  nav: ImmutablePropTypes.map.isRequired,
  //  taskId     : PropTypes.number.isRequired,
};

const defaultProps = {
  formValues: null,
}; // Lint rule doesn't allow a line break here...

class NavFormEditor extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { formValues, nav } = this.props;
    if (!nav || nav.isEmpty()) {
      return null;
    }

    const templatesAsOptions = BackgroundBlockDefinition.templates.map(
      (tName) => ({
        text: tName,
        value: tName,
      })
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Field
          component={FormInputCheckbox}
          label='Espressive Logo'
          name='espressiveLogo'
          toggle
        />

        <Field
          component={FormInputCheckbox}
          label='Progress'
          name='progress'
          toggle
        />

        <Field
          component={FormInputCheckbox}
          inversed
          label='Show background'
          name='background.hide'
          normalize={inverse}
          toggle
        />
        {formValues && !formValues.getIn(['background', 'hide']) && (
          <Segment>
            <Field
              component={FormInputSelect}
              label='Template'
              name='background.template'
              options={templatesAsOptions}
              placeholder='Select a template'
            />

            <Field
              component={FormInputText}
              label='Image'
              name='background.image'
              placeholder='Image URL'
            />

            <Form.Group inline>
              <label>{'Scrim'}</label>
              <Field
                component={FormInputCheckbox}
                label=''
                name='background.scrim'
              />
            </Form.Group>
            <Field
              component={FormInputText}
              label='Scrim color'
              name='background.scrimColor'
              placeholder='color'
            />
            <Field
              component={FormInputText}
              label='Scrim opacity'
              name='background.scrimOpacity'
              placeholder='opacity'
              type='number'
            />
          </Segment>
        )}

        <Field
          component={FormInputCheckbox}
          inversed
          label='Show Tool bar'
          name='toolbar.hide'
          normalize={inverse}
          toggle
        />
        {formValues && !formValues.getIn(['toolbar', 'hide']) && (
          <Segment>
            <Field
              component={FormInputText}
              label='Title'
              name='toolbar.title'
              placeholder='Page title'
            />
            <Field
              component={FormInputText}
              label='Next'
              name='toolbar.next'
              placeholder='Button content'
            />
            <Field
              component={FormInputText}
              label='Prev'
              name='toolbar.prev'
              placeholder='Button content'
            />
          </Segment>
        )}

        <Field
          component={FormInputCheckbox}
          inversed
          label='Show Footer'
          name='footer.hide'
          normalize={inverse}
          toggle
        />
        {formValues && !formValues.getIn(['footer', 'hide']) && (
          <Segment>
            <Field
              component={FormInputText}
              label='Skip'
              name='footer.skip'
              placeholder='Button content'
            />
            <Field
              component={FormInputText}
              label='Next'
              name='footer.next'
              placeholder='Button content'
            />
          </Segment>
        )}
      </Form>
    );
  }
}

NavFormEditor.propTypes = propTypes;
NavFormEditor.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const taskId = String(ownProps.taskId);
  return {
    form: `TaskEditor.nav.${taskId}`,
    formValues: state.getIn(['form', 'TaskEditor', 'nav', taskId, 'values']),
    initialValues: ownProps.nav,
  };
};

export default compose(connect(mapStateToProps), reduxForm({}))(NavFormEditor);
