// these are the extensions that were requested to  be supported
// however, some browsers might not support some of these ones
// also, we need to add manually these extensions to the api
// /api/config/v0.1/configuration/attachments.allowed_extensions/
const ALLOWED_VIDEO_EXTENSIONS = [
  '.mp4',
  '.m4a',
  '.m4p',
  '.m4b',
  '.m4r',
  '.m4v',
];

export default Object.freeze(ALLOWED_VIDEO_EXTENSIONS);
