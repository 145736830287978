import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
// globals
import EspUserPropShape from '../../globals/EspUserPropShape';

const propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  firstNameOnly: PropTypes.bool,
  formal: PropTypes.bool,
  lastNameOnly: PropTypes.bool,
  textOnly: PropTypes.bool, // whether to return a tet node alone
  user: EspUserPropShape,
};

const UserName = ({
  as: Component = 'span',
  firstNameOnly = false,
  formal = false,
  lastNameOnly = false,
  textOnly = false,
  user = Immutable.Map(),
  ...rest
}) => {
  let name;

  if (formal) {
    name = user.get('full_name');
  } else if (firstNameOnly) {
    name = user.get('nickname');
  } else if (lastNameOnly) {
    name = user.get('last_name');
  } else {
    const nickname = user.get('nickname', '');
    const lastName = user.get('last_name', '');

    name = `${nickname} ${lastName}`;
  }

  if (textOnly) {
    return name;
  }

  return <Component {...rest}>{name}</Component>;
};

UserName.propTypes = propTypes;

export default UserName;
