import React from 'react';
import { Segment } from 'semantic-ui-react';

const style = {
  height: 'calc(100% - 2em)',
  margin: '1em',
};

const LoadingMain = () => <Segment loading style={style} />;

export { LoadingMain };
