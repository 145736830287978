import React from 'react';
import PropTypes from 'prop-types';
import FormInputText from './FormInputText';
import { Field } from 'redux-form/immutable';

const propTypes = {
  component: PropTypes.func,
};

const ValidatedField = ({ component = FormInputText, ...props }) => (
  <Field component={component} {...props} />
);

ValidatedField.propTypes = propTypes;
export default ValidatedField;
