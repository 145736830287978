import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string,
};

const FirstBubble = ({ color }) => {
  const baseBubbleStyle = {
    borderBottom: `2em solid ${color}`,
    borderLeft: '0.5em solid transparent',
    borderRight: '0.5em solid transparent',
    display: 'block',
    height: 0,
    position: 'absolute',
    top: '-1.25em',
    width: 0,
  };

  const firstBubbleStyle = {
    borderBottom: 'none',
    borderTop: `2em solid ${color}`,
    bottom: '-1.25em',
    left: '1em',
    right: 'auto !important',
    top: 'auto !important',
    transform: 'rotate(-45deg)',
    transformOrigin: 'top right !important',
  };

  return (
    <div
      style={{
        ...baseBubbleStyle,
        ...firstBubbleStyle,
      }}
    />
  );
};

FirstBubble.propTypes = propTypes;

export default FirstBubble;
