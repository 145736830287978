import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import adminReportsThunks from '../../../../v1/actions/adminReportsThunks';
import reportDefinitions from '../../../../v1/globals/AdminReportDefinitions';
import toastNotificationsActions from '../../../../v1/actions/toastNotificationsActions';
import isCurrentUserAdmin from '../../../../v1/selectors/isCurrentUserAdmin';
import { INPUT_DATE_FORMAT } from '../../../../globals/DefaultInputFormats';
import moment from 'moment';
import async from 'async';
import { get } from 'lodash';

const mapDispatchToProps = (dispatch) => ({
  reportDisplayChart: (intentSurvey) => {
    const reportDefinition = reportDefinitions.survey;
    const query = {
      end_date: moment.utc().add(1, 'days').format(INPUT_DATE_FORMAT),
      freq: 'ALL_TIME',
      start_date: moment.utc().subtract(30, 'days').format(INPUT_DATE_FORMAT),
    };
    async.waterfall([
      (next) => {
        // 1. Look for survey id
        dispatch(adminReportsThunks.getSurveyId(intentSurvey)).then(
          (results) => {
            next(null, results.eid);
          }
        );
      },
      // 2. Start Job to fetch the report Data
      (surveyId, next) => {
        query.survey_id = surveyId;
        dispatch(
          adminReportsThunks.startJob(query, reportDefinition)
        ).then(() => next());
      },
      // 3. Start loop to get report data
      (next) => {
        const formatForAnnouncementSection = true;
        dispatch(
          adminReportsThunks.updateJobStatus(
            query,
            reportDefinition,
            formatForAnnouncementSection
          )
        )
          .then(() => next())
          .catch((error) => {
            dispatch(
              toastNotificationsActions.error({
                message: get(error, ['message'], 'Something went wrong'),
                title: 'Error',
              })
            );
          });
      },
    ]);
  },
});

const mapStateToProps = (state) => {
  const adminReports = state?.get('adminReports');
  const surveys = adminReports?.get('data')
    ? adminReports?.get('data')?.toJS()
    : [];

  return {
    isCurrentUserAdmin: isCurrentUserAdmin(state),
    isLoading:
      adminReports?.get('isLoadingData') ||
      adminReports?.get('isLoadingStatusUrl'),
    survey: adminReports?.get('survey'),
    surveys: surveys[0],
  };
};

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
