const api_barista_v0_1_kb_articles_get_kbs_by_source = {
  count: 3,
  results: [
    {
      kb_origin: 'wiki',
      total: 10,
    },
    {
      kb_origin: 'confluence',
      total: 5,
    },
    {
      kb_origin: 'service now',
      total: 41,
    },
  ],
};

export default api_barista_v0_1_kb_articles_get_kbs_by_source;
