const api_config_v0_1_configuration_dashboard_first_deployment_time = {
  DEBUG_decrypted: null,
  created_in_elc: false,
  eid: '6f031ca0-9d45-41a5-8ea6-f6205c3b8499',
  is_protected: false,
  key: 'dashboard.first_deployment_time',
  last_sync_time: null,
  src_active_status: true,
  sys_date_updated: '2020-11-10T23:05:43.018472Z',
  type: 'string',
  value: '2020-11-10 23:59:59+00:00',
};

export default api_config_v0_1_configuration_dashboard_first_deployment_time;
