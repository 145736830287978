import { createSelector } from 'reselect';

const unreadCount = (state, type) => {
  if (type === 'direct') {
    return state.getIn(['socialize', 'unreadCountDirect']);
  }
  return state.getIn(['socialize', 'unreadCountScoped']);
};

const getUnreadByType = (unreadCount) => unreadCount;

const selector = createSelector([unreadCount], getUnreadByType);

export default selector;
