import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

// Molecules
import EspHero from '../molecules/EspHero';
// Controller
import PreviewProfileDetailController from '../controllers/PreviewProfileDetailController';
// globals
import EspUserPropShape from '../../globals/EspUserPropShape';

class PreviewProfileDetail extends PureComponent {
  static propTypes = {
    forceReload: PropTypes.bool,
    isLoadingUser: PropTypes.bool,
    loadUser: PropTypes.func.isRequired,
    userData: EspUserPropShape,
    userId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    forceReload: false,
    isLoadingUser: false,
    userData: null,
  };

  componentDidMount() {
    const { forceReload, loadUser, userData, userId } = this.props;

    if (!userData || forceReload) {
      loadUser(userId, forceReload);
    }
  }
  render() {
    const { isLoadingUser, userData } = this.props;

    return (
      <div>
        {isLoadingUser ? (
          <Dimmer active inverted>
            <Loader size='large' />
          </Dimmer>
        ) : null}

        {userData ? <EspHero user={userData} /> : null}
        <div className='scrim black gradient' />
      </div>
    );
  }
}

const PreviewProfileDetailTest = PreviewProfileDetail;

// eslint-disable-next-line no-class-assign -- DEV-1526
PreviewProfileDetail = PreviewProfileDetailController(PreviewProfileDetail);
export { PreviewProfileDetailTest };
export default PreviewProfileDetail;
