/**
 * Utility to check the Exif data from an image
 * and return a value for a rotation
 * https://stackoverflow.com/questions/46354195/exif-data-from-mobiles-cameras-in-portrait-mode-messing-with-image-preview-i
 * @type {{}}
 */
const ExifRotation = (file, callback) => {
  const finalRotation = {
    1: 0,
    2: 0,
    3: 180,
    4: 0,
    5: 0,
    6: 90,
    7: 0,
    8: 270,
  };

  const fileReader = new FileReader();

  fileReader.onloadend = () => {
    const scanner = new DataView(fileReader.result);

    if (scanner.getUint16(0, false) !== 0xffd8) {
      return callback(0);
    }

    const length = scanner.byteLength;
    let offset = 2;
    let finalOffset = 1;

    while (offset < length) {
      const marker = scanner.getUint16(offset, false);
      offset += 2;
      if (marker === 0xffe1) {
        if (scanner.getUint32((offset += 2), false) !== 0x45786966) {
          finalOffset = 1;
          break;
        }

        const little = scanner.getUint16((offset += 6), false) === 0x4949;

        offset += scanner.getUint32(offset + 4, little);

        const tags = scanner.getUint16(offset, little);

        offset += 2;

        for (let i = 0; i < tags; i++) {
          if (scanner.getUint16(offset + i * 12, little) === 0x0112) {
            finalOffset = scanner.getUint16(offset + i * 12 + 8, little);
            break;
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break;
      } else {
        offset += scanner.getUint16(offset, false);
      }
    }
    return callback(finalRotation[finalOffset]);
  };

  fileReader.readAsArrayBuffer(file);
};

export default ExifRotation;
