import getMimeTypeFromBase64String from './getMimeTypeFromBase64String';

const imagesResizeTools = {
  convertFormData(data) {
    const base = {
      description: '',
      esp_catalog: data.id, // It's the product id - weird naming
      ordering: data.ordering,
      sys_custom_fields: null,
    };
    // Create and populate a formdata
    const finalData = new FormData();
    for (const key in base) {
      finalData.append(key, base[key]);
    }

    // for some weird reason API started to complain about image filename not having an extension,
    // so sending the image as blob.png now, that's the format we encode it to
    if (data.picture_480) {
      finalData.append('image_480', data.picture_480, 'blob.png');
    } else if (data.picture_960) {
      finalData.append('image_960', data.picture_960, 'blob.png');
    }

    return finalData;
  },

  convertFormDataBundle(data) {
    const base = {
      bundle: data.id, // It's the product id - weird naming
      description: '',
      ordering: 0,
      sys_custom_fields: null,
    };
    // Create and populate a form data
    const finalData = new FormData();
    for (const key in base) {
      finalData.append(key, base[key]);
    }

    // an extension is required for the filename
    finalData.append('image_960', data.picture_960, 'blob.png');

    return finalData;
  },

  convertFormDataServiceTeam(data) {
    const base = {
      description: '',
      ordering: 0,
      service_team: data.id,
      sys_custom_fields: null,
    };
    // Create and populate a form data
    const finalData = new FormData();
    for (const key in base) {
      finalData.append(key, base[key]);
    }

    // an extension is required for the filename
    finalData.append('image_960', data.picture_960, 'blob.png');

    return finalData;
  },

  createImg(imgCtx, size) {
    const canvas = document.createElement('canvas');
    canvas.width = imgCtx.naturalWidth;
    canvas.height = imgCtx.naturalHeight;

    // Logic to resize canvas size
    if (imgCtx.naturalWidth > imgCtx.naturalHeight) {
      if (imgCtx.naturalWidth > size) {
        canvas.width = size;
        canvas.height = (imgCtx.naturalHeight * size) / imgCtx.naturalWidth;
      }
    } else {
      if (imgCtx.naturalHeight > size) {
        canvas.height = size;
        canvas.width = (imgCtx.naturalWidth * size) / imgCtx.naturalHeight;
      }
    }

    canvas
      .getContext('2d')
      .drawImage(imgCtx, 0, 0, canvas.width, canvas.height);

    return imagesResizeTools.dataURItoBlob(canvas.toDataURL('image/png'));
  },

  dataURItoBlob(imgData) {
    const byteString = atob(imgData.split(',')[1]);
    const mimeString = getMimeTypeFromBase64String(imgData);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {
      type: mimeString,
    });
  },
};

export default imagesResizeTools;
