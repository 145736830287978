import { noop } from 'lodash';
import async from 'async';
import Immutable, { fromJS } from 'immutable';
import EspFilters from 'esp-util-filters';
// Globals
import { SortFieldNames } from '../globals/SortProductStateOptions';
// Utils
import endpointGenerator from '../utils/endpointGenerator';
import APIcall from '../utils/APIcall';

// Thunks
import dpcActions from './dpcActions';
import catalogThunks from './catalogThunks';
import catalogActions from './catalogActions';

// load DPC sub categories
// @param <Array of integers> categories
const loadDPCSubCategories = (categories) => (dispatch) => {
  dispatch(dpcActions.loadSubCategoriesStart());
  const endpoint = endpointGenerator.genPath('espCatalog.dpc.category');

  APIcall.get({
    error: function (err) {
      dispatch(dpcActions.loadSubCategoriesError(err));
    },
    query: {
      esp_filters: `id__IN=${categories.join(',')}`,
    },
    success: function ({ body }) {
      dispatch(dpcActions.loadSubCategoriesSuccess(body.results));
    },
    token: true,
    url: endpoint,
  });
};

const dpcThunks = {};

// Loads DPC top Categories
// @param <Boolean> loadSubcategories
// @param <Function> cb
dpcThunks.loadDPCCategories = (loadSubcategories = true) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(dpcActions.loadTopCategoriesStart());
    const endpoint = endpointGenerator.genPath('espCatalog.dpc.category.top');

    APIcall.get({
      error: function (err) {
        dispatch(dpcActions.loadTopCategoriesError(err));
        reject(err);
      },
      success: function (res) {
        const categories = res.body.results;
        // child category ids
        const subCategories = categories
          .map((category) => category.children)
          .flatMap((categoryID) => categoryID);

        dispatch(dpcActions.loadTopCategoriesSuccess(categories));
        if (loadSubcategories) {
          dispatch(loadDPCSubCategories(subCategories));
        }
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

dpcThunks.setCurrentDPCCategory = (categoryID, isSubCategory) => (dispatch) => {
  dispatch(dpcActions.setCurrentDPCCategory(categoryID, isSubCategory));
};

dpcThunks.toggleDpcProductSelect = (productEid, selectedProducts) => (
  dispatch
) => {
  if (selectedProducts.find((eid) => eid === productEid)) {
    dispatch(dpcActions.removeDpcProductSelect(productEid));
  } else {
    dispatch(dpcActions.addDpcProductSelect(productEid));
  }
};

dpcThunks.includeItemsIHaveIsChecked = () => (dispatch) => {
  dispatch(dpcActions.includeItemsIHaveIsChecked());
};

dpcThunks.setOrderByFilter = (orderBy) => (dispatch) => {
  dispatch(dpcActions.setOrderByFilter(orderBy));
};

const copyItemsToCatalog = (eids = Immutable.List(), cb = noop) => {
  const endpoint = endpointGenerator.genPath('espCatalog.dpci');
  APIcall.post({
    data: {
      products: eids.toJS(),
    },
    error(err) {
      cb(err);
    },
    success({ body: { products_requested } }) {
      cb(null, products_requested);
    },
    token: true,
    url: endpoint,
  });
};

const loadProductByEid = (eids = Immutable.List(), cb = noop) => {
  const endpoint = endpointGenerator.genPath('espCatalog.products');
  APIcall.get({
    error(err) {
      cb(err);
    },
    query: {
      esp_filters: `sys_dpc_parent.eid__IN=${eids.join(',')}`,
    },
    success({ body: { results = [] } }) {
      cb(null, results);
    },
    token: true,
    url: endpoint,
  });
};

const loadProductWithFamilies = (eids = Immutable.List(), cb = noop) => {
  APIcall.get({
    error(err) {
      cb(err);
    },
    query: {
      esp_filters: `eid__IN=${eids.join(',')}`,
    },
    success({ body: { results = [] } }) {
      const products = fromJS(results);
      const families = products
        .map((p) => p.get('product_family') || null)
        .filterNot((i) => i === null);
      cb(null, families);
    },
    token: true,
    url: endpointGenerator.genPath('espCatalog.dpc.products'),
  });
};

const loadProductFamilyProducts = (
  families = Immutable.List(),
  eids,
  cb = noop
) => {
  if (families.size === 0) {
    cb(null, eids);
  } else {
    APIcall.get({
      error(err) {
        cb(err);
      },
      query: {
        esp_filters: `id__IN=${families.join(',')}`,
      },
      success({ body: { results = [] } }) {
        // Get list of products in each product family
        let productEids = results.reduce((arr, entry) => {
          const { products } = entry; // Property that return the list of each per family
          return [...arr, ...products.map((pf) => pf.eid)];
        }, []);

        // Copy missing eids and avoid duplicate entries
        productEids = [...new Set([...productEids, ...eids.toJS()])];

        cb(null, fromJS(productEids)); // Send immutable version of the array
      },
      token: true,
      url: endpointGenerator.genPath('espCatalog.dpc.poption_set'),
    });
  }
};

dpcThunks.copyToCatalog = (eids) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(dpcActions.copyToCatalogStart());
    async.waterfall(
      [
        (next) => loadProductWithFamilies(eids, next),

        (families, next) => loadProductFamilyProducts(families, eids, next),

        // copy items to Catalog
        (allEids, next) => copyItemsToCatalog(allEids, next),

        // load catalog products that we just copy
        (productEids, next) => loadProductByEid(productEids, next),
      ],
      (error) => {
        if (!error) {
          dispatch(dpcActions.copyToCatalogSuccess());
          eids.forEach((eid) => {
            dispatch(dpcActions.removeDpcProductSelect(eid));
            dispatch(dpcActions.removeDpcProduct(eid));
          });

          resolve();
        } else {
          dispatch(dpcActions.copyToCatalogError());
          eids.forEach((eid) => {
            dispatch(dpcActions.removeDpcProductSelect(eid));
          });
          reject();
        }
      }
    );
  });

dpcThunks.loadOneProduct = (productID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(dpcActions.loadSingleProductStart());

    const endpoint = endpointGenerator.genPath('espCatalog.dpc.products');
    APIcall.get({
      error() {
        dispatch(dpcActions.loadSingleProductError());
        reject();
      },
      query: {
        inc_owned: 1,
      },
      success({ body }) {
        dispatch(catalogActions.loadOneProductSuccess(body.id, body));
        dispatch(dpcActions.loadSingleProductSuccess(body)); // todo - need to clean that at some point
        resolve(body);
      },
      token: true,
      url: `${endpoint}${productID}/`,
    });
  });

dpcThunks.loadProductFamily = (familyID) => (dispatch) => {
  dispatch(dpcActions.setProductFamilyStart());

  const endpoint = endpointGenerator.genPath(
    'espCatalog.dpc.poption_set.instance',
    {
      familyID,
    }
  );

  return APIcall.get({
    error() {
      dispatch(dpcActions.setProductFamilyFail());
    },
    success(res) {
      dispatch(dpcActions.setProductFamilySuccess(res.body));
    },
    token: true,
    url: endpoint,
  });
};

//
// DPC search
//

dpcThunks.dpcSearch = (options = {}) => (dispatch) =>
  new Promise((resolve, reject) => {
    const optionsQuery = options.query;
    const limit = options.limit || 1;
    const page = options.page || 1;
    const subcategoryID = options.subcategoryID || 0;
    const categoryID = options.categoryID || 0;
    const orderBy = options.orderBy || SortFieldNames.NEGATIVE_SYS_DATE_CREATED;
    const searchTerm = options.searchTerm || '';
    const showItemsIhave =
      options.inc_owned || (optionsQuery ? optionsQuery.incOwned : '');

    dispatch(dpcActions.loadDpcStart());

    //
    // Pagination
    //
    const query = {};
    query.limit = limit;
    query.offset = (page - 1) * limit;

    //
    // Esp Filters
    //
    const espFilters = new EspFilters();

    if (searchTerm) {
      espFilters.contains('name', searchTerm);
    }

    if (subcategoryID) {
      espFilters.equalTo('category.id', subcategoryID);
    } else if (categoryID) {
      espFilters.in('category_all.id', [categoryID]);
    }
    if (espFilters.asQueryString()) {
      query.esp_filters = espFilters.asQueryString();
    }

    //
    // OrderBy
    //
    query.order_by = orderBy;

    //
    // Include items that I have filter
    //
    if (showItemsIhave) {
      query.inc_owned = '1';
    }

    //
    // Squash flag always true
    //
    query.squash_pf = '1';

    const endpoint = endpointGenerator.genPath('espCatalog.dpc.products');

    APIcall.get({
      error: function (err) {
        dispatch(dpcActions.loadDpcError(err));
        reject(err);
      },
      query: query,
      success: function (res) {
        const products = res.body.results || [];
        const count = res.body.count || 0;
        const { next } = res.body;
        const { previous } = res.body;
        dispatch(dpcActions.loadDpcSuccess(products, count, next, previous));
        const eids = products.map((p) => p.eid);
        dispatch(catalogThunks.loadProductsByEid(null, eids));
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

dpcThunks.loadSubCategoriesDPC = (categoryId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.loadSubCategoriesStart(categoryId));

    const endpoint = endpointGenerator.genPath(
      'espCatalog.dpc.category.instance.subcategories',
      {
        categoryID: categoryId,
      }
    );

    return APIcall.get({
      error(err) {
        dispatch(catalogActions.loadSubCategoriesFailure(categoryId, err));
        reject(err);
      },
      success(res) {
        dispatch(
          catalogActions.loadSubCategoriesSuccess(categoryId, res.body.results)
        );
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

dpcThunks.loadSubCategoryDPC = (categoryId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.loadSubCategoriesStart(categoryId));

    const endpoint = endpointGenerator.genPath(
      'espCatalog.dpc.category.instance',
      {
        categoryID: categoryId,
      }
    );

    return APIcall.get({
      error(err) {
        dispatch(catalogActions.loadSubCategoriesFailure(categoryId, err));
        reject(err);
      },
      success(res) {
        dispatch(
          catalogActions.loadSubCategoriesSuccess(categoryId, res.body.results)
        );
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

export default dpcThunks;
