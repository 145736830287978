import React, { PureComponent } from 'react';
import { Form, Icon, Input, Segment } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { intl } from 'esp-util-intl';
import { noop } from 'lodash';
import { Field } from 'redux-form/immutable';

// Atoms
import UserName from '../../atoms/UserName';
// Molecules
import UserSelector from '../../molecules/UserSelector';
import EspMetaVariables from '../../molecules/EspMetaVariables';
// Organisms
import BlockModal from '../../organisms/BlockModal';
// Global
import TypeUserSelect, {
  TypeDefaults,
} from '../../../globals/blocks/TypeUserSelect';

// Controller
import UserSelect01Controller from '../../controllers/UserSelect01Controller';

class UserSelect01 extends PureComponent {
  static propTypes = {
    ...TypeUserSelect,
    changeSelectedTarget: PropTypes.func.isRequired,
    /** formValue can be one of this 2 types we use target mentor_id or any other */
    formValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]),

    getDefaultSelectedUser: PropTypes.func, // From Controller

    targetRequestedFor: ImmutablePropTypes.map, // From controller
    updateWorkflowRequestedForUser: PropTypes.func, // From Controller
    workflowRequestedFor: ImmutablePropTypes.map, // From Controller
  };

  static defaultProps = {
    ...TypeDefaults,
    formValue: null,
    getDefaultSelectedUser: noop,
    targetRequestedFor: null,
    updateWorkflowRequestedForUser: noop,
    workflowRequestedFor: null,
  };

  state = {
    selectedUser: null,
  };
  componentDidMount() {
    const { getDefaultSelectedUser, target, formValue, workflowRequestedFor } =
      this.props;
    if (target === 'mentor_id' || !target) {
      // pull mentor user if one is set
      const mentorID = workflowRequestedFor.get('mentor_id');
      if (mentorID) {
        getDefaultSelectedUser(mentorID);
      }
    } else if (formValue) {
      getDefaultSelectedUser(Number(formValue));
    }
    // Check if a mentor has been already selected
    this.setMentor();
  }

  componentDidUpdate() {
    const { target } = this.props;
    if (target === 'mentor_id' || !target) {
      // Check if a mentor has been already selected
      this.setMentor();
    }
  }

  setMentor = () => {
    const {
      id,
      changeSelectedTarget,
      formValue,
      target,
      workflowRequestedFor,
    } = this.props;

    // Check if a mentor has already been set
    let { selectedUser } = this.state;
    if (!selectedUser && (target === 'mentor_id' || !target)) {
      const defaultSelectedUser = workflowRequestedFor.get('mentor');
      selectedUser = defaultSelectedUser;
    }

    // Set input to redux form if a selected mentor exists
    if (!formValue && selectedUser && !selectedUser.isEmpty()) {
      const mentorUrl = selectedUser.get('url');
      changeSelectedTarget(
        {
          mentor: mentorUrl,
        },
        id
      );
    }
  };

  setUserSelectModal = (userSelectModal) => {
    this.userSelectModal = userSelectModal;
  };

  handleSelectUser = (user) => {
    const { changeSelectedTarget, id, target, updateWorkflowRequestedForUser } =
      this.props;
    const { selectedUser } = this.state;

    // immutable shallow comparison

    const didChange = selectedUser !== user;

    if (!didChange) {
      // close modal if open
      this.userSelectModal.close();

      return;
    }

    this.setState({
      selectedUser: user,
    });

    this.userSelectModal.close();

    // optimistically update user's mentor with selected one
    /** @type {string} */
    const mentorUrl = user.get('url');
    const mentorID = user.get('id');

    const changes =
      target === 'mentor_id'
        ? {
            mentor: mentorUrl,
          }
        : mentorID;

    // Change in redux form the value
    changeSelectedTarget(changes, id);

    if (target === 'mentor_id' || !target) {
      updateWorkflowRequestedForUser(changes);
    }
  };

  handleIWillBeTheMentorClick = () => {
    const { currentUser } = this.props;
    this.handleSelectUser(currentUser);
  };

  fieldValidated = (value) => {
    const { inputRequired } = this.props;
    // if a value is returned,
    // this Validation will make the redux-form entering in a error state
    // which will prevent to active the NEXT button

    if (inputRequired && !value) {
      return 'Error';
    }
    return null;
  };

  renderInputHidden = (props) => {
    const { meta, ...rest } = props;

    return <Input {...rest} />;
  };

  render() {
    const {
      readOnly,
      target,
      targetRequestedFor,
      template,
      type,
      workflowRequestedFor,
      id,
    } = this.props;

    /** NOTE: This list should grow will all possible target */
    const targetList = {
      manager_id: intl.formatMessage({
        id: 'label.manager',
      }),
    };

    const msgPickup =
      target === 'mentor_id' || !target
        ? intl.formatMessage({
            id: 'label.pick_a_buddy',
          })
        : `Choose a ${targetList[target]}`;

    const msgIWillBe =
      target === 'mentor_id' || !target
        ? intl.formatMessage({
            id: 'label.ill_be_the_buddy',
          })
        : `I will be the ${targetList[target]}`;

    let { selectedUser } = this.state;
    let userNameManager;

    if (!selectedUser) {
      const defaultSelectedUser =
        workflowRequestedFor.get('mentor') || targetRequestedFor;
      selectedUser = defaultSelectedUser;
      userNameManager =
        selectedUser && !selectedUser.isEmpty()
          ? `${selectedUser.get('nickname')} ${selectedUser.get('last_name')}`
          : '';
    }

    const triggerModal = (
      <Form.Field>
        {target && target !== 'mentor_id' && (
          <label>{<EspMetaVariables text={targetList[target]} />}</label>
        )}
        <Segment
          className='link'
          data-test-id='pick-mentor-button'
          textAlign='center'
        >
          {selectedUser && !selectedUser.isEmpty() ? (
            <div className='content'>
              <UserName user={selectedUser} />
              <Icon
                className='right floated'
                color='green'
                name='check'
                size='large'
              />
            </div>
          ) : (
            <EspMetaVariables text={msgPickup} />
          )}
        </Segment>
      </Form.Field>
    );

    const readOnlyRender = (
      <Form.Field>
        {target && target !== 'mentor_id' && (
          <label>{<EspMetaVariables text={targetList[target]} />}</label>
        )}
        <Input readOnly transparent value={userNameManager} />
      </Form.Field>
    );

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          className='hidden'
          component={this.renderInputHidden}
          hidden
          name={id}
          type='hidden'
          validate={this.fieldValidated}
        />
        {readOnly && readOnlyRender}

        {!readOnly && (
          <BlockModal
            className={template}
            modalTitle='Select User'
            ref={this.setUserSelectModal}
            trigger={triggerModal}
            useScroll={false}
            withFooter={false}
          >
            <UserSelector
              defaultSelectedUser={selectedUser}
              onUserSelect={this.handleSelectUser}
            />
          </BlockModal>
        )}

        {!readOnly && (
          <Segment
            basic
            className='link'
            onClick={this.handleIWillBeTheMentorClick}
            textAlign='center'
          >
            <EspMetaVariables text={msgIWillBe} />
          </Segment>
        )}
      </div>
    );
  }
}
const UserSelect01Test = UserSelect01;

// eslint-disable-next-line no-class-assign -- DEV-1526
UserSelect01 = UserSelect01Controller(UserSelect01);

export { UserSelect01Test };

export default UserSelect01;
