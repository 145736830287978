import { useCallback, useEffect, useState } from 'react';
import { loadTemplate } from '../endpoints/APITemplate';

const useLoadTemplate = (templateID) => {
  const [template, setTemplate] = useState({});
  const [isLoading, setLoading] = useState(false);
  const ERROR_STATUS = 400;

  const initialize = useCallback(async (templateID) => {
    const response = await loadTemplate(templateID);

    if (response?.status !== ERROR_STATUS) {
      setTemplate(response?.body);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);

    if (templateID !== 'new') {
      initialize(templateID);
    }
  }, [initialize, templateID]);

  return {
    isTemplatesLoading: isLoading,
    template,
  };
};

export default useLoadTemplate;
