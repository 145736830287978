const api_task_v0_1_categories = {
  count: 1,
  results: [
    {
      ancestry: null,
      children: [],
      code: 'usa',
      id: 1,
      location_type: 1,
      location_type_name: 'country',
      name: 'USA',
      parent: null,
      sys_date_created: '2018-02-16T18:23:37.122073Z',
      sys_date_updated: '2018-02-16T18:23:37.122032Z',
      url: 'http://tenant1.esp/api/places/v0.1/locations/1/',
    },
  ],
};

export default api_task_v0_1_categories;
