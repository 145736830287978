import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsMentor extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M57.89 49.46c-.068-.842-.068-1.688 0-2.53 4.5-4.08 4.84-9 4.59-19.61-.25-10.2-7.72-13.82-14-13.82s-13.83 3.62-14.03 13.82c-.26 10.62.09 15.52 4.59 19.61.068.842.068 1.688 0 2.53-3.536.85-7.002 1.967-10.37 3.34-8.1 3.34-12.22 7.32-12.22 11.81v6.44c0 .828.672 1.5 1.5 1.5h24.63l5.87 9.87 5.66-9.87h24.77c.828 0 1.5-.672 1.5-1.5v-6.44c0-9.24-17.51-13.94-22.49-15.15zm19.49 20.09H54.53l-4.54-15 1.22-4.64h-5l1.24 4.68-5.19 15H19.45v-4.98c0-3.93 5.56-7.08 10.23-9 3.566-1.443 7.245-2.59 11-3.43.63-.133 1.104-.652 1.18-1.29.225-1.66.225-3.34 0-5-.072-.316-.244-.6-.49-.81-3.73-3.17-4.21-6.8-3.95-17.62.25-10.11 8.51-10.89 11-10.89s10.79.79 11 10.89c.26 10.82-.22 14.45-3.95 17.62-.246.21-.418.494-.49.81-.225 1.66-.225 3.34 0 5 .076.638.55 1.157 1.18 1.29 5.5 1.15 21.19 5.92 21.19 12.46l.03 4.91z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CardsMentor;
