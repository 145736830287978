import UserImageColors from '../globals/UserImageColors';

/**
 * Return a color in correlation with the user ID
 * and the list of colors set
 * @param userID (int)
 * @returns {string} The color
 */
const getUserColorsById = (userID) => {
  let seed = userID;

  // Be sure that the seed in not a string
  if (typeof seed === 'string') {
    seed = Number(seed);

    if (isNaN(seed)) {
      // eslint-disable-next-line no-console -- debugging
      console.warn('Error - This user id is not a number');
      return UserImageColors[0]; // Fall back to return always a color
    }
  }

  return UserImageColors[seed % UserImageColors.length];
};

export default getUserColorsById;
