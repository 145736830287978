import { Icon, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { curry, memoize } from 'lodash';

// Globals
import AppOS from '../../globals/AppOS';

class SendDownloadAppSMS extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {};

  handleSelectOS = memoize(
    curry((os, e) => {
      const { input } = this.props;
      input.onChange(os);
    })
  );

  render() {
    const { input } = this.props;
    const selectedOS = input.value;

    return (
      <>
        <Segment className='link' onClick={this.handleSelectOS(AppOS.ANDROID)}>
          <Icon name='android' size='large' />
          {'Android'}
          {selectedOS === AppOS.ANDROID && (
            <Icon
              className='right floated'
              color='green'
              name='check'
              size='large'
            />
          )}
        </Segment>
        <Segment className='link' onClick={this.handleSelectOS(AppOS.IOS)}>
          <Icon name='apple' size='large' />
          {'iOS'}
          {selectedOS === AppOS.IOS && (
            <Icon
              className='right floated'
              color='green'
              name='check'
              size='large'
            />
          )}
        </Segment>
      </>
    );
  }
}
export default SendDownloadAppSMS;
