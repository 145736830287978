import React, { useEffect } from 'react';
import pt from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
import { Form, Segment } from 'semantic-ui-react';
import { ACTION_TYPES } from '../../../../../v1/globals/announcementCustomActionConstants';

const propTypes = {
  action: ImmutablePropTypes.mapContains({
    action_url: pt.string,
    button_text: pt.string.isRequired,
    button_type: pt.string.isRequired,
    intent_eid: pt.string,
    type: pt.oneOf(Object.values(ACTION_TYPES)),
  }).isRequired,
  date: pt.string.isRequired,
  intentsList: ImmutablePropTypes.list,
  isLoadingIntent: pt.bool,
  loadIntentByEid: pt.func.isRequired,
};

const ActionButtonInfo = ({
  action,
  date,
  intentsList = Immutable.List(),
  isLoadingIntent = false,
  loadIntentByEid,
}) => {
  const isIntentActionType = action.get('type') === ACTION_TYPES.INTENT;

  useEffect(() => {
    if (
      !isLoadingIntent &&
      isIntentActionType &&
      (intentsList.isEmpty() ||
        !intentsList.find(
          (intent) => intent.get('eid') === action.get('intent_eid')
        ))
    ) {
      loadIntentByEid(action.get('intent_eid'));
    }
  }, [
    action,
    intentsList,
    isIntentActionType,
    isLoadingIntent,
    loadIntentByEid,
  ]);

  let intentName = '';
  if (!isLoadingIntent && !intentsList.isEmpty() && isIntentActionType) {
    const intent = intentsList.find(
      (intent) => intent.get('eid') === action.get('intent_eid')
    );
    if (intent) {
      intentName = intent.get('name', '');
    }
  }

  return (
    <>
      <Form.Input
        fluid
        label={intl.formatMessage({ id: 'label.action_button' })}
        transparent
        value={date}
      />
      <Segment basic style={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}>
        <Form.Input
          fluid
          label={intl.formatMessage({ id: 'label.text' })}
          transparent
          value={action.get('button_text')}
        />
        {action.get('type') === ACTION_TYPES.URL && (
          <Form.Input
            fluid
            label={intl.formatMessage({ id: 'label.external_url' })}
            transparent
            value={action.get('action_url', '')}
          />
        )}
        {isIntentActionType && (
          <Form.Input
            fluid
            label={intl.formatMessage({ id: 'label.intent' })}
            loading={isLoadingIntent}
            transparent
            value={intentName}
          />
        )}
      </Segment>
    </>
  );
};

ActionButtonInfo.propTypes = propTypes;

export default ActionButtonInfo;
