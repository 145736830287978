import buildCompositeController from '../../utils/buildCompositeController';
import uiPathGenerator from '../../utils/uiPathGenerator';

const mapDispatchToProps = () => ({
  exitWorkflow() {
    if (window.cordova || window.electron) {
      document.location = 'index.html';
    } else {
      window.location = uiPathGenerator.genPath('app.toDo');
    }
  },
});

export default buildCompositeController(null, mapDispatchToProps);
