import actionTypes from './actionTypes';

const integrationActions = {};

integrationActions.setConfluenceCredentialsStart = () => ({
  type: actionTypes.SET_CONFLUENCE_CREDENTIALS_START,
});
integrationActions.setConfluenceCredentialsSuccess = (response) => ({
  response,
  type: actionTypes.SET_CONFLUENCE_CREDENTIALS_SUCCESS,
});
integrationActions.setConfluenceCredentialsFail = (errors) => ({
  errors,
  type: actionTypes.SET_CONFLUENCE_CREDENTIALS_FAIL,
});

integrationActions.getConfluenceCredentialsStart = () => ({
  type: actionTypes.GET_CONFLUENCE_CREDENTIALS_START,
});
integrationActions.getConfluenceCredentialsSuccess = (response) => ({
  response,
  type: actionTypes.GET_CONFLUENCE_CREDENTIALS_SUCCESS,
});
integrationActions.getConfluenceCredentialsFail = (errors) => ({
  errors,
  type: actionTypes.GET_CONFLUENCE_CREDENTIALS_FAIL,
});

integrationActions.testConfluenceSettingsStart = () => ({
  type: actionTypes.TEST_CONFLUENCE_SETTINGS_START,
});

integrationActions.testConfluenceSettingsSuccess = (results) => ({
  results,
  type: actionTypes.TEST_CONFLUENCE_SETTINGS_SUCCESS,
});

integrationActions.testConfluenceSettingsFail = () => ({
  type: actionTypes.TEST_CONFLUENCE_SETTINGS_FAILURE,
});

integrationActions.loadConfluenceContentSyncStart = () => ({
  type: actionTypes.LOAD_CONFLUENCE_CONTENT_SYNC_START,
});

integrationActions.loadConfluenceContentSyncSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_CONFLUENCE_CONTENT_SYNC_SUCCESS,
});

integrationActions.loadConfluenceLastSyncFail = () => ({
  type: actionTypes.LOAD_CONFLUENCE_LAST_SYNC_FAILURE,
});

integrationActions.loadConfluenceLastSyncStart = () => ({
  type: actionTypes.LOAD_CONFLUENCE_LAST_SYNC_START,
});

integrationActions.loadConfluenceLastSyncSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_CONFLUENCE_LAST_SYNC_SUCCESS,
});

integrationActions.loadConfluenceContentSyncFail = () => ({
  type: actionTypes.LOAD_CONFLUENCE_CONTENT_SYNC_FAILURE,
});

integrationActions.azureAccountDeleteStart = () => ({
  type: actionTypes.AZURE_ACCOUNT_DELETE_START,
});

integrationActions.azureAccountDeleteSuccess = () => ({
  type: actionTypes.AZURE_ACCOUNT_DELETE_SUCCESS,
});

integrationActions.azureAccountDeleteFail = () => ({
  type: actionTypes.AZURE_ACCOUNT_DELETE_FAILURE,
});

integrationActions.azureAccountLoadStart = () => ({
  type: actionTypes.AZURE_ACCOUNT_LOAD_START,
});

integrationActions.azureAccountLoadSuccess = () => ({
  type: actionTypes.AZURE_ACCOUNT_LOAD_SUCCESS,
});

integrationActions.azureAccountLoadFail = () => ({
  type: actionTypes.AZURE_ACCOUNT_LOAD_FAILURE,
});

integrationActions.azureAccountSaveStart = () => ({
  type: actionTypes.AZURE_ACCOUNT_SAVE_START,
});

integrationActions.azureAccountSaveSuccess = (azureAuth) => ({
  azureAuth,
  type: actionTypes.AZURE_ACCOUNT_SAVE_SUCCESS,
});

integrationActions.azureAccountSaveFail = () => ({
  type: actionTypes.AZURE_ACCOUNT_SAVE_FAILURE,
});

integrationActions.startGetBusinessObjects = () => ({
  type: actionTypes.ADD_INTEGRATION_BUSINESS_OBJECTS_START,
});

integrationActions.getBusinessObjectsSuccess = (businessObjects) => ({
  businessObjects,
  type: actionTypes.ADD_INTEGRATION_BUSINESS_OBJECTS_SUCCESS,
});

integrationActions.startUploadCsv = () => ({
  type: actionTypes.START_UPLOAD_CSV,
});

integrationActions.uploadCsvSuccess = () => ({
  type: actionTypes.UPLOAD_CSV_SUCCESS,
});

integrationActions.uploadCsvError = () => ({
  type: actionTypes.UPLOAD_CSV_FAIL,
});

integrationActions.loadServicenowWorkflowMapsStart = () => ({
  type: actionTypes.LOAD_SNOW_WORKFLOW_MAP_START,
});

integrationActions.loadServicenowWorkflowMapsSuccess = (snWorkflowMap) => ({
  snWorkflowMap,
  type: actionTypes.LOAD_SNOW_WORKFLOW_MAP_SUCCESS,
});

integrationActions.saveServicenowWorkflowMapSuccess = (
  snWorkflowMapInstance
) => ({
  snWorkflowMapInstance,
  type: actionTypes.SAVE_SNOW_WORKFLOW_MAP_SUCCESS,
});

integrationActions.deleteServicenowWorkflowMapSuccess = (
  catalogCategoryId
) => ({
  catalogCategoryId,
  type: actionTypes.DELETE_SNOW_WORKFLOW_MAP_SUCCESS,
});

integrationActions.loadGenericTableStart = (tableName, field) => ({
  field,
  tableName,
  type: actionTypes.LOAD_GENERIC_TABLE_START,
});
integrationActions.loadGenericTableFail = (tableName, field) => ({
  field,
  tableName,
  type: actionTypes.LOAD_GENERIC_TABLE_FAIL,
});
integrationActions.loadGenericTableSuccess = (
  tableName,
  field,
  results,
  displayValue,
  realValue
) => ({
  displayValue,
  field,
  realValue,
  results,
  tableName,
  type: actionTypes.LOAD_GENERIC_TABLE_SUCCESS,
});

integrationActions.loadSnowProductsSuccess = (pagination) => ({
  pagination,
  type: actionTypes.LOAD_SNOW_PRODUCTS_SUCCESS,
});

integrationActions.loadSnowCategoriesStart = () => ({
  type: actionTypes.LOAD_SNOW_CATEGORIES_START,
});

integrationActions.loadSnowCategoriesSuccess = (categories) => ({
  categories,
  type: actionTypes.LOAD_SNOW_CATEGORIES_SUCCESS,
});

integrationActions.loadSnowCredentialsStart = () => ({
  type: actionTypes.LOAD_SNOW_CREDENTIALS_START,
});

integrationActions.loadSnowCredentialsSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_SNOW_CREDENTIALS_SUCESS,
});

integrationActions.loadSnowCredentialsFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_SNOW_CREDENTIALS_FAIL,
});

integrationActions.loadSnowConnectionStatusStart = () => ({
  type: actionTypes.LOAD_SNOW_CONNECTION_STATUS_START,
});

integrationActions.loadSnowConnectionStatusSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_SNOW_CONNECTION_STATUS_SUCCESS,
});

integrationActions.loadSnowConnectionStatusFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_SNOW_CONNECTION_STATUS_FAIL,
});

integrationActions.loadCsvImportHistoryStart = () => ({
  type: actionTypes.LOAD_CSV_IMPORT_HISTORY_START,
});

integrationActions.loadCsvImportHistorySuccess = (
  importHistoryResults,
  importHistoryResultsCount
) => ({
  importHistoryResults,
  importHistoryResultsCount,
  type: actionTypes.LOAD_CSV_IMPORT_HISTORY_SUCCESS,
});

integrationActions.loadCsvImportHistoryFailure = () => ({
  type: actionTypes.LOAD_CSV_IMPORT_HISTORY_FAILURE,
});

integrationActions.setCVSSchedulesStart = () => ({
  type: actionTypes.SET_CSV_SCHEDULE_START,
});

integrationActions.setCVSSchedulesError = (error) => ({
  error,
  type: actionTypes.SET_CSV_SCHEDULE_ERROR,
});

integrationActions.setCVSSchedulesSuccess = (schedules) => ({
  schedules,
  type: actionTypes.SET_CSV_SCHEDULE_SUCCESS,
});

integrationActions.updateCVSSchedulesSuccess = (schedule) => ({
  schedule,
  type: actionTypes.UPDATE_CSV_SCHEDULE_SUCCESS,
});

integrationActions.setTestCVSSchedulesStart = () => ({
  type: actionTypes.TEST_CSV_SCHEDULE_CONNECTION_START,
});

integrationActions.setTestCVSSchedulesSuccess = () => ({
  type: actionTypes.TEST_CSV_SCHEDULE_CONNECTION_SUCCESS,
});

integrationActions.setTestCVSSchedulesError = () => ({
  type: actionTypes.TEST_CSV_SCHEDULE_CONNECTION_ERROR,
});

integrationActions.setTestCVSSchedulesReset = () => ({
  type: actionTypes.TEST_CSV_SCHEDULE_CONNECTION_RESET,
});

integrationActions.deleteCsvScheduleStart = (scheduleToDelete) => ({
  scheduleToDelete,
  type: actionTypes.DELETE_CSV_SCHEDULE_START,
});

integrationActions.deleteCsvScheduleSuccess = () => ({
  type: actionTypes.DELETE_CSV_SCHEDULE_SUCCESS,
});

integrationActions.deleteCsvScheduleFailure = (scheduleToDelete, error) => ({
  error,
  scheduleToDelete,
  type: actionTypes.DELETE_CSV_SCHEDULE_FAILURE,
});

integrationActions.sendInvitesStart = () => ({
  // Right now we send all invitations at once
  type: actionTypes.SEND_INVITES_START,
});

integrationActions.sendInvitesSuccess = (results) => ({
  results,
  type: actionTypes.SEND_INVITES_SUCCESS,
});

integrationActions.sendInvitesFailure = (invitesImportId, error) => ({
  error,
  type: actionTypes.SEND_INVITES_FAILURE,
});

integrationActions.oktaAccountLoadStart = (invitesTrackingUrl, error) => ({
  error,
  invitesTrackingUrl,
  type: actionTypes.OKTA_ACCOUNT_START,
});
integrationActions.oktaAccountSaveStart = (invitesTrackingUrl, error) => ({
  error,
  invitesTrackingUrl,
  type: actionTypes.OKTA_ACCOUNT_SAVE_START,
});
integrationActions.oktaAccountLoadSuccess = (oktaAuth) => ({
  oktaAuth,
  type: actionTypes.OKTA_ACCOUNT_SUCCESS,
});
integrationActions.oktaAccountLoadFail = (error) => ({
  error,
  type: actionTypes.OKTA_ACCOUNT_FAILURE,
});

integrationActions.getSamlSpStart = () => ({
  type: actionTypes.GET_SAML_SP_START,
});

integrationActions.getSamlSpSuccess = (serviceProvider) => ({
  serviceProvider,
  type: actionTypes.GET_SAML_SP_SUCCESS,
});

integrationActions.getSamlSpFail = () => ({
  type: actionTypes.GET_SAML_SP_FAILURE,
});

integrationActions.getAuthBackendsStart = () => ({
  type: actionTypes.GET_AUTH_BE_START,
});
integrationActions.getAuthBackendsSuccess = (results) => ({
  results,
  type: actionTypes.GET_AUTH_BE_SUCCESS,
});
integrationActions.getAuthBackendsFail = (error) => ({
  error,
  type: actionTypes.GET_AUTH_BE_FAILURE,
});

integrationActions.updateOrderingValue = (id, sortingValue) => ({
  id,
  sortingValue,
  type: actionTypes.UPDATE_ORDERING_VALUE,
});

integrationActions.authBackendUpdateStart = (id) => ({
  id,
  type: actionTypes.AUTH_BE_UPDATE_START,
});

integrationActions.authBackendUpdateSuccess = (id, authBackEnd) => ({
  authBackEnd,
  id,
  type: actionTypes.AUTH_BE_UPDATE_SUCCESS,
});
integrationActions.authBackendUpdateFailure = (id) => ({
  id,
  type: actionTypes.AUTH_BE_UPDATE_FAIL,
});

integrationActions.authBackendDeleteSuccess = (id) => ({
  id,
  type: actionTypes.AUTH_BE_DELETE_SUCCESS,
});

integrationActions.setOktaTenantStart = () => ({
  type: actionTypes.SET_OKTA_TENANT_START,
});

integrationActions.setOktaTenantFail = () => ({
  type: actionTypes.SET_OKTA_TENANT_FAIL,
});

integrationActions.setOktaTenantSuccess = (data) => ({
  data,
  type: actionTypes.SET_OKTA_TENANT_SUCCESS,
});

integrationActions.errorAddEmailCredentials = () => ({
  type: actionTypes.ERROR_ADD_EMAIL_CREDENTIALS,
});

integrationActions.startAddEmailCredentials = () => ({
  type: actionTypes.START_ADD_EMAIL_CREDENTIALS,
});

integrationActions.successAddEmailCredentials = (credentials) => ({
  credentials,
  type: actionTypes.SUCCESS_ADD_EMAIL_CREDENTIALS,
});

integrationActions.errorUpdateEmailCredentials = () => ({
  type: actionTypes.ERROR_UPDATE_EMAIL_CREDENTIALS,
});

integrationActions.startUpdateEmailCredentials = () => ({
  type: actionTypes.START_UPDATE_EMAIL_CREDENTIALS,
});

integrationActions.successUpdateEmailCredentials = () => ({
  type: actionTypes.SUCCESS_UPDATE_EMAIL_CREDENTIALS,
});

integrationActions.successUpdatePrimaryEmail = () => ({
  type: actionTypes.SUCCESS_UPDATE_PRIMARY_EMAIL,
});
integrationActions.errorEmailTestConnection = (emailAccountType) => ({
  emailAccountType,
  type: actionTypes.ERROR_EMAIL_TEST_CONNECTION,
});

integrationActions.startEmailTestConnection = () => ({
  type: actionTypes.START_EMAIL_TEST_CONNECTION,
});
integrationActions.resetEmailTestConnection = () => ({
  type: actionTypes.RESET_EMAIL_TEST_CONNECTION,
});

integrationActions.successEmailTestConnection = (emailAccountType) => ({
  emailAccountType,
  type: actionTypes.SUCCESS_EMAIL_TEST_CONNECTION,
});

integrationActions.errorGetEmailCredentials = () => ({
  type: actionTypes.ERROR_GET_EMAIL_CREDENTIALS,
});

integrationActions.startGetEmailCredentials = () => ({
  type: actionTypes.START_GET_EMAIL_CREDENTIALS,
});

integrationActions.successGetEmailCredentials = (credentials) => ({
  credentials,
  type: actionTypes.SUCCESS_GET_EMAIL_CREDENTIALS,
});

integrationActions.setCurrentCredential = (credential) => ({
  credential,
  type: actionTypes.SET_CURRENT_EMAIL_CREDENTIAL,
});

integrationActions.inboxFormErrors = (showErrors, emailData) => ({
  emailData,
  showErrors,
  type: actionTypes.BARISTA_INBOX_FORM_ERRORS,
});

integrationActions.loadHistoryInstanceStart = (id) => ({
  id,
  type: actionTypes.CSV_INSTANCE_START,
});

integrationActions.loadHistoryInstanceSuccess = (id, instance) => ({
  id,
  instance,
  type: actionTypes.CSV_INSTANCE_SUCCESS,
});

integrationActions.loadHistoryInstanceFail = (id) => ({
  id,
  type: actionTypes.CSV_INSTANCE_ERROR,
});

integrationActions.addEmailCaseStart = () => ({
  type: actionTypes.EMAIL_CASE_ADD_START,
});

integrationActions.addEmailCaseFail = () => ({
  type: actionTypes.EMAIL_CASE_ADD_FAIL,
});

integrationActions.addEmailCaseSuccess = (caseGenerator) => ({
  caseGenerator,
  type: actionTypes.EMAIL_CASE_ADD_SUCCESS,
});

integrationActions.deleteCaseGeneratorStart = () => ({
  type: actionTypes.EMAIL_CASE_DELETE_START,
});

integrationActions.deleteCaseGeneratorFail = () => ({
  type: actionTypes.EMAIL_CASE_DELETE_FAIL,
});

integrationActions.deleteCaseGeneratorSuccess = (emailID) => ({
  emailID,
  type: actionTypes.EMAIL_CASE_DELETE_SUCCESS,
});

integrationActions.setSlackStart = () => ({
  type: actionTypes.SLACK_INTEGRATION_START,
});

integrationActions.setSlackSuccess = () => ({
  type: actionTypes.SLACK_INTEGRATION_SUCCESS,
});

integrationActions.setSlackFail = () => ({
  type: actionTypes.SLACK_INTEGRATION_FAIL,
});

integrationActions.getSlackBotActiveStart = () => ({
  type: actionTypes.SLACKBOT_IS_ACTIVE_START,
});

integrationActions.getSlackBotActiveSuccess = () => ({
  type: actionTypes.SLACKBOT_IS_ACTIVE_SUCCESS,
});

integrationActions.getSlackBotActiveFail = () => ({
  type: actionTypes.SLACKBOT_IS_ACTIVE_FAIL,
});

integrationActions.getBaristaBotIntegrationStart = () => ({
  type: actionTypes.GET_BARISTABOT_INTEGRATION_START,
});

integrationActions.getBaristaBotIntegrationSuccess = (botType, data) => ({
  botType,
  data,
  type: actionTypes.GET_BARISTABOT_INTEGRATION_SUCCESS,
});

integrationActions.getBaristaBotIntegrationFail = () => ({
  type: actionTypes.GET_BARISTABOT_INTEGRATION_FAIL,
});

integrationActions.updateBaristaBotIntegrationStart = () => ({
  type: actionTypes.UPDATE_BARISTABOT_INTEGRATION_START,
});

integrationActions.updateBaristaBotIntegrationSuccess = (botType, data) => ({
  botType,
  data,
  type: actionTypes.UPDATE_BARISTABOT_INTEGRATION_SUCCESS,
});

integrationActions.updateBaristaBotIntegrationFail = () => ({
  type: actionTypes.UPDATE_BARISTABOT_INTEGRATION_FAIL,
});

integrationActions.getSlackBotNotActiveSuccess = () => ({
  type: actionTypes.SLACKBOT_IS_NOT_ACTIVE_SUCCESS,
});

integrationActions.removeSlackbotStart = () => ({
  type: actionTypes.SLACKBOT_REMOVE_START,
});

integrationActions.removeSlackbotSuccess = () => ({
  type: actionTypes.SLACKBOT_REMOVE_SUCCESS,
});

integrationActions.removeSlackbotFail = () => ({
  type: actionTypes.SLACKBOT_REMOVE_FAIL,
});

integrationActions.teamsFetchTokenStart = () => ({
  type: actionTypes.INTEGR_TEAMS_FETCH_TOKEN_START,
});

integrationActions.teamsFetchTokenSuccess = (response) => ({
  response,
  type: actionTypes.INTEGR_TEAMS_FETCH_TOKEN_SUCCESS,
});

integrationActions.removeTeamsTokenSuccess = (response) => ({
  response,
  type: actionTypes.INTEGR_TEAMS_REMOVE_TOKEN_SUCCESS,
});

integrationActions.runElcStart = () => ({
  type: actionTypes.ELC_RUN_SYNC_START,
});

integrationActions.runElcSuccess = (job) => ({
  job,
  type: actionTypes.ELC_RUN_SYNC_SUCCESS,
});

integrationActions.loadLastElcJobSuccess = (job) => ({
  job,
  type: actionTypes.ELC_LOAD_LAST_JOB_SUCCESS,
});

integrationActions.addGeneratorToPrimaryCredentialSuccess = () => ({
  type: actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_ADD_GENERATOR_SUCCESS,
});

integrationActions.removeGeneratorFromPrimaryCredentialSuccess = () => ({
  type:
    actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_REMOVE_GENERATOR_SUCCESS,
});

integrationActions.remoteUserCreateFlagFail = () => ({
  type: actionTypes.REMOTE_USER_CREATE_FLAG_FAIL,
});

integrationActions.remoteUserCreateFlagStart = () => ({
  type: actionTypes.REMOTE_USER_CREATE_FLAG_START,
});

integrationActions.remoteUserCreateFlagSuccess = (data) => ({
  data,
  type: actionTypes.REMOTE_USER_CREATE_FLAG_SUCCESS,
});

integrationActions.testSharepointSettingsStart = () => ({
  type: actionTypes.TEST_SHAREPOINT_SETTINGS_START,
});

integrationActions.testSharepointSettingsSuccess = (results) => ({
  results,
  type: actionTypes.TEST_SHAREPOINT_SETTINGS_SUCCESS,
});

integrationActions.testSharepointSettingsFail = () => ({
  type: actionTypes.TEST_SHAREPOINT_SETTINGS_FAILURE,
});

export default integrationActions;
