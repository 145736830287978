import buildCompositeController from '../../../../../v1/utils/buildCompositeController';
import wsActions from '../../../../../v1/actions/wsActions';
import endpointGenerator from '../../../../../v1/utils/endpointGenerator';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  selectItem: (item, channelId, selectNone) => {
    const selection = item.toJS();
    const what = {
      body: {
        channel_id: channelId,
        metadata: {
          select_special: selection,
        },
        text: selection.label || selectNone,
      },
      request: endpointGenerator.genPath('espChat.messages'),
    };
    dispatch(wsActions.send(what));
  },
});
export default buildCompositeController(mapStateToProps, mapDispatchToProps);
