const TeamRoles = {
  SERVICE_AGENT: 'SERVICE_AGENT',
  SERVICE_LEAD: 'SERVICE_LEAD',
};

const TeamRolesCaseMgmt = [
  {
    key: TeamRoles.SERVICE_LEAD,
    text: 'Service Lead',
    value: TeamRoles.SERVICE_LEAD,
  },
  {
    key: TeamRoles.SERVICE_AGENT,
    text: 'Service Agent',
    value: TeamRoles.SERVICE_AGENT,
  },
];
export { TeamRoles };
export default TeamRolesCaseMgmt;
