import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  count: 0,
  // used in case management
  fieldOptions: [],

  isLoading: false,

  isLoadingRole: null,

  isSearching: false,

  list: {
    count: 0,
    results: [],
  },

  next: null,

  searchTerm: '',

  userAssignment: {},
  userRoles: [],
  users: {},
  usersList: [], // used in admin users
});

const addUsers = (newUsers) => {
  let users = fromJS({});

  newUsers.forEach((newUser, index) => {
    const userId = newUser.get('id');
    users = users.set(index, userId); // Preserve the order
  });
  return users;
};

const concateUsers = (newUsers, currentUsers) => {
  let newUsersList = newUsers.map((usr) => usr.get('id'));
  newUsersList = newUsersList.filter(
    (id) => !currentUsers.find((usr) => usr === id)
  );
  return currentUsers.concat(newUsersList);
};

const removeUser = (userIdRemoved, currentUsers) =>
  currentUsers.filter((user) => user.get('id') !== userIdRemoved);

const addSessionToUsers = (users, sessions) =>
  users.map((user) => {
    const username = user.username || user.email;
    user.last_logged_in = sessions[username] || null;
    return user;
  });

/**
 * Admin user reducer
 * @param state
 * @param action
 * @returns {*}
 */
const usersAdminReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CHANGE_START_DATE_USER_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.CHANGE_START_DATE_USER_SUCCESS: {
      return state.set('isLoading', false);
    }

    case actionTypes.CHANGE_START_DATE_USER_FAIL: {
      return state.set('isLoading', false);
    }

    case actionTypes.ADD_USERS_ENTITIES_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.ADD_USER_ENTITY_START: {
      return state.set('isLoadingRole', action.userId);
    }

    case actionTypes.ADD_USER_ENTITY_SUCCESS: {
      return state.set('next', action.next).set('isLoadingRole', null);
    }

    case actionTypes.REMOVE_USER_ENTITY_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.REMOVE_USER_ENTITY: {
      const userListUpdate = removeUser(
        action.userId,
        state.getIn(['list', 'results'])
      );
      return state
        .set('count', state.get('count') - 1)
        .set('isLoading', false)
        .set('isLoadingRole', null)
        .setIn(['list', 'results'], userListUpdate)
        .setIn(['list', 'count'], userListUpdate.length);
    }

    case actionTypes.ADD_USER_ENTITY_FAIL: {
      return state.set('isLoadingRole', null);
    }

    case actionTypes.ADD_USERS_ENTITIES_SUCCESS: {
      if (action.resetUsersList) {
        // Reset the current users list to replace it by a new one
        state = state.set('users', fromJS({}));
        state = state.set('usersList', fromJS([]));
      }

      const currentUsers = state.get('users');
      const currentUsersList = state.get('usersList');

      const newUsers = fromJS(action.users);

      return state
        .set('count', action.count)
        .set('users', !action.concate ? addUsers(newUsers) : currentUsers)
        .set(
          'usersList',
          action.concate
            ? concateUsers(newUsers, currentUsersList)
            : currentUsersList
        )
        .set('isLoading', false)
        .set('next', action.next)
        .set('isSearching', false);
    }

    case actionTypes.ADD_USERS_ENTITIES_FAIL: {
      return state.set('isSearching', false).set('isLoading', false);
    }

    case actionTypes.IS_SEARCHING_USER_START: {
      return state.set('isSearching', true);
    }

    case actionTypes.SET_USER_SEARCH_TERM: {
      return state.set('searchTerm', action.searchTerm);
    }

    case actionTypes.RESET_USER_LIST: {
      return state.set('users', fromJS({})).set('usersList', fromJS([]));
    }

    case actionTypes.LOAD_USER_ASSIGNMENT_START:
      return state
        .setIn(['userAssignment', action.userID, 'isLoading'], true)
        .setIn(['userAssignment', action.userID, 'groups'], fromJS({}))
        .setIn(['userAssignment', action.userID, 'error'], null);

    case actionTypes.LOAD_USER_ASSIGNMENT_SUCCESS:
      return state
        .setIn(['userAssignment', action.userID, 'isLoading'], false)
        .setIn(
          ['userAssignment', action.userID, 'groups'],
          fromJS(action.groups)
        );

    case actionTypes.LOAD_USER_ASSIGNMENT_ERROR:
      return state
        .setIn(['userAssignment', action.userID, 'isLoading'], false)
        .setIn(
          ['userAssignment', action.userID, 'error'],
          fromJS(action.error)
        );
    case actionTypes.LOAD_USER_OPTIONS_SUCCESS:
      return state.set('fieldOptions', fromJS(action.options));

    case actionTypes.LOAD_USER_OPTIONS_FAIL:
      return state.set('fieldOptions', fromJS([]));

    case actionTypes.LOAD_USER_START:
      return state.set('isLoading', true);

    case actionTypes.LOAD_USER_SUCCESS:
      return state.set('isLoading', false).set('list', fromJS(action.users));

    case actionTypes.LOAD_USER_FAIL:
      return state.set('isLoading', false);

    case actionTypes.ADD_USER_START:
      return state.set('isLoading', true);

    case actionTypes.ADD_USER_SUCCESS:
      return state.set('isLoading', false);

    case actionTypes.ADD_USER_FAIL:
      return state.set('isLoading', false);

    case actionTypes.ADD_USER_SESSION_SUCCESS: {
      const usersWithSessions = addSessionToUsers(
        action.users.results,
        action.sessions
      );
      action.users.results = usersWithSessions;
      return state.set('isLoading', false).set('list', fromJS(action.users));
    }
    case actionTypes.ADD_USER_SESSION_FAIL:
      return state.set('isLoading', false);

    case actionTypes.LOAD_USER_ROLES_START:
      return state.set('isLoading', true);

    case actionTypes.LOAD_USER_ROLES_FAIL:
      return state.set('isLoading', false);

    case actionTypes.LOAD_USER_ROLES_SUCCESS:
      return state
        .set('isLoading', false)
        .set('userRoles', fromJS(action.roles));

    default: {
      return state;
    }
  }
};

export default usersAdminReducer;
