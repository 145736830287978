import { handleOptionClick } from '../handlers';

const getOptionsObjects = (options) => {
  if (Array.isArray(options)) {
    return options?.map(
      ({ actual_text, eid, index, label, value, ...rest }) => {
        // console.table(rest);
        // index and value apply for select_special

        return {
          content: label,
          key: eid || index,
          onClick: handleOptionClick,
          value,
        };
      }
    );
  }

  const { actual_text, eid, index, label, value } = options;

  return {
    content: actual_text || label,
    key: eid || index,
    onClick: handleOptionClick,
    value,
  };
};

export default getOptionsObjects;
