import { createSelector } from 'reselect';
import compatibilityStatus from '../globals/compatibilityStatus';

const compatibilityCheck = (state) => state.get('compatibilityCheck');

export default createSelector([compatibilityCheck], (comp) => {
  if (!comp) {
    return true;
  }

  if (comp.get('compatible') === compatibilityStatus.SUPPORTED) {
    return true;
  }

  if (comp.get('compatible') === compatibilityStatus.UNKNOWN) {
    return true;
  }

  if (comp.get('compatible') === compatibilityStatus.LOCAL) {
    return true;
  }

  // return false only if the API tells us this is UNSUPPORTED
  // everything else should be true; <3
  if (comp.get('compatible') === compatibilityStatus.UNSUPPORTED) {
    return false;
  }

  return true;
});
