const api_catalog_v0_1_fulfillment_option = {
  results: [
    {
      name: 'carrier?',
      question: 'What is the carrier????',
      tye: 'TEXT',
    },
  ],
};

export default api_catalog_v0_1_fulfillment_option;
