/* eslint-disable sort-keys -- backend shape */
const data = {
  results: [
    { intent: 'Holiday Transition to PTO Request', total: 3 },
    { intent: 'How do I enroll 401a', total: 2 },
  ],
  count: 2,
};
/* eslint-enable sort-keys */

export default data;
