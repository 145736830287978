import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  image: {
    required: true,
    type: 'string',
  },
  scrim: {
    default: false,
    type: 'bool',
  },
  scrimColor: {
    default: '',
    type: 'string',
  },
  scrimOpacity: {
    default: 1,
    type: 'number',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'background_1',
  },
});

// Object Definition used by blobifier
const BackgroundBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Background',
  templates: ['background_1'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, BackgroundBlockDefinition, TypeDefaults };
