const api_task_v0_1_tasks = {
  allowed_children_types: [],
  assigned_date: null,
  author: 218862,
  author_info: {
    eid: '4a410674-2493-485b-a1cc-a8e89ad16014',
    email: 'ruben@example.com',
    employee_number: 'fri2jul19',
    first_name: 'ruben',
    id: 218862,
    last_name: 'ortega',
    nickname: 'ruben',
    username: 'ruben@example.com',
  },
  card_id: null,
  cart: null,
  category: null,
  category_name: null,
  channel: 'c37b6a9c-2b4d-4137-b75a-eadcf9c9578e',
  children_info: {},
  closed_datetime: null,
  copy_reference_conv_chan_msgs: false,
  description: '',
  eid: '611676ed-ae68-4281-b86e-35017562362e',
  feed_priority_1: 0,
  feed_priority_2: 1565726656,
  id: 13931,
  location: 34455,
  location_ancestry: {
    ancestry_id_list: [34455],
    zone: 'Every Location',
    zone_espid: 34455,
  },
  location_display_preference: 'floor',
  location_name: 'Every Location',
  location_type: {
    eid: 'f6c48b03-8ab7-439e-b8b0-2ef709b8f89d',
    id: 8,
    name: 'zone',
    parent_id: null,
    priority_order: 5,
    sys_created_by: 'rsoundar@espressive.com',
    sys_custom_fields: null,
    sys_date_created: '2019-01-24T18:39:04.676375+00:00',
    sys_date_updated: '2019-01-24T18:39:04.676318+00:00',
    sys_updated_by: 'rsoundar@espressive.com',
  },
  metadata: null,
  open_subtasks: false,
  owner_info: null,
  owner_unread_message_count: 0,
  parent: 13928,
  parent_info: {
    context_user_perms: true,
    eid: '27b49132-9be5-44e8-b8d9-0e84bfbd32f6',
    id: 13928,
    ref_num: null,
    service_department_id: 4,
    service_team_id: 20,
    status: 'pending_approval',
    title: 'gatolobo',
    type: 'service_request',
  },
  priority: 50,
  ref_num: 'APP 13931',
  related_responses: {},
  remote_model: null,
  resolve_deadline: '2019-08-13T21:34:16.406062Z',
  response_deadline: '2019-08-13T20:04:16.406062Z',
  response_sla_breach: null,
  service_department: {
    auth_group_id: 27,
    created_in_elc: false,
    default_task_type_id: 'incident',
    description: 'Test second department!',
    display_name: 'IT',
    eid: '87561ee1-ed72-4757-9e49-80a41bb36a3b',
    id: 4,
    is_protected: false,
    last_sync_time: null,
    loc_display_preference: 'floor',
    service_department: 'IT',
    service_department_type_id: 'IT',
    src_active_status: true,
    sys_created_by: 'pascal@espressive.com',
    sys_custom_fields: null,
    sys_date_created: '2018-03-26T23:18:39.595248+00:00',
    sys_date_updated: '2020-10-07T22:17:51.947534+00:00',
    sys_updated_by: 'ruben@example.com',
  },
  service_team: {
    classification_id: 1,
    created_in_elc: false,
    description: 'Bad bad',
    eid: 'a30cda4e-3f8d-42ac-8e8b-5df361074856',
    id: 20,
    is_protected: false,
    last_sync_time: null,
    name: 'QA',
    service_department_id: 4,
    src_active_status: true,
    sys_created_by: 'gg65464532181+manualtesting@gmail.com',
    sys_custom_fields: null,
    sys_date_created: '2018-04-05T17:26:16.059204+00:00',
    sys_date_updated: '2020-02-06T18:55:08.595332+00:00',
    sys_updated_by: 'martin@espressive.com',
    team_auth_group_id: 596,
    team_lead_id: 1989,
  },
  source: 'barista',
  status: 'pending_approval',
  sub_status: null,
  sys_created_by: null,
  sys_custom_fields: {},
  sys_date_created: '2019-08-13T19:34:16.468841Z',
  sys_date_updated: '2019-08-13T19:34:16.468802Z',
  sys_updated_by: null,
  task_notes: [],
  title: 'gatolobo',
  type: 'approval_team',
  type_locked: false,
  uncancelled_subtasks: false,
};

export default api_task_v0_1_tasks;
