import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { curry, memoize, noop } from 'lodash';
import { Field } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Button, Divider, Dropdown } from 'semantic-ui-react';
// Molecule
import ResponsesTextArea from './ResponsesTextArea';
import AttachFAQFile from './AttachFAQFile';
// V2
import ConversationSelect from '../../../v2/components/functional/ConversationSelect';
// Controller
import ResponsesFAQGroupController from '../controllers/ResponsesFAQGroupController';

class ResponsesFAQGroup extends PureComponent {
  static propTypes = {
    activeResponsesLines: PropTypes.arrayOf(PropTypes.bool),

    addFile: PropTypes.func,

    answerEID: PropTypes.string,

    baristaID: PropTypes.number,

    caseAuthor: PropTypes.number,

    change: PropTypes.func,

    checkErrorField: PropTypes.func,

    checkMessage: PropTypes.bool,

    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

    /** ReduxForm 'field' prop */
    fields: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,

    imageNewFaq: ImmutablePropTypes.map,

    isFromKB: PropTypes.bool,

    isProtectedResponsesLines: PropTypes.arrayOf(PropTypes.bool),

    messages: ImmutablePropTypes.list,

    onChange: PropTypes.func,

    onToggleActive: PropTypes.func,

    overrideAddFile: PropTypes.func,

    responses: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, ImmutablePropTypes.map])
    ),

    setFormAsDirty: PropTypes.func,

    taskId: PropTypes.number,

    tenantName: PropTypes.string,
  };

  static defaultProps = {
    activeResponsesLines: [],
    addFile: noop,
    answerEID: null,
    baristaID: null,
    caseAuthor: null,
    change: noop,
    checkErrorField: noop,
    checkMessage: false,
    errors: {},
    imageNewFaq: Immutable.Map(),
    isFromKB: false,
    isProtectedResponsesLines: [],
    messages: Immutable.List(),
    onChange: noop,
    onToggleActive: noop,
    overrideAddFile: null,
    responses: [],
    setFormAsDirty: noop,
    taskId: null,
    tenantName: '',
  };

  state = {
    // field index focused
    fieldSelected: null,
    // modal state handler
    isConversationSelectModalOpen: false,
    // value position in the field focused
    target: null,
  };

  componentDidMount() {
    const { responses, fields, imageNewFaq } = this.props;

    this.mounted = true;

    fields.removeAll(); // Clean fields

    responses.forEach((value) => {
      if (Immutable.Map.isMap(value) && value.has('name')) {
        fields.push({
          name: value.get('name'),
          src: imageNewFaq.get(value.get('name')).src,
        });
      } else {
        fields.push(value);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { checkErrorField, fields } = this.props;

    let shouldCheckError = fields.length !== prevProps.fields.length;

    if (!shouldCheckError && prevProps.fields) {
      const prevFields = prevProps.fields.getAll();
      const newFields = fields.getAll();

      const changedField = prevFields
        ? prevFields.filter((field, i) => newFields.get(i) !== field)
        : Immutable.Map();
      shouldCheckError = !changedField.isEmpty();
    }

    if (shouldCheckError) {
      checkErrorField(fields);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;

  handleToggleConversationSelectModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { isConversationSelectModalOpen } = this.state;
    this.setState({
      isConversationSelectModalOpen: !isConversationSelectModalOpen,
    });
  };

  handleAddEntries = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { fields } = this.props;
    fields.push('');

    // Scroll down the scroll area
    setTimeout(() => {
      this.bottomLine.scrollIntoView({
        behavior: 'smooth',
      });
    }, 150);
  };

  handleRemove = (index) => {
    const { fields, setFormAsDirty } = this.props;
    if (setFormAsDirty) {
      setFormAsDirty();
    }
    fields.remove(index); // Remove field form fieldarray
  };

  handleEvent = (e) => {
    e.preventDefault();
  };

  handleAddLink = (e, { value }) => {
    const { fieldSelected, target } = this.state;
    const { change } = this.props;

    const addValue = {
      email: ' [email title](mailto:example@example.com) ',
      link: ' [link title](https://www.example.com) ',
    };

    const finalValue = addValue[value];

    if (fieldSelected || fieldSelected === 0) {
      const { value } = this.refText[fieldSelected].props;
      const newValue =
        value.slice(0, target.selectionEnd) +
        finalValue +
        value.slice(target.selectionEnd);
      change(`responsesFaq.${fieldSelected}`, newValue);
    }

    if (this.mounted) {
      this.setState({
        fieldSelected: null,
        target: null,
      });
    }
  };

  handleOnFocus = (e, index) => {
    if (this.refText && this.refText[index] && this.mounted) {
      this.setState({
        fieldSelected: index,
        target: e.target,
      });
    }
  };

  handleOnBlur = (e, index) => {
    if (this.refText && this.refText[index]) {
      setTimeout(() => {
        const target = document.activeElement;
        if (
          target.tagName !== 'TEXTAREA' &&
          (!target.dataset.role || target.dataset.role !== 'addlink') &&
          this.mounted
        ) {
          this.setState({
            fieldSelected: null,
            target: null,
          });
        }
      }, 1);
    }
  };

  handleBlurLink = () => {
    const { target } = this.state;

    if (target && this.mounted) {
      this.setState({
        fieldSelected: null,
        target: null,
      });
    }
  };

  handleFileSelect = (e, file) => {
    const {
      answerEID,
      addFile,
      fields,
      overrideAddFile,
      setFormAsDirty,
    } = this.props;

    if (overrideAddFile) {
      overrideAddFile(fields, file);
      setFormAsDirty();
    } else {
      addFile(answerEID, file).then((response) => {
        fields.push({
          eid: response.eid,
          url: response.attachment_url,
        });
        setFormAsDirty();
      });
    }
  };

  move = (from, to) => {
    const { fields, setFormAsDirty } = this.props;
    if (setFormAsDirty) {
      setFormAsDirty();
    }
    fields.move(from, to);
  };

  triggerAttach = (onClick) => (
    <Button basic icon='attach' onClick={onClick} size='small' />
  );

  renderListButtons = () => {
    const { checkMessage, isFromKB, taskId } = this.props;
    const { isConversationSelectModalOpen } = this.state;
    return (
      <>
        <Button
          basic
          content='Add text section'
          onClick={this.handleAddEntries}
          size='small'
        />

        <AttachFAQFile
          onAttachmentChange={this.handleFileSelect}
          renderTrigger={this.triggerAttach}
        />

        {checkMessage && !isFromKB ? (
          <ConversationSelect
            onChange={this.handleChatSelect}
            onClose={this.handleToggleConversationSelectModal}
            open={isConversationSelectModalOpen}
            taskId={taskId}
            trigger={
              <Button
                basic
                content='Add from chat'
                onClick={this.handleToggleConversationSelectModal}
                size='small'
              />
            }
          />
        ) : null}
      </>
    );
  };

  renderAddlink = (i) => {
    const { fieldSelected, target } = this.state;

    const options = [
      {
        key: 0,
        text: 'External Link',
        value: 'link',
      },
      {
        key: 1,
        text: 'Email Link',
        value: 'email',
      },
    ];

    // Hide if no focus on a text area
    if ((!fieldSelected && !target) || i !== fieldSelected) {
      return null;
    }

    return (
      <Dropdown
        icon={null}
        onBlur={this.handleBlurLink}
        onChange={this.handleAddLink}
        options={options}
        selectOnBlur={false}
        trigger={
          <Button
            data-role='addlink'
            icon='linkify'
            onClick={this.handleEvent}
            size='small'
          />
        }
      />
    );
  };

  setTextRef = (ref, index) => {
    if (!this.refText) {
      this.refText = {};
    }
    this.refText[index] = ref;
  };

  handleChatSelect = (messagesSelected = []) => {
    const {
      baristaID,
      caseAuthor,
      fields,
      messages,
      setFormAsDirty,
      tenantName,
    } = this.props;

    // Check for empty field
    const fieldsList = fields.getAll();
    if (fieldsList && fieldsList.size === 1 && !fieldsList.get(0)) {
      // Remove the empty field
      fields.removeAll();
    }

    // Filter messages in order to remove barista and author message
    const msgList = messages.filter((comment) => {
      if (comment.get('user_id') === baristaID) {
        return false;
      } else if (caseAuthor && caseAuthor === comment.get('user_id')) {
        return false;
      }
      return true;
    });

    // Push selected msg
    messagesSelected.forEach((msgIndex) => {
      const msg = msgList.get(msgIndex);
      if (msg) {
        let data;

        if (msg.get('type') === 'attachment') {
          data = {};
          data.src = msg.getIn(['metadata', 'url']);
          data.src =
            tenantName === 'tenant1.esp'
              ? `http://${tenantName}${data.src}`
              : data.src; // This is just for local test only and will not affect the prod testing
          data.attachment_eid = msg.getIn(['metadata', 'attachment_eid']);
        } else {
          data = msg.get('text');
        }

        fields.push(data);
      }
    });
    setFormAsDirty();
  };

  handleOnChange = memoize(
    curry((i, e, value) => {
      const { onChange } = this.props;
      onChange(i, e, value);
    })
  );

  setRef = (ref) => {
    this.bottomLine = ref;
  };

  render() {
    const {
      errors,
      fields,
      isProtectedResponsesLines,
      activeResponsesLines,
      onToggleActive,
    } = this.props;

    return (
      <>
        {this.renderListButtons()}

        <Divider clearing fitted hidden />
        {fields.map((entry, i) => (
          <Field
            active={activeResponsesLines[i]}
            component={ResponsesTextArea}
            error={errors[i]}
            fieldsLength={fields.length}
            index={i}
            isProtected={isProtectedResponsesLines[i]}
            key={entry}
            linkElement={this.renderAddlink(i)}
            move={this.move}
            name={entry}
            onBlurText={this.handleOnBlur}
            onChange={this.handleOnChange(i)}
            onFocusText={this.handleOnFocus}
            onRemove={this.handleRemove}
            onToggleActive={onToggleActive}
            setRef={this.setTextRef}
          />
        ))}

        {fields.length > 2 ? this.renderListButtons() : null}

        <div
          ref={this.setRef}
          style={{
            clear: 'both',
            float: 'left',
          }}
        />
      </>
    );
  }
}

export const ResponsesFAQGroupTest = ResponsesFAQGroup;

export default ResponsesFAQGroupController(ResponsesFAQGroup);
