import React, { Component } from 'react';
import ProfileUploadImage from '../../molecules/ProfileUploadImage';

// Globals
import TypeImageSelect, {
  TypeDefaults,
} from '../../../globals/blocks/TypeImageSelect';

class ImageSelect01 extends Component {
  static propTypes = {
    ...TypeImageSelect,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  render() {
    const { template, type } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ProfileUploadImage {...this.props} loadOnOpen />
      </div>
    );
  }
}

export default ImageSelect01;
