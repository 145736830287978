import APIcall from '../../v1/utils/APIcall';

const fetchSVG = (url) => {
  return APIcall.get({
    isExternalAPI: true,
    url,
  });
};

export default fetchSVG;
