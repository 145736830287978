import Immutable from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = Immutable.fromJS({});

const productImagesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ADD_PRODUCT_IMAGE_ENTITY: {
      const productImage = Immutable.fromJS(action.productImage);

      const id = productImage.get('id');
      return state.set(id, productImage);
    }
    default:
      return state;
  }
};

export default productImagesReducer;
