import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';

// controller
import IntegrationCSVScheduleListController from '../../../../controllers/IntegrationCSVScheduleListController';

// molecules
import NewCSVImportScheduleList from '../../../../molecules/NewCSVImportScheduleList';

class IntegrationCSVScheduleList extends PureComponent {
  static propTypes = {
    businessObjects: ImmutablePropTypes.list,
    deleteSchedule: PropTypes.func,
    getCSVSchedule: PropTypes.func,
    isLoading: PropTypes.bool,
    params: PropTypes.shape({
      scheduleID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    scheduleConnectionSuccess: PropTypes.bool,
    schedules: ImmutablePropTypes.list,
    testSchedule: PropTypes.func,
  };

  static defaultProps = {
    businessObjects: immutable.List(),
    deleteSchedule: noop,
    getCSVSchedule: noop,
    isLoading: false,
    scheduleConnectionSuccess: false,
    schedules: immutable.List(),
    testSchedule: noop,
  };

  componentDidMount() {
    const { getCSVSchedule } = this.props;
    getCSVSchedule();
  }

  render() {
    const {
      businessObjects,
      deleteSchedule,
      isLoading,
      scheduleConnectionSuccess,
      schedules,
      testSchedule,
      params: { scheduleID },
    } = this.props;

    const showList = !scheduleID;

    return (
      <Segment attached='bottom'>
        {showList && (
          <NewCSVImportScheduleList
            businessObjects={businessObjects}
            deleteSchedule={deleteSchedule}
            isLoading={isLoading}
            listData={schedules}
            scheduleConnectionSuccess={scheduleConnectionSuccess}
            testSchedule={testSchedule}
          />
        )}
      </Segment>
    );
  }
}

const IntegrationCSVScheduleListTest = IntegrationCSVScheduleList;

// eslint-disable-next-line no-class-assign -- DEV-1526
IntegrationCSVScheduleList = IntegrationCSVScheduleListController(
  IntegrationCSVScheduleList
);
export { IntegrationCSVScheduleListTest };
export default IntegrationCSVScheduleList;
