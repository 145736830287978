import getLocale from '../selectors/getLocale';
import Store from '../stores/store';
import { i18Locales } from 'esp-util-intl';

class LocaleSingleton {
  constructor() {
    this.locale = i18Locales.en_us;
    this.set();
  }

  set() {
    this.locale = getLocale(Store?.getState());
  }

  get() {
    return this.locale;
  }
}

const instance = new LocaleSingleton();

export default instance;
