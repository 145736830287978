// import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import styles from './NoMatch.module.scss';
import { Helmet } from 'react-helmet';
import { Container, Divider } from 'semantic-ui-react';
import BodyClassName from '../../../globals/BodyClassName';

class NoMatch extends PureComponent {
  render() {
    return (
      <BodyClassName className='not-found-page'>
        <section>
          <Helmet title='404: Resource Not Found' />

          <Container textAlign='center'>
            <Divider hidden />
            <h4>{'Error 404: Page not found'}</h4>
            <div className={styles.barista}>
              <svg
                className='primary barista_404 icon'
                viewBox='0 0 96 96'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g fill='#008468'>
                  <path d='M45.082 26.274c1.477-13.366 30.912-6.69 24.783-24.03-.946-2.658-5.17-1.476-4.24 1.168 2.69 7.606-7.53 8.522-12.73 10.338-6.248 2.083-11.432 5.598-12.2 12.584-.31 2.747 4.077 2.717 4.387-.06z' />
                  <path d='M65.98 16.985c-5.745 1.358-10.973 3.825-12.775 9.865-.798 2.733 3.426 3.87 4.24 1.167 1.978-6.675 10.72-6.794 16.245-8.418 6.01-1.75 9.437-5.91 8.61-12.31-.37-2.78-4.74-2.81-4.43 0 1.107 7.66-5.997 8.21-11.89 9.69zm4.313 59.712c15.61-.635 22.553-7.74 22.553-16.453 0-7.577-5.244-13.942-16.852-15.892v-8.08L2 32.33c0 16.586 0 44.603 14.887 56.123C7.863 89.147 2 90.255 2 91.48c0 2.098 16.57 3.796 37.026 3.796 20.456 0 37.027-1.698 37.027-3.795 0-1.22-5.908-2.33-14.887-3.02 3.946-3.12 7.077-7.16 9.127-11.75zm5.435-25.108c6.513 1.47 7.68 4.71 7.606 8.86-.088 4.69-1.477 7.88-10.338 8.98 1.536-5.85 2.447-11.84 2.717-17.87l.015.03zm-30.81 27.33c-1.554.66-3.226 1.01-4.917 1.02-2.51 0-4.96-.81-6.97-2.32-.7-.59-.8-1.63-.22-2.34.59-.71 1.63-.81 2.34-.22 2.49 1.76 5.74 2.06 8.51.78.85-.35 1.82.05 2.17.9.35.843-.05 1.82-.9 2.17zm10.34-10.95c-4.162-.19-8-2.31-10.383-5.73-2.384-3.417-3.042-7.75-1.787-11.72-1.605-1.625-4.2-1.708-5.908-.193.95 4.055-.035 8.322-2.67 11.55-2.636 3.223-6.62 5.04-10.784 4.917-6.84-.425-12.414-5.64-13.293-12.436-1.063-.28-2.152-.44-3.25-.474l.104-2.952c1.207.03 2.408-.19 3.53-.636 1.7-5.64 7.754-9.467 13.883-9.23 5.686.22 9.35 2.717 11.24 6.07.554.32 1.228.36 1.816.103.795-.36 1.654-.56 2.526-.576.96.075 1.893.34 2.747.784.49.256 1.07.256 1.56 0 2.14-3.19 5.91-5.405 11.65-5.21 6.13.22 11.89 4.43 13.17 10.234 1.08.525 2.26.826 3.47.885l-.11 2.96c-1.09-.04-2.18.045-3.25.25-1.393 6.746-7.397 11.54-14.28 11.404z' />
                  <path d='M24.612 42.772c-4.515-.178-8.692 2.386-10.58 6.49-1.89 4.107-1.116 8.945 1.956 12.26 3.087 3.312 7.842 4.445 12.08 2.873 4.24-1.565 7.105-5.538 7.28-10.058.225-6.144-4.57-11.313-10.723-11.564h-.013zm1.27 12.406c-1.093-.062-2.04-.777-2.4-1.812-.36-1.034-.06-2.183.76-2.91.82-.724 1.997-.886 2.98-.405.985.48 1.58 1.51 1.51 2.6-.04.71-.366 1.38-.9 1.85-.535.48-1.237.71-1.95.67v.02zm30.262-11.225c-4.515-.177-8.692 2.387-10.58 6.49-1.89 4.107-1.116 8.946 1.956 12.26 3.087 3.313 7.843 4.445 12.08 2.874 4.24-1.566 7.105-5.54 7.28-10.058.226-6.15-4.57-11.32-10.723-11.57h-.013zm-4.135 12.82c-1.1-.062-2.05-.777-2.4-1.812-.36-1.03-.06-2.18.76-2.91.82-.72 1.99-.88 2.98-.4.98.47 1.58 1.51 1.51 2.6-.09 1.48-1.36 2.6-2.84 2.53H52v.01z' />
                </g>
              </svg>
            </div>
            <div>
              <div className='barista_404_bubble'>
                <p>
                  {'Hello there!'}
                  <br />
                  {
                    'The page you are trying to reach does not exist, or has been removed'
                  }
                  <br />
                  {'Please check and try another url'}
                </p>
              </div>
            </div>
          </Container>
        </section>
      </BodyClassName>
    );
  }
}

export default NoMatch;
