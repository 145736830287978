import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

import CartController from '../../controllers/CartController';

// Globals
import TypeButton, { TypeDefaults } from '../../../globals/blocks/TypeButton';

class AddToCartButton01 extends Component {
  static propTypes = {
    ...TypeButton,
    cartFillFromBundle: PropTypes.func.isRequired,
    cartItems: ImmutablePropTypes.list,
    getCart: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    ...TypeDefaults,
    cartItems: new List(),
    loading: false,
  };

  componentDidMount() {
    const { cartItems, getCart } = this.props;

    // Load usr cart
    if ((cartItems && cartItems.size === 0) || cartItems) {
      getCart();
    }
  }

  handleClick = () => {
    const { cartFillFromBundle } = this.props;

    cartFillFromBundle();
  };

  render() {
    const { loading, template, text, type } = this.props;

    return (
      <div
        className='block padding'
        data-blocktype={type}
        data-template={template}
      >
        <Button
          basic
          content={text}
          data-test-id='add-to-cart-button'
          loading={loading}
          onClick={this.handleClick}
          primary
          size='large'
        />
      </div>
    );
  }
}

// In use for unit test
export const AddToCartButton = AddToCartButton01;

// Default export with controller
export default CartController(AddToCartButton01);
