import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Molecule
import ValidatedDate from '../../molecules/ValidatedDate';

// Controller
import Field02Controller from '../../controllers/Field02Controller';

// Globals
import TypeField, { TypeDefaults } from '../../../globals/blocks/TypeField';
class Field02 extends Component {
  static propTypes = {
    ...TypeField,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    initialDate: PropTypes.string,
    setStartingDate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    initialDate: null,
  };

  render() {
    const {
      errorsApi,
      errors,
      id,
      initialDate,
      labelText,
      readOnly,
      setStartingDate,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API
    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedDate
          blockModal
          enablePastDate={false}
          formError={finalError}
          iconPosition='left'
          id={id}
          initialDate={initialDate}
          label={labelText}
          name={id.toString()}
          placeholder={labelText}
          readOnly={readOnly}
          setStartingDate={setStartingDate}
        />
      </div>
    );
  }
}

export const Field02Test = Field02;

export default Field02Controller(Field02);
