import React, { useCallback, useEffect } from 'react';
import pt from 'prop-types';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';

// Atoms
import UserImage from '../../../atoms/UserImage';
import EspActivityList from '../../../atoms/EspActivityList';
import ScrollArea from '../../../atoms/ScrollArea';

// Utils

import uiPathGenerator from '../../../../utils/uiPathGenerator';
// import getOverdueDate from '../../../../utils/getOverdueDate';
import dateFormat from '../../../../utils/dateFormat';
// Globals
import OnboardingActivityStates from '../../../../globals/OnboardingActivityStates';
import NewHireStatus from '../../../../globals/NewHireStatus';
import groupPermissionsOptions from '../../../../globals/groupPermissions';
import { fromJS } from 'immutable';

const propTypes = {
  escalateProgress: pt.func,

  isLoadingPerformanceDetail: pt.bool,
  location: pt.shape({
    query: pt.shape({
      type: pt.string,
    }),
  }).isRequired,
  params: pt.shape({
    userID: pt.oneOfType([pt.string, pt.number]),
  }),
  performanceDetail: pt.shape({
    jobRole: pt.shape({
      name: pt.string,
    }),
    manager: pt.shape({}),
    progress: pt.arrayOf(pt.shape({})),
    user: pt.shape({}),
  }),
  performanceDetailFn: pt.func,
};

const groupPermissions = Object.assign({}, groupPermissionsOptions, {
  DEPARTING_EMPLOYEE: 'DEPARTING_EMPLOYEE',
});

const OffboardingPerformanceDetail = (props) => {
  const {
    escalateProgress,
    params: { userID },

    isLoadingPerformanceDetail,
    performanceDetail,
    performanceDetailFn,
    location: { query },
  } = props;

  useEffect(() => {
    if (query.type && userID) {
      performanceDetailFn(userID, query.type);
    }
  }, [query.type, performanceDetailFn, userID]);

  const handleEscalate = useCallback(() => {
    escalateProgress(userID);
  }, [escalateProgress, userID]);

  if (!query.type || !groupPermissions[query.type]) {
    const errorMsg = !query.type
      ? 'Not type has been passed'
      : `The type ${query.type} does not exist`;

    return <Message content={errorMsg} header='Error' negative />;
  }

  const listDetail = performanceDetail?.progress || [];
  const user = performanceDetail?.user || {};
  const manager = performanceDetail?.manager || {};

  const filteredProgress = fromJS({
    active: listDetail.filter(
      (detail) =>
        detail.state === OnboardingActivityStates.NORMAL ||
        detail.state === OnboardingActivityStates.WARNING
    ),
    completed: listDetail.filter(
      (detail) => detail.state === OnboardingActivityStates.COMPLETE
    ),
    delinquent: listDetail.filter(
      (detail) => detail.state === OnboardingActivityStates.DELINQUENT
    ),
    overdue: listDetail.filter(
      (detail) => detail.state === OnboardingActivityStates.OVERDUE
    ),
    scheduled: listDetail.filter(
      (detail) => detail.state === OnboardingActivityStates.SCHEDULED
    ),
  });

  const displayEscalateButton =
    (filteredProgress.has('overdue') &&
      !filteredProgress.get('overdue').isEmpty()) ||
    (filteredProgress.has('delinquent') &&
      !filteredProgress.get('delinquent').isEmpty());

  const userName =
    user?.nickname && user?.last_name
      ? `${user?.nickname} ${user?.last_name}`
      : 'Loading...';

  const managerName =
    manager?.nickname && manager?.last_name
      ? `${manager?.nickname} ${manager?.last_name}`
      : 'There is no manager for this user';

  const managerLink = user?.id
    ? uiPathGenerator.genPath('app.directory.detail', {
        userID: user.id,
      })
    : uiPathGenerator.genPath('app.directory');

  const scheduledActivities = filteredProgress.get('scheduled');
  const count = scheduledActivities.getIn([0, 'count']) || 0;
  const remainingActivities = intl.formatMessage(
    {
      id: 'message.count_remaining_activities',
    },
    {
      activitiesRemainingCount: count,
    }
  );

  return (
    <Segment as={Grid} attached clearing>
      <Helmet title={'Offboarding Activities - Performance'} />

      <Grid.Row columns={1}>
        <Grid.Column>
          <Form>
            <Form.Group className='button-actions' grouped>
              <Button
                as={Link}
                className='text'
                content='Back'
                floated={'left'}
                icon='left chevron'
                to={`${uiPathGenerator.genPath(
                  'admin.adminActivities.offboarding.performance'
                )}?show=${query.type}`}
              />
              <Button
                basic
                content='Escalate'
                disabled={!displayEscalateButton}
                onClick={handleEscalate}
              />
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>

      {isLoadingPerformanceDetail && (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )}

      <Grid.Row>
        <Grid.Column
          style={{
            paddingRight: 0,
          }}
          width={1}
        >
          <NavLink
            to={uiPathGenerator.genPath('app.directory.detail', {
              userID: userID,
            })}
          >
            <UserImage
              className='circular'
              user={user.id ? fromJS(user) : null}
            />
          </NavLink>
        </Grid.Column>
        <Grid.Column width={15}>
          <NavLink
            to={uiPathGenerator.genPath('app.directory.detail', {
              userID: userID,
            })}
          >
            <Header as='h3' content={userName} />
          </NavLink>

          {user.title && (
            <div>
              <strong>{'Title: '}</strong>
              {user.title}
            </div>
          )}

          {performanceDetail?.jobRole?.name && (
            <div>
              <strong>{'Job Role: '}</strong>
              {performanceDetail.jobRole.name}
            </div>
          )}

          {user.department && (
            <div>
              <strong>{'Department: '}</strong>
              {user.department}
            </div>
          )}

          <div>
            <strong>{'Manager: '}</strong>
            <NavLink to={managerLink}>{managerName}</NavLink>
          </div>

          {user?.start_date && (
            <div>
              <strong>{'Start Date: '}</strong>
              {dateFormat(user.start_date)}
            </div>
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <p>{remainingActivities}</p>

          <ScrollArea
            height='calc(100vh - 450px)'
            horizontal
            margin='0 -1em -1em'
            padding={0}
            width='calc(100% + 2em)'
          >
            <Grid
              divided
              padded
              style={{
                height: '100%',
                width: '120%',
              }}
            >
              <Grid.Row
                columns='equal'
                style={{
                  padding: 0,
                }}
              >
                <Grid.Column
                  style={{
                    padding: 0,
                  }}
                >
                  <EspActivityList
                    activities={filteredProgress.get('active')}
                    intl={intl}
                    isNewHire={query.type !== NewHireStatus}
                    title={intl.formatMessage({
                      id: 'label.active',
                    })}
                  />
                </Grid.Column>

                <Grid.Column
                  style={{
                    padding: 0,
                  }}
                >
                  <EspActivityList
                    activities={filteredProgress.get('delinquent')}
                    intl={intl}
                    isNewHire={query.type !== NewHireStatus}
                    title={intl.formatMessage({
                      id: 'label.delinquent',
                    })}
                  />
                </Grid.Column>

                <Grid.Column
                  style={{
                    padding: 0,
                  }}
                >
                  <EspActivityList
                    activities={filteredProgress.get('overdue')}
                    intl={intl}
                    isNewHire={query.type !== NewHireStatus}
                    title={intl.formatMessage({
                      id: 'label.overdue',
                    })}
                  />
                </Grid.Column>

                <Grid.Column
                  style={{
                    padding: 0,
                  }}
                >
                  <EspActivityList
                    activities={filteredProgress.get('completed')}
                    intl={intl}
                    isNewHire={query.type !== NewHireStatus}
                    title={intl.formatMessage({
                      id: 'label.completed',
                    })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </ScrollArea>
        </Grid.Column>
      </Grid.Row>
    </Segment>
  );
};

OffboardingPerformanceDetail.propTypes = propTypes;

export default OffboardingPerformanceDetail;
