// import PropTypes           from 'prop-types';
import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';

class IntegrationCSVMappingHierarchyLocation extends PureComponent {
  render() {
    return (
      <div>
        <Header content='IntegrationCSVMappingHierarchyLocation' />
      </div>
    );
  }
}

export default IntegrationCSVMappingHierarchyLocation;
