import { Button } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { map, noop } from 'lodash';

import PermissionsForm from '../organisms/PermissionsForm';

class AdminProductPermissions extends PureComponent {
  static propTypes = {
    addPermissionGroupState: ImmutablePropTypes.map.isRequired,
    addProductPermissionGroup: PropTypes.func,
    getProductPermissionGroups: PropTypes.func,
    jobRoles: ImmutablePropTypes.list,
    loadJobRoles: PropTypes.func,
    loadProductFamily: PropTypes.func,
    product: ImmutablePropTypes.map,
    productFamily: ImmutablePropTypes.map,
    productPermissionGroups: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    removePermissionGroupState: ImmutablePropTypes.map.isRequired,
    removeProductPermissionGroup: PropTypes.func,
    resetProductPermissionGroup: PropTypes.func,
    savePermissionGroupState: ImmutablePropTypes.map.isRequired,
    saveProductPermissionGroup: PropTypes.func,
    toggleProductFromPermissionGroup: PropTypes.func,
  };

  static defaultProps = {
    addProductPermissionGroup: noop,
    getProductPermissionGroups: noop,
    jobRoles: null,
    loadJobRoles: noop,
    loadProductFamily: noop,
    product: null,
    productFamily: null,
    productPermissionGroups: null,
    removeProductPermissionGroup: noop,
    resetProductPermissionGroup: noop,
    saveProductPermissionGroup: noop,
    toggleProductFromPermissionGroup: noop,
  };

  componentDidMount() {
    const {
      getProductPermissionGroups,
      loadJobRoles,
      loadProductFamily,
      product,
    } = this.props;

    loadJobRoles();

    const productFamilyID = product.get('product_family');

    if (productFamilyID) {
      loadProductFamily(productFamilyID).then((productFamily) => {
        const productFamilyProducts = productFamily.products;
        const productIDs = map(productFamilyProducts, 'id');

        getProductPermissionGroups(productIDs);
      });
    } else {
      getProductPermissionGroups([product.get('id')]);
    }
  }

  componentWillUnmount() {
    const { resetProductPermissionGroup } = this.props;
    resetProductPermissionGroup();
  }

  handleAddButtonClick = () => {
    const { addProductPermissionGroup, product } = this.props;

    const productID = product.get('id');

    addProductPermissionGroup(productID);
  };

  render() {
    const {
      addPermissionGroupState,
      removePermissionGroupState,
      savePermissionGroupState,

      jobRoles,
      productFamily,
      productPermissionGroups,
      removeProductPermissionGroup,
      saveProductPermissionGroup,
      toggleProductFromPermissionGroup,
    } = this.props;

    // is some async operation taking place?
    const isLoading =
      !productPermissionGroups ||
      addPermissionGroupState.get('isLoading') ||
      removePermissionGroupState.get('isLoading') ||
      savePermissionGroupState.get('isLoading');

    return (
      <>
        <Button
          disabled={isLoading}
          loading={addPermissionGroupState.get('isLoading')}
          onClick={this.handleAddButtonClick}
        >
          {'Add Users/Groups'}
        </Button>

        {productPermissionGroups &&
          productPermissionGroups.map((productPermissionGroup) => {
            const isRemoving =
              removePermissionGroupState.get('isLoading') &&
              removePermissionGroupState.get('productPermissionGroupID') ===
                productPermissionGroup.get('id');

            const isSaving =
              savePermissionGroupState.get('isLoading') &&
              savePermissionGroupState.get('productPermissionGroupID') ===
                productPermissionGroup.get('id');

            return (
              <PermissionsForm
                disabled={isLoading}
                isRemoving={isRemoving}
                isSaving={isSaving}
                jobRoles={jobRoles}
                key={productPermissionGroup.get('id')}
                productFamily={productFamily}
                productPermissionGroup={productPermissionGroup}
                removeProductPermissionGroup={removeProductPermissionGroup}
                saveProductPermissionGroup={saveProductPermissionGroup}
                toggleProductFromPermissionGroup={
                  toggleProductFromPermissionGroup
                }
              />
            );
          })}
      </>
    );
  }
}

export default AdminProductPermissions;
