import { fromJS } from 'immutable';

// Actions
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  assignedTo: '',
  location: '',
  requestedBy: '',
  sortBy: '',
  state: '',
  taskType: '',
  timePeriod: '',
});

/**
 * case feed reducer
 * @param state
 * @param action
 * @returns {*}
 */
const casesFilterReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CASES_FILTER_UPDATE: {
      return state
        .set('assignedTo', action.newFilters.assignedTo)
        .set('location', action.newFilters.location)
        .set('requestedBy', action.newFilters.requestedBy)
        .set('sortBy', action.newFilters.sortBy)
        .set('state', action.newFilters.state)
        .set('taskType', action.newFilters.taskType)
        .set('timePeriod', action.newFilters.timePeriod);
    }
    case actionTypes.CASES_FILTER_RESET: {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};

export default casesFilterReducer;
