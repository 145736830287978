const api_images_v0_1_tenant_images = {
  count: 2,
  results: [
    {
      id: 1,
      type: 'meta',
    },
    {
      id: 2,
      type: 'logo',
    },
  ],
};

export default api_images_v0_1_tenant_images;
