import { UserAgentUtils } from 'esp-globals';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import browserHistory from '../../utils/browserHistory';
import endpointGenerator from '../../utils/endpointGenerator';
// V2 utility
import { openExternalUrlInNative } from '../../../v2/utils/externalDownloadHandler';

// Selector
import getMetaTexts from '../../selectors/getMetaTexts';
import getBaristaId from '../../selectors/getBaristaId';
// Actions
import baristaActions from '../../actions/baristaActions';
import baristaThunks from '../../actions/baristaThunks';
// Utils
import BrowserPrompt from '../../globals/BrowserPrompt';
// Globals
import uiPathGenerator from '../../utils/uiPathGenerator';
import { intl } from 'esp-util-intl';

import { isUndefined } from 'lodash';

const mapStateToProps = (state, ownProps) => {
  // Get the ID of the Barista user from state
  const baristaId = getBaristaId(state);

  // Get the ID of the user passed through ownProps
  const userId = Number(ownProps.userId); // Forcing numeric id

  // Check if this is the Barista user
  const isBarista = Number(baristaId) === userId;

  return {
    globals: getMetaTexts(state),
    isBarista: isUndefined(ownProps.isBarista) ? isBarista : ownProps.isBarista,
  };
};

const mapDispatchToProps = (dispatch) => ({
  downloadKbAttachment: (sys_id) => {
    let windowReference;
    if (!window.cordova && UserAgentUtils.isIOS()) {
      windowReference = window.open();
    }

    dispatch(baristaThunks.getProxyKey()).then(({ key, user_id }) => {
      // DEV-26529 fixed order of params as seen with Raj
      const endpoint = endpointGenerator.genPath(
        'serviceNowIntegration.proxy.serve'
      );
      const url =
        `${endpoint}` +
        // 1. key parameter must come first
        `?key=${key}` +
        // 2. user_id should be a separated parameter also
        `&user_id=${user_id}` +
        // 3.after attachment.do?sys_id should be the second parameter
        `&url=sys_attachment.do%3Fsys_id=${sys_id}`;

      openExternalUrlInNative(url, windowReference);
    });
  },

  loadKbArticle: (params) => {
    dispatch(baristaThunks.openKbArticle(params));
  },

  openInternaLinkFromBarista: (event, avoidPromptingDialog = false) => {
    // don't show warning dialog
    // just close barista and go to the link
    if (avoidPromptingDialog) {
      let chatLink = event.target.getAttribute('href'); // save original link
      chatLink = chatLink.replace('#', ''); // removes history tag added by native apps
      event.preventDefault(); // cancel link click event

      dispatch(baristaActions.closeSupportChannel()); // close current channel

      // DEV-11229 fixing links to /app/home if we're in /portal
      const currentLocation = window.location && window.location.pathname;
      const portalPath = uiPathGenerator.genPath('portalBarista');
      const baristaHomePath = uiPathGenerator.genPath('app.barista');
      if (currentLocation === portalPath) {
        if (chatLink.indexOf(baristaHomePath) > -1) {
          // do replacemenet
          chatLink = chatLink.replace(baristaHomePath, portalPath);
        }
      }

      setTimeout(() => {
        // push url after a little bit
        browserHistory.push(chatLink);
      }, 500);
    } else {
      // Ask first
      const closeBaristaAndNavigate = () => {
        dispatch(baristaActions.closeSupportChannel());
      };
      const confirmed = BrowserPrompt.confirm(
        intl.formatMessage({
          id: 'message.youre_about_to_follow_a_link_do_you_want_to_close_barista_as_well',
        }),
        {
          buttons: [
            intl.formatMessage({
              id: 'label.keep_barista_open_link_in_background',
            }),
            intl.formatMessage({
              id: 'label.yes_close_barista',
            }), // Confirmation
          ],
          callback: (action) => {
            if (action === 2) {
              // Confirmation from the second button
              closeBaristaAndNavigate();
            }
          },
          content: intl.formatMessage({
            id: 'message.youre_about_to_follow_a_link_do_you_want_to_close_barista_as_well',
          }),
          title: intl.formatMessage({
            id: 'label.barista_navigation_alert',
          }),
        }
      );

      // Cancel
      if (confirmed) {
        closeBaristaAndNavigate();
      } else {
        // <- don't critique my empty else, i just want to make clear in a comment what happens otherwise ;)
        // do nothing, this will navigate normally using click on NavLink while keeping barista modal in the front
      }
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
