import Immutable from 'immutable';
import { createSelector } from 'reselect';
// Globals
import ConversationMessagesTypes from '../globals/ConversationMessagesTypes';

const filterEmptyMessages = (messages) => {
  return messages?.filter(
    (message) =>
      (!message?.get('text') &&
        message?.get('type') !== ConversationMessagesTypes.BOT_ACTION) ||
      message?.get('text')
  );
};

/** COMMENT THIS LINE IF USING CHAT TEST BLOB **/
const getChannelMessages = (state, channelId) =>
  filterEmptyMessages(state.getIn(['chatState', 'messages', channelId]));

// I'll leave this to provide an example of an easy way to debug messages
// Simply put the content of the channel messages here
// e.g. what you see in /api/chat/v0.1/channels/<eid> inside "results"
// Because of the post-processing filters
// this is the most accurate way to really see what's going on at a local level
// START OF TEST BLOB
// const getChannelMessages = () =>
// filterEmptyMessages(
//   Immutable.fromJS([
//     {
//       alt_texts: null,
//       channel: '6a17fe2c-7108-417b-89d1-5feca44eb5d6',
//       id: 'b65a8f6e-7d89-4c12-a925-b1181e535a2d',
//       metadata: {
//         action: 'com.espressive.bot_action.start_wait_on_param',
//         intent_eid: '133582c3-03ea-45d2-8198-a905b6848d82',
//       },
//       response_received: false,
//       source_type: null,
//       sys_date_created: '2024-04-25T22:33:05.597045Z',
//       text: '',
//       type: 'bot_action',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'ivanti.KB [LONG encrypted string](ivanti.KB KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'sharepoint.KB [LONG encrypted string](sharepoint.KB KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'zendesk.KB [LONG encrypted string](zendesk.KB KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'confluence.KB [LONG encrypted string](confluence.KB KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'snow.kbNumber [LONG encrypted string](snow.kbNumber KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'esp.kbArticle [LONG encrypted string](esp.kbArticle KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2re7ad9b03x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'snow.KB [LONG encrypted string](snow.KB KB0000033 aHR0cHM6Ly9xYXNhdGVzdC5xYS5lc3ByZXNzaXZlLmNvbS9hcGkvdXJsX3JlZGlyZWN0b3IvdjAuMS9yZWRpcmVjdC8/dXJsPWh0dHBzJTNBJTJGJTJGZGV2MTY0NjYxLnNlcnZpY2Utbm93LmNvbSUyRmtiX3ZpZXcuZG8lM0ZzeXNwYXJtX2FydGljbGUlM0RLQjAwMTAwNzMmY3R4PWdBQUFBQUJsWjNYR0FmQVAyNXA4UlJfV0JraEowYmwxRW9uZUFZWHZkTEJHQ0dKaG1NbV8wY0FJdFlHNUpnaHJ4SWN4VzFkY09iN0xxTXNXSlhOSW5CQUhXOFl5emNnbWpYM09jVzdTNGtNcWdNdnRTMWtPYzl0dFg2UDFMVTFSbVN1dHFBdWNRak80Q2RhdExVT2drZG5odnNjZDQ5SDIxV2Fkdm45QmhmaFhUTXJQb2g5QVEwb3ZJMlFvWG5tdjFKS3J2UFRUN2c4YkNyamsxa0ZaY3p2eWwzQ3diczRaSmthS3l3X0dXTzJlSmp2Si01YVRVSG5YT3pMYkJqMVp1XzVtMjZOUFVZT24zbmhlOWRyU1lhTi1TVDF6Qm80bVJZdWFZZVhieEtkTllsMlpUZ2tQbGFhbDJ2VWRzdUgxeU5UdWwxQXA0b05xOTJtSU5iNnpXdzVCeXVuRUdlWDNCT3ZyNDl3dFFXb2wzRkt0YThQa1FCNDEzOEtudHNjSW9uRmxubGpIY2drWFNlRFRZLUc5bmw0eTZlNl9xb2FIVHZfN1BVUi0zaW9Ba21fRFMyZ1FLZzQlM0Q=)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2557ad9bu3x',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'dasd [meatballs](snow.KB KB0000033 ENCRYPTED_STRING)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89321-f2557ad9b03xy',
//       id: 'cec14b32-0f12-4e96-465645ew0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'dasd [Getting Around in Windows](snow.KB KB0000033)',
//       type: 'message',
//       user_id: 1,
//     },

//     {
//       channel: 'ca5e9f8f-7940-464b-rwrew2557ad9b03c',
//       id: '5896ca5a-bb13-4480-aaea-6b201cd11d95',
//       metadata: {
//         user_input: {
//           api_action_button: {
//             clicked: false,
//             label: 'REOPEN',
//             url: '/api/task/v0.1/tasks/19996/reopen_conversation/',
//             url_type: 'post',
//           },
//         },
//       },
//       sys_date_created: '2020-04-08T18:53:15.052418Z',
//       text: '',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-rwrew2557ad9b03c',
//       id: '038848d4-fcb2-4a0a-a853-7e82a982625c',
//       metadata: {
//         user_input: {
//           api_action_button: {
//             close_channel: true,
//           },
//         },
//       },
//       sys_date_created: '2020-04-08T18:51:26.305558Z',
//       text: '',
//       type: 'system',
//       user_id: null,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03c',
//       id: '7da7978c-fe9a-4df6-bbe1-d1c8f0b93da7',
//       metadata: {
//         user_input: {
//           select: [
//             {
//               actual_text: 'Yes, it was helpful',
//               bring_always: false,
//               default_value: null,
//               eid: '77a7d80a-b88b-4b52-a0bf-a2bf81ab84ef',
//               entity: 'Feedback.Inquiryv2',
//               instance: null,
//               is_default: false,
//               is_global: false,
//               is_primary: false,
//               label: 'Yes, it was helpful',
//               label_lemma: null,
//               match_origin: null,
//               ml_probability: null,
//               ml_score: null,
//               ml_threshold: null,
//               order: null,
//               original: 'Yes, it was helpful',
//               param_eid: null,
//               phrase: null,
//               pk: '2341',
//               required: null,
//               score: null,
//               sub_label: null,
//               type: 'custom',
//             },
//             {
//               actual_text: 'No, I need additional assistance',
//               bring_always: false,
//               default_value: null,
//               eid: 'f197aeae-1491-47e7-8c90-a180258f5a23',
//               entity: 'Feedback.Inquiryv2',
//               instance: null,
//               is_default: false,
//               is_global: false,
//               is_primary: false,
//               label: 'No, I need additional assistance',
//               label_lemma: null,
//               match_origin: null,
//               ml_probability: null,
//               ml_score: null,
//               ml_threshold: null,
//               order: null,
//               original: 'No, I need additional assistance',
//               param_eid: null,
//               phrase: null,
//               pk: '2342',
//               required: null,
//               score: null,
//               sub_label: null,
//               type: 'custom',
//             },
//           ],
//         },
//       },
//       sys_date_created: '2020-04-08T18:51:24.841837Z',
//       text: 'Did this resolve your issue or answer your question?',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03c',
//       id: '27d1c077-77c4-4743-8868-75f7b45e4a7d',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:51:23.161824Z',
//       text: 'sdfh',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03c',
//       id: '781e322a-6c11-4828-8b1f-d2ae08f0b68a',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:51:21.867689Z',
//       text: 'sdfg',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03c',
//       id: 'fc38ed3d-a999-4d8d-8f7f-d1dac03cd3de',
//       metadata: {
//         widget: {
//           body: [
//             {
//               children: [
//                 {
//                   children: [
//                     {
//                       children: [
//                         {
//                           eid: 'acd3dba1-3b2b-432c-aacb-e9debd2cbead',
//                           icon: 'directions_walk',
//                           order: 1,
//                           widget: 'Icon',
//                         },
//                         {
//                           eid: '0351540f-a2d6-46e5-8805-776a65a0c917',
//                           order: 2,
//                           text: 'Did this resolve your request?',
//                           widget: 'Text',
//                         },
//                       ],
//                       eid: '169f2c5c-b4ba-4c62-995f-91efb055907a',
//                       order: 1,
//                       widget: 'Row',
//                     },
//                     {
//                       eid: '1ffd13d6-ac4c-4ba2-aec5-1d6670d60bf6',
//                       order: 2,
//                       widget: 'Separator',
//                     },
//                     {
//                       children: [
//                         {
//                           action: 'is_resolved',
//                           button_type: 'radio',
//                           eid: 'c0914e6e-db77-4af8-b843-f5774b6b8999',
//                           label: 'Yes, my request is resolved',
//                           order: 1,
//                           value: 'yes_resolved',
//                           widget: 'Button',
//                         },
//                         {
//                           action: 'is_resolved',
//                           button_type: 'radio',
//                           eid: '587c295d-c766-4fe6-9b4a-d1eb743a30ab',
//                           label: 'No, please reopen it',
//                           order: 3,
//                           value: 'no_do_reopen',
//                           widget: 'Button',
//                         },
//                       ],
//                       eid: '63b6fe6c-d62c-4b8e-a9db-7cddf0ef09a4',
//                       order: 3,
//                       widget: 'Row',
//                     },
//                   ],
//                   eid: '025b2ac9-befd-40ca-b1aa-299be48a6f99',
//                   order: 1,
//                   widget: 'Column',
//                 },
//               ],
//               eid: '7da40ca6-c812-46bb-b8d5-b9c8f7d95e0c',
//               order: 1,
//               widget: 'Container',
//             },
//           ],
//           eid: '2646674e-25cd-48df-8505-c6a3cc38acee',
//           widget: 'Ticket Resolved Survey',
//         },
//       },
//       sys_date_created: '2020-04-08T18:51:20.336029Z',
//       text: '',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03c',
//       id: '802a0abe-6d03-4123-bd10-2252ec40c3c7',
//       metadata: null,
//       sys_date_created: '2020-04-08T18:51:19.987273Z',
//       text: 'tester3 user3 has resolved the request',
//       type: 'system',
//       user_id: null,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03c',
//       id: 'cec14b32-0f12-4e96-ab7d-8bd220d02286',
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'Testing test',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03x',
//       id: 'cec14b32-0f12-4e96rwe20d0228x',
//       metadata: {
//         user_input: {
//           count: 2,
//           none_label: 'Select None',
//           select_special: [
//             {
//               description: 'description 1',
//               index: 1,
//               label: 'label 1',
//               value: 'value 1',
//             },
//             {
//               description: 'description 2',
//               index: 2,
//               label: 'label 2',
//               value: 'value 2',
//             },
//           ],
//           trigger_label: 'View List of 20 results',
//         },
//       },
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: null,
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03x',
//       id: 'cec14b32-0f12-4e96-46564564d0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: 'This is [text title](esp.kbArticle AAABBCC)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03x',
//       id: 'cec14b32-0f12-4e96-ab7d-8bd220d0228x',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: '[Testing](confluence.KB 278298793)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ca5e9f8f-7940-464b-89e1-f2557ad9b03x',
//       id: 'cec14b32-0f12-4e96-rwe',
//       metadata: {},
//       sys_date_created: '2020-04-08T18:50:10.533700Z',
//       text: '[Testing](zendesk.KB 278298793)',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'd76b1c2444-4335-a825-456cd5d123b8',
//       id: '1',
//       metadata: {},
//       response_received: false,
//       sys_date_created: '2022-04-01T16:10:03.259974Z',
//       text: 'https://i.picsum.photos/id/838/200/300.jpg?hmac=yns6FqTn8FmJq3qluHDmnjn6X4x-rC4lGjZVUIMknuI',
//       type: 'message',
//       user_id: 1,
//     },
//     {
//       channel: 'ce08dedc-d659-49f8-a943-f313de68d0a5',
//       id: 'f3c86f0b-16b7-4793-ba53-7e099ca3829a',
//       metadata: {
//         attachment_eid: '9968ebdf-9ef6-48d9-b56f-4840a6053657',
//         height: 3384,
//         mime_type: 'image/png',
//         size: 3204679,
//         type: 'png',
//         url: 'https://q.evisserp.se/media/release/base_attachments/e595aa50-ce47-4805-9250-c1dc4358e62d/Captura_de_Pantalla_2022-02-08_a_las_16.34.53.png?Expires=1656619397&Signature=MEUCIQC5uHGKYjwV7AkrE7XmB2cMGPs9Am8g2-9PGYVmeHnpHQIgQxUyTFbCXkcIhI94oXaxbc0kdLbp-walmrTRzKq22Nk_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
//         width: 6016,
//       },
//       response_received: false,
//       source_type: null,
//       sys_date_created: '2022-03-07T21:15:42.778487Z',
//       text: "I've sent you an attachment, please check it.",
//       type: 'attachment',
//       user_id: 219010,
//     },
//   ]).reverse()
// ); // Important in reverse order from what you get in the API
// END OF TEST BLOB *

const getUsersTypingInChannel = (state, channelId) =>
  state.getIn(['chatState', 'userTyping', channelId]);
const getTestDate = (state, channelId, isoDate) => isoDate;

// Returns the conversation messages with a cleaned up of attachments not containing metadata
const getChannelCleansedMessages = createSelector(
  [getChannelMessages],
  (messages = Immutable.List()) =>
    messages.filter((msg) => {
      if (
        msg.get('type') === ConversationMessagesTypes.ATTACHMENT &&
        !msg.get('metadata')
      ) {
        return false;
      }

      return true;
    })
);

// Decorates the channel messages with "virtual" messages at the end of the list
// where the authors are the users that are typing
const getChannelMessagesWithTyping = createSelector(
  [getChannelCleansedMessages, getUsersTypingInChannel, getTestDate],
  (
    messages = Immutable.List(),
    guysTyping = Immutable.List(),
    testIsoDate = ''
  ) => {
    if (!guysTyping.isEmpty()) {
      guysTyping.forEach((userId) => {
        messages = messages.push(
          Immutable.Map({
            id: `typing_${userId}`,
            sys_date_created: testIsoDate
              ? testIsoDate
              : new Date().toISOString(),
            type: ConversationMessagesTypes.TYPING,
            user_id: userId,
          })
        );
      });
    }

    return messages;
  }
);
// Clean messages that come empty
// cause by "Got it." message not being the last one (DEV-1643  )
const getChannelMessagesWithTypingSanitized = createSelector(
  [getChannelMessagesWithTyping],
  (messages = Immutable.List()) =>
    messages.filter((m) => {
      // filters out empty comments of type "message"
      // unless they're widgets
      if (
        m.get('type') === ConversationMessagesTypes.MESSAGE &&
        // NOR here
        // neither widget nor user_input DEV-10354
        !(
          m.hasIn(['metadata', 'widget']) ||
          m.hasIn(['metadata', 'user_input', 'api_action_button'])
        )
      ) {
        return m.get('text');
      }
      return true; // all other let it pass
    })
);

// Will return only the messages until the "Got it." message
const getMessagesUntilGotIt = createSelector(
  [getChannelMessagesWithTyping],
  (messages = Immutable.List()) => {
    let foundGotIt = false;
    const newMessages = messages.filter((m) => {
      let shouldInclude = true;

      if (foundGotIt) {
        shouldInclude = false;
      }

      const isDimiss = m.getIn(['metadata', 'user_input', 'dismiss']);
      if (isDimiss) {
        foundGotIt = true;
      }

      return shouldInclude;
    });

    return newMessages;
  }
);

export { getChannelMessages };
export { getMessagesUntilGotIt };
export { getChannelCleansedMessages };
export { getUsersTypingInChannel };
export { getChannelMessagesWithTyping };
export { getChannelMessagesWithTypingSanitized };
export default getChannelCleansedMessages;
