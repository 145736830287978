import { isArray, isEmpty } from 'lodash';
import Immutable, { fromJS } from 'immutable';
import faqThunks from '../../actions/faqThunks';
import buildCompositeController from '../../utils/buildCompositeController';

const isPresent = (value) =>
  !(value && value.isEmpty ? value.isEmpty() : isEmpty(value));

const mapStateToProps = (state, ownProps) => {
  // Attention: I did this because there is a bug with Redux/Immutable that doesn't find the scoped values using
  // the full Immutable getIn path, e.g . state.getIn(['barista', 'scopedIntentLookup', ownProps.id, 'isLoading']
  const scopedLookup = state.getIn(['barista', 'scopedIntentLookup']).toJS()[
    ownProps.scope
  ];

  return {
    isLoading: isPresent(scopedLookup) ? scopedLookup.isLoading : false,
    lookupResults: isPresent(scopedLookup)
      ? isArray(scopedLookup.list)
        ? fromJS(scopedLookup.list)
        : scopedLookup.list
      : Immutable.List(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchIntents({ search, scope, intentEid }) {
    dispatch(
      faqThunks.baristaScopedIntentLookup({
        intentEid,
        scope,
        search,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
