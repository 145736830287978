import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form/immutable';
import FormInputSelect from './FormInputSelect';
import _ from 'lodash';

class WorkflowDropdown extends PureComponent {
  static propTypes = {
    goToWorkflowPage: PropTypes.func.isRequired,
    loadWorkflows: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    options: [],
  };

  componentDidMount() {
    const { loadWorkflows } = this.props;
    loadWorkflows();
  }

  render() {
    const { options, goToWorkflowPage } = this.props;
    return (
      <Form>
        <Field
          component={FormInputSelect}
          fluid
          label='Select a workflow'
          name='workflow'
          onChange={goToWorkflowPage}
          options={options}
          placeholder='Type to search...'
          search
          selection
        />
      </Form>
    );
  }
}

export default reduxForm({
  form: 'WorkflowDropdown',
  validate: _.noop,
})(WorkflowDropdown);
