import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Button, Divider, Grid } from 'semantic-ui-react';

import WorkflowBrowserPageController from '../../../../../../admin/js/v1/components/controllers/WorkflowBrowserPageController';
// Molecules
import WorkflowSelectForm from '../../molecules/WorkflowSelectForm';
// Controllers
import WorkflowModal from '../../organisms/WorkflowModal';

class WorkflowBrowserPage extends PureComponent {
  static propTypes = {
    goToWorkflowPage: PropTypes.func.isRequired,
    loadWorkflows: PropTypes.func.isRequired,
    workflowsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        key: PropTypes.any,
        text: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        value: PropTypes.any,
      })
    ).isRequired,
  };

  static defaultProps = {};

  render() {
    const { workflowsOptions, goToWorkflowPage, loadWorkflows } = this.props;

    const newWorkflowbutton = (
      <Button
        content={intl.formatMessage({
          id: 'label.create_new_workflow',
        })}
        fluid
      />
    );

    return (
      <Grid.Row>
        <Grid.Column computer={5} mobile={16} tablet={8}>
          <WorkflowSelectForm
            goToWorkflowPage={goToWorkflowPage}
            loadWorkflows={loadWorkflows}
            options={workflowsOptions}
          />

          <Divider horizontal>
            {intl.formatMessage({
              id: 'label.Or',
            })}
          </Divider>

          <WorkflowModal trigger={newWorkflowbutton} />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowBrowserPage = WorkflowBrowserPageController(WorkflowBrowserPage);

export default WorkflowBrowserPage;
