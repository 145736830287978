// import PropTypes               from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
// import Immutable               from 'immutable';
import { Grid, Segment } from 'semantic-ui-react';
import { Field, FieldArray, reduxForm } from 'redux-form/immutable';

import FormInputText from './FormInputText';
// import FormInputRadio          from './FormInputRadio';

// import FormInputSelect         from './FormInputSelect';
import BlockArrayOfConditions from '../../../../../admin/js/v1/components/blocks/forms/BlockArrayOfConditions';
// Atom
import ExternalLink from '../atoms/ExternalLink';
import AddConditionFormController from '../controllers/AddConditionFormController';

class AddConditionForm extends PureComponent {
  static propTypes = {
    conditionSet: ImmutablePropTypes.map,
  };

  static defaultProps = {
    conditionSet: null,
  };

  render() {
    const { conditionSet } = this.props;

    if (!conditionSet) {
      return null;
    }

    return (
      <>
        <Grid.Row>
          <Grid.Column width={16}>
            <label>
              {'Condition Set URL : '}
              <ExternalLink href={conditionSet.get('url')}>
                {conditionSet.get('url')}
              </ExternalLink>
            </label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Segment className='editorConditions'>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    component={FormInputText}
                    fluid
                    label='Name : '
                    name='name'
                    placeholder='Enter condition set name'
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    component={FormInputText}
                    fluid
                    label='Failure Workflow URL : '
                    name='failure_workflow'
                    placeholder='Enter Failure Workflow URL'
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    component={FormInputText}
                    fluid
                    label='Failure Workflow Task URL : '
                    name='failure_workflow_task'
                    placeholder='Enter Failure workflowtask URL'
                  />
                </Grid.Column>
              </Grid.Row>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <FieldArray
              component={BlockArrayOfConditions}
              label='Conditions'
              name='condition_entry'
            />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
AddConditionForm = reduxForm({
  enableReinitialize: true,
  form: 'ConditionForm',
  keepDirtyOnReinitialize: true,
})(AddConditionForm);

export default AddConditionFormController(AddConditionForm);
