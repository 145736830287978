import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { NotificationStack } from 'react-notification';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Controller
import ToastNotificationsController from '../controllers/ToastNotificationsController';

class ToastNotifications extends PureComponent {
  static propTypes = {
    removeToast: PropTypes.func,
    toasts: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  };

  static defaultProps = {
    removeToast: _.noop,
  };

  handleToastClick = (toast, deactivate) => {
    const { removeToast } = this.props;

    deactivate();
    const toastID = toast.key;
    removeToast(toastID);

    if (_.hasIn(toast, 'clickHandler.click')) {
      toast.clickHandler.click();
    }
  };

  handleToastDismiss = (toast) => {
    const { removeToast } = this.props;

    const toastID = toast.key;
    removeToast(toastID);
  };

  render() {
    const { toasts } = this.props;

    const removableToastsArray = toasts
      .toJS() // NotificationStack expects plain JS Array
      .map((toast) => {
        if (_.hasIn(toast, 'clickHandler.action')) {
          toast.action = toast.clickHandler.action;
        }
        toast.onClick = this.handleToastClick;
        return toast;
      });

    return (
      <NotificationStack
        dismissInOrder={false} // workaround for https://github.com/pburtchaell/react-notification/issues/110#issuecomment-297260651
        notifications={removableToastsArray}
        onDismiss={this.handleToastDismiss}
      />
    );
  }
}

export default ToastNotificationsController(ToastNotifications);
