import buildCompositeController from '../../utils/buildCompositeController';

// Thunk
import searchThunks from '../../actions/searchThunks';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Action
import searchActions from '../../actions/searchActions';
// Selector
import getTeamClassification, {
  getTeamClassificationById,
} from '../../selectors/getTeamClassification';
import { change, getFormValues, isDirty } from 'redux-form/immutable';

const FORM = 'ServiceDepartmentTeamCreate';

const mapStateToProps = (state, ownProps) => {
  const shortHandSearch = state.getIn(['search', 'userSearch']);
  const departmentList = state.getIn(['caseMgmt', 'serviceDepartment', 'list']);
  const formError = state.getIn(['form', FORM, 'syncErrors']) || null;

  let teamClassification;
  if (ownProps.importServiceTeam) {
    const seletedInFormDepartment =
      getFormValues(FORM)(state) &&
      getFormValues(FORM)(state).get('service_department');
    const departmentSelected =
      departmentList &&
      departmentList.find((dep) => dep.get('id') === seletedInFormDepartment);
    const departmentSelectedName =
      departmentSelected && departmentSelected.get('service_department');
    teamClassification = getTeamClassificationById(
      state,
      departmentSelectedName
    );
  } else {
    teamClassification = getTeamClassification(
      state,
      ownProps.departmentSelected
    );
  }

  let teamLeadSelected;
  if (ownProps.teamSelected) {
    teamLeadSelected = state.getIn([
      'entities',
      'users',
      ownProps.teamSelected.get('team_lead'),
    ]);
  }

  if (state.getIn(['search', 'userSearch', 'defaultSelection'])) {
    teamLeadSelected = state.getIn([
      'search',
      'userSearch',
      'defaultSelection',
    ]);
  }

  const formValues = getFormValues(FORM)(state);

  return {
    departmentList: departmentList,
    formError,
    formValues,
    isDirty: isDirty(FORM)(state),
    isLoading: state.getIn(['caseMgmt', 'serviceDepartmentTeam', 'isLoading']),
    isLoadingSearch: shortHandSearch.get('isLoading'),
    teamClassification,
    teamLeadSelected,
    usersSearch: shortHandSearch.get('results'),
    usersSearchTerm: shortHandSearch.get('userInput'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  change(property, value) {
    dispatch(change(FORM, property, value));
  },

  createNewTeam(data, departmentID, msg, cb) {
    dispatch(caseMgmtThunks.createNewTeam(data, departmentID)).then(
      (newTeam) => {
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        cb(newTeam);
      }
    );
  },

  formValidation(value) {
    const error = {};
    //   classification
    //   team_name          : '',
    //   team_lead          : '',
    //   description        : '',

    if (!value.get('classification')) {
      error.classification = 'Select a classification';
    }

    if (!value.get('team_name')) {
      error.team_name = "Can't be empty";
    }

    if (!value.get('team_lead')) {
      error.team_lead = "Can't be empty";
    }

    if (!value.get('description')) {
      error.description = "Can't be empty";
    }

    return error;
  },

  getServiceDepartmentClassification() {
    // These two have a rough relationship,
    // Where the link is not actually done by id
    // for now let's just load them all and filter them in the ui
    // otherwise we'd need BE extension of this old feature
    dispatch(
      caseMgmtThunks.loadTeamClassificationList({
        limit: 1000,
      })
    ); // this actually gets all the team classifications
    dispatch(
      caseMgmtThunks.loadServiceDepartmenList({
        limit: 1000,
      })
    );
  },

  searchUsers() {
    dispatch(searchThunks.searchUsers());
  },

  setDefaultManager(sysId) {
    dispatch(searchThunks.findSnowUser(sysId)).then((user) => {
      dispatch(searchActions.setDefaultSearchUser(user));
    });
  },

  setSearchInputUsers(searchTerm) {
    dispatch(searchActions.setUserSearchInput(searchTerm));
  },

  updateTeam(data, departmentID, teamID, msg, cb) {
    dispatch(caseMgmtThunks.updateOneTeam(data, teamID, departmentID)).then(
      () => {
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        cb();
      }
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
