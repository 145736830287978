import createHierarchyThunks from './createHierarchyThunks';
import locationsActions from './locationsActions';
// Utils
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

const locationsThunks = createHierarchyThunks({
  getChildrenNodes: {
    fail: locationsActions.getChildrenLocationsFail,
    start: locationsActions.getChildrenLocationsStart,
    success: locationsActions.getChildrenLocationsSuccess,
  },

  getDefaultSelectedNode: {
    fail: locationsActions.getDefaultSelectedLocationFail,
    start: locationsActions.getDefaultSelectedLocationStart,
    success: locationsActions.getDefaultSelectedLocationSuccess,
  },

  getRootNodes: {
    fail: locationsActions.getRootLocationsFail,
    start: locationsActions.getRootLocationsStart,
    success: locationsActions.getRootLocationsSuccess,
  },

  nodeInstanceEndpoint: 'espPlaces.locations.instance',

  nodeInstanceEndpointIDAttrName: 'locationID',

  nodeTypeEndpoint: 'espPlaces.types',

  nodesCollectionEndpoint: 'espPlaces.locations',
});

locationsThunks.getLeafNodes = ({ initial = false, locationType = '' }) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();

    const next = state.getIn([
      'hierarchySelect',
      'leaftNodesPagination',
      'next',
    ]);
    let initialUrl;
    if (locationType) {
      initialUrl = `${endpointGenerator.genPath(
        'espPlaces.locations'
      )}?esp_filters=location_type.name__IEQ=${locationType}`;
    } else {
      initialUrl = `${endpointGenerator.genPath(
        'espPlaces.locations'
      )}?esp_filters=children__ISNULL=True`;
    }

    let url = initial ? initialUrl : next;
    if (!url) {
      url = initialUrl;
    }

    dispatch(locationsActions.getChildrenLocationsStart());
    // / make this API Call
    APIcall.get({
      token: true,
      url,
    })
      .then(({ body }) => {
        const childLocations = body.results;
        dispatch(
          locationsActions.getChildrenLocationsSuccess(
            childLocations,
            body.next
          )
        );
        resolve(childLocations);
      })
      .catch((error) => {
        dispatch(locationsActions.getChildrenLocationsFail());
        reject(error);
      });
  });

export default locationsThunks;
