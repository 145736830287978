import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { intl } from 'esp-util-intl';
import { Accordion, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import EspIcon from '../../../../../v1/components/atoms/EspIcon';
import CustomActionButtonBuilder from '../CustomActionButtonBuilder';

class ActionButtonFormSection extends React.PureComponent {
  static propTypes = {
    action: PropTypes.shape({
      button_text: PropTypes.string,
      icon: PropTypes.string,
    }),
    changeFormFieldValueWithAnnouncementId: PropTypes.func,
    index: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    action: {},
    changeFormFieldValueWithAnnouncementId: noop,
    readOnly: false,
  };

  state = {
    isAccordionOpen: true,
  };

  handleToggleAccordion = () => {
    this.setState((state) => ({
      isAccordionOpen: !state.isAccordionOpen,
    }));
  };

  render() {
    const {
      action,
      changeFormFieldValueWithAnnouncementId,
      index,
      readOnly,
    } = this.props;

    const { isAccordionOpen } = this.state;

    const { button_text, icon } = action;

    const baseSegmentContent = `${index + 1}. ${intl.formatMessage({
      id: 'label.action_button',
    })}`;

    const segmentContent = isEmpty(button_text)
      ? baseSegmentContent
      : `${baseSegmentContent} (${intl.formatMessage({ id: button_text })})`;

    const buttonBuilder = (
      <CustomActionButtonBuilder
        action={action}
        changeFormFieldValueWithAnnouncementId={
          changeFormFieldValueWithAnnouncementId
        }
        index={index}
        readOnly={readOnly}
        showPreview={isEmpty(icon)}
      />
    );

    // I hate the following code, but Redux-Form drove me nuts. If I try to build my components as layouts that receive the buttonBuilder, I get a very weird behavior on
    // the Icon Layout. This is not how I like code built but works. If you try to componentize these in a Layout (functional) style, Redux starts unregistering the fields
    // in the layout that uses the icon.

    const iconLayout = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            flexBasis: 'content',
            paddingTop: '0.25em',
          }}
        >
          <EspIcon
            name={icon}
            style={{
              color: '#17A589',
              fontSize: '3rem',
              marginTop: '0.5rem',
            }}
          />
        </div>
        <div
          className='customAction'
          float='right'
          style={{
            flexBasis: '85%',
          }}
        >
          {buttonBuilder}
        </div>
      </div>
    );

    const flatLayout = <div>{buttonBuilder}</div>;

    return (
      <Segment>
        <Accordion>
          <Accordion.Title
            active={isAccordionOpen}
            onClick={this.handleToggleAccordion}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Header
              as='h5'
              content={segmentContent}
              style={{
                marginBottom: '7px',
                marginTop: '4px',
                padding: '0em',
              }}
            />
            <Icon name='dropdown' />
          </Accordion.Title>
          <Accordion.Content
            active={isAccordionOpen}
            style={{ marginTop: '-5px' }}
          >
            <Divider style={{ marginBottom: '0.5rem' }} />
            {isEmpty(icon) ? flatLayout : iconLayout}
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}
export default ActionButtonFormSection;
