import React from 'react';
import { Grid, Popup } from 'semantic-ui-react';

import store from '../../stores/store';
import b64DecodeIfEncoded from '../../utils/base64Encode';

const BuildInfo = () => {
  const appVersion = store.getState().get('appVersion');
  const clientId = b64DecodeIfEncoded(appVersion.get('clientId'));
  const systemId = b64DecodeIfEncoded(appVersion.get('systemId'));
  const nativeMobileVersion = b64DecodeIfEncoded(
    appVersion.get('nativeMobileVersion')
  );
  const nativeDesktopVersion = b64DecodeIfEncoded(
    appVersion.get('nativeDesktopVersion')
  );

  const triggerStyle = {
    color: '#aaa',
    cursor: 'pointer',
    fontSize: '11px',
    opacity: 0.6,
    paddingTop: '1em',
    textAlign: 'center',
    textDecoration: 'underline',
    textShadow: '0 0 2em rgba(0,0,0,.5)',
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column textAlign='center' verticalAlign='bottom' width={16}>
          <Popup
            on='click'
            position='top center'
            trigger={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO replace with buttons
              <a className='version-link' style={triggerStyle}>
                {'Version Info'}
              </a>
            }
          >
            <p
              style={{
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              {`Client ID: ${clientId}`}
              <br />
              {`System ID: ${systemId}`}
              <br />
              {nativeMobileVersion && `App Version: ${nativeMobileVersion}`}
              <br />
              {nativeDesktopVersion && `App Version: ${nativeDesktopVersion}`}
            </p>
          </Popup>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BuildInfo;
