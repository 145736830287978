import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Globals
import TypeCreateNewFaq, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCreateNewFaq';
import SetValueFromKBSnowInFAQ01Controller from '../../controllers/SetValueFromKBSnowInFAQ01Controller';

/**
 * This block is an empty component which will pass the KB title to the name/description of a new FAQ
 */

class SetValueFromKBSnowInFAQ01 extends PureComponent {
  static propTypes = {
    ...TypeCreateNewFaq,
    query: PropTypes.shape({
      title: PropTypes.string,
    }),
    setValues: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    query: {},
  };

  componentDidMount() {
    const {
      query: { title },
      setValues,
    } = this.props;

    if (title) {
      setValues(title);
    }
  }

  render() {
    const { template, type } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {/* Empty content */}
      </div>
    );
  }
}

export const SetValueFromKBSnowInFAQ01Test = SetValueFromKBSnowInFAQ01;

export default SetValueFromKBSnowInFAQ01Controller(SetValueFromKBSnowInFAQ01);
