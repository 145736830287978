import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import _ from 'lodash';
import TypeButton, { TypeDefaults } from './TypeButton';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const rule = () => ({});

class Button01 extends PureComponent {
  static propTypes = {
    ...TypeButton,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    loading: false,
    onClick: _.noop,
  };

  render() {
    const {
      className,
      type,
      template,
      text,
      loading,
      onClick,
      to,
    } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        <Button
          as={Link}
          content={text}
          loading={loading}
          onClick={onClick}
          primary
          to={to}
        />
      </div>
    );
  }
}

export default createComponent(rule, Button01, [
  'text',
  'loading',
  'onClick',
  'to',
  'template',
  'type',
  'id',
  'trigger',
]);
