const api_authentication_v0_1_auth_backend = {
  active_directory_base_dn: '',
  active_directory_knobs: null,
  active_directory_password: '',
  active_directory_username: '',
  api_key: '',
  auth_backend: 'AZURE',
  azure_client_id: '123',
  azure_client_secret: '/SN8OOJdnUgA65/yd7HSeaZCIUVqhE+eFGVDy/2pdmo=',
  azure_login_via_oauth: true,
  azure_tenant_id: '123',
  azure_token_endpoint: '',
  base_url: '',
  custom_map: null,
  eid: '139beab3-38ab-4074-84ca-5663ae078607',
  enable_saml: false,
  enabled: true,
  id: 12,
  name: 'yolo',
  ordering: 1,
  saml_idp_metadata_url: '',
  sys_created_by: 'john@acme.com',
  sys_custom_fields: null,
  sys_date_created: '2019-04-11T20:00:44.143412Z',
  sys_date_updated: '2019-04-11T20:00:44.143380Z',
  sys_updated_by: 'john@acme.com',
  url: 'http://tenant1.esp/api/authentication/v0.1/auth_backend/12/',
};

export default api_authentication_v0_1_auth_backend;
