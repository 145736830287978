/* eslint-disable sort-keys -- backend shape */
const data = {
  frequency: 'weekly',
  results: [
    {
      start_date: '2021-09-07T00:00:00Z',
      end_date: '2021-09-10T23:59:59Z',
      content: { deflected: 10, not_deflected: 1, empty: 1 },
    },
  ],
};
/* eslint-enable sort-keys */

export default data;
