import { createSelector } from 'reselect';

const job_role_permissions = (state) =>
  state.getIn(['bundles', 'job_role_permissions']);
const bundleID = (state, bundleID) => Number(bundleID);

const getBundleJobRolePermissions = (job_role_permissions, bundleID) =>
  job_role_permissions.filter((p) =>
    p.get('bundles').find((b) => b === bundleID)
  );

const selector = createSelector(
  [job_role_permissions, bundleID],
  getBundleJobRolePermissions
);

export default selector;
