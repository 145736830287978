import React from 'react';
import styles from './LocalWarn.module.scss';

const LocalWarn = () => {
  return process.env.NODE_ENV === 'development' ? (
    <div className={styles._} title='NODE_ENV = development'>
      Local
    </div>
  ) : null;
};

export default LocalWarn;
