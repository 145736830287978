import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Form,
  Header,
  Modal,
  Select,
} from 'semantic-ui-react';
import ImmutableProps from 'react-immutable-proptypes';
import InboxFormController from './InboxFormController';
import { withFormik } from 'formik';
import EmailServiceTypes from '../../../globals/EmailServiceTypes';
import { object, string } from 'yup';
import { emailServiceOptions } from '../../pages/admin/integrations/email/emailConstsAndUtils';
import {
  modalCredentialsExclusiveExchangeGmailFields,
  modalCredentialsExclusiveExchangeOauthFields,
  modalDetailsInboxForm,
  updatedFields,
} from '../../pages/admin/integrations/email/PrimaryEmailAccountFormFields';
import EmailAccountTypes from '../../../globals/EmailAccountTypes';
import { isEmpty } from 'lodash';
import GmailInboxFormModal from './GmailInboxFormModal';

class InboxForm extends Component {
  static propTypes = {
    addNewEmailAccount: PropTypes.func.isRequired,
    caseInbox: PropTypes.shape({
      credentials: PropTypes.shape({
        email_address: PropTypes.string,
        id: PropTypes.number,
        secret_creds: PropTypes.shape({ client_secret: PropTypes.string }),
      }),
      eid: PropTypes.string,
      esp_service_department: PropTypes.number,
      id: PropTypes.number,
      inbox: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
      }),

      lastEmailScanned: PropTypes.node,
      serviceDepartment: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    credentialId: PropTypes.number,
    departmentList: ImmutableProps.list,
    editEmailCredentials: PropTypes.func.isRequired,
    editEmailCredentialsAndServiceDepartement: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    inboxAccountType: PropTypes.string,
    isDirty: PropTypes.bool,
    isValid: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    reopenNewInboxModal: PropTypes.func,
    setFieldValue: PropTypes.func,
    testEmailConnection: PropTypes.func.isRequired,
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      app_id: PropTypes.string,
      client_secret: PropTypes.string,
      domain: PropTypes.string,
      email_address: PropTypes.string,
      email_type: PropTypes.string,
      esp_service_department: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
      password: PropTypes.string,
      server_name: PropTypes.string,
      tenant_id: PropTypes.string,
      use_autodiscover: PropTypes.bool,
    }),
  };

  static defaultProps = {
    caseInbox: {},
    departmentList: ImmutableProps.list,
  };

  state = {
    client_secret: '',
    isGmailConnected: false,
    serviceDepartmentOptions: [],
  };

  componentDidMount() {
    this.initializeValues();
  }

  initializeValues = () => {
    const { departmentList } = this.props;
    const { serviceDepartmentOptions } = this.state;
    if (serviceDepartmentOptions.length === 0) {
      this.setState({
        serviceDepartmentOptions: this.formatDepartmentList(departmentList),
      });
    }
  };

  formatDepartmentList = (departmentList) => {
    const serviceDepartment = departmentList
      .map((list) => ({
        key: list.get('id'),
        text: list.get('name'),
        value: list.get('id'),
      }))
      .toJS();
    return [
      {
        key: null,
        text: 'ANY',
        value: 'any',
      },
      ...serviceDepartment,
    ];
  };

  getGmailConnectionStatus = (isGmailConnected) => {
    this.setState({ isGmailConnected });
  };
  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleToggleAutoDiscover = (e, { checked: value, name }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  handleChangeSelect = (e, { name, value }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  handleOnFocusClientSecret = () => {
    const { caseInbox, setFieldValue } = this.props;
    const currentClientSecret =
      caseInbox?.credentials?.secret_creds?.client_secret;
    setFieldValue('client_secret', '');
    this.setState({ client_secret: currentClientSecret });
  };

  handleOnBlurClientSecret = () => {
    const { caseInbox, setFieldValue, values } = this.props;
    const { client_secret } = this.state;
    const currentClientSecret =
      caseInbox?.credentials?.secret_creds?.client_secret;
    if (values.client_secret === '' && currentClientSecret !== '') {
      setFieldValue('client_secret', client_secret);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      caseInbox,
      values,
      addNewEmailAccount,
      editEmailCredentials,
      editEmailCredentialsAndServiceDepartement,
    } = this.props;
    const emailType = values?.email_type;
    const currentCredential = { ...caseInbox?.credentials };

    const modifiedFields =
      !isEmpty(caseInbox?.credentials) &&
      updatedFields({
        currentCredential,
        emailType,
        values,
      });

    const isUpdating =
      !isEmpty(modifiedFields) &&
      currentCredential?.id &&
      emailType === currentCredential?.email_type;

    if (isUpdating) {
      const serviceDepartment = caseInbox?.serviceDepartment?.id;
      let emailData = {
        ...modifiedFields,
        id: currentCredential.id,
      };
      if (values.client_secret && values.client_secret !== '******') {
        emailData.secret_creds = {
          client_secret: values.client_secret,
        };
      }

      if (
        serviceDepartment === values.esp_service_department ||
        serviceDepartment === null
      ) {
        delete emailData.esp_service_department;
        editEmailCredentials({ emailData, emailType });
      } else {
        const { esp_service_department } = emailData;
        if (esp_service_department === 'any') {
          emailData.esp_service_department = null;
        }

        emailData = {
          ...emailData,
          caseGeneratorID: caseInbox.id,
        };
        editEmailCredentialsAndServiceDepartement({ emailData, emailType });
      }
    } else if (!currentCredential?.id) {
      let fieldsByEmailType = {};
      const {
        app_id,
        client_secret,
        tenant_id,
        password,
        domain,
        use_autodiscover,
        server_name,
        name,
        email_address,
        email_type,
        esp_service_department,
      } = values;
      switch (emailType) {
        case EmailServiceTypes.EXCHANGE_OAUTH:
          fieldsByEmailType = {
            email_address,
            email_type,
            esp_service_department:
              esp_service_department === 'any' ? null : esp_service_department,
            name,
            primary: false,
            secret_creds: {
              client_secret,
            },
            text_creds: {
              app_id,
              tenant_id,
            },
          };
          break;
        case EmailServiceTypes.EXCHANGE:
          fieldsByEmailType = {
            domain,
            email_address,
            email_type,
            esp_service_department:
              esp_service_department === 'any' ? null : esp_service_department,
            name,
            password,
            primary: false,
            server_name,
            use_autodiscover,
          };
          break;
        case EmailServiceTypes.GMAIL:
          fieldsByEmailType = {
            email_address,
            email_type,
            esp_service_department:
              esp_service_department === 'any' ? null : esp_service_department,
            name,
          };
          break;
        default:
          break;
      }
      addNewEmailAccount(fieldsByEmailType);
    }
  };

  handleTest = () => {
    const { caseInbox, values, testEmailConnection } = this.props;
    const caseInboxCredentials = caseInbox?.credentials;
    const currentCredential = { ...caseInboxCredentials, ...values };
    const emailAccountType = EmailAccountTypes.PRIMARY;
    const emailData = {
      email_address: currentCredential.email_address,
      id: currentCredential.id,
    };
    testEmailConnection(emailData, emailAccountType);
  };

  handleChangeSelect = (e, { name, value }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  render() {
    const {
      values,
      errors,
      handleChange,
      // isDirty,
      isValid,
      handleBlur,
      caseInbox,
      touched,
      credentialId,
      onClose,
      reopenNewInboxModal,
    } = this.props;
    const emailType = values?.email_type;

    const { isGmailConnected, serviceDepartmentOptions } = this.state;

    const propsToEmailForms = {
      errors,
      handleBlur,
      handleChange,
      handleChangeCheckbox: this.handleToggleAutoDiscover,
      handleChangeSelect: this.handleChangeSelect,
      handleOnBlurClientSecret: this.handleOnBlurClientSecret,
      handleOnFocusClientSecret: this.handleOnFocusClientSecret,
      serviceDepartmentOptions: serviceDepartmentOptions,
      touched,
      values,
    };

    const isGmailForm = emailType === EmailServiceTypes.GMAIL;
    const isDisabledForGmail = !credentialId && isValid;
    const style = { pointerEvents: 'none' };

    return (
      <Form noValidate>
        <Modal.Content
          style={{
            padding: '1em',
          }}
        >
          <Form.Field
            control={Select}
            fluid
            label='Email Service'
            name='email_type'
            onChange={this.handleChangeSelect}
            options={emailServiceOptions}
            required
            style={caseInbox?.credentials?.id ? style : {}}
            value={values.email_type || ''}
          />
          <Header as='h4' content='Details' />
          {/* General Fields  */}
          {!isGmailForm && modalDetailsInboxForm(propsToEmailForms)}
          {!isGmailForm && <Header as='h4' content='Credentials' />}
          {emailType === EmailServiceTypes.EXCHANGE &&
            modalCredentialsExclusiveExchangeGmailFields(propsToEmailForms)}
          {emailType === EmailServiceTypes.EXCHANGE_OAUTH &&
            modalCredentialsExclusiveExchangeOauthFields(propsToEmailForms)}
          {isGmailForm && (
            <GmailInboxFormModal
              {...propsToEmailForms}
              credentialId={credentialId}
              credentials={caseInbox.credentials}
              getGmailConnectionStatus={this.getGmailConnectionStatus}
              onClose={onClose}
              reopenNewInboxModal={reopenNewInboxModal}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content='Save'
            disabled={isGmailForm ? !isDisabledForGmail : !isValid}
            floated='right'
            onClick={this.handleSubmit}
            positive
          />

          {caseInbox?.credentials?.id && (
            <Button
              basic
              content='Test'
              disabled={
                isGmailForm
                  ? !isGmailConnected
                  : !caseInbox?.credentials?.email_address
              }
              floated='right'
              onClick={this.handleTest}
            />
          )}
          <Button
            basic
            content='Cancel'
            floated='right'
            onClick={this.handleClose}
          />
          <Divider clearing fitted hidden />
        </Modal.Actions>
      </Form>
    );
  }
}

const InboxFormWithFormik = withFormik({
  displayName: 'PrimaryEmailAccountForm',
  enableReinitialize: true,
  mapPropsToValues: ({ caseInbox, inboxAccountType }) => {
    return {
      // exchange OAuth credential fields
      app_id: caseInbox?.credentials?.text_creds?.app_id || '',

      client_secret: caseInbox?.credentials?.secret_creds?.client_secret || '',

      domain: caseInbox?.credentials?.domain || '',

      email_address: caseInbox?.credentials?.email_address || '',

      // general fields
      email_type:
        caseInbox?.credentials?.email_type ||
        inboxAccountType ||
        EmailServiceTypes.EXCHANGE_OAUTH,

      esp_service_department: caseInbox?.serviceDepartment?.id || 'any',

      name: caseInbox?.credentials?.name || '',

      // exchange and gmail credential fields
      password: caseInbox?.credentials?.password || '',

      server_name: caseInbox?.credentials?.server_name || '',
      tenant_id: caseInbox?.credentials?.text_creds?.tenant_id || '',
      use_autodiscover: caseInbox?.credentials?.use_autodiscover || false,
    };
  },
  validationSchema: object().shape({
    email_address: string().email('Invalid email').required('Required'),
    name: string().required('Required'),
  }),
})(InboxForm);

const InboxFormTest = InboxFormWithFormik;

// eslint-disable-next-line no-class-assign -- DEV-1526
InboxForm = InboxFormController(InboxFormWithFormik);

export { InboxFormTest };
export default InboxForm;
