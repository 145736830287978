import persistentStorage from './persistentStorage';
import PersistentStorageKeys, {
  TOKEN_KEY,
} from './globals/PersistentStorageKeys';

const TokenUtil = {
  clearToken() {
    // just a shorthad really
    TokenUtil.setToken('');
  },

  getToken() {
    if (persistentStorage.has(PersistentStorageKeys.SESSION)) {
      const session = persistentStorage.get(PersistentStorageKeys.SESSION);
      return session[TOKEN_KEY];
    }

    return '';
  },

  isAuthenticated() {
    const token = TokenUtil.getToken();
    return Boolean(token);
  },

  setToken(tokenString = '') {
    if (typeof tokenString === 'string') {
      // Note from Erick of the past:
      // this complies with the format we decided the token,
      // inside a session object wrapper (see sessionThunks.setSessionUser)
      // so we're just mantaining the same format here, but as this package evolves
      // we can choose a different structure
      persistentStorage.put(PersistentStorageKeys.SESSION, {
        accessToken: tokenString,
      });
    }
  },
};
export default TokenUtil;
