import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Checkbox, Form, Header } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, isBoolean, memoize } from 'lodash';

// Controller
import Radio03Controller from '../../controllers/Radio03Controller';

// Globals
import TypeRadio, { TypeDefaults } from '../../../globals/blocks/TypeRadio03';

class Radio03 extends Component {
  static propTypes = {
    ...TypeRadio,
    formValues: ImmutablePropTypes.map,
    onChangeValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    formValues: null,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- TODO fix bad practice
    options: this.props.options,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { formValues, options, onChangeValue, id } = nextProps;

    const value = formValues.get(id);

    if (!value || !isBoolean(value)) {
      let checked = false;
      // Check for value
      if (value) {
        const index = options.findIndex((o) => o.name === value);
        if (index > -1) {
          checked = true;

          onChangeValue(options[index].id, id);

          // Build new options
          const newOptions = options.map((option) => {
            if (option.name !== value) {
              option.checked = false;
            } else {
              option.checked = true;
            }
            return option;
          });

          // update the state
          this.setState({
            options: newOptions,
          });
        }
      }

      if (!checked) {
        // Check for default checked
        options.forEach((option) => {
          if (option.checked) {
            onChangeValue(option.name, id);
          }
        });
      }
    }
  }

  handleClick = memoize(
    curry((name, e) => {
      // Marking only selected option as checked

      const { options, onChangeValue, id } = this.props;

      const newOptions = options.map((option) => {
        option.checked = option.name === name;
        if (option.checked) {
          onChangeValue(option.name, id);
        }
        return option;
      });

      this.setState({
        options: newOptions,
      });
    })
  );

  render() {
    const { template, type } = this.props;
    const { options } = this.state;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {options.map((option) => (
          <Form.Field key={option.name}>
            <Checkbox
              as={option.as}
              checked={option.checked}
              disabled={option.disabled}
              fitted={option.fitted}
              id={option.name}
              label={
                <label>
                  <Header
                    as='h3'
                    content={option.title}
                    subheader={option.subTitle}
                  />
                  <p>{option.description}</p>
                </label>
              }
              onClick={this.handleClick(option.name)}
              radio
              value={option.name}
            />
          </Form.Field>
        ))}
      </div>
    );
  }
}

export const Radio03Test = Radio03;

export default Radio03Controller(Radio03);
