import { connect } from 'react-redux';

import getRootLocations from '../../selectors/getRootLocations';
import getWiredLocationsByID from '../../selectors/getWiredLocationsByID';
import locationThunks from '../../actions/locationsThunks';

const mapStateToProps = (state) => {
  const nodesByID = getWiredLocationsByID(state);
  const rootNodes = getRootLocations(state);

  return {
    isLoadingLocation: state.getIn([
      'onboardActivity',
      'schedule',
      'isloadingLocation',
    ]),
    nodesByID,
    rootNodes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getChildrenNodes(parentID) {
    return dispatch(locationThunks.getChildrenNodes(parentID));
  },

  getDefaultSelectedNode(selectedNodeID) {
    return dispatch(locationThunks.getDefaultSelectedNode(selectedNodeID));
  },

  getRootNodes() {
    return dispatch(locationThunks.getRootNodes());
  },
});

// default React Redux mergeProps
const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps);

// tells React Redux we can wrap non pure components with this controller
const options = {
  pure: false,
};

const LocationSelectController = (component) =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, options)(component);

export default LocationSelectController;
