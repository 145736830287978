import faqThunks from '../../actions/faqThunks';
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const intentLookup = state.getIn(['barista', 'intentLookup']);
  const isLoading = intentLookup.get('isLoading');
  const lookupResults = intentLookup.get('list');

  return {
    isLoading,
    lookupResults,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchIntentByEID(edit) {
    return dispatch(faqThunks.baristaIntentLookupByEID(edit));
  },
  searchIntents(search) {
    dispatch(faqThunks.baristaIntentLookup({ search }));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
