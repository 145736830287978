import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  baristaImg: {
    default: '',
    type: 'string',
  },
  messages: {
    arrayShape: {
      text: {
        required: false,
        type: 'string',
      },
    },
    default: [],
    type: 'arrayOf',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'barista01',
  },
});

// Object Definition used by blobifier
const BaristaBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Barista',
  templates: ['barista01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, BaristaBlockDefinition, TypeDefaults };
