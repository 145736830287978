import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsEtaNormal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M69.87 53.512c0 12.832-10.44 23.272-23.27 23.272-12.833 0-23.273-10.44-23.273-23.272 0-12.833 10.44-23.27 23.272-23.27 12.83 0 23.27 10.437 23.27 23.27zM43.34 20.41h7.573v-2.165H43.34v2.164zm27.587 10.407l3.18 3.18 2.294-2.295-8.65-8.654-2.292 2.295 3.18 3.18-4.066 4.066c-4.322-3.72-9.8-6.12-15.816-6.59v-2.35h3.787c.896 0 1.623-.728 1.623-1.624v-5.41c0-.896-.728-1.62-1.624-1.62H41.72c-.898 0-1.624.724-1.624 1.62v5.41c0 .896.726 1.622 1.623 1.622h3.784v2.286C30.792 26.52 19 38.66 19 53.512c0 15.218 12.38 27.6 27.6 27.6 15.217 0 27.598-12.382 27.598-27.6 0-7.194-2.77-13.752-7.296-18.67l4.025-4.025z' />
          <path d='M49.29 49.677V39.34c0-1.194-.968-2.163-2.163-2.163-1.195 0-2.164.97-2.164 2.164v10.34c-1.288.75-2.163 2.13-2.163 3.73 0 2.39 1.937 4.328 4.327 4.328 2.39 0 4.327-1.935 4.327-4.326 0-1.6-.875-2.977-2.163-3.727' />
        </g>
      </svg>
    );
  }
}

export default CardsEtaNormal;
