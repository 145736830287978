import { useEffect, useState } from 'react';

import { baristaBotIcon } from 'esp-globals';
import { fetchSVG } from '../../globals/commonAPICalls';
import { ImageDefaults } from 'esp-assets';

const useChatBotIcon = (isCustomBrandingEnabled = false, chatBotSvgURL) => {
  const [chatBotIcon, setChatBotIcon] = useState(ImageDefaults.BARISTA_AVATAR);
  const [isLoading, setLoading] = useState();

  const convertSVGTextToDOM = (text) => {
    const svgDOM = new DOMParser().parseFromString(text, 'image/svg+xml');
    const body = svgDOM.firstChild?.innerHTML;
    let width = parseInt(svgDOM.firstChild?.getAttribute('width'), 10);
    let height = parseInt(svgDOM.firstChild?.getAttribute('height'), 10);

    if (!width && !height) {
      const [svg] = svgDOM.getElementsByTagName('svg');
      const viewBoxValues = svg?.getAttribute('viewBox')?.split(' ');
      const widthIndex = 2;
      const heightIndex = 3;
      width = viewBoxValues ? parseInt(viewBoxValues[widthIndex], 10) : 64;
      height = viewBoxValues ? parseInt(viewBoxValues[heightIndex], 10) : 64;
    }

    return {
      body,
      height,
      width,
    };
  };

  const setNewChatBotIcon = (newChatBotIcon) => {
    const svgObject = convertSVGTextToDOM(newChatBotIcon);
    setChatBotIcon(svgObject);
  };

  const resetChatBotIcon = () => {
    setChatBotIcon(baristaBotIcon);
  };

  useEffect(() => {
    const loadSVG = async () => {
      if (chatBotSvgURL) {
        const { text } = await fetchSVG(chatBotSvgURL);
        const svgObject = convertSVGTextToDOM(text);
        setChatBotIcon(svgObject);
        setLoading(false);
      }
    };

    if (chatBotSvgURL && isCustomBrandingEnabled) {
      setLoading(true);
      loadSVG();
    } else {
      setChatBotIcon(baristaBotIcon);
    }
  }, [chatBotSvgURL, isCustomBrandingEnabled]);

  return {
    chatBotIconSVG: chatBotIcon,
    isLoadingChatBotIcon: isLoading,
    resetChatBotIcon,
    setNewChatBotIcon,
  };
};

export default useChatBotIcon;
