/**
 * Type of bundle in use in AdminBundle and AdminRoleBundle
 */

const BundleType = {
  BUNDLE: 'bundle',
  ROLE_BUNDLE: 'role_bundle',
};

export default BundleType;
