import React, { PureComponent } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Image,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ErrorLabel from '../atoms/ErrorLabel';
// Global
import imageRegex from '../../globals/imageRegex';

class ResponsesTextArea extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,

    error: PropTypes.string,

    /** fields length */
    fieldsLength: PropTypes.number,

    /** current index in the field array */
    index: PropTypes.number,

    /** Coming from Redux Form  */
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        ImmutablePropTypes.map,
      ]),
    }),

    isProtected: PropTypes.bool,

    linkElement: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),

    move: PropTypes.func,

    onBlurText: PropTypes.func,

    onFocusText: PropTypes.func,

    onRemove: PropTypes.func,

    onToggleActive: PropTypes.func,

    placeholder: PropTypes.string,

    setRef: PropTypes.func,

    valueChange: PropTypes.func,
  };

  static defaultProps = {
    active: true,
    error: '',
    fieldsLength: 0,
    index: 0,
    input: '',
    isProtected: false,
    linkElement: null,
    move: _.noop,
    onBlurText: _.noop,
    onFocusText: _.noop,
    onRemove: _.noop,
    onToggleActive: _.noop,
    placeholder: '',
    setRef: _.noop,
    valueChange: _.noop,
  };

  handleOnChange = (value) => {
    const { input, valueChange } = this.props;

    input.onChange(value);
    valueChange(value);
  };

  handleMoveUp = (e) => {
    e.preventDefault();

    const { index, move } = this.props;
    move(index, index - 1);
  };

  handleMoveDown = (e) => {
    e.preventDefault();
    const { index, move } = this.props;
    move(index, index + 1);
  };

  handleRemove = (e) => {
    e.preventDefault();
    const { index, onRemove } = this.props;
    onRemove(index);
  };

  setTextRef = (ref) => {
    this.ref = ref;
    const { index, setRef } = this.props;
    setRef(ref, index);
  };

  handleFocus = (e) => {
    const { index, onFocusText } = this.props;
    onFocusText(e, index);
  };

  handleBlur = (e) => {
    const { index, onBlurText } = this.props;
    onBlurText(e, index);
  };

  handleOnToggleActive = (e) => {
    e.preventDefault();
    const { index, onToggleActive } = this.props;
    onToggleActive(index);
  };

  render() {
    const {
      error,
      fieldsLength,
      index,
      input,
      placeholder,
      linkElement,
      isProtected,
      active,
    } = this.props;

    let isImage = false;
    let name = '',
      newSrc = null,
      newUrl = '';

    const checkboxStyle = {
      float: 'right',
      marginBottom: '10px',
      overflow: 'hidden',
    };

    /**
     * Detect if it's an image or a file or a text
     */
    if (
      Immutable.Map.isMap(input.value) ||
      (_.isObject(input.value) && _.hasIn(input, 'value.url')) ||
      (_.isObject(input.value) && _.hasIn(input, 'value.src')) ||
      (!_.isObject(input.value) &&
        Boolean(
          !input.value.match('][(]http') && // not a markdown syntaxt
            (input.value.match('data:image/') || // data image file
              (input.value.match('^(http|https)://') &&
                _.last(input.value.split('/')).split('.').length > 1)) // URL containing a file
        ))
    ) {
      if (_.isObject(input.value) && _.hasIn(input, 'value.url')) {
        newUrl = input.value.url;
      } else if (_.isObject(input.value) && _.hasIn(input, 'value.name')) {
        newUrl = input.value.name;
      } else if (_.isObject(input.value) && _.hasIn(input, 'value.src')) {
        newUrl = input.value.src;
      } else if (Immutable.Map.isMap(input.value) && input.value.has('name')) {
        newUrl = input.value.get('name');
      } else {
        newUrl = input.value;
      }

      name = newUrl.split('/');
      name = name[name.length - 1];

      isImage = imageRegex.test(name);
      isImage =
        isImage ||
        (!_.isObject(input.value) &&
          Boolean(input.value.match('data:image/'))) ||
        (input.value.src && Boolean(input.value.src.match('data:image/')));

      if (isImage) {
        if (_.isObject(input.value)) {
          if (input.value.url) {
            newSrc = input.value.url;
          } else if (
            Immutable.Map.isMap(input.value) &&
            input.value.has('name')
          ) {
            newSrc = input.value.get('name');
          } else {
            newSrc = input.value.src;
          }
        } else {
          newSrc = input.value;
        }
      } else {
        const [splitName] = name.split('?');
        name = splitName;
      }
    }

    return (
      <Segment>
        {fieldsLength > 1 ||
        index >= 1 ||
        index < fieldsLength - 1 ||
        linkElement ? (
          <Button.Group
            basic
            floated='right'
            size='small'
            style={{
              display: active && !isProtected ? '' : 'none',
              marginBottom: '.5rem',
            }}
          >
            {linkElement}

            {index >= 1 ? (
              <Button icon='arrow up' onClick={this.handleMoveUp} />
            ) : null}

            {index < fieldsLength - 1 ? (
              <Button icon='arrow down' onClick={this.handleMoveDown} />
            ) : null}

            {fieldsLength > 1 ? (
              <Button icon='close' onClick={this.handleRemove} />
            ) : null}
          </Button.Group>
        ) : null}

        {isProtected ? (
          <Checkbox
            checked={active}
            onClick={this.handleOnToggleActive}
            style={checkboxStyle}
            toggle
          />
        ) : null}

        <Divider clearing fitted hidden />
        {isImage ? (
          <Image src={newSrc} />
        ) : name ? (
          <span
            style={{
              wordWrap: 'break-word',
            }}
          >
            {name}
          </span>
        ) : (
          <>
            <TextArea
              {...input}
              onBlur={this.handleBlur}
              onChange={this.handleOnChange}
              onFocus={this.handleFocus}
              placeholder={placeholder}
              readOnly={isProtected || !active}
              ref={this.setTextRef}
            />
            {error && (
              <ErrorLabel
                error={error}
                inputName='responseError'
                pointing='above'
              />
            )}
          </>
        )}
      </Segment>
    );
  }
}

export default ResponsesTextArea;
