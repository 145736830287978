import _ from 'lodash';

// Actions
import reactRouterActions from '../actions/reactRouterActions';

const locationKeyChars = '0123456789abcdefghijklmnopqrstuvwxyz';

/**
 * Generates a random location key of length 6 containing only chars from 'locationKeyChars'.
 * @returns {string}
 */
const generateLocationKey = () => {
  let locationKey = '';

  _.times(6, () => {
    const randIndex = _.random(_.size(locationKeyChars) - 1);
    const nextChar = locationKeyChars.charAt(randIndex);
    locationKey += nextChar;
  });

  return locationKey;
};

/**
 * Don't write application code that relies on 'location.key'. If you need to know
 * wheter or not the route changed compare the location Objects directly like:
 * location !== nextLocation
 * 'location.key' is missing when 'createHashHistory' is used, and that's why we
 * had to workaround it here.
 */
const syncReactRouterRedux = (history, store) => {
  let { location } = history;
  const initialLocationKey = location.key || generateLocationKey();

  store.dispatch(reactRouterActions.sync(initialLocationKey));

  const unlisten = history.listen((nextLocation) => {
    // location is immutable so we get a new one when there's a change,
    // shallow comparison is ok
    if (location !== nextLocation) {
      const nextLocationKey = nextLocation.key || generateLocationKey();
      store.dispatch(reactRouterActions.sync(nextLocationKey));
      location = nextLocation;
    }
  });

  return unlisten;
};

export default syncReactRouterRedux;
