import Immutable from 'immutable';
import { submit } from 'redux-form';
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const productIDs = ownProps.products;
  const productIDsSet = new Set(productIDs);

  const reduxFormState = state.get('form');
  const productFulfillmentForm = reduxFormState.get(
    'ProductFulfillment',
    Immutable.Map()
  );

  const fulfillmentFormsByProduct = productFulfillmentForm.filter(
    (fulfillmentForm, productID) => productIDsSet.has(Number(productID))
  );

  const fulfillmentAnswersByProduct = fulfillmentFormsByProduct.map(
    (fulfillmentForm) => fulfillmentForm.get('values')
  );
  const isValid = fulfillmentFormsByProduct.every(
    (fulfillmentForm) => !fulfillmentForm.has('syncErrors')
  );

  return {
    fulfillmentAnswersByProduct,
    isValid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitProductFulfillmentForms() {
    const productIDs = ownProps.products;
    productIDs.forEach((productID) =>
      dispatch(submit(`ProductFulfillment.${productID}`))
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
