import React from 'react';
import { Header } from 'semantic-ui-react';
import pt from 'prop-types';
import DateTools from '../../../../../../utils/DateTools';

const propTypes = {
  countRecords: pt.string,
  updatedDate: pt.string,
};

const SyncCompleted = ({ updatedDate, countRecords = 0 }) => {
  return (
    <div>
      <Header as='h4'>{'Last Sync Results'}</Header>
      <p>{`Finished on ${DateTools.getDateFromUTCToLocal({
        date: updatedDate,
        toLocaleDateString: true,
      })}`}</p>
      <Header as='h4'>{countRecords}</Header>
      <p>{'Users Synced'}</p>
    </div>
  );
};

SyncCompleted.propTypes = propTypes;

export default SyncCompleted;
