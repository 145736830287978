import buildCompositeController from '../../../../utils/buildCompositeController';
// Actions
// import toastNotificationsActions from '../../../../actions/toastNotificationsActions';
// import wfIntlThunks              from '../../../../actions/wfIntlThunks';
import wfIntlActions from '../../../../actions/wfIntlActions';

const mapStateToProps = (state) => {
  const currentSelectedTaskId = state.getIn(['wfIntl', 'editorSelectedTask']);

  return {
    currentSelectedTaskId: currentSelectedTaskId,
    isLoadingTask: state.getIn(['wfIntl', 'editorTaskIsLoading']),
    task: state.getIn(['wfIntl', 'editorTasks', currentSelectedTaskId]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  launchIntlKeyEditor: (term, blobKeyPath) => {
    // terms can be an untranslated text "Hello there"
    // Or a intl key enclosed inside {{ }}
    // so we have to remove curly braces if they are at the beggining and end of the term

    // look for [[...]] matches
    let intlKey = term;
    const isDoubleBracketExpression = new RegExp(/^\[\[(.*?)\]\]$/); // Begins with [[, ends with ]]
    if (isDoubleBracketExpression.test(term)) {
      // matches "[[sensual]]"
      const match = isDoubleBracketExpression.exec(term);
      const secondItemIndex = 1;
      intlKey = match[secondItemIndex]; // sensual
    }
    // term will be transformed into a key if it's not a key already
    dispatch(wfIntlActions.launchIntlKeyEditor(intlKey, blobKeyPath));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
