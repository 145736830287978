import buildCompositeController from '../../utils/buildCompositeController';
import userEntitiesThunks from '../../actions/userEntitiesThunks';
import getUserDataFromEntities from '../../selectors/getUserDataFromEntities';

const mapStateToProps = (state, ownProps) => {
  const userId = Number(ownProps.userId); // Forcing numeric id
  const userData = getUserDataFromEntities(state, userId);
  return {
    error: state.getIn(['containersStatus', 'users', userId, 'error']),
    userData: userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUserData: (userId) => {
    if (userId) {
      dispatch(userEntitiesThunks.addUserEntity(userId));
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
