const api_barista_v0_1_intents = {
  results: [
    {
      action: 'handler://send_catalog_product',
      action_metadata: null,
      activation_condition: null,
      active: true,
      application: '3600c68d-7511-4c34-844d-0daf8ca9935e',
      application_name: 'Send catalog item',
      archetype: null,
      archetype_default_intent: null,
      available_pre_employee: false,
      created_in_elc: false,
      default_task_type: null,
      description: 'Intent to send items to someone',
      eid: '2b715721-a0fb-4aea-b1ea-84cabb740d23',
      end_conversation: false,
      is_archetype: false,
      is_protected: false,
      is_reviewed: false,
      last_sync_time: null,
      name: 'Send catalog product',
      parent_intent: null,
      response_mode: 'ONE',
      sys_created_by: null,
      sys_date_created: '2020-02-06T16:39:37.304218Z',
      sys_date_updated: '2020-02-06T18:12:17.518560Z',
      sys_updated_by: null,
      total_activations: 0,
      total_completions: 0,
    },
  ],
};

export default api_barista_v0_1_intents;
