import React from 'react';
import pt from 'prop-types';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Boundaries } from 'cascara-middleware';
import BadSessionFallback from '../BadSessionFallback';

import AuthProvider from '../../context/auth/AuthProvider';
import PlatformProvider from '../../context/platform/PlatformProvider';
import TenantProvider from '../../context/tenant/TenantProvider';

const propTypes = {
  children: pt.oneOfType([pt.node, pt.arrayOf(pt.node)]),
  showRQdevTools: pt.bool,
};

// For now we are not changing any defaults for the queryClient. In the future we may
// decide to override these here. We think there will not be a need to have these be
// different per application. If that changes, we can potentially expose a `clientConfig`
// object as a prop on EspAuth.
const queryClient = new QueryClient();

//
// Espressive Auth
// @param {JSX.Element} children The content to be displayed when there is an active
const EspAuth = ({ children, showRQdevTools }) => (
  <Boundaries ErrorComponent={BadSessionFallback}>
    <PlatformProvider>
      <TenantProvider>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            {children}
            {showRQdevTools && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </AuthProvider>
      </TenantProvider>
    </PlatformProvider>
  </Boundaries>
);

EspAuth.propTypes = propTypes;
EspAuth.displayName = 'Authentication';

export default EspAuth;
