import buildCompositeController from '../../utils/buildCompositeController';
import getConditionSet from '../../selectors/getConditionSet';

const mapStateToProps = (state) => {
  const conditionSet = getConditionSet(state);

  return {
    conditionSet,
    formValues: state.getIn(['form', 'AddAttributeForm', 'values']),
    initialValues: {
      condition_entry:
        (conditionSet && conditionSet.get('condition_entry')) || '',
      failure_workflow:
        (conditionSet && conditionSet.get('failure_workflow')) || '',
      failure_workflow_task:
        (conditionSet && conditionSet.get('failure_workflow_task')) || '',
      name: (conditionSet && conditionSet.get('name')) || '',
    },
  };
};

export default buildCompositeController(mapStateToProps);
