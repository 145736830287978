import NewHireStatus from './NewHireStatus';

const ShowPerformanceGroupOptions = (intl, groupPermissions) => [
  {
    text: intl.formatMessage({
      id: 'label.new_hire',
    }),
    value: NewHireStatus,
  },
  {
    text: intl.formatMessage({
      id: 'label.hr_contact',
    }),
    value: groupPermissions.HR_CONTACT,
  },
  {
    text: intl.formatMessage({
      id: 'label.hr_recruiter',
    }),
    value: groupPermissions.HR_RECRUITER,
  },
  {
    text: intl.formatMessage({
      id: 'label.enablement',
    }),
    value: groupPermissions.ENABLEMENT,
  },
  {
    text: intl.formatMessage({
      id: 'label.it_contact',
    }),
    value: groupPermissions.IT_CONTACT,
  },
  {
    text: intl.formatMessage({
      id: 'label.operations',
    }),
    value: groupPermissions.OPERATIONS,
  },
];

export default ShowPerformanceGroupOptions;
