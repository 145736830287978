import { createSelector } from 'reselect';
import { i18Locales } from 'esp-util-intl';

const DEFAULT_LOCALE = i18Locales.en_us;

const tenantLocale = (state) =>
  state?.getIn(['tenant', 'entity', 'value', 'ui_config', 'locale']);

const intlLocale = (state) => state?.getIn(['intl', 'locale']);

const getLocale = (tenantLocale, intlLocale) =>
  intlLocale || tenantLocale || DEFAULT_LOCALE;

const selector = createSelector([tenantLocale, intlLocale], getLocale);

export default selector;
