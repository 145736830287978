import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Grid, Input, Segment } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';
import { NavLink } from 'react-router-dom';
import { debounce } from 'lodash';

// Molecules
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import FormInputSelect from './FormInputSelect';
import FormSelectUserInput from './FormSelectUserInput';
import FormInputCheckbox from './FormInputCheckbox';
import UserImageContainer from './UserImageContainer';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
// Global
import TeamRolesCaseMgmt from '../../globals/TeamRolesCaseMgmt';
import groupPermissions from '../../../v1/globals/groupPermissions';
// Controller
import ServiceTeamMemberListController from '../controllers/ServiceTeamMemberListController';

const TIMEOUT = 300;

class ServiceTeamMemberList extends PureComponent {
  static propTypes = {
    /** Remove an user */
    deleteUser: PropTypes.func.isRequired,
    /** New member form error */
    formError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    /** Method to add a new Member */
    isLoadingSearch: PropTypes.bool,
    /** Check if the current user belong to the FAQ Admin group */
    isUsrFaqAdmin: PropTypes.bool,
    listSelected: ImmutablePropTypes.map,
    /** New Member selected in the search field */
    memberSelected: ImmutablePropTypes.map,
    /** Launch the user search */
    searchUsers: PropTypes.func.isRequired,
    /** Set search users input */
    setSearchInputUsers: PropTypes.func.isRequired,
    submitNewMember: PropTypes.func.isRequired,
    teamLead: PropTypes.number,
    /** user Name for the selected user */
    userName: PropTypes.string,
    /** User list search */
    usersSearch: ImmutablePropTypes.list,
    /** User search term input */
    usersSearchTerm: PropTypes.string,
  };

  static defaultProps = {
    formError: null,
    isLoadingSearch: false,
    isUsrFaqAdmin: false,
    listSelected: immutable.Map(),
    memberSelected: null,
    teamLead: null,
    userName: null,
    usersSearch: immutable.List(),
    usersSearchTerm: '',
  };

  state = {
    name: '',
    role: '',
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const { usersSearchTerm, searchUsers } = this.props;
    if (
      nextProps.usersSearchTerm &&
      nextProps.usersSearchTerm !== usersSearchTerm
    ) {
      searchUsers();
    }
  }

  handleSearchChange = debounce((e, value) => {
    const { setSearchInputUsers } = this.props;
    setSearchInputUsers(value.searchQuery);
  }, TIMEOUT);

  handleDeleteUser = () => {
    const { listSelected, deleteUser } = this.props;

    deleteUser(listSelected.get('id'));
  };

  handleSubmitNewUser = (e) => {
    e.preventDefault();
    const { listSelected, submitNewMember } = this.props;
    const formName = `FormMember_${listSelected.get('id')}`;

    submitNewMember(formName);
  };

  onHandleValidation = (value) => {
    if (!value.get('name')) {
      return {
        name: 'Select an user',
      };
    }

    if (!value.get('role')) {
      return {
        name: 'Select a role',
      };
    }

    return {};
  };

  handleOnBlur = (name) => (e) => {
    e.preventDefault();
    const { formError } = this.props;

    const { state } = this;
    state[name] = formError && formError[name] ? formError[name] : '';
    this.setState(state);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  search = (options) => options;

  render() {
    const {
      isLoadingSearch,
      isUsrFaqAdmin,
      listSelected,
      userName,
      usersSearch,
      formError,
      memberSelected,
    } = this.props;

    const isTeamMemberFaqAdmin =
      memberSelected && !memberSelected.isEmpty()
        ? memberSelected.get('groups')?.contains(groupPermissions.FAQ_ADMIN)
        : false;

    const selectedUser = listSelected.get('member');

    const formName = `FormMember_${listSelected.get('id')}`;
    const userID = selectedUser
      ? selectedUser.get('id')
      : listSelected.get('user');

    const initialValues = {
      name: userID,
      role: listSelected.get('auth_group'),
    };

    let readOnly = true;

    let userImageLink;
    let buttonContent = 'Delete';
    let disableButton = false;
    let primary = false;
    let handleButtonClick = this.handleDeleteUser; // DELETE HERE

    const { name, role } = this.state;

    if (listSelected.isEmpty() || !listSelected.get('auth_group')) {
      readOnly = false;

      if (memberSelected && !memberSelected.isEmpty()) {
        const propsImg = {
          className: 'circular',
        };
        propsImg.userId = memberSelected.get('id');
        userImageLink = <UserImageContainer {...propsImg} />;
        buttonContent = 'Save';
        primary = true;
        disableButton = Boolean(
          formError && (formError.name || formError.role)
        ); // Disable if an error exists on a field
        handleButtonClick = this.handleSubmitNewUser;
      }
    } else if (userID) {
      userImageLink = (
        <NavLink
          to={uiPathGenerator.genPath('app.directory.detail', {
            userID: userID,
          })}
        >
          <UserImageContainer className='circular' userId={userID} />
        </NavLink>
      );
    }

    const memberError = !memberSelected && formError && name ? name : '';

    const selectedAgent = readOnly
      ? TeamRolesCaseMgmt.filter(
          (t) => t.key === listSelected.get('auth_group')
        )
      : null;

    return (
      <Segment as={Grid} clearing divided>
        <Grid.Row columns={2}>
          <Grid.Column width={2}>{userImageLink}</Grid.Column>
          <Grid.Column width={12}>
            <ValidatedForm
              enableReinitialize
              form={formName}
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              validate={this.onHandleValidation}
            >
              {readOnly ? (
                <Form.Field>
                  <label>{'Name'}</label>
                  <Input readOnly transparent value={userName || ''} />
                </Form.Field>
              ) : (
                <ValidatedField
                  component={FormSelectUserInput}
                  disabled={readOnly}
                  formError={memberError}
                  label='Name'
                  loading={isLoadingSearch}
                  name='name'
                  onBlur={this.handleOnBlur(name)}
                  onSearchChange={this.handleSearchChange}
                  placeholder='Search by name'
                  readOnly
                  search={this.search}
                  selectedUser={selectedUser}
                  userList={usersSearch}
                />
              )}

              {readOnly ? (
                <Form.Field>
                  <label>{'Role'}</label>
                  <Input
                    readOnly
                    transparent
                    value={
                      isTeamMemberFaqAdmin
                        ? `FAQ Admin, ${selectedAgent[0].text}`
                        : selectedAgent[0].text
                    }
                  />
                </Form.Field>
              ) : (
                <ValidatedField
                  component={FormInputSelect}
                  disabled={readOnly}
                  formError={formError && role}
                  label='Role'
                  name='role'
                  onBlur={this.handleOnBlur(role)}
                  options={TeamRolesCaseMgmt}
                  placeholder='Search by name'
                  readOnly
                />
              )}

              {!readOnly && isUsrFaqAdmin && !isTeamMemberFaqAdmin ? (
                <ValidatedField
                  component={FormInputCheckbox}
                  disabled={readOnly}
                  formError={formError && role}
                  label='Invite as FAQ Admin'
                  name='faqAdmin'
                />
              ) : null}
            </ValidatedForm>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              content={buttonContent}
              disabled={disableButton}
              onClick={handleButtonClick}
              primary={primary}
            />
          </Grid.Column>
        </Grid.Row>
      </Segment>
    );
  }
}

export const ServiceTeamMemberListTest = ServiceTeamMemberList;

export default ServiceTeamMemberListController(ServiceTeamMemberList);
