import { change, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';

// Thunk
import workflowThunks from '../../actions/workflowThunks';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const formValues = getFormValues('Form01')(state) || fromJS({});

  const isManager = state.hasIn([
    'workflowState',
    'frontendScratch',
    'is_manager',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'is_manager'])
    : null;

  return {
    formValues,
    isManager,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    dispatch(change('Form01', id, data));
  },

  setIsManager(value, cb) {
    dispatch(
      workflowThunks.updateFrontEndScratch({
        is_manager: value,
      })
    ).then(() => cb());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
