import React from 'react';
import pt from 'prop-types';
import { TransitionablePortal } from 'semantic-ui-react';
import styles from './Lightbox.module.scss';
import classNames from 'classnames/bind';

const propTypes = {
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node, pt.string]),
  className: pt.string,
  id: pt.string,
  onClick: pt.func,
  open: pt.bool,
  transition: pt.shape({
    animation: pt.string,
    duration: pt.number,
  }),
  trigger: pt.node,
};

const cx = classNames.bind(styles);

const handleOpen = (onClick) => () => {
  window.addEventListener('keydown', handleCloseOnEscape(onClick));
};

const handleClose = (onClick) => () => {
  window.removeEventListener('keydown', handleCloseOnEscape(onClick));
};
const handleStopPropagate = () => (e) => {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};

const handleCloseOnEscape = (onClick) => (e) => {
  if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
    e.preventDefault();
    onClick();
  }
};

const Lightbox = ({
  id,
  onClick,
  open,
  transition = {
    animation: 'fade',
    duration: 400,
  },
  trigger,
  children,
  className,
}) => {
  return (
    <TransitionablePortal
      onClose={handleClose(onClick)}
      onOpen={handleOpen(onClick)}
      open={open}
      transition={transition}
      trigger={trigger}
    >
      {}
      <div
        className={cx(className, {
          _: true,
          flex: true,
        })}
        data-component='Lightbox'
        id={id}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <div onClick={handleStopPropagate} onKeyPress={handleStopPropagate}>
          {children}
        </div>
      </div>
    </TransitionablePortal>
  );
};

Lightbox.propTypes = propTypes;
export default Lightbox;
