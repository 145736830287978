import { NavLink } from 'react-router-dom';
import { Button, Divider, Form, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Recaptcha from 'react-recaptcha';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

// Globals
import EmailRegExp from '../../globals/EmailRegExp';
import RecaptchaConfig from '../../globals/RecaptchaConfig';
import ResetPasswordMethods from '../../globals/ResetPasswordMethods';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

// Molecules
import ValidatedField from '../molecules/ValidatedField';
import FormInputRadio from '../molecules/FormInputRadio';

// Packages
import { intl } from 'esp-util-intl';

const validate = (values) => {
  const errors = {};

  const email = values.get('email').trim();

  // validate email
  if (_.isEmpty(email)) {
    errors.email = 'Required';
  } else if (!EmailRegExp.test(email)) {
    errors.email = 'Please add a valid email';
  }

  return errors;
};

class ResetPasswordSendCodeForm extends PureComponent {
  static propTypes = {
    /** From Redux Form */
    change: PropTypes.func,
    errorMsg: PropTypes.string,
    /** From Redux Form */
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    verifiedRecaptcha: PropTypes.bool,
  };

  static defaultProps = {
    change: _.noop,
    errorMsg: null,
    handleSubmit: _.noop,
    isLoading: false,
    verifiedRecaptcha: false,
  };

  setRef = (ref) => {
    this.captchaRef = ref;
  };

  //
  // expiredRecaptchaCallback(){
  // const {change} = this.props;
  // change('verifiedRecaptcha', false);
  // }
  //

  verifyRecaptchaCallback = () => {
    const { change } = this.props;
    if (this.captchaRef) {
      this.captchaRef.reset(); // Workaround for Firefox to remove the image challenge popup
    }
    change('verifiedRecaptcha', true);
  };

  render() {
    const { errorMsg, handleSubmit, isLoading, verifiedRecaptcha } = this.props;

    return (
      <Form error={Boolean(errorMsg)} onSubmit={handleSubmit}>
        <Message info size='tiny'>
          {intl.formatMessage({
            id: 'message.this_will_reset_your_barista_password',
          })}
        </Message>

        <ValidatedField
          fluid
          icon='user'
          name='email'
          placeholder='Email'
          required
          type='email'
        />

        {!verifiedRecaptcha ? (
          <Form.Field
            style={{
              textAlign: 'center',
            }}
          >
            <Helmet>
              <script
                async
                defer
                src='https://www.google.com/recaptcha/api.js'
              />
            </Helmet>
            <Recaptcha
              // expiredCallback={this.expiredRecaptchaCallback}
              onloadCallback={_.noop}
              ref={this.setRef}
              // https://github.com/appleboy/react-recaptcha/issues/242
              render='explicit'
              sitekey={RecaptchaConfig.getSiteKey()}
              verifyCallback={this.verifyRecaptchaCallback}
            />
          </Form.Field>
        ) : (
          <>
            <Form.Field>
              <label>
                {intl.formatMessage({
                  id: 'label.recovery_method',
                })}
              </label>
            </Form.Field>
            <Field
              component={FormInputRadio}
              label='Work Email'
              name='recoveryMethod'
              type='radio'
              value={ResetPasswordMethods.PRIMARY_EMAIL}
            />
            <Field
              component={FormInputRadio}
              label='Personal Email'
              name='recoveryMethod'
              type='radio'
              value={ResetPasswordMethods.SECONDARY_EMAIL}
            />
            <Field
              component={FormInputRadio}
              label='Cell Phone'
              name='recoveryMethod'
              type='radio'
              value={ResetPasswordMethods.SMS}
            />
          </>
        )}

        {errorMsg && (
          <Message error size='tiny'>
            {errorMsg}
          </Message>
        )}

        <Form.Field>
          <Button
            // need to validate recaptcha separately because it's not a registered field
            disabled={!verifiedRecaptcha || isLoading}
            fluid
            loading={isLoading}
            primary
            type='submit'
          >
            {intl.formatMessage({
              id: 'label.send_code',
            })}
          </Button>
        </Form.Field>

        {window.cordova || window.electron ? (
          <>
            <Divider horizontal />
            <NavLink to={uiPathGenerator.genPath('login')}>{'Go back'}</NavLink>
          </>
        ) : null}
      </Form>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
ResetPasswordSendCodeForm = reduxForm({
  validate,
})(ResetPasswordSendCodeForm);

const mapStateToProps = (state, ownProps) => {
  // For not for mobile app we are going to ignore recaptcha challenge
  const verifiedRecaptchaDefault = Boolean(window.cordova || window.electron);
  return {
    initialValues: {
      email: ownProps.email,

      recoveryMethod: ResetPasswordMethods.PRIMARY_EMAIL,

      verifiedRecaptcha: verifiedRecaptchaDefault,
    },

    verifiedRecaptcha: formValueSelector(ownProps.form)(
      state,
      'verifiedRecaptcha'
    ),
  };
};

// eslint-disable-next-line no-class-assign -- DEV-1526
ResetPasswordSendCodeForm = connect(mapStateToProps)(ResetPasswordSendCodeForm);

export default ResetPasswordSendCodeForm;
