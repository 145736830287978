import { APPLICATION_VALUE, DEFAULT_LANGUAGE } from '../utils';

import APIcall from '../../../../../../utils/APIcall';
import endpointGenerator from '../../../../../../utils/endpointGenerator';

const saveNewTranslation = async ({ key, text }) => {
  return APIcall.post({
    data: {
      application: APPLICATION_VALUE,
      key,
      language: DEFAULT_LANGUAGE,
      text,
    },
    token: true,
    url: endpointGenerator.genPath('commons.localization'),
  });
};

export default saveNewTranslation;
