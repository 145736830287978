import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

class ExternalLogin extends Component {
  static propTypes = {
    externalLoginUri: PropTypes.string.isRequired,
    loginExternally: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    const { externalLoginUri, loginExternally } = this.props;
    // externalLoginUri;
    // loginExternally;
    loginExternally(externalLoginUri);
  }

  render() {
    return (
      <div>
        {/* This text won't be seen 95% of the time.
         * Leaving it here so the user can go back to tenant select if the close the in-app browser
         */}
        <p>{'Connecting to your SSO provider..'}</p>
        {window.cordova || window.electron ? (
          <Button
            as={Link}
            basic
            content={'Change tenant'}
            to={uiPathGenerator.genPath('noTenant')}
          />
        ) : null}
      </div>
    );
  }
}

export default ExternalLogin;
