import React from 'react';
import PropTypes from 'prop-types';
import { noop, zipObject } from 'lodash';
import { Button, Form, Input, Message, Select } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import BaristaIntentLookup from '../../../../../v1/components/molecules/BaristaIntentLookupNoReduxForm';

import {
  ACTION_TYPES,
  MAX_ACTION_TEXT_LENGTH,
  PREVIEW_TEXT,
} from '../../../../../v1/globals/announcementCustomActionConstants';

import { isActionValid } from '../SurveyTypes';

const fieldsToReset = (newActionType) => {
  switch (newActionType) {
    case ACTION_TYPES.URL:
      return ['intent_eid'];
    case ACTION_TYPES.INTENT:
      return ['action_url'];
    case ACTION_TYPES.REPORT_RESPONSE_ONLY:
      return ['action_url', 'intent_eid'];
    default:
      return [];
  }
};
class CustomActionButtonBuilder extends React.PureComponent {
  static propTypes = {
    action: PropTypes.shape({
      action_url: PropTypes.string,
      button_text: PropTypes.string,
      intent_eid: PropTypes.string,
      type: PropTypes.string,
    }),

    changeFormFieldValueWithAnnouncementId: PropTypes.func,
    index: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
    showPreview: PropTypes.bool,
  };

  static defaultProps = {
    action: {
      action_url: '',
      button_text: PREVIEW_TEXT,
      intent_eid: '',
      type: ACTION_TYPES.REPORT_RESPONSE_ONLY,
    },
    changeFormFieldValueWithAnnouncementId: noop,
    readOnly: false,
    showPreview: false,
  };

  state = {
    actionUrl: '',
    buttonText: '',
    selectedActionType: null,
  };

  static getDerivedStateFromProps(nextProps, state) {
    const {
      action_url: nextActionUrl,
      button_text: nextButtonText,
      type: nextSelectedType,
    } = nextProps.action;

    if (!state.actionUrl && !state.buttonText && !state.selectedActionType) {
      return {
        ...state,
        actionUrl: nextActionUrl,
        buttonText: intl.formatMessage({ id: nextButtonText }),
        selectedActionType: nextSelectedType,
      };
    }

    return state;
  }

  actionTypeOptions = () => [
    {
      key: ACTION_TYPES.URL,
      text: intl.formatMessage({ id: 'label.external_url' }),
      value: ACTION_TYPES.URL,
    },
    {
      key: ACTION_TYPES.INTENT,
      text: intl.formatMessage({ id: 'label.open_barista_chat' }),
      value: ACTION_TYPES.INTENT,
    },
    {
      key: ACTION_TYPES.REPORT_RESPONSE_ONLY,
      text: intl.formatMessage({ id: 'label.report_responses_only' }),
      value: ACTION_TYPES.REPORT_RESPONSE_ONLY,
    },
  ];

  handleActionTypeChange = (_, { value }) => {
    const { action } = this.props;
    // reset values in redux form
    fieldsToReset(value).forEach((field) => this.saveField(field, ''));

    // and now reset values in memory to validate
    const fill = new Array(fieldsToReset.length).fill('');
    const resetValues = zipObject(fieldsToReset, fill);

    const actionCopy = {
      ...action,
      ...resetValues,
    };

    this.saveField('type', value);
    const isValid = isActionValid(value, actionCopy);
    this.saveField('isValid', isValid);
    this.setState({ selectedActionType: value });
  };

  handleButtonTextChange = (_, { value }) => {
    const { action } = this.props;

    const isValid = this.validateField('button_text', value, action);
    this.saveField('isValid', isValid);
    this.saveField('button_text', value);
    this.setState({ buttonText: value });
  };

  handleActionUrlChange = (evt, { value }) => {
    const { action } = this.props;
    const isValid = this.validateField('action_url', value, action);
    this.saveField('isValid', isValid);
    this.saveField('action_url', value);
    this.setState({ actionUrl: value });
  };

  handleIntentChange = (_, { value }) => {
    const { action } = this.props;
    const isValid = this.validateField('intent_eid', value, action);
    this.saveField('isValid', isValid);
    this.saveField('intent_eid', value);
  };

  saveField = (fieldName, value) => {
    const { changeFormFieldValueWithAnnouncementId, index } = this.props;
    const member = `actions.${index}`;
    changeFormFieldValueWithAnnouncementId(`${member}.${fieldName}`, value);
  };

  validateField = (fieldName, newValue, action) => {
    const isValid = isActionValid(action.type, {
      ...action,
      [fieldName]: newValue,
    });
    return isValid;
  };

  render() {
    const {
      action,
      changeFormFieldValueWithAnnouncementId,
      index,
      readOnly,
      showPreview,
    } = this.props;

    // const actionType = action.type || ACTION_TYPES.REPORT_RESPONSE_ONLY;
    const { actionUrl, buttonText, selectedActionType } = this.state;

    return (
      <>
        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.text' })}</label>
          <Input
            maxLength={MAX_ACTION_TEXT_LENGTH}
            onChange={this.handleButtonTextChange}
            readOnly={readOnly}
            required
            type='text'
            value={buttonText}
          />
        </Form.Field>

        {buttonText.length + 1 > MAX_ACTION_TEXT_LENGTH && (
          <Message
            content={intl.formatMessage({
              id: 'message.action_text_too_large',
              values: {
                max_len: MAX_ACTION_TEXT_LENGTH,
              },
            })}
            error
            size={'small'}
          />
        )}

        {showPreview && (
          <>
            <label
              style={{
                display: 'block',
                fontSize: '.9375em',
                fontWeight: 700,
                margin: '0 0 .25rem .625em',
              }}
            >
              {intl.formatMessage({ id: 'label.preview' })}
            </label>

            <Button
              content={buttonText}
              disabled={readOnly}
              style={{
                maxWidth: '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              type='button'
            />
          </>
        )}

        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.action' })} </label>
          <Select
            disabled={readOnly}
            fluid
            onChange={this.handleActionTypeChange}
            options={this.actionTypeOptions()}
            placeholder={intl.formatMessage({ id: 'label.choose_an_option' })}
            readOnly={readOnly}
            value={selectedActionType}
          />
        </Form.Field>

        {selectedActionType === ACTION_TYPES.INTENT && (
          <BaristaIntentLookup
            changeFormFieldValueWithAnnouncementId={
              changeFormFieldValueWithAnnouncementId
            }
            disabled={readOnly}
            input={{ value: action.intent_eid }}
            label={intl.formatMessage({ id: 'label.target_intent' })}
            onHandleChange={this.handleIntentChange}
            placeholder={intl.formatMessage({
              id: 'label.choose_target_intent',
            })}
            readOnly={readOnly}
            required
            scope={String(index)}
            value={action.intent_eid}
          />
        )}
        {selectedActionType === ACTION_TYPES.URL && (
          <Form.Field>
            <label>{intl.formatMessage({ id: 'label.URL' })}</label>
            <Input
              disabled={readOnly}
              input={{ value: actionUrl ? actionUrl : '' }}
              onChange={this.handleActionUrlChange}
              readOnly={readOnly}
              required
              type='text'
            />
          </Form.Field>
        )}
      </>
    );
  }
}
export default CustomActionButtonBuilder;
