import React from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';
import { Nodes, Regexes, VideoPlayer } from 'esp-globals';
import ChatMessageImg from './ChatMessageImg';

const propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
};
const getImageUrl = ({ text }) => {
  const textIsMarkdownFormattedImage = !isNull(
    text?.match(Regexes[Nodes.markdownImage])
  );
  let url = '';
  if (textIsMarkdownFormattedImage) {
    const urlSectionCatcher = Regexes.urlInMarkdown;
    url = text?.match(urlSectionCatcher)[0].replace(/[()]/g, '');
  }

  return {
    isMarkdown: textIsMarkdownFormattedImage,
    url,
  };
};

const ChatMessageMetadataViewer = ({ text = '', type = '' }) => {
  switch (type) {
    case Nodes.embedVideo: {
      return <VideoPlayer type={type} url={text} />;
    }
    case Nodes.nativeVideo: {
      return <VideoPlayer type={type} url={text} />;
    }
    case Nodes.imageLink: {
      const { url, isMarkdown } = getImageUrl({ text });
      // There's 2 types of imageLink -> Image link with markdown format and just a link ended with a image extension like png, jpg, gif
      const attachment = isMarkdown ? url : text;

      return (
        <ChatMessageImg
          altText={text}
          attachment={attachment}
          isFirstMessage={false}
          isLastMessage={false}
          isMe={false}
        />
      );
    }
    default:
      return '';
  }
};

ChatMessageMetadataViewer.propTypes = propTypes;

export { getImageUrl };
export default ChatMessageMetadataViewer;
