import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { curry, memoize } from 'lodash';
import { Dropdown, Icon } from 'semantic-ui-react';

class SocialMediaDropDown extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired, // From redux form Field
    noNetworkElegibility: PropTypes.bool,
    onOptionClick: PropTypes.func.isRequired,
    selectedMedia: PropTypes.shape({
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ]),
    }).isRequired,
    socialOptions: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            name: PropTypes.string,
          }),
        ]),
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
        key: PropTypes.any,
        text: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        value: PropTypes.any,
      })
    ).isRequired,
  };

  static defaultProps = {
    noNetworkElegibility: false,
  };

  handleOptionClick = memoize(
    curry((input, e, d) => {
      const { onOptionClick } = this.props;
      onOptionClick(input, e, d);
    })
  );

  render() {
    const {
      input,
      noNetworkElegibility,
      selectedMedia,
      socialOptions,
    } = this.props;

    const onlyOneOption = socialOptions.length === 0;

    return (
      <Dropdown
        {...input}
        basic
        className='label'
        disabled={noNetworkElegibility}
        icon={onlyOneOption || noNetworkElegibility ? null : void 0}
        trigger={<Icon name={selectedMedia.icon} />}
      >
        {!noNetworkElegibility ? (
          <Dropdown.Menu>
            <Dropdown.Menu scrolling>
              {socialOptions.map((option) => (
                <Dropdown.Item
                  icon={option.icon}
                  key={option.key}
                  onClick={this.handleOptionClick(input)}
                  text={option.text}
                  value={option.value}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
    );
  }
}

export default SocialMediaDropDown;
