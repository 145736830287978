import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { curry, memoize } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LaunchListPickerSearch from './LaunchListPickerSearch';

class RenderList extends PureComponent {
  static propTypes = {
    listItems: ImmutablePropTypes.list.isRequired,
    onHandleItemSelected: PropTypes.func.isRequired,
    removeSelection: PropTypes.bool.isRequired,
  };

  state = {
    itemSelected: null,
    listItemsFiltered: [],
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.listItemsFiltered.length === 0) {
      const { listItems } = nextProps;
      return {
        ...state,
        listItemsFiltered: listItems,
      };
    }
    if (nextProps.removeSelection) {
      return {
        ...state,
        itemSelected: null,
      };
    } else {
      return state;
    }
  }

  // eslint-disable-next-line react/destructuring-assignment -- risky to fix
  source = this.props.listItems
    .map((item) => ({
      description: item.get('label').split('|')[0],
      title: item.get('description'),
      value: item.get('value'),
    }))
    .toJS();

  onItemSearched = (itemSelected) => {
    const { listItems } = this.props;
    const items = listItems.filter(
      (item) =>
        item
          .get('label')
          .toLowerCase()
          .indexOf(itemSelected.toLocaleLowerCase()) !== -1
    );
    this.setState({ listItemsFiltered: items });
  };

  handleItemSelected = memoize(
    curry((item, e) => {
      this.setState({ itemSelected: item });
      const { onHandleItemSelected } = this.props;
      onHandleItemSelected(item);
    })
  );

  render() {
    const { itemSelected, listItemsFiltered } = this.state;

    return (
      <>
        <LaunchListPickerSearch
          handleItemSelected={this.onItemSearched}
          listItems={this.source}
        />
        <List divided relaxed>
          {listItemsFiltered.map((item, index) => (
            <List.Item
              active={
                itemSelected && itemSelected.get('index') === item.get('index')
              }
              as='a'
              description={item.get('description')}
              header={{
                className: 'sub-class',
                content: item.get('label'),
              }}
              icon={{
                name:
                  itemSelected &&
                  itemSelected.get('index') === item.get('index')
                    ? 'selected radio'
                    : 'radio',
                verticalAlign: 'middle',
              }}
              key={item.get('value') + index}
              onClick={this.handleItemSelected(item)}
              style={!item.get('label') ? { lineHeight: 2 } : {}}
            />
          ))}
        </List>
      </>
    );
  }
}

export default RenderList;
