import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Image } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { curry, memoize, noop } from 'lodash';

// Controller
import ProductFulfillmentQuestionsController from '../controllers/ProductFulfillmentQuestionsController';

// Globals
import FulfillmentAnswerTypes from '../../globals/FulfillmentAnswerTypes';

// Molecules
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import ValidatedDate from './ValidatedDate';

import FormInputSelect from './FormInputSelect';
import FormInputSelectIntegrationTable from './FormInputSelectIntegrationTable';

import FormInputText from './FormInputText';
import FormInputCheckbox from './FormInputCheckbox';

// Packages
import { intl } from 'esp-util-intl';

class ProductFulfillmentQuestions extends PureComponent {
  static propTypes = {
    /** if known, it will be pass down in the submit callback */
    cartItemId: PropTypes.number,
    /** Set of options available for the product */
    fulfillmentOptions: ImmutablePropTypes.list,
    /** whether or not show submit button at the bottom. Default to false. */
    hideSubmit: PropTypes.bool,
    /** whether it's the last one of multiple fulfillment questions  */
    lastOne: PropTypes.bool,
    /** Function to load fulfillment options from the APO  */
    loadFulfillmentOptionsAndAnswers: PropTypes.func,
    /** Function to reload product detail to bust the cache */
    loadProduct: PropTypes.func,
    /**
     *  Callback to be invoked every time a selection/change is made in one of the answers. Passed down a single object params containing:
     *   @value {string} value of the selection (from SUIR onChange)
     *   @choice {object} if it's multiple choice, sends the selected Full choice object,
     *   @event {object} Event object
     *   @fulfillmentOption (Immutable.Map) full Fulfillment Option object of the product
     */
    onChangeAnswer: PropTypes.func,
    // Callback for Redux form submit event. It passes down:
    //   @fieldValues {object} map with the optionId and value
    //   @productId
    //   @cartItemId (if provided in the props)
    //
    onSubmit: PropTypes.func,
    /** ESP Product object from Entitites */
    product: ImmutablePropTypes.map,
    /** Id of the product that the fulfillment questions should be displayed */
    productId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    cartItemId: null,
    fulfillmentOptions: Immutable.List(),
    hideSubmit: false,
    lastOne: false,
    loadFulfillmentOptionsAndAnswers: noop,
    loadProduct: noop,
    onChangeAnswer: noop,
    onSubmit: noop,
    product: Immutable.Map(),
  };

  componentDidMount() {
    const { loadFulfillmentOptionsAndAnswers, productId } = this.props;

    loadFulfillmentOptionsAndAnswers(productId);
  }

  handleSelection = memoize(
    curry((fulfillmentOption, event, value) => {
      const { cartItemId, onChangeAnswer } = this.props;
      let choice;
      if (fulfillmentOption.get('choices')) {
        choice = fulfillmentOption
          .get('choices')
          .find((fOption) => fOption.get('value') === value);
      }

      const passDownData = {
        cartItemId,
        choice,
        event,
        fulfillmentOption,
        value,
      };

      onChangeAnswer(passDownData);
    })
  );

  handleSubmit = (fieldValues) => {
    const { onSubmit, productId, cartItemId } = this.props;
    onSubmit(fieldValues, productId, cartItemId);
  };

  formValidation = (fieldValues) => {
    const { fulfillmentOptions } = this.props;
    const errors = {};
    fulfillmentOptions.forEach((fOption) => {
      const fOptionId = String(fOption.get('id'));
      if (!fieldValues.has(fOptionId)) {
        errors[fOptionId] = 'Required';
      }
    });

    return errors;
  };

  handleImageError = () => {
    const { productId, loadProduct } = this.props;

    loadProduct(productId);
  };

  render() {
    const { fulfillmentOptions, hideSubmit, lastOne, product } = this.props;

    if (fulfillmentOptions.isEmpty() || product.isEmpty()) {
      return null;
    }

    // setting initial values
    const initialValues = {};
    fulfillmentOptions.forEach((fOption) => {
      if (fOption.get('default_value')) {
        if (fOption.get('type') === FulfillmentAnswerTypes.CHECK_BOX) {
          // True or false value have to be parsed from their string values
          initialValues[String(fOption.get('id'))] =
            fOption.get('default_value') !== 'False';
        } else {
          initialValues[String(fOption.get('id'))] = fOption.get(
            'default_value'
          );
        }
      }
    });

    return (
      <div>
        <Header as='h4' dividing>
          {product.getIn(['images', 0, 'image_480']) ? (
            <Image
              onError={this.handleImageError}
              src={product.getIn(['images', 0, 'image_480'])}
            />
          ) : null}{' '}
          {product.get('name')}
        </Header>

        <ValidatedForm
          form={`ProductFulfillment.${product.get('id')}`}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validate={this.formValidation}
        >
          {/* NOTE: These questions are read only, so we can just show the info and that's it. */}
          {fulfillmentOptions.map((fOption, optionIndex) => {
            // First case for multiple choice is where it doesn't have table name
            // so we use the provided choices
            if (
              fOption.get('type') === FulfillmentAnswerTypes.MULTIPLE_CHOICE &&
              fOption.get('choices') &&
              !fOption.get('choices').isEmpty()
            ) {
              let options = [];

              if (fOption.get('choices')) {
                options = fOption
                  .get('choices')
                  .map((choice) => ({
                    key: choice.get('id'),
                    text: choice.get('description'),
                    value: choice.get('value'),
                  }))
                  .toJS();
              }

              return (
                <ValidatedField
                  component={FormInputSelect}
                  key={fOption.get('id')}
                  label={intl.formatMessage({
                    id: `label.${intl.prepareKey(fOption.get('question'))}`,
                  })}
                  name={String(fOption.get('id'))}
                  onChange={this.handleSelection(fOption)}
                  options={options}
                  search
                  upward={Boolean(
                    lastOne &&
                      fulfillmentOptions.size > 3 &&
                      (optionIndex + 1 === fulfillmentOptions.size ||
                        optionIndex + 2 === fulfillmentOptions.size)
                  )}
                />
              );
            }
            // Second case If we are given a table name AND column,
            // then we use the on the flight search with a field (column) name
            else if (
              fOption.get('type') === FulfillmentAnswerTypes.MULTIPLE_CHOICE &&
              fOption.get('table_name') &&
              fOption.get('column_name')
            ) {
              return (
                <ValidatedField
                  component={FormInputSelectIntegrationTable}
                  field={fOption.get('column_name')}
                  key={fOption.get('id')}
                  label={intl.formatMessage({
                    id: `label.${intl.prepareKey(fOption.get('question'))}`,
                  })}
                  name={String(fOption.get('id'))}
                  onChange={this.handleSelection(fOption)}
                  refQualCondition={fOption.get('reference_qual_condition')}
                  search
                  tableName={fOption.get('table_name')}
                />
              );
            } else if (fOption.get('type') === FulfillmentAnswerTypes.TEXT) {
              return (
                <ValidatedField
                  component={FormInputText}
                  key={fOption.get('id')}
                  label={intl.formatMessage({
                    id: `label.${intl.prepareKey(fOption.get('question'))}`,
                  })}
                  name={String(fOption.get('id'))}
                  onChange={this.handleSelection(fOption)}
                />
              );
            } else if (
              fOption.get('type') === FulfillmentAnswerTypes.CHECK_BOX
            ) {
              return (
                <ValidatedField
                  component={FormInputCheckbox}
                  key={fOption.get('id')}
                  label={intl.formatMessage({
                    id: `label.${intl.prepareKey(fOption.get('question'))}`,
                  })}
                  name={String(fOption.get('id'))}
                  onChange={this.handleSelection(fOption)}
                />
              );
            } else if (fOption.get('type') === FulfillmentAnswerTypes.DATE) {
              return (
                <ValidatedDate
                  key={fOption.get('id')}
                  label={intl.formatMessage({
                    id: `label.${intl.prepareKey(fOption.get('question'))}`,
                  })}
                  name={String(fOption.get('id'))}
                  onChange={this.handleSelection(fOption)}
                />
              );
            } else if (
              fOption.get('type') === FulfillmentAnswerTypes.REFERENCE
            ) {
              return (
                <ValidatedField
                  component={FormInputSelectIntegrationTable}
                  key={fOption.get('id')}
                  label={intl.formatMessage({
                    id: `label.${intl.prepareKey(fOption.get('question'))}`,
                  })}
                  name={String(fOption.get('id'))}
                  onChange={this.handleSelection(fOption)}
                  refQualCondition={fOption.get('reference_qual_condition')}
                  search
                  tableName={fOption.get('table_name')}
                />
              );
            }
            // Leave this just in case we don't know what to do
            // eslint-disable-next-line no-console -- debugging
            console.warn('We didnt render an option:', fOption.get('url'));
            // If anything else that we don't support...
            return null;
          })}

          {!hideSubmit ? (
            <Button content={'Submit'} primary type='submit' />
          ) : null}
        </ValidatedForm>
      </div>
    );
  }
}

const ProductFulfillmentQuestionsTest = ProductFulfillmentQuestions;

// eslint-disable-next-line no-class-assign -- DEV-1526
ProductFulfillmentQuestions = ProductFulfillmentQuestionsController(
  ProductFulfillmentQuestions
);
export { ProductFulfillmentQuestionsTest };
export default ProductFulfillmentQuestions;
