const ChatInputTypes = {
  BUTTON: 'BUTTON',
  DATE: 'date',
  FEEDBACK: 'FEEDBACK',
  INPUT_DATE: 'INPUT_DATE',
  INPUT_TIME: 'INPUT_TIME',
  RADIO: 'RADIO',
  SYS_ISO_DATE: 'Sys.ISODATE',
  SYS_ISO_TIME: 'Sys.ISOTime',
  TIME: 'time',
};

export default ChatInputTypes;
