import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsWifi extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g className='brand' fill={color} transform='translate(4 4)'>
          <path d='M30.06 48.31c-.417.58-.586 1.3-.47 2.004.117.704.51 1.332 1.09 1.746.367.26.794.42 1.24.47l8.5-10.79c-4.095.96-7.746 3.275-10.36 6.57zm14.86-26.39c3.358 0 6.695.525 9.89 1.56L58.36 19c-4.364-1.635-8.99-2.46-13.65-2.43-21.17 0-33.6 15.29-34.12 15.94-.928 1.157-.742 2.847.415 3.775 1.157.928 2.847.742 3.775-.415.11-.14 11.43-13.92 30-13.92l.14-.03z' />
          <path d='M44.64 28.91c-15.64 0-23 10.78-23.29 11.23-.536.798-.605 1.822-.182 2.686.424.863 1.276 1.435 2.235 1.5.96.064 1.88-.388 2.417-1.186.06-.09 6-8.84 18.87-8.84h.11c.487-.008.975.012 1.46.06l3.8-4.83c-1.777-.415-3.595-.623-5.42-.62zm12.42 3.03l-3.41 4.33c4.04 1.783 7.61 4.48 10.43 7.88.958 1.132 2.653 1.273 3.785.315 1.132-.958 1.273-2.653.315-3.785-3.053-3.66-6.842-6.638-11.12-8.74zm20.29.31c-3.51-4.123-7.72-7.594-12.44-10.25l-3.38 4.29c4.478 2.388 8.465 5.6 11.75 9.47.966 1.124 2.66 1.25 3.785.285 1.124-.966 1.25-2.66.285-3.785v-.01zM49.15 42l-3.72 4.7c3.588.314 6.868 2.157 9 5.06.552.784 1.48 1.214 2.436 1.128.955-.085 1.79-.674 2.195-1.545.406-.87.314-1.89-.24-2.673-2.342-3.286-5.764-5.646-9.67-6.67z' />
          <circle cx='44.34' cy='59.83' r='6.13' />
          <path d='M44 0C19.7 0 0 19.7 0 44s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C66.86 4.637 55.67 0 44 0zM5 44c.013-14.535 8.107-27.857 21.002-34.565 12.894-6.708 28.45-5.688 40.358 2.645L18.18 73.2C9.798 65.81 4.997 55.174 5 44zm39 39c-8.487.008-16.742-2.767-23.5-7.9l48.25-61.22c12.713 10.457 17.492 27.77 11.942 43.267C75.142 72.645 60.462 82.99 44 83z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsWifi;
