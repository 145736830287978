import APIcall from '../../../../../../utils/APIcall';
import { APPLICATION_VALUE } from '../utils';
import endpointGenerator from '../../../../../../utils/endpointGenerator';
import EspFilters from 'esp-util-filters';

const getTranslations = async () => {
  const espFilters = new EspFilters();

  espFilters.contains('application', APPLICATION_VALUE);

  return APIcall.get({
    query: {
      esp_filters: espFilters.asQueryString(),
    },
    token: true,
    url: endpointGenerator.genPath('commons.localization'),
  });
};

export default getTranslations;
