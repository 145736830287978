const api_espuser_v0_1_users_8_user_settings = {
  user_settings: {
    homeFeed_filters: {
      sort_by: {
        filters: [
          {
            key: 'sort',
            options: [
              {
                checked: false,
                text: 'filter.smart_sort',
                value: 'smart',
              },
              {
                checked: true,
                text: 'filter.created_new_old',
                value: '-sys_date_created',
              },
              {
                checked: false,
                text: 'filter.created_old_new',
                value: 'sys_date_created',
              },
              {
                checked: false,
                text: 'filter.updated_new_old',
                value: '-sys_date_updated',
              },
              {
                checked: false,
                text: 'filter.updated_old_new',
                value: 'sys_date_updated',
              },
            ],
            type: 'radio',
          },
        ],
        title: 'label.sort_by',
      },
    },
    interactions_selected_view_setting: 'Top Matched',
    interactions_truncate_column_number: null,
    kb_selected_view_setting: 'Test',
  },
};

export default api_espuser_v0_1_users_8_user_settings;
