import bundleThunks from '../../actions/bundleThunks';
import getChildBundleProducts from '../../selectors/getChildBundleProducts';
import getSoftwareBundle from '../../selectors/getSoftwareBundle';
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => ({
  products: getChildBundleProducts(state),
  softwareBundle: getSoftwareBundle(state),
});

const mapDispatchToProps = (dispatch) => ({
  getChildBundleProductsSoftware: () =>
    dispatch(bundleThunks.getChildBundleProducts('software')),
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
