import actionTypes from './actionTypes';

const productImagesActions = {};

productImagesActions.addProductImage = (bundleItemID, productImage) => ({
  bundleItemID,
  productImage,
  type: actionTypes.ADD_PRODUCT_IMAGE_ENTITY,
});

export default productImagesActions;
