import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOtherHrForms extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd' transform='translate(19.5 13.5)'>
          <circle
            cx='29.27'
            cy='9.65'
            fill='#FFF'
            fillRule='nonzero'
            r='1.52'
          />
          <path
            d='M26.85 27.73h15.98M26.87 38.37h15.98M26.87 49.64h15.98'
            stroke='#F00'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            d='M52.55 6.51h-14.7C36.26 3.14 32.867.988 29.14.988c-3.727 0-7.12 2.15-8.71 5.522H5.13C2.844 6.51.99 8.364.99 10.65v54c.022 2.27 1.87 4.1 4.14 4.1h47.42c2.286 0 4.14-1.854 4.14-4.14v-54c-.022-2.27-1.87-4.1-4.14-4.1zM38.69 9.6v-.19c0-.06-.01.11 0 .18v.01zm-.18-1.06v-.12.12zm-9.41.6c.84 0 1.52.68 1.52 1.52s-.68 1.52-1.52 1.52-1.52-.68-1.52-1.52c-.003-.405.156-.794.442-1.08.285-.288.673-.45 1.078-.45v.01zm-9.38-.68v.12-.12zm-.18 1v.19c0 .06-.01-.19 0-.25v.06zm28.87 51.01H9.27V14.75H19.6c-.663.593-1.045 1.44-1.05 2.33-.005.878.34 1.72.96 2.343.62.62 1.462.97 2.34.967h14.46c1.81-.005 3.275-1.47 3.28-3.28.002-.902-.384-1.762-1.06-2.36h9.88v45.72z'
            fill='#F00'
            fillRule='nonzero'
          />
          <path
            d='M20.85 25.38l-4.37 4.37-2.63-2.62m7 19.25l-4.37 4.37-2.63-2.62m7-12.38l-4.37 4.38-2.63-2.63'
            stroke='#F00'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </svg>
    );
  }
}

export default CatalogOtherHrForms;
