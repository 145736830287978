import immutable from 'immutable';
import { createSelector } from 'reselect';

const getTenantConfigKeys = (state) =>
  state.getIn(['tenant', 'entity', 'value', 'ui_config', 'newFeature']);

const getTenantMenuConfig = (state) =>
  state.getIn(['tenant', 'entity', 'value', 'ui_config', 'displayMenu']);

const getTenantMenuConfiguration = (tenantConfigKeys, tenantMenu) => {
  const featureMenu =
    !tenantConfigKeys || tenantConfigKeys.isEmpty()
      ? immutable.Map()
      : tenantConfigKeys;

  const hideMenu =
    !tenantMenu || tenantMenu.isEmpty() ? immutable.Map() : tenantMenu;

  return featureMenu.merge(hideMenu);
};

const selector = createSelector(
  [getTenantConfigKeys, getTenantMenuConfig],
  getTenantMenuConfiguration
);

export default selector;
