import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form/immutable';
import {
  Accordion,
  Button,
  Grid,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { curry, includes, memoize, remove } from 'lodash';
import Immutable from 'immutable';

import ObjectOfInstances from './ObjectOfInstances';
// Molecule
import FormInputText from '../../../../../../app/js/v1/components/molecules/FormInputText';

const propTypes = {
  fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const defaultProps = {
  fields: null,
};

class BlockArrayOfConditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: [],
    };
  }

  handleToggleAccordion = (e, { index }) => {
    const { activeIndex } = this.state;
    const activeIndexCopy = [...activeIndex];
    if (includes(activeIndexCopy, index)) {
      remove(activeIndex, (i) => i === index);
    } else {
      activeIndexCopy.push(index);
    }
    this.setState(() => ({
      activeIndex: activeIndexCopy,
    }));
  };

  handleAddOption = memoize(
    curry((fields, e) => {
      fields.push({});
      this.handleToggleAccordion(null, {
        index: fields.length,
      }); // Open the created condition accordion
    })
  );

  handleRemoveOption = memoize(
    curry((fields, index, e) => {
      fields.remove(index);
      const { activeIndex } = this.state;
      const activeIndexCopy = [...activeIndex].filter((ind) => ind !== index);
      this.setState({
        activeIndex: activeIndexCopy,
      }); // Close the removed condition accordion
    })
  );

  handleBlur(e) {
    e.preventDefault();
  }

  render() {
    const { fields } = this.props;
    const { activeIndex } = this.state;

    return (
      <>
        <Grid>
          <Grid.Column>
            <Button.Group floated='right' size='tiny' vertical>
              <Button
                content={'Add Conditions'}
                onClick={this.handleAddOption(fields)}
                size='tiny'
              />
            </Button.Group>
          </Grid.Column>
        </Grid>
        {fields.map((optionFieldName, index, field) => {
          const description =
            field.get(index) instanceof Immutable.Map
              ? field.get(index).get('description')
              : 'Empty Description';

          return (
            <Accordion key={optionFieldName}>
              <Accordion.Title
                active={includes(activeIndex, index)}
                index={index}
                onClick={this.handleToggleAccordion}
              >
                <Header
                  as='h4'
                  content={`Condition [${index}]`}
                  dividing
                  icon='dropdown'
                  subheader={description}
                />
              </Accordion.Title>

              <Accordion.Content active={includes(activeIndex, index)}>
                <Segment className='editorConditions'>
                  <Grid>
                    <Grid.Column width={15}>
                      <Header as='h5' dividing>
                        {'Condition Set'}
                      </Header>

                      <Field
                        component={FormInputText}
                        fluid
                        label='Description'
                        name={`${optionFieldName}.description`}
                      />

                      <Field
                        component={FormInputText}
                        fluid
                        label='Conditions'
                        name={`${optionFieldName}.conditions`}
                      />

                      <Field
                        component={FormInputText}
                        fluid
                        label='Success Workflow Task'
                        name={`${optionFieldName}.success_workflow_task`}
                      />

                      <Field
                        component={FormInputText}
                        fluid
                        label='Success Workflow'
                        name={`${optionFieldName}.success_workflow`}
                      />

                      <Field
                        component={FormInputText}
                        fluid
                        label='Ordering'
                        name={`${optionFieldName}.ordering`}
                      />

                      <Header as='h5' dividing>
                        {'Object of Instances'}
                      </Header>

                      <FieldArray
                        component={ObjectOfInstances}
                        name={`${optionFieldName}.object_instances`}
                      />
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <Button.Group floated='right' size='tiny' vertical>
                        <Button
                          icon={<Icon name='trash' />}
                          onClick={this.handleRemoveOption(fields, index)}
                        />
                      </Button.Group>
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Accordion.Content>
            </Accordion>
          );
        })}
      </>
    );
  }
}

BlockArrayOfConditions.propTypes = propTypes;

BlockArrayOfConditions.defaultProps = defaultProps;

export default BlockArrayOfConditions;
