import APIcall from '../utils/APIcall';
import store from '../stores/store';
import toastNotificationsActions from './toastNotificationsActions';

let updateAttempt = 0;

const getRemoteVersion = (r, version) => {
  const clientID = atob(version.get('clientId'));

  const [currentBranch, currentBuild] = clientID.split('/');

  const branchTag = r.text.match(/<meta name='branch' content='.*' \/>/i);
  const buildTag = r.text.match(/<meta name='build' content='.*' \/>/i);
  const branchMatch = branchTag[0] ? branchTag[0].match(/content='.*'/i) : '';
  const buildMatch = branchTag[0] ? buildTag[0].match(/content='.*'/i) : '';
  const newBuild = buildMatch[0]
    .split('=')[1]
    .replace(/'/, '')
    .replace(/'/, '');
  const newBranch = branchMatch[0]
    .split('=')[1]
    .replace(/'/, '')
    .replace(/'/, '')
    .replace('release_', '');

  return {
    currentBranch,
    currentBuild,
    newBranch,
    newBuild,
  };
};

const updaterThunks = {
  downloadUpstreamIndex: () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      APIcall.get({
        error: (err) => reject(err),
        // we call it like this because we want the index.html
        success: (r) => {
          const version = getState().get('appVersion');
          const versions = getRemoteVersion(r, version);

          if (
            versions.currentBranch !== versions.newBranch ||
            versions.currentBuild !== versions.newBuild
          ) {
            store.dispatch(
              toastNotificationsActions.success({
                clickHandler: () => window.location.reload(),
                dismissAfter: 30000,
                message:
                  'restart will happen in 30 seconds or click to reload manually',
                title: 'Application has been updated',
              })
            );
            setTimeout(() => window.location.reload(), 30000);
          } else {
            if (updateAttempt < 90) {
              setTimeout(
                () => dispatch(updaterThunks.downloadUpstreamIndex()),
                60000
              ); // it will call itself after 1 minute
              updateAttempt++;
            }
          }
          resolve(versions);
        },
        url: '/index.html',
      });
    }),
};

export { getRemoteVersion };

export default updaterThunks;
