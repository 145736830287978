const addDataPercentage = (data, total, proportion) =>
  data.map((entry) => {
    let addedTotal = 0;

    if (Array.isArray(total)) {
      total.forEach((key) => (addedTotal = entry[key] + addedTotal));
    } else {
      addedTotal = entry[total];
    }

    const percent = !addedTotal
      ? 0
      : Math.round(((entry[proportion] * 100) / addedTotal) * 10) / 10 || 0;

    return {
      ...entry,
      percent,
    };
  });

export default addDataPercentage;
