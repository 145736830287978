import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsAlert extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M48 9c21.54 0 39 17.46 39 39S69.54 87 48 87 9 69.54 9 48 26.46 9 48 9zm0-5C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4z' />
          <path d='M76.25 57L56.31 22c-1.63-2.828-4.646-4.57-7.91-4.57-3.264 0-6.28 1.742-7.91 4.57L20.63 57c-1.587 2.815-1.563 6.26.065 9.053 1.627 2.792 4.613 4.51 7.845 4.517h39.8c3.243-.002 6.234-1.747 7.83-4.57 1.594-2.78 1.625-6.19.08-9zm-28.08 5c-1.79 0-3.24-1.45-3.24-3.24 0-1.79 1.45-3.24 3.24-3.24 1.79 0 3.24 1.45 3.24 3.24-.038 1.762-1.478 3.17-3.24 3.17V62zM52 32.12l-3.76 19.26-3.72-19.27c-.378-1.544.237-3.162 1.546-4.064 1.31-.903 3.04-.903 4.348 0 1.31.902 1.924 2.52 1.546 4.064l.04.01z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsAlert;
