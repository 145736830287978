import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Label } from 'semantic-ui-react';
import _ from 'lodash';
import { intl } from 'esp-util-intl';

// Molecules
import FormInputText from '../../molecules/FormInputText';
// Globals
import TypeField, { TypeDefaults } from '../../../globals/blocks/TypeField';

class CopyField01 extends Component {
  static propTypes = TypeField;
  static defaultProps = TypeDefaults;

  state = {
    copied: false,
    value: '',
  };

  handleInputLoaded = (input) => {
    this.setState({
      value: input.value,
    });
  };

  handleCopy = () => {
    this.setState({
      copied: true,
    });
    _.delay(() => {
      this.setState({
        copied: false,
      });
    }, 3000);
  };

  handleClick = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      id,
      inputType,
      inputValue,
      labelText,
      placeholder,
      template,
      type,
    } = this.props;
    const { copied, value } = this.state;
    const linkAsAButton = () => (
      <a onClick={this.handleClick}>
        <Label pointing='above'>
          {intl.formatMessage({
            id: 'message.click_to_copy',
          })}
        </Label>
      </a>
    );
    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          autoComplete='off'
          autoCorrect='off'
          component={FormInputText}
          id={id}
          inputValue={inputValue}
          label={labelText}
          name={id.toString()}
          onInputLoaded={this.handleInputLoaded}
          placeholder={placeholder}
          readOnly
          transparent
          type={inputType}
        />

        {copied && (
          <Label color='green' pointing='above'>
            {intl.formatMessage({
              id: 'message.copied_to_your_clipboard',
            })}
          </Label>
        )}

        {!copied && (
          <CopyToClipboard onCopy={this.handleCopy} text={value}>
            {linkAsAButton()}
          </CopyToClipboard>
        )}
      </div>
    );
  }
}

export default CopyField01;
