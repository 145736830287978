import buildCompositeController from '../../utils/buildCompositeController';
import cartActions from '../../actions/cartActions';
import csvCasesReportThunks from '../../actions/csvCasesReportThunks';
import getNumberItemsInCart from '../../selectors/getNumberItemsInCart';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const TITLE = {
  ERROR: 'Error',
  SUCCESS: 'Success',
};

const mapStateToProps = (state) => {
  const cartNumItems = getNumberItemsInCart(state);
  const shopLabelFromBackend = state.getIn([
    'appUI',
    'menuCustomization',
    'shopLabel',
  ]);

  return {
    cartNumItems: cartNumItems,
    isLoadingReport: state.getIn(['csvCasesReport', 'isLoading']),
    jobID: state.getIn(['csvCasesReport', 'jobID']),
    shopCartOpened: state.getIn(['catalog', 'shopCartOpened']),
    shopLabelFromBackend,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelReport(jobID) {
    dispatch(csvCasesReportThunks.cancelCsvCasesReport(jobID))
      .then((response) => {
        dispatch(
          toastNotificationsActions.success({
            message: response.msg,
            title: TITLE.SUCCESS,
          })
        );
      })
      .catch((error) => {
        dispatch(
          toastNotificationsActions.error({
            message: error.message,
            title: TITLE.ERROR,
          })
        );
      });
  },
  handleCloseCart: () => {
    dispatch(cartActions.cartCloseModal());
  },
  handleOpenCart: () => {
    dispatch(cartActions.cartOpenModal());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
