import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  apiMetaResults: null,
  defaultMessage: '',
  directChannelState: {},
  errorMessage: '',
  isLoadingAttachment: false,
  isLoadingOptions: false,
  loadedImages: {},
  messages: {},
  pagination: {},
  scopedChannelState: {},
  userTyping: {},
});

/**
 * Chat Reducer which fire action thought the dispatch switch
 * @param state
 * @param action
 * @returns {*}
 */
const chatReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CHAT_LOAD_ATTACHMENT_START: {
      return state.set('isLoadingAttachment', true);
    }

    case actionTypes.CHAT_LOAD_ATTACHMENT_SUCCESS: {
      return state.set('isLoadingAttachment', false);
    }

    case actionTypes.CHAT_LOAD_ATTACHMENT_FAIL: {
      return state.set('isLoadingAttachment', false);
    }

    case actionTypes.CHAT_MESSAGE_RECEIVED: {
      const messageChannelId = action.message && action.message.channel;

      // always reset the api results
      const newState = state.setIn(['apiMetaResults'], null);

      if (messageChannelId) {
        const messages = state.getIn(['messages', messageChannelId]);
        if (!messages || messages.isEmpty()) {
          return newState.setIn(
            ['messages', messageChannelId],
            fromJS([action.message])
          );
        }

        // Only push the message if it's not there
        const indexOfMessage = messages.findIndex(
          (msg) => msg.get('id') === action.message.id
        );

        if (indexOfMessage < 0) {
          // message doesn't exist (index is -1)
          return newState.setIn(
            ['messages', messageChannelId],
            newState
              .getIn(['messages', messageChannelId])
              .push(fromJS(action.message))
              .sortBy((message) => message.get('sys_date_created'))
          );
        } else {
          // exists, so update it
          return newState.setIn(
            ['messages', messageChannelId, indexOfMessage],
            fromJS(action.message)
          );
        }
      }

      return newState;
    }

    case actionTypes.LOAD_MESSAGES: {
      // const existingMessages = state.getIn(['messages', action.channelId]);
      // const isLoadedChannel = existingMessages && !existingMessages.isEmpty();

      return state;
    }

    case actionTypes.CHAT_LOAD_ONE_MESSAGE_SUCCESS:
    case actionTypes.LOAD_MESSAGES_SUCCESS: {
      const newMessages = fromJS(action.messages.reverse());
      const existingMessages = state.getIn(['messages', action.channelId]);
      let updatedMessages = existingMessages
        ? newMessages.concat(existingMessages)
        : newMessages;

      // Smart way to get rid of duplicates, in case this messages are being re-hidratated
      updatedMessages = updatedMessages
        .groupBy((m) => m.get('id'))
        .map((x) => x.first())
        .sortBy((message) => message.get('sys_date_created'))
        .toList();

      return state
        .setIn(['messages', action.channelId], updatedMessages)
        .setIn(['pagination', action.channelId], fromJS(action.pagination));
    }

    case actionTypes.UPDATE_WIDGET_SUCCESS: {
      const { channelId, messageId, widgetResponse } = action;
      // find the message and update its widget
      return state.updateIn(['messages', channelId], (messages) => {
        const indexOfMessage = messages.findIndex(
          (m) => m.get('id') === messageId
        );
        if (indexOfMessage > -1) {
          // if found
          const newMessages = messages.setIn(
            [indexOfMessage, 'metadata', 'widget'],
            fromJS(widgetResponse)
          );
          return newMessages;
        } else {
          return messages;
        }
      });
    }

    case actionTypes.LOAD_CHANNEL_START: {
      const id = action.channelID;
      if (!id) {
        return state;
      }
      return state.setIn(['scopedChannelState', id, 'isLoadingChannel'], true);
    }

    case actionTypes.LOAD_CHANNEL_SUCCESS: {
      const id = action.channelID;
      if (!id) {
        return state;
      }
      return state
        .setIn(['scopedChannelState', id, 'isLoadedChannel'], true)
        .setIn(['scopedChannelState', id, 'isLoadingChannel'], false)
        .setIn(['scopedChannelState', id, 'errorMsg'], null);
    }

    case actionTypes.LOAD_CHANNEL_FAIL: {
      const id = action.channelID;
      if (!id) {
        return state;
      }
      return state
        .setIn(['scopedChannelState', id, 'isLoadedChannel'], false)
        .setIn(['scopedChannelState', id, 'isLoadingChannel'], false)
        .setIn(['scopedChannelState', id, 'errorMsg'], action.errorMsg);
    }

    case actionTypes.REGISTER_USER_TYPING: {
      const channelId = action.message.channel_id;
      const { userId } = action.message;
      return state.updateIn(
        ['userTyping', channelId],
        (original = fromJS([])) => {
          const indexOfUser = original.findIndex((i) => i === userId);
          if (indexOfUser > -1) {
            // if found
            return original; // just return the original
          } else {
            // we push the user id that is typing
            return original.push(userId);
          }
        }
      );
    }

    case actionTypes.DEREGISTER_USER_TYPING: {
      const channelId = action.message.channel_id;
      const { userId } = action.message;

      return state.updateIn(
        ['userTyping', channelId],
        (original = fromJS([])) => {
          const indexOfUser = original.findIndex((i) => i === userId);
          if (indexOfUser > -1) {
            // if found
            return original.remove(indexOfUser);
          } else {
            return original;
          }
        }
      );
    }

    case actionTypes.SELECT_SUPPORT_CHANNEL: {
      return state;
    }

    case actionTypes.GET_DIRECT_CHANNEL_FOR_USER_START: {
      const id = action.userID;
      if (!id) {
        return state;
      }
      return state.setIn(['directChannelState', id, 'isLoadingChannel'], true);
    }

    case actionTypes.GET_DIRECT_CHANNEL_FOR_USER_SUCCESS: {
      const id = action.userID;
      if (!id) {
        return state;
      }
      return state
        .setIn(['directChannelState', id, 'isLoadedChannel'], true)
        .setIn(['directChannelState', id, 'isLoadingChannel'], false)
        .setIn(['directChannelState', id, 'errorMsg'], null);
    }

    case actionTypes.GET_DIRECT_CHANNEL_FOR_USER_FAIL: {
      const id = action.userID;
      if (!id) {
        return state;
      }
      return state
        .setIn(['directChannelState', id, 'isLoadedChannel'], false)
        .setIn(['directChannelState', id, 'isLoadingChannel'], false)
        .setIn(['directChannelState', id, 'errorMsg'], action.errorMsg);
    }

    default: {
      return state;
    }

    case actionTypes.META_RESULTS_START: {
      return state.set('isLoadingOptions', true);
    }

    case actionTypes.META_RESULTS_ADD: {
      return state
        .set('apiMetaResults', fromJS(action.results))
        .set('isLoadingOptions', false);
    }

    case actionTypes.META_RESULTS_CLEAR: {
      return state.set('apiMetaResults', null).set('isLoadingOptions', false);
    }

    case actionTypes.CHAT_ADD_DEFAULT_MESSAGE: {
      return state.set('defaultMessage', action.message);
    }

    case actionTypes.WS_SEND: {
      return state.set('defaultMessage', '');
    }

    case actionTypes.DELETE_CHAT_MESSAGE: {
      const { channelId, messageEid } = action;
      // find the message and update its widget
      return state.updateIn(['messages', channelId], (messages) => {
        if (messages) {
          const indexOfMessage = messages.findIndex(
            (m) => m.get('id') === messageEid
          );
          if (indexOfMessage > -1) {
            // if found, remove it
            return messages.delete(indexOfMessage);
          } else {
            // do nothing
            return messages;
          }
        }

        return messages;
      });
    }

    case actionTypes.DELETE_CHAT_CHANNEL: {
      return state.deleteIn(['messages', action.channelId]);
    }

    case actionTypes.CHAT_IMAGE_LOADED: {
      return state.setIn(['loadedImages', action.imgSrc], true);
    }
  }
};

export default chatReducer;
