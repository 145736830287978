import async from 'async';
import EspFilters from 'esp-util-filters';
import APIcall from '../../../../../../../v1/utils/APIcall';
import endpointGenerator from '../../../../../../../v1/utils/endpointGenerator';

const loadPerformanceList = ({
  limit = 24,
  offset,
  filterBy,
  searchTerm,
  orderBy,
}) => {
  return new Promise((resolve, reject) => {
    const getProgressSummary = (next) => {
      const esp_filters = new EspFilters()
        .equalTo('type', filterBy)
        .differentTo('status', 'ARCHIVED');

      // We are performing a search
      if (searchTerm) {
        // Esp filter - search for nickname or last name
        // Todo - Add support for esp_filter &ORoverall_state will it will be support on the API
        // esp_filters += `&user.nickname__IC=${searchTerm}&ORuser.last_name__IC=${searchTerm}`;
        esp_filters
          .contains('user.nickname', searchTerm)
          .or()
          .contains('user.last_name', searchTerm);
      }

      const query = {
        esp_filters: esp_filters.asQueryString(),
        limit,
        offset,
        order_by: orderBy,
      };

      APIcall.get({
        error(err) {
          next(err);
        },
        query,
        success(res) {
          const performanceList = res.body;

          next(null, performanceList);
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.progressSummary'),
      });
    };

    const getUsers = (progressSummaryList, next) => {
      const performanceList = progressSummaryList?.results || [];
      const ids = performanceList.map((item) => item.user);
      const esp_filters = new EspFilters();
      const query = {
        esp_filters: esp_filters.in('id', ids).asQueryString(),
      };
      const url = endpointGenerator.genPath('espUser.users');
      APIcall.get({
        error(err) {
          next(err);
        },
        query,
        success(res) {
          const users = res.body.results;
          // combinePerformanceListAndUser(performanceList, users);
          const newList = progressSummaryList.results
            .map((perf) => {
              const findUser = users.find((user) => user.id === perf.user);
              if (findUser) {
                perf.user = findUser;
                return perf;
              }
              return null;
            })
            .filter((item) => Boolean(item));
          progressSummaryList.results = newList;
          next(null, progressSummaryList);
        },
        token: true,
        url,
      });
    };

    const finallyHook = (err, performanceList) => {
      if (err) {
        reject(err);
      } else {
        resolve(performanceList);
      }
    };
    async.waterfall([getProgressSummary, getUsers], finallyHook);
  });
};

const archivePerformanceSummary = ({ progressSummaryID }) => {
  return APIcall.post({
    data: {
      status: 'ARCHIVED',
    },
    token: true,
    url: endpointGenerator.genPath(
      'espTodo.progressSummary.instance.changeStatus',
      {
        progressSummaryID,
      }
    ),
  });
};

const loadPerformanceDetail = (userID, type) => {
  return new Promise((resolve, reject) => {
    if (!type) {
      reject('error - No type has been passed');
    }

    const getProgress = (next) => {
      const esp_filters = new EspFilters()
        .equalTo('user', userID)
        .equalTo('todo.activity_recipient', type)
        .asQueryString();
      const query = {
        esp_filters,
        limit: 500,
      };
      APIcall.get({
        error(err) {
          next(err);
        },
        query,
        success({ body: { results } }) {
          next(null, results);
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.progress'),
      });
    };

    const getTemplates = (progress, next) => {
      const listId = progress.map((perf) => perf.todo);
      const esp_filters = new EspFilters().in('id', listId).asQueryString();
      const query = {
        esp_filters,
      };
      // query.esp_filters = `id__IN=${listId.join(',')}`;
      APIcall.get({
        error(err) {
          next(err);
        },
        query,
        success({ body: { results } }) {
          next(null, progress, results);
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.template'),
      });
    };
    const getProgressSummary = (progress, templates, next) => {
      const esp_filters = new EspFilters()
        .equalTo('user', userID)
        .equalTo('type', type)
        .asQueryString();
      const query = {
        esp_filters,
      };
      // query.esp_filters = `user__EQ=${userID}&type__EQ=${type}`;

      APIcall.get({
        error(err) {
          next(err);
        },
        query,
        success(res) {
          const performanceList = res.body.results;
          if (performanceList.length > 0 && performanceList[0]) {
            next(null, progress, templates, performanceList[0]);
          } else {
            next(null, progress, templates, null);
          }
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.progressSummary'),
      });
    };

    const getUser = (next) => {
      APIcall.get({
        error(err) {
          next(err);
        },
        success(res) {
          const user = res.body;
          next(null, user);
        },
        token: true,
        url: endpointGenerator.genPath('espUser.users.instance', {
          userID,
        }),
      });
    };

    const getJobRole = (user, next) => {
      const { job_role } = user;
      if (job_role) {
        APIcall.get({
          error(err) {
            next(err);
          },
          success(res) {
            const jobRole = res.body;
            next(null, user, jobRole);
          },
          token: true,
          url: user.job_role,
        });
      } else {
        next(null, user, null);
      }
    };

    const getManagerInfo = (user, jobRole, next) => {
      if (!user.manager) {
        next(null, user, jobRole, null);
      } else {
        let managerId = user.manager.split('/');
        managerId = Number(managerId[managerId.length - 2]);
        APIcall.get({
          error(err) {
            next(err);
          },
          success(res) {
            const manager = res.body;
            next(null, user, jobRole, manager);
          },
          token: true,
          url: endpointGenerator.genPath('espUser.users.instance', {
            userID: managerId,
          }),
        });
      }
    };

    async.parallel(
      {
        progressResult: (cb) =>
          async.waterfall(
            [getProgress, getTemplates, getProgressSummary],
            (err, progress, templates, performanceList) =>
              cb(err, progress, templates, performanceList)
          ),
        usersResult: (cb) =>
          async.waterfall(
            [getUser, getJobRole, getManagerInfo],
            (err, user, jobRole, manager) => cb(err, user, jobRole, manager)
          ),
      },
      (err, results) => {
        if (err) {
          reject(err);
        } else {
          const { progressResult, usersResult } = results;
          const [progress, progressSummary, templates] = progressResult;
          const [user, jobRole, manager] = usersResult;
          resolve({
            jobRole,
            manager,
            progress,
            progressSummary,
            templates,
            user,
          });
        }
      }
    );
  });
};

const escalateProgressByUser = (userID) =>
  APIcall.post({
    data: {
      user_id: userID,
    },
    token: true,
    url: endpointGenerator.genPath('espTodo.progress.escalate'),
  });

export default loadPerformanceList;
export {
  archivePerformanceSummary,
  escalateProgressByUser,
  loadPerformanceDetail,
};
