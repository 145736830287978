import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
// import {stripUnit}           from 'polished';

import ChatFormSelectOption from './ChatFormSelectOption';

const rule = (props) => {
  const { open, theme } = props;

  return {
    backgroundColor: theme.colors.gray,
    bottom: '100%',
    margin: 0,
    maxHeight: '240px',
    minHeight: theme.formHeight,
    opacity: open ? 1 : 0,
    overflowY: 'scroll',
    paddingLeft: 0,
    position: 'absolute',
    transform: open ? 'scaleY(1)' : 'scaleY(0)',
    transformOrigin: 'bottom',
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'transform opacity',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: '100%',
  };
};

class ChatFormSelect extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    onOptionChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onOptionClick: PropTypes.func.isRequired,
        secondarylabel: PropTypes.string,
      })
    ).isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: null,
  };

  getSelectedItem = () => {
    const { options, value } = this.props;

    // If no option is selected we will show the first option in the list as being selected.
    return value ? value : options[0].id;
  };

  renderItems = (options) => {
    const { onOptionChange } = this.props;

    if (options.length > 0) {
      return options.map((option) => {
        const { id, key, label, onOptionClick, secondarylabel } = option;

        const isSelected = option.id === this.getSelectedItem();

        return (
          <ChatFormSelectOption
            id={id}
            key={key}
            label={label}
            onOptionChange={onOptionChange}
            onOptionClick={onOptionClick}
            secondarylabel={secondarylabel}
            selected={isSelected}
          />
        );
      });
    } else {
      return (
        <ChatFormSelectOption disabled>
          <em>{'No results found'}</em>
        </ChatFormSelectOption>
      );
    }
  };

  render() {
    const { className, options, value } = this.props;
    return (
      <ul
        aria-activedescendant={value}
        className={className}
        data-component='ChatFormSelect'
        role='listbox'
        tabIndex='0'
      >
        {this.renderItems(options)}
      </ul>
    );
  }
}

export default createComponent(rule, ChatFormSelect, [
  'onOptionChange',
  'open',
  'options',
  'value',
]);
