import Immutable from 'immutable';
import _ from 'lodash';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
  /**
   * 0-based index, first step is 0 and last one is steps.values.size - 1.
   * @type {number}
   */
  currentStep: null,

  /**
   * Each item in this List is an Immutable.Map as follows:
   *
   * All keys and values for this should be Strings, even if they represent numbers.
   *
   * fulfillmentAnswers: [
   *   // fulfillment answers for step 0
   *   {
   *     String(productID): {
   *       String(fulfillmentOptionID): String(answer),
   *     },
   *   },
   *   ...
   * ]
   */
  fulfillmentAnswers: [],

  /**
   * Each item in this List is an Immutable.Set of Numbers, keeping the selected products
   * per step.
   *
   * List : [
   *   Set: {1, 2}, // Set of selected products from step 0
   *   Set: {7}, // Set of selected products from step 1
   *   ...
   * ]
   */
  selectedProductIDs: [],

  steps: {
    failed: false,

    isLoading: false,

    values: null,
  },

  /**
   * Each item in this List is an Immutable.Map as follows:
   *
   * Maps productID as String to a Number.
   *
   * weights: [
   *   // weights for step 0
   *   {
   *     String(productID): Number(weight),
   *   },
   *   ...
   * ]
   */
  weights: [],
});

const BASE_WEIGHT = 1000;

const calculateAbsoluteWeight = (stepIndex, relativeWeight) =>
  stepIndex * BASE_WEIGHT + relativeWeight;

const handleGetStepsSuccess = (state, steps) => {
  let selectedProductIDs = state.get('selectedProductIDs');
  let fulfillmentAnswers = state.get('fulfillmentAnswers');
  let weights = state.get('weights');

  _.times(steps.size, () => {
    selectedProductIDs = selectedProductIDs.push(Immutable.Set());
    fulfillmentAnswers = fulfillmentAnswers.push(Immutable.Map());
    weights = weights.push(Immutable.Map());
  });

  state = state
    .set('steps', INITIAL_STATE.get('steps'))
    .setIn(['steps', 'values'], steps)
    .set('selectedProductIDs', selectedProductIDs)
    .set('fulfillmentAnswers', fulfillmentAnswers)
    .set('weights', weights);

  return state;
};

const handlePreAddProduct = (state, stepIndex, productID, relativeWeight) => {
  let selectedProductIDs = state.getIn(['selectedProductIDs', stepIndex]);

  selectedProductIDs = selectedProductIDs.add(productID);

  let weights = state.getIn(['weights', stepIndex]);

  const absoluteWeight = calculateAbsoluteWeight(stepIndex, relativeWeight);
  weights = weights.set(String(productID), absoluteWeight);

  state = state
    .setIn(['selectedProductIDs', stepIndex], selectedProductIDs)
    .setIn(['weights', stepIndex], weights);

  return state;
};

const handleAddProduct = (state, productID, relativeWeight) => {
  const currentStep = state.get('currentStep');

  let selectedProductIDs = state.getIn(['selectedProductIDs', currentStep]);

  selectedProductIDs = selectedProductIDs.add(productID);

  let weights = state.getIn(['weights', currentStep]);

  const absoluteWeight = calculateAbsoluteWeight(currentStep, relativeWeight);
  weights = weights.set(String(productID), absoluteWeight);

  state = state
    .setIn(['selectedProductIDs', currentStep], selectedProductIDs)
    .setIn(['weights', currentStep], weights);

  return state;
};

const handleRemoveProduct = (state, productID) => {
  const currentStep = state.get('currentStep');

  let selectedProductIDs = state.getIn(['selectedProductIDs', currentStep]);

  selectedProductIDs = selectedProductIDs.remove(productID);

  // clear fulfillment answers (if any) for the product being removed
  let fulfillmentAnswers = state.getIn(['fulfillmentAnswers', currentStep]);

  // explicit cast to String is required,
  // answers are stored under String keys
  fulfillmentAnswers = fulfillmentAnswers.remove(String(productID));

  // clear weight of product being removed
  let weights = state.getIn(['weights', currentStep]);

  // explicit cast to String is required,
  // weights are stored under String keys
  weights = weights.remove(String(productID));

  state = state
    .setIn(['selectedProductIDs', currentStep], selectedProductIDs)
    .setIn(['fulfillmentAnswers', currentStep], fulfillmentAnswers)
    .setIn(['weights', currentStep], weights);

  return state;
};

const handleAnswerFulfillmentQuestions = (state, answersByProduct) => {
  const currentStep = state.get('currentStep');

  let fulfillmentAnswers = state.getIn(['fulfillmentAnswers', currentStep]);

  fulfillmentAnswers = fulfillmentAnswers.merge(answersByProduct);

  state = state.setIn(['fulfillmentAnswers', currentStep], fulfillmentAnswers);

  return state;
};

const handleMoveNextStep = (state) => {
  const steps = state.getIn(['steps', 'values']);
  const stepsCount = steps.size;

  let currentStep = state.get('currentStep');

  if (currentStep < stepsCount - 1) {
    currentStep++;
    state = state.set('currentStep', currentStep);
  }

  return state;
};

const handleMovePrevStep = (state) => {
  let currentStep = state.get('currentStep');

  if (currentStep > 0) {
    currentStep--;
    state = state.set('currentStep', currentStep);
  }

  return state;
};

const selectMyGearReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_SELECT_MY_GEAR_STEPS_START:
      return state
        .set('steps', INITIAL_STATE.get('steps'))
        .setIn(['steps', 'isLoading'], true);

    case actionTypes.GET_SELECT_MY_GEAR_STEPS_SUCCESS:
      return handleGetStepsSuccess(state, Immutable.fromJS(action.steps));

    case actionTypes.GET_SELECT_MY_GEAR_STEPS_FAIL:
      return state
        .set('steps', INITIAL_STATE.get('steps'))
        .setIn(['steps', 'failed'], true);

    case actionTypes.SELECT_MY_GEAR_RESET_PROGRESS:
      return INITIAL_STATE;

    case actionTypes.SELECT_MY_GEAR_RESUME_PROGRESS:
      return state
        .set('currentStep', action.selectMyGearScratch.currentStep)
        .set(
          'selectedProductIDs',
          Immutable.fromJS(
            action.selectMyGearScratch.selectedProductIDs.map((productIDs) =>
              Immutable.Set(productIDs)
            )
          )
        )
        .set(
          'fulfillmentAnswers',
          Immutable.fromJS(action.selectMyGearScratch.fulfillmentAnswers)
        )
        .set('weights', Immutable.fromJS(action.selectMyGearScratch.weights));

    case actionTypes.SELECT_MY_GEAR_PRE_ADD_PRODUCT:
      return handlePreAddProduct(
        state,
        action.stepIndex,
        action.productID,
        action.relativeWeight
      );

    case actionTypes.SELECT_MY_GEAR_ADD_PRODUCT:
      return handleAddProduct(state, action.productID, action.relativeWeight);

    case actionTypes.SELECT_MY_GEAR_REMOVE_PRODUCT:
      return handleRemoveProduct(state, action.productID);

    case actionTypes.SELECT_MY_GEAR_ANSWER_FULFILLMENT_QUESTIONS:
      return handleAnswerFulfillmentQuestions(
        state,
        Immutable.fromJS(action.answersByProduct)
      );

    case actionTypes.SELECT_MY_GEAR_MOVE_NEXT_STEP:
      return handleMoveNextStep(state);

    case actionTypes.SELECT_MY_GEAR_MOVE_PREV_STEP:
      return handleMovePrevStep(state);

    case actionTypes.SELECT_MY_GEAR_MOVE_FIRST_STEP:
      return state.set('currentStep', 0);

    default:
      return state;
  }
};

export default selectMyGearReducer;
