import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, memoize, noop } from 'lodash';
import { fromJS } from 'immutable';
import ScrollArea from '../atoms/ScrollArea';

class IconSelectModal extends PureComponent {
  static propTypes = {
    icons: ImmutablePropTypes.map,
    onItemSelect: PropTypes.func,
    selectedItem: PropTypes.string,
  };

  static defaultProps = {
    icons: fromJS({}),
    onItemSelect: noop,
    selectedItem: '',
  };

  state = {
    modalOpen: false,
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleItemSelect = memoize(
    curry((icon, e) => {
      const { onItemSelect } = this.props;
      this.handleCloseModal();
      onItemSelect(icon);
    })
  );

  render() {
    const { icons, selectedItem } = this.props;

    const { modalOpen } = this.state;

    if (icons.size === 0 || selectedItem === '') {
      return null;
    }

    const IconElement = icons.getIn([selectedItem, 'image']) ?? 'div';
    const modalTrigger = (
      <Button className='text' fluid onClick={this.handleOpenModal}>
        {/* <Isvg*/}
        {/* className='ui bordered fluid image'*/}
        {/* key={icons.getIn([selectedItem,'image'])}*/}
        {/* src={icons.getIn([selectedItem,'image'])}*/}
        {/* >*/}
        {/* <img src={icons.getIn([selectedItem,'image'])} />*/}
        {/* </Isvg>*/}
        <div className='ui bordered fluid image'>
          <IconElement />
        </div>
      </Button>
    );

    const closeTrigger = (
      <div
        className='item close'
        onClick={this.handleCloseModal}
        onKeyPress={this.handleCloseModal}
      >
        {'Exit'}
      </div>
    );

    return (
      <Modal
        className='scrolling'
        closeIcon={closeTrigger}
        open={modalOpen}
        size='small'
        trigger={modalTrigger}
      >
        <div className='ui form'>
          <Modal.Header>{'Select a Category'}</Modal.Header>
          <Modal.Content
            style={{
              height: 'calc(100% - 48px)',
            }}
          >
            <ScrollArea>
              <Grid columns={3} stretched verticalAlign='bottom'>
                {icons.valueSeq().map((icon) => {
                  const ImgElement = icon.get('image');
                  return (
                    <Grid.Column
                      key={icon.get('id')}
                      onClick={this.handleItemSelect(icon)}
                      textAlign='center'
                    >
                      {icon.get('name')}
                      {ImgElement && (
                        <ImgElement className='ui bordered centered fluid image' />
                      )}
                    </Grid.Column>
                  );
                })}
              </Grid>
            </ScrollArea>
          </Modal.Content>
        </div>
      </Modal>
    );
  }
}

export default IconSelectModal;
