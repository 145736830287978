import React, { PureComponent } from 'react';
import { Button, Table } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
// import {NavLink}                         from 'react-router-dom';
import { List } from 'immutable';
import PropTypes from 'prop-types';

// utils
// import uiPathGenerator                   from '../../utils/uiPathGenerator';
import injectIntlToController from '../../utils/injectIntlToController';
import dateFormat from '../../utils/dateFormat';
import ServiceDepartmentCategoryCreateModal from './ServiceDepartmentCategoryCreateModal';

class ServiceDetailCategoriesTableList extends PureComponent {
  static propTypes = {
    deleteOneCategory: PropTypes.func.isRequired,
    departmentID: PropTypes.number,
    departmentSelected: PropTypes.string,
    loadingDeleteID: PropTypes.number,
    tableList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    departmentID: null,
    departmentSelected: null,
    loadingDeleteID: null,
    tableList: List(),
  };

  render() {
    const {
      deleteOneCategory,
      departmentSelected,
      loadingDeleteID,
      tableList,
      departmentID,
    } = this.props;

    const tableListed = tableList.map((list) => {
      // const linkTo = uiPathGenerator.genPath('admin.serviceDepartment.detail',{departmentID: list.get('id')});

      const categorytName = list.get('name');

      const handleDelete = (perfID) => () => {
        const msg = {
          message: 'Category deleted',
          title: 'Success',
        };
        deleteOneCategory(perfID, departmentID, msg);
      };

      const deletedLoading = loadingDeleteID === list.get('id');

      const triggerModal = <Button content='View' />;

      return (
        <Table.Row key={list.get('id')}>
          <Table.Cell selectable>{categorytName}</Table.Cell>
          <Table.Cell>{dateFormat(list.get('created_Date'))}</Table.Cell>
          <Table.Cell textAlign='right'>
            <Button.Group basic>
              <Button
                content='Delete'
                disabled={deletedLoading}
                loading={deletedLoading}
                onClick={handleDelete(list.get('id'))}
              />
              <ServiceDepartmentCategoryCreateModal
                categoryID={list.get('id')}
                departmentID={departmentID}
                departmentSelected={departmentSelected}
                trigger={triggerModal}
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table basic columns={4} compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Category'}</Table.HeaderCell>
            <Table.HeaderCell>{'Created'}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
          {tableListed}
        </Table.Header>
      </Table>
    );
  }
}

export default injectIntlToController(ServiceDetailCategoriesTableList);
