const getTopicsAggregate = (data, comparisonData) =>
  data[0].Values.map((intent) => {
    const name = intent['Topic Name'];
    const prev = comparisonData[0].Values.find((i) => i['Topic Name'] === name);
    const current = comparisonData[1].Values.find(
      (i) => i['Topic Name'] === name
    );

    return {
      current: current ? current.Count : 0,
      intent_name: name,
      prev: prev ? prev.Count : 0,
      total: intent.Count,
      type: intent.Type,
    };
  });

export default getTopicsAggregate;
