import { getFormSyncErrors } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import TenantImageTypes from '../../utils/TenantImageTypes';
import getTenant from '../../selectors/getTenant';
import tenantThunks from '../../actions/tenantThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

/** Array of image type accepted in the tenant upload image form */
const typeImagesAccepted = ['.png', '.jpg', '.gif', '.jpeg'];

const mapStateToProps = (state) => {
  const tenant = getTenant(state);

  let metaImage;
  if (tenant && tenant.has('images')) {
    metaImage =
      tenant
        .get('images')
        .find((img) => img.get('type') === TenantImageTypes.META) || void 0;
  }

  const initialValues = {
    description: tenant ? tenant.get('description') : null,
  };

  const isLoadingTenant = tenant && tenant.get('tenantIsLoading');
  const isLoadingImage = tenant && tenant.get('tenantImageIsLoading');

  return {
    formErrors: getFormSyncErrors('TenantLinksConfiguration')(state),
    initialValues,
    isLoadingImage,
    isLoadingTenant,
    metaImage,
    tenantID: tenant && tenant.get('id'),
    typeImagesAccepted: typeImagesAccepted.join(', '),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit(values, props, ownProps) {
    dispatch(
      tenantThunks.updateTenant(ownProps.tenantID, {
        description: values.get('description'),
      })
    ).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: 'The Meta data have been updaded',
          title: 'Success',
        })
      );
    });
  },

  removeImage(imageID) {
    dispatch(tenantThunks.removeTenantImage(imageID));
  },

  submitImage(imageType, imageFile, intl) {
    const { type } = imageFile;
    let imageAccepted = false;

    typeImagesAccepted.forEach((img) => {
      if (type.match(img)) {
        imageAccepted = true;
      }
    });

    if (imageAccepted) {
      dispatch(tenantThunks.uploadTenantImage(imageType, imageFile));
    } else {
      const title = intl.formatMessage({
        id: 'label.error',
      });
      const message = intl.formatMessage({
        id: 'message.file_type_not_supported_tenant_image',
      });

      dispatch(
        toastNotificationsActions.error({
          message,
          title,
        })
      );
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
