import Immutable from 'immutable';
import { createSelector } from 'reselect';
import groupPermissions from '../globals/groupPermissions';

const getUsersById = (state) => state.getIn(['entities', 'users']);

const getCurrentUserId = (state) => state.getIn(['session', 'currentUser']);

const getImpersonatingUserId = (state) =>
  state.getIn(['session', 'impersonation', 'impersonatingUser']);

const getCurrentUser = (usersById = Immutable.Map(), currentUserId) => {
  let currentUser = usersById.get(String(currentUserId), null);
  if (!currentUser) {
    currentUser = usersById.get(currentUserId, null);
  }

  if (currentUser) {
    const userPermissions = currentUser.get('groups', Immutable.List());
    const isAdminFlag = currentUser.get('is_admin');
    const isAdminGroup = userPermissions.find(
      (perm) => perm === groupPermissions.ADMIN
    );

    if (isAdminFlag && !isAdminGroup) {
      // This is a super ADMIN, we need to add the group ADMIN by Hand
      const newGrp = currentUser.get('groups').push(groupPermissions.ADMIN);
      currentUser = currentUser.set('groups', newGrp);
    }
  }

  return currentUser;
};

const selector = createSelector(
  [getUsersById, getCurrentUserId],
  getCurrentUser
);
export { getCurrentUserId };
export { getImpersonatingUserId };

export default selector;
