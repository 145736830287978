import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

const blockAttrsDefinition = _.merge({}, BlockBase, {
  buttonAddEntryText: {
    default: '',
    required: true,
    type: 'string',
  },
  buttonShowBottomAfter: {
    default: 0,
    type: 'number',
  },
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputValue: {
    objectShape: {
      map: {
        requred: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  placeholder: {
    default: '',
    type: 'string',
  },
  readOnly: {
    default: false,
    type: 'bool',
  },
  template: {
    default: 'addEntries01',
  },
});

const AddEntriesBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Add Entries',
  templates: ['addEntries01'],
};

const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

export { propTypes as default, AddEntriesBlockDefinition, TypeDefaults };
