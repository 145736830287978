import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSupplies extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M34 46.82l1.41-1.41-17.12-17.14 9.77-9.77 2.35 2.35-3.09 3.09c-.285.244-.41.628-.322.993.088.366.373.65.74.74.364.087.748-.038.992-.323l3.09-3.09 4 4-6 6c-.285.244-.41.628-.322.993.088.366.373.65.74.74.364.087.748-.038.992-.323l6-6 4 4-3.09 3.09c-.285.244-.41.628-.322.993.088.366.373.65.74.74.364.087.748-.038.992-.323l3.09-3.09 2.56 2.56 1.41-1.41-17.14-17.15c-.375-.376-.884-.587-1.415-.587-.53 0-1.04.21-1.415.587l-9.77 9.77c-.376.375-.587.884-.587 1.415 0 .53.21 1.04.587 1.415L34 46.82zM79.12 66.74L61.88 49.49l-1.41 1.41 2.38 2.38-3.09 3.09c-.285.244-.41.628-.322.993.088.366.373.65.74.74.364.087.748-.038.992-.323l3.09-3.09 4 4-6 6c-.285.244-.41.628-.322.993.088.366.373.65.74.74.364.087.748-.038.992-.323l6-6 4 4-3.09 3.09c-.252.254-.35.622-.256.967.094.345.365.614.71.705.346.092.714-.008.966-.262l3.09-3.09 2.64 2.64-9.77 9.77-.71.71.71-.71L50.7 60.67l-1.41 1.41 17.24 17.25c.373.376.88.588 1.41.59.53-.002 1.037-.214 1.41-.59l9.77-9.77c.775-.78.775-2.04 0-2.82zM73.71 34.55l5.23-15.81c.152-.466.03-.977-.316-1.324-.347-.346-.858-.468-1.324-.316l-15.82 5.23c-.193.065-.367.175-.51.32L25.34 58.27l1.84 1.84 35-35c.78.31 2.49 1.21 2.86 2.32-.047.024-.09.055-.13.09L29.75 62.68l.92.92 35.18-35.18c.53.12 2.1.6 1.92 2.12-.003.033-.003.067 0 .1L32.74 65.67l.92.92 35.06-35.06c.023-.028.043-.058.06-.09.88.42 2.13 1.24 2 2.16-.013.112.004.226.05.33L35.92 68.86l1.84 1.84 35.63-35.64c.145-.143.255-.317.32-.51zm-4.6-4.39c-.14-1.503-1.265-2.73-2.75-3-.495-1.258-1.465-2.27-2.7-2.82l6.94-2.3 3.4 3.39-2.25 6.81c-.664-.93-1.58-1.652-2.64-2.08zM22.54 61.08l-.07.06-5.3 5.3c-.733.732-1.145 1.725-1.145 2.76 0 1.035.412 2.028 1.145 2.76l6.83 6.9c.732.733 1.725 1.145 2.76 1.145 1.035 0 2.028-.412 2.76-1.145l5.19-5.19c.104-.044.2-.1.29-.17l1-1-12.5-12.39-.96.97z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOfficeSupplies;
