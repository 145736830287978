import React from 'react';

const CHECKBOXDESCRIPTIONTEXT =
  'Checking this box will allow the Virtual Agent to periodically check this email account and respond to new messages.';
const CHECKBOXCONFIGTEXT =
  'Depending on your configuration, the Virtual Agent can respond to email with answers, knowledge article links, links to continue the conversation, or by creating a ticket.';

const styles = {
  fontSize: '0.9em',
  margin: '0 3em',
  marginRight: '20em',
};
const GmailPrimaryEmailLegend = () => (
  <div style={styles}>
    <em>{CHECKBOXDESCRIPTIONTEXT}</em>
    <br />
    <em>{CHECKBOXCONFIGTEXT}</em>
  </div>
);

export default GmailPrimaryEmailLegend;
