import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { noop } from 'lodash';
import { reduxForm } from 'redux-form/immutable';
import { intl } from 'esp-util-intl';

// Molecule
import SupportFormContent from './SupportFormContent';

// Controllers
import SupportFormController from '../controllers/SupportFormController';

// Global
import typeSupport from '../../globals/TypeSupport';

class SupportForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,

    isDirty: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    sendMessage: PropTypes.func.isRequired,
    successOTPSend: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDirty: false,
    isLoading: false,
    onClose: noop,
  };

  state = {
    error: '',
  };

  onHandleFormError = (error) =>
    this.setState({
      error,
    });

  handleSubmitMessage = (data) => {
    const { onClose, sendMessage, successOTPSend } = this.props;

    // Check if a message has been typed in the field
    if (!data.get('msgFeedback')) {
      this.setState({
        error: intl.formatMessage({
          id: 'label.field_can_not_empty',
        }),
      });
    } else {
      // Send the msg
      sendMessage(data, (success) => {
        if (success) {
          onClose(); // Close the modal

          successOTPSend(
            intl.formatMessage({
              id: 'message.success_order',
            }),
            intl.formatMessage({
              id: 'message.msg_has_been_sent',
            })
          );
        }
      });
    }
  };

  render() {
    const { handleSubmit, isDirty, isLoading } = this.props;
    const { error } = this.state;

    return (
      <Form onSubmit={handleSubmit(this.handleSubmitMessage)}>
        <Modal.Header>
          {intl.formatMessage({
            id: 'label.feedback_help',
          })}
        </Modal.Header>

        <Modal.Content className='withFooter'>
          <SupportFormContent handleFormError={this.onHandleFormError} />
        </Modal.Content>

        <Modal.Actions>
          <Button
            disabled={!isDirty || Boolean(error)}
            loading={isLoading}
            primary
            type='submit'
          >
            {intl.formatMessage({
              id: 'label.send',
            })}
          </Button>
        </Modal.Actions>
      </Form>
    );
  }
}

const SupportFormTest = SupportForm;

// eslint-disable-next-line no-class-assign -- DEV-1526
SupportForm = reduxForm({
  form: 'SupportForm',
  initialValues: {
    typeFeedback: typeSupport.HELP,
  },
})(SupportForm);

// eslint-disable-next-line no-class-assign -- DEV-1526
SupportForm = SupportFormController(SupportForm);
export { SupportFormTest };
export default SupportForm;
