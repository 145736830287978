import {
  CONDITION_REGEX,
  FILTER_CONDITIONS,
  FILTER_OPERATORS,
} from '../Filter';
const filterSeparators = ['__', '='];

// Reverse lookup of the object key by the value
const getKeyFromValue = (obj, val) =>
  Object.keys(obj).find((key) => obj[key] === val);

const getFilterStateFromEspFilters = (paramsString) =>
  paramsString

    ?.split(CONDITION_REGEX)
    // We COULD remove the conditions from the array, but we do not want to at this time.
    // .filter((val) => !val.match(CONDITION_REGEX))
    .map((val) => {
      const isCondition = Boolean(val.match(CONDITION_REGEX));

      if (isCondition) {
        return {
          isCondition,
          type: getKeyFromValue(FILTER_CONDITIONS, val),
        };
      } else {
        const array = val?.split(new RegExp(filterSeparators.join('|'), 'g'));

        const [parameter, filterOperator, value] = array;

        return {
          parameter,
          type: getKeyFromValue(FILTER_OPERATORS, filterOperator),
          value,
        };
      }
    });

export default getFilterStateFromEspFilters;
