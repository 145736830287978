import { connect } from 'react-redux';

import AddTaskModal from '../../../../../admin/js/v1/components/organisms/AddTaskModal';

const mapStateToProps = (state) => {
  const addTaskForm = state.getIn(['form', 'AddTaskForm']);

  return {
    addTaskForm,
  };
};

export default connect(mapStateToProps)(AddTaskModal);
