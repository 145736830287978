import React, { PureComponent } from 'react';

import TypeSearchFAQArchetype, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSearchFAQArchetype';
import PropTypes from 'prop-types';
import { debounce, noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
// Molecule
import FormInputSelect from '../../molecules/FormInputSelect';
import ValidatedField from '../../molecules/ValidatedField';
// Controller
import SearchFAQArchetype01Controller from '../../controllers/SearchFAQArchetype01Controller';

const TIMEOUT = 300;

class SearchFAQArchetype01 extends PureComponent {
  static propTypes = {
    ...TypeSearchFAQArchetype,
    archetypeList: ImmutablePropTypes.list,
    departmentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isLoadingArchetype: PropTypes.bool,
    searchArchetype: PropTypes.func,
    submitNoMatch: PropTypes.func,
    templateSelected: PropTypes.string,
  };

  static defaultProps = {
    ...TypeDefaults,
    archetypeList: Immutable.List(),
    departmentID: null,
    isLoadingArchetype: false,
    searchArchetype: noop,
    submitNoMatch: noop,
    templateSelected: '',
  };

  componentDidMount() {
    const {
      changeValue,
      departmentID,
      id,
      searchArchetype,
      setAlternateSubmit,
      setAlternateSkip,
      templateSelected,
    } = this.props;

    setAlternateSkip(this.handleNoMatch);
    setAlternateSubmit(this.handleSubmit);

    let resetSkip = Immutable.fromJS({
      skip: 'noskip',
    });
    if (templateSelected) {
      const getFullResult = true; // Will force to load also the first pagination data
      resetSkip = resetSkip.set('template', templateSelected);
      changeValue('searchArchetype', templateSelected); // Set the default value to pass as search
      searchArchetype(templateSelected, 'eid', departmentID, getFullResult); // Get preselected archetype
    } else {
      searchArchetype(null, null, departmentID); // Get default archetype list
    }
    changeValue(id, Immutable.fromJS(resetSkip));
  }

  handleNoMatch = () => {
    const { submitNoMatch, id } = this.props;
    submitNoMatch(id);
  };

  handleSetTerm = debounce((e, data) => {
    const { changeValue, departmentID, id, searchArchetype } = this.props;

    searchArchetype(data.searchQuery, null, departmentID);
    changeValue(
      id,
      Immutable.fromJS({
        skip: 'skip',
      })
    );
  }, TIMEOUT);

  handleSubmit = (cb) => {
    const { archetypeList, saveBEscratch, templateSelected } = this.props;

    const temp = archetypeList.find(
      (arch) => arch.get('eid') === templateSelected
    );
    saveBEscratch({
      archTemplateDescription: temp && temp.get('description'),
      templateName: temp && temp.get('name'),
    }).then(() => {
      cb();
    });
  };

  handleChange = (e, value) => {
    const { id, changeValue } = this.props;

    changeValue(
      id,
      Immutable.fromJS({
        skip: 'noskip',
        template: value,
      })
    );
  };

  onHandleOnBlur = () => {
    const {
      departmentID,
      archetypeList,
      isLoadingArchetype,
      searchArchetype,
    } = this.props;

    if (!isLoadingArchetype && archetypeList.isEmpty()) {
      searchArchetype(null, null, departmentID); // Get default archetype list
    }
  };

  validate = (value) => (value ? null : 'error');

  render() {
    const { archetypeList, isLoadingArchetype, template, type } = this.props;

    const options = [];

    archetypeList.forEach((template) => {
      options.push({
        key: template.get('eid'),
        text: template.get('description') || template.get('name'),
        value: template.get('eid'), // For some reason, an archetype can have empty description...
      });
    });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField
          component={FormInputSelect}
          handleBlur={this.onHandleOnBlur}
          icon='search'
          iconPosition='left'
          label='Templates'
          loading={isLoadingArchetype}
          name='searchArchetype'
          onChange={this.handleChange}
          onSearchChange={this.handleSetTerm}
          options={options}
          placeholder='Search a template'
          search
          validate={this.validate}
        />
      </div>
    );
  }
}

export const SearchFAQArchetype01Test = SearchFAQArchetype01;

export default SearchFAQArchetype01Controller(SearchFAQArchetype01);
