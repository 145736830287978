import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addProducts = (products, newProducts) => {
  newProducts.forEach((newProduct, index) => {
    const productId = newProduct.get('id');

    // Check if the product already exists in order to use the previous index
    // or use the current one received to preserve the sort
    // and avoid messes with the order
    const productIndex = products.hasIn([productId, '_index'])
      ? products.getIn([productId, '_index'])
      : index;

    newProduct = newProduct.set('_index', productIndex);

    products = products.set(productId, newProduct);
  });

  return products;
};

// We need this separate function
// as a adding a new product messes with the _index
// set in the multiple addProducts function
const addOneProduct = (products, newProduct) => {
  const productId = newProduct.get('id');
  // Merge the old product with the new, or return the new product
  // if the old doesn't exist
  products = products.update(productId, (oldProduct) =>
    oldProduct ? oldProduct.merge(newProduct) : newProduct
  );

  return products;
};

const productsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_PRODUCTS_SUCCESS:
      if (action.resetProductsList) {
        // Reset the products list to replace it by a new one
        state = INITIAL_STATE;
      }
      return addProducts(state, fromJS(action.products));

    case actionTypes.LOAD_ONE_PRODUCT_SUCCESS:
      return addOneProduct(state, fromJS(action.product));

    case actionTypes.GET_HARDWARE_BUNDLE_PRODUCTS_SUCCESS:
      // TODO can make this code a bit better
      return addProducts(state, fromJS(action.hardwareBundleProducts));

    case actionTypes.RESET_PRODUCTS_SUCCESS:
      return fromJS({});

    case actionTypes.UPDATE_PRODUCTS_IMAGES_SUCCESS:
      return addProducts(state, action.products);

    default:
      return state;
  }
};

export default productsReducer;
