import { change, getFormValues } from 'redux-form/immutable';
// Actions - Thunks
import baristaThunks from '../../actions/baristaThunks';
import workflowThunks from '../../actions/workflowThunks';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues('Form01')(state);
  const wordSelected =
    (formValues && formValues.getIn([ownProps.id, 'word'], null)) || null;
  const archetypeEID =
    (formValues && formValues.getIn([ownProps.id, 'template'], null)) || null;
  return {
    archetypeEID,
    entityList: state.getIn(['adminFaqs', 'entityList']),
    formValue: formValues.get(ownProps.id),
    isLoadingEntityList: state.getIn(['adminFaqs', 'isLoadingEntityList']),
    wordSelected,
  };
};

// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    dispatch(change('Form01', id, data));
  },
  saveBEscratch(data) {
    return dispatch(workflowThunks.updateTempDataBackEndScratch(data));
  },
  searchEntity(eid, term, searchBy, getFullResult) {
    dispatch(
      baristaThunks.searchEntityByArchetype(eid, term, searchBy, getFullResult)
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
