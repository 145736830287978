import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Block own attributes definition
const blockAttrsDefinition = _.merge({}, BlockBase, {
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputValue: {
    default: {},
    objectShape: {
      map: {
        required: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'boolean',
      },
    },
    type: 'object',
  },
  pickDept: {
    default: false,
    type: 'bool',
  },
  pickLocation: {
    default: false,
    type: 'bool',
  },
  pickRole: {
    default: false,
    type: 'bool',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'locationDeptRolePicker',
  },
});

// Object Definition used by blobifier
const locationDeptRolePickerBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Location Department Role Picker',
  templates: ['locationDeptRolePicker01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export {
  propTypes as default,
  locationDeptRolePickerBlockDefinition,
  TypeDefaults,
};
