import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import React, { PureComponent } from 'react';
import { Button, Divider, Form, Modal } from 'semantic-ui-react';

import { noop } from 'lodash';

// Atom
import ScrollArea from '../atoms/ScrollArea';
// Molecules
import FulfillmentQuestions from './FulfillmentQuestions';
// Controllers
import AdminProductFulfillmentController from '../controllers/AdminProductFulfillmentController';

class AdminProductFulfillment extends PureComponent {
  static propTypes = {
    fulfillmentOptions: ImmutablePropTypes.list,
    loadFulfillmentOptions: PropTypes.func,
    product: ImmutablePropTypes.map,
    productId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    fulfillmentOptions: null,
    loadFulfillmentOptions: noop,
    product: Immutable.Map(),
  };
  componentDidMount() {
    const {
      productId,
      loadFulfillmentOptions,
      fulfillmentOptions,
    } = this.props;

    if (!fulfillmentOptions || fulfillmentOptions.isEmpty()) {
      loadFulfillmentOptions(productId);
    }
  }

  render() {
    const { fulfillmentOptions, product, productId } = this.props;

    // This can be either null or an empty Immutable map. In either case means we have no fulfillment options
    const hasFulfillmentOptions =
      fulfillmentOptions && !fulfillmentOptions.isEmpty();

    const trigger = <Button content='Review Questions' />;

    return (
      <>
        {hasFulfillmentOptions && (
          <div>
            <Form>
              {/* NOTE: Leave this commented out for now.
                <Form.Checkbox
                inline
                label='Use external fulfillment'
              />*/}
              <Form.Input
                label='ServiceNow Catalog Link'
                readOnly
                value={product.get('snow_url') || ''}
              />
            </Form>

            <Divider hidden />
            {/* NOTE: The button below will only show up once a workflow has been selected above */}
            <Modal
              className='scrolling'
              closeIcon
              size='small'
              trigger={trigger}
            >
              <Modal.Header content='Fulfillment Options Questions' />
              <Modal.Content>
                <ScrollArea>
                  <FulfillmentQuestions productId={productId} />
                </ScrollArea>
              </Modal.Content>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

const AdminProductFulfillmentTest = AdminProductFulfillment;

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminProductFulfillment = AdminProductFulfillmentController(
  AdminProductFulfillment
);
export { AdminProductFulfillmentTest };
export default AdminProductFulfillment;
