import async from 'async';
import { find } from 'lodash';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

import inboxesActions from './inboxesActions';
import integrationActions from './integrationActions';
import toastNotificationsActions from './toastNotificationsActions';

const inboxesThunks = {};

const mergeGeneratorsAndCredentials = (generators, credentials) =>
  generators.map((generator) => ({
    ...generator,
    credential: find(credentials, { id: generator.email_account }),
  }));

inboxesThunks.createCase = (emailCredential) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const primaryEmail = state.getIn([
      'integration',
      'emailIntegrationPrimaryCredential',
    ]);
    const url = endpointGenerator.genPath('emailSensor.caseGenerators');
    APIcall.post({
      data: {
        active: true,
        email_account: primaryEmail.get('id'),
      },
      error(error) {
        reject(error);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url,
    });
  });
inboxesThunks.deleteCaseInbox = ({ caseGeneratorID }) => {
  const url = endpointGenerator.genPath('emailSensor.caseGenerators.instance', {
    caseGeneratorID,
  });
  APIcall.delete({
    token: true,
    url,
  });
};
inboxesThunks.fetchCaseGenerators = () =>
  new Promise((resolve, reject) => {
    APIcall.get({
      error(err) {
        reject(err);
      },
      success({ body }) {
        const { results } = body;
        resolve(results);
      },
      token: true,
      url: endpointGenerator.genPath('emailSensor.caseGenerators'),
    });
  });
/**
 * Summary. Gets the inbox that is currently set as primary (in state ) and creates a new case generator out of it
 * Description. It will get the current inbox from state and will send a POST call to the API to create a new generator
 * out of it
 *
 */
inboxesThunks.addCaseGeneratorToCredential = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const primaryEmail = state.getIn([
      'integration',
      'emailIntegrationPrimaryCredential',
    ]);

    const patchCredential = (next) => {
      const data = new FormData();
      data.append('is_for_case', true);

      const url = endpointGenerator.genPath('emails.credentials.emailID', {
        emailID: primaryEmail.get('id'),
      });
      APIcall.patch({
        data,
        error(error) {
          next(error);
        },
        preventShowError: true,
        success() {
          next(null);
        },
        token: true,
        url,
      });
    };

    const createCaseGenerator = (next) => {
      const url = endpointGenerator.genPath('emailSensor.caseGenerators');
      APIcall.post({
        data: {
          active: true,
          email_account: primaryEmail.get('id'),
        },
        error(error) {
          next(error);
        },
        success({ body }) {
          next(null, body);
        },
        token: true,
        url,
      });
    };

    const finallyHook = (err, body) => {
      if (err) {
        reject(err);
      } else {
        dispatch(integrationActions.addGeneratorToPrimaryCredentialSuccess());
        resolve(body);
      }
    };

    async.waterfall([patchCredential, createCaseGenerator], finallyHook);
  });
inboxesThunks.addInbox = (emailCredentials) => (dispatch) => {
  dispatch(inboxesActions.addStart());
  const createCredentials = (next) => {
    const data = { ...emailCredentials };
    const url = endpointGenerator.genPath('emails.credentials');
    dispatch(integrationActions.startAddEmailCredentials());
    APIcall.post({
      data,
      error(error) {
        dispatch(integrationActions.errorAddEmailCredentials());
        next(error);
      },
      preventShowError: true,
      success({ body }) {
        dispatch(integrationActions.successAddEmailCredentials(body));
        next(null, body);
      },
      token: true,
      url,
    });
  };
  const createCaseGenerator = (credential, next) => {
    APIcall.post({
      data: {
        active: true,
        email_account: credential.id,
        esp_service_department: emailCredentials?.esp_service_department,
      },
      error(err) {
        next(err);
      },
      preventShowError: true,
      success({ body: caseGenerator }) {
        next(null, {
          caseGenerator,
          credential,
        });
      },
      token: true,
      url: endpointGenerator.genPath('emailSensor.caseGenerators'),
    });
  };
  const updateServiceDepartment = (credential, next) => {
    const { id, esp_service_department } = credential.caseGenerator;

    const generatorURL = endpointGenerator.genPath(
      'emailSensor.caseGenerators.instance',
      { caseGeneratorID: id }
    );
    APIcall.patch({
      data: {
        active: true,
        esp_service_department: esp_service_department?.id,
      },
      error(err) {
        next(err);
      },
      preventShowError: true,
      success({ body }) {
        const caseInbox = {
          ...body,
          credential,
        };
        next(null, caseInbox);
      },
      token: true,
      url: generatorURL,
    });
  };

  const actions = emailCredentials.esp_service_department
    ? [createCredentials, createCaseGenerator, updateServiceDepartment]
    : [createCredentials, createCaseGenerator];

  return new Promise((resolve, reject) => {
    async.waterfall(actions, (err, { credential, caseGenerator } = {}) => {
      if (err) {
        reject(err);
      } else {
        const caseInbox = { ...caseGenerator, credential };
        dispatch(inboxesActions.addSuccess(caseInbox));
        resolve({
          caseGenerator,
          credential,
        });
      }
    });
  });
};
/**
 * Summary. Deletes a Barista Inbox. The ids here ARE NOT PULLED FROM STATE
 * Description. A barista inbox is a combination of 2 backend models: CaseGenerator and Credentials
 * It will send a DELETE call for both resources if the credential is not primary
 * If credential is primary, it will send a DELETE only to the case generator resource and update the other one
 * By default, the last created credential on primary email account is primary, the other ones are not.
 * We set that value using the attribute primary of the credential
 * When a credential is set as primary=FALSE that credential is removed form CASE_GENERATOR
 * When a box is removed from CASE_GENERATOR it just get updated as primary === false
 * For this thunk By default, all credentials are primary to avoid deletion by omission.
 * @param {int} baristaInboxId
 * @param {int} credentialId
 * @param {bool} [isPrimary=true]
 */
inboxesThunks.deleteInbox = (
  caseGeneratorID,
  credentialId,
  isPrimary,
  isGmailAccount = false
) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(inboxesActions.deleteStart());

    const disconnectGmailAccount = (next) => {
      if (isGmailAccount) {
        APIcall.post({
          data: {},
          error(error) {
            next(error);
          },
          success() {
            next(null);
          },
          token: true,
          url: endpointGenerator.genPath(
            'emails.credentials.instance.oauth_disconnect',
            { emailID: credentialId }
          ),
        });
      } else {
        next(null);
      }
    };

    // responses for both API calls below are 204 ( No Content )
    const deleteCaseInbox = (next) => {
      const url = endpointGenerator.genPath(
        'emailSensor.caseGenerators.instance',
        { caseGeneratorID }
      );
      APIcall.delete({
        error(err) {
          next(err);
        },
        success() {
          next();
        },
        token: true,
        url,
      });
    };
    const deleteCredential = (next) => {
      const url = endpointGenerator.genPath('emails.credentials.instance', {
        emailID: credentialId,
      });
      APIcall.delete({
        error(err) {
          next(err);
        },
        success() {
          next();
        },
        token: true,
        url,
      });
    };

    const editEmailCredential = (next) => {
      return dispatch(
        inboxesThunks.editEmailCredentials({
          id: credentialId,
          primary: false,
        })
      )
        .then(() => {
          next();
        })
        .catch((err) => {
          next(err);
        });
    };

    const finallyHook = (err) => {
      if (err) {
        dispatch(inboxesActions.deleteFail());
        reject(err);
      } else {
        // these responses have no content
        dispatch(
          toastNotificationsActions.success({
            message: 'Succesfuly deleted',
            title: 'Barista Inbox',
          })
        );
        dispatch(inboxesActions.deleteSuccess(caseGeneratorID));
        resolve({ caseGeneratorID, credentialId });
      }
    };

    const waterfallCalls = isPrimary
      ? [disconnectGmailAccount, deleteCaseInbox, editEmailCredential]
      : [disconnectGmailAccount, deleteCaseInbox, deleteCredential];

    async.waterfall(waterfallCalls, finallyHook);
  });

inboxesThunks.editEmailCredentials = (emailCredentials) => (dispatch) =>
  new Promise((resolve, reject) => {
    const { id: emailID } = emailCredentials;

    const url = endpointGenerator.genPath('emails.credentials.emailID', {
      emailID,
    });
    dispatch(inboxesActions.updateCaseInboxCredentialStart());

    APIcall.patch({
      data: { ...emailCredentials },
      error({ response }) {
        reject(response);
      },
      preventShowError: true,
      success({ body: credentials }) {
        dispatch(inboxesActions.updateCaseInboxCredentialSuccess(credentials));
        resolve(credentials);
      },
      token: true,
      url,
    });
  });

inboxesThunks.editEmailCredentialsAndServiceDepartement = (
  emailCredentials
) => (dispatch) => {
  dispatch(inboxesActions.updateStart());
  const updateCredentials = (next) => {
    const url = endpointGenerator.genPath('emails.credentials.emailID', {
      emailID: emailCredentials.id,
    });
    dispatch(integrationActions.startUpdateEmailCredentials());
    APIcall.patch({
      data: { ...emailCredentials },
      error(err) {
        dispatch(integrationActions.errorUpdateEmailCredentials());
        next(err);
      },
      preventShowError: true,
      success({ body }) {
        next(null, body);
      },
      token: true,
      url,
    });
  };

  const updateServiceDepartment = (credential, next) => {
    if (emailCredentials.esp_service_department === 'any') {
      next(null, credential);
    } else {
      const { caseGeneratorID } = emailCredentials;
      const generatorURL = endpointGenerator.genPath(
        'emailSensor.caseGenerators.instance',
        { caseGeneratorID }
      );

      APIcall.patch({
        data: {
          active: true,
          email_account: emailCredentials.id,
          esp_service_department: emailCredentials.esp_service_department,
        },
        error(err) {
          next(err);
        },
        preventShowError: true,
        success({ body }) {
          const caseInbox = {
            ...body,
            credential,
          };
          next(null, caseInbox);
        },
        token: true,
        url: generatorURL,
      });
    }
  };
  return new Promise((resolve, reject) => {
    async.waterfall(
      [updateCredentials, updateServiceDepartment],
      (err, caseInbox) => {
        if (!err) {
          dispatch(inboxesActions.updateSuccess(caseInbox));
          resolve(caseInbox);
        } else {
          reject(err);
        }
      }
    );
  });
};

inboxesThunks.emailTestConnection = ({
  emailData,
  emailAccountType,
  isNewFormInbox,
  isOnlyTestingConnection,
}) => (dispatch) => {
  const url = endpointGenerator.genPath(
    'emails.credentials.instance.test_connection',
    {
      emailID: emailData.id,
    }
  );
  dispatch(inboxesActions.startEmailTestConnection({ ...emailData }));
  return APIcall.post({
    data: { ...emailData },
    preventShowError: false,
    token: true,
    url,
  })
    .then(() =>
      dispatch(
        inboxesActions.successEmailTestConnection({
          caseBox: { ...emailData },
          emailAccountType,
          isNewFormInbox,
          isOnlyTestingConnection,
        })
      )
    )
    .catch(() =>
      dispatch(
        inboxesActions.errorEmailTestConnection({
          emailAccountType,
          isNewFormInbox,
          isOnlyTestingConnection,
        })
      )
    );
};

inboxesThunks.fetchBoxes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(inboxesActions.fetchListStart());

    async.waterfall(
      [
        (next) => {
          inboxesThunks
            .fetchCaseGenerators()
            .then((generatorsData) => {
              // pass case generators data as first argument for the next
              // function
              next(null, generatorsData);
            })
            .catch((err) => {
              next(err);
            });
        },
        (generators, next) => {
          dispatch(inboxesThunks.fetchCredentials())
            .then((credentialsData) => {
              const baristaBoxes = mergeGeneratorsAndCredentials(
                generators,
                credentialsData
              );
              next(null, baristaBoxes);
            })
            .catch((err) => {
              next(err);
            });
        },
      ],
      (err, baristaBoxes) => {
        if (err) {
          dispatch(inboxesActions.fetchListFail());
          reject(err);
        } else {
          dispatch(inboxesActions.fetchListSuccess(baristaBoxes));
          resolve(baristaBoxes);
        }
      }
    );
  });

inboxesThunks.fetchCredentials = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('emails.credentials');
    dispatch(inboxesActions.fetchEmailCredentialListStart());
    APIcall.get({
      error({ response }) {
        inboxesActions.fetchEmailCredentialListFail();
        reject(response);
      },
      preventShowError: true,
      success({ body }) {
        const credentials = body.results;
        dispatch(inboxesActions.fetchEmailCredentialListSuccess(credentials));
        resolve(credentials);
      },
      token: true,
      url,
    });
  });

/**
 * Summary. Recovers the credential set as primary and it's case generator's ID ( if exists )
 * Description. Recovers the primary credential and dispatches it into state. Its case generator's data is appended
 * in the payload ( if exists) to allow its removal
 */
inboxesThunks.fetchPrimaryCredential = () => (dispatch) => {
  const url = endpointGenerator.genPath('emails.credentials');
  dispatch(inboxesActions.fetchPrimaryCredentialStart());
  APIcall.get({
    error() {
      dispatch(inboxesActions.fetchPrimaryCredentialFail());
    },
    preventShowError: true,
    success({ body }) {
      const primary = find(body.results, { is_primary: true });
      dispatch(inboxesActions.fetchPrimaryCredentialSuccess(primary));
    },
    token: true,
    url,
  });
};

inboxesThunks.removeCaseGeneratorFromCredential = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const primaryCredential = getState().getIn([
      'integration',
      'emailIntegrationPrimaryCredential',
    ]);

    const findGeneratorForCredential = (next) => {
      const url = endpointGenerator.genPath('emailSensor.caseGenerators');
      const filter = `email_account__EQ=${primaryCredential.get('id')}`;
      APIcall.get({
        error(error) {
          next(error);
        },
        query: {
          esp_filters: encodeURI(filter),
        },
        success({ body }) {
          next(null, body.results[0]);
        },
        token: true,
        url,
      });
    };

    const removeGenerator = (generator, next) => {
      const { id: caseGeneratorID } = generator;
      const url = endpointGenerator.genPath(
        'emailSensor.caseGenerators.instance',
        { caseGeneratorID }
      );
      APIcall.delete({
        error(error) {
          next(error);
        },
        success({ body }) {
          next(null, body);
        },
        token: true,
        url,
      });
    };
    const patchCredential = (body, next) => {
      const data = new FormData();
      data.append('is_for_case', false);

      const url = endpointGenerator.genPath('emails.credentials.emailID', {
        emailID: primaryCredential.get('id'),
      });
      APIcall.patch({
        data,
        error() {
          next();
        },
        preventShowError: true,
        success({ body }) {
          next(null, body);
        },
        token: true,
        url,
      });
    };

    const finallyHook = (error, body) => {
      if (error) {
        reject(error);
      } else {
        dispatch(
          integrationActions.removeGeneratorFromPrimaryCredentialSuccess()
        );

        dispatch(
          toastNotificationsActions.success({
            message: 'Success',
            title: 'Delete Barista Inbox',
          })
        );
        resolve(body);
      }
    };

    async.waterfall(
      [findGeneratorForCredential, removeGenerator, patchCredential],
      finallyHook
    );
  });

export default inboxesThunks;
