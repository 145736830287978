import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';

const setSamlSpIdentityIdConfig = async (data, method) => {
  const url = endpointGenerator.genPath('espConfig.samlSpIdentityId');

  return _APIcall[method]({
    data,
    token: true,
    url: url,
  });
};

export default setSamlSpIdentityIdConfig;
