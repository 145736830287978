import moment from 'moment-timezone';

const utcMethods = {};

const getUTCDays = (
  days = [],
  startDate,
  startTime,
  timeZone = moment.tz.guess()
) => {
  const utcStartDate = moment
    .tz(`${startDate} ${startTime}`, timeZone)
    .utc()
    .format('ddd');
  const localStartDate = moment
    .tz(`${startDate} ${startTime}`, timeZone)
    .format('ddd');
  // if current local date is different that utc date
  const isUTCStartDateDifferentThatLocalDate = utcStartDate !== localStartDate;
  if (isUTCStartDateDifferentThatLocalDate) {
    // offset number
    const utcOffset = moment
      .tz(`${startDate} ${startTime}`, timeZone)
      .utcOffset();
    // if time offset is behind the utc time, then we need to sum 1 day to each selected day
    if (utcOffset < 0) {
      return days.map((day) =>
        moment(day, 'ddd').add(1, 'day').format('ddd').toLowerCase()
      );
    }
    // if time offset is forward the utc time, then we need to subtract 1 day to each selected day
    else if (utcOffset > 0) {
      return days.map((day) =>
        moment(day, 'ddd').subtract(1, 'day').format('ddd').toLowerCase()
      );
    }
  }
  // send the same days without any modifications
  return days;
};
utcMethods.getUTCHoursOnLocalTimeZone = (
  time,
  date = moment().format('YYYY-MM-DD')
) => {
  const utcHoursLocalTimezone = moment
    .utc(`${date} ${time}`)
    .local()
    .format('HH:mm');

  return utcHoursLocalTimezone;
};
export const { getUTCHoursOnLocalTimeZone } = utcMethods;

export default getUTCDays;
