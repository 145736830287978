import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';
import classNames from 'classnames';
import _ from 'lodash';

// Atom
import ErrorLabel from '../atoms/ErrorLabel';
// Molecule
import EspMetaVariables from './EspMetaVariables';

class FormInputTextarea extends PureComponent {
  static propTypes = {
    /**
     * If chartLimit is passed and > 0 , FormInputTextarea will display a chart count witht the limit set from this prop
     */
    charLimit: PropTypes.number,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    forceFocus: PropTypes.bool,
    formError: PropTypes.string,
    hideLabel: PropTypes.bool,
    icon: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
      active: PropTypes.bool,
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    removeErrorHandler: PropTypes.func,
    required: PropTypes.bool,
    rows: PropTypes.number,
    showErrorsWithoutSubmitting: PropTypes.bool,
    synchroError: PropTypes.bool,
    // transparent                 : PropTypes.bool,
    widths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    charLimit: null,
    className: null,
    defaultValue: null,
    disabled: false,
    forceFocus: false,
    formError: '',
    hideLabel: false,
    icon: null,
    inline: false,
    label: '',
    meta: {},
    onChange: null,
    onClick: _.noop,
    placeholder: '',
    readOnly: false,
    removeErrorHandler: null,
    required: false,
    rows: 3,
    showErrorsWithoutSubmitting: false,
    synchroError: false,
    // transparent                 : false,
    widths: null,
  };

  fieldRef = null;

  handlerRef = (ref) => {
    const { forceFocus } = this.props;
    if (!this.fieldRef && forceFocus) {
      this.fieldRef = ref;
      ref.focus();
    }
  };

  handleFocus = (event) => {
    const { input } = this.props;
    if (input.onFocus) {
      input.onFocus(event);
    }
  };

  handleChange = (event, data) => {
    const { removeErrorHandler, onChange, input } = this.props;
    removeErrorHandler && removeErrorHandler();
    if (onChange) {
      onChange(event, data);
    } else {
      input.onChange(event, data.value);
    }
  };

  render() {
    const {
      className,
      defaultValue,
      disabled,
      formError,
      hideLabel,
      icon,
      inline,
      input,
      label,
      placeholder,
      readOnly,
      required,
      rows,
      showErrorsWithoutSubmitting,
      synchroError,
      // transparent,
      widths,
      meta: { active, error, submitFailed },
      onClick,
      charLimit,
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    const hasCharCounter = _.isNumber(charLimit) && charLimit > 0 && !readOnly;
    const textAreaStyle = {};
    let charLength;

    if (hasCharCounter) {
      charLength = input.value ? input.value.length : 0;
      textAreaStyle.paddingBottom = '30px';
    }

    return (
      <Form.Field
        className={classNames({
          required: required,
          transparent: readOnly,
        })}
        inline={inline}
      >
        {!hideLabel && (
          <label htmlFor={input.name}>
            <EspMetaVariables text={label} />
          </label>
        )}
        <TextArea
          {...input}
          className={className}
          disabled={disabled}
          icon={icon}
          onChange={this.handleChange}
          onClick={onClick}
          onFocus={this.handleFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
          style={textAreaStyle}
          widths={widths}
        />

        {hasCharCounter && (
          <div className='text-right char-counter padding-right'>
            {charLength}
            {'/'}
            {charLimit}
          </div>
        )}
        {(formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )) ||
          (showErrorsWithoutSubmitting && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (!active && submitFailed && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (synchroError && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          ))}
      </Form.Field>
    );
  }
}

export default FormInputTextarea;
