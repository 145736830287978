const api_authentication_v0_1_okta_tenant = {
  results: [
    {
      admin_email: '@espressive.com',
      admin_firstname: 'roberto',
      admin_lastname: 'francisco',
      admin_password: 'HLC2/q1HUM63IxDaXfokrn6HGU9bQC1m56V7MNLXLnA=',
      company_name: 'Test Company 4',
      enable_saml: true,
      id: 3,
      is_production_tenant: false,
      okta_subdomain: 'espressiveSubDomain',
      security_answer: 'Yfkw95dfg4w5F4wj75L',
      security_question: 'Your first pet',
      url: 'http://tenant1.esp/api/authentication/okta_tenant/3/',
    },
  ],
};

export default api_authentication_v0_1_okta_tenant;
