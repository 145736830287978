import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Field } from 'redux-form/immutable';
import _ from 'lodash';

// Molecules
import FormInputSelect from '../../molecules/FormInputSelect';
// Globals
import TypeSoftwareUtilization, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSoftwareUtilization';
// Controller
import SoftwareUtilizationController from '../../controllers/SoftwareUtilizationController';

let SoftwareUtilization01 = class SoftwareUtilization01 extends Component {
  static propTypes = {
    ...TypeSoftwareUtilization,
    initialize: PropTypes.func,
    softwares: ImmutablePropTypes.list,
    submitEquipment: PropTypes.func,
    utilizationFrequencies: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        text: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
        value: PropTypes.any,
      })
    ).isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    initialize: _.noop,
    softwares: Immutable.list,
    submitEquipment: _.noop,
  };

  componentDidMount() {
    const { initialize, setAlternateSubmit } = this.props;
    initialize();

    setAlternateSubmit(this.handleSubmit);
  }

  handleSubmit = (onMoveNextWorkflowTask) => {
    const { submitEquipment, softwares } = this.props;
    submitEquipment(softwares);
    onMoveNextWorkflowTask();
  };

  render() {
    const { softwares, template, type, utilizationFrequencies } = this.props;
    const renderSoftwares = () =>
      softwares.map((sw) => (
        <Field
          component={FormInputSelect}
          fluid
          key={sw.get('id')}
          label={sw.get('product_name')}
          name={String(sw.get('id'))}
          options={utilizationFrequencies}
          placeholder='Usage frequency'
          selectOnBlur={false}
          selection
        />
      ));

    return (
      <div
        className='block esp-software-utilization'
        data-blocktype={type}
        data-template={template}
      >
        {softwares && renderSoftwares()}
      </div>
    );
  }
};

SoftwareUtilization01 = SoftwareUtilizationController(SoftwareUtilization01);

export default SoftwareUtilization01;
