// Stores
import store from '../stores/store';

// Selectors
import getCurrentUser from '../selectors/getCurrentUser';
// Actions
import sessionActions from '../actions/sessionActions';

// Utils
import browserHistory from './browserHistory';
import uiPathGenerator from './uiPathGenerator';
import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';

const PARAM_OTC = 'otc';
const PARAM_VERSION = 'chat_version';

const validateLoginRequired = () => {
  const { location } = browserHistory;
  const currentUser = getCurrentUser(store.getState());

  let nextUrl = location.pathname;
  nextUrl = nextUrl.includes('/login') ? nextUrl.split('/login')[0] : nextUrl;
  nextUrl = nextUrl === uiPathGenerator.genPath('app.toDo') ? '' : nextUrl; // if it's trying to access home, don't store it as it's the default path
  // If Query param was passed to the URL
  if (location.search) {
    nextUrl += location.search;
  }

  const session = persistentStorage.get(PersistentStorageKeys.SESSION);

  // App is in iframe when running as a widget
  const isIframeWidget = window.self !== window.top;

  const url = new URL(window.location.href);
  const isChatV2 = url.searchParams.get(PARAM_VERSION) === '2';
  const otc = url.searchParams.get(PARAM_OTC);

  if (!session || !session.accessToken) {
    if (isIframeWidget) {
      const widgetAuthRequiredPath =
        uiPathGenerator.genPath('widgetAuthRequired');

      const path = `${widgetAuthRequiredPath}`;
      const urlSearchParam = new URLSearchParams();

      if (otc) {
        urlSearchParam.set(PARAM_OTC, otc);
      }
      if (isChatV2) {
        urlSearchParam.set(PARAM_VERSION, '2');
      }

      browserHistory.replace(`${path}?${urlSearchParam.toString()}`);
      return true;
    }

    persistentStorage.put(PersistentStorageKeys.SESSION, {
      // as per T8387, we need to keep this in localStorage
      previousUrl: nextUrl,
    });

    store.dispatch(sessionActions.userSetPreviousUrl(nextUrl));

    const query = new URLSearchParams(location.search ?? '');
    if (query.has('localAuth')) {
      // DEV-24295 preserve local auth method
      browserHistory.replace(
        uiPathGenerator.genPath('login') + location.search
      );
    } else {
      // Log them out always to login
      browserHistory.replace(uiPathGenerator.genPath('login'));
    }

    return true;
  }

  // Usually a user shouldn't even be able to login if it's inactive
  // But some cases might make this happen:
  // 1. Login via okta
  // 2. User is separated while he was using the app
  if (currentUser && !currentUser.get('is_active')) {
    store.dispatch(
      sessionActions.userAuthFailure(
        'Unable to login with the credentials provided'
      )
    );
    persistentStorage.remove(PersistentStorageKeys.SESSION); // We should remove this user local storage, at least for current user
    browserHistory.replace(uiPathGenerator.genPath('auth.inactive'));
  }

  return false;
};

export default validateLoginRequired;
