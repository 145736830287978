import React, { useCallback, useRef, useState } from 'react';
import pt from 'prop-types';
import classnames from 'classnames/bind';
import { ChatProvider } from '@espressive/cascara/private';
import { Chat as CascaraChat } from '@espressive/cascara';
import { getCascaraMessagesFromAPI } from '../../normalizers';
import { useChannelUsers } from '../../hooks';
import ChatForm from '../ChatForm';
import styles from './Chat.module.scss';

const cx = classnames.bind(styles);

const propTypes = {
  className: pt.string,
  isThemeSelectable: pt.bool,
  messageData: pt.arrayOf(pt.object),
  onSubmit: pt.func,
  userIDs: pt.arrayOf(pt.number),
};

const Chat = ({
  className,
  isThemeSelectable = false,
  messageData,
  onSubmit,
  userIDs,
  ...rest
}) => {
  const chatRef = useRef();
  const [chatMessageHeight, setChatMessageHeight] = useState();
  const usersList = useChannelUsers(userIDs);

  // This function will set the new height for chat manually when the Form height changes
  const handleHeightChange = useCallback(
    // Currently this only changes the height but we should also consider
    // scrolling the chat message div however high the form has changed
    // from last height. It would be bad UX to scroll it to the bottom
    // because someone may be typing a message relative to a message in
    // the conversation and scrolling to bottom would force them to scroll
    // back to the message they are responding to.
    (formHeight) =>
      setChatMessageHeight(
        chatRef?.current?.clientHeight - formHeight - 16 * 2
      ),
    []
  );

  const chatClassNames = cx(className, { _: true });

  return (
    <div {...rest} className={chatClassNames} ref={chatRef}>
      <div
        className={styles.ChatMessages}
        style={{ height: `${chatMessageHeight}px` }}
      >
        <ChatProvider isThemeSelectable={isThemeSelectable}>
          <CascaraChat
            messages={messageData && getCascaraMessagesFromAPI(messageData)}
            users={usersList}
          />
        </ChatProvider>
      </div>
      <ChatForm
        className={styles.ChatForm}
        onHeightChange={handleHeightChange}
        onSubmit={onSubmit}
      />
    </div>
  );
};

Chat.propTypes = propTypes;

export default Chat;
