import _APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';

const postConfig = async (data, method) => {
  const url = endpointGenerator.genPath('espConfig');

  return _APIcall[method]({
    data,
    token: true,
    url: url,
  });
};

export default postConfig;
