import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesEnvelopes extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M22.442 25.363c-3.603 0-6.572 2.97-6.572 6.572v32.13c0 3.603 2.97 6.572 6.572 6.572h51.116c3.603 0 6.572-2.97 6.572-6.572v-32.13c0-3.603-2.97-6.572-6.572-6.572H22.442zm.64 4.38h49.837L49.71 49.416c-1.12.95-2.35.95-3.467 0L23.08 29.745zM20.25 33.1l23.16 19.646c2.626 2.228 6.504 2.224 9.13 0L75.747 33.1v30.965c0 1.25-.94 2.19-2.19 2.19H22.442c-1.25 0-2.19-.94-2.19-2.19V33.1z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesEnvelopes;
