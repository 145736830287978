import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreJackets extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M37.68 65.19c.552 0 1-.448 1-1V53.57c0-.552-.448-1-1-1s-1 .448-1 1v10.61c-.003.267.102.524.29.714.187.19.443.296.71.296z' />
          <path d='M77.16 32.51c.003-.04.003-.08 0-.12-1.27-11.96-9.37-13.85-10.66-14.44l-2.52-.73-3.56-.84c-8.33-1.686-16.904-1.808-25.28-.36-.062.017-.122.04-.18.07h-.14l-3.8.84c-.12 0-12 2.56-13 15.62l.57 32.91v.08l.91 10.95c.15 1.266 1.206 2.23 2.48 2.26h4c1.284-.024 2.357-.986 2.52-2.26l1.38-8.58v3.33c.12 2.353 1.73 4.367 4 5h.13l9.4 1.25c1.32.21 2.672-.117 3.75-.91l.11-.12.19-.37c0-.2.06-.39.11-.59V26.59c-.004-.033-.004-.067 0-.1-.135-.206-.326-.37-.55-.47-.908-.01-1.813-.115-2.7-.31-3.9-1-6.63-5.72-7.71-7.89 7.457-1.132 15.05-1.02 22.47.33-2.42 6.6-7.41 7.51-7.71 7.6-.77.163-1.553.26-2.34.29-.09.027-.173.068-.25.12-.1.165-.174.343-.22.53v49.65l-.08.29c-.105.196-.195.4-.27.61v.12c-.048.147-.085.297-.11.45v.72c-.067.577.204 1.142.695 1.45.492.31 1.118.31 1.61 0 .49-.308.762-.873.695-1.45v-1h.44c.25.004.502-.013.75-.05l9.45-1.26h.13c2.268-.636 3.877-2.648 4-5v-1.23l1 6.5c.148 1.2 1.107 2.138 2.31 2.26h4c1.274-.026 2.336-.985 2.49-2.25l.91-10.44v-.23l.58-33.32zm-33.44-4.84c.395.097.796.17 1.2.22v47.58c-.407.07-.823.07-1.23 0l-9.39-1.21c-1.345-.44-2.292-1.65-2.4-3.06V42.91c0-8.9-5.42-18.81-6.62-20.88 1.813-1.494 3.94-2.56 6.22-3.12l3-.67c1 2.18 4.17 8.11 9.22 9.43zM49.5 79.3c-.332 0-.63-.2-.758-.506-.127-.307-.056-.66.178-.894.235-.234.587-.305.894-.178.306.127.506.426.506.758 0 .453-.367.82-.82.82zm14.18-36.39v28.25c-.09 1.43-1.04 2.66-2.4 3.11l-9.43 1.26c-.292.033-.588.033-.88 0V27.84c.28 0 .55-.09.79-.15s6.52-1.09 9.22-9.1l3.4.83c2.123.586 4.115 1.57 5.87 2.9-1.1 1.87-6.57 11.6-6.57 20.59z' />
          <path d='M44.68 19.84h6.36v3.54h-6.36zm13.36 32.72c-.552 0-1 .448-1 1v10.62c0 .552.448 1 1 1s1-.448 1-1V53.57c.003-.267-.102-.524-.29-.714-.187-.19-.443-.296-.71-.296z' />
        </g>
      </svg>
    );
  }
}

export default CatalogCompanyStoreJackets;
