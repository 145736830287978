import { createSelector } from 'reselect';
import { mimeTypeList, UserAgentUtils } from 'esp-globals';

const getExtensionsList = (state) =>
  state.getIn(['tenant', 'entity', 'allowedExtensions']);

const getAllowedExtensions = (extensionsList) => {
  if (!extensionsList) {
    return null;
  }

  const isIOS = UserAgentUtils.isCordova() && !UserAgentUtils.isAndroidApp();

  return extensionsList
    .filter((ext) => (isIOS ? true : Boolean(mimeTypeList[ext])))
    .map((ext) => (isIOS ? ext : mimeTypeList[ext]))
    .toJS()
    .join(', ');
};

const selector = createSelector([getExtensionsList], getAllowedExtensions);

export default selector;
