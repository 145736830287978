import actionTypes from './actionTypes';

const onboardActivityActions = {};

onboardActivityActions.loadScheduleActivityStart = () => ({
  type: actionTypes.LOAD_ONBOARD_SCHEDULE_START,
});

onboardActivityActions.loadScheduleActivityEnd = () => ({
  type: actionTypes.LOAD_ONBOARD_SCHEDULE_END,
});

onboardActivityActions.loadScheduleActivityListStart = () => ({
  type: actionTypes.LOAD_ONBOARD_SCHEDULE_LIST_START,
});

onboardActivityActions.loadScheduleActivityListSuccess = (
  schedules,
  count
) => ({
  count,
  schedules,
  type: actionTypes.LOAD_ONBOARD_SCHEDULE_LIST_SUCCESS,
});

onboardActivityActions.loadScheduleOneActivityListSuccess = (schedule) => ({
  schedule,
  type: actionTypes.LOAD_ONBOARD_ONE_SCHEDULE_SUCCESS,
});

onboardActivityActions.loadScheduleActivityListFail = () => ({
  type: actionTypes.LOAD_ONBOARD_SCHEDULE_LIST_FAIL,
});

onboardActivityActions.loadTemplateActivityListStart = () => ({
  type: actionTypes.LOAD_ONBOARD_TEMPLATE_LIST_START,
});

onboardActivityActions.loadTemplateActivityListSuccess = (
  templates,
  keepCurrent
) => ({
  keepCurrent,
  templates,
  type: actionTypes.LOAD_ONBOARD_TEMPLATE_LIST_SUCCESS,
});

onboardActivityActions.addTemplateActivityListSuccess = (templates, next) => ({
  next,
  templates,
  type: actionTypes.ADD_ONBOARD_TEMPLATE_LIST_SUCCESS,
});

onboardActivityActions.changeStatusTemplateActivityListSuccess = (
  templateID,
  status
) => ({
  status,
  templateID,
  type: actionTypes.CHANGE_STATUS_ONBOARD_TEMPLATE,
});

onboardActivityActions.loadTemplateActivityListFail = () => ({
  type: actionTypes.LOAD_ONBOARD_TEMPLATE_LIST_FAIL,
});

onboardActivityActions.saveScheduleStart = () => ({
  type: actionTypes.SAVE_ONBOARD_SCHEDULE_START,
});

onboardActivityActions.saveScheduleSuccess = (schedule, stillLoading) => ({
  schedule,
  stillLoading,
  type: actionTypes.SAVE_ONBOARD_SCHEDULE_SUCCESS,
});

onboardActivityActions.saveScheduleFail = () => ({
  type: actionTypes.SAVE_ONBOARD_SCHEDULE_FAIL,
});

onboardActivityActions.setCategory = (category) => ({
  category,
  type: actionTypes.SET_TODO_TEMPLATE_CATEGORY,
});

onboardActivityActions.deleteScheduleActivityStart = (deletedID) => ({
  deletedID,
  type: actionTypes.DELETE_ONBOARD_SCHEDULE_START,
});

onboardActivityActions.deleteScheduleActivityFail = () => ({
  type: actionTypes.DELETE_ONBOARD_SCHEDULE_FAIL,
});

onboardActivityActions.deleteScheduleActivitySuccess = (scheduleID) => ({
  scheduleID,
  type: actionTypes.DELETE_ONBOARD_SCHEDULE_SUCCESS,
});

onboardActivityActions.loadTemplateInterval = (templateSelected) => ({
  templateSelected,
  type: actionTypes.LOAD_TEMPLATE_INTERVAL_SELECTED,
});

onboardActivityActions.updateTemplateSelectedscheduleToTemplate = (
  id,
  intervalID
) => ({
  id,
  intervalID,
  type: actionTypes.UPDATE_TEMPLATE_SCHEDULE,
});

onboardActivityActions.updateTemplateSelectedInterval = (
  interval,
  intervalID,
  templateID
) => ({
  interval,
  intervalID,
  templateID,
  type: actionTypes.UPDATE_TEMPLATE_INTERVAL,
});

onboardActivityActions.updateTemplateSelectedScheduleToTemplate = (
  scheduleToTemplate,
  intervalID
) => ({
  intervalID,
  scheduleToTemplate,
  type: actionTypes.UPDATE_TEMPLATE_SCHEDULE_TO_TEMPLATE,
});

onboardActivityActions.deleteTemplateSelectedInterval = (
  intervalID,
  templateID
) => ({
  intervalID,
  templateID,
  type: actionTypes.DELETE_TEMPLATE_INTERVAL_SELECTED,
});

onboardActivityActions.createActivityIntervalStart = () => ({
  type: actionTypes.ADD_NEW_TEMPLATE_INTERVAL_START,
});

onboardActivityActions.createActivityIntervalFail = () => ({
  type: actionTypes.ADD_NEW_TEMPLATE_INTERVAL_FAIL,
});

onboardActivityActions.createActivityIntervalSuccess = (templateSelected) => ({
  templateSelected,
  type: actionTypes.ADD_NEW_TEMPLATE_INTERVAL_SUCCESS,
});

onboardActivityActions.resetTemplateInterval = () => ({
  type: actionTypes.RESET_TEMPLATE_INTERVAL_SELECTED,
});

onboardActivityActions.updateGroupStart = () => ({
  type: actionTypes.SCHEDULE_UPDATE_GROUP_START,
});

onboardActivityActions.updateGroupSavedSuccess = (group) => ({
  group,
  type: actionTypes.SCHEDULE_SAVE_GROUP_SUCCESS,
});

onboardActivityActions.updateGroupFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.SCHEDULE_UPDATE_GROUP_FAIL,
});

onboardActivityActions.updateGroupAddedSuccess = (group) => ({
  group,
  type: actionTypes.SCHEDULE_ADDED_GROUP_SUCCESS,
});

onboardActivityActions.updateGroupDeletedSuccess = (groupId, scheduleId) => ({
  groupId,
  scheduleId,
  type: actionTypes.SCHEDULE_DELETED_GROUP_SUCCESS,
});

onboardActivityActions.saveStatusSuccess = (scheduleId, status) => ({
  scheduleId,
  status,
  type: actionTypes.SAVE_SCHEDULE_STATUS_SUCCESS,
});

onboardActivityActions.setSearchScheduleTerm = (term) => ({
  term,
  type: actionTypes.SET_TERM_SEARCH_SCHEDULE_ACTIVITY,
});

onboardActivityActions.setIsSearchingScheduleTerm = () => ({
  type: actionTypes.SET_IS_SEARCHING_SCHEDULE,
});

//
//
// Performance Actions
//
//

onboardActivityActions.loadPerformanceListStart = () => ({
  type: actionTypes.LOAD_ONBOARD_PERFORMANCE_LIST_START,
});

onboardActivityActions.loadPerformanceListSuccess = (performances, count) => ({
  count,
  performances,
  type: actionTypes.LOAD_ONBOARD_PERFORMANCE_LIST_SUCCESS,
});

onboardActivityActions.loadPerformanceListFail = () => ({
  type: actionTypes.LOAD_ONBOARD_PERFORMANCE_LIST_FAIL,
});

onboardActivityActions.loadPerformanceDetailStart = () => ({
  type: actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_START,
});

onboardActivityActions.loadPerformanceDetailSuccess = (
  performances,
  userID
) => ({
  performances,
  type: actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_SUCCESS,
  userID,
});

onboardActivityActions.loadPerformanceDetailFail = () => ({
  type: actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_FAIL,
});

onboardActivityActions.setSearchPerformanceTerm = (term) => ({
  term,
  type: actionTypes.SET_TERM_SEARCH_PERFORMANCE_ACTIVITY,
});

onboardActivityActions.setIsSearchingPerformanceTerm = () => ({
  type: actionTypes.SET_IS_SEARCHING_PERFORMANCE,
});

onboardActivityActions.deletePerformanceStart = (performanceSummaryID) => ({
  performanceSummaryID,
  type: actionTypes.DELETE_ONBOARD_PERFORMANCE_SUMMARY_START,
});

onboardActivityActions.deletePerformanceFail = () => ({
  type: actionTypes.DELETE_ONBOARD_PERFORMANCE_SUMMARY_FAIL,
});

onboardActivityActions.deletePerformanceSuccess = (performanceSummaryID) => ({
  performanceSummaryID,
  type: actionTypes.DELETE_ONBOARD_PERFORMANCE_SUMMARY_SUCCESS,
});

onboardActivityActions.escalateProgressStart = (userID) => ({
  type: actionTypes.ESCALATE_PROGRESS_START,
  userID,
});

onboardActivityActions.escalateProgressSuccess = (response) => ({
  response,
  type: actionTypes.ESCALATE_PROGRESS_SUCCESS,
});

onboardActivityActions.escalateProgressFail = (error) => ({
  error,
  type: actionTypes.ESCALATE_PROGRESS_FAIL,
});

export default onboardActivityActions;
