import Immutable, { fromJS, List, Map } from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';

const isDoubleBracketEsp = new RegExp(/^\[\[(.*?)\]\]$/); // Begins with [[ ends with ]]

class BlobAttributesParser {
  constructor(attributes) {
    this.attributes = fromJS({
      attributes,
    });
  }

  attrMapToPathArray(attrMap) {
    const regex = /\['(\d+)'\]/g;
    const normalizedAttrMap = attrMap.replace(regex, '.$1');
    const normalizedAttrMapPath = normalizedAttrMap.split('.');
    return normalizedAttrMapPath;
  }

  /**
   * @param values Immutable.Map blockId -> value
   */
  updateFromBlockValues(blocks, values) {
    // use brand new Map instead this.attributes to avoid submit attributes that aren't
    // mapped in the blocks
    let attributes = Map();

    values.forEach((value, fieldID) => {
      if (value !== null) {
        // Convert to plain JS if the value is an immutable Map
        value =
          Map.isMap(value) || List.isList(value) ? value.toJS() : String(value);
      }

      // Need to check if the value was not a boolean
      value = value === 'true' || value === 'false' ? value === 'true' : value;

      const fieldIDParts = fieldID.split('-');
      const blockID = _.head(fieldIDParts);

      let childInputValueIndex = -1;

      if (_.size(fieldIDParts) > 1) {
        childInputValueIndex = _.last(fieldIDParts);
        childInputValueIndex = Number(childInputValueIndex);
      }

      // console.log(blockID, childInputValueIndex);

      // find the block from where this value came from
      const block = blocks.find((b) => String(b.get('id')) === blockID);

      // ups! no block found
      if (!block) {
        return;
      }

      let inputValue = block.get('inputValue');
      if (childInputValueIndex !== -1) {
        // This block has a composite inputValue
        inputValue = inputValue.get(childInputValueIndex);
      }

      const attrMap = inputValue.get('map');
      const attrValue = inputValue.get('value');

      if (_.isEmpty(attrMap)) {
        // this field is not mapped to any attribute, lets ignore it
        return;
      }

      const normalizedAttrMapPath = this.attrMapToPathArray(attrMap);

      // console.log(`Setting ${normalizedAttrMapPath.join('.')} to ${value} from block ${blockId}`);

      // Don't save the value if it's the same than the attrValue (not the map) set via the blobifier
      const finalValue = value === attrValue ? null : value;
      attributes = attributes.setIn(normalizedAttrMapPath, finalValue);
    });

    return attributes;
  }

  recursivelyGetInitialValues(inputValue, fieldID) {
    let initialValues = Map();
    if (Map.isMap(inputValue)) {
      const attrMap = inputValue.get('map', '');
      const attrMapDefault = inputValue.get('mapDefault', '');
      const attrValue = inputValue.get('value', '');

      // this block is not mapped to an attribute
      if (_.isEmpty(attrMap) && _.isEmpty(attrValue)) {
        return initialValues;
      }

      let value = attrValue;

      if (attrMap) {
        const normalizedAttrMapPath = this.attrMapToPathArray(attrMap);
        value = this.attributes.getIn(normalizedAttrMapPath);

        if (!value && attrValue) {
          value = attrValue;
        }

        if (!value && attrMapDefault) {
          const normalizedAttrMapPath = this.attrMapToPathArray(attrMapDefault);
          const mapDefaultValue = this.attributes.getIn(normalizedAttrMapPath);
          value = mapDefaultValue || value;
        }
      }

      // console.log(`Reading ${normalizedAttrMapPath.join('.')} with value ${value} for block ${blockId}`);
      return initialValues.set(fieldID, value);
    } else if (List.isList(inputValue)) {
      // This is a composite inputValue, that is, is made of nested inputValue's
      inputValue.forEach((childInputValue, i) => {
        const childFieldID = `${fieldID}-${i}`;

        initialValues = initialValues.merge(
          this.recursivelyGetInitialValues(childInputValue, childFieldID)
        );
      });

      return initialValues;
    } else {
      return initialValues;
    }
  }

  recursivelyLabelValues(inputValue) {
    if (Map.isMap(inputValue)) {
      const attrMap = inputValue.get('map', '');

      // this block is not mapped to an attribute
      if (_.isEmpty(attrMap)) {
        return null;
      }

      const normalizedAttrMapPath = this.attrMapToPathArray(attrMap);

      const labelName = this.attributes.getIn([
        normalizedAttrMapPath[0],
        normalizedAttrMapPath[1],
        'label',
      ]);

      return labelName;
    }
    return null;
  }

  /**
   * @param blocks Immutable.List of blocks
   */
  getInitialValues = createSelector(
    [
      // this one is just a helper function to pass the parameters to the selector
      (blocks) => blocks,
    ],
    // selector result function definition
    (blocks) => {
      let initialValues = Map();

      blocks.forEach((block) => {
        const blockID = block.get('id');
        const inputValue = block.get('inputValue');

        // should we skip adding an initial value for this block?
        const isInitializeDisabled = block.get('disableInitializeInput', false);

        if (!isInitializeDisabled) {
          initialValues = initialValues.merge(
            this.recursivelyGetInitialValues(inputValue, blockID)
          );
        }
      });
      return initialValues;
    }
  );

  /**
   * @param blocks Immutable.List of blocks
   */
  setLabelName(blocks) {
    let newBlocks = List();

    blocks.forEach((block) => {
      const inputValue = block.get('inputValue');

      if (inputValue) {
        const labelName = this.recursivelyLabelValues(inputValue);

        if (labelName) {
          block = block.set('attributeLabel', labelName);
        }
      }

      newBlocks = newBlocks.push(block);
    });

    return newBlocks;
  }

  /**
   * @param blocks Immutable.List of blocks
   */
  setErrorLabelName = createSelector(
    [
      // these are just two helper functions to pass the two parameters to the selector
      (blocks) => blocks,
      (blocks, error) => error,
    ],
    // selector result function definition
    (blocks, error) => {
      let newError = error;

      blocks.forEach((block) => {
        const inputValue = block.get('inputValue');
        const blockId = block.get('id');

        if (inputValue) {
          const labelName = this.recursivelyLabelValues(inputValue);

          if (labelName) {
            // Search in the Error list if one parameterName match this labelName
            newError = newError.map((err) => {
              if (err.get('parameterName') === labelName) {
                return err.set('parameterName', blockId);
              }
              return err;
            });
          }
        }
      });

      return newError;
    }
  );

  /**
   * Replace attribute references in String with actual values.
   * @param s A String.
   */
  evalAttrsInString(s) {
    const tokens = ['\\w', '\\[', '\\]', "'", '"', '\\.'];

    const pipeSeparatedTokens = tokens.join('|');

    // TODO Add support for text filter, like "| lowercase"
    /**
     * Replace {{attributes[X].attribute_value}} byt the value saved
     * @param str {string}
     * @returns {*}
     */
    const evaluateAttribute = (str) => {
      // remove {{ at the start and }} at the end
      const attrMap = str.slice(2, str.length - 2);
      const normalizedAttrMapPath = this.attrMapToPathArray(attrMap);

      return this.attributes.getIn(normalizedAttrMapPath);
    };

    // look for {{...}} matches
    const regex = new RegExp(`{{(${pipeSeparatedTokens})+}}`, 'g');
    let evaluated = s;

    if (s.replace(regex, '').length) {
      // We match {{attributes[X].attribute_value}} in the middle of a sentence
      evaluated = s.replace(regex, evaluateAttribute);
    } else {
      // The string passed match only {{attributes[X].attribute_value}}
      evaluated = evaluateAttribute(s);
    }

    // Since we can return null or a string or a number, if not null we want to return a string to avoid props issues
    if (evaluated) {
      evaluated = String(evaluated);
    }
    return evaluated;
  }

  /**
   * @param node Can be Immutable.List, Immutable.Map or primitive value.
   */
  recursivelyEvalAttrs(node) {
    if (_.isString(node)) {
      return this.evalAttrsInString(node);
    } else if (List.isList(node) || Map.isMap(node)) {
      return node.map((child) => this.recursivelyEvalAttrs(child));
    } else {
      // leave primitive value as is
      return node;
    }
  }

  recursivelyEvalTranslations(node, stringsIntlMap) {
    if (_.isString(node)) {
      if (isDoubleBracketEsp.test(node)) {
        // if it's a translation (enclosed as double bracket)
        node = this.replaceTranslationInString(node, stringsIntlMap);
      }
      return node;
    } else if (List.isList(node) || Map.isMap(node)) {
      return node.map((child) =>
        this.recursivelyEvalTranslations(child, stringsIntlMap)
      );
    } else {
      // leave primitive value as is
      return node;
    }
  }

  replaceTranslationInString(text, stringsIntlMap) {
    const match = isDoubleBracketEsp.exec(text);

    const [, intlKey] = match;
    let res = intlKey;
    if (stringsIntlMap && stringsIntlMap.has(intlKey)) {
      res = stringsIntlMap.get(intlKey);
    }
    // console.log(`We translated ${text} into ${res} `);

    return res;
  }

  /**
   * Deep evaluate attribute references in blob blocks.
   * @param blocks Immutable.List of blocks.
   */
  evalBlocksAttrs(blocks) {
    const blocksEval = this.recursivelyEvalAttrs(blocks);

    return this.setLabelName(blocksEval);
  }

  setupBlob = createSelector(
    [
      // these are just two helper functions to pass the two parameters to the selector
      (workflowState) => workflowState,
      (workflowState, locale) => locale,
    ],
    // selector result function definition
    (workflowState, locale = '') => {
      // get the Front-end blob part
      let blob = workflowState.get('blob');

      // ToDO: should we remove this condition now that we're not gonna use it?
      if (locale) {
        // translate the contents of the blob if needed
        const intl = workflowState.getIn(['blob', 'intl']); // the place where the translations are coming should change depending on DEV-2265
        blob = this.translateBlob(blob, intl, locale);
      }

      if (workflowState.get('frontendIntl')) {
        blob = this.recursivelyEvalTranslations(
          blob,
          workflowState.get('frontendIntl')
        );
      }

      // get the blocks
      let blocks = blob.get('blocks');

      // first eval attribuutes
      blocks = this.recursivelyEvalAttrs(blocks);
      // then set labels
      blocks = this.setLabelName(blocks);

      blob = blob.set('blocks', blocks);

      return blob;
    }
  );

  /**
   * Translates blocks by mergging from a source
   * @param originalBlob Immutable.List of blocks.
   */
  translateBlob(originalBlob, intl = null, selectedLocale) {
    // First, merge the translations if available

    if (intl && Immutable.Map.isMap(intl)) {
      // passing these to JS
      // as immutableJS has issues deep merging objects
      const intlJS = intl.toJS();
      const blobJS = originalBlob.toJS();

      // getting the locale if exists
      if (_.hasIn(intlJS, selectedLocale)) {
        const currentIntl = intlJS[selectedLocale];

        // This is for blob.blocks
        if (_.isArray(blobJS.blocks) && _.isArray(currentIntl.blocks)) {
          // merge the two arrays
          blobJS.blocks = _.merge([], blobJS.blocks, currentIntl.blocks);
        }

        // This is for blob.nav
        if (_.isObject(blobJS.nav) && _.isObject(currentIntl.nav)) {
          // merge the two objects
          blobJS.nav = _.merge({}, blobJS.nav, currentIntl.nav);
        }
      }

      return fromJS(blobJS);
    }

    return originalBlob;
  }
}

export default BlobAttributesParser;
