import { connect } from 'react-redux';
// Utils
import HelloActions from '../../../utils/HelloActions';
// Selectors
import getCurrentUser from '../../../selectors/getCurrentUser';
// Redux Action
import baristaActions from '../../../actions/baristaActions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    currentUser,
    isBaristaOpened: state.getIn(['appUI', 'isBaristaOpened']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  /**
   * Actions to be performed when this component mounts
   */
  doPortalHelloActions() {
    HelloActions.portalHello(dispatch);
  },

  reOpenBarista() {
    dispatch(baristaActions.openSupportChannel());
  },
});

const Controller = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
export default Controller;
