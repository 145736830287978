import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

// Molecules
import BundleBaristaPhrase from '../molecules/BundleBaristaPhrase';
import MessageEmptyState from '../molecules/MessageEmptyState';
import SelectMyGearProductStep from '../molecules/SelectMyGearProductStep';
import SelectMyGearProductFamilyStep from '../molecules/SelectMyGearProductFamilyStep';
import SelectMyGearBundleOneStep from '../molecules/SelectMyGearBundleOneStep';
import SelectMyGearBundleAnyStep from '../molecules/SelectMyGearBundleAnyStep';
import SelectMyGearBundleAllStep from '../molecules/SelectMyGearBundleAllStep';

// Controllers
import SelectMyGearController from '../controllers/SelectMyGearController';

// Globals
import { BundleSelectValues } from '../../globals/BundleSelectOptions';
import SelectMyGearStepTypes from '../../globals/SelectMyGearStepTypes';

class SelectMyGear extends PureComponent {
  static propTypes = {
    // from Redux Form
    currentStep: ImmutablePropTypes.map,
    getSteps: PropTypes.func,
    input: PropTypes.shape({
      onChange: PropTypes.func,
    }).isRequired,
    isLoadingSteps: PropTypes.bool,
    onAddProduct: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    roleBundleID: PropTypes.number.isRequired,
  };

  static defaultProps = {
    currentStep: null,
    getSteps: _.noop,
    isLoadingSteps: false,
    onAddProduct: _.noop,
    onRemoveProduct: _.noop,
  };

  componentDidMount() {
    const { roleBundleID, getSteps } = this.props;

    getSteps(roleBundleID);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentStep, input } = this.props;

    const nextStep = nextProps.currentStep;

    // since we are using Immutable values we are good doing shallow comparison to detect a change!
    const changed = currentStep !== nextStep;

    if (changed && nextStep) {
      const isValid = this.validate(nextStep);

      // tell Redux Form if we are in a valid state or no
      input.onChange(isValid);
    }
  }

  /**
   * Returs true if 'step' is valid, otherwise returns false.
   */
  validate(step) {
    if (step.get('type') === SelectMyGearStepTypes.PRODUCT) {
      // it's a single product and by default it's selected, we are good
      return true;
    } else if (step.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
      // it's valid if there is a selected configuration option
      const items = step.get('items');

      const hasSelectedConfigurationOption = items.some((configurationOption) =>
        configurationOption.get('isSelected')
      );

      return hasSelectedConfigurationOption;
    } else {
      // it's a product bundle

      const selects = step.get('selects');

      if (selects === BundleSelectValues.SELECT_ALL) {
        // it's valid if for every product family inside the bundle there is a selected configuration option
        const bundleChildren = step.get('items');

        const allProductFamiliesHaveSelectedConfigurationOption = bundleChildren.every(
          (bundleChild) => {
            if (
              bundleChild.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY
            ) {
              const itemsFromFamily = bundleChild.get('items');

              const hasSelectedConfigurationOption = itemsFromFamily.some(
                (configurationOption) => configurationOption.get('isSelected')
              );

              return hasSelectedConfigurationOption;
            } else {
              // it's a single product, there's no special validation for it
              return true;
            }
          }
        );

        return allProductFamiliesHaveSelectedConfigurationOption;
      } else if (selects === BundleSelectValues.SELECT_ONE) {
        // it's valid if there exits a child in the bundle that is selected
        const bundleChildren = step.get('items');

        const hasSelectedChild = bundleChildren.some((bundleChild) => {
          if (bundleChild.get('type') === SelectMyGearStepTypes.PRODUCT) {
            return bundleChild.get('isSelected');
          } else {
            // it's a product family
            const itemsFromFamily = bundleChild.get('items');

            const hasSelectedConfigurationOption = itemsFromFamily.some(
              (configurationOption) => configurationOption.get('isSelected')
            );

            return hasSelectedConfigurationOption;
          }
        });

        return hasSelectedChild;
      } else {
        // it's SELECT_ANY type, no special validation for it

        return true;
      }
    }
  }

  render() {
    const {
      currentStep,
      isLoadingSteps,
      onAddProduct,
      onRemoveProduct,
    } = this.props;

    let isProductStep = false;
    let isProductFamilyStep = false;
    let isProductBundleStep = false;

    if (currentStep) {
      isProductStep = currentStep.get('type') === SelectMyGearStepTypes.PRODUCT;
      isProductFamilyStep =
        currentStep.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY;
      isProductBundleStep =
        currentStep.get('type') === SelectMyGearStepTypes.PRODUCT_BUNDLE;
    }

    return (
      <div
        className={classNames('bundle-config', {
          'esp-catalog-detail': isProductStep || isProductFamilyStep,
        })}
      >
        {/* Keep in loading state as long as we don't have the data */}
        <BundleBaristaPhrase isLoading={!currentStep} item={currentStep} />

        {/* Notify user about data issue with permissions */}
        {!isLoadingSteps && !currentStep && (
          <MessageEmptyState
            content='This role bundle does not contain any products that match your user permissions'
            header='Please contact your manager'
            negative
          />
        )}

        {isProductStep && <SelectMyGearProductStep step={currentStep} />}

        {isProductFamilyStep && (
          <SelectMyGearProductFamilyStep
            onAddProduct={onAddProduct}
            onRemoveProduct={onRemoveProduct}
            step={currentStep}
          />
        )}

        {isProductBundleStep &&
          currentStep.get('selects') === BundleSelectValues.SELECT_ONE && (
            <SelectMyGearBundleOneStep
              onAddProduct={onAddProduct}
              onRemoveProduct={onRemoveProduct}
              step={currentStep}
            />
          )}

        {isProductBundleStep &&
          currentStep.get('selects') === BundleSelectValues.SELECT_ANY && (
            <SelectMyGearBundleAnyStep
              onAddProduct={onAddProduct}
              onRemoveProduct={onRemoveProduct}
              step={currentStep}
            />
          )}

        {isProductBundleStep &&
          currentStep.get('selects') === BundleSelectValues.SELECT_ALL && (
            <SelectMyGearBundleAllStep
              onAddProduct={onAddProduct}
              onRemoveProduct={onRemoveProduct}
              step={currentStep}
            />
          )}
      </div>
    );
  }
}

const SelectMyGearTest = SelectMyGear;

// eslint-disable-next-line no-class-assign -- DEV-1526
SelectMyGear = SelectMyGearController(SelectMyGear);
export { SelectMyGearTest };
export default SelectMyGear;
