import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';

import CartController from '../../controllers/CartController';

// Globals
import TypeButton, { TypeDefaults } from '../../../globals/blocks/TypeButton';

class SubmitCartButton01 extends Component {
  static propTypes = {
    ...TypeButton,
    cartSubmitOrder: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    ...TypeDefaults,
    loading: false,
  };

  componentDidMount() {
    const { setAlternateSubmit } = this.props;
    setAlternateSubmit(this.handleClick);
  }

  handleClick = (cb = _.noop) => {
    const { cartSubmitOrder } = this.props;

    cartSubmitOrder((error) => {
      if (!error) {
        // wait until cart gets submitted, then move forward
        cb();
      }
    });
  };

  render() {
    const { loading, template, text, type } = this.props;

    return (
      <div
        className='block padding'
        data-blocktype={type}
        data-template={template}
      >
        <Button
          color='red'
          content={text}
          loading={loading}
          onClick={this.handleClick}
          size='large'
        />
      </div>
    );
  }
}

// In use for unit test
export const SubmitCartButton = SubmitCartButton01;

// Default export with controller
export default CartController(SubmitCartButton01);
