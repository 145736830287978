import actionTypes from './actionTypes';

const onboardProgressManagerViewActions = {};

onboardProgressManagerViewActions.setFilterBy = (filterBy) => ({
  filterBy,
  type: actionTypes.ONBOARD_PROGRESS_MANAGER_VIEW_SET_FILTER_BY,
});

onboardProgressManagerViewActions.setSortBy = (sortBy) => ({
  sortBy,
  type: actionTypes.ONBOARD_PROGRESS_MANAGER_VIEW_SET_SORT_BY,
});

onboardProgressManagerViewActions.resetOptions = () => ({
  type: actionTypes.RESET_ONBOARD_PROGRESS_MANAGER_VIEW_OPTIONS,
});

onboardProgressManagerViewActions.getOnboardProgressManagerViewStart = () => ({
  type: actionTypes.GET_ONBOARD_PROGRESS_MANAGER_VIEW_START,
});

onboardProgressManagerViewActions.getOnboardProgressManagerViewSuccess = (
  onboardProgress,
  pagination
) => ({
  onboardProgress,
  pagination,
  type: actionTypes.GET_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS,
});

onboardProgressManagerViewActions.getOnboardProgressManagerViewFail = () => ({
  type: actionTypes.GET_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL,
});

onboardProgressManagerViewActions.getNextOnboardProgressManagerViewStart = () => ({
  type: actionTypes.GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_START,
});

onboardProgressManagerViewActions.getNextOnboardProgressManagerViewSuccess = (
  nextOnboardProgress,
  pagination
) => ({
  nextOnboardProgress,
  pagination,
  type: actionTypes.GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS,
});

onboardProgressManagerViewActions.getNextOnboardProgressManagerViewFail = () => ({
  type: actionTypes.GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL,
});

onboardProgressManagerViewActions.selectUser = (userID) => ({
  type: actionTypes.ONBOARD_PROGRESS_MANAGER_VIEW_SELECT_USER,
  userID,
});

export default onboardProgressManagerViewActions;
