import React from 'react';
import { Segment } from 'semantic-ui-react';
import pt from 'prop-types';
import EspConfirmationModal from '../../../../../../../v2/components/display/EspConfirmationModal/EspConfirmationModal';

const propTypes = {
  handleCancel: pt.func.isRequired,
  handleConfirm: pt.func.isRequired,
  isModalOpen: pt.bool,
  recordNumber: pt.string,
};

const SyncWarningModal = ({
  recordNumber = 0,
  handleCancel,
  handleConfirm,
  isModalOpen,
}) => {
  return (
    <EspConfirmationModal
      cancelButton='Cancel'
      confirmButton='Yes'
      content={
        <Segment attached>
          <p>{`You are about to sync ${recordNumber} users. This could take some time. `}</p>
          <p>{'Would you like to proceed?'}</p>
        </Segment>
      }
      header={'Sync Warning'}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      open={isModalOpen}
    />
  );
};

SyncWarningModal.propTypes = propTypes;

export default SyncWarningModal;
