import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PasswordField from './PasswordField';
import { Field } from 'redux-form/immutable';

/**
 * Wrapper for Password field that integrates redux form field validation
 */

class ValidatedPassword extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  /**
   * We check for the value of the hidden field _validationPassed
   * And then set the errors for this ValidatedPassword Field using redux-form field validation
   */
  fieldValidation = (value, allValues) => {
    const { name } = this.props;
    if (allValues && allValues.get(`${name}_validationPassed`)) {
      return void 0;
    }
    return 'Validation errors';
  };

  render() {
    const { ...props } = this.props;
    return (
      <Field
        component={PasswordField}
        validate={this.fieldValidation}
        {...props}
      />
    );
  }
}

export default ValidatedPassword;
