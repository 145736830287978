import PropTypes from 'prop-types';

import React, { isValidElement } from 'react';
import { Menu } from 'semantic-ui-react';
// atoms
import BackButtonMain from './BackButtonMain';

const propTypes = {
  customBackButton: PropTypes.node,
  noBack: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const EspMainPanelHeader = ({
  customBackButton = null,
  noBack = null,
  title = null,
}) => {
  const headerSpan = <span>{title}</span>;

  return (
    <Menu borderless>
      {customBackButton ? customBackButton : !noBack && <BackButtonMain />}

      {isValidElement(title) ? (
        title
      ) : (
        <Menu.Item content={headerSpan} header />
      )}
    </Menu>
  );
};

EspMainPanelHeader.propTypes = propTypes;

export default EspMainPanelHeader;
