import { fromJS } from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';

// Redux Store
import Store from '../stores/store';

const hasValidInputValue = (block) => {
  const inputValue = block.get('inputValue', null);

  if (!inputValue) {
    return false;
  }

  const attrMap = inputValue.get('map', null);

  if (!_.isEmpty(attrMap)) {
    return true;
  }

  const value = inputValue.get('value', null);

  return !_.isEmpty(value);
};

const hasValidMutableInputValue = (block) => hasValidInputValue(fromJS(block));

/**
 * Main function to convert a string with {{attrib}}
 * @param txt {String} String to convert
 * @param store {Object} Current Redux state
 * @returns {*} {String} Final string
 * @constructor
 */
const StringAttrib = (txt, payload) => {
  const convrt = (attribTo) => {
    const [, elementToAttrib] = attribTo.split('.'),
      positionToAttrib = Number(
        attribTo.split('.')[0].split('[')[1].split(']')[0].split("'")[1]
      );

    return {
      0: positionToAttrib,
      1: elementToAttrib,
    };
  };

  const convertCase = (str, caseType) => {
    switch (caseType) {
      case 'lowercase': {
        return str.toLowerCase();
      }
      case 'uppercase': {
        return str.toUpperCase();
      }
      case 'capitalize': {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      default: {
        return str;
      }
    }
  };

  const asAttrib = txt.indexOf('}}');
  let finalTxt = txt;

  if (typeof asAttrib === 'number' && asAttrib > -1) {
    const attribute = txt.substring(
      txt.lastIndexOf('{{') + 2,
      txt.lastIndexOf('}}')
    ); // Get attribute

    const attrib = attribute.split('|'), // Split to find if lowercase or other
      attribElement = convrt(attrib[0]), // Convert and Search in payload
      attribString = payload.current_workflowtask
        ? payload.current_workflowtask.attributes
        : payload.attributes;

    if (attribString && attribString[attribElement[0]]) {
      if (attribString[attribElement[0]][attribElement[1]]) {
        finalTxt = txt.replace(
          `{{${attribute}}}`,
          convertCase(
            attribString[attribElement[0]][attribElement[1]],
            attrib[1]
          )
        );
      } else {
        finalTxt = txt.replace(`{{${attribute}}}`, '');
      }

      // Recursive call to check if another attribute was pass in the string
      const reCheck = finalTxt.indexOf('{{') && finalTxt.indexOf('}}');

      if (typeof reCheck === 'number' && reCheck > -1) {
        return StringAttrib(finalTxt, payload);
      }
    }
  }

  return finalTxt;
};

/**
 * Main Handler Pattern
 * @type {{blocks: null, skillsInput: null, initialValues: null}}
 */
const handlerUI = {
  alternateSubmit: null,
  blocks: {},
  initialValues: null,
  skillsInput: null,
};

handlerUI.submitSkills = (value) => {
  // Get option from skills & endorsements
  handlerUI.skillsInput = value;
};

// these only return the parameters that the selector is going to receive
const getBlocks = (blocks) => blocks;
const getIsPreview = (blocks, isPreview) => isPreview;
const getIntl = (blocks, isPreview, intl) => intl;

handlerUI.setupBlocks = createSelector(
  getBlocks,
  getIsPreview,
  getIntl,
  (blocksToSetup, isPreview) => {
    if (blocksToSetup) {
      const state = Store.getState().get('workflowState');
      const blocks = blocksToSetup.toJS();
      const inputValues = {};

      let newBlocks = null;

      const checkAttrib = (data) =>
        _.mapValues(data, (val) => {
          if (_.isString(val)) {
            return StringAttrib(val, state.get('payload'));
          } else if (_.isArray(val)) {
            return val.map((v) => checkAttrib(v));
          }
          return val;
        });

      // This is for blob.blocks
      if (_.isArray(blocks)) {
        newBlocks = blocks.map((block) => {
          if (!block.template) {
            return {};
          }
          const template = block.template.replace('_', 0); // Temporary fix to make Eslint happy
          if (hasValidMutableInputValue(block) && !isPreview) {
            const currentWorkflowtask = state.get('payload')
              .current_workflowtask;
            const cachedUI = handlerUI.blocks;

            if (_.has(block, 'inputValue') && currentWorkflowtask) {
              if (
                cachedUI &&
                _.size(cachedUI).length > 0 &&
                cachedUI[block.id] &&
                cachedUI[block.id].value !== null
              ) {
                inputValues[block.id] = cachedUI[block.id].value;
              } else if (
                _.has(block.inputValue, 'map') &&
                block.inputValue.map
              ) {
                const firstItemIndex = 0;
                const secondItemIndex = 1;
                // Check value in map and put it here
                const attribTo = block.inputValue.map,
                  elementToAttrib = attribTo.split('.')[secondItemIndex],
                  positionToAttrib = attribTo
                    .split('.')
                    [firstItemIndex].split('[')[secondItemIndex][
                    secondItemIndex
                  ];
                inputValues[block.id] =
                  currentWorkflowtask.attributes[positionToAttrib][
                    elementToAttrib
                  ];
              } else if (
                _.has(block.inputValue, 'value') &&
                block.inputValue.value
              ) {
                inputValues[block.id] = StringAttrib(
                  block.inputValue.value,
                  state.get('payload')
                );
              }
            }
          } else if (template === 'skills01' && !isPreview) {
            block.onSubmit = handlerUI.submitSkills;
          }
          return checkAttrib(block);
        });
      }
      // This is for blob.nav
      else {
        newBlocks = Object.assign({}, blocks);

        _.mapValues(newBlocks, (value) => {
          if (_.isString(value)) {
            return StringAttrib(value, state.get('payload'));
          }
          return value;
        });
      }

      if (_.size(inputValues) > 0) {
        handlerUI.initialValues = Object.assign({}, inputValues);
      }

      // Handle progress step is an empty string or depth too
      const prgValue = newBlocks.progress;
      if (
        prgValue &&
        (_.isString(prgValue.step) || _.isString(prgValue.depth))
      ) {
        newBlocks.progress = null;
      }

      return newBlocks;
    }
    return null;
  }
);

export default handlerUI;
