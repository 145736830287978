import React from 'react';
import PropTypes from 'prop-types';

import ImmutablePropTypes from 'react-immutable-proptypes';

const propTypes = {
  comment: ImmutablePropTypes.map,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const ConversationSystemMessage = ({ comment = null, text = '' }) => (
  <div
    style={{
      fontSize: '1em',
      lineHeight: 1.25,
      padding: '.5rem 0',
      textAlign: 'center',
      textTransform: 'initial',
    }}
  >
    <span className='message'>{comment ? comment.get('text') : text}</span>
  </div>
);

ConversationSystemMessage.propTypes = propTypes;

export default ConversationSystemMessage;
