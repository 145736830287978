import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareCreative extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M41.4 58.69l7.08 1.23-1.08 1.07 4.37 4.37 8.9-8.9-4.37-4.37-1 1-1.29-7s-.86.11-1.62.13c-1.78 0-5.69-.93-17.14-6.48-.077.114-.16.224-.25.33 1.4 2.68 6.87 13.54 6.4 18.62zm14.9-3.76l1.54 1.54-6.07 6.07L50.23 61l6.07-6.07zm-16.24-8.88l4.78 4.86v4.38h5.8v-5.77h-4.36l-4.51-4.59c5 2.11 8.57 3.26 10.79 3.41l1 5.93L50 58.15 43.34 57c-.26-3.25-1.75-7.38-3.28-10.95zm7.2 6.85v-1h1v1h-1z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.65c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.528 15 25.324 15 23.84c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareCreative;
