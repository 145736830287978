import { getFormValues } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import integrationThunks from '../../actions/integrationThunks';
import errorsActions from '../../actions/errorsActions';
// Global
import EmailRegExp from '../../globals/EmailRegExp';

const mapStateToProps = (state) => {
  const formError =
    state.getIn(['form', 'OktaSetupCustomer', 'syncErrors']) || null;
  const errorsApi = state.getIn(['errorsApi', 'errors']);
  const formValues = getFormValues('OktaSetupCustomer')(state);

  return {
    disabledSAML: (formValues && !formValues.get('enable_saml')) || false,
    errorsApi,
    formError,
    isLoading: state.getIn(['integration', 'oktaTenant', 'isLoading']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  formValidation(value) {
    const error = {};

    if (!value.get('company_name')) {
      error.okta_subdomain = "Can't be empty";
    }

    if (!value.get('okta_subdomain')) {
      error.okta_subdomain = "Can't be empty";
    }

    if (!value.get('admin_firstname')) {
      error.admin_firstname = "Can't be empty";
    }

    if (!value.get('admin_lastname')) {
      error.admin_lastname = "Can't be empty";
    }

    if (!value.get('admin_email')) {
      error.admin_email = "Can't be empty";
    } else if (!value.get('admin_email').match(EmailRegExp)) {
      error.admin_email = 'Invalid Email';
    }

    if (!value.get('security_question')) {
      error.security_question = 'Select one Security Question';
    }

    if (!value.get('security_answer')) {
      error.security_answer = "Can't be empty";
    }

    return error;
  },
  resetOktaErrors() {
    dispatch(errorsActions.forceResetErrors());
  },
  setOktaTenant(data, msg, cb) {
    dispatch(integrationThunks.setOktaTenant(data)).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: msg.message,
          title: msg.header,
        })
      );
      cb();
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
