import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Scrollbars as ReactCustomScrollbars } from 'react-custom-scrollbars';

const renderView = (props) => <div {...props} className='scroll-content' />;

const MAX_SCROLL_TIMEOUT = 100; // DEV-11734 - decreasing timer (DEV-11304)
class ScrollArea extends PureComponent {
  static propTypes = {
    absolute: PropTypes.bool,
    autoHide: PropTypes.bool,
    children: PropTypes.node,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.string,
    indicateScrolling: PropTypes.bool,
    margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    scrollBottom: PropTypes.bool,
    scrollHeight: PropTypes.bool,
    use3D: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    absolute: true,
    autoHide: true,
    children: null,
    height: '100%',
    id: null,
    indicateScrolling: false,
    margin: null,
    maxWidth: '100%',
    padding: null,
    scrollBottom: false,
    scrollHeight: false,
    use3D: true,
    width: '100%',
  };

  state = {
    hideIndicate: false,
    scrolling: false,
  };

  componentDidMount() {
    const { scrollBottom, scrollHeight } = this.props;

    if (scrollBottom || scrollHeight) {
      this.scrollToBottom();
    }
  }
  componentDidUpdate() {
    const { scrollBottom, scrollHeight } = this.props;

    if (scrollBottom) {
      this.scrollToBottom();
    } else if (scrollHeight) {
      // console.log('Calling scrollToHeight');
      this.scrollToHeight();
    }
  }

  scrollToBottom = () => {
    // cancel any call incoming to  scrollToHeight
    this.scrollToHeight.cancel();

    const scrollAreaEl = this.wrapperNode;
    // Total height of the "scroll-content"
    const currentScrollHeight = scrollAreaEl.getScrollHeight();

    // console.log('Scrolling bottom', currentScrollHeight);

    scrollAreaEl.scrollTop(currentScrollHeight);
  };

  scrollToHeight = debounce(
    () => {
      const scrollAreaEl = this.wrapperNode;
      if (scrollAreaEl) {
        // container is the element "scroll-area"
        const scrollHeightOfAreaWrapper = scrollAreaEl.container.scrollHeight;
        // this is the current scroll position within the content element "scroll-content"
        const contentScrollPosition = scrollAreaEl.getScrollTop();

        let newScroll;
        if (contentScrollPosition === 0) {
          // if no scroll then we'd assume no movement has been done and essentially we scroll to bottom
          newScroll = scrollAreaEl.getScrollHeight();
        } else {
          // that extra 15 just to fill the weird gaps
          newScroll = scrollHeightOfAreaWrapper + contentScrollPosition + 15;
        }

        // console.log('scrollToHeight position to', newScroll);
        // console.log('based on scrollHeightOfAreaWrapper', scrollHeightOfAreaWrapper);
        // console.log('and on contentScrollPosition', contentScrollPosition);

        scrollAreaEl.scrollTop(newScroll);
      }
    },
    MAX_SCROLL_TIMEOUT,
    {
      leading: true, // call only at the beggining
      trailing: false, // don't perform funct at the end
    }
  );

  wrapperNode = null;

  setDOMNode = (domNode) => {
    this.wrapperNode = domNode;
  };

  handleScrollbarsUpdate = () => {
    if (this.domNode) {
      const {
        clientHeight,
        scrollHeight,
        scrollTop,
      } = this.domNode.getValues();

      const { hideIndicate, scrolling } = this.state;
      const { indicateScrolling } = this.props;

      const onBottom = clientHeight + scrollTop >= scrollHeight;

      if (
        !onBottom &&
        !hideIndicate &&
        clientHeight < scrollHeight &&
        indicateScrolling &&
        !scrolling
      ) {
        this.setState({
          scrolling: true,
        });
      } else if (!hideIndicate && onBottom && indicateScrolling && scrolling) {
        this.setState({
          hideIndicate: true,
          scrolling: false,
        });
      } else if (clientHeight >= scrollHeight && (scrolling || hideIndicate)) {
        // Force no scrolling classname if it was set previously
        this.setState({
          hideIndicate: false,
          scrolling: false,
        });
      }
    }
  };

  renderScrollContent = (scrollContentProps) => {
    const {
      absolute,
      // horizontal,
      // vertical,
      use3D,
      padding,
    } = this.props;

    scrollContentProps.style.padding = padding;
    // Need to perform a fake translateZ here in order to force the hardware acceleration on mobile and avoid a repainting issue when the user scroll fast
    scrollContentProps.style.transform = use3D ? 'translateZ(0)' : null;
    scrollContentProps.style.overflowY = 'scroll';

    if (!absolute) {
      scrollContentProps.style.position = 'relative';
    }

    // // hides both scrollbars
    // scrollContentProps.style.overflow = 'hidden';
    // scrollContentProps.style.zIndex = '0';
    //
    // // set vertical scroll visible
    // if (vertical){
    //   scrollContentProps.style.overflowY = 'scroll';
    // }
    //
    // // set horizontal scroll visible
    // if (horizontal){
    //   scrollContentProps.style.overflowX = 'scroll';
    // }

    return renderView(scrollContentProps);
  };

  render() {
    const {
      // horizontal,
      // vertical,
      autoHide,
      children,
      height,
      id,
      margin,
      maxWidth,
      width,
    } = this.props;
    const { scrolling } = this.state;

    return (
      <ReactCustomScrollbars
        autoHide={autoHide}
        className={scrolling ? 'scroll-area scrolling' : 'scroll-area'}
        id={id}
        onUpdate={this.handleScrollbarsUpdate}
        ref={this.setDOMNode}
        // renderThumbHorizontal={horizontal ? renderThumb : noScroll}
        // renderThumbVertical={vertical ? renderThumb : noScroll}
        // renderTrackHorizontal={horizontal ? renderTrackHorizontal : noScroll}
        // renderTrackVertical={vertical ? renderTrackVertical : noScroll}
        renderView={this.renderScrollContent}
        style={{
          height,
          margin,
          maxWidth,
          width,
          zIndex: 1,
        }}
      >
        {children}
      </ReactCustomScrollbars>
    );
  }
}

export default ScrollArea;
