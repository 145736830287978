import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsNetworkissue extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M79.92 52H67.43c-1.52 0-2.75 1.23-2.75 2.75v6.64c0 1.52 1.23 2.75 2.75 2.75h5.5v2.5h-1.64c-.414 0-.75.336-.75.75s.336.75.75.75h4.77c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-1.64v-2.5h5.5c1.52 0 2.75-1.23 2.75-2.75v-6.65c-.006-1.515-1.235-2.74-2.75-2.74zm1.25 9.39c0 .69-.56 1.25-1.25 1.25H67.43c-.69 0-1.25-.56-1.25-1.25v-6.65c0-.69.56-1.25 1.25-1.25h12.49c.69 0 1.25.56 1.25 1.25v6.65zm-9-12.02c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5V34.82c0-.828-.672-1.5-1.5-1.5H63.13l-2.36 3h11.4v13.05zM50.72 33.32V14c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v19.32H24.33c-.828 0-1.5.672-1.5 1.5v13.42c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5V36.32h21.89v5.24l3-3.81v-1.43h1.13l2.36-3h-3.49zM30.63 51.93H18.14c-1.52 0-2.75 1.23-2.75 2.75v6.64c0 1.52 1.23 2.75 2.75 2.75h5.5v2.66H22c-.414 0-.75.336-.75.75s.336.75.75.75h4.7l.78-1c-.1-.303-.38-.51-.7-.51h-1.64v-2.65H30l1.39-1.77c-.21.17-.47.264-.74.27H18.14c-.69 0-1.25-.56-1.25-1.25v-6.64c0-.69.56-1.25 1.25-1.25h12.49c.69 0 1.25.56 1.25 1.25v6.64c-.002.154-.032.307-.09.45l1.59-2v-5.09c0-1.52-1.23-2.75-2.75-2.75zm24.86.07h-7.08l-1.18 1.5h8.27c.69 0 1.25.56 1.25 1.25v6.64c0 .69-.56 1.25-1.25 1.25H43c-.69 0-1.25-.56-1.25-1.25v-.94l-1.37 1.74c.33 1.176 1.398 1.992 2.62 2h5.5v2.5h-1.64c-.414 0-.75.336-.75.75s.336.75.75.75h4.77c.414 0 .75-.336.75-.75s-.336-.75-.75-.75H50v-2.5h5.5c1.52 0 2.75-1.23 2.75-2.75v-6.7c-.003-.73-.295-1.428-.813-1.942-.517-.513-1.218-.8-1.947-.798z' />
          <path d='M48 4C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4zM9 48c.01-14.538 8.106-27.865 21.004-34.573C42.902 6.717 58.46 7.74 70.37 16.08L22.19 77.21C13.8 69.82 8.996 59.18 9 48zm39 39c-8.482.01-16.733-2.763-23.49-7.89l48.25-61.22c12.706 10.46 17.48 27.77 11.928 43.264C79.138 76.647 64.458 86.99 48 87z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsNetworkissue;
