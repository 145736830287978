import { useContext } from 'react';

import AuthContext from '../../context/auth/authContext';

//
// Hook: useCurrentUser
//
// Exposes the logged-in user's profile. *
const useCurrentUser = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error(
      'useCurrentUser Hook can only be called inside AuthContext.Provider!!'
    );
  }

  return authContext.profile;
};

export default useCurrentUser;
