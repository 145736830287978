import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  Dropdown,
  Grid,
  Header,
  List,
  Menu,
  Message,
  Segment,
} from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, memoize } from 'lodash';

// Molecules
import EspListItem from './EspListItem';
import OnboardTemplateIntervalModal from './OnboardTemplateIntervalModal';
// Utils
import getDueDate from '../../utils/getDueDate';
import OnboardActivitiesCategory from '../../globals/OnboardActivitiesCategory';

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

class OnboardingActivityScheduleGroup extends PureComponent {
  static propTypes = {
    /** Action on a schedule ID (copy) */
    action: PropTypes.string,
    /** List of activity templates to display in the group. */
    activities: ImmutablePropTypes.list,
    /** Delete template schedule */
    deleteOneTemplate: PropTypes.func.isRequired,
    /** Read only state */
    readOnly: PropTypes.bool,
    /** Schedule ID */
    scheduleID: PropTypes.string,
    /** Title of the group. */
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    action: null,
    activities: Immutable.List(),
    readOnly: false,
    scheduleID: null,
  };

  onHandleDeleteTemplateSelected = memoize(
    curry((intervalID, templateID, title, e) => {
      e.preventDefault();
      const { deleteOneTemplate } = this.props;
      // Todo - Use intl translation here
      const msg = {
        header: 'Success',
        message: `The ${title} activity has been removed`,
      };

      deleteOneTemplate(intervalID, templateID, msg);
    })
  );

  render() {
    const { action, activities, readOnly, title, scheduleID } = this.props;
    // Dropdown EDIT trigger for the template interval modal
    const editTrigger = <Dropdown.Item>{'Edit'}</Dropdown.Item>;

    const headerAction = (interval, templateID, title) => {
      if (readOnly) {
        return null;
      }

      return (
        <Menu compact text>
          <Dropdown icon='ellipsis horizontal'>
            <Dropdown.Menu>
              <OnboardTemplateIntervalModal
                action={action}
                interval={interval}
                intervalID={String(interval.get('id'))}
                scheduleID={scheduleID}
                templateID={templateID}
                title={title}
                trigger={editTrigger}
              />
              <Dropdown.Item
                onClick={this.onHandleDeleteTemplateSelected(
                  interval.get('id'),
                  templateID,
                  title
                )}
              >
                {'Delete'}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      );
    };

    // Make the title titleCase
    const titleCase = toTitleCase(title);
    return (
      <Segment>
        <Header as='h4' content={titleCase} dividing />
        <Grid columns={2}>
          {/* NOTE: attachedHeader should support the 'internalDescription' but since it's not ready on BE  and for demo purpose */}

          {activities && !activities.isEmpty() ? (
            activities.map((item) => {
              const ImgElement = OnboardActivitiesCategory.getIn([
                item.get('category'),
                'image',
              ])
                ? OnboardActivitiesCategory.getIn([
                    item.get('category'),
                    'image',
                  ])
                : null;

              return (
                <Grid.Column key={item.get('key')}>
                  <Segment as={List}>
                    <EspListItem
                      attachedHeader={item.get('private_notes')}
                      description={item.get('description')}
                      header={item.get('title')}
                      headerAction={headerAction(
                        item.get('interval'),
                        item.get('templateID'),
                        item.get('title')
                      )}
                      image={ImgElement && <ImgElement />}
                      meta={getDueDate(item)}
                      ribbon={
                        item.get('status') === 'ARCHIVED' ? 'ARCHIVED' : null
                      }
                    />
                  </Segment>
                </Grid.Column>
              );
            })
          ) : (
            <Message basic content='No activities in this group...' info />
          )}
        </Grid>
      </Segment>
    );
  }
}

export default OnboardingActivityScheduleGroup;
