import { change } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => ({
  initialInputValue: state.getIn(
    ['form', 'Form01', 'values', ownProps.inputName],
    null
  ),
});

const mapDispatchToProps = (dispatch) => ({
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
