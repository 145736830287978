import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  categories: [],
  categoriesIsLoading: false,
  copyAttributesProduct: {},
  copyAttributesProductID: 0,
  copyIsLoading: false,
  count: 0,
  currentCategory: 0,
  currentProduct: {
    description: '',
    id: 1,
    images: [],
    name: 'Loading...',
    short_description: '',
  },
  currentProductLoading: false,
  currentSubCategory: 0,
  isLoading: false,
  isLoadingProductFamily: false,
  isSearching: false,
  next: '',
  orderBy: null,
  previous: '',
  productFamily: null,
  products: [],
  productsPerPage: 0,
  productsSearch: [],
  searchTerm: '',
  selected: [],
  showItemsIhave: true,
  subcategories: [],
  subcategoriesIsLoading: false,
});

const catalogReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.DPC_LOADING_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.DPC_LOADING_ERROR: {
      return state.set('isLoading', false);
    }

    case actionTypes.DPC_LOADING_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('isSearching', false)
        .set('products', fromJS(action.products))
        .set('count', action.count)
        .set('next', action.next)
        .set('previous', action.previous);
    }

    case actionTypes.DPC_TOP_CATEGORIES_LOADING_START: {
      return state.set('categoriesIsLoading', true);
    }

    case actionTypes.DPC_TOP_CATEGORIES_LOADING_ERROR: {
      return state.set('categoriesIsLoading', false);
    }

    case actionTypes.DPC_TOP_CATEGORIES_LOADING_SUCCESS: {
      return state
        .set('categoriesIsLoading', false)
        .set('categories', fromJS(action.categories));
    }

    case actionTypes.DPC_SUB_CATEGORIES_LOADING_START: {
      return state.set('subcategoriesIsLoading', true);
    }

    case actionTypes.DPC_SUB_CATEGORIES_LOADING_ERROR: {
      return state.set('subcategoriesIsLoading', false);
    }

    case actionTypes.DPC_SUB_CATEGORIES_LOADING_SUCCESS: {
      return state
        .set('subcategoriesIsLoading', false)
        .set('subcategories', fromJS(action.subcategories));
    }

    case actionTypes.DPC_SET_CATEGORY: {
      if (action.isSubCategory) {
        return state.set('currentSubCategory', action.currentCategory);
      }
      return state
        .set('currentCategory', action.currentCategory)
        .set('currentSubCategory', 0);
    }

    case actionTypes.DPC_IS_SEARCHING_START: {
      return state.set('isSearching', true);
    }

    case actionTypes.DPC_IS_SEARCHING_ERROR: {
      return state.set('isSearching', false);
    }

    case actionTypes.DPC_IS_SEARCHING_SUCCESS: {
      return state.set('isSearching', false);
    }

    case actionTypes.DPC_SET_CATALOG_SEARCH_PRODUCTS_TERM: {
      return state.set('searchTerm', action.searchTerm);
    }

    case actionTypes.DPC_SELECTED_ADD_PRODUCT: {
      const selectedDpcProducts = state.get('selected').toJS();
      selectedDpcProducts.push(action.productEid);
      return state.set('selected', fromJS(selectedDpcProducts));
    }

    case actionTypes.DPC_SELECTED_REMOVE_PRODUCT: {
      const selectedDpcProducts = state.get('selected');
      const newSelectedDpcProducts = selectedDpcProducts
        .filterNot((eid) => eid === action.productEid)
        .toJS();
      return state.set('selected', fromJS(newSelectedDpcProducts));
    }

    case actionTypes.DPC_INCLUDE_ITEMS_I_HAVE: {
      const showItemsIhave = state.get('showItemsIhave');
      const newState = showItemsIhave !== true;
      return state.set('showItemsIhave', newState);
    }

    case actionTypes.DPC_SET_ORDER_BY: {
      return state.set('orderBy', action.orderBy);
    }

    case actionTypes.DPC_COPY_TO_CATALOG_START: {
      return state.set('copyIsLoading', true);
    }

    case actionTypes.DPC_COPY_TO_CATALOG_ERROR: {
      return state.set('copyIsLoading', false);
    }

    case actionTypes.DPC_COPY_TO_CATALOG_SUCCESS: {
      return state.set('copyIsLoading', false);
    }

    case actionTypes.DPC_LOAD_SINGLE_START: {
      return state
        .set('isLoadingProductFamily', true)
        .set('currentProductLoading', true);
    }

    case actionTypes.DPC_LOAD_SINGLE_ERROR: {
      return state
        .set('isLoadingProductFamily', false)
        .set('currentProductLoading', false);
    }

    case actionTypes.DPC_LOAD_SINGLE_SUCCESS: {
      return state
        .set('isLoadingProductFamily', false)
        .set('currentProductLoading', false)
        .set('currentProduct', fromJS(action.currentProduct));
    }

    case actionTypes.DPC_SET_PRODUCT_FAMILY_START: {
      return state.set('isLoadingProductFamily', true);
    }

    case actionTypes.DPC_SET_PRODUCT_FAMILY_FAIL: {
      return state.set('isLoadingProductFamily', false);
    }

    case actionTypes.DPC_SET_PRODUCT_FAMILY_SUCCESS: {
      return state
        .set('isLoadingProductFamily', false)
        .set('productFamily', fromJS(action.productFamily));
    }

    case actionTypes.DPC_SET_COPY_PRODUCT_ID: {
      return state.set('copyAttributesProductID', action.productID);
    }

    case actionTypes.SET_COPY_ATTRIBUTES_PRODUCT: {
      return state.setIn(['copyAttributesProduct', action.key], true);
    }

    case actionTypes.REMOVE_COPY_ATTRIBUTES_PRODUCT: {
      return state.set(
        'copyAttributesProduct',
        state.get('copyAttributesProduct').delete(action.key)
      );
    }

    case actionTypes.RESET_COPY_ATTRIBUTES_PRODUCT: {
      return state
        .set('copyAttributesProduct', fromJS({}))
        .set('copyAttributesProductID', 0);
    }

    case actionTypes.DPC_CLEAR_ATTRIBUTES_PRODUCT: {
      return state
        .set('copyAttributesProductID', 0)
        .set('copyAttributesProduct', fromJS({}));
    }

    case actionTypes.DPC_REMOVE_PRODUCT_EID: {
      return state.set(
        'products',
        state.get('products').filterNot((p) => p.get('eid') === action.eid)
      );
    }

    default: {
      return state;
    }
  }
};

export default catalogReducer;
