import React, { PureComponent } from 'react';
import { Button, Input, List, Segment } from 'semantic-ui-react';
import classNames from 'classnames';
import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { UserAgentUtils } from 'esp-globals';

// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Controller
import SearchController from '../controllers/SearchController';
// Utils
import browserHistory from '../../utils/browserHistory';
import SearchModels from '../../utils/SearchModels';
import uiPathGenerator from '../../utils/uiPathGenerator';
import UserUtils from '../../utils/UserUtils';

// in ms
const SEARCH_DELAY = 300;

const KEY_ENTER = 13;

class SearchBox extends PureComponent {
  static propTypes = {
    currentUser: ImmutablePropTypes.map,
    isLoading: PropTypes.bool.isRequired,
    results: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    search: PropTypes.func,
    searchContext: PropTypes.shape({
      appModel: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      fullResultsRoute: PropTypes.any,
      isFullResultsMode: PropTypes.bool,
      launchesFromRoute: PropTypes.string,
      msg: PropTypes.string,
      name: PropTypes.string,
      resultTemplate: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      routeToResult: PropTypes.any,
    }),
    setUserInput: PropTypes.func,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    currentUser: null,
    results: null,
    search: _.noop,
    searchContext: null,
    setUserInput: _.noop,
  };

  state = {
    focused: false,
  };

  cancelledCloseOnBlur = false;

  isClearButtonVisible = () => {
    const { searchContext, userInput } = this.props;

    if (!searchContext) {
      return false;
    }

    if (searchContext.isFullResultsMode) {
      // always available in full results mode
      return true;
    } else {
      const isEmpty = _.isEmpty(userInput.trim());
      return !isEmpty;
    }
  };

  handleBoxMouseDown = () => {
    // user clicked somewhere inside the box, next blur event shouldn't close the results
    this.cancelledCloseOnBlur = true;
  };

  handleSearchInputFocus = () => {
    this.setState({
      focusHelpText: true,
      focused: true,
    });

    this.cancelledCloseOnBlur = false;
  };

  handleSearchInputBlur = () => {
    if (!this.cancelledCloseOnBlur) {
      this.setState({
        focused: false,
      });
    }
    this.setState({
      focusHelpText: false,
    });
    this.cancelledCloseOnBlur = false;
  };

  handleScrimClick = () => {
    this.setState({
      focusHelpText: false,
      focused: false,
    });
  };

  handleOnChange = (e, data) => {
    const userInput = data.value;
    const { setUserInput } = this.props;
    setUserInput(userInput);
    this.setState({
      focusHelpText: false,
    });

    // with {leading: true} debounce option, first time this.search is called,
    // this.props.setUserInput is pending and this.props.userInput is not up to date yet.
    // By using _.defer this.search is called as soon as event queue gets empty, which gives
    // us the warranty this.props.userInput is up to date at that point.
    _.defer(this.search);
  };

  handleKeyDown = (e) => {
    const { searchContext, userInput } = this.props;
    this.setState({
      focusHelpText: false,
    });
    const isEnter = e.keyCode === KEY_ENTER;
    const isEmpty = _.isEmpty(userInput.trim());

    if (
      isEnter &&
      !isEmpty &&
      !searchContext.isFullResultsMode &&
      searchContext.fullResultsRoute
    ) {
      const fullResultsUrl = `${uiPathGenerator.genPath(
        searchContext.fullResultsRoute
      )}?q=${userInput}`;
      browserHistory.push(fullResultsUrl);
    }
  };

  handleClearButtonClick = () => {
    const { searchContext, setUserInput } = this.props;

    if (searchContext.isFullResultsMode) {
      // exit full results mode
      const launchesFromUrl = uiPathGenerator.genPath(
        searchContext.launchesFromRoute
      );
      browserHistory.push(launchesFromUrl);
    } else {
      setUserInput('');
    }
  };

  handleClickResult = () => {
    this.setState({
      focused: false,
    });
  };

  search = _.debounce(() => {
    const { userInput, search } = this.props;

    const isEmpty = _.isEmpty(userInput.trim());

    if (!isEmpty) {
      search();
    }
  }, SEARCH_DELAY);

  render() {
    const { currentUser, isLoading, results, searchContext, userInput } =
      this.props;
    const weHaveMultipleResults = results && results.size > 1;

    if (!currentUser) {
      return null;
    }

    // non confirmed users aren't allowed to search for users
    const hidden =
      Boolean(searchContext) &&
      searchContext.appModel === SearchModels.USER &&
      !UserUtils.isManagerConfirmed(currentUser);

    if (hidden) {
      return null;
    }

    const { focused, focusHelpText } = this.state;
    const isFullResultsMode =
      Boolean(searchContext) && searchContext.isFullResultsMode;

    const placeholder = searchContext
      ? intl.formatMessage({
          id: searchContext.msg,
        })
      : intl.formatMessage({
          id: 'message.search_placeholder',
        });

    let clearButton = null;

    if (this.isClearButtonVisible()) {
      clearButton = (
        <Button
          content={intl.formatMessage({
            id: 'label.clear',
          })}
          icon='close'
          onClick={this.handleClearButtonClick}
        />
      );
    }

    let listBoxHeightStyle = {
      height: 'auto',
    };
    if (weHaveMultipleResults) {
      listBoxHeightStyle = {
        height: `${results.size * 80 + 80}px`,
      };
    }

    const disabled = !searchContext;
    const shouldDisableTab = UserAgentUtils.isCordova() ? -1 : void 0;

    return (
      // NOTE: This might end up needing to be extended with classnames() so we can have a 'results' class also added when there are search results returned -bje
      <div
        className={classNames('', {
          focusHelpText: focusHelpText && !userInput,
          focused: focused && Boolean(results) && !isFullResultsMode,
        })}
        id='esp-search'
      >
        {}
        <div className='focus-box' onMouseDown={this.handleBoxMouseDown}>
          <Input
            action={clearButton}
            disabled={disabled}
            fluid
            icon='search'
            iconPosition='left'
            loading={isLoading}
            maxLength={200}
            onBlur={this.handleSearchInputBlur}
            onChange={this.handleOnChange}
            onFocus={this.handleSearchInputFocus}
            onKeyDown={this.handleKeyDown}
            placeholder={placeholder}
            tabIndex={shouldDisableTab}
            value={userInput}
          />
          <div className='list-box' style={listBoxHeightStyle}>
            <ScrollArea absolute={weHaveMultipleResults}>
              {/* TODO Temporal empty state */}
              {results &&
                results.isEmpty() &&
                intl.formatMessage({
                  id: 'message.no_matching_results',
                })}
              <List>
                {results &&
                  results.map((result) =>
                    React.createElement(searchContext.resultTemplate, {
                      currentUser,
                      key: result.get('pk'),
                      onClick: this.handleClickResult,
                      result,
                      routeToResult: searchContext.routeToResult,
                      routeToResultIncludesQ: false,
                      userInput,
                    })
                  )}
              </List>
            </ScrollArea>
          </div>

          <Segment className='use-get-help-reminder' textAlign='center'>
            <p>
              {intl.formatMessage({
                id: 'message.use_get_help_reminder',
              })}
              {/* {`To ask a question, get help or create a ticket click the “Get Help” item on the left side of the screen. If you want to continue searching the current view continue typing.`} */}
            </p>
          </Segment>
        </div>
        {}
        <div
          className='scrim'
          onClick={this.handleScrimClick}
          onKeyPress={this.handleScrimClick}
        />
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SearchBox = SearchController(SearchBox);

export default SearchBox;
