import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Segment,
  Table,
} from 'semantic-ui-react';
import { curry, memoize } from 'lodash';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Boundaries } from 'cascara-middleware';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CRUDTable from '../../../../../v2/components/functional/CRUDTable';
// Controller
import AdminServiceTeamSubstatesController from '../../../controllers/AdminServiceTeamSubstatesController';

import AdminSubstates from '../AdminSubstates';

const LIMIT = 25;

class AdminServiceTeamSubstates extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    loadServiceDepartment: PropTypes.func,
    loadSubstates: PropTypes.func,
    onRemove: PropTypes.func,
    onToggleActive: PropTypes.func,
    params: PropTypes.shape({
      page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      teamID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,

    rows: ImmutablePropTypes.list,

    serviceTeamEID: PropTypes.string,
    serviceTeamSelected: ImmutablePropTypes.map,
    totalRows: PropTypes.number,
  };

  static defaultProps = {
    isLoading: false,
    loadServiceDepartment: () => {},
    loadSubstates: () => {},
    onRemove: () => {},
    onToggleActive: () => {},
    rows: Immutable.List(),
    serviceTeamEID: null,
    serviceTeamSelected: Immutable.Map(),
    totalRows: 0,
  };

  state = {
    isModalOpen: false,
    rowIndex: null,
    substateID: null,
    values: null,
  };

  componentDidMount() {
    const {
      serviceTeamSelected,
      loadServiceDepartment,
      loadSubstates,
      params,
    } = this.props;
    if (!serviceTeamSelected || serviceTeamSelected.isEmpty()) {
      loadServiceDepartment().then((serviceTeam) => {
        loadSubstates(
          serviceTeam.eid,
          LIMIT,

          (Number(params.page) - 1) * LIMIT
        );
      });
    } else {
      loadSubstates(serviceTeamSelected.get('eid'));
    }
  }

  componentDidUpdate(prevProps) {
    const { page } = prevProps.params;
    const { serviceTeamSelected, loadSubstates, params } = this.props;

    if (page !== params.page) {
      if (!serviceTeamSelected.isEmpty()) {
        loadSubstates(
          serviceTeamSelected.get('eid'),
          LIMIT,

          (Number(params.page) - 1) * LIMIT
        );
      }
    }
  }

  setModalState = (isModalOpen) => {
    this.setState({
      isModalOpen,
      ...(!isModalOpen && {
        rowIndex: null,
        substateID: null,
        values: null,
      }),
    });
  };

  handleRemove = memoize(
    curry(({ row, rowIndex }, e) => {
      if (e) {
        const { onRemove, params } = this.props;
        onRemove(
          row,
          rowIndex,
          LIMIT,
          (Number(params.page) - 1) * Number(LIMIT, params.page)
        );
      }
    })
  );

  handleEdit = memoize(
    curry(({ row, rowIndex }, e) => {
      if (e) {
        this.setState({
          isModalOpen: true,
          rowIndex,
          substateID: row.get('id'),
          values: {
            order: String(row.get('order')),
            parent: row.get('parent'),
            sub_status: row.get('sub_status'),
            task_type: row.get('task_type'),
          },
        });
      }
    })
  );

  handleCopy = memoize(
    curry(({ row, rowIndex }, e) => {
      if (e) {
        this.setState({
          isModalOpen: true,
          rowIndex,
          values: {
            order: String(row.get('order')),
            parent: row.get('parent'),
            task_type: row.get('task_type'),
          },
        });
      }
    })
  );

  handleToggleActive = memoize(
    curry(({ row, rowIndex }, e) => {
      if (e) {
        const { onToggleActive } = this.props;
        onToggleActive(row, rowIndex);
      }
    })
  );

  render() {
    const {
      isLoading,
      params,
      rows,
      serviceTeamEID,
      serviceTeamSelected,
      totalRows,
    } = this.props;
    const { isModalOpen, rowIndex, substateID, values } = this.state;

    if (!serviceTeamSelected) {
      return null;
    }

    const serviceDepartment =
      serviceTeamSelected &&
      serviceTeamSelected.getIn(['service_department', 'display_name']);
    const serviceDepartmentID =
      serviceTeamSelected &&
      serviceTeamSelected.getIn(['service_department', 'id']);

    if (!serviceDepartment && !serviceDepartmentID) {
      return null;
    }

    const headers = (
      <>
        <Table.HeaderCell>{'Task Type'}</Table.HeaderCell>
        <Table.HeaderCell>{'State'}</Table.HeaderCell>
        <Table.HeaderCell>{'Substate'}</Table.HeaderCell>
        <Table.HeaderCell>{'Order'}</Table.HeaderCell>
        <Table.HeaderCell>{''}</Table.HeaderCell>
      </>
    );
    const CRUDTableRows = rows.map((row, rowIndex) => (
      <Table.Row key={row.get('id')}>
        {
          <>
            <Table.Cell>{row.get('task_type_label')}</Table.Cell>
            <Table.Cell>{row.get('parent_label')}</Table.Cell>
            <Table.Cell>{row.get('label')}</Table.Cell>
            <Table.Cell>{row.get('order')}</Table.Cell>
          </>
        }
        {
          <Table.Cell>
            <Grid
              columns='equal'
              style={{
                paddingBottom: '.25em',
                paddingTop: '.25em',
              }}
            >
              <Grid.Row>
                <Grid.Column>
                  <Button
                    basic
                    disabled={isLoading}
                    icon
                    onClick={this.handleCopy({
                      row,
                      rowIndex,
                    })}
                  >
                    <Icon name='copy outline' />
                  </Button>

                  <Button
                    basic
                    disabled={isLoading}
                    icon
                    onClick={this.handleEdit({
                      row,
                      rowIndex,
                    })}
                  >
                    <Icon name='edit' />
                  </Button>

                  <Button
                    basic
                    disabled={isLoading}
                    icon
                    onClick={this.handleRemove({
                      row,
                      rowIndex,
                    })}
                  >
                    <Icon name='x' />
                  </Button>

                  <Checkbox
                    checked={row.get('active')}
                    disabled={isLoading}
                    onClick={this.handleToggleActive({
                      row,
                      rowIndex,
                    })}
                    toggle
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
        }
      </Table.Row>
    ));

    return (
      <Boundaries>
        <Segment attached='bottom'>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <AdminSubstates
                  limit={LIMIT}
                  message={''}
                  offset={(Number(params.page || 1) - 1) * LIMIT}
                  open={isModalOpen}
                  rowIndex={rowIndex}
                  serviceTeamEID={serviceTeamEID}
                  setModalState={this.setModalState}
                  substateID={substateID}
                  values={values}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <CRUDTable
                  celled
                  headers={headers}
                  isLoading={isLoading}
                  limit={LIMIT}
                  page={Number(params.page) || 1}
                  paginationURL={'admin.serviceTeams.detail.substates'}
                  pathOptions={{
                    teamID: params.teamID,
                  }}
                  totalRows={totalRows}
                >
                  {CRUDTableRows}
                </CRUDTable>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Boundaries>
    );
  }
}

export const AdminServiceTeamSubstatesTest = AdminServiceTeamSubstates;

export default AdminServiceTeamSubstatesController(AdminServiceTeamSubstates);
