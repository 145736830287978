import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { debounce, isNumber, memoize, noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Divider } from 'semantic-ui-react';
// Molecules
import ValidatedField from '../../molecules/ValidatedField';
import FormInputText from '../../molecules/FormInputText';
import FormInputSelect from '../../molecules/FormInputSelect';
import FaqItem from '../../molecules/FaqItem';
// Controller
import SearchSelectFAQ01Controller from '../../controllers/SearchSelectFAQ01Controller';

import TypeSearchSelectFAQ, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSearchSelectFAQ';

const TIMEOUT = 300;

class SearchSelectFAQ01 extends Component {
  static propTypes = {
    ...TypeSearchSelectFAQ,
    beScratch: ImmutablePropTypes.map,
    changeValue: PropTypes.func,
    isLoadingTrigger: PropTypes.bool,
    myServiceDeparment: ImmutablePropTypes.list,
    query: PropTypes.shape({
      title: PropTypes.string,
    }),
    resetBEScratch: PropTypes.func,
    results: ImmutablePropTypes.list,
    saveBEscratch: PropTypes.func,

    searchTrigger: PropTypes.func,

    searchedTerm: PropTypes.string,

    selectServiceDepartment: PropTypes.func,

    selectedFaq: ImmutablePropTypes.map,
    setDepartmentIDSelected: PropTypes.func,
    setFaqBEScratch: PropTypes.func,
    setSelectedFromFAQ: PropTypes.func,
    submitNoMatch: PropTypes.func,
    taskSelected: ImmutablePropTypes.map,
  };

  static defaultProps = {
    ...TypeDefaults,
    beScratch: Immutable.Map(),
    changeValue: noop,
    isLoadingTrigger: false,
    myServiceDeparment: Immutable.List(),
    query: {},
    resetBEScratch: noop,
    results: Immutable.List(),
    saveBEscratch: noop,

    searchTrigger: noop,

    searchedTerm: null,

    selectServiceDepartment: noop,

    selectedFaq: null,
    setDepartmentIDSelected: noop,
    setFaqBEScratch: noop,
    setSelectedFromFAQ: noop,
    submitNoMatch: noop,
    taskSelected: null,
  };

  state = {
    checked: null,
    serviceDepartmentSelected: null,
  };

  componentDidMount() {
    const {
      beScratch,
      changeValue,
      id,
      query: { title },
      resetBEScratch,
      selectedFaq,
      searchTrigger,
      setAlternateSkip,
      setAlternateSubmit,
      setDepartmentIDSelected,
      taskSelected,
    } = this.props;

    this.componentMounted = true;
    setAlternateSkip(this.handleNoMatch);
    setAlternateSubmit(this.handleSubmit);

    if (taskSelected && !taskSelected.isEmpty()) {
      setDepartmentIDSelected(taskSelected);
    }

    if ((selectedFaq && !selectedFaq.isEmpty()) || title) {
      searchTrigger(selectedFaq.get('trigger'), title); // Get FAQ list by trigger
      changeValue('searchFaq', selectedFaq.get('trigger') || title); // Set the default value to pass as search
    }

    if (
      (!beScratch.isEmpty() && beScratch.get('category_faq')) ||
      beScratch.get('faq_desc')
    ) {
      // Need to reset possible this data if the user went back from EDIT FAQ TEACH BARISTA condition
      const newBEScratch = {};
      if (beScratch.get('faq_trigger')) {
        newBEScratch.faq_trigger = beScratch.get('faq_trigger');
      }
      if (beScratch.get('faq_selected')) {
        newBEScratch.faq_selected = beScratch.get('faq_selected').toJS();
      }

      if (beScratch.get('faq_name_selected')) {
        newBEScratch.faq_name_selected = beScratch.get('faq_name_selected');
      }

      if (beScratch.get('case_trigger')) {
        newBEScratch.case_trigger = beScratch.get('case_trigger');
      }

      if (beScratch.get('faq_initial_question')) {
        newBEScratch.faq_initial_question = beScratch.get(
          'faq_initial_question'
        );
      }

      resetBEScratch(newBEScratch);
    }

    changeValue(id, selectedFaq.set('skip', 'noskip'));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { checked } = this.state;
    if (!nextProps.results.isEmpty() && nextProps.selectedFaq) {
      const findSelectedFAQ = nextProps.results.findIndex(
        (res) => res.get('eid') === nextProps.selectedFaq.get('faq_selected')
      );

      if (findSelectedFAQ > -1 && checked !== findSelectedFAQ) {
        this.setState({
          checked: findSelectedFAQ,
        }); // Select the FAQ checkbox
      }
    }
  }

  skipped = false;

  handleNoMatch = () => {
    this.skipped = true;
    const { submitNoMatch, id } = this.props;
    submitNoMatch(id);
  };

  handleSubmit = (cb) => {
    const {
      query: { title },
      results,
      saveBEscratch,
      setFaqBEScratch,
      setSelectedFromFAQ,
    } = this.props;
    const { checked } = this.state;

    if (isNumber(checked) || this.skipped) {
      if (isNumber(checked)) {
        const faqSelected = results.get(checked);
        // Send faqSelected has BE scratch value
        saveBEscratch({
          faq_name_selected: faqSelected.get('name'),
          isELC: faqSelected.get('is_protected') ? 'true' : 'false', // Use for the condition
          isKB: title ? 'true' : 'false',
        })
          .then(() => setSelectedFromFAQ(faqSelected.get('eid')))
          .then(() => {
            if (title) {
              return setFaqBEScratch(title);
            } else {
              return true;
            }
          })
          .then(() => {
            cb();
          });
      } else {
        cb();
      }
    }
  };

  handleSetTerm = debounce((e, term) => {
    const {
      changeValue,
      id,
      query: { title },
      searchTrigger,
    } = this.props;

    this.setState(
      {
        checked: null,
      },
      () => {
        searchTrigger(term, title);
        changeValue(
          id,
          Immutable.fromJS({
            skip: 'skip',
          })
        );
      }
    );
  }, TIMEOUT);

  handleOnChange = (index) => {
    const { changeValue, id, results } = this.props;

    if (this.componentMounted) {
      this.setState({
        checked: index,
      });

      changeValue(
        id,
        Immutable.fromJS({
          faq_selected: results.getIn([index, 'eid']),
          skip: 'noskip',
          trigger: results.getIn([index, 'matched_trigger']),
        })
      );

      changeValue('emtpy_field', true);
    }
  };

  handleSelectServiceDepartment = (e, value) => {
    const {
      changeValue,
      id,
      query: { title },
      myServiceDeparment,
      searchedTerm,
      searchTrigger,
      selectServiceDepartment,
    } = this.props;

    const optionsDepartment = this.buildServiceDepartmentOptions(
      myServiceDeparment
    );
    const selectedDepartment = optionsDepartment.filter((v) => v.id === value);

    if (selectedDepartment && selectedDepartment.length) {
      this.setState(
        {
          checked: null,
          serviceDepartmentSelected: value,
        },
        () => {
          changeValue(
            id,
            Immutable.fromJS({
              skip: 'skip',
            })
          );
          selectServiceDepartment(
            selectedDepartment[0].id,
            selectedDepartment[0].type
          );
          if (searchedTerm) {
            searchTrigger(searchedTerm, title); // Launch the term search
          }
        }
      );
    }
  };

  buildServiceDepartmentOptions = memoize((myServiceDeparment) =>
    myServiceDeparment
      .map((dep) => ({
        id: dep.get('id'),
        key: dep.get('id'),
        text: dep.get('name'),
        type: dep.get('type'),
        value: dep.get('id'),
      }))
      .toJS()
  );

  validate = (value) =>
    (value && !value.isEmpty() && value.get('faq_selected')) || this.skipped
      ? null
      : 'error';

  render() {
    const {
      id,
      isLoadingTrigger,
      myServiceDeparment,
      options,
      query: { title },
      results,
      template,
      type,
    } = this.props;
    const { checked } = this.state;

    const optionsDepartment = this.buildServiceDepartmentOptions(
      myServiceDeparment
    );
    const { serviceDepartmentSelected } = this.state;
    const disabledSearch = Boolean(title && !serviceDepartmentSelected);

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {title ? (
          <ValidatedField
            component={FormInputSelect}
            label='Please, select a service department'
            name='service_department'
            onChange={this.handleSelectServiceDepartment}
            options={optionsDepartment}
          />
        ) : null}

        <ValidatedField
          component={FormInputText}
          disabled={disabledSearch}
          icon='search'
          iconPosition='left'
          label={title ? 'Search an FAQ' : void 0}
          loading={isLoadingTrigger}
          name='searchFaq'
          onChange={this.handleSetTerm}
          options={options}
          search
        />

        <ValidatedField
          component={FormInputText}
          hidden
          name={id}
          validate={this.validate}
        />

        <Divider hidden />

        {!disabledSearch && (
          <div
            style={{
              paddingLeft: '2.2em',
            }}
          >
            {!isLoadingTrigger
              ? `${results.size} FAQs found`
              : 'Loading FAQ from trigger...'}
          </div>
        )}

        {!isLoadingTrigger
          ? results.map((res, i) => (
              <FaqItem
                checked={i === checked}
                description={res.get('description')}
                index={i}
                key={res.get('eid')}
                matchedTrigger={res.get('matched_trigger')}
                name={res.get('name')}
                onChange={this.handleOnChange}
              />
            ))
          : null}
      </div>
    );
  }
}

export const SearchSelectFAQ01Test = SearchSelectFAQ01;

export default SearchSelectFAQ01Controller(SearchSelectFAQ01);
