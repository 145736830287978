import { createSelector } from 'reselect';
import immutable from 'immutable';
import _ from 'lodash';

// Global
import BundleType from '../globals/BundleType';

// from state
const getProductImagesByID = (state) =>
  state.getIn(['entities', 'productImages']);

const getProductImageIDsByBundleItem = (state) =>
  state.getIn(['bundles', 'bundleItemsImages']);

const bundle = (state, { bundleID = null, type = '', bundle = null }) => {
  if (bundle && bundle.size !== 0) {
    return bundle;
  }

  const bundleType =
    type === BundleType.ROLE_BUNDLE
      ? 'searchResultsRoleBundles'
      : 'searchResultsBundles';
  if (bundleID) {
    return state
      .getIn(['bundles', bundleType, 'searchResults'])
      .find((b) => b.get('id') === bundleID);
  } else {
    return state.getIn(['bundles', 'currentBundle']);
  }
};

const getBundleImages = (
  bundle = immutable.Map(),
  productImagesByID,
  productImageIDsByBundleItem
) => {
  if (bundle.isEmpty()) {
    return immutable.List();
  }

  const items = bundle.get('items') || immutable.List();

  return items
    .map((item) => {
      const bundleItemID = item.get('id');

      if (!productImageIDsByBundleItem.has(bundleItemID)) {
        return null;
      }

      const productImageID = productImageIDsByBundleItem.get(bundleItemID);
      const productImage = productImagesByID.get(productImageID);

      return productImage;
    })
    .filter(_.identity)
    .take(3);
};

const selector = createSelector(
  [bundle, getProductImagesByID, getProductImageIDsByBundleItem],
  getBundleImages
);

export default selector;
