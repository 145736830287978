import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';
import EspReactIcon from '../../../../v1/components/atoms/EspReactIcon';
// Controller
import ChatMessageButtonController from './ChatMessageButtonController';
import ChatInputTypes from './ChatInputTypes';
import ChatFeedbackIcons from './ChatFeedbakIcons';

const rule = (props) => {
  const { input, theme } = props;

  const isFeedback = input.type === ChatInputTypes.FEEDBACK;

  return {
    '> .ui.button': {
      ':hover': {
        backgroundColor: theme.colors.white,
      },
      backgroundColor: 'transparent',
      color: theme.brandTenant.primaryColor,
    },
    alignItems: isFeedback ? 'baseline' : 'normal',
    display: isFeedback ? 'flex' : 'block',
    justifyContent: 'space-between',
  };
};

// Show icons in the order of sentiment
const feedbackNameIcon = ['sad', 'meh', 'happy', 'great', 'amazing'];

class ChatMessageButton extends PureComponent {
  static propTypes = {
    // channelId : PropTypes.string,
    className: PropTypes.string.isRequired,
    input: PropTypes.shape({
      handleOptionSelection: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      options: PropTypes.array,
      readOnly: false,
      type: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    isCaseMgmt: PropTypes.bool, // passed directly. If passed then it's assumed this chat belongs to a case
    onUserSelection: PropTypes.func,
  };

  static defaultProps = {
    // channelId       : '',
    isCaseMgmt: false,
    onUserSelection: noop,
  };

  state = {
    hasBeenClicked: false,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // When the props for input change, assume nothing is clicked
    // if the state changed, then the input.value should be updated
    const { input } = this.props;

    if (nextProps.input !== input) {
      this.setState({
        hasBeenClicked: false,
      });
    }
  }

  handleOnClick = memoize(
    curry((clickedOption, e) => {
      const { input, onUserSelection } = this.props;
      const { hasBeenClicked } = this.state;

      if (hasBeenClicked) {
        return;
      }

      if (input.handleOptionSelection) {
        input.handleOptionSelection(clickedOption);
      } else if (clickedOption) {
        onUserSelection(clickedOption);
      }

      this.setState({
        hasBeenClicked: true,
      });
    })
  );

  render() {
    const { isCaseMgmt, className, input } = this.props;
    const { hasBeenClicked } = this.state;

    return (
      <div className={className} data-component='ChatMessageButton'>
        {input.options.map((option, i) => {
          const isLast = input.options.length - 1 === i;
          const isSelected = option.value === input.value;
          // as per DEV-3300 we need to make this disabled when IS used in cases as well (also DEV-6822)
          const isOptionDisabled =
            input.value || hasBeenClicked || isCaseMgmt || input.readOnly;

          const uniqueKey = `${option.pk || option.id}.${option.label}`;

          if (input.type === ChatInputTypes.RADIO) {
            return (
              <Button
                content={option.label}
                disabled={hasBeenClicked}
                fluid
                icon={isSelected ? 'dot circle outline' : 'circle outline'}
                key={uniqueKey}
                onClick={this.handleOnClick(option)}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  cursor: isOptionDisabled ? 'default' : 'pointer',
                  marginBottom: isLast ? 0 : '.25em',
                  opacity: !isOptionDisabled || isSelected ? 1 : 0.35,
                  // paddingLeft: '.5em',
                  pointerEvents: isOptionDisabled ? 'none' : 'initial',
                  textAlign: 'center',
                  textTransform: 'initial',
                }}
              />
            );
          }
          if (input.type === ChatInputTypes.FEEDBACK) {
            return (
              <Button
                content={option.label}
                disabled={hasBeenClicked}
                icon={
                  <EspReactIcon
                    name={
                      ChatFeedbackIcons[feedbackNameIcon.at(option.value - 1)]
                    }
                    style={{
                      display: 'block',
                      fontSize: '3rem',
                      height: '3rem',
                      lineHeight: 1,
                      margin: '0 auto .25rem',
                    }}
                  />
                }
                key={uniqueKey}
                onClick={this.handleOnClick(option)}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  cursor: isOptionDisabled ? 'default' : 'pointer',
                  fontSize: '.75em',
                  marginBottom: isLast ? 0 : '.25em',
                  maxWidth: '80px',
                  opacity: !isOptionDisabled || isSelected ? 1 : 0.35,
                  padding: '0',
                  pointerEvents: isOptionDisabled ? 'none' : 'initial',
                  textTransform: 'initial',
                  width: '80px',
                }}
              />
            );
          } else {
            return (
              <Button
                basic
                content={option.label}
                disabled={hasBeenClicked}
                fluid
                key={uniqueKey}
                onClick={this.handleOnClick(option)}
                primary
                style={{
                  marginBottom: isLast ? 0 : '.25em',
                  textTransform: 'initial',
                }}
              />
            );
          }
        })}
      </div>
    );
  }
}
// eslint-disable-next-line no-class-assign -- DEV-1526
ChatMessageButton = ChatMessageButtonController(ChatMessageButton);
export default createComponent(rule, ChatMessageButton, [
  'channelId',
  'input',
  'isCaseMgmt',
  'onUserSelection',
]);
