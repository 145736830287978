import React, { useCallback, useRef, useState } from 'react';
import pt from 'prop-types';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '@espressive/cascara';

const propTypes = {
  className: pt.string,
  // Function invoked on textarea height change, with height as first argument. The second function argument is an object containing additional information that might be useful for custom behaviors. Current options include { rowHeight: number }.
  onHeightChange: pt.func,
  // This function will return the original synthetic event, and the value of ChatForm
  onSubmit: pt.func.isRequired,
};

const ChatForm = ({ className, onSubmit, onHeightChange, ...rest }) => {
  const inputRef = useRef();

  // NOTE: the state for this component will need to become more complex later as we
  // need to support the ability to add attachments, images, etc.
  const [textareaValue, setTextareaValue] = useState();

  const handleTextareaChange = useCallback(
    (e) => setTextareaValue(e?.target?.value),
    [setTextareaValue]
  );

  // We are returning the original synthetic event. If someone needs to prevent
  // default they can do `e.preventDefault()` in their onSubmit function.
  const handleSubmit = useCallback(
    (e) => {
      // Return the original synthetic event, and then the value
      onSubmit(e, textareaValue);
      // Set the controlled component value to nothing
      setTextareaValue('');
      // Place focus back on the textarea after submitting
      inputRef?.current?.focus();
    },
    [onSubmit, textareaValue, setTextareaValue]
  );

  // NOTE: For now users can press Tab and move to the Send button where
  // they can then hit enter to submit the form. We likely should evaluate
  // options for doing command + Enter or control + Enter for invoking
  // the handleSubmit function. There are accessibility issues here that we
  // should be aware of and make sure we are following. accesskeys would
  // present problems.

  const formClassNames = classnames({
    [className]: true,
    'ui form': true,
  });

  return (
    <form {...rest} className={formClassNames} onSubmit={handleSubmit}>
      <div className='fields' style={{ marginBottom: 0 }}>
        <div className='twelve wide field'>
          <TextareaAutosize
            onChange={handleTextareaChange}
            onHeightChange={onHeightChange}
            placeholder='Type a message...'
            ref={inputRef}
            rows={1}
            value={textareaValue}
          />
        </div>
        <div className='four wide field'>
          <Button content='Send' fluid type='submit' />
        </div>
      </div>
    </form>
  );
};

ChatForm.propTypes = propTypes;

export default ChatForm;
