import { useMutation, useQueryClient } from '@tanstack/react-query';

import _APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const useMutateTenant = () => {
  const queryClient = useQueryClient();

  const tenantQueryName = 'tenantQuery';

  return useMutation(
    async ({ tenantID, data, mutationActions }) => {
      const newData = await _APIcall.patch({
        data,
        token: true,
        url: endpointGenerator.genPath('espTenant.tenants.instance', {
          tenantID,
        }),
      });
      if (mutationActions) {
        mutationActions();
      }

      return newData;
    },
    {
      onSuccess: ({ body }) => {
        queryClient.setQueryData([tenantQueryName, { id: body.id }], body);
        queryClient.fetchQuery([tenantQueryName]);
      },
    }
  );
};

export default useMutateTenant;
