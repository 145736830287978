import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  image: {
    default: {},
    objectShape: {
      onSubmit: {
        required: true,
        type: 'string',
      },
      type: {
        requred: true,
        type: 'string',
      },
    },
    type: 'object',
  },
  modalButtonText: {
    required: true,
    type: 'string',
  },
  modalText: {
    required: true,
    type: 'string',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'systemAvatars01',
  },
});

// Object Definition used by blobifier
const SystemAvatarBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'System Avatar',
  templates: ['systemAvatars01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, SystemAvatarBlockDefinition, TypeDefaults };
