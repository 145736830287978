import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Modal } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable, { fromJS } from 'immutable';

// Controllers
import LaunchListPickerController from './LaunchListPickerController';
import RenderList from './RenderList';
import EspModal from '../../../display/EspModal';

let LaunchListPicker = class LaunchListPicker extends PureComponent {
  static propTypes = {
    channelId: PropTypes.string.isRequired,
    content: PropTypes.string,
    listItems: ImmutablePropTypes.list.isRequired,

    selectItem: PropTypes.func,

    // From message API metadata.user_input.select_special
    seletNoneLabel: PropTypes.string,
  };

  static defaultProps = {
    content: 'View List',
    selectItem: void 0,
    seletNoneLabel: 'Select None',
  };

  state = {
    isModalOpen: false,
    itemSelected: Immutable.Map(),
    removeSelection: false,
  };

  clearState = () => {
    this.setState({
      itemSelected: Immutable.Map(),
      removeSelection: true,
    });
  };

  handleToggleModal = () =>
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));

  handleItemSelected = (item) =>
    this.setState({
      itemSelected: item,
      removeSelection: false,
    });

  handleConfirmSelection = () => {
    const { selectItem, channelId } = this.props;
    const { itemSelected } = this.state;

    selectItem(itemSelected, channelId);
    this.clearState();
    this.handleToggleModal();
  };

  handleRemoveSelection = () => {
    const { selectItem, channelId, seletNoneLabel } = this.props;

    selectItem(fromJS({}), channelId, seletNoneLabel);
    this.clearState();
    this.handleToggleModal();
  };

  modalCloseButton = (
    <span
      className='close'
      onClick={this.handleToggleModal}
      onKeyPress={this.handleToggleModal}
      style={{ fontSize: '1em' }}
    >
      {'Exit'}
    </span>
  );

  render() {
    const { content, listItems, seletNoneLabel } = this.props;
    const { itemSelected, removeSelection, isModalOpen } = this.state;

    const actions = (
      <>
        <Button
          basic
          content={seletNoneLabel}
          onClick={this.handleRemoveSelection}
        />
        <Button
          content='Select'
          disabled={!itemSelected.get('label')}
          onClick={this.handleConfirmSelection}
          primary
        />
      </>
    );
    return (
      <EspModal
        actions={actions}
        closeIcon={this.modalCloseButton}
        content={
          <RenderList
            listItems={listItems}
            onHandleItemSelected={this.handleItemSelected}
            removeSelection={removeSelection}
          />
        }
        contentPadding='1em'
        header={
          <Header
            content='Choose an option from the list below'
            style={{ fontSize: '1em' }}
          />
        }
        open={isModalOpen}
        size='small'
        trigger={
          <Modal.Actions className='barista'>
            <Button
              basic
              content={content}
              onClick={this.handleToggleModal}
              primary
            />
          </Modal.Actions>
        }
      />
    );
  }
};

LaunchListPicker = LaunchListPickerController(LaunchListPicker);

export default LaunchListPicker;
