import localStorageMemory from 'localstorage-memory';
import _ from 'lodash';
import sjcl from 'sjcl';

import PersistentStorageKeys, {
  getStorageKey,
  IS_TOKEN_ENCRYPTED,
  TOKEN_KEY,
} from './globals/PersistentStorageKeys';

// See https://gist.github.com/yetanotherchris/99f107d6ead03d467ea61b00456d41a7
const encryptionOptions = {
  // don't ever change these or we won't be able to decrypt stored tokens
  cipher: 'aes', // cipher
  ks: 128, // key size in bits
  mode: 'ccm', // mode
  ts: 64, // authentication strength
  v: 1, // version
};

// This could really be anything, as long as it's not a string in our code base
// I'm using this as this is really a good constant per tenant that is available before we
// load anything
const secretPhraseWord = window.location.host;

const encryptToken = (plainToken = '') => {
  const password = secretPhraseWord;
  const text = plainToken;
  const cipherTextJson = sjcl.encrypt(password, text, encryptionOptions);
  return cipherTextJson; // stringified json
};

const decryptToken = (cipherTextJson = '') => {
  const password = secretPhraseWord;
  const decryptedToken = sjcl.decrypt(password, cipherTextJson);
  return decryptedToken;
};
// in Safari, the localStorage doesn't work on private mode and crashes the app
let localStorageImpl;

try {
  window.localStorage.setItem(
    'TEST_BROKEN_LOCAL_STORAGE',
    'TEST_BROKEN_LOCAL_STORAGE'
  );
  window.localStorage.removeItem('TEST_BROKEN_LOCAL_STORAGE');
  localStorageImpl = window.localStorage;
} catch (e) {
  localStorageImpl = localStorageMemory;
}

const persistentStorage = {
  get(key) {
    try {
      const value = JSON.parse(localStorageImpl.getItem(getStorageKey(key)));
      const returnValue = value.data;

      // Returning an decrypted token if it exist an encrypted one in session
      if (key === PersistentStorageKeys.SESSION) {
        // if token exist, check if the token is unencrypted
        if (_.has(returnValue, TOKEN_KEY) && returnValue[IS_TOKEN_ENCRYPTED]) {
          const encryptedToken = returnValue[TOKEN_KEY];
          const decryptedToken = decryptToken(encryptedToken);

          returnValue[TOKEN_KEY] = decryptedToken;
        }
      }

      return returnValue;
    } catch (e) {
      return null;
    }
  },

  has(key) {
    return this.get(key) !== null;
  },

  put(key, value) {
    // encrypt the token value if we're saving the session
    if (key === PersistentStorageKeys.SESSION) {
      // if token exist, check if the token is unencrypted
      if (
        _.has(value, TOKEN_KEY) &&
        value[TOKEN_KEY] !== '' &&
        !value[IS_TOKEN_ENCRYPTED]
      ) {
        value[TOKEN_KEY] = encryptToken(value[TOKEN_KEY]);
        value[IS_TOKEN_ENCRYPTED] = true; // marks that have encrypted this, so we don't rely on char lengths
      }
    }

    // in cordova, the localStorage might be flushed cleared by the OS for performance reasons
    // So it's not quite persistent. We use NativeStorage to add a persistency layer.
    // See https://stackoverflow.com/questions/32761099/cordova-ios-app-loosing-value-stored-using-window-localstorage
    if (window.NativeStorage) {
      window.NativeStorage.setItem(getStorageKey(key), value, _.noop, _.noop);
    }

    localStorageImpl.setItem(
      getStorageKey(key),
      JSON.stringify({
        data: value,
      })
    );
  },

  remove(key) {
    // Removing this in the Native storage layer as well
    if (window.NativeStorage) {
      window.NativeStorage.remove(getStorageKey(key), _.noop, _.noop);
    }

    localStorageImpl.removeItem(getStorageKey(key));
  },
};

export default persistentStorage;
