const FulfillmentAnswerTypes = {
  // answer for a text line question
  CHECK_BOX: 'CHECK_BOX',

  // true or false
  DATE: 'DATE',

  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',

  // date
  REFERENCE: 'REFERENCE',
  // multiple choices
  TEXT: 'TEXT', // foreign key to external table outside espressive
};

export default FulfillmentAnswerTypes;
