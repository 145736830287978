import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';

const saveAuthSAML_SP = async (data) => {
  const url = endpointGenerator.genPath('authentication.samlSP');

  return _APIcall.post({
    data,
    token: true,
    url: url,
  });
};

export default saveAuthSAML_SP;
