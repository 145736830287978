import buildCompositeController from '../../../../../utils/buildCompositeController';
import integrationThunks from '../../../../../actions/integrationThunks';
import toastNotificationsActions from '../../../../../actions/toastNotificationsActions';
import { fromJS } from 'immutable';

const mapStateToProps = (state) => {
  const isTestingCredentials = state.getIn([
    'integration',
    'confluence',
    'settings',
    'isLoading',
  ]);
  const isConnectionValid = state.getIn([
    'integration',
    'confluence',
    'settings',
    'isConnectionValid',
  ]);
  const credentials = state.getIn(
    ['integration', 'confluence', 'settings', 'credentials'],
    fromJS({})
  );
  const isSubmitting = state.getIn([
    'integration',
    'confluence',
    'settings',
    'credentials',
    'isSubmitting',
  ]);
  return {
    credentials,
    isConnectionValid,
    isSubmitting,
    isTestingCredentials,
  };
};
const testCredentialsVsValues = (credentials, values) => {
  const isNotPreSavedCredentials =
    !credentials.get('url') &&
    !credentials.get('username') &&
    !credentials.get('api_token');
  const editedCredentials =
    credentials.get('url') !== values.url ||
    credentials.get('username') !== values.username ||
    credentials.get('api_token') !== values.api_token;
  return {
    testSettingsWithValues: isNotPreSavedCredentials || editedCredentials,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCredentials: () => {
    dispatch(integrationThunks.getConfluenceCredentials());
  },
  setCredentials: (values, credentials) => {
    const tester = testCredentialsVsValues(credentials, values);
    dispatch(
      integrationThunks.testConfluenceSettings(
        tester.testSettingsWithValues ? values : void 0
      )
    ).then((response) => {
      if (response.CONNECTION_IS_VALID) {
        dispatch(
          integrationThunks.setConfluenceCredentials(
            tester.testSettingsWithValues ? values : void 0
          )
        );
      } else {
        dispatch(
          toastNotificationsActions.error({
            message: 'Invalid Credentials',
            title: 'Error:',
          })
        );
      }
    });
  },
  testCredentials: (values, credentials) => {
    const tester = testCredentialsVsValues(credentials, values);
    dispatch(
      integrationThunks.testConfluenceSettings(
        tester.testSettingsWithValues ? values : void 0
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
