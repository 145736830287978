import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import TypeLoading, { TypeDefaults } from './TypeLoading';
import { Dimmer, Loader } from 'semantic-ui-react';

const rule = () => ({});

class Loading01 extends PureComponent {
  static propTypes = TypeLoading;
  static defaultProps = TypeDefaults;

  render() {
    const { className, type, template } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        <Dimmer active inverted>
          <Loader size='large' />
        </Dimmer>
      </div>
    );
  }
}

export default createComponent(rule, Loading01, [
  'template',
  'type',
  'id',
  'trigger',
]);
