import React from 'react';
import { Header } from 'semantic-ui-react';
import pt from 'prop-types';
import DateTools from '../../../../../../utils/DateTools';

const propTypes = {
  createdDate: pt.string,
};

const SyncInProgress = ({ createdDate }) => {
  return (
    <div>
      <Header as='h4'>{'Sync In Progress...'}</Header>
      <p>{`Initiated on ${DateTools.getDateFromUTCToLocal({
        date: createdDate,
        toLocaleDateString: true,
      })}`}</p>
    </div>
  );
};

SyncInProgress.propTypes = propTypes;

export default SyncInProgress;
