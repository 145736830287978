import { isPristine } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import catalogThunks from '../../actions/catalogThunks';
// Actions
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state, ownProps) => {
  const { productId } = ownProps;
  const fulfillmentAnswers = state.getIn([
    'catalog',
    'fulfillmentAnswers',
    productId,
  ]);
  const fulfillmentOptions = state.getIn([
    'catalog',
    'fulfillmentOptions',
    productId,
  ]);
  const isSavingFulfillmentAnswers = state.getIn([
    'catalog',
    'isSavingFulfillmentAnswers',
    productId,
  ]);

  return {
    fulfillmentAnswers,
    fulfillmentOptions,
    isFormLoading: isSavingFulfillmentAnswers,
    isFormPristine: isPristine(`FulfillmentOptions.${productId}`)(state),
    productId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFulfillmentOptionsAndAnswers: (productId) => {
    // we're blessed yet again with another very usable API design /s
    // because of that, we need to:
    // 1) Load any possible answer first
    // 2) then get fulfillment options
    // 3) Once having both, we need to map the options we have against the answers
    // 4) From there depending on an ansert existing or not, we might need to POST
    // or PATCH any edition to said answer
    dispatch(catalogThunks.loadFulfillmentAnswers(productId)).finally(() => {
      // we want to do this rewarding of success/failure of the ansers
      dispatch(catalogThunks.loadFulfillmentOptions(productId));
    });
  },

  onSubmit: (fieldValues, productId) => {
    dispatch(catalogThunks.saveFulfillmentAnswers(productId, fieldValues))
      .then(() => {
        // we need to reload the fulfillment answers as they might have been modified
        // by the async thunk saveFulfillmentAnswers
        dispatch(catalogThunks.loadFulfillmentAnswers(productId));

        dispatch(
          toastNotificationsActions.success({
            dismissAfter: 3 * 1000,
            message: 'All answers saved',
          })
        );
      })
      .catch(() => {
        dispatch(
          toastNotificationsActions.error({
            dismissAfter: 3 * 1000,
            message: 'Errors saving answers. See console for details',
          })
        );
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
