import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Portal } from 'semantic-ui-react';

const rule = (props) => {
  const { width, height, zIndex } = props;

  const landscape = width > height;

  let basicHeight = landscape ? '100%' : 'auto',
    basicWidth = landscape ? 'auto' : '100%',
    reverseHeight = landscape ? 'auto' : '100%',
    reverseWidth = landscape ? '100%' : 'auto';

  if (width <= height) {
    basicWidth = landscape ? '100%' : 'auto';
    basicHeight = landscape ? 'auto' : '100%';
    reverseWidth = landscape ? 'auto' : '100%';
    reverseHeight = landscape ? '100%' : 'auto';
  }

  const basicMedia = `@media screen and (max-aspect-ratio: ${width}/${height})`;
  const biggerMedia = `@media screen and (min-aspect-ratio: ${
    width + 1
  }/${height})`;

  const imgStyle = {
    [basicMedia]: {
      height: basicHeight,
      width: basicWidth,
    },
    [biggerMedia]: {
      height: reverseHeight,
      width: reverseWidth,
    },
    left: '50%',
    maxWidth: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  };

  return {
    '> img': imgStyle,
    '> img.ui.image': imgStyle,
    height: '100vh',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: zIndex,
  };
};

class BackgroundImagePortal extends PureComponent {
  static propTypes = {
    /** Classname passed from fela */
    className: PropTypes.string.isRequired,
    /** Image height */
    height: PropTypes.number,
    /** The background <img> tag */
    image: PropTypes.element,
    /** Image width */
    width: PropTypes.number,
    /** Z-index of the portal - Optional for storybook */
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    height: void 0,
    image: void 0,
    width: void 0,
    zIndex: -1,
  };

  render() {
    // eslint-disable-next-line no-unused-vars -- it will get fixed when fella is removed from this component
    const { className, height, image, width, zIndex } = this.props;

    return (
      <Portal open>
        <div className={className}>{image}</div>
      </Portal>
    );
  }
}

export default createComponent(rule, BackgroundImagePortal, [
  'height',
  'image',
  'width',
  'zIndex',
]);
