import { CURRENT_PLATFORM } from '../__globals';

const downloadAttachmentHandlers = {
  // eslint-disable-next-line no-alert -- temp dev function
  web: () => alert('This is the default handleDownload()'),
};

const handleDownloadAttachment =
  downloadAttachmentHandlers[CURRENT_PLATFORM] ||
  downloadAttachmentHandlers['web'];

export { handleDownloadAttachment };
