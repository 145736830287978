import React, { useEffect } from 'react';
import { Dropdown, Grid, Header, Message } from 'semantic-ui-react';
import useUpdateChatExperience from '../../../customHooks/tenantCustomization/useUpdateChatExperience';
import { useTenantHook } from '../../../customHooks';

const propTypes = {};

const chatExperiences = [
  {
    key: 'chatv1',
    text: 'Classic Barista',
    value: 'chatv1',
  },
  {
    key: 'chatv2',
    text: 'Modern Barista',
    value: 'chatv2',
  },
];
const TenantConfigSorterDefault = () => {
  const { chatExperience, handleToggleHomefeedSorting, isChatV2Enabled } =
    useUpdateChatExperience();
  const tenant = useTenantHook();
  const { refetchTenant, tenantInfo } = tenant;

  useEffect(() => {
    if (!tenantInfo?.id) {
      console.error('Tenant ID not found'); // eslint-disable-line no-console -- debugging
      refetchTenant();
    }
  }, [refetchTenant, tenantInfo?.id]);

  return isChatV2Enabled ? (
    <Grid centered>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as='h4' dividing>
            Chat Experience
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={6}>
          <label>{'Select the default chat experience'}</label>
          {tenantInfo?.id && (
            <Dropdown
              name='homefeedSorting'
              onChange={handleToggleHomefeedSorting}
              options={chatExperiences}
              placeholder={'Select filter'}
              search
              selection
              style={{ marginLeft: '1rem' }}
              value={chatExperience || ''}
            />
          )}
        </Grid.Column>
        <Grid.Column width={10}>
          <Message info>
            <p>Select the default chat experience for all users </p>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : null;
};

TenantConfigSorterDefault.propTypes = propTypes;
export default TenantConfigSorterDefault;
