import { fromJS } from 'immutable';

/**
 * Immutable List of custom Label in use in the APP and Workflow
 * This list should growth time to time
 * @type {Immutabe List}
 */
const customLabels = fromJS({
  MENTOR: {
    key: 'label.custom.mentor',
    locale: ['en'],
    name: 'mentor',
  },
});

/**
 * List of Language suppoerted and use in the Select language field
 * @type {[*]}
 */
export const languageOptions = [
  {
    key: 1,
    text: 'EN',
    value: 'en',
  },
];
export default customLabels;
