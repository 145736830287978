import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';

import React, { PureComponent } from 'react';
import { Button, List } from 'semantic-ui-react';

import { noop } from 'lodash';

// Controllers
import FulfillmentQuestionsController from '../controllers/FulfillmentQuestionsController';
// Molecules
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';

class FulfillmentQuestions extends PureComponent {
  static propTypes = {
    fulfillmentAnswers: ImmutablePropTypes.list,
    fulfillmentOptions: ImmutablePropTypes.list,
    isFormLoading: PropTypes.bool,
    isFormPristine: PropTypes.bool,
    loadFulfillmentOptionsAndAnswers: PropTypes.func,
    onSubmit: PropTypes.func,
    productId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    fulfillmentAnswers: Immutable.List(),
    fulfillmentOptions: Immutable.List(),
    isFormLoading: false,
    isFormPristine: true,
    loadFulfillmentOptionsAndAnswers: noop,
    onSubmit: noop,
  };

  componentDidMount() {
    const { productId, loadFulfillmentOptionsAndAnswers } = this.props;

    loadFulfillmentOptionsAndAnswers(productId);
  }

  handleSubmit = (fieldValues) => {
    const { onSubmit, productId } = this.props;
    onSubmit(fieldValues, productId);
  };

  render() {
    const {
      isFormLoading,
      isFormPristine,
      productId,
      fulfillmentOptions,
      fulfillmentAnswers,
    } = this.props;

    if (!fulfillmentOptions || fulfillmentOptions.isEmpty()) {
      return null;
    }

    const initialValues = {};
    fulfillmentOptions.forEach((fOption) => {
      const answer = fulfillmentAnswers.find(
        (a) => a.get('option') === fOption.get('id')
      );
      const defaultValue = answer ? answer.get('value') : '';

      initialValues[String(fOption.get('id'))] = defaultValue;
    });

    return (
      <ValidatedForm
        enableReinitialize
        form={`FulfillmentOptions.${productId}`}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
      >
        <List ordered>
          {fulfillmentOptions.map((fOption) => (
            <List.Item key={fOption.get('id')}>
              <List.Content>
                <List.Header content={fOption.get('question')} />
                <List.Content content={`Type: ${fOption.get('type')}`} />
                {fOption.get('type') === 'REFERENCE' ? (
                  <List.Description
                    content={`Table: ${fOption.get('table_name')}`}
                  />
                ) : null}

                {fOption.get('choices') && !fOption.get('choices').isEmpty() ? (
                  <List.Content
                    content={`Choices: ${fOption
                      .get('choices')
                      .map((c) => c.get('value'))
                      .join(', ')}`}
                  />
                ) : null}

                <List.Content>
                  {'Default value: '}
                  <ValidatedField
                    key={fOption.get('id')}
                    labelPosition='left'
                    name={String(fOption.get('id'))}
                    size='mini'
                  />
                </List.Content>
              </List.Content>
            </List.Item>
          ))}
        </List>

        <Button
          content={'Submit'}
          disabled={isFormLoading || isFormPristine}
          floated='left'
          loading={isFormLoading}
          positive
          primary
          type='submit'
        />
      </ValidatedForm>
    );
  }
}

const FulfillmentQuestionsTest = FulfillmentQuestions;

// eslint-disable-next-line no-class-assign -- DEV-1526
FulfillmentQuestions = FulfillmentQuestionsController(FulfillmentQuestions);
export { FulfillmentQuestionsTest };
export default FulfillmentQuestions;
