import departmentsActions from './departmentsActions';
import createHierarchyThunks from './createHierarchyThunks';

const departmentsThunks = createHierarchyThunks({
  getChildrenNodes: {
    fail: departmentsActions.getChildrenDepartmentsFail,
    start: departmentsActions.getChildrenDepartmentsStart,
    success: departmentsActions.getChildrenDepartmentsSuccess,
  },

  getDefaultSelectedNode: {
    fail: departmentsActions.getDefaultSelectedDepartmentFail,
    start: departmentsActions.getDefaultSelectedDepartmentStart,
    success: departmentsActions.getDefaultSelectedDepartmentSuccess,
  },

  getRootNodes: {
    fail: departmentsActions.getRootDepartmentsFail,
    start: departmentsActions.getRootDepartmentsStart,
    success: departmentsActions.getRootDepartmentsSuccess,
  },

  nodeInstanceEndpoint: 'espUser.department.instance',

  nodeInstanceEndpointIDAttrName: 'departmentID',

  nodeTypeEndpoint: 'espUser.departmentType',

  nodesCollectionEndpoint: 'espUser.department',
});

export default departmentsThunks;
