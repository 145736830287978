import actionTypes from '../actions/actionTypes';
import electronActions from '../actions/electronActions';
import store from '../stores/store';
import browserHistory from '../utils/browserHistory';
import { getCardDetailPath } from '../utils/CordovaUtils';

/**
 * Electron middleware
 * Used to connect messages send in electron main process via ipcRenderer
 * To any redux action you want to dispatch
 *
 */

const electronMiddleware = (function () {
  if (window.electron) {
    window.electronAPI.ipcOn('update-not-available', (event, arg) => {
      // eslint-disable-next-line no-console -- debugging
      console.warn('Desktop Update not available', arg);
      store.dispatch(electronActions.updateNotAvailable());
    });
    window.electronAPI.ipcOn('log-message', (event, arg) => {
      // eslint-disable-next-line no-console -- debugging
      console.warn('Log received from electron:', arg);
    });
  }

  // Reducer-like middleware.
  // Not a real reducer. We only listen for actions here
  // and perform side effects
  return (store) => (next) => (action) => {
    if (window.electron) {
      switch (action.type) {
        // any action we catch and want to do something with it, e.g.
        case actionTypes.ELECTRON_NOTIFICATION: {
          const message = action.payload ? action.payload.message : 'hmmm';
          const title = 'Barista';

          const desktopNotification = new Notification(
            title, // we can use anything here really
            {
              body: message,
            }
          );

          desktopNotification.onclick = () => {
            // In the future we can do whatever we want when a notification is clicked
            // right now we do nothing as the story doesn't require anything
            // console.log('Notification clicked');

            // Redirects towards card details
            const cardDetailPath = getCardDetailPath(action.payload.url);
            if (cardDetailPath.isValid) {
              browserHistory.push({ pathname: cardDetailPath.path });
              window.electronAPI.ipcSend('notification-clicked');
            }
          };

          return store;
        }
        default:
          return next(action);
      }
    }

    return next(action);
  };
})();

export default electronMiddleware;
