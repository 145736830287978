import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { intl } from 'esp-util-intl';

const rule = () => ({
  '& .ui.image': {
    padding: '0',
    // display : 'inline-block',
    width: '5rem',
  },
  '&.ui.list.relaxed.divided': {
    '& .content': {
      display: 'flex',
      padding: '0',
    },
    '& .description': {
      alignSelf: 'center',
      fontSize: '1em',
      marginLeft: '1rem',
    },
    '& > .item': {
      padding: '1rem',
    },
  },
});

class AnnouncementCategorySelectList extends PureComponent {
  static propTypes = {
    categories: ImmutablePropTypes.list.isRequired,
    className: PropTypes.string.isRequired,
  };
  static defaultProps = {};

  render() {
    const { categories, className } = this.props;
    return (
      <List
        className={`${className} AnnouncementCategorySelectList`}
        divided
        relaxed
      >
        {categories.map((category) => {
          const handleClick = category.get('handler');
          const ImgSection = category.get('element');
          return (
            <List.Item key={category.get('id')} onClick={handleClick}>
              <List.Content>
                <span className='ui image'>
                  <ImgSection />
                </span>

                <List.Description
                  content={intl.formatMessage({
                    id: `category.${intl.prepareKey(category.get('name'))}`,
                  })}
                />
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    );
  }
}

export default createComponent(rule, AnnouncementCategorySelectList, [
  'categories',
]);
