import React, { useCallback, useEffect } from 'react';
import { DEFAULT_ROW_ACTIONS, PROP_TYPES } from './__globals';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { usePaginationState } from 'cascara-middleware';
import { ActionStack } from '@espressive/cascara';
import { useViewConfigState } from '@espressive/cascara/private';
import { useAPIGet, useAPISet } from 'esp-auth';
import { useFilterState } from '../Filter';
import TableLayout from '../TableLayout';
import { PageTitle } from '../../components';
import {
  getEspFiltersFromFilterState,
  getFilterStateFromEspFilters,
} from '../filter-normalizers';
import { getParamsFromPaginationState } from '../pagination-normalizers';
import castModuleValues from '../castModuleValues';

const propTypes = PROP_TYPES;

const SectionList = ({
  actions,
  basicSearchParams, // We might want to consider a resonable baseline of params that we check against OPTIONS if none are defined
  dataDisplay,
  initialDisplayParams,
  endpointUrl,
  onAction,
  pageTitle,
  pageTitleSub,
  resolveRecordActions,
  rowActions = DEFAULT_ROW_ACTIONS,
  uniqueIdAttribute = 'eid',
}) => {
  // WARNING:
  // In order to not get into re-render loops where we update the filter
  // state, then update the query params, then update the API in a circle,
  // we need to make sure we define our direction of flow for this data
  // and then one thing should set the other in series. Our source of truth
  // for filtering with this component is the URL itself. Update the URL
  // query params, and the API should rely on that state as the source of
  // filters, as should the filter component.
  const [searchParams, setSearchParams] = useSearchParams();

  // Be create an object from the searchParams of all of our query params on the current URL.
  const queryParamsObject = Object.fromEntries(searchParams.entries());

  // the queryParams are only being used for setting the initial states of our state hooks
  const filterInitialState = getFilterStateFromEspFilters(
    queryParamsObject.esp_filters
  );

  const paginationState = usePaginationState();
  const filterState = useFilterState({
    basicSearchParams,
    initialState: filterInitialState,
  });

  const { isEmptyFilter, value: filterValue } = filterState;

  const espFilters = getEspFiltersFromFilterState(filterValue);

  // This useEffect hook is when we set the search params in the URL. If we have
  // hooks that are not safely sealing away the values in their hook to prevent
  // re-rendering, we can get into re-render loops. All state hooks should be
  // initialized with initial state that is protected from updates on subsequent renders.
  useEffect(() => {
    const filtersObj = !isEmptyFilter && {
      esp_filters: espFilters,
    };

    setSearchParams({
      ...filtersObj,
    });
  }, [isEmptyFilter, espFilters, setSearchParams]);

  // Always create our selection of currently displaying modules from
  // the list of dataDisplay objects. If we do not pick our list of
  // selected objects from the provided list of possible objects, we
  // can have object conflicts inside the ViewConfig component.
  const initialSelectedObjects =
    initialDisplayParams?.map((displayParam) =>
      dataDisplay.find(({ attribute }) => attribute === displayParam)
    ) || dataDisplay;

  // this could potentially not be needed if we update the above to
  // warn users that there is a missing attribute in initialDisplayParams
  const initialSelection = initialSelectedObjects.filter(
    (obj) => obj !== undefined
  );

  // Initialize viewConfigState with the initial selection objects.
  // Make sure we do not try to pass an initial selection state object
  // if our value is still undefined or we are going to have a bad time.
  const viewConfigState = useViewConfigState(
    initialSelection && {
      initialSelection,
    }
  );

  // fetch data from endpoint
  const { data, refetch } = useAPIGet({
    query: {
      // Keep this as a spread operator so we can also keep track of pagination in the URL the same way
      ...queryParamsObject,
      ...getParamsFromPaginationState(paginationState),
    },
    url: endpointUrl,
  });

  // get a handle to navigation
  const navigate = useNavigate();

  // create the mutation function
  const { save } = useAPISet({
    url: `${endpointUrl}:id/`,
  });

  const handleTableAction = useCallback(
    async (action, record = {}) => {
      const recordId = record[uniqueIdAttribute];
      const detailUrl = recordId;
      const tempSafeRecord = castModuleValues(record, dataDisplay);

      switch (action?.name) {
        case 'edit.save':
          await save({ data: tempSafeRecord, params: { id: recordId } });
          refetch();
          break;
        // when clicking the View button, navigate to the details view of that row
        case 'view':
          navigate(`${detailUrl}`);
          break;

        case 'delete':
          // WIP
          break;

        default:
        // skip
      }
    },
    [dataDisplay, navigate, refetch, save, uniqueIdAttribute]
  );

  return (
    <>
      {pageTitle && (
        <PageTitle
          post={<ActionStack actions={actions} />}
          sub={pageTitleSub}
          title={pageTitle}
        />
      )}
      <TableLayout
        data={data?.data?.results}
        dataDisplay={dataDisplay}
        filterState={filterState}
        onAction={handleTableAction}
        paginationState={paginationState}
        resolveRecordActions={resolveRecordActions}
        rowActions={rowActions}
        totalRecordCount={data?.data?.count}
        uniqueIdAttribute={uniqueIdAttribute}
        viewConfigState={viewConfigState}
      />
    </>
  );
};

SectionList.propTypes = propTypes;

export default SectionList;
