import { createSelector } from 'reselect';
import immutable from 'immutable';

const bundle = (state, { bundle }) => bundle;

const products = (state) => state.getIn(['entities', 'products']);

const bundles = (state) => state.getIn(['entities', 'bundles']);

const getBundleItems = (
  bundle = immutable.Map(),
  products = immutable.Map(),
  bundles = immutable.Map()
) => {
  if (bundle.isEmpty() || products.isEmpty()) {
    return immutable.List();
  }

  const items = bundle.get('items') || immutable.List();

  return items
    .sortBy((b) => b.get('weight'))
    .map((item) => {
      if (item.get('product')) {
        return products.get(item.get('product')) || null;
      }

      if (item.get('bundle')) {
        return bundles.get(item.get('bundle')) || null;
      }

      return null;
    })
    .filterNot((i) => i === null);
};

const selector = createSelector([bundle, products, bundles], getBundleItems);

export default selector;
