import actionTypes from '../actions/actionTypes';
import { fromJS } from 'immutable';
import _ from 'lodash';

import ImmutableUtils from '../utils/ImmutableUtils';

const INITIAL_STATE = fromJS({
  // productConfiguration
  activeHardwareBundleProductImageIdx: 0,
  addNewBundle: false,
  addNewProduct: false,
  /** AdminRoleBundle entities */
  // bundleItemID -> productImageID mapping
  // product images are stored under sotre.entities.productImages
  bundleItemsImages: {},
  bundlesByTagLoaded: {},
  currentBundle: {},
  errorMessage: '',
  hardwareBundleId: null,
  hardwareBundleProductsIds: null,
  isLoading: false,
  // images
  isLoadingImages: {},
  // Bundle Job_Role Permissions
  isLoadingPermissions: false,
  /** AdminBundle entities */
  items: [],
  itemsVisited: '',
  job_role_permissions: {},
  query: {},
  searchResultsBundles: {
    isLoadingBundles: false,
    searchResults: [],
    searchResultsCount: 0,
    searchResultsNext: 0,
    searchResultsPrevious: 0,
  },
  searchResultsRoleBundles: {
    isLoadingRoleBundles: false,
    searchResults: [],
    searchResultsCount: 0,
    searchResultsNext: 0,
    searchResultsPrevious: 0,
  },
  searchTerm: '',

  selectedBundleId: '',

  selectedHardwareBundleProductId: null,

  temporaryImages: [],
  temporaryPermissions: [],
  tmpBundleItems: [],
  withError: false,
});

const onGetHardwareBundleProductsStart = (state) =>
  state
    .set(
      'activeHardwareBundleProductImageIdx',
      INITIAL_STATE.get('activeHardwareBundleProductImageIdx')
    )
    .set('hardwareBundleId', INITIAL_STATE.get('hardwareBundleId'))
    .set(
      'hardwareBundleProductsIds',
      INITIAL_STATE.get('hardwareBundleProductsIds')
    )
    .set(
      'selectedHardwareBundleProductId',
      INITIAL_STATE.get('selectedHardwareBundleProductId')
    );

const onGetHardwareBundleProductsSuccess = (
  state,
  hardwareBundle,
  hardwareBundleProducts
) => {
  const hardwareBundleId = hardwareBundle.get('id');
  const hardwareBundleProductsIds = ImmutableUtils.pluck(
    hardwareBundleProducts,
    'id'
  );
  const selectedHardwareBundleProductId = hardwareBundleProductsIds.first();

  return state
    .set('activeHardwareBundleProductImageIdx', 0)
    .set('hardwareBundleId', hardwareBundleId)
    .set('hardwareBundleProductsIds', hardwareBundleProductsIds)
    .set('selectedHardwareBundleProductId', selectedHardwareBundleProductId);
};

/**
 * Chat Reducer which fire action thought the dispatch switch
 * @param state
 * @param action
 * @returns {*}
 */
const bundlesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_BUNDLES_START: {
      let newState = state.set('isLoading', true);
      if (action.tag) {
        newState = newState.setIn(['bundlesByTagLoaded', action.tag], false);
      }
      return newState;
    }

    case actionTypes.LOAD_START_SEARCH_BUNDLES: {
      return state.setIn(['searchResultsBundles', 'isLoadingBundles'], true);
    }

    case actionTypes.LOAD_START_SEARCH_ROLE_BUNDLES: {
      return state.setIn(
        ['searchResultsRoleBundles', 'isLoadingRoleBundles'],
        true
      );
    }

    case actionTypes.LOAD_BUNDLES_SUCCESS: {
      let newState = state.set('isLoading', false).set('withError', false);
      if (action.tag) {
        newState = newState.setIn(['bundlesByTagLoaded', action.tag], true);
      }

      return newState;
    }

    case actionTypes.LOAD_BUNDLES_FAIL: {
      let newState = state
        .set('isLoading', false)
        .set('withError', true)
        .set('errorMessage', action.error);

      if (action.tag) {
        newState = newState.setIn(['bundlesByTagLoaded', action.tag], false);
      }

      return newState;
    }

    case actionTypes.BUNDLE_SELECTED: {
      return state.set('selectedBundleId', action.bundleId);
    }

    case actionTypes.GET_HARDWARE_BUNDLE_PRODUCTS_START: {
      return onGetHardwareBundleProductsStart(state);
    }

    case actionTypes.GET_HARDWARE_BUNDLE_PRODUCTS_SUCCESS: {
      return onGetHardwareBundleProductsSuccess(
        state,
        fromJS(action.hardwareBundle),
        fromJS(action.hardwareBundleProducts)
      );
    }

    case actionTypes.SET_ACTIVE_HARDWARE_BUNDLE_PRODUCT_IMAGE_IDX: {
      return state.set('activeHardwareBundleProductImageIdx', action.index);
    }

    case actionTypes.SELECT_HARDWARE_BUNDLE_PRODUCT: {
      return state.set('selectedHardwareBundleProductId', action.productId);
    }

    case actionTypes.WK_SAVE_SCRATCH_SUCESS:
      return state.set('isLoading', false);

    case actionTypes.BUNDLE_SET_SEARCH_TERM: {
      return state.set('searchTerm', action.searchTerm);
    }

    case actionTypes.BUNDLE_SET_SEARCH_BUNDLES_RESULTS: {
      let stateResultsBundle = state.get('searchResultsBundles');

      stateResultsBundle = stateResultsBundle
        .set('searchResults', fromJS(action.bundles.results))
        .set('searchResultsCount', fromJS(action.bundles.count))
        .set('searchResultsNext', fromJS(action.bundles.next))
        .set('searchResultsPrevious', fromJS(action.bundles.previous))
        .set('isLoadingBundles', false);

      return state.set('searchResultsBundles', stateResultsBundle);
    }

    case actionTypes.BUNDLE_SET_SEARCH_ROLE_BUNDLES_RESULTS: {
      let stateResultsRoleBundle = state.get('searchResultsRoleBundles');

      stateResultsRoleBundle = stateResultsRoleBundle
        .set('searchResults', fromJS(action.bundles.results))
        .set('searchResultsCount', fromJS(action.bundles.count))
        .set('searchResultsNext', fromJS(action.bundles.next))
        .set('searchResultsPrevious', fromJS(action.bundles.previous))
        .set('isLoadingRoleBundles', false);

      return state.set('searchResultsRoleBundles', stateResultsRoleBundle);
    }

    case actionTypes.BUNDLE_SET_SEARCH_QUERY: {
      return state.set('query', fromJS(action.query));
    }

    case actionTypes.BUNDLE_ADMIN_DETAIL_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.BUNDLE_ADMIN_DETAIL_SUCCESS: {
      return state
        .set('currentBundle', fromJS(action.bundle))
        .set('isLoading', false);
    }

    case actionTypes.BUNDLE_ADMIN_DETAIL_ClEAR: {
      return state.set('currentBundle', fromJS({}));
    }

    case actionTypes.BUNDLE_ADMIN_DETAIL_ERROR: {
      return state.set('isLoading', false);
    }

    case actionTypes.BUNDLE_ADMIN_SET_NEW_PRODUCT: {
      return state.set('addNewProduct', action.status);
    }

    case actionTypes.BUNDLE_ADMIN_SET_NEW_BUNDLE: {
      return state.set('addNewBundle', action.status);
    }

    case actionTypes.BUNDLE_ADMIN_CREATE_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.BUNDLE_ADMIN_CREATE_SUCCESS: {
      return state.set('isLoading', false);
    }

    case actionTypes.BUNDLE_ADMIN_CREATE_ERROR: {
      return state.set('isLoading', false);
    }

    case actionTypes.SAVE_TEMPORARY_BUNDLE_IMAGE: {
      const images = state.get('temporaryImages').toJS();
      images.push(action.img);
      return state.set('temporaryImages', fromJS(images));
    }

    case actionTypes.SAVE_BUNDLE_ITEMS: {
      return state.set('items', fromJS(action.bItems));
    }

    case actionTypes.DELETE_TEMPORARY_BUNDLE_IMAGE: {
      const images = state
        .get('temporaryImages')
        .filterNot((i) => i.get('id') === action.imgID);
      return state.set('temporaryImages', images);
    }

    case actionTypes.DELETE_TEMPORARY_BUNDLE_IMAGES: {
      return state.set('temporaryImages', fromJS([]));
    }

    case actionTypes.SET_BUNDLE_JOB_ROLE_PERMISSIONS: {
      return state.set(
        'job_role_permissions',
        fromJS(action.job_role_permissions)
      );
    }

    case actionTypes.ADD_BUNDLE_TEMPORARY_PERMISSIONS: {
      return state.set('temporaryPermissions', fromJS(action.permissions));
    }

    case actionTypes.CLEAR_BUNDLE_TEMPORARY_PERMISSIONS: {
      return state.set('temporaryPermissions', fromJS([]));
    }

    case actionTypes.LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_START: {
      return state.set('isLoadingPermissions', true);
    }

    case actionTypes.LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_SUCCESS: {
      return state.set('isLoadingPermissions', false);
    }

    case actionTypes.LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_ERROR: {
      return state.set('isLoadingPermissions', false);
    }

    case actionTypes.SET_BUNDLE_IMAGES_ISLOADING: {
      return state.setIn(['isLoadingImages', action.productID], true);
    }

    case actionTypes.SET_BUNDLE_IMAGES_DONE: {
      return state.setIn(['isLoadingImages', action.productID], false);
    }

    case actionTypes.ADD_PRODUCT_IMAGE_ENTITY:
      return state.setIn(
        ['bundleItemsImages', action.bundleItemID],
        action.productImage.id
      );

    case actionTypes.ADD_TEMPORARY_BUNDLE_ITEM: {
      return state.set(
        'tmpBundleItems',
        state.get('tmpBundleItems').push(
          fromJS({
            bundle: action.bundle,
            id: _.random(0, 99999),
            product: action.product,
            product_family: action.product_family,
            type: action.bundleItemType,
          })
        )
      );
    }

    case actionTypes.REMOVE_TEMPORARY_BUNDLE_ITEM: {
      return state.set(
        'tmpBundleItems',
        state
          .get('tmpBundleItems')
          .filterNot((item) => item.get('id') === action.id)
      );
    }

    case actionTypes.REPLACE_TEMPORARY_BUNDLE_ITEM: {
      return state.set(
        'tmpBundleItems',
        state.get('tmpBundleItems').update(
          state
            .get('tmpBundleItems')
            .findIndex((i) => i.get('id') === action.id),
          () =>
            fromJS({
              bundle: action.bundle,
              id: _.random(0, 99999),
              product: action.product,
              product_family: action.product_family,
              type: action.bundleItemType,
            })
        )
      );
    }

    case actionTypes.CLEAR_TEMPORARY_BUNDLE_ITEM: {
      return state.set('tmpBundleItems', fromJS([]));
    }

    case actionTypes.REPLACE_ALL_TEMPORARY_BUNDLE_ITEM: {
      return state.set('tmpBundleItems', fromJS(action.items));
    }

    default: {
      return state;
    }
  }
};

export default bundlesReducer;
