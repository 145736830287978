/**
 * List of all
 * Web Menu With possible display: hidden
 */

const tenantMenu = {
  addEmployee: 'addEmployee',
  adminActivities: 'adminActivities',
  adminFaqs: 'adminFaqs',
  // Web menu for Features - This Menus will be hide by default if no configuration has been set in the tenant
  approvalRules: 'approvalRules',
  approvals: 'approvals',
  approvalsMainMenu: 'approvalsMainMenu',
  caseMgmt: 'caseMgmt',
  casesMainMenu: 'casesMainMenu',
  csvMapping: 'csvMapping',
  emailIntegrationCase: 'emailIntegrationCase',
  full_edit_profile: 'full_edit_profile',
  incidentCategorySettings: 'incidentCategorySettings',
  linkCustomization: 'linkCustomization',
  locations: 'locations',
  my_equipment: 'my_equipment',
  my_requests: 'my_requests',
  offboarding: 'offboarding',
  onboardingActivities: 'onboardingActivities',
  // Onboard activities Menu in ADMIN
  onboardingActivitiesManager: 'onboardingActivitiesManager',
  publicEmployeeNumber: 'publicEmployeeNumber',
  reports: 'reports',
  // Onboard activities Menu in APP
  serviceDepartment: 'serviceDepartment',
  serviceTeams: 'serviceTeams',
  store: 'store',
};

/**
 * NOTE
 * To avoid to display FEATURE in work in progress
 * Add by hand a new entry in the ui_config obj
 * to the tenant API (/api/tenant/v0.1/tenant/tenantID)
 * Ex to hide the onboardActivity Menu add:
 {
    "ui_config":{
      "newFeature":{
        "linkCustomization":true,
        "onboardingActivities": true
      }
    }
  }
 *
 * For Menu Hidding or Help Settings, for now we still use the ui_config property of the tenant
 * but in the future, we plan tohave their own endpoint / reducer entity
 * EX:
 {
  "ui_config":{
      "locale":"en",
      "displayMenu":{
        "store":true,
        "my_equipment":false
      },
      "helpSettings": {
        "chat": true
      }
    }
  }
 */

export default tenantMenu;
