import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { reduxForm } from 'redux-form/immutable';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

const ValidatedForm = ({
  handleSubmit,
  hidden = false,
  className = '',
  children,
}) => (
  <Form className={className} error hidden={hidden} onSubmit={handleSubmit}>
    {children}
  </Form>
);

ValidatedForm.propTypes = propTypes;
export default reduxForm({})(ValidatedForm);
