import { fromJS } from 'immutable';
import { Announcements } from 'esp-svg';

const AnnouncementCategories = fromJS({
  'Building or Facility': {
    element: Announcements.Facilities,
    id: 15,
    name: 'Building or Facility',
  },
  'Company News or Announcement': {
    element: Announcements.News,
    id: 7,
    name: 'Company News or Announcement',
  },
  Expenses: {
    element: Announcements.Expenses,
    id: 20,
    name: 'Expenses',
  },
  'Food or Cafeteria': {
    element: Announcements.Food,
    id: 14,
    name: 'Food or Cafeteria',
  },
  'Generic Announcement': {
    element: Announcements.Alert,
    id: 13,
    name: 'Generic Announcement',
  },
  'Gym Announcement': {
    element: Announcements.Gym,
    id: 12,
    name: 'Gym Announcement',
  },
  'Hazardous Issue': {
    element: Announcements.Hazard,
    id: 11,
    name: 'Hazardous Issue',
  },
  'Health Related': {
    element: Announcements.Healthwellness,
    id: 10,
    name: 'Health Related',
  },
  'Leader Board Announcements': {
    element: Announcements.Lederboard,
    id: 19,
    name: 'Leader Board Announcements',
  },
  'Parking Lot Issue': {
    element: Announcements.Parking,
    id: 6,
    name: 'Parking Lot Issue',
  },
  'People or HR Related': {
    element: Announcements.Hr,
    id: 9,
    name: 'People or HR Related',
  },
  'Printer Issue or Outage': {
    element: Announcements.PrinterOutage,
    id: 5,
    name: 'Printer Issue or Outage',
  },
  Recognition: {
    element: Announcements.Recognition,
    id: 16,
    name: 'Recognition',
  },
  'Referral Programs': {
    element: Announcements.ReferralPrograms,
    id: 18,
    name: 'Referral Programs',
  },
  'Repair or Maintenance': {
    element: Announcements.FacilitiyOutage,
    id: 3,
    name: 'Repair or Maintenance',
  },
  'Sales Wins': {
    element: Announcements.SalesWins,
    id: 17,
    name: 'Sales Wins',
  },
  'Security Announcement': {
    element: Announcements.Security,
    id: 4,
    name: 'Security Announcement',
  },
  'Service Issue or Outage': {
    element: Announcements.ServiceOutage,
    id: 2,
    name: 'Service Issue or Outage',
  },
  'System Issue or Outage': {
    element: Announcements.NoCloud,
    id: 8,
    name: 'System Issue or Outage',
  },
  'Wifi Issue or Outage': {
    element: Announcements.Wifi,
    id: 1,
    name: 'Wifi Issue or Outage',
  },
});

export default AnnouncementCategories;
