import actionTypes from './actionTypes';

const casesActions = {};

casesActions.resetTasks = () => ({
  type: actionTypes.RESET_TASKS,
});

casesActions.setRejectionReasonConfig = (value) => ({
  type: actionTypes.SET_REJECTION_REASON_CONFIG,
  value,
});

casesActions.loadApprovalFeedStart = () => ({
  type: actionTypes.APPROVAL_FEED_START,
});

casesActions.loadApprovalFeedSuccess = (tasks, pagination, reset = false) => ({
  pagination,
  reset,
  tasks,
  type: actionTypes.APPROVAL_FEED_SUCCESS,
});

casesActions.loadApprovalFeedFailure = (errorMsg) => ({
  errorMsg,
  type: actionTypes.APPROVAL_FEED_FAIL,
});

casesActions.loadCaseFeedStart = () => ({
  type: actionTypes.CASE_FEED_START,
});

casesActions.loadCaseFeedSuccess = (
  count,
  tasks,
  pagination,
  reset = false
) => ({
  count,
  pagination,
  reset,
  tasks,
  type: actionTypes.CASE_FEED_SUCCESS,
});

casesActions.loadCaseFeedFailure = (errorMsg) => ({
  errorMsg,
  type: actionTypes.CASE_FEED_FAIL,
});

casesActions.assignToMeStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_ASSING_TO_ME_START,
});

casesActions.assignToStart = (taskId, userId) => ({
  taskId,
  type: actionTypes.CASE_ASSING_TO_START,
  userId,
});

casesActions.taskUpdateStart = (task) => ({
  task,
  type: actionTypes.CASE_TASK_UPDATE_START,
});

casesActions.taskUpdate = (task) => ({
  task,
  type: actionTypes.CASE_TASK_UPDATE,
});

casesActions.taskUpdateFail = (taskId, errorMsg) => ({
  errorMsg,
  taskId,
  type: actionTypes.CASE_TASK_UPDATE_FAIL,
});

casesActions.discardTaskStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_DISCARD_START,
});

casesActions.reOpenTaskStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_REOPEN_START,
});

casesActions.resolveTaskStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_RESOLVE_START,
});

casesActions.toggleCardIsOpen = (taskId) => ({
  taskId,
  type: actionTypes.CASE_TOGGLE_CARD_OPEN,
});

casesActions.actionStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_ACTION_START,
});

casesActions.actionEnd = (taskId, error = null) => ({
  error,
  taskId,
  type: actionTypes.CASE_ACTION_END,
});

casesActions.approveAllStart = () => ({
  type: actionTypes.APPROVE_ALL_ACTION_START,
});
casesActions.approveAllEnd = (error = null) => ({
  error,
  type: actionTypes.APPROVE_ALL_ACTION_END,
});

casesActions.loadCaseTaskStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_TASK_START,
});

casesActions.setApprovalTaskQuestionsStart = () => ({
  type: actionTypes.SET_APPROVAL_TASK_QUESTIONS_START,
});
casesActions.setApprovalTaskQuestionsSuccess = () => ({
  type: actionTypes.SET_APPROVAL_TASK_QUESTIONS_SUCCESS,
});
casesActions.setApprovalTaskQuestionsFail = () => ({
  type: actionTypes.SET_APPROVAL_TASK_QUESTIONS_FAIL,
});

casesActions.loadCaseSubscriberStart = () => ({
  type: actionTypes.CASE_SUBSCRIBERS_START,
});

casesActions.loadCaseSubscriberEnd = () => ({
  type: actionTypes.CASE_SUBSCRIBERS_END,
});

casesActions.setDefaultSubscribers = (defaultSubscribers) => ({
  defaultSubscribers,
  type: actionTypes.CASE_SET_DEFAULT_SUBSCRIBERS,
});

casesActions.loadCaseTaskSuccess = (taskId, task, teamMembers, card) => ({
  card,
  task,
  taskId,
  teamMembers,
  type: actionTypes.CASE_TASK_SUCCESS,
});

casesActions.loadCaseTaskFailure = (taskId, errorMsg) => ({
  errorMsg,
  taskId,
  type: actionTypes.CASE_TASK_FAIL,
});

casesActions.loadTaskCategoriesStart = () => ({
  type: actionTypes.LOAD_CASE_CATEGORIES_START,
});

casesActions.loadTaskCategoriesSuccess = (categories) => ({
  categories,
  type: actionTypes.LOAD_CASE_CATEGORIES_SUCCESS,
});

casesActions.loadTaskCategoriesFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_CASE_CATEGORIES_FAIL,
});

casesActions.setParentTask = (parentTask) => ({
  parentTask,
  type: actionTypes.SET_PARENT_TASK,
});

casesActions.setChildrenTasks = (childrenTasks) => ({
  childrenTasks,
  type: actionTypes.SET_CHILDREN_TASKS,
});

casesActions.loadTaskLocationFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_CASE_LOCATION_FAIL,
});

casesActions.loadTaskLocationStart = () => ({
  type: actionTypes.LOAD_CASE_LOCATION_START,
});

casesActions.loadTaskLocationSuccess = (location) => ({
  location,
  type: actionTypes.LOAD_CASE_LOCATION_SUCCESS,
});

casesActions.loadTaskLocationFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_CASE_LOCATION_FAIL,
});

casesActions.loadUserLocationStart = () => ({
  type: actionTypes.LOAD_USER_LOCATION_START,
});

casesActions.loadUserLocationSuccess = (userLocations) => ({
  type: actionTypes.LOAD_USER_LOCATION_SUCCESS,
  userLocations,
});

casesActions.loadUserLocationFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_USER_LOCATION_FAIL,
});

casesActions.loadUserLocationDetailStart = () => ({
  type: actionTypes.LOAD_USER_LOCATION_DETAIL_START,
});

casesActions.loadUserLocationDetailSuccess = (locationDetail) => ({
  locationDetail,
  type: actionTypes.LOAD_USER_LOCATION_DETAIL_SUCCESS,
});

casesActions.loadUserLocationDetailFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_USER_LOCATION_DETAIL_FAIL,
});

casesActions.updateCaseFromBaristaStart = () => ({
  type: actionTypes.UPDATE_CASE_FROM_BARISTA_START,
});

casesActions.updateCaseFromBaristaFail = () => ({
  type: actionTypes.UPDATE_CASE_FROM_BARISTA_FAIL,
});

casesActions.updateCaseFromBaristaSuccess = (/* task*/) => ({
  type: actionTypes.UPDATE_CASE_FROM_BARISTA_SUCCESS,
  // task,
});

casesActions.createCaseStart = () => ({
  type: actionTypes.CREATE_CASE_START,
});

casesActions.createCaseFail = () => ({
  type: actionTypes.CREATE_CASE_FAIL,
});

casesActions.createCaseSuccess = (task) => ({
  task,
  type: actionTypes.CREATE_CASE_SUCCESS,
});

casesActions.postCaseNoteStart = (taskId) => ({
  taskId,
  type: actionTypes.POST_CASE_NOTE_START,
});

casesActions.postCaseNoteSuccess = (taskId, note = '') => ({
  note,
  taskId,
  type: actionTypes.POST_CASE_NOTE_SUCCESS,
});

casesActions.postCaseNoteFailure = (taskId, errorMsg) => ({
  errorMsg,
  taskId,
  type: actionTypes.POST_CASE_NOTE_FAIL,
});

casesActions.deleteCase = (taskId) => ({
  taskId,
  type: actionTypes.DELETE_CASE,
});

casesActions.updateCasesFilter = (newFilters) => ({
  newFilters,
  type: actionTypes.CASES_FILTER_UPDATE,
});
casesActions.resetCasesFilter = () => ({
  type: actionTypes.CASES_FILTER_RESET,
});

casesActions.updateApprovalsFilter = (approvalsFilters) => ({
  approvalsFilters,
  type: actionTypes.APPROVALS_FILTER_UPDATE,
});

casesActions.loadCaseSummaryStart = () => ({
  type: actionTypes.CASE_LOAD_SUMMARY_START,
});

casesActions.loadCaseSummarySuccess = ({
  teams,
  count,
  next = null,
  resetTeamList = false,
  concate = false,
}) => ({
  concate,
  count,
  next,
  resetTeamList,
  teams,
  type: actionTypes.CASE_LOAD_SUMMARY_SUCCESS,
});

casesActions.loadCaseSummaryFail = () => ({
  type: actionTypes.CASE_LOAD_SUMMARY_FAIL,
});

casesActions.resetTeamList = () => ({
  type: actionTypes.CASE_RESET_TEAMS_LIST,
});

casesActions.isSearchingTeam = () => ({
  type: actionTypes.CASE_IS_SEARCHING_TEAM,
});

casesActions.setSearchTeamsTerm = (searchTerm) => ({
  searchTerm,
  type: actionTypes.CASE_SET_USER_SEARCH_TERM,
});

casesActions.loadTeamsListStart = () => ({
  type: actionTypes.CASE_LOAD_TEAMS_LIST_START,
});

casesActions.loadTeamsListFail = () => ({
  type: actionTypes.CASE_LOAD_TEAMS_LIST_FAIL,
});

casesActions.loadTeamsListSuccess = (teams) => ({
  teams,
  type: actionTypes.CASE_LOAD_TEAMS_LIST_SUCCESS,
});

casesActions.loadServiceDepartmentStart = () => ({
  type: actionTypes.LOAD_CASE_SERVICE_DEPARTMENT_START,
});

casesActions.loadServiceDepartmentFail = () => ({
  type: actionTypes.LOAD_CASE_SERVICE_DEPARTMENT_FAIL,
});

casesActions.loadServiceDepartmentSuccess = (department) => ({
  department,
  type: actionTypes.LOAD_CASE_SERVICE_DEPARTMENT_SUCCESS,
});
casesActions.loadCurrentCaseServiceDepartmentSuccess = (department) => ({
  department,
  type: actionTypes.SET_CURRENT_CASE_SERVICE_DEPARTMENT_SUCCESS,
});
casesActions.creatingNewCaseStart = () => ({
  type: actionTypes.CASE_CREATING_NEW_START,
});

casesActions.creatingNewCaseEnd = () => ({
  type: actionTypes.CASE_CREATING_NEW_END,
});

casesActions.setKBwf = (KBid, KBTitle) => ({
  KBTitle,
  KBid,
  type: actionTypes.CASE_SET_KB_WF,
});

casesActions.openAddSubscribers = (taskId) => ({
  taskId,
  type: actionTypes.CASE_OPEN_ADD_SUBSCRIBERS,
});

casesActions.closeAddSubscribers = () => ({
  type: actionTypes.CASE_CLOSE_ADD_SUBSCRIBERS,
});

casesActions.setTmpSubscriberLocationSelection = (
  locationId,
  locationName
) => ({
  locationId,
  locationName,
  type: actionTypes.SET_TMP_SUBSCRIBER_LOCATION_SELECTION,
});

casesActions.loadSubscriptionScopeStart = (taskId) => ({
  taskId,
  type: actionTypes.CASE_SUBS_SCOPE_START,
});

casesActions.loadSubscriptionScopeSuccess = (taskId, scope) => ({
  scope,
  taskId,
  type: actionTypes.CASE_SUBS_SCOPE_SUCCESS,
});

casesActions.loadSubscriptionScopeFail = (taskId) => ({
  taskId,
  type: actionTypes.CASE_SUBS_SCOPE_FAIL,
});

export default casesActions;
