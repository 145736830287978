import APIcall from '../../../../../utils/APIcall';

const getScheduleSync = (scheduleSyncURL) => {
  return APIcall.get({
    token: true,
    url: scheduleSyncURL,
  });
};

export default getScheduleSync;
