import React, { useCallback, useEffect, useState } from 'react';
import { Dimmer, Form, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import authorizeGmailCall, {
  changeGmailAccount,
  testConnection,
  unauthorize,
} from './apiCall';
import { isEmpty } from 'lodash';
import DateTools from '../../../../../../utils/DateTools';
import EspConfirmationModal from '../../../../../../../v2/components/display/EspConfirmationModal';
import modalConfig, { AUTHORIZE_LEGEND, TYPE_CONFIRM } from './modalConfig';

const propTypes = {
  currentCredential: PropTypes.shape({
    email_address: PropTypes.string,
    extra_creds: PropTypes.shape({
      updated_at: PropTypes.string,
    }),
    id: PropTypes.number,
    sys_custom_fields: PropTypes.PropTypes.shape({
      oauth_authorized_by: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    email_address: PropTypes.string,
    name: PropTypes.string,
  }),
  getPrimaryEmailCredentials: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  resetGmailForm: PropTypes.func.isRequired,
  setIsGmailConnected: PropTypes.func,
  touched: PropTypes.shape({
    email_address: PropTypes.bool,
    name: PropTypes.bool,
  }),
  values: PropTypes.shape({
    email_address: PropTypes.string,
    name: PropTypes.string,
    primary: PropTypes.bool,
  }),
};

const GmailPrimaryEmailAccount = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  currentCredential,
  getPrimaryEmailCredentials,
  resetGmailForm,
  isValid,
  setIsGmailConnected,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeConfirmation, setTypeConfirmation] = useState(null);

  useEffect(() => {
    const isAuthorized = !isEmpty(currentCredential?.extra_creds);
    if (isAuthorized) {
      setIsloading(true);
      const { email_address, id } = currentCredential;
      const params = {
        email_address,
        id,
      };
      testConnection(id, params)
        .then(() => {
          setIsGmailConnected(true);
          setIsConnected(true);
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  }, [currentCredential, setIsGmailConnected]);

  const handleAuthorize = useCallback(() => {
    authorizeGmailCall(currentCredential.id).then(({ body }) => {
      window.open(body.redirect_uri, '_self');
    });
  }, [currentCredential]);

  const handleUnauthorize = useCallback(() => {
    setIsloading(true);
    unauthorize(currentCredential.id)
      .then(() => setIsConnected(false))
      .finally(() => {
        setIsModalOpen(false);
        setIsloading(false);
      });
  }, [currentCredential]);

  const handleChangeEmailAccount = useCallback(() => {
    setIsloading(true);
    changeGmailAccount(currentCredential.id)
      .then(() => {
        setIsConnected(false);
        getPrimaryEmailCredentials();
        resetGmailForm();
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsloading(false);
      });
  }, [currentCredential, getPrimaryEmailCredentials, resetGmailForm]);

  const handleToggleChangeAccount = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setTypeConfirmation(TYPE_CONFIRM.CHANGE_ACCOUNT);
  }, [isModalOpen]);

  const handleToggleAnauthorizeAccount = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setTypeConfirmation(TYPE_CONFIRM.UNAUTHORIZE_ACCOUNT);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const isAuthorized = !isEmpty(currentCredential?.extra_creds);
  const updatedAt =
    currentCredential &&
    currentCredential?.extra_creds &&
    DateTools.getFormatDate(currentCredential.extra_creds.updated_at);

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader inverted size='large' />
      </Dimmer>
      <Form.Input
        error={
          touched.email_address && errors.email_address
            ? errors.email_address
            : null
        }
        label='Email Address'
        name='email_address'
        onBlur={handleBlur}
        onChange={handleChange}
        required
        type='email'
        value={values.email_address || ''}
      />
      <Form.Input
        error={touched.name && errors.name ? errors.name : null}
        label='Name'
        name='name'
        onBlur={handleBlur}
        onChange={handleChange}
        required
        type='text'
        value={values.name || ''}
      />
      {/* there is here a eslint rule that is not working: eslint(jsx-a11y/label-has-associated-control
          Check with Brian when he is available */}
      {isAuthorized && isConnected && (
        <>
          <Form.Field>
            <label htmlFor='connected'>{'Status'}</label>
            <div style={{ color: 'green' }}>Connected</div>
          </Form.Field>
          <Form.Field>
            <label htmlFor='connectedDate'>Connected Date</label>
            <div>{updatedAt}</div>
          </Form.Field>
          <Form.Field>
            <label htmlFor='userAuthorized'>User Authorized</label>
            <div>
              {currentCredential?.sys_custom_fields?.oauth_authorized_by}
            </div>
          </Form.Field>
          <Form.Group>
            <Form.Button
              basic
              content='Change Primary Email'
              onClick={handleToggleChangeAccount}
              primary
              style={{ margin: '1em 0' }}
            />
            <Form.Button
              basic
              content='Unauthorize Gmail'
              onClick={handleToggleAnauthorizeAccount}
              primary
              style={{ margin: '1em 0' }}
            />
          </Form.Group>
        </>
      )}

      {(!isAuthorized || !isConnected) && (
        <div style={{ margin: '1em 0' }}>
          <Form.Button
            content='Authorize Gmail'
            disabled={!currentCredential?.id || isValid}
            onClick={handleAuthorize}
            primary
            style={{ margin: '0' }}
          />
          <em style={{ fontSize: '0.9em' }}>{AUTHORIZE_LEGEND}</em>
        </div>
      )}
      {isModalOpen && (
        <EspConfirmationModal
          cancelButton={modalConfig[typeConfirmation].BUTTON_CANCEL}
          confirmButton={modalConfig[typeConfirmation].BUTTON_OK}
          content={modalConfig[typeConfirmation].CONTENT}
          header={modalConfig[typeConfirmation].TITLE}
          onCancel={handleCloseModal}
          onConfirm={
            typeConfirmation === TYPE_CONFIRM.CHANGE_ACCOUNT
              ? handleChangeEmailAccount
              : handleUnauthorize
          }
          open={isModalOpen}
        />
      )}
    </>
  );
};

GmailPrimaryEmailAccount.propTypes = propTypes;
export default GmailPrimaryEmailAccount;
