import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { intl } from 'esp-util-intl';
import { curry, memoize, noop } from 'lodash';
// Controller
import ConversationFeedController from '../controllers/ConversationFeedController';
// Molecules
import ConversationSystemMessage from './ConversationSystemMessage';
// Globals
import ConversationMessagesTypes from '../../globals/ConversationMessagesTypes';
import IntegrationsImages from '../../globals/ArtificialAuthorImages';
import { DROPDOWN_MAX_NUM_OF_ITEMS } from '../organisms/ChatRoom';
// Utils
import WidgetUtils from '../../utils/WidgetUtils';
// v2 Components
import ChatMessage from '../../../v2/components/display/ChatMessage';
import ChatInputTypes from '../../../v2/components/display/ChatMessage/ChatInputTypes';
import PseudoBaristaWidget from '../../../v2/components/functional/ChatInput/PseudoBaristaWidget';
import LaunchWorkflowButton from '../../../v2/components/functional/ChatInput/LaunchWorkflowButton';

import IFrameModal from '../../../v2/components/display/IFrameModal';
import ChannelTypes from '../../../v1/globals/ChannelTypes';

import { getDateTimeFormat } from '../../../v2/utils/pickerHelpers';
class ConversationFeed extends PureComponent {
  static propTypes = {
    baristaID: PropTypes.number,
    channelId: PropTypes.string,
    /** Type of channel. Passed down by component */
    channelType: PropTypes.oneOf([
      ChannelTypes.DIRECT_CHANNEL,
      ChannelTypes.SCOPED_CHANNEL,
      ChannelTypes.SUPPORT_CHANNEL,
    ]),
    currentUser: ImmutablePropTypes.map,
    hideBarista: PropTypes.bool,
    hideUserID: PropTypes.arrayOf(PropTypes.number),
    inlineButtonActive: PropTypes.bool,
    isCaseMgmt: PropTypes.bool, // passed directly. If passed then it's assumed this chat belongs to a case
    isExpandable: PropTypes.bool, // enables expandable/collapsable bubbles for long messages
    isScoped: PropTypes.bool, // Is scoped chat
    markImagesAsLoaded: PropTypes.func,
    messages: ImmutablePropTypes.list.isRequired,
    messagesChecked: PropTypes.arrayOf(PropTypes.number), // Index of the msg checked if onCheckboxClick is passed
    onCheckboxClick: PropTypes.func,

    refreshDownloadLineURL: PropTypes.func,

    // Only for ChatMessage with onCheckboxClick active
    refreshDownloadMessage: PropTypes.func,
    userDisabled: PropTypes.bool, // Will inject a system message saying user was disabled
    users: ImmutablePropTypes.list, // For injecting an artificial list of users (in a Block)
  };

  static defaultProps = {
    baristaID: null,
    channelId: '',
    currentUser: Immutable.Map(),
    hideBarista: false,
    hideUserID: null,
    inlineButtonActive: false,
    isCaseMgmt: false,
    isExpandable: true,
    isScoped: false,
    markImagesAsLoaded: noop,
    messagesChecked: [],
    onCheckboxClick: void 0,
    refreshDownloadLineURL: noop,
    refreshDownloadMessage: noop,
    userDisabled: false,
    users: void 0,
  };

  /**
   * Checks time difference between the 2 messages
   * @param  {message}  messageA
   * @param  {message}  messageB
   * @return {Boolean}  true if absolute difference is greater than 15 minutes
   */
  isMessageOldEnough = (messageA, messageB) => {
    const t0 = new Date(messageA.get('sys_date_created'));
    const t1 = new Date(messageB.get('sys_date_created'));
    const dif = t1.getTime() - t0.getTime();

    const secondsFromT1toT0 = Math.abs(dif / 1000); // in seconds

    let oldEnough = false;
    if (secondsFromT1toT0 > 60 * 15) {
      // 15 minutes
      oldEnough = true;
    }
    return oldEnough;
  };

  handleOnDownload = memoize(
    curry(({ commentId, faqData }, e) => {
      // DEV-20836 this was being done in the ConversationFeedController
      // for both Cordova and Electron.
      // But due to the bug report I confirmed this happens on web also
      // where links open twice (links to download attachments are a <a> tag,
      // but we open them with window.open in the function openExternalUrlInNative)
      e.preventDefault();
      const { refreshDownloadLineURL, refreshDownloadMessage } = this.props;
      // check if this link is in fact for a download
      if (e.target && e.target.hasAttribute('download')) {
        // force to reload the message
        // so we get a fresh signed url
        if (faqData && faqData.faqEid && faqData.answerEid && faqData.lineEid) {
          refreshDownloadLineURL(faqData);
        } else if (commentId) {
          refreshDownloadMessage(commentId);
        }
      }
    })
  );

  handleImageLoaded = (img) => {
    const { markImagesAsLoaded } = this.props;
    markImagesAsLoaded(img);
  };

  handleCheckboxClick = memoize((id) => {
    // We don't want to return a function if onCheckboxClick is not passed
    // to avoid to display the checkbox
    const { onCheckboxClick } = this.props;

    if (onCheckboxClick) {
      return () => {
        onCheckboxClick(id);
      };
    }
    return void 0;
  });

  renderMessages = () => {
    const {
      baristaID,
      isCaseMgmt,
      channelId,
      channelType,
      currentUser,
      hideBarista,
      hideUserID,
      inlineButtonActive,
      isExpandable,
      isScoped,
      messages,
      messagesChecked,
      userDisabled,
      users,
    } = this.props;
    const currentUserId = currentUser && currentUser.get('id');
    //
    // NOTE: Since the update of the Conversation01 Type when we removed the ID for each messages
    // some old workflow have issue to add new messages
    // This keyById allows to fix that dynamically
    //
    let keyById = true;
    messages.forEach((msg) => {
      if (keyById && !msg.get('id')) {
        keyById = false;
      }
    });

    const filteredMessages = messages.filter((comment) => {
      if (hideBarista && comment.get('user_id') === baristaID) {
        return false;
      } else if (
        hideUserID &&
        hideUserID.length &&
        hideUserID.indexOf(comment.get('user_id')) > -1
      ) {
        return false;
      }

      if (comment.get('type') === ConversationMessagesTypes.BOT_ACTION) {
        return false;
      }

      return true;
    });
    let res = filteredMessages.map((comment, i) => {
      const commentUserId = Number(comment.get('user_id'));
      const commentId = keyById ? comment.get('id') : i;
      const faqData =
        comment && comment.get('faqEid')
          ? {
              answerEid: comment.get('answerEid'),
              faqEid: comment.get('faqEid'),
              lineEid: comment.get('id'),
            }
          : null;
      // allows ud to know if the last comment was made by the same user
      // using .has first since .get may be a negative number, which indexes back from the end of the Collection.
      const prevMessage = i > 0 && filteredMessages.get(i - 1);
      const nextMessage = filteredMessages.get(i + 1);

      const isMe = Number(currentUserId) === commentUserId;

      // MODIFY DATA IF A FAKE USERS ARRAY IS PASSED
      let isBarista = baristaID === commentUserId;
      let avatarUrl;
      let artificialAuthor;

      if (users) {
        artificialAuthor = users.find(
          (u) => Number(u.get('id')) === commentUserId
        );
        if (artificialAuthor && artificialAuthor.get('isBarista')) {
          isBarista = true;
        } else if (artificialAuthor && artificialAuthor.get('imageUrl')) {
          if (window.cordova || window.electron) {
            avatarUrl = IntegrationsImages[artificialAuthor.get('imageUrl')]
              ? IntegrationsImages[artificialAuthor.get('imageUrl')]
              : artificialAuthor.get('imageUrl');
          } else {
            avatarUrl = artificialAuthor.get('imageUrl');
          }
        }
      }

      // DETERMINE MESSAGE PLACEMENT
      let isFirstMessageFromThisUser = true;
      let isLastMessageFromThisUser = false;
      let shouldHideAvatar = false;

      // Compare with the previous type message
      if (
        prevMessage &&
        Number(prevMessage.get('user_id')) === commentUserId &&
        prevMessage.get('type') !== ConversationMessagesTypes.SYSTEM
      ) {
        shouldHideAvatar = true;
        isFirstMessageFromThisUser = this.isMessageOldEnough(
          prevMessage,
          comment
        );
      }

      // Compare with the next type message
      if (
        nextMessage &&
        nextMessage.get('type') === ConversationMessagesTypes.SYSTEM
      ) {
        // If the next message is a system message, it is always the last message
        isLastMessageFromThisUser = true;
      } else if (
        nextMessage &&
        Number(nextMessage.get('user_id')) === commentUserId
      ) {
        isLastMessageFromThisUser = this.isMessageOldEnough(
          nextMessage,
          comment
        );
      }

      // If there are no more messages at all
      if (
        !nextMessage ||
        (Number(nextMessage.get('user_id')) !== commentUserId &&
          nextMessage.get('type') !== ConversationMessagesTypes.SYSTEM)
      ) {
        isLastMessageFromThisUser = true;
      }

      let showTimeStamp = true;

      if (
        nextMessage &&
        nextMessage.get('type') !== ConversationMessagesTypes.SYSTEM
      ) {
        // if there is a next message, show the time if it is old enough, except for system messages
        showTimeStamp = this.isMessageOldEnough(nextMessage, comment);
      }

      // There are other use cases where we are needing inputProp
      let inputProp;
      if (comment.hasIn(['metadata', 'user_input', 'select'])) {
        // we have to parse them like that because the BE implementation doesn't follow the shape of input
        inputProp = {
          options: comment.getIn(['metadata', 'user_input', 'select']).toJS(),
          type: ChatInputTypes.BUTTON,
          value: '', // still not used
        };
      }
      // DEV-12566
      if (
        comment.hasIn(['metadata', 'default_options']) &&
        comment.getIn(['metadata', 'default_options']).size <=
          DROPDOWN_MAX_NUM_OF_ITEMS
      ) {
        inputProp = {
          options: comment.getIn(['metadata', 'default_options']).toJS(),
          type: ChatInputTypes.BUTTON,
          value: '', // still not used
        };
      }

      const dateTimeFormat = getDateTimeFormat(comment, messages.last());

      const shouldReturnMultipleChatMessages =
        inlineButtonActive && inputProp && !nextMessage;

      const workflowRequestWidget =
        comment && WidgetUtils.getWorkflowRequestWidget(comment);

      // console.warn('M=> ' + ' type: ' + comment.get('type') + ', UID: ' + commentUserId + ', isFirstMessage: ' + isFirstMessage + ', hideAvatar: ' + hideAvatar + ', isLastMessage: ' + isLastMessage);

      if (comment.get('type') === ConversationMessagesTypes.MESSAGE) {
        // support for the pseudo widget to launch workflow in DEV-5615
        // if rendered at botton, will be rendered by ChatRoom
        if (
          workflowRequestWidget &&
          !workflowRequestWidget.get('display_bottom')
        ) {
          return (
            <LaunchWorkflowButton
              content={workflowRequestWidget.get('label')}
              inlineConversation
              isLastMessage
              key={`${commentId}_input`}
              timeStamp={comment.get('sys_date_created')}
              workflowRequestId={workflowRequestWidget.get(
                'workflow_request_id'
              )}
            />
          );
        } else if (comment.hasIn(['metadata', 'widget'])) {
          return (
            <PseudoBaristaWidget
              comment={comment}
              isCaseMgmt={isCaseMgmt}
              key={`${commentId}_input`}
            />
          );
        } else if (shouldReturnMultipleChatMessages) {
          return [
            // NOTE: Take a look at the storybook examples. Without dramatically upating how ChatMessage works,
            // we will need to return TWO ChatMessage components if there is a user_input being asked for. The
            // first ChatMessage will be the message from Barista. The second will be the input portion of the
            // ChatMessage component. That will also allow them to be stacked.
            <ChatMessage
              avatarUrl={avatarUrl}
              channelId={channelId}
              checked={messagesChecked.indexOf(i) > -1}
              hideAvatar={shouldHideAvatar}
              isBarista={isBarista}
              isCaseMgmt={isCaseMgmt}
              isExpandable={isExpandable}
              isFirstMessage={isFirstMessageFromThisUser}
              isLastMessage
              isMe={isMe}
              isScoped={isScoped}
              key={commentId}
              messageText={comment.get('text')}
              onCheckboxClick={this.handleCheckboxClick(i)}
              paramValues={comment.getIn(['metadata', 'param_values'])}
              showTimeStamp={showTimeStamp}
              userId={commentUserId}
            />,
            <ChatMessage
              avatarUrl={avatarUrl}
              channelId={channelId}
              hideAvatar
              input={inputProp}
              isBarista={isBarista}
              isCaseMgmt={isCaseMgmt}
              isExpandable={isExpandable}
              isFirstMessage
              isLastMessage={isLastMessageFromThisUser}
              isMe={isMe}
              isScoped={isScoped}
              key={`${commentId}_input`}
              paramValues={comment.getIn(['metadata', 'param_values'])}
              showTimeStamp={false}
              timeStamp={comment.get('sys_date_created')}
            />,
          ];
        } else {
          return (
            <ChatMessage
              avatarUrl={avatarUrl}
              channelId={channelId}
              channelType={channelType}
              checked={messagesChecked.indexOf(i) > -1}
              dateTimeFormat={dateTimeFormat}
              hideAvatar={shouldHideAvatar}
              isBarista={isBarista}
              isCaseMgmt={isCaseMgmt}
              isExpandable={isExpandable}
              isFirstMessage={isFirstMessageFromThisUser}
              isLastMessage={isLastMessageFromThisUser}
              isMe={isMe}
              isScoped={isScoped}
              key={commentId}
              messageText={comment.get('text')}
              onCheckboxClick={this.handleCheckboxClick(i)}
              paramValues={comment.getIn(['metadata', 'param_values'])}
              showTimeStamp={showTimeStamp}
              timeStamp={comment.get('sys_date_created')}
              userId={commentUserId}
            />
          );
        }
      } else if (comment.get('type') === ConversationMessagesTypes.SYSTEM) {
        return (
          <ConversationSystemMessage
            comment={comment}
            key={comment.get('id')}
            userId={commentUserId}
          />
        );
      } else if (comment.get('type') === ConversationMessagesTypes.ATTACHMENT) {
        if (shouldReturnMultipleChatMessages) {
          return (
            <Fragment key={`${commentId}_fragment`}>
              {/* comment.get('text') ?
                  <ChatMessage
                    avatarUrl={avatarUrl}
                    channelId={channelId}
                    hideAvatar={shouldHideAvatar}
                    isBarista={isBarista}
                    isExpandable={isExpandable}
                    isFirstMessage={isFirstMessageFromThisUser}
                    isLastMessage={false}
                    isMe={isMe}
                    messageText={comment.get('text')}
                    paramValues={comment.getIn(['metadata', 'param_values'])}
                    showTimeStamp={showTimeStamp}
                    userId={commentUserId}
                  />
                  :
                  null
                */}
              <ChatMessage
                attachment={comment.get('metadata')}
                avatarUrl={avatarUrl}
                checked={messagesChecked.indexOf(i) > -1}
                hideAvatar={shouldHideAvatar}
                isBarista={isBarista}
                isCaseMgmt={isCaseMgmt}
                isExpandable={isExpandable}
                isFirstMessage={isFirstMessageFromThisUser}
                isLastMessage={isLastMessageFromThisUser}
                isMe={isMe}
                isScoped={isScoped}
                onCheckboxClick={this.handleCheckboxClick(i)}
                onDownload={this.handleOnDownload({
                  commentId,
                  faqData,
                })}
                onImageLoaded={this.handleImageLoaded}
                paramValues={comment.getIn(['metadata', 'param_values'])}
                showTimeStamp={false}
                timeStamp={comment.get('sys_date_created')}
                userId={commentUserId}
              />
              <ChatMessage
                avatarUrl={avatarUrl}
                channelId={channelId}
                hideAvatar
                input={inputProp}
                isBarista={isBarista}
                isCaseMgmt={isCaseMgmt}
                isExpandable={isExpandable}
                isFirstMessage
                isLastMessage={isLastMessageFromThisUser}
                isMe={isMe}
                isScoped={isScoped}
                onImageLoaded={this.handleImageLoaded}
                paramValues={comment.getIn(['metadata', 'param_values'])}
                showTimeStamp={false}
                timeStamp={comment.get('sys_date_created')}
              />
            </Fragment>
          );
        } else {
          return (
            <Fragment key={`${commentId}_fragment`}>
              {/* comment.get('text') ?
                  <ChatMessage
                    avatarUrl={avatarUrl}
                    channelId={channelId}
                    hideAvatar={shouldHideAvatar}
                    isBarista={isBarista}
                    isExpandable={isExpandable}
                    isFirstMessage={isFirstMessageFromThisUser}
                    isLastMessage={false}
                    isMe={isMe}
                    messageText={comment.get('text')}
                    paramValues={comment.getIn(['metadata', 'param_values'])}
                    showTimeStamp={showTimeStamp}
                    userId={commentUserId}
                  />
                  :
                  null
                */}
              <ChatMessage
                attachment={comment.get('metadata')}
                avatarUrl={avatarUrl}
                checked={messagesChecked.indexOf(i) > -1}
                hideAvatar={shouldHideAvatar}
                isBarista={isBarista}
                isCaseMgmt={isCaseMgmt}
                isExpandable={isExpandable}
                isFirstMessage={isFirstMessageFromThisUser}
                isLastMessage={isLastMessageFromThisUser}
                isMe={isMe}
                isScoped={isScoped}
                onCheckboxClick={this.handleCheckboxClick(i)}
                onDownload={this.handleOnDownload({
                  commentId,
                  faqData,
                })}
                onImageLoaded={this.handleImageLoaded}
                paramValues={comment.getIn(['metadata', 'param_values'])}
                showTimeStamp={showTimeStamp}
                timeStamp={comment.get('sys_date_created')}
                userId={commentUserId}
              />
            </Fragment>
          );
        }
      } else if (comment.get('type') === ConversationMessagesTypes.TYPING) {
        return (
          <ChatMessage
            hideAvatar={shouldHideAvatar}
            isBarista={isBarista}
            isCaseMgmt={isCaseMgmt}
            isFirstMessage={isFirstMessageFromThisUser}
            isLastMessage={isLastMessageFromThisUser}
            isMe={isMe}
            isScoped={isScoped}
            key={commentId}
            paramValues={comment.getIn(['metadata', 'param_values'])}
            timeStamp={comment.get('sys_date_created')}
            typing
            userId={commentUserId}
          />
        );
      } else {
        return null;
      }
    });

    if (userDisabled) {
      res = res.concat(
        <ConversationSystemMessage
          key={'disabled'}
          text={intl.formatMessage({
            id: 'message.user_has_been_disabled',
          })}
        />
      );
    }

    return res;
  };

  render() {
    return (
      <div aria-live='polite' id='ConversationFeed'>
        {this.renderMessages()}
        <span className='ConversationFeedBottom' />
        <IFrameModal />
      </div>
    );
  }
}

const ConversationFeedTest = ConversationFeed;

// eslint-disable-next-line no-class-assign -- DEV-1526
ConversationFeed = ConversationFeedController(ConversationFeed);
export { ConversationFeedTest };
export default ConversationFeed;
