import getCurrentUser from '../../selectors/getCurrentUser';
import getTenant from '../../selectors/getTenant';
import buildCompositeController from '../../utils/buildCompositeController';
import bootstrapThunks from '../../actions/bootstrapThunks';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const tenant = getTenant(state);
  return {
    currentUser: currentUser,
    tenant: tenant,
  };
};
const mapDispatchToProps = (dispatch) => ({
  appBootstrap: () => {
    dispatch(bootstrapThunks.appBootstrap());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
