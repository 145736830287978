import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  messages: {
    arrayShape: {
      text: {
        required: true,
        type: 'string',
      },
      type: {
        required: true,
        restrictToOptions: ['message', 'system'],
        type: 'string',
      },
      user_id: {
        required: true,
        type: 'string',
      },
    },
    default: [],
    type: 'arrayOf',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'conversation01',
  },

  users: {
    arrayShape: {
      first_name: {
        required: true,
        type: 'string',
      },
      id: {
        required: true,
        type: 'string',
      },
      imageUrl: {
        required: false,
        type: 'string',
      },
      isBarista: {
        required: false,
        type: 'bool',
      },
      last_name: {
        required: true,
        type: 'string',
      },
      nickname: {
        required: true,
        type: 'string',
      },
      realUser: {
        required: false,
        type: 'bool',
      },
    },
    default: [],
    type: 'arrayOf',
  },
});

// Object Definition used by blobifier
const ConversationBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Conversation',
  templates: ['conversation01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, ConversationBlockDefinition, TypeDefaults };
