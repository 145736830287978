import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Atoms
import UserImage from '../atoms/UserImage';
// Controllers
import UserDataController from '../controllers/UserDataController';
// globals
import EspUserPropShape from '../../globals/EspUserPropShape';

/**
 * Component Container that produces a UserImage component based off given id
 * If the user exist and entities, it uses that data
 * Otherwise loads it asynchrounously before passing user data and props to UserImage
 */

class UserImageContainer extends PureComponent {
  static propTypes = {
    error: PropTypes.bool, // from the controller
    loadUserData: PropTypes.func.isRequired, // from the controller
    noAvatarLinks: PropTypes.bool,
    userData: EspUserPropShape, // object containing the user data
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired, // prop used to initialize this component
  };

  static defaultProps = {
    error: false,
    noAvatarLinks: false,
    userData: null,
  };

  componentDidMount() {
    const {
      userId,
      loadUserData,
      // userData
    } = this.props;

    // Will always invoke the loadUserData function. It already knows how to economize

    // if (userData === null) {
    //   loadUserData(userId);
    // }

    loadUserData(userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userId, loadUserData } = this.props;
    // If user ID changes, we need to load new data
    if (nextProps.userId !== userId) {
      loadUserData(nextProps.userId);
    }
  }

  handleImageError = () => {
    const { userId, loadUserData } = this.props;

    if (userId) {
      // re-load the user data on img load errors
      // aka failsafe for expired images
      loadUserData(userId);
    }
  };

  handleImageLoaded = () => {
    // console.log('Image loaded!');
  };

  render() {
    const {
      userData,
      // loadUserData,
      // userId,
      noAvatarLinks,
      ...props
    } = this.props;

    // We're using this variables as statements to avoid lint complains
    // we need to declare them so we can deconstruct ...props without those own props
    // loadUserData;
    // userId;
    // we need to remove the following props to avoid warnings about invalid html attributes
    const newProps = { ...props };
    delete newProps.userId;
    delete newProps.loadUserData;

    return (
      <UserImage
        {...newProps}
        noAvatarLinks={noAvatarLinks}
        onError={this.handleImageError}
        onLoad={this.handleImageLoaded}
        user={userData}
      />
    );
  }
}

const UserImageContainerTest = UserImageContainer;

// eslint-disable-next-line no-class-assign -- DEV-1526
UserImageContainer = UserDataController(UserImageContainer);
export { UserImageContainerTest };
export default UserImageContainer;
