import { createSelector } from 'reselect';

/**
 * Return the current selected Departments List with Users
 */

const getDepartmentUsersList = (state) => {
  const departments = state.getIn(['caseMgmt', 'serviceDepartment', 'list']);

  return departments.map((dept) => {
    const newPerf = dept;

    const idusr = dept.get('owner');
    const usr = state.getIn(['entities', 'users', idusr]);

    if (idusr && usr) {
      return newPerf.set('technical_contact', usr);
    }

    return newPerf;
  });
};

const getDepartmentsList = (departments) => departments;

const selector = createSelector([getDepartmentUsersList], getDepartmentsList);

export default selector;
