import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import { AuthContext } from '../../context';
import {
  addParamsToURL,
  getMethodInfo,
  getSearchParamsFromQuery,
} from '../../utils';

import { HTTP_METHODS, MUTATE_METHODS } from '../../constants/httpMethods';

/**
 * useAPISet
 * Returns a react-query [useMutationResult] hook, pre-configured to mutate the data
 *
 * @param {Object} config The configuration object
 * @param {Object|URLSearchParams} params URL parameters to be sent with the request
 * @return {import('react-query').useMutationResult<unknown, unknown>}
 */
const useAPISet = (
  {
    method = HTTP_METHODS.PATCH, // By default we will do PATCH
    url,
  } = {},
  config
) => {
  const { isFetching, isUnknownMethod } = getMethodInfo(method);
  if (isFetching || isUnknownMethod) {
    throw new Error(
      `${method} is an invalid parameter for 'method'. Try one of: ${MUTATE_METHODS.join(
        ', '
      )}`
    );
  }

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'useAPISet Hook can only be called inside AuthContext.Provider!'
    );
  }

  // @param data {Object} to become the request payload
  // return the useMutation object overrinding the mutateAsync funtion to create a easier readable name function
  const mutateAndReturnResult = async (opts) => {
    const { data, query, params } = opts;
    const urlWithParams = addParamsToURL(url, params);
    const queryStringHash = getSearchParamsFromQuery(query);

    const mergedParams = {
      ...queryStringHash,
    };

    // prepare config for this specific request
    const requestConfig = {
      data,
      method,
      params: new URLSearchParams(mergedParams),
      url: urlWithParams,
    };

    return authContext?.requestInstance(requestConfig);
  };

  // NOTE: If we are doing any of the mutating methods, we should instead return
  // useMutate, which will return a single function as an argument instead of an
  // object like useQuery does.
  // @manu: this will be done in the future...

  const mutation = useMutation(mutateAndReturnResult, config);

  return {
    save: mutation.mutateAsync,
    ...mutation,
  };
};

export default useAPISet;
