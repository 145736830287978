import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Button, Segment } from 'semantic-ui-react';
import Immutable from 'immutable';
import _ from 'lodash';

// Controller
import BeginChatController from '../../controllers/BeginChatController';

// Molecules
import MessageEmptyState from '../../molecules/MessageEmptyState';

// Organisms
import BlockModal from '../../organisms/BlockModal';
import SendChatMessage from '../../organisms/SendChatMessage';

// Blocks
import Conversation01 from '../conversation/Conversation01';
import PreviewProfile01 from '../previewProfile/PreviewProfile01';

// Globals
import TypeBeginChat, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBeginChat';

// Utils
import endpointGenerator from '../../../utils/endpointGenerator';

class BeginChat01 extends Component {
  static propTypes = {
    ...TypeBeginChat,

    managerId: PropTypes.number,
    modalConversationText: PropTypes.string,
    moveNextWorkflowTask: PropTypes.func.isRequired,
    onCloseWorkflow: PropTypes.func.isRequired,
    sendData: PropTypes.func.isRequired,
    userRequestedFor: PropTypes.number.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    managerId: null,
    modalConversationText: '',
  };

  state = {
    inputText: '',
    selectedChannel: null,
    showFinalConversation: false,
  };

  handleSendMessage = (e) => {
    e.preventDefault();
    const { selectedChannel } = this.state;
    const { sendData, moveNextWorkflowTask } = this.props;

    const { id } = this.props;
    const { accepted, inputText } = this.state;

    // Send the message
    if (inputText.replace(/\s/g, '') === '') {
      return;
    }
    const what = {
      body: {
        channel_id: selectedChannel,
        text: inputText,
      },
      request: endpointGenerator.genPath('espChat.messages'),
    };
    sendData(what);
    this.setState({
      showFinalConversation: true,
    });

    // send the workflow
    const dataToSend = {
      [id]: accepted,
    };

    moveNextWorkflowTask(Immutable.Map(dataToSend));
  };

  closeModalAndExitWorkflow = (e) => {
    e && e.preventDefault();
    const { onCloseWorkflow } = this.props;
    onCloseWorkflow();
  };

  setChatModal = (modal) => {
    this.chatModal = modal;
  };

  updateMessageChat = (value, selectedChannel) => {
    const newState = {
      inputText: value,
    };
    if (selectedChannel) {
      newState.selectedChannel = selectedChannel;
    }
    this.setState(newState);
  };

  handleClickBeingChat = () => {
    const {
      id,
      inputValue,
      managerId,
      onChangeMessageApproved,
      userRequestedFor,
    } = this.props;
    const accepted = inputValue.value === 'true'; // Parsing strict string value that is set in the blobifier
    onChangeMessageApproved(id, accepted);

    // Setting to who send the message
    const toWho = accepted ? userRequestedFor : managerId;

    this.setState({
      accepted: accepted,
      startChatWithWho: toWho,
    });
  };

  render() {
    const {
      buttonText,
      id,

      modalConversationText,
      modalLabelText,
      modalTitle,
      placeholderModalText,
      template,
      type,
    } = this.props;

    const { inputText } = this.state;
    const conversationAskReviseBlock = {
      id: '2429d4msg',
      messages: [
        {
          id: '000',
          text: modalConversationText,
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    const conversationFinal = {
      id: '2429f4msg',
      messages: [
        {
          id: '000',
          text: intl.formatMessage({
            id: 'message.your_msg_is_sent_check_chat',
          }),
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    const { showFinalConversation, startChatWithWho } = this.state;

    const buttonModal = showFinalConversation
      ? intl.formatMessage({
          id: 'label.close',
        })
      : intl.formatMessage({
          id: 'label.send',
        });
    const handleOnClick = showFinalConversation
      ? this.closeModalAndExitWorkflow
      : this.handleSendMessage;

    const handleOnModalClose = showFinalConversation
      ? this.closeModalAndExitWorkflow
      : _.noop;

    const triggerModal = (
      <Segment
        className='link'
        onClick={this.handleClickBeingChat}
        textAlign='center'
      >
        {buttonText}
      </Segment>
    );

    const modalActions = (
      <Button
        content={buttonModal}
        disabled={!(inputText.length > 0)}
        onClick={handleOnClick}
        primary
        size='large'
      />
    );

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <BlockModal
          modalActions={modalActions}
          modalTitle={modalTitle}
          onClose={handleOnModalClose}
          ref={this.setChatModal}
          trigger={triggerModal}
        >
          <div className='esp-blocks'>
            {showFinalConversation ? (
              <div className='block'>
                <Conversation01 {...conversationFinal} />
              </div>
            ) : null}

            {!showFinalConversation && startChatWithWho ? (
              <div className='block'>
                <Conversation01 {...conversationAskReviseBlock} />
              </div>
            ) : null}

            {!showFinalConversation && startChatWithWho ? (
              <PreviewProfile01
                id={id}
                type={'profile'}
                userId={startChatWithWho}
              />
            ) : null}

            {!showFinalConversation && startChatWithWho ? (
              <div className='block'>
                <SendChatMessage
                  id={id}
                  label={modalLabelText}
                  placeholder={placeholderModalText}
                  updateMessageChat={this.updateMessageChat}
                  withUserId={startChatWithWho}
                />
              </div>
            ) : null}

            {showFinalConversation && !startChatWithWho ? (
              <MessageEmptyState
                content={intl.formatMessage({
                  id: 'message.no_manager_no_message',
                })}
                header={intl.formatMessage({
                  id: 'label.no_manager_for_you',
                })}
                negative
              />
            ) : null}
          </div>
        </BlockModal>
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
BeginChat01 = BeginChatController(BeginChat01);

export default BeginChat01;
