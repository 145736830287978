import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';

import actionTypes from '../actions/actionTypes';

import ImmutableUtils from '../utils/ImmutableUtils';
import DirectorySortCriteria from '../utils/DirectorySortCriteria';
import DirectoryFilters from '../utils/DirectoryFilters';

const INITIAL_STATE = fromJS({
  alphabetLetter: 'A',
  // state for FavoriteUserButton
  favoriteUserButton: {
    error: null,
    isLoading: false,
  },
  filterBy: DirectoryFilters.MY_TEAM,

  openAddEmployeeWorkflow: {
    failed: false,
    isLoading: false,
  },

  selectedUser: {
    cache: Immutable.Set(),
    error: null,
    id: null,
    isLoading: false,
  },

  sortBy: DirectorySortCriteria.LAST_NAME,

  users: {
    error: null,
    ids: null,
    isLoading: false,
    isLoadingNext: false,
    isLoadingPrev: false,
    isPrependScrollFixRequired: false,
    isScrollToActiveUserRequired: false,
    isScrollToDividerRequired: false,

    pagination: {
      next: null,
      prev: null,
    },
  },
});

const onGetPrevDirectoryUsersSuccess = (state, users, prevUrl) => {
  let ids = state.getIn(['users', 'ids']);
  const newIds = ImmutableUtils.pluck(users, 'id');

  ids = newIds.concat(ids);

  return state
    .setIn(['users', 'isLoadingPrev'], false)
    .setIn(['users', 'ids'], ids)
    .setIn(['users', 'pagination', 'prev'], prevUrl)
    .setIn(['users', 'isPrependScrollFixRequired'], true);
};

const onGetNextDirectoryUsersSuccess = (state, users, nextUrl) => {
  let ids = state.getIn(['users', 'ids']);
  const newIds = users.map((user) => user.get('id'));

  ids = ids.concat(newIds);

  return state
    .setIn(['users', 'isLoadingNext'], false)
    .setIn(['users', 'ids'], ids)
    .setIn(['users', 'pagination', 'next'], nextUrl);
};

const onUnfavoriteUserSuccess = (state, userID) => {
  // get favoriteButton to it's original state
  state = state.set(
    'favoriteUserButton',
    INITIAL_STATE.get('favoriteUserButton')
  );

  const filterBy = state.get('filterBy');

  // if viewing favorites, remove unfavorited user from the list
  if (filterBy === DirectoryFilters.MY_FAVORITES) {
    let usersIDs = state.getIn(['users', 'ids']);

    if (usersIDs) {
      usersIDs = usersIDs.filterNot((id) => id === userID);
      state = state.setIn(['users', 'ids'], usersIDs);
    }
  }

  return state;
};

const onSelectDirectoryUserSuccess = (state, userID) => {
  let cache = state.getIn(['selectedUser', 'cache']);
  cache = cache.add(userID);

  return state
    .setIn(['selectedUser', 'isLoading'], false)
    .setIn(['selectedUser', 'id'], userID)
    .setIn(['selectedUser', 'cache'], cache);
};

/**
 * Just manage the 'directory' part of the state.
 * It's important to keep reducers as pure functions
 * http://redux.js.org/docs/introduction/ThreePrinciples.html#changes-are-made-with-pure-functions
 */
const directoryReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_DIRECTORY_USERS_START: {
      if (action.noResetList) {
        // Don't reset the users list during the loading
        // noResetList is now just in use with the Directory component mount
        // Todo - don't reset this list make the scrollView a bit weird when we select a letter - Need to improve that.
        return state.setIn(['users', 'isLoading'], true);
      }

      return state
        .set('users', INITIAL_STATE.get('users'))
        .setIn(['users', 'isLoading'], true);
    }

    case actionTypes.GET_DIRECTORY_USERS_SUCCESS: {
      const userIds = _.map(action.users, 'id');

      return state
        .setIn(['users', 'isLoading'], false)
        .setIn(['users', 'ids'], fromJS(userIds))
        .setIn(['users', 'pagination'], fromJS(action.pagination))
        .setIn(
          ['users', 'isScrollToActiveUserRequired'],
          action.isScrollToActiveUserRequired
        );
    }
    case actionTypes.GET_DIRECTORY_USERS_FAILURE:
      // TODO
      return state;

    case actionTypes.SET_SORT_USERS_BY:
      return state.set('sortBy', action.sortBy);

    case actionTypes.SET_FILTER_USERS_BY:
      return state.set('filterBy', action.filterBy);

    case actionTypes.SET_ALPHABET_LETTER:
      return state.set('alphabetLetter', action.alphabetLetter);

    case actionTypes.SELECT_DIRECTORY_USER_START: {
      const cache = state.getIn(['selectedUser', 'cache']);

      return state
        .set('selectedUser', INITIAL_STATE.get('selectedUser'))
        .setIn(['selectedUser', 'isLoading'], true)
        .setIn(['selectedUser', 'cache'], cache);
    }

    case actionTypes.SELECT_DIRECTORY_USER_SUCCESS:
      return onSelectDirectoryUserSuccess(state, action.user.id);

    case actionTypes.SELECT_DIRECTORY_USER_FAILURE:
      return state
        .setIn(['selectedUser', 'isLoading'], false)
        .setIn(['selectedUser', 'error'], fromJS(action.error));

    case actionTypes.GET_PREV_DIRECTORY_USERS_START:
      return state.setIn(['users', 'isLoadingPrev'], true);

    case actionTypes.GET_PREV_DIRECTORY_USERS_SUCCESS:
      return onGetPrevDirectoryUsersSuccess(
        state,
        fromJS(action.users),
        action.prevUrl
      );

    case actionTypes.GET_PREV_DIRECTORY_USERS_FAILURE:
      return state.setIn(['users', 'isLoadingPrev'], false);

    case actionTypes.DIRECTORY_PREPEND_SCROLL_FIXED:
      return state.setIn(['users', 'isPrependScrollFixRequired'], false);

    case actionTypes.DIRECTORY_SCROLLED_TO_ACTIVE_USER:
      return state.setIn(['users', 'isScrollToActiveUserRequired'], false);

    case actionTypes.DIRECTORY_SCROLL_TO_DIVIDER_REQUIRED:
      return state.setIn(['users', 'isScrollToDividerRequired'], true);

    case actionTypes.DIRECTORY_SCROLLED_TO_DIVIDER:
      return state.setIn(['users', 'isScrollToDividerRequired'], false);

    case actionTypes.GET_NEXT_DIRECTORY_USERS_START:
      return state.setIn(['users', 'isLoadingNext'], true);

    case actionTypes.GET_NEXT_DIRECTORY_USERS_SUCCESS:
      return onGetNextDirectoryUsersSuccess(
        state,
        fromJS(action.users),
        action.nextUrl
      );

    case actionTypes.GET_NEXT_DIRECTORY_USERS_FAILURE:
      return state.setIn(['users', 'isLoadingNext'], false);

    case actionTypes.RESET_DIRECTORY_OPTIONS:
      return state
        .set('sortBy', INITIAL_STATE.get('sortBy'))
        .set('filterBy', INITIAL_STATE.get('filterBy'))
        .set('alphabetLetter', INITIAL_STATE.get('alphabetLetter'));

    case actionTypes.FAVORITE_USER_START:
    case actionTypes.UNFAVORITE_USER_START:
      return state
        .set('favoriteUserButton', INITIAL_STATE.get('favoriteUserButton'))
        .setIn(['favoriteUserButton', 'isLoading'], true);

    case actionTypes.FAVORITE_USER_SUCCESS:
      return state.set(
        'favoriteUserButton',
        INITIAL_STATE.get('favoriteUserButton')
      );

    case actionTypes.UNFAVORITE_USER_SUCCESS:
      return onUnfavoriteUserSuccess(state, action.userID);

    case actionTypes.FAVORITE_USER_FAIL:
    case actionTypes.UNFAVORITE_USER_FAIL:
      return state
        .get('favoriteUserButton', INITIAL_STATE.get('favoriteUserButton'))
        .setIn(['favoriteUserButton', 'error'], action.error);

    case actionTypes.OPEN_ADD_EMPLOYEE_WORKFLOW_START:
      return state
        .set(
          'openAddEmployeeWorkflow',
          INITIAL_STATE.get('openAddEmployeeWorkflow')
        )
        .setIn(['openAddEmployeeWorkflow', 'isLoading'], true);

    case actionTypes.OPEN_ADD_EMPLOYEE_WORKFLOW_SUCCESS:
      return state.set(
        'openAddEmployeeWorkflow',
        INITIAL_STATE.get('openAddEmployeeWorkflow')
      );

    case actionTypes.OPEN_ADD_EMPLOYEE_WORKFLOW_FAIL:
      return state
        .set(
          'openAddEmployeeWorkflow',
          INITIAL_STATE.get('openAddEmployeeWorkflow')
        )
        .setIn(['openAddEmployeeWorkflow', 'failed'], true);

    default:
      return state;
  }
};

export default directoryReducer;
