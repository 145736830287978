// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Global
import groupPermissions from '../../globals/groupPermissions';
// Thunk
import onboardActivityThunks from '../../actions/onboardActivityThunks';
import onboardActivityActions from '../../actions/onboardActivityActions';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Selector
import getPerformanceList from '../../selectors/getPerformanceList';

// Limit of schedule item per page
const limitByPage = 24;

const mapStateToProps = (state) => {
  const performance = state.getIn(['onboardActivity', 'performance']);
  const performanceList = getPerformanceList(state);

  return {
    groupPermissions,
    isLoadingPerformanceList: performance.get('isLoading'),
    isSearching: performance.get('isSearching'),
    limitByPage,
    listCount: performance.get('count'),
    loadingDeleteID: performance.get('loadingDeleteID'),
    loadingEscalateID: performance.get('loadingEscalateID'),
    performanceList,
    searchTerm: performance.get('searchTerm'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  archiveOnePerformance(performanceSummaryID, msg) {
    dispatch(
      onboardActivityThunks.archiveOnePerformanceSummary(
        performanceSummaryID,
        msg
      )
    );
  },

  escalateProgress(userID) {
    dispatch(onboardActivityThunks.escalateProgressByUser(userID)).then(
      (response) => {
        dispatch(
          toastNotificationsActions.success({
            message: response.result,
            title: 'Success',
          })
        );
      }
    );
  },

  loadPerformanceList(
    page = 1,
    limit = limitByPage,
    orderBy = '-user__start_date',
    filterBy = 'NEW_HIRE'
  ) {
    dispatch(onboardActivityActions.setSearchPerformanceTerm()); // Reset search term
    // Offset logic
    const offset = (page - 1) * limit;
    dispatch(
      onboardActivityThunks.loadPerformanceList({
        filterBy,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
      })
    );
  },

  searchPerformance(
    page = 1,
    limit = limitByPage,
    orderBy = '-sys_date_updated'
  ) {
    // Offset logic
    const offset = (page - 1) * limit;

    dispatch(
      onboardActivityThunks.loadPerformanceList({
        limit: limit,
        offset: offset,
        orderBy: orderBy,
      })
    );
  },

  setSeachTerm(term) {
    dispatch(onboardActivityActions.setSearchPerformanceTerm(term));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
