import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import pt from 'prop-types';

import createAxiosInstance from '../../requestInstance';

import Page from '../../components/Page';
import TenantForm from '../../components/TenantForm';

import TenantContext from '../../context/tenant/tenantContext';
import PlatformContext from '../../context/platform/platformContext';

import tenantReducer from './tenantReducer';
import {
  FETCH_TENANT_SUCCESS,
  SET_BASE_URL,
  UNSET_BASE_URL,
} from './tenantActions';

import {
  DEFAULT_TENANT,
  ESPRESSIVE_DOMAIN,
  TENANT_STATUS,
  WEB_PLATFORMS,
} from '../../constants';

const propTypes = {
  children: pt.oneOfType([pt.node, pt.arrayOf(pt.node)]),
};

//
// This can only be called in web platforms.
//
// There are certain aspects we need to be aware of:
//
// - in development, we use 'local' suffix for the dns masquerade to work.
// - in production we use the regular '.com' suffix.
// - in MS TEAMS we use a pathname /teams
//
// * @param {String | undefined} [companyName] The subdomain for the Tenant
const generateBaseURL = (companyName) => {
  const domainSuffix = process.env.NODE_ENV === 'production' ? 'com' : 'local';
  const hostname = window?.location?.hostname;
  const pathname =
    window?.location?.pathname === '/' ? '' : window?.location?.pathname;

  const tenantSubDommain = hostname
    ?.replace('.local', '')
    ?.split(`${ESPRESSIVE_DOMAIN}`)
    ?.shift();

  const generatedBaseURL = {
    // api's baseURL - used to call the api
    apiBaseURL: `https://${companyName || tenantSubDommain}.espressive.com`,
    // web app's baseURL - used to redirect if needed
    webBaseURL: `https://${
      companyName || tenantSubDommain
    }.espressive.${domainSuffix}${pathname ? pathname : ''}`,
    // DEV-16323: remove port when generating web base URL to play nice with reverse proxy
  };

  return generatedBaseURL;
};

//
// Espressive Tenant Provider
//
// This provider allows the user to select a tenant if no tenant has been selected.
//
// @param {JSX.Element} children The content to be displayed when there is an active
const TenantProvider = ({ children }) => {
  const { apiBaseURL: derivedBaseURL } = generateBaseURL();
  const { type: platformType } = useContext(PlatformContext);
  const isWeb = WEB_PLATFORMS.includes(platformType);

  const [state, dispatch] = useReducer(tenantReducer, {
    baseURL: isWeb ? derivedBaseURL : null,
    firstRender: true,
    message: 'Please, type your company name',
    status: TENANT_STATUS.NO_TENANT,
    ...DEFAULT_TENANT,
  });

  const espressiveAPI = useMemo(
    () =>
      createAxiosInstance({
        baseURL: state.baseURL,
      }),
    [state.baseURL]
  );

  //
  // Resets tenant to its initial value
  const clearTenant = useCallback(() => {
    dispatch({
      type: UNSET_BASE_URL,
    });
  }, [dispatch]);

  //
  // Fetch tenant
  //
  // This function makes 3 calls to our API:
  //
  // - fetch images
  // - fetch domain
  // - fetch data
  //
  // The data from these 3 calls is merged and stored
  // as the `tenant` object in the state.
  const fetchTenant = useCallback(async () => {
    try {
      const tenantDomain = state.baseURL
        .replace('https://', '')
        .replace('/', '');

      const {
        data: { results: tenantImages },
      } = await espressiveAPI.get(
        `${state.baseURL}/api/images/v0.1/tenant_images/`,
        { params: { limit: 1000 } }
      );

      const tenantDomainResponse = await espressiveAPI.get(
        `${state.baseURL}/api/tenants/v0.1/domain/`,
        { params: { domain: tenantDomain } }
      );

      const [
        { domain, id, is_primary, is_primary_for_email },
      ] = tenantDomainResponse.data.results;

      const {
        data: { results: tenantDataResults },
      } = await espressiveAPI.get(`${state.baseURL}/api/tenants/v0.1/tenant/`);

      const [tenantData] = tenantDataResults;

      const { data: authTypeResponse } = await espressiveAPI.get(
        `${state.baseURL}/api/authentication/v0.1/auth/login/`
      );
      const authorizationEndpoint = authTypeResponse?.data?.redirect_uri;

      // Create a hash map of tenant images. This will create a single object key
      // for each image type in the tenant images array. If there are duplicates in
      // image types, they will be overridden with any duplicate image types. This
      // is expected at the application level that there will only be one image per
      // type in this array. If there is an image in this array without a type, it
      // is not added to the hash map.
      const tenantImageHashMap = tenantImages.reduce(
        (allImages, currentImage) => {
          if (currentImage.type) {
            allImages[currentImage.type] = currentImage.image;
          }

          return allImages;
        },
        {}
      );

      // Check if there is any branding set on the tenant under `branding_obj`
      if (tenantData?.branding_obj) {
        // Create an theme object and set it to the 'theme' key
        // DEV-15685: prevent app from blowing up if we are missing any branding values
        tenantData.theme = {
          color: {
            primary:
              tenantData?.branding_obj?.color_primary ||
              DEFAULT_TENANT.tenant.branding_obj.color_primary,
            secondary:
              tenantData?.branding_obj?.header_color ||
              DEFAULT_TENANT.tenant.branding_obj.header_color,
          },
        };
      } else {
        tenantData.theme = {
          color: {
            primary: DEFAULT_TENANT.tenant.branding_obj.color_primary,
            secondary: DEFAULT_TENANT.tenant.branding_obj.header_color,
          },
        };
      }

      dispatch({
        payload: {
          authorizationEndpoint,
          domain,
          id,
          images: tenantImageHashMap,
          is_primary,
          is_primary_for_email,
          ...tenantData,
        },
        type: FETCH_TENANT_SUCCESS,
      });
    } catch (tenantLoadException) {
      // eslint-disable-next-line no-console -- we need this as developer message
      console.warn(tenantLoadException);

      // The baseURL is rubbish, throw it away..
      dispatch({
        payload: {
          isError: true,
          message: tenantLoadException.toString(),
        },
        type: UNSET_BASE_URL,
      });
    }
  }, [espressiveAPI, dispatch, state.baseURL]);

  //
  // The function that is called when the User submits the tenant form.
  // This will build a tenant URL from the company name and navigate to it.
  //
  // @param {String} companyName The User's company name
  const handleTenantFormSubmission = useCallback(
    (companyName) => {
      const safeCompanyName = companyName.trim().replace(/\s/g, '');
      const { apiBaseURL, webBaseURL } = generateBaseURL(safeCompanyName);

      dispatch({
        payload: apiBaseURL,
        type: SET_BASE_URL,
      });

      window.location.assign(webBaseURL);
    },
    [dispatch]
  );

  // mute anything we won't expose:
  const { firstRender, status, ...rest } = state;
  const disclosureObject = {
    ...rest,
  };

  // tenant can only be selected in hybrid platforms
  if (!isWeb) {
    disclosureObject.clearTenant = clearTenant;
  }

  // Fetch tenant data if tenant has been derived and baseURL
  // is set but tenant data hasn't been fetched yet.
  useEffect(() => {
    if (
      state.baseURL &&
      state.baseURL !== 'https://localhost.espressive.com' &&
      state.status === TENANT_STATUS.NO_TENANT
    ) {
      fetchTenant();
    }
  }, [fetchTenant, state.baseURL, state.status]);

  const spreadProps = {};
  if (status === TENANT_STATUS.NO_TENANT) {
    if (state.baseURL) {
      spreadProps.info = true;
    } else {
      spreadProps.negative = true;
    }
  } else {
    spreadProps.negative = true;
  }

  const tenantScreen = (
    <Page>
      <TenantForm {...spreadProps} onSubmit={handleTenantFormSubmission} />
    </Page>
  );

  return (
    <TenantContext.Provider value={disclosureObject}>
      {firstRender && status === TENANT_STATUS.NO_TENANT
        ? state.baseURL === 'https://localhost.espressive.com'
          ? tenantScreen
          : null
        : status === TENANT_STATUS.READY
        ? children
        : tenantScreen}
    </TenantContext.Provider>
  );
};

TenantProvider.propTypes = propTypes;

export default TenantProvider;
