import { createSelector } from 'reselect';
import _, { every, sum } from 'lodash';
import getUnreadByType from './getUnreadByType';
import ChannelTypes from '../globals/ChannelTypes';

const directCount = (state) =>
  getUnreadByType(state, ChannelTypes.DIRECT_CHANNEL);
const scopedCount = (state) =>
  getUnreadByType(state, ChannelTypes.SCOPED_CHANNEL);

const getTotalUnreadCount = (unreadCountDirect, unreadCountScoped) => {
  if (_.isInteger(unreadCountDirect) && _.isInteger(unreadCountScoped)) {
    return unreadCountDirect + unreadCountScoped;
  }

  return 0;
};

const getTotalUnreadCountOrNull = (unreadCountDirect, unreadCountScoped) => {
  const counts = [unreadCountDirect, unreadCountScoped];
  return every(counts, _.isInteger) ? sum(counts) : null;
};

const getTotalUnreadCountOrNullSelector = createSelector(
  [directCount, scopedCount],
  getTotalUnreadCountOrNull
);

const selector = createSelector(
  [directCount, scopedCount],
  getTotalUnreadCount
);
export { getTotalUnreadCountOrNullSelector };
export default selector;
