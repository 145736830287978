import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesSharpies extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M46.6 11.08s-.04.784-.054 1.64h-.43v2.81c-2.577 3.878-2.677 13.378-2.677 13.378h-.537L43.3 61.15h-.564c0 7.627 1.104 21.775 2.408 23.08.87.868 4.262 1.65 6.12-.1 1.707-1.606 2.11-22.98 2.11-22.98h-.436l.267-32.242h-.535c.4-7.627-2.943-13.38-2.943-13.38v-2.81h-.52v-2.44l-2.056-.854c.002 0-.418.996-.55 1.656zm.174 68.873c1.332 1.11 2.5.157 2.55.116.126-.106.314-.093.418.034.105.124.09.31-.034.416-.016.014-1.626 1.35-3.368-.16-.128-.1-.497-.427-.573-16.842 0-.164.13-.297.295-.298.082 0 .156.033.21.087.053.053.086.126.087.208.026 5.923.163 15.513.414 16.438zm5.494-50.376l-.534 31.44H44.31l-.27-31.44h8.228z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesSharpies;
