import React, { useCallback, useContext } from 'react';
import { labels, samlURLS } from '../utils/constants';
import IntegrationSetupWizard from '../../IntegrationSetupWizard/IntegrationSetupWizard';
import { Form, List, Table } from 'semantic-ui-react';
import styles from './OktaSSOWithSAMLModal.module.scss';
import { object, string } from 'yup';

import OktaSAMLContext from './OktaSAMLContext';
import MetadataURLField from './MetadataURLField';
import CopyField from '../../IntegrationSetupWizard/utils/CopyField';

const propTypes = {};

const AzureSSOWithSAMLModal = () => {
  const { handleSave } = useContext(OktaSAMLContext);

  const steps = [
    {
      content: (
        <List ordered>
          <List.Item>
            Log in with an admin role and go to your Okta admin center
          </List.Item>
          <List.Item>
            Go to <strong>{'Applications > Applications'}</strong>
          </List.Item>
          <List.Item>
            Click on <strong>{'Create App Integration'}</strong>
          </List.Item>
          <List.Item>
            Choose <strong>SAML 2.0</strong> and click <strong>Next</strong>
          </List.Item>
          <List.Item>
            Name your app and click <strong>Next</strong>
          </List.Item>
        </List>
      ),
      stepName: 'Create App Integration',
    },
    {
      content: (
        <div className={styles._}>
          <p>
            After clicking next you should be on the Configure SAML step of the
            app integration creation screen.
          </p>

          <List ordered>
            <List.Item>Configure the below values in the form</List.Item>
            <List.Item>
              Choose <strong>{'sign-on method SAML'}</strong>
            </List.Item>
            <List.Item>
              Click <strong>Next</strong>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>Field</Table.HeaderCell>
                    <Table.HeaderCell width={12}>Value</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Single sign on URL</Table.Cell>
                    <Table.Cell>
                      <CopyField text={samlURLS.singleSignOnURL} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Audience URI</Table.Cell>
                    <Table.Cell>
                      <CopyField text={samlURLS.audienceURI} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Application Username</Table.Cell>
                    <Table.Cell>Okta username OR Email</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </List.Item>
            <List.Item>
              On the <strong>Feedback screen</strong> select:
              <List as='ul'>
                <List.Item as='li'>
                  I’m an Okta customer adding an internal app
                </List.Item>
                <List.Item as='li'>
                  This is an internal app that we have created
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              Click <strong>Finish</strong>
            </List.Item>
          </List>
        </div>
      ),
      stepName: 'Configure SAML',
    },
    {
      content: (
        <div className={styles._}>
          <p>
            After your app is created you should be directed to a tab named Sign
            On.
          </p>

          <List ordered>
            <List.Item>
              Under the section <strong>{'SAML Signing Certificates'}</strong>{' '}
              find the entry where <strong>Type = SHA-2</strong>
            </List.Item>
            <List.Item>
              Click the <strong>Actions</strong> dropdown and select{' '}
              <strong>View IdP Metadata</strong>
            </List.Item>
            <List.Item>
              <p>
                This will open a browser window with XML.{' '}
                <strong>Copy the URL</strong> from the URL bar and paste into
                the field below:
              </p>
            </List.Item>

            <Form as={'div'} style={{ marginTop: '15px' }}>
              <MetadataURLField />
            </Form>
          </List>
        </div>
      ),
      initialValues: {
        id_p_metadata_url: '',
      },
      stepName: 'SAML Certificate',
      validationSchema: object().shape({
        id_p_metadata_url: string().required('idP Metadata URL is required'),
      }),
    },
    {
      content: (
        <div className={styles._}>
          <p>
            Now we need to apply our SSO app to our users. You can choose to
            granularly control this based on the needs of your organization.
          </p>

          <List ordered>
            <List.Item>
              Go back to the main page of your admin center in Okta
            </List.Item>
            <List.Item>
              Go to <strong>{'Applications > Applications'}</strong>
            </List.Item>
            <List.Item>
              Find the app you just created in the list and click on the
              dropdown on the right hand side.
            </List.Item>
            <List.Item>
              Assign users and groups according to your needs.
            </List.Item>
          </List>
        </div>
      ),
      stepName: 'Assign App',
    },
  ];

  const handleOnClose = useCallback(
    (list) => {
      if (list) {
        const inputFieldLocationIndex = list.length - 2;
        const { formValues } = list[inputFieldLocationIndex];

        handleSave({
          idPMetadataUrl: formValues.id_p_metadata_url,
        });
      }
    },
    [handleSave]
  );

  return (
    <IntegrationSetupWizard
      onClose={handleOnClose}
      steps={steps}
      title={labels.MODAL_TITLE}
      triggerButtonTitle={labels.TRIGGER_BUTTON}
    />
  );
};

AzureSSOWithSAMLModal.propTypes = propTypes;

export default AzureSSOWithSAMLModal;
