import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurniture extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M53.26 41.46c0-.828-.672-1.5-1.5-1.5H13.51c-.4-.003-.784.154-1.067.436-.284.28-.443.664-.443 1.064v41.6h16.81v-40.1h23c.81-.027 1.45-.69 1.45-1.5zM82.16 13.28c-1.223-.637-2.73-.162-3.37 1.06-7.186 13.74-11.442 28.82-12.5 44.29h-18c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h10.3v11.29c-9.1.34-10 5.77-10.07 6-.12.802.43 1.552 1.23 1.68H50c.723-.006 1.342-.52 1.48-1.23 0-.14.74-3.23 7.11-3.49v3.38c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-3.31c5.74.43 6.44 3.27 6.48 3.45.122.722.748 1.25 1.48 1.25h.22c.813-.138 1.363-.906 1.23-1.72 0-.23-.92-5.44-9.44-6v-11.3h7.12c.667 0 1.305-.266 1.775-.74.47-.472.73-1.114.725-1.78.69-15.52 4.787-30.7 12-44.46.643-1.213.188-2.717-1.02-3.37z' />
        </g>
      </svg>
    );
  }
}

export default CatalogFurniture;
