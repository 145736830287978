const api_search_v0_1_typeahead_espuser_Espuser = [
  {
    display_name: 'John Doe',
    pk: 5,
  },
  {
    display_name: 'John Smith',
    pk: 8,
  },
];

export default api_search_v0_1_typeahead_espuser_Espuser;
