import { fromJS } from 'immutable';

import profileActions from '../actions/profileActions';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  error: false,
  isLoading: false,
  users: {
    full_name: null,
    id: null,
    url: null,
  },
});

const mapUserIds = (users) => users.map((user) => user.get('id'));

const profileReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case profileActions.GET_USERS_PROFILE_START:
      return state.set('isLoading', true).set('error', false);

    case profileActions.GET_USERS_PROFILE_ERROR:
      return state.set('isLoading', false).set('error', true);

    case profileActions.GET_USERS_PROFILE_SUCCESS:
      return state
        .set('isLoading', false)
        .set('error', false)
        .setIn(['users', 'ids'], mapUserIds(action.users));

    case actionTypes.ASSIGN_DELEGATE_START: {
      return state.set('isLoadingAssignDelegate', true);
    }

    case actionTypes.ASSIGN_DELEGATE_SUCCESS: {
      return state.set('isLoadingAssignDelegate', false);
    }

    case actionTypes.ASSIGN_DELEGATE_FAIL: {
      return state.set('isLoadingAssignDelegate', false);
    }
    default: {
      return state;
    }
  }
};

export default profileReducer;
