import _ from 'lodash';
import Immutable from 'immutable';

// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import onboardActivityThunks from '../../actions/onboardActivityThunks';
import jobRoleThunks from '../../actions/jobRoleThunks';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Selector
import getPerformanceDetail from '../../selectors/getPerformanceDetail';
import getPermissionsWithNewHire from '../../selectors/getPermissionsWithNewHire';

const mapStateToProps = (state, ownProps) => {
  const performance = state.getIn(['onboardActivity', 'performance']);

  let performanceDetail = Immutable.List(),
    userID;

  if (_.has(ownProps.params, 'userID')) {
    userID = Number(ownProps.params.userID);
  } else {
    // for on app onboarding activities empty state
    userID = state.getIn(['onboardProgressManagerView', 'selectedUserID']);
  }

  let selectedUser;

  if (userID) {
    performanceDetail = getPerformanceDetail(state, userID);
    selectedUser = state.getIn(['entities', 'users', userID]);
  }

  const jobRoles = state.getIn(['entities', 'jobRoles']) || Immutable.List();
  const selectedUserJobRole =
    jobRoles.find((jr) => {
      const url = (selectedUser && selectedUser.get('job_role')) || '';
      return url && jr.get('url') === url;
    }) || Immutable.Map();

  let selectedUserManager;

  if (selectedUser) {
    let managerId = selectedUser ? selectedUser.get('manager') : null;
    if (managerId) {
      managerId = managerId.split('/');
      managerId = Number(managerId[managerId.length - 2]);
    }
    selectedUserManager = state.getIn(['entities', 'users', managerId]);
  }

  return {
    groupPermissions: getPermissionsWithNewHire(),
    isLoadingPerformanceDetail: performance.get('isLoading'),
    performanceDetail,
    selectedUser,
    selectedUserJobRole,
    selectedUserManager,
  };
};

const mapDispatchToProps = (dispatch) => ({
  escalateProgress(userID) {
    dispatch(onboardActivityThunks.escalateProgressByUser(userID)).then(
      (response) => {
        dispatch(
          toastNotificationsActions.success({
            message: response.result,
            title: 'Success',
          })
        );
      }
    );
  },
  loadPerformanceDetailList(userID, type) {
    dispatch(onboardActivityThunks.loadPerformanceDetailList(userID, type));
  },
  loadUserJobRole(user) {
    const jobRole = user.get('job_role') || null;
    if (jobRole) {
      dispatch(jobRoleThunks.getUserJobRole(jobRole));
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
