const api_todo_v0_1_template = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      card_template: 15,
      category: 'HR Benefits',
      deadline_days: 0,
      deadline_hours: 0,
      description:
        'Mlkshk blue bottle cornhole, drinking vinegar tumeric artisan gentrify brunch PBR&B. Mumblecore seitan venmo, woke food truck yuccie wayfarers af fanny pack....',
      display_attributes: null,
      eid: '0739ef56-2567-44d1-aa49-22726658845b',
      enable_deadline: false,
      id: 1,
      private_notes: '',
      schedules: [
        {
          description:
            'Mlkshk blue bottle cornhole, drinking vinegar tumeric artisan gentrify brunch PBR&B. Mumblecore seitan venmo, woke food truck yuccie wayfarers af fanny pack.',
          id: 1,
          name: 'what is this?',
          status: 'DRAFT',
          url: 'https://qa.dev.espressive.com/api/todo/v0.1/schedule/1/',
        },
      ],
      status: 'ACTIVE',
      sys_created_by: 'admin@acme.com',
      sys_date_created: '2017-09-07T18:34:16.718025Z',
      sys_date_updated: '2017-09-13T21:11:41.327628Z',
      sys_updated_by: 'ricardo@espressive.com',
      title: 'This is the best link',
      type: 'WEB_LINK',
      url: 'https://qa.dev.espressive.com/api/todo/v0.1/template/1/',
      web_link:
        'http://qa.dev.espressive.dev:3000/admin/onboarding-activities/templates/1',
    },
  ],
};

export default api_todo_v0_1_template;
