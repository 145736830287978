import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// Utils
import asEllipsis from '../../../v2/utils/asEllipsis';
import dateFormat from '../../utils/dateFormat';
import uiPathGenerator from '../../utils/uiPathGenerator';
// Globals
import CaseSearchAttributesI18n from '../../globals/CaseSearchAttributesI18n';
import TextHighlight from '../atoms/TextHighlight';

import EspListItem from './EspListItem';
import { intl } from 'esp-util-intl';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

class CaseSearchResult extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    ignoredProperties: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func.isRequired,
    result: ImmutablePropTypes.map.isRequired,
    routeToResult: PropTypes.string.isRequired,
    routeToResultIncludesQ: PropTypes.bool.isRequired,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    active: false,
    ignoredProperties: ['pk', 'notes_list'],
  };

  getTranslatedLabel = (attributeName) => {
    if (CaseSearchAttributesI18n[attributeName]) {
      return intl.formatMessage({
        id: CaseSearchAttributesI18n[attributeName],
      });
    }
    return capitalizeFirstLetter(attributeName);
  };

  render() {
    const {
      active,
      ignoredProperties,
      onClick,
      routeToResult,
      routeToResultIncludesQ,
      userInput,
    } = this.props;
    let { result } = this.props;

    const urlToResult = uiPathGenerator.genPath(routeToResult, {
      caseID: result.get('pk'),
    });
    let toResult = urlToResult;

    if (routeToResultIncludesQ) {
      toResult = {
        pathname: urlToResult,
        search: `?q=${userInput}`,
      };
    }

    const name = result.get('summary');
    const nameEl = <TextHighlight term={userInput} text={name} />;

    result = result.delete('summary');
    result = result.delete('display_value');

    ignoredProperties.forEach((name) => {
      result = result.delete(name);
    });

    const descriptionEl = result.reduce((list, value, key) => {
      const subStatus = result.get('sub_status', '');
      let displayValue = `${value}`;

      if (key === 'sub_status') {
        // sub_status is merged to status
        return list;
      }

      if (key === 'status') {
        displayValue = `${value}${subStatus ? ` - ${subStatus}` : ''}`;
      }

      if (key === 'sys_date_updated') {
        displayValue = dateFormat(value, true);
      }

      if (key === 'notes') {
        displayValue = asEllipsis(value, userInput, 25);
      }

      list.push(
        <React.Fragment key={value + key.toString()}>
          {`${this.getTranslatedLabel(key)}: `}
          <TextHighlight as='span' term={userInput} text={displayValue} />
          <br />
        </React.Fragment>
      );

      return list;
    }, []);

    return (
      <EspListItem
        active={active}
        as={Link}
        content={descriptionEl}
        isSearchResult
        name={nameEl}
        onClick={onClick}
        to={toResult}
      />
    );
  }
}

export default CaseSearchResult;
