import _ from 'lodash';

import { any, optional } from './PathParamsValidators';

class PathParam {
  constructor(args) {
    if (_.isEmpty(args)) {
      throw new Error(
        'paramName argument is required, no arguments were provided.'
      );
    }

    const [paramName, ...validators] = args;

    if (!_.isString(paramName)) {
      throw new Error('paramName argument must be a String.');
    }

    if (_.isEmpty(paramName)) {
      throw new Error('paramName must not be empty.');
    }

    const areAllValidatorsFunctions = validators.every((validator) =>
      _.isFunction(validator)
    );

    if (!areAllValidatorsFunctions) {
      throw new Error('All validators must be functions.');
    }

    this.paramName = paramName;

    if (_.isEmpty(validators)) {
      this.validators = [any];
    } else {
      this.validators = validators;
    }
  }

  getParamName() {
    return this.paramName;
  }

  getValidators() {
    return this.validators;
  }

  /** Evaluates applying validators from left to right */
  evaluate() {
    return this.validators.reduce(
      (evaluatedParam, validator) => validator(evaluatedParam),
      this.paramName
    );
  }

  /** Evaluates without applying validators, but optional */
  relaxedEvaluate() {
    let evaluatedParam = any(this.paramName);

    // keep as optional
    if (_.includes(this.validators, optional)) {
      evaluatedParam = optional(evaluatedParam);
    }

    return evaluatedParam;
  }
}

// sugar syntax around PathParam class
const param = (...args) => new PathParam(args);

export { param };

export default PathParam;
