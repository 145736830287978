import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Pagination } from 'esp-ui-pagination';

import { debounce } from 'lodash';
import { Button, Menu, Message, Search, Segment } from 'semantic-ui-react';
import useScheduleList from '../hooks/useScheduleList';
import uiPathGenerator from '../../../../../utils/uiPathGenerator';
import { Link } from 'react-router-dom';
import ScheduleTable from './ScheduleTable';

const INITIAL_PAGINATION = {
  limit: 10,
  offset: 1,
  orderBy: '-sys_date_updated',
  page: 0,
};
const ScheduleList = () => {
  const [paginationData, setPaginationData] = useState(INITIAL_PAGINATION);

  const [isSearching, setIsSearching] = useState(false);
  const searchTermRef = useRef('');

  const {
    loadOffboardingSchedules,
    schedulesListData,
    records,
  } = useScheduleList();

  const handleStartSearch = debounce((_, { value }) => {
    setIsSearching(true);
    searchTermRef.current = value;
    loadOffboardingSchedules({ ...INITIAL_PAGINATION, searchTerm: value }).then(
      () => {
        setIsSearching(false);
      }
    );
  }, 300);

  useEffect(() => {
    loadOffboardingSchedules({});
    // eslint-disable-next-line react-hooks/exhaustive-deps -- test
  }, []);

  const handlePagination = useCallback(
    (newPagination) => {
      const pagination = { ...INITIAL_PAGINATION, ...newPagination };
      setPaginationData(pagination);
      loadOffboardingSchedules(pagination);
    },
    [loadOffboardingSchedules]
  );
  const emptySearch = searchTermRef?.current && schedulesListData?.count === 0;

  return (
    <Segment attached clearing>
      <h4>Offboarding Schedule</h4>
      <Menu borderless secondary>
        <Menu.Item fitted>
          <Search
            loading={isSearching}
            onSearchChange={handleStartSearch}
            placeholder='Search...'
            showNoResults={false}
          />
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item fitted>
            <Button
              as={Link}
              basic
              content='Create Schedule'
              to={uiPathGenerator.genPath(
                'admin.adminActivities.offboarding.schedule',
                {
                  scheduleID: 'new',
                }
              )}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Pagination
        currentPage={paginationData.currentPage}
        itemsPerPageLimit={paginationData.limit}
        onPaginationChange={handlePagination}
        recordCount={schedulesListData.count}
      >
        <ScheduleTable
          loadOffboardingSchedules={loadOffboardingSchedules}
          tableList={records}
        />
        {emptySearch ? (
          <Message info>
            {`This search does not match any existing schedule. Please click the
            create schedule button on the right to add one.`}
          </Message>
        ) : null}
      </Pagination>
    </Segment>
  );
};

export default ScheduleList;
