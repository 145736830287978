import { createSelector } from 'reselect';
import immutable from 'immutable';

// Global
import UserImageTypes from '../globals/UserImageTypes';

const getUsers = (state) => state.getIn(['entities', 'users']);

const getId = (state, listOfUser) => listOfUser;

/**
 * Update A list of user got from the typeahead API
 * and Add a Image property
 * @param users
 * @param listOfUser
 * @returns {*}
 */
const getUserImageFromEntities = (users, listOfUser) => {
  if (!listOfUser || listOfUser.isEmpty()) {
    return immutable.List();
  }

  listOfUser = listOfUser.map((user) => {
    const userFind = users && users.get(Number(user.get('pk')));
    if (userFind && !userFind.isEmpty()) {
      return user
        .set('ready', true)
        .set('images', userFind.get('images'))
        .set('id', Number(user.get('pk')));
    }

    // If not image, the API return us an object intead of an array
    const userImg =
      user.has('images') && !user.get('images').isEmpty()
        ? user.get('images') instanceof immutable.List
          ? user.get('images')
          : immutable.fromJS([
              {
                id: 1, // Fake ID to avoid UserImage to complain
                picture: user.getIn(['images', 'profile_picture']),
                thumbnail: user.getIn(['images', 'profile_thumbnail']),
                type: UserImageTypes.PROFILE,
              },
            ])
        : immutable.fromJS([]);

    return user.set('id', Number(user.get('pk'))).set('images', userImg);
  });

  return listOfUser;
};

const selector = createSelector([getUsers, getId], getUserImageFromEntities);

export default selector;
