import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { curry, isBoolean, memoize } from 'lodash';

// Controller
import Radio02Controller from '../../controllers/Radio02Controller';

// Globals
import TypeRadio, { TypeDefaults } from '../../../globals/blocks/TypeRadio';

class Radio02 extends Component {
  static propTypes = {
    ...TypeRadio,
    formValues: ImmutablePropTypes.map,
    onChangeValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    formValues: null,
  };

  constructor(props) {
    super(props);

    const value = props.formValues ? props.formValues.get(props.id) : '';
    const options = props.options.map((op) => {
      if (op.id === value.toString()) {
        op.checked = true;
      }
      return op;
    });

    this.state = {
      options,
    };
  }

  componentDidMount() {
    const { formValues, options, onChangeValue, id } = this.props;

    const value = formValues.get(id);

    if (!value || !isBoolean(value)) {
      options.forEach((option) => {
        if (option.checked) {
          onChangeValue(option.id, id);
        }
      });
    }
  }

  handleClick = memoize(
    curry((optionId, e) => {
      // Marking only selected option as checked
      const { options, onChangeValue, id } = this.props;

      const newOptions = options.map((option) => {
        option.checked = option.id === optionId;
        if (option.checked) {
          onChangeValue(option.id, id);
        }
        return option;
      });

      this.setState({
        options: newOptions,
      });
    })
  );

  render() {
    const { template, type, id } = this.props;
    const { options } = this.state;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Segment.Group>
          {options.map((option) => {
            const checked = Boolean(option.checked);
            return (
              <Segment
                className={classNames({
                  active: checked,
                  link: true,
                })}
                key={option.id}
                onClick={this.handleClick(option.id)}
                textAlign='center'
              >
                {option.label}

                {checked && <Icon name='checkmark' />}
              </Segment>
            );
          })}
          <Field
            className='hidden'
            component='input'
            id={id}
            name={id.toString()} // name propType needs to be a string
            type='text'
          />
        </Segment.Group>
      </div>
    );
  }
}

export const Radio02Test = Radio02;

export default Radio02Controller(Radio02);
