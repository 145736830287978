import hashUtils from '../../../../app/js/v1/utils/hashUtils';
import actionTypes from '../../../../app/js/v1/actions/actionTypes';
import workflowBrowserPageActions from '../actions/workflowBrowserPageActions';
import { fromJS, Range } from 'immutable';

const INITIAL_STATE = fromJS({
  currentEditingTask: null,

  isLoadingAssignDelegate: false,

  newTask: {
    error: null,
    isLoading: false,
  },

  objectMappingList: null,

  removingTask: {
    error: null,
    isLoading: false,
  },

  saveStatus: {
    taskFormHasErrors: false,
    taskFormIsChanged: false,
    taskFormSaveInProgress: false,
    workflowFormIsChanged: false,
    workflowFormSaveInProgress: false,
  },

  // values of the task currently being edited in workflow task editor
  selectedTaskId: null,

  tasks: {
    changedWorkflowId: null,
    conditionSet: {},
    error: null,

    isLoading: false,

    items: null,

    // what workflow do this tasks belongs to?,
    // represents a permutation of the items list,
    // basically this is a way to lazyly change the order of the tasks
    // ordering: null,
    selectedWorkflowId: null,

    showPreview: false,
    // TODO Object of tasks indexed by id or an Array of them?
    workflow: null,
  },

  workflows: {
    formError: '',
    isLoading: false,
    loadError: '',
    modalOpen: false,

    // TODO. move this into entities
    workflows: [],

    workflowsOptions: [
      {
        text: '',
        value: '',
      },
    ],
  },
});

/**
 * Just manage the 'editor' part of the state.
 * It's important to keep reducers as pure functions
 * http://redux.js.org/docs/introduction/ThreePrinciples.html#changes-are-made-with-pure-functions
 */
const editorReducer = (state, action) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  switch (action.type) {
    case workflowBrowserPageActions.WORKFLOW_CREATE_START:
      return state
        .setIn(['workflows', 'isLoading'], true)
        .setIn(['workflows', 'formError'], '');

    case workflowBrowserPageActions.WORKFLOW_CREATE_ERROR:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'formError'], action.formError);

    case workflowBrowserPageActions.WORKFLOW_CREATE_CLEAR_ERROR:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'formError'], '');

    case workflowBrowserPageActions.WORKFLOW_CREATE_SUCESS:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'formError'], '');

    case workflowBrowserPageActions.LOAD_WORKFLOWS_START:
      return state
        .setIn(['workflows', 'isLoading'], true)
        .setIn(['workflows', 'loadError'], '');

    case workflowBrowserPageActions.LOAD_WORKFLOWS_ERROR:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'loadError'], action.error);

    case workflowBrowserPageActions.LOAD_WORKFLOWS_SUCCESS:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'loadError'], '')
        .setIn(['workflows', 'workflows'], action.workflows);

    case workflowBrowserPageActions.WORKFLOW_EDIT_START:
      return state
        .setIn(['workflows', 'isLoading'], true)
        .setIn(['workflows', 'formError'], '');

    case workflowBrowserPageActions.WORKFLOW_EDIT_SUCCESS:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'formError'], '')
        .setIn(['tasks', 'workflow'], fromJS(action.workflow));

    case workflowBrowserPageActions.WORKFLOW_EDIT_ERROR:
      return state
        .setIn(['workflows', 'isLoading'], false)
        .setIn(['workflows', 'formError'], action.formError);

    case actionTypes.LOAD_CONDITION_TASK_START:
      return state.setIn(['tasks', 'isLoading'], true);

    case actionTypes.LOAD_CONDITION_TASK_FAIL:
      return state.setIn(['tasks', 'isLoading'], false);

    case actionTypes.LOAD_CONDITION_TASK_SUCCESS:
      return state
        .setIn(['tasks', 'isLoading'], false)
        .setIn(['tasks', 'conditionSet'], fromJS(action.condition));

    case actionTypes.START_SAVING_TASKS:
      return state.setIn(['saveStatus', 'workflowFormSaveInProgress'], true);

    case actionTypes.GET_TASKS_START:
      return state
        .set('tasks', INITIAL_STATE.get('tasks'))
        .setIn(['tasks', 'isLoading'], true);

    case actionTypes.GET_TASKS_SUCCESS:
      return state
        .setIn(['tasks', 'isLoading'], false)
        .setIn(['tasks', 'workflow'], fromJS(action.workflow))
        .setIn(['tasks', 'items'], fromJS(action.tasks))
        .setIn(['tasks', 'ordering'], Range(0, action.tasks.length).toList())
        .setIn(['saveStatus', 'workflowFormSaveInProgress'], false)
        .setIn(['saveStatus', 'workflowFormIsChanged'], false);

    case actionTypes.GET_WORKFLOWS_FAILURE:
      // TODO
      // Ups! we are not handling this error!
      return state;

    case actionTypes.SAVE_TASKS_ORDER:
      // const tasks = state.getIn(['tasks', 'items']);
      return state.setIn(['tasks', 'items'], fromJS(action.tasks));

    case actionTypes.REARRANGE_TASKS: {
      const items = state.getIn(['tasks', 'items']);

      const ordering = fromJS(action.ordering);

      const rearrangedItems = ordering.map((orderedTaskIndex) =>
        items.get(orderedTaskIndex)
      );

      return state
        .setIn(['tasks', 'items'], rearrangedItems)
        .setIn(['saveStatus', 'workflowFormIsChanged'], true);
    }
    case actionTypes.SAVE_TASK_IN_PROGRESS:
      return state.setIn(['saveStatus', 'taskFormSaveInProgress'], true);

    case actionTypes.SAVE_TASK_SUCCESS: {
      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], false)
        .setIn(['saveStatus', 'taskFormSaveInProgress'], false)
        .setIn(['tasks', 'changedWorkflowId'], null)
        .setIn(
          ['currentEditingTask', 'attributes'],
          fromJS(action.task.attributes)
        )
        .setIn(
          ['currentEditingTask', 'related_workflow_condition'],
          fromJS(action.task.related_workflow_condition)
        )
        .updateIn(['tasks', 'items'], (items) =>
          items.update(
            items.findIndex((item) => item.get('id') === action.task.id),
            () => fromJS(action.task)
          )
        );
    }

    case actionTypes.SAVE_TASK_NAME: {
      return state.setIn(['currentEditingTask', 'name'], fromJS(action.name));
    }

    case actionTypes.TASK_FORM_IS_CHANGED:
      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], true)
        .setIn(['tasks', 'items'], action.workflowId);

    case actionTypes.ADD_BLOCK_TO_TASK: {
      // Add the new empty block object to the task
      const newBlock = {
        id: hashUtils.createRandomId(),
        inputValue: {
          map: '',
          value: '',
        },
        type: action.blockType,
      };

      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], true)
        .updateIn(['currentEditingTask', 'frontend_blob', 'blocks'], (blocks) =>
          blocks.push(fromJS(newBlock))
        );
    }

    case actionTypes.CHANGE_TASK_NAME: {
      return state.setIn(['currentEditingTask', 'name'], action.taskName);
    }

    case actionTypes.REMOVE_BLOCK_FROM_TASK: {
      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], true)
        .updateIn(['currentEditingTask', 'frontend_blob', 'blocks'], (blocks) =>
          blocks.remove(action.blockIndex)
        );
    }

    case actionTypes.ADD_BLOCK_ATTR_OPTION: {
      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], true)
        .updateIn(['currentEditingTask', 'frontend_blob', 'blocks'], (blocks) =>
          blocks.update(
            blocks.findIndex((block) => block.get('id') === action.blockId),
            (block) =>
              block.update(action.blockAttrName, (val) => {
                if (!val) {
                  val = fromJS([]);
                }
                return val.push(fromJS(action.newOption));
              })
          )
        );
    }

    case actionTypes.REMOVE_BLOCK_ATTR_OPTION: {
      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], true)
        .updateIn(['currentEditingTask', 'frontend_blob', 'blocks'], (blocks) =>
          blocks.update(
            blocks.findIndex((block) => block.get('id') === action.blockId),
            (block) =>
              block.update(action.blockAttrName, (val) =>
                val.delete(action.optionIndex)
              )
          )
        );
    }

    case actionTypes.REARRANGE_BLOCKS: {
      return state
        .setIn(['saveStatus', 'taskFormIsChanged'], true)
        .updateIn(
          ['currentEditingTask', 'frontend_blob', 'blocks'],
          (blocks) => {
            const newOrder = fromJS(action.ordering);
            const newBlocks = newOrder.map((orderedBlockIndex) =>
              blocks.get(orderedBlockIndex)
            );
            return newBlocks;
          }
        );
    }

    case actionTypes.ADD_TASK_START: {
      return state
        .set('newTask', INITIAL_STATE.get('newTask'))
        .setIn(['newTask', 'isLoading'], true);
    }

    case actionTypes.ADD_TASK_SUCCESS:
      return state.set('newTask', INITIAL_STATE.get('newTask'));

    case actionTypes.ADD_TASK_FAILURE:
      return state
        .set('newTask', INITIAL_STATE.get('newTask'))
        .setIn(['newTask', 'error'], fromJS(action.error));

    case actionTypes.REMOVE_TASK_START:
      return state
        .set('removingTask', INITIAL_STATE.get('removingTask'))
        .setIn(['removingTask', 'isLoading'], true);

    case actionTypes.REMOVE_TASK_SUCCESS:
      return state.set('removingTask', INITIAL_STATE.get('removingTask'));

    case actionTypes.REMOVE_TASK_FAILURE:
      return state
        .set('removingTask', INITIAL_STATE.get('removingTask'))
        .setIn(['removingTask', 'error'], fromJS(action.error));

    case actionTypes.TOGGLE_PREVIEW:
      return state.setIn(
        ['tasks', 'showPreview'],
        !state.getIn(['tasks', 'showPreview'])
      );

    case '@@redux-form/UPDATE_SYNC_ERRORS': {
      if (action.meta.form.split('.')[0] === 'TaskEditor') {
        const hasErrors = Boolean(
          Object.keys(action.payload.syncErrors).length
        );
        return state.setIn(['saveStatus', 'taskFormHasErrors'], hasErrors);
      }
      return state;
    }

    case '@@redux-form/CHANGE': {
      if (action.meta.form.split('.')[0] === 'TaskEditor') {
        const [, blockID] = action.meta.form.split('.');
        // This gets the field path as an array representacion
        // e.g. "field[0].label" to ['field', 0, 'label']
        const fieldPath = action.meta.field
          .replace(/\[(\d+)\]/g, '.$1')
          .split('.');
        const value = action.payload;

        if (blockID === 'nav') {
          return state
            .setIn(['saveStatus', 'taskFormIsChanged'], true)
            .updateIn(['currentEditingTask', 'frontend_blob', 'nav'], (nav) =>
              nav.setIn(fieldPath, value)
            );
        }

        return state
          .setIn(['saveStatus', 'taskFormIsChanged'], true)
          .updateIn(
            ['currentEditingTask', 'frontend_blob', 'blocks'],
            (blocks) =>
              blocks.update(
                blocks.findIndex((block) => block.get('id') === blockID),
                (block) => block.setIn(fieldPath, value)
              )
          );
      }
      return state;
    }

    case actionTypes.SELECT_CURRENT_EDITING_TASK: {
      const { taskId } = action;

      let currentEditingTask = null;
      if (state.getIn(['tasks', 'items'])) {
        currentEditingTask = state
          .getIn(['tasks', 'items'])
          .find((task) => task.get('id') === taskId);
      }

      return state
        .set('selectedTaskId', taskId)
        .setIn(['currentEditingTask'], currentEditingTask);
    }

    case actionTypes.LOAD_OBJECT_MAPPING_SUCCESS: {
      return state.set('objectMappingList', fromJS(action.objectMappingList));
    }

    default:
      return state;
  }
};

export default editorReducer;
