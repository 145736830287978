const api_workflow_v0_1_object_mapping = {
  results: {
    app_label: 'authentication',
    attributes: {},
    filters: [],
    methods: [],
    model: 'espauthintegrationcredentialproxy',
    url:
      'https://release.qa.espressive.com/api/workflow/v0.1/object_mapping/272/',
    verbose_name: 'esp auth integration credential proxy',
  },
};
export default api_workflow_v0_1_object_mapping;
