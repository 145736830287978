import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

// Controller
import ExitWorkflow01Controller from '../../controllers/ExitWorkflow01Controller';
// Globals
import TypeExitWorkflow, {
  TypeDefaults,
} from '../../../globals/blocks/TypeExitWorkflow';

class ExitWorkflow01 extends Component {
  static propTypes = {
    ...TypeExitWorkflow,
    exitWorkflow: PropTypes.func,
    setAlternateSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    exitWorkflow: _.noop,
  };

  componentDidMount() {
    const { setAlternateSubmit } = this.props;
    setAlternateSubmit(this.handleClick);
  }

  handleClick = () => {
    const { exitWorkflow } = this.props;
    exitWorkflow();
  };

  render() {
    return <div className='hidden' />;
  }
}

export const ExitWorkflow01Test = ExitWorkflow01;

export default ExitWorkflow01Controller(ExitWorkflow01);
