import { createSelector } from 'reselect';
import Immutable from 'immutable';
import getBundleImages from './getBundleImages';

const selectOtherImages = (images) => {
  const mainImage =
    images.min((images) => images.get('ordering')) || Immutable.Map();

  return images
    .filter((image) => image.get('id') !== mainImage.get('id'))
    .sortBy((image) => image.get('ordering'))
    .slice(0, 2)
    .map((img, i) => img.set('key', img.get('id') + i));
};

const getOtherTwoImages = createSelector([getBundleImages], selectOtherImages);

export default getOtherTwoImages;
