const getParamsFromPaginationState = (paginationState) => {
  const { currentPage, perPage: limit } = paginationState;
  const offset = limit * currentPage - limit;

  return {
    limit,
    offset,
  };
};

export default getParamsFromPaginationState;
