import { fromJS } from 'immutable';
import forOwn from 'lodash/forOwn';

// Actions
import actionTypes from '../actions/actionTypes';

// Globals
import CaseStates from '../globals/CaseStates';
import FilterInputTypes from '../globals/FilterInputTypes';
import FilterTitles from '../globals/FilterTitles';
import FilterTypes from '../globals/FilterTypes';

const INITIAL_STATE = fromJS([
  {
    filters: [
      {
        key: FilterTypes.STATE,
        label: '',
        options: [
          {
            checked: true,
            text: 'label.pending_approval',
            value: CaseStates.PENDING_APPROVAL,
          },
          {
            text: 'label.approved',
            value: CaseStates.APPROVED,
          },
          {
            text: 'label.rejected',
            value: CaseStates.REJECTED,
          },
        ],
        type: FilterInputTypes.RADIO,
      },
    ],
    title: FilterTitles.SHOW,
  },
  {
    filters: [
      {
        key: FilterTypes.SORT_BY,
        options: [
          {
            checked: true,
            text: 'filter.created_new_old',
            value: '-sys_date_created',
          },
          {
            text: 'filter.created_old_new',
            value: 'sys_date_created',
          },
        ],
        type: FilterInputTypes.RADIO,
      },
    ],
    title: FilterTitles.SORT_BY,
  },
  {
    filters: [
      {
        key: FilterTypes.TIME_PERIOD,
        options: [
          {
            checked: true,
            text: 'label.all',
            value: 'all',
          },
          {
            text: 'filter.last_24_hours',
            value: 1000 * 60 * 60 * 24,
          },
          {
            text: 'filter.last_7_days',
            value: 1000 * 60 * 60 * 24 * 7,
          },
        ],
        type: FilterInputTypes.RADIO,
      },
    ],
    title: FilterTitles.TIME_PERIOD,
  },
]);

/**
 * case feed reducer
 * @param state
 * @param action
 * @returns {*}
 */
const casesFilterReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.APPROVALS_FILTER_UPDATE: {
      let currentFilters = state;
      // merges state of current filters with the filter options received in the action
      forOwn(action.approvalsFilters, (sectionFilters, sectionTitle) => {
        const sectionIndex = currentFilters.findIndex(
          (section) => section.get('title') === sectionTitle
        );
        if (sectionIndex > -1) {
          // finding current section
          // for each filter in the section, update its options
          forOwn(sectionFilters, (options, filterKey) => {
            const filterIndex = currentFilters
              .getIn([sectionIndex, 'filters'])
              .findIndex((filter) => filter.get('key') === filterKey);
            if (filterIndex > -1) {
              currentFilters = currentFilters.updateIn(
                [sectionIndex, 'filters', filterIndex, 'options'],
                (currentOptions) => currentOptions.mergeDeep(fromJS(options))
              );
            }
          });
        }
      });

      return currentFilters;
    }

    default: {
      return state;
    }
  }
};

export default casesFilterReducer;
