//
// index.js
//
// This file is autogenerated from 'gulpSVG' gulp file.
// Template for this is in '/templates/index.handlebars'
//
// Please don't modify it by hand.
//
// If you need to add a new SVG, add the svg file in the images folder
// then run the gulp script: gulp --gulpfile ./gulpSvg.js
//
//
import React, { PureComponent } from 'react';
import Expenses from './Expenses';
import Lederboard from './Lederboard';
import Recognition from './Recognition';
import ReferralPrograms from './ReferralPrograms';
import SalesWins from './SalesWins';
import Alert from './Alert';
import Facilities from './Facilities';
import FacilitiyOutage from './FacilitiyOutage';
import Food from './Food';
import Gym from './Gym';
import Hazard from './Hazard';
import Healthwellness from './Healthwellness';
import Hr from './Hr';
import Networkissue from './Networkissue';
import News from './News';
import NoCloud from './NoCloud';
import Parking from './Parking';
import PrinterOutage from './PrinterOutage';
import Security from './Security';
import ServiceOutage from './ServiceOutage';
import Wifi from './Wifi';

class Announcements extends PureComponent {
  render() {
    const styleError = { color: '#a00' };
    return <p style={styleError}>{'ERROR: Please import a specific image.'}</p>;
  }
}

Announcements.Expenses = Expenses;
Announcements.Lederboard = Lederboard;
Announcements.Recognition = Recognition;
Announcements.ReferralPrograms = ReferralPrograms;
Announcements.SalesWins = SalesWins;
Announcements.Alert = Alert;
Announcements.Facilities = Facilities;
Announcements.FacilitiyOutage = FacilitiyOutage;
Announcements.Food = Food;
Announcements.Gym = Gym;
Announcements.Hazard = Hazard;
Announcements.Healthwellness = Healthwellness;
Announcements.Hr = Hr;
Announcements.Networkissue = Networkissue;
Announcements.News = News;
Announcements.NoCloud = NoCloud;
Announcements.Parking = Parking;
Announcements.PrinterOutage = PrinterOutage;
Announcements.Security = Security;
Announcements.ServiceOutage = ServiceOutage;
Announcements.Wifi = Wifi;

export default Announcements;
