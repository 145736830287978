import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsItIncident extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0005',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M61.45 46.54c.006-2.03-.805-3.976-2.25-5.4l-.2-.2c-2.828-2.795-7.316-2.986-10.37-.44-3.054-2.546-7.542-2.355-10.37.44l-.19.19c-1.442 1.43-2.253 3.375-2.253 5.405 0 2.03.81 3.976 2.253 5.405l8.32 8.29c1.234 1.216 3.216 1.216 4.45 0l8.33-8.28c1.456-1.424 2.278-3.374 2.28-5.41zm-4.37 3.28l-8.33 8.28c-.062.058-.158.058-.22 0l-8.33-8.28c-.877-.866-1.37-2.047-1.37-3.28 0-1.233.493-2.414 1.37-3.28l.19-.18c1.836-1.82 4.794-1.82 6.63 0l1.63 1.62 1.63-1.62c1.835-1.812 4.785-1.812 6.62 0l.2.2c.874.866 1.366 2.045 1.366 3.275s-.492 2.41-1.366 3.275l-.02-.01z' />
          <path d='M72.12 38.62h-.27C65.39 20.7 50.2 21.45 49.08 21.53c-16.6 0-22.62 13.33-23.93 16.87h-.38c-4.44 1.06-7.78 5.74-7.78 11.36 0 6.4 4.34 11.58 9.69 11.58.64 0 1.16-.52 1.16-1.16V39.64c0-.467-.28-.89-.71-1.07 1.46-3.81 7-15 21.91-15h.1c2.823-.15 5.643.338 8.25 1.43 5.65 2.33 9.81 7 12.37 13.81-.462.154-.775.583-.78 1.07v20.29c0 .635.515 1.15 1.15 1.15h.37c-.715 4.627-4.583 8.116-9.26 8.35-.572-1.513-2.055-2.483-3.67-2.4-2.1 0-3.8 1.42-3.8 3.18 0 1.76 1.7 3.18 3.8 3.18 1.582.076 3.04-.854 3.64-2.32 5.688-.248 10.352-4.594 11-10.25 4.3-1.13 7.53-5.71 7.53-11.2 0-5.49-3.22-10.19-7.62-11.24z' />
        </g>
      </svg>
    );
  }
}

export default CardsItIncident;
