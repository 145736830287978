import buildCompositeController from '../../utils/buildCompositeController';

// Selectors
import getBaristaId from '../../selectors/getBaristaId';

const mapStateToProps = (state) => ({
  baristaID: getBaristaId(state),
});

export default buildCompositeController(mapStateToProps);
