import { createSelector } from 'reselect';

import getWiredLocationsByID from './getWiredLocationsByID';

const getUsersByID = (state) => state.getIn(['entities', 'users']);

const getJobRolesByID = (state) => state.getIn(['entities', 'jobRoles']);

const getWorkflowRequestedForID = (state) =>
  state.getIn(['workflowState', 'sessionId', 'userRequestedFor']);

const getWorkflowRequestedFor = (
  usersByID,
  jobRolesByID,
  wiredLocationsByID,
  workflowRequestedForID
) => {
  let workflowRequestedFor = usersByID.get(workflowRequestedForID, null);

  if (workflowRequestedFor) {
    // does this user have a mentor that is available?
    const mentorUrl = workflowRequestedFor.get('mentor', null);

    workflowRequestedFor = workflowRequestedFor.set('mentor_id', null);

    if (mentorUrl) {
      const mentorUrlParts = mentorUrl.split('/');
      const mentorID = Number(mentorUrlParts[mentorUrlParts.length - 2]);

      workflowRequestedFor = workflowRequestedFor.set('mentor_id', mentorID);
    }
    // does this user have a job role that is available?
    const jobRoleUrl = workflowRequestedFor.get('job_role', null);

    workflowRequestedFor = workflowRequestedFor.set('job_role_id', null);

    if (jobRoleUrl) {
      const jobRoleUrlParts = jobRoleUrl.split('/');
      const jobRoleID = Number(jobRoleUrlParts[jobRoleUrlParts.length - 2]);

      workflowRequestedFor = workflowRequestedFor.set('job_role_id', jobRoleID);
    }

    // does this user have a location that is available?
    const locationUrl = workflowRequestedFor.get('location', null);

    if (locationUrl) {
      const locationUrlParts = locationUrl.split('/');
      const locationID = Number(locationUrlParts[locationUrlParts.length - 2]);

      workflowRequestedFor = workflowRequestedFor.set(
        'location_id',
        locationID
      );
    }
  }

  return workflowRequestedFor;
};

const selector = createSelector(
  [
    getUsersByID,
    getJobRolesByID,
    getWiredLocationsByID,
    getWorkflowRequestedForID,
  ],
  getWorkflowRequestedFor
);

export default selector;
