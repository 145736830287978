import React, { useCallback } from 'react';
import pt from 'prop-types';
import { Role } from 'reakit/Role';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit/Menu';
import classNames from 'classnames/bind';
import styles from './Filter.module.scss';
import { Icon } from '@iconify/react';
import baselineArrowDropDown from '@iconify/icons-ic/baseline-arrow-drop-down';

import {
  FILTER_CONDITIONS,
  popperOverTrigger,
  popperSameWidth,
} from './__globals';

const cx = classNames.bind(styles);

const propTypes = {
  index: pt.number.isRequired,
  isAdvanced: pt.bool,
  setType: pt.func.isRequired,
  type: pt.oneOf([...Object.keys(FILTER_CONDITIONS)]),
};

const FilterCondition = ({ index, isAdvanced = false, setType, type }) => {
  const menuState = useMenuState({
    modal: true,
    // placement: 'bottom-end',
    unstable_popperModifiers: [popperOverTrigger, popperSameWidth],
  });

  const { hide } = menuState;

  const handleOptionSelect = useCallback(
    (e) => {
      setType(e.target.dataset.condition, index);
      hide();
    },
    [index, setType, hide]
  );

  const trigger = (
    <Role
      as={isAdvanced ? MenuButton : 'div'}
      className={cx({ Condition: true, advanced: isAdvanced })}
      state={isAdvanced ? menuState : undefined}
    >
      <span className={styles.Attribute}>{type}</span>
      <Icon icon={baselineArrowDropDown} inline />
    </Role>
  );

  return isAdvanced ? (
    <>
      {trigger}
      <Menu aria-label='condition' className={styles.Menu} state={menuState}>
        {Object.keys(FILTER_CONDITIONS)
          .reverse()
          .map((typeOption) => (
            <MenuItem
              className={cx('MenuItem', { active: typeOption === type })}
              data-condition={typeOption} // The condition type being selected
              key={typeOption}
              onClick={handleOptionSelect}
              state={menuState}
            >
              {typeOption}
            </MenuItem>
          ))}
      </Menu>
    </>
  ) : (
    trigger
  );
};

FilterCondition.propTypes = propTypes;

export default FilterCondition;
