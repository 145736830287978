import React from 'react';
import pt from 'prop-types';
import styles from './EspModalCloseButton.module.scss';
import { Button } from 'reakit';
import { intl } from 'esp-util-intl';

const propTypes = {
  text: pt.string,
};
const EspModalCloseButton = ({ text = 'label.exit', ...rest }) => (
  <Button className={styles._} {...rest} aria-label='Close'>
    {intl.formatMessage({
      id: text,
    })}
  </Button>
);

EspModalCloseButton.propTypes = propTypes;

export default EspModalCloseButton;
