const api_espuser_v0_1_users_15 = {
  about_me: 'Glad to be there',
  alias: 'rapwqwe555@acme.com',
  department: null,
  eid: '82aaea71-3652-48b3-b12e-f27d4b6fe783',
  email: 'pascalito.bo@tenant1.esp',
  first_name: 'pascalito',
  full_name: 'pascalito Boudierosa',
  groups: ['ADMIN'],
  id: 2027,
  images: null,
  is_active: true,
  is_admin: true,
  last_name: 'Boudierosa',
  manager: 'http://tenant1.esp/api/espuser/v0.1/users/3/',
  nickname: 'Roberto',
  phone_numbers: null,
  social_coordinates: [
    {
      network: 'linkedin',
    },
  ],
  social_profile_picture: '',
  title: 'frontend',
  url: 'http://tenant1.esp/api/espuser/v0.1/users/2027/',
  user_state: 'USER_CREATED',
  work_collaboration: null,
};

export default api_espuser_v0_1_users_15;
