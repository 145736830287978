import { createSelector } from 'reselect';

/**
 * Return the current selected performance for onboard activity mapped with Users
 */

const getPerformanceUsersList = (state) => {
  const performance = state.getIn(['onboardActivity', 'performance', 'list']);

  const newList = performance.map((perf) => {
    const newPerf = perf;
    const idusr = perf.get('user');
    const usr = state.getIn(['entities', 'users', idusr]);

    if (idusr && usr) {
      return newPerf.set('user', usr);
    }

    return null;
  });

  return newList.filter((item) => item);
};

const getPerformanceList = (performance) => performance;

const selector = createSelector([getPerformanceUsersList], getPerformanceList);

export default selector;
