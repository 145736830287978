// Actions - Thunks
import faqThunks from '../../actions/faqThunks';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const archetypeEID = state.getIn(
    [
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
      'selected_template',
      'template',
    ],
    null
  );
  return {
    archetypeEID,
    entitySynonymList: state.getIn(['adminFaqs', 'entitySynonymList']),
    isLoadingEntitySynonymList: state.getIn([
      'adminFaqs',
      'isLoadingEntitySynonymList',
    ]),
  };
};

// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  loadEntitySynonymValues(eid) {
    dispatch(faqThunks.searchEntitySynonymByArchetype(eid));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
