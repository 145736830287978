import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsPurchaseOrderComplete extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M22.38 72.58c2.69 0 4.87 2.18 4.87 4.87s-2.18 4.87-4.87 4.87-4.87-2.18-4.87-4.87 2.18-4.87 4.87-4.87zm0-3c-4.346 0-7.87 3.524-7.87 7.87s3.524 7.87 7.87 7.87 7.87-3.524 7.87-7.87c0-2.087-.83-4.09-2.305-5.565-1.476-1.476-3.478-2.305-5.565-2.305zm25.2 3c2.69 0 4.87 2.18 4.87 4.87s-2.18 4.87-4.87 4.87-4.87-2.18-4.87-4.87 2.18-4.87 4.87-4.87zm0-3c-4.346 0-7.87 3.524-7.87 7.87s3.524 7.87 7.87 7.87 7.87-3.524 7.87-7.87c0-2.087-.83-4.09-2.305-5.565-1.476-1.476-3.478-2.305-5.565-2.305zm-5.74-17.45c.018-5.846 2.922-11.307 7.76-14.59-.242-.86-1.026-1.457-1.92-1.46H24.93c-1.105 0-2 .895-2 2v18.9l-6.55-27.93c-.21-1.077-.993-1.952-2.04-2.283-1.045-.33-2.188-.065-2.98.694-.792.76-1.106 1.89-.82 2.95l7.34 31.46c.316 1.36 1.526 2.32 2.92 2.32h25.86c-3.09-3.253-4.815-7.57-4.82-12.06zm40.87-41.15H64.88c-1.312.002-2.47.857-2.86 2.11l-6.8 21.89c1.536-.414 3.12-.626 4.71-.63.59 0 1.17 0 1.75.09l5.41-17.46h15.62c1.072 0 2.062-.572 2.598-1.5s.536-2.072 0-3c-.536-.928-1.526-1.5-2.598-1.5z' />
          <path d='M59.05 66.48c-8.008 0-14.5-6.492-14.5-14.5s6.492-14.5 14.5-14.5 14.5 6.492 14.5 14.5c-.01 8.004-6.496 14.49-14.5 14.5zm0-26c-6.35 0-11.5 5.15-11.5 11.5s5.15 11.5 11.5 11.5 11.5-5.15 11.5-11.5c-.006-6.35-5.15-11.494-11.5-11.5z' />
          <path d='M57.72 57.4c-.39.002-.768-.148-1.05-.42l-3-2.91c-.404-.37-.574-.932-.443-1.464.13-.532.54-.95 1.07-1.09.53-.142 1.095.017 1.473.414l1.82 1.79 5.18-6c.357-.405.904-.59 1.434-.483.53.107.962.488 1.135 1 .17.512.054 1.078-.3 1.483l-6.19 7.16c-.27.312-.66.5-1.07.52h-.06z' />
        </g>
      </svg>
    );
  }
}

export default CardsPurchaseOrderComplete;
