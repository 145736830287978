import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Icon, Message } from 'semantic-ui-react';
import { createComponent } from 'react-fela';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
// Display
import EspModal from '../../display/EspModal';
// v1
import ConversationFeed from '../../../../v1/components/molecules/ConversationFeed';
import ChannelTypes from '../../../../v1/globals/ChannelTypes';
// Controller
import ConversationSelectController from '../../../../v1/components/controllers/ConversationSelectController';
import Conversation01 from '../../../../v1/components/blocks/conversation/Conversation01';

const rule = () => ({
  border: 'none',
  left: 0,
  position: 'absolute',
  top: 0,
});

let ConversationSelect = class ConversationSelect extends PureComponent {
  static propTypes = {
    baristaID: PropTypes.number,
    caseAuthor: PropTypes.number,
    channel: PropTypes.string,
    /** Fela classname */
    className: PropTypes.string.isRequired,
    /** For the semantic modal prop */
    defaultOpen: PropTypes.bool,
    /** Main header to appear at the top of the Esp Modal. */
    header: PropTypes.string,
    hideUserID: PropTypes.arrayOf(PropTypes.number),
    /** Userlist loading */
    isLoading: PropTypes.bool,
    loadMessages: PropTypes.func,
    messages: ImmutablePropTypes.list,
    /**
     * Called when the user attempts to change the user selected.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and proposed value.
     */
    onChange: PropTypes.func,
    /**
     * Called when a close event happens in Esp Modal.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    /** If modal is open */
    open: PropTypes.bool,
    taskId: PropTypes.number.isRequired,
    /** Trigger element which will open the Esp Modal. */
    trigger: PropTypes.element,
  };

  static defaultProps = {
    baristaID: null,
    caseAuthor: null,
    channel: null,
    defaultOpen: false,
    header: '',
    hideUserID: [],
    isLoading: false,
    loadMessages: _.noop,
    messages: fromJS([]),
    onChange: _.noop,
    onClose: _.noop,
    onOpen: _.noop,
    open: false,
    trigger: null,
  };

  state = {
    selected: [],
  };

  componentDidMount() {
    const { loadMessages, taskId } = this.props;

    loadMessages(ChannelTypes.SCOPED_CHANNEL, false, taskId);
  }

  setRef = (ref) => {
    this.refChatSelect = ref;
  };

  handleCheckboxClick = (id) => {
    const { selected } = this.state;
    const currentSelection = [...selected];
    const index = currentSelection.indexOf(id);

    if (currentSelection.indexOf(id) > -1) {
      // We clicked on a already selected chat, we have to unselected it;
      currentSelection.splice(index, 1);
    } else {
      // Add this new selection
      currentSelection.push(id);
    }

    this.setState({
      selected: currentSelection,
    });
  };

  handleOnSelect = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { onChange } = this.props;
    const { selected } = this.state;
    onChange(selected);
    this.refChatSelect.close();
  };

  handleOnOpen = () => {
    const { onOpen } = this.props;

    this.setState(
      {
        selected: [],
      },
      () => {
        onOpen();
      }
    );
  };

  render() {
    const {
      baristaID,
      caseAuthor,
      channel,
      className,
      header,
      hideUserID,
      messages,
      onClose,
      open,
      trigger,
    } = this.props;

    const { selected } = this.state;

    const actions = (
      <Button
        content='Select'
        disabled={!selected.length}
        onClick={this.handleOnSelect}
      />
    );

    const conversationFinal = {
      id: '2429f4msg',
      messages: [
        {
          id: '000',
          text:
            'Below you will find all of the responses from the agent for this request',
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    const preScrollContent = (
      <div
        style={{
          padding: '0 1em 1.5em',
        }}
      >
        <Conversation01 {...conversationFinal} />
      </div>
    );

    const realMsgLength = messages.filter((comment) => {
      if (
        !comment.get('user_id') ||
        comment.get('user_id') === baristaID ||
        caseAuthor === comment.get('user_id')
      ) {
        return false;
      }
      return true;
    });

    const content = realMsgLength.isEmpty() ? (
      <Message info size='tiny'>
        {intl.formatMessage({
          id: 'message.no_msg_to_select',
        })}
      </Message>
    ) : (
      <ConversationFeed
        channelId={channel}
        hideBarista
        hideUserID={[caseAuthor, ...hideUserID]}
        messages={messages}
        messagesChecked={[...selected]}
        onCheckboxClick={this.handleCheckboxClick}
      />
    );

    return (
      <EspModal
        actions={actions}
        className={className}
        closeIcon={<Icon name='close' />}
        content={content}
        contentPadding='0 1em 1em'
        header={header || 'Select a message'}
        onClose={onClose}
        onOpen={this.handleOnOpen}
        open={open}
        preScrollContent={preScrollContent}
        preScrollContentHeight={107}
        ref={this.setRef}
        trigger={trigger}
      />
    );
  }
};
const ConversationSelectTest = ConversationSelect;

ConversationSelect = ConversationSelectController(ConversationSelect);
export { ConversationSelectTest };
export default createComponent(rule, ConversationSelect, [
  'baristaID',
  'caseAuthor',
  'channel',
  'defaultOpen',
  'header',
  'isLoading',
  'onChange',
  'onClose',
  'open',
  'taskId',
  'trigger',
]);
