import { createSelector } from 'reselect';

const getCondition = (state) =>
  state.getIn(['editor', 'tasks', 'conditionSet']);

const getConditionSet = (condition) => {
  if (!condition || condition.isEmpty()) {
    return null;
  }
  let newConditionEntry = condition.get('condition_entry');

  newConditionEntry = newConditionEntry.map((cond) => {
    let obj = cond.get('object_instances');
    if (obj && !obj.isEmpty()) {
      obj = obj.toList();

      return cond.set('object_instances', obj);
    }

    return cond;
  });

  return condition.set('condition_entry', newConditionEntry);
};

const selector = createSelector([getCondition], getConditionSet);

export default selector;
