import PropTypes from 'prop-types';

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const propTypes = {
  progress: ImmutablePropTypes.mapContains({
    max_steps: PropTypes.number,
    steps_completed: PropTypes.number,
  }).isRequired,
};
const EspProgress02 = ({ progress }) => {
  // We receive the steps that we have completed
  // So our current steps is completed + 1

  const current_step = progress?.get('steps_completed') + 1;

  const max_steps = progress?.get('max_steps');

  let pw = (current_step / max_steps) * 100;
  pw = isNaN(pw) ? 100 : pw;

  return (
    <div className='esp-progress bar'>
      <div
        className='progress'
        style={{
          width: `${pw}%`,
        }}
      />
    </div>
  );
};

EspProgress02.propTypes = propTypes;
export default EspProgress02;
