import { change, getFormValues } from 'redux-form/immutable';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';

// Selectors
import getSelectedDirectoryUser from '../../selectors/getSelectedDirectoryUser';
import getCurrentUser from '../../selectors/getCurrentUser';

// Thunk
import directoryThunks from '../../actions/directoryThunks';

const mapStateToProps = (state, ownProps) => {
  let selectedUser = getCurrentUser(state);

  const userRequestedFor = state.getIn([
    'workflowState',
    'sessionId',
    'userRequestedFor',
  ]);
  const setEntryDone = state.getIn([
    'workflowState',
    'sessionId',
    'setEntryDone',
  ]);

  if (userRequestedFor !== selectedUser.get('id')) {
    selectedUser = getSelectedDirectoryUser(state);
  }

  let modalCommentText = null;
  const isLoading = state.getIn(['workflowState', 'loading']);
  const messageApproved = state.getIn([
    'form',
    'Form01',
    'initial',
    `${ownProps.id}-2`,
  ]);

  const textAreaValue = {};
  textAreaValue[`${ownProps.id}-0`] = '';
  textAreaValue[`${ownProps.id}-1`] = '';

  const formValues = getFormValues('Form01')(state);
  if (formValues) {
    // Revise Comment value
    textAreaValue[`${ownProps.id}-0`] =
      formValues.get(`${ownProps.id}-0`) || '';
    if (textAreaValue[`${ownProps.id}-0`]) {
      textAreaValue[`${ownProps.id}-0`] = textAreaValue[
        `${ownProps.id}-0`
      ].replace(/^\s+/g, ''); // Trim the value only on the left side
    }

    // Edit comment value
    textAreaValue[`${ownProps.id}-1`] =
      formValues.get(`${ownProps.id}-1`) || '';
    if (textAreaValue[`${ownProps.id}-1`]) {
      textAreaValue[`${ownProps.id}-1`] = textAreaValue[
        `${ownProps.id}-1`
      ].replace(/^\s+/g, ''); // Trim the value only on the left side
    }

    modalCommentText = formValues.get(`${ownProps.id}-1`);
  }

  return {
    isLoading,
    messageApproved,
    modalCommentText,
    selectedUser,
    setEntryDone,
    textAreaValue,
    userRequestedFor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeMessage(value, id) {
    dispatch(change('Form01', id, value)); // Update message_approved field
  },

  selectUser(id) {
    dispatch(directoryThunks.selectDirectoryUser(id));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
