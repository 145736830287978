import actionTypes from './actionTypes';

const reactRouterActions = {};

reactRouterActions.sync = (locationKey) => ({
  locationKey,
  type: actionTypes.SYNC_REACT_ROUTER_REDUX,
});

export default reactRouterActions;
