import baristaActions from '../../actions/baristaActions';
import catalogThunks from '../../actions/catalogThunks';
import buildCompositeController from '../../utils/buildCompositeController';
import { openExternalUrlInNative } from '../../../v2/utils/externalDownloadHandler';

const mapStateToProps = (state) => ({
  addingCatalogItemID: state.getIn(['cart', 'addingCatalogItemID']),
  removeCatalogItemID: state.getIn(['cart', 'removeCatalogItemID']),
});

const mapDispatchToProps = (dispatch) => ({
  loadProduct(productID) {
    const forceReload = true;
    dispatch(catalogThunks.loadOneProduct(productID, forceReload));
  },

  openBaristaChat(product) {
    if (product.get('target_intent')) {
      dispatch(
        baristaActions.openSupportChannel(
          null,
          '',
          false,
          product.get('target_intent')
        )
      );
    } else if (product.get('barista_url')) {
      openExternalUrlInNative(product.get('barista_url'));
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
