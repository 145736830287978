import {
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import APIcall from '../utils/APIcall';

import endpointGenerator from '../utils/endpointGenerator';
import EspFilters from 'esp-util-filters';

// Actions
import actionTypes from '../actions/actionTypes';
import baristaActions from '../actions/baristaActions';
import faqActions from '../actions/faqActions';

/**
 * Loads the FAQ list from API
 *
 * @param {Observable} action$ A stream of actions coming from Redux store
 * @param {Observable} state$ A stream of state updates, it gives you the latest state
 */
export const loadFaqList = (action$, state$) =>
  //
  // Since action$ is an observable, we can use pipe to process all
  // actions that are dispatched to the store
  action$.pipe(
    //
    // Just as Reducers, epics receive all actions by default, this filters out
    // unwanted actions and processes only the one(s) we pass
    ofType(actionTypes.LOAD_FAQ_LIST), // to process multiple actions: ofType([actionTypes.LOAD_FAQ_LIST, actionTypes.FAQ_SEARCH])

    // we debounce the stream for 500 miliseconds
    debounceTime(300),

    //
    // Includes the latest state
    //
    // @param {Obserbable} state$ The state observable passed to this epic
    withLatestFrom(state$),

    //
    // here, we switch to a new observable, effectivelly canceling the previous (no memory leaks).
    // Also, if we are hitting an endpoint, the previous call will be canceled, hence no race conditions.
    //
    // @param {Object} action The action object, note we are destructuring the payload
    // @param {Object} state The current state
    switchMap(([{ payload }, state]) => {
      const {
        applicationEID,
        categoryID,
        departmentID,
        isTopic = false,
        query,
      } = payload;

      /** DEV-11953: section scope */
      const successAction = isTopic
        ? baristaActions.faqsTopicListSuccess
        : faqActions.loadFaqListSuccess;
      const failAction = isTopic
        ? baristaActions.faqsTopicListFail
        : faqActions.loadFaqListFailure;

      let task_category_type;
      if (categoryID && categoryID !== '0') {
        const categoryList = state.getIn(['cases', 'taskCategoryResults']);
        // Find the category selected
        const categorySelected = categoryList.filter(
          (cat) => cat.get('eid') === categoryID
        );
        if (!categorySelected.isEmpty()) {
          task_category_type = categorySelected.getIn([
            0,
            'classification_name',
          ]);
        }
      }

      let service_department_type;
      if (departmentID && !isNaN(departmentID)) {
        // Get the service department name
        const myServiceTeams = state.getIn(['caseMgmt', 'myServiceTeams']);

        if (!myServiceTeams || myServiceTeams.isEmpty()) {
          return of(failAction(new Error('No Service Teams')));
        } else {
          const myDepartment = myServiceTeams.find(
            (team) =>
              Number(team.getIn(['service_department', 'id'])) ===
              Number(departmentID)
          );

          service_department_type =
            myDepartment &&
            myDepartment.getIn(
              ['service_department', 'service_department'],
              ''
            );
        }
      }

      if (task_category_type) {
        query.attributes = `task_category_type=${task_category_type}`;
      }

      // DEV-12342: disable date sort when there is an active search
      const thereIsAnActiveSearch =
        query.searchTerm && query.searchTerm.length > 0;
      if (!thereIsAnActiveSearch) {
        query.esp_order_by = query.orderBy || '-sys_date_updated';
        delete query.orderBy;
      }

      // Check if it's a search
      if (query.searchTerm && !isTopic) {
        query.input_text = query.searchTerm;
        delete query.searchTerm;
      }

      if (service_department_type) {
        query.attributes = query.attributes
          ? `${query.attributes}&service_department_type=${service_department_type}`
          : `service_department_type=${service_department_type}`;
      }

      if (query.expiration) {
        switch (query.expiration) {
          case 'Expiring in 7 days':
            query.expires_in = 7;
            break;
          case 'Expiring in 30 days':
            query.expires_in = 30;
            break;
          case 'Expired':
            query.expired = true;
            break;
          default:
            break;
        }

        delete query.expiration;
      }

      // Filter archetype type
      query.is_archetype = 'False';

      // Build the esp filter
      const filter = new EspFilters();
      if (query.display) {
        switch (query.display) {
          case 'active': {
            filter.isTrue('active');
            break;
          }

          case 'inactive': {
            filter.isFalse('active');
            break;
          }
          default:
            break;
        }

        delete query.display;
      }

      if (query.reviewed) {
        switch (query.reviewed) {
          case 'true': {
            filter.isTrue('is_reviewed');
            break;
          }

          case 'false': {
            filter.isFalse('is_reviewed');
            break;
          }
          default:
            break;
        }

        delete query.reviewed;
      }

      if (query.source) {
        switch (query.source) {
          case 'elc': {
            filter.isTrue('is_protected');
            break;
          }

          case 'customer': {
            filter.isFalse('is_protected');
            break;
          }
          default:
            break;
        }

        delete query.source;
      }

      if (applicationEID) {
        filter.equalTo('application', applicationEID);
      }

      if (filter.getQuerySize()) {
        query.esp_filters = filter.asQueryString();
      }

      // create a new observable straight from a promise, this is our real API call
      return from(
        APIcall.get({
          query,
          token: true,
          url: endpointGenerator.genPath(
            'espBarista.faqs.general.intentLookup'
          ),
        })

        // next, we pipe again to continue chaining
      ).pipe(
        // we map each API response to an action, in this case success
        map((response) =>
          successAction(response.body.results, response.body.count)
        ),

        // we catch the error in the case of unsuccessful API call
        catchError((err) => of(failAction(err)))
      );
    })
  );
