import { createSelector } from 'reselect';
import Immutable from 'immutable';
import buildConditionHeader from '../utils/buildConditionHeader';

const getLocDeptRole = (state) =>
  state.getIn([
    'form',
    'Form01',
    'values',
    'editLocDeptRole',
    '0',
    'locationdeptrole',
  ]);

/**
 * Returns the condition of a FAQ answer
 */
const getLocationDeptJobroleFAQ = (locationdeptrole = Immutable.Map()) =>
  buildConditionHeader(locationdeptrole);

const selector = createSelector([getLocDeptRole], getLocationDeptJobroleFAQ);

export default selector;
