import React /*, { useCallback }*/ from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AnnouncementSection from '../AnnouncementSection';
import Immutable from 'immutable';

const propTypes = {
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  slackChannels: ImmutablePropTypes.list,
  slackChannelsOptions: ImmutablePropTypes.list,
};

const SlackAudienceSection = ({
  onChange,
  slackChannelsOptions = Immutable.List(),
  readOnly,
  slackChannels = Immutable.List(),
}) => {
  const getChannelsNamesFromIDs = () => {
    return Array.from(
      slackChannels.reduce((options, channelID) => {
        const channel = slackChannelsOptions.find(
          (item) => channelID === item.get('value')
        );
        if (channel) {
          options.add(channel?.get('text'));
        }

        return options;
      }, new Set())
    );
  };

  if (readOnly) {
    return (
      <Form.Field>
        <label aria-label={'slack_channels'}>
          {intl.formatMessage({
            id: 'label.selected_slack_channels',
          })}
        </label>

        <div data-testid='slack_channels_values'>
          {getChannelsNamesFromIDs().join(', ')}
        </div>
      </Form.Field>
    );
  }

  return (
    <AnnouncementSection>
      <Form.Field>
        <label htmlFor={'slack_channels'}>
          {intl.formatMessage({
            id: 'label.available_slack_channels',
          })}
        </label>
        <Dropdown
          disabled={readOnly}
          id='slack_channels'
          multiple
          onChange={onChange}
          options={slackChannelsOptions.toJS()}
          placeholder={intl.formatMessage({
            id: 'label.available_slack_channels_placeholder',
          })}
          search
          selection
          value={slackChannels.toArray()}
        />
        <div>
          <em>
            <small>
              {intl.formatMessage({
                id: 'label.available_slack_channels_description',
              })}
            </small>
          </em>
        </div>
      </Form.Field>
    </AnnouncementSection>
  );
};

SlackAudienceSection.propTypes = propTypes;

export default SlackAudienceSection;
