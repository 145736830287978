import actionTypes from './actionTypes';

const compatibilityCheckActions = {
  compatibilityCheckError: (error) => ({
    error,
    type: actionTypes.COMPATIBILITY_CHECK_ERROR,
  }),
  compatibilityCheckStart: () => ({
    type: actionTypes.COMPATIBILITY_CHECK_START,
  }),
  compatibilityCheckSuccess: ({ compatible, clients, backend }) => ({
    backend,
    clients,
    compatible,
    type: actionTypes.COMPATIBILITY_CHECK_SUCCESS,
  }),
};

export default compatibilityCheckActions;
