import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { curry, isNumber, memoize, noop } from 'lodash';

import SelectMyGearProductFamilyItem from '../atoms/SelectMyGearProductFamilyItem';
import SelectMyGearProductItem from '../atoms/SelectMyGearProductItem';
import SelectMyGearList from './SelectMyGearList';
import SelectMyGearStepTypes from '../../globals/SelectMyGearStepTypes';

class SelectMyGearBundleOneStep extends PureComponent {
  static propTypes = {
    onAddProduct: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    step: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    onAddProduct: noop,
    onRemoveProduct: noop,
  };

  // Scans all items in this bundle to remove selected one (if any) from selection
  removeSelectedProduct = () => {
    const { step, onRemoveProduct } = this.props;

    let selectedProductID = null;

    const items = step.get('items');

    items.forEach((item) => {
      if (
        item.get('type') === SelectMyGearStepTypes.PRODUCT &&
        item.get('isSelected')
      ) {
        const selectedProduct = item.get('entity');
        selectedProductID = selectedProduct.get('id');
      }

      if (item.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
        const familyItems = item.get('items');

        familyItems.forEach((familyItem) => {
          if (familyItem.get('isSelected')) {
            const selectedFamilyProduct = familyItem.get('entity');
            selectedProductID = selectedFamilyProduct.get('id');
          }
        });
      }
    });

    if (selectedProductID) {
      onRemoveProduct(selectedProductID);
    }
  };

  handleToggleSelectProduct = memoize(
    curry((childIndex, productID, isSelected) => {
      const { onAddProduct } = this.props;

      if (isSelected) {
        this.removeSelectedProduct();

        // use position in bundle as relative weight
        onAddProduct(productID, childIndex);
      }
    })
  );

  handleSelectProductFromFamily = memoize(
    curry((childIndex, selectedProductID, newSelectedProductID) => {
      const { onAddProduct } = this.props;

      if (isNumber(newSelectedProductID)) {
        this.removeSelectedProduct();

        // use position in bundle as relative weight
        onAddProduct(newSelectedProductID, childIndex);
      }
    })
  );

  render() {
    const { step } = this.props;

    const items = step.get('items');

    return (
      <SelectMyGearList>
        {items.map((item, childIndex) => {
          if (item.get('type') === SelectMyGearStepTypes.PRODUCT) {
            const product = item.get('entity');

            return (
              <SelectMyGearProductItem
                key={product.get('id')}
                onToggleSelect={this.handleToggleSelectProduct(childIndex)}
                step={item}
              />
            );
          } else {
            // has to be a product family
            const product = item.get('representativeEntity');

            return (
              <SelectMyGearProductFamilyItem
                key={product.get('id')}
                onSelect={this.handleSelectProductFromFamily(childIndex)}
                step={item}
                withCheckbox
              />
            );
          }
        })}
      </SelectMyGearList>
    );
  }
}

export default SelectMyGearBundleOneStep;
