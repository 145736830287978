import Immutable from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
  locationKey: null,
});

const reactRouterReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SYNC_REACT_ROUTER_REDUX:
      return state.set('locationKey', action.locationKey);

    default:
      return state;
  }
};

export default reactRouterReducer;
