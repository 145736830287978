import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsPrinterOutage extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(4 4)'>
          <circle cx='57.61' cy='47.27' r='2.68' />
          <path d='M68.6 44.4c-.076-4.023-3.357-7.245-7.38-7.25h-.79v-9.14l-3 3.81v5.31h-4.19l-2.38 3h10.36c2.417.005 4.375 1.963 4.38 4.38-.007.08-.007.16 0 .24l2.33 14.38c-.065 2.374-2.005 4.265-4.38 4.27h-.42l-1.37-9.7H40.17l-2.36 3h21.35l1.93 13.66h-34l-2.36 3h39.81l-1-7c4.074-.006 7.374-3.306 7.38-7.38.007-.08.007-.16 0-.24L68.6 44.4zm-41.49-7.26h-.28c-4.023.005-7.304 3.227-7.38 7.25l-2.38 14.4c-.007.08-.007.16 0 .24.005 3.008 1.837 5.712 4.63 6.83l2-2.53c-2.048-.36-3.56-2.11-3.62-4.19l2.33-14.38c.007-.08.007-.16 0-.24.003-1.168.47-2.286 1.3-3.108.83-.822 1.952-1.28 3.12-1.272h15.12l2.38-3H30.11V16.81h27.31v3.7l3-3.81v-2.89H27.11v23.33z' />
          <path d='M25.3 61.27l5.96-7.56H26.9' />
          <path d='M44 .95c-24.3 0-44 19.7-44 44s19.7 44 44 44 44-19.7 44-44-19.7-44-44-44zm-39 44c.027-14.436 8.027-27.677 20.793-34.416C38.56 3.794 54.005 4.66 65.94 12.78c-.096.072-.184.156-.26.25l-47.92 60.8C9.617 66.44 4.98 55.947 5 44.95zm39 39c-8.677.008-17.106-2.893-23.94-8.24l48-60.87c.093-.118.167-.25.22-.39 12.96 10.328 17.978 27.718 12.513 43.362C75.327 73.457 60.573 83.94 44 83.95z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsPrinterOutage;
