import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsEtaUrgent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M44.73 13.25h7.57v2.16h-7.57z' />
          <path d='M48.13 4c-24.3 0-44 19.7-44 44s19.7 44 44 44 44-19.7 44-44-19.7-44-44-44zm27.46 44.51c.004 15.1-12.128 27.402-27.228 27.606-15.1.203-27.56-11.766-27.962-26.862-.403-15.095 11.4-27.712 26.49-28.314v-2.29h-3.78c-.437.003-.856-.17-1.163-.48-.307-.312-.475-.733-.467-1.17v-5.38c0-.43.172-.845.478-1.15.306-.303.72-.473 1.152-.47h10.81c.895 0 1.62.725 1.62 1.62V17c0 .895-.725 1.62-1.62 1.62h-3.78V21c5.855.445 11.414 2.755 15.86 6.59l4-4.07-3.18-3.18L69.14 18l8.65 8.65L75.5 29l-3.18-3.18-4 4c4.69 5.092 7.287 11.767 7.27 18.69z' />
          <path d='M48.13 25.24c-12.852 0-23.27 10.418-23.27 23.27s10.418 23.27 23.27 23.27S71.4 61.362 71.4 48.51c-.017-12.845-10.425-23.253-23.27-23.27zm0 28.49c-1.95-.005-3.655-1.32-4.155-3.208-.5-1.886.332-3.872 2.025-4.842V35.34c0-1.196.97-2.165 2.165-2.165 1.196 0 2.165.97 2.165 2.165v10.34c1.698.973 2.528 2.967 2.02 4.857-.506 1.89-2.223 3.2-4.18 3.193h-.04z' />
        </g>
      </svg>
    );
  }
}

export default CardsEtaUrgent;
