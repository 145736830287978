import APIcall from '../../../../utils/APIcall';
import endpointGenerator from '../../../../utils/endpointGenerator';

const fetchSlackChannels = () => {
  // TODO to be used when api it's ready
  const url = endpointGenerator.genPath(
    'espBaristaBot.integrations.slackChannels'
  );
  return APIcall.get({
    preventShowError: true,
    token: true,
    url,
  });
};

export default fetchSlackChannels;
