import actionTypes from './actionTypes';

const directoryActions = {};

directoryActions.getDirectoryUsersStart = (noResetList) => ({
  noResetList,
  type: actionTypes.GET_DIRECTORY_USERS_START,
});

directoryActions.getDirectoryUsersSuccess = (
  users,
  totalCount,
  pagination,
  isScrollToActiveUserRequired = false
) => ({
  isScrollToActiveUserRequired,
  pagination,
  totalCount,
  type: actionTypes.GET_DIRECTORY_USERS_SUCCESS,
  users,
});

directoryActions.getDirectoryUsersFailure = (error) => ({
  error,
  type: actionTypes.GET_DIRECTORY_USERS_FAILURE,
});

directoryActions.setSortUsersBy = (sortBy) => ({
  sortBy,
  type: actionTypes.SET_SORT_USERS_BY,
});

directoryActions.setFilterUsersBy = (filterBy) => ({
  filterBy,
  type: actionTypes.SET_FILTER_USERS_BY,
});

directoryActions.setAlphabetLetter = (alphabetLetter) => ({
  alphabetLetter,
  type: actionTypes.SET_ALPHABET_LETTER,
});

directoryActions.selectDirectoryUserStart = () => ({
  type: actionTypes.SELECT_DIRECTORY_USER_START,
});

directoryActions.selectDirectoryUserSuccess = (user, manager = null) => ({
  manager,
  type: actionTypes.SELECT_DIRECTORY_USER_SUCCESS,
  user,
});

directoryActions.noSelectDirectoryUserSuccess = (user, manager = null) => ({
  manager,
  type: actionTypes.NO_SELECT_DIRECTORY_USER_SUCCESS,
  user,
});

directoryActions.selectDirectoryUserFailure = (error) => ({
  error,
  type: actionTypes.SELECT_DIRECTORY_USER_FAILURE,
});

directoryActions.getPrevDirectoryUsersStart = () => ({
  type: actionTypes.GET_PREV_DIRECTORY_USERS_START,
});

directoryActions.getPrevDirectoryUsersSuccess = (users, prevUrl) => ({
  prevUrl,
  type: actionTypes.GET_PREV_DIRECTORY_USERS_SUCCESS,
  users,
});

directoryActions.getPrevDirectoryUsersFailure = (error) => ({
  error,
  type: actionTypes.GET_PREV_DIRECTORY_USERS_FAILURE,
});

directoryActions.prependScrollFixed = () => ({
  type: actionTypes.DIRECTORY_PREPEND_SCROLL_FIXED,
});

directoryActions.scrolledToActiveUser = () => ({
  type: actionTypes.DIRECTORY_SCROLLED_TO_ACTIVE_USER,
});

directoryActions.scrollToDividerRequired = () => ({
  type: actionTypes.DIRECTORY_SCROLL_TO_DIVIDER_REQUIRED,
});

directoryActions.scrolledToDivider = () => ({
  type: actionTypes.DIRECTORY_SCROLLED_TO_DIVIDER,
});

directoryActions.getNextDirectoryUsersStart = () => ({
  type: actionTypes.GET_NEXT_DIRECTORY_USERS_START,
});

directoryActions.getNextDirectoryUsersSuccess = (users, nextUrl) => ({
  nextUrl,
  type: actionTypes.GET_NEXT_DIRECTORY_USERS_SUCCESS,
  users,
});

directoryActions.getNextDirectoryUsersFailure = (error) => ({
  error,
  type: actionTypes.GET_NEXT_DIRECTORY_USERS_FAILURE,
});

directoryActions.resetDirectoryOptions = () => ({
  type: actionTypes.RESET_DIRECTORY_OPTIONS,
});

directoryActions.openAddEmployeeWorkflowStart = () => ({
  type: actionTypes.OPEN_ADD_EMPLOYEE_WORKFLOW_START,
});

directoryActions.openAddEmployeeWorkflowSuccess = () => ({
  type: actionTypes.OPEN_ADD_EMPLOYEE_WORKFLOW_SUCCESS,
});

directoryActions.openAddEmployeeWorkflowFail = () => ({
  type: actionTypes.OPEN_ADD_EMPLOYEE_WORKFLOW_FAIL,
});

export default directoryActions;
