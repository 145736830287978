import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import { fromJS } from 'immutable';
import { curry, last, memoize, split } from 'lodash';
// Atoms
import CSVReportModal from '../../../../atoms/CSVReportModal';
// import ExternalLink        from '../../../../atoms/ExternalLink';
// Utils
import dateFormat from '../../../../../utils/dateFormat';

/**
 * This is a renderer component to improve the performance of this part of the table
 * used by IntegrationCSVHistory
 */
class CSVTableBody extends PureComponent {
  static propTypes = {
    cancelParcing: PropTypes.func.isRequired,
    importHistoryResults: ImmutablePropTypes.list,
    isLoadingCancelParsing: PropTypes.bool,
    isLoadingImportHistory: PropTypes.bool,
  };

  static defaultProps = {
    importHistoryResults: fromJS([]),
    isLoadingCancelParsing: false,
    isLoadingImportHistory: false,
  };

  handleCancel = memoize(
    curry((id, e) => {
      const { cancelParcing } = this.props;
      cancelParcing(id);
    })
  );

  render() {
    const {
      importHistoryResults,
      isLoadingCancelParsing,
      isLoadingImportHistory,
    } = this.props;

    const fileNameCell = (csv_file) => {
      let fileName = '';
      if (csv_file) {
        fileName = last(split(csv_file, '/'));
      }

      if (csv_file) {
        return <Table.Cell>{fileName}</Table.Cell>;
      }
      return <Table.Cell content={fileName} />;
    };

    const parsedStatusCell = ({
      id,
      parse_status,
      reason,
      parsed,
      parse_json_response,
    }) => {
      const successProcessing =
        parse_json_response && parse_json_response.status === 200;

      const statusColumn = successProcessing
        ? parse_status
        : reason
        ? reason && reason[0]
        : parse_status;

      if (parsed) {
        return (
          <Table.Cell
            collapsing
            content={statusColumn}
            icon={
              <CSVReportModal
                disposable={{
                  message: 'Close',
                }}
                instanceId={id}
                negative={!successProcessing}
                title={
                  successProcessing
                    ? 'Parse report'
                    : statusColumn || 'Error report'
                }
              />
            }
          />
        );
      } else if (!parsed && parse_status === 'IN_PROGRESS') {
        return (
          <Table.Cell>
            {statusColumn}
            <Button
              basic
              content={'CANCEL'}
              floated='right'
              loading={isLoadingCancelParsing}
              onClick={this.handleCancel(id)}
            />
          </Table.Cell>
        );
      } else if (!parsed) {
        return <Table.Cell>{statusColumn}</Table.Cell>;
      }
      return null;
    };

    return (
      <Table.Body>
        {isLoadingImportHistory ? (
          <Table.Row>
            <Dimmer.Dimmable as={Table.Cell} colSpan={3} dimmed height={'48px'}>
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            </Dimmer.Dimmable>
          </Table.Row>
        ) : (
          importHistoryResults &&
          importHistoryResults.map((result) => {
            const resultJS = result.toJS();
            const {
              csv_file,
              id,
              is_scheduled,
              parse_json_response,
              parsed,
              sys_created_by,
              sys_date_created,
            } = resultJS;

            const successProcessing =
              parse_json_response && parse_json_response.status === 200;

            return (
              <Table.Row error={!successProcessing && parsed} key={id}>
                {fileNameCell(csv_file, parse_json_response)}
                {parsedStatusCell(resultJS)}
                <Table.Cell>{sys_created_by}</Table.Cell>
                <Table.Cell>{is_scheduled ? 'true' : 'false'}</Table.Cell>
                <Table.Cell singleLine>
                  {dateFormat(sys_date_created, true)}
                </Table.Cell>
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    );
  }
}

export default CSVTableBody;
