import { find, first, split } from 'lodash';
import i18Locales from '../../i18Locales';
import flattenMessages from '../utils/flattenMessages';

const getIntlMessages = (localeToJSON, locale) => {
  // Will pick the locale language based on this priority:
  // 1. formatted locale match
  // 2. language family. e.g. "en-GB" will default to the only locale of the "en" family we have, which is "en-US" (DEPRECATED in most cases)
  // 3. en-US by default if the first two options didn't match results

  const formattedLocale = locale.replace('-', '_').toLowerCase();

  const messages =
    localeToJSON[formattedLocale] ||
    find(
      localeToJSON,
      (json, localeKey) =>
        first(split(localeKey, '-')) === first(split(locale, '-'))
    ) ||
    localeToJSON[i18Locales.en_us];

  return flattenMessages(messages);
};

export default getIntlMessages;
