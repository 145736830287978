import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareBusiness extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M20.51 58.96c.232.13.506.16.76.087.256-.073.47-.245.6-.477l9.4-16.88 8.4 7.5c.276.237.66.306 1 .18l7.76-3.14 9.31 6.21c.322.2.728.2 1.05 0l7.78-4.48 11.51 6c.473.18 1.007-.023 1.24-.472.236-.448.098-1.002-.32-1.288l-12-6.25c-.31-.18-.69-.18-1 0l-7.71 4.44-9.23-6.16c-.274-.185-.623-.223-.93-.1l-7.67 3.1-8.87-7.92c-.227-.202-.533-.29-.832-.24-.3.05-.56.234-.708.5l-10 18c-.134.243-.16.532-.073.795.088.264.28.48.533.595zm9.07 10.31v-.21.21z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.66c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.538 15 25.334 15 23.85c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareBusiness;
