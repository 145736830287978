import React, { useCallback, useEffect, useState } from 'react';
import { Dimmer, Form, Loader, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import DateTools from '../../../utils/DateTools';
import authorizeGmailCall, {
  changeGmailAccount,
  testConnection,
  unauthorize,
} from '../../../components/pages/admin/integrations/email/gmail/apiCall';
import modalConfig, {
  AUTHORIZE_LEGEND,
  TYPE_CONFIRM,
} from '../../../components/pages/admin/integrations/email/gmail/modalConfig';
import EspConfirmationModal from '../../../../v2/components/display/EspConfirmationModal';

const propTypes = {
  credentialId: PropTypes.number,
  credentials: PropTypes.shape({
    email_address: PropTypes.string,
    extra_creds: PropTypes.shape({
      updated_at: PropTypes.string,
    }),
    id: PropTypes.number,
    sys_custom_fields: PropTypes.PropTypes.shape({
      oauth_authorized_by: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    email_address: PropTypes.string,
    name: PropTypes.string,
  }),
  getGmailConnectionStatus: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  reopenNewInboxModal: PropTypes.func,
  serviceDepartmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  touched: PropTypes.shape({
    email_address: PropTypes.bool,
    name: PropTypes.bool,
  }),
  values: PropTypes.shape({
    email_address: PropTypes.string,
    esp_service_department: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
  }),
};

const GmailInboxFormModal = ({
  values,
  errors,
  handleChange,
  handleChangeSelect,
  handleBlur,
  touched,
  serviceDepartmentOptions,
  credentials,
  credentialId,
  reopenNewInboxModal,
  getGmailConnectionStatus,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeConfirmation, setTypeConfirmation] = useState(null);
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    const isAuthorized = !isEmpty(credentials?.extra_creds);
    if (isAuthorized && !isValidated) {
      setIsloading(true);
      const { email_address, id } = credentials;
      const params = {
        email_address,
        id,
      };
      testConnection(id, params)
        .then(() => {
          setIsConnected(true);
          getGmailConnectionStatus(true);
        })
        .finally(() => {
          setIsloading(false);
          setIsValidated(true);
        });
    }
  }, [credentials, isValidated, getGmailConnectionStatus]);

  const handleAuthorize = useCallback(() => {
    const isCaseInboxAccount = true;
    const credential = credentialId || credentials.id;
    authorizeGmailCall(credential, isCaseInboxAccount).then(({ body }) => {
      window.open(body.redirect_uri, '_self');
    });
  }, [credentials, credentialId]);

  const handleUnauthorize = useCallback(() => {
    setIsloading(true);
    unauthorize(credentials.id)
      .then(() => {
        setIsConnected(false);
        getGmailConnectionStatus(false);
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsloading(false);
      });
  }, [credentials, getGmailConnectionStatus]);

  const handleChangeEmailAccount = useCallback(() => {
    setIsloading(true);
    changeGmailAccount(credentials.id)
      .then(() => {
        setIsConnected(false);
        reopenNewInboxModal();
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsloading(false);
      });
  }, [credentials, reopenNewInboxModal]);

  const handleToggleChangeAccount = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setTypeConfirmation(TYPE_CONFIRM.CHANGE_ACCOUNT);
  }, [isModalOpen]);

  const handleToggleAnauthorizeAccount = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setTypeConfirmation(TYPE_CONFIRM.UNAUTHORIZE_ACCOUNT);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const isAuthorized = !isEmpty(credentials?.extra_creds);

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader inverted size='large' />
      </Dimmer>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={touched.name && errors.name ? errors.name : null}
          label='Name'
          name='name'
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.name}
        />
        <Form.Field
          control={Select}
          label='Existing Service Department'
          name='esp_service_department'
          onChange={handleChangeSelect}
          options={serviceDepartmentOptions}
          placeholder='Service Department'
          value={values.esp_service_department}
        />
      </Form.Group>
      <Form.Input
        error={
          touched.email_address && errors.email_address
            ? errors.email_address
            : null
        }
        label='Email Address'
        name='email_address'
        onBlur={handleBlur}
        onChange={handleChange}
        required
        type='email'
        value={values.email_address}
      />
      {isAuthorized && isConnected && (
        <>
          <Form.Field>
            <label htmlFor='connected'>Status</label>
            <div style={{ color: 'green' }}>Connected</div>
          </Form.Field>
          <Form.Field>
            <label htmlFor='connectedDate'>Connected Date</label>
            <div>
              {DateTools.getFormatDate(credentials?.extra_creds.updated_at)}
            </div>
          </Form.Field>
          <Form.Field>
            <label htmlFor='useAuthorized'>User Authorized</label>
            <div>{credentials?.sys_custom_fields?.oauth_authorized_by}</div>
          </Form.Field>
          <Form.Group>
            <Form.Button
              basic
              content='Change Gmail Account'
              onClick={handleToggleChangeAccount}
              primary
              style={{ margin: '1em 0' }}
            />
            <Form.Button
              basic
              content='Unauthorize Gmail'
              onClick={handleToggleAnauthorizeAccount}
              primary
              style={{ margin: '1em 0' }}
            />
          </Form.Group>
        </>
      )}
      {(!isAuthorized || !isConnected) && (
        <>
          <Form.Button
            content='Authorize Gmail'
            disabled={!credentialId && !credentials?.id}
            onClick={handleAuthorize}
            primary
          />
          <em style={{ fontSize: '0.9em' }}>{AUTHORIZE_LEGEND}</em>
        </>
      )}
      {isModalOpen && (
        <EspConfirmationModal
          cancelButton={modalConfig[typeConfirmation].BUTTON_CANCEL}
          confirmButton={modalConfig[typeConfirmation].BUTTON_OK}
          content={modalConfig[typeConfirmation].CONTENT}
          header={modalConfig[typeConfirmation].TITLE}
          onCancel={handleCloseModal}
          onConfirm={
            typeConfirmation === TYPE_CONFIRM.CHANGE_ACCOUNT
              ? handleChangeEmailAccount
              : handleUnauthorize
          }
          open={isModalOpen}
        />
      )}
    </>
  );
};

GmailInboxFormModal.propTypes = propTypes;

export default GmailInboxFormModal;
