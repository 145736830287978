import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesRecreation extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M67.21 40.58c-1.566.004-3.117.292-4.58.85l-2-4.83 5.39-3.88c.436-.313.672-.834.62-1.368-.054-.534-.387-1-.876-1.22-.49-.22-1.058-.165-1.494.148l-6.43 4.61c-.57.407-.78 1.155-.51 1.8l.74 1.76H44.39l-1.48-3.2c-.21-.508-.684-.86-1.23-.916-.548-.056-1.082.19-1.393.645-.31.453-.348 1.04-.097 1.53l1.2 2.57-4.37 4.36c-4.682-3.732-11.306-3.784-16.046-.126s-6.368 10.08-3.944 15.554c2.423 5.475 8.272 8.586 14.167 7.536 5.894-1.05 10.31-5.99 10.693-11.964h7.53c.512 0 .988-.262 1.263-.693.275-.43.312-.973.097-1.437l-5-10.83h13.59l.58 1.37c-5.313 3.634-7.166 10.61-4.358 16.402 2.81 5.792 9.434 8.656 15.577 6.735 6.143-1.92 9.956-8.05 8.965-14.41-.99-6.36-6.488-11.037-12.925-10.997zm-30.19 7.13c.817 1.106 1.396 2.37 1.7 3.71H33.3l3.72-3.71zm-8 15.87c-4.36.035-8.24-2.76-9.59-6.907-1.348-4.147.146-8.69 3.693-11.226 3.547-2.537 8.328-2.483 11.817.133l-6.32 6.28c-.428.43-.557 1.074-.325 1.634.232.56.78.925 1.385.926h9.24c-.434 5.154-4.728 9.127-9.9 9.16zM47.1 51.42h-5.34c-.363-2.134-1.257-4.142-2.6-5.84l3.56-3.56 4.38 9.4zm20.11 12.16c-4.26-.034-8.03-2.763-9.393-6.798-1.364-4.035-.022-8.49 3.343-11.102l3.45 8.16c.343.73 1.2 1.057 1.943.743.742-.315 1.103-1.16.817-1.913l-3.57-8.44c3.763-1.426 8.014-.458 10.788 2.457 2.774 2.915 3.53 7.21 1.92 10.896-1.61 3.688-5.274 6.05-9.298 5.997zM34.85 33.33h11.17c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5H34.85c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5z' />
        </g>
      </svg>
    );
  }
}

export default CatalogServicesRecreation;
