import { createSelector } from 'reselect';

const getArchetype = (state) => state.getIn(['adminFaqs', 'archetypeList']);

// Remove duplicate entries
const getArchetypeList = (archetypeList) =>
  archetypeList
    .groupBy((c) => c.get('eid'))
    .map((entry) => entry.first())
    .toList();

const selector = createSelector([getArchetype], getArchetypeList);

export default selector;
