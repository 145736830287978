import React from 'react';
import pt from 'prop-types';
import { intl } from 'esp-util-intl';

const AnnouncementAudiencePropTypes = {
  departmentName: pt.string,
  isDepartmentAllSelected: pt.bool,
  isJobRoleAllSelected: pt.bool,
  isLocationAllSelected: pt.bool,
  isRemoteWorkersSelected: pt.bool,
  jobRoleName: pt.string,
  locationName: pt.string,
};

const AnnouncementAudience = ({
  departmentName = '',
  isDepartmentAllSelected = false,
  isJobRoleAllSelected = false,
  isLocationAllSelected = false,
  isRemoteWorkersSelected = false,
  jobRoleName = '',
  locationName = '',
}) => {
  const remoteWorkers = isRemoteWorkersSelected
    ? ` (${intl.formatMessage({ id: 'label.includes_remote_workers' })})`
    : '';

  const style = { marginBottom: '0.2em' };

  return (
    <>
      <div style={style}>
        <strong>{intl.formatMessage({ id: 'label.location' })}:</strong>{' '}
        {isLocationAllSelected
          ? `${intl.formatMessage({
              id: 'label.sent_to_all_locations',
            })}${remoteWorkers}`
          : `${locationName}${remoteWorkers}`}
      </div>
      <div style={style}>
        <strong>{intl.formatMessage({ id: 'label.department' })}:</strong>{' '}
        {isDepartmentAllSelected
          ? intl.formatMessage({
              id: 'label.sent_to_all_departments',
            })
          : departmentName}
      </div>
      <div style={style}>
        <strong>{intl.formatMessage({ id: 'label.job_role' })}:</strong>{' '}
        {isJobRoleAllSelected
          ? intl.formatMessage({
              id: 'label.sent_to_all_job_roles',
            })
          : jobRoleName}
      </div>
    </>
  );
};

AnnouncementAudience.propTypes = AnnouncementAudiencePropTypes;

export default AnnouncementAudience;
