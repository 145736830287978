import React, { useCallback } from 'react';
import pt from 'prop-types';
import OffboardingActivityScheduleTemplate from './OffboardingActivityScheduleTemplate';
import useTemplateListSettings from '../../../../molecules/Offboarding/utils/hooks/useTemplateListSettings';

const propTypes = {
  scheduleID: pt.string,
  selectedTemplates: pt.arrayOf(pt.shape({})),
};

const ScheduleActivityTemplates = ({ scheduleID, selectedTemplates }) => {
  const { templates } = useTemplateListSettings();
  const loadTemplateActivityList = useCallback(() => {}, []);
  // THIS WILL BE REMOVED ONCE FUNCTIONALITY IS ADDED TO THIS SECTION
  const testPropsToTemplate = {
    action: null,
    isLoadingTemplate: false,
    nextTemplate: null,
    scheduleStatus: null,
  };

  return (
    <OffboardingActivityScheduleTemplate
      action={testPropsToTemplate.action}
      isLoading={testPropsToTemplate.isLoadingTemplate}
      loadTemplates={loadTemplateActivityList}
      next={testPropsToTemplate.nextTemplate}
      scheduleID={scheduleID}
      scheduleStatus={testPropsToTemplate.scheduleStatus}
      selectedTemplates={selectedTemplates}
      templateList={templates}
    />
  );
};

ScheduleActivityTemplates.propTypes = propTypes;

export default ScheduleActivityTemplates;
