import actionTypes from './actionTypes';

const emailPoliciesActions = {};

emailPoliciesActions.getTestEmailStart = () => ({
  type: actionTypes.GET_TEST_EMAIL_START,
});

emailPoliciesActions.getTestEmailSuccess = (result) => ({
  result,
  type: actionTypes.GET_TEST_EMAIL_SUCCESS,
});
emailPoliciesActions.getTestEmailEnabledSuccess = (result) => ({
  result,
  type: actionTypes.GET_TEST_EMAIL_ENABLED_SUCCESS,
});

emailPoliciesActions.getTestEmailFail = () => ({
  type: actionTypes.GET_TEST_EMAIL_FAIL,
});

emailPoliciesActions.getAddressPoliciesStart = () => ({
  type: actionTypes.GET_EMAIL_ADDRESS_POLICIES_START,
});

emailPoliciesActions.getAddressPoliciesSuccess = (id, addressRules) => ({
  addressRules,
  id,
  type: actionTypes.GET_EMAIL_ADDRESS_POLICIES_SUCCESS,
});

emailPoliciesActions.getAddressPoliciesFail = () => ({
  type: actionTypes.GET_EMAIL_ADDRESS_POLICIES_FAIL,
});

emailPoliciesActions.updateAddressPolicyStart = () => ({
  type: actionTypes.UPDATE_EMAIL_ADDRESS_POLICY_START,
});

emailPoliciesActions.updateAddressPolicySuccess = (addressRules) => ({
  addressRules,
  type: actionTypes.UPDATE_EMAIL_ADDRESS_POLICY_SUCCESS,
});

emailPoliciesActions.updateAddressPolicyFail = () => ({
  type: actionTypes.UPDATE_EMAIL_ADDRESS_POLICY_FAIL,
});

emailPoliciesActions.getEmailDomainsStart = () => ({
  type: actionTypes.GET_EMAIL_DOMAINS_START,
});

emailPoliciesActions.getEmailDomainsSuccess = (emailDomains) => ({
  emailDomains,
  type: actionTypes.GET_EMAIL_DOMAINS_SUCCESS,
});

emailPoliciesActions.getEmailDomainsFail = () => ({
  type: actionTypes.GET_EMAIL_DOMAINS_FAIL,
});

emailPoliciesActions.selectEmailDomainStart = () => ({
  type: actionTypes.SELECT_EMAIL_DOMAIN_START,
});

emailPoliciesActions.selectEmailDomainSuccess = (emailDomains) => ({
  emailDomains,
  type: actionTypes.SELECT_EMAIL_DOMAIN_SUCCESS,
});

emailPoliciesActions.selectEmailDomainFail = () => ({
  type: actionTypes.SELECT_EMAIL_DOMAIN_FAIL,
});

emailPoliciesActions.addEmailDomainStart = () => ({
  type: actionTypes.ADD_EMAIL_DOMAIN_START,
});

emailPoliciesActions.addEmailDomainSuccess = () => ({
  type: actionTypes.ADD_EMAIL_DOMAIN_SUCCESS,
});

emailPoliciesActions.addEmailDomainFail = () => ({
  type: actionTypes.ADD_EMAIL_DOMAIN_FAIL,
});

export default emailPoliciesActions;
