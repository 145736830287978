const data = {
  count: 2,
  results: [
    {
      source_name: 'release',
      total: 2,
    },
    {
      source_name: 'GPT',
      total: 1,
    },
  ],
};
export default data;
