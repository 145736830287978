import { getFormValues } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
// Selector
import getTenantLabelCustomization from '../../selectors/getTenantLabelCustomization';
// Thunks
import tenantThunks from '../../actions/tenantThunks';
// Global
import customLabels from '../../globals/customLabels';

const mapStateToProps = (state) => {
  const tenantState = state.get('tenant');

  const labelsList = getTenantLabelCustomization(state);
  const isLoading = tenantState.getIn(['localization', 'isLoading']);
  const loaded = tenantState.getIn(['localization', 'loaded']);

  let initialValues;

  const preExistingValues = {};

  if (loaded) {
    initialValues = {};
    customLabels.forEach((item) => {
      const indexList = labelsList.findIndex(
        (lt) => lt.get('key') === item.get('key')
      );
      if (indexList > -1) {
        preExistingValues[item.get('name')] = true;
        initialValues[item.get('name')] = labelsList.getIn([indexList, 'text']);
        initialValues[`${item.get('name')}-language`] = labelsList.getIn([
          indexList,
          'language',
        ]);
      } else {
        initialValues[`${item.get('name')}-language`] = customLabels.getIn([
          'MENTOR',
          'locale',
          0,
        ]);
      }
    });
  }

  return {
    formValues: getFormValues('TenantLabelConfiguration')(state),
    initialValues,
    isLoading,
    labelsList,
    loaded,
    preExistingValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTenantLabelConfiguration() {
    dispatch(tenantThunks.loadTenantLabelConfiguration());
  },

  onSubmit() {},

  setTenantLabelConfiguration(valuesArray) {
    dispatch(tenantThunks.setTenantLabelConfiguration(valuesArray));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
