import { createSelector } from 'reselect';
import { UserAgentUtils } from 'esp-globals';
import immutable from 'immutable';

const getAppVersion = createSelector(
  [
    (state) => state.getIn(['appVersion', 'nativeDesktopVersion']),
    (state) => state.getIn(['appVersion', 'nativeMobileVersion']),
    (state) => state.getIn(['appVersion', 'clientId']),
    (state) => state.getIn(['appVersion', 'systemId']),
  ],
  (nativeDesktopVersion, nativeMobileVersion, clientId, systemId) => {
    const appVersion = {
      systemId,
    };

    if (UserAgentUtils.isWinElectron() || UserAgentUtils.isMacElectron()) {
      appVersion.clientId = nativeDesktopVersion;
    } else if (UserAgentUtils.isCordova()) {
      appVersion.clientId = nativeMobileVersion;
    } else {
      appVersion.clientId = clientId;
    }

    return immutable.Map(appVersion);
  }
);

export default getAppVersion;
