import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareManagement extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M46.83 53.93h16c1.348 0 2.44-1.092 2.44-2.44v-13c0-1.348-1.092-2.44-2.44-2.44h-16c-1.348 0-2.44 1.092-2.44 2.44v13c0 1.348 1.092 2.44 2.44 2.44zm11.76-9.65H62v7.33h-3.41v-7.33zm-5.29-3.05h3.36v10.39H53.3V41.23zm-5.4 5.52h3.26v4.89H47.9v-4.89zm-16.14 7.21h8.17c1.345-.005 2.435-1.095 2.44-2.44v-3.44c-.004-.252-.048-.502-.13-.74-.2-2.17-2.94-3.89-6.34-3.91 2.198-.03 3.96-1.83 3.945-4.027-.015-2.198-1.802-3.973-4-3.973s-3.985 1.775-4 3.973C31.83 41.6 33.592 43.4 35.79 43.43c-3.4 0-6.15 1.74-6.36 3.91-.082.238-.126.488-.13.74v3.44c.003.65.263 1.272.725 1.73.46.457 1.085.713 1.735.71zm-2.18 15.31v-.21.21zm17.48-4.66c-2.02-.082-3.99.637-5.48 2-1.465-1.36-3.412-2.08-5.41-2 2.198-.03 3.96-1.83 3.945-4.027-.015-2.198-1.802-3.973-4-3.973s-3.985 1.775-4 3.973c-.015 2.198 1.747 3.997 3.945 4.027-3.4 0-6.22 1.74-6.42 3.91-.048.176-.068.358-.06.54 0 .642.255 1.257.71 1.71.453.455 1.068.71 1.71.71h8.2c.498 0 .98-.17 1.37-.48.423.312.934.48 1.46.48h8.17c.498 0 .98-.17 1.37-.48.423.312.934.48 1.46.48h8.17c1.367-.08 2.425-1.23 2.39-2.6l-.08-.36c-.2-2.17-2.94-3.89-6.34-3.91 2.198-.03 3.96-1.83 3.945-4.027-.015-2.198-1.802-3.973-4-3.973s-3.985 1.775-4 3.973c-.015 2.198 1.747 3.997 3.945 4.027-2.02-.082-3.99.637-5.48 2-1.465-1.36-3.412-2.08-5.41-2 2.198-.03 3.96-1.83 3.945-4.027-.015-2.198-1.802-3.973-4-3.973s-3.985 1.775-4 3.973c-.015 2.198 1.747 3.997 3.945 4.027z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.65c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.528 15 25.324 15 23.84c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareManagement;
