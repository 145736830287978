import APIcall from '../../../../../utils/APIcall';

const postResetScheduleSync = ({ resetSyncURL, days, time }) => {
  return APIcall.post({
    data: {
      scheduled_sync: {
        days,
        time,
      },
    },
    token: true,
    url: resetSyncURL,
  });
};

export default postResetScheduleSync;
