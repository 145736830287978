import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Input, List } from 'semantic-ui-react';
import { change, Field } from 'redux-form/immutable';
import { intl } from 'esp-util-intl';
// Atoms
import ErrorLabel from '../atoms/ErrorLabel';

class PasswordField extends PureComponent {
  static propTypes = {
    /** Allow diable of uppercase/lowercase check */
    disableCaseCheck: PropTypes.bool,
    /** Force to not use a "confirm password" input box. Defaults to false */
    disableConfirmation: PropTypes.bool,
    /** Allow diable of number check */
    disableNumberCheck: PropTypes.bool,
    /** Allow diable of special character check */
    disableSpecialCheck: PropTypes.bool,
    /** Form error */
    formError: PropTypes.string,
    /** Icon of the field before its valid */
    icon: PropTypes.string,
    /** Coming from Redux Form input object */
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }),

    /** Label for the field input */
    label: PropTypes.string,

    /** Label for the confirmation field input */
    labelConfirm: PropTypes.string,
    /** Coming from Redux Form  */
    meta: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      dispatch: PropTypes.any,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      form: PropTypes.any,
      submitFailed: PropTypes.bool,
    }),
    /** Change length of the minimum required password */
    passwordLengthMinimum: PropTypes.number,
  };

  static defaultProps = {
    disableCaseCheck: false,
    disableConfirmation: false,
    disableNumberCheck: false,
    disableSpecialCheck: false,
    formError: '',
    icon: null,
    input: null,
    label: 'New Password',
    labelConfirm: 'Confirm Password',
    meta: {},
    passwordLengthMinimum: 8,
  };

  // NOTE: Right now the final checkmark that is set on the input is a combination
  // of the below states all being true. We are not exposing that to a component
  // above the PasswordField component, which we probably should so we can see if
  // it is valid outside of the component.
  state = {
    confirmationPassword: '',
    isTotallyValid: false,
    password: '',
    validCase: false,
    validConfirmationMatch: false,
    validLength: false,
    validNumber: false,
    validSpecial: false,
  };

  componentDidMount() {
    const { input } = this.props;
    if (input) {
      // Force to reset the password field when the component will mount
      input.onChange('');
    }
  }

  handleInputChange = (e, d) => {
    const { input } = this.props;
    const { confirmationPassword } = this.state;

    const newPassword = d.value;
    this.setState({
      password: newPassword,
    });

    // Triggering on change event for redux form
    if (input) {
      input.onChange(newPassword);
    }

    this.performValidations(newPassword, confirmationPassword);
  };

  handleConfirmationInputChange = (e, d) => {
    const { password } = this.state;

    const newConfirmationPassword = d.value;
    this.setState({
      confirmationPassword: newConfirmationPassword,
    });

    this.performValidations(password, newConfirmationPassword);
  };

  performValidations = (password, passwordConfirmation = '') => {
    const {
      disableCaseCheck,
      disableNumberCheck,
      disableSpecialCheck,
      input,
      passwordLengthMinimum,
      meta: { form, dispatch },
    } = this.props;

    const hasLower = new RegExp(/[a-z]/);
    const hasUpper = new RegExp(/[A-Z]/);
    const hasNumber = new RegExp(/\d/);
    const hasSpecial = new RegExp(/[$&+,:;=?@#|'<>.^*()%!-]/);

    // Check the length of the password
    const validLength = password.length >= passwordLengthMinimum;

    // Check for uppercase/lowercase
    const validCase =
      (hasUpper.test(password) && hasLower.test(password)) || disableCaseCheck;

    // Check for number
    const validNumber = hasNumber.test(password) || disableNumberCheck;

    // Check for special characters
    const validSpecial = hasSpecial.test(password) || disableSpecialCheck;

    // Check that both passwords match
    const validConfirmationMatch =
      (password === passwordConfirmation || disableSpecialCheck) &&
      password !== '' &&
      passwordConfirmation !== '';

    const isTotallyValid =
      validCase &&
      validLength &&
      validNumber &&
      validSpecial &&
      validConfirmationMatch;

    this.setState({
      isTotallyValid: isTotallyValid,
      validCase: validCase,
      validConfirmationMatch: validConfirmationMatch,
      validLength: validLength,
      validNumber: validNumber,
      validSpecial: validSpecial,
    });

    // Changing the value of the field that is used to keep track of the validation
    if (!disableSpecialCheck) {
      dispatch(change(form, `${input.name}_validationPassed`, isTotallyValid));
    }
  };

  render() {
    const {
      disableCaseCheck,
      disableNumberCheck,
      disableSpecialCheck,
      disableConfirmation,
      formError,
      icon,
      input,
      label,
      labelConfirm,

      meta: { error, submitFailed },
      passwordLengthMinimum,
    } = this.props;

    const {
      isTotallyValid,
      validCase,
      validConfirmationMatch,
      validLength,
      validNumber,
      validSpecial,
    } = this.state;

    return (
      <Form.Field>
        <label>{label}</label>
        <Input
          {...input}
          icon={isTotallyValid ? 'checkmark' : icon}
          onChange={this.handleInputChange}
          required
          type='password'
        />
        {submitFailed && error ? (
          <ErrorLabel error={error} inputName={input.name} pointing='above' />
        ) : null}
        {formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )}
        <List>
          <List.Item>
            <List.Icon
              className={validLength ? 'primary' : null}
              name={validLength ? 'checkmark box' : 'square outline'}
              size='large'
            />
            <List.Content
              content={intl.formatMessage({
                id: 'message.password_must_be_atleast_characters_long',
                values: {
                  n: passwordLengthMinimum,
                },
              })}
            />
          </List.Item>
          {!disableCaseCheck ? (
            <List.Item>
              <List.Icon
                className={validCase ? 'primary' : null}
                name={validCase ? 'checkmark box' : 'square outline'}
                size='large'
              />
              <List.Content
                content={intl.formatMessage({
                  id: 'message.password_must_contain_one_lowercase_one_uppercase',
                })}
              />
            </List.Item>
          ) : null}
          {!disableNumberCheck ? (
            <List.Item>
              <List.Icon
                className={validNumber ? 'primary' : null}
                name={validNumber ? 'checkmark box' : 'square outline'}
                size='large'
              />
              <List.Content
                content={intl.formatMessage({
                  id: 'message.password_must_contain_one_number',
                })}
              />
            </List.Item>
          ) : null}
          {!disableSpecialCheck ? (
            <List.Item>
              <List.Icon
                className={validSpecial ? 'primary' : null}
                name={validSpecial ? 'checkmark box' : 'square outline'}
                size='large'
              />
              <List.Content
                content={intl.formatMessage({
                  id: 'message.password_must_contain_one_special_character',
                })}
              />
            </List.Item>
          ) : null}

          {!disableConfirmation ? (
            <List.Item>
              <List.Icon
                className={validConfirmationMatch ? 'primary' : null}
                name={
                  validConfirmationMatch ? 'checkmark box' : 'square outline'
                }
                size='large'
              />
              <List.Content
                content={intl.formatMessage({
                  id: 'message.passwords_must_match',
                })}
              />
            </List.Item>
          ) : null}
        </List>

        {!disableConfirmation ? <label>{labelConfirm}</label> : null}

        {!disableConfirmation ? (
          <Input
            icon={isTotallyValid ? 'checkmark' : icon}
            onChange={this.handleConfirmationInputChange}
            required
            type='password'
          />
        ) : null}

        {!disableConfirmation ? (
          <Field
            component='input'
            name={`${input.name}_validationPassed`}
            type='hidden'
          />
        ) : null}
      </Form.Field>
    );
  }
}

export const PasswordFieldTest = PasswordField;

export default PasswordField;
