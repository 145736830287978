import _ from 'lodash';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

/**
 * @typedef {Object} HierarchyThunksActionsGroup
 * @property {function} start
 * @property {function} success
 * @property {function} fail
 */

/**
 * @typedef {Object} HierarchyThunksConfiguration
 * @property {string} nodesCollectionEndpoint
 * @property {string} nodeInstanceEndpoint
 * @property {string} nodeInstanceEndpointIDAttrName
 * @property {string} nodeTypeEndpoint
 *
 * @property {HierarchyThunksActionsGroup} getRootNodes
 * @property {HierarchyThunksActionsGroup} getChildrenNodes
 * @property {HierarchyThunksActionsGroup} getDefaultSelectedNode
 */

/**
 * @param {HierarchyThunksConfiguration} configuration
 */
const createHierarchyThunks = (configuration) => {
  const getNode = (nodeID, onNode = _.noop) => {
    const url = endpointGenerator.genPath(configuration.nodeInstanceEndpoint, {
      [configuration.nodeInstanceEndpointIDAttrName]: nodeID,
    });

    APIcall.get({
      error(error) {
        // eslint-disable-next-line no-prototype-builtins -- TODO fix on DEV-15263
        if (error && error.hasOwnProperty('response')) {
          onNode(error.response.body);
        }
      },
      success(response) {
        const node = response.body;
        onNode(null, node);
      },
      token: true,
      url,
    });
  };

  const thunks = {};

  /**
   * Gets top level nodes, those that don't have a parent.
   */
  thunks.getRootNodes = () => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        configuration.nodesCollectionEndpoint
      );

      dispatch(configuration.getRootNodes.start());

      APIcall.get({
        error() {
          dispatch(configuration.getRootNodes.fail());
          reject();
        },
        query: {
          limit: 1000,
          roots: 'yes',
        },
        success(response) {
          let rootNodes = response.body.results;
          // We have to filter the result to be sure that all results.type are the same
          // If not, the hierarchy Select will break
          let typeToCheck = '';
          let valueToCheck = '';
          let dataIssue = false;
          let rootURLwithIssue = null;
          let wrongType = null;
          rootNodes?.forEach((node) => {
            // Get the key name of the type to check;
            if (!typeToCheck) {
              for (const key in node) {
                if (key.match('_type_name')) {
                  typeToCheck = key;
                  break;
                }
              }
            }

            if (!valueToCheck) {
              valueToCheck = node[typeToCheck]; // Fill value to check
            } else if (valueToCheck !== node[typeToCheck]) {
              dataIssue = true; // We have a data issue here
              const domain = endpointGenerator.getDomain();
              wrongType = node[typeToCheck];
              const firstItemIndex = 1;
              rootURLwithIssue = node.url.split(domain)[firstItemIndex];
            }
          });

          if (dataIssue) {
            // eslint-disable-next-line no-console -- debugging
            console.error(
              `Warning: the node ${rootURLwithIssue} is returned as a root but with a wrong type ('${wrongType}' instead of '${valueToCheck}')`
            );

            // We have a data error. We have to find now what should be the real type to use and filter others
            // We now have to load the typelist from this hierarchy
            dispatch(thunks.getTypesList())
              .then((results) => {
                // Get the name of the root type - It should be the only entry with no parent

                const [rootType] = results.filter((r) => !r.parent);

                // Filter with the root name
                rootNodes = rootNodes.filter(
                  (node) => node[typeToCheck] === rootType.name
                );
                dispatch(configuration.getRootNodes.success(rootNodes));
                resolve(rootNodes);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            dispatch(configuration.getRootNodes.success(rootNodes));
            resolve(rootNodes);
          }
        },
        token: true,
        url,
      });
    });

  /**
   * Get type
   */
  thunks.getTypesList = () => () =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(configuration.nodeTypeEndpoint);
      APIcall.get({
        error(err) {
          reject(err);
        },
        success({ body }) {
          resolve(body.results);
        },
        token: true,
        url,
      });
    });

  /**
   * Gets the children nodes of a node.
   * @param {number} parentID
   */
  thunks.getChildrenNodes = (parentID) => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        configuration.nodesCollectionEndpoint
      );

      dispatch(configuration.getChildrenNodes.start());

      APIcall.get({
        error() {
          dispatch(configuration.getChildrenNodes.fail());
          reject();
        },
        query: {
          esp_filters: encodeURI(`parent__EQ=${parentID}`),
          limit: 1000,
        },
        success(response) {
          const childrenNodes = response.body.results;

          dispatch(configuration.getChildrenNodes.success(childrenNodes));
          resolve(childrenNodes);
        },
        token: true,
        url,
      });
    });

  /**
   * Gets the default selected node and all ancestors up to a root node.
   * @param {number} selectedNodeID
   */
  thunks.getDefaultSelectedNode = (selectedNodeID) => (dispatch) =>
    new Promise((resolve, reject) => {
      const nodes = [];

      const recursiveGetNode = (nodeID) => {
        getNode(nodeID, (error, node) => {
          if (error) {
            dispatch(configuration.getDefaultSelectedNode.fail());
            reject();
          } else {
            nodes.push(node);

            const parentID = node?.parent;

            if (parentID) {
              recursiveGetNode(parentID);
            } else {
              dispatch(configuration.getDefaultSelectedNode.success(nodes));
              resolve(nodes);
            }
          }
        });
      };

      dispatch(configuration.getDefaultSelectedNode.start());

      recursiveGetNode(selectedNodeID);
    });

  /**
   * Simple Thunks to return a hierarchy by id
   * @param name
   * @returns {function(): Promise<any>}
   */
  thunks.getByID = (id) => () =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        configuration.nodesCollectionEndpoint
      );

      APIcall.get({
        error(err) {
          reject(err);
        },
        query: {
          esp_filters: encodeURI(`id__EQ=${id}`),
        },
        success(response) {
          const { results } = response.body;
          resolve(results);
        },
        token: true,
        url,
      });
    });

  /**
   * Simple Thunks to return a hierarchy by id
   * @param code
   * @returns {function(): Promise<any>}
   */
  thunks.getByCode = (code) => () =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        configuration.nodesCollectionEndpoint
      );

      APIcall.get({
        error(err) {
          reject(err);
        },
        query: {
          esp_filters: encodeURI(`code__EQ=${code}`),
        },
        success(response) {
          const { results } = response.body;
          resolve(results);
        },
        token: true,
        url,
      });
    });

  return thunks;
};

export default createHierarchyThunks;
