import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import TypeSpacer, { TypeDefaults } from './TypeSpacer';

const rule = () => ({
  paddingBottom: '0',
});

class Spacer01 extends PureComponent {
  static propTypes = TypeSpacer;
  static defaultProps = TypeDefaults;

  render() {
    const { className, type, template } = this.props;

    return (
      <div
        className={className}
        data-blocktype={type}
        data-template={template}
      />
    );
  }
}

export default createComponent(rule, Spacer01, [
  'template',
  'type',
  'id',
  'trigger',
]);
