import tenantActions from '../../../../../actions/tenantActions';
import toastNotificationsActions from '../../../../../actions/toastNotificationsActions';
import buildCompositeController from '../../../../../utils/buildCompositeController';
import getTenantMenuConfiguration from '../../../../../selectors/getTenantMenuConfiguration';
import TenantImagesTypes from '../../../../../utils/TenantImageTypes';
import Immutable from 'immutable';
import getCurrentUser from '../../../../../selectors/getCurrentUser';

const mapStateToProps = (state) => {
  const imagesList =
    state?.getIn(['tenant', 'images', 'values']) ?? Immutable.List();
  const [chatBotSvgImage] = imagesList.filter(
    (image) => TenantImagesTypes.BOT === image?.get('type')
  );

  const PROFILE = 'PROFILE';

  const [avatarImage] =
    getCurrentUser(state)
      ?.get('images')
      .filter((image) => image?.get('type') === PROFILE) ?? Immutable.List();

  return {
    chatBotSvgImage: chatBotSvgImage && chatBotSvgImage?.get('svg'),
    isCustomBrandingEnabled: getTenantMenuConfiguration(state)?.get(
      'customBranding'
    ),
    usersAvatar: avatarImage ? avatarImage?.get('picture') : '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendToastNotification(message, title, type) {
    dispatch(
      toastNotificationsActions[type]({
        message,
        title,
      })
    );
  },
  updateTenantStore(tenantData, tenantImages) {
    // we need to dispatch the start action to trigger a change on the store
    dispatch(tenantActions.updateTenantStart());
    // then save new store values
    dispatch(tenantActions.updateTenantSuccess(tenantData));

    dispatch(tenantActions.uploadTenantImageStart());
    dispatch(tenantActions.uploadTenantImageSuccess(tenantImages));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
