const BundleSelectOptions = [
  {
    key: 'SELECT_ANY',
    text: 'Select Any',
    value: 'SELECT_ANY',
  },
  {
    key: 'SELECT_ALL',
    text: 'Select All',
    value: 'SELECT_ALL',
  },
  {
    key: 'SELECT_ONE',
    text: 'Select One',
    value: 'SELECT_ONE',
  },
];

// Expose Object with constant values
export const BundleSelectValues = {
  SELECT_ALL: 'SELECT_ALL',
  SELECT_ANY: 'SELECT_ANY',
  SELECT_ONE: 'SELECT_ONE',
};

export default BundleSelectOptions;
