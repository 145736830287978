import { Button, Form, Header, Radio, Segment } from 'semantic-ui-react';
import {
  camelCase,
  curry,
  isEmpty,
  isNull,
  isNumber,
  isUndefined,
  memoize,
  noop,
  startCase,
} from 'lodash';
import React, { PureComponent } from 'react';

// molecules
import AnnouncementAudience from './AnnouncementAudience';
// controllers
import AnnouncementGroupSegmentController from '../controllers/AnnouncementGroupSegmentController';
// organisms
import DepartmentSelectField from '../organisms/DepartmentSelectField';
import { Field } from 'redux-form';
import FormInputSelect from './FormInputSelect';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
// Package
import { intl } from 'esp-util-intl';
import LocationSelectField from '../organisms/LocationSelectField';
import PropTypes from 'prop-types';
import SelectUserGroups from '../pages/admin/UserGroups/select/SelectUserGroups';
import ValidatedCheckbox from './ValidatedCheckbox';
import ValidatedField from './ValidatedField';

const groupTypes = {
  ADHOC_GROUP: 'adhoc_group',
  DEPARTMENT: 'department',
  JOB_ROLE: 'job_role',
  LOCATION: 'location',
};

const ALL = 'all';
const SINGLE = 'single';

class AnnouncementGroupSegment extends PureComponent {
  static propTypes = {
    announcement: ImmutablePropTypes.map.isRequired,
    changeGroupField: PropTypes.func,
    currentDepartments: ImmutablePropTypes.map,
    currentLocations: ImmutablePropTypes.map,
    formFields: PropTypes.shape({
      forEach: PropTypes.func,
      length: PropTypes.number,
      name: PropTypes.string,
      remove: PropTypes.func,
    }).isRequired, // ReduxForm 'field' prop coming from FieldArray
    formIndex: PropTypes.number.isRequired, // ReduxForm 'field' index coming from FieldArray
    formMember: PropTypes.string.isRequired, // ReduxForm 'field' member coming from FieldArray
    formValues: ImmutablePropTypes.map, // values of the form where this is being used
    getDepartmentById: PropTypes.func,
    getLocationById: PropTypes.func,
    jobRoles: ImmutablePropTypes.list,
    loadJobRoles: PropTypes.func, // from controller
    readOnly: PropTypes.bool,
    setAllGroupSelectedValue: PropTypes.func,
    tempDepartment: ImmutablePropTypes.map,
    tempLocation: ImmutablePropTypes.map,
  };

  static defaultProps = {
    changeGroupField: noop,
    currentDepartments: Immutable.Map(),
    currentLocations: Immutable.Map(),
    formValues: Immutable.Map(),
    getDepartmentById: noop,
    getLocationById: noop,
    jobRoles: Immutable.List(),
    loadJobRoles: noop,
    readOnly: false,
    setAllGroupSelectedValue: noop,
    tempDepartment: Immutable.Map(),
    tempLocation: Immutable.Map(),
  };

  state = {
    adhoc_group: false,
    departmentRadio: '',
    hasBeenInitialized: false,
    jobRoleRadio: '',
    locationRadio: '',
  };

  componentDidMount() {
    const {
      announcement,
      formIndex,
      getDepartmentById,
      getLocationById,
      jobRoles,
      loadJobRoles,
    } = this.props;
    const { state } = this;
    if (!jobRoles) {
      loadJobRoles();
    }
    if (
      announcement.get('id') !== 'new' &&
      announcement.getIn(['groups', formIndex, groupTypes.DEPARTMENT])
    ) {
      getDepartmentById(
        announcement.getIn(['groups', formIndex, groupTypes.DEPARTMENT])
      );
    }
    if (
      announcement.get('id') !== 'new' &&
      announcement.getIn(['groups', formIndex, groupTypes.LOCATION])
    ) {
      getLocationById(
        announcement.getIn(['groups', formIndex, groupTypes.LOCATION])
      );
    }
    if (
      announcement.get('id') !== 'new' &&
      announcement.getIn(['groups', formIndex, groupTypes.ADHOC_GROUP])
    ) {
      this.setState({
        ...state,
        adhoc_group: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { formIndex, formValues, announcement } = this.props;
    const { departmentRadio, locationRadio, jobRoleRadio, hasBeenInitialized } =
      this.state;
    if (
      ((prevProps.formValues.getIn([
        'groups',
        formIndex,
        groupTypes.LOCATION,
      ]) &&
        formValues.getIn(['groups', formIndex, groupTypes.LOCATION])) ||
        (prevProps.formValues.getIn([
          'groups',
          formIndex,
          groupTypes.DEPARTMENT,
        ]) &&
          formValues.getIn(['groups', formIndex, groupTypes.DEPARTMENT])) ||
        (prevProps.formValues.getIn([
          'groups',
          formIndex,
          groupTypes.JOB_ROLE,
        ]) &&
          formValues.getIn(['groups', formIndex, groupTypes.JOB_ROLE])) ||
        !jobRoleRadio ||
        !locationRadio ||
        !departmentRadio) &&
      announcement.get('id') !== 'new' &&
      !hasBeenInitialized
    ) {
      this.initializeRadioButtons();
    }
  }

  initializeRadioButtons = () => {
    const { formIndex, formValues } = this.props;
    // get current state values
    const defaultState = { ...this.state };
    // set new state / radio button values according to what we have on groups reducer

    Object.keys(groupTypes).forEach((groupTypeKey) => {
      const currentType = groupTypes[groupTypeKey];
      const formValue = formValues.getIn(['groups', formIndex, currentType]);
      if (isNull(formValue)) {
        defaultState[this.getStateKeyName(currentType)] = null;
      }
      if (isNumber(formValue)) {
        defaultState[this.getStateKeyName(currentType)] = SINGLE;
      }
    });

    this.setState({ ...defaultState, hasBeenInitialized: true });
  };

  // In this case, group is a field (an ArrayField) so we remove groups here
  handleRemoveField = () => {
    const { formFields, formIndex } = this.props;
    const confirmFoolLintJS = window.confirm;
    if (
      confirmFoolLintJS(
        intl.formatMessage({
          id: 'message.are_you_sure_you_want_to_delete_this_group',
        })
      )
    ) {
      formFields.remove(formIndex); // local form delete
    }
  };

  memoizedClearSelection = memoize(
    // e argument is needed to create the proper curried function
    curry((type, e) => {
      this.setSelection(type, ALL);
    })
  );

  getStateKeyName = (currentGroupType) => `${camelCase(currentGroupType)}Radio`;

  recountUsers = (values) => {
    const { formMember, announcement, changeGroupField } = this.props;
    // recount affected users
    changeGroupField(values, formMember, announcement);
  };

  setSelection = (type, value) => {
    const {
      setAllGroupSelectedValue,
      formMember,
      announcement,
      formIndex,
      formValues,
    } = this.props;

    let newGroupValues = {};

    const groupTypesKeys = Object.keys(groupTypes);
    const setGroupTypeAsAll = (currentGroupType) => {
      setAllGroupSelectedValue(currentGroupType, formMember, announcement);
    };
    const isCurrentGroupTypeEmpty = (currentGroupType) =>
      !formValues.getIn(['groups', formIndex, currentGroupType]);

    const getAllGroupValueBySelectedType = (
      selectedGroupType,
      currentGroupType
    ) =>
      selectedGroupType === currentGroupType
        ? null
        : formValues.getIn(['groups', formIndex, currentGroupType], null);
    const geGroupValueByGroupType = (currentGroupType) =>
      formValues.getIn(['groups', formIndex, currentGroupType], null);

    if (value === ALL) {
      newGroupValues = {
        department: getAllGroupValueBySelectedType(type, groupTypes.DEPARTMENT),
        include_remote: formValues.getIn([
          'groups',
          formIndex,
          'include_remote',
        ]),
        job_role: getAllGroupValueBySelectedType(type, groupTypes.JOB_ROLE),
        location: getAllGroupValueBySelectedType(type, groupTypes.LOCATION),
      };
      const newState = {};
      groupTypesKeys.forEach((groupType) => {
        const currentGroupType = groupTypes[groupType];
        if (
          (isCurrentGroupTypeEmpty(currentGroupType) &&
            // eslint-disable-next-line react/destructuring-assignment -- not sure how to fix this dynamic access to the state
            !this.state[this.getStateKeyName(currentGroupType)]) ||
          type === currentGroupType
        ) {
          newState[this.getStateKeyName(currentGroupType)] = null;
          // set null on the reducer
          setGroupTypeAsAll(currentGroupType);
        }
      });
      this.setState({ ...newState }, () => this.recountUsers(newGroupValues));
    } else {
      newGroupValues = {
        department: geGroupValueByGroupType(groupTypes.DEPARTMENT),
        include_remote: formValues.getIn([
          'groups',
          formIndex,
          'include_remote',
        ]),
        job_role: geGroupValueByGroupType(groupTypes.JOB_ROLE),
        location: geGroupValueByGroupType(groupTypes.LOCATION),
      };
      // value === single
      const newState = {
        [this.getStateKeyName(type)]: SINGLE,
      };
      groupTypesKeys.forEach((groupType) => {
        const currentGroupType = groupTypes[groupType];
        if (
          isCurrentGroupTypeEmpty(currentGroupType) &&
          type !== currentGroupType
        ) {
          setGroupTypeAsAll(currentGroupType);
        }
      });
      this.setState(newState);
    }
  };

  setSelectionByGroup = memoize(
    curry((type, e, { value }) => {
      this.setSelection(type, value);
    })
  );

  handleChangeLocation = (e, val) => {
    const { formIndex, formValues } = this.props;
    const { locationRadio } = this.state;

    const newGroupVals = {
      adhoc_group: undefined,
      department: formValues.getIn(
        ['groups', formIndex, groupTypes.DEPARTMENT],
        null
      ),
      include_remote: formValues.getIn(['groups', formIndex, 'include_remote']),
      job_role: formValues.getIn(
        ['groups', formIndex, groupTypes.JOB_ROLE],
        null
      ),
      location: val,
    };
    // set radio button if the user clicked on select button

    if (!locationRadio) {
      this.setState({
        locationRadio: SINGLE,
      });
    }

    this.recountUsers(newGroupVals);
  };

  handleChangeIncludeRemote = (e, val) => {
    const { formIndex, formValues } = this.props;

    const newGroupValues = {
      adhoc_group: undefined,
      department: formValues.getIn(
        ['groups', formIndex, groupTypes.DEPARTMENT],
        null
      ),
      include_remote: val,
      job_role: formValues.getIn(
        ['groups', formIndex, groupTypes.JOB_ROLE],
        null
      ),
      location: formValues.getIn(
        ['groups', formIndex, groupTypes.LOCATION],
        null
      ),
    };

    // set radio buttons is they have not been clicked
    const newState = {};
    Object.keys(newGroupValues).forEach((groupTypeKey) => {
      const newGroupValuesType = newGroupValues[groupTypeKey];
      if (
        !newGroupValuesType &&
        // eslint-disable-next-line react/destructuring-assignment -- not sure how to fix this dynamic access to the state
        !this.state[this.getStateKeyName(groupTypeKey)]
      ) {
        newState[this.getStateKeyName(groupTypeKey)] = null;
      }
    });
    this.setState({ ...newState });

    this.recountUsers(newGroupValues);
  };

  handleChangeDepartment = (e, val) => {
    const { formIndex, formValues } = this.props;
    const { departmentRadio } = this.state;

    const newGroupVals = {
      adhoc_group: undefined,
      department: val,
      include_remote: formValues.getIn(['groups', formIndex, 'include_remote']),
      job_role: formValues.getIn(
        ['groups', formIndex, groupTypes.JOB_ROLE],
        null
      ),
      location: formValues.getIn(
        ['groups', formIndex, groupTypes.LOCATION],
        null
      ),
    };
    // set radio button if the user clicked on select button

    if (!departmentRadio) {
      this.setState({
        departmentRadio: SINGLE,
      });
    }

    this.recountUsers(newGroupVals);
  };

  handleChangeJobRoles = (e, val) => {
    const { formIndex, formValues } = this.props;

    const newGroupVals = {
      department: formValues.getIn(
        ['groups', formIndex, groupTypes.DEPARTMENT],
        null
      ),
      include_remote: formValues.getIn(['groups', formIndex, 'include_remote']),
      job_role: val,
      location: formValues.getIn(
        ['groups', formIndex, groupTypes.LOCATION],
        null
      ),
    };

    this.recountUsers(newGroupVals);
  };

  handleChangeSelectUsersGroup = (e, val) => {
    const newGroupVals = {
      adhoc_group: val,
    };
    this.setState({
      adhoc_group: true,
      departmentRadio: null,
      hasBeenInitialized: false,
      jobRoleRadio: null,
      locationRadio: null,
    });
    this.recountUsers(newGroupVals);
  };
  renderSelectUserGroups = ({ adhoc_group, selectedGroup, ...rest }) => {
    const { announcement, formIndex } = this.props;
    const hasAdhocGroup =
      typeof announcement.getIn([
        'groups',
        formIndex,
        groupTypes.ADHOC_GROUP,
      ]) === 'number';
    return (
      <SelectUserGroups
        disabled={this.disableUserGroupsIf(this.state, hasAdhocGroup)}
        handleSelectionInParent={rest.input.onChange}
        hasAdHocGroup={hasAdhocGroup}
        selectedGroup={selectedGroup}
      />
    );
  };
  getValuePath = (type, formIndex) => ['groups', formIndex, type];
  disableUserGroupsIf = (
    { departmentRadio, locationRadio, jobRoleRadio },
    savedAdhocGroup
  ) => {
    const { formValues, formIndex } = this.props;
    const isTempGroup = formValues.getIn(['groups', formIndex, 'id']) === 'tmp';
    return (
      (departmentRadio === '' || locationRadio === '' || jobRoleRadio === '') &&
      !savedAdhocGroup &&
      !isTempGroup
    );
  };
  render() {
    const {
      announcement,
      currentDepartments,
      currentLocations,
      formFields,
      formIndex,
      formMember,
      formValues,
      jobRoles,
      readOnly,
      tempDepartment,
      tempLocation,
    } = this.props;

    const { locationRadio, departmentRadio, jobRoleRadio, adhoc_group } =
      this.state;

    let displayRemove = !readOnly;

    const styles = {
      header: { margin: 0 },
      pickerText: { padding: '0.5em 1em 0.5em 2em' },
      radio: { margin: '0.5em 0', paddingRight: '0.5em' },
      segment: { margin: 0 },
    };

    // If no location at all and just one grp is available, hide the "REMOVE" option.
    if (formValues.get('groups') && formValues.get('groups').size === 1) {
      displayRemove = false;
    }

    const locationId = formValues.getIn(
      this.getValuePath(groupTypes.LOCATION, formIndex)
    );
    const departmentId = formValues.getIn(
      this.getValuePath(groupTypes.DEPARTMENT, formIndex)
    );
    const jobRoleId = formValues.getIn(
      this.getValuePath(groupTypes.JOB_ROLE, formIndex)
    );
    const locationAll = locationRadio === null;
    const departmentAll = departmentRadio === null;
    const jobRoleAll = jobRoleRadio === null;
    const locationSingle = !isEmpty(locationRadio);
    const departmentSingle = !isEmpty(departmentRadio);
    const jobRoleSingle = !isEmpty(jobRoleRadio);
    const selectLabel = intl.formatMessage({ id: 'label.select' });

    const disableCustomGroupSelectorIf = (type) =>
      readOnly ||
      typeof announcement.getIn([
        'groups',
        formIndex,
        groupTypes.ADHOC_GROUP,
      ]) === 'number' ||
      adhoc_group ||
      isUndefined(this.getValuePath(type), formIndex);

    const buttonStyles = {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      display: 'inline',
      padding: 0,
      textDecoration: 'underline',
    };

    const locationClearLink = () => (
      <button
        onClick={this.memoizedClearSelection(groupTypes.LOCATION)}
        style={buttonStyles}
        type='button'
      >
        {intl.formatMessage({
          id: 'label.clear',
        })}
      </button>
    );

    const departmentClearLink = () => (
      //
      <button
        onClick={this.memoizedClearSelection(groupTypes.DEPARTMENT)}
        style={buttonStyles}
        type='button'
      >
        {intl.formatMessage({
          id: 'label.clear',
        })}
      </button>
    );

    const departmentName = startCase(
      currentDepartments.getIn([departmentId, 'name'], '') ||
        tempDepartment.get('name')
    );

    const locationName = startCase(
      currentLocations.getIn([locationId, 'name'], '') ||
        tempLocation.get('name')
    );

    const jobRole =
      jobRoles &&
      !jobRoles.isEmpty() &&
      jobRoles.find((jl) => jl.get('value') === jobRoleId);

    const jobRoleName = startCase(
      jobRole && !jobRole.isEmpty() ? jobRole.get('text') : ''
    );

    const triggerChangeButton = (
      <button style={buttonStyles} type='button'>
        {intl.formatMessage({
          id: 'label.change',
        })}
      </button>
    );

    return !formValues.isEmpty() ? (
      <div data-group={formIndex} id={`group${formIndex}`}>
        <Header as='h5' style={styles.header}>
          {displayRemove ? (
            <Button
              className='text'
              content={intl.formatMessage({ id: 'label.remove' })}
              floated='right'
              onClick={this.handleRemoveField}
              type='button'
            />
          ) : null}

          {`${intl.formatMessage({
            id: 'label.group',
          })} ${Number(formIndex + 1)} (${formValues.getIn(
            ['groups', formIndex, 'user_count'],
            0
          )} ${intl.formatMessage({
            id: 'label.user',
            value: formValues.getIn(['groups', formIndex, 'user_count'], 0),
          })})`}
        </Header>
        <Segment basic style={styles.segment}>
          {readOnly ? (
            <AnnouncementAudience
              departmentName={departmentName}
              isDepartmentAllSelected={departmentAll}
              isJobRoleAllSelected={jobRoleAll}
              isLocationAllSelected={locationAll}
              isRemoteWorkersSelected={formValues.getIn(
                ['groups', formIndex, 'include_remote'],
                false
              )}
              jobRoleName={jobRoleName}
              locationName={locationName}
            />
          ) : (
            <div>
              <Form.Field>
                <p>
                  {intl.formatMessage({ id: 'label.admin_title_user_groups' })}
                </p>
                {/* --------------USER GROUPS IMPLEMENTATION------------------- */}
                <Field
                  adhoc_group={adhoc_group}
                  component={this.renderSelectUserGroups}
                  name={`${formMember}.adhoc_group`}
                  onChange={this.handleChangeSelectUsersGroup}
                  selectedGroup={announcement.getIn([
                    'groups',
                    formIndex,
                    groupTypes.ADHOC_GROUP,
                  ])}
                />

                {/* ------------------------------------------------------------ */}
              </Form.Field>
              <Form.Field>
                <p>{intl.formatMessage({ id: 'label.location' })}</p>
                <Radio
                  checked={locationAll}
                  disabled={disableCustomGroupSelectorIf(groupTypes.LOCATION)}
                  label={intl.formatMessage({
                    id: 'label.sent_to_all_locations',
                  })}
                  onClick={
                    !readOnly
                      ? this.setSelectionByGroup(groupTypes.LOCATION)
                      : noop
                  }
                  readOnly
                  style={styles.radio}
                  value={ALL}
                />

                <Radio
                  checked={locationSingle}
                  disabled={disableCustomGroupSelectorIf(groupTypes.LOCATION)}
                  label={intl.formatMessage({
                    id: 'label.restrict_single_location',
                  })}
                  onClick={
                    !readOnly
                      ? this.setSelectionByGroup(groupTypes.LOCATION)
                      : noop
                  }
                  readOnly
                  style={styles.radio}
                  value={SINGLE}
                />

                <Field
                  component={LocationSelectField}
                  name={`${formMember}.location`}
                  onChange={this.handleChangeLocation}
                  placeholder={intl.formatMessage({
                    id: 'label.select_a_location',
                  })}
                  required
                  trigger={
                    <Button
                      basic
                      content={selectLabel}
                      disabled={disableCustomGroupSelectorIf(
                        groupTypes.LOCATION
                      )}
                      size='small'
                      type='button'
                    />
                  }
                />
                {locationId ? (
                  <div style={styles.pickerText}>
                    <div>
                      {locationId && <strong>{locationName}</strong>}
                      {!locationId && (
                        <strong>
                          {intl.formatMessage({
                            id: 'label.none',
                          })}
                        </strong>
                      )}{' '}
                      {!readOnly && (
                        <>
                          <Field
                            component={LocationSelectField}
                            disabled={disableCustomGroupSelectorIf(
                              groupTypes.LOCATION
                            )}
                            name={`${formMember}.location`}
                            onChange={this.handleChangeLocation}
                            placeholder={intl.formatMessage({
                              id: 'label.select_a_location',
                            })}
                            required
                            trigger={triggerChangeButton}
                          />
                          {' / '}
                          {locationClearLink()}
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
                <div style={styles.radio}>
                  <ValidatedCheckbox
                    disabled={disableCustomGroupSelectorIf()}
                    label={intl.formatMessage({
                      id: 'label.include_remote_workers',
                    })}
                    name={`${formMember}.include_remote`}
                    onChange={this.handleChangeIncludeRemote}
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <p>{intl.formatMessage({ id: 'label.department' })}</p>
                <Radio
                  checked={departmentAll}
                  disabled={disableCustomGroupSelectorIf(groupTypes.DEPARTMENT)}
                  label={intl.formatMessage({
                    id: 'label.sent_to_all_departments',
                  })}
                  onClick={
                    !readOnly
                      ? this.setSelectionByGroup(groupTypes.DEPARTMENT)
                      : noop
                  }
                  readOnly
                  style={styles.radio}
                  value={ALL}
                />
                <Radio
                  checked={departmentSingle}
                  disabled={disableCustomGroupSelectorIf(groupTypes.DEPARTMENT)}
                  label={intl.formatMessage({
                    id: 'label.restrict_single_department',
                  })}
                  onClick={
                    !readOnly
                      ? this.setSelectionByGroup(groupTypes.DEPARTMENT)
                      : noop
                  }
                  readOnly
                  style={styles.radio}
                  value={SINGLE}
                />

                <Field
                  component={DepartmentSelectField}
                  name={`${formMember}.department`}
                  onChange={this.handleChangeDepartment}
                  placeholder={intl.formatMessage({
                    id: 'label.select_a_department',
                  })}
                  required={disableCustomGroupSelectorIf(groupTypes.DEPARTMENT)}
                  trigger={
                    <Button
                      basic
                      content={selectLabel}
                      disabled={disableCustomGroupSelectorIf(
                        groupTypes.DEPARTMENT
                      )}
                      size='small'
                      type='button'
                    />
                  }
                />
                {departmentId && (
                  <div style={styles.pickerText}>
                    <div>
                      {departmentId && <strong>{departmentName}</strong>}
                      {!departmentId && (
                        <strong>
                          {intl.formatMessage({
                            id: 'label.none',
                          })}
                        </strong>
                      )}{' '}
                      {!readOnly && (
                        <>
                          <Field
                            component={DepartmentSelectField}
                            name={`${formMember}.department`}
                            onChange={this.handleChangeDepartment}
                            placeholder={intl.formatMessage({
                              id: 'label.select_a_department',
                            })}
                            required
                            trigger={triggerChangeButton}
                          />
                          {' / '}
                          {departmentClearLink()}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Form.Field>
              {jobRoles ? (
                <Form.Field>
                  <p>
                    {intl.formatMessage({
                      id: 'label.job_role',
                    })}
                  </p>
                  <div>
                    <Radio
                      checked={jobRoleAll}
                      disabled={disableCustomGroupSelectorIf(
                        groupTypes.JOB_ROLE
                      )}
                      label={intl.formatMessage({
                        id: 'label.sent_to_all_job_roles',
                      })}
                      onClick={
                        !readOnly
                          ? this.setSelectionByGroup(groupTypes.JOB_ROLE)
                          : noop
                      }
                      readOnly
                      style={styles.radio}
                      value={ALL}
                    />
                  </div>
                  <Radio
                    checked={jobRoleSingle}
                    disabled={disableCustomGroupSelectorIf(groupTypes.JOB_ROLE)}
                    label={intl.formatMessage({
                      id: 'label.restrict_single_job_role',
                    })}
                    onClick={
                      !readOnly
                        ? this.setSelectionByGroup(groupTypes.JOB_ROLE)
                        : noop
                    }
                    readOnly
                    style={styles.radio}
                    value={SINGLE}
                  />

                  {jobRoleSingle && (
                    <div style={styles.pickerText}>
                      <ValidatedField
                        component={FormInputSelect}
                        disabled={disableCustomGroupSelectorIf(
                          groupTypes.JOB_ROLE
                        )}
                        name={`${formMember}.job_role`}
                        onChange={this.handleChangeJobRoles}
                        options={jobRoles.toJS()}
                        placeholder={intl.formatMessage({
                          id: 'label.job_roles',
                        })}
                        search
                        upward={
                          formFields.length > 1 &&
                          formIndex + 1 === formFields.length
                        }
                      />
                    </div>
                  )}
                </Form.Field>
              ) : null}
            </div>
          )}
        </Segment>
      </div>
    ) : null;
  }
}

export default AnnouncementGroupSegmentController(AnnouncementGroupSegment);
