const { default: _APIcall } = require('../../../../../../utils/APIcall');
const {
  default: endpointGenerator,
} = require('../../../../../../utils/endpointGenerator');

const fetchAllowedExtensions = () => {
  const url = endpointGenerator.genPath('espConfig.brandingAllowedExtensions');
  return _APIcall.get({
    preventShowError: true,
    token: true,
    url: url,
  });
};

export default fetchAllowedExtensions;
