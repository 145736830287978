const api_todo_v0_1_progress = {
  count: 1,
  results: [
    {
      complete_date_time: null,
      deadline: '2017-09-22T02:00:00Z',
      eid: '109b82e3-91bf-43f6-bb50-ab3f3c2312c8',
      id: 25,
      start_date_time: '2017-09-28T22:39:56.939474Z',
      state: 'DELINQUENT',
      state_before_complete: '',
      sys_created_by: null,
      sys_date_created: '2017-09-28T22:39:56.945714Z',
      sys_date_updated: '2017-09-29T04:47:08.735225Z',
      sys_updated_by: null,
      todo: 8,
      url: 'http://tenant1.esp/api/todo/v0.1/progress/25/',
    },
  ],
};

export default api_todo_v0_1_progress;
