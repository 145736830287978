import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesDryEraseMarkers extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path
            d='M53.125 27.382c.44 0 .8.355.8.796v56.32c0 .44-.357.797-.8.797h-10.3c-.443 0-.8-.354-.8-.796V28.176c0-.44.355-.796.8-.796h10.3z'
            stroke='#F00'
            strokeWidth='2'
          />
          <path
            d='M45.198 13.895h-3.174c-.432 0-.775.353-.752.79l.71 13.49c.023.432.4.79.842.79h10.3c.444 0 .82-.353.842-.79l.71-13.49c.023-.432-.314-.79-.752-.79H50.79v-2.383c0-.44-.352-.79-.788-.79h-4.015c-.44 0-.79.353-.79.79v2.383zm6.048 24.593v43.164L45.99 38.488h5.256z'
            fill='#F00'
            fillRule='nonzero'
          />
        </g>
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesDryEraseMarkers;
