import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import ImmutablePropTypes from 'react-immutable-proptypes';

import BuildInfo from '../atoms/BuildInfo';
import LoginController from '../controllers/LoginController';
import TenantImage from '../molecules/TenantImage';
// Organisms
import LoginForm from '../organisms/LoginForm';
import ExternalLogin from '../organisms/ExternalLogin';

// Util
import TenantImageTypes from '../../utils/TenantImageTypes';
import getAppVersions from '../../utils/appVersionHelper';

import TenantBackgroundImage from '../../../v2/components/functional/TenantBackgroundImage';
import BodyClassName from '../../../globals/BodyClassName';
import {
  CLIENT_WIDGET_FRAME,
  CLIENT_WIDGET_FRAME_REDIRECT,
} from '../../globals/clientFrameKeys';

/**
 * If client is "widget", set flag in localStorage to keep track of
 * the client type in auth flow. If redirect is present, set it in localStorage
 *
 * See sessionThunks.js>authenticateWithOAuth and sessionThunks.js>userLogin>authenticateWithSAML
 * @param location
 */
function checkWidgetClient(location) {
  const query = new URLSearchParams(location.search ?? '');
  if (query.has('client') && query.get('client') === 'widget') {
    window.localStorage.setItem(CLIENT_WIDGET_FRAME, 'true');
  }
  if (query.has('redirect')) {
    window.localStorage.setItem(
      CLIENT_WIDGET_FRAME_REDIRECT,
      query.get('redirect')
    );
  }
}

class LoginPage extends PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    error: ImmutablePropTypes.map,
    externalLoginChecked: PropTypes.bool,
    externalLoginUri: PropTypes.string,
    formValidation: PropTypes.func.isRequired,
    getAuthLogin: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    loginExternally: PropTypes.func.isRequired,
    previousURL: PropTypes.string,
    tenantImageHeight: PropTypes.string,
  };

  static defaultProps = {
    error: null,
    externalLoginChecked: false,
    externalLoginUri: '',
    isLoading: false,
    previousURL: '',
    tenantImageHeight: '',
  };

  state = {
    isLocalAuth: false,
  };

  componentDidMount() {
    const { getAuthLogin, location } = this.props;
    checkWidgetClient(location);
    getAuthLogin();
    getAppVersions();

    // DEV-24250
    // This ignores the result of getAuthLogin
    // if the URL is /login?localAuth=true
    // then avoids any redirect to force to use local auth
    const query = new URLSearchParams(location.search ?? '');
    if (query.has('localAuth')) {
      this.setState({
        isLocalAuth: true,
      });
    }
  }

  onSubmitForm = (data) => {
    const { handleSubmit, previousURL } = this.props;

    handleSubmit(data, previousURL);
  };

  render() {
    const {
      email,
      error,
      externalLoginChecked,
      externalLoginUri,
      formValidation,
      isLoading,
      loginExternally,
      tenantImageHeight,
    } = this.props;

    const { isLocalAuth } = this.state;

    return (
      <BodyClassName className='login-page'>
        <Grid centered verticalAlign='middle'>
          <Helmet title='Login' />
          <Grid.Row>
            <Grid.Column width={16}>
              <TenantBackgroundImage />
              <TenantImage
                className='logo'
                height={tenantImageHeight}
                type={TenantImageTypes.ALTERNATE_LOGO}
              />
              {externalLoginChecked && (!externalLoginUri || isLocalAuth) ? (
                <Segment>
                  <LoginForm
                    email={email}
                    error={error}
                    formValidation={formValidation}
                    handleSubmit={this.onSubmitForm}
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    isLoading={isLoading}
                  />
                </Segment>
              ) : null}

              {externalLoginChecked && externalLoginUri && !isLocalAuth ? (
                <Segment>
                  <ExternalLogin
                    externalLoginUri={externalLoginUri}
                    loginExternally={loginExternally}
                  />
                </Segment>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <BuildInfo />
        </Grid>
      </BodyClassName>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
LoginPage = LoginController(LoginPage);

export default LoginPage;
