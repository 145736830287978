import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form/immutable';
import _ from 'lodash';

// Molecule
import SocialMediaArray from './SocialMediaArray';

// globals
import { MediaTypes } from '../../globals/SocialMedias';

/**
 * Wrapper for SocialMediaArray that does in fact all the job
 */

class ValidatedSocialMedia extends PureComponent {
  static propTypes = {
    defaultNetwork: PropTypes.string,
    disabledOptions: PropTypes.arrayOf(PropTypes.string),
    fieldValidation: PropTypes.func,
    label: PropTypes.string,
    mediaType: PropTypes.oneOf([MediaTypes.SOCIAL, MediaTypes.COLLAB]),
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    showOneByDefault: PropTypes.bool,
  };

  static defaultProps = {
    defaultNetwork: 'slack',
    disabledOptions: [],
    fieldValidation: _.noop,
    label: '',
    mediaType: MediaTypes.COLLAB,
    multiple: true,
    showOneByDefault: true,
  };

  render() {
    const { fieldValidation, name, ...props } = this.props;

    return (
      <FieldArray
        {...props}
        component={SocialMediaArray}
        name={name}
        validate={fieldValidation}
      />
    );
  }
}

export default ValidatedSocialMedia;
