// Artificial models don't match and appModel in the api
const SearchModels = {
  CASES: 'task',
  CATALOG_PRODUCT: 'catalog.EspProduct',
  DIRECT_CHAT_CHANNEL: 'chat.Channel.d', // artificial model
  EQUIPMENT: 'assets.EspAsset',
  FEED_CARD: 'cards.EspCard', // artificial model
  SCOPED_CHAT_CHANNEL: 'chat.Channel.s', // artificial model
  SERVICE_TEAM: 'case_mgmt.ServiceTeam',
  USER: 'espuser.Espuser',
};

export default SearchModels;
