import buildCompositeController from '../../utils/buildCompositeController';

// Actions
import searchThunks from '../../actions/searchThunks';
import searchActions from '../../actions/searchActions';
// Selectors
import getCurrentUser from '../../selectors/getCurrentUser';
import getTenantMenuConfiguration from '../../selectors/getTenantMenuConfiguration';

// Utils
import SearchContexts from '../../utils/SearchContexts';
// Globals
import ContextNames from '../../globals/ContextNames';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);

  const searchState = state.get('search');

  const userInput = searchState.get('userInput');
  const contextName = searchState.get('contextName');
  const results = searchState.get('results');
  const isLoading = searchState.get('isLoading');

  const configurationTenantMenu = getTenantMenuConfiguration(state);

  let searchContext = null;

  if (contextName) {
    searchContext = SearchContexts.find(
      (context) => context.name === contextName
    );

    // DEV-6905 disabling Contacts for search
    if (
      configurationTenantMenu.get('disableDirectory_hideContacts') &&
      searchContext &&
      searchContext.name === ContextNames.DIRECTORY
    ) {
      searchContext = null;
    }
  }

  return {
    currentUser,
    isLoading,
    results,
    searchContext,
    userInput,
  };
};

const mapDispatchToProps = (dispatch) => ({
  search() {
    dispatch(searchThunks.search());
  },

  setUserInput(userInput) {
    dispatch(searchActions.setUserInput(userInput));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
