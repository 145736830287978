import buildCompositeController from '../../utils/buildCompositeController';
// Selections
import getCurrentUser from '../../selectors/getCurrentUser';
import { getSelectedChannelByUser } from '../../selectors/getChannelsSorted';
import {
  getChannelMessagesWithTypingSanitized,
  getMessagesUntilGotIt,
} from '../../selectors/getMessages';
// Actions
import chatThunks from '../../actions/chatThunks';
import socializeThunks from '../../actions/socializeThunks';
import baristaThunks from '../../actions/baristaThunks';
import wsActions from '../../actions/wsActions';
import baristaActions from '../../actions/baristaActions';

// Globals
import ChannelTypes from '../../globals/ChannelTypes';

const mapStateToProps = (state, ownProps) => {
  let selectedChannel;
  let channelType;

  const withUserId = ownProps && ownProps.withUserId;
  // case where the entry point is a user id
  // and we need to find the direct channel with that user
  if (withUserId) {
    channelType = ChannelTypes.DIRECT_CHANNEL;
    selectedChannel = getSelectedChannelByUser(state, withUserId);
  } else if (ownProps.channelId) {
    // entry point is channel ID
    channelType = ChannelTypes.SCOPED_CHANNEL;
    selectedChannel = state.getIn(['entities', 'channels', ownProps.channelId]);
  } else if (ownProps.directChannelId) {
    channelType = ChannelTypes.DIRECT_CHANNEL;
    selectedChannel = state.getIn([
      'entities',
      'channels',
      ownProps.directChannelId,
    ]);
  } else if (ownProps.supportChannelStart) {
    channelType = ChannelTypes.SUPPORT_CHANNEL;
    // Chart started via supportChannelStart prop
    const supportChannelId = state.getIn(['barista', 'selectedSupportChannel']);
    selectedChannel = state.getIn(['entities', 'channels', supportChannelId]);
  }

  if (selectedChannel) {
    // DEV-5415
    // The channel type is loaded a priori before loading the channel proper
    // We need to set the actual channel type here
    // because the type can change now in the course of the conversation
    const loadedType = selectedChannel.get('type');
    if (loadedType) {
      channelType = selectedChannel.get('type');
    }
  }

  let messages;
  let pagination;

  let selectedChannelId;
  if (selectedChannel) {
    selectedChannelId = selectedChannel.get('id');
    messages = ownProps.inModal
      ? getMessagesUntilGotIt(state, selectedChannelId)
      : getChannelMessagesWithTypingSanitized(state, selectedChannelId); // temporary solution
    pagination = state.getIn(['chatState', 'pagination', selectedChannelId]);
  }

  // channel state
  const isLoadedChannel =
    channelType === ChannelTypes.SUPPORT_CHANNEL
      ? state.getIn(['barista', 'supportChannelState', 'isLoadedChannel'])
      : channelType === ChannelTypes.DIRECT_CHANNEL
      ? state.getIn([
          'chatState',
          'directChannelState',
          withUserId,
          'isLoadedChannel',
        ])
      : state.getIn([
          'chatState',
          'scopedChannelState',
          selectedChannelId,
          'isLoadedChannel',
        ]);
  const isLoadingChannel =
    channelType === ChannelTypes.SUPPORT_CHANNEL
      ? state.getIn(['barista', 'supportChannelState', 'isLoadingChannel'])
      : channelType === ChannelTypes.DIRECT_CHANNEL
      ? state.getIn([
          'chatState',
          'directChannelState',
          withUserId,
          'isLoadingChannel',
        ])
      : state.getIn([
          'chatState',
          'scopedChannelState',
          selectedChannelId,
          'isLoadingChannel',
        ]);
  const errorMsg =
    channelType === ChannelTypes.SUPPORT_CHANNEL
      ? state.getIn(['barista', 'supportChannelState', 'errorMsg'])
      : channelType === ChannelTypes.DIRECT_CHANNEL
      ? state.getIn(['chatState', 'directChannelState', withUserId, 'errorMsg'])
      : state.getIn([
          'chatState',
          'scopedChannelState',
          selectedChannelId,
          'errorMsg',
        ]);
  const currentUser = getCurrentUser(state);

  const getHelpLabelFromBackend = state.getIn([
    'appUI',
    'menuCustomization',
    'getHelpLabel',
  ]);
  const isProhibitChatAutoScrollingEnabled = state.getIn([
    'tenant',
    'entity',
    'value',
    'ui_config',
    'messages',
    'prohibit_chat_autoScrolling',
  ]);

  return {
    channelType: channelType,
    currentUser,
    errorMessage: errorMsg,
    // it's easier to tell which properties are using the shorthand by grouping them at the beginning
    // https://github.com/airbnb/javascript#objects--grouped-shorthand
    getHelpLabelFromBackend,
    isLoadedChannel: isLoadedChannel,
    isLoadingChannel: isLoadingChannel,
    isProhibitChatAutoScrollingEnabled,
    loadedImages: state.getIn(['chatState', 'loadedImages']),
    messages: messages,
    pagination: pagination,
    selectedChannel: selectedChannel,
    selectedChannelId: selectedChannelId,
    usersEntities: state.getIn(['entities', 'users']),
    wsStatus: state.get('ws'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  connectWebSocket: () => {
    dispatch(wsActions.connect());
  },

  // Loads the channel and depending on the type, also the first batch of messages
  loadConversation: ({
    channelId = '',
    channelType = '',
    intent = '',
    isSupportChannel = false,
    shouldStartWithQrParams = false,
    withUserId = null,
  }) => {
    if (isSupportChannel) {
      // Barista communicates right away via websocket so no need to load messages after
      dispatch(
        baristaThunks.loadSupportChannel(intent, shouldStartWithQrParams)
      ).then((channel) => {
        // DEV-10986 when we load a conversation by intent
        // there might be a weird race condition where WS channel is not open yet
        // and we ight have received messages that we didn't get
        if (intent) {
          const loadPaginatedResults = false;
          dispatch(
            chatThunks.loadMessages(
              channel.id,
              channel.type,
              loadPaginatedResults,
              ownProps.isChannelAdmin
            )
          );
        }
      });
    } else if (withUserId) {
      dispatch(socializeThunks.loadDirectChannelForUserId(withUserId)).then(
        (channel) => {
          dispatch(chatThunks.loadMessages(channel.id, channel.type));
        }
      );
    } else if (channelId && channelType) {
      const useMultipleRecover =
        ownProps.isApprovalConversation || ownProps.isCaseMgmt;
      dispatch(
        socializeThunks.loadChannel(
          channelId,
          channelType,
          useMultipleRecover,
          ownProps.isChannelAdmin
        )
      ).then((channel) => {
        const loadPaginatedResults = false;
        dispatch(
          chatThunks.loadMessages(
            channel.id,
            channel.type,
            loadPaginatedResults,
            ownProps.isChannelAdmin
          )
        );

        // Mark support channel as loaded
        if (channel.type === ChannelTypes.SUPPORT_CHANNEL) {
          dispatch(baristaActions.selectSupportChannel(channel.id));
        }
      });
    } else {
      // for DEV-19436 might just print the error but at least if another life cycle
      // fails to load then this doesn't blow the page
      // eslint-disable-next-line no-console --  DEV-19436
      console.error(
        'ChatRoom needs to have either of these props: withUserId, channelId or supportChannelStart'
      );
    }
  },

  loadMessages: (channelID, channelType, loadPaginatedResults = false) => {
    dispatch(
      chatThunks.loadMessages(
        channelID,
        channelType,
        loadPaginatedResults,
        ownProps.isChannelAdmin
      )
    );
  },

  sendApiActionResponse: (selection, message) => {
    dispatch(chatThunks.sendApiActionResponse(selection, message)).then(() => {
      dispatch(chatThunks.confirmClick(message.get('id')));
    });
  },

  sendData: (data) => {
    if (!ownProps.isChannelAdmin) {
      // Admin are read only and should not transfer stuff via ws
      dispatch(wsActions.send(data));
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
