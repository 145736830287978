import { fromJS } from 'immutable';
// Actions
import wfIntlActions from './wfIntlActions';
// Utils
import endpointGenerator from '../utils/endpointGenerator';
import APIcall from '../utils/APIcall';
import hashUtils from '../utils/hashUtils';
// Packages
import EspFilters from 'esp-util-filters';

const createNewTranslationKey = (term, prefix) => {
  const sanitizedTerm = term // API limitations in defining key
    .replace(/\s/g, '_') // no spaces
    .replace(/\W/g, '') // no non alphanumeric
    .replace(/__*/g, '_'); // no double under scores
  const newKey = `${prefix}.${sanitizedTerm}.${hashUtils.createRandomId(4)}`;
  return newKey;
};
const wfIntlThunks = {};
wfIntlThunks.loadTask = (taskId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(wfIntlActions.loadTaskStart(taskId));

    return APIcall.get({
      token: true,
      url: endpointGenerator.genPath('workflow.tasks.instance', {
        taskID: taskId,
      }),
    })
      .then(({ body }) => {
        dispatch(wfIntlActions.loadTaskSuccess(taskId, body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(wfIntlActions.loadTaskError(taskId, error));
        reject(error);
      });
  });

wfIntlThunks.loadLocalizationsForKey = (translationKey) => (dispatch) =>
  new Promise((resolve, reject) => {
    const espFilter = new EspFilters().equalTo('key', translationKey);

    dispatch(wfIntlActions.loadLocalizationsForKeyStart(translationKey));

    APIcall.get({
      query: {
        esp_filters: espFilter.asQueryString(),
      },
      token: true,
      url: endpointGenerator.genPath('commons.localization'),
    })
      .then(({ body }) => {
        let { results } = body;

        // DEV-8103 Treat double underscore in translation key as data error
        // as it produces wrong query results in the API
        // this will make the app generate a new key
        if (translationKey.includes('__')) {
          results = [];
        }

        dispatch(
          wfIntlActions.loadLocalizationsForKeySuccess(translationKey, results)
        );
        resolve(results);
      })
      .catch((error) => {
        dispatch(
          wfIntlActions.loadLocalizationsForKeyError(translationKey, error)
        );
        reject(error);
      });
  });

wfIntlThunks.generateNewTranslationKey = (term) => (dispatch, getState) =>
  new Promise((resolve) => {
    const state = getState();

    // optionally we try to get the current selected task eid
    const currentSelectedTaskId = state.getIn(['wfIntl', 'editorSelectedTask']);
    const task = state.getIn(['wfIntl', 'editorTasks', currentSelectedTaskId]);
    const taskEid = task && task.get('eid');

    const newProposedKey = createNewTranslationKey(term, taskEid);
    dispatch(wfIntlActions.proposeNewKey(newProposedKey));
    resolve(newProposedKey);
  });

wfIntlThunks.saveNewTranslation = (lang, translationKey, text) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(wfIntlActions.saveTranslationStart(translationKey));

    APIcall.post({
      data: {
        application: 'workflow',
        key: translationKey,
        language: lang,
        text: text,
      },
      token: true,
      url: endpointGenerator.genPath('commons.localization'),
    })
      .then(({ body }) => {
        dispatch(wfIntlActions.saveTranslationSuccess(translationKey, body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(wfIntlActions.saveTranslationError(translationKey, error));
        reject(error);
      });
  });

wfIntlThunks.editTranslation = (translationId, text, translationKey) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    dispatch(wfIntlActions.saveTranslationStart(translationKey));
    APIcall.patch({
      data: {
        key: translationKey,
        text: text,
      },
      token: true,
      url: endpointGenerator.genPath('commons.localization.instance', {
        localizationID: translationId,
      }),
    })
      .then(({ body }) => {
        dispatch(wfIntlActions.saveTranslationSuccess(translationKey, body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(wfIntlActions.saveTranslationError(translationKey, error));
        reject(error);
      });
  });

wfIntlThunks.patchFrontEndBlob = (taskId, blobKeyPath = [], intlKey) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();

    const taskIdAsString = taskId.toString();
    // the object we'll send to the server
    const task = state.getIn(['wfIntl', 'editorTasks', taskIdAsString]);
    if (task && task.get('id').toString() === taskIdAsString) {
      let frontEndBlob = task.get('frontend_blob');

      // We save the key inside [[ ]] to later identifier when we render the view
      const intlKeyInDoubleBrackets = `[[${intlKey}]]`;
      frontEndBlob = frontEndBlob.updateIn(
        blobKeyPath,
        () => intlKeyInDoubleBrackets
      );
      frontEndBlob = frontEndBlob.toJS();

      // Registers the key into the frontend_intl object of the task.
      // The BE needs this list to know what translations are being used by the workflow
      const frontEndIntlKeys = task
        .getIn(['frontend_intl', 'keys'], fromJS([]))
        .toJS();
      frontEndIntlKeys.push(intlKey);

      APIcall.patch({
        data: {
          frontend_blob: frontEndBlob,
          frontend_intl: {
            keys: frontEndIntlKeys,
          },
        },
        error(error) {
          dispatch(wfIntlActions.loadTaskError(taskId, error));
          reject(error);
        },
        success({ body }) {
          dispatch(wfIntlActions.loadTaskSuccess(taskId, body));
          resolve(body);
        },
        token: true,
        url: task.get('url'),
      });
    }
  });

export { createNewTranslationKey };
export default wfIntlThunks;
