const OnboardActivityTemplateTypes = [
  {
    key: 'STANDARD',
    text: 'Standard',
    value: 'STANDARD',
  },
  {
    key: 'WEB_LINK',
    text: 'Web Link',
    value: 'WEB_LINK',
  },
  {
    key: 'PROFILE_ACTIVITY',
    text: 'Link to Profile',
    value: 'PROFILE_ACTIVITY',
  },
  {
    key: 'CHAT_REMINDER',
    text: 'Chat Reminder',
    value: 'CHAT_REMINDER',
  },
];

export default OnboardActivityTemplateTypes;
