import React, { useCallback } from 'react';
import pt from 'prop-types';
import { Button, Divider, Icon, List, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Boundaries } from 'cascara-middleware';
import OffboardingActivityTemplateListItem from './OffboardingActivityTemplateListItem';
import { Waypoint } from 'react-waypoint';
import { intl } from 'esp-util-intl';
import uiPathGenerator from '../../../utils/uiPathGenerator';
import ScrollArea from '../../atoms/ScrollArea';

const propTypes = {
  isTemplatesLoading: pt.bool,
  loadMoreTemplates: pt.func,
  nextTemplate: pt.string,
  templateID: pt.oneOfType([pt.string, pt.number]),
  templates: pt.arrayOf(pt.shape()),
};

const OffboardingTemplates = ({
  isTemplatesLoading,
  loadMoreTemplates,
  nextTemplate,
  templates,
  templateID,
}) => {
  const handleBottomWaypointEnter = useCallback(() => {
    if (loadMoreTemplates) {
      loadMoreTemplates();
    }
  }, [loadMoreTemplates]);
  const templateSelected = Number(templateID);
  const showLoading = isTemplatesLoading && templates.length > 0;

  return (
    <div
      className='onboarding-activity-template-list'
      style={{
        height: '100%',
        margin: '-1em',
        minHeight: '70vh',
      }}
    >
      <Button
        as={Link}
        basic
        content='Create New Template'
        fluid
        style={{
          margin: '1em',
          width: 'calc(100% - 2em)',
        }}
        to={uiPathGenerator.genPath(
          'admin.adminActivities.offboarding.templates',
          {
            templateID: 'new',
          }
        )}
      />
      {templates.length > 0 ? (
        <ScrollArea height='calc(100% - 52px)'>
          <List divided relaxed='very'>
            {/* NOTE: image: this should come from the category images */}
            {/* NOTE: action: this should be */}
            {/* TODO: format SLA with moment.js */}
            {/* TODO: set 'active' prop if the detail view is active. */}
            {/* TODO: menu: this prop needs to get added to EspListItem as a node. This will allow us to render a dropdown with options. */}
            {/* TODO: attachedHeader: this prop needs to get added to EspListItem as a string. It will render the internal description at the top. */}
            {/* Note: Need to pass activeClassName with empty string in order to avoid to pass the ".active" class added by React Router */}
            {templates.map((item) => (
              <Boundaries key={item.eid}>
                <OffboardingActivityTemplateListItem
                  active={item.id === templateSelected}
                  item={item}
                />
              </Boundaries>
            ))}

            {nextTemplate && !isTemplatesLoading && (
              <Waypoint
                key={templates.length}
                onEnter={handleBottomWaypointEnter}
              />
            )}

            {showLoading && (
              <Message icon>
                <Icon loading name='circle notched' />
                <Message.Content>
                  {intl.formatMessage({
                    id: 'label.loading_more',
                  })}
                </Message.Content>
              </Message>
            )}
          </List>

          <Divider hidden />
        </ScrollArea>
      ) : (
        <Message
          content='Click the button above to add your first onboard task template.'
          info
        />
      )}
    </div>
  );
};

OffboardingTemplates.propTypes = propTypes;

export default OffboardingTemplates;
