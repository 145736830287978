const api_barista_v0_1_faqs_general_04e9e7de_6415_48db_9685_cbe1eb137710 = {
  acknowledgments: [],
  activation_condition: null,
  active: true,
  additional_text: [
    {
      additional_text: '',
      eid: 'c959933c-58e9-4fd4-bc71-e536cca36cdd',
      match_validation: false,
    },
  ],
  answer_expiry_info: {
    earliest_answer_expiry: null,
    total_expired_active_answers: 0,
  },
  answers: [
    {
      active: true,
      condition: '',
      eid: '4219fa40-b915-420a-8c4b-7488dfee4443',
      else_condition: false,
      end_date: null,
      is_protected: false,
      lines: [
        {
          active: true,
          eid: '58b480b1-7aad-440e-a437-879122f5de01',
          is_protected: false,
          order: 1,
          phrase: 'Response sample',
        },
      ],
      start_date: null,
      type: null,
    },
  ],
  application_active: true,
  application_eid: '6a9f0e6b-5402-4f81-8551-0f8dfba2a174',
  application_name: '1_DEMO',
  attributes: [
    {
      eid: '26b0405d-f559-4590-afb8-91a9e70c074c',
      name: 'service_department',
      value: '87561ee1-ed72-4757-9e49-80a41bb36a3b',
    },
  ],
  case_feedback: 0.0,
  cases_created: 0,
  deflected: 0.0,
  description: '',
  eid: '04e9e7de-6415-48db-9685-cbe1eb137710',
  helpful: 0,
  is_kb: true,
  is_protected: false,
  is_reviewed: false,
  last_used: '',
  name: '1_DEMO override',
  not_helpful: 0,
  sys_created_by: 'espbeta02@gmail.com',
  sys_date_created: '2020-07-31T16:42:18.952695Z',
  sys_date_updated: '2020-07-31T16:42:19.141218Z',
  sys_updated_by: 'espbeta02@gmail.com',
  total_completions: 0,
  triggered_misspell_total: 0,
  triggered_total: 0,
  triggers: [],
  triggers_total: 0,
};

export default api_barista_v0_1_faqs_general_04e9e7de_6415_48db_9685_cbe1eb137710;
