// Selectors
import getIntlMessages from './selectors/getIntlMessages';

// Globals
import locales from './globals/locales';

let messages = {};
let language = '';

const intlProvider = (locale, supportedLanguages = locales) => {
  if (!supportedLanguages || typeof supportedLanguages !== 'object') {
    throw new TypeError('Supported languages must be provided');
  }

  if (!locale && typeof locale !== 'string') {
    throw new TypeError('A local must be provided');
  }

  language = locale.replace(/[-_][a-zA-Z]+$/, '');
  messages = getIntlMessages(supportedLanguages, locale);
};

export default intlProvider;

export { language, messages };
