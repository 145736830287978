import { change } from 'redux-form';
import _ from 'lodash';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';

// Selector
import getCurrentUser from '../../selectors/getCurrentUser';

// Action
import workflowThunks from '../../actions/workflowThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state) => ({
  code: state.getIn(['form', 'Form01', 'values', 'code']),
  currentUser: getCurrentUser(state),
  frontendScratch: state.getIn(['workflowState', 'frontendScratch']),
  method: state.getIn(['form', 'Form01', 'values', 'OTP']),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeValueValidOTP() {
    dispatch(change('Form01', 'validOTP', 'valid')); // Reset the form value if exist
  },

  sendOTP: (method, cb = _.noop) => {
    dispatch(change('Form01', 'code', '')); // Reset the form value if exist
    dispatch(workflowThunks.sendOTP(method, cb));
  },

  successOTPSend: () => {
    dispatch(
      toastNotificationsActions.success({
        message: ownProps.intl.formatMessage({
          id: 'message.new_code_been_sent',
        }),
        title: ownProps.intl.formatMessage({
          id: 'label.success',
        }),
      })
    );
  },

  validateOTP: (code, cb = _.noop) => {
    dispatch(workflowThunks.validateOTP(code, cb));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
