import { createSelector } from 'reselect';
import { find, findKey, first, split } from 'lodash';
import IntlUtils from '../utils/IntlUtils';
import { i18Locales, locales } from 'esp-util-intl';

// return the current selected locale name e.g. en-US
const getLocale = (state) => state.getIn(['intl', 'locale']);

// return tenant multi language support
const getMultiLanguageConfig = (state) =>
  state.getIn(['intl', 'isTenantMultiLanguageSupported']) || false;

// returns the locale used in the FE blob translations used in packages (cards and workflows)
const getFEBlobLocaleKey = createSelector([getLocale], (locale) => {
  let res = findKey(i18Locales, (k) => k === locale);
  // get only the first half of the locale ("es" for "es_MX") since this is the way
  // is defined in frontend_blob
  res = res && first(split(res, '_'));
  return res || locale;
});

const getIntlMessages = (locale) => {
  // Will pick the locale language based on this priority:
  // 1. formatted locale match
  // 2. language family. e.g. "en-GB" will default to the only locale of the "en" family we have, which is "en-US" (DEPRECATED in most cases)
  // 3. en-US by default if the first two options didn't match results

  const formattedLocale = locale.replace('-', '_').toLowerCase();

  const messages =
    locales[formattedLocale] ||
    find(
      locales,
      (json, localeKey) =>
        first(split(localeKey, '-')) === first(split(locale, '-'))
    ) ||
    locales[i18Locales.en_us];
  return IntlUtils.flattenMessages(messages);
};

const selector = createSelector([getLocale], getIntlMessages);

export default selector;
export { getLocale };
export { getMultiLanguageConfig };
export { getFEBlobLocaleKey };
