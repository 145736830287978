import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureTables extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M86 29.6c0-6.13-17-11.1-38-11.1s-38 5-38 11.1c0 5.94 16 10.77 36 11.09v28.45c-6.72.33-11.84 2-11.84 4.08 0 2.29 6.35 4.14 14.18 4.14s14.18-1.86 14.18-4.14c0-2.1-5.37-3.84-12.33-4.11V40.68C70.17 40.37 86 35.51 86 29.6z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogFurnitureTables;
