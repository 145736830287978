import buildCompositeController from '../../utils/buildCompositeController';
import systemVersionThunks from '../../actions/systemVersionThunks';

const mapStateToProps = (state) => ({
  systemId: state.getIn(['appVersion', 'systemId']),
});

const mapDispatchToProps = (dispatch) => ({
  getVersion() {
    const isEncodeForced = true;
    dispatch(systemVersionThunks.getVersion(isEncodeForced));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
