import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Header, Table } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link, NavLink } from 'react-router-dom';
import { List } from 'immutable';

// utils
import uiPathGenerator from '../../utils/uiPathGenerator';
import injectIntlToController from '../../utils/injectIntlToController';
import dateFormat from '../../utils/dateFormat';

class OnboardingActivityScheduleTableList extends PureComponent {
  static propTypes = {
    /** Delete on Schedule Activity */
    deleteOneScheduleActivity: PropTypes.func.isRequired,
    /** Schedule id in process to be deleted */
    loadingDeleteID: PropTypes.number,
    /** Immutable List of each element */
    tableList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    loadingDeleteID: null,
    tableList: List(),
  };

  render() {
    const {
      deleteOneScheduleActivity,
      loadingDeleteID,
      tableList,
    } = this.props;

    const handleDelete = (scheduleID, name) => () => {
      const msg = {
        header: 'Success',
        message: `The ${name} activity has been deleted`,
      };
      deleteOneScheduleActivity(scheduleID, msg);
    };

    const finalTableList = tableList.map((list) => {
      const linkTo = uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.schedule',
        {
          scheduleID: list.get('id'),
        }
      );
      const linkToCopy = uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.schedule',
        {
          action: 'copy',
          scheduleID: list.get('id'),
        }
      );

      const deletedLoading = loadingDeleteID === list.get('id');

      return (
        <Table.Row key={list.get('id')}>
          <Table.Cell selectable width={7}>
            <Header as={NavLink} to={linkTo}>
              <Header.Content>{list.get('name')}</Header.Content>
              <Header.Subheader>{list.get('description')}</Header.Subheader>
            </Header>
          </Table.Cell>
          <Table.Cell width={3}>
            {dateFormat(list.get('sys_date_updated'), true)}
          </Table.Cell>
          <Table.Cell width={3}>{list.get('status')}</Table.Cell>
          <Table.Cell textAlign='right' width={3}>
            <Button.Group basic>
              <Button as={Link} content='View' to={linkTo} />
              <Button as={Link} content='Copy' to={linkToCopy} />
              <Button
                content='Delete'
                loading={deletedLoading}
                onClick={handleDelete(list.get('id'), list.get('name'))}
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table columns={4} compact striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Title'}</Table.HeaderCell>
            <Table.HeaderCell>{'Updated'}</Table.HeaderCell>
            <Table.HeaderCell>{'Status'}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
          {finalTableList}
        </Table.Header>
      </Table>
    );
  }
}

export const OnboardingActivityScheduleTableListTest = OnboardingActivityScheduleTableList;

export default injectIntlToController(OnboardingActivityScheduleTableList);
