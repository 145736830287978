import _APIcall from '../../../v1/utils/APIcall';
import endpointGenerator from '../../../v1/utils/endpointGenerator';

/**
 *
 * @param {string} userID userid value to get users's settings
 * @returns {Promise}  returns api promise result
 */
const fetchUserSettings = (userID) =>
  _APIcall.get({
    token: true,
    url: endpointGenerator.genPath('espUser.users.instance.userSettings', {
      userID,
    }),
  });

export default fetchUserSettings;
