import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Flex, Table } from 'cascara-middleware';
import pt from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Grid,
  Header,
  Input,
  List,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import toastNotificationsActions from '../../../../../actions/toastNotificationsActions';
import APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import uiPathGenerator from '../../../../../utils/uiPathGenerator';
import InputDescription from '../../settings/CustomBrandingPage/InputDescription';

const botId = 'botId';
const botSecret = 'botSecret';
const appId = 'appId';
const sendToAll = 'sendToAll';

const labels = {
  [appId]: 'App ID',
  [botId]: 'Bot ID',
  [botSecret]: 'Bot Secret',
  [sendToAll]: 'Force send to all users',
};

const descriptions = {
  [sendToAll]:
    'This will trigger the Welcome message to go to all users, even if they have previously received a Welcome message',
};

const validationSchema = object().shape({
  [appId]: string().required('App ID is required'),
  [botId]: string().required('Bot ID is required'),
  [botSecret]: string().required('Bot Secret is required'),
});

const redactedSecret = '***************';

const SuccessIcon = <List.Icon color='green' name='check' size='large' />;

const FailIcon = <List.Icon color='red' name='delete' size='large' />;

const checkStatusPath = endpointGenerator.genPath(
  'espBaristaBot.teams.checkStatus'
);

const azureRoute = uiPathGenerator.genPath('admin.integrations.azure.basicSSO');

const useTeamsSdkStatus = () => {
  return useQuery([checkStatusPath], async () => {
    const response = await APIcall.get({
      token: true,
      url: checkStatusPath,
    });

    const {
      teams: isTeamsConnected,
      azure: isAzureConnected,
      bot_id,
      app_id,
    } = response.body;

    return {
      appId: app_id,
      botId: bot_id,
      botSecret: isTeamsConnected ? redactedSecret : '',
      isAzureConnected,
      isTeamsConnected,
    };
  });
};

const useAddTeamsSdk = () => {
  return useMutation(async (values) => {
    await APIcall.post({
      data: {
        app_id: values[appId],
        bot_id: values[botId],
        bot_secret: values[botSecret],
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.teams.addTeamsSdk'),
    });
  });
};
const usePatchAddTeamsSdk = () => {
  return useMutation(async (values) => {
    await APIcall.patch({
      data: {
        app_id: values[appId],
        bot_id: values[botId],
        bot_secret: values[botSecret],
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.teams.addTeamsSdk'),
    });
  });
};

const azureGroupsPath = endpointGenerator.genPath(
  'espBaristaBot.teams.azureGroups'
);

const useAzureGroups = () => {
  return useQuery([azureGroupsPath], () =>
    APIcall.get({
      token: true,
      url: azureGroupsPath,
    })
  );
};

const useDeleteAzureGroup = () => {
  return useMutation(async (group) => {
    await APIcall.delete({
      query: {
        group_id: group,
      },
      token: true,
      url: azureGroupsPath,
    });
  });
};
const usePostAzureGroup = () => {
  return useMutation(async (group) => {
    await APIcall.post({
      data: {
        azure_groups: group,
      },
      token: true,
      url: azureGroupsPath,
    });
  });
};

const useSendWelcomeMessage = () => {
  return useMutation(async ({ selectedGroup, sendToAllValue }) => {
    await APIcall.post({
      data: {
        azure_groups: selectedGroup,
        send_to_everyone: sendToAllValue,
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.teams.sendWelcomeMessage'),
    });
  });
};

const propTypes = {
  welcomeMessageSuccessToast: pt.func.isRequired,
};

const TeamsIntegrationSdkConnection = ({ welcomeMessageSuccessToast }) => {
  const queryClient = useQueryClient();
  const { data: connectionStatus, isLoading } = useTeamsSdkStatus();
  const { mutateAsync: addTeamsSdk, isLoading: isAddLoading } =
    useAddTeamsSdk();
  const { mutateAsync: patchAddTeamsSdk, isLoading: isPatchAddLoading } =
    usePatchAddTeamsSdk();
  const { data: azureGroups, isLoading: isLoadingGroups } = useAzureGroups();
  const { mutateAsync: deleteAzureGroup, isLoading: isDeleteGroupLoading } =
    useDeleteAzureGroup();
  const { mutateAsync: postAzureGroup, isLoading: isPostGroupLoading } =
    usePostAzureGroup();
  const {
    mutateAsync: sendWelcomeMessage,
    isLoading: isPostLoadingWelcomeMessage,
  } = useSendWelcomeMessage();

  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isWelcomeMessageOpen, setIsWelcomeMessageModalOpen] = useState(false);
  const [newGroupId, setNewGroupId] = useState('');
  const [sendToAllValue, setSendToAllValue] = useState(false);

  const saveNewGroup = useCallback(async () => {
    await postAzureGroup(newGroupId);
    await queryClient.invalidateQueries([azureGroupsPath]);
    setIsNewGroupModalOpen(false);
    setNewGroupId('');
  }, [newGroupId, postAzureGroup, queryClient]);

  const handleSendWelcomeMessage = useCallback(async () => {
    if (!selectedGroup) {
      return;
    }
    await sendWelcomeMessage({ selectedGroup, sendToAllValue });
    welcomeMessageSuccessToast();
    setIsWelcomeMessageModalOpen(false);
    setSelectedGroup('');
  }, [
    selectedGroup,
    sendToAllValue,
    sendWelcomeMessage,
    welcomeMessageSuccessToast,
  ]);

  const handleGroupAction = useCallback(
    async (action, record) => {
      switch (action.actionName) {
        case 'sendWelcomeMessage':
          setIsWelcomeMessageModalOpen(true);
          setSelectedGroup(record.id);
          break;
        case 'delete':
          await deleteAzureGroup(record.id);
          await queryClient.invalidateQueries([azureGroupsPath]);
          break;
        default:
          break;
      }
    },
    [deleteAzureGroup, queryClient]
  );

  const handleReset = useCallback(() => {}, []);
  const handleSubmit = useCallback(
    async (/* object */ values, /* FormikActions<object> */ actions) => {
      actions.setSubmitting(true);
      // await updateTeamsSdkConnection(sdkSettings, values);
      try {
        if (!connectionStatus?.isTeamsConnected) {
          await addTeamsSdk(values);
        } else {
          const changedValues = {};
          if (values[appId] !== connectionStatus?.appId) {
            changedValues[appId] = values[appId];
          }
          if (values[botId] !== connectionStatus?.botId) {
            changedValues[botId] = values[botId];
          }
          if (values[botSecret] !== connectionStatus?.botSecret) {
            changedValues[botSecret] = values[botSecret];
          }
          await patchAddTeamsSdk(changedValues);
        }
        await queryClient.invalidateQueries(checkStatusPath);
      } catch (error) {
        // eslint-disable-next-line no-console -- debug
        console.log('error', error);
      }
      actions.resetForm();
      actions.setSubmitting(false);
    },
    [
      addTeamsSdk,
      connectionStatus?.appId,
      connectionStatus?.botId,
      connectionStatus?.botSecret,
      connectionStatus?.isTeamsConnected,
      patchAddTeamsSdk,
      queryClient,
    ]
  );

  const handleSendWelcomeClose = useCallback(() => {
    setIsWelcomeMessageModalOpen(false);
    setSendToAllValue(false);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [appId]: connectionStatus?.appId,
        [botId]: connectionStatus?.botId,
        [botSecret]: connectionStatus?.botSecret,
      }}
      onReset={handleReset}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <>
          <Grid as={Segment} attached='bottom' loading={isLoading}>
            <Grid.Row columns='equal' style={{ paddingLeft: '1em' }}>
              <Grid.Column>
                <Message
                  content='Contact your customer success advisor to provide the values needed for configuration.'
                  header='Installation Instructions'
                  info
                />
                {!connectionStatus?.isAzureConnected && (
                  <Message
                    content={
                      <p>
                        {' '}
                        {
                          'Before you can connect to Teams you need to successfully integrate with an MS Azure App Registration. To configure the app registration,'
                        }{' '}
                        <NavLink to={azureRoute}>{'click here.'}</NavLink>
                      </p>
                    }
                    header='MS Azure App Registration not configured'
                    warning
                  />
                )}

                <Segment>
                  <Form>
                    <Form.Field
                      error={formikProps.errors[botId]}
                      style={{ flexDirection: 'column', marginTop: '1em' }}
                    >
                      <label>{labels[botId]}</label>

                      <Input
                        id={botId}
                        name={botId}
                        // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                        onChange={(event, data) =>
                          formikProps.setFieldValue(
                            botId,
                            data.value.replace(/\s/g, '')
                          )
                        }
                        type='text'
                        value={formikProps.values[botId]}
                      />
                    </Form.Field>
                    <Form.Field
                      error={formikProps.errors[botSecret]}
                      style={{ flexDirection: 'column', marginTop: '1em' }}
                    >
                      <label>{labels[botSecret]}</label>

                      <Input
                        id={botSecret}
                        name={botSecret}
                        // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                        onChange={(event, data) =>
                          formikProps.setFieldValue(
                            botSecret,
                            data.value.replace(/\s/g, '')
                          )
                        }
                        type='password'
                        value={formikProps.values[botSecret]}
                      />
                    </Form.Field>
                    <Form.Field
                      error={formikProps.errors[appId]}
                      style={{ flexDirection: 'column', marginTop: '1em' }}
                    >
                      <label>{labels[appId]}</label>
                      <Input
                        id={appId}
                        name={appId}
                        // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                        onChange={(event, data) =>
                          formikProps.setFieldValue(
                            appId,
                            data.value.replace(/\s/g, '')
                          )
                        }
                        type='text'
                        value={formikProps.values[appId]}
                      />
                    </Form.Field>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Header as='h4' content='Status' />
                <List
                  items={[
                    {
                      content: 'Azure Configured',
                      icon: connectionStatus?.isAzureConnected
                        ? SuccessIcon
                        : FailIcon,
                      key: '0',
                    },
                    {
                      content: 'Teams Configured',
                      icon: connectionStatus?.isTeamsConnected
                        ? SuccessIcon
                        : FailIcon,
                      key: '1',
                    },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
            {connectionStatus?.isTeamsConnected && (
              <Grid.Row columns='equal' style={{ paddingLeft: '1em' }}>
                <Grid.Column>
                  <Segment>
                    <Header as='h4' content='Add Azure Group' />
                    <Flex space='between' vAlign='center'>
                      <Header.Subheader>
                        <p>
                          Members and users in these groups will receive
                          messages and ITSM ticket updates from Barista.
                        </p>
                        <Message
                          content={
                            <p>
                              {
                                'Barista only supports the following types of groups: Security, Mail-enabled security, Distribution, and Microsoft 365'
                              }
                            </p>
                          }
                        />
                      </Header.Subheader>
                      <div>
                        <Button
                          /* eslint-disable-next-line react/jsx-no-bind -- its ok*/
                          onClick={() => setIsNewGroupModalOpen(true)}
                          primary
                        >
                          Add
                        </Button>
                      </div>
                    </Flex>
                    {(isLoadingGroups ||
                      isDeleteGroupLoading ||
                      isPostGroupLoading) && (
                      <Dimmer active>
                        <Loader />
                      </Dimmer>
                    )}
                    <Table
                      actions={{
                        actionButtonMenuIndex: -1,
                        modules: [
                          {
                            actionName: 'sendWelcomeMessage',
                            content: 'Send Welcome Message',
                            module: 'button',
                            size: 'small',
                          },
                          {
                            actionName: 'delete',
                            content: 'Delete',
                            module: 'button',
                            size: 'small',
                          },
                        ],
                      }}
                      celled
                      compact
                      data={azureGroups?.body?.current_azure_groups?.value?.map(
                        (group) => ({
                          displayName: group.displayName,
                          id: group.id,
                          memberCount: group.memberCount,
                        })
                      )}
                      dataDisplay={[
                        {
                          attribute: 'displayName',
                          isEditable: false,
                          label: 'Name',
                          module: 'text',
                        },
                        {
                          attribute: 'id',
                          isEditable: false,
                          label: 'ID',
                          module: 'text',
                        },
                        {
                          attribute: 'memberCount',
                          isEditable: false,
                          label: 'Members',
                          module: 'text',
                        },
                      ]}
                      onAction={handleGroupAction}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )}
            <Segment attached='bottom' secondary textAlign='right'>
              <Button
                content='Cancel'
                disabled={!formikProps.dirty}
                id='cancel_button'
                onClick={formikProps.handleReset}
                type='button'
              />
              <Button
                content='Save'
                disabled={formikProps.isSubmitting || !formikProps.dirty}
                id='save_button'
                loading={isPatchAddLoading || isAddLoading}
                onClick={formikProps.handleSubmit}
                primary
                type='button'
              />
            </Segment>
          </Grid>
          <Modal open={isNewGroupModalOpen}>
            <Modal.Header content='Add Azure Group' />
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label htmlFor='newGroupId'>Group ID</label>
                  <Input
                    name='newGroupId'
                    // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                    onChange={(event, data) => setNewGroupId(data.value)}
                    value={newGroupId}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content='Cancel'
                // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                onClick={() => {
                  setIsNewGroupModalOpen(false);
                  setNewGroupId('');
                }}
              />
              <Button
                content='Save'
                disabled={!newGroupId}
                onClick={saveNewGroup}
                primary
              />
            </Modal.Actions>
          </Modal>
          <Modal
            closeIcon
            onClose={handleSendWelcomeClose}
            open={isWelcomeMessageOpen}
          >
            <Modal.Header content='Confirm Send Welcome Message' />
            <Modal.Content>
              <p>
                Are you sure you want to send a welcome message to
                {` "${selectedGroup}"`} group?
              </p>

              <Form.Field style={{ flexDirection: 'column', marginTop: '1em' }}>
                <Checkbox
                  checked={sendToAllValue}
                  fitted
                  label={labels[sendToAll]}
                  name={sendToAll}
                  // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                  onChange={() => setSendToAllValue((prevValue) => !prevValue)}
                />
                <InputDescription description={descriptions[sendToAll]} />
              </Form.Field>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content='Cancel'
                // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                onClick={() => setIsWelcomeMessageModalOpen(false)}
              />
              <Button
                content={sendToAllValue ? 'Send to all' : 'Send'}
                loading={isPostLoadingWelcomeMessage}
                onClick={handleSendWelcomeMessage}
                primary
              />
            </Modal.Actions>
          </Modal>
        </>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  welcomeMessageSuccessToast: () =>
    dispatch(
      toastNotificationsActions.success({
        message: 'Welcome Message successfully sent',
        title: 'Success',
      })
    ),
});

TeamsIntegrationSdkConnection.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(TeamsIntegrationSdkConnection);
