import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const actionNewRecordLabel = 'New Record';
const actionNewRecordPath = 'new';

const useActionNewRecord = ({ isChild = false } = {}) => {
  const navigate = useNavigate();
  const handleNavigateNew = useCallback(
    () => navigate(isChild ? `../${actionNewRecordPath}` : actionNewRecordPath),
    [isChild, navigate]
  );

  return {
    handler: handleNavigateNew, // This is to prepare for future actions improvements
    label: actionNewRecordLabel,
    onClick: handleNavigateNew,
  };
};

export { actionNewRecordLabel, actionNewRecordPath };
export default useActionNewRecord;
