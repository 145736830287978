import { change, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import _ from 'lodash';

// Thunk
import searchThunks from '../../actions/searchThunks';
import workflowThunks from '../../actions/workflowThunks';
import userEntitiesThunks from '../../actions/userEntitiesThunks';
// Action
import searchActions from '../../actions/searchActions';
// Selector
import getSearchUser from '../../selectors/getSearchUser';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const formValues = getFormValues('Form01')(state) || fromJS({});
  const shortHandSearch = state.getIn(['search', 'userSearch']);

  const frontEndScratchContact = state.hasIn([
    'workflowState',
    'frontendScratch',
    'user_contacts',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'user_contacts'])
    : null;

  const listUsersSelected = [];

  formValues.forEach((val) => {
    listUsersSelected.push(val);
  });

  if (frontEndScratchContact) {
    frontEndScratchContact.forEach((contact) => {
      listUsersSelected.push(Number(contact));
    });
  }

  const usersSelected = getSearchUser(state, listUsersSelected);

  return {
    contextName: shortHandSearch.get('contextName'),
    formValues,
    frontEndScratchContact,
    isLoadingSearch: shortHandSearch.get('isLoading'),
    usersSearch: shortHandSearch.get('results'),
    usersSearchTerm: shortHandSearch.get('userInput'),
    usersSelected,
  };
};

// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data, cb = _.noop) {
    // We have to use .toString() here for the changes made in DEV-6012
    dispatch(change('Form01', id, data.toString()));

    cb();
  },

  deleteOneContact(id, frontEndScratchContact, cb = _.noop) {
    const name = id.toUpperCase();

    if (frontEndScratchContact && frontEndScratchContact.get(name)) {
      // Update the frontend Scratch by removing this entry
      const values = {};
      frontEndScratchContact.forEach((frt, key) => {
        if (key !== name) {
          values[key] = frt;
        }
      });

      dispatch(
        workflowThunks.updateFrontEndScratch({
          user_contacts: values,
        })
      ).then(() => {
        dispatch(change('Form01', id, ''));
        cb();
      });
    } else {
      dispatch(change('Form01', id, ''));
      cb();
    }
  },

  loadFrontEndScratchUsers(frontEndScratchContact) {
    const userIds = frontEndScratchContact.map((t) => Number(t)).toArray();

    dispatch(userEntitiesThunks.getListUsersByID(userIds)).then(() => {
      frontEndScratchContact.forEach((value, key) => {
        dispatch(change('Form01', key.toLowerCase(), value)); // Change the input value of each entry
      });
    });
  },

  searchResetResults() {
    dispatch(searchActions.setUserSearchInput(''));
  },

  searchUsers(filter) {
    dispatch(searchThunks.searchUsers(filter));
  },

  setContactGroup(data, cb) {
    const values = {};
    values.HR_RECRUITER = data.get('hr_recruiter');
    values.HR_CONTACT = data.get('hr_contact');
    values.IT_CONTACT = data.get('it_contact');
    values.ENABLEMENT = data.get('enablement');
    values.OPERATIONS = data.get('operations');

    dispatch(
      workflowThunks.updateFrontEndScratch({
        user_contacts: values,
      })
    ).then(() => cb());
  },

  setSearchInputUsers(searchTerm) {
    dispatch(searchActions.setUserSearchInput(searchTerm));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
