import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  backend: {},
  clients: {},
  compatible: '',
  electronUpdateNotAvailable: false,
  error: null,
  isLoading: false,
});

const compatibilityCheckReducer = (state, action) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.COMPATIBILITY_CHECK_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.COMPATIBILITY_CHECK_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('compatible', action.compatible)
        .set('clients', fromJS(action.clients))
        .set('backend', fromJS(action.backend));
    }

    case actionTypes.COMPATIBILITY_CHECK_ERROR: {
      return state.set('isLoading', false).set('error', action.error);
    }

    // For internal purposes only
    case actionTypes.COMPATIBILITY_FORCE_COMPATIBLE_STATUS: {
      return state.set('compatible', action.compatible);
    }

    case actionTypes.ELECTRON_UPDATE_NOT_AVAILABLE: {
      return state.set('electronUpdateNotAvailable', true);
    }

    case actionTypes.ELECTRON_UPDATE_AVAILABLE: {
      return state.set('electronUpdateNotAvailable', false);
    }

    default: {
      return state;
    }
  }
};

export default compatibilityCheckReducer;
