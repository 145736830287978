import { ALL_DEFAULT_FEED_MESSAGES } from '../../../../molecules/EmptyFeed/getFinalMessages';

const APPLICATION_VALUE = 'help_messages_home';
const DEFAULT_LANGUAGE = 'en';

const LABELS = {
  ACCORDION_BODY: `Bold - **Bold**
Italic - *Italic*
Hyperlinks -  [Link Text](https://www.espressive.com/)
Emoticons - 😀`,
  ACCORDION_TITLE: 'View Supported Formatting',
  ACTIVE: 'Active',
  ADD: 'Add',
  ADD_LANGUAGE: 'Add language',
  ADD_SECTION: 'Add section',
  ADD_TIP: 'Add tip',
  CANCEL: 'Cancel',
  COLOR_ERROR_MESSAGE: 'Tip Background Color should be a hexadecimal color',
  COLOR_FIELD: 'Tip Background Color',
  COLOR_FIELD_DESCRIPTION:
    'Input a hex value to change the background of the tip. Text color will automatically adjust to provide for appropriate contrast.',
  CONFIRM: 'confirm',
  DELETE_CONFIRM_CONTENT:
    'Are you sure you want to delete this tip? Users will no longer see this content.',
  DELETE_CONFIRM_HEADER: 'Confirm Delete',
  EDIT_TIP: 'Edit tip',
  LANGUAGE: 'Language',
  LANGUAGES: 'Languages',
  MODAL_DESCTIPTION:
    'Tips support basic formatting and emojis. New sections present as chat bubbles to the user. Shorter tips are recommended.',
  PAGE_DESCRIPTION:
    'Tips that show up on the right hand side of the web application. Display of tips is randomly generated upon user view.',
  SAVE: 'Save',
  TABLE_DESCRIPTION:
    'Defaults can be turned off for display only. They cannot be edited or deleted.',
  TIP_CONTENT: 'Tip Content',
};

const sortByActive = (a, b) => {
  if (a.isActive && !b.isActive) {
    return -1;
  }
  if (b.isActive && !a.isActive) {
    return 1;
  }
  return 0;
};

const getCustomMappedMessages = (customMessages) => {
  const mappedMessages = customMessages.map((message) => {
    const parsedJSON = JSON.parse(message.text);

    return {
      content: parsedJSON.texts.map((message) => message.text).join(' ,'),
      isActive: parsedJSON.isActive,
      messageKey: message.key,
      rawData: message,
      texts: parsedJSON.texts,
    };
  });

  return mappedMessages;
};

const getDefaultMappedMessages = ({ tenantInfo, intl }) => {
  const helpSettings = tenantInfo?.ui_config?.helpSettings;

  const defaultFeedMessagesKey = Object.keys({
    default: true,
    default2: true,
    ...tenantInfo.ui_config.helpSettings,
  });

  const defaultsSettings = {
    default: true,
    default2: true,
    ...helpSettings,
  };

  const mappedMessages = defaultFeedMessagesKey
    .map((currentMessageGroupKey) => {
      return {
        content: ALL_DEFAULT_FEED_MESSAGES[currentMessageGroupKey]
          .map((bubble) => {
            return intl.formatMessage({
              id: bubble.text,
            });
          })
          .join(' ,'),
        isActive: defaultsSettings && defaultsSettings[currentMessageGroupKey],
        messageKey: currentMessageGroupKey,
      };
    })
    .sort(sortByActive);

  return mappedMessages;
};

export {
  APPLICATION_VALUE,
  DEFAULT_LANGUAGE,
  getCustomMappedMessages,
  getDefaultMappedMessages,
  LABELS,
  sortByActive,
};
