import buildCompositeController from '../../../../utils/buildCompositeController';
// Actions
import toastNotificationsActions from '../../../../actions/toastNotificationsActions';
import wfIntlThunks from '../../../../actions/wfIntlThunks';
import wfIntlActions from '../../../../actions/wfIntlActions';

const mapStateToProps = (state) => {
  const currentSelectedTaskId = state.getIn(['wfIntl', 'editorSelectedTask']);

  return {
    currentSelectedTaskId: currentSelectedTaskId,
    isLoadingTask: state.getIn(['wfIntl', 'editorTaskIsLoading']),
    task: state.getIn(['wfIntl', 'editorTasks', currentSelectedTaskId]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTask: (taskId) => {
    dispatch(wfIntlActions.selectEditorTask(taskId));
    dispatch(wfIntlThunks.loadTask(taskId))
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: `Task id ${taskId} loaded`,
          })
        );
      })
      .catch(() => {
        dispatch(
          toastNotificationsActions.error({
            message: `Error loading task id ${taskId}`,
          })
        );
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
