import Immutable from 'immutable';

/**
 * Filter Template activities selected by interval
 * @param template
 * @returns {List}
 */

const getNameSelected = (template) => {
  const daysSelected = template.getIn(['interval', 'days']);

  // IMPORTANT: Note about legacy bug discovered in AUG_2022
  // THIS IS PART OF THE FIX for DEV-19887 for offboarding we're trying to keep consistency to the template
  // schedule activities attached before the AUG_2022 release. It turns out this bug is there since 5 years ago
  // in the onboarding schedule implementation and because the offboarding implementation uses pretty much the same code
  // and this wasn't discovered before, we inherit the bug in offboarding: departure day and the day after were considered
  // the same day.

  // This fix will set all the templates before the August 5th, 2022 with daysSelected=1 as the day of departure day.
  // After this date all the templates with a daysSelected equal to 1 will be treated under "One day after departure day" category

  const DATE_ZERO_FOR_ONE_DAY_AFTER_DEPARTURE = new Date('08/05/2022');
  const wasDayOfDeparture =
    new Date(template?.getIn(['scheduleToTemplate', 'sys_date_created'])) <
    DATE_ZERO_FOR_ONE_DAY_AFTER_DEPARTURE;

  let nameSelected;

  if (template.get('schedule_type') === 'offboarding') {
    if (daysSelected === 0 || (wasDayOfDeparture && daysSelected === 1)) {
      nameSelected = 'Day of departure';
    } else if (daysSelected === -1) {
      nameSelected = 'One day before departure day';
    } else if (daysSelected === 1) {
      nameSelected = 'One day after departure day';
    } else if (daysSelected > 0) {
      nameSelected = `${daysSelected} Days after departure day`;
    } else if (daysSelected < 0) {
      nameSelected = `${Math.abs(daysSelected)} Days before departure day`;
    }
  } else {
    if (daysSelected === 1) {
      nameSelected = 'First day';
    } else if (daysSelected === -1) {
      nameSelected = 'One day before start';
    } else if (daysSelected > 0) {
      nameSelected = `${daysSelected} Days after start`;
    } else if (daysSelected < 0) {
      nameSelected = `${Math.abs(daysSelected)} Days before start`;
    }
  }
  return nameSelected;
};

const filterTemplateSelectedByInterval = (templateSelected) => {
  let filteredList = Immutable.List();

  templateSelected.forEach((template) => {
    const nameSelected = getNameSelected(template);
    const indexFilter = filteredList.findIndex(
      (filt) => filt.get('title') === nameSelected
    );

    let activity = template.get('template');
    activity = activity.set('interval', template.get('interval'));
    activity = activity.set('templateID', template.getIn(['template', 'id']));

    if (indexFilter > -1) {
      const activitiesList = filteredList.getIn([indexFilter, 'activities']);
      activity = activity.set('key', activity.get('eid') + nameSelected);

      const activities = activitiesList.push(activity);

      filteredList = filteredList.setIn(
        [indexFilter, 'activities'],
        activities
      );
    } else {
      const newEntry = Immutable.fromJS({
        id: filteredList.size + 1,
        title: nameSelected,
      });
      activity = activity.set('key', activity.get('eid') + nameSelected);

      const newActivities = Immutable.List().push(activity);

      filteredList = filteredList.push(
        newEntry
          .set('activities', newActivities)
          .set('sortId', template.getIn(['interval', 'days']))
      );
    }
  });

  return filteredList.sortBy((temp) => temp.get('sortId'));
};

export default filterTemplateSelectedByInterval;
