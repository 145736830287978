import React, { PureComponent } from 'react';
import { Checkbox, Header, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class FaqItem extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    description: PropTypes.string,
    index: PropTypes.number,
    matchedTrigger: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    checked: false,
    description: '',
    index: null,
    matchedTrigger: '',
    name: '',
    onChange: _.noop,
  };

  handleOnChange = () => {
    const { index, onChange } = this.props;

    onChange(index);
  };

  render() {
    const { checked, description, matchedTrigger, name } = this.props;

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          padding: '0.5em 0 0.5em 0',
        }}
      >
        <Checkbox
          checked={checked}
          fitted
          onChange={this.handleOnChange}
          radio
        />

        <div
          style={{
            flexGrow: 2,
          }}
        >
          <Segment>
            <Header as='h5'>
              <Header.Content>{name}</Header.Content>
              <Header.Subheader
                style={{
                  margin: '0.5em 0 0.5em 0',
                }}
              >
                {description}
              </Header.Subheader>
            </Header>
            {`Matched Trigger: ${matchedTrigger}`}
          </Segment>
        </div>
      </div>
    );
  }
}

export default FaqItem;
