import _ from 'lodash';

const FileUtils = {
  getFileNameFromUrl: (url = '') => {
    let fileName = _.last(_.split(url, '/'));
    fileName = _.first(_.split(fileName, '?')); // gets filename before any possible '?'

    return fileName;
  },
};

export default FileUtils;
