import { connect } from 'react-redux';

import announcementThunks from '../../actions/announcementThunks';
import catalogThunks from '../../actions/catalogThunks';
import getJobRoleWithoutAll from '../../selectors/getJobRoleWithoutAll';
import getProductPermissionGroups from '../../selectors/getProductPermissionGroups';
import productPermissionsActions from '../../actions/productPermissionsActions';
import productPermissionsThunks from '../../actions/productPermissionsThunks';

const mapStateToProps = (state, ownProps) => {
  // TODO using job roles from announcements
  const jobRoles = getJobRoleWithoutAll(state);

  const { product } = ownProps;

  let productFamily = null;

  if (product && product.get('product_family')) {
    productFamily = state.getIn(
      ['catalog', 'productFamilies', product.get('product_family')],
      null
    );
  }

  const productPermissionGroups = getProductPermissionGroups(state);

  const addPermissionGroupState = state.getIn([
    'productPermissions',
    'addPermissionGroup',
  ]);
  const removePermissionGroupState = state.getIn([
    'productPermissions',
    'removePermissionGroup',
  ]);
  const savePermissionGroupState = state.getIn([
    'productPermissions',
    'savePermissionGroup',
  ]);

  return {
    addPermissionGroupState,
    jobRoles,
    productFamily,
    productPermissionGroups,
    removePermissionGroupState,
    savePermissionGroupState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addProductPermissionGroup(productID) {
    dispatch(productPermissionsThunks.addProductPermissionGroup(productID));
  },

  getProductPermissionGroups(productIDs) {
    dispatch(productPermissionsThunks.getProductPermissionGroups(productIDs));
  },

  // TODO using job roles from announcements
  loadJobRoles() {
    dispatch(announcementThunks.loadJobRoles());
  },

  loadProductFamily(productFamilyID) {
    return dispatch(catalogThunks.loadProductFamily(productFamilyID));
  },

  removeProductPermissionGroup(productPermissionGroupID) {
    dispatch(
      productPermissionsThunks.removeProductPermissionGroup(
        productPermissionGroupID
      )
    );
  },

  resetProductPermissionGroup() {
    dispatch(productPermissionsActions.resetProductPermissionGroup());
  },

  saveProductPermissionGroup(productPermissionGroupID, ruleExpression) {
    dispatch(
      productPermissionsThunks.saveProductPermissionGroup(
        productPermissionGroupID,
        ruleExpression
      )
    );
  },

  toggleProductFromPermissionGroup(groupID, productID) {
    dispatch(
      productPermissionsActions.toggleProductFromPermissionGroup(
        groupID,
        productID
      )
    );
  },
});

const AdminProductPermissionsController = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
export default AdminProductPermissionsController;
