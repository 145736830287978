import { createSelector } from 'reselect';

const getCartItems = (state) => state.getIn(['cart', 'cartItems']);

const getNumberItems = (items) => {
  let qty = 0;
  items.forEach((item) => {
    if (item.get('qty')) {
      qty += item.get('qty');
    }
  });
  return qty;
};

const selector = createSelector(getCartItems, getNumberItems);

export default selector;
