const api_reporting_v0_1_templates_top_intents_report = {
  result_details: {
    result: {
      data: [
        {
          Values: [
            {
              Count: 480,
              'Topic Name': 'who is your favorite employee',
              Type: 'Non Chit Chat',
            },
            {
              Count: 318,
              'Topic Name': 'Reset Password',
              Type: 'Non Chit Chat',
            },
            {
              Count: 292,
              'Topic Name': 'Greeting',
              Type: 'Chit Chat',
            },
          ],
          start_date: '2018-08-01T00:00:00',
        },
        {
          Values: [
            {
              Count: 75,
              'Topic Name': 'Many attachments',
              Type: 'Non Chit Chat',
            },
            {
              Count: 51,
              'Topic Name': 'hyperlinks',
              Type: 'Non Chit Chat',
            },
            {
              Count: 33,
              'Topic Name': 'Greeting',
              Type: 'Chit Chat',
            },
          ],
          start_date: '2019-02-01T00:00:00',
        },
      ],
    },
  },
  status: 'COMPLETED',
};

export default api_reporting_v0_1_templates_top_intents_report;
