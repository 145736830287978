import EspFilters from 'esp-util-filters';
import endpointGenerator from '../../../utils/endpointGenerator';
import _APIcall from '../../../utils/APIcall';

const fetchClassifiedTeam = async ({ searchQuery, departmentName, id }) => {
  const url = endpointGenerator.genPath(
    'espCaseMgmt.serviceTeamClassification'
  );

  const espFilters = new EspFilters();

  if (departmentName) {
    espFilters.equalTo('service_department', departmentName);
  }
  if (id) {
    espFilters.equalTo('id', id);
  }

  if (searchQuery) {
    const tokens = searchQuery.split(' ');

    tokens.forEach((token, index) => {
      if (index > 0) {
        espFilters.or();
      }
      espFilters.contains('name', token);
      espFilters.or();
      espFilters.contains('description', token);
    });
  }

  return _APIcall.get({
    query: {
      esp_filters: `${espFilters.asQueryString()}`,
      limit: 30,
      oder_by: 'name',
    },
    token: true,
    url: url,
  });
};

export default fetchClassifiedTeam;
