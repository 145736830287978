import buildCompositeController from '../../utils/buildCompositeController';
// Selector
import getTotalUnreadCount from '../../selectors/getTotalUnreadCount';
import getTenantMenuConfiguration from '../../selectors/getTenantMenuConfiguration';
import groupPermissions from '../../globals/groupPermissions';
import { getApprovalsPendingFeed } from '../../selectors/getCaseFeed';
// Actions
import anonResetPasswordActions from '../../actions/anonResetPasswordActions';
import appUIActions from '../../actions/appUIActions';
import casesThunks from '../../actions/casesThunks';
import getTenant from '../../../v1/selectors/getTenant';

const mapStateToProps = (state) => {
  const groupPermission = Object.assign({}, groupPermissions);

  // Add SERVICE_LEAD AND AGENT to the List
  groupPermission.SERVICE_LEAD = 'SERVICE_LEAD';
  groupPermission.SERVICE_AGENT = 'SERVICE_AGENT';

  const getHelpLabelFromBackend = state.getIn([
    'appUI',
    'menuCustomization',
    'getHelpLabel',
  ]);
  const shopLabelFromBackend = state.getIn([
    'appUI',
    'menuCustomization',
    'shopLabel',
  ]);

  const cases = getApprovalsPendingFeed(state);
  const tenant = getTenant(state);

  return {
    chatbot_name: tenant && tenant.getIn(['branding_obj', 'chatbot_name']),
    configurationTenantMenu: getTenantMenuConfiguration(state),
    getHelpLabelFromBackend,
    groupPermissions: groupPermission,
    isBaristaOpened: state.getIn(['appUI', 'isBaristaOpened']),
    isChatV2Enabled: Boolean(
      state.getIn([
        'tenant',
        'entity',
        'value',
        'ui_config',
        'helpSettings',
        'chatv2',
      ])
    ),
    passwordChanged: state.getIn(['anonResetPassword', 'passwordChanged']),
    promptResetPassword: state.getIn([
      'anonResetPassword',
      'promptResetPassword',
    ]),
    shopLabelFromBackend,
    tenant,
    unreadApprovalCount: cases?.size,
    unreadCount: getTotalUnreadCount(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeMoreMenu() {
    dispatch(appUIActions.closeMoreMenu());
  },

  closeResetPasswordModal: () => {
    dispatch(anonResetPasswordActions.dimissPromptResetPassword());
  },

  loadApprovals() {
    // Load cards in feed to get badge count
    dispatch(casesThunks.loadApprovalFeed());
  },

  toggleMoreMenu() {
    dispatch(appUIActions.toggleMoreMenu());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
