import { Confirm } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

const EspConfirmationModal = ({
  cancelButton,
  confirmButton,
  content,
  header,
  onCancel,
  onConfirm,
  open,
}) => {
  return (
    <Confirm
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      content={content}
      data-testid='confirm_modal'
      header={header}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      style={{
        height: 'auto',
        transform: 'translateX(0) translateY(-50%)',
      }}
    />
  );
};

EspConfirmationModal.propTypes = {
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.node,
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

EspConfirmationModal.defaultProps = {
  cancelButton: 'Cancel',
  confirmButton: void 0,
  content: void 0,
  header: void 0,
  onCancel: void 0,
  onConfirm: void 0,
  open: void 0,
};
export default EspConfirmationModal;
