import { change, formValueSelector } from 'redux-form/immutable';
import _ from 'lodash';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('Form01');
  const locationField = `${ownProps.id}-0`;
  const remoteWorkerField = `${ownProps.id}-1`;
  const locationID = selector(state, locationField);
  const remoteWorker = Boolean(selector(state, remoteWorkerField));
  // Make sure that we don't return NaN
  const defaultSelectedLocationID = !_.isNil(locationID)
    ? Number(locationID)
    : locationID;

  return {
    defaultSelectedLocationID,
    locationID: _.isNil(locationID) ? null : Number(locationID),
    remoteWorker,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  /** Change form location_selected value */
  changeLocationFrom(locationID) {
    dispatch(change('Form01', 'location_selected', locationID));
  },

  /** Reports selection to Redux Form */
  onSelectLocation(locationID) {
    const locationField = `${ownProps.id}-0`;
    dispatch(change('Form01', locationField, locationID));
    dispatch(change('Form01', 'location_selected', locationID));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
