import React, { Component } from 'react';

// Globals
import TypeSpacer, { TypeDefaults } from '../../../globals/blocks/TypeSpacer';

class Space01 extends Component {
  static propTypes = TypeSpacer;
  static defaultProps = TypeDefaults;

  render() {
    const { type, template } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template} />
    );
  }
}

export default Space01;
