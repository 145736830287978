import { curry, memoize } from 'lodash';
import React, { Component } from 'react';

import { Button } from 'semantic-ui-react';
import classNames from 'classnames';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import SwipeableWrapper from '../../../globals/SwipeableWrapper';

class EspSlider extends Component {
  static propTypes = {
    activeSlide: PropTypes.number,
    carouselSwipe: PropTypes.bool, // Whether swipe will act circularly as a carousel
    children: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list])
      .isRequired,
    pageNumbers: PropTypes.bool,
    setActiveSlide: PropTypes.func,
  };

  static defaultProps = {
    activeSlide: 0,
    carouselSwipe: true,
    pageNumbers: false,
    setActiveSlide: null,
  };

  state = {
    active: 0,
  };

  shouldComponentUpdate() {
    // Use internal state when no external handler
    const { setActiveSlide } = this.props;

    if (setActiveSlide) {
      return false;
    } else {
      return true;
    }
  }

  handleActive = memoize(
    curry((clickedActive, e) => {
      e.preventDefault();
      const { setActiveSlide } = this.props;

      if (setActiveSlide) {
        // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
        setActiveSlide(clickedActive);
      } else {
        this.setState({
          active: clickedActive,
        });
      }
    })
  );

  handleSwipedLeft = () => {
    const { activeSlide, carouselSwipe, children, setActiveSlide } = this.props;
    const { active } = this.state;

    let newActive;
    if (setActiveSlide) {
      // Use internal state when no external handler
      newActive = activeSlide;
    } else {
      newActive = active;
    }

    let moveToActive = newActive + 1;

    const childrenSize = children.length || children.size; // wheter immutable or array
    if (carouselSwipe) {
      // Making sure we don't exceed the limits of available children
      moveToActive = moveToActive > childrenSize - 1 ? 0 : moveToActive;
    } else {
      moveToActive =
        moveToActive > childrenSize - 1 ? childrenSize - 1 : moveToActive;
    }
    // console.log('handleSwipedLeft', moveToActive);

    if (setActiveSlide) {
      // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
      setActiveSlide(moveToActive);
    } else {
      this.setState({
        active: moveToActive,
      });
    }
  };

  handleSwipedRight = () => {
    const { activeSlide, carouselSwipe, children, setActiveSlide } = this.props;
    const { active } = this.state;

    let newActive;
    if (setActiveSlide) {
      // Use internal state when no external handler
      newActive = activeSlide;
    } else {
      newActive = active;
    }

    let moveToActive = newActive - 1;

    const childrenSize = children.length || children.size; // wheter immutable or array

    if (carouselSwipe) {
      // /making sure we dont pass down to 0 and instead go to the last child
      moveToActive = moveToActive < 0 ? childrenSize - 1 : moveToActive;
    } else {
      moveToActive = moveToActive < 0 ? 0 : moveToActive;
    }
    // console.log('handleSwipedRight', moveToActive);

    if (setActiveSlide) {
      // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
      setActiveSlide(moveToActive);
    } else {
      this.setState({
        active: moveToActive,
      });
    }
  };

  render() {
    const { children, activeSlide, pageNumbers, setActiveSlide } = this.props;
    const { active } = this.state;

    const isImmutableList = children instanceof Immutable.List;
    const childrenLength = isImmutableList ? children.size : children.length;

    let newActive;
    if (setActiveSlide) {
      // Use internal state when no external handler
      newActive = activeSlide;
    } else {
      newActive = active;
    }

    const slidesContainerWidth = {
      transform: `translateX(-${(100 / childrenLength) * newActive}%)`,
      width: `${childrenLength}00%`,
    };

    const slideWidth = {
      width: `${100 / childrenLength}%`,
    };

    return (
      <SwipeableWrapper
        onSwipedLeft={this.handleSwipedLeft}
        onSwipedRight={this.handleSwipedRight}
      >
        <div className='esp-slider'>
          <div className='slides-container' style={slidesContainerWidth}>
            {children.map((slide) => (
              <div className='slide' key={slide.key} style={slideWidth}>
                {slide}
              </div>
            ))}
          </div>
          {pageNumbers ? (
            <div className='slides-nav'>
              <Button.Group basic className='borderless' compact icon>
                {childrenLength > 1 &&
                  children.map((slide, i) => (
                    <Button
                      alt=''
                      as='div'
                      className={classNames({
                        active: newActive === i,
                      })}
                      key={slide.key}
                      onClick={this.handleActive(i)}
                    >
                      {i + 1}
                    </Button>
                  ))}
              </Button.Group>
            </div>
          ) : (
            <div className='slides-nav'>
              {childrenLength > 1 &&
                children.map((slide, i) => (
                  <span
                    alt=''
                    className={classNames({
                      active: newActive === i,
                    })}
                    key={slide.key}
                    onClick={this.handleActive(i)}
                    onKeyPress={this.handleActive(i)}
                  />
                ))}
            </div>
          )}
        </div>
      </SwipeableWrapper>
    );
  }
}

export default EspSlider;
