import APIcall from '../utils/APIcall';
import browserHistory from '../utils/browserHistory';
import anonResetPasswordActions from './anonResetPasswordActions';
import endpointGenerator from '../utils/endpointGenerator';
import sessionActions from './sessionActions';
import sessionThunks from './sessionThunks';
import uiPathGenerator from '../utils/uiPathGenerator';

const anonResetPasswordThunks = {};

anonResetPasswordThunks.sendCode = (email, recoveryMethod) => (dispatch) => {
  dispatch(anonResetPasswordActions.sendCodeStart());

  const url = endpointGenerator.genPath('espUser.users.sendOtp');

  APIcall.post({
    data: {
      method: recoveryMethod,
    },
    error() {
      dispatch(
        anonResetPasswordActions.sendCodeFail(
          'Unable to send verification code.'
        )
      );
    },
    query: {
      email,
    },
    success() {
      dispatch(anonResetPasswordActions.sendCodeSuccess(email));
    },
    url,
  });
};

anonResetPasswordThunks.validateCode = (recoveryCode, userEmail) => (
  dispatch,
  getState
) => {
  const state = getState();
  const anonResetPassword = state.get('anonResetPassword');
  const email = userEmail || anonResetPassword.get('email');

  dispatch(anonResetPasswordActions.validateCodeStart());

  const url = endpointGenerator.genPath('espUser.users.verifyOtp');

  APIcall.post({
    data: {
      code: recoveryCode,
    },
    error() {
      dispatch(anonResetPasswordActions.validateCodeFail('Invalid code'));
    },
    query: {
      email,
    },
    success(response) {
      const accessToken = response.body.key;

      // recovery code is correct
      dispatch(anonResetPasswordActions.validateCodeSuccess(accessToken));

      // user is now authenticated
      dispatch(sessionActions.setToken(accessToken));

      // pull user information
      dispatch(sessionThunks.whoami()).then(() => {
        // redirect to home page
        browserHistory.replace(uiPathGenerator.genPath('app.toDo'));

        // mark session as loggged in with code
        // so we can prompt the user to change his password
        dispatch(anonResetPasswordActions.promptResetPassword());
      });
    },
    url,
  });
};

anonResetPasswordThunks.changePassword = (formData) => (dispatch, getState) => {
  const state = getState();
  const userId = state.getIn(['session', 'currentUser']);
  const url = endpointGenerator.genPath(
    'espUser.users.instance.resetPassword',
    {
      userID: userId,
    }
  );

  dispatch(anonResetPasswordActions.changePasswordStart());

  APIcall.post({
    data: {
      new_password: formData.get('password1'),
    },
    error() {
      dispatch(
        anonResetPasswordActions.changePasswordFail('Change password error')
      );
    },
    success() {
      // recovery code is correct
      dispatch(anonResetPasswordActions.changePasswordSuccess());
    },
    token: true,
    url,
  });
};

export default anonResetPasswordThunks;
