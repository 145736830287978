import _ from 'lodash';

import BlockBase from '../../utils/BlockBase';
import { processPropDefaults, processPropTypes } from '../../utils/typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  template: {
    default: 'button_1',
  },
  text: {
    required: true,
    type: 'string',
  },
  to: {
    default: '',
    type: 'string',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
});

// Object Definition used by blobifier
const ButtonBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Button',
  templates: [
    'addtocartbutton01',
    'button_1',
    'nextbutton_1',
    'submitcartbutton01',
    'cancelcartbutton01',
    'submitandcancelcartbutton01',
  ],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, ButtonBlockDefinition, TypeDefaults };
