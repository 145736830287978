import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { curry, memoize } from 'lodash';

// Controller
import OktaAccountController from '../../../../controllers/OktaAccountController';

// Molecules
import ValidatedForm from '../../../../molecules/ValidatedForm';
import ValidatedField from '../../../../molecules/ValidatedField';
import ValidatedCheckbox from '../../../../molecules/ValidatedCheckbox';
import OktaAccountCreateModal from '../../../../molecules/OktaAccountCreateModal';

class OktaAccount extends PureComponent {
  static propTypes = {
    acsUrl: PropTypes.string,
    clearApiKey: PropTypes.func.isRequired,
    formValidation: PropTypes.func.isRequired,
    formValues: ImmutablePropTypes.map,
    getOktaAccount: PropTypes.func.isRequired,
    isDirty: PropTypes.bool,
    isLoadingOktaAccount: PropTypes.bool,
    oktaAccount: ImmutablePropTypes.map,
    saveOktaAccount: PropTypes.func.isRequired,
    syncErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
  };

  static defaultProps = {
    acsUrl: '',
    formValues: Immutable.Map(),
    isDirty: false,
    isLoadingOktaAccount: false,
    oktaAccount: Immutable.Map(),
    syncErrors: null,
  };

  state = {
    displayButtonOption: true,
  };

  componentDidMount() {
    const { getOktaAccount } = this.props;
    getOktaAccount();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { displayButtonOption } = this.state;

    if (
      displayButtonOption &&
      nextProps.oktaAccount &&
      !nextProps.oktaAccount.isEmpty()
    ) {
      this.setState({
        displayButtonOption: false,
      });
    }
  }

  handleOption = memoize(
    curry((value, e) => {
      e.preventDefault();
      this.setState({
        displayButtonOption: value,
      });
    })
  );

  handleSubmit = () => {
    const { saveOktaAccount } = this.props;
    saveOktaAccount();
  };

  // Clears the password if the field was pristine and not changed
  // So it forces the user to change the password if this field is touched
  handleOnFocusApiKey = () => {
    const { formValues, oktaAccount, clearApiKey } = this.props;
    if (formValues.get('api_key') === oktaAccount.get('api_key')) {
      clearApiKey();
    }
  };

  handleClick(e) {
    e.preventDefault();
  }

  render() {
    const {
      acsUrl,
      formValidation,
      getOktaAccount,
      isLoadingOktaAccount,
      oktaAccount,
      syncErrors,
      isDirty,
    } = this.props;
    const { displayButtonOption } = this.state;

    const initialValues = {
      acs_url: acsUrl,
      api_key: oktaAccount.get('api_key'),
      base_url: oktaAccount.get('base_url'),
      enable_saml: oktaAccount.get('enable_saml') || true,
      saml_idp_metadata_url: oktaAccount.get('saml_idp_metadata_url'),
    };

    const trigger = (
      <Form.Button
        content='Use Espressive Okta Integration'
        floated='right'
        onClick={this.handleClick}
      />
    );

    return (
      <Segment attached='bottom'>
        <Header as='h4' content='Okta Account' dividing />

        {!displayButtonOption && oktaAccount && oktaAccount.isEmpty() && (
          <Message
            content='If you have an existing Okta account you can fill out the form below.'
            info
          />
        )}

        {displayButtonOption && (
          <>
            <Divider hidden />
            <Button
              content={"I'm an Existing OKTA Customer"}
              floated='left'
              onClick={this.handleOption(false)}
            />
            <OktaAccountCreateModal
              getOktaAccount={getOktaAccount}
              trigger={trigger}
            />
            <Divider hidden />
            <Divider hidden />
          </>
        )}

        {!displayButtonOption && (
          <ValidatedForm
            enableReinitialize
            form='OktaAccountForm'
            initialValues={initialValues}
            keepDirtyOnReinitialize
            onSubmit={this.handleSubmit}
            validate={formValidation}
          >
            <Form.Group widths='equal'>
              <ValidatedField fluid label='Base URL' name='base_url' required />
              <ValidatedField
                fluid
                label='Okta Api Key'
                name='api_key'
                onFocus={this.handleOnFocusApiKey}
                required
                type='password'
              />
            </Form.Group>

            {!displayButtonOption &&
              (!oktaAccount || (oktaAccount && oktaAccount.isEmpty())) && (
                <ValidatedCheckbox
                  fluid
                  label={'Enable SAML with Okta'}
                  name='enable_saml'
                />
              )}

            {oktaAccount && !oktaAccount.isEmpty() && (
              <Button
                as='a'
                content='Update Okta Info'
                floated='right'
                href={oktaAccount.get('base_url')}
              />
            )}

            {!oktaAccount ||
              (oktaAccount.isEmpty() && (
                <Button content='Cancel' onClick={this.handleOption(true)} />
              ))}

            <Button
              content='Save'
              disabled={syncErrors !== null || isLoadingOktaAccount || !isDirty}
              loading={isLoadingOktaAccount}
              primary
            />

            {!oktaAccount ||
              (oktaAccount.isEmpty() && (
                <Message
                  content='By clicking Save, you are linking to a third-party service provided by Okta.'
                  info
                />
              ))}
          </ValidatedForm>
        )}
      </Segment>
    );
  }
}

export default OktaAccountController(OktaAccount);
