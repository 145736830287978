import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Header, Message, Segment } from 'semantic-ui-react';
import ExternalLink from '../../../../atoms/ExternalLink';
import injectIntlToController from '../../../../../utils/injectIntlToController';
import IntegrationServiceNowUpdatesController from '../../../../controllers/IntegrationServiceNowUpdatesController';

class IntegrationServicenowUpdates extends Component {
  static propTypes = {
    getVersion: PropTypes.func,
    systemId: PropTypes.string,
  };

  static defaultProps = {
    getVersion: () => {},
    systemId: '',
  };

  componentDidMount() {
    this.initializeVersion();
  }

  initializeVersion = () => {
    const { getVersion } = this.props;
    getVersion();
  };

  getReleaseName = (systemId) => {
    const RELEASE_INDEX = 0;
    try {
      return atob(systemId).split('/')[RELEASE_INDEX];
    } catch (err) {
      return systemId.split('/')[RELEASE_INDEX];
    }
  };

  render() {
    const { systemId } = this.props;
    const currentRelase = this.getReleaseName(systemId);

    const downloadLabel = 'Download';
    const buttonSize = 'tiny';
    const headerSize = 'h5';
    const baseURL = 'https://download.espressive.com/update_set';

    return (
      <Segment attached='bottom'>
        <Message
          content='These links are for the most current ServiceNow update set.'
          info
        />
        <Container textAlign='justified'>
          <Header as={headerSize} content='Initial Install' />
          <p>
            {
              'This is the full update set that you will need when performing the initial installation.'
            }{' '}
            <br />
            {'For all deployments start with this update set. '}
            <Button
              as={ExternalLink}
              basic
              content={downloadLabel}
              href={`${baseURL}/${currentRelase}/install.xml`}
              size={buttonSize}
            />
            <br />
            {
              'Additional update set required only for integrating with HR Case. '
            }
            <Button
              as={ExternalLink}
              basic
              content={downloadLabel}
              href={`${baseURL}/${currentRelase}/install_hr.xml`}
              size={buttonSize}
            />
          </p>
          <Header as={headerSize} content='Update Install' />
          <p>
            {
              'This is the update set that you will need when you have already installed the initial update set above and you want to '
            }
            {
              'upgrade to the latest version regardless of how many versions you might have skipped. '
            }
            <Button
              as={ExternalLink}
              basic
              content={downloadLabel}
              href={`${baseURL}/${currentRelase}/update.xml`}
              size={buttonSize}
            />
          </p>
          <Header as={headerSize} content='Delta Install' />
          <p>
            {
              'This is the update set that you will need when you know you are current with previous update set releases and you just '
            }
            {
              'want to install the incremental changes from the latest release. '
            }
            <Button
              as={ExternalLink}
              basic
              content={downloadLabel}
              href={`${baseURL}/${currentRelase}/delta.xml`}
              size={buttonSize}
            />
          </p>
        </Container>
      </Segment>
    );
  }
}

const IntegrationServicenowUpdatesTest = IntegrationServicenowUpdates;

// eslint-disable-next-line no-class-assign -- DEV-1526
IntegrationServicenowUpdates = injectIntlToController(
  IntegrationServicenowUpdates
);
export { IntegrationServicenowUpdatesTest };
export default IntegrationServiceNowUpdatesController(
  IntegrationServicenowUpdates
);
