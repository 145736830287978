import {
  Accordion,
  Button,
  Divider,
  Form,
  Icon,
  Modal,
} from 'semantic-ui-react';
import {
  getCustomMappedMessages,
  getDefaultMappedMessages,
  LABELS,
} from './utils';
import React, { useCallback, useContext, useRef } from 'react';

import AdminHelpContext from './AdminHelpContext';
import Barista01 from '../../../../blocks/barista/Barista01';
import BodyClassName from '../../../../../../globals/BodyClassName';
import { ImageDefaults } from 'esp-assets';
import { initialState } from './hook/useAdminHelp';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
import saveNewTranslation from './api/saveNewTranslation';
import ScrollArea from '../../../../atoms/ScrollArea';
import types from './types';
import updateTranslation from './api/updateTranslation';
import { v4 as uuidv4 } from 'uuid';

const propTypes = {
  isNew: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

const TipModal = ({ messages = [], trigger, isNew = false }) => {
  const {
    handleOpen,
    handleClose,
    state = initialState,
    closeModal,
    activeAccordionIndex,
    handleAccordionClick,
    dispatch,
    tenantInfo,
  } = useContext(AdminHelpContext);

  const messagesRef = useRef(null);

  const accordionStyle = {
    marginLeft: '1.5em',
    padding: '1em 0',
    whiteSpace: 'pre',
  };

  const handleTipChange = useCallback(
    (e, { value }) => {
      dispatch({
        newMessage: value,
        type: types.SET_TIP,
      });
    },
    [dispatch]
  );
  const handleAddTipClick = useCallback(
    (e) => {
      const newMessages = [...state.modalForm.messages];
      newMessages.push({
        id: uuidv4(),
        text: state.modalForm.newMessage,
      });
      dispatch({
        messages: newMessages,
        type: types.ADD_TIP,
      });
      setTimeout(() => {
        if (messagesRef.current?.scrollIntoView) {
          messagesRef.current?.scrollIntoView();
        }
      }, 200);
    },
    [dispatch, state.modalForm.messages, state.modalForm.newMessage]
  );

  const style = {
    overflowY: 'auto',
  };

  const handleEditChange = useCallback(
    (e, element) => {
      dispatch({
        id: element['data-bubbleid'],
        text: element['data-bubblevalue'],
        type: types.EDIT_CHANGE_TIP,
      });
    },
    [dispatch]
  );

  const handleEditBubble = useCallback(
    (e, element) => {
      dispatch({
        id: element['data-bubbleid'],
        type: types.EDIT_TIP,
      });
    },
    [dispatch]
  );

  const handleDeleteBubble = useCallback(
    (e, element) => {
      dispatch({
        id: element['data-bubbleid'],
        type: types.DELETE_TIP,
      });
    },
    [dispatch]
  );

  const baristaBlock = {
    baristaImg: ImageDefaults.BARISTA_AVATAR,
    handleDelete: handleDeleteBubble,
    handleEdit: handleEditBubble,
    handleEditChange: handleEditChange,
    id: 1,
    isEditable: true,
    messages: state.modalForm.messages,
    template: 'barista01',
    type: 'barista',
  };

  const createNewMessageGroup = useCallback(() => {
    const text = JSON.stringify({
      isActive: true,
      texts: state.modalForm.messages,
    });

    dispatch({
      type: types.TOGGLE_LOADING,
    });

    const saveNewTips = async () => {
      try {
        const { body } = await saveNewTranslation({
          key: uuidv4(),
          text,
        });

        const newRawData = [...state.customMessagesRaw, body];

        dispatch({
          custom_messages_raw: newRawData,
          messages: getCustomMappedMessages(newRawData).concat(
            getDefaultMappedMessages({
              intl,
              tenantInfo,
            })
          ),
          type: types.SAVE_FORM,
        });
      } catch (error) {
        // eslint-disable-next-line no-console -- debugging purposes
        console.error(error);
      }
    };

    saveNewTips();
  }, [dispatch, state.customMessagesRaw, state.modalForm.messages, tenantInfo]);

  const updateMessageGroup = useCallback(() => {
    const update = async () => {
      try {
        const text = JSON.stringify({
          isActive: state.modalForm.currentMessage.isActive,
          texts: state.modalForm.messages,
        });
        const { body } = await updateTranslation({
          id: state.modalForm.currentMessage.rawData.id,
          text,
        });

        const newRawData = state.customMessagesRaw.map((rawMessage) => {
          if (rawMessage.id === body.id) {
            return body;
          }
          return rawMessage;
        });

        dispatch({
          custom_messages_raw: newRawData,
          messages: getCustomMappedMessages(newRawData).concat(
            getDefaultMappedMessages({
              intl,
              tenantInfo,
            })
          ),
          type: types.SAVE_FORM,
        });
      } catch (error) {
        // eslint-disable-next-line no-console -- For debugging purposes
        console.error(error);
      }
    };

    dispatch({
      type: types.TOGGLE_LOADING,
    });

    update();
  }, [
    dispatch,
    state?.customMessagesRaw,
    state.modalForm?.currentMessage?.isActive,
    state.modalForm?.currentMessage?.rawData?.id,
    state.modalForm.messages,
    tenantInfo,
  ]);

  const handleSave = useCallback(() => {
    if (isNew) {
      createNewMessageGroup();
    } else {
      updateMessageGroup();
    }
  }, [createNewMessageGroup, isNew, updateMessageGroup]);

  return (
    <Modal
      className='scrolling'
      closeIcon
      data-testid='modal'
      onClose={handleClose}
      onOpen={handleOpen}
      open={state.isTipModalOpen}
      size='small'
      trigger={trigger}
    >
      <Modal.Header>{isNew ? LABELS.ADD_TIP : LABELS.EDIT_TIP}</Modal.Header>
      <Modal.Content className='withFooter'>
        <ScrollArea>
          <div data-testid='modal_description'>
            <p>{LABELS.MODAL_DESCTIPTION}</p>

            <Form as='div'>
              <Form.Group>
                <Accordion>
                  <Accordion.Title
                    active={activeAccordionIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                  >
                    <Icon name='dropdown' />
                    {LABELS.ACCORDION_TITLE}
                  </Accordion.Title>
                  <Accordion.Content active={activeAccordionIndex === 0}>
                    <p style={accordionStyle}>{LABELS.ACCORDION_BODY}</p>
                  </Accordion.Content>
                </Accordion>
              </Form.Group>

              <Form.TextArea
                data-testid='tipfield'
                disabled={state.isLoading}
                onChange={handleTipChange}
                rows={6}
                value={state.modalForm.newMessage}
              />

              <Form.Group>
                <span
                  style={{
                    margin: '0 auto',
                  }}
                >
                  <Form.Button
                    basic
                    content={LABELS.ADD_SECTION}
                    data-testid='moda_add_tip'
                    disabled={!state.modalForm.newMessage || state.isLoading}
                    onClick={handleAddTipClick}
                    primary
                    type='button'
                  />
                </span>
              </Form.Group>
            </Form>
            <div
              style={{
                margin: 'auto 0',
              }}
            >
              <BodyClassName className='main-padding'>
                <div className='empty' ref={messagesRef} style={style}>
                  <Barista01 {...baristaBlock} />
                </div>
              </BodyClassName>
            </div>
          </div>
        </ScrollArea>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content={LABELS.SAVE}
          data-testid='modal_save'
          disabled={
            state.modalForm.messages.length === 0 ||
            !state.modalForm.dirty ||
            state.isLoading
          }
          floated='right'
          loading={state.isLoading}
          onClick={handleSave}
          primary
          type='button'
        />
        <Button
          basic
          content={LABELS.CANCEL}
          floated='right'
          loading={state.isLoading}
          onClick={closeModal}
          type='button'
        />
        <Divider clearing hidden />
      </Modal.Actions>
    </Modal>
  );
};

TipModal.propTypes = propTypes;

export default TipModal;
