import { combineReducers } from 'redux-immutable';

import bundlesEntitiesReducer from './bundlesEntitiesReducer';
import cardsReducer from './cardsReducer';
import channelsReducer from './channelsReducer';
import departmentsReducer from './departmentsReducer';
import jobRolesReducer from './jobRolesEntitiesReducer';
import locationsReducer from './locationsReducer';
import ordersReducer from './ordersReducer';
import performancesReducer from './performancesReducer';
import productImagesReducer from './productImagesReducer';
import productsReducer from './productsReducer';
import requestsEntitiesReducer from './requestsEntitiesReducer';
import tasksReducer from './tasksReducer';
import usersReducer from './usersReducer';
import userRulesEntities from './userRulesEntities';

const entitiesReducer = combineReducers({
  bundles: bundlesEntitiesReducer,
  cards: cardsReducer,
  channels: channelsReducer,
  departments: departmentsReducer,
  jobRoles: jobRolesReducer,
  locations: locationsReducer,
  orders: ordersReducer,
  performances: performancesReducer,
  productImages: productImagesReducer,
  products: productsReducer,
  requests: requestsEntitiesReducer,
  tasks: tasksReducer,
  userRules: userRulesEntities,
  users: usersReducer,
});

export default entitiesReducer;
