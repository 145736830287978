import PropTypes from 'prop-types';
/**
 * Generate a PropType shape object
 * Based on the description provided in blockAttrsDefinition input
 * BAsed on http://stackoverflow.com/questions/34287100/extract-read-react-proptypes
 * @param  {object} blockAttrsDefinition An object defining proptypes
 * @return {React.PropTypes.shape} PropTypes shape object
 */

export function processPropTypes(blockAttrsDefinition) {
  const props = {};
  for (const key in blockAttrsDefinition) {
    if (Object.prototype.hasOwnProperty.call(blockAttrsDefinition, key)) {
      const attributeDefinition = blockAttrsDefinition[key];

      if (attributeDefinition.type === 'arrayOf') {
        const internalArrayOf = {};
        for (const arrayKey in attributeDefinition.arrayShape) {
          if (
            Object.prototype.hasOwnProperty.call(
              attributeDefinition.arrayShape,
              arrayKey
            )
          ) {
            const arrayOfDefinition = attributeDefinition.arrayShape[arrayKey];
            internalArrayOf[arrayKey] = PropTypes[arrayOfDefinition.type];
            if (arrayOfDefinition.required) {
              internalArrayOf[arrayKey] = internalArrayOf[arrayKey].isRequired;
            }
          }
        }

        props[key] = PropTypes.arrayOf(PropTypes.shape(internalArrayOf));
      } else {
        if (key === 'id') {
          props[key] = PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]);
        } else {
          props[key] = PropTypes[attributeDefinition.type];
        }
      }

      if (attributeDefinition.required && !attributeDefinition.default) {
        props[key] = props[key].isRequired;
      }
    }
  }

  return props;
}

/**
 * Plucks the defaults values, but checks if required is not set
 */
export function processPropDefaults(blockAttrsDefinition) {
  const defaults = {};
  for (const key in blockAttrsDefinition) {
    if (Object.prototype.hasOwnProperty.call(blockAttrsDefinition, key)) {
      const attributeDefinition = blockAttrsDefinition[key];
      if (attributeDefinition.default !== void 0) {
        defaults[key] = attributeDefinition.default;
      } else if (!attributeDefinition.required) {
        // eslint-disable-next-line no-console -- debugging
        console.warn(
          `attribute ${key} is not required but does not have a default`
        );
      }
    }
  }

  return defaults;
}
