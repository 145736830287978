import { getFormValues } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
import searchThunks from '../../actions/searchThunks';
import searchActions from '../../actions/searchActions';

import getUsersSearchTeam from '../../selectors/getUsersSearchTeam';
import isCurrentUserFAQAdmin from '../../selectors/isCurrentUserFAQAdmin';

const mapStateToProps = (state, ownProps) => {
  const shortHandSearch = state.getIn(['search', 'userSearch']);
  const formError = state.hasIn(['form', 'FormMember_newMember', 'syncErrors'])
    ? state.getIn(['form', 'FormMember_newMember', 'syncErrors'])
    : null;
  const formValues = getFormValues('FormMember_newMember')(state);

  const usersSearch = getUsersSearchTeam(state); // Filter from existing users the results.

  let memberSelected;
  if (
    ownProps.listSelected.get('id') === 'newMember' &&
    formValues &&
    !formValues.isEmpty() &&
    formValues.has('name')
  ) {
    memberSelected = state.hasIn([
      'entities',
      'users',
      Number(formValues.get('name')),
    ])
      ? state.getIn(['entities', 'users', Number(formValues.get('name'))])
      : usersSearch
      ? usersSearch.find((usr) => usr.get('pk') === formValues.get('name'))
      : null;
  }

  if (!memberSelected) {
    const { listSelected } = ownProps;
    const userID = listSelected.get('member')
      ? listSelected.getIn(['member', 'id'])
      : listSelected.get('user');
    memberSelected = userID ? state.getIn(['entities', 'users', userID]) : null;
  }

  const userName = memberSelected
    ? `${memberSelected.get('nickname')} ${memberSelected.get('last_name')}`
    : null;

  return {
    formError,
    formValues: getFormValues('FormMember_newMember')(state),
    isLoadingSearch: shortHandSearch.get('isLoading'),
    isUsrFaqAdmin: isCurrentUserFAQAdmin(state),
    memberSelected,
    userName,
    usersSearch,
    usersSearchTerm: shortHandSearch.get('userInput'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchUsers() {
    dispatch(searchThunks.searchUsers());
  },

  setSearchInputUsers(searchTerm) {
    dispatch(searchActions.setUserSearchInput(searchTerm));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
