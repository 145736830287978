import _ from 'lodash';
import { isPristine } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import caseMgmtThunks from '../../actions/caseMgmtThunks';

const mapStateToProps = (state, ownProps) => {
  const formID = ownProps.categoryID
    ? `ServiceDepartmentCategoryCreate-${ownProps.categoryID}`
    : 'ServiceDepartmentCategoryCreate';
  const formError =
    state.getIn(['form', 'ServiceDepartmentCategoryCreate', 'syncErrors']) ||
    null;
  const serviceDepartmentTeams = state.getIn([
    'caseMgmt',
    'serviceDepartmentTeam',
    'list',
  ]);
  return {
    formError,
    isLoading: state.getIn(['caseMgmt', 'serviceDepartment', 'isLoading']),
    isPristine: isPristine(formID)(state),
    serviceDepartmentTeams: serviceDepartmentTeams,
    taskClassifications: state.getIn(['caseMgmt', 'taskClassifications']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewCategory(serviceDepartmentID, data, msg, cb = _.noop) {
    dispatch(caseMgmtThunks.createTaskCategory(serviceDepartmentID, data)).then(
      () => {
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        cb();
      }
    );
  },

  formValidation(value) {
    const error = {};
    if (!value.get('classification')) {
      error.classification = "Can't be empty";
    }

    if (!value.get('display_name')) {
      error.display_name = "Can't be empty";
    }

    if (!value.get('response_sla')) {
      error.response_sla = "Can't be empty";
    }

    if (!value.get('resolve_sla')) {
      error.resolve_sla = "Can't be empty";
    }

    // if (!value.get('keywords')) {
    //   error.keywords = 'Select one Department';
    // }

    if (!value.get('teams')) {
      error.teams = 'Select one team';
    }

    return error;
  },

  loadFormData(categoryID) {
    if (categoryID) {
      dispatch(caseMgmtThunks.loadCurrentCategory(categoryID));
    }
  },

  loadInitialData(departmentID) {
    dispatch(caseMgmtThunks.loadTaskClassification(departmentID)).then(() =>
      dispatch(caseMgmtThunks.loadServiceTeamByDepartment(departmentID))
    );
  },
  updateCategory(categoryID, serviceDepartmentID, data, msg, cb = _.noop) {
    dispatch(
      caseMgmtThunks.updateTaskCategory(categoryID, serviceDepartmentID, data)
    )
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        cb();
      })
      .then(() =>
        dispatch(
          caseMgmtThunks.loadTaskCategories({
            departmentID: serviceDepartmentID,
          })
        )
      );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
