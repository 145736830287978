import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwareScanner extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M79.11 56.7L59.64 18.08s-3.9-2.33-16.93-2.56c0 0-13.13-.48-15.83 3.23l22.4 34.88 29.83 3.07zM57.85 24.98l13 24.74-21.53-1.6-15.27-23.49 23.8.35zm21.31 32.65v-.19s-21.21-2.49-30.08-2.66L16 68.54l34.75 5.43 28.41-16.34zm-51.66 9.8l21.35-9.55 20.21 2.2-19.05 10.18-22.51-2.83z' />
          <path d='M79.25 58.8L50.96 75.11l-34.34-5.36 2.23 4.2 31.92 5.74 28.79-17.06' />
        </g>
      </svg>
    );
  }
}

export default CatalogHardwareScanner;
