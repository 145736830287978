import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import getUserColorsById from '../../../../v1/utils/getUserColorsById';

class AvatarDefault extends PureComponent {
  static propTypes = {
    /** render as other component **/
    backgroundColor: PropTypes.string,
    backgroundSeed: PropTypes.number,
    initials: PropTypes.string,
    responsive: PropTypes.bool,
    size: PropTypes.number,
  };

  static defaultProps = {
    backgroundColor: '#ccc',
    backgroundSeed: void 0,
    initials: void 0,
    responsive: false,
    size: 96,
  };

  componentDidMount = () => {
    const { backgroundColor, backgroundSeed, initials } = this.props;

    if (!backgroundColor && !backgroundSeed) {
      throw new Error('A backgroundColor or backgroundSeed are required.');
    }

    if (initials && initials.length > 2) {
      throw new Error('initials requires no more than two characters.');
    }
  };

  generateBackgroundColor = (backgroundSeed) => backgroundSeed;

  render() {
    const {
      backgroundColor,
      backgroundSeed,
      initials,
      responsive, // Not used yet. This will make the SVG fill the container
      size, // Not used yet but can define the attrs if needed
    } = this.props;

    const bgColor =
      backgroundSeed && backgroundSeed > 0
        ? getUserColorsById(backgroundSeed)
        : backgroundColor;

    return (
      <svg
        display='block'
        height={responsive ? '100%' : size}
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 96 96'
        width={responsive ? '100%' : size}
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <clipPath id='clip-circle'>
            <circle cx='48' cy='48' r='48' />
          </clipPath>
        </defs>

        <circle cx='48' cy='48' fill={bgColor} r='48' />
        <text
          clipPath='url(#clip-circle)'
          fill='#fff'
          fontSize='48'
          letterSpacing='-2'
          textAnchor='middle'
          x='48'
          y='66'
        >
          {initials}
        </text>
      </svg>
    );
  }
}

export default AvatarDefault;
