import { Form, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import moment from 'moment';

// global
import { SupportedLocales } from '../../utils/SupportedLocales';
// Controller
import TenantConfigLocaleController from '../controllers/TenantConfigLocaleController';

/**
 * Handles tenant locale configuration for dates through momentjs
 */
class TenantConfigLocaleForm extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    locale: PropTypes.string,
    saveTenantLocale: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    locale: 'en',
    saveTenantLocale: _.noop,
  };

  state = {
    isDirty: false,
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    selectedLocale: this.props.locale,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { locale } = this.props;
    if (locale !== nextProps.locale) {
      this.setState(() => ({
        isDirty: false,
        selectedLocale: nextProps.locale,
      }));
    }
  }

  localeOptions = Object.keys(SupportedLocales).map((name) => {
    const locale = SupportedLocales[name];
    return {
      flag: {
        name: locale.flag,
      },
      text: locale.country,
      value: name,
    };
  });

  handleLocaleChange = (e, { value }) => {
    const { locale } = this.props;
    const { selectedLocale } = this.state;
    if (value !== selectedLocale) {
      this.setState(() => ({
        isDirty: value !== locale,
        selectedLocale: value,
      }));
    }
  };

  handleSubmit = () => {
    const { saveTenantLocale } = this.props;
    const { selectedLocale } = this.state;
    saveTenantLocale(selectedLocale);
  };

  render() {
    const { isLoading } = this.props;
    const { isDirty, selectedLocale } = this.state;

    const date = moment().locale(selectedLocale).format('l');

    return isLoading ? (
      <Loader />
    ) : (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Select
            label='Country'
            name='locale'
            onChange={this.handleLocaleChange}
            options={this.localeOptions}
            value={selectedLocale}
            width={6}
          />

          <Form.Input label={'Preview'} readOnly transparent value={date} />
        </Form.Group>

        {isDirty ? (
          <Form.Button
            color='green'
            content='Save'
            disabled={isLoading}
            floated='right'
            loading={isLoading}
          />
        ) : null}
      </Form>
    );
  }
}

export const TenantConfigLocaleFormTest = TenantConfigLocaleForm;

export default TenantConfigLocaleController(TenantConfigLocaleForm);
