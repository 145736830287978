import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

class SocialMediaUsernameInput extends PureComponent {
  static propTypes = {
    icon: PropTypes.shape({
      mame: PropTypes.string,
    }),
    input: PropTypes.shape({
      name: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      value: PropTypes.any,
    }).isRequired, // From redux form Field
    onInputChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
  };

  static defaultProps = {
    icon: null,
  };

  handleInputChange = (e) => {
    const { input, onInputChange } = this.props;
    onInputChange(input, e);
  };

  render() {
    const { input, icon, placeholder } = this.props;

    return (
      <input
        autoCapitalize='off'
        icon={icon}
        name={input.name}
        onChange={this.handleInputChange}
        placeholder={placeholder}
        type='text'
        value={input.value}
      />
    );
  }
}

export default SocialMediaUsernameInput;
