import { fromJS } from 'immutable';
import { forEach } from 'lodash';
import actionTypes from '../actions/actionTypes';
import { i18Locales } from 'esp-util-intl';

const DEFAULT_LOCALE = i18Locales.en_us;

const INITIAL_STATE = fromJS({
  // the json messages files were moved into getIntMessage selector
  isLoadingSupportedLanguages: false,
  isTenantMultiLanguageSupported: false, // default no multi language support
  locale: DEFAULT_LOCALE, // default locale
  supportedLanguages: [],
});

/**
 * Just manage the 'intl' part of the state
 * It's important to keep reducers as pure functions
 * http://redux.js.org/docs/introduction/ThreePrinciples.html#changes-are-made-with-pure-functions
 */
const intlReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.I18N_SET_LOCALE: {
      return state.set('locale', action.locale);
    }

    case actionTypes.APP_BOOTSTRAP_SUCCESS: {
      forEach(i18Locales, (value) => {
        if (value.match(action.me.preferred_language_id)) {
          action.me.preferred_language_id = value;
        }
      });

      return state
        .set('locale', action.me.preferred_language_id || DEFAULT_LOCALE)
        .set(
          'isTenantMultiLanguageSupported',
          action.bootstrapData.multi_language_support_enabled
        );
    }

    case actionTypes.I18N_LOAD_SUPPORTED_LANGUAGES_FAIL: {
      return state.set('isLoadingSupportedLanguages', false);
    }

    case actionTypes.I18N_LOAD_SUPPORTED_LANGUAGES_START: {
      return state.set('isLoadingSupportedLanguages', true);
    }

    case actionTypes.I18N_LOAD_SUPPORTED_LANGUAGES_SUCCESS: {
      return state
        .set('supportedLanguages', fromJS(action.languages))
        .set('isLoadingSupportedLanguages', false);
    }
    default:
      return state;
  }
};

export default intlReducer;
