import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import workflowThunks from '../../actions/workflowThunks';
import { getMultiLanguageConfig } from '../../selectors/getIntlMessages';

const mapStateToProps = (state) => ({
  isMultiLanguageTenant: getMultiLanguageConfig(state),
});

const mapDispatchToProps = (dispatch) => ({
  setBuildEmployeeBEScratch(isMultiLanguageTenant) {
    const dataScratch = {
      'scratch.temp_data': {
        isMultiLanguageTenant: isMultiLanguageTenant ? 'true' : 'false', // Use as temp_data['isMultiLanguageTenant'] for the Select preferred language condition
        skipTutorial: 'false', // user as temp_data['skipTutorial'] for the skip tutorial condition
      },
    };

    dispatch(workflowThunks.saveToBackEndScratch(dataScratch));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
