const CountryCodes = [
  {
    iso: 'US',
    key: 'us',
    mask: '999-999-9999',
    placeholder: '123-456-7890',
    text: 'United States',
    value: '+1',
  },
  {
    iso: 'CA',
    key: 'ca',
    mask: '999-999-9999',
    placeholder: '123-456-7890',
    text: 'Canada',
    value: '+1',
  },
  {
    iso: 'MX',
    key: 'mx',
    mask: '99-9999-9999',
    placeholder: '12-3456-7890',
    text: 'Mexico',
    value: '+52',
  },

  {
    iso: 'AF',
    key: 'af',
    mask: '999-9999',
    text: 'Afghanistan',
    value: '+93',
  },
  {
    iso: 'AL',
    key: 'al',
    text: 'Albania',
    value: '+355',
  },
  {
    iso: 'DZ',
    key: 'dz',
    text: 'Algeria',
    value: '+213',
  },
  {
    iso: 'AD',
    key: 'ad',
    text: 'Andorra',
    value: '+376',
  },
  {
    iso: 'AO',
    key: 'ao',
    text: 'Angola',
    value: '+244',
  },
  {
    iso: 'AR',
    key: 'ar',
    text: 'Argentina',
    value: '+54',
  },
  {
    iso: 'AM',
    key: 'am',
    text: 'Armenia',
    value: '+374',
  },
  {
    iso: 'AW',
    key: 'aw',
    text: 'Aruba',
    value: '+297',
  },
  {
    iso: 'AU',
    key: 'au',
    text: 'Australia',
    value: '+61',
  },
  {
    iso: 'AT',
    key: 'at',
    text: 'Austria',
    value: '+43',
  },
  {
    iso: 'AZ',
    key: 'az',
    text: 'Azerbaijan',
    value: '+994',
  },

  {
    iso: 'BS',
    key: 'bs',
    text: 'Bahamas',
    value: '+1242',
  },
  {
    iso: 'BH',
    key: 'bh',
    text: 'Bahrain',
    value: '+973',
  },
  {
    iso: 'BD',
    key: 'bd',
    text: 'Bangladesh',
    value: '+880',
  },
  {
    iso: 'BB',
    key: 'bb',
    text: 'Barbados',
    value: '+1246',
  },
  {
    iso: 'BY',
    key: 'by',
    text: 'Belarus',
    value: '+375',
  },
  {
    iso: 'BE',
    key: 'be',
    text: 'Belgium',
    value: '+32',
  },
  {
    iso: 'BZ',
    key: 'bz',
    text: 'Belize',
    value: '+501',
  },
  {
    iso: 'BJ',
    key: 'bj',
    text: 'Benin',
    value: '+229',
  },
  {
    iso: 'BM',
    key: 'bm',
    text: 'Bermuda',
    value: '+1441',
  },
  {
    iso: 'BT',
    key: 'bt',
    text: 'Bhutan',
    value: '+975',
  },
  {
    iso: 'BO',
    key: 'bo',
    text: 'Bolivia',
    value: '+591',
  },
  {
    iso: 'BA',
    key: 'ba',
    text: 'Bosnia',
    value: '+387',
  },
  {
    iso: 'BW',
    key: 'bw',
    text: 'Botswana',
    value: '+267',
  },
  {
    iso: 'BR',
    key: 'br',
    text: 'Brazil',
    value: '+55',
  },
  {
    iso: 'BN',
    key: 'bn',
    text: 'Brunei',
    value: '+673',
  },
  {
    iso: 'BG',
    key: 'bg',
    text: 'Bulgaria',
    value: '+359',
  },
  {
    iso: 'BF',
    key: 'bf',
    text: 'Burkina Faso',
    value: '+226',
  },
  {
    iso: 'BI',
    key: 'bi',
    text: 'Burundi',
    value: '+257',
  },

  {
    iso: 'KH',
    key: 'kh',
    text: 'Cambodia',
    value: '+855',
  },
  {
    iso: 'CM',
    key: 'cm',
    text: 'Cameroon',
    value: '+237',
  },
  {
    iso: 'CV',
    key: 'cv',
    text: 'Cape Verde',
    value: '+238',
  },
  {
    iso: 'KY',
    key: 'ky',
    text: 'Cayman Islands',
    value: '+1345',
  },
  {
    iso: 'CF',
    key: 'cf',
    text: 'Central African Republic',
    value: '+236',
  },
  {
    iso: 'TD',
    key: 'td',
    text: 'Chad',
    value: '+235',
  },
  {
    iso: 'CL',
    key: 'cl',
    text: 'Chile',
    value: '+56',
  },
  {
    iso: 'CN',
    key: 'cn',
    text: 'China',
    value: '+86',
  },
  {
    iso: 'CX',
    key: 'cx',
    text: 'Christmas Island',
    value: '+618',
  },
  {
    iso: 'CC',
    key: 'cc',
    text: 'Cocos Islands',
    value: '+618',
  },
  {
    iso: 'CO',
    key: 'co',
    text: 'Colombia',
    value: '+57',
  },
  {
    iso: 'KM',
    key: 'km',
    text: 'Comoros',
    value: '+269',
  },
  {
    iso: 'CG',
    key: 'cg',
    text: 'Congo Brazzaville',
    value: '+242',
  },
  {
    iso: 'CD',
    key: 'cd',
    text: 'Congo',
    value: '+243',
  },
  {
    iso: 'CK',
    key: 'ck',
    text: 'Cook Islands',
    value: '+682',
  },
  {
    iso: 'CR',
    key: 'cr',
    text: 'Costa Rica',
    value: '+506',
  },
  {
    iso: 'CI',
    key: 'ci',
    text: 'Cote Divoire',
    value: '+225',
  },
  {
    iso: 'HR',
    key: 'hr',
    text: 'Croatia',
    value: '+385',
  },
  {
    iso: 'CU',
    key: 'cu',
    text: 'Cuba',
    value: '+53',
  },
  {
    iso: 'CY',
    key: 'cy',
    text: 'Cyprus',
    value: '+357',
  },
  {
    iso: 'CZ',
    key: 'cz',
    text: 'Czech Republic',
    value: '+420',
  },

  {
    iso: 'DK',
    key: 'dk',
    text: 'Denmark',
    value: '+45',
  },
  {
    iso: 'DJ',
    key: 'dj',
    text: 'Djibouti',
    value: '+253',
  },
  {
    iso: 'DM',
    key: 'dm',
    text: 'Dominica',
    value: '+1767',
  },
  {
    iso: 'DO',
    key: 'do',
    text: 'Dominican Republic',
    value: '+1809',
  },
  {
    iso: 'EC',
    key: 'ec',
    text: 'Ecuador',
    value: '+593',
  },
  {
    iso: 'EG',
    key: 'eg',
    text: 'Egypt',
    value: '+20',
  },
  {
    iso: 'SV',
    key: 'sv',
    text: 'El Salvador',
    value: '+503',
  },
  {
    iso: 'GQ',
    key: 'gq',
    text: 'Equatorial Guinea',
    value: '+240',
  },
  {
    iso: 'ER',
    key: 'er',
    text: 'Eritrea',
    value: '+291',
  },
  {
    iso: 'EE',
    key: 'ee',
    text: 'Estonia',
    value: '+372',
  },
  {
    iso: 'ET',
    key: 'et',
    text: 'Ethiopia',
    value: '+251',
  },
  {
    iso: 'FK',
    key: 'fk',
    text: 'Falkland Islands',
    value: '+500',
  },
  {
    iso: 'FO',
    key: 'fo',
    text: 'Faroe Islands',
    value: '+298',
  },
  {
    iso: 'FJ',
    key: 'fj',
    text: 'Fiji',
    value: '+679',
  },
  {
    iso: 'FI',
    key: 'fi',
    text: 'Finland',
    value: '+358',
  },
  {
    iso: 'FR',
    key: 'fr',
    text: 'France',
    value: '+33',
  },
  {
    iso: 'GF',
    key: 'gf',
    text: 'French Guiana',
    value: '+594',
  },
  {
    iso: 'PF',
    key: 'pf',
    text: 'French Polynesia',
    value: '+689',
  },

  {
    iso: 'GA',
    key: 'ga',
    text: 'Gabon',
    value: '+241',
  },
  {
    iso: 'GM',
    key: 'gm',
    text: 'Gambia',
    value: '+220',
  },
  {
    iso: 'GE',
    key: 'ge',
    text: 'Georgia',
    value: '+995',
  },
  {
    iso: 'DE',
    key: 'de',
    text: 'Germany',
    value: '+49',
  },
  {
    iso: 'GH',
    key: 'gh',
    text: 'Ghana',
    value: '+233',
  },
  {
    iso: 'GI',
    key: 'gi',
    text: 'Gibraltar',
    value: '+gi',
  },
  {
    iso: 'GR',
    key: 'gr',
    text: 'Greece',
    value: '+350',
  },
  {
    iso: 'GL',
    key: 'gl',
    text: 'Greenland',
    value: '+299',
  },
  {
    iso: 'GD',
    key: 'gd',
    text: 'Grenada',
    value: '+1',
  },
  {
    iso: 'GP',
    key: 'gp',
    text: 'Guadeloupe',
    value: '+590',
  },
  {
    iso: 'GU',
    key: 'gu',
    text: 'Guam',
    value: '+1',
  },
  {
    iso: 'GT',
    key: 'gt',
    text: 'Guatemala',
    value: '+502',
  },
  {
    iso: 'GW',
    key: 'gw',
    text: 'Guinea-Bissau',
    value: '+245',
  },
  {
    iso: 'GN',
    key: 'gn',
    text: 'Guinea',
    value: '+224',
  },
  {
    iso: 'GY',
    key: 'gy',
    text: 'Guyana',
    value: '+592',
  },

  {
    iso: 'HT',
    key: 'ht',
    text: 'Haiti',
    value: '+509',
  },
  {
    iso: 'HN',
    key: 'hn',
    text: 'Honduras',
    value: '+504',
  },
  {
    iso: 'HK',
    key: 'hk',
    text: 'Hong Kong',
    value: '+852',
  },
  {
    iso: 'HU',
    key: 'hu',
    text: 'Hungary',
    value: '+36',
  },

  {
    iso: 'IS',
    key: 'is',
    text: 'Iceland',
    value: '+354',
  },
  {
    iso: 'IN',
    key: 'in',
    text: 'India',
    value: '+91',
  },
  {
    iso: 'ID',
    key: 'id',
    text: 'Indonesia',
    value: '+62',
  },
  {
    iso: 'IR',
    key: 'ir',
    text: 'Iran',
    value: '+98',
  },
  {
    iso: 'IQ',
    key: 'iq',
    text: 'Iraq',
    value: '+964',
  },
  {
    iso: 'IE',
    key: 'ie',
    text: 'Ireland',
    value: '+353',
  },
  {
    iso: 'IL',
    key: 'il',
    text: 'Israel',
    value: '+972',
  },
  {
    iso: 'IT',
    key: 'it',
    text: 'Italy',
    value: '+39',
  },

  {
    iso: 'JM',
    key: 'jm',
    text: 'Jamaica',
    value: '+1876',
  },
  {
    iso: 'JP',
    key: 'jp',
    text: 'Japan',
    value: '+81',
  },
  {
    iso: 'JO',
    key: 'jo',
    text: 'Jordan',
    value: '+692',
  },

  {
    iso: 'KZ',
    key: 'kz',
    text: 'Kazakhstan',
    value: '+7',
  },
  {
    iso: 'KE',
    key: 'ke',
    text: 'Kenya',
    value: '+254',
  },
  {
    iso: 'KI',
    key: 'ki',
    text: 'Kiribati',
    value: '+686',
  },
  {
    iso: 'KW',
    key: 'kw',
    text: 'Kuwait',
    value: '+965',
  },
  {
    iso: 'KG',
    key: 'kg',
    text: 'Kyrgyzstan',
    value: '+996',
  },

  {
    iso: 'LA',
    key: 'la',
    text: 'Laos',
    value: '+856',
  },
  {
    iso: 'LV',
    key: 'lv',
    text: 'Latvia',
    value: '+371',
  },
  {
    iso: 'LB',
    key: 'lb',
    text: 'Lebanon',
    value: '+961',
  },
  {
    iso: 'LS',
    key: 'ls',
    text: 'Lesotho',
    value: '+266',
  },
  {
    iso: 'LR',
    key: 'lr',
    text: 'Liberia',
    value: '+231',
  },
  {
    iso: 'LY',
    key: 'ly',
    text: 'Libya',
    value: '+218',
  },
  {
    iso: 'LI',
    key: 'li',
    text: 'Liechtenstein',
    value: '+423',
  },
  {
    iso: 'LT',
    key: 'lt',
    text: 'Lithuania',
    value: '+370',
  },
  {
    iso: 'LU',
    key: 'lu',
    text: 'Luxembourg',
    value: '+352',
  },

  {
    iso: 'MO',
    key: 'mo',
    text: 'Macau',
    value: '+853',
  },
  {
    iso: 'MK',
    key: 'mk',
    text: 'Macedonia',
    value: '+389',
  },
  {
    iso: 'MG',
    key: 'mg',
    text: 'Madagascar',
    value: '+261',
  },
  {
    iso: 'MW',
    key: 'mw',
    text: 'Malawi',
    value: '+265',
  },
  {
    iso: 'MY',
    key: 'my',
    text: 'Malaysia',
    value: '+60',
  },
  {
    iso: 'MV',
    key: 'mv',
    text: 'Maldives',
    value: '+960',
  },
  {
    iso: 'ML',
    key: 'ml',
    text: 'Mali',
    value: '+223',
  },
  {
    iso: 'MT',
    key: 'mt',
    text: 'Malta',
    value: '+356',
  },
  {
    iso: 'MH',
    key: 'mh',
    text: 'Marshall Islands',
    value: '+692',
  },
  {
    iso: 'MQ',
    key: 'mq',
    text: 'Martinique',
    value: '+596',
  },
  {
    iso: 'MR',
    key: 'mr',
    text: 'Mauritania',
    value: '+222',
  },
  {
    iso: 'MU',
    key: 'mu',
    text: 'Mauritius',
    value: '+230',
  },
  {
    iso: 'YT',
    key: 'yt',
    text: 'Mayotte',
    value: '+262',
  },
  {
    iso: 'FM',
    key: 'fm',
    text: 'Micronesia',
    value: '+691',
  },
  {
    iso: 'MD',
    key: 'md',
    text: 'Moldova',
    value: '+373',
  },
  {
    iso: 'MC',
    key: 'mc',
    text: 'Monaco',
    value: '+377',
  },
  {
    iso: 'MN',
    key: 'mn',
    text: 'Mongolia',
    value: '+976',
  },
  {
    iso: 'ME',
    key: 'me',
    text: 'Montenegro',
    value: '+382',
  },
  {
    iso: 'MS',
    key: 'ms',
    text: 'Montserrat',
    value: '+1664',
  },
  {
    iso: 'MA',
    key: 'ma',
    text: 'Morocco',
    value: '+212',
  },
  {
    iso: 'MZ',
    key: 'mz',
    text: 'Mozambique',
    value: '+258',
  },

  {
    iso: 'NA',
    key: 'na',
    text: 'Namibia',
    value: '+264',
  },
  {
    iso: 'NR',
    key: 'nr',
    text: 'Nauru',
    value: '+674',
  },
  {
    iso: 'NP',
    key: 'np',
    text: 'Nepal',
    value: '+977',
  },
  {
    iso: 'AN',
    key: 'an',
    text: 'Netherlands Antilles',
    value: '+599',
  },
  {
    iso: 'NL',
    key: 'nl',
    text: 'Netherlands',
    value: '+31',
  },
  {
    iso: 'NC',
    key: 'nc',
    text: 'New Caledonia',
    value: '+687',
  },
  {
    iso: 'NZ',
    key: 'nz',
    text: 'New Zealand',
    value: '+64',
  },
  {
    iso: 'NI',
    key: 'ni',
    text: 'Nicaragua',
    value: '+505',
  },
  {
    iso: 'NE',
    key: 'ne',
    text: 'Niger',
    value: '+227',
  },
  {
    iso: 'NG',
    key: 'ng',
    text: 'Nigeria',
    value: '+234',
  },
  {
    iso: 'NU',
    key: 'nu',
    text: 'Niue',
    value: '+683',
  },
  {
    iso: 'NF',
    key: 'nf',
    text: 'Norfolk Island',
    value: '+672',
  },
  {
    iso: 'MP',
    key: 'mp',
    text: 'Northern Mariana Islands',
    value: '+1670',
  },
  {
    iso: 'NO',
    key: 'no',
    text: 'Norway',
    value: '+47',
  },

  {
    iso: 'OM',
    key: 'om',
    text: 'Oman',
    value: '+968',
  },

  {
    iso: 'PK',
    key: 'pk',
    text: 'Pakistan',
    value: '+92',
  },
  {
    iso: 'PW',
    key: 'pw',
    text: 'Palau',
    value: '+680',
  },
  {
    iso: 'PS',
    key: 'ps',
    text: 'Palestine',
    value: '+970',
  },
  {
    iso: 'PA',
    key: 'pa',
    text: 'Panama',
    value: '+507',
  },
  {
    iso: 'PY',
    key: 'py',
    text: 'Paraguay',
    value: '+595',
  },
  {
    iso: 'PE',
    key: 'pe',
    text: 'Peru',
    value: '+51',
  },
  {
    iso: 'PH',
    key: 'ph',
    text: 'Philippines',
    value: '+63',
  },
  {
    iso: 'PN',
    key: 'pn',
    text: 'Pitcairn Islands',
    value: '+870',
  },
  {
    iso: 'PL',
    key: 'pl',
    text: 'Poland',
    value: '+48',
  },
  {
    iso: 'PT',
    key: 'pt',
    text: 'Portugal',
    value: '+351',
  },
  {
    iso: 'PR',
    key: 'pr',
    text: 'Puerto Rico',
    value: '+1787',
  },

  {
    iso: 'QA',
    key: 'qa',
    text: 'Qatar',
    value: '+974',
  },

  {
    iso: 'RE',
    key: 're',
    text: 'Reunion',
    value: '+262',
  },
  {
    iso: 'RO',
    key: 'ro',
    text: 'Romania',
    value: '+40',
  },
  {
    iso: 'RU',
    key: 'ru',
    text: 'Russia',
    value: '+7',
  },
  {
    iso: 'RW',
    key: 'rw',
    text: 'Rwanda',
    value: '+250',
  },

  {
    iso: 'SH',
    key: 'sh',
    text: 'Saint Helena',
    value: '+290',
  },
  {
    iso: 'KN',
    key: 'kn',
    text: 'Saint Kitts and Nevis',
    value: '+1689',
  },
  {
    iso: 'LC',
    key: 'lc',
    text: 'Saint Lucia',
    value: '+1758',
  },
  {
    iso: 'PM',
    key: 'pm',
    text: 'Saint Pierre',
    value: '+508',
  },
  {
    iso: 'VC',
    key: 'vc',
    text: 'Saint Vincent',
    value: '+1784',
  },
  {
    iso: 'WS',
    key: 'ws',
    text: 'Samoa',
    value: '+685',
  },
  {
    iso: 'SM',
    key: 'sm',
    text: 'San Marino',
    value: '+378',
  },
  {
    iso: 'ST',
    key: 'st',
    text: 'Sao Tome',
    value: '+239',
  },
  {
    iso: 'SA',
    key: 'sa',
    text: 'Saudi Arabia',
    value: '+966',
  },
  {
    iso: 'SN',
    key: 'sn',
    text: 'Senegal',
    value: '+221',
  },
  {
    iso: 'RS',
    key: 'rs',
    text: 'Serbia',
    value: '+381',
  },
  {
    iso: 'SC',
    key: 'sc',
    text: 'Seychelles',
    value: '+248',
  },
  {
    iso: 'SL',
    key: 'sl',
    text: 'Sierra Leone',
    value: '+232',
  },
  {
    iso: 'SG',
    key: 'sg',
    text: 'Singapore',
    value: '+65',
  },
  {
    iso: 'SK',
    key: 'sk',
    text: 'Slovakia',
    value: '+421',
  },
  {
    iso: 'SI',
    key: 'si',
    text: 'Slovenia',
    value: '+386',
  },
  {
    iso: 'SB',
    key: 'sb',
    text: 'Solomon Islands',
    value: '+677',
  },
  {
    iso: 'SO',
    key: 'so',
    text: 'Somalia',
    value: '+252',
  },
  {
    iso: 'ZA',
    key: 'za',
    text: 'South Africa',
    value: '+27',
  },
  {
    iso: 'KR',
    key: 'kr',
    text: 'South Korea',
    value: '+82',
  },
  {
    iso: 'ES',
    key: 'es',
    text: 'Spain',
    value: '+34',
  },
  {
    iso: 'LK',
    key: 'lk',
    text: 'Sri Lanka',
    value: '+94',
  },
  {
    iso: 'SD',
    key: 'sd',
    text: 'Sudan',
    value: '+249',
  },
  {
    iso: 'SR',
    key: 'sr',
    text: 'Suriname',
    value: '+597',
  },
  {
    iso: 'SJ',
    key: 'sj',
    text: 'Svalbard',
    value: '+47',
  },
  {
    iso: 'SZ',
    key: 'sz',
    text: 'Swaziland',
    value: '+268',
  },
  {
    iso: 'SE',
    key: 'se',
    text: 'Sweden',
    value: '+46',
  },
  {
    iso: 'CH',
    key: 'ch',
    text: 'Switzerland',
    value: '+41',
  },
  {
    iso: 'SY',
    key: 'sy',
    text: 'Syria',
    value: '+963',
  },

  {
    iso: 'TW',
    key: 'tw',
    text: 'Taiwan',
    value: '+886',
  },
  {
    iso: 'TJ',
    key: 'tj',
    text: 'Tajikistan',
    value: '+992',
  },
  {
    iso: 'TZ',
    key: 'tz',
    text: 'Tanzania',
    value: '+255',
  },
  {
    iso: 'TH',
    key: 'th',
    text: 'Thailand',
    value: '+66',
  },
  {
    iso: 'TL',
    key: 'tl',
    text: 'Timorleste',
    value: '+670',
  },
  {
    iso: 'TG',
    key: 'tg',
    text: 'Togo',
    value: '+228',
  },
  {
    iso: 'TK',
    key: 'tk',
    text: 'Tokelau',
    value: '+690',
  },
  {
    iso: 'TO',
    key: 'to',
    text: 'Tonga',
    value: '+676',
  },
  {
    iso: 'TT',
    key: 'tt',
    text: 'Trinidad',
    value: '+1868',
  },
  {
    iso: 'TN',
    key: 'tn',
    text: 'Tunisia',
    value: '+216',
  },
  {
    iso: 'TR',
    key: 'tr',
    text: 'Turkey',
    value: '+90',
  },
  {
    iso: 'TM',
    key: 'tm',
    text: 'Turkmenistan',
    value: '+993',
  },
  {
    iso: 'TV',
    key: 'tv',
    text: 'Tuvalu',
    value: '+688',
  },

  {
    iso: 'UG',
    key: 'ug',
    text: 'Uganda',
    value: '+256',
  },
  {
    iso: 'UA',
    key: 'ua',
    text: 'Ukraine',
    value: '+380',
  },
  {
    iso: 'AE',
    key: 'ae',
    text: 'United Arab Emirates',
    value: '+971',
  },
  {
    iso: 'GB',
    key: 'gb',
    text: 'United Kingdom',
    value: '+44',
  },
  {
    iso: 'UY',
    key: 'uy',
    text: 'Uruguay',
    value: '+598',
  },
  {
    iso: 'VI',
    key: 'vi',
    text: 'Us Virgin Islands',
    value: '+1340',
  },
  {
    iso: 'UZ',
    key: 'uz',
    text: 'Uzbekistan',
    value: '+998',
  },

  {
    iso: 'VU',
    key: 'vu',
    text: 'Vanuatu',
    value: '+678',
  },
  {
    iso: 'VA',
    key: 'va',
    text: 'Vatican City',
    value: '+379',
  },
  {
    iso: 'VE',
    key: 've',
    text: 'Venezuela',
    value: '+58',
  },
  {
    iso: 'VN',
    key: 'vn',
    text: 'Vietnam',
    value: '+84',
  },

  {
    iso: 'WF',
    key: 'wf',
    text: 'Wallis and Futuna',
    value: '+681',
  },
  {
    iso: 'EH',
    key: 'eh',
    text: 'Western Sahara',
    value: '+212',
  },

  {
    iso: 'YE',
    key: 'ye',
    text: 'Yemen',
    value: '+967',
  },

  {
    iso: 'ZM',
    key: 'zm',
    text: 'Zambia',
    value: '+260',
  },
  {
    iso: 'ZW',
    key: 'zw',
    text: 'Zimbabwe',
    value: '+263',
  },
];

export default CountryCodes;
