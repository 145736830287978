import _ from 'lodash';
import { change, submit } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import faqThunks from '../../actions/faqThunks';
import faqActions from '../../actions/faqActions';
import workflowActions from '../../actions/workflowActions';

const mapStateToProps = (state, ownProps) => ({
  attributes: state.getIn(['workflowState', 'attributes']),
  beScratchData: state.getIn([
    'workflowState',
    'backendScratch',
    'scratch.temp_data',
  ]),
  blocks: state.getIn(['workflowState', 'blob', 'blocks']),
  faqMatched: state.getIn(['adminFaqs', 'faqMatch']),
  isLoadingFAQMatch: state.getIn(['adminFaqs', 'isLoadingFAQMatch']),
  kbID: _.hasIn(ownProps, 'query.sys_id') ? ownProps.query.sys_id : null,
  kbTitle: _.hasIn(ownProps, 'query.title') ? ownProps.query.title : null,
  taskId: _.hasIn(ownProps, 'query.taskId')
    ? Number(ownProps.query.taskId)
    : null,
  taskSelected: state.getIn([
    'entities',
    'tasks',
    Number(ownProps.query.taskId),
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    dispatch(change('Form01', id, data));
  },

  setBEscratchAndSearchFAQ(taskid, inputID, kbID, kbTitle) {
    dispatch(faqActions.loadFAQMatchStart()); // Set loading state

    dispatch(faqThunks.setTeachBaristaBEScratch(taskid, kbTitle))
      .then((task) => dispatch(faqThunks.getFAQMatchByTrigger(task, kbTitle)))
      .then((faq) => {
        if (faq) {
          dispatch(change('Form01', inputID, 'match')); // Pass the value to set the condition
          dispatch(workflowActions.updateAttributesFromBEScratch());
        } else {
          // No FAQ matched the trigger, we must redirect the user to the next step
          dispatch(change('Form01', inputID, 'no_match')); // Pass the value to set the condition
          dispatch(submit('Form01')); // Submit the form to go next
        }
      });
  },
  setTeachBaristaBEScratch(taskId) {
    dispatch(faqThunks.setTeachBaristaBEScratch(taskId));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
