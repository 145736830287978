import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import FormInputSelect from '../../../../v1/components/molecules/FormInputSelect';
import LanguageSelectController from '../../../../v1/components/controllers/LanguageSelectController';

class LanguageSelect extends PureComponent {
  static propTypes = {
    //
    // By passing a fieldName, we let the form know that we what to change the value of this fieldName
    // instead to force a re-initialization
    //
    // fieldName     : PropTypes.string,
    formName: PropTypes.string,
    initialValues: PropTypes.shape({
      about_me: PropTypes.string,
      date_of_birth: PropTypes.string,
      email: PropTypes.string,
      employee_number: PropTypes.string,
      first_name: PropTypes.string,
      home_phone: ImmutablePropTypes.map,
      last_name: PropTypes.string,
      location: PropTypes.string,
      mobile_phone: ImmutablePropTypes.map,
      nickname: PropTypes.string,
      office_location: PropTypes.string,
      preferred_language_id: PropTypes.string,
      remote_worker: PropTypes.bool,
      secondary_email: PropTypes.string,
      social_coordinates: ImmutablePropTypes.list,
      username: PropTypes.string,
      work_phone: ImmutablePropTypes.map,
    }),
    isLoading: PropTypes.bool,
    isTenantMultiLanguageSupported: PropTypes.bool,
    label: PropTypes.string,
    labelText: PropTypes.string,
    loadSupportedLanguages: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    supportedLanguages: ImmutablePropTypes.list,
  };

  static defaultProps = {
    // fieldName     : null,
    formName: '',
    initialValues: {},
    isLoading: false,
    isTenantMultiLanguageSupported: false,
    label: '',
    labelText: '',
    loadSupportedLanguages: _.noop,
    name: '',
    placeholder: '',
    supportedLanguages: new List(),
  };

  componentDidMount() {
    const {
      formName,
      isTenantMultiLanguageSupported,
      loadSupportedLanguages,
      supportedLanguages,
    } = this.props;

    if (isTenantMultiLanguageSupported && !supportedLanguages.size) {
      loadSupportedLanguages(formName);
    }
  }

  render() {
    const {
      isLoading,
      isTenantMultiLanguageSupported,
      label,
      labelText,
      name,
      placeholder,
      supportedLanguages,
    } = this.props;

    const optionLanguages = [];
    if (supportedLanguages && !supportedLanguages.isEmpty()) {
      supportedLanguages.forEach((usr, i) => {
        optionLanguages.push({
          key: i,
          text: `${usr.get('language')} (${usr.get('beCode')})`,
          value: usr.get('beCode'),
        });
      });
    }

    return isTenantMultiLanguageSupported ? (
      <>
        {label && <label htmlFor=''>{label}</label>}
        <Form.Field>
          <Field
            component={FormInputSelect}
            fluid
            label={labelText}
            loading={isLoading}
            name={name}
            options={optionLanguages}
            placeholder={placeholder}
          />
        </Form.Field>
      </>
    ) : null;
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
LanguageSelect = LanguageSelectController(LanguageSelect);

export default LanguageSelect;
