import React, { PureComponent } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import OnboardRedirectPageController from '../../controllers/OnboardRedirectPageController';

class OnboardRedirectPage extends PureComponent {
  static propTypes = {
    // from React Router
    params: PropTypes.shape({
      workflowID: PropTypes.string,
    }).isRequired,
    redirectToOnboardWorkflow: PropTypes.func,
    workflowName: PropTypes.string,
  };

  static defaultProps = {
    redirectToOnboardWorkflow: _.noop,
    workflowName: '',
  };

  componentDidMount() {
    const { params, redirectToOnboardWorkflow } = this.props;

    const workflowID = Number(params.workflowID);
    redirectToOnboardWorkflow(workflowID);
  }

  render() {
    const { workflowName } = this.props;
    return (
      <Loader active inline='centered'>
        <Helmet title={workflowName} />
      </Loader>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardRedirectPage = OnboardRedirectPageController(OnboardRedirectPage);

export default OnboardRedirectPage;
