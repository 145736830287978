import actionTypes from '../actions/actionTypes';
import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({});

const addLocations = (state, newLocations) => {
  newLocations.forEach((location) => {
    if (location?.get('id')) {
      const locationID = location.get('id');
      state = state.set(locationID, location);
    }
  });

  return state;
};

const locationsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_ROOT_LOCATIONS_SUCCESS:
      return addLocations(state, fromJS(action.rootLocations));

    case actionTypes.GET_CHILDREN_LOCATIONS_SUCCESS:
      return addLocations(state, fromJS(action.childrenLocations));

    case actionTypes.GET_DEFAULT_SELECTED_LOCATION_SUCCESS:
      return addLocations(state, fromJS(action.locations));

    case actionTypes.GET_PRODUCT_PERMISSION_GROUPS_SUCCESS:
      return addLocations(state, fromJS(action.locations));

    default:
      return state;
  }
};

export default locationsReducer;
