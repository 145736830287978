// util
import buildCompositeController from '../../../../../app/js/v1/utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const blockID = ownProps.blockData.get('id');

  if (blockID) {
    return {
      destroyOnUnmount: false,
      form: `TaskEditor.${blockID}`,
      initialValues: ownProps.blockData,
    };
  }

  throw new Error('blockData must have an id');
};

export default buildCompositeController(mapStateToProps);
