import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesPaper extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M31.334 64.214c-.46.043-.798.45-.755.91.04.462.45.8.91.757H64.6c.3.01.58-.15.73-.41.153-.26.153-.58 0-.84-.15-.26-.43-.41-.73-.41H31.334z' />
          <path d='M23.914 12.43c-.46 0-.834.372-.834.832v69.985c0 .46.373.833.834.833h48.114c.103.02.21.02.312 0 .112-.022.22-.066.313-.13.027-.016.053-.034.078-.053.02-.016.04-.034.06-.052.068-.06.12-.13.16-.208.01-.026.02-.052.027-.078.023-.05.04-.11.05-.16v-.16c.007-.05.007-.11 0-.16l.054-55.38c0-.108-.017-.21-.054-.31v-.03c-.016-.197-.1-.38-.236-.52L58.78 12.68c-.174-.17-.41-.255-.65-.236-.046-.003-.09-.003-.13 0l-34.08-.025zm.833 1.665l32.597.026v13.31c0 .46.373.84.833.84h13.227l-.052 54.156H24.748V14.1zM59.01 15.32l11.17 11.27H59.01V15.32z' />
          <path d='M31.412 24.327c-.298.014-.566.185-.703.45-.14.265-.13.583.03.834.16.26.44.4.74.39h21.53c.3.01.58-.15.73-.41.152-.26.152-.58 0-.84-.15-.26-.43-.414-.73-.41h-21.6zm0 48.115c-.298.013-.566.185-.703.45-.14.265-.13.583.03.834.16.25.44.396.74.382H64.6c.3.004.58-.154.73-.413.154-.26.154-.58 0-.84-.15-.26-.43-.418-.73-.413H31.49c-.026-.002-.052-.002-.078 0zm-.078-16.794c-.298.028-.558.213-.683.485-.12.27-.09.59.08.833.18.244.47.377.76.35h33.12c.3.003.58-.155.73-.414.16-.26.16-.58 0-.84-.15-.26-.43-.418-.73-.414H31.49c-.052-.004-.104-.004-.156 0zm.078-7.94c-.298.013-.566.185-.703.45-.14.265-.13.582.03.833.16.26.44.4.74.39H64.6c.3.01.58-.15.73-.41.154-.26.154-.58 0-.84-.15-.26-.43-.414-.73-.41H31.41zm-.078-8.124c-.298.028-.558.213-.683.485-.12.27-.09.58.08.83.18.24.47.37.76.35h33.12c.3 0 .58-.16.73-.42.16-.26.16-.58 0-.84-.15-.26-.43-.42-.73-.418H31.49c-.052-.005-.104-.005-.156 0zm0-7.81c-.298.027-.558.212-.683.484-.12.27-.09.59.08.833.18.25.47.38.76.35h33.12c.3.01.58-.15.73-.41.16-.26.16-.58 0-.84-.15-.26-.43-.42-.73-.41H31.337z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesPaper;
