import React, { useCallback, useEffect } from 'react';
import pt from 'prop-types';
import { Button, Form, Grid, Label, Select } from 'semantic-ui-react';
import { withFormik } from 'formik';
import { object, string } from 'yup';

import { intl } from 'esp-util-intl';
import ActivityRecipients, {
  ActivityTypes,
  isPersonToContact,
} from '../../../globals/ActivityRecipients';
import OnboardActivityTemplateTypes from '../../../globals/OnboardActivityTemplateTypes';
import BaristaIntentLookupV2 from '../../molecules/BaristaIntentLookup_v2';
import IconSelectModal from '../IconSelectModal';
import OnboardActivitiesCategory from '../../../globals/OnboardActivitiesCategory';

const propTypes = {
  changeCategory: pt.func,
  changeTemplateStatus: pt.func,
  copyTemplate: pt.func,
  dirty: pt.bool,
  duplicateTemplate: pt.func,
  errors: pt.shape({
    activity_recipient: pt.string,
    category: pt.string,
    deadline_days: pt.string,
    deadline_hours: pt.string,
    description: pt.string,
    enable_deadline: pt.string,
    intent: pt.string,
    person_to_contact: pt.string,
    private_notes: pt.string,
    title: pt.string,
    type: pt.string,
    web_link: pt.string,
  }),
  handleBlur: pt.func.isRequired,

  handleChange: pt.func.isRequired,

  handleSubmit: pt.func.isRequired,
  isLoading: pt.bool,
  isValid: pt.bool,
  newStatus: pt.string,
  resetForm: pt.func,
  setFieldValue: pt.func,
  setValues: pt.func,
  template: pt.shape({}),
  templateID: pt.string,
  touched: pt.shape({
    activity_recipient: pt.bool,
    category: pt.bool,
    deadline_days: pt.bool,
    deadline_hours: pt.bool,
    description: pt.bool,
    enable_deadline: pt.bool,
    intent: pt.bool,
    person_to_contact: pt.bool,
    private_notes: pt.bool,
    title: pt.bool,
    type: pt.bool,
    web_link: pt.bool,
  }),
  updateTemplateList: pt.func,
  values: pt.shape({
    activity_recipient: pt.string,
    category: pt.string,
    deadline_days: pt.number,
    deadline_hours: pt.number,
    description: pt.string,
    eid: pt.string,
    enable_deadline: pt.bool,
    intent: pt.string,
    person_to_contact: pt.string,
    private_notes: pt.string,
    status: pt.string,
    title: pt.string,
    type: pt.string,
    web_link: pt.string,
  }),
};

const CONVERSATION_TYPE = {
  key: 'CONVERSATION',
  text: 'Conversation',
  value: 'BARISTA_CONVERSATION',
};

const DEPARTING_EMPLOYEE_TYPE = {
  key: 'DEPARTING_EMPLOYEE',
  text: 'Departing Employee',
  value: 'DEPARTING_EMPLOYEE',
};
const activityRecipientsWithoutHire = ActivityRecipients.slice(1);
const activityTypesWithDeparting = [
  DEPARTING_EMPLOYEE_TYPE,
  ...activityRecipientsWithoutHire,
];
const OffboardingActivityTemplateDetail = ({
  changeCategory,
  changeTemplateStatus,
  copyTemplate,
  duplicateTemplate,
  dirty,
  handleChange,
  handleBlur,
  handleSubmit,
  isLoading,
  isValid,
  newStatus,
  setFieldValue,
  setValues,
  template,
  templateID,
  errors,
  values,
  touched,
  resetForm,
  updateTemplateList,
}) => {
  useEffect(() => {
    if (templateID === 'new') {
      resetForm();
    } else {
      setValues(template);
    }
  }, [resetForm, setValues, templateID, template]);

  useEffect(() => {
    if (newStatus) {
      setFieldValue('status', newStatus);
    }
  }, [newStatus, setFieldValue]);

  const handleChangeTemplateStatus = useCallback(async () => {
    const status = values.status === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE';
    if (!isLoading) {
      const newStatus = await changeTemplateStatus(templateID, status);
      updateTemplateList(templateID, newStatus);
    }
  }, [
    changeTemplateStatus,
    isLoading,
    values.status,
    templateID,
    updateTemplateList,
  ]);

  const handleCopyTemplate = useCallback(async () => {
    if (!isLoading) {
      const response = await copyTemplate(values);
      if (response.id) {
        duplicateTemplate(response);
      }
    }
  }, [copyTemplate, duplicateTemplate, isLoading, values]);

  const handleCategoryChange = useCallback(
    (icon) => {
      changeCategory(icon.name);
    },
    [changeCategory]
  );

  const handleChangeDropdown = useCallback(
    (e, { name, value }) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleCheckBox = useCallback(
    (e, { checked }) => {
      setFieldValue('enable_deadline', checked);
    },
    [setFieldValue]
  );

  const isConversationType = values.type === CONVERSATION_TYPE.value;
  const activityTypes = [...OnboardActivityTemplateTypes, CONVERSATION_TYPE];
  return (
    <Form>
      {templateID !== 'new' ? (
        <Form.Group className='button-actions' grouped>
          <Label
            color={values?.status === 'ACTIVE' ? 'green' : null}
            content={values?.status}
            style={{
              float: 'left',
              marginTop: '.5em',
            }}
          />
          <Button
            basic
            content={values?.status === 'ACTIVE' ? 'ARCHIVE' : 'REACTIVATE'}
            loading={isLoading}
            onClick={handleChangeTemplateStatus}
            type='button'
          />
          <Button
            basic
            content='DUPLICATE'
            loading={isLoading}
            onClick={handleCopyTemplate}
            type='button'
          />
          <Button
            basic
            content='SAVE'
            disabled={!(dirty && isValid)}
            loading={isLoading}
            onClick={handleSubmit}
            type='button'
          />
        </Form.Group>
      ) : (
        <Form.Group className='button-actions' grouped>
          <Button
            basic
            content='SAVE'
            disabled={!(dirty && isValid)}
            loading={isLoading}
            onClick={handleSubmit}
            type='button'
          />
        </Form.Group>
      )}
      <Grid divided>
        <Grid.Row>
          <Grid.Column width={10}>
            <Form.Input hidden name='category' type='hidden' />

            <Form.Field
              control={Select}
              error={Boolean(
                touched?.activity_recipient && errors?.activity_recipient
              )}
              label='Activity Recipient'
              name='activity_recipient'
              onChange={handleChangeDropdown}
              options={activityTypesWithDeparting}
              placeholder='Select one...'
              search
              value={values?.activity_recipient || ''}
            />

            <Form.Field
              control={Select}
              error={Boolean(touched?.type && errors?.type)}
              label='Activity Type'
              name='type'
              onChange={handleChangeDropdown}
              options={activityTypes}
              placeholder='Select one...'
              search
              value={values?.type || ''}
            />

            {isConversationType ? (
              <BaristaIntentLookupV2
                intl={intl}
                label={intl.formatMessage({ id: 'label.target_intent' })}
                name='intent'
                placeholder={intl.formatMessage({
                  id: 'label.choose_target_intent',
                })}
                required
                setFieldValue={setFieldValue}
                setValues={setValues}
                values={values}
              />
            ) : null}

            <Form.Input
              error={Boolean(touched?.title && errors.title)}
              label='Activity Title'
              name='title'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder='Title that will display on the activity card.'
              required
              value={values.title || ''}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            {/** DEV-16688 For conversation: no image selection available (home feed cards for ticket do not support this)  */}
            {!isConversationType ? (
              <IconSelectModal
                icons={OnboardActivitiesCategory}
                onItemSelect={handleCategoryChange}
                selectedItem={values?.category || ''}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Form.TextArea
        error={Boolean(touched?.private_notes && errors.private_notes)}
        label='Activity Private Notes'
        name='private_notes'
        onBlur={handleBlur}
        onChange={handleChange}
        rows={5}
        value={values.private_notes || ''}
      />
      <Form.TextArea
        error={Boolean(touched?.description && errors.description)}
        label='Activity Description'
        name='description'
        onBlur={handleBlur}
        onChange={handleChange}
        rows={5}
        value={values.description || ''}
      />
      <Form.Checkbox
        checked={values?.enable_deadline ? true : false}
        label='Enable deadline'
        name='enable_deadline'
        onChange={handleCheckBox}
      />
      {Boolean(values?.enable_deadline) && (
        <Form.Group widths={'equal'}>
          <Form.Input
            error={Boolean(touched?.deadline_days && errors?.deadline_days)}
            label={'Days'}
            name='deadline_days'
            onBlur={handleBlur}
            onChange={handleChange}
            type='number'
          />

          <Form.Input
            error={Boolean(touched?.deadline_hours && errors.deadline_hours)}
            label={'Hours'}
            name='deadline_hours'
            onBlur={handleBlur}
            onChange={handleChange}
            type='number'
          />
        </Form.Group>
      )}
      {isPersonToContact(values?.type) && (
        <Form.Field
          control={Select}
          error={Boolean(
            touched?.person_to_contact && errors.person_to_contact
          )}
          label='Person to contact'
          name='person_to_contact'
          onChange={handleChangeDropdown}
          options={ActivityRecipients}
          placeholder='Select one...'
          search
          value={values?.person_to_contact || ''}
        />
      )}
      {values?.type === ActivityTypes.WEB_LINK && (
        <Form.Input
          error={Boolean(touched?.web_link && errors.web_link)}
          label='Web Link'
          name='web_link'
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values?.web_link || ''}
        />
      )}
    </Form>
  );
};

OffboardingActivityTemplateDetail.propTypes = propTypes;

const OffboardingActivityTemplateDetailFormik = withFormik({
  displayName: 'OffboardingActivityTemplateDetail',
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.saveForm(values);
  },
  mapPropsToValues: () => ({}),
  validationSchema: object().shape({
    intent: string().when('type', (type) => {
      if (type === 'BARISTA_CONVERSATION') {
        return string().required('Required');
      }
      return string().notRequired().nullable();
    }),
    title: string().required('Required'),
    web_link: string().when('type', (type) => {
      if (type === 'WEB_LINK') {
        return string().required('Required');
      }
      return string().notRequired();
    }),
  }),
})(OffboardingActivityTemplateDetail);

export default OffboardingActivityTemplateDetailFormik;
