import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Waypoint } from 'react-waypoint';
import { intl } from 'esp-util-intl';

import { Comment, Dimmer, Loader, Message } from 'semantic-ui-react';
import { hasIn, noop, throttle } from 'lodash';
// Globals
import ChannelTypes from '../../globals/ChannelTypes';
// Utils
import endpointGenerator from '../../utils/endpointGenerator';
import WidgetUtils from '../../utils/WidgetUtils';
// Controllers
import ChatController from '../controllers/ChatController';
// Atoms
import ScrollArea from '../atoms/ScrollArea';
import EspMainPanel from '../atoms/EspMainPanel';
import ChatChannelHeader from '../atoms/ChatChannelHeader';
import EspMainPanelHeader from '../atoms/EspMainPanelHeader';

// Molecules
import ChatBaristaInput from '../molecules/ChatBaristaInput';
import ConversationFeed from '../molecules/ConversationFeed';
import UserNameContainer from '../molecules/UserNameContainer';
import EspAboutMe from '../molecules/EspAboutMe';
import ConversationSystemMessage from '../molecules/ConversationSystemMessage';

// Organisms
import CaseAddSubscribers from './CaseAddSubscribers';

// v2
import ChatInput from '../../../v2/components/functional/ChatInput';
import LaunchWorkflowButton from '../../../v2/components/functional/ChatInput/LaunchWorkflowButton';
import LaunchListPicker from '../../../v2/components/functional/ChatInput/LaunchListPicker/LaunchListPicker';

import ImpersonationBarWrapper from './ImpersonationBarWrapper';

const DROPDOWN_MAX_NUM_OF_ITEMS = 5;

class ChatRoom extends PureComponent {
  static propTypes = {
    IsChatInHomeFeed: PropTypes.bool,

    active: PropTypes.bool,

    caseTaskId: PropTypes.number,

    // passed directly. If passed then it's assumed this chat belongs to a case
    channelId: PropTypes.string,

    channelType: PropTypes.oneOf([
      ChannelTypes.DIRECT_CHANNEL,
      ChannelTypes.SCOPED_CHANNEL,
      ChannelTypes.SUPPORT_CHANNEL,
    ]).isRequired,

    connectWebSocket: PropTypes.func,

    currentUser: ImmutablePropTypes.map.isRequired,

    customBackButton: PropTypes.node,

    customChatTitle: PropTypes.string,

    defaultMessage: PropTypes.string,

    disableHeader: PropTypes.bool,

    errorMessage: PropTypes.string,

    getHelpLabelFromBackend: PropTypes.string,

    inModal: PropTypes.bool,

    isCaseMgmt: PropTypes.bool,

    // is this enable invoked in cases?
    isChatAlive: PropTypes.bool,
    isLoadedChannel: PropTypes.bool,
    isLoadingChannel: PropTypes.bool,
    isProhibitChatAutoScrollingEnabled: PropTypes.bool,
    isSubscriber: PropTypes.bool,
    //* A chat room can be active or only part of an empty state. *//
    isTask: PropTypes.bool,
    isTaskResolved: PropTypes.bool,
    loadConversation: PropTypes.func,
    loadMessages: PropTypes.func,
    messages: ImmutablePropTypes.list,
    onUserSelection: PropTypes.func,
    pagination: ImmutablePropTypes.map,
    selectedChannel: ImmutablePropTypes.map,
    selectedChannelId: PropTypes.string,
    sendApiActionResponse: PropTypes.func,
    sendData: PropTypes.func,
    shouldStartWithQrParams: PropTypes.bool,
    supportChannelIntent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    supportChannelStart: PropTypes.bool,
    usersEntities: ImmutablePropTypes.map,
    withUserId: PropTypes.number,
    wsStatus: ImmutablePropTypes.map,
  };

  static defaultProps = {
    IsChatInHomeFeed: false,

    active: false,
    caseTaskId: void 0,

    channelId: null,

    connectWebSocket: noop,

    customBackButton: null,

    customChatTitle: '',

    defaultMessage: '',

    disableHeader: false,

    errorMessage: null,

    getHelpLabelFromBackend: null,

    inModal: false,

    isCaseMgmt: false,

    isChatAlive: true,
    isLoadedChannel: false,
    isLoadingChannel: false,
    isProhibitChatAutoScrollingEnabled: false,
    isSubscriber: false,
    isTask: false,
    isTaskResolved: false,
    loadConversation: noop,
    loadMessages: noop,
    messages: Immutable.List(),
    onUserSelection: null,
    pagination: Immutable.Map(),
    selectedChannel: Immutable.Map(),
    selectedChannelId: '',
    sendApiActionResponse: noop,
    sendData: noop,
    shouldStartWithQrParams: false,
    supportChannelIntent: {},
    supportChannelStart: false,
    usersEntities: Immutable.Map(),
    withUserId: null,
    wsStatus: Immutable.Map(),
  };

  constructor(props) {
    super(props);
    this.childRef = createRef();
  }

  state = {
    initialized: false,
  };

  /**
   * Life cycle hooks
   */
  componentDidMount() {
    const { wsStatus, connectWebSocket } = this.props;
    if (wsStatus.get('open')) {
      this.initializeChannel();
    } else if (!wsStatus.get('connecting')) {
      connectWebSocket();
    }
  }

  /**
   * Checks if it can initialize channel
   * marks chats as read
   * and re-load from API on ws reconnection
   */
  componentDidUpdate(prevProps) {
    const {
      pagination,
      messages,
      wsStatus,
      selectedChannel,
      selectedChannelId,
      supportChannelStart,
      isLoadingChannel,
    } = this.props;
    const { initialized } = this.state;

    // Adjusting scroll position to the previous position before WayPoint loads more messages
    const prevPagination = prevProps.pagination;
    if (
      prevPagination.get('next') !== pagination.get('next') &&
      prevPagination.get('next')
    ) {
      const { target } = this.previousWayPointPosition;
      if (target) {
        const scroll =
          target.scrollHeight -
          this.previousWayPointPosition.previousScrollHeight;
        target.scrollTop = scroll;

        this.previousWayPointPosition.preventScrollToBottom = false;
      }
    }

    // Mark messages as read if we're rendering this
    // but only if there are new messages

    if (prevProps.messages.size < messages.size) {
      this.markAsRead();
      const lastMessage = messages?.get(messages.size - 1);
      const prevLastMessage = prevProps.messages?.get(
        prevProps.messages.size - 1
      );

      const isTheSameChannelAndLastMessagesAreDifferent =
        lastMessage?.get('channel') === selectedChannelId &&
        lastMessage?.get('id') !== prevLastMessage?.get('id');

      if (isTheSameChannelAndLastMessagesAreDifferent) {
        this.doScrollBottom();
      }
    }

    // if in the past it was not open and now it is
    // then it means we just reconnected. So let's refresh the feed

    if (!prevProps.wsStatus.get('open') && wsStatus.get('open')) {
      if (!initialized) {
        // initialize only if it hasn't initialized yet
        this.initializeChannel();
      }
      // otherwise if it has been reinitialized, load messages only if this is not a support channel
      // DEV-17273 checking the channel type against the channel object instead of component prop
      // as channel type might mutate during conversation (i.e. transferred to live agent)
      // so the hard condition on prop type might prevent this from loading.
      // Prevent loading messages if loading is in progress (we don't have the channel yet)
      else if (
        isLoadingChannel === false &&
        selectedChannel?.get('type') !== ChannelTypes.SUPPORT_CHANNEL
      ) {
        this.loadMessages(
          selectedChannel?.get('id'),
          selectedChannel?.get('type')
        );
      }
    }

    // DEV-9564
    // The filters loaded by the left-side panel may delete this channel if they reset all channels
    // Therefore if the channel has been initialized
    // and somehow we lost the channel eid, we re-initialize it
    if (
      initialized &&
      prevProps.selectedChannelId &&
      !selectedChannelId &&
      !supportChannelStart //DEV-14554 not reinit for Barista support
    ) {
      this.loadMessages();
    }
  }

  // Used to store WayPoint position data
  // We don't want to force re-denders so we don't use state for this.
  previousWayPointPosition = {
    preventScrollToBottom: false,
    previousScrollHeight: 0,
    target: null,
  };

  doScrollBottom = () => {
    const { isProhibitChatAutoScrollingEnabled } = this.props;
    if (!isProhibitChatAutoScrollingEnabled) {
      if (this.childRef.current) {
        this.childRef.current.scrollToBottom();
      }
    }
  };

  initializeChannel = () => {
    const { initialized } = this.state;
    if (!initialized) {
      this.loadMessages();
      this.setState({
        initialized: true,
      });

      this.markAsRead();
      this.doScrollBottom();
    }
  };

  loadMessages = (overrideChannelId, overrideChannelType) => {
    const {
      channelId,
      channelType,
      supportChannelIntent,
      supportChannelStart,
      withUserId,
      shouldStartWithQrParams,
      loadConversation,
    } = this.props;

    loadConversation({
      channelId: overrideChannelId || channelId,
      channelType: overrideChannelType || channelType,
      intent: supportChannelIntent,
      // DEV-17273 conversation is no longer regarded as barista support channel once transferred to live agent
      // where channel type becomes 'scoped'
      isSupportChannel: overrideChannelId ? false : supportChannelStart, // if it's a barista channel, this restarts the conversation. Is not wanted in DEV-17273
      shouldStartWithQrParams: shouldStartWithQrParams,
      withUserId: withUserId,
    });
  };

  markAsRead = throttle(() => {
    const {
      active,
      caseTaskId,
      inModal,
      isCaseMgmt,
      selectedChannel,
      sendData,
    } = this.props;
    const selectedChannelId = selectedChannel.get('id');

    // NOTE: On mobile devices, only mark messages read if the user is TRUE
    // active on a chat (no empty state active)
    const isTablet = window.innerWidth < 1024;
    let markItZero = false;
    if (active || !isTablet || inModal) {
      markItZero = true;
    } else {
      markItZero = false;
    }

    if (markItZero) {
      if (selectedChannelId) {
        // Make the ws call so the server knows we read them read
        let endPoint;

        if (caseTaskId && isCaseMgmt) {
          endPoint = endpointGenerator.genPath(
            'task.tasks.instance.markReadAll',
            {
              taskPK: caseTaskId,
            }
          );
        } else if (
          selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL
        ) {
          endPoint = endpointGenerator.genPath(
            'espChat.directChannels.instance.markRead',
            {
              channelID: selectedChannelId,
            }
          );
        } else {
          endPoint = endpointGenerator.genPath(
            'espChat.channels.instance.markRead',
            {
              channelID: selectedChannelId,
            }
          );
        }

        const wsFrame = {
          body: {}, // We need this empty body to make this a POST call as if were, in websockets
          request: endPoint,
        };
        sendData(wsFrame);
      }
    }
  }, 250);

  /**
   * Handlers and functions
   */
  handleOnUserSelection = (
    selection,
    userInputType = 'select',
    message = Immutable.Map()
  ) => {
    const {
      onUserSelection,
      sendApiActionResponse,
      selectedChannel,
      sendData,
    } = this.props;
    if (message.hasIn(['metadata', 'user_input', 'api_action_button'])) {
      if (onUserSelection) {
        onUserSelection(message);
      } else {
        // New method send selection via HTTP

        sendApiActionResponse(selection, message);
      }
    } else {
      // old method send via ws
      const what = {
        body: {
          channel_id: selectedChannel.get('id'),
          metadata: {
            [userInputType]: selection,
          },
          text: selection.label,
        },
        request: endpointGenerator.genPath('espChat.messages'),
      };

      sendData(what);
    }
  };

  handleTopWaypointEnter = (previousPosition) => {
    const { pagination, loadMessages, selectedChannel } = this.props;

    if (selectedChannel) {
      if (pagination.get('next')) {
        const loadPaginatedResults = true;
        loadMessages(
          selectedChannel.get('id'),
          selectedChannel.get('type'),
          loadPaginatedResults
        );

        // Saving scroll node and previous scroll height
        // To be used after the component updates
        const target =
          previousPosition &&
          previousPosition.event &&
          previousPosition.event.target;
        if (target) {
          this.previousWayPointPosition = {
            preventScrollToBottom: true,
            previousScrollHeight: target.scrollHeight,
            target: target,
          };
        }
      }
    }
  };

  /**
   * Feature only for direct messages
   * Return the user that is not the current user
   * =
   */
  getOtherParticipantInDirectConversation = () => {
    const { selectedChannel, currentUser, usersEntities } = this.props;
    const currentUserId = currentUser && currentUser.get('id');
    let otherParticipantId;

    if (
      !selectedChannel.get('participants') ||
      selectedChannel.get('participants').size === 1
    ) {
      otherParticipantId = currentUserId;
    }
    if (
      selectedChannel &&
      selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL
    ) {
      otherParticipantId = selectedChannel
        .get('participants')
        .find((id) => id !== currentUserId);
    }

    return (
      usersEntities.get(otherParticipantId) ||
      Immutable.Map({
        id: otherParticipantId,
      })
    );
  };

  checkShouldAutoFocus = () => {
    // Only disable autofocus for cordova or for smaller screens
    if (window.cordova) {
      return false;
    }

    if (hasIn(window, 'screen.width') && window.screen.width < 1024) {
      return false;
    }

    return true;
  };

  render() {
    const {
      caseTaskId,
      channelType,
      customBackButton,
      customChatTitle,
      currentUser,
      defaultMessage,
      disableHeader,
      IsChatInHomeFeed,
      isTaskResolved,
      isTask,
      errorMessage,
      inModal,
      isCaseMgmt,
      isChatAlive,
      isLoadedChannel,
      isLoadingChannel,
      messages,
      pagination,
      selectedChannel,
      supportChannelStart,
      wsStatus,
      isSubscriber,
      getHelpLabelFromBackend,
    } = this.props;
    const { initialized } = this.state;

    const otherParticipant = this.getOtherParticipantInDirectConversation();

    const isOtherUserDisabled =
      otherParticipant.get('is_active') === false &&
      selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL;

    // ws status
    const notWsConnected = !wsStatus.get('open');
    const isWebsocketsDead =
      notWsConnected && wsStatus.get('reconnection_attempts') >= 5;

    let disableChatInput = notWsConnected || isOtherUserDisabled; // makes ChatInput disabled but still visible

    // Pulling last message
    const lastMessage = messages.last();
    // const isLastMessageMine =   currentUser && lastMessage && currentUser.get('id') === lastMessage.get('user_id');
    let useBaristaInput = false;
    let blockChatInput = false;
    let inlineButtonActive = false;
    if (lastMessage && lastMessage.hasIn(['metadata', 'user_input'])) {
      const lastMessageInputType = lastMessage.getIn([
        'metadata',
        'user_input',
      ]);

      // for now only enable it in this three
      if (
        lastMessageInputType.get('reopen') ||
        lastMessageInputType.get('task_created') ||
        lastMessageInputType.get('dismiss') ||
        lastMessageInputType.get('confirm') ||
        (lastMessageInputType.get('select') &&
          lastMessageInputType.get('select').size > DROPDOWN_MAX_NUM_OF_ITEMS)
      ) {
        useBaristaInput = true;
      } else if (lastMessageInputType.get('api_action_button')) {
        const isHiddenForMe =
          lastMessageInputType.getIn([
            'api_action_button',
            'hide_from_user',
          ]) === currentUser.get('id');

        if (isHiddenForMe) {
          // false because we *don't* want to show the ChatBaristaInput that blocks the conversation
          useBaristaInput = false;
        } else {
          // Only show this when clicked is false
          useBaristaInput = !lastMessageInputType.getIn(
            ['api_action_button', 'clicked'],
            true
          );
        }

        if (
          lastMessageInputType.getIn([
            'api_action_button',
            'proposed_service_department',
            'id',
          ])
        ) {
          useBaristaInput = true;
          disableChatInput = false;
        }

        // Block chat UI (DEV-7843)
        if (
          lastMessageInputType.getIn(['api_action_button', 'close_channel'])
        ) {
          blockChatInput = false;
          useBaristaInput = false;
          disableChatInput = true;
        }
      } else if (lastMessageInputType.get('select')) {
        blockChatInput = true;
        inlineButtonActive = true;
      }
      if (
        lastMessage.hasIn(['metadata', 'user_input', 'dismiss']) &&
        !inModal
      ) {
        // Exception case: if it's a dimiss action, and this is not shown in a modal
        // Do not display barista input
        useBaristaInput = false;
      }
    }
    // DEV-12566 need to do this also for v2 metadata types
    if (
      lastMessage &&
      lastMessage.getIn(['metadata', 'default_options']) &&
      lastMessage.getIn(['metadata', 'default_options']).size <=
        DROPDOWN_MAX_NUM_OF_ITEMS
    ) {
      inlineButtonActive = true;
      blockChatInput = true;
    }

    let channelTitle;
    if (selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL) {
      channelTitle = <UserNameContainer userId={otherParticipant.get('id')} />;
    } else if (selectedChannel.get('type') === ChannelTypes.SCOPED_CHANNEL) {
      channelTitle = selectedChannel.get('name');

      // Truncate the title with Barista
      if (channelTitle && channelTitle.length > 48) {
        channelTitle = `${channelTitle.substring(0, 45)}...`;
      }
    } else if (supportChannelStart) {
      // I'm not destructuring the props to make it clearer that this backed text comes as props
      channelTitle =
        getHelpLabelFromBackend ||
        intl.formatMessage({
          id: 'label.navigation_barista_get_help',
        });
    }

    const isChannelLoadedWithoutErrors = isLoadedChannel && !errorMessage;

    const workflowRequestWidget =
      lastMessage && WidgetUtils.getWorkflowRequestWidget(lastMessage);
    const shouldDisplayWorkflowWidgetAtBottom =
      workflowRequestWidget && workflowRequestWidget.get('display_bottom');
    const shouldDisplayLaunchPickerButton =
      lastMessage &&
      selectedChannel.get('id') &&
      lastMessage.hasIn(['metadata', 'user_input', 'select_special']);

    const shouldUseChatInput =
      isChannelLoadedWithoutErrors &&
      !useBaristaInput &&
      !blockChatInput &&
      isChatAlive &&
      !shouldDisplayWorkflowWidgetAtBottom;
    // console.log('shouldUseChatInput?', shouldUseChatInput);

    return (
      <EspMainPanel className={inModal ? 'esp-chat modal' : 'esp-chat'}>
        {disableHeader ? null : (
          <EspMainPanelHeader
            customBackButton={customBackButton}
            noBack={inModal}
            title={
              customChatTitle || (
                <ChatChannelHeader
                  caseTaskId={caseTaskId}
                  isSubscriber={isSubscriber}
                  isTaskResolved={isTaskResolved}
                  title={channelTitle}
                />
              )
            }
          />
        )}

        <ImpersonationBarWrapper />

        <ScrollArea id='chat-area' ref={this.childRef}>
          {!isWebsocketsDead &&
          (!initialized || isLoadingChannel || !wsStatus.get('open')) ? (
            <Dimmer active inverted>
              <Loader size='large' />
            </Dimmer>
          ) : null}

          {errorMessage ? (
            <Message negative>
              <Message.Header>{'Failed to load channel'}</Message.Header>
              <p>{errorMessage}</p>
            </Message>
          ) : null}

          {isWebsocketsDead ? (
            <Message negative>
              <Message.Header>{'Connection Problems'}</Message.Header>
              <p>
                {
                  'We are currently unable to make a WebSocket connection for you to use this channel. This can be caused by a temporary loss of internet connection or you may be on a network that does not allow WebSocket connections.'
                }
              </p>
              <p>
                {
                  'If you feel that this was a temporary connection problem, you can refresh your browser. If we are able to reestablish a connection, this message will disappear.'
                }
              </p>
            </Message>
          ) : null}

          {isChannelLoadedWithoutErrors && pagination.get('next') ? (
            <Waypoint
              key={pagination.get('next')}
              onEnter={this.handleTopWaypointEnter}
            />
          ) : null}

          {pagination.get('next') === null &&
          selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL &&
          otherParticipant.get('about_me') ? (
            <EspAboutMe userId={otherParticipant.get('id')} />
          ) : null}

          {isChannelLoadedWithoutErrors &&
          pagination.get('next') === null &&
          selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL ? (
            <Comment.Group>
              <ConversationSystemMessage
                text={intl.formatMessage({
                  id: 'message.this_is_the_beggining_of_conversation_with',
                  values: {
                    name: `${otherParticipant.get(
                      'nickname',
                      ''
                    )} ${otherParticipant.get('last_name', '')}`,
                  },
                })}
              />
            </Comment.Group>
          ) : (
            void 0
          )}

          {isChannelLoadedWithoutErrors || !messages.isEmpty() ? (
            <ConversationFeed
              animated={false}
              barista={
                selectedChannel.get('type') === ChannelTypes.SUPPORT_CHANNEL
              }
              channelId={selectedChannel.get('id')}
              channelType={channelType}
              currentUser={currentUser}
              inModal={inModal}
              inlineButtonActive={inlineButtonActive}
              isCaseMgmt={isCaseMgmt}
              isScoped={
                selectedChannel.get('type') === ChannelTypes.SCOPED_CHANNEL
              }
              key={selectedChannel.get('id')}
              messages={messages}
              userDisabled={isOtherUserDisabled}
            />
          ) : null}
        </ScrollArea>

        {shouldUseChatInput ? (
          <ChatInput
            IsChatInHomeFeed={IsChatInHomeFeed}
            // eslint-disable-next-line jsx-a11y/no-autofocus -- Chat feature requires autofocus
            autoFocus={this.checkShouldAutoFocus()}
            baristaMessage={lastMessage}
            caseTaskId={caseTaskId}
            channelId={selectedChannel.get('id')}
            channelType={channelType}
            defaultMessage={defaultMessage}
            disabled={disableChatInput}
            isTask={isTask}
            isTaskResolved={isTaskResolved}
            markAsRead={this.markAsRead}
            onUserSelection={this.handleOnUserSelection}
            placeholder={
              notWsConnected
                ? intl.formatMessage({
                    id: 'label.connecting',
                  })
                : intl.formatMessage({
                    id: 'label.send_message',
                  })
            }
          />
        ) : null}

        {isChannelLoadedWithoutErrors &&
        useBaristaInput &&
        isChatAlive &&
        !shouldDisplayWorkflowWidgetAtBottom ? (
          <ChatBaristaInput
            baristaMessage={lastMessage}
            caseTaskId={caseTaskId}
            isCaseMgmt={isCaseMgmt}
            markAsRead={this.markAsRead}
            onUserSelection={this.handleOnUserSelection}
            supportChannelStart={supportChannelStart}
          />
        ) : null}

        {/* This is a widget that comes in a message, but needs to be rendered outisde the conversation feed */}
        {shouldDisplayWorkflowWidgetAtBottom ? (
          <LaunchWorkflowButton
            content={workflowRequestWidget.get('label')}
            inModal={inModal}
            workflowRequestId={workflowRequestWidget.get('workflow_request_id')}
          />
        ) : null}

        {shouldDisplayLaunchPickerButton && (
          <LaunchListPicker
            channelId={selectedChannel.get('id')}
            content={lastMessage.getIn([
              'metadata',
              'user_input',
              'trigger_label',
            ])}
            listItems={lastMessage.getIn([
              'metadata',
              'user_input',
              'select_special',
            ])}
            selectNoneLabel={lastMessage.getIn([
              'metadata',
              'user_input',
              'none_label',
            ])}
            timeStamp={lastMessage.get('sys_date_created')}
          />
        )}
        <CaseAddSubscribers caseTaskId={caseTaskId} />
      </EspMainPanel>
    );
  }
}

const ChatRoomTest = ChatRoom;

// eslint-disable-next-line no-class-assign -- DEV-1526
ChatRoom = ChatController(ChatRoom);

export { DROPDOWN_MAX_NUM_OF_ITEMS, ChatRoomTest };
export default ChatRoom;
