import buildCompositeController from '../../utils/buildCompositeController';
import directoryActions from '../../actions/directoryActions';
import directoryThunks from '../../actions/directoryThunks';
import getCurrentUser from '../../selectors/getCurrentUser';
import getDirectoryUserGroupsByLetter from '../../selectors/getDirectoryUserGroupsByLetter';

const mapStateToProps = (state) => {
  const directory = state.get('directory');
  const filterBy = directory.get('filterBy');
  const pagination = directory.getIn(['users', 'pagination']);

  const currentUser = getCurrentUser(state);
  const hideUnactiveUsers = true;
  const directoryUserGroupsByLetter = getDirectoryUserGroupsByLetter(
    state,
    hideUnactiveUsers
  );

  return {
    currentUser,
    directoryUserGroupsByLetter,
    filterBy,
    isLoading: directory.getIn(['users', 'isLoading']),
    pagination,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDirectoryUsers: (onUsers) => {
    dispatch(directoryThunks.getDirectoryUsers(false, onUsers));
  },

  getNextDirectoryUsers() {
    dispatch(directoryThunks.getNextDirectoryUsers());
  },

  resetDirectoryOptions() {
    dispatch(directoryActions.resetDirectoryOptions());
  },

  setFilterUsersBy(filterBy) {
    dispatch(directoryActions.setFilterUsersBy(filterBy));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
