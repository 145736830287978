const adminReportMetrics = {
  ACCURATE_CONTENT_BUT_ASKING_FOR_HELP: 'Accurate content but asking for help',
  AUTOMATED_INCIDENTS: 'Automated Incidents',
  AUTOMATED_RESOLUTIONS: 'Automated Resolutions',
  AUTOMATED_SERVICE_REQUESTS: 'Automated Service Requests',
  CC_INTERACTIONS: 'Chit Chat Interactions',
  HELPFUL_FAQS: 'Helpful FAQs',
  INCIDENTS: 'Incidents',
  NCC_INTERACTIONS: 'Non Chit Chat Interactions',
  NEEDS_CONTENT_NO_MATCH: 'Needs content - No match',
  NEEDS_CONTENT_NO_RESPONSE: 'Needs content - No response',
  NEW_EMPLOYEES: 'New Employees',
  NEW_INTENTS: 'New Intents',
  NOT_HELPFUL_FAQS: 'Not Helpful FAQs',
  NO_FEEDBACK_FAQS: 'No feedback received FAQs',
  PERCENTAGE_AUTOMATED_RESOLUTIONS: '% of Automated Resolutions',
  RETURNING_EMPLOYEES: 'Returning Employees',
  SERVICE_REQUESTS: 'Service Requests',
  TOTAL_EMPLOYEES: 'Total Employees',
  TOTAL_FAQS: 'Total FAQs',
  TOTAL_INTERACTIONS: 'Total Barista Interactions',
  UNIQUE_EMPLOYEES: 'Unique Employees',
  UNKNOWN_EMPLOYEE_PHRASES: 'Unknown Employee Phrases',
};

export default adminReportMetrics;
