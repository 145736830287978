import { UserAgentUtils } from 'esp-globals';

// Actions
import chatThunks from '../../actions/chatThunks';
import faqThunks from '../../actions/faqThunks';
import chatActions from '../../actions/chatActions';

// Selectors
import getBaristaId from '../../selectors/getBaristaId';
// V2 utility
import { openExternalUrlInNative } from '../../../v2/utils/externalDownloadHandler';
// v1 utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => ({
  baristaID: getBaristaId(state),
});

const mapDispatchToProps = (dispatch) => ({
  markImagesAsLoaded: (attachmentSrc) => {
    dispatch(chatActions.imageLoaded(attachmentSrc));
  },
  refreshDownloadLineURL: (faqData) => {
    // NOTE: for safari web only
    // Safari is blocking any call to window.open() which is made inside an async call.
    // The solution that I found to this problem is to call window.open before making an asnyc call and set the location when the promise resolves.
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    let windowReference;
    if (!window.cordova && UserAgentUtils.isIOS()) {
      windowReference = window.open(); // This must always be OUTSIDE of the async code
    }

    // Refresh the line to get a new signed url
    dispatch(faqThunks.loadFaqLine(faqData)).then((line) => {
      const newDownloadUrl = line.url;
      openExternalUrlInNative(newDownloadUrl, windowReference);
    });
  },

  refreshDownloadMessage: (commentId) => {
    // NOTE: for safari web only
    // Safari is blocking any call to window.open() which is made inside an async call.
    // The solution that I found to this problem is to call window.open before making an asnyc call and set the location when the promise resolves.
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    let windowReference;
    if (UserAgentUtils.isIOS()) {
      // for both cordova-ios and safari
      windowReference = window.open(); // This must always be OUTSIDE of the async code
    }

    // Refresh the message to get a new signed url
    dispatch(chatThunks.loadOneMessage(commentId)).then((message) => {
      const newDownloadUrl = message.metadata.url;

      openExternalUrlInNative(newDownloadUrl, windowReference);
    });
  },
});
export default buildCompositeController(mapStateToProps, mapDispatchToProps);
