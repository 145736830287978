import React from 'react';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Boundaries } from 'cascara-middleware';
import ScheduleSync from './ScheduleSync';

const propTypes = {
  LastSyncComponent: PropTypes.element,
  isAlwaysEnabled: PropTypes.bool,
  isEnabledScheduleSync: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSyncLoading: PropTypes.bool,
  maxHourLimit: PropTypes.string,
  minHourLimit: PropTypes.string,
  resetScheduleSync: PropTypes.func,
  scheduleSyncDescription: PropTypes.string,
  selectedDays: PropTypes.arrayOf(PropTypes.string),
  shouldDisplaySpaces: PropTypes.bool,
  spaces: PropTypes.shape({
    description: PropTypes.string,
    fieldName: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  timeOfDay: PropTypes.string,
  toggleSchedule: PropTypes.func,
  updateSchedule: PropTypes.func,
};

const ContentSync = ({
  isAlwaysEnabled,
  LastSyncComponent,
  maxHourLimit,
  minHourLimit,
  isSyncLoading,
  timeOfDay = '',
  selectedDays = [],
  scheduleSyncDescription,
  isLoading,
  toggleSchedule,
  updateSchedule,
  isEnabledScheduleSync,
  shouldDisplaySpaces,
  resetScheduleSync,
  spaces = {},
}) => {
  return (
    <Boundaries>
      <Segment attached loading={isSyncLoading}>
        {LastSyncComponent}
        <ScheduleSync
          isAlwaysEnabled={isAlwaysEnabled}
          isEnabledScheduleSync={isEnabledScheduleSync}
          isLoading={isLoading}
          maxHourLimit={maxHourLimit}
          minHourLimit={minHourLimit}
          resetScheduleSync={resetScheduleSync}
          scheduleSyncDescription={scheduleSyncDescription}
          selectedDays={selectedDays}
          shouldDisplaySpaces={shouldDisplaySpaces}
          spaces={spaces}
          timeOfDay={timeOfDay}
          toggleSchedule={toggleSchedule}
          updateSchedule={updateSchedule}
        />
      </Segment>
    </Boundaries>
  );
};

ContentSync.propTypes = propTypes;

export default ContentSync;
