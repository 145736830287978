import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Menu, Modal } from 'semantic-ui-react';
import { Boundaries } from 'cascara-middleware';

// Controllers
import TitleController from '../controllers/TitleController';

// Packages
import { intl } from 'esp-util-intl';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

import CSVCasesReportModalContent from '../../../v2/components/functional/CSVCasesReportModal';

const getIntlFromSecondaryName = (secondaryName) => {
  const intlId = `label.navigation_${secondaryName.replace(
    /-/g,
    '_'
  )}`.toLowerCase();
  return intl.formatMessage({ id: intlId });
};

let EspSectionTitle = class EspSectionTitle extends PureComponent {
  static propTypes = {
    cancelReport: PropTypes.func.isRequired,
    isLoadingReport: PropTypes.bool,
    jobID: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    secondaryName: PropTypes.string.isRequired,
    shopLabelFromBackend: PropTypes.string,
  };

  static defaultProps = {
    isLoadingReport: false,
    jobID: null,
    shopLabelFromBackend: null,
  };

  handleCloseModal = () => {
    const { cancelReport, isLoadingReport, jobID } = this.props;

    if (isLoadingReport && jobID) {
      cancelReport(jobID);
    }
  };

  render() {
    const { secondaryName, shopLabelFromBackend, location } = this.props;

    const getLabelFromBackend =
      secondaryName === 'shop' && shopLabelFromBackend;

    const label = getLabelFromBackend
      ? shopLabelFromBackend
      : getIntlFromSecondaryName(secondaryName);

    const isCaseFeed = location.pathname.includes(
      uiPathGenerator.genPath('app.casesFeed')
    );

    return (
      <Boundaries>
        <div className='esp-section-title'>
          <Menu borderless widths={isCaseFeed ? 2 : 16}>
            <Menu.Item>
              <div
                style={{
                  paddingLeft: '1em',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                {label}
              </div>
            </Menu.Item>

            {isCaseFeed && (
              <Menu.Item>
                <div
                  style={{
                    paddingLeft: '1em',
                    textAlign: 'left',
                  }}
                >
                  <Modal
                    as={Form}
                    className='scrolling'
                    closeIcon={
                      <Menu.Item className='close'>
                        {intl.formatMessage({ id: 'label.cancel' })}
                      </Menu.Item>
                    }
                    content={
                      <CSVCasesReportModalContent
                        title={intl.formatMessage({
                          id: 'label.export_csv_report',
                        })}
                      />
                    }
                    onClose={this.handleCloseModal}
                    size='tiny'
                    trigger={
                      <Button
                        basic
                        content={intl.formatMessage({ id: 'label.csv_report' })}
                        floated='right'
                      />
                    }
                  />
                </div>
              </Menu.Item>
            )}
          </Menu>
        </div>
      </Boundaries>
    );
  }
};

const EspSectionTitleTest = EspSectionTitle;

EspSectionTitle = TitleController(EspSectionTitle);
export { EspSectionTitleTest };
export default EspSectionTitle;
