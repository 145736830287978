import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { isNull, isUndefined } from 'lodash';
import { Button, Dimmer, Divider, Loader } from 'semantic-ui-react';
import { Nodes, Regexes } from 'esp-globals';

import bubbleRadiusHelper from './bubbleRadiusHelper';
import ChatMessageFile from './ChatMessageFile';
import Lightbox from '../Lightbox';

import convertBytes from '../../../utils/convertBytes';
import externalDownloadHandler from '../../../utils/externalDownloadHandler';
import FileSizes from '../../../../globals/FileSizes';
// import ImageDefaults         from '../../../../v1/globals/ImageDefaults';

const config = {
  maxAutoDisplay: FileSizes.MAX_IMG_DISPLAY,
  maxFileSize: FileSizes.MAX_FILE_UPLOAD,
};

const rule = (props) => {
  const { isFirstMessage, isLastMessage, isMe, theme } = props;

  return {
    ...bubbleRadiusHelper(isFirstMessage, isLastMessage, isMe, theme),
    backgroundColor: theme.colors.gray,
    display: 'block',
    lineHeight: 0,
    marginLeft: isMe ? 'auto' : 0,
    marginRight: !isMe ? 'auto' : 0,
    maxWidth: '100%',
    minHeight: '80px',
    overflow: 'hidden',
  };
};
const getAltText = (text, attachment) => {
  // variable to save the altText
  let alt;

  const isMDImage = !isNull(text?.match(Regexes[Nodes.markdownImage]));

  // Here we are finding the name of the image/attachment from the URL let's say you have an attachment value like
  // https://cdn.pixabay.com/photo/2020/09/13/04/13/coffee-5567269_960_720.jpg imageNameFromURl will be coffee-5567269_960_720.jpg
  const imageNameFromUrl = attachment
    ?.match(Regexes.findImageNameOnURL)?.[0]
    .split('/')
    .pop();

  // using the example above
  if (!isUndefined(imageNameFromUrl)) {
    const { altTextInMarkDown } = Regexes;
    // alt = coffee-5567269_960_720.jpg
    alt = imageNameFromUrl;

    // The first match will always be the text of the image with the square brackets '[IMAGE text ]'
    // If in the source there is a alt text (assuming it is a Markdown value) ![The text in here](), we are saving it on markdownAltText
    // To create a composed alt text using the imageNameFromUrl and the possible altText in the markdown
    const markdownAltText = text?.match(altTextInMarkDown)
      ? text?.match(altTextInMarkDown)[0]?.split(altTextInMarkDown)[1]
      : undefined;

    //then at split,  the second result will always be the text without the square brackets.
    // thankfully the backend pre-process the name of the file to avoid edge cases like a text with square brackets so
    // this implementation is completely safe :)

    // if there is a markdownAltText and it is a Markdown formatted image we concatenate the alt value (imageNameFromUrl) with the alternative text
    if (isMDImage && !isUndefined(markdownAltText)) {
      alt += ` ${markdownAltText}`;
    }
  }

  return alt ?? `No alternative text found from source`;
};
class ChatMessageImg extends PureComponent {
  static propTypes = {
    altText: PropTypes.string,
    attachment: PropTypes.string.isRequired,
    attachmentDimension: PropTypes.arrayOf(PropTypes.number),
    attachmentSize: PropTypes.number,
    className: PropTypes.string.isRequired,
    height: PropTypes.number,
    hideAvatar: PropTypes.bool,

    isFirstMessage: PropTypes.bool.isRequired,

    isLastMessage: PropTypes.bool.isRequired,

    isMe: PropTypes.bool.isRequired,

    onImageLoaded: PropTypes.func,
    width: PropTypes.number,
  };

  static defaultProps = {
    attachmentDimension: void 0,
    attachmentSize: 0,
    height: void 0,
    hideAvatar: false,
    onImageLoaded: void 0,
    width: void 0,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    imgAutoDisplay: this.props.attachmentSize <= config.maxAutoDisplay,
    imgError: false,
    imgLoaded: false,
    lightboxOpen: false,
  };

  handleImageDisplay = () => {
    this.setState({
      imgAutoDisplay: true,
    });
  };

  handleImageLoaded = () => {
    this.setState({
      imgLoaded: true,
    });
    const { attachment, onImageLoaded } = this.props;

    onImageLoaded && onImageLoaded(attachment);
  };

  handleImageError = () => {
    this.setState({
      imgError: true,
    });
  };

  handleLightboxToggle = () => {
    const { lightboxOpen } = this.state;

    this.setState({
      lightboxOpen: !lightboxOpen,
    });
  };

  render() {
    const {
      altText,
      attachment,
      attachmentSize,
      className,
      isFirstMessage,
      height,
      hideAvatar,
      isMe,
      isLastMessage,
      width,
    } = this.props;

    const placement = {
      isFirstMessage,
      isLastMessage,
      isMe,
    };

    const { imgAutoDisplay, imgError, imgLoaded, lightboxOpen } = this.state;

    // What is attachment: if the node type is markdown formatted image then attachment will be the URL of that image.
    // if not it is just going to be the text defined on ./ChatMessageBubble:450

    // What is altText: altText is the text defined on ./ChatMessageBubble:450 it is the source
    // we are using to extract a posible altText on getAltText

    const alt = getAltText(altText, attachment);

    const theImage = (
      <img
        alt={alt}
        height={height}
        onLoad={this.handleImageLoaded}
        src={attachment}
        style={{
          height: 'auto',
          maxHeight: '90vh', // Subtract the button heights
          maxWidth: '90vw',
          width: width ? 'auto' : '100%',
        }}
        width={width}
      />
    );

    const errorMsgStyle = imgError
      ? {
          padding: '0 0.75em 0 0',
        }
      : void 0;

    return (
      <div
        data-component='ChatMessageImg'
        style={{
          position: 'relative',
          transform: 'translate3D(0,0,0)',
        }}
      >
        {imgAutoDisplay ? (
          <Dimmer.Dimmable
            blurring={!imgError}
            className={className}
            dimmed={!imgLoaded}
            style={{
              cursor: imgError ? 'default' : 'wait',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          >
            <Dimmer active={!imgLoaded} style={errorMsgStyle}>
              {imgError ? (
                <em>{'There was an error loading your image.'}</em>
              ) : attachmentSize ? (
                <Loader>{convertBytes(attachmentSize)}</Loader>
              ) : (
                <Loader indeterminate />
              )}
            </Dimmer>

            {imgError ? (
              <div
                style={{
                  height: '100px',
                  maxWidth: '100%',
                  width: '380px',
                }}
              />
            ) : (
              <img
                alt={alt}
                data-testid='chat-message-img'
                height={height}
                onClick={this.handleLightboxToggle}
                onError={this.handleImageError}
                onKeyPress={this.handleLightboxToggle}
                onLoad={this.handleImageLoaded}
                src={attachment}
                style={{
                  cursor: 'zoom-in',
                  display: 'block',
                  height: 'auto',
                  // We need this or maxHeight crushes tall images. Width also might break SVG images though.
                  maxHeight: '50vh',
                  maxWidth: '100%',
                  width: width ? 'auto' : '100%',
                }}
                width={width}
              />
            )}

            {!imgError ? (
              <Lightbox onClick={this.handleLightboxToggle} open={lightboxOpen}>
                <div style={{ padding: '2em 1em' }}>
                  <Button
                    as='a'
                    basic
                    content='Download'
                    download
                    floated='left'
                    href={attachment}
                    inverted
                    onClick={externalDownloadHandler}
                    target='_blank'
                  />
                  <Button
                    basic
                    circular
                    floated='right'
                    icon='close'
                    inverted
                    onClick={this.handleLightboxToggle}
                  />
                  <Divider clearing hidden />
                  {theImage}
                </div>
              </Lightbox>
            ) : null}
          </Dimmer.Dimmable>
        ) : (
          <ChatMessageFile
            {...placement}
            attachment={attachment}
            attachmentSize={attachmentSize}
            hideAvatar={hideAvatar}
            isDownloadable={false}
            onClick={this.handleImageDisplay}
          />
        )}
      </div>
    );
  }
}

export { getAltText };
export default createComponent(rule, ChatMessageImg, [
  'altText',
  'attachment',
  'attachmentSize',
  'fileSize',
  'height',
  'hideAvatar',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'onImageLoaded',
  'width',
]);
