import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

// Molecule
import ValidatedPassword from '../../molecules/ValidatedPassword';

// Global
import TypeLogin, {
  TypeDefaults,
} from '../../../globals/blocks/TypeChangePassword';
import BodyClassName from '../../../../globals/BodyClassName';

class ChangePassword01 extends Component {
  static propTypes = {
    ...TypeLogin,
    attributeLabel: PropTypes.string,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
  };

  static defaultProps = {
    ...TypeDefaults,
    attributeLabel: '',
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
  };

  render() {
    const {
      attributeLabel,
      disableCaseCheck,
      disableConfirmation,
      disableNumberCheck,
      disableSpecialCheck,
      errors,
      errorsApi,
      id,
      label,
      labelConfirm,
      passwordLengthMinimum,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === attributeLabel
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    return (
      <BodyClassName className={template}>
        <div className='block' data-blocktype={type} data-template={template}>
          <ValidatedPassword
            disableCaseCheck={disableCaseCheck}
            disableConfirmation={disableConfirmation}
            disableNumberCheck={disableNumberCheck}
            disableSpecialCheck={disableSpecialCheck}
            formError={finalError}
            label={label}
            labelConfirm={labelConfirm}
            name={id}
            passwordLengthMinimum={passwordLengthMinimum}
          />
        </div>
      </BodyClassName>
    );
  }
}

export default ChangePassword01;
