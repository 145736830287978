import { createSelector } from 'reselect';
import getChildBundleProductIDs from './getChildBundleProductIDs';
import getProductsBy from './getProductsBy';

const getChildBundleProducts = (ids, products) => {
  if (ids && products) {
    const childProducts = ids.map((id) =>
      products.find((p) => {
        if (p) {
          return p.get('id') === id;
        }
        return null;
      })
    );
    return childProducts.filter((p) => p !== void 0);
  }
  return null;
};

const selector = createSelector(
  [getChildBundleProductIDs, getProductsBy],
  getChildBundleProducts
);

export default selector;
