import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

// globals
import EspUserPropShape from '../../globals/EspUserPropShape';
import { ImageDefaults } from 'esp-assets';
import UserImageTypes from '../../globals/UserImageTypes';

import AvatarDefault from '../../../v2/components/display/AvatarDefault';

const userDefaultShape = fromJS({
  id: 0,
  images: [],
  last_name: '',
  nickname: '',
});

class UserImage extends PureComponent {
  static propTypes = {
    /** render as other component **/
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** user loaded but it has an error **/
    error: PropTypes.bool,
    /** barista avoid to use 'to' property **/
    noAvatarLinks: PropTypes.bool,
    // We need to allow any object in this particular case
    style: PropTypes.shape({}),
    /** use small version as thumb **/
    thumb: PropTypes.bool,
    /** user images have these two types **/
    type: PropTypes.oneOf([UserImageTypes.PROFILE, UserImageTypes.BACKGROUND]),
    /** Whether image should be wrapperd with a NavLink to user's profile page **/
    useLink: PropTypes.bool,
    /** EspUser object, with at least these fields **/
    user: EspUserPropShape,
  };
  static defaultProps = {
    as: Image,
    error: false,
    noAvatarLinks: false,
    style: void 0,
    thumb: false,
    type: UserImageTypes.PROFILE,
    useLink: false,
    user: userDefaultShape,
  };

  render() {
    const {
      as,
      error,
      noAvatarLinks,
      type,
      thumb,
      useLink,
      user,
      style,
      ...props
    } = this.props;

    let id, img;

    if (user) {
      id = user.get('id');

      img =
        user.get('images') &&
        user.get('images').find((img) => img.get('type') === type);

      // DEV-12850 if using background and no type BACKGROUND found
      // then find the type DEF_BACKGROUND
      if (type === UserImageTypes.BACKGROUND && !img) {
        img =
          user.get('images') &&
          user
            .get('images')
            .find((img) => img.get('type') === UserImageTypes.DEF_BACKGROUND);
      }
    }

    const linktToDetail = id
      ? uiPathGenerator.genPath('app.directory.detail', {
          userID: id,
        })
      : void 0;

    let src;

    if (img) {
      // User loaded and it has images
      if (thumb) {
        src = img.get('thumbnail');
      } else {
        src = img.get('picture');
      }
    }
    // cases when no image found
    else if (type === UserImageTypes.BACKGROUND) {
      src = ImageDefaults.USER_BACKGROUND;
    } else if (user || error) {
      // user loaded but no image, only name OR this is an erroed user
      let nickname = '';
      let last_name = '';
      if (user) {
        nickname = user.get('nickname');
        last_name = user.get('last_name');
      }

      const firstInitial = nickname.charAt(0);
      const lastInitial = last_name.charAt(0);

      const initials = firstInitial + lastInitial;

      const avatarWithProps = (
        <AvatarDefault
          backgroundSeed={id}
          initials={initials}
          responsive
          to={linktToDetail}
        />
      );

      if (useLink) {
        src = (
          <NavLink style={style} to={linktToDetail}>
            {avatarWithProps}
          </NavLink>
        );
      } else {
        src = <Image style={style}>{avatarWithProps}</Image>;
      }
    } else {
      // user not loaded (loading state)
      src = ImageDefaults.LOADING;
    }

    let asAs; // Decide to use normal 'as' or render the 'as' as NavLink

    if (user && useLink && !noAvatarLinks && user.get('is_active') !== false) {
      asAs = (props) => (
        <this.props.as {...props} as={NavLink} to={linktToDetail} />
      );
    } else {
      asAs = as;
    }

    if (typeof src === 'object') {
      // Return the react component if it is the SVG
      return src;
    } else {
      return React.createElement(asAs, {
        src,
        style,
        ...props,
      });
    }
  }
}

export default UserImage;
