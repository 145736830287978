import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsSurvey extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M78 9H18c-4.418 0-8 3.582-8 8v46.5c0 4.418 3.582 8 8 8h34.27v-3H18c-2.76 0-5-2.24-5-5V23.59h70v39.9c0 2.76-2.24 5-5 5h-2v3h2c4.418 0 8-3.582 8-8V17c0-4.418-3.582-8-8-8zM17.69 19C16.204 19 15 17.796 15 16.31s1.204-2.69 2.69-2.69 2.69 1.204 2.69 2.69c.008.72-.272 1.41-.777 1.922-.506.51-1.194.798-1.913.798V19zm10.3 0c-1.486 0-2.69-1.204-2.69-2.69s1.204-2.69 2.69-2.69 2.69 1.204 2.69 2.69c.008.717-.27 1.407-.774 1.918-.503.51-1.19.8-1.906.802l-.01-.03zm10.3 0c-1.09-.008-2.065-.672-2.474-1.68-.408-1.01-.167-2.167.61-2.93.777-.762 1.937-.98 2.94-.555 1 .427 1.646 1.416 1.634 2.505 0 .715-.285 1.4-.79 1.906-.508.504-1.195.787-1.91.784l-.01-.03z' />
          <path d='M69.39 73.22l4.34-2.5c.578-.335.95-.937.993-1.604.042-.667-.252-1.31-.783-1.716L55.28 53.15c-.632-.48-1.488-.54-2.18-.154-.694.385-1.094 1.144-1.02 1.934l2.33 23.92c.066.687.482 1.292 1.1 1.6.62.307 1.352.273 1.94-.09l4.2-2.61 3.16 7.21c.24.554.718.97 1.3 1.13.173.047.35.07.53.07.416 0 .82-.13 1.16-.37l6.08-4.33c.473-.333.778-.854.838-1.43.06-.575-.133-1.147-.528-1.57l-4.8-5.24zm-2.74 10.93l-4.08-9.33-6.17 3.83-2.34-23.92 18.67 14.25-6.5 3.75 6.5 7.08-6.08 4.34zm-4-48h13.61c.552 0 1-.448 1-1s-.448-1-1-1H62.65c-.552 0-1 .448-1 1s.448 1 1 1zm13.63 9.87H62.66c-.552 0-1 .448-1 1s.448 1 1 1h13.62c.552 0 1-.448 1-1s-.448-1-1-1zM56.83 32.44l-3 3-1.53-1.53c-.397-.34-.988-.317-1.358.052-.37.37-.392.96-.052 1.358l2.24 2.24c.39.388 1.02.388 1.41 0l3.73-3.73c.34-.397.317-.988-.052-1.358-.37-.37-.96-.392-1.358-.052l-.03.02zm0 11.64l-3 3-1.53-1.53c-.397-.34-.988-.317-1.358.052-.37.37-.392.96-.052 1.358l2.24 2.24c.39.388 1.02.388 1.41 0l3.73-3.73c.34-.397.317-.988-.052-1.358-.37-.37-.96-.392-1.358-.052l-.03.02zM19 32.83h4.88v22.14H19zm6.99 5.01h4.88v17.13h-4.88zm6.99-1.64h4.88v18.78h-4.88z' />
        </g>
      </svg>
    );
  }
}

export default CardsSurvey;
