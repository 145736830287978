import React from 'react';
import { Modal } from 'semantic-ui-react';
import pt from 'prop-types';

const propTypes = {
  message: pt.oneOfType([pt.string, pt.node]),
  onClose: pt.func,
};

const SyncErrorMessage = ({ message, onClose }) => {
  if (!message) {
    return null;
  }

  return (
    <Modal
      actions={['OK']}
      centered
      content={message}
      header='Sync Warning'
      onClose={onClose}
      open={Boolean(message)}
      size='tiny'
      style={{
        height: 'auto',
        transform: 'translateX(0) translateY(-50%)',
      }}
    />
  );
};

SyncErrorMessage.propTypes = propTypes;

export default SyncErrorMessage;
