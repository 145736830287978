import React, { useCallback, useState } from 'react';
import FormSectionContainer from './FormSectionContainer';
import pt from 'prop-types';
import { Button, Form, Radio } from 'semantic-ui-react';
import LocaleSingleton from '../../utils/LocaleSingleton';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { intl } from 'esp-util-intl';
import ScheduleButtonStyle from '../../../globals/ScheduleButtonsStyle/ScheduleButtonsStyle.module.scss';
import 'moment/min/locales';
import { endTypes, onTypes, repeatTypes } from './AnnouncementManage';

const getOrdinalNumber = (number, userLanguage) => {
  return moment.localeData(userLanguage).ordinal(number);
};

const getLocalizedWeekdays = (userLanguage, formatValue = 'ddd') => {
  return Array.from({ length: 7 }, (_, index) => ({
    key: index,
    text: moment().locale(userLanguage).weekday(index).format('dddd'),
    value: moment()
      .locale(userLanguage)
      .weekday(index)
      .format(formatValue)
      .toLowerCase(),
  }));
};

const getLocalizedMonths = (userLanguage) => {
  return Array.from({ length: 12 }, (_, index) => ({
    key: index,
    text: moment().locale(userLanguage).month(index).format('MMMM'),
    value: index,
  }));
};

const getOrdinalNumbersOptions = (userLanguage) => [
  {
    key: '1st',
    text: getOrdinalNumber('1', userLanguage),
    value: 1,
  },
  {
    key: '2nd',
    text: getOrdinalNumber(2, userLanguage),
    value: 2,
  },
  {
    key: '3rd',
    text: getOrdinalNumber(3, userLanguage),
    value: 3,
  },
  {
    key: '4th',
    text: getOrdinalNumber(4, userLanguage),
    value: 4,
  },
  {
    key: 'last',
    text: intl.formatMessage({
      id: 'label.last',
    }),
    value: -1,
  },
];

const propTypes = {
  formValues: pt.shape({
    auto_expire_days: pt.oneOfType([pt.string, pt.number]),
    end_date: pt.string,
    end_type: pt.string,
    every: pt.oneOfType([pt.string, pt.number]),
    on_day: pt.number,
    on_ordinal_number: pt.string,
    on_week_day: pt.string,
    on_weekdays: pt.arrayOf(pt.string),
    repeat: pt.string.isRequired,
  }).isRequired,
  isDisabled: pt.bool,
  minDate: pt.string,
  onFormChange: pt.func,
  userLanguage: pt.string,
};

const AnnouncementRecurring = ({
  isDisabled,
  onFormChange,
  formValues,
  minDate,
  userLanguage,
}) => {
  const repeatOptions = [
    {
      key: repeatTypes.DAYLY,
      text: intl.formatMessage({
        id: 'label.daily',
      }),
      value: repeatTypes.DAYLY,
    },
    {
      key: repeatTypes.WEEKLY,
      text: intl.formatMessage({
        id: 'label.weekly',
      }),
      value: repeatTypes.WEEKLY,
    },
    {
      key: repeatTypes.MONTHLY,
      text: intl.formatMessage({
        id: 'label.monthly',
      }),
      value: repeatTypes.MONTHLY,
    },
    {
      key: repeatTypes.YEARLY,
      text: intl.formatMessage({
        id: 'label.yearly',
      }),
      value: repeatTypes.YEARLY,
    },
  ];

  const months = getLocalizedMonths(userLanguage);

  const [radioButtonValue, setRadioButtonValue] = useState(onTypes.DAY);

  const weekDaysOptions = getLocalizedWeekdays(userLanguage);

  const endOptions = [
    {
      key: endTypes.ON,
      text: intl.formatMessage({
        id: 'label.on_this_day',
      }),
      value: endTypes.ON,
    },
    {
      key: endTypes.AFTER,
      text: intl.formatMessage({
        id: 'label.after',
      }),
      value: endTypes.AFTER,
    },
    // TODO currently not supported
    // {
    //   key: endTypes.NEVER,
    //   text: intl.formatMessage({
    //     id: 'label.never',
    //   }),
    //   value: endTypes.NEVER,
    // },
  ];

  const [ordinalNumber] = getOrdinalNumbersOptions(userLanguage);
  const [, weekday] = weekDaysOptions;

  const handleRadioButtonChange = useCallback(
    (e, { value }) => {
      if (value === onTypes.ORDINAL_WEEK_DAY) {
        onFormChange('on_day', null);
        onFormChange('on_week_day', weekday.value);
        onFormChange('on_ordinal_number', ordinalNumber.value);
      } else {
        onFormChange('on_day', 1);
        onFormChange('on_ordinal_number', null);
        onFormChange('on_week_day', null);
      }

      onFormChange('on_type', value);
      setRadioButtonValue(value);
    },
    [onFormChange, ordinalNumber.value, weekday.value]
  );

  const createRangeArray = (lastNumber) => {
    if (lastNumber < 1) {
      return [];
    }
    const rangeArray = [];
    for (let i = 1; i <= lastNumber; i++) {
      rangeArray.push(i);
    }
    return rangeArray;
  };

  const generateDaysOfTheMonthOptions = () => {
    const daysOfTheMonthOptions = createRangeArray(31).map((day) => ({
      key: day,
      text: day,
      value: day,
    }));
    return daysOfTheMonthOptions;
  };

  const daysLabel = intl
    .formatMessage({
      id: 'label.day',
      values: {
        days: formValues.every,
      },
    })
    .replace(String(formValues.every), '');

  const getSuffix = () => {
    switch (formValues.repeat) {
      case repeatTypes.DAYLY:
        return daysLabel;
      case repeatTypes.WEEKLY:
        return intl
          .formatMessage({
            id: 'label.week',
            values: {
              weeks: formValues.every,
            },
          })
          .replace(String(formValues.every), '');
      case repeatTypes.MONTHLY:
        return intl
          .formatMessage({
            id: 'label.month',
            values: {
              months: formValues.every,
            },
          })
          .replace(String(formValues.every), '');
      case repeatTypes.YEARLY:
        return '';

      default:
        return daysLabel;
    }
  };

  const labels = {
    DATE: intl.formatMessage({
      id: 'label.date',
    }),
    DAY: intl
      .formatMessage({
        id: 'label.day',
        values: {
          days: 1,
        },
      })
      .replace('1', ''),
    END: intl.formatMessage({
      id: 'label.end_date',
    }),
    EVERY: intl.formatMessage({
      id: 'label.every_xtime_label',
    }),
    MONTH: intl
      .formatMessage({
        id: 'label.month',
        values: {
          months: 1,
        },
      })
      .replace('1', ''),
    OCURRENCES: intl.formatMessage({
      id: 'label.occurrences',
    }),
    ON: intl.formatMessage({
      id: 'label.on',
    }),
    REAPEAT: intl.formatMessage({
      id: 'label.repeat',
    }),
    SELECTED_PERIOD_SUFFIX: getSuffix(),
  };

  const handleWeekDayToggle = useCallback(
    (e, { value }) => {
      const lowerCaseDay = value.toLowerCase();
      const currentWeekDays = Array.isArray(formValues.on_weekdays)
        ? formValues.on_weekdays
        : [];
      const selectedDayIndex = currentWeekDays.indexOf(lowerCaseDay);

      if (selectedDayIndex > -1) {
        currentWeekDays.splice(selectedDayIndex, 1);
      } else {
        currentWeekDays.push(lowerCaseDay);
      }

      onFormChange('on_weekdays', currentWeekDays);
    },
    [formValues.on_weekdays, onFormChange]
  );

  const RecurringButtonGroup = () => {
    const LOCALIZED_WEEK_DAYS_STRINGS = [0, 1, 2, 3, 4, 5, 6].map((weekDay) =>
      moment().locale(LocaleSingleton.get()).weekday(weekDay).format('ddd')
    );
    const WEEK_DAYS_STRINGS = [0, 1, 2, 3, 4, 5, 6].map((weekDay) =>
      moment().weekday(weekDay).format('ddd')
    );

    const selectedDays = {};

    (Array.isArray(formValues.on_weekdays)
      ? formValues.on_weekdays
      : []
    ).forEach((day) => {
      selectedDays[day] = day;
    });

    const SIZE = 'small';

    const buttons = (size = 'small') =>
      LOCALIZED_WEEK_DAYS_STRINGS.map((day, index) => {
        const activeColor = !isEmpty(
          selectedDays[WEEK_DAYS_STRINGS[index].toLowerCase()]
        )
          ? 'grey'
          : void 0;
        return (
          <Button
            basic={!activeColor}
            color={activeColor}
            content={day}
            disabled={isDisabled}
            key={day}
            name={WEEK_DAYS_STRINGS[index]}
            onClick={handleWeekDayToggle}
            size={SIZE}
            type='button'
            value={WEEK_DAYS_STRINGS[index]}
          />
        );
      });

    return <div className={ScheduleButtonStyle._}>{buttons()}</div>;
  };

  const handleChange = useCallback(
    (e, { name, value }) => {
      switch (name) {
        case 'repeat':
          switch (value) {
            case repeatTypes.YEARLY: {
              onFormChange('every', moment().month());
              onFormChange('on_day', 1);
              onFormChange('on_weekdays', null);
              onFormChange('on_type', onTypes.DAY);
              break;
            }
            case repeatTypes.WEEKLY: {
              onFormChange('every', 1);
              onFormChange('on_weekdays', []);
              onFormChange('on_ordinal_number', null);
              onFormChange('on_week_day', null);
              onFormChange('on_month', null);
              onFormChange('on_day', null);
              onFormChange('on_type', null);
              break;
            }
            case repeatTypes.MONTHLY: {
              onFormChange('every', 1);
              onFormChange('on_day', 1);
              onFormChange('on_weekdays', []);
              onFormChange('on_weekdays', null);
              onFormChange('on_ordinal_number', null);
              onFormChange('on_month', null);
              onFormChange('on_type', onTypes.DAY);
              break;
            }
            default:
              onFormChange('every', 1);
              onFormChange('on_weekdays', []);
              onFormChange('on_weekdays', null);
              onFormChange('on_day', null);
              onFormChange('on_week_day', null);
              onFormChange('on_ordinal_number', null);
              onFormChange('on_month', null);
              onFormChange('on_type', null);
              break;
          }
          break;
        case 'end_type':
          if (value === endTypes.AFTER) {
            onFormChange('auto_expire_days', 10);
            onFormChange('end_date', '');
          }

          if (value === endTypes.ON) {
            onFormChange('end_date', '');
            onFormChange('auto_expire_days', 0);
          }

          break;
        default:
          break;
      }

      onFormChange(name, value);
    },
    [onFormChange]
  );

  const repeatSuffixStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    opacity: isDisabled ? 0.5 : '',
    paddingBottom: 10,
  };

  const capitalizeText = (text) => (
    <span style={{ textTransform: 'capitalize' }}>{text}</span>
  );

  return (
    <FormSectionContainer isDisabled={isDisabled}>
      <Form.Group>
        <Form.Select
          disabled={isDisabled}
          label={labels.REAPEAT}
          name='repeat'
          onChange={handleChange}
          options={repeatOptions}
          value={formValues.repeat}
        />
        {formValues.repeat !== repeatTypes.YEARLY && (
          <Form.Field>
            <label>{capitalizeText(labels.EVERY)}</label>
            <Form.Input
              disabled={isDisabled}
              max={99}
              min={1}
              name='every'
              onChange={handleChange}
              type='number'
              value={formValues.every}
            />
          </Form.Field>
        )}
        {formValues.repeat === repeatTypes.YEARLY && (
          <Form.Field>
            <label>{capitalizeText(labels.EVERY)}</label>
            <Form.Select
              name='every'
              onChange={handleChange}
              options={months}
              value={formValues.every}
            />
          </Form.Field>
        )}

        <span style={repeatSuffixStyle}>{labels.SELECTED_PERIOD_SUFFIX}</span>
      </Form.Group>
      <Form.Group>
        {formValues.repeat === repeatTypes.WEEKLY && <RecurringButtonGroup />}
      </Form.Group>

      {(formValues.repeat === repeatTypes.YEARLY ||
        formValues.repeat === repeatTypes.MONTHLY) && (
        <div>
          <h4>{labels.ON}</h4>

          <Form.Group>
            <Form.Field>
              <Radio
                checked={radioButtonValue === onTypes.DAY}
                name='radioGroup'
                onChange={handleRadioButtonChange}
                value={onTypes.DAY}
              />
            </Form.Field>
            <Form.Field>
              <label>
                {capitalizeText(
                  intl
                    .formatMessage({
                      id: 'label.day',
                      values: {
                        days: 1,
                      },
                    })
                    .replace('1', '')
                )}
              </label>
              <Form.Select
                disabled={radioButtonValue !== onTypes.DAY}
                name='on_day'
                onChange={handleChange}
                options={generateDaysOfTheMonthOptions()}
                value={formValues.on_day}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <Radio
                checked={radioButtonValue === onTypes.ORDINAL_WEEK_DAY}
                name='radioGroup'
                onChange={handleRadioButtonChange}
                value={onTypes.ORDINAL_WEEK_DAY}
              />
            </Form.Field>
            <Form.Field>
              <label>{''}</label>
              <Form.Select
                disabled={radioButtonValue !== onTypes.ORDINAL_WEEK_DAY}
                name='on_ordinal_number'
                onChange={handleChange}
                options={getOrdinalNumbersOptions(userLanguage)}
                value={formValues?.on_ordinal_number}
              />
            </Form.Field>
            <Form.Field>
              <label>{''}</label>
              <Form.Select
                disabled={radioButtonValue !== onTypes.ORDINAL_WEEK_DAY}
                name='on_week_day'
                onChange={handleChange}
                options={weekDaysOptions}
                value={formValues?.on_week_day}
              />
            </Form.Field>
          </Form.Group>
        </div>
      )}
      <Form.Group>
        <Form.Field>
          <label>{labels.END}</label>
          <Form.Select
            disabled={isDisabled}
            name='end_type'
            onChange={handleChange}
            options={endOptions}
            value={formValues?.end_type}
          />
        </Form.Field>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {formValues.end_type === endTypes.AFTER && (
            <Form.Field>
              <label> {labels.OCURRENCES}</label>
              <Form.Input
                disabled={isDisabled}
                max={99}
                min={1}
                name='auto_expire_days'
                onChange={handleChange}
                type='number'
                value={formValues.auto_expire_days}
              />
            </Form.Field>
          )}

          <Form.Field>
            <label>{labels.DATE}</label>
            <Form.Input
              disabled={isDisabled || !minDate}
              min={minDate}
              name='end_date'
              onChange={handleChange}
              type='datetime-local'
              value={formValues.end_date || ''}
            />
          </Form.Field>
        </div>
      </Form.Group>
    </FormSectionContainer>
  );
};

AnnouncementRecurring.propTypes = propTypes;

export { getLocalizedWeekdays, getLocalizedMonths, getOrdinalNumbersOptions };

export default AnnouncementRecurring;
