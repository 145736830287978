import React, { Component } from 'react';
import classNames from 'classnames';
// Globals
import TypeBackground, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBackground';
import BodyClassName from '../../../../globals/BodyClassName';

class Background1 extends Component {
  static propTypes = TypeBackground;
  static defaultProps = TypeDefaults;

  render() {
    const {
      image,
      scrim,
      scrimColor,
      scrimOpacity,
      template,
      type,
    } = this.props;

    return (
      <BodyClassName className={template}>
        <div
          className={classNames('block', {})}
          data-blocktype={type}
          data-template={template}
        >
          {scrim && (
            <div
              className={`scrim ${scrimColor}`}
              style={{
                opacity: scrimOpacity,
              }}
            />
          )}

          <img alt='' className='bg' src={image} />
        </div>
      </BodyClassName>
    );
  }
}

export default Background1;
