import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import TypeRadio, { TypeDefaults } from './TypeRadio';
import { Checkbox, Form } from 'semantic-ui-react';
import { curry, memoize } from 'lodash';

const rule = () => ({
  '> div': {
    '> span': {
      marginLeft: '30px',
    },
  },
  '> h2': {
    textAlign: 'center',
  },
  paddingLeft: '32px',
});
class Radio01 extends PureComponent {
  static propTypes = TypeRadio;
  static defaultProps = TypeDefaults;
  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    options: this.props.options,
  };

  handleClick = memoize(
    curry((id, e) => {
      const { options } = this.props;
      // Marking only selected option as checked
      const newOptions = options.map((option) => {
        option.checked = option.id === id;
        return option;
      });

      this.setState({
        options: newOptions,
      });
    })
  );

  render() {
    const { className, type, template } = this.props;

    const { options } = this.state;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        {options.map((option) => (
          <Form.Field key={option.id}>
            <Checkbox
              as={option.as}
              checked={option.checked}
              disabled={option.disabled}
              fitted={option.fitted}
              id={option.id}
              label={option.label}
              onClick={this.handleClick(option.id)}
              radio
              slider={option.slider}
              value={option.id}
            />
          </Form.Field>
        ))}
      </div>
    );
  }
}

export default createComponent(rule, Radio01, [
  'options',
  'template',
  'type',
  'id',
  'trigger',
]);
