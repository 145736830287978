import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureDisplayMounts extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0007',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M52.62 34.59h2.12l3.1 3.15c.948 1.018 2.373 1.44 3.722 1.103 1.35-.338 2.408-1.38 2.765-2.725.358-1.344-.044-2.775-1.047-3.738l-3.42-3.48v-9.23L63 16.45c1.006-.96 1.41-2.392 1.056-3.737-.355-1.345-1.413-2.39-2.76-2.73-1.35-.34-2.777.08-3.726 1.097L53.73 15h-7.58l-3.65-3.71c-.948-1.018-2.373-1.44-3.722-1.103-1.35.338-2.408 1.38-2.765 2.725-.358 1.344.044 2.775 1.047 3.738l3.21 3.26v8.69l-3.45 3.48c-1.006.96-1.41 2.392-1.056 3.737.355 1.345 1.413 2.39 2.76 2.73 1.35.34 2.777-.08 3.726-1.097l2.84-2.87h2.23l11.74 24.35-15.89 21.7c-6.22.52-11 2.82-11.3 5.62H59.8c-.33-2.72-4.81-5-10.75-5.56l14.7-20.09c.518-.7.614-1.628.25-2.42L52.62 34.59zm8.11-.53c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1c-.003-.267.102-.524.29-.714.187-.19.443-.296.71-.296v.01zm-.18-21.47c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1c-.003-.265.1-.52.286-.71.185-.19.44-.297.704-.3l.01.01zM39.42 35.9c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm.12-21.17c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogFurnitureDisplayMounts;
