import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  count: null,
  isLoading: false,
  list: [],
  searchTerm: '',
});

const topicsFaqsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.BARISTA_FAQS_TOPIC_START:
      return state
        .set('count', null)
        .set('isLoading', true)
        .set('list', fromJS([]));

    case actionTypes.BARISTA_FAQS_TOPIC_SUCCESS:
      return state
        .set('count', action.count)
        .set('isLoading', false)
        .set('list', fromJS(action.results));

    case actionTypes.BARISTA_FAQS_TOPIC_FAIL:
      return state
        .set('count', null)
        .set('isSearching', false)
        .set('isLoading', false);

    case actionTypes.BARISTA_FAQ_TOPIC_SEARCH_TERM:
      return state.set('searchTerm', action.searchTerm);

    default:
      return state;
  }
};

export default topicsFaqsReducer;
