import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Divider, Header, Segment } from 'semantic-ui-react';
import IntlField from '../IntlField';

const SKIP_FIELDS = [
  'id',
  'map',
  'template',
  'type',
  'inputValue',
  'inputType',
  'realUser',
  'isBarista',
  'user_id',
];

class IntlBlocks extends PureComponent {
  static propTypes = {
    blobKeyPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),

    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260  DEV-15289
    block: PropTypes.object.isRequired,

    template: PropTypes.string.isRequired,

    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    blobKeyPath: [],
  };
  renderField = (field, blobKeyPath) => {
    const [fieldName, defaultValue] = field;

    // Do not render a field if we are skipping a field
    if (SKIP_FIELDS.indexOf(fieldName) < 0) {
      return (
        <IntlField
          blobKeyPath={blobKeyPath}
          defaultValue={defaultValue}
          key={defaultValue + fieldName + blobKeyPath.join()}
          label={fieldName}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const { block, template, type, blobKeyPath } = this.props;

    const fields = Object.entries(block);

    // console.warn(fields);

    return (
      <>
        <Header
          attached='top'
          content={`Type: ${type}`}
          style={{
            borderRadius: '.5em .5em 0 0',
          }}
          subheader={{
            content: `Template: ${template}`,
            style: {
              display: 'inline',
              paddingLeft: '1em',
            },
          }}
        />
        <Segment attached='bottom'>
          <div className='ui form'>
            {fields.map((field) => {
              const [fieldName, fieldValue] = field;

              // we actually want to clone this array!
              const blobKeyPathClone = blobKeyPath.slice(0);
              blobKeyPathClone.push(fieldName);

              if (typeof fieldValue === 'string') {
                return this.renderField(field, blobKeyPathClone);
              }
              // first check for array
              else if (Array.isArray(fieldValue)) {
                if (SKIP_FIELDS.indexOf(fieldName) < 0) {
                  // skip excluded fields
                  return (
                    <Segment key={fieldName + blobKeyPathClone.join()}>
                      <div className='ui form'>
                        <h4>{fieldName}</h4>
                        {fieldValue.map((subField, index) => {
                          // internally we had to dig and render all the attributes of the array element
                          const indexFoolLint = index; // nobody tell me what to do
                          return (
                            <React.Fragment
                              key={`${blobKeyPathClone.join()}.${indexFoolLint}`}
                            >
                              <h5>{`[${index}]`}</h5>
                              {Object.entries(subField).map((fieldInArray) => {
                                const [fieldInArrayName] = fieldInArray;
                                // we actually want to clone this array!
                                const blobKeyPathCloneSub = blobKeyPathClone.slice(
                                  0
                                );
                                blobKeyPathCloneSub.push(index);
                                blobKeyPathCloneSub.push(fieldInArrayName);

                                return this.renderField(
                                  fieldInArray,
                                  blobKeyPathCloneSub,
                                  index
                                );
                              })}

                              <Divider />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Segment>
                  );
                }

                return null;
              } else if (typeof fieldValue === 'object') {
                if (SKIP_FIELDS.indexOf(fieldName) < 0) {
                  // skip excluded fields
                  return (
                    <Segment key={fieldName + blobKeyPathClone.join()}>
                      <h4>{fieldName}</h4>
                      {Object.entries(fieldValue).map((subField) => {
                        const [subFieldName] = subField;
                        // we actually want to clone this array!
                        const blobKeyPathCloneSub = blobKeyPathClone.slice(0);
                        blobKeyPathCloneSub.push(subFieldName);
                        return this.renderField(subField, blobKeyPathClone);
                      })}
                    </Segment>
                  );
                }
                return null;
              } else {
                // what else?
                return (
                  <p key={fieldName + fieldValue + blobKeyPathClone.join()}>
                    {`Not supported type in ${blobKeyPathClone.join()}`}
                  </p>
                );
              }
            })}
          </div>
        </Segment>
      </>
    );
  }
}

export default IntlBlocks;
