import React, { useContext } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { Field as FormikField } from 'formik';
import IntegrationSetupWizardFormikContext from '../../IntegrationSetupWizard/IntegrationSetupWizardFormikContext';

const MetadataURLField = () => {
  const { values, handleChange } = useContext(
    IntegrationSetupWizardFormikContext
  );

  return (
    <Form.Field>
      <label htmlFor='id_p_metadata_url'>{'IdP Metadata URL'}</label>
      <FormikField
        component={Input}
        id='id_p_metadata_url'
        name='id_p_metadata_url'
        onChange={handleChange}
        type='text'
        value={values?.id_p_metadata_url || ''}
      />
    </Form.Field>
  );
};

export default MetadataURLField;
