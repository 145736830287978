import { imagesResizeTools } from 'esp-globals';
import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';

const mapStateToProps = () => {
  return {
    allowDelete: false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteTeamImage(imageID, teamId) {},

  onAttachmentChanged(teamID, e) {
    e.preventDefault();

    const [file] = e.target.files,
      reader = new FileReader();

    reader.onloadend = (img) => {
      const image = new window.Image();

      image.onload = function () {
        const img = {
          id: teamID,
          picture_960: imagesResizeTools.createImg(this, 960),
        };

        const finalData = imagesResizeTools.convertFormDataServiceTeam(img);

        dispatch(caseMgmtThunks.addTeamImage(finalData)).then(() =>
          dispatch(caseMgmtThunks.loadServiceTeam(teamID))
        );
      };
      image.src = img.target.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
