import { createSelector } from 'reselect';
import groupPermissions from '../globals/groupPermissions';

const getGroupPermission = () => groupPermissions;

const getPermissionsWithNewHire = (groupPerms) =>
  Object.assign({}, groupPerms, {
    NEW_HIRE: 'NEW_HIRE',
  });

export default createSelector([getGroupPermission], getPermissionsWithNewHire);
