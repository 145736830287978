import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { noop, omit } from 'lodash';
import LocationSelect from './LocationSelect';

class LocationSelectField extends PureComponent {
  static propTypes = {
    /** Redux From input prop */
    input: PropTypes.shape({
      onChange: PropTypes.func,
    }).isRequired,
    //
    // Transforms location into value for Redux Form. Called with non null selectedLocation as argument.
    //
    mapLocationToValue: PropTypes.func,
    //
    // Called when user picks a non null location,
    // 'selectedLocation' and 'value' are passed as arguments,
    // where 'selectedLocation' is an Immutable.Map and 'value' equals mapLocationToValue(selectedLocation).
    //
    // (selectedLocation, value)=> {}
    //
    onSelectLocation: PropTypes.func,
    trigger: PropTypes.node,
  };

  static defaultProps = {
    mapLocationToValue(location) {
      return location.get('id');
    },
    onSelectLocation: noop,
    trigger: null,
  };

  handleSelectLocation = (selectedLocation) => {
    const { input, mapLocationToValue, onSelectLocation } = this.props;

    // value reported to Redux Form
    let value = null;

    if (selectedLocation) {
      value = mapLocationToValue(selectedLocation);
    }

    // report selection to Redux Form
    input.onChange(value);

    // report selection to parent component
    onSelectLocation(selectedLocation, value);
  };

  render() {
    // explicitly listing omitted props since 'propTypes' is removed for production builds
    const nonOwnProps = omit(this.props, [
      'input',
      'mapLocationToValue',
      'onSelectLocation',
    ]);

    return (
      <LocationSelect
        noRootPreselect
        onChange={this.handleSelectLocation}
        {...nonOwnProps}
      />
    );
  }
}

export default LocationSelectField;
