const api_common_v0_1_object_mapping = {
  count: 2,
  next: null,
  previous: null,
  results: [
    {
      app_label: 'barista',
      attributes: {
        active: 'readonly',
        add_date: 'readonly',
        article_body: 'readonly',
        article_id: 'readonly',
        article_language: 'readonly',
        article_opening: 'readonly',
        article_url: 'readonly',
        base_article_id: 'readonly',
        category: 'readonly',
        created: 'readonly',
        created_in_elc: 'readonly',
        disabled_by_user: 'readonly',
        eid: 'readonly',
        id: 'readonly',
        is_active: 'readonly',
        is_protected: 'readonly',
        kb_text: 'readonly',
        last_sync_time: 'readonly',
        meta: 'readonly',
        number: 'readonly',
        origin: 'readonly',
        published: 'readonly',
        retired: 'readonly',
        short_description: 'readonly',
        src_active_status: 'readonly',
        sys_created_by: 'readonly',
        sys_custom_fields: 'readonly',
        sys_date_created: 'readonly',
        sys_date_updated: 'readonly',
        sys_updated_by: 'readonly',
        updated: 'readonly',
      },
      fields_info: [
        {
          attribute: 'id',
          choices: null,
          ref_api_url: null,
          type: 'AutoField',
          uses_custom_filter: false,
        },
        {
          attribute: 'src_active_status',
          choices: null,
          ref_api_url: null,
          type: 'NullBooleanField',
          uses_custom_filter: false,
        },
        {
          attribute: 'last_sync_time',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'is_protected',
          choices: null,
          ref_api_url: null,
          type: 'NullBooleanField',
          uses_custom_filter: false,
        },
        {
          attribute: 'created_in_elc',
          choices: null,
          ref_api_url: null,
          type: 'NullBooleanField',
          uses_custom_filter: false,
        },
        {
          attribute: 'eid',
          choices: null,
          ref_api_url: null,
          type: 'UUIDField',
          uses_custom_filter: false,
        },
        {
          attribute: 'sys_updated_by',
          choices: null,
          ref_api_url: null,
          type: 'CharField',
          uses_custom_filter: false,
        },
        {
          attribute: 'sys_date_updated',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'sys_created_by',
          choices: null,
          ref_api_url: null,
          type: 'CharField',
          uses_custom_filter: false,
        },
        {
          attribute: 'sys_date_created',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'sys_custom_fields',
          choices: null,
          ref_api_url: null,
          type: 'EspCustomFields',
          uses_custom_filter: false,
        },
        {
          attribute: 'active',
          choices: null,
          ref_api_url: null,
          type: 'BooleanField',
          uses_custom_filter: false,
        },
        {
          attribute: 'disabled_by_user',
          choices: null,
          ref_api_url: null,
          type: 'NullBooleanField',
          uses_custom_filter: false,
        },
        {
          attribute: 'article_id',
          choices: null,
          ref_api_url: null,
          type: 'CharField',
          uses_custom_filter: false,
        },
        {
          attribute: 'base_article_id',
          choices: null,
          ref_api_url: null,
          type: 'CharField',
          uses_custom_filter: false,
        },
        {
          attribute: 'number',
          choices: null,
          ref_api_url: null,
          type: 'CharField',
          uses_custom_filter: false,
        },
        {
          attribute: 'short_description',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'category',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'is_active',
          choices: null,
          ref_api_url: null,
          type: 'NullBooleanField',
          uses_custom_filter: false,
        },
        {
          attribute: 'article_url',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'add_date',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'updated',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'created',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'published',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'retired',
          choices: null,
          ref_api_url: null,
          type: 'DateTimeField',
          uses_custom_filter: false,
        },
        {
          attribute: 'origin',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'meta',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'article_body',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'article_opening',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
        {
          attribute: 'kb_text',
          choices: null,
          ref_api_url: null,
          type: 'TextField',
          uses_custom_filter: false,
        },
      ],
      filters: [],
      methods: [],
      model: 'knowledgebasearticles',
      url: 'http://elc.esp/api/workflow/v0.1/object_mapping/269/',
      verbose_name: 'knowledge base articles',
    },
    {
      app_label: 'barista',
      attributes: {
        suggested_count: 'readonly',
      },
      fields_info: [
        {
          attribute: 'suggested_count',
          choices: null,
          ref_api_url: null,
          type: 'IntegerField',
          uses_custom_filter: false,
        },
      ],
      filters: [],
      methods: [],
      model: 'knowledgebasearticlesmetrics',
      url: 'http://elc.esp/api/workflow/v0.1/object_mapping/309/',
      verbose_name: 'knowledge base articles metrics',
    },
  ],
};

export default api_common_v0_1_object_mapping;
