import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';
// Controller
import TenantImageController from '../../controllers/TenantImageController';
// Util
import TenantImageTypes from '../../../utils/TenantImageTypes';
// Globals
import TypeLogo, { TypeDefaults } from '../../../globals/blocks/TypeLogo';
import { ImageDefaults } from 'esp-assets';

// ES6 Map
const imageDefaultsByType = new Map();
imageDefaultsByType.set(TenantImageTypes.LOGO, ImageDefaults.ESPRESSIVE_LOGO);

class Logo01 extends Component {
  static propTypes = {
    ...TypeLogo,
    images: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
    loadTenantImages: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    images: List(),
    isLoading: false,
    loadTenantImages: noop,
  };

  componentDidMount() {
    const { loadTenantImages, type } = this.props;
    // ToDo: this will load all the images, has to be fixed in T8714
    loadTenantImages(type);
  }

  handleImageError = () => {
    const forceReload = true;
    const { loadTenantImages, userId } = this.props;
    loadTenantImages(userId, forceReload);
  };

  render() {
    const { type, images, isLoading } = this.props;

    const img = images
      .reverse()
      .filter((img) => img.get('type') === type)
      .minBy((img) => img.get('ordering'));

    let src;

    if (img) {
      // tries to use the svg version first
      src = img.get('svg') ? img.get('svg') : img.get('image');
    }

    src = src ? src : imageDefaultsByType.get(TenantImageTypes.LOGO);

    const { template, text1, text2 } = this.props;

    return (
      <div
        className={classNames('block padding', {})}
        data-blocktype={type}
        data-template={template}
      >
        {text1 && <div className='text'>{text1}</div>}

        {isLoading ? ( // we should show this only when it is actually loading, and not on errors
          <img alt='' className='logo' src={ImageDefaults.LOADING} />
        ) : (
          <img
            alt=''
            className='logo'
            onError={this.handleImageError}
            onLoad={this.handleImageLoaded}
            src={src}
          />
        )}

        {text2 && <div className='text'>{text2}</div>}
      </div>
    );
  }
}

export const Logo01Test = Logo01;

export default TenantImageController(Logo01);
