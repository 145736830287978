import { useContext } from 'react';

import TenantContext from '../../context/tenant/tenantContext';

//
// Hook: useTenant
//
// Exposes the tenant's profile. *
const useTenant = () => {
  const tenantContext = useContext(TenantContext);

  if (!tenantContext) {
    throw new Error(
      'useTenant Hook can only be called inside TenantContext.Provider!'
    );
  }

  return tenantContext.tenant;
};

export default useTenant;
