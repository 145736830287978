import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesCases extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M60.06 15.5l-28 .06c-.105 0-.21.017-.31.05-1.223.453-1.946 1.716-1.72 3V70.2c-.046 1.196.53 2.33 1.52 3 .087.054.18.095.28.12l24.06 6.2c.17.065.35.1.53.1.316-.003.625-.097.89-.27.84-.653 1.315-1.668 1.28-2.73v-4.2h1.47c3.58-.027 6.466-2.94 6.46-6.52V22.01c0-3.576-2.884-6.483-6.46-6.51zm-11.8 2.25h3c.552 0 1 .448 1 1s-.448 1-1 1h-3c-.552 0-1-.448-1-1s.448-1 1-1zM31.71 70.93l.06-52.43 24.73 6.12.1 52.72-24.89-6.41zm32.6-9h-5.72v-37c.037-.71-.216-1.407-.7-1.93h6.42v38.93z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogAccessoriesCases;
