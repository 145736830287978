import buildCompositeController from '../../utils/buildCompositeController';
import { getCurrentUserId } from '../../selectors/getCurrentUser';

const mapStateToProps = (state) =>
  // no need to send the whole user
  ({
    userId: getCurrentUserId(state),
  });
const mapDispatchToProps = () => ({});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
