import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  editorActiveBlobPathKey: null,
  editorActiveFormTranslations: {},
  editorActiveKey: null,
  editorActiveKeyIntlResults: [],
  editorActiveProposedKey: null,
  editorIntlKeyIsLoading: false,
  editorIsKeyEditorOpen: false,
  editorIsSavigTranslations: false,
  editorSelectedTask: null,
  editorTaskError: '',
  editorTaskIsLoading: false,
  editorTasks: {}, // fuck redux form
});

const wfIntlReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.WF_INTL_LOAD_TASK_START: {
      return state.set('editorTaskIsLoading', true);
    }

    case actionTypes.WF_INTL_LOAD_TASK_SUCCESS: {
      return state
        .set('editorTaskIsLoading', false)
        .setIn(['editorTasks', action.taskId.toString()], fromJS(action.task));
    }

    case actionTypes.WF_INTL_LOAD_TASK_ERROR: {
      return state
        .set('editorTaskIsLoading', false)
        .set('editorTaskError', fromJS(action.error));
    }

    case actionTypes.WF_INTL_SELECT_TASK: {
      return state.set('editorSelectedTask', action.taskId);
    }

    case actionTypes.WF_INTL_LAUNCH_INTL_KEY_EDITOR: {
      return state
        .set('editorIsKeyEditorOpen', true)
        .set('editorActiveKey', action.intlKey)
        .set('editorActiveProposedKey', null)
        .set('editorActiveBlobPathKey', fromJS(action.blobKeyPath))
        .set('editorActiveKeyIntlResults', fromJS([]));
    }

    case actionTypes.WF_INTL_CLOSE_INTL_KEY_EDITOR: {
      return state
        .set('editorIsKeyEditorOpen', false)
        .set('editorActiveKey', null)
        .set('editorActiveProposedKey', null)
        .set('editorActiveBlobPathKey', null)
        .set('editorActiveFormTranslations', fromJS({}));
    }

    case actionTypes.WF_INTL_LOAD_KEY_SUCCESS: {
      let nextState = state;
      if (action.keyLocalizations) {
        nextState = nextState.setIn(
          ['editorActiveKeyIntlResults'],
          fromJS(action.keyLocalizations)
        );
      }

      nextState = nextState.set('editorIntlKeyIsLoading', false);
      return nextState;
    }

    case actionTypes.WF_INTL_LOAD_KEY_START: {
      return state.set('editorIntlKeyIsLoading', true);
    }

    case actionTypes.WF_INTL_LOAD_KEY_ERROR: {
      return state.set('editorIntlKeyIsLoading', false);
    }

    case actionTypes.WF_INTL_NEW_KEY_PROSPECT: {
      return state.set('editorActiveProposedKey', action.intlKey);
    }

    case actionTypes.WF_INTL_SAVE_TRANS_START: {
      return state.set('editorIsSavigTranslations', true);
    }

    case actionTypes.WF_INTL_SAVE_TRANS_SUCCESS: {
      let newState = state.set('editorIsSavigTranslations', false);

      if (action.translationBody) {
        const newTranslation = action.translationBody;
        newState = newState.updateIn(
          ['editorActiveKeyIntlResults'],
          (original = fromJS([])) => {
            const indexOfLanguage = original.findIndex(
              (t) => t.get('language') === newTranslation.language
            );
            if (indexOfLanguage > -1) {
              // if found
              return original.setIn([indexOfLanguage], fromJS(newTranslation));
            } else {
              // we push the new language translation
              return original.push(fromJS(newTranslation));
            }
          }
        );
      }

      return newState;
    }

    case actionTypes.WF_INTL_SAVE_TRANS_ERROR: {
      return state.set('editorIsSavigTranslations', false);
    }

    case actionTypes.WF_INTL_TRANSLATION_CHANGE: {
      const { lang, str } = action;

      if (lang) {
        return state.setIn(['editorActiveFormTranslations', lang], str);
      } else {
        // eslint-disable-next-line no-console -- debugging
        console.error(
          'Watch out, active translation form tried to change a translation but action didnt pass a language'
        );
        return state;
      }
    }

    default: {
      return state;
    }
  }
};

export default wfIntlReducer;
