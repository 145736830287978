const Nodes = {
  email: 'email',
  embedVideo: 'embedVideo',
  file: 'file',
  image: 'image',
  imageLink: 'imageLink',
  link: 'link',
  markdownImage: 'markdownImage',
  markdownLink: 'markdownLink',
  nativeVideo: 'nativeVideo',
  paragraph: 'paragraph',
  tag: 'tag',
};

export default Object.freeze(Nodes);
