import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
// Utils
import HelloActions from '../../utils/HelloActions';
// Global
import groupPermissions from '../../globals/groupPermissions';
// Selectors
import getTenantBranding from '../../selectors/getTenantBranding';
import getCurrentUser from '../../selectors/getCurrentUser';
import getTenantMenuConfiguration from '../../selectors/getTenantMenuConfiguration';
import getGroupPermissions from '../../selectors/getGroupPermissions';
import { isFeatureFlag } from 'esp-ui-flag';
import getTenant from '../../selectors/getTenant';

const DEFAULT_BRANDING = {
  PRIMARY: '#2db59e',
  SECONDARY: '#e59925',
};

const DEFAULT_CHAT_VERSION = 'chatv1';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const uiConfig = getTenantMenuConfiguration(state);
  const offlineMessage = uiConfig.get('offlineMessage') || false;
  const adminPerm = getGroupPermissions(state);
  const groupPerm = isEmpty(adminPerm) ? groupPermissions : adminPerm;
  const tenant = getTenant(state);

  // Add SERVICE_LEAD AND AGENT to the List
  groupPerm.SERVICE_LEAD = 'SERVICE_LEAD';
  groupPerm.SERVICE_AGENT = 'SERVICE_AGENT';

  const themeBranding = getTenantBranding(state);
  const theme = {
    color: {
      primary:
        themeBranding.get('tenantPrimaryColor') ?? DEFAULT_BRANDING.PRIMARY,
      secondary:
        themeBranding.get('tenantHeaderColor') ?? DEFAULT_BRANDING.SECONDARY,
    },
  };

  let chatVersion = DEFAULT_CHAT_VERSION;
  if (
    tenant &&
    tenant.getIn(['ui_config', 'default_chat_experience', 'version'])
  ) {
    chatVersion = tenant.getIn([
      'ui_config',
      'default_chat_experience',
      'version',
    ]);
  }
  return {
    chatVersion,
    configurationTenantMenu: uiConfig,
    currentUser,
    groupPermissions: groupPerm,
    isKBDashboardEnabled: isFeatureFlag('kbDashboardV2', state),
    offlineMessage: offlineMessage,
    theme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  /**
   * Actions to be performed when admin unmounts
   */
  doAdminGoodbyeActions() {
    HelloActions.adminGoodbye(dispatch);
  },

  /**
   * Actions to be performed when admin mounts
   */
  doAdminHelloActions() {
    HelloActions.adminHello(dispatch);
  },
});

const AdminPageController = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
export default AdminPageController;
