import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, TextArea } from 'semantic-ui-react';
import FileUploadForm from '../../../../molecules/FileUploadForm';
import TenantImagesTypes from '../../../../../utils/TenantImageTypes';
import { imageProps } from './BrandingContent';
import InputDescription from './InputDescription';

const propTypes = {
  allowedFileTypes: PropTypes.string,
  deleteImagesList: PropTypes.instanceOf(Set),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  setDeletedImage: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.shape(imageProps), PropTypes.string]),
};

const getTypeFromName = {
  applicationLogo: TenantImagesTypes.LOGO,
  backgroundImage: TenantImagesTypes.BACKGROUND,
  chatBotIcon: TenantImagesTypes.BOT,
  favicon: TenantImagesTypes.FAVICON,
  logInPageLogo: TenantImagesTypes.ALTERNATE_LOGO,
};

const InputFile = ({
  disabled = false,
  value = '',
  label,
  onChange,
  name,
  deleteImagesList,
  setDeletedImage,
  setFieldValue,
  description = '',
  allowedFileTypes = '.jpg',
}) => {
  const handleChange = useCallback(
    (newValue) => {
      setFieldValue(name, newValue);
      deleteImagesList.delete(getTypeFromName[name]);
      setFieldValue(
        'deleteImagesList',
        setDeletedImage(new Set(deleteImagesList))
      );
    },
    [name, setFieldValue, deleteImagesList, setDeletedImage]
  );

  const deleteImage = useCallback(() => {
    if (getTypeFromName[name] === TenantImagesTypes.BOT) {
      setFieldValue(name, '');
    } else {
      setFieldValue(name, null);
    }

    deleteImagesList.add(getTypeFromName[name]);
    setDeletedImage(new Set(deleteImagesList));
  }, [name, setFieldValue, deleteImagesList, setDeletedImage]);

  if (value && typeof value === 'object') {
    return (
      <Form.Field>
        <label htmlFor='trash_button'>{label}</label>
        <strong
          style={{
            paddingRight: '1em',
          }}
        >
          {value?.name}
        </strong>
        <Button
          aria-label={label}
          icon='trash'
          id='trash_button'
          negative
          onClick={deleteImage}
          type='button'
        />
        <InputDescription description={description} />
      </Form.Field>
    );
  }

  if (getTypeFromName[name] === TenantImagesTypes.BOT) {
    return (
      <Form.Field>
        <label htmlFor='textarea_bot' style={{ fontWeight: 'bold' }}>
          {label}
        </label>
        <TextArea
          aria-label={label}
          disabled={disabled}
          id={'textarea_bot'}
          name={name}
          onChange={onChange}
          rows={10}
          value={value}
        />
        <InputDescription description={description} />
      </Form.Field>
    );
  }

  return (
    <FileUploadForm
      accept={allowedFileTypes}
      aria-label={label}
      description={description}
      disabled={disabled}
      isForm={false}
      isSubmitAvailable={false}
      label={label}
      onFileChange={handleChange}
      placeholder='Select a file'
      uploadFieldId={name}
    />
  );
};

InputFile.propTypes = propTypes;

export default InputFile;
