import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesPens extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M34.893 20.53H33.08v-3.054c0-.524-.33-.873-.824-.873h-6.018c-.494 0-.824.35-.824.873v21.12c0 .435.33.87.742.87v41.977c0 .523.33.872.824.872h4.45c.496 0 .826-.35.826-.872V39.467c.412 0 .74-.436.74-.872v-16.32h.99v20.77c0 .524.33.873.825.873.495 0 .825-.35.825-.873V21.403c.083-.436-.33-.873-.742-.873zm-4.286 56.113h-2.802V39.555h2.802v37.088zM27.063 18.35h4.368V37.72h-4.367V18.35zm.742 62.22v-2.182h2.802v2.27h-2.802v-.088zM53.357 20.53h-1.813v-3.054c0-.524-.33-.873-.825-.873H44.7c-.494 0-.824.35-.824.873v21.12c0 .435.33.87.742.87v41.977c0 .523.33.872.824.872h4.45c.496 0 .825-.35.825-.872V39.467c.412 0 .74-.436.74-.872v-16.32h.99v20.77c0 .524.33.873.825.873.494 0 .824-.35.824-.873V21.403c.08-.436-.248-.873-.743-.873zm-4.204 56.113H46.35V39.555h2.803v37.088zM45.526 18.35h4.37V37.72h-4.37V18.35zm.825 62.22v-2.182h2.803v2.27H46.35v-.088zM71.903 20.53H70.09v-3.054c0-.524-.33-.873-.824-.873h-6.018c-.494 0-.824.35-.824.873v21.12c0 .435.33.87.742.87v41.977c0 .523.33.872.824.872h4.534c.494 0 .824-.35.824-.872V39.467c.412 0 .742-.436.742-.872v-16.32h.99v20.77c0 .524.33.873.823.873.495 0 .825-.35.825-.873V21.403c0-.436-.33-.873-.825-.873zM67.7 76.643h-2.803V39.555H67.7v37.088zM64.072 18.35h4.368V37.72h-4.367V18.35zm.824 62.22v-2.182H67.7v2.27h-2.803v-.088z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesPens;
