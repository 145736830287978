import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Grid, Segment } from 'semantic-ui-react';

// molecules
import OnboardingActivityTemplateList from '../../../molecules/OnboardingActivityTemplateList';
import OnboardingActivityTemplateDetail from '../../../molecules/OnboardingActivityTemplateDetail';

const propTypes = {
  params: PropTypes.shape({
    templateID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const defaultProps = {
  params: null,
};

class OnboardingActivitiesTemplates extends Component {
  render() {
    const {
      params: { templateID },
    } = this.props;

    return (
      <Segment as={Grid} attached clearing divided>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_onboarding_activities_templates',
          })}
        />

        <Grid.Column width={6}>
          <OnboardingActivityTemplateList templateID={templateID} />
        </Grid.Column>

        <Grid.Column width={10}>
          {templateID && (
            <OnboardingActivityTemplateDetail
              key={templateID}
              templateID={templateID}
            />
          )}
        </Grid.Column>
      </Segment>
    );
  }
}

OnboardingActivitiesTemplates.propTypes = propTypes;
OnboardingActivitiesTemplates.defaultProps = defaultProps;

export default OnboardingActivitiesTemplates;
