import React from 'react';
import classNames from 'classnames';
import TypeLogo from '../../../globals/blocks/TypeLogo'; // TypeDefaults,
import { ImageDefaults } from 'esp-assets';

const Espressive01 = ({
  logo = ImageDefaults.ESPRESSIVE_LOGO,
  text1 = 'Powered by',
}) => (
  <div
    className={classNames('block padding', {})}
    data-blocktype='esp-logo'
    data-template='espressive_1'
  >
    {text1 && <div className='text'>{text1}</div>}
    <img alt='' className='logo' src={logo} />
  </div>
);

Espressive01.propTypes = TypeLogo;

export default Espressive01;
