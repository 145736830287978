import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';

// Controller
import IntlFieldController from './IntlFieldController';

class IntlField extends PureComponent {
  static propTypes = {
    blobKeyPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),

    defaultValue: PropTypes.string,

    label: PropTypes.string.isRequired,

    launchIntlKeyEditor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    blobKeyPath: [],
    defaultValue: void 0,
  };

  handleLaunchIntlKeyEditor = () => {
    const { defaultValue, launchIntlKeyEditor, blobKeyPath } = this.props;
    launchIntlKeyEditor(defaultValue, blobKeyPath);
  };

  render() {
    const { defaultValue, label } = this.props;

    return (
      <Form.Field>
        <label htmlFor=''>{label}</label>
        <Input labelPosition='right'>
          <input type='text' value={defaultValue} />
          <Button
            className='label'
            icon='globe'
            onClick={this.handleLaunchIntlKeyEditor}
          />
        </Input>
      </Form.Field>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
IntlField = IntlFieldController(IntlField);
export default IntlField;
