import _ from 'lodash';

import PathParam from './PathParam';

class CompositePathPattern {
  constructor(patterns) {
    if (_.isEmpty(patterns)) {
      throw new Error('No pattern was passed, at least one must be provided.');
    }

    const arePatternsValid = patterns.every(
      (pattern) =>
        (_.isString(pattern) && !_.isEmpty(pattern)) ||
        pattern instanceof PathParam
    );

    if (!arePatternsValid) {
      throw new Error(
        'All patterns must be a non empty String or an instance of PathParam.'
      );
    }

    this.patterns = patterns;
  }

  /**
   * Returns a list of patterns that are instances of PathParam.
   */
  getPathParamPatterns() {
    return this.patterns.filter((pattern) => pattern instanceof PathParam);
  }

  evaluate() {
    const evaluatedPatterns = this.patterns.map((pattern) => {
      if (_.isString(pattern)) {
        return pattern;
      }

      // pattern is a PathParam
      return pattern.evaluate();
    });

    return evaluatedPatterns.join('/');
  }

  relaxedEvaluate() {
    const evaluatedPatterns = this.patterns.map((pattern) => {
      if (_.isString(pattern)) {
        return pattern;
      }

      // pattern is a PathParam
      return pattern.relaxedEvaluate();
    });

    return evaluatedPatterns.join('/');
  }
}

// sugar syntax around CompositePathPattern class
const createCompositePathPattern = (...patterns) =>
  new CompositePathPattern(patterns);

export { createCompositePathPattern };

export default CompositePathPattern;
