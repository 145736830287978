import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsFacilitiyOutage extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M68.69 17.32c-2.186-.016-4.11 1.434-4.698 3.54-.588 2.105.31 4.343 2.188 5.46l6.95-6.08c-.768-1.77-2.51-2.916-4.44-2.92zM24.07 72.87v.76c.243 1.046 1.176 1.787 2.25 1.787s2.007-.74 2.25-1.787h.09V67.1l-4.64 4.06.05 1.71zM39.6 31v-.2c-.262-2.195-2.12-3.85-4.33-3.86h-3.8v-.22c2.15-.847 3.405-3.09 3.004-5.366-.402-2.275-2.35-3.953-4.658-4.014-2.31-.06-4.343 1.513-4.863 3.764-.52 2.25.615 4.557 2.717 5.516v.29h-3.72c-2.318-.03-4.247 1.775-4.37 4.09v1l-.06 16.69c0 .917.743 1.66 1.66 1.66.917 0 1.66-.743 1.66-1.66l.22-13.21h1.18l-.27 12.31v15.4l4.64-4.06V48.68h1.45v9.17l4.64-4.06v-8l.22-10.2h1.16l.06 12.5v.1c.067.87.793 1.54 1.665 1.54s1.598-.67 1.665-1.54V48l.17-16.62-.04-.38zm19.01 0v2l6.95-6.08h-2.6c-2.307-.02-4.222 1.777-4.35 4.08zm-9.93-17.1c-1.005 0-1.82.815-1.82 1.82v27.51l3.63-3.18V15.72c0-1-.81-1.814-1.81-1.82zM74.55 27l-11.21 9.78-.22 10.11-3.85 12.24h3.83v14.5c.243 1.046 1.176 1.787 2.25 1.787s2.007-.74 2.25-1.787h.09V59.18h1.45V73.1c-.03.882.44 1.705 1.216 2.124.777.42 1.724.362 2.444-.148s1.088-1.385.95-2.256V59.21h3.7l-3.7-12.16v-1.26l.17-10.2h1.16l.06 12.5v.1c.067.87.793 1.54 1.665 1.54s1.598-.67 1.665-1.54V48l.17-16.62v-.51c-.24-2.113-1.967-3.747-4.09-3.87z' />
          <path d='M61.92 48.69l.18-10.86-3.56 3.12v7.74c0 .917.743 1.66 1.66 1.66.917 0 1.66-.743 1.66-1.66h.06zM46.87 76.83c.07.95.862 1.685 1.815 1.685s1.744-.735 1.815-1.685V48l-3.63 3.2v25.63zM34.81 61.76l-4.64 4.06v7.27c-.03.882.44 1.705 1.216 2.124.777.42 1.724.362 2.444-.148s1.088-1.385.95-2.256l.03-11.05z' />
          <path d='M55.98 4.74C32.084.34 9.144 16.142 4.74 40.037.336 63.933 16.135 86.875 40.03 91.282 63.925 95.69 86.87 79.894 91.28 56c2.12-11.48-.41-23.33-7.03-32.943C77.63 13.443 67.46 6.854 55.98 4.74zm-39 66.87c-12.255-16.3-9.882-39.29 5.444-52.745C37.75 5.41 60.854 6.037 75.43 20.3c-.108.054-.21.12-.3.2L16.98 71.61zm69.38-16.53c-2.72 14.626-13.522 26.43-27.85 30.433-14.328 4.003-29.684-.494-39.59-11.593l58.19-51.17c.11-.102.205-.22.28-.35 7.822 8.953 11.125 20.99 8.97 32.68z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsFacilitiyOutage;
