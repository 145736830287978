import { useCallback, useState } from 'react';
import { useSealedState } from 'reakit-utils/useSealedState';

const useToggleCB = (initialState = false) => {
  const sealed = useSealedState(initialState);
  const [state, setState] = useState(sealed);

  const handleToggleState = useCallback(() => setState(!state), [
    setState,
    state,
  ]);

  return [state, handleToggleState];
};

export default useToggleCB;
