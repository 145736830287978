import { AUTH_STATUS } from '../../constants';
import {
  FETCH_PROFILE_FAILURE,
  FETCH_PROFILE_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
} from './authActions';

function authReducer(state, action) {
  const { payload = {}, type } = action;

  switch (type) {
    case LOGIN_SUCCESS: {
      const { session, profile } = payload;

      return {
        ...state,
        ...profile,
        message: '',
        profile: {
          ...state.profile,
          ...profile.current_user,
        },
        session: {
          ...state.sesison,
          ...session,
        },
        status: AUTH_STATUS.AUTHENTICATED,
      };
    }
    case LOGIN_FAILURE:
      return {
        ...state,
        message: payload,
        profile: null,
        session: {
          ...state.sesison,
          accessToken: null,
        },
        status: AUTH_STATUS.AUTH_ERROR,
      };

    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        message: payload,
        profile: null,
      };

    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        ...payload,
        message: '',
        profile: {
          ...payload.current_user,
        },
      };

    default:
      return {
        ...state,
        message: '',
        profile: null,
        session: {
          ...state.sesison,
          accessToken: null,
        },
        status: AUTH_STATUS.UNAUTHENTICATED,
      };
  }
}

export default authReducer;
