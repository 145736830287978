import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { noop, truncate } from 'lodash';
import InputDescription from '../pages/admin/settings/CustomBrandingPage/InputDescription';

class FileUploadForm extends PureComponent {
  static propTypes = {
    /** Pass the accepted type of file ex: '.png, .gif' */
    accept: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    displayFullName: PropTypes.bool, // Display the entire name of the file to upload instead of the truncated version
    isForm: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSubmitAvailable: PropTypes.bool,
    isValid: PropTypes.func,
    label: PropTypes.string,
    onFileChange: PropTypes.func,
    onFileSubmit: PropTypes.func,
    uploadFieldId: PropTypes.string,
  };

  static defaultProps = {
    accept: null,
    description: null,
    disabled: false,
    displayFullName: false,
    isForm: true,
    isLoading: false,
    isSubmitAvailable: true,
    isValid: noop,
    label: null,
    onFileChange: noop,
    onFileSubmit: noop,
    uploadFieldId: 'file_upload',
  };

  state = {
    selectedFile: null,
  };

  handleFileChange = (e) => {
    const { onFileChange } = this.props;
    const firstIndex = 0;
    const file = e.target.files[firstIndex];
    this.setState({
      selectedFile: file,
    });
    onFileChange(file);
  };

  handleFileSubmit = (e) => {
    const { onFileSubmit } = this.props;
    const { selectedFile } = this.state;
    e.preventDefault();
    this.setState({
      selectedFile: null,
    });
    onFileSubmit(selectedFile);
  };

  handleFormFileChange = () => {
    const { isValid, disabled } = this.props;
    const { selectedFile } = this.state;
    isValid(selectedFile === null || disabled);
  };

  render() {
    const {
      accept,
      description,
      disabled,
      displayFullName,
      isLoading,
      isSubmitAvailable,
      label,
      uploadFieldId,
      isForm,
    } = this.props;

    const { selectedFile } = this.state;

    let filename = selectedFile ? selectedFile.name : '';

    // Avoid too long name which can break styling
    // 20 with '...' is the limit
    if (selectedFile && !displayFullName && selectedFile.name.length > 20) {
      filename = truncate(filename, {
        length: 20,
      });
    }

    const FileField = (
      <>
        {label && (
          <Form.Field>
            <label htmlFor={uploadFieldId}>{label}</label>
          </Form.Field>
        )}
        <Form.Group widths='equal'>
          <Form.Input className='file' onChange={this.handleFormFileChange}>
            <label htmlFor={uploadFieldId}>
              <Button as='div' icon='folder' />
              <span className={selectedFile ? 'filename' : 'placeholder'}>
                {selectedFile ? filename : 'Select a file...'}
              </span>

              <input
                accept={accept}
                className='hidden'
                id={uploadFieldId}
                onChange={this.handleFileChange}
                type='file'
              />
            </label>
          </Form.Input>
          {isSubmitAvailable && (
            <Form.Button
              basic={!selectedFile}
              color={selectedFile ? 'green' : void 0}
              content='Upload'
              disabled={selectedFile === null || disabled}
              loading={isLoading}
              onClick={this.handleFileSubmit}
            />
          )}
        </Form.Group>
        {description && (
          <Form.Field>
            <InputDescription description={description} />
          </Form.Field>
        )}
      </>
    );

    return isForm ? <Form>{FileField}</Form> : FileField;
  }
}

export default FileUploadForm;
