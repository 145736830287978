import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';

// molecules
import ScheduleGroupSegment from './ScheduleGroupSegment';

class ScheduleGroups extends PureComponent {
  static propTypes = {
    addNewGroup: PropTypes.func,
    fields: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]).isRequired, // ReduxForm 'field' prop coming from FieldArray
    formValues: ImmutablePropTypes.list, // values of the form where this is being used
    readOnly: PropTypes.bool,
    schedule: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    addNewGroup: _.noop,
    formValues: Immutable.List(),
    readOnly: false,
  };

  componentDidMount() {
    const { fields } = this.props;
    // If no group, create one
    if (fields.length === 0) {
      this.handleAddGroup();
    }
  }

  handleAddGroup = () => {
    const { schedule, addNewGroup, fields } = this.props;

    // Pushing the group after it has been added to the schedule
    addNewGroup(schedule.get('id'), (res) => {
      fields.push(fromJS(res));
    });
  };

  render() {
    const { fields, schedule, readOnly, formValues } = this.props;

    return (
      <div>
        {!readOnly ? (
          <Button onClick={this.handleAddGroup} type='button'>
            {'Add Group'} <Icon name='plus' />
          </Button>
        ) : null}

        {fields.map((member, index, fields) => {
          const indexLint = index;
          const formValuesFormIndex = formValues.get(index);

          return (
            <ScheduleGroupSegment
              formFields={fields}
              formIndex={index}
              formMember={member}
              formValues={formValuesFormIndex}
              key={indexLint}
              labelRecipients='Schedule'
              readOnly={readOnly}
              schedule={schedule}
            />
          );
        })}
      </div>
    );
  }
}

export default ScheduleGroups;
