import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import userEntitiesThunks from '../../actions/userEntitiesThunks';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import errorsActions from '../../actions/errorsActions';

const mapDispatchToProps = (dispatch) => ({
  createNewUserFromBEScratch(cb) {
    dispatch(userEntitiesThunks.createNewUserFromBEScratch()).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: ' The new user has been created',
          title: 'Success',
        })
      );
      cb(); // Set Next task
    });
  },

  forceResetError(cb) {
    dispatch(errorsActions.forceResetErrors());
    cb(); // Set prev task
  },
});

export default buildCompositeController(null, mapDispatchToProps);
