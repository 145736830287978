// Constants
import {
  APPROVAL_MANAGER_VALUE,
  CASE_BUTTONS,
  CASE_TYPE,
  EXTERNAL_APPROVAL_VALUE,
} from './caseConstants';
import {
  CaseTypes,
  SubtaskTypes,
  TypeToIcon,
} from '../../../../v1/globals/CaseTypeOptions';
import { every, forEach, isEmpty } from 'lodash';
// import {
//   getCheckedOption,
//   getCheckedOptions,
// } from '../../../../v1/selectors/getCaseFeed';
import {
  getApprovalSelectedServiceTeamId,
  getCasesSelectedServiceTeamId,
  getMyTeams,
} from '../../../../v1/selectors/getSelectedServiceTeam';

// Globals
import CaseStates from '../../../../v1/globals/CaseStates';
// Utils
import DateTools from '../../../../v1/utils/DateTools';
import EspFilters from 'esp-util-filters';
// Selectors
import { getCurrentUserId } from '../../../../v1/selectors/getCurrentUser';
// Selectors
import getLocationById from '../../../../v1/selectors/getLocationById';
import Immutable from 'immutable';
import moment from 'moment';
import { optionValues } from '../../../../v1/globals/caseFilterOptions';
// Reducer store
import store from '../../../../v1/stores/store';
import { ALL_SERVICE_TEAMS } from '../CaseFeedTeamPicker/utils';

const caseUtils = {
  createTaskTypeFilterOptions(taskTypes = []) {
    const newFilterOptions = [];
    forEach(taskTypes, (taskType) => {
      newFilterOptions.push({
        checked: true,
        text: taskType.label,
        value: taskType.type, // default state is checked for all
      });
    });

    return newFilterOptions;
  },

  /**
   * Returns an esp_filter string build from the redux state of casesFilter
   */
  getEspFilterFromApprovalFilter: () => {
    // redux state stuff
    const state = store.getState();
    const approvalsFilter = state.getIn(['approvalsFilter']);
    const userServiceTeamID = getApprovalSelectedServiceTeamId(state);
    const currentUserId = getCurrentUserId(state);

    // EspFilters API string
    const espFilter = new EspFilters();
    const otherQueryParameters = {}; // well, for this API some usages of esp filters require extra query params ¯\_(ツ)_/¯
    /**
     * "Show" section
     * Any value can be selected (true)
     */

    /**
     * Service team
     */
    if (userServiceTeamID && userServiceTeamID > -1) {
      espFilter.equalTo('service_team', userServiceTeamID);
      espFilter.equalTo('type', CASE_TYPE.APPROVAL_TEAM);
      espFilter.differentTo('author', currentUserId);
    }

    if (userServiceTeamID === APPROVAL_MANAGER_VALUE) {
      espFilter.equalTo('type', CASE_TYPE.APPROVAL_MANAGER);
    }

    if (userServiceTeamID === EXTERNAL_APPROVAL_VALUE) {
      espFilter.equalTo('owner.id', currentUserId);
      espFilter.equalTo('type', CASE_TYPE.EXTERNAL_APPROVALS);
    }

    // "State" filters
    const statusFiltersOptions = approvalsFilter.getIn([
      0,
      'filters',
      0,
      'options',
    ]);
    const filterStatusValues = {
      [CaseStates.PENDING_APPROVAL]: statusFiltersOptions.getIn([0, 'checked']),
      [CaseStates.APPROVED]: statusFiltersOptions.getIn([1, 'checked']),
      [CaseStates.REJECTED]: statusFiltersOptions.getIn([2, 'checked']),
    };

    const inClause = [];

    // if none is checked, this will actually be used to used every filter
    const allUnchecked = every(filterStatusValues, (value) => !value);

    if (filterStatusValues[CaseStates.PENDING_APPROVAL] || allUnchecked) {
      inClause.push(CaseStates.PENDING_APPROVAL);
      otherQueryParameters.show_pending_approval = true;
    }
    if (filterStatusValues[CaseStates.APPROVED]) {
      inClause.push(CaseStates.APPROVED);
      otherQueryParameters.show_approved = true;
    }
    if (filterStatusValues[CaseStates.REJECTED]) {
      inClause.push(CaseStates.REJECTED);
      otherQueryParameters.show_rejected = true;
    }

    espFilter.in('status.status', inClause); // e.g. status.status__IN=approved,rejected...

    /**
     * "Sort by" section
     * Only one value can be selected (true)
     */
    const sortByFilterOptions = approvalsFilter.getIn([
      1,
      'filters',
      0,
      'options',
    ]);
    const sortByCheckedOptions = sortByFilterOptions.find((op) =>
      op.get('checked')
    );
    const sortValueChecked =
      sortByCheckedOptions && sortByCheckedOptions.get('value');

    if (sortValueChecked) {
      otherQueryParameters.order_by = sortValueChecked;
    }

    /**
     * "Time period" section
     * Only one value can be selected (true)
     */
    const timePeriodOptions = approvalsFilter.getIn([
      2,
      'filters',
      0,
      'options',
    ]);
    const tpOptionChecked = timePeriodOptions.find((op) => op.get('checked'));
    const tpValueChecked = tpOptionChecked && tpOptionChecked.get('value');

    if (tpValueChecked !== 'all') {
      // do nothing
      const momento = moment().subtract(tpValueChecked, 'ms').toISOString();
      espFilter.greaterThan('sys_date_updated', momento); // always on update date according to fran
    }

    return {
      espFilter,
      otherQueryParameters,
    };
  },

  /**
   * Returns an esp_filter string build from the redux state of casesFilter
   */
  getEspFilterFromCasesFilter: () => {
    const getStatusExtraParameters = (status, parameters) => {
      switch (status) {
        case CaseStates.RESOLVED:
          return {
            ...parameters,
            show_resolved: true,
          };

        case CaseStates.CLOSED:
          return {
            ...parameters,
            show_closed: true,
          };

        case CaseStates.DISCARDED:
          return {
            ...parameters,
            show_discarded: true,
          };

        case CaseStates.CANCELLED_BY_AGENT:
          return {
            ...parameters,
            show_cancelled: true,
          };

        default:
          return parameters;
      }
    };

    // redux state stuff
    const state = store.getState();
    const currentUserId = getCurrentUserId(state);
    const userServiceTeamID = getCasesSelectedServiceTeamId(state);
    const myTeams = getMyTeams(state);
    // EspFilters API string
    const espFilter = new EspFilters();
    let otherQueryParameters = {}; // well, for this API some usages of esp filters require extra query params ¯\_(ツ)_/¯

    const getSelectedStates = (filters, parameters = {}) => {
      let inStatusClause = [];
      let inSubStatusClause = [];
      let isNullSubStatusSelected = false;
      const taskState = filters?.get('state'); // vanilla js

      if (!taskState || taskState === optionValues.ALL) {
        // default state
        inStatusClause = [CaseStates.OPEN];
      }

      let isAnySubStateSelected = false;

      if (taskState) {
        for (const [
          key,
          { value, substateRadio, substates },
        ] of taskState.entries()) {
          if (value) {
            inStatusClause.push(key);
            parameters = getStatusExtraParameters(key, parameters);

            const isSpecificSubStateSelected =
              substateRadio === optionValues.SPECIFIC_SUBSTATE;

            if (isSpecificSubStateSelected && substates) {
              const isAllSubstateSelected = substates.find(
                (substate) => substate === optionValues.ALL_SUBSTATES
              );
              if (isAllSubstateSelected) {
                isAnySubStateSelected = true;
              } else {
                inSubStatusClause = substates;
              }
            }
            if (
              (isSpecificSubStateSelected && !substates) ||
              (isSpecificSubStateSelected && substates?.length === 0)
            ) {
              isAnySubStateSelected = true;
            }
            if (substateRadio === optionValues.WITHOUT_SUBSTATE) {
              isNullSubStatusSelected = true;
            }
          }
        }
      }

      if (inStatusClause?.length > 0) {
        espFilter.in('status.status', inStatusClause);
      }

      if (isAnySubStateSelected) {
        espFilter.notNull('sub_status.sub_status');
      }

      if (inSubStatusClause.length > 0) {
        espFilter.in('sub_status.sub_status', inSubStatusClause);
      }
      if (isNullSubStatusSelected) {
        espFilter.isNull('sub_status.sub_status');
      }

      return {
        parameters,
      };
    };

    const getAssignedTo = (filters) => {
      const assignedToValue = filters?.get('assignedTo'); // vanilla js map

      if (assignedToValue && Array.isArray(assignedToValue)) {
        assignedToValue.forEach(({ userID }, index) => {
          if (index) {
            espFilter.or().equalTo('owner', userID);
          } else {
            espFilter.equalTo('owner', userID);
          }
        });
      } else {
        switch (assignedToValue) {
          case optionValues.ME:
            espFilter.equalTo('owner', currentUserId);
            break;
          case optionValues.UNASSIGNED:
            espFilter.isNull('owner');
            break;

          default:
            break;
        }
      }
    };

    const requestedBy = (filters) => {
      const requestedByValue = filters?.get('requestedBy'); // vanilla js map

      if (requestedByValue && Array.isArray(requestedByValue)) {
        requestedByValue.forEach(({ userID }, index) => {
          if (index) {
            espFilter.or().equalTo('author', userID);
          } else {
            espFilter.equalTo('author', userID);
          }
        });
      } else {
        switch (requestedByValue) {
          case optionValues.ME:
            espFilter.equalTo('author', currentUserId);
            break;

          default:
            break;
        }
      }
    };

    const getTypes = () => {
      const isSpecificTypeSelected =
        selectedFilters.get('taskType') &&
        Array.isArray(selectedFilters.get('taskType'));

      if (isSpecificTypeSelected) {
        espFilter.in('type', selectedFilters.get('taskType')); // e.g. type__IN=task,incident
      }
    };

    const getLocation = (filters) => {
      const locationValue = filters?.get('location');

      const isLocationSelected =
        locationValue &&
        Array.isArray(
          selectedFilters.get('location')?.ancestry?.ancestry_id_list
        );

      if (isLocationSelected) {
        const locationsData = [];
        selectedFilters
          .get('location')
          ?.ancestry?.ancestry_id_list.forEach((id) => {
            const locationData = getLocationById(id)(state);

            if (!isEmpty(locationData)) {
              locationsData.push(locationData);
            }
          });

        locationsData.forEach((location) => {
          const ancestryList = location.getIn(['ancestry', 'ancestry_id_list']);
          if (ancestryList && Immutable.List.isList(ancestryList)) {
            ancestryList.forEach((locationId, index) => {
              if (index) {
                espFilter
                  .or()
                  .containsInteger(
                    'location.ancestry.ancestry_id_list',
                    locationId
                  );
              } else {
                espFilter.containsInteger(
                  'location.ancestry.ancestry_id_list',
                  locationId
                );
              }
            });
          }
        });
      }
    };

    const getSortBy = (filters) => {
      const sortBy = filters?.get('sortBy');

      const parameters = {
        order_by: `-${optionValues.LAST_UPDATED}`,
      };

      // when ortBy?.order is set but not the type
      if (!sortBy?.type && sortBy?.order === optionValues.DESCENDING) {
        parameters.order_by = `-${optionValues.LAST_UPDATED}`;
      }
      if (!sortBy?.type && sortBy?.order === optionValues.ASCENDING) {
        parameters.order_by = optionValues.LAST_UPDATED;
      }
      // when LAST_UPDATED is selected but the order is empty
      if (sortBy?.type === optionValues.LAST_UPDATED && !sortBy?.order) {
        parameters.order_by = `-${optionValues.LAST_UPDATED}`;
      }
      // when DATE_CREATED is selected but the order  is not
      if (sortBy?.type === optionValues.DATE_CREATED && !sortBy?.order) {
        parameters.order_by = `-${optionValues.DATE_CREATED}`;
      }

      if (
        sortBy?.type === optionValues.LAST_UPDATED &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        parameters.order_by = optionValues.LAST_UPDATED;
      }
      if (
        sortBy?.type === optionValues.LAST_UPDATED &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        parameters.order_by = `-${optionValues.LAST_UPDATED}`;
      }
      if (
        sortBy?.type === optionValues.DATE_CREATED &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        parameters.order_by = optionValues.DATE_CREATED;
      }
      if (
        sortBy?.type === optionValues.DATE_CREATED &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        parameters.order_by = `-${optionValues.DATE_CREATED}`;
      }

      if (sortBy?.type === optionValues.ALPHABETICAL && !sortBy?.order) {
        parameters.order_by = optionValues.ASSIGNED;
      }
      if (sortBy?.type === optionValues.ASSIGNED && !sortBy?.order) {
        parameters.order_by = optionValues.ASSIGNED;
      }
      if (sortBy?.type === optionValues.AUTHOR && !sortBy?.order) {
        parameters.order_by = optionValues.AUTHOR;
      }
      if (sortBy?.type === optionValues.LOCATION_NAME && !sortBy?.order) {
        parameters.order_by = optionValues.LOCATION_NAME;
      }

      if (
        sortBy?.type === optionValues.ALPHABETICAL &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        parameters.order_by = optionValues.ALPHABETICAL;
      }
      if (
        sortBy?.type === optionValues.ALPHABETICAL &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        parameters.order_by = `-${optionValues.ALPHABETICAL}`;
      }
      if (
        sortBy?.type === optionValues.ASSIGNED &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        parameters.order_by = optionValues.ASSIGNED;
      }
      if (
        sortBy?.type === optionValues.ASSIGNED &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        parameters.order_by = `-${optionValues.ASSIGNED}`;
      }
      if (
        sortBy?.type === optionValues.AUTHOR &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        parameters.order_by = optionValues.AUTHOR;
      }
      if (
        sortBy?.type === optionValues.AUTHOR &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        parameters.order_by = `-${optionValues.AUTHOR}`;
      }
      if (
        sortBy?.type === optionValues.LOCATION_NAME &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        parameters.order_by = optionValues.LOCATION_NAME;
      }
      if (
        sortBy?.type === optionValues.LOCATION_NAME &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        parameters.order_by = `-${optionValues.LOCATION_NAME}`;
      }

      if (sortBy?.type === optionValues.SMART_SORT) {
        // DEV-23740
        // According to BE, if we want to use smart sort
        // we shouldn't send this order_by parameter at all
        delete parameters.order_by;
      }

      return parameters;
    };

    const getTimePeriod = (filters) => {
      const timePeriod = filters?.get('timePeriod');

      if (timePeriod && timePeriod !== optionValues.ALL) {
        espFilter.greaterThan(
          optionValues.LAST_UPDATED,
          moment().subtract(timePeriod, 'ms').toISOString()
        );
      }
    };

    /**
     * Service team
     */
    if (userServiceTeamID !== ALL_SERVICE_TEAMS) {
      espFilter.equalTo('service_team', userServiceTeamID);
    } else {
      myTeams.forEach((team) => {
        espFilter.or().equalTo('service_team', team.get('id'));
      });
    }

    /**
     * Removes "approvals" and "service_request" from the case feed
     */
    espFilter.notIn('type', ['approval', 'service_request']);

    const selectedFilters = state.get('casesFilter');

    /**
     * "Show" section
     */

    /**
     * task State and substate
     */

    const statesFilters = getSelectedStates(
      selectedFilters,
      otherQueryParameters
    );

    /**
     * task type
     */

    getTypes(selectedFilters);

    /**
     * assigned to
     */

    getAssignedTo(selectedFilters);

    /**
     * requested by
     */

    requestedBy(selectedFilters);

    /**
     * location
     */

    getLocation(selectedFilters);

    /**
     * "Sort by" section
     */

    const sortByParameters = getSortBy(selectedFilters);

    /**
     * "Time Period" section
     */

    getTimePeriod(selectedFilters);

    otherQueryParameters = otherQueryParameters = {
      ...statesFilters.parameters,
      ...sortByParameters,
    };

    return {
      espFilter,
      parameters: otherQueryParameters,
    };
  },

  getMenusForTask: (task = Immutable.Map(), currentUserId = 0) => {
    const state = task.get('status');
    const assignedUser = task.get('owner');

    // All the specification of these requirements are in
    // https://itautomationsys.sharepoint.com/:x:/r/sites/espressive-team-site/_layouts/15/WopiFrame.aspx?sourcedoc=%7BF1D9CBD7-F722-476D-B05E-15503ACA2BB8%7D&file=case-buttons.xlsx&action=default
    const buttons = [];

    if (state === CaseStates.OPEN) {
      if (assignedUser) {
        // Assigned to me
        if (currentUserId === assignedUser) {
          buttons.push(CASE_BUTTONS.RESOLVE, CASE_BUTTONS.ASSIGN);
        } else {
          // Assigned to other
          buttons.push(CASE_BUTTONS.RESOLVE, CASE_BUTTONS.ASSIGN);
        }
      } else {
        // Unasigned
        buttons.push(CASE_BUTTONS.ASSIGN, CASE_BUTTONS.DISCARD);
      }
    }

    if (state === CaseStates.PENDING_APPROVAL) {
      buttons.push(CASE_BUTTONS.REJECT, CASE_BUTTONS.APPROVE);
    }
    if (state === CaseStates.REJECTED) {
      buttons.push(CASE_BUTTONS.DISCARD);
    }
    if (state === CaseStates.APPROVED) {
      buttons.push(CASE_BUTTONS.DISCARD);
    }

    if (state === CaseStates.DISCARDED) {
      buttons.push(CASE_BUTTONS.RE_OPEN);
    }

    if (state === CaseStates.RESOLVED) {
      buttons.push(CASE_BUTTONS.RE_OPEN);
    }

    if (
      state === CaseStates.CANCELLED_BY_AGENT ||
      state === CaseStates.CANCELLED_BY_USER
    ) {
      buttons.push(CASE_BUTTONS.RE_OPEN);
    }

    return buttons;
  },

  getTaskIcon(taskType = '') {
    return this.hasTaskIcon(taskType) ? TypeToIcon[taskType] : null;
  },

  hasTaskIcon(taskType = '') {
    return (
      Object.values(SubtaskTypes).includes(taskType) ||
      Object.values(CaseTypes).includes(taskType)
    );
  },

  isTaskAtRisk(task = Immutable.Map(), currentTime) {
    const responseDeadline = task.get('response_deadline');
    const slaMinutes = task.get('resolve_sla');
    const closedDateTime = task.get('closed_datetime');

    if (closedDateTime) {
      return false;
    }
    // the risk is considered when it's passed more than the 75% of the allowed SLA
    const riskSla = slaMinutes ? slaMinutes * 0.25 : null;

    const threshold = moment(responseDeadline).subtract(riskSla, 'minutes');

    return DateTools.checkIsAfter(currentTime, threshold);
  },

  isTaskBreached(task = Immutable.Map(), currentTime) {
    const responseDeadline = task.get('response_deadline');
    const responseSlaBreach = task.get('response_sla_breach');
    const closedDateTime = task.get('closed_datetime');

    if (responseSlaBreach) {
      return true;
    }

    if (!closedDateTime) {
      return DateTools.checkIsAfter(currentTime, responseDeadline);
    }

    return false;
  },
};

export default caseUtils;
