import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Header, Icon, Image, List } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';
import _ from 'lodash';
// Global
import { ImageDefaults } from 'esp-assets';
// Controller
import RoleBundleListItemController from '../controllers/RoleBundleListItemController';

class RoleBundleListItem extends PureComponent {
  static propTypes = {
    bundle: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      name: PropTypes.string,
      tags: PropTypes.string,
    }).isRequired,
    images: ImmutablePropTypes.list,
    loadImages: PropTypes.func,
    otherTwoImages: ImmutablePropTypes.list,
  };

  static defaultProps = {
    images: immutable.List(),
    loadImages: _.noop,
    otherTwoImages: immutable.List(),
  };

  componentDidMount() {
    const { loadImages } = this.props;
    loadImages();
  }

  render() {
    const { images, bundle, otherTwoImages } = this.props;

    const mainImage =
      images.min((images) => images.get('ordering')) || immutable.Map();

    return (
      <List>
        <List.Item>
          <Header content={bundle.get('name')} />
          <div className='image-container'>
            <Image
              size={mainImage.isEmpty() ? 'big' : 'medium'}
              src={
                !mainImage.isEmpty()
                  ? mainImage.get('thumbnail')
                  : ImageDefaults.PRODUCT_IMAGE
              }
            />
          </div>
          <List.Content>
            <List>
              {otherTwoImages &&
                otherTwoImages.map((image) => (
                  <List.Item key={image.get('key')}>
                    <Image size={'mini'} src={image.get('thumbnail')} />
                    <List.Content>
                      <List.Description>
                        <Icon name='circle' size='tiny' />
                        {image.get('esp_catalog_name')}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          </List.Content>
        </List.Item>
      </List>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
RoleBundleListItem = RoleBundleListItemController(RoleBundleListItem);

export default RoleBundleListItem;
