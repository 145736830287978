import apiMock from '../../../../__mocks__/apiMocks';
import emptyCall from './emptyCall';

const fakeRequest = (method, options) => {
  if (!options || !options.url) {
    const error = 'url not found';
    throw new Error(error);
  }

  const getNextCall = emptyCall.getResponse();

  if (getNextCall) {
    if (getNextCall.throwError) {
      if (options.error) {
        return options.error({
          response: {
            body: getNextCall.throwError,
          },
        });
      }
      return Promise.reject({
        response: {
          body: getNextCall.throwError,
        },
      });
    }

    if (options.success) {
      return options.success({
        body: getNextCall,
        status: 200,
      });
    }
    return Promise.resolve({
      body: getNextCall,
      status: 200,
    });
  }

  const key = options.url
    .replace('http://tenant1.esp/', '')
    .split('/')
    .join('_')
    .split('.')
    .join('_')
    .replace(/_$/, '');

  const mock =
    apiMock[`${key}_${method.toLowerCase()}`] ||
    apiMock[`${key}_query`] ||
    apiMock[key];

  if (!mock) {
    const error = `Mock not found! url:"${options.url}" key:"${key}"`;
    throw new Error(error);
  } else {
    if (options.success) {
      return options.success({
        body: mock,
        status: 200,
      });
    }
    return Promise.resolve({
      body: mock,
      status: 200,
    });
  }
};

const fakeAPIcall = {
  del(options = {}) {
    return fakeRequest('DELETE', options);
  },

  delete(options = {}) {
    return fakeRequest('DELETE', options);
  },

  get(options = {}) {
    return fakeRequest('GET', options);
  },

  patch(options = {}) {
    return fakeRequest('PATCH', options);
  },

  post(options = {}) {
    return fakeRequest('POST', options);
  },

  put(options = {}) {
    return fakeRequest('PUT', options);
  },
};

export default fakeAPIcall;
