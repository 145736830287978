// parent types allowed : {incident, case, esp service request}
// sub-task types allowed: {incident, case, esp service request, action item, purchase request}

export const CaseTypes = {
  CASE: 'case',
  INCIDENT: 'incident',
  SERVICE_REQUEST: 'esp_service_request',
  SNOW_SERVICE_REQUEST: 'service_request',
};

export const SubtaskTypes = {
  ACTION_ITEM: 'action_item',
  CASE: 'case',
  CATALOG_REQUEST: 'purchase_request',
  INCIDENT: 'incident',
  SERVICE_REQUEST: 'esp_service_request',
};

export const TypeToIcon = {
  [SubtaskTypes.ACTION_ITEM]: 'check square',
  [SubtaskTypes.CASE]: 'briefcase',
  [SubtaskTypes.CATALOG_REQUEST]: 'cart',
  [SubtaskTypes.INCIDENT]: 'exclamation',
  [SubtaskTypes.SERVICE_REQUEST]: 'help',
};

export const CaseTypeOptions = (intl) => [
  {
    key: 0,
    text: intl.formatMessage({
      id: 'label.case',
    }),
    value: CaseTypes.CASE,
  },
  {
    key: 1,
    text: intl.formatMessage({
      id: 'label.incident',
    }),
    value: CaseTypes.INCIDENT,
  },
  {
    key: 2,
    text: intl.formatMessage({
      id: 'label.service_request',
    }),
    value: CaseTypes.SERVICE_REQUEST,
  },
  {
    key: 3,
    text: intl.formatMessage({
      id: 'label.service_request',
    }),
    value: SubtaskTypes.SNOW_SERVICE_REQUEST,
  },
];

export const SubtaskTypesOptions = (intl) => [
  {
    key: 0,
    text: intl.formatMessage({
      id: 'label.case',
    }),
    value: SubtaskTypes.CASE,
  },
  {
    key: 1,
    text: intl.formatMessage({
      id: 'label.incident',
    }),
    value: SubtaskTypes.INCIDENT,
  },
  {
    key: 2,
    text: intl.formatMessage({
      id: 'label.service_request',
    }),
    value: SubtaskTypes.SERVICE_REQUEST,
  },
  {
    key: 3,
    text: intl.formatMessage({
      id: 'label.purchase_request',
    }),
    value: SubtaskTypes.CATALOG_REQUEST,
  },
  {
    key: 4,
    text: intl.formatMessage({
      id: 'label.action_item',
    }),
    value: SubtaskTypes.ACTION_ITEM,
  },
];
