import { createSelector } from 'reselect';
import { first, last } from 'lodash';
import { Map } from 'immutable';

// Globals
import ChannelTypes from '../globals/ChannelTypes';

// Selectors
import { getCurrentUserId } from './getCurrentUser';

const getChannels = (state) => state.getIn(['entities', 'channels']);

const getSortOptionChecked = (state) =>
  state.hasIn(['channelsFilter', 1, 'filters', 0, 'options']) &&
  state
    .getIn(['channelsFilter', 1, 'filters', 0, 'options'])
    .find((option) => option.get('checked'))
    .get('value');

const getChannelsSorted = createSelector(
  [getChannels, getSortOptionChecked],
  (chls = Map(), sortValueChecked = '') => {
    let channels = chls.toList();

    if (sortValueChecked === 'UNREAD') {
      channels = channels.sort(
        (channel) =>
          channel.get('message_count') - channel.get('read_message_count')
      );
    } else if (sortValueChecked) {
      const attrSplit = sortValueChecked.split('-');
      const sortAttr = last(attrSplit);
      const isReverse = first(attrSplit) === ''; // if the first one is empty, means is negative

      channels = channels.sort((channelA, channelB) => {
        const aDate = new Date(channelA.get(sortAttr));
        const bDate = new Date(channelB.get(sortAttr));

        if (aDate > bDate) {
          return 1;
        }
        if (aDate < bDate) {
          return -1;
        }

        return 0;
      });

      if (isReverse) {
        channels = channels.reverse();
      }
    }

    return channels;
  }
);

// And for direct channels, only return those with exactly 2 participants
// (multi participant direct channels not supported by design yet)
const getDirectChannels = createSelector(getChannelsSorted, (channels) =>
  channels.filter(
    (ch) =>
      ch.get('type') === ChannelTypes.DIRECT_CHANNEL &&
      ch.has('participants') &&
      ch.get('participants').size === 2
  )
);

const getScopedChannels = createSelector(
  getChannelsSorted,
  getCurrentUserId,
  (channels, currenUserId = null) =>
    channels.filter((ch) => {
      if (ch.get('app_model_context') === 'task.Task') {
        // only accept those where current user is part of the admin participant list
        if (ch.has('admin_participants')) {
          return ch
            .get('admin_participants')
            .some((userId) => userId === currenUserId);
        }
      }
      return ch.get('type') === ChannelTypes.SCOPED_CHANNEL;
    })
);

// Filter by type
const getTypeParam = (state, type) => type;
const getChannelsByType = createSelector(
  [getDirectChannels, getScopedChannels, getTypeParam],
  (directChannels, scopedChannel, type) => {
    if (type === ChannelTypes.DIRECT_CHANNEL) {
      return directChannels;
    } else {
      return scopedChannel;
    }
  }
);

const getUserParam = (state, selectedUserId) => selectedUserId;
const getSelectedChannelByUser = createSelector(
  [getDirectChannels, getUserParam],
  (channels, selectedUserId) => {
    let channel;
    if (channels && !channels.isEmpty() && selectedUserId) {
      channel = channels.find(
        (channel) =>
          channel.get('participants') &&
          channel.get('participants').find((id) => id === selectedUserId)
      );
    }
    return channel;
  }
);

export { getChannelsByType };
export { getScopedChannels };
export { getDirectChannels };
export { getSelectedChannelByUser };

export default getChannelsSorted;
