import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsOnboardingComplete extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M59.42 43.88c-1.862-.003-3.706.372-5.42 1.1V18.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v8.64H25V18.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v58.27c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-8.63h25.2c.26.233.527.453.8.66v8c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-6.24c5.185 2.196 11.183 1.045 15.186-2.916 4.003-3.96 5.22-9.945 3.08-15.154-2.142-5.208-7.215-8.61-12.846-8.61zM25 30.13h26v16H25v-16zm0 19h23.56c-3.683 4.595-4.045 11.02-.9 16H25v-16zm34.42 19.54c-6.01-.01-10.876-4.89-10.87-10.9.006-6.01 4.88-10.88 10.89-10.88 6.01 0 10.884 4.87 10.89 10.88-.006 6.022-4.888 10.9-10.91 10.9z' />
          <path d='M63.17 53.41L57.5 59.5l-2.17-1.94c-.38-.433-.976-.61-1.532-.453-.555.156-.972.616-1.072 1.184-.1.57.135 1.146.604 1.48l3.25 3c.292.27.68.416 1.077.4.397-.014.772-.186 1.043-.48l6.63-7.23c.427-.616.33-1.455-.226-1.96-.555-.505-1.4-.52-1.974-.04l.04-.05z' />
        </g>
      </svg>
    );
  }
}

export default CardsOnboardingComplete;
