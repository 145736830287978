import actionTypes from './actionTypes';

const chatActions = {};

chatActions.loadMessagesStart = (channelId) => ({
  channelId,
  type: actionTypes.LOAD_MESSAGES,
});

chatActions.loadMessagesSuccess = (messages, channelId, pagination) => ({
  channelId,
  messages,
  pagination,
  type: actionTypes.LOAD_MESSAGES_SUCCESS,
});

chatActions.loadMessagesFailure = (error, channelId) => ({
  channelId,
  error,
  type: actionTypes.LOAD_MESSAGES_FAIL,
});

chatActions.chatMessageReceived = (message, currentUserId) => ({
  currentUserId,
  message,
  type: actionTypes.CHAT_MESSAGE_RECEIVED, // current user is needed to not mark own messages as unread
});

chatActions.registerUserTyping = (message) => ({
  message,
  type: actionTypes.REGISTER_USER_TYPING,
});

chatActions.deRegisterUserTyping = (message) => ({
  message,
  type: actionTypes.DEREGISTER_USER_TYPING,
});

chatActions.loadAttachmentStart = () => ({
  type: actionTypes.CHAT_LOAD_ATTACHMENT_START,
});

chatActions.loadAttachmentFail = () => ({
  type: actionTypes.CHAT_LOAD_ATTACHMENT_FAIL,
});

chatActions.loadAttachmentSuccess = () => ({
  type: actionTypes.CHAT_LOAD_ATTACHMENT_SUCCESS,
});

chatActions.loadOneMessageSuccess = (message, channelId) => ({
  channelId,
  messages: [message],
  type: actionTypes.CHAT_LOAD_ONE_MESSAGE_SUCCESS,
});

chatActions.updateWidgetSuccess = (channelId, messageId, widgetResponse) => ({
  channelId,
  messageId,
  type: actionTypes.UPDATE_WIDGET_SUCCESS,
  widgetResponse,
});

chatActions.loadApiMetaResultsStart = () => ({
  type: actionTypes.META_RESULTS_START,
});

chatActions.addApiMetaResults = (results = []) => ({
  results,
  type: actionTypes.META_RESULTS_ADD,
});

chatActions.clarApiMetaResults = () => ({
  type: actionTypes.META_RESULTS_CLEAR,
});

chatActions.addDefaultMessage = (message) => ({
  message,
  type: actionTypes.CHAT_ADD_DEFAULT_MESSAGE,
});

chatActions.deleteChatMessage = (channelId, messageEid) => ({
  channelId,
  messageEid,
  type: actionTypes.DELETE_CHAT_MESSAGE,
});

chatActions.deleteChaChannel = (channelId) => ({
  channelId,
  type: actionTypes.DELETE_CHAT_CHANNEL,
});

chatActions.imageLoaded = (imgSrc) => ({
  imgSrc,
  type: actionTypes.CHAT_IMAGE_LOADED,
});

export default chatActions;
