import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

// This Initial state will change depending on the structure of the actual API
const INITIAL_STATE = fromJS({
  tenantImages: {},
  users: {},
});

/**
 * Containers reducer
 * Used to keep loading state of self contained entities
 */
const containersStatusReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ADD_USER_ENTITY_START: {
      return state.setIn(['users', action.userId, 'loading'], true);
    }

    case actionTypes.UPDATE_USER_ENTITY_SUCCESS:
    case actionTypes.ADD_USER_ENTITY_SUCCESS: {
      return state
        .setIn(['users', action.userId, 'loading'], false)
        .setIn(['users', action.userId, 'timestamp'], action.timestamp);
    }

    case actionTypes.ADD_USER_ENTITY_FAIL: {
      return state
        .setIn(['users', action.userId, 'loading'], false)
        .setIn(['users', action.userId, 'error'], true);
    }

    case actionTypes.GET_TENANT_IMAGES_START: {
      return state.setIn(['tenantImages', 'loading'], true);
    }

    case actionTypes.GET_TENANT_ONE_IMAGE_START: {
      return state
        .setIn(['tenantImages', 'loading'], true)
        .setIn(['tenantImages', action.imgType, 'loading'], true);
    }

    case actionTypes.GET_TENANT_ONE_IMAGE_FAIL:
    case actionTypes.GET_TENANT_IMAGES_FAIL: {
      return state
        .setIn(['tenantImages', 'loading'], false)
        .setIn(['tenantImages', action.imgType, 'error'], true)
        .setIn(['tenantImages', action.imgType, 'loading'], false);
    }

    case actionTypes.GET_TENANT_IMAGES_SUCCESS: {
      let newState = state.setIn(['tenantImages', 'loading'], false);

      action.tenantImages.forEach((img) => {
        const type = img.type || 'empty';
        newState = newState
          .setIn(['tenantImages', type, 'loading'], false)
          .setIn(['tenantImages', type, 'error'], false)
          .setIn(['tenantImages', type, 'timestamp'], action.timestamp);
      });

      return newState;
    }

    case actionTypes.GET_TENANT_ONE_IMAGE_SUCCESS: {
      const type = action.tenantImage?.type || 'empty';
      return state
        .setIn(['tenantImages', type, 'loading'], false)
        .setIn(['tenantImages', type, 'timestamp'], action.timestamp);
    }

    case actionTypes.UPLOAD_TENANT_IMAGE_SUCCESS: {
      return state.setIn(['tenantImages', 'loading'], false);
    }

    default: {
      return state;
    }
  }
};

export default containersStatusReducer;
