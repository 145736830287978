import React from 'react';
import { Segment } from 'semantic-ui-react';

const style = {
  height: '100%',
};

const LoadingList = () => <Segment basic loading style={style} />;

export { LoadingList };
