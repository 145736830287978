import React from 'react';
import pt from 'prop-types';

import { useCurrentUser } from '../../hooks/currentUser';
import styles from '../Auth.module.scss';
import { Loader } from '@espressive/cascara/private';

const propTypes = {
  /** The children component(s) to render */
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  /** The component to display as fallback ui */
  fallback: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  /** The required roles (group membership) to render the children */
  roles: pt.arrayOf(pt.string),
};

const DEFAULT_FALLBACK = <h2>{'Unauthorized'}</h2>;
const NO_GROUPS_NOR_ROLES = (
  <h2>{'Either the user has no groups or no required roles were specified'}</h2>
);

/**
 * Permissions
 *
 * Conditionally renders children if the user belongs to the any of the `groups` passed as `roles`.
 *
 * @param {node|Array[node]} The children component(s) to render
 * @param {Array[String]} The required roles (group membership) to render the children
 */
const Permissions = ({ children, fallback = DEFAULT_FALLBACK, roles }) => {
  const currentUser = useCurrentUser();
  const userGroups = currentUser?.groups;

  const userIsEmpty =
    !currentUser ||
    (typeof currentUser === 'object' && Object.keys(currentUser).length === 0);

  const userHasNoGroups =
    !userIsEmpty && Array.isArray(userGroups) && userGroups.length === 0;

  const noRolesPassed =
    (Array.isArray(roles) && roles.length === 0) ||
    typeof roles === 'undefined';

  if (userIsEmpty) {
    return (
      <div className={styles.Page}>
        <Loader />
      </div>
    );
  }

  if (userHasNoGroups || noRolesPassed) {
    return <div className={styles.Page}>{NO_GROUPS_NOR_ROLES}</div>;
  }

  for (const userGroup of userGroups) {
    if (roles.includes(userGroup)) {
      return children;
    }
  }

  return fallback;
};

Permissions.propTypes = propTypes;

export default Permissions;
