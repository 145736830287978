import apiActionButton from './apiActionButton';
import attachment from './attachment';
import select from './select';
import system from './system';
import typing from './typing';
import supportFeedbackWidget from './supportFeedback';

const customMessageTypes = {
  apiActionButton,
  attachment,
  select,
  supportFeedbackWidget,
  system,
  typing,
};

export default customMessageTypes;
