import tinycolor from 'tinycolor2';

// Custom
import breakpoints from './component/breakpoints';

const EspTheme = (tenantColorPrimary, tenantColorHeader) => {
  // TODO Move fallback values to getTenantBrandingSelector maybe
  tenantColorPrimary = tenantColorPrimary || '#ccc';
  tenantColorHeader = tenantColorHeader || tenantColorPrimary;

  // text colors depending on light/darkness
  const textColorPrimary = tinycolor.isReadable(tenantColorPrimary, '#fff', {
    level: 'AA',
    size: 'large', // shift to 3:1 from 4.5:1
  })
    ? '#fff'
    : '#000';
  const textColorHeader = tinycolor.isReadable(tenantColorHeader, '#fff', {
    level: 'AA',
    size: 'large', // shift to 3:1 from 4.5:1
  })
    ? '#fff'
    : '#000';

  // Create our theme object
  const theme = {};

  // We can keep global values here for consistency
  // We should not be using shorthand properties anywhere
  theme.globals = {
    borderRadius: '2px',
    fontSize: '16px',
    lineHeight: '1em',
    padding: '1em',
    transitionDuration: 200,
    transitionTimingFunction: 'ease-in-out',
    zIndexDropdown: 101,
    zIndexInput: 10,
  };

  // Value for breakpoints
  theme.breakpoints = breakpoints;

  // Values for Espressive branding
  theme.brandEspressive = {
    green: '#2db59e',
    orange: '#e59925',
  };

  // Values for Tenant branding
  theme.brandTenant = {
    headerColor: tenantColorHeader,
    primaryColor: tenantColorPrimary,
    textColorHeader: textColorHeader,
    textColorPrimary: textColorPrimary,
  };

  // Values for consistent colors
  theme.colors = {
    black: '#000',
    darkGray: '#aaa',
    gray: '#e0e1e2',
    lightGray: '#fafafa',
    red: '#a00',
    text: '#4a4a4a',
    white: '#fff',
  };

  // Component values for ListCard - We may want to separate these to an external?
  // TODO: bje will move this to a separate theme file
  theme.component = {
    announcementPadding: '.125em',
  };

  return theme;
};

export default EspTheme;
