import { change } from 'redux-form/immutable';
import { hasIn } from 'lodash';
// Thunk
import faqThunks from '../../actions/faqThunks';
// Util
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  let taskSelected;
  if (ownProps.query && hasIn(ownProps, 'query.taskId')) {
    taskSelected = state.getIn([
      'entities',
      'tasks',
      Number(ownProps.query.taskId),
    ]);
  }

  return {
    taskSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    return new Promise((resolve) => {
      const updateFAQ = true;
      dispatch(change('Form01', id, data));
      dispatch(faqThunks.createNewFaqFromBEScratch(updateFAQ)).then(() => {
        resolve();
      });
    });
  },
  skipOrNotFAQ(id, data) {
    return new Promise((resolve) => {
      dispatch(change('Form01', id, data || 'edit'));
      // DEV-8896 this action should NOT trigger a submit,
      // otherwise it will do an infinite loop of submits
      // since the handleSubmit of this component calls this function again
      // As a note I don't know how come this was working before ¯\_(ツ)_/¯
      // dispatch(submit('Form01'));
      resolve();
    });
  },
  updateFAQ(taskSelected) {
    const forceReBuild = true;
    dispatch(faqThunks.setFaqBEScratch(forceReBuild, taskSelected)).then(() => {
      const updateFAQ = true;
      dispatch(faqThunks.createNewFaqFromBEScratch(updateFAQ));
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
