import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Header,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
// Global
import { TeamRoles } from '../../../../globals/TeamRolesCaseMgmt';
import BrowserPrompt from '../../../../globals/BrowserPrompt';
// Molecules
import ServiceTeamMemberList from '../../../molecules/ServiceTeamMemberList';
// Controller
import AdminServiceTeamMembersController from '../../../controllers/AdminServiceTeamMembersController';

class AdminServiceTeamMembers extends Component {
  static propTypes = {
    /** Add new member */
    addNewMember: PropTypes.func.isRequired,
    /** Deletean existing member */
    deleteMember: PropTypes.func.isRequired,
    /** Loading state */
    isLoadingMemberList: PropTypes.bool,
    /** Members list loader */
    loadMembersList: PropTypes.func.isRequired,
    /** Immutable Members List */
    membersList: ImmutableProps.list,
    /** RR4 params */
    params: PropTypes.shape({
      teamID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    /** Start to add new member */
    startAddUser: PropTypes.func.isRequired,
    /* Team Lead User ID */
    teamLead: PropTypes.number,
  };

  static defaultProps = {
    isLoadingMemberList: false,
    membersList: immutable.List(),
    params: {
      teamID: null,
    },
    teamLead: null,
  };

  componentDidMount() {
    const {
      loadMembersList,
      params: { teamID },
    } = this.props;

    loadMembersList(Number(teamID));
  }

  shouldComponentUpdate() {
    // Note : Complete that
    return true;
  }

  handleAddMember = (e) => {
    e.preventDefault();
    const { membersList, startAddUser } = this.props;

    const existingMember = membersList.findIndex(
      (member) => member.get('id') === 'newMember'
    );

    // Avoid to create several user in the same time
    if (existingMember && existingMember === -1) {
      startAddUser();
    }
  };

  onHandleSubmitNewUser = (formName) => {
    const {
      params: { teamID },
      addNewMember,
    } = this.props;

    const msg = {
      header: 'Success',
      message: 'A new member has been added',
      messageUpdate: 'The member has been updated',
    };

    addNewMember(formName, teamID, msg);
  };

  onHandleDeleteUser = (relationshipID) => {
    const {
      deleteMember,
      membersList,
      params: { teamID },
    } = this.props;

    // Check if this is a LEAD role and the only one
    const index = membersList.findIndex((m) => m.get('id') === relationshipID);
    const leadInTeam = membersList.filter(
      (m) => m.get('auth_group') === TeamRoles.SERVICE_LEAD
    );

    if (
      membersList.getIn([index, 'auth_group']) === TeamRoles.SERVICE_LEAD &&
      leadInTeam.size === 1
    ) {
      // This is the only LEAD guy in the team
      const msg =
        'You can not delete the only LEAD on this Team. Please, add another LEAD before to remove this one';
      BrowserPrompt.alert(msg, {
        buttons: 'Ok',
        callback: null,
        content: msg,
        title: 'Error',
      });
    } else {
      deleteMember(relationshipID, teamID);
    }
  };

  render() {
    const { isLoadingMemberList, membersList, teamLead } = this.props;

    const teamMemberCount = `Team Members: ${membersList.size}`;

    const existingMember = membersList.findIndex(
      (member) => member.get('id') === 'newMember'
    );
    const disableInvite = existingMember > -1;

    return (
      <Segment attached='bottom'>
        <Menu borderless secondary>
          <Menu.Menu position='left'>
            <Menu.Item fitted>
              <Header as='h4'>{teamMemberCount}</Header>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <Button
                content='Invite Member'
                disabled={disableInvite}
                onClick={this.handleAddMember}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {isLoadingMemberList && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}

        {membersList.map((member) => (
          <ServiceTeamMemberList
            deleteUser={this.onHandleDeleteUser}
            key={member.get('id')}
            listSelected={member}
            submitNewMember={this.onHandleSubmitNewUser}
            teamLead={teamLead}
          />
        ))}

        <Divider hidden />
      </Segment>
    );
  }
}

export const AdminServiceTeamMembersTest = AdminServiceTeamMembers;

export default AdminServiceTeamMembersController(AdminServiceTeamMembers);
