import { fromJS } from 'immutable';
import {
  change, // The action creator
  getFormValues,
  isDirty,
  isInvalid,
  reset,
} from 'redux-form/immutable';

import { get } from 'lodash';
// Actions
import announcementThunks from '../../actions/announcementThunks';
import announcementActions from '../../actions/announcementActions';
import baristaThunks from '../../actions/baristaThunks';
// Global
import AnnouncementState from '../../globals/AnnouncementState';
import {
  ACTION_TYPES,
  ANNOUNCEMENT_TYPES,
} from '../../globals/announcementCustomActionConstants';
import SurveyTypes from '../../../v2/components/form/Announcement/SurveyTypes';
// Utils
import { isFeatureFlag } from 'esp-ui-flag';
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
import buildCompositeController from '../../utils/buildCompositeController';
// selectors
import getTotalAnnouncementRecipents from '../../selectors/getTotalAnnouncementRecipents';
import { getCurrentUserId } from '../../selectors/getCurrentUser';

const mapStateToProps = (state, ownProps) => {
  const announcements = state.getIn(['announcement', 'announcements']);
  const announcementId = ownProps.params.announcementID;

  let announcement = announcements.find(
    (a) => a.get('id').toString() === announcementId
  );

  if (announcementId === 'new') {
    // DEV-12891 Getting defaults from query params
    const subjectParam = get(ownProps, 'location.query.subject');
    const bodyParam = get(ownProps, 'location.query.body');
    let typeParam = get(ownProps, 'location.query.type');
    if (typeParam) {
      typeParam = typeParam.toUpperCase();
    }
    const etaParam = get(ownProps, 'location.query.eta');

    const buttonText = get(ownProps, 'location.query.buttonText');
    const buttonUrl = get(ownProps, 'location.query.buttonUrl');
    let buttonType = get(ownProps, 'location.query.buttonType');

    let action;
    if (buttonText || buttonUrl || buttonType) {
      // if not set then is General
      if (!typeParam) {
        typeParam = ANNOUNCEMENT_TYPES.GENERAL.value;
      }

      let announcementType; // this is needed for the announcement type

      // button type depends on survey type
      if (typeParam === ANNOUNCEMENT_TYPES.SURVEY.value) {
        // set this as default if using survey
        // and no type declared, otherwise the Form blows out
        buttonType = SurveyTypes.GOOD_BAD.value;
      } else {
        buttonType = ACTION_TYPES.URL;
        announcementType = ACTION_TYPES.URL;
      }

      // Build action object
      action = [
        {
          action_url: buttonUrl,
          button_text: buttonText,
          button_type: buttonType,
          type: announcementType,
        },
      ];
    }

    announcement = fromJS({
      action: action,
      body: bodyParam,
      eta: etaParam,
      id: announcementId,
      status: AnnouncementState.STATUS_DRAFT,
      subject: subjectParam,
      type: typeParam,
    });
  }

  const announcementFormName = `AnnouncementManageForm_${announcementId}`;
  const formValues = getFormValues(announcementFormName)(state);
  const isAnnouncementSurveyBuilderEnabled = isFeatureFlag(
    'announcementSurveyBuilder',
    state
  );

  return {
    announcement,
    announcementId,
    formValues,
    intentsList: state.getIn(['barista', 'intents', 'list']),
    isAnnouncementSaving: state.getIn(
      ['announcement', 'loadStates', 'announcements', announcementId],
      false
    ),
    isAnnouncementSurveyBuilderEnabled,
    isDirty: isDirty(announcementFormName)(state),
    isInvalid: isInvalid(announcementFormName)(state),
    isLoadingIntent: state.getIn(['barista', 'intents', 'isLoading']),
    isUpdating: state.getIn(['announcement', 'isUpdating']),
    key: announcementId,
    slackChannels: state.getIn(['announcement', 'slackChannels']),
    totalRecipents: getTotalAnnouncementRecipents(formValues),
    userLanguage: state
      .getIn(['entities', 'users', getCurrentUserId(state)])
      ?.get('preferred_language_id')
      ?.toLowerCase(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewGroup(announcementId, cb) {
    dispatch(announcementThunks.createNewGroup(announcementId, cb));
  },

  changeFormFieldValue(fieldName, value, announcementId) {
    dispatch(
      change(`AnnouncementManageForm_${announcementId}`, fieldName, value)
    );
  },

  createNewAnnouncement() {
    dispatch(announcementActions.createNewAnnouncement());
  },

  deleteAnnouncement(id) {
    const onSuccess = () => {
      browserHistory.push(uiPathGenerator.genPath('app.announcements'));
    };
    dispatch(announcementThunks.deleteAnnouncement(id, onSuccess));
  },

  loadAnnouncement: (id) =>
    new Promise((resolve, reject) => {
      dispatch(announcementThunks.loadOneAnnouncement(id))
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    }),

  loadIntentByEid(intentEid) {
    dispatch(baristaThunks.loadIntentByEid(intentEid));
  },

  loadSlackChannels: () =>
    new Promise((resolve, reject) => {
      dispatch(announcementThunks.loadSlackChannels())
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    }),

  recall(announcementId) {
    dispatch(announcementThunks.recall(announcementId)).then(() => {
      browserHistory.push(uiPathGenerator.genPath('app.announcements'));
    });
  },

  resetForm(announcementId) {
    dispatch(reset(`AnnouncementManageForm_${announcementId}`));
  },

  saveAnnouncement: (
    id,
    currentStatus,
    newStatus,
    changeFormFieldValue,
    slackChannels
  ) => {
    if (newStatus) {
      changeFormFieldValue('status', newStatus, id);
    }

    const onFail = () => {
      changeFormFieldValue('status', currentStatus, id);
    };

    const onCreate = (announcement, announcementStatus) => {
      dispatch(
        announcementThunks.saveStatus(announcement.id, announcementStatus)
      ) // status should be updated separately
        .then(() => {
          browserHistory.replace(
            uiPathGenerator.genPath('app.announcements.manage', {
              announcementID: announcement.id,
            })
          );
        });
    };

    const onUpdate = (announcement, announcementStatus, groups) => {
      dispatch(
        announcementThunks.saveAllGroups(id, announcement, groups, () => {
          dispatch(
            announcementThunks.saveStatus(
              announcement.id,
              announcementStatus,
              currentStatus
            )
          ) // status should be updated separately
            .then(() => {
              if (newStatus) {
                changeFormFieldValue('status', newStatus, id);
              }
              if (announcementStatus === AnnouncementState.STATUS_ARCHIVED) {
                browserHistory.push(
                  uiPathGenerator.genPath('app.announcements')
                );
              } else {
                dispatch(announcementThunks.loadOneAnnouncement(id));
              }
            });
        })
      );
    };
    dispatch(
      announcementThunks.saveAnnouncement(
        id,
        onCreate,
        onUpdate,
        onFail,
        slackChannels
      )
    );
  },

  updateAnnouncement(announcementId, announcementFormValues) {
    return dispatch(
      announcementThunks.updateAnnouncement(
        announcementId,
        announcementFormValues
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
