import { connect } from 'react-redux';

import emailPolicyThunks from '../../actions/emailPolicyThunks';
import getSelectedEmailDomain from '../../selectors/getSelectedEmailDomain';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state) => ({
  addressRules: state.getIn(['emailPolicies', 'addressPolicies', 'rules']),
  emailDomains: state.getIn(['emailPolicies', 'emailDomains', 'values']),
  isAddingEmailDomain: state.getIn([
    'emailPolicies',
    'addEmailDomain',
    'isLoading',
  ]),
  isLoadingEmailDomains: state.getIn([
    'emailPolicies',
    'emailDomains',
    'isLoading',
  ]),
  isSelectingEmailDomain: state.getIn([
    'emailPolicies',
    'selectEmailDomain',
    'isLoading',
  ]),
  isTestEmailEnabled: state.getIn([
    'emailPolicies',
    'testEmail',
    'isTestEmailEnabled',
  ]),
  isTestEmailLoading: state.getIn(['emailPolicies', 'testEmail', 'isLoading']),
  isUpdatingEmailPolicy: state.getIn([
    'emailPolicies',
    'updateAddressPolicy',
    'isLoading',
  ]),
  selectedEmailDomain: getSelectedEmailDomain(state),
  testEmail: state.getIn(['emailPolicies', 'testEmail', 'testEmail']),
});

const mapDispatchToProps = (dispatch) => ({
  addEmailDomain(domain) {
    return dispatch(emailPolicyThunks.addDomain(domain)).then(
      (newEmailDomain) => {
        const newEmailDomainID = newEmailDomain.id;
        dispatch(emailPolicyThunks.selectDomain(newEmailDomainID));
      }
    );
  },

  getEmailDomains() {
    dispatch(emailPolicyThunks.getDomains());
  },

  getEmailPolicy() {
    dispatch(emailPolicyThunks.getPolicy());
  },
  getTestEmail() {
    dispatch(emailPolicyThunks.getTestEmail());
  },
  getTestEmailEnabled() {
    dispatch(emailPolicyThunks.getTestEmailEnabled());
  },
  selectEmailDomain(newSelectedEmailDomainID) {
    dispatch(emailPolicyThunks.selectDomain(newSelectedEmailDomainID));
  },

  setTestEmail(newValue) {
    dispatch(emailPolicyThunks.setTestEmail(newValue))
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            title: 'Success',
          })
        );
      })
      .catch(() => {
        dispatch(
          toastNotificationsActions.error({
            title: 'Something went wrong',
          })
        );
      });
  },

  setTestEmailEnabled(newValue) {
    dispatch(emailPolicyThunks.setTestEmailEnabled(newValue))
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            title: 'Success',
          })
        );
      })
      .catch(() => {
        dispatch(
          toastNotificationsActions.error({
            title: 'Something went wrong',
          })
        );
      });
  },

  updateEmailPolicy(addressRules) {
    dispatch(emailPolicyThunks.updatePolicy(addressRules));
  },
});

const SettingEmailPolicyController = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
export default SettingEmailPolicyController;
