/* eslint-disable sort-keys -- Reducer does not need to be sorted */
const actionTypes = {
  ADD_BLOCK_ATTR_OPTION: 'ADD_BLOCK_ATTR_OPTION',
  ADD_BLOCK_TO_TASK: 'ADD_BLOCK_TO_TASK',
  ADD_BUNDLE_TEMPORARY_PERMISSIONS: 'ADD_BUNDLE_TEMPORARY_PERMISSIONS',
  ADD_CATALOG_ITEM_FAILURE: 'ADD_CATALOG_ITEM_FAILURE',
  ADD_CATALOG_ITEM_START: 'ADD_CATALOG_ITEM_START',
  ADD_CATALOG_ITEM_SUCCESS: 'ADD_CATALOG_ITEM_SUCCESS',
  ADD_EMAIL_DOMAIN_FAIL: 'ADD_EMAIL_DOMAIN_FAIL',
  ADD_EMAIL_DOMAIN_START: 'ADD_EMAIL_DOMAIN_START',
  ADD_EMAIL_DOMAIN_SUCCESS: 'ADD_EMAIL_DOMAIN_SUCCESS',
  ADD_FAQ_ANSWER: 'ADD_FAQ_ANSWER',
  ADD_INTEGRATION_BUSINESS_OBJECTS_START:
    'ADD_INTEGRATION_BUSINESS_OBJECTS_START',
  ADD_INTEGRATION_BUSINESS_OBJECTS_SUCCESS:
    'ADD_INTEGRATION_BUSINESS_OBJECTS_SUCCESS',
  ADD_INTERVAL_ID: 'ADD_INTERVAL_ID',
  ADD_NEW_TEMPLATE_INTERVAL_FAIL: 'ADD_NEW_TEMPLATE_INTERVAL_FAIL',
  ADD_NEW_TEMPLATE_INTERVAL_START: 'ADD_NEW_TEMPLATE_INTERVAL_START',
  ADD_NEW_TEMPLATE_INTERVAL_SUCCESS: 'ADD_NEW_TEMPLATE_INTERVAL_SUCCESS',
  ADD_ONBOARD_TEMPLATE_LIST_SUCCESS: 'ADD_ONBOARD_TEMPLATE_LIST_SUCCESS',
  ADD_PRODUCT_IMAGE_ENTITY: 'ADD_PRODUCT_IMAGE_ENTITY',
  ADD_PRODUCT_PERMISSION_GROUP_FAIL: 'ADD_PRODUCT_PERMISSION_GROUP_FAIL',
  ADD_PRODUCT_PERMISSION_GROUP_START: 'ADD_PRODUCT_PERMISSION_GROUP_START',
  ADD_PRODUCT_PERMISSION_GROUP_SUCCESS: 'ADD_PRODUCT_PERMISSION_GROUP_SUCCESS',
  ADD_SELECTED_BUNDLE: 'ADD_SELECTED_BUNDLE',
  ADD_SERVICE_DEPARTMENT_SUCCESS: 'ADD_SERVICE_DEPARTMENT_SUCCESS',
  ADD_TASK_FAILURE: 'ADD_TASK_FAILURE',
  ADD_TASK_START: 'ADD_TASK_START',
  ADD_TASK_SUCCESS: 'ADD_TASK_SUCCESS',
  ADD_TEAM_MEMBER_FAIL: 'ADD_TEAM_MEMBER_FAIL',
  ADD_TEAM_MEMBER_START: 'ADD_TEAM_MEMBER_START',
  ADD_TEAM_MEMBER_SUCCESS: 'ADD_TEAM_MEMBER_SUCCESS',
  ADD_TEMPORARY_BUNDLE_ITEM: 'ADD_TEMPORARY_BUNDLE_ITEM',
  ADD_TENANT_USERS_GROUP_FAIL: 'ADD_TENANT_USERS_GROUP_FAIL',
  ADD_TENANT_USERS_GROUP_START: 'ADD_TENANT_USERS_GROUP_START',
  ADD_TENANT_USERS_GROUP_SUCCESS: 'ADD_TENANT_USERS_GROUP_SUCCESS',
  ADD_TOAST_NOTIFICATION: 'ADD_TOAST_NOTIFICATION',
  ADD_USERS_ENTITIES_FAIL: 'ADD_USERS_ENTITIES_FAIL',
  ADD_USERS_ENTITIES_START: 'ADD_USERS_ENTITIES_START',
  ADD_USERS_ENTITIES_SUCCESS: 'ADD_USERS_ENTITIES_SUCCESS',
  ADD_USER_ENTITY_FAIL: 'ADD_USER_ENTITY_FAIL',
  ADD_USER_ENTITY_START: 'ADD_USER_ENTITY_START',
  ADD_USER_ENTITY_SUCCESS: 'ADD_USER_ENTITY_SUCCESS',
  ADD_USER_FAIL: 'ADD_USER_FAIL',
  ADD_USER_SESSION_FAIL: 'ADD_USER_SESSION_FAIL',
  ADD_USER_SESSION_SUCCESS: 'ADD_USER_SESSION_SUCCESS',
  ADD_USER_START: 'ADD_USER_START',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_WORKFLOW_EID_TO_ID_MAPPING: 'ADD_WORKFLOW_EID_TO_ID_MAPPING',
  ADMIN_ALLOW_LEAVE: 'ADMIN_ALLOW_LEAVE',
  ADMIN_CUSTOM_LOCATIONS_SUCCESS: 'ADMIN_CUSTOM_LOCATIONS_SUCCESS',
  ADMIN_LOCATIONS_END: 'ADMIN_LOCATIONS_END',
  ADMIN_LOCATIONS_SET_FILTER: 'ADMIN_LOCATIONS_SET_FILTER',
  ADMIN_LOCATIONS_SET_NOTE: 'ADMIN_LOCATIONS_SET_NOTE',
  ADMIN_LOCATIONS_START: 'ADMIN_LOCATIONS_START',
  ADMIN_LOCATIONS_SUCCESS: 'ADMIN_LOCATIONS_SUCCESS',
  ADMIN_LOCATIONS_TYPES_END: 'ADMIN_LOCATIONS_TYPES_END',
  ADMIN_LOCATIONS_TYPES_START: 'ADMIN_LOCATIONS_TYPES_START',
  ADMIN_LOCATIONS_TYPES_SUCCESS: 'ADMIN_LOCATIONS_TYPES_SUCCESS',
  ADMIN_SET_USER_ACCESS_TOKENS_ERROR: 'ADMIN_SET_USER_ACCESS_TOKENS_ERROR',
  ADMIN_SET_USER_ACCESS_TOKENS_START: 'ADMIN_SET_USER_ACCESS_TOKENS_START',
  ADMIN_SET_USER_ACCESS_TOKENS_SUCCESS: 'ADMIN_SET_USER_ACCESS_TOKENS_SUCCESS',
  ADMIN_SET_USER_TOKENS_REMOVED: 'ADMIN_SET_USER_TOKENS_REMOVED',
  ANNOUNC_ADDED_GROUP_SUCCESS: 'ANNOUNC_ADDED_GROUP_SUCCESS',
  ANNOUNC_DELETED_GROUP_SUCCESS: 'ANNOUNC_DELETED_GROUP_SUCCESS',
  ANNOUNC_LOAD_DPTS_FAIL: 'ANNOUNC_LOAD_DPTS_FAIL',
  ANNOUNC_LOAD_DPTS_START: 'ANNOUNC_LOAD_DPTS_START',
  ANNOUNC_LOAD_DPTS_SUCCESS: 'ANNOUNC_LOAD_DPTS_SUCCESS',
  ANNOUNC_LOAD_ROLE_FAIL: 'ANNOUNC_LOAD_ROLE_FAIL',
  ANNOUNC_LOAD_ROLE_START: 'ANNOUNC_LOAD_ROLE_START',
  ANNOUNC_LOAD_ROLE_SUCCESS: 'ANNOUNC_LOAD_ROLE_SUCCESS',
  ANNOUNC_LOAD_SINGLE_DPT_SUCCESS: 'ANNOUNC_LOAD_SINGLE_DPT_SUCCESS',
  ANNOUNC_SLACK_CHANNELS_START: 'ANNOUNC_SLACK_CHANNELS_START',
  ANNOUNC_SLACK_CHANNELS_END: 'ANNOUNC_SLACK_CHANNELS_END',
  ANNOUNC_SET_SLACK_CHANNELS_SUCCESS: 'ANNOUNC_SET_SLACK_CHANNELS_SUCCESS',
  ANNOUNC_LOAD_SINGLE_LOCATION_SUCCESS: 'ANNOUNC_LOAD_SINGLE_LOCATION_SUCCESS',
  ANNOUNC_SAVE_GROUP_SUCCESS: 'ANNOUNC_SAVE_GROUP_SUCCESS',
  ANNOUNC_UPDATE_FAIL: 'ANNOUNC_UPDATE_FAIL',
  ANNOUNC_UPDATE_GROUP_FAIL: 'ANNOUNC_UPDATE_GROUP_FAIL',
  ANNOUNC_UPDATE_GROUP_START: 'ANNOUNC_UPDATE_GROUP_START',
  ANNOUNC_UPDATE_START: 'ANNOUNC_UPDATE_START',
  ANNOUNC_UPDATE_SUCCESS: 'ANNOUNC_UPDATE_SUCCESS',
  ANON_CHANGE_PASSWORD_FAIL: 'ANON_CHANGE_PASSWORD_FAIL',
  ANON_CHANGE_PASSWORD_SUCCESS: 'ANON_CHANGE_PASSWORD_SUCCESS',
  ANON_RESET_PASSWORD_INIT: 'ANON_RESET_PASSWORD_INIT',
  ANON_RESET_PASSWORD_SEND_CODE_FAIL: 'ANON_RESET_PASSWORD_SEND_CODE_FAIL',
  ANON_RESET_PASSWORD_SEND_CODE_START: 'ANON_RESET_PASSWORD_SEND_CODE_START',
  ANON_RESET_PASSWORD_SEND_CODE_SUCCESS:
    'ANON_RESET_PASSWORD_SEND_CODE_SUCCESS',
  ANON_RESET_PASSWORD_SEND_NEW_PASSWORD_START:
    'ANON_RESET_PASSWORD_SEND_NEW_PASSWORD_START',
  ANON_RESET_PASSWORD_VALIDATE_CODE_FAIL: 'RESET_PASSWORD_VALIDATE_CODE_FAIL',
  ANON_RESET_PASSWORD_VALIDATE_CODE_START: 'RESET_PASSWORD_VALIDATE_CODE_START',
  ANON_RESET_PASSWORD_VALIDATE_CODE_SUCCESS:
    'RESET_PASSWORD_VALIDATE_CODE_SUCCESS',
  ANSWER_SURVEY_ON_CARD: 'ANSWER_SURVEY_ON_SURVEY_CARD',
  APPROVAL_FEED_FAIL: 'APPROVAL_FEED_FAIL',
  APPROVALS_FILTER_UPDATE: 'APPROVALS_FILTER_UPDATE',
  SET_REJECTION_REASON_CONFIG: 'SET_REJECTION_REASON_CONFIG',
  APPROVAL_FEED_START: 'APPROVAL_FEED_START',
  APPROVAL_FEED_SUCCESS: 'APPROVAL_FEED_SUCCESS',
  APP_BOOTSTRAP_FAIL: 'APP_BOOTSTRAP_FAIL',
  APPROVE_ALL_ACTION_END: 'APPROVE_ALL_ACTION_END',
  APP_BOOTSTRAP_START: 'APP_BOOTSTRAP_START',
  APPROVE_ALL_ACTION_START: 'APPROVE_ALL_ACTION_START',
  APP_BOOTSTRAP_SUCCESS: 'APP_BOOTSTRAP_SUCCESS',
  APP_HELLO_ACTIONS_END: 'APP_HELLO_ACTIONS_END',
  APP_HELLO_ACTIONS_START: 'APP_HELLO_ACTIONS_START',
  APP_UI_CLOSE_CARD_DELEGATE: 'APP_UI_CLOSE_CARD_DELEGATE',
  APP_UI_CLOSE_CASE_DELEGATE: 'APP_UI_CLOSE_CASE_DELEGATE',
  APP_UI_CLOSE_FEEDBACK_MODAL: 'APP_UI_CLOSE_FEEDBACK_MODAL',
  APP_UI_CLOSE_MORE_MENU: 'APP_UI_CLOSE_MORE_MENU',
  APP_UI_CLOSE_WORKFLOW_MODAL: 'APP_UI_CLOSE_WORKFLOW_MODAL',
  APP_UI_LAUNCH_WF_START: 'APP_UI_LAUNCH_WF_START',
  APP_UI_OPEN_CARD_DELEGATE: 'APP_UI_OPEN_CARD_DELEGATE',
  APP_UI_OPEN_CASE_DELEGATE: 'APP_UI_OPEN_CASE_DELEGATE',
  APP_UI_OPEN_EE_WF: 'APP_UI_OPEN_EE_WF',
  APP_UI_OPEN_FEEDBACK_MODAL: 'APP_UI_OPEN_FEEDBACK_MODAL',
  APP_UI_OPEN_WORKFLOW_MODAL: 'APP_UI_OPEN_WORKFLOW_MODAL',
  APP_UI_TOGGLE_MORE_MENU: 'APP_UI_TOGGLE_MORE_MENU',
  ASSET_START_SUPPORT_MODAL: 'ASSET_START_SUPPORT_MODAL',
  ASSIGN_DELEGATE_FAIL: 'ASSIGN_DELEGATE_FAIL',
  ASSIGN_DELEGATE_START: 'ASSIGN_DELEGATE_START',
  ASSIGN_DELEGATE_SUCCESS: 'ASSIGN_DELEGATE_SUCCESS',
  AUTH_BE_DELETE_SUCCESS: 'AUTH_BE_DELETE_SUCCESS',
  AUTH_BE_UPDATE_FAIL: 'AUTH_BE_UPDATE_FAIL',
  AUTH_BE_UPDATE_START: 'AUTH_BE_UPDATE_START',
  AUTH_BE_UPDATE_SUCCESS: 'AUTH_BE_UPDATE_SUCCESS',
  AZURE_ACCOUNT_DELETE_FAILURE: 'AZURE_ACCOUNT_DELETE_FAILURE',
  AZURE_ACCOUNT_DELETE_START: 'AZURE_ACCOUNT_DELETE_START',
  AZURE_ACCOUNT_DELETE_SUCCESS: 'AZURE_ACCOUNT_DELETE_SUCCESS',
  AZURE_ACCOUNT_LOAD_FAILURE: 'AZURE_ACCOUNT_LOAD_FAILURE',
  AZURE_ACCOUNT_LOAD_START: 'AZURE_ACCOUNT_LOAD_START',
  AZURE_ACCOUNT_LOAD_SUCCESS: 'AZURE_ACCOUNT_LOAD_SUCCESS',
  AZURE_ACCOUNT_SAVE_FAILURE: 'AZURE_ACCOUNT_SAVE_FAILURE',
  AZURE_ACCOUNT_SAVE_START: 'AZURE_ACCOUNT_SAVE_START',
  AZURE_ACCOUNT_SAVE_SUCCESS: 'AZURE_ACCOUNT_SAVE_SUCCESS',
  BARISTA_EMAIL_TEST_CONNECTION_CLOSE: 'BARISTA_EMAIL_TEST_CONNECTION_CLOSE',
  BARISTA_EMAIL_TEST_CONNECTION_ERROR: 'BARISTA_EMAIL_TEST_CONNECTION_ERROR',
  BARISTA_EMAIL_TEST_CONNECTION_START: 'BARISTA_EMAIL_TEST_CONNECTION_START',
  BARISTA_EMAIL_TEST_CONNECTION_SUCCESS:
    'BARISTA_EMAIL_TEST_CONNECTION_SUCCESS',
  BARISTA_FAQ_ADD_ARCHETYPE_SUCCESS: 'BARISTA_FAQ_ADD_ARCHETYPE_SUCCESS',
  BARISTA_FAQ_ADD_FILE: 'BARISTA_FAQ_ADD_FILE',
  BARISTA_FAQ_EDIT_FAQ_ID: 'BARISTA_FAQ_EDIT_FAQ_ID',
  BARISTA_FAQ_IS_SEARCHING_TERM: 'BARISTA_FAQ_IS_SEARCHING_TERM',
  BARISTA_FAQ_LOAD_ARCHETYPE_FAIL: 'BARISTA_FAQ_LOAD_ARCHETYPE_FAIL',
  BARISTA_FAQ_LOAD_ARCHETYPE_START: 'BARISTA_FAQ_LOAD_ARCHETYPE_START',
  BARISTA_FAQ_LOAD_ARCHETYPE_SUCCESS: 'BARISTA_FAQ_LOAD_ARCHETYPE_SUCCESS',
  BARISTA_FAQS_ADD_RESULT_SUCCESS: 'BARISTA_FAQS_ADD_RESULT_SUCCESS',
  BARISTA_FAQ_LOAD_ENTITY_FAIL: 'BARISTA_FAQ_LOAD_ENTITY_FAIL',
  BARISTA_FAQS_ADD_SUCCESS: 'BARISTA_FAQS_ADD_SUCCESS',
  BARISTA_FAQ_LOAD_ENTITY_START: 'BARISTA_FAQ_LOAD_ENTITY_START',
  BARISTA_FAQS_GENERAL_FAIL: 'BARISTA_FAQS_GENERAL_FAIL',
  BARISTA_FAQ_LOAD_ENTITY_SUCCESS: 'BARISTA_FAQ_LOAD_ENTITY_SUCCESS',
  BARISTA_FAQS_GENERAL_START: 'BARISTA_FAQS_GENERAL_START',
  BARISTA_FAQ_LOAD_ENTITY_SYNONYM_FAIL: 'BARISTA_FAQ_LOAD_ENTITY_SYNONYM_FAIL',
  BARISTA_FAQS_GENERAL_SUCCESS: 'BARISTA_FAQS_GENERAL_SUCCESS',
  BARISTA_FAQ_LOAD_ENTITY_SYNONYM_START:
    'BARISTA_FAQ_LOAD_ENTITY_SYNONYM_START',
  BARISTA_FAQS_LOAD_IMG_FAIL: 'BARISTA_FAQS_LOAD_IMG_FAIL',
  BARISTA_FAQ_LOAD_ENTITY_SYNONYM_SUCCESS:
    'BARISTA_FAQ_LOAD_ENTITY_SYNONYM_SUCCESS',
  BARISTA_FAQS_LOAD_IMG_START: 'BARISTA_FAQS_LOAD_IMG_START',
  BARISTA_FAQ_LOAD_PRIMARY_ENTITY_FAIL: 'BARISTA_FAQ_LOAD_PRIMARY_ENTITY_FAIL',
  BARISTA_FAQS_LOAD_IMG_SUCCESS: 'BARISTA_FAQS_LOAD_IMG_SUCCESS',
  BARISTA_FAQ_LOAD_PRIMARY_ENTITY_START:
    'BARISTA_FAQ_LOAD_PRIMARY_ENTITY_START',
  BARISTA_FAQS_TOPIC_FAIL: 'BARISTA_FAQS_TOPIC_FAIL',
  BARISTA_FAQ_LOAD_PRIMARY_ENTITY_SUCCESS:
    'BARISTA_FAQ_LOAD_PRIMARY_ENTITY_SUCCESS',
  BARISTA_FAQS_TOPIC_START: 'BARISTA_FAQS_TOPIC_START',
  BARISTA_FAQ_LOAD_TRIGGER_FAIL: 'BARISTA_FAQ_LOAD_TRIGGER_FAIL',
  BARISTA_FAQS_TOPIC_SUCCESS: 'BARISTA_FAQS_TOPIC_SUCCESS',
  BARISTA_FAQ_LOAD_TRIGGER_START: 'BARISTA_FAQ_LOAD_TRIGGER_START',
  BARISTA_FAQS_UPDATE_SUCCESS: 'BARISTA_FAQS_UPDATE_SUCCESS',
  BARISTA_FAQ_LOAD_TRIGGER_SUCCESS: 'BARISTA_FAQ_LOAD_TRIGGER_SUCCESS',
  BARISTA_FAQ_SEARCH_TERM: 'BARISTA_FAQ_SEARCH_TERM',
  BARISTA_FAQ_SELECT_DEPT: 'BARISTA_FAQ_SELECT_DEPT',
  BARISTA_FAQ_UPDATE_FAIL: 'BARISTA_FAQ_UPDATE_FAIL',
  BARISTA_FAQ_UPDATE_START: 'BARISTA_FAQ_UPDATE_START',
  BARISTA_FAQ_UPDATE_STOP: 'BARISTA_FAQ_UPDATE_STOP',
  BARISTA_FAQ_WF_REQUEST_FAIL: 'BARISTA_FAQ_WF_REQUEST_FAIL',
  BARISTA_FAQ_WF_REQUEST_START: 'BARISTA_FAQ_WF_REQUEST_START',
  BARISTA_FAQ_WF_REQUEST_SUCCEED: 'BARISTA_FAQ_WF_REQUEST_SUCCEED',
  BARISTA_INBOXES_LOAD_ERROR: 'BARISTA_INBOXES_LOAD_ERROR',
  BARISTA_INBOXES_LOAD_START: 'BARISTA_INBOXES_LOAD_START',
  BARISTA_INBOXES_LOAD_SUCCESS: 'BARISTA_INBOXES_LOAD_SUCCESS',
  BARISTA_INBOX_ADD_FAIL: 'BARISTA_INBOX_ADD_FAIL',
  BARISTA_INBOX_ADD_START: 'BARISTA_INBOX_ADD_START',
  BARISTA_INBOX_ADD_SUCCESS: 'BARISTA_INBOX_ADD_SUCCESS',
  BARISTA_INBOX_CANCEL_EMAIL_INBOX_ERRORS:
    'BARISTA_INBOX_CANCEL_EMAIL_INBOX_ERRORS',
  BARISTA_INBOX_CLOSE_CONFIRM_EMAIL_INBOX_ERRORS:
    'BARISTA_INBOX_CLOSE_CONFIRM_EMAIL_INBOX_ERRORS',
  BARISTA_INBOX_CONFIRM_EMAIL_INBOX_ERRORS:
    'BARISTA_INBOX_CONFIRM_EMAIL_INBOX_ERRORS',
  BARISTA_INBOX_CREDENTIAL_UPDATE_ERROR:
    'BARISTA_INBOX_CREDENTIAL_UPDATE_ERROR',
  BARISTA_INBOX_CREDENTIAL_UPDATE_START:
    'BARISTA_INBOX_CREDENTIAL_UPDATE_START',
  BARISTA_INBOX_CREDENTIAL_UPDATE_SUCCESS:
    'BARISTA_INBOX_CREDENTIAL_UPDATE_SUCCESS',
  BARISTA_INBOX_DELETE_FAIL: 'BARISTA_INBOX_DELETE_FAIL',
  BARISTA_INBOX_DELETE_START: 'BARISTA_INBOX_DELETE_START',
  BARISTA_INBOX_DELETE_SUCCESS: 'BARISTA_INBOX_DELETE_SUCCESS',
  BARISTA_INBOX_FORM_ERRORS: 'BARISTA_INBOX_FORM_ERRORS',
  BARISTA_INBOX_UPDATE_FAIL: 'BARISTA_INBOX_UPDATE_FAIL',
  BARISTA_INBOX_UPDATE_START: 'BARISTA_INBOX_UPDATE_START',
  BARISTA_INBOX_UPDATE_SUCCESS: 'BARISTA_INBOX_UPDATE_SUCCESS',
  BARISTA_INTENT_LOOKUP_FAIL: 'BARISTA_INTENT_LOOKUP_FAIL',
  BARISTA_INTENT_LOOKUP_START: 'BARISTA_INTENT_LOOKUP_START',
  BARISTA_INTENT_LOOKUP_SUCCESS: 'BARISTA_INTENT_LOOKUP_SUCCESS',
  BARISTA_KB_LOAD_ERROR: 'BARISTA_KB_LOAD_ERROR',
  BARISTA_KB_LOAD_START: 'BARISTA_KB_LOAD_START',
  BARISTA_KB_LOAD_SUCCESS: 'BARISTA_KB_LOAD_SUCCESS',
  BARISTA_KB_MODAL_CLOSE: 'BARISTA_KB_MODAL_CLOSE',
  BARISTA_KB_MODAL_OPEN: 'BARISTA_KB_MODAL_OPEN',
  BARISTA_LOAD_INTENTS_ERROR: 'BARISTA_LOAD_INTENTS_ERROR',
  BARISTA_LOAD_INTENTS_START: 'BARISTA_LOAD_INTENTS_START',
  BARISTA_LOAD_INTENTS_SUCCESS: 'BARISTA_LOAD_INTENTS_SUCCESS',
  BARISTA_LOAD_INTENT_ERROR: 'BARISTA_LOAD_INTENT_ERROR',
  BARISTA_LOAD_INTENT_START: 'BARISTA_LOAD_INTENT_START',
  BARISTA_LOAD_INTENT_SUCCESS: 'BARISTA_LOAD_INTENT_SUCCESS',
  BARISTA_LOAD_KB_SUPPORT_ERROR: 'BARISTA_LOAD_KB_SUPPORT_ERROR',
  BARISTA_LOAD_KB_SUPPORT_START: 'BARISTA_LOAD_KB_SUPPORT_START',
  BARISTA_LOAD_KB_SUPPORT_SUCCESS: 'BARISTA_LOAD_KB_SUPPORT_SUCCESS',
  BARISTA_LOAD_KNOWLEDGE_ARTICLE_ERROR: 'BARISTA_LOAD_KNOWLEDGE_ARTICLE_ERROR',
  BARISTA_LOAD_KNOWLEDGE_ARTICLE_START: 'BARISTA_LOAD_KNOWLEDGE_ARTICLE_START',
  BARISTA_LOAD_KNOWLEDGE_ARTICLE_SUCCESS:
    'BARISTA_LOAD_KNOWLEDGE_ARTICLE_SUCCESS',
  BARISTA_OVERRIDE_APPLICATION_FAILURE: 'BARISTA_OVERRIDE_APPLICATION_FAILURE',
  BARISTA_OVERRIDE_APPLICATION_START: 'BARISTA_OVERRIDE_APPLICATION_START',
  BARISTA_OVERRIDE_APPLICATION_SUCCESS: 'BARISTA_OVERRIDE_APPLICATION_SUCCESS',
  BARISTA_SCOPED_INTENT_LOOKUP_FAIL: 'BARISTA_SCOPED_INTENT_LOOKUP_FAIL',
  BARISTA_SCOPED_INTENT_LOOKUP_START: 'BARISTA_SCOPED_INTENT_LOOKUP_START',
  BARISTA_SCOPED_INTENT_LOOKUP_SUCCESS: 'BARISTA_SCOPED_INTENT_LOOKUP_SUCCESS',
  BARISTA_SINGLE_APPLICATION_FAILURE: 'BARISTA_SINGLE_APPLICATION_FAILURE',
  BARISTA_SINGLE_APPLICATION_START: 'BARISTA_SINGLE_APPLICATION_START',
  BARISTA_SINGLE_APPLICATION_SUCCESS: 'BARISTA_SINGLE_APPLICATION_SUCCESS',
  BARISTA_UPDATE_KNOWLEDGE_ARTICLE_ERROR:
    'BARISTA_UPDATE_KNOWLEDGE_ARTICLE_ERROR',
  BARISTA_UPDATE_KNOWLEDGE_ARTICLE_START:
    'BARISTA_UPDATE_KNOWLEDGE_ARTICLE_START',
  BARISTA_UPDATE_KNOWLEDGE_ARTICLE_SUCCESS:
    'BARISTA_UPDATE_KNOWLEDGE_ARTICLE_SUCCESS',
  BUNDLE_ADMIN_CREATE_ERROR: 'BUNDLE_ADMIN_CREATE_ERROR',
  BUNDLE_ADMIN_CREATE_START: 'BUNDLE_ADMIN_CREATE_START',
  BUNDLE_ADMIN_CREATE_SUCCESS: 'BUNDLE_ADMIN_CREATE_SUCCESS',
  BUNDLE_ADMIN_DETAIL_ClEAR: 'BUNDLE_ADMIN_DETAIL_ClEAR',
  BUNDLE_ADMIN_DETAIL_ERROR: 'BUNDLE_ADMIN_DETAIL_ERROR',
  BUNDLE_ADMIN_DETAIL_START: 'BUNDLE_ADMIN_DETAIL_START',
  BUNDLE_ADMIN_DETAIL_SUCCESS: 'BUNDLE_ADMIN_DETAIL_SUCCESS',
  BUNDLE_ADMIN_SET_NEW_BUNDLE: 'BUNDLE_ADMIN_SET_NEW_BUNDLE',
  BUNDLE_ADMIN_SET_NEW_PRODUCT: 'BUNDLE_ADMIN_SET_NEW_PRODUCT',
  BUNDLE_SELECTED: 'BUNDLE_SELECTED',
  BUNDLE_SET_SEARCH_BUNDLES_RESULTS: 'BUNDLE_SET_SEARCH_BUNDLES_RESULTS',
  BUNDLE_SET_SEARCH_QUERY: 'BUNDLE_SET_SEARCH_QUERY',
  BUNDLE_SET_SEARCH_ROLE_BUNDLES_RESULTS:
    'BUNDLE_SET_SEARCH_ROLE_BUNDLES_RESULTS',
  BUNDLE_SET_SEARCH_TERM: 'BUNDLE_SET_SEARCH_TERM',
  CANCEL_CARD_SUCCESS: 'CANCEL_CARD_SUCCESS',
  CANCEL_CSV_PARSING_FAIL: 'CANCEL_CSV_PARSING_FAIL',
  CANCEL_CSV_PARSING_START: 'CANCEL_CSV_PARSING_START',
  CANCEL_CSV_PARSING_SUCCESS: 'CANCEL_CSV_PARSING_SUCCESS',
  CANCEL_REQUEST_SUCCESS: 'CANCEL_REQUEST_SUCCESS',
  CARD_ACTION_END: 'CARD_ACTION_END',
  CARD_ACTION_START: 'CARD_ACTION_START',
  CARD_TOGGLE_CARD_OPEN: 'CARD_TOGGLE_CARD_OPEN',
  CART_CLOSE_MODAL: 'CART_CLOSE_MODAL',
  CART_OPEN_MODAL: 'CART_OPEN_MODAL',
  CASE_ACTION_END: 'CASE_ACTION_END',
  CASE_ACTION_START: 'CASE_ACTION_START',
  CASE_APPROVE_START: 'CASE_APPROVE_START',
  CASE_ASSING_TO_ME_START: 'CASE_ASSING_TO_ME_START',
  CASE_ASSING_TO_START: 'CASE_ASSING_TO_START',
  CASE_CLOSE_ADD_SUBSCRIBERS: 'CASE_CLOSE_ADD_SUBSCRIBERS',
  CASE_CREATING_NEW_END: 'CASE_CREATING_NEW_END',
  CASE_CREATING_NEW_START: 'CASE_CREATING_NEW_START',
  CASE_DISCARD_START: 'CASE_DISCARD_START',
  CASE_FEED_FAIL: 'CASE_FEED_FAIL',
  CASE_FEED_START: 'CASE_FEED_START',
  CASE_FEED_SUCCESS: 'CASE_FEED_SUCCESS',
  CASE_IS_SEARCHING_TEAM: 'CASE_IS_SEARCHING_TEAM',
  CASE_LOAD_SUMMARY_FAIL: 'CASE_LOAD_SUMMARY_FAIL',
  CASE_LOAD_SUMMARY_START: 'CASE_LOAD_SUMMARY_START',
  CASE_LOAD_SUMMARY_SUCCESS: 'CASE_LOAD_SUMMARY_SUCCESS',
  CASE_LOAD_TEAMS_LIST_FAIL: 'CASE_LOAD_TEAMS_LIST_FAIL',
  CASE_LOAD_TEAMS_LIST_START: 'CASE_LOAD_TEAMS_LIST_START',
  CASE_LOAD_TEAMS_LIST_SUCCESS: 'CASE_LOAD_TEAMS_LIST_SUCCESS',
  CASE_OPEN_ADD_SUBSCRIBERS: 'CASE_OPEN_ADD_SUBSCRIBERS',
  CASE_REJECT_START: 'CASE_REJECT_START',
  CASES_CUSTOM_TASK_TYPE_FILTER_ADDED: 'CASES_CUSTOM_TASK_TYPE_FILTER_ADDED',
  CASE_REOPEN_START: 'CASE_REOPEN_START',
  CASES_FILTER_UPDATE: 'CASES_FILTER_UPDATE',
  CASES_FILTER_RESET: 'CASES_FILTER_RESET',
  CASE_RESET_TEAMS_LIST: 'CASE_RESET_TEAMS_LIST',
  CASE_RESOLVE_START: 'CASE_RESOLVE_START',
  CASE_SET_DEFAULT_SUBSCRIBERS: 'CASE_SET_DEFAULT_SUBSCRIBERS',
  CASE_SET_KB_WF: 'CASE_SET_KB_WF',
  CASE_SET_QUERY: 'CASE_SET_QUERY',
  CASE_SET_USER_SEARCH_TERM: 'CASE_SET_USER_SEARCH_TERM',
  CASE_SUBSCRIBERS_END: 'CASE_SUBSCRIBERS_END',
  CASE_SUBSCRIBERS_START: 'CASE_SUBSCRIBERS_START',
  CASE_SUBS_SCOPE_FAIL: 'CASE_SUBS_SCOPE_FAIL',
  CASE_SUBS_SCOPE_START: 'CASE_SUBS_SCOPE_START',
  CASE_SUBS_SCOPE_SUCCESS: 'CASE_SUBS_SCOPE_SUCCESS',
  CASE_TASK_FAIL: 'CASE_TASK_FAIL',
  CASE_TASK_START: 'CASE_TASK_START',
  CASE_TASK_SUCCESS: 'CASE_TASK_SUCCESS',
  CASE_TASK_UPDATE: 'CASE_TASK_UPDATE',
  CASE_TASK_UPDATE_FAIL: 'CASE_TASK_UPDATE_FAIL',
  CASE_TASK_UPDATE_START: 'CASE_TASK_UPDATE_START',
  CASE_TOGGLE_CARD_OPEN: 'CASE_TOGGLE_CARD_OPEN',
  CATALOG_BROWSE: 'CATALOG_BROWSE',
  CHANGE_DEPARTMENT_USER_FAIL: 'CHANGE_DEPARTMENT_USER_FAIL',
  CHANGE_DEPARTMENT_USER_START: 'CHANGE_DEPARTMENT_USER_START',
  CHANGE_DEPARTMENT_USER_SUCCESS: 'CHANGE_DEPARTMENT_USER_SUCCESS',
  CHANGE_START_DATE_USER_FAIL: 'CHANGE_START_DATE_USER_FAIL',
  CHANGE_START_DATE_USER_START: 'CHANGE_START_DATE_USER_START',
  CHANGE_START_DATE_USER_SUCCESS: 'CHANGE_START_DATE_USER_SUCCESS',
  CHANGE_STATUS_ONBOARD_TEMPLATE: 'CHANGE_STATUS_ONBOARD_TEMPLATE',
  CHANGE_TENANT_NOT_FOUND: 'CHANGE_TENANT_NOT_FOUND',
  CHANNELS_FILTER_UPDATE: 'CHANNELS_FILTER_UPDATE',
  CHANNELS_FILTER_UPDATE_TYPE: 'CHANNELS_FILTER_UPDATE_TYPE',
  CHAT_ADD_DEFAULT_MESSAGE: 'CHAT_ADD_DEFAULT_MESSAGE',
  CHAT_IMAGE_LOADED: 'CHAT_IMAGE_LOADED',
  CHAT_LOAD_ATTACHMENT_FAIL: 'CHAT_LOAD_ATTACHMENT_FAIL',
  CHAT_LOAD_ATTACHMENT_START: 'CHAT_LOAD_ATTACHMENT_START',
  CHAT_LOAD_ATTACHMENT_SUCCESS: 'CHAT_LOAD_ATTACHMENT_SUCCESS',
  CHAT_LOAD_ONE_MESSAGE_SUCCESS: 'CHAT_LOAD_ONE_MESSAGE_SUCCESS',
  CHAT_MESSAGE_RECEIVED: 'CHAT_MESSAGE_RECEIVED',
  CLEAN_ADD_TEAM_MEMBER: 'CLEAN_ADD_TEAM_MEMBER',
  CLEAR_ASSET_ITEM: 'CLEAR_ASSET_ITEM',
  CLEAR_BUNDLE_TEMPORARY_PERMISSIONS: 'CLEAR_BUNDLE_TEMPORARY_PERMISSIONS',
  CLEAR_CART_ITEM: 'CLEAR_CART_ITEM',
  CLEAR_INTERVAL_IDS: 'CLEAR_INTERVAL_IDS',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
  CLEAR_SESSION: 'CLEAR_SESSION',
  CLEAR_TEMPORARY_BUNDLE_ITEM: 'CLEAR_TEMPORARY_BUNDLE_ITEM',
  CLEAR_TEMPORARY_PRODUCT_IMAGE: 'CLEAR_TEMPORARY_PRODUCT_IMAGE',
  CLEAR_TENANT: 'CLEAR_TENANT',
  CLIENT_VERSION: 'CLIENT_VERSION',
  CLOSE_SUPPORT_CHANNEL: 'CLOSE_SUPPORT_CHANNEL',
  COMPATIBILITY_CHECK_ERROR: 'COMPATIBILITY_CHECK_ERROR',
  COMPATIBILITY_CHECK_START: 'COMPATIBILITY_CHECK_START',
  COMPATIBILITY_CHECK_SUCCESS: 'COMPATIBILITY_CHECK_SUCCESS',
  COMPATIBILITY_FORCE_COMPATIBLE_STATUS:
    'COMPATIBILITY_FORCE_COMPATIBLE_STATUS',
  CONFIRM_CARD_SUCCESS: 'CONFIRM_CARD_SUCCESS',
  CORDOVA_KEYBOARD_HIDDEN: 'CORDOVA_KEYBOARD_HIDDEN',
  CORDOVA_KEYBOARD_VISIBLE: 'CORDOVA_KEYBOARD_VISIBLE',
  CORDOVA_REGISTER_NOTIFICATIONS_SUCCESS:
    'CORDOVA_REGISTER_NOTIFICATIONS_SUCCESS',
  CREATE_BARISTA_SEARCH_CONDITION_ERROR:
    'CREATE_BARISTA_SEARCH_CONDITION_ERROR',
  CREATE_BARISTA_SEARCH_CONDITION_START:
    'CREATE_BARISTA_SEARCH_CONDITION_START',
  CREATE_BARISTA_SEARCH_CONDITION_SUCCESS:
    'CREATE_BARISTA_SEARCH_CONDITION_SUCCESS',
  CREATE_CASE_FAIL: 'CREATE_CASE_FAIL',
  CREATE_CASE_START: 'CREATE_CASE_START',
  CREATE_CASE_SUCCESS: 'CREATE_CASE_SUCCESS',
  CREATE_FAQ_END: 'CREATE_FAQ_END',
  CREATE_FAQ_START: 'CREATE_FAQ_START',
  CREATE_NEW_PRODUCT_ERROR: 'CREATE_NEW_PRODUCT_ERROR',
  CREATE_NEW_PRODUCT_START: 'CREATE_NEW_PRODUCT_START',
  CREATE_NEW_PRODUCT_SUCCESS: 'CREATE_NEW_PRODUCT_SUCCESS',
  CREATE_NEW_USER_FAIL: 'CREATE_NEW_USER_FAIL',
  CREATE_NEW_USER_START: 'CREATE_NEW_USER_START',
  CREATE_NEW_USER_SUCCESS: 'CREATE_NEW_USER_SUCCESS',
  CREATE_TEAM_SERVICE_DEPARTMENT_FAIL: 'CREATE_TEAM_SERVICE_DEPARTMENT_FAIL',
  CREATE_TEAM_SERVICE_DEPARTMENT_START: 'CREATE_TEAM_SERVICE_DEPARTMENT_START',
  CREATE_TEAM_SERVICE_DEPARTMENT_SUCCESS:
    'CREATE_TEAM_SERVICE_DEPARTMENT_SUCCESS',
  CSV_INSTANCE_ERROR: 'CSV_INSTANCE_ERROR',
  CSV_INSTANCE_START: 'CSV_INSTANCE_START',
  CSV_INSTANCE_SUCCESS: 'CSV_INSTANCE_SUCCESS',
  DASHBOARD_GET_CONTENT_FEEDBACK_FAIL: 'DASHBOARD_GET_CONTENT_FEEDBACK_FAIL',
  DASHBOARD_GET_CONTENT_FEEDBACK_START: 'DASHBOARD_GET_CONTENT_FEEDBACK_START',
  DASHBOARD_GET_CONTENT_FEEDBACK_SUCCESS:
    'DASHBOARD_GET_CONTENT_FEEDBACK_SUCCESS',
  DASHBOARD_GET_COUNTERS_FAIL: 'DASHBOARD_GET_COUNTERS_FAIL',
  DASHBOARD_GET_COUNTERS_START: 'DASHBOARD_GET_COUNTERS_START',
  DASHBOARD_GET_COUNTERS_SUCCESS: 'DASHBOARD_GET_COUNTERS_SUCCESS',
  DASHBOARD_GET_DEFLECTED_DATA_FAIL: 'DASHBOARD_GET_DEFLECTED_DATA_FAIL',
  DASHBOARD_GET_DEFLECTED_DATA_START: 'DASHBOARD_GET_DEFLECTED_DATA_START',
  DASHBOARD_GET_DEFLECTED_DATA_SUCCESS: 'DASHBOARD_GET_DEFLECTED_DATA_SUCCESS',
  DASHBOARD_GET_GEO_DISTRIBUTION_FAIL: 'DASHBOARD_GET_GEO_DISTRIBUTION_FAIL',
  DASHBOARD_GET_GEO_DISTRIBUTION_START: 'DASHBOARD_GET_GEO_DISTRIBUTION_START',
  DASHBOARD_GET_GEO_DISTRIBUTION_SUCCESS:
    'DASHBOARD_GET_GEO_DISTRIBUTION_SUCCESS',
  DASHBOARD_GET_INTERACTION_BY_CHANNEL_FAIL:
    'DASHBOARD_GET_INTERACTION_BY_CHANNEL_FAIL',
  DASHBOARD_GET_INTERACTION_BY_CHANNEL_START:
    'DASHBOARD_GET_INTERACTION_BY_CHANNEL_START',
  DASHBOARD_GET_INTERACTION_BY_CHANNEL_SUCCESS:
    'DASHBOARD_GET_INTERACTION_BY_CHANNEL_SUCCESS',
  DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_FAIL:
    'DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_FAIL',
  DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_START:
    'DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_START',
  DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_SUCCESS:
    'DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_SUCCESS',
  DASHBOARD_GET_TOP_MATCHED_INTENTS_FAIL:
    'DASHBOARD_GET_TOP_MATCHED_INTENTS_FAIL',
  DASHBOARD_GET_TOP_MATCHED_INTENTS_START:
    'DASHBOARD_GET_TOP_MATCHED_INTENTS_START',
  DASHBOARD_GET_TOP_MATCHED_INTENTS_SUCCESS:
    'DASHBOARD_GET_TOP_MATCHED_INTENTS_SUCCESS',
  DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_FAIL:
    'DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_FAIL',
  DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_START:
    'DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_START',
  DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_SUCCESS:
    'DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_SUCCESS',
  DEFAULT_SERVICE_TEAM_ERROR: 'DEFAULT_SERVICE_TEAM_ERROR',
  DELETE_ANNOUNCEMENT_SUCCESS: 'DELETE_ANNOUNCEMENT_SUCCESS',
  DELETE_ANNOUNC_START: 'DELETE_ANNOUNC_START',
  DELETE_BARISTA_SEARCH_CONDITION_SUCCESS:
    'DELETE_BARISTA_SEARCH_CONDITION_SUCCESS',
  DELETE_CARD_SUCCESS: 'DELETE_CARD_SUCCESS',
  DELETE_CASE: 'DELETE_CASE',
  DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_FAIL:
    'DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_FAIL',
  DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_START:
    'DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_START',
  DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_SUCCESS:
    'DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_SUCCESS',
  DELETE_CHANNELS: 'DELETE_CHANNELS',
  DELETE_CHAT_CHANNEL: 'DELETE_CHAT_CHANNEL',
  DELETE_CHAT_MESSAGE: 'DELETE_CHAT_MESSAGE',
  DELETE_CSV_SCHEDULE_FAILURE: 'DELETE_CSV_SCHEDULE_FAILURE',
  DELETE_CSV_SCHEDULE_START: 'DELETE_CSV_SCHEDULE_START',
  DELETE_CSV_SCHEDULE_SUCCESS: 'DELETE_CSV_SCHEDULE_SUCCESS',
  DELETE_CURRENT_USER: 'DELETE_CURRENT_USER',
  DELETE_ONBOARD_PERFORMANCE_SUMMARY_FAIL:
    'DELETE_ONBOARD_PERFORMANCE_SUMMARY_FAIL',
  DELETE_ONBOARD_PERFORMANCE_SUMMARY_START:
    'DELETE_ONBOARD_PERFORMANCE_SUMMARY_START',
  DELETE_ONBOARD_PERFORMANCE_SUMMARY_SUCCESS:
    'DELETE_ONBOARD_PERFORMANCE_SUMMARY_SUCCESS',
  DELETE_ONBOARD_SCHEDULE_FAIL: 'DELETE_ONBOARD_SCHEDULE_FAIL',
  DELETE_ONBOARD_SCHEDULE_START: 'DELETE_ONBOARD_SCHEDULE_START',
  DELETE_ONBOARD_SCHEDULE_SUCCESS: 'DELETE_ONBOARD_SCHEDULE_SUCCESS',
  DELETE_SERVICE_DEPARTMENT_FAIL: 'DELETE_SERVICE_DEPARTMENT_FAIl',
  DELETE_SERVICE_DEPARTMENT_START: 'DELETE_SERVICE_DEPARTMENT_START',
  DELETE_SERVICE_DEPARTMENT_SUCCESS: 'DELETE_SERVICE_DEPARTMENT_SUCCESS',
  DELETE_SNOW_WORKFLOW_MAP_SUCCESS: 'DELETE_SNOW_WORKFLOW_MAP_SUCCESS',
  DELETE_TEAM_MEMBER_FAIL: 'DELETE_TEAM_MEMBER_FAIL',
  DELETE_TEAM_MEMBER_START: 'DELETE_TEAM_MEMBER_START',
  DELETE_TEAM_MEMBER_SUCCESS: 'DELETE_TEAM_MEMBER_SUCCESS',
  DELETE_TEAM_SERVICE_DEPARTMENT_FAIL: 'DELETE_TEAM_SERVICE_DEPARTMENT_FAIL',
  DELETE_TEAM_SERVICE_DEPARTMENT_START: 'DELETE_TEAM_SERVICE_DEPARTMENT_START',
  DELETE_TEAM_SERVICE_DEPARTMENT_SUCCESS:
    'DELETE_TEAM_SERVICE_DEPARTMENT_SUCCESS',
  DELETE_TEMPLATE_INTERVAL_SELECTED: 'DELETE_TEMPLATE_INTERVAL_SELECTED',
  DELETE_TEMPORARY_BUNDLE_IMAGE: 'DELETE_TEMPORARY_BUNDLE_IMAGE',
  DELETE_TEMPORARY_BUNDLE_IMAGES: 'DELETE_TEMPORARY_BUNDLE_IMAGES',
  DELETE_TEMPORARY_PRODUCT_IMAGE: 'DELETE_TEMPORARY_PRODUCT_IMAGE',
  DELETE_TENANT_CONFIG_LABEL_SUCCESS: 'DELETE_TENANT_CONFIG_LABEL_SUCCESS',
  DELETE_TOKEN: 'DELETE_TOKEN',
  DEREGISTER_USER_TYPING: 'DEREGISTER_USER_TYPING',
  DIRECTORY_PREPEND_SCROLL_FIXED: 'DIRECTORY_PREPEND_SCROLL_FIXED',
  DIRECTORY_SCROLLED_TO_ACTIVE_USER: 'DIRECTORY_SCROLLED_TO_ACTIVE_USER',
  DIRECTORY_SCROLLED_TO_DIVIDER: 'DIRECTORY_SCROLLED_TO_DIVIDER',
  DIRECTORY_SCROLL_TO_DIVIDER_REQUIRED: 'DIRECTORY_SCROLL_TO_DIVIDER_REQUIRED',
  DISMISS_CARD_SUCCESS: 'DISMISS_CARD_SUCCESS',
  DPC_CLEAR_ATTRIBUTES_PRODUCT: 'DPC_CLEAR_ATTRIBUTES_PRODUCT',
  DPC_COPY_TO_CATALOG_ERROR: 'DPC_COPY_TO_CATALOG_ERROR',
  DPC_COPY_TO_CATALOG_START: 'DPC_COPY_TO_CATALOG_START',
  DPC_COPY_TO_CATALOG_SUCCESS: 'DPC_COPY_TO_CATALOG_SUCCESS',
  DPC_INCLUDE_ITEMS_I_HAVE: 'DPC_INCLUDE_ITEMS_I_HAVE',
  DPC_IS_SEARCHING_ERROR: 'DPC_IS_SEARCHING_ERROR',
  DPC_IS_SEARCHING_START: 'DPC_IS_SEARCHING_START',
  DPC_IS_SEARCHING_SUCCESS: 'DPC_IS_SEARCHING_SUCCESS',
  DPC_LOADING_ERROR: 'DPC_LOADING_ERROR',
  DPC_LOADING_START: 'DPC_LOADING_START',
  DPC_LOADING_SUCCESS: 'DPC_LOADING_SUCCESS',
  DPC_LOAD_PRODUCTS_SEARCH_SUCCESS: 'DPC_LOAD_PRODUCTS_SEARCH_SUCCESS',
  DPC_LOAD_SINGLE_ERROR: 'DPC_LOAD_SINGLE_ERROR',
  DPC_LOAD_SINGLE_START: 'DPC_LOAD_SINGLE_START',
  DPC_LOAD_SINGLE_SUCCESS: 'DPC_LOAD_SINGLE_SUCCESS',
  DPC_REMOVE_PRODUCT_EID: 'DPC_REMOVE_PRODUCT_EID',
  DPC_SELECTED_ADD_PRODUCT: 'DPC_SELECTED_ADD_PRODUCT',
  DPC_SELECTED_REMOVE_PRODUCT: 'DPC_SELECTED_REMOVE_PRODUCT',
  DPC_SET_CATALOG_SEARCH_PRODUCTS_TERM: 'DPC_SET_CATALOG_SEARCH_PRODUCTS_TERM',
  DPC_SET_CATEGORY: 'DPC_SET_CATEGORY',
  DPC_SET_COPY_PRODUCT_ID: 'DPC_SET_COPY_PRODUCT_ID',
  DPC_SET_ORDER_BY: 'DPC_SET_ORDER_BY',
  DPC_SET_PRODUCT_FAMILY_FAIL: 'DPC_SET_PRODUCT_FAMILY_FAIL',
  DPC_SET_PRODUCT_FAMILY_START: 'DPC_SET_PRODUCT_FAMILY_START',
  DPC_SET_PRODUCT_FAMILY_SUCCESS: 'DPC_SET_PRODUCT_FAMILY_SUCCESS',
  DPC_SUB_CATEGORIES_LOADING_ERROR: 'DPC_SUB_CATEGORIES_LOADING_ERROR',
  DPC_SUB_CATEGORIES_LOADING_START: 'DPC_SUB_CATEGORIES_LOADING_START',
  DPC_SUB_CATEGORIES_LOADING_SUCCESS: 'DPC_SUB_CATEGORIES_LOADING_SUCCESS',
  DPC_TOP_CATEGORIES_LOADING_ERROR: 'DPC_TOP_CATEGORIES_LOADING_ERROR',
  DPC_TOP_CATEGORIES_LOADING_START: 'DPC_TOP_CATEGORIES_LOADING_START',
  DPC_TOP_CATEGORIES_LOADING_SUCCESS: 'DPC_TOP_CATEGORIES_LOADING_SUCCESS',
  EDIT_SERVICE_DEPARTMENT_SUCCESS: 'EDIT_SERVICE_DEPARTMENT_SUCCESS',
  ELC_LOAD_LAST_JOB_SUCCESS: 'ELC_LOAD_LAST_JOB_SUCCESS',
  ELC_RUN_SYNC_START: 'ELC_RUN_SYNC_START',
  ELC_RUN_SYNC_SUCCESS: 'ELC_RUN_SYNC_SUCCESS',
  ELECTRON_NOTIFICATION: 'ELECTRON_NOTIFICATION',
  ELECTRON_UPDATE_AVAILABLE: 'ELECTRON_UPDATE_AVAILABLE',
  ELECTRON_UPDATE_NOT_AVAILABLE: 'ELECTRON_UPDATE_NOT_AVAILABLE',
  EMAIL_CASE_ADD_FAIL: 'EMAIL_CASE_ADD_FAIL',
  EMAIL_CASE_ADD_START: 'EMAIL_CASE_ADD_START',
  EMAIL_CASE_ADD_SUCCESS: 'EMAIL_CASE_ADD_SUCCESS',
  EMAIL_CASE_DELETE_FAIL: 'EMAIL_CASE_DELETE_FAIL',
  EMAIL_CASE_DELETE_START: 'EMAIL_CASE_DELETE_START',
  EMAIL_CASE_DELETE_SUCCESS: 'EMAIL_CASE_DELETE_SUCCESS',
  EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_ADD_GENERATOR_SUCCESS:
    'EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_ADD_GENERATOR_SUCCESS',
  EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_FAIL:
    'EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_FAIL',
  EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_START:
    'EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_START',
  EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_SUCCESS:
    'EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_SUCCESS',
  EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_REMOVE_GENERATOR_SUCCESS:
    'EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_REMOVE_GENERATOR_SUCCESS',
  EMAIL_TEST_CONNECTION_CLOSE: 'EMAIL_TEST_CONNECTION_CLOSE',
  ERROR_ADD_EMAIL_CREDENTIALS: 'ERROR_ADD_EMAIL_CREDENTIALS',
  ERROR_EMAIL_TEST_CONNECTION: 'ERROR_EMAIL_TEST_CONNECTION',
  ERROR_GET_EMAIL_CREDENTIALS: 'ERROR_GET_EMAIL_CREDENTIALS',
  ERROR_UPDATE_EMAIL_CREDENTIALS: 'ERROR_UPDATE_EMAIL_CREDENTIALS',
  ESCALATE_PROGRESS_FAIL: 'ESCALATE_PROGRESS_FAIL',
  ESCALATE_PROGRESS_START: 'ESCALATE_PROGRESS_START',
  ESCALATE_PROGRESS_SUCCESS: 'ESCALATE_PROGRESS_SUCCESS',
  EXTERNAL_LOGIN_CHECKED: 'EXTERNAL_LOGIN_CHECKED',
  FAIL_RECALL_ANNOUNCEMENT: 'FAIL_RECALL_ANNOUNCEMENT',
  FAQ_ADMIN_ACTIVE_EDIT_FAQ: 'FAQ_ADMIN_ACTIVE_EDIT_FAQ',
  FAQ_AS_KB_ADDITIONAL_INFORMATION: 'FAQ_AS_KB_ADDITIONAL_INFORMATION',
  FAQ_AS_KB_IS_DISABLED_MODAL_CLOSE: 'FAQ_AS_KB_IS_DISABLED_MODAL_CLOSE',
  FAQ_AS_KB_IS_DISABLED_MODAL_OPEN: 'FAQ_AS_KB_IS_DISABLED_MODAL_OPEN',
  FAQ_CASES_SEARCH_START: 'FAQ_CASES_SEARCH_START',
  FAQ_CASES_SEARCH_SUCCESS: 'FAQ_CASES_SEARCH_SUCCESS',
  FAQ_WIZ_ADD_NEW_SYNON_SUCCESS: 'FAQ_WIZ_ADD_NEW_SYNON_SUCCESS',
  FAQ_WIZ_ADD_NEW_WORD_SUCCESS: 'FAQ_WIZ_ADD_NEW_WORD_SUCCESS',
  FAQ_WIZ_CLOSE: 'FAQ_WIZ_CLOSE',
  FAQ_WIZ_RESET: 'FAQ_WIZ_RESET',
  FAQ_WIZ_DESELECT_REQ_KEYWORD_LIST: 'FAQ_WIZ_DESELECT_REQ_KEYWORD_LIST',
  FAQ_WIZ_DESELECT_REQ_TEM: 'FAQ_WIZ_DESELECT_REQ_TEM',
  FAQ_WIZ_EDIT_SYNON_SUCCESS: 'FAQ_WIZ_EDIT_SYNON_SUCCESS',
  FAQ_WIZ_EDIT_WORD_SUCCESS: 'FAQ_WIZ_EDIT_WORD_SUCCESS',
  FAQ_WIZ_GET_DATA_START: 'FAQ_WIZ_GET_DATA_START',
  FAQ_WIZ_GET_DATA_SUCCESS: 'FAQ_WIZ_GET_DATA_SUCCESS',
  FAQ_WIZ_GET_ENTITY_FAIL: 'FAQ_WIZ_GET_ENTITY_FAIL',
  FAQ_WIZ_GET_ENTITY_START: 'FAQ_WIZ_GET_ENTITY_START',
  FAQ_WIZ_GET_ENTITY_SUCCESS: 'FAQ_WIZ_GET_ENTITY_SUCCESS',
  FAQ_WIZ_GET_ENTITY_WORDS_FAIL: 'FAQ_WIZ_GET_ENTITY_WORDS_FAIL',
  FAQ_WIZ_GET_ENTITY_WORDS_START: 'FAQ_WIZ_GET_ENTITY_WORDS_START',
  FAQ_WIZ_GET_ENTITY_WORDS_SUCCESS: 'FAQ_WIZ_GET_ENTITY_WORDS_SUCCESS',
  FAQ_WIZ_GET_TEMPLATES_FAIL: 'FAQ_WIZ_GET_TEMPLATES_FAIL',
  FAQ_WIZ_GET_TEMPLATES_START: 'FAQ_WIZ_GET_TEMPLATES_START',
  FAQ_WIZ_GET_TEMPLATES_SUCCESS: 'FAQ_WIZ_GET_TEMPLATES_SUCCESS',
  FAQ_WIZ_GET_TEMPLATE_EXAMPLES_FAIL: 'FAQ_WIZ_GET_TEMPLATE_EXAMPLES_FAIL',
  FAQ_WIZ_GET_TEMPLATE_EXAMPLES_START: 'FAQ_WIZ_GET_TEMPLATE_EXAMPLES_START',
  FAQ_WIZ_GET_TEMPLATE_EXAMPLES_SUCCESS:
    'FAQ_WIZ_GET_TEMPLATE_EXAMPLES_SUCCESS',
  FAQ_WIZ_GET_WORD_SYNON_FAIL: 'FAQ_WIZ_GET_WORD_SYNON_FAIL',
  FAQ_WIZ_GET_WORD_SYNON_START: 'FAQ_WIZ_GET_WORD_SYNON_START',
  FAQ_WIZ_GET_WORD_SYNON_SUCCESS: 'FAQ_WIZ_GET_WORD_SYNON_SUCCESS',
  FAQ_WIZ_OPEN: 'FAQ_WIZ_OPEN',
  FAQ_WIZ_REMOVE_SYNON_SUCCESS: 'FAQ_WIZ_REMOVE_SYNON_SUCCESS',
  FAQ_WIZ_REMOVE_WORD_SUCCESS: 'FAQ_WIZ_REMOVE_WORD_SUCCESS',
  FAQ_WIZ_RESET_SELECTED_WORD: 'FAQ_WIZ_RESET_SELECTED_WORD',
  FAQ_WIZ_RESET_SELECTIONS: 'FAQ_WIZ_RESET_SELECTIONS',
  FAQ_WIZ_SELECT_ENTITY: 'FAQ_WIZ_SELECT_ENTITY',
  FAQ_WIZ_SELECT_ENTITY_WORD: 'FAQ_WIZ_SELECT_ENTITY_WORD',
  FAQ_WIZ_SELECT_REQ_KEYWORD_LIST: 'FAQ_WIZ_SELECT_REQ_KEYWORD_LIST',
  FAQ_WIZ_SELECT_REQ_TEM: 'FAQ_WIZ_SELECT_REQ_TEM',
  FAQ_WIZ_SELECT_TEMPLATE: 'FAQ_WIZ_SELECT_TEMPLATE',
  FAVORITE_USER_FAIL: 'FAVORITE_USER_FAIL',
  FAVORITE_USER_START: 'FAVORITE_USER_START',
  FAVORITE_USER_SUCCESS: 'FAVORITE_USER_SUCCESS',
  FILL_CART_SUCCESS: 'FILL_CART_SUCCESS',
  FOLLOW_CARD_SUCCESS: 'FOLLOW_CARD_SUCCESS',
  FORCE_RESET_ERRORS: 'FORCE_RESET_ERRORS',
  FULFILLMENT_QUESTIONS_ANSWERED: 'FULFILLMENT_QUESTIONS_ANSWERED',
  FULFILLMENT_QUESTIONS_STARTED: 'FULFILLMENT_QUESTIONS_STARTED',
  GET_ANNOUNC_FAIL: 'GET_ANNOUNC_FAIL',
  GET_ANNOUNC_START: 'GET_ANNOUNC_START',
  GET_ANNOUNC_SUCCESS: 'GET_ANNOUNC_SUCCESS',
  GET_AUTH_BE_FAILURE: 'GET_AUTH_BE_FAILURE',
  GET_AUTH_BE_START: 'GET_AUTH_BE_START',
  GET_AUTH_BE_SUCCESS: 'GET_AUTH_BE_SUCCESS',
  GET_BARISTABOT_INTEGRATION_FAIL: 'GET_SLACKBOT_SETTINGS_FAIL',
  GET_BARISTABOT_INTEGRATION_START: 'GET_SLACKBOT_SETTINGS_START',
  GET_BARISTABOT_INTEGRATION_SUCCESS: 'GET_SLACKBOT_SETTINGS_SUCCESS',
  GET_CHANNELS_FAIL: 'GET_CHANNELS_FAIL',
  GET_CHANNELS_START: 'GET_CHANNELS_START',
  GET_CHANNELS_SUCCESS: 'GET_CHANNELS_SUCCESS',
  GET_CHILDREN_DEPARTMENTS_FAIL: 'GET_CHILDREN_DEPARTMENTS_FAIL',
  GET_CHILDREN_DEPARTMENTS_START: 'GET_CHILDREN_DEPARTMENTS_START',
  GET_CHILDREN_DEPARTMENTS_SUCCESS: 'GET_CHILDREN_DEPARTMENTS_SUCCESS',
  GET_CHILDREN_LOCATIONS_FAIL: 'GET_CHILDREN_LOCATIONS_FAIL',
  GET_CHILDREN_LOCATIONS_START: 'GET_CHILDREN_LOCATIONS_START',
  GET_CHILDREN_LOCATIONS_SUCCESS: 'GET_CHILDREN_LOCATIONS_SUCCESS',
  GET_CONFLUENCE_CREDENTIALS_FAIL: 'GET_CONFLUENCE_CREDENTIALS_FAIL',
  GET_CONFLUENCE_CREDENTIALS_START: 'GET_CONFLUENCE_CREDENTIALS_START',
  GET_CONFLUENCE_CREDENTIALS_SUCCESS: 'GET_CONFLUENCE_CREDENTIALS_SUCCESS',
  GET_CSV_CASES_REPORT_JOB_SUCCESS: 'GET_CSV_CASES_REPORT_JOB_SUCCESS',
  GET_CURRENT_FAQ_SUCCESS: 'GET_CURRENT_FAQ_SUCCESS',
  GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_FAIL:
    'GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_FAIL',
  // INTERACTIONS BY MATCHED ARCHETYPE get_interactions_by_matched_archetype
  GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_START:
    'GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_START',
  GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_SUCCESS:
    'GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_SUCCESS',
  GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_FAIL:
    'GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_FAIL',
  // Streamline process part 2
  // INTERACTIONS BY TOPIC
  GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_START:
    'GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_START',
  GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_SUCCESS:
    'GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_SUCCESS',
  GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_FAIL:
    'GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_FAIL',
  // // INTERACTIONS BY intents driving deflections get_intents_driving_deflection
  GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_START:
    'GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_START',
  GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_SUCCESS:
    'GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_SUCCESS',
  GET_DEFAULT_SELECTED_DEPARTMENT_FAIL: 'GET_DEFAULT_SELECTED_DEPARTMENT_FAIL',
  GET_DEFAULT_SELECTED_DEPARTMENT_START:
    'GET_DEFAULT_SELECTED_DEPARTMENT_START',
  GET_DEFAULT_SELECTED_DEPARTMENT_SUCCESS:
    'GET_DEFAULT_SELECTED_DEPARTMENT_SUCCESS',
  GET_DEFAULT_SELECTED_LOCATION_FAIL: 'GET_DEFAULT_SELECTED_LOCATION_FAIL',
  GET_DEFAULT_SELECTED_LOCATION_START: 'GET_DEFAULT_SELECTED_LOCATION_START',
  GET_DEFAULT_SELECTED_LOCATION_SUCCESS:
    'GET_DEFAULT_SELECTED_LOCATION_SUCCESS',
  GET_DEPLOYMENT_DATE_FAIL: 'GET_DEPLOYMENT_DATE_FAIL',
  GET_DEPLOYMENT_DATE_START: 'GET_DEPLOYMENT_DATE_START',
  GET_DEPLOYMENT_DATE_SUCCESS: 'GET_DEPLOYMENT_DATE_SUCCESS',
  GET_DIRECTORY_USERS_FAILURE: 'GET_DIRECTORY_USERS_FAILURE',
  GET_DIRECTORY_USERS_START: 'GET_DIRECTORY_USERS_START',
  GET_DIRECTORY_USERS_SUCCESS: 'GET_DIRECTORY_USERS_SUCCESS',
  GET_DIRECT_CHANNEL_FOR_USER_FAIL: 'GET_DIRECT_CHANNEL_FOR_USER_FAIL',
  GET_DIRECT_CHANNEL_FOR_USER_START: 'GET_DIRECT_CHANNEL_FOR_USER_START',
  GET_DIRECT_CHANNEL_FOR_USER_SUCCESS: 'GET_DIRECT_CHANNEL_FOR_USER_SUCCESS',
  GET_EMAIL_ADDRESS_POLICIES_FAIL: 'GET_EMAIL_ADDRESS_POLICIES_FAIL',
  GET_EMAIL_ADDRESS_POLICIES_START: 'GET_EMAIL_ADDRESS_POLICIES_START',
  GET_EMAIL_ADDRESS_POLICIES_SUCCESS: 'GET_EMAIL_ADDRESS_POLICIES_SUCCESS',
  GET_EMAIL_DOMAINS_FAIL: 'GET_EMAIL_DOMAINS_FAIL',
  GET_EMAIL_DOMAINS_START: 'GET_EMAIL_DOMAINS_START',
  GET_EMAIL_DOMAINS_SUCCESS: 'GET_EMAIL_DOMAINS_SUCCESS',
  GET_FAQ_TO_EDIT_FAIL: 'GET_FAQ_TO_EDIT_FAIL',
  GET_FAQ_TO_EDIT_START: 'GET_FAQ_TO_EDIT_START',
  GET_HARDWARE_BUNDLE_PRODUCTS_START: 'GET_HARDWARE_BUNDLE_PRODUCTS_START',
  GET_HARDWARE_BUNDLE_PRODUCTS_SUCCESS: 'GET_HARDWARE_BUNDLE_PRODUCTS_SUCCESS',
  GET_INTERACTION_USER_DETAILS_FAIL: 'GET_INTERACTION_USER_DETAILS_FAIL',
  GET_INTERACTION_USER_DETAILS_START: 'GET_INTERACTION_USER_DETAILS_START',
  GET_INTERACTION_USER_DETAILS_SUCCESS: 'GET_INTERACTION_USER_DETAILS_SUCCESS',
  GET_JOB_ROLES_SUCCESS: 'GET_JOB_ROLES_SUCCESS',
  GET_JOB_ROLES_WITH_BUNDLES_SUCCESS: 'GET_JOB_ROLES_WITH_BUNDLES_SUCCESS',
  GET_LIVE_CHAT_FEEDBACK_KEYS_FAIL: 'GET_LIVE_CHAT_FEEDBACK_KEYS_FAIL',
  GET_LIVE_CHAT_FEEDBACK_KEYS_START: 'GET_LIVE_CHAT_FEEDBACK_KEYS_START',
  GET_LIVE_CHAT_FEEDBACK_KEYS_SUCCESS: 'GET_LIVE_CHAT_FEEDBACK_KEYS_SUCCESS',
  GET_LOCATIONS_ERROR: 'GET_LOCATIONS_ERROR',
  GET_LOCATIONS_START: 'GET_LOCATIONS_START',
  GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
  GET_NEXT_DIRECTORY_USERS_FAILURE: 'GET_NEXT_DIRECTORY_USERS_FAILURE',
  GET_NEXT_DIRECTORY_USERS_START: 'GET_NEXT_DIRECTORY_USERS_START',
  GET_NEXT_DIRECTORY_USERS_SUCCESS: 'GET_NEXT_DIRECTORY_USERS_SUCCESS',
  GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL:
    'GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL',
  GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_START:
    'GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_START',
  GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS:
    'GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS',
  GET_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL:
    'GET_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL',
  GET_ONBOARD_PROGRESS_MANAGER_VIEW_START:
    'GET_ONBOARD_PROGRESS_MANAGER_VIEW_START',
  GET_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS:
    'GET_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS',
  GET_PENDING_ORDERS_FAIL: 'GET_PENDING_ORDERS_FAIL',
  GET_PENDING_ORDERS_START: 'GET_PENDING_ORDERS_START',
  GET_PENDING_ORDERS_SUCCESS: 'GET_PENDING_ORDERS_SUCCESS',
  GET_PENDING_ORDER_ITEMS_FAIL: 'GET_PENDING_ORDER_ITEMS_FAIL',
  GET_PENDING_ORDER_ITEMS_START: 'GET_PENDING_ORDER_ITEMS_START',
  GET_PENDING_ORDER_ITEMS_SUCCESS: 'GET_PENDING_ORDER_ITEMS_SUCCESS',
  GET_PREV_DIRECTORY_USERS_FAILURE: 'GET_PREV_DIRECTORY_USERS_FAILURE',
  GET_PREV_DIRECTORY_USERS_START: 'GET_PREV_DIRECTORY_USERS_START',
  GET_PREV_DIRECTORY_USERS_SUCCESS: 'GET_PREV_DIRECTORY_USERS_SUCCESS',
  GET_PRODUCT_PERMISSION_GROUPS_FAIL: 'GET_PRODUCT_PERMISSION_GROUPS_FAIL',
  GET_PRODUCT_PERMISSION_GROUPS_START: 'GET_PRODUCT_PERMISSION_GROUPS_START',
  GET_PRODUCT_PERMISSION_GROUPS_SUCCESS:
    'GET_PRODUCT_PERMISSION_GROUPS_SUCCESS',
  GET_REACT_CONVERSATION_FAIL: 'GET_REACT_CONVERSATION_FAIL',
  GET_REACT_CONVERSATION_START: 'GET_REACT_CONVERSATION_START',
  GET_REACT_CONVERSATION_SUCCESS: 'GET_REACT_CONVERSATION_SUCCESS',
  SET_REPORT_DATA_ESP_FILTER: 'SET_REPORT_DATA_ESP_FILTER',
  GET_REPORT_DATA_FAIL: 'GET_REPORT_DATA_FAIL',
  GET_REPORT_DATA_START: 'GET_REPORT_DATA_START',
  GET_REPORT_DATA_SUCCESS: 'GET_REPORT_DATA_SUCCESS',
  GET_REQUESTS_ERROR: 'GET_REQUESTS_ERROR',
  GET_REQUESTS_START: 'GET_REQUESTS_START',
  GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
  GET_REQUEST_DETAIL_ERROR: 'GET_REQUEST_DETAIL_ERROR',
  GET_REQUEST_DETAIL_START: 'GET_REQUEST_DETAIL_START',
  GET_REQUEST_DETAIL_SUCCESS: 'GET_REQUEST_DETAIL_SUCCESS',
  GET_ROOT_DEPARTMENTS_FAIL: 'GET_ROOT_DEPARTMENTS_FAIL',
  GET_ROOT_DEPARTMENTS_START: 'GET_ROOT_DEPARTMENTS_START',
  GET_ROOT_DEPARTMENTS_SUCCESS: 'GET_ROOT_DEPARTMENTS_SUCCESS',
  GET_ROOT_LOCATIONS_FAIL: 'GET_ROOT_LOCATIONS_FAIL',
  GET_ROOT_LOCATIONS_START: 'GET_ROOT_LOCATIONS_START',
  GET_ROOT_LOCATIONS_SUCCESS: 'GET_ROOT_LOCATIONS_SUCCESS',
  GET_SAML_SP_FAILURE: 'GET_SAML_SP_FAILURE',
  GET_SAML_SP_START: 'GET_SAML_SP_START',
  GET_SAML_SP_SUCCESS: 'GET_SAML_SP_SUCCESS',
  GET_SELECT_MY_GEAR_STEPS_FAIL: 'GET_SELECT_MY_GEAR_STEPS_FAIL',
  GET_SELECT_MY_GEAR_STEPS_START: 'GET_SELECT_MY_GEAR_STEPS_START',
  GET_SELECT_MY_GEAR_STEPS_SUCCESS: 'GET_SELECT_MY_GEAR_STEPS_SUCCESS',
  GET_SERVICE_TEAM_LOCATIONS_FAIL: 'GET_SERVICE_TEAM_LOCATIONS_FAIL',
  GET_SERVICE_TEAM_LOCATIONS_START: 'GET_SERVICE_TEAM_LOCATIONS_START',
  GET_SERVICE_TEAM_LOCATIONS_SUCCESS: 'GET_SERVICE_TEAM_LOCATIONS_SUCCESS',
  GET_STATUS_URL_ERROR: 'GET_STATUS_URL_ERROR',
  GET_STATUS_URL_START: 'GET_STATUS_URL_START',
  GET_STATUS_URL_SUCCESS: 'GET_STATUS_URL_SUCCESS',
  GET_TASKS_FAILURE: 'GET_TASKS_FAILURE',
  GET_TASKS_START: 'GET_TASKS_START',
  GET_TASKS_SUCCESS: 'GET_TASKS_SUCCESS',
  GET_TENANT_CONFIG_LABEL_FAIL: 'GET_TENANT_CONFIG_LABEL_FAIL',
  GET_TENANT_CONFIG_LABEL_START: 'GET_TENANT_CONFIG_LABEL_START',
  GET_TENANT_CONFIG_LABEL_SUCCESS: 'GET_TENANT_CONFIG_LABEL_SUCCESS',
  GET_TENANT_CONFIG_MENU_FAIL: 'GET_TENANT_CONFIG_MENU_FAIL',
  GET_TENANT_CONFIG_MENU_START: 'GET_TENANT_CONFIG_MENU_START',
  GET_TENANT_CONFIG_MENU_SUCCESS: 'GET_TENANT_CONFIG_MENU_SUCCESS',
  GET_TENANT_CONFIG_ONE_MENU_SUCCESS: 'GET_TENANT_CONFIG_ONE_MENU_SUCCESS',
  GET_TENANT_DOMAIN_FAIL: 'GET_TENANT_DOMAIN_FAIL',
  GET_TENANT_DOMAIN_START: 'GET_TENANT_DOMAIN_START',
  GET_TENANT_DOMAIN_SUCCESS: 'GET_TENANT_DOMAIN_SUCCESS',
  GET_TENANT_FAIL: 'GET_TENANT_FAIL',
  GET_TENANT_IMAGES_FAIL: 'GET_TENANT_IMAGES_FAIL',
  GET_TENANT_IMAGES_START: 'GET_TENANT_IMAGES_START',
  GET_TENANT_IMAGES_SUCCESS: 'GET_TENANT_IMAGES_SUCCESS',
  GET_TENANT_ONE_IMAGE_FAIL: 'GET_TENANT_ONE_IMAGE_FAIL',
  GET_TENANT_ONE_IMAGE_START: 'GET_TENANT_ONE_IMAGE_START',
  GET_TENANT_ONE_IMAGE_SUCCESS: 'GET_TENANT_ONE_IMAGE_SUCCESS',
  GET_TENANT_START: 'GET_TENANT_START',
  GET_TENANT_SUB_PRODUCTION_CONFIGURATION_FAIL:
    'GET_TENANT_SUB_PRODUCTION_CONFIGURATION_FAIL',
  GET_TENANT_SUB_PRODUCTION_CONFIGURATION_START:
    'GET_TENANT_SUB_PRODUCTION_CONFIGURATION_START',
  GET_TENANT_SUB_PRODUCTION_CONFIGURATION_SUCCESS:
    'GET_TENANT_SUB_PRODUCTION_CONFIGURATION_SUCCESS',
  GET_TENANT_SUCCESS: 'GET_TENANT_SUCCESS',
  GET_TEST_EMAIL_ENABLED_SUCCESS: 'GET_TEST_EMAIL_ENABLED_SUCCESS',
  GET_TEST_EMAIL_FAIL: 'GET_TEST_EMAIL_FAIL',
  GET_TEST_EMAIL_START: 'GET_TEST_EMAIL_START',
  GET_TEST_EMAIL_SUCCESS: 'GET_TEST_EMAIL_SUCCESS',
  GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_FAIL:
    'GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_FAIL',
  GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_START:
    'GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_START',
  GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_SUCCESS:
    'GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_SUCCESS',
  GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_START:
    'GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_START',
  GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_SUCCESS:
    'GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_SUCCESS',
  GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_FAIL:
    'GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_FAIL',
  GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_START:
    'GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_START',
  GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_SUCCESS:
    'GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_SUCCESS',
  GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_FAIL:
    'GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_FAIL',
  GET_USER_DEPARTMENT_ID_FAIL: 'GET_USER_DEPARTMENT_ID_FAIL',
  GET_USER_DEPARTMENT_ID_START: 'GET_USER_DEPARTMENT_ID_START',
  GET_USER_DEPARTMENT_ID_SUCCESS: 'GET_USER_DEPARTMENT_ID_SUCCESS',
  // End of streamline process 2
  HIERARCHY_SELECT_CACHE_CHILDREN_NODES:
    'HIERARCHY_SELECT_CACHE_CHILDREN_NODES',
  HIERARCHY_SELECT_CACHE_DEFAULT_SELECTED_NODE:
    'HIERARCHY_SELECT_CACHE_DEFAULT_SELECTED_NODE',
  HIERARCHY_SELECT_CACHE_ROOT_NODES: 'HIERARCHY_SELECT_CACHE_ROOT_NODES',
  HOME_FILTER_UPDATE: 'HOME_FILTER_UPDATE',
  I18N_LOAD_SUPPORTED_LANGUAGES_FAIL: 'I18N_LOAD_SUPPORTED_LANGUAGES_FAIL',
  I18N_LOAD_SUPPORTED_LANGUAGES_START: 'I18N_LOAD_SUPPORTED_LANGUAGES_START',
  I18N_LOAD_SUPPORTED_LANGUAGES_SUCCESS:
    'I18N_LOAD_SUPPORTED_LANGUAGES_SUCCESS',
  I18N_SET_LOCALE: 'I18N_SET_LOCALE',
  IMPERSONATING_SAVE_ORIGINAL_USER: 'IMPERSONATING_SAVE_ORIGINAL_USER',
  IMPERSONATING_SET: 'IMPERSONATING_SET',
  IMPERSONATING_START: 'IMPERSONATING_START',
  INTEGR_TEAMS_FETCH_TOKEN_START: 'INTEGR_TEAMS_FETCH_TOKEN_START',
  INTEGR_TEAMS_FETCH_TOKEN_SUCCESS: 'INTEGR_TEAMS_FETCH_TOKEN_SUCCESS',
  INTEGR_TEAMS_REMOVE_TOKEN_SUCCESS: 'INTEGR_TEAMS_REMOVE_TOKEN_SUCCESS',
  IS_SEARCHING_START: 'IS_SEARCHING_START',
  IS_SEARCHING_TEAM_SERVICE_DEPARTMENT: 'IS_SEARCHING_TEAM_SERVICE_DEPARTMENT',
  IS_SEARCHING_USER_START: 'IS_SEARCHING_USER_START',
  LOAD_ALLOWED_EXTENSION_FAIL: 'LOAD_ALLOWED_EXTENSION_FAIL',
  LOAD_ALLOWED_EXTENSION_START: 'LOAD_ALLOWED_EXTENSION_START',
  LOAD_ALLOWED_EXTENSION_SUCCESS: 'LOAD_ALLOWED_EXTENSION_SUCCESS',
  LOAD_ALL_SUBCATEGORIES_FAIL: 'LOAD_ALL_SUBCATEGORIES_FAIL',
  LOAD_ALL_SUBCATEGORIES_START: 'LOAD_ALL_SUBCATEGORIES_START',
  LOAD_ALL_SUBCATEGORIES_SUCCESS: 'LOAD_ALL_SUBCATEGORIES_SUCCESS',
  LOAD_ANNOUNCEMENTS_FAIL: 'LOAD_ANNOUNCEMENTS_FAIL',
  LOAD_ANNOUNCEMENTS_START: 'LOAD_ANNOUNCEMENTS_START',
  LOAD_ANNOUNCEMENTS_SUCCESS: 'LOAD_ANNOUNCEMENTS_SUCCESS',
  LOAD_ASSETS_FAIL: 'LOAD_ASSETS_FAIL',
  LOAD_ASSETS_START: 'LOAD_ASSETS_START',
  LOAD_ASSETS_SUCCESS: 'LOAD_ASSETS_SUCCESS',
  LOAD_BARISTA_APPLICATIONS_FAILURE: 'LOAD_BARISTA_APPLICATIONS_FAILURE',
  LOAD_BARISTA_APPLICATIONS_SUCCESS: 'LOAD_BARISTA_APPLICATIONS_SUCCESS',
  LOAD_BARISTA_APPLICATIONS_WORKING: 'LOAD_BARISTA_APPLICATIONS_WORKING',
  LOAD_BARISTA_SEARCH_CONDITIONS_ERROR: 'LOAD_BARISTA_SEARCH_CONDITIONS_ERROR',
  LOAD_BARISTA_SEARCH_CONDITIONS_START: 'LOAD_BARISTA_SEARCH_CONDITIONS_START',
  LOAD_BARISTA_SEARCH_CONDITIONS_SUCCESS:
    'LOAD_BARISTA_SEARCH_CONDITIONS_SUCCESS',
  LOAD_BUNDLES_FAIL: 'LOAD_BUNDLES_FAIL',
  LOAD_BUNDLES_START: 'LOAD_BUNDLES_START',
  LOAD_BUNDLES_SUCCESS: 'LOAD_BUNDLES_SUCCESS',
  LOAD_CARDS: 'LOAD_CARDS',
  LOAD_CARDS_FAIL: 'LOAD_CARDS_FAIL',
  LOAD_CARDS_SUCCESS: 'LOAD_CARDS_SUCCESS',
  LOAD_CARD_BY_ID_FAIL: 'LOAD_CARD_BY_ID_FAIL',
  LOAD_CARD_BY_ID_START: 'LOAD_CARD_BY_ID_START',
  LOAD_CARD_BY_ID_SUCCESS: 'LOAD_CARD_BY_ID_SUCCESS',
  LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_FAIL:
    'LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_FAIL',
  LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_START:
    'LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_START',
  LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_SUCCESS:
    'LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_SUCCESS',
  LOAD_CART: 'LOAD_CARTG',
  LOAD_CART_FAIL: 'LOAD_CART_FAIL',
  LOAD_CART_ISLOADING: 'LOAD_CART_ISLOADING',
  LOAD_CART_ISLOADING_END: 'LOAD_CART_ISLOADING_END',
  LOAD_CART_START: 'LOAD_CART_START',
  LOAD_CART_SUCCESS: 'LOAD_CART_SUCCESS',
  LOAD_CART_USER_SUCCESS: 'LOAD_CART_USER_SUCCESS',
  LOAD_CART_WF_START: 'LOAD_CART_WF_START',
  LOAD_CART_WF_STOP: 'LOAD_CART_WF_STOP',
  LOAD_CASE_CATEGORIES_FAIL: 'LOAD_CASE_CATEGORIES_FAIL',
  LOAD_CASE_CATEGORIES_START: 'LOAD_CASE_CATEGORIES_START',
  LOAD_CASE_CATEGORIES_SUCCESS: 'LOAD_CASE_CATEGORIES_SUCCESS',
  LOAD_CASE_LOCATION_FAIL: 'LOAD_CASE_LOCATION_FAIL',
  LOAD_CASE_LOCATION_START: 'LOAD_CASE_LOCATION_START',
  LOAD_CASE_LOCATION_SUCCESS: 'LOAD_CASE_LOCATION_SUCCESS',
  LOAD_CASE_SERVICE_DEPARTMENT_FAIL: 'LOAD_CASE_SERVICE_DEPARTMENT_FAIL',
  LOAD_CASE_SERVICE_DEPARTMENT_START: 'LOAD_CASE_SERVICE_DEPARTMENT_START',
  LOAD_CASE_SERVICE_DEPARTMENT_SUCCESS: 'LOAD_CASE_SERVICE_DEPARTMENT_SUCCESS',
  LOAD_CATEGORIES_FAIL: 'LOAD_CATEGORIES_FAIL',
  LOAD_CATEGORIES_START: 'LOAD_CATEGORIES_START',
  LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_SUCCESS',
  LOAD_CHANNEL_FAIL: 'LOAD_CHANNEL_FAIL',
  LOAD_CHANNEL_START: 'LOAD_CHANNEL_START',
  LOAD_CHANNEL_SUCCESS: 'LOAD_CHANNEL_SUCCESS',
  LOAD_CHILD_BUNDLES_SUCCESS: 'LOAD_CHILD_BUNDLES_SUCCESS',
  LOAD_CONDITION_TASK_FAIL: 'LOAD_CONDITION_TASK_FAIL',
  LOAD_CONDITION_TASK_START: 'LOAD_CONDITION_TASK_START',
  LOAD_CONDITION_TASK_SUCCESS: 'LOAD_CONDITION_TASK_SUCCESS',
  LOAD_CONFLUENCE_CONTENT_SYNC_FAILURE: 'LOAD_CONFLUENCE_CONTENT_SYNC_FAILURE',
  LOAD_CONFLUENCE_CONTENT_SYNC_START: 'LOAD_CONFLUENCE_CONTENT_SYNC_START',
  LOAD_CONFLUENCE_CONTENT_SYNC_SUCCESS: 'LOAD_CONFLUENCE_CONTENT_SYNC_SUCCESS',
  LOAD_CONFLUENCE_LAST_SYNC_FAILURE: 'LOAD_CONFLUENCE_LAST_SYNC_FAILURE',
  LOAD_CONFLUENCE_LAST_SYNC_START: 'LOAD_CONFLUENCE_LAST_SYNC_START',
  LOAD_CONFLUENCE_LAST_SYNC_SUCCESS: 'LOAD_CONFLUENCE_LAST_SYNC_SUCCESS',
  LOAD_CSV_CASES_REPORT_FAIL: 'LOAD_CSV_CASES_REPORT_FAIL',
  LOAD_CSV_CASES_REPORT_START: 'LOAD_CSV_CASES_REPORT_START',
  LOAD_CSV_CASES_REPORT_SUCCESS: 'LOAD_CSV_CASES_REPORT_SUCCESS',
  LOAD_CSV_IMPORT_HISTORY_FAILURE: 'LOAD_CSV_IMPORT_HISTORY_FAILURE',
  LOAD_CSV_IMPORT_HISTORY_START: 'LOAD_CSV_IMPORT_HISTORY_START',
  LOAD_CSV_IMPORT_HISTORY_SUCCESS: 'LOAD_CSV_IMPORT_HISTORY_SUCCESS',
  LOAD_DEPARTMENT_CLASSIFICATION_FAIL: 'LOAD_DEPARTMENT_CLASSIFICATION_FAIL',
  LOAD_DEPARTMENT_CLASSIFICATION_START: 'LOAD_DEPARTMENT_CLASSIFICATION_START',
  LOAD_DEPARTMENT_CLASSIFICATION_SUCCESS:
    'LOAD_DEPARTMENT_CLASSIFICATION_SUCCESS',
  LOAD_FAQ_LIST: 'LOAD_FAQ_LIST',
  LOAD_FAQ_LIST_FAILURE: 'LOAD_FAQ_LIST_FAILURE',
  LOAD_FAQ_LIST_SUCCESS: 'LOAD_FAQ_LIST_SUCCESS',
  LOAD_FAQ_MATCH_FAIL: 'LOAD_FAQ_MATCH_FAIL',
  LOAD_FAQ_MATCH_START: 'LOAD_FAQ_MATCH_START',
  LOAD_FAQ_MATCH_SUCCESS: 'LOAD_FAQ_MATCH_SUCCESS',
  LOAD_FUL_ANSWERS_SUCCESS: 'LOAD_FUL_ANSWERS_SUCCESS',
  LOAD_FUL_OPTS_FAIL: 'LOAD_FUL_OPTS_FAIL',
  LOAD_FUL_OPTS_START: 'LOAD_FUL_OPTS_START',
  LOAD_FUL_OPTS_SUCCESS: 'LOAD_FUL_OPTS_SUCCESS',
  LOAD_GENERIC_TABLE_FAIL: 'LOAD_GENERIC_TABLE_FAIL',
  LOAD_GENERIC_TABLE_START: 'LOAD_GENERIC_TABLE_START',
  LOAD_GENERIC_TABLE_SUCCESS: 'LOAD_GENERIC_TABLE_SUCCESS',
  LOAD_INTERACTIONS_FIELD_OPTIONS_ERROR:
    'LOAD_INTERACTIONS_FIELD_OPTIONS_ERROR',
  LOAD_INTERACTIONS_FIELD_OPTIONS_START:
    'LOAD_INTERACTIONS_FIELD_OPTIONS_START',
  LOAD_INTERACTIONS_FIELD_OPTIONS_SUCCESS:
    'LOAD_INTERACTIONS_FIELD_OPTIONS_SUCCESS',
  LOAD_MESSAGES: 'LOAD_MESSAGES',
  LOAD_MESSAGES_FAIL: 'LOAD_MESSAGES_FAIL',
  LOAD_MESSAGES_SUCCESS: 'LOAD_MESSAGES_SUCCESS',
  LOAD_NO_CART_SUCCESS: 'LOAD_NO_CART_SUCCESS',
  LOAD_OBJECT_MAPPING_FAIL: 'LOAD_OBJECT_MAPPING_FAIL',
  LOAD_OBJECT_MAPPING_SUCCESS: 'LOAD_OBJECT_MAPPING_SUCCESS',
  LOAD_ONBOARD_ONE_SCHEDULE_SUCCESS: 'LOAD_ONBOARD_ONE_SCHEDULE_SUCCESS',
  LOAD_ONBOARD_PERFORMANCE_DETAIL_FAIL: 'LOAD_ONBOARD_PERFORMANCE_DETAIL_FAIL',
  LOAD_ONBOARD_PERFORMANCE_DETAIL_START:
    'LOAD_ONBOARD_PERFORMANCE_DETAIL_START',
  LOAD_ONBOARD_PERFORMANCE_DETAIL_SUCCESS:
    'LOAD_ONBOARD_PERFORMANCE_DETAIL_SUCCESS',
  LOAD_ONBOARD_PERFORMANCE_LIST_FAIL: 'LOAD_ONBOARD_PERFORMANCE_LIST_FAIL',
  LOAD_ONBOARD_PERFORMANCE_LIST_START: 'LOAD_ONBOARD_PERFORMANCE_LIST_START',
  LOAD_ONBOARD_PERFORMANCE_LIST_SUCCESS:
    'LOAD_ONBOARD_PERFORMANCE_LIST_SUCCESS',
  LOAD_ONBOARD_SCHEDULE_END: 'LOAD_ONBOARD_SCHEDULE_END',
  LOAD_ONBOARD_SCHEDULE_LIST_FAIL: 'LOAD_ONBOARD_SCHEDULE_LIST_FAIL',
  LOAD_ONBOARD_SCHEDULE_LIST_START: 'LOAD_ONBOARD_SCHEDULE_LIST_START',
  LOAD_ONBOARD_SCHEDULE_LIST_SUCCESS: 'LOAD_ONBOARD_SCHEDULE_LIST_SUCCESS',
  LOAD_ONBOARD_SCHEDULE_START: 'LOAD_ONBOARD_SCHEDULE_START',
  LOAD_ONBOARD_TEMPLATE_LIST_FAIL: 'LOAD_ONBOARD_TEMPLATE_LIST_FAIL',
  LOAD_ONBOARD_TEMPLATE_LIST_START: 'LOAD_ONBOARD_TEMPLATE_LIST_START',
  LOAD_ONBOARD_TEMPLATE_LIST_SUCCESS: 'LOAD_ONBOARD_TEMPLATE_LIST_SUCCESS',
  LOAD_ONE_ASSET_FAIL: 'LOAD_ONE_ASSET_FAIL',
  LOAD_ONE_ASSET_START: 'LOAD_ONE_ASSET_START',
  LOAD_ONE_ASSET_SUCCESS: 'LOAD_ONE_ASSET_SUCCESS',
  LOAD_ONE_PRODUCT_FAIL: 'LOAD_ONE_PRODUCT_FAIL',
  LOAD_ONE_PRODUCT_RESET: 'LOAD_ONE_PRODUCT_RESET',
  LOAD_ONE_PRODUCT_START: 'LOAD_ONE_PRODUCT_START',
  LOAD_ONE_PRODUCT_SUCCESS: 'LOAD_ONE_PRODUCT_SUCCESS',
  LOAD_PFAMILY_FAIL: 'LOAD_PFAMILY_FAIL',
  LOAD_PFAMILY_START: 'LOAD_PFAMILY_START',
  LOAD_PFAMILY_SUCCESS: 'LOAD_PFAMILY_SUCCESS',
  LOAD_PRODUCTS_FAIL: 'LOAD_PRODUCTS_FAIL',
  LOAD_PRODUCTS_START: 'LOAD_PRODUCTS_START',
  LOAD_PRODUCTS_SUCCESS: 'LOAD_PRODUCTS_SUCCESS',
  LOAD_SERVICE_DEPARTMENT_FAIL: 'LOAD_SERVICE_DEPARTMENT_FAIL',
  LOAD_SERVICE_DEPARTMENT_ROUTE_FAIL: 'LOAD_SERVICE_DEPARTMENT_ROUTE_FAIL',
  LOAD_SERVICE_DEPARTMENT_ROUTE_START: 'LOAD_SERVICE_DEPARTMENT_ROUTE_START',
  LOAD_SERVICE_DEPARTMENT_ROUTE_SUCCESS:
    'LOAD_SERVICE_DEPARTMENT_ROUTE_SUCCESS',
  LOAD_SERVICE_DEPARTMENT_START: 'LOAD_SERVICE_DEPARTMENT_START',
  LOAD_SERVICE_DEPARTMENT_SUCCESS: 'LOAD_SERVICE_DEPARTMENT_SUCCESS',
  LOAD_SERVICE_TEAM_SUBSTATES_FAIL: 'LOAD_SERVICE_TEAM_SUBSTATES_FAIL',
  LOAD_SERVICE_TEAM_SUBSTATES_START: 'LOAD_SERVICE_TEAM_SUBSTATES_START',
  LOAD_SERVICE_TEAM_SUBSTATES_SUCCESS: 'LOAD_SERVICE_TEAM_SUBSTATES_SUCCESS',
  LOAD_SNOW_CATEGORIES_START: 'LOAD_SNOW_CATEGORIES_START',
  LOAD_SNOW_CATEGORIES_SUCCESS: 'LOAD_SNOW_CATEGORIES_SUCCESS',
  LOAD_SNOW_CONNECTION_STATUS_FAIL: 'LOAD_SNOW_CONNECTION_STATUS_FAIL',
  LOAD_SNOW_CONNECTION_STATUS_START: 'LOAD_SNOW_CONNECTION_STATUS_START',
  LOAD_SNOW_CONNECTION_STATUS_SUCCESS: 'LOAD_SNOW_CONNECTION_STATUS_SUCCESS',
  LOAD_SNOW_CREDENTIALS_FAIL: 'LOAD_SNOW_CREDENTIALS_FAIL',
  LOAD_SNOW_CREDENTIALS_START: 'LOAD_SNOW_CREDENTIALS_START',
  LOAD_SNOW_CREDENTIALS_SUCESS: 'LOAD_SNOW_CREDENTIALS_SUCESS',
  LOAD_SNOW_PRODUCTS_SUCCESS: 'LOAD_SNOW_PRODUCTS_SUCCESS',
  LOAD_SNOW_WORKFLOW_MAP_START: 'LOAD_SNOW_WORKFLOW_MAP_START',
  LOAD_SNOW_WORKFLOW_MAP_SUCCESS: 'LOAD_SNOW_WORKFLOW_MAP_SUCCESS',
  LOAD_START_SEARCH_BUNDLES: 'LOAD_START_SEARCH_BUNDLES',
  LOAD_START_SEARCH_ROLE_BUNDLES: 'LOAD_START_SEARCH_ROLE_BUNDLES',
  LOAD_SUBCATEGORIES_FAIL: 'LOAD_SUBCATEGORIES_FAIL',
  LOAD_SUBCATEGORIES_START: 'LOAD_SUBCATEGORIES_START',
  LOAD_SUBCATEGORIES_SUCCESS: 'LOAD_SUBCATEGORIES_SUCCESS',
  LOAD_SUBSTATES_FAIL: 'LOAD_SUBSTATES_FAIL',
  LOAD_SUBSTATES_START: 'LOAD_SUBSTATES_START',
  LOAD_SUBSTATES_SUCCESS: 'LOAD_SUBSTATES_SUCCESS',
  LOAD_SURVEY_TYPES_FAIL: 'LOAD_SURVEY_TYPES_FAIL',
  LOAD_SURVEY_TYPES_START: 'LOAD_SURVEY_TYPES_START',
  LOAD_SURVEY_TYPES_SUCCESS: 'LOAD_SURVEY_TYPES_SUCCESS',
  LOAD_TASK_CART_FAIL: 'LOAD_TASK_CART_FAIL',
  LOAD_TASK_CART_ITEMS_FAIL: 'LOAD_TASK_CART_ITEMS_FAIL',
  LOAD_TASK_CART_ITEMS_START: 'LOAD_TASK_CART_ITEMS_START',
  LOAD_TASK_CART_ITEMS_SUCCESS: 'LOAD_TASK_CART_ITEMS_SUCCESS',
  LOAD_TASK_CART_START: 'LOAD_TASK_CART_START',
  LOAD_TASK_CART_SUCCESS: 'LOAD_TASK_CART_SUCCESS',
  LOAD_TASK_CATEGORIES_FAIL: 'LOAD_TASK_CATEGORIES_FAIL',
  LOAD_TASK_CATEGORIES_START: 'LOAD_TASK_CATEGORIES_START',
  LOAD_TASK_CATEGORIES_SUCCESS: 'LOAD_TASK_CATEGORIES_SUCCESS',
  LOAD_TASK_CLASSFICATIONS: 'LOAD_TASK_CLASSFICATIONS',
  LOAD_TASK_STATUSES_FAIL: 'LOAD_TASK_STATUSES_FAIL',
  LOAD_TASK_STATUSES_START: 'LOAD_TASK_STATUSES_START',
  LOAD_TASK_STATUSES_SUCCESS: 'LOAD_TASK_STATUSES_SUCCESS',
  LOAD_TASK_TYPES_FAIL: 'LOAD_TASK_TYPES_FAIL',
  LOAD_TASK_TYPES_START: 'LOAD_TASK_TYPES_START',
  LOAD_TASK_TYPES_SUCCESS: 'LOAD_TASK_TYPES_SUCCESS',
  LOAD_TEAMS_LIST_FAIL: 'LOAD_TEAMS_LIST_FAIL',
  LOAD_TEAMS_LIST_START: 'LOAD_TEAMS_LIST_START',
  LOAD_TEAMS_LIST_SUCCESS: 'LOAD_TEAMS_LIST_SUCCESS',
  LOAD_TEAM_CLASSIFICATION_DEPARTMENT: 'LOAD_TEAM_CLASSIFICATION_DEPARTMENT',
  LOAD_TEAM_MEMBER_FAIL: 'LOAD_TEAM_MEMBER_FAIL',
  LOAD_TEAM_MEMBER_START: 'LOAD_TEAM_MEMBER_START',
  LOAD_TEAM_MEMBER_SUCCESS: 'LOAD_TEAM_MEMBER_SUCCESS',
  LOAD_TEAM_ROLE: 'LOAD_TEAM_ROLE',
  LOAD_TEAM_SERVICE_DEPARTMENT_FAIL: 'LOAD_TEAM_SERVICE_DEPARTMENT_FAIL',
  LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_ERROR:
    'LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_ERROR',
  LOAD_TEAM_SERVICE_DEPARTMENT_START: 'LOAD_TEAM_SERVICE_DEPARTMENT_START',
  LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_START:
    'LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_START',
  LOAD_TEAM_SERVICE_DEPARTMENT_SUCCESS: 'LOAD_TEAM_SERVICE_DEPARTMENT_SUCCESS',
  LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_SUCCESS:
    'LOADING_BUNDLE_JOB_ROLE_PERMISSIONS_SUCCESS',
  LOAD_TEMPLATE_INTERVAL_SELECTED: 'LOAD_TEMPLATE_INTERVAL_SELECTED',
  LOAD_TOP_CANDIDATE_MATCHES_FAQS_FAIL: 'LOAD_TOP_CANDIDATE_MATCHES_FAQS_FAIL',
  LOAD_TOP_CANDIDATE_MATCHES_FAQS_START:
    'LOAD_TOP_CANDIDATE_MATCHES_FAQS_START',
  LOAD_TOP_CANDIDATE_MATCHES_FAQS_SUCCESS:
    'LOAD_TOP_CANDIDATE_MATCHES_FAQS_SUCCESS',
  LOAD_USER_ASSIGNMENT_ERROR: 'LOAD_USER_ASSIGNMENT_ERROR',
  LOAD_USER_ASSIGNMENT_START: 'LOAD_USER_ASSIGNMENT_START',
  LOAD_USER_ASSIGNMENT_SUCCESS: 'LOAD_USER_ASSIGNMENT_SUCCESS',
  LOAD_USER_FAIL: 'LOAD_USER_FAIL',
  LOAD_USER_LOCATION_DETAIL_FAIL: 'LOAD_USER_LOCATION_DETAIL_FAIL',
  LOAD_USER_LOCATION_DETAIL_START: 'LOAD_USER_LOCATION_DETAIL_START',
  LOAD_USER_LOCATION_DETAIL_SUCCESS: 'LOAD_USER_LOCATION_DETAIL_SUCCESS',
  LOAD_USER_LOCATION_FAIL: 'LOAD_USER_LOCATION_FAIL',
  LOAD_USER_LOCATION_START: 'LOAD_USER_LOCATION_START',
  LOAD_USER_LOCATION_SUCCESS: 'LOAD_USER_LOCATION_SUCCESS',
  LOAD_USER_OPTIONS_FAIL: 'LOAD_USER_OPTIONS_FAIL',
  LOAD_USER_OPTIONS_SUCCESS: 'LOAD_USER_OPTIONS_SUCCESS',
  LOAD_USER_ROLES_START: 'LOAD_USER_ROLES',
  LOAD_USER_ROLES_FAIL: 'LOAD_USER_ROLES_FAIL',
  LOAD_USER_ROLES_SUCCESS: 'LOAD_USER_ROLES_SUCCESS',
  LOAD_USER_START: 'LOAD_USER_START',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOGOUT_REGISTER_EXTERNAL_HTML: 'LOGOUT_REGISTER_EXTERNAL_HTML',
  META_RESULTS_ADD: 'META_RESULTS_ADD',
  META_RESULTS_CLEAR: 'META_RESULTS_CLEAR',
  META_RESULTS_START: 'META_RESULTS_START',
  MOUNT_EVENT: 'MOUNT_EVENT',
  NATIVE_DESKTOP_VERSION: 'NATIVE_DESKTOP_VERSION',
  NATIVE_MOBILE_VERSION: 'NATIVE_MOBILE_VERSION',
  NEW_ANNOUNCEMENT_CREATED: 'NEW_ANNOUNCEMENT_CREATED',
  NO_MORE_PRODUCTS: 'NO_MORE_PRODUCTS',
  NO_SELECT_DIRECTORY_USER_SUCCESS: 'NO_SELECT_DIRECTORY_USER_SUCCESS',
  NUDGE_CARD_SUCCESS: 'NUDGE_CARD_SUCCESS',
  NUDGE_REQUEST_ERROR: 'NUDGE_REQUEST_ERROR',
  NUDGE_REQUEST_START: 'NUDGE_REQUEST_START',
  NUDGE_REQUEST_SUCCESS: 'NUDGE_REQUEST_SUCCESS',
  OKTA_ACCOUNT_FAILURE: 'OKTA_ACCOUNT_FAILURE',
  OKTA_ACCOUNT_SAVE_START: 'OKTA_ACCOUNT_SAVE_START',
  OKTA_ACCOUNT_START: 'OKTA_ACCOUNT_START',
  OKTA_ACCOUNT_SUCCESS: 'OKTA_ACCOUNT_SUCCESS',
  ONBOARD_PROGRESS_MANAGER_VIEW_SELECT_USER:
    'ONBOARD_PROGRESS_MANAGER_VIEW_SELECT_USER',
  ONBOARD_PROGRESS_MANAGER_VIEW_SET_FILTER_BY:
    'ONBOARD_PROGRESS_MANAGER_VIEW_SET_FILTER_BY',
  ONBOARD_PROGRESS_MANAGER_VIEW_SET_SORT_BY:
    'ONBOARD_PROGRESS_MANAGER_VIEW_SET_SORT_BY',
  OPEN_ADD_EMPLOYEE_WORKFLOW_FAIL: 'OPEN_ADD_EMPLOYEE_WORKFLOW_FAIL',
  OPEN_ADD_EMPLOYEE_WORKFLOW_START: 'OPEN_ADD_EMPLOYEE_WORKFLOW_START',
  OPEN_ADD_EMPLOYEE_WORKFLOW_SUCCESS: 'OPEN_ADD_EMPLOYEE_WORKFLOW_SUCCESS',
  OPEN_SUPPORT_CHANNEL: 'OPEN_SUPPORT_CHANNEL',
  OTP_ERROR: 'OTP_ERROR',
  OTP_START: 'OTP_START',
  OTP_SUCCESS: 'OTP_SUCCESS',
  POST_ANNOUNC_END: 'POST_ANNOUNC_END',
  POST_ANNOUNC_START: 'POST_ANNOUNC_START',
  POST_CASE_NOTE_FAIL: 'POST_CASE_NOTE_FAIL',
  POST_CASE_NOTE_START: 'POST_CASE_NOTE_START',
  POST_CASE_NOTE_SUCCESS: 'POST_CASE_NOTE_SUCCESS',
  PROMPT_RESET_PASSWORD: 'PROMPT_RESET_PASSWORD',
  PROMPT_RESET_PASSWORD_DISMISS: 'PROMPT_RESET_PASSWORD_DISMISS',
  PWA_NOTIFICATION: 'PWA_NOTIFICATION',
  REARRANGE_BLOCKS: 'REARRANGE_BLOCKS',
  REARRANGE_TASKS: 'REARRANGE_TASKS',
  REDUX_FORM_RESET_SYNC_ERROR: '@@redux-form/UPDATE_SYNC_ERRORS',
  REGISTER_USER_TYPING: 'REGISTER_USER_TYPING',
  REINITIALIZE_SUPPORT_CHANNEL: 'REINITIALIZE_SUPPORT_CHANNEL',
  REMOTE_USER_CREATE_FLAG_FAIL: 'REMOTE_USER_CREATE_FLAG_FAIL',
  REMOTE_USER_CREATE_FLAG_START: 'REMOTE_USER_CREATE_FLAG_START',
  REMOTE_USER_CREATE_FLAG_SUCCESS: 'REMOTE_USER_CREATE_FLAG_SUCCESS',
  REMOVE_ASSET_ITEM_FAIL: 'REMOVE_ASSET_ITEM_FAIL',
  REMOVE_ASSET_ITEM_SUCCESS: 'REMOVE_ASSET_ITEM_SUCCESS',
  REMOVE_BLOCK_ATTR_OPTION: 'REMOVE_BLOCK_ATTR_OPTION',
  REMOVE_BLOCK_FROM_TASK: 'REMOVE_BLOCK_FROM_TASK',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  REMOVE_CATALOG_ITEM_START: 'REMOVE_CATALOG_ITEM_START',
  REMOVE_COPY_ATTRIBUTES_PRODUCT: 'REMOVE_COPY_ATTRIBUTES_PRODUCT',
  REMOVE_INTERVAL_ID: 'REMOVE_INTERVAL_ID',
  REMOVE_NEW_FAQ_ANSWER: 'REMOVE_NEW_FAQ_ANSWER',
  REMOVE_PRODUCT_PERMISSION_GROUP_FAIL: 'REMOVE_PRODUCT_PERMISSION_GROUP_FAIL',
  REMOVE_PRODUCT_PERMISSION_GROUP_START:
    'REMOVE_PRODUCT_PERMISSION_GROUP_START',
  REMOVE_PRODUCT_PERMISSION_GROUP_SUCCESS:
    'REMOVE_PRODUCT_PERMISSION_GROUP_SUCCESS',
  REMOVE_SERVICE_TEAM_SUBSTATES_SUCCESS:
    'REMOVE_SERVICE_TEAM_SUBSTATES_SUCCESS',
  REMOVE_TASK_FAILURE: 'REMOVE_TASK_FAILURE',
  REMOVE_TASK_START: 'REMOVE_TASK_START',
  REMOVE_TASK_SUCCESS: 'REMOVE_TASK_SUCCESS',
  REMOVE_TEMPORARY_BUNDLE_ITEM: 'REMOVE_TEMPORARY_BUNDLE_ITEM',
  REMOVE_TENANT_IMAGES_FAIL: 'REMOVE_TENANT_IMAGES_FAIL',
  REMOVE_TENANT_IMAGES_START: 'REMOVE_TENANT_IMAGES_START',
  REMOVE_TENANT_IMAGES_SUCCESS: 'REMOVE_TENANT_IMAGES_SUCCESS',
  REMOVE_TOAST_NOTIFICATION: 'REMOVE_TOAST_NOTIFICATION',
  REMOVE_USER_ENTITY: 'REMOVE_USER_ENTITY',
  REMOVE_USER_ENTITY_START: 'REMOVE_USER_ENTITY_START',
  REPLACE_ALL_TEMPORARY_BUNDLE_ITEM: 'REPLACE_ALL_TEMPORARY_BUNDLE_ITEM',
  REPLACE_TEMPORARY_BUNDLE_ITEM: 'REPLACE_TEMPORARY_BUNDLE_ITEM',
  REPLACE_TEMPORARY_PRODUCT_IMAGE: 'REPLACE_TEMPORARY_PRODUCT_IMAGE',
  REPORTS_CALL_COST_SUCCESS: 'REPORTS_CALL_COST_SUCCESS',
  REPORTS_GET_ID_SUCCESS: 'REPORTS_GET_ID_SUCCESS',
  REPORTS_LOAD_DATA_END: 'REPORTS_LOAD_DATA_END',
  REPORTS_LOAD_DATA_ERROR: 'REPORTS_LOAD_DATA_ERROR',
  REPORTS_LOAD_DATA_START: 'REPORTS_LOAD_DATA_START',
  REPORTS_LOAD_DATA_SUCCESS: 'REPORTS_LOAD_DATA_SUCCESS',
  REPORTS_LOAD_REPORT_ERROR: 'REPORTS_LOAD_REPORT_ERROR',
  REPORTS_LOAD_REPORT_START: 'REPORTS_LOAD_REPORT_START',
  REPORTS_LOAD_REPORT_SUCCESS: 'REPORTS_LOAD_REPORT_SUCCESS',
  REPORTS_LOAD_SUMMARY_DATA_SUCCESS: 'REPORTS_LOAD_SUMMARY_DATA_SUCCESS',
  REPORTS_LOAD_SURVEY: 'REPORTS_LOAD_SURVEY',
  REPORTS_SET_DISPLAY_OPTIONS: 'REPORTS_SET_DISPLAY_OPTIONS',
  REPORTS_SURVEY_LOAD_FAIL: 'REPORTS_SURVEY_LOAD_FAIL',
  REPORTS_SURVEY_LOAD_START: 'REPORTS_SURVEY_LOAD_START',
  REPORTS_SURVEY_LOAD_SUCCESS: 'REPORTS_SURVEY_LOAD_SUCCESS',
  REPORT_WORKFLOW_TASK_PROGRESS: 'REPORT_WORKFLOW_TASK_PROGRESS',
  RESET_ASSET_SUCCESS: 'RESET_ASSET_SUCCESS',
  RESET_BUNDLES_LIST: 'RESET_BUNDLES_LIST',
  RESET_CARDS_STATE: 'RESET_CARDS_STATE',
  RESET_CART_SUCCESS: 'RESET_CART_SUCCESS',
  RESET_COPY_ATTRIBUTES_PRODUCT: 'RESET_COPY_ATTRIBUTES_PRODUCT',
  RESET_CSV_CASES_REPORT: 'RESET_CSV_CASES_REPORT',
  RESET_DEPARTMENT_TEAM_LIST_SEARCH: 'RESET_DEPARTMENT_TEAM_LIST_SEARCH',
  RESET_DIRECTORY_OPTIONS: 'RESET_DIRECTORY_OPTIONS',
  RESET_EMAIL_TEST_CONNECTION: 'RESET_EMAIL_TEST_CONNECTION',
  RESET_ERRORS: 'RESET_ERRORS',
  RESET_ONBOARD_PROGRESS_MANAGER_VIEW_OPTIONS:
    'RESET_ONBOARD_PROGRESS_MANAGER_VIEW_OPTIONS',
  RESET_PRODUCTS_SUCCESS: 'RESET_PRODUCTS_SUCCESS',
  RESET_PRODUCT_PERMISSION_GROUPS: 'RESET_PRODUCT_PERMISSION_GROUPS',
  RESET_REPORTS_DATA: 'RESET_REPORTS_DATA',
  RESET_REPORT_DATA: 'RESET_REPORT_DATA',
  RESET_SEARCH_QUERY: 'RESET_SEARCH_QUERY',
  RESET_SELECTED_ITEMS: 'RESET_SELECTED_ITEMS',
  RESET_TASKS: 'RESET_TASKS',
  RESET_TEMPLATE_INTERVAL_SELECTED: 'RESET_TEMPLATE_INTERVAL_SELECTED',
  RESET_USER_LIST: 'RESET_USER_LIST',
  RESUME_WORKFLOW_FAIL: 'RESUME_WORKFLOW_FAIL',
  RESUME_WORKFLOW_SUCCESS: 'RESUME_WORKFLOW_SUCCESS',
  SAVE_ANNOUNC_STATUS_SUCCESS: 'SAVE_ANNOUNC_STATUS_SUCCESS',
  SAVE_BUNDLE_ITEMS: 'SAVE_BUNDLE_ITEMS',
  SAVE_FUL_ANSWERS_FAIL: 'SAVE_FUL_ANSWERS_FAIL',
  SAVE_FUL_ANSWERS_START: 'SAVE_FUL_ANSWERS_START',
  SAVE_FUL_ANSWERS_SUCCESS: 'SAVE_FUL_ANSWERS_SUCCESS',
  SAVE_ONBOARD_SCHEDULE_FAIL: 'SAVE_ONBOARD_SCHEDULE_FAIL',
  SAVE_ONBOARD_SCHEDULE_START: 'SAVE_ONBOARD_SCHEDULE_START',
  SAVE_ONBOARD_SCHEDULE_SUCCESS: 'SAVE_ONBOARD_SCHEDULE_SUCCESS',
  SAVE_PRODUCT_PERMISSION_GROUP_FAIL: 'SAVE_PRODUCT_PERMISSION_GROUP_FAIL',
  SAVE_PRODUCT_PERMISSION_GROUP_START: 'SAVE_PRODUCT_PERMISSION_GROUP_START',
  SAVE_PRODUCT_PERMISSION_GROUP_SUCCESS:
    'SAVE_PRODUCT_PERMISSION_GROUP_SUCCESS',
  SAVE_SCHEDULE_STATUS_SUCCESS: 'SAVE_SCHEDULE_STATUS_SUCCESS',
  SAVE_SEARCH_QUERY: 'SAVE_SEARCH_QUERY',
  SAVE_SNOW_WORKFLOW_MAP_SUCCESS: 'SAVE_SNOW_WORKFLOW_MAP_SUCCESS',
  SAVE_TASKS_ORDER: 'SAVE_TASKS_ORDER',
  SAVE_TASK_FAILURE: 'SAVE_TASK_FAILURE',
  SAVE_TASK_IN_PROGRESS: 'SAVE_TASK_IN_PROGRESS',
  SAVE_TASK_NAME: 'SAVE_TASK_NAME',
  SAVE_TASK_SUCCESS: 'SAVE_TASK_SUCCESS',
  SAVE_TEMPORARY_BUNDLE_IMAGE: 'SAVE_TEMPORARY_BUNDLE_IMAGE',
  SAVE_TEMPORARY_PRODUCT_IMAGE: 'SAVE_TEMPORARY_PRODUCT_IMAGE',
  SCHEDULE_ADDED_GROUP_SUCCESS: 'SCHEDULE_ADDED_GROUP_SUCCESS',
  SCHEDULE_DELETED_GROUP_SUCCESS: 'SCHEDULE_DELETED_GROUP_SUCCESS',
  SCHEDULE_SAVE_GROUP_SUCCESS: 'SCHEDULE_SAVE_GROUP_SUCCESS',
  SCHEDULE_UPDATE_GROUP_FAIL: 'SCHEDULE_UPDATE_GROUP_FAIL',
  SCHEDULE_UPDATE_GROUP_START: 'SCHEDULE_UPDATE_GROUP_START',
  SEARCH_FAIL: 'SEARCH_FAIL',
  SEARCH_START: 'SEARCH_START',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SELECT_CURRENT_EDITING_TASK: 'SELECT_CURRENT_EDITING_TASK',
  SELECT_DIRECTORY_USER_FAILURE: 'SELECT_DIRECTORY_USER_FAILURE',
  SELECT_DIRECTORY_USER_START: 'SELECT_DIRECTORY_USER_START',
  SELECT_DIRECTORY_USER_SUCCESS: 'SELECT_DIRECTORY_USER_SUCCESS',
  SELECT_EMAIL_DOMAIN_FAIL: 'SELECT_EMAIL_DOMAIN_FAIL',
  SELECT_EMAIL_DOMAIN_START: 'SELECT_EMAIL_DOMAIN_START',
  SELECT_EMAIL_DOMAIN_SUCCESS: 'SELECT_EMAIL_DOMAIN_SUCCESS',
  SELECT_HARDWARE_BUNDLE_PRODUCT: 'SELECT_HARDWARE_BUNDLE_PRODUCT',
  SELECT_MY_GEAR_ADD_PRODUCT: 'MY_GEAR_ADD_PRODUCT',
  SELECT_MY_GEAR_ANSWER_FULFILLMENT_QUESTIONS:
    'SELECT_MY_GEAR_ANSWER_FULFILLMENT_QUESTIONS',
  SELECT_MY_GEAR_MOVE_FIRST_STEP: 'SELECT_MY_GEAR_MOVE_FIRST_STEP',
  SELECT_MY_GEAR_MOVE_NEXT_STEP: 'SELECT_MY_GEAR_MOVE_NEXT_STEP',
  SELECT_MY_GEAR_MOVE_PREV_STEP: 'SELECT_MY_GEAR_MOVE_PREV_STEP',
  SELECT_MY_GEAR_PRE_ADD_PRODUCT: 'SELECT_MY_GEAR_PRE_ADD_PRODUCT',
  SELECT_MY_GEAR_REMOVE_PRODUCT: 'MY_GEAR_REMOVE_PRODUCT',
  SELECT_MY_GEAR_RESET_PROGRESS: 'SELECT_MY_GEAR_RESET_PROGRESS',
  SELECT_MY_GEAR_RESUME_PROGRESS: 'SELECT_MY_GEAR_RESUME_PROGRESS',
  SELECT_SERVICE_DEPARTMENT_TYPE: 'SELECT_SERVICE_DEPARTMENT_TYPE',
  SELECT_APPROVAL_SERVICE_TEAM: 'SELECT_APPROVAL_SERVICE_TEAM',
  SELECT_SERVICE_TEAM: 'SELECT_SERVICE_TEAM',
  SELECT_SUPPORT_CHANNEL: 'SELECT_SUPPORT_CHANNEL',
  SEND_FAQ_REPORT_FAIL: 'SEND_FAQ_REPORT_FAIL',
  SEND_FAQ_REPORT_START: 'SEND_FAQ_REPORT_START',
  SEND_FAQ_REPORT_SUCCESS: 'SEND_FAQ_REPORT_SUCCESS',
  SEND_INVITES_FAILURE: 'SEND_INVITES_FAILURE',
  SEND_INVITES_START: 'SEND_INVITES_START',
  SEND_INVITES_SUCCESS: 'SEND_INVITES_SUCCESS',
  SERVICE_DEPARTMENT_RELOADED: 'SERVICE_DEPARTMENT_RELOADED',
  SET_CURRENT_CASE_SERVICE_DEPARTMENT_SUCCESS:
    'SET_CURRENT_CASE_SERVICE_DEPARTMENT_SUCCESS',

  SET_ACTIVE_HARDWARE_BUNDLE_PRODUCT_IMAGE_IDX:
    'SET_ACTIVE_HARDWARE_BUNDLE_PRODUCT_IMAGE_IDX',
  SET_ACTIVE_PRODUCT: 'SET_ACTIVE_PRODUCT',
  SET_ADMIN_LAST_PAGE_VISITED: 'SET_ADMIN_LAST_PAGE_VISITED',
  SET_ADMIN_SEARCH_TERM: 'SET_ADMIN_SEARCH_TERM',
  SET_ALPHABET_LETTER: 'SET_ALPHABET_LETTER',
  SET_APPROVAL_INFO: 'SET_APPROVAL_INFO',
  SET_APPROVAL_TASK_QUESTIONS_FAIL: 'SET_APPROVAL_TASK_QUESTIONS_FAIL',
  SET_APPROVAL_TASK_QUESTIONS_START: 'SET_APPROVAL_TASK_QUESTIONS_START',
  SET_APPROVAL_TASK_QUESTIONS_SUCCESS: 'SET_APPROVAL_TASK_QUESTIONS_SUCCESS',
  SET_ARE_FEEDS_READ: 'SET_ARE_FEEDS_READ',
  SET_BUNDLE_IMAGES_DONE: 'SET_BUNDLE_IMAGES_DONE',
  SET_BUNDLE_IMAGES_ISLOADING: 'SET_BUNDLE_IMAGES_ISLOADING',
  SET_BUNDLE_JOB_ROLE_PERMISSIONS: 'SET_BUNDLE_JOB_ROLE_PERMISSIONS',
  SET_CATALOG_ACTIVE_RPODUCT_GALLERY_IMAGE:
    'SET_CATALOG_ACTIVE_RPODUCT_GALLERY_IMAGE',
  SET_CATALOG_SEARCH_PRODUCTS_TERM: 'SET_CATALOG_SEARCH_PRODUCTS_TERM',
  SET_CATALOG_SELECTED_CATEGORY: 'SET_CATALOG_SELECTED_CATEGORY',
  SET_CATALOG_SELECTED_SUBCATEGORY: 'SET_CATALOG_SELECTED_SUBCATEGORY',
  SET_CHANNEL_TO_USER: 'SET_CHANNEL_TO_USER',
  SET_CHILDREN_TASKS: 'SET_CHILDREN_TASKS',
  SET_CONFIG_DEFAULT_SERVICE_TEAM_ERROR:
    'SET_CONFIG_DEFAULT_SERVICE_TEAM_ERROR',
  SET_CONFIG_DEFAULT_SERVICE_TEAM_START:
    'SET_CONFIG_DEFAULT_SERVICE_TEAM_START',
  SET_CONFIG_DEFAULT_SERVICE_TEAM_SUCCESS:
    'SET_CONFIG_DEFAULT_SERVICE_TEAM_SUCCESS',
  SET_CONFLUENCE_CREDENTIALS_FAIL: 'SET_CONFLUENCE_CREDENTIALS_FAIL',
  SET_CONFLUENCE_CREDENTIALS_START: 'SET_CONFLUENCE_CREDENTIALS_START',
  SET_CONFLUENCE_CREDENTIALS_SUCCESS: 'SET_CONFLUENCE_CREDENTIALS_SUCCESS',
  SET_COPY_ATTRIBUTES_PRODUCT: 'SET_COPY_ATTRIBUTES_PRODUCT',
  SET_CSV_SCHEDULE_ERROR: 'SET_CSV_SCHEDULE_ERROR',
  SET_CSV_SCHEDULE_START: 'SET_CSV_SCHEDULE_START',
  SET_CSV_SCHEDULE_SUCCESS: 'SET_CSV_SCHEDULE_SUCCESS',
  SET_CURRENT_EMAIL_CREDENTIAL: 'SET_CURRENT_EMAIL_CREDENTIAL',
  SET_DEFAULT_SERVICE_TEAM: 'SET_DEFAULT_SERVICE_TEAM',
  SET_ENTRY_WORKFLOW_SUCCESS: 'SET_ENTRY_WORKFLOW_SUCCESS',
  SET_ERRORS: 'SET_ERRORS',
  SET_FAQ_CONDITION_LOCDEPJOB_NAMES: 'SET_FAQ_CONDITION_LOCDEPJOB_NAMES',
  SET_FAQ_EDIT_CONDITION: 'SET_FAQ_EDIT_CONDITION',
  SET_FAQ_RESET_EDIT_CONDITION: 'SET_FAQ_RESET_EDIT_CONDITION',
  SET_FAQ_SERVICE_DEPARTMENT_SUCCESS: 'SET_FAQ_SERVICE_DEPARTMENT_SUCCESS',
  SET_FAQ_SERVICE_TEAM_SUCCESS: 'SET_FAQ_SERVICE_TEAM_SUCCESS',
  SET_FAQ_TASK_CATEGORY_SUCCESS: 'SET_FAQ_TASK_CATEGORY_SUCCESS',
  SET_FILTER_USERS_BY: 'SET_FILTER_USERS_BY',
  SET_INTERACTION_EID_TO_SHOW_DETAILS: 'SET_INTERACTION_EID_TO_SHOW_DETAILS',
  SET_IS_SEARCHING_PERFORMANCE: 'SET_IS_SEARCHING_PERFORMANCE',
  SET_IS_SEARCHING_SCHEDULE: 'SET_IS_SEARCHING_SCHEDULE',
  SET_MY_TEAMS: 'SET_MY_TEAMS',
  SET_MY_TEAMS_ERROR: 'SET_MY_TEAMS_ERROR',
  SET_OKTA_TENANT_FAIL: 'SET_OKTA_TENANT_FAIL',
  SET_OKTA_TENANT_START: 'SET_OKTA_TENANT_START',
  SET_OKTA_TENANT_SUCCESS: 'SET_OKTA_TENANT_SUCCESS',
  SET_PARENT_TASK: 'SET_PARENT_TASK',
  SET_PENDING_REQUEST_ORDER_ID: 'SET_PENDING_REQUEST_ORDER_ID',
  SET_PRODUCT_IMAGES: 'SET_PRODUCT_IMAGES',
  SET_SEARCH_CONTEXT_NAME: 'SET_SEARCH_CONTEXT_NAME',
  SET_SEARCH_SERVICE_DEPARTMENT: 'SET_SEARCH_SERVICE_DEPARTMENT',
  SET_SEARCH_TEAM_SERVICE_DEPARTMENT: 'SET_SEARCH_TEAM_SERVICE_DEPARTMENT',
  SET_SEARCH_USER_INPUT: 'SET_SEARCH_USER_INPUT',
  SET_SESSION_INDEX: 'SET_SESSION_INDEX',
  SET_SORT_USERS_BY: 'SET_SORT_USERS_BY',
  SET_TENANT_CONFIG_LABEL_FAIL: 'SET_TENANT_CONFIG_LABEL_FAIL',
  SET_TENANT_CONFIG_LABEL_START: 'SET_TENANT_CONFIG_LABEL_START',
  SET_TENANT_CONFIG_LABEL_SUCCESS: 'SET_TENANT_CONFIG_LABEL_SUCCESS',
  SET_TENANT_CONFIG_MENU_FAIL: 'SET_TENANT_CONFIG_MENU_FAIL',
  SET_TENANT_CONFIG_MENU_START: 'SET_TENANT_CONFIG_MENU_START',
  SET_TENANT_CONFIG_MENU_SUCCESS: 'SET_TENANT_CONFIG_MENU_SUCCESS',
  SET_TENANT_CONFIG_ONE_LABEL_SUCCESS: 'SET_TENANT_CONFIG_ONE_LABEL_SUCCESS',
  SET_TERM_SEARCH_PERFORMANCE_ACTIVITY: 'SET_TERM_SEARCH_PERFORMANCE_ACTIVITY',
  SET_TERM_SEARCH_SCHEDULE_ACTIVITY: 'SET_TERM_SEARCH_SCHEDULE_ACTIVITY',
  SET_TMP_SUBSCRIBER_LOCATION_SELECTION:
    'SET_TMP_SUBSCRIBER_LOCATION_SELECTION',
  SET_TODO_TEMPLATE_CATEGORY: 'SET_TODO_TEMPLATE_CATEGORY',
  SET_TOKEN: 'SET_TOKEN',
  SET_USER_SEARCH_INPUT: 'SET_USER_SEARCH_INPUT',
  SET_USER_SEARCH_TERM: 'SET_USER_SEARCH_TERM',
  SET_USER_TO_CHANNELS: 'SET_USER_TO_CHANNELS',
  SHOP_MODAL_ACTIVE_IMAGE: 'SHOP_MODAL_ACTIVE_IMAGE',
  SHOP_MODAL_SEARCH_FAIL: 'SHOP_MODAL_SEARCH_FAIL',
  SHOP_MODAL_SEARCH_RESET: 'SHOP_MODAL_SEARCH_RESET',
  SHOP_MODAL_SEARCH_START: 'SHOP_MODAL_SEARCH_START',
  SHOP_MODAL_SEARCH_SUCCESS: 'SHOP_MODAL_SEARCH_SUCCESS',
  SHOW_THANKS_SURVEY_CARD: 'SHOW_THANKS_SURVEY_CARD',
  SINGLE_CARD_UPDATE: 'SINGLE_CARD_UPDATE',
  SLACKBOT_IS_ACTIVE_FAIL: 'SLACKBOT_IS_ACTIVE_FAIL',
  SLACKBOT_IS_ACTIVE_START: 'SLACKBOT_IS_ACTIVE_START',
  SLACKBOT_IS_ACTIVE_SUCCESS: 'SLACKBOT_IS_ACTIVE_SUCCESS',
  SLACKBOT_IS_NOT_ACTIVE_SUCCESS: 'SLACKBOT_IS_NOT_ACTIVE_SUCCESS',
  SLACKBOT_REMOVE_FAIL: 'SLACKBOT_REMOVE_FAIL',
  SLACKBOT_REMOVE_START: 'SLACKBOT_REMOVE_START',
  SLACKBOT_REMOVE_SUCCESS: 'SLACKBOT_REMOVE_SUCCESS',
  SLACK_INTEGRATION_FAIL: 'SLACK_INTEGRATION_FAIL',
  SLACK_INTEGRATION_START: 'SLACK_INTEGRATION_START',
  SLACK_INTEGRATION_SUCCESS: 'SLACK_INTEGRATION_SUCCESS',
  SNOOZE_CARD_SUCCESS: 'SNOOZE_CARD_SUCCESS',
  SSO_TOKEN_RETRIEVAL_END: 'SSO_TOKEN_RETRIEVAL_END',
  SSO_TOKEN_RETRIEVAL_START: 'SSO_TOKEN_RETRIEVAL_START',
  START_ADD_EMAIL_CREDENTIALS: 'START_ADD_EMAIL_CREDENTIALS',
  START_ADD_TEAM_MEMBER: 'START_ADD_TEAM_MEMBER',
  START_EMAIL_TEST_CONNECTION: 'START_EMAIL_TEST_CONNECTION',
  START_GET_EMAIL_CREDENTIALS: 'START_GET_EMAIL_CREDENTIALS',
  START_RECALL_ANNOUNCEMENT: 'START_RECALL_ANNOUNCEMENT',
  START_SAVING_TASKS: 'START_SAVING_TASKS',
  START_UPDATE_EMAIL_CREDENTIALS: 'START_UPDATE_EMAIL_CREDENTIALS',
  START_UPLOAD_CSV: 'START_UPLOAD_CSV',
  SUCCESS_ADD_EMAIL_CREDENTIALS: 'SUCCESS_ADD_EMAIL_CREDENTIALS',
  SUCCESS_EMAIL_TEST_CONNECTION: 'SUCCESS_EMAIL_TEST_CONNECTION',
  SUCCESS_GET_EMAIL_CREDENTIALS: 'SUCCESS_GET_EMAIL_CREDENTIALS',
  SUCCESS_RECALL_ANNOUNCEMENT: 'SUCCESS_RECALL_ANNOUNCEMENT',
  SUCCESS_UPDATE_EMAIL_CREDENTIALS: 'SUCCESS_UPDATE_EMAIL_CREDENTIALS',
  SUCCESS_UPDATE_PRIMARY_EMAIL: 'SUCCESS_UPDATE_PRIMARY_EMAIL',
  SUPPORT_CHANNEL_ERROR: 'SUPPORT_CHANNEL_ERROR',
  SUPPORT_CHANNEL_START: 'SUPPORT_CHANNEL_START',
  SWAP_BARISTA_SEARCH_CONDITIONS_SUCCESS:
    'SWAP_BARISTA_SEARCH_CONDITIONS_SUCCESS',
  SYNC_REACT_ROUTER_REDUX: 'SYNC_REACT_ROUTER_REDUX',
  SYSTEM_VERSION_FAIL: 'SYSTEM_VERSION_FAIL',
  SYSTEM_VERSION_START: 'SYSTEM_VERSION_START',
  SYSTEM_VERSION_SUCCESS: 'SYSTEM_VERSION_SUCCESS',
  TABLE_DETAIL_GET_INTERACTION_FAILURE: 'TABLE_DETAIL_GET_INTERACTION_FAILURE',
  TABLE_DETAIL_GET_INTERACTION_START: 'TABLE_DETAIL_GET_INTERACTION_START',
  TABLE_DETAIL_GET_INTERACTION_SUCCESS: 'TABLE_DETAIL_GET_INTERACTION_SUCCESS',
  TASK_FORM_IS_CHANGED: 'TASK_FORM_IS_CHANGED',
  TEST_ANNOUNCEMENTS_CLEAN_ERRORS: 'TEST_ANNOUNCEMENTS_CLEAN_ERRORS',
  TEST_ANNOUNCEMENTS_FAIL: 'TEST_ANNOUNCEMENTS_FAIL',
  TEST_ANNOUNCEMENTS_START: 'TEST_ANNOUNCEMENTS_START',
  TEST_ANNOUNCEMENTS_SUCCESS: 'TEST_ANNOUNCEMENTS_SUCCESS',
  TEST_CONFLUENCE_SETTINGS_FAILURE: 'TEST_CONFLUENCE_SETTINGS_FAILURE',
  TEST_CONFLUENCE_SETTINGS_START: 'TEST_CONFLUENCE_SETTINGS_START',
  TEST_CONFLUENCE_SETTINGS_SUCCESS: 'TEST_CONFLUENCE_SETTINGS_SUCCESS',
  TEST_CSV_SCHEDULE_CONNECTION_ERROR: 'TEST_CSV_SCHEDULE_CONNECTION_ERROR',
  TEST_CSV_SCHEDULE_CONNECTION_RESET: 'TEST_CSV_SCHEDULE_CONNECTION_RESET',
  TEST_CSV_SCHEDULE_CONNECTION_START: 'TEST_CSV_SCHEDULE_CONNECTION_START',
  TEST_CSV_SCHEDULE_CONNECTION_SUCCESS: 'TEST_CSV_SCHEDULE_CONNECTION_SUCCESS',
  TEST_SHAREPOINT_SETTINGS_FAILURE: 'TEST_SHAREPOINT_SETTINGS_FAILURE',
  TEST_SHAREPOINT_SETTINGS_START: 'TEST_SHAREPOINT_SETTINGS_START',
  TEST_SHAREPOINT_SETTINGS_SUCCESS: 'TEST_SHAREPOINT_SETTINGS_SUCCESS',
  TOGGLE_BARISTA_SEARCH_CONDITION_SUCCESS:
    'TOGGLE_BARISTA_SEARCH_CONDITION_SUCCESS',
  TOGGLE_PREVIEW: 'TOGGLE_PREVIEW',
  TOGGLE_PRODUCT_FROM_PERMISSION_GROUP: 'TOGGLE_PRODUCT_FROM_PERMISSION_GROUP',
  UNFAVORITE_USER_FAIL: 'UNFAVORITE_USER_FAIL',
  UNFAVORITE_USER_START: 'UNFAVORITE_USER_START',
  UNFAVORITE_USER_SUCCESS: 'UNFAVORITE_USER_SUCCESS',
  UNMOUNT_EVENT: 'UNMOUNT_EVENT',
  UNSUBSCRIBE_CARD_SUCCESS: 'UNSUBSCRIBE_CARD_SUCCESS',
  UPDATE_AUTOMATION_TASK_TYPE_CARD_FROM_CHANNEL:
    'UPDATE_AUTOMATION_TASK_TYPE_CARD_FROM_CHANNEL',
  UPDATE_BARISTABOT_INTEGRATION_FAIL: 'UPDATE_SLACKBOT_UPDATE_SETTINGS_FAIL',
  UPDATE_BARISTABOT_INTEGRATION_START: 'UPDATE_SLACKBOT_SETTINGS_START',
  UPDATE_BARISTABOT_INTEGRATION_SUCCESS: 'UPDATE_SLACKBOT_SETTINGS_SUCCESS',
  UPDATE_BARISTA_SEARCH_CONDITION_ERROR:
    'UPDATE_BARISTA_SEARCH_CONDITION_ERROR',
  UPDATE_BARISTA_SEARCH_CONDITION_START:
    'UPDATE_BARISTA_SEARCH_CONDITION_START',
  UPDATE_BARISTA_SEARCH_CONDITION_SUCCESS:
    'UPDATE_BARISTA_SEARCH_CONDITION_SUCCESS',
  UPDATE_CART_SUCCESS: 'UPDATE_CART_SUCCESS',
  UPDATE_CASE_FROM_BARISTA_FAIL: 'UPDATE_CASE_FROM_BARISTA_FAIL',
  UPDATE_CASE_FROM_BARISTA_START: 'UPDATE_CASE_FROM_BARISTA_START',
  UPDATE_CASE_FROM_BARISTA_SUCCESS: 'UPDATE_CASE_FROM_BARISTA_SUCCESS',
  UPDATE_CHANNEL_READ_COUNT: 'UPDATE_CHANNEL_READ_COUNT',
  UPDATE_CSV_SCHEDULE_SUCCESS: 'UPDATE_CSV_SCHEDULE_SUCCESS',
  UPDATE_EMAIL_ADDRESS_POLICY_FAIL: 'UPDATE_EMAIL_ADDRESS_POLICY_FAIL',
  UPDATE_EMAIL_ADDRESS_POLICY_START: 'UPDATE_EMAIL_ADDRESS_POLICY_START',
  UPDATE_EMAIL_ADDRESS_POLICY_SUCCESS: 'UPDATE_EMAIL_ADDRESS_POLICY_SUCCESS',
  UPDATE_FAQ_ANSWER_LINE_SUCCESS: 'UPDATE_FAQ_ANSWER_LINE_SUCCESS',
  UPDATE_FAQ_ANSWER_SUCCESS: 'UPDATE_FAQ_ANSWER_SUCCESS',
  UPDATE_FAQ_FAIL: 'UPDATE_FAQ_FAIL',
  UPDATE_FAQ_GROUP_FAIL: 'UPDATE_FAQ_GROUP_FAIL',
  UPDATE_FAQ_GROUP_START: 'UPDATE_FAQ_GROUP_START',
  UPDATE_FAQ_GROUP_SUCCESS: 'UPDATE_FAQ_GROUP_SUCCESS',
  UPDATE_FAQ_START: 'UPDATE_FAQ_START',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  UPDATE_INTERACTION_ERROR: 'UPDATE_INTERACTION_ERROR',
  UPDATE_INTERACTION_START: 'UPDATE_INTERACTION_START',
  UPDATE_INTERACTION_SUCCESS: 'UPDATE_INTERACTION_SUCCESS',
  UPDATE_ONE_ASSET_SUCCESS: 'UPDATE_ONE_ASSET_SUCCESS',
  UPDATE_ORDERING_VALUE: 'UPDATE_ORDERING_VALUE',
  UPDATE_PRODUCTS_IMAGES_ERROR: 'UPDATE_PRODUCTS_IMAGES_ERROR',
  UPDATE_PRODUCTS_IMAGES_START: 'UPDATE_PRODUCTS_IMAGES_START',
  UPDATE_PRODUCTS_IMAGES_SUCCESS: 'UPDATE_PRODUCTS_IMAGES_SUCCESS',
  UPDATE_SERVICE_TEAM_SUBSTATES_SUCCESS:
    'UPDATE_SERVICE_TEAM_SUBSTATES_SUCCESS',
  UPDATE_SESSION_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_TASK_CART_STATUS: 'UPDATE_TASK_CART_STATUS',
  UPDATE_TEAM_SERVICE_DEPARTMENT_FAIL: 'UPDATE_TEAM_SERVICE_DEPARTMENT_FAIL',
  UPDATE_TEAM_SERVICE_DEPARTMENT_START: 'UPDATE_TEAM_SERVICE_DEPARTMENT_START',
  UPDATE_TEAM_SERVICE_DEPARTMENT_SUCCESS:
    'UPDATE_TEAM_SERVICE_DEPARTMENT_SUCCESS',
  UPDATE_TEMPLATE_INTERVAL: 'UPDATE_TEMPLATE_INTERVAL',
  UPDATE_TEMPLATE_SCHEDULE: 'UPDATE_TEMPLATE_SCHEDULE',
  UPDATE_TEMPLATE_SCHEDULE_TO_TEMPLATE: 'UPDATE_TEMPLATE_SCHEDULE_TO_TEMPLATE',
  UPDATE_TENANT_FAIL: 'UPDATE_TENANT_FAIL',
  UPDATE_TENANT_START: 'UPDATE_TENANT_START',
  UPDATE_TENANT_SUCCESS: 'UPDATE_TENANT_SUCCESS',
  UPDATE_UNREAD_COUNT_DIRECT_CHANNELS: 'UPDATE_UNREAD_COUNT_DIRECT_CHANNELS',
  UPDATE_UNREAD_COUNT_SCOPED_CHANNELS: 'UPDATE_UNREAD_COUNT_SCOPED_CHANNELS',
  UPDATE_USER_ENTITY_SUCCESS: 'UPDATE_USER_ENTITY_SUCCESS',
  UPDATE_WIDGET_SUCCESS: 'UPDATE_WIDGET_SUCCESS',
  UPDATE_WORKFLOW_REQUESTED_FOR_USER_SUCCESS:
    'UPDATE_WORKFLOW_REQUESTED_FOR_USER_SUCCESS',
  UPLOAD_CSV_FAIL: 'UPLOAD_CSV_FAIL',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
  UPLOAD_TENANT_FILE_FAIL: 'UPLOAD_TENANT_FILE_FAIL',
  UPLOAD_TENANT_FILE_START: 'UPLOAD_TENANT_FILE_START',
  UPLOAD_TENANT_FILE_SUCCESS: 'UPLOAD_TENANT_FILE_SUCCESS',
  UPLOAD_TENANT_IMAGE_FAIL: 'UPLOAD_TENANT_IMAGE_FAIL',
  UPLOAD_TENANT_IMAGE_START: 'UPLOAD_TENANT_IMAGE_START',
  UPLOAD_TENANT_IMAGE_SUCCESS: 'UPLOAD_TENANT_IMAGE_SUCCESS',
  USER_AUTH_FAILURE: 'USER_AUTH_FAILURE',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_RULES_SUCCESS: 'USER_RULES_SUCCESS',
  USER_SEARCH_FAIL: 'USER_SEARCH_FAIL',
  USER_SEARCH_SET_DEFAULT: 'USER_SEARCH_SET_DEFAULT',
  USER_SEARCH_START: 'USER_SEARCH_START',
  USER_SEARCH_SUCCESS: 'USER_SEARCH_SUCCESS',
  USER_SEND_FEEDBACK_FAIL: 'USER_SEND_FEEDBACK_FAIL',
  USER_SEND_FEEDBACK_START: 'USER_SEND_FEEDBACK_START',
  USER_SEND_FEEDBACK_SUCCESS: 'USER_SEND_FEEDBACK_SUCCESS',
  USER_SET_PREVIOUSURL: 'USER_SET_PREVIOUSURL',
  USER_UPLOAD_IMG_FAIL: 'USER_UPLOAD_IMG_FAIL',
  USER_UPLOAD_IMG_START: 'USER_UPLOAD_IMG_START',
  USER_UPLOAD_IMG_SUCCESS: 'USER_UPLOAD_IMG_SUCCESS',
  WF_INTL_CLOSE_INTL_KEY_EDITOR: 'WF_INTL_CLOSE_INTL_KEY_EDITOR',
  WF_INTL_LAUNCH_INTL_KEY_EDITOR: 'WF_INTL_LAUNCH_INTL_KEY_EDITOR',
  WF_INTL_LOAD_KEY_ERROR: 'WF_INTL_LOAD_KEY_ERROR',
  WF_INTL_LOAD_KEY_START: 'WF_INTL_LOAD_KEY_START',
  WF_INTL_LOAD_KEY_SUCCESS: 'WF_INTL_LOAD_KEY_SUCCESS',
  WF_INTL_LOAD_TASK_ERROR: 'WF_INTL_LOAD_TASK_ERROR',
  WF_INTL_LOAD_TASK_START: 'WF_INTL_LOAD_TASK_START',
  WF_INTL_LOAD_TASK_SUCCESS: 'WF_INTL_LOAD_TASK_SUCCESS',
  WF_INTL_NEW_KEY_PROSPECT: 'WF_INTL_NEW_KEY_PROSPECT',
  WF_INTL_SAVE_TRANS_ERROR: 'WF_INTL_SAVE_TRANS_ERROR',
  WF_INTL_SAVE_TRANS_START: 'WF_INTL_SAVE_TRANS_START',
  WF_INTL_SAVE_TRANS_SUCCESS: 'WF_INTL_SAVE_TRANS_SUCCESS',
  WF_INTL_SELECT_TASK: 'WF_INTL_SELECT_TASK',
  WF_INTL_TRANSLATION_CHANGE: 'WF_INTL_TRANSLATION_CHANGE',
  WF_ON_BLUR_FIELD: 'WF_ON_BLUR_FIELD',
  WF_ON_FOCUS_FIELD: 'WF_ON_FOCUS_FIELD',
  WF_UPDATE_ATTRIBUTES_FROM_BESCRATCH: 'WF_UPDATE_ATTRIBUTES_FROM_BESCRATCH',
  WHO_AM_I: 'WHO_AM_I',
  WK_EXIT_LOADING: 'WK_EXIT_LOADING',
  WK_LOADING: 'WK_LOADING',
  WK_LOGIN_DONE: 'WK_LOGIN_DONE',
  WK_OVERLOADING: 'WK_OVERLOADING',
  WK_SAVE_BE_SCRATCH_FAIL: 'WK_SAVE_BE_SCRATCH_FAIL',
  WK_SAVE_BE_SCRATCH_START: 'WK_SAVE_BE_SCRATCH_START',
  WK_SAVE_BE_SCRATCH_SUCCESS: 'WK_SAVE_BE_SCRATCH_SUCCESS',
  WK_SAVE_SCRATCH_FAIL: 'WK_SAVE_SCRATCH_FAIL',
  WK_SAVE_SCRATCH_START: 'WK_SAVE_SCRATCH_START',
  WK_SAVE_SCRATCH_SUCESS: 'WK_SAVE_SCRATCH_SUCESS',
  WK_SET_ERRORS: 'WK_SET_ERRORS',
  WS_CONNECT: 'WS_CONNECT',
  WS_CONNECTED: 'WS_CONNECTED',
  WS_CONNECTING: 'WS_CONNECTING',
  WS_DISCONNECT: 'WS_DISCONNECT',
  WS_DISCONNECTED: 'WS_DISCONNECTED',
  WS_ERRORED: 'WS_ERRORED',
  WS_REFUSED: 'WS_REFUSED',
  WS_SEND: 'WS_SEND',
};

export default actionTypes;
/* eslint-enable */
