import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';
import { isEmpty } from 'lodash';
import { INITIAL_FILTER_VALUES } from '../globals/HomeFilterGlobals';

const INITIAL_STATE = INITIAL_FILTER_VALUES;

const homeFilterReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SET_INITIAL_HOME_FILTERS: {
      return state;
    }
    case actionTypes.HOME_FILTER_UPDATE: {
      return !isEmpty(action.homeFilters) ? fromJS(action.homeFilters) : state;
    }
    default: {
      return state;
    }
  }
};

export default homeFilterReducer;
