import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getFulfillmentOptions = (state, productId) =>
  state.getIn(['catalog', 'fulfillmentOptions', productId]);
const getFulfillmentAnswers = (state, productId) =>
  state.getIn(['catalog', 'fulfillmentAnswers', productId]);

// Returns only the fulfillment options that DO NOT have answers
const getGroomedFulfillmentOptions = createSelector(
  [getFulfillmentOptions, getFulfillmentAnswers],
  (
    fulfillmentOptions = Immutable.List(),
    fulfillmentAnswers = Immutable.List()
  ) =>
    // fulfillmentOptions;
    // console.log('fulfillmentAnswers', fulfillmentAnswers.toJS());
    // console.log('fulfillmentOptions', fulfillmentOptions.toJS());
    fulfillmentOptions.filter((fOption) => {
      const hasAnswer = fulfillmentAnswers.find(
        (a) => a.get('option') === fOption.get('id')
      );
      return Boolean(!hasAnswer); // explicit return for legibility of the selector
    })
);

export { getGroomedFulfillmentOptions };
export default getGroomedFulfillmentOptions;
