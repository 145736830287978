const api_todo_v0_1_progress_summary = {
  count: 2,
  results: [
    {
      complete_state_count: 4,
      delinquent_state_count: 1,
      id: 9,
      normal_state_count: 0,
      overall_state: 'At risk',
      overdue_state_count: 0,
      scheduled_state_count: 0,
      start_date_time: '2017-09-28T22:39:56.066022Z',
      url: 'http://tenant1.esp/api/todo/v0.1/progress_summary/9/',
      user: 2027,
      warning_state_count: 0,
    },
  ],
};

export default api_todo_v0_1_progress_summary;
