import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  entity: {
    allowedExtensions: [],
    domain: null,
    error: null,
    groupPermissions: {},
    isLoading: false,
    value: null,
  },

  files: {
    isLoading: false,
  },

  formErrorMsg: '',

  images: {
    error: null,
    imageLoading: null,
    isLoading: false,
    loaded: false,
    values: [],
  },

  localization: {
    isLoading: false,
    list: [],
    loaded: false,
  },

  updateTenant: {
    error: null,
    isLoading: false,
  },

  uploadTenantImage: {
    error: null,
    isLoading: false,
  },
});

const onUploadTenantImageSuccess = (state, newTenantImage) => {
  state = state.set(
    'uploadTenantImage',
    INITIAL_STATE.get('uploadTenantImage')
  );

  let images = state.getIn(['images', 'values']);
  images = images.push(newTenantImage);

  state = state.setIn(['images', 'values'], images);

  return state;
};

/**
 * Tenant Reducer which fire action thought the dispatch switch
 * @param state
 * @param action
 * @returns {*}
 */
const tenantReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ADD_TENANT_USERS_GROUP_START: {
      return state.setIn(['entity', 'isLoading'], true);
    }

    case actionTypes.ADD_TENANT_USERS_GROUP_FAIL: {
      return state.setIn(['entity', 'isLoading'], false);
    }

    case actionTypes.ADD_TENANT_USERS_GROUP_SUCCESS: {
      return state
        .setIn(['entity', 'isLoading'], false)
        .setIn(['entity', 'groupPermissions'], fromJS(action.groups));
    }

    case actionTypes.LOAD_ALLOWED_EXTENSION_START: {
      return state.setIn(['entity', 'isLoading'], true);
    }

    case actionTypes.LOAD_ALLOWED_EXTENSION_FAIL:
      return state.setIn(['entity', 'isLoading'], false);

    case actionTypes.LOAD_ALLOWED_EXTENSION_SUCCESS: {
      // Parse allowed extension and handle *

      const value = action.value.match(/[*]/)
        ? ['*']
        : action.value.split(',').map((v) => `.${v.replace(' ', '')}`);
      return state
        .setIn(['entity', 'isLoading'], false)
        .setIn(['entity', 'allowedExtensions'], fromJS(value));
    }

    case actionTypes.CLEAR_TENANT: // resets tenant to initial state
      return INITIAL_STATE;

    case actionTypes.GET_TENANT_START:
      return state
        .setIn(['entity', 'value'], null)
        .setIn(['entity', 'error'], null)
        .setIn(['entity', 'isLoading'], true);

    case actionTypes.GET_TENANT_SUCCESS:
      return state
        .setIn(['entity', 'value'], fromJS(action.tenant))
        .setIn(['entity', 'error'], null)
        .setIn(['entity', 'isLoading'], false)
        .set('formErrorMsg', '');

    case actionTypes.GET_TENANT_FAIL:
      return state
        .set('entity', INITIAL_STATE.get('entity'))
        .setIn(['entity', 'isLoading'], false)
        .setIn(['entity', 'error'], fromJS(action.error));

    case actionTypes.REFRESH_TENANT_START:
      return state.setIn(['entity', 'isLoading'], true);

    case actionTypes.GET_TENANT_DOMAIN_START:
      return state.setIn(
        ['entity', 'domain'],
        INITIAL_STATE.getIn(['entity', 'domain'])
      );

    case actionTypes.GET_TENANT_DOMAIN_SUCCESS:
      return state
        .set('entity', INITIAL_STATE.get('entity'))
        .setIn(['entity', 'domain'], fromJS(action.domain))
        .set('formErrorMsg', '');

    case actionTypes.GET_TENANT_DOMAIN_FAIL:
      return state
        .set('entity', INITIAL_STATE.get('entity'))
        .setIn(['entity', 'error'], fromJS(action.error));

    case actionTypes.GET_TENANT_IMAGES_START: {
      const newImages = action.keepCurrentImages
        ? state.get('images')
        : INITIAL_STATE.get('images');

      return state
        .set('images', newImages)
        .setIn(['images', 'isLoading'], true);
    }
    case actionTypes.GET_TENANT_IMAGES_SUCCESS:
      return state
        .set('images', INITIAL_STATE.get('images'))
        .setIn(['images', 'loaded'], true)
        .setIn(['images', 'values'], fromJS(action.tenantImages));

    case actionTypes.GET_TENANT_IMAGES_FAIL:
      return state
        .set('images', INITIAL_STATE.get('images'))
        .setIn(['images', 'loaded'], true)
        .setIn(['images', 'error'], fromJS(action.error));

    case actionTypes.GET_TENANT_ONE_IMAGE_START:
      return state.setIn(['images, isLoading'], true);

    case actionTypes.GET_TENANT_ONE_IMAGE_FAIL:
      return state.setIn(['images, isLoading'], false);

    case actionTypes.GET_TENANT_ONE_IMAGE_SUCCESS: {
      // Find the img to update

      const oldImageIndex = state
        .getIn(['images', 'values'])
        .findIndex((img) => img.get('type') === action.imgType);

      return oldImageIndex > -1
        ? state.setIn(
            ['images', 'values', oldImageIndex],
            fromJS(action.tenantImage)
          )
        : onUploadTenantImageSuccess(state, fromJS(action.tenantImage));
    }

    case actionTypes.REMOVE_TENANT_IMAGES_START:
      return state.setIn(['images', 'imageLoading'], action.imageID);

    case actionTypes.REMOVE_TENANT_IMAGES_FAIL:
      return state.setIn(['images', 'imageLoading'], null);

    case actionTypes.REMOVE_TENANT_IMAGES_SUCCESS: {
      let imagesList = state.getIn(['images', 'values']);
      imagesList = imagesList.filter((img) => img.get('id') !== action.imageID);

      return state
        .setIn(['images', 'imageLoading'], null)
        .setIn(['images', 'values'], imagesList);
    }

    case actionTypes.UPDATE_TENANT_START:
      return state
        .set('updateTenant', INITIAL_STATE.get('updateTenant'))
        .setIn(['updateTenant', 'isLoading'], true);

    case actionTypes.UPDATE_TENANT_SUCCESS:
      return state
        .set('updateTenant', INITIAL_STATE.get('updateTenant'))
        .setIn(['entity', 'value'], fromJS(action.updatedTenant));

    case actionTypes.UPDATE_TENANT_FAIL:
      return state
        .set('updateTenant', INITIAL_STATE.get('updateTenant'))
        .setIn(['updateTenant', 'error'], fromJS(action.error));

    case actionTypes.UPLOAD_TENANT_IMAGE_START:
      return state
        .set('uploadTenantImage', INITIAL_STATE.get('uploadTenantImage'))
        .setIn(['uploadTenantImage', 'isLoading'], true);

    case actionTypes.UPLOAD_TENANT_IMAGE_SUCCESS: {
      const { tenantImages } = action;
      return state.setIn(['images', 'values'], fromJS(tenantImages));
    }
    case actionTypes.UPLOAD_TENANT_IMAGE_FAIL:
      return state
        .set('uploadTenantImage', INITIAL_STATE.get('uploadTenantImage'))
        .setIn(['uploadTenantImage', 'error'], fromJS(action.error));

    case actionTypes.CHANGE_TENANT_NOT_FOUND:
      return state
        .set('formErrorMsg', action.errorMsg)
        .setIn(['entity', 'isLoading'], false);

    case actionTypes.GET_TENANT_CONFIG_LABEL_START:
      return state.setIn(['localization', 'isLoading'], true);

    case actionTypes.GET_TENANT_CONFIG_LABEL_FAIL:
      return state
        .setIn(['localization', 'loaded'], true)
        .setIn(['localization', 'isLoading'], false);

    case actionTypes.GET_TENANT_CONFIG_LABEL_SUCCESS: {
      let labelsList = state.getIn(['localization', 'list']);

      action.data.forEach((data) => {
        labelsList = labelsList.push(fromJS(data));
      });

      return state
        .setIn(['localization', 'list'], labelsList)
        .setIn(['localization', 'isLoading'], false)
        .setIn(['localization', 'loaded'], true);
    }

    case actionTypes.SET_TENANT_CONFIG_LABEL_START:
      return state.setIn(['localization', 'isLoading'], true);

    case actionTypes.SET_TENANT_CONFIG_LABEL_FAIL:
      return state.setIn(['localization', 'isLoading'], false);

    case actionTypes.SET_TENANT_CONFIG_LABEL_SUCCESS:
      return state.setIn(['localization', 'isLoading'], false);

    case actionTypes.SET_TENANT_CONFIG_ONE_LABEL_SUCCESS: {
      let labList = state.getIn(['localization', 'list']);

      const index = labList.findIndex(
        (lab) => lab.get('key') === action.data.key
      );

      if (index > -1) {
        labList = labList.set(index, fromJS(action.data));
      } else {
        labList = labList.push(fromJS(action.data));
      }
      return state.setIn(['localization', 'list'], labList);
    }

    case actionTypes.DELETE_TENANT_CONFIG_LABEL_SUCCESS: {
      let oldList = state.getIn(['localization', 'list']);

      oldList = oldList.filter((lab) => lab.get('key') !== action.key);
      return state.setIn(['localization', 'list'], oldList);
    }

    case actionTypes.UPLOAD_TENANT_FILE_START:
      return state.setIn(['files', 'isLoading'], true);

    case actionTypes.UPLOAD_TENANT_FILE_FAIL:
      return state.setIn(['files', 'isLoading'], false);

    case actionTypes.UPLOAD_TENANT_FILE_SUCCESS:
      return state.setIn(['files', 'isLoading'], false);

    default:
      return state;
  }
};

export default tenantReducer;
