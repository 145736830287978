import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';

const rule = (props) => {
  const { expanded, isMe, theme } = props;

  const meColor = theme.brandTenant.primaryColor;
  // const meColorText = theme.brandTenant.textColorPrimary;
  const themeColor = theme.colors.gray;
  // const themeColorText = theme.colors.text;

  return {
    ':after': {
      backgroundImage: `linear-gradient(transparent,${
        isMe ? meColor : themeColor
      })`,
      borderBottomLeftRadius: theme.messageRadius,
      borderBottomRightRadius: theme.messageRadius,
      bottom: 0,
      content: '" "',
      display: 'block',
      height: theme.minHeight,
      left: 0,
      opacity: expanded ? 0 : 1,
      pointerEvents: 'none',
      position: 'absolute',
      transitionDuration: `${theme.globals.transitionDuration * 2}ms`,
      transitionProperty: 'opacity',
      transitionTimingFunction: theme.globals.transitionTimingFunction,
      width: '100%',
      zIndex: 0,
    },
    height: expanded ? 'fit-content' : `calc(100% - 5em)`,
    marginBottom: expanded ? '0' : '-5em',
    overflow: 'hidden',
  };
};

class ChatMessageExpandWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string.isRequired,
    // expanded  : PropTypes.bool,
    // isMe      : PropTypes.bool,
  };

  static defaultProps = {
    children: void 0,
    // expanded : false,
    // isMe     : false,
  };

  render() {
    const { children, className } = this.props;

    return (
      <div className={className} data-component='ChatMessageExpandWrapper'>
        {children}
      </div>
    );
  }
}

export default createComponent(rule, ChatMessageExpandWrapper, [
  'expanded',
  'isMe',
]);
