import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesHeadsets extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M69.78 34.64c-.776-3.638-2.5-7.005-5-9.76-3.92-4.2-9.62-6.35-16.93-6.38h-.16c-7.31 0-13 2.17-16.93 6.38-2.3 2.538-3.935 5.606-4.76 8.93-5.71 1.36-10 7.39-10 14.62 0 8.26 5.58 14.9 12.47 14.9h.05c.823 0 1.49-.667 1.49-1.49v-26.4c0-.823-.667-1.49-1.49-1.49.735-2.743 2.105-5.275 4-7.39 3.45-3.7 8.57-5.58 15.21-5.6 6.64-.02 11.81 1.89 15.27 5.58 2.052 2.273 3.503 5.023 4.22 8-.067-.005-.133-.005-.2 0-.823 0-1.49.667-1.49 1.49v26.52c.008.765.597 1.398 1.36 1.46-.602 4.765-4.524 8.418-9.32 8.68-.572-1.513-2.055-2.483-3.67-2.4-2.1 0-3.8 1.42-3.8 3.18 0 1.76 1.71 3.22 3.81 3.22 1.582.076 3.04-.854 3.64-2.32 5.7-.25 10.37-4.618 11-10.29 6.3-.75 11.22-7.1 11.22-14.83 0-7.22-4.29-13.24-9.99-14.61z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogAccessoriesHeadsets;
