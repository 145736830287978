// Selectors
import getTenant from '../../selectors/getTenant';
import { getTotalUnreadCountOrNullSelector } from '../../selectors/getTotalUnreadCount';
// Thunks
import tenantThunks from '../../actions/tenantThunks';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import ElectronUtils from '../../utils/ElectronUtils';
import CordovaUtils from '../../utils/CordovaUtils';
// Selector
import isCompatible from '../../selectors/isCompatible';
import getAppVersion from '../../selectors/getAppVersion';

// Actions
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import { getCurrentUserId } from '../../selectors/getCurrentUser';

const mapStateToProps = (state, ownprops) => {
  const tenant = getTenant(state); // FYI this may return null
  const domainLoaded = Boolean(state.getIn(['tenant', 'entity', 'domain']));

  const currentUserID = getCurrentUserId(state);

  const configPath = [
    'tenant',
    'entity',
    'value',
    'ui_config',
    'notifications_config',
    'sounds_enabled',
  ];
  const canPlayNotificationsSounds = state.hasIn(configPath)
    ? state.getIn(configPath)
    : true;

  return {
    appVersion: getAppVersion(state),
    canPlayNotificationsSounds,
    currentUserID,
    domainLoaded,
    errorTenant: Boolean(state.getIn(['tenant', 'entity', 'error'])),
    isCompatible: isCompatible(state),
    isLoading: state.getIn(['tenant', 'entity', 'isLoading']),
    locale: state.getIn(['intl', 'locale']),
    location: ownprops.location,
    store: state,
    tenantLoaded: Boolean(tenant),
    tenantLocalization: state.getIn(['tenant', 'localization', 'list']),
    tenantName: state.getIn(['tenant', 'name', 'value']),
    unreadCount: getTotalUnreadCountOrNullSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkTenantDomain() {
    dispatch(tenantThunks.checkTenantDomain());
  },

  dispatchNotification(versionInfo) {
    dispatch(
      toastNotificationsActions.info({
        dismissAfter: 6 * 1000,
        message: versionInfo,
      })
    );
  },
  getTenant(pathname) {
    dispatch(tenantThunks.getTenant(pathname)).then((tenant) => {
      if (window.electron) {
        ElectronUtils.setTenantUpdateServer(tenant);
      }
      if (window.cordova) {
        CordovaUtils.setStatusBarColor();
      }
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
