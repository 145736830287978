// eslint-disable-next-line eslint-comments/disable-enable-pair -- disables eslint-disable sort-keys
/* eslint-disable sort-keys -- No needed this is BE mocked data */
const api_config_v0_1_configuration_dashboard_ui_config = {
  key: 'dashboard.ui_config',
  value: {
    default: [
      {
        widget: 'interaction_stats_widget',
        active: true,
        children: [
          {
            widget: 'total_interactions',
            active: true,
            children: null,
          },
          {
            widget: 'unique_users',
            active: true,
            children: null,
          },
          {
            widget: 'mobile_interactions',
            active: true,
            children: null,
          },
        ],
      },
      {
        widget: 'deflection_stats_widget',
        active: true,
        children: [
          {
            widget: 'total_deflections',
            active: true,
            children: null,
          },
          {
            widget: 'deflection_rate',
            active: true,
            children: null,
          },
          {
            widget: 'transferred_to_agent',
            active: true,
            children: null,
          },
          {
            widget: 'dollars_saved',
            active: true,
            children: null,
          },
          {
            widget: 'kb_deflections',
            active: true,
            children: null,
          },
          {
            widget: 'deflection_by_kb',
            active: true,
            children: null,
          },
        ],
      },
      {
        widget: 'possibly_abandoned_stats_widget',
        active: true,
        children: [
          {
            active: true,
            children: null,
            widget: 'possibly_abandoned',
          },
          {
            active: true,
            children: null,
            widget: 'possibly_abandoned_rate',
          },
        ],
      },
      {
        widget: 'top_unanswered_questions_widget',
        active: true,
        children: null,
      },
      {
        widget: 'helpful_feedback_widget',
        active: true,
        children: null,
      },
      {
        widget: 'feedback_details_widget',
        active: true,
        children: null,
      },
      {
        widget: 'geo_distribution_widget',
        active: true,
        children: null,
      },
      {
        widget: 'top_matched_intents_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interactions_channel_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interactions_os_client_widget',
        active: true,
        children: null,
      },
      {
        widget: 'deflected_widget',
        active: true,
        children: null,
      },
      {
        widget: 'intents_driving_deflection_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interactions_by_topic_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interactions_by_matched_archetype_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interactions_by_keywords_group_widget',
        active: true,
        children: null,
      },
      {
        widget: 'top_unanswered_questions_without_kb_widget',
        active: false,
        children: null,
      },
      {
        widget: 'top_answered_questions_with_kb_widget',
        active: false,
        children: null,
      },
      {
        widget: 'top_unanswered_questions_list_widget',
        active: true,
        children: null,
      },
      {
        widget: 'automation_stats_widget',
        active: false,
        children: [
          {
            widget: 'total_automations',
            active: true,
            children: null,
          },
          {
            widget: 'automation_escalations',
            active: true,
            children: null,
          },
          {
            widget: 'automation_rate',
            active: true,
            children: null,
          },
          {
            widget: 'successful_automations',
            active: true,
            children: null,
          },
          {
            widget: 'automation_success_rate',
            active: true,
            children: null,
          },
        ],
      },
      {
        widget: 'automation_reports_by_status',
        active: false,
        children: null,
      },
      {
        widget: 'top_intents_driving_automation',
        active: false,
        children: null,
      },
      {
        widget: 'interactions_by_source',
        active: false,
        children: null,
      },
    ],
    'Only Deflection Stats': [
      {
        // Duplicate widget for testing purposes
        widget: 'interactions_channel_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interactions_channel_widget',
        active: true,
        children: null,
      },
      {
        widget: 'interaction_stats_widget',
        active: true,
        children: [
          {
            widget: 'total_interactions',
            active: true,
            children: null,
          },
          {
            widget: 'unique_users',
            active: true,
            children: null,
          },
          {
            widget: 'mobile_interactions',
            active: true,
            children: null,
          },
        ],
      },
      {
        // Duplicate widget for testing purposes
        widget: 'interaction_stats_widget',
        active: true,
        children: [
          {
            widget: 'total_interactions',
            active: true,
            children: null,
          },
          {
            widget: 'unique_users',
            active: true,
            children: null,
          },
          {
            widget: 'mobile_interactions',
            active: true,
            children: null,
          },
        ],
      },
    ],
  },
  DEBUG_decrypted: null,
  src_active_status: true,
  last_sync_time: null,
  is_protected: false,
  created_in_elc: false,
  eid: '6f8cc5bf-e7ac-4e55-9252-2dc62cb1d378',
  sys_date_updated: '2022-04-05T21:42:25.040832Z',
  type: 'json',
};

export default api_config_v0_1_configuration_dashboard_ui_config;
