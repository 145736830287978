import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { intl } from 'esp-util-intl';
import { noop } from 'lodash';

import ModalEsp from '../../../../../admin/js/v1/components/organisms/ModalEsp';
import EditorWorkflowForm from '../molecules/EditorWorkflowForm';
//  Controllers
import WorkflowModalController from '../controllers/WorkflowModalController';

class WorkflowModal extends PureComponent {
  static propTypes = {
    createNewWorkFlow: PropTypes.func,
    editWorkFlow: PropTypes.func,
    existingWorkflow: PropTypes.shape({
      name: PropTypes.string,
    }),
    trigger: PropTypes.node.isRequired,
    workflowFormError: PropTypes.string,
    workflowFormState: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    createNewWorkFlow: noop,
    editWorkFlow: noop,
    existingWorkflow: null,
    workflowFormError: '',
    workflowFormState: null,
  };

  handleProcessWorkflow = (close) => {
    const {
      workflowFormState,
      existingWorkflow,
      editWorkFlow,
      createNewWorkFlow,
    } = this.props;

    const data = workflowFormState.get('values');

    if (existingWorkflow) {
      editWorkFlow(data, existingWorkflow, (error) => {
        if (!error) {
          close();
        }
      });
    } else {
      createNewWorkFlow(data);
    }
  };

  handleSubmit = (values, close) => {
    this.handleProcessWorkflow(close);
  };

  render() {
    const { trigger, existingWorkflow, workflowFormState, workflowFormError } =
      this.props;

    let workflowFormHasErrors = false;

    if (workflowFormState) {
      workflowFormHasErrors = workflowFormState.has('syncErrors');
    }

    let header;
    if (existingWorkflow) {
      header = intl.formatMessage({
        id: 'label.edit_workflow',
      });
    } else {
      header = intl.formatMessage({
        id: 'label.create_new_workflow',
      });
    }

    return (
      <ModalEsp
        acceptContent={intl.formatMessage({
          id: 'label.Submit',
        })}
        closeOnDimmerClick={false}
        headerContent={header}
        headerIcon='list layout'
        isAcceptDisabled={workflowFormHasErrors}
        isLoading={false}
        onAccept={this.handleProcessWorkflow}
        trigger={trigger}
      >
        <ModalEsp.Form onSubmit={this.handleSubmit}>
          <EditorWorkflowForm
            existingWorkflow={existingWorkflow}
            formError={workflowFormError}
          />
        </ModalEsp.Form>
      </ModalEsp>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowModal = WorkflowModalController(WorkflowModal);

export default WorkflowModal;
