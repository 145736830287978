import React from 'react';
import pt from 'prop-types';
import { Link } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import BaristaUserImg from './BaristaUserImg';

import AvatarDefault from '../../display/AvatarDefault';

import uiPathGenerator from '../../../../v1/utils/uiPathGenerator';
import { ImageDefaults } from 'esp-assets';

const IMG_STYLE = {
  borderRadius: '256px',
  display: 'block',
  maxWidth: '100%',
  overflow: 'hidden',
};

const propTypes = {
  isBarista: pt.bool,
  isLinkingDisabled: pt.bool,
  isProfileLink: pt.bool,
  user: pt.shape({
    error: pt.bool,
    id: pt.number,
    images: pt.arrayOf(pt.shape()),
    is_active: pt.bool,
    last_name: pt.string,
    nickname: pt.string,
    userImgUrl: pt.string,
    userInitials: pt.string,
  }),
};

const UserImgV2 = ({ user, isBarista, isLinkingDisabled, isProfileLink }) => {
  const { error } = user;
  const imgLoaded = true;
  const isUserActive = user.is_active;
  const userId = user.id;

  // Get the profile type images from our user data
  const images = user?.images.find((img) => img.type === 'PROFILE');
  // Make sure there were profile pictures, then get the picture url
  const userImgUrl = images?.picture;

  const firstInit = user?.nickname.charAt(0);
  const lastInit = user?.last_name.charAt(0);
  const userInitials = firstInit + lastInit;

  const renderLoader = (content) => {
    // if we don't have user initials or an url, then means we're in loading state
    // we cannot rely on props.isLoading because it can be caused by a *reload* of data
    // which can we avoid to use loader states therem since we should be able to render something
    const loaderActive =
      (!userImgUrl && !userInitials) || (userImgUrl && !imgLoaded);

    if (!loaderActive) {
      return content;
    }

    return (
      <Dimmer.Dimmable blurring style={IMG_STYLE}>
        <Dimmer active inverted={!imgLoaded}>
          <Loader indeterminate />
        </Dimmer>
        {content}
      </Dimmer.Dimmable>
    );
  };

  const renderImage = () => {
    // const { error, isBarista, userId, userImgUrl, userInitials } = this.props;

    // const { imgError, imgLoaded } = this.state;

    if (error) {
      return (
        <img alt='default-user' src={ImageDefaults.BROKEN} style={IMG_STYLE} />
      );
    } else if (isBarista) {
      // Probably we should do the same for all other svggs

      return <BaristaUserImg />;
    } else if (userImgUrl && userId) {
      let src = userImgUrl;
      if (window.cordova && src && src.indexOf('http') !== 0) {
        // do NOT do this for web images
        const wwwFolder =
          window.device.platform === 'IOS' ? 'www/data/' : 'www/';

        src = src[0] === '/' ? src.substring(1, src.length) : src;
        src = `${window.cordova.file.applicationDirectory}${wwwFolder}${src}`;
      }

      return renderLoader(
        <>
          {src ? (
            <img
              alt={userId}
              src={src}
              style={{
                ...IMG_STYLE,
                display: 'block',
                maxHeight: '100%',
              }}
            />
          ) : null}

          {imgLoaded ? null : (
            <AvatarDefault
              backgroundSeed={userId}
              initials={userInitials}
              responsive
            />
          )}
        </>
      );
    } else if (userId || userId === 0) {
      return renderLoader(
        <AvatarDefault
          backgroundSeed={userId}
          initials={userInitials}
          responsive
        />
      );
    } else {
      return null;
    }
  };

  const linkStyle = {
    display: 'block',
    maxWidth: '100%',
  };

  if (
    isProfileLink &&
    userId &&
    isUserActive &&
    !isBarista &&
    !isLinkingDisabled
  ) {
    return (
      <Link
        style={linkStyle}
        to={uiPathGenerator.genPath('app.directory.detail', {
          userID: userId,
        })}
      >
        {renderImage()}
      </Link>
    );
  } else {
    return renderImage();
  }
};

UserImgV2.propTypes = propTypes;

export default UserImgV2;
