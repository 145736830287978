import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import { noop } from 'lodash';

// Molecule
import FormInputText from '../../molecules/FormInputText';

// Controller
import EspResetPasswordMethodController from '../../controllers/EspResetPasswordMethodController';

// Globals
import TypeResetPasswordCode, {
  TypeDefaults,
} from '../../../globals/blocks/TypeResetPasswordCode';

const AUTHENTICATION_LENGTH = 8;

let ResetPasswordCode01 = class ResetPasswordCode01 extends Component {
  static propTypes = {
    ...TypeResetPasswordCode,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  state = {
    error: '',
  };

  componentDidMount() {
    const { setAlternateSubmit } = this.props;
    setAlternateSubmit(this.handleClick);
  }

  handleClick = (cb = noop) => {
    const { code, validateOTP } = this.props;

    this.setState({
      error: '',
    });

    validateOTP(code, (res, error) => {
      if (error) {
        this.setState({
          error: intl.formatMessage({
            id: 'label.wrong_code_sent',
          }),
        });
      } else {
        cb(); // move to next worflow task
      }
    });
  };

  handleClickSendAgain = (e) => {
    e.preventDefault(); // Prevent the set_next_task to be fire

    const { sendOTP, frontendScratch, successOTPSend } = this.props;

    sendOTP(frontendScratch.get('method'), (res) => {
      if (res) {
        this.setState({
          error: '',
        }); // Reset any error state

        successOTPSend();
      }
    });
  };

  handleChangeText = () => {
    const { error } = this.state;

    if (error) {
      this.setState({
        error: '',
      });
    }
  };

  validate = (value) =>
    !value || value.length !== AUTHENTICATION_LENGTH ? 'error' : '';

  render() {
    const { template, type } = this.props;
    const { error } = this.state;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          // eslint-disable-next-line jsx-a11y/no-autofocus -- Legacy workflow component, auto focus is expected
          autoFocus
          component={FormInputText}
          formError={error}
          name='code'
          onChange={this.handleChangeText}
          type='text'
          validate={this.validate}
        />
        <div className='block centered'>
          <Button
            className='text'
            content={intl.formatMessage({
              id: 'label.send_again',
            })}
            onClick={this.handleClickSendAgain}
            type='button'
          />
        </div>
      </div>
    );
  }
};

ResetPasswordCode01 = EspResetPasswordMethodController(ResetPasswordCode01);

export default ResetPasswordCode01;
