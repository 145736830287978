/**
 * SocketIntance
 * This class is used as a low layer level to handle WebScoket instance and actions
 */
import { debounce, noop } from 'lodash';

import WebSocketStatus from '../globals/WebSocketStatus';

// Utils
import APIcall from './APIcall';
import endpointGenerator from './endpointGenerator';
import IdleTime from './IdleTime';

/**
 * Config variables
 */
const reconnectDelayMs = 2 * 1000; // (miliseconds) if connection is lost, we'll try to reconnect every time this in ms
const reconnectMultiplier = 1.2; // for every failed reconnection attempt, `reconnectDelayMs` will be extended by multiplying for this amount
const heartBeatFrequencyMs = 40 * 1000; // (milliseconds) interval to send haertbeat

// Prints out stuff only in dev environment or if window.wsLogger is true
const logger = function (...rest) {
  if (process.env.NODE_ENV !== 'production' || window.wsLogger) {
    // eslint-disable-next-line no-console -- debugging
    console.log.apply(null, rest);
  }
};
/**
 * Internal instances
 */
// this websocket instance should be kept private
class SocketIntance {
  constructor(params) {
    this.customerHandlers = {};
    this.customerHandlers.onMessageHandler = params.onMessageHandler || noop;
    this.customerHandlers.onOpenHandler = params.onOpenHandler || noop;
    this.customerHandlers.onCloseHandler = params.onCloseHandler || noop;
    this.customerHandlers.onErrorHandler = params.onErrorHandler || noop;
    this.customerHandlers.onConnectingHandler =
      params.onConnectingHandler || noop;
    this.customerHandlers.onRefusedHandler = params.onRefusedHandler || noop;

    this.reconnect = debounce(this.reconnect.bind(this), reconnectDelayMs);

    this.id = Date.now();

    this.socket = null; // reference to treconnectionAttemptshe actual ws instance
    this.reconnectionAttempts = 0; // how maany times this handler has been connecting
    this.heartbeatInterval = null; // pointer to an interval
    this.reconnectTimeout = null;
    this.outGoingMail = [];
    this.idleTimer = new IdleTime(
      this.onIdleTimeout.bind(this),
      this.onUserInteraction.bind(this),
      heartBeatFrequencyMs
    );
    this.user_interaction_found = true;
  }

  /**
   * Wrapper functions for standard websocket action handlers
   */
  onopen(evt) {
    // Clears reconnection timeout and attempts
    clearTimeout(this.reconnectTimeout);
    this.reconnectionAttempts = 0;

    this.customerHandlers.onOpenHandler(evt);
    this.doHeartBeat();

    let data;
    // Flush the outgoing mail on connected
    while ((data = this.outGoingMail.pop())) {
      this.send(data);
    }
  }

  onclose(evt) {
    // only do this when the connection is actually closed
    // and no other instance moved the socket to"connecting", don't even bother
    // re-connecting when you were in process of connecting, will bugs (DEV-5266)
    if (this.socket && this.socket.readyState === WebSocketStatus.CLOSED) {
      // Only do this if the instance was not destroyed
      const details = {
        attempts: this.reconnectionAttempts,
        interval: this.increasingReconnectDelayMs,
      };

      this.customerHandlers.onCloseHandler(evt, details);

      // If connection was closed in a state other than close normal or by browser
      if (evt.code !== 1000 && evt.code !== 1006) {
        this.customerHandlers.onRefusedHandler(evt, details);
      }
    }

    // Only if it was closed by abnormally by the browser, then try to reconnect
    if (evt.code === 1006) {
      this.reconnect();
    }
  }

  onerror(evt) {
    this.customerHandlers.onErrorHandler(evt);
  }

  onmessage(evt) {
    // Parse the JSON message received on the websocket
    try {
      const evtParsed = JSON.parse(evt.data);
      this.customerHandlers.onMessageHandler(evtParsed);
    } catch (e) {
      // for non parseable responses reported in T5513
      this.reportErrorToRollbar(e);
    }
  }

  // Creates a new websocket and adds the event handlers passed in the consutrctor
  connect() {
    // Creates a new websocket connection only if none has been created
    // or the existing is closed
    if (
      !this.socket ||
      (this.socket.readyState !== WebSocketStatus.OPEN &&
        this.socket.readyState !== WebSocketStatus.CONNECTING)
    ) {
      try {
        this.customerHandlers.onConnectingHandler();

        this.socket = APIcall.generateWebSocket(
          endpointGenerator.getWSServer()
        );

        this.socket.onmessage = this.onmessage.bind(this);
        this.socket.onopen = this.onopen.bind(this);
        this.socket.onclose = this.onclose.bind(this);
        this.socket.onerror = this.onerror.bind(this);

        logger('New websocket connection by SocketInstance', this.id);
      } catch (e) {
        this.reportErrorToRollbar(e);
        this.customerHandlers.onErrorHandler(e);
      }
    }
  }

  reconnect() {
    logger('Asked to reconnect SocketInstance', this.id);
    clearTimeout(this.reconnectTimeout); // Clearing timeout if called already

    if (this.reconnectionAttempts === 0) {
      this.increasingReconnectDelayMs = reconnectDelayMs;
    }

    this.reconnectTimeout = setTimeout(() => {
      this.increasingReconnectDelayMs *= reconnectMultiplier;
      logger('Reconnecting, try number ', this.reconnectionAttempts);
      this.connect();
    }, this.increasingReconnectDelayMs);

    this.reconnectionAttempts++;
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null; // destroying this reference to avoid
      clearInterval(this.heartbeatInterval);
      this.idleTimer.deactivate();
    }
  }

  send(data) {
    if (this.socket && this.socket.readyState === WebSocketStatus.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      // If connection turns out to be closed while heartbeating or any other action
      // That means that the connection was closed without the client or server notifying it
      // and we have to treated as a connection close
      this.customerHandlers.onCloseHandler();

      // store any data send while disconnected in a cache
      this.outGoingMail.push(data);

      this.reconnect();
    }
  }

  onIdleTimeout() {
    this.user_interaction_found = false;
  }

  onUserInteraction() {
    this.user_interaction_found = true;
  }

  doHeartBeat() {
    // Clears any previous interval that might be running
    clearInterval(this.heartbeatInterval);
    this.idleTimer.activate();

    const heartbeatMsg = {
      body: {
        user_interaction_found: this.user_interaction_found,
      },
      request: endpointGenerator.genPath('espUser.presence'),
    };

    if (this.socket && this.socket.readyState === WebSocketStatus.OPEN) {
      // Sends a continue message to prevent the browser from disconnecting the websocket
      this.heartbeatInterval = setInterval(() => {
        this.send({
          ...heartbeatMsg,
          body: { user_interaction_found: this.user_interaction_found },
        });
        this.idleTimer.activate();
      }, heartBeatFrequencyMs);
    }
  }

  reportErrorToRollbar(e) {
    if (window.Rollbar) {
      // reporting actual legitime important errors
      window.Rollbar.error(e);
    }
  }
}

export default SocketIntance;
