import React, { PureComponent } from 'react';
import ImmutableProps from 'react-immutable-proptypes';
import { List } from 'immutable';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import BaristaInboxRow, { caseInboxType } from './BaristaInboxesRow';

class BaristaInboxesTable extends PureComponent {
  static propTypes = {
    baristaTestConnectionSent: PropTypes.bool,

    baristaTestConnectionSuccess: PropTypes.bool,

    connectingGmailAccount: PropTypes.string,

    data: PropTypes.arrayOf(caseInboxType),

    deleteBaristaInbox: PropTypes.func.isRequired,

    departmentList: ImmutableProps.list,

    isNewFormInbox: PropTypes.bool,

    isOnlyTestingConnection: PropTypes.bool,
    reopenNewInboxModal: PropTypes.func,

    setupFirstInbox: PropTypes.func,

    terminateEmailTestConnection: PropTypes.func.isRequired,

    testConnection: PropTypes.func.isRequired,
    testedBox: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  static defaultProps = {
    baristaTestConnectionSent: false,
    baristaTestConnectionSuccess: false,
    data: [],
    departmentList: List(),
    isNewFormInbox: false,
    isOnlyTestingConnection: false,
    setupFirstInbox: noop,
    // isConnecting: false,
  };

  renderEmptyState = () => {
    const { setupFirstInbox } = this.props;
    return (
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan='4' textAlign='center'>
            <h1>{'No Inboxes Configured'}</h1>
            <p>
              {
                'Setup a Barista inbox to deflect requests by scanning incoming questions for a specific topic/department'
              }
            </p>
            {setupFirstInbox()}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    );
  };

  render() {
    const {
      data,
      baristaTestConnectionSent,
      deleteBaristaInbox,
      departmentList,
      baristaTestConnectionSuccess,
      connectingGmailAccount,
      isNewFormInbox,
      isOnlyTestingConnection,
      reopenNewInboxModal,
      terminateEmailTestConnection,
      testConnection,
      testedBox,
    } = this.props;
    const inboxesWithCredentials = [];
    return (
      <Table style={{ marginTop: '3.3em' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Inbox'}</Table.HeaderCell>
            <Table.HeaderCell>{'Last Email Scanned'}</Table.HeaderCell>
            <Table.HeaderCell>{'Service Department'}</Table.HeaderCell>
            <Table.HeaderCell>{'Actions'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {isEmpty(data) ? (
          this.renderEmptyState()
        ) : (
          <Table.Body>
            {data.map((caseBox) => {
              if (caseBox.credentials) {
                inboxesWithCredentials.push(true);
                return (
                  <BaristaInboxRow
                    baristaTestConnectionSent={baristaTestConnectionSent}
                    baristaTestConnectionSuccess={baristaTestConnectionSuccess}
                    caseInbox={caseBox}
                    connectingGmailAccount={connectingGmailAccount}
                    deleteBaristaInbox={deleteBaristaInbox}
                    departmentList={departmentList}
                    isNewFormInbox={isNewFormInbox}
                    isOnlyTestingConnection={isOnlyTestingConnection}
                    key={caseBox.credentials?.eid}
                    reopenNewInboxModal={reopenNewInboxModal}
                    terminateEmailTestConnection={terminateEmailTestConnection}
                    testConnection={testConnection}
                    testedBox={testedBox}
                  />
                );
              }
              return null;
            })}
          </Table.Body>
        )}
        {!isEmpty(data) &&
          inboxesWithCredentials.length === 0 &&
          this.renderEmptyState()}
      </Table>
    );
  }
}
export default BaristaInboxesTable;
