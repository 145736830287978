const api_catalog_v0_1_citem = {
  results: [
    {
      assigned_to: 27,
      card_template: 2,
      eid: '577b8e17-b7c9-42da-bb20-0137a29e2cfc',
      id: 753,
      items: {
        results: [
          {
            bundle: null,
            cart: 753,
            eid: '6d9e34be-c7a2-47fb-938e-bbc76412d8f9',
            id: 1212,
            item_status: 'CREATED',
            product: 2203,
            product_name: 'AAA',
            quantity: 1,
            url:
              'https://release.qa.espressive.com/api/catalog/v0.1/citem/1212/',
            weight: 1,
          },
        ],
      },
      num_items: 1,
      num_sum_items: 1,
      product: 999,
      ref_num: null,
      requested_for: 27,
      requester: 27,
      status: 'CREATED',
      url: 'https://release.qa.espressive.com/api/catalog/v0.1/cart/753/',
    },
  ],
};

export default api_catalog_v0_1_citem;
