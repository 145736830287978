import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { NavLink } from 'react-router-dom';

import {
  Button,
  Divider,
  Form,
  Grid,
  Label,
  List,
  Message,
} from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import _ from 'lodash';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// utils
import OnboardingActivityTemplateDetailController from '../controllers/OnboardingActivityTemplateDetailController';
import uiPathGenerator from '../../utils/uiPathGenerator';
import OnboardActivityTemplateTypes from '../../globals/OnboardActivityTemplateTypes';
import OnboardActivitiesCategory from '../../globals/OnboardActivitiesCategory';
import ActivityRecipients, {
  ActivityTypes,
  isPersonToContact,
} from '../../globals/ActivityRecipients';
import fetchConfiguration from '../../../globals/commonAPICalls/fetchConfiguration';

// molecules
import ValidatedForm from './ValidatedForm';
import FormInputText from './FormInputText';
import FormInputSelect from './FormInputSelect';
import FormInputTextarea from './FormInputTextarea';
import FormInputCheckbox from './FormInputCheckbox';
import IconSelectModal from './IconSelectModal';
import BaristaIntentLookup from '../molecules/BaristaIntentLookup';

const FORM = 'OnboardTemplateForm';

const SAVE_DELAY = 100;

const CONVERSATION_TYPE = {
  key: 'CONVERSATION',
  text: 'Conversation',
  value: 'BARISTA_CONVERSATION',
};
class OnboardingActivityTemplateDetail extends PureComponent {
  static propTypes = {
    changeCategory: PropTypes.func,
    changeStatus: PropTypes.func,
    copyTemplate: PropTypes.func,
    createTemplate: PropTypes.func,
    formErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    formValidation: PropTypes.func,
    formValues: ImmutablePropTypes.map,
    isLoading: PropTypes.bool,
    isPristine: PropTypes.bool,
    isValid: PropTypes.bool,
    loadEmptyForm: PropTypes.func,
    loadTemplate: PropTypes.func,
    template: ImmutablePropTypes.map,
    templateID: PropTypes.string,
    updateTemplate: PropTypes.func,
  };

  static defaultProps = {
    changeCategory: _.noop,
    changeStatus: _.noop,
    copyTemplate: _.noop,
    createTemplate: _.noop,
    formErrors: null,
    formValidation: _.noop,
    formValues: fromJS({}),
    isLoading: false,
    isPristine: false,
    isValid: false,
    loadEmptyForm: _.noop,
    loadTemplate: _.noop,
    template: fromJS({}),
    templateID: null,
    updateTemplate: _.noop,
  };

  state = {
    // OnboardActivityTemplateTypes: will be OnboardActivityTemplateTypes but can be extended with an extra entry
    activityTypes: [],
  };

  componentDidMount() {
    const { templateID, loadTemplate, loadEmptyForm, isLoading } = this.props;
    if (templateID === 'new') {
      loadEmptyForm();
    } else if (!isLoading) {
      loadTemplate(templateID);
    }

    // Default values, may be extended below
    this.setState({
      activityTypes: OnboardActivityTemplateTypes,
    });

    // DEV-16688 DEMO
    // Adds a new template type value
    fetchConfiguration({
      key: 'var.enable_onboarding_conversation_type',
    }).then((res) => {
      // Config is boolean, so it must be true
      if (res.value) {
        const { changeCategory } = this.props;
        // Set this hardcoded category
        changeCategory('Reach Out or Chat With');

        this.setState({
          activityTypes: [...OnboardActivityTemplateTypes, CONVERSATION_TYPE],
        });
      }
    });
  }

  handleCategoryChange = (icon) => {
    const { changeCategory } = this.props;
    changeCategory(icon.get('name'));
  };

  saveForm = _.debounce(() => {
    const {
      templateID,
      isLoading,
      formValues,
      isValid,
      createTemplate,
      isPristine,
      updateTemplate,
    } = this.props;
    if (templateID === 'new') {
      if (isValid && !isPristine) {
        createTemplate(formValues);
      }
    } else if (!isLoading) {
      if (isValid && !isPristine) {
        updateTemplate(templateID, formValues);
      }
    }
  }, SAVE_DELAY);

  handleChangeTemplateStatus = () => {
    const { changeStatus, templateID, template, isLoading } = this.props;
    const status = template.get('status') === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE';
    if (!isLoading) {
      changeStatus(templateID, status);
    }
  };

  handleCopyTemplate = () => {
    const { template, copyTemplate, isLoading } = this.props;
    if (!isLoading) {
      copyTemplate({
        template,
      });
    }
  };

  handleSubmit = () => {
    this.saveForm();
  };

  handlePreventSubmit = () => {
    // prevent redux-form submit
  };

  getError = (key) => {
    const { formErrors } = this.props;
    const newFormErrors = formErrors || {};
    return newFormErrors[key] || null;
  };

  render() {
    const {
      templateID,
      formValues,
      isLoading,
      template,
      formValidation,

      isPristine,
      formErrors,
    } = this.props;

    const { activityTypes } = this.state;

    const schedules = template.get('schedule_with_interval') || fromJS([]);
    const filteredSchedules = schedules
      .groupBy((s) => s.get('id'))
      .map((x) => x.first()); // TODO the api returns duplicated schedules BE task T4275

    const schedulesList = filteredSchedules.map((schedules) => ({
      as: NavLink,
      description: schedules.get('schedule_description'),
      header: schedules.get('schedule_name'),
      key: `${templateID}-${schedules.get('schedule_id')}`,
      to: uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.schedule',
        {
          scheduleID: schedules.get('schedule_id'),
        }
      ),
    }));

    const isConversationType =
      formValues.get('activity_type') === CONVERSATION_TYPE.value;

    return (
      <ValidatedForm
        className='onboarding-activity-template-detail'
        form={FORM}
        intl={intl}
        loading={isLoading}
        onSubmit={this.handlePreventSubmit}
        validate={formValidation}
      >
        {templateID !== 'new' ? (
          <Form.Group className='button-actions' grouped>
            <Label
              color={template.get('status') === 'ACTIVE' ? 'green' : null}
              content={template.get('status')}
              style={{
                float: 'left',
                marginTop: '.5em',
              }}
            />
            <Button
              basic
              content={
                template.get('status') === 'ACTIVE' ? 'ARCHIVE' : 'REACTIVATE'
              }
              loading={isLoading}
              onClick={this.handleChangeTemplateStatus}
            />
            <Button
              basic
              content='DUPLICATE'
              loading={isLoading}
              onClick={this.handleCopyTemplate}
            />
            <Button
              basic
              content='SAVE'
              disabled={isPristine || Boolean(formErrors)}
              loading={isLoading}
              onClick={this.handleSubmit}
            />
          </Form.Group>
        ) : (
          <Form.Group className='button-actions' grouped>
            <Button
              basic
              content='SAVE'
              disabled={isPristine || Boolean(formErrors)}
              loading={isLoading}
              onClick={this.handleSubmit}
            />
          </Form.Group>
        )}

        <Grid divided>
          <Grid.Row>
            <Grid.Column width={10}>
              <Field
                component={FormInputText}
                hidden
                name='category'
                type='hidden'
              />

              <Field
                component={FormInputSelect}
                formError={this.getError('activity_recipient')}
                label='Activity Recipient'
                name='activity_recipient'
                options={ActivityRecipients}
                placeholder='Select one...'
                search
              />

              <Field
                component={FormInputSelect}
                formError={this.getError('activity_type')}
                label='Activity Type'
                name='activity_type'
                options={activityTypes}
                placeholder='Select one...'
                search
              />

              {isConversationType ? (
                <BaristaIntentLookup
                  label={intl.formatMessage({ id: 'label.target_intent' })}
                  name='intent'
                  placeholder={intl.formatMessage({
                    id: 'label.choose_target_intent',
                  })}
                  required
                  value={formValues.get('intent')}
                />
              ) : null}

              <Field
                component={FormInputText}
                formError={this.getError('title')}
                label='Activity Title'
                name='title'
                placeholder='Title that will display on the activity card.'
              />
            </Grid.Column>

            <Grid.Column width={6}>
              {/** DEV-16688 For conversation: no image selection available (home feed cards for ticket do not support this)  */}
              {!isConversationType ? (
                <IconSelectModal
                  icons={OnboardActivitiesCategory}
                  onItemSelect={this.handleCategoryChange}
                  selectedItem={formValues.get('category')}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Field
          component={FormInputTextarea}
          formError={this.getError('private_notes')}
          label='Activity Private Notes'
          name='private_notes'
          rows={5}
        />

        <Field
          component={FormInputTextarea}
          formError={this.getError('description')}
          label='Activity Description'
          name='description'
          rows={5}
        />

        <Field
          component={FormInputCheckbox}
          formError={this.getError('enable_deadline')}
          label='Enable deadline'
          name='enable_deadline'
        />

        {Boolean(formValues.get('enable_deadline')) && (
          <Form.Group widths={'equal'}>
            <Field
              component={FormInputText}
              formError={this.getError('deadline_days')}
              label={'Days'}
              name='deadline_days'
              type='number'
            />

            <Field
              component={FormInputText}
              formError={this.getError('deadline_hours')}
              label={'Hours'}
              name='deadline_hours'
              type='number'
            />
          </Form.Group>
        )}

        {isPersonToContact(formValues.get('activity_type')) && (
          <Field
            component={FormInputSelect}
            formError={this.getError('person_to_contact')}
            label='Person to contact'
            name='person_to_contact'
            options={ActivityRecipients}
            placeholder='Select one...'
            search
          />
        )}

        {formValues.get('activity_type') === ActivityTypes.WEB_LINK && (
          <Field
            component={FormInputText}
            formError={this.getError('web_link')}
            label='Web Link'
            name='web_link'
          />
        )}

        <Divider horizontal>{`Schedules (${schedulesList.size})`}</Divider>
        {schedulesList ? (
          <List divided items={schedulesList.toArray()} />
        ) : (
          <Message
            content='This template is not currently used in any activity schedules.'
            info
          />
        )}
      </ValidatedForm>
    );
  }
}

const OnboardingActivityTemplateDetailTest = OnboardingActivityTemplateDetail;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardingActivityTemplateDetail = OnboardingActivityTemplateDetailController(
  OnboardingActivityTemplateDetail
);

export { OnboardingActivityTemplateDetailTest };
export default OnboardingActivityTemplateDetail;
