import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  Button,
  Container,
  Form,
  Icon,
  Input,
  Message,
} from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Controller
import TaskPickerController from './TaskPickerController';

class TaskPicker extends PureComponent {
  static propTypes = {
    // currentSelectedTaskId : PropTypes.oneOfType([
    //   PropTypes.number,
    //   PropTypes.string,
    // ]),

    isLoadingTask: PropTypes.bool,

    loadTask: PropTypes.func.isRequired,
    task: ImmutablePropTypes.map,
  };

  static defaultProps = {
    // currentSelectedTaskId : null,
    isLoadingTask: false,
    task: null,
  };

  state = {
    currentSelectedTaskId: '',
  };

  handleLoadTask = () => {
    const { currentSelectedTaskId } = this.state;
    const { loadTask } = this.props;
    loadTask(currentSelectedTaskId);
  };

  handleTaskInputChange = (event) => {
    this.setState({
      currentSelectedTaskId: event.target.value,
    });
  };

  render() {
    const { isLoadingTask, task } = this.props;
    const { currentSelectedTaskId } = this.state;

    return (
      <Form
        loading={isLoadingTask}
        style={{
          backgroundColor: '#fff',
          borderBottom: '1px solid rgba(0,0,0,.15)',
          boxShadow: '0 0 1em rgba(0,0,0,.08)',
          marginBottom: '2rem',
          padding: '1rem 1rem 0',
        }}
      >
        <Container>
          <Form.Group>
            <Form.Field width={4}>
              <h1
                style={{
                  fontSize: '22px',
                }}
              >
                <Icon name='globe' />
                {'Intl'}
              </h1>

              <label htmlFor=''>{'Task ID'}</label>
              <Input
                labelPosition='right'
                onChange={this.handleTaskInputChange}
                placeholder='<id>'
              >
                <input defaultValue={currentSelectedTaskId} type='text' />
                <Button
                  className='label'
                  color='green'
                  content='Go'
                  onClick={this.handleLoadTask}
                />
              </Input>
            </Form.Field>

            <Form.Field width={12}>
              {task ? (
                <Message
                  color='blue'
                  header={task.get('name')}
                  list={[`URL: ${task.get('url')}`, `EID: ${task.get('eid')}`]}
                />
              ) : (
                <Message
                  color='blue'
                  content={"If you don't know it then go to Blobifier"}
                  header='Start by entering a task id'
                  icon='checkmark'
                  info
                />
              )}
            </Form.Field>
          </Form.Group>
        </Container>
      </Form>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
TaskPicker = TaskPickerController(TaskPicker);

export default TaskPicker;
