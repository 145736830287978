// import PropTypes                from 'prop-types';
import React, { PureComponent } from 'react';
import { Header, Segment } from 'semantic-ui-react';

class IntegrationServicenowIncidents extends PureComponent {
  render() {
    return (
      <Segment attached='bottom'>
        <Header content='IntegrationServicenowIncidents' />
      </Segment>
    );
  }
}

export default IntegrationServicenowIncidents;
