import { Dropdown, Form, Icon } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import fetchTeams from './api/fetchClassifiedTeam';
import PropTypes from 'prop-types';

const propTypes = {
  departmentName: PropTypes.string,
  handleSelection: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onlyRead: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.number,
};

const ServiceDepartmentClassifiedTeamDropdown = ({
  handleSelection = () => {},
  name,
  value,
  label = 'Team Classification',
  placeholder = 'Select one',
  onlyRead,
  departmentName,
}) => {
  const [teams, setTeams] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const TIMEOUT = 400;

  const fetchData = useCallback(
    (searchQuery) => {
      const fetchItems = async () => {
        setLoading(true);
        try {
          setTeams([]);

          if (value && !searchQuery) {
            const { body } = await fetchTeams({
              departmentName,
              id: value,
            });
            setTeams(body.results);

            return;
          } else {
            if (searchQuery) {
              const { body } = await fetchTeams({
                departmentName,
                searchQuery,
              });
              setTeams(body.results);
            }

            return;
          }
        } catch (error) {
          // eslint-disable-next-line no-console -- debugginh purposes
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchItems();
    },
    [departmentName, value]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const doSearch = debounce((searchQuery) => {
    fetchData(searchQuery?.trim() || '');
  }, TIMEOUT);

  const handleSearch = useCallback(
    (e, { searchQuery }) => {
      doSearch(searchQuery);
    },
    [doSearch]
  );
  const handleChange = useCallback(
    (e, { value }) => {
      handleSelection(name, value);
    },
    [handleSelection, name]
  );

  const getTeam = () => {
    if (!value) {
      return '';
    }
    const [team] = teams.filter((team) => team.id === value);
    if (!team) {
      return '';
    }
    return team.name;
  };

  if (onlyRead) {
    return (
      <Form.Field>
        <label>{label}</label>
        <p>{getTeam()}</p>
      </Form.Field>
    );
  }

  const options = teams?.map((team) => {
    return {
      content: (
        <div>
          <span>{team.name}</span>
        </div>
      ),
      key: team.id,
      text: team.name,
      value: team.id,
    };
  });

  if (!value) {
    return (
      <Form.Field>
        <Form.Dropdown
          fluid
          icon={<Icon name='search' style={{ top: '0.4em' }} />}
          label={label}
          loading={isLoading}
          onChange={handleChange}
          onSearchChange={handleSearch}
          options={options}
          placeholder={placeholder}
          search
          selection
          value={value}
        />
      </Form.Field>
    );
  }

  // current version of semantic ui react does not support clearable prop yet (until semantic css 2.4.0 version) :(
  // so we need to do this workaround
  return (
    <div>
      <Form.Field>
        <label htmlFor='search_dropdown'>{label}</label>
        <Dropdown
          fluid
          icon={''}
          id='search_dropdown'
          loading={isLoading}
          onChange={handleChange}
          onSearchChange={handleSearch}
          options={options}
          placeholder={placeholder}
          search
          selection
          value={value}
        />
        <Icon
          name='x'
          onClick={handleChange}
          style={{
            cursor: 'pointer',
            left: '91%',
            position: 'relative',
            top: '-29px',
            zIndex: 9,
          }}
        />
      </Form.Field>
    </div>
  );
};

ServiceDepartmentClassifiedTeamDropdown.propTypes = propTypes;

export default ServiceDepartmentClassifiedTeamDropdown;
