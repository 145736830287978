import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsRightArrow extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path d='M55.582 0v36.984H0V0z' id='a' />
        </defs>
        <g fill={color} fillRule='evenodd' transform='matrix(-1 0 0 1 76 30)'>
          <mask fill='#fff' id='b'>
            <use xlinkHref='#a' />
          </mask>
          <path
            d='M52.582 15.93H10.396l11.35-10.753C22.95 4.037 23 2.14 21.86.937 20.724-.267 18.825-.32 17.62.82L.936 16.628c-.61.577-.95 1.385-.936 2.224.013.84.378 1.636 1.005 2.195l17.06 15.178c.573.51 1.285.76 1.994.76.822 0 1.644-.34 2.24-1.007 1.1-1.238.99-3.133-.25-4.235L11.026 21.93h41.556c1.657 0 3-1.342 3-3 0-1.656-1.343-3-3-3'
            fill='#F00'
            mask='url(#b)'
          />
        </g>
      </svg>
    );
  }
}

export default CardsRightArrow;
