const WidgetTypes = {
  AUTOMATION_ESCALATIONS: 'automation_escalations',
  AUTOMATION_RATE: 'automation_rate',
  AUTOMATION_REPORTS_BY_STATUS: 'automation_reports_by_status',
  AUTOMATION_STATS: 'automation_stats',
  AUTOMATION_SUCCESS_RATE: 'automation_success_rate',
  DEFLECTED: 'deflected',
  DEFLECTION_BY_KB: 'deflection_by_kb',
  DEFLECTION_RATE: 'deflection_rate',
  DEFLECTION_STATS: 'deflection_stats',
  DEPRECATED_POSSIBLE_ABANDONED_STATS_WIDGET: 'possible_abandoned_stats_widget',
  DOLLARS_SAVED: 'dollars_saved',
  FEEDBACK: 'feedback',
  FEEDBACK_DETAILS: 'feedback_details',
  GEO_DISTRIBUTION: 'geo_distribution',
  HELPFUL_FEEDBACK: 'helpful_feedback',
  INTENTS_DRIVING_AUTOMATION_REPORTS: 'top_intents_driving_automation',
  INTERACTIONS_BY_SOURCE: 'interactions_by_source',
  INTERACTIONS_CHANNEL: 'interactions_channel',
  INTERACTIONS_OS_CLIENT: 'interactions_os_client',
  INTERACTION_STATS: 'interaction_stats',
  KB_DEFLECTIONS: 'kb_deflections',
  MOBILE_INTERACTIONS: 'mobile_interactions',
  POSSIBLE_ABANDONED: 'possibly_abandoned',
  POSSIBLE_ABANDONED_RATE: 'possibly_abandoned_rate',
  POSSIBLE_ABANDONED_STATS: 'possibly_abandoned_stats',
  SUCCESSFUL_AUTOMATIONS: 'successful_automations',
  TOP_CONVERSATION_AREAS: 'interactions_by_matched_archetype',
  TOP_INTENTS_DRIVING_DEFLECTIONS: 'intents_driving_deflection',
  TOP_INTENTS_UNANSWERED_WITHOUT_KB: 'top_unanswered_questions_without_kb',
  TOP_INTENTS_UNANSWERED_WITH_KB: 'top_answered_questions_with_kb',
  TOP_INTERACTIONS_BY_TOPIC: 'interactions_by_topic',
  TOP_MATCHED_INTENTS: 'top_matched_intents',
  TOP_UNANSWERED_QUESTIONS: 'top_unanswered_questions',
  TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP: 'interactions_by_keywords_group',
  TOP_UNANSWERED_QUESTIONS_LIST: 'top_unanswered_questions_list',
  TOTAL_AUTOMATIONS: 'total_automations',
  TOTAL_DEFLECTIONS: 'total_deflections',
  TOTAL_INTERACTIONS: 'total_interactions',
  TRANSFERRED_TO_AGENT: 'transferred_to_agent',
  UNIQUE_USERS: 'unique_users',
};

const WIDGETS_WITH_DRILL_DOWN = [
  WidgetTypes.TOTAL_INTERACTIONS,
  WidgetTypes.MOBILE_INTERACTIONS,
  WidgetTypes.DEFLECTION_BY_KB,
  WidgetTypes.KB_DEFLECTIONS,
  WidgetTypes.TOTAL_DEFLECTIONS,
  WidgetTypes.TRANSFERRED_TO_AGENT,
  WidgetTypes.POSSIBLE_ABANDONED,
  WidgetTypes.TOTAL_AUTOMATIONS,
  WidgetTypes.AUTOMATION_ESCALATIONS,
  WidgetTypes.AUTOMATION_SUCCESS_RATE,
  WidgetTypes.AUTOMATION_RATE,
  WidgetTypes.SUCCESSFUL_AUTOMATIONS,
];

const WIDGETS_TO_IGNORE = [WidgetTypes.DOLLARS_SAVED];
const widgetNeedsDrillDown = (widgetName) => {
  return WIDGETS_WITH_DRILL_DOWN.includes(widgetName);
};
export { WIDGETS_WITH_DRILL_DOWN, WIDGETS_TO_IGNORE, widgetNeedsDrillDown };
export default Object.freeze(WidgetTypes);
