import buildCompositeController from '../../utils/buildCompositeController';

import searchActions from '../../actions/searchActions';

const mapStateToProps = (state) => {
  const searchState = state.get('search');
  const contextName = searchState.get('contextName');

  return {
    contextName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setContextName(contextName) {
    dispatch(searchActions.setContextName(contextName));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
