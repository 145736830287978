import React, { cloneElement } from 'react';
import pt from 'prop-types';

import { HTTP_METHODS, useAPIGet } from 'esp-auth';
import { PROP_TYPES } from '../SectionList/__globals';
import { Loader } from '@espressive/cascara/private';
import { getCascaraModulesFromOptionsAPI } from '../endpointOptions-normalizers';

// Pick only the prop types that we are using from SectionList
const propTypes = {
  children: pt.oneOfType([pt.node], pt.arrayOf(pt.node)),
  endpointUrl: PROP_TYPES.endpointUrl,
};

const EndpointOptions = ({ children, ...props }) => {
  const { endpointUrl } = props;

  const { data: optionsAPI } = useAPIGet({
    method: HTTP_METHODS.OPTIONS,
    url: endpointUrl,
  });

  const endpointActions = optionsAPI?.data?.actions;

  // We have to do this because some endpoints do not return the same
  // property under `actions`. Some return an object with PUT and some
  // return an object with PATCH
  const options = endpointActions?.[Object.keys(endpointActions)[0]];

  // normalize metadata to Cascara modules
  const dataDisplay = getCascaraModulesFromOptionsAPI(options);

  // Once the Section components get updated to show a loading state, this can return the component directly again without the API call resolving
  return optionsAPI ? (
    cloneElement(children, { ...props, dataDisplay })
  ) : (
    <Loader />
  );
};

EndpointOptions.propTypes = propTypes;

export default EndpointOptions;
