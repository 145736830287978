import _ from 'lodash';
import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import faqThunks from '../../actions/faqThunks';
import workflowThunks from '../../actions/workflowThunks';
import { change } from 'redux-form/immutable';
import { TypeDefaults } from '../../globals/blocks/TypeSelectDepartmentCategory';

const mapStateToProps = (state, ownProps) => {
  let taskSelected;
  if (ownProps.query && _.hasIn(ownProps, 'query.taskId')) {
    taskSelected = state.getIn([
      'entities',
      'tasks',
      Number(ownProps.query.taskId),
    ]);
  }

  return {
    attributes: state.getIn(['workflowState', 'attributes']),
    beScratchData: state.getIn([
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
    ]),
    blocks: state.getIn(['workflowState', 'blob', 'blocks']),
    taskSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Use this to reset any previous data set
  forceResetscratch(beScratchData) {
    const synonym = beScratchData.get('synonym');
    dispatch(
      workflowThunks.updateTempDataBackEndScratch({
        new_entity: '',
        synonym: typeof synonym === 'string' ? '' : {}, // Reset with the correct type
      })
    );
  },

  setFaqBEScratch(blocks, forceReBuild, taskSelected) {
    return new Promise((resolve, reject) => {
      dispatch(faqThunks.setFaqBEScratch(forceReBuild, taskSelected))
        .then((beScratchData) => {
          // Search for selectDepartmentCategory block ID and update the value
          const block = blocks.find(
            (b) =>
              b.get('template').toLowerCase() ===
              TypeDefaults.template.toLowerCase()
          );
          if (block) {
            dispatch(
              change(
                'Form01',
                block.get('id'),
                beScratchData.getIn(['scratch.temp_data', 'category_faq'])
              )
            );
            resolve();
          } else if (!forceReBuild) {
            reject({
              error: new Error('No block has been find'),
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
