import { change } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import workflowThunks from '../../actions/workflowThunks';
// Selector
import getFAQServiceDeptIDSelected from '../../selectors/getFAQServiceDeptIDSelected';

const mapStateToProps = (state) => ({
  departmentId: getFAQServiceDeptIDSelected(state),
  isLoadingTeam: state.getIn([
    'caseMgmt',
    'serviceDepartmentTeam',
    'isLoading',
  ]),
  teamList: state.getIn(['caseMgmt', 'serviceDepartmentTeam', 'list']),
});

const mapDispatchToProps = (dispatch) => ({
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
  loadServiceTeamList(departmentID, search) {
    dispatch(caseMgmtThunks.loadServiceTeamByDepartment(departmentID, search));
  },
  setScratch(value) {
    dispatch(
      workflowThunks.updateFrontEndScratch({
        serviceTeam: value,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
