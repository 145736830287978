import _ from 'lodash';
import { fromJS } from 'immutable';

import APIcall from '../../../../app/js/v1/utils/APIcall';
import browserHistory from '../../../../app/js/v1/utils/browserHistory';
import endpointGenerator from '../../../../app/js/v1/utils/endpointGenerator';
import uiPathGenerator from '../../../../app/js/v1/utils/uiPathGenerator';
import workflowBrowserPageActions from './workflowBrowserPageActions';

const workflowBrowserPageThunks = {};

workflowBrowserPageThunks.createNewWorkflow = (data, cb = _.noop) => (
  dispatch
) => {
  dispatch(workflowBrowserPageActions.createNewWorkflowStart());

  APIcall.post({
    data: data.toJS(),
    error(err) {
      const { body } = err.response;
      cb(err);
      dispatch(workflowBrowserPageActions.createNewWorkflowError(body.name[0]));
    },
    success(res) {
      dispatch(workflowBrowserPageActions.createNewWorkflowSucess());
      cb();
      browserHistory.push(
        uiPathGenerator.genPath('editor.workflow', {
          workflowID: res.body.id,
        })
      );
    },
    token: true,
    url: endpointGenerator.genPath('workflow.workflows'),
  });
};

workflowBrowserPageThunks.editWorkFlow = (
  data,
  existingWorkflow,
  cb = _.noop
) => (dispatch) => {
  dispatch(workflowBrowserPageActions.editWorkflowStart());
  APIcall.put({
    data: data.toJS(),
    error(err) {
      const { body } = err.response;
      cb(err);
      dispatch(workflowBrowserPageActions.editWorkflowError(body.name[0]));
    },
    success(res) {
      dispatch(workflowBrowserPageActions.editWorkflowSucess(res.body));
      cb();
    },
    token: true,
    url: existingWorkflow.url,
  });
};

workflowBrowserPageThunks.loadWorkflows = () => (dispatch) => {
  dispatch(workflowBrowserPageActions.loadWorkflowsStart());

  APIcall.get({
    error(err) {
      dispatch(workflowBrowserPageActions.loadWorkflowsError(err));
    },
    query: {
      limit: 200,
    },
    // We will fix that later => https://espressive.atlassian.net/browse/DEV-5691
    success(res) {
      const { body } = res;
      const workflows = fromJS(body.results);
      dispatch(workflowBrowserPageActions.loadWorkflowsSuccess(workflows));
    },

    token: true,

    url: endpointGenerator.genPath('workflow.workflows'),
  });
};

workflowBrowserPageThunks.goToWorkflowPage = (workflowID) => () => {
  const url = uiPathGenerator.genPath('editor.workflow', {
    workflowID,
  });
  browserHistory.push(url);
};

workflowBrowserPageThunks.removeNewWorkflowFromError = () => (dispatch) => {
  dispatch(workflowBrowserPageActions.removeFormError());
};

export default workflowBrowserPageThunks;
