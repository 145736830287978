import APIcall from '../utils/APIcall';
import chatActions from './chatActions';
import endpointGenerator from '../utils/endpointGenerator';

const widgetThunks = {};

widgetThunks.postBaristaWidgetResponse = (
  channelID,
  messageID,
  widgetID,
  data
) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.post({
      data,
      error(err) {
        reject(err);
      },
      success({ body }) {
        dispatch(chatActions.updateWidgetSuccess(channelID, messageID, body));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('widgets.instance.action', {
        widgetID,
      }),
    });
  });

export default widgetThunks;
