import endpointGenerator from '../utils/endpointGenerator';
import APIcall from '../utils/APIcall';
import appVersionActions from './appVersionActions';
import getClientID from '../utils/getClientID';

const systemVersionThunks = {};

systemVersionThunks.getVersion = (isEncodeForced) => (dispatch, getState) => {
  new Promise((resolve, reject) => {
    const environment = getState().getIn(['appUI', 'rollbarEnvironment']);
    const url = endpointGenerator.genPath('espBEVersion');
    dispatch(appVersionActions.systemVersionStart());
    APIcall.get({
      error(e) {
        const errorMsg = 'Unable to get API version.';
        dispatch(appVersionActions.systemVersionFail(errorMsg));
        reject(e);
      },
      preventShowError: true,
      success(response) {
        const { body } = response;
        const { BRANCH, BUILD_NUMBER } = body.django;
        const systemId =
          BRANCH && BUILD_NUMBER ? `${BRANCH}/${BUILD_NUMBER}` : null;
        dispatch(
          appVersionActions.systemVersionSuccess(
            systemId,
            isEncodeForced,
            environment
          )
        );
        resolve(BRANCH);
      },
      token: false,
      url,
    });
  });
};

// ToDo: this probably be its own thunk, but since we're only doing 1 action,
// i'm going to reuse systemVersionThunks
systemVersionThunks.getClientVersion = (isEncodeForced) => (
  dispatch,
  getState
) => {
  new Promise((resolve, reject) => {
    const environment = getState().getIn(['appUI', 'rollbarEnvironment']);

    getClientID()
      .then((clientID) => {
        dispatch(
          appVersionActions.clientVersionId(
            clientID,
            isEncodeForced,
            environment
          )
        );
        resolve(clientID);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default systemVersionThunks;
