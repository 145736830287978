import Nodes from '../../../Nodes';
import ObjectTypes from '../../../ObjectTypes';

const nodesRules = {
  serialize(obj, children) {
    if (obj.object !== ObjectTypes.block) {
      return null;
    }

    switch (obj.type) {
      case Nodes.image: {
        return {
          file: obj.getIn(['data', 'src']),
          phrase: '',
          url: obj.getIn(['data', 'src']),
        };
      }
      case Nodes.embedVideo: {
        return {
          phrase: obj.getIn(['data', 'src']),
        };
      }

      case Nodes.nativeVideo: {
        return {
          file: obj.getIn(['data', 'src']),
          phrase: '',
          url: obj.getIn(['data', 'src']),
        };
      }

      case Nodes.file: {
        return {
          file: obj.getIn(['data', 'src']),
          fileName: obj.getIn(['data', 'fileName']),
          phrase: obj.getIn(['data', 'content']),
          url: obj.getIn(['data', 'src']),
        };
      }

      case Nodes.paragraph: {
        if (typeof children === 'string') {
          return {
            phrase: children,
          };
        }

        return children;
      }
      default:
        return null;
    }
  },
};

export default nodesRules;
