import React from 'react';
import PropTypes from 'prop-types';
import FormInputCheckbox from './FormInputCheckbox';
import { Field } from 'redux-form/immutable';

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const ValidatedCheckbox = ({ name, label, ...props }) => (
  <Field
    {...props}
    component={FormInputCheckbox}
    label={label}
    name={name}
    type='checkbox'
  />
);
ValidatedCheckbox.propTypes = propTypes;
export default ValidatedCheckbox;
