import CartStatuses from '../../app/js/v1/globals/CartStatuses';

const api_catalog_v0_1_cart = {
  results: [
    {
      id: 34,
      items: [
        {
          product: 23,
        },
        {
          product: 22,
        },
      ],
      status: CartStatuses.SUBMITTED,
    },
    {
      id: 56,
      items: [
        {
          product: 33,
        },
        {
          product: 32,
        },
      ],
      status: CartStatuses.SUBMITTED,
    },
    {
      id: 234,
      items: [
        {
          product: 43,
        },
        {
          product: 42,
        },
      ],
      status: CartStatuses.SUBMITTED,
    },
  ],
};

export default api_catalog_v0_1_cart;
