import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getTeamList = (state) =>
  state.getIn(['caseMgmt', 'serviceDepartmentTeam', 'list']);

const getUserList = (state) => state.getIn(['entities', 'users']);

const getSetviceDepartmentTeam = (teamList, userList) => {
  if (!teamList || teamList.isEmpty() || !userList || userList.isEmpty()) {
    return Immutable.List();
  }

  const newteamList = teamList.map((team) => {
    const teamLeadID = team.get('team_lead');

    if (teamLeadID) {
      return team.set('user', userList.get(teamLeadID));
    }

    return team;
  });

  return newteamList;
};

const selector = createSelector(
  [getTeamList, getUserList],
  getSetviceDepartmentTeam
);

export default selector;
