class FaqJsonSerializer {
  constructor(rules, stringRecord) {
    this.rules = [...rules];
    this.stringRecord = stringRecord;
  }

  serialize(state) {
    const { document } = state;
    const elements = document.nodes
      .map((node) => {
        if (node) {
          if (node.nodes) {
            return node.nodes.map((inlineNode) =>
              this.serializeNode(inlineNode, node)
            );
          }
          return this.serializeNode(node, document);
        }
        return this.serializeNode(
          {
            document: {
              nodes: [],
            },
          },
          document
        );
      })
      .toJSON();

    return elements;
  }
  deserialize(state) {
    const elements = state.map((line) => this.deserializeLine(line));
    return {
      document: {
        nodes: elements ? elements : [],
      },
    };
  }

  deserializeLine(line) {
    for (const rule of this.rules) {
      if (!rule.deserialize) {
        continue;
      }

      const ret = rule.deserialize(line);
      if (ret) {
        return ret;
      }
    }
    return null;
  }

  serializeNode(node, document) {
    if (node.object === 'text') {
      const leaves = node.getLeaves();

      return leaves.map((leave) => this.serializeLeaves(leave));
    }

    const children = node.nodes
      .map((childNode) => {
        const serialized = this.serializeNode(childNode, document);
        return (
          (serialized && serialized.join ? serialized.join('') : serialized) ||
          ''
        );
      })
      .join('');

    for (const rule of this.rules) {
      if (!rule.serialize) {
        continue;
      }
      const ret = rule.serialize(node, children, document);
      if (ret) {
        return ret;
      }
    }
    return null;
  }

  serializeLeaves(leaves) {
    const leavesText = leaves.text;
    const Record = this.stringRecord;
    const string = new Record({
      text: leavesText,
    });
    const text = this.serializeString(string);

    return leaves.marks.reduce((children, mark) => {
      for (const rule of this.rules) {
        if (!rule.serialize) {
          continue;
        }
        const ret = rule.serialize(mark, children);
        if (ret) {
          return ret;
        }
      }
      return null;
    }, text);
  }

  serializeString(string) {
    for (const rule of this.rules) {
      if (!rule.serialize) {
        continue;
      }
      const ret = rule.serialize(string, string.text);

      if (ret) {
        return ret;
      }
    }
    return null;
  }
}

export default FaqJsonSerializer;
