import EquipmentSearchResult from '../components/molecules/EquipmentSearchResult';
import DirectChatChannelResult from '../components/molecules/DirectChatChannelResult';
import ProductSearchResult from '../components/molecules/ProductSearchResult';
import ScopedChatChannelResult from '../components/molecules/ScopedChatChannelResult';
import UserSearchResult from '../components/molecules/UserSearchResult';
import CaseSearchResult from '../components/molecules/CaseSearchResult';

import SearchModels from './SearchModels';
import uiPathGenerator from './uiPathGenerator';

// Util
import ContextNames from '../globals/ContextNames';
/**
 * Context constants for search feature.
 */
const SearchContexts = [
  {
    appModel: SearchModels.USER,
    dotExpression: 'app.directory',
    fullResultsRoute: 'app.search.directory',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_directory',
    name: ContextNames.DIRECTORY,
    resultTemplate: UserSearchResult,
    routeToResult: 'app.directory.detail',
  },
  {
    appModel: SearchModels.USER,
    dotExpression: 'app.search.directory',
    isFullResultsMode: true,
    launchesFromRoute: 'app.directory',
    msg: 'message.search_placeholder_directory',
    name: ContextNames.DIRECTORY_FULL_RESULTS,
    resultTemplate: UserSearchResult,
    routeToResult: 'app.search.directory.result',
  },
  {
    appModel: SearchModels.CATALOG_PRODUCT,
    dotExpression: 'app.catalog',
    fullResultsRoute: 'app.search.catalog',
    isFullResultsMode: false,
    msg: 'message.search_all_products',
    name: ContextNames.CATALOG,
    resultTemplate: ProductSearchResult,
    routeToResult: 'app.catalog.detail',
  },
  {
    appModel: SearchModels.CATALOG_PRODUCT,
    dotExpression: 'app.search.catalog',
    isFullResultsMode: true,
    launchesFromRoute: 'app.catalog',
    msg: 'message.search_all_products',
    name: ContextNames.CATALOG_FULL_RESULTS,
    resultTemplate: ProductSearchResult,
    routeToResult: 'app.search.catalog.result',
  },
  {
    appModel: SearchModels.EQUIPMENT,
    dotExpression: 'app.equipment',
    fullResultsRoute: 'app.search.equipment',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_equipment',
    name: ContextNames.EQUIPMENT,
    resultTemplate: EquipmentSearchResult,
    routeToResult: 'app.equipment.detail',
  },
  {
    appModel: SearchModels.EQUIPMENT,
    dotExpression: 'app.search.equipment',
    isFullResultsMode: true,
    launchesFromRoute: 'app.equipment',
    msg: 'message.search_placeholder_equipment',
    name: ContextNames.EQUIPMENT_FULL_RESULTS,
    resultTemplate: EquipmentSearchResult,
    routeToResult: 'app.search.equipment.result',
  },
  {
    appModel: SearchModels.DIRECT_CHAT_CHANNEL,
    dotExpression: 'app.messages.direct',
    fullResultsRoute: 'app.search.directChats',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_direct_chats',
    name: ContextNames.DIRECT_CHATS,
    resultTemplate: DirectChatChannelResult,
    routeToResult: 'app.messages.navigation.direct.user',
  },
  {
    appModel: SearchModels.DIRECT_CHAT_CHANNEL,
    dotExpression: 'app.messages.navigation.direct',
    fullResultsRoute: 'app.search.directChats',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_direct_chats',
    name: ContextNames.DIRECT_CHATS,
    resultTemplate: DirectChatChannelResult,
    routeToResult: 'app.messages.navigation.direct.user',
  },
  {
    appModel: SearchModels.DIRECT_CHAT_CHANNEL,
    dotExpression: 'app.search.directChats',
    isFullResultsMode: true,
    launchesFromRoute: 'app.messages.direct',
    msg: 'message.search_placeholder_direct_chats',
    name: ContextNames.DIRECT_CHATS_FULL_RESULTS,
    resultTemplate: DirectChatChannelResult,
    routeToResult: 'app.search.directChats.result',
  },
  {
    appModel: SearchModels.SCOPED_CHAT_CHANNEL,
    dotExpression: 'app.messages.scoped',
    fullResultsRoute: 'app.search.scopedChats',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_scoped_chats',
    name: ContextNames.SCOPED_CHATS,
    resultTemplate: ScopedChatChannelResult,
    routeToResult: 'app.messages.navigation.scoped.channel',
  },
  {
    appModel: SearchModels.SCOPED_CHAT_CHANNEL,
    dotExpression: 'app.messages.navigation.scoped',
    fullResultsRoute: 'app.search.scopedChats',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_scoped_chats',
    name: ContextNames.SCOPED_CHATS,
    resultTemplate: ScopedChatChannelResult,
    routeToResult: 'app.messages.navigation.scoped.channel',
  },
  {
    appModel: SearchModels.SCOPED_CHAT_CHANNEL,
    dotExpression: 'app.search.scopedChats',
    isFullResultsMode: true,
    launchesFromRoute: 'app.messages.scoped',
    msg: 'message.search_placeholder_scoped_chats',
    name: ContextNames.SCOPED_CHATS_FULL_RESULTS,
    resultTemplate: ScopedChatChannelResult,
    routeToResult: 'app.search.scopedChats.result',
  },
  // TODO This one doesn't have a result template because we do the filtering client side
  {
    appModel: SearchModels.FEED_CARD,
    dotExpression: 'app.toDo',
    fullResultsRoute: null,
    isFullResultsMode: false,
    msg: 'message.search_placeholder_feed',
    name: ContextNames.FEED,
    resultTemplate: null,
    routeToResult: null,
  },

  // Cases. For what it seesm the first search that uses the generic typeahead endpoint
  {
    appModel: SearchModels.CASES,
    dotExpression: 'app.casesFeed',
    fullResultsRoute: 'app.search.cases',
    isFullResultsMode: false,
    msg: 'message.search_placeholder_cases',
    name: ContextNames.CASESFEED,
    resultTemplate: CaseSearchResult,
    routeToResult: 'app.casesFeed.detail',
  },
  {
    appModel: SearchModels.CASES,
    dotExpression: 'app.search.cases',
    isFullResultsMode: true,
    launchesFromRoute: 'app.casesFeed',
    msg: 'message.search_placeholder_cases',
    name: ContextNames.CASES_FULL_RESULTS,
    resultTemplate: CaseSearchResult,
    routeToResult: 'app.search.cases.result',
  },
];

const getSearchContext = (pathname) => {
  const searchContext = SearchContexts.find((context) =>
    uiPathGenerator.isPartialMatch(context.dotExpression, pathname)
  );

  return searchContext || null;
};

export { getSearchContext };

export default SearchContexts;
