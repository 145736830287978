import Immutable, { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

// This Initial state will change depending on the structure of the actual API
const INITIAL_STATE = fromJS({
  filters: {
    searchText: '',
    type: -1,
  },
  isLoading: false,
  locations: {
    count: 0,
    results: [],
  },
  types: Immutable.List(),
});

/**
 * Admin Locations reducer
 * @param state
 * @param action
 * @returns {*}
 */
const cartReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ADMIN_LOCATIONS_START: {
      return state.set('isLoading', true);
    }
    case actionTypes.ADMIN_LOCATIONS_SUCCESS: {
      return state.set('locations', action.locations).set('isLoading', false);
    }

    case actionTypes.ADMIN_LOCATIONS_END: {
      return state.set('isLoading', false);
    }

    case actionTypes.ADMIN_LOCATIONS_TYPES_START: {
      return state.set('isLoading', true);
    }
    case actionTypes.ADMIN_LOCATIONS_TYPES_SUCCESS: {
      return state.set('types', fromJS(action.types)).set('isLoading', false);
    }

    case actionTypes.ADMIN_LOCATIONS_TYPES_END: {
      return state.set('isLoading', false);
    }

    case actionTypes.ADMIN_LOCATIONS_SET_FILTER: {
      return state.set('filters', action.filters);
    }

    case actionTypes.ADMIN_CUSTOM_LOCATIONS_SUCCESS: {
      return state
        .set('isLoading', false)
        .setIn(
          ['locations', 'results', action.locationIndex, 'customLocations'],
          action.locations
        );
    }

    case actionTypes.ADMIN_LOCATIONS_SET_NOTE: {
      return state.setIn(
        ['locations', 'results', action.locationIndex, 'note'],
        action.note
      );
    }

    default: {
      return state;
    }
  }
};

export default cartReducer;
