/* eslint-disable sort-keys -- backend shape */
const data = {
  total_interactions: 12,
  total_unique_users: 4,
  total_deflections: 10,
  total_deflections_by_kb: 5,
  total_not_deflections: 1,
  total_empty_deflections: 1,
  deflection_precent: 0.9090909090909091,
  deflection_precent_by_kb: 0.45454545454545453,
  total_transferred_to_agent: 0,
  on_mobile: 0.0,
};
/* eslint-enable sort-keys */

export default data;
