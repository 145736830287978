import _APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';

const saveAuth = async (data) => {
  const url = endpointGenerator.genPath('authentication.authBackend');

  return _APIcall.post({
    data,
    token: true,
    url: url,
  });
};

export default saveAuth;
