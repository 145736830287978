const api_csv_v0_1_remote_server_info = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      business_object: 23,
      connection_timeout: 30,
      dns_name: 'files.google.com',
      eid: 'dfe8d651-437d-4a07-b650-e540ccc4a82b',
      id: 17,
      interval: 30,
      ip_address: null,
      password: 'cUMEqtYiEUWJn0yP4bFbqV00QNCE9GxPr43aDfX544Y=',
      periodicity: 'MINUTES',
      port_number: 80,
      protocol: 'SCP',
      remote_file_path: '/some/random/path/file.csv',
      start_times: [],
      sys_created_by: 'ricardo@espressive.com',
      sys_custom_fields: null,
      sys_date_created: '2017-08-31T18:25:50.607724Z',
      sys_date_updated: '2017-08-31T18:25:50.607700Z',
      sys_updated_by: 'ricardo@espressive.com',
      username: 'ericschmidt',
    },
  ],
};

export default api_csv_v0_1_remote_server_info;
