import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

const getBackendReportedProgress = (state) =>
  state.getIn(['workflowState', 'progress']);

const getTaskProgress = (state) =>
  state.getIn(['workflowState', 'taskProgress']);

const getWorkflowProgress = (backendReportedProgress, taskProgress) => {
  if (!taskProgress) {
    // use backend reported progress as is
    return backendReportedProgress;
  }

  // add task progress into the mix!
  const workflowProgress = fromJS({
    max_steps:
      backendReportedProgress.get('max_steps') * taskProgress.get('stepsCount'),
    steps_completed:
      backendReportedProgress.get('steps_completed') *
        taskProgress.get('stepsCount') +
      taskProgress.get('stepsCompleted'),
  });

  return workflowProgress;
};

const selector = createSelector(
  [getBackendReportedProgress, getTaskProgress],
  getWorkflowProgress
);

export default selector;
