import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import catalogThunks from '../../actions/catalogThunks';
// Selectors
import { getGroomedFulfillmentOptions } from '../../selectors/getGroomedFulfillmentOptions';

const mapStateToProps = (state, ownProps) => {
  const { productId } = ownProps;
  const product = state.getIn(['entities', 'products', productId]);

  return {
    fulfillmentOptions: getGroomedFulfillmentOptions(state, productId),
    product,
    productId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFulfillmentOptionsAndAnswers(productId) {
    // we're blessed yet again with another very usable API design /s
    // because of that, we need to:
    // 1) Load any possible answer first
    // 2) then get fulfillment options
    // 3) Once having both, we need to map the options we have against the answers
    // 4) From there depending on an ansert existing or not, we might need to POST
    // or PATCH any edition to said answer
    dispatch(catalogThunks.loadFulfillmentAnswers(productId)).finally(() => {
      // we want to do this rewarding of success/failure of the ansers
      dispatch(catalogThunks.loadFulfillmentOptions(productId));
    });
  },

  loadProduct(productID) {
    const forceReload = true;
    dispatch(catalogThunks.loadOneProduct(productID, forceReload));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
