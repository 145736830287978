import { useEffect, useState } from 'react';
import { INPUT_TIME_FORMAT } from '../../../../../../globals/DefaultInputFormats';
import DateTools from '../../../../../utils/DateTools';
import getScheduleSync from '../endpoints/getScheduleSync';
import postScheduleSync from '../endpoints/postScheduleSync';
import convertWeekdays from '../utils/convertWeekdays';
import postResetScheduleSync from '../endpoints/postResetScheduleSync';

const useScheduleSync = ({ scheduleSyncURL, spaceFieldName }) => {
  const [scheduleSync, setScheduleSync] = useState({
    selectedDays: [],
    spaceFieldName: '',
    timeOfDay: '',
  });
  const [loading, setLoading] = useState(false);

  const setSchedule = async ({ weekDays, timeDay, ...rest }) => {
    setLoading(true);
    try {
      const utcTime = DateTools.getDateFromLocalToUTC(
        timeDay,
        INPUT_TIME_FORMAT
      );
      const { body } = await postScheduleSync({
        days: convertWeekdays({
          days: weekDays,
          localTime: timeDay,
          toUTC: true,
        }),
        scheduleSyncURL,
        time: utcTime,
        ...rest,
      });
      if (!body?.scheduled_sync !== null) {
        const { days, time } = body?.scheduled_sync;

        const localTime = DateTools.getDateFromUTCToLocal({
          date: time,
          format: INPUT_TIME_FORMAT,
        });

        const data = {
          selectedDays: convertWeekdays({
            days,
            fromUtc: true,
            localTime,
          }),
          timeOfDay: localTime,
        };

        if (spaceFieldName) {
          data[spaceFieldName] = body[spaceFieldName]
            ? body[spaceFieldName]
            : '';
        }
        setScheduleSync(data);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const resetSchedule = async (resetSyncURL) => {
    setLoading(true);
    try {
      await postResetScheduleSync({
        days: [],
        resetSyncURL,
        time: '',
      });
      setScheduleSync({
        ...scheduleSync,
        selectedDays: [],
        timeOfDay: '',
      });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const { body } = await getScheduleSync(scheduleSyncURL);
        const { scheduled_sync, ...rest } = body;
        if (scheduled_sync !== null) {
          const { days, time } = body?.scheduled_sync;

          const localTime = DateTools.getDateFromUTCToLocal({
            date: time,
            format: INPUT_TIME_FORMAT,
          });

          setScheduleSync({
            selectedDays: convertWeekdays({
              days,
              fromUtc: true,
              localTime,
            }),
            timeOfDay: localTime,
            ...rest,
          });
        } else if (rest?.spaces) {
          setScheduleSync({
            selectedDays: [],
            spaceFieldName: '',
            spaces: rest.spaces,
            timeOfDay: '',
          });
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [scheduleSyncURL]);

  return {
    loadingSchedule: loading,
    resetSchedule,
    scheduleSync,
    setSchedule,
  };
};

export default useScheduleSync;
