const labels = {
  CANCEL: 'Cancel',
  DELETE_BODY:
    'Removing SSO configuration will disable your users ability to log in using their SSO credentials. This will also remove all of your current configuration settings.',
  DELETE_TITLE: 'Remove SSO',
  DISABLED: 'DISABLED',
  DISABLE_SSO: 'DISABLE SSO',
  DISABLE_SSO_BODY: 'Would you like to disable SSO now?',
  ENABLED: 'ENABLED',
  ENABLE_SSO: 'ENABLE SSO',
  ENABLE_SSO_BODY: 'Would you like to enable SSO now?',
  MODAL_TITLE: 'Setup SSO',
  NO: 'No',
  NO_SSO_DESCRIPTION: 'Setup single sign-on by clicking the button below',
  REMOVE: 'Remove',
  TRIGGER_BUTTON: 'Begin Setup',
  YES: 'Yes',
};

const { hostname } = window.location;

const newHostname = hostname.replace('.espressive.com', '');

const samlURLS = {
  audienceURI: `https://${newHostname}.saml.espressive.com/`,
  hostname,
  logoutURL: `https://${newHostname}.espressive.com/api/authentication/v0.1/saml/logout/`,
  singleSignOnURL: `https://${newHostname}.espressive.com/api/authentication/v0.1/saml/response/`,
};

export { labels, samlURLS };
