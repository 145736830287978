import { throttle } from 'lodash';
// Actions
import actionTypes from '../actions/actionTypes';
import pwaActions from '../actions/pwaActions';
import wsActions from '../actions/wsActions';
import chatActions from '../actions/chatActions';
import socializeActions from '../actions/socializeActions';
import todoActions from '../actions/todoActions';
import socializeThunks from '../actions/socializeThunks';
import casesActions from '../actions/casesActions';
import updaterThunks from '../actions/updaterThunks';
import catalogThunks from '../actions/catalogThunks';
import baristaActions from '../actions/baristaActions';
import electronActions from '../actions/electronActions';
// Globals
import WebSocketEventTypes from '../globals/WebSocketEventTypes';
// Utils
import SocketInstance from '../utils/SocketInstance';
// Selects
import { getCurrentUserId } from '../selectors/getCurrentUser';
import getBaristaId from '../selectors/getBaristaId';

// store
import reduxStore from '../stores/store'; // i have to name the store differently as the event handlers are using the store name

/**
 * Websocket middleware. Based on https://exec64.co.uk/blog/websockets_with_redux/
 *
 */
const socketMiddleware = (function () {
  let stream = null;

  window.addEventListener('offline', () => {
    reduxStore.dispatch(wsActions.disconnect());
    // leaving this for QA
    // eslint-disable-next-line no-console -- debugging
    console.log('Browser offline. Marking websocket connection as offline');
  });

  window.addEventListener('online', () => {
    reduxStore.dispatch(wsActions.connect());
    // leaving this for QA
    // eslint-disable-next-line no-console -- debugging
    console.log('Browser online. Marking websocket connection as online');
  });

  const onOpen = (store) => () => {
    // Tell the store we're connected
    store.dispatch(wsActions.connected());
  };

  const onClose = (store) => (evt, details) => {
    // Tell the store we've disconnected
    store.dispatch(wsActions.disconnected(evt, details));
  };

  const onRefused = (store) => (evt, details) => {
    // Tell the store we've disconnected
    store.dispatch(wsActions.refused(evt, details));
  };
  const onError = (store) => (evt) => {
    // Tell the store we've disconnected
    store.dispatch(wsActions.errored(evt));
  };

  const onConnecting = (store) => () => {
    // Tell the store we've disconnected
    store.dispatch(wsActions.connecting());
  };

  // These two functions (startDelay, resetDelay) will be used to trigger the deRegisterUserTyping
  // if startDelay is not revived after the first timeout ends
  let delay;
  const startDelay = (msg, timeout = 350) => {
    delay = setTimeout(() => {
      reduxStore.dispatch(chatActions.deRegisterUserTyping(msg));
    }, timeout);
  };
  const resetDelay = () => {
    clearTimeout(delay);
  };

  const checkReadCount = throttle((store) => {
    store.dispatch(socializeThunks.getTotalUnreadForScopedChannels());
    store.dispatch(socializeThunks.getTotalUnreadForDirectChannels());
  }, 5000); // check at most every 5 seconds. DEV-18940Prevents this call from being overused

  const onMessage = (store) => (evt) => {
    if (!evt.body) {
      return;
    }
    if (
      evt.body &&
      (evt.body.message === 'ok' || evt.body.message === 'done')
    ) {
      // ignoring ok, done, noise
      return;
    }

    let messageType = evt.type;
    // Replacing a message with body.type='typing' to a ws 'rte.Typing' type because Daniel didn't want to fix it
    if (messageType === WebSocketEventTypes.RTE && evt.body.type === 'typing') {
      messageType = WebSocketEventTypes.TYPING;
    }

    switch (messageType) {
      case WebSocketEventTypes.CHATMESSAGE: {
        // requests the latests unread count for ALL channels in general
        // as we don't have a way to know the unread count for all other channels
        checkReadCount(store);

        const currentUserId = getCurrentUserId(store.getState());

        const message = evt.body;
        // deregister user from typing as soon as it receives a message
        // this avoid a weird behavior when you can see the user typing
        // even when he already sent the message
        store.dispatch(
          chatActions.deRegisterUserTyping({
            // we need this normalization because the webspcket attribute names are different :/
            channel_id: message.channel,
            userId: message.user_id,
          })
        );

        // Dispatch an action that adds the received message to our state
        store.dispatch(chatActions.chatMessageReceived(message, currentUserId));

        // checking if the channel exists already
        const channelId = message.channel;
        const channel = store
          .getState()
          .getIn(['entities', 'channels', channelId]);
        if (!channel) {
          // Load the channel if we don't have it
          store.dispatch(socializeThunks.loadChannel(channelId));
        }
        break;
      }
      case WebSocketEventTypes.CHANNEL: {
        // requests the latests unread count for ALL channels in general
        // as we don't have a way to know the unread count for all other channels
        checkReadCount(store);

        // Dispatch an action that adds the received message to our state
        store.dispatch(socializeActions.loadChannelSuccess(evt.body));
        break;
      }

      case WebSocketEventTypes.CHANNEL_READ_COUNT: {
        // Dispatch an action that adds the received message to our state
        store.dispatch(socializeActions.loadChannelSuccess(evt.body));
        store.dispatch(socializeActions.updateAutomationTaskTypeCard(evt.body));

        break;
      }

      case WebSocketEventTypes.TYPING: {
        // Dispatch an action that adds the received message to our state
        const wsMessage = evt.body;
        store.dispatch(chatActions.registerUserTyping(wsMessage));

        const baristaUserId = getBaristaId(store.getState());
        const userTypingId = evt.body && evt.body.userId;

        // if barista was typing, deregister after 2 seconds
        // otherwise 450ms
        const deRegisterTypingAfter =
          baristaUserId === userTypingId ? 2000 : 750;

        resetDelay(); // resets current delay
        startDelay(wsMessage, deRegisterTypingAfter); // will clear after time has passed and no reset was received

        break;
      }

      case WebSocketEventTypes.CARDUPDATE: {
        // Dispatch an action that adds the received message to our state
        store.dispatch(todoActions.cardUpdate(evt.body));
        break;
      }

      case WebSocketEventTypes.TASKUPDATE: {
        // Dispatch an action that adds the received case to our state
        store.dispatch(casesActions.taskUpdate(evt.body));
        break;
      }

      case WebSocketEventTypes.TENANTUPDATE: {
        store.dispatch(updaterThunks.downloadUpstreamIndex());
        break;
      }

      case WebSocketEventTypes.REFRESH: {
        // Ok, I know this looks very unorthodox, but consider that this is a middleware
        // nothing really stops us from having side effects here
        // and in this case, if we receive this web socket event, we want to refresh the browser (T8530)
        // btw this works in cordova and electron too
        window.location.reload(true);
        break;
      }

      case WebSocketEventTypes.RESTART_SUPPORT_CHANNEL: {
        // DEV-26031 the message barista.GoToChannel may contain a
        // channel_id in the body in which case we should open that
        const channelId = evt?.body?.channel_id;
        if (channelId) {
          store.dispatch(
            baristaActions.openSupportChannel(null, channelId, false, '')
          );
        } else {
          // turning off and on barista will make it
          // reinitialize in a completely blank new state
          store.dispatch(baristaActions.restartSupportChannel());
          setTimeout(() => {
            store.dispatch(baristaActions.supportChannelStart());
          }, 1);
        }

        break;
      }

      case WebSocketEventTypes.DPC_PRODUCT_UPDATE_COMPLETE: {
        const response = JSON.parse(evt.body);
        store.dispatch(catalogThunks.loadOneProduct(response.product_id));
        break;
      }

      case WebSocketEventTypes.DESKTOP_NOTIFICATION: {
        // only reports that a ws message with notification has bbeen received
        // this will be processed by electronMiddleware
        store.dispatch(electronActions.desktopNotification(evt.body));
        store.dispatch(pwaActions.notification(evt.body));
        break;
      }

      case WebSocketEventTypes.DELETE_CHAT_MESSAGE: {
        const { body } = evt;
        store.dispatch(
          chatActions.deleteChatMessage(body.channel_id, body.eid)
        );
        break;
      }

      case WebSocketEventTypes.DELETE_CHAT_CHANNEL: {
        const { body } = evt;
        const channelId = body.id;
        store.dispatch(chatActions.deleteChaChannel(channelId));
        break;
      }

      default:
        // console.log(`Received unknown message type: '${evt.type}'`);
        break;
    }
  };

  // Reducer-like middleware.
  // Not a real reducer as as it doesn't modify a store
  // We just listen to action types, and perform side effects
  // For instance opening websocket connections
  // or closing them, etc.
  return (store) => (next) => (action) => {
    switch (action.type) {
      // The user wants us to connect
      case actionTypes.WS_CONNECT: {
        if (!stream) {
          stream = new SocketInstance({
            onCloseHandler: onClose(store),
            onConnectingHandler: onConnecting(store),
            onErrorHandler: onError(store),
            onMessageHandler: onMessage(store),
            onOpenHandler: onOpen(store),
            onRefusedHandler: onRefused(store),
          });
        }

        stream.connect();

        break;
      }

      // The user wants us to disconnect
      case actionTypes.IMPERSONATING_START:
      case actionTypes.WS_DISCONNECT: {
        if (stream) {
          stream.disconnect();
        }
        // Set our state to disconnected
        store.dispatch(wsActions.disconnected());
        break;
      }

      // Send the 'WS_SEND' action down the websocket to the server
      case actionTypes.WS_SEND: {
        stream.send(action.data);
        break;
      }
      default:
        return next(action);
    }

    // We had to put this to silent lintingm, even when it's returned as the default case of the switch
    return next(action);
  };
})();

export default socketMiddleware;
