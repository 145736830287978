import { isNumber } from 'lodash';
import EspFilters from 'esp-util-filters';
import APIcall from '../../utils/APIcall';
import endpointGenerator from '../../utils/endpointGenerator';
import baristaSearchConditionsActions from '../caseMgmtActions/baristaSearchConditionsActions';

const thunks = {};

thunks.createCondition = (newCondition, prepend = true) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCaseMgmt.conditions');
    dispatch(baristaSearchConditionsActions.createConditionStart());

    APIcall.post({
      data: newCondition,
      error(error) {
        dispatch(baristaSearchConditionsActions.createConditionError());
        reject(error);
      },
      success({ body }) {
        dispatch(
          baristaSearchConditionsActions.createConditionSuccess(body, prepend)
        );
        resolve(body);
      },
      token: true,
      url,
    });
  });

thunks.deleteCondition = (conditionID, serviceDepartmentEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!isNumber(conditionID)) {
      throw new Error('A condition ID must be provided');
    }
    const url = endpointGenerator.genPath('espCaseMgmt.conditions.instance', {
      conditionID,
    });
    APIcall.delete({
      error(err) {
        reject(err);
      },
      success() {
        dispatch(thunks.loadConditions(serviceDepartmentEID));
        resolve();
      },
      token: true,
      url,
    });
  });

thunks.loadConditions = (serviceDepartmentEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = `${endpointGenerator.genPath(
      'espCaseMgmt.conditions'
    )}?order_by=order_of_execution`;
    const filters = new EspFilters();
    dispatch(baristaSearchConditionsActions.loadConditionsStart());
    APIcall.get({
      error(error) {
        dispatch(baristaSearchConditionsActions.loadConditionsError());
        reject(error);
      },
      query: {
        esp_filters: filters
          .equalTo('service_department', serviceDepartmentEID)
          .asQueryString(),
        limit: 100,
      },
      success({ body: { results } }) {
        dispatch(
          baristaSearchConditionsActions.loadConditionsSuccess(
            results,
            serviceDepartmentEID
          )
        );
        resolve();
      },
      token: true,
      url,
    });
  });

thunks.swapCondition = (eid1, eid2) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCaseMgmt.conditions.swap');

    APIcall.post({
      data: {
        eid1,
        eid2,
      },
      error: reject,
      success() {
        dispatch(
          baristaSearchConditionsActions.swapConditionsSuccess(eid1, eid2)
        );
        resolve();
      },
      token: true,
      url,
    });
  });

thunks.toggleCondition = (conditionID, isActive = false) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!isNumber(conditionID)) {
      throw new Error('A condition ID must be provided');
    }
    const url = endpointGenerator.genPath('espCaseMgmt.conditions.instance', {
      conditionID,
    });
    APIcall.patch({
      data: { is_active: isActive },
      error(err) {
        reject(err.response.body);
      },
      success({ body }) {
        dispatch(baristaSearchConditionsActions.toggleConditionSuccess(body));
        resolve(body);
      },
      token: true,
      url,
    });
  });

thunks.updateCondition = (updatedCondition) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(baristaSearchConditionsActions.updateConditionStart());
    const url = endpointGenerator.genPath('espCaseMgmt.conditions.instance', {
      conditionID: updatedCondition.id,
    });
    APIcall.put({
      data: updatedCondition,
      error(error) {
        dispatch(baristaSearchConditionsActions.updateConditionError());
        reject(error);
      },
      success({ body }) {
        dispatch(baristaSearchConditionsActions.updateConditionSuccess(body));
        resolve(body);
      },
      token: true,
      url,
    });
  });

export default thunks;
