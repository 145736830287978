import React, { useCallback, useState } from 'react';
import pt from 'prop-types';
import ChatInputController from '../../functional/ChatInput/ChatInputController';
import { TimePicker } from 'cascara-middleware';
import moment from 'moment';
import { BROWSER_LANGUAGE } from '../../../../v1/utils/DateTools';
import ChatInputTypes from '../../../../v2/components/display/ChatMessage/ChatInputTypes';
import { UserAgentUtils } from 'esp-globals';

const propTypes = {
  sendMessage: pt.func,
};

const BE_TIME_FORMAT = 'HH:mm:ss';

const ChatTime = ({ sendMessage }) => {
  const [isDisable, setIsDisable] = useState(false);

  const handleOnClick = useCallback(
    (_, value) => {
      const ISOTime = `${moment(value, BE_TIME_FORMAT).format(
        BE_TIME_FORMAT
      )}-06:00`;
      sendMessage({
        isPicker: true,
        message: ISOTime,
        messageType: ChatInputTypes.TIME,
      });
      setIsDisable(true);
    },
    [sendMessage]
  );
  const currentDate = new Date().toLocaleTimeString(BROWSER_LANGUAGE);
  const is12Format = currentDate.includes('AM') || currentDate.includes('PM');
  const format = is12Format ? 'h:mm a' : 'H:mm';

  const isCordova = UserAgentUtils.isCordova();
  return (
    <TimePicker
      disabled={isDisable}
      format={format}
      lang={BROWSER_LANGUAGE}
      name='chatTime'
      onChange={handleOnClick}
      style={{ marginLeft: '0.5em' }}
      useNativeInput={isCordova}
    />
  );
};

ChatTime.propTypes = propTypes;
const ChatTimeCtrl = ChatInputController(ChatTime);
export default ChatTimeCtrl;
