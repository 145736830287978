const MobileOptions = ['Android', 'iOS'];
const AnyOption = {
  key: 'any',
  text: 'Any',
  value: 'any',
};

const ApplicationTypes = {
  CHITCHAT: 'chitchat',
  // FAQ: 'FAQ',
  // SYSTEM: 'system',
  // USER: 'user',
};

const DefaultOption = {
  key: 'all',
  text: 'All',
  value: 'all',
};

const DeflectedOptions = [
  DefaultOption,
  {
    key: 'deflected',
    text: 'Deflected',
    value: 'true',
  },
  {
    key: 'empty',
    text: 'Empty',
    value: 'null',
  },
  {
    key: 'nonDeflected',
    text: 'Non Deflected',
    value: 'false',
  },
];

const FieldNames = {
  ACTUAL_MATCHED_APPLICATION: 'actual_matched_application',
  ACTUAL_MATCHED_APPLICATION_EID: 'actual_matched_application_eid',
  ACTUAL_MATCHED_APPLICATION_TYPE: 'actual_matched_application_type',
  ACTUAL_MATCHED_ARCHETYPE: 'matched_archetype_intent',
  ACTUAL_MATCHED_INTENT: 'actual_matched_intent',
  AUTOMATION_STATUS: 'automation_status',
  CASE_REFERENCE: 'case_reference',
  CHANNEL: 'channel',
  CLIENT: 'client',
  DEFLECTED: 'deflected',
  END_DATE: 'end_date',
  ESP_SERVICE_DEPARTMENT: 'esp_service_department',
  HELPFUL: 'helpful_feedback',
  INTERACTIONS_BY_SOURCE: 'source',
  INTERACTION_TEXT: 'interaction_text',
  ISO_COUNTRY_CODE: 'iso_country_code',
  KB_ARTICLES: 'kb_articles',
  KB_DISPLAYED: 'kb_displayed',
  KB_RESPONSE: 'kb_response',
  KB_RESULTS: 'kb_results',
  KEYWORDS: 'keywords',
  LIST_SEARCH_TERM: 'list_searchTerm',
  LIVE_CHAT_INTERACTION_FEEDBACK: 'live_chat_interaction_feedback',
  NO_RESPONSE: 'no_response',
  OS: 'os',
  POSSIBLE_ABANDONED: 'possibly_abandoned',
  POSSIBLE_DUPLICATES: 'duplicate_tickets',
  START_DATE: 'start_date',
  USER_DEPARTMENT: 'user_department',
  USER_ID: 'user_id',
  USER_LANGUAGE: 'user_language',
};

const FeedbackEspFiltersMapper = {
  helpful: 'helpful',
  more_help: 'more-help',
  needs_content_no_match: 'needs-content-no-match',
  needs_content_no_response: 'needs-content-no-response',
  no_feedback: '',
  non_helpful: 'not-helpful',
};

const FeedbackLabels = {
  chart_title: 'User Feedback',
  feedback: 'Feedback',
  helpful: 'Content Helpful',
  more_help: 'Content Accurate but asking for help',
  needs_content_no_match: 'Needs Content - No Match',
  needs_content_no_response: 'Needs Content - No Response',
  no_feedback: 'No Feedback',
  non_helpful: 'Content Needs Improvement',
};
const FeedbackKeys = {
  helpful: 'helpful',
  more_help: 'more_help',
  needs_content_no_match: 'needs_content_no_match',
  needs_content_no_response: 'needs_content_no_response',
  no_feedback: 'no_feedback',
  non_helpful: 'non_helpful',
};

const ShowFeedbackOptions = [
  DefaultOption,
  {
    key: FeedbackKeys.helpful,
    text: FeedbackLabels.helpful,
    value: 'helpful',
  },
  {
    key: FeedbackKeys.more_help,
    text: FeedbackLabels.more_help,
    value: 'more_help',
  },
  {
    key: FeedbackKeys.needs_content_no_response,
    text: FeedbackLabels.needs_content_no_response,
    value: 'needs_content_no_response',
  },
  {
    key: FeedbackKeys.needs_content_no_match,
    text: FeedbackLabels.needs_content_no_match,
    value: 'needs_content_no_match',
  },
  {
    key: FeedbackKeys.non_helpful,
    text: FeedbackLabels.non_helpful,
    value: 'non_helpful',
  },
  {
    key: FeedbackKeys.no_feedback,
    text: FeedbackLabels.no_feedback,
    value: 'no_feedback',
  },
];

const ResponseOptions = [
  AnyOption,
  {
    key: 'false',
    text: 'False',
    value: 'false',
  },
  {
    key: 'true',
    text: 'True',
    value: 'true',
  },
];

const ClientOptions = {
  BARISTA: 'Barista',
  CHROME: 'Chrome',
  EDGE: 'Edge',
  MOBILE_CHROME: 'Mobile Chrome',
  MOBILE_SAFARI: 'Mobile Safari',
  OTHER: 'Other',
  SAFARI: 'Safari',
};

const supportedOS = ['Windows', 'iOS', 'Android', 'macOS', 'Other'];

const SupportedChannelLabels = {
  SLACK: 'Slack',
  TEAMS: 'Teams',
};
const SupportedOsDataTemplate = supportedOS.map((os) => ({
  Barista: 0,
  Chrome: 0,
  Edge: 0,
  'Mobile Chrome': 0,
  'Mobile Safari': 0,
  Other: 0,
  Safari: 0,
  drillDownIdentifier: {},
  os,
  totalInteractionsByOs: 0,
}));

const FEEDBACK = 'feedback';

export {
  AnyOption,
  ApplicationTypes,
  ClientOptions,
  DefaultOption,
  DeflectedOptions,
  FeedbackEspFiltersMapper,
  FeedbackKeys,
  FeedbackLabels,
  FieldNames,
  MobileOptions,
  ResponseOptions,
  ShowFeedbackOptions,
  SupportedChannelLabels,
  SupportedOsDataTemplate,
  FEEDBACK,
};
