import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { intl } from 'esp-util-intl';

// Atoms
import BuildInfo from '../atoms/BuildInfo';
// Molecules
import ValidatedForm from '../molecules/ValidatedForm';
import ValidatedField from '../molecules/ValidatedField';
// Controller
import TenantSelectController from '../controllers/TenantSelectController';
// Globals
import { ImageDefaults } from 'esp-assets';
import getAppVersions from '../../utils/appVersionHelper';
import BodyClassName from '../../../globals/BodyClassName';

class TenantSelect extends PureComponent {
  static propTypes = {
    checkPreExistingCreds: PropTypes.func.isRequired,
    clearTenant: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
    formValidation: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoadingTenant: PropTypes.bool,
    isValid: PropTypes.bool,
  };

  static defaultProps = {
    errorMsg: null,
    isLoadingTenant: false,
    isValid: false,
  };

  componentDidMount() {
    const { clearTenant, checkPreExistingCreds } = this.props;
    clearTenant();
    checkPreExistingCreds(); // will check if something is stored in local storage
    getAppVersions();
  }

  render() {
    const { errorMsg, formValidation, handleSubmit, isLoadingTenant, isValid } =
      this.props;

    return (
      <BodyClassName className='login-page tenant-select'>
        <Grid centered verticalAlign='middle'>
          <Helmet title={'Select a tenant'} />

          <Grid.Row>
            <Grid.Column>
              <Image
                className='logo'
                src={ImageDefaults.ESPRESSIVE_LOGO}
                type='logo'
              />
              <Segment>
                <ValidatedForm
                  form='TenantSelectForm'
                  header={<Header content='Profile' icon='user' />}
                  onSubmit={handleSubmit}
                  validate={formValidation}
                >
                  <Form.Group widths='equal'>
                    <ValidatedField
                      autocomplete='false'
                      label={{
                        basic: true,
                        content: '.espressive.com',
                      }}
                      labelPosition='right'
                      name='tenantName'
                      placeholder='my-company'
                    />
                  </Form.Group>

                  <Form.Field widths='equal'>
                    <Button
                      disabled={isLoadingTenant || !isValid}
                      fluid
                      loading={isLoadingTenant}
                      primary
                      type='submit'
                    >
                      {intl.formatMessage({
                        id: 'label.select_tenant',
                      })}
                    </Button>
                  </Form.Field>

                  {errorMsg && (
                    <Message error>
                      <Message.Header>
                        {intl.formatMessage({
                          id: 'message.invalid_tenant',
                        })}
                      </Message.Header>
                      <Message.Content>
                        {intl.formatMessage({
                          id: 'message.selected_tenant_not_exist',
                        })}
                      </Message.Content>
                    </Message>
                  )}
                </ValidatedForm>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <BuildInfo />
        </Grid>
      </BodyClassName>
    );
  }
}
const TenantSelectTest = TenantSelect;

// eslint-disable-next-line no-class-assign -- DEV-1526
TenantSelect = TenantSelectController(TenantSelect);
export { TenantSelectTest };
export default TenantSelect;
