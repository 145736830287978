import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
// Globals
import TypeEditFaqBarista, {
  TypeDefaults,
} from '../../../globals/blocks/TypeEditFaqBarista';
import EditFAQTeachBaristaController from '../../controllers/EditFAQTeachBaristaController';
import FormInputText from '../../molecules/FormInputText';

/**
 * This block is an empty component which allow
 * to build a BE scratch object for this FAQ
 */

class EditFAQTeachBarista01 extends PureComponent {
  static propTypes = {
    ...TypeEditFaqBarista,
    skipOrNotFAQ: PropTypes.func.isRequired,
    taskSelected: ImmutablePropTypes.map,
    updateFAQ: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    taskSelected: null,
  };

  componentDidMount() {
    const {
      setAlternateSkip,
      setAlternateSubmit,
      taskSelected,
      updateFAQ,
    } = this.props;

    setAlternateSubmit(this.handleSubmit);
    setAlternateSkip(this.handleSkip);

    updateFAQ(taskSelected); // Edit selected FAQ
  }

  skipped = false;

  handleSubmit = (cb) => {
    const { id, skipOrNotFAQ } = this.props;
    const obj = {
      [id]: 'edit',
    };

    if (!this.skipped) {
      obj[id] = 'noedit';

      skipOrNotFAQ(id, obj).then(() => {
        cb(fromJS(obj));
      });
    } else {
      cb(fromJS(obj));
    }
  };

  handleSkip = () => {
    const { id, skipOrNotFAQ } = this.props;

    this.skipped = true;
    skipOrNotFAQ(id);
  };

  render() {
    const { id, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          autoComplete='off'
          autoCorrect='off'
          component={FormInputText}
          hidden
          hideLabel
          id={`${id}-2`}
          label={`${id}-2`}
          name={`${id}-2`}
        />
      </div>
    );
  }
}

export const EditFAQTeachBarista01Test = EditFAQTeachBarista01;

export default EditFAQTeachBaristaController(EditFAQTeachBarista01);
