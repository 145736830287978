import React from 'react';
import pt from 'prop-types';

import ScheduleList from './OffboardingSchedule/ScheduleList';
import ScheduleDetails from './OffboardingSchedule/ScheduleDetail';
import OffboardingScheduleState from './OffboardingSchedule/Context/OffboardingScheduleState';

const propTypes = {
  params: pt.shape({
    scheduleID: pt.string,
  }),
};

const OffboardingSchedule = (props) => {
  const { params } = props;
  return (
    <OffboardingScheduleState>
      {params?.scheduleID ? (
        <ScheduleDetails params={params} scheduleID={params?.scheduleID} />
      ) : (
        <ScheduleList {...props} />
      )}
    </OffboardingScheduleState>
  );
};

OffboardingSchedule.propTypes = propTypes;
export default OffboardingSchedule;
