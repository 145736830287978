import endpointPaths from './endpointPaths';
import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';

import EndpointPathGenerator from 'esp-util-path';

// reading origin from local storage if exist
let originLocalStorage;
if (window.cordova || window.electron) {
  // currently only remembering tenant url in native apps
  originLocalStorage = persistentStorage.get(PersistentStorageKeys.TENANT_URL);
}
const endpointGenerator = new EndpointPathGenerator(
  endpointPaths,
  originLocalStorage
);

export default endpointGenerator;
