import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesMemoryCards extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(23 15.5)'>
          <rect height='17.03' rx='2' width='26.54' x='11.46' y='34.84' />
          <path d='M38.37 0H0v64.26h6l43.81-.09V13.35L38.37 0zm4.9 58.08L6 58.22V6h3v9.84h5.27V6H18v9.84h5.27V6h3.47v9.93H32V6h3.63l8.2 9.5-.56 42.58z' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesMemoryCards;
