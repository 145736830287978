import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useTenantHook } from './customHooks';
import useUpdateChatExperience from './customHooks/tenantCustomization/useUpdateChatExperience';
import pt from 'prop-types';

const propTypes = {
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  currentUserID: pt.number,
};

const AppWrapper = ({ children, currentUserID }) => {
  const { isTenantLoading } = useTenantHook();
  const { chatExperience } = useUpdateChatExperience();
  // Render a loading state while tenant info is being fetched
  if (isTenantLoading) {
    return <Loader active />;
  }

  const isCordova = window.cordova;
  const isElectron = window.electronAPI;
  const IsAppInIframe = window !== window.top;

  const isClassicExperience =
    localStorage.getItem('isClassicExperience') === 'true';

  const path = window.location.pathname;

  if (!isCordova && !isElectron && !IsAppInIframe) {
    if (
      !isClassicExperience &&
      chatExperience === 'chatv2' &&
      !path.includes('admin') &&
      currentUserID
    ) {
      window.location.replace('/v2/chat/');
      return null;
    }
  }

  return children;
};

AppWrapper.propTypes = propTypes;
export default AppWrapper;
