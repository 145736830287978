import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import FormInputCheckbox from '../../molecules/FormInputCheckbox';
import { Field } from 'redux-form/immutable';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Input } from 'semantic-ui-react';

// Controller
import CheckUserAsManagerController from '../../controllers/CheckUserAsManagerController';

// Globals
import TypeCheckbox, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCheckbox';

let CheckUserAsManager01 = class CheckUserAsManager01 extends Component {
  static propTypes = {
    ...TypeCheckbox,

    /** Redux change value handler */
    changeValue: PropTypes.func.isRequired,

    /** Workflows Errors*/
    errors: ImmutablePropTypes.map,

    /** API errors */
    errorsApi: ImmutablePropTypes.list,

    /** Value of the different Contacts */
    formValues: ImmutablePropTypes.map,

    /** Preset value */
    isManager: PropTypes.bool,

    /** Set frontend scratch data */
    setIsManager: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    formValues: null,
    isManager: false,
  };

  componentDidMount() {
    const { readOnly, setAlternateSubmit, isManager, changeValue, id } =
      this.props;

    if (!readOnly) {
      setAlternateSubmit(this.handleClick);

      if (isManager) {
        changeValue(id, isManager);
      }
    }
  }

  handleChange = (e, value) => {
    const { changeValue, id } = this.props;

    changeValue(id, value);
  };

  handleClick = (cb = _.noop) => {
    const { formValues, setIsManager, id } = this.props;

    setIsManager(formValues.get(id), cb);
  };

  render() {
    const {
      errors,
      errorsApi,
      id,
      inputType,

      isManager,
      labelText,
      placeholder,
      readOnly,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    const managerMsg = isManager
      ? intl.formatMessage({
          id: 'message.yes',
        })
      : intl.formatMessage({
          id: 'message.no',
        });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {readOnly ? (
          <Form.Field>
            <label>{'Manager'}</label>
            <Input fluid readOnly transparent value={managerMsg} />
          </Form.Field>
        ) : (
          <Field
            autoComplete='off'
            autoCorrect='off'
            component={FormInputCheckbox}
            formError={finalError}
            id={id}
            label={labelText}
            name={id.toString()}
            onChange={this.handleChange}
            placeholder={placeholder}
            type={inputType}
          />
        )}
      </div>
    );
  }
};
const CheckUserAsManager01Test = CheckUserAsManager01;

CheckUserAsManager01 = CheckUserAsManagerController(CheckUserAsManager01);
export { CheckUserAsManager01Test };

export default CheckUserAsManager01;
