import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';

import FormInputCheckbox from '../../../../../../app/js/v1/components/molecules/FormInputCheckbox';
import FormInputText from '../../../../../../app/js/v1/components/molecules/FormInputText';
import FormInputSelect from '../../../../../../app/js/v1/components/molecules/FormInputSelect';

const propTypes = {
  addOptionFn: PropTypes.func,
  fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  item: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    arrayShape: PropTypes.any,
  }),
  removeOptionFn: PropTypes.func,
};

const defaultProps = {
  addOptionFn: noop,
  fields: null,
  item: null,
  removeOptionFn: noop,
};

class BlockArrayOfAttributes extends React.Component {
  handleAddOption = memoize(
    curry((fields, e) => {
      const { addOptionFn } = this.props;
      addOptionFn(fields.name, fields);
    })
  );

  handleRemoveOption = memoize(
    curry((fields, index, e) => {
      const { removeOptionFn } = this.props;
      removeOptionFn(fields.name, index, fields);
    })
  );

  handleBlur(e) {
    e.preventDefault();
  }

  render() {
    const { fields, item } = this.props;

    return (
      <Segment>
        <Grid>
          <Grid.Column width={12}>
            <label>{fields.name}</label>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button.Group floated='right' size='tiny' vertical>
              <Button
                content={`Add ${fields.name}`}
                onClick={this.handleAddOption(fields)}
                size='tiny'
              />
            </Button.Group>
          </Grid.Column>
        </Grid>

        {fields.map((optionFieldName, index) => (
          <Segment key={optionFieldName}>
            <Grid>
              <Grid.Column width={15}>
                <Header as='h5'>{optionFieldName}</Header>
              </Grid.Column>
              <Grid.Column width={1}>
                <Button.Group floated='right' size='tiny' vertical>
                  <Button
                    icon={<Icon name='trash' />}
                    onClick={this.handleRemoveOption(fields, index)}
                  />
                </Button.Group>
              </Grid.Column>
            </Grid>

            {fields
              .get(index)
              .keySeq()
              .map((attr) => {
                const currentSubItem = item.arrayShape[attr];
                // Do not render anything if for some reason the attribute
                // is not declared in the arrayShape
                if (!currentSubItem) {
                  return null;
                }

                if (
                  currentSubItem.type === 'string' &&
                  currentSubItem.restrictToOptions
                ) {
                  const selectRestrictToOptions = currentSubItem.restrictToOptions.map(
                    (op) => ({
                      text: op,
                      value: op,
                    })
                  );

                  if (!currentSubItem.required) {
                    selectRestrictToOptions.unshift({
                      text: '-empty-',
                      value: '',
                    });
                  }

                  return (
                    <Field
                      component={FormInputSelect}
                      fluid
                      key={`${optionFieldName}.${attr}`}
                      label={attr}
                      name={`${optionFieldName}.${attr}`}
                      options={selectRestrictToOptions}
                      required={currentSubItem.required}
                      selection
                    />
                  );
                } else {
                  return (
                    <Field
                      component={
                        currentSubItem.type === 'bool'
                          ? FormInputCheckbox
                          : FormInputText
                      }
                      key={`${optionFieldName}.${attr}`}
                      label={attr}
                      name={`${optionFieldName}.${attr}`}
                      onBlur={this.handleBlur}
                      required={currentSubItem.required}
                    />
                  );
                }
              })}
          </Segment>
        ))}
      </Segment>
    );
  }
}

BlockArrayOfAttributes.propTypes = propTypes;
BlockArrayOfAttributes.defaultProps = defaultProps;

export default BlockArrayOfAttributes;
