import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';

// atoms
import EspSlider from '../atoms/EspSlider';

// organism
import RoleBundleListItem from '../organisms/RoleBundleListItem';

class RoleBundleGroupSelect extends PureComponent {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onSelectRole: PropTypes.func,
    role: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    onSelectRole: _.noop,
  };

  handleRoleClick = () => {
    const { role, onSelectRole } = this.props;

    onSelectRole(role);
  };

  render() {
    const { isSelected, role } = this.props;

    const name = role.get('name');
    const availableBundles = role.get('availableBundles');

    return (
      <Segment.Group data-role={name}>
        <Segment className='link' clearing onClick={this.handleRoleClick}>
          {isSelected ? (
            <Icon
              className='right floated'
              color='green'
              name='check'
              size='large'
            />
          ) : (
            <Button
              className='text'
              content='Select'
              data-test-id='change-role-button'
              floated='right'
            />
          )}
          <Header as='h5' className='fitted' content={name} />
        </Segment>
        <Segment className='fitted'>
          <EspSlider>
            {availableBundles.map((bundle) => (
              <RoleBundleListItem bundle={bundle} key={bundle.get('id')} />
            ))}
          </EspSlider>
        </Segment>
      </Segment.Group>
    );
  }
}

export default RoleBundleGroupSelect;
