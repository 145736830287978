import actionTypes from './actionTypes';

const sessionActions = {};

sessionActions.userLoginStart = () => ({
  type: actionTypes.USER_LOGIN_START,
});

sessionActions.userLoginSuccess = (currentUser, timeStamp) => ({
  currentUser,
  timeStamp,
  type: actionTypes.USER_LOGIN_SUCCESS,
});

sessionActions.userLoginFailure = (error) => ({
  error,
  type: actionTypes.USER_LOGIN_FAILURE,
});

sessionActions.userSetPreviousUrl = (previousUrl) => ({
  previousUrl,
  type: actionTypes.USER_SET_PREVIOUSURL,
});

sessionActions.userAuthFailure = (errorMsg) => ({
  errorMsg,
  type: actionTypes.USER_AUTH_FAILURE,
});

sessionActions.userImgUploadStart = () => ({
  type: actionTypes.USER_UPLOAD_IMG_START,
});

sessionActions.userImgUploadFail = (error) => ({
  error,
  type: actionTypes.USER_UPLOAD_IMG_FAIL,
});

sessionActions.userImgUploadSuccess = () => ({
  type: actionTypes.USER_UPLOAD_IMG_SUCCESS,
});

sessionActions.userLogout = () => ({
  type: actionTypes.USER_LOGOUT,
});

sessionActions.setToken = (accessToken) => ({
  accessToken,
  type: actionTypes.SET_TOKEN,
});

sessionActions.whoami = (me) => ({
  me,
  type: actionTypes.WHO_AM_I,
});

sessionActions.externalLoginChecked = (externalLoginRedirectUri) => ({
  externalLoginRedirectUri,
  type: actionTypes.EXTERNAL_LOGIN_CHECKED,
});

sessionActions.updateUserSuccess = () => ({
  type: actionTypes.UPDATE_SESSION_SUCCESS,
});

sessionActions.assignDelegateStart = () => ({
  type: actionTypes.ASSIGN_DELEGATE_START,
});
sessionActions.assignDelegateSuccess = () => ({
  type: actionTypes.ASSIGN_DELEGATE_SUCCESS,
});
sessionActions.assignDelegateFail = () => ({
  type: actionTypes.ASSIGN_DELEGATE_FAIL,
});

sessionActions.impersonatingStart = () => ({
  type: actionTypes.IMPERSONATING_START,
});
sessionActions.impersonatingSet = (userId, eid) => ({
  eid,
  type: actionTypes.IMPERSONATING_SET,
  userId,
});
sessionActions.saveOriginalUser = (userId, delegators) => ({
  delegators,
  type: actionTypes.IMPERSONATING_SAVE_ORIGINAL_USER,
  userId,
});

sessionActions.loadUserAccessTokenStart = (userName) => ({
  // ToDo: this should be moved away from here to an admin specific action
  type: actionTypes.ADMIN_SET_USER_ACCESS_TOKENS_START,
  userName,
});

sessionActions.loadUserAccessTokenSuccess = (userName, response) => ({
  response,
  // ToDo: this should be moved away from here to an admin specific action
  type: actionTypes.ADMIN_SET_USER_ACCESS_TOKENS_SUCCESS,
  userName,
});

sessionActions.loadUserAccessTokenError = (userName, errors) => ({
  errors,
  // ToDo: this should be moved away from here to an admin specific action
  type: actionTypes.ADMIN_SET_USER_ACCESS_TOKENS_ERROR,
  userName,
});

sessionActions.clearSession = () => ({
  type: actionTypes.CLEAR_SESSION,
});

sessionActions.registerLogoutExternalHtml = (htmlDoc = '') => ({
  htmlDoc,
  type: actionTypes.LOGOUT_REGISTER_EXTERNAL_HTML,
});

sessionActions.setSessionIndex = (sessionIndex = '') => ({
  sessionIndex,
  type: actionTypes.SET_SESSION_INDEX,
});

sessionActions.startSSOTokenRetrieval = () => ({
  type: actionTypes.SSO_TOKEN_RETRIEVAL_START,
});

sessionActions.endSSOTokenRetrieval = () => ({
  type: actionTypes.SSO_TOKEN_RETRIEVAL_END,
});

export default sessionActions;
