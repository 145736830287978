import { getFormValues, initialize } from 'redux-form/immutable';
import { List } from 'immutable';
import _ from 'lodash';

// Thunk
import assetThunk from '../../actions/assetThunks';
// Globals
import CatalogCategoryNames from '../../globals/CatalogCategoryNames';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import dateFormat from '../../utils/dateFormat';

const mapStateToProps = (state) => {
  const equipments = state.getIn(['asset', 'assetItems']);

  let gear = new List();
  equipments.forEach((equip) => {
    const eq = {
      description: [],
      id: equip.get('id'),
      title: equip.get('product_name'),
    };

    const dateAcquired = equip.get('date_acquired');

    if (dateAcquired) {
      const dateFormatted = dateFormat(dateAcquired);
      eq.description.push(`Received on ${dateFormatted}`);
    }

    if (!_.isEmpty(equip.get('serial_number'))) {
      eq.description.push(`Serial Number: ${equip.get('serial_number')}`);
    }

    gear = gear.push(eq);
  });
  return {
    equipments: gear,
    formValues: getFormValues('Form01')(state),
    isLoading: state.getIn(['asset', 'isLoading']),
  };
};

const mapToDispatch = (dispatch) => ({
  /** Force to init the checkbox of each equipment to TRUE */
  initialize: (id) => {
    dispatch(
      assetThunk.loadAssets(CatalogCategoryNames.HARDWARE, (assets) => {
        const initialValues = {};
        assets.forEach((asset, i) => {
          initialValues[id + i] = true;
        });
        dispatch(initialize('Form01', initialValues));
      })
    );
  },
  submitEquipment: (id, equipments, formValues, cb) => {
    const assetIDtoRemove = [];

    equipments.forEach((equipment, i) => {
      const value = formValues.get(id + i);
      if (!value) {
        assetIDtoRemove.push(Number(equipment.id));
      }
    });

    if (assetIDtoRemove.length) {
      // Remove asset
      dispatch(assetThunk.removeArrayOfAssets(assetIDtoRemove)).then(() => {
        cb();
      });
    } else {
      cb();
    }
  },
});

export default buildCompositeController(mapStateToProps, mapToDispatch);
