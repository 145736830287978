const FilterInputTypes = {
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  DROPRADIO: 'dropradio',
  LOCATION: 'location',
  RADIO: 'radio',
  USER: 'user',
};

export default Object.freeze(FilterInputTypes);
