//
// index.js
//
// This file is autogenerated from 'gulpSVG' gulp file.
// Template for this is in '/templates/index.handlebars'
//
// Please don't modify it by hand.
//
// If you need to add a new SVG, add the svg file in the images folder
// then run the gulp script: gulp --gulpfile ./gulpSvg.js
//
//
import React, { PureComponent } from 'react';
import AccessoriesBackpacks from './AccessoriesBackpacks';
import AccessoriesCases from './AccessoriesCases';
import AccessoriesDongles from './AccessoriesDongles';
import AccessoriesHeadsets from './AccessoriesHeadsets';
import AccessoriesKeyboard from './AccessoriesKeyboard';
import AccessoriesMemoryCards from './AccessoriesMemoryCards';
import AccessoriesOther from './AccessoriesOther';
import AccessoriesPowerAdaptors from './AccessoriesPowerAdaptors';
import AccessoriesPowerStrips from './AccessoriesPowerStrips';
import AccessoriesScanners from './AccessoriesScanners';
import AccessoriesStorageDevices from './AccessoriesStorageDevices';
import AccessoriesVpnToken from './AccessoriesVpnToken';
import AccessoriesWebcams from './AccessoriesWebcams';
import Accessories from './Accessories';
import CommunicationConferencePhones from './CommunicationConferencePhones';
import CommunicationHeadsets from './CommunicationHeadsets';
import CommunicationMicrophones from './CommunicationMicrophones';
import CommunicationMobilePhones from './CommunicationMobilePhones';
import CommunicationOther from './CommunicationOther';
import CommunicationPagers from './CommunicationPagers';
import CommunicationPhoneAccessories from './CommunicationPhoneAccessories';
import CommunicationPhonePlans from './CommunicationPhonePlans';
import CommunicationVideoConferencing from './CommunicationVideoConferencing';
import CommunicationVoipPhones from './CommunicationVoipPhones';
import Communication from './Communication';
import CompanyStoreBackpacks from './CompanyStoreBackpacks';
import CompanyStoreHats from './CompanyStoreHats';
import CompanyStoreJackets from './CompanyStoreJackets';
import CompanyStoreKeychains from './CompanyStoreKeychains';
import CompanyStoreLongSleeve from './CompanyStoreLongSleeve';
import CompanyStoreMugs from './CompanyStoreMugs';
import CompanyStoreOther from './CompanyStoreOther';
import CompanyStoreShortSleeve from './CompanyStoreShortSleeve';
import CompanyStoreStickers from './CompanyStoreStickers';
import CompanyStore from './CompanyStore';
import FurnitureBookshelves from './FurnitureBookshelves';
import FurnitureChairs from './FurnitureChairs';
import FurnitureDesks from './FurnitureDesks';
import FurnitureDisplayMounts from './FurnitureDisplayMounts';
import FurnitureFileCabinets from './FurnitureFileCabinets';
import FurnitureLighting from './FurnitureLighting';
import FurnitureOther from './FurnitureOther';
import FurnitureSitStandDesks from './FurnitureSitStandDesks';
import FurnitureTables from './FurnitureTables';
import FurnitureWhiteboards from './FurnitureWhiteboards';
import Furniture from './Furniture';
import HardwareAccessory from './HardwareAccessory';
import HardwareDesktop from './HardwareDesktop';
import HardwareHeadsets from './HardwareHeadsets';
import HardwareLaptop from './HardwareLaptop';
import HardwareMonitor from './HardwareMonitor';
import HardwareOther from './HardwareOther';
import HardwarePhone from './HardwarePhone';
import HardwarePrinter from './HardwarePrinter';
import HardwareProjector from './HardwareProjector';
import HardwareScanner from './HardwareScanner';
import HardwareTablet from './HardwareTablet';
import Hardware from './Hardware';
import OfficeSuppliesDryEraseMarkers from './OfficeSuppliesDryEraseMarkers';
import OfficeSuppliesEnvelopes from './OfficeSuppliesEnvelopes';
import OfficeSuppliesErasers from './OfficeSuppliesErasers';
import OfficeSuppliesOther from './OfficeSuppliesOther';
import OfficeSuppliesPaper from './OfficeSuppliesPaper';
import OfficeSuppliesPens from './OfficeSuppliesPens';
import OfficeSuppliesScotchTape from './OfficeSuppliesScotchTape';
import OfficeSuppliesSharpies from './OfficeSuppliesSharpies';
import OfficeSuppliesStickies from './OfficeSuppliesStickies';
import OfficeSupplies from './OfficeSupplies';
import OtherCareerAssistance from './OtherCareerAssistance';
import OtherContinuingEducation from './OtherContinuingEducation';
import OtherCounseling from './OtherCounseling';
import OtherHrForms from './OtherHrForms';
import OtherLegalServices from './OtherLegalServices';
import OtherMentorProgram from './OtherMentorProgram';
import OtherOther from './OtherOther';
import OtherTrainingCourses from './OtherTrainingCourses';
import Other from './Other';
import ServicesCareerAssistance from './ServicesCareerAssistance';
import ServicesDataRecovery from './ServicesDataRecovery';
import ServicesEmployeeGym from './ServicesEmployeeGym';
import ServicesLegalServices from './ServicesLegalServices';
import ServicesLunchPrograms from './ServicesLunchPrograms';
import ServicesOther from './ServicesOther';
import ServicesPhonePlans from './ServicesPhonePlans';
import ServicesRecreation from './ServicesRecreation';
import ServicesRideSharing from './ServicesRideSharing';
import ServicesSpecialCatering from './ServicesSpecialCatering';
import ServicesTrainingClasses from './ServicesTrainingClasses';
import Services from './Services';
import SoftwareBusiness from './SoftwareBusiness';
import SoftwareCollaboration from './SoftwareCollaboration';
import SoftwareCreative from './SoftwareCreative';
import SoftwareDevelopment from './SoftwareDevelopment';
import SoftwareManagement from './SoftwareManagement';
import SoftwareOffice from './SoftwareOffice';
import SoftwareOs from './SoftwareOs';
import SoftwareOther from './SoftwareOther';
import SoftwareProjectAndTasking from './SoftwareProjectAndTasking';
import SoftwareVpn from './SoftwareVpn';
import Software from './Software';

class Catalog extends PureComponent {
  render() {
    const styleError = { color: '#a00' };
    return <p style={styleError}>{'ERROR: Please import a specific image.'}</p>;
  }
}

Catalog.AccessoriesBackpacks = AccessoriesBackpacks;
Catalog.AccessoriesCases = AccessoriesCases;
Catalog.AccessoriesDongles = AccessoriesDongles;
Catalog.AccessoriesHeadsets = AccessoriesHeadsets;
Catalog.AccessoriesKeyboard = AccessoriesKeyboard;
Catalog.AccessoriesMemoryCards = AccessoriesMemoryCards;
Catalog.AccessoriesOther = AccessoriesOther;
Catalog.AccessoriesPowerAdaptors = AccessoriesPowerAdaptors;
Catalog.AccessoriesPowerStrips = AccessoriesPowerStrips;
Catalog.AccessoriesScanners = AccessoriesScanners;
Catalog.AccessoriesStorageDevices = AccessoriesStorageDevices;
Catalog.AccessoriesVpnToken = AccessoriesVpnToken;
Catalog.AccessoriesWebcams = AccessoriesWebcams;
Catalog.Accessories = Accessories;
Catalog.CommunicationConferencePhones = CommunicationConferencePhones;
Catalog.CommunicationHeadsets = CommunicationHeadsets;
Catalog.CommunicationMicrophones = CommunicationMicrophones;
Catalog.CommunicationMobilePhones = CommunicationMobilePhones;
Catalog.CommunicationOther = CommunicationOther;
Catalog.CommunicationPagers = CommunicationPagers;
Catalog.CommunicationPhoneAccessories = CommunicationPhoneAccessories;
Catalog.CommunicationPhonePlans = CommunicationPhonePlans;
Catalog.CommunicationVideoConferencing = CommunicationVideoConferencing;
Catalog.CommunicationVoipPhones = CommunicationVoipPhones;
Catalog.Communication = Communication;
Catalog.CompanyStoreBackpacks = CompanyStoreBackpacks;
Catalog.CompanyStoreHats = CompanyStoreHats;
Catalog.CompanyStoreJackets = CompanyStoreJackets;
Catalog.CompanyStoreKeychains = CompanyStoreKeychains;
Catalog.CompanyStoreLongSleeve = CompanyStoreLongSleeve;
Catalog.CompanyStoreMugs = CompanyStoreMugs;
Catalog.CompanyStoreOther = CompanyStoreOther;
Catalog.CompanyStoreShortSleeve = CompanyStoreShortSleeve;
Catalog.CompanyStoreStickers = CompanyStoreStickers;
Catalog.CompanyStore = CompanyStore;
Catalog.FurnitureBookshelves = FurnitureBookshelves;
Catalog.FurnitureChairs = FurnitureChairs;
Catalog.FurnitureDesks = FurnitureDesks;
Catalog.FurnitureDisplayMounts = FurnitureDisplayMounts;
Catalog.FurnitureFileCabinets = FurnitureFileCabinets;
Catalog.FurnitureLighting = FurnitureLighting;
Catalog.FurnitureOther = FurnitureOther;
Catalog.FurnitureSitStandDesks = FurnitureSitStandDesks;
Catalog.FurnitureTables = FurnitureTables;
Catalog.FurnitureWhiteboards = FurnitureWhiteboards;
Catalog.Furniture = Furniture;
Catalog.HardwareAccessory = HardwareAccessory;
Catalog.HardwareDesktop = HardwareDesktop;
Catalog.HardwareHeadsets = HardwareHeadsets;
Catalog.HardwareLaptop = HardwareLaptop;
Catalog.HardwareMonitor = HardwareMonitor;
Catalog.HardwareOther = HardwareOther;
Catalog.HardwarePhone = HardwarePhone;
Catalog.HardwarePrinter = HardwarePrinter;
Catalog.HardwareProjector = HardwareProjector;
Catalog.HardwareScanner = HardwareScanner;
Catalog.HardwareTablet = HardwareTablet;
Catalog.Hardware = Hardware;
Catalog.OfficeSuppliesDryEraseMarkers = OfficeSuppliesDryEraseMarkers;
Catalog.OfficeSuppliesEnvelopes = OfficeSuppliesEnvelopes;
Catalog.OfficeSuppliesErasers = OfficeSuppliesErasers;
Catalog.OfficeSuppliesOther = OfficeSuppliesOther;
Catalog.OfficeSuppliesPaper = OfficeSuppliesPaper;
Catalog.OfficeSuppliesPens = OfficeSuppliesPens;
Catalog.OfficeSuppliesScotchTape = OfficeSuppliesScotchTape;
Catalog.OfficeSuppliesSharpies = OfficeSuppliesSharpies;
Catalog.OfficeSuppliesStickies = OfficeSuppliesStickies;
Catalog.OfficeSupplies = OfficeSupplies;
Catalog.OtherCareerAssistance = OtherCareerAssistance;
Catalog.OtherContinuingEducation = OtherContinuingEducation;
Catalog.OtherCounseling = OtherCounseling;
Catalog.OtherHrForms = OtherHrForms;
Catalog.OtherLegalServices = OtherLegalServices;
Catalog.OtherMentorProgram = OtherMentorProgram;
Catalog.OtherOther = OtherOther;
Catalog.OtherTrainingCourses = OtherTrainingCourses;
Catalog.Other = Other;
Catalog.ServicesCareerAssistance = ServicesCareerAssistance;
Catalog.ServicesDataRecovery = ServicesDataRecovery;
Catalog.ServicesEmployeeGym = ServicesEmployeeGym;
Catalog.ServicesLegalServices = ServicesLegalServices;
Catalog.ServicesLunchPrograms = ServicesLunchPrograms;
Catalog.ServicesOther = ServicesOther;
Catalog.ServicesPhonePlans = ServicesPhonePlans;
Catalog.ServicesRecreation = ServicesRecreation;
Catalog.ServicesRideSharing = ServicesRideSharing;
Catalog.ServicesSpecialCatering = ServicesSpecialCatering;
Catalog.ServicesTrainingClasses = ServicesTrainingClasses;
Catalog.Services = Services;
Catalog.SoftwareBusiness = SoftwareBusiness;
Catalog.SoftwareCollaboration = SoftwareCollaboration;
Catalog.SoftwareCreative = SoftwareCreative;
Catalog.SoftwareDevelopment = SoftwareDevelopment;
Catalog.SoftwareManagement = SoftwareManagement;
Catalog.SoftwareOffice = SoftwareOffice;
Catalog.SoftwareOs = SoftwareOs;
Catalog.SoftwareOther = SoftwareOther;
Catalog.SoftwareProjectAndTasking = SoftwareProjectAndTasking;
Catalog.SoftwareVpn = SoftwareVpn;
Catalog.Software = Software;

export default Catalog;
