import React from 'react';
import pt from 'prop-types';
import { Header } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import styles from './BrandingContent.module.scss';

const propTypes = {
  // Optional section description
  description: pt.string,
  // This is an iconify icon object
  iconObject: pt.exact({
    body: pt.string,
    height: pt.number,
    width: pt.number,
  }),
  // Section safe area overlay to show the correct grid
  safeAreaImgUrl: pt.string.isRequired,
  // Section title
  title: pt.string.isRequired,
};

const BrandingContentSvgSafearea = ({
  description,
  iconObject,
  safeAreaImgUrl,
  title,
}) => {
  return (
    <>
      <Header as='h3' content={title} />
      {description && <p>{description}</p>}
      <div className={styles.SafeArea}>
        <img alt={title} className={styles.Overlay} src={safeAreaImgUrl} />
        <Icon
          className='primary icon' // This class combination is needed for any SVG to respond to the brand schemes in our apps
          height='100%'
          icon={iconObject}
          width='100%'
        />
      </div>
    </>
  );
};

BrandingContentSvgSafearea.propTypes = propTypes;

export default BrandingContentSvgSafearea;
