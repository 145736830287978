const api_case_mgmt_v0_1_service_team_default_team = {
  classification: 1,
  description: 'Bad',
  eid: 'a30cda4e-3f8d-42ac-8e8b-5df361074856',
  groups: [],
  id: 20,
  images: [],
  name: 'QA',
  service_department: {
    auth_group_id: 27,
    description: 'Test second department',
    display_name: 'IT test',
    eid: '87561ee1-ed72-4757-9e49-80a41bb36a3b',
    id: 4,
    service_department: 'IT',
    sys_created_by: 'pascal@espressive.com',
    sys_custom_fields: null,
    sys_date_created: '2018-03-26T23:18:39.595248+00:00',
    sys_date_updated: '2018-03-26T23:18:39.595210+00:00',
    sys_updated_by: 'pascal@espressive.com',
  },
  sys_created_by: 'gg65464532181+manualtesting@gmail.com',
  sys_date_created: '2018-04-05T17:26:16.059204Z',
  sys_date_updated: '2018-04-05T18:40:25.034632Z',
  sys_updated_by: 'gg65464532181+manualtesting@gmail.com',
  team_lead: 1989,
  team_members: [
    {
      auth_group: 'SERVICE_AGENT',
      id: 69,
      user: 30,
    },
    {
      auth_group: 'SERVICE_AGENT',
      id: 70,
      user: 5262,
    },
    {
      auth_group: 'SERVICE_LEAD',
      id: 73,
      user: 11,
    },
  ],
  url:
    'https://release.qa.espressive.com/api/case_mgmt/v0.1/service_team/20/?format=json',
};

export default api_case_mgmt_v0_1_service_team_default_team;
