import {
  Button,
  Form,
  Header,
  Label,
  Segment,
  Select,
} from 'semantic-ui-react';
import { curry, memoize } from 'lodash';
import React, { PureComponent } from 'react';

import DepartmentField from './DepartmentField';
import { Field } from 'redux-form';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LocationField from './LocationField';
import PropTypes from 'prop-types';
// controllers
import ScheduleGroupSegmentController from '../controllers/ScheduleGroupSegmentController';
// Global
import TypeStateGroup from '../../globals/TypeStateGroup';
// molecules
import ValidatedCheckbox from './ValidatedCheckbox';

const checkGroup = (groups, listToUpdate, nodesByID) => {
  let listUpdated = listToUpdate;
  groups.forEach((group) => {
    const node = nodesByID.get(group);
    if (node) {
      listUpdated = listUpdated.push(node);
    }
  });
  return listUpdated;
};

const loadGroup = (groups, nodeById, action, alreadyExist) => {
  if (!groups.isEmpty()) {
    groups.forEach((group, i) => {
      if (!alreadyExist && !nodeById.get(group)) {
        // Only load location not found
        action(group);
      } else if (alreadyExist && nodeById.get(group)) {
        const end = groups.size === i + 1;
        action(nodeById.get(group), end);
      }
    });
  }
};

class ScheduleGroupSegment extends PureComponent {
  static propTypes = {
    /** Change value a redux form reducer for a group field */
    changeGroupField: PropTypes.func.isRequired,
    /** Department node */
    departmentNodesByID: ImmutablePropTypes.map.isRequired,
    /** ReduxForm 'field' prop coming from FieldArray */
    formFields: PropTypes.shape({
      forEach: PropTypes.func,
      length: PropTypes.number,
      name: PropTypes.string,
      remove: PropTypes.func,
    }).isRequired,
    /** ReduxForm 'field' index coming from FieldArray */
    formIndex: PropTypes.number.isRequired,
    /** ReduxForm 'field' member coming from FieldArray */
    formMember: PropTypes.string.isRequired,
    formValues: ImmutablePropTypes.map, // values of the form where this is being used
    /** Load Department data */
    getDefaultSelectedDepartment: PropTypes.func.isRequired,
    /** Load location data */
    getDefaultSelectedLocation: PropTypes.func.isRequired,
    /** Group deprtments from redux form */
    groupDepartments: ImmutablePropTypes.list.isRequired,
    /** Group job_roles from redux form */
    groupJobRoles: ImmutablePropTypes.list.isRequired,
    /** Group locations from redux form */
    groupLocations: ImmutablePropTypes.list.isRequired,
    isLoadingGroup: PropTypes.bool,
    jobRoles: ImmutablePropTypes.list,
    /** Load job role */
    loadJobRoles: PropTypes.func.isRequired,

    /** Location node */
    locationNodesByID: ImmutablePropTypes.map.isRequired,
    readOnly: PropTypes.bool,
    schedule: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    formValues: Immutable.Map(),
    isLoadingGroup: false,
    jobRoles: null,
    readOnly: false,
  };

  constructor(props) {
    super(props);

    const locations =
      !props.groupLocations.isEmpty() && !props.locationNodesByID.isEmpty()
        ? checkGroup(
            props.groupLocations,
            Immutable.List(),
            props.locationNodesByID
          )
        : Immutable.List();

    const departments =
      !props.groupDepartments.isEmpty() && !props.departmentNodesByID.isEmpty()
        ? checkGroup(
            props.groupDepartments,
            Immutable.List(),
            props.departmentNodesByID
          )
        : Immutable.List();

    const newState = {};
    newState[TypeStateGroup.departments] = departments;
    newState[TypeStateGroup.locations] = locations;
    newState[TypeStateGroup.selectedJobRoles] = Immutable.List();

    this.state = newState;
  }

  componentDidMount() {
    const { jobRoles, loadJobRoles } = this.props;

    if (!jobRoles || jobRoles.isEmpty()) {
      loadJobRoles();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedJobRoles } = this.state;

    if (
      !nextProps.isLoadingGroup &&
      nextProps.departmentNodesByID.isEmpty() &&
      nextProps.locationNodesByID.isEmpty()
    ) {
      // Don't load department and location if we are loading them
      loadGroup(
        nextProps.groupLocations,
        nextProps.locationNodesByID,
        nextProps.getDefaultSelectedLocation
      );
      loadGroup(
        nextProps.groupDepartments,
        nextProps.departmentNodesByID,
        nextProps.getDefaultSelectedDepartment
      );
    } else {
      // Check if we already get the data needed for location and department
      let { departments } = this.state;
      let { locations } = this.state;

      const updateDepartment = (department, end) => {
        const alreadySelected = departments.some(
          (d) => d.get('id') === department.get('id')
        );
        if (alreadySelected) {
          return;
        }
        departments = departments.push(department);

        // ON end, update the state
        if (end) {
          this.setState({
            departments,
          });
        }
      };
      const updateLocation = (location, end) => {
        const alreadySelected = locations.some(
          (d) => d.get('id') === location.get('id')
        );
        if (alreadySelected) {
          return;
        }
        locations = locations.push(location);

        // ON end, update the state
        if (end) {
          this.setState({
            locations,
          });
        }
      };

      loadGroup(
        nextProps.groupLocations,
        nextProps.locationNodesByID,
        updateLocation,
        true
      );
      loadGroup(
        nextProps.groupDepartments,
        nextProps.departmentNodesByID,
        updateDepartment,
        true
      );
    }

    if (
      nextProps.jobRoles &&
      !nextProps.groupJobRoles.isEmpty() &&
      selectedJobRoles.isEmpty()
    ) {
      const newSelectedJobRoles = nextProps.groupJobRoles.filter((job) =>
        nextProps.jobRoles.find((j) => j.get('value') === job)
      );
      this.setState({
        selectedJobRoles: newSelectedJobRoles,
      });
    }
  }

  handleRemoveField = () => {
    // Remove this groups from redux form fieldarray
    const { formFields, formIndex } = this.props;
    const confirmFoolLintJS = window.confirm;
    if (confirmFoolLintJS('Are you sure you want to delete this group?')) {
      formFields.remove(formIndex); // local form delete
    }
  };

  handleChangeIncludeRemote = (e, val) => {
    const { schedule, changeGroupField, formMember } = this.props;
    const idSchedule = schedule.get('id') || 'new';
    changeGroupField(val, formMember, idSchedule, 'include_remote');
  };

  handleChangeJobRoles = (e, data) => {
    const { schedule, changeGroupField, formMember } = this.props;
    const idSchedule = schedule.get('id') || 'new';
    changeGroupField(data.value, formMember, idSchedule, 'job_roles');

    this.setState({
      selectedJobRoles: Immutable.List(data.value),
    });
  };

  onHandleRemove = (id, type) => {
    if (!TypeStateGroup[type]) {
      return; // Error with te type send - should be 'departments' or 'locations'
    }

    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    let selectedType = this.state[type];
    selectedType = selectedType.filterNot((select) => select.get('id') === id);

    this.updateState(selectedType, type);
  };

  onHandleSelect = (data, type) => {
    if (!TypeStateGroup[type]) {
      return; // Error with te type send - should be 'departments' or 'locations'
    }
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    const selectedType = this.state[type];
    const alreadySelected = selectedType.some(
      (l) => l.get('id') === data.get('id')
    );

    if (alreadySelected) {
      return;
    }

    this.updateState(selectedType.push(data), type);
  };

  updateState = (selectedType, type) => {
    const { schedule, changeGroupField, formMember } = this.props;

    const selectedArray = [];
    selectedType.forEach((dep) => {
      selectedArray.push(dep.get('id'));
    });
    const idSchedule = schedule.get('id') || 'new';
    changeGroupField(selectedArray, formMember, idSchedule, type);

    const newState = {};
    newState[type] = selectedType;
    this.setState(newState);
  };

  handleIgnoreClick = (e) => {
    e.preventDefault();
  };

  createJobRoleField = ({ disabled, options }) => {
    const { formValues, jobRoles } = this.props;

    const { selectedJobRoles } = this.state;
    if (disabled) {
      const jobRolesSelected = formValues.get('job_roles');

      return (
        <Form.Field>
          <label>{'Job Roles'}</label>
          <Label.Group>
            {jobRolesSelected &&
            options.length > 0 &&
            !jobRolesSelected.isEmpty()
              ? jobRolesSelected.map((job) => {
                  const jobIndex = jobRoles.findIndex(
                    (jobrole) => jobrole.get('value') === job
                  );
                  const contentLabel =
                    jobIndex && jobIndex > -1
                      ? jobRoles.getIn([jobIndex, 'text'])
                      : '';

                  return (
                    <Label color='blue' content={contentLabel} key={job} />
                  );
                })
              : 'All'}
          </Label.Group>
        </Form.Field>
      );
    }

    const defaultValue =
      selectedJobRoles && !selectedJobRoles.isEmpty()
        ? selectedJobRoles.toJS()
        : [];

    return (
      <Form.Input
        control={Select}
        label='Job Roles'
        multiple
        onChange={this.handleChangeJobRoles}
        onClick={this.handleIgnoreClick}
        options={jobRoles.toJS()}
        placeholder='All'
        search
        value={defaultValue}
      />
    );
  };

  handleRemoveLocationsDepartments = memoize(
    curry((type, e, data) => {
      const { options } = data;
      const { value } = data;

      if (value.length < options.length) {
        // It's a remove event - search for the ID removed
        let idToRemove = null;
        options.forEach((option) => {
          if (!value.find((value) => value === option.value)) {
            idToRemove = option.value;
          }
        });

        this.onHandleRemove(idToRemove, type);
      }
    })
  );

  locationsSelectedRender = (locations) => {
    const options = [];
    const selected = [];

    if (locations && !locations.isEmpty()) {
      locations.forEach((location) => {
        options.push({
          id: location.get('id'),
          text: location.get('name'),
          value: location.get('id'),
        });
        selected.push(location.get('id'));
      });
    }

    return (
      <Form.Input
        control={Select}
        label='Locations'
        multiple
        onChange={this.handleRemoveLocationsDepartments(
          TypeStateGroup.locations
        )}
        onClick={this.handleIgnoreClick}
        options={options}
        placeholder='All'
        search
        value={selected}
      />
    );
  };

  render() {
    const {
      formIndex,
      formMember,
      formFields,
      jobRoles,
      readOnly,
      formValues,
    } = this.props;

    const { departments, locations } = this.state;
    let displayRemove = !readOnly;

    const defaultSelectedNodeID = formValues.get('location')
      ? formValues.get('location').map((loc) => loc.get('id'))
      : null;

    // If no location at all and just one grp is available, hide the "REMOVE" option.
    if (formValues.size === 1) {
      displayRemove = false;
    }
    return !formValues.isEmpty() ? (
      <Segment data-group={formIndex}>
        <Header as='h4' dividing>
          {displayRemove && (
            <Button
              className='text'
              content='Remove'
              floated='right'
              onClick={this.handleRemoveField}
              type='button'
            />
          )}
          {`Group ${Number(formIndex + 1)}`}
        </Header>

        <Field
          component={DepartmentField}
          defaultSelectedNodeID={formValues.get('department')}
          departments={departments}
          handleRemoveDepartment={this.onHandleRemove}
          handleSelectDepartment={this.onHandleSelect}
          name={`${formMember}.departments`}
          placeholder='All'
          readOnly={readOnly}
        />

        <Field
          component={LocationField}
          defaultSelectedNodeID={defaultSelectedNodeID}
          handleRemoveLocation={this.onHandleRemove}
          handleSelectLocation={this.onHandleSelect}
          locations={locations}
          locationsSelectedRender={this.locationsSelectedRender}
          name={`${formMember}.locations`}
          placeholder='All'
          readOnly={readOnly}
        />

        <ValidatedCheckbox
          disabled={readOnly}
          label='Include remote workers from target location'
          name={`${formMember}.include_remote`}
          onChange={this.handleChangeIncludeRemote}
        />

        {jobRoles && (
          <Field
            component={this.createJobRoleField}
            disabled={readOnly}
            label='Job Roles'
            name={`${formMember}.job_roles`}
            onChange={this.handleChangeJobRoles}
            options={jobRoles.toJS()}
            search
            upward={
              formFields.length > 1 && formIndex + 1 === formFields.length
            }
          />
        )}
      </Segment>
    ) : null;
  }
}

export const ScheduleGroupSegmentTest = ScheduleGroupSegment;

export default ScheduleGroupSegmentController(ScheduleGroupSegment);
