import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable, { fromJS } from 'immutable';
import { noop } from 'lodash';

// molecules
import AnnouncementGroupSegment from './AnnouncementGroupSegment';

// Package
import { intl } from 'esp-util-intl';

class AnnouncementGroup extends PureComponent {
  static propTypes = {
    addNewGroup: PropTypes.func,
    announcement: ImmutablePropTypes.map.isRequired,
    fields: PropTypes.shape({
      forEach: PropTypes.func,
      get: PropTypes.func,
      length: PropTypes.number,
      map: PropTypes.func,
      name: PropTypes.string,
      push: PropTypes.func,
    }).isRequired, // ReduxForm 'field' prop coming from FieldArray
    formValues: ImmutablePropTypes.map, // values of the form where this is being used
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    addNewGroup: noop,
    formValues: Immutable.Map(),
    readOnly: false,
  };

  componentDidMount() {
    const { fields } = this.props;
    // If no group, create one
    if (fields.length === 0) {
      this.handleAddGroup();
    }
  }

  handleAddGroup = () => {
    const { announcement, addNewGroup, fields } = this.props;

    // Pushing the group after it has been added to the announcement
    addNewGroup(announcement.get('id'), (res) => {
      fields.push(fromJS(res));
    });
  };

  render() {
    const { fields, announcement, readOnly, formValues } = this.props;

    return (
      <>
        {fields.map((member, index, fields) => {
          const indexLint = index;

          // We only want to render groups here when the announcement is new
          // OR when a group has an id (which means we've loaded it from the individual API or added it manually)
          // otherwise this will render a group based on the data in the announcement list
          // that contains incomplete data (for optimization)
          // that may lead us to calculate recipent totals with wrong assumptions on location, department, etc.
          // and if AnnouncementGroupSegmentController#changeGroupField resolves before AnnouncementManageController#loadAnnouncement then we have a race condition
          // because the number of group recipents gets overwritten by AnnouncementGroupSegmentController#changeGroupField
          // which was calculated with the wrong assumptions
          // See DEV-5853
          if (
            announcement.get('id') === 'new' ||
            (fields.get(index) && fields.get(index).get('id'))
          ) {
            return (
              <Segment key={indexLint}>
                <AnnouncementGroupSegment
                  announcement={announcement}
                  formFields={fields}
                  formIndex={index}
                  formMember={member}
                  formValues={formValues}
                  readOnly={readOnly}
                />
              </Segment>
            );
          }
          return null;
        })}

        {!readOnly ? (
          <Button
            basic
            content={intl.formatMessage({
              id: 'label.add_group',
            })}
            onClick={this.handleAddGroup}
            style={{
              display: 'block',
              margin: '0 auto',
            }}
            type='button'
          />
        ) : null}
      </>
    );
  }
}

export default AnnouncementGroup;
