/**
 * Sends a message to the parent frame, if the app is running as a widget
 * @param {string} type
 * @param {{[key: string]: any}} message
 */
function sendMessageToParentFrame(type, message) {
  window.parent.postMessage(
    {
      type,
      ...message,
    },
    '*'
  );
}

export default sendMessageToParentFrame;
