import React from 'react';
import PropTypes from 'prop-types';
import EntriesGroup from './EntriesGroup';
import { FieldArray } from 'redux-form/immutable';

const propTypes = {
  name: PropTypes.string.isRequired,
  nameField: PropTypes.string.isRequired,
};

const ValidatedMultipleEntries = ({ name, nameField, ...rest }) => (
  <FieldArray
    {...rest}
    component={EntriesGroup}
    inputName={name}
    name={nameField}
  />
);

ValidatedMultipleEntries.propTypes = propTypes;

export default ValidatedMultipleEntries;
