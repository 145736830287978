import localStorageMemory from 'localstorage-memory';

const NOOP = () => {};

class LocalStorageAdapter {
  constructor() {
    try {
      window.localStorage.setItem(
        'TEST_BROKEN_LOCAL_STORAGE',
        'TEST_BROKEN_LOCAL_STORAGE'
      );
      window.localStorage.removeItem('TEST_BROKEN_LOCAL_STORAGE');
      this.localStorage = window.localStorage;
    } catch (e) {
      this.localStorage = localStorageMemory;
    }
  }

  getItem(dataKey) {
    let finalValue;

    try {
      const storedValue = this.localStorage.getItem(dataKey);
      const envelope = JSON.parse(storedValue) || {};
      const { dataType, data } = envelope;

      finalValue = data;

      // DEV-15758: If the value was stored by legacy code
      if (!dataType) {
        return finalValue;
      }

      if (data && typeof data !== dataType) {
        finalValue = JSON.parse(data);
      }

      return finalValue;
    } catch (err) {
      // eslint-disable-next-line no-console -- we need this as a developer message
      console.warn(
        `Error while trying to read ${dataKey} from localStorage`,
        err
      );
    }

    return finalValue;
  }

  setItem(dataKey, dataValue) {
    try {
      const dataType = typeof dataValue;
      const envelope = JSON.stringify({
        data: dataValue,
        dataKey,
        dataType,
      });

      this.localStorage.setItem(dataKey, envelope);

      // in cordova, the localStorage might be flushed cleared by the OS for performance reasons
      // So it's not quite persistent. We use NativeStorage to add a persistency layer.
      // See https://stackoverflow.com/questions/32761099/cordova-ios-app-loosing-value-stored-using-window-localstorage
      if (window.NativeStorage) {
        window.NativeStorage.setItem(dataKey, envelope, NOOP, NOOP);
      }
    } catch (err) {
      // eslint-disable-next-line no-console -- we need this as a developer message
      console.warn(
        `Error while trying to write ${dataKey} to localStorage`,
        err
      );
    }
  }

  removeItem(dataKey) {
    try {
      this.localStorage.removeItem(dataKey);

      // Removing this in the Native storage layer as well
      if (window.NativeStorage) {
        window.NativeStorage.remove(dataKey, NOOP, NOOP);
      }
    } catch (err) {
      // eslint-disable-next-line no-console -- we need this as a developer message
      console.warn(
        `Error while trying to remove ${dataKey} from localStorage`,
        err
      );
    }
  }
}

export default LocalStorageAdapter;
