const IntegrationsImages = {
  '/images/avatars/system_avatar_01.svg': require('../../../../lib/images/avatars/system_avatar_01.svg'),
  '/images/avatars/system_avatar_02.svg': require('../../../../lib/images/avatars/system_avatar_02.svg'),
  '/images/avatars/system_avatar_03.svg': require('../../../../lib/images/avatars/system_avatar_03.svg'),
  '/images/avatars/system_avatar_04.svg': require('../../../../lib/images/avatars/system_avatar_04.svg'),
  '/images/avatars/system_avatar_05.svg': require('../../../../lib/images/avatars/system_avatar_05.svg'),
  '/images/avatars/system_avatar_06.svg': require('../../../../lib/images/avatars/system_avatar_06.svg'),
};

export default IntegrationsImages;
