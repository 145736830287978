import actionTypes from '../actions/actionTypes';
import { fromJS } from 'immutable';
import _ from 'lodash';

const INITIAL_STATE = fromJS({
  //
  // 'name' of one of utils/SearchContexts.
  // @type {string}
  //
  contextName: null,
  /** @type {Object} */
  error: null,
  /** @type {string} */
  isLoading: false,
  /** @type {Array} */
  results: null,
  /** @type {string} */
  userInput: '',
  /** @type {Object} - Sub reducer for the FormSelectuserInput */
  userSearch: {
    defaultSelection: null,
    error: null,
    isLoading: false,
    results: null,
    userInput: '',
  },
});

const setSearchUserInput = (state, userInput, userSearch) => {
  let rootState = userSearch ? state.get('userSearch') : state;

  // set 'userInput' as is
  rootState = rootState.set('userInput', userInput);

  const isEmpty = _.isEmpty(userInput.trim());

  if (isEmpty) {
    rootState = rootState
      .set('results', INITIAL_STATE.get('results'))
      .set('isLoading', INITIAL_STATE.get('isLoading'))
      .set('error', INITIAL_STATE.get('error'));
  }

  return userSearch ? state.set('userSearch', rootState) : rootState;
};

const searchReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SET_SEARCH_USER_INPUT:
      return setSearchUserInput(state, action.userInput);

    case actionTypes.SET_SEARCH_CONTEXT_NAME:
      return INITIAL_STATE.set('contextName', action.contextName);

    case actionTypes.SEARCH_START:
      return (
        state
          // TODO should we keep results from previous search while doing a new one?
          // .set('results', INITIAL_STATE.get('results'))
          .set('error', INITIAL_STATE.get('error'))
          .set('isLoading', true)
      );

    case actionTypes.SEARCH_SUCCESS:
      return state
        .set('isLoading', false)
        .set('results', fromJS(action.results));

    case actionTypes.SEARCH_FAIL:
      return state.set('isLoading', false).set('error', fromJS(action.error));

    case actionTypes.SET_USER_SEARCH_INPUT:
      return setSearchUserInput(state, action.userInput, true);

    case actionTypes.USER_SEARCH_START:
      return state
        .setIn(['userSearch', 'error'], INITIAL_STATE.get('error'))
        .setIn(['userSearch', 'isLoading'], true);

    case actionTypes.USER_SEARCH_SUCCESS:
      return state
        .setIn(['userSearch', 'isLoading'], false)
        .setIn(['userSearch', 'results'], fromJS(action.results));

    case actionTypes.USER_SEARCH_FAIL:
      return state
        .setIn(['userSearch', 'isLoading'], false)
        .setIn(['userSearch', 'error'], fromJS(action.error));

    case actionTypes.USER_SEARCH_SET_DEFAULT:
      return state.setIn(
        ['userSearch', 'defaultSelection'],
        fromJS(action.user)
      );

    default:
      return state;
  }
};

export default searchReducer;
