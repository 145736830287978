import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Form, Input } from 'semantic-ui-react';
// Molecule
import ValidatedField from '../../molecules/ValidatedField';
// Controller
import AddEntityValue01Controller from '../../controllers/AddEntityValue01Controller';
// Type
import TypeAddEntitySynonymValue, {
  TypeDefaults,
} from '../../../globals/blocks/TypeAddEntitySynonymValue';

class AddEntityValue01 extends PureComponent {
  static propTypes = {
    ...TypeAddEntitySynonymValue,
    archetypeEID: PropTypes.string,
    entityList: ImmutablePropTypes.list,
    isLoadingEntityList: PropTypes.bool,
    loadEntityValues: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    archetypeEID: '',
    entityList: Immutable.List(),
    isLoadingEntityList: false,
    loadEntityValues: noop,
  };

  componentDidMount() {
    const { archetypeEID, loadEntityValues } = this.props;

    loadEntityValues(archetypeEID); // Load list of value available for the primary entity of the archetypeEID
  }

  render() {
    const { entityList, id, isLoadingEntityList, template, type } = this.props;

    const options = [];
    entityList.forEach((template) => {
      options.push({
        key: template.get('eid'),
        text: template.get('name'),
        value: template.get('eid'),
      });
    });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField
          label=''
          loading={isLoadingEntityList}
          name={id}
          onChange={this.handleChange}
          onSearchChange={this.handleSetTerm}
          options={options}
          placeholder='Add a new word'
          search
        />

        {entityList.map((entity) => (
          <Form.Field key={entity.get('eid')}>
            <Input disabled readOnly value={entity.get('label')} />
          </Form.Field>
        ))}
      </div>
    );
  }
}

export const AddEntityValue01Test = AddEntityValue01;

export default AddEntityValue01Controller(AddEntityValue01);
