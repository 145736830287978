import { change } from 'redux-form';

// Thunk
import wsActions from '../../actions/wsActions';
import workflowThunks from '../../actions/workflowThunks';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  // Todo - create a selector for that
  const userRequestedFor = state.getIn([
    'workflowState',
    'sessionId',
    'userRequestedFor',
  ]);
  const selectedUser = state.getIn(['entities', 'users', userRequestedFor]);

  const initialValues = {};

  initialValues[ownProps.id] = {
    text: ownProps.modalCommentText,
  };

  // const managerId = selectedUser ? selectedUser.getIn(['manager','id']) : null;
  let managerId = selectedUser ? selectedUser.get('manager') : null;
  // Manager is an URL for now...
  if (managerId) {
    managerId = managerId.split('/');
    managerId = Number(managerId[managerId.length - 2]);
  }
  return {
    initialValues,
    managerId,
    selectedUser,
    userRequestedFor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  moveNextWorkflowTask(values) {
    dispatch(
      workflowThunks.saveAttributes(values, () =>
        dispatch(workflowThunks.moveNextWorkflowTask())
      )
    );
  },

  onChangeMessageApproved: (id, value) => {
    dispatch(change('Form01', id, value));
  },

  sendData: (data) => {
    dispatch(wsActions.send(data));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
