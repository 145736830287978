import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Divider, Form, Grid, Header, List, Segment } from 'semantic-ui-react';
import { isEmpty, noop, random } from 'lodash';

import EmailAddressRulesForm from '../../../organisms/EmailAddressRulesForm';
import SettingsEmailPolicyController from '../../../controllers/SettingsEmailPolicyController';
import { RegexTokens } from '../../../../globals/EmailAddressRuleLiterals';
import AddEmailDomainForm from '../../../organisms/AddEmailDomainForm';
import AddTestEmailAddress from '../../../organisms/AddTestEmailAddress';

class SettingsEmailPolicy extends Component {
  static propTypes = {
    addEmailDomain: PropTypes.func,
    addressRules: ImmutablePropTypes.listOf(PropTypes.string),
    emailDomains: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    getEmailDomains: PropTypes.func,
    getEmailPolicy: PropTypes.func,
    getTestEmail: PropTypes.func,
    getTestEmailEnabled: PropTypes.func,

    isAddingEmailDomain: PropTypes.bool,
    isLoadingEmailDomains: PropTypes.bool,
    isSelectingEmailDomain: PropTypes.bool,
    isTestEmailEnabled: PropTypes.bool,
    isTestEmailLoading: PropTypes.bool,
    isUpdatingEmailPolicy: PropTypes.bool,

    selectEmailDomain: PropTypes.func,

    selectedEmailDomain: ImmutablePropTypes.map,
    setTestEmail: PropTypes.func,
    setTestEmailEnabled: PropTypes.func,
    testEmail: PropTypes.string,
    updateEmailPolicy: PropTypes.func,
  };

  static defaultProps = {
    addEmailDomain: noop,
    addressRules: null,
    emailDomains: null,
    getEmailDomains: noop,
    getEmailPolicy: noop,
    getTestEmail: noop,
    getTestEmailEnabled: noop,
    isAddingEmailDomain: false,
    isLoadingEmailDomains: false,
    isSelectingEmailDomain: false,
    isTestEmailEnabled: false,
    isTestEmailLoading: false,
    isUpdatingEmailPolicy: false,

    selectEmailDomain: noop,

    selectedEmailDomain: null,
    setTestEmail: noop,
    setTestEmailEnabled: noop,
    testEmail: '',
    updateEmailPolicy: noop,
  };

  constructor(props) {
    super(props);
    // getting random value here so we can keep it as Component
    this.randValue = random(1, 100);
  }

  state = {
    isAddEmailDomainFormOpen: false,
  };

  componentDidMount() {
    const {
      getEmailDomains,
      getEmailPolicy,
      getTestEmail,
      getTestEmailEnabled,
    } = this.props;

    // tells if the component is mounted or not
    // as suggested at https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
    this._isMounted = true;

    getEmailDomains();
    getEmailPolicy();
    getTestEmail();
    getTestEmailEnabled();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddEmailDomainFormClose = () => {
    this.setState({
      isAddEmailDomainFormOpen: false,
    });
  };

  handleAddEmailDomainFormOpen = () => {
    this.setState({
      isAddEmailDomainFormOpen: true,
    });
  };

  handleSaveEmailAddressRules = (values) => {
    const { updateEmailPolicy } = this.props;

    let addressRules = values.get('addressRules').toJS();

    addressRules = addressRules
      .map((rule) => {
        if (!rule) {
          rule = '';
        }

        return rule.trim();
      })
      .filter((rule) => !isEmpty(rule));

    updateEmailPolicy(addressRules);
  };

  handleSelectEmailDomain = (e, data) => {
    const { selectEmailDomain, selectedEmailDomain } = this.props;

    const selectedEmailDomainID = selectedEmailDomain.get('id');
    const newSelectedEmailDomainID = data.value;

    if (selectedEmailDomainID !== newSelectedEmailDomainID) {
      selectEmailDomain(newSelectedEmailDomainID);
    }
  };

  handleAddEmailDomain = (values) => {
    const { addEmailDomain } = this.props;

    const domain = values.get('domain').trim();

    addEmailDomain(domain).then(() => {
      // prevents calling setState if the component unmounted before addEmailDomain Promise resolves
      if (this._isMounted) {
        this.handleAddEmailDomainFormClose();
      }
    });
  };

  evalRuleVariable(rule, variableExpression, value) {
    const variableRegex = new RegExp(variableExpression, 'g');

    const evaluatedRule = rule.replace(
      variableRegex,
      (match, optionalSubstring, substringLength) => {
        if (optionalSubstring) {
          // variable is using substring syntax
          substringLength = Number(substringLength);

          // take substring from value
          value = value.substring(0, substringLength);
        }

        return value;
      }
    );

    return evaluatedRule;
  }

  render() {
    const {
      addressRules,
      emailDomains,

      isAddingEmailDomain,
      isLoadingEmailDomains,
      isSelectingEmailDomain,
      isTestEmailEnabled,
      isTestEmailLoading,
      isUpdatingEmailPolicy,
      selectedEmailDomain,
      setTestEmail,
      setTestEmailEnabled,
      testEmail,
    } = this.props;
    const { isAddEmailDomainFormOpen } = this.state;

    let domainOptions = [];

    if (emailDomains) {
      domainOptions = emailDomains
        .map((emailDomain) => ({
          key: emailDomain.get('id'),
          text: emailDomain.get('domain'),
          value: emailDomain.get('id'),
        }))
        .toJS();
    }

    let selectedEmailDomainID;

    if (selectedEmailDomain) {
      selectedEmailDomainID = selectedEmailDomain.get('id');
    }

    // data for preview
    const fakeUser = {
      firstName: 'Jonathan',
      lastName: 'Doe',
      nickname: 'Jon',
    };

    return (
      <Segment as={Grid} attached columns={2} divided>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_global_settings',
          })}
        />

        <Grid.Row>
          <Grid.Column>
            <Header as='h4' content='Username Rules' dividing />
            <p>{'Available email rule tags:'}</p>
            <p>
              <code>{'{{nickname}}'}</code> <code>{'{{first_name}}'}</code>{' '}
              <code>{'{{last_name}}'}</code> <code>{'{{$$seq}}'}</code>{' '}
              <code>{'{{$$rand}}'}</code> <code>{'.'}</code> <code>{'_'}</code>
            </p>
            {addressRules && (
              <EmailAddressRulesForm
                form='EmailAddressRulesForm'
                initialValues={Immutable.fromJS({
                  addressRules,
                })}
                isLoading={isUpdatingEmailPolicy}
                onSubmit={this.handleSaveEmailAddressRules}
              />
            )}

            <Divider clearing fitted hidden />

            <Header as='h4' content='Domain' dividing />
            <Form>
              <Form.Select
                disabled={isLoadingEmailDomains || isSelectingEmailDomain}
                label='Company Domains'
                loading={isLoadingEmailDomains || isSelectingEmailDomain}
                onChange={this.handleSelectEmailDomain}
                options={domainOptions}
                placeholder='Select one...'
                selectOnBlur={false}
                value={selectedEmailDomainID}
              />
            </Form>
            <Divider horizontal>{'or'}</Divider>
            <AddEmailDomainForm
              emailDomains={emailDomains}
              isLoading={isAddingEmailDomain}
              isOpen={isAddEmailDomainFormOpen}
              onClose={this.handleAddEmailDomainFormClose}
              onOpen={this.handleAddEmailDomainFormOpen}
              onSubmit={this.handleAddEmailDomain}
              trigger={
                <Form.Button
                  basic
                  content='Add New Domain'
                  disabled={isLoadingEmailDomains || isSelectingEmailDomain}
                  floated='right'
                  type='button'
                />
              }
            />

            <Divider clearing fitted hidden />

            <AddTestEmailAddress
              isLoading={isTestEmailLoading}
              isTestEmailEnabled={isTestEmailEnabled}
              setTestEmail={setTestEmail}
              setTestEmailEnabled={setTestEmailEnabled}
              testEmail={testEmail}
            />

            <Divider clearing fitted hidden />
          </Grid.Column>

          <Grid.Column>
            <Header as='h4' content='Preview' dividing />
            {addressRules && selectedEmailDomain && (
              <List ordered>
                {addressRules.map((rule, i) => {
                  const key = i;

                  let previewRule = rule
                    .replace(new RegExp(RegexTokens.SEQ, 'g'), 1) // first value for $$seq is 1
                    .replace(new RegExp(RegexTokens.RAND, 'g'), this.randValue);

                  previewRule = this.evalRuleVariable(
                    previewRule,
                    RegexTokens.NICKNAME,
                    fakeUser.nickname
                  );
                  previewRule = this.evalRuleVariable(
                    previewRule,
                    RegexTokens.FIRST_NAME,
                    fakeUser.firstName
                  );
                  previewRule = this.evalRuleVariable(
                    previewRule,
                    RegexTokens.LAST_NAME,
                    fakeUser.lastName
                  );

                  return (
                    <List.Item key={key}>
                      {previewRule}
                      {'@'}
                      {selectedEmailDomain.get('domain')}
                    </List.Item>
                  );
                })}
              </List>
            )}
          </Grid.Column>
        </Grid.Row>
      </Segment>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SettingsEmailPolicy = SettingsEmailPolicyController(SettingsEmailPolicy);

export default SettingsEmailPolicy;
