import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesWebcams extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M47.35 33.12c-4.183 0-7.954 2.52-9.554 6.385-1.6 3.865-.713 8.314 2.246 11.27 2.96 2.956 7.408 3.84 11.272 2.235 3.863-1.604 6.38-5.377 6.376-9.56-.006-5.707-4.633-10.33-10.34-10.33zm-2.74 8.92c-1.248.006-2.264-1.002-2.27-2.25-.005-1.248 1.002-2.264 2.25-2.27 1.248-.005 2.264 1.002 2.27 2.25 0 1.244-1.006 2.254-2.25 2.26v.01z' />
          <path d='M63.12 65.4l2.22-1.41c8.558-7.552 11.546-19.614 7.502-30.287-4.044-10.673-14.275-17.727-25.688-17.71-11.414.015-21.625 7.097-25.64 17.78C17.5 44.46 20.52 56.513 29.1 64.04l2.07 1.38S18.95 73 18 80.04l5.86-.13s7.6-7.42 23.53-7.24c8.82.197 17.422 2.77 24.9 7.45l5.68-.08C76.86 73.72 63.12 65.4 63.12 65.4zm-15.86-4.67c-9.695-.017-17.544-7.882-17.54-17.577.004-9.694 7.86-17.554 17.553-17.563 9.695-.01 17.565 7.836 17.587 17.53.01 4.672-1.84 9.156-5.143 12.46-3.302 3.305-7.785 5.158-12.457 5.15z' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesWebcams;
