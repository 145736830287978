import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const propTypes = {
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

const ContentTestConnectionModal = ({
  isLoading,
  isSuccess,
  messageConnection,
}) => {
  const renderContentBasedOnCredentialsTest = () => {
    let component = null;
    if (isLoading) {
      component = (
        <section style={{ display: 'flex', margin: '1em' }}>
          <Button basic disabled inverted loading />
          <Header
            as='h5'
            content='Testing Authentication Settings.'
            style={{ margin: 0 }}
          />
        </section>
      );
    }
    if (
      !isLoading &&
      messageConnection &&
      !messageConnection.isConnectionValid
    ) {
      component = (
        <Header
          as='h5'
          color='red'
          content={messageConnection.message}
          icon={<Icon circular color='red' inverted name='exclamation' />}
        />
      );
    }
    if (
      !isLoading &&
      messageConnection &&
      messageConnection.isConnectionValid
    ) {
      component = component = (
        <Header
          as='h5'
          color='green'
          content={messageConnection.message}
          icon={<Icon circular color='green' inverted name='checkmark' />}
        />
      );
    }

    return <>{component}</>;
  };

  return renderContentBasedOnCredentialsTest();
};

ContentTestConnectionModal.propTypes = propTypes;
export default ContentTestConnectionModal;
