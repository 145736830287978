import getPath from './getPath';
import KbEntities from '../globals/KbEntities';
import { EspEntities } from 'esp-globals';

//
// Interpolates relative links with the entity and the ID
// @param {string} text - text which should container a markdown link with an entity and a ID
// @returns {string} string with relative links
//
const replaceInternalLink = (text) =>
  text.replace(/\[(.*?)\]\((.*?)\)/gim, (match, $1, $2) => {
    const [espEntity, espId, clickContext] = $2.split(' ');

    // is regular link
    if (
      !Object.values(EspEntities).includes(espEntity) ||
      (!espEntity === EspEntities.CATEGORY && // espId is not required for category
        !espId)
    ) {
      return match;
    }

    const isKb = KbEntities.includes(espEntity);

    let path = isKb ? `${espEntity}~~${espId}` : getPath(espEntity, espId);
    // if clickContext is present, it means that we need to send clickContext as a path param (click_context) DEV-24594
    if (clickContext) {
      path = `${espEntity}~~${espId}~~${clickContext}`;
    }

    return `[${$1}](${path})`;
  });

export default replaceInternalLink;
