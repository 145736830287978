import { useCallback, useState } from 'react';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import APIcall from '../../../../../utils/APIcall';
import { fromJS } from 'immutable';
import filterTemplateSelectedByInterval from '../../../../../utils/filterTemplateSelectedByInterval';
import { isNull, isUndefined } from 'lodash';
import async from 'async';
import EspFilters from 'esp-util-filters';
import browserHistory from '../../../../../utils/browserHistory';
import uiPathGenerator from '../../../../../utils/uiPathGenerator';

// import EspFilters from 'esp-util-filters';

const useOffboardingSchedule = () => {
  const [schedule, setSchedule] = useState({});
  const [departments, setDepartments] = useState([]);
  const [allLocations, setLocations] = useState([]);

  const [templateSelected, setTemplateSelected] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [allJobRoles, setAllJobRoles] = useState([]);

  const loadSchedule = useCallback(({ scheduleID, params }) => {
    // this needs to be replaced by the offboarding schedule url
    const url = endpointGenerator.genPath('espTodo.schedule.instance', {
      scheduleID: scheduleID,
    });

    const query = {};
    return new Promise((resolve, reject) => {
      return APIcall.get({
        query: {
          ...query,
        },
        token: true,
        url,
      })
        .then(({ body }) => {
          setSchedule(body);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);
  const loadScheduleTemplateInterval = useCallback(
    (schedule) =>
      new Promise((resolve, reject) => {
        const templateID = schedule.template;
        const scheduleIntervalID = schedule.interval;

        async.waterfall(
          [
            // 3.1 Load the template selected
            (next) => {
              APIcall.get({
                success(res) {
                  const result = res.body;
                  next(null, result);
                },
                token: true,
                url: endpointGenerator.genPath('espTodo.template.instance', {
                  templateID,
                }),
              });
            },

            // 3.2 Load the interval selected
            (resultTemplate, next) => {
              APIcall.get({
                success(res) {
                  const resultInterval = res.body;
                  const templateSelectedTemp = {
                    interval: resultInterval,
                    scheduleToTemplate: schedule,
                    template: resultTemplate,
                  };
                  setTemplateSelected(templateSelectedTemp);
                  next(null, templateSelectedTemp);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espTodo.scheduleInterval.instance',
                  {
                    scheduleIntervalID,
                  }
                ),
              });
            },
          ],
          (error, templateSelected) => {
            if (error) {
              reject(error);
            } else {
              resolve(templateSelected);
            }
          }
        );
      }),
    []
  );
  const loadOneScheduleActivity = useCallback(
    (scheduleID, schedule, provisionalType) =>
      new Promise((resolve, reject) => {
        const masterSchedule = schedule;
        async.waterfall(
          [
            // 1. Get the Schedule Activity
            (next) => {
              // This schedule already exists
              const url = endpointGenerator.genPath(
                'espTodo.schedule.instance',
                {
                  scheduleID: scheduleID,
                }
              );
              if (schedule) {
                next(null, schedule);
              } else {
                APIcall.get({
                  success(res) {
                    schedule = res.body;
                    next(null, schedule);
                  },
                  token: true,
                  url,
                });
              }
            },

            // 2. Load schedule_to_template
            (schedule, next) => {
              const esp_filters = new EspFilters()
                .in('schedule', [schedule.id])
                .asQueryString();
              const url = endpointGenerator.genPath(
                'espTodo.scheduleToTemplate'
              );
              APIcall.get({
                query: {
                  esp_filters,
                },
                success(res) {
                  const result = res.body.results;
                  next(null, result);
                },
                token: true,
                url,
              });
            },

            // 3. Load template and interval of each schedule_to_template
            (scheduleToTemplate, next) => {
              const listTemplate = [];

              async.each(
                scheduleToTemplate,
                (schedule, done) => {
                  loadScheduleTemplateInterval(schedule)
                    .then((result) => {
                      listTemplate.push({
                        ...result,
                        schedule_type: !isNull(masterSchedule.schedule_type)
                          ? masterSchedule.schedule_type
                          : provisionalType,
                      });
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                () => {
                  next(null, listTemplate);
                }
              );
            },
          ],
          (error, result) => {
            // in the end of the sequence invoking callback
            if (!error) {
              resolve(result);
            } else {
              reject(error);
            }
          }
        );
      }),
    [loadScheduleTemplateInterval]
  );

  const loadTemplatesPerSchedule = useCallback(
    ({ schedule, scheduleID }) => {
      loadOneScheduleActivity(scheduleID, schedule, 'offboarding').then(
        (selectedTemplatesRes) => {
          // in this case the util filterTemplateSelectedByInterval expects an immutable list :c and returns once as well that’s why all this back and forward of the format
          const templates = filterTemplateSelectedByInterval(
            fromJS(selectedTemplatesRes)
          ).toJS();
          setSelectedTemplates(templates);
        }
      );
    },
    [loadOneScheduleActivity]
  );
  const deleteScheduleActivity = useCallback(({ msg, scheduleID }) => {
    return new Promise((resolve, reject) => {
      APIcall.delete({
        error(err) {
          reject(err);
        },
        success() {
          browserHistory.replace(
            uiPathGenerator.genPath(
              'admin.adminActivities.offboarding.schedule'
            )
          );
          resolve(msg);
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.schedule.instance', {
          scheduleID,
        }),
      });
    });
  }, []);
  const getJobRoles = useCallback(({ id, name }) => {
    return new Promise((resolve) => {
      const url = endpointGenerator.genPath('espUser.jobRoles');
      const filters = new EspFilters();
      const query = {
        limit: 1000,
      };
      if (id || name) {
        filters.equalTo('id', id).or().contains('name', name);
      }
      APIcall.get({
        query: {
          ...query,
          esp_filters: filters.asQueryString(),
        },
        success({ body: { results } }) {
          if (id === '' && name === '') {
            setAllJobRoles(results);
          }
          resolve(results);
        },
        token: true,
        url: url,
      });
    });
  }, []);

  const getDepartments = useCallback(() => {
    const url = endpointGenerator.genPath('espUser.department');
    APIcall.get({
      success(res) {
        const response = res.body.results;
        const departments = response.map((dpt) => ({
          id: dpt.id,
          key: dpt.id,
          name: dpt.name,
          text: dpt.name,
          value: dpt.id,
        }));
        setDepartments(departments);
      },
      token: true,
      url,
    });
  }, []);

  const getLocations = useCallback((query = {}) => {
    const url = endpointGenerator.genPath('espPlaces.locations');
    APIcall.get({
      query: {
        ...query,
        limit: 1000,
      },
      success({ body: { results } }) {
        setLocations(results);
      },
      token: true,
      url,
    });
  }, []);

  const createNewGroup = useCallback(() => {
    const getUserCount = ({
      location = null,
      department = null,
      job_role = null,
      include_remote = false,
    }) => {
      return new Promise((resolve, reject) => {
        APIcall.get({
          error(err) {
            reject(err);
          },
          query: {
            department,
            include_remote,
            job_role,
            location,
          },
          success(res) {
            const count = res.body;
            resolve(count);
          },
          token: true,
          url: endpointGenerator.genPath('espUser.users.userCount'),
        });
      });
    };

    return new Promise((resolve, reject) => {
      // by default remote workers aren't targeted by announcement
      const include_remote = false;

      getUserCount({
        include_remote,
      })
        .then((userCount) => {
          const newGroup = {
            include_remote,
            user_count: userCount,
          };
          resolve(newGroup);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);
  const goToScheduleInstancePage = (id) => {
    browserHistory.replace(
      uiPathGenerator.genPath('admin.adminActivities.offboarding.schedule', {
        scheduleID: id,
      })
    );
  };
  const updateGroupsForSchedule = (groups, scheduleGroups, scheduleID) => {
    return groups.map((group, index) => {
      const groupPayload = {
        departments: group.departments,
        include_remote: group.include_remote,
        job_roles: group.job_roles,
        locations: group.locations,
      };
      const updateGroupPostUrl = endpointGenerator.genPath(
        'espTodo.schedule.instance.groups',
        {
          scheduleID,
        }
      );
      const method =
        (scheduleGroups[index] && scheduleGroups[index].id === group.id) ||
        !isUndefined(scheduleGroups[index])
          ? 'put'
          : 'post';
      const url = method === 'post' ? updateGroupPostUrl : group.url;
      if (method === 'post') {
        groupPayload.todo_schedule = scheduleID;
      }

      return new Promise((resolve) => {
        resolve();
        APIcall[method]({
          data: groupPayload,
          succes() {
            resolve();
          },
          token: true,
          url,
        });
      });
    });
  };
  const saveDraft = useCallback(
    ({ action, newStatus, formValues, scheduleID }) => {
      const payload = {
        ...formValues.administrativeRecipients,
        description: formValues.schedule_activity_description,
        groups: Object.values(formValues.departingEmployeeRecipients.groups),
        name: formValues.schedule_activity_name,
        schedule_type: 'offboarding',
        status: newStatus,
      };
      if (scheduleID !== 'new' && action !== 'copy') {
        const url = endpointGenerator.genPath('espTodo.schedule.instance', {
          scheduleID: scheduleID,
        });
        return new Promise((resolve) => {
          APIcall.put({
            data: payload,
            token: true,
            url,
          }).then(({ body }) => {
            if (payload?.groups) {
              const updateGroupsForSchedulePromises = updateGroupsForSchedule(
                payload.groups,
                body?.groups,
                scheduleID
              );
              Promise.all(updateGroupsForSchedulePromises).then(() => {
                goToScheduleInstancePage(scheduleID);
                resolve();
              });
            }
          });
        });
      } else {
        const url = endpointGenerator.genPath('espTodo.schedule');
        return new Promise((resolve) => {
          APIcall.post({
            data: payload,
            token: true,
            url,
          }).then(({ body }) => {
            goToScheduleInstancePage(body.id);
            resolve();
          });
        });
      }
    },
    []
  );
  const updateScheduleStatus = useCallback((scheduleID, status) => {
    const url = endpointGenerator.genPath(
      'espTodo.schedule.instance.changeStatus',
      {
        scheduleID,
      }
    );
    return new Promise((resolve) => {
      APIcall.post({
        data: {
          status,
        },
        success() {
          resolve();
        },
        token: true,
        url,
      });
    });
  }, []);

  const getScheduleToTemplateByID = useCallback((scheduleID) => {
    const esp_filters = new EspFilters()
      .in('schedule', [scheduleID])
      .asQueryString();
    const url = endpointGenerator.genPath('espTodo.scheduleToTemplate');
    return new Promise((resolve) => {
      APIcall.get({
        query: {
          esp_filters,
        },
        success(res) {
          const result = res.body.results;
          resolve(result);
        },
        token: true,
        url,
      });
    });
  }, []);

  const deleteScheduleToTemplate = useCallback((url, message) => {
    return new Promise((resolve) => {
      return APIcall.delete({
        success() {
          resolve(message);
        },
        token: true,
        url: url,
      });
    });
  }, []);

  return {
    allJobRoles,
    allLocations,
    createNewGroup,
    deleteScheduleActivity,
    deleteScheduleToTemplate,
    departments,
    getDepartments,
    getJobRoles,
    getLocations,
    getScheduleToTemplateByID,
    goToScheduleInstancePage,
    loadOneScheduleActivity,
    loadSchedule,
    loadTemplatesPerSchedule,
    saveDraft,
    schedule,
    selectedTemplates,
    templateSelected,
    updateScheduleStatus,
  };
};

export default useOffboardingSchedule;
