import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesStickies extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M82.314 60.97c-7.918-8.71-8.016-36.66-8.016-36.94 0-.964-.78-1.745-1.744-1.745H28.51c-.445 0-.852.167-1.16.442-.002 0-.002 0-.002.002-.025.02-.048.042-.07.066L20.5 29.57c-.326.328-.51.772-.51 1.234v38.143c.004.226.05.44.132.638.085.207.212.4.38.568.165.166.356.29.56.376.207.087.434.134.672.134h50.57c.964 0 1.745-.78 1.745-1.744v-5.032h6.974c.69 0 1.316-.407 1.595-1.04.28-.63.16-1.367-.306-1.877zm-52.06-8.792c.876 3.057 1.977 5.886 3.346 8.222h-3.345v-8.222zm-6.776-20.65l3.29-3.29v33.183l-3.29 3.29V31.526zm47.084 35.648H25.944l3.288-3.29H70.56v3.29h.002zm3.686-6.777H37.784c-6.328-7.91-7.34-28.5-7.5-34.627h40.55C70.97 31.64 71.852 50.53 77.63 60.4h-3.382z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesStickies;
