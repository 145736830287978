import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureDesks extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(9.5 24.5)'>
          <path d='M0 0v9.36h5.4v35.22c0 .85.69 1.54 1.54 1.54.85 0 1.54-.69 1.54-1.54v-6.72h17c.85 0 1.54-.69 1.54-1.54v-27h22.62v27c0 .85.69 1.54 1.54 1.54H67.8v6.59c0 .85.69 1.54 1.54 1.54.85 0 1.54-.69 1.54-1.54V9.36h5.4V0H0zm23.9 34.78H8.47V23.94H23.9v10.84zm0-13.91H8.47V9.36H23.9v11.51zm43.9 13.91H52.72V23.94H67.8v10.84zm0-13.91H52.72V9.36H67.8v11.51z' />
          <circle cx='16.38' cy='14.99' r='2.51' />
          <circle cx='60.24' cy='29.37' r='2.51' />
          <circle cx='60.24' cy='14.99' r='2.51' />
          <circle cx='16.38' cy='29.37' r='2.51' />
        </g>
      </svg>
    );
  }
}

export default CatalogFurnitureDesks;
