import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addBundles = (bundles, newBundles) => {
  newBundles.forEach((newBundle) => {
    const id = newBundle.get('id');
    bundles = bundles.set(id, newBundle);
  });

  return bundles;
};

const bundlesEntitiesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.RESET_BUNDLES_LIST:
      return INITIAL_STATE;

    case actionTypes.LOAD_BUNDLES_SUCCESS:
      return addBundles(state, fromJS(action.bundles));

    case actionTypes.LOAD_CHILD_BUNDLES_SUCCESS:
      return addBundles(state, fromJS(action.bundles));

    case actionTypes.GET_HARDWARE_BUNDLE_PRODUCTS_SUCCESS:
      // TODO can make this code a bit better
      return addBundles(
        state,
        fromJS([action.topLevelBundle, action.hardwareBundle])
      );

    case actionTypes.GET_JOB_ROLES_WITH_BUNDLES_SUCCESS:
      return addBundles(state, fromJS(action.allBundles));

    case actionTypes.BUNDLE_ADMIN_CREATE_SUCCESS:
      return addBundles(state, fromJS([action.bundle]));

    default:
      return state;
  }
};

export default bundlesEntitiesReducer;
