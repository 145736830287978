import moment from 'moment';
import { BROWSER_LANGUAGE } from '../../v1/utils/DateTools';
import ChatInputTypes from '../../v2/components/display/ChatMessage/ChatInputTypes';

const getDateTimeFormat = (comment, lastMessage) => {
  const isLastMessage = lastMessage.get('id') === comment.get('id');
  const isInputDate =
    isLastMessage &&
    comment.hasIn(['metadata', 'bot_metadata']) &&
    comment.getIn(['metadata', 'bot_metadata', 'input_type']) ===
      ChatInputTypes.DATE;

  const isInputTime =
    isLastMessage &&
    comment.hasIn(['metadata', 'bot_metadata']) &&
    comment.getIn(['metadata', 'bot_metadata', 'input_type']) ===
      ChatInputTypes.TIME;

  const isDateMsg =
    comment.hasIn(['metadata', 'bot_metadata', 'input_message']) &&
    comment.getIn(['metadata', 'bot_metadata', 'input_message']) ===
      ChatInputTypes.DATE;

  const isTimeMsg =
    comment.hasIn(['metadata', 'bot_metadata', 'input_message']) &&
    comment.getIn(['metadata', 'bot_metadata', 'input_message']) ===
      ChatInputTypes.TIME;

  const isEntityDateMsg =
    comment.hasIn(['metadata', 'param_values', 'date0', 'entity']) &&
    comment.getIn(['metadata', 'param_values', 'date0', 'entity']);

  const isEntityTimeMsg =
    comment.hasIn(['metadata', 'param_values', 'time0', 'entity']) &&
    comment.getIn(['metadata', 'param_values', 'time0', 'entity']);

  return {
    isDateTimeToLocalize:
      comment.hasIn(['metadata', 'bot_metadata', 'input_type']) ||
      comment.hasIn(['metadata', 'bot_metadata', 'input_message']) ||
      comment.hasIn(['metadata', 'param_values', 'time0', 'entity']) ||
      comment.hasIn(['metadata', 'param_values', 'date0', 'entity']),
    type:
      (isInputTime ? ChatInputTypes.INPUT_TIME : null) ||
      (isInputDate ? ChatInputTypes.INPUT_DATE : null) ||
      (isDateMsg ? ChatInputTypes.DATE : null) ||
      (isTimeMsg ? ChatInputTypes.TIME : null) ||
      (isEntityDateMsg ? ChatInputTypes.SYS_ISO_DATE : null) ||
      (isEntityTimeMsg ? ChatInputTypes.SYS_ISO_TIME : null),
  };
};
/**
 *
 * @param {String} msg
 * The original msg is: 13:00:00-06:00. This function is to remove the last digits and return the value in the local time without seconds
 * @returns Local Time
 */
const formatTimeMessage = (msg) => {
  const time = msg.slice(0, msg.length - 6);
  const today = moment(new Date()).format('YYYY-MM-DD');

  return new Date(`${today}T${time}`).toLocaleTimeString(BROWSER_LANGUAGE, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

const formatDateMessage = (msg) => moment(msg).toDate().toLocaleDateString();

/**
 *
 * @param {String} msg
 * The original msg is The time is 13:00:00-06:00. This function is to have only the time and return the original text, in this case The time is plus the time in the local value without seconds
 * @returns Local Time
 */
const formatTimeResolution = (msg) => {
  const timeFragment = msg.slice(0, msg.length - 6);
  const justTime = msg.substring(timeFragment.length - 8);
  const justLabel = msg.substring(0, timeFragment.length - 8);
  const formatTime = formatTimeMessage(justTime);
  return `${justLabel} ${formatTime}`;
};

const formatDateResolution = (msg) => {
  return msg.slice(0, msg.length - 10);
};

export {
  formatTimeMessage,
  formatDateMessage,
  formatDateResolution,
  formatTimeResolution,
  getDateTimeFormat,
};
