const ActivityTypes = {
  CHAT_REMINDER: 'CHAT_REMINDER',
  PROFILE_ACTIVITY: 'PROFILE_ACTIVITY',
  STANDARD: 'STANDARD',
  WEB_LINK: 'WEB_LINK',
};

const isPersonToContact = (type) => {
  if (
    type === ActivityTypes.CHAT_REMINDER ||
    type === ActivityTypes.PROFILE_ACTIVITY
  ) {
    return true;
  }
  return false;
};

const ActivityRecipients = [
  {
    key: 'NEW_HIRE',
    text: 'New Hire',
    value: 'NEW_HIRE',
  },
  {
    key: 'MANAGER',
    text: 'Manager',
    value: 'MANAGER',
  },
  {
    key: 'MENTOR',
    text: 'Mentor',
    value: 'MENTOR',
  },
  {
    key: 'HR_RECRUITER',
    text: 'HR Recruiter',
    value: 'HR_RECRUITER',
  },
  {
    key: 'HR_CONTACT',
    text: 'HR Contact',
    value: 'HR_CONTACT',
  },
  {
    key: 'ENABLEMENT',
    text: 'Enablement',
    value: 'ENABLEMENT',
  },
  {
    key: 'IT_CONTACT',
    text: 'IT Contact',
    value: 'IT_CONTACT',
  },
  {
    key: 'OPERATIONS',
    text: 'Operations',
    value: 'OPERATIONS',
  },
];
export { isPersonToContact, ActivityTypes };
export default ActivityRecipients;
