import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import { curry, memoize, noop } from 'lodash';

// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Molecule
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import FormInputSelect from './FormInputSelect';
import FormInputText from './FormInputText';
// import FormInputTextarea                      from './FormInputTextarea';

// Controller
import ServiceDepartmentCategoryCreateModalController from '../controllers/ServiceDepartmentCategoryCreateModalController';

const arrayFieldName = [
  'display_name',
  'description',
  'service_department',
  'technical_contact',
];

class ServiceDepartmentCategoryCreateModal extends PureComponent {
  static propTypes = {
    /* setting this prop will edit the category */
    categoryID: PropTypes.number,
    /** Save a New Department */
    createNewCategory: PropTypes.func.isRequired,
    /** service department ID */
    departmentID: PropTypes.number,
    /** Form error from redux form */
    formError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    /** Form Validation */
    formValidation: PropTypes.func.isRequired,
    /** Main loading state */
    isLoading: PropTypes.bool,
    isPristine: PropTypes.bool,
    /* loads current category if set */
    loadFormData: PropTypes.func,
    /**  loads initial data such as classifications and teams **/
    loadInitialData: PropTypes.func,
    /** Reload category after created a new one */
    reloadCategories: PropTypes.func,

    /** teams **/

    serviceDepartmentTeams: ImmutableProps.list,

    /** Modal size */
    size: PropTypes.string,

    /** classifications **/
    taskClassifications: ImmutableProps.list,
    /** Modal trigger */
    trigger: PropTypes.node.isRequired,
    /* update category task */
    updateCategory: PropTypes.func,
  };

  static defaultProps = {
    categoryID: null,
    departmentID: null,
    formError: null,
    isLoading: false,
    isPristine: false,
    loadFormData: noop,
    loadInitialData: noop,
    reloadCategories: noop,
    serviceDepartmentTeams: immutable.List(),
    size: 'small',
    taskClassifications: immutable.List(),
    updateCategory: noop,
  };

  state = {
    description: '',
    display_name: '',
    open: false,
    service_department: '',
    technical_contact: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = this.state;
    let updateState = false;

    if (nextProps.formError) {
      // Compare formError and current State
      arrayFieldName.forEach((fieldName) => {
        // eslint-disable-next-line react/destructuring-assignment -- risky to fix
        if (!nextProps.formError[fieldName] && this.state[fieldName]) {
          newState[fieldName] = '';
          updateState = true;
        }
      });
    }

    if (updateState) {
      // We have to update the state
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = true;

  handleClose = (e) => {
    e.preventDefault();
    if (this._isMounted) {
      this.setState({
        open: false,
      });
    }
  };

  handleOpen = () => {
    const { loadFormData, loadInitialData, departmentID, categoryID } =
      this.props;
    loadInitialData(departmentID);

    loadFormData(categoryID);

    this.setState({
      open: true,
    });
  };

  handleSubmitAndClose = (data) => {
    const {
      categoryID,
      createNewCategory,
      departmentID,
      updateCategory,
      reloadCategories,
    } = this.props;

    const msg = {
      header: 'Success',
      message: `The Category ${data.get('display_name')} has been ${
        categoryID ? 'updated' : 'created'
      }`,
    };

    if (categoryID) {
      updateCategory(categoryID, departmentID, data, msg, () => {
        if (this._isMounted) {
          this.setState({
            open: false,
          });
        }
      });
    } else {
      createNewCategory(departmentID, data, msg, () => {
        if (this._isMounted) {
          this.setState(
            {
              open: false,
            },
            () => {
              reloadCategories();
            }
          );
        }
        reloadCategories();
      });
    }
  };

  handleOnBlur = memoize(
    curry((name, e) => {
      e.preventDefault();

      const { formError } = this.props;
      const { state } = this;
      state[name] = formError && formError[name] ? formError[name] : '';

      this.setState(state);
    })
  );

  render() {
    const {
      categoryID,
      formError,
      formValidation,
      isLoading,
      isPristine,
      taskClassifications,
      serviceDepartmentTeams,
      size,
      trigger,
    } = this.props;
    const { open } = this.state;

    const optionTeams =
      serviceDepartmentTeams && !serviceDepartmentTeams.isEmpty()
        ? serviceDepartmentTeams
            .map((team) => ({
              key: team.get('id'),
              text: team.get('name'),
              value: team.get('id'),
            }))
            .toJS()
        : [];

    const optionDepartment = taskClassifications
      .sort((a, b) => a.get('name').localeCompare(b.get('name')))
      .map((task) => ({
        key: task.get('id'),
        text: task.get('name'),
        value: task.get('id'),
      }))
      .toJS();

    const {
      display_name,
      service_department,
      technical_contact,
      // description,
    } = this.state;

    const closeEditProfileTrigger = <div className='item close'>{'Close'}</div>;

    const formID = categoryID
      ? `ServiceDepartmentCategoryCreate-${categoryID}`
      : 'ServiceDepartmentCategoryCreate';

    return (
      <Modal
        className='scrolling'
        closeIcon={closeEditProfileTrigger}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={open}
        size={size}
        trigger={trigger}
      >
        <ValidatedForm
          form={formID}
          onSubmit={this.handleSubmitAndClose}
          validate={formValidation}
        >
          <Modal.Header content='New Category' />

          <Modal.Content className='withFooter'>
            <ScrollArea>
              <ValidatedField
                component={FormInputSelect}
                formError={formError && service_department}
                label='Espressive Classification'
                loading={false}
                name='classification'
                onBlur={this.handleOnBlur('classification')}
                onChange={this.handleOnChange}
                options={optionDepartment}
                placeholder='Select one'
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && display_name}
                label='Category Name'
                name='display_name'
                onBlur={this.handleOnBlur('display_name')}
                placeholder='Type a name'
                value='tu cola'
              />

              <Form.Group widths='equal'>
                <ValidatedField
                  component={FormInputText}
                  fluid
                  formError={formError && display_name}
                  label='Response SLA (in minutes)'
                  name='response_sla'
                  onBlur={this.handleOnBlur('response_sla')}
                  placeholder='Type a SLA'
                  type='number'
                />
                <ValidatedField
                  component={FormInputText}
                  fluid
                  formError={formError && display_name}
                  label='Resolve SLA (in minutes)'
                  name='resolve_sla'
                  onBlur={this.handleOnBlur('resolve_sla')}
                  placeholder='Type a SLA'
                  type='number'
                />
              </Form.Group>

              <p>
                {
                  "this is what Barista will use to match a user's phrase when it does not match an existing intent."
                }
              </p>

              <ValidatedField
                component={FormInputSelect}
                formError={formError && technical_contact}
                label='Teams'
                multiple
                name='teams'
                onBlur={this.handleOnBlur('teams')}
                options={optionTeams}
                placeholder='Select a team'
              />
            </ScrollArea>
          </Modal.Content>

          <Modal.Actions>
            <Button basic content='Cancel' onClick={this.handleClose} />

            <Button
              content={categoryID ? 'Save' : 'Create'}
              disabled={isPristine || Boolean(formError)}
              loading={isLoading}
              primary
            />
          </Modal.Actions>
        </ValidatedForm>
      </Modal>
    );
  }
}

const ServiceDepartmentCategoryCreateModalTest =
  ServiceDepartmentCategoryCreateModal;

// eslint-disable-next-line no-class-assign -- DEV-1526
ServiceDepartmentCategoryCreateModal =
  ServiceDepartmentCategoryCreateModalController(
    ServiceDepartmentCategoryCreateModal
  );
export { ServiceDepartmentCategoryCreateModalTest };
export default ServiceDepartmentCategoryCreateModal;
