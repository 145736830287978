import buildCompositeController from '../../utils/buildCompositeController';
import integrationThunks from '../../actions/integrationThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state) => {
  const isLoading =
    state.getIn(['integration', 'isLoadingDelete']) ||
    state.getIn(['integration', 'isLoadingSchedules']) ||
    state.getIn(['integration', 'connectionIsLoading']);
  return {
    businessObjects: state.getIn(['integration', 'businessObjects']),
    isLoading,
    scheduleConnectionSuccess: state.getIn([
      'integration',
      'scheduleConnectionSuccess',
    ]),
    schedules: state.getIn(['integration', 'schedules']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteSchedule(scheduleToDelete) {
    dispatch(integrationThunks.deleteCsvSchedule(scheduleToDelete))
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: 'Successfully unscheduled',
            title: 'Import Schedule',
          })
        );
      })
      .catch((error) => {
        dispatch(
          toastNotificationsActions.error({
            message: error.message ? error.message : error,
            title: 'Import Schedule',
          })
        );
      });
  },
  getCSVSchedule() {
    dispatch(integrationThunks.getCSVSchedule());
    dispatch(integrationThunks.loadBusinessObjectList());
  },
  testSchedule(scheduleToTest) {
    dispatch(integrationThunks.newTestCSVSchedule(scheduleToTest.get('id')));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
