import { get, has, isEmpty } from 'lodash';

/**
 * Interpolates [[{key}]] according to the values provided
 * @param {string} text - text to look up for the global variables syntax
 * @param {Pick<import('../EspMarkdown').EspMarkdownProps, 'globalVariables' | 'globalsAsUpperCase'>} config - EspMarkdown props
 * @returns {string} string with values interpolated
 */
const replaceGlobalVariables = (
  text,
  { globalVariables, globalsAsUpperCase }
) =>
  text.replace(/\[\[(\S+)\]\]/gim, (match, key) =>
    !isEmpty(globalVariables) && has(globalVariables, key)
      ? globalsAsUpperCase
        ? get(globalVariables, key).toUpperCase()
        : get(globalVariables, key)
      : match
  );

export default replaceGlobalVariables;
