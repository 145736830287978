import CaseStates from './CaseStates';
import FilterInputTypes from './FilterInputTypes';

const optionValues = {
  ALL: 'all',
  ALL_SUBSTATES: 'all_substates',
  ALPHABETICAL: 'alphabetical',
  ASCENDING: '+',
  ASSIGNED: 'owner__last_name',
  ASSIGNED_TO_SPECIFIC_USER: 'assigned_to_specific_user',
  AUTHOR: 'author__last_name',
  DATE_CREATED: 'sys_date_created',
  DESCENDING: '-',
  LAST_UPDATED: 'sys_date_updated',
  LOCATION_NAME: 'location__name',
  ME: 'me',
  REPORTED_BY_SPECIFIC_USER: 'reported_by_specific_user',
  SMART_SORT: 'smart_sort',
  SPECIFIC_LOCATION: 'specific_location',
  SPECIFIC_SUBSTATE: 'specific_substate',
  SPECIFIC_TYPE: 'specific_type',
  TIME: 'time',
  UNASSIGNED: 'unassigned',
  WITHOUT_SUBSTATE: 'without_substate',
};

const DROPDOWN = {
  input: {
    placeholder: 'label.select_substates',
  },
  options: [],
  type: FilterInputTypes.DROPDOWN,
};

const caseFilterOptions = [
  {
    checked: true,
    text: 'label.open',
    value: CaseStates.OPEN,
    ...DROPDOWN,
  },
  {
    text: 'label.resolved',
    value: CaseStates.RESOLVED,
    ...DROPDOWN,
  },
  {
    text: 'label.cancelled_by_agent',
    value: CaseStates.CANCELLED_BY_AGENT,
    ...DROPDOWN,
  },
  {
    text: 'label.discarded',
    ...DROPDOWN,
    value: CaseStates.DISCARDED,
  },
  {
    text: 'label.closed',
    ...DROPDOWN,
    value: CaseStates.CLOSED,
  },
];

export { optionValues };

export default caseFilterOptions;
