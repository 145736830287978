import React, { useContext, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import IntegrationSetupWizardFormikContext from './IntegrationSetupWizardFormikContext';
import IntegrationSetupWizardContext from './IntegrationSetupWizardContext';

const propTypes = {};

const IntegrationSetupWizardActionsButtons = () => {
  const { isValid, values } = useContext(IntegrationSetupWizardFormikContext);

  const { handleCancelOnClick, handleBackOnClick, state, handleNextOnClick } =
    useContext(IntegrationSetupWizardContext);

  const { list, isOpen } = state;

  const shouldDisable =
    !isValid && Boolean(list?.getCurrentNode()?.initialValues);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        const isLeftArrowKey = event.keyCode === 37;
        const isRightArrowKey = event.keyCode === 39;
        if (isLeftArrowKey && !list.isHeadNode(list.getCurrentNode())) {
          handleBackOnClick();
        } else if (
          isRightArrowKey &&
          !list.isTailNode(list.getCurrentNode()) &&
          !shouldDisable
        ) {
          handleNextOnClick(values);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    handleBackOnClick,
    handleNextOnClick,
    isOpen,
    list,
    shouldDisable,
    values,
  ]);

  return (
    <Modal.Actions style={{ textAlign: 'right' }}>
      <Button basic onClick={handleCancelOnClick} type='button'>
        Cancel
      </Button>
      {!list.isHeadNode(list.getCurrentNode()) && (
        <Button basic onClick={handleBackOnClick} type='button'>
          Back
        </Button>
      )}
      {!list.isTailNode(list.getCurrentNode()) && (
        <Button disabled={shouldDisable} positive type='submit'>
          Next
        </Button>
      )}
      {list.isTailNode(list.getCurrentNode()) && (
        <Button disabled={shouldDisable} positive type='submit'>
          Finish
        </Button>
      )}
    </Modal.Actions>
  );
};

IntegrationSetupWizardActionsButtons.propTypes = propTypes;

export default IntegrationSetupWizardActionsButtons;
