import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, isNumber, isString, memoize, noop } from 'lodash';
import { Boundaries } from 'cascara-middleware';
import {
  Button,
  Divider,
  Form,
  Header,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';
import serviceDepartmentListShape, {
  serviceDepartmentConditionsShape,
  taskTypeListShape,
} from './PropTypesShapes';
import AdminServiceDepartmentDetailsCaseManagementController from './controller';
import BrowserPrompt from '../../../../../globals/BrowserPrompt';
import CommonTable from '../../../../../../v2/components/display/Table';
import ConditionForm from '../../../../../../v2/components/form/serviceDepartment/caseManagement/createOrUpdateCondition';

const getServiceDepartmentEID = memoize((serviceDepartmentList, departmentID) =>
  serviceDepartmentList && serviceDepartmentList.size && isString(departmentID)
    ? serviceDepartmentList
        .find(
          (serviceDepartment) =>
            serviceDepartment.get('id') === Number.parseInt(departmentID)
        )
        .get('eid')
    : ''
);

class AdminServiceDepartmentDetailsCaseManagement extends PureComponent {
  static propTypes = {
    conditionsServiceDepartmentEID: PropTypes.string,
    deleteCondition: PropTypes.func.isRequired, // dispatch-prop
    intentsList: serviceDepartmentListShape, // state-prop
    loadIntentsList: PropTypes.func.isRequired, // dispatch-prop
    loadServiceDepartmentCategoriesList: PropTypes.func.isRequired, // dispatch-prop
    loadServiceDepartmentConditionsList: PropTypes.func.isRequired, // dispatch-prop
    loadServiceDepartmentTeamList: PropTypes.func.isRequired, // dispatch-prop
    loadTaskTypes: PropTypes.func.isRequired, // dispatch-prop
    params: PropTypes.shape({
      departmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }), // state-prop
    serviceDepartmentCategoriesList: serviceDepartmentListShape, // state-prop
    serviceDepartmentConditionsList: serviceDepartmentConditionsShape, // state-prop
    serviceDepartmentList: ImmutablePropTypes.list,
    serviceDepartmentTeamList: serviceDepartmentListShape, // state-prop
    swapCondition: PropTypes.func, // dispatch-prop
    taskTypesList: taskTypeListShape, // state-prop
    toggleCondition: PropTypes.func.isRequired, // dispatch-prop
  };

  static defaultProps = {
    conditionsServiceDepartmentEID: null,
    intentsList: Immutable.List(),
    params: {},
    serviceDepartmentCategoriesList: Immutable.List(),
    serviceDepartmentConditionsList: Immutable.List(),
    serviceDepartmentList: Immutable.List(),
    serviceDepartmentTeamList: Immutable.List(),
    swapCondition: noop,
    taskTypesList: Immutable.List(),
  };

  static getDerivedStateFromProps(nextProps, preState) {
    const {
      serviceDepartmentCategoriesList,
      serviceDepartmentConditionsList,
      serviceDepartmentList,
      serviceDepartmentTeamList,
      taskTypesList,
      params: { departmentID },
    } = nextProps;

    const {
      areCategoriesLoaded,
      areConditionsLoaded,
      areServiceTeamsLoaded,
      areTaskTypesLoaded,
      serviceDepartmentEID,
    } = preState;

    return {
      areCategoriesLoaded:
        areCategoriesLoaded || !serviceDepartmentCategoriesList.isEmpty(),
      areConditionsLoaded:
        areConditionsLoaded || !serviceDepartmentConditionsList.isEmpty(),
      areServiceTeamsLoaded:
        areServiceTeamsLoaded || !serviceDepartmentTeamList.isEmpty(),
      areTaskTypesLoaded: areTaskTypesLoaded || !taskTypesList.isEmpty(),
      serviceDepartmentEID:
        serviceDepartmentEID ||
        getServiceDepartmentEID(serviceDepartmentList, departmentID),
    };
  }

  state = {
    areCategoriesLoaded: false,
    areConditionsLoaded: false,
    areServiceTeamsLoaded: false,
    areTaskTypesLoaded: false,
    currentConditionOnEdit: null,
    isCreateConditionModalOpen: false,
    isUpdateConditionModalOpen: false,
    serviceDepartmentEID: '',
  };

  componentDidMount() {
    const {
      conditionsServiceDepartmentEID,
      loadServiceDepartmentCategoriesList,
      loadServiceDepartmentConditionsList,
      loadServiceDepartmentTeamList,
      loadTaskTypes,
      params: { departmentID },
    } = this.props;

    const {
      areCategoriesLoaded,
      areServiceTeamsLoaded,
      areTaskTypesLoaded,
      serviceDepartmentEID,
    } = this.state;

    if (!areTaskTypesLoaded) {
      loadTaskTypes().finally(() =>
        this.changeStateOnUpdate({ areTaskTypesLoaded: true })
      );
    }

    if (!areServiceTeamsLoaded && departmentID) {
      loadServiceDepartmentTeamList(departmentID).finally(() =>
        this.changeStateOnUpdate({ areServiceTeamsLoaded: true })
      );
    }

    if (!areCategoriesLoaded && departmentID) {
      loadServiceDepartmentCategoriesList(departmentID).finally(() =>
        this.changeStateOnUpdate({ areCategoriesLoaded: true })
      );
    }

    if (serviceDepartmentEID && conditionsServiceDepartmentEID) {
      if (serviceDepartmentEID !== conditionsServiceDepartmentEID) {
        loadServiceDepartmentConditionsList(serviceDepartmentEID).finally(() =>
          this.changeStateOnUpdate({ areConditionsLoaded: true })
        );
      } else {
        this.changeStateOnUpdate({ areConditionsLoaded: true });
      }
    }
  }

  componentDidUpdate() {
    const { loadServiceDepartmentConditionsList } = this.props;
    const { areConditionsLoaded, serviceDepartmentEID } = this.state;

    if (!areConditionsLoaded && serviceDepartmentEID) {
      loadServiceDepartmentConditionsList(serviceDepartmentEID).finally(() =>
        this.changeStateOnUpdate({ areConditionsLoaded: true })
      );
    }
  }

  changeStateOnUpdate = (newState) => this.setState(newState);

  emptyStateModalTrigger = () => (
    <Button basic content='Add a condition' onClick={this.handleToggleModal} />
  );

  handleCheckboxChange = memoize(
    curry((id, evt, { checked }) => {
      evt.preventDefault();
      const { toggleCondition } = this.props;
      if (isNumber(id)) {
        toggleCondition(id, checked);
      }
    })
  );

  handleDeleteClick = memoize(
    curry((conditionID, evt) => {
      evt.preventDefault();
      const { deleteCondition } = this.props;
      const { serviceDepartmentEID } = this.state;
      const shouldRemoveRow = BrowserPrompt.confirm(
        'Deleting this condition could change the order of events. Make sure to understand what the effects of removing the condition are.',
        { title: 'Delete this condition?' }
      );
      if (shouldRemoveRow && isNumber(conditionID) && serviceDepartmentEID) {
        deleteCondition(conditionID, serviceDepartmentEID);
      }
    })
  );

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleToggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleEditClick = memoize(
    curry((eid, evt) => {
      evt.preventDefault();
      const { serviceDepartmentConditionsList } = this.props;
      const condition = serviceDepartmentConditionsList.find(
        (condition) => condition.get('eid') === eid
      );
      this.openUpdateConditionModal(condition);
    })
  );

  // Note: do not use memoize here or it will default direction always to up (or down, depending on
  // which call occurs first)
  handleSwapCondition = curry((eid, direction, evt) => {
    evt.preventDefault();
    const { serviceDepartmentConditionsList, swapCondition } = this.props;
    const condition = serviceDepartmentConditionsList.find(
      (condition) => condition.get('eid') === eid
    );
    const orderOfExecution = condition.get('order_of_execution');
    const swapForOrderOfExecution =
      direction === 'up' ? orderOfExecution - 1 : orderOfExecution + 1;
    const swapFor = serviceDepartmentConditionsList.find(
      (condition) =>
        condition.get('order_of_execution') === swapForOrderOfExecution
    );
    swapCondition(condition, swapFor);
  });

  handleCreateConditionModalClose = () => {
    this.setState({ isCreateConditionModalOpen: false });
  };

  handleIntentsSearch = (query) => {
    const { loadIntentsList } = this.props;

    loadIntentsList(query);
  };

  handleUpdateConditionModalClose = () => {
    this.setState({ isUpdateConditionModalOpen: false });
  };

  handleToggleCreateConditionModal = () => {
    this.setState((prevState) => ({
      isCreateConditionModalOpen: !prevState.isCreateConditionModalOpen,
    }));
  };

  handleToggleUpdateConditionModal = () => {
    this.setState((prevState) => ({
      isUpdateConditionModalOpen: !prevState.isUpdateConditionModalOpen,
    }));
  };

  openUpdateConditionModal = (condition) => {
    this.setState({ currentConditionOnEdit: condition });
    this.setState({ isUpdateConditionModalOpen: true });
  };

  render() {
    const {
      intentsList,
      serviceDepartmentCategoriesList,
      serviceDepartmentConditionsList,
      serviceDepartmentTeamList,
      taskTypesList,
    } = this.props;

    const {
      areConditionsLoaded,
      serviceDepartmentEID,
      isCreateConditionModalOpen,
      currentConditionOnEdit,
      isUpdateConditionModalOpen,
    } = this.state;

    const emptyStateModalTrigger = (
      <Button
        basic
        content='Add a condition'
        onClick={this.handleToggleCreateConditionModal}
      />
    );

    const modalTrigger = (
      <Button
        basic
        content='Add condition'
        floated='right'
        onClick={this.handleToggleCreateConditionModal}
      />
    );

    const renderCreateConditionModal = (modalTrigger) => (
      <Modal
        as={Form}
        className='scrolling'
        content={
          <ConditionForm
            intentsList={intentsList}
            nextOrderOfExecution={serviceDepartmentConditionsList.size}
            onClose={this.handleCreateConditionModalClose}
            onIntentSearch={this.handleIntentsSearch}
            serviceDepartmentCategoriesList={serviceDepartmentCategoriesList}
            serviceDepartmentEID={serviceDepartmentEID}
            serviceDepartmentTeamList={serviceDepartmentTeamList}
            taskTypesList={taskTypesList}
          />
        }
        header={'Require Barista Search - Add a condition'}
        onClose={this.handleToggleCreateConditionModal}
        open={isCreateConditionModalOpen}
        size='tiny'
        trigger={modalTrigger}
      />
    );

    const updateConditionModal = (
      <Modal
        as={Form}
        className='scrolling'
        content={
          <ConditionForm
            formInstance={currentConditionOnEdit}
            intentsList={intentsList}
            onClose={this.handleUpdateConditionModalClose}
            onIntentSearch={this.handleIntentsSearch}
            serviceDepartmentCategoriesList={serviceDepartmentCategoriesList}
            serviceDepartmentEID={serviceDepartmentEID}
            serviceDepartmentTeamList={serviceDepartmentTeamList}
            taskTypesList={taskTypesList}
          />
        }
        header={'Require Barista Search - Update condition'}
        onClose={this.handleToggleUpdateConditionModal}
        open={isUpdateConditionModalOpen}
        size='tiny'
      />
    );

    return (
      <Boundaries>
        <Segment attached='bottom'>
          <div>
            <div>
              <Header as='h3'>{'Require Barista Search'}</Header>
              <p>
                {
                  'Require Barista Search to be utilized on cases where certain conditions are met.'
                }
              </p>
            </div>
            {renderCreateConditionModal(modalTrigger)}
            <Divider hidden />
            {updateConditionModal}
            <Divider hidden />
            <CommonTable
              emptyStateContent={
                <>
                  <h1>{'No Conditions Configured'}</h1>
                  <p>
                    {
                      'Add a condition to require Barista search when a case meets certain criteria.'
                    }
                  </p>
                  {renderCreateConditionModal(emptyStateModalTrigger)}
                </>
              }
              headers={[
                '',
                'Activated',
                'Action',
                'Service Team',
                'Task Type',
                'Category',
                '',
              ]}
              isLoading={!areConditionsLoaded}
            >
              {!serviceDepartmentConditionsList.isEmpty() &&
                serviceDepartmentConditionsList.map((condition, index) => (
                  <Table.Row key={condition.get('eid')}>
                    <Table.Cell>
                      {index > 0 && (
                        <Button
                          basic
                          icon='arrow up'
                          onClick={this.handleSwapCondition(
                            condition.get('eid'),
                            'up'
                          )}
                          style={{ display: 'block' }}
                        />
                      )}
                      {index < serviceDepartmentConditionsList.size - 1 && (
                        <Button
                          basic
                          icon='arrow down'
                          onClick={this.handleSwapCondition(
                            condition.get('eid'),
                            'down'
                          )}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Checkbox
                        checked={condition.get('is_active')}
                        onChange={this.handleCheckboxChange(
                          condition.get('id')
                        )}
                        toggle
                      />
                    </Table.Cell>
                    <Table.Cell>{condition.get('action')}</Table.Cell>
                    <Table.Cell>
                      {condition.getIn(['service_team', 'name'])}
                    </Table.Cell>
                    <Table.Cell>
                      {condition.getIn(['task_type', 'label'])}
                    </Table.Cell>
                    <Table.Cell>
                      {condition.getIn(['task_category', 'name'])}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        content='Edit'
                        onClick={this.handleEditClick(condition.get('eid'))}
                      />
                      <Button
                        basic
                        content='Delete'
                        onClick={this.handleDeleteClick(condition.get('id'))}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </CommonTable>
          </div>
        </Segment>
      </Boundaries>
    );
  }
}
const DetailsCaseManagementTest = AdminServiceDepartmentDetailsCaseManagement;

export { DetailsCaseManagementTest };

export default AdminServiceDepartmentDetailsCaseManagementController(
  AdminServiceDepartmentDetailsCaseManagement
);
