import { Button, Form, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, memoize } from 'lodash';

// Organism
import DepartmentSelect from '../organisms/DepartmentSelect';
// Global
import TypeStateGroup from '../../globals/TypeStateGroup';

class DepartmentField extends PureComponent {
  static propTypes = {
    /** Departments list */
    departments: ImmutablePropTypes.list.isRequired,
    /** External Departments selected render */
    departmentsSelectedRender: PropTypes.func,
    /** Remove Department hanlder */
    groupName: PropTypes.string,
    handleRemoveDepartment: PropTypes.func.isRequired,
    /** Select department handler */
    handleSelectDepartment: PropTypes.func.isRequired,
    noRootPreselect: PropTypes.bool,
    /** Placeholder when empty */
    placeholder: PropTypes.string,
    /** Make the field read only */
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    departmentsSelectedRender: void 0,
    noRootPreselect: false,
    placeholder: 'All',
    readOnly: false,
  };

  handleRemoveOneDepartment = memoize(
    curry((departmentID, e) => {
      const { handleRemoveDepartment } = this.props;
      handleRemoveDepartment(departmentID, TypeStateGroup.departments);
    })
  );

  handleSelect = (data) => {
    const { handleSelectDepartment, groupName = '' } = this.props;
    handleSelectDepartment(data, TypeStateGroup.departments, groupName);
  };

  renderAddDepartmentButton = () => {
    const { departments, departmentsSelectedRender, readOnly } = this.props;

    if (departmentsSelectedRender) {
      return departmentsSelectedRender(departments);
    }

    if (readOnly) {
      return null;
    }

    return (
      <Button
        className='text'
        content='Add Department'
        floated='right'
        type='button'
      />
    );
  };

  render() {
    const {
      departments,
      departmentsSelectedRender,
      noRootPreselect,
      placeholder,
      readOnly,
    } = this.props;

    return (
      <Form.Field>
        {!readOnly && (
          <DepartmentSelect
            clearAfterSelection
            noRootPreselect={noRootPreselect}
            onChange={this.handleSelect}
            renderEmptySelectionTrigger={this.renderAddDepartmentButton}
          />
        )}

        {!departmentsSelectedRender || readOnly ? (
          <>
            <label>{'Departments'}</label>
            <Label.Group className='multiple search selection dropdown'>
              {departments && !departments.isEmpty()
                ? departments?.map((department) => (
                    <Label
                      color={readOnly ? null : 'blue'}
                      content={department?.get('name')}
                      key={`${department?.get('id')}_key`}
                      onRemove={
                        readOnly
                          ? null
                          : this.handleRemoveOneDepartment(
                              department?.get('id')
                            )
                      }
                    />
                  ))
                : null}

              {!departments || departments.isEmpty() ? (
                <Label content={placeholder} />
              ) : null}
            </Label.Group>
          </>
        ) : null}
      </Form.Field>
    );
  }
}

export default DepartmentField;
