import actionTypes from './actionTypes';

const i18nActions = {};

i18nActions.loadSupportedLanguagesFail = () => ({
  type: actionTypes.I18N_LOAD_SUPPORTED_LANGUAGES_FAIL,
});

i18nActions.loadSupportedLanguagesStart = () => ({
  type: actionTypes.I18N_LOAD_SUPPORTED_LANGUAGES_START,
});

i18nActions.loadSupportedLanguagesSuccess = (languages) => ({
  languages,
  type: actionTypes.I18N_LOAD_SUPPORTED_LANGUAGES_SUCCESS,
});

i18nActions.setLocale = (locale) => ({
  locale,
  type: actionTypes.I18N_SET_LOCALE,
});

export default i18nActions;
