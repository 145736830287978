import PropTypes from 'prop-types';

import React, { Component } from 'react';
import PreviewProfileDetail from '../../organisms/PreviewProfileDetail';

// Block own controller
import PreviewProfileController from './PreviewProfileController';

// Globals
import TypePreviewProfile, {
  TypeDefaults,
} from '../../../globals/blocks/TypePreviewProfile';

class PreviewProfile01 extends Component {
  static propTypes = {
    ...TypePreviewProfile,
    // Might be set to workflowState.sessionId.userRequestedFor by the controller if not initialized with it
    userId: PropTypes.number.isRequired,
  };

  static defaultProps = TypeDefaults;

  render() {
    const { template, type, userId } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <PreviewProfileDetail forceReload userId={userId} />
      </div>
    );
  }
}
const PreviewProfile01Test = PreviewProfile01;

// eslint-disable-next-line no-class-assign -- DEV-1526
PreviewProfile01 = PreviewProfileController(PreviewProfile01);
export { PreviewProfile01Test };
export default PreviewProfile01;
