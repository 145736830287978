const api_workflow_v0_1_workflowtask_666 = {
  actions: null,
  attributes: null,
  auth_skip: false,
  backend_blob: null,
  eid: '4cc41a75-09f1-4b08-8985-824643b48c41',
  esp_objects: null,
  frontend_blob: {
    blocks: [
      {
        id: '485d3d',
        inputValue: {
          map: '',
          value: '',
        },
        template: 'logo_1',
        text2: 'Onboarding',
        type: 'logo',
      },
      {
        id: '548595',
        inputValue: {
          map: '',
          value: '',
        },
        messages: [
          {
            text: "Hi, I'm Barista!",
          },
          {
            text: 'Sounds like you have a new hire coming!',
          },
          {
            text:
              "That's great! I'm going to help you get the onboarding process started.",
          },
        ],
        template: 'barista01',
        type: 'barista',
      },
    ],
    nav: {
      background: {
        hide: true,
        id: 'de3de1',
        type: 'background',
      },
      espressiveLogo: false,
      footer: {
        hide: false,
        next: "Let's go",
        skip: '',
      },
      progress: false,
      toolbar: {
        hide: false,
        next: '',
        prev: '',
        title: '',
      },
    },
  },
  frontend_intl: null,
  id: 44,
  images: [],
  methods: null,
  name: 'New hire - Barista welcome',
  post_condition_group: {
    condition_entry: [
      {
        conditions: null,
        description: 'Condition ebe62c56-04a3-4200-a1bd-58a2d5378725',
        eid: '2fabee5b-a60f-412c-9015-53f70cc45545',
        id: 53,
        object_instances: null,
        ordering: 0,
        success_workflow: null,
        success_workflow_task:
          'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/46/',
        sys_created_by: null,
        sys_custom_fields: null,
        sys_updated_by: null,
        url:
          'https://release.qa.espressive.com/api/workflow/v0.1/condition/53/',
      },
    ],
    eid: '9a319a81-1be0-4dc5-9dd1-c3546b5cb293',
    failure_workflow: null,
    failure_workflow_task: null,
    id: 53,
    name: 'Condition Group cc4f8fbb-d78b-489a-9b38-def03b6fb481',
    sys_created_by: null,
    sys_custom_fields: null,
    sys_updated_by: null,
    url:
      'https://release.qa.espressive.com/api/workflow/v0.1/workflowconditionset/53/',
  },
  pre_condition_group: null,
  related_workflow: null,
  related_workflow_condition: null,
  sys_created_by: null,
  sys_custom_fields: null,
  sys_date_created: '2017-10-26T21:41:21.482467Z',
  sys_date_updated: '2018-09-20T20:40:06.232185Z',
  sys_updated_by: null,
  tags: '',
  url: 'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/44/',
};

export default api_workflow_v0_1_workflowtask_666;
