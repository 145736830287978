const HOST = window?.location?.hostname;
const EXCLUDE_HOSTS_ENDING_WITH = [
  '.esp',
  '.local',
  '.qa.espressive.com',
  '.demo.espressive.com',
];

// Check if the host ends with any of our excluded hosts
const isAllowedHost =
  EXCLUDE_HOSTS_ENDING_WITH.filter((excluded) => HOST.endsWith(excluded))
    .length === 0;

// Make sure Fathom is loaded first
const isFathomLoaded = window?.fathom;

// Only track if we match all criterion
const isTrackable = isFathomLoaded && isAllowedHost;

export { isTrackable };
