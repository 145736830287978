// deserialize
import deserializeNodesRules from './deserialize/nodeRules';

// serialize
import defaultRule from './serialize/defaultRule';
import inlineRules from './serialize/inlineRules';
import markRules from './serialize/marksRules';
import nodesRules from './serialize/nodesRules';

const rules = [
  // Serialize
  defaultRule,
  inlineRules,
  markRules,
  nodesRules,
  // Deserialize
  deserializeNodesRules,
];

export default rules;
