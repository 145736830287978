import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesScotchTape extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M39.162 58.068c-6.44 0-11.68-5.24-11.68-11.68 0-6.44 5.24-11.678 11.68-11.678 6.44 0 11.68 5.24 11.68 11.68 0 6.44-5.24 11.678-11.68 11.678zm0-21.8c-5.58 0-10.122 4.54-10.122 10.12 0 5.582 4.54 10.123 10.122 10.123 5.58 0 10.122-4.54 10.122-10.12 0-5.58-4.54-10.12-10.122-10.12zm0 17.128c-3.863 0-7.007-3.144-7.007-7.007 0-3.87 3.144-7.01 7.007-7.01 3.864 0 7.008 3.14 7.008 7.01 0 3.86-3.144 7-7.008 7zm0-12.458c-3.005 0-5.45 2.445-5.45 5.45 0 3.006 2.445 5.45 5.45 5.45 3.006 0 5.45-2.444 5.45-5.45 0-3.005-2.444-5.45-5.45-5.45z' />
          <path d='M76.98 41.125v.095c-15.705 3.682-21.19-1.905-21.642-2.406-.343-.73-.74-1.438-1.184-2.123l4.333-7.29-.4-.42C53.18 23.72 46.28 20.7 39.162 20.7c-14.275 0-25.888 11.81-25.888 26.326 0 13.405 10.384 24.86 23.22 26.275v1.71h7.785v-1.56h10.9v1.56h7.78v-1.9C74 71.21 82.43 61.4 82.43 49.6v-9.874l-5.45 1.4zm-9.877 2.923c2.74 0 5.993-.343 9.826-1.214-.53 6.336-5.48 11.152-11.88 11.152-3.36 0-6.31-1.387-8.47-3.703.28-1.273.432-2.578.432-3.894 0-1.65-.235-3.27-.67-4.82 1.98 1.17 5.38 2.47 10.745 2.47zm-13.706-5.606c1.355 2.42 2.075 5.133 2.075 7.947 0 .76-.06 1.52-.164 2.27-1.3-1.95-2.142-4.34-2.37-7.01-.093-1.107.186-2.33.46-3.22zM80.874 49.6c0 12.29-9.78 22.288-21.8 22.288H39.16c-13.19 0-24.33-11.388-24.33-24.867 0-13.65 10.914-24.77 24.33-24.77 6.497 0 12.8 2.68 17.387 7.36l-4.09 6.89-.04.08c-.052.12-1.238 2.82-1.033 5.22.68 7.97 6.49 13.75 13.818 13.75 7.443 0 13.315-5.76 13.614-13.22l2.054-.553v7.84z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesScotchTape;
