import _APIcall from '../../../../../utils/APIcall';

const deleteAuth = async (url) => {
  return _APIcall.delete({
    token: true,
    url: url,
  });
};

export default deleteAuth;
