import buildCompositeController from '../../utils/buildCompositeController';
import catalogThunks from '../../actions/catalogThunks';

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.productID || Number(ownProps.params.productID);
  const fulfillmentOptions = state.getIn([
    'catalog',
    'fulfillmentOptions',
    productId,
  ]);
  const product = state.getIn(['entities', 'products', productId]);

  return {
    fulfillmentOptions,
    product,
    productId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFulfillmentOptions: (productId) => {
    dispatch(catalogThunks.loadFulfillmentOptions(productId));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
