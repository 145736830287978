import { get, has, hasIn, isArray, noop } from 'lodash';
import Immutable from 'immutable';
import moment from 'moment';
import async from 'async';
import EspFilters from 'esp-util-filters';

// Actions
import socializeActions from './socializeActions';
// import wsActions         from './wsActions';
// Utils
import endpointGenerator from '../utils/endpointGenerator';
import APIcall from '../utils/APIcall';
// Globals
import ChannelTypes from '../globals/ChannelTypes';
// Selectors
import getTenant from '../selectors/getTenant';
// import getCurrentUser       from '../selectors/getCurrentUser';

const RETRY_LOAD_TIME = 1000;
const MAX_CALLS = 5;

const socializeThunks = {};

const paginationLimit = 10;

const getEndpointWithFilters = ({
  filters = Immutable.Map(),
  type,
  otherQueryParameters = [],
}) => {
  let endpoint;
  const espFilter = new EspFilters();
  const getCheckedFilterByIndex = (index) =>
    filters
      .getIn([index, 'filters', 0, 'options'], Immutable.List())
      .find((option) => option.get('checked'));

  // Filters
  const sortByFilter = getCheckedFilterByIndex(1);
  const timePeriodFilter = getCheckedFilterByIndex(2);

  // Message type
  if (type === ChannelTypes.DIRECT_CHANNEL) {
    endpoint = endpointGenerator.genPath('espChat.directChannels');
  } else if (type === ChannelTypes.SCOPED_CHANNEL) {
    endpoint = endpointGenerator.genPath('task.tasks.myHelpRequests');
    espFilter.isTrue('show_to_user');
  } else {
    throw new Error(`Unknown channel type${type}`);
  }

  // Sort by
  if (sortByFilter && sortByFilter.has('value')) {
    if (sortByFilter.get('value') !== 'UNREAD') {
      otherQueryParameters.push(`order_by=${sortByFilter.get('value')}`);
    }
  }

  // Time period
  if (
    timePeriodFilter &&
    timePeriodFilter.has('value') &&
    timePeriodFilter.get('value') !== 'all'
  ) {
    const momento = moment()
      .subtract(timePeriodFilter.get('value'), 'ms')
      .toISOString();
    espFilter.greaterThan('sys_date_updated', momento);
  }

  const queryParameters = otherQueryParameters.length
    ? `?${otherQueryParameters.join('&')}`
    : '';
  const espFilters = espFilter.getQuerySize()
    ? otherQueryParameters.length
      ? `&esp_filters=${espFilter.asEncodedQueryString()}`
      : `?esp_filters=${espFilter.asEncodedQueryString()}`
    : '';
  return `${endpoint}${queryParameters}${espFilters}`;
};

/**
 * Loads the list of direct channels for the active user
 * @param  {[type]} ) [description]
 * @return {[type]}   [description]
 */
socializeThunks.loadChannels = (type, ignorePagination = false) => (
  dispatch,
  getState
) => {
  const state = getState();
  const filters = state.get('channelsFilter');
  const pagination = state.getIn(['socialize', 'pagination', type]);
  const isLoading = state.getIn([
    'socialize',
    'channelsStatus',
    type,
    'isLoading',
  ]);

  if (isLoading) {
    return null;
  }

  // If it exist a next pagination in the reducer, we use that as the url
  // otherwise we fallback to the endpoint root
  const url =
    !ignorePagination && pagination.get('next')
      ? pagination.get('next')
      : getEndpointWithFilters({
          filters,
          otherQueryParameters: [`limit=${paginationLimit}`],
          type,
        });

  dispatch(socializeActions.getChannelsStart(type));
  return APIcall.get({
    error(res) {
      dispatch(socializeActions.getChannelsFail(res, type));
    },
    success(res) {
      const { body } = res;
      const channels = body.results;

      const pagination = {
        next: body.next,
        prev: body.previous,
      };

      dispatch(
        socializeActions.getChannelsSuccess(
          channels,
          pagination,
          type,
          ignorePagination
        )
      );
    },
    token: true,
    url,
  });
};

socializeThunks.loadDirectChannelForUserId = (userID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(socializeActions.getDirectChannelForUserIdStart(userID));

    return APIcall.post({
      data: {
        users: [userID],
      },
      error(err) {
        const response = get(err, 'response');
        let errorMessage;
        // There are 4 possible scenarios here and we need to report in either case
        if (hasIn(response, ['body', 'message'])) {
          // we at least got a server response
          errorMessage = response.body.message;
        } else if (isArray(get(response, ['body', 'errors']))) {
          // Correctly formatted errors
          errorMessage = response.body.errors[0].message;
        } else if (has(response, 'message')) {
          // we at least got a server response
          errorMessage = response.message;
        } else if (has(err, 'message')) {
          // at least an error object
          errorMessage = err.message;
        } else {
          // then wtf happened
          errorMessage = 'Unknown Error';
        }

        dispatch(
          socializeActions.getDirectChannelForUserIdFail(errorMessage, userID)
        );
        reject(err);
      },
      success(res) {
        const channel = res.body;
        dispatch(
          socializeActions.getDirectChannelForUserIdSuccess(
            channel,
            channel.id,
            userID
          )
        );

        resolve(channel);
      },
      token: true,
      url: endpointGenerator.genPath('espChat.directChannels.withUsers'),
    });
  });

socializeThunks.loadChannel = (
  channelID,
  channelType,
  multipleRecover = false,
  isAdminChannel = false
) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(socializeActions.loadChannelStart(channelID));

    let channelEndpoint;
    if (channelType === ChannelTypes.DIRECT_CHANNEL) {
      channelEndpoint = endpointGenerator.genPath(
        'espChat.directChannels.instance',
        {
          channelID,
        }
      );
    } else if (channelType === ChannelTypes.SCOPED_CHANNEL) {
      if (isAdminChannel) {
        channelEndpoint = endpointGenerator.genPath(
          'espChat.adminChannels.instance',
          {
            channelID,
          }
        );
      } else {
        channelEndpoint = endpointGenerator.genPath(
          'espChat.channels.instance',
          {
            channelID,
          }
        );
      }
    } else {
      reject();
      throw new Error(`Channel type${channelType} not known where to load`);
    }

    //
    //    In order to avoid racing condition on BE, we need to make several request until we get valid data,
    //    but we established a limit of 5 calls to the endpoint
    //

    let totalCalls = 0;

    async.until(
      () => totalCalls >= MAX_CALLS,
      (cb) => {
        setTimeout(
          () => {
            APIcall.get({
              error(err) {
                totalCalls += 1;
                if (totalCalls === 5) {
                  const response = get(err, 'response');
                  let errorMessage;
                  // There are 4 possible scenarios here and we need to report in either case
                  if (isArray(get(response, ['body', 'errors']))) {
                    // Correctly formatted errors
                    errorMessage = response.body.errors[0].message;
                  } else if (has(response, 'message')) {
                    // we at least got a server response
                    errorMessage = response.message;
                  } else if (has(err, 'message')) {
                    // at least an error object
                    errorMessage = err.message;
                  } else {
                    // then wtf happened
                    errorMessage = 'Unknown Error';
                  }

                  dispatch(
                    socializeActions.loadChannelFail(errorMessage, channelID)
                  );
                  reject(err);
                }
                cb();
              },
              preventShowError: totalCalls < 5 && multipleRecover,
              success(res) {
                totalCalls = 5;
                const channel = res.body;
                dispatch(
                  socializeActions.loadChannelSuccess(channel, channel.id)
                );
                resolve(channel);
                cb();
              },
              token: true,
              url: channelEndpoint,
            });
          },
          multipleRecover ? RETRY_LOAD_TIME : 0
        );
      },
      () => {}
    );
  });

socializeThunks.getTotalUnreadForScopedChannels = () => (dispatch) =>
  new Promise((resolve, reject) => {
    // Implementation via websocket: use this once message come wrapped with metadata (T1101)
    // const what = {
    //   // websocket request
    //   request: endpointGenerator.genPath('task.tasks.myHelpRequestTotalUnread'),
    // };
    // dispatch(wsActions.send(what));

    APIcall.get({
      error(err) {
        reject(err);
      },
      success(res) {
        dispatch(
          socializeActions.updateUnreadCountScopedChannels(
            res.body.total_unread
          )
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('task.tasks.myHelpRequestTotalUnread'),
    });
  });

socializeThunks.getTotalUnreadForDirectChannels = () => (dispatch) => {
  // Implementation via websocket: use this once message come wrapped with metadata (T1101)
  // const what = {
  //   // websocket request
  //   request: endpointGenerator.genPath('espChat.directChannels.totalUnread'),
  // };
  // dispatch(wsActions.send(what));

  APIcall.get({
    success(res) {
      dispatch(
        socializeActions.updateUnreadCountDirectChannels(res.body.total_unread)
      );
    },
    token: true,
    url: endpointGenerator.genPath('espChat.directChannels.totalUnread'),
  });
};

socializeThunks.sendFeedback = (data, cb = noop) => (dispatch, getState) => {
  dispatch(socializeActions.sendFeedbackStart());

  const state = getState();
  const tenant =
    state.getIn(['tenant', 'name', 'value']) || getTenant(state).get('name');
  // const currentUser = getCurrentUser(state);
  // const usrEmail = currentUser ? getCurrentUser(state).get('email') : null; // To avoid error during unit test for now

  const msg = data.get('msgFeedback');
  const subject = data.get('typeFeedback');

  // Run on Cordova and which OS OR Run on browser
  const deviceOrBrowser = window.device
    ? `cordova app - ${window.device.platform}`
    : `browser - ${window.navigator.userAgent}`;

  const body = `Msg: ${msg}<br> Tenant: ${tenant}<br> Device: ${deviceOrBrowser}`;

  const feedbackMessage = {
    body,
    subject,
  };

  const endPts = endpointGenerator.genPath('espNotification.supportEmail');

  APIcall.post({
    data: feedbackMessage,
    error(err) {
      dispatch(socializeActions.sendFeedbackFail(err));
      cb(null, err);
    },
    success(res) {
      dispatch(socializeActions.sendFeedbackSuccess());
      cb(res.body);
    },
    token: true,
    url: endPts,
  });
};

export default socializeThunks;
