import { initialize } from 'redux-form';
import { getFormSyncErrors } from 'redux-form/immutable';
import immutable from 'immutable';
import _ from 'lodash';

// Utilities
import BlobAttributesParser from '../../utils/BlobAttributesParser';
import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import workflowThunks from '../../actions/workflowThunks';
import appUIActions from '../../actions/appUIActions';

// Selectors
import { getFEBlobLocaleKey } from '../../selectors/getIntlMessages';
import getWorkflowProgress from '../../selectors/getWorkflowProgress';

const mapStateToProps = (state) => {
  const workflowState = state.get('workflowState');
  const attributes = workflowState.get('attributes');
  const locale = getFEBlobLocaleKey(state);

  // Block set up, attributr parsing, and translation
  const attributesParser = new BlobAttributesParser(attributes);
  const blob = attributesParser.setupBlob(workflowState, locale);
  const initialValues = attributesParser.getInitialValues(blob.get('blocks'));
  let errorsApi = state.getIn(['errorsApi', 'errors']) || immutable.List();
  errorsApi = attributesParser.setErrorLabelName(blob.get('blocks'), errorsApi);

  const workflowTaskID =
    workflowState.getIn(['sessionId', 'workflowTaskID']) || null;
  const workflowID = workflowState.getIn(['sessionId', 'workflowID']) || null;
  const blurField = workflowState.get('blurField');
  let errors = workflowState.get('errors');

  const formSyncErrors = getFormSyncErrors('Form01')(state);
  if (
    !errors.get(blurField) &&
    formSyncErrors &&
    blurField &&
    formSyncErrors[blurField]
  ) {
    // A blur event has been detected for one field with an error validation
    errors = errors.set(blurField, formSyncErrors[blurField]);
  }

  return {
    blob: blob,
    errors,
    errorsApi: errorsApi,
    formSyncErrors,
    initialValues: initialValues,
    isLoading: workflowState.get('loading'),
    overLoading: workflowState.get('overLoading'),
    progress: getWorkflowProgress(state),
    step: workflowState.get('step'),
    workflowID,
    workflowTaskID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  forceUpdateForm(initialValues) {
    dispatch(initialize('Form01', initialValues, true)); // Force to update redux form if needed
  },

  moveNextWorkflowTask(requestId, cb) {
    dispatch(workflowThunks.moveNextWorkflowTask(requestId, cb));
  },

  movePrevWorkflowTask(cb) {
    dispatch(workflowThunks.movePrevWorkflowTask(cb));
  },

  resumeWorkflow(workflowRequestID, cb) {
    dispatch(workflowThunks.resumeWorkflow(workflowRequestID, cb)).catch(() => {
      dispatch(appUIActions.closeWorkflowModal());
    });
  },

  saveAttributes(values, onSaveAttributes = _.noop) {
    dispatch(workflowThunks.saveAttributes(values, onSaveAttributes));
  },

  skipCurrentTask(requestId, cb) {
    dispatch(workflowThunks.skipCurrentTask(requestId))
      .then((workflowRequest) => {
        cb(null, workflowRequest);
      })
      .catch((error) => {
        cb(error);
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
