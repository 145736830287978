import React, { PureComponent } from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { has, noop } from 'lodash';
// Controllers
import OnAppWorkflowModalController from '../controllers/OnAppWorkflowModalController';
// Pages
import WorkflowPage from '../pages/flow/WorkflowPage';
// Global
import EspUserPropShape from '../../globals/EspUserPropShape';
// Util
import { WorkflowProvider } from '../../utils/WorkflowContext';

class OnAppWorkflowModal extends PureComponent {
  static propTypes = {
    closeWorkflowModal: PropTypes.func,
    currentUser: EspUserPropShape,
    isOpen: PropTypes.bool,
    launchTeachBaristaWF: PropTypes.func,
    launchTeachBaristaWFWithKB: PropTypes.func,
    location: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      key: PropTypes.any,
      query: PropTypes.shape({
        inc: PropTypes.string,
        sys_id: PropTypes.string,
        table: PropTypes.string,
        title: PropTypes.string,
        workflowEID: PropTypes.string,
        workflowRequestID: PropTypes.string,
      }),
    }).isRequired,
    openWorkflowModal: PropTypes.func,
    requestID: PropTypes.number,
    wfIsLaunching: PropTypes.bool,
  };

  static defaultProps = {
    closeWorkflowModal: noop,
    currentUser: null,
    isOpen: false,
    launchTeachBaristaWF: noop,
    launchTeachBaristaWFWithKB: noop,
    openWorkflowModal: noop,
    requestID: null,
    wfIsLaunching: false,
  };

  componentDidMount() {
    const {
      currentUser,
      location,
      launchTeachBaristaWF,
      launchTeachBaristaWFWithKB,
      openWorkflowModal,
      wfIsLaunching,
    } = this.props;

    const { query } = location;
    const incident = query.inc;
    const KBtitle =
      query.table &&
      query.table === 'kb_knowledge' &&
      query.sys_id &&
      query.title
        ? query.title
        : null;
    const KBid =
      query.table &&
      query.table === 'kb_knowledge' &&
      query.sys_id &&
      query.title
        ? query.sys_id
        : null;

    if (
      !has(query, 'workflowRequestID') &&
      !has(query, 'workflowEID') &&
      !has(query, 'inc') &&
      !has(query, 'table')
    ) {
      return;
    }

    const requestIDParam = query.workflowRequestID;

    const workflowEIDParam = query.workflowEID;

    if (
      requestIDParam &&
      this.validateRequestID(requestIDParam) &&
      currentUser
    ) {
      const requestID = Number(requestIDParam);
      openWorkflowModal(requestID);
    } else if (
      !wfIsLaunching &&
      this.validateWorkflowID(workflowEIDParam) &&
      currentUser
    ) {
      openWorkflowModal(null, workflowEIDParam);
    } else if (requestIDParam) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        `${requestIDParam} is not a valid value for workflowRequestID query param.`
      );
    } else if (!wfIsLaunching && currentUser && incident) {
      launchTeachBaristaWF(incident);
    } else if (!wfIsLaunching && currentUser && KBtitle && KBid) {
      launchTeachBaristaWFWithKB(KBid, KBtitle);
    } else if (workflowEIDParam) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        `${workflowEIDParam} is not a valid value for workflow ID query param.`
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isOpen,
      launchTeachBaristaWF,
      launchTeachBaristaWFWithKB,
      location,
      openWorkflowModal,
      wfIsLaunching,
    } = this.props;

    // close modal if the location changed
    const didLocationChange = location !== nextProps.location;
    const { query } = nextProps.location;

    const requestIDParam = query.workflowRequestID;

    const workflowEIDParam = query.workflowEID;

    const incident = query.inc;
    const KBtitle =
      query.table &&
      query.table === 'kb_knowledge' &&
      query.sys_id &&
      query.title
        ? query.title
        : null;
    const KBid =
      query.table &&
      query.table === 'kb_knowledge' &&
      query.sys_id &&
      query.title
        ? query.sys_id
        : null;

    if (isOpen && didLocationChange) {
      // TODO by some reason React Perf has an issue if I synchronously dispatch below, using defer as workaround
      // defer(this.handleCloseWorkflow);
    } else if (
      !isOpen &&
      nextProps.currentUser &&
      this.validateRequestID(requestIDParam)
    ) {
      const requestID = Number(requestIDParam);
      openWorkflowModal(requestID);
    } else if (
      !wfIsLaunching &&
      !isOpen &&
      nextProps.currentUser &&
      this.validateWorkflowID(workflowEIDParam)
    ) {
      openWorkflowModal(null, workflowEIDParam);
    } else if (!wfIsLaunching && !isOpen && nextProps.currentUser && incident) {
      launchTeachBaristaWF(incident);
    } else if (
      !wfIsLaunching &&
      !isOpen &&
      nextProps.currentUser &&
      KBtitle &&
      KBid
    ) {
      launchTeachBaristaWFWithKB(KBid, KBtitle);
    }
  }

  validateRequestID(requestIDParam) {
    const numbersOnlyRegex = /^\d+$/;

    return numbersOnlyRegex.test(requestIDParam);
  }

  validateWorkflowID = (workflowEIDParam) =>
    workflowEIDParam && workflowEIDParam.match(/[-]+/g).length === 4;

  handleCloseWorkflow = () => {
    const { closeWorkflowModal, location } = this.props;
    closeWorkflowModal(location);
  };

  handleMoveNextWorkflowTask = (error, workflowRequest) => {
    const { closeWorkflowModal } = this.props;

    if (error) {
      return;
    }

    const isComplete = workflowRequest.current_workflowtask === null;

    if (isComplete) {
      closeWorkflowModal();
    }
  };

  render() {
    const { isOpen, location, requestID } = this.props;

    const { query } = location;

    return (
      <Modal
        className='scrolling'
        closeOnDimmerClick={false}
        open={isOpen}
        size='small'
      >
        <WorkflowProvider value={query}>
          <WorkflowPage
            bodyClassName='' // Pass empty bodyClassName to disable default styles
            onCloseWorkflow={this.handleCloseWorkflow}
            onMoveNextWorkflowTask={this.handleMoveNextWorkflowTask}
            workflowRequestID={requestID}
          />
        </WorkflowProvider>
      </Modal>
    );
  }
}

export const OnAppWorkflowModalTest = OnAppWorkflowModal;

export default OnAppWorkflowModalController(OnAppWorkflowModal);
