import PropTypes from 'prop-types';

import { PureComponent } from 'react';
import { noop } from 'lodash';

import { getSearchContext } from '../../utils/SearchContexts';
import SearchContextProviderController from '../controllers/SearchContextProviderController';

let SearchContextProvider = class SearchContextProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    contextName: PropTypes.string,
    pathname: PropTypes.string,
    setContextName: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    contextName: '',
    pathname: '',
    setContextName: noop,
  };

  constructor(props) {
    super(props);
    const { pathname, setContextName } = props;

    const searchContext = getSearchContext(pathname);

    //
    // Using setContextName here ensures searchContext is ready in the Redux state before any other component
    // looks for it on componentDidMount.
    //
    if (searchContext) {
      setContextName(searchContext.name);
    }
  }

  /**
   * Listens for search context transitions on route changes.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contextName, pathname, setContextName } = this.props;

    const newPathname = nextProps.pathname;

    const routeChanged = pathname !== newPathname;

    if (routeChanged) {
      const searchContext = getSearchContext(newPathname);

      let newContextName = null;

      if (searchContext) {
        newContextName = searchContext.name;
      }

      if (contextName !== newContextName) {
        setContextName(newContextName);
      }
    }
  }

  render() {
    // just render children as is, with no additional processing
    const { children } = this.props;
    return children;
  }
};

SearchContextProvider = SearchContextProviderController(SearchContextProvider);

export default SearchContextProvider;
