import { useEffect, useRef, useState } from 'react';
import fetchUserSettings from './endpoints/fetchUserSettings';
import postUserSettings from './endpoints/postUserSettings';

/**
 *
 * @param {{userID:string, initialUserSettings}} userID userid value to get users's settings
 * @returns {{isLoadingUserSettings:string, userSettings:object}}  returns object that contains user's settings, loading state and function to update user's settings
 */
const useUsersSettings = ({ userID } = {}) => {
  const [usersSettings, setUsersSettings] = useState({});
  const [isLoading, setLoading] = useState(false);
  const ERROR_STATUS = 400;
  const isFetchingData = useRef(false);

  /**
   *
   * @param {string} newValue  new selected setting
   * @param {string} setting  index of selected setting
   * @returns {null}  returns does not return any value
   */
  const updateUsersSettings = async (newValue, setting) => {
    const newSettings = { ...usersSettings };
    newSettings[setting] = newValue;
    setUsersSettings(newSettings);
    await postUserSettings({
      data: newSettings,
      userID,
    });
  };

  const getUserSetting = (setting) => {
    return usersSettings ? usersSettings[setting] : null;
  };

  useEffect(() => {
    setLoading(true);

    const initialize = async () => {
      isFetchingData.current = true;
      const response = await fetchUserSettings(userID);

      if (response?.status !== ERROR_STATUS) {
        setUsersSettings(response?.body?.user_settings);
      }

      setLoading(false);
      isFetchingData.current = false;
    };
    if (!isFetchingData.current && userID) {
      initialize();
    }
  }, [userID]);

  return {
    getUserSetting,
    isLoadingUserSettings: isLoading,
    updateUsersSettings,
    usersSettings,
  };
};

export default useUsersSettings;
