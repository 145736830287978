import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapDispatchToProps = (dispatch) => ({
  addSubstate: (data, limit, offset) =>
    new Promise((resolve, reject) =>
      dispatch(caseMgmtThunks.addSubstate(data, limit, offset))
        .then(() => resolve())
        .catch(({ status, message }) => {
          if (status === 400) {
            dispatch(
              toastNotificationsActions.error({
                message:
                  'Substates values should not be duplicated for the same state or task',
                title: 'Error in Service Teams',
              })
            );
          } else {
            dispatch(
              toastNotificationsActions.error({
                message,
                title: 'Error in Service Teams',
              })
            );
          }
          reject(message);
        })
    ),
  editSubstate: (substateID, rowIndex, data) =>
    new Promise((resolve, reject) =>
      dispatch(caseMgmtThunks.editSubstate(substateID, rowIndex, data))
        .then(() => resolve())
        .catch((error) => reject(error))
    ),
  loadTaskStatuses() {
    dispatch(caseMgmtThunks.loadTaskStatuses());
  },
  loadTaskTypes() {
    dispatch(caseMgmtThunks.loadTaskTypes());
  },
});

const mapStateProps = (state) => {
  const taskTypes = state.getIn(['caseMgmt', 'taskTypes']);
  const taskStatuses = state.getIn(['caseMgmt', 'taskStatuses']);

  return {
    isLoading: state.getIn(['caseMgmt', 'serviceTeam', 'isLoading'], false),
    isLoadingTaskStatuses: taskStatuses && taskStatuses.get('isLoading'),
    isLoadingTaskTypes: taskTypes && taskTypes.get('isLoading'),
    taskStatuses: taskStatuses && taskStatuses.get('list'),
    taskTypes: taskTypes && taskTypes.get('list'),
  };
};

export default buildCompositeController(mapStateProps, mapDispatchToProps);
