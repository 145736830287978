import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import { noop } from 'lodash';

import ModalForm from '../../../../../app/js/v1/components/atoms/ModalForm';

const propTypes = {
  acceptButtonColor: PropTypes.string,
  acceptContent: PropTypes.node,
  cancelContent: PropTypes.node,
  children: PropTypes.node,
  headerContent: PropTypes.node,
  headerIcon: PropTypes.node,
  isAcceptDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalSize: PropTypes.string,
  onAccept: PropTypes.func,
  trigger: PropTypes.element.isRequired,
};

const defaultProps = {
  acceptButtonColor: 'green',
  acceptContent: intl.formatMessage({
    id: 'label.accept',
  }),
  cancelContent: intl.formatMessage({
    id: 'label.cancel',
  }),
  children: null,
  headerContent: 'Modal Content',
  headerIcon: '',
  isAcceptDisabled: false,
  isLoading: false,
  modalSize: 'small',
  onAccept: noop,
};

class ModalEsp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleOnAccept = () => {
    const { onAccept } = this.props;
    const close = () => this.handleClose();
    onAccept(close);
  };

  render() {
    const {
      acceptButtonColor,
      acceptContent,
      cancelContent,
      children,
      headerContent,
      headerIcon,
      isAcceptDisabled,
      isLoading,
      modalSize,
      trigger,
    } = this.props;
    const { modalOpen } = this.state;

    const newTrigger = React.cloneElement(trigger, {
      onClick: this.handleOpen,
    });

    return (
      <Modal
        basic
        className='scrolling'
        onClose={this.handleClose}
        open={modalOpen}
        size={modalSize}
        trigger={newTrigger}
      >
        <Header content={headerContent} icon={headerIcon} />
        <Modal.Content>
          {React.isValidElement(children)
            ? React.cloneElement(children, {
                close: this.handleClose,
              })
            : children}
        </Modal.Content>

        <Modal.Actions>
          {cancelContent && (
            <Button
              basic
              disabled={isLoading}
              inverted
              onClick={this.handleClose}
            >
              <Icon name='remove' /> {cancelContent}
            </Button>
          )}

          {acceptContent && (
            <Button
              color={acceptButtonColor}
              disabled={isLoading || isAcceptDisabled}
              loading={isLoading}
              onClick={this.handleOnAccept}
            >
              <Icon name='checkmark' /> {acceptContent}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalEsp.propTypes = propTypes;

ModalEsp.defaultProps = defaultProps;

ModalEsp.Form = ModalForm;

export default ModalEsp;
