import '../scss/main.scss';

import _ from 'lodash';
import APIcall from './v1/utils/APIcall';
import ControlledThemeProvider from './fela/ControlledThemeProvider';
import createRenderer from './fela/Renderer';
import espBranding from './fela/EspBranding';
// Selectors
import getTenantBranding from './v1/selectors/getTenantBranding';
import { LocalWarn } from 'cascara-middleware';
// Fela styles
import { Provider } from 'react-fela';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
// Store
import store from './v1/stores/store';

// Renderer for Fela styles
const view = createRenderer();

const renderStaticStyles = (tenantBranding) => {
  const tenantPrimaryColor = tenantBranding.get('tenantPrimaryColor');
  const tenantHeaderColor = tenantBranding.get('tenantHeaderColor');

  // compute static branding styles
  const brandingStyles = espBranding(tenantPrimaryColor, tenantHeaderColor);

  // Looks like we still need to clear the Fela cache to make the UI reflect every update
  // consistently. However, using renderer.clear() is not suitable because it also wipes out the
  // CSS generated by dynamic rules. Thus we manually remove the static rule entries.
  view.cache = _.reject(
    view.cache,
    (cacheEntry) => cacheEntry.type === 'STATIC'
  );

  brandingStyles.forEach((style) =>
    view.renderStatic(style.object, style.selector)
  );
};

// initial tenant branding
let prevTenantBranding = getTenantBranding(store.getState());

renderStaticStyles(prevTenantBranding);

store.subscribe(() => {
  const state = store.getState();
  const tenantBranding = getTenantBranding(state);

  const didChange = prevTenantBranding !== tenantBranding;

  if (didChange) {
    renderStaticStyles(tenantBranding);
  }

  prevTenantBranding = tenantBranding;
});

// oddly the store module isn't getting loaded *before* ApiCall invokes it
// so atm we have to set the store again here.
APIcall.setStore(store);

const renderApp = () => {
  ReactDOM.render(
    <Provider renderer={view}>
      <>
        <LocalWarn />
        <ControlledThemeProvider store={store}>
          <Routes />
        </ControlledThemeProvider>
      </>
    </Provider>,
    document.getElementById('app')
  );
};

export default renderApp;
