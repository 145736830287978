import PropTypes from 'prop-types';
import React, { Component } from 'react';

// molecules
import OnboardingActivityScheduleList from '../../../molecules/OnboardingActivityScheduleList';
import OnboardingActivityScheduleDetail from '../../../molecules/OnboardingActivityScheduleDetail';

const propTypes = {
  /** URl Parameters */
  params: PropTypes.shape({
    scheduleID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const defaultProps = {
  params: {},
};

class OnboardingActivitiesSchedule extends Component {
  render() {
    const {
      params: { scheduleID },
    } = this.props;

    if (scheduleID) {
      return <OnboardingActivityScheduleDetail {...this.props} />;
    }

    return <OnboardingActivityScheduleList {...this.props} />;
  }
}

OnboardingActivitiesSchedule.propTypes = propTypes;
OnboardingActivitiesSchedule.defaultProps = defaultProps;

export default OnboardingActivitiesSchedule;
