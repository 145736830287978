import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesEmployeeGym extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path d='M30.067 67.563h30.067V1H0v66.563z' id='a' />
        </defs>
        <g fill={color} fillRule='evenodd' transform='translate(17.5 14)'>
          <mask fill='#fff' id='b'>
            <use xlinkHref='#a' />
          </mask>
          <path
            d='M28.776 53.563V41.156c0-.64.518-1.157 1.157-1.157.64-.008 1.157.51 1.157 1.15v12.41h-2.314zm28.957-6.372l-2.502.008c-1.32 0-2.4 1.075-2.4 2.4v3.97H37.71l2.112-32.15 13.41 13.364c.892.89 2.45.89 3.34 0 .447-.446.693-1.04.693-1.67 0-.632-.246-1.226-.693-1.672l-15.79-15.79c-.91-.395-4.49-.624-8.498-.688v-1.334c2.446-.946 4.182-3.313 4.182-6.09C36.466 3.926 33.54 1 29.933 1 26.323 1 23.4 3.925 23.4 7.534c0 2.778 1.736 5.144 4.182 6.09v1.332c-4.01.064-7.59.293-8.5.69L3.295 31.434c-.447.447-.693 1.04-.693 1.67 0 .632.247 1.226.694 1.672.892.89 2.448.89 3.34 0L20.61 21.41l1.69 32.152H7.303v-3.97c0-1.325-1.075-2.4-2.402-2.4H2.4c-1.326 0-2.4 1.075-2.4 2.398V59.6C0 60.925 1.075 62 2.4 62h2.502c1.327 0 2.402-1.075 2.402-2.4V56h15.123l.447 8.51c0 .005.004.01.004.015 0 .025-.007.048-.007.073 0 1.638 1.33 2.965 2.97 2.965s2.965-1.328 2.965-2.965c0-.08-.017-.15-.023-.228V56h2.314v8.424c-.002.055-.016.107-.016.163 0 1.636 1.327 2.962 2.962 2.962 1.634 0 2.96-1.33 2.96-2.968 0-.025-.007-.05-.008-.075v-.01l.56-8.5h15.28v3.6c0 1.324 1.074 2.4 2.4 2.4h2.503c1.327 0 2.4-1.076 2.4-2.4V49.59c0-1.325-1.073-2.4-2.4-2.4z'
            fill='#F00'
            mask='url(#b)'
          />
        </g>
      </svg>
    );
  }
}

export default CatalogServicesEmployeeGym;
