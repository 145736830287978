const api_case_mgmt_v0_1_service_team_11_create_membership = {
  team_members: [
    {
      auth_group: 'SERVICE_AGENT',
      id: 4,
      user: 12,
    },
  ],
};

export default api_case_mgmt_v0_1_service_team_11_create_membership;
