import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import FormInputSelect from './FormInputSelect';

class Select extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        key: PropTypes.any,
        text: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        value: PropTypes.any,
      })
    ).isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    label: null,
    name: '',
    placeholder: null,
  };

  render() {
    const { label, name, options, placeholder, ...props } = this.props;

    return (
      <Field
        {...props}
        component={FormInputSelect}
        label={label}
        name={name}
        options={options}
        placeholder={placeholder}
      />
    );
  }
}

export default Select;
