import actionTypes from './actionTypes';

const productPermissionsActions = {};

productPermissionsActions.getProductPermissionGroupsStart = () => ({
  type: actionTypes.GET_PRODUCT_PERMISSION_GROUPS_START,
});

productPermissionsActions.getProductPermissionGroupsSuccess = (
  wiredPermissionGroups,
  locations,
  departments
) => ({
  departments,
  locations,
  type: actionTypes.GET_PRODUCT_PERMISSION_GROUPS_SUCCESS,
  wiredPermissionGroups,
});

productPermissionsActions.getProductPermissionGroupsFail = (error) => ({
  error,
  type: actionTypes.GET_PRODUCT_PERMISSION_GROUPS_FAIL,
});

productPermissionsActions.addProductPermissionGroupStart = () => ({
  type: actionTypes.ADD_PRODUCT_PERMISSION_GROUP_START,
});

productPermissionsActions.addProductPermissionGroupSuccess = (
  productPermissionGroup
) => ({
  productPermissionGroup,
  type: actionTypes.ADD_PRODUCT_PERMISSION_GROUP_SUCCESS,
});

productPermissionsActions.addProductPermissionGroupFail = (error) => ({
  error,
  type: actionTypes.ADD_PRODUCT_PERMISSION_GROUP_FAIL,
});

productPermissionsActions.toggleProductFromPermissionGroup = (
  productPermissionGroupID,
  productID
) => ({
  productID,
  productPermissionGroupID,
  type: actionTypes.TOGGLE_PRODUCT_FROM_PERMISSION_GROUP,
});

productPermissionsActions.removeProductPermissionGroupStart = (
  productPermissionGroupID
) => ({
  productPermissionGroupID,
  type: actionTypes.REMOVE_PRODUCT_PERMISSION_GROUP_START,
});

productPermissionsActions.removeProductPermissionGroupSuccess = (
  productPermissionGroupID
) => ({
  productPermissionGroupID,
  type: actionTypes.REMOVE_PRODUCT_PERMISSION_GROUP_SUCCESS,
});

productPermissionsActions.removeProductPermissionGroupFail = (error) => ({
  error,
  type: actionTypes.REMOVE_PRODUCT_PERMISSION_GROUP_FAIL,
});

productPermissionsActions.saveProductPermissionGroupStart = (
  productPermissionGroupID
) => ({
  productPermissionGroupID,
  type: actionTypes.SAVE_PRODUCT_PERMISSION_GROUP_START,
});

productPermissionsActions.saveProductPermissionGroupSuccess = (
  oldProductPermissionGroupID,
  productPermissionGroup
) => ({
  oldProductPermissionGroupID,
  productPermissionGroup,
  type: actionTypes.SAVE_PRODUCT_PERMISSION_GROUP_SUCCESS,
});

productPermissionsActions.saveProductPermissionGroupFail = (error) => ({
  error,
  type: actionTypes.SAVE_PRODUCT_PERMISSION_GROUP_FAIL,
});

productPermissionsActions.resetProductPermissionGroup = () => ({
  type: actionTypes.RESET_PRODUCT_PERMISSION_GROUPS,
});

export default productPermissionsActions;
