const UserStates = {
  AUTH_USER_CREATED: 'AUTH_USER_CREATED',

  AUTH_USER_CREATE_FAILED: 'AUTH_USER_CREATE_FAILED',

  MANAGER_CONFIRMED: 'MANAGER_CONFIRMED',

  MENTOR_ASSIGNED: 'MENTOR_ASSIGNED',

  MENTOR_ASSIGNMENT_FAILED: 'MENTOR_ASSIGNMENT_FAILED',
  // API default value
  SIGNUP_NOTIFICATION_SENT: 'SIGNUP_NOTIFICATION_SENT',
  USER_ACTIVE: 'USER_ACTIVE',
  USER_CREATED: 'USER_CREATED',
};

export default UserStates;
