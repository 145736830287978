import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form/immutable';
import FormInputTextarea from '../molecules/FormInputTextarea';
import _ from 'lodash';

class AdminProductSpecsForm extends PureComponent {
  static propTypes = {
    context: PropTypes.string,
    handleSave: PropTypes.func,
  };

  static defaultProps = {
    context: '',
    handleSave: _.noop,
  };

  render() {
    const { handleSave, context } = this.props;
    return (
      <Form>
        <Field
          className='transparent'
          component={FormInputTextarea}
          label='Specification'
          name='specifications'
          onBlur={handleSave}
          readOnly={context === 'dpc'}
        />
      </Form>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminProductSpecsForm = reduxForm({
  form: 'CatalogDetailFormSpecs',
})(AdminProductSpecsForm);

export default AdminProductSpecsForm;
