import { fromJS, Map } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addSingleChannel = (channels, newChannel) => {
  const channelId = newChannel.get('id');

  if (channels.has(channelId)) {
    // merge only if something already exists
    // the result of v.merge will be v itself if the merge with newCard didn't bring anything new
    // ferras.gif
    channels = channels.update(channelId, (t) => t.merge(newChannel));
  } else {
    channels = channels.set(channelId, newChannel);
  }

  return channels;
};

const addChannels = (channels, newChannels, reset) => {
  if (reset) {
    channels = Map(newChannels.map((channel) => [channel.get('id'), channel]));
  } else {
    newChannels.forEach((newChannel) => {
      channels = addSingleChannel(channels, newChannel);
    });
  }
  return channels;
};

const channelsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_CHANNELS_SUCCESS:
      return addChannels(state, fromJS(action.channels), action.reset);

    case actionTypes.GET_DIRECT_CHANNEL_FOR_USER_SUCCESS:
      return addSingleChannel(state, fromJS(action.directChannel));

    case actionTypes.UPDATE_CHANNEL_READ_COUNT: // not a mistake. this reducer handles two possible actions
    case actionTypes.LOAD_CHANNEL_SUCCESS: {
      const { channel } = action;
      return addSingleChannel(state, fromJS(channel));
    }
    case actionTypes.CHAT_MESSAGE_RECEIVED: {
      // Fix to provent posting the same message twice
      // Cause by WebsSockets API broadcasting one's own message
      if (state.hasIn([action.message.channel, 'last_message', 'id'])) {
        if (
          state.getIn([action.message.channel, 'last_message', 'id']) ===
          action.message.id
        ) {
          return state;
        }
      }

      // Only push the message if it belongs to the current channel
      return state.setIn(
        [action.message.channel, 'last_message'],
        fromJS(action.message)
      );

      // Note: a websocket event WebSocketEventTypes.CHANNEL is already dispatching the action LOAD_CHANNEL_SUCCESS
      // which will update the unread count. We don't have to do it "manually" here anymore
      // .updateIn([action.message.channel, 'message_count'], (value) => {
      //   if (action.currentUserId !== action.message.user_id) {
      //     return value + 1;
      //   }
      //   return value;
      // });
    }

    case actionTypes.DELETE_CHANNELS: // clears all the channels
      return INITIAL_STATE;

    case actionTypes.DELETE_CHAT_CHANNEL: // clears only one channel
      return state.delete(action.channelId);

    default:
      return state;
  }
};

export default channelsReducer;
