import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutableProps from 'react-immutable-proptypes';
import { Button, Modal } from 'semantic-ui-react';
import InboxForm from './inboxes/InboxForm';

const caseInboxType = PropTypes.shape({
  credentials: PropTypes.shape({
    id: PropTypes.number,
  }),
  eid: PropTypes.string,
  inbox: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  lastEmailScanned: PropTypes.string,
  serviceDepartment: PropTypes.shape({
    id: PropTypes.integer,
    name: PropTypes.string,
  }),
});

class EditCaseInboxButton extends PureComponent {
  static propTypes = {
    caseInbox: caseInboxType.isRequired,
    connectingGmailAccount: PropTypes.string,
    departmentList: ImmutableProps.list,
    reopenNewInboxModal: PropTypes.func,
  };

  static defaultProps = {
    departmentList: ImmutableProps.list,
  };

  state = { isModalOpen: false };

  componentDidMount() {
    const { connectingGmailAccount, caseInbox } = this.props;
    if (connectingGmailAccount) {
      if (caseInbox.credentials.id === Number(connectingGmailAccount)) {
        this.handleToggleModal();
      }
    }
  }

  handleToggleModal = () => {
    this.setState((prevState) => {
      if (prevState.isModalOpen) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      return { isModalOpen: !prevState.isModalOpen };
    });
  };

  uiButton = (
    <Button basic content='Edit' onClick={this.handleToggleModal} size='tiny' />
  );

  render() {
    const { reopenNewInboxModal, caseInbox, departmentList } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Modal
        className='scrolling'
        content={
          <InboxForm
            caseInbox={caseInbox}
            departmentList={departmentList}
            onClose={this.handleToggleModal}
            reopenNewInboxModal={reopenNewInboxModal}
          />
        }
        header='Edit Barista Inbox'
        onClose={this.handleToggleModal}
        open={isModalOpen}
        size='large'
        trigger={this.uiButton}
      />
    );
  }
}

export default EditCaseInboxButton;
