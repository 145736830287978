import actionTypes from './actionTypes';

const announcementActions = {};

announcementActions.loadSingleLocationSuccess = (location) => ({
  location,
  type: actionTypes.ANNOUNC_LOAD_SINGLE_LOCATION_SUCCESS,
});
announcementActions.loadSingleDepartmentSuccess = (department) => ({
  department,
  type: actionTypes.ANNOUNC_LOAD_SINGLE_DPT_SUCCESS,
});

announcementActions.slackChannelsStart = () => ({
  type: actionTypes.ANNOUNC_SLACK_CHANNELS_START,
});
announcementActions.slackChannelsEnd = () => ({
  type: actionTypes.ANNOUNC_SLACK_CHANNELS_END,
});

announcementActions.setSlackChannelsSuccess = (slackChannels) => ({
  slackChannels,
  type: actionTypes.ANNOUNC_SET_SLACK_CHANNELS_SUCCESS,
});
announcementActions.testAnnouncementsStart = () => ({
  type: actionTypes.TEST_ANNOUNCEMENTS_START,
});
announcementActions.testAnnouncementsCleanErrors = () => ({
  type: actionTypes.TEST_ANNOUNCEMENTS_CLEAN_ERRORS,
});

announcementActions.testAnnouncementsSuccess = ({
  status,
  invalidEmails = [],
}) => ({
  invalidEmails,
  status,
  type: actionTypes.TEST_ANNOUNCEMENTS_SUCCESS,
});

announcementActions.testAnnouncementsFail = () => ({
  type: actionTypes.TEST_ANNOUNCEMENTS_FAIL,
});

announcementActions.loadAnnouncementsStart = (status) => ({
  status,
  type: actionTypes.LOAD_ANNOUNCEMENTS_START,
});

announcementActions.loadAnnouncementsSuccess = (
  announcements,
  status,
  pagination,
  count
) => ({
  announcements,
  count,
  pagination,
  status,
  type: actionTypes.LOAD_ANNOUNCEMENTS_SUCCESS,
});

announcementActions.loadAnnouncementsFail = (error, status) => ({
  error,
  status,
  type: actionTypes.LOAD_ANNOUNCEMENTS_FAIL,
});

announcementActions.loadOneAnnouncementStart = () => ({
  type: actionTypes.GET_ANNOUNC_START,
});

announcementActions.loadOneAnnouncementSuccess = (announcement) => ({
  announcement,
  type: actionTypes.GET_ANNOUNC_SUCCESS,
});

announcementActions.loadOneAnnouncementFail = (errorMsg, announcementId) => ({
  announcementId,
  errorMsg,
  type: actionTypes.GET_ANNOUNC_FAIL,
});

announcementActions.postOneAnnouncementStart = (announcementId) => ({
  announcementId,
  type: actionTypes.POST_ANNOUNC_START,
});
announcementActions.postOneAnnouncementEnd = (announcementId) => ({
  announcementId,
  type: actionTypes.POST_ANNOUNC_END,
});

announcementActions.loadJobRolesStart = () => ({
  type: actionTypes.ANNOUNC_LOAD_ROLE_START,
});

announcementActions.loadJobRolesSuccess = (jobRoles) => ({
  jobRoles,
  type: actionTypes.ANNOUNC_LOAD_ROLE_SUCCESS,
});
announcementActions.loadJobRolesFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.ANNOUNC_LOAD_ROLE_FAIL,
});

announcementActions.loadDepartmentsStart = () => ({
  type: actionTypes.ANNOUNC_LOAD_DPTS_START,
});

announcementActions.loadDepartmentsSuccess = (departments) => ({
  departments,
  type: actionTypes.ANNOUNC_LOAD_DPTS_SUCCESS,
});

announcementActions.loadDepartmentsFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.ANNOUNC_LOAD_DPTS_FAIL,
});

announcementActions.updateAnnouncementStart = () => ({
  type: actionTypes.ANNOUNC_UPDATE_START,
});

announcementActions.updateAnnouncementFail = () => ({
  type: actionTypes.ANNOUNC_UPDATE_FAIL,
});

announcementActions.updateAnnouncementSuccess = () => ({
  type: actionTypes.ANNOUNC_UPDATE_SUCCESS,
});

announcementActions.updateGroupStart = () => ({
  type: actionTypes.ANNOUNC_UPDATE_GROUP_START,
});

announcementActions.updateGroupFail = (errorMsg) => ({
  errorMsg,
  type: actionTypes.ANNOUNC_UPDATE_GROUP_FAIL,
});

announcementActions.updateGroupSavedSuccess = (group) => ({
  group,
  type: actionTypes.ANNOUNC_SAVE_GROUP_SUCCESS,
});

announcementActions.updateGroupAddedSuccess = (group) => ({
  group,
  type: actionTypes.ANNOUNC_ADDED_GROUP_SUCCESS,
});

announcementActions.updateGroupDeletedSuccess = (groupId, announcementId) => ({
  announcementId,
  groupId,
  type: actionTypes.ANNOUNC_DELETED_GROUP_SUCCESS,
});

announcementActions.deleteAnnouncementSuccess = (announcementId) => ({
  announcementId,
  type: actionTypes.DELETE_ANNOUNCEMENT_SUCCESS,
});

announcementActions.deleteOneAnnouncementStart = (announcementId) => ({
  announcementId,
  type: actionTypes.DELETE_ANNOUNC_START,
});

announcementActions.saveStatusSuccess = (
  announcementId,
  status,
  previousStatus
) => ({
  announcementId,
  previousStatus,
  status,
  type: actionTypes.SAVE_ANNOUNC_STATUS_SUCCESS,
});

announcementActions.startRecall = (announcementId) => ({
  announcementId,
  type: actionTypes.START_RECALL_ANNOUNCEMENT,
});

announcementActions.failRecall = (announcementId) => ({
  announcementId,
  type: actionTypes.FAIL_RECALL_ANNOUNCEMENT,
});

announcementActions.successRecall = (announcementId) => ({
  announcementId,
  type: actionTypes.SUCCESS_RECALL_ANNOUNCEMENT,
});

announcementActions.createNewAnnouncement = () => ({
  type: actionTypes.NEW_ANNOUNCEMENT_CREATED,
});

export default announcementActions;
