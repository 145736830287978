const api_integration_servicenow_v0_1_custom_search_for_choices = {
  display: 'title',
  real_value: 'sys_id',
  records: [
    {
      sc_catalog: 'Service Catalog',
      sys_id: '00728916937002002dcef157b67ffb6d',
      title: 'Template Management',
    },
    {
      sc_catalog: 'Admin Home',
      sys_id: '02608116eb430100d4360c505206fe46',
      title: 'Problem',
    },
    {
      sc_catalog: 'Admin Home',
      sys_id: '03f38475d7021100b9a5c7400e6103af',
      title: 'User Interface',
    },
  ],
};

export default api_integration_servicenow_v0_1_custom_search_for_choices;
