import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'semantic-ui-react';
import _ from 'lodash';

import LocaleSingleton from '../../utils/LocaleSingleton';

// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Organism
import BlockModal from '../organisms/BlockModal';
import moment from 'moment';

class DatePickerModal extends PureComponent {
  static propTypes = {
    /** Rend the Daypicker in a blockModal or a Modal */
    blockModal: PropTypes.bool,
    children: PropTypes.node,
    closeTrigger: PropTypes.node,
    handleCloseModal: PropTypes.func,
    handleOpenModal: PropTypes.func,
    modalActions: PropTypes.node,
    /** Node selected where the portal will be added - Only for blockModal & usePortal */
    mountNodeSelector: PropTypes.string,
    /** open state */
    open: PropTypes.bool,
    setDateModal: PropTypes.func,
    /** title for the DayPicker Portal/Modal */
    title: PropTypes.string,
    /** Rend DayPicker in a Portal */
    usePortal: PropTypes.bool,
  };

  static defaultProps = {
    blockModal: false,
    children: null,
    closeTrigger: null,
    handleCloseModal: _.noop,
    handleOpenModal: _.noop,
    modalActions: null,
    mountNodeSelector: '',
    open: false,
    setDateModal: _.noop,
    title: null,
    usePortal: false,
  };

  componentDidMount() {
    moment.locale(LocaleSingleton.get());
  }

  render() {
    const {
      children,
      closeTrigger,
      blockModal,
      title,
      modalActions,
      mountNodeSelector,
      usePortal,
      setDateModal,
      handleOpenModal,
      handleCloseModal,
      open,
    } = this.props;

    // Rend a blockModal or a Modal dependly on the prop
    let dateRender;

    if (blockModal || usePortal) {
      const propsModal = {
        modalActions: modalActions,
        modalTitle: title,
        onClose: handleCloseModal,
        open: open,
        ref: setDateModal,
      };

      if (usePortal) {
        propsModal.mountNodeSelector = mountNodeSelector;
      }

      dateRender = <BlockModal {...propsModal}>{children}</BlockModal>;
    } else {
      dateRender = (
        <Modal
          className='scrolling'
          closeIcon={closeTrigger}
          closeOnDimmerClick={false}
          onClose={handleCloseModal}
          onOpen={handleOpenModal}
          open={open}
          size='small'
        >
          <div className='form ui'>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content
              className={modalActions ? 'collapsed withFooter' : 'collapsed'}
            >
              <ScrollArea>{children}</ScrollArea>
            </Modal.Content>
            {modalActions && <Modal.Actions>{modalActions}</Modal.Actions>}
          </div>
        </Modal>
      );
    }

    return dateRender;
  }
}

export default DatePickerModal;
