import { Button, Modal } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import ScrollArea from '../atoms/ScrollArea';
import ValidatedField from '../molecules/ValidatedField';
import ValidatedForm from '../molecules/ValidatedForm';

const propTypes = {
  emailDomains: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  trigger: PropTypes.element.isRequired,
};

const defaultProps = {
  emailDomains: Immutable.List(),
  isLoading: false,
  isOpen: false,
  onClose: _.noop,
  onOpen: _.noop,
  onSubmit: _.noop,
};

const initialValues = Immutable.fromJS({
  domain: '',
});

// took from https://www.labnol.org/internet/regular-expressions-forms/28380/
const validDomainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

class AddEmailDomainForm extends Component {
  validate = (values) => {
    const { emailDomains } = this.props;

    const errors = {};

    let newDomain = values.get('domain') || '';
    newDomain = newDomain.trim();

    if (_.isEmpty(newDomain)) {
      errors.domain = 'Required';
    } else if (!validDomainRegex.test(newDomain)) {
      errors.domain = 'Invalid domain';
    } else {
      const alreadyExists = Boolean(
        emailDomains.find(
          (emailDomain) => emailDomain.get('domain') === newDomain
        )
      );

      if (alreadyExists) {
        errors.domain = 'Domain already exists';
      }
    }

    return errors;
  };

  render() {
    const {
      isLoading,
      isOpen,
      onClose,
      onOpen,
      onSubmit,
      trigger,
    } = this.props;

    return (
      <Modal
        className='scrolling'
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        size='small'
        trigger={trigger}
      >
        <ValidatedForm
          form='AddEmailDomainForm'
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={this.validate}
        >
          <Modal.Header>{'Add New Domain'}</Modal.Header>
          <Modal.Content className='withFooter'>
            <ScrollArea>
              <ValidatedField fluid label='Domain' name='domain' />
            </ScrollArea>
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={isLoading} loading={isLoading} type='submit'>
              {'Submit'}
            </Button>
          </Modal.Actions>
        </ValidatedForm>
      </Modal>
    );
  }
}

AddEmailDomainForm.propTypes = propTypes;

AddEmailDomainForm.defaultProps = defaultProps;

export default AddEmailDomainForm;
