import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Dimmer, Loader } from 'semantic-ui-react';
import { fromJS, List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

// Blocks
import Block from '../blocks/Block';
import Background01 from '../blocks/background/Background01';
import EspressiveLogo from '../blocks/logo/Espressive01';
import Form01 from '../blocks/form/Form01';
// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Molecules
import WorkflowHeader from '../molecules/WorkflowHeader';
import WorkflowMenu from '../molecules/WorkflowMenu';
import { Boundaries } from 'cascara-middleware';

const defaultSubmit = (cb) => {
  cb();
};

class WorkflowForm extends PureComponent {
  static propTypes = {
    blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    formSyncErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    handleCloseButtonClick: PropTypes.func,
    handleOnBackButtonClick: PropTypes.func,
    handleOnSkipButtonClick: PropTypes.func,
    initialValues: ImmutablePropTypes.map,
    isLoading: PropTypes.bool,
    nav: PropTypes.shape({
      background: PropTypes.shape({
        hide: PropTypes.bool,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        type: PropTypes.string,
      }),
      espressiveLogo: PropTypes.bool,
      footer: PropTypes.shape({
        hide: PropTypes.bool,
        next: PropTypes.string,
        skip: PropTypes.string,
      }),
      progress: PropTypes.bool,
      toolbar: PropTypes.shape({
        hide: PropTypes.bool,
        next: PropTypes.string,
        prev: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    onSubmit: PropTypes.func,
    progress: ImmutablePropTypes.mapContains({
      max_steps: PropTypes.number.isRequired,
      steps_completed: PropTypes.number.isRequired,
    }),

    step: PropTypes.number,
    workflowID: PropTypes.number,

    workflowTaskID: PropTypes.number,
  };

  static defaultProps = {
    errors: Map(),
    errorsApi: List(),
    formSyncErrors: null,
    handleCloseButtonClick: noop,
    handleOnBackButtonClick: noop,
    handleOnSkipButtonClick: noop,
    initialValues: Map(),
    isLoading: false,
    nav: {
      background: {
        hide: true,
        id: 0,
        type: 'background',
      },
      espressiveLogo: false,
      footer: {
        hide: true,
        next: '',
        skip: '',
      },
      progress: false,
      toolbar: {
        hide: true,
        next: '',
        prev: '',
        title: '',
      },
    },
    onSubmit: noop,
    progress: fromJS({
      max_steps: 0,
      steps_completed: 0,
    }),
    step: 0,
    workflowID: null,
    workflowTaskID: null,
  };

  state = {
    isLoadingState: false,
  };

  static getDerivedStateFromProps = (props, state) => {
    if (!props.isLoading && state.isLoading) {
      return {
        isLoadingState: true,
      };
    }
    return {
      isLoadingState: false,
    };
  };

  alternateSubmit = fromJS({
    cb: defaultSubmit,
  });
  alternateBack = fromJS({
    cb: defaultSubmit,
  });
  alternateSkip = fromJS({
    cb: defaultSubmit,
  });
  fakeNext = false; // Are we running an alternate Submit ?

  setAlternateSubmit = (cb) => {
    const callback = cb ? cb : defaultSubmit;
    this.alternateSubmit = this.alternateSubmit.set('cb', callback);

    // Set fakeNext is an alternate callback has been pass
    this.fakeNext = Boolean(cb);
  };

  setAlternateBack = (cb = defaultSubmit) => {
    this.alternateBack = this.alternateBack.set('cb', cb);
  };

  setAlternateSkip = (cb = defaultSubmit) => {
    this.alternateSkip = this.alternateSkip.set('cb', cb);
  };

  onResetLocalLoading = () => {
    // Allow any block to reset the local isLoading state
    this.setState({
      isLoadingState: false,
    });
  };

  onForceLocalLoading = () => {
    // Allow any block to force the local isLoading state
    this.setState({
      isLoadingState: true,
    });
  };

  handleSubmit = (value) => {
    const { onSubmit } = this.props;
    this.setState(
      {
        isLoadingState: true,
      },
      () => {
        /** Until we find something less tricky, this alternateSubmit func is working */
        const cb = this.alternateSubmit.get('cb');

        cb((newValue) => {
          onSubmit(newValue || value);

          // Reset alternateSubmit and alternateBack
          this.setAlternateSubmit();
          this.setAlternateBack();
          this.setAlternateSkip();
        });
      }
    );
  };

  handleOnBack = () => {
    const { handleOnBackButtonClick } = this.props;

    this.setState(
      {
        isLoadingState: true,
      },
      () => {
        const cb = this.alternateBack.get('cb');
        cb(() => {
          handleOnBackButtonClick();

          // Reset alternateSubmit and alternateBack
          this.setAlternateSubmit();
          this.setAlternateBack();
          this.setAlternateSkip();
        });
      }
    );
  };

  handleOnSkip = () => {
    const { handleOnSkipButtonClick } = this.props;

    const cb = this.alternateSkip.get('cb');
    cb(() => {
      handleOnSkipButtonClick();

      // Reset alternateSubmit and alternateBack
      this.setAlternateSubmit();
      this.setAlternateBack();
      this.setAlternateSkip();

      this.setState({
        isLoadingState: true,
      });
    });
  };

  isNavSectionVisible = (sectionName) => {
    const { nav } = this.props;

    return (
      Boolean(nav) &&
      Boolean(nav[sectionName]) &&
      Boolean(!nav[sectionName].hide)
    );
  };

  /**
   * Tells if the header's close icon should be visible.
   * @return {boolean}
   */
  isCloseIconVisible = () => {
    const { progress } = this.props;

    // If we get "nav.toolbar.hide" from the blob data, we should never display the header
    if (!this.isNavSectionVisible('toolbar')) {
      return false;
    }

    const stepsCompleted = progress.get('steps_completed');
    const maxSteps = progress.get('max_steps');

    const isLastWorkflowTask = stepsCompleted === maxSteps;

    return !isLastWorkflowTask || maxSteps === 1;
  };

  /**
   * Tells if header elements, but close icon, are visible from Blobifier configuration.
   * @return {boolean}
   */
  areHeaderElementsVisible = () => this.isNavSectionVisible('toolbar');

  hasEspressiveLogo = () => {
    const { nav } = this.props;

    return Boolean(nav) && nav.espressiveLogo;
  };

  renderBlocks = () => {
    const { blocks, errors, errorsApi, handleCloseButtonClick } = this.props;

    return blocks.map((block) => {
      const blockWithProps = Object.assign({}, block);

      return (
        <Block
          block={blockWithProps}
          errors={errors}
          errorsApi={errorsApi}
          forceBlockLoadingState={this.onForceLocalLoading}
          key={block.id}
          onCloseWorkflow={handleCloseButtonClick}
          resetBlockLoadingState={this.onResetLocalLoading}
          setAlternateBack={this.setAlternateBack}
          setAlternateSkip={this.setAlternateSkip}
          setAlternateSubmit={this.setAlternateSubmit}
        />
      );
    });
  };

  render() {
    const {
      blocks,
      formSyncErrors,
      handleCloseButtonClick,
      isLoading,
      nav,
      progress,
      step,
      workflowID,
      workflowTaskID,
      initialValues,
    } = this.props;
    const { isLoadingState } = this.state;

    const isCloseIconVisible = this.isCloseIconVisible();
    const areHeaderElementsVisible = this.areHeaderElementsVisible();

    const hasHeader = isCloseIconVisible || areHeaderElementsVisible;

    const hasFooter = this.isNavSectionVisible('footer');
    const hasBackground = this.isNavSectionVisible('background');

    // is the default button to move to next task visible?
    const isNextButtonVisible = hasFooter && Boolean(nav.footer.next);

    // we want to show it up when a workflow operation is in progress
    // and the task has no Next button
    // Avoid to rend it if we pass an alternate submit which means that a button is there
    const isLargeLoaderActive =
      isLoading && !isNextButtonVisible && !this.fakeNext;

    const isStillLoading = isLoading || isLoadingState;

    return (
      <Form01
        blocks={blocks} // TODO check this out
        className={classNames(
          'esp-workflow ui form',
          {
            'menu-top': hasHeader,
          },
          {
            'menu-bottom': hasFooter,
          }
        )}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
      >
        <Dimmer active={isLargeLoaderActive} inverted>
          <Loader size='large' />
        </Dimmer>
        {hasHeader ? (
          <WorkflowHeader
            areElementsVisible={areHeaderElementsVisible}
            header={nav.toolbar}
            isCloseIconVisible={isCloseIconVisible}
            isLoading={isStillLoading}
            isProgressActive={Boolean(nav.progress)}
            nextUrl={'#TODO'}
            onBackButtonClick={this.handleOnBack}
            onCloseButtonClick={handleCloseButtonClick}
            progress={progress}
            step={step}
            workflowID={workflowID}
            workflowTaskID={workflowTaskID}
          />
        ) : null}

        <div className='workflow-content'>
          <ScrollArea indicateScrolling>
            <div
              className='esp-blocks'
              component='div'
              id={`wft_${this.step || 0}`}
            >
              <Boundaries>
                <>
                  {this.renderBlocks()}

                  {this.hasEspressiveLogo() ? (
                    <EspressiveLogo id='intro' type='logo' />
                  ) : null}
                </>
              </Boundaries>
            </div>
          </ScrollArea>
        </div>

        {hasFooter ? (
          <WorkflowMenu
            formSyncErrors={formSyncErrors}
            isLoading={isStillLoading}
            nextButton={nav.footer.next}
            nextUrl={'#TODO'}
            onSkipButtonClick={this.handleOnSkip}
            pageBackground={hasBackground}
            placement='bottom'
            progress={progress}
            skipButton={nav.footer.skip}
            workflowID={workflowID}
            workflowTaskID={workflowTaskID}
          />
        ) : null}

        {hasBackground ? <Background01 {...nav.background} /> : null}
      </Form01>
    );
  }
}

export default WorkflowForm;
