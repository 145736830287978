import { fromJS } from 'immutable';
// Util
import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

// Limit of schedule item per page
const limitByPage = 24;

const mapStateToProps = (state) => {
  const serviceDepartmentCategories =
    state.getIn(['caseMgmt', 'serviceDepartmentCategories']) || fromJS({});

  return {
    categoriesList: serviceDepartmentCategories.get('list'),
    isLoadingTeamList: serviceDepartmentCategories.get('isLoading'),
    limitByPage,
    listCount: serviceDepartmentCategories.get('count'),
    loadingDeleteID: serviceDepartmentCategories.get('loadingDeleteID'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewCategory() {},

  deleteOneCategory(id, departmentID, msg) {
    return new Promise((resolve, reject) => {
      dispatch(caseMgmtThunks.deleteTaskCategories(id))
        .then(() => {
          dispatch(toastNotificationsActions.success(msg));
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadCategoriesList(
    page = 1,
    limit = limitByPage,
    orderBy = '-sys_date_updated',
    departmentID
  ) {
    const offset = (page - 1) * limit;
    dispatch(
      caseMgmtThunks.loadTaskCategories({
        departmentID,
        limit,
        offset,
        orderBy,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
