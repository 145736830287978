class EspFilters {
  constructor() {
    this.query = new Set();
    this.cursor = new Set();
  }

  or() {
    const cursor = Array.from(this.cursor).filter((i) => i);
    if (cursor.length) {
      this.query.add(cursor.join('&'));
      this.cursor.clear();
    }
    return this;
  }

  escapeValue(value) {
    if (!value) {
      return value;
    }

    value = String(value);

    // This hack is needed because the backend does not handle the `&` character correctly when it is escaped.
    // The scenario is when the value sent is just the single character `&`.
    //  Example: `&` should be `%26`
    // JIRA ticket: https://espressive.atlassian.net/browse/DEV-27341
    if (value && value === '&') {
      return value.replace(/&/g, '%26');
    }

    if (value && value?.includes('&')) {
      value = value.replace(/&/g, '\\%26').trim();
    }

    return value;
  }

  equalTo(field, value) {
    value = this.escapeValue(value);
    this.cursor.add(`${field}__EQ=${value}`);
    return this;
  }

  insensitiveEqualTo(field, value) {
    value = this.escapeValue(value);
    this.cursor.add(`${field}__IEQ=${value}`);
    return this;
  }

  differentTo(field, value) {
    value = this.escapeValue(value);
    this.cursor.add(`${field}__!EQ=${value}`);
    return this;
  }

  startsWith(field, pattern, caseSensitive = false) {
    pattern = this.escapeValue(pattern);
    this.cursor.add(`${field}__${caseSensitive ? '' : 'I'}SW=${pattern}`);
    return this;
  }

  endsWith(field, pattern, caseSensitive = false) {
    pattern = this.escapeValue(pattern);
    this.cursor.add(`${field}__${caseSensitive ? '' : 'I'}EW=${pattern}`);
    return this;
  }

  contains(field, pattern, caseSensitive = false) {
    pattern = this.escapeValue(pattern);
    this.cursor.add(`${field}__${caseSensitive ? '' : 'I'}C=${pattern}`);
    return this;
  }

  containsInteger(field, value) {
    this.cursor.add(`${field}__INTC=${value}`);
    return this;
  }

  in(field, patterns) {
    patterns = this.escapeValue(patterns.join(','));
    this.cursor.add(`${field}__IN=${patterns}`);
    return this;
  }

  notIn(field, patterns) {
    patterns = this.escapeValue(patterns.join(','));
    this.cursor.add(`${field}__!IN=${patterns}`);
    return this;
  }

  isTrue(field) {
    this.cursor.add(`${field}__IBOOL=True`);
    return this;
  }

  isDifferentFromTrue(field) {
    this.cursor.add(`${field}__!IBOOL=True`);
    return this;
  }

  isFalse(field) {
    this.cursor.add(`${field}__IBOOL=False`);
    return this;
  }

  isDifferentFromFalse(field) {
    this.cursor.add(`${field}__!IBOOL=False`);
    return this;
  }

  isNull(field) {
    this.cursor.add(`${field}__ISNULL=True`);
    return this;
  }

  isDifferentFromNull(field) {
    this.cursor.add(`${field}__ISNULL=False`);
    return this;
  }

  notNull(field) {
    this.cursor.add(`${field}__!ISNULL=True`);
    return this;
  }

  getQuerySize() {
    return this.cursor.size;
  }

  greaterThan(field, value) {
    value = this.escapeValue(value);
    this.cursor.add(`${field}__GT=${value}`);
    return this;
  }

  lessThan(field, value) {
    value = this.escapeValue(value);
    this.cursor.add(`${field}__LT=${value}`);
    return this;
  }

  asQueryString() {
    const cursor = Array.from(this.cursor).filter((i) => i);
    const query = Array.from(this.query);
    if (cursor.length) {
      query.push(cursor.join('&'));
    }
    return query.length ? query.join('&OR') : '';
  }

  asEncodedQueryString() {
    return encodeURIComponent(this.asQueryString());
  }

  asEncodedString() {
    return encodeURIComponent(this.toString());
  }

  toString() {
    const queryString = this.asQueryString();
    return queryString ? `?esp_filters=${queryString}` : '';
  }
}

export default EspFilters;
