import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const add = (oldItems, newItems) => {
  newItems.forEach((item) => {
    oldItems = oldItems.set(item.get('id'), item);
  });
  return oldItems;
};

const userRulesEntities = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.USER_RULES_SUCCESS:
      return add(state, fromJS(action.userRules));

    default:
      return state;
  }
};

export default userRulesEntities;
