import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesStorageDevices extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M47.66 32.21c-4.518 0-8.18 3.662-8.18 8.18s3.662 8.18 8.18 8.18 8.18-3.662 8.18-8.18c0-2.17-.862-4.25-2.396-5.784-1.534-1.534-3.615-2.396-5.784-2.396zm4.93 2.83c.425 0 .77.345.77.77 0 .425-.345.77-.77.77-.425 0-.77-.345-.77-.77.005-.42.35-.76.77-.76v-.01zm-4.74-2c.425 0 .77.345.77.77 0 .425-.345.77-.77.77-.425 0-.77-.345-.77-.77.01-.414.346-.745.76-.75l.01-.02zm-6.45 8.17c-.425 0-.77-.345-.77-.77 0-.425.345-.77.77-.77.425 0 .77.345.77.77.003.208-.08.408-.225.555-.147.146-.347.228-.555.225l.01-.01zm1.73-6.41c.425 0 .77.345.77.77 0 .425-.345.77-.77.77-.425 0-.77-.345-.77-.77.005-.42.35-.76.77-.76v-.01zm.12 10.78c-.425 0-.77-.345-.77-.77 0-.425.345-.77.77-.77.425 0 .77.345.77.77 0 .425-.345.77-.77.77zm4.6 2.19c-.425 0-.77-.345-.77-.77 0-.425.345-.77.77-.77.425 0 .77.345.77.77-.005.42-.35.76-.77.76v.01zm-.19-4.1c-1.8 0-3.26-1.46-3.26-3.26 0-1.8 1.46-3.26 3.26-3.26 1.8 0 3.26 1.46 3.26 3.26-.006 1.797-1.463 3.25-3.26 3.25v.01zm4.85 2.06c-.425 0-.77-.345-.77-.77 0-.425.345-.77.77-.77.425 0 .77.345.77.77-.003.204-.086.4-.233.542-.146.142-.343.22-.547.218l.01.01zm1.56-6c.425 0 .77.345.77.77 0 .425-.345.77-.77.77-.425 0-.77-.345-.77-.77-.01-.21.064-.414.207-.567.144-.153.343-.24.553-.243l.01.04z' />
          <path d='M71.73 15.5H23.5c-.552 0-1 .448-1 1v62.08c0 .552.448 1 1 1h48.23c.552 0 1-.448 1-1V16.5c0-.552-.448-1-1-1zM39.91 72.92c-2.073.006-3.88-1.41-4.367-3.426-.488-2.015.47-4.1 2.317-5.044l16.4-10.24h.06c.416-.21.92-.12 1.24.22.172.18.263.422.25.67 0 .36-.26.66-.69 1.08l-11.27 14.4c-.785 1.443-2.297 2.342-3.94 2.34zm11.5-10l4.67-6c.566-.464.925-1.132 1-1.86.03-.594-.187-1.173-.6-1.6-.57-.605-1.42-.86-2.23-.67l-.57.31-13.95 8.71c-10.767-3.984-16.91-15.337-14.353-26.53 2.557-11.19 13.018-18.75 24.447-17.666 11.43 1.085 20.28 10.48 20.685 21.953.4 11.474-7.768 21.467-19.09 23.354h-.01z' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesStorageDevices;
