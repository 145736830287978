import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsNewEmployee extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M57.15 49.58c-.064-.815-.064-1.635 0-2.45 4.51-4.11 4.86-9 4.6-19.7-.26-10.29-7.78-13.93-14.14-13.93s-13.9 3.64-14.15 13.92c-.26 10.66.09 15.59 4.6 19.7.064.815.064 1.635 0 2.45-5.07 1.19-22.56 6-22.56 15.25v6.46c0 .867.703 1.57 1.57 1.57H48.7c-.173-1.04-.203-2.1-.09-3.15h-30v-4.88c0-6.5 15.7-11.27 21.2-12.43.66-.137 1.16-.68 1.24-1.35.23-1.685.23-3.395 0-5.08-.074-.33-.253-.63-.51-.85-3.72-3.16-4.2-6.79-3.94-17.62.25-10.08 8.48-10.86 11-10.86 2.52 0 10.75.78 11 10.86.26 10.83-.22 14.45-3.94 17.62-.257.22-.436.52-.51.85-.23 1.685-.23 3.395 0 5.08.08.67.58 1.213 1.24 1.35 5.47 1.19 21.22 5.91 21.22 12.43v4.89H73.3c.116 1.045.09 2.102-.08 3.14h4.91c.867 0 1.57-.703 1.57-1.57v-6.46c.01-9.24-17.48-14.06-22.55-15.24z' />
          <path d='M60.96 59.58c-4.604 0-8.754 2.774-10.515 7.027-1.76 4.254-.785 9.15 2.472 12.403 3.256 3.254 8.153 4.225 12.405 2.46 4.252-1.764 7.022-5.916 7.018-10.52-.01-6.28-5.1-11.364-11.38-11.37zm0 19.61c-4.545 0-8.23-3.685-8.23-8.23s3.685-8.23 8.23-8.23 8.23 3.685 8.23 8.23c-.006 4.543-3.687 8.224-8.23 8.23z' />
          <path d='M62.51 69.58v-4.11c0-.867-.703-1.57-1.57-1.57-.867 0-1.57.703-1.57 1.57v4.11h-4.14c-.867 0-1.57.703-1.57 1.57 0 .867.703 1.57 1.57 1.57h4.14v4.14c0 .867.703 1.57 1.57 1.57.867 0 1.57-.703 1.57-1.57v-4.1h4.1c.878 0 1.59-.712 1.59-1.59 0-.878-.712-1.59-1.59-1.59h-4.1z' />
        </g>
      </svg>
    );
  }
}

export default CardsNewEmployee;
