import async from 'async';
import { find, first, hasIn, isEmpty, noop } from 'lodash';
import { getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
// Utils
import APIcall from '../utils/APIcall';
import browserHistory from '../utils/browserHistory';
import endpointGenerator from '../utils/endpointGenerator';
import uiPathGenerator from '../utils/uiPathGenerator';

// Globals
import AuthBackends from '../globals/AuthBackends';
import AuthBackendsDirection from '../globals/AuthBackendsDirection';
import { SortFieldNames } from '../globals/SortProductStateOptions';
// Selector
import getEmailsSetting from '../selectors/getEmailsSetting';
// Actions
import integrationActions from './integrationActions';
import workflowThunks from './workflowThunks';
import { getExistingEmployeeWFeid } from '../selectors/shouldLaunchExistingEmployeeWF';
import inboxesThunks from './inboxesThunks';

const isIPExp = new RegExp(
  /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/
);
const SERVICENOW_CATEGORY_TABLE = 'sc_category';

const parseScheduleData = ({ server, ...scheduleData }) => ({
  ...scheduleData,
  ...(scheduleData.start_time && {
    start_time: new Date(scheduleData.start_time).toISOString(),
  }),
  // If server is a valid IP will return:
  // ip_address = server, dns_name = null
  // else
  // ip_address = null, dns_nme = server
  ...(server.match(isIPExp)
    ? {
        dns_name: null,
        ip_address: server,
      }
    : {
        dns_name: server,
        ip_address: null,
      }),
  connection_timeout: 30,
});

const integrationThunks = {};

integrationThunks.getConfluenceCredentials = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.getConfluenceCredentialsStart());
    const url = endpointGenerator.genPath(
      'espIntegration.integrations.confluence.setCredential'
    );
    APIcall.get({
      token: true,
      url,
    })
      .then(({ body }) => {
        dispatch(integrationActions.getConfluenceCredentialsSuccess(body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(integrationActions.getConfluenceCredentialsFail());
        reject(error);
      });
  });
integrationThunks.setConfluenceCredentials = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.setConfluenceCredentialsStart());
    const url = endpointGenerator.genPath(
      'espIntegration.integrations.confluence.setCredential'
    );
    APIcall.post({
      data,
      token: true,
      url,
    })
      .then(({ body }) => {
        dispatch(integrationActions.setConfluenceCredentialsSuccess(body));
        browserHistory.push({
          pathname: uiPathGenerator.genPath(
            'admin.integrations.confluence.contentSync'
          ),
        });
        resolve(body);
      })
      .catch((error) => {
        dispatch(integrationActions.setConfluenceCredentialsFail(error));
        reject(error);
      });
  });

integrationThunks.confluenceSyncNow = () => (dispatch) => {
  dispatch(integrationActions.loadConfluenceLastSyncStart());
  const url = endpointGenerator.genPath('espIntegration.confluence.runSync');
  APIcall.post({
    token: true,
    url,
  })
    .then(({ body }) => {
      dispatch(integrationActions.loadConfluenceLastSyncSuccess(body));
    })
    .catch(() => {
      dispatch(integrationActions.loadConfluenceLastSyncFail());
    });
};

integrationThunks.testConfluenceSettings = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.testConfluenceSettingsStart());
    const url = endpointGenerator.genPath(
      'espIntegration.integrations.confluence.testConnection'
    );
    APIcall.post({
      data,
      token: true,
      url,
    })
      .then(({ body }) => {
        dispatch(integrationActions.testConfluenceSettingsSuccess(body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(integrationActions.testConfluenceSettingsFail());
        reject(error);
      });
  });

integrationThunks.loadConfluenceContentSync = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.loadConfluenceContentSyncStart());
    const url = endpointGenerator.genPath('espIntegration.confluence.set');
    APIcall.get({
      token: true,
      url,
    })
      .then(({ body }) => {
        if (!body.scheduled_sync) {
          body.scheduled_sync = {
            days: [],
          };
        }
        dispatch(integrationActions.loadConfluenceContentSyncSuccess(body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(integrationActions.loadConfluenceContentSyncFail());
        reject(error);
      });
  });

integrationThunks.setConfluenceContentSync = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.loadConfluenceContentSyncStart());
    const url = endpointGenerator.genPath('espIntegration.confluence.set');
    APIcall.post({
      data,
      token: true,
      url,
    })
      .then(({ body }) => {
        dispatch(integrationActions.loadConfluenceContentSyncSuccess(body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(integrationActions.loadConfluenceContentSyncFail());
        reject(error);
      });
  });

integrationThunks.loadConfluenceLastSync = () => (dispatch) => {
  dispatch(integrationActions.loadConfluenceLastSyncStart());
  const url = endpointGenerator.genPath('espIntegration.confluence.lastSync');
  APIcall.get({
    token: true,
    url,
  })
    .then(({ body }) => {
      dispatch(integrationActions.loadConfluenceLastSyncSuccess(body));
    })
    .catch(() => {
      dispatch(integrationActions.loadConfluenceLastSyncFail());
    });
};

integrationThunks.loadBusinessObjectList = () => (dispatch) => {
  dispatch(integrationActions.startGetBusinessObjects());

  async.waterfall(
    [
      // 1. Get ID of integration asked
      (next) => {
        const filter = 'identifier__EQ=customer_data_integration';
        APIcall.get({
          error() {
            dispatch(integrationActions.uploadCsvError());
          },
          query: {
            esp_filters: encodeURI(filter),
          },
          success(res) {
            if (res.body.results.length > 0 && res.body.results[0].id) {
              next(null, res.body.results[0].eid);
            }
          },
          token: true,
          url: endpointGenerator.genPath('espIntegration.integrations'),
        });
      },
      // 2. Set the BO list
      (eid, next) => {
        APIcall.get({
          error() {
            dispatch(integrationActions.uploadCsvError());
          },
          success(res) {
            dispatch(
              integrationActions.getBusinessObjectsSuccess(res.body.results)
            );
            next();
          },
          token: true,
          url: endpointGenerator.genPath(
            'espIntegration.integrations.instance.business_objects',
            {
              integrationEID: eid,
            }
          ),
        });
      },
    ],
    (error) => {
      if (error) {
        dispatch(integrationActions.uploadCsvError());
      }
    }
  );
};

integrationThunks.uploadCSVFile = (file, businessObjectID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const fileName = file.name;
    // Create a new FormData object.
    const formData = new FormData();

    formData.append('csv_file', file, fileName);
    formData.append('sys_custom_fields', '{}');
    formData.append('business_object', businessObjectID);

    dispatch(integrationActions.startUploadCsv());

    const successMessages = [];
    const errorMessages = [];

    async.waterfall(
      [
        // 1. Post the CSV file to parse
        (next) => {
          APIcall.post({
            data: formData,
            error(err) {
              next(err);
            },
            success(res) {
              if (res.body && res.body.id) {
                const url = endpointGenerator.genPath(
                  'espCSV.csvfile.instance.parseCSV',
                  {
                    invitesImportId: res.body.id,
                  }
                );
                successMessages.push({
                  results: res,
                  type: 'success',
                });
                next(null, url);
              } else {
                next('No response');
              }
            },
            token: true,
            url: endpointGenerator.genPath('espCSV.csvfile'),
          });
        },

        // 2. Post the BusinessObjectID into the new CSV API link
        (url, next) => {
          const formData2 = new FormData();
          formData2.append('sys_custom_fields', '{}');
          formData2.append('business_object', businessObjectID);

          APIcall.post({
            data: formData2,
            error(err) {
              next(err);
            },
            success(res) {
              dispatch(integrationActions.uploadCsvSuccess());
              successMessages.push({
                results: res,
                type: 'success',
              });
              next();
            },
            token: true,
            url: url,
          });
        },
      ],
      (error) => {
        if (error) {
          dispatch(integrationActions.uploadCsvError(error));
          errorMessages.push({
            results: error,
            type: 'error',
          });
          reject(errorMessages);
        }
        resolve(successMessages);
      }
    );
  });

integrationThunks.saveServicenowWorkflowMap =
  (categoryID, workflowMap = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const snWorkflowMap = state.getIn(['integration', 'snWorkflowMap']);

    const productID = workflowMap.default_product;
    const snowCategoryID = workflowMap.snow_category_id;
    const isMapSet = productID && snowCategoryID;
    // Look up for an existing map for the category...
    const foundWorkflowMap = snWorkflowMap.find(
      (map) => map.get('catalog_category') === categoryID
    );

    if (isMapSet) {
      if (foundWorkflowMap) {
        // if we have a product then we update it
        APIcall.patch({
          data: {
            ...workflowMap,
          },
          error() {
            // dispatch(integrationActions.uploadCsvError());
          },
          success(res) {
            dispatch(
              integrationActions.saveServicenowWorkflowMapSuccess(res.body)
            );
          },
          token: true,
          url: endpointGenerator.genPath(
            'serviceNowIntegration.defaultWorkflowMap.instance',
            {
              mapID: foundWorkflowMap.get('id'),
            }
          ),
        });
      } else {
        // otherwise we create a new relationship
        APIcall.post({
          data: {
            catalog_category: categoryID,
            ...workflowMap,
          },
          error() {
            // dispatch(integrationActions.uploadCsvError());
          },
          success(res) {
            dispatch(
              integrationActions.saveServicenowWorkflowMapSuccess(res.body)
            );
          },
          token: true,
          url: endpointGenerator.genPath(
            'serviceNowIntegration.defaultWorkflowMap'
          ),
        });
      }
    } else if (foundWorkflowMap) {
      // if mapping is empty we delete
      APIcall.delete({
        success() {
          dispatch(
            integrationActions.deleteServicenowWorkflowMapSuccess(categoryID)
          );
        },
        token: true,
        url: endpointGenerator.genPath(
          'serviceNowIntegration.defaultWorkflowMap.instance',
          {
            mapID: foundWorkflowMap.get('id'),
          }
        ),
      });
    }
  };

integrationThunks.loadServicenowWorkflowMaps = () => (dispatch) => {
  dispatch(integrationActions.loadServicenowWorkflowMapsStart());
  APIcall.get({
    error() {
      // dispatch(integrationActions.uploadCsvError());
    },
    success(res) {
      dispatch(
        integrationActions.loadServicenowWorkflowMapsSuccess(res.body.results)
      );
    },
    token: true,
    url: endpointGenerator.genPath('serviceNowIntegration.defaultWorkflowMap'),
  });
};

integrationThunks.loadHistory =
  ({ limit, offset }) =>
  (dispatch) => {
    dispatch(integrationActions.loadCsvImportHistoryStart());

    const query = {
      limit,
      offset,
      order_by: SortFieldNames.NEGATIVE_SYS_DATE_CREATED,
    };

    APIcall.get({
      data: {},
      error: () => {
        dispatch(integrationActions.loadCsvImportHistoryFailure());
      },
      query,
      success: (res) => {
        const { count, results } = res.body;
        dispatch(
          integrationActions.loadCsvImportHistorySuccess(results, count || 0)
        );
      },
      token: true,
      url: endpointGenerator.genPath('espCSV.csvfile'),
    });
  };

integrationThunks.loadHistoryInstance = (instanceId) => (dispatch) => {
  dispatch(integrationActions.loadHistoryInstanceStart(instanceId));

  APIcall.get({
    error: () => {
      dispatch(integrationActions.loadHistoryInstanceFail(instanceId));
    },
    success: (res) => {
      dispatch(
        integrationActions.loadHistoryInstanceSuccess(instanceId, res.body)
      );
    },
    token: true,
    url: endpointGenerator.genPath('espCSV.csvfile.csvId', {
      csvId: instanceId,
    }),
  });
};

integrationThunks.loadGenericTable =
  (tableName = '', keyword = '', field = '', refQualCondition = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!tableName) {
        reject(new Error('tableName must be provided'));
        return;
      }

      const data = {
        table_name: tableName,
      };

      if (keyword) {
        data.keyword = keyword;
      }

      let endpoint;
      if (field) {
        data.field = field;
        endpoint = endpointGenerator.genPath(
          'serviceNowIntegration.custom.searchForChoices'
        );
      } else {
        endpoint = endpointGenerator.genPath(
          'serviceNowIntegration.custom.search'
        );
      }

      if (refQualCondition) {
        data.encoded_query = refQualCondition;
      }

      dispatch(integrationActions.loadGenericTableStart(tableName, field));
      APIcall.post({
        data: data,
        error(e) {
          dispatch(integrationActions.loadGenericTableFail(tableName, field));
          reject(e);
        },
        success({ body }) {
          const results = body.records;
          const displayValue = body.display;
          const realValue = body.real_value;

          dispatch(
            integrationActions.loadGenericTableSuccess(
              tableName,
              field,
              results,
              displayValue,
              realValue
            )
          );
          resolve();
        },
        token: true,
        url: endpoint,
      });
    });

integrationThunks.loadSnowCategories =
  (keyword = '', sys_id = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const data = {
        table_name: SERVICENOW_CATEGORY_TABLE,
      };

      if (keyword) {
        data.keyword = keyword;
      } else if (sys_id) {
        data.encoded_query = `sys_id=${sys_id}`;
      }

      dispatch(integrationActions.loadSnowCategoriesStart());
      APIcall.post({
        data: data,
        error(e) {
          // dispatch(integrationActions.uploadCsvError());
          reject(e);
        },
        success({ body: { records } }) {
          dispatch(integrationActions.loadSnowCategoriesSuccess(records));
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath('serviceNowIntegration.custom.search'),
      });
    });

integrationThunks.postSnowCredentials = () => (dispatch, getState) => {
  const state = getState();

  const fomValues = state.getIn(['form', 'SnowConnection', 'values']);
  // const isPasswordTocuhed = state.getIn(['form', 'SnowConnection', 'fields', 'password', 'touched']);

  const data = {
    url: fomValues.get('url'),
    username: fomValues.get('username'),
  };

  // Send password only if it changed
  if (
    fomValues.get('password') !==
    state.getIn(['integration', 'snowCredentials', 'password'])
  ) {
    data.password = fomValues.get('password');
  }

  dispatch(integrationActions.loadSnowCredentialsStart());
  APIcall.post({
    data: data,
    error(error) {
      dispatch(integrationActions.loadSnowCredentialsFail(error.message));
    },
    success(res) {
      const results = res.body;
      dispatch(integrationActions.loadSnowCredentialsSuccess(results));
    },
    token: true,
    url: endpointGenerator.genPath(
      'espIntegration.integrations.snowIntegration.setCredential'
    ),
  });
};

integrationThunks.getSnowCredentials = () => (dispatch) => {
  dispatch(integrationActions.loadSnowCredentialsStart());
  APIcall.get({
    error(error) {
      dispatch(integrationActions.loadSnowCredentialsFail(error.message));
    },
    success(res) {
      const results = res.body;
      dispatch(integrationActions.loadSnowCredentialsSuccess(results));
    },
    token: true,
    url: endpointGenerator.genPath(
      'espIntegration.integrations.snowIntegration.setCredential'
    ),
  });
};

integrationThunks.getSnowConnectionStatus = () => (dispatch) => {
  dispatch(integrationActions.loadSnowConnectionStatusStart());
  APIcall.post({
    error(error) {
      dispatch(integrationActions.loadSnowConnectionStatusFail(error.message));
    },
    success(res) {
      const results = res.body;
      dispatch(integrationActions.loadSnowConnectionStatusSuccess(results));
    },
    token: true,
    url: endpointGenerator.genPath(
      'espIntegration.integrations.snowIntegration.status'
    ),
  });
};

//
// loads CVS schedules
//
integrationThunks.getCSVSchedule =
  (cb = noop) =>
  (dispatch) => {
    dispatch(integrationActions.setCVSSchedulesStart());
    APIcall.get({
      error(err) {
        dispatch(integrationActions.setCVSSchedulesError(err));
      },
      success({ body: { results } }) {
        dispatch(integrationActions.setCVSSchedulesSuccess(results));
        cb(results);
      },
      token: true,
      url: endpointGenerator.genPath('espCSV.remote'),
    });
  };

//
// updates CSV schedule
//
integrationThunks.updateCSVSchedule = (formValues) => (dispatch) =>
  new Promise((resolve, reject) => {
    const { id, import_frequency, ...scheduleData } = formValues;
    const url = endpointGenerator.genPath('espCSV.remote.instance', {
      scheduleID: id,
    });
    const data = parseScheduleData(
      Object.assign(scheduleData, first(Object.values(import_frequency)))
    );

    dispatch(integrationActions.setCVSSchedulesStart());
    APIcall.patch({
      data,
      error(err) {
        dispatch(integrationActions.setCVSSchedulesError(err));
        reject(err);
      },
      success({ body }) {
        dispatch(integrationActions.updateCVSSchedulesSuccess(body));
        resolve(body);
      },
      token: true,
      url: url,
    });
  });

//
// Test schedule connection
//
integrationThunks.testCSVSchedule = (scheduleID, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCSV.remote.test_connection');
    dispatch(integrationActions.setTestCVSSchedulesStart());
    APIcall.post({
      data: parseScheduleData(data),
      error() {
        dispatch(integrationActions.setTestCVSSchedulesError());
        reject();
      },
      preventShowError: true,
      // we don't show api errors for this case, we just set the connection as invalid
      success() {
        dispatch(integrationActions.setTestCVSSchedulesSuccess());
        resolve();
      },

      token: true,

      url: url,
    });
  });

integrationThunks.newTestCSVSchedule = (scheduleID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath(
      'espCSV.remote.instance.testConnection',
      {
        scheduleID,
      }
    );
    dispatch(integrationActions.setTestCVSSchedulesStart());

    // simulating success request
    // setTimeout(() => {
    //   dispatch(integrationActions.setTestCVSSchedulesSuccess());
    //   resolve();
    // },8000);
    // simulating failed request
    // setTimeout(() => {
    //   dispatch(integrationActions.setTestCVSSchedulesError());
    //   reject();
    // },8000);

    APIcall.post({
      data: void 0,
      error() {
        dispatch(integrationActions.setTestCVSSchedulesError());
        reject();
      },
      preventShowError: true,
      // we don't show api errors for this case, we just set the connection as invalid
      success() {
        dispatch(integrationActions.setTestCVSSchedulesSuccess());
        resolve();
      },

      token: true,

      url: url,
    });
  });

//
// create new schedule
//
integrationThunks.createCSVSchedule = (formValues) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const newSchedules = [];
    const url = endpointGenerator.genPath('espCSV.remote');
    const { import_frequency, ...scheduleData } = formValues;

    dispatch(integrationActions.setCVSSchedulesStart());
    async.eachSeries(
      Object.values(import_frequency),
      (importFrequency, next) => {
        const data = parseScheduleData(
          Object.assign(scheduleData, importFrequency)
        );
        APIcall.post({
          data,
          error: next,
          success({ body }) {
            newSchedules.push(body);
            next();
          },
          token: true,
          url: url,
        });
      },
      (err) => {
        if (err) {
          reject(err);
          dispatch(integrationActions.setCVSSchedulesError(err));
        } else {
          const schedules = state.getIn(['integration', 'schedules']).toArray();
          dispatch(
            integrationActions.setCVSSchedulesSuccess(
              schedules.concat(newSchedules)
            )
          );
          resolve(first(newSchedules));
        }
      }
    );
  });
integrationThunks.deleteCsvSchedule =
  (scheduleToDelete) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const scheduleId = scheduleToDelete.get('id');
      dispatch(integrationActions.deleteCsvScheduleStart(scheduleToDelete));
      const url = `${endpointGenerator.genPath('espCSV.remote')}${scheduleId}/`;
      APIcall.delete({
        error(err) {
          dispatch(
            integrationActions.deleteCsvScheduleFailure(scheduleToDelete, err)
          );
          reject(err);
        },
        success() {
          dispatch(integrationActions.deleteCsvScheduleSuccess());
          state.setIn(['integration', 'scheduleToDelete'], null);
          const schedules = state.getIn(['integration', 'schedules']).toJS();
          dispatch(
            integrationActions.setCVSSchedulesSuccess(
              schedules.filter((i) => i.id !== scheduleId)
            )
          );
          resolve();
        },
        token: true,
        url: url,
      });
    });

integrationThunks.sendInvites = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.sendInvitesStart());
    // Get the EID WF configured
    const existingEmployeeWFeid = getExistingEmployeeWFeid(getState());
    async.waterfall(
      [
        // 1. Get ID of "Existing Employee Workflow"
        (next) => {
          dispatch(workflowThunks.mapEIDToID(existingEmployeeWFeid))
            .then((workflowID) => {
              next(null, workflowID);
            })
            .catch((e) => {
              next(e);
            });
        },
        // 2. Send the notifications on that workflow
        (workflowID, next) => {
          const url = endpointGenerator.genPath(
            'workflow.workflows.instance.sendNotification',
            {
              workflowID: workflowID,
            }
          );
          APIcall.post({
            error(err) {
              if (err.response) {
                if (err.response.body && err.response.body.errors) {
                  err = err.response.body.errors[0].message;
                }
              }
              next(err);
            },
            success(res) {
              const results = res.body;
              next(null, results);
            },
            token: true,
            url: url,
          });
        },
      ],
      (error, results) => {
        if (error) {
          dispatch(integrationActions.sendInvitesFailure(error));
          reject(error);
        } else {
          dispatch(integrationActions.sendInvitesSuccess(results));
          resolve(results);
        }
      }
    );
  });

integrationThunks.getRemoteUserCreateFlag = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espConfig.remoteUserCreateFlag');
    dispatch(integrationActions.remoteUserCreateFlagStart());
    APIcall.get({
      error(err) {
        dispatch(integrationActions.remoteUserCreateFlagFail());
        reject(err);
      },
      success({ body }) {
        dispatch(integrationActions.remoteUserCreateFlagSuccess(body));
        resolve(body);
      },
      token: true,
      url,
    });
  });

integrationThunks.setRemoteUserCreateFlag = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espConfig.remoteUserCreateFlag');
    dispatch(integrationActions.remoteUserCreateFlagStart());
    APIcall.patch({
      data,
      error(err) {
        dispatch(integrationActions.remoteUserCreateFlagFail());
        reject(err);
      },
      success({ body }) {
        dispatch(integrationActions.remoteUserCreateFlagSuccess(body));
        resolve(body);
      },
      token: true,
      url,
    });
  });

integrationThunks.deleteAzureAccount = (authBackEnd) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.azureAccountDeleteStart());
    dispatch(integrationThunks.deleteAuthBackend(authBackEnd))
      .then(() => {
        dispatch(integrationActions.azureAccountDeleteSuccess());
        resolve();
      })
      .catch(() => {
        dispatch(integrationActions.azureAccountDeleteFail());
        reject();
      });
  });

integrationThunks.getAzureAccount = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const authBackends = state.getIn(['integration', 'authBackends']);
    const azureAccount = authBackends.find(
      (auth) => auth.get('auth_backend') === AuthBackends.AZURE
    );

    if (!azureAccount) {
      dispatch(integrationActions.azureAccountLoadStart());
      dispatch(integrationThunks.getAuthBackends())
        .then(() => {
          dispatch(integrationActions.azureAccountLoadSuccess());
          resolve();
        })
        .catch(() => {
          dispatch(integrationActions.azureAccountLoadFail());
          reject();
        });
    }
    resolve();
  });

integrationThunks.saveAzureAccount = (values) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const authBackends = state.getIn(['integration', 'authBackends']);
    const azureAccount = authBackends.find(
      (auth) => auth.get('auth_backend') === AuthBackends.AZURE
    );

    let methodApi = 'post';
    let url = endpointGenerator.genPath('authentication.authBackend');
    let data = {
      ...values,
    };

    if (azureAccount && azureAccount.has('url')) {
      methodApi = 'patch';
      url = azureAccount.get('url');
    } else {
      data = Object.assign(data, {
        auth_backend: AuthBackends.AZURE,
        azure_login_via_oauth: true,
        enabled: true,
        ordering:
          authBackends && authBackends.size
            ? Math.min(...authBackends.map((auth) => auth.get('ordering'))) - 1
            : 1,
      });
    }

    dispatch(integrationActions.azureAccountSaveStart());

    APIcall[methodApi]({
      data,
      error() {
        dispatch(integrationActions.azureAccountSaveFail());
        reject();
      },
      success(res) {
        const azureAuth =
          hasIn(res, 'body') && !isEmpty(res.body) ? res.body : new Map();
        dispatch(integrationActions.azureAccountSaveSuccess(azureAuth));
        resolve();
      },
      token: true,
      url,
    });
  });

integrationThunks.getOktaAccount = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.oktaAccountLoadStart());
    const filter = `auth_backend__EQ=${AuthBackends.OKTA}`;
    APIcall.get({
      error(error) {
        dispatch(integrationActions.oktaAccountLoadFail(error));
        reject(error);
      },
      query: {
        esp_filters: encodeURI(filter),
      },
      success({ body: { results } }) {
        // Doing this since API doesn't respect esp_filters yet..
        const oktaAuth =
          find(results, (r) => r.auth_backend === AuthBackends.OKTA) || {}; // For now it's assumed that only 1 okta account should exist

        dispatch(integrationActions.oktaAccountLoadSuccess(oktaAuth));
        resolve(oktaAuth);
      },
      token: true,
      url: endpointGenerator.genPath('authentication.authBackend'),
    });
  });

integrationThunks.saveOktaAccount = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const formValues = getFormValues('OktaAccountForm')(state);
    const oktaAccount = state.getIn(['integration', 'oktaAccount']);
    const apiKeyFormValue = formValues.get('api_key');

    async.waterfall(
      [
        // 1. Get authBackends to set the correct ordering
        (next) => {
          if (
            state.hasIn(['integration', 'authBackends']) &&
            state.getIn(['integration', 'authBackends']).size
          ) {
            next(null, state.getIn(['integration', 'authBackends']).toJS());
          }
          dispatch(integrationThunks.getAuthBackends())
            .then((res) => {
              next(null, res);
            })
            .catch((err) => {
              next(err);
            });
        },
        // 2. Save Okta auth backend
        (authBackends, next) => {
          let methodApi;
          let url;

          const formAttributes = {
            base_url: formValues.get('base_url'),
            enable_saml: formValues.get('enable_saml'),
          };

          if (oktaAccount && !oktaAccount.isEmpty()) {
            // Update
            methodApi = 'patch';
            url = oktaAccount.get('url');
          } else {
            // Post new
            methodApi = 'post';
            url = endpointGenerator.genPath('authentication.authBackend');

            // Send this value only when POST for first time
            formAttributes.name = 'Okta Integration'; // We don't use it but we have to set it
            formAttributes.auth_backend = AuthBackends.OKTA;
            formAttributes.ordering =
              Math.min(...authBackends.map((auth) => auth.ordering)) - 1;
          }

          // Send API key only if it changed
          if (
            apiKeyFormValue &&
            apiKeyFormValue !== oktaAccount.get('api_key')
          ) {
            formAttributes.api_key = apiKeyFormValue;
          }

          dispatch(integrationActions.oktaAccountSaveStart());
          APIcall[methodApi]({
            data: formAttributes,
            error(error) {
              dispatch(integrationActions.oktaAccountLoadFail(error));
              next(error);
            },
            success(res) {
              const oktaAuth = res.body;

              dispatch(integrationActions.oktaAccountLoadSuccess(oktaAuth));
              next(null, oktaAuth);
            },
            token: true,
            url: url,
          });
        },
      ],
      // Finally
      (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      }
    );
  });

integrationThunks.setOktaTenant = (values) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.setOktaTenantStart());

    APIcall.post({
      data: values.toJS(),
      error(error) {
        dispatch(integrationActions.setOktaTenantFail(error));
        reject(error);
      },
      preventShowError: true,
      success(res) {
        dispatch(integrationActions.setOktaTenantSuccess(res.body));
        resolve(res.body);
      },
      token: true,
      url: endpointGenerator.genPath('authentication.oktaTenant'),
    });
  });

integrationThunks.loadOktaTenant = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.setOktaTenantStart());

    APIcall.get({
      error(error) {
        dispatch(integrationActions.setOktaTenantFail(error));
        reject(error);
      },
      success(res) {
        const { results } = res.body;
        const result = results.length > 0 ? results[results.length - 1] : {};

        dispatch(integrationActions.setOktaTenantSuccess(result));
        resolve(res.body);
      },
      token: true,
      url: endpointGenerator.genPath('authentication.oktaTenant'),
    });
  });

integrationThunks.getAuthBackends = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.getAuthBackendsStart());
    APIcall.get({
      error(error) {
        dispatch(integrationActions.getAuthBackendsFail(error));
        reject(error);
      },
      success({ body: { results } }) {
        dispatch(integrationActions.getAuthBackendsSuccess(results));
        resolve(results);
      },
      token: true,
      url: endpointGenerator.genPath('authentication.authBackend'),
    });
  });

integrationThunks.enableAuthLayer = (isEnabled, authBackEnd) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.authBackendUpdateStart());
    APIcall.patch({
      data: {
        enabled: isEnabled,
      },
      error(error) {
        dispatch(integrationActions.authBackendUpdateFailure(error));
        reject(error);
      },
      success(res) {
        const auth = res.body;
        dispatch(integrationActions.authBackendUpdateSuccess(auth.id, auth));
        resolve(auth);
      },
      token: true,
      url: authBackEnd.get('url'),
    });
  });

integrationThunks.moveAuthLayer = (direction, id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const authBackends = getState()
      .getIn(['integration', 'authBackends'])
      .sortBy((item) => item.get('ordering'));
    const authBackEndIndex = authBackends.findIndex(
      (authBackEnd) => authBackEnd.get('id') === id
    );
    const switchAuthBackEndIndex =
      direction === AuthBackendsDirection.UP
        ? authBackEndIndex - 1
        : authBackEndIndex + 1;

    const authBackEnd = authBackends.get(authBackEndIndex);
    const switchAuthBackEnd = authBackends.get(switchAuthBackEndIndex);

    let newOrdering = switchAuthBackEnd.get('ordering');
    const oldOrdering = authBackEnd.get('ordering');

    if (newOrdering === oldOrdering) {
      newOrdering =
        direction === AuthBackendsDirection.UP ? --newOrdering : ++newOrdering;
    }

    dispatch(integrationActions.authBackendUpdateStart());
    async.waterfall(
      [
        // 1. Update auth with new ordering
        (next) => {
          APIcall.patch({
            data: {
              ordering: newOrdering,
            },
            error(error) {
              dispatch(integrationActions.authBackendUpdateFailure(error));
              next(error);
            },
            success(res) {
              const auth = res.body;
              dispatch(
                integrationActions.updateOrderingValue(auth.id, newOrdering)
              );
              dispatch(
                integrationActions.authBackendUpdateSuccess(auth.id, auth)
              );
              next();
            },
            token: true,
            url: authBackEnd.get('url'),
          });
        },

        // 2. Switch auth ordering
        (next) => {
          APIcall.patch({
            data: {
              ordering: oldOrdering,
            },
            error(error) {
              dispatch(integrationActions.authBackendUpdateFailure(error));
              next(error);
            },
            success(res) {
              const auth = res.body;
              dispatch(
                integrationActions.updateOrderingValue(auth.id, oldOrdering)
              );
              dispatch(
                integrationActions.authBackendUpdateSuccess(auth.id, auth)
              );
              next();
            },
            token: true,
            url: switchAuthBackEnd.get('url'),
          });
        },
      ],
      (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }
    );
  });

integrationThunks.deleteAuthBackend = (authBackEnd) => (dispatch) =>
  new Promise((resolve, reject) => {
    const id = authBackEnd.get('id');
    dispatch(integrationActions.authBackendUpdateStart());
    APIcall.delete({
      error(error) {
        dispatch(integrationActions.authBackendUpdateFailure(error));
        reject(error);
      },
      success() {
        dispatch(integrationActions.authBackendDeleteSuccess(id));
        resolve();
      },
      token: true,
      url: authBackEnd.get('url'),
    });
  });

integrationThunks.getEmailCaseGenerator = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.addEmailCaseStart());

    APIcall.get({
      error(err) {
        dispatch(integrationActions.addEmailCaseFail());
        reject(err);
      },
      success({ body }) {
        // We only support 1 case generator on the tenant
        const { results } = body;
        dispatch(integrationActions.addEmailCaseSuccess(results[0]));
        resolve(results[0]);
      },
      token: true,
      url: endpointGenerator.genPath('emailSensor.caseGenerators'),
    });
  });

integrationThunks.getEmailCredentials = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('emails.credentials');
    dispatch(integrationActions.startGetEmailCredentials());
    APIcall.get({
      error({ response }) {
        reject(response);
      },
      preventShowError: true,
      success({ body }) {
        const credentials = body.results;
        dispatch(integrationActions.successGetEmailCredentials(credentials));
        resolve(credentials);
      },
      token: true,
      url,
    });
  });

integrationThunks.getEmailsSetting = () => (dispatch) =>
  new Promise((resolve, reject) => {
    async.waterfall(
      [
        // 1. Get the Emails generator list
        (next) => {
          dispatch(integrationThunks.getEmailCaseGenerator())
            .then(() => {
              next();
            })
            .catch((err) => {
              next(err);
            });
        },

        // 2. Get the email credential list
        (next) => {
          dispatch(integrationThunks.getEmailCredentials())
            .then((data) => {
              next(null, data);
            })
            .catch((err) => {
              next(err);
            });
        },
      ],
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });

integrationThunks.deleteEmailAsCaseAccount = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const caseGeneratorID = getState().getIn([
      'integration',
      'emailCaseGenerator',
      'id',
    ]);
    APIcall.delete({
      error(err) {
        reject(err);
      },
      success() {
        dispatch(integrationActions.deleteCaseGeneratorSuccess());
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('emailSensor.caseGenerators.instance', {
        caseGeneratorID,
      }),
    });
  });

integrationThunks.deleteCaseAccount = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const caseGenerator = getEmailsSetting(state).get('caseGenerator');
    const caseGeneratorID = state.getIn([
      'integration',
      'emailCaseGenerator',
      'id',
    ]);

    dispatch(integrationActions.deleteCaseGeneratorStart());

    async.waterfall(
      [
        // 1. Delete the Credential
        (next) => {
          APIcall.delete({
            error(err) {
              next(err);
            },
            success() {
              next();
            },
            token: true,
            url: endpointGenerator.genPath('emails.credentials.instance', {
              emailID: caseGenerator.get('id'),
            }),
          });
        },

        // 2. Delete the Case generator
        (next) => {
          APIcall.delete({
            error(err) {
              next(err);
            },
            success() {
              next();
            },
            token: true,
            url: endpointGenerator.genPath(
              'emailSensor.caseGenerators.instance',
              {
                caseGeneratorID,
              }
            ),
          });
        },
      ],
      (err) => {
        if (err) {
          dispatch(integrationActions.deleteCaseGeneratorFail());
          reject(err);
        } else {
          dispatch(
            integrationActions.deleteCaseGeneratorSuccess(
              caseGenerator.get('id')
            )
          );
          resolve();
        }
      }
    );
  });

integrationThunks.addEmailCredentials = (emailCredentials) => (dispatch) =>
  new Promise((resolve, reject) => {
    const data = { ...emailCredentials };
    const url = endpointGenerator.genPath('emails.credentials');
    dispatch(integrationActions.startAddEmailCredentials());
    APIcall.post({
      data,
      error({ response }) {
        dispatch(integrationActions.errorAddEmailCredentials());
        reject(response);
      },
      preventShowError: true,
      success({ body }) {
        dispatch(integrationActions.successAddEmailCredentials(body));
        if (body.primary) {
          dispatch(inboxesThunks.fetchPrimaryCredential());
          dispatch(integrationActions.successUpdatePrimaryEmail());
          createInbox(body.id, dispatch);
        }
        resolve(body);
      },
      token: true,
      url,
    });
  });

const getInboxes = async () => {
  return await APIcall.get({
    token: true,
    url: endpointGenerator.genPath('emailSensor.caseGenerators'),
  });
};
const deleteInboxByID = async (inboxID) => {
  const url = endpointGenerator.genPath('emailSensor.caseGenerators.instance', {
    caseGeneratorID: inboxID,
  });
  return await APIcall.delete({
    token: true,
    url,
  });
};

const removeInbox = async (emailID) => {
  const { body } = await getInboxes();
  const { results } = body;
  const inbox = results.find((inboxes) => inboxes.email_account === emailID);
  if (inbox) {
    await deleteInboxByID(inbox.id);
  }
};

const createInbox = async (emailID) => {
  return await APIcall.post({
    data: {
      active: true,
      email_account: emailID,
    },
    token: true,
    url: endpointGenerator.genPath('emailSensor.caseGenerators'),
  });
};

const updateInbox = async (emailID, dispatch) => {
  const { body } = await getInboxes();
  const { results } = body;
  const inbox = results.find((inboxes) => inboxes.email_account === emailID);
  if (inbox) {
    await deleteInboxByID(inbox.id);
  }
  const newInboxResults = await createInbox(emailID);
  dispatch(integrationActions.addEmailCaseSuccess(newInboxResults.body));
};

integrationThunks.updateEmailCredentials = (emailCredentials) => (dispatch) =>
  new Promise((resolve, reject) => {
    const data = {
      ...emailCredentials,
    };

    const url = endpointGenerator.genPath('emails.credentials.emailID', {
      emailID: emailCredentials.id,
    });
    dispatch(integrationActions.startUpdateEmailCredentials());
    APIcall.patch({
      data,
      error({ response }) {
        dispatch(integrationActions.errorUpdateEmailCredentials());
        reject(response);
      },
      preventShowError: true,
      success(res) {
        const { body } = res;
        if (body.primary) {
          dispatch(inboxesThunks.fetchPrimaryCredential());
          dispatch(integrationActions.successUpdatePrimaryEmail());
          updateInbox(data.id, dispatch);
        } else {
          removeInbox(data.id);
        }
        dispatch(integrationActions.resetEmailTestConnection());
        dispatch(integrationActions.successGetEmailCredentials([body]));
        resolve(res);
      },
      token: true,
      url,
    });
  });

integrationThunks.emailTestConnection =
  (emailData, emailAccountType) => (dispatch) => {
    const data = { ...emailData };
    const url = endpointGenerator.genPath(
      'emails.credentials.instance.test_connection',
      {
        emailID: emailData.id,
      }
    );
    dispatch(integrationActions.startEmailTestConnection());
    return APIcall.post({
      data,
      preventShowError: true,
      token: true,
      url,
    })
      .then(() =>
        dispatch(
          integrationActions.successEmailTestConnection(emailAccountType)
        )
      )
      .catch(() =>
        dispatch(integrationActions.errorEmailTestConnection(emailAccountType))
      );
  };

integrationThunks.setCurrentCredential =
  (fields, currentCredentialByType) => (dispatch) => {
    let currentCredential = {};
    fields.forEach((fieldName) => {
      if (
        (fieldName === 'app_id' || fieldName === 'tenant_id') &&
        currentCredentialByType?.text_creds
      ) {
        currentCredential = {
          ...currentCredential,
          [fieldName]: currentCredentialByType?.text_creds[fieldName],
        };
      } else if (
        fieldName === 'client_secret' &&
        currentCredentialByType?.secret_creds
      ) {
        currentCredential = {
          ...currentCredential,
          [fieldName]: currentCredentialByType?.secret_creds[fieldName],
        };
      } else {
        currentCredential = {
          ...currentCredential,
          [fieldName]: currentCredentialByType[fieldName],
        };
      }
    });
    dispatch(integrationActions.setCurrentCredential(currentCredential));
  };

/**
 * @deprecated As of aug_2021 slack configurations move out from /configurations endpoint.
 * Use instead integrationThunks.getBaristaBotIntegration.
 *
 * Get Slackbot key in Configuration in order
 * to know if it's active or not
 * @returns {function(*): *}
 */
integrationThunks.getSlack = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.getSlackBotActiveStart());

    APIcall.get({
      error(err) {
        dispatch(integrationActions.getSlackBotActiveFail());
        reject(err);
      },
      query: {
        esp_filters: encodeURI('key__SW=slackbot.registration.blob'),
      },
      success(res) {
        if (res.body.results.length) {
          dispatch(integrationActions.getSlackBotActiveSuccess());
        } else {
          dispatch(integrationActions.getSlackBotNotActiveSuccess());
        }
        resolve(res);
      },
      token: true,
      url: endpointGenerator.genPath('espConfig'),
    });
  });

/**
 * Remove existing slackbot integration
 * @returns {function(*): *}
 */
integrationThunks.removeSlack = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.removeSlackbotStart());

    APIcall.post({
      error(err) {
        dispatch(integrationActions.removeSlackbotFail(err));
        reject();
      },
      success() {
        dispatch(integrationActions.removeSlackbotSuccess());
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.remove'),
    });
  });

/**
 * @typedef BaristaBotSettingsData
 * @prop {'SLACK' | 'TEAMS'} bottype
 * @prop integration_settings
 * @prop {boolean} integration_settings.display_tasks_on_app_home
 * @prop {boolean} integration_settings.display_approvals_on_app_home
 * @prop {'always' | 'mention' | 'never'} integration_settings.public_channel_response_mode
 * @prop {boolean} integration_settings.display_announcements_on_app_home
 */

/**
 * Get Barista Bot Integration Settings
 *
 * @param {string} botType
 * @return {PromiseLike<void>}
 */
integrationThunks.getBaristaBotIntegration = (botType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(integrationActions.getBaristaBotIntegrationStart());

    APIcall.get({
      error(err) {
        dispatch(integrationActions.getBaristaBotIntegrationFail(err));
        reject();
      },
      /** @type {{body: BaristaBotSettingsData}} data */
      success({ body }) {
        dispatch(
          integrationActions.getBaristaBotIntegrationSuccess(botType, body)
        );
        resolve(body);
      },

      token: true,

      url: endpointGenerator.genPath('espBaristaBot.integrations.instance', {
        botType,
      }),
    });
  });
};

/**
 * Updates Barista Bot Integration Settings
 *
 * @param {string} botType
 * @param {BaristaBotSettingsData} data
 * @return {PromiseLike<void>}
 */
integrationThunks.updateBaristaBotIntegration =
  (botType, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(integrationActions.updateBaristaBotIntegrationStart());

      APIcall.patch({
        data,
        error(err) {
          dispatch(integrationActions.updateBaristaBotIntegrationFail(err));
          reject();
        },
        success({ body }) {
          dispatch(
            integrationActions.updateBaristaBotIntegrationSuccess(botType, body)
          );
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('espBaristaBot.integrations.instance', {
          botType,
        }),
      });
    });
  };

integrationThunks.setSlack = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.setSlackStart());

    async.waterfall(
      [
        // 1. Generate Token for slack
        (next) => {
          APIcall.post({
            error(err) {
              next(err);
            },
            success() {
              next();
            },
            token: true,
            url: endpointGenerator.genPath('espBaristaBot.genToken'),
          });
        },

        // 2. Get the slack integration URL
        (next) => {
          APIcall.get({
            error(err) {
              next(err);
            },
            success({ body }) {
              next(null, body.redirect_url);
            },
            token: true,
            url: endpointGenerator.genPath('espBaristaBot.add'),
          });
        },
      ],
      (err, redirectUrl) => {
        if (err) {
          dispatch(integrationActions.setSlackFail(err));
          reject(err);
        } else {
          // It's a success, we need to redirect the current window location with the redirect_url provided
          window.location.replace(redirectUrl);
          dispatch(integrationActions.setSlackSuccess());
          resolve();
        }
      }
    );
  });

integrationThunks.getTeamsToken = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.teamsFetchTokenStart());

    APIcall.get({
      error(err) {
        reject(err);
      },
      preventShowError: true,
      success({ body }) {
        dispatch(integrationActions.teamsFetchTokenSuccess(body));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.teams.add'),
    });
  });
integrationThunks.postTeamsToken = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.teamsFetchTokenStart());

    APIcall.post({
      error(err) {
        reject(err);
      },
      success({ body }) {
        dispatch(integrationActions.teamsFetchTokenSuccess(body));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.teams.add'),
    });
  });

integrationThunks.removeTeamsToken = () => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.post({
      error(err) {
        reject(err);
      },
      success({ body }) {
        dispatch(integrationActions.removeTeamsTokenSuccess(body));
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espBaristaBot.teams.remove'),
    });
  });

integrationThunks.loadLastElcJob = () => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.get({
      error(err) {
        reject(err);
      },
      success({ body }) {
        const [job] = body.results;
        dispatch(integrationActions.loadLastElcJobSuccess(job));
        resolve(body);
      },
      token: true,
      url: `${endpointGenerator.genPath('commons.jobStatus')}?${encodeURI(
        'offset=0&limit=1&order_by=-id&only_user_jobs=false&esp_filters=api_path__IC=/elc/'
      )}`,
    });
  });

integrationThunks.runElcSync = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.runElcStart());
    APIcall.post({
      data: {
        forced: false,
      },
      error(err) {
        reject(err);
      },
      success({ body }) {
        dispatch(integrationActions.runElcSuccess(body.job));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espBarista.elc.importAllElc'),
    });
  });

integrationThunks.testSharepointSettings = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(integrationActions.testSharepointSettingsStart());
    const url = endpointGenerator.genPath(
      'espIntegration.integrations.sharepoint.testConnection'
    );
    APIcall.post({
      data,
      token: true,
      url,
    })
      .then(({ body }) => {
        dispatch(integrationActions.testSharepointSettingsSuccess(body));
        resolve(body);
      })
      .catch((error) => {
        dispatch(integrationActions.testSharepointSettingsFail());
        reject(error);
      });
  });

export default integrationThunks;
