import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import TypeAddEntries, {
  TypeDefaults,
} from '../../../globals/blocks/TypeAddEntries';
import ValidatedMultipleEntries from '../../molecules/ValidatedMultipleEntries';
import BodyClassName from '../../../../globals/BodyClassName';

class AddEntries01 extends Component {
  static propTypes = {
    ...TypeAddEntries,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
  };

  render() {
    const {
      buttonAddEntryText,
      errors,
      errorsApi,
      id,
      placeholder,
      readOnly,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    return (
      <BodyClassName className='blocks-top'>
        <div className='block' data-blocktype={type} data-template={template}>
          <ValidatedMultipleEntries
            buttonAddEntryText={buttonAddEntryText}
            formError={finalError}
            name={id}
            nameField={'AddEntries'}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        </div>
      </BodyClassName>
    );
  }
}

export default AddEntries01;
