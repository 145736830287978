import _ from 'lodash';

import BlockBase from '../../utils/BlockBase';
import { processPropDefaults, processPropTypes } from '../../utils/typesUtils';

// Block own attributes definition
const blockAttrsDefinition = _.merge({}, BlockBase, {
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputValue: {
    default: {},
    objectShape: {
      map: {
        required: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'boolean',
      },
    },
    type: 'object',
  },
  options: {
    arrayShape: {
      checked: {
        requred: true,
        type: 'bool',
      },
      id: {
        required: true,
        type: 'string',
      },
      label: {
        required: true,
        type: 'string',
      },
    },
    default: [],
    type: 'arrayOf',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'radio_1',
  },
});

// Object Definition used by blobifier
const RadioBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Radio',
  templates: ['radio_1', 'radio02'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, RadioBlockDefinition, TypeDefaults };
