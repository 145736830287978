import DateTools from '../../../../../utils/DateTools';

/**
 * @param  {{localTime: string, days: Array<string>, fromUtc: bool, toUTC: bool}} object that contains required parameters to convert week days to utc or local time week days
 */
const convertWeekdays = ({
  localTime,
  days = [],
  fromUtc = false,
  toUTC = false,
}) => {
  const [hour] = localTime?.split(':');

  const currentDate = new Date();
  const isNextDay = () =>
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      Number(hour)
    ).getUTCDate() > new Date().getDate();

  const isPreviousDay = () =>
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      Number(hour)
    ).getUTCDate() < new Date().getDate();

  const dayFormat = 'ddd';

  const decreaseDays = () =>
    days.map((day) =>
      DateTools.subtractDays(day, 1, 'days', dayFormat).toLowerCase()
    );
  const increaseDays = () =>
    days.map((day) =>
      DateTools.sumDays(day, 1, 'days', dayFormat).toLowerCase()
    );

  if (isNextDay()) {
    if (fromUtc) {
      return decreaseDays();
    }
    if (toUTC) {
      return increaseDays();
    }
  } else {
    if (isPreviousDay()) {
      if (fromUtc) {
        return increaseDays();
      }
      if (toUTC) {
        return decreaseDays();
      }
    }
  }

  return days;
};

export default convertWeekdays;
