import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OffboardingScheduleContext from './OffboardingScheduleContext';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

/**
 * will return the state for view settings
 * @param {JSX.Element} children - child to be wrapped with the view settings context
 * @returns {JSX.Element} component that allows consuming view settings state
 */
const OffboardingScheduleState = ({ children }) => {
  const [isScheduleUpdated, setIsScheduleUpdated] = useState(false);

  return (
    <OffboardingScheduleContext.Provider
      value={{
        isScheduleUpdated,
        setIsScheduleUpdated,
      }}
    >
      {children}
    </OffboardingScheduleContext.Provider>
  );
};

OffboardingScheduleState.propTypes = propTypes;

export default OffboardingScheduleState;
