// Utils
import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import onboardActivityThunks from '../../actions/onboardActivityThunks';
// Selector
import getScheduleGroupOrdered from '../../selectors/getScheduleGroupOrdered';
import getTemplateIntervalSelected from '../../selectors/getTemplateIntervalSelected';
import getActiveActivityTemplates from '../../selectors/getActiveActivityTemplates';

const mapStateToProps = (state, ownProps) => {
  const isLoading = state.getIn(['onboardActivity', 'schedule', 'isLoading']);
  const isLoadingTemplate = state.getIn([
    'onboardActivity',
    'template',
    'isLoading',
  ]);
  const nextTemplate = state.getIn(['onboardActivity', 'template', 'next']);
  const { scheduleID } = ownProps.params;
  const { action } = ownProps.params;
  const schedule = getScheduleGroupOrdered(state, scheduleID);

  return {
    action,
    isLoading,
    isLoadingScheduleList: state.getIn([
      'onboardActivity',
      'template',
      'isLoading',
    ]),
    isLoadingTemplate,
    nextTemplate,
    schedule,
    scheduleID,
    templateList: getActiveActivityTemplates(state),
    templateSelected: getTemplateIntervalSelected(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadOneScheduleActivity(scheduleID) {
    dispatch(onboardActivityThunks.loadOneScheduleActivity(scheduleID)).then(
      () => {
        dispatch(onboardActivityThunks.loadTemplatesList());
      }
    );
  },

  loadTemplateActivityList() {
    dispatch(onboardActivityThunks.loadTemplatesList());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
