import React, { PureComponent } from 'react';

// Controller
import CaseAddSubscribersCtrl from '../controllers/CaseAddSubscribersCtrl';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
// v2
import UserPicker from '../../../v2/components/functional/UserPicker';

class CaseAddSubscribers extends PureComponent {
  static propTypes = {
    caseTaskId: PropTypes.number,
    defaultSubscribers: ImmutablePropTypes.set,
    isModalOpen: PropTypes.bool,
    loadDefaultSubscribers: PropTypes.func.isRequired,
    loadServiceTeam: PropTypes.func.isRequired,
    loadSubscriptionScope: PropTypes.func.isRequired,
    onCloseSubscribers: PropTypes.func.isRequired,
    saveSubscriber: PropTypes.func.isRequired,
    saveSubscriberLocation: PropTypes.func.isRequired,
    serviceTeam: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tmpSelectedLocationId: PropTypes.number,
    tmpSelectedLocationName: PropTypes.string,
  };

  static defaultProps = {
    caseTaskId: null,
    defaultSubscribers: Immutable.Set(),
    isModalOpen: false,
    serviceTeam: null,
    tmpSelectedLocationId: null,
    tmpSelectedLocationName: '',
  };

  componentDidUpdate(prevProps) {
    const {
      loadSubscriptionScope,
      loadDefaultSubscribers,
      caseTaskId,
      isModalOpen,
      loadServiceTeam,
      serviceTeam,
    } = this.props;

    // Only do this when the modal opens
    if (!prevProps.isModalOpen && isModalOpen && !serviceTeam && caseTaskId) {
      loadServiceTeam().then(() => {
        loadDefaultSubscribers(caseTaskId);
        loadSubscriptionScope(caseTaskId);
      });
    } else {
      if (!prevProps.isModalOpen && isModalOpen && serviceTeam && caseTaskId) {
        loadDefaultSubscribers(caseTaskId);
        loadSubscriptionScope(caseTaskId);
      }
    }
  }

  handleSubscriberSelection = (selectedUser, name) => {
    const {
      caseTaskId,
      saveSubscriberLocation,
      saveSubscriber,
      tmpSelectedLocationId,
      tmpSelectedLocationName,
    } = this.props;

    if (tmpSelectedLocationId) {
      // Save location only if set
      saveSubscriberLocation(
        tmpSelectedLocationId,
        caseTaskId,
        tmpSelectedLocationName
      );
    }
    if (selectedUser) {
      // Save selectedUser only if set
      saveSubscriber(selectedUser, caseTaskId, name);
    }
  };

  render() {
    const {
      onCloseSubscribers,
      isModalOpen,
      defaultSubscribers,
      caseTaskId,
      tmpSelectedLocationId,
      serviceTeam,
    } = this.props;

    return isModalOpen && serviceTeam ? (
      <UserPicker
        caseTaskId={caseTaskId}
        defaultSelectedUsers={defaultSubscribers}
        header={`${intl.formatMessage({
          id: 'label.add_another_employee_requester',
        })}:`}
        isCaseSearch
        isSearchable
        onClose={onCloseSubscribers}
        onUserSelect={this.handleSubscriberSelection}
        open={isModalOpen}
        pagination={'1'}
        selectButtonEnabled={
          tmpSelectedLocationId ? Boolean(tmpSelectedLocationId) : void 0
        }
        subscriberType
      />
    ) : null;
  }
}

export default CaseAddSubscribersCtrl(CaseAddSubscribers);
