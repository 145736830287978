import pt from 'prop-types';

export const FILTER_TYPE_ICONS = {
  'begins-with': '^',
  contains: 'C',
  'ends-with': '$',
  equals: '=',
  'not-equal': '≠',
};

// https://espressive.atlassian.net/wiki/spaces/EN/pages/250511469/Filtering#Conditions
export const FILTER_CONDITIONS = {
  and: '&',
  not: '__!EQ',
  or: '&OR',
};

export const CONDITION_REGEX = new RegExp(
  // We are sorting alphabetically and then reversing since these matches are being run in order
  `(${Object.values(FILTER_CONDITIONS).sort().reverse().join('|')})`,
  'g'
);

// https://espressive.atlassian.net/wiki/spaces/EN/pages/250511469/Filtering#Operators
export const FILTER_OPERATORS = {
  boolean: 'IBOOL',
  contains: 'C',
  containsInsensitive: 'IC',
  containsInteger: 'INT',
  endsWith: 'EW',
  endsWithInsensitive: 'IEW',
  equalTo: 'EQ',
  equalToInsensitive: 'IEQ',
  greaterThan: 'GT',
  in: 'IN',
  integerContains: 'INTC',
  lessThan: 'LT',
  notEqualTo: '!EQ',
  notGreaterThan: '!GT',
  notIn: '!IN',
  notLessThan: '!LT',
  notNull: '!ISNULL',
  null: 'ISNULL',
  startsWith: 'SW',
  startsWithInsensitive: 'ISW',
};

export const OPERATOR_REGEX = new RegExp(
  // We are sorting alphabetically and then reversing since these matches are being run in order
  Object.values(FILTER_OPERATORS).sort().reverse().join('|'),
  'g'
);

export const FILTER_STATE_SHAPE = pt.exact({
  basicSearchParams: pt.array,
  isBasicCompatible: pt.bool,
  isEmptyFilter: pt.bool,
  removeOperator: pt.func,
  setBasicValue: pt.func,
  setOperatorValue: pt.func,
  setType: pt.func,
  value: pt.array,
});

// This util is available in Cascara and we should switch to it when this comes back
export const popperOverTrigger = {
  name: 'offset',
  options: {
    offset: ({ placement, reference, popper }) => {
      if (['bottom', 'bottom-end', 'bottom-start'].includes(placement)) {
        return [0, -reference.height];
      } else {
        return [];
      }
    },
  },
};

export const popperSameWidth = {
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
  enabled: true,
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  name: 'popperSameWidth',
  phase: 'beforeWrite',
  requires: ['computeStyles'],
};
