const avatarDefaults = {
  '0': '/images/avatars/system_avatar_01.svg',
  '1': '/images/avatars/system_avatar_02.svg',
  '2': '/images/avatars/system_avatar_03.svg',
  '3': '/images/avatars/system_avatar_04.svg',
  '4': '/images/avatars/system_avatar_05.svg',
  '5': '/images/avatars/system_avatar_06.svg',
};

export default avatarDefaults;
