import React, { useCallback, useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import {
  Accordion,
  Form,
  Header,
  Input,
  Message,
  TextArea,
} from 'semantic-ui-react';
import ScheduleOffboardingGroupSegment from './ScheduleOffboardingGroupSegment';
import ScheduleActivityAdministrativeRecipientsForm from './ScheduleActivityAdministrativeRecipientsForm';
import OffboardingScheduleFormButtons from './OffboardingScheduleFormButtons';
import OffboardingActivityScheduleGroup from './OffboardingActivityScheduleGroup';
import useScheduleAccordion from '../hooks/useScheduleAccordion';
import ScheduleState from '../../../../../globals/ScheduleState';
import { Formik } from 'formik';
import offboardingScheduleContext from './Context/OffboardingScheduleContext';

import { isEmpty } from 'lodash';
import useOffboardingSchedule from '../hooks/useOffboardingSchedule';

const propTypes = {
  isPristine: pt.bool,
  params: pt.shape({
    action: pt.string,
    scheduleID: pt.string,
  }),
  schedule: pt.shape({
    administrativeRecipients: pt.shape({
      enablement: pt.oneOfType([pt.number, pt.string]),
      hr_contact: pt.oneOfType([pt.number, pt.string]),
      hr_recruiter: pt.oneOfType([pt.number, pt.string]),
      it_contact: pt.oneOfType([pt.number, pt.string]),
      mentor: pt.oneOfType([pt.number, pt.string]),
      operations: pt.oneOfType([pt.number, pt.string]),
    }),
    description: pt.description,
    groups: pt.arrayOf(pt.shape({})),
    name: pt.string,
    status: pt.string,
  }),
  selectedTemplates: pt.arrayOf(pt.shape({})),
};

const ScheduleActivityForm = ({
  params,
  isPristine,
  schedule,
  selectedTemplates,
}) => {
  const {
    createNewGroup,
    getScheduleToTemplateByID,
    deleteScheduleToTemplate,
  } = useOffboardingSchedule();
  const { setIsScheduleUpdated } = useContext(offboardingScheduleContext);
  const [activeIndex, setActiveIndex] = useState();
  const [scheduleState, setScheduleState] = useState({});
  const [initialValuesForm, setInitialValuesForm] = useState({
    administrativeRecipients: {
      enablement: '',
      hr_contact: '',
      hr_recruiter: '',
      it_contact: '',
      mentor: '',
      operations: '',
    },
    departingEmployeeRecipients: {
      groups: {},
    },
    schedule_activity_description: '',
    schedule_activity_name: '',
  });

  const [groups, setGroups] = useState([]);
  const { action, scheduleID } = params;

  const allowRepublish = !isPristine;
  const disableButtons = false; // errors from form

  const displayDraft =
    scheduleID === 'new' ||
    action === 'copy' ||
    schedule?.status !== ScheduleState.STATUS_PUBLISHED;

  const isLoading = false;

  const {
    ActivitiesIsActive,
    administrativeRecipientsIsActive,
    departingEmployeeRecipientsIsActive,
    handleAccordionClick,
  } = useScheduleAccordion(activeIndex, setActiveIndex);

  useEffect(() => {
    if (isEmpty(scheduleState) && !isEmpty(schedule)) {
      setScheduleState(schedule);
      const groupsObject = {};
      if (schedule.groups.length > 0) {
        schedule.groups.forEach((group, index) => {
          groupsObject[`group[${index}]`] = { ...group };
        });
      }
      setInitialValuesForm({
        ...initialValuesForm,
        administrativeRecipients: {
          enablement: schedule?.administrativeRecipients?.enablement || '',
          hr_contact: schedule?.administrativeRecipients?.hr_contact || '',
          hr_recruiter: schedule?.administrativeRecipients?.hr_recruiter || '',
          it_contact: schedule?.administrativeRecipients?.it_contact || '',
          mentor: schedule?.administrativeRecipients?.mentor || '',
          operations: schedule?.administrativeRecipients?.operations || '',
        },
        departingEmployeeRecipients: {
          groups: groupsObject,
        },
        schedule_activity_description: schedule?.description,
        schedule_activity_name: schedule?.name,
      });
    }
    if (
      isEmpty(schedule) &&
      scheduleID === 'new' &&
      isEmpty(initialValuesForm?.departingEmployeeRecipients?.groups) &&
      !isEmpty(groups)
    ) {
      const groupsObject = {};
      if (groups.length > 0) {
        groups.forEach((group, index) => {
          groupsObject[`group[${index}]`] = { ...group };
        });
      }
      setInitialValuesForm({
        ...initialValuesForm,
        departingEmployeeRecipients: {
          groups: groupsObject,
        },
      });
    }
  }, [groups, initialValuesForm, schedule, scheduleID, scheduleState]);

  useEffect(() => {
    if (isEmpty(groups) && schedule?.groups) {
      setGroups(schedule?.groups);
    }
  }, [groups, initialValuesForm, schedule, schedule?.groups, scheduleID]);
  useEffect(() => {
    if (scheduleID === 'new' && isEmpty(groups)) {
      createNewGroup().then((newGroup) => {
        setGroups([
          { ...newGroup, departments: [], job_roles: [], locations: [] },
        ]);
      });
    }
  }, [createNewGroup, groups, scheduleID]);

  //-------- Callbacks ----------

  const onHandleDeleteTemplateInterval = useCallback(
    ({ intervalID, msg, scheduleID, templateID }) => {
      getScheduleToTemplateByID(scheduleID).then((activitiesInSchedule) => {
        const activityToDelete = activitiesInSchedule.filter(
          (activity) =>
            activity.template === templateID && activity.interval === intervalID
        );
        const activityToDeleteURL = activityToDelete[0]?.url;
        deleteScheduleToTemplate(activityToDeleteURL, msg).then(() => {
          setIsScheduleUpdated(true);
        });
      });
    },
    [deleteScheduleToTemplate, getScheduleToTemplateByID, setIsScheduleUpdated]
  );
  const onSubmitForm = useCallback((values, actions) => {}, []);
  // ----------------------------
  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesForm}
      onSubmit={onSubmitForm}
    >
      {(formikProps) => {
        return (
          <Form as={'div'}>
            <OffboardingScheduleFormButtons
              action={action}
              allowRepublish={allowRepublish}
              disableButtons={disableButtons}
              emptyTemplate={isEmpty(selectedTemplates)}
              formValues={formikProps.values}
              saveSchedule={onSubmitForm}
              schedule={schedule}
              scheduleID={scheduleID}
              status={schedule?.status}
            />

            <Form.Field>
              <label>{'Schedule Name'}</label>
              <Input
                name='schedule_activity_name'
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                required
                value={formikProps.values.schedule_activity_name}
              />
            </Form.Field>
            <Form.Field>
              <label>{'Schedule Description'}</label>
              <TextArea
                name='schedule_activity_description'
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                value={formikProps.values.schedule_activity_description}
              />
            </Form.Field>
            <Accordion fluid>
              <Accordion.Title
                active={departingEmployeeRecipientsIsActive}
                index={0}
                onClick={handleAccordionClick}
              >
                <Header
                  as='h4'
                  content={'Departing Employee Recipients'}
                  dividing
                  icon='dropdown'
                />
              </Accordion.Title>
              <Accordion.Content active={departingEmployeeRecipientsIsActive}>
                <ScheduleOffboardingGroupSegment
                  addNewGroup={setGroups}
                  groups={groups}
                  readOnly={
                    !displayDraft ||
                    schedule?.status === ScheduleState.STATUS_PUBLISHED
                  }
                  schedule={schedule}
                  scheduleID={scheduleID}
                  setFieldValue={formikProps.setFieldValue}
                  values={formikProps.values}
                />
              </Accordion.Content>

              <Accordion.Title
                active={administrativeRecipientsIsActive}
                index={1}
                onClick={handleAccordionClick}
              >
                <Header
                  as='h4'
                  content={'Administrative Recipients'}
                  dividing
                  icon='dropdown'
                />
              </Accordion.Title>
              <Accordion.Content
                active={administrativeRecipientsIsActive}
                style={{ padding: '1em' }}
              >
                <ScheduleActivityAdministrativeRecipientsForm
                  disabled={scheduleID === 'new'}
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.setFieldValue}
                  values={formikProps.values?.administrativeRecipients}
                />
              </Accordion.Content>

              <Accordion.Title
                active={ActivitiesIsActive}
                index={2}
                onClick={handleAccordionClick}
              >
                <Header
                  as='h4'
                  content={'Activities'}
                  dividing
                  icon='dropdown'
                />
              </Accordion.Title>
              <Accordion.Content
                active={ActivitiesIsActive}
                style={{ padding: '1em' }}
              >
                {(selectedTemplates.length > 0 &&
                  !isEmpty(selectedTemplates)) ||
                isLoading ? (
                  selectedTemplates?.map((group) => {
                    return (
                      <OffboardingActivityScheduleGroup
                        action={action}
                        activities={group?.activities}
                        deleteOneTemplate={onHandleDeleteTemplateInterval}
                        key={group?.id}
                        readOnly={
                          !displayDraft ||
                          schedule?.status === ScheduleState.STATUS_PUBLISHED
                        }
                        scheduleID={scheduleID}
                        scheduleStatus={schedule.status}
                        title={group?.title}
                      />
                    );
                  })
                ) : scheduleID === 'new' ? (
                  <Message
                    content='Please save this schedule as draft first in order to add offboarding activity templates '
                    info
                  />
                ) : (
                  <Message
                    content='This schedule does not have any offboarding activity templates added yet. Please click the + button on the right to add one.'
                    info
                  />
                )}
              </Accordion.Content>
            </Accordion>
          </Form>
        );
      }}
    </Formik>
  );
};

ScheduleActivityForm.propTypes = propTypes;

export default ScheduleActivityForm;
