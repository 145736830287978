import { Checkbox } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import EspListItem from '../molecules/EspListItem';

class SelectMyGearProductItem extends Component {
  static propTypes = {
    onToggleSelect: PropTypes.func,

    step: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    onToggleSelect: null,
  };

  handleToggleSelect = () => {
    const { step, onToggleSelect } = this.props;

    if (!onToggleSelect) {
      return;
    }

    const product = step.get('entity');
    const productID = product.get('id');

    // toggle isSelected value
    const isSelected = !step.get('isSelected');

    onToggleSelect(productID, isSelected);
  };

  render() {
    const { step, onToggleSelect } = this.props;

    const product = step.get('entity');

    let checkbox;

    if (onToggleSelect) {
      checkbox = <Checkbox checked={step.get('isSelected')} />;
    }

    return (
      <EspListItem
        description={product.get('short_description')}
        image={product.getIn(['images', 0, 'thumbnail'])}
        name={product.get('name')}
        onClick={this.handleToggleSelect}
        selectable={checkbox}
      />
    );
  }
}

export default SelectMyGearProductItem;
