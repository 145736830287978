import React, { PureComponent } from 'react';
import { createComponent, ThemeProvider } from 'react-fela';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import // Button,
'semantic-ui-react';
import { stripUnit } from 'polished';

import { Boundaries } from 'cascara-middleware';
import ChatMessageAvatar from './ChatMessageAvatar';
import ChatMessageText from './ChatMessageText';
import ChatMessageAttachment from './ChatMessageAttachment';
import ChatMessageInput from './ChatMessageInput';
import ChatInputTypes from './ChatInputTypes';
import ChatMessageCheckbox from './ChatMessageCheckbox';
import ChannelTypes from '../../../../v1/globals/ChannelTypes';

// NOTE: These values are included in the component theme wrapper below
const ChatMessageTheme = {
  bubblePadding: '.75em',
  dashedBorderStyle: 'dashed',
  dashedBorderWidth: '2px',
  messageRadius: '.875em',
  minHeight: '44px',
};

const rule = (props) => {
  const { isFirstMessage, isLastMessage, theme } = props;

  const messagePadding = `${stripUnit(theme.globals.padding) * 0.25}em`;

  return {
    display: 'flex',
    paddingBottom: isLastMessage ? messagePadding : 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: isFirstMessage ? messagePadding : 0,
    width: '100%',
  };
};

class ChatMessage extends PureComponent {
  static propTypes = {
    attachment: PropTypes.shape({
      height: PropTypes.number,
      size: PropTypes.number,
      url: PropTypes.string,
      width: PropTypes.number,
    }),
    attachmentDimension: PropTypes.arrayOf(PropTypes.number),
    attachmentSize: PropTypes.number,
    avatarUrl: PropTypes.string,

    channelId: PropTypes.string,
    channelType: PropTypes.oneOf([
      ChannelTypes.DIRECT_CHANNEL,
      ChannelTypes.SCOPED_CHANNEL,
      ChannelTypes.SUPPORT_CHANNEL,
    ]),
    checked: PropTypes.bool,
    className: PropTypes.string.isRequired,
    dateTimeFormat: PropTypes.shape({}),
    hideAvatar: PropTypes.bool,
    input: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      options: PropTypes.array,

      type: PropTypes.string,

      value: PropTypes.string,
    }),
    inputResponse: PropTypes.string,
    isBarista: PropTypes.bool,
    isCaseMgmt: PropTypes.bool, // passed directly. If passed then it's assumed this chat belongs to a case
    isExpandable: PropTypes.bool, // Ability to disable expandable chat bubbles
    isFirstMessage: PropTypes.bool, // The first message in series from the same user
    isLastMessage: PropTypes.bool, // The last message in series from the same user
    isMe: PropTypes.bool,
    isScoped: PropTypes.bool,
    messageText: PropTypes.string, // The message text string
    onCheckboxClick: PropTypes.func,
    onDownload: PropTypes.func,
    onImageLoaded: PropTypes.func,
    paramValues: ImmutablePropTypes.map,
    showTimeStamp: PropTypes.bool,
    timeStamp: PropTypes.string,
    typing: PropTypes.bool,
    userId: PropTypes.number,
  };

  static defaultProps = {
    attachment: void 0,
    attachmentDimension: void 0,
    attachmentSize: 0,
    avatarUrl: void 0,
    channelId: '',
    checked: false,
    hideAvatar: false,
    input: void 0,
    inputResponse: void 0,
    isBarista: void 0,
    isCaseMgmt: false,
    isExpandable: true,
    isFirstMessage: false,
    isLastMessage: false,
    isMe: false,
    isScoped: false,
    messageText: '',
    onCheckboxClick: void 0,
    onDownload: void 0,
    onImageLoaded: void 0,
    paramValues: void 0,
    showTimeStamp: false,
    timeStamp: void 0,
    typing: false,
    userId: void 0,
  };

  state = {};

  render() {
    const {
      attachment,
      avatarUrl,
      isCaseMgmt,
      channelId,
      channelType,
      checked,
      className,
      dateTimeFormat,
      hideAvatar,
      input,
      inputResponse,
      isBarista,
      isExpandable,
      isFirstMessage,
      isLastMessage,
      isMe,
      isScoped,
      messageText,
      onCheckboxClick,
      onDownload,
      onImageLoaded,
      paramValues,
      showTimeStamp,
      timeStamp,
      typing,
      userId,
    } = this.props;

    const placement = {
      isFirstMessage,
      isLastMessage,
      isMe,
    };

    const isInputEmpty = input ? input.value === null : false;
    const isInputTypeButton = input
      ? input.type === ChatInputTypes.BUTTON
      : false;

    const avatarWithProps = (
      <ChatMessageAvatar
        avatarUrl={avatarUrl}
        empty={
          !isFirstMessage ||
          hideAvatar ||
          isInputEmpty ||
          (input && !isInputTypeButton)
        }
        isBarista={isBarista}
        userId={userId}
      />
    );

    return (
      <ThemeProvider theme={ChatMessageTheme}>
        <Boundaries>
          <div
            className={className}
            data-component='ChatMessage'
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- used for accesibility in screen readers
            tabIndex='0'
          >
            {onCheckboxClick ? (
              <ChatMessageCheckbox
                checked={checked}
                onCheckboxClick={onCheckboxClick}
              />
            ) : null}
            {!isMe || (isInputEmpty && isInputTypeButton)
              ? avatarWithProps
              : null}
            {typing || (messageText && !input) ? (
              <ChatMessageText
                {...placement}
                channelId={channelId}
                channelType={channelType}
                dateTimeFormat={dateTimeFormat}
                hideAvatar={hideAvatar}
                isBarista={isBarista}
                isExpandable={isExpandable}
                isScoped={isScoped}
                messageText={messageText}
                paramValues={paramValues}
                showTimeStamp={showTimeStamp}
                timeStamp={timeStamp}
                typing={typing}
                userId={userId}
              />
            ) : null}
            {attachment ? (
              <ChatMessageAttachment
                {...placement}
                attachment={attachment}
                hideAvatar={hideAvatar}
                isSelectable={Boolean(onCheckboxClick)}
                onDownload={onDownload}
                onImageLoaded={onImageLoaded}
                showTimeStamp={showTimeStamp}
                timeStamp={timeStamp}
              />
            ) : null}
            {input ? (
              input.value && isInputTypeButton ? (
                <ChatMessageText
                  {...placement}
                  hideAvatar={hideAvatar}
                  isBarista={isBarista}
                  isScoped={isScoped}
                  messageText={
                    input.options.find((option) => option.value === input.value)
                      .label
                  }
                  paramValues={paramValues}
                  showTimeStamp={showTimeStamp}
                  timeStamp={timeStamp}
                  userId={userId}
                />
              ) : (
                <ChatMessageInput
                  {...placement}
                  channelId={channelId}
                  hideAvatar={hideAvatar}
                  input={input}
                  inputResponse={inputResponse}
                  isCaseMgmt={isCaseMgmt}
                  messageText={messageText}
                  showTimeStamp={showTimeStamp}
                  timeStamp={timeStamp}
                />
              )
            ) : null}
            {isMe && (input ? !isInputEmpty && isInputTypeButton : true)
              ? avatarWithProps
              : null}
          </div>
        </Boundaries>
      </ThemeProvider>
    );
  }
}

export default createComponent(rule, ChatMessage, [
  'attachment',
  'avatarUrl',
  'channelId',
  'channelType',
  'checked',
  'dateTimeFormat',
  'hideAvatar',
  'input',
  'inputResponse',
  'isBarista',
  'isCaseMgmt',
  'isExpandable',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'isScoped',
  'messageText',
  'onCheckboxClick',
  'onDownload',
  'onImageLoaded',
  'paramValues',
  'showTimeStamp',
  'timeStamp',
  'typing',
  'userId',
]);
