// WARNING: All of the methods in this file are being exported from the package

const getOptionMap = (arr) =>
  arr.reduce((options, opt) => {
    options[opt] = opt;

    return options;
  }, {});

export default getOptionMap;
