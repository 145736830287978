import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOtherMentorProgram extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M27.586 45.763c2.346-1.124 3.97-3.513 3.97-6.288 0-3.852-3.123-6.975-6.975-6.975-3.85 0-6.974 3.123-6.974 6.975 0 2.832 1.69 5.264 4.113 6.357v.616c-3.137.58-5.4 1.945-5.416 3.655l-.005.09-.3 4.25v9.477s17.14.15 17.367.15v-8.456l11.567 1.913c-.167-.387-.26-.812-.26-1.26 0-1.073.533-2.017 1.343-2.596l-18.43-7.254v-.653zM79.387 50.194l-.004-.09c-.017-1.71-2.28-3.075-5.415-3.656v-.616c2.423-1.094 4.113-3.525 4.113-6.357 0-3.852-3.122-6.975-6.974-6.975-3.852 0-6.975 3.123-6.975 6.975 0 2.775 1.625 5.164 3.97 6.288v.654L47.49 54.53v.005c-.79.168-1.38.867-1.38 1.705 0 .963.78 1.744 1.743 1.744.1 0 .196-.014.29-.03l.004.006 14.173-2.346v8.457c.226 0 17.367-.15 17.367-.15V54.444l-.3-4.25z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOtherMentorProgram;
