import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  List,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

// Atoms
import UserImage from '../atoms/UserImage';
import EspActivityList from '../atoms/EspActivityList';
import ScrollArea from '../atoms/ScrollArea';
// Molecules
import EspListItem from './EspListItem';
// Controller
import OnboardActivityPerformanceDetailController from '../controllers/OnboardActivityPerformanceDetailController';

import uiPathGenerator from '../../utils/uiPathGenerator';
import getOverdueDate from '../../utils/getOverdueDate';
import dateFormat from '../../utils/dateFormat';
// Globals
import OnboardActivitiesCategory from '../../globals/OnboardActivitiesCategory';
import OnboardingActivityStates from '../../globals/OnboardingActivityStates';
import NewHireStatus from '../../globals/NewHireStatus';

/**
 * @param {function} escalateProgress Escalate a user's progress
 * @param {Object} intl  i18n module to format messages
 * @param {function} loadPerformanceDetailList Load performance details
 * @param {Object} location Router parameters
 * @param {Boolean} isLoadingPerformanceDetail Whether the component is in loading state or not
 * @param {function} loadUserJobRole Load a user's job role
 * @param {Object} params URL parameters (userID)
 * @param {immutable.List} performanceDetail Performance details for the user
 * @param {immutable.Map} selectedUser  Currenly selected user
 * @param {immutable.Map} selectedUserJobRole Currently selected user's job role
 * @param {immutable.Map} selectedUserManager Currently selected user's manager
 */

class OnboardingActivityPerformanceDetail extends PureComponent {
  static propTypes = {
    escalateProgress: PropTypes.func.isRequired,
    groupPermissions: PropTypes.objectOf(PropTypes.string).isRequired,
    isLoadingPerformanceDetail: PropTypes.bool,
    loadPerformanceDetailList: PropTypes.func.isRequired,
    loadUserJobRole: PropTypes.func,
    location: PropTypes.shape({
      query: PropTypes.shape({
        type: PropTypes.string,
      }),
    }).isRequired,
    params: PropTypes.shape({
      userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    performanceDetail: ImmutablePropTypes.list,
    selectedUser: ImmutablePropTypes.map,
    selectedUserJobRole: ImmutablePropTypes.map,
    selectedUserManager: ImmutablePropTypes.map,
  };

  static defaultProps = {
    isLoadingPerformanceDetail: false,
    loadUserJobRole: _.noop,
    params: {},
    performanceDetail: immutable.List(),
    selectedUser: immutable.Map(),
    selectedUserJobRole: immutable.Map(),
    selectedUserManager: immutable.Map(),
  };

  componentDidMount() {
    const {
      params: { userID },
      loadPerformanceDetailList,
      location: { query },
    } = this.props;

    if (query.type) {
      loadPerformanceDetailList(userID, query.type);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedUser, loadUserJobRole } = this.props;
    if (selectedUser !== prevProps.selectedUser) {
      loadUserJobRole(selectedUser);
    }
  }

  buildList = (activityList, title) => {
    const {
      location: { query },
    } = this.props;

    let subheader = null;

    if (activityList.size) {
      subheader = (
        <Header
          content={activityList.size}
          floated='right'
          style={{
            marginTop: '.25em',
          }}
          sub
        />
      );
    }

    const wrapList = (children) => (
      <>
        <Header
          as='h4'
          content={title}
          dividing
          style={{
            backgroundColor: '#fcfcfc',
            borderTop: '1px solid rgba(34,36,38,.15)',
            margin: 0,
            padding: '.75em',
          }}
          subheader={subheader}
        />
        <ScrollArea height='calc(100% - 50px)'>
          <List divided relaxed='very' verticalAlign='middle'>
            {children}
          </List>
        </ScrollArea>
      </>
    );

    if (activityList.isEmpty()) {
      const emptyMessage = (
        <List.Item>
          <Message content={`No tasks are ${title}`} info />
        </List.Item>
      );
      return wrapList(emptyMessage);
    }
    const listItems = [];

    activityList.forEach((item) => {
      const template = item.get('template');
      if (!template.isEmpty()) {
        /* NOTE2: template.internalDescription is still not supported by the API, I use private_notes for now */
        const meta =
          title !==
          intl.formatMessage({
            id: 'label.completed',
          })
            ? getOverdueDate(item, intl)
            : null;

        const attachedHeader =
          query.type !== NewHireStatus && item.has('user_requested')
            ? `New Hire: ${item.getIn([
                'user_requested',
                'nickname',
              ])} ${item.getIn(['user_requested', 'last_name'])}`
            : template.get('private_notes');

        listItems.push(
          <EspListItem
            attachedHeader={attachedHeader}
            description={template.get('description')}
            header={template.get('title')}
            image={
              OnboardActivitiesCategory.getIn([
                template.get('category'),
                'image',
              ]) || null
            }
            key={item.get('key')}
            meta={meta}
          />
        );
      }
    });

    return wrapList(listItems);
  };

  handleEscalate = () => {
    const {
      escalateProgress,
      params: { userID },
    } = this.props;

    escalateProgress(userID);
  };

  render() {
    const {
      groupPermissions,

      isLoadingPerformanceDetail,
      performanceDetail,
      selectedUser,
      selectedUserManager,
      params: { userID },
      location: { query },
      selectedUserJobRole,
    } = this.props;

    if (!query.type || !groupPermissions[query.type]) {
      const errorMsg = !query.type
        ? 'Not type has been passed'
        : `The type ${query.type} does not exist`;

      return <Message content={errorMsg} header='Error' negative />;
    }

    const listDetail = isLoadingPerformanceDetail
      ? immutable.fromJS([])
      : performanceDetail; // Avoid to render the previous detail selected

    const filteredProgress = immutable.fromJS({
      active: listDetail.filter(
        (detail) =>
          detail.get('state') === OnboardingActivityStates.NORMAL ||
          detail.get('state') === OnboardingActivityStates.WARNING
      ),
      completed: listDetail.filter(
        (detail) => detail.get('state') === OnboardingActivityStates.COMPLETE
      ),
      delinquent: listDetail.filter(
        (detail) => detail.get('state') === OnboardingActivityStates.DELINQUENT
      ),
      overdue: listDetail.filter(
        (detail) => detail.get('state') === OnboardingActivityStates.OVERDUE
      ),
      scheduled: listDetail.filter(
        (detail) => detail.get('state') === OnboardingActivityStates.SCHEDULED
      ),
    });

    const displayEscalateButton =
      (filteredProgress.has('overdue') &&
        !filteredProgress.get('overdue').isEmpty()) ||
      (filteredProgress.has('delinquent') &&
        !filteredProgress.get('delinquent').isEmpty());

    let managerName = `${selectedUserManager.get(
      'nickname'
    )} ${selectedUserManager.get('last_name')}`;

    if (!selectedUserManager || selectedUserManager.isEmpty()) {
      managerName = 'There is no manager for this user';
    }

    let userName = `${selectedUser.get('nickname')} ${selectedUser.get(
      'last_name'
    )}`;
    if (!selectedUser || selectedUser.isEmpty()) {
      userName = 'Loading...';
    }

    const managerLink = selectedUserManager.get('id')
      ? uiPathGenerator.genPath('app.directory.detail', {
          userID: selectedUserManager.get('id'),
        })
      : uiPathGenerator.genPath('app.directory');

    const scheduledActivities = filteredProgress.get('scheduled');
    const count = scheduledActivities.getIn([0, 'count']) || 0;
    const remainingActivities = intl.formatMessage(
      {
        id: 'message.count_remaining_activities',
      },
      {
        activitiesRemainingCount: count,
      }
    );

    return (
      <Segment as={Grid} attached clearing>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_onboarding_activities_performance',
          })}
        />

        <Grid.Row columns={1}>
          <Grid.Column>
            <Form>
              <Form.Group className='button-actions' grouped>
                <Button
                  as={Link}
                  className='text'
                  content='Back'
                  floated={'left'}
                  icon='left chevron'
                  to={uiPathGenerator.genPath(
                    'admin.adminActivities.onboardingActivities.performance'
                  )}
                />
                <Button
                  basic
                  content='Escalate'
                  disabled={!displayEscalateButton}
                  onClick={this.handleEscalate}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        {isLoadingPerformanceDetail && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}

        <Grid.Row>
          <Grid.Column
            style={{
              paddingRight: 0,
            }}
            width={1}
          >
            <NavLink
              to={uiPathGenerator.genPath('app.directory.detail', {
                userID: userID,
              })}
            >
              <UserImage
                className='circular'
                user={selectedUser.isEmpty() ? null : selectedUser}
              />
            </NavLink>
          </Grid.Column>
          <Grid.Column width={15}>
            <NavLink
              to={uiPathGenerator.genPath('app.directory.detail', {
                userID: userID,
              })}
            >
              <Header as='h3' content={userName} />
            </NavLink>

            {selectedUser.get('title') && (
              <div>
                <strong>{'Title: '}</strong>
                {selectedUser.get('title')}
              </div>
            )}

            {selectedUserJobRole.get('name') && (
              <div>
                <strong>{'Job Role: '}</strong>
                {selectedUserJobRole.get('name')}
              </div>
            )}

            {selectedUser.get('department') && (
              <div>
                <strong>{'Department: '}</strong>
                {selectedUser.get('department')}
              </div>
            )}

            <div>
              <strong>{'Manager: '}</strong>
              <NavLink to={managerLink}>{managerName}</NavLink>
            </div>

            {selectedUser.get('start_date') && (
              <div>
                <strong>{'Start Date: '}</strong>
                {dateFormat(selectedUser.get('start_date'))}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <p>{remainingActivities}</p>

            <ScrollArea
              height='calc(100vh - 450px)'
              horizontal
              margin='0 -1em -1em'
              padding={0}
              width='calc(100% + 2em)'
            >
              <Grid
                divided
                padded
                style={{
                  height: '100%',
                  width: '120%',
                }}
              >
                <Grid.Row
                  columns='equal'
                  style={{
                    padding: 0,
                  }}
                >
                  <Grid.Column
                    style={{
                      padding: 0,
                    }}
                  >
                    <EspActivityList
                      activities={filteredProgress.get('active')}
                      intl={intl}
                      isNewHire={query.type !== NewHireStatus}
                      title={intl.formatMessage({
                        id: 'label.active',
                      })}
                    />
                  </Grid.Column>

                  <Grid.Column
                    style={{
                      padding: 0,
                    }}
                  >
                    <EspActivityList
                      activities={filteredProgress.get('delinquent')}
                      intl={intl}
                      isNewHire={query.type !== NewHireStatus}
                      title={intl.formatMessage({
                        id: 'label.delinquent',
                      })}
                    />
                  </Grid.Column>

                  <Grid.Column
                    style={{
                      padding: 0,
                    }}
                  >
                    <EspActivityList
                      activities={filteredProgress.get('overdue')}
                      intl={intl}
                      isNewHire={query.type !== NewHireStatus}
                      title={intl.formatMessage({
                        id: 'label.overdue',
                      })}
                    />
                  </Grid.Column>

                  <Grid.Column
                    style={{
                      padding: 0,
                    }}
                  >
                    <EspActivityList
                      activities={filteredProgress.get('completed')}
                      intl={intl}
                      isNewHire={query.type !== NewHireStatus}
                      title={intl.formatMessage({
                        id: 'label.completed',
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </ScrollArea>
          </Grid.Column>
        </Grid.Row>
      </Segment>
    );
  }
}

const OnboardingActivityPerformanceDetailTest =
  OnboardingActivityPerformanceDetail;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardingActivityPerformanceDetail =
  OnboardActivityPerformanceDetailController(
    OnboardingActivityPerformanceDetail
  );
export { OnboardingActivityPerformanceDetailTest };
export default OnboardingActivityPerformanceDetail;
