// Selector
import getCurrentUser, { getCurrentUserId } from '../selectors/getCurrentUser';
import {
  getCasesSelectedServiceTeamId,
  getSelectedDepartmentId,
} from '../selectors/getSelectedServiceTeam';

import _ from 'lodash';
import APIcall from '../utils/APIcall';
// Actions
import appUIActions from './appUIActions';
import async from 'async';
import browserHistory from '../utils/browserHistory';
import { CASE_TYPE } from '../../v2/components/functional/CaseCard/caseConstants';
import caseMgmtThunks from './caseMgmtThunks';
import casesActions from './casesActions';
import CaseStates from '../globals/CaseStates';
import caseUtils from '../../v2/components/functional/CaseCard/caseUtils';
// Utils
import endpointGenerator from '../utils/endpointGenerator';
// Packages
import EspFilters from 'esp-util-filters';
import { fetchConfiguration } from '../../globals/commonAPICalls';
import { fromJS } from 'immutable';

import SearchModels from '../utils/SearchModels';
// Globals
import { SubtaskTypes } from '../globals/CaseTypeOptions';
import uiPathGenerator from '../utils/uiPathGenerator';
import workflowActions from './workflowActions';
import WorkflowEIDs from '../globals/WorkflowEIDs';
import workflowThunks from './workflowThunks';

/**
 * Case (Task) Thunks
 * Raison D'être: deals with the api 'task/v0.1/tasks/' at the instance level of each case (task)
 * but not with clasification and categories and other endpoints handled by caseMgmtThunks
 *
 */

const OK_STATUS = 200;

const changeOwner = (taskId, ownerId, forceNoOwner) =>
  new Promise((resolve, reject) => {
    if (!taskId) {
      reject('No taskId');
      return;
    }
    if (!ownerId && !forceNoOwner) {
      reject('No ownerId');
      return;
    }

    const url = endpointGenerator.genPath('task.tasks.instance.changeOwner', {
      taskPK: taskId,
    });
    APIcall.post({
      data: {
        owner: ownerId || null,
        show_purchase_reqs: true,
      },
      error(e) {
        reject(e);
      },
      query: {
        show_purchase_reqs: true,
      },
      success(res) {
        const task = res.body;
        resolve(task);
      },
      token: true,
      url: url,
    });
  });

const changeTeam = (taskId, teamId) =>
  new Promise((resolve, reject) => {
    if (!taskId) {
      reject('No taskId');
      return;
    }
    if (!teamId) {
      reject('No teamId');
      return;
    }

    const url = endpointGenerator.genPath('task.tasks.instance.changeTeam', {
      taskPK: taskId,
    });
    APIcall.post({
      data: {
        service_team: teamId,
      },
      error(e) {
        reject(e);
      },
      query: {
        show_purchase_reqs: true,
      },
      success(res) {
        const task = res.body;
        resolve(task);
      },
      token: true,
      url: url,
    });
  });

const casesThunks = {
  approveAll: () => (dispatch /* getState*/) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks.approveAll');
      const filterQuery = caseUtils.getEspFilterFromApprovalFilter();
      APIcall.post({
        error(err) {
          reject(err);
        },
        query: {
          ...filterQuery.otherQueryParameters,
          esp_filters: filterQuery.espFilter.asQueryString(),
        },
        success({ body }) {
          dispatch(casesActions.resetTasks());
          resolve(body);
        },
        token: true,
        url: url,
      });
    }),

  approveTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }
      // dispatch(casesActions.approveTaskStart(taskId));
      dispatch(casesThunks.changeStatus(taskId, CaseStates.APPROVED))
        .then((task) => {
          dispatch(casesActions.taskUpdate(task));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  assignTo: (taskId, userId, teamId) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(casesActions.assignToStart(taskId, userId));

      let newTeamId = teamId;
      const state = getState();
      const currentServiceTeamId = getCasesSelectedServiceTeamId(state);
      const taskSelected = state.getIn(['entities', 'tasks', taskId]);
      const selectedTaskTeamId = taskSelected.getIn(['service_team', 'id']);

      if (!teamId) {
        // If we didn't got a teamId, the user should belong to only one service team
        // Take it and compare it
        const userTeamId = state.hasIn([
          'entities',
          'users',
          userId,
          'service_departments',
          0,
          'service_teams',
          0,
          'id',
        ])
          ? state.getIn([
              'entities',
              'users',
              userId,
              'service_departments',
              0,
              'service_teams',
              0,
              'id',
            ])
          : null;
        if (userTeamId && userTeamId !== selectedTaskTeamId) {
          newTeamId = userTeamId;
        }
      }

      async.waterfall(
        [
          // 1 . Update Owner
          (next) => {
            changeOwner(taskId, userId)
              .then((task) => {
                next(null, task);
              })
              .catch((err) => {
                next(err);
              });
          },
          // 2 . Update the team if needed
          (task, next) => {
            const serviceTeam = task.service_team;
            if (newTeamId && newTeamId !== serviceTeam.id) {
              // Update the team
              changeTeam(taskId, newTeamId)
                .then((newTask) => {
                  // Is the new team different from the current filters
                  const teamChanged = currentServiceTeamId !== newTeamId;

                  next(null, newTask, teamChanged);
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              next(null, task);
            }
          },
        ],
        (err, task, changedTeam) => {
          if (err) {
            reject(err);
          } else {
            dispatch(casesActions.taskUpdate(task));
            resolve({
              changedTeam,
              task,
            });
          }
        }
      );
    }),

  assignToMe: (taskId) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const currentUserId = getCurrentUserId(state);
      dispatch(casesActions.assignToMeStart(taskId));
      changeOwner(taskId, currentUserId)
        .then((task) => {
          dispatch(casesActions.taskUpdate(task));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  cancelByAgentTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }
      dispatch(casesThunks.changeStatus(taskId, CaseStates.CANCELLED_BY_AGENT))
        .then((task) => {
          dispatch(casesActions.deleteCase(taskId));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  changeAuthor: (taskId, userId) => () =>
    new Promise((resolve, reject) => {
      if (!userId) {
        return;
      }

      const url = endpointGenerator.genPath(
        'task.tasks.instance.changeAuthor',
        {
          taskPK: taskId,
        }
      );
      APIcall.post({
        data: {
          author: userId,
        },
        error(e) {
          reject(e);
        },
        query: {
          show_purchase_reqs: true,
        },
        success(res) {
          const task = res.body;
          resolve(task);
        },
        token: true,
        url: url,
      });
    }),

  changeLocation: (taskId, locationID) => () =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks.instance', {
        taskPK: taskId,
      });
      APIcall.patch({
        data: {
          location: locationID,
        },
        error(e) {
          reject(e);
        },
        query: {
          show_purchase_reqs: true,
        },
        success(res) {
          resolve(res.body);
        },
        token: true,
        url: url,
      });
    }),
  changeStatus:
    (taskId, newStatus, espFilters = '') =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        if (!taskId) {
          reject('No taskId');
          return;
        }
        if (!newStatus) {
          reject('No newStatus');
          return;
        }

        const task = getState().getIn(['entities', 'tasks', taskId]);

        async.waterfall(
          [
            (next) => {
              if (task && task.get('sub_status')) {
                dispatch(
                  casesThunks.saveCase(
                    taskId,
                    fromJS({
                      category: task.get('category'),
                      sub_status: null,
                      title: task.get('title'),
                    })
                  )
                )
                  .then(() => {
                    next();
                  })
                  .catch((err) => {
                    next(null, err);
                  });
              } else {
                next();
              }
            },
            (next) => {
              APIcall.post({
                data: {
                  status: newStatus,
                },
                error(e) {
                  next(e);
                },
                query: {
                  espFilters: espFilters ? espFilters : null,
                  show_purchase_reqs: true,
                },
                success(res) {
                  const task = res.body;
                  next(null, task);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'task.tasks.instance.changeStatus',
                  {
                    taskPK: taskId,
                  }
                ),
              });
            },
          ],
          (err, task) => {
            if (err) {
              reject(err);
            } else {
              resolve(task);
            }
          }
        );
      }),

  changeTeam: (taskId, teamId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId || !teamId) {
        reject(new Error('No taskId or teamId'));
        return;
      }

      dispatch(casesActions.taskUpdateStart());

      // Check all teams of this user
      async.waterfall(
        [
          // 1 . Make the task unassign
          (next) => {
            const forceNoOwner = true;
            changeOwner(taskId, null, forceNoOwner)
              .then(() => next())
              .catch((err) => next(err));
          },

          // 2 . Change the Team
          (next) => {
            changeTeam(taskId, teamId)
              .then((task) => {
                next(null, task);
              })
              .catch((err) => {
                next(err);
              });
          },
        ],
        (err, task) => {
          if (err) {
            dispatch(casesActions.taskUpdateFail());
            reject();
          } else {
            dispatch(casesActions.taskUpdate(task));
            resolve(task);
          }
        }
      );
    }),

  changeType: (taskId, type) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('taskId must be provided'));
        return;
      }

      if (!type) {
        reject(new Error('type must be provided'));
        return;
      }

      const task = getState().getIn(['entities', 'tasks', taskId]);

      async.waterfall(
        [
          (next) => {
            if (task && task.get('sub_status')) {
              dispatch(
                casesThunks.saveCase(
                  taskId,
                  fromJS({
                    sub_status: null,
                  })
                )
              )
                .then(() => {
                  next();
                })
                .catch((err) => {
                  next(null, err);
                });
            } else {
              next();
            }
          },
          (next) => {
            APIcall.post({
              data: { type },
              error(e) {
                next(e);
              },
              success(res) {
                next(null, res.body);
              },
              token: true,
              url: endpointGenerator.genPath('task.tasks.instance.changeType', {
                taskPK: taskId,
              }),
            });
          },
        ],
        (err, task) => {
          if (err) {
            reject(err);
          } else {
            resolve(task);
          }
        }
      );
    }),

  checkCaseAndTeachBarista: (incident) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // Check if this task already existing in the state
      const state = getState();
      const tasks = state.getIn(['entities', 'tasks']);

      const selectedTask = tasks.find((t) => t.get('ref_num') === incident);

      if (selectedTask) {
        const redirectPath = 'app.casesFeed.detail';
        browserHistory.push(
          `${uiPathGenerator.genPath(redirectPath, {
            caseID: selectedTask.get('id'),
          })}?taskId=${selectedTask.get('id')}`
        );

        dispatch(workflowActions.loading());
        dispatch(
          workflowThunks.createWorkflowRequestID(WorkflowEIDs.TEACH_BARISTA)
        );

        resolve(selectedTask);
      } else {
        // The task doesn't exist in the store, we need to find and load it
        dispatch(casesActions.loadCaseFeedStart());

        async.waterfall(
          [
            // 1. Check and load my default team
            (next) => {
              // Check for my defaults teams
              const myDefaultTeam = state.getIn([
                'caseMgmt',
                'defaultServiceTeam',
              ]);
              if (myDefaultTeam.isEmpty()) {
                dispatch(caseMgmtThunks.getDefaultAndMyTeams()).then(() => {
                  next();
                });
              } else {
                next();
              }
            },

            // 2.  Load the case that belong to the incident
            (next) => {
              APIcall.get({
                query: {
                  esp_filters: new EspFilters()
                    .equalTo('ref_num', incident)
                    .asQueryString(),
                  show_cancelled: true,
                  show_closed: true,
                  show_discarded: true,
                  show_purchase_reqs: true,
                  show_related_barista_responses: true,
                  show_resolved: true,
                },
                token: true,
                url: endpointGenerator.genPath('task.tasks'),
              })
                .then(({ body }) => {
                  const { results: cards, count, next: nxt, previous } = body;
                  const pagination = {
                    next: nxt,
                    prev: previous,
                  };

                  dispatch(
                    casesActions.loadCaseFeedSuccess(
                      count,
                      cards,
                      pagination,
                      false
                    )
                  );

                  const taskId = cards.length ? cards[0].id : '';

                  next(null, taskId);
                })
                .catch((err) => {
                  next(err);
                });
            },
          ],
          (err, taskId) => {
            if (err) {
              dispatch(casesActions.loadCaseFeedFailure(err.message));
              reject(err);
            } else {
              browserHistory.push({
                search: `?taskId=${taskId}`,
              });
              dispatch(workflowActions.loading());
              dispatch(
                workflowThunks.createWorkflowRequestID(
                  WorkflowEIDs.TEACH_BARISTA
                )
              );
              resolve(taskId);
            }
          }
        );
      }
    }),

  checkKBAndTeachBarista: (KBid, KBTitle) => (dispatch) =>
    new Promise((resolve) => {
      // Set the value that we will need
      dispatch(casesActions.setKBwf(KBid, KBTitle));

      // Load the WF Teach Barista
      dispatch(workflowActions.loading());
      dispatch(
        workflowThunks.createWorkflowRequestID(WorkflowEIDs.TEACH_BARISTA)
      );
      resolve();
    }),

  createFAQ: () => (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    const userID = currentUser.get('id');

    dispatch(workflowThunks.mapEIDToID(WorkflowEIDs.CREATE_FAQ))
      .then((workflowID) =>
        dispatch(
          workflowThunks.addWorkflowRequest({
            assignedTo: userID,
            currentWorkflow: workflowID,
            owner: userID,
            requestedFor: userID,
            startingWorkflow: workflowID,
          })
        )
      )
      .then((workflowRequest) => {
        dispatch(appUIActions.openWorkflowModal(workflowRequest.id));
      });
  },

  createNewCase: (data) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!data) {
        reject(new Error('No Data task'));
        return;
      }

      const state = getState();

      // Get the current User service team and service department
      const userServiceTeamID = getCasesSelectedServiceTeamId(state);
      const userServiceDepartmentID = getSelectedDepartmentId(state);

      dispatch(casesActions.createCaseStart());

      const departmentID = data.get('teamID')
        ? data.get('departmentID')
        : userServiceDepartmentID;

      const serviceTeamID = data.get('teamID')
        ? data.get('teamID')
        : userServiceTeamID;

      const finalData = {
        author: data.get('author'),
        category: data.get('category'),
        location: data.get('location'),
        owner: data.get('owner'),
        parent: data.get('parent'),
        service_department: departmentID,
        service_team: serviceTeamID,
        source: 'web',
        title: data.get('title'),
        type: data.get('type'),
      };

      async.waterfall(
        [
          // 1 Post the new Task
          (next) => {
            const url = endpointGenerator.genPath('task.tasks');
            APIcall.post({
              data: finalData,
              error(e) {
                next(e);
              },
              success(res) {
                const task = res.body;

                next(null, task);
              },
              token: true,
              url: url,
            });
          },
        ],
        (error, task) => {
          if (error) {
            dispatch(casesActions.createCaseFail());
            reject(error);
          } else {
            dispatch(casesActions.createCaseSuccess(task));
            resolve(task);
          }
        }
      );
    }),

  createNewCaseFromBaristaSearch:
    (
      task,
      useResponseData,
      shouldCopyEmployeeConversation = false,
      searchQuery
    ) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        dispatch(casesActions.createCaseStart());

        const state = getState();
        const currentUserId = getCurrentUserId(state);

        const finalData = {
          author: task.get('author'),
          category: useResponseData.getIn(
            [
              'metadata',
              'user_input',
              'api_action_button',
              'proposed_service_category',
              'eid',
            ],
            task.getIn(['category'])
          ),
          copy_reference_conv_chan_msgs: shouldCopyEmployeeConversation
            ? shouldCopyEmployeeConversation
            : void 0,
          location: task.get('location'),
          owner: currentUserId,
          parent: task.get('parent') ? task.get('parent') : task.get('id'),
          reference_conv_chan_eid: shouldCopyEmployeeConversation
            ? useResponseData.get('channel')
            : void 0,
          service_department: useResponseData.getIn(
            [
              'metadata',
              'user_input',
              'api_action_button',
              'proposed_service_department',
              'id',
            ],
            task.getIn(['service_department', 'id'])
          ),
          service_team: useResponseData.getIn(
            [
              'metadata',
              'user_input',
              'api_action_button',
              'proposed_service_team',
              'id',
            ],
            task.getIn(['service_team', 'id'])
          ),
          source: 'web',
          title: searchQuery,
          type: useResponseData.getIn([
            'metadata',
            'user_input',
            'api_action_button',
            'proposed_task_type',
          ]),
        };

        const urlTask = endpointGenerator.genPath('task.tasks');

        const createSubtask = () => {
          async.waterfall(
            [
              // 1 Post the new Task
              (next) => {
                APIcall.post({
                  data: finalData,
                  error(e) {
                    next(e);
                  },
                  success(res) {
                    const task = res.body;
                    next(null, task);
                  },
                  token: true,
                  url: urlTask,
                });
              },
            ],
            (error, newTask) => {
              if (error) {
                dispatch(casesActions.createCaseFail());
                reject(error);
              } else {
                dispatch(casesActions.createCaseSuccess(newTask));
                resolve(newTask);
              }
            }
          );
        };

        createSubtask();
      }),

  discardTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }
      dispatch(casesActions.discardTaskStart(taskId));

      dispatch(casesThunks.changeStatus(taskId, CaseStates.DISCARDED))
        .then((task) => {
          dispatch(casesActions.deleteCase(taskId));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  getApprovalRejectionReasonConfig: () => (dispatch) =>
    new Promise((resolve) => {
      fetchConfiguration({
        key: 'var.enable_approval_reject_reason',
      })
        .then((res) => {
          // Config is boolean, so it must be true
          if (res.value) {
            resolve(true);
            dispatch(casesActions.setRejectionReasonConfig(true));
          } else {
            dispatch(casesActions.setRejectionReasonConfig(false));
            resolve(false);
            // config does not exist or is false
          }
        })
        .catch(() => {
          dispatch(casesActions.setRejectionReasonConfig(false));
          resolve(false);
        });
    }),

  getLocation: (locationID) => () =>
    new Promise((resolve, reject) => {
      const url = locationID
        ? endpointGenerator.genPath('espPlaces.locations.instance', {
            locationID,
          })
        : endpointGenerator.genPath('espPlaces.locations');

      const espFilters = new EspFilters();
      if (!locationID) {
        espFilters.isNull('parent');
      }

      APIcall.get({
        error(error) {
          reject(error);
        },
        query: {
          esp_filters: espFilters.asQueryString(),
        },
        success(response) {
          let location = response.body;
          if (!locationID) {
            if (response.body.results && response.body.results.length > 0) {
              [location] = response.body.results;
            }
          }
          resolve(location);
        },
        token: true,
        url,
      });
    }),

  getUserLocations: (locationID) => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('espCaseMgmt.locationUser');
      dispatch(casesActions.loadUserLocationStart());

      const espFilters = new EspFilters();
      if (locationID) {
        espFilters.equalTo('location', locationID);
      }

      APIcall.get({
        error(error) {
          dispatch(casesActions.loadUserLocationFail(error));
          reject(error);
        },
        query: {
          esp_filters: espFilters.asQueryString(),
        },
        success(response) {
          const userLocations = response.body.results;
          dispatch(casesActions.loadUserLocationSuccess(userLocations));
          resolve(userLocations);
        },
        token: true,
        url,
      });
    }),
  loadApprovalFeed:
    (reset = false, limit = 30) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        const state = getState();

        const endpoint = endpointGenerator.genPath('task.tasks');
        const pagination = state.getIn(['cases', 'pagination']);
        // If it exist a next pagination in the reducer, we use that as the url
        // otherwise we fallback to the endpoint root
        const url = reset
          ? endpoint
          : pagination?.get('next')
          ? pagination?.get('next')
          : endpoint;

        const filterQuery = caseUtils.getEspFilterFromApprovalFilter();
        dispatch(casesActions.loadApprovalFeedStart());

        APIcall.get({
          error(err) {
            dispatch(casesActions.loadApprovalFeedFailure(err.message));
            reject(err);
          },
          query: {
            esp_filters: filterQuery.espFilter.asQueryString(),
            limit,
            ...filterQuery.otherQueryParameters,
          },
          success(res) {
            const { body } = res;
            const cards = body.results;

            const pagination = {
              next: body.next,
              prev: body.previous,
            };

            dispatch(
              casesActions.loadApprovalFeedSuccess(cards, pagination, reset)
            );
            resolve(cards);
          },
          token: true,
          url: url,
        });
      }),

  loadCaseFeed:
    (reset = false, limit = 10) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        const state = getState();

        const endpoint = endpointGenerator.genPath('task.tasks');
        const pagination = state.getIn(['cases', 'pagination']);
        const userDefaultTeamID = state.getIn([
          'caseMgmt',
          'defaultServiceTeam',
        ]);
        const userServiceTeamID = getCasesSelectedServiceTeamId(state);

        if (!userServiceTeamID && !userDefaultTeamID) {
          reject(
            new Error('No Service Teams configured to route to Espressive')
          );
        }

        if (!userServiceTeamID) {
          reject(new Error('The user does not belong to any Service Team'));
          return;
        }

        // If it exist a next pagination in the reducer, we use that as the url
        // otherwise we fallback to the endpoint root
        const url = reset
          ? endpoint
          : pagination.get('next')
          ? pagination.get('next')
          : endpoint;

        const filterQuery =
          pagination.get('next') && !reset
            ? '' // query should be empty, as next includes all the query parameters used before
            : caseUtils.getEspFilterFromCasesFilter(); // dynamic based on casesFilter reducer state
        dispatch(casesActions.loadCaseFeedStart());

        if (reset) {
          dispatch(casesActions.resetTasks());
        }

        const query = {
          ...filterQuery?.parameters,
          esp_filters: filterQuery?.espFilter?.asQueryString(),
          limit,
        };

        if (!url.includes('show_purchase_reqs')) {
          query.show_purchase_reqs = true;
        }
        if (!url.includes('show_related_barista_responses')) {
          query.show_related_barista_responses = true;
        }

        APIcall.get({
          error(err) {
            dispatch(casesActions.loadCaseFeedFailure(err.message));
            reject(err);
          },
          query,
          success({ body }) {
            const { results: cards, count, next, previous } = body;
            const pagination = {
              next,
              prev: previous,
            };

            dispatch(
              casesActions.loadCaseFeedSuccess(count, cards, pagination, reset)
            );
            resolve(cards);
          },
          token: true,
          url: url,
        });
      }),

  loadCaseTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }

      const query = {
        check_conditions: true,
        show_cancelled: true,
        show_discarded: true,
        show_purchase_reqs: true,
        show_related_barista_responses: true,
        show_resolved: true,
      };

      const url = endpointGenerator.genPath('task.tasks.instance', {
        taskPK: taskId,
      });
      dispatch(casesActions.loadCaseTaskStart(taskId));

      async.waterfall(
        [
          // 1. Load the selected Task
          (next) => {
            APIcall.get({
              error(e) {
                next(e);
              },
              query,
              success(res) {
                const task = res.body;
                if (
                  !_.has(task, 'service_team.id') &&
                  task.type !== CASE_TYPE.APPROVAL_MANAGER
                ) {
                  // eslint-disable-next-line no-console -- externals approvals don't have a service team, however we will keep it as warning for other cases
                  console.warn('The task doesnt belong to a service team');
                }
                next(null, task);
              },
              token: true,
              url: url,
            });
          },

          // 2 . Load the member of the service team that belong to this Task
          (task, next) => {
            if (
              task.type !== CASE_TYPE.APPROVAL_MANAGER &&
              task.service_team?.id
            ) {
              APIcall.get({
                error(err) {
                  next(err);
                },
                success(res) {
                  const teamMembers = res.body.team_members;
                  next(null, task, teamMembers);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espCaseMgmt.serviceTeam.instance',
                  {
                    serviceTeamID: task.service_team?.id,
                  }
                ),
              });
            } else {
              next(null, task, null);
            }
          },

          // 3. Load the card of the task
          (task, teamMembers, next) => {
            const espFilters = new EspFilters();
            espFilters.equalTo('object_id', task.id);
            APIcall.get({
              error(err) {
                next(null, task, teamMembers, null);
              },
              query: {
                esp_filters: espFilters.asQueryString(),
              },
              success(res) {
                const { results } = res.body;
                const card = results.length > 0 ? results[0] : null;
                next(null, task, teamMembers, card);
              },
              token: true,
              url: endpointGenerator.genPath('espCards.cards'),
            });
          },
        ],
        (err, task, teamMembers, card) => {
          if (err) {
            reject(err);
          } else {
            dispatch(
              casesActions.loadCaseTaskSuccess(taskId, task, teamMembers, card)
            );
            resolve(task);
          }
        }
      );
    }),

  loadCatalogSubtask: (taskEid) => () =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks');
      const query = {
        esp_filters: `eid__EQ=${taskEid}`,
        show_purchase_reqs: true,
        show_related_barista_responses: true,
      };
      APIcall.get({
        error(e) {
          reject(new Error(e));
        },
        query,
        success(res) {
          const task = _.head(res.body.results);
          resolve(task);
        },
        token: true,
        url,
      });
    }),

  loadDefaultSubscribers: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      // returns http://tenant1.esp/api/task/v0.1/tasks/taskId/subscribe/
      const url = endpointGenerator.genPath('task.tasks.instance.subscribe', {
        taskPK: taskId,
      });

      APIcall.get({
        error(err) {
          reject(err);
        },
        success(res) {
          if (res?.body.status === OK_STATUS) {
            const { subscribers } = res?.body ?? [];

            dispatch(casesActions.setDefaultSubscribers(subscribers));

            resolve(subscribers);
          } else {
            resolve([]);
          }
        },
        token: true,
        url,
      });
    }),

  loadDepartmentSummary:
    ({ limit, offset, concate, resetTeamList, searchTerm }) =>
    (dispatch) => {
      const url = endpointGenerator.genPath('espCaseMgmt.summary');

      const query = {
        internal: true,
      };

      if (offset) {
        query.offset = offset;
      }

      if (limit) {
        query.limit = limit;
      }

      if (searchTerm) {
        query.esp_filters = encodeURI(`service_teams.name__IC=${searchTerm}`);
      }

      dispatch(casesActions.loadCaseSummaryStart());

      return APIcall.get({
        error() {
          dispatch(casesActions.loadCaseSummaryFail());
        },
        query,
        success({ body }) {
          dispatch(
            casesActions.loadCaseSummarySuccess({
              concate,
              next: body.next,
              resetTeamList,
              teams: body.results,
            })
          );
        },
        token: true,
        url,
      });
    },

  loadSubscriptionScope: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        'task.tasks.instance.subscriptionScope',
        {
          taskPK: taskId,
        }
      );
      dispatch(casesActions.loadSubscriptionScopeStart(taskId));
      APIcall.get({
        error(e) {
          reject(new Error(e));
        },
        success({ body }) {
          const scope = body;
          dispatch(casesActions.loadSubscriptionScopeSuccess(taskId, scope));
          resolve(scope);
        },
        token: true,
        url,
      });
    }),

  loadTask: (taskId) => () =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }

      const query = {
        check_conditions: true,
        show_cancelled: true,
        show_discarded: true,
        show_purchase_reqs: true,
        show_resolved: true,
      };

      const url = endpointGenerator.genPath('task.tasks.instance', {
        taskPK: taskId,
      });

      APIcall.get({
        error(err) {
          reject(err);
        },
        query,
        success(res) {
          const task = res.body;
          resolve(task);
        },
        token: true,
        url: url,
      });
    }),

  loadTaskCategories:
    (searchQuery, eid, departmentId, serviceTeamId) => (dispatch) =>
      new Promise((resolve, reject) => {
        const espFilters = new EspFilters();
        if (eid) {
          // loads only 1 specific category by eid
          espFilters.equalTo('eid', eid);
        } else {
          // handle parameters as esp filters query
          if (departmentId && !isNaN(departmentId)) {
            espFilters.equalTo('service_department', departmentId);
          }
          if (searchQuery) {
            espFilters.contains('name', searchQuery);
          }
          if (serviceTeamId) {
            espFilters.in('teams', [serviceTeamId]);
          }
        }

        const url = endpointGenerator.genPath('task.categories');
        const espQuery = espFilters.asQueryString();
        const query = espQuery
          ? {
              esp_filters: espQuery,
            }
          : void 0;

        dispatch(casesActions.loadTaskCategoriesStart());
        APIcall.get({
          error(e) {
            dispatch(casesActions.loadTaskCategoriesFail(e.message));
            reject(e);
          },
          query: query,
          success(res) {
            const categories = res.body && res.body.results;
            dispatch(casesActions.loadTaskCategoriesSuccess(categories));
            resolve(categories);
          },
          token: true,
          url: url,
        });
      }),

  postCaseNote:
    (taskId, noteMsg = '', attachment) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        if (!taskId) {
          reject(new Error('No taskId'));
          return;
        }

        const state = getState();
        const currentUserId = getCurrentUserId(state);

        // Create a new FormData object.
        const formData = new FormData();

        if (attachment) {
          formData.append('attachment.file', attachment);
          formData.append('attachment.file_name', attachment?.name);
        }
        formData.append('author', currentUserId);
        formData.append('task', taskId);
        formData.append('notes', noteMsg);

        const url = endpointGenerator.genPath('task.taskNotes');

        dispatch(casesActions.postCaseNoteStart(taskId));
        APIcall.post({
          data: formData,
          error(e) {
            dispatch(casesActions.postCaseNoteFailure(taskId, e.message));
            reject(e);
          },
          success(res) {
            const note = res.body;
            dispatch(casesActions.postCaseNoteSuccess(taskId, note));
            resolve(note);
          },
          token: true,
          url: url,
        });
      }),

  reOpenTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }
      const openStatus = CaseStates.OPEN;

      const espFilter = 'show_resolved=true'; // this espFilter is needed to re-open a resolved task. Thank Martin.

      dispatch(casesActions.reOpenTaskStart(taskId));

      dispatch(casesThunks.changeStatus(taskId, openStatus, espFilter))
        .then((task) => {
          dispatch(casesActions.taskUpdate(task));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  rejectTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }
      // dispatch(casesActions.rejectTaskStart(taskId));
      dispatch(casesThunks.changeStatus(taskId, CaseStates.REJECTED))
        .then((task) => {
          dispatch(casesActions.taskUpdate(task));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  resolveTask: (taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }
      const resolvedStatus = CaseStates.RESOLVED;
      dispatch(casesActions.resolveTaskStart(taskId));

      dispatch(casesThunks.changeStatus(taskId, resolvedStatus))
        .then((task) => {
          dispatch(casesActions.taskUpdate(task));
          resolve(task);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  saveCase: (taskId, formData) => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks.instance', {
        taskPK: taskId,
      });
      return APIcall.patch({
        data: {
          // only these fields are editable so far
          category: formData.get('category'),
          sub_status:
            !formData.get('sub_status') ||
            formData.get('sub_status') === 'blank'
              ? null
              : formData.get('sub_status'),
          title: formData.get('title'),
        },
        error(e) {
          dispatch(casesActions.taskUpdateFail(taskId, e.message));
          reject();
        },
        query: {
          show_purchase_reqs: true,
        },
        success(res) {
          const task = res.body;
          dispatch(casesActions.taskUpdate(task));
          resolve();
        },
        token: true,
        url: url,
      });
    }),

  saveSubscriber: (subscriber, taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks.instance.subscribe', {
        taskPK: taskId,
      });
      APIcall.post({
        data: {
          subscribers: [subscriber],
        },
        error(e) {
          reject(new Error(e));
        },
        success(res) {
          const { subscribers } = res.body;
          dispatch(casesActions.setDefaultSubscribers(subscribers));
          resolve(res.body);
        },
        token: true,
        url,
      });
    }),

  saveSubscriptionScope:
    (taskId, { locationId }) =>
    (dispatch) =>
      new Promise((resolve, reject) => {
        const url = endpointGenerator.genPath(
          'task.tasks.instance.subscriptionScope',
          {
            taskPK: taskId,
          }
        );
        APIcall.post({
          data: {
            location: [locationId], // only save 1 location at a time for now, later we can extend this same thunk to save more complex roles or departments
          },
          error(e) {
            reject(new Error(e));
          },
          success({ body }) {
            const scope = body;
            dispatch(casesActions.loadSubscriptionScopeSuccess(taskId, scope));
            resolve(scope);
          },
          token: true,
          url,
        });
      }),

  searchServiceTeam: () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(casesActions.loadTeamsListStart());
      const state = getState();

      const userInput = state.getIn(['cases', 'teamSelect', 'searchTerm']);

      const url = endpointGenerator.genPath('espSearch.typeAhead', {
        model: SearchModels.SERVICE_TEAM,
      });

      return APIcall.get({
        error(err) {
          dispatch(casesActions.loadTeamsListFail(err));
          reject();
        },
        query: {
          q: userInput,
        },
        success({ body }) {
          dispatch(casesActions.loadTeamsListSuccess(body));
          resolve();
        },
        token: true,
        url,
      });
    }),

  sendCard: (taskId) => (/* dispatch, getState*/) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks.instance.sendCard', {
        taskPK: taskId,
      });

      APIcall.post({
        data: {},
        error(e) {
          reject(new Error(e));
        },
        success() {
          resolve();
        },
        token: true,
        url,
      });
    }),

  unsubscribeUser: (userId, taskId) => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(casesActions.loadCaseSubscriberStart());
      // returns http://tenant1.esp/api/task/v0.1/tasks/taskId/unsubscribe/
      const url = endpointGenerator.genPath('task.tasks.instance.unsubscribe', {
        taskPK: taskId,
      });

      APIcall.post({
        data: {
          subscribers: [userId],
        },
        error(err) {
          reject(new Error(err));
        },
        success(res) {
          const { subscribers } = res.body;
          dispatch(casesActions.loadCaseSubscriberEnd());
          resolve(subscribers);
        },
        token: true,
        url,
      });
    }),

  updateApprovalTaskQuestions:
    ({ taskId, questions }) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        const task =
          getState().getIn(['entities', 'tasks', taskId]) ||
          getState().getIn(['entities', 'tasks', String(taskId)]);
        const url = endpointGenerator.genPath(
          'task.tasks.instance.setApprovalQuestions',
          {
            taskPK: taskId,
          }
        );
        const updatedTask = task.setIn(
          ['approval_details', 'approval_questions'],
          questions
        );
        const data = updatedTask.get('approval_details');
        dispatch(casesActions.setApprovalTaskQuestionsStart());
        APIcall.post({
          data,
          error(e) {
            dispatch(casesActions.setApprovalTaskQuestionsFail());
            reject(e);
          },
          success() {
            dispatch(casesActions.taskUpdate(updatedTask.toJS()));
            dispatch(casesActions.setApprovalTaskQuestionsSuccess());
            resolve(questions);
          },
          token: true,
          url: url,
        });
      }),

  updateBaristaSearchCondition: (taskId, actions) => () =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.tasks.instance.condition', {
        taskPK: taskId,
      });

      async.each(
        actions,
        (action, next) => {
          APIcall.patch({
            data: { ...action },
            error(e) {
              next(e);
            },
            success() {
              next();
            },
            token: true,
            url,
          });
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    }),

  updateCaseFromBaristaSearch:
    (task, useResponseData, shouldCopyEmployeeConversation = false) =>
    (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(casesActions.updateCaseFromBaristaStart());

        const isCatalogTask = SubtaskTypes.CATALOG_REQUEST === task.get('type');

        const proposedTaskType = useResponseData.getIn([
          'metadata',
          'user_input',
          'api_action_button',
          'proposed_task_type',
        ]);
        const proposedParentTaskType = useResponseData.getIn(
          [
            'metadata',
            'user_input',
            'api_action_button',
            'proposed_parent_task_type',
          ],
          proposedTaskType
        );
        const finalProposedTaskType = task.get('parent')
          ? proposedTaskType
          : proposedParentTaskType;

        const query = {
          show_purchase_reqs: true,
        };

        const saveMessageCopy = (newTask, useResponseData) =>
          new Promise((resolve, reject) => {
            const urlMessageCopy = endpointGenerator.genPath(
              'task.tasks.instance.messageCopy',
              {
                taskPK: newTask.id,
              }
            );

            APIcall.post({
              data: {
                channel_eid: useResponseData.get('channel'),
              },
              error(e) {
                reject(e);
              },
              query: isCatalogTask ? query : void 0,
              success() {
                resolve();
              },
              token: true,
              url: urlMessageCopy,
            });
          });

        const saveRelatedResponses = (updatedTask, useResponseData) =>
          new Promise((resolve, reject) => {
            const urlRelatedResponses = endpointGenerator.genPath(
              'task.tasks.instance.relatedResponses',
              {
                taskPK: updatedTask.id,
              }
            );

            APIcall.post({
              data: {
                channel_eid: useResponseData.get('channel'),
              },
              error(e) {
                reject(e);
              },
              query: isCatalogTask ? query : void 0,
              success() {
                resolve();
              },
              token: true,
              url: urlRelatedResponses,
            });
          });

        // if proposed service department values are empty, then we do a regular change of status. We don't need to call barista_proposal
        if (
          !useResponseData.getIn([
            'metadata',
            'user_input',
            'api_action_button',
            'proposed_service_department',
            'id',
          ]) &&
          finalProposedTaskType
        ) {
          let updatedTask = task.toJS();
          async.waterfall(
            [
              // 1 change status
              (next) => {
                if (isCatalogTask) {
                  // we should not change status for catalog tasks
                  next();
                } else {
                  dispatch(
                    casesThunks.changeStatus(
                      task.get('id'),
                      finalProposedTaskType
                    )
                  )
                    .then((res) => {
                      updatedTask = res.body;
                      next();
                    })
                    .catch((e) => {
                      dispatch(casesActions.updateCaseFromBaristaFail());
                      reject(e);
                    });
                }
              },
              // 2 save related responses
              (next) => {
                if (shouldCopyEmployeeConversation) {
                  saveRelatedResponses(updatedTask, useResponseData)
                    .then(() => {
                      next();
                    })
                    .catch((e) => {
                      next(e);
                    });
                } else {
                  next();
                }
              },
              // 3 save message copy
              (next) => {
                if (shouldCopyEmployeeConversation) {
                  saveMessageCopy(updatedTask, useResponseData)
                    .then(() => {
                      next();
                    })
                    .catch((e) => {
                      next(e);
                    });
                } else {
                  next();
                }
              },
            ],
            (error) => {
              if (error) {
                dispatch(casesActions.updateCaseFromBaristaFail());
                reject(error);
              } else {
                dispatch(casesActions.updateCaseFromBaristaSuccess());
                resolve(updatedTask);
              }
            }
          );
        } else {
          const url = endpointGenerator.genPath(
            'task.tasks.instance.baristaProposal',
            {
              taskPK: task.get('id'),
            }
          );
          let updatedTask = task.toJS();
          async.waterfall(
            [
              // 1 call barista proposal
              (next) => {
                if (isCatalogTask) {
                  // we should not call barista proposal api for catalog tasks
                  next();
                } else {
                  APIcall.post({
                    data: {
                      // we need to ignore proposed_service_category and proposed_service_team for the moment
                      // category           : useResponseData.getIn(['metadata','user_input','api_action_button','proposed_service_category','eid']),
                      service_department: useResponseData.getIn([
                        'metadata',
                        'user_input',
                        'api_action_button',
                        'proposed_service_department',
                        'id',
                      ]),
                      // service_team       : useResponseData.getIn(['metadata','user_input','api_action_button','proposed_service_team','id']),
                      type: finalProposedTaskType,
                    },
                    error(e) {
                      next(e);
                    },
                    success(res) {
                      updatedTask = res.body;
                      next();
                    },
                    token: true,
                    url,
                  });
                }
              },
              // 2 save related responses
              (next) => {
                if (shouldCopyEmployeeConversation) {
                  saveRelatedResponses(updatedTask, useResponseData)
                    .then(() => {
                      next();
                    })
                    .catch((e) => {
                      next(e);
                    });
                } else {
                  next();
                }
              },
              // 3 save message copy
              (next) => {
                if (shouldCopyEmployeeConversation) {
                  saveMessageCopy(updatedTask, useResponseData)
                    .then(() => {
                      next();
                    })
                    .catch((e) => {
                      next(e);
                    });
                } else {
                  next();
                }
              },
            ],
            (error) => {
              if (error) {
                dispatch(casesActions.updateCaseFromBaristaFail());
                reject(error);
              } else {
                dispatch(casesActions.updateCaseFromBaristaSuccess());
                resolve(updatedTask);
              }
            }
          );
        }
      }),

  viewTask: (taskId) => () =>
    new Promise((resolve, reject) => {
      if (!taskId) {
        reject(new Error('No taskId'));
        return;
      }

      browserHistory.push(
        uiPathGenerator.genPath('app.casesFeed.detail', {
          caseID: taskId,
        })
      );

      resolve();
    }),
};

export default casesThunks;
