import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Molecule
import TenantImage from '../../../../v1/components/molecules/TenantImage';
// Util
import TenantImageTypes from '../../../../v1/utils/TenantImageTypes';

// Display
import BackgroundImagePortal from '../../display/BackgroundImagePortal';
// Controller
import TenantBackgroundImageController from './TenantBackgroundImageController';

class TenantBackgroundImage extends PureComponent {
  static propTypes = {
    background: ImmutablePropTypes.map,
  };

  static defaultProps = {
    background: null,
  };
  render() {
    const { background } = this.props;

    if (!background || background.isEmpty()) {
      return null;
    } else {
      return (
        <BackgroundImagePortal
          height={background.get('height')}
          image={<TenantImage type={TenantImageTypes.BACKGROUND} />}
          width={background.get('width')}
        />
      );
    }
  }
}

export const TenantBackgroundImageTest = TenantBackgroundImage;

export default TenantBackgroundImageController(TenantBackgroundImage);
