import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import faqThunks from '../../actions/faqThunks';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import errorsActions from '../../actions/errorsActions';

const mapDispatchToProps = (dispatch) => ({
  createNewFaqFromBEScratch(updateFAQ = false, fromEntity = false, cb) {
    dispatch(faqThunks.createNewFaqFromBEScratch(updateFAQ, fromEntity))
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: updateFAQ
              ? 'The FAQ has been updated'
              : ' The new FAQ has been created',
            title: 'Success',
          })
        );
        cb(); // Set Next task
      })
      .catch((error) => {
        // eslint-disable-next-line no-console -- debugging
        console.error(error);
      });
  },

  forceResetError(cb) {
    dispatch(errorsActions.forceResetErrors());
    cb(); // Set prev task
  },
});

export default buildCompositeController(null, mapDispatchToProps);
