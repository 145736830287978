import buildCompositeController from '../../../../v1/utils/buildCompositeController';

// Selector
import getAllUsersList from '../../../../v1/selectors/getAllUsersList';
import getCurrentUser from '../../../../v1/selectors/getCurrentUser';

import userEntitiesThunks from '../../../../v1/actions/userEntitiesThunks';
import userEntitiesActions from '../../../../v1/actions/userEntitiesActions';

const limit = 24;

const mapStateToProps = (state) => {
  const pagination = state.getIn(['usersAdmin', 'next']);
  const usersList = getAllUsersList(state);
  const currentUser = getCurrentUser(state);

  return {
    currentUser,
    isLoading: state.getIn(['usersAdmin', 'isLoading']),
    isLoadingSearch: state.getIn(['usersAdmin', 'isSearching']),
    pagination,
    searchTerm: state.getIn(['usersAdmin', 'searchTerm']),
    usersList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isSearching() {
    dispatch(userEntitiesActions.isSearchingUser());
  },

  loadListUsers(
    page = 1,
    userID,
    filterByTeam,
    filterByFavorites,
    getHideFromDirectory,
    resetUsersList,
    typeSearch
  ) {
    /** Offset logic */
    const offset = (page - 1) * limit;
    const orderBy = 'last_name';

    /** Launch search */
    const concate = true; // Concate new added users to the list

    const method = typeSearch ? 'searchTermForCases' : 'searchTermWithFilters'; // Regular Esp User Search

    dispatch(
      userEntitiesThunks[method]({
        concate,
        filterByFavorites,
        filterByTeam,
        getHideFromDirectory,
        limit,
        offset,
        orderBy,
        resetUsersList,
        typeSearch,
        userID,
      })
    );
  },

  resetUsersList() {
    dispatch(userEntitiesActions.resetUsersList());
  },

  setSearchTerm(term) {
    dispatch(userEntitiesActions.setSearchUsersTerm(term));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
