const typingEval = (msg) => Boolean(msg?.type === 'typing');

const typing = {
  evaluate: (msg) => typingEval(msg),
};

const typingEvals = {
  typing: typingEval,
};

export { typingEvals };
export default typing;
