class Node {
  constructor({ stepName, content, validationSchema, initialValues }) {
    this.stepName = stepName;
    this.content = content;
    this.validationSchema = validationSchema;
    this.initialValues = initialValues;
    this.completed = false;
    this.formValues = false;
    this.next = null;
    this.prev = null;
  }
}

class LinkedList {
  constructor() {
    this.head = null;
    this.currentNode = null;
  }

  addNode(node) {
    const newNode = new Node(node);
    if (this.head === null) {
      this.head = newNode;
      this.currentNode = newNode;
    } else {
      let current = this.head;
      while (current.next !== null) {
        current = current.next;
      }
      current.next = newNode;
      newNode.prev = current;
    }
  }

  displayList() {
    if (this.head === null) {
      // eslint-disable-next-line no-console -- debugging purposes
      console.log('Linked list is empty.');
    } else {
      let current = this.head;
      while (current !== null) {
        // eslint-disable-next-line no-console -- debugging purposes
        console.log('Step Name:', current.stepName);
        // eslint-disable-next-line no-console -- debugging purposes
        console.log('Content:', current.content);

        current = current.next;
      }
    }
  }

  getCurrentNode() {
    if (this.currentNode !== null) {
      return this.currentNode;
    } else {
      return null;
    }
  }

  setCurrentNode(stepName) {
    let current = this.head;
    while (current !== null) {
      if (current.stepName === stepName) {
        this.currentNode = current;
        return;
      }
      current = current.next;
    }
  }

  getNextNode() {
    if (this.currentNode !== null) {
      if (this.currentNode.next !== null) {
        this.currentNode = this.currentNode.next;
        return this.currentNode;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getPreviousNode() {
    if (this.currentNode !== null) {
      if (this.currentNode.prev !== null) {
        this.currentNode = this.currentNode.prev;
        return this.currentNode;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  addMultipleNodes(nodes) {
    for (const node of nodes) {
      this.addNode(node);
    }
  }

  getHeadNode() {
    if (this.head !== null) {
      return this.head;
    } else {
      return null;
    }
  }

  getTailNode() {
    if (this.head !== null) {
      let current = this.head;
      while (current.next !== null) {
        current = current.next;
      }
      return current;
    } else {
      return null;
    }
  }

  setTailNodeFormValues(values) {
    if (this.head !== null) {
      let current = this.head;
      while (current.next !== null) {
        current = current.next;
      }
      this.currentNode = current;
      this.currentNode.formValues = values;
      this.currentNode.completed = true;
    }
  }

  isHeadNode(node) {
    return node === this.head;
  }

  isTailNode(node) {
    return node === this.getTailNode();
  }

  setNextNodeAsCurrent(values) {
    if (this.currentNode !== null && this.currentNode.next !== null) {
      this.currentNode.completed = true;
      this.currentNode.formValues = values;
      this.currentNode = this.currentNode.next;
    }
  }

  setPreviousNodeAsCurrent() {
    if (this.currentNode !== null && this.currentNode.prev !== null) {
      this.currentNode.completed = false;
      this.currentNode = this.currentNode.prev;
    }
  }

  getNodesArray() {
    const nodesArray = [];
    let current = this.head;
    while (current !== null) {
      nodesArray.push(current);
      current = current.next;
    }
    return nodesArray;
  }
}

export default LinkedList;
