/**
 * Build Service department/team description
 * @param user {Immutable Map}
 * @returns {string}
 */
const buildUserServiceDepartmentDesc = (user) => {
  if (!user || user.isEmpty() || !user.has('service_departments')) {
    throw new Error('Error User - Service Department property is missing');
  }

  let departmentDesc = '';

  user.get('service_departments').forEach((dep, i) => {
    departmentDesc += i > 0 ? ', ' : '';
    departmentDesc += `${dep.get('department_display_name')}: `;

    const teamList = dep.get('service_teams');
    teamList.forEach((t, ii) => {
      departmentDesc += t.get('team_name');
      departmentDesc += ii < teamList.size - 1 ? ', ' : '';
    });
  });

  return departmentDesc;
};

export default buildUserServiceDepartmentDesc;
