import PropTypes from 'prop-types';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Header, Image, Rating } from 'semantic-ui-react';
import _ from 'lodash';
// utils
import uiPathGenerator from '../../utils/uiPathGenerator';

class EspProductHeader extends React.PureComponent {
  static propTypes = {
    categoryID: PropTypes.number,
    listItem: PropTypes.bool,
    onClose: PropTypes.func,
    price: PropTypes.string,
    productID: PropTypes.number,
    productImage: PropTypes.string,
    rating: PropTypes.number,
    small: PropTypes.bool,
    subcategoryID: PropTypes.number,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    categoryID: 0,
    listItem: false,
    onClose: _.noop,
    price: '',
    productID: 0,
    productImage: '',
    rating: null,
    small: false,
    subcategoryID: 0,
  };

  handleClose = (e) => {
    const { onClose } = this.props;
    if (onClose) {
      onClose(e);
    }
  };

  render() {
    const {
      title,
      price,
      rating,
      productImage,
      small,
      categoryID,
      subcategoryID,
      productID,
      listItem,
    } = this.props;

    return (
      <div className='esp-product-header'>
        {productImage ? (
          <div className='product-image'>
            <Image alt='' src={productImage} />
          </div>
        ) : null}
        <div className={small ? 'title small' : 'title'}>
          <Header
            as={listItem ? NavLink : null}
            content={title}
            onClick={this.handleClose}
            to={
              listItem
                ? uiPathGenerator.genPath('app.catalog.detail', {
                    catalogID: productID,
                    categoryID: categoryID,
                    subcategoryID: subcategoryID,
                  })
                : null
            }
          />
          {price ? <div className='price'>{`$${price}`}</div> : null}
        </div>
        {rating ? (
          <Rating
            disabled
            icon='star'
            maxRating='5'
            rating={rating}
            size={small ? 'mini' : 'large'}
          />
        ) : null}
      </div>
    );
  }
}

export default EspProductHeader;
