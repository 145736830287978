import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { isNumber, isString } from 'lodash';

const getAllLocations = (state) => state.getIn(['entities', 'locations']);

const getLocation = (locationId) => (getAllLocations = Immutable.Map()) => {
  if (isNumber(locationId) || isString(locationId)) {
    return getAllLocations.get(
      isString(locationId) ? parseInt(locationId) : locationId,
      null
    );
  } else {
    throw new Error('Location ID should be a number or a string');
  }
};

const getLocationById = (locationId) =>
  createSelector([getAllLocations], getLocation(locationId));

export { getLocation };

export default getLocationById;
