import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react';

// Utils
import uiPathGenerator from '../../../../../utils/uiPathGenerator';

class IntegrationCSVMapping extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),

    // from React Router 4
    match: PropTypes.shape({
      isExact: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { match, children } = this.props;

    return (
      <Segment attached='bottom'>
        {match.isExact ? (
          <div>
            <Button
              as={Link}
              content='New People Mapping'
              to={uiPathGenerator.genPath(
                'admin.integrations.csv.mapping.people'
              )}
            />
            <Button
              as={Link}
              content='New Asset Mapping'
              to={uiPathGenerator.genPath(
                'admin.integrations.csv.mapping.asset'
              )}
            />
            <Header content='IntegrationCSVMapping (list here)' />
          </div>
        ) : (
          children
        )}
      </Segment>
    );
  }
}

export default IntegrationCSVMapping;
