import { getFormSyncErrors } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import anonResetPasswordThunks from '../../actions/anonResetPasswordThunks';
import anonResetPasswordActions from '../../actions/anonResetPasswordActions';

const mapStateToProps = (state) => {
  return {
    changeViaOPT: state.getIn(['anonResetPassword', 'promptResetPassword']),
    isLoading: state.getIn([
      'anonResetPassword',
      'changePassword',
      'isLoading',
    ]),
    passwordChanged: state.getIn(['anonResetPassword', 'passwordChanged']),
    syncErrors: getFormSyncErrors('ChangePasswordForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeResetPasswordModal() {
    dispatch(anonResetPasswordActions.dimissPromptResetPassword());
  },

  formValidation() {
    const errors = {};
    // We don't do any real validation here since this field is self-validated
    // const password1 = values.get('password1');

    return errors;
  },

  handleSubmit(data) {
    dispatch(anonResetPasswordThunks.changePassword(data));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
