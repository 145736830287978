import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Label,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';
import LocationSelect from '../../../organisms/LocationSelect';
// controllers
import AdminServiceTeamLocationController from '../../../controllers/AdminServiceTeamLocationController';

class AdminServiceTeamLocation extends PureComponent {
  static propTypes = {
    addServiceTeamLocation: PropTypes.func,
    getServiceTeamLocations: PropTypes.func,
    isLoading: PropTypes.bool,
    locations: ImmutablePropTypes.list,
    params: PropTypes.shape({
      teamID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    removeServiceTeamLocation: PropTypes.func,
  };

  static defaultProps = {
    addServiceTeamLocation: noop,
    getServiceTeamLocations: noop,
    isLoading: false,
    locations: Immutable.List(),
    params: {
      teamID: null,
    },
    removeServiceTeamLocation: noop,
  };

  componentDidMount() {
    const {
      params: { teamID },
      getServiceTeamLocations,
    } = this.props;

    if (teamID) {
      getServiceTeamLocations(teamID);
    }
  }

  handleSelectLocation = (location) => {
    const {
      locations,
      params: { teamID },
      addServiceTeamLocation,
    } = this.props;

    if (location.isEmpty()) {
      return;
    }

    const alreadySelected = locations.some(
      (l) => l.get('location') === location.get('id')
    );

    // Do not pass this location if already selected
    if (!alreadySelected && location.get('code') !== 'global') {
      addServiceTeamLocation(teamID, location);
    }
  };

  handleRemoveLocation = memoize(
    curry((locationTeamID, e) => {
      const {
        params: { teamID },
        removeServiceTeamLocation,
      } = this.props;
      removeServiceTeamLocation(teamID, locationTeamID);
    })
  );

  renderAddLocationButton = () => (
    <Button
      className='text'
      content='Add Location'
      floated='right'
      type='button'
    />
  );

  render() {
    const { isLoading, locations } = this.props;

    return (
      <Segment attached='bottom'>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size='large' />
          </Dimmer>
        ) : (
          <Form>
            <Form.Field>
              <LocationSelect
                clearAfterSelection
                onChange={this.handleSelectLocation}
                onSelectClick={this.handleSelectClick}
                renderEmptySelectionTrigger={this.renderAddLocationButton}
                selectOptionRequired
              />
              <label>{'Locations'}</label>
              <Label.Group>
                {locations &&
                  !locations.isEmpty() &&
                  locations.map((location) => {
                    const propsLabel = {
                      color: 'blue',
                      content: location.get('label'),
                      onRemove: this.handleRemoveLocation(location.get('id')),
                      removeIcon: 'delete',
                    };

                    if (locations.size === 1) {
                      delete propsLabel.removeIcon;
                      delete propsLabel.onRemove;
                    }

                    return (
                      <Label {...propsLabel} key={location.get('location')} />
                    );
                  })}
              </Label.Group>
            </Form.Field>
          </Form>
        )}
        <Divider hidden />
      </Segment>
    );
  }
}

export default AdminServiceTeamLocationController(AdminServiceTeamLocation);
