import { Button, Modal, Select } from 'semantic-ui-react';
import { curry, first, memoize, noop, size } from 'lodash';
import React, { PureComponent } from 'react';

import browserHistory from '../../utils/browserHistory';
// Controllers
import ChatBaristaController from '../controllers/ChatBaristaController';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

const defaultDisableButtonTimeout = 10000;

class ChatBaristaInput extends PureComponent {
  static propTypes = {
    baristaMessage: ImmutablePropTypes.map.isRequired,
    caseTaskId: PropTypes.number,
    /** passed directly. If passed then it's assumed this chat belongs to a case **/
    exitBaristaSupportChannel: PropTypes.func,
    isCaseMgmt: PropTypes.bool,
    markAsRead: PropTypes.func,
    onUserSelection: PropTypes.func,
    supportChannelStart: PropTypes.bool,
  };

  static defaultProps = {
    caseTaskId: null,
    exitBaristaSupportChannel: noop,
    isCaseMgmt: false,
    markAsRead: noop,
    onUserSelection: noop,
    supportChannelStart: false,
  };

  state = {
    // this will turn true when the button is clicked
    isLoading: false,
  };

  componentWillUnmount() {
    if (document.documentElement) {
      document.documentElement.removeAttribute('data-keyboard-open');
    }
  }

  handleSelectBlur = () => {
    if (document.documentElement) {
      document.documentElement.setAttribute('data-keyboard-open', false);
    }
    if (document.getElementById('ChatForm')) {
      document.getElementById('ChatForm').removeAttribute('style');
    }
  };

  handleSelect = (event, data) => {
    const { baristaMessage, markAsRead, onUserSelection } = this.props;
    // As per DEV-3373 for some reason in cordova this event handler is called twice
    // the first is called with an event type "blur"
    // So we only want to do this when the event type is click

    const setData = () => {
      const selection = baristaMessage
        .getIn(['metadata', 'user_input', 'select'])
        .find(
          (sel) => sel.get('pk') === data.value || sel.get('eid') === data.value
        );
      onUserSelection(selection.toJS(), 'select', baristaMessage);

      // mark as reads when this happens
      markAsRead();
    };

    setData();
  };

  handleApiButtonClick = (event) => {
    event.preventDefault();

    const { onUserSelection, baristaMessage } = this.props;

    this.temporaryDisableButtons();
    onUserSelection(null, '', baristaMessage);
  };

  handleExitBaristaSupportChannel = () => {
    const { exitBaristaSupportChannel, markAsRead } = this.props;

    let navigateToURL = '';
    const baristaAppUrl = uiPathGenerator.genPath('app.barista');
    const currentUrl = browserHistory.location.pathname;
    // redirect to toDo only when closed in the barista standalone url
    if (currentUrl && currentUrl.indexOf(baristaAppUrl) === 0) {
      navigateToURL = uiPathGenerator.genPath('app.toDo');
    }

    exitBaristaSupportChannel(navigateToURL);
    // mark as reads when this happens
    markAsRead();
  };

  handleButtonClick = memoize(
    curry((option, e) => {
      const { onUserSelection, markAsRead } = this.props;
      onUserSelection(option.toJS(), 'confirm');
      this.setState({
        isLoading: true,
      });

      // mark as reads when this happens
      markAsRead();
    })
  );

  /**
   * DEV-10667: prevent creating double linked task
   * by disabling button for *localDisableTimeout* seconds
   * after first click.
   *
   * @param {Number} duration The amount of time in seconds the buttons will be stay disabled for
   */
  temporaryDisableButtons = (duration = defaultDisableButtonTimeout) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        setTimeout(
          () =>
            this.setState({
              isLoading: false,
            }),
          duration
        );
      }
    );
  };

  render() {
    const { baristaMessage, caseTaskId, isCaseMgmt, supportChannelStart } =
      this.props;
    const { isLoading } = this.state;

    let userInputType = '';
    let selectOptions;
    let defaultSelectOption = null;

    const SELECT_PLACEHOLDER = intl.formatMessage({
      id: 'label.choose_an_option',
    });

    const userInput = baristaMessage.getIn(['metadata', 'user_input']);
    if (userInput.get('select')) {
      userInputType = 'select';
      selectOptions = userInput
        .get('select')
        .map((sel) => ({
          key: sel.get('pk') || sel.get('eid'),
          text: sel.get('label'),
          value: sel.get('pk') || sel.get('eid'),
        }))
        .toJS();

      if (size(selectOptions) === 1) {
        const firstSelectOption = first(selectOptions);
        defaultSelectOption = firstSelectOption.value;
      }
    } else if (userInput.get('confirm')) {
      userInputType = 'confirm';
    } else if (userInput.get('reopen')) {
      userInputType = 'reopen';
    } else if (userInput.get('task_created') && supportChannelStart) {
      userInputType = 'task_created';
    } else if (userInput.get('dismiss')) {
      userInputType = 'dismiss';
    } else if (userInput.get('api_action_button')) {
      userInputType = 'api_action_button';
    }

    return (
      <Modal.Actions className='barista'>
        {userInputType === 'select' && (
          <Select
            className='upward'
            data-barista-button={userInputType}
            data-testid='chat-barista-input-select'
            defaultValue={defaultSelectOption}
            disabled={Boolean(caseTaskId)}
            fluid
            onChange={this.handleSelect}
            options={selectOptions}
            placeholder={SELECT_PLACEHOLDER}
            search={selectOptions.length > 5}
            selectOnBlur={false}
            size='large'
          />
        )}

        {userInputType === 'confirm' &&
          userInput
            .get('confirm')
            .map((confirmOption) => (
              <Button
                basic
                content={confirmOption.get('label')}
                data-barista-button={userInputType}
                disabled={isLoading || (Boolean(caseTaskId) && isCaseMgmt)}
                key={`${confirmOption.get('pk')}.${confirmOption.get(
                  'original'
                )}`}
                onClick={this.handleButtonClick(confirmOption)}
              />
            ))}

        {userInputType === 'reopen' && ( // TODO: Hook this up after demo to reopen the task
          <Button
            basic
            content='Reopen This Issue'
            data-barista-button={userInputType}
            disabled={isLoading || Boolean(caseTaskId)}
            primary
            size='large'
          />
        )}

        {userInputType === 'task_created' && (
          <Button
            basic
            content='Okay'
            data-barista-button={userInputType}
            disabled={isLoading || Boolean(caseTaskId)}
            onClick={this.handleExitBaristaSupportChannel}
            primary
            size='large'
          />
        )}

        {userInputType === 'dismiss' && (
          <Button
            basic
            content={userInput.getIn(['dismiss', 'label'])}
            data-barista-button={userInputType}
            disabled={isLoading || Boolean(caseTaskId)}
            onClick={this.handleExitBaristaSupportChannel}
            primary
            size='large'
          />
        )}

        {userInputType === 'api_action_button' && (
          <Button
            basic
            content={userInput.getIn(['api_action_button', 'label'])}
            data-barista-button={userInputType}
            disabled={isLoading}
            onClick={this.handleApiButtonClick}
            primary
            size='large'
          />
        )}
      </Modal.Actions>
    );
  }
}

export default ChatBaristaController(ChatBaristaInput);
