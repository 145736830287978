import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from './PhoneInput';
import { Field } from 'redux-form/immutable';

/**
 * Wrapper for PhoneInput in a redux-form field
 */

const propTypes = {
  name: PropTypes.string.isRequired,
};
const ValidatedPhone = ({ ...props }) => (
  <Field {...props} component={PhoneInput} />
);
ValidatedPhone.propTypes = propTypes;
export default ValidatedPhone;
