import { change } from 'redux-form/immutable';
import Immutable from 'immutable';
import _ from 'lodash';

import buildCompositeController from '../../utils/buildCompositeController';

import announcementThunks from '../../actions/announcementThunks';
import locationThunks from '../../actions/locationsThunks';
import departmentsThunks from '../../actions/departmentsThunks';
// Selector
import getWiredDepartmentsByID from '../../selectors/getWiredDepartmentsByID';
import getWiredLocationsByID from '../../selectors/getWiredLocationsByID';
import getJobRoleWithoutAll from '../../selectors/getJobRoleWithoutAll';

const mapStateToProps = (state, ownProps) => {
  const jobRoles = getJobRoleWithoutAll(state);
  const departments = state.getIn(['announcement', 'departments']);

  const departmentNodesByID = getWiredDepartmentsByID(state);
  const locationNodesByID = getWiredLocationsByID(state);

  const isLoadingGroup =
    state.getIn(['onboardActivity', 'schedule', 'isLoadingLocation']) ||
    state.getIn(['onboardActivity', 'schedule', 'isLoadingDepartment']);

  const isLoadedGroup = state.getIn([
    'onboardActivity',
    'schedule',
    'isLoadedGroup',
  ]);
  const isLoadingSchedule = state.getIn([
    'onboardActivity',
    'schedule',
    'isLoading',
  ]);

  const groupLocations =
    ownProps.formValues.get('locations') || Immutable.List();
  const groupDepartments =
    ownProps.formValues.get('departments') || Immutable.List();
  const groupJobRoles =
    ownProps.formValues.get('job_roles') || Immutable.List();

  return {
    departmentNodesByID,
    departments,
    groupDepartments,
    groupJobRoles,
    groupLocations,
    isLoadedGroup,
    isLoadingGroup,
    isLoadingSchedule,
    jobRoles,
    locationNodesByID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeGroupField(newGroupVals, formMember, idSchedule, type) {
    const groupsType = `${formMember}.${type}`;
    const finalValue = _.isArray(newGroupVals)
      ? Immutable.List(newGroupVals)
      : newGroupVals;

    dispatch(
      change(`ScheduleActivityForm_${idSchedule}`, groupsType, finalValue)
    );
  },

  getDefaultSelectedDepartment(selectedNodeID) {
    return dispatch(departmentsThunks.getDefaultSelectedNode(selectedNodeID));
  },

  getDefaultSelectedLocation(selectedNodeID) {
    return dispatch(locationThunks.getDefaultSelectedNode(selectedNodeID));
  },

  loadDepartments: () => {
    dispatch(announcementThunks.loadDepartments());
  },

  loadJobRoles: () => {
    dispatch(announcementThunks.loadJobRoles());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
