import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsRatings extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M42.77 39.08l-7.43-.43c2.49-4.15 2.16-7.31 2.16-7.31-.32-5.6-4.92-4.8-4.92-4.8-2.74.4-2.84 5.22-2.84 5.22-1 9.23-9.15 10.24-9.15 10.24v17.32c7.75 3.66 12.16 2.91 12.16 2.91 6.14 1.29 8.4-1.08 8.4-1.08 1.294-1 2.02-2.567 1.94-4.2 1.43-1.427 1.894-3.557 1.19-5.45 1.244-1.48 1.46-3.57.54-5.27 2.15-5.54-2.05-7.15-2.05-7.15zM62.89 33.5c-6.14-1.3-8.39 1.07-8.39 1.07-1.294 1-2.02 2.567-1.94 4.2-1.442 1.437-1.904 3.587-1.18 5.49-1.234 1.47-1.452 3.545-.55 5.24-2.15 5.49 2 7.1 2 7.1l7.43.43c-2.49 4.2-2.17 7.31-2.17 7.31.32 5.6 4.92 4.8 4.92 4.8 2.74-.4 2.84-5.22 2.84-5.22 1-9.23 9.15-10.24 9.15-10.24V36.4c-7.7-3.66-12.11-2.9-12.11-2.9zM15.15 39.35H11.5c-1.105 0-2 .895-2 2v18.78c0 1.105.895 2 2 2h3.65c1.105 0 2-.895 2-2V41.35c0-1.105-.895-2-2-2zm-2.14 4.93c-.728 0-1.384-.44-1.663-1.11-.28-.674-.125-1.448.39-1.963.515-.515 1.29-.67 1.962-.39.67.28 1.11.935 1.11 1.663 0 .994-.806 1.8-1.8 1.8zM84.23 33.59h-3.65c-1.105 0-2 .895-2 2v18.78c0 1.105.895 2 2 2h3.65c1.105 0 2-.895 2-2V35.59c0-1.105-.895-2-2-2zM82.4 54.5c-.728 0-1.384-.44-1.663-1.11-.28-.674-.125-1.448.39-1.963.515-.515 1.29-.67 1.962-.39.67.28 1.11.935 1.11 1.663 0 .994-.806 1.8-1.8 1.8z' />
        </g>
      </svg>
    );
  }
}

export default CardsRatings;
