import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import SocialMediaInput from './SocialMediaInput';
import Immutable from 'immutable';

import { Button, Form } from 'semantic-ui-react';
import { curry, filter, find, memoize } from 'lodash';

// globals
import SocialMedias, { MediaTypes } from '../../globals/SocialMedias';

/**
 * Generates a collection of SocialMediaInput to be used in ArrayField
 */

class SocialMediaArray extends PureComponent {
  static propTypes = {
    defaultNetwork: PropTypes.string,
    disabledOptions: PropTypes.arrayOf(PropTypes.string),
    fields: PropTypes.shape({
      forEach: PropTypes.func,
      get: PropTypes.func,
      length: PropTypes.number,
      map: PropTypes.func,
      name: PropTypes.string,
    }).isRequired, // ReduxForm 'field' prop coming from FieldArray
    label: PropTypes.string,
    mediaType: PropTypes.oneOf([MediaTypes.SOCIAL, MediaTypes.COLLAB]),
    multiple: PropTypes.bool,
    noNetworkElegibility: PropTypes.bool,
    showOneByDefault: PropTypes.bool,
  };

  static defaultProps = {
    defaultNetwork: 'slack',
    disabledOptions: [],
    label: '',
    mediaType: MediaTypes.COLLAB,
    multiple: true,
    noNetworkElegibility: false,
    showOneByDefault: true,
  };

  componentDidMount() {
    const { showOneByDefault, fields } = this.props;
    if (showOneByDefault && fields.length === 0) {
      this.addNewField(fields);
    }
  }

  addNewField = memoize(
    curry((fields, e) => {
      const { disabledOptions, defaultNetwork, mediaType } = this.props;

      let selectedMedia;

      const socialMediasElegible = filter(
        SocialMedias[mediaType],
        (option) => !(disabledOptions.indexOf(option.value) > -1)
      );

      if (fields.length === 0) {
        selectedMedia = find(
          socialMediasElegible,
          (option) => option.value === defaultNetwork
        );
      } else {
        const selectedFields = fields.map((member, index, fields) =>
          fields.get(index).get('network')
        );
        selectedMedia = filter(
          socialMediasElegible,
          (option) => !(selectedFields.indexOf(option.value) > -1)
        ).pop();
      }

      if (selectedMedia) {
        fields.push(
          Immutable.Map({
            network: selectedMedia.value,
            username: '',
          })
        );
      } else {
        throw new Error(
          `No social media found for type ${mediaType}. Using defaultNetwork ${defaultNetwork}`
        );
      }
    })
  );

  render() {
    const {
      disabledOptions,
      fields,
      label,
      mediaType,
      multiple,
      noNetworkElegibility,
    } = this.props;

    const usedFields = [];
    let noMoreOptionsLeft = false;

    return (
      <Form.Field>
        <label>{label}</label>
        {fields.map((name, index) => {
          // Makes sure that the default option is not selected in the other fields
          // and also makes disabledOptions include any other selected
          const fieldNetwork = fields.get(index).get('network');
          usedFields.push(fields.get(index).get('network'));

          const foolLint = index;
          const concatDisabledOptions = disabledOptions.concat(usedFields);

          if (concatDisabledOptions.length === SocialMedias[mediaType].length) {
            noMoreOptionsLeft = true;
          }

          return (
            <SocialMediaInput
              defaultOption={fieldNetwork}
              disabledOptions={concatDisabledOptions}
              key={foolLint}
              mediaType={mediaType}
              name={name}
              noNetworkElegibility={noNetworkElegibility}
            />
          );
        })}

        {!noMoreOptionsLeft && multiple ? (
          <Button onClick={this.addNewField(fields)} type='button'>
            {'Add account'}
          </Button>
        ) : null}
      </Form.Field>
    );
  }
}

export default SocialMediaArray;
