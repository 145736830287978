import taskThunks from '../../../../../admin/js/v1/actions/taskThunks';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => ({
  form: state.getIn(['form', 'WorkflowTaskForm']),
  initialValues: {
    name: state.getIn(['editor', 'currentEditingTask', 'name']),
  },
  isLoading: state.getIn(['saveStatus', 'workflowFormSaveInProgress']),
  task: state.getIn(['editor', 'currentEditingTask']),
});

const mapDispatchToProps = (dispatch) => ({
  updateEditingTaskName: (task, name) =>
    dispatch(taskThunks.updateTaskName(task, name)),
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
