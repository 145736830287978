import { List } from 'immutable';

import { createSelector } from 'reselect';

const jobRoles = (state) => {
  const jobs = state.getIn(['announcement', 'jobRoles']);
  return jobs || List();
};

const getFilteredJobRoles = (jobRoles) =>
  jobRoles.filter((jobRole) => jobRole.get('value') !== null);

export default createSelector([jobRoles], getFilteredJobRoles);
