const api_catalog_v0_1_product = {
  count: 1,
  next: 'http://dpc.esp:8000/api/catalog/v0.1/product/?limit=1&offset=26',
  previous: 'http://dpc.esp:8000/api/catalog/v0.1/product/?limit=1&offset=24',
  results: [
    {
      brand_name: 'Apple',
      cost: '0.00',
      id: 1,
      images: [],
      name: 'Apple iPhone 7',
      recurring_price: '0.00',
    },
  ],
};

export default api_catalog_v0_1_product;
