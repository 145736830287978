import beautifier from 'fela-beautifier';
import { createRenderer } from 'fela';
import dynamicPrefixer from 'fela-plugin-dynamic-prefixer';
import embedded from 'fela-plugin-embedded';
import extend from 'fela-plugin-extend';
import fallbackValue from 'fela-plugin-fallback-value';
import lvha from 'fela-plugin-lvha';
import monolithic from 'fela-monolithic';
import validator from 'fela-plugin-validator';

const Renderer = () => {
  const view = createRenderer({
    enhancers: process.env.NODE_ENV !== 'production' && [
      // perf(),
      monolithic(),
      beautifier(),
    ],

    plugins:
      process.env.NODE_ENV === 'production'
        ? [
            embedded(),
            extend(),
            dynamicPrefixer(),
            fallbackValue(),
            // namedMediaQuery(),
            // important(),
            lvha(),
          ]
        : [
            embedded(),
            extend(),
            dynamicPrefixer(),
            fallbackValue(),
            // namedMediaQuery(),
            lvha(),
            validator({
              useCSSLint: true,
            }),
            // important(),
            // logger(),
          ],

    selectorPrefix: 'esp-',
  });

  return view;
};

export default Renderer;
