import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const rule = (props) => {
  const { theme } = props;

  return {
    alignSelf: 'center',
    paddingRight: theme.bubblePadding,
  };
};

class ChatMessageCheckbox extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checked: false,
  };

  handleClick = () => {
    const { onCheckboxClick } = this.props;
    onCheckboxClick();
  };

  render() {
    const { checked, className } = this.props;

    return (
      <div className={className} data-component='ChatMessageCheckbox'>
        <Checkbox checked={checked} onClick={this.handleClick} />
      </div>
    );
  }
}

export default createComponent(rule, ChatMessageCheckbox, [
  'checked',
  'onCheckboxClick',
]);
