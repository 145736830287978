import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Button, Dimmer, Header, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import _ from 'lodash';

// Controller
import EditCommentController from '../../controllers/EditCommentController';

// Atom
import UserImage from '../../atoms/UserImage';

// Molecule
import FormInputTextarea from '../../molecules/FormInputTextarea';
import FormInputCheckbox from '../../molecules/FormInputCheckbox';

// Organisms
import BlockModal from '../../organisms/BlockModal';

// Blocks
import Conversation01 from '../conversation/Conversation01';

// Globals
import TypeEditComment, {
  TypeDefaults,
} from '../../../globals/blocks/TypeEditComment';

const charLimit = 300;
const minCharacters = 10;
const TIMEOUT = 600;

class EditComment01 extends Component {
  static propTypes = {
    ...TypeEditComment,

    isLoading: PropTypes.bool,
    messageApproved: PropTypes.bool,
    modalCommentText: PropTypes.string,
    onChangeMessage: PropTypes.func.isRequired,

    selectUser: PropTypes.func.isRequired,

    selectedUser: ImmutablePropTypes.map,
    setAlternateSubmit: PropTypes.func.isRequired,
    setEntryDone: PropTypes.bool,
    textAreaValue: PropTypes.objectOf(PropTypes.string),
    userRequestedFor: PropTypes.number.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    isLoading: false,
    messageApproved: false,
    modalCommentText: null,
    selectedUser: Immutable.Map(),
    setEntryDone: false,
    textAreaValue: null,
  };

  state = {
    error: '',
    loadDimmer: false,
  };

  componentDidMount() {
    const { onChangeMessage, selectedUser, selectUser, userRequestedFor, id } =
      this.props;

    // Check if we have to reload new data for the requested_for user
    const reloadUsrSelected =
      !selectedUser ||
      selectedUser.isEmpty() ||
      selectedUser.get('id') !== userRequestedFor;

    if (reloadUsrSelected) {
      selectUser(userRequestedFor);
    }

    // Change to true the message_approved attribute - then if the user click on next, set_entry will send true

    onChangeMessage(true, `${id}-2`);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.setEntryDone && this.reviseModal && this.editModal) {
      this.reviseModal.close();
      this.editModal.close();

      this.setState({
        loadDimmer: true,
      });
    }
  }

  reviseModal = null;
  editModal = null;

  setBlockEditModal = (block) => {
    this.editModal = block;
  };

  setBlockReviseModal = (block) => {
    this.reviseModal = block;
  };

  handleMessageApproved = () => {
    const { onChangeMessage, id } = this.props;

    onChangeMessage(true, `${id}-2`);
    this.setState({
      error: '',
    });
  };

  handleMessageNotApproved = () => {
    const { onChangeMessage, id } = this.props;

    onChangeMessage(false, `${id}-2`);
    this.setState({
      error: '',
    });
  };

  handleUpdateCharLength = (e, newValue) => {
    const { onChangeMessage } = this.props;
    const { error } = this.state;
    const value = newValue.replace(/^\s+/g, '');
    const count = value.length;
    e.preventDefault();

    if (count <= charLimit) {
      onChangeMessage(value, e.target.name);

      if (error) {
        // Reset error msg anytime a new character is added
        this.setState({
          error: '',
        });
      }

      if (count < minCharacters) {
        // Check if we have to set an error for the character minimum value
        this.checkMinCharacters(e.target.name);
      }
    }
  };

  checkMinCharacters = _.debounce((idValue) => {
    const { textAreaValue } = this.props;
    const { error } = this.state;

    const count = textAreaValue[idValue].length;

    if (count < minCharacters && !error) {
      const msg = intl.formatMessage(
        {
          id: 'message.characters_minimum',
        },
        {
          number: minCharacters,
        }
      );

      this.setState({
        error: msg,
      });
    }
  }, TIMEOUT);

  /**
   *
   * Always crops text to the limit size in case of copy and paste
   */
  textCrop(value) {
    return value.substr(0, charLimit);
  }

  render() {
    const {
      askToReviseButtonText,
      editCommentButtonText,
      id,
      isLoading,
      modalButtonText,
      modalChatImage,
      modalChatText,
      modalCommentText,
      modalTitle,
      placeholderAskEditTextModal,
      selectedUser,
      template,
      type,
      textAreaValue,
    } = this.props;

    const introFullName = selectedUser
      ? `${selectedUser.get('full_name')} Intro`
      : '';
    const first_name = selectedUser ? selectedUser.get('first_name') : '';
    const last_name = selectedUser ? selectedUser.get('last_name') : '';
    const nickname = selectedUser ? selectedUser.get('nickname') : '';
    const idUser = selectedUser ? selectedUser.get('id') : '0';

    const conversationAskReviseBlock = {
      id: '2429d4msg',
      messages: [
        {
          id: '000',
          text: modalCommentText || 'No intro message has been written',
          type: 'message',
          user_id: idUser.toString(),
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: first_name,
          id: idUser.toString(),
          imageUrl: null,
          last_name: last_name,
          nickname: nickname,
          realUser: true,
        },
      ],
    };
    const conversationEditThisBlock = {
      id: '2429d4msg',
      messages: [
        {
          id: '000',
          text: modalChatText,
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          imageUrl: modalChatImage,
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    const charLengthEdit = textAreaValue[`${id}-1`].length;
    const charLengthRevise = textAreaValue[`${id}-0`].length;

    const askToRevise = (
      <Segment
        className='link'
        data-test-id='ask-to-revise-intro-button'
        textAlign='center'
      >
        {askToReviseButtonText}
      </Segment>
    );
    const editThis = (
      <Segment basic className='link' textAlign='center'>
        {editCommentButtonText}
      </Segment>
    );

    const modalActionsRevise = (
      <Button
        content={modalButtonText}
        disabled={charLengthRevise < minCharacters}
        loading={isLoading}
        primary
        size='large'
      />
    );

    const modalActionsEdit = (
      <Button
        content={modalButtonText}
        disabled={charLengthEdit < minCharacters}
        loading={isLoading}
        primary
        size='large'
      />
    );

    const { error, loadDimmer } = this.state;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Dimmer active={loadDimmer} inverted />

        <BlockModal
          modalActions={modalActionsRevise}
          modalTitle={modalTitle}
          onClose={this.handleMessageApproved}
          onOpen={this.handleMessageNotApproved}
          ref={this.setBlockReviseModal}
          trigger={askToRevise}
        >
          <div className='esp-blocks'>
            <div className='block'>
              <Conversation01 {...conversationAskReviseBlock} />
            </div>
            <div className='block'>
              <Field
                autoComplete='off'
                autoCorrect='off'
                // eslint-disable-next-line jsx-a11y/no-autofocus -- Legacy component, Pat asked for autofocus
                autoFocus
                charLimit={charLimit}
                component={FormInputTextarea}
                formError={error}
                hideLabel
                id={`${id}-0`}
                label={`${id}-0`}
                name={`${id}-0`}
                normalize={this.textCrop}
                onChange={this.handleUpdateCharLength}
                placeholder={placeholderAskEditTextModal}
                value=''
              />
              <Field
                autoComplete='off'
                autoCorrect='off'
                component={FormInputCheckbox}
                hidden
                hideLabel
                id={`${id}-2`}
                label={`${id}-2`}
                name={`${id}-2`}
              />
            </div>
          </div>
        </BlockModal>
        <BlockModal
          modalActions={modalActionsEdit}
          modalTitle={modalTitle}
          onOpen={this.handleMessageApproved}
          ref={this.setBlockEditModal}
          trigger={editThis}
        >
          <div className='esp-blocks'>
            <div className='block'>
              <Conversation01 {...conversationEditThisBlock} />
            </div>
            <div className='block'>
              <Segment attached='top'>
                <Header as='h4'>
                  <UserImage avatar user={selectedUser} />
                  {introFullName}
                </Header>
              </Segment>
              <Field
                autoComplete='off'
                autoCorrect='off'
                // eslint-disable-next-line jsx-a11y/no-autofocus -- Legacy component, Pat asked for autofocus
                autoFocus
                charLimit={charLimit}
                component={FormInputTextarea}
                formError={error}
                hideLabel
                id={`${id}-1`}
                label={`${id}-1`}
                name={`${id}-1`}
                normalize={this.textCrop}
                onChange={this.handleUpdateCharLength}
              />
            </div>
          </div>
        </BlockModal>
      </div>
    );
  }
}
const EditComment01Test = EditComment01;

// eslint-disable-next-line no-class-assign -- DEV-1526
EditComment01 = EditCommentController(EditComment01);

export { EditComment01Test };
export default EditComment01;
