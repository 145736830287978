// Utils
import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import endpointGenerator from '../../../../v1/utils/endpointGenerator';

// Actions
import wsActions from '../../../../v1/actions/wsActions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUserSelection: (selectedOption) => {
    const { channelId } = ownProps;
    if (channelId) {
      const wsMessage = {
        body: {
          channel_id: channelId,
          metadata: {
            select: selectedOption,
          },
          text: selectedOption.label,
        },
        request: endpointGenerator.genPath('espChat.messages'),
      };

      dispatch(wsActions.send(wsMessage));
    }
  },
});
export default buildCompositeController(mapStateToProps, mapDispatchToProps);
