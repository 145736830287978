import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Button, Label, Modal } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';

// atoms
import ScrollArea from '../atoms/ScrollArea';
import EspIcon from '../atoms/EspIcon';
// molecules
import CartList from '../molecules/CartList';
// Controller
import CartController from '../controllers/CartController';

import ContextNames from '../../globals/ContextNames';

class CartModal extends PureComponent {
  static propTypes = {
    // cartRemoveProduct : React.PropTypes.func.isRequired,
    // cartTotal       : React.PropTypes.number,
    // cartUpdateProduct : React.PropTypes.func.isRequired,
    cartItems: ImmutablePropTypes.list,
    cartLoad: PropTypes.func.isRequired,
    cartNumItems: PropTypes.number,
    cartSubmitOrder: PropTypes.func.isRequired,
    context: PropTypes.string,
    isLoading: PropTypes.bool,
    modalOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    successOTPSend: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cartItems: Immutable.List(),
    cartNumItems: 0,
    context: '',
    isLoading: false,
    modalOpen: false,
  };

  componentDidMount() {
    const { context, cartLoad } = this.props;
    // load only in these two condition
    if (
      context === ContextNames.CATALOG ||
      context === ContextNames.CATALOG_FULL_RESULTS
    ) {
      cartLoad();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { context } = this.props;
    if (context !== nextProps.context) {
      if (
        nextProps.context === ContextNames.CATALOG ||
        nextProps.context === ContextNames.CATALOG_FULL_RESULTS
      ) {
        nextProps.cartLoad();
      }
    }
  }

  handleSubmitCart = () => {
    const {
      cartItems,
      cartSubmitOrder,

      isLoading,
      onClose,
      successOTPSend,
    } = this.props;

    // Make sure the Cart is not in a loading state before to submit an order
    if (!isLoading) {
      cartSubmitOrder(() => {
        // Close the modal
        onClose();

        // Send the success notification to the snackbar
        successOTPSend(
          intl.formatMessage({
            id: 'message.success_order',
          }),
          intl.formatMessage({
            id: 'message.order_submitted',
          })
        );
      }, cartItems.size);
    }
  };

  render() {
    const {
      cartNumItems,
      context,
      modalOpen,
      onClose,
      onOpen,
      cartItems,

      isLoading,
    } = this.props;

    if (
      context !== ContextNames.CATALOG &&
      context !== ContextNames.CATALOG_FULL_RESULTS
    ) {
      return null; // Not in CATALOG context
    }

    const isCartEmpty = !(cartItems.size > 0);
    const disableSubmitOrder = isCartEmpty || isLoading;

    const closeIcon = (
      <div className='item close'>
        {intl.formatMessage({
          id: 'label.close',
        })}
      </div>
    );

    const cartTrigger = (
      <div className='header-cart-modal'>
        <Button basic className='transparent' icon onClick={onOpen}>
          <EspIcon name='cart' />
          {cartNumItems && cartNumItems > 0 ? (
            <Label content={cartNumItems} floating />
          ) : null}
        </Button>
      </div>
    );

    return (
      <Modal
        className='scrolling'
        closeIcon={closeIcon}
        closeOnDimmerClick={false}
        onClose={onClose}
        open={modalOpen}
        size='small'
        trigger={cartTrigger}
      >
        <div className='ui form'>
          <Modal.Header
            content={intl.formatMessage({
              id: 'label.Cart',
            })}
          />
          <Modal.Content className='withFooter'>
            <ScrollArea padding={0}>
              <CartList handleClose={onClose} isLink />
            </ScrollArea>
          </Modal.Content>

          <Modal.Actions>
            <Button
              content={intl.formatMessage({
                id: 'label.submit_order',
              })}
              disabled={disableSubmitOrder}
              loading={isLoading}
              onClick={this.handleSubmitCart}
              primary
            />
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

const CartModalTest = CartModal;

// eslint-disable-next-line no-class-assign -- DEV-1526
CartModal = CartController(CartModal);
export { CartModalTest };
export default CartModal;
