import { change, isPristine } from 'redux-form/immutable';

// Thunks
import onboardActivityThunks from '../../actions/onboardActivityThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';
// Global
import fieldNameTemplateModal, {
  fieldName as valueCheck,
} from '../../globals/fieldNameTemplateModal';

const mapStateToProps = (state, ownProps) => {
  const intervalID = ownProps.intervalID || 'new';
  const formName = `ScheduleTemplateForm_${intervalID}`;

  const formError = state.getIn(['form', formName, 'syncErrors']);
  const isLoading = state.getIn(['onboardActivity', 'schedule', 'isLoading']);

  return {
    formError,
    formValue: state.getIn(['form', formName, 'values']),
    isLoading,
    isPristine: isPristine(formName)(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeCheckValue(nameSelected, intervalID) {
    const formName = `ScheduleTemplateForm_${intervalID}`;

    // Unselected other Checkbox
    fieldNameTemplateModal.forEach((name) => {
      if (name !== nameSelected) {
        dispatch(change(formName, name, false));
      }
    });

    dispatch(change(formName, nameSelected, true));
  },

  formValidation(value) {
    const day_after_selected = value.get('day_after_selected');
    const day_after = value.get('day_after');
    const first_day_selected = value.get('first_day_selected');
    const day_before_selected = value.get('day_before_selected');
    const day_before = value.get('day_before');

    if (day_after_selected) {
      if (!day_after) {
        return {
          day_after: '',
        }; // return error with empty string so we just disable the ADD button
      }
      if (day_after === '0' || Number(day_after) < 0) {
        return {
          day_after: 'Should be superior to 0',
        };
      }
      if (isNaN(Number(day_after))) {
        return {
          day_after: 'Should be number',
        };
      }
    }

    if (day_before_selected) {
      if (!day_before) {
        return {
          day_before: '',
        }; // return error with empty string so we just disable the ADD button
      }
      if (day_before === '0' || Number(day_before) < 0) {
        return {
          day_before: 'Should be superior to 0',
        };
      }
      if (isNaN(Number(day_before))) {
        return {
          day_before: 'Should be number',
        };
      }
    }

    if (!day_after_selected && !first_day_selected && !day_before_selected) {
      return 'error';
    }

    return {};
  },

  handleSubmit(value, intervalID, templateID, scheduleID, action, msg, cb) {
    let fieldName, valueTopass;

    fieldNameTemplateModal.forEach((field, i) => {
      if (value.get(field)) {
        fieldName = valueCheck[i];
        valueTopass = value.get(valueCheck[i]);
      }
    });

    const sendNotification = (exist) => {
      // Display a SnackBar notification msg
      if (exist) {
        // Todo - Use intl translation here
        dispatch(
          toastNotificationsActions.error({
            message: 'This template with this interval already exists',
            title: 'Error',
          })
        );
      } else {
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        cb();
      }
    };
    if (intervalID !== 'new') {
      const isNew = scheduleID === 'new' || action === 'copy'; // Upate a new SCHEDULE whichi is still not saved
      // Update the activity interval
      dispatch(
        onboardActivityThunks.updateActivityInterval(
          fieldName,
          valueTopass,
          intervalID,
          templateID,
          isNew
        )
      ).then((exist) => {
        sendNotification(exist && exist.alreadyExist);
      });
    } else {
      // Create a new activity interval
      dispatch(
        onboardActivityThunks.createActivityInterval(
          fieldName,
          valueTopass,
          templateID,
          scheduleID,
          action
        )
      ).then((exist) => {
        sendNotification(exist && exist.alreadyExist);
      });
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
