import React from 'react';
import pt from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import Widget, { propTypes as widgetPT } from './Widget';
import { AXIS_CONFIG, CHART_DEFAULTS } from './widgetConfig';
import { getDataState } from './dataState';
import Loading from '../../../atoms/Loading/Loading';

const propTypes = {
  ...widgetPT,
  /** Can have a bottom axis label */
  axisBottomLabel: pt.string,
  /** Can have a left axis label */
  axisLeftLabel: pt.string,
  /** Data to display in a widget */
  data: pt.oneOfType([pt.array, pt.object]),
  /** The unique value to index by on `data` */
  indexBy: pt.string,
  isLoading: pt.bool,

  /** Values to show from `data` */
  keys: pt.arrayOf(pt.string),
  /** The value to use for displaying bar labels */
  label: pt.string,
  /** Direction to orient the bar */
  layout: pt.oneOf(['horizontal', 'vertical']),
};

/**
 * Widget for @nivo/bar.
 */
const WidgetBar = ({
  axisBottomLabel,
  axisLeft,
  axisLeftLabel,
  data,
  indexBy = 'id',
  keys,
  label = 'value',
  layout = 'vertical',
  isLoading,
  tickRotation = 0,
  marginBottom,
  bottomLegendOffset,
  ...rest
}) => {
  // TODO: Throw an error if no indexBy prop is set and there is no matching attribute in a data object

  const CHART_CONFIG = {
    ...CHART_DEFAULTS,
    axisBottom: {
      ...AXIS_CONFIG,
      legend: axisBottomLabel,
      legendOffset: bottomLegendOffset
        ? bottomLegendOffset
        : axisBottomLabel
        ? 32
        : 0, // These and the margin calcs are still a little too "magic",
      tickRotation,
    },
    axisLeft: {
      ...AXIS_CONFIG,
      legend: axisLeftLabel,
      legendOffset: axisLeftLabel ? -40 : 0, // Also make this automatic with margins below
    },
    labelSkipHeight: 12,
    labelSkipWidth: 12,
    margin: {
      bottom: marginBottom ? marginBottom : axisBottomLabel ? 48 : 32,
      left: axisLeft ? (axisLeftLabel ? 60 : 48) : axisLeftLabel ? 60 : 16,
      right: 16,
      top: 32,
    },
  };

  const dataState = getDataState(data);

  return (
    <Widget {...rest} {...dataState}>
      {isLoading ? <Loading /> : <span />}
      <ResponsiveBar
        {...CHART_CONFIG}
        axisLeft={axisLeft}
        data={data}
        indexBy={indexBy}
        keys={keys}
        label={label}
        layout={layout}
      />
    </Widget>
  );
};

WidgetBar.propTypes = propTypes;
WidgetBar.displayName = 'bar';

export { propTypes };

export default WidgetBar;
