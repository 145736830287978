import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import UserImageTypes from '../../../../v1/globals/UserImageTypes';

import userEntitiesThunks from '../../../../v1/actions/userEntitiesThunks';
// Selectors
import getUserDataFromEntities from '../../../../v1/selectors/getUserDataFromEntities';
import getBaristaId from '../../../../v1/selectors/getBaristaId';
import getTenantMenuConfiguration from '../../../../v1/selectors/getTenantMenuConfiguration';

const mapStateToProps = (state, ownProps) => {
  // Get the ID of the Barista user from state
  const baristaId = getBaristaId(state);

  // Get the ID of the user passed through ownProps
  const userId = Number(ownProps.userId); // Forcing numeric id

  // Check if this is the Barista user
  const isBarista = ownProps.isBarista
    ? ownProps.isBarista
    : Number(baristaId) === userId;

  // Get our user data
  const userData = getUserDataFromEntities(state, userId);

  let { userImgUrl } = ownProps;
  let userInitials;
  // Check to make sure there is user data first
  if (userData) {
    if (!userImgUrl) {
      // Get the profile type images from our user data
      const images =
        userData.get('images') &&
        userData
          .get('images')
          .find((img) => img.get('type') === UserImageTypes.PROFILE);
      // Make sure there were profile pictures, then get the picture url
      userImgUrl = images && images.get('picture');
    }

    // Get the first initial from the nickname/lastname
    const firstInit =
      userData.get('nickname') && userData.get('nickname').charAt(0);
    const lastInit =
      userData.get('last_name') && userData.get('last_name').charAt(0);

    // set the initials
    userInitials = firstInit + lastInit;
  }

  const isLinkingDisabled = getTenantMenuConfiguration(state).get(
    'disableDirectory_disableLinksToProfiles'
  );

  return {
    error: state.getIn(['containersStatus', 'users', userId, 'error']),
    isBarista: isBarista,
    isLinkingDisabled,
    isLoading:
      ownProps.isLoading ||
      state.getIn(['containersStatus', 'users', userId, 'loading']) ||
      false, // <-- 'loading' should be changed to isLoading at some point to standardize
    isUserActive: userData ? userData.get('is_active') : void 0,
    userImgUrl: userImgUrl,
    userInitials: userInitials,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUserData: (userId) => {
    if (userId) {
      dispatch(userEntitiesThunks.addUserEntity(userId));
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
