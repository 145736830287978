import React from 'react';
import pt from 'prop-types';
import OffboardingPerformanceList from './OffboardingPerformanceList';
import OffboardingPerformanceDetail from './OffboardingPerformanceDetail';
import injectIntlToController from '../../../../utils/injectIntlToController';
import usePerformanceList from './hooks/usePerformanceList';

const propTypes = {
  params: pt.shape({
    userID: pt.oneOfType([pt.string, pt.number]),
  }),
};

const OffboardingPerformance = (props) => {
  const {
    escalateProgress,
    isLoadingDetail,
    isUserIDEscalating,
    loadPerformance,
    performanceDetail,
  } = usePerformanceList();
  const {
    params: { userID },
  } = props;

  if (userID) {
    return (
      <OffboardingPerformanceDetail
        {...props}
        escalateProgress={escalateProgress}
        isLoadingPerformanceDetail={isLoadingDetail}
        performanceDetail={performanceDetail}
        performanceDetailFn={loadPerformance}
      />
    );
  }

  return (
    <OffboardingPerformanceList
      {...props}
      escalateProgress={escalateProgress}
      isUserIDEscalating={isUserIDEscalating}
    />
  );
};

OffboardingPerformance.propTypes = propTypes;

export default injectIntlToController(OffboardingPerformance);
