import actionTypes from '../../../../app/js/v1/actions/actionTypes';

const taskActions = {};

taskActions.getTasksStart = () => ({
  type: actionTypes.GET_TASKS_START,
});

taskActions.getTasksSuccess = (workflow, tasks) => ({
  tasks,
  type: actionTypes.GET_TASKS_SUCCESS,
  workflow,
});

taskActions.getTasksFailure = (error) => ({
  error,
  type: actionTypes.GET_TASKS_FAILURE,
});

taskActions.startSaveTask = (task) => ({
  task,
  type: actionTypes.SAVE_TASK_IN_PROGRESS,
});

taskActions.getSaveTaskSuccess = (task) => ({
  task,
  type: actionTypes.SAVE_TASK_SUCCESS,
});

taskActions.getSaveTaskFailure = (error) => ({
  error,
  type: actionTypes.SAVE_TASK_FAILURE,
});

taskActions.loadConditionsSetStart = () => ({
  type: actionTypes.LOAD_CONDITION_TASK_START,
});

taskActions.loadConditionsSetSuccess = (condition) => ({
  condition,
  type: actionTypes.LOAD_CONDITION_TASK_SUCCESS,
});

taskActions.loadConditionsSetFail = () => ({
  type: actionTypes.LOAD_CONDITION_TASK_FAIL,
});

taskActions.updateTaskName = (name) => ({
  name,
  type: actionTypes.SAVE_TASK_NAME,
});

taskActions.registerFormChange = (args) => ({
  taskId: args.taskId,
  type: actionTypes.TASK_FORM_IS_CHANGED,
  workflowId: args.workflowId,
});

taskActions.addBlock = (taskId, blockType, multiInputMapping) => ({
  blockType,
  multiInputMapping,
  taskId,
  type: actionTypes.ADD_BLOCK_TO_TASK,
});

taskActions.removeBlock = (taskId, blockIndex) => ({
  blockIndex,
  taskId,
  type: actionTypes.REMOVE_BLOCK_FROM_TASK,
});

taskActions.addOptionToBlockAttribute = (
  blockId,
  blockAttrName,
  newOption
) => ({
  blockAttrName,
  blockId,
  newOption,
  type: actionTypes.ADD_BLOCK_ATTR_OPTION,
});

taskActions.removeOptionFromBlockAttribute = (
  blockId,
  blockAttrName,
  optionIndex
) => ({
  blockAttrName,
  blockId,
  optionIndex,
  type: actionTypes.REMOVE_BLOCK_ATTR_OPTION,
});

taskActions.rearrangeBlocks = (ordering, taskId) => ({
  ordering,
  taskId,
  type: actionTypes.REARRANGE_BLOCKS,
});

/**
 * Rearrange tasks locally
 */
taskActions.rearrangeTasks = (ordering) => ({
  ordering,
  type: actionTypes.REARRANGE_TASKS,
});

taskActions.saveTasks = (tasks) => ({
  tasks,
  type: actionTypes.SAVE_TASKS_ORDER,
});

taskActions.startSavingTasks = () => ({
  type: actionTypes.START_SAVING_TASKS,
});

taskActions.addTaskStart = () => ({
  type: actionTypes.ADD_TASK_START,
});

taskActions.addTaskSuccess = () => ({
  type: actionTypes.ADD_TASK_SUCCESS,
});

taskActions.addTaskFailure = (error) => ({
  error,
  type: actionTypes.ADD_TASK_FAILURE,
});

taskActions.removeTaskStart = () => ({
  type: actionTypes.REMOVE_TASK_START,
});

taskActions.removeTaskSuccess = () => ({
  type: actionTypes.REMOVE_TASK_SUCCESS,
});

taskActions.removeTaskFailure = (error) => ({
  error,
  type: actionTypes.REMOVE_TASK_FAILURE,
});

taskActions.togglePreview = () => ({
  type: actionTypes.TOGGLE_PREVIEW,
});

taskActions.selectCurrentEditingTask = (taskId) => ({
  taskId,
  type: actionTypes.SELECT_CURRENT_EDITING_TASK,
});

taskActions.loadObjectMappingSuccess = (objectMappingList) => ({
  objectMappingList,
  type: actionTypes.LOAD_OBJECT_MAPPING_SUCCESS,
});

taskActions.loadObjectMappingFail = (error) => ({
  error,
  type: actionTypes.LOAD_OBJECT_MAPPING_FAIL,
});

export default taskActions;
