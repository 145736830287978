import Nodes from './Nodes';
import Regexes from './Regexes';

const RegexesFaqs = {
  ...Regexes,
  [Nodes.email]: /^([\w.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)$/,
  [Nodes.link]: /^([a-z]+:\/+)([^/\s]*)([a-z0-9\-@^=%&;/~+]*)[?]?([^ #]*)#?([^ #]*)$/,
};

export default Object.freeze(RegexesFaqs);
