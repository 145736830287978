import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import TypeSkills, { TypeDefaults } from './TypeSkills';

const rule = () => ({
  '> div': {
    '> span': {
      marginLeft: '30px',
    },
  },
  '> h2': {
    textAlign: 'center',
  },
  paddingLeft: '32px',
});

class Skills01 extends PureComponent {
  static propTypes = TypeSkills;
  static defaultProps = TypeDefaults;

  render() {
    const { className, type, options, template } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        <h2>{'Skills page'}</h2>
        {options.map((option) => (
          <div key={option.id}>
            <span>{option.count}</span>
            <span>{option.label}</span>
          </div>
        ))}
      </div>
    );
  }
}

export default createComponent(rule, Skills01, [
  'options',
  'template',
  'type',
  'id',
  'trigger',
]);
