import { createSelector } from 'reselect';
import getAllBundles from './getAllBundles';

const getSelectedBundleId = (state) =>
  state.getIn(['workflowState', 'frontendScratch', 'bundle']);

const getSelectedBundle = (allBundles, bundleId) => {
  if (allBundles) {
    return allBundles.get(bundleId);
  }
  return null;
};

const selector = createSelector(
  [getAllBundles, getSelectedBundleId],
  getSelectedBundle
);

export default selector;
