import APIcall from './APIcall';
import endpointGenerator from './endpointGenerator';
import { v4 as uuidv4 } from 'uuid';

/**
 * Utility methods for Progressive Web Apps.
 *
 * @namespace PwaUtils
 */
const PwaUtils = {
  /**
   * Checks if the current webpage is installed as a Progressive Web App (PWA).
   *
   * @returns {boolean} - True if the PWA is installed, false otherwise.
   */
  isPwaInstalled: () => {
    if (window.matchMedia) {
      return window.matchMedia('(display-mode: standalone)').matches;
    } else {
      // fallback for older browsers
      return window.navigator.standalone;
    }
  },
  /**
   * Registers the client for desktop notifications.
   *
   * @returns {Promise} A promise that resolves with the registration response.
   * @throws {Error} If an error occurs during the API call.
   */
  registerClientNotification: () => {
    return new Promise((resolve, reject) => {
      const uniqueId = uuidv4();

      APIcall.post({
        data: {
          active: true,
          registration_id: uniqueId,
        },
        error(e) {
          reject(e);
        },
        preventShowError: true,
        success({ body }) {
          resolve(body);
          // eslint-disable-next-line no-console -- debugging
          console.info('Desktop notification successfully registered', body);
        },
        token: true,
        url: endpointGenerator.genPath('espNotification.desktopRegistration'),
      });
    });
  },
};

export default PwaUtils;
