const api_rbac_v0_1_group = {
  count: 10,
  results: [
    {
      id: 39,
      name: 'HR_CONTACT',
      url: 'https://test.api/api/rbac/v0.1/group/39/',
    },
    {
      id: 40,
      name: 'HR_RECRUITER',
      url: 'https://test.api/api/rbac/v0.1/group/40/',
    },
    {
      id: 41,
      name: 'ENABLEMENT',
      url: 'https://test.api/api/rbac/v0.1/group/41/',
    },
    {
      id: 42,
      name: 'IT_CONTACT',
      url: 'https://test.api/api/rbac/v0.1/group/42/',
    },
    {
      id: 43,
      name: 'OPERATIONS',
      url: 'https://test.api/api/rbac/v0.1/group/43/',
    },
    {
      id: 47,
      name: 'ONBOARD_SCHEDULER',
      url: 'https://test.api/api/rbac/v0.1/group/47/',
    },
    {
      id: 48,
      name: 'ACTIVITY_CREATOR',
      url: 'https://test.api/api/rbac/v0.1/group/48/',
    },
    {
      id: 55,
      name: 'MANAGER',
      url: 'https://test.api/api/rbac/v0.1/group/55/',
    },
    {
      id: 60,
      name: 'SERVICE_LEAD',
      url: 'https://test.api/api/rbac/v0.1/group/60/',
    },
    {
      id: 61,
      name: 'SERVICE_AGENT',
      url: 'https://test.api/api/rbac/v0.1/group/61/',
    },
  ],
};

export default api_rbac_v0_1_group;
