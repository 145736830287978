import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Icon, List, Message } from 'semantic-ui-react';
import _ from 'lodash';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Waypoint } from 'react-waypoint';
import { intl } from 'esp-util-intl';

// utils
import uiPathGenerator from '../../utils/uiPathGenerator';
import { Boundaries } from 'cascara-middleware';

// atoms
import ScrollArea from '../atoms/ScrollArea';
import OnboardingActivityTemplateListItem from '../atoms/OnboardingActivityTemplateListItem';

// controller
import OnboardingActivityTemplateListController from '../controllers/OnboardingActivityTemplateListController';

class OnboardingActivityTemplateList extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    loadTemplates: PropTypes.func,
    nextTemplate: PropTypes.string,
    templateID: PropTypes.string,
    templates: ImmutablePropTypes.list,
  };

  static defaultProps = {
    isLoading: false,
    loadTemplates: _.noop,
    nextTemplate: null,
    templateID: null,
    templates: fromJS([]),
  };

  componentDidMount() {
    // Since we use the same reducer for the performance detail view
    // we need to force to reset the existing list and load a new one when
    // when this component mount
    const { loadTemplates } = this.props;
    const resetTemplateList = true;
    loadTemplates(resetTemplateList);
  }

  handleBottomWaypointEnter = () => {
    const { loadTemplates } = this.props;
    loadTemplates();
  };

  render() {
    const { templates, templateID, isLoading, nextTemplate } = this.props;

    const templateSelected = Number(templateID);

    const showLoading = isLoading && !templates.isEmpty();

    return (
      <div
        className='onboarding-activity-template-list'
        style={{
          height: '100%',
          margin: '-1em',
          minHeight: '70vh',
        }}
      >
        <Button
          as={Link}
          basic
          content='Create New Template'
          fluid
          style={{
            margin: '1em',
            width: 'calc(100% - 2em)',
          }}
          to={uiPathGenerator.genPath(
            'admin.adminActivities.onboardingActivities.templates',
            {
              templateID: 'new',
            }
          )}
        />
        {templates.size > 0 ? (
          <ScrollArea height='calc(100% - 52px)'>
            <List divided relaxed='very'>
              {/* NOTE: image: this should come from the category images */}
              {/* NOTE: action: this should be */}
              {/* TODO: format SLA with moment.js */}
              {/* TODO: set 'active' prop if the detail view is active. */}
              {/* TODO: menu: this prop needs to get added to EspListItem as a node. This will allow us to render a dropdown with options. */}
              {/* TODO: attachedHeader: this prop needs to get added to EspListItem as a string. It will render the internal description at the top. */}
              {/* Note: Need to pass activeClassName with empty string in order to avoid to pass the ".active" class added by React Router */}
              {templates.toList().map((item) => (
                <Boundaries key={item.get('eid')}>
                  <OnboardingActivityTemplateListItem
                    item={item}
                    templateSelected={templateSelected}
                  />
                </Boundaries>
              ))}

              {nextTemplate && !isLoading && (
                <Waypoint
                  key={templates.size}
                  onEnter={this.handleBottomWaypointEnter}
                />
              )}

              {showLoading && (
                <Message icon>
                  <Icon loading name='circle notched' />
                  <Message.Content>
                    {intl.formatMessage({
                      id: 'label.loading_more',
                    })}
                  </Message.Content>
                </Message>
              )}
            </List>

            <Divider hidden />
          </ScrollArea>
        ) : (
          <Message
            content='Click the button above to add your first onboard task template.'
            info
          />
        )}
      </div>
    );
  }
}

const OnboardingActivityTemplateListTest = OnboardingActivityTemplateList;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardingActivityTemplateList = OnboardingActivityTemplateListController(
  OnboardingActivityTemplateList
);
export { OnboardingActivityTemplateListTest };
export default OnboardingActivityTemplateList;
