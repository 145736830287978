import { useCallback, useState } from 'react';
import { changeTemplateStatusAPI } from '../endpoints/APITemplate';
import { ActivityTypes } from '../../../../../globals/ActivityRecipients';

const ERROR_STATUS = 400;

const parseTemplateData = (formValues) => {
  const values = { ...formValues };

  if (values.enable_deadline === false) {
    values.deadline_hours = 0;
    values.deadline_days = 0;
  }

  if (values.type !== ActivityTypes.WEB_LINK) {
    values.web_link = ''; // send empty string because null is not a valid value
  }

  if (
    values.type !== ActivityTypes.CHAT_REMINDER &&
    values.type !== ActivityTypes.PROFILE_ACTIVITY
  ) {
    values.person_to_contact = ''; // send empty string because null is not a valid value
  }
  delete values.intentName;
  values.activity_type = 'offboarding';

  return values;
};

const useChangeTemplateStatus = () => {
  const [isLoading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState('');

  const changeTemplateStatus = useCallback(async (templateID, status) => {
    setLoading(true);
    const response = await changeTemplateStatusAPI(templateID, status);
    if (response?.status !== ERROR_STATUS) {
      setLoading(false);
      setNewStatus(response?.body?.status);
      return response?.body?.status;
    }
    setLoading(false);
    return false;
  }, []);

  return {
    changeTemplateStatus,
    isChangeTemplateLoading: isLoading,
    newStatus,
  };
};

export { parseTemplateData, useChangeTemplateStatus };
