const api_barista_v0_1_faqs_general_intent_lookup = {
  count: 1,
  next:
    'https://mocha.qa.espressive.com/api/barista/v0.1/faqs/general/intent_lookup/?attributes=service_department_type%3DHR&is_archetype=False&limit=24&offset=24&order_by=-sys_date_updated&resp_type=all',
  previous: null,
  results: [
    {
      acknowledgments: [],
      activation_condition: null,
      active: false,
      additional_text: [
        {
          additional_text: '',
          eid: '2dc9c1f7-2b94-46c1-addf-828d21dc4d26',
          match_validation: false,
        },
      ],
      answer_expiry_info: {
        earliest_answer_expiry: null,
        total_expired_active_answers: 0,
      },
      answers: [],
      application_active: true,
      application_name: 'FAQs',
      attributes: [
        {
          eid: '2210a5a5-dc2d-41f3-844d-b58a6cc68fda',
          name: 'service_department',
          value: 'c8e9d8bc-e6af-440d-8782-2fe35d121fe9',
        },
      ],
      case_feedback: 0.0,
      cases_created: 0,
      deflected: 0.0,
      description: '',
      eid: 'bca543c4-1d52-43f4-bd32-59dca82482f4',
      helpful: 0,
      is_kb: true,
      is_protected: false,
      is_reviewed: false,
      last_used: '',
      name: 'test faq jsdbafabkf',
      not_helpful: 0,
      sys_created_by: 'eddie.valverde@espressive.com',
      sys_date_created: '2020-06-05T15:55:32.263117Z',
      sys_date_updated: '2020-06-08T17:08:21.314599Z',
      sys_updated_by: 'eddie.valverde@espressive.com',
      total_completions: 0,
      triggered_misspell_total: 0,
      triggered_total: 0,
      triggers: [],
      triggers_total: 0,
    },
  ],
};

export default api_barista_v0_1_faqs_general_intent_lookup;
