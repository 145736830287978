import React, { useEffect } from 'react';
import {
  // IndexRedirect,
  // IndexRoute,
  Redirect,
  Router,
} from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

// Stores
import Store from './v1/stores/store';
import PwaUtils from './v1/utils/PwaUtils';
// Utils
import uiPathGenerator from './v1/utils/uiPathGenerator';
import browserHistory from './v1/utils/browserHistory';
import syncReactRouterRedux from './v1/utils/syncReactRouterRedux';
// Components
import App from './App';
import OnboardRedirectPage from './v1/components/pages/flow/OnboardRedirectPage';
import ResetPasswordPage from './v1/components/pages/ResetPasswordPage';
import LogoutPage from './v1/components/pages/LogoutPage';
// import Notenant                 from './v1/components/pages/NoTenant';
import TenantSelect from './v1/components/pages/TenantSelect';
import NoMatch from './v1/components/pages/NoMatch';
import Slate from './v1/components/pages/Slate';
import IntlPage from './v1/components/pages/intl/IntlPage';

import AuthPage from './v1/components/pages/AuthPage';
import IntlProvider from './v1/components/controllers/IntlProvider';

// Organisms
// import Empty                    from './v1/components/atoms/Empty';
// import BlankPage                from './v1/components/atoms/BlankPage';
// Molecules
import EspRoute from './v1/components/molecules/EspRoute';
import EspSwitch from './v1/components/molecules/EspSwitch';

// Chunks
import AdminPage from './v1/components/pages/AdminPage';
import EditorPage from '../../admin/js/v1/components/pages/EditorPage';
import HomePage from './v1/components/pages/HomePage';
import LoginPage from './v1/components/pages/LoginPage';
import OnboardAuthPage from './v1/components/pages/flow/OnboardAuthPage';
import OnboardWorkflowContext from './v1/components/organisms/OnboardWorkflowContext';
import PortalPage from './v1/components/pages/PortalBarista';
import ProxyPage from './v1/components/pages/Proxy/ProxyPage';
import StandAloneKbViewPage from './v1/components/pages/kbView';

import { FlagProvider } from 'esp-ui-flag';
import WidgetAuthSuccess from './v1/components/pages/WidgetAuthSuccess';
import WidgetAuthRequired from './v1/components/pages/WidgetAuthRequired';

syncReactRouterRedux(browserHistory, Store);

async function handleNotificationPermission() {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      // Handle the case when permission is not granted
      // Show a user-friendly error message or take some other action
      // todo: show a message to the user to suggest they enable notifications
    }
  } catch (error) {
    // eslint-disable-next-line no-console -- This is a console log
    console.log('Notification permission denied');
  }
}
const Routes = () => {
  useEffect(() => {
    // This event is triggered when the user installs the app as a PWA
    window.addEventListener('appinstalled', () =>
      handleNotificationPermission()
    );

    if (PwaUtils.isPwaInstalled()) {
      handleNotificationPermission();
    }
    return () => {
      window.removeEventListener(
        'appinstalled',
        () => handleNotificationPermission
      );
    };
  }, []);

  return (
    <ReduxProvider store={Store}>
      <FlagProvider>
        <IntlProvider>
          <Router history={browserHistory}>
            <EspRoute component={App} path='/'>
              <EspSwitch>
                <Redirect
                  exact
                  from='/'
                  to={uiPathGenerator.genPath('app.toDo')}
                />

                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern('app')}
                  to={uiPathGenerator.genFullPattern('app.toDo')}
                />

                <EspRoute
                  component={Slate}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('slateApp')}
                />

                <EspRoute
                  component={IntlPage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('intlApp')}
                />

                <EspRoute
                  component={PortalPage}
                  exact
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('portalBarista.intent')}
                />

                <EspRoute
                  component={PortalPage}
                  exact
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('portalBarista.channel')}
                />

                <EspRoute
                  component={PortalPage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('portalBarista')}
                />

                <EspRoute
                  component={ProxyPage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('proxy.bquery')}
                />

                <EspRoute
                  component={StandAloneKbViewPage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('standAloneKb')}
                />

                <EspRoute
                  component={HomePage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('app')}
                />

                <EspRoute
                  component={AdminPage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('admin')}
                />

                <EspRoute
                  component={OnboardAuthPage}
                  path={uiPathGenerator.genFullPattern('onboardAuthentication')}
                />

                <EspRoute
                  component={OnboardRedirectPage}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('onboardRedirect')}
                />

                <EspRoute
                  component={OnboardWorkflowContext}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('onboardStep')}
                />

                <EspRoute
                  component={LoginPage}
                  isAnonsOnly
                  path={uiPathGenerator.genFullPattern('login')}
                />
                <EspRoute
                  component={LogoutPage}
                  path={uiPathGenerator.genFullPattern('logout')}
                />
                <EspRoute
                  component={TenantSelect}
                  isAnonsOnly
                  path={uiPathGenerator.genFullPattern('noTenant')}
                />

                <EspRoute
                  component={EditorPage}
                  isLoginRequired
                  isPermissionsProtected
                  path={uiPathGenerator.genFullPattern('editor')}
                />

                <EspRoute
                  component={ResetPasswordPage}
                  isAnonsOnly
                  path={uiPathGenerator.genFullPattern('resetPassword')}
                />

                <EspRoute
                  component={AuthPage}
                  isAnonsOnly
                  path={uiPathGenerator.genFullPattern('auth')}
                >
                  <EspRoute
                    component={AuthPage}
                    path={uiPathGenerator.genFullPattern('auth.oauth')}
                  />
                  <EspRoute
                    component={AuthPage}
                    path={uiPathGenerator.genFullPattern('auth.saml')}
                  />
                  <EspRoute
                    component={AuthPage}
                    path={uiPathGenerator.genFullPattern('auth.inactive')}
                  />
                </EspRoute>

                <EspRoute
                  component={WidgetAuthSuccess}
                  isLoginRequired
                  path={uiPathGenerator.genFullPattern('widgetAuthSuccess')}
                />
                <EspRoute
                  component={WidgetAuthRequired}
                  path={uiPathGenerator.genFullPattern('widgetAuthRequired')}
                />
                <EspRoute component={NoMatch} />
              </EspSwitch>
            </EspRoute>
          </Router>
        </IntlProvider>
      </FlagProvider>
    </ReduxProvider>
  );
};
export default Routes;
