import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsSecurity extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M48 9c21.54 0 39 17.46 39 39S69.54 87 48 87 9 69.54 9 48 26.46 9 48 9zm0-5C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4z' />
          <path d='M72 28c-8.73-1.04-16.996-4.504-23.86-10 0 0-10.14 9-23.84 10 0 0-5.65 42 23.71 49C48 77 78.91 73.38 72 28zM55.62 57.15L48.52 52l-7.1 5.16 2.71-8.35L37 43.64h8.77l2.71-8.35 2.71 8.35H60l-7.1 5.16 2.72 8.35z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsSecurity;
