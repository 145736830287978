import actionTypes from './actionTypes';

const csvParsingActions = {};

csvParsingActions.cancelCSVParsingFail = () => ({
  type: actionTypes.CANCEL_CSV_PARSING_FAIL,
});
csvParsingActions.cancelCSVParsingStart = () => ({
  type: actionTypes.CANCEL_CSV_PARSING_START,
});
csvParsingActions.cancelCSVParsingSuccess = () => ({
  type: actionTypes.CANCEL_CSV_PARSING_SUCCESS,
});

export default csvParsingActions;
