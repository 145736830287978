import {
  Button,
  Checkbox,
  Form,
  Label,
  Segment,
  Select,
  Table,
} from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { curry, memoize, noop } from 'lodash';

import DepartmentSelect from './DepartmentSelect';
import LocationSelect from './LocationSelect';
// Utils
import ProductPermissionsParser from '../../utils/ProductPermissionsParser';
// Global
import LocationTypes from '../../globals/LocationTypes';

class PermissionsForm extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    isRemoving: PropTypes.bool,
    isSaving: PropTypes.bool,
    jobRoles: ImmutablePropTypes.list,
    productFamily: ImmutablePropTypes.map,
    productPermissionGroup: ImmutablePropTypes.map.isRequired,
    removeProductPermissionGroup: PropTypes.func,
    saveProductPermissionGroup: PropTypes.func,
    toggleProductFromPermissionGroup: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    isRemoving: false,
    isSaving: false,
    jobRoles: null,
    productFamily: null,
    removeProductPermissionGroup: noop,
    saveProductPermissionGroup: noop,
    toggleProductFromPermissionGroup: noop,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    departments: this.props.productPermissionGroup
      .get('parsedRule')
      .get('departments'),
    isDirty: false,
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    locations: this.props.productPermissionGroup
      .get('parsedRule')
      .get('locations'),
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    selectedJobRoles: this.props.productPermissionGroup
      .get('parsedRule')
      .get('jobRoles'),
  };
  //
  // constructor(props){
  // super(props);
  //
  // const {productPermissionGroup} = this.props;
  // const parsedRule = productPermissionGroup.get('parsedRule');
  //
  // this.state = {
  //     departments      : parsedRule.get('departments'),
  //     locations        : parsedRule.get('locations'),
  //     selectedJobRoles : parsedRule.get('jobRoles'),
  // };
  // }
  //

  handleSelectLocation = (location) => {
    let { locations } = this.state;

    const alreadySelected = locations.some(
      (l) => l.get('id') === location.get('id')
    );
    const isZone = location.get('location_type_name') === LocationTypes.ZONE;

    // Do not pass this location if already selected or if it's a zone and there is already a selection
    if (alreadySelected || (!locations.isEmpty() && isZone)) {
      return;
    }

    locations = locations.push(location);

    this.setState({
      isDirty: !isZone,
      locations, // the form as dirty only if the location set is not a zone (root path)
    });
  };

  handleSelectDepartment = (department) => {
    let { departments } = this.state;

    const alreadySelected = departments.some(
      (d) => d.get('id') === department.get('id')
    );

    if (alreadySelected) {
      return;
    }

    departments = departments.push(department);

    this.setState({
      departments,
      isDirty: true,
    });
  };

  handleChangeJobRoles = (e, data) => {
    this.setState({
      isDirty: true,
      selectedJobRoles: Immutable.List(data.value),
    });
  };

  handleRemoveLocation = memoize(
    curry((locationID, e) => {
      let { locations } = this.state;
      locations = locations.filterNot(
        (location) => location.get('id') === locationID
      );

      this.setState({
        isDirty: true,
        locations,
      });
    })
  );

  handleRemoveDepartment = memoize(
    curry((departmentID, e) => {
      let { departments } = this.state;
      departments = departments.filterNot(
        (department) => department.get('id') === departmentID
      );

      this.setState({
        departments,
      });
    })
  );

  handleToggleProductFromPermissionGroup = memoize(
    curry((productID, e) => {
      const {
        productPermissionGroup,
        toggleProductFromPermissionGroup,
      } = this.props;

      const productPermissionGroupID = productPermissionGroup.get('id');

      toggleProductFromPermissionGroup(productPermissionGroupID, productID);
    })
  );

  handleRemovePermissionGroupButtonClick = () => {
    //
    // const confirmed = window.confirm('Are you sure you want to remove this permission group?');
    //
    // if (!confirmed){
    // return;
    // }
    //

    const { productPermissionGroup, removeProductPermissionGroup } = this.props;

    const productPermissionGroupID = productPermissionGroup.get('id');

    removeProductPermissionGroup(productPermissionGroupID);
  };

  handleSavePermissionGroup = (e) => {
    e.preventDefault();

    const { productPermissionGroup, saveProductPermissionGroup } = this.props;

    const productPermissionGroupID = productPermissionGroup.get('id');
    const ruleExpression = this.computeRuleExpression();

    saveProductPermissionGroup(productPermissionGroupID, ruleExpression);
  };

  computeRuleExpression = () => {
    const { locations, departments, selectedJobRoles } = this.state;

    const rule = ProductPermissionsParser.toExpression(
      locations.map((location) => location.get('id')).toJS(),
      departments.map((department) => department.get('id')).toJS(),
      selectedJobRoles.toJS()
    );

    return rule;
  };

  renderAddLocationButton = () => (
    <Button
      className='text'
      content='Add Location'
      floated='right'
      type='button'
    />
  );

  renderAddDepartmentButton = () => (
    <Button
      className='text'
      content='Add Department'
      floated='right'
      type='button'
    />
  );

  render() {
    const {
      disabled,
      isRemoving,
      isSaving,
      jobRoles,
      productFamily,
      productPermissionGroup,
    } = this.props;

    const selectedProducts = productPermissionGroup.get('productIDs');

    let productFamilyProducts = null;
    let productFamilyOptions = null;

    if (productFamily) {
      productFamilyProducts = productFamily.get('products');
      productFamilyOptions = productFamilyOptions = productFamily.get(
        'options'
      );
    }

    const { departments, locations, selectedJobRoles, isDirty } = this.state;

    // const rule = this.computeRuleExpression();

    return (
      <Segment>
        <Form onSubmit={this.handleSavePermissionGroup}>
          <Button
            disabled={disabled}
            floated='right'
            icon='close'
            loading={isRemoving}
            onClick={this.handleRemovePermissionGroupButtonClick}
            type='button'
          />

          {productFamily && (
            <Table
              compact
              // definition
              fixed
              selectable
              singleLine
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{'Enable'}</Table.HeaderCell>

                  {productFamilyOptions
                    .map((optionName, optionID) => {
                      const key = optionID;

                      return (
                        <Table.HeaderCell key={key}>
                          {optionName}
                        </Table.HeaderCell>
                      );
                    })
                    .toList()}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {productFamilyProducts.map((product) => {
                  const productID = product.get('id');

                  return (
                    <Table.Row key={productID}>
                      <Table.Cell>
                        <Checkbox
                          checked={selectedProducts.includes(productID)}
                          onChange={this.handleToggleProductFromPermissionGroup(
                            productID
                          )}
                        />
                      </Table.Cell>

                      {productFamilyOptions
                        .map((optionName, optionID) => {
                          optionID = Number(optionID);

                          // what is the value of this option for this product?
                          const optionsForProduct = product.get('pf_options');
                          const optionForProduct =
                            optionsForProduct.find(
                              (opt) => opt.get('option_id') === optionID
                            ) || Immutable.Map();

                          const key = optionID;

                          return (
                            <Table.Cell key={key}>
                              {optionForProduct.get('value', 'Not available')}
                            </Table.Cell>
                          );
                        })
                        .toList()}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}

          <Form.Field>
            <LocationSelect
              clearAfterSelection
              onChange={this.handleSelectLocation}
              renderEmptySelectionTrigger={this.renderAddLocationButton}
              selectOptionRequired
            />
            <label>{'Locations'}</label>
            <Label.Group>
              {locations &&
                !locations.isEmpty() &&
                locations.map((location) => {
                  const propsLabel = {
                    color: 'blue',
                    content: location.get('name'),
                    onRemove: this.handleRemoveLocation(location.get('id')),
                    removeIcon: 'delete',
                  };

                  if (
                    location.get('location_type_name') === LocationTypes.ZONE &&
                    locations.size === 1
                  ) {
                    // this is the only location set and the type is ZONE, we don't want to let the user think that they can remove it
                    delete propsLabel.removeIcon;
                    delete propsLabel.onRemove;
                  }

                  return <Label {...propsLabel} key={location.get('id')} />;
                })}
            </Label.Group>
          </Form.Field>

          <Form.Field>
            <DepartmentSelect
              clearAfterSelection
              onChange={this.handleSelectDepartment}
              renderEmptySelectionTrigger={this.renderAddDepartmentButton}
              selectOptionRequired
            />
            <label>{'Departments'}</label>
            <Label.Group>
              {departments &&
                !departments.isEmpty() &&
                departments.map((department) => (
                  <Label
                    color='blue'
                    content={department.get('name')}
                    key={department.get('id')}
                    onRemove={this.handleRemoveDepartment(department.get('id'))}
                  />
                ))}
            </Label.Group>
          </Form.Field>

          {jobRoles && (
            <Form.Input
              control={Select}
              defaultValue={selectedJobRoles.toJS()}
              label='Job Roles'
              multiple
              onChange={this.handleChangeJobRoles}
              options={jobRoles.toJS()}
              placeholder='Select any...'
              search
            />
          )}

          {/* Please don't remove */}
          {/*
          <div>
            {rule}
          </div>
          */}

          <Button
            color='green'
            disabled={disabled || !isDirty}
            loading={isSaving}
          >
            {'Save'}
          </Button>
        </Form>
      </Segment>
    );
  }
}

export default PermissionsForm;
