import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, memoize } from 'lodash';

// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Molecule
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import FormInputCheckbox from './FormInputCheckbox';
import FormInputText from './FormInputText';
// Controller
import OnboardTemplateIntervalController from '../controllers/OnboardTemplateIntervalController';
// Global
import fieldNameTemplateModal, {
  fieldName,
} from '../../globals/fieldNameTemplateModal';

const buildInitialValues = (interval) => {
  const days = interval.get('days');
  const value = {
    day_after: '',
    day_before: '',
    selected: '',
  };

  const dayBeforeIndex = 0;
  const firstDayIndex = 1;
  const dayAfterIndex = 2;

  if (days === 1) {
    value.selected = fieldNameTemplateModal[firstDayIndex];
  } else if (days < 0) {
    value.selected = fieldNameTemplateModal[dayBeforeIndex];
    value.day_before = Math.abs(days);
  } else {
    value.selected = fieldNameTemplateModal[dayAfterIndex];
    value.day_after = days;
  }
  return value;
};

class OnboardTemplateIntervalModal extends PureComponent {
  static propTypes = {
    /** Action on a schedule ID (copy) */
    action: PropTypes.string,
    /** Redux form changer */
    changeCheckValue: PropTypes.func.isRequired,
    /** from error */
    formError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    /** Form validation */
    formValidation: PropTypes.func.isRequired,
    /** Form values for the shouldComponentUpdate */
    // formValue        : ImmutablePropTypes.map,
    /** form submit */
    handleSubmit: PropTypes.func.isRequired,
    interval: ImmutablePropTypes.map,
    intervalID: PropTypes.string,
    isLoading: PropTypes.bool,
    isPristine: PropTypes.bool,
    /** Schedule ID */
    scheduleID: PropTypes.string,
    /** Modal size */
    size: PropTypes.string,
    /** template ID selected */
    templateID: PropTypes.number,
    /** template title */
    title: PropTypes.string,
    /** Modal trigger */
    trigger: PropTypes.node.isRequired,
  };

  static defaultProps = {
    action: null,
    formError: null,
    // formValue  : Immutable.Map(),
    interval: Immutable.Map(),
    intervalID: 'new',
    isLoading: false,
    isPristine: false,
    scheduleID: null,
    size: 'small',
    templateID: null,
    title: null,
  };

  constructor(props) {
    super(props);

    let initialValues = {};

    // Get initialValue
    if (props.interval && !props.interval.isEmpty()) {
      initialValues = buildInitialValues(props.interval);
    }

    this.state = {
      day_after: initialValues.day_after,
      day_before: initialValues.day_before,
      open: false,
      selected: initialValues.selected,
    };

    // Avoid setState on unMounted component
    // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
    this._isMounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextDay = nextProps.interval && nextProps.interval.get('days');
    const { interval } = this.props;
    const currentDay = interval && interval.get('days');
    if (nextDay !== currentDay) {
      const initialValues = buildInitialValues(nextProps.interval);

      this.setState({
        day_after: initialValues.day_after,
        day_before: initialValues.day_before,
        selected: initialValues.selected,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleChangeSelect = memoize(
    curry((nameSelected, noPrevent, e) => {
      if (!noPrevent) {
        e.preventDefault();
      }

      const { changeCheckValue, intervalID } = this.props;

      changeCheckValue(nameSelected, intervalID);
    })
  );

  handleSubmitAndClose = (data) => {
    const {
      action,
      handleSubmit,
      intervalID,
      scheduleID,
      templateID,
    } = this.props;

    const msg = {
      header: 'Success',
      message: 'A template has been added',
    };

    handleSubmit(data, intervalID, templateID, scheduleID, action, msg, () => {
      if (this._isMounted) {
        this.setState({
          open: false,
        });
      }
    });
  };

  render() {
    const {
      formError,
      formValidation,
      intervalID,
      isLoading,
      isPristine,
      size,
      title,
      trigger,
    } = this.props;
    const { day_after, day_before, open, selected } = this.state;

    const initialValues = {
      day_after_selected: selected === fieldNameTemplateModal[2],

      day_before_selected: selected === fieldNameTemplateModal[0],

      first_day_selected: selected === fieldNameTemplateModal[1],
    };

    initialValues[fieldName[0]] = day_before;
    initialValues[fieldName[1]] = 1;

    initialValues[fieldName[2]] = day_after;

    const disableAddButton = Boolean(isPristine || formError) || isLoading;

    const msg =
      intervalID === 'new'
        ? 'Select the timing for your new onboarding activity.'
        : 'Update the timing for your onboarding activity.';

    const buttonAdd = intervalID === 'new' ? 'Add' : 'Update';

    const header = title ? `Activity: ${title}` : 'Activity Details';
    return (
      <Modal
        className='scrolling'
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={open}
        size={size}
        trigger={trigger}
      >
        <ValidatedForm
          form={`ScheduleTemplateForm_${intervalID}`}
          initialValues={initialValues}
          onSubmit={this.handleSubmitAndClose}
          validate={formValidation}
        >
          <Modal.Header content={header} />
          <Modal.Content className='withFooter'>
            <ScrollArea>
              <Message content={msg} info />
              <Form.Group>
                <ValidatedField
                  component={FormInputCheckbox}
                  label='Day before start'
                  name={fieldNameTemplateModal[0]}
                  onChange={this.handleChangeSelect(fieldNameTemplateModal[0])}
                  width={13}
                />
                <ValidatedField
                  component={FormInputText}
                  formError={formError && formError.day_before}
                  name={fieldName[0]}
                  onChange={this.handleChangeSelect(
                    fieldNameTemplateModal[0],
                    true
                  )}
                  width={3}
                />
              </Form.Group>

              <Form.Group>
                <ValidatedField
                  component={FormInputCheckbox}
                  label='First Day'
                  name={fieldNameTemplateModal[1]}
                  onChange={this.handleChangeSelect(fieldNameTemplateModal[1])}
                  width={13}
                />
                <ValidatedField
                  component={FormInputText}
                  hidden
                  name={fieldName[1]}
                  width={3}
                />
              </Form.Group>

              <Form.Group>
                <ValidatedField
                  component={FormInputCheckbox}
                  label='Day after start'
                  name={fieldNameTemplateModal[2]}
                  onChange={this.handleChangeSelect(fieldNameTemplateModal[2])}
                  width={13}
                />
                <ValidatedField
                  component={FormInputText}
                  formError={formError && formError.day_after}
                  name={fieldName[2]}
                  onChange={this.handleChangeSelect(
                    fieldNameTemplateModal[2],
                    true
                  )}
                  width={3}
                />
              </Form.Group>
            </ScrollArea>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              content='Cancel'
              disabled={isLoading}
              onClick={this.handleClose}
            />

            <Button
              content={buttonAdd}
              disabled={disableAddButton}
              loading={isLoading}
              primary
            />
          </Modal.Actions>
        </ValidatedForm>
      </Modal>
    );
  }
}

export const OnboardTemplateIntervalModalTest = OnboardTemplateIntervalModal;

export default OnboardTemplateIntervalController(OnboardTemplateIntervalModal);
