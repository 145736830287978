import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';

import ModalEsp from './ModalEsp';
import AddAttributeForm from '../../../../../app/js/v1/components/molecules/AddAttributeForm';

const propTypes = {
  addAttribute: PropTypes.func.isRequired,
  addAttributeForm: ImmutablePropTypes.map,
  existingAttribute: ImmutablePropTypes.map,
  existingKey: PropTypes.string,
  loadObjectMapping: PropTypes.func.isRequired,
  objectMappingList: ImmutablePropTypes.list,
  taskAttributes: ImmutablePropTypes.map,
  trigger: PropTypes.node.isRequired,
};

const defaultProps = {
  addAttributeForm: Immutable.Map(),
  existingAttribute: Immutable.Map(),
  existingKey: '',
  objectMappingList: Immutable.List(),
  taskAttributes: Immutable.Map(),
};

class AddAttributeModal extends Component {
  componentDidMount() {
    const { loadObjectMapping } = this.props;
    loadObjectMapping();
  }

  handleAddAttribute = (close) => {
    const { addAttribute } = this.props;
    addAttribute();
    close();
  };

  handleOnSubmit(e) {
    e.preventDefault();
  }

  render() {
    const {
      addAttributeForm,
      existingAttribute,
      existingKey,
      objectMappingList,
      taskAttributes,
      trigger,
    } = this.props;

    let addAttributeFormHasErrors = false;
    if (addAttributeForm) {
      addAttributeFormHasErrors = addAttributeForm.has('syncErrors');
    }

    let headerContent;
    let acceptContent;

    if (existingKey) {
      headerContent = 'Edit attribute in this task';
      acceptContent = 'Edit';
    } else {
      headerContent = 'Add an attribute to this task';
      acceptContent = 'Add';
    }

    return (
      <ModalEsp
        acceptContent={acceptContent}
        headerContent={headerContent}
        headerIcon='list layout'
        isAcceptDisabled={addAttributeFormHasErrors}
        isLoading={false}
        onAccept={this.handleAddAttribute}
        trigger={trigger}
      >
        <ModalEsp.Form onSubmit={this.handleOnSubmit}>
          <AddAttributeForm
            existingAttribute={existingAttribute}
            existingKey={existingKey}
            objectMappingList={objectMappingList}
            taskAttributes={taskAttributes}
          />
        </ModalEsp.Form>
      </ModalEsp>
    );
  }
}

AddAttributeModal.propTypes = propTypes;
AddAttributeModal.defaultProps = defaultProps;

export default AddAttributeModal;
