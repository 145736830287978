const types = {
  ADD_TIP: 'ADD_TIP',
  DELETE_TIP: 'DELETE_TIP',
  DELETE_TIP_GROUP: 'DELETE_TIP_GROUP',
  EDIT_CHANGE_TIP: 'EDIT_CHANGE_TIP',
  EDIT_TIP: 'EDIT_TIP',
  HIDE_CONFIRM: 'HIDE_CONFIRM',
  HIDE_MODAL: 'HIDE_MODAL',
  MERGE_CUSTOM_MESSAGES: 'MERGE_CUSTOM_MESSAGES',
  RESET_FORM: 'RESET_FORM',
  SAVE_FORM: 'SAVE_FORM',
  SET_COLOR: 'SET_COLOR',
  SET_FORM: 'SET_FORM',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_TIP: 'SET_TIP',
  SHOW_CONFIRM: 'SHOW_CONFIRM',
  SHOW_EDIT_MODAL: 'SHOW_EDIT_MODAL',
  SHOW_MODAL: 'SHOW_MODAL',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  UPDATE_FORM: 'UPDATE_FORM',
};

export default types;
