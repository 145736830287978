import '@espressive/legacy-css';
import 'inter-ui';
import { track } from 'cascara-middleware';

// Main App renderer
import renderApp from './renderApp';

// Make sure we get all of the assets from our lib directory
require.context('../../lib/images/', true, /^.*/);

const startupWeb = () => {
  renderApp();
  // Enables page tracking for this application. A corresponding index.html
  // file must have a tracking code loaded in order for this to work.
  track.page();
};

export default startupWeb;
