import React, { useCallback, useContext, useState } from 'react';
import OktaSAMLContext from './OktaSAMLContext';
import { labels } from '../utils/constants';
import EspConfirmationModal from '../../../../../../../v2/components/display/EspConfirmationModal/EspConfirmationModal';
import { Button, Form } from 'semantic-ui-react';
import styles from './OktaSAMLConfigured.module.scss';

const propTypes = {};

const OktaSAMLConfigured = () => {
  const { state, handleDelete, handleUpdate, toggleEnableModal } =
    useContext(OktaSAMLContext);

  const { config, isEnableModalOpen, isLoading } = state;
  const { enabled } = config;
  const [isDeleteModalOpen, setDeleteModal] = useState();

  const handleDeleteConfirm = useCallback(() => {
    setDeleteModal(!isDeleteModalOpen);
    handleDelete();
  }, [handleDelete, isDeleteModalOpen]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModal(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleEnableConfirm = useCallback(() => {
    toggleEnableModal();
    handleUpdate(enabled);
  }, [enabled, handleUpdate, toggleEnableModal]);

  const enableModalTitle = enabled ? labels.DISABLE_SSO : labels.ENABLE_SSO;
  const enableModalBody = enabled
    ? labels.DISABLE_SSO_BODY
    : labels.ENABLE_SSO_BODY;

  return (
    <Form as={'div'}>
      <Form.Group>
        <Form.Field width={12}>
          <div className={styles.checkbox}>
            <label htmlFor='enabled'>
              <strong>{labels.STATUS}</strong>
            </label>
            <Form.Checkbox
              checked={enabled}
              disabled={isLoading}
              id='enabled'
              label={enabled ? labels.ENABLED : labels.DISABLED}
              name='enabled'
              onClick={toggleEnableModal}
              toggle
            />
          </div>
        </Form.Field>

        <Form.Field width={4}>
          <div style={{ textAlign: 'right' }}>
            <Button
              color='red'
              content={labels.REMOVE}
              disabled={isLoading}
              onClick={toggleDeleteModal}
              type='button'
            />

            <EspConfirmationModal
              cancelButton={labels.NO}
              confirmButton={
                <Button content={labels.YES} positive type='button' />
              }
              content={enableModalBody}
              header={enableModalTitle}
              onCancel={toggleEnableModal}
              onConfirm={handleEnableConfirm}
              open={isEnableModalOpen}
            />

            <EspConfirmationModal
              cancelButton={labels.CANCEL}
              confirmButton={
                <Button color='red' content={labels.REMOVE} type='button' />
              }
              content={labels.DELETE_BODY}
              header={labels.DELETE_TITLE}
              onCancel={toggleDeleteModal}
              onConfirm={handleDeleteConfirm}
              open={isDeleteModalOpen}
            />
          </div>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

OktaSAMLConfigured.propTypes = propTypes;

export default OktaSAMLConfigured;
