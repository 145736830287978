import { fromJS } from 'immutable';

/**
 * Create a String which contains the full address from a selected Path Nodes
 * @param selectionPathNodesIDs
 * @param nodesByID
 * @returns {any}
 */
const getStringLocationsByIDs = (selectionPathNodesIDs, nodesByID) => {
  let listNode = fromJS({
    name: '',
  });

  selectionPathNodesIDs.forEach((node, i) => {
    if (nodesByID.get(node)) {
      const addComma = i > 0 ? ', ' : '';
      const newListNode =
        listNode.get('name') + addComma + nodesByID.get(node).get('name');

      listNode = listNode.set('name', newListNode);
    }
  });

  return listNode;
};

export default getStringLocationsByIDs;
