import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  catalog: '',
  dpc: '',
  productBundles: '',
  roleBundles: '',
  url: '',
});

const adminSearchReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SET_ADMIN_SEARCH_TERM: {
      return state.set(action.context, action.searchTerm);
    }

    case actionTypes.SET_ADMIN_LAST_PAGE_VISITED: {
      return state.set('url', action.url);
    }

    default: {
      return state;
    }
  }
};

export default adminSearchReducer;
