import { createSelector } from 'reselect';

import getCurrentUser from './getCurrentUser';

const getUsersById = (state) => state.getIn(['entities', 'users']);

const getSelectedDirectoryUserId = (state) =>
  state.getIn(['directory', 'selectedUser', 'id']);

const getSelectedDirectoryUser = (usersById, selectedUserId, currentUser) => {
  let user = usersById.get(selectedUserId, null);

  if (user) {
    const myFavorites = currentUser.get('favorites');

    const isFavorited = myFavorites.includes(user.get('id'));

    user = user.set('isFavorited', isFavorited);

    // does this user has a manager?
    const managerUrl = user.get('manager');

    if (managerUrl) {
      // TODO We are getting manager id from manager url, need the api to get updated to do this for us
      /** @type {Array.<string>}  */
      const managerUrlParts = managerUrl.split('/');

      /** @type {number} */
      const managerId = Number(managerUrlParts[managerUrlParts.length - 2]);

      const manager = usersById.get(managerId, null);

      user = user.set('manager', manager);
    }
  }

  return user;
};

const selector = createSelector(
  [getUsersById, getSelectedDirectoryUserId, getCurrentUser],
  getSelectedDirectoryUser
);

export default selector;
