import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import TypeLogo, { TypeDefaults } from './TypeLogo';
import { ImageDefaults } from 'esp-assets';

const rule = () => ({
  '> .logo': {
    maxWidth: '35%',
    width: 100,
  },
  '> .text': {
    fontSize: '.9em',
  },
  textAlign: 'center',
});

/**
 * Espressive01 component description.
 */

class Espressive01 extends PureComponent {
  static propTypes = {
    /**
     * Espressive01 logo
     */
    logo: PropTypes.string,
    /**
     * Espressive01 text
     */
    text1: PropTypes.string,
    ...TypeLogo,
  };
  static defaultProps = {
    ...TypeDefaults,
    logo: ImageDefaults.ESPRESSIVE_LOGO,
    text1: 'Powered by',
  };

  render() {
    const { text1, logo, type, template, className } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        {text1 && <div className='text'>{text1}</div>}
        <img alt='' className='logo' src={logo} />
      </div>
    );
  }
}

export default createComponent(rule, Espressive01, [
  'logo',
  'text1',
  'template',
  'type',
  'id',
  'trigger',
]);
