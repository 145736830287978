import { fromJS } from 'immutable';
import _ from 'lodash';
import async from 'async';
import { change } from 'redux-form/immutable';

import endpointGenerator from '../utils/endpointGenerator';
import APIcall from '../utils/APIcall';
// Global
import OnboardingActivityStates from '../globals/OnboardingActivityStates';
import NewHireStatus from '../globals/NewHireStatus';
import { SortFieldNames } from '../globals/SortProductStateOptions';

import onboardActivityActions from './onboardActivityActions';
import toastNotificationsActions from './toastNotificationsActions';
// Thunk
import onboardProgressManagerViewActions from './onboardProgressManagerViewActions';
import userEntitiesThunks from './userEntitiesThunks';
import { ActivityTypes } from '../globals/ActivityRecipients';
import onboardProgressManagerViewThunks from './onboardProgressManagerViewThunks';
import EspFilters from 'esp-util-filters';

const checkOrCreateNewInterval = (fieldName, valueTopass) =>
  new Promise((resolve, reject) => {
    const days = fieldName !== 'day_before' ? valueTopass : -valueTopass;

    async.waterfall(
      [
        // 1 Check if we can found an activity interval with the same days set
        (next) => {
          const esp_filters = `days__EQ=${days}`;
          APIcall.get({
            error(err) {
              next(err);
            },
            query: {
              esp_filters,
            },
            success(res) {
              const resultInterval = res.body.results;
              next(null, resultInterval);
            },
            token: true,
            url: endpointGenerator.genPath('espTodo.scheduleInterval'),
          });
        },
        // 2 If not exist, create a new interval activity OR go next
        (resultInterval, next) => {
          if (resultInterval && resultInterval.length > 0) {
            // The result should return only one entry in the result array since we want to avoid duplicate interval activity
            next(null, resultInterval[0]);
          } else {
            // Create a new one
            APIcall.post({
              data: {
                days: Number(days),
                name: days,
              },
              error(err) {
                next(err);
              },
              success(res) {
                const result = res.body;
                next(null, result);
              },
              token: true,
              url: endpointGenerator.genPath('espTodo.scheduleInterval'),
            });
          }
        },
      ],
      (error, resultInterval) => {
        if (error) {
          reject(error);
        } else {
          resolve(resultInterval);
        }
      }
    );
  });

const onboardActivityThunks = {};

onboardActivityThunks.escalateProgressByUser = (userID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.escalateProgressStart(userID));

    APIcall.post({
      data: {
        user_id: userID,
      },
      error(err) {
        dispatch(onboardActivityActions.escalateProgressFail(err));
        reject(err);
      },
      success(res) {
        const response = res.body;
        dispatch(onboardActivityActions.escalateProgressSuccess(response));
        resolve(response);
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.progress.escalate'),
    });
  });

onboardActivityThunks.loadPerformanceList = ({
  limit = 24,
  offset,
  orderBy,
  filterBy = NewHireStatus,
}) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadPerformanceListStart());

    const searchTerm = getState().getIn([
      'onboardActivity',
      'performance',
      'searchTerm',
    ]);

    async.waterfall(
      [
        // 1 Load the list
        (next) => {
          const query = {};
          // Offset
          if (_.isNumber(offset)) {
            query.offset = offset;
          }

          // Limit
          query.limit = limit;

          // Order by
          query.order_by = orderBy;

          // Filter only NEW_HIRE type
          query.esp_filters = `type__EQ=${filterBy}`;

          // We are performing a search
          if (searchTerm) {
            dispatch(onboardActivityActions.setIsSearchingPerformanceTerm());
            // Esp filter - search for nickname or last name
            // Todo - Add support for esp_filter &ORoverall_state will it will be support on the API
            query.esp_filters += `&user.nickname__IC=${searchTerm}&ORuser.last_name__IC=${searchTerm}`;
          }

          query.esp_filters += '&status__!EQ=ARCHIVED';

          APIcall.get({
            error(err) {
              next(err);
            },
            query,
            success(res) {
              const performanceList = res.body.results;
              const { count } = res.body;

              next(null, performanceList, count);
            },
            token: true,
            url: endpointGenerator.genPath('espTodo.progressSummary'),
          });
        },

        // 2 Load each users from the list
        (performanceList, count, next) => {
          const listID = performanceList.map((item) => item.user);
          dispatch(userEntitiesThunks.getListUsersByID(listID))
            .then(() => {
              next(null, performanceList, count);
            })
            .catch((error) => {
              next(error);
            });
        },
      ],
      (error, performanceList, count) => {
        if (error) {
          dispatch(onboardActivityActions.loadPerformanceListFail(error));
          reject(error);
        } else {
          dispatch(
            onboardActivityActions.loadPerformanceListSuccess(
              performanceList,
              count
            )
          );
          resolve(performanceList);
        }
      }
    );
  });

onboardActivityThunks.archiveOnePerformanceSummary = (
  progressSummaryID,
  msg
) => (dispatch) =>
  new Promise((resolve, reject) => {
    // Archive should remove from the list this performance item
    dispatch(onboardActivityActions.deletePerformanceStart(progressSummaryID));
    APIcall.post({
      data: {
        status: 'ARCHIVED',
      },
      error(err) {
        dispatch(onboardActivityActions.deletePerformanceFail());
        reject(err);
      },
      success() {
        dispatch(
          onboardActivityActions.deletePerformanceSuccess(progressSummaryID)
        ); // Remove the progress from the list
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath(
        'espTodo.progressSummary.instance.changeStatus',
        {
          progressSummaryID,
        }
      ),
    });
  });

onboardActivityThunks.loadPerformanceDetailList = (userID, type) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    if (!type) {
      reject('error - No type has been passed');
    }

    dispatch(onboardProgressManagerViewActions.selectUser(userID));
    dispatch(onboardActivityActions.loadPerformanceDetailStart());

    const state = getState();
    const onboardProgressManagerViewState = state.get(
      'onboardProgressManagerView'
    );

    async.waterfall(
      [
        // 1 Load the list
        (next) => {
          const query = {};
          query.esp_filters = `user__EQ=${userID}&todo.activity_recipient__EQ=${type}`;
          query.limit = 500; // TODO - Temporary fix of the pagination - we need to get rid of this when the new UI for the performanceDetail view will be done - View with Jamie
          APIcall.get({
            error(err) {
              next(err);
            },
            query,
            success(res) {
              const performanceList = res.body.results;

              // if onboardProgressManagerViewState values are available
              if (onboardProgressManagerViewState) {
                if (
                  onboardProgressManagerViewState
                    .get('getOnboardProgressManagerView')
                    .get('values')
                ) {
                  // hghghj
                  const foundUser = onboardProgressManagerViewState
                    .get('getOnboardProgressManagerView')
                    .get('values')
                    .toJS()
                    .reduce((item, currentItem) => {
                      if (currentItem.user === userID) {
                        item = currentItem;
                      }
                      return item;
                    }, null);

                  if (foundUser) {
                    const completeActivities = performanceList.filter(
                      (activity) =>
                        activity.state === OnboardingActivityStates.COMPLETE
                    );
                    const activitiesCount =
                      foundUser.complete_state_count +
                      foundUser.delinquent_state_count +
                      foundUser.overdue_state_count +
                      foundUser.normal_state_count;
                    const perfomanceListCount = performanceList.filter(
                      (item) =>
                        item.state !== OnboardingActivityStates.SCHEDULED &&
                        item.state !== OnboardingActivityStates.WARNING
                    ).length;
                    if (
                      foundUser.complete_state_count !==
                        completeActivities.length ||
                      activitiesCount !== perfomanceListCount
                    ) {
                      dispatch(
                        onboardProgressManagerViewThunks.getOnboardProgressManagerView()
                      );
                    }
                  }
                }
              }

              const { count } = res.body;
              next(null, performanceList, count);
            },
            token: true,
            url: endpointGenerator.genPath('espTodo.progress'),
          });
        },

        // 2 Load each template from this result
        (performanceList, count, next) => {
          const listId = performanceList.map((perf) => perf.todo);
          const query = {};
          query.esp_filters = `id__IN=${listId.join(',')}`;

          APIcall.get({
            error(err) {
              dispatch(
                onboardActivityActions.loadTemplateActivityListFail(err)
              );
              next(err);
            },
            query,
            success({ body: { results } }) {
              dispatch(
                onboardActivityActions.loadTemplateActivityListSuccess(results)
              );
              next(null, performanceList, count);
            },
            token: true,
            url: endpointGenerator.genPath('espTodo.template'),
          });
        },

        // 3 Load the user selected from the list
        (performanceList, count, next) => {
          if (type !== NewHireStatus) {
            // Load all user for each entry
            let listID = [userID];
            listID = listID.concat(
              performanceList.map((item) => item.requested_for)
            );

            dispatch(userEntitiesThunks.getListUsersByID(listID))
              .then((users) => {
                let newManager;
                users.results.forEach((usr) => {
                  if (usr.id === userID) {
                    newManager = usr.manager;
                  }
                });
                next(null, performanceList, count, newManager);
              })
              .catch((error) => {
                next(error);
              });
          } else {
            // Load the selected user only
            dispatch(userEntitiesThunks.addUserEntity(userID))
              .then((user) => {
                next(null, performanceList, count, user.manager);
              })
              .catch((error) => {
                next(error);
              });
          }
        },

        // 4 Load the manager detail of this user if needed
        (performanceList, count, manager, next) => {
          let selectedUserManager;
          let managerId;
          if (manager) {
            managerId = manager.split('/');
            managerId = Number(managerId[managerId.length - 2]);
            selectedUserManager = state.getIn(['entities', 'users', managerId]);
          }

          if (!selectedUserManager && manager) {
            // Check if the user already exists or not in the entities reducer
            dispatch(userEntitiesThunks.addUserEntity(managerId))
              .then(() => {
                next(null, performanceList, count);
              })
              .catch((error) => {
                next(error);
              });
          } else {
            next(null, performanceList, count);
          }
        },

        // 5 Load the Scheduled activities of this user
        (performanceList, count, next) => {
          dispatch(
            onboardActivityThunks.loadScheduledActivitiesForOneUser(
              userID,
              type
            )
          )
            .then((scheduledActivitiesCount) => {
              performanceList.push({
                count: scheduledActivitiesCount || 0,
                id: 'scheduled',
                state: OnboardingActivityStates.SCHEDULED,
              });
              next(null, performanceList, userID);
            })
            .catch((error) => {
              next(error);
            });
        },
      ],
      (error, performanceList, userID) => {
        if (error) {
          dispatch(onboardActivityActions.loadPerformanceDetailFail(error));
          reject(error);
        } else {
          dispatch(
            onboardActivityActions.loadPerformanceDetailSuccess(
              performanceList,
              userID
            )
          );
          resolve(performanceList);
        }
      }
    );
  });

onboardActivityThunks.loadScheduledActivitiesForOneUser = (
  userID,
  type
) => () =>
  new Promise((resolve, reject) => {
    const query = {};
    query.esp_filters = `user__EQ=${userID}&type__EQ=${type}`;

    APIcall.get({
      error(err) {
        reject(err);
      },
      query,
      success(res) {
        const performanceList = res.body.results;
        if (performanceList.length > 0 && performanceList[0]) {
          resolve(performanceList[0].scheduled_state_count);
        } else {
          resolve(null);
        }
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.progressSummary'),
    });
  });

onboardActivityThunks.loadScheduleActivityList = ({
  limit = 24,
  offset,
  orderBy,
}) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadScheduleActivityListStart());

    const query = {
      esp_filters: new EspFilters().isNull('schedule_type').asQueryString(),
      limit,
      order_by: orderBy,
    };
    // Offset
    if (_.isNumber(offset)) {
      query.offset = offset;
    }

    const url = endpointGenerator.genPath('espTodo.schedule');
    APIcall.get({
      error(err) {
        dispatch(onboardActivityActions.loadScheduleActivityListFail(err));
        reject(err);
      },
      query,
      success(res) {
        const scheduleList = res.body.results;
        const { count } = res.body;

        dispatch(
          onboardActivityActions.loadScheduleActivityListSuccess(
            scheduleList,
            count
          )
        );
        resolve();
      },
      token: true,
      url: url,
    });
  });

onboardActivityThunks.loadOneScheduleActivity = (id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadScheduleActivityListStart());

    const state = getState();

    async.waterfall(
      [
        // 1. Get the Schedule Activity
        (next) => {
          const scheduleList = state.getIn([
            'onboardActivity',
            'schedule',
            'list',
          ]);
          let schedule = scheduleList.find(
            (sc) => Number(sc.get('id')) === Number(id)
          );

          // This schedule already exists
          if (schedule) {
            next(null, schedule);
          } else {
            APIcall.get({
              error(err) {
                dispatch(
                  onboardActivityActions.loadScheduleActivityListFail(err)
                );
                next(err);
              },
              success(res) {
                schedule = res.body;
                dispatch(
                  onboardActivityActions.loadScheduleOneActivityListSuccess(
                    schedule
                  )
                );
                next(null, fromJS(schedule));
              },
              token: true,
              url: endpointGenerator.genPath('espTodo.schedule.instance', {
                scheduleID: id,
              }),
            });
          }
        },

        // 2. Load schedule_to_template
        (schedule, next) => {
          const esp_filters = `schedule__IN=${schedule.get('id')}`;

          APIcall.get({
            error(err) {
              dispatch(
                onboardActivityActions.loadScheduleActivityListFail(err)
              );
              next(err);
            },
            query: {
              esp_filters,
            },
            success(res) {
              const result = res.body.results;
              next(null, result);
            },
            token: true,
            url: endpointGenerator.genPath('espTodo.scheduleToTemplate'),
          });
        },

        // 3. Load template and interval of each schedule_to_template
        (scheduleToTemplate, next) => {
          const listTemplate = [];

          async.each(
            scheduleToTemplate,
            (schedule, done) => {
              dispatch(
                onboardActivityThunks.loadScheduleTemplateInterval(schedule)
              )
                .then((result) => {
                  listTemplate.push(result);
                  done();
                })
                .catch((err) => {
                  done(err);
                });
            },
            (err) => {
              next(err, listTemplate);
            }
          );
        },
      ],
      (error, result) => {
        // in the end of the sequence invoking callback
        if (!error) {
          dispatch(onboardActivityActions.loadTemplateInterval(result));

          dispatch(onboardActivityActions.loadScheduleActivityEnd());
          resolve();
        } else {
          dispatch(onboardActivityActions.loadScheduleActivityEnd());
          reject(error);
        }
      }
    );
  });

onboardActivityThunks.loadScheduleTemplateInterval = (schedule) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();

    const templateID = schedule.template;
    const scheduleIntervalID = schedule.interval;

    async.waterfall(
      [
        // 3.1 Load the template selected
        (next) => {
          // Check if we already loaded this template
          const items = state.getIn(['onboardActivity', 'template', 'items']);
          const templateFound = items.filter(
            (itm) => itm.get('id') === Number(templateID)
          );

          if (templateFound && !templateFound.isEmpty()) {
            next(null, templateFound.get(0));
          } else {
            APIcall.get({
              error(err) {
                dispatch(
                  onboardActivityActions.loadScheduleActivityListFail(err)
                );
                next(err);
              },
              success(res) {
                const result = res.body;
                next(null, result);
              },
              token: true,
              url: endpointGenerator.genPath('espTodo.template.instance', {
                templateID,
              }),
            });
          }
        },

        // 3.2 Load the interval selected
        (resultTemplate, next) => {
          APIcall.get({
            error(err) {
              dispatch(
                onboardActivityActions.loadScheduleActivityListFail(err)
              );
              next(err);
            },
            success(res) {
              const resultInterval = res.body;
              const templateSelected = {
                interval: resultInterval,
                scheduleToTemplate: schedule,
                template: resultTemplate,
              };

              next(null, templateSelected);
            },
            token: true,
            url: endpointGenerator.genPath(
              'espTodo.scheduleInterval.instance',
              {
                scheduleIntervalID,
              }
            ),
          });
        },
      ],
      (error, templateSelected) => {
        if (error) {
          reject(error);
        } else {
          resolve(templateSelected);
        }
      }
    );
  });

onboardActivityThunks.saveSchedule = (scheduleID, copyID) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.saveScheduleStart());

    const realScheduleID = copyID || scheduleID;
    const state = getState();
    const schedule = state.getIn([
      'form',
      `ScheduleActivityForm_${realScheduleID}`,
      'values',
    ]);
    const groupsInForm = schedule.get('groups').toJS();

    // Set the API method to use
    const methodAPI =
      scheduleID === 'new' || scheduleID === 'copy' ? 'post' : 'patch';

    // Set the URL endpoint to use
    const url =
      scheduleID === 'new' || scheduleID === 'copy'
        ? endpointGenerator.genPath('espTodo.schedule')
        : endpointGenerator.genPath('espTodo.schedule.instance', {
            scheduleID,
          });

    const groups = [];
    schedule.get('groups').forEach((grp) => {
      const group = {
        departments: grp.get('departments'),
        include_remote: grp.get('include_remote'),
        job_roles: grp.get('job_roles'),
        locations: grp.get('locations'),
      };
      groups.push(group);
    });

    async.waterfall(
      [
        // 1 Post the new Schedule
        (next) => {
          const data = {
            description: schedule.get('description'),
            groups,
            name: schedule.get('name'),
          };

          APIcall[methodAPI]({
            data,
            error(err) {
              next(err);
            },
            success(res) {
              const newSchedule = res.body;
              const isStillLoading = true; // Let's the loading state to true
              dispatch(
                onboardActivityActions.saveScheduleSuccess(
                  newSchedule,
                  isStillLoading
                )
              );
              next(null, newSchedule);
            },
            token: true,
            url,
          });
        },
        // 2 Create the schedule_to_template or Save group if it's an update
        (newSchedule, next) => {
          if (scheduleID === 'new' || scheduleID === 'copy') {
            // Create the new schedule to template
            const templateSelected = state.getIn([
              'onboardActivity',
              'schedule',
              'templateSelected',
            ]);
            let intervalID, templateID;

            templateSelected.forEach((temp) => {
              const idSchedule = copyID
                ? temp.getIn(['scheduleToTemplate', 'schedule'])
                : temp.getIn(['scheduleToTemplate', 'id']);

              const matchingID = copyID || 'new_';
              if (String(idSchedule).match(matchingID)) {
                templateID = temp.getIn(['template', 'id']);
                intervalID = temp.getIn(['interval', 'id']);
              } else if (copyID && copyID === idSchedule) {
                templateID = temp.getIn(['template', 'id']);
                intervalID = temp.getIn(['interval', 'id']);
              }
            });

            if (!intervalID && !templateID) {
              // The user doesn't save any template interval here - Go to the next step
              next(null, newSchedule.id);
            } else {
              // For Each schedule_to_template created, POST a new entry
              const templateSelect = templateSelected.toJS();
              async.eachOf(
                templateSelect,
                (template, key, done) => {
                  const data = {
                    interval: template.interval.id,
                    schedule: newSchedule.id,
                    template: template.template.id,
                  };
                  APIcall.post({
                    data,
                    error(err) {
                      done(err);
                    },
                    success(res) {
                      const scheduleToTemplate = res.body;

                      // Update the templateSelected
                      dispatch(
                        onboardActivityActions.updateTemplateSelectedScheduleToTemplate(
                          scheduleToTemplate,
                          intervalID
                        )
                      );
                      done();
                    },
                    token: true,
                    url: endpointGenerator.genPath(
                      'espTodo.scheduleToTemplate'
                    ),
                  });
                },
                (err) => {
                  if (!err) {
                    next(null, newSchedule.id);
                  } else {
                    next(err);
                  }
                }
              );
            }
          } else {
            // Or save the groups
            dispatch(
              onboardActivityThunks.saveAllGroups(newSchedule, groupsInForm)
            ).then(() => {
              next(null, newSchedule.id);
            });
          }
        },
      ],
      (error, resultScheduleID) => {
        dispatch(onboardActivityActions.loadScheduleActivityEnd()); // Set loading state to false

        if (error) {
          reject(error);
        } else {
          resolve(resultScheduleID);
        }
      }
    );
  });

onboardActivityThunks.deleteOneScheduleActivity = (scheduleID, msg) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.deleteScheduleActivityStart(scheduleID));
    APIcall.delete({
      error(err) {
        dispatch(onboardActivityActions.deleteScheduleActivityFail());
        reject(err);
      },
      success() {
        dispatch(
          onboardActivityActions.deleteScheduleActivitySuccess(scheduleID)
        );
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.schedule.instance', {
        scheduleID,
      }),
    });
  });

onboardActivityThunks.createActivityInterval = (
  fieldName,
  valueTopass,
  templateID,
  scheduleID,
  action
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();

    dispatch(onboardActivityActions.createActivityIntervalStart());

    async.waterfall(
      [
        // 1 Check if we can found an activity interval with the same days set or create a new one
        (next) => {
          checkOrCreateNewInterval(fieldName, valueTopass)
            .then((resultInterval, err) => {
              next(err, resultInterval);
            })
            .catch((error) => {
              next(error);
            });
        },
        // 2 Create new schedule_to_template
        (resultInterval, next) => {
          // Check if this template with this interval is not already set with this schedule
          const templateSelected = state.getIn([
            'onboardActivity',
            'schedule',
            'templateSelected',
          ]);
          const intervalID = resultInterval.id;
          let alreadyExist = false;

          templateSelected.forEach((temp) => {
            // Check intervalID
            if (
              Number(temp.getIn(['interval', 'id'])) === Number(intervalID) &&
              Number(temp.getIn(['template', 'id'])) === Number(templateID)
            ) {
              alreadyExist = true;
            }
          });

          if (alreadyExist) {
            next(null, {
              alreadyExist: true,
            });
          } else {
            const data = {
              interval: intervalID,
              schedule: scheduleID,
              template: templateID,
            };
            if (scheduleID === 'new' || action) {
              // Fake templateSelected for a new schedule task
              data.id = `new_${templateSelected.size + 1}`;
              next(null, resultInterval, data);
            } else {
              // Add real template selected
              APIcall.post({
                data,
                error(err) {
                  next(err);
                },
                success(res) {
                  const scheduleToTemplate = res.body;
                  next(null, resultInterval, scheduleToTemplate);
                },
                token: true,
                url: endpointGenerator.genPath('espTodo.scheduleToTemplate'),
              });
            }
          }
        },
      ],
      (error, resultInterval, scheduleToTemplate) => {
        if (error) {
          dispatch(onboardActivityActions.createActivityIntervalFail());
          reject(error);
        } else {
          // Get the template selected
          const items = state.getIn(['onboardActivity', 'template', 'items']);
          const getTemplate = items.filter(
            (itm) => itm.get('id') === Number(templateID)
          );
          let alreadyExist = false;

          if (getTemplate && !getTemplate.isEmpty()) {
            const templateSelected = getTemplate.get(0).toJS();

            alreadyExist = resultInterval.alreadyExist
              ? {
                  alreadyExist: true,
                }
              : null;

            if (resultInterval.alreadyExist) {
              dispatch(onboardActivityActions.createActivityIntervalFail());
            } else {
              // Create new templateSelect with interval, schetoToTemplate and template
              const templateSelect = {
                interval: resultInterval,
                scheduleToTemplate: scheduleToTemplate,
                template: templateSelected,
              };

              // Create new the templateSelected.interval
              dispatch(
                onboardActivityActions.createActivityIntervalSuccess(
                  templateSelect
                )
              );
            }

            resolve(alreadyExist);
          } else {
            dispatch(onboardActivityActions.createActivityIntervalFail());
            reject(error);
          }
        }
      }
    );
  });

onboardActivityThunks.updateActivityInterval = (
  fieldName,
  valueTopass,
  intervalID,
  templateID,
  isNew
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadScheduleActivityStart());

    const state = getState();

    async.waterfall(
      [
        // 1 Check if we can found an activity interval with the same days set or create a new one
        (next) => {
          checkOrCreateNewInterval(fieldName, valueTopass)
            .then((resultInterval) => {
              next(null, resultInterval);
            })
            .catch((error) => {
              next(error);
            });
        },
        // 2 Update schedule_to_template
        (resultInterval, next) => {
          let alreadyExist = false;

          // Check if this template with this interval is not already set with this schedule
          const templateSelected = state.getIn([
            'onboardActivity',
            'schedule',
            'templateSelected',
          ]);
          templateSelected.forEach((temp) => {
            // Check intervalID
            if (
              Number(temp.getIn(['interval', 'id'])) ===
                Number(resultInterval.id) &&
              Number(temp.getIn(['template', 'id'])) === Number(templateID)
            ) {
              alreadyExist = true;
            }
          });
          if (alreadyExist) {
            next(null, {
              alreadyExist: true,
            });
          } else {
            const getScheduleIndex = templateSelected.findIndex(
              (temp) =>
                Number(temp.getIn(['interval', 'id'])) === Number(intervalID) &&
                Number(temp.getIn(['template', 'id'])) === Number(templateID)
            );

            let scheduleToTemplatelID;
            if (getScheduleIndex > -1) {
              scheduleToTemplatelID = templateSelected.getIn([
                getScheduleIndex,
                'scheduleToTemplate',
                'id',
              ]);
            }

            if (isNew) {
              // If it's a new SCHEDULE, don't send the API call
              // Fake templateSelected for a new schedule task
              next(null, resultInterval);
            } else {
              // else send the API call to update the schedule_to_template
              APIcall.patch({
                data: {
                  interval: resultInterval.id,
                },
                error(err) {
                  next(err);
                },
                success() {
                  next(null, resultInterval);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espTodo.scheduleToTemplate.instance',
                  {
                    scheduleToTemplatelID,
                  }
                ),
              });
            }
          }
        },
      ],
      (error, resultInterval) => {
        if (error) {
          dispatch(onboardActivityActions.loadScheduleActivityEnd());
          reject(error);
        } else if (resultInterval.alreadyExist) {
          dispatch(onboardActivityActions.loadScheduleActivityEnd());

          resolve({
            alreadyExist: true,
          });
        } else {
          // update the templateSelected.interval
          dispatch(
            onboardActivityActions.updateTemplateSelectedInterval(
              resultInterval,
              intervalID,
              templateID
            )
          );

          // Update the templateSelected.scheduleToTemplate
          dispatch(
            onboardActivityActions.updateTemplateSelectedscheduleToTemplate(
              resultInterval.id,
              intervalID
            )
          );

          dispatch(onboardActivityActions.loadScheduleActivityEnd());

          resolve();
        }
      }
    );
  });

onboardActivityThunks.deleteOneTemplateSelected = (
  intervalID,
  templateID,
  isANewSchedule
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // Get the scheduleToTemplate selected from the intervalID
    dispatch(onboardActivityActions.loadScheduleActivityStart());
    const state = getState();
    const templateSelected = state.getIn([
      'onboardActivity',
      'schedule',
      'templateSelected',
    ]);
    const getScheduleIndex = templateSelected.findIndex(
      (temp) =>
        Number(temp.getIn(['interval', 'id'])) === Number(intervalID) &&
        Number(temp.getIn(['template', 'id'])) === Number(templateID)
    );

    if (getScheduleIndex > -1 && !isANewSchedule) {
      const scheduleToTemplate = templateSelected.getIn([
        getScheduleIndex,
        'scheduleToTemplate',
      ]);

      APIcall.delete({
        error(err) {
          dispatch(onboardActivityActions.loadScheduleActivityEnd());
          reject(err);
        },
        success() {
          // delete the templateSelected from the list
          dispatch(
            onboardActivityActions.deleteTemplateSelectedInterval(
              intervalID,
              templateID
            )
          );
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.scheduleToTemplate.instance', {
          scheduleToTemplatelID: scheduleToTemplate.get('id'),
        }),
      });
    } else if (isANewSchedule) {
      // It's a NEW or a COPY schedule, jsut delete the template in the reducer
      dispatch(
        onboardActivityActions.deleteTemplateSelectedInterval(
          intervalID,
          templateID
        )
      );
      resolve();
    } else {
      reject();
    }
  });

onboardActivityThunks.saveAllGroups = (schedule, groupsInForm = []) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    const groupsInSchedule = schedule.groups;

    let groupstoBeDeleted = [];
    if (schedule.id !== 'new') {
      // Delete all the groups that didn't remain in the form
      groupstoBeDeleted = _.filter(
        groupsInSchedule,
        (ga) =>
          !_.find(groupsInForm, (gf) => gf.id === ga.id && gf.id !== 'new')
      );
    }

    async.series(
      [
        (next) => {
          // 1. Save all the groups in the form
          async.eachOf(
            groupsInForm,
            (group, key, done) => {
              dispatch(
                onboardActivityThunks.saveGroup(group, schedule.id)
              ).then(() => {
                done();
              });
            },
            (err) => {
              if (!err) {
                next();
              }
            }
          );
        },
        // 2. delete the groups not in the form
        (next) => {
          async.eachOf(
            groupstoBeDeleted,
            (group, key, done) => {
              dispatch(onboardActivityThunks.deleteGroup(group)).then(() => {
                done();
              });
            },
            (err) => {
              if (!err) {
                next();
              }
            }
          );
        },
      ],
      (error) => {
        // in the end of the sequence invoking callback
        if (!error) {
          resolve();
        } else {
          reject(error);
        }
      }
    );
  });

onboardActivityThunks.saveGroup = (group, scheduleID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const groupURL = group.url;
    if (!groupURL) {
      group.todo_schedule = scheduleID;
    }

    if (groupURL) {
      // Updating existing group
      dispatch(onboardActivityActions.updateGroupStart());
      APIcall.patch({
        data: group,
        error(error) {
          dispatch(onboardActivityActions.updateGroupFail(error.message));
          reject(error);
        },
        success(res) {
          dispatch(onboardActivityActions.updateGroupSavedSuccess(res.body));
          resolve();
        },
        token: true,
        url: groupURL,
      });
    } else {
      // Creating a new group
      APIcall.post({
        data: group,
        error(error) {
          dispatch(onboardActivityActions.updateGroupFail(error.message));
          reject(error);
        },
        success(res) {
          dispatch(onboardActivityActions.updateGroupAddedSuccess(res.body));
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath('espTodo.schedule.instance.groups', {
          scheduleID: scheduleID,
        }),
      });
    }
  });

onboardActivityThunks.deleteGroup = (group) => (dispatch) =>
  new Promise((resolve, reject) => {
    const groupURL = group.url;
    const groupId = group.id;
    const announcementId = group.todo_schedule;
    if (groupURL) {
      // Only update existing groups
      dispatch(onboardActivityActions.updateGroupStart());
      APIcall.delete({
        error(error) {
          dispatch(onboardActivityActions.updateGroupFail(error.message));
          reject(error);
        },
        success() {
          dispatch(
            onboardActivityActions.updateGroupDeletedSuccess(
              groupId,
              announcementId
            )
          );
          resolve();
        },
        token: true,
        url: groupURL,
      });
    } else {
      resolve();
    }
  });

onboardActivityThunks.saveStatus = (scheduleID, status) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.post({
      data: {
        status: status,
      },
      error(err) {
        reject(err);
      },
      success() {
        dispatch(
          change(`ScheduleActivityForm_${scheduleID}`, 'status', status)
        );
        dispatch(onboardActivityActions.saveStatusSuccess(scheduleID, status));
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.schedule.instance.changeStatus', {
        scheduleID,
      }),
    });
  });

// Creates a new group object and sends it back to the callback
onboardActivityThunks.createNewGroup = () => (dispatch) =>
  new Promise((resolve, reject) => {
    // by default remote workers aren't targeted by announcement
    const include_remote = false;

    dispatch(
      onboardActivityThunks.getUserCount({
        include_remote,
      })
    )
      .then((userCount) => {
        const newGroup = {
          include_remote,
          user_count: userCount,
        };
        resolve(newGroup);
      })
      .catch((err) => {
        reject(err);
      });
  });

onboardActivityThunks.getUserCount = ({
  location = null,
  department = null,
  job_role = null,
  include_remote = false,
}) => () =>
  new Promise((resolve, reject) => {
    APIcall.get({
      error(err) {
        reject(err);
      },
      query: {
        department,
        include_remote,
        job_role,
        location,
      },
      success(res) {
        const count = res.body;
        resolve(count);
      },
      token: true,
      url: endpointGenerator.genPath('espUser.users.userCount'),
    });
  });

onboardActivityThunks.searchTerm = ({ limit = 24, offset, orderBy }) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.setIsSearchingScheduleTerm());

    dispatch(onboardActivityActions.loadScheduleActivityListStart());

    const state = getState();
    const searchTerm = state.getIn([
      'onboardActivity',
      'schedule',
      'searchTerm',
    ]);

    const query = {
      esp_filters: new EspFilters()
        .isNull('schedule_type')
        .contains('name', searchTerm)
        .asQueryString(),
      limit,
      order_by: orderBy,
    };
    // Offset
    if (_.isNumber(offset)) {
      query.offset = offset;
    }

    APIcall.get({
      error(err) {
        dispatch(onboardActivityActions.loadScheduleActivityListFail(err));
        reject(err);
      },
      query,
      success(res) {
        const scheduleList = res.body.results;
        const { count } = res.body;

        dispatch(
          onboardActivityActions.loadScheduleActivityListSuccess(
            scheduleList,
            count
          )
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.schedule'),
    });
  });

//
// Loads onboard activity template
// @param templateID {integer}
// @param cb {Function}
// @return void
//
onboardActivityThunks.loadTemplate = (templateID, cb = _.noop) => (
  dispatch
) => {
  dispatch(onboardActivityActions.loadTemplateActivityListStart());
  const url = endpointGenerator.genPath('espTodo.template.instance', {
    templateID: templateID,
  });
  APIcall.get({
    error(err) {
      dispatch(onboardActivityActions.loadTemplateActivityListFail(err));
    },
    success({ body }) {
      const keepCurrent = true;
      dispatch(
        onboardActivityActions.loadTemplateActivityListSuccess(
          [body],
          keepCurrent
        )
      );
      cb(body);
    },
    token: true,
    url: url,
  });
};

/**
 * Loads onboard activity templates list
 * @param {boolean} resetTemplateList - Force the offset to zero to get the list from the beginning
 * @returns {function(*=, *): Promise}
 */
onboardActivityThunks.loadTemplatesList = (resetTemplateList = false) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadTemplateActivityListStart());

    const url = endpointGenerator.genPath('espTodo.template');
    const templates = getState().getIn([
      'onboardActivity',
      'template',
      'items',
    ]);

    const query = {
      esp_filters: new EspFilters().isNull('activity_type').asQueryString(),
      limit: 24,
      offset: resetTemplateList ? 0 : templates ? templates.size : 0,
      order_by: SortFieldNames.NEGATIVE_SYS_DATE_CREATED,
    };

    APIcall.get({
      error(err) {
        dispatch(onboardActivityActions.loadTemplateActivityListFail(err));
        reject(err);
      },
      query,
      success({ body: { results, next } }) {
        dispatch(
          onboardActivityActions.addTemplateActivityListSuccess(results, next)
        );
        resolve(results);
      },
      token: true,
      url: url,
    });
  });

const parseTemplateData = (formValues) => {
  let values = formValues;
  values = values.set(
    'enable_deadline',
    Boolean(values.get('enable_deadline'))
  );

  if (values.get('enable_deadline') === false) {
    values = values.set('deadline_hours', 0);
    values = values.set('deadline_days', 0);
  }

  if (values.get('activity_type') !== ActivityTypes.WEB_LINK) {
    values = values.set('web_link', ''); // send empty string because null is not a valid value
  }

  if (
    values.get('activity_type') !== ActivityTypes.CHAT_REMINDER &&
    values.get('activity_type') !== ActivityTypes.PROFILE_ACTIVITY
  ) {
    values = values.set('person_to_contact', ''); // send empty string because null is not a valid value
  }

  values = values.set('type', values.get('activity_type'));
  values = values.delete('activity_type');

  return values;
};

//
// Update onboard activity template
// @param options.templateID {integer}
// @param options.formValues {Immutable.map}
// @param options.type {String}
// @param options.enable_deadline {Boolean}
// @param options.activity_recipient {String}
// @param options.person_to_contact {String}
// @return Promise
//
onboardActivityThunks.updateTemplate = (templateID, formValues) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadTemplateActivityListStart());
    APIcall.put({
      data: parseTemplateData(formValues).toJS(),
      error(err) {
        dispatch(onboardActivityActions.loadTemplateActivityListFail(err));
        reject(err);
      },
      success({ body }) {
        const keepCurrent = true;
        dispatch(
          onboardActivityActions.loadTemplateActivityListSuccess(
            [body],
            keepCurrent
          )
        );
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.template.instance', {
        templateID: templateID,
      }),
    });
  });

//
// Create onboard activity template
// @param options.formValues {Immutable.map}
// @param options.type {String}
// @param options.enable_deadline {Boolean}
// @param options.activity_recipient {String}
// @param options.person_to_contact {String}
//
onboardActivityThunks.createTemplate = (formValues) => (dispatch) =>
  new Promise((resolve, reject) => {
    const keepCurrent = true;
    dispatch(onboardActivityActions.loadTemplateActivityListStart());
    APIcall.post({
      data: parseTemplateData(formValues).toJS(),
      error(err) {
        dispatch(onboardActivityActions.loadTemplateActivityListFail(err));
        reject(err);
      },
      success({ body }) {
        dispatch(
          onboardActivityActions.loadTemplateActivityListSuccess(
            [body],
            keepCurrent
          )
        );
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.template'),
    });
  });

//
// change template
// @param {templateID} number
// @param {status} string
//
onboardActivityThunks.changeTemplateStatus = (templateID, status) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadTemplateActivityListStart());
    APIcall.post({
      data: {
        status,
      },
      error(e) {
        dispatch(onboardActivityActions.loadTemplateActivityListFail(e));
        reject();
      },
      success() {
        dispatch(
          onboardActivityActions.changeStatusTemplateActivityListSuccess(
            templateID,
            status
          )
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.template.instance.changeStatus', {
        templateID: templateID,
      }),
    });
  });

//
// creates a new template based on an old one
// @param {template} Immutable.Map()
// @return Promise
//
onboardActivityThunks.copyTemplate = (template) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(onboardActivityActions.loadTemplateActivityListStart());
    const allowedFields = [
      'activity_type',
      'title',
      'description',
      'enable_deadline',
      'web_link',
      'deadline_hours',
      'deadline_days',
      'category',
      'private_notes',
      'activity_recipient',
      'person_to_contact',
    ];
    const newTemplate = template.filter((v, k) =>
      allowedFields.find((x) => x === k)
    );
    APIcall.post({
      data: parseTemplateData(newTemplate).toJS(),
      error(err) {
        dispatch(onboardActivityActions.loadTemplateActivityListFail(err));
        reject(err);
      },
      success({ body }) {
        const keepCurrent = true;
        dispatch(
          onboardActivityActions.loadTemplateActivityListSuccess(
            [body],
            keepCurrent
          )
        );
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espTodo.template'),
    });
  });

export default onboardActivityThunks;
