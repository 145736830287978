import Immutable from 'immutable';

import buildCompositeController from '../../utils/buildCompositeController';

import announcementThunks from '../../actions/announcementThunks';
import locationThunks from '../../actions/locationsThunks';
import departmentsThunks from '../../actions/departmentsThunks';
// Selector
import getWiredDepartmentsByID from '../../selectors/getWiredDepartmentsByID';
import getWiredLocationsByID from '../../selectors/getWiredLocationsByID';
import getJobRoleWithoutAll from '../../selectors/getJobRoleWithoutAll';

const nonCustomCondition = [
  'locations',
  'departments',
  'job_roles',
  'computerType',
  'mobileType',
  'deviceType',
];

const mapStateToProps = (state, ownProps) => {
  const jobRoles = getJobRoleWithoutAll(state);
  const departments = state.getIn(['announcement', 'departments']);

  const departmentNodesByID = getWiredDepartmentsByID(state);
  const locationNodesByID = getWiredLocationsByID(state);

  const isLoadingGroup =
    state.getIn(['onboardActivity', 'schedule', 'isLoadingLocation']) ||
    state.getIn(['onboardActivity', 'schedule', 'isLoadingDepartment']);

  const isLoadedGroup = state.getIn([
    'onboardActivity',
    'schedule',
    'isLoadedGroup',
  ]);

  const groupLocations =
    (ownProps.formValues && ownProps.formValues.get('locations')) ||
    Immutable.List();
  const groupDepartments =
    (ownProps.formValues && ownProps.formValues.get('departments')) ||
    Immutable.List();
  const groupJobRoles =
    (ownProps.formValues && ownProps.formValues.get('job_roles')) ||
    Immutable.List();
  const groupComputerType =
    (ownProps.formValues && ownProps.formValues.get('computerType')) ||
    Immutable.List();
  const groupMobileType =
    (ownProps.formValues && ownProps.formValues.get('mobileType')) ||
    Immutable.List();
  const groupDeviceType =
    ownProps?.formValues?.get('deviceType') || Immutable.List();

  // Check for custom conditions
  const customCondition = [];
  ownProps.formValues.forEach((value, key) => {
    const index = nonCustomCondition.indexOf(key);
    if (index === -1) {
      customCondition.push(key);
    }
  });

  return {
    customCondition,
    departmentNodesByID,
    departments,
    groupComputerType,
    groupDepartments,
    groupDeviceType,
    groupJobRoles,
    groupLocations,
    groupMobileType,
    isLoadedGroup,
    isLoadingGroup,
    jobRoles,
    locationNodesByID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDefaultSelectedDepartment(selectedNodeID) {
    return dispatch(departmentsThunks.getDefaultSelectedNode(selectedNodeID));
  },

  getDefaultSelectedLocation(selectedNodeID) {
    return dispatch(locationThunks.getDefaultSelectedNode(selectedNodeID));
  },

  loadDepartments: () => {
    dispatch(announcementThunks.loadDepartments());
  },

  loadJobRoles: () => {
    dispatch(announcementThunks.loadJobRoles());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
