/* eslint-disable sort-keys -- backend shape */
const data = {
  frequency: 'weekly',
  results: [
    {
      start_date: '2021-09-07T00:00:00Z',
      end_date: '2021-09-10T23:59:59Z',
      content: {
        helpful: 0,
        not_helpful: 1,
        helpful_but_need_help: 0,
        no_match: 0,
        no_response: 9,
        no_feedback: 2,
      },
    },
  ],
};
/* eslint-enable sort-keys */

export default data;
