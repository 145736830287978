import pt from 'prop-types';
import { TablePropTypes } from '@espressive/cascara';

export const DEFAULT_ROW_ACTIONS = {
  actionButtonMenuIndex: 1,
  modules: [
    {
      content: 'View',
      module: 'button',
      name: 'view',
    },
    {
      module: 'edit',
    },
  ],
};

export const PROP_TYPES = {
  ...TablePropTypes,

  actions: pt.arrayOf(
    pt.shape({
      label: pt.string,
      onClick: pt.func,
    })
  ),

  basicSearchParams: pt.arrayOf(pt.string),

  children: pt.oneOfType([pt.node], pt.arrayOf(pt.node)),

  // URL of the API endpoint that we want to get data from
  endpointUrl: pt.string.isRequired,

  // List of parameters to display on a section table. If undefined, all display.
  initialDisplayParams: pt.arrayOf(pt.string),

  // The title of the page.
  pageTitle: pt.string,

  // The sub title for the page.
  pageTitleSub: pt.string,

  /** Actions will be appended to each row, they'll appear as buttons. */
  rowActions: TablePropTypes.actions,
};
