import React from 'react';
import { createSelector } from 'reselect';
// Selector
import getUserImageFromEntities from './getUserImageFromEntities';
// Component
import UserImageContainer from '../components/molecules/UserImageContainer';

const style = {
  content: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '88%',
  },
  image: {
    verticalAlign: 'middle',
  },
};

const TRIM_LIMIT = 36;
const trimLongContent = (content) => {
  if (content.length > TRIM_LIMIT) {
    return `${content.substring(0, TRIM_LIMIT)}...`;
  }
  return content;
};

const optionContent = (displayValue, email) => (
  <div style={style.content}>
    {email ? (
      <>
        <b>{trimLongContent(displayValue)}</b>
        {email && <p>{trimLongContent(email)}</p>}
      </>
    ) : (
      trimLongContent(displayValue)
    )}
  </div>
);

/**
 * Create a list of option that will contain the user image and the name
 * @param userSelectList
 * @returns {Array}
 */
const getUserSelectList = (userSelectList) => {
  const optionUsers = [];

  if (userSelectList && !userSelectList.isEmpty()) {
    userSelectList.forEach((usr, i) => {
      const img = (
        <UserImageContainer
          avatar
          style={style.image}
          thumb
          userId={Number(usr.get('pk'))}
        />
      );

      optionUsers.push({
        content: optionContent(usr.get('display_value'), usr.get('email')),
        image: img,
        key: i + 1,
        text: usr.get('display_value'),
        value: Number(usr.get('pk')),
      });
    });
  }
  return optionUsers;
};

const selector = createSelector([getUserImageFromEntities], getUserSelectList);

export const getUserSelectListTest = getUserSelectList;

export default selector;
