import React, { PureComponent } from 'react';
import { isNull, pick } from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  List as ListSemantic,
  Modal,
  Segment,
} from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import BaristaInboxesTable from './BaristaInboxesTable';
import InboxForm from './inboxes/InboxForm';
import CaseInboxAccountController from '../controllers/CaseInboxAccountController';
import EspConfirmationModal from '../../../v2/components/display/EspConfirmationModal';
import queryString from 'query-string';
import EmailServiceTypes from '../../globals/EmailServiceTypes';

class CaseInboxAccount extends PureComponent {
  static propTypes = {
    baristaInboxes: ImmutableProps.list,

    baristaTestConnectionSent: PropTypes.bool,

    baristaTestConnectionSuccess: PropTypes.bool,

    cancelEmailInboxErrors: PropTypes.func,

    closeFormInboxModal: PropTypes.func,

    confirmEmailInboxErrors: PropTypes.func,

    deleteBaristaInbox: PropTypes.func.isRequired,

    departmentList: ImmutableProps.list,

    getBaristaInboxes: PropTypes.func.isRequired,

    getPrimaryEmailCredentials: PropTypes.func.isRequired,

    isFormInboxError: PropTypes.bool,

    isLoadingBaristaInboxes: PropTypes.bool,

    isLoadingBaristaTestConnection: PropTypes.bool,

    isNewFormInbox: PropTypes.bool,

    isOnlyTestingConnection: PropTypes.bool,

    loadDepartmentList: PropTypes.func.isRequired,

    location: PropTypes.shape({
      search: PropTypes.string,
    }),

    showEmailFormErrors: ImmutableProps.list,

    temporalEmailInboxForm: PropTypes.shape({}),

    terminateEmailTestConnection: PropTypes.func.isRequired,

    testEmailConnection: PropTypes.func.isRequired,
    testedBox: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  static defaultProps = {
    baristaInboxes: Immutable.List(),
    baristaTestConnectionSent: false,
    baristaTestConnectionSuccess: false,
    cancelEmailInboxErrors: void 0,
    closeFormInboxModal: void 0,
    confirmEmailInboxErrors: void 0,
    departmentList: Immutable.List(),
    isFormInboxError: false,
    isLoadingBaristaInboxes: false,
    isLoadingBaristaTestConnection: false,
    isNewFormInbox: false,
    isOnlyTestingConnection: false,
    showEmailFormErrors: Immutable.List(),
    temporalEmailInboxForm: {},
    testedBox: null,
  };

  state = {
    connectingGmailAccount: null,
    credentialId: null,
    inboxAccountType: null,
    isAddInboxModalOpen: false,
  };

  componentDidMount() {
    const {
      getBaristaInboxes,
      getPrimaryEmailCredentials,
      loadDepartmentList,
      location,
    } = this.props;

    getPrimaryEmailCredentials();
    getBaristaInboxes();
    loadDepartmentList();
    const { success, credentialId } = queryString.parse(location.search);
    if (success) {
      this.setState({ connectingGmailAccount: credentialId });
    }
  }

  setIsNewFormSaved = (credentialId) => {
    this.setState({ credentialId });
  };

  reopenNewInboxModal = () => {
    const { getPrimaryEmailCredentials, getBaristaInboxes } = this.props;
    getPrimaryEmailCredentials();
    getBaristaInboxes();
    window.history.replaceState(null, null, window.location.pathname);
    this.setState({
      credentialId: null,
      inboxAccountType: EmailServiceTypes.GMAIL,
      isAddInboxModalOpen: true,
    });
  };

  handleToggleModal = () => {
    this.setState((prevState) => {
      if (prevState.isAddInboxModalOpen) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      return {
        credentialId: null,
        inboxAccountType: null,
        isAddInboxModalOpen: !prevState.isAddInboxModalOpen,
      };
    });
  };

  handleCloseFormInboxModal = () => {
    const { closeFormInboxModal } = this.props;
    closeFormInboxModal();
  };

  // Note:
  // Building these as arrow functions help in optimizing 2 things.
  // 1 - It makes these triggers lazy so they're only created when needed
  // 2 - They are bound to the *this*.
  // Without binding this trigger to *this* it doesn't work since it's passed down to the
  // table component
  //
  renderEmptyStateModalTrigger = () => (
    <Button
      basic
      content={'Setup your first inbox'}
      onClick={this.handleToggleModal}
      primary
    />
  );

  renderMainModalTrigger = () => (
    <Button
      content={'Add Inbox'}
      floated='right'
      onClick={this.handleToggleModal}
      primary
    />
  );

  render() {
    const {
      baristaInboxes,
      baristaTestConnectionSent,
      baristaTestConnectionSuccess,
      cancelEmailInboxErrors,
      confirmEmailInboxErrors,
      deleteBaristaInbox,
      departmentList,
      isFormInboxError,
      isLoadingBaristaInboxes,
      isLoadingBaristaTestConnection,
      isNewFormInbox,
      isOnlyTestingConnection,
      showEmailFormErrors,
      testedBox,
      terminateEmailTestConnection,
      testEmailConnection,
    } = this.props;

    const {
      credentialId,
      isAddInboxModalOpen,
      connectingGmailAccount,
      inboxAccountType,
    } = this.state;

    // Note: credentials: {id, email_address} are needed to be sent as is for the
    // testEmailConnection dispatch prop to work
    const mappingFunction = (caseInbox) => ({
      credentials: caseInbox.credential,
      eid: caseInbox.eid,
      id: caseInbox.id,
      lastEmailScanned: caseInbox.last_email_scan_time,
      serviceDepartment: pick(caseInbox.esp_service_department, ['id', 'name']),
    });

    // return null here, since we don't want to show an empty state while it loads
    const inboxes = baristaInboxes
      ? baristaInboxes.toJS().map(mappingFunction)
      : null;

    const getEmailInboxErrors = (
      <div className='content' style={{ padding: '1.5rem' }}>
        <ListSemantic>
          {showEmailFormErrors.map((error) => (
            <ListSemantic.Item key={error.get('id')}>
              {error.get('message')}
            </ListSemantic.Item>
          ))}
        </ListSemantic>
      </div>
    );

    return (
      <Segment
        attached='bottom'
        loading={isLoadingBaristaInboxes || isLoadingBaristaTestConnection}
      >
        {this.renderMainModalTrigger()}
        {!isNull(inboxes) && (
          <BaristaInboxesTable
            baristaTestConnectionSent={baristaTestConnectionSent}
            baristaTestConnectionSuccess={baristaTestConnectionSuccess}
            connectingGmailAccount={connectingGmailAccount}
            data={inboxes}
            deleteBaristaInbox={deleteBaristaInbox}
            departmentList={departmentList}
            isNewFormInbox={isNewFormInbox}
            isOnlyTestingConnection={isOnlyTestingConnection}
            reopenNewInboxModal={this.reopenNewInboxModal}
            setupFirstInbox={this.renderEmptyStateModalTrigger}
            terminateEmailTestConnection={terminateEmailTestConnection}
            testConnection={testEmailConnection}
            testedBox={testedBox}
          />
        )}
        <EspConfirmationModal
          cancelButton='Cancel'
          confirmButton='Edit Settings'
          content={getEmailInboxErrors}
          header={'Error Creating Email Box'}
          onCancel={cancelEmailInboxErrors}
          onConfirm={confirmEmailInboxErrors}
          open={isFormInboxError}
        />
        <Modal
          className='scrolling'
          content={
            <InboxForm
              credentialId={credentialId}
              departmentList={departmentList}
              inboxAccountType={inboxAccountType}
              onClose={this.handleToggleModal}
              setIsNewFormSaved={this.setIsNewFormSaved}
            />
          }
          header='Add a Virtual Agent Inbox'
          onClose={this.handleToggleModal}
          open={isAddInboxModalOpen}
          size='large'
        />
      </Segment>
    );
  }
}
const CaseInboxAccountTest = CaseInboxAccount;

// eslint-disable-next-line no-class-assign -- DEV-1526
CaseInboxAccount = CaseInboxAccountController(CaseInboxAccount);
export { CaseInboxAccountTest };
export default CaseInboxAccount;
