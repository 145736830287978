import { createSelector } from 'reselect';

import SelectMyGearStepTypes from '../globals/SelectMyGearStepTypes';

const getSelectMyGearSteps = (state) =>
  state.getIn(['selectMyGear', 'steps', 'values']);

const getSelectedProductIDsList = (state) =>
  state.getIn(['selectMyGear', 'selectedProductIDs']);

const mapProductStep = (step, selectedProductIDs) => {
  const product = step.get('entity');
  const isSelected = selectedProductIDs.has(product.get('id'));

  step = step.set('isSelected', isSelected);

  return step;
};

const mapProductFamily = (step, selectedProductIDs) => {
  let items = step.get('items');

  items = items.map((item) => {
    const product = item.get('entity');

    const isSelected = selectedProductIDs.has(product.get('id'));

    item = item.set('isSelected', isSelected);

    return item;
  });

  step = step.set('items', items);

  return step;
};

const mapProductBundle = (step, selectedProductIDs) => {
  let items = step.get('items');

  items = items.map((item) => {
    // can be a Product or a Product Family step
    if (item.get('type') === SelectMyGearStepTypes.PRODUCT) {
      return mapProductStep(item, selectedProductIDs);
    } else {
      // has to be a product family
      return mapProductFamily(item, selectedProductIDs);
    }
  });

  step = step.set('items', items);

  return step;
};

const getSelectMyGearStepsWithSelections = (steps, selectedProductIDsList) => {
  if (!steps) {
    return null;
  }

  const stepsWithSelections = steps.map((step, i) => {
    const selectedProductIDs = selectedProductIDsList.get(i);

    if (step.get('type') === SelectMyGearStepTypes.PRODUCT) {
      return mapProductStep(step, selectedProductIDs);
    } else if (step.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
      return mapProductFamily(step, selectedProductIDs);
    } else {
      // it has type === SelectMyGearStepTypes.PRODUCT_BUNDLE
      return mapProductBundle(step, selectedProductIDs);
    }
  });

  return stepsWithSelections;
};

const selector = createSelector(
  [getSelectMyGearSteps, getSelectedProductIDsList],
  getSelectMyGearStepsWithSelections
);

export default selector;
