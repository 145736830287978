import _ from 'lodash';

/**
 * Detects groups of one or more consecutive blank characters in a String.
 * @type {RegExp}
 */
const blankRegex = /\s+/;

/**
 * Tells if String is made of all blank characters.
 * @type {RegExp}
 */
const allBlankRegex = /^\s+$/;

/**
 * Splits String in alternate groups of blank and non blank sub strings.
 * '  Hello   World '.match(blankAndNonBlankRegex) returns ['  ', 'Hello', '   ', 'World', ' ']
 * @type {RegExp}
 */
const blankAndNonBlankRegex = /\s+|[^\s]+/g;

/**
 * Split 'term' in an Array of non empty lowercase tokens sorted
 * in descending order, so if token 't' is a prefix of token 'T',
 * 't' will come after 'T'.
 *
 * tokenizeTerm('BB b   A aa cA') returns ['ca', 'bb', 'b', 'aa', 'a']
 *
 * In above example, note that 'b' comes after 'bb' and 'a' comes after 'aa'.
 *
 * @param {string} term
 * @return {Array.<string>}
 */
const tokenizeTerm = (term) =>
  term
    .trim()
    .split(blankRegex)
    .map((token) => token.toLowerCase())
    .sort()
    .reverse();

const TextUtils = {};

/**
 * Tells if 'text' contains a match of any token from 'term'.
 *
 * @param {string} text
 * @param {string} term
 * @return {boolean}
 */
TextUtils.hasStartsWithMatches = (text, term) => {
  // no matches can happen in empty String
  if (_.isEmpty(text)) {
    return false;
  }

  const termTokens = tokenizeTerm(term);

  const hasMatches = text.match(blankAndNonBlankRegex).some((textToken) => {
    const isAllBlank = allBlankRegex.test(textToken);

    if (isAllBlank) {
      return false;
    }

    const lowerTextToken = textToken.toLowerCase();

    const matchingTermTokenExists = termTokens.some((termToken) =>
      lowerTextToken.startsWith(termToken)
    );

    return matchingTermTokenExists;
  });

  return hasMatches;
};

/**
 * Tells if 'sources' contains matches for all tokens from 'term'.
 * @param {Array.<string>} sources
 * @param {string} term
 * @return {boolean}
 */
TextUtils.fullyStartsWithMatches = (sources, term) => {
  const termTokens = tokenizeTerm(term);

  // for every 'termToken' in 'term',
  // does exist a 'text' in 'sources',
  // such that there exists a 'textToken' in 'text',
  // such that 'textToken' starts with 'termToken'?
  const allMatch = termTokens.every((termToken) =>
    sources.some((text) => {
      if (_.isEmpty(text)) {
        return false;
      }

      return text.match(blankAndNonBlankRegex).some((textToken) => {
        const isAllBlank = allBlankRegex.test(textToken);

        if (isAllBlank) {
          return false;
        }

        const lowerTextToken = textToken.toLowerCase();

        return lowerTextToken.startsWith(termToken);
      });
    })
  );

  return allMatch;
};

/**
 * Highlight matches of tokens from 'term' found in words of 'text'.
 * @param {string} text
 * @param {string} term
 * @param {function} renderHighlight
 */
TextUtils.highlightStartsWithMatches = (text, term, renderHighlight) => {
  // no matches can happen in empty String
  if (_.isEmpty(text)) {
    return text;
  }

  const termTokens = tokenizeTerm(term);

  const highlightedText = text
    .match(blankAndNonBlankRegex)
    .map((textToken, i) => {
      const isAllBlank = allBlankRegex.test(textToken);

      if (isAllBlank) {
        // return token as blank space
        return '\u00A0';
      }

      const lowerTextToken = textToken.toLowerCase();

      // is there a 'termToken' that is prefix of 'lowerTextToken'?
      const matchingTermToken = termTokens.find(
        (termToken) => -lowerTextToken.startsWith(termToken)
      );

      if (!matchingTermToken) {
        // no match found, return token as is
        return textToken;
      }

      // there was a match!, lets highlight it!
      const match = textToken.slice(0, _.size(matchingTermToken));
      const rest = textToken.slice(_.size(matchingTermToken));

      return [renderHighlight(match, i), rest];
    });

  return highlightedText;
};

export default TextUtils;
