const api_barista_v0_1_application_individual = {
  active: true,
  created_in_elc: true,
  default_task_type: null,
  description: 'Demo Only Content',
  eid: '6a9f0e6b-5402-4f81-8551-0f8dfba2a174',
  is_protected: true,
  last_sync_time: '2020-07-31T05:38:57.921798Z',
  name: '1_DEMO',
  override_faq: true,
  override_intent_id: '04e9e7de-6415-48db-9685-cbe1eb137710',
  sys_created_by: null,
  sys_date_created: '2020-07-31T05:38:57.949678Z',
  sys_date_updated: '2020-07-31T16:42:19.582400Z',
  sys_updated_by: 'espbeta02@gmail.com',
  type: 'FAQ',
};

export default api_barista_v0_1_application_individual;
