import React, { Component } from 'react';
import classNames from 'classnames';

// Globals
import TypeText, { TypeDefaults } from '../../../globals/blocks/TypeText';

class Text01 extends Component {
  static propTypes = TypeText;
  static defaultProps = TypeDefaults;

  render() {
    const { type, template, text } = this.props;

    return (
      <div
        className={classNames('block padding', {})}
        data-blocktype={type}
        data-template={template}
      >
        {text}
      </div>
    );
  }
}

export default Text01;
