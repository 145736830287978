import moment from 'moment';
import LocaleSingleton from './LocaleSingleton';

/**
 * Format a date in a specific format
 * @param date  {timestamp}
 * @param passHour {boolean}  Allow to pass the hour in the final formatted date
 * @param forcedLocale {string} Allow to force locale
 * @param forceUtc {boolean} Unit test purpose only to force a timezone
 * @returns {*}
 */
const dateFormat = (date, passHour, forcedLocale, forceUtc) => {
  if (!date) {
    return null;
  }

  // Get the current locale
  const locale = forcedLocale || LocaleSingleton.get();

  const momentDate = forceUtc ? moment.utc(date) : moment(date);
  const datePreFormatted = momentDate.locale(locale).format('L');

  let dateHour = '';

  if (passHour) {
    const year = momentDate.locale(locale).format('YYYY');
    const hour = momentDate.locale(locale).format('LLL').split(year);
    dateHour = hour[hour.length - 1];
  }

  // Return the date formatted
  return datePreFormatted.split('/').join('-') + dateHour;
};

export default dateFormat;
