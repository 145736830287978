import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesRideSharing extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M77.78 45.04l-4-5.64.46-1 2.35-.62s1.22-.56 1.45-1.08c.23-.52.17-.5.17-.5.073-1.223-.7-2.338-1.87-2.7-.885-.326-1.85-.365-2.76-.11 0 0-1.17.74-1.32 1.49-.308.715-.217 1.54.24 2.17l-.33.81-.71-.22h.07l-5.95-13.28c-.1-.11-.193-.228-.28-.35-.51-.39-2.11-1.32-5.82-1.51l-23.73.08s-4.16-.14-5.9 2.09l-5.87 11.9-.72 1.47-.32-.78c.443-.63.526-1.444.22-2.15-.14-.76-1.32-1.49-1.32-1.49-.91-.255-1.875-.216-2.76.11-1.17.362-1.943 1.477-1.87 2.7l.17.5c.17.5 1.45 1.08 1.45 1.08l2.35.62.43.9-.32.53-4.79 5.66s-1 1-1 5c0 0 0 6 .17 10.64l.43 8.91s.5 2.27 1.4 2.45l4.13.22s2 .17 1.93-1l.16-.77 48 .48c-.06 1.16 1.93 1 1.93 1l4.13-.22c.88-.17 1.38-2.42 1.38-2.42l.34-10.29v-8.23s.26-4.46-1.72-6.45zM30.66 25.15l34-.22 5.73 12.93-45.3-.14 5.57-12.57zm-8.68 39.13c-1.265 0-2.29-1.025-2.29-2.29 0-1.265 1.025-2.29 2.29-2.29 1.265 0 2.29 1.025 2.29 2.29 0 1.265-1.025 2.29-2.29 2.29zm4.25-15.72c-.6 2.84-3 4.74-5.37 4.24s-3.8-3.2-3.21-6c.59-2.8 3-4.74 5.37-4.24s3.8 3.16 3.2 5.99l.01.01zm39 14.55l-.88 2.76c-.093.595-.522 1.082-1.1 1.25 0 0-19.33 2.17-33-.11 0 0-.48 0-.4-.88l-.73-2.87s0-1 .85-.81c0 0 8.41 1.07 35.05-.11.01-.01.63.14.23.76l-.02.01zm1.95-8.39c-.11.24-.43.64-1.3.64l-37.7-.15c-.538.107-1.08-.166-1.316-.66-.236-.497-.106-1.09.316-1.44l2.09-2.6c.822-.934 1.988-1.493 3.23-1.55l28.71.1c1.234.065 2.396.598 3.25 1.49l2.37 2.74c.407.355.547.927.35 1.43zm5.22 9.52c-1.265 0-2.29-1.025-2.29-2.29 0-1.265 1.025-2.29 2.29-2.29 1.265 0 2.29 1.025 2.29 2.29-.01 1.253-1.027 2.265-2.28 2.27l-.01.02zm.87-11.43c-2.37.5-4.77-1.4-5.37-4.24-.6-2.84.84-5.55 3.21-6 2.37-.45 4.78 1.34 5.39 4.15.61 2.81-.86 5.58-3.22 6.08l-.01.01z' />
          <path d='M65.81 53.47H28l-.14.17c-.19.24-.24.43-.21.51.03.08.22.16.52.16l37.7.15c.35 0 .48-.09.5-.13.02-.04 0-.21-.23-.47l-.33-.39zm-4.62-3.53l-28.69-.1c-.797.053-1.557.357-2.17.87h33c-.624-.458-1.367-.726-2.14-.77zm2.99 1.65H29.51l-.8 1h36.33' />
        </g>
      </svg>
    );
  }
}

export default CatalogServicesRideSharing;
