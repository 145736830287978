import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStore extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M37.21 59.82l-6.09-.16L31 37.25c-.003-.37-.21-.71-.54-.88l-1.79-.92c-.367-.19-.81-.133-1.12.14l-1.93 1.71-6.73-7.1 11.34-11.58h3.38c1 1.66 4.61 6.55 10.37 6.55h.14c.3 0 7.17-.33 10.06-6.57h3.42l11.68 12-7 6.74-3.36-1.76c-.355-.188-.79-.144-1.1.11l-1.52 1.24c-.232.188-.368.47-.37.77v7.25c0 .552.448 1 1 1s1-.448 1-1v-6.89l.64-.52 3.42 1.79c.38.178.833.098 1.13-.2l8.25-7.94c.19-.188.296-.443.296-.71 0-.267-.107-.522-.296-.71l-12.69-13c-.192-.196-.456-.304-.73-.3l-4.5.06c-.406.004-.77.253-.92.63-2.19 5.65-8.25 5.97-8.48 5.97h-.08c-5.57 0-8.87-6-8.91-6-.188-.314-.524-.51-.89-.52l-4.37.06c-.264.004-.515.112-.7.3L16.78 29.49c-.375.388-.375 1.002 0 1.39l8.06 8.5c.37.392.986.418 1.39.06l2.14-1.9.64.33.11 22.76c0 .552.448 1 1 1l7.06.19c.552 0 1-.448 1-1s-.448-1-1-1h.03z' />
          <path d='M78.12 41.13h-9.39c-.735-.015-1.392.458-1.61 1.16l-7.34 23.6H46.93l-3.55-15.23c-.134-.575-.565-1.035-1.13-1.207-.565-.17-1.18-.028-1.61.375-.43.404-.614 1.007-.48 1.582l3.86 16.51c.173.75.84 1.28 1.61 1.28h15.4c.724.002 1.365-.47 1.58-1.16l7.34-23.6h8.17c.914 0 1.655-.74 1.655-1.655 0-.914-.74-1.655-1.655-1.655zM46.45 71.94c-2.28 0-4.13 1.85-4.13 4.13s1.85 4.13 4.13 4.13 4.13-1.85 4.13-4.13-1.85-4.13-4.13-4.13zm0 6.6c-1.003 0-1.907-.604-2.29-1.53-.385-.928-.173-1.994.536-2.704.71-.71 1.776-.92 2.703-.537.92.38 1.53 1.28 1.53 2.29 0 .66-.26 1.29-.73 1.76-.465.46-1.098.73-1.757.73v-.01zm13.2-6.6c-2.28 0-4.13 1.85-4.13 4.13s1.85 4.13 4.13 4.13 4.13-1.85 4.13-4.13-1.85-4.13-4.13-4.13zm0 6.6c-1.005.004-1.913-.6-2.3-1.526-.386-.928-.174-1.997.536-2.708.71-.71 1.78-.922 2.708-.535.927.39 1.53 1.3 1.526 2.3 0 1.37-1.104 2.48-2.47 2.48v-.01z' />
        </g>
      </svg>
    );
  }
}

export default CatalogCompanyStore;
