import { intl } from 'esp-util-intl';
import { Menu } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import uiPathGenerator from '../../utils/uiPathGenerator';

class AdminTitle extends PureComponent {
  renderTitle = (title) => () =>
    (
      <Menu.Item header>
        {intl.formatMessage({
          id: title,
        })}
      </Menu.Item>
    );

  renderDefaultTitle = () => (
    <Menu.Item header>{'Missing title in <AdminTitle />'}</Menu.Item>
  );

  render() {
    return (
      <Switch>
        <Route
          path={uiPathGenerator.genFullPattern('admin.approvalRules')}
          render={this.renderTitle('label.admin_title_approval_rules')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.dpc')}
          render={this.renderTitle('label.admin_title_dpc')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.dashboard')}
          render={this.renderTitle('label.dashboard')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.storefrontDesigner')}
          render={this.renderTitle('label.admin_title_storefront_designer')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern('admin.bundle.new')}
          render={this.renderTitle('label.admin_title_new_bundle')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.bundle')}
          render={this.renderTitle('label.admin_title_bundles')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern('admin.roleBundle.new')}
          render={this.renderTitle('label.admin_title_new_role_bundle')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.roleBundle')}
          render={this.renderTitle('label.admin_title_role_bundles')}
        />
        <Route
          path={uiPathGenerator.genFullPattern(
            'admin.adminActivities.offboarding'
          )}
          render={this.renderTitle('label.admin_activities_offboarding_title')}
        />
        <Route
          path={uiPathGenerator.genFullPattern(
            'admin.adminActivities.onboardingActivities.templates'
          )}
          render={this.renderTitle(
            'label.admin_title_onboarding_activities_templates'
          )}
        />
        <Route
          path={uiPathGenerator.genFullPattern(
            'admin.adminActivities.onboardingActivities.schedule'
          )}
          render={this.renderTitle(
            'label.admin_title_onboarding_activities_schedule'
          )}
        />
        <Route
          path={uiPathGenerator.genFullPattern(
            'admin.adminActivities.onboardingActivities.performance'
          )}
          render={this.renderTitle(
            'label.admin_title_onboarding_activities_performance'
          )}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.serviceDepartment')}
          render={this.renderTitle('label.admin_title_service_department')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.serviceTeams')}
          render={this.renderTitle('label.admin_title_service_teams')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.reports')}
          render={this.renderTitle('label.admin_title_reports')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.locations')}
          render={this.renderTitle('label.admin_title_locations')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.faqsV2')}
          render={this.renderTitle('label.admin_faqs_v2')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.user_groups')}
          render={this.renderTitle('label.admin_title_users')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.users')}
          render={this.renderTitle('label.admin_title_users')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.integrations.csv')}
          render={this.renderTitle('label.admin_title_csv_integration')}
        />
        <Route
          path={uiPathGenerator.genFullPattern(
            'admin.integrations.service_now'
          )}
          render={this.renderTitle('label.admin_title_service_now_integration')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.integrations.okta')}
          render={this.renderTitle('label.admin_title_okta_integration')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.integrations')}
          render={this.renderTitle('label.admin_title_integrations')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.authentication')}
          render={this.renderTitle('label.admin_title_authentication')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.globalSettings')}
          render={this.renderTitle('label.admin_title_global_settings')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.adminInteractions')}
          render={this.renderTitle('label.admin_title_interactions')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.interactionsDashboard')}
          render={this.renderTitle('label.admin_title_interactions')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern('admin.knowledgeArticles')}
          render={this.renderTitle('label.kb_dashboard')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern('admin.knowledgeArticlesV2')}
          render={this.renderTitle('label.kb_dashboard')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern(
            'admin.knowledgeArticlesV2.table'
          )}
          render={this.renderTitle('label.kb_dashboard')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern('admin.knowledgeArticles.table')}
          render={this.renderTitle('label.knowledge_articles')}
        />
        <Route
          exact
          path={uiPathGenerator.genFullPattern(
            'admin.knowledgeArticles.detail'
          )}
          render={this.renderTitle('label.knowledge_article_detail')}
        />
        <Route
          path={uiPathGenerator.genFullPattern('admin.tickets')}
          render={this.renderTitle('label.admin.tickets')}
        />
        AdminTitle
        <Route render={this.renderDefaultTitle} />
      </Switch>
    );
  }
}

export default AdminTitle;
