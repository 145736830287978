import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  buttonText: {
    required: true,
    type: 'string',
  },
  image: {
    default: {},
    objectShape: {
      mHeight: {
        required: true,
        type: 'number',
      },
      mWidth: {
        required: true,
        type: 'number',
      },
      onSubmit: {
        required: true,
        type: 'string',
      },
      type: {
        required: true,
        type: 'string',
      },
    },
    type: 'object',
  },
  inputValue: {
    default: {
      map: '',
      value: '',
    },
    objectShape: {
      map: {
        required: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    type: 'object',
  },
  modalButtonText: {
    required: true,
    type: 'string',
  },
  modalText: {
    required: true,
    type: 'string',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'imageSelect01',
  },
});

// Object Definition used by blobifier
const ImageSelectBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Image Select',
  templates: ['imageSelect01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, ImageSelectBlockDefinition, TypeDefaults };
