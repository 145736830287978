import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Label, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { Field } from 'redux-form/immutable';

// atoms
import EspSlider from '../../atoms/EspSlider';

// Molecules
import RoleBundleGroupSelect from '../../molecules/RoleBundleGroupSelect';
import FormInputText from '../../molecules/FormInputText';
import MessageEmptyState from '../../molecules/MessageEmptyState';

// Organisms
import BlockModal from '../../organisms/BlockModal';
import RoleBundleListItem from '../../organisms/RoleBundleListItem';

// Globals
import TypeRoleSelect, {
  TypeDefaults,
} from '../../../globals/blocks/TypeRoleSelect';

// Controllers
import RoleSelectController from '../../controllers/RoleSelectController';

class RoleSelect01 extends Component {
  static propTypes = {
    ...TypeRoleSelect,
    getJobRolesWithBundles: PropTypes.func,
    isLoading: PropTypes.bool,
    jobRoles: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    updateWorkflowRequestedForUser: PropTypes.func,
    workflowRequestedFor: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    getJobRolesWithBundles: _.noop,
    isLoading: false,
    updateWorkflowRequestedForUser: _.noop,
  };

  state = {
    selectedJobRole: null,
  };

  componentDidMount() {
    const { getJobRolesWithBundles, workflowRequestedFor } = this.props;

    getJobRolesWithBundles(workflowRequestedFor.get('job_role_id'));
  }

  /**
   * Selects the very first job role as default if user doesn't have one assigned.
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { workflowRequestedFor } = this.props;
    const { jobRoles } = nextProps;
    const { selectedJobRole } = this.state;

    let defaultSelectedJobRoleID = workflowRequestedFor.get('job_role_id');

    if (
      defaultSelectedJobRoleID &&
      !jobRoles.find(
        (jobRole) => jobRole.get('id') === defaultSelectedJobRoleID
      )
    ) {
      defaultSelectedJobRoleID = null; // Reset the selected jobrole if this jobrole is not available anymore
    }

    if (
      !selectedJobRole &&
      !defaultSelectedJobRoleID &&
      jobRoles &&
      !jobRoles.isEmpty()
    ) {
      this.handleSelectRole(jobRoles.first());
    }
  }

  handleChangeButtonClick = (e) => {
    e.preventDefault();
  };

  handleSelectRole = (selectedJobRole) => {
    const { updateWorkflowRequestedForUser } = this.props;

    this.setState({
      selectedJobRole,
    });

    if (this.selectRoleModal) {
      this.selectRoleModal.close();
    }

    // optimistically update user's mentor with selected one

    /** @type {string} */
    const jobRoleUrl = selectedJobRole.get('url');

    const changes = {
      job_role: jobRoleUrl,
    };

    updateWorkflowRequestedForUser(changes);
  };

  setSelectRoleModal = (selectRoleModal) => {
    this.selectRoleModal = selectRoleModal;
  };

  fieldValidation = () => {
    const { isLoading, jobRoles, workflowRequestedFor } = this.props;

    const jobRole = workflowRequestedFor.get('job_role_id');

    if (!isLoading && (!jobRole || jobRoles.isEmpty())) {
      return 'Validation errors';
    }
    return null;
  };

  render() {
    const { isLoading, jobRoles, workflowRequestedFor } = this.props;

    let { selectedJobRole } = this.state;

    if (!selectedJobRole) {
      const defaultSelectedJobRoleID = workflowRequestedFor.get('job_role_id');

      // we need to pick the job role from 'jobRoles' because it must contain the available bundles
      if (jobRoles) {
        selectedJobRole = jobRoles.find(
          (jobRole) => jobRole.get('id') === defaultSelectedJobRoleID
        );
      }
    }

    const { template, type } = this.props;

    const selectTrigger = (
      <Button
        className='text'
        content='Change'
        data-test-id='change-role-button'
        floated='right'
        onClick={this.handleChangeButtonClick}
      />
    );

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {/* Empty state */}
        {!isLoading && jobRoles.isEmpty() && (
          <MessageEmptyState
            content='There are no role bundles configured for your user'
            header='Please contact an admin'
            negative
          />
        )}
        {jobRoles && selectedJobRole ? (
          <Segment.Group>
            <Segment clearing>
              <BlockModal
                modalTitle='Select a Role'
                ref={this.setSelectRoleModal}
                trigger={selectTrigger}
              >
                {jobRoles.map((jobRole) => (
                  <RoleBundleGroupSelect
                    isSelected={jobRole.get('id') === selectedJobRole.get('id')}
                    key={jobRole.get('id')}
                    onSelectRole={this.handleSelectRole}
                    role={jobRole}
                  />
                ))}
              </BlockModal>
              <Header as='h5' className='fitted'>
                <Label className='text' content='Role' horizontal />
                {selectedJobRole.get('name')}
              </Header>
            </Segment>
            <Segment className='fitted'>
              <EspSlider key={selectedJobRole.get('id')} pageNumbers>
                {selectedJobRole.get('availableBundles').map((bundle) => (
                  <RoleBundleListItem bundle={bundle} key={bundle.get('id')} />
                ))}
              </EspSlider>
            </Segment>
          </Segment.Group>
        ) : null}
        {/* Note: This is a hidden input in order to force the validation through Redux-form and avoid NEXT to be clickable until a job role has been selected */}
        <Field
          component={FormInputText}
          hidden
          name='job_role_selected'
          validate={this.fieldValidation}
        />
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
RoleSelect01 = RoleSelectController(RoleSelect01);

export default RoleSelect01;
