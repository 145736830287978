import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useOffboardingSchedule from '../hooks/useOffboardingSchedule';
// Utils
import uiPathGenerator from '../../../../../utils/uiPathGenerator';
// Global
import ScheduleState from '../../../../../globals/ScheduleState';

const propTypes = {
  /** Action on a schedule ID (copy) */
  action: PropTypes.string,
  /** Boolean to active REPUBLISH */
  allowRepublish: PropTypes.bool,
  /** Disable button */
  disableButtons: PropTypes.bool,
  /** Template selected is empty */
  emptyTemplate: PropTypes.bool,
  formValues: PropTypes.shape({}),
  /** Main Loading state */
  isLoading: PropTypes.bool,
  /** Save Schedule */
  // saveSchedule: PropTypes.func.isRequired,
  /** ID of the template OR 'new' for a new template. Please update type checking for this. */
  schedule: PropTypes.shape({}),
  scheduleID: PropTypes.string,
  /** schedule status */
  status: PropTypes.string,
};
const ACTIONS = {
  COPY: 'copy',
  LABELS: {
    SAVE_DRAFT: 'Save Draft',
    UPDATE_DRAFT: 'Update Draft',
  },
};

const NEW_ID = 'new';

const OffboardingScheduleFormButtons = ({
  action,
  allowRepublish,
  disableButtons,
  formValues,
  isLoading,
  schedule,
  scheduleID,
  status,
  emptyTemplate,
}) => {
  const {
    saveDraft,
    updateScheduleStatus,
    goToScheduleInstancePage,
  } = useOffboardingSchedule();

  const handleSaveDraft = useCallback(
    (e, { actionname }) => {
      saveDraft({
        action,
        formValues,
        newStatus: actionname,
        schedule,
        scheduleID,
      });
    },
    [action, formValues, saveDraft, schedule, scheduleID]
  );
  const handlePublishSchedule = useCallback(
    (e, { actionname }) => {
      saveDraft({
        formValues,
        newStatus: ScheduleState.STATUS_DRAFT,
        schedule,
        scheduleID,
      }).then(() => {
        updateScheduleStatus(scheduleID, actionname).then(() => {
          goToScheduleInstancePage(scheduleID);
        });
      });
    },
    [
      formValues,
      goToScheduleInstancePage,
      saveDraft,
      schedule,
      scheduleID,
      updateScheduleStatus,
    ]
  );
  const handleUnpublishSchedule = useCallback(
    (e, { actionname }) => {
      updateScheduleStatus(scheduleID, actionname).then(() => {
        goToScheduleInstancePage(scheduleID);
      });
    },
    [goToScheduleInstancePage, scheduleID, updateScheduleStatus]
    // []
  );
  const handleArchiveSchedule = useCallback(
    (e, { actionname }) => {
      updateScheduleStatus(scheduleID, actionname).then(() => {
        goToScheduleInstancePage(scheduleID);
      });
    },
    [goToScheduleInstancePage, scheduleID, updateScheduleStatus]
  );

  const buttonDraft =
    scheduleID === NEW_ID || action === ACTIONS.COPY
      ? ACTIONS.LABELS.SAVE_DRAFT
      : ACTIONS.LABELS.UPDATE_DRAFT;

  const displayDraft =
    scheduleID === NEW_ID ||
    status === ScheduleState.STATUS_DRAFT ||
    action === ACTIONS.COPY;
  const isArchived = status === ScheduleState.STATUS_ARCHIVED;

  const disableAllButtons = isLoading;

  return (
    <Form.Group className='button-actions' grouped>
      <Button
        as={Link}
        className='text'
        content='Back'
        floated='left'
        icon='left chevron'
        to={uiPathGenerator.genPath(
          'admin.adminActivities.offboarding.schedule'
        )}
      />

      {displayDraft && (
        <Button
          actionname={ScheduleState.STATUS_DRAFT}
          basic
          content={buttonDraft}
          disabled={disableButtons || disableAllButtons}
          onClick={handleSaveDraft}
        />
      )}

      {displayDraft && scheduleID !== 'new' && (
        <Button
          actionname={ScheduleState.STATUS_PUBLISHED}
          basic
          content='Publish'
          disabled={disableButtons || emptyTemplate || disableAllButtons}
          onClick={handlePublishSchedule}
        />
      )}

      {!displayDraft && !isArchived && (
        <Button
          actionname={ScheduleState.STATUS_PUBLISHED}
          basic
          content='Republish'
          disabled={!allowRepublish || disableAllButtons}
          onClick={handlePublishSchedule}
        />
      )}

      {!displayDraft && !isArchived && (
        <Button
          actionname={ScheduleState.STATUS_DRAFT}
          basic
          content='Unpublish'
          disabled={disableAllButtons}
          onClick={handleUnpublishSchedule}
        />
      )}

      {!displayDraft && !isArchived && (
        <Button
          actionname={ScheduleState.STATUS_ARCHIVED}
          basic
          content='Archive'
          disabled={disableAllButtons}
          onClick={handleArchiveSchedule}
        />
      )}

      {!displayDraft && isArchived && (
        <Button basic content='Archived' disabled />
      )}
    </Form.Group>
  );
};
OffboardingScheduleFormButtons.propTypes = propTypes;
export default OffboardingScheduleFormButtons;
