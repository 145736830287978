import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwareTablet extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(23 16)'>
          <path d='M44.94 63.92H5c-2.76 0-5-2.24-5-5V5c0-2.76 2.24-5 5-5h39.94c2.76 0 5 2.24 5 5v53.92c0 2.76-2.24 5-5 5zM5 2C3.343 2 2 3.343 2 5v53.92c0 1.657 1.343 3 3 3h39.94c1.657 0 3-1.343 3-3V5c0-1.657-1.343-3-3-3H5z' />
          <rect height='44.89' rx='2' width='38.44' x='5.6' y='5.73' />
          <path d='M24.66 59.13c-1.14 0-2.17-.687-2.605-1.74-.437-1.055-.196-2.268.61-3.074.807-.807 2.02-1.048 3.074-.61 1.05.435 1.74 1.463 1.74 2.604 0 1.557-1.268 2.82-2.82 2.82zm0-4.64c-.736 0-1.4.443-1.68 1.124-.283.68-.127 1.462.393 1.983.52.52 1.303.676 1.983.394.68-.28 1.124-.94 1.124-1.68 0-1-.815-1.82-1.82-1.82z' />
        </g>
      </svg>
    );
  }
}

export default CatalogHardwareTablet;
