import { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string.isRequired,
};

const BodyClassName = ({ className, children }) => {
  useEffect(() => {
    const classes = className ? className?.split(' ') : [];

    // Set up
    classes.map((classItem) => window.document.body.classList.add(classItem));

    // Clean up
    return () => {
      classes.map((classItem) =>
        window.document.body.classList.remove(classItem)
      );
    };
  }, [className]);

  return children;
};

BodyClassName.propTypes = propTypes;

export default BodyClassName;
