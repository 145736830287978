import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { noop } from 'lodash';

import SelectableUser from './SelectableUser';

const propTypes = {
  isLoading: PropTypes.bool,
  onSelectUser: PropTypes.func,
  selectedUser: ImmutablePropTypes.map,
  users: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
};

const SelectableUsers = ({
  users,
  selectedUser = null,
  isLoading = false,
  onSelectUser = noop,
}) => (
  <List divided verticalAlign='middle'>
    {users.map((user) => {
      const isSelected =
        Boolean(selectedUser) && user.get('id') === selectedUser.get('id');
      return (
        <SelectableUser
          isLoading={isLoading}
          isSelected={isSelected}
          key={user.get('id')}
          onClick={onSelectUser}
          user={user}
        />
      );
    })}
  </List>
);

SelectableUsers.propTypes = propTypes;

export default SelectableUsers;
