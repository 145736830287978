import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import ChatMessageWrapper from './ChatMessageWrapper';
import ChatMessageBubbleDashed from './ChatMessageBubbleDashed';
import ChatMessageDashedSection from './ChatMessageDashedSection';
import ChatMessageTimeStamp from './ChatMessageTimeStamp';
import ChatMessageButton from './ChatMessageButton';
import ChatInputTypes from './ChatInputTypes';

import UserImg from '../../functional/UserImg';

const rule = () => {};

class ChatMessageInput extends PureComponent {
  static propTypes = {
    attachmentSize: PropTypes.number,
    channelId: PropTypes.string,
    hideAvatar: PropTypes.bool,
    input: PropTypes.shape({
      handleOptionSelection: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      options: PropTypes.array,
      type: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    inputResponse: PropTypes.string,
    isCaseMgmt: PropTypes.bool, // passed directly. If passed then it's assumed this chat belongs to a case
    isFirstMessage: PropTypes.bool.isRequired,
    isLastMessage: PropTypes.bool.isRequired,
    isMe: PropTypes.bool.isRequired,
    messageText: PropTypes.string,
    showTimeStamp: PropTypes.bool,
    timeStamp: PropTypes.string.isRequired,
  };

  static defaultProps = {
    attachmentSize: 0,
    channelId: '',
    hideAvatar: false,
    inputResponse: void 0,
    isCaseMgmt: false,
    messageText: void 0,
    showTimeStamp: false,
  };

  render() {
    const {
      isCaseMgmt,
      channelId,
      hideAvatar,
      input,
      inputResponse,
      isFirstMessage,
      isLastMessage,
      messageText,
      showTimeStamp,
      timeStamp,
    } = this.props;

    const isMe = false;

    const placement = {
      isFirstMessage,
      isLastMessage,
      isMe,
      showTimeStamp,
    };

    const isButton = input.type === ChatInputTypes.BUTTON;
    const isRadio = input.type === ChatInputTypes.RADIO;
    const isFeedback = input.type === ChatInputTypes.FEEDBACK;

    return (
      <ChatMessageWrapper {...placement} input={input}>
        <ChatMessageBubbleDashed>
          {messageText ? (
            <ChatMessageDashedSection>
              <>
                <Image
                  style={{
                    marginBottom: 0,
                    maxWidth: '3em',
                    paddingRight: '1em',
                    width: '100%',
                  }}
                >
                  <UserImg isBarista />
                </Image>
                {messageText}
              </>
            </ChatMessageDashedSection>
          ) : null}

          {isButton || isRadio || isFeedback ? (
            <ChatMessageButton
              {...placement}
              channelId={channelId}
              hideAvatar={hideAvatar}
              input={input}
              isCaseMgmt={isCaseMgmt}
              onUserSelection={input.handleOptionSelection}
            />
          ) : null}

          {timeStamp && showTimeStamp && isLastMessage ? (
            <ChatMessageTimeStamp timeStamp={timeStamp} />
          ) : null}

          {inputResponse ? (
            <ChatMessageDashedSection placement='bottom'>
              {inputResponse}
            </ChatMessageDashedSection>
          ) : null}
        </ChatMessageBubbleDashed>
      </ChatMessageWrapper>
    );
  }
}

export default createComponent(rule, ChatMessageInput, [
  'channelId',
  'hideAvatar',
  'input',
  'inputResponse',
  'isCaseMgmt',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'messageText',
  'showTimeStamp',
  'timeStamp',
]);
