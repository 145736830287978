import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { truncate } from 'lodash';

import { ImageDefaults } from 'esp-assets';
import TextHighlight from '../atoms/TextHighlight';
import TextUtils from '../../utils/TextUtils';
import uiPathGenerator from '../../utils/uiPathGenerator';
import ImmutableUtils from '../../utils/ImmutableUtils';

import EspListItem from './EspListItem';

const MAX_DESCRIPTION_LEN = 45;

const propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  result: ImmutablePropTypes.map.isRequired,
  routeToResult: PropTypes.string.isRequired,
  routeToResultIncludesQ: PropTypes.bool.isRequired,
  userInput: PropTypes.string.isRequired,
};

const ProductSearchResult = ({
  active = false,
  onClick,
  result,
  routeToResult,
  routeToResultIncludesQ,
  userInput,
}) => {
  const name = result.get('display_value');
  const nameEl = <TextHighlight term={userInput} text={name} />;
  const subcategories = result.get('category');
  const subcategoryNames = ImmutableUtils.pluck(subcategories, 'label').toJS();
  const commaSeparedSubcategoryNames = subcategoryNames.join(', ');
  const subcategoryNamesEl = (
    <span>
      {intl.formatMessage({
        id: 'label.in',
      })}{' '}
      <TextHighlight
        as='span'
        term={userInput}
        text={commaSeparedSubcategoryNames}
      />
    </span>
  );
  let descriptionEl = null;
  const nameAndSubcategoriesDontMatch =
    !TextUtils.hasStartsWithMatches(name, userInput) &&
    !TextUtils.hasStartsWithMatches(commaSeparedSubcategoryNames, userInput);

  if (nameAndSubcategoriesDontMatch) {
    let description = result.get('description') || '';
    description = truncate(description, MAX_DESCRIPTION_LEN);

    if (TextUtils.hasStartsWithMatches(description, userInput)) {
      descriptionEl = (
        <span>
          {intl.formatMessage({
            id: 'label.description',
          })}
          {': '}
          <TextHighlight as='span' term={userInput} text={description} />
        </span>
      );
    }
  }

  const image = result.get('image') || ImageDefaults.PRODUCT_IMAGE;
  const firstSubcategory = subcategories.first();
  const urlToResult = uiPathGenerator.genPath(routeToResult, {
    catalogID: result.get('pk'),
    categoryID: firstSubcategory ? firstSubcategory.get('parent') : null,
    subcategoryID: firstSubcategory ? firstSubcategory.get('pk') : null,
  });
  let toResult = urlToResult;

  if (routeToResultIncludesQ) {
    toResult = {
      pathname: urlToResult,
      search: `?q=${userInput}`,
    };
  }

  return (
    <EspListItem
      active={active}
      as={Link}
      content={subcategoryNamesEl}
      description={descriptionEl}
      image={image}
      isSearchResult
      name={nameEl}
      onClick={onClick}
      to={toResult}
    />
  );
};
ProductSearchResult.propTypes = propTypes;
export default ProductSearchResult;
