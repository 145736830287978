import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesLunchPrograms extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M78.36 57.64h-8.87l.6-3.14c1.31-7.5-.47-12.31-.52-12.42-2.35-7.46-10.23-8.51-11.29-8.61-3.23-.65-7.17.94-9.57 2.16.147-1.797.09-3.606-.17-5.39 4 .44 13-.08 12-14.37 0 0-12.51-3-13.92 8.36-.832-1.688-1.987-3.196-3.4-4.44-.282-.222-.66-.276-.994-.142-.333.134-.57.435-.62.79-.05.355.092.71.374.932.22.18 5.27 4.35 4.78 13.58-1.37-1-3.63-1.81-7.31-1.4-7.51.85-11.88 8.37-12 8.67-3.53 5.92-1.61 12.63-.57 15.33H17c-.552 0-1 .448-1 1s.448 1 1 1h1.39l3.81 19.28c.092.477.514.82 1 .81h49c.49.008.912-.34 1-.82l3.44-19.15h1.75c.552 0 1-.448 1-1s-.448-1-1-1l-.03-.03zm-48.31-.22c0-.08-3.68-7.61 0-13.71 0-.06 3.81-6.52 9.79-7.2 5.29-.6 6.07 1.87 6.09 1.93.11.477.446.87.9 1.054.455.183.97.133 1.38-.134 1.62-1 6.5-3.63 9.59-3h.2c.07 0 6.9.61 8.79 6.61 0 0 1.55 4.3.4 10.91l-.62 3.28c-.018.133-.018.267 0 .4H55.62c-.347 0-.67.183-.85.48-3.32 5.46-7.1 5-7.25 4.94h-.17c-4 0-6.47-4.9-6.49-5-.165-.347-.515-.57-.9-.57h-9.88s-.02.05-.04.02l.01-.01zm41.3 20.19H24l-3.57-18.08h7.86c.264.08.546.08.81 0h10.26c.87 1.51 3.56 5.47 7.92 5.52.19.01.38.01.57 0 1.49 0 5.15-.55 8.33-5.44h18.39l-3.22 18z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogServicesLunchPrograms;
