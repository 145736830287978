import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCommunicationPhonePlans extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M58.54 62.2v5.65H24.15v-43h34.39v4.31c.34 0 .68-.05 1-.05.32 0 .84 0 1.25.06V22c0-3.314-2.686-6-6-6H27.54c-3.314 0-6 2.686-6 6v52c0 3.314 2.686 6 6 6h27.27c3.314 0 6-2.686 6-6V62.19c-.41 0-.83.06-1.25.06-.42 0-.68-.03-1.02-.05zM38.6 19.48h5.11c.552 0 1 .448 1 1s-.448 1-1 1H38.6c-.552 0-1-.448-1-1s.448-1 1-1zm2.77 56.64c-1.215 0-2.2-.985-2.2-2.2 0-1.215.985-2.2 2.2-2.2 1.215 0 2.2.985 2.2 2.2 0 .583-.232 1.143-.644 1.556-.413.412-.973.644-1.556.644z' />
          <path d='M60.05 31h-.51c-7.983.208-14.346 6.74-14.346 14.725 0 7.986 6.363 14.517 14.346 14.725h.51c7.908-.292 14.168-6.787 14.168-14.7 0-7.913-6.26-14.408-14.168-14.7V31zm-5.85 3.66c-.91 1.48-1.585 3.093-2 4.78H49c1.232-2.062 3.04-3.72 5.2-4.77v-.01zm-6.93 11c0-1.298.205-2.587.61-3.82h3.83c-.186 1.265-.28 2.542-.28 3.82 0 1.002.057 2.004.17 3h-3.95c-.25-.967-.38-1.96-.39-2.96l.01-.04zm1.27 5.5h3.44c.404 1.974 1.148 3.862 2.2 5.58-2.443-1.188-4.425-3.15-5.64-5.58zm10 6.5c-1.61-.81-3.16-3.16-4-6.5h4v6.5zm0-8.95h-4.48c-.127-.995-.19-1.997-.19-3-.004-1.28.096-2.557.3-3.82h4.37v6.82zm0-9.27h-3.8c.88-2.91 2.3-4.95 3.79-5.69l.01 5.69zm11.56 0h-2.8c-.376-1.555-.97-3.05-1.76-4.44 1.874 1.06 3.44 2.59 4.54 4.44h.02zm-9.11-5.69c1.48.74 2.91 2.78 3.79 5.69h-3.81l.02-5.69zm0 23.91v-6.5h4c-.85 3.34-2.45 5.69-4.02 6.5h.02zm0-8.95v-6.82h4.35c.204 1.263.304 2.54.3 3.82 0 1.003-.064 2.005-.19 3h-4.46zm4.55 7.72c.943-1.64 1.618-3.418 2-5.27h3c-1.1 2.218-2.843 4.054-5 5.27zm2.41-7.72c.113-.996.17-1.998.17-3 0-1.278-.094-2.555-.28-3.82h3.41c.735 2.203.815 4.572.23 6.82h-3.53z' />
        </g>
      </svg>
    );
  }
}

export default CatalogCommunicationPhonePlans;
