import { change } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import faqThunks from '../../actions/faqThunks';
import { getChannelMessages } from '../../selectors/getMessages';

const mapStateToProps = (state, ownProps) => {
  const channel = state.getIn([
    'entities',
    'tasks',
    ownProps.taskId,
    'channel',
  ]);
  return {
    baristaID: state.getIn(['barista', 'baristaID']),
    caseAuthor: state.getIn(['entities', 'tasks', ownProps.taskId, 'author']),
    imageNewFaq: state.getIn(['adminFaqs', 'imageNewFaq']),
    messages: getChannelMessages(state, channel),
    tenantName: state.getIn(['tenant', 'entity', 'domain', 'domain']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFile(answerEID, file) {
    return dispatch(faqThunks.addFileToFaq(answerEID, file));
  },
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
