import buildCompositeController from '../../utils/buildCompositeController';
import cartThunks from '../../actions/cartThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import cartActions from '../../actions/cartActions';
import getItemsCart from '../../selectors/getItemsCart';
import getNumberItemsInCart from '../../selectors/getNumberItemsInCart';

const mapStateToProps = (state, ownProps) => {
  let cartTotal = 0;
  const cartItems = getItemsCart(state);

  cartItems.forEach((item) => {
    const qty = item.get('qty') || 1;
    cartTotal += Number(item.get('price')) * qty;
  });

  const cartNumItems = getNumberItemsInCart(state);

  return {
    cartItems,
    cartNumItems,
    cartTotal,
    context: state.getIn(['search', 'contextName']),
    error: state.getIn(['cart', 'error']),
    isLoading: ownProps.isLoading || state.getIn(['cart', 'isLoading'], false),
    loaded: state.getIn(['cart', 'loaded']),
    modalOpen: state.getIn(['catalog', 'shopCartOpened']),
    status: state.getIn(['cart', 'status']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  cartFillFromBundle(idItem) {
    dispatch(cartThunks.fillFromBundle(idItem));
  },

  cartLoad() {
    dispatch(cartThunks.loadUserCart());
  },

  cartRemoveProduct(idItem, productId, categoryId) {
    dispatch(cartThunks.removeProduct(idItem));
  },

  cartSubmitOrder(onCartSubmit, cartSize) {
    dispatch(cartThunks.orderCart()).then(() => onCartSubmit());
  },

  cartUpdateProduct(idProduct, citemId, newQty) {
    dispatch(cartThunks.updateProduct(idProduct, citemId, newQty));
  },

  clearCart(cb) {
    dispatch(cartThunks.clearAllCartItems()).then(() => cb());
  },

  getCart() {
    dispatch(cartThunks.loadUserCart());
  },

  onClose: () => {
    dispatch(cartActions.cartCloseModal());
  },

  onOpen: () => {
    dispatch(cartActions.cartOpenModal());
  },

  successOTPSend: (title, message) => {
    // Send a SUCCESS notification to the snackbar
    dispatch(
      toastNotificationsActions.success({
        message,
        title,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
