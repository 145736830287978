const api_espuser_v0_1_users_95_activate_user = {
  email: 'francis.lalane@gmail.com',
  esp_dict: {
    existing_user: false,
  },
  existing_user: false,
  first_name: 'Francis',
  home_address: {
    postal_code: '95050',
  },
  id: 1,
  is_active: true,
  last_name: 'Lalane',
  location: 'http://tenant1.esp/api/places/v0.1/locations/1/',
  manager: 'http://tenant1.esp/api/espuser/v0.1/users/3/',
  phone_numbers: {
    mobile: {
      number: '+16506506506',
      public: true,
    },
  },
  secondary_email: 'francis.lalane@gmail.com',
  sms_subscription: true,
  start_date: '2017-11-20T00:00:00-08:00',
  terms_accepted: true,
  title: 'front end',
  user_type: 'EMPLOYEE',
};

export default api_espuser_v0_1_users_95_activate_user;
