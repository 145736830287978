import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesLegalServices extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M73.08 52.98c-1.66-1.237-3.99-1.002-5.37.54l-6.45 7.3c-.12 3.44-3.44 4.84-6.64 5.34h-7c-.72 0-1.24-.1-1.24-.89s.46-.95 1.24-.95h6.94c2 0 4.68-1.57 4.74-3.56.06-1.99-2.51-3.74-4.56-3.74H42.21l-3.67-.07c-4.29-.24-10.38 2.78-11.32 3.9l-5.72 7.2 9.64 11.54 3.32-3.38h19.31c2.617.032 5.114-1.095 6.82-3.08l12.85-14.72c1.377-1.607 1.217-4.02-.36-5.43zm-39.5-7.13c4.25.022 7.724-3.38 7.79-7.63.014-.066.014-.134 0-.2-.01-.082-.03-.163-.06-.24L35.72 23.1h24.16l-5.49 14.83c-.056.086-.084.187-.08.29 0 4.302 3.488 7.79 7.79 7.79s7.79-3.488 7.79-7.79c.014-.066.014-.134 0-.2-.01-.082-.03-.163-.06-.24l-5.3-13.8-1.66-4.29c-.077-.192-.263-.32-.47-.32H33.09c-.213 0-.405.13-.48.33l-1 2.64v.06l-5.73 15.53c-.056.086-.084.187-.08.29.066 4.245 3.535 7.647 7.78 7.63zm34.58-8.15H56.07l6-15.52 6.09 15.52zM33.54 22.18l6.1 15.52H27.55l5.99-15.52zm15.75-5.32c0-.75-.61-1.36-1.36-1.36-.75 0-1.36.61-1.36 1.36v1.64h2.72v-1.64z' />
          <path d='M43.44 49.11h-1.11c-.75 0-1.36.61-1.36 1.36 0 .75.61 1.36 1.36 1.36h11c.75 0 1.36-.61 1.36-1.36 0-.75-.61-1.36-1.36-1.36h-1.14c-.385-1.483-1.478-2.68-2.92-3.2.004-.063.004-.127 0-.19V23.87h-2.7v21.85c-.002.043-.002.087 0 .13-1.54.467-2.726 1.702-3.13 3.26z' />
        </g>
      </svg>
    );
  }
}

export default CatalogServicesLegalServices;
