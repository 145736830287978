import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';
import Immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import queryString from 'query-string';
// Molecule
import ServiceDetailCategoriesTableList from '../../../molecules/ServiceDetailCategoriesTableList';
import MenuPagination from '../../../molecules/MenuPagination';
import ServiceDepartmentCategoryCreateModal from '../../../molecules/ServiceDepartmentCategoryCreateModal';
// utils
import uiPathGenerator from '../../../../utils/uiPathGenerator';
import browserHistory from '../../../../utils/browserHistory';
// Controller
import ServiceDetailCategoriesController from '../../../controllers/ServiceDetailCategoriesController';

class AdminServiceDetailCategories extends Component {
  static propTypes = {
    /** Category Immutable List */
    categoriesList: ImmutableProps.list,
    /** Save a New Category */
    createNewCategory: PropTypes.func.isRequired,
    /** Method to delete one category */
    deleteOneCategory: PropTypes.func,
    /* Selected Department Name */
    departmentSelected: PropTypes.string,
    /** Main Loader */
    isLoadingCategoryList: PropTypes.bool,
    /** Limit item per page */
    limitByPage: PropTypes.number.isRequired,
    /** max count of Team in the list*/
    listCount: PropTypes.number,
    /** Main Categories list loader */
    loadCategoriesList: PropTypes.func.isRequired,
    /** Category Deleted ID in loading state */
    loadingDeleteID: PropTypes.number,
    /** Router data */
    location: PropTypes.shape({
      query: PropTypes.shape({
        orderBy: PropTypes.string,
        page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }).isRequired,
    /** params via react router **/
    params: PropTypes.shape({
      departmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  static defaultProps = {
    categoriesList: Immutable.List(),
    deleteOneCategory: noop,
    departmentSelected: null,
    isLoadingCategoryList: false,
    listCount: 0,
    loadingDeleteID: null,
    params: {
      departmentID: null,
    },
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    orderBy: this.props.location.query.orderBy,
  };

  componentDidMount() {
    const {
      limitByPage,
      loadCategoriesList,
      location: { query },
      params: { departmentID },
    } = this.props;

    const page = query.page || 1;
    const { orderBy } = query;

    if (!query.page) {
      browserHistory.push({
        pathname: uiPathGenerator.genPath(
          'admin.serviceDepartment.detail.categories',
          {
            departmentID,
          }
        ),
        search: `?${queryString.stringify(
          Object.assign({}, query, {
            page: 1,
          })
        )}`,
      });
    } else {
      loadCategoriesList(page, limitByPage, orderBy, Number(departmentID));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      limitByPage,
      loadCategoriesList,
      location: { query },
      params: { departmentID },
    } = this.props;
    const { orderBy } = this.state;

    const nextPage = nextProps.location.query.page;
    const nextPageOrderBy = nextProps.location.query.orderBy;

    if (nextPage !== query.page || nextPageOrderBy !== orderBy) {
      this.setState({
        orderBy: nextPageOrderBy,
      });
      loadCategoriesList(nextPage, limitByPage, nextPageOrderBy, departmentID);
    }
  }

  onHandleReload = memoize(
    curry((resetPage, e) => {
      const {
        limitByPage,
        loadCategoriesList,
        location: { query },
        params: { departmentID },
      } = this.props;

      const page = resetPage ? 1 : query.page;
      const { orderBy } = query;

      loadCategoriesList(page, limitByPage, orderBy, departmentID);
    })
  );

  onHandleDeleteCategory = (id, departmentID, msg) => {
    const { deleteOneCategory } = this.props;
    deleteOneCategory(id, departmentID, msg).then(() => {
      this.onHandleReload();
    });
  };

  render() {
    const {
      categoriesList,
      createNewCategory,
      departmentSelected,
      isLoadingCategoryList,
      loadingDeleteID,
      location: { query },
      limitByPage,
      listCount,
      params: { departmentID },
    } = this.props;

    const triggerModal = <Button basic content='New Category' />;

    return (
      <Segment attached='bottom'>
        <Menu borderless secondary>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <ServiceDepartmentCategoryCreateModal
                createNewDepartment={createNewCategory}
                departmentID={Number(departmentID)}
                departmentSelected={departmentSelected}
                reloadCategories={this.onHandleReload(true)}
                trigger={triggerModal}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {isLoadingCategoryList && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}

        <ServiceDetailCategoriesTableList
          deleteOneCategory={this.onHandleDeleteCategory}
          departmentID={Number(departmentID)}
          departmentSelected={departmentSelected}
          loadingDeleteID={loadingDeleteID}
          tableList={categoriesList}
        />

        <MenuPagination
          currentPage={Number(query.page) || 1}
          numberPages={Math.ceil(listCount / limitByPage) || 1}
          pageByQuery
          pathOptions={{
            departmentID,
          }}
          pathname={'admin.serviceDepartment.detail.categories'}
          query={query}
        />
        <Divider hidden />
      </Segment>
    );
  }
}

const AdminServiceDetailCategoriesTest = AdminServiceDetailCategories;

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminServiceDetailCategories = ServiceDetailCategoriesController(
  AdminServiceDetailCategories
);

export { AdminServiceDetailCategoriesTest };

export default AdminServiceDetailCategories;
