import buildCompositeController from '../../utils/buildCompositeController';

import catalogThunks from '../../actions/catalogThunks';
import getCategoryIDToNameMapping from '../../selectors/getCategoryIDToNameMapping';

const mapStateToProps = (state) => {
  const categoryIDToNameMapping = getCategoryIDToNameMapping(state);

  return {
    categoryIDToNameMapping,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadCategories() {
    dispatch(catalogThunks.loadCategories());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
