import moment from 'moment';

const DATE_TYPE = 'date';

const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
const INPUT_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm';
const INPUT_TIME_FORMAT = 'HH:mm';

const DefaultInputFormats = {
  INPUT_DATE_FORMAT,
  INPUT_DATE_TIME_FORMAT,
  INPUT_TIME_FORMAT,
};
// const TIME_TYPE = 'time';

const BROWSER_LANGUAGE = navigator.language;

// TODO  move these functions to EspDateUtil

const parseDateAndTimeToMoment = (date, time, locale = BROWSER_LANGUAGE) =>
  moment(`${date} ${time}`, `${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`).locale(
    locale
  );

const getBEFormattedCompleteDate = (
  date,
  BE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
) =>
  date
    ? moment
        .utc(date, BE_DATE_FORMAT)
        .local()
        .locale(BROWSER_LANGUAGE)
        .format(`${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`)
    : void 0;

const getBEFormattedDate = (date, type = DATE_TYPE, BE_DATE_FORMAT) =>
  date
    ? moment
        .utc(date, BE_DATE_FORMAT)
        .local()
        .locale(BROWSER_LANGUAGE)
        .format(type === DATE_TYPE ? INPUT_DATE_FORMAT : INPUT_TIME_FORMAT)
    : void 0;

const getISOFormattedDate = (date, type = DATE_TYPE) =>
  date
    ? moment(date)
        .locale(BROWSER_LANGUAGE)
        .format(type === DATE_TYPE ? INPUT_DATE_FORMAT : INPUT_TIME_FORMAT)
    : void 0;

export {
  BROWSER_LANGUAGE,
  getBEFormattedCompleteDate,
  getBEFormattedDate,
  getISOFormattedDate,
  INPUT_DATE_FORMAT,
  INPUT_DATE_TIME_FORMAT,
  INPUT_TIME_FORMAT,
  parseDateAndTimeToMoment,
};

export default DefaultInputFormats;
