import buildCompositeController from '../../utils/buildCompositeController';

// selectors
import getProductsInSnow from '../../selectors/getProductsInSnow';
// thunks
import catalogThunks from '../../actions/catalogThunks';
import integrationThunks from '../../actions/integrationThunks';

const mapStateToProps = (state) => ({
  allCategories: state.getIn(['catalog', 'allSubcategories']),
  allSubcategoriesLoaded: state.getIn(['catalog', 'allSubcategoriesLoaded']),
  isLoadingSnCategories: state.getIn(['integration', 'isLoadingSnCategories']),
  isLoadingSubCategories: state.getIn(['catalog', 'isLoadingSubCategories']),
  isLoadingWorkflowMap: state.getIn(['integration', 'isLoadingWorkflowMap']),
  pagination: state.getIn(['catalog', 'subCatPagination']),
  snCategories: state.getIn(['integration', 'snCategories']),
  snWorkflowMap: state.getIn(['integration', 'snWorkflowMap']),
  snowProducts: getProductsInSnow(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadAllSubCategories: () => {
    dispatch(catalogThunks.loadAllSubCategories());
  },
  loadServicenowWorkflowMaps: () => {
    dispatch(integrationThunks.loadServicenowWorkflowMaps());
  },
  loadSnowCategories: (searchQuery) => {
    dispatch(integrationThunks.loadSnowCategories(searchQuery));
  },
  loadSnowCategoryBySysId: (sys_id) => {
    dispatch(integrationThunks.loadSnowCategories('', sys_id));
  },
  loadSnowProductById: (productId) => {
    dispatch(catalogThunks.loadOneProduct(productId));
  },
  loadSnowProducts: (searchQuery) => {
    dispatch(catalogThunks.loadSnowProducts(searchQuery));
  },
  saveServicenowWorkflowMap: (categoryID, productID) => {
    dispatch(
      integrationThunks.saveServicenowWorkflowMap(categoryID, productID)
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
