import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';

// Controller
import WorkflowPageController from '../../controllers/WorkflowPageController';
// Organisms
import WorkflowForm from '../../organisms/WorkflowForm';
// Utils
import TrimImmutableValues from '../../../utils/TrimImmutableValues';
// import URLUtil                  from '../../../utils/URLUtil';
import HandlerUi from '../../../utils/handlerUi';
import BodyClassName from '../../../../globals/BodyClassName';

const blobValidator = {
  blocks: ImmutablePropTypes.list.isRequired,
  nav: ImmutablePropTypes.map.isRequired,
};

class WorkflowPage extends PureComponent {
  static propTypes = {
    blob: ImmutablePropTypes.mapContains(blobValidator).isRequired,
    bodyClassName: PropTypes.string,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    forceWorkflowForm: PropTypes.bool, // only for unit test purpose
    formSyncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    // fromApp              : PropTypes.bool,
    initialValues: ImmutablePropTypes.map,
    isLoading: PropTypes.bool.isRequired,
    moveNextWorkflowTask: PropTypes.func,
    movePrevWorkflowTask: PropTypes.func,
    onCloseWorkflow: PropTypes.func,
    onMoveNextWorkflowTask: PropTypes.func,
    onMovePrevWorkflowTask: PropTypes.func,
    progress: ImmutablePropTypes.mapContains({
      // validation for keys
      max_steps: PropTypes.number.isRequired,
      steps_completed: PropTypes.number.isRequired,
    }).isRequired,
    resumeWorkflow: PropTypes.func,
    saveAttributes: PropTypes.func,
    skipCurrentTask: PropTypes.func,
    step: PropTypes.number.isRequired,
    // params               : PropTypes.object,
    workflowID: PropTypes.number,
    workflowRequestID: PropTypes.number.isRequired,
    workflowTaskID: PropTypes.number,
  };

  static defaultProps = {
    bodyClassName: 'main-active workflow',
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    forceWorkflowForm: false,
    formSyncErrors: null,
    // fromApp                : true,
    initialValues: Immutable.Map(),
    moveNextWorkflowTask: noop,
    movePrevWorkflowTask: noop,
    onCloseWorkflow: noop,
    onMoveNextWorkflowTask: noop,
    onMovePrevWorkflowTask: noop,
    // params                 : {},
    resumeWorkflow: noop,
    saveAttributes: noop,
    skipCurrentTask: noop,
    workflowID: null,
    workflowTaskID: null,
  };

  // this state allows to render or not the workflowForm
  state = {
    getFirstResume: false,
  };

  componentDidMount() {
    const { workflowRequestID, resumeWorkflow } = this.props;

    resumeWorkflow(workflowRequestID, (err, workflowId, workflowTaskId) => {
      this.markGetFirstResume();
    });
  }

  markGetFirstResume = () => {
    this.setState({
      getFirstResume: true,
    });
  };

  pageData = {};
  moveNextWorkflowTask = (skipped = false) => {
    const {
      moveNextWorkflowTask,
      onMoveNextWorkflowTask,
      skipCurrentTask,
      workflowRequestID,
    } = this.props;

    if (skipped) {
      skipCurrentTask(workflowRequestID, onMoveNextWorkflowTask);
    } else {
      // Move to the next task
      moveNextWorkflowTask(workflowRequestID, onMoveNextWorkflowTask);
    }
  };

  skipWorkflowTask = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.moveNextWorkflowTask(true);
  };

  movePrevWorkflowTask = () => {
    // Tracking event
    const { movePrevWorkflowTask, onMovePrevWorkflowTask } = this.props;

    movePrevWorkflowTask(onMovePrevWorkflowTask);
  };

  handleSubmit = (values) => {
    const { saveAttributes } = this.props;

    if (Immutable.Map.isMap(values) && !values.isEmpty()) {
      const newValues = TrimImmutableValues(values);

      const onSaveAttributes = (error) => {
        if (!error) {
          this.moveNextWorkflowTask();
        }
      };

      saveAttributes(newValues, onSaveAttributes);
    } else {
      this.moveNextWorkflowTask(true); // Set true to be sure that we perform set_entry
    }
  };

  setupContent = () => {
    const { blob } = this.props;

    if (blob) {
      this.pageData.nav = HandlerUi.setupBlocks(blob.get('nav'), false);
      this.pageData.blocks = HandlerUi.setupBlocks(blob.get('blocks'), false);

      if (this.pageData.blocks.length < 3) {
        this.pageData.blocks.push({
          id: 9999,
          template: 'spacer01',
          type: 'spacer',
        });
      }
    }

    this.pageData.blocks.forEach((block) => {
      if (
        block.template === 'imageSelect01' ||
        block.template === 'systemAvatars01'
      ) {
        block.handleSubmit = this.handleSubmit;
      }
    });
  };

  render() {
    this.setupContent();
    const { nav } = this.pageData;

    const {
      bodyClassName,
      errors,
      errorsApi,
      forceWorkflowForm,
      formSyncErrors,
      initialValues,
      isLoading,
      progress,
      step,
      workflowID,
      workflowTaskID,
      onCloseWorkflow,
    } = this.props;
    const { getFirstResume } = this.state;

    //
    // Only render WorkflowForm if we already finish the first resumeWorkflow
    // in order to avoid to send wrong data
    //
    const workflowIsReady = getFirstResume || forceWorkflowForm;

    return (
      <BodyClassName className={bodyClassName}>
        {workflowIsReady ? (
          <WorkflowForm
            blocks={this.pageData.blocks}
            errors={errors}
            errorsApi={errorsApi}
            formSyncErrors={formSyncErrors}
            handleCloseButtonClick={onCloseWorkflow}
            handleOnBackButtonClick={this.movePrevWorkflowTask}
            handleOnSkipButtonClick={this.skipWorkflowTask}
            initialValues={initialValues}
            isLoading={isLoading}
            nav={nav}
            onSubmit={this.handleSubmit}
            progress={progress}
            step={step}
            workflowID={workflowID}
            workflowTaskID={workflowTaskID}
          />
        ) : (
          <Dimmer active inverted>
            <Loader size='large' />
          </Dimmer>
        )}
      </BodyClassName>
    );
  }
}

const WorkflowPageTest = WorkflowPage;

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowPage = WorkflowPageController(WorkflowPage);
export { WorkflowPageTest };
export default WorkflowPage;
