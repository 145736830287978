import React, { Component } from 'react';
import classNames from 'classnames';

import { Dimmer, Loader } from 'semantic-ui-react';

// Globals
import TypeLoading, { TypeDefaults } from '../../../globals/blocks/TypeLoading';
import BodyClassName from '../../../../globals/BodyClassName';

class Loading01 extends Component {
  static propTypes = TypeLoading;
  static defaultProps = TypeDefaults;

  render() {
    const { template, type } = this.props;

    return (
      <BodyClassName className={template}>
        <div
          className={classNames('block padding', {})}
          data-blocktype={type}
          data-template={template}
        >
          <Dimmer active inverted>
            <Loader size='large' />
          </Dimmer>
        </div>
      </BodyClassName>
    );
  }
}

export default Loading01;
