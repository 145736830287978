import { fromJS } from 'immutable';
import _ from 'lodash';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  announcements: [],
  countByStatus: {},
  departments: null,
  error: '',
  errorManage: '',
  isLoading: false,
  isUpdating: false,
  jobRoles: null,
  loadStates: {
    announcements: {},
    departments: {
      loadDone: false,
      loading: false,
    },
    jobRoles: {
      loadDone: false,
      loading: false,
    },
  },
  loaded: false,

  pagination: {},
  slackChannels: {
    channels: [],
    isSlackConfigured: false,
    slackChannelsOptions: [],
  },
  testAnnouncement: {
    invalidEmails: [],
    isLoading: false,
    status: 0,
  },
});

/**
 * Announcements reducer
 * @param state
 * @param action
 * @returns {*}
 */
const announcementReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ANNOUNC_SET_SLACK_CHANNELS_SUCCESS: {
      return state.set('slackChannels', fromJS(action.slackChannels));
    }
    case actionTypes.ANNOUNC_SLACK_CHANNELS_START: {
      return state.set('isLoading', true);
    }
    case actionTypes.ANNOUNC_SLACK_CHANNELS_END: {
      return state.set('isLoading', false);
    }
    case actionTypes.ANNOUNC_UPDATE_START: {
      return state.set('isUpdating', true);
    }

    case actionTypes.ANNOUNC_UPDATE_SUCCESS: {
      return state.set('isUpdating', false);
    }

    case actionTypes.ANNOUNC_UPDATE_FAIL: {
      return state.set('isUpdating', false);
    }

    case actionTypes.ANNOUNC_LOAD_SINGLE_LOCATION_SUCCESS: {
      return state.set('tempLocation', fromJS(action.location));
    }

    case actionTypes.ANNOUNC_LOAD_SINGLE_DPT_SUCCESS: {
      return state.set('tempDepartment', fromJS(action.department));
    }

    case actionTypes.TEST_ANNOUNCEMENTS_START: {
      return state.setIn(['testAnnouncement', 'isLoading'], true);
    }
    case actionTypes.TEST_ANNOUNCEMENTS_SUCCESS: {
      return state
        .setIn(['testAnnouncement', 'isLoading'], false)
        .setIn(['testAnnouncement', 'status'], action.status)
        .setIn(
          ['testAnnouncement', 'invalidEmails'],
          fromJS(action.invalidEmails)
        );
    }
    case actionTypes.TEST_ANNOUNCEMENTS_CLEAN_ERRORS: {
      return state
        .setIn(['testAnnouncement', 'status'], 0)
        .setIn(['testAnnouncement', 'invalidEmails'], fromJS([]));
    }
    case actionTypes.TEST_ANNOUNCEMENTS_FAIL: {
      return state.setIn(['testAnnouncement', 'isLoading'], false);
    }

    case actionTypes.LOAD_ANNOUNCEMENTS_START: {
      return state.set('loaded', false).set('isLoading', true);
    }

    case actionTypes.LOAD_ANNOUNCEMENTS_SUCCESS: {
      let announcements = state.get('announcements');

      // Update existing announcements with what came from action
      const newAnnouncements = action.announcements.map((announcement) => {
        const index = announcements.findIndex(
          (ann) => ann.get('id') === announcement.id
        );
        // Pass GROUP only if announcement doesn't contain the groups property
        // and doesn't already exist in the reducer with the groups property
        if (
          (index === -1 && !_.has(announcement, 'groups')) ||
          (index > -1 && !announcements.hasIn([index, 'groups']))
        ) {
          announcement.groups = [
            {
              include_remote: false,
              notification: 0,
              user_count: 0,
            },
          ];
        } else if (index > -1) {
          // Merge the new announcement in the previous one
          announcement = Object.assign(
            {},
            announcement,
            announcements.get(index).toJS()
          );
        }
        return announcement;
      });

      announcements = announcements.concat(fromJS(newAnnouncements));

      // Smart way to get rid of duplicates, in case this announcements are being re-hydrated
      announcements = announcements
        .groupBy((m) => m.get('id'))
        .map((x) => x.last())
        .toList();

      return state
        .set('loaded', true)
        .set('isLoading', false)
        .setIn(['countByStatus', action.status], action.count)
        .setIn(['pagination', action.status], fromJS(action.pagination))
        .set('announcements', announcements);
    }

    case actionTypes.LOAD_ANNOUNCEMENTS_FAIL: {
      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('error', action.error);
    }

    case actionTypes.START_RECALL_ANNOUNCEMENT: {
      return state.setIn(
        ['loadStates', 'announcements', action.announcementId],
        true
      );
    }

    case actionTypes.FAIL_RECALL_ANNOUNCEMENT: {
      return state.setIn(
        ['loadStates', 'announcements', action.announcementId],
        false
      );
    }

    case actionTypes.SUCCESS_RECALL_ANNOUNCEMENT: {
      const announcements = state
        .get('announcements')
        .filter(
          (announcement) =>
            announcement.get('id') !== Number(action.announcementId)
        );

      return state
        .setIn(['loadStates', 'announcements', action.announcementId], false)
        .set('announcements', announcements);
    }

    case actionTypes.GET_ANNOUNC_SUCCESS: {
      let announcements = state.get('announcements');
      const index = announcements.findIndex(
        (ann) => ann.get('id') === action.announcement.id
      );
      // anouncement doest not exist, creating it
      if (index > -1) {
        return state.setIn(
          ['announcements', index],
          fromJS(action.announcement)
        );
      }
      // anouncement exist, pushing it
      announcements = announcements.push(fromJS(action.announcement));
      return state.set('announcements', announcements);
    }

    case actionTypes.GET_ANNOUNC_FAIL: {
      return state
        .set('errorManage', action.errorMsg)
        .setIn(['loadStates', 'jobRoles', 'loading'], false)
        .setIn(['loadStates', 'jobRoles', 'loadDone'], true)
        .setIn(
          ['loadStates', 'announcements', String(action.announcementId)],
          false
        );
    }

    case actionTypes.ANNOUNC_LOAD_ROLE_START: {
      return state
        .setIn(['loadStates', 'jobRoles', 'loading'], true)
        .setIn(['loadStates', 'jobRoles', 'loadDone'], false);
    }

    case actionTypes.ANNOUNC_LOAD_ROLE_SUCCESS: {
      return state
        .setIn(['loadStates', 'jobRoles', 'loading'], false)
        .setIn(['loadStates', 'jobRoles', 'loadDone'], true)
        .setIn(['jobRoles'], fromJS(action.jobRoles));
    }

    case actionTypes.ANNOUNC_LOAD_DPTS_START: {
      return state
        .setIn(['loadStates', 'departments', 'loading'], true)
        .setIn(['loadStates', 'departments', 'loadDone'], false);
    }

    case actionTypes.ANNOUNC_LOAD_DPTS_SUCCESS: {
      return state
        .setIn(['loadStates', 'departments', 'loading'], false)
        .setIn(['loadStates', 'departments', 'loadDone'], true)
        .setIn(['departments'], fromJS(action.departments));
    }

    case actionTypes.ANNOUNC_LOAD_DPTS_FAIL: {
      return state
        .setIn(['loadStates', 'departments', 'loading'], false)
        .setIn(['loadStates', 'departments', 'loadDone'], true);
    }

    case actionTypes.ANNOUNC_SAVE_GROUP_SUCCESS: {
      const announcementIndex = state
        .get('announcements')
        .findIndex((ann) => ann.get('id') === action.group.notification);
      const groupIndex = state
        .getIn(['announcements', announcementIndex, 'groups'])
        .findIndex((group) => group.get('id') === action.group.id);

      return state.setIn(
        ['announcements', announcementIndex, 'groups', groupIndex],
        fromJS(action.group)
      );
    }

    case actionTypes.ANNOUNC_ADDED_GROUP_SUCCESS: {
      const announcementIndex = state
        .get('announcements')
        .findIndex((ann) => ann.get('id') === action.group.notification);
      return state.updateIn(
        ['announcements', announcementIndex, 'groups'],
        (groups) => groups.push(fromJS(action.group))
      );
    }

    case actionTypes.ANNOUNC_DELETED_GROUP_SUCCESS: {
      const announcementIndex = state
        .get('announcements')
        .findIndex((ann) => ann.get('id') === action.announcementId);
      const groupIndex = state
        .getIn(['announcements', announcementIndex, 'groups'])
        .findIndex((group) => group.get('id') === action.groupId);

      return state.deleteIn([
        'announcements',
        announcementIndex,
        'groups',
        groupIndex,
      ]);
    }

    case actionTypes.DELETE_ANNOUNCEMENT_SUCCESS:
      {
        const announcementIndex = state
          .get('announcements')
          .findIndex((ann) => ann.get('id') === action.announcementId);
        const getStatus = state.getIn([
          'announcements',
          announcementIndex,
          'status',
        ]);
        const getStatusCount = state.getIn(['countByStatus', getStatus]);

        if (announcementIndex > -1) {
          return state
            .setIn(['countByStatus', getStatus], getStatusCount - 1)
            .deleteIn(['announcements', announcementIndex]);
        }
      }
      return state;
    case actionTypes.DELETE_ANNOUNC_START: {
      return state.setIn(
        ['loadStates', 'announcements', String(action.announcementId)],
        true
      );
    }

    case actionTypes.SAVE_ANNOUNC_STATUS_SUCCESS: {
      const announcementIndex = state
        .get('announcements')
        .findIndex((ann) => ann.get('id') === action.announcementId);
      let getNewStatusCount = state.getIn(['countByStatus', action.status]);
      let newState = state.setIn(
        ['loadStates', 'announcements', String(action.announcementId)],
        false
      );

      if (action.previousStatus) {
        const getCurrentStatusCount = newState.getIn([
          'countByStatus',
          action.previousStatus,
        ]);
        newState = newState.setIn(
          ['countByStatus', action.previousStatus],
          getCurrentStatusCount - 1
        );

        // Remove one count if same status
        if (action.previousStatus === action.status) {
          getNewStatusCount = getCurrentStatusCount - 1;
        }
      }

      return newState
        .setIn(['announcements', announcementIndex, 'status'], action.status)
        .setIn(['countByStatus', action.status], getNewStatusCount + 1);
    }

    case actionTypes.POST_ANNOUNC_START: {
      return state.setIn(
        ['loadStates', 'announcements', String(action.announcementId)],
        true
      );
    }
    case actionTypes.POST_ANNOUNC_END: {
      return state.setIn(
        ['loadStates', 'announcements', String(action.announcementId)],
        false
      );
    }

    case actionTypes.NEW_ANNOUNCEMENT_CREATED: {
      return state.setIn(['loadStates', 'announcements', 'new'], false);
    }

    default: {
      return state;
    }
  }
};

export default announcementReducer;
