import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class OnboardActivitiesWatchVideo extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 48 48'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8,0 L40,0 C44.418278,-8.11624501e-16 48,3.581722 48,8 L48,40 C48,44.418278 44.418278,48 40,48 L8,48 C3.581722,48 5.41083001e-16,44.418278 0,40 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z M8,2.5 C4.96243388,2.5 2.5,4.96243388 2.5,8 L2.5,40 C2.5,43.0375661 4.96243388,45.5 8,45.5 L40,45.5 C43.0375661,45.5 45.5,43.0375661 45.5,40 L45.5,8 C45.5,4.96243388 43.0375661,2.5 40,2.5 L8,2.5 Z M9.00000373,24.4832966 C9.00587127,16.0082914 15.9329477,9.05993145 24.3036169,9.00040099 C32.8337766,8.93980431 39.9985773,15.754354 39.9999998,24.5394507 C40.0014222,33.0778959 33.0041131,40.0202139 24.4460382,39.9999558 C15.9084107,39.9798754 8.99413619,33.0340033 9.00000373,24.4832966 Z M19.7761869,24.5117291 C19.7761869,26.8362603 19.7687191,29.1607915 19.7809876,31.4851451 C19.7843659,32.1090599 19.7984124,32.7837976 20.4626892,33.1061507 C21.1143418,33.4226395 21.6577117,33.0599479 22.1699657,32.6810854 C25.1517433,30.4752597 28.1344099,28.2706779 31.1163652,26.0652076 C32.4890142,25.0499911 32.4797683,23.5675937 31.0884499,22.5793881 C28.3298168,20.6203916 25.5706501,18.6619283 22.8041936,16.7137717 C22.3481256,16.3926627 21.8803226,16.062313 21.3709134,15.8485365 C20.7037918,15.5684768 20.1227274,15.8799899 19.9162967,16.5783622 C19.8135258,16.9261267 19.7832991,17.3058777 19.7813432,17.6715902 C19.769786,19.951518 19.7756535,22.2316235 19.7761869,24.5117291 Z'
          fill={color}
        />
      </svg>
    );
  }
}

export default OnboardActivitiesWatchVideo;
