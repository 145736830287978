import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import APIcall from '../../../utils/APIcall';
import endpointGenerator from '../../../utils/endpointGenerator';
import { Helmet } from 'react-helmet';
import { Dimmer, Grid, Loader, Message, Segment } from 'semantic-ui-react';
import BodyClassName from '../../../../globals/BodyClassName';

const propTypes = {
  location: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- This come from query parameters and its dynamic
    query: PropTypes.object,
  }),
  params: PropTypes.shape({
    bottype: PropTypes.string,
  }),
};

/**
 * This page acts as a proxy to execute a deep link to chatbot integration. Like MS Teams.
 *
 * This route could appear in email links so users can start an intent with just clicking on it.
 *
 * The sequence is:
 * 1. User clicks on email link and opens this page
 * 2. If user is unauthenticated the app will redirect to sign in flow and on success get back here
 * 3. The page extracts the bot type from url params and the query params and calls the deeplink endpoint to generate a
 * deeplink
 * 4. On success it redirects to the link.
 *
 * @param params
 * @param location
 * @return {JSX.Element}
 * @constructor
 */
const ProxyPage = ({ location }) => {
  const { query } = location;
  const [redirectUrl, setRedirectUrl] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const payload = useMemo(() => ({ ...query }), [query]);

  const createDeepLink = useCallback(async () => {
    if (payload) {
      try {
        const response = await APIcall.post({
          data: payload,
          token: true,
          url: endpointGenerator.genPath('espBaristaBot.deeplink'),
        });
        if (response.body.redirect_url) {
          setRedirectUrl(response.body.redirect_url);
        } else {
          // eslint-disable-next-line no-console -- debugging
          console.warn(
            '[proxy] Failed to execute deep link. Response does not contain redirect_url.'
          );
          setErrorMessage(
            'Failed to execute deep link. Response does not contain redirect_url.'
          );
        }
      } catch (e) {
        const message = `Failed to execute deep link. ${e.message}`;
        // eslint-disable-next-line no-console -- debugging
        console.warn(`[proxy] ${message}`, e);
        setErrorMessage(message);
      }
    }
  }, [payload]);

  useEffect(() => {
    createDeepLink();
  }, [createDeepLink]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }, [redirectUrl]);

  return (
    <BodyClassName className='login-page'>
      <Grid centered verticalAlign='middle'>
        <Helmet title='Barista Proxy' />
        <Grid.Column>
          <Dimmer.Dimmable as={Segment} textAlign='center'>
            {!redirectUrl && !errorMessage && (
              <Loader active data-testid='loader' inline='centered' />
            )}
            {errorMessage && (
              <Message content={errorMessage} data-testid='error' negative />
            )}
          </Dimmer.Dimmable>
        </Grid.Column>
      </Grid>
    </BodyClassName>
  );
};

ProxyPage.propTypes = propTypes;

export default ProxyPage;
