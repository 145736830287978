import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

// Molecules
import CartList from '../../molecules/CartList';
import MessageEmptyState from '../../molecules/MessageEmptyState';

// Globals
import TypeCart, { TypeDefaults } from '../../../globals/blocks/TypeCart';

import CartBlockController from '../../controllers/CartBlockController';
import BodyClassName from '../../../../globals/BodyClassName';

let EspCart01 = class EspCart01 extends Component {
  static propTypes = {
    ...TypeCart,
    cartSubmitOrder: PropTypes.func.isRequired,
    clearCart: PropTypes.func.isRequired,
    fillFromSelectMyGear: PropTypes.func,
    forceBlockLoadingState: PropTypes.func,

    isLoading: PropTypes.bool,
    isLoadingCart: PropTypes.bool,
    resetBlockLoadingState: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    fillFromSelectMyGear: noop,
    forceBlockLoadingState: noop,
    isLoading: false,
    isLoadingCart: false,
    resetBlockLoadingState: noop,
  };

  state = {
    // Clicked on Submit my order
    isLoading: false,
    submitted: false,
  };

  static getDerivedStateFromProps = (props, state) => {
    const { isLoadingCart, isLoading } = props;

    const isLoadingTheCart = isLoading || isLoadingCart;

    // Force local loading state if the cart is still loading
    if (isLoadingTheCart) {
      return {
        ...state,
        isLoading: true,
      };
    }

    return {
      ...state,
      isLoading: false,
    };
  };

  componentDidMount() {
    const {
      fillFromSelectMyGear,
      resetBlockLoadingState,
      resetValue,
      setAlternateBack,
      setAlternateSubmit,
      setAlternateSkip,
    } = this.props;

    setAlternateBack(this.handleBack);
    setAlternateSubmit(this.handleClick);
    setAlternateSkip(this.handleSkip);

    resetValue(); // Force noskip value

    // remove existing cart (if any), create a new one and add equipment selection from frontendScratch
    fillFromSelectMyGear().catch(() => {
      // In case of error, we want to reset the local loading state
      resetBlockLoadingState();
    });
  }

  componentDidUpdate(prevProps) {
    const { forceBlockLoadingState, resetBlockLoadingState } = prevProps;
    const { isLoading } = this.state;

    if (isLoading) {
      forceBlockLoadingState(); // Get state from derivated state which will force local loading state for workflowForm
    } else {
      resetBlockLoadingState();
    }
  }

  handleBack = (cb) => {
    // Reset the alternate submit fn
    const { setAlternateSubmit } = this.props;
    setAlternateSubmit();

    cb();
  };

  skipped = false;

  handleClick = (cb = noop) => {
    const { clearCart, cartSubmitOrder } = this.props;

    if (this.skipped) {
      // User clicked on CHANGE ORDER

      clearCart(cb);
    } else {
      // User clicked on SUBMIT ORDER

      cartSubmitOrder((error) => {
        if (!error) {
          // wait until cart gets submitted, then move forward with set_next_task
          this.setState({
            submitted: true,
          });
          cb();
        }
      });
    }
  };

  handleSkip = (cb) => {
    this.skipped = true;
    const { resetValue } = this.props;

    resetValue('skip').then(() => {
      cb();
    });
  };

  renderCart = () => {
    const { isLoading, isLoadingCart, ...rest } = this.props;

    const { submitted } = this.state;

    if (submitted && !isLoadingCart) {
      // Cart Submitted return a message
      return (
        <MessageEmptyState
          content={''}
          header={intl.formatMessage({
            id: 'message.order_submitted',
          })}
        />
      );
    } else if (this.skipped) {
      // Change the order return empty
      return null;
    }

    // Return the current loaded Cart
    // Disabling auto load because we are going to set up a cart for it
    return (
      <CartList
        {...rest}
        autoCartLoad={false}
        isLoading={isLoading || isLoadingCart}
      />
    );
  };

  render() {
    const { template, type } = this.props;

    const cartRender = this.renderCart();

    return (
      <BodyClassName className='blocks-top'>
        <div className='block' data-blocktype={type} data-template={template}>
          {cartRender}
        </div>
      </BodyClassName>
    );
  }
};

EspCart01 = CartBlockController(EspCart01);

export default EspCart01;
