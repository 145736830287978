import { createSelector } from 'reselect';

import Immutable from 'immutable';

const getAllLocations = (state) => state.getIn(['entities', 'locations']);
const getLocationType = (state, props) => props.locationType;

const getLeafLocations = createSelector(
  [getAllLocations, getLocationType],

  (allLocations = Immutable.Map(), locationType) => {
    let leafNodes = allLocations.toList();

    if (locationType) {
      leafNodes = leafNodes.filter(
        (location) => location.get('location_type_name') === locationType
      );
    } else {
      // a leaf node is anything wiuth an empty children list
      leafNodes = leafNodes.filter(
        (location) =>
          location.get('children') && location.get('children').isEmpty()
      );
    }

    return leafNodes;
  }
);

export default getLeafLocations;
