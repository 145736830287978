import actionTypes from './actionTypes';

const inboxesActions = {
  addFail: () => ({
    type: actionTypes.BARISTA_INBOX_ADD_FAIL,
  }),

  addStart: () => ({
    type: actionTypes.BARISTA_INBOX_ADD_START,
  }),

  addSuccess: (caseInbox) => ({
    caseInbox,
    type: actionTypes.BARISTA_INBOX_ADD_SUCCESS,
  }),

  cancelEmailInboxErrors: () => ({
    type: actionTypes.BARISTA_INBOX_CANCEL_EMAIL_INBOX_ERRORS,
  }),

  closeConfirmEmailInboxErrors: () => ({
    type: actionTypes.BARISTA_INBOX_CLOSE_CONFIRM_EMAIL_INBOX_ERRORS,
  }),

  confirmEmailInboxErrors: () => ({
    type: actionTypes.BARISTA_INBOX_CONFIRM_EMAIL_INBOX_ERRORS,
  }),

  deleteFail: () => ({
    type: actionTypes.BARISTA_INBOX_DELETE_FAIL,
  }),

  deleteStart: () => ({
    type: actionTypes.BARISTA_INBOX_DELETE_START,
  }),

  deleteSuccess: (caseInboxId) => ({
    id: caseInboxId,
    type: actionTypes.BARISTA_INBOX_DELETE_SUCCESS,
  }),

  emailTestConnectionClose: () => ({
    type: actionTypes.BARISTA_EMAIL_TEST_CONNECTION_CLOSE,
  }),

  errorEmailTestConnection: ({ isNewFormInbox, isOnlyTestingConnection }) => ({
    isNewFormInbox,
    isOnlyTestingConnection,
    type: actionTypes.BARISTA_EMAIL_TEST_CONNECTION_ERROR,
  }),

  fetchEmailCredentialListFail: () => ({
    type: actionTypes.ERROR_GET_EMAIL_CREDENTIALS,
  }),

  fetchEmailCredentialListStart: () => ({
    type: actionTypes.START_GET_EMAIL_CREDENTIALS,
  }),

  fetchEmailCredentialListSuccess: (credentials) => ({
    credentials,
    type: actionTypes.SUCCESS_GET_EMAIL_CREDENTIALS,
  }),

  fetchListFail: () => ({
    type: actionTypes.BARISTA_INBOXES_LOAD_FAIL,
  }),

  fetchListStart: () => ({
    type: actionTypes.BARISTA_INBOXES_LOAD_START,
  }),

  fetchListSuccess: (baristaBoxes) => ({
    baristaBoxes,
    type: actionTypes.BARISTA_INBOXES_LOAD_SUCCESS,
  }),

  fetchPrimaryCredentialFail: () => ({
    type: actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_FAIL,
  }),

  fetchPrimaryCredentialStart: () => ({
    type: actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_START,
  }),

  fetchPrimaryCredentialSuccess: (credential) => ({
    credential,
    type: actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_SUCCESS,
  }),

  inboxFormErrors: (showErrors, emailData) => ({
    emailData,
    showErrors,
    type: actionTypes.BARISTA_INBOX_FORM_ERRORS,
  }),

  startEmailTestConnection: (caseBox) => ({
    caseBox,
    type: actionTypes.BARISTA_EMAIL_TEST_CONNECTION_START,
  }),

  successEmailTestConnection: ({
    isNewFormInbox,
    isOnlyTestingConnection,
    caseBox,
  }) => ({
    caseBox,
    isNewFormInbox,
    isOnlyTestingConnection,
    type: actionTypes.BARISTA_EMAIL_TEST_CONNECTION_SUCCESS,
  }),

  updateCaseInboxCredentialError: () => ({
    type: actionTypes.BARISTA_INBOX_CREDENTIAL_UPDATE_ERROR,
  }),

  updateCaseInboxCredentialStart: () => ({
    type: actionTypes.BARISTA_INBOX_CREDENTIAL_UPDATE_START,
  }),

  updateCaseInboxCredentialSuccess: (credential) => ({
    credential,
    type: actionTypes.BARISTA_INBOX_CREDENTIAL_UPDATE_SUCCESS,
  }),

  updateFail: () => ({
    type: actionTypes.BARISTA_INBOX_UPDATE_FAIL,
  }),

  updateStart: () => ({
    type: actionTypes.BARISTA_INBOX_UPDATE_START,
  }),

  updateSuccess: (caseInbox) => ({
    caseInbox,
    type: actionTypes.BARISTA_INBOX_UPDATE_SUCCESS,
  }),
};

export default inboxesActions;
