import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';
import classNames from 'classnames';
import ErrorLabel from '../atoms/ErrorLabel';

class FormInputCheckbox extends PureComponent {
  static propTypes = {
    alignLabel: PropTypes.bool, // Add an empty label in top of the checkbox
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    formError: PropTypes.string,
    hidden: PropTypes.bool,
    icon: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }).isRequired,
    inversed: PropTypes.bool, // sometimes we might need to mark values as checked when they're false
    label: PropTypes.string,
    meta: PropTypes.shape({
      active: PropTypes.bool,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      error: PropTypes.object,
      submitFailed: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    removeErrorHandler: PropTypes.func,
    required: PropTypes.bool,
    showErrorsWithoutSubmitting: PropTypes.bool,
    synchroError: PropTypes.bool,
    toggle: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    alignLabel: false,
    className: null,
    defaultValue: null,
    disabled: false,
    formError: '',
    hidden: false,
    icon: null,
    inline: false,
    inversed: false,
    label: '',
    meta: {},
    onChange: null,
    readOnly: false,
    removeErrorHandler: null,
    required: false,
    showErrorsWithoutSubmitting: false,
    synchroError: false,
    toggle: false,
    width: null,
  };

  // It can use a customized change event or default
  handleChange = (param, data) => {
    const { input, onChange, removeErrorHandler } = this.props;
    removeErrorHandler && removeErrorHandler();
    if (onChange) {
      return onChange(param, data);
    }
    return input.onChange(data.checked);
  };

  render() {
    const {
      alignLabel,
      className,
      defaultValue,
      disabled,
      formError,
      hidden,
      icon,
      inline,
      input,
      inversed,
      label,
      readOnly,
      required,
      showErrorsWithoutSubmitting,
      synchroError,
      toggle,
      width,
      meta: { active, error, submitFailed },
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    return (
      <Form.Field
        className={classNames({
          hidden: hidden,
          required: required,
          [`${className}`]: Boolean(className),
        })}
        inline={inline}
        width={width}
      >
        {/* Note: Add an empty label in top of the radio button in order to align */}
        {/* this checkbox with another field in a Form group */}

        {alignLabel && <label className='hidden'>{'emtpy'}</label>}
        <Checkbox
          checked={inversed ? !input.value : Boolean(input.value)}
          disabled={disabled}
          icon={icon}
          label={label}
          onChange={this.handleChange}
          readOnly={readOnly}
          toggle={toggle}
        />
        {(formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )) ||
          (showErrorsWithoutSubmitting && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (!active && submitFailed && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (synchroError && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          ))}
      </Form.Field>
    );
  }
}

export default FormInputCheckbox;
