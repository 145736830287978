import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Form, Grid, Message } from 'semantic-ui-react';
import { noop } from 'lodash';
import { FormikWithSemanticUI } from 'esp-ui-form';
import TenantConfigNotificationsFormController from '../controllers/TenantConfigNotificationsFormController';

class TenantConfigNotificationsForm extends React.PureComponent {
  static propTypes = {
    dirty: PropTypes.bool,
    handleChange: PropTypes.func,
    info: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    setTenantNotificationsConfiguration: PropTypes.func.isRequired,
    values: PropTypes.shape({
      sounds_enabled: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    dirty: false,
    handleChange: noop,
    info: '',
  };

  handleCheckBoxChange = (evt, { name, checked }) => {
    const { handleChange: formikOnChange } = this.props;
    formikOnChange(evt, {
      name,
      value: checked,
    });
  };

  handleSubmit = (evt) => {
    const { setTenantNotificationsConfiguration, values } = this.props;
    evt.preventDefault();
    setTenantNotificationsConfiguration(values);
  };
  render() {
    const { dirty, isSaving, info, values } = this.props;

    return (
      <Form>
        <Grid>
          <Grid.Column width={6}>
            <Form.Checkbox
              aria-label={'Enable sounds'}
              checked={values.sounds_enabled}
              label='Enable sounds'
              name='sounds_enabled'
              onChange={this.handleCheckBoxChange}
              toggle
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Message content={info} data-testid={'infoMessage'} info />
          </Grid.Column>
        </Grid>

        <Divider clearing hidden />

        {dirty && (
          <>
            <Form.Button
              color='green'
              content='Save'
              disabled={isSaving}
              floated='right'
              loading={isSaving}
              onClick={this.handleSubmit}
            />
            <Divider clearing hidden />
          </>
        )}
      </Form>
    );
  }
}
const FormikComponent = FormikWithSemanticUI({
  enableReinitialize: true,
  mapPropsToValues: ({ soundsEnabled }) => ({
    sounds_enabled: soundsEnabled,
  }),
})(TenantConfigNotificationsForm);

export const TenantConfigNotificationsFormTest = FormikComponent;

export default TenantConfigNotificationsFormController(FormikComponent);
