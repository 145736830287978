import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form/immutable';
// Controller
import Form01Controller from '../../controllers/Form01Controller';

let Form01 = class Form01 extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    className: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onBlurField: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: [],
    className: '',
  };

  handleBlur = (e) => {
    const { onBlurField } = this.props;
    onBlurField(e.target.id);
  };

  render() {
    const { children, className, handleSubmit } = this.props;

    const renderForm = () => {
      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions  -- changing this can be risky
        <form
          className={className}
          onBlur={this.handleBlur}
          onSubmit={handleSubmit}
        >
          {children}
        </form>
      );
    };

    return renderForm();
  }
};

Form01 = reduxForm({
  // enableReinitialize to make sure Redux Form is
  // properly initialized from Attributes values when the workflow task changes.
  enableReinitialize: true,

  form: 'Form01',

  // Keeping keepDirtyOnReinitialize true can make some issues by forcing to keep previous values of previous step or when you try to reinitialize the form. It have to be false (default value).
  // Btw , we should not keep the form dirty after we reinitialize it
  // https://github.com/erikras/redux-form/issues/2723#issuecomment-305916590
  // keepDirtyOnReinitialize: true,

  validate: (values, props) => {
    const { blocks } = props;
    const errors = {};

    blocks.forEach((block) => {
      const blockId = block.id;
      const value = values.find((value, indexBlock) => indexBlock === blockId);

      // Validate required inputs
      const { inputRequired } = block;
      if (inputRequired) {
        // DEV-16363 we need to find the value instead of .get()
        // because some blocks have two field
        // with keys like "5a17db-1", "5a17db-2"
        // for instance the block workAddress0
        const valueBlockId = values.find((val, key) => key.startsWith(blockId));

        if (
          !valueBlockId ||
          (Immutable.Map.isMap(valueBlockId) && valueBlockId.isEmpty()) ||
          (typeof value === 'string' && !value.trim())
        ) {
          errors[blockId] = 'Cannot be empty';
        }
      }

      // Validate pattern
      let { inputPattern } = block;

      if (inputPattern && !errors[blockId]) {
        if (!inputPattern.startsWith('^') && !inputPattern.endsWith('$')) {
          // make sure the whole input is validated
          inputPattern = `^${inputPattern}$`;
        } else {
          // eslint-disable-next-line no-console -- debugging
          console.warn(
            `inputPattern with value "${inputPattern}" should not start with ^ or end with $. It's wrapped automatically.`
          );
        }

        const regex = new RegExp(inputPattern);

        if (value && !regex.test(value.trim())) {
          errors[blockId] = 'Invalid pattern';
        }
      }

      // TODO Validate image
    });

    return errors;

    // TODO Commented out intentionally, don't delete
    //
    // const blocks = {}, errors = {};
    //
    // props.blocks.map((block) => {
    // if (block.inputPattern) {
    //     blocks[block.id] = {
    //       pattern: block.inputPattern,
    //       input  : block.placeholder || block.labelText
    //     };
    // }
    // else if(block.inputValue && block.type === 'imageSelect') {
    //     blocks[block.id] = {
    //       type  : 'img',
    //       input : block.placeholder || block.labelText
    //     };
    // }
    // });
    //
    // values.map((value, key) => {
    // if (blocks[key]){
    //     if(blocks[key].pattern && !new RegExp(blocks[key].pattern).test(value)) {
    //      errors[key] = `Invalid ${blocks[key].input}...`;
    //     }
    // else if(value.get && value.get('picture')) {
    //    const isAnImg = value.get('picture').type.split('/')[0] == 'image';
    //     if(!isAnImg)
    //      errors[key] = 'Invalid Image...';
    //     }
    // }
    // });
    //
    // return errors;
    //
  },
})(Form01);

export const Form01Test = Form01;

export default Form01Controller(Form01);
