import { createSelector } from 'reselect';
import Immutable from 'immutable';

import getTenant from './getTenant';

const getTenantBranding = (tenant) => {
  let branding = Immutable.fromJS({
    tenantHeaderColor: null,
    tenantPrimaryColor: null,
  });

  const hasHeaderColor =
    Boolean(tenant) &&
    Boolean(tenant.get('branding_obj')) &&
    Boolean(tenant.getIn(['branding_obj', 'header_color']));

  const hasPrimaryColor =
    Boolean(tenant) &&
    Boolean(tenant.get('branding_obj')) &&
    Boolean(tenant.getIn(['branding_obj', 'color_primary']));

  if (hasHeaderColor) {
    const headerColor = tenant.getIn(['branding_obj', 'header_color']).trim();

    branding = branding.set('tenantHeaderColor', headerColor);
  }

  if (hasPrimaryColor) {
    const primaryColor = tenant.getIn(['branding_obj', 'color_primary']).trim();

    branding = branding.set('tenantPrimaryColor', primaryColor);
  }

  return branding;
};

const selector = createSelector([getTenant], getTenantBranding);

export default selector;
