import FrequencyTypes from './FrequencyTypes';

const FrequencyOptions = [
  {
    key: 'DAYS',
    text: 'DAYS',
    value: FrequencyTypes.DAYS,
  },
  {
    key: 'HOURS',
    text: 'HOURS',
    value: FrequencyTypes.HOURS,
  },
];

export default FrequencyOptions;
