const api_integration_v0_1_confluence_kb_run_sync = {
  datetime: '2020-08-26T08:00:21.194008Z',
  results: {
    added: 100,
    archived: 0,
    deleted: 3,
    found: 123,
    updated: 20,
  },
};

export default api_integration_v0_1_confluence_kb_run_sync;
