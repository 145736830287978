import React from 'react';
import { times } from 'lodash';
import Immutable from 'immutable';
// import {Image}               from 'semantic-ui-react';

// Global
import { ImageDefaults } from 'esp-assets';
import CardTypes from './CardTypes';

import UserImg from '../../v2/components/functional/UserImg';
import CaseStates from './CaseStates';

const LOADING_USER_IMG = <UserImg isLoading userId={0} />;

/**
 * Loading state for categories and subcategories in Shop
 */
const loadingShopCategories = Immutable.fromJS(
  times(7, (i) => ({
    children: [],
    id: i,
    name: 'Loading',
    sys_custom_fields: {
      icon: 'folder outline',
    },
  }))
);

/**
 * Loading state for Products in shop
 */
const loadingShopContent = Immutable.fromJS(
  times(7, (i) => ({
    category: [],
    category_all: [],
    description: 'Loading',
    header: 'Loading',
    id: i,
    images: [
      {
        image_custom: ImageDefaults.LOADING,
      },
    ],
    name: 'Loading',
  }))
);

/**
 * Loading state for Messages
 */
const loadingMessageContent = times(7, (i) => ({
  description: 'Loading',
  header: 'Loading',
  image: LOADING_USER_IMG,
  key: i,
}));

/**
 * Loading state for Directory (Contact)
 */
const loadingDirectory = Immutable.fromJS([
  {
    letter: 'a',
    users: times(3, (i) => ({
      department: 'Loading',
      id: -i,
      images: [
        {
          id: -i,
          thumbnail: ImageDefaults.LOADING,
          type: 'PROFILE',
        },
      ],
      last_name: 'Loading',
      nickname: 'Loading',
      title: 'Loading',
    })),
  },
  {
    letter: 'b',
    users: times(4, (i) => ({
      department: 'Loading',
      id: -i,
      images: [
        {
          id: -i,
          thumbnail: ImageDefaults.LOADING,
          type: 'PROFILE',
        },
      ],
      last_name: 'Loading',
      nickname: 'Loading',
      title: 'Loading',
    })),
  },
  {
    letter: 'c',
    users: times(6, (i) => ({
      department: 'Loading',
      id: -i,
      images: [
        {
          id: -i,
          thumbnail: ImageDefaults.LOADING,
          type: 'PROFILE',
        },
      ],
      last_name: 'Loading',
      nickname: 'Loading',
      title: 'Loading',
    })),
  },
]);

/**
 * Loading state for To-do (home)
 */
const loadingCard = Immutable.fromJS(
  times(7, (i) => ({
    content: [],
    due_date: null,
    eid: i,
    frontend_blob: {
      card_image: {
        url: ImageDefaults.LOADING,
      },
      content: [
        {
          text: 'Loading',
        },
      ],
      force_blob: true,
      header: {
        text: 'Loading',
      },
    },
    id: i,
    name: 'Default template for cart',
    type: CardTypes.LOADING,
  }))
);

/**
 * Loading state for the Admin DPC / CatalogList / Bundle / Role Bundle grid item
 */
const loadingAdminProducts = Immutable.fromJS(
  times(25, (i) => ({
    description: 'Loading',
    eid: i,
    id: i,
    images: [
      {
        image_480: ImageDefaults.LOADING,
        thumbnail: ImageDefaults.LOADING,
      },
      {
        thumbnail: ImageDefaults.LOADING,
      },
      {
        caption: 'Loading',
        thumbnail: ImageDefaults.LOADING,
      },
    ],
    name: 'Loading',
    selects: 'Loading',
    short_description: 'Loading',
  }))
);

/**
 * Loading state for the Admin User Role List
 */
const loadingAdminUsersRole = Immutable.fromJS(
  times(7, (i) => ({
    department: 'Loading',
    groups: [],
    id: i,
    images: [
      {
        id: i,
        picture: ImageDefaults.LOADING,
        type: 'PROFILE',
      },
    ],
    last_name: 'Loading',
    nickname: 'Loading',
    user_state: 'ACTIVE',
  }))
);

/**
 * Loading state for EquipmentList
 */
const loadingEquipment = Immutable.fromJS(
  times(5, (i) => ({
    department: 'Loading',
    id: i,
    last_name: 'Loading',
    product_images: [
      {
        image_480: ImageDefaults.LOADING,
      },
    ],
    product_name: 'Loading',
    product_short_description: 'Loading',
  }))
);

/**
 * Loading state for AnnouncementList
 */
const loadingAnnouncement = Immutable.fromJS(
  times(5, (i) => ({
    body: 'Loading',
    id: i,
    image: ImageDefaults.LOADING,
    subject: 'Loading',
  }))
);

/**
 * Loading state for PendingOrderDetail
 */
const loadingPendingOrder = Immutable.fromJS(
  times(3, (i) => ({
    id: i,
    item_status: 'Loading',
    product: {
      images: [
        {
          image_480: ImageDefaults.LOADING,
        },
      ],
      name: 'Loading',
      short_description: 'Loading',
    },
  }))
);

/**
 * Loading state for CartList
 */
const loadingItemsCart = Immutable.fromJS(
  times(3, (i) => ({
    category: [],
    category_all: [],
    citemId: i,
    description: 'Loading',
    header: 'Loading',
    id: i,
    images: [
      {
        image_custom: ImageDefaults.LOADING,
      },
    ],
    name: 'Loading',
  }))
);

/**
 * Loading state for New Hire Progress
 */
const loadingNewHireProgress = Immutable.fromJS(
  times(5, (i) => ({
    complete_state_count: 0,
    delinquent_state_count: 0,
    id: i,
    normal_state_count: 0,
    overall_state: 'Loading',
    overdue_state_count: 0,
    priority: 0,
    scheduled_state_count: 0,
    start_date_time: 'Loading',
    status: 'ACTIVE',
    type: 'NEW_HIRE',
    url: '',
    user: {
      department: 'Loading',
      groups: [],
      id: i,
      images: [
        {
          id: i,
          thumbnail: ImageDefaults.LOADING,
          type: 'PROFILE',
        },
      ],
      last_name: 'Loading',
      nickname: 'Loading',
      user_state: 'ACTIVE',
    },
    warning_state_count: 0,
  }))
);

/**
 * Loading State for Cases List
 */
const loadingCasesList = Immutable.fromJS(
  times(5, (i) => ({
    author: 0,
    description: 'Loading',
    feed_priority_1: 0,
    feed_priority_2: 1523486020,
    id: i,
    parent: null,
    priority: 50,
    ref_num: 'Loading',
    response_sla_breach: null,
    source: 'api',
    status: CaseStates.OPEN,
    title: 'Loading',
    type: 'task',
  }))
);

/**
 * Loading State for Approvals List
 */
const loadingApprovalsList = Immutable.fromJS(
  times(5, (i) => ({
    author: 0,
    description: 'Loading',
    feed_priority_1: 0,
    feed_priority_2: 1523486020,
    id: i,
    parent: null,
    priority: 50,
    ref_num: 'Loading',
    response_sla_breach: null,
    source: 'api',
    status: CaseStates.PENDING_APPROVAL,
    title: 'Loading',
    type: 'task',
  }))
);

export {
  loadingAdminProducts,
  loadingAdminUsersRole,
  loadingAnnouncement,
  loadingCard,
  loadingDirectory,
  loadingEquipment,
  loadingMessageContent,
  loadingShopCategories,
  loadingShopContent,
  loadingPendingOrder,
  loadingItemsCart,
  loadingNewHireProgress,
  loadingCasesList,
  loadingApprovalsList,
};
