import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { curry, memoize, noop } from 'lodash';

// Controllers
import SystemAvatarController from '../../controllers/SystemAvatarController';
// Globals
import TypeSystemAvatar, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSystemAvatar';
// Avatar
import avatarDefaults from '../../../globals/avatarDefaults';
// Organisms
import BlockModal from '../../organisms/BlockModal';
// Blocks
import Button01 from '../button/Button01';
import Text01 from '../text/Text01';
import BodyClassName from '../../../../globals/BodyClassName';

class SystemAvatars01 extends Component {
  static propTypes = {
    ...TypeSystemAvatar,
    isLoading: PropTypes.bool,
    modalButtonLoading: PropTypes.bool,
    onSubmitImg: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    isLoading: false,
    modalButtonLoading: false,
    onSubmitImg: noop,
  };

  state = {
    avatarSelected: 0,
  };

  setAvatarSelectModal = (avatarSelectModal) => {
    this.avatarSelectModal = avatarSelectModal;
  };

  handleSubmit = (e) => {
    const { onSubmitImg } = this.props;
    const { avatarSelected } = this.state;

    const image = new window.Image();
    const { props } = this;

    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      canvas.getContext('2d').drawImage(this, 0, 0);

      const blob = canvas.toDataURL('image/png');
      const type = props.image.onSubmit;

      onSubmitImg(blob, 'PROFILE', () => {
        if (type === 'setNext') {
          props.handleSubmit(e);
        } else if (type === 'close') {
          this.avatarSelectModal.close();
        }
      });
    };

    image.src = avatarDefaults[avatarSelected];
  };

  selectAvatar = memoize(
    curry((id, e) => {
      this.setState(
        {
          avatarSelected: id,
        },
        () => {
          this.avatarSelectModal.open();
        }
      );
    })
  );

  render() {
    const {
      modalText,
      modalButtonText,
      isLoading,
      modalButtonLoading,
      template,
      type,
    } = this.props;
    const { avatarSelected } = this.state;

    const buttonIsLoading = isLoading && modalButtonLoading;

    const modalBlockText = {
      id: '100',
      template: 'text_1',
      text: modalText,
      type: 'text',
    };

    const modalBlockButton = {
      id: '100',
      template: 'button01',
      text: modalButtonText,
      type: 'button',
    };

    const buttonToRend = [];
    for (const key in avatarDefaults) {
      buttonToRend.push(
        <Button key={key} onClick={this.selectAvatar(key)}>
          <img alt='' src={avatarDefaults[key]} />
        </Button>
      );
    }
    const AvatarButtons = () => (
      <Button.Group
        className='avatars'
        widths={Object.keys(avatarDefaults).length}
      >
        {buttonToRend.map((img) => img)}
      </Button.Group>
    );

    return (
      <BodyClassName className={template}>
        <div className='block' data-blocktype={type} data-template={template}>
          <AvatarButtons />

          <BlockModal ref={this.setAvatarSelectModal}>
            <div className='esp-blocks'>
              <div className='block'>{/* This is a spacer for flexbox */}</div>
              <div className='block centered'>
                <img alt='' src={avatarDefaults[avatarSelected]} />
              </div>
              <Text01 {...modalBlockText} />
              <div className='block centered'>
                <AvatarButtons />
              </div>
              <Button01
                {...modalBlockButton}
                loading={buttonIsLoading}
                onClick={this.handleSubmit}
              />
            </div>
          </BlockModal>
        </div>
      </BodyClassName>
    );
  }
}

export default SystemAvatarController(SystemAvatars01);
