import React from 'react';
import pt from 'prop-types';
import { Header } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import styles from './BrandingContent.module.scss';

const propTypes = {
  // Background image - do not set this and it will not display
  backgroundImgUrl: pt.string,
  // This is an iconify icon object
  baristaIconObject: pt.exact({
    body: pt.string,
    height: pt.number,
    width: pt.number,
  }),
  // Hex or rgb value for the main brand color
  brandColor: pt.string,
  // Hex or rgb value for the secondary brand color (header in app)
  brandColorAlt: pt.string,
  // Image file for the tenant logo
  logoImgUrl: pt.string,
  // The image used for the section preview
  previewImgUrl: pt.string.isRequired,
  // The title for the section
  title: pt.string.isRequired,
  // We need to know the type of preview area in order to place items inside
  type: pt.oneOf(['login', 'app', 'chat']).isRequired,
  // Current user image URL
  userImgUrl: pt.string,
};

const BrandingContentPreview = ({
  backgroundImgUrl,
  baristaIconObject,
  brandColor = '#108a79',
  brandColorAlt,
  logoImgUrl,
  previewImgUrl,
  title,
  type,
  userImgUrl,
}) => {
  return (
    <>
      <Header as='h3' content={title} />
      <div
        className={styles.Preview}
        data-type={type}
        style={{
          backgroundColor: brandColor,
          backgroundImage: backgroundImgUrl
            ? `url(${backgroundImgUrl})`
            : undefined,
        }}
      >
        {logoImgUrl && (
          <img alt='Logo' className={styles.Logo} src={logoImgUrl} />
        )}

        <img alt={title} className={styles.PreviewImage} src={previewImgUrl} />

        <div
          className={styles.LoginBackground}
          style={{ backgroundColor: brandColor }}
        />
        {brandColorAlt && (
          <div
            className={styles.HeaderBackground}
            style={{ backgroundColor: brandColorAlt }}
          />
        )}
        {baristaIconObject && (
          <Icon
            className={`${styles.Barista} primary icon`}
            icon={baristaIconObject}
          />
        )}
        {userImgUrl && (
          <img alt='You' className={styles.UserAvatar} src={userImgUrl} />
        )}
      </div>
    </>
  );
};

BrandingContentPreview.propTypes = propTypes;

export default BrandingContentPreview;
