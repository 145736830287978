import {
  FETCH_TENANT_SUCCESS,
  SET_BASE_URL,
  UNSET_BASE_URL,
} from './tenantActions';
import { DEFAULT_TENANT, TENANT_STATUS } from '../../constants';

function tenantReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_TENANT_SUCCESS:
      return {
        baseURL: state.baseURL,
        firstRender: false,
        message: '',
        status: TENANT_STATUS.READY,
        tenant: payload,
      };

    case SET_BASE_URL:
      return {
        baseURL: payload,
        firstRender: false,
        message: 'Connecting with your company...',
        status: TENANT_STATUS.NO_TENANT,
        ...DEFAULT_TENANT,
      };

    case UNSET_BASE_URL:
      return {
        baseURL: null,
        firstRender: false,
        message: payload?.isError ? payload?.message : '',
        status: payload?.isError
          ? TENANT_STATUS.ERROR
          : TENANT_STATUS.NO_TENANT,
        ...DEFAULT_TENANT,
      };

    default:
      return {
        baseURL: null,
        firstRender: false,
        message: '',
        status: TENANT_STATUS.NO_TENANT,
        ...DEFAULT_TENANT,
      };
  }
}

export default tenantReducer;
