import React, { useCallback, useContext } from 'react';
import { Button, Container, Form, Icon, Modal, Step } from 'semantic-ui-react';
import IntegrationSetupWizardContext from './IntegrationSetupWizardContext';
import IntegrationSetupWizardFormikWrapper from './IntegrationSetupWizardFormikWrapper';
import IntegrationSetupWizardActionsButtons from './IntegrationSetupWizardActionsButtons';
import { object } from 'yup';

const propTypes = {};

const IntegrationSetupWizardModal = () => {
  const {
    triggerButtonTitle,
    title,
    onClose,
    onOpen,
    state,
    handleFinishOnClick,
    handleCancelOnClick,
    handleNextOnClick,
    handleOnOpenOnClick,
  } = useContext(IntegrationSetupWizardContext);

  const { list, isOpen } = state;

  const handleOpen = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const handleOnClose = useCallback(() => {
    onClose();
    handleCancelOnClick();
  }, [handleCancelOnClick, onClose]);

  const handleSubmit = useCallback(
    (values) => {
      if (!list.isTailNode(list.getCurrentNode())) {
        handleNextOnClick(values);
      } else {
        if (!list.getCurrentNode()?.next) {
          handleFinishOnClick(values, onClose);
        }
      }
    },
    [handleFinishOnClick, handleNextOnClick, list, onClose]
  );

  return (
    <Modal
      className={'scrolling'}
      closeIcon={<Icon name='close' />}
      onClose={handleOnClose}
      onOpen={handleOpen}
      open={isOpen}
      size='large'
      style={{
        height: 'auto',
      }}
      trigger={
        <Button
          basic
          onClick={handleOnOpenOnClick}
          style={{ marginRight: '15px' }}
        >
          {triggerButtonTitle}
        </Button>
      }
    >
      <IntegrationSetupWizardFormikWrapper
        initialValues={
          list.getCurrentNode()?.initialValues
            ? list.getCurrentNode()?.initialValues
            : {}
        }
        onSubmit={handleSubmit}
        validationSchema={
          list.getCurrentNode()?.validationSchema
            ? list.getCurrentNode()?.validationSchema
            : object().shape({})
        }
      >
        <>
          <Modal.Header>{title}</Modal.Header>

          <Modal.Content style={{ padding: '20px' }}>
            <Form as='div'>
              <Form.Group>
                <Form.Field>
                  <Step.Group vertical>
                    {list.getNodesArray().map((node, key) => {
                      return (
                        <Step
                          active={
                            list.getCurrentNode().stepName === node.stepName
                          }
                          completed={node.completed}
                          key={key}
                        >
                          <Icon name={'circle outline'} />
                          <Step.Content>
                            <Step.Title>{node.stepName}</Step.Title>
                          </Step.Content>
                        </Step>
                      );
                    })}
                  </Step.Group>
                </Form.Field>
                <Form.Field style={{ marginLeft: '20px' }} width={10}>
                  <Container textAlign='left'>
                    {list.getCurrentNode()?.content}
                  </Container>
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <IntegrationSetupWizardActionsButtons />
        </>
      </IntegrationSetupWizardFormikWrapper>
    </Modal>
  );
};

IntegrationSetupWizardModal.propTypes = propTypes;

export default IntegrationSetupWizardModal;
