import React, { PureComponent } from 'react';

import TypeSearchFAQArchetype, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSearchFAQArchetype';
import PropTypes from 'prop-types';
import { debounce, noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
// Molecule
import FormInputSelect from '../../molecules/FormInputSelect';
import ValidatedField from '../../molecules/ValidatedField';
// Controller
import SearchArchetypeEntity01Controller from '../../controllers/SearchArchetypeEntity01Controller';

const TIMEOUT = 300;

class SearchArchetypeEntity01 extends PureComponent {
  static propTypes = {
    ...TypeSearchFAQArchetype,
    archetypeEID: PropTypes.string,
    entityList: ImmutablePropTypes.list,
    formValue: ImmutablePropTypes.map,
    isLoadingEntityList: PropTypes.bool,
    searchEntity: PropTypes.func,
    wordSelected: PropTypes.string,
  };

  static defaultProps = {
    ...TypeDefaults,
    archetypeEID: '',
    entityList: Immutable.List(),
    formValue: Immutable.Map(),
    isLoadingEntityList: false,
    searchEntity: noop,
    wordSelected: '',
  };

  componentDidMount() {
    const {
      archetypeEID,
      changeValue,
      formValue,
      id,
      searchEntity,
      setAlternateSkip,
      setAlternateSubmit,
      wordSelected,
    } = this.props;

    setAlternateSkip(this.handleNoMatch);
    setAlternateSubmit(this.handleSubmit);

    let resetSkip = formValue.set('skip', 'noskip');

    if (archetypeEID) {
      const getFullResult = true; // Will force to load also the first pagination data
      resetSkip = resetSkip.set('word', wordSelected);
      changeValue('searchEntity', wordSelected); // Set the default value to pass as search
      searchEntity(archetypeEID, wordSelected, 'eid', getFullResult); // Get preselected archetype
    } else {
      searchEntity(archetypeEID); // Get default Word list for this Archetype
    }
    changeValue(id, resetSkip);
  }

  handleNoMatch = () => {
    const { changeValue, formValue, id } = this.props;
    const setSkip = formValue
      .set('word', null) // if skipping with no match we should clear the word, even if it was selected (DEV-7254)
      .set('skip', 'skip');

    changeValue(id, setSkip);
    changeValue('searchEntity', 'ok'); // Force validation
  };

  handleSubmit = (cb) => {
    const { entityList, saveBEscratch, wordSelected } = this.props;

    const entity = entityList.find((arch) => arch.get('eid') === wordSelected);
    if (entity && !entity.isEmpty()) {
      saveBEscratch({
        entityLabel: entity.get('label'),
      }).then(() => {
        cb();
      });
    } else {
      cb();
    }
  };

  handleSetTerm = debounce((e, data) => {
    const {
      archetypeEID,
      changeValue,
      formValue,
      id,
      searchEntity,
    } = this.props;

    searchEntity(archetypeEID, data.searchQuery);
    changeValue(id, formValue.set('skip', 'skip'));
  }, TIMEOUT);

  handleChange = (e, value) => {
    const { formValue, id, changeValue } = this.props;

    changeValue(id, formValue.set('skip', 'noskip').set('word', value));
  };

  onHandleOnBlur = () => {
    const {
      archetypeEID,
      entityList,
      isLoadingEntityList,
      searchEntity,
    } = this.props;

    if (!isLoadingEntityList && entityList.isEmpty()) {
      searchEntity(archetypeEID);
    }
  };

  validate = (value) => (value ? null : 'error');

  render() {
    const { entityList, isLoadingEntityList, template, type } = this.props;

    const options = [];
    entityList.forEach((entity) => {
      options.push({
        key: entity.get('eid'),
        text: entity.get('label'),
        value: entity.get('eid'),
      });
    });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField
          component={FormInputSelect}
          handleBlur={this.onHandleOnBlur}
          icon='search'
          iconPosition='left'
          label='Word'
          loading={isLoadingEntityList}
          name='searchEntity'
          onChange={this.handleChange}
          onSearchChange={this.handleSetTerm}
          options={options}
          placeholder='Search a word'
          search
          validate={this.validate}
        />
      </div>
    );
  }
}

export const SearchArchetypeEntity01Test = SearchArchetypeEntity01;

export default SearchArchetypeEntity01Controller(SearchArchetypeEntity01);
