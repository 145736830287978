import React from 'react';
import pt from 'prop-types';
import ReactPlayer from 'react-player';
import reactPlayerConfig from './reactPlayerConfig';
import VideoPlayerStyles from './VideoPlayer.module.scss';
import Nodes from '../editor/Nodes';
import UserAgentUtils from '../userAgentUtils';

const propTypes = {
  type: pt.string.isRequired,
  url: pt.string.isRequired,
};

const VideoPlayer = ({ url, type }) => {
  if (Nodes.embedVideo === type) {
    return (
      <div className={VideoPlayerStyles._}>
        <ReactPlayer
          config={reactPlayerConfig}
          controls
          height='100%'
          playsinline={false}
          url={url}
          width='100%'
        />
      </div>
    );
  }
  // added small hack to display thumbnail on safari mobile https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
  return (
    <div className={VideoPlayerStyles.video}>
      {
        <video
          controls
          controlsList='nodownload'
          disablePictureInPicture
          width='100%'
        >
          <track default={UserAgentUtils.isChrome()} kind='captions' />

          <source src={`${url}#t=0.001`} type='video/mp4' />
          {" Sorry, your browser doesn't support embedded videos."}
        </video>
      }
    </div>
  );
};

VideoPlayer.propTypes = propTypes;

export default VideoPlayer;
