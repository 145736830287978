import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addOrders = (state, orders) => {
  orders.forEach((order) => {
    const orderID = order.get('id');

    state = state.set(orderID, order);
  });

  return state;
};

const ordersReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_PENDING_ORDERS_SUCCESS:
      return addOrders(state, fromJS(action.orders));

    default:
      return state;
  }
};

export default ordersReducer;
