import React from 'react';
import pt from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

const propTypes = {
  cancelButton: pt.string,
  confirmButton: pt.string,
  content: pt.oneOfType([pt.element, pt.node, pt.string]),
  isOpen: pt.bool,
  onCancel: pt.func,
  onConfirm: pt.func,
};

const EspModal = ({
  cancelButton,
  confirmButton,
  isOpen,
  content,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      className='scrolling confirm'
      onClose={onCancel}
      open={isOpen}
      size='small'
    >
      <div className='ui form'>
        <Modal.Content className='collapsed confirm-content'>
          {content}
        </Modal.Content>

        <Modal.Actions>
          <Button content={cancelButton} onClick={onCancel} />
          <Button content={confirmButton} onClick={onConfirm} primary />
        </Modal.Actions>
      </div>
    </Modal>
  );
};

EspModal.propTypes = propTypes;

export default EspModal;
