/* eslint-disable sort-keys -- backend shape */
const data = {
  results: [
    { intent: 'Holiday Transition to PTO Request', total: 1 },
    { intent: 'test eleazar archetype cond', total: 1 },
  ],
  count: 2,
};
/* eslint-enable sort-keys */

export default data;
