import { change } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import announcementThunks from '../../actions/announcementThunks';
import departmentActions from '../../actions/departmentsActions';
import locationsActions from '../../actions/locationsActions';

const mapStateToProps = (state) => {
  const jobRoles = state.getIn(['announcement', 'jobRoles']);
  const tempDepartment = state.getIn(['announcement', 'tempDepartment']);
  const tempLocation = state.getIn(['announcement', 'tempLocation']);
  return {
    currentDepartments: state.getIn(['entities', 'departments']),
    currentLocations: state.getIn(['entities', 'locations']),
    jobRoles,
    tempDepartment,
    tempLocation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeGroupField(newGroupVals, formMember, announcement) {
    dispatch(
      announcementThunks.getUserCount(newGroupVals, (count) => {
        dispatch(
          change(
            `AnnouncementManageForm_${announcement.get('id')}`,
            `${formMember}.time_limit`,
            count.timeLimit
          )
        );
        dispatch(
          change(
            `AnnouncementManageForm_${announcement.get('id')}`,
            `${formMember}.user_count`,
            count.userCount
          )
        );
        dispatch(
          change(
            `AnnouncementManageForm_${announcement.get('id')}`,
            `${formMember}.real_members`,
            count.realMembers
          )
        );
      })
    );
  },
  getDepartmentById(id) {
    dispatch(announcementThunks.loadDepartmentByID(id))
      .then((department) =>
        dispatch(departmentActions.getRootDepartmentsSuccess([department]))
      )
      .catch(() => dispatch(departmentActions.getRootDepartmentsFail()));
  },
  getLocationById(id) {
    dispatch(announcementThunks.loadLocationByID(id))
      .then((location) =>
        dispatch(locationsActions.getRootLocationsSuccess([location]))
      )
      .catch(() => dispatch(locationsActions.getRootLocationsFail()));
  },
  loadDepartments: () => {
    dispatch(announcementThunks.loadDepartments());
  },
  loadJobRoles: () => {
    dispatch(announcementThunks.loadJobRoles());
  },
  setAllGroupSelectedValue(groupType, formMember, announcement) {
    // Need tp defined what value should be used
    dispatch(
      change(
        `AnnouncementManageForm_${announcement.get('id')}`,
        `${formMember}.${groupType}`,
        null
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
