import React from 'react';
import PropTypes from 'prop-types';

const stylesFactory = ({ count }) => {
  return {
    '> img': {
      '-webkit-user-drag': 'none',
      display: 'block',
      height: 'auto',
      margin: '0 auto',
      maxWidth: '100%',
      textAlign: 'center',
    },

    display: 'inline-block',

    lineHeight: 1,
    overflowY: 'scroll',
    verticalAlign: 'top',
    width: `${100 / count}%`,
  };
};

class SliderItem extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    count: PropTypes.number.isRequired,
    height: PropTypes.string,
  };

  static defaultProps = {
    children: null,
  };
  render() {
    const { count, children, height } = this.props;
    const stylesFactoryValues = stylesFactory({ count, height });
    return (
      <div
        data-component='SliderItem'
        data-count={count}
        style={stylesFactoryValues}
      >
        {children}
      </div>
    );
  }
}

export default SliderItem;
