const topIntentsDrivingAutomationReportsTestData = {
  count: 25,
  results: [
    {
      intent: 'intent 1',
      total: 25,
    },
    {
      intent: 'intent 2',
      total: 24,
    },
    {
      intent: 'intent 3',
      total: 23,
    },
    {
      intent: 'intent 4',
      total: 22,
    },
    {
      intent: 'intent 5',
      total: 21,
    },
    {
      intent: 'intent 6',
      total: 20,
    },
    {
      intent: 'intent 7',
      total: 19,
    },
    {
      intent: 'intent 8',
      total: 18,
    },
    {
      intent: 'intent 9',
      total: 17,
    },
    {
      intent: 'intent 10',
      total: 16,
    },
    {
      intent: 'intent 11',
      total: 15,
    },
    {
      intent: 'intent 12',
      total: 14,
    },
    {
      intent: 'intent 13',
      total: 13,
    },
    {
      intent: 'intent 14',
      total: 12,
    },
    {
      intent: 'intent 15',
      total: 11,
    },
    {
      intent: 'intent 16',
      total: 10,
    },
    {
      intent: 'intent 17',
      total: 9,
    },
    {
      intent: 'intent 18',
      total: 8,
    },
    {
      intent: 'intent 19',
      total: 7,
    },
    {
      intent: 'intent 20',
      total: 6,
    },
    {
      intent: 'intent 21',
      total: 5,
    },
    {
      intent: 'intent 22',
      total: 4,
    },
    {
      intent: 'intent 23',
      total: 3,
    },
    {
      intent: 'intent 24',
      total: 2,
    },
    {
      intent: 'intent 25',
      total: 1,
    },
  ],
};

export default topIntentsDrivingAutomationReportsTestData;
