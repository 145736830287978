import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';

import ChatInputTypes from './ChatInputTypes';

const rule = (props) => {
  const { input, isMe, isSelectable, theme } = props;

  const noPadding = input ? input.type !== ChatInputTypes.BUTTON : false;

  return {
    flexGrow: 2,
    paddingLeft: noPadding || isMe ? 0 : theme.globals.padding,
    paddingRight: noPadding || !isMe ? 0 : theme.globals.padding,
    textAlign: noPadding || !isMe ? 'left' : 'right',
    width: isSelectable
      ? 'calc(100% - 78px)'
      : noPadding
      ? '100%'
      : `calc(100% - ${theme.minHeight})`,
  };
};

class ChatMessageWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string.isRequired,
    input: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      options: PropTypes.array,

      type: PropTypes.string,

      value: PropTypes.string,
    }),
  };

  static defaultProps = {
    children: null,
    input: void 0,
  };

  render() {
    const { children, className } = this.props;

    return (
      <div className={className} data-component='ChatMessageWrapper'>
        {children}
      </div>
    );
  }
}

export default createComponent(rule, ChatMessageWrapper, [
  'isMe',
  'isSelectable',
  'input',
]);
