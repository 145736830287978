import APIcall from '../../../../../utils/APIcall';

const postScheduleSync = ({ scheduleSyncURL, time, days, ...rest }) => {
  return APIcall.post({
    data: {
      scheduled_sync: {
        days,
        time,
      },
      ...rest,
    },
    token: true,
    url: scheduleSyncURL,
  });
};

export default postScheduleSync;
