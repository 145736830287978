const PaginationKeys = {
  LIMIT: 'limit',
  OFFSET: 'offset',
  PAGE: 'page',
};

const PaginationValues = {
  [PaginationKeys.LIMIT]: 10,
  [PaginationKeys.OFFSET]: 0,
  [PaginationKeys.PAGE]: 1,
};
export { PaginationKeys };
export default PaginationValues;
