import { createSelector } from 'reselect';
import { List } from 'immutable';

const productEntities = (state) => state.getIn(['entities', 'products']);

const productCart = (state) => state.getIn(['cart', 'cartItems']);

const getCurrentCart = (entities, cartItems) => {
  let cartProduct = new List();

  cartItems.forEach((product) => {
    let data = entities.flatMap((item) => {
      if (product.get('id') === item.get('id')) {
        return item;
      }
      return null;
    });

    if (data) {
      data = data
        .set('citemId', product.get('citemId'))
        .set('qty', product.get('qty'));

      cartProduct = cartProduct.push(data);
    }
  });
  return cartProduct;
};

const selector = createSelector([productEntities, productCart], getCurrentCart);

export default selector;
