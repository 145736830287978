const api_barista_v0_1_faqs_general_faqs_and_templates_lookup = {
  faqs: {
    count: 2,
    next: null,
    previous: null,
    results: [{}, {}],
  },
  templates: {
    count: 1,
    next: null,
    previous: null,
    results: [{}],
  },
};

export default api_barista_v0_1_faqs_general_faqs_and_templates_lookup;
