import APIcall from '../../../../../../utils/APIcall';
import endpointGenerator from '../../../../../../utils/endpointGenerator';

const deleteTranslation = async (id) => {
  return APIcall.delete({
    token: true,
    url: endpointGenerator.genPath('commons.localization.instance', {
      localizationID: id,
    }),
  });
};

export default deleteTranslation;
