const MediaTypes = {
  COLLAB: 'collab',
  SOCIAL: 'social',
};

const SocialMedias = {
  [MediaTypes.SOCIAL]: [
    {
      app: 'https://www.linkedin.com/in/',
      icon: 'linkedin square',
      key: 'linkedin',
      placeholder: 'username or url',
      regex: 'http(s)?://([w]+.)?linkedin.com/in/([A-z 0-9 _ -]+)/?',
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/in/',
      value: 'linkedin',
    },
    {
      app: 'twitter://timeline/',
      icon: 'twitter square',
      key: 'twitter',
      placeholder: '@username or url',
      regex: 'http(s)?://(.*.)?twitter.com/([A-z 0-9 _]+)/?',
      text: 'Twitter',
      url: 'https://www.twitter.com/',
      value: 'twitter',
    },
    {
      app: 'fb://profile/',
      icon: 'facebook square',
      key: 'facebook',
      placeholder: 'username or url',
      regex: 'http(s)?://(www.)?(facebook|fb).com/(A-z 0-9 _ - .)/?',
      text: 'Facebook',
      url: 'https://www.facebook.com/',
      value: 'facebook',
    },
    {
      app: 'instagram://',
      icon: 'instagram',
      key: 'instagram',
      placeholder: '@username or url',
      regex:
        'https?://(www.)?instagram.com/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:.(?!.))){0,28}(?:[A-Za-z0-9_]))?)',
      text: 'Instagram',
      url: 'https://www.instagram.com/',
      value: 'instagram',
    },
    {
      icon: 'pinterest square',
      key: 'pinterest',
      text: 'Pinterest',
      url: 'https://www.pinterest.com/',
      value: 'pinterest',
    },
  ],
  [MediaTypes.COLLAB]: [
    {
      icon: 'slack',
      key: 'slack',
      placeholder: 'slack @username',
      text: 'Slack',
      value: 'slack',
    },
    {
      icon: 'weixin',
      key: 'teams',
      text: 'Microsoft teams',
      value: 'teams',
    },
    {
      icon: 'weixin',
      key: 'spark',
      text: 'Cisco Spark',
      value: 'spark',
    },
    {
      icon: 'weixin',
      key: 'lync',
      text: 'Microsoft lync',
      value: 'lync',
    },
    {
      icon: 'skype',
      key: 'skype',
      text: 'Microsoft Skype',
      value: 'skype',
    },
    {
      icon: 'weixin',
      key: 'jabber',
      text: 'Cisco Jabber',
      value: 'jabber',
    },
    {
      icon: 'weixin',
      key: 'chatter',
      text: 'Salesforce Chatter',
      value: 'chatter',
    },
    {
      icon: 'weixin',
      key: 'yammer',
      text: 'Microsoft Yammer',
      value: 'yammer',
    },
    {
      icon: 'weixin',
      key: 'hipchat',
      text: 'Jira Hipchat',
      value: 'hipchat',
    },
  ],
};

export { MediaTypes };

export default SocialMedias;
