import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Menu, Modal, Portal } from 'semantic-ui-react';
import _ from 'lodash';

import ScrollArea from '../atoms/ScrollArea';

class BlockModal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    closeIcon: PropTypes.string,
    closeText: PropTypes.string,
    collapsed: PropTypes.bool,

    innerRef: PropTypes.func,

    modalActions: PropTypes.node,

    modalTitle: PropTypes.string,

    mountNodeSelector: PropTypes.string,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
    prepend: PropTypes.bool,
    trigger: PropTypes.node,
    useScroll: PropTypes.bool,
    withFooter: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    closeIcon: null,
    closeText: 'Close',
    collapsed: false,
    innerRef: _.noop,
    modalActions: null,
    modalTitle: '  ',
    mountNodeSelector: '.esp-workflow',
    onClose: _.noop,
    onOpen: _.noop,
    open: false,
    prepend: false,
    trigger: null,
    useScroll: true,
    withFooter: true,
  };

  state = {
    isOpen: false,
  };

  /**
   * Public method to be called to open modal programatically by using ref.
   * I'm taking this pattern from react-portal
   * https://github.com/tajo/react-portal#open-modal-programmatically
   */
  open = () => {
    this.handleOpenModal();
  };

  /**
   * Same as this.open
   */
  close = () => {
    this.handleCloseModal();
  };

  handleOpenModal = () => {
    const { onOpen } = this.props;
    this.setState(
      {
        isOpen: true,
      },
      () => {
        onOpen();
      }
    );
  };

  handleCloseModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { onClose } = this.props;

    onClose(); // Need to pass that before we close the modal to still have access to the childrens
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const {
      children,
      className,
      closeIcon,
      closeText,
      collapsed,
      innerRef,
      modalActions,
      modalTitle,
      mountNodeSelector,
      open,
      prepend,
      trigger,
      useScroll,
      withFooter,
    } = this.props;

    const tempStyles = {
      left: 0,
      margin: 0,
      top: 0,
      transform: 'none',
    };

    const { isOpen } = this.state;

    const mountNode = document.querySelector(mountNodeSelector);

    let modalContent = children;

    if (useScroll) {
      modalContent = <ScrollArea>{modalContent}</ScrollArea>;
    }
    const LinkAsAButton = (
      <a
        className='item close'
        icon={closeIcon}
        onClick={this.handleCloseModal}
      >
        {closeText}
      </a>
    );

    return (
      <Portal
        mountNode={mountNode}
        onOpen={this.handleOpenModal}
        open={isOpen || open}
        prepend={prepend}
        ref={innerRef}
        trigger={trigger}
      >
        <div className={classNames('esp-workflow-modal', className)}>
          <div className='ui small modal active block-modal' style={tempStyles}>
            {LinkAsAButton}
            <Form as='div'>
              <Modal.Header>
                {modalTitle ? <Menu.Item content={modalTitle} header /> : null}
              </Modal.Header>

              <Modal.Content
                className={classNames(
                  'collapsed',
                  'blockModal',
                  {
                    withFooter: withFooter,
                  },
                  collapsed
                )}
              >
                {modalContent}
              </Modal.Content>

              {modalActions ? (
                <Modal.Actions>{modalActions}</Modal.Actions>
              ) : null}
            </Form>
          </div>
        </div>
      </Portal>
    );
  }
}

export default BlockModal;
