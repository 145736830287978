import React from 'react';
import PropTypes from 'prop-types';
import ResponsesGroup from './ResponsesGroup';
import { FieldArray } from 'redux-form/immutable';

/**
 * Wrapper for PhoneInput in a redux-form field
 */
const propTypes = {
  name: PropTypes.string.isRequired,
};

const ValidatedAddResponses = ({ name, ...rest }) => (
  <FieldArray
    {...rest}
    component={ResponsesGroup}
    inputName={name}
    name='addResponses'
  />
);
ValidatedAddResponses.propTypes = propTypes;
export default ValidatedAddResponses;
