import async from 'async';
import moment from 'moment';
import { first, forOwn, isEmpty, isNull, isUndefined } from 'lodash';
import { getTenantUIConfig } from '../selectors/getTenant';
import { fromJS } from 'immutable';
// Utils
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

// Actions
import todoActions from './todoActions';
import getCurrentUser from '../selectors/getCurrentUser';
import workflowThunks from './workflowThunks';
import WorkflowEIDs from '../globals/WorkflowEIDs';
import appUIActions from './appUIActions';
import toastNotificationsActions from './toastNotificationsActions';

// Globals
import cardTypes from '../globals/CardTypes';
import cardState from '../globals/CardState';
import { INITIAL_FILTER_VALUES as initialValuesForHomeFeedFilter } from '../globals/HomeFilterGlobals';

// Packages
import EspFilters from 'esp-util-filters';

const todoThunks = {};

const getEspFilter = (filters) => {
  let otherQueryParameters = '';
  const espFilter = new EspFilters();
  // Always filter CONVERSATION Type
  espFilter.differentTo('type', cardTypes.TYPE_CONVERSATION);

  const typeFilterOptions =
    filters.hasIn([0, 'filters', 0, 'options']) &&
    filters.getIn([0, 'filters', 0, 'options']);
  const typeFilter = typeFilterOptions.find((option) => option.get('checked'));
  const sortByFilter =
    filters.hasIn([1, 'filters', 0, 'options']) &&
    filters
      .getIn([1, 'filters', 0, 'options'])
      .find((option) => option.get('checked'));
  const timePeriodFilter =
    filters.hasIn([2, 'filters', 0, 'options']) &&
    filters
      .getIn([2, 'filters', 0, 'options'])
      .find((option) => option.get('checked'));
  const allOptionsChecked = typeFilterOptions.every(
    (type) => type.has('checked') && type.get('checked')
  );

  if (typeFilter && typeFilter.has('value') && !allOptionsChecked) {
    const activeOptions = filters
      .getIn([0, 'filters', 0, 'options'])
      .filter(
        (option) => option.has('checked') && option.get('checked') === true
      );
    const isOtherActive = Boolean(
      activeOptions.find(
        (option) =>
          option.get('value') === 'OTHER' && option.get('checked') === true
      )
    );

    if (isOtherActive) {
      const filterOptions = filters.getIn([0, 'filters', 0, 'options']);
      const filterOptionsValues = filterOptions
        .filter((option) => option.get('value') !== 'OTHER')
        .map((option) => option.get('value'))
        .toJS();
      const otherOptions = Object.values(cardTypes).filter(
        (type) => !filterOptionsValues.includes(type)
      );
      otherOptions.forEach((filter, index) => {
        index
          ? espFilter.or().equalTo('type', filter)
          : espFilter.equalTo('type', filter);
      });
    }

    activeOptions.forEach((option, index) => {
      if (option.get('value') !== 'OTHER') {
        index || isOtherActive
          ? espFilter.or().equalTo('type', option.get('value'))
          : espFilter.equalTo('type', option.get('value'));
      }
    });

    espFilter.differentTo('state', cardState.DELETED);
  }

  if (
    sortByFilter &&
    sortByFilter.has('value') &&
    sortByFilter.get('value') !== 'smart'
  ) {
    otherQueryParameters += `order_by=${sortByFilter.get('value')}`;
  }

  if (
    timePeriodFilter &&
    timePeriodFilter.has('value') &&
    timePeriodFilter.get('value') !== 'all'
  ) {
    const momento = moment()
      .subtract(timePeriodFilter.get('value'), 'ms')
      .toISOString();
    espFilter.greaterThan('sys_date_updated', momento);
  }

  const encodedQueryString = espFilter.asEncodedQueryString()
    ? `?esp_filters=${espFilter.asEncodedQueryString()}`
    : '';
  if (otherQueryParameters) {
    otherQueryParameters = encodedQueryString
      ? `&${otherQueryParameters}`
      : `?${otherQueryParameters}`;
  }

  return `${encodedQueryString}${otherQueryParameters}`;
};

todoThunks.loadCards =
  (initial = false, limit) =>
  (dispatch, getState) => {
    const state = getState();

    const homeFilter = state.get('homeFilter');
    const endpoint = endpointGenerator.genPath('espCards.cards');
    const paginationNext = state.getIn(['todo', 'pagination', 'next']);

    // If it exist a next pagination in the reducer, we use that as the url
    // otherwise we fallback to the endpoint root
    const filters = getEspFilter(homeFilter);
    const url =
      paginationNext && !initial
        ? paginationNext
        : `${endpoint}${
            filters ? `${filters}&limit=${limit}` : `?limit=${limit}`
          }`;

    dispatch(todoActions.loadCardsStart());
    return APIcall.get({
      forceEnableAntiSpam: true,
      token: true,
      url: url, // we need to activate this so we can unit test it. Otherwise this setting is disabled in test environments
    })
      .then((res) => {
        const { body } = res;
        const cards = body.results;

        const pagination = {
          next: body.next,
          prev: body.previous,
        };

        dispatch(todoActions.loadCardsSuccess(cards, pagination, initial));
      })
      .catch((err) => {
        dispatch(todoActions.loadCardsFailure(err.message));
      });
  };

todoThunks.loadCardByTypeAndAnnouncementId =
  (type, announcementId, preventShowError = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!type) {
        reject(new Error('type must be provided'));
      } else if (!announcementId) {
        reject(new Error('announcement ID must be provided'));
      } else {
        const url = endpointGenerator.genPath('espCards.cards');
        const espFilters = new EspFilters()
          .equalTo('type', type.toUpperCase())
          .equalTo('object_id', announcementId);

        dispatch(todoActions.loadCardByTypeAndAnnouncementIdStart());
        async.waterfall(
          [
            // 1. Load card details
            (next) => {
              APIcall.get({
                error(e) {
                  next(e);
                },
                preventShowError,
                query: { esp_filters: espFilters.asQueryString() },
                success({ body }) {
                  next(null, first(body.results));
                },
                token: true,
                url: url,
              });
            },

            // 2. Load card
            (card, next) => {
              if (card) {
                dispatch(todoThunks.loadCardById(card.id, preventShowError))
                  .then((card) => next(null, card))
                  .catch((e) => next(e));
              } else {
                next(new Error('Card not found'));
              }
            },
          ],
          (e, card) => {
            if (e) {
              dispatch(todoActions.loadCardByTypeAndAnnouncementIdFailure(e));
              reject(e);
            } else {
              dispatch(
                todoActions.loadCardByTypeAndAnnouncementIdSuccess(card)
              );
              resolve(card);
            }
          }
        );
      }
    });

todoThunks.loadCardById =
  (cardID, preventShowError = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!cardID) {
        reject(new Error('card ID must be provided'));
      } else {
        const url = endpointGenerator.genPath('espCards.cards.instance', {
          cardID,
        });
        dispatch(todoActions.loadCardByIdStart());
        APIcall.get({
          error(e) {
            dispatch(todoActions.loadCardByIdFailure(e));
            reject(e);
          },
          preventShowError,
          success({ body }) {
            dispatch(todoActions.loadCardByIdSuccess(body));
            resolve(body);
          },
          token: true,
          url: url,
        });
      }
    });

todoThunks.dismissCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCards.dismissCard', {
      cardID: cardId,
    });
    return APIcall.post({
      error(e) {
        reject(e);
        // dispatch(todoActions.loadCardsFailure(err));
      },
      success() {
        dispatch(todoActions.dismissCardSuccess(cardId)); // Dismiss in particular doesn't return the updated card
        resolve();
      },
      token: true,
      url: url,
    });
  });

todoThunks.followCard = (cardId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCards.followCard', {
      cardID: cardId,
    });
    return APIcall.post({
      error(e) {
        reject(e);
      },
      success(res) {
        const result = res.body;
        dispatch(todoActions.followCardSuccess(result));
        resolve(result);
      },
      token: true,
      url: url,
    });
  });

todoThunks.snoozeCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCards.snoozeCard', {
      cardID: cardId,
    });
    return APIcall.post({
      error(e) {
        reject(e);
      },
      success(res) {
        const result = res.body;
        dispatch(todoActions.snoozeCardSuccess(result));
        resolve(result);
      },
      token: true,
      url: url,
    });
  });

todoThunks.unsubscribeCard = (cardId, userId, taskId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const card = state.getIn(['entities', 'cards', cardId]);

    // returns http://tenant1.esp/api/task/v0.1/tasks/taskId/unsubscribe/
    const url = endpointGenerator.genPath('task.tasks.instance.unsubscribe', {
      taskPK: taskId,
    });

    APIcall.post({
      data: {
        subscribers: [userId],
      },
      error(e) {
        reject(new Error(e));
      },
      success() {
        if (card) {
          dispatch(todoActions.unsubscribeCardSuccess(cardId));
        }
        resolve();
      },
      token: true,
      url,
    });
  });
todoThunks.nudgeCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCards.nudgeCard', {
      cardID: cardId,
    });
    return APIcall.post({
      error(e) {
        reject(e);
      },
      success(res) {
        const result = res.body;
        dispatch(todoActions.nudgeCardSuccess(result));
        resolve(result);
      },
      token: true,
      url: url,
    });
  });

todoThunks.cancelCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCards.cancelCard', {
      cardID: cardId,
    });
    return APIcall.post({
      error(e) {
        reject(e);
      },
      success(res) {
        const result = res.body;
        dispatch(todoActions.cancelCardSuccess(result));
        resolve(result);
      },
      token: true,
      url: url,
    });
  });

todoThunks.deleteCard = (cardID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCards.cards.instance', {
      cardID,
    });
    return APIcall.del({
      error(e) {
        reject(e);
      },
      success() {
        dispatch(todoActions.deleteCardSuccess(cardID));
        resolve(cardID);
      },
      token: true,
      url: url,
    });
  });

todoThunks.confirmCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    async.waterfall(
      [
        // 1. confirm the task
        (next) => {
          const url = endpointGenerator.genPath('espCards.confirmCard', {
            cardID: cardId,
          });
          return APIcall.post({
            error() {
              // dispatch(todoActions.loadCardsFailure(err));
            },
            success() {
              dispatch(todoActions.confirmCardSuccess(cardId)); // Dismiss in particular doesn't return the updated card
              next(null);
            },
            token: true,
            url: url,
          });
        },
        // 2. Dismiss the task
        (next) => {
          dispatch(todoThunks.dismissCard(cardId))
            .then(() => {
              next(null);
            })
            .catch((e) => {
              next(e);
            });
        },
      ], // Finally...
      (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      }
    );
  });

todoThunks.workflowTriggeredCard = (cardId) => (getState) =>
  new Promise((resolve) => {
    resolve();
  });

todoThunks.conversationStartedCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve) => {
    resolve();
  });

todoThunks.cancelRequestCard = (cardId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const card = state.getIn(['entities', 'cards', cardId]);

    const taskPk = card ? card.get('object_id') : 0;
    const url = endpointGenerator.genPath('task.tasks.instance.cancelRequest', {
      taskPK: taskPk,
    });
    APIcall.post({
      error(e) {
        reject(e);
      },
      success() {
        dispatch(todoActions.cancelRequestCardSuccess(cardId));
        resolve();
      },
      token: true,
      url: url,
    });
  });

todoThunks.markTodoStateAs = (cardId, newState) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const card = state.getIn(['entities', 'cards', cardId]);

    const progessID = card ? card.get('object_id') : 0;
    const url = endpointGenerator.genPath(
      'espTodo.progress.instance.changeState',
      {
        progessID: progessID,
      }
    );
    return APIcall.post({
      data: {
        state: newState,
      },
      error(e) {
        reject(e);
      },
      success(res) {
        if (res.state === newState) {
          todoActions.cardUpdate(card.set('state', newState));
        }
        resolve();
      },
      token: true,
      url: url,
    });
  });

todoThunks.delegateTask = (cardId, userId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const card = state.getIn(['entities', 'cards', cardId]);
    const progessID = card.get('object_id');

    const url = endpointGenerator.genPath(
      'espTodo.progress.instance.assignToDelegate',
      {
        progessID,
      }
    );

    APIcall.post({
      data: {
        assigned_user: userId,
      },
      error(err) {
        reject(err);
      },
      success() {
        resolve();
      },
      token: true,
      url,
    });
  });

todoThunks.openDownloadAppWorkflow = (card) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    const userID = currentUser.get('id');

    const workflowEid =
      card && card.get('name') === 'Contact me card'
        ? WorkflowEIDs.CONTACT_ME // for k19 workflow
        : WorkflowEIDs.DOWNLOAD_APP;

    dispatch(workflowThunks.mapEIDToID(workflowEid))
      .then((workflowID) =>
        dispatch(
          workflowThunks.addWorkflowRequest({
            assignedTo: userID,
            currentWorkflow: workflowID,
            owner: userID,
            requestedFor: userID,
            startingWorkflow: workflowID,
          })
        )
      )
      .then((workflowRequest) => {
        dispatch(appUIActions.openWorkflowModal(workflowRequest.id));
      })
      .catch(() => {
        const errorMsg = 'This workflow is not installed';
        dispatch(
          toastNotificationsActions.error({
            message: errorMsg,
            title: 'Error',
          })
        );
        reject(errorMsg);
      });
  });

todoThunks.answerSurvey =
  ({
    choice, // required
    task_id,
    faq_eid,
    conversation_id,
    action,
    card_id, // required
    survey_identifier,
    user_id,
  }) =>
  () =>
    new Promise((resolve, reject) => {
      if (!card_id) {
        const error = new Error(
          'parameter card_id is required to answer a survey'
        );
        reject(error);
        throw error;
      }
      if (isNull(choice) || isUndefined(choice)) {
        const error = new Error(
          'parameter choice is required to answer a survey'
        );
        reject(error);
        throw error;
      }

      const url = endpointGenerator.genPath('espCards.submitFeedback', {
        cardID: card_id,
      });
      return APIcall.post({
        data: {
          action,
          card_id,
          choice,
          conversation_id,
          faq_eid,
          survey_identifier,
          task_id,
          user_id,
        },
        error(e) {
          reject(e);
          // dispatch(todoActions.loadCardsFailure(err));
        },
        success() {
          resolve();
        },
        token: true,
        url: url,
      });
    });

todoThunks.answerAnnouncementSurvey = (cardId, selectedOption) => () =>
  new Promise((resolve, reject) => {
    // endpoint should be like /api/cards/v0.1/card/187/submit_feedback/
    // payload like
    //
    // {
    // "choice":"GOOGLE",
    // "survey_identifier": "testing-name-7_763efdfe-a48a-4eed-9223-e70ebed17887"
    // }
    //
    const url = endpointGenerator.genPath('espCards.submitFeedback', {
      cardID: cardId,
    });
    return APIcall.post({
      data: {
        choice: selectedOption.get('button_text'), // ToDo: confirm this is the right field
        survey_identifier: selectedOption.get('survey_identifier'),
      },
      error(e) {
        reject(e);
        // dispatch(todoActions.loadCardsFailure(err));
      },
      success() {
        resolve();
      },
      token: true,
      url: url,
    });
  });

const homeFiltersFormatter = (filtersData) => {
  const homeFilters = [];
  forOwn(filtersData, (value) => {
    homeFilters.push(value);
  });
  return homeFilters;
};
// DEV-15746 Replace only "sort by" flter
const mergeUserSortFilter = (
  userFilters,
  defaultSortingPerTenant,
  sortByAdmin
) => {
  const defaultFilters = initialValuesForHomeFeedFilter.toJS(); // array, default reducer state
  // there is not settings saved and the admin has not suggested any default sorting
  if (
    (!defaultSortingPerTenant || !sortByAdmin) &&
    (isEmpty(userFilters) || isUndefined(userFilters) || isNull(userFilters))
  ) {
    // set the default filter value to sort_by to created: newest to oldest
    const defaultNewestToOldest = defaultFilters.map((f) => {
      if (f.title === 'label.sort_by') {
        const optionsArray = f.filters[0].options;
        const sortByOptionIndexes = {
          newestToOldest: optionsArray.findIndex(
            (e) => e.text === 'filter.created_new_old'
          ),
        };
        optionsArray[sortByOptionIndexes.newestToOldest].checked = true;
      }
      return f;
    });
    return defaultNewestToOldest;
  }
  if (
    defaultSortingPerTenant &&
    sortByAdmin &&
    (isEmpty(userFilters) || isUndefined(userFilters) || isNull(userFilters))
  ) {
    // set the default filter value to sort_by defined by the admin in the global settings
    const defaultAdminSort = defaultFilters.map((f) => {
      if (f.title === 'label.sort_by') {
        const optionsArray = f.filters[0].options.map((option) => {
          if (option.text === sortByAdmin) {
            option.checked = true;
          } else {
            option.checked = false;
          }
          return option;
        });
        f.filters[0].options = optionsArray;
      }

      return f;
    });
    return defaultAdminSort;
  }

  const userSortByFilter = !isUndefined(userFilters)
    ? userFilters['sort_by']
    : false;

  if (userSortByFilter) {
    const removeSmartSort = userSortByFilter.filters[0].options.filter(
      (option) => option.text !== 'filter.smart_sort'
    );

    userSortByFilter.filters[0].options = removeSmartSort;

    const updatedFilters = defaultFilters.map((f) => {
      return f.title === 'label.sort_by' ? userSortByFilter : f;
    });
    return updatedFilters;
  }
  return defaultFilters;
};
todoThunks.setInitialHomeFilters = (userFilters) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const isDefaultHomefeedSortingPerTenantEnabled = getTenantUIConfig(
      state
    )?.getIn(['default_sorting', 'homefeed']);

    const sortByAdmin = getTenantUIConfig(state)?.getIn([
      'default_sorting',
      'default_sort_by',
    ]);

    if (!isEmpty(userFilters)) {
      const homeFilter = mergeUserSortFilter(
        userFilters,
        isDefaultHomefeedSortingPerTenantEnabled,
        sortByAdmin
      );
      dispatch(todoActions.updateHomeFilter(homeFilter));
    } else {
      // http://tenant1.esp/api/espuser/v0.1/users/3/user_settings/ <- endpoint to update userSettings
      const userID = state.getIn(['session', 'currentUser']);
      const url = endpointGenerator.genPath(
        'espUser.users.instance.userSettings',
        { userID }
      );
      //   /**
      //    * initial get of user settings espuser/v0.1/users/3/user_settings/
      //    * The previous `if` set the user_settings saved on BE when the application starts, this else
      //    * check if there's any changes on the user_settings api and update the homeFilters component when this is mount.
      //    */
      APIcall.get({
        success({ body }) {
          let homeFilter = {};
          if (body.user_settings || isNull(body.user_settings)) {
            homeFilter = mergeUserSortFilter(
              body?.user_settings?.homeFeed_filters,
              isDefaultHomefeedSortingPerTenantEnabled,
              sortByAdmin
            );
            dispatch(todoActions.updateHomeFilter(homeFilter));
          }
          resolve(body);
        },
        token: true,
        url: url,
      });
    }
  });

todoThunks.updateHomeFilter = (newFiltersStatus) => (dispatch, getState) =>
  new Promise((resolve) => {
    // processing the newFilterStatus to reflect changes on UI and have the desired format to save it on the BD
    const state = getState();
    let homeFilter = state.get('homeFilter');
    const data = {};
    // merges state of current filters with the filter options received in the action
    forOwn(newFiltersStatus, (sectionFilters, sectionTitle) => {
      const sectionIndex = homeFilter.findIndex(
        (section) => section.get('title') === sectionTitle
      );
      if (sectionIndex > -1) {
        // finding current section
        // for each filter in the section, update its options
        forOwn(sectionFilters, (options, filterKey) => {
          const filterIndex = homeFilter
            .getIn([sectionIndex, 'filters'])
            .findIndex((filter) => filter.get('key') === filterKey);
          if (filterIndex > -1) {
            homeFilter = homeFilter.updateIn(
              [sectionIndex, 'filters', filterIndex, 'options'],
              (currentOptions) => currentOptions.mergeDeep(fromJS(options))
            );
          }
        });
        data[sectionTitle] = homeFilter.get(sectionIndex).toJS();
      }
    });

    const userID = state.getIn(['session', 'currentUser']);
    if (userID) {
      //  // http://tenant1.esp/api/espuser/v0.1/users/3/user_settings/ <- endpoint to update userSettings
      const url = endpointGenerator.genPath(
        'espUser.users.instance.userSettings',
        { userID }
      );

      const homeFilters = homeFiltersFormatter({ ...data });
      dispatch(todoActions.updateHomeFilter(homeFilters));
      // /**
      //  * Also there is no patch for espuser/v0.1/users/3/user_settings/
      //  * Only POST.
      //  * But FE can post only the changed field, not all the unchanged fields.
      //  */
      // /* Saving only sort settings on API */
      APIcall.post({
        data: {
          user_settings: {
            homeFeed_filters: {
              sort_by: data['label.sort_by'],
            },
          },
        },
        success({ body }) {
          resolve(body);
        },
        token: true,
        url: url,
      });
    }
  });

export default todoThunks;
