import React, { Component } from 'react';
import FormInputText from '../../molecules/FormInputText';
import { Field } from 'redux-form/immutable';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';

// Globals
import TypeField, { TypeDefaults } from '../../../globals/blocks/TypeField';

class Field01 extends Component {
  static propTypes = {
    ...TypeField,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    forceFocus: PropTypes.bool,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    forceFocus: false,
  };

  state = {
    limitationReach: null,
  };

  handleChange = () => {
    const { handleChange } = this.props;

    // change event is being handle by reduxform, this is a handler for customized stuff
    if (handleChange) {
      handleChange();
    }
  };

  normalize = (value) => {
    const { charLimit } = this.props;
    const { limitationReach } = this.state;

    const charLimitationMessage = charLimit
      ? intl.formatMessage(
          {
            id: 'message.max_characters',
          },
          {
            number: charLimit,
          }
        )
      : null;

    if (charLimit && !limitationReach && value.length > charLimit) {
      this.setState({
        limitationReach: charLimitationMessage,
      });
    } else if (limitationReach && value.length <= charLimit) {
      this.setState({
        limitationReach: null,
      });
    }
    return charLimit ? value.substr(0, charLimit) : value;
  };

  render() {
    const {
      errors,
      errorsApi,
      forceFocus,
      id,
      inputType,
      labelText,
      placeholder,
      readOnly,
      template,
      type,
    } = this.props;
    const { limitationReach } = this.state;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    const charLimitError = limitationReach;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          charLimitError={charLimitError}
          component={FormInputText}
          forceFocus={forceFocus}
          formError={finalError}
          id={id}
          label={labelText}
          name={id.toString()}
          normalize={this.normalize}
          onChange={this.handleChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={this.handlerRef}
          transparent={readOnly}
          type={inputType}
        />
      </div>
    );
  }
}

export const Field01Test = Field01;

export default Field01;
