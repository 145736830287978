import buildCompositeController from '../../../utils/buildCompositeController';

const mapStateToProps = (state, ownprops) => {
  const userId = ownprops.userId
    ? ownprops.userId
    : state.getIn(['workflowState', 'sessionId', 'userRequestedFor']);

  return {
    userId: userId,
  };
};

export default buildCompositeController(mapStateToProps);
