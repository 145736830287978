const api_barista_v0_1_application = {
  count: 140,
  next: 'https://mrewrea.qa.espressive.com/api/barista/v0.1/application/?esp_filters=name__IC%3Dre%26override_faq__IBOOL%3DFalse&limit=10&offset=10',
  previous: null,
  results: [
    {
      active: true,
      created_in_elc: false,
      default_task_type: null,
      description: null,
      eid: 'a72a3441-d6bd-43a1-a7ce-d0a12170dfd0',
      is_protected: false,
      last_sync_time: null,
      name: '1_preProcessing',
      override_faq: false,
      override_intent_id: null,
      sys_created_by: 'fran@espressive.com',
      sys_date_created: '2022-09-21T19:13:30.333061Z',
      sys_date_updated: '2022-09-21T19:43:42.960863Z',
      sys_updated_by: 'fran@espressive.com',
      type: 'FAQ',
    },
    {
      active: true,
      created_in_elc: true,
      default_task_type: null,
      description: 'Issues and Requests Related to Active Directory',
      eid: '93594f13-a0d0-42b7-a811-bb96f0ddeb55',
      is_protected: true,
      last_sync_time: '2022-09-21T00:31:24.746878Z',
      name: 'Active Directory',
      override_faq: false,
      override_intent_id: null,
      sys_created_by: null,
      sys_date_created: '2019-03-15T14:50:56.137683Z',
      sys_date_updated: '2022-09-21T00:31:24.770556Z',
      sys_updated_by: 'vivek.rangaraj@espressive.com',
      type: 'FAQ',
    },
    {
      active: true,
      created_in_elc: true,
      default_task_type: null,
      description: null,
      eid: '9ddf2ae9-8ab4-4d19-b826-e70704c88f61',
      is_protected: true,
      last_sync_time: '2022-08-09T18:50:40.357176Z',
      name: 'Adobe Creative Suite',
      override_faq: false,
      override_intent_id: null,
      sys_created_by: 'selva.sankaranarayanan',
      sys_date_created: '2021-05-14T15:02:36.784975Z',
      sys_date_updated: '2022-08-09T18:50:40.378244Z',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      type: 'FAQ',
    },
    {
      active: true,
      created_in_elc: true,
      default_task_type: null,
      description: null,
      eid: '8ab172e0-3b3d-4e49-8d47-a73e47335b9c',
      is_protected: true,
      last_sync_time: '2022-08-09T18:50:30.416349Z',
      name: 'Adobe Document Cloud and Creative Cloud',
      override_faq: false,
      override_intent_id: null,
      sys_created_by: null,
      sys_date_created: '2018-09-18T00:05:58.086128Z',
      sys_date_updated: '2022-08-09T18:50:30.435835Z',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      type: 'FAQ',
    },
  ],
};

export default api_barista_v0_1_application;
