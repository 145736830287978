import { change, getFormValues } from 'redux-form/immutable';

// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Thinks
import userEntitiesThunks from '../../actions/userEntitiesThunks';
// Selector
import getCurrentUser from '../../selectors/getCurrentUser';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);

  // In use to set the minimum date selectable in the DayPicker
  const initialDate = state.hasIn([
    'workflowState',
    'frontendScratch',
    'initialStartingDate',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'initialStartingDate'])
    : currentUser.get('start_date');

  const initialReason = state.hasIn([
    'workflowState',
    'frontendScratch',
    'changeDateReason',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'changeDateReason'])
    : null;

  const isLoading = state.hasIn(['workflowState', 'loading'])
    ? state.getIn(['workflowState', 'loading'])
    : false;

  const formValues = getFormValues('Form01')(state);

  return {
    initialDate,
    initialReason,
    isLoading,
    reasonMsg: formValues.get('reason'),
    userId: currentUser ? currentUser.get('id') : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeReasonOnLoad(reason = '') {
    dispatch(change('Form01', 'reason', reason));
  },

  setStartingDateAndReason(startDate, reason, userID, cb) {
    dispatch(userEntitiesThunks.changeStartDateUser(userID, startDate, reason))
      .then(() => {
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
