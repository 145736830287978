import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AnnouncementCustomAction from '../../../../v1/components/organisms/AnnouncementCustomAction';

const propTypes = {
  announcementId: PropTypes.string.isRequired,
  changeFormFieldValue: PropTypes.func.isRequired,
  formValues: ImmutablePropTypes.map,
  isActionEnabled: PropTypes.bool,
  isDraft: PropTypes.bool.isRequired,
  onHandleActionCheckboxToggle: PropTypes.func.isRequired,
};

const GenericAnnouncementContentSection = ({
  announcementId,
  changeFormFieldValue,
  formValues = Immutable.Map(),
  isActionEnabled = false,
  isDraft,
  onHandleActionCheckboxToggle,
}) => (
  <AnnouncementCustomAction
    announcementId={announcementId}
    changeFormFieldValue={changeFormFieldValue}
    formValues={formValues}
    isActionEnabled={isActionEnabled}
    onToggleActionCheckbox={onHandleActionCheckboxToggle}
    readOnly={!isDraft}
  />
);

GenericAnnouncementContentSection.propTypes = propTypes;

export default GenericAnnouncementContentSection;
