import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { debounce, noop } from 'lodash';

import { intl } from 'esp-util-intl';
import controller from '../controllers/BaristaIntentLookupController';
import ValidatedSelect from './ValidatedSelect';

const SEARCH_TIMEOUT = 300;

class BaristaIntentLookup extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    lookupResults: ImmutablePropTypes.list.isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    searchIntentByEID: PropTypes.func,
    searchIntents: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    isLoading: false,
    label: '',
    name: '',
    placeholder: '',
    readOnly: false,
    required: false,
    searchIntentByEID: noop,
    searchIntents: noop,
    value: null,
  };

  static state = {
    searchQuery: '',
  };

  componentDidMount() {
    const { searchIntentByEID, value } = this.props;
    if (value) {
      searchIntentByEID(value);
    }
  }

  handleOnSearchChange = debounce((__, { searchQuery }) => {
    const { searchIntents } = this.props;

    if (searchQuery) {
      // eslint-disable-next-line react/no-unused-state -- TODO fix weird implementation
      this.setState({ searchQuery }, () => {
        searchIntents(searchQuery);
      });
    }
  }, SEARCH_TIMEOUT);

  render() {
    const searchQuery = this.state && this.searchQuery;
    const {
      disabled,
      isLoading,
      label,
      lookupResults,
      name,
      placeholder,
      readOnly,
      required,
      value,
    } = this.props;

    const options = lookupResults
      .map((intent) => ({
        key: intent.get('eid'),
        text: intent.get('name'),
        value: intent.get('eid'),
      }))
      .toArray();

    // re-using noResultMessage to give Users better feedback
    let noResultsMessage = placeholder;
    if (isLoading) {
      noResultsMessage = intl.formatMessage({ id: 'label.searching_intents' });
    } else if (this.state && searchQuery && !options.length) {
      noResultsMessage = intl.formatMessage({
        id: 'label.no_intents_found',
      });
    }

    return (
      <ValidatedSelect
        disabled={disabled}
        fluid
        label={label}
        loading={isLoading}
        name={name}
        noResultsMessage={noResultsMessage}
        onSearchChange={this.handleOnSearchChange}
        options={options}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        search
        value={value}
      />
    );
  }
}

export const BaristaIntentLookupTest = BaristaIntentLookup;

export default controller(BaristaIntentLookup);
