import APIcall from '../../../../../../utils/APIcall';

const checkSyncUsersStatus = (url) => {
  return APIcall.get({
    token: true,
    url,
  });
};

export default checkSyncUsersStatus;
