/* eslint-disable sort-keys -- testing */
const api_groups_v0_1_groups = {
  count: 9,
  next: null,
  previous: null,
  results: [
    {
      id: 5,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/5/',
      eid: '8cd0e7c9-c73d-4d36-84d6-a7a843bc99a5',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-20T22:49:57.480845Z',
      sys_created_by: 'meme.castillogavante@espressive.com',
      sys_date_created: '2022-09-20T22:49:57.480864Z',
      name: 'test group 4',
      active: true,
      description: 'A testGroup 4 for development of user groups UI',
      filter: 'last_login <= today - 2800',
      owner: {
        id: 226489,
        eid: '035e995c-a03f-4be6-b8c3-3a207e951964',
        email: 'meme.castillogavante@espressive.com',
        username: 'meme.castillogavante@espressive.com',
        first_name: 'Meme',
        last_name: 'Castillo',
        nickname: 'Meme',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 6,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/6/',
      eid: 'e7c310d2-5248-474c-8b05-9bee4975a5b0',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-20T22:50:20.689325Z',
      sys_created_by: 'meme.castillogavante@espressive.com',
      sys_date_created: '2022-09-20T22:50:20.689345Z',
      name: 'test group 5',
      active: true,
      description: 'A testGroup 5 for development of user groups UI',
      filter: 'last_login <= today - 43200',
      owner: {
        id: 226489,
        eid: '035e995c-a03f-4be6-b8c3-3a207e951964',
        email: 'meme.castillogavante@espressive.com',
        username: 'meme.castillogavante@espressive.com',
        first_name: 'Meme',
        last_name: 'Castillo',
        nickname: 'Meme',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 7,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/7/',
      eid: 'a00f5d78-a653-42a4-ae15-9f9a891a8415',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-20T22:50:38.539921Z',
      sys_created_by: 'meme.castillogavante@espressive.com',
      sys_date_created: '2022-09-20T22:50:38.539940Z',
      name: 'test group 6',
      active: true,
      description: 'A testGroup 6 for development of user groups UI',
      filter: 'last_login <= today - 144000',
      owner: {
        id: 226489,
        eid: '035e995c-a03f-4be6-b8c3-3a207e951964',
        email: 'meme.castillogavante@espressive.com',
        username: 'meme.castillogavante@espressive.com',
        first_name: 'Meme',
        last_name: 'Castillo',
        nickname: 'Meme',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 8,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/8/',
      eid: 'e70caabf-567f-4cfc-92cf-bca6b3375b36',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-20T22:51:48.480605Z',
      sys_created_by: 'meme.castillogavante@espressive.com',
      sys_date_created: '2022-09-20T22:51:48.480622Z',
      name: 'test group 6',
      active: true,
      description: 'A testGroup 6 for development of user groups UI',
      filter: 'last_login <= today - 5256000',
      owner: {
        id: 226489,
        eid: '035e995c-a03f-4be6-b8c3-3a207e951964',
        email: 'meme.castillogavante@espressive.com',
        username: 'meme.castillogavante@espressive.com',
        first_name: 'Meme',
        last_name: 'Castillo',
        nickname: 'Meme',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 9,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/9/',
      eid: '4457eb7a-3f98-4f8a-b2aa-ad499ba57a3a',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-20T22:52:41.326650Z',
      sys_created_by: 'meme.castillogavante@espressive.com',
      sys_date_created: '2022-09-20T22:52:41.326668Z',
      name: 'test group 7',
      active: true,
      description: 'A testGroup 7 for development of user groups UI',
      filter: 'last_login <= today - 2628000',
      owner: {
        id: 226489,
        eid: '035e995c-a03f-4be6-b8c3-3a207e951964',
        email: 'meme.castillogavante@espressive.com',
        username: 'meme.castillogavante@espressive.com',
        first_name: 'Meme',
        last_name: 'Castillo',
        nickname: 'Meme',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 12,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/12/',
      eid: '63a326a9-8441-4c58-8273-4387e73795e9',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-27T20:00:20.714707Z',
      sys_created_by: 'meme.castillogavante@espressive.com',
      sys_date_created: '2022-09-27T20:00:20.714725Z',
      name: 'test announcement group',
      active: true,
      description: '',
      filter: 'location == 2',
      owner: {
        id: 226489,
        eid: '035e995c-a03f-4be6-b8c3-3a207e951964',
        email: 'meme.castillogavante@espressive.com',
        username: 'meme.castillogavante@espressive.com',
        first_name: 'Meme',
        last_name: 'Castillo',
        nickname: 'Meme',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 19,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/19/',
      eid: '0be975e8-7c69-4a83-bd00-b386f618fb8b',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-29T00:16:25.869534Z',
      sys_created_by: 'gonzalez@release.qa.espressive.com',
      sys_date_created: '2022-09-28T17:05:47.138235Z',
      name: 'qwerty',
      active: true,
      description: null,
      filter: 'last_login RANGE 2021/01/02, today',
      owner: {
        id: 10553,
        eid: 'c3a45e68-624f-4731-885c-7753d23dbce0',
        email: 'gonzalez@example.com',
        username: 'gonzalez@release.qa.espressive.com',
        first_name: 'Ricardo',
        last_name: 'Gonzalez',
        nickname: 'Ricardo',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 16,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/16/',
      eid: '2a70e16b-c30c-4dac-8aa1-1f46537f6107',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-29T00:16:42.595631Z',
      sys_created_by: 'gonzalez@release.qa.espressive.com',
      sys_date_created: '2022-09-28T04:20:23.249889Z',
      name: 'qwerty',
      active: true,
      description: null,
      filter: 'last_login RANGE 2021/01/02, today',
      owner: {
        id: 10553,
        eid: 'c3a45e68-624f-4731-885c-7753d23dbce0',
        email: 'gonzalez@example.com',
        username: 'gonzalez@release.qa.espressive.com',
        first_name: 'Ricardo',
        last_name: 'Gonzalez',
        nickname: 'Ricardo',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
    {
      id: 17,
      url: 'https://release.qa.espressive.com/api/groups/v0.1/groups/17/',
      eid: 'c52893dc-0a82-4268-8dc2-eaffd2a4b610',
      sys_updated_by: 'meme.castillogavante@espressive.com',
      sys_date_updated: '2022-09-29T00:17:09.842426Z',
      sys_created_by: 'gonzalez@release.qa.espressive.com',
      sys_date_created: '2022-09-28T17:04:15.298945Z',
      name: 'test test',
      active: true,
      description: null,
      filter: 'last_login RANGE 2021/01/02, today',
      owner: {
        id: 10553,
        eid: 'c3a45e68-624f-4731-885c-7753d23dbce0',
        email: 'gonzalez@example.com',
        username: 'gonzalez@release.qa.espressive.com',
        first_name: 'Ricardo',
        last_name: 'Gonzalez',
        nickname: 'Ricardo',
        employee_number: 'None provided',
      },
      member_count: 0,
    },
  ],
};

export default api_groups_v0_1_groups;
/*eslint-enable */
