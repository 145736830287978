import React, { useCallback } from 'react';
import { Button, Form, Header, Input, TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Boundaries } from 'cascara-middleware';
import { Formik } from 'formik';
import { isEmpty, memoize } from 'lodash';
import ScheduleWeekDayButtons from './ScheduleWeekDayButtons';
import LABELS from './utils/labels';

const propTypes = {
  isAlwaysEnabled: PropTypes.bool,
  isEnabledScheduleSync: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxHourLimit: PropTypes.string,
  minHourLimit: PropTypes.string,
  resetScheduleSync: PropTypes.func,
  scheduleSyncDescription: PropTypes.string,
  selectedDays: PropTypes.arrayOf(PropTypes.string),
  shouldDisplaySpaces: PropTypes.bool,
  spaces: PropTypes.shape({
    description: PropTypes.string,
    fieldName: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  timeOfDay: PropTypes.string,
  toggleSchedule: PropTypes.func,
  updateSchedule: PropTypes.func,
};

const ScheduleSync = ({
  maxHourLimit,
  minHourLimit,
  isLoading,
  isAlwaysEnabled = false,
  isEnabledScheduleSync = false,
  shouldDisplaySpaces = false,
  spaces = {},
  selectedDays,
  timeOfDay = '',
  scheduleSyncDescription,
  updateSchedule = () => {},
  toggleSchedule = () => {},
  resetScheduleSync,
}) => {
  const onSubmit = useCallback(
    (values) => {
      updateSchedule(values);
      toggleSchedule(values);
    },
    [updateSchedule, toggleSchedule]
  );
  const validateForm = useCallback(
    ({ timeDay, weekDays, scheduleSyncCheckbox, ...rest }) => {
      const errors = {};
      if (!timeDay && (scheduleSyncCheckbox || isAlwaysEnabled)) {
        errors.timeDay = 'Required';
      }
      if (weekDays?.length === 0 && (scheduleSyncCheckbox || isAlwaysEnabled)) {
        errors.weekDays = 'Required';
      }

      return errors;
    },
    [isAlwaysEnabled]
  );

  const initialValues = {
    timeDay: timeOfDay,
    weekDays: selectedDays,
  };

  if (!isAlwaysEnabled) {
    initialValues.scheduleSyncCheckbox = isEnabledScheduleSync;
  }
  if (shouldDisplaySpaces) {
    initialValues[spaces?.fieldName] = spaces?.value;
  }

  return (
    <Boundaries>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        {({
          handleSubmit,
          values: { weekDays, timeDay, scheduleSyncCheckbox, ...rest },
          setFieldValue,
          handleChange,
          isValid,
        }) => {
          const handleWeekDayOnClick = memoize((newWeekDays) => {
            setFieldValue('weekDays', newWeekDays);
          });

          const shouldDisableSubmitButton = isLoading || !isValid;
          const shouldDisableFields =
            (!scheduleSyncCheckbox && !isAlwaysEnabled) || isLoading;

          return (
            <>
              <Header as='h4' content={LABELS.SYNC_SETTINGS} />
              <Form onSubmit={handleSubmit}>
                {!isAlwaysEnabled && (
                  <Form.Checkbox
                    checked={scheduleSyncCheckbox}
                    id='schedule_checkbox'
                    label={LABELS.SCHEDULE_CHECKBOX}
                    name='scheduleSyncCheckbox'
                    onChange={handleChange}
                  />
                )}

                {shouldDisplaySpaces && (
                  <Form.Field key='space'>
                    <label htmlFor={spaces.fieldName}>{spaces.label}</label>
                    <TextArea
                      data-testid='space-textarea'
                      id={spaces.fieldName}
                      name={spaces.fieldName}
                      onChange={handleChange}
                      rows={6}
                      value={rest[spaces.fieldName]}
                    />
                    <p>
                      <small>{spaces.description}</small>
                    </p>
                  </Form.Field>
                )}

                <Form.Group>
                  <Form.Field>
                    <label aria-label={LABELS.SCHEDULE_SYNC}>
                      {LABELS.SCHEDULE_SYNC}
                    </label>
                    <ScheduleWeekDayButtons
                      disabled={shouldDisableFields}
                      onClick={handleWeekDayOnClick}
                      selectedDays={weekDays}
                    />
                    {scheduleSyncDescription && (
                      <p>
                        <small>{scheduleSyncDescription}</small>
                      </p>
                    )}
                  </Form.Field>

                  <Form.Field>
                    <label htmlFor='timeDay'> {LABELS.TIME_DAY} </label>
                    <Input
                      disabled={shouldDisableFields}
                      id='timeDay'
                      max={maxHourLimit}
                      min={minHourLimit}
                      name='timeDay'
                      onChange={handleChange}
                      type='time'
                      value={timeDay}
                    />
                  </Form.Field>

                  <Form.Field style={{ margin: '1.5em 0', marginBottom: 0 }}>
                    <label>{''}</label>
                    <Button
                      basic
                      content={LABELS.SAVE}
                      disabled={shouldDisableSubmitButton}
                      id='save_button'
                      primary
                      type='submit'
                    />
                  </Form.Field>
                </Form.Group>
                {!isEmpty(initialValues?.weekDays) && (
                  <Button
                    onClick={resetScheduleSync}
                    style={{
                      color: 'red',
                    }}
                    type='button'
                  >
                    Reset Scheduled Sync
                  </Button>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </Boundaries>
  );
};

ScheduleSync.propTypes = propTypes;

export default ScheduleSync;
