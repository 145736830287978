import { Divider, Form, Grid, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Molecules
import ValidatedCheckbox from './ValidatedCheckbox';
// Controllers
import TenantConfigMenuFormController from '../controllers/TenantConfigMenuFormController';

class TenantConfigMenuForm extends PureComponent {
  static propTypes = {
    approvalsMainMenuAlreadyExist: PropTypes.bool,
    casesMainMenuAlreadyExist: PropTypes.bool,
    dirty: PropTypes.bool,
    equipmentValueAlreadyExist: PropTypes.bool,
    formValues: ImmutablePropTypes.map,
    fullEditProfilValueAlreadyExist: PropTypes.bool,
    info: PropTypes.string,
    isSaving: PropTypes.bool,
    publicEmployeeNbrValueAlreadyExist: PropTypes.bool,
    requestsValueAlreadyExist: PropTypes.bool,
    setTenantMenuConfiguration: PropTypes.func.isRequired,
    storeValueAlreadyExist: PropTypes.bool,
  };

  static defaultProps = {
    approvalsMainMenuAlreadyExist: false,
    casesMainMenuAlreadyExist: false,
    dirty: false,
    equipmentValueAlreadyExist: false,
    formValues: null,
    fullEditProfilValueAlreadyExist: false,
    info: '',
    isSaving: false,
    publicEmployeeNbrValueAlreadyExist: false,
    requestsValueAlreadyExist: false,
    storeValueAlreadyExist: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      approvalsMainMenuAlreadyExist,
      casesMainMenuAlreadyExist,
      equipmentValueAlreadyExist,
      formValues,
      fullEditProfilValueAlreadyExist,
      publicEmployeeNbrValueAlreadyExist,
      requestsValueAlreadyExist,
      setTenantMenuConfiguration,
      storeValueAlreadyExist,
    } = this.props;

    const employeNbr = Boolean(formValues.get('publicEmployeeNumber'));
    const equipmentConfig = Boolean(formValues.get('my_equipment'));
    const fullProfil = Boolean(formValues.get('full_edit_profile'));
    const requestsConfig = Boolean(formValues.get('my_requests'));
    const storeConfig = Boolean(formValues.get('store'));
    const valueOfDisableContacts = Boolean(formValues.get('disable_contacts'));
    const approvalsMainMenu = Boolean(formValues.get('approvalsMainMenu'));
    const casesMainMenu = Boolean(formValues.get('casesMainMenu'));

    const valuesArray = [
      {
        key: 'approvalsMainMenu',
        update: approvalsMainMenuAlreadyExist,
        value: approvalsMainMenu,
      },
      {
        key: 'casesMainMenu',
        update: casesMainMenuAlreadyExist,
        value: casesMainMenu,
      },
      {
        key: 'store',
        update: storeValueAlreadyExist,
        value: storeConfig,
      },
      {
        key: 'my_equipment',
        update: equipmentValueAlreadyExist,
        value: equipmentConfig,
      },
      {
        key: 'my_requests',
        update: requestsValueAlreadyExist,
        value: requestsConfig,
      },
      {
        key: 'full_edit_profile',
        update: fullEditProfilValueAlreadyExist,
        value: fullProfil,
      },
      {
        key: 'publicEmployeeNumber',
        update: publicEmployeeNbrValueAlreadyExist,
        value: employeNbr,
      },
      // These 3 options must be turned on simultaneously
      {
        key: 'disableDirectory_hideContacts',
        value: valueOfDisableContacts,
      },
      {
        key: 'disableDirectory_hideMyMessages',
        value: valueOfDisableContacts,
      },
      {
        key: 'disableDirectory_disableLinksToProfiles',
        value: valueOfDisableContacts,
      },
    ];

    setTenantMenuConfiguration(valuesArray);
  };

  render() {
    const { dirty, info, isSaving } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Grid>
          <Grid.Column width={6}>
            <ValidatedCheckbox label='Show Store' name='store' toggle />

            <ValidatedCheckbox
              label='Show My Equipment'
              name='my_equipment'
              toggle
            />

            <ValidatedCheckbox
              label='Show My Requests'
              name='my_requests'
              toggle
            />

            <ValidatedCheckbox
              label='Show Full Employee Profile Edit'
              name='full_edit_profile'
              toggle
            />

            <ValidatedCheckbox
              label='Show Public Employee Number'
              name='publicEmployeeNumber'
              toggle
            />

            <ValidatedCheckbox
              label='Disable Contacts'
              name='disable_contacts'
              toggle
            />

            <ValidatedCheckbox
              label='Show Approvals on Main Menu'
              name='approvalsMainMenu'
              toggle
            />

            <ValidatedCheckbox
              label='Show Cases on Main Menu'
              name='casesMainMenu'
              toggle
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Message content={info} info />
          </Grid.Column>
        </Grid>

        <Divider clearing hidden />

        {dirty && (
          <>
            <Form.Button
              color='green'
              content='Save'
              disabled={isSaving}
              floated='right'
              loading={isSaving}
            />
            <Divider clearing hidden />
          </>
        )}
      </Form>
    );
  }
}

const TenantConfigMenuFormTest = TenantConfigMenuForm;

// eslint-disable-next-line no-class-assign -- DEV-1526
TenantConfigMenuForm = reduxForm({
  enableReinitialize: true,
  form: 'TenantMenuConfiguration',
})(TenantConfigMenuForm);
export { TenantConfigMenuFormTest };
export default TenantConfigMenuFormController(TenantConfigMenuForm);
