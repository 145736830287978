import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import Immutable, { fromJS } from 'immutable';
import { Dropdown, Flag, Form, Icon, Input } from 'semantic-ui-react';
import { change } from 'redux-form/immutable';
import { find } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Atoms
import ErrorLabel from '../atoms/ErrorLabel';

// globals
import CountryCodes from '../../globals/CountryCodes';

class PhoneInput extends PureComponent {
  static propTypes = {
    /** Wheter to have a preselected country. Defaults to USA  */
    defaultCountry: PropTypes.shape({
      flag: PropTypes.string,
      key: PropTypes.string,
      mask: PropTypes.string,
      placeholder: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
    /** Form error */
    formError: PropTypes.string,
    /** Coming from Redux Form  */
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        ImmutablePropTypes.mapContains({
          country_code: PropTypes.string,
          country_iso: PropTypes.string,
          number: PropTypes.string,
        }),
      ]),
    }),
    /** Label of the field  */
    label: PropTypes.string,
    /** Coming from Redux Form  */
    meta: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      dispatch: PropTypes.any,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      form: PropTypes.any,
      submitFailed: PropTypes.bool,
    }),
    /** Use custom placeholder. If not used, will be grabbed from CountryCodes if available */
    placeholder: PropTypes.string,
    /** Set the Phone input read only */
    readOnly: PropTypes.bool,
    /** Allow defining a width inside a form */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    defaultCountry: CountryCodes[0],
    formError: '',
    input: fromJS({
      value: {
        country_code: '+1',
        country_iso: 'us',
        number: '',
      },
    }),
    label: 'Phone Number',
    meta: {},
    placeholder: '',
    readOnly: false,
    width: null,
  };

  componentDidMount() {
    const {
      input,
      meta: { form, dispatch },
    } = this.props;

    if (input && input.value) {
      // Check for uppercase country_iso
      const country_iso = input.value.get('country_iso', '') || '';

      // Set public flag
      if (!input.value.has('public')) {
        dispatch(change(form, `${input.name}.public`, false));
      }

      // Need to check if the country_iso has been pass in uppercase (like from CSV import), if not
      // we have to force the uppercase
      if (country_iso !== country_iso.toUpperCase()) {
        // Set the country_iso to upperCase
        dispatch(
          change(form, `${input.name}.country_iso`, country_iso.toUpperCase())
        );
      }
    }
  }

  handleDropDownCountryClick = (e, d) => {
    const {
      input,
      meta: { form, dispatch },
    } = this.props;

    const optionSelected = d;

    // In case that this is used inside a redux form Field
    // we save the value inside the hidden field _countryCode
    if (input) {
      dispatch(
        change(
          form,
          `${input.name}.country_iso`,
          optionSelected.flag.toUpperCase()
        )
      ); // the prop in the option component is called flag but contains the iso in lowerCase
      dispatch(
        change(form, `${input.name}.country_code`, optionSelected.value)
      );
    }
  };

  handleInputChange = (e) => {
    const {
      defaultCountry,
      input,
      meta: { form, dispatch },
    } = this.props;

    const phoneInput = e.target.value.replace(/-/g, '');
    if (input) {
      dispatch(change(form, `${input.name}.number`, phoneInput));

      const countryIsoValue = input.value.get('country_iso');
      const countryCodeValue = input.value.get('country_code');

      // Fixes for when we receive a number that doesn't have either
      // country_iso or country_code set
      if (!countryIsoValue || !countryCodeValue) {
        // try to match at least the country code
        let selectedCountry = find(
          CountryCodes,
          (country) => country.value === countryCodeValue
        );
        if (!selectedCountry) {
          // otherwise just use the default
          selectedCountry = defaultCountry;
        }

        dispatch(
          change(form, `${input.name}.country_iso`, selectedCountry.iso)
        );
        dispatch(
          change(form, `${input.name}.country_code`, selectedCountry.value)
        );
      }
    }
  };

  render() {
    const {
      formError,
      input,
      label,
      meta: { submitFailed, error },
      placeholder,
      readOnly,
      width,
      defaultCountry,
    } = this.props;

    // This should not work with non map values
    if (!Immutable.Map.isMap(input.value)) {
      return null;
    }

    const phoneNumberValue = input.value.get('number');
    const countryIsoValue = input.value.get('country_iso');
    const countryCodeValue = input.value.get('country_code');

    let selectedCountry =
      countryIsoValue &&
      find(CountryCodes, (country) => country.iso === countryIsoValue);

    if (!selectedCountry) {
      selectedCountry = defaultCountry;
    }

    // Check if the phone typed fit the selected mask length
    const maskSelected = selectedCountry.mask
      ? selectedCountry.mask.split('-').join('')
      : '';

    const phoneNumber = phoneNumberValue
      ? phoneNumberValue.split('-').join('')
      : '';
    const phoneVerified =
      countryIsoValue === 'US'
        ? phoneNumber && phoneNumber.length === maskSelected.length
        : phoneNumber.length > 0;

    return (
      <Form.Field width={width}>
        <label htmlFor=''>{label}</label>
        <Input className='labeled' icon>
          {!readOnly && (
            <Dropdown
              basic
              className='label'
              trigger={
                <span>
                  <Flag name={selectedCountry.key} />
                  {selectedCountry.value}
                </span>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  {CountryCodes.map((country) => (
                    <Dropdown.Item
                      flag={country.key}
                      key={country.key}
                      onClick={this.handleDropDownCountryClick}
                      text={country.text}
                      value={country.value}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {readOnly ? (
            <Input
              readOnly
              transparent
              value={countryCodeValue + phoneNumberValue}
            />
          ) : (
            <InputMask
              alwaysShowMask={!selectedCountry.mask}
              className={classNames('phone-mask', {
                readOnly: readOnly,
              })}
              mask={selectedCountry.mask}
              maskChar={null}
              onChange={this.handleInputChange}
              placeholder={placeholder || selectedCountry.placeholder}
              readOnly={readOnly}
              type='tel'
              value={phoneNumberValue || ''}
            />
          )}
          {/* NOTE: This component below is the actual html input tag */}

          {phoneVerified && !readOnly && <Icon name='checkmark' />}
        </Input>

        {submitFailed && error ? (
          <ErrorLabel error={error} inputName={input.name} pointing='above' />
        ) : null}
        {formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )}
      </Form.Field>
    );
  }
}

export default PhoneInput;
