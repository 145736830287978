const api_cards_v0_1_card = {
  /* eslint-disable sort-keys -- backend shape */
  results: [
    {
      id: 4526622,
      name: 'Default template for task',
      nudged_time: null,
      frontend_blob: {
        menu: [
          {
            icon: 'delete',
            text: 'Dismiss',
            swipe: 'right',
          },
          {
            icon: 'stop',
            text: 'Cancel Request',
            swipe: 'right',
          },
        ],
        header: {
          text: 'Task Title',
        },
        content: null,
        card_image: {
          url: '/images/cards/cards-todo.svg',
        },
        cta_button: [
          {
            text: 'THIS TEXT SHOULD NOT SHOW',
            condition: null,
            action_type: 'link',
          },
        ],
      },
      chat_enabled: false,
      snooze_time: 30,
      sla_threshold: 0,
      allowed_time: 0,
      natural_expiration: 100000,
      due_date: null,
      threshold: 'OVERDUE',
      cta_button: null,
      content: [
        {
          text: "RITM0010165: Request 'Local External Approver Test2' is created by Andres Araujo",
        },
      ],
      header: {
        text: 'RITM0010165: Local External Approver Test2',
      },
      display_attributes: null,
      card_meta: {
        task_status: 'pending_approval',
        task_type: 'external_approvals',
        children: {},
        parent: {},
        cancelable: true,
        external_task_link:
          'https://dev119897.service-now.com/task.do?sys_id=877f1a16c32ba5109524216dc001314e',
        live_chat: false,
        source: 'servicenow',
        description:
          'Price: $369.99USD Item Description: <p>We are making the Apple Watch available to all field technicians so they can stay up with workloads, get notifications, and take quick actions for approvals and assignments.</p>\r\n<p>We are providing the Sport model with:</p>\r\n<ul><li>38mm/42mm space gray and black sport band</li><li>38mm/42mm silver aluminum with either the white or blue band</li></ul>',
      },
      operation_state: 'ACTIVE',
      state: 'ACTIVE',
      assigned_user: 219181,
      business_obj_owner: null,
      channel_id: 'cb460649-be48-4000-8023-f28ba4677d30',
      channel_type: 'scoped',
      unread_message_count: 0,
      external_link_url: null,
      asset_model: null,
      asset_id: null,
      default_message: null,
      is_subscriber: false,
      owners_email: 'andres.araujo@espressive.com',
      eid: '6bd241a6-1915-48f7-865f-6a51088c1d44',
      sys_date_updated: '2023-10-31T21:12:13.150787Z',
      sys_date_created: '2023-10-31T20:59:23.770574Z',
      type: 'APPROVAL',
      behavior_type: 'STANDARD',
      updates_available: false,
      priority: 5000,
      object_id: 13931,
      backend_blob: null,
      card_expiry_time: null,
      owner: 219181,
      template: 1,
      content_type: 81,
    },
  ],
  /* eslint-enable sort-keys -- backend shape */
};

export default api_cards_v0_1_card;
