const api_workflow_v0_1_workflowtask = {
  actions: null,
  attributes: null,
  auth_skip: false,
  eid: 'fbd04848-33a5-4756-8768-702c17f07014',
  esp_objects: null,
  id: 173,
  images: [],
  methods: null,
  name: 'Test Workflow',
  post_condition_group: null,
  pre_condition_group: null,
  related_workflow: null,
  related_workflow_condition: null,
  tags: '',
  url: 'http://tenant1.esp/api/workflow/v0.1/workflowtask/173/',
};
export default api_workflow_v0_1_workflowtask;
