import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import {
  Button,
  Form,
  Input,
  List,
  Menu,
  Modal,
  Segment,
} from 'semantic-ui-react';

// Atoms
import ScrollArea from '../atoms/ScrollArea';

// Controllers
import SearchController from '../controllers/SearchController';

import SearchModels from '../../utils/SearchModels';
import UserUtils from '../../utils/UserUtils';

// in ms
const SEARCH_DELAY = 300;

class HeaderSearchModal extends PureComponent {
  static propTypes = {
    currentUser: ImmutablePropTypes.map,

    isLoading: PropTypes.bool.isRequired,
    results: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    search: PropTypes.func,
    searchContext: PropTypes.shape({
      appModel: PropTypes.string,
      msg: PropTypes.string,
      name: PropTypes.string,
      resultTemplate: PropTypes.func,
      routeToResult: PropTypes.string,
    }),
    setUserInput: PropTypes.func,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    currentUser: null,
    results: null,
    search: _.noop,
    searchContext: null,
    setUserInput: _.noop,
  };

  state = {
    isFocused: false,
    isOpen: false,
  };

  handleChange = (e, data) => {
    const userInput = data.value;
    const { setUserInput } = this.props;

    setUserInput(userInput);

    _.defer(this.search);
  };

  handleClickResult = () => {
    this.handleModalClose();
  };

  handleModalOpen = () => {
    this.setState({
      isFocused: false,
      isOpen: true,
    });
  };
  handleFocus = () => {
    this.setState({
      isFocused: true,
    });
  };
  handleBlur = () => {
    this.setState({
      isFocused: false,
    });
  };
  handleModalClose = () => {
    this.setState({
      isFocused: false,
      isOpen: false,
    });
  };

  search = _.debounce(() => {
    const { userInput, search } = this.props;

    const isEmpty = _.isEmpty(userInput.trim());

    if (!isEmpty) {
      this.handleBlur();
      search();
    }
  }, SEARCH_DELAY);

  render() {
    const { currentUser, isLoading, results, searchContext, userInput } =
      this.props;

    if (!currentUser) {
      return null;
    }

    const isHidden =
      !searchContext ||
      searchContext.appModel === SearchModels.FEED_CARD ||
      (searchContext.appModel === SearchModels.USER &&
        !UserUtils.isManagerConfirmed(currentUser));

    if (isHidden) {
      return null;
    }

    const { isOpen, isFocused } = this.state;

    const styles = {
      scrollArea: {
        padding: '62px 1em 48px',
      },
      searchInput: {
        boxShadow: '0 0 1em .5em #fff',
        left: 0,
        margin: '1em 1em 0 1em',
        position: 'fixed',
        top: '48px',
        width: 'calc(100% - 2em)',
        zIndex: 100,
      },
    };

    const modalTrigger = <Button basic className='transparent' icon='search' />;

    const modalCloseButton = <Menu.Item className='close'>{'Exit'}</Menu.Item>;

    return (
      <div className='header-search-modal'>
        <Modal
          className='scrolling'
          closeIcon={modalCloseButton}
          onClose={this.handleModalClose}
          onOpen={this.handleModalOpen}
          open={isOpen}
          size='small'
          trigger={modalTrigger}
        >
          <Form as='div'>
            <Modal.Header content='Search' />
            <Modal.Content
              style={{
                height: 'calc(100% - 48px)',
                position: 'relative',
              }}
            >
              <Input
                fluid
                icon='search'
                iconPosition='left'
                loading={isLoading}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                placeholder={intl.formatMessage({
                  id: searchContext.msg,
                })}
                style={styles.searchInput}
                value={userInput}
              />

              <ScrollArea padding={styles.scrollArea.padding}>
                {/* Empty State */}
                {isFocused && (
                  <Segment className='use-get-help-reminder' textAlign='center'>
                    <p>
                      {intl.formatMessage({
                        id: 'message.use_get_help_reminder',
                      })}
                      {/* {`To ask a question, get help or create a ticket click the “Get Help” item on the left side of the screen. If you want to continue searching the current view continue typing.`} */}
                    </p>
                  </Segment>
                )}
                {results &&
                  results.isEmpty() &&
                  intl.formatMessage({
                    id: 'message.no_matching_results',
                  })}

                <List>
                  {results &&
                    results.map((result) =>
                      React.createElement(searchContext.resultTemplate, {
                        currentUser,
                        key: result.get('pk'),
                        onClick: this.handleClickResult,
                        result,
                        routeToResult: searchContext.routeToResult,
                        routeToResultIncludesQ: false,
                        userInput,
                      })
                    )}
                </List>
              </ScrollArea>
            </Modal.Content>
          </Form>
        </Modal>
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
HeaderSearchModal = SearchController(HeaderSearchModal);

export default HeaderSearchModal;
