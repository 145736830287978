const api_emails_v0_1_credentials = {
  id: 1,
  results: [
    {
      id: 1,
      is_primary: true,
    },
  ],
};
export default api_emails_v0_1_credentials;
