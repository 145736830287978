import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Message } from 'semantic-ui-react';
// Globals
import TypeBuildEditFaq, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBuildEditFaq';
import BuildEditFaqController from '../../controllers/BuildEditFaqController';

/**
 * This block is an empty component which allow
 * to build a BE scratch object for this FAQ
 */

class BuildEditFaq01 extends PureComponent {
  static propTypes = {
    ...TypeBuildEditFaq,
    beScratchData: ImmutablePropTypes.map,
    blocks: ImmutablePropTypes.list,
    forceResetscratch: PropTypes.func.isRequired,
    setFaqBEScratch: PropTypes.func.isRequired,
    taskSelected: ImmutablePropTypes.map,
  };

  static defaultProps = {
    ...TypeDefaults,
    beScratchData: null,
    blocks: null,
    taskSelected: null,
  };

  componentDidMount() {
    const {
      beScratchData,
      blocks,
      forceReBuild,
      forceReset,
      forceResetscratch,
      setFaqBEScratch,
      taskSelected,
    } = this.props;

    // Only build a new beScratch obj if there is not BE scratch obj existing or it doesn't contain anything about an existing FAQ
    // or if we force it to rebuild
    if (!beScratchData || !beScratchData.get('faq_responses') || forceReBuild) {
      setFaqBEScratch(blocks, forceReBuild, taskSelected).catch(
        (possibleError) => {
          this.setFAQResult = possibleError;
        }
      );
    } else if (forceReset) {
      forceResetscratch(beScratchData);
    }
  }

  render() {
    const { errorsApi, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {errorsApi && !errorsApi.isEmpty() && (
          <Message
            content={errorsApi.getIn([0, 'message'])}
            header='Error'
            negative
          />
        )}
        {this.setFAQResult && this.setFAQResult.error && (
          <Message
            content={this.setFAQResult.error.message}
            header='Error'
            negative
          />
        )}
      </div>
    );
  }
}

export const BuildEditFaq01Test = BuildEditFaq01;

export default BuildEditFaqController(BuildEditFaq01);
