import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Container, Divider } from 'semantic-ui-react';
import ChatBotIcon from '../../../globals/ChatBotIcon';
import { CLIENT_WIDGET_FRAME_REDIRECT } from '../../globals/clientFrameKeys';
import { intl } from 'esp-util-intl';
import WidgetAuthSuccessController from '../controllers/WidgetAuthSuccessController';
import PropTypes from 'prop-types';
import APIcall from '../../utils/APIcall';
import endpointGenerator from '../../utils/endpointGenerator';

const validateRedirectUrl = async (url) => {
  return APIcall.post({
    data: { url },
    token: true,
    url: endpointGenerator.genPath('commons.validateUrlRedirect'),
  });
};

async function handleRedirectBack() {
  try {
    // Request a one time code to be used to redirect back to the widget host
    const response = await APIcall.post({
      data: {},
      token: true,
      url: endpointGenerator.genPath('authentication.auth.login.requestOtc'),
    });

    const url = new URL(localStorage.getItem(CLIENT_WIDGET_FRAME_REDIRECT));
    if (url.searchParams.has('b_otc')) {
      url.searchParams.delete('b_otc');
    }
    url.searchParams.append('b_otc', response.body.code);

    const isAllowed = await validateRedirectUrl(url.href);

    if (isAllowed) {
      localStorage.removeItem(CLIENT_WIDGET_FRAME_REDIRECT);
      window.location.href = url.href;
    } else {
      // eslint-disable-next-line no-console -- debug
      console.warn('[WidgetAuthSuccess] invalid redirect url', url.href);
    }
  } catch (e) {
    // eslint-disable-next-line no-console -- debug
    console.warn('[WidgetAuthSuccess] invalid redirect url', e);
  }
}

// time before redirecting back to the widget host automatically
const autoRedirectCountdownSeconds = 2;

const propTypes = {
  appBootstrap: PropTypes.func.isRequired,
};

const WidgetAuthSuccess = (props) => {
  const { appBootstrap } = props;
  const [secondsRemaining, setSecondsRemaining] = useState(
    autoRedirectCountdownSeconds
  );

  useEffect(() => {
    // Needed to call this to have the users preferred language set
    appBootstrap();
  }, [appBootstrap]);

  useEffect(() => {
    if (secondsRemaining === 0) {
      handleRedirectBack();
      return () => {};
    }
    const interval = setInterval(() => {
      setSecondsRemaining((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [secondsRemaining]);

  return (
    <div>
      <section>
        <Helmet title='Widget Auth Success' />

        <Container fluid textAlign='center'>
          <Divider hidden />
          <div>
            <ChatBotIcon isCustomBrandingEnabled size='large' />
          </div>
          <div>
            <div className='barista_404_bubble'>
              <p>
                {intl.formatMessage({
                  id: 'label.widget_login_success_title',
                })}
                <br />
                {intl.formatMessage({
                  id: 'label.widget_login_success_message',
                })}
              </p>
              <Button onClick={handleRedirectBack} primary>
                {intl.formatMessage({
                  id: 'label.widget_button_redirect_back',
                })}
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

WidgetAuthSuccess.propTypes = propTypes;

export default WidgetAuthSuccessController(WidgetAuthSuccess);
