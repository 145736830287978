import Immutable, { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  authBackends: [],
  /** @type {{string, object}} */
  baristabot_integrations: {
    isLoading: false,
  },

  businessObjects: [],

  confluence: {
    contentSync: {
      isLoading: false,
      scheduled_sync: {
        time: '00:00',
      },
      spaces: '',
    },
    lastSync: {
      datetime: '',
      isLoading: false,
      results: {
        added: 0,
        archived: 0,
        deleted: 0,
        found: 0,
        updated: 0,
      },
    },
    settings: {
      credentials: {
        api_token: '',
        isLoading: false,
        isSubmitting: false,
        url: '',
        username: '',
      },
      isConnectionSet: false,
      isConnectionValid: false,
      isLoading: false,
    },
  },

  connectionIsLoading: false,

  csv_upload_instances: {},

  csv_upload_instances_loading_status: {},

  elcSync: {
    isSyncLoading: false,
    lastJob: {},
  },

  emailAccountTestConnection: '',

  emailCaseGenerator: {},

  emailCredentials: [],

  emailIntegration: {
    currentCredential: {},
  },

  emailTestConnectionSuccess: false,

  errorAtCSVParsing: false,

  genericTableData: {},

  genericTableResults: {},

  importHistoryResults: [],

  importHistoryResultsCount: 0,

  isLoading: false,

  isLoadingAuthBackedns: false,

  isLoadingAzureAccount: false,

  isLoadingAzureDelete: false,

  isLoadingCVSParsing: false,

  isLoadingDelete: false,

  isLoadingEmailCaseGenerator: false,

  isLoadingEmailTestConnection: false,

  isLoadingImportHistory: false,

  isLoadingOktaAccount: false,

  isLoadingRemoteUserCreateFlag: false,

  isLoadingSchedules: false,

  isLoadingSlack: false,

  isLoadingSnCategories: false,

  isLoadingSnowConnectionStatus: false,

  isLoadingSnowCredentials: false,

  isLoadingWorkflowMap: false,

  isSendingInvites: false,

  isSlackbotActive: false,

  isUploading: false,

  isloadingAddEmailCredentials: false,

  isloadingEmailCredentials: false,

  msftTeams: {
    isLoading: false,
    tokenData: {
      status: '',
      token: '',
    },
  },

  oktaAccount: {},

  oktaTenant: {
    isLoading: false,
    loaded: false,
    tenant: {},
  },

  remoteUserCreateFlag: {},

  samlServiceProvider: {
    acs_url: null,
  },

  scheduleConnectionSuccess: false,

  schedules: [],

  sharepoint: {
    settings: {
      isConnectionSet: false,
      isLoading: false,
    },
  },

  snCategories: [],

  snWorkflowMap: [],

  snowConnectionStatus: {},

  snowCredentials: {},

  snowProducts: {
    pagination: {},
  },
  testConnectionSent: false,
});

/**
 * Announcements reducer
 * @param state
 * @param action
 * @returns {*}
 */
const IntegrationReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_CONFLUENCE_CREDENTIALS_START: {
      return state.setIn(
        ['confluence', 'settings', 'credentials', 'isLoading'],
        true
      );
    }
    case actionTypes.GET_CONFLUENCE_CREDENTIALS_SUCCESS: {
      return state
        .setIn(['confluence', 'settings', 'credentials', 'isLoading'], false)
        .setIn(
          ['confluence', 'settings', 'credentials', 'username'],
          action.response.username
        )
        .setIn(
          ['confluence', 'settings', 'credentials', 'api_token'],
          action.response.api_token
        )
        .setIn(
          ['confluence', 'settings', 'credentials', 'url'],
          action.response.url
        );
    }
    case actionTypes.GET_CONFLUENCE_CREDENTIALS_FAIL: {
      return state
        .setIn(['confluence', 'settings', 'credentials', 'isLoading'], false)
        .setIn(
          ['confluence', 'settings', 'credentials', 'errors'],
          action.response.errors
        );
    }
    case actionTypes.SET_CONFLUENCE_CREDENTIALS_START: {
      return state.setIn(
        ['confluence', 'settings', 'credentials', 'isSubmitting'],
        true
      );
    }
    case actionTypes.SET_CONFLUENCE_CREDENTIALS_SUCCESS: {
      return state
        .setIn(['confluence', 'settings', 'credentials', 'isSubmitting'], false)
        .setIn(
          ['confluence', 'settings', 'credentials', 'username'],
          action.response.username
        )
        .setIn(
          ['confluence', 'settings', 'credentials', 'api_token'],
          action.response.api_token
        )
        .setIn(
          ['confluence', 'settings', 'credentials', 'url'],
          action.response.url
        );
    }
    case actionTypes.SET_CONFLUENCE_CREDENTIALS_FAIL: {
      return state
        .setIn(['confluence', 'credentials', 'isSubmitting'], false)
        .setIn(['confluence', 'credentials', 'errors'], action.response.errors);
    }
    case actionTypes.TEST_CONFLUENCE_SETTINGS_START: {
      return state.setIn(['confluence', 'settings', 'isLoading'], true);
    }
    case actionTypes.TEST_CONFLUENCE_SETTINGS_SUCCESS: {
      return state
        .setIn(['confluence', 'settings', 'isLoading'], false)
        .setIn(
          ['confluence', 'settings', 'isConnectionSet'],
          action.results.CONNECTION_IS_SET
        )
        .setIn(
          ['confluence', 'settings', 'isConnectionValid'],
          action.results.CONNECTION_IS_VALID
        );
    }
    case actionTypes.TEST_CONFLUENCE_SETTINGS_FAILURE: {
      return state.setIn(['confluence', 'settings', 'isLoading'], false);
    }

    case actionTypes.LOAD_CONFLUENCE_CONTENT_SYNC_START: {
      return state.setIn(['confluence', 'contentSync', 'isLoading'], true);
    }
    case actionTypes.LOAD_CONFLUENCE_CONTENT_SYNC_SUCCESS: {
      return state
        .setIn(['confluence', 'contentSync'], fromJS(action.results))
        .setIn(
          ['confluence', 'contentSync', 'scheduled_sync', 'days'],
          Immutable.Set(action.results.scheduled_sync.days)
        )
        .setIn(['confluence', 'contentSync', 'isLoading'], false);
    }
    case actionTypes.LOAD_CONFLUENCE_CONTENT_SYNC_FAILURE: {
      return state.setIn(['confluence', 'contentSync', 'isLoading'], false);
    }

    case actionTypes.LOAD_CONFLUENCE_LAST_SYNC_START: {
      return state.setIn(['confluence', 'lastSync', 'isLoading'], true);
    }
    case actionTypes.LOAD_CONFLUENCE_LAST_SYNC_SUCCESS: {
      return state
        .setIn(['confluence', 'lastSync'], fromJS(action.results))
        .setIn(['confluence', 'lastSync', 'isLoading'], false);
    }
    case actionTypes.LOAD_CONFLUENCE_LAST_SYNC_FAILURE: {
      return state.setIn(['confluence', 'lastSync', 'isLoading'], false);
    }

    case actionTypes.AZURE_ACCOUNT_DELETE_START: {
      return state.set('isLoadingAzureDelete', true);
    }

    case actionTypes.AZURE_ACCOUNT_DELETE_SUCCESS: {
      return state.set('isLoadingAzureDelete', false);
    }

    case actionTypes.AZURE_ACCOUNT_DELETE_FAILURE: {
      return state.set('isLoadingAzureDelete', false);
    }

    case actionTypes.AZURE_ACCOUNT_LOAD_START: {
      return state.set('isLoadingAzureAccount', true);
    }

    case actionTypes.AZURE_ACCOUNT_LOAD_SUCCESS: {
      return state.set('isLoadingAzureAccount', false);
    }

    case actionTypes.AZURE_ACCOUNT_LOAD_FAILURE: {
      return state.set('isLoadingAzureAccount', false);
    }

    case actionTypes.AZURE_ACCOUNT_SAVE_START: {
      return state.set('isLoadingAzureAccount', true);
    }

    case actionTypes.AZURE_ACCOUNT_SAVE_SUCCESS: {
      const authBackends = state.get('authBackends');
      const index = authBackends.findIndex(
        (ab) => ab.get('id') === action.azureAuth.id
      );

      if (index !== -1) {
        return state
          .set('isLoadingAzureAccount', false)
          .setIn(['authBackends', index], fromJS(action.azureAuth));
      }

      return state
        .set('isLoadingAzureAccount', false)
        .set('authBackends', authBackends.push(fromJS(action.azureAuth)));
    }

    case actionTypes.AZURE_ACCOUNT_SAVE_FAILURE: {
      return state.set('isLoadingAzureAccount', false);
    }

    case actionTypes.SLACKBOT_IS_ACTIVE_START:
      return state.set('isLoadingSlack', true);

    case actionTypes.SLACKBOT_IS_ACTIVE_SUCCESS:
      return state.set('isSlackbotActive', true).set('isLoadingSlack', false);

    case actionTypes.SLACKBOT_IS_ACTIVE_FAIL:
      return state.set('isSlackbotActive', false).set('isLoadingSlack', false);

    case actionTypes.SLACKBOT_IS_NOT_ACTIVE_SUCCESS:
      return state.set('isLoadingSlack', false);

    case actionTypes.SLACKBOT_REMOVE_START:
      return state.set('isLoadingSlack', true);

    case actionTypes.SLACKBOT_REMOVE_SUCCESS:
      return state.set('isSlackbotActive', false).set('isLoadingSlack', false);

    case actionTypes.SLACKBOT_REMOVE_FAIL:
      return state.set('isLoadingSlack', false);

    case actionTypes.EMAIL_CASE_DELETE_START:
      return state.set('isLoadingEmailCaseGenerator', true);

    case actionTypes.EMAIL_CASE_DELETE_FAIL:
      return state.set('isLoadingEmailCaseGenerator', false);

    case actionTypes.EMAIL_CASE_DELETE_SUCCESS: {
      let credential = state.get('emailCredentials');
      if (action.emailID) {
        credential = credential.filter(
          (cred) => cred.get('id') !== action.emailID
        );
      }

      // TODO: Remove all reducers dealing with emailCaseGenerator
      return state
        .set('emailCaseGenerator', fromJS({}))
        .set('emailCredentials', credential)
        .set('isLoadingEmailCaseGenerator', false);
    }

    case actionTypes.SLACK_INTEGRATION_START:
      return state.set('isLoadingSlack', true);

    case actionTypes.SLACK_INTEGRATION_FAIL:
      return state.set('isLoadingSlack', false);

    case actionTypes.SLACK_INTEGRATION_SUCCESS:
      return state.set('isLoadingSlack', false);

    case actionTypes.GET_BARISTABOT_INTEGRATION_START:
      return state.setIn(['baristabot_integrations', 'isLoading'], true);

    case actionTypes.GET_BARISTABOT_INTEGRATION_SUCCESS:
      return state
        .setIn(['baristabot_integrations', action.botType], action.data)
        .setIn(['baristabot_integrations', 'isLoading'], false);

    case actionTypes.GET_BARISTABOT_INTEGRATION_FAIL:
      return state.setIn(['baristabot_integrations', 'isLoading'], false);

    case actionTypes.UPDATE_BARISTABOT_INTEGRATION_SUCCESS:
      return state.setIn(
        ['baristabot_integrations', action.botType],
        action.data
      );

    case actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_FAIL:
      return state.set('isLoadingEmailIntegrationPrimaryCredential', false);

    case actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_START:
      return state.set('isLoadingEmailIntegrationPrimaryCredential', true);

    case actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_LOAD_SUCCESS:
      return state
        .set('isLoadingEmailIntegrationPrimaryCredential', false)
        .set('emailIntegrationPrimaryCredential', fromJS(action.credential));

    case actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_ADD_GENERATOR_SUCCESS: {
      const credential = state.get('emailIntegrationPrimaryCredential');
      return state.set(
        'emailIntegrationPrimaryCredential',
        credential.set('is_for_case', true)
      );
    }

    case actionTypes.EMAIL_INTEGRATION_PRIMARY_CREDENTIAL_REMOVE_GENERATOR_SUCCESS: {
      const credential = state.get('emailIntegrationPrimaryCredential');
      return state.set(
        'emailIntegrationPrimaryCredential',
        credential.set('is_for_case', false)
      );
    }

    case actionTypes.START_GET_EMAIL_CREDENTIALS:
      return state.set('isloadingEmailCredentials', true);

    case actionTypes.ERROR_GET_EMAIL_CREDENTIALS:
      return state.set('isloadingEmailCredentials', false);

    case actionTypes.ERROR_ADD_EMAIL_CREDENTIALS:
      return state.set('isloadingAddEmailCredentials', false);

    case actionTypes.START_ADD_EMAIL_CREDENTIALS:
      return state.set('isloadingAddEmailCredentials', true);

    case actionTypes.SUCCESS_ADD_EMAIL_CREDENTIALS: {
      const currentCredential = state.get('emailCredentials');
      return state
        .set('isloadingAddEmailCredentials', false)
        .set('isloadingEmailCredentials', false)
        .set(
          'emailCredentials',
          currentCredential.push(fromJS(action.credentials))
        );
    }
    case actionTypes.ERROR_UPDATE_EMAIL_CREDENTIALS:
      return state.set('isloadingAddEmailCredentials', false);

    case actionTypes.START_UPDATE_EMAIL_CREDENTIALS:
      return state.set('isloadingAddEmailCredentials', true);

    case actionTypes.SUCCESS_UPDATE_PRIMARY_EMAIL:
      return state.set('isloadingAddEmailCredentials', false);

    case actionTypes.SUCCESS_GET_EMAIL_CREDENTIALS: {
      const newCredentials = [...action.credentials];

      return state
        .set('isloadingEmailCredentials', false)
        .set('emailCredentials', fromJS(newCredentials));
    }
    case actionTypes.ERROR_EMAIL_TEST_CONNECTION:
      return state
        .set('isLoadingEmailTestConnection', false)
        .set('emailTestConnectionSuccess', false)
        .set('testConnectionSent', true)
        .set('emailAccountTestConnection', action.emailAccountType);

    case actionTypes.START_EMAIL_TEST_CONNECTION:
      return state
        .set('isLoadingEmailTestConnection', true)
        .set('emailTestConnectionSuccess', false)
        .set('testConnectionSent', false)
        .set('emailAccountTestConnection', '');

    case actionTypes.RESET_EMAIL_TEST_CONNECTION:
      return state
        .set('isLoadingEmailTestConnection', false)
        .set('emailTestConnectionSuccess', false)
        .set('testConnectionSent', false)
        .set('emailAccountTestConnection', '');

    case actionTypes.SUCCESS_EMAIL_TEST_CONNECTION:
      return state
        .set('isLoadingEmailTestConnection', false)
        .set('emailTestConnectionSuccess', true)
        .set('testConnectionSent', true)
        .set('emailAccountTestConnection', action.emailAccountType);

    case actionTypes.SET_CURRENT_EMAIL_CREDENTIAL:
      return state.setIn(['emailIntegration', 'currentCredential'], {
        ...action.credential,
      });
    case actionTypes.CANCEL_CSV_PARSING_START:
      return state
        .set('isLoadingCVSParsing', true)
        .set('errorAtCSVParsing', false);

    case actionTypes.CANCEL_CSV_PARSING_FAIL:
      return state
        .set('isLoadingCVSParsing', false)
        .set('errorAtCSVParsing', true);

    case actionTypes.CANCEL_CSV_PARSING_SUCCESS:
      return state
        .set('isLoadingCVSParsing', false)
        .set('errorAtCSVParsing', false);

    case actionTypes.ADD_INTEGRATION_BUSINESS_OBJECTS_START: {
      return state.set('isLoading', true).set('isUploading', false);
    }

    case actionTypes.ADD_INTEGRATION_BUSINESS_OBJECTS_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('businessObjects', fromJS(action.businessObjects));
    }

    case actionTypes.START_UPLOAD_CSV: {
      return state.set('isUploading', true);
    }

    case actionTypes.UPLOAD_CSV_SUCCESS: {
      return state.set('isUploading', false);
    }

    case actionTypes.UPLOAD_CSV_FAIL: {
      return state.set('isLoading', false).set('isUploading', false);
    }

    case actionTypes.LOAD_SNOW_WORKFLOW_MAP_START: {
      return state.set('isLoadingWorkflowMap', true);
    }

    case actionTypes.LOAD_SNOW_WORKFLOW_MAP_SUCCESS: {
      return state
        .set('snWorkflowMap', fromJS(action.snWorkflowMap))
        .set('isLoadingWorkflowMap', false);
    }

    case actionTypes.SAVE_SNOW_WORKFLOW_MAP_SUCCESS: {
      let snWorkflowMap = state.get('snWorkflowMap');

      // Updates an existing workflow mapping or pushes a new one
      const foundIndex = snWorkflowMap.findIndex(
        (map) => map.get('id') === action.snWorkflowMapInstance.id
      );
      if (foundIndex >= 0) {
        return state.setIn(
          ['snWorkflowMap', foundIndex],
          fromJS(action.snWorkflowMapInstance)
        );
      } else {
        snWorkflowMap = snWorkflowMap.push(
          fromJS(action.snWorkflowMapInstance)
        );
        return state.set('snWorkflowMap', snWorkflowMap);
      }
    }

    case actionTypes.DELETE_SNOW_WORKFLOW_MAP_SUCCESS: {
      const mapIndex = state
        .get('snWorkflowMap')
        .findIndex(
          (map) => map.get('catalog_category') === action.catalogCategoryId
        );

      return state.deleteIn(['snWorkflowMap', mapIndex]);
    }

    case actionTypes.LOAD_GENERIC_TABLE_SUCCESS: {
      const composedTableName = action.field
        ? `${action.tableName}.${action.field}`
        : action.tableName;

      let tableResults = state.getIn(
        ['genericTableResults', composedTableName],
        fromJS([])
      );
      // Update existing results with what came from action
      tableResults = tableResults.concat(fromJS(action.results));

      // Smart way to get rid of duplicates
      tableResults = tableResults
        .groupBy((m) => m.get('sys_id'))
        .map((x) => x.last())
        .toList();

      return state
        .setIn(['genericTableResults', composedTableName], tableResults)
        .setIn(
          ['genericTableData', composedTableName, 'displayValue'],
          action.displayValue
        )
        .setIn(
          ['genericTableData', composedTableName, 'realValue'],
          action.realValue
        )
        .setIn(['genericTableData', composedTableName, 'isLoading'], false);
    }

    case actionTypes.LOAD_GENERIC_TABLE_START: {
      const composedTableName = action.field
        ? `${action.tableName}.${action.field}`
        : action.tableName;

      return state.setIn(
        ['genericTableData', composedTableName, 'isLoading'],
        true
      );
    }

    case actionTypes.LOAD_GENERIC_TABLE_FAIL: {
      const composedTableName = action.field
        ? `${action.tableName}.${action.field}`
        : action.tableName;

      return state.setIn(
        ['genericTableData', composedTableName, 'isLoading'],
        false
      );
    }

    case actionTypes.LOAD_SNOW_PRODUCTS_SUCCESS: {
      return state.setIn(
        ['snowProducts', 'pagination'],
        fromJS(action.pagination)
      );
    }

    case actionTypes.LOAD_SNOW_CATEGORIES_START: {
      return state.setIn(['isLoadingSnCategories'], true);
    }

    case actionTypes.LOAD_SNOW_CATEGORIES_SUCCESS: {
      let snCategories = state.get('snCategories');
      // Update existing categories with what came from action
      snCategories = snCategories.concat(fromJS(action.categories));

      // Smart way to get rid of duplicates
      snCategories = snCategories
        .groupBy((m) => m.get('sys_id'))
        .map((x) => x.last())
        .toList();

      return state
        .setIn(['snCategories'], snCategories)
        .setIn(['isLoadingSnCategories'], false);
    }

    case actionTypes.LOAD_SNOW_CREDENTIALS_START: {
      return state.setIn(['isLoadingSnowCredentials'], true);
    }

    case actionTypes.LOAD_SNOW_CREDENTIALS_SUCESS: {
      return state
        .setIn(['snowCredentials'], fromJS(action.results))
        .setIn(['isLoadingSnowCredentials'], false);
    }

    case actionTypes.LOAD_SNOW_CREDENTIALS_FAIL: {
      return state.setIn(['isLoadingSnowCredentials'], false);
    }

    case actionTypes.LOAD_SNOW_CONNECTION_STATUS_START: {
      return state.setIn(['isLoadingSnowConnectionStatus'], true);
    }

    case actionTypes.LOAD_SNOW_CONNECTION_STATUS_FAIL: {
      return state.setIn(['isLoadingSnowConnectionStatus'], false);
    }

    case actionTypes.LOAD_SNOW_CONNECTION_STATUS_SUCCESS: {
      return state
        .setIn(['snowConnectionStatus'], fromJS(action.results))
        .setIn(['isLoadingSnowConnectionStatus'], false);
    }

    case actionTypes.LOAD_CSV_IMPORT_HISTORY_START: {
      return state.set('isLoadingImportHistory', true);
    }

    case actionTypes.LOAD_CSV_IMPORT_HISTORY_SUCCESS: {
      return state
        .set('importHistoryResults', fromJS(action.importHistoryResults))
        .set('importHistoryResultsCount', action.importHistoryResultsCount)
        .set('isLoadingImportHistory', false);
    }

    case actionTypes.LOAD_CSV_IMPORT_HISTORY_FAILURE: {
      return state.set('isLoadingImportHistory', false);
    }

    case actionTypes.SET_CSV_SCHEDULE_START: {
      return state.set('isLoadingSchedules', true);
    }

    case actionTypes.SET_CSV_SCHEDULE_ERROR: {
      return state.set('isLoadingSchedules', false);
    }

    case actionTypes.SET_CSV_SCHEDULE_SUCCESS: {
      return state
        .set('schedules', fromJS(action.schedules))
        .set('isLoadingSchedules', false);
    }

    case actionTypes.UPDATE_CSV_SCHEDULE_SUCCESS: {
      const schedules = state.get('schedules');
      const index = schedules.findIndex(
        (schedule) => schedule.get('id') === action.schedule.id
      );
      return state
        .setIn(['schedules', index], fromJS(action.schedule))
        .set('isLoadingSchedules', false);
    }

    case actionTypes.TEST_CSV_SCHEDULE_CONNECTION_START: {
      return state.set('connectionIsLoading', true);
    }

    case actionTypes.TEST_CSV_SCHEDULE_CONNECTION_ERROR: {
      return state
        .set('connectionIsLoading', false)
        .set('connectionSent', true)
        .set('scheduleConnectionSuccess', false);
    }

    case actionTypes.TEST_CSV_SCHEDULE_CONNECTION_SUCCESS: {
      return state
        .set('connectionIsLoading', false)
        .set('connectionSent', true)
        .set('scheduleConnectionSuccess', true);
    }

    case actionTypes.TEST_CSV_SCHEDULE_CONNECTION_RESET: {
      return state.set('connectionSent', false);
    }

    case actionTypes.SEND_INVITES_START: {
      return state.set('isSendingInvites', true);
    }

    case actionTypes.SEND_INVITES_SUCCESS: {
      return state.set('isSendingInvites', false);
    }

    case actionTypes.SEND_INVITES_FAILURE: {
      return state
        .set('invitesError', action.error)
        .set('isSendingInvites', false);
    }

    case actionTypes.OKTA_ACCOUNT_START: {
      return state.set('isLoadingOktaAccount', true);
    }

    case actionTypes.OKTA_ACCOUNT_SUCCESS: {
      return state
        .set('isLoadingOktaAccount', false)
        .set('oktaAccount', fromJS(action.oktaAuth));
    }

    case actionTypes.OKTA_ACCOUNT_FAILURE: {
      return state.set('isLoadingOktaAccount', false);
    }

    case actionTypes.GET_SAML_SP_SUCCESS: {
      return state.set('samlServiceProvider', fromJS(action.serviceProvider));
    }

    case actionTypes.OKTA_ACCOUNT_SAVE_START: {
      return state.set('isLoadingOktaAccount', true);
    }

    case actionTypes.GET_AUTH_BE_START: {
      return state.set('isLoadingAuthBackedns', true);
    }

    case actionTypes.GET_AUTH_BE_SUCCESS: {
      return state
        .set('authBackends', fromJS(action.results))
        .set('isLoadingAuthBackedns', false);
    }

    case actionTypes.GET_AUTH_BE_FAILURE: {
      return state.set('isLoadingAuthBackedns', false);
    }

    case actionTypes.UPDATE_ORDERING_VALUE: {
      const authBackends = state.get('authBackends');
      const index = authBackends.findIndex((ab) => ab.get('id') === action.id);

      return state.setIn(
        ['authBackends', index, 'ordering'],
        action.sortingValue
      );
    }

    case actionTypes.AUTH_BE_UPDATE_SUCCESS: {
      const authBackends = state.get('authBackends');
      const index = authBackends.findIndex((ab) => ab.get('id') === action.id);

      return state.setIn(['authBackends', index], fromJS(action.authBackEnd));
    }

    case actionTypes.AUTH_BE_DELETE_SUCCESS: {
      const authBackends = state.get('authBackends');
      const index = authBackends.findIndex((ab) => ab.get('id') === action.id);

      return state.deleteIn(['authBackends', index]);
    }

    case actionTypes.SET_OKTA_TENANT_START: {
      return state
        .setIn(['oktaTenant', 'isLoading'], true)
        .setIn(['oktaTenant', 'loaded'], false);
    }

    case actionTypes.SET_OKTA_TENANT_FAIL: {
      return state
        .setIn(['oktaTenant', 'isLoading'], false)
        .setIn(['oktaTenant', 'loaded'], true);
    }

    case actionTypes.SET_OKTA_TENANT_SUCCESS: {
      return state
        .setIn(['oktaTenant', 'isLoading'], false)
        .setIn(['oktaTenant', 'loaded'], true)
        .setIn(['oktaTenant', 'tenant'], fromJS(action.data));
    }

    case actionTypes.DELETE_CSV_SCHEDULE_START: {
      return state.set('isLoadingDelete', true);
    }

    case actionTypes.DELETE_CSV_SCHEDULE_SUCCESS: {
      return state.set('isLoadingDelete', false);
    }

    case actionTypes.DELETE_CSV_SCHEDULE_FAILURE: {
      return state.set('isLoadingDelete', false);
    }

    case actionTypes.CSV_INSTANCE_START: {
      const instanceId = action.id;
      return state.setIn(
        ['csv_upload_instances_loading_status', instanceId],
        true
      );
    }

    case actionTypes.CSV_INSTANCE_ERROR: {
      const instanceId = action.id;
      return state.setIn(
        ['csv_upload_instances_loading_status', instanceId],
        false
      );
    }

    case actionTypes.CSV_INSTANCE_SUCCESS: {
      const instanceId = action.id;
      const { instance } = action;
      return state
        .setIn(['csv_upload_instances_loading_status', instanceId], false)
        .setIn(['csv_upload_instances', instanceId], fromJS(instance));
    }

    case actionTypes.INTEGR_TEAMS_FETCH_TOKEN_START: {
      return state.setIn(['msftTeams', 'isLoading'], true);
    }

    case actionTypes.INTEGR_TEAMS_FETCH_TOKEN_SUCCESS: {
      return state
        .setIn(['msftTeams', 'isLoading'], false)
        .setIn(['msftTeams', 'tokenData'], fromJS(action.response));
    }

    case actionTypes.INTEGR_TEAMS_REMOVE_TOKEN_SUCCESS: {
      return state
        .setIn(['msftTeams', 'isLoading'], false)
        .setIn(['msftTeams', 'tokenData'], fromJS(action.response));
    }

    case actionTypes.ELC_RUN_SYNC_START: {
      return state.setIn(['elcSync', 'isSyncLoading'], true);
    }

    case actionTypes.ELC_LOAD_LAST_JOB_SUCCESS:
    case actionTypes.ELC_RUN_SYNC_SUCCESS: {
      return state
        .setIn(['elcSync', 'isSyncLoading'], false)
        .setIn(['elcSync', 'lastJob'], fromJS(action.job));
    }

    case actionTypes.REMOTE_USER_CREATE_FLAG_FAIL: {
      return state.set('isLoadingRemoteUserCreateFlag', false);
    }

    case actionTypes.REMOTE_USER_CREATE_FLAG_START: {
      return state.set('isLoadingRemoteUserCreateFlag', true);
    }

    case actionTypes.REMOTE_USER_CREATE_FLAG_SUCCESS: {
      return state
        .set('isLoadingRemoteUserCreateFlag', false)
        .set('remoteUserCreateFlag', fromJS(action.data));
    }

    case actionTypes.TEST_SHAREPOINT_SETTINGS_START: {
      return state.setIn(['sharepoint', 'settings', 'isLoading'], true);
    }

    case actionTypes.TEST_SHAREPOINT_SETTINGS_SUCCESS: {
      return state
        .setIn(['sharepoint', 'settings', 'isLoading'], false)
        .setIn(
          ['sharepoint', 'settings', 'isConnectionSet'],
          action.results.CONNECTION_IS_SET
        )
        .setIn(
          ['sharepoint', 'settings', 'isConnectionValid'],
          action.results.CONNECTION_IS_VALID
        );
    }
    case actionTypes.TEST_SHAREPOINT_SETTINGS_FAILURE: {
      return state.setIn(['sharepoint', 'settings', 'isLoading'], false);
    }

    default: {
      return state;
    }
  }
};

export default IntegrationReducer;
