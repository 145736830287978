import Immutable from 'immutable';
import { createSelector } from 'reselect';

const ALL = 'ALL';

const products = (state) => state.getIn(['entities', 'products']);

const defaultProps = {
  filterProductFamilies: true,
  sortBy: 'name',
  status: 'ALL',
};

// const props = (state, props = {}) => Object.assign({}, defaultProps, props);
// ^ this can NEVER be an appropiate argument for a selector
// as it creates a new object every single time
// effectly making the selector re-calculate even when there are no changes
const props = (state, props = {}) => props;

// if we want to compose the props object, it should be done inside a selector
// so it returns a different object only when the argument changes
const getProps = createSelector([props], (props) =>
  Object.assign({}, defaultProps, props)
);

const getProductsBy = (products, props) => {
  if (!products) {
    return Immutable.List();
  }

  let families = Immutable.Map();

  return (
    products
      // _index
      .sortBy((p) => p.get('_index'))
      // sort by name
      .sortBy((p) =>
        // IE getProductsBy(state, {sortBy: 'description'} || default value 'name'
        p.get(props.sortBy)
          ? p.get(props.sortBy).toLowerCase()
          : p.get(props.sortBy)
      )
      // filter product families
      .filter((p) => {
        // IE getProductsBy(state, {filterProductFamilies: false} || default value true
        if (!props.filterProductFamilies || !p.get('product_family')) {
          return true;
        }
        if (families.get(p.get('product_family'))) {
          return false;
        } else {
          families = families.set(p.get('product_family'), true);
          return true;
        }
      })
      .filter((p) => {
        // IE getProductsBy(state, {filterProductFamilies: false} || default value 'ALL'
        if (props.status === ALL) {
          return true;
        } else {
          return p.get('status') === props.status;
        }
      })
      .toList()
  );
};

const selector = createSelector([products, getProps], getProductsBy);

export default selector;
