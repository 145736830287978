import React, { PureComponent } from 'react';
import { Button, Table } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { curry, memoize } from 'lodash';
// Molecule
import ServiceDepartmentCreateTeamModal from './ServiceDepartmentCreateTeamModal';
// utils
import injectIntlToController from '../../utils/injectIntlToController';
import dateFormat from '../../utils/dateFormat';
import uiPathGenerator from '../../utils/uiPathGenerator';

class ServiceDetailTeamsTableList extends PureComponent {
  static propTypes = {
    defaultTeamId: PropTypes.number,
    /** Method to delete one team */
    deleteOneTeam: PropTypes.func,
    /** Department ID */
    departmentId: PropTypes.string,
    /** Department name selected */
    departmentSelected: PropTypes.string,
    /** team ID which is deleted */
    loadingDeleteID: PropTypes.number,
    /** Sets this into tenant config */
    setConfigDefaultServiceTeam: PropTypes.func,
    /** Whether this is loading */
    settingConfigDefaultServiceTeam: PropTypes.bool,
    /** Immutable List of the data */
    tableList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    defaultTeamId: null,
    deleteOneTeam: null,
    departmentId: null,
    departmentSelected: null,
    loadingDeleteID: null,
    setConfigDefaultServiceTeam: null,
    settingConfigDefaultServiceTeam: false,
    tableList: List(),
  };

  handleMakeDefault = memoize(
    // e argument is needed to create the proper curried function
    curry((eid, e) => {
      const { setConfigDefaultServiceTeam } = this.props;
      setConfigDefaultServiceTeam(eid);
    })
  );

  render() {
    const {
      defaultTeamId,
      deleteOneTeam,
      departmentId,
      departmentSelected,
      loadingDeleteID,
      setConfigDefaultServiceTeam,
      settingConfigDefaultServiceTeam,
      tableList,
    } = this.props;

    const tableListed = tableList.map((team) => {
      const user = team.get('user');

      const isDefault = team.get('id') === defaultTeamId;
      // checks if this is the default team to add "(default)"
      const teamName = isDefault
        ? `${team.get('name')} (default)`
        : team.get('name');

      const leadName = user
        ? `${user.get('nickname')} ${user.get('last_name')}`
        : '';

      const triggerModal = <Button content='View' />;

      let handleDelete;
      let buttonView;
      if (deleteOneTeam) {
        handleDelete = (perfID) => () => {
          const msg = {
            header: 'Success',
            message: `The Team ${teamName} has been deleted`,
          };
          deleteOneTeam(perfID, msg);
        };

        buttonView = (
          <ServiceDepartmentCreateTeamModal
            departmentId={departmentId}
            departmentSelected={departmentSelected}
            teamSelected={team}
            trigger={triggerModal}
          />
        );
      } else {
        buttonView = (
          <Button
            as={Link}
            content='View'
            to={uiPathGenerator.genPath('admin.serviceTeams.detail.members', {
              teamID: team.get('id'),
            })}
          />
        );
      }

      const deletedLoading = loadingDeleteID === team.get('id');
      const renderHourCreated = true;

      const url = user
        ? uiPathGenerator.genPath('app.directory.detail', {
            userID: user.get('id'),
          })
        : '#';

      return (
        <Table.Row key={team.get('id')}>
          <Table.Cell selectable width={4}>
            {teamName}
          </Table.Cell>

          <Table.Cell selectable width={4}>
            <Link to={url}>{leadName}</Link>
          </Table.Cell>

          <Table.Cell width={3}>
            {dateFormat(team.get('sys_date_created'), renderHourCreated)}
          </Table.Cell>

          <Table.Cell textAlign='right' width={5}>
            <Button.Group basic>
              {buttonView}
              {deleteOneTeam && tableList.size > 1 && (
                <Button
                  content='Delete'
                  disabled={deletedLoading}
                  loading={deletedLoading}
                  onClick={handleDelete(team.get('id'))}
                />
              )}

              {setConfigDefaultServiceTeam &&
              ((defaultTeamId && !isDefault) || !defaultTeamId) ? (
                <Button
                  content='Make Default'
                  loading={settingConfigDefaultServiceTeam}
                  onClick={this.handleMakeDefault(team.get('eid'))}
                />
              ) : null}
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table basic columns={4} compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Service Team'}</Table.HeaderCell>
            <Table.HeaderCell>{'Lead'}</Table.HeaderCell>
            <Table.HeaderCell>{'Created'}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
          {tableListed}
        </Table.Header>
      </Table>
    );
  }
}

export const ServiceDetailTeamsTableListTest = ServiceDetailTeamsTableList;

export default injectIntlToController(ServiceDetailTeamsTableList);
