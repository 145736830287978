import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';
import ImmutableUtils from '../utils/ImmutableUtils';

// This Initial state will change depending on the structure of the actual API
const INITIAL_STATE = fromJS({
  assetItems: [],
  error: null,
  isLoading: false,
  isLoadingAssetStatus: {},
  isSupportModalOpen: false,
  loaded: false,
  pendingOrders: {
    /** @type {string} */
    error: null,

    /** @type {boolean} */
    isLoading: false,

    /** @type {Array.<number>} */
    values: null,
  },
  selectedPendingOrder: {
    /** @type {string} */
    error: null,

    /** @type {number} */
    id: null,

    /** @type {boolean} */
    isLoading: false,

    /** @type {Array.<number>} */
    items: null,
  },

  selectedPendingOrderID: null,

  status: null,
});

const onPendingOrdersSuccess = (state, orders) => {
  const orderIDs = ImmutableUtils.pluck(orders, 'id');

  return state
    .set('pendingOrders', INITIAL_STATE.get('pendingOrders'))
    .setIn(['pendingOrders', 'values'], orderIDs);
};

/**
 * Asset reducer
 * @param state
 * @param action
 * @returns {*}
 */
const assetReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_ASSETS_START: {
      return state.set('loaded', false).set('isLoading', true);
    }

    case actionTypes.LOAD_ASSETS_SUCCESS: {
      return state
        .set('assetItems', fromJS(action.assetItems))
        .set('loaded', true)
        .set('isLoading', false)
        .set('error', null);
    }

    case actionTypes.LOAD_ASSETS_FAIL: {
      return state
        .set('loaded', false)
        .set('isLoading', false)
        .set('error', action.error);
    }

    case actionTypes.REMOVE_ASSET_ITEM_SUCCESS: {
      const currentItem = state.get('assetItems');
      const { arrayID } = action;

      const assetItems = currentItem.filter(
        (item) => arrayID.indexOf(item.get('id')) === -1
      );

      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('assetItems', assetItems);
    }

    case actionTypes.CLEAR_ASSET_ITEM: {
      const assetItems = fromJS([]);

      return state
        .set('loaded', true)
        .set('isLoading', false)
        .set('assetItems', assetItems);
    }

    case actionTypes.LOAD_ONE_ASSET_START: {
      return state.setIn(['isLoadingAssetStatus', action.id], true);
    }

    case actionTypes.LOAD_ONE_ASSET_SUCCESS: {
      let assetItems = state.get('assetItems');
      const index = assetItems.findIndex(
        (ass) => ass.get('id') === Number(action.id)
      );

      let newState = state;
      if (index !== -1) {
        newState = newState.setIn(['assetItems', index], fromJS(action.asset));
      } else {
        assetItems = assetItems.push(fromJS(action.asset));
        newState = newState.setIn(['assetItems'], assetItems);
      }

      return newState.setIn(['isLoadingAssetStatus', action.id], false);
    }

    case actionTypes.LOAD_ONE_ASSET_FAIL: {
      return state.setIn(['isLoadingAssetStatus', action.id], false);
    }

    case actionTypes.UPDATE_ONE_ASSET_SUCCESS: {
      return state
        .set('loaded', true)
        .set('isLoading', false)
        .updateIn(['assetItems'], (assets) =>
          assets.update(
            assets.findIndex((asset) => asset.get('id') === Number(action.id)),
            () => fromJS(action.asset)
          )
        );
    }

    case actionTypes.GET_PENDING_ORDERS_START:
      return state
        .setIn(['pendingOrders', 'error'], null)
        .setIn(['pendingOrders', 'isLoading'], true);

    case actionTypes.GET_PENDING_ORDERS_SUCCESS:
      return onPendingOrdersSuccess(state, fromJS(action.orders));

    case actionTypes.GET_PENDING_ORDERS_FAIL:
      return state
        .set('pendingOrders', INITIAL_STATE.get('pendingOrders'))
        .setIn(['pendingOrders', 'error'], action.error);

    case actionTypes.GET_PENDING_ORDER_ITEMS_START:
      return state
        .set('selectedPendingOrder', INITIAL_STATE.get('selectedPendingOrder'))
        .setIn(['selectedPendingOrder', 'id'], action.orderID)
        .setIn(['selectedPendingOrder', 'isLoading'], true);

    case actionTypes.GET_PENDING_ORDER_ITEMS_SUCCESS:
      return state
        .setIn(['selectedPendingOrder', 'isLoading'], false)
        .setIn(['selectedPendingOrder', 'items'], fromJS(action.cartItems));

    case actionTypes.GET_PENDING_ORDER_ITEMS_FAIL:
      return state
        .setIn(['selectedPendingOrder', 'isLoading'], false)
        .setIn(['selectedPendingOrder', 'error'], action.error);

    case actionTypes.SET_PENDING_REQUEST_ORDER_ID:
      return state.setIn(['selectedPendingOrderID'], action.requestOrderID);

    case actionTypes.SUPPORT_CHANNEL_START:
    case actionTypes.ASSET_START_SUPPORT_MODAL:
      return state.set('isSupportModalOpen', true);

    case actionTypes.CLOSE_SUPPORT_CHANNEL:
      return state.set('isSupportModalOpen', false);

    default: {
      return state;
    }
  }
};

export default assetReducer;
