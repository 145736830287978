const defaultRule = {
  serialize(obj, children) {
    if (obj.object === 'string') {
      return children;
    }
    return null;
  },
};

export default defaultRule;
