import actionTypes from './actionTypes';

const dpcActions = {};

dpcActions.loadDpcStart = () => ({
  type: actionTypes.DPC_LOADING_START,
});

dpcActions.loadDpcError = (error) => ({
  error,
  type: actionTypes.DPC_LOADING_ERROR,
});

dpcActions.loadDpcSuccess = (products, count, next, previous) => ({
  count,
  next,
  previous,
  products,
  type: actionTypes.DPC_LOADING_SUCCESS,
});

dpcActions.loadTopCategoriesStart = () => ({
  type: actionTypes.DPC_TOP_CATEGORIES_LOADING_START,
});

dpcActions.loadTopCategoriesError = () => ({
  type: actionTypes.DPC_TOP_CATEGORIES_LOADING_ERROR,
});

dpcActions.loadTopCategoriesSuccess = (categories) => ({
  categories,
  type: actionTypes.DPC_TOP_CATEGORIES_LOADING_SUCCESS,
});

dpcActions.loadSubCategoriesStart = () => ({
  type: actionTypes.DPC_SUB_CATEGORIES_LOADING_START,
});

dpcActions.loadSubCategoriesError = () => ({
  type: actionTypes.DPC_SUB_CATEGORIES_LOADING_ERROR,
});

dpcActions.loadSubCategoriesSuccess = (subcategories) => ({
  subcategories,
  type: actionTypes.DPC_SUB_CATEGORIES_LOADING_SUCCESS,
});

dpcActions.setCurrentDPCCategory = (currentCategory, isSubCategory) => ({
  currentCategory,
  isSubCategory,
  type: actionTypes.DPC_SET_CATEGORY,
});

dpcActions.isSearchingStart = () => ({
  type: actionTypes.DPC_IS_SEARCHING_START,
});

dpcActions.isSearchingError = () => ({
  type: actionTypes.DPC_IS_SEARCHING_ERROR,
});

dpcActions.isSearchingSuccess = () => ({
  type: actionTypes.DPC_IS_SEARCHING_SUCCESS,
});

dpcActions.setSearchUsersTerm = (searchTerm) => ({
  searchTerm,
  type: actionTypes.DPC_SET_CATALOG_SEARCH_PRODUCTS_TERM,
});

dpcActions.setProductSearch = (products) => ({
  products,
  type: actionTypes.DPC_LOAD_PRODUCTS_SEARCH_SUCCESS,
});

dpcActions.addDpcProductSelect = (productEid) => ({
  productEid,
  type: actionTypes.DPC_SELECTED_ADD_PRODUCT,
});

dpcActions.removeDpcProductSelect = (productEid) => ({
  productEid,
  type: actionTypes.DPC_SELECTED_REMOVE_PRODUCT,
});

dpcActions.includeItemsIHaveIsChecked = () => ({
  type: actionTypes.DPC_INCLUDE_ITEMS_I_HAVE,
});

dpcActions.setOrderByFilter = (orderBy) => ({
  orderBy,
  type: actionTypes.DPC_SET_ORDER_BY,
});

dpcActions.copyToCatalogStart = () => ({
  type: actionTypes.DPC_COPY_TO_CATALOG_START,
});

dpcActions.copyToCatalogError = () => ({
  type: actionTypes.DPC_COPY_TO_CATALOG_ERROR,
});

dpcActions.copyToCatalogSuccess = () => ({
  type: actionTypes.DPC_COPY_TO_CATALOG_SUCCESS,
});

dpcActions.loadSingleProductStart = () => ({
  type: actionTypes.DPC_LOAD_SINGLE_START,
});

dpcActions.loadSingleProductError = () => ({
  type: actionTypes.DPC_LOAD_SINGLE_ERROR,
});

dpcActions.loadSingleProductSuccess = (currentProduct) => ({
  currentProduct,
  type: actionTypes.DPC_LOAD_SINGLE_SUCCESS,
});

dpcActions.removeCopyAttributesProduct = (key) => ({
  key,
  type: actionTypes.REMOVE_COPY_ATTRIBUTES_PRODUCT,
});

dpcActions.setProductFamilyStart = () => ({
  type: actionTypes.DPC_SET_PRODUCT_FAMILY_START,
});

dpcActions.setProductFamilyFail = () => ({
  type: actionTypes.DPC_SET_PRODUCT_FAMILY_FAIL,
});

dpcActions.setProductFamilySuccess = (productFamily) => ({
  productFamily,
  type: actionTypes.DPC_SET_PRODUCT_FAMILY_SUCCESS,
});

dpcActions.setDpcCopyProductId = (productID) => ({
  productID,
  type: actionTypes.DPC_SET_COPY_PRODUCT_ID,
});

dpcActions.setCopyAttributesProduct = (key) => ({
  key,
  type: actionTypes.SET_COPY_ATTRIBUTES_PRODUCT,
});

dpcActions.resetCopyAttributesProduct = () => ({
  type: actionTypes.RESET_COPY_ATTRIBUTES_PRODUCT,
});

dpcActions.clearCopyAttributesProduct = () => ({
  type: actionTypes.DPC_CLEAR_ATTRIBUTES_PRODUCT,
});

dpcActions.removeDpcProduct = (eid) => ({
  eid,
  type: actionTypes.DPC_REMOVE_PRODUCT_EID,
});

export default dpcActions;
