import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'semantic-ui-react';
// Globals
import TypeButton, { TypeDefaults } from '../../../globals/blocks/TypeButton';

// Utils
// import URLUtil    from '../../../utils/URLUtil';

class NextButton01 extends Component {
  static propTypes = {
    ...TypeButton,
  };

  static defaultProps = TypeDefaults;

  generateNextLink = () =>
    // TODO commenting below since it's using URLUtil
    // const step = Number(step);
    // return URLUtil.createUrlReplacingParams({step: step + 1});
    '#';

  render() {
    const { text, type, template } = this.props;
    return (
      <div
        className='block padding'
        data-blocktype={type}
        data-template={template}
      >
        <Button as={Link} to={this.generateNextLink()}>
          {text}
        </Button>
      </div>
    );
  }
}

export default NextButton01;
