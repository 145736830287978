import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessories extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M75.67 57.14l-9.54-23.6h-.07c-3.658-7.558-12.286-11.313-20.31-8.84-1.622-3.317-4.353-5.963-7.72-7.48-3.44-1.4-7.3-1.19-11.45.63h-.1c-1.576.934-2.94 2.187-4 3.68-2.4 3.424-3.134 7.745-2 11.77.02.093.052.183.09.27l3.57 8c.664 1.608.745 3.398.23 5.06-.646 1.457-1.866 2.582-3.37 3.11-.51.196-.878.65-.963 1.19-.086.542.124 1.087.55 1.43.425.346 1.002.436 1.513.24 2.26-.83 4.07-2.56 5-4.78.807-2.413.704-5.038-.29-7.38l-3.51-7.9c-1.83-8.11 4-11.63 4.54-12 3.35-1.45 6.39-1.65 9-.58 2.63 1.207 4.772 3.27 6.08 5.85-7.366 3.87-10.85 12.502-8.23 20.4h-.1l9.54 23.6h.09c3.97 7.92 13.355 11.462 21.568 8.142 8.212-3.32 12.5-12.39 9.852-20.842l.03.03zm-21.58-9c-.495.193-1.045.18-1.53-.033-.487-.214-.868-.612-1.06-1.107l-3.11-7.69c-.268-.663-.162-1.418.278-1.98.44-.565 1.147-.85 1.855-.75.708.098 1.31.567 1.577 1.23l3.11 7.69c.207.495.206 1.053-.003 1.548-.21.495-.608.884-1.107 1.082l-.01.01z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogAccessories;
