import Marks from '../../../Marks';
import Regexes from '../../../Regexes';

const marksRules = {
  serialize(obj, children) {
    if (obj.object !== 'mark' || !children) {
      return '';
    }

    switch (obj.type) {
      case Marks.bold: {
        const finalWord = children;
        if (finalWord) {
          if (Regexes[Marks.bold].test(children)) {
            return `${finalWord}`;
          } else {
            return `**${finalWord}**`;
          }
        }

        return finalWord;
      }
      case Marks.italic:
        return `_${children}_`;
      case Marks.underline:
        return `__${children}__`;
      case Marks.strikethrough:
        return `~~${children}~~`;
      default:
        return '';
    }
  },
};

export default marksRules;
