import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  isLoading: false,
  isLoadingReportSurvey: false,
  surveyTypes: [],
});

const surveysReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_SURVEY_TYPES_FAIL: {
      return state.set('isLoading', false);
    }

    case actionTypes.LOAD_SURVEY_TYPES_START: {
      return state.set('isLoading', true);
    }

    case actionTypes.LOAD_SURVEY_TYPES_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('surveyTypes', fromJS(action.surveyTypes));
    }

    case actionTypes.REPORTS_SURVEY_LOAD_START: {
      return state.set('isLoadingReportSurvey', true);
    }

    case actionTypes.REPORTS_SURVEY_LOAD_FAIL: {
      return state.set('isLoadingReportSurvey', false);
    }

    case actionTypes.REPORTS_SURVEY_LOAD_SUCCESS: {
      return state.set('isLoadingReportSurvey', false);
    }

    default: {
      return state;
    }
  }
};

export default surveysReducer;
