import { createSelector } from 'reselect';

const getSession = (state) => state.getIn(['session']);

const isImpersonating = (session) => {
  if (session) {
    if (
      session.getIn(['impersonation', 'impersonatingUser']) ===
      session.get('currentUser')
    ) {
      return true;
    }
  }

  return false;
};

const selector = createSelector([getSession], isImpersonating);

export default selector;
