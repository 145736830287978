import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { debounce, isNumber } from 'lodash';
import { Field } from 'redux-form/immutable';

// Molecule
import FormInputTextarea from '../../molecules/FormInputTextarea';
import FormInputText from '../../molecules/FormInputText';
// Controller
import TextAreaController from '../../controllers/TextAreaController';
// Globals
import TypeTextArea, {
  TypeDefaults,
} from '../../../globals/blocks/TypeTextArea';

const TIMEOUT = 600;

let DescriptionFAQ01 = class DescriptionFAQ01 extends Component {
  static propTypes = {
    ...TypeTextArea,
    charLength: PropTypes.number,
    emptyState: PropTypes.shape({
      readOnly: PropTypes.bool,
      value: PropTypes.string,
    }),
    forceFocus: PropTypes.bool,

    isProtected: PropTypes.bool,

    textAreaValue: PropTypes.string,
  };

  static defaultProps = {
    ...TypeDefaults,
    charLength: 0,
    emptyState: {
      readOnly: false,
      value: null,
    },
    forceFocus: false,
    isProtected: null,
    textAreaValue: null,
  };

  state = {
    error: '',
  };

  handleUpdateCharLength = (event, newValue) => {
    const { minCharacters, charLimit } = this.props;
    const { error } = this.state;

    const count = newValue.length;

    const limit = charLimit;

    if (isNumber(limit) && count > limit) {
      // Maximum exceeded
      event.preventDefault();
    }

    if (error) {
      // Reset error msg anytime a new character is added
      this.setState({
        error: '',
      });
    }

    if (isNumber(minCharacters) && count < minCharacters) {
      // Check if we have to set an error for the character minimum value
      this.checkMinCharacters();
    }
  };

  /**
   *
   * Always crops text to the limit size
   * Useful when user forces a large input via copy pasta
   */
  textCrop = (value) => {
    const { charLimit } = this.props;

    const limit = charLimit || value.length;
    return value.substr(0, limit);
  };

  checkMinCharacters = debounce(() => {
    const { minCharacters, charLength } = this.props;
    const { error } = this.state;

    if (charLength < minCharacters && !error) {
      const msg = intl.formatMessage(
        {
          id: 'message.characters_minimum',
        },
        {
          number: minCharacters,
        }
      );

      this.setState({
        error: msg,
      });
    }
  }, TIMEOUT);

  render() {
    const {
      charLimit,
      emptyState,
      forceFocus,
      id,
      isProtected,
      labelText,
      placeholder,
      readOnly,
      rows,
      template,
      textAreaValue,
      type,
    } = this.props;
    const { error } = this.state;

    // Basic empty state value
    const emptyStateValue = {
      input: {
        value: emptyState.value,
      },
    };

    // Empty state value when available in a no readOnly state
    const emptyStateValueReadOnly =
      !emptyState.readOnly && emptyState.value
        ? {
            input: {
              value: emptyState.value,
            },
          }
        : null;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {!textAreaValue &&
        readOnly &&
        emptyState.readOnly &&
        emptyState.value ? (
          <Field
            {...emptyStateValue}
            component={FormInputText}
            label={labelText}
            name={`${id.toString()}_empty`}
            readOnly
            transparent
          />
        ) : (
          <Field
            {...emptyStateValueReadOnly}
            autoComplete='off'
            autoCorrect='off'
            charLimit={charLimit}
            component={FormInputTextarea}
            forceFocus={forceFocus}
            formError={error}
            id={id}
            label={labelText}
            name={id.toString()}
            normalize={this.textCrop}
            onChange={this.handleUpdateCharLength}
            placeholder={isProtected ? '' : placeholder}
            readOnly={readOnly || isProtected}
            rows={rows}
          />
        )}
      </div>
    );
  }
};
const TextArea = DescriptionFAQ01;

DescriptionFAQ01 = TextAreaController(DescriptionFAQ01);

export { TextArea };
export default DescriptionFAQ01;
