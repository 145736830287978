const api_espuser_v0_1_users_3_get_contact_for_all_groups = {
  ENABLEMENT: null,
  HR_CONTACT: null,
  HR_RECRUITER: null,
  IT_CONTACT: null,
  MANAGER: 1833,
  MENTOR: null,
  OPERATIONS: null,
};

export default api_espuser_v0_1_users_3_get_contact_for_all_groups;
