import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwareLaptop extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(11 21)'>
          <rect height='26.61' rx='1' width='36' x='18.85' y='5.08' />
          <path d='M73.52 47.29L59.75 34.58V4.02c0-2.21-1.79-4-4-4H18.1c-2.21 0-4 1.79-4 4v30.91l-13.74 12c-.222.19-.35.468-.35.76v4.81c0 .552.448 1 1 1h71.83c.552 0 1-.448 1-1v-4.47c.002-.28-.114-.55-.32-.74zM16.09 4.02c0-1.105.895-2 2-2h37.65c1.105 0 2 .895 2 2v30.11l-41.66.17.01-30.28zm55.8 47.54l-69.58-.19v-3.33l13.64-11.69h42.16L71.9 48.23l-.01 3.33z' />
          <path d='M43.1 45.04H30.58c-.762.088-1.337.733-1.337 1.5s.575 1.412 1.337 1.5H43.1c.762-.088 1.337-.733 1.337-1.5s-.575-1.412-1.337-1.5z' />
        </g>
      </svg>
    );
  }
}

export default CatalogHardwareLaptop;
