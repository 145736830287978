import { EspEntities } from 'esp-globals';
import { first, last } from 'lodash';
import uiPathGenerator from './uiPathGenerator';

/**
 * Return a relative URI
 * @param {string} espEntity - esp entity definition
 * @param {string} espId - entity ID
 * @returns {string} relative URI based on entity and ID
 */
const getPath = (espEntity, espId) => {
  switch (espEntity) {
    case EspEntities.ASSET: {
      return uiPathGenerator.genPath('app.equipment.detail', {
        equipmentId: espId,
      });
    }

    case EspEntities.CATEGORY: {
      // espId may be either "1" (category) or "1.4" (for subcategories)
      let categoryID;
      let subcategoryID;

      if (espId) {
        const catAndSubCat = espId.split('.');
        categoryID = first(catAndSubCat);
        subcategoryID =
          catAndSubCat.length === 2 && last(catAndSubCat)
            ? last(catAndSubCat)
            : null;
      }

      return uiPathGenerator.genPath('app.catalog', {
        categoryID,
        subcategoryID,
      });
    }

    case EspEntities.CHANNEL_DIRECT: {
      return uiPathGenerator.genPath('app.messages.direct.user', {
        userId: espId,
      });
    }

    case EspEntities.CHANNEL_SUPPORT: {
      return uiPathGenerator.genPath('app.messages.scoped.channel', {
        channelId: espId,
      });
    }

    case EspEntities.PRODUCT: {
      return uiPathGenerator.genPath('app.standaloneProduct.detail', {
        catalogId: espId,
      });
    }

    case EspEntities.USER: {
      return uiPathGenerator.genPath('app.directory.detail', {
        userId: espId,
      });
    }

    default: {
      return `${espEntity} ${espId}`;
    }
  }
};

export default getPath;
