import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  existingUser: {
    default: false,
    type: 'bool',
  },

  inputRequired: {
    default: false,
    type: 'bool',
  },

  readOnly: {
    default: false,
    type: 'bool',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'selectDepartment01',
  },
});

// Object Definition used by blobifier
const SelectDepartmentDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Select Department',
  templates: ['selectDepartment01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, SelectDepartmentDefinition, TypeDefaults };
