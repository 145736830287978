import { change, getFormValues } from 'redux-form/immutable';
import _ from 'lodash';
import { fromJS } from 'immutable';

import workflowThunks from '../../actions/workflowThunks';
import faqThunks from '../../actions/faqThunks';
import baristaActions from '../../actions/baristaActions';
import reduxFormActions from '../../actions/reduxFormActions';

import { getMyServiceDepartments } from '../../selectors/getSelectedServiceTeam';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  // Todo - This should be a selector
  const formValues = getFormValues('Form01')(state);
  let selectedFaq = formValues && formValues.get(ownProps.id);
  if (
    (!selectedFaq || !selectedFaq.get('trigger')) &&
    _.hasIn(ownProps, 'query.taskId')
  ) {
    selectedFaq = fromJS({
      trigger: state.getIn([
        'entities',
        'tasks',
        Number(ownProps.query.taskId),
        'title',
      ]),
    });
  }

  return {
    beScratch: state.getIn([
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
    ]),
    isLoadingTrigger: state.getIn(['adminFaqs', 'isLoadingTriggerList']),
    myServiceDeparment: getMyServiceDepartments(state),
    results: state.getIn(['adminFaqs', 'triggerList']),
    searchedTerm: formValues.get('searchFaq'),
    selectedFaq,
    taskSelected: state.getIn([
      'entities',
      'tasks',
      Number(ownProps.query.taskId),
    ]),
  };
};

// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    dispatch(change('Form01', id, data));
  },
  resetBEScratch(data) {
    dispatch(
      workflowThunks.saveToBackEndScratch({
        'scratch.temp_data': data,
      })
    );
  },
  saveBEscratch(data) {
    return dispatch(workflowThunks.updateTempDataBackEndScratch(data));
  },
  searchTrigger(term, kbTitle) {
    dispatch(faqThunks.getFAQListByTrigger(term, kbTitle));
  },
  selectServiceDepartment(departmentID, departmentTypeID) {
    dispatch(
      baristaActions.faqSelectDepartment(departmentID, departmentTypeID)
    );
  },
  setDepartmentIDSelected(selectedFaq) {
    const departmentID = selectedFaq.getIn([
      'service_team',
      'service_department_id',
    ]);
    const departmentTypeID = selectedFaq.getIn([
      'service_department',
      'service_department_type_id',
    ]);
    dispatch(
      baristaActions.faqSelectDepartment(departmentID, departmentTypeID)
    );
  },
  setFaqBEScratch(kbTitle) {
    const forceReBuild = true;
    return dispatch(faqThunks.setFaqBEScratch(forceReBuild, null, kbTitle));
  },
  setSelectedFromFAQ(eid) {
    dispatch(baristaActions.faqSelectFAQtoEdit(eid));
  },
  submitNoMatch(id) {
    dispatch(reduxFormActions.forceResetSyncErrors('Form01'));
    dispatch(
      change(
        'Form01',
        id,
        fromJS({
          skip: 'skip',
        })
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
