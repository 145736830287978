import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { fromJS } from 'immutable';
// Molecules
import ValidatedField from '../../molecules/ValidatedField';
import FormInputText from '../../molecules/FormInputText';
// Controller
import SearchSelectFAQ01Controller from '../../controllers/SearchSelectFAQ01Controller';

import TypeSearchSelectFAQ, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSearchSelectFAQ';
import ImmutablePropTypes from 'react-immutable-proptypes';

class SkipTeachBarista01 extends Component {
  static propTypes = {
    ...TypeSearchSelectFAQ,
    setDepartmentIDSelected: PropTypes.func,
    submitNoMatch: PropTypes.func,
    taskSelected: ImmutablePropTypes.map,
  };

  static defaultProps = {
    ...TypeDefaults,
    setDepartmentIDSelected: _.noop,
    submitNoMatch: _.noop,
    taskSelected: null,
  };

  componentDidMount() {
    const {
      setAlternateSkip,
      setAlternateSubmit,
      setDepartmentIDSelected,
      taskSelected,
    } = this.props;

    setAlternateSkip(this.handleNoMatch);
    setAlternateSubmit(this.handleSubmit);

    if (taskSelected && !taskSelected.isEmpty()) {
      setDepartmentIDSelected(taskSelected);
    }
  }

  skipped = false;

  handleSubmit = (cb) => {
    const obj = {};
    const { id } = this.props;

    obj[id] = {
      skip: 'noskip',
    };

    if (this.skipped) {
      obj[id] = {
        skip: 'skip',
      };
    }

    cb(fromJS(obj));
  };

  handleNoMatch = () => {
    this.skipped = true;
    const { submitNoMatch, id } = this.props;
    submitNoMatch(id);
  };

  render() {
    const { template, type, id } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField component={FormInputText} hidden name={id} />
      </div>
    );
  }
}

export const SkipTeachBarista01Test = SkipTeachBarista01;

export default SearchSelectFAQ01Controller(SkipTeachBarista01);
