const labels = {
  filter: 'Query',
  placeholder: 'Select query or type new one',
  run: 'Run',
  zero_users_message_p1:
    'The filter query you are using does not return any users.',
  zero_users_message_p2: 'No users can be synced',
};

const status = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  SCHEDULED: 'SCHEDULED',
};

const constants = {
  labels,
  status,
};

export { labels, status };

export default constants;
