import buildCompositeController from '../../utils/buildCompositeController';

import appUIActions from '../../actions/appUIActions';
import workflowThunks from '../../actions/workflowThunks';
import casesThunks from '../../actions/casesThunks';

// Selector
import getCurrentUser from '../../selectors/getCurrentUser';

const mapStateToProps = (state) => {
  const workflowModalState = state.getIn(['appUI', 'workflowModal']);

  return {
    currentUser: getCurrentUser(state),
    isOpen: workflowModalState.get('isOpen'),
    requestID: workflowModalState.get('requestID'),
    wfIsLaunching: workflowModalState.get('isLaunching'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeWorkflowModal() {
    dispatch(appUIActions.closeWorkflowModal());
  },

  launchTeachBaristaWF(incident) {
    dispatch(casesThunks.checkCaseAndTeachBarista(incident));
  },

  launchTeachBaristaWFWithKB(KBid, KBtitle) {
    dispatch(casesThunks.checkKBAndTeachBarista(KBid, KBtitle));
  },

  openWorkflowModal(requestID, workflowEID) {
    if (workflowEID && !requestID) {
      dispatch(workflowThunks.createWorkflowRequestID(workflowEID)).catch(
        () => {
          dispatch(appUIActions.closeWorkflowModal());
        }
      ); // Create and launch a WF from a workflow Request ID
    } else {
      dispatch(appUIActions.openWorkflowModal(requestID)); // Launch a WF from an request ID
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
