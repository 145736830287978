import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Form, Input } from 'semantic-ui-react';
// Molecule
import ValidatedField from '../../molecules/ValidatedField';
import ValidatedMultipleEntries from '../../molecules/ValidatedMultipleEntries';
// Controller
import AddEntitySynonymValue01Controller from '../../controllers/AddEntitySynonymValue01Controller';
// Type
import TypeAddEntitySynonymValue, {
  TypeDefaults,
} from '../../../globals/blocks/TypeAddEntitySynonymValue';

class AddEntitySynonymValue01 extends PureComponent {
  static propTypes = {
    ...TypeAddEntitySynonymValue,
    archetypeEID: PropTypes.string,
    entitySynonymList: ImmutablePropTypes.list,
    isLoadingEntitySynonymList: PropTypes.bool,
    loadEntitySynonymValues: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    archetypeEID: '',
    entitySynonymList: Immutable.List(),
    isLoadingEntitySynonymList: false,
    loadEntitySynonymValues: noop,
  };

  componentDidMount() {
    const {
      archetypeEID,
      dontLoadExistingSynonym,
      loadEntitySynonymValues,
    } = this.props;

    if (!dontLoadExistingSynonym) {
      loadEntitySynonymValues(archetypeEID); // Load list of value available for the primary entity of the archetypeEID
    }
  }

  render() {
    const {
      dontLoadExistingSynonym,
      entitySynonymList,
      id,
      isLoadingEntitySynonymList,
      multiSynonym,
      rest,
      template,
      type,
    } = this.props;

    const options = [];
    entitySynonymList.forEach((template) => {
      options.push({
        key: template.get('eid'),
        text: template.get('name'),
        value: template.get('eid'),
      });
    });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {multiSynonym ? (
          <ValidatedMultipleEntries
            {...rest}
            loading={isLoadingEntitySynonymList}
            multiSynonym
            name={id}
            nameField='synonyms'
            placeholder='Add a new synonym'
          />
        ) : (
          <ValidatedField
            label=''
            loading={isLoadingEntitySynonymList}
            name={id}
            onChange={this.handleChange}
            onSearchChange={this.handleSetTerm}
            options={options}
            placeholder='Add a new synonym'
            search
          />
        )}

        {!dontLoadExistingSynonym
          ? entitySynonymList.map((entity) => (
              <Form.Field key={entity.get('eid')}>
                <Input disabled readOnly value={entity.get('label')} />
              </Form.Field>
            ))
          : null}
      </div>
    );
  }
}

export const AddEntitySynonymValue01Test = AddEntitySynonymValue01;

export default AddEntitySynonymValue01Controller(AddEntitySynonymValue01);
