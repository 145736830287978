import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  activeProductGalleryImage: {},
  allSubcategories: [],
  allSubcategoriesLoaded: false,
  allowLeave: false,
  categories: [],
  count: null,
  error: null,
  fulfillmentAnswers: {},
  fulfillmentOptions: {},
  images: {},
  isLoadingCategories: false,
  isLoadingDoneCategories: false,
  isLoadingDoneProductsMap: {},
  isLoadingDoneSubCatsMap: {},
  isLoadingImages: false,
  isLoadingProductStatus: {},
  isLoadingProducts: false,
  isLoadingSubCategories: false,
  isSavingFulfillmentAnswers: {},
  isSearching: false,
  newProductIsLoading: false,
  productFamilies: {},
  searchResultsOrder: [],
  searchTerm: '',
  selectedCategoryId: null,
  selectedProductId: null,
  selectedSubCategoryId: null,
  shopCartOpened: false,
  subCatPagination: {},
  subCategories: {},
  temporaryImages: [],
  temporaryImagesForms: [],
});

/**
 * Catalog reducer
 * @param state
 * @param action
 * @returns {*}
 */
const catalogReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_CATEGORIES_START: {
      return state
        .set('isLoadingCategories', true)
        .set('isLoadingDoneSubCatsMap', fromJS({}))
        .set('subCategories', fromJS({}));
    }

    case actionTypes.LOAD_CATEGORIES_SUCCESS: {
      return state
        .set('isLoadingCategories', false)
        .set('isLoadingDoneCategories', true)
        .set('error', null)
        .set('categories', fromJS(action.categories));
    }

    case actionTypes.LOAD_CATEGORIES_FAIL: {
      return state
        .set('isLoadingCategories', false)
        .set('isLoadingDoneCategories', true)
        .set('error', action.error);
    }

    case actionTypes.LOAD_SUBCATEGORIES_START: {
      return state
        .setIn(['isLoadingDoneSubCatsMap', action.categoryId], false)
        .set('isLoadingSubCategories', true);
    }

    case actionTypes.LOAD_SUBCATEGORIES_SUCCESS: {
      return state
        .set('isLoadingSubCategories', false)
        .setIn(['isLoadingDoneSubCatsMap', action.categoryId], true)
        .set('error', null)
        .setIn(
          ['subCategories', action.categoryId],
          fromJS(action.subcategories)
        );
    }

    case actionTypes.LOAD_ALL_SUBCATEGORIES_START: {
      return state.set('isLoadingSubCategories', true);
    }
    case actionTypes.LOAD_ALL_SUBCATEGORIES_FAIL: {
      return state.set('isLoadingSubCategories', false);
    }
    case actionTypes.LOAD_ALL_SUBCATEGORIES_SUCCESS: {
      const newCats = fromJS(action.subcategories);
      const existingCats = state.get('allSubcategories');

      let updatedCats = existingCats ? existingCats.concat(newCats) : newCats;

      // Smart way to get rid of duplicates
      updatedCats = updatedCats
        .groupBy((m) => m.get('id'))
        .map((x) => x.first())
        .toList();

      return state
        .set('allSubcategoriesLoaded', true)
        .set('allSubcategories', updatedCats)
        .set('isLoadingSubCategories', false)
        .set('subCatPagination', fromJS(action.pagination));
    }

    case actionTypes.LOAD_SUBCATEGORIES_FAIL: {
      return state
        .setIn(['isLoadingDoneSubCatsMap', action.categoryId], true)
        .set('isLoadingSubCategories', false)
        .set('error', action.error);
    }

    case actionTypes.LOAD_PRODUCTS_START: {
      let newState = state.set('isLoadingProducts', true);
      if (action.categoryId) {
        newState = newState.set('selectedSubCategory', action.categoryId);
      }

      return newState;
    }

    case actionTypes.LOAD_PRODUCTS_SUCCESS: {
      let newState = state;
      if (action.categoryPath) {
        newState = newState.setIn(
          [
            'isLoadingDoneProductsMap',
            action.categoryPath.categoryId,
            action.categoryPath.subCategoryId,
          ],
          true
        );
      }
      return newState
        .set('count', action.count)
        .set('isLoadingProducts', false)
        .set('isSearching', false)
        .set('error', null)
        .set('searchResultsOrder', fromJS(action.searchResultsOrder));
    }

    case actionTypes.UPDATE_PRODUCTS_IMAGES_START: {
      return state.set('isLoadingImages', true).set('error', null);
    }

    case actionTypes.UPDATE_PRODUCTS_IMAGES_SUCCESS: {
      return state.set('isLoadingImages', false).set('error', null);
    }

    case actionTypes.UPDATE_PRODUCTS_IMAGES_ERROR: {
      return state
        .set('isLoadingImages', false)
        .set('error', fromJS(action.error));
    }

    case actionTypes.LOAD_ONE_PRODUCT_START: {
      return state
        .setIn(['isLoadingProductStatus', action.productId], true)
        .set('isLoadingProductDetail', true);
    }

    case actionTypes.LOAD_ONE_PRODUCT_SUCCESS: {
      return state
        .setIn(['isLoadingProductStatus', action.productId], false)
        .set('isLoadingProductDetail', false);
    }

    case actionTypes.LOAD_ONE_PRODUCT_FAIL: {
      return state
        .setIn(['isLoadingProductStatus', action.productId], false)
        .set('isLoadingProductDetail', false);
    }

    case actionTypes.LOAD_PRODUCTS_FAIL: {
      return state.set('isLoadingProducts', false).set('error', action.error);
    }

    case actionTypes.CATALOG_BROWSE: {
      return state
        .set('selectedCategoryId', action.categoryId)
        .set('selectedSubCategoryId', action.subCategoryId);
    }

    case actionTypes.SET_CATALOG_SELECTED_CATEGORY: {
      return state.set('selectedCategoryId', action.categoryId);
    }

    case actionTypes.SET_CATALOG_SELECTED_SUBCATEGORY:
      return state.set('selectedSubCategoryId', action.subCategoryId);

    case actionTypes.SET_CATALOG_ACTIVE_RPODUCT_GALLERY_IMAGE: {
      return state.setIn(
        ['activeProductGalleryImage', action.productID],
        action.position
      );
    }

    case actionTypes.SET_CATALOG_SEARCH_PRODUCTS_TERM: {
      return state.set('searchTerm', action.searchTerm);
    }

    case actionTypes.SET_ACTIVE_PRODUCT: {
      return state.set('selectedProductId', action.productID);
    }

    case actionTypes.IS_SEARCHING_START: {
      return state.set('isSearching', true);
    }

    case actionTypes.LOAD_PFAMILY_SUCCESS: {
      return state.setIn(
        ['productFamilies', action.id],
        fromJS(action.productFamily)
      );
    }

    case actionTypes.LOAD_FUL_OPTS_SUCCESS: {
      return state.setIn(
        ['fulfillmentOptions', action.productId],
        fromJS(action.fulfillmentOptions)
      );
    }

    case actionTypes.LOAD_FUL_ANSWERS_SUCCESS: {
      return state.setIn(
        ['fulfillmentAnswers', action.productId],
        fromJS(action.fulfillmentAnswers)
      );
    }

    case actionTypes.CART_OPEN_MODAL: {
      return state.setIn(['shopCartOpened'], true);
    }

    case actionTypes.CART_CLOSE_MODAL: {
      return state.setIn(['shopCartOpened'], false);
    }

    case actionTypes.CREATE_NEW_PRODUCT_START: {
      return state.set('newProductIsLoading', true);
    }

    case actionTypes.CREATE_NEW_PRODUCT_ERROR: {
      return state.set('newProductIsLoading', false);
    }

    case actionTypes.CREATE_NEW_PRODUCT_SUCCESS: {
      return state.set('newProductIsLoading', false);
    }

    case actionTypes.SAVE_TEMPORARY_PRODUCT_IMAGE: {
      const images = state.get('temporaryImages').toJS();
      images.push(action.img);
      return state.set('temporaryImages', fromJS(images));
    }

    case actionTypes.CLEAR_TEMPORARY_PRODUCT_IMAGE: {
      return state.set('temporaryImages', fromJS([]));
    }

    case actionTypes.REPLACE_TEMPORARY_PRODUCT_IMAGE: {
      return state.set('temporaryImages', action.images);
    }

    case actionTypes.DELETE_TEMPORARY_PRODUCT_IMAGE: {
      const images = state
        .get('temporaryImages')
        .filterNot((img) => img.get('id') === action.imageID);
      return state.set('temporaryImages', images);
    }

    case actionTypes.ADMIN_ALLOW_LEAVE: {
      return state.set('allowLeave', action.flag);
    }

    case actionTypes.SET_PRODUCT_IMAGES: {
      const addImages = (state, newImages = []) => {
        let images = state.get('images');
        const newImagesList = fromJS(newImages);
        newImages.forEach((image) => {
          images = images.set(
            image.esp_catalog,
            newImagesList.filter(
              (img) => img.get('esp_catalog') === image.esp_catalog
            )
          );
        });
        return state.set('images', images);
      };
      return addImages(state, action.images);
    }

    case actionTypes.SAVE_FUL_ANSWERS_START: {
      return state.setIn(
        ['isSavingFulfillmentAnswers', action.productId],
        true
      );
    }

    case actionTypes.SAVE_FUL_ANSWERS_SUCCESS: {
      return state.setIn(
        ['isSavingFulfillmentAnswers', action.productId],
        false
      );
    }

    case actionTypes.SAVE_FUL_ANSWERS_FAIL: {
      return state.setIn(
        ['isSavingFulfillmentAnswers', action.productId],
        false
      );
    }
    default: {
      return state;
    }
  }
};

export default catalogReducer;
