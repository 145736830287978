const api_barista_v0_1_kb_articles_get_top_20_conversation_areas = {
  count: 3,
  results: [
    {
      article_record: 'rwerwe',
      title: 'This is TESTKB 1',
      total: 439,
    },
    {
      article_record: 'rwe43rwe',
      title: 'This is TESTKB 2',
      total: 49,
    },
    {
      article_record: 'rw$@#$#!@erwe',
      title: 'This is TESTKB 3',
      total: 39,
    },
    {
      article_record: 'rw%&%erwe',
      title: 'This is TESTKB 4',
      total: 9,
    },
  ],
};

export default api_barista_v0_1_kb_articles_get_top_20_conversation_areas;
