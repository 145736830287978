const BlockBase = {
  id: {
    required: true,
    type: 'string',
  },
  template: {
    required: true,
    type: 'string',
  },
  type: {
    required: true,
    type: 'string',
  },
};

export default BlockBase;
