import { BundleSelectValues } from './BundleSelectOptions';
import CatalogCategoryNames from './CatalogCategoryNames';
import SelectMyGearStepTypes from './SelectMyGearStepTypes';

/**
 * Marks in parenthesis () represent position in spreadsheet
 * https://itautomationsys.sharepoint.com/sites/espressive-team-site/_layouts/15/WopiFrame.aspx?sourcedoc=%7B7a16a65a-a08e-4056-b8ad-3f79a2cc7846%7D&action=default
 */
const Phrases = [
  /** 0 (2) */
  [
    /** (B) */ 'n/a',
    /** (C) */ 'n/a',
    /** (D) */ "Now let's review the following items that will be provided",
    /** (E) */ 'Great news! Please pick one of the items below. Choose wisely!',
    /** (F) */ 'Anything else needed to be productive? Add any of the items below.',
  ],

  /** 1 (3) */
  [
    /** (B) */ 'We are setting up the following device – sweet! Please choose one from the available options below.',
    /** (C) */ 'We are setting up the following device – sweet!',
    /** (D) */ "Now let's review the hardware that will be provided.",
    /** (E) */ 'Great news! Please pick from one of the hardware items below. Choose wisely!',
    /** (F) */ 'Is additional hardware needed to be productive? Pick from any of the items below.',
  ],

  /** 2 (4) */
  [
    /** (B) */ "It's time to set up the software! Make sure to choose one from the available options below.",
    /** (C) */ 'We are going to set up this software!',
    /** (D) */ "Now let's review the software that will be provided.",
    /** (E) */ 'Great news! Please pick from one of the software items below. Choose wisely!',
    /** (F) */ 'Is additional software needed to be productive? Pick from any of the items below.',
  ],

  /** 3 (5) */
  [
    /** (B) */ 'Time to accessorize.  Here is one of the provided accessories. Make sure to choose one from the available options below.',
    /** (C) */ 'Time to accessorize.  Here is one of the provided accessories.',
    /** (D) */ "Now let's review the provided accessories.",
    /** (E) */ 'Great news! Please pick from one of the accessory items below. Choose wisely!',
    /** (F) */ 'Are additional accessories needed to be productive? Pick from any of the items below.',
  ],

  /** 4 (6) */
  [
    /** (B) */ 'Get ready for comfort as we prepare this furniture item! Make sure to choose one from the available options below.',
    /** (C) */ 'Get ready for comfort as we prepare this furniture item!',
    /** (D) */ "Now let's review the comfy furniture.",
    /** (E) */ 'Great news! Please pick from one of the furniture items below. Choose wisely!',
    /** (F) */ 'Is additional furniture required to be productive? Pick from any of the items below.',
  ],

  /** 5 (7) */
  [
    /** (B) */ 'Get connected!   Check out the available communication devices. Make sure to choose one from the available options below.',
    /** (C) */ 'Get connected! Check out the selected communication device that is being prepared.',
    /** (D) */ "Now let's review the communication items that will be provided.",
    /** (E) */ 'Great news! Please pick from one of the communications items below. Choose wisely!',
    /** (F) */ 'Is additional communications gear needed to be productive? Pick from any of the items below.',
  ],

  /** 6 (8) */
  [
    /** (B) */ 'The following item is included. Make sure to choose one from the available options below.',
    /** (C) */ 'The following item will be made ready.',
    /** (D) */ "Now let's review the other items that will be provided.",
    /** (E) */ 'Great news! Please pick from the items below. Choose wisely!',
    /** (F) */ 'Are additional things needed to be productive? Pick from any of the items below.',
  ],

  /** 7 (9) */
  [
    /** (B) */ 'And now a gift from our company store :) Make sure to choose one from the available options below.',
    /** (C) */ 'And now a gift from our company store :)',
    /** (D) */ "Now let's review the company store items that will be provided.",
    /** (E) */ 'Great news! Please pick from one of the items in the company store. Choose wisely!',
    /** (F) */ 'Need swag? Pick from any of the items below.',
  ],

  /** 8 (10) */
  [
    /** (B) */ 'The following service will be provided! Make sure to choose one from the available options below.',
    /** (C) */ 'The following service will be provided!',
    /** (D) */ "Now let's review the services that will be provided.",
    /** (E) */ 'Great news!  Please pick from one of the services below. Choose wisely!',
    /** (F) */ 'Are additional services needed to be productive? Pick from any of the items below.',
  ],

  /** 9 (11) */
  [
    /** (B) */ 'The following office supplies are included. Make sure to choose one from the available options below.',
    /** (C) */ 'The following office supplies are included.',
    /** (D) */ "Now let's review the office supplies that will be provided.",
    /** (E) */ 'Great news! Please pick from one of the office supplies below. Choose wisely!',
    /** (F) */ 'Are additional office supplies needed to be productive? Pick from any of the items below.',
  ],
];

const categoryNameToIndexMapping = {
  // categoryName => index
  // 'missing': 0, //Mixed Categories (Default)

  [CatalogCategoryNames.HARDWARE]: 1,
  [CatalogCategoryNames.SOFTWARE]: 2,
  [CatalogCategoryNames.ACCESSORIES]: 3,
  [CatalogCategoryNames.FURNITURE]: 4,
  [CatalogCategoryNames.COMMUNICATION]: 5,
  [CatalogCategoryNames.OTHER]: 6,
  [CatalogCategoryNames.COMPANY_STORE]: 7,
  [CatalogCategoryNames.SERVICES]: 8,
  [CatalogCategoryNames.OFFICE_SUPPLIES]: 9,
};

const situationMapping = {
  // Product Bundle (Receive All)
  pb_1: 3,

  // Single Product Item
  pb_all: 2,

  // Product Bundle (Choose 1)
  pb_any: 4,

  // identifier => index
  single_pf: 0,
  // Single Product Family
  single_pi: 1, // Product Bundle (Choose Any)
};

/**
 * Gets top level category of a product.
 * @param product An EspProduct
 */
const getProductCategory = (product) => {
  const subcategoryIDs = product.get('category');

  // let's suppose for now it only can be in one subcategory
  const subcategoryID = subcategoryIDs.first();

  const allCategoryIDs = product.get('category_all');

  const categoryID = allCategoryIDs.find(
    (candidateCategoryID) => candidateCategoryID !== subcategoryID
  );

  return categoryID;
};

const getProductStepCategory = (step) => {
  const product = step.get('entity');
  return getProductCategory(product);
};

const getProductFamilyStepCategory = (step) => {
  const representativeProduct = step.get('representativeEntity');
  return getProductCategory(representativeProduct);
};

/**
 * @param step Must be a product or a product family step.
 */
const getSingleStepCategory = (step) => {
  if (step.get('type') === SelectMyGearStepTypes.PRODUCT) {
    return getProductStepCategory(step);
  } else {
    // it has to be a product family
    return getProductFamilyStepCategory(step);
  }
};

const W3BaristaPhrasing = {};

W3BaristaPhrasing.getPhrase = (item, categoryIDToNameMapping) => {
  let categoryID;
  let situationIdentifier;

  if (item.get('type') === SelectMyGearStepTypes.PRODUCT) {
    categoryID = getProductStepCategory(item);
    situationIdentifier = 'single_pi';
  } else if (item.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
    categoryID = getProductFamilyStepCategory(item);
    situationIdentifier = 'single_pf';
  } else {
    // it's a product bundle

    const bundleChildren = item.get('items');
    const firstBundleChild = bundleChildren.first();
    const firstBundleChildCategoryID = getSingleStepCategory(firstBundleChild);

    const areAllProductsSameCategory = bundleChildren.every(
      (bundleChild) =>
        getSingleStepCategory(bundleChild) === firstBundleChildCategoryID
    );

    if (areAllProductsSameCategory) {
      categoryID = firstBundleChildCategoryID;
    }

    if (item.get('selects') === BundleSelectValues.SELECT_ALL) {
      situationIdentifier = 'pb_all';
    } else if (item.get('selects') === BundleSelectValues.SELECT_ANY) {
      situationIdentifier = 'pb_any';
    } else {
      // it's SELECT_ONE type
      situationIdentifier = 'pb_1';
    }
  }

  const categoryName = categoryIDToNameMapping.get(categoryID);

  const categoryIndex = categoryNameToIndexMapping[categoryName] || 0;
  const situationIndex = situationMapping[situationIdentifier] || 0;

  return Phrases[categoryIndex][situationIndex];
};

export default W3BaristaPhrasing;
