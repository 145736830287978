import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { darken } from 'polished';
import _ from 'lodash';
import EspIcon from '../../../../v1/components/atoms/EspIcon';

const rule = (props) => {
  const { isLoading, theme, disabled } = props;

  const focusHoverStyle = {
    backgroundColor: darken(0.08, theme.brandTenant.primaryColor),
    boxShadow: 'none',
    color: darken(0.08, theme.brandTenant.textColorPrimary),
    outlineStyle: 'none',
  };

  const sendingIconKeyframes = {
    '0%, 100%': {
      opacity: 1,
      transform: 'translateX(0)scale(1)',
    },
    '10%': {
      opacity: 1,
      transform: 'translateX(-20%)scale(1)',
    },
    '50%': {
      opacity: 1,
      transform: 'translateX(200%)scale(0)',
    },
    '90%': {
      opacity: 0,
      transform: 'translateX(-50%)scale(0)',
    },
  };

  const sendingButtonKeyframes = {
    '0%, 100%': {
      backgroundColor: theme.brandTenant.primaryColor,
    },
    '50%': {
      backgroundColor: darken(0.12, theme.brandTenant.primaryColor),
    },
  };

  return {
    ':active': {
      backgroundColor: darken(0.16, theme.brandTenant.primaryColor),
    },
    ':focus': focusHoverStyle,
    ':hover': focusHoverStyle,
    '> i.icon': {
      animationDuration: '1500ms',
      animationIterationCount: 1,
      animationName: isLoading ? sendingIconKeyframes : 'none',
      height: '100%',
      lineHeight: 1.5,
      width: '100%',
    },
    animationDuration: '1500ms',
    animationIterationCount: 'infinite',
    animationName: isLoading ? sendingButtonKeyframes : 'none',
    backgroundColor: theme.brandTenant.primaryColor,
    border: 'none',
    borderRadius: '100px',
    color: theme.brandTenant.textColorPrimary,
    cursor: disabled ? 'default' : 'pointer',
    display: 'inline-block',
    height: `calc(${theme.formHeight} - .5em)`,
    margin: '.25em',
    opacity: disabled ? '.7' : 1,
    overflow: 'hidden',
    padding: 0,
    pointerEvents: isLoading || disabled ? 'none' : 'auto',
    position: 'relative',
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'background',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    verticalAlign: 'bottom',
    width: `calc(${theme.formHeight} - .5em)`,
  };
};

class ChatFormSendButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onClick: _.noop,
  };

  render() {
    const { className, disabled, onClick } = this.props;

    return (
      <button
        aria-label='Send'
        className={className}
        data-component='ChatFormSendButton'
        disabled={disabled}
        onClick={onClick}
        type='button'
      >
        <EspIcon fitted name='send' size='large' />
      </button>
    );
  }
}

export default createComponent(rule, ChatFormSendButton, [
  'disabled',
  'isLoading',
  'onClick',
]);
