/**
 * Returns a normalized new line
 * @param {string} text - text to look up for the new line
 * @returns {string} string with new line normalized
 */
const replaceNewLine = (text) => {
  // other way our customers declare new lines and returns. We must replace the char for it version in template literals because '' and "" doesn't accept returns and new lines
  if (text?.match(/(\r\n)/)?.length > 0) {
    return text.replace(/(\r\n( {1}?)?)/gm, `\n\n`);
  } else if (text?.match(/\n( {1}?)/g)) {
    return text.replace(/\n( {1}?)/g, `\n`);
  } else if (text?.match(/\n-+\n/gm)) {
    return text
      .replace(
        /\n-+\n/gm,
        `

    --------------------
`
      )
      .replace(/\n/gm, `\n\n`);
  }

  return text.replace(/\n/gm, `\n\n`);
};

export default replaceNewLine;
