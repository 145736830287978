import React from 'react';
import pt from 'prop-types';
import IntegrationSetupWizardModal from './IntegrationSetupWizardModal';
import IntegrationSetupWizardContext from './IntegrationSetupWizardContext';
import useIntegrationSetupWizard from './useIntegrationSetupWizard';

const propTypes = {
  onClose: pt.func,
  onOpen: pt.func,
  steps: pt.arrayOf(
    pt.shape({
      content: pt.element,
      stepName: pt.string,
    })
  ),
  title: pt.string,
  triggerButtonTitle: pt.string,
};

const IntegrationSetupWizard = ({
  title = '',
  triggerButtonTitle = '',
  steps = [],
  onClose = () => {},
  onOpen = () => {},
}) => {
  const values = useIntegrationSetupWizard(steps);

  const providerValues = {
    ...values,
    onClose,
    onOpen,
    steps,
    title,
    triggerButtonTitle,
  };

  return (
    <IntegrationSetupWizardContext.Provider value={providerValues}>
      <IntegrationSetupWizardModal />
    </IntegrationSetupWizardContext.Provider>
  );
};

IntegrationSetupWizard.propTypes = propTypes;

export default IntegrationSetupWizard;
