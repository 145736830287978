import { useCallback, useEffect, useRef, useState } from 'react';
import fetchLoadTemplateList from '../endpoints/fetchLoadTemplateList';
import { parseTemplateData } from './useCreateTemplate';
import createTemplateAPI, { updateTemplateAPI } from '../endpoints/APITemplate';
import uiPathGenerator from '../../../../../utils/uiPathGenerator';
import browserHistory from '../../../../../utils/browserHistory';
import { fromJS } from 'immutable';

const allowedFields = [
  'activity_type',
  'title',
  'description',
  'enable_deadline',
  'web_link',
  'deadline_hours',
  'deadline_days',
  'category',
  'private_notes',
  'activity_recipient',
  'person_to_contact',
  'type',
];

const useTemplateListSettings = () => {
  const [templates, setTemplates] = useState([]);
  const [nextTemplate, setNextTemplate] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isLoadingCreating, setLoadingCreating] = useState(false);
  const ERROR_STATUS = 400;
  const isFetchingData = useRef(false);

  const initialize = useCallback(async () => {
    isFetchingData.current = true;
    const resetTemplateList = false;
    const response = await fetchLoadTemplateList(
      resetTemplateList,
      templates.length
    );

    if (response?.status !== ERROR_STATUS) {
      setTemplates(response?.body?.results);
      setNextTemplate(response?.body?.next);
    }

    setLoading(false);
    isFetchingData.current = false;
  }, [templates.length]);

  const fetchMore = useCallback(async () => {
    isFetchingData.current = true;
    const resetTemplateList = false;
    const response = await fetchLoadTemplateList(
      resetTemplateList,
      templates.length
    );

    if (response?.status !== ERROR_STATUS) {
      const newTemplateList = templates
        .filter((tmp) => {
          let exist = false;
          response?.body?.results.forEach((act) => {
            if (act.eid === tmp.eid) {
              exist = true;
            }
          });
          return !exist;
        })
        .concat(response?.body?.results);
      setTemplates(newTemplateList);
      setNextTemplate(response?.body?.next);
    }

    setLoading(false);
    isFetchingData.current = false;
  }, [templates]);

  const loadMoreTemplates = () => {
    setLoading(true);
    fetchMore();
  };

  const updateTemplateList = (templateID, newStatus) => {
    const templateFiltered = templates.map((item) => {
      if (item.id === Number(templateID)) {
        item.status = newStatus;
      }
      return item;
    });
    setTemplates(templateFiltered);
  };

  const duplicateTemplate = (newTemplate) => {
    const insertNewTemplate = templates.splice(0, 0, newTemplate);
    setTemplates(insertNewTemplate);
  };

  const createTemplate = async (values) => {
    setLoadingCreating(true);
    const parsedValues = parseTemplateData(values);
    const response = await createTemplateAPI(parsedValues);

    if (response?.status !== ERROR_STATUS) {
      setLoadingCreating(false);
      const url = uiPathGenerator.genPath(
        'admin.adminActivities.offboarding.templates',
        {
          templateID: response?.body?.id,
        }
      );
      browserHistory.push(url);
      setTemplates([response.body, ...templates]);
    }

    setLoadingCreating(false);
  };

  const updateTemplate = async (templateID, values) => {
    setLoadingCreating(true);
    const parsedValues = parseTemplateData(values);
    const response = await updateTemplateAPI(templateID, parsedValues);
    if (response?.status !== ERROR_STATUS) {
      const templatesUpdate = templates.map((template) => {
        if (template.id === response?.body?.id) {
          return response.body;
        } else {
          return template;
        }
      });
      setTemplates(templatesUpdate);
    }

    setLoadingCreating(false);
  };

  const copyTemplate = async (values) => {
    const newValues = fromJS(values).filter((v, k) =>
      allowedFields.find((x) => x === k)
    );
    setLoading(true);
    const parsedValues = parseTemplateData(newValues.toJS());
    const response = await createTemplateAPI(parsedValues);
    if (response?.status !== ERROR_STATUS) {
      const url = uiPathGenerator.genPath(
        'admin.adminActivities.offboarding.templates',
        {
          templateID: response?.body?.id,
        }
      );
      browserHistory.push(url);
      setTemplates([response.body, ...templates]);
    }
    setLoading(false);
    return {};
  };

  useEffect(() => {
    setLoading(true);
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- d
  }, []);

  return {
    copyTemplate,
    createTemplate,
    duplicateTemplate,
    isLoadingCreating,
    isTemplatesLoading: isLoading,
    loadMoreTemplates,
    nextTemplate,
    templates,
    updateTemplate,
    updateTemplateList,
  };
};

export default useTemplateListSettings;
