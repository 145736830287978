import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Grid,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

// Controllers
import AuthController from '../controllers/AuthController';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
import BodyClassName from '../../../globals/BodyClassName';

class AuthPage extends PureComponent {
  static propTypes = {
    authenticateWithOAuth: PropTypes.func.isRequired,
    authenticateWithSAML: PropTypes.func.isRequired,
    error: PropTypes.string,
    isOAuth: PropTypes.bool,
    isSAML: PropTypes.bool,
    queryParams: PropTypes.string,
    tokenRetrievalStarted: PropTypes.bool,
  };

  static defaultProps = {
    error: '',
    isOAuth: false,
    isSAML: false,
    queryParams: '',
    tokenRetrievalStarted: false,
  };

  componentDidMount() {
    const {
      authenticateWithOAuth,
      authenticateWithSAML,
      isOAuth,
      isSAML,
      queryParams,
      tokenRetrievalStarted,
    } = this.props;

    // eslint-disable-next-line no-console -- debugging
    console.warn('AuthPage componentDidMount');
    if (!tokenRetrievalStarted) {
      // makes sure this only happens once
      // Remember that isOAuth or isSAML is not a binary option
      if (isOAuth) {
        authenticateWithOAuth(queryParams);
      } else if (isSAML) {
        authenticateWithSAML();
      }
    }
  }
  render() {
    const { error, isOAuth, isSAML } = this.props;

    return (
      <BodyClassName className='login-page'>
        <Grid centered verticalAlign='middle'>
          <Helmet title='External Login' />

          <Grid.Column>
            <Dimmer.Dimmable as={Segment} textAlign='center'>
              {error ? (
                <>
                  <Message content={error} negative />
                  <Button
                    as={Link}
                    basic
                    content={'Login'}
                    to={uiPathGenerator.genPath('login')}
                  />

                  {window.cordova || window.electron ? (
                    <Button
                      as={Link}
                      basic
                      content={'Change tenant'}
                      to={uiPathGenerator.genPath('noTenant')}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <Loader active inline='centered' />
                  {/* This text shouldn't really matter too much as we're going to immediatly redirect
                   * but I'm adding it anyways for visibility in cordova where whe don't get to see the url
                   */}
                  {isOAuth ? <p>{'Authenticating with OAuth...'}</p> : null}
                  {isSAML ? <p>{'Authenticating with SAML...'}</p> : null}
                </>
              )}
            </Dimmer.Dimmable>
          </Grid.Column>
        </Grid>
      </BodyClassName>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
AuthPage = AuthController(AuthPage);

export default AuthPage;
