import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServices extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M76.65 48.84c-1.912-1.462-4.63-1.186-6.21.63l-7.47 8.45c-.14 4-4 5.6-7.68 6.17h-8.1c-.84 0-1.44-.12-1.44-1s.53-1.1 1.44-1.1h8c2.3 0 5.41-1.81 5.48-4.12.07-2.31-2.9-4.33-5.28-4.33H40.95l-4.25-.09c-5-.27-12 3.22-13.09 4.51L17 66.3l11.15 13.32 3.84-3.91h22.33c3.027.038 5.915-1.265 7.89-3.56l14.86-17c1.61-1.864 1.423-4.676-.42-6.31zM31.22 30.29l.94.3c-.142 1.612-.003 3.236.41 4.8l-1.29.66c-.514.263-.903.72-1.08 1.27-.176.55-.125 1.147.14 1.66l.74 1.44c.263.514.72.903 1.27 1.08.55.176 1.147.125 1.66-.14l1.27-.65c1.11 1.365 2.467 2.508 4 3.37l-.29.76c-.418 1.1.12 2.335 1.21 2.78l1.51.58c.538.216 1.14.207 1.672-.024.53-.23.948-.666 1.158-1.206l.43-1.13c1.77.16 3.556-.02 5.26-.53l.69 1.07c.312.484.803.825 1.365.947.563.12 1.15.015 1.635-.297l1.36-.87c.484-.312.825-.803.947-1.365.12-.563.015-1.15-.297-1.635l-.48-.74c1.12-.975 2.08-2.123 2.84-3.4l1 .31c.55.18 1.147.132 1.66-.13.515-.263.904-.72 1.08-1.27l.5-1.54c.187-.56.138-1.17-.136-1.693-.273-.523-.748-.912-1.314-1.077l-.9-.29c.186-1.713.053-3.445-.39-5.11l1-.52c.514-.263.903-.72 1.08-1.27.176-.55.125-1.147-.14-1.66l-.74-1.44c-.263-.514-.72-.903-1.27-1.08-.55-.176-1.147-.125-1.66.14l-1 .51c-1.022-1.252-2.254-2.318-3.64-3.15l.3-.94c.18-.55.132-1.147-.13-1.66-.263-.515-.72-.904-1.27-1.08l-1.51-.45c-.55-.18-1.147-.132-1.66.13-.515.263-.904.72-1.08 1.27l-.27.84c-1.642-.19-3.304-.09-4.91.3l-.62-1.2c-.263-.514-.72-.903-1.27-1.08-.55-.176-1.147-.125-1.66.14l-1.44.74c-1.027.55-1.438 1.812-.93 2.86l.57 1.1c-1.464 1.138-2.688 2.555-3.6 4.17l-.89-.27c-.55-.18-1.147-.132-1.66.13-.515.263-.904.72-1.08 1.27l-.5 1.54c-.176.55-.126 1.145.14 1.658.263.512.72.898 1.27 1.072zm15-6.22c4.286 0 7.76 3.474 7.76 7.76s-3.474 7.76-7.76 7.76-7.76-3.474-7.76-7.76c-.006-4.27 3.44-7.742 7.71-7.77l.05.01z' />
        </g>
      </svg>
    );
  }
}

export default CatalogServices;
