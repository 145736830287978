import buildCompositeController from '../../../../utils/buildCompositeController';
// Actions
import sessionThunks from '../../../../actions/sessionThunks';
import wfIntlActions from '../../../../actions/wfIntlActions';
import wfIntlThunks from '../../../../actions/wfIntlThunks';

const mapStateToProps = (state) => ({
  isLoadingIntlKeys: state.getIn(['wfIntl', 'editorIntlKeyIsLoading']),
  isSavingTranslations: state.getIn(['wfIntl', 'editorIsSavigTranslations']),
  proposedTranslationKey: state.getIn(['wfIntl', 'editorActiveProposedKey']),
  supportedLanguages: state.getIn(['intl', 'supportedLanguages']),
  translationKey: state.getIn(['wfIntl', 'editorActiveKey']),
  translationKeyResults: state.getIn(['wfIntl', 'editorActiveKeyIntlResults']),
});

const mapDispatchToProps = (dispatch) => ({
  changeTranslation: (lang, str) => {
    dispatch(wfIntlActions.changeTranslation(lang, str));
  },

  loadLocalizationsForKey: (translationKey) => {
    dispatch(wfIntlThunks.loadLocalizationsForKey(translationKey)).then(
      (results = []) => {
        if (!results || !results.length) {
          // if no results then means this term has not been used as translation key
          // so we have to generate a new key for it
          dispatch(wfIntlThunks.generateNewTranslationKey(translationKey));
        }
      }
    );
  },

  loadSupportedLanguages: () => {
    dispatch(sessionThunks.loadSupportedLanguages());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
