import { noop } from 'lodash';
import { UserAgentUtils } from 'esp-globals';

const windowFoolLint = window;
const OK_BUTTON_INDEX_NUMBER = 2;
const CANCEL_BUTTON_INDEX_NUMBER = 1;

const getDefaultOptions = (o = {}, msg = '') => ({
  buttons: o.buttons || ['Cancel', 'OK'],
  callback: o.callback || noop,
  content: o.content || '',
  defaultText: o.defaultText || '',
  title: o.title || msg || '',
});

const BrowserPrompt = {
  alert: function (msg = '', options = void 0) {
    const { callback, buttons, content, title } = getDefaultOptions(options);
    if (window.cordova && options) {
      return navigator.notification.alert(content, callback, title, buttons);
    } else {
      return windowFoolLint.alert(msg);
    }
  },
  confirm: function (msg = '', options = void 0) {
    const { callback, buttons, content, title } = getDefaultOptions(
      options,
      msg
    );
    if (window.cordova && options) {
      return navigator.notification.confirm(content, callback, title, buttons);
    } else {
      return windowFoolLint.confirm(msg);
    }
  },
  prompt: function (msg = '', options = void 0) {
    const {
      callback,
      buttons,
      content,
      title,
      defaultText,
    } = getDefaultOptions(options);
    if (window.cordova && options) {
      return navigator.notification.prompt(
        content,
        callback,
        title,
        buttons,
        defaultText
      );
    }
    if (UserAgentUtils.isElectron()) {
      return (
        window.electronAPI
          .electronPrompt({
            buttons,
            label: content,
            title,
            value: defaultText,
          })
          .then((response) => {
            const cancelButtonWasClicked = response === null;
            return callback({
              buttonIndex: cancelButtonWasClicked
                ? CANCEL_BUTTON_INDEX_NUMBER
                : OK_BUTTON_INDEX_NUMBER,
              input1: response,
            });
          })
          // eslint-disable-next-line no-console -- for debugging purposes
          .catch(console.error)
      );
    } else {
      const response = windowFoolLint.prompt(msg);
      const cancelButtonWasClicked = response === null;
      return callback({
        buttonIndex: cancelButtonWasClicked
          ? CANCEL_BUTTON_INDEX_NUMBER
          : OK_BUTTON_INDEX_NUMBER,
        input1: response,
      });
    }
  },
};

export { OK_BUTTON_INDEX_NUMBER, CANCEL_BUTTON_INDEX_NUMBER };

export default BrowserPrompt;
