import Immutable from 'immutable';
import { buildConditionTest as buildCondition } from './buildFAQFromBEScratch';

/**
 * Rebuild the faq object response
 * @param responsesList
 * @param objectFaq
 * @param imageNewFaq
 * @returns {*}
 */
const rebuildFAQAnswers = (
  responsesList,
  objectFaq,
  imageNewFaq = Immutable.Map()
) => {
  const fileToUpload = [];

  responsesList.forEach((response) => {
    const conditions = buildCondition(response); // Build condition
    const index = objectFaq.answers.findIndex(
      (l) =>
        // Same as in buildRegroupedList
        // We don't want to group together protected and unprotected answer groups
        // even if they share the same condition
        l.condition === conditions &&
        Boolean(response.get('main_answer_is_protected')) ===
          Boolean(l.mainAnswerisProtected)
    ); // Should we create a new condition or add new lines ?
    const order = objectFaq.answers[index]
      ? (objectFaq.answers[index].lines.length + 1) * 100
      : 1; // Reorder by index to respect what the user set

    /**
     * Build answers
     */
    if (response && response.hasIn(['response', 'name'])) {
      // This is a file to upload
      fileToUpload.push({
        conditions,
        file: imageNewFaq.get(response.getIn(['response', 'name'])),
        lineOrder: order,
      });
      const newLine = {
        active: response.get('active', true),
        eid: response.get('eid', null),
        order,
        phrase: '',
        url: '',
      };

      if (index === -1) {
        objectFaq.answers.push({
          active: response.get('main_answer_active', true),
          condition: conditions,
          eid: null,
          lines: [newLine],
          mainAnswerisProtected: response.get(
            'main_answer_is_protected',
            false
          ),
        });
      } else {
        objectFaq.answers[index].lines.push(newLine);
      }
    } else if (response && index > -1) {
      // Lines with this conditions already exist.
      const obj = {
        active: response.get('active', true),
        eid: response.get('eid'),
        order,
        phrase: response.hasIn(['response', 'attachment_eid'])
          ? ''
          : response.get('response'),
        url: response.get('url'),
      };

      if (response.hasIn(['response', 'attachment_eid'])) {
        // This is a file to get from a Chat
        obj.attachment_eid = response.getIn(['response', 'attachment_eid']);
      }

      objectFaq.answers[index].lines.push(obj);
    } else if (response && (response.get('response') || response.get('url'))) {
      // New Conditions with new lines
      const obj = {
        active: response.get('main_answer_active', true),
        condition: conditions,
        eid: response.get('answerEID'),
        lines: [
          {
            active: response.get('active', true),
            eid: response.get('eid'),
            order,
            phrase: response.hasIn(['response', 'attachment_eid'])
              ? ''
              : response.get('response'),
            url: response.get('url'),
          },
        ],
        mainAnswerisProtected: response.get('main_answer_is_protected', false),
      };

      if (response && response.hasIn(['response', 'attachment_eid'])) {
        // This is a file to get from a Chat
        obj.lines[0].attachment_eid = response.getIn([
          'response',
          'attachment_eid',
        ]);
      }

      objectFaq.answers.push(obj);
    }
  });

  objectFaq.answers.forEach((ans) => {
    // this was just temporal so we should delete
    delete ans.mainAnswerisProtected;
  });

  return {
    fileToUpload,
    newObj: objectFaq,
  };
};

export default rebuildFAQAnswers;
