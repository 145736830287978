const getSearchParamsFromQuery = (queryString) => {
  const urlSearchParams = new URLSearchParams(queryString);
  const searchParams = {};

  urlSearchParams.entries(([key, val]) => (searchParams[key] = val));

  return searchParams;
};

export default getSearchParamsFromQuery;
