import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Global
import ChannelTypes from '../../globals/ChannelTypes';

// Molecule
import FormInputTextarea from '../molecules/FormInputTextarea';

// Controllers
import SendChatMessageController from '../controllers/SendChatMessageController';

class SendChatMessage extends PureComponent {
  static propTypes = {
    currentUser: ImmutablePropTypes.map.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    // wsStatus           : ImmutablePropTypes.map,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    loadChannelForUserId: PropTypes.func.isRequired,

    loadMessages: PropTypes.func.isRequired,

    loaded: PropTypes.bool,
    placeholder: PropTypes.string,
    selectedChannel: ImmutablePropTypes.map,
    updateMessageChat: PropTypes.func.isRequired,
    usersList: ImmutablePropTypes.map,
    withUserId: PropTypes.number,
  };

  static defaultProps = {
    isLoading: false,
    label: '',
    loaded: false,
    placeholder: '',
    selectedChannel: Immutable.Map(),
    usersList: Immutable.Map(),
    withUserId: null,
  };

  /**
   * Life cycle hooks
   */
  componentDidMount() {
    const { withUserId, loadChannelForUserId } = this.props;
    // case where the entry point is a user id
    if (withUserId) {
      loadChannelForUserId(withUserId);
    }
  }

  /**
   * Loads messages only if there's a channel and is not loading or loaded
   */
  componentDidUpdate() {
    const { selectedChannel, isLoading, loaded, loadMessages } = this.props;
    if (
      selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL &&
      !isLoading &&
      !loaded
    ) {
      loadMessages(selectedChannel);
    }
  }

  /**
   * Input callback handlers
   */
  handleChange = (e) => {
    const { selectedChannel, updateMessageChat } = this.props;
    const newSelectedChannel = selectedChannel.get('id');
    updateMessageChat(e.target.value, newSelectedChannel);
  };

  /**
   * Feature only for direct messages
   * Return the user that is not the current user
   * =
   */
  getOtherParticipantInDC = () => {
    const { selectedChannel, currentUser, usersList } = this.props;
    const currentUserId = currentUser.get('id');

    if (
      selectedChannel &&
      selectedChannel.get('type') === ChannelTypes.DIRECT_CHANNEL
    ) {
      const otherGuyId = selectedChannel
        .get('participants')
        .find((id) => id !== currentUserId);

      return usersList.get(otherGuyId);
    }
    return null;
  };

  render() {
    const {
      id,
      // intl,
      label,
      // loaded,
      placeholder,
      // wsStatus,
    } = this.props;
    // Todo - need to manage WS connection issue
    // let channelTitle;
    // if (loaded && this.getOtherParticipantInDC()) {
    //   channelTitle = this.getOtherParticipantInDC().get('full_name');
    // }

    // let messageText;
    // let messageType = 'default';
    // //If WS is not opened, report what's going on
    // if (!wsStatus.get('open')) {
    //   if (wsStatus.get('times_refused')) {
    //     messageType = 'error';
    //     messageText = intl.formatMessage(
    //       {id: 'message.n_attempted_to_reconnect'},
    //       {number: wsStatus.get('times_refused')}
    //     );
    //     //console.warn(messageText);
    //   }
    //   else if (wsStatus.get('connecting')) {
    //     messageType = 'warning';
    //     messageText = intl.formatMessage(
    //       {id: 'message.trying_to_connect_to_stream'},
    //     );
    //   }
    //   else if (wsStatus.get('errored'))  {
    //     messageType = 'error';
    //     messageText = intl.formatMessage(
    //       {id: 'message.error_connecting_to_stream'},
    //     );
    //     //throw new Error(messageText);
    //   }
    //   else {
    //     messageType = 'error';
    //     messageText = intl.formatMessage(
    //       {id: 'message.could_not_connect_to_stream'},
    //     );
    //   }
    // }

    return (
      <FormInputTextarea
        autoComplete='off'
        autoCorrect='off'
        // eslint-disable-next-line jsx-a11y/no-autofocus -- feature long waiting autofocus
        autoFocus
        input={{
          name: 'sendChatMessage',
        }}
        label={label}
        name={id}
        onChange={this.handleChange}
        placeholder={placeholder}
      />
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SendChatMessage = SendChatMessageController(SendChatMessage);

export default SendChatMessage;
