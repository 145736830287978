import { SESSION_KEYS } from '../../constants';

const STORAGE_PREFIX = 'ESPRESSIVE';

//
// Namespaces are stored as JSON strings, hence can contain any number
// of properties of any serializable data type.
//
// The purpose of namespaces is to add semantics to the way we store
// discrete -but related- data in local storage as well as to allow any
// piece of code in our apps to easily consume such data.
//
// NOTE: Remember that loccal storage has its limits in terms of the data size.
//        Please use namespaces with caution and limit the nested
//        properties to 1 level as much as possible.
const STORAGE_NAMESPACES = {
  SESSION: 'SESSION',
};

const localStorageConfig = {
  SESSION_KEYS,
  STORAGE_NAMESPACES,
  STORAGE_PREFIX,
};

export default localStorageConfig;
