import React, { useCallback, useEffect, useRef, useState } from 'react';
import pt from 'prop-types';
import { Select } from 'semantic-ui-react';
import { debounce, isEmpty } from 'lodash';
import useGetUsers from '../hooks/useGetUsers';

const propTypes = {
  label: pt.string,
  name: pt.string,
  onChange: pt.func,
};

const FunctionalFormSelectUserInput = ({ name, label, onChange }) => {
  const { users, loadUsers } = useGetUsers();
  const prevSearchTerm = useRef('');
  const initialUsers = useRef([]);

  useEffect(() => {
    if (isEmpty(users) && isEmpty(initialUsers.current)) {
      loadUsers({
        query: {},
      }).then((result) => {
        initialUsers.current = result;
      });
    }
  }, [loadUsers, users]);

  const handleSearch = debounce((e, { searchQuery: searchTerm }) => {
    if (prevSearchTerm.current !== searchTerm) {
      loadUsers({
        query: {
          searchTerm,
        },
      });
      prevSearchTerm.current = searchTerm;
    }
  }, 300);
  const [usrSelected, setUsrSelected] = useState('');

  const handleChange = useCallback((e, { value }) => {
    setUsrSelected(value);
  }, []);

  useEffect(() => {
    onChange(name, usrSelected);
  }, [name, onChange, usrSelected]);
  const options = users.length !== initialUsers.length ? users : initialUsers;
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        name={name}
        onChange={handleChange}
        onSearchChange={handleSearch}
        options={options}
        placeholder='Choose an option'
        search
        selection
        value={usrSelected}
      />
    </>
  );
};

FunctionalFormSelectUserInput.propTypes = propTypes;

export default FunctionalFormSelectUserInput;
