import buildCompositeController from '../../utils/buildCompositeController';
// utisl
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
// Selections
import getCurrentUser from '../../selectors/getCurrentUser';
// Actions
import casesThunks from '../../actions/casesThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Globals
import BrowserPrompt from '../../globals/BrowserPrompt';
// package
import { intl } from 'esp-util-intl';

const mapStateToProps = (state) => {
  const isSubscriberLoading = state.getIn(
    ['cases', 'subscribersSelect', 'isLoading'],
    false
  );
  const currentUser = getCurrentUser(state);
  const userId = currentUser && currentUser.get('id');

  return {
    isSubscriberLoading,
    userId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  unsubscribeUser: (userId) => {
    const { caseTaskId } = ownProps;
    const unsubscribe = BrowserPrompt.confirm(
      intl.formatMessage({
        id: 'message.unsubscribe_confirmation',
      }),
      {
        title: intl.formatMessage({
          id: 'label.barista_navigation_alert',
        }),
      }
    );

    if (unsubscribe) {
      dispatch(casesThunks.unsubscribeUser(userId, caseTaskId))
        .then(() => {
          dispatch(
            toastNotificationsActions.success({
              message: 'You are succesfully unsubscribed from the request',
            })
          );

          browserHistory.replace(uiPathGenerator.genPath('app.toDo'));
        })
        .catch((error) => {
          dispatch(
            toastNotificationsActions.error({
              message: `Something went wrong: ${error}`,
            })
          );
        });
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
