import { isEmpty } from 'lodash';
import moment from 'moment';
import LocaleSingleton from './LocaleSingleton';

/**
 * Returns a localized momentJS instance, defaults to en-US
 * @param timestamp  {timestamp} String representing a timestamp
 * @returns {*} A localized moment instance
 */
const localeMoment = (timestamp = null) => {
  // Retrieve locale setttings, if exists
  const locale = LocaleSingleton.get();
  const instance = isEmpty(timestamp) ? moment() : moment(timestamp);
  instance.locale(locale);
  return instance;
};

export default localeMoment;
