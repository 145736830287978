import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareVpn extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M33.4 61.61h9.31v-3h3.14v-3.4h21V52h-2.51v-3h-3.1v3h-3.15v-3H55v3h-2.9v-3.1H49V52h-3.15v-3.11h-3.09v-3.2h-9.33v3.06h-3.18v9.82h3.15v3.04zm.27-9.64h3.1v3.09h-3.1v-3.09zm-4.09 17.07v.02-.02z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.66c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.538 15 25.334 15 23.85c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareVpn;
