import { useCallback, useEffect, useState } from 'react';

import { patchTenantConfig } from '../../globals/commonAPICalls';
import { useTenantHook } from '../../customHooks';

const CHATV1 = 'chatv1';
const useUpdateChatExperience = () => {
  const tenant = useTenantHook();
  const { tenantInfo } = tenant;

  const [chatExperience, setChatExperience] = useState(CHATV1);
  const [isChatV2Enabled, setIsChatV2Enabled] = useState(false);

  useEffect(() => {
    setChatExperience(
      tenantInfo?.ui_config?.default_chat_experience?.version || CHATV1
    );
    setIsChatV2Enabled(tenantInfo?.ui_config?.helpSettings?.chatv2 === true);
  }, [tenantInfo?.ui_config?.default_chat_experience, tenantInfo]);

  const handleToggleHomefeedSorting = useCallback(
    (_, eventData) => {
      const data = {
        ...tenantInfo,
        ui_config: {
          ...tenantInfo?.ui_config,
          default_chat_experience: {
            version: eventData.value,
          },
        },
      };
      if (tenantInfo?.id) {
        patchTenantConfig(tenantInfo.id, data)
          .then(() => {
            setChatExperience(eventData.value);
            window.location.reload();
          })
          .catch((error) => {
            console.error('Error updating chat experience', error); // eslint-disable-line no-console -- debugging
          });
      } else {
        console.error('Tenant ID not found'); // eslint-disable-line no-console -- debugging
      }
    },
    [tenantInfo]
  );
  return { chatExperience, handleToggleHomefeedSorting, isChatV2Enabled };
};

export default useUpdateChatExperience;
