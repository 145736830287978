import buildCompositeController from '../../../../../utils/buildCompositeController';
import integrationThunks from '../../../../../actions/integrationThunks';
import inboxesThunks from '../../../../../actions/inboxesThunks';
import toastNotificationsActions from '../../../../../actions/toastNotificationsActions';

const mapStateToProps = (state) => {
  const emailCredentials = state.getIn(['integration', 'emailCredentials']);
  // this is not necessarily the primary credential, let's remember we are supporting more than one type of email service
  const currentEmailCredential = state.getIn([
    'integration',
    'emailIntegration',
    'currentCredential',
  ]);
  const integration = state.get('integration');
  const emailTestConnectionSuccess = integration.get(
    'emailTestConnectionSuccess'
  );
  const emailAccountTestConnection = integration.get(
    'emailAccountTestConnection'
  );
  const isloadingAddEmailCredentials = integration.get(
    'isloadingAddEmailCredentials'
  );
  const isLoadingEmailTestConnection = integration.get(
    'isLoadingEmailTestConnection'
  );
  const testConnectionSent = integration.get('testConnectionSent');

  // About default value to show on primary email credential
  // the first record that's created should be the default. If someone sets up Gmail, that should be the default.
  // Except if something is set as primary credential, in that case that will be always the default credential to show
  const primaryCredential = emailCredentials
    ?.toJS()
    .filter((credential) => credential.primary)[0];
  const sortedCredentials = emailCredentials
    ?.toJS()
    .sort((c1, c2) => c1.id - c2.id);
  const firstEmailCredential = primaryCredential || sortedCredentials[0];
  return {
    currentEmailCredential,
    emailAccountTestConnection,
    emailCredentials,
    emailTestConnectionSuccess,
    firstEmailCredential,
    isLoadingEmailTestConnection,
    isloadingAddEmailCredentials,
    testConnectionSent,
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  getCredentials() {
    dispatch(inboxesThunks.fetchCredentials());
  },
  getPrimaryEmailCredentials() {
    dispatch(inboxesThunks.fetchPrimaryCredential());
  },
  saveEmailCredentials(values) {
    dispatch(integrationThunks.addEmailCredentials(values))
      .then(({ body }) => {
        dispatch(
          toastNotificationsActions.success({
            message: 'Barista Inbox Account has been added.',
            title: 'Success',
          })
        );
      })
      .catch(({ body }) => {
        if (Array.isArray(body.errors[0].message)) {
          let [message] = body.errors[0].message;

          message = !message
            ? body.errors[0].message.split('["').join('').split('"]').join('')
            : message;
          dispatch(
            toastNotificationsActions.error({
              message: message || 'An error occurred',
              title: 'Error',
            })
          );
        } else {
          dispatch(
            toastNotificationsActions.error({
              message: body.errors[0].message,
              title: 'Error',
            })
          );
        }
      });
  },
  setCurrentCredentialOnState(fields, currentCredentialByType) {
    dispatch(
      integrationThunks.setCurrentCredential(fields, currentCredentialByType)
    );
  },
  testEmailConnection({ currentCredential, emailAccountType }) {
    const emailData = {
      email_address: currentCredential.email_address,
      id: currentCredential.id,
    };
    // emailData = SavedEmailData emailAccountType is primary
    dispatch(
      integrationThunks.emailTestConnection(emailData, emailAccountType)
    );
  },
  updateEmailCredentials(modifiedValues, currentValues) {
    const newCredentials = {
      id: currentValues.id,
      ...modifiedValues,
    };
    dispatch(integrationThunks.updateEmailCredentials(newCredentials))
      .then(({ body }) => {
        dispatch(
          toastNotificationsActions.success({
            message: 'Barista Inbox Account has been updated.',
            title: 'Success',
          })
        );
      })
      .catch(({ body }) => {
        let [message] = body.errors[0].message;

        message = !message
          ? body.errors[0].message.split('["').join('').split('"]').join('')
          : message;
        dispatch(
          toastNotificationsActions.error({
            message: message || 'An error occurred',
            title: 'Error',
          })
        );
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
