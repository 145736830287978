const CASE_ACTIONS = {
  APPROVE: 'APPROVE',
  ASSIGN: 'ASSING',
  ASSING_TO_ME: 'ASSING_TO_ME',
  DISCARD: 'DISCARD',
  REJECT: 'REJECT',
  RESOLVE: 'RESOLVE',
  RE_OPEN: 'RE_OPEN',
  TEACH_BARISTA: 'TEACH_BARISTA',
  VIEW: 'VIEW',
};

const APPROVAL_MANAGER_VALUE = -1;
const EXTERNAL_APPROVAL_VALUE = 'external';

const CASE_USER_PICKER_TYPES = {
  AGENT: 'agent',
  FROM: 'from',
};

const CASE_TYPE = {
  APPROVAL_MANAGER: 'approval_manager',
  APPROVAL_TEAM: 'approval_team',
  AUTOMATION: 'automation',
  CASE: 'case',
  CHANGE_REQUEST: 'change_request',
  DEFLECT_REQUEST: 'deflect_request',
  EXTERNAL_APPROVALS: 'external_approvals',
  INCIDENT: 'incident',
  SERVICE_REQUEST: 'esp_service_request',
  SNOW_SERVICE_REQUEST: 'service_request',
  TASK: 'task',
};

const CASE_BUTTONS = {
  APPROVE: {
    action: CASE_ACTIONS.APPROVE,
    icon: 'checkmark',
    label: 'label.approve',
  },
  ASSIGN: {
    action: CASE_ACTIONS.ASSIGN,
    icon: 'user',
    label: 'label.assign',
  },
  ASSING_TO_ME: {
    action: CASE_ACTIONS.ASSING_TO_ME,
    icon: 'user',
    label: 'label.assign_to_me',
  },
  CANCEL: {
    // this is used only in case info bottom button
    action: null,
    icon: null,
    label: 'label.cancel',
  },
  CREATE: {
    // this is used only in case info bottom button
    action: null,
    icon: null,
    label: 'label.create_case',
  },
  CREATE_SUBTASK: {
    // this is used only in case info bottom button
    action: null,
    icon: null,
    label: 'label.create_subtask',
  },
  DISCARD: {
    action: CASE_ACTIONS.DISCARD,
    icon: 'trash',
    label: 'label.discard',
  },
  REJECT: {
    action: CASE_ACTIONS.REJECT,
    icon: 'delete',
    label: 'label.reject',
  },
  RESOLVE: {
    action: CASE_ACTIONS.RESOLVE,
    icon: 'checkmark',
    label: 'label.resolve',
  },
  RE_OPEN: {
    action: CASE_ACTIONS.RE_OPEN,
    icon: 'undo',
    label: 'label.re_open',
  },
  TEACH_BARISTA: {
    action: CASE_ACTIONS.TEACH_BARISTA,
    icon: null,
    label: 'label.teach_barista',
  },
  UPDATE: {
    // this is used only in case info bottom button
    action: null,
    icon: null,
    label: 'label.update',
  },
  VIEW: {
    action: CASE_ACTIONS.VIEW,
    icon: 'eye',
    label: 'label.view',
  },
};

export {
  CASE_BUTTONS,
  CASE_ACTIONS,
  CASE_USER_PICKER_TYPES,
  CASE_TYPE,
  APPROVAL_MANAGER_VALUE,
  EXTERNAL_APPROVAL_VALUE,
};
