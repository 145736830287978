const api_barista_v0_1_kb_support_123456789_annotation = {
  count: 2,
  results: [
    {
      active: true,
      annotate: '',
      eid_intent: 'a7ac28fa-4898-441c-b92e-9a374f3ccd9a',
      eid_kb: 'cb22ac20-6586-418f-9fe8-96432bde9e67',
      intent: {
        eid: 'a7ac28fa-4898-441c-b92e-9a374f3ccd9a',
        name: 'IT Need to create or change MFA account with App archetype',
      },
      kb: {
        annotate: '',
        article_id: 'f77d34b1474321009db4b5b08b9a71c5',
        boost: 1,
        category: '',
        eid: 'cb22ac20-6586-418f-9fe8-96432bde9e67',
        es_score_norm: 0,
        kb_entities:
          'windows device,some_software,ethernet port,windows,windows device,case,windows device,macos,ethernet port,pto,some_device,mac,pc,cable,monitor,pc,ethernet port',
        kb_number: 'KB0000023',
        opening:
          'Enable/Disable Wireless Laptop Connections \n Dell Quickset Utility On a Dell laptop running the latest versions of Quickset and the Intel drivers (downloadable from support.dell.com), perform the following steps: \n',
        origin: 'servicenow',
        rank: null,
        section_eid: '3e1c6969-d099-42be-ae39-0774f2ca959b',
        section_title: 'Enable/Disable Wireless Laptop Connections',
        sys_id: 'f77d34b1474321009db4b5b08b9a71c5',
        title: 'Enable/Disable Wireless Laptop Connections',
        url:
          'https://dev108621.service-now.com//kb_view.do?sysparm_article=KB0000023',
      },
      rank: null,
    },
    {
      active: true,
      annotate: '',
      eid_intent: '0c50346c-fae4-4773-bc9a-05c529666d83',
      eid_kb: 'cb22ac20-6586-418f-9fe8-96432bde9e67',
      intent: {
        eid: '0c50346c-fae4-4773-bc9a-05c529666d83',
        name: 'I am unable to edit my goals in Workday',
      },
      kb: {
        annotate: '',
        article_id: 'f77d34b1474321009db4b5b08b9a71c5',
        boost: 1,
        category: '',
        eid: 'cb22ac20-6586-418f-9fe8-96432bde9e67',
        es_score_norm: 0,
        kb_entities:
          'windows device,some_software,ethernet port,windows,windows device,case,windows device,macos,ethernet port,pto,some_device,mac,pc,cable,monitor,pc,ethernet port',
        kb_number: 'KB0000023',
        opening:
          'Enable/Disable Wireless Laptop Connections \n Dell Quickset Utility On a Dell laptop running the latest versions of Quickset and the Intel drivers (downloadable from support.dell.com), perform the following steps: \n',
        origin: 'servicenow',
        rank: null,
        section_eid: '3e1c6969-d099-42be-ae39-0774f2ca959b',
        section_title: 'Enable/Disable Wireless Laptop Connections',
        sys_id: 'f77d34b1474321009db4b5b08b9a71c5',
        title: 'Enable/Disable Wireless Laptop Connections',
        url:
          'https://dev108621.service-now.com//kb_view.do?sysparm_article=KB0000023',
      },
      rank: null,
    },
  ],
};

export default api_barista_v0_1_kb_support_123456789_annotation;
