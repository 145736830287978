import Nodes from './editor/Nodes';
import Regexes from './editor/Regexes';

const getMetadataContentType = (text) => {
  if (Regexes[Nodes.embedVideo].test(text) && text.startsWith('http')) {
    return Nodes.embedVideo;
  }
  if (Regexes[Nodes.nativeVideo].test(text)) {
    return Nodes.nativeVideo;
  }

  if (Regexes[Nodes.markdownImage].test(text)) {
    // Text formatted as a markdownImage should be treated as an imageLink, MD format to images is
    // ![NAME_OF_THE_IMAGE](IMAGE_URL.JPG/GIF/PNG/etc, ?Alt text) ?Alt Text is optional
    return Nodes.imageLink;
  } else if (
    !Regexes[Nodes.link].test(text) &&
    Regexes[Nodes.image].test(text)
  ) {
    // This is checking the url of the image is not at the start of the string.
    // It means the url is part of a much larger text and it's not intended to
    // render an image.
    if (text.search(/https:|http:/gi) !== 0) {
      return null;
    }

    // text is an image but is not formatted as a markdown link.
    // Text formatted as a MD link  << [IMAGE_NAME](IMAGEURL.JPG/GIF/PNG/etc)>> must be treated as links no images.
    return Nodes.imageLink;
  }

  return null;
};

export default getMetadataContentType;
