import actionTypes from './actionTypes';

const appVersionActions = {};

appVersionActions.systemVersionFail = (error) => ({
  error,
  type: actionTypes.SYSTEM_VERSION_FAIL,
});

appVersionActions.systemVersionStart = () => ({
  type: actionTypes.SYSTEM_VERSION_START,
});

appVersionActions.systemVersionSuccess = (
  systemId,
  isEncodeForced = false,
  environment
) => ({
  environment,
  isEncodeForced,
  systemId,
  type: actionTypes.SYSTEM_VERSION_SUCCESS,
});

appVersionActions.clientVersionId = (
  clientId,
  isEncodeForced = false,
  environment
) => ({
  clientId,
  environment,
  isEncodeForced,
  type: actionTypes.CLIENT_VERSION,
});

appVersionActions.setNativeMobileVersion = (version) => ({
  type: actionTypes.NATIVE_MOBILE_VERSION,
  version,
});

appVersionActions.setNativeDesktopVersion = (version) => ({
  type: actionTypes.NATIVE_DESKTOP_VERSION,
  version,
});

export default appVersionActions;
