import { messageEval } from '../utils';

const supportFeedbackEval = (msg) =>
  Boolean(msg?.metadata?.widget) &&
  Boolean(msg?.metadata?.widget?.widget === 'Support Feedback');

const supportFeedback = {
  evaluate: (msg) => messageEval(msg) && supportFeedbackEval(msg),
};

const supportFeedbackEvals = {
  supportFeedback: supportFeedbackEval,
};

export { supportFeedbackEvals };
export default supportFeedback;
