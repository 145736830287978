import { fromJS } from 'immutable';
import { has } from 'lodash';

// Actions
import sessionActions from '../actions/sessionActions';
import actionTypes from '../actions/actionTypes';

import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';

const INITIAL_STATE = fromJS({
  accessToken: null,
  auth_error_msg: '',
  currentUser: null,
  error: null,
  externalLoginChecked: false,
  externalLoginRedirectUri: '',
  externalLogoutHtmlDoc: '',
  impersonation: {
    impersonatingUser: null,
    impersonatingUserEid: null,
    originalUser: null,
    original_delegate_of: null,
  },
  isLoading: false,
  isLoadingUserImage: false,
  previousUrl: null,
  sessionIndex: null,
  tokenCreation: null,
  tokenRetrievalStarted: false,
  userAccessTokens: {},
});

// TODO Warning! this is not a pure function as it's not side effects free, I'm leaving it and going to address this in a separate issue
const getDefaultState = () => {
  let defaultState = INITIAL_STATE;

  const sessionExists = persistentStorage.has(PersistentStorageKeys.SESSION);
  if (sessionExists) {
    const sessionFromStorage = persistentStorage.get(
      PersistentStorageKeys.SESSION
    );
    defaultState = INITIAL_STATE.merge(fromJS(sessionFromStorage));
  } else {
    persistentStorage.remove(PersistentStorageKeys.SESSION);
  }

  return defaultState;
};

/**
 * Just manage the 'session' part of the state.
 * It's important to keep reducers as pure functions
 * http://redux.js.org/docs/introduction/ThreePrinciples.html#changes-are-made-with-pure-functions
 */
const sessionReducer = (state, action = {}) => {
  if (!state) {
    state = getDefaultState();
  }

  if (!action.type) {
    return INITIAL_STATE; // return the initial one, not the one from getDefaultState
  }

  switch (action.type) {
    case sessionActions.CLEAR_SESSION: // resets the reducer
      return INITIAL_STATE;

    case actionTypes.APP_BOOTSTRAP_SUCCESS:
      if (has(action, ['me', 'id'])) {
        return state.set('isLoading', false).set('currentUser', action.me.id);
      }
      return state;
    case actionTypes.USER_LOGIN_START:
      return state
        .set('externalLogoutHtmlDoc', '')
        .set('isLoading', true)
        .set('error', null);

    case actionTypes.USER_UPLOAD_IMG_START:
      return state.set('isLoadingUserImage', true).set('error', null);

    case actionTypes.USER_UPLOAD_IMG_SUCCESS:
      return state.set('isLoadingUserImage', false).set('error', null);

    case actionTypes.USER_UPLOAD_IMG_FAIL:
      return state.set('isLoadingUserImage', false).set('error', action.error);

    case actionTypes.USER_LOGIN_SUCCESS:
      return state
        .set('isLoading', false)
        .set('tokenCreation', action.timeStamp)
        .set('previousUrl', null)
        .set('currentUser', action?.currentUser?.id);

    case actionTypes.DELETE_CURRENT_USER:
      return state.set('currentUser', null);

    case actionTypes.UPDATE_USER_ENTITY_SUCCESS:
      return state.set('isLoading', false).set('error', null);

    case actionTypes.USER_LOGIN_FAILURE:
      return state.set('isLoading', false).set('error', fromJS(action.error));

    case actionTypes.USER_AUTH_FAILURE:
      return state.set('auth_error_msg', fromJS(action.errorMsg));

    case actionTypes.USER_SET_PREVIOUSURL:
      return state.set('previousUrl', action.previousUrl);

    case actionTypes.SET_TOKEN:
      return state.set('accessToken', action.accessToken);

    case actionTypes.DELETE_TOKEN:
      return state.set('accessToken', null);

    case actionTypes.UPDATE_SESSION_SUCCESS:
      return state.set('isLoading', false).set('error', null);

    case actionTypes.EXTERNAL_LOGIN_CHECKED:
      return state
        .set('externalLoginRedirectUri', action.externalLoginRedirectUri)
        .set('externalLoginChecked', true);

    case actionTypes.CLEAR_TENANT:
      return state
        .set('externalLoginRedirectUri', '')
        .set('externalLogoutHtmlDoc', '')
        .set('externalLoginChecked', false);

    case actionTypes.IMPERSONATING_SET:
      return state
        .setIn(['impersonation', 'impersonatingUser'], action.userId)
        .setIn(['impersonation', 'impersonatingUserEid'], action.eid);

    case actionTypes.IMPERSONATING_START:
      return state
        .setIn(['impersonation', 'impersonatingUser'], null)
        .setIn(['impersonation', 'impersonatingUserEid'], null);

    case actionTypes.IMPERSONATING_SAVE_ORIGINAL_USER:
      return state
        .setIn(['impersonation', 'originalUser'], action.userId)
        .setIn(['impersonation', 'original_delegate_of'], action.delegators);

    case actionTypes.ADMIN_SET_USER_ACCESS_TOKENS_START:
      return state
        .setIn(['userAccessTokens', action.userName], fromJS({}))
        .setIn(['userAccessTokens', action.userName, 'isLoading'], true);

    case actionTypes.ADMIN_SET_USER_ACCESS_TOKENS_SUCCESS:
      return state
        .setIn(['userAccessTokens', action.userName], fromJS(action.response))
        .setIn(['userAccessTokens', action.userName, 'isLoading'], false);

    case actionTypes.ADMIN_SET_USER_ACCESS_TOKENS_ERROR:
      return state
        .setIn(
          ['userAccessTokens', action.userName, 'errors'],
          fromJS(action.errors)
        )
        .setIn(['userAccessTokens', action.userName, 'isLoading'], false);

    case actionTypes.LOGOUT_REGISTER_EXTERNAL_HTML:
      return state.set('externalLogoutHtmlDoc', action.htmlDoc);

    case actionTypes.SET_SESSION_INDEX:
      return state.set('sessionIndex', action.sessionIndex);

    case actionTypes.SSO_TOKEN_RETRIEVAL_START:
      return state.set('tokenRetrievalStarted', true);

    case actionTypes.SSO_TOKEN_RETRIEVAL_END:
      return state.set('tokenRetrievalStarted', false);

    default:
      return state;
  }
};

export { INITIAL_STATE };

export default sessionReducer;
