import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { curry, memoize, range } from 'lodash';
import { Button } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';

const tabShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
});

class SliderControls extends Component {
  static propTypes = {
    activeSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    navType: PropTypes.oneOf(['default', 'numbers', 'tabs']),
    onSetActive: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
    tabs: PropTypes.arrayOf(tabShape),
  };

  static defaultProps = {
    navType: 'default',
    tabs: null,
  };

  handleClick = memoize(
    curry((clickedActive, e) => {
      e.preventDefault();

      const { onSetActive } = this.props;

      onSetActive(clickedActive);
    })
  );

  render() {
    const { activeSlide, size, navType, tabs } = this.props;

    const pages = range(size);

    return (
      <div
        data-component='SliderControls'
        data-type={navType}
        style={{ textAlign: 'center' }}
      >
        <Button.Group
          basic
          className='borderless'
          compact
          fluid={Boolean(tabs)}
          icon
        >
          {size > 1 &&
            pages.map((slide, i) => (
              <Button
                alt=''
                as='div'
                className={activeSlide === i ? 'active' : void 0}
                key={slide}
                onClick={this.handleClick(i)}
              >
                {navType === 'tabs'
                  ? intl.formatMessage({
                      id: tabs[i].title,
                    })
                  : null}
                {navType === 'numbers' ? i + 1 : null}
              </Button>
            ))}
        </Button.Group>
      </div>
    );
  }
}

export default SliderControls;
