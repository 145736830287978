import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  errors: [],
});

const updateErrors = (state, id, newErrors) => {
  let currentErrors = state.get('errors');

  if (currentErrors.some((currentError) => currentError.get('id') === id)) {
    // Find if this id already existing
    currentErrors = currentErrors.filter(
      (currentError) => currentError.get('id') !== id
    ); // Delete all of them if exists
  }

  if (newErrors) {
    // Concat the currentErrors with the newErrors
    currentErrors = currentErrors.concat(fromJS(newErrors));
  }

  return currentErrors;
};

const errorsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SET_ERRORS: {
      const newErrors = action.errors;

      const [{ id }] = newErrors;
      const currentErrors = updateErrors(state, id, newErrors);

      return state.set('errors', currentErrors);
    }

    case actionTypes.RESET_ERRORS: {
      const id = action.errorId;
      const currentErrors = updateErrors(state, id);

      return state.set('errors', currentErrors);
    }

    case actionTypes.FORCE_RESET_ERRORS: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default errorsReducer;
