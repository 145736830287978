import { createSelector } from 'reselect';
import BlockTypes from '../globals/BlockTypes';

const blocks = (state) => state.getIn(['workflowState', 'blob', 'blocks']);

const getLoginBlock = (blocks) => {
  if (blocks) {
    return blocks.find((b) => b.get('type') === BlockTypes.BLOCK_LOGIN);
  }
  return null;
};

const selector = createSelector([blocks], getLoginBlock);

export default selector;
