import APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const fetchTenantImages = () => {
  return APIcall.get({
    token: true,
    url: endpointGenerator.genPath('espImage.tenantImages'),
  });
};

export default fetchTenantImages;
