import { change, formValueSelector } from 'redux-form/immutable'; // ES6

import buildCompositeController from '../../utils/buildCompositeController';

import workflowThunks from '../../actions/workflowThunks';
import sessionThunks from '../../actions/sessionThunks';

const mapStateToProps = (state, ownProps) => ({
  formValue: formValueSelector('Form01')(state, ownProps.id),
});

const mapDispatchToProps = (dispatch) => ({
  loadUserProfile(id, network) {
    dispatch(sessionThunks.whoami()).then((user) => {
      // Get Social Coordinates array
      const socialCoordinate = user.social_coordinates;

      let newUsername = '';

      // Set the username found from this specific network
      if (socialCoordinate) {
        socialCoordinate.forEach((net) => {
          if (net.network && net.network === network && net.username) {
            newUsername = net.username;
          }
        });
      }

      dispatch(change('Form01', `${id}[0].username`, newUsername));
    });
  },

  updateRequestedForSocialCoordinates(formValues) {
    const socialCoordinatesChanges = {
      social_coordinates: formValues.toJS(),
    };
    dispatch(workflowThunks.updateRequestedForUser(socialCoordinatesChanges));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
