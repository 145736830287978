import { isFeatureFlag } from 'esp-ui-flag';
import baristaThunks from '../../../actions/baristaThunks';
import sessionActions from '../../../actions/sessionActions';
import userEntitiesThunks from '../../../actions/userEntitiesThunks';
import wsActions from '../../../actions/wsActions';
import groupPermissions from '../../../globals/groupPermissions';
import getCurrentUser from '../../../selectors/getCurrentUser';
import buildCompositeController from '../../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    agentName: currentUser?.get('full_name'),
    isAgent: Boolean(
      currentUser?.get('groups').find((role) => groupPermissions.AGENT === role)
    ),
    isBaristaImpersonatorEnabled: isFeatureFlag('baristaImpersonator', state),
    isImpersonating: Boolean(
      state.getIn(['session', 'impersonation', 'impersonatingUser'])
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  endImpersonation: () => {
    dispatch(wsActions.disconnect());
    dispatch(sessionActions.impersonatingSet(null, null));
    dispatch(baristaThunks.loadSupportChannel());
    dispatch(wsActions.connect());
  },
  startImpersonation: (userId, userEid) => {
    dispatch(wsActions.disconnect());
    dispatch(sessionActions.impersonatingSet(userId, userEid));
    dispatch(wsActions.connect());
    dispatch(userEntitiesThunks.addUserEntity(userId));
    dispatch(baristaThunks.loadSupportChannel());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
