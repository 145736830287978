import _APIcall from '../../../v1/utils/APIcall';
import endpointGenerator from '../../../v1/utils/endpointGenerator';

/**
 *
 * @param {{userID:string,data:object}} object userid value to get users's settings and data contains the settings
 * @returns {Promise}  returns api promise result
 */
const postUserSettings = ({ data, userID }) =>
  _APIcall.post({
    data: {
      user_settings: {
        ...data,
      },
    },
    token: true,
    url: endpointGenerator.genPath('espUser.users.instance.userSettings', {
      userID,
    }),
  });

export default postUserSettings;
