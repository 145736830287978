// Utils
import buildCompositeController from '../../../../v1/utils/buildCompositeController';

// Actions
import appUIActions from '../../../../v1/actions/appUIActions';
// Actions
import baristaActions from '../../../../v1/actions/baristaActions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  launchWorkflowRequestModal: (workflowRequestId) => {
    // close the barista modal and session
    dispatch(baristaActions.closeSupportChannel());
    // and just launch workflow, chop chop!
    dispatch(appUIActions.openWorkflowModal(workflowRequestId));
  },
});
export default buildCompositeController(mapStateToProps, mapDispatchToProps);
