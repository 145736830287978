const PREVIEW_TEXT = 'VIEW';
const MAX_ACTION_TEXT_LENGTH = 20;
const ACTION_TYPES = {
  INTENT: 'INTENT',
  REPORT_RESPONSE_ONLY: 'RESPONSE_ONLY',
  URL: 'URL',
};

const actionConstants = {
  ACTION_TYPES,
  MAX_ACTION_TEXT_LENGTH,
  PREVIEW_TEXT,
};

const ANNOUNCEMENT_AUDIENCE_TYPES = {
  DIRECT_TO_USER: {
    description: 'label.direct_to_user_description',
    text: 'label.direct_to_user',
    value: 'direct_to_user',
  },
  SLACK: {
    description: 'label.slack_audience_description',
    text: 'label.slack_audience',
    value: 'slack',
  },
};

const ANNOUNCEMENT_TYPES = {
  GENERAL: {
    text: 'label.general',
    value: 'GENERAL',
  },
  SURVEY: {
    text: 'label.survey',
    value: 'SURVEY',
  },
};

const ANNOUNCEMENT_OPTIONS = {
  default: ANNOUNCEMENT_TYPES.GENERAL.value,
  values: [
    {
      key: ANNOUNCEMENT_TYPES.GENERAL.value,
      text: ANNOUNCEMENT_TYPES.GENERAL.text,
      value: ANNOUNCEMENT_TYPES.GENERAL.value,
    },
    {
      key: ANNOUNCEMENT_TYPES.SURVEY.value,
      text: ANNOUNCEMENT_TYPES.SURVEY.text,
      value: ANNOUNCEMENT_TYPES.SURVEY.value,
    },
  ],
};

export {
  ACTION_TYPES,
  ANNOUNCEMENT_AUDIENCE_TYPES,
  ANNOUNCEMENT_OPTIONS,
  ANNOUNCEMENT_TYPES,
  MAX_ACTION_TEXT_LENGTH,
  PREVIEW_TEXT,
};

export default actionConstants;
