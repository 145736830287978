import React from 'react';
import FilterBase from './FilterBase';
import { FILTER_STATE_SHAPE } from './__globals';

const propTypes = {
  // The state of the Filter component from the useFilterState hook
  state: FILTER_STATE_SHAPE,
};

// NOTE: We will see this pattern frequently so it probably makes sense
// for us to figure out how to abstract this boilerplate out for not
// rendering the component if no state hook is defined.
const Filter = ({ state, ...rest }) => {
  return state ? (
    <FilterBase state={state} {...rest} />
  ) : (
    <div className='ui mini negative message'>
      <div className='header'>Missing State Hook</div>
      <p>
        The Filter component needs a useFilterState hook in order to function
        correctly.
      </p>
    </div>
  );
};

Filter.propTypes = propTypes;

export default Filter;
