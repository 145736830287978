const _SortFieldNames = {
  NAME: 'name',
  NEGATIVE_NAME: '-name',
  NEGATIVE_SYS_DATE_CREATED: '-sys_date_created',
  SYS_DATE_CREATED: 'sys_date_created',
};

const SortProductState = (intl) => [
  {
    text: intl.formatMessage({
      id: 'label.date_added_newest',
    }),
    value: _SortFieldNames.NEGATIVE_SYS_DATE_CREATED,
  },
  {
    text: intl.formatMessage({
      id: 'label.date_added_oldest',
    }),
    value: _SortFieldNames.SYS_DATE_CREATED,
  },
  {
    text: intl.formatMessage({
      id: 'label.product_A_Z',
    }),
    value: _SortFieldNames.NAME,
  },
  {
    text: intl.formatMessage({
      id: 'label.product_Z_A',
    }),
    value: _SortFieldNames.NEGATIVE_NAME,
  },
];

export const SortFieldNames = _SortFieldNames;

export default SortProductState;
