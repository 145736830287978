import actionTypes from './actionTypes';

const appUIActions = {};

appUIActions.closeMoreMenu = () => ({
  type: actionTypes.APP_UI_CLOSE_MORE_MENU,
});

appUIActions.toggleMoreMenu = () => ({
  type: actionTypes.APP_UI_TOGGLE_MORE_MENU,
});

appUIActions.openWorkflowModal = (requestID) => ({
  requestID,
  type: actionTypes.APP_UI_OPEN_WORKFLOW_MODAL,
});

appUIActions.closeWorkflowModal = () => ({
  type: actionTypes.APP_UI_CLOSE_WORKFLOW_MODAL,
});

appUIActions.openDelegateCardSelector = (cardId, businessObjOwner) => ({
  businessObjOwner,
  cardId,
  type: actionTypes.APP_UI_OPEN_CARD_DELEGATE,
});

appUIActions.closeDelegateCardSelector = () => ({
  type: actionTypes.APP_UI_CLOSE_CARD_DELEGATE,
});

appUIActions.openDelegateCaseSelector = (taskId) => ({
  taskId,
  type: actionTypes.APP_UI_OPEN_CASE_DELEGATE,
});

appUIActions.closeDelegateCaseSelector = () => ({
  type: actionTypes.APP_UI_CLOSE_CASE_DELEGATE,
});

appUIActions.launchWorkflowStart = () => ({
  type: actionTypes.APP_UI_LAUNCH_WF_START,
});

/**
 * Set the flag isEmployeeWFOpen to true
 * @returns {{type: string}}
 */
appUIActions.openWorkflowExistingEmployee = () => ({
  type: actionTypes.APP_UI_OPEN_EE_WF,
});

appUIActions.closeFeedbackModal = () => ({
  type: actionTypes.APP_UI_CLOSE_FEEDBACK_MODAL,
});

appUIActions.openFeedbackModal = () => ({
  type: actionTypes.APP_UI_OPEN_FEEDBACK_MODAL,
});
export default appUIActions;
