import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Divider } from 'semantic-ui-react';

import TypeLoc, {
  TypeDefaults,
} from '../../../globals/blocks/TypeLocationDeptRolePicker';
// Molecule
import LocationDeptRolePicker from '../../molecules/LocationDeptRolePicker';
// Controller
import LocationDeptRolePicker01Controller from '../../controllers/LocationDeptRolePicker01Controller';

class LocationDeptRolePicker01 extends Component {
  static propTypes = {
    ...TypeLoc,
    change: PropTypes.func,
    disableRemoteAndNewHires: PropTypes.bool, // in order to prevent DEV-7125
    formValues: ImmutablePropTypes.map,
    indexResponse: PropTypes.number,
    noBlock: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    change: noop,
    disableRemoteAndNewHires: false,
    formValues: Immutable.Map(),
    indexResponse: 0,
    isNewResponse: false,
    noBlock: false,
    onChange: noop,
  };

  changeField = (value, type) => {
    const { change, id, indexResponse, onChange } = this.props;

    change(`${id}.${indexResponse}.locationdeptrole.${type}`, value);
    onChange(type, value);
  };

  render() {
    const {
      disableRemoteAndNewHires,
      formValues,
      id,
      indexResponse,
      noBlock,
      pickComputer,
      pickDept,
      pickLocation,
      pickMobile,
      pickRole,
      template,
      type,
    } = this.props;

    const name = `${id}.${indexResponse}.locationdeptrole`;

    return (
      <div
        className={noBlock ? '' : 'block'}
        data-blocktype={type}
        data-template={template}
      >
        {noBlock ? <Divider hidden /> : null}
        <LocationDeptRolePicker
          changeField={this.changeField}
          disableRemoteAndNewHires={disableRemoteAndNewHires}
          formValues={formValues}
          name={name} // we do not pas to get the root path passed by default in ase of empty location
          noRootPreselect
          pickComputer={pickComputer}
          pickDept={pickDept}
          pickLocation={pickLocation}
          pickMobile={pickMobile}
          pickRole={pickRole}
        />
      </div>
    );
  }
}

export const LocationDeptRolePicker01Test = LocationDeptRolePicker01;

export default LocationDeptRolePicker01Controller(LocationDeptRolePicker01);
