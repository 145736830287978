import buildCompositeController from '../../utils/buildCompositeController';

import workflowBrowserPageThunks from '../../../../../admin/js/v1/actions/workflowBrowserPageThunks';

const mapStateToProps = (state) => ({
  workflowFormError: state.getIn(['editor', 'workflows', 'formError']),
  workflowFormState: state.getIn(['form', 'WorkflowForm']),
});

const mapDispatchToProps = (dispatch) => ({
  createNewWorkFlow: (data, cb) => {
    dispatch(workflowBrowserPageThunks.createNewWorkflow(data, cb));
  },

  editWorkFlow: (data, existingWorkflow, cb) => {
    dispatch(
      workflowBrowserPageThunks.editWorkFlow(data, existingWorkflow, cb)
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
