import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox, Form, Header } from 'semantic-ui-react';

import { Formik } from 'formik';
import { noop } from 'lodash';

class AddTestEmailAddress extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    isTestEmailEnabled: PropTypes.bool,
    setTestEmail: PropTypes.func,
    setTestEmailEnabled: PropTypes.func,
    testEmail: PropTypes.string,
  };

  static defaultProps = {
    isLoading: false,
    isTestEmailEnabled: false,
    setTestEmail: noop,
    setTestEmailEnabled: noop,
    testEmail: '',
  };

  handleToggle = () => {
    const { setTestEmailEnabled, isTestEmailEnabled } = this.props;
    setTestEmailEnabled(!isTestEmailEnabled);
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  handleFormSubmit = ({ email }) => {
    const { setTestEmail } = this.props;
    setTestEmail(email);
  };

  renderForm = ({
    handleReset,
    handleSubmit,
    isValid,
    handleBlur,
    handleChange,
    values,
    dirty,
  }) => {
    const { isLoading } = this.props;

    const errorContent =
      !isValid && dirty
        ? { content: 'Please enter a valid email address' }
        : void 0;
    // avoid displaying weird large btn when the error label is displayed
    const btnStyle = { height: '100%' };

    return (
      <Form onReset={handleReset} onSubmit={handleSubmit}>
        <Form.Group style={{ marginTop: '1em' }} widths='equal'>
          <Form.Input
            disabled={isLoading}
            error={errorContent}
            name='email'
            onBlur={handleBlur}
            onChange={handleChange}
            type='email'
            value={values.email}
          />

          <Button
            content='Save'
            disabled={isLoading || !isValid}
            positive
            style={btnStyle}
            type='submit'
          />
        </Form.Group>
      </Form>
    );
  };

  render() {
    const { isLoading, testEmail, isTestEmailEnabled } = this.props;

    const descriptionStyle = {
      fontSize: '.875em',
    };

    const sectionDescription =
      'Address you can use to test functionality of outgoing emails from Barista. If test email is not enabled then emails will go out to users as normal';
    const emailFieldDescription =
      'The email address configured can receive a large number of emails as a result of testing';
    const toggleBtnLabel = isTestEmailEnabled ? 'ON' : 'OFF';
    const toggleLabel = 'Test email';
    const componentHeader = 'Test Email Address';

    return (
      <Form as='div'>
        <Header as='h4' content={componentHeader} dividing />
        <em style={descriptionStyle}>{sectionDescription}</em>

        <Form.Group style={{ marginTop: '1em' }}>
          <Form.Field style={{ paddingRight: '4em' }}>{toggleLabel}</Form.Field>

          <Checkbox
            checked={isTestEmailEnabled}
            disabled={isLoading || !testEmail}
            label={toggleBtnLabel}
            onChange={this.handleToggle}
            toggle
          />
        </Form.Group>

        <Formik
          enableReinitialize
          initialValues={{ email: testEmail }}
          onSubmit={this.handleFormSubmit}
          render={this.renderForm}
          validate={this.validateForm}
        />

        <em style={descriptionStyle}>{emailFieldDescription}</em>
      </Form>
    );
  }
}

export default AddTestEmailAddress;
