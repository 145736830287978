import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import baristaNotification from './globals/espNotificationSound/src/index';

const SOUND_DEBOUNCE_INTERVAL = 3000;
// Uses the visibility API to determine the name of the event handler that hooks to a
// tab's hidden/show STATE CHANGE
const visibilityAPIDocumentVisibilityChangeProperty = (doc) => {
  if (typeof doc.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof doc.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof doc.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return null;
};

// Uses the visibility API to determine the name of the document's property that tells the
// tab's hidden/show STATE
// e.g on Firefox if you do check the value of `document.hidden` it will return a true/false
const visibilityAPIDocumentIsHiddenProperty = (doc) => {
  if (typeof doc.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof doc.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof doc.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return null;
};

// Check if the document is on the front (tab is shown)
// @param {Document} doc .                      DOM Document
// @param {string}   browserDocumentHiddenProp  One of 'hidden', 'msHidden', 'webkitHidden'
const isDocumentShown = (doc, browserDocumentHiddenProp) =>
  !doc[browserDocumentHiddenProp];

// Detects an integer change from numberX to number Y. delta is true if the change is positive.
// If the prev value is null it will return false.
// @param {(null|integer)} prev
// @param{ integer}        current
const deltaDetected = (prev, current) => prev && current - prev > 0;

// Checks if a placeholder exists with the pattern ([any digit]) exists in the text.
// If it is, it replaces the digits with the passed newValue , otherwise prepends it.
// @param {string} text
// @param {int}    newValue
const replaceOrPrependCount = (text, newValue) => {
  const containsCount = new RegExp(/\(\d+\)/).test(text);
  return containsCount
    ? text.replace(/\d+/, newValue)
    : `(${newValue}) ${text}`;
};
class UnreadCount extends React.Component {
  static propTypes = {
    canPlayNotificationsSounds: PropTypes.bool,
    unreadCount: PropTypes.number,
  };

  static defaultProps = {
    canPlayNotificationsSounds: true,
    unreadCount: null,
  };

  constructor(props) {
    super(props);
    // Listen to changes in the visibility of the tab. This is the magic here.
    // This event listener is triggered every time the tab is hidden/shown
    // Also, do not try to asign this to the window.document since it has a weird behavior
    document.addEventListener(
      visibilityAPIDocumentVisibilityChangeProperty(document),
      this.onVisibilityChange,
      false
    );
    // Safari is pretty restrictive in terms of what you can do when the tab is hidden.
    // It will not allow you to load the sound when hidden, but will allow you to play it.
    // So instance the audio here and not in the playing function, since that call can happen at the
    // 'hidden' state of the tab.
    this.audio = new Audio(baristaNotification);
  }

  state = {
    baseTitle: null,
    hidden: false,
  };

  componentDidUpdate(prevProps) {
    const { canPlayNotificationsSounds, unreadCount } = this.props;
    const { baseTitle, hidden } = this.state;

    const hasDelta = deltaDetected(prevProps.unreadCount, unreadCount);
    const deltaFirstChangeState = unreadCount > 0 && hasDelta === 0;
    // Change the header ONLY AND ONLY IF the state of the component is hidden
    // In the shown state, it will be <Helmet> updating the count
    if (hasDelta && hidden) {
      document.title = replaceOrPrependCount(baseTitle, unreadCount);
    }
    // regardless of the hidden state make the sound on delta detected
    // if the tenant is configured to do so.

    if ((hasDelta && canPlayNotificationsSounds) || deltaFirstChangeState) {
      // debounce returns a function that does its magic when invoked.
      // Also optimize it by calling a named function.
      debounce(this.playNotificationSound, SOUND_DEBOUNCE_INTERVAL, {
        leading: true,
      })();
      // NOTE: it seems that the speed of the function call is limited by
      // the API polling (request/response cycle) to fetch the scoped and direct messages.
      // Nevertheless this code will protect against having a burst of sound notifications if
      // we go real real time (websockets).
    }
  }
  playNotificationSound = () => {
    this.audio.play();
  };

  onVisibilityChange = () => {
    const isShown = isDocumentShown(
      document,
      visibilityAPIDocumentIsHiddenProperty(document)
    );

    if (isShown) {
      const { baseTitle } = this.state;
      // Put back the original (base) title that was there in the shown state.
      if (baseTitle) {
        document.title = baseTitle;
      }

      this.setState({
        baseTitle: null,
        hidden: false,
      });
    } else {
      this.setState({
        baseTitle: document.title,
        hidden: true,
      });
    }
  };

  render() {
    return <></>;
  }
}

export default UnreadCount;
