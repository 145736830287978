import { intl } from 'esp-util-intl';
import { random } from 'lodash';

/**
 * MSG tips available only when EMAIL is true in the tenant config
 * @type {[*]}
 */
const msgEmail = [
  [
    {
      text: 'message.tired_old_emails',
    },
    {
      text: 'message.make_things_better',
    },
    {
      text: 'message.get_future_announcements',
    },
  ],
];

/**
 * MSG tips available only when PROFILE is true in the tenant config
 * @type {[*]}
 */
const msgProfile = [
  [
    {
      text: 'message.profile_contact_information',
    },
    {
      text: 'message.profile_always_up_date',
    },
    {
      text: 'message.hr_immediately_notified',
    },
  ],
];

/**
 * MSG tips available only when DIRECTORY is true in the tenant config
 * @type {[*]}
 */
const msgDirectory = [
  [
    {
      text: 'message.need_find_someone',
    },
    {
      text: 'message.corporate_directory',
    },
    {
      text: 'message.search_based_on_name',
    },
  ],
];

/**
 * MSG tips available only when CONVERSATION is true in the tenant config
 * @type {[*]}
 */
const msgChat = [
  [
    {
      text: 'message.messages_section_active_conversations',
    },
    {
      text: 'message.new_conversation_go_profile',
    },
  ],
];

/**
 * MSG tips available only when SHOP is true in the tenant config
 * @type {[*]}
 */
const msgHardware = [
  [
    {
      text: 'message.new_hardware_software',
    },
    {
      text: 'message.state_of_the_art',
    },
    {
      text: 'message.find_number_products',
    },
  ],
];

/**
 * MSG tips available only when EQUIPMENT is true in the tenant config
 * @type {[*]}
 */
const msgEquipment = [
  [
    {
      text: 'message.my_equipment',
    },
    {
      text: 'message.view_track_pending_orders',
    },
    {
      text: 'message.ask_me_for_help',
    },
  ],
];

const defaultMsgs = [
  [
    {
      text: 'message.need_help',
    },
    {
      text: 'message.tell_me_question_issue',
    },
  ],
];
const defaultMsgs2 = [
  [
    {
      text: 'message.always_here_to_help',
    },
  ],
];

const getFinalMessages = (
  config = {
    chat: false,
    default: true,
    default2: true,
    directory: false,
    email: false,
    my_equipment: false,
    profile: false,
    store: false,
  },
  customMessages = []
) => {
  let messages = [];

  if (customMessages.length > 0) {
    messages = messages.concat(customMessages);
  }
  if (config.default) {
    messages = messages.concat(defaultMsgs);
  }
  if (config.default2) {
    messages = messages.concat(defaultMsgs2);
  }
  if (config.store) {
    messages = messages.concat(msgHardware);
  }
  if (config.my_equipment) {
    messages = messages.concat(msgEquipment);
  }
  if (config.email) {
    messages = messages.concat(msgEmail);
  }

  if (config.profile) {
    messages = messages.concat(msgProfile);
  }

  if (config.directory) {
    messages = messages.concat(msgDirectory);
  }

  if (config.chat) {
    messages = messages.concat(msgChat);
  }

  if (!messages.length) {
    return [];
  }

  const randomMsgId = random(0, messages.length - 1);
  return messages[randomMsgId].map((item) => {
    if (item.id) {
      return {
        text: item.text,
      };
    }
    return {
      text: intl.formatMessage({ id: item.text }),
    };
  });
};

const ALL_DEFAULT_FEED_MESSAGES = {
  chat: msgChat[0],
  default: defaultMsgs[0],
  default2: defaultMsgs2[0],
  directory: msgDirectory[0],
  email: msgEmail[0],
  my_equipment: msgEquipment[0],
  profile: msgProfile[0],
  store: msgHardware[0],
};

export { ALL_DEFAULT_FEED_MESSAGES };

export default getFinalMessages;
