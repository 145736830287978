import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';

const getSamlSpIdentityIdConfig = async (data) => {
  const url = endpointGenerator.genPath('espConfig.samlSpIdentityId');

  return _APIcall.get({
    data,
    preventShowError: true,
    token: true,
    url: url,
  });
};

export default getSamlSpIdentityIdConfig;
