import async from 'async';
import _ from 'lodash';
import { fromJS } from 'immutable';

// Util
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

// Globals
import BundleStatus from '../globals/BundleStatus';
import JobRoleError from '../globals/JobRoleError';

// Selector
import getCurrentUser from '../selectors/getCurrentUser';
import getSimpleWFRequestedFor from '../selectors/getSimpleWFRequestedFor';

// Action
import bundleAction from './bundleActions';
// Thunks
import workflowThunks from './workflowThunks';

const bundleThunks = {};

/**
 * Load bundle role of a selected user
 * @param tag
 * @param requestedFor {bool} - Load fr the requestedFor user and not the currentUser
 */
bundleThunks.loadBundles = (tag = 'parent', requestedFor) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(bundleAction.loadBundlesStart(tag));

    const state = getState();
    const currentUser = requestedFor
      ? getSimpleWFRequestedFor(state)
      : getCurrentUser(state);

    async.waterfall(
      [
        // 1. Get User Job role
        (next) => {
          const job_role_url = currentUser.get('job_role');

          APIcall.get({
            token: true,
            url: job_role_url,
          })
            .then((res) => {
              const nameRole = res.body.name;
              next(null, nameRole);
            })
            .catch((err) => {
              next(err);
            });
        },

        // 2. Get Role bundles
        (nameRole, next) => {
          const job_url = endpointGenerator.genPath(
            'espUser.jobRoles.permissions'
          );

          APIcall.get({
            query: encodeURI('application=catalog&entity_type=espbundle'),
            token: true,
            url: job_url,
          })
            .then(({ body }) => {
              if (body.job_role_permissions[nameRole]) {
                const roleBundleIDs =
                  body.job_role_permissions[nameRole].bundles;
                next(null, roleBundleIDs);
              } else {
                next(JobRoleError.bundle_missing);
              }
            })
            .catch((err) => {
              next(err);
            });
        },

        // 3. Get Parent bundle
        (roleBundleIDs, next) => {
          if (_.isEmpty(roleBundleIDs)) {
            // no bundles are available for user's role.
            // Skip the API call and return an empty Array,
            // otherwise it'll return all bundles without any filtering

            dispatch(bundleAction.loadBundlesSuccess([], tag));
            next(null);
            // nothing else to do here ;)
            return;
          }

          const commaSeparatedIDs = roleBundleIDs.join(',');
          const espFilters = encodeURI(
            `status__EQ=${BundleStatus.ACTIVE}&id__IN=${commaSeparatedIDs}`
          );

          const endpoint = endpointGenerator.genPath('espCatalog.bundles');

          APIcall.get({
            query: {
              esp_filters: espFilters,

              // required to be passed since we know beforehand how many results we'll get (at most)
              limit: _.size(roleBundleIDs),
            },
            token: true,
            url: endpoint,
          })
            .then((res) => {
              if (res.body.results) {
                // We need to filter empty bundles or bundles with items not active
                const newBundlesList = res.body.results.filter((bundle) => {
                  const activeItems = bundle.items.filter(
                    (item) => item.status === BundleStatus.ACTIVE
                  );
                  return (
                    bundle.items.length &&
                    bundle.status === BundleStatus.ACTIVE &&
                    activeItems.length
                  );
                });
                dispatch(bundleAction.loadBundlesSuccess(newBundlesList, tag));
                next();
              } else {
                next(res.body);
              }
            })
            .catch((err) => {
              next(err);
            });
        },
      ],
      (error) => {
        if (error) {
          dispatch(bundleAction.loadBundlesFail(error, tag));
          reject();
        } else {
          resolve();
        }
      }
    );
  });

/**
 * Saves bundleId in the frontend_scratch and moves on to the next wf task
 * @param  {number} bundleId Selected bundle Id
 */
bundleThunks.selectBundle = (bundleId) => (dispatch) => {
  dispatch(bundleAction.bundleSelected(bundleId));

  dispatch(
    workflowThunks.saveToFrontEndScratch(
      {
        bundle: bundleId,
      },
      () => {
        dispatch(workflowThunks.moveNextWorkflowTask());
      }
    )
  );
  return true;
};

const getBundle = (bundleID, onBundle = _.noop) => {
  const url = endpointGenerator.genPath('espCatalog.bundles.instance', {
    bundleID,
  });
  APIcall.get({
    error(error) {
      onBundle(error.response.body);
    },
    success(response) {
      const bundle = response.body;
      onBundle(null, bundle);
    },
    token: true,
    url,
  });
};

const getProduct = (productID, onProduct = _.noop, checkFamily, isFamily) => {
  const url = isFamily
    ? endpointGenerator.genPath('espCatalog.productFamilies.instance', {
        pfamilyId: productID,
      })
    : endpointGenerator.genPath('espCatalog.products.instance', {
        productID,
      });

  APIcall.get({
    error(error) {
      onProduct(error.response.body);
    },
    success(response) {
      const product = response.body;
      if (checkFamily && product.product_family) {
        getProduct(product.product_family, onProduct, null, true);
      } else {
        onProduct(null, product);
      }
    },
    token: true,
    url,
  });
};

bundleThunks.getChildBundleProducts = (tag) => (dispatch, getState) => {
  const state = getState();
  const workflowState = state.get('workflowState');
  const frontendScratch = workflowState.get('frontendScratch');

  const selectedBundleId = frontendScratch.get('bundle');

  // TODO I'm quick fixing a major issue with this if statement. This thunk was originally created specific for the ProductConfiguration use case
  // and it's now being used by SoftwareSelect as well by accepting a bundle tag as argument. The problem is that 'bundleActions.getHardwareProductsStart()'
  // clears 'state.bundles.selectedHardwareBundleProductId' while AddToCartButton action is still in progress, causing products from hardware bundle to not be added
  // to the cart when SoftwareSelect loads. This happens because AddToCartButton causes a form submit on click and also triggers an api update at same time.
  // this needs to be addessed in a more robust way. Ozzy
  if (tag === 'hardware') {
    dispatch(bundleAction.getHardwareProductsStart());
  }

  async.waterfall(
    [
      // 1. Get selected bundle
      (next) => {
        getBundle(selectedBundleId, next);
      },

      // 2. Get child bundles
      (topLevelBundle, next) => {
        const childBundlesIds = topLevelBundle.child_bundles;

        async.map(childBundlesIds, getBundle, (error, childBundles) => {
          if (error) {
            next(error);
          } else {
            next(null, topLevelBundle, childBundles);
          }
        });
      },

      // 3. Get hardware bundle
      (toplevelBundle, childBundles, next) => {
        // Todo - Needed to avoid another bundle apicall in the cart so we load all child bundle in the entities reducer here
        // See possible improvment here later
        dispatch(bundleAction.addBundlesChildSuccess(fromJS(childBundles)));

        const hardwareBundle = childBundles.find((b) => b.tags === tag);
        next(null, toplevelBundle, hardwareBundle);
      },

      // 4. Get hardware bundle products
      (toplevelBundle, hardwareBundle, next) => {
        const productsIds = hardwareBundle.products;

        async.map(productsIds, getProduct, (error, hardwareBundleProducts) => {
          if (error) {
            next(error);
          } else {
            next(null, toplevelBundle, hardwareBundle, hardwareBundleProducts);
          }
        });
      },
    ],
    (error, toplevelBundle, hardwareBundle, hardwareBundleProducts) => {
      if (error) {
        dispatch(bundleAction.loadBundlesFail(error));
      } else {
        dispatch(
          bundleAction.getHardwareProductsSuccess(
            toplevelBundle,
            hardwareBundle,
            hardwareBundleProducts
          )
        );
      }
    }
  );
};

//
// Loads all bundles that have current productID as part of a bundle item
//
// @param <Integer> productID
//
// @action bundleActions.saveBundleItems
// @action bundleActions.loadBundlesStart
// @action bundleActions.loadBundlesSuccess
// @action bundleActions.loadBundlesFail
//
// @return <Undefined> stores bundle and bundle items into redux state
//
bundleThunks.loadProductBundles = () => (dispatch, getState) => {
  const state = getState();
  const productID = state.getIn(['catalog', 'selectedProductId']);

  // loads bundle items from productID
  const loadBundleItems = (next = _.noop) => {
    const endpoint = endpointGenerator.genPath('espCatalog.bundleItem');

    APIcall.get({
      query: {
        esp_filters: `product__EQ=${productID}`,
      },
      success({ body }) {
        if (body.count > 0) {
          next(null, body.results);
          dispatch(bundleAction.saveBundleItems(body.results));
        } else {
          next(true);
        }
      },
      token: true,
      url: endpoint,
    });
  };

  // loads bundle items bundles
  const loadBundles = (bundleItems, next = _.noop) => {
    const bundleIDs = bundleItems.map((bItem) => bItem.parent);
    const endpoint = endpointGenerator.genPath('espCatalog.bundles');

    APIcall.get({
      query: {
        esp_filters: `items.parent__IN=${bundleIDs.join(',')}`,
      },
      success({ body }) {
        if (body.count > 0) {
          next(null, body.results);
        } else {
          next(true);
        }
      },
      token: true,
      url: endpoint,
    });
  };

  dispatch(bundleAction.loadBundlesStart());

  async.waterfall([loadBundleItems, loadBundles], (error, data) => {
    if (error) {
      dispatch(bundleAction.loadBundlesFail(error));
    } else {
      dispatch(bundleAction.loadBundlesSuccess(data));
    }
  });
};

export default bundleThunks;
