import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  keyboardVisible: false,
  registrationId: null,
});

const cordovaReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CORDOVA_KEYBOARD_VISIBLE: {
      return state.set('keyboardVisible', true);
    }

    case actionTypes.CORDOVA_KEYBOARD_HIDDEN: {
      return state.set('keyboardVisible', false);
    }

    case actionTypes.CORDOVA_REGISTER_NOTIFICATIONS_SUCCESS: {
      return state.set('registrationId', action.registrationId);
    }

    default: {
      return state;
    }
  }
};

export default cordovaReducer;
