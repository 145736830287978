import { injectIntl } from 'react-intl';

/**
 * Pass defaultProps to the new created component from injectIntl
 * @param component
 * @returns {*}
 */
const injectIntlToController = (component) => {
  /**  Connect component */
  const componentConnected = injectIntl(component);

  if (component.defaultProps) {
    componentConnected.defaultProps = component.defaultProps;
  }

  /** Returned function where we pass defaultProps */
  return componentConnected;
};

export default injectIntlToController;
