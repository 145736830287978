import Immutable from 'immutable';

const possibleCondition = [
  {
    name: 'user.job_role__EQ=', // Keep this previous version of condtion to keep the compatibility
    type: 'job_roles',
  },
  {
    name: 'user.job_role.id__EQ=',
    type: 'job_roles',
  },
  {
    name: 'user.location.ancestry.ancestry_id_list__INTC=',
    type: 'locations',
  },
  {
    name: 'user.department.ancestry.ancestry_id_list__INTC=',
    type: 'departments',
  },
  {
    name: 'user.user_state__EQ=',
    type: 'include_new_hire',
  },
  {
    name: 'user.remote_worker__IBOOL=',
    type: 'include_remote',
  },
  {
    name: 'user.computer_type__EQ=',
    type: 'computerType',
  },
  {
    name: 'user.mobile_type__EQ=',
    type: 'mobileType',
  },
  {
    name: 'IT_OperatingSystems__EQ=',
    type: 'deviceType',
  },
];

const locDeptJobToLoad = {
  computerType: [],
  departments: [],
  deviceType: [],
  job_roles: [],
  locations: [],
  mobileType: [],
};

/**
 * Build the conditions and  responses from a selected FAQ
 * @param selectedFAQ {immutable map}
 * @returns {{is_faq_protected: boolean, faq_response: {}}}
 */
const buildFAQResponses = (selectedFAQ) => {
  const faqResponses = {
    faq_response: {},
    is_faq_protected: false,
  };
  const answers = selectedFAQ.get('answers');

  let entry = -1;

  // Check if it's a protected FAQ with trigger
  const trigger = selectedFAQ.get('trigger');
  if (trigger && !trigger.isEmpty()) {
    trigger.forEach((entry) => {
      if (entry.get('is_protected')) {
        faqResponses.is_faq_protected = true;
      }
    });
  }

  answers.forEach((a) => {
    const locationdeptrole = {};

    if (a.get('condition')) {
      const listCondition = a
        .get('condition')
        .split(/&OR|&/g)
        .filter((cond) => cond) // Filter empty condition
        .map((cond) => (cond && cond.replace('#', '')) || cond); // Remove #

      possibleCondition.forEach((cond) => {
        listCondition.forEach((l) => {
          if (l.match(cond.name)) {
            const [, idToPass] = l.split(cond.name);

            if (
              cond.name === possibleCondition[4].name ||
              cond.name === possibleCondition[5].name
            ) {
              locationdeptrole[cond.type] = true;
            } else {
              if (!locationdeptrole[cond.type]) {
                locationdeptrole[cond.type] = [];
              }

              locationdeptrole[cond.type].push({
                id: idToPass,
                name:
                  cond.type === 'mobileType' || cond.type === 'computerType'
                    ? idToPass
                    : '', // Need to load this name later in the waterfall
              });
            }

            if (
              locDeptJobToLoad[cond.type] &&
              !locDeptJobToLoad[cond.type].find((c) => c === idToPass)
            ) {
              locDeptJobToLoad[cond.type].push(idToPass); // Will be in use to load the name
            }
          } else {
            // Check there is no match at all for unknow condition
            let noMAtch = true;
            for (let i = 0; i < possibleCondition.length; i++) {
              if (l.match(possibleCondition[i].name)) {
                noMAtch = false;
                break;
              }
            }

            if (noMAtch && !locationdeptrole[l]) {
              // Unknow condition
              locationdeptrole[l] = [];
              locationdeptrole[l].push({
                id: null,
                name: l,
              });
            }
          }
        });
      });
    }

    // Sort the lines by order
    const lines = a.get('lines', Immutable.fromJS([])).sort((a, b) => {
      const orderA = a.get('order'),
        orderB = b.get('order');
      return orderA < orderB ? -1 : orderA > orderB ? 1 : 0;
    });

    lines.forEach((l) => {
      // Add new entry for every line
      const order = l.get('order');
      entry++;
      faqResponses.faq_response[entry] = {
        active: l.get('active'),
        answerEID: a.get('eid'),
        eid: l.get('eid'),
        is_protected: l.get('is_protected'),
        locationdeptrole: locationdeptrole,
        main_answer_active: a.get('active'),
        main_answer_is_protected: a.get('is_protected'),
        order: order,
        response: l.get('phrase'),
      };

      if (l.get('is_protected') && !faqResponses.is_faq_protected) {
        faqResponses.is_faq_protected = true;
      }

      if (l.get('url')) {
        // The URL img
        faqResponses.faq_response[entry].url = l.get('url');
      }
    });
  });

  return faqResponses;
};

export default buildFAQResponses;
