// Utils
import APIcall from '../../../utils/APIcall';
import endpointGenerator from '../../../utils/endpointGenerator';

const fetchKb = (eid) => {
  return APIcall.get({
    query: {
      eid: eid,
    },
    token: true,
    url: endpointGenerator.genPath('espBarista.faqKbs.faqResponseLookup'),
  });
};

// Test code
// const fetchKb = (eid) => {
//   return new Promise((resolve) => {
//     APIcall.get({
//       url: endpointGenerator.genPath('espBarista.faqKbs.faqResponseLookup'),
//       token: true,
//       query: {
//         eid: eid,
//       },
//     }).catch(() => {
//       resolve({
//         body: {
//           plain_text:
//             'This is the KB content, images appear in the original sizo \n\n![Baby Yoda](https://upload.wikimedia.org/wikipedia/en/0/00/The_Child_aka_Baby_Yoda_%28Star_Wars%29.jpg) More content here, large images are scaled to max width of container \n\n![Galaxy stufft](https://upload.wikimedia.org/wikipedia/commons/4/4e/Pleiades_large.jpg)',
//           title: 'The Title',
//         },
//       });
//     });
//   });
// };
export { fetchKb };
