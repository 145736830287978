import APIcall from '../../../../../../v1/utils/APIcall';
import endpointGenerator from '../../../../../../v1/utils/endpointGenerator';
import EspFilters from 'esp-util-filters';

/**
 *
 * @param {boolean} resetTemplateList restart list
 * @returns {Promise}  returns api promise result
 */
const fetchLoadTemplateList = (resetTemplateList = false, templatesLength) => {
  const url = endpointGenerator.genPath('espTodo.template');

  const query = {
    limit: 24,
    offset: resetTemplateList ? 0 : templatesLength ? templatesLength : 0,
    order_by: '-sys_date_created',
    // uncomment this block once activity_type is supported by BE
    // eslint-disable-next-line sort-keys -- order matters
    esp_filters: new EspFilters()
      .equalTo('activity_type', 'offboarding')
      .asQueryString(),
  };

  return APIcall.get({
    query,
    token: true,
    url: url,
  });
};
export default fetchLoadTemplateList;
