import async from 'async';
import _ from 'lodash';

import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import ProductPermissionsParser from '../utils/ProductPermissionsParser';
import productPermissionActions from './productPermissionsActions';

const getGroupIDs = (productID, onGroups = _.noop) => {
  const url = endpointGenerator.genPath(
    'espCatalog.products.instance.permissionGroups',
    {
      productID,
    }
  );

  APIcall.get({
    error(error) {
      onGroups(error.response.body);
    },
    query: {
      brief: 'yes',
    },
    success(response) {
      const { groups } = response.body;
      onGroups(null, groups);
    },
    token: true,
    url,
  });
};

const getGroupRule = (permissionGroupID, onRule = _.noop) => {
  const url = endpointGenerator.genPath(
    'espRbac.permissionGroups.instance.rule',
    {
      permissionGroupID,
    }
  );

  APIcall.get({
    error(error) {
      onRule(error.response.body);
    },
    success(response) {
      const rule = response.body;
      onRule(null, rule);
    },
    token: true,
    url,
  });
};

const getLocation = (locationID, onLocation = _.noop) => {
  const url = endpointGenerator.genPath('espPlaces.locations.instance', {
    locationID,
  });

  APIcall.get({
    error(error) {
      onLocation(error.response.body);
    },
    success(response) {
      const location = response.body;
      onLocation(null, location);
    },
    token: true,
    url,
  });
};

const getDepartment = (departmentID, onDepartment = _.noop) => {
  const url = endpointGenerator.genPath('espUser.department.instance', {
    departmentID,
  });

  APIcall.get({
    error(error) {
      onDepartment(error.response.body);
    },
    success(response) {
      const department = response.body;
      onDepartment(null, department);
    },
    token: true,
    url,
  });
};

const getDepartments = (departmentIDs, onDepartments = _.noop) => {
  const requestedDepartmentIDs = new Set();
  const departments = [];

  const traverseUpToRoot = (departmentID, done) => {
    if (requestedDepartmentIDs.has(departmentID)) {
      done(null);
      return;
    }

    requestedDepartmentIDs.add(departmentID);

    getDepartment(departmentID, (error, department) => {
      if (error) {
        done(error);
      } else {
        departments.push(department);

        const parentID = department.parent;

        if (parentID) {
          traverseUpToRoot(parentID, done);
        } else {
          // 'department' is a root
          done(null);
        }
      }
    });
  };

  async.each(departmentIDs, traverseUpToRoot, (error) => {
    if (error) {
      onDepartments(error);
    } else {
      onDepartments(null, departments);
    }
  });
};

const getLocations = (locationIDs, onLocations = _.noop) => {
  const requestedLocationIDs = new Set();
  const locations = [];

  const traverseUpToRoot = (locationID, done) => {
    if (requestedLocationIDs.has(locationID)) {
      done(null);
      return;
    }

    requestedLocationIDs.add(locationID);

    getLocation(locationID, (error, location) => {
      if (error) {
        done(error);
      } else {
        locations.push(location);

        const parentID = location.parent;

        if (parentID) {
          traverseUpToRoot(parentID, done);
        } else {
          // 'location' is a root
          done(null);
        }
      }
    });
  };

  async.each(locationIDs, traverseUpToRoot, (error) => {
    if (error) {
      onLocations(error);
    } else {
      onLocations(null, locations);
    }
  });
};

const addUserRule = (ruleExpression, onAddUserRule = _.noop) => {
  const url = endpointGenerator.genPath('espRbac.userRules');

  APIcall.post({
    data: {
      rule: ruleExpression,
    },
    error(error) {
      onAddUserRule(error.response.body);
    },
    success(response) {
      const userRule = response.body;
      onAddUserRule(null, userRule);
    },
    token: true,
    url,
  });
};

const addProductEntityPermission = (
  productIDs,
  userRuleID,
  onEntityPermission = _.noop
) => {
  const userRuleUrl = endpointGenerator.genPath('espRbac.userRules.instance', {
    userRuleID,
  });

  const url = endpointGenerator.genPath('espRbac.entityPermissions');

  APIcall.post({
    data: {
      entity_app_label: 'catalog',
      entity_filter: {
        pk__in: productIDs,
      },
      entity_model: 'espproduct',
      obj_permissions: ['catalog.view_espproduct'],
      rule: userRuleUrl,
    },
    error(error) {
      onEntityPermission(error.response.body);
    },
    success(response) {
      const entityPermission = response.body;
      onEntityPermission(null, entityPermission);
    },
    token: true,
    url,
  });
};

const getUserRuleByExpression = (expression, onUserRule = _.noop) => {
  const url = endpointGenerator.genPath('espRbac.userRules');

  APIcall.get({
    error(error) {
      onUserRule(error.response.body);
    },
    query: {
      rule: expression,
    },
    success(response) {
      const userRules = response.body.results;

      if (!_.isEmpty(userRules)) {
        onUserRule(null, _.head(userRules));
      } else {
        onUserRule(null, null);
      }
    },
    token: true,
    url,
  });
};

const getUniqueCatalogProductFilterByRule = (
  userRuleID,
  onUniqueCatalogProductFilter = _.noop
) => {
  const url = endpointGenerator.genPath(
    'espRbac.userRules.instance.entityFilters',
    {
      userRuleID,
    }
  );

  APIcall.get({
    error(error) {
      onUniqueCatalogProductFilter(error.response.body);
    },
    query: {
      app: 'catalog',
      entity: 'espproduct',
    },
    success(response) {
      const catalogProductFilters = response.body.results;

      if (_.isEmpty(catalogProductFilters)) {
        onUniqueCatalogProductFilter(null, null);
      } else {
        if (_.size(catalogProductFilters) > 1) {
          // eslint-disable-next-line no-console -- debugging
          console.warn(
            'For the poll permissions structure, ' +
              `it's expected that user rule ${userRuleID} to be matched with 0 or 1 entity permissions, ` +
              `but it's matched with ${_.size(catalogProductFilters)}. ` +
              'This is a permissions data issue.'
          );
        }

        const uniqueCatalogProductFilter =
          catalogProductFilters[catalogProductFilters.length - 1];

        onUniqueCatalogProductFilter(null, uniqueCatalogProductFilter);
      }
    },
    token: true,
    url,
  });
};

const removeEntityPermission = (
  entityPermissionID,
  onRemoveEntityPermission = _.noop
) => {
  const url = endpointGenerator.genPath('espRbac.entityPermissions.instance', {
    entityPermissionID,
  });

  APIcall.del({
    error(error) {
      onRemoveEntityPermission(error.response.body);
    },
    success() {
      onRemoveEntityPermission(null);
    },
    token: true,
    url,
  });
};

/**
 * @param {number} ruleID Left side of the relationship
 * @param {Array.<number>} productIDs Right side of the relationship
 * @return {string}
 */
const encodeProductPermissionGroupID = (ruleID, productIDs) => {
  const sortedProductIDs = _.sortBy(productIDs);

  const commaSeparedProductIDs = sortedProductIDs.join(',');
  return `${ruleID}-[${commaSeparedProductIDs}]`;
};

/**
 * @param {string} productPermissionGroupID
 */
const decodeProductPermissionGroupID = (productPermissionGroupID) => {
  const productPermissionGroupIDParts = productPermissionGroupID.split('-');

  const ruleID = Number(_.head(productPermissionGroupIDParts));
  const productIDs = JSON.parse(_.last(productPermissionGroupIDParts));

  return {
    productIDs,
    ruleID,
  };
};

const productPermissionsThunks = {};

/**
 * @param {Array.<number>} productIDs
 */
productPermissionsThunks.getProductPermissionGroups = (productIDs) => (
  dispatch
) => {
  // console.log(productIDs);

  dispatch(productPermissionActions.getProductPermissionGroupsStart());

  async.waterfall(
    [
      // 1. Get permission groups of all the products
      (next) => {
        async.map(productIDs, getGroupIDs, next);
      },

      // 2. Reverse index, get products per permission group
      (groupIDsArray, next) => {
        // console.log(groupIDsArray);

        // ES6 Map
        // G1 -> [P1, P2, ..., Pn]
        const groupsToProducts = new Map();

        groupIDsArray.forEach((groupIDs, i) => {
          const productID = productIDs[i];

          groupIDs.forEach((groupID) => {
            if (!groupsToProducts.has(groupID)) {
              groupsToProducts.set(groupID, []);
            }

            const productsInGroup = groupsToProducts.get(groupID);
            productsInGroup.push(productID);
          });
        });

        next(null, groupsToProducts);
      },

      // 3. Get user rule for each group
      (groupsToProducts, next) => {
        // console.log(groupsToProducts);

        const uniqueGroupIds = Array.from(groupsToProducts.keys());

        async.map(uniqueGroupIds, getGroupRule, (error, rules) => {
          if (error) {
            next(error);
          } else {
            next(null, groupsToProducts, rules);
          }
        });
      },

      // 4. Parse user rule expression to get locations, departments and job roles ids
      (groupsToProducts, rules, next) => {
        // console.log(rules);

        const parsedRules = rules.map((r) => {
          const parsedRule = ProductPermissionsParser.fromExpression(r.rule);
          parsedRule.ruleID = r.id;

          return parsedRule;
        });

        next(null, groupsToProducts, rules, parsedRules);
      },

      // 5. Ignore all rules not generated by our permissions UI tool
      (groupsToProducts, rules, parsedRules, next) => {
        const systemRuleIndexes = [];
        const systemGroupIDs = [];

        let iteration = 0;

        groupsToProducts.forEach((productIDs, groupID) => {
          const rule = rules[iteration];
          const parsedRule = parsedRules[iteration];

          const isSystemRule =
            _.isEmpty(parsedRule.locations) &&
            _.isEmpty(parsedRule.departments) &&
            _.isEmpty(parsedRule.jobRoles) &&
            rule.rule !== ProductPermissionsParser.FALSY_RULE;

          if (isSystemRule) {
            systemRuleIndexes.push(iteration);
            systemGroupIDs.push(groupID);
          }

          iteration++;
        });

        parsedRules = _.reject(parsedRules, (parsedRule, i) =>
          systemRuleIndexes.includes(i)
        );

        systemGroupIDs.forEach((systemGroupID) => {
          groupsToProducts.delete(systemGroupID);
        });

        next(null, groupsToProducts, parsedRules);
      },

      // 6. Get locations from the rule expression
      (groupsToProducts, parsedRules, next) => {
        // ES6 Set
        const uniqueLocationIDs = new Set();

        parsedRules.forEach((parsedRule) => {
          const locationIDs = parsedRule.locations;

          locationIDs.forEach((locationID) =>
            uniqueLocationIDs.add(locationID)
          );
        });

        // console.log(uniqueLocationIDs);

        const uniqueLocationIDsArray = Array.from(uniqueLocationIDs);

        getLocations(uniqueLocationIDsArray, (error, locations) => {
          if (error) {
            next(error);
          } else {
            next(null, groupsToProducts, parsedRules, locations);
          }
        });
      },

      // 7. Get departments from the rule expression
      (groupsToProducts, parsedRules, locations, next) => {
        // ES6 Set
        const uniqueDepartmentIDs = new Set();

        parsedRules.forEach((parsedRule) => {
          const departmentIDs = parsedRule.departments;

          departmentIDs.forEach((departmentID) =>
            uniqueDepartmentIDs.add(departmentID)
          );
        });

        // console.log(uniqueDepartmentIDs);

        const uniqueDepartmentIDsArray = Array.from(uniqueDepartmentIDs);

        getDepartments(uniqueDepartmentIDsArray, (error, departments) => {
          if (error) {
            next(error);
          } else {
            next(null, groupsToProducts, parsedRules, locations, departments);
          }
        });
      },
    ],
    (error, groupsToProducts, parsedRules, locations, departments) => {
      if (error) {
        dispatch(
          productPermissionActions.getProductPermissionGroupsFail(error)
        );
      } else {
        // console.log(locations);
        // console.log(departments);

        const wiredPermissionGroups = [];

        groupsToProducts.forEach((productIDs) => {
          const parsedRule = _.head(parsedRules);
          parsedRules = _.tail(parsedRules);

          const wiredPermissionGroup = {};
          wiredPermissionGroup.id = encodeProductPermissionGroupID(
            parsedRule.ruleID,
            productIDs
          );
          wiredPermissionGroup.productIDs = productIDs;

          wiredPermissionGroup.parsedRule = parsedRule;

          wiredPermissionGroups.push(wiredPermissionGroup);
        });

        dispatch(
          productPermissionActions.getProductPermissionGroupsSuccess(
            wiredPermissionGroups,
            locations,
            departments
          )
        );
      }
    }
  );
};

/**
 * Encapsulates the logic to add a product permission group without throwing any concrete action.
 * So it can be re used among thunks.
 *
 * @param {Array.<number>} productIDs Right side of the relationship
 * @param {string} ruleExpression Left side of the relationship
 */
const addProductPermissionGroup = (productIDs, ruleExpression) =>
  new Promise((resolve, reject) => {
    async.waterfall(
      [
        // 1. Check if user rule already exist for rule expression
        (next) => {
          // console.log(`Check if "${ruleExpression}" rule exists.`);

          getUserRuleByExpression(ruleExpression, (error, userRule) => {
            if (error) {
              next(error);
            } else {
              // userRule may be null, but that's fine
              next(null, userRule);
            }
          });
        },

        // 2. Create user rule for empty expression if it doesn't exist
        (existingUserRule, next) => {
          // console.log('User rule exists?', existingUserRule);

          if (existingUserRule) {
            next(null, existingUserRule);
          } else {
            // console.log('Creating user rule.');

            addUserRule(ruleExpression, next);
          }
        },

        // 3. Get the rule id
        (userRule, next) => {
          if (userRule.status_url) {
            if (!userRule.id && !userRule.rule) {
              const statusID = userRule.status_url
                .split('/')
                .filter((e) => !isNaN(Number(e)) && e)
                .join('');
              const url = endpointGenerator.genPath(
                'commons.jobStatus.instance',
                {
                  statusID: Number(statusID),
                }
              );
              APIcall.get({
                token: true,
                url,
              })
                .then(({ body }) => {
                  const newUserRule = {
                    id: body.result_details.app_specific.rbac_rule_id,
                    rule: body.result_details.app_specific.rbac_rule,
                  };
                  next(null, newUserRule);
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              const newUserRule = {
                id: userRule.id,
                rule: userRule.rule,
              };
              next(null, newUserRule);
            }
          } else {
            next(null, userRule);
          }
        },

        // 4. Check if there's a entity permission linked to the empty rule
        (userRule, next) => {
          // console.log('Checking if there is an entity permission matched with the user rule.',userRule);

          getUniqueCatalogProductFilterByRule(
            userRule.id,
            (error, uniqueEntityPermission) => {
              if (error) {
                next(error);
              } else {
                next(null, userRule, uniqueEntityPermission);
              }
            }
          );
        },

        // 5. Determine pk__in for the new entity permission to be created
        (userRule, entityPermission, next) => {
          // console.log('Entity permission exists?', entityPermission);
          // console.log('Determining pk__in for the entity permission to be created.');

          let newPkIn;
          let error = null;

          if (entityPermission) {
            // validate if the productIDs aren't already covered by entityPermission
            const pkIn = entityPermission.entity_filter.pk__in;
            const diff = _.difference(productIDs, pkIn);

            // if true, productIDs is a subset of pkIn and _.union(pkIn, productIDs) equals pkIn,
            // so doesn't make sense to continue.
            const alreadyCovered = _.isEmpty(diff);

            if (alreadyCovered) {
              const msg = `Products [${productIDs.join()}] are already covered by entity permission ${
                entityPermission.id
              }`;
              // console.log(msg);
              error = new Error(msg);
            } else {
              newPkIn = _.union(pkIn, productIDs);
            }
          } else {
            newPkIn = productIDs;
          }

          if (error) {
            next(error);
          } else {
            next(null, userRule, entityPermission, newPkIn);
          }
        },

        // 6. Create entity permission with new pk__in
        (userRule, oldEntityPermission, newPkIn, next) => {
          // console.log('pk__in', newPkIn);
          // console.log('Creating entity permission.');

          addProductEntityPermission(
            newPkIn,
            userRule.id,
            (error, newEntityPermission) => {
              if (error) {
                next(error);
              } else {
                // console.log(`Entity permission ${newEntityPermission.id} created.`, newEntityPermission);
                next(null, userRule, oldEntityPermission, newEntityPermission);
              }
            }
          );
        },

        // 7. Remove old entity permission if exists
        (userRule, oldEntityPermission, newEntityPermission, next) => {
          if (oldEntityPermission) {
            // console.log(`Removing entity permission ${oldEntityPermission.id}.`);

            removeEntityPermission(oldEntityPermission.id, (error) => {
              if (error) {
                next(error);
              } else {
                next(null, userRule, newEntityPermission);
              }
            });
          } else {
            next(null, userRule, newEntityPermission);
          }
        },
      ],
      (error, userRule) => {
        if (error) {
          reject(error);
        } else {
          const parsedRule = ProductPermissionsParser.fromExpression(
            userRule.rule
          );
          parsedRule.ruleID = userRule.id;

          const productPermissionGroup = {
            id: encodeProductPermissionGroupID(userRule.id, productIDs),

            parsedRule,

            productIDs,
          };

          // console.log(productPermissionGroup);
          resolve(productPermissionGroup);
        }
      }
    );
  });

/**
 * Binds concrete actions to addProductPermissionGroup function.
 * @param {number} productID
 */
productPermissionsThunks.addProductPermissionGroup = (productID) => (
  dispatch
) => {
  const emptyRuleExpression = ProductPermissionsParser.toExpression([], [], []);

  dispatch(productPermissionActions.addProductPermissionGroupStart());

  addProductPermissionGroup([productID], emptyRuleExpression)
    .then((productPermissionGroup) =>
      dispatch(
        productPermissionActions.addProductPermissionGroupSuccess(
          productPermissionGroup
        )
      )
    )
    .catch((error) =>
      dispatch(productPermissionActions.addProductPermissionGroupFail(error))
    );
};

/**
 * Encapsulates the logic to delete a product permission group without throwing any concrete action.
 * So it can be re used among thunks.
 */
const removeProductPermissionGroup = (productPermissionGroupID) =>
  new Promise((resolve, reject) => {
    const decodedProductPermissionGroupID = decodeProductPermissionGroupID(
      productPermissionGroupID
    );
    const userRuleID = decodedProductPermissionGroupID.ruleID;
    const { productIDs } = decodedProductPermissionGroupID;

    // console.log(`Release products [${productIDs.join()}] from user rule ${userRuleID}.`);

    async.waterfall(
      [
        // 1. Get unique entity permission (right side) related to this user rule (left side)
        (next) => {
          // console.log(`Get entity permission for user rule ${userRuleID}.`);
          getUniqueCatalogProductFilterByRule(userRuleID, next);
        },

        // 2. Determine pk__in for the new entity permission
        (entityPermission, next) => {
          const pkIn = entityPermission.entity_filter.pk__in;
          const newPkIn = _.difference(pkIn, productIDs);

          // console.log(`pkIn [${pkIn.join()}]`);
          // console.log(`newPkIn [${newPkIn.join()}]`);

          next(null, entityPermission, newPkIn);
        },

        // 3. Create new entity permission if new pk__in is not empty
        (oldEntityPermission, newPkIn, next) => {
          // console.log('Create new entity permission?', !_.isEmpty(newPkIn));

          if (_.isEmpty(newPkIn)) {
            // it doesn't make sense to create the new entity permission
            next(null, oldEntityPermission, null);
          } else {
            addProductEntityPermission(
              newPkIn,
              userRuleID,
              (error, newEntityPermission) => {
                if (error) {
                  next(error);
                } else {
                  // console.log(`Entity permission ${newEntityPermission.id} created.`);
                  next(null, oldEntityPermission, newEntityPermission);
                }
              }
            );
          }
        },

        // 4. Remove the old entity permission
        (oldEntityPermission, newEntityPermission, next) => {
          removeEntityPermission(oldEntityPermission.id, (error) => {
            if (error) {
              next(error);
            } else {
              // console.log(`Entity permission ${oldEntityPermission.id} removed`);
              next(null);
            }
          });
        },
      ],
      (error) => {
        if (error) {
          reject(error);
        } else {
          resolve(productPermissionGroupID);
        }
      }
    );
  });

/**
 * Binds concrete actions to removeProductPermission function.
 * @param {string} productPermissionGroupID
 */
productPermissionsThunks.removeProductPermissionGroup = (
  productPermissionGroupID
) => (dispatch) => {
  dispatch(
    productPermissionActions.removeProductPermissionGroupStart(
      productPermissionGroupID
    )
  );

  removeProductPermissionGroup(productPermissionGroupID)
    .then(() =>
      dispatch(
        productPermissionActions.removeProductPermissionGroupSuccess(
          productPermissionGroupID
        )
      )
    )
    .catch((error) =>
      dispatch(productPermissionActions.removeProductPermissionGroupFail(error))
    );
};

/**
 * a) Delete the entity permission. This breaks the relationship.
 * b) Get rule for updated set of locations, departments and job roles, or create a new one
 * if it doesn't exist.
 * c) Create a new entiy permission for selected products and link it to rule from above step.
 *
 * @param {string} productPermissionGroupID
 * @param {string} ruleExpression Represents the left side of the relationship, should be provided
 * since the source of truth for this one doesn't come from Redux state
 */
productPermissionsThunks.saveProductPermissionGroup = (
  productPermissionGroupID,
  ruleExpression
) => (dispatch, getState) => {
  const state = getState();
  const productPermissionsState = state.get('productPermissions');
  const permissionGroups = productPermissionsState.getIn([
    'permissionGroups',
    'values',
  ]);

  let productPermissionGroup = permissionGroups.find(
    (g) => g.get('id') === productPermissionGroupID
  );
  productPermissionGroup = productPermissionGroup.toJS();

  dispatch(
    productPermissionActions.saveProductPermissionGroupStart(
      productPermissionGroupID
    )
  );

  async.waterfall(
    [
      // 1. Delete the old entity permission
      (next) => {
        removeProductPermissionGroup(productPermissionGroupID)
          .then(() => next(null))
          .catch((error) => next(error));
      },

      // 2. Apply ruleExpression to selected productIDs
      (next) => {
        const { productIDs } = productPermissionGroup;

        addProductPermissionGroup(productIDs, ruleExpression)
          .then((newProductPermissionGroup) =>
            next(null, newProductPermissionGroup, productIDs)
          )
          .catch((error) => next(error));
      },
    ],
    (error, newProductPermissionGroup, productIDs) => {
      if (error) {
        dispatch(
          productPermissionActions.saveProductPermissionGroupFail(error)
        );
      } else {
        const { parsedRule } = newProductPermissionGroup;

        const newProductPermissionGroupID = encodeProductPermissionGroupID(
          parsedRule.ruleID,
          productIDs
        );

        productPermissionGroup.id = newProductPermissionGroupID;
        productPermissionGroup.parsedRule = parsedRule;

        dispatch(
          productPermissionActions.saveProductPermissionGroupSuccess(
            productPermissionGroupID,
            productPermissionGroup
          )
        );
      }
    }
  );
};

export default productPermissionsThunks;
