import { messageEval } from '../utils';

const apiActionButtonEval = (msg) =>
  Boolean(msg?.metadata?.user_input?.api_action_button);

const apiActionButtonEvals = {
  apiActionButton: apiActionButtonEval,
};

const apiActionButton = {
  evaluate: (msg) => messageEval(msg) && apiActionButtonEval(msg),
};

export { apiActionButtonEvals };
export default apiActionButton;
