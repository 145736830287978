import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreLongSleeve extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M66.93 78.7c-.552 0-1-.448-1-1V46.37c0-.552.448-1 1-1s1 .448 1 1V77.7c0 .552-.448 1-1 1z' />
          <path d='M78.39 71.58H66.88c-.552 0-1-.448-1-1s.448-1 1-1H77.4L78 34.2C77.29 20.53 67.51 20.01 67.1 20l-5.43.08c-3.79 8.5-13.09 8.94-13.5 9H48c-7.89 0-12.7-6.92-13.93-8.94l-5.38.08c-.43 0-10.86.53-10.72 15.42l.63 33.29h10.06c.552 0 1 .448 1 1s-.448 1-1 1h-11c-.552 0-1-.448-1-1L16 35.61c-.16-16.91 12.55-17.44 12.68-17.45l6-.09c.372-.003.715.2.89.53 0 .08 4.71 8.5 12.57 8.42.33 0 8.83-.44 11.94-8.31.15-.377.514-.626.92-.63l6.13-.09c.16 0 12.08.54 12.88 16.17L79.4 70.6c-.01.548-.462.986-1.01.98z' />
          <path d='M66.91 78.93l-37.77-.18c-.552 0-1-.448-1-1L28 45.93c0-.552.448-1 1-1s1 .448 1 1l.17 30.87 36.74.13c.552 0 1 .448 1 1s-.448 1-1 1z' />
        </g>
      </svg>
    );
  }
}

export default CatalogCompanyStoreLongSleeve;
