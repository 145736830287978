import buildCompositeController from '../../../../v1/utils/buildCompositeController';

import locationsThunks from '../../../../v1/actions/locationsThunks';
import casesActions from '../../../../v1/actions/casesActions';

import getLeafLocations from '../../../../v1/selectors/getLeafLocations';

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.getIn([
    'hierarchySelect',
    'leaftNodesPagination',
    'isLoading',
  ]),
  leafLocations: getLeafLocations(state, ownProps),
  pagination: state.getIn(['hierarchySelect', 'leaftNodesPagination', 'next']),
  savedSelectedLocationId: state.getIn([
    'cases',
    'subscribersSelect',
    'subscriptionScope',
    'location',
    0,
  ]),
  tmpSelectLocationedId: state.getIn([
    'cases',
    'subscribersSelect',
    'tmpSelectedLocationId',
  ]), // for now we only pass the 1 location, since we don't support multiple locations
});

const mapDispatchToProps = (dispatch) => ({
  getNextLeafNodes({ initial, locationType }) {
    dispatch(
      locationsThunks.getLeafNodes({
        initial,
        locationType,
      })
    );
  },

  setTmpLocationSelection(locationId, locationName) {
    dispatch(
      casesActions.setTmpSubscriberLocationSelection(locationId, locationName)
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
