import { change, initialize } from 'redux-form/immutable';
import sessionThunks from '../../actions/sessionThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import buildCompositeController from '../../utils/buildCompositeController';
import { getMultiLanguageConfig } from '../../selectors/getIntlMessages';

const mapStateToProps = (state) => {
  const intl = state.get('intl');
  const isLoading = intl.get('isLoadingSupportedLanguages');
  const supportedLanguages = intl.get('supportedLanguages');
  const isTenantMultiLanguageSupported = getMultiLanguageConfig(state);

  return {
    isLoading,
    isTenantMultiLanguageSupported,
    supportedLanguages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadSupportedLanguages(formName) {
    dispatch(sessionThunks.loadSupportedLanguages())
      .then((languages) => {
        if (languages && languages.length) {
          const { initialValues } = ownProps;
          // A fieldName has been passed - We have to change the redux form value
          // instead to force to re-init the entire form
          if (ownProps.fieldName && formName) {
            // establish default selection only when none exists
            if (!initialValues.preferred_language_id) {
              dispatch(
                change(formName, ownProps.fieldName, languages[0].beCode)
              );
            }
          } else {
            // Re-init the form
            if (!initialValues.preferred_language_id) {
              initialValues.preferred_language_id = languages[0].beCode;
            }
            if (formName) {
              dispatch(initialize(formName, initialValues));
            }
          }
        } else {
          dispatch(
            toastNotificationsActions.error({
              message: 'No supported languages',
              title: 'Error',
            })
          );
        }
      })
      .catch(({ message }) => {
        dispatch(
          toastNotificationsActions.error({
            message: message,
            title: 'Error',
          })
        );
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
