import CordovaUtils from './CordovaUtils';
import ElectronUtils from './ElectronUtils';

// Actions
import bootstrapThunks from '../actions/bootstrapThunks';
import bootstrapActions from '../actions/bootstrapActions';
import wsActions from '../actions/wsActions';
import tenantThunks from '../actions/tenantThunks';
import socializeThunks from '../actions/socializeThunks';
import analyticsThunks from '../actions/analyticsThunks';
import todoThunks from '../actions/todoThunks';
import compatibilityCheckThunks from '../actions/compatibilityCheckThunks';
import systemVersionThunks from '../actions/systemVersionThunks';
import store from '../stores/store';
import LocaleSingleton from './LocaleSingleton';
import PwaUtils from './PwaUtils';
import uiPathGenerator from './uiPathGenerator';
/**
 * Actions to be perform when the main pages mount/unmount
 * Attention devs: When adding a new action, consider what other pages might need it
 */
const HelloActions = {
  adminGoodbye: () => {
    // Nothing here for now
    // dispatch;
  },

  // Used by AdminPageController
  adminHello: (dispatch) => {
    dispatch(bootstrapThunks.appBootstrap()).then(() => {
      dispatch(analyticsThunks.startTracking());
      dispatch(tenantThunks.getTenantUserGroupPermissions());
      dispatch(tenantThunks.getAllowedExtensions());
      dispatch(wsActions.disconnect());
    });
  },

  editorGoodbye: () => {
    // Nothing here for now
    // dispatch;
  },

  // Used by EditorPageController
  editorHello: (dispatch) => {
    dispatch(bootstrapThunks.appBootstrap());
  },

  homeGoodbye: () => {
    const { dispatch } = store;

    // these are called on componentWillUnmount
    if (process.env.NODE_ENV !== 'development') {
      // avoids doing this on webpack hot reload
      dispatch(wsActions.disconnect());
      dispatch(analyticsThunks.endTracking());
    }
  },

  // Used by HomePageController
  homeHello: () => {
    const { dispatch } = store;
    const state = store.getState();
    const isHelloDone = state.getIn(['appUI', 'isHelloDone']);
    const isHelloLoading = state.getIn(['appUI', 'isHelloLoading']);

    // make sure to only do this when we haven't done the hello actions before
    // and when this is not loading
    if (!isHelloDone && !isHelloLoading) {
      dispatch(bootstrapActions.helloActionsStart());
      dispatch(bootstrapThunks.appBootstrap()).then(({ me, userSettings }) => {
        // we can mark this as ended here already
        // to make sure boostrap was only called once
        dispatch(bootstrapActions.helloActionsEnd());
        dispatch(wsActions.connect());
        dispatch(tenantThunks.getAllowedExtensions());
        dispatch(tenantThunks.loadTenantLabelConfiguration());
        dispatch(socializeThunks.getTotalUnreadForScopedChannels());
        dispatch(socializeThunks.getTotalUnreadForDirectChannels());
        dispatch(analyticsThunks.startTracking());
        dispatch(compatibilityCheckThunks.checkCompatibility());
        dispatch(systemVersionThunks.getClientVersion());
        const isEncodeForced = true;
        dispatch(systemVersionThunks.getVersion(isEncodeForced));
        dispatch(compatibilityCheckThunks.checkCompatibility());
        dispatch(
          todoThunks.setInitialHomeFilters(userSettings?.homeFeed_filters)
        );

        const scrollDownBaristaChat = () => {
          // here we use this new chat-area id to get barista chat content area
          const chatArea = document.getElementById('chat-area');
          const contentAreaIndex = 0;
          const startOfXAxeValue = 0;
          const newYAxeValue =
            document.getElementById('chat-area').children[contentAreaIndex]
              .scrollHeight;

          if (chatArea) {
            document
              .getElementById('chat-area')
              .children[contentAreaIndex].scrollTo(
                startOfXAxeValue,
                newYAxeValue
              );
          }
        };

        if (window.cordova) {
          CordovaUtils.initPushNotifications();
          CordovaUtils.lockScreenRotation();
          CordovaUtils.retrieveAppVersion();
          CordovaUtils.setDeepLinkListener();

          document
            .querySelector('body')
            .setAttribute('data-os-version', window.device.version);

          // shrinking view
          window.addEventListener('keyboardDidShow', () => {
            document
              .querySelector('body')
              .setAttribute('data-keyboard', 'open');
            //
            // We need to scroll down barista chat when virtual keyboard is being displayed ,
            // because currently the keyboard overlaps the content and the view does not seem to be auto scrolling the content
            //
            scrollDownBaristaChat();
          });

          window.addEventListener('keyboardDidHide', () => {
            document
              .querySelector('body')
              .setAttribute('data-keyboard', 'closed');
          });
        }

        if (window.electron) {
          ElectronUtils.registerClientNotification()
            .then(() => {})
            .catch(() => {
              // e;
            });

          ElectronUtils.retrieveAppVersion();
        }

        if (PwaUtils.isPwaInstalled()) {
          PwaUtils.registerClientNotification().catch(() => {
            // e;
          });
        }

        LocaleSingleton.set();
      });
    }
  },

  portalHello: (dispatch) => {
    const url = new URL(window.location.href);
    const chatVersion = url.searchParams.get('chat_version');
    if (chatVersion === '2') {
      const chatAppPath = uiPathGenerator.genPath('chatV2App');
      window.location.replace(`${chatAppPath}/`);
      return;
    }
    dispatch(bootstrapThunks.appBootstrap());
    dispatch(tenantThunks.loadChatbotImage());
  },
};

export default HelloActions;
