import EspDateUtil from 'esp-date-util';

/**
 * Formats an ISO date into local format
 * @param {string} date - The ISO date to be formatted.
 * @returns {string} - The formatted date string.
 */
const dateFormat = (date) => new EspDateUtil(date).getStandardDateFormat();

export default dateFormat;
