import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form/immutable';
import _ from 'lodash';

import FormInputText from './FormInputText';

class AddTaskForm extends PureComponent {
  static propTypes = {
    apiError: PropTypes.shape({
      name: PropTypes.string,
    }),
    handleSubmit: PropTypes.func,
  };

  static defaultProps = {
    apiError: {},
    handleSubmit: _.noop,
  };

  render() {
    const { handleSubmit, apiError } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          component={FormInputText}
          formError={apiError ? apiError.name : null}
          label=''
          name='name'
          placeholder={intl.formatMessage({
            id: 'label.my_workflow_task',
          })}
        />
      </Form>
    );
  }
}
// eslint-disable-next-line no-class-assign -- DEV-1526
AddTaskForm = reduxForm({
  form: 'AddTaskForm',

  validate(values) {
    const errors = {};

    const name = values.get('name', '').trim();

    if (_.isEmpty(name)) {
      errors.name = 'Please add a value';
    }

    return errors;
  },
})(AddTaskForm);

export default AddTaskForm;
