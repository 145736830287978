import { change } from 'redux-form/immutable';
import _ from 'lodash';
import Immutable from 'immutable';

import buildCompositeController from '../../utils/buildCompositeController';

import getJobRolesWithAvailableBundles from '../../selectors/getJobRolesWithAvailableBundles';
import getWorkflowRequestedFor from '../../selectors/getWorkflowRequestedFor';
import jobRoleThunks from '../../actions/jobRoleThunks';
import workflowThunks from '../../actions/workflowThunks';
import workflowActions from '../../actions/workflowActions';

const mapStateToProps = (state) => {
  const isWorkflowLoading = state.getIn(['workflowState', 'loading']);
  const jobRoles = getJobRolesWithAvailableBundles(state) || Immutable.List();
  const workflowRequestedFor = getWorkflowRequestedFor(state);

  return {
    isLoading: isWorkflowLoading,
    jobRoles,
    workflowRequestedFor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getJobRolesWithBundles(jobRoleID) {
    // Set workflow in loading state
    dispatch(workflowActions.loading());
    dispatch(jobRoleThunks.getJobRolesWithBundles())
      .then(({ jobRoles }) => {
        if (jobRoleID || _.isEmpty(jobRoles)) {
          // Set workflow exit loading state
          dispatch(workflowActions.exitLoading());
        }
      })
      .catch(() => {
        // Set workflow exit loading state even if we get an error which will stop the step here
        dispatch(workflowActions.exitLoading());
      });
  },

  updateWorkflowRequestedForUser(changes) {
    // Set workflow in loading state
    dispatch(workflowActions.loading());

    dispatch(workflowThunks.updateRequestedForUser(changes))
      .then(() => {
        dispatch(change('Form01', 'job_role_selected', 'job_selected'));
        // Set workflow exit loading state
        dispatch(workflowActions.exitLoading());
      })
      .catch(() => {
        // Set workflow exit loading state even if we got an error from the API
        dispatch(workflowActions.exitLoading());
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
