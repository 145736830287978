const api_common_v0_1_supported_languages = {
  count: 10,
  next: null,
  previous: null,
  results: [
    {
      code: 'it-it',
      eid: 'b0389622-1873-11ea-9ca7-d20010190e05',
      id: 7,
      language: 'Italian (Italy)',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2019-12-12T22:10:20.099231Z',
      sys_date_updated: '2021-01-11T18:07:00.381402Z',
      sys_updated_by: 'dadasdasdadad@rewrwerwe.com',
    },
    {
      code: 'zh-cn',
      eid: 'b08dd9f2-8c9a-4913-a5f0-5bd84b24b6cf',
      id: 10,
      language: 'Chinese (China)',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2020-02-13T01:17:18.630881Z',
      sys_date_updated: '2021-01-11T18:07:03.070691Z',
      sys_updated_by: 'dadasdasdadad@rewrwerwe.com',
    },
    {
      code: 'pt-br',
      eid: '92745282-22bc-4cf8-9c1a-1b73ea30d9cd',
      id: 12,
      language: 'Portuguese (Brazil)',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2020-06-04T16:16:02.890575Z',
      sys_date_updated: '2021-01-11T18:07:05.676780Z',
      sys_updated_by: 'dadasdasdadad@rewrwerwe.com',
    },
  ],
};

export default api_common_v0_1_supported_languages;
