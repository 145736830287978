import immutable from 'immutable';
import { change, formValueSelector } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
import getWorkflowRequestedFor from '../../selectors/getWorkflowRequestedFor';
import getCurrentUser from '../../selectors/getCurrentUser';
import getUserDataFromEntities from '../../selectors/getUserDataFromEntities';
import workflowThunks from '../../actions/workflowThunks';
import userEntitiesThunks from '../../actions/userEntitiesThunks';

const mapStateToProps = (state, ownProps) => {
  let workflowRequestedFor = immutable.Map();
  let targetRequestedFor = immutable.Map();
  const formValue = formValueSelector('Form01')(state, ownProps.id);
  if (ownProps.target === 'mentor_id' || !ownProps.target) {
    workflowRequestedFor = getWorkflowRequestedFor(state);
  } else {
    targetRequestedFor = getUserDataFromEntities(state, Number(formValue));
  }
  const currentUser = getCurrentUser(state);
  return {
    currentUser,
    formValue,
    targetRequestedFor,
    workflowRequestedFor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSelectedTarget(value, formID) {
    dispatch(change('Form01', formID, value));
  },

  getDefaultSelectedUser(mentorID) {
    dispatch(userEntitiesThunks.addUserEntity(mentorID));
  },

  /**
   * @param {Object} changes
   */
  updateWorkflowRequestedForUser(changes) {
    dispatch(workflowThunks.updateRequestedForUser(changes));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
