import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardware extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(9.5 14.5)'>
          <path d='M69 34.85V8.68c0-1.105-.895-2-2-2H29.69c.505.843.77 1.807.77 2.79v27.36h36.6c1.073-.032 1.93-.906 1.94-1.98z' />
          <circle cx='42.33' cy='42.34' r='1.92' />
          <path d='M69.3 0H18.71c-2.69.013-5.137 1.568-6.29 4h2.3c.938-1.267 2.424-2.01 4-2H69.3c2.76 0 5 2.24 5 5v35.53c0 2.76-2.24 5-5 5H30.47v2h8.6v7.46l-8.6.54v1c0 1.027-.29 2.032-.84 2.9H60v-3.5l-14.11-.94v-7.52H69.3c3.82-.033 6.907-3.12 6.94-6.94V6.94C76.207 3.12 73.12.033 69.3 0z' />
          <rect height='3.97' rx='.5' width='17.86' x='6.06' y='17.11' />
          <path d='M29.7 58.47V9.71c0-2.76-2.24-5-5-5H5c-2.76 0-5 2.24-5 5v48.76c.033 1.986 1.238 3.764 3.07 4.53v4h23.49v-3.93c1.883-.754 3.123-2.572 3.14-4.6zM5.07 17.61c0-.823.667-1.49 1.49-1.49h16.86c.823 0 1.49.667 1.49 1.49v3c0 .395-.157.774-.436 1.054-.28.28-.66.436-1.054.436H6.55c-.395 0-.774-.157-1.054-.436-.28-.28-.436-.66-.436-1.054l.01-3zm2.6 32.06h14.94c.276 0 .5.224.5.5s-.224.5-.5.5H7.67c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm-.5-4.23c0-.276.224-.5.5-.5h14.94c.276 0 .5.224.5.5s-.224.5-.5.5H7.67c-.276 0-.5-.224-.5-.5zm.5 9h14.94c.276 0 .5.224.5.5s-.224.5-.5.5H7.67c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zM25.57 66H4.06v-2.66c.297.057.598.087.9.09h19.78c.278-.003.556-.03.83-.08V66z' />
        </g>
      </svg>
    );
  }
}

export default CatalogHardware;
