import { useLayoutEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import dateUtil from '../../../../../utils/dateUtil';

// returns something like ['mon',false],['mue',false] .... I could not used esp date util because it makes fail other tests for some reason (package needs to be mocked)
/**
 * @param {string} locale
 * @return {Array<[string, boolean]>}
 */
const getDefaultWeekDays = (locale) =>
  [1, 2, 3, 4, 5, 6, 0]
    .map((weekDay) =>
      dateUtil()
        .clone()
        .locale(locale)
        .weekday(weekDay)
        .format('ddd')
        .toLocaleLowerCase()
    )
    .map((day) => [day, false]);

// returns initial vanilla js map of the weekdays
/**
 * @param {string} selectedDays
 * @return {Map<[string, boolean]>}
 */
const getDaysMap = (selectedDays) => {
  // TODO to be able to use other languages
  const newMap = new Map(getDefaultWeekDays('en'));
  if (isEmpty(selectedDays)) {
    return newMap;
  }

  selectedDays.forEach((selectedDay) => {
    newMap.set(selectedDay, true);
  });

  return newMap;
};
/**
 * @param  {Map<[string, boolean]>} selectedDaysMap
 *  @return {Array<[string]>}
 */
const getDaysFromMap = (selectedDaysMap) =>
  Array.from(selectedDaysMap.keys())
    .filter((day) => selectedDaysMap.get(day))
    .map((day) => day);

/**
 * @param {Array<string>} selectedDays
 * @return {{toggleWeekDay: (function(*=): *), weekDayMap: Map<string, boolean>}}
 */
const useScheduleWeekDay = (selectedDays = []) => {
  const [weekDayMap, setWeekDayMap] = useState(getDaysMap());

  useLayoutEffect(() => {
    setWeekDayMap(getDaysMap(selectedDays));
  }, [selectedDays]);

  const toggleWeekDay = (day) => {
    const newMap = new Map(weekDayMap.set(day, !weekDayMap.get(day)));
    setWeekDayMap(newMap);
    return getDaysFromMap(newMap);
  };

  return {
    toggleWeekDay,
    weekDayMap,
  };
};
export { getDaysMap };
export default useScheduleWeekDay;
