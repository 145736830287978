import Immutable from 'immutable';
import async from 'async';
import _ from 'lodash';

// Utils
import browserHistory from '../utils/browserHistory';
import endpointGenerator from '../utils/endpointGenerator';
import uiPathGenerator from '../utils/uiPathGenerator';
import APIcall from '../utils/APIcall';
import BlobAttributesParser from '../utils/BlobAttributesParser';

// Packages
import EspFilters from 'esp-util-filters';

// Globals
import NoAccessToWorkflowBlob from '../globals/NoAccessToWorkflowBlob';
import WorkflowStates from '../globals/WorkflowStates';
// Selector
import getCurrentUser from '../selectors/getCurrentUser';

// Thunk and action
import sessionThunks from './sessionThunks';
import sessionActions from './sessionActions';
import workflowActions from './workflowActions';
import toastNotificationsActions from './toastNotificationsActions';
import appUIActions from './appUIActions';

const ERROR_WORKFLOW_DOES_NOT_EXIST = 404;
const ERROR_DOES_NOT_HAVE_THE_BALL = 403;
const ERROR_500 = 500;

const createDoesNotHaveTheBallError = () => ({
  messages: ["Current user doesn't have access to this workflow at this time."],
  status: ERROR_DOES_NOT_HAVE_THE_BALL,
});

// TODO this should happen at APIcall level
const normalizeError = (apiError) => {
  const { response } = apiError;

  if (!response) {
    // Non regular error like 502 Bad Getaway
    return {
      message: [apiError],
      status: '', // Can't pass any status since apiError is not an object
    };
  }

  if (response.body) {
    return {
      messages: response.body,
      status: response.status,
    };
  } else {
    // this because api error response body is [String...]
    return {
      messages: [response.text],
      status: response.status,
    };
  }
};

const onboardGetEntry = (workflowID, ref, code, cb = _.noop) => {
  const url = endpointGenerator.genPath(
    'workflow.onboardWorkflows.instance.getNext',
    {
      workflowID,
    }
  );

  APIcall.get({
    error(error) {
      cb(normalizeError(error));
    },
    query: {
      code,
      ref,
    },
    success(res) {
      const workflowTask = res.body;
      cb(null, workflowTask);
    },
    url,
  });
};

const onboardSetEntry = (onboardID, payload, ref, code, cb = _.noop) => {
  const url = endpointGenerator.genPath('workflow.onboard.setEntry', {
    onboardID,
  });

  APIcall.post({
    data: payload,
    error(error) {
      cb(normalizeError(error));
    },
    preventShowError: true,
    query: {
      code,
      ref,
    },
    success(res) {
      const workflowTask = res.body;
      cb(null, workflowTask);
    },
    url,
  });
};

/**
 * @deprecated Frontend will no longer have the responsibility to create workflow requests. Backend is going to create them for use, and we should be querying for them instead.
 */
const addWorkflowRequest = (
  ownerID,
  assignedToID,
  requestedForID,
  workflowID,
  cb = _.noop
) => {
  const url = endpointGenerator.genPath('workflow.workflowRequest');

  const payload = {
    assigned_to: assignedToID,
    current_workflow: workflowID,
    other_info: null,
    owner: ownerID || assignedToID,
    requested_for: requestedForID,
    starting_workflow: workflowID,
  };

  APIcall.post({
    data: payload,
    error(error) {
      cb(normalizeError(error));
    },
    success(res) {
      const workflowRequest = res.body;
      cb(null, workflowRequest);
    },
    token: true,
    url,
  });
};

const getNewHireOnboardWorkflowRequest = (
  userID,
  workflowID,
  onNewHireOnboardWorkflowRequest = _.noop
) => {
  const url = endpointGenerator.genPath('workflow.workflowRequest');

  const espFilters = encodeURI(
    `starting_workflow__EQ=${workflowID}&state__EQ=${WorkflowStates.ACTIVE}&requested_for__EQ=${userID}&assigned_to__EQ=${userID}`
  );

  APIcall.get({
    error(error) {
      onNewHireOnboardWorkflowRequest(normalizeError(error));
    },
    query: {
      esp_filters: espFilters,
    },
    success(response) {
      const workflowRequests = response.body.results;

      if (_.isEmpty(workflowRequests)) {
        // even if the onboard workflow request always exists, current user might not have the ball!
        const doesNotHaveTheBallError = createDoesNotHaveTheBallError();
        onNewHireOnboardWorkflowRequest(doesNotHaveTheBallError);
      } else {
        const newHireOnboardWorkflowRequest = _.head(workflowRequests);
        onNewHireOnboardWorkflowRequest(null, newHireOnboardWorkflowRequest);
      }
    },
    token: true,
    url,
  });
};

const getWorkflowRequest = (workflowRequestID, cb = _.noop) => {
  const url = endpointGenerator.genPath('workflow.workflowRequest.instance', {
    workflowRequestID,
  });

  APIcall.get({
    error(error) {
      cb(normalizeError(error));
    },
    success(res) {
      const workflowRequest = res.body;
      cb(null, workflowRequest);
    },
    token: true,
    url,
  });
};

const workflowRequestGetEntry = (workflowRequestID, cb = _.noop) => {
  const url = endpointGenerator.genPath(
    'workflow.workflowRequest.instance.getEntry',
    {
      workflowRequestID,
    }
  );

  APIcall.get({
    error(error) {
      const normalizedError = normalizeError(error);
      normalizedError.status = normalizedError.status || 500; // Force status 500 if stauts is missing
      cb(normalizedError);
    },
    success(res) {
      const workflowRequest = res.body;
      cb(null, workflowRequest);
    },
    token: true,
    url,
  });
};

const workflowRequestSetEntry = (workflowRequestID, payload, cb = _.noop) => {
  const url = endpointGenerator.genPath(
    'workflow.workflowRequest.instance.setEntry',
    {
      workflowRequestID,
    }
  );

  APIcall.post({
    data: payload,
    error(error) {
      // console.log(error);
      cb(normalizeError(error));
    },
    preventShowError: true,
    success(res) {
      const workflowRequest = res.body;
      cb(null, workflowRequest);
    },
    token: true,
    url,
  });
};

const setWorkflowRequestNextTask = (workflowRequestID, cb = _.noop) => {
  const url = endpointGenerator.genPath(
    'workflow.workflowRequest.instance.setNextTask',
    {
      workflowRequestID,
    }
  );

  APIcall.post({
    error(error) {
      cb(normalizeError(error));
    },
    success(res) {
      const workflowRequest = res.body;
      cb(null, workflowRequest);
    },
    token: true,
    url,
  });
};

const setWorkflowRequestPrevTask = (workflowRequestID, cb = _.noop) => {
  const url = endpointGenerator.genPath(
    'workflow.workflowRequest.instance.setPrevTask',
    {
      workflowRequestID,
    }
  );

  APIcall.post({
    error(error) {
      cb(normalizeError(error));
    },
    success(res) {
      const workflowRequest = res.body;
      cb(null, workflowRequest);
    },
    token: true,
    url,
  });
};

const getUser = (userID, onUser = _.noop) => {
  const url = endpointGenerator.genPath('espUser.users.instance', {
    userID,
  });

  APIcall.get({
    error(error) {
      onUser(normalizeError(error));
    },
    success(res) {
      const user = res.body;
      onUser(null, user);
    },
    token: true,
    url,
  });
};

const getWorkflowByEID = (workflowEID) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('workflow.workflows');
    const espFilters = encodeURI(`eid__EQ=${workflowEID}`);

    APIcall.get({
      error(error) {
        reject(normalizeError(error));
      },
      query: {
        esp_filters: espFilters,
      },
      success(response) {
        const { results } = response.body;

        if (_.isEmpty(results)) {
          const notFoundError = {
            messages: [`Workflow with EID ${workflowEID} doesn't exist.`],
            status: 404,
          };

          reject(notFoundError);
        } else {
          const workflow = _.first(results);
          resolve(workflow);
        }
      },
      token: true,
      url,
    });
  });

const workflowThunks = {};

workflowThunks.getOnboardLoginActivity =
  (workflowID, ref, code) => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(workflowActions.loading());

      onboardGetEntry(workflowID, ref, code, (error, workflowTask) => {
        if (error || !workflowTask) {
          reject(error);
          return;
        }

        const onboardID = workflowTask.id;

        dispatch(
          workflowActions.loginDone(
            workflowTask,
            workflowID,
            onboardID,
            ref,
            code
          )
        );

        resolve(workflowTask);
      });
    });

workflowThunks.onboardLogin =
  (values, cb = _.noop) =>
  (dispatch, getState) => {
    const state = getState();
    const workflowState = state.get('workflowState');

    const workflowID = workflowState.getIn(['sessionId', 'workflowID']);
    const onboardID = workflowState.getIn(['sessionId', 'onboardID']);
    const ref = workflowState.getIn(['sessionId', 'ref']);
    const code = workflowState.getIn(['sessionId', 'code']);

    const isSelfRegistration = !ref && !code;

    let attributes = workflowState.get('attributes');

    const blob = workflowState.get('blob');
    const blocks = blob.get('blocks');

    const attributesParser = new BlobAttributesParser(attributes);
    attributes = attributesParser.updateFromBlockValues(blocks, values);

    const payload = Immutable.Map()
      .setIn(['methods', '0', 'name'], 'login')
      .setIn(['methods', '0', 'return_value'], '')
      .merge(attributes)
      .toJS();

    // in the case where we don't have ref and code (self registration)
    // we're overriding the hardcoded "methods" and sending whatever we received from the payload
    if (isSelfRegistration) {
      // *sight* payload is not an immutable object, is a plain JS object...
      // Rather than fix this problem and cause god knows how many issues, i'm just gonna use it as it is
      payload.methods = workflowState.get('payload').methods;
    }

    dispatch(workflowActions.loading());

    async.waterfall(
      [
        // 1. Submit onboard password to get api token
        (next) => {
          onboardSetEntry(
            onboardID,
            payload,
            ref,
            code,
            (error, workflowTask) => {
              if (error) {
                next(error);
              } else {
                // Token :accessToken
                const auth = _.get(workflowTask, 'methods.0.return_value');
                const authParts = auth.split(' ');

                const [, accessToken] = authParts;
                dispatch(sessionActions.setToken(accessToken));

                next(null);
              }
            }
          );
        },

        // 2. Get current user
        (next) => {
          dispatch(sessionThunks.whoami())
            .then(() => {
              next(null);
            })
            .catch((error) => {
              next(error);
            });
        },

        // 3. Query workflow request for onboard
        (next) => {
          const currentUser = getCurrentUser(getState());
          const userID = currentUser.get('id');

          if (isSelfRegistration) {
            const managerUrl = currentUser.get('manager');
            const managerUrlParts = managerUrl ? managerUrl.split('/') : null;
            const managerID = managerUrl
              ? Number(managerUrlParts[managerUrlParts.length - 2])
              : null;

            addWorkflowRequest(
              managerID,
              userID,
              userID,
              workflowID,
              (error, workflowRequest) => {
                if (error) {
                  next(error);
                } else {
                  next(null, workflowRequest);
                }
              }
            );
          } else {
            getNewHireOnboardWorkflowRequest(userID, workflowID, next);
          }
        },

        // 4. Check if calling set_next_task is required
        (workflowRequest, next) => {
          const workflowRequestID = workflowRequest.id;

          const isSetNextTaskRequired = !workflowRequest.current_workflowtask;

          if (isSetNextTaskRequired) {
            // by calling set_next_task on the workflow request, backend
            // returns what we should display as the next task, no additional checks are
            // needded from our side
            setWorkflowRequestNextTask(
              workflowRequestID,
              (error, workflowRequest) => {
                if (error) {
                  next(error);
                } else {
                  const nextWorkflowID = workflowRequest.current_workflow;
                  const nextWorkflowTaskID =
                    workflowRequest.current_workflowtask.id;

                  next(
                    null,
                    nextWorkflowID,
                    nextWorkflowTaskID,
                    workflowRequestID
                  );
                }
              }
            );
          } else {
            // we are good, workflow request is pointing to a valid workflow task already
            const workflowID = workflowRequest.current_workflow;
            const workflowTaskID = workflowRequest.current_workflowtask.id;

            next(null, workflowID, workflowTaskID, workflowRequestID);
          }
        },
      ],
      (error, nextWorkflowID, nextWorkflowTaskID, workflowRequestID) => {
        if (error) {
          if (error.status === ERROR_DOES_NOT_HAVE_THE_BALL) {
            const logoutUrl = uiPathGenerator.genPath('logout');
            browserHistory.replace(logoutUrl);
          }

          dispatch(workflowActions.setBlockErrors(error));
          cb(error);
        } else {
          // Move forward to /ob route to continue onboard as a logged in user
          const nextOnboardStepUrl = uiPathGenerator.genPath('onboardStep', {
            requestID: workflowRequestID,
            taskID: nextWorkflowTaskID,
            workflowID: nextWorkflowID,
          });

          browserHistory.replace(nextOnboardStepUrl);
          cb();
        }
      }
    );
  };

// TODO Used on existing employee onboard workflow, this is likely to be removed later
/** @deprecated */
workflowThunks.onboardAlternateLogin = (values) => (dispatch, getState) => {
  async.waterfall(
    [
      (next) => {
        dispatch(sessionThunks.userLogin(values))
          .then(() => next(null))
          .catch((error) => next(error));
      },
      (next) => {
        const state = getState();
        const workflowState = state.get('workflowState');
        const workflowID = workflowState.getIn(['sessionId', 'workflowID']);
        const currentUser = getCurrentUser(getState());
        const userID = currentUser.get('id');
        const managerUrl = currentUser.get('manager');
        const managerUrlParts = managerUrl ? managerUrl.split('/') : null;
        const managerID = managerUrl
          ? Number(managerUrlParts[managerUrlParts.length - 2])
          : null;

        addWorkflowRequest(
          managerID,
          userID,
          userID,
          workflowID,
          (error, workflowRequest) => {
            if (error) {
              next(error);
            } else {
              next(null, workflowRequest);
            }
          }
        );
      },
      (workflowRequest, next) => {
        setWorkflowRequestNextTask(
          workflowRequest.id,
          (error, workflowRequest) => {
            if (error) {
              next(error);
            } else {
              next(null, workflowRequest);
            }
          }
        );
      },
      (workflowRequest, next) => {
        const workflowRequestID = workflowRequest.id;

        setWorkflowRequestNextTask(
          workflowRequestID,
          (error, workflowRequest) => {
            if (error) {
              next(error);
            } else {
              const workflowRequestID = workflowRequest.id;
              const nextWorkflowID = workflowRequest.current_workflow;
              const nextWorkflowTaskID =
                workflowRequest.current_workflowtask.id;

              next(null, nextWorkflowID, nextWorkflowTaskID, workflowRequestID);
            }
          }
        );
      },
    ],
    (error, nextWorkflowID, nextWorkflowTaskID, workflowRequestID) => {
      if (error) {
        dispatch(workflowActions.setBlockErrors(error));
      } else {
        const nextOnboardStepUrl = uiPathGenerator.genPath('onboardStep', {
          requestID: workflowRequestID,
          taskID: nextWorkflowTaskID,
          workflowID: nextWorkflowID,
        });

        browserHistory.replace(nextOnboardStepUrl);
      }
    }
  );
};

workflowThunks.redirectToOnboardWorkflow =
  (workflowID) => (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    const userID = currentUser.get('id');

    getNewHireOnboardWorkflowRequest(
      userID,
      workflowID,
      (error, workflowRequest) => {
        let redirectTo;

        if (error) {
          redirectTo = uiPathGenerator.genPath('logout');
        } else {
          const taskID = workflowRequest.current_workflowtask.id;
          const requestID = workflowRequest.id;

          redirectTo = uiPathGenerator.genPath('onboardStep', {
            requestID,
            taskID,
            workflowID,
          });
        }

        browserHistory.replace(redirectTo);
      }
    );
  };

workflowThunks.resumeWorkflow =
  (workflowRequestID, cb = _.noop) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const currentUser = getCurrentUser(state);

      dispatch(workflowActions.overLoading());
      async.waterfall(
        [
          // 1. Validate access to workflow
          (next) => {
            getWorkflowRequest(workflowRequestID, (error, workflowRequest) => {
              if (error) {
                next(error);
              } else {
                const assignedTo = workflowRequest?.assigned_to;
                const isAssignedToMe = assignedTo === currentUser.get('id');

                if (isAssignedToMe) {
                  // no problem, let's move forward
                  next(null);
                } else {
                  // sorry, you can't go ahead, current user doesn't have the ball!

                  // artificial api error
                  const doesNotHaveTheBallError =
                    createDoesNotHaveTheBallError();

                  next(doesNotHaveTheBallError);
                }
              }
            });
          },

          // 2. Get the workflow request entry
          (next) => {
            workflowRequestGetEntry(workflowRequestID, next);
          },

          // 3. Call set_next_task on workflow request if hasn't been performed before
          (workflowRequest, next) => {
            const otherInf = workflowRequest.other_info;
            let latestWorkflow = null,
              newHistory = [];
            if (otherInf && otherInf.history && otherInf.history.length > 0) {
              newHistory = otherInf.history;
              latestWorkflow =
                _.last(newHistory).length > 0
                  ? _.last(newHistory)[0]
                  : newHistory[newHistory.length - 2][0];
            }
            if (
              newHistory.length > 0 &&
              latestWorkflow &&
              latestWorkflow !== workflowRequest.current_workflow
            ) {
              setWorkflowRequestNextTask(workflowRequestID, next);
            } else if (!otherInf || !otherInf.history) {
              setWorkflowRequestNextTask(workflowRequestID, next);
            } else {
              // use the workflow request as is
              next(null, workflowRequest);
            }
          },

          // 4. Get requested_for user if set
          (workflowRequest, next) => {
            const requestedForUserID = workflowRequest.requested_for;

            if (requestedForUserID) {
              getUser(requestedForUserID, (error, requestedForUser) => {
                if (error) {
                  next(error, workflowRequest);
                } else {
                  next(null, workflowRequest, requestedForUser);
                }
              });
            } else {
              next(null, workflowRequest, null);
            }
          },
        ],
        (error, workflowRequest, requestedForUser) => {
          const workflowId = workflowRequest
            ? workflowRequest.current_workflow
            : null;
          const workflowTaskId =
            workflowRequest && workflowRequest.current_workflowtask
              ? workflowRequest.current_workflowtask.id
              : null;

          if (error) {
            // TODO Api should return 403 when the workflow exists but current doesn't have the ball, instead 404, which means it doesn't exist at all
            if (
              error.status === ERROR_WORKFLOW_DOES_NOT_EXIST ||
              error.status === ERROR_DOES_NOT_HAVE_THE_BALL ||
              error.status === ERROR_500
            ) {
              dispatch(
                workflowActions.resumeWorkflowFail(NoAccessToWorkflowBlob)
              );
              cb(error, workflowId, workflowTaskId);
            } else {
              // TODO handle unknown error
            }

            reject(error);
          }
          // should handle case when we for whatever reason load an archived workflow request
          else if (workflowRequest.state === WorkflowStates.ARCHIVED) {
            dispatch(
              workflowActions.resumeWorkflowFail(NoAccessToWorkflowBlob)
            );
            cb(error, workflowId, workflowTaskId);
          } else {
            dispatch(
              workflowActions.resumeWorkflowSuccess(
                workflowRequest,
                requestedForUser
              )
            );
            resolve(workflowTaskId);
            cb(null, workflowId, workflowTaskId);
          }
        }
      );
    });

workflowThunks.skipCurrentTask = (requestId) => (dispatch) =>
  new Promise((resolve, reject) => {
    workflowRequestSetEntry(requestId, null, (error) => {
      if (error) {
        reject(error);
      } else {
        dispatch(
          workflowThunks.moveNextWorkflowTask(
            requestId,
            (error, workflowRequest) => {
              if (error) {
                reject(error);
              } else {
                resolve(workflowRequest);
              }
            }
          )
        );
      }
    });
  });

workflowThunks.moveNextWorkflowTask =
  (requestId, cb = _.noop) =>
  (dispatch, getState) => {
    const state = getState();

    const workflowState = state.get('workflowState');
    const workflowRequestID =
      requestId || workflowState.getIn(['sessionId', 'workflowRequestID']);

    dispatch(workflowActions.loading());

    setWorkflowRequestNextTask(workflowRequestID, (error, workflowRequest) => {
      if (error) {
        // TODO Handle error
        // console.log(error);
      } else {
        // TODO just to see how UI updates, should redirect to next task url
        dispatch(workflowActions.resumeWorkflowSuccess(workflowRequest));
      }

      cb(error, workflowRequest);
    });
  };

workflowThunks.movePrevWorkflowTask =
  (cb = _.noop) =>
  (dispatch, getState) => {
    const state = getState();

    const workflowState = state.get('workflowState');
    const workflowRequestID = workflowState.getIn([
      'sessionId',
      'workflowRequestID',
    ]);

    dispatch(workflowActions.loading());

    setWorkflowRequestPrevTask(workflowRequestID, (error, workflowRequest) => {
      if (error) {
        // TODO Handle error
        // console.log(error);
      } else {
        // TODO just to see how UI updates, should redirect to next task url
        dispatch(workflowActions.resumeWorkflowSuccess(workflowRequest));
      }

      cb(error, workflowRequest);
    });
  };

workflowThunks.saveAttributes =
  (values, onSaveAttributes = _.noop) =>
  (dispatch, getState) => {
    const state = getState();
    const workflowState = state.get('workflowState');

    const workflowRequestID = workflowState.getIn([
      'sessionId',
      'workflowRequestID',
    ]);

    let attributes = workflowState.get('attributes');
    const blob = workflowState.get('blob');
    const blocks = blob.get('blocks');

    const attributesParser = new BlobAttributesParser(attributes);
    attributes = attributesParser.updateFromBlockValues(blocks, values);

    const payload = Immutable.Map()
      .set('current_workflowtask', attributes)
      .toJS();

    dispatch(workflowActions.loading());

    workflowRequestSetEntry(
      workflowRequestID,
      payload,
      (error, workflowRequest) => {
        dispatch(workflowActions.setEntrySuccess()); // Set setEntryDone to true

        if (error) {
          dispatch(workflowActions.setBlockErrors(error));
        }

        onSaveAttributes(error, workflowRequest);
      }
    );
  };

workflowThunks.saveToFrontEndScratch =
  (data, onSaveCb = _.noop, setNext) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const wkRequestId = state.getIn([
        'workflowState',
        'sessionId',
        'workflowRequestID',
      ]);

      dispatch(workflowActions.saveScratchStart());

      if (wkRequestId) {
        const endPoint = endpointGenerator.genPath(
          'workflow.workflowRequest.instance',
          {
            workflowRequestID: wkRequestId,
          }
        );

        APIcall.put({
          data: {
            frontend_scratch: data,
          },
          error(error) {
            dispatch(workflowActions.saveScratchFail(error));
            onSaveCb(null, error);
            reject();
          },
          success(res) {
            const workflowRequest = res.body;

            dispatch(
              workflowActions.saveScratchSuccess(
                workflowRequest.frontend_scratch
              )
            );

            if (setNext) {
              dispatch(workflowActions.resumeWorkflowSuccess(workflowRequest));
            }

            onSaveCb(res.body);
            resolve();
          },
          token: true,
          url: endPoint,
        });
      } else {
        dispatch(workflowActions.saveScratchFail('No workflow request id'));
      }
    });

/**
 * Save to BE scratch
 * @param data
 * @param onSaveCb
 * @param setNext
 * @returns {function(*=, *): Promise<any>}
 */
workflowThunks.saveToBackEndScratch = (data, setNext) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const wkRequestId = state.getIn([
      'workflowState',
      'sessionId',
      'workflowRequestID',
    ]);

    dispatch(workflowActions.saveBEScratchStart());

    if (wkRequestId) {
      const endPoint = endpointGenerator.genPath(
        'workflow.workflowRequest.instance',
        {
          workflowRequestID: wkRequestId,
        }
      );

      APIcall.put({
        data: {
          backend_scratch: data,
        },
        error(error) {
          dispatch(workflowActions.saveBEScratchFail(error));
          reject(error);
        },
        success(res) {
          const workflowRequest = res.body;

          dispatch(
            workflowActions.saveBEScratchSuccess(
              workflowRequest.backend_scratch
            )
          );

          if (setNext) {
            dispatch(workflowActions.resumeWorkflowSuccess(workflowRequest));
          }
          resolve(workflowRequest);
        },
        token: true,
        url: endPoint,
      });
    } else {
      dispatch(workflowActions.saveScratchFail('No workflow request id'));
    }
  });

/**
 * Update the current Workflow BE scratch data
 * @param data
 * @param onSaveCb
 * @param setNext
 */
workflowThunks.updateTempDataBackEndScratch =
  (data, setNext) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      let backendScratch =
        state.getIn(['workflowState', 'backendScratch', 'scratch.temp_data']) ||
        Immutable.Map();

      for (const key in data) {
        backendScratch = backendScratch.set(key, data[key]);
      }

      dispatch(
        workflowThunks.saveToBackEndScratch(
          {
            'scratch.temp_data': backendScratch.toJS(),
          },
          setNext
        )
      )
        .then((workflowRequest) => {
          resolve(workflowRequest);
        })
        .catch((err) => {
          reject(err);
        });
    });

/**
 * Update the current Workflow FrontendScratch data
 * @param data
 * @param onSaveCb
 * @param setNext
 */
workflowThunks.updateFrontEndScratch =
  (data, onSaveCb = _.noop, setNext) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      let frontEndScratch =
        state.getIn(['workflowState', 'frontendScratch']) || Immutable.Map();

      for (const key in data) {
        frontEndScratch = frontEndScratch.set(key, data[key]);
      }

      dispatch(
        workflowThunks.saveToFrontEndScratch(
          frontEndScratch.toJS(),
          onSaveCb,
          setNext
        )
      )
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });

/**
 * Updates the user referenced by workflowRequest.requestedFor.
 *
 * @param {Object} changes
 */
workflowThunks.updateRequestedForUser = (changes) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const workflowState = state.get('workflowState');

    /** @type {number} */
    const userRequestedFor = workflowState.getIn([
      'sessionId',
      'userRequestedFor',
    ]);

    /** @type {string} */
    const url = endpointGenerator.genPath('espUser.users.instance', {
      userID: userRequestedFor,
    });

    dispatch(workflowActions.loading());

    async.waterfall(
      [
        // 1. Get user referenced by workflowRequest.requestedFor
        (next) => {
          APIcall.get({
            error(err) {
              next(err);
            },
            success(response) {
              const requestedForUser = response.body;
              next(null, requestedForUser);
            },
            token: true,
            url,
          });
        },

        // 2. Update user referenced by workflowRequest.requestedFor with provided 'changes'
        (requestedForUser, next) => {
          // set required values
          let payload = {
            email: requestedForUser.email,
            first_name: requestedForUser.first_name,
            last_name: requestedForUser.last_name,
          };

          if (requestedForUser.secondary_email) {
            payload.secondary_email = requestedForUser.secondary_email;
          }

          // overwrite with changes
          payload = Object.assign(payload, changes);

          APIcall.patch({
            data: payload,
            error(err) {
              next(err);
            },
            success(response) {
              const requestedForUser = response.body;
              next(null, requestedForUser);
            },
            token: true,
            url,
          });
        },
      ],
      (error, requestedForUser) => {
        if (!error) {
          dispatch(
            workflowActions.updateRequestedForUserSuccess(requestedForUser)
          );
          resolve(requestedForUser);
        } else {
          dispatch(workflowActions.exitLoading());
          reject(error);
        }
      }
    );
  });

workflowThunks.mapEIDToID = (workflowEID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const workflowState = state.get('workflowState');
    const eidToID = workflowState.get('eidToID');

    if (eidToID.has(workflowEID)) {
      const workflowID = eidToID.get(workflowEID);
      resolve(workflowID);
    } else {
      getWorkflowByEID(workflowEID)
        .then((workflow) => {
          const workflowID = workflow.id;

          dispatch(workflowActions.addEIDToIDMapping(workflowEID, workflowID));
          resolve(workflowID);
        })
        .catch((error) => {
          // throw new Error(`Unable to get ID for workflow with EID ${workflowEID}. This is likely to be a data issue, make sure such workflow exists on this tenant.`, error);
          reject(error);
        });
    }
  });

workflowThunks.addWorkflowRequest = (workflowRequestSpec) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('workflow.workflowRequest');

    const payload = {
      assigned_to: workflowRequestSpec?.assignedTo,
      current_workflow: workflowRequestSpec?.currentWorkflow,
      owner: workflowRequestSpec?.owner,
      requested_for: workflowRequestSpec?.requestedFor,
      starting_workflow: workflowRequestSpec?.startingWorkflow,
    };

    APIcall.post({
      data: payload,
      error(error) {
        reject(normalizeError(error));
      },
      success(response) {
        const workflowRequest = response.body;
        resolve(workflowRequest);
      },
      token: true,
      url,
    });
  });

workflowThunks.setBlockError = (error) => (dispatch, getState) => {
  const errors = getState().getIn(['workflowState', 'errors']);
  dispatch(workflowActions.setBlockErrors(errors.set(error.id, error.msg)));
};

workflowThunks.sendOTP =
  (method, cb = _.noop) =>
  (dispatch) => {
    dispatch(workflowActions.otpStart());
    const apiUrl = endpointGenerator.genPath('espUser.users.sendOtp');

    APIcall.post({
      data: {
        method,
      },
      error: function (err) {
        dispatch(workflowActions.otpError(err));
        cb(null, err);
      },
      success: function (res) {
        dispatch(workflowActions.otpSuccess(res.body));
        dispatch(
          workflowThunks.saveToFrontEndScratch(
            {
              method,
            },
            cb
          )
        );
      },
      token: true,
      url: apiUrl,
    });
  };

workflowThunks.validateOTP =
  (code, cb = _.noop) =>
  (dispatch) => {
    const apiUrl = endpointGenerator.genPath('espUser.users.verifyOtp');
    dispatch(workflowActions.otpStart());

    APIcall.post({
      data: {
        code,
      },
      error: function (err) {
        dispatch(workflowActions.otpError(err));
        cb(null, err);
      },
      success: function (res) {
        dispatch(workflowActions.otpSuccess(res.body));
        cb(res.body);
      },
      token: true,
      url: apiUrl,
    });
  };

/**
 * Create and launch a WF from an EID with the current logged USER.
 * @param workflowEID {String} - EID of the workflow that we want to launch
 * @returns {function(*=, *): Promise<any>}
 */
workflowThunks.createWorkflowRequestID =
  (workflowEID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const currentUser = getCurrentUser(getState());
      const userID = currentUser.get('id');

      dispatch(appUIActions.launchWorkflowStart());

      dispatch(workflowThunks.mapEIDToID(workflowEID))
        .then((workflowID) =>
          dispatch(
            workflowThunks.addWorkflowRequest({
              assignedTo: userID,
              currentWorkflow: workflowID,
              owner: userID,
              requestedFor: userID,
              startingWorkflow: workflowID,
            })
          )
        )
        .then((workflowRequest) => {
          dispatch(appUIActions.openWorkflowModal(workflowRequest.id)); // Open the workflow
          resolve();
        })
        .catch((err) => {
          dispatch(
            toastNotificationsActions.error({
              message: `The Workflow EID ${workflowEID} doesn't exist`,
              title: 'Error',
            })
          );
          reject(err);
        });
    });

workflowThunks.getWorkflowRequestByIdForCurrentUser =
  (workflowId) => (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const userID = currentUser.get('id');

    const url = endpointGenerator.genPath('workflow.workflowRequest');

    const espFilters = new EspFilters()
      .equalTo('starting_workflow', workflowId)
      .equalTo('requested_for', userID)
      .asQueryString();

    return APIcall.get({
      query: {
        esp_filters: espFilters,
      },
      token: true,
      url,
    });
  };

workflowThunks.getArchivedWorkflowRequests = (workflowId) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('workflow.workflowRequest');

    const espFilters = new EspFilters()
      .equalTo('starting_workflow.id', workflowId)
      .equalTo('state', 'ARCHIVED')
      .asQueryString();

    return APIcall.get({
      preventKickOut: true,
      // to not be logged out on 401 HTTP error
      preventShowError: true,

      // Dont show Notification if something bad happens as its expected
      query: {
        esp_filters: espFilters,
      },

      token: true,
      url,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

export { getWorkflowByEID };

export default workflowThunks;
