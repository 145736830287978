import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  // Dimmer,
  Divider,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import { noop } from 'lodash';
import { intl } from 'esp-util-intl';
import { Waypoint } from 'react-waypoint';

// Controller
import UserSelectController from '../controllers/UserSelectController';
// Utils
import DirectoryFilters from '../../utils/DirectoryFilters';
// global
import { loadingDirectory } from '../../globals/LoadingState';
// Atoms
import ScrollArea from '../atoms/ScrollArea';

// Molecules
import SelectableUsers from './SelectableUsers';

// Display
import ListFilter from '../../../v2/components/display/ListFilter';

class UserSelector extends PureComponent {
  static propTypes = {
    defaultSelectedUser: ImmutablePropTypes.map,
    directoryUserGroupsByLetter: ImmutablePropTypes.listOf(
      ImmutablePropTypes.map
    ),
    excludeIds: PropTypes.arrayOf(PropTypes.number),
    filterBy: PropTypes.string.isRequired,
    getDirectoryUsers: PropTypes.func,
    getNextDirectoryUsers: PropTypes.func,

    isLoading: PropTypes.bool,
    onUserSelect: PropTypes.func, // From component props
    pagination: ImmutablePropTypes.map.isRequired,
    resetDirectoryOptions: PropTypes.func,
    setFilterUsersBy: PropTypes.func,
  };

  static defaultProps = {
    defaultSelectedUser: null,
    directoryUserGroupsByLetter: null,
    excludeIds: [],
    getDirectoryUsers: noop,
    getNextDirectoryUsers: noop,
    isLoading: false,
    onUserSelect: noop,
    resetDirectoryOptions: noop,
    setFilterUsersBy: noop,
  };

  state = {
    selectedUser: null,
  };

  componentDidMount() {
    const { resetDirectoryOptions, getDirectoryUsers, setFilterUsersBy } =
      this.props;

    resetDirectoryOptions();

    setFilterUsersBy(DirectoryFilters.MY_TEAM);

    getDirectoryUsers();
  }

  handleFilterChange = (e, data) => {
    const { getDirectoryUsers, setFilterUsersBy, filterBy } = this.props;

    const newFilterBy = data.value;

    if (newFilterBy !== filterBy) {
      setFilterUsersBy(newFilterBy);
      getDirectoryUsers();
    }
  };

  handleBottomWaypointEnter = () => {
    const { getNextDirectoryUsers } = this.props;
    getNextDirectoryUsers();
  };

  handleSelectUser = (user) => {
    const { onUserSelect } = this.props;

    this.setState({
      selectedUser: user,
    });

    onUserSelect(user);
  };

  render() {
    const {
      defaultSelectedUser,
      directoryUserGroupsByLetter,
      excludeIds,
      filterBy,

      isLoading,
      pagination,
    } = this.props;

    let { selectedUser } = this.state;

    if (!selectedUser) {
      selectedUser = defaultSelectedUser;
    }

    const filterByOptions = [
      {
        checked: filterBy === DirectoryFilters.MY_TEAM,
        text: intl.formatMessage({
          id: 'label.my_team',
        }),
        value: DirectoryFilters.MY_TEAM,
      },
      {
        checked: filterBy === DirectoryFilters.MY_FAVORITES,
        text: intl.formatMessage({
          id: 'label.my_favorites',
        }),
        value: DirectoryFilters.MY_FAVORITES,
      },
    ];

    const selectedFilterByOption = filterByOptions.find(
      (option) => option.checked
    );

    let filterLabel = 'Filters';

    if (selectedFilterByOption) {
      filterLabel = selectedFilterByOption.text;
    }

    const listFilterOptions = [
      {
        key: 'filterBy',
        onChange: this.handleFilterChange,
        options: filterByOptions,
        type: 'radio',
      },
    ];

    const loadingState = isLoading && !directoryUserGroupsByLetter;

    const directoryList = loadingState
      ? loadingDirectory
      : directoryUserGroupsByLetter;

    return (
      <div className='user-select'>
        <ListFilter filters={listFilterOptions} label={filterLabel} />

        <ScrollArea>
          {directoryList &&
            directoryList.map((group) => {
              const letter = group.get('letter');
              let users = group.get('users');
              if (excludeIds.length > 0) {
                // Filter out users not in the exclude list
                users = users.filterNot(
                  (user) => excludeIds.indexOf(user.get('id')) > -1
                );
              }
              if (users.isEmpty()) {
                return null;
              }
              return [
                <Divider horizontal key={`divider-${letter}`}>
                  {letter}
                </Divider>,

                <SelectableUsers
                  isLoading={loadingState}
                  key={`users-${letter}`}
                  onSelectUser={this.handleSelectUser}
                  selectedUser={selectedUser}
                  users={users}
                />,
              ];
            })}

          {pagination.get('next') ? (
            <Waypoint
              key={pagination.get('next')}
              onEnter={this.handleBottomWaypointEnter}
            />
          ) : null}

          {pagination.get('next') ? (
            <Message icon>
              <Icon loading name='circle notched' />
              <Message.Content>
                {intl.formatMessage({
                  id: 'label.loading_more',
                })}
              </Message.Content>
            </Message>
          ) : null}

          {pagination.get('next') && <Loader active inline='centered' />}
        </ScrollArea>
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
UserSelector = UserSelectController(UserSelector);

export default UserSelector;
