import { matchPath } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { forOwn } from 'lodash';
import browserHistory from './browserHistory';
import { APIRoutes, Routes, WebSocketsPattern } from '../globals/APIRoutes';

const formatPattern = (pattern, params) => {
  const evaluablePattern = pathToRegexp.compile(pattern);
  return evaluablePattern(params);
};

const getParams = (pattern, path) => {
  const match = matchPath(path, {
    path: pattern,
  });

  return match ? match.params : {};
};

const sanitizeURL = (phrase = '') => {
  // This function and REGEXP select '+' symbols used as spaceS at the URls
  // it means the + symbol between two words.
  // why+do+you+hate+me will be decode as `why do you hate me`
  // and hi+my+amiga++ will be decode as `hi my amiga++`
  //
  return decodeURI(phrase).replace(/\+(?=\w)/gi, ' ');
};

/** @deprecated PathGenerator based tools should be used instead. */
const URLUtil = {};
/**
 * Generates a React Route type of pattern based on the structure
 * declared in the Routes object
 * @param  { String } routename [Name of the key in the Routes object]
 * @return { String } [React router pattern]
 */

URLUtil._getPathName = () => {
  const currentLocation = browserHistory && browserHistory.getCurrentLocation();
  const pathname = currentLocation ? currentLocation.pathname : '/';

  return pathname;
};

/** @deprecated Use uiPathGenerator instead. */
URLUtil.getRoutePattern = (routeName) => {
  if (Routes[routeName]) {
    return Routes[routeName].pattern;
  } else {
    // Finding the current component by matching the current path
    // and seeing to which pattern corresponds
    // We can prpbably improve this, as our current comparison is based on a patterned
    // route url without parameters
    const pathname = URLUtil._getPathName();

    for (const name in Routes) {
      if (Object.prototype.hasOwnProperty.call(Routes, name)) {
        // Having issues with tests using browsify if i use for...of loop
        const currentPattern = Routes[name].pattern;
        const formattedPatternWithoutParameters = formatPattern(
          currentPattern,
          {}
        );

        if (pathname.includes(formattedPatternWithoutParameters)) {
          return Routes[name].pattern;
        }
      }
    }
  }

  return '';
};

/**
 * Creates a new url under the route pattern
 * replacing all the parameters with the new specified params
 * @param  { Object } params  [Map with params and values]
 * @param  { String } routeName  [Name of the route to use, defaults to current route based on url location]
 * @return { String } [String of the URL formatted to the Route pattern]
 */
URLUtil.createUrl = (params, routeName = '') => {
  const pattern = URLUtil.getRoutePattern(routeName);
  return formatPattern(pattern, params);
};

/**
 * Similar to createUrl, but preserves all the the params in URL except for those specified in params
 * @param  { Object } params  [Map with params and values]
 * @param  { String } routeName  [Name of the route to use, defaults to current route based on url location]
 * @return { String } [String of the URL formatted to the Route pattern]
 */
URLUtil.createUrlReplacingParams = (params, routeName = '') => {
  const pattern = URLUtil.getRoutePattern(routeName);
  const pathname = URLUtil._getPathName();
  const oldParams = getParams(pattern, pathname);
  const newParams = Object.assign({}, oldParams, params);

  return formatPattern(pattern, newParams);
};

//
// Return apiendpoint
// @see lib/js/globals/Routes.js
// @param { string }
// @return { String }
// @deprecated Use endpointGenerator instead.
//
URLUtil.getEndpoint = (endpointName, suffix = '') => {
  if (endpointName === 'onboard' && window.JSON_ONLY) {
    return '/json/';
  }
  return `${APIRoutes.root}${APIRoutes.endpoints[endpointName]}${suffix}`;
};

//
// Return websocket tenant websocket url
// @see lib/js/globals/Routes.js
// @param { string }
// @return { String }
//
URLUtil.getTenantWebsocketUrl = (tenant = 'tenant1') =>
  WebSocketsPattern.replace('{tenant}', tenant);

URLUtil.getQRParamsFromQueryString = (queryString = '') => {
  const queryParams = {};
  const regex = new RegExp('([^?=&]+)(=([^&]*))?', 'g');

  // queryString is the string got as an argument in getQRParamsFromQueryString
  queryString.replace(regex, (...arg) => {
    const secondItemIndex = 1;
    const fourItemIndex = 3;
    queryParams[arg[secondItemIndex]] = arg[fourItemIndex];
  });

  const qRCodeParams = {};
  // those are needed
  qRCodeParams.phrase = queryParams.phrase
    ? sanitizeURL(queryParams.phrase)
    : null;
  qRCodeParams.submit = queryParams.submit || null;
  if (queryParams.kbSource) {
    // using 'if' statement because i don't want an undefined or null as value
    qRCodeParams.kbSource = queryParams.kbSource;
  }
  if (queryParams.kbId) {
    qRCodeParams.kbId = queryParams.kbId;
  }

  // delete these params now as we want to ignore them
  delete queryParams.phrase;
  delete queryParams.submit;
  delete queryParams.kbSource;
  delete queryParams.kbId;

  // extracting all other params as array
  const otherParamsArray = [];
  forOwn(queryParams, (value, key) => {
    const paramValue = sanitizeURL(value);
    otherParamsArray.push({
      [key]: paramValue,
    });
  });

  qRCodeParams.params = otherParamsArray;

  return qRCodeParams;
};

URLUtil.getParameterByName = (paramName = '', url = '') => {
  url = decodeURIComponent(url);
  paramName = paramName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default URLUtil;
