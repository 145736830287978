const _SortFieldNames = {
  NAME: 'name',
  NEGATIVE_NAME: '-name',
  NEGATIVE_STATUS: '-status',
  NEGATIVE_SYS_DATE_UPDATED: '-sys_date_updated',
  STATUS: 'status',
  SYS_DATE_UPDATED: 'sys_date_updated',
};

const SortActivityStateOptions = (intl) => [
  {
    text: intl.formatMessage({
      id: 'label.date_updated_newest',
    }),
    value: _SortFieldNames.NEGATIVE_SYS_DATE_UPDATED,
  },
  {
    text: intl.formatMessage({
      id: 'label.date_updated_oldest',
    }),
    value: _SortFieldNames.SYS_DATE_UPDATED,
  },
  {
    text: intl.formatMessage({
      id: 'label.status_A_Z',
    }),
    value: _SortFieldNames.STATUS,
  },
  {
    text: intl.formatMessage({
      id: 'label.status_Z_A',
    }),
    value: _SortFieldNames.NEGATIVE_STATUS,
  },
  {
    text: intl.formatMessage({
      id: 'label.schedule_name_A_Z',
    }),
    value: _SortFieldNames.NAME,
  },
  {
    text: intl.formatMessage({
      id: 'label.schedule_name_Z_A',
    }),
    value: _SortFieldNames.NEGATIVE_NAME,
  },
];

export const SortActivityFieldNames = _SortFieldNames;

export default SortActivityStateOptions;
