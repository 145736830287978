import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';

// Molecules
import AdminProductPermissions from './AdminProductPermissions';

// Controllers
import AdminProductPermissionsController from '../controllers/AdminProductPermissionsController';

class AdminProductPermissionContext extends PureComponent {
  static propTypes = {
    product: ImmutablePropTypes.map,
  };

  static defaultProps = {
    product: null,
  };

  render() {
    const { product } = this.props;

    if (product) {
      return <AdminProductPermissions {...this.props} />;
    }

    return null;
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminProductPermissionContext = AdminProductPermissionsController(
  AdminProductPermissionContext
);

export default AdminProductPermissionContext;
