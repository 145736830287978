import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  cardError: {},
  cardId: null,
  cardsState: {},
  errorMsg: '',
  isLoaded: false,
  isLoading: false,
  isLoadingCard: false,
  pagination: {},
});

/**
 * Cards reducer
 * @param state
 * @param action
 * @returns {*}
 */
const todoReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_CARD_BY_ID_FAIL:
    case actionTypes.LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_FAIL: {
      return state
        .set('cardId', null)
        .set('isLoadingCard', false)
        .set('cardError', fromJS({ ...action.error.response }));
    }

    case actionTypes.LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_START: {
      return state.set('isLoadingCard', true).set('cardId', null);
    }

    case actionTypes.LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_SUCCESS: {
      return state
        .set('cardId', action.card.id)
        .set('isLoadingCard', false)
        .set('cardError', fromJS({}));
    }

    case actionTypes.LOAD_CARD_BY_ID_START: {
      return state.set('isLoadingCard', true);
    }

    case actionTypes.LOAD_CARD_BY_ID_SUCCESS: {
      return state.set('isLoadingCard', false).set('cardError', fromJS({}));
    }

    case actionTypes.LOAD_CARDS: {
      return state.set('isLoaded', false).set('isLoading', true);
    }

    case actionTypes.LOAD_CARDS_SUCCESS: {
      return state
        .set('isLoaded', true)
        .set('isLoading', false)
        .set('errorMsg', '')
        .set('pagination', fromJS(action.pagination));
    }

    case actionTypes.LOAD_CARDS_FAIL: {
      return state
        .set('isLoaded', false)
        .set('isLoading', false)
        .set('errorMsg', action.error);
    }

    case actionTypes.CARD_TOGGLE_CARD_OPEN: {
      const { cardId } = action;
      const isOpen = Boolean(state.getIn(['cardsState', cardId, 'isOpen']));

      // Inverting the value
      return state.setIn(['cardsState', cardId, 'isOpen'], !isOpen);
    }

    case actionTypes.CARD_ACTION_START: {
      const { cardId } = action;

      // Flagging that is doing something
      return state.setIn(['cardsState', cardId, 'isDoingAction'], true);
    }

    case actionTypes.CARD_ACTION_END: {
      const { cardId } = action;
      const hasError = Boolean(action.error);

      return state
        .setIn(['cardsState', cardId, 'isDoingAction'], false)
        .setIn(['cardsState', cardId, 'hasError'], hasError)
        .setIn(['cardsState', cardId, 'isOpen'], false);
    }

    case actionTypes.RESET_CARDS_STATE: {
      return state.setIn(['cardsState'], fromJS({}));
    }

    default: {
      return state;
    }
  }
};

export default todoReducer;
