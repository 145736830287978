import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsBirthday extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M57.27 35.81s-2.08.12-5.27.54V26.8c0-1.38-1.12-2.5-2.5-2.5S47 25.42 47 26.8v10.37c-6.26 1.13-14.19 3.23-19.9 7L27 69.73l41.48 10.32V54.17L57.27 35.81zm8.22 40.53l-35.6-8.95v-8.65l35.64 8.07-.04 9.53zm.07-12.59l-35.64-8.08V45.41l35.68 8.42-.04 9.92zM49.46 23.83c1.105 0 2-.895 2-2 0-1.08-.88-5.87-2-5.87s-2 4.79-2 5.87c0 1.105.895 2 2 2z' />
        </g>
      </svg>
    );
  }
}

export default CardsBirthday;
