import { change, getFormValues } from 'redux-form/immutable';

// Util
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => ({
  formValues: getFormValues('Form01')(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeValue: (optionId, blockId) => {
    let updatedValue = optionId;
    // this code doesn't make much sense to me
    // I already checked all workflows and no radio option has
    // ids with literal strings "true" or "false", we have indeed
    // ids "falsy" and "truly" used as workflow conditions in 1 workflow
    // I explain more here https://espressive.atlassian.net/browse/DEV-16857?focusedCommentId=49658
    // it's possible that this was made by Pascal during the first version of FAQs
    // based on workflows and BE scratch.
    // this code won't be executed unless literally the ids are these
    // ...but I'm leaving it anyways because I'm not 100% sure if this is no longer used elsewhere
    // (a demo? a customer customization to a workfloe? dunno...)
    if (optionId === 'true') {
      updatedValue = Boolean(optionId);
    } else if (optionId === 'false') {
      updatedValue = !optionId;
    }
    dispatch(change('Form01', blockId, updatedValue));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
