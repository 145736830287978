import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Menu } from 'semantic-ui-react';
import { NavLink, Route } from 'react-router-dom';
import BreadcrumbArrow from '../atoms/BreadcrumbArrow';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

/**
 * @param {immutable.List} tasks List of tasks
 * @param {String} workflowName Workflow's name
 */

class WorkflowBreadcrumbs extends PureComponent {
  static propTypes = {
    tasks: ImmutablePropTypes.list,
    workflowName: PropTypes.string,
  };

  static defaultProps = {
    tasks: new List(),
    workflowName: 'no name',
  };

  renderDividerArrow() {
    return (
      <Breadcrumb.Divider>
        <BreadcrumbArrow />
      </Breadcrumb.Divider>
    );
  }

  renderWorkflowLink = (routeProps) => {
    const { workflowName } = this.props;

    const { params } = routeProps.match;
    const workflowID = Number(params.workflowID);

    return (
      <Breadcrumb.Section
        activeClassName='active'
        as={NavLink}
        exact
        to={uiPathGenerator.genPath('editor.workflow', {
          workflowID,
        })}
      >
        {workflowName}
      </Breadcrumb.Section>
    );
  };

  renderTaskLink = (routeProps) => {
    const { tasks } = this.props;

    const { params } = routeProps.match;
    const workflowTaskID = Number(params.workflowTaskID);

    const currentTask =
      tasks && tasks.size > 0
        ? tasks.find((task) => task.get('id') === Number(workflowTaskID))
        : null;

    return (
      <Breadcrumb.Section active>
        {currentTask && currentTask.get('name')}
      </Breadcrumb.Section>
    );
  };

  render() {
    return (
      <Menu secondary>
        <Menu.Item fitted>
          <Breadcrumb size='large'>
            <Breadcrumb.Section
              activeClassName='active'
              as={NavLink}
              exact
              to={uiPathGenerator.genPath('editor')}
            >
              {'Blobifier'}
            </Breadcrumb.Section>

            <Route
              path={uiPathGenerator.genFullPattern('editor.workflow')}
              render={this.renderDividerArrow}
            />

            <Route
              path={uiPathGenerator.genFullPattern('editor.workflow')}
              render={this.renderWorkflowLink}
            />

            <Route
              path={uiPathGenerator.genFullPattern('editor.workflowTask')}
              render={this.renderDividerArrow}
            />

            <Route
              path={uiPathGenerator.genFullPattern('editor.workflowTask')}
              render={this.renderTaskLink}
            />
          </Breadcrumb>
        </Menu.Item>
      </Menu>
    );
  }
}

export default WorkflowBreadcrumbs;
