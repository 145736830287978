import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Immutable from 'immutable';
// Global
import TypeSelectFAQByArchetype, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectFAQByArchetype';
// Controller
import SelectFAQ01Controller from '../../controllers/SelectFAQ01Controller';

class MatchFAQFromArchetypeEntity01 extends PureComponent {
  static propTypes = {
    ...TypeSelectFAQByArchetype,
    searchFAQByArchetypeAndEntity: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    searchFAQByArchetypeAndEntity: noop,
  };

  componentDidMount() {
    const {
      changeValue,
      id,
      searchFAQByArchetypeAndEntity,
      templateSelected,
    } = this.props;

    const resetSkip = Immutable.fromJS({
      skip: 'noskip',
    });

    if (!templateSelected.get('template') || !templateSelected.get('word')) {
      // eslint-disable-next-line no-console -- debugging
      console.error(
        ' Error - No Archetype and entity exists in the BE scratch'
      );
    }

    searchFAQByArchetypeAndEntity(
      templateSelected.get('template'),
      templateSelected.get('word'),
      id
    );
    changeValue(id, resetSkip);
  }

  render() {
    const { template, type } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template} />
    );
  }
}

export const MatchFAQFromArchetypeEntity01Test = MatchFAQFromArchetypeEntity01;

export default SelectFAQ01Controller(MatchFAQFromArchetypeEntity01);
