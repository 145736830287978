import _APIcall from '../../../../../utils/APIcall';

const patchAuth = async (url, data) => {
  return _APIcall.patch({
    data,
    token: true,
    url: url,
  });
};

export default patchAuth;
