import actionTypes from './actionTypes';

const baristaActions = {};

baristaActions.supportChannelStart = () => ({
  type: actionTypes.SUPPORT_CHANNEL_START,
});

baristaActions.selectSupportChannel = (channelID, conversationId) => ({
  channelID,
  conversationId: conversationId,
  type: actionTypes.SELECT_SUPPORT_CHANNEL,
});

baristaActions.supportChannelError = (errorMsg) => ({
  errorMsg,
  type: actionTypes.SUPPORT_CHANNEL_ERROR,
});

/**
 * query (optional) location.query object with this shape:
 * {
 *   phrase: 'something',
 *   submit: 'Y',
 *   params: [{a: 1}. {b: 2}],
 * }
 */
baristaActions.openSupportChannel = (
  qRCodeParams = null,
  channelID = '',
  isOpenFromUrl = false,
  intentEid = ''
) => ({
  channelID,
  intentEid,
  isOpenFromUrl,
  qRCodeParams,
  type: actionTypes.OPEN_SUPPORT_CHANNEL,
});

baristaActions.closeSupportChannel = () => ({
  type: actionTypes.CLOSE_SUPPORT_CHANNEL,
});

baristaActions.restartSupportChannel = () => ({
  type: actionTypes.REINITIALIZE_SUPPORT_CHANNEL,
});

baristaActions.faqsGeneralListStart = () => ({
  type: actionTypes.BARISTA_FAQS_GENERAL_START,
});

baristaActions.faqsGeneralListSuccess = (results = [], count = 0) => ({
  count,
  results,
  type: actionTypes.BARISTA_FAQS_GENERAL_SUCCESS,
});

baristaActions.faqsGeneralListFail = () => ({
  type: actionTypes.BARISTA_FAQS_GENERAL_FAIL,
});

baristaActions.faqsTopicListStart = () => ({
  type: actionTypes.BARISTA_FAQS_TOPIC_START,
});

baristaActions.faqsTopicListSuccess = (results = [], count = 0) => ({
  count,
  results,
  type: actionTypes.BARISTA_FAQS_TOPIC_SUCCESS,
});

baristaActions.faqsTopicListFail = () => ({
  type: actionTypes.BARISTA_FAQS_TOPIC_FAIL,
});

baristaActions.fAQWorkflowRequestStart = () => ({
  type: actionTypes.BARISTA_FAQ_WF_REQUEST_START,
});

baristaActions.fAQWorkflowRequestSuccess = (workflowRequest) => ({
  type: actionTypes.BARISTA_FAQ_WF_REQUEST_SUCCEED,
  workflowRequest,
});

baristaActions.fAQWorkflowRequestFail = () => ({
  type: actionTypes.BARISTA_FAQ_WF_REQUEST_FAIL,
});

baristaActions.faqSelectDepartment = (departmentID, departmentTypeID) => ({
  departmentID,
  departmentTypeID,
  type: actionTypes.BARISTA_FAQ_SELECT_DEPT,
});

baristaActions.faqSelectFAQtoEdit = (faqID) => ({
  faqID,
  type: actionTypes.BARISTA_FAQ_EDIT_FAQ_ID,
});

baristaActions.faqUpdate = (faq, displayFilter, keepLoadingFAQ = false) => ({
  displayFilter,
  faq,
  keepLoadingFAQ,
  type: actionTypes.BARISTA_FAQS_UPDATE_SUCCESS,
});

baristaActions.faqAdd = (faq) => ({
  faq,
  type: actionTypes.BARISTA_FAQS_ADD_SUCCESS,
});

baristaActions.addFAQResult = (faq) => ({
  faq,
  type: actionTypes.BARISTA_FAQS_ADD_RESULT_SUCCESS,
});

baristaActions.faqUpdateStart = (eid) => ({
  eid,
  type: actionTypes.BARISTA_FAQ_UPDATE_START,
});

baristaActions.faqUpdateStop = (eid) => ({
  eid,
  type: actionTypes.BARISTA_FAQ_UPDATE_STOP,
});

baristaActions.faqUpdateFail = (eid) => ({
  eid,
  type: actionTypes.BARISTA_FAQ_UPDATE_FAIL,
});

baristaActions.setSearchFAQTerm = (searchTerm) => ({
  searchTerm,
  type: actionTypes.BARISTA_FAQ_SEARCH_TERM,
});

baristaActions.faqLoadImgStart = () => ({
  type: actionTypes.BARISTA_FAQS_LOAD_IMG_START,
});

baristaActions.faqLoadImgSuccess = (eid, responseEid, url, selectedFaqEid) => ({
  eid,
  responseEid,
  selectedFaqEid,
  type: actionTypes.BARISTA_FAQS_LOAD_IMG_SUCCESS,
  url,
});

baristaActions.faqLoadImgFail = () => ({
  type: actionTypes.BARISTA_FAQS_LOAD_IMG_FAIL,
});

baristaActions.isSearchingFAQTerm = () => ({
  type: actionTypes.BARISTA_FAQ_IS_SEARCHING_TERM,
});

baristaActions.addFiletoFAQ = (files) => ({
  files,
  type: actionTypes.BARISTA_FAQ_ADD_FILE,
});

baristaActions.loadIntentError = () => ({
  type: actionTypes.BARISTA_LOAD_INTENT_ERROR,
});

baristaActions.loadIntentStart = () => ({
  type: actionTypes.BARISTA_LOAD_INTENT_START,
});

baristaActions.loadIntentSuccess = (intent) => ({
  intent,
  type: actionTypes.BARISTA_LOAD_INTENT_SUCCESS,
});

baristaActions.loadIntentsError = () => ({
  type: actionTypes.BARISTA_LOAD_INTENTS_ERROR,
});

baristaActions.loadIntentsStart = () => ({
  type: actionTypes.BARISTA_LOAD_INTENTS_START,
});

baristaActions.loadIntentsSuccess = (intents) => ({
  intents,
  type: actionTypes.BARISTA_LOAD_INTENTS_SUCCESS,
});

baristaActions.loadFAQTriggerStart = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_TRIGGER_START,
});

baristaActions.loadFAQTriggerFail = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_TRIGGER_FAIL,
});

baristaActions.loadFAQTriggerSuccess = (results) => ({
  results,
  type: actionTypes.BARISTA_FAQ_LOAD_TRIGGER_SUCCESS,
});

baristaActions.loadFAQArchetypeStart = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_ARCHETYPE_START,
});

baristaActions.loadFAQArchetypeFail = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_ARCHETYPE_FAIL,
});

baristaActions.loadFAQArchetypeSuccess = (results) => ({
  results,
  type: actionTypes.BARISTA_FAQ_LOAD_ARCHETYPE_SUCCESS,
});

baristaActions.addFAQArchetypeSuccess = (results) => ({
  results,
  type: actionTypes.BARISTA_FAQ_ADD_ARCHETYPE_SUCCESS,
});

baristaActions.loadArchetypeEntityStart = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_ENTITY_START,
});

baristaActions.loadArchetypeEntityFail = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_ENTITY_FAIL,
});

baristaActions.loadArchetypeEntitySuccess = (results) => ({
  results,
  type: actionTypes.BARISTA_FAQ_LOAD_ENTITY_SUCCESS,
});

baristaActions.getPrimaryEntityFromArchetypeStart = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_PRIMARY_ENTITY_START,
});

baristaActions.getPrimaryEntityFromArchetypeFail = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_PRIMARY_ENTITY_FAIL,
});

baristaActions.getPrimaryEntityFromArchetypeSuccess = (
  archetypeEID,
  primaryEntity
) => ({
  archetypeEID,
  primaryEntity,
  type: actionTypes.BARISTA_FAQ_LOAD_PRIMARY_ENTITY_SUCCESS,
});

baristaActions.loadArchetypeEntitySynonymStart = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_ENTITY_SYNONYM_START,
});

baristaActions.loadArchetypeEntitySynonymFail = () => ({
  type: actionTypes.BARISTA_FAQ_LOAD_ENTITY_SYNONYM_FAIL,
});

baristaActions.loadArchetypeEntitySynonymSuccess = (results) => ({
  results,
  type: actionTypes.BARISTA_FAQ_LOAD_ENTITY_SYNONYM_SUCCESS,
});

baristaActions.loadKBArticleStart = () => ({
  type: actionTypes.BARISTA_KB_LOAD_START,
});

baristaActions.loadKBArticleSuccess = ({
  baristaKbArticleId,
  clickContext,
  htmlBody,
  plainText,
  source,
  title,
}) => ({
  baristaKbArticleId,
  clickContext,
  htmlBody,
  plainText,
  source,
  title,
  type: actionTypes.BARISTA_KB_LOAD_SUCCESS,
});

baristaActions.loadKBArticleError = (error) => ({
  error,
  type: actionTypes.BARISTA_KB_LOAD_ERROR,
});

baristaActions.openKBArticleModal = () => ({
  type: actionTypes.BARISTA_KB_MODAL_OPEN,
});

baristaActions.closeKBArticleModal = () => ({
  type: actionTypes.BARISTA_KB_MODAL_CLOSE,
});

baristaActions.loadKbSupportError = () => ({
  type: actionTypes.BARISTA_LOAD_KB_SUPPORT_ERROR,
});

baristaActions.loadKbSupportStart = () => ({
  type: actionTypes.BARISTA_LOAD_KB_SUPPORT_START,
});

baristaActions.loadKbSupportSuccess = (results, count) => ({
  count,
  results,
  type: actionTypes.BARISTA_LOAD_KB_SUPPORT_SUCCESS,
});

baristaActions.loadKnowledgeArticleError = () => ({
  type: actionTypes.BARISTA_LOAD_KNOWLEDGE_ARTICLE_ERROR,
});

baristaActions.loadKnowledgeArticleStart = () => ({
  type: actionTypes.BARISTA_LOAD_KNOWLEDGE_ARTICLE_START,
});

baristaActions.loadKnowledgeArticleSuccess = (data) => ({
  data,
  type: actionTypes.BARISTA_LOAD_KNOWLEDGE_ARTICLE_SUCCESS,
});

baristaActions.updateKnowledgeArticleError = () => ({
  type: actionTypes.BARISTA_UPDATE_KNOWLEDGE_ARTICLE_ERROR,
});

baristaActions.updateKnowledgeArticleStart = () => ({
  type: actionTypes.BARISTA_UPDATE_KNOWLEDGE_ARTICLE_START,
});

baristaActions.updateKnowledgeArticleSuccess = (data) => ({
  data,
  type: actionTypes.BARISTA_UPDATE_KNOWLEDGE_ARTICLE_SUCCESS,
});

export default baristaActions;
