import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Grid, Message, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import { Helmet } from 'react-helmet';

// Controllers
import LogoutControler from '../controllers/LogoutController';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
// Molecule
import TenantBackgroundImage from '../../../v2/components/functional/TenantBackgroundImage';
import BodyClassName from '../../../globals/BodyClassName';
// import ExternalLogoutIframe   from '../molecules/ExternalLogoutIframe';

class LogoutPage extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }).isRequired,

    logoutSession: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    const { logoutSession, location } = this.props;

    const { query } = location;
    const isLocalOnly = has(query, 'isLocalOnly');

    logoutSession(isLocalOnly);
  }

  render() {
    // const {externalLogoutHtmlDoc} = this.props;
    return (
      <BodyClassName className='login-page'>
        <Grid centered verticalAlign='middle'>
          <Helmet title='Logout' />
          <Grid.Column>
            <TenantBackgroundImage />

            <Dimmer.Dimmable as={Segment} textAlign='center'>
              <Message content={'You have been logged out.'} success />
              <Button
                as={Link}
                basic
                content={'Login'}
                to={uiPathGenerator.genPath('login')}
              />

              {window.cordova || window.electron ? (
                <Button
                  as={Link}
                  basic
                  content={'Change tenant'}
                  to={uiPathGenerator.genPath('noTenant')}
                />
              ) : null}

              {/**
              {externalLogoutHtmlDoc && !window.cordova && !window.electron ?
                <React.Fragment>
                  <p>
                    {'login you out of your idp services. If a new window is not opened, then click'}
                    <a
                      href='about:blank'
                      target='esp_logout'
                    >{'here'}
                    </a>
                  </p>
                </React.Fragment>
                :
                null

              }
              **/}
            </Dimmer.Dimmable>
          </Grid.Column>
        </Grid>
      </BodyClassName>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
LogoutPage = LogoutControler(LogoutPage);

export default LogoutPage;
