import { useCallback, useState } from 'react';

import LocalStorageAdapter from './LocalStorageAdapter';
import localStorageConfig from './localStorageConfig';

const { STORAGE_PREFIX } = localStorageConfig;
const localStorage = new LocalStorageAdapter();

//
// Local Storage Hook
//
// The data is stored along its metadata inside an wrapping envelope.
// The contents of the envelope are the following:
//
// data: The data itself
// dataKey: The key given to the item
// dataType: The type of the data
//
// If dataType is other than String, it will be
// parsed/stringified after/before reading/writing.
//
// @param {String} dataKey The key that uniquely identifies the item.
// @param {*} dataValue The piece of data to store.
// @param {String} storagePrefix An optional prefix for the key.
export const useLocalStorage = ({
  dataKey,
  dataValue,
  storagePrefix = STORAGE_PREFIX,
}) => {
  const compositeKey = `${storagePrefix}_${dataKey}`;
  const [storedDataValue, setDataValue] = useState(() => {
    try {
      const storedValue = localStorage.getItem(compositeKey);

      // always prefer the incoming value
      if (dataValue) {
        localStorage.setItem(compositeKey, dataValue);
        return dataValue;
      }

      return storedValue;
    } catch (err) {
      // eslint-disable-next-line no-console -- we need this as a developer message
      console.warn(
        `Error while trying to read/write ${dataKey} in localStorage`,
        err
      );
      return dataValue;
    }
  });

  const setItem = useCallback(
    (dataValue) => {
      try {
        localStorage.setItem(compositeKey, dataValue);
        setDataValue(dataValue);
      } catch (localStorageSetItemException) {
        // eslint-disable-next-line no-console -- we need this as a developer message
        console.warn(
          `Error while trying to set ${dataKey} in localStorage`,
          localStorageSetItemException
        );
      }
    },
    [compositeKey, dataKey, setDataValue]
  );

  const removeItem = useCallback(() => {
    try {
      localStorage.removeItem(compositeKey);
      setDataValue();
    } catch (localStorageRemoveItemException) {
      // eslint-disable-next-line no-console -- we need this as a developer message
      console.warn(
        `Error while trying to remove ${dataKey} from localStorage`,
        localStorageRemoveItemException
      );
    }
  }, [compositeKey, dataKey, setDataValue]);

  return [storedDataValue, setItem, removeItem];
};

export default useLocalStorage;
