import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareOs extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M32 43.26h8.49v8.49H32zM62.73 59.6h.34c1.952-.035 3.8-.883 5.1-2.34.2-.218.183-.556-.035-.755-.218-.2-.556-.183-.755.035-1.097 1.242-2.664 1.967-4.32 2h-.28c-3.68 0-5-1.87-5.07-2-.163-.246-.494-.313-.74-.15s-.313.494-.15.74c.06.14 1.65 2.47 5.91 2.47zM69 50.22v-1.47c0-.19-.102-.368-.267-.463-.166-.096-.37-.096-.536 0-.165.095-.267.272-.267.463v1.5c0 .19.102.368.267.463.166.096.37.096.535 0 .166-.095.268-.272.268-.463v-.03zm-11.93 0v-1.47c0-.295-.24-.535-.535-.535-.295 0-.535.24-.535.535v1.5c0 .295.24.535.535.535.295 0 .535-.24.535-.535v-.03zm-5.06-6.97h20.44v1H52.01zM49.5 45.7h1v14.96h-1zm2.51 16.55h20.44v1H52.01zM74.5 45.7h1v14.96h-1zM32 53.75h8.49v8.49H32zM21.51 43.26H30v8.49h-8.49zm0 10.49H30v8.49h-8.49z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.65c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.528 15 25.324 15 23.84c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareOs;
