import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import { Field, FieldArray, reduxForm } from 'redux-form/immutable';
import { Form, Header, Segment } from 'semantic-ui-react';
import _ from 'lodash';

// Controller
import BlockFormController from '../../controllers/BlockFormController';
// globals
import { BlockDefinitionMap } from '../../../../../../app/js/v1/globals/BlockTypes';
// moleculos
import FormInputSelect from '../../../../../../app/js/v1/components/molecules/FormInputSelect';
import FormInputText from '../../../../../../app/js/v1/components/molecules/FormInputText';
import FormInputTextarea from '../../../../../../app/js/v1/components/molecules/FormInputTextarea';
import FormInputCheckbox from '../../../../../../app/js/v1/components/molecules/FormInputCheckbox';

import BlockArrayOfAttributes from './BlockArrayOfAttributes';

const propTypes = {
  addOptionCallback: PropTypes.func,
  attributesSelectable: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

      text: PropTypes.node,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    })
  ),
  blockData: ImmutablePropTypes.map.isRequired,
  removeOptionCallback: PropTypes.func,
  type: PropTypes.string.isRequired,
};

const defaultProps = {
  addOptionCallback: _.noop,
  attributesSelectable: [],
  removeOptionCallback: _.noop,
};

class BlockForm extends PureComponent {
  /**
   * Only prevents default
   */
  handleSubmit = (e) => {
    e.preventDefault();
  };

  addOption = (blockAttrName, formOptionFields) => {
    const { type, addOptionCallback, blockData } = this.props;
    const { arrayShape } = BlockDefinitionMap.get(type).blobAttributes[
      blockAttrName
    ];
    const emptyNewOption = {};
    Object.keys(arrayShape).forEach((key) => {
      emptyNewOption[key] = arrayShape[key].type === 'bool' ? false : '';
    });

    addOptionCallback(blockData.get('id'), blockAttrName, emptyNewOption);

    formOptionFields.push(fromJS(emptyNewOption));
  };

  removeOption = (blockAttrName, optionIndex, formOptionFields) => {
    const { removeOptionCallback, blockData } = this.props;

    removeOptionCallback(blockData.get('id'), blockAttrName, optionIndex);
    formOptionFields.remove(optionIndex);
  };

  render() {
    const { type, attributesSelectable } = this.props;

    const blockDefinition = BlockDefinitionMap.get(type);

    if (!blockDefinition) {
      return null;
    }

    const { templates } = blockDefinition;
    const blockAttributes = blockDefinition.blobAttributes;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Header dividing>{`${blockDefinition.label} Block`}</Header>
        {Object.keys(blockAttributes).map((blockAttr, i) => {
          const blockKey = i;
          const item = blockAttributes[blockAttr];
          // 'Type' field is static and hidden
          if (blockAttr === 'type' || blockAttr === 'id' || item.uncontrolled) {
            return null; // do not even render these two
          }
          // Template is always a dropdown
          if (blockAttr === 'template') {
            const templatesAsOptions = templates.map((tName) => ({
              text: tName,
              value: tName,
            }));

            return (
              <Field
                component={FormInputSelect}
                fluid
                key={blockKey}
                label='Select a Template'
                name={blockAttr}
                options={templatesAsOptions}
                placeholder='Template...'
                required={item.required}
                selection
                showErrorsWithoutSubmitting
              />
            );
          }
          // Still a string, but only posibly to pick from a list of possible values
          if (item.type === 'string' && item.restrictToOptions) {
            const selectRestrictToOptions = item.restrictToOptions.map(
              (op) => ({
                text: op,
                value: op,
              })
            );
            if (!item.required) {
              selectRestrictToOptions.unshift({
                text: '-empty-',
                value: '',
              });
            }

            return (
              <Field
                component={FormInputSelect}
                fluid
                key={blockKey}
                label={blockAttr}
                name={blockAttr}
                options={selectRestrictToOptions}
                required={item.required}
                selection
                showErrorsWithoutSubmitting
              />
            );
          }
          // strings or numbers are rendered as input fields
          if (item.type === 'string' || item.type === 'number') {
            let fieldInputType;
            switch (item.type) {
              case 'string':
                fieldInputType = 'text';
                break;
              case 'number':
                fieldInputType = 'number';
                break;
              default:
                fieldInputType = 'text';
            }

            return (
              <Field
                component={FormInputText}
                fluid
                key={blockKey}
                label={blockAttr}
                name={blockAttr}
                placeholder={blockAttr}
                required={item.required}
                showErrorsWithoutSubmitting
                type={fieldInputType}
              />
            );
          } else if (item.type === 'bool') {
            return (
              <div key={blockKey}>
                <label>
                  <b>{blockAttr}</b>
                </label>
                <Field component={FormInputCheckbox} name={blockAttr} />
              </div>
            );
          } else if (item.type === 'object') {
            return (
              <div key={blockKey}>
                <label>
                  <b>{blockAttr}</b>
                </label>

                {/*
                 * The inputValue attribute is kinda special so we don't render it procedurally
                 */}
                {blockAttr === 'inputValue' && (
                  <Form.Group widths='equal'>
                    <Field
                      component={FormInputSelect}
                      label='Map to attribute'
                      name={`${blockAttr}.map`}
                      options={attributesSelectable}
                      placeholder='Map'
                      required={item.objectShape.map.required}
                      showErrorsWithoutSubmitting
                      type={item.objectShape.map.type}
                    />

                    <Field
                      component={FormInputSelect}
                      fluid
                      label='Map Default'
                      name={`${blockAttr}.mapDefault`}
                      options={attributesSelectable}
                      placeholder='Map'
                      showErrorsWithoutSubmitting
                      type={item.objectShape.map.type}
                    />

                    <Field
                      component={FormInputTextarea}
                      label='Value'
                      name={`${blockAttr}.value`}
                      placeholder='Value'
                      required={item.objectShape.value.required}
                      type={item.objectShape.value.type}
                    />
                  </Form.Group>
                )}

                {blockAttr !== 'inputValue' && (
                  <Segment>
                    {item.objectShape &&
                      Object.keys(item.objectShape).map((objKey, j) => {
                        const objectKey = j;

                        if (item.objectShape[objKey].type === 'bool') {
                          return (
                            <div key={objectKey}>
                              <label>
                                <b>{objKey}</b>
                              </label>
                              <Field
                                component={FormInputCheckbox}
                                name={`${blockAttr}.${objKey}`}
                              />
                            </div>
                          );
                        }

                        return (
                          <Field
                            component={FormInputText}
                            key={objectKey}
                            label={objKey}
                            name={`${blockAttr}.${objKey}`}
                            placeholder={objKey}
                            required={item.objectShape[objKey].required}
                            type={item.objectShape[objKey].type}
                          />
                        );
                      })}
                  </Segment>
                )}
              </div>
            );
          } else if (item.type === 'arrayOf') {
            // The inputValue attribute is kinda special so we don't render it procedurally
            if (blockAttr === 'inputValue') {
              return item.arrayShape.map((elem, ig) => {
                const arrayShapeKey = ig;
                return (
                  <div key={arrayShapeKey}>
                    <label>
                      <b>
                        {blockAttr} {'-'} {arrayShapeKey} {elem.comment}
                      </b>
                    </label>
                    {blockAttr === 'inputValue' && (
                      <Form.Group widths='equal'>
                        <Field
                          component={FormInputSelect}
                          label='Map to attribute'
                          name={`${blockAttr}.map${arrayShapeKey}`}
                          options={attributesSelectable}
                          placeholder='Map'
                          required={elem.map.required}
                          showErrorsWithoutSubmitting
                          type={elem.map.type}
                        />
                        <Field
                          component={FormInputSelect}
                          fluid
                          label='Map Default'
                          name={`${blockAttr}.mapDefault${arrayShapeKey}`}
                          options={attributesSelectable}
                          placeholder='Map'
                          showErrorsWithoutSubmitting
                          type={elem.map.type}
                        />
                        <Field
                          component={FormInputTextarea}
                          label='Value'
                          name={`${blockAttr}.value${ig}`}
                          placeholder='Value'
                          required={elem.value.required}
                          type={elem.value.type}
                        />
                      </Form.Group>
                    )}
                  </div>
                );
              });
            } // Otherwise use the procedural BlockArrayOfAttributes
            else {
              return (
                <FieldArray
                  addOptionFn={this.addOption}
                  blockIndex={i}
                  component={BlockArrayOfAttributes}
                  item={item}
                  key={blockKey}
                  name={blockAttr}
                  removeOptionFn={this.removeOption}
                />
              );
            }
          }

          return null;
        })}
      </Form>
    );
  }
}

const checkAttributes = (attrs, values, errors, path = null) => {
  Object.keys(attrs).forEach((blockAttr) => {
    const item = attrs[blockAttr];

    const valuePath = blockAttr.replace(/\[(\d+)\]/g, '.$1').split('.');
    const levelPath = path ? path.concat(valuePath) : valuePath;

    if (item.required) {
      if (!values.getIn(levelPath)) {
        errors[blockAttr] = 'Required';
      }
    }

    if (item.type === 'object') {
      checkAttributes(item.objectShape, values, errors, levelPath);
    }
  });
};

BlockForm.validateRequiredFields = (values) => {
  const errors = {};
  const type = values.get('type');
  const blockDefinition = BlockDefinitionMap.get(type);
  if (blockDefinition) {
    const blockAttributes = BlockDefinitionMap.get(type).blobAttributes;
    checkAttributes(blockAttributes, values, errors);
  } else {
    // eslint-disable-next-line no-console -- debugging
    console.error(`No block definition for type ${type}`);
  }

  return errors;
};

BlockForm.propTypes = propTypes;
BlockForm.defaultProps = defaultProps;

export default BlockFormController(
  reduxForm({
    validate: BlockForm.validateRequiredFields,
  })(BlockForm)
);
