import React, { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';

// Molecule
import DatePicker from './DatePicker';

class ValidatedDate extends PureComponent {
  static propTypes = {
    /** Any kind of node/string that will be render after the DatePicker */
    afterElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    /** Any kind of node/string that will be render before the DatePicker */
    beforeElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  };

  static defaultProps = {
    afterElement: null,
    beforeElement: null,
  };

  state = {
    focused: false,
  };

  handleFocusChange = ({ focused }) => {
    this.setState({
      focused,
    });
  };

  handleDateChange = (date) => {};

  render() {
    const { afterElement, beforeElement } = this.props;

    const { focused } = this.state;

    return (
      <Field
        afterElement={afterElement}
        beforeElement={beforeElement}
        component={DatePicker}
        focused={focused}
        onDateChange={this.handleDateChange}
        onFocusChange={this.handleFocusChange}
        {...this.props}
      />
    );
  }
}

export default ValidatedDate;
