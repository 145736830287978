const api_elc = {
  count: 6637,
  next:
    'https://release.qa.espressive.com/api/common/v0.1/job_status/?esp_filters=api_path__IC%3D%2Felc%2F&limit=1&offset=1&only_user_jobs=false&order_by=-id',
  previous: null,
  results: [
    {
      api_path: '/api/barista/v0.1/elc/import_all_elc/',
      app_job_eid: null,
      counter: 13,
      eid: '64dcf9bf-frewrew-9b55-c538332fed8e',
      id: 20178,
      result_details: {
        current_process: 'Importing intents content',
        errors: {},
        import_data: {
          'Deleting configurations': 80,
          'Importing applications': 1,
          'Importing archetype intents': 0,
          'Importing attributes': 0,
          'Importing configurations': 13,
          'Importing entities': 0,
          'Importing esp_service_departments': 0,
          'Importing hooks': 0,
          'Importing intents': 2,
          'Importing intents content': 2,
          'Importing localizations': 0,
          'Importing service_team_classifications': 0,
          'Importing synonyms': 829,
          'Importing task_classifications': 0,
          'Importing variables': 1,
        },
        message: 'Success',
        percent_complete: 100,
        username: 'etefsdfsfdsf@fake.com',
        warnings: {},
      },
      status: 'COMPLETED',
      sys_created_by: 'etefsdfsfdsf@fake.com',
      sys_custom_fields: null,
      sys_date_created: '2021-07-22T19:48:32.137316Z',
      sys_date_updated: '2021-07-22T19:49:39.152718Z',
      sys_updated_by: 'etefsdfsfdsf@fake.com',
      total_count: 17,
      url: 'https://release.qa.fake.com/api/common/v0.1/job_status/20178/',
    },
  ],
};

export default api_elc;
