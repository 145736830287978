import actionTypes from './actionTypes';

const jobRoleActions = {};

jobRoleActions.getJobRolesWithBundlesSuccess = (jobRoles, allBundles) => ({
  allBundles,
  jobRoles,
  type: actionTypes.GET_JOB_ROLES_WITH_BUNDLES_SUCCESS,
});

jobRoleActions.getJobRolesSuccess = (jobRoles) => ({
  jobRoles,
  type: actionTypes.GET_JOB_ROLES_SUCCESS,
});

export default jobRoleActions;
