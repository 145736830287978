import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';

const rule = (props) => {
  const { placement, theme } = props;

  const isTop = placement === 'top';
  const bubbleNegativePadding = `-${theme.bubblePadding}`;

  return {
    alignItems: 'center',
    borderBottomWidth: isTop ? theme.dashedBorderWidth : 0,
    borderColor: theme.brandTenant.primaryColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: theme.dashedBorderStyle,
    borderTopWidth: isTop ? 0 : theme.dashedBorderWidth,
    display: 'flex',
    marginBottom: isTop ? theme.bubblePadding : bubbleNegativePadding,
    marginLeft: bubbleNegativePadding,
    marginRight: bubbleNegativePadding,
    marginTop: isTop ? bubbleNegativePadding : theme.bubblePadding,
    padding: theme.bubblePadding,
  };
};

class ChatMessageDashedSection extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string.isRequired,
    // placement is required to fela
    // to set position of this component in the right way, please do not remove
    // remove when FELA is removed fron this component
    placement: PropTypes.oneOf(['top', 'bottom']),
  };

  static defaultProps = {
    children: void 0,
    placement: 'top',
  };

  state = {};

  render() {
    const { className, children, placement } = this.props;
    // TODO remove this when FELA is removed
    const fakeFn = () => (placement ? '' : '');

    fakeFn();

    return (
      <div className={className} data-component='ChatMessageDashedSection'>
        {children}
      </div>
    );
  }
}

export default createComponent(rule, ChatMessageDashedSection, []);
