import actionTypes from './actionTypes';

const searchActions = {};

searchActions.setUserInput = (userInput) => ({
  type: actionTypes.SET_SEARCH_USER_INPUT,
  userInput,
});

searchActions.setContextName = (contextName) => ({
  contextName,
  type: actionTypes.SET_SEARCH_CONTEXT_NAME,
});

searchActions.searchStart = () => ({
  type: actionTypes.SEARCH_START,
});

searchActions.searchSuccess = (results) => ({
  results,
  type: actionTypes.SEARCH_SUCCESS,
});

searchActions.searchFail = (error) => ({
  error,
  type: actionTypes.SEARCH_FAIL,
});

searchActions.setUserSearchInput = (userInput) => ({
  type: actionTypes.SET_USER_SEARCH_INPUT,
  userInput,
});

searchActions.searchUserStart = () => ({
  type: actionTypes.USER_SEARCH_START,
});

searchActions.searchUserSuccess = (results) => ({
  results,
  type: actionTypes.USER_SEARCH_SUCCESS,
});

searchActions.searchUserFail = (error) => ({
  error,
  type: actionTypes.USER_SEARCH_FAIL,
});

searchActions.setDefaultSearchUser = (user) => ({
  type: actionTypes.USER_SEARCH_SET_DEFAULT,
  user,
});

export default searchActions;
