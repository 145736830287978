const ContextNames = {
  CASESFEED: 'CASESFEED',
  CASES_FULL_RESULTS: 'CASES_FULL_RESULTS',
  CATALOG: 'CATALOG',
  CATALOG_FULL_RESULTS: 'CATALOG_FULL_RESULTS',
  DIRECTORY: 'DIRECTORY',
  DIRECTORY_FULL_RESULTS: 'DIRECTORY_FULL_RESULTS',
  DIRECT_CHATS: 'DIRECT_CHATS',
  DIRECT_CHATS_FULL_RESULTS: 'DIRECT_CHATS_FULL_RESULTS',
  EQUIPMENT: 'EQUIPMENT',
  EQUIPMENT_FULL_RESULTS: 'EQUIPMENT_FULL_RESULTS',
  FEED: 'FEED',
  SCOPED_CHATS: 'SCOPED_CHATS',
  SCOPED_CHATS_FULL_RESULTS: 'SCOPED_CHATS_FULL_RESULTS',
};

export default ContextNames;
