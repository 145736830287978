import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { noop } from 'lodash';
// Components
import ChatMessage from '../../display/ChatMessage';
import ChatInputTypes from '../../display/ChatMessage/ChatInputTypes';

// Controllers
import LaunchWorkflowButtonController from './LaunchWorkflowButtonController';

class LaunchWorkflowButton extends PureComponent {
  static propTypes = {
    content: PropTypes.string,

    inModal: PropTypes.bool,

    inlineConversation: PropTypes.bool,

    isLastMessage: PropTypes.bool,

    launchWorkflowRequestModal: PropTypes.func,

    timeStamp: PropTypes.string,

    workflowRequestId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    content: 'Begin',

    inModal: false,

    inlineConversation: false,
    isLastMessage: false,
    launchWorkflowRequestModal: noop,
    timeStamp: '',
  };

  handleWorkflowClick = () => {
    const { launchWorkflowRequestModal, workflowRequestId } = this.props;

    launchWorkflowRequestModal(workflowRequestId);
  };

  render() {
    const {
      content,
      inModal,
      inlineConversation,
      timeStamp,
      isLastMessage,
    } = this.props;

    const defaultButton = (
      <Button
        basic
        content={content}
        onClick={this.handleWorkflowClick}
        primary
      />
    );

    // render decorators
    if (inlineConversation) {
      return (
        <ChatMessage
          input={{
            handleOptionSelection: this.handleWorkflowClick,
            options: [
              {
                id: 1,
                label: content,
                value: content,
              },
            ],
            type: ChatInputTypes.BUTTON,
            value: null,
          }}
          isLastMessage={isLastMessage}
          showTimeStamp
          timeStamp={timeStamp}
        />
      );
    } else if (inModal) {
      return <Modal.Actions className='barista'>{defaultButton}</Modal.Actions>;
    } else {
      return defaultButton;
    }
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
LaunchWorkflowButton = LaunchWorkflowButtonController(LaunchWorkflowButton);

export default LaunchWorkflowButton;
