// let's avoid using buildCompositeController
// since it creates react-router-location-key that destroys the purpose of pure components
import { connect } from 'react-redux';

import getTenantBranding from '../../../selectors/getTenantBranding';
import { getTenantEntity } from '../../../selectors/getTenant';

const mapStateToProps = (state) => {
  const tenantBranding = getTenantBranding(state);
  const tenant = getTenantEntity(state);

  return {
    tenantHeaderColor: tenantBranding?.get('tenantHeaderColor'),
    tenantName: tenant?.get('name'),
  };
};

// My component is not needed it but I'm leaving this as example
const mapDispatchToProps = (dispatch) => ({
  example() {
    dispatch();
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
