import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

import taskActions from '../../actions/taskActions';
import taskThunks from '../../actions/taskThunks';

const mapStateToProps = (state) => {
  const editor = state.get('editor');

  return {
    newTask: editor.get('newTask').toJS(),
    removingTask: editor.get('removingTask').toJS(),
    saveStatus: editor.get('saveStatus').toJS(),
    tasks: editor.get('tasks').toJS(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addTask: (name, workflow, onAddTask) => {
    const immutableWorkflow = fromJS(workflow);
    dispatch(taskThunks.addTask(name, immutableWorkflow, onAddTask));
  },

  getTasks: (workflowId) => {
    dispatch(taskThunks.getTasks(workflowId));
  },

  rearrangeTasks: (ordering) => {
    dispatch(taskActions.rearrangeTasks(ordering));
  },

  removeTask: (taskId, onRemoveTask) => {
    dispatch(taskThunks.removeTask(taskId, onRemoveTask));
  },

  saveTasksRearrangement: () => {
    dispatch(taskThunks.saveTasksRearrangement());
  },
});

const WorkflowEditorPageController = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(component));
export default WorkflowEditorPageController;
