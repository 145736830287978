import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Form, Grid, Header, Input, Segment } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';

import BrandingContentPreview from './BrandingContentPreview';
import BrandingContentSection from './BrandingContentSection';
import BrandingContentSvgSafearea from './BrandingContentSvgSafearea';

import applicationPreview from './images/applicationPreview.png';
import chatPreview from './images/chatPreview.png';
import loginPreview from './images/loginPreview.png';

import safeArea from './images/safeArea.png';

import { DESCRIPTION_LABELS, INPUT_LABELS, TITLES } from './utils';
import { Form as FormikForm } from 'formik';
import PropTypes from 'prop-types';
import InputFile from './InputFile';
import InputField from './InputField';
import fetchAllowedExtensions from './api/fetchAllowedExtensions';
import InputDescription from './InputDescription';
import BrandingWarning from './BrandingWarning';

const imageProps = {
  caption: PropTypes.string,
  class_name: PropTypes.string,
  eid: PropTypes.string,
  height: PropTypes.number,
  id: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  ordering: PropTypes.number,
  svg: PropTypes.string,
  sys_created_by: PropTypes.string,
  sys_date_created: PropTypes.string,
  sys_date_updated: PropTypes.string,
  sys_updated_by: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.number,
};

const chatBotIconSVGPropType = PropTypes.shape({
  body: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
});

const formPropTypes = PropTypes.shape({
  applicationLogo: PropTypes.shape(imageProps),
  backgroundImage: PropTypes.shape(imageProps),
  chatBotIcon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  chatBotIconSVG: chatBotIconSVGPropType,
  chatBotName: PropTypes.string,
  customColor: PropTypes.string,
  customColorCheckbox: false,
  favicon: PropTypes.shape(imageProps),
  logInPageLogo: PropTypes.shape(imageProps),
  name: PropTypes.string,
  primaryColor: PropTypes.string,
});

const propTypes = {
  chatBotIconSVG: chatBotIconSVGPropType,
  deleteImagesList: PropTypes.instanceOf(Set).isRequired,
  dirty: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isCustomBrandingEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  readOnlyValues: formPropTypes,
  setDeletedImage: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  usersAvatar: PropTypes.string,
  values: formPropTypes,
};

const BrandingContent = ({
  values: {
    applicationLogo,
    backgroundImage,
    chatBotIcon = '',
    chatBotName = '',
    customColor = '',
    customColorCheckbox = false,
    favicon,
    logInPageLogo,
    name = '',
    primaryColor = '',
  },
  isValid,
  isCustomBrandingEnabled = false,
  deleteImagesList,
  setDeletedImage,
  isLoading = false,
  handleChange,
  handleSubmit,
  readOnlyValues,
  setFieldValue,
  handleReset,
  dirty,
  usersAvatar,
}) => {
  const handleCheckboxChange = useCallback(
    (e, { checked }) => {
      if (!checked) {
        setFieldValue('customColor', '');
      }
      setFieldValue('customColorCheckbox', checked);
    },
    [setFieldValue]
  );

  const [allowedBrandingExtensions, setBrandingAllowedExtension] = useState('');

  useEffect(() => {
    const fetchBrandingAllowedExtension = async () => {
      const { body } = await fetchAllowedExtensions();
      if (body?.value) {
        const formatExtensions = (extensions) =>
          extensions.split(',').join(',.').replace(/\s/g, '');
        setBrandingAllowedExtension(formatExtensions(body?.value ?? ''));
      } else {
        setBrandingAllowedExtension('');
      }
    };

    fetchBrandingAllowedExtension();
  }, []);

  const shouldDisableSubmitButton =
    isLoading || !dirty || (customColorCheckbox && !customColor) || !isValid;
  const shouldDisableCancelButton = isLoading || !dirty;

  return (
    <>
      <Segment attached loading={isLoading}>
        <FormikForm onSubmit={handleSubmit}>
          <Helmet
            title={intl.formatMessage({
              id: 'label.admin_title_global_settings',
            })}
          />

          <BrandingWarning
            isBrandingAllowedExtensionEnabled={Boolean(
              allowedBrandingExtensions
            )}
          />

          <Header
            as='h3'
            content={TITLES.APPLICATION_IDENTITY_BRANDING}
            dividing
          />

          <Grid columns={2}>
            <Grid.Column>
              <Form as='div'>
                <InputField
                  description={DESCRIPTION_LABELS.NAME_DESCRIPTION}
                  id='name'
                  label={INPUT_LABELS.NAME}
                  name='name'
                  onChange={handleChange}
                  value={name}
                />
              </Form>
              <BrandingContentSection title={TITLES.IMAGES}>
                <InputFile
                  allowedFileTypes={allowedBrandingExtensions}
                  deleteImagesList={deleteImagesList}
                  description={DESCRIPTION_LABELS.APPLICATION_LOGO_DESCRIPTION}
                  label={INPUT_LABELS.APPLICATION_LOGO}
                  name='applicationLogo'
                  setDeletedImage={setDeletedImage}
                  setFieldValue={setFieldValue}
                  value={applicationLogo}
                />

                <InputFile
                  allowedFileTypes={allowedBrandingExtensions}
                  deleteImagesList={deleteImagesList}
                  description={DESCRIPTION_LABELS.LOG_IN_PAGE_LOGO_DESCRIPTION}
                  label={INPUT_LABELS.LOG_IN_PAGE_LOGO}
                  name='logInPageLogo'
                  setDeletedImage={setDeletedImage}
                  setFieldValue={setFieldValue}
                  value={logInPageLogo}
                />

                <InputFile
                  allowedFileTypes={allowedBrandingExtensions}
                  deleteImagesList={deleteImagesList}
                  description={DESCRIPTION_LABELS.BACKGROUND_IMAGE_DESCRIPTION}
                  label={INPUT_LABELS.BACKGROUND_IMAGE}
                  name='backgroundImage'
                  setDeletedImage={setDeletedImage}
                  setFieldValue={setFieldValue}
                  value={backgroundImage}
                />
              </BrandingContentSection>

              <BrandingContentSection title={TITLES.COLORS}>
                <InputField
                  description={DESCRIPTION_LABELS.PRIMARY_COLOR_DESCRIPTION}
                  id='primaryColor'
                  label={INPUT_LABELS.PRIMARY_COLOR}
                  name='primaryColor'
                  onChange={handleChange}
                  value={primaryColor}
                />

                <Form.Checkbox
                  checked={customColorCheckbox}
                  id='customColorCheckbox'
                  label={INPUT_LABELS.CUSTOMIZE_HEADER_COLOR}
                  name='customColorCheckbox'
                  onChange={handleCheckboxChange}
                />
                <Form.Field>
                  <Input
                    aria-label={'Header Color'}
                    disabled={!customColorCheckbox}
                    id={'customColor'}
                    name={'customColor'}
                    onChange={handleChange}
                    value={customColor}
                  />
                  <InputDescription
                    description={
                      DESCRIPTION_LABELS.CUSTOMIZE_HEADER_COLOR_DESCRIPTION
                    }
                  />
                </Form.Field>
              </BrandingContentSection>

              {isCustomBrandingEnabled && (
                <BrandingContentSection
                  description={
                    DESCRIPTION_LABELS.BOT_IDENTITY_BRANDING_DESCRIPTION
                  }
                  title={TITLES.BOT_IDENTITY_BRANDING}
                >
                  <InputField
                    disabled={isLoading}
                    id='chatBotName'
                    label={INPUT_LABELS.CHAT_BOT_NAME}
                    name='chatBotName'
                    onChange={handleChange}
                    value={chatBotName}
                  />

                  <InputFile
                    deleteImagesList={deleteImagesList}
                    description={DESCRIPTION_LABELS.CHAT_BOT_ICON_DESCRIPTION}
                    disabled={isLoading}
                    label={INPUT_LABELS.CHAT_BOT_ICON_LOGO}
                    name='chatBotIcon'
                    onChange={handleChange}
                    setDeletedImage={setDeletedImage}
                    setFieldValue={setFieldValue}
                    value={chatBotIcon}
                  />
                  <InputFile
                    allowedFileTypes={allowedBrandingExtensions}
                    deleteImagesList={deleteImagesList}
                    label={INPUT_LABELS.FAVICON}
                    name='favicon'
                    setDeletedImage={setDeletedImage}
                    setFieldValue={setFieldValue}
                    value={favicon}
                  />
                </BrandingContentSection>
              )}
            </Grid.Column>
            <Grid.Column>
              <BrandingContentPreview
                backgroundImgUrl={readOnlyValues?.backgroundImage?.image}
                brandColor={readOnlyValues?.primaryColor}
                logoImgUrl={
                  readOnlyValues?.logInPageLogo?.image ??
                  readOnlyValues?.applicationLogo?.image
                }
                previewImgUrl={loginPreview}
                title='Login View'
                type='login'
              />
              <BrandingContentPreview
                brandColor={readOnlyValues?.primaryColor}
                brandColorAlt={readOnlyValues?.customColor}
                logoImgUrl={readOnlyValues?.applicationLogo?.image}
                previewImgUrl={applicationPreview}
                title='Application View'
                type='app'
              />
              <BrandingContentPreview
                baristaIconObject={readOnlyValues?.chatBotIconSVG}
                brandColor={readOnlyValues?.primaryColor}
                previewImgUrl={chatPreview}
                title='Chat View'
                type='chat'
                userImgUrl={usersAvatar}
              />
              {isCustomBrandingEnabled && (
                <BrandingContentSvgSafearea
                  description='In order for your custom bot icon to display correctly in all locations in our user interface, it must safe area padding. Use a 512px grid and maintain 64px gutter. Your icon should not extend into the red area in the preview below. This icon should not have any background fill and it should only have a single color fill. This fill color will change to match the color configured in the "Primary Color" controls on the left.'
                  iconObject={readOnlyValues?.chatBotIconSVG} // Note that this icon is not properly formatted according to the guidelines. One needs to be created.
                  safeAreaImgUrl={safeArea}
                  title='Bot Icon Safe Area'
                />
              )}
            </Grid.Column>
          </Grid>
        </FormikForm>
      </Segment>
      <Segment attached='bottom' clearing secondary>
        <Button
          aria-label={INPUT_LABELS.SAVE}
          basic
          content={INPUT_LABELS.SAVE}
          disabled={shouldDisableSubmitButton}
          floated='right'
          loading={isLoading}
          onClick={handleSubmit}
          primary
          type='button'
        />
        <Button
          aria-label={INPUT_LABELS.CANCEL}
          basic
          content={INPUT_LABELS.CANCEL}
          disabled={shouldDisableCancelButton}
          floated='right'
          loading={isLoading}
          onClick={handleReset}
          type='button'
        />
      </Segment>
    </>
  );
};

BrandingContent.propTypes = propTypes;

export { imageProps };

export default BrandingContent;
