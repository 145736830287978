import { matchPath } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import _ from 'lodash';

const formatPattern = (pattern, params) => {
  const evaluablePattern = pathToRegexp.compile(pattern);
  return evaluablePattern(params);
};

const getParams = (pattern, path) => {
  const match = matchPath(path, {
    path: pattern,
  });

  return match ? match.params : {};
};

class PathGenerator {
  constructor(pathsTree) {
    this.pathsTree = pathsTree;
  }

  /**
   * @param dotExpression A.B.C like String
   * @return {Array} Transform A.B.C into ['A', 'B', 'C']
   */
  splitDotExpression(dotExpression) {
    // make sure it's a String
    dotExpression = String(dotExpression);

    return dotExpression.split('.');
  }

  genFullPattern(dotExpression) {
    const tokens = this.splitDotExpression(dotExpression);
    return this.recursiveGenFullPattern(this.pathsTree, tokens, '');
  }

  recursiveGenFullPattern(pathNode, tokens, pattern) {
    const augmentedPattern = this.augmentPattern(pattern, pathNode);

    if (_.isEmpty(tokens)) {
      return augmentedPattern;
    }

    const nextToken = _.head(tokens);

    if (!_.has(pathNode.children, nextToken)) {
      throw new Error(`No path node defined for ${nextToken}.`);
    }

    const nextPathNode = _.get(pathNode.children, nextToken);
    const remainingTokens = _.tail(tokens);

    return this.recursiveGenFullPattern(
      nextPathNode,
      remainingTokens,
      augmentedPattern
    );
  }

  //
  // Augments 'pattern' with 'pathNode'.
  //
  augmentPattern(pattern, pathNode) {
    if (_.isEmpty(pathNode.pattern)) {
      return pattern;
    } else {
      return `${pattern}/${pathNode.pattern}`;
    }
  }

  getPattern(dotExpression) {
    const tokens = this.splitDotExpression(dotExpression);
    return this.recursiveGetPattern(this.pathsTree, tokens);
  }

  recursiveGetPattern(pathNode, tokens) {
    if (_.isEmpty(tokens)) {
      return pathNode.pattern;
    }

    const nextToken = _.head(tokens);

    if (!_.has(pathNode.children, nextToken)) {
      throw new Error(`No path node defined for ${nextToken}.`);
    }

    const nextPathNode = _.get(pathNode.children, nextToken);
    const remainingTokens = _.tail(tokens);

    return this.recursiveGetPattern(nextPathNode, remainingTokens);
  }

  /**
   * @param dotExpression String of the form A.B.C
   * @param params Object
   * @param options Object
   */
  genPath(dotExpression, params = {}, options = {}) {
    try {
      const fullPattern = this.genFullPattern(dotExpression);
      return formatPattern(fullPattern, params);
    } catch (e) {
      // Prevents whole app from blowing up in a blank screen
      // If the path was not generated for bad format or missing params
      if (
        !options.disableLogError &&
        _.hasIn(process, 'env.NODE_ENV') &&
        process.env.NODE_ENV !== 'test'
      ) {
        // eslint-disable-next-line no-console -- debugging
        console.error(e);
      }

      // Note: Update UIPathGenerator.findPathToNode if you change the value for unresolved url
      return '';
    }
  }

  /**
   * Convert the pathname in dotExpression
   * @param pathname String of the form /A/B
   * @returns {string}
   */
  genDotExpression(pathname) {
    if (pathname.indexOf('/') === -1) {
      // eslint-disable-next-line no-console -- debugging
      console.error(`Invalid pathname ${pathname}.`); // Invalid pathname
      return '';
    }

    const splitedPath = pathname.split('/');
    let finalDotPath = '';

    splitedPath.forEach((path, i) => {
      const isPathANumber = Number(path);
      const isNotAParameterURL = _.isNaN(isPathANumber);

      if (path && isNotAParameterURL) {
        const addDot = i > 1 ? '.' : '';
        finalDotPath = finalDotPath + addDot + path;
      }
    });

    return finalDotPath;
  }

  /**
   * @param dotExpression String of the form A.B.C
   * @param oldPath String that matches full pattern for 'dotExpression'
   * @param params Object
   */
  replacePath(dotExpression, oldPath, params = {}) {
    const fullPattern = this.genFullPattern(dotExpression);
    const oldParams = getParams(fullPattern, oldPath);
    const replaceParams = Object.assign(oldParams, params);

    return formatPattern(fullPattern, replaceParams);
  }

  isPartialMatch(dotExpression, path) {
    const fullPattern = this.genFullPattern(dotExpression);
    const match = matchPath(path, {
      path: fullPattern,
    });

    return Boolean(match);
  }
}

export default PathGenerator;
