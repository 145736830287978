const PersistentStorageKeys = {
  CURRENT_USER: 'CURRENT_USER', // not used, but leaving it here so we can clear it on logout
  SESSION: 'SESSION',
  TENANT_URL: 'TENANT_URL',
};

const StoragePrefix = 'ESPRESSIVE_';
const getStorageKey = (key) => `${StoragePrefix}${key}`;
const TOKEN_KEY = 'accessToken';
const IS_TOKEN_ENCRYPTED = 'isEncrypted';

export { IS_TOKEN_ENCRYPTED };
export { TOKEN_KEY };
export { getStorageKey };
export { StoragePrefix };
export default PersistentStorageKeys;
