/* eslint-disable sort-keys -- backend shape */
const data = {
  results: [
    { application: 'Company Holidays', total: 4 },
    { application: '401a', total: 2 },
  ],
  count: 2,
};
/* eslint-enable sort-keys */

export default data;
