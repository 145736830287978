const api_authentication_v0_1_auth_backend = {
  auth_backend: 'AZURE',
  azure_client_id: '123',
  azure_client_secret: '123',
  azure_tenant_id: '123',
  enabled: true,
  id: 12,
  name: '123',
  ordering: 1,
};

export default api_authentication_v0_1_auth_backend;
