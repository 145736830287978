import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureLighting extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path d='M45.41 65H0V.655h45.41z' id='a' />
        </defs>
        <g fill={color} fillRule='evenodd'>
          <path
            d='M33.734 39.022c-.45.437-.858.566-1.682.54-.708-.025-1.363-.324-1.845-.842-.476-.513-.724-1.178-.697-1.874.027-.69.09-.98.43-1.45.325-.445.227-1.07-.22-1.395-.447-.323-1.07-.224-1.397.22-.632.87-.773 1.568-.81 2.55-.05 1.23.388 2.408 1.23 3.313.846.912 1.997 1.438 3.24 1.48.084.003.166.004.246.004 1.225 0 2.103-.337 2.895-1.106.396-.384.405-1.02.02-1.414-.384-.396-1.017-.405-1.413-.02'
            fill='#F00'
          />
          <g transform='translate(25 15.345)'>
            <mask fill='#fff' id='b'>
              <use xlinkHref='#a' />
            </mask>
            <path
              d='M39.842 40.066c-2.014 0-3.646-1.632-3.646-3.646 0-2.013 1.632-3.646 3.646-3.646 2.014 0 3.646 1.633 3.646 3.646 0 2.014-1.632 3.646-3.646 3.646M23.14 18.88l-6.73 6.788-13.59-13.59 6.805-6.885c1.78-1.64 4.078-2.538 6.513-2.538 2.57 0 4.988 1 6.806 2.82 3.696 3.695 3.793 9.575.196 13.406m16.665 11.987c-1.07 0-2.066.306-2.916.827l-12.11-11.65c3.89-4.32 3.986-10.726.34-15.14l1.304-1.294c.393-.39.395-1.023.006-1.416-.39-.392-1.02-.395-1.413-.005l-1.302 1.29C21.608 1.656 18.952.653 16.138.653c-3.106 0-6.025 1.21-8.22 3.405L0 12.084l16.417 16.417 6.967-7.026 12.007 11.55c-.74.952-1.19 2.145-1.19 3.444 0 1.445.556 2.76 1.457 3.754L22.93 53.334c-5.928.037-10.725 4.85-10.725 10.79 0 .295.02.586.044.876h21.49c.025-.29.045-.58.045-.877 0-5.118-3.566-9.394-8.346-10.502l11.81-12.165c.766.394 1.633.622 2.554.622 3.09 0 5.604-2.515 5.604-5.605 0-3.09-2.516-5.604-5.606-5.604'
              fill='#F00'
              mask='url(#b)'
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default CatalogFurnitureLighting;
