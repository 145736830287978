import Immutable from 'immutable';
import getFAQServiceDeptIDSelected from '../selectors/getFAQServiceDeptIDSelected';
import BuildKBLink from './BuildKBLink';

const possibleCondition = [
  {
    name: 'user.job_role__EQ=', // Keep this previous version of condtion to keep the compatibility
    type: 'job_roles',
  },
  {
    name: 'user.job_role.id__EQ=',
    type: 'job_roles',
  },
  {
    name: 'user.location.ancestry.ancestry_id_list__INTC=',
    type: 'locations',
  },
  {
    name: 'user.department.ancestry.ancestry_id_list__INTC=',
    type: 'departments',
  },
  {
    name: 'user.user_state__EQ=',
    type: 'include_new_hire',
  },
  {
    name: 'user.remote_worker__IBOOL=',
    type: 'include_remote',
  },
  {
    name: 'user.computer_type__EQ=',
    type: 'computerType',
  },
  {
    name: 'user.mobile_type__EQ=',
    type: 'mobileType',
  },
  {
    name: 'IT_OperatingSystems__EQ=',
    type: 'deviceType',
  },
];

const buildFAQobj = (state) => {
  if (!state || !Immutable.Map.isMap(state) || state.isEmpty()) {
    return {
      error: new Error('Wrong state passed'),
    };
  }
  const kbLink = BuildKBLink();

  const dataScratch = {
    'scratch.temp_data': {
      // phrase variations - each entry looks 0:{phrase: 'phrase to add'}
      category_faq: {
        serviceDepartment: getFAQServiceDeptIDSelected(state),
      },

      faq_desc: null,

      // Category selected - {id: 79, name: '14-directory', serviceDepartment: 4}
      faq_question: null,

      faq_response: {},

      // main question ( the first one)
      faq_trigger: '',

      is_faq_protected: false,
      // description of the FAQ
      phrases_faq: {},
      // Map of responses - see below
      serviceTeam: null,
      service_team_faq: null,
    },
  };
  /**
   * Each entry for faq_response
   '0': {
            response: 'first response',
            locationdeptrole: {
              locations: [
                {
                  id: 1,
                  name: 'USA'
                }
              ]
            }
          }
   */

  /**
   * Load the selected FAQ then build the BE scratch object
   */
  const selectedEID = state.getIn(['adminFaqs', 'selectedFAQtoEdit']);

  if (!selectedEID) {
    return {
      error: new Error(
        'There is no FAQ EID passed to this WF, maybe you launched this WF outside the Admin area'
      ),
    };
  }

  /**
   * Select the FAQ
   */
  const faqList = state.getIn(['adminFaqs', 'faqsList']);
  if (!faqList || faqList.isEmpty()) {
    return {
      error: new Error('There is no FAQ List - Check the network'),
    };
  }
  const selectedFAQ = faqList.find((f) => f.get('eid') === selectedEID);

  if (!selectedFAQ || selectedFAQ.isEmpty()) {
    return {
      error: new Error(
        `There is a data issue with this FAQ EID: ${selectedEID}`
      ),
    };
  }

  dataScratch['scratch.temp_data'].faq_desc = selectedFAQ.get('description');

  /**
   * Build Question and Phrases
   */
  const triggersList = selectedFAQ.get('triggers', Immutable.List());

  /**
   * No need to pass faq_question since this field is not in this WF
   */
  triggersList.forEach((t, i) => {
    if (i === 0) {
      dataScratch['scratch.temp_data'].faq_trigger = t.get('phrase');
    }
    dataScratch['scratch.temp_data'].is_faq_protected =
      t.get('is_protected') ||
      dataScratch['scratch.temp_data'].is_faq_protected;
    dataScratch['scratch.temp_data'].phrases_faq[i] = {
      phrase: t.get('phrase'),
    }; // Pass all triggers here
  });

  const locDeptJobToLoad = {
    computerType: [],
    departments: [],
    deviceType: [],
    job_roles: [],
    locations: [],
    mobileType: [],
  };

  /**
   * Build the conditions here
   */
  const answers = selectedFAQ.get('answers');

  let entry = -1;

  answers.forEach((a) => {
    const locationdeptrole = {};
    // $eval.label__EQ=false user.location.ancestry.ancestry_id_list__INTC=2
    if (a.get('condition')) {
      const listCondition = a
        .get('condition')
        .split(/&OR|&/g)
        .filter((cond) => cond); // Filter empty condition

      possibleCondition.forEach((cond) => {
        listCondition.forEach((l) => {
          // Check for non custom condition here
          if (l.match(cond.name)) {
            const [, idToPass] = l.split(cond.name);

            if (
              cond.name === possibleCondition[4].name ||
              cond.name === possibleCondition[5].name
            ) {
              locationdeptrole[cond.type] = true;
            } else {
              if (!locationdeptrole[cond.type]) {
                locationdeptrole[cond.type] = [];
              }

              locationdeptrole[cond.type].push({
                id: idToPass,
                name:
                  cond.type === 'mobileType' || cond.type === 'computerType'
                    ? idToPass
                    : '', // Need to load this name later in the waterfall
              });
            }

            // if (locDeptJobToLoad[cond.type] && !locDeptJobToLoad[cond.type].find((c) => c === idToPass)) {
            // The commented code above was preventing two answer sets from having the same condition type
            // (the second answer set was getting its condition removed)
            // there seems to be no reason for it, so I'm changing that
            if (locDeptJobToLoad[cond.type]) {
              locDeptJobToLoad[cond.type].push(idToPass); // Will be in use to load the name
            }
          } else {
            // Check there is no match at all for unknow condition (custom condition)
            let noMAtch = true;
            for (let i = 0; i < possibleCondition.length; i++) {
              if (l.match(possibleCondition[i].name)) {
                noMAtch = false;
                break;
              }
            }

            if (noMAtch && !locationdeptrole[l]) {
              // Custom condition found !
              locationdeptrole[l] = [];
              locationdeptrole[l].push({
                id: null,
                name: l,
              });
            }
          }
        });
      });
    }

    // Sort the lines by order
    const lines = a.get('lines', Immutable.fromJS([])).sort((a, b) => {
      const orderA = a.get('order'),
        orderB = b.get('order');
      return orderA < orderB ? -1 : orderA > orderB ? 1 : 0;
    });

    lines.forEach((l) => {
      // Add new entry for every line
      const order = l.get('order');
      entry++;

      // if it's protected then it's going to be active=false because we only want to disable it
      // we need to check that is still active
      // to not keep creating additional duplicates
      const isProtectedAndActive = l.get('is_protected') && l.get('active');
      const gonnaBeActive = isProtectedAndActive ? true : l.get('active');

      dataScratch['scratch.temp_data'].faq_response[entry] = {
        active: gonnaBeActive,
        answerEID: a.get('eid'),
        eid: l.get('eid'),
        is_protected: l.get('is_protected'),
        locationdeptrole: locationdeptrole,
        main_answer_active: a.get('active'),
        main_answer_is_protected: a.get('is_protected'),
        order: order,
        response: l.get('phrase'),
      };

      if (l.get('is_protected')) {
        dataScratch['scratch.temp_data'].is_faq_protected = true;
      }

      if (l.get('url')) {
        // The URL img
        dataScratch['scratch.temp_data'].faq_response[entry].url = l.get('url');
      }
    });
  });

  // The we build an FAQ from Snow Knowledge, add as a new response the link
  if (kbLink) {
    // we want to add a *new* response group, that's we we have to increase this guy
    // to not override whatever was there previously
    entry++;
    dataScratch['scratch.temp_data'].faq_response[entry] = {
      locationdeptrole: {},
      order: 999,
      response: kbLink,
    };
  }

  /**
   * Get the attributes
   */
  const attributes = selectedFAQ.get('attributes', Immutable.fromJS([]));
  // We want for now to allow empty attribute
  // if (!attributes || attributes.isEmpty()) {
  //   return {error: new Error('There is a data issue with this FAQ EID: ' + selectedEID + ' - No attributes exist')};
  // }

  /**
   * Pass service Team
   */
  let serviceTeam = attributes.find(
    (at) =>
      at.get('name') === 'service_team' ||
      at.get('name') === 'esp.service_team_type'
  );

  if (serviceTeam && !serviceTeam.isEmpty()) {
    serviceTeam =
      serviceTeam && !serviceTeam.isEmpty() ? serviceTeam.get('value') : null;
    dataScratch['scratch.temp_data'].serviceTeam = serviceTeam;
    dataScratch['scratch.temp_data'].service_team_faq = serviceTeam;
  }

  /**
   * Pass Category
   */
  let catSelected = null;
  const category = attributes.find(
    (at) =>
      at.get('name') === 'category' ||
      at.get('name') === 'esp.task_category_type'
  );

  if (category && !category.isEmpty()) {
    dataScratch['scratch.temp_data'].category_faq.eid = category.get('value');
    dataScratch['scratch.temp_data'].category_faq.name = selectedFAQ.get(
      'application_name'
    );

    /**
     * Get the category name
     */
    const categoryResults = state.getIn(['cases', 'taskCategoryResults']);
    catSelected = categoryResults.find(
      (c) => c.get('eid') === dataScratch['scratch.temp_data'].category_faq.eid
    );
  }

  return {
    category:
      catSelected ||
      Immutable.fromJS({
        eid: dataScratch['scratch.temp_data'].category_faq.eid,
      }), // Category name selected
    dataScratch: dataScratch,
    locDeptJobToLoad: locDeptJobToLoad,
  };
};

export default buildFAQobj;
