import _ from 'lodash';

// Stores
import store from '../stores/store';

// Selector
import getCurrentUser from '../selectors/getCurrentUser';
// Actions
import actionTypes from '../actions/actionTypes';

// Utils
import browserHistory from './browserHistory';
import uiPathGenerator from './uiPathGenerator';
import uiPaths from './uiPaths';
import uiPathPermissions from './uiPathPermissions';

// Get List a top level path
const rootPath = {};
_.forIn(uiPaths.children, (value, key) => {
  rootPath[value.pattern] = {
    realName: key,
  };
  _.forIn(value.children, (subValue, subKey) => {
    rootPath[value.pattern][subValue.pattern] = {
      realName: subKey,
    };
  });
});

/**
 * Check the current pathname and build a new one with just 2 levels
 * @param pathname {string} current full pathname
 * @returns {string} Final pathname with just two first level
 */
const buildSubRoute = (pathname) => {
  const splitedPath = pathname.split('/');

  let currentRootPath = '';

  splitedPath.forEach((path, i) => {
    const keyRoot = _.findKey(rootPath, (valueRoot, key) => key === path); // Try to find the root lvl in the tree

    if (keyRoot) {
      // We found the top level of the path - Need to check the children patterns
      const mainPath = rootPath[keyRoot].realName; // Set the realname to the path to check

      const subRoute = splitedPath[i + 1]; // Check the next path entry in the selected root path.
      let subPath = '';

      if (subRoute) {
        const keySubRoot = _.findKey(
          rootPath[keyRoot],
          (valueSubRoot, key) => key === subRoute
        );

        if (keySubRoot) {
          // We found the sub level of the path
          subPath = `/${rootPath[keyRoot][keySubRoot].realName}`;
        }
      }

      currentRootPath = `/${mainPath}${subPath}`;
    }
  });

  return currentRootPath;
};

/**
 * Return the first available route for user base
 * on his GROUP permission
 * @returns {*}
 * @private
 */
const _firstAdminPathname = () => {
  const currentUser = getCurrentUser(store.getState());
  if (!currentUser) {
    return uiPathGenerator.genPath('admin.interactionsDashboard');
  }

  const userPermissions = currentUser.get('groups');
  const isAdmin = currentUser.get('is_admin');

  if (isAdmin) {
    // As an ADMIN, the user have access to everything. Use the normal redirection
    return uiPathGenerator.genPath('admin.interactionsDashboard');
  }

  let firstPath;

  // Get the first allowed ROUTE in ADMIN
  Object.keys(uiPaths.children.admin.children).forEach((key) => {
    if (!firstPath) {
      const allPermissions = uiPathPermissions();
      const permission =
        allPermissions.admin[key] && allPermissions.admin[key].permissions;
      if (permission) {
        userPermissions.forEach((perm1) => {
          if (!firstPath) {
            permission.forEach((perm2) => {
              if (!firstPath && perm2 === perm1) {
                firstPath = key;
              }
            });
          }
        });
      }
    }
  });

  if (firstPath) {
    return uiPathGenerator.genPath(`admin.${firstPath}`);
  }

  return uiPathGenerator.genPath('admin.interactionsDashboard');
};

/**
 * Check the permission that belong a user and pathname
 * @param fakeState {Immutable Map} For testing purpose
 * @param fakePathname {string} For testing purpose
 * @returns {boolean} Return true if the user is not allow
 */
const checkPermissions = (fakeState, fakePathname) => {
  const currentRootPath = buildSubRoute(
    fakePathname || browserHistory.location.pathname
  ); // Get the first and second pathname
  if (!currentRootPath) {
    // The error URL doesn't exist
    return false;
  }

  const state = fakeState || store.getState();

  const permissions = uiPathPermissions();
  const dotExpression = uiPathGenerator.genDotExpression(currentRootPath);
  const currentPermissions = _.get(permissions, dotExpression);

  if (!currentPermissions) {
    // no permissions then we allow
    return false;
  }

  const pathPermissions = currentPermissions.permissions;
  const { noFullPermissionForAdmin } = currentPermissions;

  const currentUser = getCurrentUser(state);

  if (!currentUser) {
    return false;
  }

  let userPermissions = currentUser.get('groups');
  const isAdmin = currentUser.get('is_admin');

  // Fix Super ADMIN permission - Because a super ADMIN will not be part of the ADMIN group
  if (isAdmin && !userPermissions.find((perm) => perm === 'ADMIN')) {
    userPermissions = userPermissions.push('ADMIN');
  }

  if (isAdmin && !noFullPermissionForAdmin) {
    return false;
  }

  let nextUrl = browserHistory.location.pathname;
  nextUrl = nextUrl.includes('/login') ? nextUrl.split('/login')[0] : nextUrl;

  if (browserHistory.location.search) {
    nextUrl += browserHistory.location.search; // If Query param was passed to the URL
  }
  // Check if the user have the permission
  let isAuthorize = pathPermissions.length === 0;
  pathPermissions.forEach((allowedRole) => {
    userPermissions.forEach((perm) => {
      if (!isAuthorize && perm === allowedRole) {
        isAuthorize = true;
      }
    });
  });

  // User not allow to continue
  if (!isAuthorize) {
    store.dispatch({
      previousUrl: nextUrl,
      type: actionTypes.USER_SET_PREVIOUSURL,
    });
    let redirect = '/';
    const dpcPath = uiPathGenerator.genPath('admin.dpc');
    const fromDPC = browserHistory.location.pathname.match(dpcPath);
    if (fromDPC) {
      redirect = _firstAdminPathname();

      if (redirect.match(dpcPath)) {
        redirect = '/';
      }
    }

    browserHistory.replace(redirect);
    return true;
  }

  return false;
};

export const firstAdminPathname = _firstAdminPathname;

export default checkPermissions;
