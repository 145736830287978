// import PropTypes            from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import {
  // Checkbox,
  Segment,
  Table,
} from 'semantic-ui-react';
import immutable from 'immutable';

const propTypes = {
  productFamily: ImmutablePropTypes.map,
};

const AdminProductFamily = ({ productFamily = immutable.Map() }) => {
  const products = productFamily.get('products') || immutable.List();
  const pf_options = products.getIn([0, 'pf_options']) || immutable.List();
  return (
    <Segment attached='bottom'>
      <Table compact definition fixed selectable singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing />
            <Table.HeaderCell content='Model' />
            {pf_options
              .sortBy((o) => o.get('option_id'))
              .map((o) => (
                <Table.HeaderCell
                  content={o.get('option')}
                  data-option-id={o.get('option_id')}
                  key={o.get('option_id')}
                />
              ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {products.map((p) => {
            const options = p.get('pf_options');
            return (
              <Table.Row key={p.get('id')}>
                <Table.Cell content={''} />
                <Table.Cell content={p.get('model_no')} />
                {options
                  .sortBy((o) => o.get('option_id'))
                  .map((o) => (
                    <Table.Cell
                      content={o.get('value')}
                      key={`${o.get('option_id')}-${p.get('id')}`}
                    />
                  ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Segment>
  );
};

AdminProductFamily.propTypes = propTypes;

export default AdminProductFamily;
