// This implementation is based on
// https://github.com/chenglou/react-motion/tree/master/demos/demo8-draggable-list
import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import { curry, memoize, noop, range } from 'lodash';

function reinsert(arr, from, to) {
  const _arr = arr.slice(0);
  const val = _arr[from];
  _arr.splice(from, 1);
  _arr.splice(to, 0, val);
  return _arr;
}

const springConfig = {
  damping: 35,
  stiffness: 650,
};

const propTypes = {
  //  as: PropTypes.oneOfType([
  //    PropTypes.string,
  //    PropTypes.func,
  //  ]),
  children: PropTypes.node.isRequired,
  dynamicHeightChildren: PropTypes.bool,

  // TODO this feature is experimental, need to revisit the reinsert function with a more clever approach
  // callback to allow fixing the position of certain items
  // the position of the item is passed as argument,
  // must return true or false
  isItemLocked: PropTypes.func,

  onOrderChange: PropTypes.func,
};

const defaultProps = {
  dynamicHeightChildren: false,
  isItemLocked: () => false,
  onOrderChange: noop,
};

class DragDrop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPressed: false,
      itemsHeights: null,
      mouseY: 0,
      order: null,

      originalPosOfLastPressed: 0,

      topDeltaY: 0,
      totalHeight: null,
    };
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.handleTouchMove);
    window.addEventListener('touchend', this.handleMouseUp);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    window.addEventListener('resize', this.calculateHeight);

    this.observer = new MutationObserver(() => {
      this.calculateHeight();
    });

    const { dynamicHeightChildren } = this.props;
    this.observer.observe(this.dragElement, {
      childList: true,
      subtree: dynamicHeightChildren,
    });

    this.calculateHeight();
  }

  componentWillUnmount() {
    // cleanup
    window.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('touchend', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    window.removeEventListener('resize', this.calculateHeight);

    this.observer.disconnect();
  }

  setRef = (ref) => {
    this.dragElement = ref;
  };

  calculateHeight = () => {
    const { children } = this.props;
    const childrenArray = React.Children.toArray(children);

    // required because domNode.children is a HTMLCollection
    const childrenDomNodes = Array.from(this.dragElement.children);

    const itemsHeights = new Map();
    let totalHeight = 0;

    childrenDomNodes.forEach((childDomNode, i) => {
      const childHeight = childDomNode.offsetHeight;

      const { key } = childrenArray[i];

      itemsHeights.set(key, childHeight);

      totalHeight += childHeight;
    });

    this.setState({
      itemsHeights,
      totalHeight,
    });
  };

  handleTouchStart = memoize(
    curry((e, key, pressLocation) => {
      this.handleMouseDown(key, pressLocation, e.touches[0]);
    })
  );

  handleTouchMove = (e) => {
    e.preventDefault();
    this.handleMouseMove(e.touches[0]);
  };

  handleMouseDown = memoize(
    curry((position, pressY, { pageY }) => {
      const { children } = this.props;

      const itemsCount = React.Children.count(children);

      this.setState({
        isPressed: true,
        mouseY: pressY,
        order: range(itemsCount),
        originalPosOfLastPressed: position,

        topDeltaY: pageY - pressY,
      });
    })
  );

  handleMouseMove = ({ pageY }) => {
    const { children } = this.props;

    const childrenArray = React.Children.toArray(children);
    const itemsCount = childrenArray.length;

    const {
      isPressed,
      topDeltaY,
      order,
      originalPosOfLastPressed,
    } = this.state;

    if (isPressed) {
      const mouseY = pageY - topDeltaY;

      let currentRow = itemsCount - 1;
      let accumulatedHeight = 0;

      const { itemsHeights } = this.state;
      const { isItemLocked } = this.props;

      // find the lowest i such that the accumulated sum of heights
      // is greater than mouseY
      // TODO This could benefit from binary search!
      for (let i = 0; i < itemsCount; i++) {
        const h = itemsHeights.get(childrenArray[order[i]].key);

        if (accumulatedHeight + h / 2 > mouseY) {
          currentRow = i;
          break;
        }

        accumulatedHeight += h;
      }

      // console.log(mouseY, currentRow);
      // const currentRow = clamp(Math.round(mouseY / 49), 0, itemsCount - 1);

      let newOrder = order;

      // is the dragged item over a locked item?
      const isCurrentRowLocked = isItemLocked(currentRow);

      if (
        !isCurrentRowLocked &&
        currentRow !== order.indexOf(originalPosOfLastPressed)
      ) {
        newOrder = reinsert(
          order,
          order.indexOf(originalPosOfLastPressed),
          currentRow
        );
      }

      this.setState({
        mouseY: mouseY,
        order: newOrder,
      });
    }
  };

  handleMouseUp = () => {
    const { isPressed } = this.state;
    const { onOrderChange } = this.props;

    const wasPressed = isPressed;

    this.setState(
      {
        isPressed: false,
        topDeltaY: 0,
      },
      () => {
        if (wasPressed) {
          const { order } = this.state;
          const didOrderChange = order.some(
            (orderedIndex, index) => orderedIndex !== index
          );

          if (didOrderChange) {
            onOrderChange(order);
          }
        }
      }
    );
  };

  render() {
    const { children, isItemLocked } = this.props;

    const childrenArray = React.Children.toArray(children);

    const { mouseY, isPressed, originalPosOfLastPressed, order } = this.state;

    const { itemsHeights } = this.state;
    const { totalHeight } = this.state;

    return (
      <div
        ref={this.setRef}
        style={{
          height: `${totalHeight}px`,
        }}
      >
        {childrenArray.map((child, i) => {
          let distanceFromTop = 0;

          // calculate item vertical position based on
          // ordering and other items height
          if (itemsHeights) {
            const indexInOrder = isPressed ? order.indexOf(i) : i;
            // let indexInOrder = order.indexOf(i);

            for (let k = 0; k < indexInOrder; k++) {
              let newKey;

              if (isPressed) {
                newKey = childrenArray[order[k]].key;
              } else {
                newKey = childrenArray[k].key;
              }

              if (itemsHeights.has(newKey)) {
                distanceFromTop += itemsHeights.get(newKey);
              }
            }
          }

          // Apply different style to the element being moved
          const style =
            originalPosOfLastPressed === i && isPressed
              ? {
                  scale: spring(1.02, springConfig),
                  shadow: spring(16, springConfig),
                  y: mouseY,
                }
              : {
                  scale: spring(1, springConfig),
                  shadow: spring(1, springConfig),
                  y: spring(distanceFromTop, springConfig),
                };

          const isLocked = isItemLocked(i);

          return (
            <Motion key={child.key} style={style}>
              {({ scale, shadow, y }) =>
                React.cloneElement(child, {
                  onMouseDown: isLocked ? noop : this.handleMouseDown(i, y),
                  onTouchStart: isLocked ? noop : this.handleTouchStart(i, y),

                  // TODO does this is good performance wise?
                  // merge child style with the one to control it's position
                  style: Object.assign(
                    {},
                    {
                      WebkitTransform: `translate3d(0, ${y}px, 0) scale(${scale})`,
                      boxShadow: `rgba(0, 0, 0, 0.2) 0px ${shadow}px ${
                        2 * shadow
                      }px 0px`,

                      margin: 0,

                      // TODO should we move these styles to a CSS file?
                      position: 'absolute',

                      transform: `translate3d(0, ${y}px, 0) scale(${scale})`,
                      transformOrigin: '50% 50% 0px',
                      width: '100%',
                      zIndex: i === originalPosOfLastPressed ? 99 : i,
                    },
                    child.props.style || {}
                  ),
                })
              }
            </Motion>
          );
        })}
      </div>
    );
  }
}

DragDrop.propTypes = propTypes;

DragDrop.defaultProps = defaultProps;

export default DragDrop;
