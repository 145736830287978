// Actions
import searchActions from './searchActions';
// Utils
import SearchContexts from '../utils/SearchContexts';
import SearchModels from '../utils/SearchModels';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

/**
 * We should only accept results for the 'userInput' and 'appModel' we are
 * interested in.
 * @param {string} userInput
 * @param {string} model
 * @param stateAfter
 */
const shouldAccept = (userInput, appModel, stateAfter) => {
  const newSearchState = stateAfter.get('search');
  const newUserInput = newSearchState.get('userInput');

  const newContextName = newSearchState.get('contextName');

  if (!newContextName) {
    return false;
  }

  const newSearchContext = SearchContexts.find(
    (context) => context.name === newContextName
  );
  const newAppModel = newSearchContext.appModel;

  return userInput === newUserInput && appModel === newAppModel;
};

const searchThunks = {};

searchThunks.search = (filter) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const searchState = state.get('search');

    const userInput = searchState.get('userInput');

    const contextName = searchState.get('contextName');
    const searchContext = SearchContexts.find(
      (context) => context.name === contextName
    );
    const model = searchContext.appModel;

    // TODO we are doing search for feed client side, so there is nothing to do here!
    if (model === SearchModels.FEED_CARD) {
      // ToDo: remove FE-filterng in getFilteredCards.js once this gets implemented
      // -Erick, December 2018.
      return;
    }

    let url;

    if (model === SearchModels.DIRECT_CHAT_CHANNEL) {
      url = endpointGenerator.genPath('espChat.directChannels.searchMessages');
    } else if (model === SearchModels.SCOPED_CHAT_CHANNEL) {
      url = endpointGenerator.genPath('espChat.scopedChannels.searchMessages');
    } else if (model === SearchModels.CATALOG_PRODUCT) {
      url = endpointGenerator.genPath('espSearch.catalog');
    } else if (model === SearchModels.EQUIPMENT) {
      url = endpointGenerator.genPath('espSearch.myEquipment');
    } else {
      url = endpointGenerator.genPath('espSearch.typeAhead', {
        model,
      });
    }

    dispatch(searchActions.searchStart());

    const onResults = (error, results) => {
      const stateAfter = getState();

      if (!shouldAccept(userInput, model, stateAfter)) {
        return;
      }

      if (error) {
        dispatch(searchActions.searchFail(error));
        reject(error);
      } else {
        dispatch(searchActions.searchSuccess(results));
        resolve(results);
      }
    };

    const query = {
      q: userInput,
    };

    if (filter) {
      query.esp_filters = encodeURI(`groups__EQ=${filter}`);
    }

    APIcall.get({
      error(error) {
        onResults(error.response.body);
      },
      query,
      success(response) {
        const results = response.body;
        onResults(null, results);
      },
      token: true,
      url,
    });
  });

searchThunks.searchUsers = (filter) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const searchState = state.getIn(['search', 'userSearch']);

    const userInput = searchState.get('userInput');

    const url = endpointGenerator.genPath('espSearch.typeAhead', {
      model: SearchModels.USER,
    });

    dispatch(searchActions.searchUserStart());

    const onResults = (error, results) => {
      if (error) {
        dispatch(searchActions.searchUserFail(error));
        reject(error);
      } else {
        dispatch(searchActions.searchUserSuccess(results));
        resolve(results);
      }
    };

    const query = {
      q: userInput,
    };

    if (filter) {
      query.esp_filters = encodeURI(`groups__EQ=${filter}`);
    }

    APIcall.get({
      error(error) {
        onResults(error.response.body);
      },
      query,
      success(response) {
        const results = response.body;
        onResults(null, results);
      },
      token: true,
      url,
    });
  });

searchThunks.findSnowUser = (sysId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espUser.users.findUserRemoteId');

    dispatch(searchActions.searchUserStart());

    APIcall.post({
      data: {
        remote_id: sysId,
      },
      error(error) {
        dispatch(searchActions.searchUserFail(error));

        reject(error);
      },
      success({ body }) {
        const user = body;
        dispatch(searchActions.searchUserSuccess([user])); // this has to be passed as array so
        resolve(user);
      },
      token: true,
      url,
    });
  });

export default searchThunks;
