import APIcall from '../../../../../utils/APIcall';

const runSync = (runSyncURL) => {
  return APIcall.post({
    data: {},
    token: true,
    url: runSyncURL,
  });
};

export default runSync;
