import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Image } from 'semantic-ui-react';
import { fromJS } from 'immutable';
import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Controller
import TenantImageController from '../controllers/TenantImageController';
// Utils
import TenantImageTypes from '../../utils/TenantImageTypes';
// Global
import { ImageDefaults } from 'esp-assets';

// ES6 Map
const imageDefaultsByType = new Map();
imageDefaultsByType.set(TenantImageTypes.LOGO, ImageDefaults.COMPANY_LOGO);
imageDefaultsByType.set(
  TenantImageTypes.ALTERNATE_LOGO,
  ImageDefaults.COMPANY_LOGO
);
imageDefaultsByType.set(TenantImageTypes.AVATAR, ImageDefaults.COMPANY_AVATAR);
imageDefaultsByType.set(
  TenantImageTypes.BACKGROUND,
  ImageDefaults.COMPANY_LOGO
);
imageDefaultsByType.set(TenantImageTypes.META, ImageDefaults.COMPANY_LOGO);

class TenantImage extends PureComponent {
  static propTypes = {
    alternateLogo: ImmutablePropTypes.map,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    // the images attribute from a Tenant resource
    images: PropTypes.oneOfType([
      ImmutablePropTypes.list,
      ImmutablePropTypes.map,
    ]),
    isLoading: PropTypes.bool, // Not in use with this component
    loadTenantImages: PropTypes.func,
    type: PropTypes.oneOf([
      TenantImageTypes.LOGO,
      TenantImageTypes.AVATAR,
      TenantImageTypes.ALTERNATE_LOGO,
      TenantImageTypes.BACKGROUND,
      TenantImageTypes.META,
    ]),
  };

  static defaultProps = {
    alternateLogo: null,
    as: Image,
    images: fromJS({}),
    isLoading: false,
    loadTenantImages: _.noop,
    type: TenantImageTypes.LOGO,
  };

  componentDidMount() {
    const { loadTenantImages, type } = this.props;
    loadTenantImages(type);
  }
  render() {
    const {
      as,
      type,
      images,
      isLoading,
      loadTenantImages,
      alternateLogo,
      ...props
    } = this.props;

    let img = images
      .reverse()
      .filter((img) => img.get('type') === type)
      .minBy((img) => img.get('ordering'));

    if (
      type === TenantImageTypes.ALTERNATE_LOGO &&
      (!img || !img.has('image'))
    ) {
      img = images
        .reverse()
        .filter((img) => img.get('type') === TenantImageTypes.LOGO)
        .minBy((img) => img.get('ordering'));
    }

    let src;

    if (img) {
      // tries to use the svg version first
      src = img.get('svg') ? img.get('svg') : img.get('image');
    }

    src = src ? src : imageDefaultsByType.get(type);

    return React.createElement(as, {
      src,
      ...props,
    });
  }
}

export const TenantImageTest = TenantImage;

export default TenantImageController(TenantImage);
