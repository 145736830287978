import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOtherContinuingEducation extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M15.87 30c5.927-10.684 16.767-17.733 28.937-18.815C56.977 10.103 68.89 15.13 76.61 24.6l-5.05-.46c-.552-.05-1.04.358-1.09.91-.05.552.358 1.04.91 1.09l7.95.72h.09c.283 0 .554-.118.745-.327.19-.21.283-.49.255-.773l-.85-8.13c-.038-.357-.263-.667-.59-.814-.33-.146-.71-.106-1 .105-.292.214-.448.564-.41.92l.62 5.44C70.05 13.31 57.497 8.023 44.676 9.166c-12.82 1.144-24.24 8.57-30.486 19.825-.18.313-.177.697.004 1.008.182.31.515.5.875.5.36-.002.69-.195.87-.508l-.07.01zm65.48 35.47c-.232-.13-.506-.16-.76-.087-.256.073-.47.245-.6.477-5.928 10.687-16.772 17.736-28.945 18.817-12.173 1.08-24.088-3.95-31.805-13.427l5.12.47c.357.032.705-.13.91-.422.208-.293.242-.675.09-1-.15-.326-.463-.546-.82-.578L16.6 69c-.295-.028-.587.077-.797.285-.21.21-.318.5-.293.795l.68 7.92c.047.517.48.912 1 .91h.09c.27-.022.522-.155.694-.366.172-.21.25-.484.216-.754l-.45-5.23c8.143 9.97 20.698 15.253 33.52 14.105 12.82-1.147 24.238-8.578 30.48-19.835.13-.232.16-.506.087-.76-.073-.256-.245-.47-.477-.6z' />
          <path d='M79.19 41.15c.375-.154.62-.52.62-.925 0-.406-.245-.77-.62-.925L48.84 26.61c-.243-.1-.517-.1-.76 0l-30.8 12.58c-.375.154-.62.52-.62.925 0 .406.245.77.62.925l9.91 4.05v13.16c0 .172.045.34.13.49 2.87 5.12 11 8.43 20.61 8.43 10.26 0 21.17-3.42 21.17-9.76 0-.39.07-9.67.09-12.29l3.47-1.38v9.69c-.922.932-1.19 2.33-.677 3.536.513 1.206 1.704 1.983 3.015 1.966 1.31-.017 2.482-.826 2.963-2.045.48-1.22.178-2.61-.77-3.517V41.94l2-.79zM67.1 57.41c0 4.8-10 7.76-19.17 7.76-8.66 0-16.13-2.88-18.74-7.17V46l18.36 7.71c.246.103.524.103.77 0l18.87-7.8c0 3.35-.09 11.14-.09 11.5zm.85-14h-.14l-1.13.59-18.77 7.4L20.3 40.11l28.15-11.5L76.19 40.2l-8.24 3.21z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOtherContinuingEducation;
