import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Globals
import TypeButton, { TypeDefaults } from '../../../globals/blocks/TypeButton';

class Button01 extends Component {
  static propTypes = {
    ...TypeButton,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    loading: false,
    onClick: _.noop,
  };
  render() {
    const { type, template, text, loading, onClick, to } = this.props;

    return (
      <div
        className='block padding'
        data-blocktype={type}
        data-template={template}
      >
        <Button
          as={Link}
          content={text}
          loading={loading}
          onClick={onClick}
          primary
          to={to}
        />
      </div>
    );
  }
}

export default Button01;
