import { Button, Form, Icon, Input, Segment } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import BlockModal from '../../organisms/BlockModal';
import HierarchySelect from '../../organisms/HierarchySelect';
import TypeWorkAddress, {
  TypeDefaults,
} from '../../../globals/blocks/TypeWorkAddress';
import WorkAddressController from '../../controllers/WorkAddressController';
import LocationSelectController from '../../controllers/LocationSelectController';
import ValidatedCheckbox from '../../molecules/ValidatedCheckbox';
import ValidatedField from '../../molecules/ValidatedField';

const LocationHierarchySelect = LocationSelectController(HierarchySelect);

let WorkAddress01 = class WorkAddress01 extends Component {
  static propTypes = {
    ...TypeWorkAddress,
    changeLocationFrom: PropTypes.func.isRequired,
    defaultSelectedLocationID: PropTypes.number,

    locationID: PropTypes.number,
    onSelectLocation: PropTypes.func,
    remoteWorker: PropTypes.bool,
  };

  static defaultProps = {
    ...TypeDefaults,
    defaultSelectedLocationID: null,
    locationID: '',
    onSelectLocation: _.noop,
    remoteWorker: false,
  };

  componentDidMount() {
    const { changeLocationFrom, locationID } = this.props;
    if (locationID) {
      changeLocationFrom(locationID);
    }
  }

  setSelectLocationModal = (selectLocationModal) => {
    this.selectLocationModal = selectLocationModal;
  };

  handleSelectLocation = (location, fromClick) => {
    const { onSelectLocation } = this.props;
    const locationID = location.get('id');
    const { isOpen } = this.selectLocationModal.state;

    if (!isOpen || (isOpen && fromClick)) {
      onSelectLocation(locationID);
    }
  };

  handleCloseModal = () => {
    this.selectLocationModal.close();
  };

  formValidate = (val) => {
    const { inputRequired } = this.props;
    if (val || !inputRequired) {
      return '';
    }
    return 'error';
  };

  // TODO Connect loading state
  // renderEmptySelectionTrigger(isLoadingDefaultSelectedLocation){
  renderEmptySelectionTrigger = () => (
    <Segment className='link'>
      {intl.formatMessage({
        id: 'label.find_your_location',
      })}
    </Segment>
  );

  renderSelectionTrigger = (selectedLocation) => (
    <Segment className='link'>
      <div className='content'>
        <div className='location'>{selectedLocation.get('name')}</div>
        <Icon
          className='right floated'
          color='green'
          name='check'
          size='large'
        />
      </div>
    </Segment>
  );

  renderSelectNodeButton = () => (
    <Button size='large'>
      {intl.formatMessage({
        id: 'label.set_my_location',
      })}
    </Button>
  );

  renderModal = (
    trigger,
    dropdowns,
    selectNodeButton,
    selectedLocation,
    resetState,
    setDefault
  ) => {
    const { readOnly, template } = this.props;

    if (readOnly && selectedLocation && selectedLocation.has('name')) {
      return (
        <Input readOnly transparent value={selectedLocation.get('name')} />
      );
    }

    return (
      <BlockModal
        className={template}
        modalActions={selectNodeButton}
        modalTitle={intl.formatMessage({
          id: 'label.work_address',
        })}
        onClose={resetState}
        onOpen={setDefault}
        ref={this.setSelectLocationModal}
        trigger={trigger}
      >
        {dropdowns}
      </BlockModal>
    );
  };

  render() {
    const {
      defaultSelectedLocationID,
      id, // Block ID
      readOnly,
      remoteWorker,
      type,
      template,
    } = this.props;

    const classNames = readOnly
      ? {
          className: 'readOnly',
        }
      : null;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Form.Field {...classNames}>
          {intl.formatMessage({
            id: 'label.work_address',
          })}

          <LocationHierarchySelect
            defaultSelectedNodeID={defaultSelectedLocationID}
            displayFullAddress
            onChange={this.handleSelectLocation}
            onCloseModal={this.handleCloseModal}
            renderEmptySelectionTrigger={this.renderEmptySelectionTrigger}
            renderModal={this.renderModal}
            renderSelectNodeButton={this.renderSelectNodeButton}
            renderSelectionTrigger={this.renderSelectionTrigger}
            required
            typeNameAttr='location_type_name'
          />

          {readOnly && remoteWorker && (
            <Input readOnly transparent value={'Remote Worker'} />
          )}
        </Form.Field>

        {!readOnly && (
          <ValidatedCheckbox
            label='Remote Worker'
            name={`${id}-1`}
            readOnly={readOnly}
          />
        )}

        <ValidatedField
          hidden
          name='location_selected'
          validate={this.formValidate}
        />
      </div>
    );
  }
};

WorkAddress01 = WorkAddressController(WorkAddress01);

export default WorkAddress01;
