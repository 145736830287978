import pt from 'prop-types';
import React from 'react';
import {
  Dropdown,
  Grid,
  Header,
  List,
  Menu,
  Message,
  Segment,
} from 'semantic-ui-react';
import { curry, isEmpty, memoize } from 'lodash';

// Molecules
import EspListItem from '../../../../molecules/EspListItem';
import OffboardingTemplateIntervalModal from './OffboardingTemplateIntervalModal';
// Utils
import getDueDate from '../../../../../utils/getDueDate';
import OnboardActivitiesCategory from '../../../../../globals/OnboardActivitiesCategory';
import { fromJS } from 'immutable';

const toTitleCase = (str) =>
  str?.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const propTypes = {
  /** Action on a schedule ID (copy) */
  action: pt.string,
  /** List of activity templates to display in the group. */
  activities: pt.arrayOf(pt.shape({})),
  /** Delete template schedule */
  deleteOneTemplate: pt.func.isRequired,
  /** Read only state */
  readOnly: pt.bool,
  /** Schedule ID */
  scheduleID: pt.string,
  /** Title of the group. */
  title: pt.string.isRequired,
};

const OffboardingActivityScheduleGroup = (props) => {
  const onHandleDeleteTemplateSelected = memoize(
    curry((handleDeleteTemplateSelectedProps, e) => {
      e.preventDefault();
      const { deleteOneTemplate, scheduleID } = props;
      // Todo - Use intl translation here
      const msg = {
        header: 'Success',
        message: `The ${title} activity has been removed`,
      };
      const { interval, templateID } = handleDeleteTemplateSelectedProps;
      deleteOneTemplate({
        intervalID: interval.id,
        msg,
        scheduleID,
        templateID: templateID,
      });
    })
  );

  const { action, activities, readOnly, title, scheduleID } = props;
  // Dropdown EDIT trigger for the template interval modal
  const editTrigger = <Dropdown.Item>{'Edit'}</Dropdown.Item>;

  const headerAction = (interval, templateID, title, item) => {
    if (readOnly) {
      return null;
    }
    return (
      <Menu compact text>
        <Dropdown icon='ellipsis horizontal'>
          <Dropdown.Menu>
            <OffboardingTemplateIntervalModal
              action={action}
              interval={interval}
              intervalID={String(interval.id)}
              scheduleID={scheduleID}
              templateID={templateID}
              title={title}
              trigger={editTrigger}
            />
            <Dropdown.Item
              onClick={onHandleDeleteTemplateSelected({
                interval,
                item,
                templateID,
                title,
              })}
            >
              {'Delete'}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );
  };

  // Make the title titleCase
  const titleCase = toTitleCase(title);
  return (
    <Segment>
      <Header as='h4' content={titleCase} dividing />
      <Grid columns={2}>
        {/* NOTE: attachedHeader should support the 'internalDescription' but since it's not ready on BE  and for demo purpose */}

        {activities && !isEmpty(activities) ? (
          activities.map((item, index) => {
            const ImgElement = OnboardActivitiesCategory.getIn([
              item.category,
              'image',
            ])
              ? OnboardActivitiesCategory.getIn([item.category, 'image'])
              : null;
            return (
              <Grid.Column key={`${item?.interval?.eid}_${index}`}>
                <Segment as={List}>
                  <EspListItem
                    attachedHeader={item.private_notes}
                    description={item.description}
                    header={item?.title}
                    headerAction={headerAction(
                      item.interval,
                      item.templateID,
                      item.title,
                      item
                    )}
                    image={ImgElement && <ImgElement />}
                    meta={getDueDate(fromJS(item))}
                    ribbon={item.status === 'ARCHIVED' ? 'ARCHIVED' : null}
                  />
                </Segment>
              </Grid.Column>
            );
          })
        ) : (
          <Message basic content='No activities in this group...' info />
        )}
      </Grid>
    </Segment>
  );
};

OffboardingActivityScheduleGroup.propTypes = propTypes;
export default OffboardingActivityScheduleGroup;
