import { isEmpty, last, omit, reduce } from 'lodash';
import moment from 'moment';
import flatten from './flatten';
import adminReportFilterOptions from '../globals/AdminReportFilterOptions';

const DATE_FORMAT = {
  SHORT_MONTH: 'MMM',
  SHORT_MONTH_DAY: 'MMM DD',
};

const labelByMonth = (data) => {
  let currentMonth;

  return data.map((entry) => {
    const end = moment(entry.start_date).add(6, 'days');
    const start = moment(entry.start_date);

    const month = start.format(DATE_FORMAT.SHORT_MONTH);
    if (month !== currentMonth) {
      currentMonth = month;
      entry.name = currentMonth;
    }

    entry.dates = {
      end: end.format('L'),
      start: start.format('L'),
    };
    entry.start_date = `${start.format(
      DATE_FORMAT.SHORT_MONTH_DAY
    )} - ${end.format(DATE_FORMAT.SHORT_MONTH_DAY)}, ${start.format('YYYY')}`;
    entry.frequency = adminReportFilterOptions.WEEKLY;
    return entry;
  });
};

const labelAllByMonth = (data) =>
  data.map((entry) => {
    let end = null;
    const start = moment(entry.start_date);

    if (start.format('dd') > 20) {
      end = start.add(1, 'month');
      entry.name = end.format(DATE_FORMAT.SHORT_MONTH);
    } else {
      end = start;
      entry.name = end.format(DATE_FORMAT.SHORT_MONTH);
    }

    entry.dates = {
      end: end.format('L'),
      start: start.format('L'),
    };

    entry.start_date = start.format('MMM YYYY');
    entry.frequency = adminReportFilterOptions.MONTHLY;
    return entry;
  });

const labelAllByDay = (data) =>
  data.map((entry) => ({
    ...entry,
    dates: {
      end: moment(entry.start_date).format('L'),
      start: moment(entry.start_date).format('L'),
    },
    frequency: adminReportFilterOptions.DAILY,
    name: moment(entry.start_date).format('MM/DD'),
    start_date: moment(entry.start_date).format('MMM DD YYYY'),
  }));

const labelByAllTime = (data) =>
  data.map((entry) => ({
    ...entry,
    dates: {
      start: moment(entry.start_date).format('L'),
    },
    frequency: adminReportFilterOptions.ALL_TIME,
    name: moment(entry.start_date).format('MM/DD'),
    start_date: moment(entry.start_date).format('L'),
  }));

const reportsDateFormat = (freq, reportData, endDate) => {
  const { ALL_TIME, DAILY, MONTHLY, WEEKLY } = adminReportFilterOptions;

  // Remove last entry if end data matches with the start date which it is exclusive
  let data;
  if (
    reportData &&
    reportData.length > 1 &&
    moment(last(reportData).start_date).isSame(moment(endDate), 'date')
  ) {
    data = reportData.slice(0, reportData.length - 1);
  } else {
    data = [...reportData];
  }

  switch (freq) {
    case DAILY:
      return labelAllByDay(data);
    case WEEKLY:
      return labelByMonth(data);
    case MONTHLY:
      return labelAllByMonth(data);
    case ALL_TIME:
      return labelByAllTime(data);
    default:
      break;
  }
  return data;
};

export const surveyDateAndResponsesFormat = (rawData, endDate) => {
  const momentEndDate = moment(endDate).subtract(1, 'days');
  const data =
    rawData && !isEmpty(rawData)
      ? rawData.map((entry) => ({
          ...entry,
          name: `${entry.dates.start} - ${
            entry.dates.end || momentEndDate.format('L')
          }`,
          questions: entry.questions.reduce(
            (acc, value) => [
              ...acc,
              {
                question: value.question_text,
                response_count: value.response_count,
                responses: reduce(
                  value,
                  (accumulator, value, key) =>
                    key !== 'question_text' && key !== 'response_count'
                      ? [].concat(accumulator, [{ [key]: value }])
                      : accumulator,
                  []
                ),
              },
            ],
            []
          ),
        }))
      : rawData;

  return data && !isEmpty(data)
    ? data.map((entry) => ({
        ...omit(entry, 'questions'),
        ...flatten(entry.questions),
      }))
    : rawData;
};

export const surveyAnnouncementFormat = (rawData) =>
  rawData && !isEmpty(rawData)
    ? rawData.map((entry) => ({
        ...entry,
        questions: entry.questions.reduce(
          (acc, value) => [
            ...acc,
            {
              question: value.question_text,
              responses: reduce(
                value,
                (accumulator, value, key) =>
                  key !== 'question_text'
                    ? [].concat(accumulator, [
                        {
                          label: key,
                          percentage: Math.round(
                            (value * 100) / entry.total_users_response_count
                          ),
                          value,
                        },
                      ])
                    : accumulator,
                []
              ),
            },
          ],
          []
        ),
        users_surveyed_count:
          entry.users_surveyed_count === -1
            ? 'N/A'
            : entry.users_surveyed_count,
      }))
    : [];

export default reportsDateFormat;
