import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  downloadURL: '',
  isLoading: false,
  jobID: null,
  message: '',
});

const surveysReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_CSV_CASES_REPORT_JOB_SUCCESS: {
      return state.set('jobID', action.jobID);
    }

    case actionTypes.RESET_CSV_CASES_REPORT: {
      return INITIAL_STATE;
    }

    case actionTypes.LOAD_CSV_CASES_REPORT_FAIL: {
      return state.set('isLoading', false);
    }

    case actionTypes.LOAD_CSV_CASES_REPORT_START: {
      return state.set('isLoading', true).set('message', '');
    }

    case actionTypes.LOAD_CSV_CASES_REPORT_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('downloadURL', action.results.barista_case_report)
        .set('message', action.results.message);
    }

    default: {
      return state;
    }
  }
};

export default surveysReducer;
