import React, { PureComponent } from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
// Type
import TypeSelectMobileOS, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectMobileOS';
import { DeviceOption } from '../../../globals/DeviceType';

// Molecule
import FormInputSelect from '../../molecules/FormInputSelect';

class SelectOSType01 extends PureComponent {
  static propTypes = {
    ...TypeSelectMobileOS,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  // DEV-14047 using global with correct values.
  optionOS = DeviceOption.computer;

  render() {
    const { id, label, placeholder, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Form.Field>
          <Field
            component={FormInputSelect}
            fluid
            label={label}
            name={id}
            options={this.optionOS}
            placeholder={placeholder}
            search
          />
        </Form.Field>
      </div>
    );
  }
}

export default SelectOSType01;
