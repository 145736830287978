import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import csvParsingActions from './csvParsingActions';

const csvParsingThunks = {};

csvParsingThunks.cancelCSVParsing = (csvId) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!csvId) {
      reject('No CSV ID');
    }
    const data = new FormData();
    data.append('parse_status', 'CANCELED');
    dispatch(csvParsingActions.cancelCSVParsingStart());
    const url = endpointGenerator.genPath(
      'espCSV.csvfile.csvId.changeParseStatus',
      {
        csvId,
      }
    );
    APIcall.post({
      data,
      error({ message }) {
        dispatch(csvParsingActions.cancelCSVParsingFail());
        reject(message);
      },
      success(response) {
        dispatch(csvParsingActions.cancelCSVParsingSuccess());
        resolve(response);
      },
      token: true,
      url: url,
    });
  });

export default csvParsingThunks;
