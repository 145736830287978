import { createSelector } from 'reselect';

import getWiredDepartmentsByID from './getWiredDepartmentsByID';
import getWiredLocationsByID from './getWiredLocationsByID';

const getNormalizedProductPermissionGroups = (state) =>
  state.getIn(['productPermissions', 'permissionGroups', 'values']);

const getProductPermissionGroups = (
  normalizedProductPermissionGroups,
  wiredLocationsByID,
  wiredDepartmentsByID
) => {
  if (!normalizedProductPermissionGroups) {
    return null;
  }

  const productPermissionGroups = normalizedProductPermissionGroups.map(
    (productPermissionGroup) => {
      const locationIDs = productPermissionGroup.getIn([
        'parsedRule',
        'locations',
      ]);
      const locations = locationIDs.map((locationID) =>
        wiredLocationsByID.get(locationID)
      );

      productPermissionGroup = productPermissionGroup.setIn(
        ['parsedRule', 'locations'],
        locations
      );

      const departmentIDs = productPermissionGroup.getIn([
        'parsedRule',
        'departments',
      ]);
      const departments = departmentIDs.map((departmentID) =>
        wiredDepartmentsByID.get(departmentID)
      );

      productPermissionGroup = productPermissionGroup.setIn(
        ['parsedRule', 'departments'],
        departments
      );

      return productPermissionGroup;
    }
  );

  return productPermissionGroups;
};

const selector = createSelector(
  [
    getNormalizedProductPermissionGroups,
    getWiredLocationsByID,
    getWiredDepartmentsByID,
  ],
  getProductPermissionGroups
);

export default selector;
