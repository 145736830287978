import { createSelector } from 'reselect';

import Immutable from 'immutable';

const createHierarchyWiredNodesByIDSelector = (
  getHierarchyRootNodesSelector
) => {
  const traverse = (node) => {
    let indexedNodes = Immutable.Map();

    indexedNodes = indexedNodes.set(node.get('id'), node);

    const children = node.get('children');

    children.forEach((childNode) => {
      const childIndexedNodes = traverse(childNode);
      indexedNodes = indexedNodes.merge(childIndexedNodes);
    });

    return indexedNodes;
  };

  return createSelector(
    [getHierarchyRootNodesSelector],

    (rootNodes) => {
      let wiredNodesByID = Immutable.Map();

      rootNodes?.forEach((root) => {
        const indexedNodes = traverse(root);
        wiredNodesByID = wiredNodesByID.merge(indexedNodes);
      });

      return wiredNodesByID;
    }
  );
};

export default createHierarchyWiredNodesByIDSelector;
