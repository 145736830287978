import React, { useCallback, useState } from 'react';
import { intl } from 'esp-util-intl';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Dropdown, Input, Menu } from 'semantic-ui-react';
// import MenuProvider from './context/MenuProvider';
import { Boundaries } from 'cascara-middleware';
import UserPicker from '../../../../v2/components/functional/UserPicker';
import { getUserByID } from '../../../../globals/commonAPICalls';

const propTypes = {
  agentName: PropTypes.string,
  endImpersonation: PropTypes.func,
  isImpersonating: PropTypes.bool,
  startImpersonation: PropTypes.func,
};

const defaultProps = {
  agentName: '',
  endImpersonation: noop,
  isImpersonating: false,
  startImpersonation: noop,
};

const ImpersonationBar = ({
  agentName,
  isImpersonating,
  endImpersonation,
  startImpersonation,
}) => {
  const [shouldDisplayUserPicker, setShouldDisplayUserPicker] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState();
  const [selectedUserData, setSelectedUserData] = useState();

  const LABELS = {
    END_IMPERSONATION: intl.formatMessage({
      id: 'label.end_impersonation',
    }),
    IMPERSONATE_USER: intl.formatMessage({
      id: 'label.impersonate_user',
    }),
    OPTIONS: intl.formatMessage({
      id: 'label.options',
    }),
  };

  const selectedUserName = selectedUserData?.get('full_name') ?? '';

  const dropdownItemLabel = isImpersonating
    ? LABELS.END_IMPERSONATION
    : LABELS.IMPERSONATE_USER;

  const handleClick = useCallback(() => {
    if (isImpersonating) {
      endImpersonation();
      setSelectedUserData();
      setSelectedUserID();
    } else {
      setShouldDisplayUserPicker(true);
    }
  }, [isImpersonating, endImpersonation]);
  const handleClose = useCallback(() => {
    setShouldDisplayUserPicker(false);
  }, []);
  const handleUserSelection = useCallback(
    (userID) => {
      setSelectedUserID(userID);
      getUserByID(userID).then((userData) => {
        startImpersonation(userID, userData.eid);
        setSelectedUserData(Immutable.Map(userData));
      });
    },
    [startImpersonation]
  );

  return (
    <Boundaries>
      <>
        <UserPicker
          allUser
          header={`${intl.formatMessage({ id: 'label.select_user' })}:`}
          isSearchable
          onClose={handleClose}
          onUserSelect={handleUserSelection}
          open={shouldDisplayUserPicker}
          pagination={shouldDisplayUserPicker ? '1' : ''}
        />
        <div id='impersonation_bar' style={{ padding: '0.5em' }}>
          <Input
            action={
              <Menu>
                <Dropdown direction='left' item simple text={LABELS.OPTIONS}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleClick}>
                      {dropdownItemLabel}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            }
            fluid
            icon='user circle'
            iconPosition='left'
            readOnly
            value={selectedUserID ? selectedUserName : agentName}
          />
        </div>
      </>
    </Boundaries>
  );
};

ImpersonationBar.propTypes = propTypes;
ImpersonationBar.defaultProps = defaultProps;

export default ImpersonationBar;
