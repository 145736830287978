import { createSelector } from 'reselect';

const getProductsById = (state) => state.getIn(['entities', 'products']);

const getHardwareBundleProductsIds = (state) =>
  state.getIn(['bundles', 'hardwareBundleProductsIds']);

const getHardwareBundleProducts = (productsById, hardwareBundleProductsIds) => {
  if (!hardwareBundleProductsIds) {
    return null;
  }

  const hardwareBundleProducts = hardwareBundleProductsIds.map((productId) =>
    productsById.get(productId)
  );

  return hardwareBundleProducts;
};

const selector = createSelector(
  [getProductsById, getHardwareBundleProductsIds],
  getHardwareBundleProducts
);

export default selector;
