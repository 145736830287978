import { change, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable/dist/immutable';
// Actions - Thunks
import faqThunks from '../../actions/faqThunks';
import workflowThunks from '../../actions/workflowThunks';
// Selector
import getArchetypeList from '../../selectors/getArchetypeList';
import { getFaqSelectedDepartment } from '../../selectors/getFAQServiceDeptIDSelected';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues('Form01')(state);
  const templateSelected =
    (formValues && formValues.getIn([ownProps.id, 'template'], null)) || null;

  return {
    archetypeList: getArchetypeList(state),
    departmentID: getFaqSelectedDepartment(state), // This WF has been launch from ADMIN and we need to passthe ServiceDepartmentID selected
    isLoadingArchetype: state.getIn(['adminFaqs', 'isLoadingArchetypeList']),
    templateSelected,
  };
};

// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    dispatch(change('Form01', id, data));
  },
  saveBEscratch(data) {
    return dispatch(workflowThunks.updateTempDataBackEndScratch(data));
  },
  searchArchetype(term, searchBy, departmentID, getFullResult) {
    dispatch(
      faqThunks.searchArchetype(term, searchBy, departmentID, getFullResult)
    );
  },
  submitNoMatch(id) {
    dispatch(change('Form01', 'searchArchetype', 'ok'));
    dispatch(
      change(
        'Form01',
        id,
        fromJS({
          skip: 'skip',
        })
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
