import React from 'react';
import styles from './Loading.module.scss';

const propTypes = {};

const Loading = () => {
  return (
    <span className={styles._}>
      <span className={styles.child} />
    </span>
  );
};

Loading.propTypes = propTypes;

export default Loading;
