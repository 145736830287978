const api_case_mgmt_v0_1_conditions = {
  results: [
    {
      action: 'REASSIGN_SERVICE_TEAM',
      eid: 'c0be42e5-fe4d-4ffa-8e78-1a4c67849122',
      id: 1,
      is_active: true,
      order_of_execution: 0,
      service_department: 'c0a041ac-9194-4229-9338-2eddf625343d',
      service_team: '76b21a84-1c65-4595-87b0-e12d7f229e90',
      sys_created_by: 'admin@espressive.com',
      sys_date_created: '2020-03-20T20:53:00.839928Z',
      sys_date_updated: '2020-03-20T20:53:00.839901Z',
      sys_updated_by: 'admin@espressive.com',
      task_category: '17230848-7f0e-4318-aef7-8a37eab6e3fa',
      task_intent: '6ad3220b-a159-4d78-b1b1-76ffcb67990c',
      task_type: '549047ba-0ff6-43f7-9932-03f5e8cec3fa',
    },
  ],
};

export default api_case_mgmt_v0_1_conditions;
