/**
 * Util used to generate random hashes used for blocks ids
 */
import crypto from 'crypto';

const hashUtils = {};

/**
 * Creates a hex string that can be used as identifier
 * @param  {Number} length [Size of the string
 * @return {String}        [Hash]
 */
hashUtils.createRandomId = (length = 6) => {
  const hash = crypto
    .createHash('sha1')
    .update(new Date().toString())
    .digest('hex')
    .substr(0, length);
  return hash;
};

export default hashUtils;
