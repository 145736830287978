import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import pt from 'prop-types';
import IntegrationSetupWizardFormikContext from './IntegrationSetupWizardFormikContext';

const propTypes = {
  children: pt.element,
  initialValues: pt.shape({}),
  onSubmit: pt.func,
  validationSchema: pt.shape({}),
};

const IntegrationSetupWizardFormikWrapper = ({
  initialValues = null,
  onSubmit,
  validationSchema = null,
  children,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <FormikForm>
          <IntegrationSetupWizardFormikContext.Provider value={props}>
            {children}
          </IntegrationSetupWizardFormikContext.Provider>
        </FormikForm>
      )}
    </Formik>
  );
};

IntegrationSetupWizardFormikWrapper.propTypes = propTypes;

export default IntegrationSetupWizardFormikWrapper;
