import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import {
  Button,
  Dimmer,
  Divider,
  Grid,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';
import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Molecules
import ValidatedForm from '../../../molecules/ValidatedForm';
import ValidatedField from '../../../molecules/ValidatedField';
import FormInputTextarea from '../../../molecules/FormInputTextarea';
import ServiceTeamSettingsImage from '../../../molecules/ServiceTeamSettingsImage';

// controllers
import AdminServiceTeamSettingController from '../../../controllers/AdminServiceTeamSettingController';

const propTypes = {
  formErrors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  formValues: ImmutablePropTypes.map,
  isLoading: PropTypes.bool,
  isPristine: PropTypes.bool,
  isValid: PropTypes.bool,
  loadServiceTeam: PropTypes.func,
  params: PropTypes.shape({
    teamID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  saveServiceTeam: PropTypes.func,
  serviceTeam: ImmutablePropTypes.map,
  validate: PropTypes.func,
};

const defaultProps = {
  formErrors: {},
  formValues: Immutable.Map(),
  isLoading: false,
  isPristine: false,
  isValid: false,
  loadServiceTeam: _.noop,
  params: {
    teamID: null,
  },
  saveServiceTeam: _.noop,
  serviceTeam: Immutable.Map(),
  validate: _.noop,
};

class AdminServiceTeamSetting extends Component {
  componentDidMount() {
    const { params, loadServiceTeam } = this.props;
    const { teamID } = params;

    if (teamID) {
      loadServiceTeam(teamID);
    }
  }

  handleClickSave = () => {
    const {
      params,
      isValid,
      saveServiceTeam,
      isPristine,
      isLoading,
      formValues,
    } = this.props;

    if (isValid && !isPristine && !isLoading) {
      saveServiceTeam(params.teamID, formValues);
    }
  };

  handleSubmit() {}

  render() {
    const {
      formErrors,
      isLoading,
      serviceTeam,
      validate,
      isValid,
      isPristine,
    } = this.props;

    const initialValues = {
      description: serviceTeam.get('description'),
      name: serviceTeam.get('name'),
      // informations : 'What ever you want', // TODO this field does not exists in the API
    };

    const submitDisable = !(isValid && !isPristine) || isLoading;

    return (
      <Segment attached='bottom'>
        <ValidatedForm
          enableReinitialize
          form={'ServiceTeamSettings'}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validate={validate(intl.formatMessage)}
        >
          <Menu borderless secondary>
            <Menu.Menu position='right'>
              <Menu.Item fitted>
                <Button
                  content='Save'
                  disabled={submitDisable}
                  loading={isLoading}
                  onClick={this.handleClickSave}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          {isLoading && (
            <Dimmer active inverted>
              <Loader inverted />
            </Dimmer>
          )}

          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <ServiceTeamSettingsImage
                  images={serviceTeam.get('images')}
                  isLoading={isLoading}
                  teamID={serviceTeam.get('id')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider hidden />

          <ValidatedField
            formError={formErrors.name ? formErrors.name : null}
            label='Service Team Name'
            name='name'
            onChange={this.handleFieldChange}
            placeholder='Title that will display on the task card.'
          />

          <ValidatedField
            component={FormInputTextarea}
            formError={formErrors.description ? formErrors.description : null}
            label='Service Team Description'
            name='description'
            onChange={this.handleFieldChange}
            placeholder='Enter a description'
            rows={3}
          />
        </ValidatedForm>
        <Divider hidden />
      </Segment>
    );
  }
}

AdminServiceTeamSetting.propTypes = propTypes;

AdminServiceTeamSetting.defaultProps = defaultProps;

export default AdminServiceTeamSettingController(AdminServiceTeamSetting);
