import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import integrationThunks from '../../actions/integrationThunks';

const mapStateToProps = (state, ownProps) => ({
  // only this
  instance: state.getIn([
    'integration',
    'csv_upload_instances',
    ownProps.instanceId,
  ]),
  isLoadingInstance: state.getIn([
    'integration',
    'csv_upload_instances_loading_status',
    ownProps.instanceId,
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  loadInstance: (instanceId) => {
    dispatch(integrationThunks.loadHistoryInstance(instanceId));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
