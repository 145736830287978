import { useState } from 'react';
import { useSealedState } from 'reakit-utils';
import {
  adjust,
  concat,
  drop,
  dropLast,
  lensProp,
  prop,
  set,
  splitAt,
} from 'ramda';

const useFilterState = ({ basicSearchParams = [], initialState = [] }) => {
  const basicSearchState = (value = '') =>
    basicSearchParams
      .reduce(
        (reducer, parameter) =>
          reducer.concat(
            {
              parameter,
              type: 'containsInsensitive',
              value: value,
            },
            {
              isCondition: true,
              type: 'or',
            }
          ),
        []
      )
      // Remove the last value as we do not need a condition at the end
      .slice(0, -1);

  // If no initialState is set, we should determine our initialState from basicSearchParams
  const autoInitialState =
    initialState.length > 0 ? initialState : basicSearchState();

  // Special hook that will not re-render even if the initial state changes
  const sealed = useSealedState(autoInitialState);
  const [value, setValue] = useState(sealed);

  const setBasicValue = (value) => {
    setValue(basicSearchState(value));
  };

  // Sets the condition or operator type at an index
  const setType = (type, index) => {
    // Define the property in the object that we will be adjusting
    const typeLens = lensProp('type');
    // Adjust the value at our index by setting a type on the object
    setValue(adjust(index, set(typeLens, type), value));
  };

  // Sets the operator value at an index
  const setOperatorValue = (val, index) => {
    // Define the property in the object that we will be adjusting
    const valueLens = lensProp('value');
    // Adjust the value at our index by setting a type on the object
    setValue(adjust(index, set(valueLens, val), value));
  };

  // Removes the operator at specified index and the condition after, or previous if one exists
  const removeOperator = (index) => {
    const [before, after] = splitAt(index, value);
    const newAfter = drop(1, after);

    if (newAfter.length > 0) {
      setValue(concat(before, drop(1, newAfter)));
    } else {
      setValue(concat(dropLast(1, before), newAfter));
    }
  };

  // Checks our array of objects and determines if any of the objects has a value set
  const isEmptyFilter = !value?.find(({ value }) => Boolean(value));

  // Function to use in filters to determine if our object is a condition or an operator
  const isConditionObj = ({ isCondition }) => Boolean(isCondition);
  const conditions = value.filter(isConditionObj);
  const operators = value.filter((obj) => !isConditionObj(obj));

  // Create a Set of operator and condition values. A Set guarantees uniqueness for each value.
  const operatorValueSet = new Set(operators.map(prop('value')));
  const conditionTypeSet = new Set(conditions.map(prop('type')));

  // Checks if the filter state is compatible with the basic filter UI.
  const isBasicCompatible =
    // 1. There is only one unique operator value (to show in the search input)
    operatorValueSet.size === 1 &&
    // 2. There are only 'OR' conditions
    ((conditionTypeSet.size === 1 && conditionTypeSet.has('or')) ||
      // 3. There are no conditions at all
      conditionTypeSet.size === 0);

  // WARNING: Do not export the setValue function directly.
  // We only want to expose public helper functions.
  return {
    basicSearchParams,
    isBasicCompatible,
    isEmptyFilter,
    removeOperator,
    setBasicValue,
    setOperatorValue,
    setType,
    value,
  };
};

export default useFilterState;
