import { get } from 'lodash';
import { jobStatuses } from 'esp-globals';
import APIcall from '../utils/APIcall';
import async from 'async';
import csvCasesReportActions from './csvCasesReportActions';
import endpointGenerator from '../utils/endpointGenerator';
import toastNotificationsActions from './toastNotificationsActions';

const ERROR_MESSAGE = 'There was an error downloading the report.';

const csvCasesReportThunks = {};

csvCasesReportThunks.cancelCsvCasesReport = (jobID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('commons.jobStatus.instance.cancel', {
      statusID: jobID,
    });

    APIcall.post({
      error: reject,
      success({ body }) {
        dispatch(csvCasesReportActions.resetDownloadURL());
        resolve(body);
      },
      token: true,
      url,
    });
  });

csvCasesReportThunks.downloadCsvCasesReport = (query) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const createJob = (cb) => {
      // example {tenant}/api/reporting/v0.1/templates/barista_case_report/?start_date={start_date}&end_date={end_date}
      const surveyReportJobIdUrl = endpointGenerator.genPath(
        'reporting.templates.barista_case_report'
      );
      APIcall.get({
        error(error) {
          cb(new Error(error));
        },
        query,
        success({ body }) {
          const statusURLTokens = body.status_url.split('/');
          const jobIDIndex = statusURLTokens.length - 2;
          const jobID = statusURLTokens[jobIDIndex];

          dispatch(csvCasesReportActions.getCsvCasesReportJobSuccess(jobID));
          cb(null, {
            jobID,
            statusUrl: body.status_url,
          });
        },
        token: true,
        url: surveyReportJobIdUrl,
      });
    };

    let triesCount = 0;

    const getJobStatus = ({ jobID, statusUrl }, cb) => {
      const jobStatusUrl = endpointGenerator.genPath(
        'commons.jobStatus.instance',
        { statusID: jobID }
      );

      APIcall.get({
        error(error) {
          cb(new Error(error));
        },
        success({ body }) {
          if (
            body.status === jobStatuses.SCHEDULED ||
            body.status === jobStatuses.IN_PROGRESS
          ) {
            triesCount++;
            if (triesCount < 500) {
              const state = getState();
              const jobID = state.getIn(['csvCasesReport', 'jobID']);

              if (jobID) {
                setTimeout(() => getJobStatus({ jobID, statusUrl }, cb), 600);
              } else {
                // cancels waterfall since the user has left off the modal
              }
            } else {
              cb(new Error('The report generation is taking too much time.'));
            }
          } else if (body.status === jobStatuses.COMPLETED) {
            cb(null, statusUrl);
          } else if (body.status === jobStatuses.FAILED) {
            const message = get(
              body,
              ['result_details', 'message'],
              ERROR_MESSAGE
            );
            cb(new Error(message));
          } else {
            cb(new Error(ERROR_MESSAGE));
          }
        },
        token: true,
        url: jobStatusUrl,
      });
    };

    const completedReport = (downloadUrl, cb) => {
      const COMPLETED = 'COMPLETED';
      // example https://release.qa.espressive.com/api/surveys/v0.1/surveys/{survey_id}/download_report/?job_id={job_id}
      APIcall.get({
        error(error) {
          cb(error);
        },
        // responseType: 'blob',
        success({ body }) {
          if (body.status === COMPLETED) {
            cb(null, body.result_details);
          } else {
            cb(new Error('Something went wrong!'));
          }
        },
        token: true,
        url: downloadUrl,
      });
    };

    const done = (error, results) => {
      if (error) {
        dispatch(
          toastNotificationsActions.error({
            message: error.message,
          })
        );
        dispatch(csvCasesReportActions.loadCsvCasesReportFail());
        reject(error);
      } else {
        dispatch(csvCasesReportActions.loadCsvCasesReportSuccess(results));
        resolve();
      }
    };

    dispatch(csvCasesReportActions.loadCsvCasesReportStart());

    async.waterfall([createJob, getJobStatus, completedReport], done);
  });

export default csvCasesReportThunks;
