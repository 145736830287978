/**
 * Names of the top level categories in the system.
 * Comes from https://itautomationsys-my.sharepoint.com/personal/brian_espressive_com/_layouts/15/WopiFrame.aspx?sourcedoc=%7Bd6742009-7e88-4f93-8368-3a6ddfa8fac4%7D&action=default
 */
const CatalogCategoryNames = {
  ACCESSORIES: 'Accessories',

  COMMUNICATION: 'Communication',

  COMPANY_STORE: 'Company Store',

  FURNITURE: 'Furniture',

  HARDWARE: 'Hardware',

  OFFICE_SUPPLIES: 'Office Supplies',

  OTHER: 'Other',

  SERVICES: 'Services',

  SOFTWARE: 'Software',
};

export default CatalogCategoryNames;
