const api_barista_v0_1_kb_support_origins = {
  origins: [
    'lumapps',
    'zendesk',
    'confluence',
    'sharepoint',
    'ivanti',
    'servicenow',
  ],
};

export default api_barista_v0_1_kb_support_origins;
