import React from 'react';
import { Message } from 'semantic-ui-react';
import pt from 'prop-types';

const propTypes = {
  isBrandingAllowedExtensionEnabled: pt.bool,
};

const BrandingWarning = ({ isBrandingAllowedExtensionEnabled }) => {
  if (!isBrandingAllowedExtensionEnabled) {
    return (
      <Message warning>
        <Message.Header>File image upload might be disabled.</Message.Header>
        <p>Contact your customer success advisor for assistance.</p>
      </Message>
    );
  }
  return null;
};

BrandingWarning.propTypes = propTypes;

export default BrandingWarning;
