import PropTypes from 'prop-types';

import React, { isValidElement, PureComponent } from 'react';
import { Button, Icon, Menu } from 'semantic-ui-react';
// Controllers
import ChatChannelHeaderController from '../controllers/ChatChannelHeaderController';

import { noop } from 'lodash';
import { intl } from 'esp-util-intl';

class ChatChannelHeader extends PureComponent {
  static propTypes = {
    caseTaskId: PropTypes.number,

    isSubscriber: PropTypes.bool,

    isSubscriberLoading: PropTypes.bool,

    isTaskResolved: PropTypes.bool,

    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    unsubscribeUser: PropTypes.func,

    userId: PropTypes.number,
  };

  static defaultProps = {
    caseTaskId: void 0,
    isSubscriber: false,
    isSubscriberLoading: false,
    isTaskResolved: true,
    title: '',
    unsubscribeUser: noop,
    userId: void 0,
  };

  handleUnsubscribeUser = () => {
    const { unsubscribeUser, userId } = this.props;
    unsubscribeUser(userId);
  };

  render() {
    const {
      title,
      isSubscriberLoading,
      caseTaskId,
      userId,
      isSubscriber,
      isTaskResolved,
    } = this.props;

    const subscribersMenuItems = () => {
      const MAX_TITLE_LEN = 30;
      const displayRemoveSubscriberIcon =
        isSubscriber && window.screen.width < 1024;
      const iconStyle = {
        marginRight: 0,
      };
      const formattedTitle =
        title && isSubscriber
          ? `${title.substring(0, MAX_TITLE_LEN)}...`
          : title;
      const shouldDisplaySubscribeButton =
        caseTaskId && isSubscriber && userId && !isTaskResolved;

      const headerSpan = <span>{formattedTitle}</span>;

      const unsubscribedButton = (
        <Button
          basic
          disabled={isSubscriberLoading}
          onClick={this.handleUnsubscribeUser}
        >
          {!displayRemoveSubscriberIcon ? (
            intl.formatMessage({
              id: 'label.unsubscribe',
            })
          ) : (
            <Icon name='user times' size='large' style={iconStyle} />
          )}
        </Button>
      );

      return (
        <>
          <Menu.Item
            aria-label='header'
            content={headerSpan}
            header
            tabIndex='0'
          />

          {shouldDisplaySubscribeButton ? (
            <Menu.Menu position='right'>
              <Menu.Item>{unsubscribedButton}</Menu.Item>
            </Menu.Menu>
          ) : null}
        </>
      );
    };

    if (isValidElement(title)) {
      return <Menu.Item content={title} header />;
    }

    return subscribersMenuItems();
  }
}
const ChatChannelHeaderTest = ChatChannelHeader;

// eslint-disable-next-line no-class-assign -- DEV-1526
ChatChannelHeader = ChatChannelHeaderController(ChatChannelHeader);
export { ChatChannelHeaderTest };

export default ChatChannelHeader;
