import PropTypes from 'prop-types';
import React from 'react';
import TextUtils from '../../utils/TextUtils';

const propTypes = {
  /** Component or DOM node to use as root element */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  /** The term to be highlighted */
  term: PropTypes.string.isRequired,

  /** The text we want to highlight  */
  text: PropTypes.string.isRequired,
};

const TextHighlight = ({ as = 'div', term, text, ...props }) => {
  const highlightedText = TextUtils.highlightStartsWithMatches(
    text,
    term,
    (match, i) => (
      <span className='match' key={i}>
        {match}
      </span>
    )
  );

  return React.createElement(
    as,
    {
      ...props,
    },
    highlightedText
  );
};

TextHighlight.propTypes = propTypes;

export default TextHighlight;
