import async from 'async';
import Immutable from 'immutable';
import _ from 'lodash';

import getCurrentUser from '../selectors/getCurrentUser';

import appUIActions from './appUIActions';
import directoryActions from './directoryActions';
import workflowThunks from './workflowThunks';
import toastNotificationsActions from './toastNotificationsActions';

import APIcall from '../utils/APIcall';
import DirectoryFilters from '../utils/DirectoryFilters';
import endpointGenerator from '../utils/endpointGenerator';

import UserStates from '../globals/UserStates';
import WorkflowEIDs from '../globals/WorkflowEIDs';
import groupPermissions from '../globals/groupPermissions';
/**
 * Returns endpoint url to get users given the directory filter and who the current user is.
 *
 * @param {string} filterBy
 * @param {number} userID The current user id.
 */
const getUsersEndpointUrl = (filterBy, userID, groups = null) => {
  // if its seeing new hires and belongs to these groups
  // then he should see all users
  if (
    Immutable.List.isList(groups) &&
    filterBy === DirectoryFilters.NEW_HIRES
  ) {
    const groupsThatCanSeeAll = [
      groupPermissions.HR_CONTACT,
      groupPermissions.HR_RECRUITER,
      groupPermissions.ENABLEMENT,
      groupPermissions.IT_CONTACT,
      groupPermissions.OPERATIONS,
    ];

    // Checking if any of the user's groups is in the privileged list
    const canSeeThemAll = groups.some(
      (group) => groupsThatCanSeeAll.indexOf(group) > -1
    );
    if (canSeeThemAll) {
      return endpointGenerator.genPath('espUser.users');
    }
  }

  if (
    filterBy === DirectoryFilters.MY_TEAM ||
    filterBy === DirectoryFilters.NEW_HIRES
  ) {
    return endpointGenerator.genPath('espUser.users.instance.team', {
      userID,
    });
  } else if (filterBy === DirectoryFilters.MY_FAVORITES) {
    return endpointGenerator.genPath('espUser.users.instance.favorites', {
      userID,
    });
  } else {
    // filterBy === DirectoryFilters.ALL
    return endpointGenerator.genPath('espUser.users');
  }
};

/**
 * @param {number} userID
 * @param onUser
 */
const getUser = (userID, onUser = _.noop) => {
  /** @type {string} */

  const url = endpointGenerator.genPath('espUser.users.instance', {
    userID,
  });

  APIcall.get({
    error(error) {
      error = error.response.body;
      onUser(error);
    },
    success(response) {
      const user = response.body;
      onUser(null, user);
    },
    token: true,
    url,
  });
};

const directoryThunks = {};

/**
 * We should only accept directory users if the current values of 'alphabetLetter', 'sortBy' and 'filterBy' are
 * the same than when the request was made.
 *
 * @param alphabetLetter The alphabet letter when the request was made.
 * @param sortBy The sort by option when the request was made.
 * @param filterBy The filter by option when the request was made.
 * @param stateAfter A state snapshot after the request completes.
 * @return {Boolean} True if it's ok to accept the users from the api response.
 */
const shouldAccept = (alphabetLetter, sortBy, filterBy, stateAfter) => {
  const directory = stateAfter.get('directory');

  const stillTheSame =
    alphabetLetter === directory.get('alphabetLetter') &&
    sortBy === directory.get('sortBy') &&
    filterBy === directory.get('filterBy');

  return stillTheSame;
};

/**
 * Get the Directory users list
 * @param noResetList {boolean} Allow to no reset the user list before we wall the API
 * @param onUsers {function} callback
 */
directoryThunks.getDirectoryUsers = (noResetList, onUsers = _.noop) => (
  dispatch,
  getState
) => {
  dispatch(directoryActions.getDirectoryUsersStart(noResetList));

  const state = getState();

  const currentUser = getCurrentUser(state);
  const userID = currentUser.get('id');

  const directory = state.get('directory');

  // get users params
  const alphabetLetter = directory.get('alphabetLetter');
  const sortBy = directory.get('sortBy');
  const filterBy = directory.get('filterBy');

  const url = getUsersEndpointUrl(filterBy, userID, currentUser.get('groups'));
  const query = {
    order_by: sortBy,
  };

  if (filterBy === DirectoryFilters.NEW_HIRES) {
    const espFilters = encodeURI(
      `user_state__!EQ=${UserStates.MANAGER_CONFIRMED}&id__!EQ=${userID}`
    );
    query.esp_filters = espFilters;
  }

  const onComplete = (error, response) => {
    // are we still interested in this response?
    if (!shouldAccept(alphabetLetter, sortBy, filterBy, getState())) {
      return;
    }

    if (error) {
      error = error.response.body;

      onUsers(error);
      dispatch(directoryActions.getDirectoryUsersFailure(error));
    } else {
      const { body } = response;

      const users = body.results;
      const totalCount = body.count;
      const pagination = {
        next: body.next,
        prev: body.previous,
      };

      onUsers(null, users);
      dispatch(
        directoryActions.getDirectoryUsersSuccess(users, totalCount, pagination)
      );
    }
  };

  const onError = (error) => {
    onComplete(error);
  };

  const onSuccess = (response) => {
    onComplete(null, response);
  };

  APIcall.get({
    error: onError,
    query,
    success: onSuccess,
    token: true,
    url,
  });
};

let latestSelectedUser = null;
directoryThunks.selectDirectoryUser = (userID, onUser = _.noop) => (
  dispatch,
  getState
) => {
  const state = getState();
  const cache = state.getIn(['directory', 'selectedUser', 'cache']);

  // we keep a cache of selected users for whom we already have all required data
  // stored. If we have cached data for this user we use it to populate the UI
  // as soon as possible while an API call happens in the background to fetch fresh
  // data for it.
  const isInCache = cache.has(userID);
  latestSelectedUser = userID;
  if (isInCache) {
    const usersByID = state.getIn(['entities', 'users']);

    const cachedSelectedUser = usersByID.get(userID);

    const managerUrl = cachedSelectedUser.get('manager');

    let cachedUserManager = null;

    if (managerUrl) {
      /** @type {Array.<string>} */
      const managerUrlParts = managerUrl.split('/');

      /** @type {number} */
      const managerID = Number(managerUrlParts[managerUrlParts.length - 2]);

      cachedUserManager = usersByID.get(managerID);
    }

    dispatch(
      directoryActions.selectDirectoryUserSuccess(
        cachedSelectedUser.toJS(),
        cachedUserManager ? cachedUserManager.toJS() : null
      )
    );
  } else {
    dispatch(directoryActions.selectDirectoryUserStart());
  }

  async.waterfall(
    [
      // 1. Get selectedUser
      (next) => {
        getUser(userID, next);
      },

      // 2. Get selectedUser's manager (if exists)
      (selectedUser, next) => {
        /** type {string} */
        const managerUrl = selectedUser.manager;

        if (managerUrl) {
          // TODO We are getting manager id from manager url, need the api to get updated to do this for us
          /** @type {Array.<string>}  */
          const managerUrlParts = managerUrl.split('/');

          /** @type {number} */
          const managerId = Number(managerUrlParts[managerUrlParts.length - 2]);

          getUser(managerId, (error, selectedUserManager) => {
            if (error) {
              next(error);
            } else {
              next(null, selectedUser, selectedUserManager);
            }
          });
        } else {
          next(null, selectedUser, null);
        }
      },
    ],
    (error, selectedUser, selectedUserManager) => {
      if (error) {
        onUser(error);
        if (latestSelectedUser === userID) {
          latestSelectedUser = null;
        }
        dispatch(directoryActions.selectDirectoryUserFailure(error));
      } else {
        onUser(null, selectedUser);

        if (latestSelectedUser === userID) {
          dispatch(
            directoryActions.selectDirectoryUserSuccess(
              selectedUser,
              selectedUserManager
            )
          ); // Update the selected User in both user and directory reducer
          latestSelectedUser = null;
        } else {
          dispatch(
            directoryActions.noSelectDirectoryUserSuccess(
              selectedUser,
              selectedUserManager
            )
          ); // Only update the user entitie in the userReducer
        }
      }
    }
  );
};

directoryThunks.getPrevDirectoryUsers = (onPrevDirectoryUsers = _.noop) => (
  dispatch,
  getState
) => {
  const state = getState();

  const directory = state.get('directory');

  const isLoadingPrev = directory.getIn(['users', 'isLoadingPrev']);

  if (isLoadingPrev) {
    return;
  }

  const prevDirectoryUsersUrl = directory.getIn([
    'users',
    'pagination',
    'prev',
  ]);

  const alphabetLetter = directory.get('alphabetLetter');
  const sortBy = directory.get('sortBy');
  const filterBy = directory.get('filterBy');

  const onComplete = (error, response) => {
    // are we still interested in this response?
    if (!shouldAccept(alphabetLetter, sortBy, filterBy, getState())) {
      return;
    }

    if (error) {
      error = error.response.body;

      onPrevDirectoryUsers(error);
      dispatch(directoryActions.getPrevDirectoryUsersFailure(error));
    } else {
      const { body } = response;

      const users = body.results;
      const prevUrl = body.previous;

      onPrevDirectoryUsers(null, users);
      dispatch(directoryActions.getPrevDirectoryUsersSuccess(users, prevUrl));
    }
  };

  const onError = (error) => {
    onComplete(error);
  };

  const onSuccess = (response) => {
    onComplete(null, response);
  };

  dispatch(directoryActions.getPrevDirectoryUsersStart());

  APIcall.get({
    error: onError,
    success: onSuccess,
    token: true,
    url: prevDirectoryUsersUrl,
  });
};

directoryThunks.getNextDirectoryUsers = (onNextDirectoryUsers = _.noop) => (
  dispatch,
  getState
) => {
  const state = getState();

  const directory = state.get('directory');

  const isLoadingNext = directory.getIn(['users', 'isLoadingNext']);

  if (isLoadingNext) {
    return;
  }

  const nextDirectoryUsersUrl = directory.getIn([
    'users',
    'pagination',
    'next',
  ]);

  const alphabetLetter = directory.get('alphabetLetter');
  const sortBy = directory.get('sortBy');
  const filterBy = directory.get('filterBy');

  const onComplete = (error, response) => {
    // are we still interested in this response?
    if (!shouldAccept(alphabetLetter, sortBy, filterBy, getState())) {
      return;
    }

    if (error) {
      error = error.response.body;

      onNextDirectoryUsers(error);
      dispatch(directoryActions.getNextDirectoryUsersFailure(error));
    } else {
      const { body } = response;

      const users = body.results;
      const nextUrl = body.next;

      onNextDirectoryUsers(null, users);
      dispatch(directoryActions.getNextDirectoryUsersSuccess(users, nextUrl));
    }
  };

  const onError = (error) => {
    onComplete(error);
  };

  const onSuccess = (response) => {
    onComplete(null, response);
  };

  dispatch(directoryActions.getNextDirectoryUsersStart());

  APIcall.get({
    error: onError,
    success: onSuccess,
    token: true,
    url: nextDirectoryUsersUrl,
  });
};

/**
 * If viewing favorites, reload list of users so added to favorites user shows up.
 */
directoryThunks.reflectFavoritedUser = () => (dispatch, getState) => {
  const state = getState();
  const directoryState = state.get('directory');
  const filterBy = directoryState.get('filterBy');

  if (filterBy === DirectoryFilters.MY_FAVORITES) {
    dispatch(directoryThunks.getDirectoryUsers(true));
  }
};

directoryThunks.openAddEmployeeWorkflow = (formatMessage = _.noop) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentUser = getCurrentUser(state);
  const userID = currentUser.get('id');

  dispatch(directoryActions.openAddEmployeeWorkflowStart());

  dispatch(workflowThunks.mapEIDToID(WorkflowEIDs.CREATE_NEW_EMPLOYEE))
    .then((workflowID) =>
      dispatch(
        workflowThunks.addWorkflowRequest({
          assignedTo: userID,
          currentWorkflow: workflowID,
          owner: userID,
          requestedFor: userID,
          startingWorkflow: workflowID,
        })
      )
    )
    .then((workflowRequest) => {
      dispatch(appUIActions.openWorkflowModal(workflowRequest.id));
      dispatch(directoryActions.openAddEmployeeWorkflowSuccess());
    })
    .catch(() => {
      dispatch(
        toastNotificationsActions.error({
          message: formatMessage({
            id: 'error. workflow_not_installed',
          }),
          title: 'Error',
        })
      );
      dispatch(directoryActions.openAddEmployeeWorkflowFail());
    });
};

export default directoryThunks;
