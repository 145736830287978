const api_task_v0_1_task_classification = {
  results: [
    {
      description: '',
      id: 1,
      name: 'OTHER CLASSIFICATION',
      service_department: 'OTHER',
    },
  ],
};

export default api_task_v0_1_task_classification;
