import { getFormValues, initialize, isPristine } from 'redux-form/immutable';
import _ from 'lodash';

// Thunks
import onboardActivityThunks from '../../actions/onboardActivityThunks';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Util
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const { scheduleID } = ownProps;
  const formName = `ScheduleActivityForm_${scheduleID}`;
  const formError = state.getIn(['form', formName, 'syncErrors']) || null;
  const isLoading = state.getIn(['onboardActivity', 'schedule', 'isLoading']);
  const formValues = getFormValues(`ScheduleActivityForm_${scheduleID}`)(state);

  const key = scheduleID; // Force remount when the ID change

  return {
    formError,
    formValues,
    isLoading,
    isPristine: isPristine(formName)(state),
    key,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewGroup(scheduleID, cb) {
    dispatch(onboardActivityThunks.createNewGroup(scheduleID)).then(cb);
  },

  deleteOneTemplate(intervalID, templateID, isANewSchedule, msg) {
    dispatch(
      onboardActivityThunks.deleteOneTemplateSelected(
        intervalID,
        templateID,
        isANewSchedule
      )
    ).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: msg.message,
          title: msg.header,
        })
      );
    });
  },

  initForm(newId, initialValues) {
    dispatch(initialize(`ScheduleActivityForm_${newId}`, initialValues));
  },

  saveSchedule(scheduleID, copyID, scheduleStatus, msg, cb = _.noop) {
    dispatch(onboardActivityThunks.saveSchedule(scheduleID, copyID)).then(
      (newSchedule) => {
        // Update the status
        dispatch(onboardActivityThunks.saveStatus(newSchedule, scheduleStatus));

        cb();

        if (scheduleID === 'new' || scheduleID === 'copy') {
          // Reload the page with the new schedule created
          browserHistory.push(
            uiPathGenerator.genPath(
              'admin.adminActivities.onboardingActivities.schedule',
              {
                scheduleID: newSchedule,
              }
            )
          );
        } else {
          // Reload the page
          browserHistory.push(
            uiPathGenerator.genPath(
              'admin.adminActivities.onboardingActivities.schedule',
              {
                scheduleID: scheduleID,
              }
            )
          );
        }

        // Display a SnackBar notification msg
        dispatch(
          toastNotificationsActions.success({
            message: msg.message,
            title: msg.header,
          })
        );
      }
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
