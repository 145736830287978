import React, { PureComponent } from 'react';
import { Button, Menu } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'lodash';
import classNames from 'classnames';

// Globals
import BrowserPrompt from '../../globals/BrowserPrompt';
// Utils
import injectIntlToController from '../../utils/injectIntlToController';
// Atoms
import EspProgress02 from '../atoms/EspProgress02';
import BodyClassName from '../../../globals/BodyClassName';

class WorkflowMenu extends PureComponent {
  static propTypes = {
    areHeaderElementsVisible: PropTypes.bool,
    backButton: PropTypes.string,
    formSyncErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    isCloseIconVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    nextButton: PropTypes.string,
    onBackButtonClick: PropTypes.func,
    onCloseButtonClick: PropTypes.func,
    onSkipButtonClick: PropTypes.func,
    pageBackground: PropTypes.bool,
    placement: PropTypes.string,
    progress: ImmutablePropTypes.mapContains({
      max_steps: PropTypes.number.isRequired,
      steps_completed: PropTypes.number.isRequired,
    }),
    progressActive: PropTypes.bool,
    skipButton: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    areHeaderElementsVisible: false,
    backButton: '',
    formSyncErrors: null,
    isCloseIconVisible: false,
    isLoading: false,
    nextButton: '',
    onBackButtonClick: _.noop,
    onCloseButtonClick: _.noop,
    onSkipButtonClick: _.noop,
    pageBackground: false,
    placement: '',
    progress: Immutable.Map(),
    progressActive: false,
    skipButton: '',
    title: '',
  };

  //  NOTE: Disabling this for now. I believe Pascal added this initially but I see no reason for it as this is a Component
  //  shouldComponentUpdate(nextProps) {
  //    if (nextProps.progress.get('steps_completed') != this.props.progress.get('steps_completed')) {
  //      return true;
  //    }
  //    return nextProps.isLoading != this.props.isLoading;
  //  }

  handleBackButtonClick = () => {
    const { isLoading, onBackButtonClick } = this.props;

    if (isLoading) {
      return;
    }

    onBackButtonClick();
  };

  handleCloseIconClick = (e) => {
    const { onCloseButtonClick } = this.props;

    e.preventDefault();

    const {
      intl: { formatMessage },
    } = this.props;
    const confirmed = BrowserPrompt.confirm(
      formatMessage({
        id: 'message.leaving_workflow_warning_title',
      }),
      {
        buttons: [
          formatMessage({
            id: 'label.cancel',
          }),
          formatMessage({
            id: 'label.ok',
          }),
        ],
        callback: function (action) {
          if (action === 2) {
            // Confirmation for cordova only
            onCloseButtonClick();
          }
        },
        content: formatMessage({
          id: 'message.leaving_workflow_warning_content',
        }),
        title: formatMessage({
          id: 'message.leaving_workflow_warning_title',
        }),
      }
    );

    if (confirmed) {
      onCloseButtonClick();
    }
  };

  render() {
    const {
      areHeaderElementsVisible,
      backButton,
      formSyncErrors,
      intl: { formatMessage },
      isCloseIconVisible,
      isLoading,
      nextButton,
      onSkipButtonClick,
      pageBackground,
      placement,
      progress,
      progressActive,
      skipButton,
      title,
    } = this.props;
    // const header = this.props.placement === 'top';

    const footer = placement === 'bottom';
    const maxSteps = progress.get('max_steps');
    const complete =
      progress.get('steps_completed') === maxSteps && maxSteps > 0;

    return (
      <BodyClassName
        className={classNames({
          'two-menu-items': nextButton && footer,
        })}
      >
        <Menu borderless fixed={placement} fluid vertical={footer}>
          {backButton && areHeaderElementsVisible && (
            <Menu.Item
              className='menu-back'
              disabled={isLoading}
              icon='angle left'
              name={backButton || 'back'}
              onClick={this.handleBackButtonClick}
            />
          )}

          {!backButton && isCloseIconVisible ? <Menu.Item /> : null}

          {/*
        Note: Need to hide the progress bar if more step than 98
        In this case, we still need to display the empty menu to avoid to
        break the content justification
        */}
          {progressActive && areHeaderElementsVisible && (
            <Menu.Item className='menu-progress'>
              {maxSteps < 99 && <EspProgress02 progress={progress} />}
            </Menu.Item>
          )}

          {title && areHeaderElementsVisible && (
            <Menu.Item className='title'>{title}</Menu.Item>
          )}

          {nextButton && areHeaderElementsVisible && (
            <Menu.Item name='next'>{nextButton}</Menu.Item>
          )}

          {/* areHeaderElementsVisible &&
          <Menu.Item
            className='menu-help'
            icon='help circle'
            onClick={this.handleOpenBaristaModal}
          />
        */}

          {isCloseIconVisible && (
            <Menu.Item
              as={NavLink}
              className='menu-leave'
              content={formatMessage({
                id: 'label.exit',
              })}
              onClick={this.handleCloseIconClick}
              to='#'
            />
          )}

          {title && !isCloseIconVisible ? <Menu.Item /> : null}

          {nextButton && footer && (
            <Menu.Item className='center aligned'>
              <Button
                basic={!pageBackground && !complete}
                className='menu-next'
                disabled={isLoading || Boolean(formSyncErrors)}
                loading={isLoading}
                primary
                size='large'
                type='submit'
              >
                {nextButton}
              </Button>
            </Menu.Item>
          )}

          {footer && skipButton && (
            <Menu.Item className='center aligned'>
              <Button
                className='menu-skip text'
                disabled={isLoading}
                loading={isLoading}
                onClick={onSkipButtonClick}
              >
                {skipButton}
              </Button>
            </Menu.Item>
          )}
        </Menu>
      </BodyClassName>
    );
  }
}

const WorkflowMenuTest = WorkflowMenu;

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowMenu = injectIntlToController(WorkflowMenu);
export { WorkflowMenuTest };
export default WorkflowMenu;
