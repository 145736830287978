import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const propTypes = {
  erroed: PropTypes.bool,
  loading: PropTypes.bool,
  onSaveFunc: PropTypes.func.isRequired,
  saved: PropTypes.bool,
  text: PropTypes.string,
};

const SaveButton = ({
  erroed = false,
  saved = true,
  loading = false,
  text = 'Save',
  onSaveFunc,
}) => {
  if (erroed) {
    return <Button disabled icon='exclamation' positive />;
  }
  if (saved) {
    return <Button disabled icon='checkmark' positive />;
  }
  if (loading) {
    return <Button disabled icon='checkmark' loading positive />;
  }

  return <Button content={text} onClick={onSaveFunc} positive />;
};

SaveButton.propTypes = propTypes;

export default SaveButton;
