const api_espuser_v0_1_job_role = {
  count: 15,
  next: null,
  previous: null,
  results: [
    {
      id: 1,
      name: 'Standard Office Worker',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/job_role/1/',
    },
    {
      id: 2,
      name: 'Architect',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/job_role/2/',
    },
    {
      id: 3,
      name: 'Designer',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/job_role/3/',
    },
    {
      id: 4,
      name: 'Director',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/job_role/4/',
    },
    {
      id: 5,
      name: 'Factory Worker',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/job_role/5/',
    },
  ],
};

export default api_espuser_v0_1_job_role;
