import _ from 'lodash';
import Cookies from 'js-cookie';
import jsonp from 'superagent-jsonp';
import request from 'superagent';
import { TokenUtil } from 'esp-util-auth';
import wrap from 'superagent-promise';

const MAX_TIME_BETWEEN_REQUESTS = process.env.NODE_ENV ? 0 : 500; // 0 for test environment, otherwise half second

// Provisionally using this as I couldn't find any library that does it
// https://github.com/segmentio/superagent-csrf/ does something similar but
// 1) we'd still need to pass the cookie token AND  it has hardcoded
// the header 'X-CSRF-Token' (vs 'X-CSRFToken' the one we use)
request.Request.prototype.setCSRFToken = function () {
  this.set('X-CSRFToken', Cookies.get('csrftoken'));
  return this;
};
const SuperAgent = wrap(request, Promise);

const getOdefaultOptions = (o) =>
  Object.assign(
    {
      data: null,
      error() {},
      forceEnableAntiSpam: false,
      jsonp: false,
      preventShowError: false, // Prevents error from appearing in UI popup
      query: {},
      success() {},
      tmpToken: null,
      token: null,
      url: '',
    },
    o
  );

const getUrlHash = (url = '', query = {}, method = '', data = {}) =>
  url + JSON.stringify(query) + method + JSON.stringify(data);

class XHRUtil {
  constructor(params = {}) {
    this._requestCache = {};

    this.onAnyError = params.onAnyError || _.noop;
    this.onAnySuccess = params.onAnySuccess || _.noop;
    this.store = params.store;
    this.tokenLocation = params.tokenLocation || ['session', 'accessToken'];
  }

  setStore(store) {
    this.store = store;
  }

  isSpammingTooMuch(urlHash) {
    if (this._requestCache[urlHash]) {
      // if exists kick them out
      // Leave this warning here so devs/qa can notice when a component is too greedy with calls
      return true;
    } else {
      // The throttles function have a weird behavior and in some case, the urlHash is not deleted at all.
      // For now, I used for now a simple setTimeout
      // throttledCooldown(urlHash);

      setTimeout(() => {
        delete this._requestCache[urlHash];
      }, MAX_TIME_BETWEEN_REQUESTS);
    }

    return false;
  }
  doRequest(method = 'GET', _options = {}) {
    const options = getOdefaultOptions(_options);

    const origin = window.location.origin || '';
    const [originProtocol] = origin.split('://');
    const [protocol] = options.url.split('://');

    // If url is not using https but origin is, this means
    // we are running all local client and tenant with ssl
    if (protocol === 'http' && originProtocol === 'https') {
      options.url = options.url.replace('http:', 'https:');
    }

    const request = SuperAgent(method, options.url);

    if (options.responseType) {
      request.responseType(options.responseType);
    }
    if (options.headers) {
      if (options.headers.contentType) {
        request.type(options.headers.contentType);
      }
    }

    request.query(options.query);

    // Adding access token
    const accessToken = this.getAccessToken(options);
    if ((options.token || options.tmpToken) && accessToken) {
      request.set('Authorization', accessToken);
    }

    // if app is inside an iframe add header to identify the request, this allows to identify the widget
    if (window.self !== window.top) {
      // Prevents the header from being sent when running locally
      if (!window.location.hostname.includes('.local')) {
        request.set('X-Embedded-App', 'true');
      }
    }

    // Adding impersonating as user header
    const impersonatingUser = this.getImpersonatingUser();
    // we should not send X-AS-USER for external apis
    if (impersonatingUser && !options?.isExternalAPI) {
      request.set('X-AS-USER', impersonatingUser);
    }

    const appVersion = this.store
      ? this.store.getState().getIn(['appVersion', 'clientId'])
      : null;
    if (appVersion) {
      request.set('X-Client-ID', appVersion);
    }

    if (options.attach) {
      request.attach('picture', options.attach);
    }

    if (method !== 'DELETE') {
      request.send(options.data);
    }

    if (options.jsonp) {
      request.use(jsonp);
    }

    request.setCSRFToken();

    // const errorId = buildUrlId(options.url);

    const urlHash = getUrlHash(
      options.url,
      options.query,
      method,
      options.data
    );

    // force it when we run in in anything OTHER THAN karma
    // OR if the special option forceEnableAntiSpam is used (by tests)
    const enableSpam = process.env.NODE_ENV || options.forceEnableAntiSpam;
    if (enableSpam && this.isSpammingTooMuch(urlHash)) {
      // checks if this is considered too spammy
      // if it's spammy then uses the promise results to send the success/error callbacks
      return this._requestCache[urlHash]
        .then((res) => {
          options.success(res);
          return res;
        })
        .catch((err) => {
          options.error(err);
          return err;
        });
    } else {
      this._requestCache[urlHash] = new Promise((resolve, reject) => {
        request.end((err, res) => {
          if (err) {
            // handle errors
            options.error(err); // execute error callback
            this.onAnyError(err, res, options);
            reject(err);
          } else {
            options.success(res); // execute success callback
            this.onAnySuccess(res, options);
            resolve(res);
          }
        });
      });

      return this._requestCache[urlHash];
    }
  }

  getAccessKey() {
    const state = this.store.getState();
    return state.hasIn(this.tokenLocation) && state.getIn(this.tokenLocation)
      ? state.getIn(this.tokenLocation) // Get Token from session reducer
      : TokenUtil.getToken(); // Get Token from local storage
  }

  getAccessToken(options = {}) {
    let tokenKey;
    if (options.tmpToken) {
      tokenKey = options.tmpToken;
    } else {
      tokenKey =
        typeof options.token !== 'boolean'
          ? options.token
          : this.getAccessKey();
    }
    return tokenKey ? `Token ${tokenKey}` : '';
  }

  getImpersonatingUser() {
    if (this.store) {
      return this.store
        .getState()
        .getIn(['session', 'impersonation', 'impersonatingUserEid']);
    }
    return null;
  }

  del(options = {}) {
    return this.doRequest('DELETE', options);
  }

  delete(options = {}) {
    return this.doRequest('DELETE', options);
  }

  get(options = {}) {
    return this.doRequest('GET', options);
  }

  patch(options = {}) {
    return this.doRequest('PATCH', options);
  }

  post(options = {}) {
    return this.doRequest('POST', options);
  }

  put(options = {}) {
    return this.doRequest('PUT', options);
  }

  options(options = {}) {
    return this.doRequest('OPTIONS', options);
  }

  generateWebSocket(url = '') {
    const wsURL = url;
    const token = this.getAccessKey();

    // Adding impersonating as user header
    const impersonatingUser = this.getImpersonatingUser();

    // Generating the actual socket
    const socket = new WebSocket(wsURL);

    // we need to immediatly send a message on open with the
    // authentication credentials
    socket.addEventListener('open', () => {
      const authMessage = {
        body: {
          api_key: token,
          // 'as_user' : impersonatingUser,
        },
        rttype: 'authentication',
      };

      // because of be bug, don't send as_user unless it exists
      if (impersonatingUser) {
        authMessage.body.as_user = impersonatingUser;
      }
      socket.send(JSON.stringify(authMessage));
    });

    return socket;
  }
}
export default XHRUtil;
