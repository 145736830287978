const api_espuser_v0_1_users_service_agents = {
  count: 3,
  next: null,
  previous: null,
  results: [
    {
      about_me: 'Pandas I like thiiiiiings hfrugchhr',
      alias: 'romi@espressive.com',
      eid: 'bf79a8da-2efe-4f5c-a754-5c10ecf11f6b',
      email: 'romi@espressive.com',
      first_name: 'romi',
      full_name: 'romi schneader',
      id: 63,
      is_active: true,
      is_admin: true,
      last_name: 'schneader',
      manager: 'https://release.qa.espressive.com/api/espuser/v0.1/users/2/',
      nickname: 'romi',
      phone_numbers: {
        home: {
          number: '4084067949',
          public: true,
        },
        mobile: {
          number: '4084067949',
          public: true,
        },
        work: {
          number: '',
          public: true,
        },
      },
      service_departments: [
        {
          id: 20,
        },
      ],
      social_coordinates: [
        {
          network: 'linkedin',
          username: 'Testyff',
        },
        {
          network: 'twitter',
          username: '',
        },
      ],
      social_profile_picture: '',
      title: '',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/users/63/',
      user_state: 'MANAGER_CONFIRMED',
      username: 'romi@espressive.com',
      work_collaboration: null,
    },
    {
      about_me: 'I like to have new firends!!!! P)!!!!!',
      alias: 'deaf@espressive.com',
      eid: 'c9697804-8009-48d4-9adc-fddd77753f57',
      email: 'deaf@espressive.com',
      first_name: 'deaf',
      full_name: 'deaf goil',
      id: 85,
      is_active: true,
      is_admin: true,
      last_name: 'goil',
      manager: 'https://release.qa.espressive.com/api/espuser/v0.1/users/44/',
      nickname: 'deaf',
      phone_numbers: {
        home: {
          number: '1235557890',
          public: true,
        },
        mobile: {
          number: '1235557890',
          public: true,
        },
        work: {
          number: '1235557890',
          public: true,
        },
      },
      service_departments: [
        {
          id: 21,
        },
      ],
      social_coordinates: [
        {
          network: 'linkedin',
          username: 'erickmendoza',
        },
        {
          network: 'twitter',
          username: 'odiseo42',
        },
      ],
      social_profile_picture: '',
      title: '',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/users/85/',
      user_state: 'MANAGER_CONFIRMED',
      username: 'deaf@espressive.com',
      work_collaboration: null,
    },
    {
      about_me: '',
      alias: '',
      department: 'FE',
      eid: '5352a4c1-2106-4552-8717-19f69c3144f6',
      email: 'Freanco@release.qa.espressive.com',
      first_name: 'Freanco',
      full_name: 'Freanco Delavega',
      id: 45,
      is_active: true,
      is_admin: true,
      last_name: 'Delavega',
      manager: 'https://release.qa.espressive.com/api/espuser/v0.1/users/8/',
      nickname: 'Freanco',
      phone_numbers: {
        home: {
          country_code: '',
          number: '6505551212',
          public: true,
        },
        mobile: {
          country_code: '',
          number: '4085551212',
          public: true,
        },
        work: {
          number: '',
          public: true,
        },
      },
      service_departments: [
        {
          id: 22,
        },
      ],
      social_coordinates: [
        {
          network: 'linkedin',
        },
        {
          network: 'twitter',
          username: '',
        },
      ],
      social_profile_picture: '',
      title: 'FE Engineer',
      url: 'https://release.qa.espressive.com/api/espuser/v0.1/users/45/',
      user_state: 'MANAGER_CONFIRMED',
      username: 'Freanco@release.qa.espressive.com',
      work_collaboration: null,
    },
  ],
};

export default api_espuser_v0_1_users_service_agents;
