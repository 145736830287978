'use stritc';

import React, { PureComponent } from 'react';
import { Divider, Form } from 'semantic-ui-react';

// Molecules
import ValidatedField from './ValidatedField';
import ValidatedSelect from './ValidatedSelect';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import { languageOptions } from '../../globals/customLabels';

class LabelNoun extends PureComponent {
  static propTypes = {
    customLabel: ImmutablePropTypes.map,
    name: PropTypes.string,
  };

  static defaultProps = {
    customLabel: Immutable.Map(),
    name: '',
  };

  shouldComponentRender() {
    return true;
  }

  render() {
    const { customLabel, name } = this.props;

    return (
      <Form.Group inline>
        <Divider clearing hidden />

        <ValidatedField
          label={customLabel.get('name')}
          name={name}
          placeholder={`Enter a custom name for ${customLabel.get('name')}`}
          width={11}
        />
        <ValidatedSelect
          className='hidden'
          hidden
          name={`${name}-language`}
          options={languageOptions}
          width={5}
        />
      </Form.Group>
    );
  }
}

export default LabelNoun;
