import actionTypes from './actionTypes';

const matchingWizardActions = {};

matchingWizardActions.saveSearchQuery = (node, query) => ({
  node,
  query,
  type: actionTypes.SAVE_SEARCH_QUERY,
});
matchingWizardActions.resetSearchQuery = (node, query) => ({
  node,
  query,
  type: actionTypes.RESET_SEARCH_QUERY,
});
matchingWizardActions.openWizard = (faqEid) => ({
  faqEid,
  type: actionTypes.FAQ_WIZ_OPEN,
});

matchingWizardActions.faqResetWizard = () => ({
  type: actionTypes.FAQ_WIZ_RESET,
});

matchingWizardActions.closeWizard = () => ({
  type: actionTypes.FAQ_WIZ_CLOSE,
});

matchingWizardActions.resetWizardSelections = () => ({
  type: actionTypes.FAQ_WIZ_RESET_SELECTIONS,
});

matchingWizardActions.resetSelectedWord = () => ({
  type: actionTypes.FAQ_WIZ_RESET_SELECTED_WORD,
});

matchingWizardActions.getArchetypeConditionsStart = () => ({
  type: actionTypes.FAQ_WIZ_GET_DATA_START,
});

matchingWizardActions.getArchetypeConditionsSuccess = (results) => ({
  results,
  type: actionTypes.FAQ_WIZ_GET_DATA_SUCCESS,
});

matchingWizardActions.getEntityStart = () => ({
  type: actionTypes.FAQ_WIZ_GET_ENTITY_START,
});

matchingWizardActions.getEntitySuccess = (results) => ({
  results,
  type: actionTypes.FAQ_WIZ_GET_ENTITY_SUCCESS,
});

matchingWizardActions.getEntityFail = () => ({
  type: actionTypes.FAQ_WIZ_GET_ENTITY_FAIL,
});

matchingWizardActions.selectEntity = (entityEid) => ({
  entityEid,
  type: actionTypes.FAQ_WIZ_SELECT_ENTITY,
});

matchingWizardActions.selectEntityWord = (entityWordEid) => ({
  entityWordEid,
  type: actionTypes.FAQ_WIZ_SELECT_ENTITY_WORD,
});

matchingWizardActions.selectTemplate = (templateEid) => ({
  templateEid,
  type: actionTypes.FAQ_WIZ_SELECT_TEMPLATE,
});

matchingWizardActions.selectRequestTemplate = () => ({
  type: actionTypes.FAQ_WIZ_SELECT_REQ_TEM,
});

matchingWizardActions.removeRequestTemplate = () => ({
  type: actionTypes.FAQ_WIZ_DESELECT_REQ_TEM,
});

matchingWizardActions.selectRequestKeywordList = () => ({
  type: actionTypes.FAQ_WIZ_SELECT_REQ_KEYWORD_LIST,
});

matchingWizardActions.removeRequestKeywordList = () => ({
  type: actionTypes.FAQ_WIZ_DESELECT_REQ_KEYWORD_LIST,
});

matchingWizardActions.getEntityWordsStart = (entityEid) => ({
  entityEid,
  type: actionTypes.FAQ_WIZ_GET_ENTITY_WORDS_START,
});

matchingWizardActions.getEntityWordsSuccess = (entityEid, results) => ({
  entityEid,
  results,
  type: actionTypes.FAQ_WIZ_GET_ENTITY_WORDS_SUCCESS,
});

matchingWizardActions.addNewWordSuccess = (instance) => ({
  instance,
  type: actionTypes.FAQ_WIZ_ADD_NEW_WORD_SUCCESS,
});

matchingWizardActions.editWordSuccess = (eid, instance) => ({
  eid,
  instance,
  type: actionTypes.FAQ_WIZ_EDIT_WORD_SUCCESS,
});

matchingWizardActions.removeWordSuccess = (eid) => ({
  eid,
  type: actionTypes.FAQ_WIZ_REMOVE_WORD_SUCCESS,
});

matchingWizardActions.addNewSynonymSuccess = (instance) => ({
  instance,
  type: actionTypes.FAQ_WIZ_ADD_NEW_SYNON_SUCCESS,
});

matchingWizardActions.editSynonymSuccess = (eid, instance) => ({
  eid,
  instance,
  type: actionTypes.FAQ_WIZ_EDIT_SYNON_SUCCESS,
});

matchingWizardActions.removeSynonymSuccess = (eid) => ({
  eid,
  type: actionTypes.FAQ_WIZ_REMOVE_SYNON_SUCCESS,
});

matchingWizardActions.getEntityWorsdFail = (entityEid, error) => ({
  entityEid,
  error,
  type: actionTypes.FAQ_WIZ_GET_ENTITY_WORDS_FAIL,
});

matchingWizardActions.getWordSynonymsStart = (entityEid, entityWordEid) => ({
  entityEid,
  entityWordEid,
  type: actionTypes.FAQ_WIZ_GET_WORD_SYNON_START,
});

matchingWizardActions.getWordSynonymsSuccess = (
  entityEid,
  entityWordEid,
  results
) => ({
  entityEid,
  entityWordEid,
  results,
  type: actionTypes.FAQ_WIZ_GET_WORD_SYNON_SUCCESS,
});

matchingWizardActions.getWordSynonymsFail = (
  entityEid,
  entityWordEid,
  error
) => ({
  entityEid,
  entityWordEid,
  error,
  type: actionTypes.FAQ_WIZ_GET_WORD_SYNON_FAIL,
});

matchingWizardActions.getTemplatesStart = (entityEid, entityWordEid) => ({
  entityEid,
  entityWordEid,
  type: actionTypes.FAQ_WIZ_GET_TEMPLATES_START,
});

matchingWizardActions.getTemplatesSuccess = (
  entityEid,
  entityWordEid,
  results
) => ({
  entityEid,
  entityWordEid,
  results,
  type: actionTypes.FAQ_WIZ_GET_TEMPLATES_SUCCESS,
});

matchingWizardActions.getTemplatesFail = (entityEid, entityWordEid, error) => ({
  entityEid,
  entityWordEid,
  error,
  type: actionTypes.FAQ_WIZ_GET_TEMPLATES_FAIL,
});
matchingWizardActions.getTemplateExamplesStart = (intentId) => ({
  intentId,
  type: actionTypes.FAQ_WIZ_GET_TEMPLATE_EXAMPLES_START,
});

matchingWizardActions.getTemplateExamplesSuccess = (intentId, results) => ({
  intentId,
  results,
  type: actionTypes.FAQ_WIZ_GET_TEMPLATE_EXAMPLES_SUCCESS,
});

matchingWizardActions.getTemplateExamplesFail = (intentId, error) => ({
  error,
  intentId,
  type: actionTypes.FAQ_WIZ_GET_TEMPLATE_EXAMPLES_FAIL,
});

export default matchingWizardActions;
