//
// Attenpts to decode a string
// @param {*} data A string suspected to be base64 encoded
const b64DecodeIfEncoded = (data) => {
  let decoded = data;

  if (!data || typeof data !== 'string') {
    return data;
  }

  try {
    decoded = atob(data);
  } catch (e) {
    decoded = data;
  }

  return decoded;
};

export default b64DecodeIfEncoded;
