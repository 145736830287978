import { createSelector } from 'reselect';

// toDo
// This should be changed to consume the new reducer instead
// once we move this out of baristaReducer (See DEV-5900)
const getFaqSelectedDepartment = (state) =>
  state.getIn(['barista', 'selectedDepartmentFAQ']);

/**
 * Returns the service department ID selected in FAQ as a number
 */
const getFAQServiceDeptIDSelected = (departmentID) =>
  !departmentID ? null : Number(departmentID);

const selector = createSelector(
  [getFaqSelectedDepartment],
  getFAQServiceDeptIDSelected
);
export { getFaqSelectedDepartment };
export default selector;
