import APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const getUserByID = (userID) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espUser.users.instance', {
      userID,
    });
    APIcall.get({
      token: true,
      url: url,
    })
      .then(({ body }) => {
        resolve(body);
      })
      .catch(reject);
  });

export default getUserByID;
