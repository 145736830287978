import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { noop } from 'lodash';
import { Button, Container } from 'semantic-ui-react';
// Component self Controller
import Controller from './PortalBaristaController';
// Globals
import EspUserPropShape from '../../../globals/EspUserPropShape';
// Organisms
import BaristaQR from '../../organisms/BaristaQR';
import OnAppWorkflowModal from '../../organisms/OnAppWorkflowModal';

// Molecules
import ChatBarista from '../../molecules/ChatBarista';
// Blocks
import Barista01 from '../../blocks/barista/Barista01';
// Package
import { intl } from 'esp-util-intl';
import BodyClassName from '../../../../globals/BodyClassName';

class PortalBarista extends PureComponent {
  static propTypes = {
    currentUser: EspUserPropShape,
    doPortalHelloActions: PropTypes.func,
    isBaristaOpened: PropTypes.bool,
    location: PropTypes.shape({
      query: PropTypes.shape({}),
      search: PropTypes.string,
    }),
    params: PropTypes.shape({
      channelID: PropTypes.string,
      intentEid: PropTypes.string,
    }),
    reOpenBarista: PropTypes.func,
  };
  static defaultProps = {
    currentUser: null,
    doPortalHelloActions: noop,
    isBaristaOpened: false,
    location: {},
    params: {},
    reOpenBarista: noop,
  };
  componentDidMount() {
    const { doPortalHelloActions } = this.props;
    doPortalHelloActions();
  }

  handleOpenBarista = () => {
    const { reOpenBarista } = this.props;
    reOpenBarista();
  };
  renderAltScreen = () => {
    const { isBaristaOpened } = this.props;

    const messages = [
      {
        text: intl.formatMessage({
          id: 'message.you_may_now_close_this_window',
        }),
      },
    ];
    const baristaBlock = {
      id: 11,
      messages,
      template: 'barista01',
      type: 'barista',
    };

    const screenContent = (
      <Container
        style={{
          maxWidth: '600px !important',
          paddingTop: '2em',
        }}
        textAlign='center'
      >
        <Barista01 {...baristaBlock} />
        <Button
          basic
          content={intl.formatMessage({
            id: 'message.ask_another_question',
          })}
          onClick={this.handleOpenBarista}
        />
      </Container>
    );

    return isBaristaOpened ? null : screenContent;
  };

  render() {
    const { currentUser, isBaristaOpened, location, params } = this.props;

    const isChatV2 =
      new URLSearchParams(window.location.search).get('chat_version') === '2';

    // if chatv2 is requested, prevent rendering, as it will be redirected to chatv2 app
    if (isChatV2) {
      return null;
    }

    return (
      <BodyClassName className='portal-page'>
        <>
          <Helmet title='Barista' />

          {/** DEV-14894: This component needs to be present to open workflows */}
          <OnAppWorkflowModal location={location} />

          {/** This component handles Barista initialization via URL params */}
          <BaristaQR isPortal location={location} params={params} />

          {/** Actual Barista modal */}
          {currentUser && isBaristaOpened ? (
            <ChatBarista isPortal trigger={<div />} />
          ) : null}

          {!isBaristaOpened ? this.renderAltScreen() : null}
        </>
      </BodyClassName>
    );
  }
}

const PortalBaristaTest = PortalBarista;
export { PortalBaristaTest };
export default Controller(PortalBarista);
