import { List, Map } from 'immutable';
// Global
import {
  TypeStateGroupCondition,
  TypeStateGroupProps,
} from '../globals/FAQTypeStateGroup';

import { isBoolean } from 'lodash';
// Util
import rebuildFAQAnswers from './rebuildFAQAnswers';
import UserStates from '../globals/UserStates';

const LOCATIONS = 'locations';

/**
 *  Build condition
 * @param response
 * @returns {string}
 */
const buildCondition = (response) => {
  let conditions = '';
  const locationdeptrole =
    response && !response.isEmpty() && response.get('locationdeptrole');
  if (locationdeptrole) {
    let previousCondition = null;
    locationdeptrole.forEach((value, key) => {
      if (List.isList(value)) {
        value.forEach((currentCondition) => {
          const isDeviceType =
            TypeStateGroupProps[key] === '$IT_OperatingSystems';
          const prefix = isDeviceType ? '' : '#user.';
          if (!TypeStateGroupProps[key]) {
            if (!conditions) {
              conditions = key;
              previousCondition = key;
            } else {
              // Change the operator if the previous condition set is the same or not form the new one
              const operator = previousCondition === key ? '&OR' : '&';
              previousCondition = key;
              conditions += `${operator}${key}`;
            }
          } else {
            if (!conditions) {
              if (key === LOCATIONS) {
                conditions = `${prefix}${TypeStateGroupProps[key]}${
                  TypeStateGroupCondition[key]
                }${currentCondition.get('code')}`;
              } else {
                conditions = `${prefix}${TypeStateGroupProps[key]}${
                  TypeStateGroupCondition[key]
                }${currentCondition.get('id')}`;
              }

              previousCondition = TypeStateGroupProps[key];
            } else {
              // Change the operator if the previous condition set is the same or not form the new one
              const operator =
                previousCondition === TypeStateGroupProps[key] ? '&OR' : '&';
              previousCondition = TypeStateGroupProps[key];
              if (key === LOCATIONS) {
                conditions += `${operator}${prefix}${TypeStateGroupProps[key]}${
                  TypeStateGroupCondition[key]
                }${currentCondition.get('code')}`;
              } else {
                conditions += `${operator}${prefix}${TypeStateGroupProps[key]}${
                  TypeStateGroupCondition[key]
                }${currentCondition.get('id')}`;
              }
            }
          }
        });
      } else if (isBoolean(value) && TypeStateGroupProps[key] && value) {
        const conditionValue =
          TypeStateGroupProps[key] === TypeStateGroupProps.include_new_hire
            ? UserStates.MANAGER_CONFIRMED
            : 'True';
        const conditionCheck =
          TypeStateGroupProps[key] === TypeStateGroupProps.include_new_hire
            ? '__EQ='
            : '__IBOOL=';

        if (!conditions && conditionValue) {
          conditions = `#user.${TypeStateGroupProps[key]}${conditionCheck}${conditionValue}`;
          previousCondition = TypeStateGroupProps[key];
        } else if (conditionValue) {
          // Change the operator if the previous condition set is the same or not form the new one
          const operator =
            previousCondition === TypeStateGroupProps[key] ? '&OR' : '&';
          previousCondition = TypeStateGroupProps[key];
          conditions += `${operator}#user.${TypeStateGroupProps[key]}${conditionCheck}${conditionValue}`;
        }
      }
    });
  }
  return conditions;
};

const buildFAQFromBEScratch = (state, fromEntity) => {
  // Load data from BE scratch in workflowState
  const backendScratch = state.hasIn([
    'workflowState',
    'backendScratch',
    'scratch.temp_data',
  ])
    ? state.getIn(['workflowState', 'backendScratch', 'scratch.temp_data'])
    : null;

  /**
   * No BE scratch data error
   */
  if (!backendScratch || backendScratch.isEmpty()) {
    return {
      error: new Error('BackEndScratch data error'),
    };
  }

  /**
   * Set all different properties needed
   * to build the object to pass
   */
  const faqName = backendScratch.getIn(['category_faq', 'name']);
  const categoryEID = backendScratch.getIn(['category_faq', 'eid']);
  const serviceDepartmentId = backendScratch.getIn([
    'category_faq',
    'serviceDepartment',
  ]);
  const firstQuestion = backendScratch.get('faq_question');
  const phrases = backendScratch.get('phrases_faq');
  const responsesList = backendScratch.get('faq_response');
  const serviceTeam = state.hasIn([
    'workflowState',
    'frontendScratch',
    'serviceTeam',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'serviceTeam'])
    : backendScratch.get('serviceTeam');

  const description = backendScratch.get('faq_desc');

  const imageNewFaq = state.getIn(['adminFaqs', 'imageNewFaq']);
  let newFileToUpload = []; // List of files to upload as new line - We will have to fill this list from our reducer

  // Check if the data is from an ELC
  // const isFromELC = backendScratch.get('is_faq_protected');

  let objectFaq = {
    // Support for edit
    answers: [],

    description: description,

    name:
      firstQuestion ||
      (phrases && phrases.first() && phrases.first().get('phrase')) ||
      description,
    triggers: firstQuestion
      ? [
          {
            phrase: firstQuestion,
          },
        ]
      : [],
  };

  objectFaq.attributes = [
    {
      name: 'service_team',
      value: serviceTeam,
    },
    {
      name: 'service_department',
      value: null,
    },
    {
      name: 'esp.service_department',
      value: null,
    },
    {
      name: 'category',
      value: categoryEID,
    },
  ];

  /**
   * Add Entity data if fromEntity is passed
   */
  if (fromEntity) {
    const archetypeEID = backendScratch.getIn([
      'selected_template',
      'template',
    ]);
    const entityEID = state.getIn([
      'adminFaqs',
      'primaryEntities',
      archetypeEID,
      'eid',
    ]);
    const newEntity = backendScratch.get('new_entity');
    const synonym = backendScratch.get('synonym');
    const synonymList = backendScratch.get('synonym_list');
    const selectedWord = backendScratch.get('entityLabel');
    objectFaq.archetype = archetypeEID;

    // set entity based on entityLabel (the eid is not saved, but we can look it up)
    const foundEntity = state
      .getIn(['adminFaqs', 'entityList'])
      .find((ent) => ent.get('label') === selectedWord);
    if (foundEntity) {
      objectFaq.entity_value = foundEntity.get('eid'); // this was missing, see comment by Nieto in DEV-6829
    }

    objectFaq.entity = {
      eid: entityEID,
      value: {
        instances: [],
        name: newEntity || selectedWord,
      },
    };

    if (synonym && typeof synonym === 'string') {
      objectFaq.entity.value.instances = [
        {
          name: synonym,
        },
      ];
    } else if (synonymList && Map.isMap(synonymList)) {
      // This is a map of new synonym
      synonymList.forEach((entry) => {
        objectFaq.entity.value.instances.push({
          name: entry.get('phrase'),
        });
      });
    }

    // DEV-8214
    // send entity_value when only a word is selected and no synonyms added
    if (selectedWord && !objectFaq.entity.value.instances.length) {
      delete objectFaq.entity; // deleting entity to just send entity_value
    } else {
      // send entity in every other case
      delete objectFaq.entity_value; // deleting entity_value to just send entity
    }
  }

  /**
   * Build questions variations if exists
   */
  if (phrases) {
    phrases.forEach((phrase) => {
      if (phrase && phrase.has('phrase') && phrase.get('phrase')) {
        objectFaq.triggers.push({
          phrase: phrase.get('phrase'),
        });
      }
    });
  }

  /**
   * Build Answers and Conditions
   */
  if (Map.isMap(responsesList)) {
    const newResponses = rebuildFAQAnswers(
      responsesList,
      objectFaq,
      imageNewFaq
    );

    objectFaq = newResponses.newObj;

    newFileToUpload = newResponses.fileToUpload;
  }

  return {
    faqIsProtected: backendScratch.get('is_faq_protected', false),
    faqName,
    fileToUpload: newFileToUpload,
    objectFaq,
    serviceDepartmentId,
  };
};

export const buildConditionTest = buildCondition;

export default buildFAQFromBEScratch;
