import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';

// global
import BirthdayDefinition, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBirthday';
// Atom
import BirthdayPicker from '../../atoms/BirthdayPicker';
// Controller
import BirthdayBlockController from '../../controllers/BirthdayBlockController';

class Birthday01 extends Component {
  static propTypes = {
    ...BirthdayDefinition,
    changeValue: PropTypes.func.isRequired,
    valuePrexisting: PropTypes.string,
  };

  static defaultProps = {
    ...TypeDefaults,
    valuePrexisting: '',
  };

  componentDidMount() {
    const { valuePrexisting, changeValue, id } = this.props;

    if (!valuePrexisting) {
      changeValue(id, '2000-01-01');
    }
  }

  render() {
    const { handleChange, id, template, type } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          component={BirthdayPicker}
          label={'Birthday'}
          name={id?.toString()}
          onChange={handleChange}
          upward
        />
      </div>
    );
  }
}

export default BirthdayBlockController(Birthday01);
