import { Header } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { List } from 'immutable';

import EspProductHeader from '../molecules/EspProductHeader';

class DetailedProductDescription extends PureComponent {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    description: PropTypes.string,
    images: ImmutablePropTypes.list,
    name: PropTypes.string,
    price: PropTypes.string,
    rating: PropTypes.number,
  };

  static defaultProps = {
    as: 'div',
    description: 'No description',
    images: new List(),
    name: '',
    price: '',
    rating: null,
  };

  render() {
    const { description, name, price, rating, images, ...props } = this.props;

    let topImage = null;

    if (!images.isEmpty()) {
      topImage =
        images.getIn([0, 'image_custom']) || images.getIn([0, 'image_480']);
    }

    return (
      <this.props.as {...props}>
        <EspProductHeader
          price={price}
          productImage={topImage}
          rating={rating}
          title={name}
        />

        <Header as='h4' content='Description' />

        <div className='content'>{description}</div>
      </this.props.as>
    );
  }
}

export default DetailedProductDescription;
