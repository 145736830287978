import { Button, Form, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, memoize } from 'lodash';

// Organism
import LocationSelect from '../organisms/LocationSelect';
// Global
import TypeStateGroup from '../../globals/TypeStateGroup';

class LocationField extends PureComponent {
  static propTypes = {
    groupName: PropTypes.string,
    /** Remove Department hanlder */
    handleRemoveLocation: PropTypes.func.isRequired,
    /** Select Location handler */
    handleSelectLocation: PropTypes.func.isRequired,
    /** Departments list */
    locations: ImmutablePropTypes.list.isRequired,
    /** External Lcoation Select trigger rendering */
    locationsSelectedRender: PropTypes.func,
    noRootPreselect: PropTypes.bool,
    /** Placeholder when empty */
    placeholder: PropTypes.string,
    /** Make the field read only */
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    locationsSelectedRender: void 0,
    noRootPreselect: false,
    placeholder: 'All',
    readOnly: false,
  };

  handleRemoveOneLocation = memoize(
    curry((departmentID) => {
      const { handleRemoveLocation } = this.props;
      handleRemoveLocation(departmentID, TypeStateGroup.locations);
    })
  );

  handleSelect = (data) => {
    const { handleSelectLocation, groupName = '' } = this.props;
    handleSelectLocation(data, TypeStateGroup.locations, groupName);
  };

  renderAddLocationButton = () => {
    const { locations, locationsSelectedRender, readOnly } = this.props;

    if (locationsSelectedRender) {
      return locationsSelectedRender(locations);
    }

    if (readOnly) {
      return null;
    }

    return (
      <Button
        className='text'
        content='Add Location'
        floated='right'
        type='button'
      />
    );
  };

  render() {
    const {
      locations,
      locationsSelectedRender,
      noRootPreselect,
      readOnly,
      placeholder,
    } = this.props;

    return (
      <Form.Field>
        {!readOnly && (
          <LocationSelect
            clearAfterSelection
            noRootPreselect={noRootPreselect}
            onChange={this.handleSelect}
            renderEmptySelectionTrigger={this.renderAddLocationButton}
          />
        )}

        {!locationsSelectedRender || readOnly ? (
          <>
            <label>{'Locations'}</label>
            <Label.Group className='multiple search selection dropdown'>
              {locations && !locations.isEmpty()
                ? locations.map((location) => (
                    <Label
                      color={readOnly ? null : 'blue'}
                      content={location?.get('name')}
                      key={`${location?.get('id')}_key`}
                      onRemove={
                        readOnly
                          ? null
                          : this.handleRemoveOneLocation(location?.get('id'))
                      }
                    />
                  ))
                : null}

              {!locations || locations?.isEmpty() ? (
                <Label content={placeholder} />
              ) : null}
            </Label.Group>
          </>
        ) : null}
      </Form.Field>
    );
  }
}

export default LocationField;
