import {
  change,
  getFormSyncErrors,
  getFormValues,
  isDirty,
  isValid,
} from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import integrationThunks from '../../actions/integrationThunks';

const OKTA_FORM = 'OktaAccountForm';

const mapStateToProps = (state) => ({
  acsUrl: state.getIn(['integration', 'samlServiceProvider', 'acs_url']),
  formValues: getFormValues(OKTA_FORM)(state),
  isDirty: isDirty(OKTA_FORM)(state),
  isLoadingOktaAccount: state.getIn(['integration', 'isLoadingOktaAccount']),
  isValid: isValid(OKTA_FORM)(state),
  oktaAccount: state.getIn(['integration', 'oktaAccount']),
  syncErrors: getFormSyncErrors(OKTA_FORM)(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearApiKey: () => {
    dispatch(change(OKTA_FORM, 'api_key', ''));
  },

  formValidation: (values) => {
    const errors = {};

    if (!values.get('base_url')) {
      errors.url = 'Cannot be empty';
    }

    if (!values.get('api_key')) {
      errors.url = 'Cannot be empty';
    }

    return errors;
  },

  getOktaAccount: () => {
    // We also need to get the saml provider for the url
    // after this is loaded
    dispatch(integrationThunks.getOktaAccount());
  },

  saveOktaAccount: () => {
    // We also need to get the saml provider for the url
    // after this is loaded
    dispatch(integrationThunks.saveOktaAccount());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
