/**
 * List of possible background color in use
 * when rendering a user image as a SVG
 * @type {[*]}
 */
const UserImageColors = [
  '#132C2D',
  '#265456',
  '#008580',
  '#008568',
  '#2E7264',
  '#234941',
  '#133536',
  '#044900',
  '#078100',
  '#568500',
  '#5A6A3C',
  '#354C0B',
  '#0A163B',
  '#001659',
  '#01299F',
  '#3366FF',
  '#536DB9',
  '#606A8A',
  '#3A4F8D',
  '#B85C00',
  '#D14900',
  '#9E3700',
  '#814305',
  '#E60000',
  '#B60404',
  '#880000',
  '#785360',
  '#95534D',
  '#E60050',
  '#B60442',
  '#812042',
  '#6C3447',
  '#6A0025',
  '#301B47',
  '#4C1B64',
  '#6C00E6',
  '#582096',
  '#8B66B7',
  '#695184',
  '#4B3D5A',
];

export default UserImageColors;
