import { intl } from 'esp-util-intl';

const getPublishMsg = (activeUsers, inactiveUsers, timeLimit) => {
  const isActiveUsers = activeUsers > 0;
  const isInactiveUsers = inactiveUsers > 0;
  const isTimeLimit = timeLimit > 61;
  const minutes = Math.ceil(timeLimit / 60);

  if (isActiveUsers && !isInactiveUsers && !isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_send_x_users',
      values: { number: activeUsers },
    });
  }

  if (isActiveUsers && !isInactiveUsers && isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_send_active_users_x_time',
      values: { number: activeUsers, time: minutes },
    });
  }

  if (isActiveUsers && isInactiveUsers && isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_send_active_inactive_users_x_time',
      values: {
        inactive_number: inactiveUsers,
        number: activeUsers,
        time: minutes,
      },
    });
  }

  if (isActiveUsers && isInactiveUsers && !isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_send_active_inactive_users',
      values: {
        inactive_number: inactiveUsers,
        number: activeUsers,
      },
    });
  }

  return '';
};

const getResolveMsg = (activeUsers, inactiveUsers, timeLimit) => {
  const isActiveUsers = activeUsers > 0;
  const isInactiveUsers = inactiveUsers > 0;
  const isTimeLimit = timeLimit > 61;
  const minutes = Math.ceil(timeLimit / 60);

  if (isActiveUsers && !isInactiveUsers && !isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_resolve_send_x_users',
      values: { number: activeUsers },
    });
  }

  if (isActiveUsers && !isInactiveUsers && isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_resolve_send_active_users_x_time',
      values: { number: activeUsers, time: minutes },
    });
  }

  if (isActiveUsers && isInactiveUsers && isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_resolve_send_active_inactive_users_x_time',
      values: {
        inactive_number: inactiveUsers,
        number: activeUsers,
        time: minutes,
      },
    });
  }

  if (isActiveUsers && isInactiveUsers && !isTimeLimit) {
    return intl.formatMessage({
      id: 'message.announcement_resolve_send_active_inactive_users',
      values: {
        inactive_number: inactiveUsers,
        number: activeUsers,
      },
    });
  }

  return '';
};

export default getPublishMsg;
export { getResolveMsg };
