const reactPlayerConfig = {
  vimeo: {
    playerOptions: {
      controls: true,
      playsinline: false,
      title: true,
    },
  },
  youtube: {
    embedOptions: { controls: 2, fs: 1, playsinline: 0 },
    playerVars: { controls: 2, fs: 1, playsinline: 0 },
  },
};

export default reactPlayerConfig;
