import GlobalContext from './globals/GlobalContext';
import PropTypes from 'prop-types';
import React from 'react';
import { useTenantHook } from './customHooks';
import useUsersSettings from './customHooks/useUsersSettings';

const propTypes = {
  children: PropTypes.element,
  currentUserID: PropTypes.number,
};

const AppGlobalContextProvider = ({ children, currentUserID }) => {
  const {
    getUserSetting,
    isLoadingUserSettings,
    updateUsersSettings,
    usersSettings,
  } = useUsersSettings({ userID: currentUserID });

  const tenant = useTenantHook();

  return (
    <GlobalContext.Provider
      value={{
        currentUserID,
        tenant,
        usersSettings: {
          getUserSetting,
          isLoadingUserSettings,
          updateUsersSettings,
          usersSettings,
        },
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

AppGlobalContextProvider.propTypes = propTypes;

export default AppGlobalContextProvider;
