import buildCompositeController from '../../utils/buildCompositeController';

// Thunk
import userEntitiesThunks from '../../actions/userEntitiesThunks';

const mapStateToProps = (state, ownprops) => {
  const userData = state.getIn(['entities', 'users', ownprops.userId]);
  return {
    isLoadingUser: state.getIn([
      'containersStatus',
      ownprops.userId,
      'loading',
    ]),
    userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id, forceReload) => {
    dispatch(userEntitiesThunks.addUserEntity(id, forceReload));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
