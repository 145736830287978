import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

import browserHistory from '../../utils/browserHistory';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
// Package
import { intl } from 'esp-util-intl';

class BackButtonMain extends Component {
  static propTypes = {
    content: PropTypes.string,
    icon: PropTypes.string,
  };

  static defaultProps = {
    content: 'label.back',
    icon: 'chevron left',
  };

  handleGoBack = () => {
    // current + prev step
    // if the tab is new, the blank will count as one
    if (browserHistory.length > 2) {
      browserHistory.goBack();
    } else {
      browserHistory.push(uiPathGenerator.genPath('app.toDo'));
    }
  };

  render() {
    const { content, icon } = this.props;

    return (
      <Menu.Item
        className='back-button'
        content={intl.formatMessage({
          id: content,
        })}
        icon={icon}
        link
        onClick={this.handleGoBack}
      />
    );
  }
}

export default BackButtonMain;
