const FilterTypes = {
  ASSIGNED_TO: 'assignTo',
  CUSTOM_STATE: 'customState',
  LOCATION: 'location',
  REPORTED_BY: 'reported',
  SORT_BY: 'sort',
  STATE: 'stateIs',
  TIME_PERIOD: 'time',
  TYPE: 'type',
};

export default Object.freeze(FilterTypes);
