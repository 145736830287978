import EspFilters from 'esp-util-filters';
import endpointGenerator from '../../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../../utils/APIcall';

const fetchOktaAuth = async () => {
  const url = endpointGenerator.genPath('authentication.authBackend');

  const espFilters = new EspFilters();
  espFilters.contains('name', 'Okta SAML SSO');

  return _APIcall.get({
    query: {
      esp_filters: espFilters.asQueryString(),
      limit: 1,
    },
    token: true,
    url: url,
  });
};

export default fetchOktaAuth;
