import React from 'react';
import pt from 'prop-types';
import { Transition } from 'semantic-ui-react';
import styles from './OfflineMessage.module.scss';

const propTypes = {
  message: pt.string,
};

const OfflineMessage = ({
  message = 'Your device has lost internet connection.',
}) => (
  <Transition animation='drop' transitionOnMount>
    <div className={styles._} data-component='OfflineMessage'>
      <strong>{'Warning: '}</strong>
      {message}
    </div>
  </Transition>
);
OfflineMessage.propTypes = propTypes;

export default OfflineMessage;
