const api_config_v0_1_configuration_elc_enable_scheduled_sync = {
  DEBUG_decrypted: null,
  created_in_elc: false,
  eid: 'fsfafdsfafdsfasdf23443423',
  is_protected: false,
  key: 'elc.enable_scheduled_sync',
  last_sync_time: null,
  src_active_status: true,
  sys_date_updated: '2022-01-11T16:36:45.433659Z',
  type: 'boolean',
  value: false,
};

export default api_config_v0_1_configuration_elc_enable_scheduled_sync;
