import React from 'react';
import AzureFormikContext from './AzureFormikContext';
import { object, string } from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import pt from 'prop-types';

const validationSchema = object().shape({
  azure_client_id: string().required('Client id is required'),
  azure_client_secret: string().required('Client secret is required'),
  azure_tenant_id: string().required('Client tenant id is required'),
  name: string().required('Name is required'),
});

const propTypes = {
  children: pt.element,
  initialValues: pt.shape({
    azure_client_id: '',
    azure_client_secret: '',
    azure_tenant_id: '',
    is_password_reset_enabled: false,
    name: '',
  }),
  onSubmit: pt.func,
};

const AzureBasicSSOFormikWrapper = ({ initialValues, onSubmit, children }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <FormikForm>
          <AzureFormikContext.Provider value={props}>
            {children}
          </AzureFormikContext.Provider>
        </FormikForm>
      )}
    </Formik>
  );
};

AzureBasicSSOFormikWrapper.propTypes = propTypes;

export default AzureBasicSSOFormikWrapper;
