import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Boundaries } from 'cascara-middleware';
import getBlockComponentByName from '../../utils/getBlockComponentByName';
import { WorkflowConsumer } from '../../utils/WorkflowContext';

const propTypes = {
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onSubmit: PropTypes.func,
    template: PropTypes.string.isRequired,
  }).isRequired,
  errors: ImmutablePropTypes.map,
  errorsApi: ImmutablePropTypes.list,
  forceBlockLoadingState: PropTypes.func,
  onCloseWorkflow: PropTypes.func,
  resetBlockLoadingState: PropTypes.func,
  setAlternateBack: PropTypes.func,
  setAlternateSkip: PropTypes.func,
  setAlternateSubmit: PropTypes.func,
};

const Block = ({
  block,
  errors = Immutable.Map(),
  errorsApi = Immutable.List(),
  forceBlockLoadingState = noop,
  onCloseWorkflow = noop,
  resetBlockLoadingState = noop,
  setAlternateBack = noop,
  setAlternateSkip = noop,
  setAlternateSubmit = noop,
}) => {
  // Temp fix for Eslintin case we receive forntend blob with XXX_1 for the template instead of XXX01
  let blockTemplate = block.template ? block.template.replace('_', 0) : null;

  if (blockTemplate) {
    // make sure getBlockComponentByName receives lower case names only
    blockTemplate = blockTemplate.toLowerCase(); // there is a mismatch with this template name and the React component

    if (blockTemplate === 'card01') {
      blockTemplate = 'espcard01';
    }
  }

  const blockComponent = getBlockComponentByName(blockTemplate);
  const blockProps = {
    ...block,
    // TODO Not sure if block.onSubmit is ok or if it should be this.props.onSubmit
    errors,
    errorsApi,
    forceBlockLoadingState,
    onCloseWorkflow,
    onSubmit: block.onSubmit,
    resetBlockLoadingState,
    setAlternateBack,
    setAlternateSkip,
    setAlternateSubmit,
  };
  return (
    <Boundaries>
      <WorkflowConsumer>
        {(context) => {
          if (blockComponent) {
            blockProps.query = context;
            return React.createElement(blockComponent, blockProps);
          }

          return null;
        }}
      </WorkflowConsumer>
    </Boundaries>
  );
};

Block.propTypes = propTypes;

export default Block;
