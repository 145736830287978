// WARNING: All of the methods in this file are being exported from the package

import getOptionMap from '../utils/getOptionMap';

const FETCH_METHODS = ['GET', 'OPTIONS'];
const MUTATE_METHODS = ['DELETE', 'PATCH', 'POST', 'PUT'];
const VALID_METHODS = [...FETCH_METHODS, ...MUTATE_METHODS];

const HTTP_METHODS = getOptionMap(VALID_METHODS);

export { HTTP_METHODS, FETCH_METHODS, MUTATE_METHODS, VALID_METHODS };
