// https://github.com/Espressive/cascara/blob/main/packages/cascara/src/ui/Chat/ChatAttachment.js
// tiff was removed because the preview is broken for this type of image file
const IMAGE_ATTACHMENT_TYPES = ['gif', 'jpeg', 'jpg', 'png', 'svg'];

const PLATFORMS = {
  cordova: Boolean(Object.prototype.hasOwnProperty.call(window, 'cordova')),
  electron: Boolean(process.versions['electron']),
};

function getCurrentPlatform() {
  // If we do not match any other platform, set `web` as our platform
  let finalPlatform = 'web';

  for (const platform in PLATFORMS) {
    if (PLATFORMS[platform]) {
      // Once we match a platform we return and do not test any other platforms,
      // so the order of the `platforms` object is potentially important.
      finalPlatform = platform;
    }
  }

  return finalPlatform;
}

const CURRENT_PLATFORM = getCurrentPlatform();

const MSG_TYPE = 'message';

export { CURRENT_PLATFORM, IMAGE_ATTACHMENT_TYPES, PLATFORMS, MSG_TYPE };
