import { createSelector } from 'reselect';

const getAdminGroupPermissions = (state) =>
  state.getIn(['tenant', 'entity', 'groupPermissions']);

const getGroupPermissions = (adminGroupPermissions) => {
  if (adminGroupPermissions && adminGroupPermissions.size) {
    return adminGroupPermissions.toJS(); // Full list for admin user
  }
  return {};
};

export default createSelector([getAdminGroupPermissions], getGroupPermissions);
