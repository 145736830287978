import React from 'react';
import Immutable from 'immutable';
import { every, isArray, isEmpty, some } from 'lodash';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Table, TableRow } from 'semantic-ui-react';

class CommonTable extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    ),
    emptyStateContent: PropTypes.node,
    headers: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    style: PropTypes.shape(),
  };

  static defaultProps = {
    children: null,
    data: null,
    emptyStateContent: null,
    headers: [],
    isLoading: false,
    style: {},
  };

  state = {};

  componentDidMount() {
    const { children, data, headers } = this.props;

    if (children) {
      const validateTableRow = (children) => {
        const validateChild = (child) =>
          child.type === TableRow || child.type.displayName === 'TableRow';
        return Immutable.List.isList(children)
          ? children.every(validateChild)
          : isArray(children)
          ? every(children, validateChild)
          : validateChild(children);
      };
      const valid = validateTableRow(children);

      if (!valid) {
        throw new Error(
          'Children can only be of type Table.Row (semantic-ui-react)'
        );
      }
    } else if (!isEmpty(data)) {
      if (some(data, (row) => row.length !== headers.length)) {
        throw new Error('All rows must be of same size of headers');
      }
    }
  }

  renderEmptyState = () => {
    const { headers } = this.props;
    const { emptyStateContent } = this.props;

    return (
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan={headers.length} textAlign='center'>
            {emptyStateContent}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    );
  };

  renderData = () => {
    const { data } = this.props;

    return data.map((row, index) => (
      <Table.Row key={index}>
        {row.map((cell, index) => (
          <Table.Cell key={index}>{cell}</Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  render() {
    const { data, headers, isLoading, style, children } = this.props;

    const useEmptyState = isEmpty(children) && isEmpty(data);

    const useData = isEmpty(children) && !isEmpty(data);

    const tableLoader = (
      <Dimmer active inverted>
        <Loader inverted size='large' />
      </Dimmer>
    );

    return isLoading ? (
      tableLoader
    ) : (
      <Table style={style}>
        <Table.Header>
          <Table.Row>
            {headers.map((header, index) => (
              <Table.HeaderCell key={`header-${index}`}>
                {header}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        {useEmptyState ? (
          this.renderEmptyState()
        ) : (
          <Table.Body>{useData ? this.renderData() : children}</Table.Body>
        )}
      </Table>
    );
  }
}
export default CommonTable;
