import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AvatarEditor from 'react-avatar-editor';
import { Field } from 'redux-form/immutable';
import { Button, Divider } from 'semantic-ui-react';
import _ from 'lodash';

// Controller
import ImageSelectController from '../controllers/ImageSelectController';
// Atom
import UserImage from '../atoms/UserImage';
// Molecules
import FormInputFileRef from './FormInputFileRef';
// Oraganisms
import BlockModal from '../organisms/BlockModal';
// Globals
import TypeImageSelect, {
  TypeDefaults,
} from '../../globals/blocks/TypeImageSelect';
import UserImageTypes from '../../globals/UserImageTypes';
// Utils
import FilePickerUtils from '../../utils/FilePickerUtils';
// Package
import { intl } from 'esp-util-intl';

class ProfileUploadImage extends PureComponent {
  static propTypes = {
    ...TypeImageSelect,
    buttonText: PropTypes.string,
    color: PropTypes.string,
    /** Current user Map */
    currentUser: ImmutablePropTypes.map.isRequired,
    fluid: PropTypes.bool,
    /** form Name for redux-form (can just use the id) */
    formName: PropTypes.string,
    /** String Icon name for the main icon button */
    icon: PropTypes.string,
    /** loadOnpoen allow to load an img and open the blockmodal on img load - Use full for workflow */
    loadOnOpen: PropTypes.bool,
    primary: PropTypes.bool,
    /** Func to allow to reset the current img preloaded */
    resetImgPreview: PropTypes.func.isRequired,
    sendErrorMsg: PropTypes.func,
    size: PropTypes.string,
    /** trigger is the node element pass by the parent when loadOnPen is not set. The node will open the blockmodal on click*/
    trigger: PropTypes.node,
    widths: PropTypes.number,
  };

  static defaultProps = {
    ...TypeDefaults,
    buttonText: null,
    color: 'red',
    deleteUserImage: _.noop,
    fluid: false,
    formName: null,
    icon: null,
    isLoading: false,
    loadOnOpen: false,
    modalButtonLoading: true,
    onAttachmentChange: _.noop,
    onAttachmentPreload: _.noop,
    onSubmitImg: _.noop,
    primary: null,
    sendErrorMsg: _.noop,
    size: null,
    trigger: null,
    widths: 0,
  };

  state = {
    rotate: 0,
  };

  componentDidUpdate() {
    let imgSelected = null;

    const { getImgPreview, hasChanged, resetHasChanged } = this.props;
    const imgPrev = getImgPreview();

    if (imgPrev && imgPrev.picture && imgPrev.picture.name) {
      imgSelected = imgPrev.picture.name;
    }

    if (imgSelected && this.avatarEditorModal && hasChanged()) {
      resetHasChanged();
      this.avatarEditorModal.open();
    }
  }

  editor = null;

  setAvatarEditorModal = (avatarEditorModal) => {
    this.avatarEditorModal = avatarEditorModal;
  };

  setEditorRef = (editor) => {
    if (editor) {
      this.editor = editor;
    }
  };

  setInputRef = (ref, props) => {
    this.inputElement = ref;
    this.inputElementProps = props;
  };

  handleTriggerInput = (e) => {
    e.preventDefault();
    FilePickerUtils.promptPicturePicker({
      inputRef: this.inputElement,
      message: intl.formatMessage({
        id: 'label.select_image_source',
      }),
      promptCallback: this.onAttachmentChangeCordova,
      title: intl.formatMessage({
        id: 'label.change_profile_image',
      }),
    });
  };

  handleTriggerInputOnOpen = () => {
    const { loadOnOpen } = this.props;

    if (loadOnOpen) {
      FilePickerUtils.promptPicturePicker({
        inputRef: this.inputElement,
        message: intl.formatMessage({
          id: 'label.select_image_source',
        }),
        promptCallback: this.onAttachmentChangeCordova,
        title: intl.formatMessage({
          id: 'label.change_profile_image',
        }),
      });
    }
  };

  handleAvatarEditorModalClose = () => {
    const { resetImgPreview } = this.props;

    resetImgPreview(); // Reset Img preview
  };

  onAttachmentChangeCordova = (imageData) => {
    const { currentUser, onAttachmentChange } = this.props;

    window.resolveLocalFileSystemURI(
      imageData,
      (fileEntry) => {
        fileEntry.file((file) => {
          if (file) {
            file.type = 'image/jpeg';
            onAttachmentChange(
              this.inputElementProps,
              file,
              currentUser,
              this.setRotation
            );
          }
        });
      },
      () => {
        // error
      }
    );
  };

  onAttachmentChanged = (e) => {
    e.preventDefault();

    const { currentUser, onAttachmentChange, sendErrorMsg } = this.props;

    const [file] = e.target.files;

    if (file) {
      if (file.type.match('video/')) {
        sendErrorMsg({
          header: 'Error',
          message: intl.formatMessage({
            id: 'message.videos_are_not_supported',
          }),
        });
      } else {
        onAttachmentChange(
          this.inputElementProps,
          file,
          currentUser,
          this.setRotation
        );
      }
    }
  };

  setRotation = (rotation) => {
    this.setState({
      rotate: rotation,
    });
  };

  handleDeleteProfileImage = () => {
    const { resetImgPreview, deleteUserImage } = this.props;

    deleteUserImage(UserImageTypes.PROFILE, () => {
      resetImgPreview(); // Reset Img preview
      this.avatarEditorModal.close();
    });
  };

  handleRotateProfileImage = (e) => {
    e.preventDefault();
    const { rotate } = this.state;

    let nextRotate = rotate + 90;
    if (nextRotate > 270) {
      nextRotate = 0;
    }
    this.setState({
      rotate: nextRotate,
    });
  };

  handleResetRotation = () => {
    const { rotate } = this.state;

    if (rotate) {
      this.setState({
        rotate: 0,
      });
    }
  };

  handleOnClick(e) {
    e.target.value = null;
  }

  handleSubmit = (e) => {
    const {
      currentUser,
      onSubmitImg,
      getImgPreview,
      image,
      handleSubmit,
    } = this.props;
    const { rotate } = this.state;

    const img = getImgPreview();

    if (!img.picture && !rotate) {
      this.avatarEditorModal.close();
      return;
    }

    const data = this.editor.getImageScaledToCanvas();

    const savedType = rotate
      ? {
          type: UserImageTypes.PROFILE,
        }
      : null;

    const type = image.onSubmit;

    const fn = () => {
      this.avatarEditorModal.close();
      if (type === 'setNext') {
        handleSubmit(e);
      }
    };

    onSubmitImg({
      currentUser,
      data,
      e,
      fn,
      savedType,
    });
  };

  buildButton(userRealAvatar, imgPrevLoaded, images) {
    const { loadOnOpen } = this.props;

    const buttonGrp = [];

    const hasProfilePic =
      images && images.some((i) => i.get('type') === UserImageTypes.PROFILE);

    if (hasProfilePic && !loadOnOpen) {
      buttonGrp.push(
        <Button
          content={intl.formatMessage({
            id: 'label.delete',
          })}
          key='1'
          onClick={this.handleDeleteProfileImage}
        />
      );
    }

    if (imgPrevLoaded || (!loadOnOpen && userRealAvatar)) {
      buttonGrp.push(
        <Button
          content={intl.formatMessage({
            id: 'label.rotate',
          })}
          key='2'
          onClick={this.handleRotateProfileImage}
        />
      );
    }

    if (!loadOnOpen) {
      buttonGrp.push(
        <Button
          content={intl.formatMessage({
            id: 'label.change',
          })}
          key='3'
          onClick={this.handleTriggerInput}
        />
      );
    }

    return buttonGrp;
  }

  render() {
    const {
      buttonText,
      color,
      currentUser,
      fluid,
      getImgPreview,
      icon,
      image,
      isLoading,
      loadOnOpen,
      modalButtonLoading,
      modalButtonText,
      modalText,
      mountNodeSelector,
      primary,
      size,
      trigger,
      widths,
      id,
      formName,
    } = this.props;
    const img = getImgPreview();
    const { rotate } = this.state;

    const userImg = currentUser.get('images');
    let userRealAvatar = false;
    let realImage;

    if (userImg) {
      userRealAvatar = userImg.some(
        (img) => img.get('type') === UserImageTypes.PROFILE
      );
    }

    if (userRealAvatar) {
      // Get the url of the real image avatar
      realImage = userImg
        .find((img) => img.get('type') === UserImageTypes.PROFILE)
        .get('picture');
    }
    // Note temporary realImage rotate hide
    realImage = null;
    userRealAvatar = false;

    const iconButton = icon || null;

    const buttonIsLoading = isLoading && modalButtonLoading;

    const actionButton = (
      <Button
        content={
          !loadOnOpen && !img.picture && !rotate
            ? intl
                .formatMessage({
                  id: 'label.close',
                })
                .toUpperCase()
            : modalButtonText
        }
        loading={buttonIsLoading}
        onClick={this.handleSubmit}
        primary
        size='large'
      />
    );

    const isPrimary = typeof primary === 'boolean' ? primary : true;

    const fieldRender = (
      <Field
        captureType='user'
        component={FormInputFileRef}
        handleChange={this.onAttachmentChanged}
        id={id.toString()}
        name={formName || id.toString()}
        onClick={this.handleOnClick}
        setRef={this.setInputRef}
      />
    );

    const imgPrevLoaded = Boolean(img.imgPrevUrl);

    // Display default avatar only if no real image exists
    const usrDefaultImg =
      !userRealAvatar && !loadOnOpen && !imgPrevLoaded ? (
        <UserImage className='fluid circular' user={currentUser} />
      ) : null;

    // Display the real avatar in the canvas editor if the image exists
    const avatarEdit =
      imgPrevLoaded || userRealAvatar ? (
        <AvatarEditor
          border={0}
          borderRadius={200}
          color={[255, 255, 255, 0.7]}
          crossOrigin='anonymous'
          height={image.mHeight}
          image={img.imgPrevUrl || realImage}
          ref={this.setEditorRef}
          rotate={rotate}
          scale={1}
          width={image.mWidth}
        />
      ) : null;

    const clearingHidden = !loadOnOpen ? <Divider clearing hidden /> : null;

    return (
      <div className='profile-upload'>
        {!trigger && (
          <div>
            <Button
              as='label'
              basic
              color={color}
              content={buttonText}
              fluid={fluid}
              icon={iconButton}
              onClick={this.handleTriggerInputOnOpen}
              primary={isPrimary}
              size={size}
              widths={widths}
            />
            {fieldRender}
          </div>
        )}

        <BlockModal
          closeText={intl.formatMessage({
            id: 'label.close',
          })}
          modalActions={actionButton}
          modalTitle={modalText}
          mountNodeSelector={mountNodeSelector}
          onClose={this.handleAvatarEditorModalClose}
          onOpen={this.handleResetRotation}
          ref={this.setAvatarEditorModal}
          trigger={trigger}
        >
          <div className='image-select'>
            {!loadOnOpen && fieldRender}

            {usrDefaultImg}

            {avatarEdit}

            <Divider hidden />

            <Button.Group basic fluid widths='3'>
              {this.buildButton(userRealAvatar, imgPrevLoaded, userImg)}
            </Button.Group>

            {clearingHidden}
          </div>
        </BlockModal>
      </div>
    );
  }
}

export const ProfileUploadImageTest = ProfileUploadImage;

export default ImageSelectController(ProfileUploadImage);
