// Utils
import buildCompositeController from '../../../../v1/utils/buildCompositeController';

// Actions
import widgetThunks from '../../../../v1/actions/widgetThunks';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onUserSelection: (channelID, messageID, widgetID, selectedOption) => {
    const data = {
      // we gotta send this pair
      [selectedOption.action]: selectedOption.value,
    };
    dispatch(
      widgetThunks.postBaristaWidgetResponse(
        channelID,
        messageID,
        widgetID,
        data
      )
    );
  },
});
export default buildCompositeController(mapStateToProps, mapDispatchToProps);
