import React from 'react';
import pt from 'prop-types';
import { Form, Header, Segment } from 'semantic-ui-react';

const propTypes = {
  // Should explicitly be one or more dom nodes of class .field
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]).isRequired,
  // An optional description for the section
  description: pt.string,
  // A title for the form section
  title: pt.string.isRequired,
};

// BrandingContentSection is used to simplify the repeated containing
// sections of the branding form. Any content from required
// props does not contain a check before rendering.
const BrandingContentSection = ({ children, description, title }) => {
  return (
    <>
      <Header as='h3' content={title} />

      {/* This is optional so we want to make sure not to render the markup tag if no description is defined. */}
      {description && <p>{description}</p>}

      {/* This is strictly for styling. All .field classses need to be direct children of a .ui.form class. This does not render a <form> tag. */}
      <Form as={Segment} basic>
        {children}
      </Form>
    </>
  );
};

BrandingContentSection.propTypes = propTypes;

export default BrandingContentSection;
