let apiRootUrl = '';
let webSocketUrl = '';

// if address has a port we assume it's local
// Prod API is assumed to use HTTPS and use no port
const hostParts = window.location.host.split(':');

const [domain] = hostParts;
const domainParts = domain.split('.');
const firstItemIndex = 0;
const tenant = domainParts[firstItemIndex].split('.')[firstItemIndex];
const isLocal = domainParts.length !== 3;

if (isLocal) {
  apiRootUrl = `http://${domain}:8000`;
  webSocketUrl = `ws://${domain}:8080/ws`;
} else {
  apiRootUrl = `https://${tenant}.espresive.com`;
  webSocketUrl = `wss://${tenant}.espresive.com/ws`;
}

export const Routes = {
  catalog: {
    pattern: '/app/catalog',
  },
  chat: {
    pattern: '/chat(/:channelID)',
  },
  directory: {
    pattern: '/app/directory',
  },
  editor: {
    pattern: '/editor(/:workflowID)(/:workflowTaskID)',
  },
  feed: {
    pattern: '/feed',
  },
  login: {
    pattern: '/login',
  },
  notenant: {
    pattern: '/notenant',
  },
  onboard: {
    pattern: '/onboard(/:workflowID)(/:workflowTaskID)',
  },
  workflow: {
    pattern: '/flow(/:workflowID)(/:workflowTaskID)',
  },
};

export const APIRoutes = {
  endpoints: {
    bundles: '/api/catalog/v0.1/bundle/',
    cards: '/api/cards/v0.1/card/',
    cart: '/api/catalog/v0.1/cart/',
    cartItem: '/api/catalog/v0.1/citem/',
    categories: '/api/catalog/v0.1/category/',
    channels: '/api/chat/v0.1/channels/',
    chat: '/api/chat/v0.1/',
    condition: '/api/workflow/v0.1/condition/',
    directChannelBarista: '/api/barista/v0.1/conversation/get_support_channel/',
    directChannelWithUser: '/api/chat/v0.1/direct_channels/with_users/',
    directChannels: '/api/chat/v0.1/direct_channels/',
    heartbeat: '/api/espuser/v0.1/presence/',
    login: '/api/espuser/auth/login/',
    logout: '/api/espuser/auth/logout/',
    messages: '/api/chat/v0.1/messages/',
    objectMapping: '/api/workflow/v0.1/object_mapping/',
    onboard: '/api/workflow/v0.1/onboard/',
    products: '/api/catalog/v0.1/product/',
    profileImage: '/api/images/v0.1/profile_image/',
    tenant: '/api/tenants/v0.1/tenant/',
    tenantImage: '/api/images/v0.1/tenant_images/',
    totalUnread: '/api/chat/v0.1/channels/total_unread/',
    users: '/api/espuser/v0.1/users/',
    whoami: '/api/espuser/v0.1/whoami/',
    workflow: '/api/workflow/v0.1/workflow/',
    workflowconditionset: '/api/workflow/v0.1/workflowconditionset/',
    workflowrequest: '/api/workflow/v0.1/workflowrequest/',
    workflowtask: '/api/workflow/v0.1/workflowtask/',
  },
  root: apiRootUrl,
};

export const WebSocketsPattern = webSocketUrl;
