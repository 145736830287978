import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Dimmer, Form, Icon, Input, Loader, Message } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import { curry, memoize } from 'lodash';

// Utils
import injectIntlToController from '../../../../utils/injectIntlToController';
// Controller
import IntlKeyFormController from './IntlKeyFormController';

class IntlKeyForm extends PureComponent {
  static propTypes = {
    changeTranslation: PropTypes.func.isRequired,

    isLoadingIntlKeys: PropTypes.bool,

    isSavingTranslations: PropTypes.bool,

    loadLocalizationsForKey: PropTypes.func.isRequired,

    loadSupportedLanguages: PropTypes.func.isRequired,
    proposedTranslationKey: PropTypes.string,
    supportedLanguages: ImmutableProps.list,
    translationKey: PropTypes.string,
    translationKeyResults: ImmutableProps.list,
  };

  static defaultProps = {
    isLoadingIntlKeys: false,
    isSavingTranslations: false,
    proposedTranslationKey: '',
    supportedLanguages: Immutable.List(),
    translationKey: null,
    translationKeyResults: Immutable.List(),
  };

  state = {
    hasBeenTranslated: false,
  };

  static getDerivedStateFromProps = (props) => {
    const { translationKey, isLoadingIntlKeys, translationKeyResults } = props;

    const hasBeenTranslated = Boolean(
      translationKey && !isLoadingIntlKeys && translationKeyResults.size
    );

    return {
      hasBeenTranslated: hasBeenTranslated,
    };
  };

  componentDidMount() {
    const { supportedLanguages, loadSupportedLanguages } = this.props;
    if (!supportedLanguages.size) {
      loadSupportedLanguages();
    }
  }

  componentDidUpdate(prevProps) {
    const { translationKey, loadLocalizationsForKey } = this.props;

    if (prevProps.translationKey !== translationKey && translationKey) {
      loadLocalizationsForKey(translationKey);
    }
  }

  handleTranslationChange = memoize(
    curry((lang, e) => {
      const newTranslationValue = e.target.value;

      const { changeTranslation } = this.props;
      changeTranslation(lang, newTranslationValue);
    })
  );

  render() {
    const {
      isLoadingIntlKeys,
      isSavingTranslations,
      proposedTranslationKey,
      supportedLanguages,
      translationKey,
      translationKeyResults,
    } = this.props;

    const { hasBeenTranslated } = this.state;

    // Loading state
    if (isLoadingIntlKeys) {
      return (
        <Dimmer active inverted>
          <Loader size='large' />
        </Dimmer>
      );
    } else {
      return (
        <>
          {!hasBeenTranslated ? (
            <>
              <Message icon>
                <Icon name='keyboard' />
                <Message.Content>
                  <Message.Header>
                    {`Text "${translationKey}" hasn't been translated yet`}
                  </Message.Header>
                  {`English translation is required first. If you save we'll replace "${translationKey}" with a new key <${proposedTranslationKey}> in the workflow task and every other supported language can be translated afterwards.`}
                </Message.Content>
              </Message>
              <Form>
                <Form.Field>
                  <Input
                    defaultValue={translationKey}
                    label='en'
                    onChange={this.handleTranslationChange('en')}
                    placeholder='English translation'
                  />
                </Form.Field>
              </Form>
            </>
          ) : (
            <>
              <Message>
                <Message.Header>{translationKey}</Message.Header>
              </Message>
              <Form>
                {supportedLanguages.map((lang) => {
                  const langCode = lang.get('beCode');

                  const localizationInstance = translationKeyResults.find(
                    (lan) => lan.get('language') === langCode
                  );

                  return (
                    <Form.Field key={langCode}>
                      <Input
                        defaultValue={
                          localizationInstance
                            ? localizationInstance.get('text')
                            : ''
                        }
                        disabled={isSavingTranslations}
                        label={langCode}
                        onChange={this.handleTranslationChange(langCode)}
                        placeholder=''
                      />
                    </Form.Field>
                  );
                })}
              </Form>
            </>
          )}
        </>
      );
    }
  }
}

const IntlKeyFormTest = IntlKeyForm;

// eslint-disable-next-line no-class-assign -- DEV-1526
IntlKeyForm = IntlKeyFormController(IntlKeyForm);
export { IntlKeyFormTest };
export default injectIntlToController(IntlKeyForm);
