import React from 'react';
import pt from 'prop-types';
import FunctionalFormSelectUserInput from './FunctionalFormSelectUserInput';
import { Form } from 'semantic-ui-react';

const propTypes = {
  disabled: pt.bool,
  onBlur: pt.func,
  onChange: pt.func,
  values: pt.shape({
    enablement: pt.oneOfType([pt.number, pt.string]),
    hr_contact: pt.oneOfType([pt.number, pt.string]),
    hr_recruiter: pt.oneOfType([pt.number, pt.string]),
    it_contact: pt.oneOfType([pt.number, pt.string]),
    mentor: pt.oneOfType([pt.number, pt.string]),
    operations: pt.oneOfType([pt.number, pt.string]),
  }),
};

const ScheduleActivityAdministrativeRecipientsForm = ({
  disabled,
  values,
  onBlur,
  onChange,
}) => {
  return (
    <>
      <Form.Field disabled={disabled}>
        <FunctionalFormSelectUserInput
          label='Mentor'
          multiple
          name='administrativeRecipients.mentor'
          onBlur={onBlur}
          onChange={onChange}
          value={values.mentor}
        />
      </Form.Field>

      <Form.Field disabled={disabled}>
        <FunctionalFormSelectUserInput
          label='HR Recruiter'
          multiple
          name='administrativeRecipients.hr_recruiter'
          onBlur={onBlur}
          onChange={onChange}
          value={values.hr_recruiter}
        />
      </Form.Field>
      <Form.Field disabled={disabled}>
        <FunctionalFormSelectUserInput
          label='Enablement'
          multiple
          name='administrativeRecipients.enablement'
          onBlur={onBlur}
          onChange={onChange}
          value={values.enablement}
        />
      </Form.Field>
      <Form.Field disabled={disabled}>
        <FunctionalFormSelectUserInput
          label='HR Contact'
          multiple
          name='administrativeRecipients.hr_contact'
          onBlur={onBlur}
          onChange={onChange}
          value={values.hr_contact}
        />
      </Form.Field>
      <Form.Field disabled={disabled}>
        <FunctionalFormSelectUserInput
          label='IT Contact'
          multiple
          name='administrativeRecipients.it_contact'
          onBlur={onBlur}
          onChange={onChange}
          value={values.it_contact}
        />
      </Form.Field>
      <Form.Field disabled={disabled}>
        <FunctionalFormSelectUserInput
          label='Operations'
          multiple
          name='administrativeRecipients.operations'
          onBlur={onBlur}
          onChange={onChange}
          value={values.operations}
        />
      </Form.Field>
    </>
  );
};

ScheduleActivityAdministrativeRecipientsForm.propTypes = propTypes;
export default ScheduleActivityAdministrativeRecipientsForm;
