import searchActions from '../../actions/searchActions';
import buildCompositeController from '../../utils/buildCompositeController';
import getUserSelectList from '../../selectors/getUserSelectList';

const mapStateToProps = (state, ownProps) => {
  const userData = getUserSelectList(state, ownProps.userList);

  return {
    usersList: userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchResetResults() {
    dispatch(searchActions.setUserSearchInput(''));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
