import buildCompositeController from '../../utils/buildCompositeController';
import integrationThunks from '../../actions/integrationThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state) => ({
  botPackageUrl: state.getIn([
    'integration',
    'msftTeams',
    'tokenData',
    'package_url',
  ]),
  botStatus: state.getIn(['integration', 'msftTeams', 'tokenData', 'status']),
  botToken: state.getIn(['integration', 'msftTeams', 'tokenData', 'token']),
  isIntegrationLoading: state.getIn(['integration', 'msftTeams', 'isLoading']),
});

const mapDispatchToProps = (dispatch) => ({
  displayNotification(msg) {
    dispatch(
      toastNotificationsActions.success({
        message: msg.message,
        title: msg.header,
      })
    );
  },

  getIntegrationToken() {
    dispatch(integrationThunks.getTeamsToken());
  },

  postIntegrationToken() {
    dispatch(integrationThunks.postTeamsToken());
  },

  removeIntegrationToken() {
    dispatch(integrationThunks.removeTeamsToken()).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: 'The Microsoft Teams integration has been removed',
          title: 'Success',
        })
      );
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
