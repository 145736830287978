import { fromJS, List } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  baristaInboxes: List(),
  baristaTestConnectionSent: false,
  baristaTestConnectionSuccess: false,
  emailAccountTestConnection: '',
  isAddingBaristaInbox: false,
  isDeletingBaristaInbox: false,
  isEditSettingsEmailForm: false,
  isEmailFormErrors: false,
  isFormInboxError: false,
  isLoadingBaristaInboxes: false,
  isLoadingBaristaTestConnection: false,
  isNewFormInbox: false,
  isOnlyTestingConnection: false,
  isUpdatingBaristaInbox: false,
  isUpdatingBaristaInboxCredential: false,
  showEmailFormErrors: [],
  temporalEmailInboxForm: {},
  testedCaseBox: {},
});

const inboxesReducer = (state = null, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.BARISTA_INBOX_ADD_FAIL:
      return state.set('isAddingBaristaInbox', false);

    case actionTypes.BARISTA_INBOX_ADD_START:
      return state.set('isAddingBaristaInbox', true);

    case actionTypes.BARISTA_INBOX_ADD_SUCCESS: {
      const prevInboxes = state.get('baristaInboxes');
      return state
        .set('baristaInboxes', prevInboxes.push(fromJS(action.caseInbox)))
        .set('isAddingBaristaInbox', false);
    }

    case actionTypes.BARISTA_INBOX_DELETE_FAIL:
      return state.set('isDeletingBaristaInbox', false);

    case actionTypes.BARISTA_INBOX_DELETE_START:
      return state.set('isDeletingBaristaInbox', true);

    case actionTypes.BARISTA_INBOX_DELETE_SUCCESS: {
      const prevList = state.get('baristaInboxes');
      const listWithRemovedBox = prevList.delete(
        prevList.findIndex((inbox) => inbox.get('id') === action.id)
      );
      return state.set('baristaInboxes', listWithRemovedBox);
    }

    case actionTypes.BARISTA_INBOX_UPDATE_FAIL:
      return state.set('isUpdatingBaristaInbox', false);

    case actionTypes.BARISTA_INBOX_UPDATE_START:
      return state.set('isUpdatingBaristaInbox', true);

    case actionTypes.BARISTA_INBOX_UPDATE_SUCCESS: {
      const caseInboxes = state.get('baristaInboxes');
      const newBoxes = caseInboxes.map((oldbox) =>
        oldbox.get('id') === action.caseInbox.id
          ? fromJS(action.caseInbox)
          : oldbox
      );
      return state
        .set('baristaInboxes', newBoxes)
        .set('isUpdatingBaristaInbox', false);
    }
    case actionTypes.BARISTA_INBOX_CREDENTIAL_UPDATE_FAIL:
      return state.set('isUpdatingBaristaInboxCredential', false);

    case actionTypes.BARISTA_INBOX_CREDENTIAL_UPDATE_START:
      return state.set('isUpdatingBaristaInboxCredential', true);

    case actionTypes.BARISTA_INBOX_CREDENTIAL_UPDATE_SUCCESS: {
      const caseInboxes = state.get('baristaInboxes');
      const newBoxes = caseInboxes.map((oldbox) => {
        if (oldbox.get('email_account') === action.credential.id) {
          return oldbox.set('credential', fromJS(action.credential));
        }
        return oldbox;
      });
      return state
        .set('baristaInboxes', newBoxes)
        .set('isUpdatingBaristaInboxCredential', false);
    }
    case actionTypes.BARISTA_INBOXES_LOAD_START:
      return state.set('isLoadingBaristaInboxes', true);

    case actionTypes.BARISTA_INBOXES_LOAD_FAIL:
      return state.set('isLoadingBaristaInboxes', false);

    case actionTypes.BARISTA_INBOXES_LOAD_SUCCESS:
      return state
        .set('baristaInboxes', fromJS(action.baristaBoxes))
        .set('isLoadingBaristaInboxes', false);

    case actionTypes.BARISTA_INBOX_FORM_ERRORS: {
      return state
        .set('showEmailFormErrors', fromJS(action.showErrors))
        .set('temporalEmailInboxForm', action.emailData)
        .set('isEmailFormErrors', true);
    }

    case actionTypes.BARISTA_INBOX_CANCEL_EMAIL_INBOX_ERRORS: {
      return state
        .set('showEmailFormErrors', List())
        .set('temporalEmailInboxForm', {})
        .set('isEmailFormErrors', false);
    }

    case actionTypes.BARISTA_INBOX_CONFIRM_EMAIL_INBOX_ERRORS: {
      return state.set('isEmailFormErrors', false);
    }

    case actionTypes.BARISTA_INBOX_CLOSE_CONFIRM_EMAIL_INBOX_ERRORS: {
      return state
        .set('showEmailFormErrors', List())
        .set('temporalEmailInboxForm', {})
        .set('isEmailFormErrors', false)
        .set('isEditSettingsEmailForm', false);
    }

    case actionTypes.BARISTA_EMAIL_TEST_CONNECTION_ERROR:
      return state
        .set('isLoadingBaristaTestConnection', false)
        .set('baristaTestConnectionSuccess', false)
        .set('baristaTestConnectionSent', true)
        .set('isNewFormInbox', action.isNewFormInbox)
        .set('isOnlyTestingConnection', action.isOnlyTestingConnection);

    case actionTypes.BARISTA_EMAIL_TEST_CONNECTION_START:
      return state
        .set('isLoadingBaristaTestConnection', true)
        .set('baristaTestConnectionSuccess', false)
        .set('baristaTestConnectionSent', false)
        .set('isNewFormInbox', false)
        .set('testedCaseBox', action.caseBox)
        .set('isOnlyTestingConnection', false);

    case actionTypes.BARISTA_EMAIL_TEST_CONNECTION_SUCCESS:
      return state
        .set('isLoadingBaristaTestConnection', false)
        .set('baristaTestConnectionSuccess', true)
        .set('baristaTestConnectionSent', true)
        .set('testedCaseBox', action.caseBox)
        .set('isNewFormInbox', action.isNewFormInbox)
        .set('isOnlyTestingConnection', action.isOnlyTestingConnection);

    case actionTypes.BARISTA_EMAIL_TEST_CONNECTION_CLOSE:
      return state
        .set('isLoadingBaristaTestConnection', false)
        .set('baristaTestConnectionSuccess', false)
        .set('baristaTestConnectionSent', false);

    default:
      return state;
  }
};

export default inboxesReducer;
