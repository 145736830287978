import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { curry, debounce, memoize, noop } from 'lodash';
import { Form, Input } from 'semantic-ui-react';

// Molecules
import ValidatedField from '../../molecules/ValidatedField';
import FormSelectUserInput from '../../molecules/FormSelectUserInput';
// Controller
import SelectUserContactGroupController from '../../controllers/SelectUserContactGroupController';

import TypeSelectUserContactGroup, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectUserContactGroup';

const contactsList = {
  enablement: 'enablement',
  hr_contact: 'hr_contact',
  hr_recruiter: 'hr_recruiter',
  it_contact: 'it_contact',
  operations: 'operations',
};

const TIMEOUT = 300;

let SelectUserContactGroup01 = class SelectUserContactGroup01 extends Component {
  static propTypes = {
    ...TypeSelectUserContactGroup,
    /** Change value of a field */
    changeValue: PropTypes.func.isRequired,
    /** Search Context */
    contextName: PropTypes.string,
    /** Remove contact field */
    deleteOneContact: PropTypes.func.isRequired,
    /** Value of the different Contacts */
    formValues: ImmutablePropTypes.map,
    /** Map of FrontendScratch data for Contact */
    frontEndScratchContact: ImmutablePropTypes.map,
    /** Load list of preselected user in the frontend scratch */
    loadFrontEndScratchUsers: PropTypes.func.isRequired,
    /** Reset search result and input */
    searchResetResults: PropTypes.func.isRequired,
    /** Launch the user search */
    searchUsers: PropTypes.func.isRequired,
    /** Set Contact to front end scratch */
    setContactGroup: PropTypes.func.isRequired,
    /** Set search users input */
    setSearchInputUsers: PropTypes.func.isRequired,
    /** User list search */
    usersSearch: ImmutablePropTypes.list,
    /** User search term input */
    usersSearchTerm: PropTypes.string,
    /** List of pre selected users */
    usersSelected: ImmutablePropTypes.list,
  };

  static defaultProps = {
    ...TypeDefaults,
    contextName: '',
    formValues: Immutable.Map(),
    frontEndScratchContact: Immutable.Map(),
    usersSearch: Immutable.List(),
    usersSearchTerm: '',
    usersSelected: Immutable.List(),
  };

  state = {
    categoryInSearch: '',
  };

  componentDidMount() {
    const {
      frontEndScratchContact,
      readOnly,
      setAlternateSubmit,
      loadFrontEndScratchUsers,
      changeValue,
      id,
    } = this.props;

    if (frontEndScratchContact && !frontEndScratchContact.isEmpty()) {
      // Load users detail
      loadFrontEndScratchUsers(frontEndScratchContact);
      // Set first form value to the hidden input
      changeValue(id, frontEndScratchContact.first());
    } else if (!readOnly) {
      changeValue(id, '');
    }

    if (!readOnly) {
      setAlternateSubmit(this.handleClick);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { usersSearchTerm, formValues, usersSearch } = this.props;

    if (nextProps.usersSearchTerm !== usersSearchTerm) {
      return true;
    }

    if (nextProps.formValues !== formValues) {
      return true;
    }

    if (nextProps.usersSearch !== usersSearch) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { usersSearchTerm, searchUsers } = this.props;

    if (
      nextProps.usersSearchTerm &&
      nextProps.usersSearchTerm !== usersSearchTerm
    ) {
      const { categoryInSearch } = this.state;

      searchUsers(categoryInSearch);
    }
  }

  handleClick = (cb = noop) => {
    const { formValues, setContactGroup } = this.props;

    setContactGroup(formValues, cb);
  };

  handleSearchChange = memoize(
    curry((filter, e, value) => {
      const { contextName, setSearchInputUsers } = this.props;

      this.setState({
        categoryInSearch: filter,
      });

      setSearchInputUsers(value.searchQuery, contextName);
    })
  );

  preselectUser = (type) => {
    const { formValues, usersSelected } = this.props;

    let preSelectedUser;

    const valueOfType = formValues.get(type);

    if (formValues && !formValues.isEmpty() && valueOfType) {
      const index = usersSelected.findIndex(
        (usr) => usr.get('id') === valueOfType
      );
      if (index > -1) {
        preSelectedUser = usersSelected.get(index);
      }
    }

    return preSelectedUser;
  };

  resetField = memoize(
    curry((name, e) => {
      e.preventDefault();

      const preselectedUser = this.preselectUser(name);
      const isPreselected = preselectedUser && !preselectedUser.isEmpty();

      const { deleteOneContact, frontEndScratchContact } = this.props;

      if (isPreselected) {
        deleteOneContact(name, frontEndScratchContact, () => {
          this.resetHiddenInput(name);
        });
      } else {
        this.resetHiddenInput(name);
      }
    })
  );

  resetHiddenInput = (name) => {
    const { changeValue, formValues, id } = this.props;

    // Reset the hidden input only if no value has been set in another valid field
    let activeInput = 0;
    if (formValues) {
      formValues.forEach((value, key) => {
        if (value && key !== name && contactsList[key]) {
          ++activeInput;
        }
      });
    }

    if (!formValues || formValues.isEmpty() || activeInput === 0) {
      changeValue(id, '');
    }
    changeValue(name, '');
  };

  formValidate = (value) => {
    const { readOnly } = this.props;

    if (value || readOnly) {
      return null;
    }
    return 'error';
  };

  handleFieldValidate = (value) => {
    const { changeValue, id } = this.props;

    if (value) {
      changeValue(id, value);
    }
  };

  fieldRender = (label, name) => {
    const {
      usersSearch,
      isLoadingSearch,
      searchResetResults,
      readOnly,
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment -- not sure how to destructure this
    if (!name || !this.props[name]) {
      return null;
    }

    const preselectedUser = this.preselectUser(name);

    if (readOnly) {
      let value = 'Not set yet';
      if (preselectedUser && !preselectedUser.isEmpty()) {
        value = `${preselectedUser.get('nickname')} ${preselectedUser.get(
          'last_name'
        )}`;
      }
      return (
        <Form.Field>
          <label>{label}</label>
          <Input readOnly transparent value={value} />
        </Form.Field>
      );
    } else {
      return (
        <ValidatedField
          component={FormSelectUserInput}
          label={label}
          loading={isLoadingSearch}
          name={name}
          onChange={this.handleFieldValidate}
          onClick={searchResetResults}
          onResetAction={this.resetField(name)}
          onSearchChange={debounce(
            this.handleSearchChange(name.toUpperCase()),
            TIMEOUT
          )}
          placeholder='Search by name'
          search
          selectOnBlur={false}
          selectedUser={preselectedUser}
          userList={usersSearch}
        />
      );
    }
  };

  render() {
    const {
      id,
      label_enablement,
      label_hr_contact,
      label_hr_recruiter,
      label_it_contact,
      label_operations,
      template,
      type,
    } = this.props;

    const labelHRRecruiter = label_hr_recruiter
      ? label_hr_recruiter
      : 'HR Recruiter';
    const labelHRContact = label_hr_contact ? label_hr_contact : 'HR Contact';
    const labelITContact = label_it_contact ? label_it_contact : 'IT Contact';
    const labelEnablement = label_enablement ? label_enablement : 'Enablement';
    const labelOperations = label_operations ? label_operations : 'Operations';

    const hrRecruiterField = this.fieldRender(
      labelHRRecruiter,
      contactsList.hr_recruiter
    );
    const hrContactField = this.fieldRender(
      labelHRContact,
      contactsList.hr_contact
    );
    const itContactField = this.fieldRender(
      labelITContact,
      contactsList.it_contact
    );
    const enablementField = this.fieldRender(
      labelEnablement,
      contactsList.enablement
    );
    const operationField = this.fieldRender(
      labelOperations,
      contactsList.operations
    );

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField hidden name={id} validate={this.formValidate} />

        {hrRecruiterField}

        {hrContactField}

        {itContactField}

        {enablementField}

        {operationField}
      </div>
    );
  }
};
const SelectUserContactGroup01Test = SelectUserContactGroup01;

SelectUserContactGroup01 = SelectUserContactGroupController(
  SelectUserContactGroup01
);

export { SelectUserContactGroup01Test };
export default SelectUserContactGroup01;
