const api_chat_v0_1_channels_0f63206f_messages = {
  count: 6,
  next: null,
  previous: null,
  results: [
    {
      channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
      id: 'b468e255-cabf-4a05-963e-c9a66e1d82e2',
      metadata: {
        user_input: {
          confirm: [
            {
              actual_text: 'Yes',
              bring_always: false,
              default_value: null,
              eid: '8ba865bc-944a-4e04-8d58-f3c452d3d486',
              entity: 'Sys.Confirmation',
              instance: null,
              is_default: false,
              is_global: false,
              is_primary: false,
              is_sub_match: false,
              label: 'Yes',
              label_lemma: null,
              match_origin: null,
              ml_probability: null,
              ml_score: null,
              ml_threshold: null,
              order: null,
              original: 'Yes',
              param_eid: null,
              phrase: null,
              pk: null,
              required: null,
              score: null,
              sub_label: null,
              type: 'custom',
            },
            {
              actual_text: 'No',
              bring_always: false,
              default_value: null,
              eid: '84386fe8-fcd4-4a79-a7ee-9d0b4b724785',
              entity: 'Sys.Confirmation',
              instance: null,
              is_default: false,
              is_global: false,
              is_primary: false,
              is_sub_match: false,
              label: 'No',
              label_lemma: null,
              match_origin: null,
              ml_probability: null,
              ml_score: null,
              ml_threshold: null,
              order: null,
              original: 'No',
              param_eid: null,
              phrase: null,
              pk: null,
              required: null,
              score: null,
              sub_label: null,
              type: 'custom',
            },
          ],
        },
      },
      sys_date_created: '2020-04-15T21:08:48.620290Z',
      text:
        'Would you like me to connect you with the right expert to help you?',
      type: 'message',
      user_id: 1,
    },
    {
      channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
      id: 'cb3d8a8a-b61a-4b9e-a931-1df09856e835',
      metadata: {},
      sys_date_created: '2020-04-15T21:08:47.507423Z',
      text:
        'I am currently still learning an answer for this, but I can get you to the right person that can help you.',
      type: 'message',
      user_id: 1,
    },
    {
      channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
      id: 'eb8bc12c-7a25-46cf-b374-171776b9680b',
      metadata: null,
      sys_date_created: '2020-04-15T21:08:42.656366Z',
      text: "Task 'att6' is created by eddie valverde for eddie valverde",
      type: 'system',
      user_id: null,
    },
    {
      channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
      id: '6cc96941-8b5c-4e5d-bf2e-1f9cb850f736',
      metadata: null,
      sys_date_created: '2020-04-15T21:08:39.613482Z',
      text: 'att6',
      type: 'message',
      user_id: 11232,
    },
    {
      channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
      id: '4b1c7616-574e-440a-871d-40b58aa28090',
      metadata: {},
      sys_date_created: '2020-04-15T21:08:36.646107Z',
      text:
        '• [I lost my password](espressive.com/app/barista?phrase=I%20lost%20password&submit=y)\n• [The WiFi network is slow today](espressive.com/app/barista?phrase=wifi%20is%20slow&submit=y)\n• [How do I refresh my laptop?](espressive.com/app/barista?phrase=how%20do%20I%20refresh%20my%20laptop&submit=y)\n• [I need a CRM account](espressive.com/app/barista?phrase=I%20need%20CRM%20account&submit=y)',
      type: 'message',
      user_id: 1,
    },
    {
      channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
      id: '52398b59-8162-4bbc-9421-b05d6aa80320',
      metadata: {},
      sys_date_created: '2020-04-15T21:08:36.540139Z',
      text:
        "Hi eddie, it's a privilege to serve you! Go ahead and ask me anything and I will do my best to help you. Here are examples of things you can ask or report:",
      type: 'message',
      user_id: 1,
    },
  ],
};

export default api_chat_v0_1_channels_0f63206f_messages;
