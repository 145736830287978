const api_barista_v0_1_intents_32132_annotation = {
  count: 2,
  next:
    'https://release.qa.espressive.com/api/barista/v0.1/intents/32132/annotation/?limit=25&offset=25',
  previous: null,
  results: [
    {
      active: true,
      annotate: '',
      eid_faqkb: 'cead9d0743acbfd',
      eid_intent: '32132',
      eid_kb: 'c9d0743acbfd',
      kb: {
        article_id: '7777',
        kb_number: '111',
        title: 'Hello there',
      },
      rank: null,
    },
    {
      active: true,
      annotate: '',
      eid_faqkb: 'ceadeewqeqweq743acbfd',
      eid_intent: '32132',
      eid_kb: 'ce880743acbfd',
      kb: {
        faq_eid: '32423',
        title: 'Hello there 2',
      },
      rank: null,
    },
  ],
};

export default api_barista_v0_1_intents_32132_annotation;
