const api_barista_v0_1_intents_75dc12b2d7fd575966_annotation = {
  count: 1,
  next:
    'https://release.qa.espressive.com/api/barista/v0.1/intents/75dc12b2d7fd575966/annotation/?limit=25&offset=25',
  previous: null,
  results: [
    {
      active: true,
      annotate: '',
      eid_faqkb: 'cead9d0743acbfd',
      eid_intent: '75dc12b2d7fd575966',
      eid_kb: null,
      kb: {
        body:
          'Amazon Alexa, also known simply as Alexa,[2] is a virtual assistant AI technology developed by Amazon, first used in the Amazon Echo smart speakers developed by Amazon Lab126.',
        boost: 1,
        eid: '75dc12b2d7fd575966',
        es_score_norm: 0,
        faq_eid: '8d12rwerwe4fa78f5cb6',
        kb_entities: null,
        kb_number: '111',
        markdown: "[what's alexa](esp.kbArticle 75dc12b2d7fd575966)",
        opening:
          'Amazon Alexa, also known simply as Alexa,[2] is a virtual assistant AI technology developed by Amazon, first used in the Amazon Echo smart speakers developed by Amazon Lab126.',
        origin: 'internal',
        section_eid: '51fbrwqrwe4e43f1',
        title: "what's alexa",
        url: 'https://release.qa.espressive.com/kbview?eid=75dc12b2d7fd575966',
      },
      rank: null,
    },
  ],
};

export default api_barista_v0_1_intents_75dc12b2d7fd575966_annotation;
