import { change } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import workflowThunks from '../../actions/workflowThunks';
import getBaristaId from '../../selectors/getBaristaId';

const mapStateToProps = (state, ownProps) => ({
  baristaId: getBaristaId(state),
  // Need to make a correct preview of link with bracket
  imageNewFaq: state.getIn(['adminFaqs', 'imageNewFaq']),
  initialInputValue: state.getIn(
    ['form', 'Form01', 'values', ownProps.inputName],
    null
  ),
});

const mapDispatchToProps = (dispatch) => ({
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
  setScratch(value = false) {
    dispatch(
      workflowThunks.updateFrontEndScratch({
        createNewResponse: value,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
