import { useCallback, useState } from 'react';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import APIcall from '../../../../../utils/APIcall';
import { isEmpty } from 'lodash';
import EspFilters from 'esp-util-filters';

const getUserSelectList = (userSelectList) => {
  const optionUsers = [];
  if (userSelectList && !isEmpty(userSelectList)) {
    userSelectList.forEach((usr, i) => {
      optionUsers.push({
        key: i + 1,
        text: usr.full_name,
        value: Number(usr.id),
      });
    });
  }
  return optionUsers;
};

const useGetUsers = () => {
  const [users, setUsers] = useState([]);
  const loadUsers = useCallback(({ query }) => {
    const url = endpointGenerator.genPath('espUser.users');
    const filters = new EspFilters();

    if (query?.searchTerm) {
      filters
        .contains('first_name', query.searchTerm)
        .or()
        .contains('last_name', query.searchTerm);
    }
    return new Promise((resolve) =>
      APIcall.get({
        query: {
          ...query,
          esp_filters: filters.asQueryString(),
        },
        token: true,

        url,
      }).then(({ body }) => {
        const { results } = body;
        setUsers(getUserSelectList(results));
        resolve(results);
      })
    );
  }, []);

  return {
    loadUsers,
    users,
  };
};

export default useGetUsers;
