// Actions - Thunks
import baristaThunks from '../../actions/baristaThunks';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const archetypeEID = state.getIn(
    [
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
      'selected_template',
      'template',
    ],
    null
  );
  return {
    archetypeEID,
    entityList: state.getIn(['adminFaqs', 'entityList']),
    isLoadingEntityList: state.getIn(['adminFaqs', 'isLoadingEntityList']),
  };
};

// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  loadEntityValues(eid) {
    dispatch(baristaThunks.searchEntityByArchetype(eid));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
