import moment from 'moment';

/**
 * Get the Overdue date on a progress
 * @param progress {Immutable Map}
 * @returns {string}
 */

const getTimeFromMins = (mins, intl) => {
  const days = Math.floor(mins / 1440);
  const hours = Math.floor((mins - 1440 * days) / 60);

  let finalOverdue =
    days > 0
      ? `${intl.formatMessage(
          {
            id: 'label.day',
          },
          {
            days: days,
          }
        )} `
      : '';

  if (hours > 0) {
    finalOverdue += `${intl.formatMessage(
      {
        id: 'label.hour',
      },
      {
        hours: hours,
      }
    )} `;
  }

  if (days === 0 && hours === 0) {
    finalOverdue = intl.formatMessage({
      id: 'label.few_minutes',
    });
  }

  return finalOverdue;
};

const getOverdueDate = (progress, intl) => {
  let dueOn = intl.formatMessage({
    id: 'label.no_due_date',
  });
  const deadline = progress.get('deadline');

  if (deadline) {
    const now = moment();
    const deadlineMoment = moment(deadline);
    if (deadlineMoment.isBefore(now)) {
      const diffMinutes = now.diff(deadlineMoment, 'minutes');
      const duration = getTimeFromMins(diffMinutes, intl);

      dueOn = `${duration} ${intl.formatMessage({
        id: 'label.overdue',
      })}`;
    } else {
      const diffMinutes = deadlineMoment.diff(now, 'minutes');
      const duration = getTimeFromMins(diffMinutes, intl);

      dueOn = `${duration} ${intl.formatMessage({
        id: 'label.remaining',
      })}`;
    }
  }

  return dueOn;
};

export default getOverdueDate;
