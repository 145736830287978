import { createSelector } from 'reselect';
import getCurrentUser from './getCurrentUser';
import Immutable from 'immutable';
import { ALL_SERVICE_TEAMS } from '../../v2/components/functional/CaseFeedTeamPicker/utils';

const getExistingSelectedTeamId = (state) =>
  state.getIn(['caseMgmt', 'selectedServiceTeamId']);
const getApprovalExistingSelectedTeamId = (state) =>
  state.getIn(['caseMgmt', 'selectedApprovalServiceTeamId']);
const getMyTeams = (state) => state.getIn(['caseMgmt', 'myServiceTeams']);
const getDefaultTeam = (state) =>
  state.getIn(['caseMgmt', 'defaultServiceTeam']);
const getTeamInQueryParams = (state) =>
  state.hasIn(['caseMgmt', 'queryParams', 'team'])
    ? // transforming it to number, since its a string
      Number(state.getIn(['caseMgmt', 'queryParams', 'team']))
    : null;

const getDefaultTeamId = createSelector(
  [getDefaultTeam],
  (defaultTeam = Immutable.Map()) => defaultTeam.get('id')
);

const getCasesSelectedServiceTeamId = createSelector(
  [
    getExistingSelectedTeamId,
    getTeamInQueryParams,
    getDefaultTeamId,
    getMyTeams,
  ],
  (
    existingSelectedTeamId = null,
    teamInQueryParams = null,
    defaultTeamId = null,
    myTeams = Immutable.List()
  ) => {
    if (existingSelectedTeamId) {
      // if something is selected, return it right away
      return existingSelectedTeamId;
    }

    if (teamInQueryParams) {
      return teamInQueryParams;
    }

    if (!myTeams.isEmpty()) {
      // I must have teams in my teams
      if (defaultTeamId) {
        // select the default team if it belongs to my teams
        const selTeam = myTeams.find(
          (team) => team.get('id') === defaultTeamId
        );
        if (selTeam) {
          return selTeam.get('id');
        }
      }
      // return all my cases
      return ALL_SERVICE_TEAMS;
    }

    return null;
  }
);

const getSelectedServiceTeamId = createSelector(
  [
    getExistingSelectedTeamId,
    getTeamInQueryParams,
    getDefaultTeamId,
    getMyTeams,
  ],
  (
    existingSelectedTeamId = Immutable.Map(),
    teamInQueryParams = null,
    defaultTeamId = null,
    myTeams = Immutable.List()
  ) => {
    if (existingSelectedTeamId) {
      // if something is selected, return it right away
      return existingSelectedTeamId;
    }

    if (teamInQueryParams) {
      return teamInQueryParams;
    }

    if (!myTeams.isEmpty()) {
      // I must have teams in my teams
      if (defaultTeamId) {
        // select the default team if it belongs to my teams
        const selTeam = myTeams.find(
          (team) => team.get('id') === defaultTeamId
        );
        if (selTeam) {
          return selTeam.get('id');
        }
      }
      // otherwise just return the first of my teams
      return myTeams.first().get('id');
    }

    return null;
  }
);

const getApprovalSelectedServiceTeamId = createSelector(
  [
    getCurrentUser,
    getApprovalExistingSelectedTeamId,
    getTeamInQueryParams,
    getMyTeams,
  ],
  (
    currentUser = Immutable.Map(),
    existingSelectedTeamId = null,
    teamInQueryParams = null,
    myTeams = Immutable.List()
  ) => {
    if (existingSelectedTeamId) {
      // if something is selected, return it right away
      return existingSelectedTeamId;
    }
    const MANAGER_VALUE = -1;
    const isManager = currentUser && currentUser.get('is_manager');

    if (
      (teamInQueryParams === MANAGER_VALUE && isManager) ||
      teamInQueryParams
    ) {
      return teamInQueryParams;
    }

    if (!myTeams.isEmpty()) {
      // I must have teams in my teams

      return null;
    }

    return null;
  }
);

const getCasesSelectedServiceTeam = createSelector(
  [getCasesSelectedServiceTeamId, getMyTeams],
  (selectedTeam = null, myTeams = Immutable.List()) =>
    myTeams.find((team) => team.get('id') === selectedTeam)
);

const getSelectedServiceTeam = createSelector(
  [getSelectedServiceTeamId, getMyTeams],
  (selectedTeam = null, myTeams = Immutable.List()) =>
    myTeams.find((team) => team.get('id') === selectedTeam)
);

const getMyServiceDepartments = createSelector(
  [getMyTeams],
  (myTeams = Immutable.List()) => {
    if (myTeams.isEmpty()) {
      return null;
    }

    return myTeams.reduce((arr, team) => {
      const serviceDepartment = team.get('service_department');
      const alreadyExists = arr.find(
        (t) => t.get('name') === serviceDepartment.get('display_name')
      );
      if (!alreadyExists) {
        return arr.push(
          Immutable.fromJS({
            id: serviceDepartment.get('id'),
            name: serviceDepartment.get('display_name'),
            type: serviceDepartment.get('service_department_type_id'),
          })
        );
      }
      return arr;
    }, Immutable.List());
  }
);

const getSelectedDepartmentId = createSelector(
  [getSelectedServiceTeam],
  (selectedTeam = null) => {
    if (selectedTeam && !selectedTeam.isEmpty()) {
      if (selectedTeam.hasIn(['service_department', 'id'])) {
        return selectedTeam.getIn(['service_department', 'id']);
      }
    }
    return null;
  }
);

export { getCasesSelectedServiceTeam };
export { getCasesSelectedServiceTeamId };
export { getMyTeams };
export { getDefaultTeam };
export { getDefaultTeamId };
export { getSelectedDepartmentId };
export { getSelectedServiceTeamId };
export { getApprovalSelectedServiceTeamId };
export { getSelectedServiceTeam };
export { getExistingSelectedTeamId };
export { getMyServiceDepartments };
export default getSelectedServiceTeam;
