import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.node.isRequired,
};
const SelectMyGearList = ({ children }) => (
  <List className='cart-list' divided relaxed='very' verticalAlign='middle'>
    {children}
  </List>
);
SelectMyGearList.propTypes = propTypes;
export default SelectMyGearList;
