import React, { PureComponent } from 'react';
import ChatBotIcon from '../../../../globals/ChatBotIcon';

/**
 * I detected a HUGE load time (~1 second) by loading svg files with the library react-inlinesvg
 * Since React 16, we should be good to render svg diectly as react components
 * Totally faster, let's see DEV-4108
 */

class BaristaUserImg extends PureComponent {
  render() {
    return <ChatBotIcon />;
  }
}

export default BaristaUserImg;
