import pt from 'prop-types';
import { FormPropTypes } from '@espressive/cascara';

export const DEFAULT_FORM_ACTIONS = {
  actionButtonMenuIndex: 1,
  modules: [
    {
      module: 'edit',
    },
  ],
};

export const DEFAULT_ACTIONS = {
  modules: [
    {
      module: 'edit',
    },
  ],
};

export const PROP_TYPES = {
  ...FormPropTypes,

  // actions for ActionStack
  actions: pt.arrayOf(
    pt.shape({
      label: pt.string.isRequired,
      onClick: pt.func.isRequired,
    })
  ),

  // URL of the API endpoint that we want to get data from
  endpointUrl: pt.string.isRequired,
  // actions for Form
  formActions: FormPropTypes.actions,
  // The sub title for the page.
  pageTitleSub: pt.string,
  // specifies the attribute that uniquely identifies every object in the 'data' array.
  titleParam: pt.string,
  uniqueIdAttribute: pt.string,
};
