import { useEffect, useState } from 'react';
import DateTools from '../../../../../utils/DateTools';
import getLastSync from '../endpoints/getLastSync';
import runSync from '../endpoints/runSync';

const useLastSync = ({ lastSyncURL, runSyncURL }) => {
  const [lastSync, setLastSync] = useState({
    added: 0,
    archived: 0,
    date: '',
    deleted: 0,
    found: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isEmptySyncMessage, SetEmptySyncMessage] = useState(true);

  const setSync = async () => {
    setLoading(true);
    try {
      const { body } = await runSync(runSyncURL);
      setLastSync({
        date: body?.datetime
          ? DateTools.getDateFromUTCToLocal({
              date: body?.datetime,
              toLocaleDateString: true,
            })
          : '',
        ...body?.results,
      });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const { body } = await getLastSync(lastSyncURL);
        setLastSync({
          date: body?.datetime
            ? DateTools.getDateFromUTCToLocal({
                date: body?.datetime,
                toLocaleDateString: true,
              })
            : '',
          ...body?.results,
        });
        SetEmptySyncMessage(false);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lastSyncURL]);

  return {
    isEmptySyncMessage,
    lastSync,
    loading,
    setSync,
  };
};

export default useLastSync;
