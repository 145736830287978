import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsTutorial extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M75.48 16.5h-32.5c-2.76 0-5 2.24-5 5v17l1.31 1 .69-.46V21.5c0-1.657 1.343-3 3-3h32.5c1.657 0 3 1.343 3 3v25.37c0 1.657-1.343 3-3 3h-32.5c-1.657 0-3-1.343-3-3v-.91l-.75.6-1.25-.78v1.09c0 2.76 2.24 5 5 5h32.5c1.326 0 2.598-.527 3.536-1.464.937-.938 1.464-2.21 1.464-3.536V21.5c0-2.76-2.24-5-5-5z' />
          <path d='M39.34 45.66l8.72-7.1c.652-.408.952-1.2.733-1.938-.22-.737-.904-1.237-1.673-1.222-.446.052-.865.24-1.2.54l-.11.07-6.59 4.27-4.39-3.44-.63-1.08c-1-2.41-1.45-2.72-3.64-3.08l-3-.4c2.797-.79 4.583-3.52 4.185-6.4-.398-2.878-2.86-5.02-5.765-5.02-2.906 0-5.367 2.142-5.765 5.02-.398 2.88 1.388 5.61 4.185 6.4h-3.5c-2.428.005-4.395 1.972-4.4 4.4v15.26c-.007.117-.007.233 0 .35 0 1.602 1.298 2.9 2.9 2.9h.26v20.75c-.01.742.276 1.456.797 1.985.52.528 1.23.825 1.973.825 1.466 0 2.685-1.128 2.8-2.59v-15.4c-.005-.41.133-.81.39-1.13h.86c.44 0 .5 1 .5 1.06v15.57c.088 1.463 1.3 2.604 2.765 2.604s2.677-1.14 2.765-2.604v-.36l.1-28.93s.08-1.86.12-2.93l.21-2.09 6.2 3.82.2-.11zm9.87-9.77l10.92-8.67c.157-.105.242-.29.22-.476-.023-.188-.15-.346-.327-.41-.177-.065-.376-.024-.513.106l-10.92 8.67c-.19.178-.21.47-.048.675.16.203.452.25.668.105z' />
        </g>
      </svg>
    );
  }
}

export default CardsTutorial;
