const api_barista_v0_1_faqs_general = {
  activation_condition: null,
  active: true,
  answers: [
    {
      condition: '',
      eid: '99602f66-87e9-413a-a9a8-e189187cdea8',
      lines: [
        {
          eid: '1f2ffc46-8a36-4bad-968a-f349480815c0',
          order: 1,
          phrase: '',
        },
        {
          eid: 'ed15c98d-b813-45b1-a9d6-a0c8ab07a079',
          order: 2,
          phrase: 'second response',
        },
        {
          eid: 'ed15c98d-b813-45b1-a9d6-a0c8ab07a079',
          order: 3,
          phrase: 'third response',
        },
      ],
    },
    {
      condition:
        '#user.job_role__EQ=2&user.location__EQ=2&user.department__EQ=7&user.department__EQ=5',
      eid: '197174de-d01b-404d-8799-5e505d98d329',
      lines: [
        {
          eid: '76a90d30-e597-4c36-9687-5f6a01a00c97',
          phrase: 'Another response',
        },
      ],
    },
  ],
  application_name: 'antivirus IT',
  attributes: [
    {
      eid: '6b47301e-988b-4560-9454-9a69a09a5987',
      name: 'category',
      option: 'antivirus IT',
    },
    {
      eid: '56c341f0-4284-41f0-9bae-6f7ffb2a97e2',
      name: 'service_department',
      option: 'IT',
    },
    {
      eid: '926f86d7-ff46-4518-bfdb-52c35d204b25',
      name: 'service_team',
      option: '5',
    },
  ],
  case_created_total: 0,
  case_helpful_total: 0,
  description: 'descirption question',
  eid: 'a59c9e3e-7e96-46ed-8d72-a50afa7ad87c',
  feedback_score: 0,
  name: 'question 1',
  sys_created_by: 'john@acme.com',
  sys_date_created: '2018-06-22T20:57:07.827584Z',
  sys_date_updated: '2018-06-22T20:57:07.827532Z',
  sys_updated_by: 'john@acme.com',
  total_completions: 0,
  triggered_misspell_total: 0,
  triggered_total: 0,
  triggers: [
    {
      eid: '15dc4e3a-7322-4001-9022-13a3c0393c94',
      phrase: 'question 1',
    },
    {
      eid: '7e20d0e2-e9e5-4e81-b8fb-86535f3c4d96',
      phrase: 'variation 2',
    },
  ],
  triggers_total: 2,
};

export default api_barista_v0_1_faqs_general;
