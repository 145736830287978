import moment from 'moment';
import _ from 'lodash';
import Immutable from 'immutable';

// Globals
import UserStates from '../globals/UserStates';
import tenantMenu from '../globals/tenantMenu';
import groupPermissions from '../globals/groupPermissions';
// Store
import Store from '../stores/store';
// Utils
import checkMenuPermissions from './checkMenuPermissions';

const UserUtils = {
  store: Store,
};

/**
 * In use for unit test in order to pass a fake store
 * @param store
 */
UserUtils.setStore = (store) => {
  if (store) {
    UserUtils.store = store;
  }
};

/**
 * Generic handler to verify if a user has been confirmed by his manager
 * @param {string} user_state - Current user's state
 * @returns {boolean}
 */
const managerConfirmed = (user_state) => {
  if (_.isEmpty(user_state)) {
    return false;
  }
  return user_state === UserStates.MANAGER_CONFIRMED;
};

/**
 * Generic handler to verify if a user has started
 * @param {string} start_date - User's start date (ISO8601)
 * @returns {boolean}
 */
const userStarted = (start_date) => {
  if (_.isEmpty(start_date)) {
    return false;
  }
  return moment().isAfter(start_date);
};

/**
 * @param user An Immutable Map.
 * @return {boolean} Tells if given user is part of a team.
 */
UserUtils.isInTeam = (user) =>
  (user && Boolean(user.get('manager'))) || UserUtils.isManagerGroup(user);

/**
 * Checks if the user state equals to UserStates.MANAGER_CONFIRMED
 * @param {Map} user - An immutable map holding the user's info
 * @returns {boolean}
 */
UserUtils.isManagerConfirmed = (user) =>
  managerConfirmed(user.get('user_state'));

/**
 * Checks if the current date is greater than user start_date, that is, if the user has started
 * @param {Map} user - An immutable map holding the user's info
 * @returns {boolean}
 */
UserUtils.isAfterStartDate = (user) => userStarted(user.get('start_date'));

const allowedGroupsTakeAssignment = () =>
  Immutable.List([
    groupPermissions.HR_CONTACT,
    groupPermissions.HR_RECRUITER,
    groupPermissions.ENABLEMENT,
    groupPermissions.IT_CONTACT,
    groupPermissions.OPERATIONS,
  ]);

const isAuthorizedAssignment = ({ currentUser }) => {
  const currentUserGroups = currentUser.get('groups', Immutable.List());
  const authorizedGroups = allowedGroupsTakeAssignment();
  return (
    currentUserGroups.find((g) => authorizedGroups.find((a) => a === g)) || null
  );
};

//
// @param options <Object userAssignment = Immutable.Map, user = Immutable.Map>
// @return <Boolean>
//
UserUtils.isUserAssigned = ({ userAssignment, user }) => {
  const currentUserAssignments =
    userAssignment.get(user.get('id')) || Immutable.Map();
  const groupsMap = currentUserAssignments.get('groups') || Immutable.Map();
  const groups = groupsMap
    .filterNot((g) => g === null)
    .map((g, k) => k)
    .toList();
  const res =
    groups.find((g) => allowedGroupsTakeAssignment().find((a) => a === g)) ||
    null;
  return res !== null;
};

//
// @param options <Object user = Immutable.Map, isViewingMyself = Boolean, currentUser = Immutable.Map>
// @return <Boolean>
//
UserUtils.canTerminateUser = ({ user, isViewingMyself, currentUser }) => {
  if (isViewingMyself) {
    return false;
  }

  const managerID = user.getIn(['manager', 'id']);
  if (managerID && managerID === currentUser.get('id')) {
    return true;
  }
  return false;
};

//
// @param options <Object userAssignment = Immutable.Map, user = Immutable.Map, isViewingMyself = Boolean, currentUser = Immutable.Map>
// @return <Boolean>
//
UserUtils.canTakeAssignment = ({
  userAssignment,
  user,
  isViewingMyself,
  currentUser,
}) => {
  if (isViewingMyself) {
    return false;
  }

  const isUserAssigned = UserUtils.isUserAssigned({
    user,
    userAssignment,
  });

  if (isUserAssigned) {
    return false;
  }

  if (
    !isAuthorizedAssignment({
      currentUser,
    })
  ) {
    return false;
  }

  return true;
};

//
// @param options <Object userAssignment = Immutable.Map, user = Immutable.Map, isViewingMyself = Boolean, currentUser = Immutable.Map>
// @return <Boolean>
//
UserUtils.canRemoveAssignment = ({
  userAssignment,
  user,
  isViewingMyself,
  currentUser,
}) => {
  const currentUserAssignments =
    userAssignment.get(user.get('id')) || Immutable.Map();

  if (isViewingMyself) {
    return false;
  }

  if (currentUserAssignments.isEmpty()) {
    return false;
  }

  if (
    !isAuthorizedAssignment({
      currentUser,
    })
  ) {
    return false;
  }

  if (userAssignment.isEmpty()) {
    return false;
  }

  const isAssignedToMe = UserUtils.isUserAssignedToMe({
    currentUser,
    user,
    userAssignment,
  });

  return isAssignedToMe;
};

//
// @param options <Object userAssignment = Immutable.Map, user = Immutable.Map>
// @return <Boolean>
//
UserUtils.isUserAssignedToMe = ({ currentUser, userAssignment, user }) => {
  const currentUserAssignments =
    userAssignment.get(user.get('id')) || Immutable.Map();
  const groupsMap = currentUserAssignments.get('groups') || Immutable.Map();
  const matches = groupsMap.filter((i) => i === currentUser.get('id'));
  const result = matches.filter((i, g) =>
    allowedGroupsTakeAssignment().find((a) => a === g)
  );
  if (result.size > 0) {
    return true;
  }
  return false;
};

//
// @param options <Object currentUser = Immutable.Map>
// @return <Boolean>
//
UserUtils.isAuthorizedAssignment = ({ currentUser }) => {
  const currentUserGroups = currentUser.get('groups', Immutable.List());
  const authorizedGroups = allowedGroupsTakeAssignment();
  const res =
    currentUserGroups.find((g) => authorizedGroups.find((a) => a === g)) ||
    null;
  return res !== null;
};

//
// @param options <Object currentUser = Immutable.Map>
// @return <String>
//
UserUtils.getAssignmentGroup = ({ currentUser }) => {
  const currentUserGroups = currentUser.get('groups', Immutable.List());
  const authorizedGroups = allowedGroupsTakeAssignment();
  return currentUserGroups.filter((g) => authorizedGroups.find((a) => a === g));
};

/**
 * @param user <Immutable.Map>
 * @return boolean
 */
UserUtils.isRealUser = (user = Immutable.Map()) => user.get('id') > 0;

/**
 * param user <Immutable.Map>
 */
UserUtils.isManagerGroup = (user) => {
  const userGroups = (user && user.get('groups')) || Immutable.List();
  const authorizedGroups = Immutable.List([groupPermissions.MANAGER]);
  return userGroups.some((g) => authorizedGroups.find((a) => a === g));
};

UserUtils.isAdmin = (user) => {
  const userGroups = (user && user.get('groups')) || Immutable.List();
  const authorizedGroups = Immutable.List([groupPermissions.ADMIN]);
  return (
    userGroups.some((g) => authorizedGroups.includes(g)) ||
    Boolean(user.get('is_admin'))
  );
};

UserUtils.isFaqAdmin = (user) => {
  const userGroups = (user && user.get('groups')) || Immutable.List();
  const authorizedGroups = Immutable.List([groupPermissions.FAQ_ADMIN]);
  return userGroups.some((g) => authorizedGroups.includes(g));
};

UserUtils.canViewNewHires = (user) => {
  // First thing is to make sure the user has been confirmed
  if (!UserUtils.isManagerConfirmed(user)) {
    return false;
  }

  if (UserUtils.isManagerGroup(user)) {
    return true;
  }

  const permissionGroups = user.get('groups');
  const functionalRoleGroups = allowedGroupsTakeAssignment(groupPermissions);

  return permissionGroups.some((g) => functionalRoleGroups.includes(g));
};

UserUtils.canViewEmployeeNumber = ({
  user,
  isViewingMyself,
  configurationTenantMenu,
}) => {
  if (!user || !configurationTenantMenu) {
    return false;
  }

  const employeeNumber = user.get('employee_number');

  // Display my own employee number if exist
  if (isViewingMyself && !_.isEmpty(employeeNumber)) {
    return true;
  }

  const settingEmployeeNumber = {
    web_menu: tenantMenu.publicEmployeeNumber,
  };

  return (
    (!_.isEmpty(employeeNumber) &&
      checkMenuPermissions(
        settingEmployeeNumber,
        user,
        configurationTenantMenu
      )) ||
    false
  );
};

export default UserUtils;
