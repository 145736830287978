import React, { isValidElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import RangeCell from './RangeCell';

class ReportHeader extends PureComponent {
  static propTypes = {
    coefficient: PropTypes.number,
    description: PropTypes.string,
    format: PropTypes.string,
    label: PropTypes.string,
    simpleHeader: PropTypes.bool,
    style: PropTypes.shape({
      style: PropTypes.shape({}),
    }),
    summary: PropTypes.shape({
      lastWeek: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
      thisWeek: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
    }),
  };

  static defaultProps = {
    coefficient: null,
    description: '',
    format: null,
    label: '',
    simpleHeader: false,
    style: {},
    summary: {
      lastWeek: 0,
      thisWeek: 0,
    },
  };

  formatMetric = (metric) => {
    const { format } = this.props;
    switch (format) {
      case 'currency':
        return `$${metric}`;
      default:
        return metric;
    }
  };

  formatLabel = () => {
    const { label, coefficient } = this.props;
    return coefficient ? `${label} ${this.formatMetric(coefficient)}` : label;
  };

  render() {
    const { description, simpleHeader, style, summary } = this.props;

    return (
      <header
        style={{
          borderBottom: '2px solid #d4d4d5',
          paddingBottom: '10px',
          ...style,
        }}
      >
        <p>{description}</p>

        {!simpleHeader ? (
          <>
            <span>{this.formatLabel()} </span>
            <Table basic='very' style={{ width: '50%' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {isValidElement(summary.lastWeek) ? (
                      summary.lastWeek
                    ) : (
                      <RangeCell
                        label=''
                        value={
                          isNaN(summary.lastWeek)
                            ? ''
                            : this.formatMetric(summary.lastWeek)
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {isValidElement(summary.thisWeek) ? (
                      summary.thisWeek
                    ) : (
                      <RangeCell
                        label=''
                        value={
                          isNaN(summary.thisWeek)
                            ? ''
                            : this.formatMetric(summary.thisWeek)
                        }
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        ) : null}
      </header>
    );
  }
}

export default ReportHeader;
