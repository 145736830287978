import { createSelector } from 'reselect';
import { Map } from 'immutable';

const defaultFeatureFlags = new Map({});
const getTenantFlags = createSelector(
  (state) =>
    state.getIn(
      ['tenant', 'entity', 'value', 'ui_config', 'newFeature'],
      defaultFeatureFlags
    ),
  (featureFlags) => featureFlags
);

export default getTenantFlags;
