import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';

const rule = (props, renderer) => {
  const { theme } = props;

  const circleSize = '10px';

  const jiggleInitial = {
    transform: 'translate3d(0, 0, 0)scale(1)',
  };

  // animationName
  const jiggle = () => ({
    '10%,30%': {
      transform: 'translate3d(0, 0, 0)scale(.5)',
    },

    '20%': {
      transform: 'translate3d(0, 0, 0)scale(1.2)',
    },

    '40%': jiggleInitial,

    from: jiggleInitial,

    to: jiggleInitial,
  });

  return {
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationName: renderer.renderKeyframe(jiggle),
    backgroundColor: theme.colors.darkGray,
    borderRadius: '50px',
    display: 'inline-block',
    height: circleSize,
    marginLeft: '.125em',
    marginRight: '.125em',
    width: circleSize,
  };
};

class ChatMessageTyping extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  render() {
    const { className } = this.props;

    return (
      <>
        <span className={className} data-testid='chat-message-typing' />
        <span
          className={className}
          style={{
            animationDelay: '150ms',
          }}
        />
        <span
          className={className}
          style={{
            animationDelay: '300ms',
          }}
        />
      </>
    );
  }
}

export default createComponent(rule, ChatMessageTyping, []);
