import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
// V2 utility
import externalDownloadHandler from '../../../v2/utils/externalDownloadHandler';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
};

const ExternalLink = ({
  children,
  className = null,
  href,
  onClick = null,
  style = null,
}) => {
  const handleClick = useCallback(
    (e) => {
      externalDownloadHandler(e);
      onClick && onClick(e);
    },
    [onClick]
  );

  return (
    <a
      className={className}
      href={href}
      onClick={handleClick}
      rel='noopener noreferrer'
      style={style}
      target='_blank'
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = propTypes;

export default ExternalLink;
