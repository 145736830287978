import React from 'react';
import pt from 'prop-types';

import {
  Flex,
  Pagination,
  paginationPropTypes,
  Table,
  TablePropTypes,
} from '@espressive/cascara';

import Filter, { FILTER_STATE_SHAPE } from '../Filter';
import { ViewConfig } from '../../private';

// We can probably spread all of these in later as we should not be maintaining multiple sources of truth for types
const {
  onAction: onActionPT,
  actions: actionsPT,
  data: dataPT,
  dataDisplay: dataDisplayPT,
} = TablePropTypes;

const propTypes = {
  /** A set of actions can be added to a table. */
  actions: pt.arrayOf(pt.shape({ label: pt.string, onClick: pt.func })),

  // An array of objects.
  //
  // Every object in this array will potencially be rendered as a table row.
  data: dataPT,
  // The main configuration for your table. Here you can specify the columns to display
  // as well as the available actions (if any) for each row.
  dataDisplay: dataDisplayPT,

  filterState: FILTER_STATE_SHAPE,

  // Event handler.
  //
  // An event handler you can pass to handle every event your table emits.
  onAction: onActionPT,

  // pagination options
  paginationState: pt.shape(paginationPropTypes),
  // Resolve record actions.
  // A function that returns the actions available to the current row
  resolveRecordActions: pt.func,

  /** Row actions will be appended to each row, they'll appear as buttons. */
  rowActions: actionsPT,

  // the length of data is not the same as the total count from the API
  // this specifies the total count received from API
  totalRecordCount: pt.number,
  // specifies the attribute that uniquely identifies every object in the 'data' array.
  uniqueIdAttribute: pt.string,

  viewConfigState: pt.exact({
    currentSelection: pt.arrayOf(pt.object),
    itemAdd: pt.func.isRequired,
    itemRemove: pt.func.isRequired,
    moveItemDown: pt.func.isRequired,
    moveItemUp: pt.func.isRequired,
  }),
};

const TableLayout = (props) => {
  const {
    dataDisplay,
    filterState,
    paginationState,
    rowActions,
    totalRecordCount,
    viewConfigState,
  } = props;

  const { currentSelection } = viewConfigState;

  return (
    <>
      <Filter state={filterState} />
      <Flex>
        <div style={{ width: '100%' }}>
          <Pagination
            state={paginationState}
            totalRecordCount={totalRecordCount}
          />
        </div>
        {dataDisplay && viewConfigState && (
          <ViewConfig
            options={dataDisplay}
            state={viewConfigState}
            title='Columns'
          />
        )}
      </Flex>
      <Table {...props} actions={rowActions} dataDisplay={currentSelection} />
      <Pagination state={paginationState} totalRecordCount={totalRecordCount} />
    </>
  );
};

TableLayout.propTypes = propTypes;

export const ViewConfigExportable = ViewConfig;
export default TableLayout;
