import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import TextHighlight from '../atoms/TextHighlight';
import uiPathGenerator from '../../utils/uiPathGenerator';

import UserImageContainer from './UserImageContainer';
import EspListItem from './EspListItem';

class DirectChatChannelResult extends PureComponent {
  static propTypes = {
    currentUser: ImmutablePropTypes.map.isRequired,
    onClick: PropTypes.func.isRequired,
    result: ImmutablePropTypes.map.isRequired,
    routeToResult: PropTypes.string.isRequired,
    routeToResultIncludesQ: PropTypes.bool.isRequired,
    selectedResultID: PropTypes.string,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectedResultID: null,
  };

  render() {
    const {
      selectedResultID,
      currentUser,
      onClick,
      result,
      routeToResult,
      routeToResultIncludesQ,
      userInput,
    } = this.props;

    const participants = result.get('participants');
    const otherUser = participants.find(
      (participant) => participant.get('id') !== currentUser.get('id')
    );

    let isActive = false;

    if (selectedResultID) {
      isActive = otherUser.get('id') === Number(selectedResultID);
    }

    const imageEl = <UserImageContainer avatar userId={otherUser.get('id')} />;

    // TODO Use UserName and TexyHighlight components together here
    const name = `${otherUser.get('nickname')} ${otherUser.get('last_name')}`;

    const nameEl = <TextHighlight term={userInput} text={name} />;

    const title = otherUser.get('title') || '';

    const contentEl = <TextHighlight term={userInput} text={title} />;

    let descriptionEl = null;

    // no. of messages in the channel that matched
    const totalMessages = result.get('total_messages') || 0;

    if (totalMessages > 0) {
      descriptionEl = intl.formatMessage({
        id: 'message.no_of_messages_that_matched',
      });
    }

    const urlToResult = uiPathGenerator.genPath(routeToResult, {
      userID: otherUser.get('id'),
    });
    let toResult = urlToResult;

    if (routeToResultIncludesQ) {
      toResult = {
        pathname: urlToResult,
        search: `?q=${userInput}`,
      };
    }

    return (
      <EspListItem
        active={isActive}
        as={Link}
        content={contentEl}
        description={descriptionEl}
        image={imageEl}
        isSearchResult
        name={nameEl}
        onClick={onClick}
        to={toResult}
      />
    );
  }
}

export default DirectChatChannelResult;
