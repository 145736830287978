const api_barista_v0_1_scheduled_elc_sync = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      days_of_week: ['tue', 'wed', 'sun'],
      eid: '3511e6fd-5068-4a73-a4ef-4533dd8da743',
      scheduled_time: '07:23:00',
      sync_type: 'sync_all',
      sys_created_by: null,
      sys_custom_fields: {
        __stale_check: {
          save_time: '2021-12-14 23:09:18.845744+00:00',
          version: 6,
        },
      },
      sys_date_created: '2021-08-05T17:10:01.243800Z',
      sys_date_updated: '2021-12-14T23:09:18.847068Z',
      sys_updated_by: 'espbeta02@gmail.com',
    },
  ],
};

export default api_barista_v0_1_scheduled_elc_sync;
