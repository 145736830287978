import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import ButtonChevronToggle from '../ButtonChevronToggle';
// import {stripUnit}           from 'polished';

const style = (props) => {
  const { theme } = props;

  return {
    backgroundColor: theme.colors.gray,
    cursor: 'pointer',
    display: 'inline-block',
    height: theme.formHeight,
    textAlign: 'center',
    verticalAlign: 'bottom',
    width: theme.formHeight,
  };
};

class ChatFormSelectToggle extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired, // Generated Fela class names
    onClick: PropTypes.func.isRequired, // Button onClick event
    open: PropTypes.bool, // The button can indicate an open or closed status
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { className, onClick, open } = this.props;

    return (
      <div className={className} data-component='ChatFormSelectToggle'>
        <ButtonChevronToggle isReversed onClick={onClick} open={open} />
      </div>
    );
  }
}

export default createComponent(style, ChatFormSelectToggle, [
  'open',
  'onClick',
]);
