import { useMutation, useQuery } from '@tanstack/react-query';
import APIcall from '../../../../../../utils/APIcall';
import endpointGenerator from '../../../../../../utils/endpointGenerator';

export const useTestConnection = () =>
  useMutation((credentials) => {
    const url = endpointGenerator.genPath(
      'espIntegration.integrations.sharepoint.testConnection'
    );
    return APIcall.post({
      data: credentials,
      token: true,
      url,
    });
  });

export const useSaveNewCredentials = () =>
  useMutation((credentials) => {
    const url = endpointGenerator.genPath(
      'espIntegration.integrations.sharepoint.setCredential'
    );
    return APIcall.post({
      data: credentials,
      token: true,
      url,
    });
  });

export const useGetCredentials = () =>
  useQuery({
    queryFn: () => {
      const url = endpointGenerator.genPath(
        'espIntegration.integrations.sharepoint.setCredential'
      );
      return APIcall.get({
        token: true,
        url,
      });
    },
    queryKey: ['sharepointGetCredentials'],
  });
