import moment from 'moment';
import {
  INPUT_DATE_FORMAT,
  INPUT_ISO_FORMAT,
  INPUT_TIME_FORMAT,
} from '../../globals/DefaultInputFormats';
import LocaleSingleton from './LocaleSingleton';

const BROWSER_LANGUAGE = navigator.language;
const WEEK_DAYS_STRINGS = [1, 2, 3, 4, 5, 6, 0].map((weekDay) =>
  moment().weekday(weekDay).format('ddd')
);

class DateTools {
  /**
   * @param date {UTC string}
   */
  constructor(date, format = null, locale = BROWSER_LANGUAGE) {
    const dateParser = () => date.replace(/[-]/g, '/', '/');
    // when date and format are not given, we need to create a new date by using current date
    if (!date && !format) {
      this.date = moment().locale(locale);
      this.now = moment().locale(locale);
    }
    // when date is given but format is null, we will interpret it as an ISO date or valid date value accepted by moment
    else if (date && !format) {
      // if date value is valid, moment will recognize it and create date
      if (moment(date).isValid()) {
        this.date = moment(date).locale(locale);
        this.now = moment().locale(locale);
      } else {
        throw new Error('DateTools requires a valid date');
      }
    } else {
      // Need to replace all - by / to make the date in safari / iOS working
      this.datePassed = date ? dateParser() : moment().locale(locale);
      this.date = moment(this.datePassed, format).locale(locale);
      this.now = moment().locale(locale);

      if (!this.date.isValid()) {
        throw new Error('invalid date');
      }
    }
  }

  getDate() {
    return this.date;
  }
  setDate(date) {
    return (this.date = date);
  }
  getNow(locale = BROWSER_LANGUAGE) {
    return moment().locale(locale);
  }
  getDatePassed() {
    return this.datePassed;
  }

  getStandardDateFormat() {
    return this.date.format('L');
  }

  getStandardDateFormatWithHours(customHoursFormat) {
    return `${this.date.toDate().toLocaleDateString()} ${this.date.format(
      customHoursFormat ? customHoursFormat : 'LT'
    )}`;
  }

  isToday() {
    return moment(new Date(this._getDateFormatted())).isSame(
      new Date(this._getNowFormatted())
    );
  }

  isAfter() {
    return moment(new Date(this._getNowFormatted())).isAfter(
      new Date(this._getDateFormatted())
    );
  }

  isBefore() {
    return moment(new Date(this._getNowFormatted())).isBefore(
      new Date(this._getDateFormatted())
    );
  }

  generateHoursArray(_hour = null) {
    const hours = [];
    const currentHour = _hour || this._getTimeZoneParsed();
    // we add plus one hour because we want to exclude current hour
    for (let hour = Number(currentHour) + 1; hour <= 23; hour++) {
      let time = null;
      if (hour > 12) {
        time = `${hour - 12} PM`;
      } else {
        time = `${hour} AM`;
      }
      if (hour === 0 || hour === 24) {
        time = '12 AM';
      }
      if (hour === 12) {
        time = '12 PM';
      }
      hours.push(time);
    }
    return hours;
  }

  getOptionsMap() {
    return this.generateHoursArray().map((i) => ({
      key: i,
      text: i,
      value: i,
    }));
  }

  getLocalizedLongDateFormat() {
    if (moment.localeData(LocaleSingleton.get())) {
      return moment
        .localeData(LocaleSingleton.get())
        ._longDateFormat.L.split('/')
        .join('-');
    } else {
      return moment.localeData()._longDateFormat.L.split('/').join('-');
    }
  }

  _getNowFormatted() {
    return this.date.format(this.getLocalizedLongDateFormat());
  }

  _getDateFormatted() {
    return this.now.format(this.getLocalizedLongDateFormat());
  }

  _getTimeZoneParsed() {
    return moment(this.now.parseZone()).format('HH');
  }
}

DateTools.subtractDaysFromToday = (days, format = INPUT_DATE_FORMAT) => {
  return moment().subtract(days, 'days').format(format);
};

DateTools.getMonthDayFromDate = (date, format = INPUT_DATE_FORMAT) =>
  moment(date, format).format('MMM / DD');

DateTools.utcOffsetFromDate = (date, format = INPUT_DATE_FORMAT) =>
  moment(date, format).utcOffset();

// This should be allowed to be used as a static method
DateTools.getTodaysDate = (format = INPUT_DATE_FORMAT) =>
  moment().format(format);

// This should be allowed to be used as a static method
DateTools.getDateNowAsISOString = () => moment().toISOString();

DateTools.getWeekDayStrings = () => WEEK_DAYS_STRINGS;
// 06/06/06 at 12:00PM or 06/06/06 at 12:00 (it depends on user preferences)
DateTools.getDateWithHours = (date, format = null, locale = BROWSER_LANGUAGE) =>
  `${moment(date, format).locale(locale).format('L')} at ${moment(date, format)
    .locale(locale)
    .format('LT')}`;

DateTools.getUTCDateWithHours = (utcDate, locale = BROWSER_LANGUAGE) =>
  `${moment.utc(utcDate).local().toDate().toLocaleDateString()} ${moment
    .utc(utcDate)
    .local()
    .locale(locale)
    .format('LT')}`;

DateTools.getLocalizedDate = (date, locale = BROWSER_LANGUAGE) =>
  moment(date, INPUT_ISO_FORMAT).locale(locale);

// Interface for https://momentjs.com/docs/#/displaying/from/
// I need to use it as a static function, as I want to have control over when it's "now"
DateTools.getTimeAgo = (start, end) =>
  DateTools.getLocalizedDate(end).from(start);

DateTools.subtractDays = (date, value, unit, format = INPUT_DATE_FORMAT) =>
  moment(date, format).subtract(value, unit).format(format);

DateTools.sumDays = (date, value, unit, format = INPUT_DATE_FORMAT) =>
  moment(date, format).add(value, unit).format(format);

DateTools.humanizeSeconds = (seconds = 0) =>
  moment.duration(seconds, 'seconds').locale(LocaleSingleton.get()).humanize();

/**
 * @param  {object} time1 moment time object
 * @param  {object} time2 moment time object
 * @param  {string} format time format like HH:mm
 */
DateTools.getTimeDiffInHours = (time1, time2, format = INPUT_TIME_FORMAT) =>
  moment.duration(moment(time1, format).diff(moment(time2, format))).hours();

/**
 * @param  {object} time1 moment time object
 * @param  {object} time2 moment time object
 */
DateTools.isTimeAfter = (time1, time2) =>
  moment(time1, INPUT_TIME_FORMAT).isAfter(moment(time2, INPUT_TIME_FORMAT));
/**
 * @param  {object} time1 moment time object
 * @param  {object} time2 moment time object
 */
DateTools.isTimeBefore = (time1, time2) =>
  moment(time1, INPUT_TIME_FORMAT).isBefore(moment(time2, INPUT_TIME_FORMAT));

DateTools.checkIsAfter = (date1, date2) =>
  DateTools.getLocalizedDate(date1).isAfter(date2);
DateTools.getDiffInSeconds = (date1, date2) =>
  DateTools.getLocalizedDate(date1).diff(
    DateTools.getLocalizedDate(date2),
    'seconds'
  );
DateTools.getDiffInDays = (date1, date2) =>
  DateTools.getLocalizedDate(date1).diff(
    DateTools.getLocalizedDate(date2),
    'days'
  );

DateTools.getUTCDate = (date, format = INPUT_DATE_FORMAT) =>
  moment.utc(date, INPUT_DATE_FORMAT).format(format);

DateTools.getDateFromUTCToLocal = ({
  date,
  format = INPUT_DATE_FORMAT,
  hoursFormat = 'LT',
  toLocaleDateString = false,
}) =>
  toLocaleDateString
    ? `${moment(moment.utc(date)).toDate().toLocaleDateString()} ${moment(
        moment.utc(date)
      )
        .local()
        .format(hoursFormat)}`
    : moment(moment.utc(date, format)).local().format(format);

DateTools.getDateFromLocalToUTC = (date, format = INPUT_DATE_FORMAT) =>
  moment(date, format).utc().format(format);

DateTools.getFormatDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format(INPUT_DATE_FORMAT);
  } else {
    return date;
  }
};

DateTools.isValid = (date) => moment(date).isValid();

DateTools.utcOffset = () => moment().utcOffset();

DateTools.isUTCAhead = () => DateTools.utcOffset() < 0;
DateTools.isUTCBehind = () => DateTools.utcOffset() > 0;
DateTools.localFormat = () => moment.localeData().longDateFormat('L');

DateTools.formatToUSLang = (date, fromFormat) => {
  const formatDate = moment(date, fromFormat).format(INPUT_DATE_FORMAT);
  if (moment(formatDate).isValid()) {
    return formatDate;
  } else {
    return date;
  }
};

export { BROWSER_LANGUAGE };
export default DateTools;
