import Immutable from 'immutable';
import { createSelector } from 'reselect';

import filterTemplateSelectedByInterval from '../utils/filterTemplateSelectedByInterval';

const getTemplateList = (state) =>
  state.getIn(['onboardActivity', 'schedule', 'templateSelected']);

/**
 * Returns the template List sort by date
 */
const sortTemplateList = (templateList) => {
  if (!templateList) {
    return Immutable.List();
  }

  return filterTemplateSelectedByInterval(templateList);
};

const selector = createSelector([getTemplateList], sortTemplateList);

export default selector;
