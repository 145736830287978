import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getTenantEntity = (state) => state.getIn(['tenant', 'entity', 'value']);

const getTenantImages = (state) => state.getIn(['tenant', 'images', 'values']);

const getTenantLoading = (state) =>
  state.getIn(['tenant', 'updateTenant', 'isLoading']);

const getTenantImagesLoading = (state) =>
  state.getIn(['tenant', 'uploadTenantImage', 'isLoading']);

const getUIConfig = (tenantEntity) =>
  tenantEntity ? tenantEntity.get('ui_config') : Immutable.Map();

const getTenant = (
  tenantEntity,
  tenantImages,
  tenantLoading,
  tenantImagesLoading
) => {
  if (!tenantEntity) {
    return null;
  }

  if (tenantImages) {
    tenantEntity = tenantEntity.set('images', tenantImages);
  } else {
    tenantEntity = tenantEntity.set('images', Immutable.List());
  }

  return tenantEntity
    .set('tenantIsLoading', Boolean(tenantLoading))
    .set('tenantImageIsLoading', Boolean(tenantImagesLoading));
};

const selector = createSelector(
  [getTenantEntity, getTenantImages, getTenantLoading, getTenantImagesLoading],
  getTenant
);

const getTenantUIConfig = createSelector([getTenantEntity], getUIConfig);
export { getTenantEntity, getTenantUIConfig };
export default selector;
