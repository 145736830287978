import { handleDownloadAttachment } from '../handlers';

const attachmentEval = (msg) => Boolean(msg?.type === 'attachment');

const attachment = {
  evaluate: (msg) => attachmentEval(msg),
  transform: {
    handleDownloadAttachment,
  },
};

const attachmentEvals = {
  attachment: attachmentEval,
};

export { attachmentEvals };
export default attachment;
