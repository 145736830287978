import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import { Form, Input } from 'semantic-ui-react';

// Organisms
import DepartmentSelectField from '../../organisms/DepartmentSelectField';

// Global
import TypeSelectDepartment, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectDepartment';

import injectIntlToController from '../../../utils/injectIntlToController';
// Controller
import SelectDepartment01Controller from '../../controllers/SelectDepartment01Controller';

let SelectDepartment01 = class SelectDepartment01 extends Component {
  static propTypes = {
    ...TypeSelectDepartment,
    /** Department selected from the Frontend scratch */
    departmentSelected: ImmutablePropTypes.map,
    /** Block for existing user */
    existingUser: PropTypes.bool,
    /** Input is required */
    inputRequired: PropTypes.bool,
    /** React intl */
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    /** Load department by user */
    loadDepartment: PropTypes.func.isRequired,
    /** Requested for department ID */
    requestedForDepartment: PropTypes.number,
    /** User ID requested For */
    requestedForID: PropTypes.number,
  };

  static defaultProps = {
    ...TypeDefaults,
    departmentSelected: null,
    existingUser: false,
    inputRequired: false,
    requestedForDepartment: null,
    requestedForID: null,
  };

  componentDidMount() {
    const {
      departmentSelected,
      existingUser,
      loadDepartment,
      requestedForDepartment,
      requestedForID,
      change,
    } = this.props;

    if (existingUser && requestedForID) {
      loadDepartment(requestedForID);
    }

    if (departmentSelected && !departmentSelected.isEmpty()) {
      change('department', departmentSelected.get('id'));
    } else if (requestedForDepartment) {
      change('department', requestedForDepartment);
    }
  }

  handleSelectDepartment = (value) => {
    const { change, existingUser, requestedForID } = this.props;

    const newId = value ? value.get('id') : null;
    const newName = value ? value.get('name') : null;

    const updateScratch = true; // Update the frontend Scratch here
    const userID = existingUser ? requestedForID : null; // Pass User ID if user exist

    change('department', newId, newName, updateScratch, userID);
  };

  validate = (val) => {
    const { inputRequired } = this.props;

    if (!val && inputRequired) {
      return 'error';
    }
    return '';
  };

  render() {
    const {
      type,
      template,
      readOnly,
      intl,
      departmentSelected,
      requestedForDepartment,
    } = this.props;

    const defaultDepartment =
      departmentSelected &&
      !departmentSelected.isEmpty() &&
      departmentSelected.get('id')
        ? departmentSelected.get('id')
        : requestedForDepartment;

    const nameDepartment =
      departmentSelected &&
      !departmentSelected.isEmpty() &&
      departmentSelected.get('name')
        ? departmentSelected.get('name')
        : 'Not set yet';

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: 'label.department',
            })}
          </label>
          {readOnly && <Input readOnly transparent value={nameDepartment} />}

          {!readOnly && (
            <Field
              component={DepartmentSelectField}
              defaultSelectedNodeID={defaultDepartment}
              key={defaultDepartment}
              name='department'
              onSelectDepartment={this.handleSelectDepartment}
              placeholder='All'
              validate={this.validate}
            />
          )}
        </Form.Field>
      </div>
    );
  }
};

const SelectDepartment01Test = SelectDepartment01;

SelectDepartment01 = SelectDepartment01Controller(SelectDepartment01);

export { SelectDepartment01Test };
export default injectIntlToController(SelectDepartment01);
