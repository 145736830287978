import { useCallback } from 'react';

const useScheduleAccordion = (activeIndex, setActiveIndex) => {
  const handleAccordionClick = useCallback(
    (e, { index }) => {
      const newIndex = activeIndex === index ? -1 : index;

      setActiveIndex(newIndex);
    },
    [activeIndex, setActiveIndex]
  );
  const departingEmployeeRecipientsIsActive = activeIndex === 0;
  const administrativeRecipientsIsActive = activeIndex === 1;
  const ActivitiesIsActive = activeIndex === 2;

  return {
    ActivitiesIsActive,
    administrativeRecipientsIsActive,
    departingEmployeeRecipientsIsActive,
    handleAccordionClick,
  };
};

export default useScheduleAccordion;
