import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsOverdue extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M48 4C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4zm-.15 82.23c-3.92-.022-7.085-3.215-7.07-7.137.015-3.92 3.202-7.09 7.123-7.083 3.922.007 7.097 3.188 7.097 7.11 0 1.893-.755 3.707-2.097 5.042-1.342 1.334-3.16 2.08-5.053 2.068zM56.13 21L48 63.07 39.87 21c-.922-3.42.396-7.052 3.297-9.086 2.9-2.033 6.765-2.033 9.666 0 2.9 2.034 4.22 5.665 3.297 9.086z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CardsOverdue;
