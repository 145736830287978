const api_workflow_v0_1_workflowtask_173 = {
  actions: null,
  attributes: null,
  eid: '4cc41a75-09f1-4b08-8985-824643b48c41',
  esp_objects: null,
  id: 44,
  images: [],
  methods: null,
  name: 'New hire - Barista welcome',
  post_condition_group: null,
  pre_condition_group: null,
  related_workflow: null,
  related_workflow_condition: null,
  tags: '',
  url: 'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/44/',
};

export default api_workflow_v0_1_workflowtask_173;
