import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import { curry, debounce, memoize, noop } from 'lodash';

// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Molecule
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import FormInputSelect from './FormInputSelect';
import FormSelectUserInput from './FormSelectUserInput';
import FormInputText from './FormInputText';
import FormInputTextarea from './FormInputTextarea';

// Controller
import ServiceDepartmentCreateTeamModalController from '../controllers/ServiceDepartmentCreateTeamModalController';
import ServiceDepartmentClassifiedTeamDropdown from './ServiceDepartmentClassifiedTeamDropdown';
import FormInputCheckbox from './FormInputCheckbox';

const SEARCH_DELAY = 300;

const arrayFieldName = [
  'display_name',
  'description',
  'service_department',
  'technical_contact',
];

class ServiceDepartmentCreateTeamModal extends PureComponent {
  static propTypes = {
    /** Whatever can happen after a service team is created */
    afterSaveNewTeam: PropTypes.func,
    change: PropTypes.func,
    /** Save a New Department */
    createNewTeam: PropTypes.func.isRequired,

    /** If you want the form to pre populate a description */
    defaultDescription: PropTypes.string,
    /** If you want the form to pre populate a manager (by snow sys_id). We'll look up it his espressive id */
    defaultManagerSysId: PropTypes.string,
    /** If you want the form to pre populate a name */
    defaultTeamName: PropTypes.string,

    /** Department ID */

    departmentId: PropTypes.string,

    /** Department classification list */
    departmentList: ImmutableProps.list,
    /** Department name selected */
    departmentSelected: PropTypes.string,
    /** Form error from redux form */
    formError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    /** Form Validation */
    formValidation: PropTypes.func.isRequired,
    formValues: ImmutableProps.map,
    /** To load departments on import */
    getServiceDepartmentClassification: PropTypes.func,
    /** True if this is to create an imported team */
    importServiceTeam: PropTypes.bool,
    /* redux-form selector */
    isDirty: PropTypes.bool,
    /** Main loading state */
    isLoading: PropTypes.bool,
    /** Is Searching state */
    isLoadingSearch: PropTypes.bool,
    /** Anything that may happen when closing */
    onClose: PropTypes.func,
    /** if controlled opened state */
    open: PropTypes.bool,
    /** Luanch the user search */
    searchUsers: PropTypes.func.isRequired,

    /** Set default manager in the form */

    setDefaultManager: PropTypes.func.isRequired,

    /** Set search users input */
    setSearchInputUsers: PropTypes.func.isRequired,
    /** Modal size */
    size: PropTypes.string,

    /** Team leader selected */
    teamLeadSelected: ImmutableProps.map,
    /** Immutable Map of the team selected */
    teamSelected: ImmutableProps.map,
    /** Modal trigger */
    trigger: PropTypes.node.isRequired,
    /** Update a Team */
    updateTeam: PropTypes.func.isRequired,
    /** User list search */
    usersSearch: ImmutableProps.list,
    /** User srarch term input */
    usersSearchTerm: PropTypes.string,
  };

  static defaultProps = {
    afterSaveNewTeam: noop,
    change: noop,
    defaultDescription: '',
    defaultManagerSysId: '',
    defaultTeamName: '',
    departmentId: '',
    departmentList: immutable.List(),
    departmentSelected: '',
    formError: null,
    formValues: null,
    getServiceDepartmentClassification: noop,
    importServiceTeam: false,
    isDirty: false,
    isLoading: false,
    isLoadingSearch: false,
    onClose: noop,
    open: false,
    size: 'small',
    teamLeadSelected: null,
    teamSelected: null,
    usersSearch: immutable.List(),
    usersSearchTerm: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      classification: '',
      description: '',
      open: props.open,
      team_lead: '',
      team_name: '',
    };
  }

  componentDidMount() {
    const {
      defaultManagerSysId,
      importServiceTeam,
      getServiceDepartmentClassification,
      setDefaultManager,
    } = this.props;

    if (importServiceTeam) {
      getServiceDepartmentClassification();
    }

    if (defaultManagerSysId) {
      setDefaultManager(defaultManagerSysId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = this.state;
    let updateState = false;

    if (nextProps.formError) {
      // Compare formError and current State
      arrayFieldName.forEach((fieldName) => {
        if (!nextProps.formError[fieldName] && newState[fieldName]) {
          newState[fieldName] = '';
          updateState = true;
        }
      });
    }

    if (updateState) {
      // We have to update the state
      this.setState(newState);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { usersSearchTerm, searchUsers } = this.props;
    if (
      nextProps.usersSearchTerm &&
      nextProps.usersSearchTerm !== usersSearchTerm
    ) {
      searchUsers();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Avoid setState on unMounted component
  // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
  _isMounted = true;

  handleSearchChange = debounce((e, value) => {
    const { setSearchInputUsers } = this.props;
    setSearchInputUsers(value.searchQuery);
  }, SEARCH_DELAY);

  handleClose = (e) => {
    const { onClose } = this.props;
    e.preventDefault();
    this.setState({
      open: false,
    });
    onClose();
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleSubmitAndClose = (data) => {
    const {
      afterSaveNewTeam,
      createNewTeam,
      updateTeam,
      departmentId,
      teamSelected,
      importServiceTeam,
    } = this.props;

    const msg = {
      header: 'Success',
      message: `The Department ${data.get('team_name')} has been created`,
    };

    const departmentIdToCreate = importServiceTeam
      ? data.get('service_department')
      : departmentId;

    if (teamSelected && !teamSelected.isEmpty()) {
      msg.message = `The Department ${data.get('team_name')} has been updated`;
      updateTeam(data, departmentId, teamSelected.get('id'), msg, () => {
        if (this._isMounted) {
          this.setState({
            open: false,
          });
        }
      });
    } else {
      createNewTeam(data, departmentIdToCreate, msg, (newTeam) => {
        if (this._isMounted) {
          this.setState({
            open: false,
          });
        }

        afterSaveNewTeam(newTeam);
      });
    }
  };

  handleOnBlur = memoize(
    curry((name, e) => {
      e.preventDefault();

      const { formError } = this.props;
      const { state } = this;

      state[name] = formError && formError[name] ? formError[name] : '';

      this.setState(state);
    })
  );

  trimValue = memoize(
    curry((type, value, e) => {
      const maxChars = type === 'team' ? 128 : 5000; // Chars limitationsfor each field
      return value.substring(0, maxChars);
    })
  );

  handleServiceTeamClassifficationChange = (name, value) => {
    const { change } = this.props;
    change(name, value ? value : null);
  };

  render() {
    const {
      defaultDescription,
      defaultTeamName,
      departmentList,
      departmentSelected,
      formError,
      formValidation,
      importServiceTeam,
      isDirty,
      isLoading,
      isLoadingSearch,

      teamLeadSelected,
      teamSelected,
      usersSearch,
      size,
      trigger,
      formValues,
    } = this.props;

    let initialValues;
    let contentButton = 'Create';
    let modalTitle = `New ${departmentSelected} Service Team`;

    if (teamSelected && !teamSelected.isEmpty()) {
      initialValues = {
        classification: teamSelected.get('classification'),
        description: teamSelected.get('description'),
        suppress_notification: teamSelected.get('suppress_notification'),
        team_lead: teamSelected.get('team_lead'),
        team_name: teamSelected.get('name'),
      };
      contentButton = 'Update';
      modalTitle = `${departmentSelected} Service Team`;
    }

    const optionDepartment = [];
    if (importServiceTeam) {
      // Populate departments
      departmentList.forEach((entry) => {
        optionDepartment.push({
          key: entry.get('id'),
          text: entry.get('display_name'),
          value: entry.get('id'),
        });
      });

      // set default initial value for name
      initialValues = {
        description: defaultDescription,
        team_lead: teamLeadSelected ? teamLeadSelected.get('id') : void 0,
        team_name: defaultTeamName,
      };
    }

    const { team_name, team_lead, description, open } = this.state;

    const closeEditProfileTrigger = <div className='item close'>{'Close'}</div>;

    return (
      <Modal
        className='scrolling'
        closeIcon={closeEditProfileTrigger}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={open}
        size={size}
        trigger={trigger}
      >
        <ValidatedForm
          enableReinitialize
          form={'ServiceDepartmentTeamCreate'}
          initialValues={initialValues}
          onSubmit={this.handleSubmitAndClose}
          validate={formValidation}
        >
          <Modal.Header content={modalTitle} />

          <Modal.Content className='withFooter'>
            <ScrollArea>
              {importServiceTeam ? (
                <ValidatedField
                  component={FormInputSelect}
                  formError={formError && formError.service_department}
                  label='Service Department'
                  loading={false}
                  name='service_department'
                  onBlur={this.handleOnBlur('service_department')}
                  options={optionDepartment}
                  placeholder='Select one'
                  selectOnBlur
                />
              ) : null}

              <ServiceDepartmentClassifiedTeamDropdown
                departmentName={departmentSelected}
                handleSelection={this.handleServiceTeamClassifficationChange}
                name='classification'
                value={formValues?.get('classification')}
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && formError.team_name && team_name}
                label='Team Name'
                name='team_name'
                normalize={this.trimValue('team')}
                onBlur={this.handleOnBlur('team_name')}
                placeholder='Type a name'
                selectOnBlur
              />

              <ValidatedField
                component={FormSelectUserInput}
                formError={formError && formError.team_lead && team_lead}
                label='Team Lead'
                loading={isLoadingSearch}
                name='team_lead'
                onBlur={this.handleOnBlur('team_lead')}
                onSearchChange={this.handleSearchChange}
                placeholder='Search by name'
                search
                selectOnBlur
                selectedUser={teamLeadSelected}
                userList={usersSearch}
              />

              <ValidatedField
                component={FormInputTextarea}
                formError={formError && formError.description && description}
                label='Description the type of issues handled by this team'
                name='description'
                normalize={this.trimValue('description')}
                onBlur={this.handleOnBlur('description')}
                placeholder='Eg: general questions related to work life, rules of conduct, personnel issues​'
                rows={3}
                selectOnBlur
              />

              <div style={{ margin: '1em 0' }}>
                <em
                  style={{
                    fontSize: '.875em',
                    opacity: 0.9,
                  }}
                >
                  {
                    'This description will be shown to a user when Barista does not understand the intent and must​ ask the user to select the right team.'
                  }
                </em>
              </div>

              <ValidatedField
                component={FormInputCheckbox}
                label='Suppress Notification to Employee'
                name='suppress_notification'
                toggle
              />
            </ScrollArea>
          </Modal.Content>

          <Modal.Actions>
            <Button
              basic
              content='Cancel'
              disabled={isLoading}
              onClick={this.handleClose}
            />

            <Button
              content={contentButton}
              disabled={!isDirty || Boolean(formError) || isLoading}
              loading={isLoading || isLoadingSearch}
              primary
            />
          </Modal.Actions>
        </ValidatedForm>
      </Modal>
    );
  }
}

const ServiceDepartmentCreateTeamModalTest = ServiceDepartmentCreateTeamModal;

export { ServiceDepartmentCreateTeamModalTest };
export default ServiceDepartmentCreateTeamModalController(
  ServiceDepartmentCreateTeamModal
);
