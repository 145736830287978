import moment from 'moment';
import LocaleSingleton from '../v1/utils/LocaleSingleton';

const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
const INPUT_TIME_FORMAT = 'HH:mm';
// according to https://262.ecma-international.org/6.0/#sec-date.prototype.toisostring
const INPUT_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';
const BE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DATE_TYPE = 'date';
// const TIME_TYPE = 'time';

const parseDateAndTimeToMoment = (date, time, locale = LocaleSingleton.get()) =>
  moment(`${date} ${time}`, `${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`).locale(
    locale
  );

const getBEFormattedCompleteDate = (
  date,
  BE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
) =>
  date
    ? moment
        .utc(date, BE_DATE_FORMAT)
        .local()
        .locale(LocaleSingleton.get())
        .format(`${INPUT_DATE_FORMAT} ${INPUT_TIME_FORMAT}`)
    : void 0;

const getBEFormattedDate = (date, type = DATE_TYPE, BE_DATE_FORMAT) =>
  date
    ? moment
        .utc(date, BE_DATE_FORMAT)
        .local()
        .locale(LocaleSingleton.get())
        .format(type === DATE_TYPE ? INPUT_DATE_FORMAT : INPUT_TIME_FORMAT)
    : void 0;

const getISOFormattedDate = (date, type = DATE_TYPE) =>
  date
    ? moment(date)
        .locale(LocaleSingleton.get())
        .format(type === DATE_TYPE ? INPUT_DATE_FORMAT : INPUT_TIME_FORMAT)
    : void 0;

const DefaultInputFormats = {
  INPUT_DATE_FORMAT,
  INPUT_TIME_FORMAT,
};
export {
  parseDateAndTimeToMoment,
  getBEFormattedCompleteDate,
  getBEFormattedDate,
  getISOFormattedDate,
  INPUT_DATE_FORMAT,
  INPUT_TIME_FORMAT,
  INPUT_ISO_FORMAT,
  BE_DATE_FORMAT,
};
export default DefaultInputFormats;
