import { FieldArray, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import EmailAddressRuleLiterals, {
  RegexTokens,
} from '../../globals/EmailAddressRuleLiterals';

import EmailAddressRulesFieldArray from './EmailAddressRulesFieldArray';

const validRuleTokens = [
  RegexTokens.NICKNAME,
  RegexTokens.FIRST_NAME,
  RegexTokens.LAST_NAME,
  RegexTokens.SEQ,
  RegexTokens.RAND,
  RegexTokens.DOT,
  RegexTokens.LODASH,
];

const oredValidRuleTokens = validRuleTokens.join('|');

const maybeValidRuleRegex = new RegExp(`^(${oredValidRuleTokens})+$`);

const validate = (values) => {
  const errors = {
    addressRules: [],
  };

  const addressRules = values.get('addressRules');

  addressRules.forEach((rule, i) => {
    if (!rule || _.isEmpty(rule.trim())) {
      errors.addressRules[i] = 'Required';
    } else {
      // leading/trailing whitespace is allowed
      rule = rule.trim();

      if (maybeValidRuleRegex.test(rule)) {
        // maybe a valid rule, needs additional checks

        // shouldn't have leading/trailing "_" or "."
        const invalidLeadingTrailing =
          rule.startsWith(EmailAddressRuleLiterals.DOT) ||
          rule.startsWith(EmailAddressRuleLiterals.LODASH) ||
          rule.endsWith(EmailAddressRuleLiterals.DOT) ||
          rule.endsWith(EmailAddressRuleLiterals.LODASH);

        if (invalidLeadingTrailing) {
          errors.addressRules[i] = 'Can\'t start or end with "_" or "."';
          return;
        }

        const nicknameRegex = new RegExp(RegexTokens.NICKNAME);
        const firstNameRegex = new RegExp(RegexTokens.FIRST_NAME);
        const lastNameRegex = new RegExp(RegexTokens.LAST_NAME);

        // should contain at least one variable from {{nickname}}, {{first_name}} or {{last_name}}
        const hasVariables =
          nicknameRegex.test(rule) ||
          firstNameRegex.test(rule) ||
          lastNameRegex.test(rule);

        if (!hasVariables) {
          errors.addressRules[i] =
            'Must contain at least one from "{{nickname}}", "{{first_name}}" or "{{last_name}}"';
        }
      } else {
        // definitely not a valid rule
        errors.addressRules[i] = 'Must contain only available tags';
      }
    }
  });

  return errors;
};

class EmailAddressRulesForm extends PureComponent {
  static propTypes = {
    // from Redux Form
    handleSubmit: PropTypes.func,

    isLoading: PropTypes.bool,

    // from Redux Form
    pristine: PropTypes.bool,
  };

  static defaultProps = {
    handleSubmit: _.noop,

    isLoading: false,

    pristine: false,
  };

  render() {
    const { handleSubmit, isLoading, pristine } = this.props;

    return (
      <FieldArray
        component={EmailAddressRulesFieldArray}
        isLoading={isLoading}
        isSaveButtonDisabled={pristine}
        name='addressRules'
        onSubmit={handleSubmit}
      />
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
EmailAddressRulesForm = reduxForm({
  validate,
})(EmailAddressRulesForm);

export default EmailAddressRulesForm;
