import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  OTPisLoading: false,

  attributes: {},
  /**
   * This is used to track the progress of the current task (if any).
   * Should have following structure:
   *
   * If 'taskProgress' is present, it's used in combination with 'progress' to
   * smoothly calculate workflow progress.
   *
   * taskProgress: {
   *   stepsCompleted: Number,
   *
   *   stepsCount: Number,
   * }
   */
  // TODO would be cool to have a DefaultBlob in a separate file and use it here
  backendScratch: {},
  blob: {
    blocks: [
      {
        id: 0,
        template: 'loading01',
        type: 'loading',
      },
    ],
    nav: {
      espressiveLogo: false,
      footer: {
        hide: true,
        next: '',
        skip: '',
      },
      progress: false,
      toolbar: {
        hide: true,
        next: '',
        prev: '',
        title: '',
      },
    },
  },

  blurField: null,

  // string value of the latest blurred field on WF
  eidToID: {},

  errors: {},

  frontendIntl: {},

  frontendScratch: {},

  loading: false,
  overLoading: false,

  // TODO I think we really don't need this one
  payload: {},

  progress: {
    max_steps: 0,
    steps_completed: 0,
  },
  // TODO I think we can make this more plain
  sessionId: {
    code: null,
    onboardID: null,
    ref: null,
    setEntryDone: false,
    userAssignedTo: null,
    userRequestedFor: null,
    workflowID: null,
    workflowName: null,
    workflowRequestID: null,
    workflowTaskID: null,
    workflowTaskName: null,
  },
  step: 1,
  taskProgress: null,
});

const onResumeWorkflowSuccess = (state, action) => {
  state = state
    .set('loading', false)
    .set('overLoading', false)
    .setIn(['sessionId', 'workflowID'], action.workflowID)
    .setIn(['sessionId', 'userRequestedFor'], action.userRequestedFor)
    .setIn(['sessionId', 'userAssignedTo'], action.userAssignedTo)
    .setIn(['sessionId', 'workflowTaskID'], action.workflowTaskID)
    .setIn(['sessionId', 'workflowRequestID'], action.workflowRequestID)
    .setIn(['sessionId', 'workflowName'], action.workflowName)
    .setIn(['sessionId', 'workflowTaskName'], action.workflowTaskName)
    .setIn(['sessionId', 'setEntryDone'], false)
    .set('errors', fromJS({}))
    .set('progress', fromJS(action.progress))
    // reset task progress
    .set('taskProgress', INITIAL_STATE.get('taskProgress'))
    .set('frontendScratch', fromJS(action.frontendScratch))
    .set('backendScratch', fromJS(action.backendScratch))
    .set('frontendIntl', fromJS(action.frontendIntl));

  // heads up! If we were in the last task of a workflow, next time this gets called both 'attributes' and 'blob'
  // are going to be null, we will keep the previous ones in that case
  if (action.workflowTaskID !== null) {
    state = state.set('attributes', fromJS(action.attributes));
  }

  if (action.workflowTaskID !== null) {
    state = state.set('blob', fromJS(action.blob));
  }

  return state;
};

const onResumeWorkflowFail = (state, action) => {
  const fallbackFrontendBlob = fromJS(action.fallbackFrontendBlob);
  state = state
    .set('blob', fallbackFrontendBlob)
    .setIn(['sessionId', 'setEntryDone'], false)
    .set('loading', false);

  return state;
};

const handleAddWorkflowEIDToIDMapping = (state, action) => {
  let eidToID = state.get('eidToID');

  eidToID = eidToID.set(action.workflowEID, action.workflowID);

  state = state.set('eidToID', eidToID);

  return state;
};

/**
 * Main Reducer which fire action thought the dispatch switch
 * @param state
 * @param action
 * @returns {*}
 */
const workflowReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.WF_UPDATE_ATTRIBUTES_FROM_BESCRATCH: {
      const beScratch = state.getIn(['backendScratch', 'scratch.temp_data']);
      const attributesToMap = state.get('attributes');

      const newAttributesToMap = attributesToMap.map((att) => {
        const name = att.get('name');
        if (beScratch.get(name)) {
          return att.set('attribute_value', beScratch.get(name));
        }
        return att;
      });

      return state.set('attributes', newAttributesToMap);
    }

    case actionTypes.SET_ENTRY_WORKFLOW_SUCCESS: {
      return state.setIn(['sessionId', 'setEntryDone'], true);
    }

    case actionTypes.RESUME_WORKFLOW_SUCCESS:
      return onResumeWorkflowSuccess(state, action);

    case actionTypes.RESUME_WORKFLOW_FAIL:
      return onResumeWorkflowFail(state, action);

    case actionTypes.REPORT_WORKFLOW_TASK_PROGRESS:
      return state.set(
        'taskProgress',
        fromJS({
          stepsCompleted: action.stepsCompleted,
          stepsCount: action.stepsCount,
        })
      );

    /** * Legacy actions ***/
    case actionTypes.WK_LOADING:
      return state.set('loading', true);

    case actionTypes.CREATE_NEW_USER_START:
      return state.set('loading', true);

    case actionTypes.CREATE_NEW_USER_SUCCESS:
      return state.set('loading', false);

    case actionTypes.CREATE_NEW_USER_FAIL:
      return state.set('loading', false);

    case actionTypes.CHANGE_START_DATE_USER_START:
      return state.set('loading', true);

    case actionTypes.CHANGE_START_DATE_USER_SUCCESS:
      return state.set('loading', false);

    case actionTypes.CHANGE_START_DATE_USER_FAIL:
      return state.set('loading', false);

    case actionTypes.WK_EXIT_LOADING:
      return state.set('loading', false);

    case actionTypes.WK_OVERLOADING:
      return INITIAL_STATE.set('loading', true).set('overLoading', true);

    case actionTypes.ADD_SELECTED_BUNDLE:
      return state.set('loading', false);

    // Note - This Action doesn't exists at all - I commented it for now
    // case actionTypes.GO_NEXT_SUCCESS :
    //   return state.set('loading', false);

    case actionTypes.WK_SAVE_SCRATCH_START:
      return state.set('loading', true);

    case actionTypes.WK_SAVE_SCRATCH_SUCESS:
      return state
        .set('frontendScratch', fromJS(action.data))
        .set('loading', false);

    case actionTypes.WK_SAVE_BE_SCRATCH_START:
      return state.set('loading', true);

    case actionTypes.WK_SAVE_BE_SCRATCH_SUCCESS:
      return state
        .set('backendScratch', fromJS(action.data))
        .set('loading', false);

    case actionTypes.WK_LOGIN_DONE:
      return (
        state
          .set('loading', false)

          // TODO I think we really don't need this one
          .set('payload', action.payload)

          // TODO I think we can make this more plain
          .setIn(['sessionId', 'workflowID'], action.workflowID)
          .setIn(['sessionId', 'onboardID'], action.onboardID)
          .setIn(['sessionId', 'ref'], action.ref)
          .setIn(['sessionId', 'code'], action.code)

          .set('blob', fromJS(action.payload.frontend_blob))
          .set('frontendIntl', fromJS(action.payload.frontend_intl))
          .set('attributes', fromJS(action.payload.attributes))
      );

    case actionTypes.WK_SET_ERRORS: {
      const error = action.errors ? action.errors : {};
      return state.set('errors', fromJS(error)).set('loading', false);
    }
    case actionTypes.ADD_WORKFLOW_EID_TO_ID_MAPPING:
      return handleAddWorkflowEIDToIDMapping(state, action);

    case actionTypes.OTP_START:
      return state.set('loading', true).set('OTPisLoading', true);

    case actionTypes.OTP_SUCCESS:
      return state.set('OPTisLoading', false);

    case actionTypes.OTP_ERROR:
      return state.set('loading', false).set('OPTisLoading', false);

    case actionTypes.UPDATE_WORKFLOW_REQUESTED_FOR_USER_SUCCESS:
      return state.set('loading', false);

    case actionTypes.CHANGE_DEPARTMENT_USER_START:
      return state.set('loading', true);

    case actionTypes.CHANGE_DEPARTMENT_USER_FAIL:
      return state.set('loading', false);

    case actionTypes.CHANGE_DEPARTMENT_USER_SUCCESS:
      return state.set('loading', false);

    case actionTypes.GET_USER_DEPARTMENT_ID_START:
      return state.set('loading', true);

    case actionTypes.GET_USER_DEPARTMENT_ID_FAIL:
      return state.set('loading', false);

    case actionTypes.GET_USER_DEPARTMENT_ID_SUCCESS:
      return state.set('loading', false);

    case actionTypes.WF_ON_BLUR_FIELD:
      return state.set('blurField', action.id);

    default:
      return state;
  }
};
export { INITIAL_STATE };
export default workflowReducer;
