import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';

import TypeButton, { TypeDefaults } from './TypeButton';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const rule = () => ({});

class NextButton01 extends PureComponent {
  static propTypes = {
    ...TypeButton,
  };

  static defaultProps = TypeDefaults;

  render() {
    const { className, type, template, text, to } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        <Button as={Link} to={to}>
          {text}
        </Button>
      </div>
    );
  }
}

export default createComponent(rule, NextButton01, [
  'text',
  'template',
  'type',
  'id',
  'trigger',
]);
