import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureChairs extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path
            d='M66.92 16.79c-.592-.322-1.29-.39-1.934-.19-.643.198-1.18.65-1.486 1.25-6.514 12.504-10.38 26.215-11.36 40.28H34.05c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h9.81v10.43c-9.27.15-10.19 5-10.23 5.17-.08.53.274 1.03.8 1.13h.18c.483.01.904-.327 1-.8 0 0 .82-3.39 8.26-3.52v3.94c0 .552.448 1 1 1s1-.448 1-1v-3.91c6.92.26 7.75 3.4 7.78 3.5.088.48.51.828 1 .82h.16c.544-.092.91-.606.82-1.15 0-.2-.93-4.84-9.75-5.15V63.13h8.69c1.377-.006 2.49-1.123 2.49-2.5.627-14.128 4.355-27.945 10.92-40.47.637-1.223.162-2.73-1.06-3.37z'
            fill='#F00'
            fillRule='nonzero'
          />
          <path
            d='M17.5 44.48h3.93'
            stroke='#FFF'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    );
  }
}

export default CatalogFurnitureChairs;
