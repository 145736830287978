import buildCompositeController from '../../utils/buildCompositeController';

// Selections
import getCurrentUser from '../../selectors/getCurrentUser';
import { getSelectedChannelByUser } from '../../selectors/getChannelsSorted';
// Actions
import chatThunks from '../../actions/chatThunks';
import socializeThunks from '../../actions/socializeThunks';

const mapStateToProps = (state, ownProps) => ({
  currentUser: getCurrentUser(state),
  selectedChannel: getSelectedChannelByUser(state, ownProps.withUserId),
  wsStatus: state.get('ws'),
});

const mapDispatchToProps = (dispatch) => ({
  loadChannelForUserId: (userId) => {
    dispatch(socializeThunks.loadDirectChannelForUserId(userId));
  },

  loadMessages: (channel) => {
    const channelID = channel.get('id');
    const channelType = channel.get('type');

    dispatch(chatThunks.loadMessages(channelID, channelType));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
