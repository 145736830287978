import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Divider, Message, Modal } from 'semantic-ui-react';
import { curry, memoize } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Atoms
import ScrollArea from '../atoms/ScrollArea';
// Molecule
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import ValidatedPassword from './ValidatedPassword';
import FormInputSelect from './FormInputSelect';
import FormInputCheckbox from './FormInputCheckbox';
import FormInputText from './FormInputText';
// Utils
import injectIntlToController from '../../utils/injectIntlToController';
// Controller
import OktaAccountCreateModalController from '../controllers/OktaAccountCreateModalController';

const arrayFieldName = [
  'company_name',
  'okta_subdomain',
  'admin_firstname',
  'admin_lastname',
  'admin_email',
  'admin_password',
  'confirm_password',
  'security_question',
  'security_answer',
];

class OktaAccountCreateModal extends PureComponent {
  static propTypes = {
    /** Is SAML disabled */
    disabledSAML: PropTypes.bool,
    /** Api error during tenant creation on Okta */
    errorsApi: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        message: PropTypes.string,
        parameterName: PropTypes.string,
        reason: PropTypes.string,
      })
    ),
    /** Form error from redux form */
    formError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    /** Form Validation */
    formValidation: PropTypes.func.isRequired,
    getOktaAccount: PropTypes.func.isRequired,
    /** Main loading state */
    isLoading: PropTypes.bool,
    /** Reset Okta reducer errors */
    resetOktaErrors: PropTypes.func.isRequired,
    /** Submit handler */
    setOktaTenant: PropTypes.func.isRequired,
    /** Modal size */
    size: PropTypes.string,
    /** Modal trigger */
    trigger: PropTypes.node.isRequired,
  };

  static defaultProps = {
    disabledSAML: false,
    errorsApi: null,
    formError: null,
    isLoading: false,
    size: 'small',
  };

  state = {
    admin_email: '',
    admin_firstname: '',
    admin_lastname: '',
    company_name: '',
    okta_subdomain: '',
    open: false,
    scrollBottom: false,
    security_answer: '',
    security_question: '',
  };

  componentDidMount() {
    const { resetOktaErrors } = this.props;

    resetOktaErrors();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = this.state;
    let updateState = false;

    if (nextProps.formError) {
      // Compare formError and current State
      arrayFieldName.forEach((fieldName) => {
        // eslint-disable-next-line react/destructuring-assignment -- risky to fix
        if (!nextProps.formError[fieldName] && this.state[fieldName]) {
          newState[fieldName] = '';
          updateState = true;
        }
      });
    }

    if (nextProps.errorsApi && nextProps.errorsApi.getIn([0, 'message'])) {
      newState.scrollBottom = true;
      updateState = true;
    }

    if (updateState) {
      // We have to update the state
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Avoid setState on unMounted component
  // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
  _isMounted = true;

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleSubmitAndClose = (data) => {
    const { getOktaAccount, setOktaTenant } = this.props;

    const msg = {
      header: 'Success',
      message: 'The Okta account has been set',
    };

    setOktaTenant(data, msg, () => {
      if (this._isMounted) {
        this.setState({
          open: false,
        });
        getOktaAccount();
      }
    });
  };

  handleOnBlur = memoize(
    curry((name, e) => {
      e.preventDefault();

      const { formError } = this.props;
      const { state } = this;
      state[name] = formError && formError[name] ? formError[name] : '';

      this.setState(state);
    })
  );

  handleDismiss = () => {
    const { resetOktaErrors } = this.props;

    resetOktaErrors();
    this.setState({
      scrollBottom: false,
    });
  };

  render() {
    const {
      errorsApi,
      formError,
      isLoading,
      formValidation,
      disabledSAML,
      size,
      trigger,
    } = this.props;

    const optionQuestions = [
      {
        key: 1,
        text: "Your mother's maiden name",
        value: "Your mother's maiden name",
      },
      {
        key: 2,
        text: 'Name of your first pet',
        value: 'Name of your first pet',
      },
      {
        key: 3,
        text: 'Model of your first car',
        value: 'Model of your first car',
      },
      {
        key: 4,
        text: 'City where you went to grade school',
        value: 'City where you went to grade school',
      },
      {
        key: 5,
        text: 'Favorite sports team',
        value: 'Favorite sports team',
      },
    ];

    const {
      company_name,
      okta_subdomain,
      admin_firstname,
      admin_lastname,
      admin_email,
      security_question,
      security_answer,
      scrollBottom,
      open,
    } = this.state;

    const closeEditProfileTrigger = <div className='item close'>{'Close'}</div>;

    const initialValues = {
      enable_saml: true,
    };

    return (
      <Modal
        className='scrolling'
        closeIcon={closeEditProfileTrigger}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={open}
        size={size}
        trigger={trigger}
      >
        <ValidatedForm
          enableReinitialize
          form={'OktaSetupCustomer'}
          initialValues={initialValues}
          onSubmit={this.handleSubmitAndClose}
          validate={formValidation}
        >
          <Modal.Header content='New Okta Tenant' />

          <Modal.Content>
            <ScrollArea scrollBottom={scrollBottom}>
              <ValidatedField
                component={FormInputText}
                formError={formError && company_name}
                label='Company Name'
                name='company_name'
                onBlur={this.handleOnBlur('company_name')}
                placeholder='Enter a company name'
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && okta_subdomain}
                label='Okta subdomain'
                name='okta_subdomain'
                onBlur={this.handleOnBlur('okta_subdomain')}
                placeholder='Enter a subdomain'
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && admin_firstname}
                label='Admin First Name'
                name='admin_firstname'
                onBlur={this.handleOnBlur('admin_firstname')}
                placeholder='Type the first name'
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && admin_lastname}
                label='Admin Last Name'
                name='admin_lastname'
                onBlur={this.handleOnBlur('admin_lastname')}
                placeholder='Type the last name'
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && admin_email}
                label='Admin Email'
                name='admin_email'
                onBlur={this.handleOnBlur('admin_email')}
                placeholder='Type the email'
                type='email'
              />

              <ValidatedPassword
                icon='lock'
                label={'Admin Password'}
                labelConfirm={'Confirm password'}
                name='admin_password'
              />

              <ValidatedField
                component={FormInputSelect}
                formError={formError && security_question}
                label='Security Question'
                loading={false}
                name='security_question'
                onBlur={this.handleOnBlur('security_question')}
                onSearchChange={this.handleSearchChange}
                options={optionQuestions}
                placeholder='Select one'
              />

              <ValidatedField
                component={FormInputText}
                formError={formError && security_answer}
                label='Security Answer'
                name='security_answer'
                onBlur={this.handleOnBlur('security_answer')}
                placeholder='Type the answer'
              />

              <ValidatedField
                component={FormInputCheckbox}
                label='Enable SAML with Okta'
                name='enable_saml'
              />

              {disabledSAML && (
                <Message
                  color='yellow'
                  content='Disabling SAML will use OKTA directory services instead of Single sign on.'
                  header='Caution'
                />
              )}

              {errorsApi && errorsApi.getIn([0, 'message']) && (
                <Message
                  content={errorsApi.getIn([0, 'message'])}
                  header='Error'
                  negative
                  onDismiss={this.handleDismiss}
                />
              )}

              <Divider hidden />

              <Message
                content='By clicking Submit you are activating a third-party service provided by Okta.'
                info
              />

              <Button.Group widths='3'>
                <Button basic content='Cancel' onClick={this.handleClose} />

                <Button
                  content={'Submit'}
                  disabled={Boolean(formError)}
                  loading={isLoading}
                  primary
                />
              </Button.Group>

              <Divider hidden />
            </ScrollArea>
          </Modal.Content>
        </ValidatedForm>
      </Modal>
    );
  }
}

const OktaAccountCreateModalTest = OktaAccountCreateModal;

// eslint-disable-next-line no-class-assign -- DEV-1526
OktaAccountCreateModal = OktaAccountCreateModalController(
  OktaAccountCreateModal
);
export { OktaAccountCreateModalTest };
export default injectIntlToController(OktaAccountCreateModal);
