import PropTypes from 'prop-types';

import React from 'react';
import { Label } from 'semantic-ui-react';

const propTypes = {
  error: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  pointing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

const ErrorLabel = ({ inline = false, pointing, error, inputName }) => {
  return (
    <Label
      basic
      color='red'
      data-test-id={`${inputName}_error`}
      pointing={(inline && 'left') || pointing}
    >
      {error}
    </Label>
  );
};

ErrorLabel.propTypes = propTypes;

export default ErrorLabel;
