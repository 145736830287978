const CardTypes = {
  DOWNLOAD_APP: 'DOWNLOAD_APP',
  LOADING: 'LOADING',
  OTHER: 'OTHER',
  TYPE_ANNOUNCEMENT: 'ANNOUNCEMENT',
  TYPE_APPROVAL: 'APPROVAL',
  TYPE_CART: 'CART',
  TYPE_CONVERSATION: 'CONVERSATION',
  TYPE_DUMMY: 'DUMMY',
  TYPE_EVENT: 'EVENT',
  TYPE_GREETING: 'GREETING',
  TYPE_LINK: 'LINK',
  TYPE_LOADING: 'LOADING',
  TYPE_SURVEY: 'SURVEY',
  TYPE_TASK: 'TASK',
  TYPE_THIRD_PANEL: 'THIRD_PANEL',
  TYPE_TODO: 'TODO',
  TYPE_WORKFLOW: 'WORKFLOW',
};

export default CardTypes;
