import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCommunication extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M68.89 55.21c6.69-3.58 11.1-9.72 11.1-16.7 0-11.05-11.06-20-24.7-20-13.64 0-24.7 9-24.7 20 .004.895.08 1.788.23 2.67C22.32 43 16 49.42 16 57.07c0 5.93 3.77 9.4 7.28 11.58-.794 1.573-1.89 2.974-3.23 4.12-.81.658-1.117 1.754-.767 2.737.35.983 1.284 1.637 2.327 1.633 4.56 0 9.09-2.68 11.05-4 .98.253 1.99.37 3 .35 10.27 0 18.72-6.59 19.6-15 2.303.022 4.602-.22 6.85-.72 0 0 6 6 13.35 6-.01 0-5.64-2.86-6.57-8.56zm-33.2 15.82c-.774.02-1.548-.065-2.3-.25-.742-.02-1.48.108-2.17.38-1.76 1.18-5.74 3.53-9.59 3.53 1.93-1.643 3.414-3.75 4.31-6.12-.157-.753-.57-1.428-1.17-1.91-3.84-2.35-6.31-5.24-6.31-9.58 0-6.5 5.5-11.95 12.93-13.5 2.57 8 11.06 14 21.45 14.85-.84 7.06-8.2 12.6-17.15 12.6z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogCommunication;
