const api_espuser_v0_1_job_role_permissions = {
  application: 'catalog',
  entity_type: 'espbundle',
  job_role_permissions: {
    Architect: {
      bundles: [
        1999,
        2006,
        2004,
        2028,
        2032,
        1994,
        2015,
        2019,
        2023,
        1995,
        2026,
        2001,
      ],
      id: 2,
    },
    'Default Job Role': {
      bundles: [1994, 1995],
      id: 14,
    },
    Designer: {
      bundles: [
        1999,
        2008,
        2028,
        2010,
        2030,
        1994,
        2015,
        2017,
        2021,
        1995,
        2013,
        1997,
      ],
      id: 3,
    },
    Director: {
      bundles: [
        2011,
        1999,
        2006,
        2004,
        2010,
        2030,
        2043,
        2032,
        1994,
        2015,
        2017,
        2023,
        1995,
        2026,
        2013,
        2037,
      ],
      id: 4,
    },
    Executive: {
      bundles: [2011, 1994],
      id: 13,
    },
    'Factory Worker': {
      bundles: [
        1999,
        2006,
        2028,
        2010,
        2030,
        2032,
        1994,
        2015,
        2017,
        1995,
        2001,
      ],
      id: 5,
    },
    'Field Marketing': {
      bundles: [1999, 2004, 2008, 2043, 2015, 2023, 2013],
      id: 6,
    },
    'HR Contact': {
      bundles: [2011, 1994],
      id: 15,
    },
    'IT Technician': {
      bundles: [2011, 1999, 1994, 2034, 1995, 2036],
      id: 8,
    },
    Manager: {
      bundles: [1998, 1999, 1994, 2015, 1995, 2013, 1997],
      id: 9,
    },
    'Marketing Staff': {
      bundles: [2011, 1994, 1995],
      id: 10,
    },
    'Operations Staff': {
      bundles: [1994, 1995],
      id: 11,
    },
    'Sales Representative': {
      bundles: [1999, 2006, 2004, 2010, 2043, 1994, 2019, 1995],
      id: 7,
    },
    'Standard Office Worker': {
      bundles: [1999, 1994, 2015, 2021, 1995, 2037],
      id: 1,
    },
    'Support Staff': {
      bundles: [1994, 1995],
      id: 12,
    },
  },
  permission: 'view',
};

export default api_espuser_v0_1_job_role_permissions;
