import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Checkbox, Divider, Form, List } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import { curry, memoize, noop } from 'lodash';
// v1
// Atom
import UserImage from '../../../../v1/components/atoms/UserImage';
// Util
import buildUserServiceDepartmentDesc from '../../../../v1/utils/buildUserServiceDepartmentDesc';
import ScrollArea from '../../../../v1/components/atoms/ScrollArea';

class UserSelected extends PureComponent {
  static propTypes = {
    selectTeam: PropTypes.func,
    user: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    selectTeam: noop,
  };

  state = {
    selected: null,
  };

  handleClick = memoize(
    curry((team, e) => {
      const { selectTeam } = this.props;

      this.setState(
        {
          selected: team,
        },
        () => {
          selectTeam(team);
        }
      );
    })
  );

  render() {
    const { user } = this.props;

    const userName = `${user.get('nickname')} ${user.get('last_name')}`;

    const { selected } = this.state;

    const teamList = [];
    const departmentUser = user.get('service_departments');

    departmentUser.forEach((department) => {
      const depName = department.get('department_display_name');

      department.get('service_teams').forEach((team) => {
        const teamId = team.get('id');
        teamList.push({
          checked: selected === teamId,
          department: depName,
          id: teamId,
          name: team.get('team_name'),
        });
      });
    });

    // Build Service department/team description
    const departmentDesc = buildUserServiceDepartmentDesc(user);
    const styles = {
      borderTop: 'none',
    };
    let listItems = [
      <List.Item key='userr' style={styles}>
        <div className='image-container'>
          <UserImage avatar thumb user={user} />
        </div>
        <List.Content>
          <List.Header>{userName}</List.Header>
          <List.Content>
            <span>{user.get('department')}</span>
          </List.Content>
          <List.Description>
            <span>{departmentDesc}</span>
          </List.Description>
        </List.Content>
        <Divider hidden />
      </List.Item>,
    ];

    listItems = listItems.concat(
      teamList.map((team, i) => (
        <List.Item key={team.id} onClick={this.handleClick(team.id)}>
          <List.Content>
            <Form.Field>
              <Checkbox
                checked={team.checked}
                id={team.id}
                label={
                  <label>
                    <List.Header>{team.name}</List.Header>
                    <List.Content>
                      <span>{team.department}</span>
                    </List.Content>
                  </label>
                }
                onClick={this.handleClick(team.id)}
                radio
                value={team.id}
              />
            </Form.Field>
          </List.Content>

          {/* Divider logic */}
          {i < teamList.length - 1 && <Divider />}
        </List.Item>
      ))
    );

    return (
      <ScrollArea height='100%' padding='1rem 0'>
        <List>
          <Divider hidden />
          <Divider hidden />
          {intl.formatMessage({
            id: 'message.agent_belongs_to_multiple_departments',
          })}
          <Divider hidden />

          {listItems}
        </List>
      </ScrollArea>
    );
  }
}

export default UserSelected;
