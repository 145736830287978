import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreStickers extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path
            d='M29.084.298h3.765c17.67 0 32 14.326 32 32 0 17.673-14.33 32-32 32-17.68 0-32-14.327-32-32V28.53c0-1.02.49-2.217 1.21-2.94L26.14 1.517C26.863.797 28.063.3 29.083.3zm-24.47 32c0 15.594 12.64 28.236 28.235 28.236 15.59 0 28.23-12.642 28.23-28.237 0-15.593-12.64-28.237-28.24-28.237h-2.11C29.032 17.69 18.234 28.49 4.61 30.188V32.3zM26.46 6.522L7.074 25.91c9.553-2.31 17.076-9.834 19.388-19.387zm7.04 20.842c1.382-1.38 3.19-2.07 4.998-2.07 1.803 0 3.606.687 4.982 2.063l.22.22c2.753 2.754 2.748 7.225-.008 9.98L34.18 47.07c-.736.738-1.927.738-2.663 0l-9.513-9.513c-2.756-2.754-2.76-7.225-.007-9.98l.22-.22c2.755-2.755 7.22-2.75 9.978.008l.654.654.65-.66zm.68 4.648c-.37.367-.85.55-1.333.55s-.965-.183-1.333-.55l-1.984-1.986c-1.288-1.288-3.37-1.29-4.655-.007l-.22.22c-1.282 1.28-1.28 3.37.007 4.65l8.184 8.18 8.183-8.188c1.28-1.286 1.29-3.374 0-4.654l-.22-.22c-1.29-1.284-3.37-1.28-4.66.006L34.166 32z'
            id='a'
          />
          <path
            d='M29.084.298h3.765c17.67 0 32 14.326 32 32 0 17.673-14.33 32-32 32-17.68 0-32-14.327-32-32V28.53c0-1.02.49-2.217 1.21-2.94L26.14 1.517C26.863.797 28.063.3 29.083.3zm-24.47 32c0 15.594 12.64 28.236 28.235 28.236 15.59 0 28.23-12.642 28.23-28.237 0-15.593-12.64-28.237-28.24-28.237h-2.11C29.032 17.69 18.234 28.49 4.61 30.188V32.3zM26.46 6.522L7.074 25.91c9.553-2.31 17.076-9.834 19.388-19.387zm7.04 20.842c1.38-1.38 3.19-2.07 4.997-2.07 1.803 0 3.606.687 4.982 2.063l.22.22c2.75 2.754 2.74 7.225-.01 9.98l-9.51 9.513c-.74.738-1.93.738-2.67 0L22 37.557c-2.755-2.754-2.76-7.225-.006-9.98l.22-.22c2.755-2.755 7.22-2.75 9.978.008l.654.654.652-.66zm.68 4.648c-.37.368-.85.55-1.333.55s-.965-.182-1.333-.55l-1.984-1.986c-1.288-1.288-3.37-1.29-4.655-.007l-.22.22c-1.282 1.28-1.28 3.37.007 4.65l8.184 8.18 8.183-8.188c1.28-1.286 1.29-3.374 0-4.654l-.22-.22c-1.29-1.284-3.37-1.28-4.66.006L34.166 32z'
            id='c'
          />
        </defs>
        <g fill={color} fillRule='evenodd'>
          <path
            d='M48.35 79.828c17.673 0 32-14.327 32-32 0-17.675-14.327-32-32-32h-3.766c-1.018 0-2.22.5-2.94 1.218L17.568 41.123c-.722.72-1.218 1.916-1.218 2.938v3.77c0 17.68 14.325 32 32 32zM22.573 41.44L41.96 22.053c-2.31 9.552-9.834 17.075-19.387 19.387zm-2.46 4.272v2.115c0 15.596 12.64 28.236 28.235 28.236 15.594 0 28.235-12.64 28.235-28.235 0-15.594-12.64-28.236-28.236-28.236H46.23c-1.698 13.625-12.496 24.422-26.12 26.12zm27.58-2.818c-2.756-2.755-7.222-2.762-9.977-.006l-.22.22c-2.754 2.754-2.75 7.225.007 9.98l9.513 9.513c.737.74 1.927.74 2.664 0l9.514-9.51c2.756-2.75 2.76-7.22.008-9.98l-.22-.22c-2.756-2.75-7.222-2.75-9.98.01l-.653.66-.66-.653zm-7.53 7.53c-1.285-1.285-1.288-3.373-.006-4.654l.22-.22c1.285-1.283 3.367-1.28 4.655.008l1.984 1.985c.737.735 1.927.735 2.664 0l1.985-1.985c1.288-1.29 3.37-1.29 4.655-.007l.22.22c1.283 1.28 1.28 3.37-.007 4.66l-8.183 8.18-8.186-8.18z'
            fill='#F00'
          />
          <g transform='translate(15.5 15.53)'>
            <mask fill='#fff' id='b'>
              <use xlinkHref='#a' />
            </mask>
            <path
              d='M-935 555H301.53V-681.528H-935'
              fill='#F00'
              mask='url(#b)'
            />
          </g>
          <g transform='translate(15.5 15.53)'>
            <mask fill='#fff' id='d'>
              <use xlinkHref='#c' />
            </mask>
            <path
              d='M-5.19 71.543h76.077V-4.532H-5.19'
              fill='#F00'
              mask='url(#d)'
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default CatalogCompanyStoreStickers;
