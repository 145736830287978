import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import TypeImage, { TypeDefaults } from './TypeImage';

const rule = () => ({
  '> .image': {
    '&.mobile': {
      medium: {
        display: 'none',
      },
    },
    '&.web': {
      large: {
        display: 'none',
      },
    },
    maxWidth: '100%',
  },
});

class Image01 extends PureComponent {
  static propTypes = TypeImage;
  static defaultProps = TypeDefaults;

  render() {
    const { imageMobile, imageWeb, template, type, className } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        <img alt='' className='image mobile' src={imageMobile} />
        <img alt='' className='image web' src={imageWeb} />
      </div>
    );
  }
}

export default createComponent(rule, Image01, [
  'imageMobile',
  'imageWeb',
  'template',
  'type',
  'id',
  'trigger',
]);
