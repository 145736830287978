import { equals } from 'ramda';

/**
 * Cast Module Values
 *
 * Casts data into its correct type as specified by dataDisplay.
 *
 * @param {Object} data An object with the data to be casted
 * @param {Array[Object]} dataDisplay An array of Cascara Module Definitions
 * @returns {Object} An object with the casted values
 */
const castModuleValues = (data, dataDisplay = []) => {
  const allFields = dataDisplay.reduce((allFields, currentField) => {
    if (currentField.module === 'row') {
      allFields.push(...currentField.fields);
    } else {
      allFields.push(currentField);
    }

    return allFields;
  }, []);

  const numbers = allFields
    .filter((field) => equals(field.module, 'number'))
    .reduce((numbers, currentField) => {
      numbers[currentField.attribute] = Number(data[currentField.attribute]);

      return numbers;
    }, {});

  const booleans = allFields
    .filter((field) => equals(field.module, 'checkbox'))
    .reduce((booleans, currentField) => {
      if (equals(data[currentField.attribute], ['on'])) {
        booleans[currentField.attribute] = true;
      } else if (
        equals(data[currentField.attribute], ['off']) ||
        equals(data[currentField.attribute], [])
      ) {
        booleans[currentField.attribute] = false;
      }
      return booleans;
    }, {});

  const nullValues = allFields.reduce((allNullValues, currentField) => {
    if (equals(data[currentField.attribute], 'null')) {
      allNullValues[currentField.attribute] = undefined;
    }

    return allNullValues;
  }, {});

  // DEV-17586: value must be in ISO format
  const dates = allFields
    .filter((field) => equals(field.module, 'date'))
    .reduce((dates, currentField) => {
      const value = data[currentField.attribute];

      if (value) {
        dates[currentField.attribute] = new Date(value).toISOString();
      }

      return dates;
    }, {});

  return {
    ...data,
    ...dates,
    ...booleans,
    ...numbers,
    ...nullValues,
  };
};

export default castModuleValues;
