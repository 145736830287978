import { createSelector } from 'reselect';

import buildCompositeController from '../../../../utils/buildCompositeController';
// Actions
import toastNotificationsActions from '../../../../actions/toastNotificationsActions';
import sessionThunks from '../../../../actions/sessionThunks';
import wfIntlActions from '../../../../actions/wfIntlActions';
import wfIntlThunks from '../../../../actions/wfIntlThunks';

// We do this to not return a different object if the immutable
// redux state didn't change
const getBlobKeyPathAsJS = createSelector(
  [(state) => state.getIn(['wfIntl', 'editorActiveBlobPathKey'])],
  (blobKeyPath) => (blobKeyPath ? blobKeyPath.toJS() : void 0)
);

const mapStateToProps = (state) => {
  const currentSelectedTaskId = state.getIn(['wfIntl', 'editorSelectedTask']);
  return {
    blobKeyPath: getBlobKeyPathAsJS(state),
    currentSelectedTaskId: currentSelectedTaskId,
    formTranslations: state.getIn(['wfIntl', 'editorActiveFormTranslations']),
    isLoadingIntlKeys: state.getIn(['wfIntl', 'editorIntlKeyIsLoading']),
    isOpen: state.getIn(['wfIntl', 'editorIsKeyEditorOpen']),
    isSavingTranslations: state.getIn(['wfIntl', 'editorIsSavigTranslations']),
    proposedTranslationKey: state.getIn(['wfIntl', 'editorActiveProposedKey']),
    supportedLanguages: state.getIn(['intl', 'supportedLanguages']),
    translationKey: state.getIn(['wfIntl', 'editorActiveKey']),
    translationKeyResults: state.getIn([
      'wfIntl',
      'editorActiveKeyIntlResults',
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeIntlKeyEditor: () => {
    dispatch(wfIntlActions.closeIntlKeyEditor());
  },

  loadLocalizationsForKey: (translationKey) => {
    dispatch(wfIntlThunks.loadLocalizationsForKey(translationKey)).then(
      (results = []) => {
        if (!results || !results.length) {
          // if no results then means this term has not been used as translation key
          // so we have to generate a new key for it
          dispatch(wfIntlThunks.generateNewTranslationKey(translationKey));
        }
      }
    );
  },

  loadSupportedLanguages: () => {
    dispatch(sessionThunks.loadSupportedLanguages());
  },

  saveExistingTranslations: (
    translationKey,
    supportedLanguages,
    translationKeyResults,
    formTranslations
  ) => {
    // blobKeyPath;
    supportedLanguages.forEach((lang) => {
      const langCode = lang.get('beCode');
      const localizationInstance = translationKeyResults.find(
        (lan) => lan.get('language') === langCode
      );

      // translation already exist, just save it
      if (localizationInstance) {
        const translationText = formTranslations.get(langCode)
          ? formTranslations.get(langCode)
          : localizationInstance.get('text');

        dispatch(
          wfIntlThunks.editTranslation(
            localizationInstance.get('id'),
            translationText,
            translationKey
          )
        );
      } else {
        const translationText = formTranslations.get(langCode);
        dispatch(
          wfIntlThunks.saveNewTranslation(
            langCode,
            translationKey,
            translationText
          )
        );
      }
    });

    dispatch(
      toastNotificationsActions.success({
        message: 'Translations saved!',
      })
    );
  },

  saveNewTranslation: (
    langCode,
    translationKey,
    text,
    blobKeyPath,
    currentSelectedTaskId
  ) => {
    dispatch(
      wfIntlThunks.saveNewTranslation(langCode, translationKey, text)
    ).then(() => {
      // reload the editor
      dispatch(wfIntlActions.launchIntlKeyEditor(translationKey, blobKeyPath));

      // re-save the task_frontend_blob
      dispatch(
        wfIntlThunks.patchFrontEndBlob(
          currentSelectedTaskId,
          blobKeyPath,
          translationKey
        )
      ).then((task) => {
        // update the task in wfIntlReducer
        dispatch(wfIntlActions.loadTaskSuccess(task.id, task));
      });
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
