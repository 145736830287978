import actionTypes from './actionTypes';

const workflowActions = {};

workflowActions.resumeWorkflowSuccess = (
  workflowRequest,
  requestedForUserData
) => {
  // console.log('workflowRequest',workflowRequest);

  const userRequestedFor = workflowRequest.requested_for;
  const userAssignedTo = workflowRequest.assigned_to;
  const workflowRequestID = workflowRequest.id;
  const workflowID = workflowRequest.current_workflow;
  const workflowTask = workflowRequest.current_workflowtask;
  const frontendScratch = workflowRequest.frontend_scratch;
  const backendScratch = workflowRequest.backend_scratch;
  const workflowName = workflowRequest.current_workflow_name;
  const workflowTaskName = workflowTask && workflowTask.name;
  const frontendIntl = workflowTask && workflowTask.frontend_intl;

  const progress = {
    max_steps: workflowRequest.max_steps,
    steps_completed: workflowRequest.steps_completed,
  };

  // heads up! after the last task of a workflow, 'current_workflowtask' is going to be null
  let workflowTaskID = null;
  let newAttributes = null;
  let blob = null;

  if (workflowTask) {
    workflowTaskID = workflowTask.id;

    newAttributes = workflowTask.attributes;
    blob = workflowTask.frontend_blob;
  }

  return {
    attributes: newAttributes,
    backendScratch,
    blob,
    frontendIntl,
    frontendScratch,
    progress,
    requestedForUserData,
    type: actionTypes.RESUME_WORKFLOW_SUCCESS,
    userAssignedTo,
    userRequestedFor,
    workflowID,
    workflowName,
    workflowRequestID,
    workflowTaskID,
    workflowTaskName,
  };
};

workflowActions.setEntrySuccess = () => ({
  type: actionTypes.SET_ENTRY_WORKFLOW_SUCCESS,
});

workflowActions.resumeWorkflowFail = (fallbackFrontendBlob) => ({
  fallbackFrontendBlob,
  type: actionTypes.RESUME_WORKFLOW_FAIL,
});

workflowActions.updateRequestedForUserSuccess = (requestedForUser) => ({
  requestedForUser,
  type: actionTypes.UPDATE_WORKFLOW_REQUESTED_FOR_USER_SUCCESS,
});

/**
 * If a task is a multi steps one, this action reports it's progress to the workflow state,
 * so an overall progress can be calculated.
 */
workflowActions.reportTaskProgress = (stepsCompleted, stepsCount) => ({
  stepsCompleted,
  stepsCount,
  type: actionTypes.REPORT_WORKFLOW_TASK_PROGRESS,
});

workflowActions.loginDone = (payload, workflowID, onboardID, ref, code) => ({
  code,
  onboardID,
  payload,
  ref,
  type: actionTypes.WK_LOGIN_DONE,
  workflowID,
});

workflowActions.loading = () => ({
  type: actionTypes.WK_LOADING,
});

workflowActions.exitLoading = () => ({
  type: actionTypes.WK_EXIT_LOADING,
});

workflowActions.overLoading = () => ({
  type: actionTypes.WK_OVERLOADING,
});

workflowActions.setBlockErrors = (errors) => ({
  errors,
  type: actionTypes.WK_SET_ERRORS,
});

workflowActions.saveScratchStart = () => ({
  type: actionTypes.WK_SAVE_SCRATCH_START,
});

workflowActions.saveScratchSuccess = (data) => ({
  data,
  type: actionTypes.WK_SAVE_SCRATCH_SUCESS,
});

workflowActions.saveScratchFail = (errorMesagae) => ({
  errorMesagae,
  type: actionTypes.WK_SAVE_SCRATCH_FAIL,
});

workflowActions.saveBEScratchStart = () => ({
  type: actionTypes.WK_SAVE_BE_SCRATCH_START,
});

workflowActions.saveBEScratchSuccess = (data) => ({
  data,
  type: actionTypes.WK_SAVE_BE_SCRATCH_SUCCESS,
});

workflowActions.saveBEScratchFail = (errorMesagae) => ({
  errorMesagae,
  type: actionTypes.WK_SAVE_BE_SCRATCH_FAIL,
});

workflowActions.addEIDToIDMapping = (workflowEID, workflowID) => ({
  type: actionTypes.ADD_WORKFLOW_EID_TO_ID_MAPPING,
  workflowEID,
  workflowID,
});

workflowActions.otpStart = () => ({
  type: actionTypes.OTP_START,
});

workflowActions.otpSuccess = (message) => ({
  message,
  type: actionTypes.OTP_SUCCESS,
});

workflowActions.otpError = (error) => ({
  error,
  type: actionTypes.OTP_ERROR,
});

workflowActions.onBlurField = (id) => ({
  id,
  type: actionTypes.WF_ON_BLUR_FIELD,
});

workflowActions.updateAttributesFromBEScratch = () => ({
  type: actionTypes.WF_UPDATE_ATTRIBUTES_FROM_BESCRATCH,
});

export default workflowActions;
