const EspEntities = {
  APP_USER: 'espuser.espuser', // Because Xia Yu wants <app_name>.<model_name> and i want to make him happy
  ASSET: 'assets.EspAsset',
  CATEGORY: 'catalog.EspCategory',
  CHANNEL_DIRECT: 'channel.Direct',
  CHANNEL_SUPPORT: 'channel.Support',
  CONFLUENCE_KB: 'confluence.KB', // confluence kb by confluenceId
  DATE: 'Sys.DateTime',
  ESP_KB: 'esp.kbArticle', // espressive kb by responseEid
  IVANTI_KB: 'ivanti.KB',
  KB_ARTICLE: 'snow.KB', // snow kb by sys_id
  KB_NUMBER: 'snow.kbNumber', // snow kb by number
  PRODUCT: 'catalog.EspProduct',
  SHAREPOINT_KB: 'sharepoint.KB',
  USER: 'users.EspUser',
  ZENDESK_KB: 'zendesk.KB',
};

export default EspEntities;
