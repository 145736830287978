import { CURRENT_PLATFORM } from '../__globals';

const optionClickHandlers = {
  // eslint-disable-next-line no-alert -- temp dev function
  web: () => alert('This is the default handleOptionClick()'),
};

const handleOptionClick =
  optionClickHandlers[CURRENT_PLATFORM] || optionClickHandlers['web']; // This needs to be done to set the handler if we do not have anything platform specific

export { handleOptionClick };
