import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';
import classNames from 'classnames';

import ErrorLabel from '../atoms/ErrorLabel';

class FormInputRadio extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    formError: PropTypes.string,
    icon: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }).isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      active: PropTypes.bool,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      error: PropTypes.object,
      submitFailed: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    removeErrorHandler: PropTypes.func,
    required: PropTypes.bool,
    showErrorsWithoutSubmitting: PropTypes.bool,
    synchroError: PropTypes.bool,
    toggle: PropTypes.bool,
    widths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    defaultValue: null,
    disabled: false,
    formError: '',
    icon: null,
    inline: false,
    meta: {},
    onChange: null,
    placeholder: null,
    readOnly: false,
    removeErrorHandler: null,
    required: false,
    showErrorsWithoutSubmitting: false,
    synchroError: false,
    toggle: false,
    widths: null,
  };

  // It can use a customized change event or default
  handleChange = (param, data) => {
    const { input, onChange, removeErrorHandler } = this.props;
    removeErrorHandler && removeErrorHandler();
    if (onChange) {
      return onChange(param, data);
    }
    return input.onChange(data.value);
  };

  render() {
    const {
      defaultValue,
      disabled,
      formError,
      icon,
      inline,
      input,
      label,
      placeholder,
      readOnly,
      required,
      showErrorsWithoutSubmitting,
      synchroError,
      toggle,
      widths,
      meta: { active, error, submitFailed },
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    return (
      <Form.Field
        className={classNames({
          required: required,
        })}
        inline={inline}
      >
        <Radio
          {...input}
          disabled={disabled}
          icon={icon}
          label={label}
          onChange={this.handleChange}
          placeholder={placeholder}
          readOnly={readOnly}
          toggle={toggle}
          widths={widths}
        />
        {(formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )) ||
          (showErrorsWithoutSubmitting && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (!active && submitFailed && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (synchroError && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          ))}
      </Form.Field>
    );
  }
}

export default FormInputRadio;
