const api_integration_v0_1_confluence_kb_last_sync = {
  datetime: '2020-08-25T15:23:36.194008Z',
  results: {
    added: 180,
    archived: 1,
    deleted: 1,
    found: 200,
    updated: 18,
  },
};

export default api_integration_v0_1_confluence_kb_last_sync;
