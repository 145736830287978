import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Immutable, { fromJS } from 'immutable';
import { Field } from 'redux-form/immutable';
import {
  Button,
  Checkbox,
  Divider,
  Header,
  Image,
  Input,
  List,
  Segment,
} from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';
// Atoms
import ErrorLabel from '../atoms/ErrorLabel';
// Molecule
import EditFAQResponseModal from './EditFAQResponseModal';
import EspMetaVariables from './EspMetaVariables';
// Controller
import ResponsesGroupController from '../controllers/ResponsesGroupController';
// Utility
import buildConditionHeader from '../../utils/buildConditionHeader';
// Global
import imageRegex from '../../globals/imageRegex';

const buildRegroupedList = (responsesList, props) => {
  // Todo - make this tool ... a real tool in the util folder
  const regroupedList = [];

  responsesList.forEach((response, indexField) => {
    if (response.size > 1 || !response.get('addNewResponses')) {
      const locationdeptrole =
        response && response.get('locationdeptrole', Immutable.Map());
      const responseHeader = buildConditionHeader(locationdeptrole);
      const index = regroupedList.findIndex((l) => {
        // We don't want to group all the same conditions together
        // if the condition is protected, is has to be its own group
        // and the unprotected ones go to another group as well
        // console.log('the l', l);
        // console.log('response', response.toJS());
        // console.log('responseHeader', responseHeader);

        return (
          l.id === responseHeader.locationDeptRoleName &&
          Boolean(response.get('main_answer_is_protected')) ===
            Boolean(l.mainAnswerisProtected)
        );
      });
      const keyToPass = indexField;
      let url = response.get('url')
        ? response.get('url')
        : response.getIn(['response', 'name'])
        ? response.getIn(['response', 'name'])
        : '';
      let nameUrl = typeof url === 'string' ? url.split('/') : null;

      if (
        response.hasIn(['response', 'name']) &&
        props.imageNewFaq.has(response.getIn(['response', 'name']))
      ) {
        // Get the img src from the reducer
        const file = props.imageNewFaq.get(
          response.getIn(['response', 'name'])
        );
        nameUrl = file.name;
        url = file.src;
      } else if (
        response.hasIn(['response', 'attachment_eid']) &&
        response.hasIn(['response', 'src'])
      ) {
        url = response.getIn(['response', 'src']);
        nameUrl = url;
      }

      nameUrl = _.isArray(nameUrl) ? nameUrl[nameUrl.length - 1] : nameUrl;
      if (nameUrl) {
        const [url] = nameUrl.split('?');
        nameUrl = url;
      }

      // Check if the response is an image
      let isImage =
        imageRegex.test(nameUrl) ||
        (response.hasIn(['response', 'name']) &&
          imageRegex.test(response.getIn(['response', 'name'])));
      if (
        !isImage &&
        !nameUrl &&
        typeof response.get('response') === 'string' &&
        imageRegex.test(response.get('response'))
      ) {
        isImage = true;
        url = response.get('response');
      }

      const responseMeta = (
        <div className='item' key={keyToPass} role='listitem'>
          {isImage ? (
            <Image src={url} />
          ) : (
            <EspMetaVariables
              avoidFollowLinks
              text={nameUrl || response.get('response')}
              userId={props.baristaId}
            />
          )}
        </div>
      );

      // Get the image or the text
      const responseTxt = response.get('url')
        ? response.get('url')
        : response.get('response');
      const responsActive = response.has('active')
        ? response.get('active') === true
        : true;

      if (response && index > -1) {
        regroupedList[index].indexesField.push(indexField);
        regroupedList[index].responses.push(responseTxt);
        regroupedList[index].activeResponsesLines.push(responsActive);
        regroupedList[index].isProtectedResponsesLines.push(
          response.get('is_protected') === true
        );
        regroupedList[index].responsesMeta.push(responseMeta);
      } else if (response) {
        regroupedList.push({
          activeResponsesLines: [response.get('active', true)],
          answerEID: response.get('answerEID'),
          id: responseHeader.locationDeptRoleName,
          indexesField: [indexField],
          isProtected: response.get('is_protected'),
          isProtectedResponsesLines: [response.get('is_protected', false)],
          locationdeptrole: responseHeader.locationDepartment,
          mainAnswerActive: response.get('main_answer_active', true),
          mainAnswerisProtected: response.get(
            'main_answer_is_protected',
            false
          ),
          realLocationdeptrole: responseHeader.realLocationdeptrole,
          responses: [responseTxt],
          responsesMeta: [responseMeta],
        });
      }
    }
  });

  return regroupedList;
};

class ResponsesGroup extends PureComponent {
  static propTypes = {
    addFromMessage: PropTypes.bool,
    addNewWithModal: PropTypes.bool,
    /** Text to display in the ADD ENTRY button */
    buttonAddResponseText: PropTypes.string,
    /** Button Add to display after NUMBER entries */
    buttonShowBottomAfter: PropTypes.number,
    change: PropTypes.func,
    /** ReduxForm 'field' prop */
    fields: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    /** Form error */
    formError: PropTypes.string,
    initialInputValue: ImmutablePropTypes.map,
    /** Coming from Redux Form  */
    input: PropTypes.shape({
      name: PropTypes.string,
      value: ImmutablePropTypes.listOf(PropTypes.string),
    }),
    inputName: PropTypes.string,
    /** Are this responses list editable ? */
    isEditable: PropTypes.bool,
    /** Coming from Redux Form  */
    meta: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      dispatch: PropTypes.any,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      form: PropTypes.any,
      submitFailed: PropTypes.bool,
    }),
    mountNodeSelector: PropTypes.string,
    nonEmptyLineRequired: PropTypes.bool,
    query: PropTypes.shape({
      taskId: PropTypes.string,
    }),
    setScratch: PropTypes.func,
    showAddResponse: PropTypes.bool,
  };

  static defaultProps = {
    addFromMessage: false,
    addNewWithModal: false,
    buttonAddResponseText: 'Add new',
    buttonShowBottomAfter: 0,
    change: _.noop,
    formError: '',
    initialInputValue: null,
    input: fromJS({
      value: [],
    }),
    inputName: '',
    isEditable: false,
    meta: {},
    mountNodeSelector: null,
    nonEmptyLineRequired: false,
    query: {},
    setScratch: _.noop,
    showAddResponse: false,
  };

  constructor(props) {
    super(props);

    const responsesList =
      props.fields && props.fields.getAll && props.fields.getAll();
    this.state = {
      regroupedList: Immutable.List.isList(responsesList)
        ? buildRegroupedList(responsesList, props)
        : [],
    };
  }

  componentDidMount() {
    const {
      addNewWithModal,
      change,
      initialInputValue,
      inputName,
      fields,
      setScratch,
    } = this.props;

    fields.removeAll(); // Clean fields

    let firstEntry = void 0;
    if (initialInputValue && initialInputValue.size) {
      const initValue = (value) => {
        value.forEach((v, i) => {
          if (v) {
            firstEntry = firstEntry === 0 || firstEntry ? firstEntry : i;
            fields.push(v);
          }
        });
      };

      initValue(initialInputValue);
    }

    firstEntry = firstEntry ? firstEntry : 0;

    if (!addNewWithModal) {
      // set addNewResponses flag for WF condition
      change(`${inputName}.${firstEntry}.addNewResponses`, 'falsy');
      change(`addResponses.${firstEntry}.addNewResponses`, 'falsy');
      setScratch(false); // Set flag frontendscratch  createNewResponse false - Needed in AddResponsesTextArea
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const responsesList = nextProps.fields.getAll();
    const { fields } = this.props;
    const { regroupedList } = this.state;
    if (
      Immutable.List.isList(responsesList) &&
      (nextProps.fields !== fields || regroupedList.length === 0)
    ) {
      this.setState({
        regroupedList: buildRegroupedList(responsesList, nextProps),
      });
    }
  }

  emptyResponse = {
    activeResponsesLines: [],
    eid: '',
    id: '',
    indexesField: [],
    isProtected: false,
    isProtectedResponsesLines: [],
    locationdeptrole: 'Locations : ',
    mainAnswerActive: true,
    mainAnswerisProtected: false,
    realLocationdeptrole: Immutable.Map(),
    responses: [],
    responsesMeta: [],
  };

  handleAddResponses = () => {
    const { change, inputName, initialInputValue, setScratch } = this.props;
    const value = initialInputValue.get('0', Immutable.fromJS({}));
    change(`${inputName}.0`, value.set('addNewResponses', 'truly')); // set addNewResponses flag for WF condition
    setScratch(true); // Set flag frontendscratch  createNewResponse true - Needed in AddResponsesTextArea
  };

  onRemove = _.memoize(
    _.curry((index, e) => {
      if (e) {
        e.preventDefault();
      }
      const { change, fields, initialInputValue, inputName } = this.props;
      const { regroupedList } = this.state;

      // Get the selected List to remove

      const listToRemove = regroupedList[index].indexesField;

      // Remove all of this field in Redux Form
      for (
        let indexField = listToRemove.length - 1;
        indexField >= 0;
        indexField--
      ) {
        fields.remove(listToRemove[indexField]); // Remove field from fieldarray
      }

      // Create new final input to pass as attribute
      const newInput = initialInputValue
        .toList()
        .filter((value, i) => {
          const findIt = listToRemove.find((l) => Number(l) === Number(i));
          return !(findIt >= 0);
        })
        .toMap()
        .map((v, i) => (i === 0 ? v.set('addNewResponses', 'falsy') : v)); // Set back the flag

      change(`${inputName}`, newInput); // clean value
    })
  );

  onCloneProtected = _.memoize(
    _.curry((index, e) => {
      if (e) {
        e.preventDefault();
      }

      const { change, initialInputValue, inputName } = this.props;
      const { regroupedList } = this.state;

      // All the lines currently in the form
      let existingLines = Immutable.Map(initialInputValue);

      // 1. Get the answer eid to clone;

      const { answerEID } = regroupedList[index];

      // 1. Copy all the lines to be clonesd
      let newClonedLines = Immutable.List();
      existingLines.forEach((line) => {
        if (line.get('answerEID') === answerEID) {
          // But before clean up the protected attributes
          const cloneLine = line
            .set('locationdeptrole', fromJS({})) // DEV-7518 the reason for cloning is that you want to copy to the condition, but possibly make a few changes and add different conditions
            .set('active', true)
            .set('is_protected', false)
            .set('main_answer_is_protected', false) // obvisouly this will be non protected
            .set('main_answer_active', true) // has to be active lol
            .set('answerEID', '') // new answer group
            .set('eid', null); // no eid as it's going to be new
          newClonedLines = newClonedLines.push(cloneLine);
        }
      });

      // Append them to the existing lines

      newClonedLines.forEach((v) => {
        // Start from 0 if the cleaned values are empty (only in CREATE FAQ)
        const getHighestValue = existingLines.isEmpty()
          ? -1
          : existingLines.reduce((acc, v, key) => {
              if (Number(key) > acc) {
                return Number(key);
              }
              return acc;
            }, 0);

        const newIndex = getHighestValue + 1;
        existingLines = existingLines.set(newIndex, v);
      });

      change(`${inputName}`, existingLines); // Update the real field

      // We have to update the first element of this as a list
      existingLines = existingLines.toList();
      existingLines = existingLines.setIn([0, 'addNewResponses'], 'falsy'); // this is used in workflow ¯\_(ツ)_/¯

      // console.log('Final cloned existingLines', existingLines.toJS());

      change('addResponses', existingLines); // this receives a list
    })
  );

  handleOnEdit = (newValues, oldCondition, addNewWithModal) => {
    const { change, initialInputValue, inputName } = this.props;

    // The changed groups will start off as the existing
    let existingLines = Immutable.Map(initialInputValue);

    // console.log('Lines in newValues', newValues.toJS());
    // console.log('Existing lines', existingLines.toJS());
    // Delete all the lines not in newValues
    // as that means they were deleted in the UI
    const deletedLines = existingLines.filter((el) => {
      const isInTheNewValues = newValues.find(
        (nv) => el.get('eid') && nv.get('eid') === el.get('eid')
      );

      if (!isInTheNewValues) {
        // Even if not here, double check
        // if it's not among the new values
        // only delete it if it meets the same condition
        const inSameCondition = newValues.find((nv) => {
          const cond1 = buildConditionHeader(nv.get('locationdeptrole'))
            .locationDepartment;
          const cond2 = buildConditionHeader(el.get('locationdeptrole'))
            .locationDepartment;

          const sameCondition = cond1 === cond2;
          // We also need to check the existing values against the old condition
          // to see if an existing value changed its condition (DEV-7352)
          const sameButOldCondition = cond2 === oldCondition;

          const sameProtectedness =
            Boolean(nv.get('main_answer_is_protected')) ===
            Boolean(el.get('main_answer_is_protected'));

          if ((sameCondition || sameButOldCondition) && sameProtectedness) {
            return true;
          }

          return false;
        });

        return inSameCondition;
      }

      return false;
    });

    // console.log('Lines to be deleted', deletedLines.toJS());
    deletedLines.forEach((l, key) => {
      existingLines = existingLines.delete(key); // goodbye sweet prince
    });

    // console.log('Existing lines after removing the ones to delete', existingLines.toJS());

    // console.log('New lines to add', newValues.toJS());

    // Delete the current newValues in existingLines
    // we just want to use what is new
    let newLines = Immutable.List();
    newValues.forEach((newVal) => {
      const newLineEid = newVal.get('eid');
      // If line exists, then we're doing an update
      existingLines.find((line, key) => {
        // find an replace the line
        if (line.get('eid') && line.get('eid') === newLineEid) {
          existingLines = existingLines.delete(key); // delete
          return true; // finish the find
        }

        return false;
      });

      newVal = newVal.delete('order'); // no need to set other, will be set accordinly by rebuildFAQAnswers
      newLines = newLines.push(newVal);
    });

    newLines.forEach((v) => {
      // Start from 0 if the cleaned values are empty (only in CREATE FAQ)
      const getHighestValue = existingLines.isEmpty()
        ? -1
        : existingLines.reduce((acc, v, key) => {
            if (Number(key) > acc) {
              return Number(key);
            }
            return acc;
          }, 0);

      const newIndex = getHighestValue + 1;
      existingLines = existingLines.set(newIndex, v);
    });

    // console.log('newLines', newLines.toJS());
    // console.log('existingLines before', existingLines.toJS());

    change(`${inputName}`, existingLines); // Update the real field

    // We have to update the first element of this as a list
    existingLines = existingLines.toList();
    existingLines = existingLines.setIn([0, 'addNewResponses'], 'falsy'); // this is used in workflow ¯\_(ツ)_/¯

    // console.log('Final edited existingLines', existingLines.toJS());
    change('addResponses', existingLines); // this receives a list
  };

  handleClick = (e) => {
    e.preventDefault();
  };

  handleOnToggleActive = _.memoize(
    _.curry((answerEID, e) => {
      const { inputName, change, fields } = this.props;

      if (e) {
        e.preventDefault();
      }

      const responsesList = fields && fields.getAll && fields.getAll();
      let newMap = Immutable.Map();
      const newList = responsesList.map((item, index) => {
        if (item.get('answerEID') === answerEID) {
          const newItem = item.set(
            'main_answer_active',
            !item.get('main_answer_active')
          );
          newMap = newMap.set(index, newItem);
          return newItem;
        } else {
          newMap = newMap.set(index, item);
          return item;
        }
      });

      change(`${inputName}`, newMap);
      change('addResponses', newList);
    })
  );

  render() {
    const {
      addFromMessage,
      addNewWithModal,
      buttonAddResponseText,
      buttonShowBottomAfter,
      fields,
      formError,
      input,
      inputName,
      isEditable,
      meta: { submitFailed, error },
      mountNodeSelector,
      nonEmptyLineRequired,
      query,
      showAddResponse,
    } = this.props;

    const { regroupedList } = this.state;

    const canDelete = regroupedList.length > 1;

    const style = {
      deleteButton: {
        border: 'none',
        fontSize: '16px',
      },
      header: {
        maxWidth: canDelete ? 'calc(100% - 52px)' : '100%',
      },
    };

    const editHeaderStyle = {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    };

    const checkBoxStyle = {
      margin: '10px',
    };

    return (
      <>
        {!isEditable || showAddResponse ? (
          <>
            {addNewWithModal ? (
              <EditFAQResponseModal
                addFromMessage={addFromMessage}
                addNewWithModal={addNewWithModal}
                group={this.emptyResponse}
                mountNodeSelector={mountNodeSelector}
                nonEmptyLineRequired={nonEmptyLineRequired}
                onEdit={this.handleOnEdit}
                query={query}
                showAddResponse={showAddResponse}
                trigger={
                  <Button
                    basic
                    content={buttonAddResponseText}
                    floated='right'
                    onClick={this.handleClick}
                  />
                }
              />
            ) : (
              <Button
                basic
                content={buttonAddResponseText}
                floated='right'
                onClick={this.handleAddResponses}
                type='button'
              />
            )}

            <Divider clearing fitted hidden />
          </>
        ) : null}

        <Field className={'hidden'} component={Input} name={inputName} />

        {regroupedList.map((group, i) => (
          <React.Fragment key={group.locationdeptrole + i.toString()}>
            <Segment attached={'top'} clearing>
              <div style={editHeaderStyle}>
                {canDelete && !group.mainAnswerisProtected ? (
                  <Button
                    basic
                    icon='close'
                    onClick={this.onRemove(i)}
                    style={style.deleteButton}
                  />
                ) : null}

                {isEditable &&
                Boolean(group.mainAnswerActive) &&
                !group.mainAnswerisProtected ? (
                  <EditFAQResponseModal
                    addFromMessage={addFromMessage}
                    answerEID={group.eid}
                    group={group}
                    index={i}
                    mountNodeSelector={mountNodeSelector}
                    nonEmptyLineRequired={nonEmptyLineRequired}
                    onEdit={this.handleOnEdit}
                    query={query}
                    showAddResponse={showAddResponse}
                  />
                ) : null}

                {isEditable && group.mainAnswerisProtected ? (
                  <Button
                    basic
                    icon='copy'
                    onClick={this.onCloneProtected(i)}
                    style={style.deleteButton}
                  />
                ) : null}

                {group.mainAnswerisProtected ? (
                  <Checkbox
                    checked={Boolean(group.mainAnswerActive)}
                    onClick={this.handleOnToggleActive(group.answerEID)}
                    style={checkBoxStyle}
                    toggle
                  />
                ) : null}
              </div>

              <Header
                content={'Applies to:'}
                floated={'left'}
                size={'tiny'}
                style={style.header}
                subheader={group.locationdeptrole}
              />
            </Segment>
            <Segment attached={'bottom'}>
              <List items={group.responsesMeta} />
            </Segment>
          </React.Fragment>
        ))}

        {submitFailed && error ? (
          <ErrorLabel error={error} inputName={input.name} pointing='above' />
        ) : null}

        {formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )}

        {!isEditable &&
        buttonShowBottomAfter &&
        fields.length >= Number(buttonShowBottomAfter) ? (
          <Button
            basic
            content={buttonAddResponseText}
            floated='right'
            onClick={this.handleAddResponses}
            type='button'
          />
        ) : null}
      </>
    );
  }
}

export const ResponsesGroupTest = ResponsesGroup;

export default ResponsesGroupController(ResponsesGroup);
