import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';

import BlockModal from './BlockModal';
import MultipleProductFulfillment from '../molecules/MultipleProductFulfillment';

class SelectEquipmentFulfillmentQuestionsModal extends PureComponent {
  static propTypes = {
    isEquipFulfillmentFormsValid: PropTypes.bool,
    onFulfillmentAnswers: PropTypes.func,
    productIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    resetBlockLoadingState: PropTypes.func,
    /** From Controller: Function that will perform remote submit on all these products */
    submitProductFulfillmentForms: PropTypes.func,
    trigger: PropTypes.element,
  };

  static defaultProps = {
    isEquipFulfillmentFormsValid: false,
    onFulfillmentAnswers: _.noop,
    resetBlockLoadingState: _.noop,
    submitProductFulfillmentForms: _.noop,
    trigger: null,
  };

  setModal = (modal) => {
    this.modal = modal;
  };

  open = () => {
    this.modal.open();
  };

  close() {
    this.modal.close();
  }

  handleOnFulfillmentAnswers = (answers) => {
    const { onFulfillmentAnswers } = this.props;
    onFulfillmentAnswers(answers);
  };

  handleOnModalClose = () => {
    const { resetBlockLoadingState } = this.props;
    resetBlockLoadingState();
  };

  render() {
    const {
      isEquipFulfillmentFormsValid,
      productIDs,
      submitProductFulfillmentForms,
      trigger,
    } = this.props;

    return (
      <BlockModal
        modalActions={
          <Button
            content={'Submit'}
            disabled={!isEquipFulfillmentFormsValid}
            onClick={submitProductFulfillmentForms}
            primary
          />
        }
        modalTitle={'Complete Fulfillment Options'}
        onClose={this.handleOnModalClose}
        ref={this.setModal}
        trigger={trigger}
      >
        <MultipleProductFulfillment
          onSubmit={this.handleOnFulfillmentAnswers}
          products={productIDs}
        />
      </BlockModal>
    );
  }
}

export default SelectEquipmentFulfillmentQuestionsModal;
