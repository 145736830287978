import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureBookshelves extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M66.7 16H28.5c-1.657 0-3 1.343-3 3v53.64c0 1.657 1.343 3 3 3h.41v3.64h2v-3.64h33.2v3.64h2v-3.64h.6c1.657 0 3-1.343 3-3V19c0-.797-.317-1.562-.882-2.125s-1.33-.878-2.128-.875zM46.5 47.44v11.63h-19V47.44h19zm-19-2V32.68h19v12.76h-19zm21 2h19.2v11.63H48.5V47.44zm0-2V32.68h19.2v12.76H48.5zM67.7 19v11.68H48.5V18h18.2c.552 0 1 .448 1 1zm-39.2-1h18v12.68h-19V19c0-.552.448-1 1-1zm-1 54.64V61.07h19v12.57h-18c-.552 0-1-.448-1-1zm39.21 1H48.5V61.07h19.2v11.57c0 .552-.448 1-1 1h.01z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogFurnitureBookshelves;
