import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareProjectAndTasking extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M52.44 52.5l-6.23 6.07-3.56-3.33c-.406-.332-1-.294-1.36.088-.36.38-.364.975-.01 1.362l4.25 4c.386.368.994.368 1.38 0l6.91-6.73c.395-.387.402-1.02.015-1.415s-1.02-.402-1.415-.015l.02-.03z' />
          <path d='M35.5 70.75h24.37c.828 0 1.5-.672 1.5-1.5V41.52c0-.828-.672-1.5-1.5-1.5h-7.3c-.12-2.64-2.293-4.718-4.935-4.718-2.642 0-4.816 2.08-4.935 4.718h-7.2c-.828 0-1.5.672-1.5 1.5v27.73c0 .828.672 1.5 1.5 1.5zm10.21-30.73c.07-1.022.937-1.804 1.96-1.77 1.024.036 1.836.876 1.836 1.9s-.812 1.864-1.835 1.9c-1.02.034-1.89-.748-1.96-1.77-.003-.087-.003-.173 0-.26zm-8.71 3h5.4c-.077.152-.118.32-.12.49v2.59c0 .657.533 1.19 1.19 1.19h8.43c.657 0 1.19-.533 1.19-1.19v-2.6c-.002-.17-.043-.338-.12-.49h5.4v24.74H37V43.02zm-7.42 26.02v.02-.02z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.66c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.538 15 25.334 15 23.85c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareProjectAndTasking;
