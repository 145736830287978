import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Dropdown, Form, Header } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import {
  find,
  hasIn,
  isBoolean,
  isEmpty,
  isEqual,
  map,
  noop,
  some,
  startCase,
} from 'lodash';
import onClickOutside from 'react-onclickoutside';

// V2 components
import Slider from '../Slider';

// Own module
import ListFilterContainer from './ListFilterContainer';
import ListFilterTrigger from './ListFilterTrigger';
import ListFilterMenu from './ListFilterMenu';

// Globals
import FilterInputTypes from '../../../../v1/globals/FilterInputTypes';

// Organisms
import LocationSelect from '../../../../v1/components/organisms/LocationSelect';
import UserPicker from '../../functional/UserPicker/UserPicker';

// Style
import style from './ListFilter.module.css';

const optionShape = PropTypes.shape({
  checked: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

// DEPRECATED: This shape will be going away once the new type below is implemented
const filterShape = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionShape).isRequired,
  type: PropTypes.oneOf(Object.values(FilterInputTypes)).isRequired,
});

// NEW: this is the new shape for Filters. It will be switched to `filters` once
// all app instances are switched.
const groupShape = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionShape).isRequired,
  type: PropTypes.oneOf(Object.values(FilterInputTypes)).isRequired,
});

const sectionShape = PropTypes.shape({
  filters: PropTypes.arrayOf(groupShape),
  title: PropTypes.string.isRequired,
});

class ListFilter extends PureComponent {
  static propTypes = {
    actionTrigger: PropTypes.element,
    closeOnSelection: PropTypes.bool,
    // Whether filter should auto close on selection
    filteredCount: PropTypes.number,
    filteredCountLabel: PropTypes.string,
    filters: PropTypes.arrayOf(filterShape),
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    onFilterChange: PropTypes.func,
    // returns the whole state.filterValues state on any filter change
    open: PropTypes.bool,
    resetFunc: PropTypes.func,
    resetText: PropTypes.string,
    sections: PropTypes.arrayOf(sectionShape),
    sortByAdmin: PropTypes.string,
  };

  static defaultProps = {
    actionTrigger: null,
    closeOnSelection: false,
    filteredCount: null,
    filteredCountLabel: '',
    filters: null,
    isLoading: false,
    label: 'Filters',
    onFilterChange: noop,
    open: false,
    resetFunc: noop,
    resetText: '',
    sections: null,
  };

  state = {
    filterValues: this.generateFilterValuesFromProps(this.props),
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    hidden: !this.props.open,
    isLocationPickerOpen: false,
    isPickerOpen: {},
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    open: this.props.open,
  };

  componentDidMount() {
    // we only need this message until we have migrated from the old `filter` type
    const { filters, sections } = this.props;

    if (filters && sections) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'ERROR: You cannot define both `filters` and `sections` props on ListFilter.'
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { closeOnSelection, filters, sections, open } = this.props;
    // Automatically closing the filter
    if (closeOnSelection && open !== nextProps.open) {
      this.handleToggleOpen();
    }

    // makes sure to update thee filterValues if props change
    if (filters !== nextProps.filters || sections !== nextProps.sections) {
      const modifiedFilterValues =
        this.generateFilterValuesFromProps(nextProps);
      this.setState({
        filterValues: modifiedFilterValues,
      });
    }
  }

  getTranslation = (text) => {
    return text && text.indexOf('label.') > -1
      ? intl.formatMessage({ id: text })
      : text;
  };

  /**
   * Will adjust the filterValues state according to props
   * @param {*} props
   */
  generateFilterValuesFromProps(props) {
    const { filters, sections } = props;

    let filterValues = {};

    const generateOptionValuesForAllFilters = (filters) => {
      const map = {};
      filters.forEach((filter) => {
        map[filter.key] = filter.options.map((opt) => ({
          checked: Boolean(opt.checked),
          value: opt.value,
        }));
      });

      return map;
    };

    if (!isEmpty(sections)) {
      sections.forEach((section) => {
        filterValues[section.title] = generateOptionValuesForAllFilters(
          section.filters
        );
      });
    } else if (!isEmpty(filters)) {
      filterValues = generateOptionValuesForAllFilters(filters);
    }

    return filterValues;
  }

  /**
   * Updates the state according to the modification in the provided filter
   * @param {*} filter filter that was modified
   * @param {*} data The data object passed by semantic UI event handler
   * @param {*} cb
   */
  updateFilterState = (filter, data, cb = noop) => {
    const { filters, sections } = this.props;

    const filterKey = filter.key;
    const isRadio = filter.type === FilterInputTypes.RADIO;
    const { filterValues } = this.state;

    this.setState(
      (state) => {
        const generateUpdatedOptionValues = (filter, sectionTitle) =>
          filter.options.map((opt, i) => {
            let shouldUpdate = true;
            const isTheModifiedOption = opt.value === data.value;
            let isChildModified = false;

            // Handles dropRadio state
            if (
              Object.prototype.hasOwnProperty.call(opt, 'dropOptions') &&
              Object.prototype.hasOwnProperty.call(data, 'options') &&
              isEqual(opt.dropOptions, data.options)
            ) {
              const index = opt.dropOptions.findIndex(
                (dropOption) => dropOption.value === data.value
              );
              if (index > -1) {
                opt.dropOptions = opt.dropOptions.map((dropOption) => ({
                  ...dropOption,
                  selected: false,
                }));
                opt.dropOptions[index].selected = true;
                isChildModified = true;
              }
            }

            // If it is not parent maybe is a child

            if (
              !isTheModifiedOption &&
              Object.prototype.hasOwnProperty.call(opt, 'options')
            ) {
              const index = opt.options.findIndex(
                (subOption) => subOption.value === data.value
              );
              if (index > -1) {
                if (opt.type === FilterInputTypes.DROPRADIO) {
                  // Is a Radio with dropdown
                  opt.options = opt.options.map((option) => ({
                    ...option,
                    checked: false,
                  }));
                }
                opt.options[index].checked = true;
                isChildModified = true;
              }
            }

            // uses the checked state if exist, otherwise uses the default prop value
            let checkedInState;
            if (sectionTitle) {
              checkedInState = hasIn(state, [
                'filterValues',
                sectionTitle,
                filterKey,
                i,
              ])
                ? state.filterValues[sectionTitle][filterKey][i].checked
                : Boolean(opt.checked);
            } else {
              checkedInState = hasIn(state, ['filterValues', filterKey, i])
                ? state.filterValues[filterKey][i].checked
                : Boolean(opt.checked);
            }

            // if filter is forced at least one option should be checked
            if (
              filter.isForced &&
              !isRadio &&
              isTheModifiedOption &&
              checkedInState
            ) {
              const newState = [
                ...state.filterValues[sectionTitle][filterKey]
                  .filter((val, index) => index !== i)
                  .map((val) => val.checked),
                data.checked,
              ];

              shouldUpdate = !newState.every((val) => !val);
            }

            const returned = {
              checked: isRadio
                ? // for radio inputs, they're mutually exclusive, so we have to mark any other option as unchecked
                  // If it is dropRadio we need to keep parent check value
                  isTheModifiedOption || isChildModified
                  ? data.checked || data['data-name'] === opt.value
                  : false
                : isTheModifiedOption && shouldUpdate
                ? data.checked
                : checkedInState,
              value: opt.value,
              ...(opt.checked &&
                Object.prototype.hasOwnProperty.call(opt, 'type') && {
                  // Sets location picker value

                  ...(Object.prototype.hasOwnProperty.call(
                    data,
                    'specificLocation'
                  ) &&
                    opt.type === FilterInputTypes.LOCATION && {
                      specificLocation: data.specificLocation.join(),
                    }),
                  // Sets dropdown values(s)

                  ...(Object.prototype.hasOwnProperty.call(
                    data,
                    'specificTypes'
                  ) &&
                    opt.type === FilterInputTypes.DROPDOWN &&
                    isEqual(
                      data.options.map((option) => option.key),
                      opt.options.map((option) => option.key)
                    ) && { specificTypes: data.specificTypes.join() }), // I need to join the array values in order to prevent the merge of old values with new ones by mergeDeep
                  // Sets user picker value

                  ...(Object.prototype.hasOwnProperty.call(
                    data,
                    'specificUser'
                  ) &&
                    opt.type === FilterInputTypes.USER && {
                      specificUser: data.specificUser.join(),
                    }),
                  // dropDown option

                  ...(Object.prototype.hasOwnProperty.call(
                    opt,
                    'dropOptions'
                  ) && {
                    dropOptions: [...opt.dropOptions],
                  }),
                  // sub options

                  ...(Object.prototype.hasOwnProperty.call(opt, 'options') && {
                    options: [...opt.options],
                  }),
                }),
            };

            return returned;
          });

        if (!isEmpty(sections)) {
          // Finds the section where this filter belongs
          const findSection = find(sections, (section) =>
            find(section.filters, (f) => f.key === filterKey)
          );
          if (findSection) {
            const sectionTitle = findSection.title;
            state.filterValues[sectionTitle][filterKey] =
              generateUpdatedOptionValues(filter, sectionTitle);
          }
        } else if (!isEmpty(filters)) {
          state.filterValues[filterKey] = generateUpdatedOptionValues(filter);
        }

        return state;
      },
      () => {
        cb(filterValues);
      }
    );
  };

  /**
   * Serves as proxy to apply each filter group own handler
   * as we as to update the entire state of the selected filter values
   *
   * @param {*} filterKey filter own key as defined in props
   * @param {*} options group options as defined in props
   * @param {*} groupOnChange group own onChange prop
   * @param {*} event synthetic event sent by semantic UI's component onChange
   * @param {*} data data sent by semantic UI's component onChange
   */
  applyFilterGroupHandler =
    (filter, groupOnChange = noop) =>
    (event, data) => {
      const { onFilterChange } = this.props;
      groupOnChange(event, data); // this is exactly what the Form onChange prop would be passing to the group onChange prop
      this.updateFilterState(filter, data, onFilterChange);
    };

  handleDropRadioChange =
    (filter, groupOnChange = noop) =>
    (event, data) => {
      this.setState({ [data['data-name']]: data.value });
      this.applyFilterGroupHandler(filter, groupOnChange)(event, data);
    };

  /**
   * Updates the state according to the selected location
   * @param {*} filter filter that was modified
   * @param {*} groupOnChange group own onChange prop
   * @param {*} value value of the clicked option
   * @param {*} data data sent by semantic UI's component onChange
   * @param {*} event synthetic event sent by semantic UI's component onChange
   */
  handleSelectLocationClick =
    (filter, groupOnChange = noop, value) =>
    (data, event) => {
      const newState = this.state;
      const locations = newState[value];
      const locationId = data.get('id');

      if (
        !locations ||
        isEmpty(locations) ||
        !locations.find((location) => location.value === locationId)
      ) {
        // Prevent from adding duplicate locations
        let locationTypeName = data.get('location_type_name');
        locationTypeName = startCase(locationTypeName);

        this.setState({
          [value]: [
            ...(newState[value] || []),
            {
              text: `${locationTypeName}: ${data.get('name')}`,
              value: locationId,
            },
          ],
        });

        const { specificLocation } = filter.options.find(
          (option) => option.value === value
        );
        const selectedLocations = specificLocation
          ? specificLocation.split(',')
          : [];
        this.applyFilterGroupHandler(filter, groupOnChange)(event, {
          checked: true,
          specificLocation: [...selectedLocations, locationId],
          value,
        });
      }
    };

  /**
   * Updates the state according to the selected user
   * @param {*} filter filter that was modified
   * @param {*} groupOnChange group own onChange prop
   * @param {*} value value of the clicked option
   * @param {*} userId ID of the selected user
   * @param {*} userDisplayName display name of the user selected
   */
  handleUserSelect =
    (filter, groupOnChange = noop, value) =>
    (userId, userDisplayName) => {
      const newState = this.state;
      const users = newState[value];

      if (
        !users ||
        isEmpty(users) ||
        !users.find((user) => user.value === userId)
      ) {
        // Prevent from adding duplicate users
        this.setState({
          [value]: [
            ...(newState[value] || []),
            {
              text: userDisplayName,
              value: userId,
            },
          ],
        });

        const { specificUser } = filter.options.find(
          (option) => option.value === value
        );
        const selectedUsers = specificUser ? specificUser.split(',') : [];
        this.applyFilterGroupHandler(filter, groupOnChange)(null, {
          checked: true,
          specificUser: [...selectedUsers, userId],
          value,
        });
      }
    };

  /**
   * Updates the state if an user is removed from the dropdown
   * @param {*} filter filter that was modified
   * @param {*} groupOnChange group own onChange prop
   * @param {*} value value of the clicked option
   * @param {*} event synthetic event sent by semantic UI's component onChange
   * @param {*} data data sent by semantic UI's component onChange
   */
  handleUserFieldChange =
    (filter, groupOnChange = noop, value) =>
    (event, data) => {
      if (isEmpty(data.value)) {
        // All users were removed
        this.setState({ [value]: [] });
        this.applyFilterGroupHandler(filter, groupOnChange)(null, {
          checked: true,
          specificUser: [],
          value,
        });
      } else {
        // Removes specific user
        const filteredSelectedUsers = data.options.filter((option) =>
          data.value.includes(option.value)
        );
        if (!isEmpty(filteredSelectedUsers)) {
          this.setState({ [value]: filteredSelectedUsers });
          this.applyFilterGroupHandler(filter, groupOnChange)(null, {
            checked: true,
            specificUser: filteredSelectedUsers.map((user) => user.value),
            value,
          });
        }
      }
    };

  /**
   * Updates the state if an user is removed from the dropdown
   * @param {*} filter filter that was modified
   * @param {*} groupOnChange group own onChange prop
   * @param {*} value value of the clicked option
   * @param {*} event synthetic event sent by semantic UI's component onChange
   * @param {*} data data sent by semantic UI's component onChange
   */
  handleLocationFieldChange =
    (filter, groupOnChange = noop, value) =>
    (event, data) => {
      if (isEmpty(data.value)) {
        // All locations were removed
        this.setState({ [value]: [] });
        this.applyFilterGroupHandler(filter, groupOnChange)(null, {
          checked: true,
          specificLocation: [],
          value,
        });
      } else {
        // Removes specific location
        const filteredSelectedLocations = data.options.filter((option) =>
          data.value.includes(option.value)
        );
        if (!isEmpty(filteredSelectedLocations)) {
          this.setState({ [value]: filteredSelectedLocations });
          this.applyFilterGroupHandler(filter, groupOnChange)(null, {
            checked: true,
            specificLocation: filteredSelectedLocations.map(
              (location) => location.value
            ),
            value,
          });
        }
      }
    };

  /**
   * Updates the state according to the selected user
   * @param {*} filter filter that was modified
   * @param {*} groupOnChange group own onChange prop
   * @param {*} value value of the clicked option
   * @param {*} event synthetic event sent by semantic UI's component onChange
   * @param {*} data data sent by semantic UI's component onChange
   */
  handleSelect =
    (filter, groupOnChange = noop, value) =>
    (event, data) => {
      this.applyFilterGroupHandler(filter, groupOnChange)(event, {
        checked: true,
        options: data.options,
        specificTypes: data.value,
        value,
      });
    };

  /**
   * Updates the "isLocationPickerOpen" state flag when LocationSelect "onOpen" in called.
   */
  handleOpenLocationSelect = () =>
    this.setState({ isLocationPickerOpen: true });

  /**
   * Updates the "isLocationPickerOpen" state flag when LocationSelect "onClose" in called.
   */
  handleCloseLocationSelect = () =>
    this.setState({ isLocationPickerOpen: false });

  /**
   * Adds a flag to isPickerOpen and sets he value to true, if the blag was already added it will toggle the value
   * @param {*} optionValue the value of the picker option
   */
  handleToggleUserModal = (optionValue) => () =>
    this.setState(({ isPickerOpen }) => ({
      isPickerOpen: {
        ...isPickerOpen,
        [optionValue]: isBoolean(isPickerOpen[optionValue])
          ? !isPickerOpen[optionValue]
          : true,
      },
    }));

  handleToggleOpen = () => {
    const { open } = this.state;

    if (open) {
      this.setState({
        open: false,
      });
      setTimeout(() => {
        this.setState({
          hidden: true,
        });
      }, 100);
    } else {
      this.setState({
        hidden: false,
      });
      setTimeout(() => {
        this.setState({
          open: true,
        });
      }, 100);
    }
  };

  handleClickOutside() {
    // close it when:
    // - it's open and user clicks outside of it
    // - location picker is closed
    // - user picker is closed
    const { open, isLocationPickerOpen, isPickerOpen } = this.state;

    if (open && !isLocationPickerOpen && !some(Object.values(isPickerOpen))) {
      this.setState({
        hidden: true,
        open: false,
      });
    }
  }

  handleDropRadioClick = (evt) => {
    evt.stopPropagation();
  };

  renderSubFilters = ({
    checkedValue,
    filter,
    handleChange,
    option,
    usersSelected,
  }) => {
    if (!Object.prototype.hasOwnProperty.call(option, 'type')) {
      return null;
    }

    const { isPickerOpen } = this.state;
    const newState = this.state;

    if (option.type === FilterInputTypes.LOCATION) {
      return (
        <LocationSelect
          className={style.field}
          disabled={!checkedValue}
          fluid
          multiple
          onChange={this.handleSelectLocationClick(
            filter,
            handleChange,
            option.value
          )}
          onClose={this.handleCloseLocationSelect}
          onOpen={this.handleOpenLocationSelect}
          placeholder={intl.formatMessage({
            id: option.input.placeholder,
          })}
          selection
        />
      );
    } else if (option.type === FilterInputTypes.USER) {
      return (
        <UserPicker
          getSelectedName
          header={intl.formatMessage({ id: option.input.header })}
          isSearchable
          onClose={this.handleToggleUserModal(option.value)}
          onUserSelect={this.handleUserSelect(
            filter,
            handleChange,
            option.value
          )}
          open={isPickerOpen[option.value]}
          trigger={
            <Dropdown
              className={style.field}
              disabled={!checkedValue}
              multiple
              onChange={this.handleUserFieldChange(
                filter,
                handleChange,
                option.value
              )}
              onClick={this.handleToggleUserModal(option.value)}
              options={usersSelected}
              placeholder={intl.formatMessage({
                id: option.input.placeholder,
              })}
              selection
              value={map(usersSelected, 'value')}
            />
          }
          {...option.input.pickerProps}
        />
      );
    } else if (
      !isEmpty(option.options) &&
      option.type === FilterInputTypes.DROPDOWN
    ) {
      return (
        <Dropdown
          className={style.field}
          disabled={!checkedValue}
          multiple
          onChange={this.handleSelect(filter, handleChange, option.value)}
          options={[
            ...option.options.map((option) => ({
              ...option,
              text: this.getTranslation(option.text),
            })),
          ]}
          placeholder={intl.formatMessage({
            id: option.input.placeholder,
          })}
          search={option.options.length > 5}
          selection
        />
      );
    } else if (
      !isEmpty(option.options) &&
      option.type === FilterInputTypes.DROPRADIO
    ) {
      const translatedOptions = option.dropOptions.map((option) => {
        option.text = this.getTranslation(option.text);
        return {
          ...option,
        };
      });

      const dropDownOptions = translatedOptions;

      return [
        option.options.map(
          (
            subOption,
            i // subOption second level radio
          ) => {
            return (
              <Form.Checkbox
                checked={subOption.checked}
                className={style.field}
                disabled={!checkedValue}
                key={`${subOption.value}.${i.toString()}`}
                label={
                  <label>{intl.formatMessage({ id: subOption.text })}</label>
                }
                name={subOption.key}
                onChange={this.applyFilterGroupHandler(filter, handleChange)}
                onClick={this.handleDropRadioClick}
                radio
                value={subOption.value}
              />
            );
          }
        ),
        <Dropdown
          className={style.dropradio}
          data-name={option.name}
          disabled={!checkedValue}
          key={`${filter.value}.${option.value}`}
          onChange={this.handleDropRadioChange(filter, handleChange)}
          options={dropDownOptions}
          selection
          value={
            newState[option.value] ||
            option.dropOptions.find((dropOption) => dropOption.selected).value
          }
        />,
      ];
    }

    return null;
  };

  renderFilters = (filters, sectionTitle, sortByAdmin) => {
    const renderedFilters = filters.map((filter, i) => {
      // options from the same filter share same handler
      const handleChange = filter.onChange;
      const newState = this.state;
      const { filterValues } = this.state;
      const { isLoading } = this.props;

      return (
        <Form
          as='div'
          key={filter.key}
          style={{
            marginTop: '1em',
          }}
        >
          {filter.label ? (
            <Header
              as='h5'
              content={intl.formatMessage({ id: filter.label })}
            />
          ) : null}

          {filter.options.map((option, i) => {
            const isRadio = filter.type === FilterInputTypes.RADIO;

            const usersSelected = newState[option.value] || [];

            // uses the checked state if exist, otherwise uses the default prop value
            const checkedValue = sectionTitle
              ? hasIn(filterValues, [sectionTitle, filter.key, i])
                ? filterValues[sectionTitle][filter.key][i].checked
                : option.checked
              : hasIn(filterValues, [filter.key])
              ? filterValues[filter.key][i].checked
              : option.checked;
            const isRecommended = option.text === sortByAdmin;
            return (
              <Fragment key={`${option.value}.${i.toString()}`}>
                <Form.Checkbox
                  checked={checkedValue}
                  className={style.formCheckbox}
                  disabled={isLoading}
                  label={
                    isRecommended
                      ? `${intl.formatMessage({
                          id: option.text,
                        })} (${intl.formatMessage({
                          id: 'label.recommended',
                        })})`
                      : intl.formatMessage({ id: option.text })
                  }
                  name={isRadio ? filter.key : `${filter.key}.${option.value}`}
                  onChange={this.applyFilterGroupHandler(filter, handleChange)}
                  radio={isRadio}
                  value={option.value}
                />
                {this.renderSubFilters({
                  checkedValue,
                  filter,
                  handleChange,
                  option,
                  usersSelected,
                })}
              </Fragment>
            );
          })}

          {i + 1 < filters.length ? (
            <Divider key={`divider.${filter.key}`} />
          ) : null}
        </Form>
      );
    });

    return renderedFilters;
  };

  render() {
    const {
      actionTrigger,
      filteredCount,
      filteredCountLabel,
      filters,
      label,
      resetFunc,
      resetText,
      sections,
      sortByAdmin,
    } = this.props;
    const { hidden, open } = this.state;

    const labelTrigger = <span className='text'>{label}</span>;

    return (
      <ListFilterContainer open={open}>
        <ListFilterTrigger
          action={actionTrigger}
          filteredCount={filteredCount}
          filteredCountLabel={filteredCountLabel}
          label={labelTrigger}
          onClick={this.handleToggleOpen}
          open={open}
        />
        <ListFilterMenu hidden={hidden} open={open}>
          {resetText ? ( // Temporal, will be properly implemented later
            <Button content={resetText} onClick={resetFunc} />
          ) : null}
          {filters ? this.renderFilters(filters) : null}

          {sections ? (
            <Slider
              carouselSwipe={false}
              navType='tabs'
              swipeable
              tabs={sections}
            >
              {sections.map((section) =>
                this.renderFilters(section.filters, section.title, sortByAdmin)
              )}
            </Slider>
          ) : null}
        </ListFilterMenu>
      </ListFilterContainer>
    );
  }
}

export default onClickOutside(ListFilter);
