import buildCompositeController from '../../utils/buildCompositeController';

import integrationThunks from '../../actions/integrationThunks';
import userEntitiesThunks from '../../actions/userEntitiesThunks';
import userEntitiesActions from '../../actions/userEntitiesActions';

// Selector
import { getAllUsers } from '../../selectors/getAllUsers';
import { getCurrentUserId } from '../../selectors/getCurrentUser';
import getGroupPermissions from '../../selectors/getGroupPermissions';
import tenantThunks from '../../actions/tenantThunks';

import IntegrationCSVTools from '../../utils/IntegrationCSVTools';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state) => {
  const integrationStore = state.get('integration');
  const businessList = integrationStore.get('businessObjects');
  const isLoadingFile = integrationStore.get('isUploading');

  return {
    businessList,
    countTotalUsers: state.getIn(['usersAdmin', 'count']),
    currentUserId: getCurrentUserId(state),
    groupPermissions: getGroupPermissions(state),
    isLoading: state.getIn(['usersAdmin', 'isLoading']),
    isLoadingFile,
    isLoadingRole: state.getIn(['usersAdmin', 'isLoadingRole']),
    isLoadingSearch: state.getIn(['usersAdmin', 'isSearching']),
    searchTerm: state.getIn(['usersAdmin', 'searchTerm']),
    usersList: getAllUsers(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addOrRemoveRoleToUser(userID, role, add) {
    dispatch(userEntitiesThunks.addOrRemoveRoleToUser(userID, role, add));
  },

  handleUploadCSV(file, businessObjectID) {
    dispatch(integrationThunks.uploadCSVFile(file, businessObjectID))
      .then((messages) => {
        const msgList = IntegrationCSVTools.formatCSVResponse(messages, []);
        msgList.forEach((item) => {
          dispatch(
            toastNotificationsActions.success({
              message: item.content,
              title: item.title,
            })
          );
        });
      })
      .catch((error) => {
        const msgList = IntegrationCSVTools.formatCSVResponse(error, []);
        msgList.forEach((item) => {
          dispatch(
            toastNotificationsActions.error({
              message: item.content,
              title: item.title,
            })
          );
        });
      });
  },

  isSearching() {
    dispatch(userEntitiesActions.isSearchingUser());
  },

  loadBusinessObjectList() {
    dispatch(integrationThunks.loadBusinessObjectList());
  },

  loadListUsers(
    page = 1,
    limit = 24,
    getHideFromDirectory,
    getInactiveUsers,
    loadGroup
  ) {
    /** Offset logic */
    const offset = (page - 1) * limit;

    if (loadGroup) {
      dispatch(tenantThunks.getTenantUserGroupPermissions);
    }
    dispatch(
      userEntitiesThunks.getUsersBy({
        getHideFromDirectory: getHideFromDirectory || false,
        getInactiveUsers: getInactiveUsers || false,
        limit: limit,
        offset: offset,
        orderBy: 'last_name',
      })
    );
  },

  searchUsers(
    limit = 24,
    page = 1,
    orderBy = 'last_name',
    getHideFromDirectory,
    getInactiveUsers
  ) {
    /** Offset logic */
    const offset = (page - 1) * limit;

    /** Launch search */
    const resetUsersList = true; // Reset the userslist just right after we get the API response and before we pass the new one
    dispatch(
      userEntitiesThunks.searchTerm({
        getHideFromDirectory: getHideFromDirectory || false,
        getInactiveUsers: getInactiveUsers || false,
        limit,
        offset,
        orderBy,
        resetUsersList,
      })
    );
  },

  setSeachTerm(term) {
    dispatch(userEntitiesActions.setSearchUsersTerm(term));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
