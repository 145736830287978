import React from 'react';
import { Progress } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import OnboardProgressOverallStates from '../../globals/OnboardProgressOverallStates';
// Package
import { intl } from 'esp-util-intl';

const propTypes = {
  isLoading: PropTypes.bool,

  overallState: PropTypes.string.isRequired,

  progress: ImmutablePropTypes.map.isRequired,
};

const OnboardProgressBar = ({
  progress,
  isLoading = false,
  overallState,
  ...rest
}) => {
  if (isLoading) {
    return null;
  }

  let color;

  if (overallState === OnboardProgressOverallStates.LATE) {
    color = 'yellow';
  } else if (overallState === OnboardProgressOverallStates.AT_RISK) {
    color = 'black';
  } else {
    color = 'green';
  }

  const showNoTasksText =
    progress.get('total').toString() === '0' &&
    progress.get('completed').toString() === '0';

  if (showNoTasksText) {
    return (
      <span>
        {intl.formatMessage({
          id: 'label.no_tasks',
        })}
      </span>
    );
  }

  return (
    <Progress
      color={color}
      progress='ratio'
      total={progress.get('total')}
      value={progress.get('completed')}
      {...rest}
    />
  );
};

OnboardProgressBar.propTypes = propTypes;

export default OnboardProgressBar;
