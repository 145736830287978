import React, { useCallback } from 'react';
import pt from 'prop-types';

import PlatformContext from './platformContext';
import PlatformAdapter from './PlatformAdapter';

const platform = new PlatformAdapter();

const propTypes = {
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
};

//
// Platform provider
//
// Applications are distributed to many platforms, the goal of this provider
// is to expose functionality that abstracts away the differences between platform
// implementations for common processes, so applications are able to just use the
// exposed methods of the Platform context without having to know which platform
// they are running on.
//
// All this is achieved by leveraging the PlatformAdapter, a class that derives the
// current platform and uses its API to carry out the tasks.
const PlatformProvider = ({ children }) => {
  const { type } = platform;

  //
  // Triggers the SSO flow of the current platform
  //
  // @param {String} authorizationEndpoint The URL to load in the new (popup) window
  // @returns {Object} result An object with the SSO result information
  const handleSingleSignOn = useCallback((authorizationEndpoint) => {
    // console.log('Requesting SSO');
    return platform.handleSingleSignOn(authorizationEndpoint);
  }, []);

  const disclosureObject = {
    handleSingleSignOn,
    type,
  };

  return (
    <PlatformContext.Provider value={disclosureObject}>
      {children}
    </PlatformContext.Provider>
  );
};

PlatformProvider.propTypes = propTypes;

export default PlatformProvider;
