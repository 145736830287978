import {
  change,
  getFormSyncErrors,
  isDirty,
  isValid,
} from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';

// thunks
// import catalogThunks            from '../../actions/catalogThunks';
import integrationThunks from '../../actions/integrationThunks';

const FORM_NAME = 'SnowConnection';

const mapStateToProps = (state) => ({
  formValues: state.getIn(['form', FORM_NAME, 'values']),
  isDirty: isDirty(FORM_NAME)(state),
  isLoadingSnowConnectionStatus: state.getIn([
    'integration',
    'isLoadingSnowConnectionStatus',
  ]),
  isLoadingSnowCredentials: state.getIn([
    'integration',
    'isLoadingSnowCredentials',
  ]),
  isValid: isValid(FORM_NAME)(state),
  snowConnectionStatus: state.getIn(['integration', 'snowConnectionStatus']),
  snowCredentials: state.getIn(['integration', 'snowCredentials']),
  syncErrors: getFormSyncErrors(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearPassword: () => {
    dispatch(change('SnowConnection', 'password', ''));
  },
  formValidation: (values) => {
    const errors = {};

    if (!values.get('url')) {
      errors.url = 'Cannot be empty';
    }

    if (!values.get('username')) {
      errors.username = 'Cannot be empty';
    }

    if (!values.get('password')) {
      errors.password = 'Cannot be empty';
    }

    return errors;
  },
  getSnowConnectionStatus: () => {
    dispatch(integrationThunks.getSnowConnectionStatus());
  },
  getSnowCredentials: () => {
    dispatch(integrationThunks.getSnowCredentials());
  },
  saveSnowCredentials: () => {
    dispatch(integrationThunks.postSnowCredentials());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
