const api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966 = {
  acknowledgments: [],
  activation_condition: null,
  active: true,
  additional_text: [
    {
      additional_text: '',
      eid: '74fb2038-76e4-4646-9018-ff26c388881e',
      match_validation: false,
    },
  ],
  answer_expiry_info: {
    earliest_answer_expiry: null,
    total_expired_active_answers: 0,
  },
  answers: [
    {
      active: true,
      condition: '',
      eid: '492a1dc1-cca8-46da-9689-92244f2ee0ec',
      else_condition: false,
      end_date: null,
      is_protected: false,
      lines: [
        {
          active: true,
          eid: '5abe1e16-712c-45c3-8068-db4ae1888831',
          is_protected: false,
          order: 2,
          phrase: 'Nre Respoinse test',
        },
      ],
      start_date: null,
      type: null,
    },
  ],
  application_active: true,
  application_eid: 'ac1121da-0f33-4daf-b951-07f8b8f98797',
  application_name: '401 Questions',
  attributes: [
    {
      eid: '440ac07c-d20c-4c1d-b4a0-de2014aa2c80',
      name: 'service_department',
      value: '87561ee1-ed72-4757-9e49-80a41bb36a3b',
    },
  ],
  case_feedback: 0.0,
  cases_created: 0,
  deflected: 0.0,
  description: '',
  eid: '75dc12b2d7fd575966',
  helpful: 0,
  is_kb: true,
  is_protected: false,
  is_reviewed: false,
  last_used: '',
  name: '401 Questions override',
  not_helpful: 0,
  sys_created_by: 'kharma.dharma',
  sys_date_created: '2020-07-29T21:23:58.768740Z',
  sys_date_updated: '2020-07-29T21:24:09.168650Z',
  sys_updated_by: 'kharma.dharma',
  total_completions: 0,
  triggered_misspell_total: 0,
  triggered_total: 0,
  triggers: [],
  triggers_total: 0,
};

export default api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966;
