import React from 'react';
import pt from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import InputDescription from './InputDescription';

const propTypes = {
  description: pt.string,
  id: pt.string.isRequired,
  label: pt.string.isRequired,
};

const InputField = ({ description, label, id, ...rest }) => {
  return (
    <Form.Field>
      <label htmlFor={id}>{label}</label>
      <Input id={id} {...rest} />
      {description && <InputDescription description={description} />}
    </Form.Field>
  );
};

InputField.propTypes = propTypes;

export default InputField;
