import { isObjectLike, reduce, uniq } from 'lodash';

/**
 * Returns an array of unique values for a given property in an object and its nested objects.
 * @param {Object} obj - The object to search for the property.
 * @param {string} prop - The property to search for.
 * @returns {Array} - An array of unique values for the property.
 */
const getPropertyRecursively = (obj, prop) => {
  /**
   * Recursively searches for the property in an object and its nested objects.
   * @param {Object} obj - The object to search for the property.
   * @param {string} prop - The property to search for.
   * @returns {Array} - An array of values for the property.
   */
  const searchPropertyRecursively = (obj, prop) =>
    reduce(
      obj,
      (result, value, key) =>
        key === prop
          ? [...result, value]
          : isObjectLike(value)
          ? result.concat(searchPropertyRecursively(value, prop))
          : result,
      []
    );

  /**
   * Removes duplicate values from an array.
   * @param {Array} arr - The array to remove duplicates from.
   * @returns {Array} - An array with unique values.
   */
  const removeDuplicates = (arr) => uniq(arr);

  return removeDuplicates(searchPropertyRecursively(obj, prop));
};

export default getPropertyRecursively;
