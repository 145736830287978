import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCommunicationPagers extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M80.12 47.27c0-5.22-2-10.73-4.78-13.25V32.2c0-3.976-3.224-7.2-7.2-7.2h-45c-3.976 0-7.2 3.224-7.2 7.2v31c0 3.976 3.224 7.2 7.2 7.2h45c3.976 0 7.2-3.224 7.2-7.2v-2.89c3.08-2.58 4.78-8.2 4.78-13.04zm-54 17.91c-1.28 0-2.32-1.04-2.32-2.32 0-1.28 1.04-2.32 2.32-2.32 1.28 0 2.32 1.04 2.32 2.32 0 1.28-1.04 2.32-2.32 2.32zm19.44 0c-1.28 0-2.32-1.04-2.32-2.32 0-1.28 1.04-2.32 2.32-2.32 1.28 0 2.32 1.04 2.32 2.32 0 .622-.25 1.22-.694 1.655-.443.436-1.044.676-1.666.665h.04zm19.44 0c-1.28 0-2.32-1.04-2.32-2.32 0-1.28 1.04-2.32 2.32-2.32 1.28 0 2.32 1.04 2.32 2.32 0 .622-.25 1.22-.694 1.655-.443.436-1.044.676-1.666.665H65zm3.33-10.19H23.12c-2.386 0-4.32-1.934-4.32-4.32V32.35c0-2.386 1.934-4.32 4.32-4.32h45.17c1.146 0 2.245.455 3.055 1.265.81.81 1.265 1.91 1.265 3.055v18.32c0 2.386-1.934 4.32-4.32 4.32h.04zm7.1-15.76c1.15 2.512 1.756 5.238 1.78 8 .01 2.766-.597 5.5-1.78 8v-16z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogCommunicationPagers;
