import Immutable from 'immutable';

const WidgetUtils = {};
/**
 * Returns a widget map if contains a launch workflow widget, undefined otherwise
 * @param store
 */
WidgetUtils.getWorkflowRequestWidget = (message = Immutable.Map()) => {
  if (Immutable.Map.isMap(message)) {
    if (message.hasIn(['metadata', 'widget'])) {
      const widgetBody = message.getIn(['metadata', 'widget', 'body']);
      return (
        widgetBody &&
        widgetBody.find((w) => w.get('widget') === 'WorkflowRequest')
      );
    }
  }

  return void 0;
};

export default WidgetUtils;
