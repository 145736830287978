import React from 'react';

import { Input } from 'semantic-ui-react';
import pt from 'prop-types';
import CopyToClipboard from './CopyToClipboard';

const propTypes = {
  text: pt.string,
};

const CopyField = ({ text }) => {
  return (
    <Input
      action={{
        icon: <CopyToClipboard text={text} />,
        type: 'button',
      }}
      readOnly
      value={text}
    />
  );
};

CopyField.propTypes = propTypes;

export default CopyField;
