const api_barista_v0_1_faqs_general_35eab19e_44c2_40af_9af1_e5b191af3c0f = {
  acknowledgments: [
    {
      active: true,
      eid: 'a2666897-cb9c-4180-b029-fe0c58bc59bd',
      is_protected: false,
      phrase: 'frfr',
    },
  ],
  activation_condition: null,
  active: true,
  additional_text: [
    {
      additional_text: '',
      eid: 'bc3a49ca-28f0-4691-98aa-3889b5857875',
      match_validation: false,
    },
  ],
  answer_expiry_info: {
    earliest_answer_expiry: null,
    total_expired_active_answers: 0,
  },
  answers: [
    {
      active: true,
      condition: '',
      eid: '35294719-d091-421a-98dc-ad21b14e92ff',
      else_condition: false,
      end_date: null,
      is_protected: false,
      lines: [
        {
          active: true,
          eid: '6ad85bc1-eb03-464a-9db6-9e6ce20c3641',
          is_protected: false,
          order: 2,
          phrase: 'we do not provide any 401k benefits',
        },
      ],
      start_date: null,
      type: null,
    },
  ],
  application_active: true,
  application_eid: '8903105c-c83f-4b5d-b019-9cd183060240',
  application_name: '401K',
  attributes: [
    {
      eid: '4ed930eb-009e-4d72-aba2-5bfc611d4b1f',
      name: 'service_team',
      value: '0543cd23-7d2d-4a5e-baf5-36254873667b',
    },
    {
      eid: '3761897d-2e53-4592-b11b-8552e7477dee',
      name: 'category',
      value: '3f8e7c29-44b9-4645-92fc-249228c2d844',
    },
    {
      eid: '17f87280-7e8d-48d3-bfa2-c0d38d31c716',
      name: 'service_department',
      value: '87561ee1-ed72-4757-9e49-80a41bb36a3b',
    },
    {
      eid: '3f627111-174e-43b5-968d-bffc02ee0816',
      name: 'esp.task_category_type',
      value: 'f669e2c3-ba8d-4733-aeda-728689eea381',
    },
    {
      eid: 'cc5d713a-1733-4d36-984a-f4e85558aa83',
      name: 'esp.service_team_type',
      value: 'cebe9ffb-e552-4935-8a31-532c04b18157',
    },
    {
      eid: 'f34b6b7f-7f69-41ac-9a5a-32099b307b8b',
      name: 'esp.service_department_type',
      value: '5eb0cf19-aee0-464f-a677-350da8a8ca1b',
    },
  ],
  case_feedback: 0,
  cases_created: 0,
  deflected: 0,
  description: 'fff',
  eid: '35eab19e-44c2-40af-9af1-e5b191af3c0f',
  helpful: 0,
  is_kb: true,
  is_protected: false,
  is_reviewed: false,
  last_used: '',
  name: '401K override',
  not_helpful: 0,
  sys_created_by: 'fran@example.com',
  sys_date_created: '2020-08-14T17:09:00.452657Z',
  sys_date_updated: '2020-08-18T01:41:08.094884Z',
  sys_updated_by: 'espbeta02@gmail.com',
  total_completions: 0,
  triggered_misspell_total: 0,
  triggered_total: 0,
  triggers: [],
  triggers_total: 0,
};

export default api_barista_v0_1_faqs_general_35eab19e_44c2_40af_9af1_e5b191af3c0f;
