import React, { Component } from 'react';
import Immutableproptypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import _ from 'lodash';

// Molecule
import FormInputRadio from '../../molecules/FormInputRadio';
import FormInputText from '../../molecules/FormInputText';
// Globals
import TypeResetPasswordMethod, {
  TypeDefaults,
} from '../../../globals/blocks/TypeResetPasswordMethod';
// Controller
import EspResetPasswordMethodController from '../../controllers/EspResetPasswordMethodController';
import BodyClassName from '../../../../globals/BodyClassName';

class ResetPasswordMethod01 extends Component {
  static propTypes = {
    ...TypeResetPasswordMethod,
    changeValueValidOTP: PropTypes.func.isRequired,
    currentUser: Immutableproptypes.map.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  componentDidMount() {
    const { setAlternateSubmit } = this.props;
    setAlternateSubmit(this.handleClick);
  }

  handleClick = (cb = _.noop) => {
    const { sendOTP, method } = this.props;
    sendOTP(method, cb);
  };

  mobileNumberMask = (phone) =>
    _.map(phone, (c, i) => (i < phone.length - 4 ? '*' : c)).join('');

  emailMask = (email) =>
    _.map(email, (c, i) => (i < email.split('@')[0].length ? '*' : c)).join('');

  handleChange = (e, val) => {
    if (val) {
      const { changeValueValidOTP } = this.props;
      changeValueValidOTP();
    }
  };

  formValidated = (val) => {
    const error = val ? '' : 'error';
    return error;
  };

  render() {
    const { currentUser, template, type } = this.props;

    return (
      <BodyClassName className='blocks-top'>
        <div className='block' data-blocktype={type} data-template={template}>
          <Form.Group inline>
            <Field
              component={FormInputRadio}
              label={this.mobileNumberMask(
                currentUser.getIn(['phone_numbers', 'mobile', 'number'])
              )}
              name='OTP'
              onChange={this.handleChange}
              type='radio'
              validate={this.formValidate}
              value='SMS'
            />
          </Form.Group>

          <Form.Group inline>
            <Field
              component={FormInputRadio}
              label={this.emailMask(currentUser.get('email'))}
              name='OTP'
              onChange={this.handleChange}
              type='radio'
              value='PRIMARY_EMAIL'
            />
          </Form.Group>

          <Form.Group inline>
            <Field
              component={FormInputRadio}
              label={this.emailMask(currentUser.get('secondary_email'))}
              name='OTP'
              onChange={this.handleChange}
              type='radio'
              value='SECONDARY_EMAIL'
            />
          </Form.Group>

          <Field
            component={FormInputText}
            hidden
            name='validOTP'
            validate={this.formValidated}
          />
        </div>
      </BodyClassName>
    );
  }
}

export default EspResetPasswordMethodController(ResetPasswordMethod01);
