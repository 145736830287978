/**
 * @typedef {Record<string, UiPaths>} UiPathsChildren - The child objects of a `UiPaths` object.
 */

/**
 * @typedef {{children: UiPathsChildren, pattern: string}} UiPaths - The `UiPaths` object.
 */

/**
 * The UI paths configuration object.
 * @type {UiPaths}
 */
const uiPaths = {
  children: {
    app: {
      children: {
        catalog: {
          children: {},
          pattern: 'shop/:categoryID?/:subcategoryID?',
        },
        directory: {
          children: {
            detail: {
              children: {},
              pattern: ':userId',
            },
          },
          pattern: 'contacts',
        },
        equipment: {
          children: {
            detail: {
              children: {},
              pattern: ':equipmentId',
            },
          },
          pattern: 'my-equipment',
        },
        messages: {
          children: {
            direct: {
              children: {
                user: {
                  children: {},
                  pattern: ':userId',
                },
              },
              pattern: 'd',
            },
            scoped: {
              children: {
                channel: {
                  children: {},
                  pattern: ':channelId',
                },
              },
              pattern: 's',
            },
          },
          pattern: 'messages',
        },

        standaloneProduct: {
          children: {
            detail: {
              children: {},
              pattern: ':catalogId',
            },
          },
          pattern: 'products',
        },
      },
      pattern: 'app',
    },
  },
  pattern: '',
};

export default uiPaths;
