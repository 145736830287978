import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';
import { change } from 'redux-form';
import async from 'async';
import { imagesResizeTools } from 'esp-globals';
// Utils
import endpointGenerator from '../utils/endpointGenerator';
import APIcall from '../utils/APIcall';
// Globals
import { SortFieldNames } from '../globals/SortProductStateOptions';
import ProductStatus from '../globals/ProductStatus';

// Actions
import catalogActions from './catalogActions';
import dpcActions from './dpcActions';
import integrationActions from './integrationActions';
import dpcThunks from './dpcThunks';
// Packages
import EspFilters from 'esp-util-filters';

/**
 * We should only accept catalog products if the current values of 'searchTerm' and 'subcat' are
 * the same than when the request was made.
 *
 * @param searchTerm The search term when the request was made.
 * @param subcat The current subcategory.
 * @param stateAfter A state snapshot after the request completes.
 * @return {Boolean} True if it's ok to accept the users from the api response.
 */
const shouldAccept = (searchTerm, subcat, stateAfter) => {
  const catalog = stateAfter.get('catalog');

  const stillTheSame = searchTerm === catalog.get('searchTerm');

  return stillTheSame;
};

const espFilters = (userInput, subCategory, status = 'ACTIVE') => {
  let espFilter = `name__IC=${userInput}&ORshort_description__IC=${userInput}`;

  if (subCategory) {
    espFilter = `category_all__IN=${subCategory}&${espFilter}&`;
  }

  if (status && status !== ' ') {
    // space is used to show a value in AdminCatalogList since SUIR Dropdown doesn't work well with empty strings or nulls
    espFilter += `&status__EQ=${status}`;
  }

  return encodeURI(espFilter);
};

// parallel callback
const addProductImage = (data) => (next) => {
  const endpoint = endpointGenerator.genPath('espCatalog.productsImages');
  APIcall.post({
    data,
    error(err) {
      next(err);
    },
    success() {
      next(null);
    },
    token: true,
    url: endpoint,
  });
};

const getProductValues = (formValues) => ({
  barista_url: formValues.barista_url || null,
  brand_name: formValues.brand_name,
  category: [formValues.subcategory],
  cost: formValues.cost || null,
  description: formValues.description,
  launch_barista_url: formValues.launch_barista_url || false,
  model_name: formValues.model_name,
  model_no: formValues.model_no,
  name: formValues.name,
  price: formValues.price || null,
  recurring_price: formValues.recurring_price || null,
  recurring_price_time_period: formValues.recurring_price_time_period || null,
  short_description: formValues.short_description,
  specifications: formValues.specifications,
  target_intent: formValues.target_intent || null,
});

const catalogThunks = {};

catalogThunks.loadCategories = () => (dispatch, getState) => {
  const state = getState();
  const isLoadingCategories = state.getIn(['catalog', 'isLoadingCategories']);

  const categories = state.getIn(['catalog', 'categories']);

  // skip doing the API call if we already have the categories,
  // this is ok since they are not going to change that often.
  if (isLoadingCategories || !categories.isEmpty()) {
    return;
  }

  dispatch(catalogActions.loadCategoriesStart());

  const endpoint = endpointGenerator.genPath('espCatalog.categories.top');

  APIcall.get({
    error(err) {
      dispatch(catalogActions.loadCategoriesFailure(err));
    },
    success(res) {
      dispatch(catalogActions.loadCategoriesSuccess(res.body.results));
    },
    token: true,
    url: endpoint,
  });
};

catalogThunks.loadSubCategories = (categoryId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.loadSubCategoriesStart(categoryId));

    const endpoint = endpointGenerator.genPath(
      'espCatalog.categories.instance.subcategories',
      {
        categoryID: categoryId,
      }
    );

    return APIcall.get({
      error(err) {
        dispatch(catalogActions.loadSubCategoriesFailure(categoryId, err));
        reject();
      },
      success(res) {
        dispatch(
          catalogActions.loadSubCategoriesSuccess(categoryId, res.body.results)
        );
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

catalogThunks.loadSubCategoriesByName = (name) => () =>
  new Promise((resolve, reject) => {
    let esp_filters = new EspFilters();
    esp_filters = esp_filters.equalTo('name', name).toString();
    const endpoint =
      endpointGenerator.genPath('espCatalog.categories') + esp_filters;
    return APIcall.get({
      error(err) {
        reject(err);
      },
      success(res) {
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

/**
 * Gets all subcategories (categories that parent is not null)
 */
catalogThunks.loadAllSubCategories = () => (dispatch, getState) => {
  const state = getState();

  const endpoint = endpointGenerator.genPath('espCatalog.categories');
  const pagination = state.getIn(['catalog', 'subCatPagination']);
  const isLoading = state.getIn(['catalog', 'isLoadingSubCategories']);

  if (isLoading) {
    return null;
  }
  // If it exist a next pagination in the reducer, we use that as the url
  // otherwise we fallback to the endpoint root
  const url = pagination.get('next') ? pagination.get('next') : endpoint;

  dispatch(catalogActions.loadAllSubCategoriesStart());
  const query = {};
  // esp filters
  query.esp_filters = encodeURI('parent__!ISNULL=true');

  // Sorting
  // ToDo: ask BE to implement this (T3678)
  // query.order_by = 'parent.name,name';

  return APIcall.get({
    error(err) {
      dispatch(catalogActions.loadAllSubCategoriesFailure(err.message));
    },
    query,
    success(res) {
      const { body } = res;
      const subcategories = body.results;

      const pagination = {
        next: body.next,
        prev: body.previous,
      };

      dispatch(
        catalogActions.loadAllSubCategoriesSuccess(subcategories, pagination)
      );
    },
    token: true,
    url: url,
  });
};

/**
 * Gets all products within a query
 */
catalogThunks.loadQueriedProducts = (searchQuery) => (dispatch) => {
  dispatch(catalogActions.loadProductsStart());

  const endpoint = endpointGenerator.genPath('espCatalog.products');
  const query = {};
  // limit
  query.limit = 25;

  // esp filter
  if (searchQuery) {
    query.esp_filters = encodeURI(`name__IC=${searchQuery}`);
  }

  return APIcall.get({
    error(err) {
      dispatch(catalogActions.loadAllSubCategoriesFailure(err.message));
    },
    query,
    success(res) {
      const count = res.body.count || null;
      const products = res.body.results;
      dispatch(catalogActions.loadProductsSuccess(products, count));
    },
    token: true,
    url: endpoint,
  });
};

/**
 *
 */
catalogThunks.loadSnowProducts = (searchQuery = '') => (dispatch, getState) => {
  const state = getState();
  const pagination = state.getIn(['integration', 'snowProducts', 'pagination']);

  const endpoint = endpointGenerator.genPath('espCatalog.products');
  const query = {};

  // esp filter
  if (searchQuery) {
    query.esp_filters = encodeURI(`name__IC=${searchQuery}`);
  }
  // Special parameter to query snow_url != null
  query.snow_product = 'true';

  const shouldUsePagination =
    pagination && pagination.get('next') && !searchQuery;
  const url = shouldUsePagination ? pagination.get('next') : endpoint;

  return APIcall.get({
    query,
    success(res) {
      const { body } = res;
      const count = body.count || null;
      const products = body.results;

      dispatch(catalogActions.loadProductsSuccess(products, count));

      // We really only want to save pagination if user was browsing
      // not if he was searching
      if (!searchQuery) {
        const latestPagination = {
          next: body.next,
          prev: body.previous,
        };
        dispatch(integrationActions.loadSnowProductsSuccess(latestPagination));
      }
    },
    token: true,
    url: url,
  });
};

catalogThunks.loadProductsBy = ({
  categoryPath = {},
  limit = 200,
  offset = null,
  resetProductsList = false,
  status = '',
  orderBy = SortFieldNames.NEGATIVE_SYS_DATE_CREATED,
  squash_pf = true,
  searchTerm = '',
} = {}) => (dispatch) => {
  dispatch(catalogActions.loadProductsStart());

  const query = {};

  // squash families
  if (squash_pf) {
    query.squash_pf = '1';
  }
  // Offset
  if (offset !== null) {
    query.offset = offset;
  }

  // Limit
  query.limit = limit;

  // Order by
  if (orderBy) {
    query.order_by = orderBy;
  }

  // Esp Filters
  query.esp_filters = '';
  if (categoryPath.subCategoryId && categoryPath.subCategoryId !== 0) {
    query.esp_filters = encodeURI(
      `category.id__EQ=${categoryPath.subCategoryId}`
    );
  } else if (categoryPath.categoryId && categoryPath.categoryId !== 0) {
    query.esp_filters = encodeURI(
      `category_all.id__IN=${categoryPath.categoryId}`
    );
  }

  if (status && status !== ' ') {
    // space is used to show a value in AdminCatalogList since SUIR Dropdown doesn't work well with empty strings or nulls
    let preappend = '';
    if (query.esp_filters) {
      preappend = '&';
    }
    query.esp_filters += encodeURI(`${preappend}status__EQ=${status}`);
  }

  if (searchTerm !== '') {
    let preappend = '';
    if (query.esp_filters !== '') {
      preappend = '&';
    }
    query.esp_filters += encodeURI(`${preappend}name__IC=${searchTerm}`);
  }

  // prevent send empty esp_filters field
  if (query.esp_filters === '') {
    query.esp_filters = null;
  }

  // If no esp_filters are used, by default the API should filter out the non-ACTIVE products

  const endpoint = endpointGenerator.genPath('espCatalog.products');

  return APIcall.get({
    error(err) {
      dispatch(catalogActions.loadProductsFailure(err));
    },
    query,
    success(res) {
      const count = limit ? res.body.count : null;
      const products = res.body.results;
      const searchResultsOrder = products.map((p) => ({
        id: p.id,
      }));

      dispatch(
        catalogActions.loadProductsSuccess(
          products,
          count,
          categoryPath,
          resetProductsList,
          searchResultsOrder
        )
      );
    },
    token: true,
    url: endpoint,
  });
};

// Loads first 25 product if no params and it adds more to the state if search term is passed down
// this is being use by AdminBundleDetail
catalogThunks.searchProductsBundle = (searchTerm = null) => (dispatch) => {
  dispatch(catalogActions.loadProductsStart());

  const endpoint = endpointGenerator.genPath('espCatalog.products');

  const squash_pf = '1';

  const esp_filters = searchTerm
    ? `name__IC=${searchTerm}&status__EQ=ACTIVE`
    : null;

  return APIcall.get({
    error(err) {
      dispatch(catalogActions.loadProductsFailure(err));
    },
    query: {
      esp_filters,
      squash_pf,
    },
    success(res) {
      const { count } = res.body;
      const products = res.body.results;
      dispatch(catalogActions.loadProductsSuccess(products, count));
    },
    token: true,
    url: endpoint,
  });
};

// Loads products based of an eid
catalogThunks.loadProductsByEid = (eid = null, eids = []) => (dispatch) => {
  dispatch(catalogActions.loadProductsStart());

  const endpoint = endpointGenerator.genPath('espCatalog.products');

  let esp_filters = '';

  if (eid) {
    esp_filters = `sys_dpc_parent.eid__EQ=${eid}`;
  } else {
    esp_filters = `sys_dpc_parent.eid__IN=${eids.join(',')}`;
  }

  return APIcall.get({
    error(err) {
      dispatch(catalogActions.loadProductsFailure(err));
    },
    query: {
      esp_filters,
    },
    success(res) {
      const { count } = res.body;
      const products = res.body.results;
      dispatch(catalogActions.loadProductsSuccess(products, count));
    },
    token: true,
    url: endpoint,
  });
};

catalogThunks.loadOneProduct = (productId, reload = false) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();
    // Only load if there's not a loading attempt being made for that productId
    // Note that this thunk can still be user to re-load the product to bust cache,
    // This check only prevents two concurrent AJAX calls to be made at the same time
    if (
      !state.getIn(['catalog', 'isLoadingProductStatus', productId]) ||
      reload === true
    ) {
      dispatch(catalogActions.loadOneProductStart(productId));
      const endpoint = endpointGenerator.genPath(
        'espCatalog.products.instance',
        {
          productID: productId,
        }
      );
      APIcall.get({
        error(err) {
          dispatch(catalogActions.loadOneProductFail(productId, err));
          reject(err);
        },
        success(res) {
          dispatch(catalogActions.loadOneProductSuccess(productId, res.body));
          resolve(res.body);
        },
        token: true,
        url: endpoint,
      });
    } else {
      reject(new Error('Product already loading'));
    }
  });

catalogThunks.addProductImage = (data) => (dispatch, getState) => {
  dispatch(catalogActions.updateProductImagesStart());

  const state = getState();
  const productID = Number(data.getAll('esp_catalog')[0]);
  let products = state.getIn(['entities', 'products']);

  const endpoint = endpointGenerator.genPath('espCatalog.productsImages');
  APIcall.post({
    data,
    error(e) {
      dispatch(catalogActions.updateProductImagesError(e));
    },
    success(res) {
      products = products.map((prod) => {
        if (prod.get('id') === productID) {
          const images = prod.get('images').push(fromJS(res.body));
          return prod.set('images', images);
        }
        return prod;
      });
      dispatch(catalogActions.updateProductImagesSuccess(products));
    },
    token: true,
    url: endpoint,
  });
};

catalogThunks.updateProductDetails = (productID, data) => (
  dispatch,
  getState
) => {
  dispatch(catalogActions.loadOneProductStart());

  const state = getState();
  let dataToSend = null;

  let products = state.getIn(['entities', 'products']);
  products = products.map((prod) => {
    if (prod.get('id') === productID) {
      for (const key in data) {
        prod = prod.set(key, data[key]);
      }

      dataToSend = prod.toJS();
    }
    return prod;
  });

  const endpoint = endpointGenerator.genPath('espCatalog.products.instance', {
    productID,
  });
  APIcall.put({
    data: dataToSend,
    error() {
      dispatch(catalogActions.loadOneProductFail());
    },
    success() {
      dispatch(catalogActions.updateProductImages(products));
    },
    token: true,
    url: endpoint,
  });
};

catalogThunks.searchProducts = (userInput, cat = '', status, orderBy) => (
  dispatch
) => {
  dispatch(catalogActions.setSearchUsersTerm(userInput));
  dispatch(catalogThunks.searchTerm(cat, status, orderBy));
};

catalogThunks.searchTerm = (cat = '', status = '', orderBy = null) => (
  dispatch,
  getState
) => {
  const state = getState();
  let subCategory = cat
    ? cat
    : state.getIn(['catalog', 'selectedSubCategoryId']);
  subCategory = subCategory
    ? subCategory
    : state.getIn(['catalog', 'selectedCategoryId']);

  const userInput = state.getIn(['catalog', 'searchTerm']);

  const endpoint = endpointGenerator.genPath('espCatalog.products');

  const onSuccess = (response) => {
    // are we still interested in this response?
    if (!shouldAccept(userInput, subCategory, getState())) {
      return;
    }

    const { body } = response;

    const productsList = body.results;
    // const totalCount = body.count;

    dispatch(catalogActions.loadProductsSuccess(productsList));

    const productSearch = productsList.map((product) => product.id);

    dispatch(catalogActions.setProductSearch(productSearch));
  };

  return APIcall.get({
    error(err) {
      dispatch(catalogActions.loadProductsFailure(err));
    },
    query: {
      // Apply filter
      esp_filters: espFilters(userInput, subCategory, status),
      order_by: orderBy,
    },
    success(res) {
      onSuccess(res);
    },
    token: true,
    url: endpoint,
  });
};

catalogThunks.loadProductFamily = (pfamilyId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.loadProductFamilyStart(pfamilyId));
    const endpoint = endpointGenerator.genPath(
      'espCatalog.productFamilies.instance',
      {
        pfamilyId: pfamilyId,
      }
    );
    APIcall.get({
      error(err) {
        dispatch(catalogActions.loadProductFamilyFail(pfamilyId, err.message));
        reject(err.message);
      },
      success(res) {
        dispatch(catalogActions.loadProductFamilySuccess(pfamilyId, res.body));
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

/**
 * Checks if product has fulfillment options
 * If the product doesn't have them, then checks if its category has workflow mappings
 * and if does, then checks the fulfillment options of the mapping's default product
 */
catalogThunks.loadFulfillmentOptions = (productId, cb = _.noop) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    let state = getState();
    dispatch(catalogActions.loadFulfillmentOptionsStart(productId));
    async.waterfall(
      [
        // 1. check in the esp fulfillment options
        (next) => {
          const endpoint = endpointGenerator.genPath(
            'espCatalog.fulfillmentOptions'
          );
          APIcall.get({
            error(error) {
              next(error);
            },
            query: {
              esp_filters: encodeURI(`products__IN=${productId}`),
            },
            success(res) {
              const fOptions = res.body.results;
              next(null, fOptions);
            },
            token: true,
            url: endpoint,
          });
        },
        // 1.1 Check if the product exists
        (fOptions, next) => {
          const product = state.getIn(['entities', 'products', productId]);

          if (product) {
            next(null, fOptions);
          } else {
            dispatch(catalogThunks.loadOneProduct(productId, false))
              .then(() => {
                next(null, fOptions);
              })
              .catch((error) => next(error));
          }
        },
        // 2. Check in the sn workflow map
        (fOptions, next) => {
          // Only check if we didn't find anything in the previous call
          if (fOptions.length < 1) {
            state = getState(); // Need to get the updated state
            const product = state.getIn(['entities', 'products', productId]);

            if (product) {
              APIcall.get({
                error(error) {
                  next(error);
                },
                query: {
                  esp_filters: encodeURI(
                    `catalog_category__EQ=${product.getIn(['category', 0])}`
                  ),
                },
                success(res) {
                  const wfMappings = res.body.results;
                  next(null, fOptions, wfMappings);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'serviceNowIntegration.defaultWorkflowMap'
                ),
              });
            } else {
              const error = 'product not loaded';
              next(error);
            }
          } else {
            // No need to check, just pass down the fulfillment question
            next(null, fOptions, null); // need to pass null as next callback expects 2 arguments
          }
        },
        // 3. if there were mappings, check for fulfillment question for the product family
        (fOptions, wfMappings, next) => {
          if (fOptions.length < 1 && wfMappings.length > 0) {
            const endpoint = endpointGenerator.genPath(
              'espCatalog.fulfillmentOptions'
            );
            APIcall.get({
              error(error) {
                next(error);
              },
              query: {
                esp_filters: encodeURI(
                  `products__IN=${wfMappings[0].default_product}`
                ),
              },
              success(res) {
                const fOptions = res.body.results;
                next(null, fOptions);
              },
              token: true,
              url: endpoint,
            });
          } else {
            // No need to check, just pass down the fulfillment question
            next(null, fOptions);
          }
        },
      ],
      // On Done
      (error, fOptions) => {
        if (error) {
          // throw new Error(error);
          dispatch(catalogActions.loadFulfillmentOptionsFail(productId, error));
          reject(error);
        } else {
          dispatch(
            catalogActions.loadFulfillmentOptionsSuccess(productId, fOptions)
          );
          resolve(fOptions);
        }
        cb(fOptions);
      }
    );
  });

catalogThunks.loadFulfillmentAnswers = (productID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const endpoint = endpointGenerator.genPath(
      'espCatalog.products.instance.defaultAnswers',
      {
        productID,
      }
    );
    APIcall.get({
      error(err) {
        reject(err);
      },

      // technically we should implement pagination for this
      // but there's no way to paginated based on fulfillment options => then load answers only for those paginated options
      // therefore i have to load all the answers here
      query: {
        limit: 1000,
      },

      success({ body }) {
        const fulfillmentAnswers = body.results;
        dispatch(
          catalogActions.loadFulfillmentAnswersSuccess(
            productID,
            fulfillmentAnswers
          )
        );
        resolve(fulfillmentAnswers);
      },
      token: true,
      url: endpoint,
    });
  });

catalogThunks.getFulfillmentQuestionsByProduct = (productId) => (dispatch) =>
  new Promise((resolve, reject) => {
    let fulfillmentAnswers;
    dispatch(catalogThunks.loadFulfillmentAnswers(productId))
      .then((answers) => {
        fulfillmentAnswers = answers;
        return dispatch(catalogThunks.loadFulfillmentOptions(productId));
      })
      .then((optionsResults) => {
        if (fulfillmentAnswers) {
          // filter out the options that are already answered by default
          optionsResults = _.reject(optionsResults, (option) =>
            fulfillmentAnswers.find((answer) => answer.option === option.id)
          );
        }
        resolve(optionsResults);
      })
      .catch((err) => {
        reject(err);
      });
  });

catalogThunks.saveFulfillmentAnswers = (
  productID,
  fieldValues = Immutable.Map()
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.saveFulfillmentAnswersStart(productID));

    const state = getState();
    const fulfillmentAnswers = state.getIn([
      'catalog',
      'fulfillmentAnswers',
      productID,
    ]);
    // So, we have to save the answers one by one in different API calls
    // to do so, we have to check if the answer already exists and if so, do a PATCh
    // otherwise POST a new one

    const allSavePromises = []; // used to save all promises returned by the APicalls
    fieldValues.forEach((formAnswer, optionId) => {
      const existingAnswer = fulfillmentAnswers.find(
        (a) => optionId === String(a.get('option'))
      );
      let savePromise;

      if (existingAnswer) {
        // PATCH it or DELETE IT
        if (formAnswer) {
          // if it already exist, over write the answer value
          savePromise = APIcall.patch({
            data: {
              // don't forget to save the answer!
              value: formAnswer,
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCatalog.products.instance.defaultAnswers.instance',
              {
                answerID: existingAnswer.get('id'),
                productID,
              }
            ),
          });
        } else {
          // if it doesn't exist (empty) then delete it
          // from DEV-2756:
          // If default answer is set in product level, remove the default answer for product
          savePromise = APIcall.delete({
            token: true,
            url: endpointGenerator.genPath(
              'espCatalog.products.instance.defaultAnswers.instance',
              {
                answerID: existingAnswer.get('id'),
                productID,
              }
            ),
          });
        }
      } else {
        // POST it!
        // from DEV-2756:
        // During admin fresh setup for product, if default answer is empty, do NOT create a product default answer for product
        if (formAnswer) {
          savePromise = APIcall.post({
            data: {
              option: optionId,
              // for here we need to build the whole relationship
              // with product, option, and answer
              product: productID,
              value: formAnswer,
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCatalog.products.instance.defaultAnswers',
              {
                productID,
              }
            ),
          });
        }
      }

      allSavePromises.push(savePromise);
    });

    // resolve/reject this promise when all the internal promises finish
    Promise.all(allSavePromises)
      .then(() => {
        dispatch(catalogActions.saveFulfillmentAnswersSuccess(productID));
        resolve();
      })
      .catch((e) => {
        dispatch(catalogActions.saveFulfillmentAnswersFail(productID));
        reject(e);
      });
  });

//
// Updates product attributes
// @param <Integer> productID
// @param <Object> data
//
catalogThunks.updateProductAttributes = (productID, data) => (dispatch) => {
  dispatch(catalogActions.loadOneProductStart(productID));

  const endpoint = endpointGenerator.genPath('espCatalog.products.instance', {
    productID,
  });
  APIcall.patch({
    data: getProductValues(data),
    error(err) {
      dispatch(catalogActions.loadOneProductFail(productID, err));
    },
    success({ body }) {
      dispatch(catalogActions.loadOneProductSuccess(productID, body));
    },
    token: true,
    url: endpoint,
  });
};

//
// Change product status
// @param <Integer> productID
// @param <String> status
//
catalogThunks.changeProductStatus = (productID, status) => (
  dispatch,
  getState
) => {
  dispatch(catalogActions.loadOneProductStart(productID));
  const state = getState();
  const product = state.getIn(['entities', 'products', productID]);

  const endpoint = endpointGenerator.genPath(
    'espCatalog.products.instance.changeStatus',
    {
      productID,
    }
  );
  APIcall.post({
    data: {
      status,
    },
    error(err) {
      dispatch(catalogActions.loadOneProductFail(productID, err));
    },
    success({ body }) {
      const newStatus = product.set('status', body.status);
      dispatch(
        catalogActions.loadOneProductSuccess(productID, newStatus.toJS())
      );
    },
    token: true,
    url: endpoint,
  });
};

catalogThunks.copyDpcAttributes = (product, attributesMap, dpcProduct) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.loadOneProductStart(product.get('id')));

    const endpoint = endpointGenerator.genPath('espCatalog.dpci');
    const dpcEid = product.getIn(['sys_dpc_parent', 'eid']);
    const fields = [];
    const products = dpcEid ? [dpcEid] : [dpcProduct.get('eid')];
    const copy_unlinked = !dpcEid ? [product.get('eid')] : null;

    attributesMap.forEach((i, key) => fields.push(key));

    const data = {
      copy_unlinked,
      fields,
      products,
    };

    APIcall.post({
      data,
      error(err) {
        dispatch(catalogActions.loadOneProductFail(product.get('id'), err));
        reject();
      },
      success() {
        dispatch(dpcActions.clearCopyAttributesProduct());
        dispatch(catalogThunks.loadOneProduct(product.get('id'), true));
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

const createProduct = (formValues, cb = _.noop) => {
  const endpoint = endpointGenerator.genPath('espCatalog.products');
  APIcall.post({
    data: getProductValues(formValues),
    error(err) {
      cb(err);
    },
    success({ body }) {
      cb(null, body);
    },
    token: true,
    url: endpoint,
  });
};

const copyDpcAttributes = (product, attributesMap, dpcProduct, cb = _.noop) => {
  const endpoint = endpointGenerator.genPath('espCatalog.dpci');
  const dpcEid = product.getIn(['sys_dpc_parent', 'eid']);
  const fields = [];
  const products = dpcEid ? [dpcEid] : [dpcProduct.get('eid')];
  const copy_unlinked = !dpcEid ? [product.get('eid')] : null;

  attributesMap.forEach((i, key) => fields.push(key));

  const data = {
    copy_unlinked,
    fields,
    products,
  };

  APIcall.post({
    data,
    error(err) {
      cb(err);
    },
    success() {
      cb();
    },
    token: true,
    url: endpoint,
  });
};

const uploadTemporaryImages = (productID, images, cb = _.noop) => {
  // create image upload callbacks
  const imagesCallbacks = images.map((tmpImg, key) => {
    const image = new window.Image();
    image.src = tmpImg.get('image_480');
    const img = {
      id: productID,
      ordering: key,
      picture_480: imagesResizeTools.createImg(image, 480),
    };
    const finalData = imagesResizeTools.convertFormData(img);
    return addProductImage(finalData);
  });

  // upload all images via parallel
  async.waterfall(imagesCallbacks.toJS(), () => {
    cb();
  });
};

const setProdctStatus = (productID, status, cb = _.noop) => {
  const endpoint = endpointGenerator.genPath(
    'espCatalog.products.instance.changeStatus',
    {
      productID: productID,
    }
  );
  APIcall.post({
    data: {
      status: status,
    },
    error(err) {
      cb(err);
    },
    success({ body }) {
      cb(null, body);
    },
    token: true,
    url: endpoint,
  });
};
//
// Creates new catalog product
// @param <Map> formValues
// @param <String> status
//
catalogThunks.createNewProduct = (
  formValues,
  status,
  cb = _.noop,
  callbacks = null
) => (dispatch, getState) => {
  dispatch(catalogActions.createNewProductStart());

  const waterfall = callbacks || [
    (next) => createProduct(formValues, next),

    (product, next) => {
      const state = getState();

      const copyAttributesProduct = state.getIn([
        'dpc',
        'copyAttributesProduct',
      ]);
      const copyAttributesProductID = state.getIn([
        'dpc',
        'copyAttributesProductID',
      ]);
      const dpcProducts = state.getIn(['dpc', 'products']);
      const selectedProduct = dpcProducts.find(
        (p) => p.get('id') === copyAttributesProductID
      );
      const newValues = copyAttributesProduct.map((p, k) =>
        selectedProduct.get(k)
      );

      if (copyAttributesProduct.isEmpty()) {
        next(null, product);
      } else {
        copyDpcAttributes(fromJS(product), newValues, selectedProduct, () =>
          next(null, product)
        );
      }
    },

    (product, next) => {
      const state = getState();
      const images = state.getIn(['catalog', 'temporaryImages']);
      uploadTemporaryImages(product.id, images, () => next(null, product));
    },

    (product, next) => {
      dispatch(catalogActions.clearTmpImage());
      next(null, product);
    },

    (product, next) => {
      if (status !== ProductStatus.DRAFT) {
        setProdctStatus(product.id, status, () => next(null, product));
      } else {
        next(null, product);
      }
    },
  ];

  async.waterfall(waterfall, (e, product) => {
    if (e) {
      dispatch(catalogActions.createNewProductError(e));
    } else {
      dispatch(catalogActions.adminAllowLeave(true));
      dispatch(catalogActions.createNewProductSuccess(product));
      cb(product);
    }
  });
};

const mapCategoryDpc = (id, dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(dpcThunks.loadSubCategoryDPC(id))
      .then((dpcCat) => {
        dispatch(catalogThunks.loadSubCategoriesByName(dpcCat.name)).then(
          (catalogCat) => {
            dispatch(
              catalogThunks.loadSubCategories(catalogCat.results[0].id)
            ).then(() => resolve(catalogCat.results[0].id));
          }
        );
      })
      .catch(() => reject());
  });
//
// Copy product attributes
// @param <Integer> copyAttributesProductID
// @param <Map> copyAttributesProduct
// @param <List> products
//
catalogThunks.copyAttributesProduct = (
  copyAttributesProductID,
  copyAttributesProduct,
  products
) => (dispatch) =>
  new Promise((resolve, reject) => {
    const selectedProduct = products.find(
      (p) => p.get('id') === copyAttributesProductID
    );

    const newValues = copyAttributesProduct.map((p, k) =>
      selectedProduct.get(k)
    );
    const subCategoryID = selectedProduct.get('category').first();
    const categoryID = selectedProduct
      .get('category_all')
      .filterNot((id) => id === subCategoryID)
      .first();

    newValues.forEach((value, key) => {
      dispatch(change('CatalogDetailForm', key, value));
    });

    async.parallel(
      [
        (next) =>
          mapCategoryDpc(categoryID, dispatch).then((_categoryID) =>
            next(null, _categoryID)
          ),
        (next) =>
          mapCategoryDpc(subCategoryID, dispatch).then((_subCategoryID) =>
            next(null, _subCategoryID)
          ),
      ],
      (err, values) => {
        if (err) {
          reject();
        } else {
          const [tenantCategory, tenantSubCategory] = values;
          dispatch(catalogActions.setSelectedCategory(tenantCategory));
          dispatch(
            change('CatalogDetailForm', 'subcategory', tenantSubCategory)
          );
          dispatch(change('CatalogDetailForm', 'category', tenantCategory));
          resolve();
        }
      }
    );
  });

catalogThunks.changeProductCategory = (productID, categoryID, cb = _.noop) => (
  dispatch
) => {
  const url = endpointGenerator.genPath('espCatalog.products.instance', {
    productID,
  });

  const changes = {
    category: [categoryID],
  };

  dispatch(catalogActions.loadOneProductStart(productID));

  APIcall.patch({
    data: changes,
    error(error) {
      dispatch(catalogActions.loadOneProductFail(productID, error));
    },
    success(response) {
      const product = response.body;
      dispatch(catalogActions.loadOneProductSuccess(productID, product));
      cb(productID, product);
    },
    token: true,
    url,
  });
};

catalogThunks.deleteProductImage = (imageID, productID, cb = _.noop) => (
  dispatch,
  getState
) => {
  const endpoint = endpointGenerator.genPath(
    'espCatalog.productsImages.instance',
    {
      imageID: imageID,
    }
  );

  const product = getState().getIn(['entities', 'products', productID]);

  APIcall.delete({
    success() {
      const newProduct = product.set(
        'images',
        product.get('images').filterNot((image) => image.get('id') === imageID)
      );
      dispatch(
        catalogActions.loadOneProductSuccess(productID, newProduct.toJS())
      );
      cb();
    },
    token: true,
    url: endpoint,
  });
};

const changeImageOrder = (imageID, ordering, cb = _.noop) => {
  APIcall.patch({
    data: {
      ordering,
    },
    error(e) {
      cb(e);
    },
    success({ body }) {
      cb(null, body);
    },
    token: true,
    url: endpointGenerator.genPath('espCatalog.productsImages.instance', {
      imageID,
    }),
  });
};

catalogThunks.orderImages = (productID, images) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(catalogActions.updateProductImagesStart());

    const state = getState();
    const product = state.getIn(['entities', 'products', productID]);
    const callbacks = images.map((image, index) => (next) =>
      changeImageOrder(image.get('id'), index, next)
    );

    if (!product || product.isEmpty()) {
      return;
    }

    async.parallel(callbacks.toJS(), (e, images) => {
      if (e) {
        dispatch(catalogActions.updateProductImagesError(e));
        reject();
      } else {
        const newProduct = product.set('images', fromJS(images));
        dispatch(catalogActions.updateProductImagesSuccess([newProduct]));
        resolve();
      }
    });
  });

catalogThunks.loadCatalogProducts = ({
  categoryId = null,
  limit = 24,
  searchTerm = '',
} = {}) => (dispatch, getState) => {
  const catalog = getState().get('catalog');
  const categoryPathChanged = categoryId !== catalog.get('selectedSubCategory');
  const catalogCount = categoryPathChanged ? 0 : catalog.get('count') || 0;
  const products = categoryPathChanged
    ? []
    : (catalog.get('searchResultsOrder') || new Immutable.List()).toJS();

  if (catalogCount && catalogCount === products.length) {
    dispatch(catalogActions.noMoreProducts());
  } else {
    dispatch(catalogActions.loadProductsStart(categoryId));

    const query = {
      esp_filters: encodeURI(
        `category_all.id__IN=${categoryId}&status__EQ=ACTIVE`
      ),
      limit,
      offset: products.length,
      order_by: SortFieldNames.NAME,
      squash_pf: '1',
    };

    if (!_.isEmpty(searchTerm)) {
      query.esp_filters += encodeURI(`&name__IC=${searchTerm}`);
    }

    const endpoint = endpointGenerator.genPath('espCatalog.products');

    APIcall.get({
      error(err) {
        dispatch(catalogActions.loadProductsFailure(err));
      },
      query,
      success(res) {
        const { count } = res.body;
        const { results } = res.body;
        const searchResultsOrder = products.concat(
          results.map((p) => ({
            id: p.id,
          }))
        );
        dispatch(
          catalogActions.loadProductsSuccess(
            results,
            count,
            {
              categoryId,
            },
            false,
            searchResultsOrder
          )
        );
      },
      token: true,
      url: endpoint,
    });
  }
};

export default catalogThunks;
