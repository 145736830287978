import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Loader,
  Menu,
  Search,
  Segment,
} from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Molecules
import MenuPagination from './MenuPagination';
import OnboardingActivityScheduleTableList from './OnboardingActivityScheduleTableList';
// utils
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
// globals
import SortActivityStateOptions, {
  SortActivityFieldNames,
} from '../../globals/SortActivityStateOptions';
// Controller
import OnboardActivityScheduleListController from '../controllers/OnboardActivityScheduleListController';

/**
 * @param deleteOneScheduleActivity Function to delete a schedule's activity
 * @param limitByPage Number of items per page
 * @param loadScheduleActivityList Function to load schedule's activity list
 * @param location Router parameters
 * @param searchSchedule Function to search for a schedule
 * @param setSearchTerm Setter for search's term
 * @param isLoadingScheduleList Boolean for loading state
 * @param isSearching Boolean for searching state
 * @param listCount Max count of schedules in list
 * @param loadingDeleteID Schedule's id in processs of deletion
 * @param params URL parameters
 * @param scheduleList List of schedules
 * @param searchTerm String for the term being search
 */

const SEARCH_DELAY = 300;
const MIN_CHARACTERS = 3;

class OnboardingActivityScheduleList extends PureComponent {
  static propTypes = {
    deleteOneScheduleActivity: PropTypes.func.isRequired,

    isLoadingScheduleList: PropTypes.bool,
    isSearching: PropTypes.bool,
    limitByPage: PropTypes.number.isRequired,
    listCount: PropTypes.number,

    loadScheduleActivityList: PropTypes.func.isRequired,

    loadingDeleteID: PropTypes.number,
    location: PropTypes.shape({
      query: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
        orderBy: PropTypes.any,
        page: PropTypes.number,
      }),
    }).isRequired,
    params: PropTypes.shape({
      scheduleID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    scheduleList: ImmutableProps.list,
    searchSchedule: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    setSeachTerm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoadingScheduleList: false,
    isSearching: false,
    listCount: 0,
    loadingDeleteID: null,
    params: {},
    scheduleList: Immutable.List(),
    searchTerm: null,
  };
  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    orderBy: this.props.location.query.orderBy,
  };

  componentDidMount() {
    const {
      limitByPage,
      loadScheduleActivityList,
      location: { query },
    } = this.props;

    const page = query.page || 1;
    const { orderBy } = query;

    loadScheduleActivityList(page, limitByPage, orderBy);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      limitByPage,
      loadScheduleActivityList,
      location: { query },
    } = this.props;
    const { orderBy } = this.state;

    const nextPage = nextProps.location.query.page;
    const nextPageOrderBy = nextProps.location.query.orderBy;

    if (
      !nextProps.searchTerm &&
      (nextPage !== query.page || nextPageOrderBy !== orderBy)
    ) {
      this.setState({
        orderBy: nextPageOrderBy,
      });
      loadScheduleActivityList(nextPage, limitByPage, nextPageOrderBy);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      limitByPage,
      searchTerm,
      searchSchedule,
      params: { scheduleID },
      location: { query },
      loadScheduleActivityList,
      scheduleList,
    } = this.props;

    const page = query.page || 1;
    const nextPage = nextProps.location.query.page;
    const nextPageOrderBy = nextProps.location.query.orderBy;

    if (
      nextProps.searchTerm !== searchTerm ||
      (nextProps.searchTerm !== '' && nextPage !== page)
    ) {
      let newPage = nextPage;
      if (searchTerm === '') {
        // New search - redirect to page 1 which will perform the search on page 1
        newPage = 1;
        browserHistory.push({
          pathname: uiPathGenerator.genPath(
            'admin.adminActivities.onboardingActivities.schedule',
            {
              scheduleID: scheduleID,
            }
          ),
          search: `?${queryString.stringify(
            Object.assign({}, window.location.query, {
              page: 1,
            })
          )}`,
        });
      }

      if (
        nextProps.searchTerm !== '' &&
        nextProps.searchTerm.length >= MIN_CHARACTERS
      ) {
        searchSchedule(newPage, limitByPage, nextPageOrderBy);
      } else if (nextProps.searchTerm === '') {
        // Search has been reseted
        loadScheduleActivityList(nextPage, limitByPage, nextPageOrderBy);
      }
    } else if (
      nextProps.scheduleList.isEmpty() &&
      scheduleList.isEmpty() &&
      nextPage > 1
    ) {
      // The page is too high - back to 1
      browserHistory.push({
        pathname: uiPathGenerator.genPath(
          'admin.adminActivities.onboardingActivities.schedule',
          {
            scheduleID: scheduleID,
          }
        ),
        search: `?${queryString.stringify(
          Object.assign({}, window.location.query, {
            page: 1,
          })
        )}`,
      });
    }
  }

  handleStartSearch = _.debounce((e, { value }) => {
    const { setSeachTerm } = this.props;
    setSeachTerm(value);
  }, SEARCH_DELAY);

  handleFilterSortChange = (e, { value }) => {
    const { params, location } = this.props;

    browserHistory.push({
      pathname: uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.schedule',
        {
          scheduleID: params.scheduleID,
        }
      ),
      search: `?${queryString.stringify(
        Object.assign({}, location.query, {
          orderBy: value === '' ? null : value,
        })
      )}`,
    });
  };

  render() {
    const {
      deleteOneScheduleActivity,
      isLoadingScheduleList,

      isSearching,
      location,
      limitByPage,
      listCount,
      loadingDeleteID,
      scheduleList,
    } = this.props;

    const SortScheduleOption = SortActivityStateOptions(intl);

    return (
      <Segment attached clearing>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_onboarding_activities_schedule',
          })}
        />

        <Menu borderless secondary>
          <Menu.Item fitted>
            <Search
              loading={isSearching}
              onSearchChange={this.handleStartSearch}
              placeholder='Search...'
              showNoResults={false}
            />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <Button
                as={Link}
                basic
                content='Create Schedule'
                to={uiPathGenerator.genPath(
                  'admin.adminActivities.onboardingActivities.schedule',
                  {
                    scheduleID: 'new',
                  }
                )}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Grid>
          <Grid.Column width={8}>{'Onboarding To Do Schedules'}</Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <label>{'Sort by: '}</label>
            <Dropdown
              onChange={this.handleFilterSortChange}
              options={SortScheduleOption}
              selectOnBlur={false}
              value={
                location.query.orderBy ||
                SortActivityFieldNames.NEGATIVE_SYS_DATE_UPDATED
              }
            />
          </Grid.Column>
        </Grid>

        {isLoadingScheduleList && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}

        <OnboardingActivityScheduleTableList
          deleteOneScheduleActivity={deleteOneScheduleActivity}
          loadingDeleteID={loadingDeleteID}
          tableList={scheduleList}
        />

        <MenuPagination
          currentPage={Number(location.query.page) || 1}
          numberPages={Math.ceil(listCount / limitByPage) || 1}
          pageByQuery
          pathname={'admin.adminActivities.onboardingActivities.schedule'}
          query={location.query}
        />
      </Segment>
    );
  }
}

const OnboardingActivityScheduleListTest = OnboardingActivityScheduleList;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardingActivityScheduleList = OnboardActivityScheduleListController(
  OnboardingActivityScheduleList
);
export { OnboardingActivityScheduleListTest };
export default OnboardingActivityScheduleList;
