import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { intl } from 'esp-util-intl';
import classNames from 'classnames';
import { Header, Icon, Image, Label, List } from 'semantic-ui-react';
import Isvg from 'react-inlinesvg';
import { isString, noop } from 'lodash';
// v2 Component
import { Boundaries } from 'cascara-middleware';

class EspListItem extends PureComponent {
  static propTypes = {
    /** A List.Item can have a button action */
    action: PropTypes.node,
    /** A List.Item can have an active style */
    active: PropTypes.bool,
    activeEmpty: PropTypes.bool,
    /** An element type to render as (string or function). */
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** A List.Item can have an attached header. */
    attachedHeader: PropTypes.string,
    /** Shorthand for primary content. */
    content: PropTypes.node,
    /** Shorthand for smaller description content. */
    description: PropTypes.node,
    /** A list item can disabled. */
    disabled: PropTypes.bool,
    /** Shorthand for email. */
    email: PropTypes.node,
    /** A list item can have a full width, primary header above the image and content sections */
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** Action render on the header / right side  */
    headerAction: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    id: PropTypes.number,
    /** Shorthand for image content. This has a different markup structure than the standard SUI list item image */
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** If passed, the element will sith next to image */
    imageIcon: PropTypes.element,
    /** A list item can be formatted as an announcement. */
    isAnnouncement: PropTypes.bool,
    /** A list item image can be formatted as a circular avatar. */
    isAvatar: PropTypes.bool,
    /** A list item can be formatted as a card. */
    isCard: PropTypes.bool,
    /** A list item can be formatted as a cart item. */
    isCartItem: PropTypes.bool,

    // boolean that will change the background of susbscibers to gray

    isGreyedOut: PropTypes.bool,

    /** A list item can be rendered with a loading style */
    isLoading: PropTypes.bool,

    /** A list item can be rendered smaller inside search results. */
    isSearchResult: PropTypes.bool,

    /** Check Mark when an element is selected */
    isSelected: PropTypes.bool,

    /** A list item can have a more bold unread style. */
    isUnread: PropTypes.bool,

    /** A list item can have meta content in the footer. */
    meta: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    /** Shorthand for name/header content. This is different than the list item header at the top. */
    name: PropTypes.node,

    /** displays icon next to name **/
    newHireIcon: PropTypes.element,

    /** To be called whenever the Link parts of the component are clicked*/
    onClick: PropTypes.func,

    /** A price to be placed in the footer section - Can be an empty DIV */

    price: PropTypes.node,

    /** A quantity to be placed in the footer section*/
    quantity: PropTypes.number,

    /** ribon label content **/
    ribbon: PropTypes.string,

    /** A list item can render a left aligned checkbox/radio node */
    selectable: PropTypes.node,

    /** The href url if you are rendering the list item "as" a Link component */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /** A list item can have an unread badge on the image. */
    unreadCount: PropTypes.number,
  };

  static defaultProps = {
    action: null,
    active: null,
    // this is intentionally set as null instead of false
    activeEmpty: false,
    as: null,
    attachedHeader: null,
    content: null,
    description: null,
    disabled: false,
    email: null,
    header: null,
    headerAction: null,
    id: null,
    image: null,
    imageIcon: null,
    isAnnouncement: false,
    isAvatar: false,
    // TODO: We can probably remove this because we can conditionally pass a node via @ozzy's changes
    isCard: false,
    isCartItem: false,
    isGreyedOut: false,
    isLoading: false,
    isSearchResult: false,
    isSelected: false,
    isUnread: false,
    meta: null,
    name: null,
    newHireIcon: null,
    onClick: noop,
    price: null,
    quantity: null,
    ribbon: null,
    selectable: null,
    to: null,
    unreadCount: 0,
  };

  handleLoadingImage = () => {
    // TODO: Set baseline default image support while waiting for an image to load
    // console.warn('Loaded: ' + e.target.src);
  };

  render() {
    const {
      action,
      active,
      activeEmpty,
      as,
      attachedHeader,
      content,
      description,
      disabled,
      email,
      header,
      headerAction,
      id,
      image,
      imageIcon,
      isAnnouncement,
      isAvatar,
      isCard,
      isCartItem,
      isGreyedOut,
      isLoading,
      isSearchResult,
      isSelected,
      isUnread,
      meta,
      name,
      newHireIcon,
      onClick,
      price,
      quantity,
      ribbon,
      selectable,
      to,
      unreadCount,
      ...rest
    } = this.props;
    const wordBreakStyle = {
      wordBreak: 'break-all',
    };

    let imageType;
    if (isString(image)) {
      imageType = image.split('.').pop();
      if (
        (window.cordova || window.electron) &&
        image.match('svg') &&
        image.match('xml')
      ) {
        imageType = 'svg';
      }
    } else {
      imageType = 'NODE';
    }

    const greyedOutStyle = {
      background: 'rgba(0,0,0,0.1)',
      opacity: disabled && !isSelected ? '0.75' : '1',
    };

    return (
      <Boundaries>
        <List.Item
          style={isGreyedOut ? greyedOutStyle : {}}
          {...rest}
          active={active}
          as={(action && as === Link) || isLoading ? null : as}
          className={classNames(null, {
            'active-empty': activeEmpty && !active,
            announcement: isAnnouncement,
            card: isCard || isAnnouncement,
            'cart-item': isCartItem,
            link: active !== null || (as === Link && !isLoading),
            loading: isLoading,
            search: isSearchResult,
            selectable: selectable,
            unread: isUnread,
          })}
          disabled={disabled}
          onClick={!action ? onClick : null}
          to={!action && as === Link && !isLoading ? to : null}
        >
          {isSelected && (
            <Icon
              className={`right floated ${id}`}
              color='green'
              name='check'
              size='large'
            />
          )}

          {attachedHeader && (
            <div className='attached-header'>
              <div className='text'>{attachedHeader}</div>
            </div>
          )}
          {headerAction && <div className='top-action'>{headerAction}</div>}
          {ribbon && <Label content={ribbon} ribbon='right' />}
          {header && (
            <Header
              as={action && as === Link && !isLoading ? Link : 'h4'}
              content={header}
              onClick={action && as === Link && !isLoading ? onClick : null}
              to={action && as === Link && !isLoading ? to : null}
            />
          )}
          {selectable ? (
            <div className='select-action'>{selectable}</div>
          ) : null}
          {image ? (
            <div className='image-container'>
              {isString(image) &&
              imageType !== 'NODE' &&
              imageType === 'svg' ? (
                <Isvg
                  cacheGetRequests
                  className='ui image'
                  key={image}
                  src={image}
                  uniquifyIDs={false}
                >
                  {/* NOTE: This is a fallback for browsers not allowing XHR/inline SVG */}
                  <img alt='list-item' src={image} />
                </Isvg>
              ) : null}

              {isString(image) &&
              imageType !== 'NODE' &&
              imageType !== 'svg' ? (
                <Image
                  alt={isAvatar ? 'Avatar' : 'List Item Icon'}
                  as={action && as === Link && !isLoading ? Link : 'div'}
                  avatar={isAvatar}
                  onClick={action && as === Link && !isLoading ? onClick : null}
                  onLoad={this.handleLoadingImage}
                  src={image}
                  to={action && as === Link && !isLoading ? to : null}
                />
              ) : null}

              {!isString(image) ? image : null}

              {imageIcon ? imageIcon : null}

              {unreadCount ? (
                <Label color='red' content={unreadCount} floating />
              ) : null}
            </div>
          ) : null}
          {content || description || name ? (
            <List.Content
              as={action && as === Link && !isLoading ? Link : null}
              onClick={action && as === Link && !isLoading ? onClick : null}
              style={wordBreakStyle}
              to={action && as === Link && !isLoading ? to : null}
            >
              {name ? (
                <List.Header>
                  {name}
                  {newHireIcon}
                </List.Header>
              ) : null}
              {content ? <List.Content>{content}</List.Content> : null}
              {email ? <List.Content>{email}</List.Content> : null}
              {description ? (
                <List.Description>{description}</List.Description>
              ) : null}
            </List.Content>
          ) : null}
          {meta || action || price ? (
            <div className='item-footer'>
              {meta && <div className='meta'>{meta}</div>}
              {price && quantity && (
                <>
                  {`${intl.formatMessage({
                    id: 'label.quantity',
                  })}: ${quantity}`}
                  <span style={{ float: 'right' }}>{price}</span>
                </>
              )}
              {price && !quantity && <div className='price'>{price}</div>}
              {action && (
                <div className={meta || price ? 'action' : 'action fluid'}>
                  {action}
                </div>
              )}
            </div>
          ) : null}
        </List.Item>
      </Boundaries>
    );
  }
}

export default EspListItem;
