'use stricts';

import groupPermissions from '../globals/groupPermissions';

const uiPathPermissions = () => {
  const groupPerms = Object.assign({}, groupPermissions);
  groupPerms.SERVICE_LEAD = 'SERVICE_LEAD';
  groupPerms.SERVICE_AGENT = 'SERVICE_AGENT';

  return {
    admin: {
      authentication: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          // Comment for a better readability
        ],
      },
      bundle: {
        noFullPermissionForAdmin: false,
        permissions: [groupPerms.ADMIN, groupPerms.CATALOG_MANAGER],
      },
      dpc: {
        noFullPermissionForAdmin: false,
        permissions: [groupPerms.ADMIN, groupPerms.CATALOG_MANAGER],
      },
      faqsV2: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          groupPerms.FAQ_ADMIN,
          groupPerms.FAQ_EDITOR,
        ],
      },
      globalSettings: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          // Comment for a better readability
        ],
      },
      integrations: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          // Comment for a better readability
        ],
      },
      interactionsDashboard: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          groupPermissions.BARISTA_ADMIN,
          groupPermissions.COMPLIANCE_ADMIN,
          groupPermissions.CUSTOMER_SERVICE_ADMIN,
          groupPermissions.FACILITIES_ADMIN,
          groupPermissions.FAQ_ADMIN,
          groupPermissions.FINANCE_ADMIN,
          groupPermissions.HR_ADMIN,
          groupPermissions.IT_ADMIN,
          groupPermissions.LEGAL_ADMIN,
          groupPermissions.MARKETING_ADMIN,
          groupPermissions.PAYROLL_ADMIN,
          groupPermissions.PROCUREMENT_ADMIN,
          groupPermissions.SALES_ADMIN,
          groupPermissions.SECURITY_ADMIN,
          groupPermissions.STUDENT_SERVICES_ADMIN,
          groupPermissions.TRAINING_ADMIN,
        ],
      },
      onboardingActivities: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          groupPerms.ONBOARD_SCHEDULER,
          groupPerms.ACTIVITY_CREATOR,
        ],
      },
      reports: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPermissions.ADMIN,
          groupPermissions.HR_ADMIN,
          groupPermissions.IT_ADMIN,
          groupPermissions.FINANCE_ADMIN,
          groupPermissions.SECURITY_ADMIN,
          groupPermissions.FACILITIES_ADMIN,
          groupPermissions.MARKETING_ADMIN,
          groupPermissions.SALES_ADMIN,
          groupPermissions.CUSTOMER_SERVICE_ADMIN,
          groupPermissions.PAYROLL_ADMIN,
          groupPermissions.BARISTA_ADMIN,
        ],
      },
      roleBundle: {
        noFullPermissionForAdmin: false,
        permissions: [groupPerms.ADMIN, groupPerms.CATALOG_MANAGER],
      },
      serviceDepartment: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          // Comment for a better readability
        ],
      },
      serviceTeams: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          groupPerms.SERVICE_LEAD,
          // Comment for a better readability
        ],
      },
      storefrontDesigner: {
        noFullPermissionForAdmin: false,
        permissions: [groupPerms.ADMIN, groupPerms.CATALOG_MANAGER],
      },
      users: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ADMIN,
          // Comment for a better readability
        ],
      },
    },
    app: {
      announcements: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.ANNOUNCER,
          // Comment for a better readability
        ],
      },
      approvalsFeed: {
        noFullPermissionForAdmin: true,
        permissions: [
          groupPerms.MANAGER,
          groupPerms.SERVICE_AGENT,
          groupPerms.SERVICE_LEAD,
        ],
      },
      casesFeed: {
        noFullPermissionForAdmin: true,
        permissions: [groupPerms.SERVICE_LEAD, groupPerms.SERVICE_AGENT],
      },
      newHireProgress: {
        noFullPermissionForAdmin: false,
        permissions: [
          groupPerms.MANAGER,
          // Comment for a better readability
        ],
      },
    },
    editor: {
      noFullPermissionForAdmin: false,
      permissions: [
        groupPerms.ADMIN,
        // Comment for a better readability
      ],
    },
  };
};

export default uiPathPermissions;
