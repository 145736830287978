import { camelCase, reduce } from 'lodash';

// Camelizes all keys contained in the object. For now it only supports shallow change.
// @param {object} theObject - Any object
// @returns {object}
export const camelizeKeys = (theObject) =>
  reduce(
    theObject,
    (acc, value, key) => ({
      [camelCase(key)]: value,
      ...acc,
    }),
    {}
  );
