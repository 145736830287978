import _ from 'lodash';
// Stores
import store from '../stores/store';

const EntitiesUtils = {
  // If setting not enabled in appUI, then this always will return true
  isTimestampExpired: (timestamp = Date.now(), state = store.getState()) => {
    // the optional parameter is to make the unit test on thunks work
    if (!_.isInteger(timestamp)) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Argument timestamp must be passed in miliseconds. Received',
        timestamp
      );
      return false;
    }

    const isSignExpirationEnabled = state.getIn([
      'appUI',
      's3_signed_url_cfg',
      'enabled',
    ]); // configuration comming from bootstrap
    if (isSignExpirationEnabled) {
      const signDuration =
        state.getIn(['appUI', 's3_signed_url_cfg', 'sign_duration']) * 1000; // We get a timestamp in second and we use ms

      const nowDate = new Date(); // time right now
      const threshHoldDate = new Date(timestamp + signDuration); // time of the timestamp plus the duration

      const isExpired = nowDate.getTime() > threshHoldDate.getTime(); // checking if it's too old

      // console.warn(`Your timestamp expires on ${threshHoldDate} and currently it's ${nowDate} so expired is ${isExpired}`);

      return isExpired;
    }

    return true;
  },
};

export default EntitiesUtils;
