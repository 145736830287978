import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Provider } from '../context';
import getTenantFlags from '../selectors';

const FlagProvider = ({ flags, children }) => {
  const isFlag = (flag) => flags.get(flag) || false;
  return <Provider value={{ flags, isFlag }}>{children}</Provider>;
};

FlagProvider.displayName = 'FlagProvider';
FlagProvider.propTypes = {
  children: PropTypes.element,
  flags: PropTypes.shape({
    feature: PropTypes.bool,
    get: PropTypes.func,
  }),
};
FlagProvider.defaultProps = {
  children: null,
  flags: {},
};

export { FlagProvider };

export default connect((state) => ({ flags: getTenantFlags(state) }))(
  FlagProvider
);
