import { useCallback, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { AuthContext } from '../../context';
import {
  addParamsToURL,
  getMethodInfo,
  getSearchParamsFromQuery,
} from '../../utils';
import { FETCH_METHODS, HTTP_METHODS } from '../../constants/httpMethods';

// Pseudo example
// const userDetailData = useAuthAPI({
//   url: '/api/espuser/v0.1/users/:id',
//   params: {
//     id: 13,
//   },
//   query: {
//     page: 2
//   }
// })

// useAPIGet
// Returns a react-query [UseQueryResult] hook, pre-configured to fetch
// data from [config] parameter
// @param {Object} config The configuration object
// @param {Object} data The data to be sent as the request body
// @param {Object|URLSearchParams} params URL parameters to be sent with the request
// @param {String} query Query string params for the request
// @return {import('react-query').UseQueryResult<unknown, unknown>}
const useAPIGet = (
  {
    method = HTTP_METHODS.GET, // By default we will do GET
    pagination,
    params,
    query,
    url,
  } = {},
  config
) => {
  const { isMutating, isUnknownMethod } = getMethodInfo(method);
  if (isMutating || isUnknownMethod) {
    throw new Error(
      `${method} is an invalid parameter for 'method'. Try one of: ${FETCH_METHODS.join(
        ', '
      )}`
    );
  }

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'useAPIGet Hook can only be called inside AuthContext.Provider!'
    );
  }

  const urlWithParams = addParamsToURL(url, params);

  /**
   * This function is passed to react-query as the data
   * source, it will be passed an object that has a `queryKey`
   * array for which the first item is the `key` and the second
   * is the query configuration
   */
  const fetchAndReturnData = useCallback(
    ({ queryKey }) => {
      let requestConfig = {};

      const [, config] = queryKey;
      const { query, pagination, params, ...rest } = config;
      const queryStringHash = !query
        ? {}
        : typeof query === 'string'
        ? getSearchParamsFromQuery(query)
        : query;

      requestConfig = {
        method,
        params: queryStringHash,
        url: urlWithParams,
        ...rest,
      };

      return authContext?.requestInstance(requestConfig);
    },
    [authContext, method, urlWithParams]
  );

  return {
    queryKey: urlWithParams,
    ...useQuery(
      [
        urlWithParams,
        // We can use this object to further-configure Axios calls
        {
          method,
          pagination,
          params,
          query,
          url: urlWithParams,
        },
      ],
      fetchAndReturnData,
      config
    ),
  };
};

export default useAPIGet;
