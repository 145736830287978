import { FILTER_CONDITIONS, FILTER_OPERATORS } from '../Filter';

const getEspFiltersFromFilterState = (values) =>
  values
    // ?.filter(({ value }) => Boolean(value))
    .map(({ isCondition = false, parameter, type, value }) => {
      if (isCondition) {
        return FILTER_CONDITIONS[type];
      } else {
        return `${parameter}__${FILTER_OPERATORS[type]}=${value}`;
      }
    })
    // Right now we want our MVP rules to be OR based, so this will need to get more
    // complex in the future as we might need to combine AND and NOT
    .join('');

export default getEspFiltersFromFilterState;
