import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import IntlField from '../IntlField';

const SKIP_FIELDS = ['hide'];

class IntlNav extends PureComponent {
  static propTypes = {
    blobKeyPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),

    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
    navBlock: PropTypes.object.isRequired,

    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    blobKeyPath: [],
  };
  renderField = (field, blobKeyPath) => {
    const [fieldName, defaultValue] = field;

    // Do not render a field if we are skipping a field
    if (SKIP_FIELDS.indexOf(fieldName) < 0) {
      return (
        <IntlField
          blobKeyPath={blobKeyPath}
          defaultValue={defaultValue}
          key={defaultValue + fieldName + blobKeyPath.join()}
          label={fieldName}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const { navBlock, type, blobKeyPath } = this.props;

    const fields = Object.entries(navBlock);

    return (
      <>
        <Header
          attached='top'
          content={`Nav: ${type}`}
          style={{
            borderRadius: '.5em .5em 0 0',
          }}
        />
        <Segment attached='bottom'>
          <div className='ui form'>
            {fields.map((field) => {
              const [fieldName, fieldValue] = field;

              // we actually want to clone this array!
              const blobKeyPathClone = blobKeyPath.slice(0);
              blobKeyPathClone.push(fieldName);

              if (typeof fieldValue === 'string') {
                return this.renderField(field, blobKeyPathClone);
              }
              return null;
              // first check for array
            })}
          </div>
        </Segment>
      </>
    );
  }
}

export default IntlNav;
