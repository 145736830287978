import buildCompositeController from '../../utils/buildCompositeController';

import appUIActions from '../../actions/appUIActions';
import getTenant from '../../selectors/getTenant';

const mapStateToProps = (state) => {
  const tenant = getTenant(state);
  return {
    companyName: tenant && tenant.get('name'),
    isOpen: state.getIn(['appUI', 'isFeedbackModalOpen']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal() {
    dispatch(appUIActions.closeFeedbackModal());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
