import actionTypes from './actionTypes';

const departmentsActions = {};

departmentsActions.getRootDepartmentsStart = () => ({
  type: actionTypes.GET_ROOT_DEPARTMENTS_START,
});

departmentsActions.getRootDepartmentsSuccess = (rootDepartments) => ({
  rootDepartments,
  type: actionTypes.GET_ROOT_DEPARTMENTS_SUCCESS,
});

departmentsActions.getRootDepartmentsFail = () => ({
  type: actionTypes.GET_ROOT_DEPARTMENTS_FAIL,
});

departmentsActions.getChildrenDepartmentsStart = () => ({
  type: actionTypes.GET_CHILDREN_DEPARTMENTS_START,
});

departmentsActions.getChildrenDepartmentsSuccess = (childrenDepartments) => ({
  childrenDepartments,
  type: actionTypes.GET_CHILDREN_DEPARTMENTS_SUCCESS,
});

departmentsActions.getChildrenDepartmentsFail = () => ({
  type: actionTypes.GET_CHILDREN_DEPARTMENTS_FAIL,
});

departmentsActions.getDefaultSelectedDepartmentStart = () => ({
  type: actionTypes.GET_DEFAULT_SELECTED_DEPARTMENT_START,
});

departmentsActions.getDefaultSelectedDepartmentSuccess = (departments) => ({
  departments,
  type: actionTypes.GET_DEFAULT_SELECTED_DEPARTMENT_SUCCESS,
});

departmentsActions.getDefaultSelectedDepartmentFail = () => ({
  type: actionTypes.GET_DEFAULT_SELECTED_DEPARTMENT_FAIL,
});

export default departmentsActions;
