import buildCompositeController from '../../utils/buildCompositeController';

import socializeThunks from '../../actions/socializeThunks';

import toastNotificationsActions from '../../actions/toastNotificationsActions';

import { isDirty } from 'redux-form/immutable';

const mapStateToProps = (state) => {
  const textAreaValue = state.getIn([
    'form',
    'SupportForm',
    'values',
    'msgFeedback',
  ]);
  return {
    charLength: textAreaValue ? textAreaValue.length : 0,
    isDirty: isDirty('SupportForm')(state),
    isLoading: state.getIn(['socialize', 'feedbackIsSending']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (data, cb) => {
    dispatch(socializeThunks.sendFeedback(data, cb));
  },
  successOTPSend: (title, message) => {
    // Send a SUCCESS notification to the snackbar
    dispatch(
      toastNotificationsActions.success({
        message,
        title,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
