import immutable from 'immutable';

/**
 * Useful stuff missing in Immutable.js
 * Make sure to read the docs to ensure you don't add a feature here that's already
 * part of Immutable.js
 */
const ImmutableUtils = {};

/**
 * Flats a List of Map's.
 *
 * pluck with id as key:
 * [{id: 3, name: 'Oswaldo}, {id: 7, name: 'Ricardo'}] => [3, 7]
 *
 * @param list An Immutable List of Map's
 * @param key A String, the key we want to use to flat the list.
 */
ImmutableUtils.pluck = (list, key) => list.map((item) => item.get(key));

/**
 * Create a new List of Map's.
 *
 * pluck with an array of keys:
 * ex arrayOfKey ['id','type']
 * [{id: 3, name: 'Oswaldo', 'type': 'user'}, {id: 7, name: 'Ricardo', 'type': 'people'}] => [{id:3, type: 'user'}, {id: 7, type: 'people'}]
 *
 * @param list An Immutable List of Map's
 * @param arrayOfKeys An Array, each entry is the key we want to pass to the new Map entry in the list.
 */
ImmutableUtils.objectPluck = (list, arrayOfKeys) =>
  list.map((item) => {
    let newObj = immutable.Map();

    arrayOfKeys.forEach((key) => {
      newObj = newObj.set(key, item.get(key));
    });

    return newObj;
  });

/**
 * Replace a List with a new List . Set the passed object where predicate is true.
 *
 * ex arrayOfKey ['id','type']
 * [{id: 3, name: 'Oswaldo', 'type': 'user'}, {id: 7, name: 'Ricardo', 'type': 'people'}] => [{id:3, type: 'user'}, {id: 7, type: 'people'}]
 * replace(list, {id: 7, name: 'Ignacio', 'type': 'user'}, (user) => user.get('id') === 7)
 * result: [{id: 3, name: 'Oswaldo', 'type': 'user'}, {id: 7, name: 'Ignacio', 'type': 'user'}] => [{id:3, type: 'user'}, {id: 7, type: 'people'}]
 *
 * @param {Imutable.List } list
 * @param {Any}            newObject  Any value. Responsibility is outside of this function
 * @param {Function|Value} predicate  If passed a value, we create a predicate that tests for equality
 */
ImmutableUtils.replace = (list, newObject, predicate) => {
  const equals = (value) => (x) => x === value;
  const usePredicate =
    typeof predicate === 'function' ? predicate : equals(predicate);
  const index = list.findIndex(usePredicate);
  return list.set(index, newObject);
};

export default ImmutableUtils;
