import { Dropdown } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import _ from 'lodash';

import BlockModal from '../../organisms/BlockModal';
import DetailedProductDescription from '../../organisms/DetailedProductDescription';
import EspProductHeader from '../../molecules/EspProductHeader';
import ProductConfigurationController from '../../controllers/ProductConfigurationController';
import ProductDescription from '../../atoms/ProductDescription';
import ProductImages from '../../organisms/ProductImages';
import TypeProductConfiguration, {
  TypeDefaults,
} from '../../../globals/blocks/TypeProductConfiguration';
import BodyClassName from '../../../../globals/BodyClassName';

let ProductConfiguration01 = class ProductConfiguration01 extends Component {
  static propTypes = {
    ...TypeProductConfiguration,
    activeHardwareBundleProductImageIdx: PropTypes.number,
    getHardwareBundleProducts: PropTypes.func.isRequired,
    hardwareBundle: ImmutablePropTypes.map,
    hardwareBundleProducts: ImmutablePropTypes.list,

    selectHardwareBundleProduct: PropTypes.func,

    selectedHardwareBundleProductId: PropTypes.number,
    setActiveHardwareBundleProductImageIdx: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    activeHardwareBundleProductImageIdx: 0,
    getHardwareBundleProducts: _.noop,
    hardwareBundle: new Immutable.Map(),
    hardwareBundleProducts: new Immutable.List(),

    selectHardwareBundleProduct: _.noop,

    selectedHardwareBundleProductId: 0,
    setActiveHardwareBundleProductImageIdx: _.noop,
  };

  componentDidMount() {
    const { getHardwareBundleProducts } = this.props;

    getHardwareBundleProducts();
  }

  // this is the way how ProductImages expect setActiveImage prop to look like
  setActiveHardwareBundleProductImageIdx = (productId, index) => (e) => {
    e.preventDefault();
    const { setActiveHardwareBundleProductImageIdx } = this.props;

    setActiveHardwareBundleProductImageIdx(index);
  };

  handleChange = (e, data) => {
    const productId = data.value;

    const {
      selectedHardwareBundleProductId,
      selectHardwareBundleProduct,
    } = this.props;

    // no selection change at all
    if (productId === selectedHardwareBundleProductId) {
      return;
    }

    selectHardwareBundleProduct(productId);
  };

  render() {
    const {
      type,
      template,
      activeHardwareBundleProductImageIdx,
      hardwareBundle,
      hardwareBundleProducts,
      selectedHardwareBundleProductId,
    } = this.props;

    // use name of the bundle for title
    let name = '';

    if (hardwareBundle) {
      name = hardwareBundle.get('name');
    }

    let featuredProduct = null;

    if (hardwareBundleProducts) {
      featuredProduct = hardwareBundleProducts.first();
    }

    let featuredProductId = -1;

    if (featuredProduct) {
      featuredProductId = featuredProduct.get('id');
    }

    let images = Immutable.List();

    if (featuredProduct) {
      images = featuredProduct.get('images');
    }

    let description = '';

    if (featuredProduct) {
      description = featuredProduct.get('description');
    }

    let hardwareBundleProductsOptions = Immutable.List();

    if (hardwareBundleProducts) {
      hardwareBundleProductsOptions = hardwareBundleProducts.map((product) =>
        Immutable.Map({
          text: product.get('name'),
          value: product.get('id'),
        })
      );
    }

    hardwareBundleProductsOptions = hardwareBundleProductsOptions.toJS();

    return (
      <BodyClassName className='blocks-top'>
        <div
          className='block esp-catalog-detail'
          data-blocktype={type}
          data-template={template}
        >
          <EspProductHeader title={name} />

          <ProductImages
            activeImage={activeHardwareBundleProductImageIdx}
            images={images}
            isLightbox={false}
            productID={featuredProductId}
            setActiveImage={this.setActiveHardwareBundleProductImageIdx}
          />

          <BlockModal
            trigger={
              <div className='description'>
                <ProductDescription description={description} />
              </div>
            }
          >
            <div className='esp-blocks'>
              <DetailedProductDescription
                className='block'
                description={description}
                images={images}
                name={name}
              />
            </div>
          </BlockModal>

          <Dropdown
            fluid
            onChange={this.handleChange}
            options={hardwareBundleProductsOptions}
            placeholder='Product Configuration'
            selectOnBlur={false}
            selection
            value={selectedHardwareBundleProductId || -1}
          />
        </div>
      </BodyClassName>
    );
  }
};

ProductConfiguration01 = ProductConfigurationController(ProductConfiguration01);

export default ProductConfiguration01;
