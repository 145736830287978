const CaseSearchAttributesI18n = {
  assignee: 'label.task_assignee',
  assignment: 'label.task_assignment',
  category: 'label.task_category',
  location: 'label.task_location',
  notes: 'label.task_notes',
  parent_title: 'label.task_parent_title',
  ref_num: 'label.task_ref_num',
  reporter: 'label.task_reporter',
  status: 'label.task_status',
  summary: 'label.task_summary',
  sys_date_updated: 'label.task_sys_date_updated',
};

export default CaseSearchAttributesI18n;
