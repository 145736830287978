const api_barista_v0_1_kb_articles_get_kbs_added_updated_overtime = {
  frequency: 'weekly',
  results: [
    {
      content: {
        added: 10,
        updated: 0,
      },
      end_date: '2021-07-20T23:59:59Z',
      start_date: '2021-07-14T00:00:00Z',
    },
    {
      content: {
        added: 11,
        updated: 0,
      },
      end_date: '2021-07-27T23:59:59Z',
      start_date: '2021-07-21T00:00:00Z',
    },
    {
      content: {
        added: 1,
        updated: 10,
      },
      end_date: '2021-08-03T23:59:59Z',
      start_date: '2021-07-28T00:00:00Z',
    },
    {
      content: {
        added: 1,
        updated: 110,
      },
      end_date: '2021-08-10T23:59:59Z',
      start_date: '2021-08-04T00:00:00Z',
    },
    {
      content: {
        added: 10,
        updated: 10,
      },
      end_date: '2021-08-17T23:59:59Z',
      start_date: '2021-08-11T00:00:00Z',
    },
    {
      content: {
        added: 110,
        updated: 30,
      },
      end_date: '2021-08-24T23:59:59Z',
      start_date: '2021-08-18T00:00:00Z',
    },
    {
      content: {
        added: 50,
        updated: 80,
      },
      end_date: '2021-08-31T23:59:59Z',
      start_date: '2021-08-25T00:00:00Z',
    },
    {
      content: {
        added: 80,
        updated: 99,
      },
      end_date: '2021-09-07T23:59:59Z',
      start_date: '2021-09-01T00:00:00Z',
    },
    {
      content: {
        added: 40,
        updated: 70,
      },
      end_date: '2021-09-14T23:59:59Z',
      start_date: '2021-09-08T00:00:00Z',
    },
    {
      content: {
        added: 90,
        updated: 20,
      },
      end_date: '2021-09-17T23:59:59Z',
      start_date: '2021-09-15T00:00:00Z',
    },
  ],
};

export default api_barista_v0_1_kb_articles_get_kbs_added_updated_overtime;
