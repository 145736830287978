import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesDongles extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M75.22 19.86h-2.28V16H57.25c-3.69 0-6.68 2.99-6.68 6.68v2.51l-13.43-.1h-3.45c-7.042 0-12.75 5.708-12.75 12.75s5.708 12.75 12.75 12.75h2.34l25 .08c4.61.158 8.267 3.94 8.267 8.555 0 4.614-3.656 8.397-8.267 8.555H44.84v-2.41c0-3.69-2.99-6.68-6.68-6.68H22.47v3.86h-2.28c-1.11 0-2.177.44-2.963 1.227C16.44 64.563 16 65.63 16 66.74v5.18c0 2.314 1.876 4.19 4.19 4.19h2.28V80h15.68c3.69 0 6.68-2.99 6.68-6.68V72h16.19c7.042 0 12.75-5.708 12.75-12.75S68.062 46.5 61.02 46.5l-22.45-.11h-4.83c-4.725-.006-8.554-3.835-8.56-8.56 0-4.72 3.2-8.59 7.91-8.59h17.49v1.36c0 3.69 2.99 6.68 6.68 6.68h15.68v-3.85h2.28c2.314 0 4.19-1.876 4.19-4.19v-5.18c.003-1.113-.438-2.18-1.224-2.97-.786-.787-1.853-1.23-2.966-1.23z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogAccessoriesDongles;
