import actionTypes from '../actionTypes';

const caseMgmtActions = {};

caseMgmtActions.loadServiceDepartmentStart = () => ({
  type: actionTypes.LOAD_SERVICE_DEPARTMENT_START,
});

caseMgmtActions.loadServiceDepartmentFail = () => ({
  type: actionTypes.LOAD_SERVICE_DEPARTMENT_FAIL,
});

caseMgmtActions.loadServiceDepartmentSuccess = (results, count) => ({
  count,
  results,
  type: actionTypes.LOAD_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.loadSubstatesStart = () => ({
  type: actionTypes.LOAD_SUBSTATES_START,
});

caseMgmtActions.loadSubstatesFail = () => ({
  type: actionTypes.LOAD_SUBSTATES_FAIL,
});

caseMgmtActions.loadSubstatesSuccess = ({ list, count, dictionary }) => ({
  count,
  dictionary,
  list,
  type: actionTypes.LOAD_SUBSTATES_SUCCESS,
});

caseMgmtActions.loadServiceTeamSubstatesStart = () => ({
  type: actionTypes.LOAD_SERVICE_TEAM_SUBSTATES_START,
});

caseMgmtActions.loadServiceTeamSubstatesFail = () => ({
  type: actionTypes.LOAD_SERVICE_TEAM_SUBSTATES_FAIL,
});

caseMgmtActions.loadServiceTeamSubstatesSuccess = (results, count) => ({
  count,
  results,
  type: actionTypes.LOAD_SERVICE_TEAM_SUBSTATES_SUCCESS,
});

caseMgmtActions.removeSubstatesSuccess = (substate, index) => ({
  index,
  substate,
  type: actionTypes.REMOVE_SERVICE_TEAM_SUBSTATES_SUCCESS,
});

caseMgmtActions.updateSubstatesSuccess = (substate, index) => ({
  index,
  substate,
  type: actionTypes.UPDATE_SERVICE_TEAM_SUBSTATES_SUCCESS,
});

caseMgmtActions.loadServiceDepartmentRouteListStart = () => ({
  type: actionTypes.LOAD_SERVICE_DEPARTMENT_ROUTE_START,
});

caseMgmtActions.loadServiceDepartmentRouteListFail = () => ({
  type: actionTypes.LOAD_SERVICE_DEPARTMENT_ROUTE_FAIL,
});

caseMgmtActions.loadServiceDepartmentRouteListSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_SERVICE_DEPARTMENT_ROUTE_SUCCESS,
});

caseMgmtActions.addServiceDepartmentSuccess = (newDepartment, count) => ({
  count,
  newDepartment,
  type: actionTypes.ADD_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.editServiceDepartmentSuccess = (
  editedDepartment,
  serviceDepartmentID
) => ({
  editedDepartment,
  serviceDepartmentID,
  type: actionTypes.EDIT_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.ServiceDepartmentReloaded = () => ({
  type: actionTypes.SERVICE_DEPARTMENT_RELOADED,
});

caseMgmtActions.startDeleteOnDepartment = (serviceDepartmentID) => ({
  serviceDepartmentID,
  type: actionTypes.DELETE_SERVICE_DEPARTMENT_START,
});

caseMgmtActions.deleteOnDepartmentSuccess = (serviceDepartmentID) => ({
  serviceDepartmentID,
  type: actionTypes.DELETE_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.deleteOnDepartmentFail = () => ({
  type: actionTypes.DELETE_SERVICE_DEPARTMENT_FAIL,
});

caseMgmtActions.createNewTeamStart = () => ({
  type: actionTypes.CREATE_TEAM_SERVICE_DEPARTMENT_START,
});

caseMgmtActions.createNewTeamFail = () => ({
  type: actionTypes.CREATE_TEAM_SERVICE_DEPARTMENT_FAIL,
});

caseMgmtActions.createNewTeamSuccess = (team) => ({
  team,
  type: actionTypes.CREATE_TEAM_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.LoadDepartmentTeamListStart = () => ({
  type: actionTypes.LOAD_TEAM_SERVICE_DEPARTMENT_START,
});

caseMgmtActions.LoadDepartmentTeamListFail = () => ({
  type: actionTypes.LOAD_TEAM_SERVICE_DEPARTMENT_FAIL,
});

caseMgmtActions.LoadDepartmentTeamListSuccess = (results, count) => ({
  count,
  results,
  type: actionTypes.LOAD_TEAM_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.resetDepartmentTeamListSearch = () => ({
  type: actionTypes.RESET_DEPARTMENT_TEAM_LIST_SEARCH,
});

caseMgmtActions.deleteTeamStart = (teamID) => ({
  teamID,
  type: actionTypes.DELETE_TEAM_SERVICE_DEPARTMENT_START,
});

caseMgmtActions.deleteTeamSuccess = (teamID) => ({
  teamID,
  type: actionTypes.DELETE_TEAM_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.deleteTeamFail = () => ({
  type: actionTypes.DELETE_TEAM_SERVICE_DEPARTMENT_FAIL,
});

caseMgmtActions.updateTeamStart = () => ({
  type: actionTypes.UPDATE_TEAM_SERVICE_DEPARTMENT_START,
});

caseMgmtActions.updateTeamFail = () => ({
  type: actionTypes.UPDATE_TEAM_SERVICE_DEPARTMENT_FAIL,
});

caseMgmtActions.updateTeamSuccess = (team) => ({
  team,
  type: actionTypes.UPDATE_TEAM_SERVICE_DEPARTMENT_SUCCESS,
});

caseMgmtActions.setSearchDepartmentTerm = (term) => ({
  term,
  type: actionTypes.SET_SEARCH_SERVICE_DEPARTMENT,
});

caseMgmtActions.setSearchTeamTerm = (term) => ({
  term,
  type: actionTypes.SET_SEARCH_TEAM_SERVICE_DEPARTMENT,
});

caseMgmtActions.setIsSearchingTeamTerm = () => ({
  type: actionTypes.IS_SEARCHING_TEAM_SERVICE_DEPARTMENT,
});

caseMgmtActions.loadTeamClassification = (results, departmentName) => ({
  departmentName,
  results,
  type: actionTypes.LOAD_TEAM_CLASSIFICATION_DEPARTMENT,
});

caseMgmtActions.loadDepartmentClassificationStart = () => ({
  type: actionTypes.LOAD_DEPARTMENT_CLASSIFICATION_START,
});

caseMgmtActions.loadDepartmentClassificationSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_DEPARTMENT_CLASSIFICATION_SUCCESS,
});

caseMgmtActions.loadDepartmentClassificationError = (error) => ({
  error,
  type: actionTypes.LOAD_DEPARTMENT_CLASSIFICATION_FAIL,
});

caseMgmtActions.loadTeamRole = (teamRole) => ({
  teamRole,
  type: actionTypes.LOAD_TEAM_ROLE,
});

caseMgmtActions.loadTeamStart = () => ({
  type: actionTypes.LOAD_TEAM_MEMBER_START,
});

caseMgmtActions.loadTeamFail = () => ({
  type: actionTypes.LOAD_TEAM_MEMBER_FAIL,
});

caseMgmtActions.loadTeamSuccess = (results, count) => ({
  count,
  results,
  type: actionTypes.LOAD_TEAM_MEMBER_SUCCESS,
});

caseMgmtActions.startAddMember = () => ({
  type: actionTypes.START_ADD_TEAM_MEMBER,
});

caseMgmtActions.cleanMembersList = () => ({
  type: actionTypes.CLEAN_ADD_TEAM_MEMBER,
});

caseMgmtActions.addTeamMemberStart = () => ({
  type: actionTypes.ADD_TEAM_MEMBER_START,
});

caseMgmtActions.addTeamMemberFail = () => ({
  type: actionTypes.ADD_TEAM_MEMBER_FAIL,
});

caseMgmtActions.addTeamMemberSuccess = (member) => ({
  member,
  type: actionTypes.ADD_TEAM_MEMBER_SUCCESS,
});

caseMgmtActions.deleteMemberStart = (relationshipID) => ({
  relationshipID,
  type: actionTypes.DELETE_TEAM_MEMBER_START,
});

caseMgmtActions.deleteMemberFail = () => ({
  type: actionTypes.DELETE_TEAM_MEMBER_FAIL,
});

caseMgmtActions.deleteMemberSuccess = (relationshipID) => ({
  relationshipID,
  type: actionTypes.DELETE_TEAM_MEMBER_SUCCESS,
});

caseMgmtActions.LoadTeamsListStart = () => ({
  type: actionTypes.LOAD_TEAMS_LIST_START,
});

caseMgmtActions.LoadTeamsListFail = () => ({
  type: actionTypes.LOAD_TEAMS_LIST_FAIL,
});

caseMgmtActions.LoadTeamsListSuccess = (results, count) => ({
  count,
  results,
  type: actionTypes.LOAD_TEAMS_LIST_SUCCESS,
});

caseMgmtActions.loadTaskClassifications = (taskClassifications) => ({
  taskClassifications,
  type: actionTypes.LOAD_TASK_CLASSFICATIONS,
});

caseMgmtActions.loadTaskCategoriesSuccess = (categories, count) => ({
  categories,
  count,
  type: actionTypes.LOAD_TASK_CATEGORIES_SUCCESS,
});

caseMgmtActions.loadTaskStatusesFail = () => ({
  type: actionTypes.LOAD_TASK_STATUSES_FAIL,
});

caseMgmtActions.loadTaskStatusesStart = () => ({
  type: actionTypes.LOAD_TASK_STATUSES_START,
});

caseMgmtActions.loadTaskStatusesSuccess = (list) => ({
  list,
  type: actionTypes.LOAD_TASK_STATUSES_SUCCESS,
});

caseMgmtActions.loadTaskTypesFail = () => ({
  type: actionTypes.LOAD_TASK_TYPES_FAIL,
});

caseMgmtActions.loadTaskTypesStart = () => ({
  type: actionTypes.LOAD_TASK_TYPES_START,
});

caseMgmtActions.loadTaskTypesSuccess = (list) => ({
  list,
  type: actionTypes.LOAD_TASK_TYPES_SUCCESS,
});

caseMgmtActions.loadTaskCategoriesStart = () => ({
  type: actionTypes.LOAD_TASK_CATEGORIES_START,
});

caseMgmtActions.loadTaskCategoriesFail = () => ({
  type: actionTypes.LOAD_TASK_CATEGORIES_FAIL,
});

caseMgmtActions.deleteDepartmentCategoryStart = (categoryID) => ({
  categoryID,
  type: actionTypes.DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_START,
});

caseMgmtActions.deleteDepartmentCategorySuccess = (categoryID) => ({
  categoryID,
  type: actionTypes.DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_SUCCESS,
});

caseMgmtActions.deleteOnDepartmentCategoryFail = () => ({
  type: actionTypes.DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_FAIL,
});

caseMgmtActions.selectServiceTeam = (serviceTeamId) => ({
  serviceTeamId,
  type: actionTypes.SELECT_SERVICE_TEAM,
});

caseMgmtActions.selectApprovalServiceTeam = (serviceTeamId) => ({
  serviceTeamId,
  type: actionTypes.SELECT_APPROVAL_SERVICE_TEAM,
});

caseMgmtActions.selectServiceDepartmentType = (serviceTeamType) => ({
  serviceTeamType,
  type: actionTypes.SELECT_TYPE_FAQ,
});

caseMgmtActions.setDefaultServiceTeam = (serviceTeam) => ({
  serviceTeam,
  type: actionTypes.SET_DEFAULT_SERVICE_TEAM,
});

caseMgmtActions.defaultServiceTeamFailure = (errorMsg) => ({
  errorMsg,
  type: actionTypes.DEFAULT_SERVICE_TEAM_ERROR,
});

caseMgmtActions.setMyTeams = (serviceTeams) => ({
  serviceTeams,
  type: actionTypes.SET_MY_TEAMS,
});

caseMgmtActions.setMyTeamsFailure = (errorMsg) => ({
  errorMsg,
  type: actionTypes.SET_MY_TEAMS_ERROR,
});

caseMgmtActions.setConfigDefaultServiceTeamStart = () => ({
  type: actionTypes.SET_CONFIG_DEFAULT_SERVICE_TEAM_START,
});

caseMgmtActions.setConfigDefaultServiceTeamSuccess = () => ({
  type: actionTypes.SET_CONFIG_DEFAULT_SERVICE_TEAM_SUCCESS,
});

caseMgmtActions.setConfigDefaultServiceTeamError = (errorMsg) => ({
  errorMsg,
  type: actionTypes.SET_CONFIG_DEFAULT_SERVICE_TEAM_ERROR,
});

caseMgmtActions.setAreFeedsRead = (areFeedsRead) => ({
  areFeedsRead,
  type: actionTypes.SET_ARE_FEEDS_READ,
});

caseMgmtActions.setLocationQuery = (query = {}) => ({
  query,
  type: actionTypes.CASE_SET_QUERY,
});

caseMgmtActions.getServiceTeamLocationsStart = () => ({
  type: actionTypes.GET_SERVICE_TEAM_LOCATIONS_START,
});

caseMgmtActions.getServiceTeamLocationsSuccess = (locations) => ({
  locations,
  type: actionTypes.GET_SERVICE_TEAM_LOCATIONS_SUCCESS,
});

caseMgmtActions.getServiceTeamLocationsFail = () => ({
  type: actionTypes.GET_SERVICE_TEAM_LOCATIONS_FAIL,
});

export default caseMgmtActions;
