import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareDevelopment extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M35.83 62.69c.522.643 1.467.742 2.11.22.643-.522.742-1.467.22-2.11l-5.76-7.11L38.16 46c.497-.663.363-1.603-.3-2.1-.663-.497-1.603-.363-2.1.3l-6.46 8.63c-.42.54-.42 1.3 0 1.84l6.53 8.02zm21.73.23c.31.25.705.368 1.1.327.396-.04.76-.238 1.01-.547l6.5-8c.42-.54.42-1.3 0-1.84l-6.46-8.62c-.322-.43-.847-.653-1.38-.59-.53.065-.99.408-1.2.9-.21.494-.142 1.06.18 1.49l5.76 7.69-5.76 7.11c-.495.646-.384 1.57.25 2.08zm-13.36.21c.14.04.284.06.43.06.664 0 1.25-.434 1.44-1.07L51 45.62c.155-.518.023-1.08-.348-1.473-.37-.394-.924-.56-1.45-.435-.527.124-.947.52-1.102 1.038l-4.92 16.5c-.118.385-.076.8.116 1.154.192.353.518.615.904.726z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.65c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.528 15 25.324 15 23.84c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareDevelopment;
