const api_config_v0_1_configuration_remote_user_create_flag = {
  DEBUG_decrypted: null,
  created_in_elc: false,
  eid: '222bfa37-63bd-4789-8239-c15c25d7331a',
  is_protected: false,
  key: 'remote_user_create_flag',
  last_sync_time: null,
  src_active_status: true,
  sys_date_updated: '2020-09-25T19:06:40.576892Z',
  type: 'boolean',
  value: false,
};

export default api_config_v0_1_configuration_remote_user_create_flag;
