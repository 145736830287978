import React, { useCallback } from 'react';
import pt from 'prop-types';

import Page from '../Page';

import LocalStorageAdapter from '../../hooks/storage/LocalStorageAdapter';
import localStorageConfig from '../../hooks/storage/localStorageConfig';

const propTypes = {
  error: pt.string,
  resetErrorBoundary: pt.func,
};

const { STORAGE_PREFIX, STORAGE_NAMESPACES } = localStorageConfig;

const BadSessionFallback = ({ error, resetErrorBoundary }) => {
  const handleClearToken = useCallback(() => {
    const localStorage = new LocalStorageAdapter();

    localStorage.removeItem(`${STORAGE_PREFIX}_${STORAGE_NAMESPACES.SESSION}`);
    resetErrorBoundary();
  }, [resetErrorBoundary]);

  return (
    <Page>
      &nbsp;
      <div>
        <h2>Oops</h2>
        <p>Something went wrong.</p>
        {process.env.NODE_ENV === 'development' && error && (
          <>
            <code>{error?.toString()}</code>
            <br />
            <br />
          </>
        )}
        <button
          className='ui primary button'
          onClick={handleClearToken}
          type='button'
        >
          Try Again
        </button>
      </div>
      &nbsp;
    </Page>
  );
};

BadSessionFallback.propTypes = propTypes;

export default BadSessionFallback;
