import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOfficeSuppliesErasers extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M72.708 37.8L57.745 22.84c-.914-.918-2.405-.918-3.324 0L34.476 42.79 52.76 61.073l19.948-19.947c.916-.92.916-2.41 0-3.326zM22.84 54.423c-.918.92-.918 2.408 0 3.327L37.8 72.71c.918.92 2.404.92 3.323 0l9.972-9.972L32.813 44.45l-9.972 9.973z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOfficeSuppliesErasers;
