import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Image, List } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';
import immutable from 'immutable';
import SelectBundleListItemController from '../controllers/SelectBundleListItemController';

class SelectBundleListItem extends Component {
  static propTypes = {
    bundle: ImmutablePropTypes.map,
    images: ImmutablePropTypes.list,
    loadBundleImages: PropTypes.func,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    bundle: immutable.Map(),
    images: immutable.List(),
    loadBundleImages: _.noop,
    onClick: _.noop,
  };

  componentDidMount() {
    const { loadBundleImages, bundle } = this.props;
    loadBundleImages({
      bundle,
    });
  }

  handleClick = () => {
    const { bundle, onClick } = this.props;

    const bundleID = bundle.get('id');
    onClick(bundleID);
  };

  render() {
    const { bundle, images } = this.props;

    const otherTwoImages = images.slice(1, 3);

    return (
      <List.Item as='a' key={bundle.get('id')} onClick={this.handleClick}>
        <Button
          className='text'
          content='Select'
          floated='right'
          type='button'
        />
        <Header content={bundle.get('name')} />
        {images.size > 0 && (
          <div className='image-container'>
            <Image
              alt={images.first().get('description')}
              src={images.getIn([0, 'thumbnail'])}
            />
          </div>
        )}

        <List.Content>
          <List>
            {otherTwoImages &&
              otherTwoImages.map((image, index) => {
                // image.id can't be used as key. If there are repeated products across the role bundle, there are chances
                // for duplicated image.id
                const key = index;

                return (
                  <List.Item key={key}>
                    <Image size='mini' src={image.get('thumbnail')} />
                    <List.Content>
                      <List.Description>
                        {image.get('esp_catalog_name')}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
          </List>
        </List.Content>
      </List.Item>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SelectBundleListItem = SelectBundleListItemController(SelectBundleListItem);

export default SelectBundleListItem;
