import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareCollaboration extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M41.38 50.64c-.203-1.653-1.54-2.935-3.2-3.07h-.56c1.34-.71 2.023-2.24 1.658-3.712-.366-1.47-1.687-2.504-3.203-2.504-1.516 0-2.837 1.033-3.203 2.504-.365 1.47.318 3.002 1.658 3.712h-1.1c-2.2.18-2.9 3.07-2.9 3.07 6.1 4.66 10.85 0 10.85 0zm14.01-5.96c.004 1.213.677 2.324 1.75 2.89h-1.09c-2.2.18-2.9 3.07-2.9 3.07 6.1 4.65 10.84 0 10.84 0-.203-1.653-1.54-2.935-3.2-3.07h-.56c1.422-.76 2.084-2.437 1.564-3.963-.52-1.525-2.068-2.45-3.658-2.184-1.59.266-2.752 1.645-2.746 3.257zm-5.82 21.07H49c1.34-.71 2.023-2.24 1.658-3.712-.366-1.47-1.687-2.504-3.203-2.504-1.516 0-2.837 1.033-3.203 2.504-.365 1.47.318 3.002 1.658 3.712h-1.08c-2.2.18-2.9 3.07-2.9 3.07 6.1 4.65 10.84 0 10.84 0-.203-1.653-1.54-2.935-3.2-3.07zm5.98 2.11c-.474.26-.655.85-.41 1.332.247.48.83.68 1.32.448C63.25 66.18 67 60.36 67 53.25c0-.552-.448-1-1-1s-1 .448-1 1c0 6.32-3.36 11.5-9.45 14.61zM38.51 38.23c5.742-3.428 12.935-3.29 18.54.36.458.252 1.033.108 1.32-.33.285-.438.185-1.022-.23-1.34-6.246-4.066-14.262-4.222-20.66-.4-.36.15-.602.494-.618.884-.016.39.197.754.544.93.348.18.767.138 1.074-.104h.03zm.08 31.43c.327.145.706.104.995-.107.29-.21.443-.56.405-.915-.038-.355-.263-.663-.59-.808-6.32-2.81-9.4-7.58-9.4-14.58v-.47c0-.552-.448-1-1-1s-1 .448-1 1v.53c0 7.71 3.56 13.23 10.59 16.35z' />
          <path d='M78 16.5H18c-4.418 0-8 3.582-8 8V71c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V24.5c0-4.418-3.582-8-8-8zm-39.7 4.65c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C16.202 26.528 15 25.324 15 23.84c0-1.486 1.204-2.69 2.69-2.69h.01zM83 71c0 2.76-2.24 5-5 5H18c-2.76 0-5-2.24-5-5V31.1h70V71z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareCollaboration;
