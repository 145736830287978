import React, { useCallback, useState } from 'react';
import pt from 'prop-types';
import ChatInputController from '../../functional/ChatInput/ChatInputController';
import DateTools, { BROWSER_LANGUAGE } from '../../../../v1/utils/DateTools';
import { DatePicker } from 'cascara-middleware';
import ChatInputTypes from '../../../../v2/components/display/ChatMessage/ChatInputTypes';

const propTypes = {
  sendMessage: pt.func,
};

const ChatDate = ({ sendMessage }) => {
  const [isDisable, setIsDisable] = useState(false);

  const handleOnClick = useCallback(
    (val, dateString, fromFormat) => {
      const dateFormatted = DateTools.formatToUSLang(dateString, fromFormat);
      sendMessage({
        isPicker: true,
        message: dateFormatted,
        messageType: ChatInputTypes.DATE,
      });

      setIsDisable(true);
    },
    [sendMessage]
  );
  return (
    <DatePicker
      disabled={isDisable}
      lang={BROWSER_LANGUAGE}
      name='chatDate'
      onChange={handleOnClick}
      style={{ marginLeft: '0.5em' }}
    />
  );
};

ChatDate.propTypes = propTypes;
const ChatDateCtrl = ChatInputController(ChatDate);
export default ChatDateCtrl;
