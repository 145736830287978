import { GraphQLClient } from 'graphql-request';

const endpoint =
  'https://88706ecd-e441-4f39-a851-e1b48116b449.mock.pstmn.io/api/graph/';

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    // authorization: `Bearer MY_TOKEN`,
    'Content-Type': 'application/json',
    cookie:
      'csrftoken=LXeRe0XbJfzindzPb9eaIg8qUzuPzGgJ4yNUnSgTCOzIblkojrE1pKORDEfelTp8',
    'x-mock-match-request-body': 'true',
  },
});

export default graphQLClient;
