import actionTypes from './actionTypes';

const assetActions = {};

assetActions.loadAssetsStart = () => ({
  type: actionTypes.LOAD_ASSETS_START,
});

assetActions.loadOneAssetStart = (id) => ({
  id,
  type: actionTypes.LOAD_ONE_ASSET_START,
});

assetActions.loadOneAssetSuccess = (id, asset) => ({
  asset,
  id,
  type: actionTypes.LOAD_ONE_ASSET_SUCCESS,
});

assetActions.updateOneAssetSuccess = (id, asset) => ({
  asset,
  id,
  type: actionTypes.UPDATE_ONE_ASSET_SUCCESS,
});

assetActions.loadOneAssetFail = (id, errorMsg) => ({
  errorMsg,
  id,
  type: actionTypes.LOAD_ONE_ASSET_FAIL,
});

assetActions.loadAssetsSuccess = (assetItems) => ({
  assetItems,
  type: actionTypes.LOAD_ASSETS_SUCCESS,
});

assetActions.loadAssetsFailure = (error) => ({
  error,
  type: actionTypes.LOAD_ASSETS_FAIL,
});

assetActions.removeItemSuccess = (arrayID) => ({
  arrayID,
  type: actionTypes.REMOVE_ASSET_ITEM_SUCCESS,
});

assetActions.removeItemFail = () => ({
  type: actionTypes.REMOVE_ASSET_ITEM_FAIL,
});

assetActions.resetAssetSuccess = () => ({
  type: actionTypes.RESET_ASSET_SUCCESS,
});

assetActions.clearAsset = () => ({
  type: actionTypes.CLEAR_ASSET_ITEM,
});

assetActions.getPendingOrdersStart = () => ({
  type: actionTypes.GET_PENDING_ORDERS_START,
});

assetActions.getPendingOrdersSuccess = (orders) => ({
  orders,
  type: actionTypes.GET_PENDING_ORDERS_SUCCESS,
});

assetActions.getPendingOrdersFail = (error) => ({
  error,
  type: actionTypes.GET_PENDING_ORDERS_FAIL,
});

assetActions.getPendingOrderItemsStart = (orderID) => ({
  orderID,
  type: actionTypes.GET_PENDING_ORDER_ITEMS_START,
});

assetActions.getPendingOrderItemsSuccess = (cartItems) => ({
  cartItems,
  type: actionTypes.GET_PENDING_ORDER_ITEMS_SUCCESS,
});

assetActions.getPendingOrderItemsFail = (error) => ({
  error,
  type: actionTypes.GET_PENDING_ORDER_ITEMS_FAIL,
});

assetActions.setPendingOrderRequestID = (requestOrderID) => ({
  requestOrderID,
  type: actionTypes.SET_PENDING_REQUEST_ORDER_ID,
});

assetActions.startSupportModal = () => ({
  type: actionTypes.ASSET_START_SUPPORT_MODAL,
});

export default assetActions;
