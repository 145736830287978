import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Loader, Menu } from 'semantic-ui-react';
import queryString from 'query-string';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

class MenuPagination extends PureComponent {
  static propTypes = {
    /** Current page */
    currentPage: PropTypes.number.isRequired,
    /** The associated data is still loading **/
    loading: PropTypes.bool,
    /** Maximum number of pages */
    numberPages: PropTypes.number.isRequired,
    /** The current page can be pass via the QUERY params */
    pageByQuery: PropTypes.bool,

    pathOptions: PropTypes.shape({
      categoryID: PropTypes.string,
      departmentID: PropTypes.string,
      report: PropTypes.string,
      subcategoryID: PropTypes.string,
    }),

    /** Pathname to pass as a link */
    pathname: PropTypes.string.isRequired,
    /** Query parameters to pass in the link */
    query: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      orderBy: PropTypes.any,
      page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      show: PropTypes.any,
    }),
    /** location.query object */
    search: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      query: PropTypes.any,
    }),
    /** Size menu */
    size: PropTypes.string,
    /** URL paramter to pass only when pageByQuery is true - receives an object with different keys */
    urlParameter: PropTypes.shape({
      departmentID: PropTypes.string,
    }),
  };

  static defaultProps = {
    loading: false,
    pageByQuery: false,
    pathOptions: {},
    query: null,
    search: null,
    size: 'small',
    urlParameter: null,
  };

  createMenuItem({ page, icon = null }, withKey) {
    const {
      currentPage,
      numberPages,
      loading,
      pathname,
      query,
      pageByQuery,
      search,
      urlParameter,
      pathOptions,
    } = this.props;

    const disabled =
      (currentPage === 1 && icon === 'left chevron') ||
      (currentPage === numberPages && icon === 'right chevron');

    const paramOnPathname = pageByQuery
      ? urlParameter
      : {
          page: page,
        };

    const uiPathOptions = {
      ...paramOnPathname,
      ...pathOptions,
    };

    let finalQuery = pageByQuery
      ? {
          ...query,
          page,
        }
      : query;

    if (search) {
      finalQuery = {
        ...finalQuery,
        ...search,
      };
    }

    const isCurrentPage = currentPage === page;
    const key = {};
    if (withKey) {
      key.key = page + 1;
    }

    if (isCurrentPage && !icon) {
      return (
        <Menu.Item
          {...key}
          active
          activeClassName={'active'}
          as={NavLink}
          name={String(page)}
          to={{
            pathname: uiPathGenerator.genPath(pathname, uiPathOptions),
            search: `?${queryString.stringify(finalQuery)}`,
          }}
        >
          {loading ? <Loader active size={'small'} /> : null}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item
        {...key}
        activeClassName=''
        as={NavLink}
        disabled={disabled}
        icon={icon}
        name={!icon ? String(page) : null}
        style={{
          cursor: 'pointer',
        }}
        to={{
          pathname: uiPathGenerator.genPath(pathname, uiPathOptions),
          search: `?${queryString.stringify(finalQuery)}`,
        }}
      />
    );
  }

  middlePages() {
    const { currentPage, numberPages } = this.props;
    if (currentPage < 3) {
      return [2, 3, 4];
    }
    if (currentPage >= numberPages - 1) {
      return [numberPages - 3, numberPages - 2, numberPages - 1];
    }
    return [currentPage - 1, currentPage, currentPage + 1];
  }

  render() {
    const { currentPage, numberPages, size } = this.props;

    const includesChevrons = numberPages > 2;

    if (numberPages === 1) {
      return null;
    }

    return (
      <Menu pagination size={size}>
        {includesChevrons
          ? this.createMenuItem({
              icon: 'left chevron',
              page: currentPage > 1 ? currentPage - 1 : 1,
            })
          : null}

        {this.createMenuItem({
          page: 1,
        })}

        {numberPages === 3 || numberPages === 4
          ? this.createMenuItem({
              page: 2,
            })
          : null}

        {numberPages === 4
          ? this.createMenuItem({
              page: 3,
            })
          : null}

        {numberPages > 4
          ? this.createMenuItem({
              page: this.middlePages()[0],
            })
          : null}

        {numberPages > 4
          ? this.createMenuItem({
              page: this.middlePages()[1],
            })
          : null}

        {numberPages > 4
          ? this.createMenuItem({
              page: this.middlePages()[2],
            })
          : null}

        {this.createMenuItem({
          page: numberPages,
        })}

        {includesChevrons
          ? this.createMenuItem({
              icon: 'right chevron',
              page: currentPage < numberPages ? currentPage + 1 : numberPages,
            })
          : null}
      </Menu>
    );
  }
}

export default MenuPagination;
