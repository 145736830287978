import { createSelector } from 'reselect';
import Immutable from 'immutable';

/**
 * @param getNodesByID Selector to the nodes as returned from the api.
 */
const createHierarchyRootNodesSelector = (getNodesByID) => {
  const wireNodesTree = (node, nodesByID) => {
    // children as returned by api
    const children = node.get('children');

    node = node.set('isLeaf', children?.isEmpty());

    let wiredChildren = Immutable.List();

    children?.forEach((c) => {
      const childID = c.get('id');

      if (nodesByID.has(childID)) {
        const childNode = nodesByID.get(childID);
        const wiredChildNode = wireNodesTree(childNode, nodesByID);

        wiredChildren = wiredChildren.push(wiredChildNode);
      }
    });

    node = node.set('children', wiredChildren);

    return node;
  };

  const selector = createSelector(
    [getNodesByID],

    (nodesByID) => {
      let rootNodes = nodesByID?.toList().filter((node) => !node.get('parent'));

      rootNodes = rootNodes?.map((root) => wireNodesTree(root, nodesByID));

      return rootNodes;
    }
  );

  return selector;
};

export default createHierarchyRootNodesSelector;
