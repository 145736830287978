const FilterTitles = {
  ALPHABETICAL: 'label.alphabetical',
  ASSIGNED_TO: 'label.assign_to',
  LOCATION: 'label.location',
  REQUESTED_BY: 'label.requested_by',
  SHOW: 'label.show',
  SORT_BY: 'label.sort_by',
  STATE: 'label.state',
  TIME: 'label.time',
  TIME_PERIOD: 'label.time_period',
  TYPE: 'label.type',
};

export default Object.freeze(FilterTitles);
