import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsHazard extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M71 74l-46.75-.14c-1.657 0-3-1.343-3-3s1.343-3 3-3L71 68c1.657 0 3 1.343 3 3s-1.343 3-3 3zM32.06 55.92l-2.53 9.05h34.65l-3.46-14.22m-1.14-4.69l-2.54-10.39-20.35 3.66-3.19 11.44m4.63-16.6L55.9 31l-2.82-11.58c-.54-3-2.42-5.41-5.41-5.41s-4.76 2.41-5.41 5.41l-4.13 14.75z' />
          <path d='M48 9c21.54 0 39 17.46 39 39S69.54 87 48 87 9 69.54 9 48 26.46 9 48 9zm0-5C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsHazard;
