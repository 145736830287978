import React, { Component } from 'react';
import classNames from 'classnames';

// Globals
import TypeHero, { TypeDefaults } from '../../../globals/blocks/TypeHero';

class Hero02 extends Component {
  static propTypes = TypeHero;
  static defaultProps = TypeDefaults;

  render() {
    const {
      avatarImage,
      headline,
      image,
      imageSize,
      template,
      type,
    } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {headline && <h4 className='headline'>{headline}</h4>}

        {headline && (
          <img
            alt=''
            className={classNames('image', imageSize, {
              avatar: avatarImage,
            })}
            src={image}
          />
        )}
      </div>
    );
  }
}

export default Hero02;
