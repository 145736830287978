import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
// Global
import TypeAddResponses, {
  TypeDefaults,
} from '../../../globals/blocks/TypeAddResponses';
// Molecule
import ValidatedAddResponses from '../../molecules/ValidatedAddResponses';
import BodyClassName from '../../../../globals/BodyClassName';

class AddResponses01 extends PureComponent {
  static propTypes = {
    ...TypeAddResponses,
    buttonShowBottomAfter: PropTypes.number,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    query: PropTypes.shape({
      taskId: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  static defaultProps = {
    ...TypeDefaults,
    /** Button Add to display after NUMBER entries */
    buttonShowBottomAfter: 0,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    query: {},
  };

  render() {
    const {
      addFromMessage,
      addNewWithModal,
      buttonAddResponseText,
      buttonShowBottomAfter,
      errors,
      errorsApi,
      id,
      isEditable,
      nonEmptyLineRequired,
      query,
      readOnly,
      showAddResponse,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    return (
      <BodyClassName className='blocks-top'>
        <div className='block' data-blocktype={type} data-template={template}>
          <ValidatedAddResponses
            addFromMessage={addFromMessage}
            addNewWithModal={addNewWithModal}
            buttonAddResponseText={buttonAddResponseText}
            buttonShowBottomAfter={buttonShowBottomAfter}
            formError={finalError}
            isEditable={isEditable}
            name={id}
            nonEmptyLineRequired={nonEmptyLineRequired}
            query={query}
            readOnly={readOnly}
            showAddResponse={showAddResponse}
          />
        </div>
      </BodyClassName>
    );
  }
}

export default AddResponses01;
