import { fromJS } from 'immutable';
import { get } from 'lodash';

import { camelizeKeys } from '../utils/ObjectUtils';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  analyticsEnabled: false,

  cardDelegate: {
    businessObjOwner: null,
    cardId: null,
    open: false,
  },

  caseDelegate: {
    open: false,
    taskId: null,
  },

  employeeOnboarded: {
    isEmployeeWFOpen: false,
    started: false,
    workflowEID: null,
  },

  isBaristaOpened: false,

  isFeedbackModalOpen: false,

  isHelloDone: false,

  isHelloLoading: false,

  isMoreMenuOpen: false,

  rollbarEnabled: false,

  rollbarEnvironment: 'devserver',
  s3_signed_url_cfg: {
    enabled: false,
    sign_buffer_duration: null,
    sign_duration: null,
  },

  workflowModal: {
    isLaunching: false,
    isOpen: false,
    requestID: null,
  },
});

const appUIReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.APP_UI_CLOSE_MORE_MENU:
      return state.set('isMoreMenuOpen', false);

    case actionTypes.APP_UI_TOGGLE_MORE_MENU:
      return state.set('isMoreMenuOpen', !state.get('isMoreMenuOpen'));

    case actionTypes.APP_UI_OPEN_WORKFLOW_MODAL:
      return state
        .setIn(['workflowModal', 'isOpen'], true)
        .setIn(['workflowModal', 'requestID'], action.requestID);

    case actionTypes.APP_UI_CLOSE_WORKFLOW_MODAL:
      return state.set('workflowModal', INITIAL_STATE.get('workflowModal'));

    case actionTypes.CLOSE_SUPPORT_CHANNEL:
      return state.set('isBaristaOpened', false);

    case actionTypes.OPEN_SUPPORT_CHANNEL:
      return state.set('isBaristaOpened', true);

    case actionTypes.APP_BOOTSTRAP_SUCCESS: {
      const s3_signed_url_cfg = action.bootstrapData.s3_signed_url_cfg || {};
      // API is omitting the menu_customizations key when not set but we still want to see it as an empty object
      const menuCustomization = camelizeKeys(
        get(action.bootstrapData, 'menu_customizations', {})
      );

      return state
        .set(
          'analyticsEnabled',
          Boolean(action.bootstrapData.analytics_enabled)
        )
        .set('menuCustomization', fromJS(menuCustomization))
        .set('rollbarEnabled', Boolean(action.bootstrapData.rollbar_enabled))
        .setIn(
          ['employeeOnboarded', 'started'],
          Boolean(action.bootstrapData.employee_onboarded)
        )
        .setIn(
          ['employeeOnboarded', 'workflowEID'],
          action.bootstrapData.workflow_id
        )
        .update('rollbarEnvironment', (original) => {
          // replace the value only if it's set in the action
          const value = action.bootstrapData.environment
            ? action.bootstrapData.environment
            : original;
          return value;
        })
        .updateIn(['s3_signed_url_cfg'], (original) =>
          original.merge(fromJS(s3_signed_url_cfg))
        );
    }

    case actionTypes.APP_UI_OPEN_CARD_DELEGATE: {
      return state
        .setIn(['cardDelegate', 'open'], true)
        .setIn(['cardDelegate', 'cardId'], action.cardId)
        .setIn(['cardDelegate', 'businessObjOwner'], action.businessObjOwner);
    }

    case actionTypes.APP_UI_CLOSE_CARD_DELEGATE: {
      return state
        .setIn(['cardDelegate', 'open'], false)
        .setIn(['cardDelegate', 'cardId'], null)
        .setIn(['cardDelegate', 'businessObjOwner'], null);
    }

    case actionTypes.APP_UI_OPEN_CASE_DELEGATE: {
      return state
        .setIn(['caseDelegate', 'open'], true)
        .setIn(['caseDelegate', 'taskId'], action.taskId);
    }

    case actionTypes.APP_UI_CLOSE_CASE_DELEGATE: {
      return state
        .setIn(['caseDelegate', 'open'], false)
        .setIn(['caseDelegate', 'taskId'], null);
    }

    case actionTypes.APP_UI_OPEN_EE_WF: {
      return state
        .setIn(['workflowModal', 'isLaunching'], true)
        .setIn(['employeeOnboarded', 'isEmployeeWFOpen'], true);
    }

    case actionTypes.APP_UI_LAUNCH_WF_START: {
      return state.setIn(['workflowModal', 'isLaunching'], true);
    }

    case actionTypes.APP_HELLO_ACTIONS_START: {
      return state.set('isHelloDone', false).set('isHelloLoading', true);
    }

    case actionTypes.APP_HELLO_ACTIONS_END: {
      return state.set('isHelloDone', true).set('isHelloLoading', false);
    }

    case actionTypes.APP_UI_OPEN_FEEDBACK_MODAL: {
      return state.set('isFeedbackModalOpen', true);
    }

    case actionTypes.APP_UI_CLOSE_FEEDBACK_MODAL: {
      return state.set('isFeedbackModalOpen', false);
    }

    default:
      return state;
  }
};

export default appUIReducer;
