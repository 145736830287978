const CardState = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',

  RESOLVED: 'RESOLVED',
  // API Default
  SNOOZED: 'SNOOZED',
};

export default CardState;
