import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsCalendarEvent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M16 75.1c-.828 0-1.5-.672-1.5-1.5V34.43h60.65v21.15c1.042.362 2.047.824 3 1.38v-41c0-2.485-2.015-4.5-4.5-4.5h-9.18v-.94c0-.552-.448-1-1-1s-1 .448-1 1v.94H27.55v-.94c0-.552-.448-1-1-1s-1 .448-1 1v.94H16c-2.485 0-4.5 2.015-4.5 4.5v57.62c0 2.485 2.015 4.5 4.5 4.5h37.7c-.392-.97-.693-1.974-.9-3L16 75.1zm-1.5-59.15c0-.828.672-1.5 1.5-1.5h9.55v7c-1.95.504-3.222 2.38-2.968 4.377.254 1.998 1.954 3.496 3.968 3.496 2.014 0 3.714-1.498 3.968-3.496S29.5 21.954 27.55 21.45v-7h34.92v7c-1.95.504-3.222 2.38-2.968 4.377.254 1.998 1.954 3.496 3.968 3.496 2.014 0 3.714-1.498 3.968-3.496s-1.018-3.873-2.968-4.377v-7h9.18c.828 0 1.5.672 1.5 1.5v16.48H14.5V15.95z' />
          <path d='M69.47 57.32c-7.87 0-14.248 6.378-14.25 14.247-.002 7.868 6.375 14.25 14.243 14.253 7.87.004 14.25-6.37 14.257-14.24 0-7.872-6.378-14.254-14.25-14.26zm0 25.5c-4.55 0-8.654-2.742-10.395-6.947-1.74-4.205-.776-9.045 2.444-12.26 3.212-3.218 8.06-4.178 12.26-2.434 4.2 1.74 6.94 5.85 6.94 10.4-.02 6.2-5.05 11.22-11.25 11.23v.01z' />
          <path d='M73.07 67.87l-5.37 6.21-1.9-1.87c-.596-.546-1.518-.522-2.084.055-.567.577-.574 1.5-.016 2.085l3 3c.28.275.657.43 1.05.43h.06c.413-.02.8-.208 1.07-.52l6.41-7.42c.552-.627.492-1.583-.135-2.135-.627-.552-1.583-.492-2.135.135l.05.03z' />
        </g>
      </svg>
    );
  }
}

export default CardsCalendarEvent;
