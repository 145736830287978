import { find, has, isEmpty, isNil, isNumber, noop } from 'lodash';

// Utils
import APIcall from '../../utils/APIcall';
import async from 'async';
// Actions
import caseMgmtActions from '../caseMgmtActions';
import departmentsThunks from '../departmentsThunks';
import endpointGenerator from '../../utils/endpointGenerator';
// Packages
import EspFilters from 'esp-util-filters';
import { fromJS } from 'immutable';
// Selector
import getCurrentUser from '../../selectors/getCurrentUser';
import { initialize } from 'redux-form/immutable';
import jobRoleThunks from '../jobRoleThunks';
import locationThunks from '../locationsThunks';
// Global
import { TeamRoles } from '../../globals/TeamRolesCaseMgmt';
// Thunks
import userEntitiesThunks from '../userEntitiesThunks';

/**
 * Load a user entity from a team if needed only
 * @param newTeam
 * @param dispatch
 * @param state
 */
const loadUserFromTeam = (newTeam, dispatch, state) =>
  new Promise((resolve, reject) => {
    // Check if already have this user
    const userExist = state.hasIn(['entities', 'users', newTeam.team_lead]);

    if (userExist) {
      resolve();
    } else {
      dispatch(userEntitiesThunks.getListUsersByID([newTeam.team_lead]))
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

const getOneMember = (state, userID, teamID) => {
  // Check if the member already exist
  const teamList = state.getIn(['caseMgmt', 'serviceTeam', 'list']);
  const teamSelectedIndex = teamList.findIndex(
    (team) => team.get('id') === teamID
  );

  // The team doesn't exist
  if (!isNumber(teamSelectedIndex) || teamSelectedIndex === -1) {
    return null;
  } else {
    const teamMembers = teamList.getIn([teamSelectedIndex, 'team_members']);
    const memberSelected = teamMembers.findIndex(
      (member) => member.get('user') === userID
    );

    if (!isNumber(memberSelected) || memberSelected === -1) {
      return null;
    }

    return teamList.getIn([teamSelectedIndex, 'team_members', memberSelected]);
  }
};

const caseMgmtThunks = {};

caseMgmtThunks.addSubstate =
  (data, limit = 10, offset) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(caseMgmtActions.loadServiceTeamSubstatesStart());
      APIcall.post({
        data,
        error(err) {
          dispatch(caseMgmtActions.loadServiceTeamSubstatesFail());
          reject(err);
        },
        preventShowError: true,
        success({ body }) {
          dispatch(
            caseMgmtThunks.loadSubstates({
              limit,
              offset,
              serviceTeamEID: data.service_team,
            })
          );
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('task.taskSubStatus'),
      });
    });

caseMgmtThunks.editSubstate = (substateID, rowIndex, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (isNil(substateID)) {
      reject(new Error('Substate needs to be defined'));
      return;
    }
    if (isNil(rowIndex)) {
      reject(new Error('Row index needs to be defined'));
      return;
    }

    const url = endpointGenerator.genPath('task.taskSubStatus.instance', {
      substateID,
    });

    dispatch(caseMgmtActions.loadServiceTeamSubstatesStart());
    APIcall.put({
      data,
      error(err) {
        dispatch(caseMgmtActions.loadServiceTeamSubstatesFail());
        reject(err);
      },
      success({ body }) {
        dispatch(caseMgmtActions.updateSubstatesSuccess(body, rowIndex));
        resolve(body);
      },
      token: true,
      url,
    });
  });

caseMgmtThunks.toggleActiveSubstate = (substate, rowIndex) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!substate) {
      reject(new Error('Substate needs to be defined'));
      return;
    }
    dispatch(
      caseMgmtThunks.editSubstate(
        substate.get('id'),
        rowIndex,
        substate.set('active', !substate.get('active')).toJS()
      )
    )
      .then((body) => {
        resolve(body);
      })
      .catch((err) => {
        reject(err);
      });
  });
caseMgmtThunks.removeSubstate =
  (substate = null, rowIndex = null, limit = 10, offset, page = 1) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!substate) {
        reject('Error - Substate needs to be defined');
      }

      const state = getState();
      const total = state.getIn([
        'caseMgmt',
        'serviceTeam',
        'substates',
        'count',
      ]);
      const rows = state.getIn([
        'caseMgmt',
        'serviceTeam',
        'substates',
        'list',
      ]);

      dispatch(caseMgmtActions.loadServiceTeamSubstatesStart());
      const url = endpointGenerator.genPath('task.taskSubStatus.instance', {
        substateID: substate.get('id'),
      });
      APIcall.delete({
        error(err) {
          dispatch(caseMgmtActions.loadServiceTeamSubstatesFail());
          reject(err);
        },
        success(/* {body}*/) {
          const totalPages = Math.ceil(total / limit);
          // only retrieve updated list of substates when there are more elements to retrieve and it's not the last page
          if (total > 10 && rows.size < 10 && totalPages !== page) {
            dispatch(
              caseMgmtThunks.loadSubstates({
                limit,
                offset,
                serviceTeamEID: substate.get('service_team'),
              })
            );
          } else {
            dispatch(
              caseMgmtActions.removeSubstatesSuccess(substate, rowIndex)
            );
          }

          resolve();
        },
        token: true,
        url,
      });
    });
caseMgmtThunks.loadSubstatesBySelectedTeam = (serviceTeamEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('task.taskSubStatus');
    const filter = new EspFilters()
      .equalTo('active', 'True')
      .equalTo('service_team', serviceTeamEID);

    const query = {
      esp_filters: filter.asQueryString(),
      limit: 1000,
    };

    dispatch(caseMgmtActions.loadSubstatesStart());
    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.loadSubstatesFail());
        reject(err);
      },
      query,
      success({ body: { results = [], count = 0 } }) {
        dispatch(
          caseMgmtActions.loadSubstatesSuccess({
            count,
            dictionary: results.reduce((dictionary, item) => {
              dictionary[item.eid] = item;
              return dictionary;
            }, {}),
            list: results,
          })
        );
        resolve({
          count,
          results,
        });
      },
      token: true,
      url,
    });
  });
caseMgmtThunks.loadSubstates =
  ({ serviceTeamEID = '', limit = 10, offset, status, type }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath('task.taskSubStatus');
      const filter = new EspFilters().equalTo('service_team', serviceTeamEID);
      const taskStatuses = getState().getIn([
        'caseMgmt',
        'taskStatuses',
        'list',
      ]);
      const taskTypes = getState().getIn(['caseMgmt', 'taskTypes', 'list']);

      if (
        status &&
        taskStatuses &&
        !taskStatuses.isEmpty() &&
        taskTypes &&
        !taskTypes.isEmpty()
      ) {
        const taskStatus = taskStatuses.find(
          (task) => task.get('status') === status
        );
        const taskType = taskTypes.find((task) => task.get('type') === type);
        if (
          taskStatus &&
          taskStatus.has('eid') &&
          taskType &&
          !taskType.isEmpty()
        ) {
          filter
            .equalTo('active', 'True')
            .equalTo('parent', taskStatus.get('eid'))
            .equalTo('task_type', taskType.get('eid'));
        }
      }

      const query = {
        ...(isNumber(offset) && { offset }),
        esp_filters: filter.asQueryString(),
        limit: limit,
      };

      dispatch(caseMgmtActions.loadServiceTeamSubstatesStart());
      APIcall.get({
        error(err) {
          dispatch(caseMgmtActions.loadServiceTeamSubstatesFail());
          reject(err);
        },
        query,
        success({ body: { results = [], count = 0 } }) {
          dispatch(
            caseMgmtActions.loadServiceTeamSubstatesSuccess(results, count)
          );
          resolve({
            count,
            results,
          });
        },
        token: true,
        url,
      });
    });
caseMgmtThunks.loadServiceDepartmentById =
  (serviceDepartmentID = '') =>
  () =>
    new Promise((resolve, reject) => {
      APIcall.get({
        error(err) {
          reject(err);
        },
        success({ body }) {
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath(
          'espCaseMgmt.serviceDepartment.instance',
          {
            serviceDepartmentID,
          }
        ),
      });
    });

caseMgmtThunks.loadServiceDepartmentRouteList = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadServiceDepartmentRouteListStart());

    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.loadServiceDepartmentRouteListFail());
        reject(err);
      },
      success({ body }) {
        dispatch(
          caseMgmtActions.loadServiceDepartmentRouteListSuccess(body.results)
        );
        resolve(body.results);
      },
      token: true,
      url: endpointGenerator.genPath('espCaseMgmt.serviceDepartmentRoute'),
    });
  });
caseMgmtThunks.loadTeamClassificationList =
  ({ limit = 24, offset, orderBy }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(caseMgmtActions.loadServiceDepartmentStart());

      const query = {
        limit: limit,
        order_by: orderBy,
      };

      // Offset
      if (isNumber(offset)) {
        query.offset = offset;
      }

      APIcall.get({
        error(err) {
          dispatch(caseMgmtActions.loadServiceDepartmentFail());
          reject(err);
        },
        query,
        success({ body }) {
          dispatch(caseMgmtActions.loadTeamClassification(body, 'all'));
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('espCaseMgmt.serviceTeamClassification'),
      });
    });

caseMgmtThunks.loadServiceDepartmenList =
  ({ limit = 24, offset, orderBy }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(caseMgmtActions.loadServiceDepartmentStart());

      const query = {
        limit: limit,
        order_by: orderBy,
      };

      // Offset
      if (isNumber(offset)) {
        query.offset = offset;
      }

      APIcall.get({
        error(err) {
          dispatch(caseMgmtActions.loadServiceDepartmentFail());
          reject(err);
        },
        query,
        success({ body }) {
          dispatch(
            caseMgmtActions.loadServiceDepartmentSuccess(
              body.results,
              body.count
            )
          );
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('espCaseMgmt.serviceDepartment'),
      });
    });

caseMgmtThunks.loadEspServiceDepartmentList = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadServiceDepartmentStart());

    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.loadServiceDepartmentFail());
        reject(err);
      },
      success({ body }) {
        dispatch(
          caseMgmtActions.loadServiceDepartmentSuccess(body.results, body.count)
        );
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espCaseMgmt.espServiceDepartment'),
    });
  });

caseMgmtThunks.loadServiceDepartmentList =
  ({ limit = 24, offset, orderBy }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      dispatch(caseMgmtActions.loadServiceDepartmentStart());

      async.waterfall(
        [
          // 1 . Load the service department list
          (next) => {
            const query = {
              limit: limit,
              order_by: orderBy,
            };

            if (state.hasIn(['caseMgmt', 'serviceDepartment', 'searchTerm'])) {
              query.esp_filters = `display_name__IC=${state.getIn([
                'caseMgmt',
                'serviceDepartment',
                'searchTerm',
              ])}`;
            }

            // Offset
            if (isNumber(offset)) {
              query.offset = offset;
            }

            APIcall.get({
              error(err) {
                next(err);
              },
              query,
              success(res) {
                next(null, res.body.results, res.body.count);
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceDepartment'),
            });
          },

          // 2 . Load List of user who are the Technical Contacts of each Service Department
          (departmentList, count, next) => {
            const listID = departmentList
              .filter((item) => has(item, 'technical_contact.id'))
              .map((item) => item.technical_contact.id);

            dispatch(userEntitiesThunks.getListUsersByID(listID))
              .then(() => {
                next(null, departmentList, count);
              })
              .catch((error) => {
                next(error);
              });
          },
        ],
        (err, departmentList, count) => {
          if (err) {
            dispatch(caseMgmtActions.loadServiceDepartmentFail(err));
            reject(err);
          } else {
            dispatch(
              caseMgmtActions.loadServiceDepartmentSuccess(
                departmentList,
                count
              )
            );
            resolve();
          }
        }
      );
    });

caseMgmtThunks.loadOneServiceDepartment =
  (serviceDepartmentID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(caseMgmtActions.loadServiceDepartmentStart());

      const state = getState();

      async.waterfall(
        [
          // 1 Load the Department Detail
          (next) => {
            APIcall.get({
              error(err) {
                next(err);
              },
              success(res) {
                next(null, res.body);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceDepartment.instance',
                {
                  serviceDepartmentID,
                }
              ),
            });
          },

          // 2 load the Team Classifications for this Department
          (result, next) => {
            const departmentName = result.service_department;
            const teamClassificationExist = state.hasIn([
              'caseMgmt',
              'teamClassification',
              departmentName,
            ]);

            if (teamClassificationExist) {
              next(null, result);
            } else {
              const query = {
                esp_filters: `service_department__EQ=${departmentName}`,
              };
              APIcall.get({
                error(err) {
                  next(err);
                },
                query,
                success(res) {
                  dispatch(
                    caseMgmtActions.loadTeamClassification(
                      res.body,
                      departmentName
                    )
                  );
                  next(null, result);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espCaseMgmt.serviceTeamClassification'
                ),
              });
            }
          },
        ],
        (err, result) => {
          if (err) {
            dispatch(caseMgmtActions.loadServiceDepartmentFail(err));
            reject(err);
          } else {
            dispatch(caseMgmtActions.loadServiceDepartmentSuccess([result], 1));
            resolve();
          }
        }
      );
    });

caseMgmtThunks.getServiceDepartmentClassification = (eid) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadDepartmentClassificationStart());
    const query = {};
    if (eid) {
      query.esp_filters = `eid__EQ=${eid}`;
    }
    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.loadDepartmentClassificationError(err));
        reject(err);
      },
      query,
      success({ body }) {
        const { results } = body;
        dispatch(caseMgmtActions.loadDepartmentClassificationSuccess(results));
        if (eid && results.length) {
          resolve(results[0]);
        } else {
          resolve();
        }
      },
      token: true,
      url: endpointGenerator.genPath(
        'espCaseMgmt.serviceDepartmentClassification'
      ),
    });
  });

caseMgmtThunks.createNewDepartment = (value) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadServiceDepartmentStart());

    const data = value.toJS();
    const routeData = Object.assign(
      {},
      {
        route_to: data.route_to,
      }
    );

    async.waterfall(
      [
        // 1 . POST the new service department
        (next) => {
          APIcall.post({
            data,
            error(err) {
              next(err);
            },
            success(res) {
              next(null, res.body);
            },
            token: true,
            url: endpointGenerator.genPath('espCaseMgmt.serviceDepartment'),
          });
        },

        // 2 . Post Department Route
        (newDepartment, next) => {
          routeData.service_department = newDepartment.id;
          APIcall.post({
            data: routeData,
            error(err) {
              next(err);
            },
            success() {
              next(null, newDepartment);
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCaseMgmt.serviceDepartmentRoute'
            ),
          });
        },

        // 3 . Pass the technical contact
        (newDepartment, next) => {
          APIcall.post({
            data: {
              user: Number(data.technical_contact),
            },
            error(err) {
              next(err);
            },
            success(res) {
              next(null, res.body);
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCaseMgmt.serviceDepartment.instance.addUserToGroup',
              {
                serviceDepartmentID: newDepartment.id,
              }
            ),
          });
        },

        // 4 . Load the user from the response
        (newDepartment, next) => {
          dispatch(
            userEntitiesThunks.getListUsersByID([
              newDepartment.technical_contact.id,
            ])
          )
            .then(() => {
              const state = getState();
              const count = state.getIn([
                'caseMgmt',
                'serviceDepartment',
                'count',
              ]);
              next(null, newDepartment, count + 1);
            })
            .catch((error) => {
              next(error);
            });
        },
      ],
      (err, newDepartment, count) => {
        if (err) {
          dispatch(caseMgmtActions.loadServiceDepartmentFail(err));
          reject(err);
        } else {
          dispatch(
            caseMgmtActions.addServiceDepartmentSuccess(newDepartment, count)
          );
          resolve();
        }
      }
    );
  });

caseMgmtThunks.editDepartment = (value, IDS) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadServiceDepartmentStart());
    const data = value.toJS();
    const { serviceDepartmentID, serviceDepartmentRouteID } = IDS;

    async.waterfall(
      [
        // 1 . Update service department data
        (next) => {
          APIcall.patch({
            data,
            error(err) {
              next(err);
            },
            success({ body }) {
              next(null, body);
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCaseMgmt.serviceDepartment.instance',
              {
                serviceDepartmentID,
              }
            ),
          });
        },
        // 2 . Update department route
        (editedDepartment, next) => {
          if (serviceDepartmentRouteID) {
            APIcall.put({
              data: {
                route_to: data.route_to,
              },
              error(err) {
                next(err);
              },
              success() {
                next(null, editedDepartment);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceDepartmentRoute.instance',
                {
                  serviceDepartmentRouteID,
                }
              ),
            });
          } else {
            APIcall.post({
              data: {
                route_to: data.route_to,
                service_department: editedDepartment.id,
              },
              error(err) {
                next(err);
              },
              success() {
                next(null, editedDepartment);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceDepartmentRoute'
              ),
            });
          }
        },
        // 2 . Remove old technical contact
        (editedDepartment, next) => {
          if (editedDepartment.technical_contact) {
            APIcall.post({
              data: {
                user: editedDepartment.technical_contact.id,
              },
              error(err) {
                next(err);
              },
              success() {
                next(null, editedDepartment);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceDepartment.instance.removeUserFromGroup',
                {
                  serviceDepartmentID: editedDepartment.id,
                }
              ),
            });
          } else {
            next(null, editedDepartment);
          }
        },
        // 4 . Add new technical contact
        (editedDepartment, next) => {
          APIcall.post({
            data: {
              user: Number(data.technical_contact),
            },
            error(err) {
              next(err);
            },
            success({ body }) {
              next(null, body);
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCaseMgmt.serviceDepartment.instance.addUserToGroup',
              {
                serviceDepartmentID: editedDepartment.id,
              }
            ),
          });
        },
        // 5 . Load the user from the response
        (editedDepartment, next) => {
          dispatch(
            userEntitiesThunks.getListUsersByID([data.technical_contact])
          )
            .then(() => {
              next(null, editedDepartment);
            })
            .catch((error) => {
              next(error);
            });
        },
      ],
      (err, editedDepartment) => {
        if (err) {
          dispatch(caseMgmtActions.loadServiceDepartmentFail(err));
          reject(err);
        } else {
          dispatch(
            caseMgmtActions.editServiceDepartmentSuccess(
              editedDepartment,
              editedDepartment.id
            )
          );
          resolve();
        }
      }
    );
  });

caseMgmtThunks.deleteOneDepartment = (serviceDepartmentID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.startDeleteOnDepartment(serviceDepartmentID));
    APIcall.delete({
      error(err) {
        dispatch(caseMgmtActions.deleteOnDepartmentFail());
        reject(err);
      },
      success() {
        dispatch(
          caseMgmtActions.deleteOnDepartmentSuccess(serviceDepartmentID)
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espCaseMgmt.serviceDepartment.instance', {
        serviceDepartmentID,
      }),
    });
  });

caseMgmtThunks.createNewTeam =
  (data, serviceDepartmentID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!data || data.isEmpty() || !serviceDepartmentID) {
        reject('Error Data to create a new team');
      }

      dispatch(caseMgmtActions.createNewTeamStart());

      const state = getState();

      async.waterfall(
        [
          // 1 . Update the team
          (next) => {
            const dataObj = {
              classification: data.get('classification'),
              description: data.get('description'),
              name: data.get('team_name'),
              service_department: Number(serviceDepartmentID),
              team_lead: data.get('team_lead'),
            };

            APIcall.post({
              data: dataObj,
              error(err) {
                next(err);
              },
              success(res) {
                next(null, res.body);
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
            });
          },

          // 2 . Send the invitation to the Service Lead User
          (newTeam, next) => {
            if (!newTeam.team_lead || !newTeam.team_members[0]) {
              next(
                new Error(
                  'No Team Lead has been added during the team creation on BE'
                )
              );
            } else {
              APIcall.post({
                data: {
                  team_relationship_id: newTeam.team_members[0].id,
                },
                error(err) {
                  next(err);
                },
                success() {
                  next(null, newTeam);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espCaseMgmt.serviceTeam.instance.sendInvitation',
                  {
                    serviceTeamID: newTeam.id,
                  }
                ),
              });
            }
          },

          // 3 . Load the user entity of the new created team
          (newTeam, next) => {
            // Check if already have this entitie user or load it
            loadUserFromTeam(newTeam, dispatch, state)
              .then(() => {
                next(null, newTeam);
              })
              .catch((err) => {
                next(err);
              });
          },
        ],
        (err, newTeam) => {
          if (err) {
            dispatch(caseMgmtActions.createNewTeamFail());
            reject(err);
          } else {
            dispatch(caseMgmtActions.createNewTeamSuccess(newTeam));
            resolve(newTeam);
          }
        }
      );
    });

caseMgmtThunks.addMemberToTeam = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!data.get('user') || !data.get('team')) {
      reject('Error - User or Team has been pass to add a member');
    }
    dispatch(caseMgmtActions.addTeamMemberStart());

    async.waterfall(
      [
        // 1 If Member exist - update it OR Create a new one
        (next) => {
          const newMember = {
            auth_group: data.get('role'),
            faq_admin: data.get('faqAdmin', false),
            user: data.get('user'),
          };

          APIcall.post({
            data: newMember,
            error(err) {
              next(err);
            },
            success(res) {
              next(null, res.body);
            },
            token: true,
            url: endpointGenerator.genPath(
              'espCaseMgmt.serviceTeam.instance.createMembership',
              {
                serviceTeamID: data.get('team'),
              }
            ),
          });
        },

        // 2 . Send the invitation
        (team, next) => {
          const newRelation = team.team_members.filter(
            (relation) => relation.user === data.get('user')
          );
          if (newRelation.length === 0) {
            next(
              new Error('Error - No ream_members relation ID has been passed')
            );
          } else {
            APIcall.post({
              data: {
                team_relationship_id: newRelation[0].id,
              },
              error(err) {
                next(err);
              },
              success() {
                next(null, newRelation[0]);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceTeam.instance.sendInvitation',
                {
                  serviceTeamID: data.get('team'),
                }
              ),
            });
          }
        },
      ],
      (err, member) => {
        if (err) {
          reject(err);
        } else {
          dispatch(caseMgmtActions.addTeamMemberSuccess(member));
          resolve();
        }
      }
    );
  });

caseMgmtThunks.addNewMemberToTeam =
  (formName, teamID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!formName) {
        reject('Error form Name is missing');
      }

      const state = getState();
      const formData = state.hasIn(['form', 'FormMember_newMember', 'values'])
        ? state.getIn(['form', 'FormMember_newMember', 'values'])
        : null;

      if (!formData) {
        reject('error form data');
      }

      const userID = Number(formData.get('name'));
      const currentUserID = getCurrentUser(state).get('id');

      // Build Data
      const newMember = {
        faqAdmin: formData.get('faqAdmin', false),
        role: formData.get('role'),
        team: teamID,
        user: userID,
      };

      async.waterfall(
        [
          // 1 . Load the user entitie to avoid error
          (next) => {
            dispatch(userEntitiesThunks.getListUsersByID([userID]))
              .then(() => {
                next();
              })
              .catch((error) => {
                next(error);
              });
          },

          // 2 . Check if this new member already exist as a member
          (next) => {
            const member = getOneMember(state, userID, teamID);
            next(null, member);
          },

          // 3 . Create the user relationship
          (member, next) => {
            const dontrecheckMember = true;

            if (member) {
              // Error, this member already exist
              next(null, true);
            } else {
              dispatch(
                caseMgmtThunks.addMemberToTeam(
                  fromJS(newMember),
                  dontrecheckMember
                )
              )
                .then(() => {
                  // Clean empty member
                  dispatch(caseMgmtActions.cleanMembersList());
                  next(null, null);
                })
                .catch((err) => next(err));
            }
          },

          // 4. Update the user entity only if it's myself in order to update the USER group
          (alreadyExist, next) => {
            if (userID === currentUserID) {
              const forceRefresh = true;
              dispatch(userEntitiesThunks.addUserEntity(userID, forceRefresh))
                .then(() => next(null, alreadyExist))
                .catch((err) => next(err));
            } else {
              next(null, alreadyExist);
            }
          },
        ],
        (err, alreadyExist) => {
          if (err) {
            reject(err);
          } else {
            resolve({
              alreadyExist,
            });
          }
        }
      );
    });

caseMgmtThunks.deleteRelationMember =
  (relationshipID, teamID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!relationshipID) {
        reject('Error relationshipID is missing');
      }
      dispatch(caseMgmtActions.deleteMemberStart(relationshipID));

      // Search if this is the main LEAD
      const state = getState();
      const relation = state.getIn(['caseMgmt', 'teamRelationShip']);
      const currentRelation = relation.find(
        (r) => r.get('id') === relationshipID
      );

      const currentUserID = getCurrentUser(state).get('id');
      const userID = currentRelation && currentRelation.get('user');

      async.waterfall(
        [
          // 1 . Check if this the main LEAD of this team
          (next) => {
            APIcall.get({
              error(err) {
                next(err);
              },
              success(res) {
                const currentMainLead = res.body.team_lead;
                let newLead;
                if (currentMainLead === currentRelation.get('user')) {
                  // Get the next NEW main LEAD
                  newLead = relation.find(
                    (r) =>
                      r.get('id') !== relationshipID &&
                      r.get('auth_group') === TeamRoles.SERVICE_LEAD
                  );
                }
                next(null, newLead);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceTeam.instance',
                {
                  serviceTeamID: teamID,
                }
              ),
            });
          },

          // 2 . If a NEW LEAD need to be promote or Just DELETE the current USER
          (newLead, next) => {
            if (newLead && !newLead.isEmpty()) {
              // newLead must become the new Main Lead of the team
              // This endpoint will delete the current Main from the team_member too
              APIcall.post({
                data: {
                  team_lead: newLead.get('user'),
                },
                error(err) {
                  next(err);
                },
                success() {
                  next();
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espCaseMgmt.serviceTeam.instance.changeDirectLinkedTeamLead',
                  {
                    serviceTeamID: teamID,
                  }
                ),
              });
            } else {
              APIcall.post({
                data: {
                  team_relationship_id: relationshipID,
                },
                error(err) {
                  next(err);
                },
                success() {
                  next();
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espCaseMgmt.serviceTeam.instance.removeMembership',
                  {
                    serviceTeamID: teamID,
                  }
                ),
              });
            }
          },
          // 3. Update the user entity only if it's myself in order to update the USER group
          (next) => {
            if (userID === currentUserID) {
              const forceRefresh = true;
              dispatch(userEntitiesThunks.addUserEntity(userID, forceRefresh))
                .then(() => next())
                .catch((err) => next(err));
            } else {
              next();
            }
          },
        ],
        (err) => {
          if (err) {
            dispatch(caseMgmtActions.deleteMemberFail(err));
            reject(err);
          } else {
            dispatch(caseMgmtActions.deleteMemberSuccess(relationshipID));
            resolve();
          }
        }
      );
    });

caseMgmtThunks.loadTeamMembers = (teamID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!teamID) {
      reject('Error - team ID is missing');
    }

    dispatch(caseMgmtActions.loadTeamStart());

    async.waterfall(
      [
        // 1 . Load the member list
        (next) => {
          APIcall.get({
            error(err) {
              next(err);
            },
            success(res) {
              const memberList = res.body.team_members;
              dispatch(caseMgmtActions.LoadTeamsListSuccess([res.body], 1));
              next(null, memberList, memberList.length);
            },
            token: true,
            url: endpointGenerator.genPath('espCaseMgmt.serviceTeam.instance', {
              serviceTeamID: teamID,
            }),
          });
        },

        // 2 . Load Each user member
        (memberList, count, next) => {
          const listID = memberList.map((item) => item.user);
          dispatch(userEntitiesThunks.getListUsersByID(listID))
            .then(() => {
              next(null, memberList, count);
            })
            .catch((error) => {
              next(error);
            });
        },
      ],
      (err, memberList, count) => {
        if (err) {
          dispatch(caseMgmtActions.loadTeamFail(err));
          reject(err);
        } else {
          dispatch(caseMgmtActions.loadTeamSuccess(memberList, count));
          resolve(memberList);
        }
      }
    );
  });

caseMgmtThunks.addServiceTeamLocation = (teamID, location) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCaseMgmt.locationServiceTeam');
    APIcall.post({
      data: {
        label: location.get('name'),
        location: location.get('id'),
        service_team: teamID,
      },
      error(err) {
        reject(err);
      },
      success(res) {
        resolve(res.body.results);
      },
      token: true,
      url: url,
    });
  });

caseMgmtThunks.removeServiceTeamLocation = (locationTeamID) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath(
      'espCaseMgmt.locationServiceTeam.instance',
      {
        locationTeamID,
      }
    );
    APIcall.delete({
      error(err) {
        reject(err);
      },
      success() {
        resolve();
      },
      token: true,
      url: url,
    });
  });

caseMgmtThunks.getServiceTeamLocations = (teamID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.getServiceTeamLocationsStart());
    const url = endpointGenerator.genPath('espCaseMgmt.locationServiceTeam');
    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.getServiceTeamLocationsFail());
        reject(err);
      },
      query: {
        esp_filters: `service_team__EQ=${teamID}`,
      },
      success(res) {
        dispatch(
          caseMgmtActions.getServiceTeamLocationsSuccess(res.body.results)
        );
        resolve(res.body.results);
      },
      token: true,
      url: url,
    });
  });

caseMgmtThunks.updateOneTeam =
  (data, serviceTeamID, serviceDepartmentID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (
        !data ||
        data.isEmpty() ||
        !serviceDepartmentID ||
        !serviceDepartmentID
      ) {
        reject('Error Data to update an existing team');
      }

      dispatch(caseMgmtActions.updateTeamStart());

      const state = getState();

      async.waterfall(
        [
          // 1 . Update the team
          (next) => {
            const dataObj = {
              classification: data.get('classification'),
              description: data.get('description'),
              name: data.get('team_name'),
              suppress_notification: data.get('suppress_notification'),
            };

            APIcall.patch({
              data: dataObj,
              error(err) {
                next(err);
              },
              success(res) {
                next(null, res.body);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceTeam.instance',
                {
                  serviceTeamID,
                }
              ),
            });
          },

          // 2 . Check if the selected user already exist as SERVICE_AGENT
          (team, next) => {
            if (
              !team ||
              !team.team_members ||
              !team.team_members.length === 0
            ) {
              next(new Error('team_member is missing'));
            } else {
              const newLead = data.get('team_lead');
              const searchUser = team.team_members.filter(
                (m) =>
                  m.user === Number(newLead) &&
                  m.auth_group === TeamRoles.SERVICE_AGENT
              );
              if (searchUser && searchUser.length === 1) {
                // This user already exist as an AGENT
                // We have to remove this member before to set him as LEAD to avoid duplicate user
                APIcall.post({
                  data: {
                    team_relationship_id: searchUser[0].id,
                  },
                  error(err) {
                    next(err);
                  },
                  success() {
                    next();
                  },
                  token: true,
                  url: endpointGenerator.genPath(
                    'espCaseMgmt.serviceTeam.instance.removeMembership',
                    {
                      serviceTeamID,
                    }
                  ),
                });
              } else {
                next(null);
              }
            }
          },

          // 2 . Update/Set the team Lead
          (next) => {
            const dataObj = {
              team_lead: data.get('team_lead'),
            };

            APIcall.post({
              data: dataObj,
              error(err) {
                next(err);
              },
              success() {
                next(null);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceTeam.instance.changeDirectLinkedTeamLead',
                {
                  serviceTeamID,
                }
              ),
            });
          },

          // 3. Get the updated team data
          (next) => {
            APIcall.get({
              error(err) {
                next(err);
              },
              success(res) {
                next(null, res.body);
              },
              token: true,
              url: endpointGenerator.genPath(
                'espCaseMgmt.serviceTeam.instance',
                {
                  serviceTeamID,
                }
              ),
            });
          },

          // 4 . Send the invitation to the Service Lead User
          (newTeam, next) => {
            if (!newTeam.team_lead || !newTeam.team_members[0]) {
              next(
                new Error(
                  'No Team Lead has been added during the team update on BE'
                )
              );
            } else {
              APIcall.post({
                data: {
                  team_relationship_id: newTeam.team_members[0].id,
                },
                error(err) {
                  next(err);
                },
                success() {
                  next(null, newTeam);
                },
                token: true,
                url: endpointGenerator.genPath(
                  'espCaseMgmt.serviceTeam.instance.sendInvitation',
                  {
                    serviceTeamID,
                  }
                ),
              });
            }
          },

          // 5 . Load the user entity of the new created team
          (newTeam, next) => {
            // Check if already have this user or load it
            loadUserFromTeam(newTeam, dispatch, state)
              .then(() => {
                next(null, newTeam);
              })
              .catch((err) => {
                next(err);
              });
          },
        ],
        (err, newTeam) => {
          if (err) {
            dispatch(caseMgmtActions.updateTeamFail());
            reject(err);
          } else {
            dispatch(caseMgmtActions.updateTeamSuccess(newTeam));
            resolve();
          }
        }
      );
    });

caseMgmtThunks.deleteOneTeam = (serviceTeamID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!serviceTeamID) {
      reject('Error Team ID to delete an existing team');
    }

    dispatch(caseMgmtActions.deleteTeamStart(serviceTeamID));

    APIcall.delete({
      error(err) {
        dispatch(caseMgmtActions.deleteTeamFail());
        reject(err);
      },
      success() {
        dispatch(caseMgmtActions.deleteTeamSuccess(serviceTeamID));
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espCaseMgmt.serviceTeam.instance', {
        serviceTeamID,
      }),
    });
  });

caseMgmtThunks.LoadTeamByDepartment =
  ({ limit = 24, offset, orderBy, serviceDepartmentID }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!serviceDepartmentID) {
        reject('Error serviceDepartmentID is missing');
      }

      dispatch(caseMgmtActions.LoadDepartmentTeamListStart());

      async.waterfall(
        [
          // 1 . Get the service department Team List
          (next) => {
            const query = {};
            // Offset
            if (isNumber(offset)) {
              query.offset = offset;
            }

            // Limit
            query.limit = limit;

            // Order by
            query.order_by = orderBy;

            // Service department to filter
            query.esp_filters = `service_department__EQ=${serviceDepartmentID}`;

            APIcall.get({
              error(err) {
                next(err);
              },
              query,
              success(res) {
                next(null, res.body.results, res.body.count);
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
            });
          },

          // 2 . Load the user from the team list
          (teamList, count, next) => {
            const listUsers = teamList.map((team) => team.team_lead); // Build array of user's ids
            dispatch(userEntitiesThunks.getListUsersByID(listUsers))
              .then(() => {
                next(null, teamList, count);
              })
              .catch((error) => {
                next(error);
              });
          },
        ],
        (err, teamList, count) => {
          if (err) {
            dispatch(caseMgmtActions.LoadDepartmentTeamListFail());
            reject(err);
          } else {
            dispatch(
              caseMgmtActions.LoadDepartmentTeamListSuccess(teamList, count)
            );
            resolve();
          }
        }
      );
    });

caseMgmtThunks.LoadTeamsList =
  ({ orderBy = 'name', limit = 24, offset }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(caseMgmtActions.LoadTeamsListStart());

      async.waterfall(
        [
          // 1 . Get the service department Team List
          (next) => {
            const query = {
              order_by: orderBy,
            };

            // Offset
            if (isNumber(offset)) {
              query.offset = offset;
            }

            // Limit
            query.limit = limit;

            APIcall.get({
              error(err) {
                next(err);
              },
              query,
              success(res) {
                next(null, res.body.results, res.body.count);
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
            });
          },

          // 2 . Load the user from the team list
          (teamList, count, next) => {
            const listUsers = teamList.map((team) => team.team_lead); // Build array of user's ids
            dispatch(userEntitiesThunks.getListUsersByID(listUsers))
              .then(() => {
                next(null, teamList, count);
              })
              .catch((error) => {
                next(error);
              });
          },
        ],
        (err, teamList, count) => {
          if (err) {
            dispatch(caseMgmtActions.LoadTeamsListFail());
            reject(err);
          } else {
            dispatch(caseMgmtActions.LoadTeamsListSuccess(teamList, count));
            resolve();
          }
        }
      );
    });

caseMgmtThunks.searchTeamTerm =
  ({ limit = 24, offset, orderBy, serviceDepartmentID }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!serviceDepartmentID) {
        reject('Error serviceDepartmentID is missing');
      }

      dispatch(caseMgmtActions.setIsSearchingTeamTerm());

      dispatch(caseMgmtActions.LoadDepartmentTeamListStart());

      const state = getState();
      const searchTerm = state.hasIn([
        'caseMgmt',
        'serviceDepartmentTeam',
        'searchTerm',
      ])
        ? state.getIn(['caseMgmt', 'serviceDepartmentTeam', 'searchTerm'])
        : '';

      const query = {};
      // Offset
      if (isNumber(offset)) {
        query.offset = offset;
      }

      // Limit
      query.limit = limit;

      // Order by
      query.order_by = orderBy;

      // Esp filter
      query.esp_filters = `name__IC=${searchTerm}`;

      async.waterfall(
        [
          // 1 . Get the service department Team List
          (next) => {
            const query = {};
            // Offset
            if (isNumber(offset)) {
              query.offset = offset;
            }

            // Limit
            query.limit = limit;

            // Order by
            query.order_by = orderBy;

            // Service department to filter
            query.esp_filters = encodeURI(
              `service_department__EQ=${serviceDepartmentID}&name__IC=${searchTerm}`
            );

            APIcall.get({
              error(err) {
                next(err);
              },
              query,
              success(res) {
                next(null, res.body.results, res.body.count);
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
            });
          },

          // 2 . Load the user from the team list
          (teamList, count, next) => {
            const listUsers = teamList.map((team) => team.team_lead); // Build array of user's ids
            dispatch(userEntitiesThunks.getListUsersByID(listUsers))
              .then(() => {
                next(null, teamList, count);
              })
              .catch((error) => {
                next(error);
              });
          },
        ],
        (err, teamList, count) => {
          if (err) {
            dispatch(caseMgmtActions.LoadDepartmentTeamListFail());
            reject(err);
          } else {
            dispatch(
              caseMgmtActions.LoadDepartmentTeamListSuccess(teamList, count)
            );
            resolve();
          }
        }
      );
    });

caseMgmtThunks.loadTaskClassification =
  (departmentID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const departmentSelected = state
        .getIn(['caseMgmt', 'serviceDepartment', 'list'])
        .find((dep) => dep.get('id') === departmentID);

      const departmentName = departmentSelected
        ? departmentSelected.get('service_department')
        : null;
      if (!departmentSelected) {
        reject(new Error('No department Selected'));
        return;
      }

      APIcall.get({
        error(err) {
          reject(err);
        },
        query: encodeURI(
          `limit=350&esp_filters=service_department__EQ=${departmentName}`
        ),
        success({ body: { results = [] } }) {
          resolve(results);
          dispatch(caseMgmtActions.loadTaskClassifications(results));
        },

        // TODO Optimize that later
        token: true,

        url: endpointGenerator.genPath('task.taskClassification'),
      });
    });

caseMgmtThunks.loadTaskStatuses = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadTaskStatusesStart());
    const url = endpointGenerator.genPath('task.taskStatus');
    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.loadTaskStatusesFail());
        reject(err);
      },
      success({ body: { results = [] } }) {
        dispatch(caseMgmtActions.loadTaskStatusesSuccess(results));
        resolve(results);
      },
      token: true,
      url,
    });
  });

caseMgmtThunks.loadTaskTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.loadTaskTypesStart());
    const url = endpointGenerator.genPath('task.taskTypes');
    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.loadTaskTypesFail());
        reject(err);
      },
      success({ body: { results = [] } }) {
        dispatch(caseMgmtActions.loadTaskTypesSuccess(results));
        resolve(results);
      },
      token: true,
      url,
    });
  });

caseMgmtThunks.loadAllTeams =
  ({ limit }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const query = {};

      if (limit) {
        query.limit = limit;
      }
      APIcall.get({
        error(err) {
          reject(err);
        },
        query,
        success({ body }) {
          dispatch(caseMgmtActions.loadTeamClassification(body, 'all'));
        },
        token: true,
        url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
      });
    });

caseMgmtThunks.loadServiceTeamByDepartment =
  (serviceDepartmentID, searchName, defaultValueEid) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!serviceDepartmentID) {
        reject(new Error('No Service Department is selected'));
        return;
      }

      dispatch(caseMgmtActions.LoadDepartmentTeamListStart());

      // Service department to filter
      let filter = new EspFilters().equalTo(
        'service_department',
        serviceDepartmentID
      );
      if (searchName) {
        filter = filter.contains('name', searchName);
      }

      async.waterfall(
        [
          (next) => {
            if (defaultValueEid) {
              APIcall.get({
                error(err) {
                  next(err);
                },
                query: {
                  esp_filters: `eid__EQ=${defaultValueEid}`,
                  limit: 100,
                },
                success({ body: { results } }) {
                  const [existingResult] = results;
                  next(null, existingResult);
                },
                token: true,
                url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
              });
            } else {
              next(null, null); // continue without loading additional
            }
          },
          (existingResult, next) => {
            APIcall.get({
              error(err) {
                next(err);
              },
              query: {
                esp_filters: filter.asQueryString(),
                limit: 100,
              },
              success({ body: { results = [], count = 0 } }) {
                if (existingResult) {
                  // Look up that the existing result is NOT in the current results
                  const existing = find(
                    results,
                    (r) => r.id === existingResult.id
                  );
                  if (!existing) {
                    results.push(existingResult);
                  }
                }

                next(null, {
                  count,
                  results, // no need to affect this guy, as it's used for pagination
                });
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
            });
          },
        ],
        (err, res) => {
          if (err || !res) {
            dispatch(caseMgmtActions.LoadDepartmentTeamListFail(err));
            reject(err);
          } else {
            const { results, count } = res;

            dispatch(
              caseMgmtActions.LoadDepartmentTeamListSuccess(results, count)
            );
            resolve(results);
          }
        }
      );

      // APIcall.get({
      //   url   : endpointGenerator.genPath('espCaseMgmt.serviceTeam'),
      //   token : true,
      //   query : {
      //     esp_filters: filter.asQueryString(),
      //   },
      //   success({body}) {
      //     dispatch(caseMgmtActions.LoadDepartmentTeamListSuccess(body.results, body.count));
      //     resolve();
      //   },
      //   error(err) {
      //     dispatch(caseMgmtActions.LoadDepartmentTeamListFail(err));
      //     reject(err);
      //   },
      // });
    });

caseMgmtThunks.loadServiceDepartments =
  (searchName, defaultValueEid) => (dispatch) =>
    new Promise((resolve, reject) => {
      // Service department to filter
      let filter;
      if (searchName) {
        filter = new EspFilters().contains('name', searchName);
      }

      dispatch(caseMgmtActions.loadServiceDepartmentStart());
      async.waterfall(
        [
          (next) => {
            if (defaultValueEid) {
              APIcall.get({
                error(err) {
                  next(err);
                },
                query: {
                  esp_filters: `eid__EQ=${defaultValueEid}`,
                },
                success({ body: { results } }) {
                  const [existingResult] = results;
                  next(null, existingResult);
                },
                token: true,
                url: endpointGenerator.genPath('espCaseMgmt.serviceDepartment'),
              });
            } else {
              next(null, null); // continue without loading additional
            }
          },
          (existingResult, next) => {
            APIcall.get({
              error(err) {
                next(err);
              },
              query: {
                esp_filters: filter && filter.asQueryString(),
              },
              success({ body: { results = [], count = 0 } }) {
                if (existingResult) {
                  // Look up that the existing result is NOT in the current results
                  const existing = find(
                    results,
                    (r) => r.id === existingResult.id
                  );
                  if (!existing) {
                    results.push(existingResult);
                  }
                }

                next(null, {
                  count,
                  results, // no need to affect this guy, as it's used for pagination
                });
              },
              token: true,
              url: endpointGenerator.genPath('espCaseMgmt.serviceDepartment'),
            });
          },
        ],
        (err, res) => {
          if (err) {
            dispatch(caseMgmtActions.loadServiceDepartmentFail());
            reject(err);
          } else {
            const { results, count } = res;
            dispatch(
              caseMgmtActions.loadServiceDepartmentSuccess(results, count)
            );
            resolve(results);
          }
        }
      );
    });

caseMgmtThunks.createTaskCategory = (serviceDepartmentID, data) => () =>
  new Promise((resolve, reject) => {
    let hackedData = data;
    hackedData = hackedData.set('service_department', serviceDepartmentID);
    hackedData = hackedData.set('name', data.get('display_name'));
    hackedData = hackedData.delete('display_name');

    APIcall.post({
      data: hackedData.toJS(),
      error(err) {
        reject(err);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('task.categories'),
    });
  });

caseMgmtThunks.loadTaskCategories =
  ({
    limit = 24,
    offset,
    orderBy,
    departmentID,
    searchTerm,
    defaultValueEid,
  }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(caseMgmtActions.loadTaskCategoriesStart());

      const query = {};
      // Offset
      if (isNumber(offset)) {
        query.offset = offset;
      }

      // Limit
      query.limit = limit;

      // Order by
      query.order_by = orderBy;

      // Esp Filter
      const espFilters = new EspFilters();

      if (departmentID) {
        espFilters.equalTo('service_department', departmentID);
      }

      if (searchTerm) {
        espFilters.contains('name', searchTerm);
      }

      if (espFilters.getQuerySize()) {
        query.esp_filters = espFilters.asQueryString();
      }

      async.waterfall(
        [
          (next) => {
            if (defaultValueEid) {
              APIcall.get({
                error(err) {
                  next(err);
                },
                query: {
                  esp_filters: `eid__EQ=${defaultValueEid}`,
                },
                success({ body: { results } }) {
                  const [existingResult] = results;
                  next(null, existingResult);
                },
                token: true,
                url: endpointGenerator.genPath('task.categories'),
              });
            } else {
              next(null, null); // continue without loading additional
            }
          },
          (existingResult, next) => {
            APIcall.get({
              error(err) {
                next(err);
              },
              query,
              success({ body: { results = [], count = 0 } }) {
                if (existingResult) {
                  // Look up that the existing result is NOT in the current results
                  const existing = find(
                    results,
                    (r) => r.id === existingResult.id
                  );
                  if (!existing) {
                    results.push(existingResult);
                  }
                }

                next(null, {
                  count,
                  results, // no need to affect this guy, as it's used for pagination
                });
              },
              token: true,
              url: endpointGenerator.genPath('task.categories'),
            });
          },
        ],
        (err, { results, count }) => {
          if (err) {
            dispatch(caseMgmtActions.loadTaskCategoriesFail());
            reject(err);
          } else {
            dispatch(caseMgmtActions.loadTaskCategoriesSuccess(results, count));
            resolve(results);
          }
        }
      );
    });

caseMgmtThunks.deleteTaskCategories = (categoryID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.deleteDepartmentCategoryStart(categoryID));
    APIcall.delete({
      error(err) {
        dispatch(caseMgmtActions.deleteOnDepartmentCategoryFail());
        reject(err);
      },
      success({ body }) {
        dispatch(caseMgmtActions.deleteDepartmentCategorySuccess(categoryID));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('task.categories.instance', {
        categoryID,
      }),
    });
  });

caseMgmtThunks.loadCurrentCategory = (categoryID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (!categoryID) {
      return reject();
    }
    const categories =
      getState().getIn(['caseMgmt', 'serviceDepartmentCategories', 'list']) ||
      fromJS([]);
    const currentCategory =
      categories.find((c) => c.get('id') === categoryID) || fromJS({});
    if (currentCategory.size === 0) {
      return reject();
    }
    const teams = currentCategory.get('teams') || fromJS([]);
    const form = `ServiceDepartmentCategoryCreate-${categoryID}`;
    const parseTemplate = (currentCategory, teams) => ({
      classification: currentCategory.get('classification'),
      display_name: currentCategory.get('name'),
      keywords: currentCategory.get('keywords'),
      resolve_sla: currentCategory.get('resolve_sla'),
      response_sla: currentCategory.get('response_sla'),
      teams: teams.toJS(),
    });
    dispatch(initialize(form, parseTemplate(currentCategory, teams)));
    return resolve();
  });

caseMgmtThunks.updateTaskCategory =
  (categoryID, serviceDepartmentID, data) => () =>
    new Promise((resolve, reject) => {
      let hackedData = data;
      hackedData = hackedData.set('service_department', serviceDepartmentID);
      hackedData = hackedData.set('name', data.get('display_name'));
      hackedData = hackedData.delete('display_name');

      APIcall.patch({
        data: hackedData.toJS(),
        error(err) {
          reject(err);
        },
        success({ body }) {
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('task.categories.instance', {
          categoryID,
        }),
      });
    });

caseMgmtThunks.loadServiceTeam = (serviceTeamID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.LoadTeamsListStart());
    APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.LoadTeamsListFail(err));
        reject(err);
      },
      success({ body }) {
        dispatch(caseMgmtActions.LoadTeamsListSuccess([body], 1));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espCaseMgmt.serviceTeam.instance', {
        serviceTeamID,
      }),
    });
  });

caseMgmtThunks.saveServiceTeam = (serviceTeamID, formValues) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(caseMgmtActions.LoadTeamsListStart());
    APIcall.patch({
      data: formValues.toJS(),
      error(err) {
        dispatch(caseMgmtActions.LoadTeamsListFail(err));
        reject(err);
      },
      success({ body }) {
        dispatch(caseMgmtActions.LoadTeamsListSuccess([body], 1));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espCaseMgmt.serviceTeam.instance', {
        serviceTeamID,
      }),
    });
  });

caseMgmtThunks.addTeamImage =
  (data, cb = noop) =>
  () =>
    new Promise((resolve, reject) => {
      APIcall.post({
        data,
        error(err) {
          reject(err);
        },
        success() {
          cb();
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath('espCaseMgmt.serviceTeamImage'),
      });
    });

caseMgmtThunks.getDefaultServiceTeam = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const endpoint = endpointGenerator.genPath(
      'espCaseMgmt.serviceTeam.defaultTeam'
    );

    return APIcall.get({
      error(err) {
        dispatch(caseMgmtActions.defaultServiceTeamFailure(err.message));
        reject();
      },
      success(res) {
        dispatch(caseMgmtActions.setDefaultServiceTeam(res.body));
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

caseMgmtThunks.setConfigDefaultServiceTeam = (eid) => (dispatch) => {
  const endpoint = endpointGenerator.genPath('espConfig.defaultServiceTeam');

  dispatch(caseMgmtActions.setConfigDefaultServiceTeamStart());
  return APIcall.patch({
    data: {
      // Passing config value
      value: eid,
    },
    error(err) {
      dispatch(caseMgmtActions.setConfigDefaultServiceTeamError(err.message));
    },
    success() {
      dispatch(caseMgmtActions.setConfigDefaultServiceTeamSuccess());
    },
    token: true,
    url: endpoint,
  });
};

caseMgmtThunks.getMyServiceTeams =
  ({ internal = false, external = false } = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const endpoint = endpointGenerator.genPath(
        'espCaseMgmt.serviceTeam.myTeams'
      );

      const query = {};

      // if none of these parameters are specified, the API will return both
      if (internal) {
        query.internal = true;
      }
      if (external) {
        query.external = true;
      }

      query.limit = 500;

      return APIcall.get({
        error(err) {
          dispatch(caseMgmtActions.setMyTeamsFailure(err.message));
          reject();
        },
        query,
        success({ body }) {
          const teams = body.results;
          dispatch(caseMgmtActions.setMyTeams(teams));
          resolve(teams);
        },
        token: true,
        url: endpoint,
      });
    });

caseMgmtThunks.MyTeams = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      caseMgmtThunks.getMyServiceTeams({
        internal: true,
      })
    )
      .then(() => resolve())
      .catch((e) => reject(e));
  });

// This is just a proxy to do two things under the same promise
caseMgmtThunks.getDefaultAndMyTeams = () => (dispatch) =>
  new Promise((resolve, reject) => {
    async.parallel(
      [
        // 1 - Call getDefaultServiceTeam
        (next) => {
          dispatch(caseMgmtThunks.getDefaultServiceTeam())
            .then(() => next(null))
            .catch((e) => next(e));
        },

        // 2 - Call getMyServiceTeams
        (next) => {
          dispatch(
            caseMgmtThunks.getMyServiceTeams({
              internal: true,
            })
          )
            .then(() => next(null))
            .catch((e) => next(e));
        },
      ],
      (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }
    );
  });

caseMgmtThunks.loadAreFeedsRead = () => (dispatch) => {
  const endpoint = endpointGenerator.genPath(
    'espCaseMgmt.serviceTeam.areFeedsRead'
  );

  return APIcall.get({
    success(res) {
      const areFeedsRead = res.body;
      dispatch(caseMgmtActions.setAreFeedsRead(areFeedsRead));
    },
    token: true,
    url: endpoint,
  });
};

caseMgmtThunks.setFeedRead = (serviceTeamID) => () =>
  new Promise((resolve, reject) => {
    if (serviceTeamID) {
      const endpoint = endpointGenerator.genPath(
        'espCaseMgmt.serviceTeam.instance.setFeedRead',
        {
          serviceTeamID,
        }
      );

      APIcall.post({
        data: {},
        error(e) {
          reject(e);
        },
        success() {
          resolve();
          // well we don't have to do anything since the update will come via websocket event
        },
        token: true,
        url: endpoint,
      });
    } else {
      reject(new Error('serviceTeamId must be provided'));
    }
  });

caseMgmtThunks.importMembersFromServiceNow = (serviceTeamID, sysId) => () =>
  new Promise((resolve, reject) => {
    if (!serviceTeamID) {
      reject('Error Team ID to import an existing team');
    }

    APIcall.post({
      data: {
        team_sys_id: sysId,
      },
      error(err) {
        reject(err);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath(
        'espCaseMgmt.serviceTeam.instance.importMembersFromServiceNow',
        {
          serviceTeamID,
        }
      ),
    });
  });

caseMgmtThunks.faqLoadDeptJobLocNames =
  (locDeptJobToLoad = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const loadedNames = {
        departments: {},
        job_roles: {},
        locations: {},
      };
      async.parallel(
        [
          // 1 . Load Each location Name
          (next) => {
            if (locDeptJobToLoad.locations.length) {
              async.eachOf(
                locDeptJobToLoad.locations,
                (name, key, done) => {
                  dispatch(locationThunks.getByCode(name))
                    .then((result) => {
                      const name = !isEmpty(result) && result[0].name;
                      const code = !isEmpty(result) && result[0].code;
                      const id = !isEmpty(result) && result[0].id;
                      loadedNames.locations[name] = name;
                      loadedNames.locations[code] = {
                        code,
                        id,
                        name,
                      };
                      loadedNames.locations[id] = id;
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },
          // 2 . Load Each Department Name
          (next) => {
            if (locDeptJobToLoad.departments.length) {
              async.eachOf(
                locDeptJobToLoad.departments,
                (id, key, done) => {
                  dispatch(departmentsThunks.getByID(id))
                    .then((result) => {
                      const [{ name }] = result;
                      loadedNames.departments[id] = name;
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },
          // 3 . Load Each Job Role Name
          (next) => {
            if (locDeptJobToLoad.job_roles.length) {
              async.eachOf(
                locDeptJobToLoad.job_roles,
                (id, key, done) => {
                  dispatch(jobRoleThunks.getJobRoles(void 0, id))
                    .then((result) => {
                      const [{ name }] = result;
                      loadedNames.job_roles[id] = name;
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },
        ],
        (err) => {
          if (err) {
            reject(err);
          } else {
            // Example result:
            // const loadedNames = {
            //   departments: {
            //     '15704': 'Sales',
            //   },
            //   job_roles: {
            //     '2': 'Architect',
            //   },
            //   locations: {
            //     '34455': 'global',
            //   },
            // };

            resolve(loadedNames);
          }
        }
      );
    });

caseMgmtThunks.loadDeptJobLocNames =
  (locDeptJobToLoad = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const loadedNames = {
        departments: {},
        job_roles: {},
        locations: {},
      };
      async.parallel(
        [
          // 1 . Load Each location Name
          (next) => {
            if (locDeptJobToLoad.locations.length) {
              async.eachOf(
                locDeptJobToLoad.locations,
                (id, key, done) => {
                  dispatch(locationThunks.getByID(id))
                    .then((result) => {
                      const name = !isEmpty(result) && result[0].name;
                      loadedNames.locations[id] = name;
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },
          // 2 . Load Each Department Name
          (next) => {
            if (locDeptJobToLoad.departments.length) {
              async.eachOf(
                locDeptJobToLoad.departments,
                (id, key, done) => {
                  dispatch(departmentsThunks.getByID(id))
                    .then((result) => {
                      const [{ name }] = result;
                      loadedNames.departments[id] = name;
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },
          // 3 . Load Each Job Role Name
          (next) => {
            if (locDeptJobToLoad.job_roles.length) {
              async.eachOf(
                locDeptJobToLoad.job_roles,
                (id, key, done) => {
                  dispatch(jobRoleThunks.getJobRoles(void 0, id))
                    .then((result) => {
                      const [{ name }] = result;
                      loadedNames.job_roles[id] = name;
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },
        ],
        (err) => {
          if (err) {
            reject(err);
          } else {
            // Example result:
            // const loadedNames = {
            //   departments: {
            //     '15704': 'Sales',
            //   },
            //   job_roles: {
            //     '2': 'Architect',
            //   },
            //   locations: {
            //     '34455': 'global',
            //   },
            // };

            resolve(loadedNames);
          }
        }
      );
    });

export default caseMgmtThunks;
