import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesTrainingClasses extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M77.59 52.64c1.607 0 2.91-1.303 2.91-2.91V18.41c0-1.607-1.303-2.91-2.91-2.91H27.37c-.97 0-1.874.483-2.41 1.29 2.75.09 5.236 1.668 6.484 4.122 1.25 2.453 1.063 5.39-.484 7.668 1.67.52 2.46 1.46 3.41 3.69l.36.62 2.88 2.25 5.23-3.39h.06c.603-.488 1.337-.787 2.11-.86h.25c1.566.02 2.938 1.052 3.395 2.55.457 1.498-.107 3.12-1.395 4.01l-8.4 6.84-.16.11-1 .75-1.2-.65-3.3-2c0 .76 0 1.23-.07 1.55v8.62l44.46-.03z' />
          <path d='M37.66 42.74l8.46-6.89c.625-.398.91-1.163.7-1.873-.212-.71-.87-1.195-1.61-1.187-.434.05-.843.23-1.17.52l-.11.07-6.43 4.12-4.26-3.34-.61-1c-1-2.33-1.4-2.64-3.53-3l-2.89-.38c2.71-.764 4.443-3.41 4.057-6.2-.386-2.79-2.77-4.87-5.587-4.87-2.816 0-5.2 2.08-5.587 4.87-.386 2.79 1.346 5.436 4.057 6.2h-3.38c-2.352.005-4.26 1.908-4.27 4.26v14.79c-.007.113-.007.227 0 .34 0 .745.296 1.46.823 1.987.527.527 1.242.823 1.987.823h.25V72.1c-.01.72.267 1.412.77 1.924.504.513 1.19.803 1.91.806 1.418-.006 2.596-1.096 2.71-2.51V57.38c-.005-.4.13-.79.38-1.1h.81c.42 0 .48 1 .49 1v15.11c0 1.48 1.2 2.68 2.68 2.68 1.48 0 2.68-1.2 2.68-2.68v-.36l.09-28.06s.08-1.8.12-2.84l.2-2 6 3.71.26-.1zm-3.03 34.13v-.27c0-.09 0 0 0 0v.27zm45.87-.98c-.27-2.84-3.86-5.1-8.31-5.13 2.852-.038 5.14-2.372 5.12-5.225-.02-2.852-2.337-5.155-5.19-5.155s-5.17 2.303-5.19 5.155c-.02 2.853 2.268 5.187 5.12 5.225-2.643-.097-5.22.844-7.18 2.62-1.73-1.613-3.995-2.53-6.36-2.58h-.73c2.844-.006 5.146-2.316 5.14-5.16-.006-2.844-2.316-5.146-5.16-5.14h-.14c-2.752.085-4.95 2.324-4.98 5.077-.033 2.754 2.11 5.044 4.86 5.193h-.7c-2.39.05-4.678.966-6.44 2.58-1.925-1.775-4.474-2.717-7.09-2.62 2.852-.038 5.14-2.372 5.12-5.225-.02-2.852-2.337-5.155-5.19-5.155s-5.17 2.303-5.19 5.155c-.02 2.853 2.268 5.187 5.12 5.225-4.46 0-8.15 2.28-8.41 5.13-.062.228-.09.464-.08.7.003.845.342 1.654.942 2.25.6.594 1.413.925 2.258.92H77.5c1.79-.107 3.175-1.608 3.14-3.4l-.14-.44z' />
        </g>
      </svg>
    );
  }
}

export default CatalogServicesTrainingClasses;
