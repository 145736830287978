import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';

const rule = (props) => {
  const { isReversed, open, theme } = props;

  const arrowBase = {
    backgroundColor: '#4a4a4a',
    content: '" "',
    display: 'block',
    height: '2px',
    marginTop: '-1px',
    position: 'absolute',
    top: '50%',
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'transform',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: '50%',
    zIndex: 1,
  };

  const isOpen = isReversed ? !open : open;

  return {
    ':after': {
      ...arrowBase,
      right: '42%',
      transform: isOpen ? 'rotate(-45deg)' : 'rotate(45deg)',
    },
    ':before': {
      ...arrowBase,
      left: '42%',
      transform: isOpen ? 'rotate(45deg)' : 'rotate(-45deg)',
    },
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    height: '100%',
    outline: 'none',
    padding: 0,
    position: 'relative',
    width: '100%',
  };
};

class ButtonChevronToggle extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    // open       : PropTypes.bool,
    // isReversed : PropTypes.bool, // Button can reverse the arrow direction display logic
  };

  static defaultProps = {
    // isReversed : false,
    onClick: void 0,
    // open       : void 0,
  };

  handleClick = (e) => {
    const { onClick } = this.props;
    e.preventDefault();
    onClick();
  };

  render() {
    const { className } = this.props;
    return (
      <button
        className={className}
        data-component='ButtonChevronToggle'
        data-testid='chat-form-select-toggle'
        onClick={this.handleClick}
        type='button'
      />
    );
  }
}

export default createComponent(rule, ButtonChevronToggle, [
  'isReversed',
  'onClick',
  'open',
]);
