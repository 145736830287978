import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputValue: {
    arrayShape: [
      {
        comment: 'Work Location',
        map: {
          required: false,
          type: 'string',
        },
        value: {
          required: false,
          type: 'string',
        },
      },
      {
        comment: 'Remote Worker',
        map: {
          required: false,
          type: 'string',
        },
        value: {
          required: false,
          type: 'string',
        },
      },
    ],
    default: [],
    type: 'arrayOf',
  },
  readOnly: {
    default: false,
    type: 'bool',
  },

  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'workAddress01',
  },
});

// Block definition used by blobifier
const WorkAddressDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Work Address',
  templates: ['workAddress01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, WorkAddressDefinition, TypeDefaults };
