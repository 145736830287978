import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Transition } from 'semantic-ui-react';

import UserImg from '../../functional/UserImg';

const rule = (props) => {
  const { theme } = props;

  return {
    '> *': {
      display: 'block',
      height: theme.minHeight,
      width: theme.minHeight,
    },
    flexShrink: 0,
    height: theme.minHeight,
    lineHeight: theme.globals.lineHeight,
    width: theme.minHeight,
  };
};

class ChatMessageAvatar extends PureComponent {
  static propTypes = {
    avatarUrl: PropTypes.string,
    className: PropTypes.string.isRequired,
    empty: PropTypes.bool,
    isBarista: PropTypes.bool,
    userId: PropTypes.number,
  };

  static defaultProps = {
    avatarUrl: void 0,
    empty: false,
    isBarista: false,
    userId: void 0,
  };

  render() {
    const { avatarUrl, className, empty, isBarista, userId } = this.props;

    return (
      <Transition animation='scale' duration={1000} transitionOnMount>
        <div
          className={className}
          data-component='ChatMessageAvatar'
          tabIndex='-1'
        >
          {empty ? null : (
            <UserImg
              isBarista={isBarista}
              isProfileLink
              userId={userId}
              userImgUrl={avatarUrl}
            />
          )}
        </div>
      </Transition>
    );
  }
}

export default createComponent(rule, ChatMessageAvatar, [
  'isBarista',
  'avatarUrl',
  'empty',
  'userId',
]);
