import { escapeRegExp } from 'lodash';
const punct = ['.', ',', ':', ';', ' '].map((p) => p.charCodeAt(0));

/**
 * Removes trailing punctuation from the specified text.
 *
 * @param {*} text the text to be processed
 */
const removeTrailingPunctuation = (text) => {
  if (text.length && punct.includes(text.charCodeAt(text.length - 1))) {
    text = text.slice(0, text.length - 1);
  }

  return text;
};

/**
 * Adds leading and trailing ellipses to a text, based on the provided term.
 *
 * @param {String} text the piece of text in which the term might be found
 * @param {String} term the mid-point word or phrase
 * @param {Number} threshold number of characters to be included as prefix/suffix
 */
const asEllipsis = (text, term, threshold) => {
  const anchor = text.search(escapeRegExp(term));

  if (anchor === -1) {
    return text;
  }

  let prefix =
    anchor === 0
      ? ''
      : text
          .slice(0, anchor - 1)
          .trim()
          .split(' ')
          .reverse()
          .reduce((phrase, word) => {
            if (phrase.length < threshold) {
              phrase = `${word} ${phrase}`;
            }

            return phrase;
          }, '');

  let suffix = text
    .slice(anchor + term.length)
    .trim()
    .split(' ')
    .reduce((phrase, word) => {
      if (phrase.length < threshold) {
        phrase = `${phrase}${
          punct.includes(word.charCodeAt(0)) ? '' : ' '
        }${word}`;
      }

      return phrase;
    }, '');

  prefix = removeTrailingPunctuation(prefix);
  suffix = removeTrailingPunctuation(suffix);

  return `${prefix.length ? `...${prefix} ` : ''}${term}${
    suffix.length ? `${suffix}...` : ''
  }`;
};

export default asEllipsis;
