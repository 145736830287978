import { createSelector } from 'reselect';
import getSelectedBundle from './getSelectedBundle';
import getAllBundles from './getAllBundles';

const getChildBundles = (selectedBundle, bundles) => {
  if (selectedBundle && bundles) {
    return selectedBundle
      .get('child_bundles')
      .map((id) => bundles.find((p) => p.get('id') === id));
  }
  return null;
};

const selector = createSelector(
  [getSelectedBundle, getAllBundles],
  getChildBundles
);

export default selector;
