import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const EMPTY_SELECTIONS = {
  entityEid: '',
  entityWordEid: '',

  isRequestingKeywordsList: false,
  // aka "archetype"
  isRequestingTemplate: false,
  templateEid: '',
};
const EMPTY_SEARCH_QUERIES = {
  FaqKeywordQuery: '',
};
const INITIAL_STATE = fromJS({
  archetypeConditionEid: '',
  entityIsLoading: false,
  entityResults: [],
  entityWordsIsLoading: false,
  entityWordsResults: [],
  exampleResults: [],
  examplesAreLoading: false,
  faqEid: '',
  isLoading: false,
  isOpen: false,
  searchQueries: EMPTY_SEARCH_QUERIES,
  selections: EMPTY_SELECTIONS,
  templateExamplesResult: [],
  templatesIsLoading: false,
  templatesResults: [],
  wizardData: {},
  wordSynonymsIsLoading: false,
  wordSynonymsResults: [],
});

/**
 * Admin user reducer
 * @param state
 * @param action
 * @returns {*}
 */
const matchingWizardReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.FAQ_WIZ_RESET: {
      return INITIAL_STATE;
    }
    case actionTypes.FAQ_WIZ_OPEN: {
      return state
        .setIn(['isOpen'], true)
        .setIn(
          ['selections', 'entityEid'],
          state.getIn(['wizardData', 'entity', 'eid'], '')
        )
        .setIn(
          ['selections', 'entityWordEid'],
          state.getIn(['wizardData', 'entity_value', 'eid'], '')
        )
        .setIn(
          ['selections', 'templateEid'],
          state.getIn(['wizardData', 'archetype', 'eid'], '')
        )
        .setIn(['faqEid'], action.faqEid);
    }

    case actionTypes.FAQ_WIZ_CLOSE: {
      const newState = state
        .setIn(['selections'], fromJS(EMPTY_SELECTIONS))
        .setIn(['isOpen'], false)
        .setIn(['faqEid'], '');

      return newState;
    }

    case actionTypes.FAQ_WIZ_RESET_SELECTIONS: {
      const newState = state.setIn(['selections'], fromJS(EMPTY_SELECTIONS));

      return newState;
    }

    case actionTypes.FAQ_WIZ_RESET_SELECTED_WORD: {
      // first only save entity
      const entityEid = state.getIn(['selections', 'entityEid']);
      const newState = state
        .setIn(['selections'], fromJS(EMPTY_SELECTIONS))
        .setIn(['selections', 'entityEid'], entityEid);

      return newState;
    }

    case actionTypes.FAQ_WIZ_GET_ENTITY_START:
      return state.setIn(['entityIsLoading'], true);

    case actionTypes.FAQ_WIZ_GET_ENTITY_SUCCESS:
      return state
        .setIn(['entityIsLoading'], false)
        .setIn(['entityResults'], fromJS(action.results));

    case actionTypes.FAQ_WIZ_GET_ENTITY_FAIL:
      return state.setIn(['entityIsLoading'], false);

    case actionTypes.FAQ_WIZ_SELECT_ENTITY: {
      if (!action.entityEid) {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Warning no entity eid is being selected');
      }

      let newState = state;

      newState = newState.setIn(['selections'], fromJS(EMPTY_SELECTIONS));

      newState = newState.setIn(['selections', 'entityEid'], action.entityEid);

      return newState;
    }

    case actionTypes.FAQ_WIZ_SELECT_ENTITY_WORD: {
      if (!action.entityWordEid) {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Warning no entity value eid is being selected');
      }

      const newState = state
        .setIn(['selections', 'entityWordEid'], action.entityWordEid)
        .setIn(['selections', 'templateEid'], '')
        .setIn(['selections', 'isRequestingKeywordsList'], false);

      return newState;
    }

    case actionTypes.FAQ_WIZ_SELECT_TEMPLATE: {
      if (!action.templateEid) {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Warning no template eid is being selected');
      }
      return state.setIn(['selections', 'templateEid'], action.templateEid);
    }

    case actionTypes.FAQ_WIZ_SELECT_REQ_TEM: {
      return state.setIn(['selections', 'isRequestingTemplate'], true);
    }

    case actionTypes.FAQ_WIZ_DESELECT_REQ_TEM: {
      return state.setIn(['selections', 'isRequestingTemplate'], false);
    }

    case actionTypes.FAQ_WIZ_SELECT_REQ_KEYWORD_LIST: {
      return state
        .setIn(['selections', 'isRequestingKeywordsList'], true)
        .setIn(['selections', 'entityWordEid'], '');
    }

    case actionTypes.FAQ_WIZ_DESELECT_REQ_KEYWORD_LIST: {
      return state.setIn(['selections', 'isRequestingKeywordsList'], false);
    }

    case actionTypes.FAQ_WIZ_GET_ENTITY_WORDS_START:
      return state
        .setIn(['entityWordsIsLoading'], true)
        .setIn(['entityWordsResults'], fromJS([]));

    case actionTypes.FAQ_WIZ_GET_ENTITY_WORDS_SUCCESS:
      return state
        .setIn(['entityWordsIsLoading'], false)
        .setIn(['entityWordsResults'], fromJS(action.results));

    case actionTypes.FAQ_WIZ_ADD_NEW_WORD_SUCCESS: {
      const existingResults = state
        .get('entityWordsResults')
        .push(fromJS(action.instance));

      return state.setIn(['entityWordsResults'], existingResults);
    }

    case actionTypes.FAQ_WIZ_EDIT_WORD_SUCCESS: {
      const existingResults = state.get('entityWordsResults');

      const index = existingResults.findIndex(
        (result) => result.get('eid') === action.eid
      );

      if (index > -1) {
        return state.setIn(
          ['entityWordsResults', index],
          fromJS(action.instance)
        );
      }
      return state;
    }

    case actionTypes.FAQ_WIZ_REMOVE_WORD_SUCCESS: {
      const existingResults = state.get('entityWordsResults');

      const index = existingResults.findIndex(
        (result) => result.get('eid') === action.eid
      );
      if (index > -1) {
        return state.removeIn(['entityWordsResults', index]);
      }
      return state;
    }

    case actionTypes.FAQ_WIZ_GET_DATA_START:
      return state
        .setIn(['isLoading'], true)
        .setIn(['wizardData'], fromJS({}))
        .setIn(['archetypeConditionEid'], '');

    case actionTypes.FAQ_WIZ_GET_DATA_SUCCESS: {
      const wizardData = action.results.length > 0 ? action.results.pop() : {};
      let newState = state;
      if (wizardData.intent_id) {
        newState = newState
          .setIn(['wizardData'], fromJS(wizardData))
          .setIn(['archetypeConditionEid'], wizardData.archetype_condition_eid);
      }
      return newState.setIn(['isLoading'], false);
    }
    case actionTypes.FAQ_WIZ_GET_ENTITY_WORDS_FAIL:
      return state.setIn(['entityWordsIsLoading'], false);

    case actionTypes.FAQ_WIZ_GET_WORD_SYNON_START:
      return state
        .setIn(['wordSynonymsIsLoading'], true)
        .setIn(['wordSynonymsResults'], fromJS([]));

    case actionTypes.FAQ_WIZ_GET_WORD_SYNON_SUCCESS:
      return state
        .setIn(['wordSynonymsIsLoading'], false)
        .setIn(['wordSynonymsResults'], fromJS(action.results));

    case actionTypes.FAQ_WIZ_ADD_NEW_SYNON_SUCCESS: {
      const existingResults = state
        .get('wordSynonymsResults')
        .push(fromJS(action.instance));

      return state.setIn(['wordSynonymsResults'], existingResults);
    }

    case actionTypes.FAQ_WIZ_EDIT_SYNON_SUCCESS: {
      const existingResults = state.get('wordSynonymsResults');

      const index = existingResults.findIndex(
        (result) => result.get('eid') === action.eid
      );

      if (index > -1) {
        return state.setIn(
          ['wordSynonymsResults', index],
          fromJS(action.instance)
        );
      }
      return state;
    }

    case actionTypes.FAQ_WIZ_REMOVE_SYNON_SUCCESS: {
      const existingResults = state.get('wordSynonymsResults');

      const index = existingResults.findIndex(
        (result) => result.get('eid') === action.eid
      );
      if (index > -1) {
        return state.removeIn(['wordSynonymsResults', index]);
      }
      return state;
    }

    case actionTypes.FAQ_WIZ_GET_WORD_SYNON_FAIL:
      return state.setIn(['wordSynonymsIsLoading'], false);

    case actionTypes.FAQ_WIZ_GET_TEMPLATES_START:
      return state
        .setIn(['templatesIsLoading'], true)
        .setIn(['templatesResults'], fromJS([]));
    case actionTypes.FAQ_WIZ_GET_TEMPLATES_SUCCESS:
      return state
        .setIn(['templatesIsLoading'], false)
        .setIn(['templatesResults'], fromJS(action.results));

    case actionTypes.FAQ_WIZ_GET_TEMPLATES_FAIL:
      return state.setIn(['templatesIsLoading'], false);
    case actionTypes.FAQ_WIZ_GET_TEMPLATE_EXAMPLES_START:
      return state
        .setIn(['examplesAreLoading'], true)
        .setIn(['exampleResults'], fromJS([]));
    case actionTypes.FAQ_WIZ_GET_TEMPLATE_EXAMPLES_SUCCESS:
      return state
        .setIn(['examplesAreLoading'], false)
        .setIn(['exampleResults'], fromJS(action.results));
    case actionTypes.FAQ_WIZ_GET_TEMPLATE_EXAMPLES_FAIL:
      return state.setIn(['examplesAreLoading'], false);

    case actionTypes.SAVE_SEARCH_QUERY:
      return state.setIn(['searchQueries', action.node], action.query);
    case actionTypes.RESET_SEARCH_QUERY:
      return state.setIn(['searchQueries', action.node], '');
    default: {
      return state;
    }
  }
};

export { INITIAL_STATE };
export default matchingWizardReducer;
