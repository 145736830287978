import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

const blockAttrsDefinition = _.merge({}, BlockBase, {
  template: {
    default: 'login01',
  },
});

const LoginBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Login',
  templates: ['login01'],
};

const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

export { propTypes as default, LoginBlockDefinition, TypeDefaults };
