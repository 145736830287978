import React, { useCallback, useEffect } from 'react';
import pt from 'prop-types';
import { intl } from 'esp-util-intl';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import {
  Dimmer,
  Dropdown,
  Loader,
  Menu,
  Search,
  Segment,
} from 'semantic-ui-react';
import { debounce } from 'lodash';

// Molecule
import OffboardingPerformanceTableList from './OffboardingPerformanceTableList';
import MenuPagination from '../../../molecules/MenuPagination';

// utils
import browserHistory from '../../../../utils/browserHistory';
import uiPathGenerator from '../../../../utils/uiPathGenerator';
// globals
import SortPerformanceStateOptions, {
  SortPerformanceFieldNames,
} from '../../../../globals/SortPerformanceStateOptions';
import ShowPerformanceGroupOptions from '../../../../globals/ShowPerformanceGroupOptions';
import groupPermissions from '../../../../globals/groupPermissions';
import usePerformanceList from './hooks/usePerformanceList';
import DepartingEmployeeStatus from '../../../../globals/DepartingEmployeeStatus';

const propTypes = {
  escalateProgress: pt.func,

  isSearching: pt.bool,
  isUserIDEscalating: pt.bool,

  loadingDeleteID: pt.number,

  loadingEscalateID: pt.number,
  location: pt.shape({
    query: pt.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      orderBy: pt.any,
      page: pt.oneOfType([pt.string, pt.number]),
      searchTerm: pt.string,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      show: pt.any,
    }),
  }).isRequired,
  params: pt.shape({
    userID: pt.oneOfType([pt.string, pt.number]),
  }),
};

const SEARCH_DELAY = 300;

const OffboardingPerformanceList = ({
  escalateProgress,
  isUserIDEscalating,

  isSearching,
  loadingDeleteID,
  loadingEscalateID,
  location,
}) => {
  const {
    archivePerformance,
    isLoading,
    limitByPage,
    listCount,
    loadPerformanceList,
    performanceList,
  } = usePerformanceList();

  const updateUrl = useCallback(
    (page, searchTerm, show, orderBy) => {
      browserHistory.push({
        pathname: uiPathGenerator.genPath(
          'admin.adminActivities.offboarding.performance'
        ),
        search: `?${queryString.stringify(
          Object.assign({}, location.query, {
            orderBy: orderBy,
            page: page,
            searchTerm: searchTerm,
            show: show,
          })
        )}`,
      });
    },
    [location.query]
  );

  useEffect(() => {
    const { page, searchTerm, show, orderBy } = location.query;
    loadPerformanceList(page, searchTerm, show, orderBy);
  }, [location.query, loadPerformanceList]);

  const handleStartSearch = debounce((e, { value }) => {
    const { show, orderBy } = location.query;
    updateUrl(1, value, show, orderBy);
  }, SEARCH_DELAY);

  const handleFilterSortChange = useCallback(
    (e, { value }) => {
      const { searchTerm, show } = location.query;
      const orderBy = value === '' ? null : value;
      updateUrl(1, searchTerm, show, orderBy);
    },
    [location.query, updateUrl]
  );

  const handleFilterShowChange = useCallback(
    (e, { value }) => {
      const { searchTerm, orderBy } = location.query;
      const show = value === '' ? null : value;
      updateUrl(1, searchTerm, show, orderBy);
    },
    [location.query, updateUrl]
  );

  const SortPerformanceOption = SortPerformanceStateOptions(intl);
  let showPerformanceGroupOptions = ShowPerformanceGroupOptions(
    intl,
    groupPermissions
  );

  showPerformanceGroupOptions = showPerformanceGroupOptions.map((item) => {
    if (item.value === 'NEW_HIRE') {
      // replace value
      item.text = 'Departing Employee';
      item.value = DepartingEmployeeStatus;
    }
    return item;
  });

  return (
    <Segment attached className='esp-admin-onboarding-activities' clearing>
      <Helmet
        title={intl.formatMessage({
          id: 'label.admin_title_onboarding_activities_performance',
        })}
      />
      <Menu borderless secondary>
        <Menu.Item fitted>
          <Search
            loading={isSearching}
            onSearchChange={handleStartSearch}
            placeholder='Search...'
            showNoResults={false}
          />
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item fitted>
            <label>{'Show: '}</label>
            <Dropdown
              onChange={handleFilterShowChange}
              options={showPerformanceGroupOptions}
              selectOnBlur={false}
              value={location.query.show || DepartingEmployeeStatus}
            />
          </Menu.Item>
          <Menu.Item fitted>
            <label>{'Sort by: '}</label>
            <Dropdown
              onChange={handleFilterSortChange}
              options={SortPerformanceOption}
              selectOnBlur={false}
              value={
                location.query.orderBy ||
                SortPerformanceFieldNames.NEGATIVE_SYS_DATE_UPDATED
              }
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {isLoading && (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )}
      <OffboardingPerformanceTableList
        archiveOnePerformance={archivePerformance}
        escalateProgress={escalateProgress}
        isLoading={isLoading}
        isUserIDEscalating={isUserIDEscalating}
        loadingDeleteID={loadingDeleteID}
        loadingEscalateID={loadingEscalateID}
        query={location.query}
        tableList={performanceList}
      />
      <MenuPagination
        currentPage={Number(location.query.page) || 1}
        numberPages={Math.ceil(listCount / limitByPage) || 1}
        pageByQuery
        pathname={'admin.adminActivities.offboardingActivities.performance'}
        query={location.query}
      />
    </Segment>
  );
};

OffboardingPerformanceList.propTypes = propTypes;

export default OffboardingPerformanceList;
