/**
 * Tool to build business object
 * and format message from API during CSV updload
 * @type {{}}
 */

const IntegratioCSVTools = {};

IntegratioCSVTools.buildOption = (businessList) => {
  const optionTypes = [];
  businessList.forEach((ob) => {
    optionTypes.push({
      key: ob.get('id'),
      text: ob.get('name'),
      value: ob.get('id'),
    });
  });
  return optionTypes;
};

IntegratioCSVTools.formatCSVResponse = (msg, messageList = []) => {
  const msgList = messageList;

  if (msg.type === 'error') {
    const newMsg = {
      content: msg.results,
      header: 'Error',
      key: `${msgList.length - 1}error`,
      type: 'error',
    };

    msgList.push(newMsg);
  } else {
    const { status } = msg.results;

    switch (status) {
      case 200: {
        const newMsg = {
          content: msg.results.body.message || msg.results.body.result,
          header: 'Success',
          key: `success${msgList.length - 1}`,
          type: 'success',
        };

        msgList.push(newMsg);
        break;
      }

      case 201: {
        const newMsg = {
          content: 'File uploaded',
          header: 'Success',
          key: msg.results.body.eid,
          type: 'success',
        };

        msgList.push(newMsg);
        break;
      }

      case 207: {
        const results = msg.results.body.errors;

        results.forEach((result, i) => {
          const newMsg = {
            content: result.message,
            header: result.reason,
            key: 207 + result.reason + i,
            type: 'error',
          };

          msgList.push(newMsg);
        });

        break;
      }

      default: {
        const newMsg = {
          content: JSON.stringify(msg.results.body),
          header: 'Success',
          key: `success${msgList.length - 1}`,
          type: 'success',
        };

        msgList.push(newMsg);
        break;
      }
    }
  }

  return msgList;
};

export default IntegratioCSVTools;
