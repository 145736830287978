import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';
import EspFilters from 'esp-util-filters';

const getAuthSAMLIDP = async (metadata_url) => {
  const url = endpointGenerator.genPath('authentication.samlIdp');

  const espFilters = new EspFilters();
  espFilters.equalTo('metadata_url', metadata_url);

  return _APIcall.get({
    query: {
      esp_filters: espFilters.asQueryString(),
      limit: 1,
    },
    token: true,
    url: url,
  });
};

export default getAuthSAMLIDP;
