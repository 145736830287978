import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsOnboarding extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M74.78 46.35l-7.25-8.56c-.284-.335-.7-.53-1.14-.53-.434.002-.846.19-1.13.52l-7.37 8.5c-.357.405-.47.97-.3 1.483.174.512.607.893 1.136 1 .53.107 1.077-.078 1.434-.483l4.85-5.59v34.09c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5V42.97l4.44 5.31c.347.41.885.602 1.413.507.527-.096.964-.466 1.145-.97.18-.505.08-1.068-.268-1.477l.04.01zM51.45 17c-.828 0-1.5.672-1.5 1.5v8.64h-26V18.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v58.28c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-8.64h26v8.64c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5V18.5c0-.828-.672-1.5-1.5-1.5zM50 30.14v16H24v-16h26zm-26 35v-16h26v16H24z' />
        </g>
      </svg>
    );
  }
}

export default CardsOnboarding;
