const intlShapes = {
  isCompoundMessage(args) {
    return (
      this.isRegularMessage(args) &&
      Object.prototype.hasOwnProperty.call(args, 'values') &&
      typeof args.values === 'object'
    );
  },
  isPluralMessage(args) {
    return (
      this.isRegularMessage(args) &&
      Object.prototype.hasOwnProperty.call(args, 'value') &&
      typeof args.value === 'number'
    );
  },
  isRegularMessage(args) {
    return (
      args &&
      typeof args === 'object' &&
      Object.prototype.hasOwnProperty.call(args, 'id') &&
      typeof args.id === 'string'
    );
  },
};

export default intlShapes;
