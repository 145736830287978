import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'previewProfile01',
  },
});

// Object Definition used by blobifier
const PreviewProfileBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Preview Profile',
  templates: ['previewProfile01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, PreviewProfileBlockDefinition, TypeDefaults };
