import React, { PureComponent } from 'react';
import { Field } from 'formik';
import { Checkbox as SUIRCheckbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class Checkbox extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  render() {
    const { name, label, ...props } = this.props;

    return (
      <Field
        {...props}
        component={SUIRCheckbox}
        label={label}
        name={name}
        type='checkbox'
      />
    );
  }
}

export default Checkbox;
