// Blocks attributes definitions
import { BackgroundBlockDefinition } from './blocks/TypeBackground';
import { ButtonBlockDefinition } from './blocks/TypeButton';
import { CardBlockDefinition } from './blocks/TypeCard';
import { FieldBlockDefinition } from './blocks/TypeField';
import { CheckboxBlockDefinition } from './blocks/TypeCheckbox';
import { HeroBlockDefinition } from './blocks/TypeHero';
import { LogoBlockDefinition } from './blocks/TypeLogo';
import { LoginBlockDefinition } from './blocks/TypeLogin';
import { RadioBlockDefinition } from './blocks/TypeRadio';
import { RadioBlockDefinition03 } from './blocks/TypeRadio03';
import { SkillsBlockDefinition } from './blocks/TypeSkills';
import { SpacerBlockDefinition } from './blocks/TypeSpacer';
import { TextBlockDefinition } from './blocks/TypeText';
import { TextAreaBlockDefinition } from './blocks/TypeTextArea';
import { ConversationBlockDefinition } from './blocks/TypeConversation';
import { BaristaBlockDefinition } from './blocks/TypeBarista';
import { ImageSelectBlockDefinition } from './blocks/TypeImageSelect';
import { SystemAvatarBlockDefinition } from './blocks/TypeSystemAvatar';
import { PreviewProfileBlockDefinition } from './blocks/TypePreviewProfile';
import { ProductConfigurationBlockDefinition } from './blocks/TypeProductConfiguration';
import { CartBlockDefinition } from './blocks/TypeCart';
import { BundleSelectDefinition } from './blocks/TypeBundleSelect';
import { SoftwareSelectDefinition } from './blocks/TypeSoftwareSelect';
import { UserSelectDefinition } from './blocks/TypeUserSelect';
import { EditCommentBlockDefinition } from './blocks/TypeEditComment';
import { RoleSelectBlockDefinition } from './blocks/TypeRoleSelect';
import { ResetPasswordMethodDefinition } from './blocks/TypeResetPasswordMethod';
import { ResetPasswordCodeDefinition } from './blocks/TypeResetPasswordCode';
import { BeginChatBlockDefinition } from './blocks/TypeBeginChat';
import { WorkAddressDefinition } from './blocks/TypeWorkAddress';
import { EquipmentCheckBlockDefinition } from './blocks/TypeEquipmentCheck';
import { SoftwareUtilizationBlockDefinition } from './blocks/TypeSoftwareUtilization';
import { ChangePasswordDefinition } from './blocks/TypeChangePassword';
import { PhoneBlockDefinition } from './blocks/TypePhone';
import { AddEntriesBlockDefinition } from './blocks/TypeAddEntries';
import { locationDeptRolePickerBlockDefinition } from './blocks/TypeLocationDeptRolePicker';
import { SocialMediaBlockDefinition } from './blocks/TypeSocialMedia';
import { SelectMyGearDefinition } from './blocks/TypeSelectMyGear';
import { exitWorkflowBlockDefinition } from './blocks/TypeExitWorkflow';
import { createNewUserBlockDefinition } from './blocks/TypeCreateNewUser';
import { selectUserContactGroupDefinition } from './blocks/TypeSelectUserContactGroup';
import { birthdayDefinition } from './blocks/TypeBirthday';
import { SelectDepartmentDefinition } from './blocks/TypeSelectDepartment';
import { imageBlockDefinition } from './blocks/TypeImage';
import { AddResponsesBlockDefinition } from './blocks/TypeAddResponses';
import { SendDownloadAppSMSDefinition } from './blocks/TypeSendDownloadAppSMS';
import { SelectServiceTeamBlockDefinition } from './blocks/TypeSelectServiceTeam';
import { SelectDepartmentCategoryBlockDefinition } from './blocks/TypeSelectDepartmentCategory';
import { createNewFaqBlockDefinition } from './blocks/TypeCreateNewFaq';
import { buildEditFaqBlockDefinition } from './blocks/TypeBuildEditFaq';
import { SelectLanguage } from './blocks/TypeSelectLanguage';
import { searchSelectFAQDefinition } from './blocks/TypeSearchSelectFAQ';
import { AddResponseTextBlockDefinition } from './blocks/TypeAddResponseText';
import { teachBaristaBlockDefinition } from './blocks/TypeCreateTeachBarista';
import { EditFaqTeachBlockDefinition } from './blocks/TypeEditFaqBarista';
import { buildExistingEmployeeBlockDefinition } from './blocks/TypeBuildExistingEmployee';
import { skipTaskDefinition } from './blocks/TypeSkipTask';
import { SelectMobileOSDefinition } from './blocks/TypeSelectMobileOS';
import { searchFAQArchetypeDefinition } from './blocks/TypeSearchFAQArchetype';
import { searchArchetypeEntityDefinition } from './blocks/TypeSearchArchetypeEntity';
import { updateEntityValueInstanceDefinition } from './blocks/TypeUpdateEntityValueInstance';
import { addEntityValueDefinition } from './blocks/TypeAddEntityValue';
import { addEntitySynonymValueDefinition } from './blocks/TypeAddEntitySynonymValue';
import { selectFAQByArchetypeAndEntityDefinition } from './blocks/TypeSelectFAQByArchetype';
import { sendReportBlockDefinition } from './blocks/TypeSendReport';
import { setFAQKBBlockDefinition } from './blocks/TypeSetFaqKBvalue';

const BlockTypes = {
  BLOCK_ADD_ENTITY_SYNONYM_VALUE: 'addEntitySynonymValue',
  BLOCK_ADD_ENTITY_VALUE: 'addEntityValue',
  BLOCK_ADD_ENTRIES: 'addEntries',
  BLOCK_ADD_RESPONSES: 'addResponses',
  BLOCK_ADD_RESPONSE_FAQ: 'addResponseText',
  BLOCK_BACKGROUND: 'background',
  BLOCK_BARISTA: 'barista',
  BLOCK_BEGIN_CHAT: 'beginchat',
  BLOCK_BIRTHDAY: 'birthday',
  BLOCK_BUILD_EDIT_FAQ: 'buildEditFaq',
  BLOCK_BUILD_EXISTING_EMPLOYEE: 'buildExistingEmployee',
  BLOCK_BUNDLESELECT: 'bundleSelect',
  BLOCK_BUTTON: 'button',
  BLOCK_CARD: 'card',
  BLOCK_CART: 'cart',
  BLOCK_CHANGE_PASSWORD: 'changePassword',
  BLOCK_CHECKBOX: 'checkBox',
  BLOCK_CONVERSATION: 'conversation',
  BLOCK_CREATE_NEW_FAQ: 'createNewFaq',
  BLOCK_CREATE_NEW_USER: 'createNewUser',
  BLOCK_EDITCOMMENT: 'editcomment',
  BLOCK_EDIT_TEACH_BARISTA: 'editTeachBarista',
  BLOCK_EQUIPMENT_CHECK: 'equipmentcheck',
  BLOCK_EXIT_WORKFLOW: 'exitworkflow',
  BLOCK_FIELD: 'field',
  BLOCK_HERO: 'hero',
  BLOCK_IMAGE: 'image',
  BLOCK_IMAGE_SELECT: 'imageSelect',
  BLOCK_LOADING: 'loading',
  BLOCK_LOCATION_DEPT_ROLE_PICKER: 'TypeLocationDeptRolePicker',
  BLOCK_LOGIN: 'login',
  BLOCK_LOGO: 'logo',
  BLOCK_PHONE: 'phoneNumber',
  BLOCK_PREVIEWPROFILE: 'previewProfile',
  BLOCK_PRODUCTCONFIGURATION: 'productConfiguration',
  BLOCK_RADIO: 'radio',
  BLOCK_RADIO03: 'radio03',
  BLOCK_RESET_PASSWORD_CODE: 'ResetPasswordCodeDefinition',
  BLOCK_RESET_PASSWORD_METHOD: 'resetPasswordMethod',
  BLOCK_ROLE_SELECT: 'roleSelect',
  BLOCK_SEARCH_ARCHETYPE_ENTITY: 'searchArchetypeEntity',
  BLOCK_SEARCH_FAQ: 'searchSelectFAQ',
  BLOCK_SEARCH_FAQ_ARCHETYPE: 'searchFaqArchetype',
  BLOCK_SELECT_DEPARTMENT: 'selectDepartment',
  BLOCK_SELECT_DEPARTMENT_CAT: 'selectDepartmentCategory',
  BLOCK_SELECT_FAQ_ARCHETYPE_ENTITY: 'selectFaqByArchetype',
  BLOCK_SELECT_LANGUAGE: 'SelectLanguage',
  BLOCK_SELECT_MY_GEAR: 'selectMyGear',
  BLOCK_SELECT_SERVICE_TEAM: 'selectServiceTeam',
  BLOCK_SELECT_TYPE_OS: 'selectTypeOS',
  BLOCK_SELECT_USER_CONTACT_GROUP: 'selectUserContactGroup',
  BLOCK_SEND_DOWNLOAD_APP_SMS: 'sendDownloadAppSMS',
  BLOCK_SEND_REPORT: 'sendReport',
  BLOCK_SET_FAQ_KB_VALUE: 'SetValueFromKBSnowInFAQ',
  BLOCK_SKILLS: 'skills',
  BLOCK_SKIP_TASK: 'skipTask',
  BLOCK_SOCIAL_MEDIA: 'socialMedia',
  BLOCK_SOFTWARE_SELECT: 'softwareSelect',
  BLOCK_SOFTWARE_UTILIZATION: 'softwareUtilization',
  BLOCK_SPACER: 'spacer',
  BLOCK_SYSTEMAVATAR: 'systemAvatar',
  BLOCK_TEACH_BARISTA: 'teachBarista',
  BLOCK_TEXT: 'text',
  BLOCK_TEXTAREA: 'textarea',
  BLOCK_UPDATE_ENTITY_VALUE_INSTANCE: 'updateEntityValueInstance',
  BLOCK_USERSELECT: 'userSelect',
  BLOCK_WORK_ADDRESS: 'workAddress',
};

// ES6 Map
const BlockDefinitionMap = new Map();
BlockDefinitionMap.set(BlockTypes.BLOCK_BACKGROUND, BackgroundBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_BUTTON, ButtonBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_CARD, CardBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_FIELD, FieldBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_CHECKBOX, CheckboxBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_HERO, HeroBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_LOGO, LogoBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_LOGIN, LoginBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_RADIO, RadioBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_RADIO03, RadioBlockDefinition03);
BlockDefinitionMap.set(BlockTypes.BLOCK_SKILLS, SkillsBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_SPACER, SpacerBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_TEXT, TextBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_TEXTAREA, TextAreaBlockDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_CONVERSATION,
  ConversationBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_BARISTA, BaristaBlockDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_IMAGE_SELECT,
  ImageSelectBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SYSTEMAVATAR,
  SystemAvatarBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_PREVIEWPROFILE,
  PreviewProfileBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_PRODUCTCONFIGURATION,
  ProductConfigurationBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_CART, CartBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_BUNDLESELECT, BundleSelectDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SOFTWARE_SELECT,
  SoftwareSelectDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_USERSELECT, UserSelectDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_EDITCOMMENT,
  EditCommentBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_ROLE_SELECT, RoleSelectBlockDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_RESET_PASSWORD_METHOD,
  ResetPasswordMethodDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_RESET_PASSWORD_CODE,
  ResetPasswordCodeDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_BEGIN_CHAT, BeginChatBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_WORK_ADDRESS, WorkAddressDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SOFTWARE_UTILIZATION,
  SoftwareUtilizationBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_EQUIPMENT_CHECK,
  EquipmentCheckBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_CHANGE_PASSWORD,
  ChangePasswordDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_PHONE, PhoneBlockDefinition);
BlockDefinitionMap.set(BlockTypes.BLOCK_ADD_ENTRIES, AddEntriesBlockDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SOCIAL_MEDIA,
  SocialMediaBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_SELECT_MY_GEAR, SelectMyGearDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_EXIT_WORKFLOW,
  exitWorkflowBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_CREATE_NEW_USER,
  createNewUserBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_BIRTHDAY, birthdayDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SELECT_USER_CONTACT_GROUP,
  selectUserContactGroupDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SELECT_DEPARTMENT,
  SelectDepartmentDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_IMAGE, imageBlockDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_ADD_RESPONSES,
  AddResponsesBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_LOCATION_DEPT_ROLE_PICKER,
  locationDeptRolePickerBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SEND_DOWNLOAD_APP_SMS,
  SendDownloadAppSMSDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SELECT_SERVICE_TEAM,
  SelectServiceTeamBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SELECT_DEPARTMENT_CAT,
  SelectDepartmentCategoryBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_CREATE_NEW_FAQ,
  createNewFaqBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_SELECT_LANGUAGE, SelectLanguage);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_BUILD_EDIT_FAQ,
  buildEditFaqBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_SEARCH_FAQ, searchSelectFAQDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_ADD_RESPONSE_FAQ,
  AddResponseTextBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_TEACH_BARISTA,
  teachBaristaBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_EDIT_TEACH_BARISTA,
  EditFaqTeachBlockDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_BUILD_EXISTING_EMPLOYEE,
  buildExistingEmployeeBlockDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_SKIP_TASK, skipTaskDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SELECT_TYPE_OS,
  SelectMobileOSDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SEARCH_FAQ_ARCHETYPE,
  searchFAQArchetypeDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SEARCH_ARCHETYPE_ENTITY,
  searchArchetypeEntityDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_UPDATE_ENTITY_VALUE_INSTANCE,
  updateEntityValueInstanceDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_ADD_ENTITY_VALUE,
  addEntityValueDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_ADD_ENTITY_SYNONYM_VALUE,
  addEntitySynonymValueDefinition
);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SELECT_FAQ_ARCHETYPE_ENTITY,
  selectFAQByArchetypeAndEntityDefinition
);
BlockDefinitionMap.set(BlockTypes.BLOCK_SEND_REPORT, sendReportBlockDefinition);
BlockDefinitionMap.set(
  BlockTypes.BLOCK_SET_FAQ_KB_VALUE,
  setFAQKBBlockDefinition
);

export { BlockDefinitionMap };

export default BlockTypes;
