import React from 'react';
import adminReportFilterOptions from '../../../../globals/AdminReportFilterOptions';

const Prefixes = {
  LAST: 'Last',
  THIS: 'This',
};

const formatSummaryCell = (week, isTopic = false) => (
  <div style={{ borderRight: '1px solid #d4d4d5' }}>
    <label>{week.label}</label>
    <br />
    {isTopic ? (
      <>
        <b>{week.topicName}</b>
        <br />
        <span>{week.count ? week.count : ''}</span>
        <br />
      </>
    ) : (
      <>
        <b>{week.count ? week.count : 0}</b>
        <br />
      </>
    )}
  </div>
);

const formatComparisonLabel = (prefix, freq) => {
  const { DAILY, WEEKLY, MONTHLY } = adminReportFilterOptions;

  switch (freq) {
    case DAILY:
      return prefix === Prefixes.THIS ? 'Today' : 'Yesterday';
    case WEEKLY:
      return `${prefix} Week`;
    case MONTHLY:
      return `${prefix} Month`;
    default:
      return '';
  }
};

export { formatComparisonLabel, formatSummaryCell, Prefixes };
