/**
 * Function that return a widget data point with the addition of the percent
 * @param {Object} arg
 * @param {Object} arg.entry - data point of the list widget
 * @param {number} arg.totalInteractions - total interactions of the widget
 * @return {Object}
 */
const getPercent = ({ entry: { total, ...rest }, totalInteractions }) => ({
  ...rest,
  count: total,
  percent: Number(
    // This allows to round up the percent, ie a 0.003 is 0.3% we want to round up to 1%
    Math.ceil((total / totalInteractions) * 100) / 100
  ).toLocaleString(undefined, {
    maximumFractionDigits: 0,
    style: 'percent',
  }),
});

export default getPercent;
