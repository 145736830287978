import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Transition } from 'semantic-ui-react';
import _ from 'lodash';
// import {stripUnit}           from 'polished';

import ChatFormAttachmentDelete from './ChatFormAttachmentDelete';

const rule = (props) => {
  const { theme } = props;

  return {
    '> img': {
      display: 'inline-block',
      maxHeight: '4em',
      maxWidth: '100%',
      verticalAlign: 'middle',
      width: 'auto',
    },
    display: 'inline-block',
    minHeight: theme.formHeight,
    paddingBottom: '.25em',
    paddingLeft: '.25em',
    paddingRight: '.25em',
    paddingTop: '.25em',
    verticalAlign: 'top',
    width: `calc(100% - ${theme.formHeight})`,
  };
};

class ChatFormImg extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    deleteAttachment: PropTypes.func,
    src: PropTypes.string.isRequired,
  };

  static defaultProps = {
    deleteAttachment: _.noop,
  };

  // handleTextAreaFocus(e,data){
  //   const {onTextAreaFocus} = this.props;
  //
  //   onTextAreaFocus(e,data);
  // }

  render() {
    const { className, deleteAttachment, src } = this.props;

    return (
      <Transition transitionOnMount>
        <div className={`${className} inline`} data-component='ChatFormImg'>
          <img alt='' src={src} />

          <ChatFormAttachmentDelete onClick={deleteAttachment} />
        </div>
      </Transition>
    );
  }
}

export default createComponent(rule, ChatFormImg, ['deleteAttachment', 'src']);
