import React, { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Divider, Form, Icon } from 'semantic-ui-react';
import { Boundaries } from 'cascara-middleware';

class ReportFilter extends PureComponent {
  static propTypes = {
    checkboxes: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    displayOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    onCheckboxChange: PropTypes.func,

    onDisplayChange: PropTypes.func,

    onTimePeriodChange: PropTypes.func,
    selectedDisplay: PropTypes.string,
    selectedTime: PropTypes.string,
    timeOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    checkboxes: [],
    displayOptions: [],
    onCheckboxChange: noop,
    onDisplayChange: noop,
    onTimePeriodChange: noop,
    selectedDisplay: '',
    selectedTime: '',
    timeOptions: [],
  };

  render() {
    const {
      checkboxes,
      displayOptions,
      onCheckboxChange,
      onDisplayChange,
      onTimePeriodChange,
      selectedDisplay,
      selectedTime,
      timeOptions,
    } = this.props;

    return (
      <Boundaries>
        <section>
          <header
            style={{
              borderBottom: '1px solid #d4d4d5',
            }}
          >
            {'Report Filters'} <Icon name='sliders' />
          </header>
          <Form>
            {!isEmpty(checkboxes) && (
              <div
                style={{
                  paddingTop: '10px',
                }}
              >
                <label>{'Show'}</label>
                <br />
                {checkboxes.map((checkbox) => (
                  <Form.Checkbox
                    key={checkbox.name}
                    {...checkbox}
                    onChange={onCheckboxChange}
                  />
                ))}
                <Divider />
              </div>
            )}
            <div>
              <label>{'Time Period'}</label>
              <Form.Dropdown
                fluid
                onChange={onTimePeriodChange}
                options={timeOptions}
                value={selectedTime}
              />
              <Divider />
            </div>
            <div>
              <label>{'Display'}</label>
              <Form.Dropdown
                fluid
                onChange={onDisplayChange}
                options={displayOptions}
                value={selectedDisplay}
              />
            </div>
          </Form>
        </section>
      </Boundaries>
    );
  }
}

export default ReportFilter;
