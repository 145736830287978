import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class FormInputFileRef extends PureComponent {
  static propTypes = {
    accept: PropTypes.string,
    autoComplete: PropTypes.string,
    captureType: PropTypes.string, // Pass user or environment for the Front or Rear camera
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    onClick: PropTypes.func,
    setRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    accept: '.png, .jpg, .gif, .jpeg',
    autoComplete: 'false',
    captureType: null,
    defaultValue: null,
    id: null,
    onClick: _.noop,
  };

  setRef = (input) => {
    const { setRef, captureType } = this.props;

    if (captureType && input) {
      if (captureType !== 'user' && captureType !== 'environment') {
        // eslint-disable-next-line no-console -- debugging
        console.warn(
          'Warning: The "captureType" prop should be only "user" or "environment"'
        );
      } else {
        input.setAttribute('capture', captureType);
      }
    }

    setRef(input, this.props);
  };

  render() {
    const {
      accept,
      autoComplete,
      defaultValue,
      handleChange,
      id,
      onClick,
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    return (
      <input
        accept={accept}
        autoComplete={autoComplete}
        className='hidden'
        id={id}
        onChange={handleChange}
        onClick={onClick}
        ref={this.setRef}
        type='file'
      />
    );
  }
}

export default FormInputFileRef;
