const api_barista_v0_1_kb_support_list_completed_kb = {
  count: 3,
  results: [
    {
      article_id: '23432',
      eid: '260574c5-f070-4069-a2fd-rewdf807e80',
      number: 'b865dcfb-66c4-489e-90ca-1e8e604847c0',
      opening: 'KB002',
    },
    {
      article_id: '23432',
      eid: '7dcc8002-3ebc-4508-adrwe8f2149c030',
      number: 'TEST1688',
      opening: 'New Confluence Article by Pablo R2021-06-02T06:49:56.721039!',
    },
    {
      eid: '0baf86c2-04f4-43ac-80ff-1rwe04cab',
      faq_eid: '23432',
      number: 'TESnjd8487',
      opening: 'New Confluence Article by Pablo R2021-09-17T04:53:06.170177!',
    },
    {
      article_id: '7777',
      number: 'Hooola',
      title: 'Hello there',
    },
    {
      article_id: '8888',
      number: 'lol',
      title: 'Na na nan na',
    },
  ],
};

export default api_barista_v0_1_kb_support_list_completed_kb;
