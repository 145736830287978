import actionTypes from './actionTypes';

const faqActions = {};

faqActions.openFAQasKBisDisabledModal = () => ({
  type: actionTypes.FAQ_AS_KB_IS_DISABLED_MODAL_OPEN,
});
faqActions.closeFAQasKBisDisabledModal = () => ({
  type: actionTypes.FAQ_AS_KB_IS_DISABLED_MODAL_CLOSE,
});

faqActions.setFaqAdditionalInformation = (additionalInformation) => ({
  additionalInformation,
  type: actionTypes.FAQ_AS_KB_ADDITIONAL_INFORMATION,
});
faqActions.addFAQAnswer = (eid) => ({
  eid,
  type: actionTypes.ADD_FAQ_ANSWER,
});

faqActions.removeNewFAQAnswer = (eid) => ({
  eid,
  type: actionTypes.REMOVE_NEW_FAQ_ANSWER,
});

faqActions.markActiveEditFAQ = (eid) => ({
  eid,
  // marks a faq as currently being edited
  type: actionTypes.FAQ_ADMIN_ACTIVE_EDIT_FAQ,
});

faqActions.createFaqStart = () => ({
  type: actionTypes.CREATE_FAQ_START,
});

faqActions.createFaqEnd = () => ({
  type: actionTypes.CREATE_FAQ_END,
});

faqActions.updateFaqStart = () => ({
  type: actionTypes.UPDATE_FAQ_START,
});

faqActions.updateFaqSuccess = (faq) => ({
  faq,
  type: actionTypes.UPDATE_FAQ_SUCCESS,
});

faqActions.updateFaqFail = () => ({
  type: actionTypes.UPDATE_FAQ_FAIL,
});

faqActions.updateFaqGroupStart = (eid) => ({
  eid,
  type: actionTypes.UPDATE_FAQ_GROUP_START,
});

faqActions.updateFaqGroupSuccess = (data, faqEid, answerEid) => ({
  answerEid,
  data,
  faqEid,
  type: actionTypes.UPDATE_FAQ_GROUP_SUCCESS,
});

faqActions.updateFaqAnswerSuccess = (data, faqEid, answerEid) => ({
  answerEid,
  data,
  faqEid,
  type: actionTypes.UPDATE_FAQ_ANSWER_SUCCESS,
});

faqActions.updateFaqGroupFail = () => ({
  type: actionTypes.UPDATE_FAQ_GROUP_FAIL,
});

faqActions.getFAQStart = () => ({
  type: actionTypes.GET_FAQ_TO_EDIT_START,
});

faqActions.getFAQFail = () => ({
  type: actionTypes.GET_FAQ_TO_EDIT_FAIL,
});

faqActions.getFAQSuccess = (faq) => ({
  faq,
  type: actionTypes.GET_CURRENT_FAQ_SUCCESS,
});

faqActions.loadTopCandidateFaqsMatchesFail = () => ({
  type: actionTypes.LOAD_TOP_CANDIDATE_MATCHES_FAQS_FAIL,
});

faqActions.loadTopCandidateFaqsMatchesStart = () => ({
  type: actionTypes.LOAD_TOP_CANDIDATE_MATCHES_FAQS_START,
});

faqActions.loadTopCandidateFaqsMatchesSuccess = (data, name) => ({
  data,
  name,
  type: actionTypes.LOAD_TOP_CANDIDATE_MATCHES_FAQS_SUCCESS,
});

faqActions.loadFAQMatchStart = () => ({
  type: actionTypes.LOAD_FAQ_MATCH_START,
});

faqActions.loadFAQMatchFail = () => ({
  type: actionTypes.LOAD_FAQ_MATCH_FAIL,
});

faqActions.loadFAQMatchSuccess = (faq) => ({
  faq,
  type: actionTypes.LOAD_FAQ_MATCH_SUCCESS,
});

faqActions.sendFAQReportStart = () => ({
  type: actionTypes.SEND_FAQ_REPORT_START,
});

faqActions.sendFAQReportFail = () => ({
  type: actionTypes.SEND_FAQ_REPORT_FAIL,
});

faqActions.sendFAQReportSuccess = () => ({
  type: actionTypes.SEND_FAQ_REPORT_SUCCESS,
});

faqActions.setFaqServiceDepartmentSuccess = (department, eid) => ({
  department,
  eid,
  type: actionTypes.SET_FAQ_SERVICE_DEPARTMENT_SUCCESS,
});

faqActions.setFaqServiceTeamSuccess = (team, eid) => ({
  eid,
  team,
  type: actionTypes.SET_FAQ_SERVICE_TEAM_SUCCESS,
});

faqActions.setFaqTaskCategorySuccess = (category, eid) => ({
  category,
  eid,
  type: actionTypes.SET_FAQ_TASK_CATEGORY_SUCCESS,
});

faqActions.setConditionLocDepJobNames = (
  answerEid,
  loadedNames = {},
  updatdeNew = false
) => ({
  answerEid,
  loadedNames,
  type: actionTypes.SET_FAQ_CONDITION_LOCDEPJOB_NAMES,
  updatdeNew,
});

faqActions.editConditionForAnswer = (answerEid, conditionObject) => ({
  answerEid,
  condition: conditionObject,
  type: actionTypes.SET_FAQ_EDIT_CONDITION,
});

faqActions.resetEditCondition = (answerEid) => ({
  answerEid,
  type: actionTypes.SET_FAQ_RESET_EDIT_CONDITION,
});

faqActions.casesSearchStart = () => ({
  type: actionTypes.FAQ_CASES_SEARCH_START,
});

faqActions.casesSearchSuccess = (results) => ({
  results,
  type: actionTypes.FAQ_CASES_SEARCH_SUCCESS,
});

faqActions.baristaIntentLookupStart = () => ({
  type: actionTypes.BARISTA_INTENT_LOOKUP_START,
});

faqActions.baristaIntentLookupSuccess = (results) => ({
  results,
  type: actionTypes.BARISTA_INTENT_LOOKUP_SUCCESS,
});

faqActions.baristaIntentLookupFail = () => ({
  type: actionTypes.BARISTA_INTENT_LOOKUP_FAIL,
});

faqActions.baristaScopedIntentLookupStart = (id) => ({
  id,
  type: actionTypes.BARISTA_SCOPED_INTENT_LOOKUP_START,
});

faqActions.baristaScopedIntentLookupSuccess = (id, results) => ({
  id,
  results,
  type: actionTypes.BARISTA_SCOPED_INTENT_LOOKUP_SUCCESS,
});

faqActions.baristaScopedIntentLookupFail = (id) => ({
  id,
  type: actionTypes.BARISTA_SCOPED_INTENT_LOOKUP_FAIL,
});

faqActions.loadFaqList = (payload) => ({
  payload,
  type: actionTypes.LOAD_FAQ_LIST,
});

faqActions.loadFaqListSuccess = (results = [], count = 0) => ({
  count,
  results,
  type: actionTypes.LOAD_FAQ_LIST_SUCCESS,
});

faqActions.loadFaqListFailure = (err) => ({
  err,
  type: actionTypes.LOAD_FAQ_LIST_FAILURE,
});

export default faqActions;
