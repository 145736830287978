const systemEval = (msg) => Boolean(msg?.type === 'system');

const system = {
  evaluate: (msg) => systemEval(msg),
};

const systemEvals = {
  system: systemEval,
};

export { systemEvals };
export default system;
