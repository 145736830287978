import { List } from 'immutable';

export const getInactiveUsers = (userList) =>
  userList.filter((u) => u && !u.get('is_active'));
export const getActiveUsers = (userList) =>
  userList.filter((u) => u && u.get('is_active'));

export const getAllUsers = (state, options = {}) => {
  const users = state.getIn(['entities', 'users']);
  const usersAdmin = state.getIn(['usersAdmin', 'users']);
  if (users.isEmpty() || usersAdmin.isEmpty()) {
    return List();
  }

  let userList = List();

  usersAdmin.forEach((u) => {
    const usrFound = users.find((usr) => usr.get('id') === u);
    userList = userList.push(usrFound);
  });

  if (options.is_active === true) {
    return getActiveUsers(userList);
  }
  return userList;
};
