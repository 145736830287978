const NoPermissionForWFBlob = {
  blocks: [
    {
      baristaImg: '/images/barista/barista_04.svg',
      id: '23a9aec0-4b08-11e7-a919-92ebcb67fe33',
      inputValue: {
        map: '',
        value: '',
      },
      messages: [
        {
          text: 'Oops!',
        },
        {
          text:
            'Sorry, you need additional permissions in Espressive to access this screen.',
        },
        {
          text: 'Please contact your Espressive Admin.',
        },
      ],
      template: 'barista01',
      type: 'barista',
    },
    {
      id: '25b8vgd4-4b08-11e7-a919-98we4874fw44',
      template: 'exitWorkflow01',
      text: 'exitWorkflow',
      to: '/app/home',
      type: 'exitWorkflow',
    },
  ],
  nav: {
    background: {
      hide: true,
      id: '23a9ac0e-4b08-11e7-a919-92ebcb67fe33',
      type: 'background',
    },
    espressiveLogo: false,
    footer: {
      hide: false,
      next: 'Exit',
      skip: '',
    },
    progress: false,
    toolbar: {
      hide: false,
      next: '',
      prev: '',
      title: '',
    },
  },
};

export default NoPermissionForWFBlob;
