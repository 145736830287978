import { createSelector } from 'reselect';

const allProducts = (state) => state.getIn(['entities', 'products']);

const getProductsInSnow = (products) =>
  products.filter((product) => product.get('snow_url'));

const selector = createSelector([allProducts], getProductsInSnow);

export default selector;
