import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import classNames from 'classnames';
import TypeHero, { TypeDefaults } from './TypeHero';

const rule = () => ({
  '> .headline': {
    fontSize: '1.2rem',
    margin: 0,
    marginLeft: '1em',
    order: 2,
  },
  '> .image': {
    '&.avatar': {
      borderRadius: '50%',
    },
    '&.large': {
      width: '80px',
    },
    maxWidth: '30%',
    width: '60px',
  },
  alignItems: 'center',
  display: 'flex',
  overflowWrap: 'break-word',
  padding: '16px 32px',
  paddingBottom: 0,
});

class Hero02 extends PureComponent {
  static propTypes = TypeHero;
  static defaultProps = TypeDefaults;

  render() {
    const {
      headline,
      imageSize,
      avatarImage,
      image,
      type,
      template,
      className,
    } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        {headline && <h4 className='headline'>{headline}</h4>}
        {headline && (
          <img
            alt=''
            className={classNames('image', imageSize, {
              avatar: avatarImage,
            })}
            src={image}
          />
        )}
      </div>
    );
  }
}

export default createComponent(rule, Hero02, [
  'imageSize',
  'avatarImage',
  'headline',
  'image',
  'template',
  'type',
  'id',
  'trigger',
]);
