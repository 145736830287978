import { fromJS } from 'immutable';
import cardTypes from '../globals/CardTypes';
import TimePeriods from '../globals/TimePeriods';

const INITIAL_FILTER_VALUES = fromJS([
  {
    filters: [
      {
        isForced: true,
        key: 'typeIs',
        label: 'label.show',
        options: [
          {
            checked: true,
            text: 'label.navigation_announcements',
            value: cardTypes.TYPE_ANNOUNCEMENT,
          },
          {
            checked: true,
            text: 'label.navigation_approvals',
            value: cardTypes.TYPE_APPROVAL,
          },
          {
            checked: true,
            text: 'label.requests',
            value: cardTypes.TYPE_TASK,
          },
          {
            checked: true,
            text: 'label.other',
            value: cardTypes.OTHER,
          },
        ],
        type: 'checkbox',
      },
    ],
    title: 'label.show',
  },
  {
    filters: [
      {
        key: 'sort',
        options: [
          // { // DEV-26234 - Remove Smart
          //   checked: true,
          //   text: 'filter.smart_sort',
          //   value: 'smart',
          // },
          {
            checked: true,
            text: 'filter.created_new_old',
            value: '-sys_date_created',
          },
          {
            text: 'filter.created_old_new',
            value: 'sys_date_created',
          },
          {
            text: 'filter.updated_new_old',
            value: '-sys_date_updated',
          },
          {
            text: 'filter.updated_old_new',
            value: 'sys_date_updated',
          },
        ],
        type: 'radio',
      },
    ],
    title: 'label.sort_by',
  },
  {
    filters: [
      {
        key: 'time',
        options: [
          {
            checked: true,
            text: 'label.all',
            value: 'all',
          },
          {
            text: 'filter.last_15_minutes',
            value: TimePeriods.LAST_15_MINUTES,
          },
          {
            text: 'filter.last_30_minutes',
            value: TimePeriods.LAST_30_MINUTES,
          },
          {
            text: 'filter.last_4_hours',
            value: TimePeriods.LAST_4_HOURS,
          },
          {
            text: 'filter.last_24_hours',
            value: TimePeriods.LAST_24_HOURS,
          },
          {
            text: 'filter.last_7_days',
            value: TimePeriods.LAST_7_DAYS,
          },
          {
            text: 'filter.last_30_days',
            value: TimePeriods.LAST_30_DAYS,
          },
        ],
        type: 'radio',
      },
    ],
    title: 'label.time_period',
  },
]);

export { INITIAL_FILTER_VALUES };
