import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { curry, memoize } from 'lodash';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
// Global
import ScheduleState from '../../globals/ScheduleState';

class OnboardingActivityScheduleFormButtons extends PureComponent {
  static propTypes = {
    /** Action on a schedule ID (copy) */
    action: PropTypes.string,
    /** Boolean to active REPUBLISH */
    allowRepublish: PropTypes.bool,
    /** Disable button */
    disableButtons: PropTypes.bool,
    /** Template selected is empty */
    emptyTemplate: PropTypes.bool,
    /** Main Loading state */
    isLoading: PropTypes.bool,
    /** Save Schedule */
    saveSchedule: PropTypes.func.isRequired,
    /** ID of the template OR 'new' for a new template. Please update type checking for this. */
    scheduleID: PropTypes.string,
    /** schedule status */
    status: PropTypes.string,
  };

  static defaultProps = {
    action: null,
    allowRepublish: false,
    disableButtons: false,
    emptyTemplate: false,
    isLoading: false,
    /** If templateID is null, we should display the first template in the list. Use a selector. */
    scheduleID: null,
    status: ScheduleState.STATUS_DRAFT,
  };

  handleSaveForm = memoize(
    curry((status, e) => {
      const { saveSchedule } = this.props;
      saveSchedule(status);
    })
  );

  render() {
    const {
      action,
      allowRepublish,
      disableButtons,
      isLoading,
      scheduleID,
      status,
      emptyTemplate,
    } = this.props;

    const buttonDraft =
      scheduleID === 'new' || action === 'copy' ? 'Save Draft' : 'Update Draft';

    const displayDraft =
      scheduleID === 'new' ||
      status === ScheduleState.STATUS_DRAFT ||
      action === 'copy';
    const isArchived = status === ScheduleState.STATUS_ARCHIVED;

    const disableAllButtons = isLoading;

    return (
      <Form.Group className='button-actions' grouped>
        <Button
          as={Link}
          className='text'
          content='Back'
          floated={'left'}
          icon='left chevron'
          to={uiPathGenerator.genPath(
            'admin.adminActivities.onboardingActivities.schedule'
          )}
        />

        {displayDraft && (
          <Button
            basic
            content={buttonDraft}
            disabled={disableButtons || disableAllButtons}
            onClick={this.handleSaveForm(ScheduleState.STATUS_DRAFT)}
          />
        )}

        {displayDraft && (
          <Button
            basic
            content='Publish'
            disabled={disableButtons || emptyTemplate || disableAllButtons}
            onClick={this.handleSaveForm(ScheduleState.STATUS_PUBLISHED)}
          />
        )}

        {!displayDraft && !isArchived && (
          <Button
            basic
            content='Republish'
            disabled={!allowRepublish || disableAllButtons}
            onClick={this.handleSaveForm(ScheduleState.STATUS_PUBLISHED)}
          />
        )}

        {!displayDraft && !isArchived && (
          <Button
            basic
            content='Unpublish'
            disabled={disableAllButtons}
            onClick={this.handleSaveForm(ScheduleState.STATUS_DRAFT)}
          />
        )}

        {!displayDraft && !isArchived && (
          <Button
            basic
            content='Archive'
            disabled={disableAllButtons}
            onClick={this.handleSaveForm(ScheduleState.STATUS_ARCHIVED)}
          />
        )}

        {!displayDraft && isArchived && (
          <Button basic content='Archived' disabled />
        )}
      </Form.Group>
    );
  }
}

export default OnboardingActivityScheduleFormButtons;
