import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsServiceOutage extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M60.33 23l-1 .51c-1.028-1.26-2.266-2.333-3.66-3.17l.31-.95c.367-1.15-.263-2.378-1.41-2.75L53 16.16c-1.15-.367-2.378.263-2.75 1.41l-.27.84c-1.652-.19-3.325-.085-4.94.31l-.62-1.21c-.265-.517-.725-.908-1.278-1.087-.553-.178-1.155-.13-1.672.137l-1.45.74c-.517.265-.908.725-1.087 1.278-.178.553-.13 1.155.137 1.672l.55 1.07c-1.472 1.143-2.702 2.568-3.62 4.19l-.87-.28c-1.15-.367-2.378.263-2.75 1.41l-.5 1.55c-.367 1.15.263 2.378 1.41 2.75l.94.3c-.144 1.622-.005 3.256.41 4.83l-1.3.66c-.517.265-.908.725-1.087 1.278-.178.553-.13 1.155.137 1.672l.74 1.45c.265.517.725.908 1.278 1.087.553.178 1.155.13 1.672-.137l1.28-.65c1.11 1.37 2.466 2.52 4 3.39l-.29.77c-.423 1.118.13 2.37 1.24 2.81l6.4-8.12h-.37c-4.123.005-7.54-3.195-7.804-7.31-.264-4.115 2.717-7.726 6.807-8.247 4.09-.52 7.88 2.227 8.657 6.277l6.29-8c-.616-.31-1.344-.31-1.96 0zm-4.19 24.8l1.37-.88c.998-.655 1.288-1.99.65-3l-.48-.75c1.126-.98 2.088-2.136 2.85-3.42l1 .32c1.15.367 2.378-.263 2.75-1.41l.5-1.55c.367-1.15-.263-2.378-1.41-2.75l-.82-.26-9.83 12.42.4.62c.653 1.014 2.003 1.31 3.02.66zM19 67.16l4.4 5.26 14.33-18.18c-4.87.22-11.06 3.33-12.07 4.53L19 67.16zm60-17.54c-1.923-1.472-4.66-1.196-6.25.63l-7.51 8.5c-.14 4-4 5.63-7.72 6.21h-8.13c-.84 0-1.45-.12-1.45-1s.53-1.1 1.45-1.1h8.08c2.32 0 5.45-1.82 5.52-4.14.07-2.32-2.92-4.35-5.31-4.35h-11.1L28 77.91l2.24 2.68 3.86-3.93h22.46c3.046.04 5.953-1.27 7.94-3.58l15-17.13c1.587-1.893 1.365-4.71-.5-6.33z' />
          <path d='M48 4c-18.577.012-35.144 11.69-41.4 29.18C.34 50.673 5.744 70.21 20.1 82c.05.057.103.11.16.16l.12.06c15.045 12.16 36.268 13.067 52.296 2.235s23.1-30.862 17.428-49.357C84.432 16.603 67.344 3.983 48 4zM9 48c0-14.525 8.074-27.847 20.95-34.57C42.826 6.71 58.37 7.7 70.29 16L22.24 77.24C13.825 69.853 9 59.198 9 48zm39 39c-8.465.01-16.7-2.752-23.45-7.86l48.13-61.31c12.744 10.44 17.554 27.76 12.018 43.276C79.162 76.622 64.474 86.986 48 87z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsServiceOutage;
