import React, { PureComponent } from 'react';
import { Button, Divider, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FieldArray, Formik, Field as FormikField } from 'formik';
import { every } from 'lodash';
import { Boundaries } from 'cascara-middleware';
import TenantConfigPrivacyLinksController from '../controllers/TenantConfigPrivacyLinksController';

const linkShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

class TenantConfigPrivacyLinks extends PureComponent {
  static propTypes = {
    privacyLinks: PropTypes.arrayOf(linkShape),
    saveTenantPrivacyStatement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    privacyLinks: [],
  };

  validateForm = (values) => {
    const error = {};
    if (values.links) {
      const isAllLinksFilled = every(
        values.links,
        (link) => link.url && link.label
      );
      if (!isAllLinksFilled) {
        error.links = 'Cannot be empty';
      }
    }
    return error;
  };
  handleFormSubmit = (values) => {
    const { saveTenantPrivacyStatement } = this.props;

    saveTenantPrivacyStatement(values.links);
  };

  handleRenderRow = () => (
    <Form>
      <Form.Group>
        <Form.Input inline label='Label' />
        <Form.Input inline label='URL' />
        <Button basic icon='trash' />
        {/* Only show this save when the row is dirty */}
        <Button icon='check' positive />
      </Form.Group>
    </Form>
  );

  renderLinkFields = (arrayHelpers) => {
    const { values } = arrayHelpers.form;

    return (
      <>
        {/* Clicking this button will add a new row below */}
        <Button
          basic
          content='Add Link'
          floated='right'
          // eslint-disable-next-line react/jsx-no-bind -- TODO refactor no bind
          onClick={() =>
            arrayHelpers.push({
              label: '',
              url: '',
            })
          }
          type='button'
        />

        <Divider clearing hidden />

        {values.links.map((link, index) => (
          <Form.Group key={index.toString()}>
            <Form.Input
              control={FormikField}
              inline
              label='Label'
              name={`links.${index}.label`}
              type='text'
            />
            <Form.Input
              control={FormikField}
              inline
              label='URL'
              name={`links[${index}].url`}
              type='text'
            />
            <Button
              basic
              icon='trash'
              // eslint-disable-next-line react/jsx-no-bind -- TODO refactor no bind
              onClick={() => arrayHelpers.remove(index)}
              type='button'
            />
          </Form.Group>
        ))}
      </>
    );
  };

  renderForm = ({ handleReset, handleSubmit, dirty, isValid }) => (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <FieldArray name='links' render={this.renderLinkFields} />
      {dirty ? (
        <Button
          content='Save'
          disabled={!isValid}
          floated='right'
          primary
          type='submit'
        />
      ) : null}
    </Form>
  );

  render() {
    const { privacyLinks } = this.props;

    return (
      <Boundaries>
        <Formik
          enableReinitialize
          initialValues={{ links: privacyLinks }}
          onSubmit={this.handleFormSubmit}
          render={this.renderForm}
          validate={this.validateForm}
        />
      </Boundaries>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
TenantConfigPrivacyLinks = TenantConfigPrivacyLinksController(
  TenantConfigPrivacyLinks
);

export default TenantConfigPrivacyLinks;
