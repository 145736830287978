import React from 'react';
import PropTypes from 'prop-types';
import variables from 'esp-style-vars';

const theme = variables();

const stylesFactory = (open) => ({
  backgroundColor: theme.colors.lightGray,
  borderBottom: '1px solid transparent',
  borderBottomColor: open ? theme.colors.lightGray : theme.colors.gray,
  position: 'relative',
  zIndex: theme.globals.zIndexInput,
});

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  open: PropTypes.bool,
};

const defaultProps = {
  children: null,
  open: true,
};
const ListFilterContainer = ({ open, children }) => (
  <div
    aria-expanded={open}
    data-component='ListFilterContainer'
    role='listbox'
    style={stylesFactory(open)}
  >
    {children}
  </div>
);

ListFilterContainer.propTypes = propTypes;
ListFilterContainer.defaultProps = defaultProps;

export default ListFilterContainer;
