import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import _ from 'lodash';

// Controller
import MultipleProductFulfillmentController from '../controllers/MultipleProductFulfillmentController';

import ProductFulfillmentQuestions from './ProductFulfillmentQuestions';

class MultipleProductFulfillment extends PureComponent {
  static propTypes = {
    fulfillmentAnswersByProduct: ImmutablePropTypes.map.isRequired,
    /** whether or not show submit button at the bottom. Default to false. */
    hideSubmit: PropTypes.bool,
    isValid: PropTypes.bool,
    onSubmit: PropTypes.func,
    /**  array with id of products */
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
    /** Function that will perform remote submit on all these products */
    submitProductFulfillmentForms: PropTypes.func,
  };

  static defaultProps = {
    hideSubmit: false,
    isValid: false,
    onSubmit: _.noop,
    submitProductFulfillmentForms: _.noop,
  };

  handleSubmit = () => {
    const {
      isValid,
      fulfillmentAnswersByProduct,
      onSubmit,
      submitProductFulfillmentForms,
    } = this.props;

    // triggers validation in all children forms
    submitProductFulfillmentForms();
    if (isValid) {
      onSubmit(fulfillmentAnswersByProduct);
    }
  };

  handleSuccesSubmit = () => {
    const { fulfillmentAnswersByProduct, onSubmit } = this.props;
    onSubmit(fulfillmentAnswersByProduct);
  };

  render() {
    const { products, hideSubmit } = this.props;

    return (
      <div>
        {products.map((id) => (
          <React.Fragment key={id}>
            <ProductFulfillmentQuestions
              hideSubmit
              key={id}
              onSubmit={this.handleSuccesSubmit}
              productId={id}
            />
            <Divider />
          </React.Fragment>
        ))}

        {hideSubmit ? (
          <Button
            content={'Submit Multiple'}
            onClick={this.handleSubmit}
            primary
            type='submit'
          />
        ) : null}
      </div>
    );
  }
}

const MultipleProductFulfillmentTest = MultipleProductFulfillment;

// eslint-disable-next-line no-class-assign -- DEV-1526
MultipleProductFulfillment = MultipleProductFulfillmentController(
  MultipleProductFulfillment
);
export { MultipleProductFulfillmentTest };
export default MultipleProductFulfillment;
