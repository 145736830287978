import { Dropdown, Icon } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { intl } from 'esp-util-intl';
import pt from 'prop-types';
import useGroups from '../hooks/useGroups';

const TIMEOUT = 400;

const propTypes = {
  disabled: pt.bool,
  handleSelectionInParent: pt.func,
  hasAdHocGroup: pt.bool,
  selectedGroup: pt.number,
};

const SelectUserGroups = ({
  disabled,
  hasAdHocGroup,
  selectedGroup,
  handleSelectionInParent,
}) => {
  const [groupList, setGroupList] = useState([]);
  const [value, setValue] = useState();
  const { getAllActiveGroups, searchGroup } = useGroups();
  const groupsFormatter = (groupList) => {
    return groupList
      .map((group) => ({
        key: group.id,
        text: group.name,
        value: group.id,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  };
  useEffect(() => {
    getAllActiveGroups().then((result) => {
      setGroupList(groupsFormatter(result));
    });
    if (hasAdHocGroup && selectedGroup) {
      setValue(selectedGroup);
    }
  }, [getAllActiveGroups, hasAdHocGroup, selectedGroup]);
  const handleSelection = useCallback(
    (event, { value }) => {
      setValue(value);
      handleSelectionInParent(value);
    },
    [handleSelectionInParent]
  );

  const doSearch = debounce((searchQuery) => {
    searchGroup(searchQuery.trim())
      .then((result) => {
        setGroupList(groupsFormatter(result));
      })
      .catch(() => {
        setGroupList([]);
      });
  }, TIMEOUT);

  const handleSearch = useCallback(
    (e, { searchQuery }) => {
      doSearch(searchQuery);
    },
    [doSearch]
  );

  const removeSelection = useCallback(() => {
    setValue('');
    handleSelectionInParent('');
  }, [handleSelectionInParent]);

  const SearchiIcon = !value ? (
    <Icon
      name='search'
      style={{
        position: 'absolute',
        top: 6,
      }}
    />
  ) : null;

  return (
    <>
      {value ? (
        <Icon
          name='close'
          onClick={removeSelection}
          style={{
            cursor: 'pointer',
            marginTop: 7,
            position: 'absolute',
            right: 25,
            visibility: 'visible',
            zIndex: 3,
          }}
        />
      ) : null}
      <Dropdown
        clearable
        disabled={disabled}
        fluid
        icon={SearchiIcon}
        loading={disabled}
        onChange={handleSelection}
        onSearchChange={handleSearch}
        options={groupList}
        placeholder={intl.formatMessage({ id: 'label.user_group_select' })}
        search
        selection
        value={value}
      />
    </>
  );
};

SelectUserGroups.propTypes = propTypes;

export default SelectUserGroups;
