import { createSelector } from 'reselect';
import getChildBundles from './getChildBundles';

const getSoftwareBundle = (bundles) => {
  if (bundles) {
    return bundles.find((b) => {
      if (b) {
        return b.get('tags') === 'software';
      }
      return null;
    });
  }
  return null;
};

const selector = createSelector([getChildBundles], getSoftwareBundle);

export default selector;
