import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'semantic-ui-react';

class FormInputSelect extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        key: PropTypes.any,
        name: PropTypes.string,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        value: PropTypes.any,
      })
    ).isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: null,
    disabled: false,
    label: '',
    placeholder: '',
    readOnly: false,
    required: false,
  };

  render() {
    const {
      defaultValue,
      disabled,
      label,
      options,
      placeholder,
      readOnly,
      required,
    } = this.props;

    return (
      <Form.Field>
        <label>{label}</label>
        <Select
          defaultValue={defaultValue}
          disabled={disabled}
          options={options}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
        />
      </Form.Field>
    );
  }
}

export default FormInputSelect;
