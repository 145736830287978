import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { List, Menu } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { noop } from 'lodash';

// molecules
import CatalogListItem from './CatalogListItem';
import MessageEmptyState from './MessageEmptyState';
// Global
import { loadingItemsCart } from '../../globals/LoadingState';
// Controller
import CartController from '../controllers/CartController';

class CartList extends PureComponent {
  static propTypes = {
    /** Load the cart when component will mount */
    autoCartLoad: PropTypes.bool,
    cartItems: ImmutablePropTypes.list,
    cartLoad: PropTypes.func,
    cartRemoveProduct: PropTypes.func,
    cartTotal: PropTypes.number,
    cartUpdateProduct: PropTypes.func,
    emptyCartMessage: PropTypes.string,
    handleClose: PropTypes.func,
    isLink: PropTypes.bool,
    isLoading: PropTypes.bool,
    isModal: PropTypes.bool,
  };

  static defaultProps = {
    autoCartLoad: true,
    cartItems: Immutable.List(),
    cartLoad: noop,
    cartRemoveProduct: noop,
    cartTotal: 0,
    cartUpdateProduct: noop,
    emptyCartMessage: null,
    handleClose: noop,
    isLink: false,
    isLoading: false,
    isModal: false,
  };

  componentDidMount() {
    const { cartLoad, cartItems, autoCartLoad } = this.props;
    if (cartLoad && cartItems.size === 0 && autoCartLoad) {
      cartLoad();
    }
  }

  setupCart = () => {
    const {
      cartItems,
      cartRemoveProduct,
      cartUpdateProduct,
      handleClose,
      isLink,
      isLoading,
      isModal,
    } = this.props;

    const inCartItems = [];

    const items =
      isLoading && (!cartItems || cartItems.isEmpty())
        ? loadingItemsCart
        : cartItems;

    items.forEach((item, i) => {
      if (item.get('id')) {
        const upwardDropdown = items.size > 3 && i > 2;
        inCartItems.push(
          <CatalogListItem
            cartItem={isLink}
            cartRemoveProduct={cartRemoveProduct}
            cartUpdateProduct={cartUpdateProduct}
            categoryID={item.getIn(['category_all', '0'])}
            handleClose={handleClose}
            isLink={isLink}
            isLoading={isLoading && (!cartItems || cartItems.isEmpty())}
            isModal={isModal}
            isUpdating={cartItems && !cartItems.isEmpty() && isLoading}
            key={item.get('citemId')}
            product={item}
            upwardDropdown={upwardDropdown}
          />
        );
      }
    });
    return inCartItems;
  };

  formatPrice(price) {
    // Convert final amount to a human readable - Possible to use the new Intl API
    return `$${price
      .toFixed(2)
      .replace(/./g, (c, i, a) =>
        i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c
      )}`;
  }

  render() {
    const { cartItems, cartTotal, emptyCartMessage, isLink, isLoading } =
      this.props;

    const inCartItems = this.setupCart();
    const isCartEmpty = !(cartItems.size > 0);

    const finalAmount = this.formatPrice(cartTotal);

    const totalText = intl.formatMessage({
      id: 'message.total',
    });

    if (isCartEmpty && !isLoading) {
      return (
        <MessageEmptyState
          content={
            emptyCartMessage
              ? emptyCartMessage
              : intl.formatMessage({
                  id: 'message.empty_cart',
                })
          }
          header={intl.formatMessage({
            id: 'message.empty_cart_title',
          })}
        />
      );
    }

    return (
      <div className='esp-cart'>
        <List
          className='cart-list'
          divided
          relaxed='very'
          verticalAlign='middle'
        >
          {inCartItems.map((item) => item)}
        </List>

        {isLink && (
          <Menu borderless className='cart-footer'>
            {/* {!isLink &&*/}
            {/* <Menu.Menu>*/}
            {/* <Menu.Item*/}
            {/* content={totalText}*/}
            {/* header*/}
            {/* />*/}
            {/* </Menu.Menu>*/}
            {/* }*/}

            <Menu.Menu position='right'>
              <Menu.Item content={totalText} header />
              <Menu.Item content={finalAmount} />
            </Menu.Menu>
          </Menu>
        )}
      </div>
    );
  }
}
const CartListTest = CartList;

// eslint-disable-next-line no-class-assign -- DEV-1526
CartList = CartController(CartList);
export { CartListTest };
export default CartList;
