import actionTypes from '../actions/actionTypes';
import browserHistory from '../utils/browserHistory';
import { getCardDetailPath } from '../utils/CordovaUtils';
import PwaUtils from '../utils/PwaUtils';

/**
 * PWA middleware
 */

const pwaMiddleware = (function () {
  // Reducer-like middleware.
  // Not a real reducer. We only listen for actions here
  // and perform side effects
  return (store) => (next) => (action) => {
    if (PwaUtils.isPwaInstalled()) {
      switch (action.type) {
        // any action we catch and want to do something with it, e.g.
        case actionTypes.PWA_NOTIFICATION: {
          const message = action.payload ? action.payload.message : 'hmmm';
          const title = 'Barista'; // todo: use brand name

          const desktopNotification = new Notification(title, {
            body: message,
          });

          desktopNotification.onclick = () => {
            // Redirects towards card details
            const cardDetailPath = getCardDetailPath(action.payload.url);
            if (cardDetailPath.isValid) {
              browserHistory.push({ pathname: cardDetailPath.path });
              desktopNotification.close();
            }
          };

          return store;
        }
        default:
          return next(action);
      }
    }

    return next(action);
  };
})();

export default pwaMiddleware;
