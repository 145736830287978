import React, { useContext } from 'react';
import { labels } from '../utils/constants';
import AzureContext from '../AzureContext';
import { Button, Segment } from 'semantic-ui-react';
import AzureFormikContext from './AzureFormikContext';

const propTypes = {};

const AzureBasicSSOEditActionButtons = () => {
  const { toggleEditMode, isLoading } = useContext(AzureContext);
  const { isValid, dirty } = useContext(AzureFormikContext);

  return (
    <Segment attached style={{ textAlign: 'right' }}>
      {!isLoading && (
        <Button
          basic
          content={labels.CANCEL}
          onClick={toggleEditMode}
          type='button'
        />
      )}

      <Button
        content={labels.SAVE}
        disabled={isLoading || !isValid || !dirty}
        loading={isLoading}
        positive
        type='submit'
      />
    </Segment>
  );
};

AzureBasicSSOEditActionButtons.propTypes = propTypes;

export default AzureBasicSSOEditActionButtons;
