import { createSelector } from 'reselect';
import immutable from 'immutable';

// Global
import UserImageTypes from '../globals/UserImageTypes';
import getServiceMemberList from './getServiceMemberList';

const getUsersList = (state) =>
  state.getIn(['search', 'userSearch', 'results']);

const getMemberList = (state) => getServiceMemberList(state);

const getUsersSearch = (usersSearch, memberList) => {
  let newUsersList;
  if (usersSearch && !usersSearch.isEmpty()) {
    // Filter from existing users the results.
    newUsersList = usersSearch
      .filter(
        (u) => !memberList.find((m) => m.get('user') === Number(u.get('pk')))
      )
      .map((user) => {
        const userImg =
          user.has('images') && !user.get('images').isEmpty()
            ? immutable.fromJS([
                {
                  id: 1, // Fake ID to avoid UserImage to complain
                  picture: user.getIn(['images', 'profile_picture']),
                  thumbnail: user.getIn(['images', 'profile_thumbnail']),
                  type: UserImageTypes.PROFILE,
                },
              ])
            : immutable.fromJS([]);
        return user.set('id', Number(user.get('pk'))).set('images', userImg);
      });
  }

  return newUsersList;
};

const selector = createSelector([getUsersList, getMemberList], getUsersSearch);

export default selector;
