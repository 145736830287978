import { useContext } from 'react';

import AuthContext from '../../context/auth/authContext';

//
// Hook: useBootstrapStart
//
// Exposes the bootstrap/start api. *
const useBootstrapStart = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error(
      'useBootstrapStart Hook can only be called inside AuthContext.Provider!!'
    );
  }

  return authContext;
};

export default useBootstrapStart;
