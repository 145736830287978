import React, { PureComponent } from 'react';
import { Button, Image, Table } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import { fromJS, List } from 'immutable';
import PropTypes from 'prop-types';

import UserImg from '../../../v2/components/functional/UserImg';

// Molecule
import MessageEmptyState from './MessageEmptyState';
import OnboardProgressBar from './OnboardProgressBar';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
import injectIntlToController from '../../utils/injectIntlToController';
import dateFormat from '../../utils/dateFormat';

// Global
import OnboardingActivityOverallState from '../../globals/OnboardingActivityOverallState';

class OnboardingActivityPerformanceTableList extends PureComponent {
  static propTypes = {
    archiveOnePerformance: PropTypes.func.isRequired,
    escalateProgress: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    loadingDeleteID: PropTypes.number,
    loadingEscalateID: PropTypes.number,
    query: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      show: PropTypes.any,
    }),
    tableList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    isLoading: false,
    loadingDeleteID: null,
    loadingEscalateID: null,
    query: {},
    tableList: List(),
  };

  render() {
    const {
      archiveOnePerformance,
      escalateProgress,
      loadingDeleteID,
      loadingEscalateID,
      query,
      tableList,
      isLoading,
    } = this.props;

    const tableListed = tableList.map((list) => {
      const user = list.get('user');

      const state = list.get('overall_state');

      let linkTo = uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.performance',
        {
          userID: list.getIn(['user', 'id']),
        }
      );
      linkTo = `${linkTo}?type=${query.show}`;

      const username = `${user.get('nickname')} ${user.get('last_name')}`;

      const handleDelete = (perfID) => () => {
        const msg = {
          header: 'Success',
          message: 'The performance has been archived',
        };
        archiveOnePerformance(perfID, msg);
      };

      const handleEscalate = (userID) => () => {
        escalateProgress(userID);
      };

      const activitiesProgress = fromJS({
        completed: list.get('complete_state_count', 0),
        total:
          list.get('delinquent_state_count', 0) +
          list.get('normal_state_count', 0) +
          list.get('overdue_state_count', 0) +
          list.get('scheduled_state_count', 0) +
          list.get('warning_state_count', 0) +
          list.get('complete_state_count', 0),
      });

      const deletedLoading = loadingDeleteID === list.get('id');

      const escalateLoading = user && user.get('id') === loadingEscalateID;

      const disableEscalate =
        list.get('overdue_state_count') + list.get('delinquent_state_count') ===
          0 ||
        list.get('overall_state') === OnboardingActivityOverallState.ONTRACK ||
        activitiesProgress.get('total') -
          activitiesProgress.get('complete_state_count') ===
          0;

      return (
        <Table.Row className='performance-list' key={list.get('id')}>
          <Table.Cell className='performance-user' selectable>
            <Link to={linkTo}>
              <Image
                size='mini'
                style={{
                  marginRight: '.5em',
                }}
              >
                <UserImg userId={user.get('id')} />
              </Image>
              {username}
            </Link>
          </Table.Cell>
          <Table.Cell selectable>
            <OnboardProgressBar
              as={Link}
              label={state}
              overallState={list.get('overall_state')}
              progress={activitiesProgress}
              to={linkTo}
            />
          </Table.Cell>
          <Table.Cell>
            {dateFormat(list.get('user_start_date_time'))}
          </Table.Cell>
          <Table.Cell textAlign='right'>
            <Button.Group basic>
              <Button as={Link} content='View' to={linkTo} />
              <Button
                content='Escalate'
                disabled={disableEscalate}
                loading={escalateLoading}
                onClick={handleEscalate(user.get('id'))}
              />
              <Button
                content='Archive'
                loading={deletedLoading}
                onClick={handleDelete(list.get('id'))}
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    });

    if (!isLoading && (!tableListed || tableListed.isEmpty())) {
      return (
        <MessageEmptyState
          content={'Try another filter'}
          header={'No performances available with this filters'}
        />
      );
    }
    return (
      <Table columns={4} compact striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>{'Employee'}</Table.HeaderCell>
            <Table.HeaderCell width={5}>{'Status'}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{'Start Date'}</Table.HeaderCell>
            <Table.HeaderCell width={4} />
          </Table.Row>
          {tableListed}
        </Table.Header>
      </Table>
    );
  }
}

export const OnboardingActivityPerformanceTableListTest = OnboardingActivityPerformanceTableList;

export default injectIntlToController(OnboardingActivityPerformanceTableList);
