import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Icon, List, Loader, Message, Modal } from 'semantic-ui-react';
import _ from 'lodash';

// atoms
import ScrollArea from './ScrollArea';
// Controller
import CSVReportModalController from '../controllers/CSVReportModalController';
// v2 Component
import { Boundaries } from 'cascara-middleware';

class CSVReportModal extends PureComponent {
  static propTypes = {
    disposable: PropTypes.shape({
      icon: PropTypes.string,
      message: PropTypes.string.isRequired,
    }),
    icon: PropTypes.string,
    instance: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      parse_json_response: ImmutablePropTypes.mapContains({
        filename: PropTypes.string,
        message: PropTypes.oneOfType([
          PropTypes.string,
          ImmutablePropTypes.list,
        ]),
        status: PropTypes.number,
      }),
    }),
    instanceId: PropTypes.number,
    isLoadingInstance: PropTypes.bool,
    loadInstance: PropTypes.func,
    message: PropTypes.string,
    negative: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    disposable: null,
    icon: 'info circle',
    instance: Immutable.Map(),
    instanceId: null,
    isLoadingInstance: false,
    loadInstance: _.noop,
    message: void 0,
    negative: false,
    size: 'small',
  };

  state = {
    modalOpen: false,
  };

  handleOpen = () => {
    this.setState({
      modalOpen: true,
    });

    const { instanceId, loadInstance } = this.props;

    loadInstance(instanceId);
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const {
      disposable,
      icon,
      instance,
      isLoadingInstance,
      message,
      negative,
      size,
      title,
    } = this.props;
    const { modalOpen } = this.state;

    let itemsArray;
    // converting parse_json_response to an js array
    if (instance.has('parse_json_response')) {
      itemsArray = Immutable.List.isList(
        instance.getIn(['parse_json_response', 'message'])
      )
        ? instance.getIn(['parse_json_response', 'message']).toJS()
        : [instance.getIn(['parse_json_response', 'message'])]; // because messages is not always an array
    }

    return (
      <Modal
        className='scrolling'
        onClose={this.handleClose}
        open={modalOpen}
        size={size}
        trigger={
          <Icon
            name={icon}
            onClick={this.handleOpen}
            style={{
              cursor: 'pointer',
            }}
          />
        }
      >
        <Boundaries>
          <div className='form ui'>
            <Modal.Header
              color={negative ? 'red' : void 0}
              content={title}
              icon={negative ? 'warning sign' : 'info'}
            />
            <Modal.Content
              className={disposable ? 'collapsed withFooter' : 'collapsed'}
            >
              {isLoadingInstance ? <Loader inverted>{'Loading'}</Loader> : null}
              <ScrollArea>
                {message ? <h3>{message}</h3> : null}
                <List
                  items={
                    itemsArray &&
                    _.isArray(itemsArray) &&
                    itemsArray.map((m, i) => {
                      const index = i; // Some message may be exactly the same
                      return (
                        <Message
                          content={m}
                          key={m + index}
                          negative={negative}
                        />
                      );
                    })
                  }
                />
              </ScrollArea>
            </Modal.Content>
            {disposable && (
              <Modal.Actions>
                <Button onClick={this.handleClose} primary>
                  <Icon name={disposable.icon || 'checkmark'} />
                  {disposable.message}
                </Button>
              </Modal.Actions>
            )}
          </div>
        </Boundaries>
      </Modal>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
CSVReportModal = CSVReportModalController(CSVReportModal);

export default CSVReportModal;
