import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class OnboardActivitiesVPNSetup extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 48 48'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8,0 L40,0 C44.418278,-8.11624501e-16 48,3.581722 48,8 L48,40 C48,44.418278 44.418278,48 40,48 L8,48 C3.581722,48 5.41083001e-16,44.418278 0,40 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z M8,2.5 C4.96243388,2.5 2.5,4.96243388 2.5,8 L2.5,40 C2.5,43.0375661 4.96243388,45.5 8,45.5 L40,45.5 C43.0375661,45.5 45.5,43.0375661 45.5,40 L45.5,8 C45.5,4.96243388 43.0375661,2.5 40,2.5 L8,2.5 Z M16.8562167,11.7150737 L19.3738056,11.7150737 L19.3738056,9.04610606 L27.6790567,9.04610606 L27.6790567,11.6959676 L30.2716001,11.6959676 L30.2716001,19.6014614 L27.6687688,19.6014614 L27.6687688,22.2865957 L24.9762861,22.2865957 L24.9762861,24.9291088 L27.5541326,24.9291088 L27.5541326,27.5686825 C26.8912997,27.5686825 26.21377,27.5833794 25.5391796,27.5628037 C25.107089,27.5495764 24.9057407,27.6921369 24.9263164,28.1521517 C24.952771,28.7312119 24.9321952,29.3117417 24.9321952,30.0245442 L27.4247993,30.0245442 L27.4247993,32.6567694 C26.817815,32.6567694 26.1961336,32.6758755 25.575922,32.6494209 C25.1056193,32.6303149 24.8998619,32.7861026 24.9248467,33.2828598 C24.95718,33.9368745 24.9321952,34.5952982 24.9321952,35.3360249 L27.4159812,35.3360249 L27.4159812,37.9947046 L24.9674679,37.9947046 L24.9674679,40.0464 L22.2911518,40.0464 L22.2911518,22.3350957 L19.4017298,22.3350957 L19.4017298,19.627916 L16.8562167,19.627916 L16.8562167,11.7150737 Z M22.4190154,14.5045563 L24.9174983,14.5045563 L24.9174983,12.0134219 L22.4190154,12.0134219 L22.4190154,14.5045563 Z'
          fill={color}
        />
      </svg>
    );
  }
}

export default OnboardActivitiesVPNSetup;
