import React from 'react';
import PropTypes from 'prop-types';
import variables from 'esp-style-vars';

const theme = variables();

const propTypes = {
  activePosition: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  count: PropTypes.number.isRequired,
  height: PropTypes.string,
};

const SliderStrip = ({ activePosition = 0, children, count, height }) => {
  const styles = {
    height: height || 'auto',
    lineHeight: 0,
    transform: `translateX(${(activePosition * -100) / count}%)`,
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'transform',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: `${100 * count}%`,
  };

  return (
    <div
      data-active={activePosition}
      data-component='SliderStrip'
      data-count={count}
      style={styles}
    >
      {children}
    </div>
  );
};

SliderStrip.propTypes = propTypes;

export default SliderStrip;
