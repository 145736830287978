import PropTypes from 'prop-types';

import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { noop } from 'lodash';

const propTypes = {
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const ProductDescription = ({ onClick = noop, description }) => {
  return (
    <Segment className='link' onClick={onClick}>
      <Header as='h4' content='Description' />
      <div className='content'>{description}</div>
    </Segment>
  );
};

ProductDescription.propTypes = propTypes;

export default ProductDescription;
