const automationReportsByStatusTestData = {
  count: 4,

  results: [
    {
      automation_status: 'automation',
      total: 50,
    },
    {
      automation_status: 'automation success',
      total: 20,
    },
    {
      automation_status: 'automation escalation',
      total: 10,
    },
    {
      automation_status: 'automation incomplete',
      total: 5,
    },
  ],
};

export default automationReportsByStatusTestData;
