import { connect } from 'react-redux';

import getRootDepartments from '../../selectors/getRootDepartments';
import getWiredDepartmentsByID from '../../selectors/getWiredDepartmentsByID';
import departmentsThunks from '../../actions/departmentsThunks';

const mapStateToProps = (state) => {
  const nodesByID = getWiredDepartmentsByID(state);
  const rootNodes = getRootDepartments(state);

  return {
    nodesByID,
    rootNodes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getChildrenNodes(parentID) {
    return dispatch(departmentsThunks.getChildrenNodes(parentID));
  },

  getDefaultSelectedNode(selectedNodeID) {
    return dispatch(departmentsThunks.getDefaultSelectedNode(selectedNodeID));
  },

  getRootNodes() {
    return dispatch(departmentsThunks.getRootNodes());
  },
});

// default React Redux mergeProps
const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps);

// tells React Redux we can wrap non pure components with this controller
const options = {
  pure: false,
};

const DepartmentSelectController = (component) =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, options)(component);

export default DepartmentSelectController;
