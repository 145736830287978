const SITEKEYS = {
  MOBILE: '6LeHXj8UAAAAAAbf0xoxsOK6jR7Izy92nrnq9of1',
  WEB: '6LdAZiYUAAAAABmt93He0pOWYAllsXn9KY7u4HYO', // should be onwith special permits to allow non-hosted domains
};
const RecaptchaConfig = {
  getSiteKey: () => {
    // At the moment we don't use it, but this code should support it
    // if we decide to enable recaptcha in cordova
    // See https://project.espressive.com/T7654
    if (window.cordova || window.electron) {
      return SITEKEYS.MOBILE;
    }
    return SITEKEYS.WEB;
  },
};

export default RecaptchaConfig;
