import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

const FormikWithSemanticUI = (setup) => (WrappedComponent) =>
  withFormik(setup)(
    class extends Component {
      static propTypes = {
        setFieldTouched: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
      };

      onHandleBlur = (e, data) => {
        const { setFieldValue, setFieldTouched } = this.props;
        if (data && data.name) {
          setFieldValue(data.name, data.value);
          setFieldTouched(data.name);
        }
      };

      onHandleChange = (e, data) => {
        if (data && data.name) {
          const { setFieldValue } = this.props;
          setFieldValue(data.name, data.value);
        }
      };

      render() {
        // Allow to get the Ref of the Wrapped component
        const newProps = {
          ...this.props,
        };
        if (newProps.setRef) {
          newProps.ref = newProps.setRef;
        }
        return (
          <WrappedComponent
            {...newProps}
            handleBlur={this.onHandleBlur}
            handleChange={this.onHandleChange}
          />
        );
      }
    }
  );

export default FormikWithSemanticUI;
