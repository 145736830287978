import { fromJS } from 'immutable';
import hashUtils from '../../../../app/js/v1/utils/hashUtils';

/**
 * Default frontend_blob for newly created tasks.
 * Defining it as immutable to prevent accidentally changing it in runtime.
 */
const defaultFrontendBlob = fromJS({
  blocks: [],

  nav: {
    background: {
      hide: true,
      id: hashUtils.createRandomId(),
      type: 'background',
    },
    espressiveLogo: false,
    footer: {
      hide: true,
      next: '',
      skip: '',
    },
    progress: false,
    toolbar: {
      hide: true,
      next: '',
      prev: '',
      title: '',
    },
  },
});

export default defaultFrontendBlob;
