// Actions
import bootstrapThunks from '../../actions/bootstrapThunks';
import wsActions from '../../actions/wsActions';

// Selectors
import getCurrentUser from '../../selectors/getCurrentUser';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
// Store
import store from '../../stores/store';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeWorkflow() {
    browserHistory.push(uiPathGenerator.genPath('app'));
  },

  doHelloActions() {
    dispatch(bootstrapThunks.appBootstrap()).then(() => {
      dispatch(wsActions.connect());
    });
  },

  onWorkflowTaskChange(isComplete, nextPath = '') {
    // Will move to specified next path or go directly to landing page
    nextPath = nextPath || uiPathGenerator.genLandingPath(store.getState());

    browserHistory.replace(nextPath);
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
