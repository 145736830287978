/* eslint-disable sort-keys -- backend shape */
const data = {
  results: [
    { country: '', total: 11, valid: false },
    { country: 'MEX', total: 1, valid: true },
  ],
  count: 2,
};
/* eslint-enable sort-keys */

export default data;
