//
// index.js
//
// This file is autogenerated from 'gulpSVG' gulp file.
// Template for this is in '/templates/index.handlebars'
//
// Please don't modify it by hand.
//
// If you need to add a new SVG, add the svg file in the images folder
// then run the gulp script: gulp --gulpfile ./gulpSvg.js
//
//
import React, { PureComponent } from 'react';
import AccountProfile from './AccountProfile';
import BenefitDeadline from './BenefitDeadline';
import BunsinessCard from './BunsinessCard';
import BusinessAccounts from './BusinessAccounts';
import ChatWith from './ChatWith';
import Checklist from './Checklist';
import ComplianceTask from './ComplianceTask';
import CorporateCreditCard from './CorporateCreditCard';
import DirectDeposit from './DirectDeposit';
import DownloadSoftware from './DownloadSoftware';
import EmailSetup from './EmailSetup';
import EmployeeID from './EmployeeID';
import EmployeePortrait from './EmployeePortrait';
import EnrollmentDeadline from './EnrollmentDeadline';
import GenericActivity from './GenericActivity';
import ImportantReading from './ImportantReading';
import InstallPrinter from './InstallPrinter';
import KickoffEvent from './KickoffEvent';
import MailingListOrCollaboration from './MailingListOrCollaboration';
import MeetingInvite from './MeetingInvite';
import OfficeSupplies from './OfficeSupplies';
import OnlineTraining from './OnlineTraining';
import OrderComputerEquipment from './OrderComputerEquipment';
import Orientation from './Orientation';
import PerformanceReviews from './PerformanceReviews';
import PhoneSetup from './PhoneSetup';
import PickUp from './PickUp';
import SecurityBadge from './SecurityBadge';
import ShopInCatalog from './ShopInCatalog';
import SubmitTimecard from './SubmitTimecard';
import TelephoneAccount from './TelephoneAccount';
import Travel from './Travel';
import TurnInDocuments from './TurnInDocuments';
import VPNSetup from './VPNSetup';
import WatchVideo from './WatchVideo';
import WebConferencing from './WebConferencing';

class OnboardActivities extends PureComponent {
  render() {
    const styleError = { color: '#a00' };
    return <p style={styleError}>{'ERROR: Please import a specific image.'}</p>;
  }
}

OnboardActivities.AccountProfile = AccountProfile;
OnboardActivities.BenefitDeadline = BenefitDeadline;
OnboardActivities.BunsinessCard = BunsinessCard;
OnboardActivities.BusinessAccounts = BusinessAccounts;
OnboardActivities.ChatWith = ChatWith;
OnboardActivities.Checklist = Checklist;
OnboardActivities.ComplianceTask = ComplianceTask;
OnboardActivities.CorporateCreditCard = CorporateCreditCard;
OnboardActivities.DirectDeposit = DirectDeposit;
OnboardActivities.DownloadSoftware = DownloadSoftware;
OnboardActivities.EmailSetup = EmailSetup;
OnboardActivities.EmployeeID = EmployeeID;
OnboardActivities.EmployeePortrait = EmployeePortrait;
OnboardActivities.EnrollmentDeadline = EnrollmentDeadline;
OnboardActivities.GenericActivity = GenericActivity;
OnboardActivities.ImportantReading = ImportantReading;
OnboardActivities.InstallPrinter = InstallPrinter;
OnboardActivities.KickoffEvent = KickoffEvent;
OnboardActivities.MailingListOrCollaboration = MailingListOrCollaboration;
OnboardActivities.MeetingInvite = MeetingInvite;
OnboardActivities.OfficeSupplies = OfficeSupplies;
OnboardActivities.OnlineTraining = OnlineTraining;
OnboardActivities.OrderComputerEquipment = OrderComputerEquipment;
OnboardActivities.Orientation = Orientation;
OnboardActivities.PerformanceReviews = PerformanceReviews;
OnboardActivities.PhoneSetup = PhoneSetup;
OnboardActivities.PickUp = PickUp;
OnboardActivities.SecurityBadge = SecurityBadge;
OnboardActivities.ShopInCatalog = ShopInCatalog;
OnboardActivities.SubmitTimecard = SubmitTimecard;
OnboardActivities.TelephoneAccount = TelephoneAccount;
OnboardActivities.Travel = Travel;
OnboardActivities.TurnInDocuments = TurnInDocuments;
OnboardActivities.VPNSetup = VPNSetup;
OnboardActivities.WatchVideo = WatchVideo;
OnboardActivities.WebConferencing = WebConferencing;

export default OnboardActivities;
