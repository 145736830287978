import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Message } from 'semantic-ui-react';
// Globals
import TypeCreateTeachBarista, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCreateTeachBarista';
import BuildTeachBaristaController from '../../controllers/BuildTeachBaristaController';

/**
 * This block is an empty component which allow
 * to build a BE scratch object for this FAQ
 */

class BuildTeachBarista01 extends PureComponent {
  static propTypes = {
    ...TypeCreateTeachBarista,
    beScratchData: ImmutablePropTypes.map,
    setTeachBaristaBEScratch: PropTypes.func.isRequired,
    taskId: PropTypes.number,
  };

  static defaultProps = {
    ...TypeDefaults,
    beScratchData: null,
    taskId: null,
  };

  componentDidMount() {
    const { beScratchData, setTeachBaristaBEScratch, taskId } = this.props;

    if (!beScratchData) {
      setTeachBaristaBEScratch(taskId);
    }
  }

  render() {
    const { errorsApi, taskId, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {errorsApi && !errorsApi.isEmpty() && (
          <Message
            content={errorsApi.getIn([0, 'message'])}
            header='Error'
            negative
          />
        )}

        {!taskId ? (
          <Message
            content='No taskId has been passed in the query parameters'
            header='Error'
            negative
          />
        ) : null}
      </div>
    );
  }
}

export const BuildTeachBarista01Test = BuildTeachBarista01;

export default BuildTeachBaristaController(BuildTeachBarista01);
