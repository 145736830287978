const api_workflow_v0_1_workflowtask = {
  api_path: '/api/v0.1/report9/',
  app_job_eid: null,
  counter: 0,
  eid: 'db0e9681-e38f-4078-a34f-0f892571b364',
  id: 18,
  result_details: null,
  status: 'SCHEDULED',
  sys_created_by: 'admin@espressive.com',
  sys_custom_fields: {
    job_name: 'report9_csv',
    options: {
      end_date: '12-12-2022',
      header: ['eid', 'sys_custom_field'],
      start_date: '11-11-2022',
    },
    user_id: 2,
  },
  sys_date_created: '2022-06-06T16:51:22.026610Z',
  sys_date_updated: '2022-06-06T16:51:22.026537Z',
  sys_updated_by: 'admin@espressive.com',
  total_count: 0,
  url: 'http://tenant1.esp:8000/api/common/v0.1/job_status/18/',
};
export default api_workflow_v0_1_workflowtask;
