import buildCompositeController from '../../utils/buildCompositeController';
import adminBundleThunks from '../../actions/adminBundleThunks';
import getBundleImages from '../../selectors/getBundleImages';
import getOtherTwoImages from '../../selectors/getOtherTwoImages';

const mapStateToProps = (state, { bundle }) => ({
  images: getBundleImages(state, {
    bundle,
  }),
  otherTwoImages: getOtherTwoImages(state, {
    bundle,
  }),
});

const mapDispatchToProps = (dispatch, { bundle }) => ({
  loadImages() {
    dispatch(adminBundleThunks.loadBundleImages(bundle.get('items')));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
