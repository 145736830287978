import actionTypes from './actionTypes';

const tenantActions = {};

tenantActions.getTenantStart = () => ({
  type: actionTypes.GET_TENANT_START,
});

tenantActions.getTenantDomainStart = () => ({
  type: actionTypes.GET_TENANT_DOMAIN_START,
});

tenantActions.getTenantSuccess = (tenant) => ({
  tenant,
  type: actionTypes.GET_TENANT_SUCCESS,
});

tenantActions.getTenantFail = (error) => ({
  error,
  type: actionTypes.GET_TENANT_FAIL,
});

tenantActions.getTenantDomainSuccess = (domain) => ({
  domain,
  type: actionTypes.GET_TENANT_DOMAIN_SUCCESS,
});

tenantActions.getTenantDomainFail = (error) => ({
  error,
  type: actionTypes.GET_TENANT_DOMAIN_FAIL,
});

tenantActions.getTenantImagesStart = (keepCurrentImages = false) => ({
  keepCurrentImages,
  type: actionTypes.GET_TENANT_IMAGES_START,
});

tenantActions.getTenantImagesSuccess = (
  tenantImages,
  timestamp = Date.now()
) => ({
  tenantImages,
  timestamp,
  type: actionTypes.GET_TENANT_IMAGES_SUCCESS,
});

tenantActions.getTenantImagesFail = (error) => ({
  error,
  type: actionTypes.GET_TENANT_IMAGES_FAIL,
});

tenantActions.getTenantOneImageStart = (imgType) => ({
  imgType,
  type: actionTypes.GET_TENANT_ONE_IMAGE_START,
});

tenantActions.getTenantOneImageSuccess = (
  tenantImage,
  imgType,
  timestamp = Date.now()
) => ({
  imgType,
  tenantImage,
  timestamp,
  type: actionTypes.GET_TENANT_ONE_IMAGE_SUCCESS,
});

tenantActions.getTenantOneImageFail = (error, imgType) => ({
  error,
  imgType,
  type: actionTypes.GET_TENANT_ONE_IMAGE_FAIL,
});

tenantActions.removeTenantImagesStart = (imageID) => ({
  imageID,
  type: actionTypes.REMOVE_TENANT_IMAGES_START,
});

tenantActions.removeTenantImagesSuccess = (imageID) => ({
  imageID,
  type: actionTypes.REMOVE_TENANT_IMAGES_SUCCESS,
});

tenantActions.removeTenantImagesFail = () => ({
  type: actionTypes.REMOVE_TENANT_IMAGES_FAIL,
});

tenantActions.updateTenantStart = () => ({
  type: actionTypes.UPDATE_TENANT_START,
});

tenantActions.updateTenantSuccess = (updatedTenant) => ({
  type: actionTypes.UPDATE_TENANT_SUCCESS,
  updatedTenant,
});

tenantActions.updateTenantFail = (error) => ({
  error,
  type: actionTypes.UPDATE_TENANT_FAIL,
});

tenantActions.uploadTenantImageStart = () => ({
  type: actionTypes.UPLOAD_TENANT_IMAGE_START,
});

tenantActions.uploadTenantImageSuccess = (tenantImages) => ({
  tenantImages,
  type: actionTypes.UPLOAD_TENANT_IMAGE_SUCCESS,
});

tenantActions.uploadTenantImageFail = (error) => ({
  error,
  type: actionTypes.UPLOAD_TENANT_IMAGE_FAIL,
});

tenantActions.changeTenantNotFound = (errorMsg) => ({
  errorMsg,
  type: actionTypes.CHANGE_TENANT_NOT_FOUND,
});

tenantActions.loadMenuConfigurationStart = () => ({
  type: actionTypes.GET_TENANT_CONFIG_MENU_START,
});

tenantActions.loadMenuConfigurationFail = () => ({
  type: actionTypes.GET_TENANT_CONFIG_MENU_FAIL,
});

tenantActions.loadMenuConfigurationSuccess = (data) => ({
  data,
  type: actionTypes.GET_TENANT_CONFIG_MENU_SUCCESS,
});

tenantActions.setTenantMenuConfigSuccess = () => ({
  type: actionTypes.SET_TENANT_CONFIG_MENU_SUCCESS,
});

tenantActions.loadOneMenuConfigurationSuccess = (data) => ({
  data,
  type: actionTypes.GET_TENANT_CONFIG_ONE_MENU_SUCCESS,
});

tenantActions.setTenantMenuConfigStart = () => ({
  type: actionTypes.SET_TENANT_CONFIG_MENU_START,
});

tenantActions.setTenantMenuConfigFail = () => ({
  type: actionTypes.SET_TENANT_CONFIG_MENU_FAIL,
});

tenantActions.loadLabelConfigurationStart = () => ({
  type: actionTypes.GET_TENANT_CONFIG_LABEL_START,
});

tenantActions.loadLabelConfigurationFail = () => ({
  type: actionTypes.GET_TENANT_CONFIG_LABEL_FAIL,
});

tenantActions.loadLabelConfigurationSuccess = (data) => ({
  data,
  type: actionTypes.GET_TENANT_CONFIG_LABEL_SUCCESS,
});

tenantActions.setTenantLabelConfigStart = () => ({
  type: actionTypes.SET_TENANT_CONFIG_LABEL_START,
});

tenantActions.setTenantLabelConfigFail = () => ({
  type: actionTypes.SET_TENANT_CONFIG_LABEL_FAIL,
});

tenantActions.setTenantOneLabelConfigSuccess = (data) => ({
  data,
  type: actionTypes.SET_TENANT_CONFIG_ONE_LABEL_SUCCESS,
});

tenantActions.deleteTenantOneLabelConfigSuccess = (key) => ({
  key,
  type: actionTypes.DELETE_TENANT_CONFIG_LABEL_SUCCESS,
});

tenantActions.setTenantLabelConfigSuccess = () => ({
  type: actionTypes.SET_TENANT_CONFIG_LABEL_SUCCESS,
});

tenantActions.uploadFileStart = () => ({
  type: actionTypes.UPLOAD_TENANT_FILE_START,
});

tenantActions.uploadFileFail = () => ({
  type: actionTypes.UPLOAD_TENANT_FILE_FAIL,
});

tenantActions.uploadFileSuccess = () => ({
  type: actionTypes.UPLOAD_TENANT_FILE_SUCCESS,
});

tenantActions.clearTenant = () => ({
  type: actionTypes.CLEAR_TENANT,
});

tenantActions.loadAllowedExtensionStart = () => ({
  type: actionTypes.LOAD_ALLOWED_EXTENSION_START,
});

tenantActions.loadAllowedExtensionFail = () => ({
  type: actionTypes.LOAD_ALLOWED_EXTENSION_FAIL,
});

tenantActions.loadAllowedExtensionSuccess = (value) => ({
  type: actionTypes.LOAD_ALLOWED_EXTENSION_SUCCESS,
  value,
});

tenantActions.loadUserGroupsStart = () => ({
  type: actionTypes.ADD_TENANT_USERS_GROUP_START,
});

tenantActions.loadUserGroupsFail = () => ({
  type: actionTypes.ADD_TENANT_USERS_GROUP_FAIL,
});

tenantActions.loadUserGroupsSuccess = (groups) => ({
  groups,
  type: actionTypes.ADD_TENANT_USERS_GROUP_SUCCESS,
});

export default tenantActions;
