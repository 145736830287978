import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon, Menu, Modal } from 'semantic-ui-react';
import ConnectionSettingsController from './ConnectionSettingsController';

class TestingConnectionModal extends Component {
  static propTypes = {
    credentials: PropTypes.shape({}).isRequired,

    disabled: PropTypes.bool,

    isConnectionValid: PropTypes.bool,

    isSubmitting: PropTypes.bool,

    isTestingCredentials: PropTypes.bool,

    setCredentials: PropTypes.func.isRequired,

    testCredentials: PropTypes.func.isRequired,

    values: PropTypes.shape({}),
  };
  static defaultProps = {
    disabled: false,
    isConnectionValid: false,
    isSubmitting: false,
    isTestingCredentials: false,
    values: {},
  };
  state = {
    isOpen: false,
  };
  handleTestCredentials = () => {
    const { values, testCredentials, credentials } = this.props;
    testCredentials(values, credentials);
  };
  handleOpen = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };
  handleSubmit = () => {
    const { values, setCredentials, credentials } = this.props;
    setCredentials(values, credentials);
  };
  renderContentBasedOnCredentialsTest = () => {
    const { isTestingCredentials, isConnectionValid } = this.props;
    let component = null;
    if (isTestingCredentials) {
      component = (
        <Grid.Column>
          <Button basic disabled inverted loading />
          <span>{'Testing Authentication Settings'}</span>
        </Grid.Column>
      );
    }
    if (!isConnectionValid && !isTestingCredentials) {
      component = (
        <Grid.Column>
          <Icon circular color='red' inverted name='exclamation' />

          <span style={{ color: 'red' }}>
            {'Authentication failed. Check your credentials.'}
          </span>
        </Grid.Column>
      );
    }
    if (isConnectionValid && !isTestingCredentials) {
      component = component = (
        <Grid.Column>
          <Icon circular color='green' inverted name='checkmark' />
          <span style={{ color: 'green', paddingLeft: '1em' }}>
            {'Settings test passed '}
          </span>
        </Grid.Column>
      );
    }

    return (
      <Grid as={Modal.Content} padded verticalAlign='middle'>
        <Grid.Row>{component}</Grid.Row>
      </Grid>
    );
  };
  defineActionsBasedOnCredentials = () => {
    const {
      isConnectionValid,
      isTestingCredentials,
      isSubmitting,
    } = this.props;
    let actions = [];
    if (isTestingCredentials || !isConnectionValid) {
      actions = (
        <Button basic content='cancel' key={'2'} onClick={this.handleOpen} />
      );
    }
    if (isConnectionValid) {
      actions = (
        <Button
          content='save and configure sync'
          key={'1'}
          loading={isSubmitting}
          onClick={this.handleSubmit}
          primary
        />
      );
    }
    return [actions];
  };
  render() {
    const modalStyle = {
      borderRadius: 'initial',
      top: '30%',
    };
    const { isOpen } = this.state;
    const { disabled } = this.props;

    return (
      <Modal
        actions={this.defineActionsBasedOnCredentials()}
        closeIcon={<Menu.Item className='close'>{'Exit'}</Menu.Item>}
        content={this.renderContentBasedOnCredentialsTest()}
        header='Testing Authentication'
        onClose={this.handleOpen}
        onOpen={this.handleTestCredentials}
        open={isOpen}
        size='mini'
        style={modalStyle}
        trigger={
          <Button
            basic
            content='test connection'
            disabled={disabled}
            onClick={this.handleOpen}
            primary
            type='button'
          />
        }
      />
    );
  }
}
export default ConnectionSettingsController(TestingConnectionModal);
