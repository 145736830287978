import { UserAgentUtils } from 'esp-globals';
import { get } from 'lodash';
/**
 * Use this to open external URLS in any platform, including native
 * @param {*} url download URL
 * @param {*} windowReference IMPORTANT - if you use this function in async code, you have to pass a windowRef of window.open() created OUTSIDE the async code. See ConversationFeedController for example
 */
const openExternalUrlInNative = (url = '', windowReference) => {
  // DEV-11669
  // We need to check if url starts with http or https
  // otherwise window.open regards it as relative url
  // https://stackoverflow.com/questions/23373990/window-open-simply-adds-the-url-to-my-current-url
  const startsWithHttp = /^((http|https):\/\/)/;
  const isEmailToLink = /mailto:[^\s]+@[^\s]+\.[^\s]+/i;
  const containsMailto = isEmailToLink.test(url);
  if (!startsWithHttp.test(url) && !containsMailto) {
    // append https if it doesnt have it
    url = `https://${url}`;
  }

  if (window.cordova) {
    if (containsMailto) {
      if (UserAgentUtils.isIOSApp()) {
        window.cordova.InAppBrowser.open(url, '_system');
      } else {
        window.open(url, '_system', 'location=yes');
      }
    } else {
      if (UserAgentUtils.isIOSApp() && windowReference) {
        // only do this in iOS cordova app and if there's a windowReference
        // initialized outside asynchronously
        windowReference.location = url;
      } else {
        window.open(url, '_self');
      }
    }
  } else if (window.electron) {
    const href = url.split('.');
    let extension, filename;

    for (let i = href.length; i--; i > 0) {
      if (href[i].match(/[?]/)) {
        filename = href[i - 1].split('/');
        filename = filename[filename.length - 1];
        const [splitExtension] = href[i].split('?');
        extension = splitExtension;
      }
    }
    // File download
    if (filename) {
      window.electronAPI
        .showSaveDialog({
          defaultPath: `${filename}.${extension}`,
        })
        .then((filePath) => {
          if (filePath) {
            const isHttps = url.indexOf('https://') === 0;
            // we have to do this because the https util doesn't load HTTP
            // and the http doesn't laod HTTPS 🙃
            const http = isHttps
              ? window.require('https')
              : window.require('http');
            const fs = window.require('fs');
            const file = fs.createWriteStream(filePath);

            http.get(url, (response) => {
              response.pipe(file);
            });
          }
        });
    } else {
      // External link
      window.open(url, '_system');
    }
  } else {
    // web
    // Safari is blocking any call to window.open() which is made inside an async call.
    // See https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    if (UserAgentUtils.isIOS() && windowReference) {
      windowReference.location = url;
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Opening attachemnts in mobile safari will not work if openExternalUrlInNative is called in an async function'
      );
    } else {
      // Any other browser
      // esp_download must be used as <a> target if this is opened in a <a> link
      window.open(url, 'esp_download');
    }
  }
};

// externalDownloadHandler
// Use this to capture "Click" events containing an event object
//
const externalDownloadHandler = (e) => {
  if (window.cordova || (window.electron && e.target.download)) {
    e.preventDefault && e.preventDefault();
    if (get(e, 'target.href')) {
      openExternalUrlInNative(e.target.href);
    } else {
      // eslint-disable-next-line no-console -- debugging
      console.error(
        'Opened externalDownloadHandler with no valid href in event object'
      );
    }
  }
};

export { openExternalUrlInNative };
export default externalDownloadHandler;
