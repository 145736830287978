import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  enablement: {
    default: false,
    type: 'bool',
  },

  hr_contact: {
    default: false,
    type: 'bool',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  hr_recruiter: {
    default: false,
    type: 'bool',
  },

  it_contact: {
    default: false,
    type: 'bool',
  },
  label_enablement: {
    default: '',
    type: 'string',
  },
  label_hr_contact: {
    default: '',
    type: 'string',
  },
  label_hr_recruiter: {
    default: '',
    type: 'string',
  },
  label_it_contact: {
    default: '',
    type: 'string',
  },
  label_operations: {
    default: '',
    type: 'string',
  },
  operations: {
    default: false,
    type: 'bool',
  },
  readOnly: {
    default: false,
    type: 'bool',
  },
  template: {
    default: 'selectUserContactGroup01',
  },
});

// Object Definition used by blobifier
const selectUserContactGroupDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Select User Contact Group',
  templates: ['selectUserContactGroup01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, selectUserContactGroupDefinition, TypeDefaults };
