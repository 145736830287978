class KbParser {
  constructor(kb, url, query = {}) {
    this.kb = kb;
    this.query = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');
    this.url = url;
  }

  // Regex Needed
  attrRegex = /<a.*?href=['"](.*?)['"]|src=['"](.*?)['"]/;
  linkRegex = /<a.*?href=['"](.*?)['"]/;
  isUrl = new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
  );

  // Check if either src or href attr is an absolute path, if not, replace it accordingly
  replaceRelativeUrls = (match, link, imgSrc) => {
    const { isUrl, linkRegex, query, url } = this;
    const isLink = linkRegex.test(match);
    const content = isLink ? link : imgSrc;
    const isAbsolute = isUrl.test(content);
    const relativeReplace = isLink
      ? `<a href="${url + content}"`
      : `src="${url}?url=${content}&${query}"`;
    return isAbsolute ? match : relativeReplace;
  };

  replaceAll = (str, rgx) =>
    str.replace(new RegExp(rgx, 'g'), this.replaceRelativeUrls);

  // Parse the whole document
  parseKb = () => {
    const { attrRegex, kb, replaceAll } = this;
    return replaceAll(kb, attrRegex);
  };
}

export default KbParser;
