import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Button, Header, List, Message } from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

// Molecules
import EspListItem from '../molecules/EspListItem';
// Utils
import getOverdueDate from '../../utils/getOverdueDate';
// Globals
import OnboardActivitiesCategory from '../../globals/OnboardActivitiesCategory';
// v2 Component
import { Boundaries } from 'cascara-middleware';

const EXPANSION_TRESHOLD = 3;

class EspActivityList extends Component {
  static propTypes = {
    activities: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    isNewHire: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    activities: immutable.List(),
    isNewHire: false,
    title: '',
  };

  state = {
    isExpanded: false,
  };

  componentDidMount() {
    this.updateState();
  }

  UNSAFE_componentWillReceiveProps() {
    // ToDo: this has to be largely revised DEV-9978
    this.updateState();
  }

  updateState() {
    this.setState((previous, props) => {
      const { activities } = props;
      const items = activities.filter((a) => a && !a.get('template').isEmpty());
      return {
        isExpanded: false,
        items,
      };
    });
  }

  createListItem = (item) => {
    const { isNewHire, title } = this.props;

    const { template } = item.get('template');

    const meta =
      title !==
      intl.formatMessage({
        id: 'label.completed',
      })
        ? getOverdueDate(item, intl)
        : null;
    const attachedHeader =
      isNewHire && item.has('user_requested')
        ? `New Hire: ${item.getIn(['user_requested', 'nickname'])} ${item.getIn(
            ['user_requested', 'last_name']
          )}`
        : template.get('private_notes');

    return (
      <EspListItem
        attachedHeader={attachedHeader}
        description={template.get('description')}
        header={template.get('title')}
        image={
          OnboardActivitiesCategory.getIn([
            template.get('category'),
            'image',
          ]) || null
        }
        key={item.get('key')}
        meta={meta}
      />
    );
  };

  handleExpand = (e) => {
    e.preventDefault();
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  };

  render() {
    const { activities, isNewHire, title } = this.props;

    const { isExpanded } = this.state;

    const items = activities.filter((a) => {
      if (a) {
        const template = a.get('template');
        return template && !template.isEmpty();
      }
      return false;
    });
    const count = items.size;
    let children = null;

    if (!count) {
      if (_.isEmpty(title)) {
        return null;
      }
      children = (
        <List.Item>
          <Message content={`No tasks are ${title}`} info />
        </List.Item>
      );
    } else {
      children = [];

      const takenItems = items.take(
        isExpanded ? count : EXPANSION_TRESHOLD - 1
      );

      takenItems.forEach((item) => {
        const template = item.get('template');
        const meta =
          title !==
          intl.formatMessage({
            id: 'label.completed',
          })
            ? getOverdueDate(item, intl)
            : null;
        const attachedHeader =
          isNewHire && item.has('user_requested')
            ? `New Hire: ${item.getIn([
                'user_requested',
                'nickname',
              ])} ${item.getIn(['user_requested', 'last_name'])}`
            : template.get('private_notes');

        children.push(
          <EspListItem
            attachedHeader={attachedHeader}
            description={template.get('description')}
            header={template.get('title')}
            image={
              OnboardActivitiesCategory.getIn([
                template.get('category'),
                'image',
              ]) || null
            }
            key={item.get('key') || item.get('id')}
            meta={meta}
          />
        );
      });

      if (!isExpanded && count >= EXPANSION_TRESHOLD) {
        children.push(
          <List.Item key='expand_button'>
            <Button
              animated
              basic
              content={'Load More'}
              fluid
              onClick={this.handleExpand}
              primary
            />
          </List.Item>
        );
      }
    }

    const subheader = count ? (
      <Header
        content={count}
        floated='right'
        style={{
          marginTop: '.25em',
        }}
        sub
      />
    ) : null;

    return (
      <Boundaries>
        <List divided relaxed='very' verticalAlign='middle'>
          <List.Item>
            <Header content={title} subheader={subheader} />
          </List.Item>
          {children}
        </List>
      </Boundaries>
    );
  }
}

export default EspActivityList;
