import _ from 'lodash';

// Utils
import PathParam from './PathParam';
import { numericOnly } from './PathParamsValidators';

/**
 * Returns a type cast function for a path param based on it's validators.
 *
 * @param pathParam An instance of PathParam.
 */
const getPathParamTypeCastFn = (pathParam) => {
  if (!(pathParam instanceof PathParam)) {
    throw new Error('Argument pathParam must be an instance of PathParam.');
  }

  const validators = pathParam.getValidators();

  if (validators.includes(numericOnly)) {
    return Number;
  }

  return _.identity;
};

export default getPathParamTypeCastFn;
