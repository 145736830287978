import React, { Component } from 'react';

import _ from 'lodash';
import { Button } from 'semantic-ui-react';
// v2
import EspModal from '../../display/EspModal';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
import UserSelectSearch from '../../display/UserSelect/UserSelectSearch';

class UserSelect extends Component {
  static propTypes = {
    content: PropTypes.element.isRequired,
    defaultOpen: PropTypes.bool,
    disableContentScroll: PropTypes.bool,
    header: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.string,
    ]),
    isLoadingSearch: PropTypes.bool,
    isSearchable: PropTypes.bool,
    label: PropTypes.string,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onSearch: PropTypes.func,
    onUserSelect: PropTypes.func, // From component props
    open: PropTypes.bool,
    pickTeam: PropTypes.bool,
    placeholder: PropTypes.string,

    selectButtonEnabled: PropTypes.bool,

    trigger: PropTypes.element,
  };

  static defaultProps = {
    defaultOpen: void 0,
    disableContentScroll: false,
    header: null,
    isLoadingSearch: false,
    isSearchable: true,
    label: null,
    onClose: _.noop,
    onOpen: _.noop,
    onSearch: _.noop,
    onUserSelect: _.noop,
    open: void 0,
    pickTeam: null,
    placeholder: null,
    selectButtonEnabled: false,
    trigger: null,
  };

  render() {
    const {
      content,
      defaultOpen,
      disableContentScroll,
      header,
      isLoadingSearch,
      isSearchable,
      label,
      onClose,
      onOpen,
      onSearch,
      onUserSelect,
      open,
      placeholder,
      pickTeam,
      trigger,
      selectButtonEnabled,
    } = this.props;

    const actions = (
      <Button
        content={intl.formatMessage({
          id: 'label.select',
        })}
        disabled={!selectButtonEnabled}
        onClick={onUserSelect}
      />
    );

    const preScrollContent =
      isSearchable && !pickTeam ? (
        <UserSelectSearch
          isLoading={isLoadingSearch}
          label={label}
          onSearch={onSearch}
          placeholder={placeholder}
        />
      ) : null;

    // This user picker previously referenced this EspModal with a ref so that
    // it could try to close the Modal component inside of it in an incorrect,
    // non-React way:
    // ref={this.setRef}
    // This had to be changed to an innerRef so functionally closing that modal
    // will work until this whole thing can stop. - bje 9/16/2019

    return (
      <EspModal
        actions={actions}
        content={content}
        contentPadding='0 1em 1em'
        defaultOpen={defaultOpen}
        disableContentScroll={disableContentScroll}
        header={header}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        preScrollContent={preScrollContent}
        preScrollContentHeight={preScrollContent ? 73 : 0}
        trigger={trigger}
      />
    );
  }
}

export default UserSelect;
