import { fromJS } from 'immutable';
import _ from 'lodash';

// Redux
import sessionThunks from '../../actions/sessionThunks';
import getCurrentUser from '../../selectors/getCurrentUser';
import store from '../../stores/store';
// Default Images
import { ImageDefaults } from 'esp-assets';
// Global
import UserImagesTypes from '../../globals/UserImageTypes';
// Utils
import ExifRotation from '../../utils/ExifRotation';
// Compose Controller
import buildCompositeController from '../../utils/buildCompositeController';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import { getMimeTypeFromBase64String } from 'esp-globals';

const mapStateToProps = (state, ownProps) => {
  const currentUser = getCurrentUser(state);
  const isLoading = state.getIn(['session', 'isLoadingUserImage']);

  let avatarUser = null,
    idAvatar = null;
  const imgUser = currentUser.get('images');

  if (imgUser) {
    const profileImg = imgUser.find(
      (img) => img.get('type') === UserImagesTypes.PROFILE
    );
    avatarUser = profileImg ? profileImg.get('picture') : null;
    idAvatar = profileImg ? profileImg.get('id') : null;
  }

  avatarUser = avatarUser ? avatarUser : ImageDefaults.USER_AVATAR;

  const initialValues = {};

  initialValues[ownProps.id] = {
    goodToSend: false,
    id: idAvatar,
    imgPrevUrl: avatarUser,
    picture: null,
  };

  return {
    currentUser,
    initialValues,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  let currentUsr = null,
    finalData = null,
    img = {
      goodToSend: false,
      imgPrevUrl: null,
      picture: null,
    };

  const convertFormData = (data) => {
    const base = {
      caption: "''",
      id: data.id,
      ordering: 0,
      picture: data.picture,
      sys_custom_fields: null,
      type: data.type,
      user: currentUsr.get('url'),
    };

    // Create and populate a formdata
    const finalData = new FormData();
    for (const key in base) {
      if (key === 'picture') {
        finalData.append(key, base[key], 'blob.jpeg');
      } else {
        finalData.append(key, base[key]);
      }
    }
    return finalData;
  };

  const dataURItoBlob = (imgData) => {
    const byteString = atob(imgData.split(',')[1]);
    const mimeString = getMimeTypeFromBase64String(imgData);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {
      type: mimeString,
    });
  };

  let imgPreviewChanged = false;

  return {
    deleteUserImage: (type, fn = _.noop) => {
      // Get id picture if exists. If not, a new img will be create on the server
      const userImage = getCurrentUser(store.getState())
        .get('images')
        .filter((img) => type === img.get('type'))
        .first();

      if (userImage && userImage.get('id')) {
        dispatch(sessionThunks.deleteUserImage(userImage.get('id')));
      }

      fn();
    },

    getImgPreview: () => img,

    getUserImg: () => getCurrentUser(store.getState()).get('images'),

    hasChanged: () => imgPreviewChanged,

    onAttachmentChange: (props, file, usr, setRotation) => {
      const {
        input: { onChange },
        currentUser,
      } = props;

      currentUsr = usr || currentUser;

      img = {
        goodToSend: false,
        imgPrevUrl: null,
        picture: null,
      };

      if (file && file.type && file.type.split('/')[0] === 'image') {
        img.picture = file;
        img.goodToSend = true;
      }

      const reader = new FileReader();

      // Load base64 image
      reader.onloadend = () => {
        // Prevent bad file type
        if (!img.goodToSend) {
          return;
        }
        img.imgPrevUrl = reader.result;

        //
        // Load the image and check the Exif data
        // The callback return rotation value to apply to the canvas
        //
        ExifRotation(file, (value) => {
          // Prevent bad file type
          if (!img.goodToSend) {
            return;
          }

          // img.imgPrevUrl = base64img;
          imgPreviewChanged = true;

          finalData = {
            id: img.id,
            type: UserImagesTypes.PROFILE,
          };

          // Pass the img to redux form
          onChange(fromJS(img.picture));

          // Set rotation get from the Exif data
          setRotation(value || 0);
        });
      };

      reader.readAsDataURL(file);
    },

    onAttachmentChangeBackgrd: (props, file, usr) => {
      const { onSubmitPreset, currentUser } = props;

      currentUsr = usr || currentUser;

      const reader = new FileReader();

      reader.onloadend = (img) => {
        const image = new window.Image();

        image.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          // Logic to resize canvas size
          if (this.naturalWidth > this.naturalHeight) {
            if (this.naturalWidth > 640) {
              canvas.width = 640;
              canvas.height = (this.naturalHeight * 640) / this.naturalWidth;
            }
          } else {
            if (this.naturalHeight > 640) {
              canvas.height = 640;
              canvas.width = (this.naturalWidth * 640) / this.naturalHeight;
            }
          }
          canvas
            .getContext('2d')
            .drawImage(this, 0, 0, canvas.width, canvas.height);

          const blob = canvas.toDataURL('image/png');

          onSubmitPreset(blob, currentUser);
        };
        image.src = img.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    onSubmitBackgrd: (data) => {
      const finaldata = {
        data: convertFormData(data),
        id: data.id || null,
      };

      dispatch(sessionThunks.updteUserImages(finaldata));
    },

    onSubmitImg: ({
      e,
      data = null,
      savedType = null,
      currentUser = null,
      fn,
    }) => {
      e.preventDefault();

      currentUsr = currentUser || currentUsr;

      if (!finalData && !savedType) {
        return;
      }

      const finalImg = finalData || savedType;

      // Convert canvas to dataUrl
      const dataURL = data.toDataURL('image/jpeg', 1);

      finalImg.picture = dataURItoBlob(dataURL);

      let idImg = null;

      // Get id picture if exists. If not, a new img will be create on the server
      const imagesUsr = getCurrentUser(store.getState()).get('images');
      if (imagesUsr) {
        imagesUsr.forEach((img) => {
          if (finalImg.type === img.get('type')) {
            idImg = img.get('id');
          }
        });
      }

      finalData = {
        data: convertFormData(finalImg),
        id: idImg,
      };

      // Send the new img
      dispatch(sessionThunks.updteUserImages(finalData)).then(() => {
        finalData = null;
        // Pass the new Cropped img to avoid a jump effect
        fn(dataURL);
      });
    },

    onSubmitPreset: (data, currentUser) => {
      if (!data) {
        return;
      }
      currentUsr = currentUser;

      const img = {
        goodToSend: false,
        id: null,
        picture: dataURItoBlob(data),
        type: UserImagesTypes.BACKGROUND,
      };

      // Get id picture if exists. If not, a new img will be create on the server
      currentUser.get('images') &&
        currentUser.get('images').forEach((image) => {
          if (img.goodToSend) {
            return;
          }
          if (image.get('type') === UserImagesTypes.BACKGROUND) {
            img.id = image.get('id');
            img.goodToSend = true;
          }
        });

      finalData = {
        data: convertFormData(img),
        id: img.id,
      };

      dispatch(sessionThunks.updteUserImages(finalData));
      finalData = null;
    },

    resetHasChanged: () => {
      imgPreviewChanged = false;
    },

    resetImgPreview: () => {
      imgPreviewChanged = false;
      img = {
        goodToSend: false,
        imgPrevUrl: null,
        picture: null,
      };
    },

    sendErrorMsg: (msg) => {
      dispatch(
        toastNotificationsActions.error({
          message: msg.message,
          title: msg.header,
        })
      );
    },
  };
};

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
