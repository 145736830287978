import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import TypeText, { TypeDefaults } from './TypeText';

const rule = () => ({
  overflowWrap: 'break-word',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
});

class Text01 extends PureComponent {
  static propTypes = TypeText;
  static defaultProps = TypeDefaults;

  render() {
    const { text, type, template, className } = this.props;

    return (
      <div className={className} data-blocktype={type} data-template={template}>
        {text}
      </div>
    );
  }
}

export default createComponent(rule, Text01, [
  'text',
  'template',
  'type',
  'trigger',
]);
