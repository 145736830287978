import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';
import Immutable from 'immutable';
import { curry, memoize } from 'lodash';

// Atoms
import DragDrop from '../../../../../../admin/js/v1/components/atoms/DragDrop';
import SaveButton from '../../../../../../admin/js/v1/components/atoms/SaveButton';
// Molecule
import AddConditionForm from '../../molecules/AddConditionForm';
// Blocks
import BlockForm from '../../../../../../admin/js/v1/components/blocks/forms/BlockForm';
import WorkflowTaskEditorPageController from '../../../../../../admin/js/v1/components/controllers/WorkflowTaskEditorPageController';
// Organisms
import ModalEsp from '../../../../../../admin/js/v1/components/organisms/ModalEsp';
import NavFormEditor from '../../../../../../admin/js/v1/components/organisms/NavFormEditor';
import WorkflowTaskModal from '../../organisms/WorkflowTaskModal';
// Utils
import browserHistory from '../../../utils/browserHistory';
import uiPathGenerator from '../../../utils/uiPathGenerator';
// Globals
import { BlockDefinitionMap } from '../../../globals/BlockTypes';
import BrowserPrompt from '../../../globals/BrowserPrompt';
// Controllers
import AddAttributeModalController from '../../controllers/AddAttributeModalController';

// Page workflow
// import PreviewTask                    from '../flow/PreviewTask';

// Menu Items
const menuItems = {
  BLOCK: 'Block',
  CONDITIONS: 'Conditions',
  CREATE_CONDTIONS: 'Create Conditions',
};

class WorkflowTaskEditorPage extends PureComponent {
  static propTypes = {
    addBlock: PropTypes.func.isRequired,
    addOptionToBlockAttribute: PropTypes.func.isRequired,
    conditionIsDirty: PropTypes.bool,
    conditionIsPristine: PropTypes.bool,
    createFinalCondition: PropTypes.func.isRequired,
    currentEditingTask: ImmutablePropTypes.map,
    getTask: PropTypes.func.isRequired,
    rearrangeBlocks: PropTypes.func.isRequired,
    removeAttribute: PropTypes.func.isRequired,
    removeBlock: PropTypes.func.isRequired,
    removeOptionFromBlockAttribute: PropTypes.func.isRequired,
    saveConditions: PropTypes.func.isRequired,
    saveStatus: PropTypes.shape({
      taskFormHasErrors: PropTypes.bool,
      taskFormIsChanged: PropTypes.bool,
      taskFormSaveInProgress: PropTypes.bool,
    }).isRequired,
    saveTask: PropTypes.func.isRequired,
    selectCurrentEditingTask: PropTypes.func.isRequired,
    tasks: ImmutablePropTypes.map,
    workflowID: PropTypes.number.isRequired,
    //  showPreview                   : PropTypes.bool,
    workflowTaskID: PropTypes.number.isRequired,
    //  togglePreview                 : PropTypes.func
  };

  static defaultProps = {
    conditionIsDirty: false,
    conditionIsPristine: false,
    currentEditingTask: null,
    tasks: null,
  };

  state = {
    activeItem: menuItems.BLOCK,
    createCondition: false,
  };
  /**
   * Lyfecycle hooks
   */
  componentDidMount() {
    const {
      tasks,
      workflowID,
      workflowTaskID,
      getTask,
      selectCurrentEditingTask,
    } = this.props;

    if (!tasks.get('items') || tasks.get('items').isEmpty()) {
      getTask(workflowID, workflowTaskID);
    } else {
      selectCurrentEditingTask(workflowTaskID);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeItem, createCondition } = this.state;
    const { currentEditingTask } = this.props;

    if (
      activeItem === menuItems.BLOCK &&
      createCondition &&
      currentEditingTask &&
      nextProps.currentEditingTask &&
      nextProps.currentEditingTask.get('related_workflow_condition') !==
        currentEditingTask.get('related_workflow_condition')
    ) {
      this.setState({
        activeItem: menuItems.CONDITIONS,
        createCondition: false,
      });
    }
  }

  componentDidUpdate() {
    const {
      currentEditingTask,
      workflowTaskID,
      selectCurrentEditingTask,
      tasks,
    } = this.props;

    if (
      !currentEditingTask ||
      workflowTaskID !== currentEditingTask.get('id')
    ) {
      // Only set current task if there are tasks already
      if (tasks && tasks.get('items') && !tasks.get('items').isEmpty()) {
        selectCurrentEditingTask(workflowTaskID);
      }
    }
  }

  handleBlockSelect = (e, props) => {
    this.newBlockType = props.value;
  };

  handleAddNewBlock = (close) => {
    if (this.newBlockType) {
      const { addBlock, workflowTaskID } = this.props;

      const blockToAddAttrib = BlockDefinitionMap.get(this.newBlockType)
        .blobAttributes;
      const multiInputMapping =
        blockToAddAttrib.inputValue && blockToAddAttrib.inputValue.arrayShape;

      addBlock(workflowTaskID, this.newBlockType, multiInputMapping);
      this.newBlockType = '';

      // close the modal
      close();
    }
  };

  handleOnOrderChange = (ordering) => {
    const { workflowTaskID, rearrangeBlocks } = this.props;
    rearrangeBlocks(ordering, workflowTaskID);
  };

  handleNavigateToTask = (e, data) => {
    const { workflowID } = this.props;

    const workflowTaskID = data.value;

    const taskUrl = uiPathGenerator.genPath('editor.workflowTask', {
      workflowID,
      workflowTaskID,
    });

    this.setState(
      {
        activeItem: menuItems.BLOCK,
      },
      () => {
        browserHistory.push(taskUrl);
      }
    );
  };

  removeBlock = memoize(
    curry((index, close, e) => {
      const { removeBlock, workflowTaskID } = this.props;

      if (Number.isInteger(index)) {
        removeBlock(workflowTaskID, index);

        close();
      } else {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Block must be numeric');
      }
    })
  );

  handleSave = () => {
    const { workflowTaskID, saveTask, saveConditions } = this.props;
    const { activeItem } = this.state;

    if (activeItem === menuItems.BLOCK) {
      saveTask(workflowTaskID);
    } else {
      saveConditions(workflowTaskID);
    }
  };

  handleItemClick = (e, value) => {
    const { createFinalCondition, workflowTaskID } = this.props;

    if (value.name === 'Create Conditions') {
      this.setState(
        {
          createCondition: true,
        },
        () => {
          createFinalCondition(workflowTaskID);
        }
      );
    } else {
      this.setState({
        activeItem: value.name,
      });
    }
  };

  handleRemoveAttribute = memoize(
    curry((name, object, e) => {
      const { removeAttribute } = this.props;

      const textDialog = `Are you sure you want to delete the attribute "${name}" ?\nBe sure that this attribute is not in use anymore.`;
      const confirmed = BrowserPrompt.confirm(textDialog, {
        buttons: ['Cancel', 'Ok'],
        callback(action) {
          if (action === 2) {
            return true;
          }
          return false;
        },
        content: textDialog,
        title: 'Delete',
      });

      if (confirmed) {
        removeAttribute(name, object);
      }
    })
  );

  render() {
    const {
      currentEditingTask,
      tasks,
      saveStatus: {
        taskFormIsChanged,
        taskFormSaveInProgress,
        taskFormHasErrors,
      },
      conditionIsPristine,
      conditionIsDirty,
      addOptionToBlockAttribute,
      removeOptionFromBlockAttribute,
    } = this.props;

    const dropDownProps =
      tasks.get('items') &&
      tasks
        .get('items')
        .valueSeq()
        .map((task) => ({
          text: task.get('name'),
          value: task.get('id'),
        }))
        .toJS();

    let attributeListMap;
    if (currentEditingTask && currentEditingTask.get('attributes')) {
      attributeListMap = currentEditingTask
        .get('attributes')
        .keySeq()
        .map((key) => {
          const attr = currentEditingTask.getIn(['attributes', key]);

          const obj =
            attr.get('object') === 'workflowrequest.requested_for'
              ? 'Requested_For'
              : attr.get('object');

          return {
            key: key,
            name: attr.get('name'),
            object: attr.get('object'),
            text: (
              <>
                {`${attr.get('app_label')}:${attr.get('name')}`}
                <br />
                {`Object: ${obj}`}
                <br />
                {`BE: ${attr.get('storage') || null}`}
              </>
            ),
            value: `attributes['${key}'].attribute_value`,
          };
        })
        .toJS();

      attributeListMap.unshift({
        key: '',
        text: '-None-',
        value: '',
      });
    }

    const blockTypesAsOptions = [];
    BlockDefinitionMap.forEach((blockDefinition, blockType) => {
      if (!blockDefinition) {
        // eslint-disable-next-line no-console -- debugging
        console.warn(
          `No Block Definition found in BlockTypes for blockType ${blockType}`
        );
        return;
      }
      blockTypesAsOptions.push({
        key: blockType,
        text: blockDefinition.label,
        value: blockType,
      });
    });

    const { activeItem } = this.state;

    const isSaving =
      activeItem === menuItems.BLOCK
        ? !taskFormIsChanged
        : conditionIsPristine && !conditionIsDirty;

    const textSaveButton =
      activeItem === menuItems.BLOCK ? 'Save Blocks' : 'Save Conditions';

    return (
      <Grid.Column width={16}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Loader active={tasks.get('isLoading')} inline='centered' />

              {!tasks.get('isLoading') && (
                <Menu secondary>
                  <Menu.Item fitted='horizontally'>
                    {/* <Button
                   content='Preview'
                   icon='eye'
                   onClick={togglePreview}
                   primary
                   />*/}
                  </Menu.Item>
                  <Menu.Item fitted='horizontally' position='right'>
                    <SaveButton
                      erroed={taskFormHasErrors}
                      loading={taskFormSaveInProgress}
                      onSaveFunc={this.handleSave}
                      saved={isSaving}
                      text={textSaveButton}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Grid.Column>
          </Grid.Row>

          {/*  Select another task Section  */}
          {currentEditingTask && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Dropdown
                  defaultValue={currentEditingTask.get('id')}
                  key={currentEditingTask.get('id')}
                  onChange={this.handleNavigateToTask}
                  options={dropDownProps}
                  placeholder='Select a task...'
                  search
                  selection
                />
                <WorkflowTaskModal />
              </Grid.Column>
            </Grid.Row>
          )}

          <Menu attached='top' tabular>
            <Menu.Item
              active={activeItem === menuItems.BLOCK}
              name={menuItems.BLOCK}
              onClick={this.handleItemClick}
            />

            {currentEditingTask &&
            (currentEditingTask.get('post_condition_group') ||
              currentEditingTask.get('related_workflow_condition')) ? (
              <Menu.Item
                active={activeItem === menuItems.CONDITIONS}
                name={menuItems.CONDITIONS}
                onClick={this.handleItemClick}
              />
            ) : (
              <Menu.Item
                active={activeItem === menuItems.CREATE_CONDTIONS}
                icon='plus'
                name={menuItems.CREATE_CONDTIONS}
                onClick={this.handleItemClick}
              />
            )}
          </Menu>

          {activeItem === menuItems.BLOCK ? (
            <>
              {/*  Nav Header Section  */}
              {currentEditingTask && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Menu secondary>
                      <Menu.Item as='h3' fitted='horizontally'>
                        {'Nav Header'}
                      </Menu.Item>
                    </Menu>

                    <Segment>
                      <NavFormEditor
                        key={currentEditingTask.get('id')}
                        nav={currentEditingTask.getIn(['frontend_blob', 'nav'])}
                        taskId={currentEditingTask.get('id')}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              )}

              {/*  Blocks Section Title  */}
              {currentEditingTask && (
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Menu.Item as='h3' fitted='horizontally'>
                      {'Blocks'}
                    </Menu.Item>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <ModalEsp
                      acceptContent='Add'
                      cancelContent='Cancel'
                      headerContent='Add Block'
                      headerIcon='tasks'
                      onAccept={this.handleAddNewBlock}
                      trigger={
                        <Button
                          content='Add Block'
                          floated='right'
                          icon='tasks'
                        />
                      }
                    >
                      <p>
                        {'Please select the block type you would like to add.'}
                      </p>
                      <Dropdown
                        fluid
                        onChange={this.handleBlockSelect}
                        options={blockTypesAsOptions}
                        placeholder='Type to search...'
                        search
                        selectOnBlur
                        selection
                      />
                    </ModalEsp>
                  </Grid.Column>
                </Grid.Row>
              )}

              {/*  Blocks Section  */}
              {currentEditingTask &&
                currentEditingTask.getIn(['frontend_blob', 'blocks']) && (
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <DragDrop
                        dynamicHeightChildren
                        onOrderChange={this.handleOnOrderChange}
                      >
                        {currentEditingTask
                          .getIn(['frontend_blob', 'blocks'])
                          .map((block, i) => (
                            <Segment
                              clearing
                              key={block.get('id')}
                              style={{
                                width: 'calc(100% - 2em)',
                              }}
                            >
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={14}>
                                    <BlockForm
                                      addOptionCallback={
                                        addOptionToBlockAttribute
                                      }
                                      attributesSelectable={attributeListMap}
                                      blockData={block}
                                      removeOptionCallback={
                                        removeOptionFromBlockAttribute
                                      }
                                      type={block.get('type')}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={2}>
                                    <Button.Group
                                      floated='right'
                                      size='tiny'
                                      vertical
                                    >
                                      <ModalEsp
                                        acceptButtonColor='red'
                                        acceptContent='Delete'
                                        cancelContent='Cancel'
                                        headerContent='Delete this Task Block?'
                                        headerIcon='trash'
                                        // eslint-disable-next-line no-restricted-globals  -- TODO: warning this is likely a bug, close doesnt exist
                                        onAccept={this.removeBlock(i, close)}
                                        trigger={
                                          <Button content='' icon='close' />
                                        }
                                      >
                                        <p>
                                          {
                                            'Are you sure you want to delete this block?'
                                          }
                                        </p>
                                      </ModalEsp>
                                    </Button.Group>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          ))}
                      </DragDrop>
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Segment clearing secondary>
                        <Header as='h3'>{'Attributes'}</Header>
                        {currentEditingTask && (
                          <AddAttributeModalController
                            taskAttributes={
                              currentEditingTask.get('attributes') ||
                              Immutable.Map()
                            }
                            trigger={
                              <Button content='Add attribute' icon='add' />
                            }
                          />
                        )}

                        <Form>
                          <Grid>
                            {attributeListMap &&
                              attributeListMap
                                .filter((attr) => attr.key !== '')
                                .map((attr) => (
                                  <Grid.Row key={attr.key}>
                                    <Grid.Column width={13}>
                                      <Form.Input
                                        label={attr.text}
                                        readOnly
                                        size='mini'
                                        type='text'
                                        value={`{{${attr.value}}}`}
                                      />
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                      <AddAttributeModalController
                                        existingAttribute={currentEditingTask.getIn(
                                          ['attributes', attr.key]
                                        )}
                                        existingKey={attr.key}
                                        taskAttributes={
                                          currentEditingTask.get(
                                            'attributes'
                                          ) || Immutable.Map()
                                        }
                                        trigger={
                                          <Icon
                                            fitted
                                            link
                                            name='write'
                                            size='small'
                                          />
                                        }
                                      />
                                      <Icon
                                        fitted
                                        link
                                        name='trash'
                                        onClick={this.handleRemoveAttribute(
                                          attr.name,
                                          attr.object
                                        )}
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                ))}
                          </Grid>
                        </Form>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                )}
            </>
          ) : (
            <AddConditionForm />
          )}
        </Grid>

        {/*
         <PreviewTask
         blob={currentEditingTask}
         styleSheets={['/css/main.css']}
         size={inlineStyle}
         togglePreview={this.props.togglePreview}
         textToggle='X'
         isHiding={!this.props.showPreview}
         blobIsInvalid={taskFormHasErrors}
         />
         */}
      </Grid.Column>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowTaskEditorPage = WorkflowTaskEditorPageController(
  WorkflowTaskEditorPage
);

export default WorkflowTaskEditorPage;
