import actionTypes from './actionTypes';

const wsActions = {};

wsActions.connect = () => ({
  type: actionTypes.WS_CONNECT,
});

wsActions.connecting = () => ({
  type: actionTypes.WS_CONNECTING,
});

wsActions.connected = () => ({
  type: actionTypes.WS_CONNECTED,
});

wsActions.disconnect = () => ({
  type: actionTypes.WS_DISCONNECT,
});

wsActions.disconnected = (
  evt,
  details = {
    attempts: 0,
    interval: 0,
  }
) => ({
  details,
  evt,
  type: actionTypes.WS_DISCONNECTED,
});

wsActions.refused = (
  evt,
  details = {
    attempts: 0,
    interval: 0,
  }
) => ({
  details,
  evt,
  type: actionTypes.WS_REFUSED,
});

wsActions.send = (data) => ({
  data,
  type: actionTypes.WS_SEND,
});

wsActions.errored = (evt) => ({
  evt,
  type: actionTypes.WS_ERRORED,
});

wsActions.messageReceived = (message) => ({
  message,
  type: actionTypes.MESSAGE_RECEIVED,
});

export default wsActions;
