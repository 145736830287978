import {
  change,
  getFormSyncErrors,
  getFormValues,
  initialize,
  isDirty,
  isPristine,
  isValid,
} from 'redux-form/immutable';
import uiPathGenerator from '../../utils/uiPathGenerator';
import {
  ActivityTypes,
  isPersonToContact,
} from '../../globals/ActivityRecipients';

import browserHistory from '../../utils/browserHistory';
import buildCompositeController from '../../utils/buildCompositeController';
import onboardActivityThunks from '../../actions/onboardActivityThunks';

const FORM = 'OnboardTemplateForm';

// form default values
const parseTemplate = function (template = {}) {
  return {
    activity_recipient: template.activity_recipient || '',
    activity_type: template.type || '',
    category: template.category || 'Watch a Video',
    deadline_days: template.deadline_days || 0,
    deadline_hours: template.deadline_hours || 0,
    description: template.description || '',
    enable_deadline: template.enable_deadline || false,
    intent: template.intent,
    person_to_contact: template.person_to_contact || '',
    private_notes: template.private_notes || '',
    title: template.title || '',
    web_link: template.web_link || '',
  };
};

const mapStateToProps = (state, { templateID }) => {
  const templatesList = state.getIn(['onboardActivity', 'template', 'items']);
  const templateSelected = templatesList.filter(
    (tmp) => tmp.get('id') === Number(templateID)
  );

  return {
    formErrors: getFormSyncErrors(FORM)(state),
    formValues: getFormValues(FORM)(state),
    isDirty: isDirty(FORM)(state),
    isLoading: state.getIn(['onboardActivity', 'template', 'isLoading']),
    isPristine: isPristine(FORM)(state),
    isValid: isValid(FORM)(state),
    template: templateSelected.isEmpty() ? void 0 : templateSelected.get(0),
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeCategory(category) {
    dispatch(change(FORM, 'category', category));
  },

  changeStatus(templateID, status) {
    dispatch(onboardActivityThunks.changeTemplateStatus(templateID, status));
  },

  copyTemplate({ template }) {
    dispatch(onboardActivityThunks.copyTemplate(template)).then(
      (newTemplate) => {
        const url = uiPathGenerator.genPath(
          'admin.adminActivities.onboardingActivities.templates',
          {
            templateID: newTemplate.id,
          }
        );
        browserHistory.push(url);
      }
    );
  },

  createTemplate(formValues) {
    dispatch(onboardActivityThunks.createTemplate(formValues)).then(
      (template) => {
        const url = uiPathGenerator.genPath(
          'admin.adminActivities.onboardingActivities.templates',
          {
            templateID: template.id,
          }
        );
        browserHistory.push(url);
      }
    );
  },

  formValidation(values, { pristine, invalid, intl: { formatMessage } }) {
    if (pristine && !invalid) {
      return {};
    }
    if (!values.get('title')) {
      return {
        title: formatMessage({
          id: 'label.this_field_is_required',
        }),
      };
    }

    if (values.get('title').length > 120) {
      return {
        title: formatMessage(
          {
            id: 'label.field_max_length_error',
          },
          {
            limit: 120,
          }
        ),
      };
    }

    if (!values.get('activity_type')) {
      return {
        activity_type: formatMessage({
          id: 'label.this_field_is_required',
        }),
      };
    }

    if (!values.get('activity_recipient')) {
      return {
        activity_recipient: formatMessage({
          id: 'label.this_field_is_required',
        }),
      };
    }

    if (values.get('activity_type') === ActivityTypes.WEB_LINK) {
      if (!values.get('web_link')) {
        return {
          web_link: formatMessage({
            id: 'label.this_field_is_required',
          }),
        };
      }

      const urlRegExp = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
      );
      if (!values.get('web_link').match(urlRegExp)) {
        return {
          web_link: formatMessage({
            id: 'label.invalid_url',
          }),
        };
      }
    }

    if (isPersonToContact(values.get('activity_type'))) {
      if (!values.get('person_to_contact')) {
        return {
          person_to_contact: formatMessage({
            id: 'label.this_field_is_required',
          }),
        };
      }
    }

    if (values.get('enable_deadline')) {
      if (Number(values.get('deadline_days')) < 0) {
        return {
          deadline_days: formatMessage({
            id: 'label.only_positive_values',
          }),
        };
      }

      if (Number(values.get('deadline_hours')) < 0) {
        return {
          deadline_hours: formatMessage({
            id: 'label.only_positive_values',
          }),
        };
      }
    }

    return {};
  },

  loadEmptyForm() {
    // Initialize empty form
    dispatch(initialize(FORM, parseTemplate()));
  },

  loadTemplate(templateID) {
    // load and initialize template
    dispatch(
      onboardActivityThunks.loadTemplate(templateID, (template) => {
        dispatch(initialize(FORM, parseTemplate(template)));
      })
    );
  },

  updateTemplate(templateID, formValues) {
    dispatch(onboardActivityThunks.updateTemplate(templateID, formValues)).then(
      (template) => {
        // reinitialize form so pristine is true again
        dispatch(initialize(FORM, parseTemplate(template)));
      }
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
