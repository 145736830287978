import browserHistory from './browserHistory';

/**
 * Utility to build the Knowledge link from the query parameters sys_id and title
 * @returns {string}
 * @constructor
 */
const BuildKBLink = () => {
  const searchQuery = browserHistory.location.search;
  let kbLink = '';
  const secondItemIndex = 1;
  if (searchQuery.match('table=kb_knowledge')) {
    let kbNumber = '';
    let kbTitle = '';
    const splittedQuery = searchQuery.split('&');
    splittedQuery.forEach((query) => {
      if (query.match('sys_id=')) {
        kbNumber = query.split('sys_id=')[secondItemIndex];
      } else if (query.match('title=')) {
        kbTitle = decodeURI(query.split('title=')[secondItemIndex]);
      }
    });
    kbLink = `[${kbTitle}](snow.KB ${kbNumber})`;
    return `I found an answer for you in one of our knowledge articles: ${kbLink}`;
  }
  return kbLink;
};

export default BuildKBLink;
