import { createSelector } from 'reselect';

const getBundlesById = (state) => state.getIn(['entities', 'bundles']);

const getHardwareBundleId = (state) =>
  state.getIn(['bundles', 'hardwareBundleId']);

const getHardwareBundle = (bundlesById, hardwareBundleId) => {
  if (!hardwareBundleId) {
    return null;
  }

  return bundlesById.get(hardwareBundleId);
};

const selector = createSelector(
  [getBundlesById, getHardwareBundleId],
  getHardwareBundle
);

export default selector;
