import React, { useCallback } from 'react';

import { Icon, Popup } from 'semantic-ui-react';
import pt from 'prop-types';

const propTypes = {
  text: pt.string,
};

const CopyToClipboard = ({ text }) => {
  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(text);
  }, [text]);

  return (
    <Popup
      content={'Copy to clipboard'}
      inverted
      trigger={<Icon link name='copy' onClick={handleCopy} />}
    />
  );
};

CopyToClipboard.propTypes = propTypes;

export default CopyToClipboard;
