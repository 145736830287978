import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

// Globals
import TypeSendReport, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSendReport';
// Controller
import SendReportController from '../../controllers/SendReportController';

/**
 * This block is an empty component which allow to set an alternate submit and
 * create a enw user from the Backend scratch data
 */

class SendReport01 extends PureComponent {
  static propTypes = {
    ...TypeSendReport,
    query: PropTypes.shape({
      taskId: PropTypes.string,
    }).isRequired,
    sendReport: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  componentDidMount() {
    const { setAlternateSubmit } = this.props;

    setAlternateSubmit(this.handleClick);
  }

  handleClick = (cb = _.noop) => {
    const {
      checkFromKB,
      query: { taskId },
      sendReport,
    } = this.props;

    sendReport(taskId, checkFromKB, cb);
  };

  render() {
    const { errorsApi, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {errorsApi &&
          !errorsApi.isEmpty() &&
          errorsApi.getIn([0, 'parameterName']) === 'email' && (
            <Message
              content={errorsApi.getIn([0, 'message'])}
              header='Error'
              negative
            />
          )}
      </div>
    );
  }
}

export const SendReport01Test = SendReport01;

export default SendReportController(SendReport01);
