import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import UserName from '../atoms/UserName';
import UserImage from '../atoms/UserImage';
// UTILS
import uiPathGenerator from '../../utils/uiPathGenerator';
// GLOBALS
import EspUserPropShape from '../../globals/EspUserPropShape';
import UserImageTypes from '../../globals/UserImageTypes';

class EspHero extends PureComponent {
  static propTypes = {
    action: PropTypes.element,
    buttons: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          content: PropTypes.string.isRequired,
          icon: PropTypes.string,
          to: PropTypes.string.isRequired,
        }),
        PropTypes.element,
      ])
    ),
    small: PropTypes.bool,
    user: EspUserPropShape.isRequired,
  };

  static defaultProps = {
    action: null,
    buttons: [],
    small: false,
  };

  /**
   *
   * @param button Object or React Element.
   * @return {*}
   */
  renderButton = (button, key) => {
    if (React.isValidElement(button)) {
      return React.cloneElement(button, {
        key,
      });
    } else {
      return (
        <Button
          as={Link}
          content={button.content}
          icon={button.icon}
          key={key}
          to={button.to}
        />
      );
    }
  };

  render() {
    const { action, buttons, small, user } = this.props;

    return (
      <div
        className={classNames('esp-hero', {
          small,
        })}
      >
        <div className='content'>
          <UserName as='h2' className='name' user={user} />
          {user.get('title') && <h4 className='title'>{user.get('title')}</h4>}
          {user.get('department') && (
            <h5 className='group'>{user.get('department')}</h5>
          )}
          {!small && buttons ? (
            <Button.Group basic inverted>
              {buttons.map(this.renderButton)}
            </Button.Group>
          ) : null}
        </div>
        {small ? (
          <NavLink
            activeClassName='disabled'
            className='avatar-link avatar'
            to={uiPathGenerator.genPath('app.directory.detail', {
              userID: user.get('id'),
            })}
          >
            <UserImage as='img' className='avatar' user={user} />
          </NavLink>
        ) : (
          <div className='avatar'>
            <UserImage as='img' user={user} />
          </div>
        )}

        {action ? <div className='action'>{action}</div> : null}

        <div className='background'>
          <div className='scrim' />
          <UserImage as='img' type={UserImageTypes.BACKGROUND} user={user} />
        </div>
      </div>
    );
  }
}

export default EspHero;
