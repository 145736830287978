import { Divider, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Molecules
import LabelNoun from './LabelNoun';

import customLabels from '../../globals/customLabels';

// Controller
import TenantConfigLabelController from '../controllers/TenantConfigLabelController';

class TenantConfigLabelForm extends PureComponent {
  static propTypes = {
    dirty: PropTypes.bool,
    formValues: ImmutablePropTypes.map,
    handleSubmit: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    /** label config loader */
    loadTenantLabelConfiguration: PropTypes.func.isRequired,
    preExistingValues: PropTypes.shape({
      nameNoun: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
    setTenantLabelConfiguration: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dirty: false,
    formValues: null,
    isSaving: false,
    preExistingValues: {},
  };

  componentDidMount() {
    const { loadTenantLabelConfiguration } = this.props;

    loadTenantLabelConfiguration();
  }

  handleSubmit = (e) => {
    const {
      formValues,
      preExistingValues,
      handleSubmit,
      setTenantLabelConfiguration,
    } = this.props;

    e.preventDefault();

    const valuesArray = [];

    customLabels.forEach((lab) => {
      const nameNoun = lab.get('name');
      valuesArray.push({
        application: 'label',
        delete: !formValues.get(nameNoun),
        key: lab.get('key'),
        language: formValues.get(`${nameNoun}-language`),
        name: nameNoun,
        text: formValues.get(nameNoun),
        update: Boolean(preExistingValues[nameNoun]),
      });
    });

    setTenantLabelConfiguration(valuesArray);
    handleSubmit();
  };

  render() {
    const { dirty, isSaving } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {customLabels.valueSeq().map((lab) => (
          <LabelNoun
            customLabel={lab}
            key={lab.get('name')}
            name={lab.get('name')}
          />
        ))}

        {dirty && (
          <Form.Button
            color='green'
            content='Save'
            disabled={isSaving}
            floated='right'
            loading={isSaving}
          />
        )}

        <Divider clearing hidden />
      </Form>
    );
  }
}

const TenantConfigLabelFormTest = TenantConfigLabelForm;

// eslint-disable-next-line no-class-assign -- DEV-1526
TenantConfigLabelForm = reduxForm({
  enableReinitialize: true,
  form: 'TenantLabelConfiguration',
})(TenantConfigLabelForm);
export { TenantConfigLabelFormTest };
export default TenantConfigLabelController(TenantConfigLabelForm);
