import XHRUtil from 'esp-util-api';
import { TokenUtil } from 'esp-util-auth';

const LoginUtil = {
  /**
   * @param {string} loginEndpoint
   * @param {string} email
   * @param {string} password
   * @return {Promise<unknown>}
   */
  doLogin(loginEndpoint, email, password) {
    const APIcall = new XHRUtil();
    return new Promise((resolve, reject) => {
      APIcall.post({
        // don't show snackbar error
        data: {
          email,
          password,
          username: email,
        },

        error(error) {
          reject(error);
        },
        preventShowError: true,
        success(response) {
          const authToken = response.body.key;
          TokenUtil.setToken(authToken);
          resolve(authToken);
        },
        url: loginEndpoint,
      });
    });
  },

  /**
   *
   * @param {string} logoutEndpoint
   * @return {Promise<unknown>}
   */
  doLogout(logoutEndpoint) {
    const APIcall = new XHRUtil();
    return new Promise((resolve, reject) => {
      APIcall.post({
        error(error) {
          reject(error);
        },
        preventShowError: true,
        // don't show snackbar error
        success(res) {
          TokenUtil.clearToken();
          resolve(res);
        },

        token: TokenUtil.getToken(),

        url: logoutEndpoint,
      });
    });
  },
};
export { LoginUtil };
