import { addDataPercentage } from 'esp-ui-charts';
import { jobStatuses } from 'esp-globals';
import async from 'async';
import { first, get, initial, last } from 'lodash';
import adminReportsActions from './adminReportsActions';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import reportsDateFormat, {
  surveyAnnouncementFormat,
  surveyDateAndResponsesFormat,
} from '../utils/reportsDateFormat';
import adminReportsMetrics from '../globals/AdminReportMetrics';
import adminReportFilterOptions from '../globals/AdminReportFilterOptions';
import {
  formatComparisonLabel,
  formatSummaryCell,
  Prefixes,
} from '../components/pages/admin/reports/reportUtils';
import toastNotificationsActions from './toastNotificationsActions';

const saveFile = (body, name) => {
  const { type } = body;
  const fileName = `${name}.${type.split('/')[1]}`;
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(
      new Blob([body], {
        type,
      }),
      fileName
    );
  } else {
    const a = window.document.createElement('a');
    window.document.body.appendChild(a);
    const url = window.URL.createObjectURL(
      new Blob([body], {
        type,
      })
    );
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      if (window.document.URL.revokeObjectURL) {
        window.document.URL.revokeObjectURL(url);
      }
      window.document.body.removeChild(a);
    }, 0);
  }
};

const adminReportsThunks = {};

adminReportsThunks.loadFirstDeploymentDate = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(adminReportsActions.getDeploymentDateStart());
    const url = endpointGenerator.genPath('espConfig.firstDeploymentTime');
    APIcall.get({
      preventShowError: true,
      token: true,
      url: url,
    })
      .then(({ body: { value } }) => {
        dispatch(adminReportsActions.getDeploymentDateSuccess(value));
        resolve();
      })
      .catch((e) => {
        // if this fail, it means that this tenant does not have any deployment time configuration
        dispatch(adminReportsActions.getDeploymentDateFail());

        reject(e);
      });
  });

adminReportsThunks.downloadReportByType =
  (startDate, endDate, reportNumber = 11) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(adminReportsActions.loadReportStart());
      const generateReport = (cb) => {
        // example https://release.qa.espressive.com/api/barista/v0.1/reports/report9/?&from_date=2019-7-14&to_date=2019-7-15&sort_date=Month
        const generateReportUrl = endpointGenerator.genPath(
          `espBarista.reports.report${reportNumber}`
        );
        APIcall.get({
          error(error) {
            cb(new Error(error));
          },
          query: {
            from_date: startDate,
            sort_date: 'Month',
            to_date: endDate,
          },
          success(/* {body}*/) {
            cb();
          },
          token: true,
          url: generateReportUrl,
        });
      };

      let triesCount = 0;
      let toastInstance;
      const downloadReport = (cb) => {
        // example https://release.qa.espressive.com/api/barista/v0.1/reports/get_report9/?&from_date=2019-7-14&to_date=2019-7-15&sort_date=Month
        const downloadReportUrl = endpointGenerator.genPath(
          `espBarista.reports.getReport${reportNumber}`
        );
        APIcall.get({
          error(error) {
            dispatch(
              toastNotificationsActions.removeToast(toastInstance?.toast?.key)
            );
            cb(new Error(error));
          },
          responseType: 'blob',
          success(result) {
            if (result.type === 'application/json') {
              triesCount++;

              setTimeout(() => downloadReport(cb), 3000);

              // after xth number of tries
              // don't throw error, instead warn users this will take long
              if (triesCount === 20) {
                toastInstance = dispatch(
                  toastNotificationsActions.warn({
                    dismissAfter: false,
                    message:
                      'This query is large and will take additional time to complete.',
                    title: 'Warning',
                  })
                );
              }
            } else {
              dispatch(
                toastNotificationsActions.removeToast(toastInstance?.toast?.key)
              );
              saveFile(result.body, 'Barista-Interaction-Report');
              cb();
            }
          },
          token: true,
          url: downloadReportUrl,
        });
      };

      const done = (error) => {
        if (error) {
          reject(error);
          dispatch(adminReportsActions.loadReportError(error));
        } else {
          resolve();
          dispatch(adminReportsActions.loadReportSuccess());
        }
      };

      async.waterfall([generateReport, downloadReport], done);
    });

const REPORT_INTERVAL_TIME = 3000;

/**
 * saveCSVFromArray
 *
 * We can't write data directly to a local file for security reasons. HTML5 API can only allow you to read files.
 * This is the most reliable way across all browsers to downloads a file.
 * Creates, attaches and finally removes a anchor to the DOM to download a file.
 *
 * @param data  {array} an array of objects
 * @param title {title} title of the csv file
 */
adminReportsThunks.saveCSVFromArray = (data, title) => () => {
  let csv = '';
  // This BOM is used to prevent unicode characters to be displayed correctly (ie avoid displaying ñ as ?)
  const universalBOM = '\uFEFF';
  // Loop the array of objects
  data.forEach((row, index) => {
    const keysAmount = Object.keys(row).length;
    let keysCounter = 0;

    // If this is the first row, generate the headings
    if (index === 0) {
      // Loop each property of the object
      for (const key in row) {
        // This is to not add a comma at the last cell
        // The '\r\n' adds a new line
        csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
        keysCounter++;
      }
    }

    keysCounter = 0;
    // generate csv lines
    for (const key in row) {
      const newLineValue = row[key].replace(
        // eslint-disable-next-line no-control-regex -- lint warning here, but in this case we need to check invisible characters
        /[\r\n\x0B\x0C\u0085\u2028\u2029]+/g,
        ' '
      );
      csv += `${newLineValue}${keysCounter + 1 < keysAmount ? ',' : '\r\n'}`;
      keysCounter++;
    }

    keysCounter = 0;
  });

  // Once we are done looping, download the .csv by creating a link
  const link = document.createElement('a');
  link.id = 'download-csv';
  const href = `data:text/csv;charset=utf-8,${encodeURIComponent(
    universalBOM + csv
  )}`;
  link.setAttribute('href', href);
  link.setAttribute('download', `${title}.csv`);
  document.body.appendChild(link);
  document.querySelector('#download-csv').click();

  setTimeout(() => {
    if (window.document.URL.revokeObjectURL) {
      window.document.URL.revokeObjectURL(href);
    }
    window.document.getElementById(link.id).remove();
  }, 0);
};

adminReportsThunks.exportData =
  (query, reportDefinition) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const startExportJob = (next) => {
        const isExporting = true;
        dispatch(
          adminReportsThunks.startJob(query, reportDefinition, isExporting)
        )
          .then(() => {
            next();
          })
          .catch((error) => {
            next(error);
          });
      };

      const tryToDownloadReport = (next) => {
        const url = getState().getIn(['adminReports', 'jobStatusUrl']);
        const isLoadingStatusUrl = getState().getIn([
          'adminReports',
          'isLoadingStatusUrl',
        ]);
        // abort if filters were changed
        if (isLoadingStatusUrl) {
          next();
        }

        if (!url) {
          reject(new Error('An URL must be provided'));
        }

        APIcall.get({
          error(error) {
            dispatch(adminReportsActions.loadDataError(error));
            reject(error);
          },
          success({ body }) {
            if (body && body.status === jobStatuses.COMPLETED) {
              const data = get(body, ['result_details', 'result', 'data']);
              dispatch(
                adminReportsThunks.saveCSVFromArray(
                  data,
                  'Return-Of-Investment'
                )
              );
              next();
            } else {
              setTimeout(() => tryToDownloadReport(next), REPORT_INTERVAL_TIME);
            }
          },
          token: true,
          url,
        });
      };

      const done = (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      };

      async.waterfall([startExportJob, tryToDownloadReport], done);
    });

adminReportsThunks.startJob =
  (query, reportDefinition, isExporting) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      let url;
      if (
        isExporting &&
        Object.prototype.hasOwnProperty.call(reportDefinition, 'export_url')
      ) {
        url = endpointGenerator.genPath(
          `reporting.templates.${reportDefinition.export_url}`
        );
      } else if (
        Object.prototype.hasOwnProperty.call(reportDefinition, 'custom_url')
      ) {
        url = endpointGenerator.genPath(
          `reporting.templates.${reportDefinition.custom_url}`
        );
      } else {
        url = endpointGenerator.genPath('reporting.templates.reportID', {
          reportID: getState().getIn(['adminReports', 'reportId']),
        });
      }

      dispatch(adminReportsActions.getStatusUrlStart());
      APIcall.get({
        error(error) {
          dispatch(adminReportsActions.getStatusUrlError(error));
          reject(error);
        },
        query,
        success({ body: { status_url } }) {
          const jobStatusUrl = status_url.includes('tenant1.esp')
            ? status_url.replace('https', 'http')
            : status_url;
          dispatch(adminReportsActions.getStatusUrlSuccess(jobStatusUrl));
          resolve();
        },
        token: true,
        url,
      });
    });

adminReportsThunks.updateJobStatus =
  (query, reportDefinition, formatForAnnouncementSection) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const url = getState().getIn(['adminReports', 'jobStatusUrl']);
      const isLoadingStatusUrl = getState().getIn([
        'adminReports',
        'isLoadingStatusUrl',
      ]);

      if (!url) {
        reject(new Error('An URL must be provided'));
      }

      dispatch(adminReportsActions.loadDataStart());

      const tryToGetReportData = (next) => {
        // abort if filters were changed
        if (isLoadingStatusUrl) {
          next();
        }
        APIcall.get({
          error(error) {
            dispatch(adminReportsActions.loadDataError(error));
            reject(error);
          },
          success({ body }) {
            if (body && body.status === jobStatuses.COMPLETED) {
              let data = get(body, ['result_details', 'result', 'data']);

              // Last day is exclusive
              if (
                query.freq === adminReportFilterOptions.DAILY &&
                data.length > 1
              ) {
                data = initial(data);
              }

              // Format Data
              let formattedData = reportsDateFormat(
                query.freq,
                data,
                query.end_date
              );

              if (
                Object.prototype.hasOwnProperty.call(
                  reportDefinition,
                  'percent'
                )
              ) {
                const { total, proportion } = reportDefinition.percent;
                formattedData = addDataPercentage(
                  formattedData,
                  total,
                  proportion
                );
              }

              if (
                reportDefinition.custom_url === 'surveys_report' &&
                query.end_date &&
                !formatForAnnouncementSection
              ) {
                formattedData = surveyDateAndResponsesFormat(
                  formattedData,
                  query.end_date
                );
              }

              if (
                reportDefinition.custom_url === 'surveys_report' &&
                formatForAnnouncementSection
              ) {
                formattedData = surveyAnnouncementFormat(formattedData);
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  reportDefinition,
                  'custom_metrics'
                )
              ) {
                if (reportDefinition.custom_metrics[0].dataKey === 'saved') {
                  const callCost = getState().getIn([
                    'adminReports',
                    'callCost',
                  ]);
                  formattedData = formattedData.map((entry) => ({
                    ...entry,
                    saved:
                      entry[adminReportsMetrics.AUTOMATED_RESOLUTIONS] *
                      callCost,
                  }));
                }
              }

              // Chart data points
              dispatch(adminReportsActions.loadDataSuccess(formattedData));

              // Report header
              if (!reportDefinition.customSummary) {
                const lastTwoWeeks = formattedData.slice(-2);
                const summaryMetric = reportDefinition.header.metric;
                const lastWeek = formatSummaryCell({
                  count: lastTwoWeeks[0][summaryMetric],
                  label: formatComparisonLabel(Prefixes.LAST, query.freq),
                });
                const thisWeek = formatSummaryCell({
                  count: lastTwoWeeks[1][summaryMetric],
                  label: formatComparisonLabel(Prefixes.THIS, query.freq),
                });
                const summary = {
                  lastWeek,
                  percent:
                    summaryMetric === 'percent'
                      ? Math.round((thisWeek - lastWeek) * 10) / 10
                      : Math.round((thisWeek * 100) / lastWeek) - 100,
                  thisWeek,
                };
                dispatch(adminReportsActions.loadSummaryDataSuccess(summary));
              }
              next();
            } else {
              setTimeout(() => tryToGetReportData(next), REPORT_INTERVAL_TIME);
            }
          },
          token: true,
          url,
        });
      };

      const done = (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      };

      async.waterfall([tryToGetReportData], done);
    });

adminReportsThunks.getSurveyId = (intentSurvey) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('surveys.surveys');
    APIcall.get({
      error(error) {
        reject(error);
      },
      query: {
        esp_filters: `identifier__EQ=${intentSurvey}`,
      },
      success({ body: { results } }) {
        const [survey] = results;
        dispatch(adminReportsActions.loadSurveySuccess(survey));
        resolve(survey);
      },
      token: true,
      url,
    });
  });

adminReportsThunks.getID = (eid) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('reporting.templates');

    dispatch(adminReportsActions.loadDataStart());
    APIcall.get({
      error(error) {
        dispatch(adminReportsActions.loadDataError(error));
        reject(error);
      },
      query: {
        esp_filters: `eid__EQ=${eid}`,
      },
      success(response) {
        const [template] = response.body.results;
        const { id } = template;
        dispatch(adminReportsActions.getIDSuccess(id));
        resolve(id);
      },
      token: true,
      url,
    });
  });

adminReportsThunks.getCallCost = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espConfig.deflectedCallCost');
    const cost = getState().getIn(['adminReports', 'callCost']);
    if (cost) {
      resolve(cost);
    } else {
      APIcall.get({
        error(error) {
          reject(error);
        },
        success(response) {
          const cost = Number(response.body.value);
          if (isNaN(cost) || cost === 0) {
            const error = new Error('Call cost coefficient is not defined');
            adminReportsActions.loadDataError(error);
            dispatch(adminReportsActions.getCallCostSuccess(1));
            reject(error);
          } else {
            dispatch(adminReportsActions.getCallCostSuccess(cost));
            resolve(cost);
          }
        },
        token: true,
        url,
      });
    }
  });

adminReportsThunks.loadGridData =
  (query, reportDefinition, isSummary) => (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        `reporting.templates.${reportDefinition.custom_url}`
      );

      dispatch(adminReportsActions.loadDataStart());
      APIcall.get({
        query,
        token: true,
        url,
      })
        .then((response) => {
          const { data } = response.body;
          if (isSummary) {
            const summary = {
              lastWeek: first(data).Values[0] || null,
              percent: null,
              thisWeek: last(data).Values[0] || null,
            };
            dispatch(adminReportsActions.loadSummaryDataSuccess(summary));
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          dispatch(adminReportsActions.loadDataError(error));
          reject(error);
        });
    });

export { saveFile };

export default adminReportsThunks;
