import actionTypes from './actionTypes';

const adminReportsActions = {};

adminReportsActions.getDeploymentDateStart = () => ({
  type: actionTypes.GET_DEPLOYMENT_DATE_START,
});
adminReportsActions.getDeploymentDateSuccess = (date) => ({
  date,
  type: actionTypes.GET_DEPLOYMENT_DATE_SUCCESS,
});
adminReportsActions.getDeploymentDateFail = () => ({
  type: actionTypes.GET_DEPLOYMENT_DATE_FAIL,
});

adminReportsActions.addIntervalId = (intervalId) => ({
  intervalId,
  type: actionTypes.ADD_INTERVAL_ID,
});

adminReportsActions.clearIntervalIds = () => ({
  type: actionTypes.CLEAR_INTERVAL_IDS,
});

adminReportsActions.resetReportsData = () => ({
  type: actionTypes.RESET_REPORTS_DATA,
});

adminReportsActions.removeIntervalId = (intervalId) => ({
  intervalId,
  type: actionTypes.REMOVE_INTERVAL_ID,
});

adminReportsActions.getCallCostSuccess = (callCost) => ({
  callCost,
  type: actionTypes.REPORTS_CALL_COST_SUCCESS,
});

adminReportsActions.getIDSuccess = (reportId) => ({
  reportId,
  type: actionTypes.REPORTS_GET_ID_SUCCESS,
});

adminReportsActions.getStatusUrlError = (error) => ({
  error,
  type: actionTypes.GET_STATUS_URL_ERROR,
});

adminReportsActions.getStatusUrlStart = () => ({
  type: actionTypes.GET_STATUS_URL_START,
});

adminReportsActions.getStatusUrlSuccess = (jobStatusUrl) => ({
  jobStatusUrl,
  type: actionTypes.GET_STATUS_URL_SUCCESS,
});

adminReportsActions.loadDataError = (error) => ({
  error,
  type: actionTypes.REPORTS_LOAD_DATA_ERROR,
});

adminReportsActions.loadDataEnd = () => ({
  type: actionTypes.REPORTS_LOAD_DATA_END,
});

adminReportsActions.loadDataStart = () => ({
  type: actionTypes.REPORTS_LOAD_DATA_START,
});

adminReportsActions.loadDataSuccess = (data) => ({
  data,
  type: actionTypes.REPORTS_LOAD_DATA_SUCCESS,
});

adminReportsActions.loadReportError = () => ({
  type: actionTypes.REPORTS_LOAD_REPORT_ERROR,
});

adminReportsActions.loadReportStart = () => ({
  type: actionTypes.REPORTS_LOAD_REPORT_START,
});

adminReportsActions.loadReportSuccess = () => ({
  type: actionTypes.REPORTS_LOAD_REPORT_SUCCESS,
});

adminReportsActions.loadSummaryDataSuccess = (data) => ({
  data,
  type: actionTypes.REPORTS_LOAD_SUMMARY_DATA_SUCCESS,
});

adminReportsActions.loadSurveySuccess = (survey) => ({
  survey,
  type: actionTypes.REPORTS_LOAD_SURVEY,
});

adminReportsActions.setDisplayOptions = (displayOptions) => ({
  displayOptions,
  type: actionTypes.REPORTS_SET_DISPLAY_OPTIONS,
});

export default adminReportsActions;
