import { useEffect, useReducer } from 'react';
import fetchOktaAuth from '../api/fetchOktaAuth';
import saveAuthSAMLIDP from '../../api/saveAuthSAMLIDP';
import saveAuth from '../../api/saveAuth';
import postConfig from '../../api/postConfig';
import saveAuthSAML_SP from '../../api/saveAuthSAML_SP';
import deleteAuth from '../../api/deleteAuth';
import getAuthSAMLIDP from '../../api/getAuthSAMLIDP';
import patchAuth from '../../api/patchAuth';
import deleteAuthSAMLIDP from '../../api/deleteAuthSAMLIDP';
import getAuthSAML_SP from '../../api/getAuthSAML_SP';
import deleteAuthSAML_SP from '../../api/deleteAuthSAML_SP';
import setSamlSpIdentityIdConfig from '../../api/setSamlSpIdentityIdConfig';
import getSamlSpIdentityIdConfig from '../../api/getSamlSpIdentityIdConfig';
import { samlURLS } from '../utils/constants';
import { methods } from '../../utils/constants';

const actions = {
  DELETE_SSO: 'DELETE_SSO',
  FETCH_AUTH_END: 'FETCH_AUTH_END',
  LOADING_AUTH_FAIL: 'LOADING_AUTH_FAIL',
  LOADING_AUTH_START: 'LOADING_AUTH_START',
  POST_SSO: 'POST_SSO',
  SET_ENABLE_SSO_MODAL: 'SET_ENABLE_SSO_MODAL',
  UPDATE_SSO: 'UPDATE_SSO',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.LOADING_AUTH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_AUTH_END: {
      return {
        ...state,
        config: action.oktaConfig,
        isLoading: false,
      };
    }

    case actions.LOADING_AUTH_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actions.SET_ENABLE_SSO_MODAL: {
      return {
        ...state,
        isEnableModalOpen: action.status,
      };
    }

    case actions.POST_SSO: {
      return {
        ...state,
        config: action.newConfig,
        isEnableModalOpen: true,
        isLoading: false,
      };
    }
    case actions.UPDATE_SSO: {
      return {
        ...state,
        config: action.newConfig,
        isEnableModalOpen: false,
        isLoading: false,
      };
    }
    case actions.DELETE_SSO: {
      return {
        ...state,
        config: {},
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

const useAzureAuth = () => {
  const initialState = {
    config: {},
    isEnableModalOpen: false,
    isEnabled: false,
    isLoading: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: actions.LOADING_AUTH_START });

      try {
        const { body } = await fetchOktaAuth();

        const [oktaConfig] = body?.results;

        dispatch({
          oktaConfig,
          type: actions.FETCH_AUTH_END,
        });
      } catch (error) {
        dispatch({ type: actions.LOADING_AUTH_FAIL });
      }
    };

    fetchData();
  }, []);

  const checkConfig = async () => {
    try {
      const { body } = await getSamlSpIdentityIdConfig();
      return body;
    } catch (error) {
      return null;
    }
  };

  const handleSave = ({ idPMetadataUrl, hostname }) => {
    dispatch({ type: actions.LOADING_AUTH_START });

    const postSSO = async () => {
      try {
        await saveAuthSAMLIDP({
          metadata_url: idPMetadataUrl,
        });

        const { body } = await saveAuth({
          auth_backend: 'GENERIC_SAML',
          enabled: false,
          name: `${hostname} Okta SAML SSO`,
          saml_idp_metadata_url: idPMetadataUrl,
        });

        await saveAuthSAML_SP({
          acs_url: samlURLS.singleSignOnURL,
          slo_url: samlURLS.logoutURL,
        });

        const config = await checkConfig();

        if (config) {
          await setSamlSpIdentityIdConfig(
            { value: samlURLS.audienceURI },
            methods.PATCH
          );
        } else {
          await postConfig(
            {
              key: 'saml_sp_identity_id',
              type: 'string',
              value: samlURLS.audienceURI,
            },
            methods.POST
          );
        }

        dispatch({
          newConfig: body,
          type: actions.POST_SSO,
        });
      } catch (error) {
        dispatch({ type: actions.LOADING_AUTH_FAIL });
      }
    };

    postSSO();
  };

  const handleUpdate = (value) => {
    dispatch({ type: actions.LOADING_AUTH_START });

    const patchSSO = async () => {
      try {
        const { body } = await patchAuth(state.config.url, {
          enabled: !value,
        });

        dispatch({
          newConfig: body,
          type: actions.UPDATE_SSO,
        });
      } catch (error) {
        dispatch({ type: actions.LOADING_AUTH_FAIL });
      }
    };

    patchSSO();
  };

  const handleDelete = () => {
    dispatch({ type: actions.LOADING_AUTH_START });

    const deleteSSO = async () => {
      const { saml_idp_metadata_url, url } = state.config;

      await deleteAuth(url);

      await setSamlSpIdentityIdConfig({ value: '' }, methods.PATCH);

      const idpResponse = await getAuthSAMLIDP(saml_idp_metadata_url);
      const [idpToBeRemoved] = idpResponse?.body?.results;

      const spResponse = await getAuthSAML_SP();
      const [spToBeRemoved] = spResponse?.body?.results;

      if (idpToBeRemoved) {
        await deleteAuthSAMLIDP(idpToBeRemoved.url);
      }
      if (spToBeRemoved) {
        await deleteAuthSAML_SP(spToBeRemoved.id);
      }

      dispatch({
        type: actions.DELETE_SSO,
      });
    };

    deleteSSO();
  };

  const toggleEnableModal = () => {
    dispatch({
      status: !state.isEnableModalOpen,
      type: actions.SET_ENABLE_SSO_MODAL,
    });
  };

  return {
    handleDelete,
    handleSave,
    handleUpdate,
    state,
    toggleEnableModal,
  };
};

export default useAzureAuth;
