/**
 * Returns a normalized bold markdown string
 * @param {string} text - text to look up for the esp bold markdown
 * @returns {string} string with bold markdown normalized
 */
const replaceBold = (text) =>
  text.replace(/\*{2}(.*?)\*{2}/gim, (match, $1) => {
    const spacesAtStart = $1.length - $1.trimLeft().length;
    const spacesAtEnd = $1.length - $1.trimRight().length;
    return `${' '.repeat(spacesAtStart)}**${$1.trim()}**${' '.repeat(
      spacesAtEnd
    )}`;
  });

export default replaceBold;
