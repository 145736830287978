/**
 * REST API type to Cascara module mappings
 *
 * There are some types we don't have a module for yet,
 * for these, we are falling back to text, but this will generate prop-type warnings
 */
const api2Module = {
  boolean: 'checkbox',
  choice: 'select',
  // date: 'text',
  // datetime: 'text',
  decimal: 'number',
  email: 'email',
  // field: 'text', // It looks like 'field' might be a default
  // 'file upload': 'text',
  float: 'number',
  // 'image upload': 'text',
  integer: 'number',
  json: 'json',
  list: 'textarea',
  'multiple choice': 'select',
  'nested object': 'textarea',
  // regex: 'text',
  // slug: 'text',
  // string: 'text',
  // time: 'text',
  // url: 'text',
};

/**
 * Get valid cascara modules from the response of an options request to an endpoint
 *
 * Most endpoints in the Django API can serve/respond to an OPTIONS request, the response to such requests describes
 * the schema in which all GET request will be formatted. This normalizer's goal is to translate the aforementioned schema
 * into the Cascara AST syntax for describing modules.
 *
 * @param {Object} metadata Information about the data
 * @returns {Array[Object]} normalizedModules The set of field -> module mapped objects
 */
const getCascaraModulesFromOptionsAPI = (metadata) => {
  // If there are no modules, do not return anything. We cannot return an empty array
  // because that will potentially override our loading state.
  if (!metadata) {
    return undefined;
  }

  // We always want to return every value with an object. If there is a future need to
  // hide a value from this function, we can use filter() on the returned array.
  //
  // By default we set any unknown module type to 'text` so that we always have a way to
  // show a module for each value without hiding data in a way nobody would expect. This
  // also makes the list of module keys we need to maintain shorter.
  const modules = Object.entries(metadata)?.map(
    ([attribute, { read_only, type, ...rest }]) => {
      const options = ['choice', 'multiple choice'].includes(type)
        ? rest.choices.map((choice) => ({
            key: choice.display_name,
            text: choice.display_name,
            value: choice.value,
          }))
        : null;

      return {
        attribute,
        isEditable: !read_only,
        module: api2Module[type] || 'text',
        options,
        ...rest,
      };
    }
  );

  return modules;
};

export default getCascaraModulesFromOptionsAPI;
