import PropTypes from 'prop-types';
import { noop } from 'lodash';
import React, { PureComponent } from 'react';
import ImpersonationBar from './ImpersonationBar';
import ImpersonationBarWrapperController from './ImpersonationBarWrapperController';

class ImpersonationBarWrapper extends PureComponent {
  static propTypes = {
    agentName: PropTypes.string,
    endImpersonation: PropTypes.func,
    isAgent: PropTypes.bool,
    isBaristaImpersonatorEnabled: PropTypes.bool,
    isImpersonating: PropTypes.bool,
    startImpersonation: PropTypes.func,
  };

  static defaultProps = {
    // temp default values
    agentName: '',
    endImpersonation: noop,
    isAgent: false,
    isBaristaImpersonatorEnabled: false,
    isImpersonating: false,
    startImpersonation: noop,
  };

  componentWillUnmount() {
    const { isImpersonating, endImpersonation } = this.props;
    if (isImpersonating) {
      endImpersonation();
    }
  }

  render() {
    const {
      agentName,
      endImpersonation,
      isAgent,
      isBaristaImpersonatorEnabled,
      isImpersonating,
      startImpersonation,
    } = this.props;
    if (isAgent && isBaristaImpersonatorEnabled) {
      return (
        <ImpersonationBar
          agentName={agentName}
          endImpersonation={endImpersonation}
          isImpersonating={isImpersonating}
          startImpersonation={startImpersonation}
        />
      );
    }
    return null;
  }
}

const ImpersonationBarWrapperWithController = ImpersonationBarWrapperController(
  ImpersonationBarWrapper
);

export default ImpersonationBarWrapperWithController;
