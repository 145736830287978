import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { FormikWithSemanticUI } from 'esp-ui-form';
import EmailRegExp from '../../../../../globals/EmailRegExp';
import UrlRegExp from '../../../../../globals/UrlRegExp';
import TestingConnectionModal from './TestingConnectionModal';
import ConnectionSettingsController from './ConnectionSettingsController';

class ConnectionSettings extends Component {
  static propTypes = {
    credentials: PropTypes.shape({
      apiToken: PropTypes.string,
      get: PropTypes.func,
      url: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,

    errors: PropTypes.shape({
      api_token: '',
      url: '',
      username: '',
    }),

    getCredentials: PropTypes.func.isRequired,

    handleChange: PropTypes.func.isRequired,

    handleReset: PropTypes.func.isRequired,

    handleSubmit: PropTypes.func.isRequired,

    setCredentials: PropTypes.func.isRequired,

    values: PropTypes.shape({
      api_token: PropTypes.string,
      url: PropTypes.string,
      username: PropTypes.string,
    }),
  };

  static defaultProps = {
    errors: {
      api_token: '',
      url: '',
      username: '',
    },
    values: {},
  };
  componentDidMount() {
    const { getCredentials } = this.props;
    getCredentials();
  }
  handleSubmit = () => {
    const { values, setCredentials, credentials } = this.props;
    setCredentials(values, credentials);
  };
  handleFocus = (e) => {
    e.target.value = '';
  };
  handleApiTokenBlur = (e) => {
    const { credentials, values } = this.props;
    if (e.target.value === '') {
      e.target.value = credentials.get('api_token');
      values.api_token = credentials.get('api_token');
    }
  };

  render() {
    const {
      handleReset,
      handleChange,
      handleSubmit,
      values,
      errors,
    } = this.props;

    const gridStyle = {
      borderRadius: '0',
      marginBottom: '0',
    };

    return (
      <>
        <Grid as={Segment} attached='bottom' style={gridStyle}>
          <Grid.Row columns='equal'>
            <Grid.Column>
              <Form onReset={handleReset} onSubmit={handleSubmit}>
                <Form.Group style={{ marginTop: '1em' }}>
                  <Form.Input
                    error={values.url !== '' && errors.url}
                    id='form-input-control-confluence-url'
                    label={{
                      children: 'Confluence URL',
                      htmlFor: 'form-input-control-confluence-url',
                    }}
                    name='url'
                    onChange={handleChange}
                    required
                    type='text'
                    value={values.url}
                    width={8}
                  />
                </Form.Group>
                <Header as='h4'>{'Authentication'}</Header>
                <Form.Group>
                  <Form.Input
                    error={values.username !== '' && errors.username}
                    id='form-input-control-user-email'
                    label={{
                      children: 'User Email',
                      htmlFor: 'form-input-control-user-email',
                    }}
                    name='username'
                    onChange={handleChange}
                    required
                    type='email'
                    value={values.username}
                    width={4}
                  />
                  <Form.Input
                    error={errors.api_token}
                    id='form-input-control-api-token'
                    label={{
                      children: 'API Token',
                      htmlFor: 'form-input-control-api-token',
                    }}
                    name='api_token'
                    onBlur={this.handleApiTokenBlur}
                    onChange={handleChange}
                    onFocus={this.handleFocus}
                    required
                    type='password'
                    value={values.api_token}
                    width={4}
                  />
                  <Form.Field width={5}>
                    <label style={{ visibility: 'hidden' }}>
                      {'testingConnectionButton'}
                    </label>
                    <TestingConnectionModal
                      disabled={Boolean(errors.username) || Boolean(errors.url)}
                      values={values}
                    />
                  </Form.Field>
                </Form.Group>
                <small>
                  <em>
                    {
                      'The user should have sufficient permissions for all spaces that need to be exposed.'
                    }
                  </em>
                </small>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment attached='bottom' secondary textAlign='right'>
          <Button
            content='Save'
            disabled={
              Boolean(errors.api_token) ||
              Boolean(errors.url) ||
              Boolean(errors.username)
            }
            id='save_button'
            onClick={this.handleSubmit}
            primary
          />
        </Segment>
      </>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
ConnectionSettings = FormikWithSemanticUI({
  enableReinitialize: true,
  mapPropsToValues: ({ credentials }) => ({
    api_token:
      credentials && credentials.get('api_token')
        ? credentials.get('api_token')
        : '',
    url: credentials && credentials.get('url') ? credentials.get('url') : '',
    username:
      credentials && credentials.get('username')
        ? credentials.get('username')
        : '',
  }),
  validate: (values) => {
    const errors = {};

    if (!values.url.trim()) {
      errors.url = true;
    } else if (!UrlRegExp.test(values.url.trim())) {
      errors.url = 'Invalid Url';
    }

    if (!values.username.trim()) {
      errors.username = true;
    } else if (!EmailRegExp.test(values.username.trim())) {
      errors.username = 'Invalid Email';
    }

    if (!values.api_token.trim() || values.api_token === '') {
      errors.api_token = true;
    }

    return errors;
  },
})(ConnectionSettings);
export default ConnectionSettingsController(ConnectionSettings);
