import Immutable from 'immutable';

/**
 * Recursive Util to trim any value from an immutable Map or List
 * @param values {Immutable Object}
 * @returns {*}
 */
const trimValues = (values) => {
  const newValues = values;
  if (
    (Immutable.Map.isMap(values) || Immutable.List.isList(values)) &&
    !values.isEmpty()
  ) {
    return newValues.map((value) => trimValues(value));
  }
  return newValues && newValues.trim ? newValues.trim() : newValues;
};

export default trimValues;
