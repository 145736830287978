const LocationTypes = {
  ADDRESS: 'address',
  BUILDING: 'building',
  CITY: 'city',
  COUNTRY: 'country',
  FLOOR: 'floor',
  POSTAL_CODE: 'postal_code',
  STATE: 'state',
  ZONE: 'zone',
};

export const LocationNumericTypes = {
  ADDRESS: 4,
  BULDING: 6,
  CITY: 3,
  COUNTRY: 1,
  FLOOR: 7,
  POSTAL_CODE: 5,
  STATE: 2,
  ZONE: 8,
};

export default LocationTypes;
