import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwareMonitor extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M70.86 15.5H24.5c-4.97 0-9 4.03-9 9v32c0 4.97 4.03 9 9 9h19.36v10l-15.46 1V80h36.89v-3.59l-14.43-.96v-10h20c2.387 0 4.676-.948 6.364-2.636 1.688-1.688 2.636-3.977 2.636-6.364v-32c-.028-4.95-4.05-8.95-9-8.95zM45.37 58.57c0-1.105.895-2 2-2s2 .895 2 2-.895 2-2 2-2-.895-2-2zm27.54-8c0 1.657-1.343 3-3 3H25.75c-1.657 0-3-1.343-3-3V25.42c0-1.657 1.343-3 3-3h44.16c1.657 0 3 1.343 3 3v25.15z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogHardwareMonitor;
