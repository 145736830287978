import tinycolor from 'tinycolor2';

const theme = (tenantColorPrimary, tenantColorHeader) => {
  // TODO Move fallback values to getTenantBrandingSelector maybe
  tenantColorPrimary = tenantColorPrimary || '#ccc';
  tenantColorHeader = tenantColorHeader || tenantColorPrimary;

  // text colors depending on light/darkness
  const textColorPrimary = tinycolor.isReadable(tenantColorPrimary, '#fff', {
    level: 'AA',
    size: 'large', // shift to 3:1 from 4.5:1
  })
    ? '#fff'
    : '#000';
  const textColorHeader = tinycolor.isReadable(tenantColorHeader, '#fff', {
    level: 'AA',
    size: 'large', // shift to 3:1 from 4.5:1
  })
    ? '#fff'
    : '#000';

  // Create our theme object
  const theme = {};

  // We can keep global values here for consistency
  // We should not be using shorthand properties anywhere
  theme.globals = {
    borderRadius: '2px',
    fontSize: '16px',
    lineHeight: '1em',
    padding: '1em',
    transitionDuration: 200,
    transitionTimingFunction: 'ease-in-out',
    zIndexDropdown: 101,
    zIndexInput: 10,
  };

  // Value for breakpoints
  theme.breakpoints = {
    ip5: '321px',
    iphonex:
      'only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)',
    large: '1024px',
    medium: '640px',
    small: '0',
    xlarge: '1200px',
    xxlarge: '1368px',
  };

  // Values for Espressive branding
  theme.brandEspressive = {
    green: '#2db59e',
    orange: '#e59925',
  };

  // Values for Tenant branding
  theme.brandTenant = {
    headerColor: tenantColorHeader,
    primaryColor: tenantColorPrimary,
    textColorHeader: textColorHeader,
    textColorPrimary: textColorPrimary,
  };

  // Values for consistent colors
  theme.colors = {
    black: '#000',
    darkGray: '#aaa',
    gray: '#e0e1e2',
    lightGray: '#fafafa',
    red: '#a00',
    text: '#4a4a4a',
    white: '#fff',
  };

  // Component values for ListCard - We may want to separate these to an external?
  // TODO: bje will move this to a separate theme file
  theme.component = {
    announcementPadding: '.125em',
  };

  return theme;
};
// const alias = {colors: base.color};
//
// const baseTheme = Object.assign({}, base, alias);

export default theme;

export const namedKeys = {
  ip5: '@media (min-width: 321px)',
  iphonex:
    '@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)',
  large: '@media (min-width: 1024px)',
  medium: '@media (min-width: 640px)',
  xlarge: '@media (min-width: 1200px)',
  xxlarge: '@media (min-width: 1368px)',
};
