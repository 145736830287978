import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import React, { PureComponent } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  List,
  Message,
  Segment,
} from 'semantic-ui-react';

// Molecules
import ValidatedForm from '../../../../molecules/ValidatedForm';
import ValidatedField from '../../../../molecules/ValidatedField';
// Controller
import IntegrationSnowConnectionController from '../../../../controllers/IntegrationSnowConnectionController';

class IntegrationServicenowConnection extends PureComponent {
  static propTypes = {
    clearPassword: PropTypes.func.isRequired,
    formValidation: PropTypes.func.isRequired,
    formValues: ImmutablePropTypes.map,
    getSnowConnectionStatus: PropTypes.func.isRequired,
    getSnowCredentials: PropTypes.func.isRequired,

    isDirty: PropTypes.bool,

    isLoadingSnowConnectionStatus: PropTypes.bool,

    isLoadingSnowCredentials: PropTypes.bool,

    isValid: PropTypes.bool,
    saveSnowCredentials: PropTypes.func.isRequired,
    snowConnectionStatus: ImmutablePropTypes.map,
    snowCredentials: ImmutablePropTypes.map,
    syncErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
  };

  static defaultProps = {
    formValues: Immutable.Map(),
    isDirty: false,
    isLoadingSnowConnectionStatus: false,
    isLoadingSnowCredentials: false,
    isValid: false,
    snowConnectionStatus: Immutable.Map(),
    snowCredentials: Immutable.Map(),
    syncErrors: null,
  };

  componentDidMount() {
    const { getSnowCredentials } = this.props;
    getSnowCredentials();
  }

  handleTestStatus = () => {
    const { getSnowConnectionStatus } = this.props;
    getSnowConnectionStatus();
  };

  // Clears the password if the field was pristine and not changed
  // So it forces the user to change the password if this field is touched
  handleOnFocusPassword = () => {
    const { formValues, snowCredentials, clearPassword } = this.props;
    if (formValues.get('password') === snowCredentials.get('password')) {
      clearPassword();
    }
  };

  handleSubmit = () => {
    const { saveSnowCredentials } = this.props;
    saveSnowCredentials();
  };

  render() {
    const {
      formValidation,
      isDirty,
      isLoadingSnowConnectionStatus,
      isLoadingSnowCredentials,
      isValid,
      snowConnectionStatus,
      snowCredentials,
      syncErrors,
    } = this.props;

    const successIcon = <List.Icon color='green' name='check' size='large' />;

    const failIcon = <List.Icon color='red' name='delete' size='large' />;

    const connectionItems = [
      {
        content: 'Able to test connection',
        icon: snowConnectionStatus.get('URL_IS_VALID') ? successIcon : failIcon,
        key: '0',
      },
      {
        content: 'Permissions sufficient',
        icon: snowConnectionStatus.get('AUTHENTICATION_VALID')
          ? successIcon
          : failIcon,
        key: '1',
      },
      {
        content: 'Espressive app found',
        icon: snowConnectionStatus.get('ESPRESSIVE_APP_AVAILABLE')
          ? successIcon
          : failIcon,
        key: '2',
      },
      // {
      //   key     : '3',
      //   content : 'App version up to date',
      //   icon    : successIcon,
      // },
    ];

    const initialValues = {
      password: snowCredentials.get('password'),
      url: snowCredentials.get('url'),
      username: snowCredentials.get('username'),
    };

    return (
      <Grid as={Segment} attached='bottom'>
        <Grid.Row columns='equal'>
          <Grid.Column>
            <Message
              content='Change the credential information of your ServiceNow instance. Also you can test your saved connection settings here.'
              info
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='equal'>
          <Grid.Column>
            <Segment>
              <Header as='h4' content='ServiceNow Instance Credentials' />
              <ValidatedForm
                enableReinitialize
                form={'SnowConnection'}
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validate={formValidation}
              >
                <ValidatedField label='Instance URL' name='url' required />
                <ValidatedField label='Username' name='username' required />
                <ValidatedField
                  label='Password'
                  name='password'
                  onFocus={this.handleOnFocusPassword}
                  required
                  type='password'
                />
                <Form.Button
                  content='Save'
                  disabled={
                    syncErrors !== null ||
                    isLoadingSnowCredentials ||
                    !isValid ||
                    !isDirty
                  }
                  loading={isLoadingSnowCredentials}
                  primary
                />
              </ValidatedForm>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' content='Connection Test' />
            {!snowConnectionStatus.isEmpty() ? (
              <List items={connectionItems} />
            ) : null}
            <Button
              basic
              content='Test'
              disabled={isLoadingSnowConnectionStatus}
              loading={isLoadingSnowConnectionStatus}
              onClick={this.handleTestStatus}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
IntegrationServicenowConnection = IntegrationSnowConnectionController(
  IntegrationServicenowConnection
);

export default IntegrationServicenowConnection;
