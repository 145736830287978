import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ChatMessageBubble from './ChatMessageBubble';
import ChatMessageWrapper from './ChatMessageWrapper';
import ChatMessageTimeStamp from './ChatMessageTimeStamp';
import ChannelTypes from '../../../../v1/globals/ChannelTypes';

const rule = () => {};

class ChatMessageText extends PureComponent {
  static propTypes = {
    channelId: PropTypes.string,
    channelType: PropTypes.oneOf([
      ChannelTypes.DIRECT_CHANNEL,
      ChannelTypes.SCOPED_CHANNEL,
      ChannelTypes.SUPPORT_CHANNEL,
    ]),
    dateTimeFormat: PropTypes.shape({}),
    hideAvatar: PropTypes.bool,
    isBarista: PropTypes.bool,
    isExpandable: PropTypes.bool,
    isFirstMessage: PropTypes.bool,
    isLastMessage: PropTypes.bool,
    isMe: PropTypes.bool,
    isScoped: PropTypes.bool,
    messageText: PropTypes.string.isRequired,
    paramValues: ImmutablePropTypes.map,
    showTimeStamp: PropTypes.bool,
    timeStamp: PropTypes.string,
    typing: PropTypes.bool,
    userId: PropTypes.number,
  };

  static defaultProps = {
    hideAvatar: false,
    isBarista: void 0,
    isExpandable: true,
    isFirstMessage: false,
    isLastMessage: false,
    isMe: false,
    isScoped: false,
    paramValues: void 0,
    showTimeStamp: false,
    timeStamp: void 0,
    typing: false,
    userId: void 0,
  };

  render() {
    const {
      channelId,
      channelType,
      dateTimeFormat,
      hideAvatar,
      isExpandable,
      isFirstMessage,
      isLastMessage,
      isMe,
      isBarista,
      isScoped,
      messageText,
      paramValues,
      showTimeStamp,
      timeStamp,
      typing,
      userId,
    } = this.props;

    const placement = {
      isFirstMessage,
      isLastMessage,
      isMe,
    };

    // First take the string and split into an array at any consecutive line returns
    // Trim the text in each item so we don't have extra returns or spaces
    const splitMessage = messageText
      ? messageText.split(/\n\n/).map((text) => text.trim())
      : [];
    // Remove any empty indexes in the array.
    const cleanMessageArray = splitMessage.filter(String);
    // Find the index of the last child in the array
    const textArrayLastIndex = cleanMessageArray.length - 1;

    return (
      <ChatMessageWrapper isMe={isMe}>
        {typing ? (
          <ChatMessageBubble
            {...placement}
            hideAvatar
            isBarista={isBarista}
            isExpandable={isExpandable}
            isScoped={isScoped}
            typing
          />
        ) : (
          cleanMessageArray.map((text, i) => (
            <ChatMessageBubble
              channelId={channelId}
              channelType={channelType}
              dateTimeFormat={dateTimeFormat}
              hideAvatar={i === 0 && hideAvatar}
              isBarista={isBarista}
              isExpandable={isExpandable}
              isFirstMessage={i === 0 && isFirstMessage}
              isLastMessage={i === textArrayLastIndex && isLastMessage}
              isMe={isMe}
              isScoped={isScoped}
              key={i}
              paramValues={paramValues}
              text={text}
              userId={userId}
            />
          ))
        )}
        {!typing && timeStamp && showTimeStamp && isLastMessage ? (
          <ChatMessageTimeStamp timeStamp={timeStamp} />
        ) : null}
      </ChatMessageWrapper>
    );
  }
}

export default createComponent(rule, ChatMessageText, [
  'channelId',
  'channelType',
  'dateTimeFormat',
  'hideAvatar',
  'isExpandable',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'isBarista',
  'isScoped',
  'messageText',
  'noAvatar',
  'paramValues',
  'showTimeStamp',
  'timeStamp',
  'typing',
  'userId',
]);
