import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOtherCareerAssistance extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path d='M22.126 0H.332v63h21.794z' id='a' />
          <path d='M0 63h77.126V0H0z' id='c' />
        </defs>
        <g fill={color} fillRule='evenodd' transform='translate(9 16.5)'>
          <g transform='translate(55)'>
            <mask fill='#fff' id='b'>
              <use xlinkHref='#a' />
            </mask>
            <path
              d='M22.105 14.83l-.035-.21c-.283-2.35-2.272-4.19-4.692-4.19l-4.118-.034v-.24c1.95-.774 3.33-2.666 3.33-4.89C16.59 2.36 14.235 0 11.326 0 8.416 0 6.06 2.358 6.06 5.267c0 2.126 1.266 3.947 3.08 4.778v.317l-4.033-.034c-2.535 0-4.594 1.953-4.714 4.46v1.15l-.06 18.08h.05c.048.96.827 1.73 1.8 1.73.972 0 1.75-.77 1.8-1.73h.044l.24-14.33c-.005-.01.65 0 .64.005l.643.016-.29 13.35v27.18c-.007.08-.023.155-.023.236 0 .083.016.16.024.24v.344h.05C5.58 62.175 6.57 63 7.76 63c1.19 0 2.18-.826 2.443-1.934h.1V34.012h1.575v26.27h.052c-.006.067-.02.132-.02.2 0 1.39 1.127 2.518 2.516 2.518 1.39 0 2.52-1.127 2.52-2.517 0-.103-.02-.2-.03-.3v-27.91l.007-1.4.08-11.06h1.26l.062 13.562.006.105c.03.978.82 1.766 1.808 1.766s1.78-.788 1.807-1.766l.006-.262.18-18.03v-.02l-.02-.338z'
              fill='#F00'
              mask='url(#b)'
            />
          </g>
          <mask fill='#fff' id='d'>
            <use xlinkHref='#c' />
          </mask>
          <path
            d='M41.098 62.24h10.336V10.815H41.098M27.316 62.24h10.336V20.415H27.316M13.536 62.24H23.87V30.41H13.536M0 62.24h10.336V41.31H0'
            fill='#F00'
            mask='url(#d)'
          />
        </g>
      </svg>
    );
  }
}

export default CatalogOtherCareerAssistance;
