import React, { useCallback, useEffect, useState } from 'react';

import pt from 'prop-types';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import DepartmentField from '../../../../molecules/DepartmentField';
import LocationField from '../../../../molecules/LocationField';
import { fromJS } from 'immutable';
import { isEmpty, isUndefined } from 'lodash';
import useOffboardingSchedule from '../hooks/useOffboardingSchedule';

const propTypes = {
  addNewGroup: pt.func,
  groups: pt.oneOfType([pt.arrayOf(pt.shape({})), pt.shape({})]),
  readOnly: pt.bool,
  schedule: pt.shape({}),
  scheduleID: pt.string,
  setFieldValue: pt.func,
  values: pt.shape({
    departingEmployeeRecipients: pt.shape({
      groups: pt.shape({}),
    }),
  }),
};

const jobRoleFormatter = (jobRoles) => {
  return jobRoles.map((role, index) => {
    return {
      key: `${role.id}_${role.name}_${index}_key`,
      text: role.name,
      value: role.id,
    };
  });
};

const ScheduleOffboardingGroupSegment = ({
  addNewGroup,
  groups,
  readOnly,
  scheduleID,
  values,
  setFieldValue,
}) => {
  const { departingEmployeeRecipients } = values;

  const [fieldValues, setFieldValues] = useState({});

  const {
    allJobRoles,
    allLocations,
    getJobRoles,
    createNewGroup,
    getDepartments,
    getLocations,
    departments: allDepartments,
  } = useOffboardingSchedule();

  const onHandleRemove = useCallback(() => {}, []);
  const onHandleSelect = useCallback(
    (data, type, groupName) => {
      if (type === 'locations') {
        const currentLocations =
          values.departingEmployeeRecipients.groups[`${groupName}`].locations;

        if (data?.get('id')) {
          const newID = data?.get('id');

          setFieldValue(
            `departingEmployeeRecipients.groups['${groupName}'].locations`,
            [...currentLocations, newID]
          );
        }
      } else if (type === 'departments') {
        const currentDepartmentValues =
          values.departingEmployeeRecipients.groups[`${groupName}`].departments;

        setFieldValue(
          `departingEmployeeRecipients.groups['${groupName}'].departments`,
          [...currentDepartmentValues, data.get('id')]
        );
      }
    },
    [setFieldValue, values.departingEmployeeRecipients.groups]
  );
  const handleChangeIncludeRemote = useCallback(
    (e, { checked, groupname }) => {
      const name = `departingEmployeeRecipients.groups["${groupname}"].include_remote`;
      setFieldValue(name, checked);
    },
    [setFieldValue]
  );
  const handleJobRoleSelection = useCallback(
    (e, { value, groupname }) => {
      setFieldValue(
        `departingEmployeeRecipients.groups["${groupname}"].job_roles`,
        value
      );
    },
    [setFieldValue]
  );
  const handleAddGroup = useCallback(() => {
    createNewGroup().then((newGroup) => {
      addNewGroup([
        ...groups,
        { ...newGroup, departments: [], job_roles: [], locations: [] },
      ]);
    });
  }, [addNewGroup, createNewGroup, groups]);

  const setFieldValuesFormattedInState = useCallback(
    (groups, { allDepartments, allLocations }) => {
      const defaultValuesPerGroup = groups.map((group) => ({
        defaultDepartments: group.departments,
        defaultIncludeRemote: group.include_remote,
        defaultJobRoles: group.job_roles,
        defaultLocations: group.locations,
      }));

      const defaultValues = defaultValuesPerGroup.map((defValGroup, index) => {
        const defValues = {
          key: `group[${index}]`,
        };
        if (!isEmpty(defValGroup.defaultDepartments)) {
          const depts = defValGroup.defaultDepartments;
          const filtered = depts.map((depID) => {
            return allDepartments.filter((dept) => dept.value === depID)[0];
          });
          defValues.departments = filtered;
        }
        if (!isEmpty(defValGroup.defaultJobRoles)) {
          defValues.job_roles = defValGroup.defaultJobRoles;
        }
        if (!isEmpty(defValGroup.defaultLocations)) {
          const locations = defValGroup.defaultLocations;

          const filtered = locations.map((locId) => {
            return allLocations.filter((loc) => {
              return loc.id === locId;
            })[0];
          });
          defValues.locations = filtered;
        } else {
          defValues.locations = defValGroup.defaultLocations;
        }

        defValues.include_remote = defValGroup.defaultIncludeRemote;

        return defValues;
      });
      const formattedValues = {};

      defaultValues.forEach((defaultValue) => {
        formattedValues[`${defaultValue.key}`] = {
          ...defaultValue,
        };
      });
      return formattedValues;
    },
    []
  );

  useEffect(() => {
    if (isEmpty(allJobRoles)) {
      getJobRoles({ id: '', name: '' });
    }
    if (isEmpty(allLocations)) {
      getLocations({});
    }
  }, [allJobRoles, allLocations, getJobRoles, getLocations]);

  useEffect(() => {
    if (
      !isUndefined(departingEmployeeRecipients?.groups) &&
      Object.values(departingEmployeeRecipients?.groups).length !==
        groups.length
    ) {
      setFieldValue(
        `departingEmployeeRecipients.groups['group[${groups.length - 1}]']`,
        groups[groups.length - 1]
      );
    }
  }, [
    departingEmployeeRecipients,
    departingEmployeeRecipients?.groups,
    groups,
    scheduleID,
    setFieldValue,
  ]);

  useEffect(() => {
    if (isEmpty(allDepartments)) {
      getDepartments();
    }
    if (
      !isEmpty(allDepartments) &&
      !isEmpty(allJobRoles) &&
      !isEmpty(allLocations) &&
      !isEmpty(groups)
    ) {
      const formattedValues = setFieldValuesFormattedInState(groups, {
        allDepartments,
        allJobRoles,
        allLocations,
      });
      setFieldValues(formattedValues);
    }
  }, [
    allDepartments,
    setFieldValuesFormattedInState,
    getDepartments,
    groups,
    allJobRoles,
    allLocations,
  ]);

  useEffect(() => {
    if (values.departingEmployeeRecipients.groups) {
      const updatedValues = setFieldValuesFormattedInState(
        Object.values(values?.departingEmployeeRecipients?.groups),
        {
          allDepartments,
          allJobRoles,
          allLocations,
        }
      );
      setFieldValues(updatedValues);
    }
  }, [
    allDepartments,
    setFieldValuesFormattedInState,
    groups,
    values,
    allJobRoles,
    allLocations,
    scheduleID,
  ]);

  return (
    <Segment>
      {!readOnly ? (
        <Button onClick={handleAddGroup} type='button'>
          {'Add Group'} <Icon name='plus' />
        </Button>
      ) : null}
      {groups?.map((group, index) => {
        const groupName = `group[${index}]`;
        return (
          <Segment key={`${index}_group_segment`}>
            <Header as='h4' dividing>
              {`Group ${Number(index + 1)}`}
            </Header>
            <Form.Field>
              <DepartmentField
                departments={fromJS(
                  fieldValues[`${groupName}`]?.departments || []
                )}
                groupName={groupName}
                handleRemoveDepartment={onHandleRemove}
                handleSelectDepartment={onHandleSelect}
                name={`departingEmployeeRecipients.groups.${groupName}.departments`}
                placeholder='All'
                readOnly={readOnly}
              />
            </Form.Field>
            <Form.Field>
              <LocationField
                groupName={groupName}
                handleRemoveLocation={onHandleRemove}
                handleSelectLocation={onHandleSelect}
                locations={fromJS(fieldValues[`${groupName}`]?.locations || [])}
                name={`departingEmployeeRecipients.groups.${groupName}.locations`}
                placeholder='All'
                readOnly={readOnly}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={fieldValues[`${groupName}`]?.include_remote}
                groupname={groupName}
                label='Include remote workers from target location'
                name={`departingEmployeeRecipients.groups.${groupName}.include_remote`}
                onChange={handleChangeIncludeRemote}
              />
            </Form.Field>
            <Form.Field>
              <label>{'Job Roles'}</label>
              {/* // add job roles */}
              {/* // its a simple select, refill with job titles */}
              <Dropdown
                fluid
                groupname={groupName}
                multiple
                name={`departingEmployeeRecipients.groups.${groupName}.job_roles`}
                onChange={handleJobRoleSelection}
                options={jobRoleFormatter(allJobRoles)}
                search
                selection
                value={fieldValues[`${groupName}`]?.job_roles || []}
              />
            </Form.Field>
          </Segment>
        );
      })}
    </Segment>
  );
};

ScheduleOffboardingGroupSegment.propTypes = propTypes;
export default ScheduleOffboardingGroupSegment;
