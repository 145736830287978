import { createSelector } from 'reselect';

import espTheme from '../../fela/EspTheme';

// Selectors
import getTenantBranding from './getTenantBranding';

const getTheme = (tenantBranding) => {
  const primaryColor = tenantBranding.get('tenantPrimaryColor');
  const headerColor = tenantBranding.get('tenantHeaderColor');

  const theme = espTheme(primaryColor, headerColor);
  return theme;
};

const selector = createSelector([getTenantBranding], getTheme);

export default selector;
