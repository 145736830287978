import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { i18Locales } from 'esp-util-intl';
import { Dropdown, Form } from 'semantic-ui-react';

/**
 * Number of days per month in the year
 */
const DAYS_IN_MONTHS = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * This year differs from the one used in the API (1904) because FormattedDate component behaves erratically with years prior to 1921
 * in: intl 1.2.5 + react-intl 2.4.0
 *
 * 2018-01-25 UPDATE: This issue is no longer present in Chrome Version 63.0.3239.132 (Official Build) (64-bit), therefore is no longer necessary
 */
const YEAR = 1904;

const DEFAULT_VALUE = `${YEAR}-01-01`;

class BirthdayPicker extends Component {
  /**
   * Creates a collection of option items for a Dropdown component based on the required number of days, from 1 to numberOfDays
   * @param {number} numberOfDays
   */
  static createDayOptions(numberOfDays) {
    return _.range(1, numberOfDays + 1).map((i) => ({
      text: i > 9 ? i : `0${i}`,
      value: i,
    }));
  }

  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string,
    }),
    label: PropTypes.string,
    locale: PropTypes.string,
    upward: PropTypes.bool,
  };

  static defaultProps = {
    input: {
      onChange: _.noop,
      value: DEFAULT_VALUE,
    },
    label: '',
    locale: i18Locales.en_us,
    upward: false,
  };

  constructor(props) {
    super(props);

    const { input, locale } = this.props;

    let { value } = input;
    if (_.isEmpty(value)) {
      value = DEFAULT_VALUE;
    }

    const month = Number(value.slice(5, 7)) - 1;
    const day = Number(value.slice(8));

    this.state = {
      numberOfDays: DAYS_IN_MONTHS[month],
      selectedDay: day,
      selectedMonth: month,
    };

    moment.locale(locale);

    this.monthOptions = moment.months().map((name, index) => ({
      text: name,
      value: index,
    }));
  }

  /**
   * Handles month selection by updating the local state
   * @param {SyntheticEvent} e
   * @param {*} data object return by Dropdown component, it includes a value property which holds current selection for month
   */
  handleMonthSelection = (e, { value }) => {
    const { selectedMonth } = this.state;
    if (value !== selectedMonth) {
      const numberOfDays = DAYS_IN_MONTHS[value];
      this.setState((previousState) => {
        const day =
          previousState.selectedDay <= numberOfDays
            ? previousState.selectedDay
            : 1;
        this.triggerChange({
          day,
          month: value,
        });
        return {
          numberOfDays,
          selectedDay: day,
          selectedMonth: value,
        };
      });
    }
  };

  /**
   * Handles day selection by updating the local state
   * @param {SyntheticEvent} e
   * @param {*} data object return by Dropdown component, it includes a value property which holds current selection for day
   */
  handleDaySelection = (e, { value }) => {
    const { selectedDay } = this.state;
    if (value !== selectedDay) {
      this.setState((previousState) => {
        this.triggerChange({
          day: value,
          month: previousState.selectedMonth,
        });
        return {
          selectedDay: value,
        };
      });
    }
  };

  // Creates a date string with YYYY-MM-DD format, as required for the API, based on currently selected month and day and triggers the onChange props event
  triggerChange = ({ month, day }) => {
    const { input } = this.props;

    const { onChange } = input;
    if (onChange) {
      onChange(
        `${YEAR}-${_.padStart(month + 1, 2, 0)}-${_.padStart(day, 2, 0)}`
      );
    }
  };

  render() {
    const { label, upward } = this.props;

    const { numberOfDays, selectedDay, selectedMonth } = this.state;

    return (
      <Form.Group widths='equal'>
        <Form.Field>
          {label && <label>{label}</label>}
          <Dropdown
            compact
            onChange={this.handleMonthSelection}
            options={this.monthOptions}
            placeholder={'Select month'}
            search
            selection
            upward={upward}
            value={selectedMonth}
          />
        </Form.Field>
        <Form.Field>
          <Dropdown
            className='month_select'
            compact
            onChange={this.handleDaySelection}
            options={BirthdayPicker.createDayOptions(numberOfDays)}
            placeholder={'Select day'}
            search
            selection
            upward={upward}
            value={selectedDay}
          />
        </Form.Field>
      </Form.Group>
    );
  }
}

export default BirthdayPicker;
