import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { noop } from 'lodash';
import UserImgController from './UserImgController';
import BaristaUserImg from './BaristaUserImg';

import AvatarDefault from '../../display/AvatarDefault';

import uiPathGenerator from '../../../../v1/utils/uiPathGenerator';
import { ImageDefaults } from 'esp-assets';

const IMG_STYLE = {
  borderRadius: '256px',
  display: 'block',
  maxWidth: '100%',
  overflow: 'hidden',
};

class UserImg extends PureComponent {
  static propTypes = {
    error: PropTypes.bool,
    isBarista: PropTypes.bool,
    isLinkingDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isProfileLink: PropTypes.bool,
    isUserActive: PropTypes.bool,
    loadUserData: PropTypes.func, // from the controller
    userId: PropTypes.number,
    userImgUrl: PropTypes.string,
    userInitials: PropTypes.string,
  };

  static defaultProps = {
    error: false,
    isBarista: false,
    isLinkingDisabled: false,
    isLoading: false,
    isProfileLink: false,
    isUserActive: true,
    loadUserData: noop,
    userId: void 0,
    userImgUrl: void 0,
    userInitials: void 0,
  };

  constructor(props) {
    super(props);

    // NOTE: We still need a "user loading" state in addition to the image loading state
    this.state = {
      imgError: false,
      imgLoaded: Boolean(props.userImgUrl),
    };
  }

  componentDidMount() {
    const { userId, loadUserData, userImgUrl } = this.props;

    if (!userImgUrl) {
      // Only load user info when a userImgUrl has not been passed in order to get the initial and user img
      loadUserData(userId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      loadUserData,
      userId,
      userImgUrl,
      userInitials,
      isLoading,
    } = this.props;

    if (!isLoading) {
      // first check that is not loading
      if (
        // then check props changed
        nextProps.userId !== userId ||
        nextProps.userImgUrl !== userImgUrl ||
        nextProps.userInitials !== userInitials
      ) {
        // console.warn(`UNSAFE_componentWillReceiveProps=${nextProps.userInitials}`);

        loadUserData(nextProps.userId);
      }
    }
  }

  handleImageLoaded = () => {
    this.setState({
      imgLoaded: true,
    });
  };

  handleImageError = () => {
    const { loadUserData, userId } = this.props;
    this.setState({
      imgError: true,
    });

    loadUserData(userId);
  };

  renderLoader = (content) => {
    const { userImgUrl, userInitials } = this.props;

    const { imgLoaded } = this.state;

    // if we don't have user initials or an url, then means we're in loading state
    // we cannot rely on props.isLoading because it can be caused by a *reload* of data
    // which can we avoid to use loader states therem since we should be able to render something
    const loaderActive =
      (!userImgUrl && !userInitials) || (userImgUrl && !imgLoaded);

    if (!loaderActive) {
      return content;
    }

    return (
      <Dimmer.Dimmable blurring style={IMG_STYLE}>
        <Dimmer active inverted={!imgLoaded}>
          <Loader indeterminate />
        </Dimmer>
        {content}
      </Dimmer.Dimmable>
    );
  };

  renderImage = () => {
    const { error, isBarista, userId, userImgUrl, userInitials } = this.props;

    const { imgError, imgLoaded } = this.state;

    if (imgError || error) {
      return (
        <img alt='default-user' src={ImageDefaults.BROKEN} style={IMG_STYLE} />
      );
    } else if (isBarista) {
      // Probably we should do the same for all other svggs

      return <BaristaUserImg />;
    } else if (userImgUrl && userId) {
      let src = userImgUrl;
      if (window.cordova && src && src.indexOf('http') !== 0) {
        // do NOT do this for web images
        const wwwFolder =
          window.device.platform === 'IOS' ? 'www/data/' : 'www/';

        src = src[0] === '/' ? src.substring(1, src.length) : src;
        src = `${window.cordova.file.applicationDirectory}${wwwFolder}${src}`;
      }

      return this.renderLoader(
        <>
          {src ? (
            <img
              alt={userId}
              onError={this.handleImageError}
              onLoad={this.handleImageLoaded}
              src={src}
              style={{
                ...IMG_STYLE,
                display: imgLoaded ? 'block' : 'none',
                maxHeight: '100%',
              }}
            />
          ) : null}

          {imgLoaded ? null : (
            <AvatarDefault
              backgroundSeed={userId}
              initials={userInitials}
              responsive
            />
          )}
        </>
      );
    } else if (userId || userId === 0) {
      return this.renderLoader(
        <AvatarDefault
          backgroundSeed={userId}
          initials={userInitials}
          responsive
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      isBarista,
      isProfileLink,
      isUserActive,
      userId,
      isLinkingDisabled,
    } = this.props;

    const linkStyle = {
      display: 'block',
      maxWidth: '100%',
    };

    if (
      isProfileLink &&
      userId &&
      isUserActive &&
      !isBarista &&
      !isLinkingDisabled
    ) {
      return (
        <Link
          style={linkStyle}
          tabIndex='-1'
          to={uiPathGenerator.genPath('app.directory.detail', {
            userID: userId,
          })}
        >
          {this.renderImage()}
        </Link>
      );
    } else {
      return this.renderImage();
    }
  }
}

export const UserImgTest = UserImg;
export default UserImgController(UserImg);
