import React, { Component } from 'react';
import { fromJS } from 'immutable';
// globals
import TypeConversation, {
  TypeDefaults,
} from '../../../globals/blocks/TypeConversation';

// Molecules
import ConversationFeed from '../../molecules/ConversationFeed';
import BodyClassName from '../../../../globals/BodyClassName';

class Conversation01 extends Component {
  static propTypes = TypeConversation;
  static defaultProps = TypeDefaults;
  render() {
    const { messages, template, type, users } = this.props;
    return (
      <BodyClassName className='blocks-justify'>
        <div className='block' data-blocktype={type} data-template={template}>
          <ConversationFeed
            animated
            blockConvo
            isExpandable={false}
            messages={fromJS(messages)}
            users={fromJS(users)}
          />
        </div>
      </BodyClassName>
    );
  }
}

export default Conversation01;
