import { featureFlagOptions } from 'esp-globals';
import { isArray, set } from 'lodash';

const buildUIConfig = (tenant) => {
  if (tenant && tenant.ui_config) {
    const tenantPatchedUiConfig = tenant.ui_config || {};

    const uiConfig = {};

    Object.keys(featureFlagOptions).forEach((section) => {
      if (
        Object.prototype.hasOwnProperty.call(tenantPatchedUiConfig, section)
      ) {
        if (isArray(featureFlagOptions[section])) {
          // arrays are set directly
          const value = isArray(tenantPatchedUiConfig[section])
            ? tenantPatchedUiConfig[section]
            : featureFlagOptions[section]; // defaults to default value if the value is not array

          set(uiConfig, [section], value);
        } else {
          // loop into each of of the object properties
          Object.keys(tenantPatchedUiConfig[section]).forEach((option) => {
            if (
              Object.prototype.hasOwnProperty.call(
                featureFlagOptions[section],
                option
              )
            ) {
              set(
                uiConfig,
                [section, option],
                tenantPatchedUiConfig[section][option]
              );
            }
          });
        }
      } else {
        set(uiConfig, [section], featureFlagOptions[section]);
      }
    });

    return {
      ...tenant,
      ui_config: uiConfig,
    };
  }

  return {
    ...tenant,
    ui_config: { ...featureFlagOptions },
  };
};

export default buildUIConfig;
