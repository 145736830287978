/**
 * Allowed literals that can be used to define an email address rule.
 * Check docs from "/api/espuser/v0.1/email/address_policy/1/" for additional reference.
 */
const EmailAddressRuleLiterals = {
  DOT: '.',

  FIRST_NAME: '{{first_name}}',

  LAST_NAME: '{{last_name}}',

  LODASH: '_',

  NICKNAME: '{{nickname}}',

  RAND: '{{$$rand}}',

  SEQ: '{{$$seq}}',
};

export const RegexTokens = {
  DOT: '\\.',

  FIRST_NAME: '\\{\\{first_name(\\[0:(\\d+)\\])?\\}\\}',

  LAST_NAME: '\\{\\{last_name(\\[0:(\\d+)\\])?\\}\\}',

  LODASH: '_',

  NICKNAME: '\\{\\{nickname(\\[0:(\\d+)\\])?\\}\\}',

  RAND: '\\{\\{\\$\\$rand\\}\\}',

  SEQ: '\\{\\{\\$\\$seq\\}\\}',
};

export default EmailAddressRuleLiterals;
