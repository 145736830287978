import { fromJS } from 'immutable';
import { OnboardActivities } from 'esp-svg';

// Future Categories
// Todo - Update Icon when they will be available
const OnboardActivitiesCategory = fromJS({
  'Banking or Corporate Card': {
    id: 12,
    image: OnboardActivities.CorporateCreditCard,
    name: 'Banking or Corporate Card',
  },
  'Benefit Deadline': {
    id: 6,
    image: OnboardActivities.BenefitDeadline,
    name: 'Benefit Deadline',
  },
  Checklist: {
    id: 20,
    image: OnboardActivities.Checklist,
    name: 'Checklist',
  },
  'Compliance Task': {
    id: 3,
    image: OnboardActivities.ComplianceTask,
    name: 'Compliance Task',
  },
  'Enrollment Deadline': {
    id: 5,
    image: OnboardActivities.EnrollmentDeadline,
    name: 'Enrollment Deadline',
  },
  'Generic Activity': {
    id: 8,
    image: OnboardActivities.GenericActivity,
    name: 'Generic Activity',
  },
  'Important Reading​': {
    id: 9,
    image: OnboardActivities.ImportantReading,
    name: 'Important Reading​',
  },
  'Invite to Meeting': {
    id: 7,
    image: OnboardActivities.MeetingInvite,
    name: 'Invite to Meeting',
  },
  'Large Event or Kickoff': {
    id: 10,
    image: OnboardActivities.KickoffEvent,
    name: 'Large Event or Kickoff',
  },
  'Mailing list or Collaboration': {
    id: 15,
    image: OnboardActivities.MailingListOrCollaboration,
    name: 'Mailing list or Collaboration',
  },
  'Online Training': {
    id: 2,
    image: OnboardActivities.OnlineTraining,
    name: 'Online Training',
  },
  Orientation: {
    id: 17,
    image: OnboardActivities.Orientation,
    name: 'Orientation',
  },
  'Performance Reviews': {
    id: 18,
    image: OnboardActivities.PerformanceReviews,
    name: 'Performance Reviews',
  },
  'Pick up Something': {
    id: 16,
    image: OnboardActivities.PickUp,
    name: 'Pick up Something',
  },
  'Reach Out or Chat With': {
    id: 13,
    image: OnboardActivities.ChatWith,
    name: 'Reach Out or Chat With',
  },
  'Set up Your Account': {
    id: 11,
    image: OnboardActivities.AccountProfile,
    name: 'Set up Your Account',
  },
  'Shop in Catalog': {
    id: 14,
    image: OnboardActivities.ShopInCatalog,
    name: 'Shop in Catalog',
  },
  'Travel Related': {
    id: 19,
    image: OnboardActivities.Travel,
    name: 'Travel Related',
  },
  'Turn in Documents': {
    id: 4,
    image: OnboardActivities.TurnInDocuments,
    name: 'Turn in Documents',
  },
  'Watch a Video': {
    id: 1,
    image: OnboardActivities.WatchVideo,
    name: 'Watch a Video',
  },
});

export default OnboardActivitiesCategory;
