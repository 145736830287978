import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Icon, List, Loader, Message } from 'semantic-ui-react';
import { Waypoint } from 'react-waypoint';
import Immmutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, isNumber, memoize, noop } from 'lodash';
import UserImage from '../../../../v1/components/atoms/UserImage';
import UserName from '../../../../v1/components/atoms/UserName';
import ScrollArea from '../../../../v1/components/atoms/ScrollArea';
// Utils
import buildUserServiceDepartmentDesc from '../../../../v1/utils/buildUserServiceDepartmentDesc';
// V2
// Molecules
import EspListItem from '../../../../v1/components/molecules/EspListItem';

class UserSelectList extends Component {
  static propTypes = {
    defaultSelectedUsers: ImmutablePropTypes.set,

    getNextDirectoryUsers: PropTypes.func,

    isCaseSearch: PropTypes.bool,

    isLoading: PropTypes.bool,

    isLoadingSearch: PropTypes.bool,

    loadNextMessage: PropTypes.string,

    pagination: PropTypes.string,

    selectUser: PropTypes.func,

    selectedUser: PropTypes.number,

    subscriberType: PropTypes.bool,

    usersList: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  };

  static defaultProps = {
    defaultSelectedUsers: Immmutable.Set(),
    getNextDirectoryUsers: noop,
    isCaseSearch: false,
    isLoading: false,
    isLoadingSearch: false,
    loadNextMessage: 'Loading more...',
    pagination: null,

    selectUser: noop,

    selectedUser: null,
    subscriberType: false,
    usersList: Immmutable.List(),
  };

  handleBottomWaypointEnter = () => {
    const { getNextDirectoryUsers } = this.props;
    getNextDirectoryUsers();
  };

  handleClickUser = memoize(
    curry((userID, firstName, location, e) => {
      const { selectUser } = this.props;
      selectUser(userID, firstName, location);
    })
  );

  render() {
    const {
      isCaseSearch,
      isLoading,
      isLoadingSearch,
      loadNextMessage,
      pagination,
      selectedUser,
      defaultSelectedUsers,
      usersList,
      subscriberType,
    } = this.props;

    const userListItems = usersList
      ? usersList.map((user) => {
          const isPreSelected = isNumber(
            defaultSelectedUsers.get(user.get('id'))
          );
          const nickname = user.get('nickname', '');
          const lastName = user.get('last_name', '');
          const name = `${nickname} ${lastName}`;
          const email = user.get('email', '');
          const department = user.get('department');
          const espProps = {
            as: 'a',
            content: user.get('title'),
            description: (
              <>
                {email}
                <br />
                {!subscriberType
                  ? isCaseSearch
                    ? buildUserServiceDepartmentDesc(user)
                    : department
                  : department}
              </>
            ),
            disabled: isPreSelected,
            id: user.get('id'),
            image: <UserImage avatar key={user.get('id')} thumb user={user} />,
            isGreyedOut: isPreSelected,
            isLoading: isLoadingSearch,
            isSelected:
              (!isLoading && selectedUser === user.get('id')) || isPreSelected,
            name: <UserName user={user} />,
            onClick: this.handleClickUser(
              user.get('id'),
              name,
              user.get('location', '')
            ),
          };
          return <EspListItem {...espProps} key={user.get('id')} />;
        })
      : null;

    const setWayPoint = pagination ? (
      <Waypoint key={pagination} onEnter={this.handleBottomWaypointEnter} />
    ) : null;

    const paginationMessage =
      isLoading && !isLoadingSearch && !usersList.isEmpty() ? (
        <Message icon>
          <Icon loading name='circle notched' />
          <Message.Content>{loadNextMessage}</Message.Content>
        </Message>
      ) : null;

    const userLoader =
      (isLoading || isLoadingSearch) && usersList.isEmpty() ? (
        <Dimmer active inverted>
          <Loader size='large' />
        </Dimmer>
      ) : null;

    return (
      <ScrollArea height='100%' padding='1rem 0'>
        <List divided>
          {userLoader}

          {userListItems}
        </List>

        {setWayPoint}

        {paginationMessage}
      </ScrollArea>
    );
  }
}

export default UserSelectList;
