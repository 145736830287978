import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

const blockAttrsDefinition = _.merge({}, BlockBase, {
  defaultNetwork: {
    required: true,
    type: 'string',
  },
  disabledOptions: {
    default: '',
    type: 'string',
  },
  inputRequired: {
    default: false,
    type: 'bool',
  },
  labelText: {
    required: true,
    type: 'string',
  },
  mediaType: {
    required: true,
    restrictToOptions: ['social', 'collab'],
    type: 'string',
  },
  template: {
    default: 'socialMedia01',
  },
});

const SocialMediaBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Social Media',
  templates: ['socialMedia01'],
};

const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

export { propTypes as default, SocialMediaBlockDefinition, TypeDefaults };
