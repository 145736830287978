const api_config_v0_1_configuration_notification_sms_disable = {
  DEBUG_decrypted: null,
  created_in_elc: false,
  eid: '1111',
  is_protected: false,
  key: 'notification.sms.disable',
  last_sync_time: null,
  src_active_status: true,
  sys_date_updated: '2021-03-02T14:18:56.670808Z',
  type: 'boolean',
  value: true,
};

export default api_config_v0_1_configuration_notification_sms_disable;
