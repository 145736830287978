import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import classNames from 'classnames';
import ErrorLabel from '../atoms/ErrorLabel';
import GetDefaultPlaceholderByInputType from '../../../globals/GetDefaultPlaceholderByInputType';

class FormInputText extends PureComponent {
  static propTypes = {
    autoCapitalize: PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    charLimitError: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    forceFocus: PropTypes.bool,
    formError: PropTypes.string,
    hidden: PropTypes.bool,
    icon: PropTypes.node,
    iconPosition: PropTypes.string,
    id: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelPosition: PropTypes.string,
    loading: PropTypes.bool,
    max: PropTypes.string,
    maxLength: PropTypes.number,
    meta: PropTypes.shape({
      active: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      submitFailed: PropTypes.bool,
    }),
    min: PropTypes.string,
    onChange: PropTypes.func,
    onInputLoaded: PropTypes.func,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    removeErrorHandler: PropTypes.func,
    required: PropTypes.bool,
    showErrorsWithoutSubmitting: PropTypes.bool,
    size: PropTypes.string,
    synchroError: PropTypes.bool,
    transparent: PropTypes.bool,
    type: PropTypes.string,
    width: PropTypes.number,
    widths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    autoCapitalize: null,
    autoComplete: 'false',
    autoFocus: false,
    charLimitError: null,
    defaultValue: null,
    disabled: false,
    fluid: false,
    forceFocus: false,
    formError: '',
    hidden: false,
    icon: null,
    iconPosition: null,
    id: null,
    inline: false,
    label: '',
    labelPosition: null,
    loading: false,
    max: void 0,
    maxLength: void 0,
    meta: {},
    min: void 0,
    onChange: null,
    onInputLoaded: null,
    pattern: null,
    placeholder: '',
    readOnly: false,
    removeErrorHandler: null,
    required: false,
    showErrorsWithoutSubmitting: false,
    size: void 0,
    synchroError: false,
    transparent: false,
    type: 'text',
    width: null,
    widths: null,
  };

  componentDidMount() {
    const { onInputLoaded, input } = this.props;
    onInputLoaded && onInputLoaded(input);
  }

  fieldRef = null;

  handlerRef = (ref) => {
    const { forceFocus } = this.props;
    if (!this.fieldRef && forceFocus) {
      this.fieldRef = ref;
      ref.focus();
    }
  };

  // It can use a customized change event or default
  handleChange = (param, data) => {
    const { input, onChange, removeErrorHandler } = this.props;

    if (data.type === 'number') {
      data.value = data && data.value ? Number(data.value) : null;
    }

    removeErrorHandler && removeErrorHandler();
    if (onChange) {
      return onChange(param, data);
    }
    return input.onChange(data.value);
  };

  handleBlur = (event) => {
    const { input } = this.props;
    if (input.value && input.onChange) {
      if (typeof input.value === 'string' && !input.value.trim()) {
        return input.onChange('');
      }
    }

    return input.onBlur(event.target.value ? event.target.value : '');
  };

  render() {
    const {
      autoCapitalize,
      autoComplete,
      autoFocus,
      charLimitError,
      defaultValue,
      disabled,
      fluid,
      formError,
      hidden,
      icon,
      iconPosition,
      id,
      inline,
      input,
      label,
      labelPosition,
      loading,
      max,
      maxLength,
      meta: { active, error, submitFailed },
      min,
      pattern,
      placeholder,
      readOnly,
      required,
      showErrorsWithoutSubmitting,
      size,
      synchroError,
      transparent,
      type,
      width,
      widths,
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    const finalPlaceholder = !placeholder
      ? GetDefaultPlaceholderByInputType(type)
      : placeholder;

    return (
      <Form.Field
        className={classNames({
          hidden: hidden,
          required: required,
        })}
        inline={inline}
        width={width}
      >
        {label && typeof label === 'string' && (
          <label htmlFor={input.name}>{label}</label>
        )}
        <Input
          {...input}
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          // eslint-disable-next-line jsx-a11y/no-autofocus -- feature long waiting autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          fluid={fluid}
          icon={icon}
          iconPosition={iconPosition}
          input={{
            id: id ? id : input.name,
            type: type,
          }}
          label={typeof label === 'object' ? label : null}
          labelPosition={labelPosition}
          loading={loading}
          max={max}
          maxLength={maxLength}
          min={min}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          pattern={pattern}
          placeholder={finalPlaceholder}
          readOnly={readOnly}
          ref={this.handlerRef}
          size={size}
          transparent={transparent}
          type={type}
          widths={widths}
        />
        {(formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )) ||
          (showErrorsWithoutSubmitting && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (!active && submitFailed && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (synchroError && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (charLimitError && (
            <ErrorLabel
              error={charLimitError}
              inputName={input.name}
              pointing='above'
            />
          ))}
      </Form.Field>
    );
  }
}

export default FormInputText;
