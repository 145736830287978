import { createSelector } from 'reselect';
import { List, Map, OrderedMap } from 'immutable';
import _ from 'lodash';

import getDirectoryUsers from './getDirectoryUsers';

import DirectorySortCriteria from '../utils/DirectorySortCriteria';

const getSortBy = (state) => state.getIn(['directory', 'sortBy']);

const hideUnactiveUsers = (state, hideUnactiveUsers = false) =>
  hideUnactiveUsers;

/**
 * Get the initial letter of a user given a sort criteria.
 * An empty String is returned if an initial is not available for the provided sort
 * criteria.
 *
 * @param user An Immutable Map.
 * @param sortBy Any String from DirectorySortCriteria.
 */
const getUserInitial = (user, sortBy) => {
  switch (sortBy) {
    case DirectorySortCriteria.FIRST_NAME: {
      const nickname = user.get('nickname');

      if (_.isEmpty(nickname)) {
        return '';
      }

      return _.head(nickname).toUpperCase();
    }

    case DirectorySortCriteria.LAST_NAME: {
      const lastName = user.get('last_name');

      if (_.isEmpty(lastName)) {
        return '';
      }

      return _.head(lastName).toUpperCase();
    }

    // for other sort criteria, we want a single group to exist
    // under '' initial
    default:
      return '';
  }
};

/**
 * Returns an Immutable List of user groups, where the users of each group have
 * the same initial returned by getUserInitial for the given sortBy criteria.
 * Each group has a 'letter' String that represents it and a non empty 'users' List.
 *
 * @param users An Immutable List of users.
 * @param sortBy Any String from DirectorySortCriteria.
 */
const getDirectoryUserGroupsByLetter = (users, sortBy, hideUnactiveUsers) => {
  if (users === null) {
    return null;
  }

  // hide unActive users if hideUnactiveUser is true
  const activeUsers = hideUnactiveUsers
    ? users.filter((u) => Boolean(u.get('is_active')))
    : users;

  let userGroupsByLetter = OrderedMap();

  activeUsers.forEach((user) => {
    const initial = getUserInitial(user, sortBy);

    if (!userGroupsByLetter.has(initial)) {
      let newGroup = Map();

      newGroup = newGroup.set('letter', initial).set('users', List());

      userGroupsByLetter = userGroupsByLetter.set(initial, newGroup);
    }

    let group = userGroupsByLetter.get(initial);

    let groupUsers = group.get('users');
    groupUsers = groupUsers.push(user);

    group = group.set('users', groupUsers);

    userGroupsByLetter = userGroupsByLetter.set(initial, group);
  });

  return userGroupsByLetter.toList();
};

const selector = createSelector(
  [getDirectoryUsers, getSortBy, hideUnactiveUsers],
  getDirectoryUserGroupsByLetter
);

export default selector;
