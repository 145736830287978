import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { memoize, noop } from 'lodash';
import { Message } from 'semantic-ui-react';
// Globals
import TypeCreateNewFaq, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCreateNewFaq';
import UpdateEntityValueInstanceController from '../../controllers/UpdateEntityValueInstanceController';

/**
 * This block is an empty component which allow to set an alternate submit and
 * create update an entity
 */

class UpdateEntityValueInstance01 extends PureComponent {
  static propTypes = {
    ...TypeCreateNewFaq,
    forceResetError: PropTypes.func.isRequired,
    updateEntityValueInstance: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  componentDidMount() {
    const { setAlternateSubmit, setAlternateBack } = this.props;

    setAlternateSubmit(this.handleClick());
    setAlternateBack(this.handleClickBack());
  }

  handleClick = memoize((cb = noop) => {
    const { updateEntityValueInstance } = this.props;
    updateEntityValueInstance(cb);
  });

  handleClickBack = memoize((cb = noop) => {
    const { forceResetError } = this.props;

    forceResetError(cb);
  });

  render() {
    const { errorsApi, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {errorsApi &&
          !errorsApi.isEmpty() &&
          errorsApi.getIn([0, 'parameterName']) === 'email' && (
            <Message
              content={errorsApi.getIn([0, 'message'])}
              header='Error'
              negative
            />
          )}
      </div>
    );
  }
}

export const UpdateEntityValueInstance01Test = UpdateEntityValueInstance01;

export default UpdateEntityValueInstanceController(UpdateEntityValueInstance01);
