import { createSelector } from 'reselect';
import Immutable, { fromJS } from 'immutable';

const getEmailCredentials = (state) =>
  state.getIn(['integration', 'emailCredentials']);

const getEmailsSettings = (credentials) => {
  let caseGenerator = Immutable.Map(),
    primary = Immutable.Map();

  if (credentials && credentials.size) {
    credentials.forEach((cred) => {
      const isPrimary = cred.get('is_primary');
      const isCase = cred.get('is_for_case');

      if (isPrimary) {
        primary = isCase ? cred.set('is_for_case', true) : cred;
      } else if (isCase) {
        caseGenerator = cred;
      }
    });
  }

  return fromJS({
    caseGenerator,
    primary,
  });
};

const selector = createSelector([getEmailCredentials], getEmailsSettings);

export default selector;
