import React, { PureComponent } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { Boundaries } from 'cascara-middleware';

class ReportGridFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    onDropDownChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    filters: [],
    isLoading: false,
    onDropDownChange: noop,
    onKeyDown: noop,
    onSearch: noop,
  };

  dropDown = ({ key, label, options, value }) => {
    const { onDropDownChange, isLoading } = this.props;

    return (
      <span
        key={key}
        style={{
          flex: '0 1 auto',
          marginLeft: 'auto',
        }}
      >
        {label}
        <Dropdown
          disabled={isLoading}
          id={key}
          inline
          onChange={onDropDownChange}
          options={options}
          value={value}
        />
      </span>
    );
  };

  searchBar = ({ key, label, placeholder, value }) => {
    const { onSearch, isLoading, onKeyDown } = this.props;
    return (
      <span
        key={key}
        style={{
          flex: '0 1 auto',
          marginRight: '1rem',
          width: '30%',
        }}
      >
        {label}
        <Input
          disabled={isLoading}
          fluid
          icon='search'
          iconPosition='left'
          id={key}
          loading={isLoading}
          maxLength={200}
          onChange={onSearch}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          value={value}
        />
      </span>
    );
  };

  render() {
    const { filters } = this.props;

    const TYPES = {
      DROPDOWN: 'DROPDOWN',
      SEARCHBAR: 'SEARCHBAR',
    };

    return (
      <Boundaries>
        <div
          key={'grid-key'}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            margin: '1rem 0',
          }}
        >
          {filters.map((filter) => {
            if (TYPES.SEARCHBAR !== filter.type) {
              return this.dropDown(filter);
            }
            if (TYPES.SEARCHBAR === filter.type) {
              return this.searchBar(filter);
            }
            return null;
          })}
        </div>
      </Boundaries>
    );
  }
}

export default ReportGridFilter;
