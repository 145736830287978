import React from 'react';
import pt from 'prop-types';
import { Form, Header, Segment } from 'semantic-ui-react';

const propTypes = {
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]).isRequired,
  description: pt.string,
  dividing: pt.bool,
  title: pt.string,
};

const AnnouncementSection = ({ children, description, title, dividing }) => {
  return (
    <>
      {title && <Header as='h4' content={title} dividing={dividing} />}

      {/* This is optional so we want to make sure not to render the markup tag if no description is defined. */}
      {description && <p>{description}</p>}

      {/* This is strictly for styling. All .field classses need to be direct children of a .ui.form class. This does not render a <form> tag. */}
      <Form as={Segment} basic>
        {children}
      </Form>
    </>
  );
};

AnnouncementSection.propTypes = propTypes;

export default AnnouncementSection;
