import React, { PureComponent } from 'react';
// import {Button}               from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import Immutable                     from "immutable/dist/immutable";
import FormInputFileRef from './FormInputFileRef';
import FilePickerUtils from '../../utils/FilePickerUtils';

class AttachFAQFile extends PureComponent {
  static propTypes = {
    onAttachmentChange: PropTypes.func,
    renderTrigger: PropTypes.func,
  };

  static defaultProps = {
    onAttachmentChange: _.noop,
    renderTrigger: _.noop,
  };

  inputElement = null;

  handleTriggerInputOnOpen = (e) => {
    e.preventDefault();

    FilePickerUtils.promptPicturePicker({
      inputRef: this.inputElement,
      message: 'Select image source',
      promptCallback: this.onAttachmentChangeCordova,
      title: 'Change profile image',
    });
  };

  onAttachmentChanged = (e) => {
    e.preventDefault();

    const { onAttachmentChange } = this.props;

    const [file] = e.target.files;

    if (file) {
      onAttachmentChange(this.inputElementProps, file);
    }
  };

  handleOnClick(e) {
    e.target.value = null;
  }

  setInputRef = (ref, props) => {
    this.inputElement = ref;
    this.inputElementProps = props;
  };

  render() {
    const { renderTrigger } = this.props;
    return (
      <>
        {renderTrigger(this.handleTriggerInputOnOpen)}
        <Field
          accept='*'
          component={FormInputFileRef}
          handleChange={this.onAttachmentChanged}
          id='select_file_faq'
          name='select_file_faq'
          onClick={this.handleOnClick}
          setRef={this.setInputRef}
        />
      </>
    );
  }
}

export default AttachFAQFile;
