import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import // Transition,
'semantic-ui-react';

import ChatMessageWrapper from './ChatMessageWrapper';
import ChatMessageTimeStamp from './ChatMessageTimeStamp';
import ChatMessageImg from './ChatMessageImg';
import ChatMessageFile from './ChatMessageFile';

const rule = () => {};

class ChatMessageAttachment extends PureComponent {
  static propTypes = {
    attachment: PropTypes.shape({
      get: PropTypes.func,
      height: PropTypes.number,
      size: PropTypes.number,
      url: PropTypes.string,
      width: PropTypes.number,
    }).isRequired,
    attachmentSize: PropTypes.number,
    hideAvatar: PropTypes.bool,
    isFirstMessage: PropTypes.bool.isRequired,
    isLastMessage: PropTypes.bool.isRequired,
    isMe: PropTypes.bool.isRequired,
    isSelectable: PropTypes.bool,
    onDownload: PropTypes.func,
    onImageLoaded: PropTypes.func,
    showTimeStamp: PropTypes.bool,
    timeStamp: PropTypes.string.isRequired,
  };

  static defaultProps = {
    attachmentSize: 0,
    hideAvatar: false,
    isSelectable: false,
    onDownload: void 0,
    onImageLoaded: void 0,
    showTimeStamp: false,
  };

  render() {
    const {
      attachment,
      hideAvatar,
      isFirstMessage,
      isLastMessage,
      isMe,
      isSelectable,
      onDownload,
      onImageLoaded,
      showTimeStamp,
      timeStamp,
    } = this.props;

    const placement = {
      isFirstMessage,
      isLastMessage,
      isMe,
      isSelectable,
      showTimeStamp,
    };

    const url = attachment.get('url') || '';
    const width = attachment.get('width') || void 0;
    const height = attachment.get('height') || void 0;
    const size = attachment.get('size') || void 0;

    const imgExt = /\.(gif|jpe?g|png|svg)$/i;
    const urlNoParams = url.substring(0, url.indexOf('?')) || url;
    const isImage = imgExt.test(urlNoParams);

    return (
      <ChatMessageWrapper {...placement}>
        <>
          {isImage ? (
            <ChatMessageImg
              {...placement}
              attachment={url}
              attachmentSize={size}
              height={height}
              hideAvatar={hideAvatar}
              onImageLoaded={onImageLoaded}
              width={width}
            />
          ) : (
            <ChatMessageFile
              {...placement}
              attachment={url}
              attachmentSize={size}
              hideAvatar={hideAvatar}
              onDownload={onDownload}
            />
          )}

          {timeStamp && showTimeStamp && isLastMessage ? (
            <ChatMessageTimeStamp timeStamp={timeStamp} />
          ) : null}
        </>
      </ChatMessageWrapper>
    );
  }
}

export default createComponent(rule, ChatMessageAttachment, [
  'attachment',
  'hideAvatar',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'isSelectable',
  'onDownload',
  'onImageLoaded',
  'showTimeStamp',
  'timeStamp',
]);
