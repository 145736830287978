import { Regexes } from 'esp-globals';

/**
 * Looks for espressive markdown links e.g. [https://www.espressive.com](https://www.espressive.com){:target="_blank"}
 * and convert them into a valid markdown link [https://www.espressive.com](https://www.espressive.com)
 * @param {String} text - text which should container an espressive markdown link
 * @param {Pick<import('../EspMarkdown').EspMarkdownProps, 'noExternalLinks'>} config - EspMarkdown props
 * @returns {String} string with plain text/markdown link/s
 */
const replaceLinkWithTarget = (text, { noExternalLinks }) =>
  !noExternalLinks
    ? text.replace(
        new RegExp(Regexes.linkWithTarget, 'gm'),
        (match, $1, $2, $3) => `[${$2}](${$3})`
      )
    : text;

export default replaceLinkWithTarget;
