import { curry, last, memoize, noop } from 'lodash';
import React, { PureComponent } from 'react';
import { Regexes, UserAgentUtils } from 'esp-globals';

// Globals
import EspEntities from '../../globals/EspEntities';
import EspMarkdown from 'esp-markdown';
// Controllers
import EspMetaVariablesController from '../controllers/EspMetaVariablesController';
// Atom
import ExternalLink from '../atoms/ExternalLink';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

const entitiesPaths = [
  uiPathGenerator.genPath('app.directory'),
  uiPathGenerator.genPath('app.equipment'),
  uiPathGenerator.genPath('app.messages.direct'),
  uiPathGenerator.genPath('app.messages.scoped'),
  uiPathGenerator.genPath('app.standaloneProduct'),
];

const kbEntities = [
  EspEntities.KB_ARTICLE,
  EspEntities.ESP_KB,
  EspEntities.KB_NUMBER,
  EspEntities.CONFLUENCE_KB,
  EspEntities.ZENDESK_KB,
  EspEntities.SHAREPOINT_KB,
  EspEntities.IVANTI_KB,
];

const kbEntityToIdType = {
  [EspEntities.CONFLUENCE_KB]: 'confluenceId',
  [EspEntities.ESP_KB]: 'responseEid',
  [EspEntities.IVANTI_KB]: 'ivantiId',
  [EspEntities.KB_ARTICLE]: 'sys_id',
  [EspEntities.KB_NUMBER]: 'number',
  [EspEntities.SHAREPOINT_KB]: 'sharepointId',
  [EspEntities.ZENDESK_KB]: 'zendeskId',
};

const kbEntityDivider = '~~';

/**
 * Used to render text with special Espressive tags and variables
 * Please see test file for more example usage. Syntax available:
 * {{name}} : Will render variable value.label declared in paramValues prop (immutable map)
 * [[name]] : Will render variable value declared in 'globals' prop (immutable map passed down by controller)
 * **text here** : Will render text in bold <b>
 * [text](url) : Will render markdown format as link
 * http://site.com : will render text string as link
 * [text](users.EspUser id) : Will render internal link to user profile
 * [text](assets.EspAsset id) : Will render internal link to asset detail
 */

class EspMetaVariables extends PureComponent {
  static propTypes = {
    avoidFollowLinks: PropTypes.bool,
    globals: ImmutablePropTypes.map, // Passed down by the Controller
    globalsAsUpperCase: PropTypes.bool, // Rend the string from Global uppercase
    isBarista: PropTypes.bool,

    isScoped: PropTypes.bool,

    loadKbArticle: PropTypes.func,

    // from Controller and based on userId
    noAnchorTag: PropTypes.bool,
    noExternalLinks: PropTypes.bool,
    noInternalLinks: PropTypes.bool,
    noMarkdownEmphasis: PropTypes.bool,
    noMarkdownLinks: PropTypes.bool,
    openInternaLinkFromBarista: PropTypes.func,
    paramValues: ImmutablePropTypes.map,
    text: PropTypes.string,
  };

  static defaultProps = {
    avoidFollowLinks: false, // remove an anchor the property of been followed
    globals: Immutable.Map(),
    globalsAsUpperCase: false,
    isBarista: void 0, // If it's barista who's talking funny things hha
    isScoped: false,
    loadKbArticle: noop,
    noAnchorTag: false, // replace <a> tags for <span></span> tags when a markdown link could be inside a component made by an <a> tag
    noExternalLinks: false,
    noInternalLinks: false,
    noMarkdownEmphasis: false,
    noMarkdownLinks: false, // Avoid rendering of anchor styles to markdown links
    openInternaLinkFromBarista: noop,
    paramValues: null,
    text: '',
  };

  handleNewWindowLink = (href) => (e) => {
    // main-process.js already handles external links
    if (!UserAgentUtils.isElectron()) {
      e.preventDefault();
      window.open(href, '_blank', 'toolbar=0,location=0,menubar=0');
    }
  };

  handleClickInternalLink = (event) => {
    const {
      avoidFollowLinks,
      isBarista,
      noInternalLinks,
      openInternaLinkFromBarista,
    } = this.props;

    if (avoidFollowLinks) {
      event.preventDefault(); // block link click
    } else if (isBarista && !noInternalLinks) {
      // block internal links when barista is using this
      openInternaLinkFromBarista(event);
    }
  };

  handleOpenEspressiveLink = (event) => {
    const {
      avoidFollowLinks,
      isBarista,
      noInternalLinks,
      openInternaLinkFromBarista,
    } = this.props;

    if (avoidFollowLinks) {
      event.preventDefault(); // block link click
    } else if (isBarista && !noInternalLinks) {
      // block internal links when barista is using this
      openInternaLinkFromBarista(event, true);
    }
  };

  /**
   *
   * @param {*} kbNumber unique identifier of the article in its knoledge base
   * @param {*} idType one of: sys_id, number, confluenceId, responseEid, zendeskId. See baristaThunks.openKbArticle
   * @param {*} event passed by link onClick event
   */
  handleOpenKbArticle = memoize(
    curry((kbParenthesisValues, idType, event) => {
      const { avoidFollowLinks, loadKbArticle } = this.props;

      event.preventDefault(); // block link click

      const [espId, clickContext] = kbParenthesisValues.split(kbEntityDivider);

      if (!avoidFollowLinks) {
        loadKbArticle({
          clickContext,
          [idType]: espId,
        });
      }
    })
  );

  handleAnyOtherLink = (event) => {
    const { avoidFollowLinks } = this.props;
    if (avoidFollowLinks) {
      event.preventDefault(); // block link click
    }
  };

  render() {
    const { globals, paramValues, ...restProps } = this.props;

    return (
      <EspMarkdown
        {...Object.assign(restProps, {
          globalVariables: globals ? globals.toJS() : void 0,
          paramValues: paramValues ? paramValues.toJS() : void 0,
        })}
        customComponents={{
          a: ({ extendedProps, ...restProps }) => {
            const { noAnchorTag } = this.props;
            const { href, children } = restProps;
            const { text } = extendedProps;
            const kbRegex = new RegExp(
              `(${kbEntities.join('|')})${kbEntityDivider}(\\S+)`
            );
            const entitiesRegex = new RegExp(
              `(${entitiesPaths.join('|')})\\/(\\S+)`
            );

            const isCategoryEntity = new RegExp('\\/app\\/shop');
            // const categoryEntityRegex = `(app/shop\\/(\\S+)`;
            const isInternalLink = new RegExp('espressive.com\\/app\\/');

            if (kbRegex.test(href)) {
              const [, espEntity, kbParenthesisValues] =
                kbRegex.exec(href) || [];

              return (
                <NavLink
                  data-type={kbEntityToIdType[espEntity]}
                  data-values={kbParenthesisValues}
                  onClick={this.handleOpenKbArticle(
                    kbParenthesisValues,
                    kbEntityToIdType[espEntity]
                  )}
                  to={'#'}
                >
                  {children}
                </NavLink>
              );
            } else if (entitiesRegex.test(href)) {
              const relativeLink = last(href.split('espressive.com'));
              return (
                <NavLink
                  onClick={this.handleClickInternalLink}
                  to={relativeLink}
                >
                  {children}
                </NavLink>
              );
            } else if (isCategoryEntity.test(href)) {
              const relativeLink = last(href.split('espressive.com'));
              return (
                <NavLink
                  onClick={this.handleClickInternalLink}
                  to={relativeLink}
                >
                  {children}
                </NavLink>
              );
            } else if (isInternalLink.test(href)) {
              const relativeLink = last(href.split('espressive.com'));
              return noAnchorTag ? (
                <span style={{ wordBreak: 'break-word' }}>{children}</span>
              ) : (
                <NavLink
                  onClick={this.handleOpenEspressiveLink}
                  to={relativeLink}
                >
                  {children}
                </NavLink>
              );
            } else if (new RegExp(Regexes.linkWithTarget, 'gm').test(text)) {
              return (
                <ExternalLink
                  onClick={this.handleNewWindowLink(restProps.href)}
                  {...restProps}
                />
              );
            }

            const isExternalLink = href === children[0];

            return noAnchorTag && !isExternalLink ? (
              <span style={{ wordBreak: 'break-word' }}>{children}</span>
            ) : (
              <ExternalLink {...restProps} />
            );
          },
        }}
      />
    );
  }
}

const EspMetaVariablesTest = EspMetaVariables;
// eslint-disable-next-line no-class-assign -- DEV-1526
EspMetaVariables = EspMetaVariablesController(EspMetaVariables);
export { EspMetaVariablesTest };
export default EspMetaVariables;
