/**
 * Get the due date on a template from onboard activity
 * @param template {Immutable Map}
 * @returns {string}
 */

// Todo - Use React intl
const getDueDate = (template) => {
  let dueOn = 'No due date';

  if (template.get('enable_deadline')) {
    const days = template.get('deadline_days') || 0;
    const hours = template.get('deadline_hours') || 0;

    const daysActive = days && days > 0;
    const hoursActive = hours && hours > 0;

    const textDays = days > 1 ? ' days' : ' day';
    const textHours = hours > 1 ? ' hours' : ' hour';

    dueOn = 'Due in ';

    if (daysActive) {
      dueOn = dueOn + days + textDays;

      if (hoursActive) {
        dueOn = `${dueOn} and ${hours}${textHours}`;
      }
    }

    if (!daysActive && hoursActive) {
      dueOn = dueOn + hours + textHours;
    }

    if (!daysActive && !hoursActive) {
      dueOn =
        "Error, day and hour can't be equal to zero if enable due date is active";
    }
  }

  return dueOn;
};

export default getDueDate;
