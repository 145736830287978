import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { first, isEmpty, noop, range } from 'lodash';
import { Form } from 'semantic-ui-react';
import { FormikWithSemanticUI } from 'esp-ui-form';
import FrequencyOptions from '../../../../v1/globals/FrequencyOptions';
import FrequencyTypes from '../../../../v1/globals/FrequencyTypes';

const INTERVAL_OPTIONS = {
  [FrequencyTypes.DAYS]: range(1, 366).map((interval) => ({
    key: interval,
    text: interval === 1 ? `${interval} day` : `${interval} days`,
    value: interval,
  })),
  [FrequencyTypes.HOURS]: range(12, 25).map((interval) => ({
    key: interval,
    text: interval === 1 ? `${interval} hour` : `${interval} hours`,
    value: interval,
  })),
};

const DEFAULT_INTERVAL = {
  [FrequencyTypes.DAYS]: first(INTERVAL_OPTIONS[FrequencyTypes.DAYS]).value,
  [FrequencyTypes.HOURS]: first(INTERVAL_OPTIONS[FrequencyTypes.HOURS]).value,
};

const MIN_DATE = moment().utc().add(1, 'day').format('YYYY-MM-DD');
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

class ImportFrequency extends PureComponent {
  static propTypes = {
    errors: PropTypes.objectOf(PropTypes.bool),
    handleBlur: PropTypes.func,
    id: PropTypes.string,
    length: PropTypes.number,
    onAdd: PropTypes.func,
    onError: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func,
    onValid: PropTypes.func,
    order: PropTypes.number,
    setFieldValue: PropTypes.func,
    setValues: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
    values: PropTypes.shape({
      interval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      periodicity: PropTypes.string,
      start_date: PropTypes.string,
      start_time: PropTypes.string,
    }),
  };

  static defaultProps = {
    errors: {},
    handleBlur: noop,
    id: null,
    length: 0,
    onAdd: noop,
    onError: noop,
    onRemove: noop,
    onUpdate: noop,
    onValid: noop,
    order: 0,
    setFieldValue: noop,
    setValues: noop,
    touched: {},
    values: {},
  };

  componentDidMount() {
    const {
      id,
      onUpdate,
      values: { start_date, start_time, ...values },
    } = this.props;

    onUpdate(id, {
      ...values,
      start_time: moment(
        `${start_date} ${start_time}`,
        DATE_TIME_FORMAT
      ).toISOString(),
    });
    this.validateForm();
  }

  componentDidUpdate() {
    this.validateForm();
  }

  handleBlur = (e) => {
    const { handleBlur } = this.props;
    handleBlur(e, e.target);
  };

  handleChange = (e, { name, value }) => {
    const {
      id,
      onUpdate,
      setFieldValue,
      setValues,
      values: { start_date, start_time, ...values },
    } = this.props;

    const startDate = `${name === 'start_date' && value ? value : start_date}`;
    const startTime = `${
      name === 'start_time' && value ? ` ${value}` : ` ${start_time}`
    }`;
    const startDateTime = moment(
      `${startDate}${startTime}`,
      DATE_TIME_FORMAT
    ).toISOString();

    if (name === 'periodicity') {
      // Set interval default for the selected periodicity
      setValues({
        ...values,
        interval: DEFAULT_INTERVAL[value],
        [name]: value,
        start_date,
        start_time,
      });
      onUpdate(id, {
        ...values,
        interval: DEFAULT_INTERVAL[value],
        [name]: value,
        start_time: startDateTime,
      });
    } else {
      setFieldValue(name, value);
      onUpdate(id, {
        ...values,
        [name]: value,
        start_time: startDateTime,
      });
    }
  };

  handleRemove = () => {
    const { onRemove, id } = this.props;
    onRemove(id);
  };

  validateForm = () => {
    const { errors, onError, onValid } = this.props;

    if (!isEmpty(errors)) {
      onError();
    } else {
      onValid();
    }
  };

  render() {
    const { errors, length, onAdd, order, touched, values } = this.props;

    return (
      <Form.Group>
        <>
          <Form.Input
            error={touched.start_date && errors.start_date}
            min={MIN_DATE}
            name='start_date'
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            type='date'
            value={values.start_date}
            width={3}
          />

          <Form.Input
            error={touched.start_time && errors.start_time}
            min={MIN_DATE}
            name='start_time'
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            type='time'
            value={values.start_time}
            width={3}
          />
        </>
        <Form.Dropdown
          className='periodicity'
          compact
          name='periodicity'
          onChange={this.handleChange}
          options={FrequencyOptions}
          selection
          value={values.periodicity}
          width={3}
        />
        <Form.Field
          style={{
            lineHeight: '2.2em',
            textAlign: 'center',
          }}
          width={1}
        >
          {'every'}
        </Form.Field>
        <Form.Dropdown
          className='interval'
          compact
          name='interval'
          onChange={this.handleChange}
          options={INTERVAL_OPTIONS[values.periodicity]}
          search
          selection
          value={values.interval}
          width={3}
        />
        {length > 1 && (
          <Form.Button
            basic
            icon={'minus'}
            onClick={this.handleRemove}
            size='large'
          />
        )}
        {order + 1 === length && (
          <Form.Button basic icon={'plus'} onClick={onAdd} size='large' />
        )}
      </Form.Group>
    );
  }
}

const ImportFrequencyTest = ImportFrequency;
export { ImportFrequencyTest };

export default FormikWithSemanticUI({
  enableReinitialize: true,
  mapPropsToValues: ({ values }) => {
    const getValue = (key, defaultValue) =>
      (values &&
        Object.prototype.hasOwnProperty.call(values, key) &&
        values[key]) ||
      defaultValue;
    const periodicity = first(FrequencyOptions).value;
    return {
      interval: getValue('interval', DEFAULT_INTERVAL[periodicity]),
      periodicity: getValue('periodicity', first(FrequencyOptions).value),
      start_date: getValue('start_date', MIN_DATE),
      start_time: getValue('start_time', '12:00'),
    };
  },
  validate: (values) => {
    const errors = {};

    if (!values.start_date) {
      errors.start_date = true;
    }

    if (!values.start_time) {
      errors.start_time = true;
    }

    return errors;
  },
})(ImportFrequency);
