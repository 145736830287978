import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsHealthwellness extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M48 4C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4zm0 83C26.46 87 9 69.54 9 48S26.46 9 48 9s39 17.46 39 39c0 10.343-4.11 20.263-11.423 27.577C68.263 82.89 58.343 87 48 87z' />
          <path d='M71.29 33.44h-8.77v-8.77c-.006-2.58-2.1-4.67-4.68-4.67H38.12c-2.577.006-4.664 2.093-4.67 4.67v8.77h-8.78c-2.58.006-4.67 2.1-4.67 4.68v19.73c.006 2.577 2.093 4.664 4.67 4.67h8.77v8.77c-.008 1.246.482 2.445 1.36 3.33.88.883 2.074 1.38 3.32 1.38h19.73c2.577-.006 4.664-2.093 4.67-4.67v-8.81h8.77c1.246.008 2.445-.482 3.33-1.36.883-.88 1.38-2.074 1.38-3.32V38.12c0-1.246-.497-2.44-1.38-3.32-.885-.878-2.084-1.368-3.33-1.36zM60.27 50l-.12.13L50 60.27c-.362.36-.85.56-1.36.56-.51 0-1-.2-1.36-.56L37.17 50.13c-2.49-3.238-2.14-7.83.81-10.655 2.95-2.825 7.554-2.973 10.68-.345 3.097-2.656 7.697-2.556 10.676.233 2.978 2.788 3.38 7.372.934 10.637z' />
          <path d='M54.41 40.51c-1.785-.002-3.366 1.15-3.91 2.85-.253.797-.993 1.34-1.83 1.34-.837 0-1.577-.543-1.83-1.34-.522-1.604-1.97-2.726-3.654-2.83-1.683-.105-3.26.83-3.975 2.356-.713 1.527-.424 3.336.73 4.564l8.73 8.74 8.7-8.7c1.136-1.194 1.448-2.95.794-4.46-.653-1.512-2.145-2.488-3.792-2.48l.04-.04z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsHealthwellness;
