import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import { Switch } from 'react-router-dom';

/**
 * A React Router 4 Switch that spreads it's props down to it's children.
 */
const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

const EspSwitch = ({ children, location = null, ...rest }) => (
  <Switch location={location}>
    {Children.map(children, (child) => cloneElement(child, { ...rest }))}
  </Switch>
);

EspSwitch.propTypes = propTypes;

export default EspSwitch;
