import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// molecules
import TenantImage from './TenantImage';
import SearchBox from './SearchBox';
import HeaderSearchModal from './HeaderSearchModal';
import HeaderDelegateSelect from './HeaderDelegateSelect';
// Organism
import CartModal from '../organisms/CartModal';

// utils
import TenantImageTypes from '../../utils/TenantImageTypes';
import uiPathGenerator from '../../utils/uiPathGenerator';

import styles from './EspAppHeader.module.css';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const EspAppHeader = ({ location }) => (
  <header
    className={`${styles.base} esp-app-header`}
    style={
      window.electron // Makes the header draggable in an electron window
        ? {
            '-webkit-app-region': 'drag',
          }
        : void 0
    }
  >
    <div className='brand'>
      <Link to={uiPathGenerator.genFullPattern('app.toDo')}>
        <TenantImage size={'tiny'} type={TenantImageTypes.LOGO} />
      </Link>
    </div>
    <SearchBox location={location} />
    <div className='actions'>
      {/* Leave this div here for styling... I hate this. -bje */}
      <div />
      <HeaderSearchModal />
      <HeaderDelegateSelect />
      <CartModal />
    </div>
  </header>
);

EspAppHeader.propTypes = propTypes;

export default EspAppHeader;
