import IntlMessageFormat from 'intl-messageformat';
import { upperFirst } from 'lodash';
import { messages } from './intlProvider';

const utils = {};

// Will replace underscore by space and remove key context
const keyToText = (key) =>
  upperFirst(key.replace(/.*\./, '').replace(/_/g, ' ').toLowerCase());

utils.getMessage = (id) => (messages[id] ? messages[id] : keyToText(id));

utils.getCompoundMessage = (message, values) =>
  new IntlMessageFormat(message).format(values);

export default utils;
