import React, { useCallback, useContext } from 'react';
import { labels } from '../utils/constants';
import AzureFormikContext from './AzureFormikContext';
import { Form, Input } from 'semantic-ui-react';
import { Field as FormikField } from 'formik';
import pt from 'prop-types';

const propTypes = {
  isEditable: pt.bool,
};

const AzureBasicSSOForm = ({ isEditable = true }) => {
  const MAX_LENGTH = 120;

  const { values, handleChange } = useContext(AzureFormikContext);

  const handleCheckBoxChange = useCallback(
    (_, { checked }) =>
      handleChange({
        target: { name: 'is_password_reset_enabled', value: checked },
      }),
    [handleChange]
  );

  return (
    <Form as={'div'}>
      <Form.Field>
        <label htmlFor='name'>{labels.NAME}</label>

        <FormikField
          component={Input}
          disabled={!isEditable}
          id='name'
          maxLength={MAX_LENGTH}
          name='name'
          onChange={handleChange}
          type='text'
          value={values.name}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='azure_client_id'>{labels.CLIENT_ID}</label>

        <FormikField
          component={Input}
          disabled={!isEditable}
          id='azure_client_id'
          maxLength={MAX_LENGTH}
          name='azure_client_id'
          onChange={handleChange}
          type='text'
          value={values.azure_client_id}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='azure_client_secret'>{labels.CLIENT_SECRET}</label>

        <FormikField
          component={Input}
          data-testid='azure_client_secret'
          disabled={!isEditable}
          id='azure_client_secret'
          maxLength={MAX_LENGTH}
          name='azure_client_secret'
          onChange={handleChange}
          role='password'
          type='password'
          value={values.azure_client_secret}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='azure_tenant_id'>{labels.TENANT_ID}</label>

        <FormikField
          component={Input}
          disabled={!isEditable}
          id='azure_tenant_id'
          maxLength={MAX_LENGTH}
          name='azure_tenant_id'
          onChange={handleChange}
          type='text'
          value={values.azure_tenant_id}
        />
      </Form.Field>

      <Form.Checkbox
        checked={values.is_password_reset_enabled}
        disabled={!isEditable}
        id='checkbox'
        label={labels.ENABLE_EMPLOYEE_PASSWORD_RESET}
        name='is_password_reset_enabled'
        onChange={handleCheckBoxChange}
      />

      <Form.Field
        style={{
          fontSize: '0.8em',
          left: 31,
          position: 'relative',
        }}
      >
        <em>{labels.CHECKBOX_DESCRIPTION}</em>
      </Form.Field>
    </Form>
  );
};

AzureBasicSSOForm.propTypes = propTypes;

export default AzureBasicSSOForm;
