import React, { useCallback, useContext, useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { Button, Input } from 'reakit';

import tenantContext from '../../context/tenant/tenantContext';

import { ImageDefaults } from 'esp-assets';
import styles from '../Auth.module.scss';

const propTypes = {
  info: pt.bool,

  initialValue: pt.string,

  negative: pt.bool,

  onSubmit: pt.func.isRequired,
};

//
// Tenant form
//
// This form allows the User to select a tenant.
//
// @param {Function} onSubmit A function to run when the user submits the form.
// @param {String} initialValue The initial value for companyName
// @param {String} message A string message to display
const TenantForm = ({ info, initialValue = '', negative, onSubmit }) => {
  const fields = {
    company: {
      label: 'Tenant Name',
      name: 'tenant',
      placeholder: 'Your Company',
    },
    next: {
      label: 'Next',
    },
  };

  const formHeader = negative ? 'There was an error' : 'Welcome!';
  const { message } = useContext(tenantContext) || {};
  const [company, setCompany] = useState(initialValue);
  const isCompanyEmpty = !company;
  const submitButtonProps = !isCompanyEmpty ? {} : { disabled: true };

  const messageClassName = cx('ui mini top attached message', {
    info,
    negative,
  });

  const submitButtonClassName = cx('ui primary right floated button', {
    disabled: isCompanyEmpty,
  });

  const handleCompanyInputChange = useCallback(
    (event) => {
      event.preventDefault();
      const {
        target: { value: newUserName },
      } = event;

      setCompany(newUserName);
    },
    [setCompany]
  );

  const handleSubmit = useCallback(
    (submissionEvent) => {
      submissionEvent.preventDefault();

      onSubmit(company);
    },
    [company, onSubmit]
  );

  return (
    <div className={styles.Form}>
      <img
        alt='Espressive logo'
        className={styles.Logo}
        src={ImageDefaults.ESPRESSIVE_LOGO}
        type='logo'
      />
      {message && (
        <div className={messageClassName}>
          <div className='content'>
            <div className='header'>{formHeader}</div>
            <p>{message}</p>
          </div>
        </div>
      )}

      <form
        className='ui form bottom attached segment'
        id='tenant'
        onSubmit={handleSubmit}
      >
        <div className='field'>
          <label htmlFor={fields.company.name}>{fields.company.label}</label>
          <div className='ui right labeled input'>
            <Input
              id={fields.company.name}
              name={fields.company.name}
              onChange={handleCompanyInputChange}
              placeholder={fields.company.placeholder}
              type='text'
              value={company}
            />
            <div className='ui basic label'>.espressive.com</div>
          </div>
        </div>
        <Button
          className={submitButtonClassName}
          type='submit'
          {...submitButtonProps}
        >
          {fields.next.label}
        </Button>

        {/* We need this clearing, hidden divider here since the login button is 
        floated. There is a bug in the SUI CSS that does not keep the clearfix 
        on a segment when it is loading. */}
        <div className='ui clearing hidden fitted divider' />
      </form>
    </div>
  );
};

TenantForm.propTypes = propTypes;

export default TenantForm;
