import { sortByActive } from './utils';
import types from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return { ...state, isLoading: !state.isLoading };
    case types.SET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          color: action.color,
          messages: action.messages,
        },
      };
    case types.RESET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          color: action.color,
          dirty: false,
          messages: action.messages.sort(sortByActive),
        },
      };
    case types.UPDATE_FORM: {
      const set = new Set([...action.custom_messages, ...state.form.messages]);

      return {
        ...state,
        customMessagesRaw: action?.custom_messages_raw,
        form: {
          ...state.form,
          dirty: false,
          messages: Array.from(set).sort(sortByActive),
        },
        isLoading: false,
      };
    }
    case types.SET_COLOR:
      return {
        ...state,
        form: {
          ...state.form,
          color: action.color,
          dirty: true,
        },
      };
    case types.MERGE_CUSTOM_MESSAGES:
      return {
        ...state,
        customMessagesRaw: action?.custom_messages_raw,
        form: {
          ...state.form,
          dirty: false,
          messages: action.custom_messages
            .concat(...state.form.messages)
            .sort(sortByActive),
        },
      };
    case types.SET_MESSAGES:
      return {
        ...state,
        form: {
          ...state.form,
          dirty: true,
          messages: action.messages,
        },
      };

    case types.SHOW_CONFIRM:
      return {
        ...state,
        deleteItemIndex: action.index,
        isDeleteConfirmOpen: true,
      };
    case types.HIDE_CONFIRM:
      return {
        ...state,
        deleteItemIndex: null,
        isDeleteConfirmOpen: false,
      };
    case types.DELETE_TIP_GROUP: {
      return {
        ...state,
        customMessagesRaw: action?.custom_messages_raw,
        deleteItemIndex: null,
        form: {
          ...state.form,
          messages: state.form.messages.filter(
            (_, index) => index !== action.index
          ),
        },
        isDeleteConfirmOpen: false,
      };
    }
    case types.SHOW_EDIT_MODAL:
      return {
        ...state,
        isTipModalOpen: true,
        modalForm: {
          ...state.modalForm,
          currentMessage: action.message,
          dirty: false,
          messages: action.message.texts,
          newMessage: '',
        },
      };
    case types.SHOW_MODAL:
      return {
        ...state,
        isTipModalOpen: true,
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        isTipModalOpen: false,
        modalForm: {
          currentMessage: null,
          dirty: false,
          messages: [],
          newMessage: '',
        },
      };
    case types.SET_TIP:
      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          dirty: true,
          newMessage: action.newMessage,
        },
      };
    case types.ADD_TIP:
      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          dirty: true,
          messages: action.messages,
          newMessage: '',
        },
      };
    case types.DELETE_TIP:
      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          dirty: true,
          messages: state.modalForm.messages.filter(
            (message) => message.id !== action.id
          ),
        },
      };
    case types.EDIT_TIP: {
      const newMessages = [...state.modalForm.messages];

      const messageIndex = newMessages.findIndex(
        (message) => message.id === action.id
      );

      newMessages[messageIndex].isEditable =
        !newMessages[messageIndex]?.isEditable;

      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          dirty: true,
          messages: newMessages,
        },
      };
    }
    case types.EDIT_CHANGE_TIP: {
      const newMessages = [...state.modalForm.messages];

      const messageIndex = newMessages.findIndex(
        (message) => message.id === action.id
      );

      newMessages[messageIndex].text = action.text;
      newMessages[messageIndex].isEditable = false;

      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          messages: newMessages,
        },
      };
    }

    case types.SAVE_FORM: {
      return {
        ...state,
        customMessagesRaw: action?.custom_messages_raw,
        form: {
          ...state.form,
          messages: action.messages.sort(sortByActive),
        },
        isLoading: false,
        isTipModalOpen: false,
        modalForm: {
          currentMessage: null,
          dirty: false,
          messages: [],
          newMessage: '',
        },
      };
    }
    default: {
      throw new Error();
    }
  }
};

export default reducer;
