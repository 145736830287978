import buildCompositeController from '../../utils/buildCompositeController';

import workflowThunks from '../../actions/workflowThunks';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  redirectToOnboardWorkflow(workflowID) {
    dispatch(workflowThunks.redirectToOnboardWorkflow(workflowID));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
