// Stores
import store from '../stores/store';

// Selectors
import getCurrentUser from '../selectors/getCurrentUser';

const validateAnonsOnly = () => {
  const currentUser = getCurrentUser(store.getState());

  const isLoggedIn = Boolean(currentUser);

  // If user is inactive, would be the same to consider it anonymous
  // Before changing this, see validateLoginRequired too
  if (currentUser && !currentUser.get('is_active')) {
    return false;
  }

  return isLoggedIn;
};

export default validateAnonsOnly;
