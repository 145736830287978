import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Dimmer, Loader } from 'semantic-ui-react';

// Molecule
import ValidatedDate from '../../molecules/ValidatedDate';
import ValidatedField from '../../molecules/ValidatedField';
import FormInputTextarea from '../../molecules/FormInputTextarea';

// Controller
import Field03Controller from '../../controllers/Field03Controller';

// Globals
import TypeField, { TypeDefaults } from '../../../globals/blocks/TypeField';

const charLimit = 300;

class Field03 extends Component {
  static propTypes = {
    ...TypeField,
    changeReasonOnLoad: PropTypes.func.isRequired,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    initialDate: PropTypes.string,
    initialReason: PropTypes.string,
    isLoading: PropTypes.bool,
    reasonMsg: PropTypes.string,
    setStartingDateAndReason: PropTypes.func.isRequired,
    userId: PropTypes.number,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    initialDate: null,
    initialReason: null,
    isLoading: false,
    reasonMsg: null,
    userId: null,
  };

  state = {
    reasonError: '',
  };

  componentDidMount() {
    const { changeReasonOnLoad, initialReason } = this.props;

    if (initialReason) {
      changeReasonOnLoad(initialReason);
    }
  }

  setStartDate = (value, returnResult, noCheck) => {
    const { setStartingDateAndReason, reasonMsg, userId } = this.props;

    if (reasonMsg || noCheck) {
      // Reason msg ok - Send it to the thunk
      setStartingDateAndReason(value, reasonMsg, userId, () => {
        returnResult(reasonMsg);
      });
    } else {
      // Error no reason msg - Send formError to the field
      this.setState({
        reasonError: 'Please, add a reason before to select a new date',
      });
      returnResult(reasonMsg);
    }
  };

  handleResetError = (e) => {
    const { target } = e;
    const { reasonError } = this.state;

    if (reasonError) {
      this.setState(
        {
          reasonError: '',
        },
        () => {
          target.focus(); // Avoid to lose focus because of the setState
        }
      );
    }
  };

  handleUpdateCharLength(e, newValue) {
    const value = newValue.replace(/^\s+/g, '');

    if (value.length > charLimit) {
      e.preventDefault(); // Prevent too much characters
    }
  }

  render() {
    const {
      errors,
      errorsApi,
      id,
      initialDate,
      isLoading,
      labelText,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    const { reasonError } = this.state;

    const renderInput = isLoading ? (
      <Dimmer active inverted>
        <Loader size='large' />
      </Dimmer>
    ) : (
      <ValidatedField
        charLimit={charLimit}
        component={FormInputTextarea}
        formError={reasonError}
        label='Please provide a reason for the Start Date change:'
        name='reason'
        onChange={this.handleUpdateCharLength}
        onClick={this.handleResetError}
        placeholder='Example: family emergency'
        row={3}
      />
    );

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedDate
          beforeElement={renderInput}
          blockModal
          changebackValue
          enablePastDate={false}
          formError={finalError}
          iconPosition='left'
          id={id}
          initialDate={initialDate}
          label={labelText}
          name={id.toString()}
          placeholder={labelText}
          setStartingDate={this.setStartDate}
        />
      </div>
    );
  }
}

export const Field03Test = Field03;

export default Field03Controller(Field03);
