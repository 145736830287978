import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
// Global
import TypeSelectFAQByArchetype, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectFAQByArchetype';
// Molecule
import FormInputSelect from '../../molecules/FormInputSelect';
import ValidatedField from '../../molecules/ValidatedField';
// Controller
import SelectFAQ01Controller from '../../controllers/SelectFAQ01Controller';

class SelectFAQ01 extends PureComponent {
  static propTypes = {
    ...TypeSelectFAQByArchetype,
    faqFromArchetypeSelected: PropTypes.string,
    faqList: ImmutablePropTypes.list,
    forceResetError: PropTypes.func.isRequired,
    isLoadingArchetype: PropTypes.bool,
    searchFAQByArchetypeAndEntity: PropTypes.func,
    selectFAQ: PropTypes.func,
    submitNoMatch: PropTypes.func,
    templateSelected: ImmutablePropTypes.mapContains({
      template: PropTypes.string,
      word: PropTypes.string,
    }),
  };

  static defaultProps = {
    ...TypeDefaults,
    faqFromArchetypeSelected: null,
    faqList: Immutable.List(),
    isLoadingArchetype: false,
    searchFAQByArchetypeAndEntity: noop,
    selectFAQ: noop,
    submitNoMatch: noop,
    templateSelected: Immutable.Map(),
  };

  state = {
    selectedFAQEID: null,
  };

  componentDidMount() {
    const {
      changeValue,
      faqFromArchetypeSelected,
      id,
      searchFAQByArchetypeAndEntity,
      setAlternateBack,
      setAlternateSubmit,
      setAlternateSkip,
      templateSelected,
    } = this.props;

    setAlternateSkip(this.handleNoMatch);
    setAlternateSubmit(this.handleSubmit);
    setAlternateBack(this.handleClickBack);

    const resetSkip = Immutable.fromJS({
      skip: 'noskip',
    });

    if (!templateSelected.get('template') || !templateSelected.get('word')) {
      // eslint-disable-next-line no-console -- debugging
      console.error(
        ' Error - No Arhcetype and entity exists in the BE scratch'
      );
    }

    searchFAQByArchetypeAndEntity(
      templateSelected.get('template'),
      templateSelected.get('word')
    );
    changeValue(id, resetSkip);

    // If we have a preselected faq, update redux form to display it as preselected option in the dropdown
    if (faqFromArchetypeSelected) {
      changeValue('searchArchetype', faqFromArchetypeSelected);
    }
  }

  handleNoMatch = () => {
    const { id, submitNoMatch } = this.props;
    submitNoMatch(id);
  };

  handleSubmit = (cb) => {
    const { selectFAQ } = this.props;
    const { selectedFAQEID } = this.state;

    const updateEntity = true; // We want to check if we have to update an entity value before to go next
    selectFAQ(selectedFAQEID, updateEntity).then(() => {
      cb();
    });
  };

  handleClickBack = (cb = noop) => {
    const { forceResetError } = this.props;
    forceResetError(cb);
  };

  handleChange = (e, value) => {
    const { id, changeValue } = this.props;

    changeValue(
      id,
      Immutable.fromJS({
        faqEID: value,
        skip: 'noskip',
      })
    );

    this.setState({
      selectedFAQEID: value,
    });
  };

  validate = (value) => {
    const { forceNoValidation } = this.props;
    return forceNoValidation ? null : value ? null : 'error';
  };

  render() {
    const { faqList, isLoadingArchetype, label, type, template } = this.props;

    const options = [];
    faqList.forEach((template) => {
      options.push({
        key: template.get('eid'),
        text: template.get('name'),
        value: template.get('eid'),
      });
    });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField
          component={FormInputSelect}
          iconPosition='left'
          label={label}
          loading={isLoadingArchetype}
          name='searchArchetype'
          onChange={this.handleChange}
          options={options}
          placeholder='Select an FAQ'
          validate={this.validate}
        />
      </div>
    );
  }
}

export const SelectFAQ01Test = SelectFAQ01;

export default SelectFAQ01Controller(SelectFAQ01);
