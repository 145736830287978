import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Checkbox, Dimmer, List, Loader, Message } from 'semantic-ui-react';
import { noop } from 'lodash';
import Immutable from 'immutable';
// Molecule
import EspListItem from '../../molecules/EspListItem';
import FormInputText from '../../molecules/FormInputText';
import ValidatedField from '../../molecules/ValidatedField';
// Globals
import TypeCreateTeachBarista, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCreateTeachBarista';
// Controller
import BuildTeachBaristaController from '../../controllers/BuildTeachBaristaController';

/**
 * This block is an empty component which allow
 * to build a BE scratch object for this FAQ
 */

class BuildAndSearchFAQTeachBarista01 extends PureComponent {
  static propTypes = {
    ...TypeCreateTeachBarista,
    changeValue: PropTypes.func,
    faqMatched: ImmutablePropTypes.map,
    isLoadingFAQMatch: PropTypes.bool,
    kbID: PropTypes.string,
    kbTitle: PropTypes.string,
    setBEscratchAndSearchFAQ: PropTypes.func.isRequired,
    taskId: PropTypes.number,
  };

  static defaultProps = {
    ...TypeDefaults,
    changeValue: noop,
    faqMatched: Immutable.Map(),
    isLoadingFAQMatch: false,
    kbID: null,
    kbTitle: null,
    taskId: null,
  };

  state = {
    checked: 0,
  };

  componentDidMount() {
    const { id, setBEscratchAndSearchFAQ, kbID, kbTitle, taskId } = this.props;

    setBEscratchAndSearchFAQ(taskId, id, kbID, kbTitle);
  }

  handleToggleSelect = () => {
    const { changeValue, id } = this.props;

    this.setState((state) => {
      const newChecked = state.checked ? 0 : 1;
      changeValue(id, newChecked ? 'no_match' : 'match'); // In use for the condition
      return {
        checked: state.checked ? 0 : 1,
      };
    });
  };

  render() {
    const {
      errorsApi,
      faqMatched,
      id,
      isLoadingFAQMatch,
      kbID,
      kbTitle,
      taskId,
      template,
      type,
    } = this.props;

    const { checked } = this.state;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {isLoadingFAQMatch || faqMatched.isEmpty() ? (
          <Dimmer active inverted>
            <Loader size='large' />
          </Dimmer>
        ) : null}

        {/* Hidden input in use to build the BE scratch condition */}
        <ValidatedField component={FormInputText} hidden name={id} />

        <List
          className='cart-list'
          divided
          relaxed='very'
          verticalAlign='middle'
        >
          <EspListItem
            description={'I want to update the response/s'}
            // name={product.get('name')}
            onClick={this.handleToggleSelect}
            selectable={<Checkbox checked={checked === 0} />}
          />

          <EspListItem
            description={'The match was incorrect, I want to fix this issue.'}
            // name={product.get('name')}
            onClick={this.handleToggleSelect}
            selectable={<Checkbox checked={checked === 1} />}
          />
        </List>

        {errorsApi && !errorsApi.isEmpty() && (
          <Message
            content={errorsApi.getIn([0, 'message'])}
            header='Error'
            negative
          />
        )}

        {!taskId && !kbID && !kbTitle ? (
          <Message
            content='No taskId or Title and ID has been passed in the query parameters'
            header='Error'
            negative
          />
        ) : null}
      </div>
    );
  }
}

export const BuildAndSearchFAQTeachBarista01Test = BuildAndSearchFAQTeachBarista01;

export default BuildTeachBaristaController(BuildAndSearchFAQTeachBarista01);
