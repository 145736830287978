import buildCompositeController from '../../utils/buildCompositeController';

// Redux
import sessionThunks from '../../actions/sessionThunks';
import getCurrentUser from '../../selectors/getCurrentUser';
import store from '../../stores/store';
import { getMimeTypeFromBase64String } from 'esp-globals';

const mapDispatchToProps = (dispatch) => {
  const currentUsr = getCurrentUser(store.getState()).toJS();
  let finalData = null;

  const convertFormData = (data) => {
    const base = {
      caption: "''",
      id: data.id,
      ordering: 0,
      picture: data.picture,
      sys_custom_fields: null,
      type: data.type,
      user: currentUsr.url,
    };

    // Create and populate a formdata
    const finalData = new FormData();
    for (const key in base) {
      finalData.append(key, base[key]);
    }
    return finalData;
  };

  const dataURItoBlob = (imgData) => {
    const byteString = atob(imgData.split(',')[1]);
    const mimeString = getMimeTypeFromBase64String(imgData);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {
      type: mimeString,
    });
  };

  return {
    onSubmitImg: (data, type, fn) => {
      if (!data) {
        return;
      }

      let idImg = null;

      // Get id picture if exists. If not, a new img will be create on the server
      currentUsr.images.forEach((img) => {
        if (type === img.type) {
          idImg = img.id;
        }
      });

      finalData = {
        id: idImg,
        picture: dataURItoBlob(data),
        type: type,
      };

      finalData = {
        data: convertFormData(finalData),
        id: idImg,
      };

      dispatch(sessionThunks.updteUserImages(finalData));
      finalData = null;

      fn();
    },
  };
};

export default buildCompositeController(null, mapDispatchToProps);
