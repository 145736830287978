import React, { useCallback, useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { Button, Input } from 'reakit';

import tenantContext from '../../context/tenant/tenantContext';

import { ImageDefaults } from 'esp-assets';
import styles from '../Auth.module.scss';

const propTypes = {
  message: pt.string,
  onSubmit: pt.func.isRequired,
};

//
// Login form
//
// This form allows the User to exchange credentials for an access token.
//
// @param {Function} clearTenant A function that will clear the tenant information when invoked.
// @param {Function} onSubmit A function to run when the user submits the login form.
// @param {Boolean} isTenantSelectable Specifies if the User can manually select a tenant
// @param {String} message A text message to display when there is an error.
const LoginForm = ({ message, onSubmit }) => {
  const fields = {
    changeTenant: {
      label: 'Change Tenant',
    },
    login: {
      label: 'Login',
    },
    password: {
      label: 'Password',
      name: 'password',
      placeholder: 'Your Password',
    },
    username: {
      label: 'Username',
      name: 'username',
      placeholder: 'name@company.com',
    },
  };

  const [isLoading, setIsLoading] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isUsernameOrPasswordEmpty = !(username && password);
  const { clearTenant, tenant } = useContext(tenantContext) || {};

  const submitButtonProps = !isUsernameOrPasswordEmpty
    ? {}
    : { disabled: true };

  // If an alternate logo is defined, we should display that for the login screen,
  // or show the Barista fallback if neither an alternate or regular logo are defined
  const tenantLogo =
    tenant?.images?.alternate_logo ||
    tenant?.images?.logo ||
    ImageDefaults.ESPRESSIVE_LOGO;

  const formClassName = cx('ui segment form', {
    'bottom attached': message,
    loading: isLoading,
  });

  const loginButtonClassName = cx('ui primary right floated button', {
    disabled: isUsernameOrPasswordEmpty,
  });

  const handleUsernameInputChange = useCallback(
    (event) => {
      event.preventDefault();
      const {
        target: { value: newUserName },
      } = event;

      setUsername(newUserName);
    },
    [setUsername]
  );

  const handlePasswordInputChange = useCallback(
    (event) => {
      event.preventDefault();
      const {
        target: { value: newPassword },
      } = event;

      setPassword(newPassword);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      onSubmit(username, password);
      setIsLoading('loading');

      // do not keep credentials in memory
      setUsername('');
      setPassword('');
    },
    [onSubmit, password, username]
  );

  const handleClearTenant = useCallback(
    (event) => {
      event.preventDefault();
      clearTenant();
    },
    [clearTenant]
  );

  // exit loading state when there is a message
  useEffect(() => {
    if (message) {
      setIsLoading();
    }
  }, [message]);

  return (
    <div className={styles.Form}>
      <img
        alt='company logo'
        className={styles.Logo}
        src={tenantLogo}
        type='logo'
      />
      {message && (
        <div className='ui mini negative top attached message'>
          <div className='content'>
            <div className='header'>There was an error</div>
            <p>{message}</p>
          </div>
        </div>
      )}

      <form className={formClassName} id='login' onSubmit={handleSubmit}>
        <div className='field'>
          <label htmlFor={fields.username.name}>{fields.username.label}</label>
          <Input
            id={fields.username.name}
            name={fields.username.label}
            onChange={handleUsernameInputChange}
            placeholder={fields.username.placeholder}
            type='text'
            value={username}
          />
        </div>
        <div className='field'>
          <label htmlFor={fields.password.name}>{fields.password.label}</label>
          <Input
            id={fields.password.name}
            name={fields.password.name}
            onChange={handlePasswordInputChange}
            placeholder={fields.password.placeholder}
            type='password'
            value={password}
          />
        </div>

        {clearTenant && (
          <Button
            className='ui secondary button'
            onClick={handleClearTenant}
            type='button'
          >
            {fields.changeTenant.label}
          </Button>
        )}

        <Button
          className={loginButtonClassName}
          type='submit'
          {...submitButtonProps}
        >
          {fields.login.label}
        </Button>

        {/* We need this clearing, hidden divider here since the login button is 
        floated. There is a bug in the SUI CSS that does not keep the clearfix 
        on a segment when it is loading. */}
        <div className='ui clearing hidden fitted divider' />
      </form>
    </div>
  );
};

LoginForm.propTypes = propTypes;

export default LoginForm;
