import React from 'react';
import PropTypes from 'prop-types';
import FlagContext from '../context';

const Flag = ({ feature, children }) => {
  return (
    <FlagContext.Consumer>
      {({ flags }) => {
        if (flags.get(feature)) {
          return children;
        }
        return null;
      }}
    </FlagContext.Consumer>
  );
};

Flag.propTypes = {
  children: PropTypes.element,

  feature: PropTypes.string.isRequired,
};
Flag.defaultProps = {
  children: null,
};

export default Flag;
