import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsNoCloud extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M29.13 39.64c-6.622-1.163-13.117 2.62-15.374 8.952-2.258 6.332.38 13.37 6.244 16.658l27.34-23V31.44c-.144-.618.107-1.262.63-1.62.525-.358 1.215-.358 1.74 0 .523.358.774 1.002.63 1.62v8.3L64.35 28c-5.684-5.172-13.714-6.88-21.01-4.47-7.297 2.413-12.728 8.57-14.21 16.11zm42.16 5.44c0-.43.07-.86.07-1.3.004-3.6-.908-7.14-2.65-10.29L50.37 48.88v11.73L53.78 57c.57-.595 1.502-.65 2.14-.13.297.24.48.59.505.97.024.382-.112.755-.375 1.03l-6 6.42c-.298.31-.71.484-1.14.48-.43.004-.842-.17-1.14-.48l-6-6.42c-.263-.275-.4-.648-.375-1.03.025-.38.208-.73.505-.97.63-.497 1.535-.44 2.1.13l3.38 3.6v-9.17L28 67.66c.185.135.41.202.64.19l42.65-.12h3.14c5.42-.67 9.64-6.24 9.64-11.84.053-3.246-1.356-6.344-3.838-8.437-2.48-2.093-5.77-2.96-8.962-2.363l.02-.01z' />
          <path d='M57.15 5C33.37-.002 10.04 15.22 5.036 39 .033 62.78 15.254 86.11 39.033 91.114 62.813 96.12 86.145 80.9 91.15 57.12c2.404-11.42.172-23.328-6.205-33.102C78.57 14.243 68.57 7.402 57.15 5zM16.47 70.81C4.644 54.204 7.603 31.29 23.26 18.234 38.917 5.177 61.99 6.384 76.2 21c-.11.05-.215.114-.31.19L16.47 70.81zM86.25 56c-3.07 14.57-14.168 26.122-28.604 29.772-14.436 3.65-29.69-1.24-39.316-12.602l59.48-49.68c.113-.098.21-.213.29-.34 7.582 9.135 10.583 21.23 8.15 32.85z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsNoCloud;
