import async from 'async';
import { head, isEmpty, noop } from 'lodash';

import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
// import getSelectedEmailDomain from '../selectors/getSelectedEmailDomain';
import getTenant from '../selectors/getTenant';

import emailPoliciesActions from './emailPoliciesActions';

const getAddressPolicyList = (onAddressPolicyList = noop) => {
  const url = endpointGenerator.genPath('espUser.emailAddressPolicies');

  APIcall.get({
    error(error) {
      onAddressPolicyList(error);
    },
    success(response) {
      const addressPolicyList = response.body.results;
      onAddressPolicyList(null, addressPolicyList);
    },
    token: true,
    url,
  });
};

const addAddressPolicy = (onAddAddressPolicy = noop) => {
  const url = endpointGenerator.genPath('espUser.emailAddressPolicies');

  const payload = {
    address_rules: [],
  };

  APIcall.post({
    data: payload,
    error(error) {
      onAddAddressPolicy(error);
    },
    preventShowError: true,
    success(response) {
      const addressPolicy = response.body;
      onAddAddressPolicy(null, addressPolicy);
    },
    token: true,
    url,
  });
};

const getEmailDomains = (onEmailDomains = noop) => {
  const url = endpointGenerator.genPath('espTenant.domain');

  APIcall.get({
    error(error) {
      onEmailDomains(error);
    },
    success(response) {
      const domains = response.body.results;
      onEmailDomains(null, domains);
    },
    token: true,
    url,
  });
};

const updateEmailDomain = (domainID, changes, onUpdateEmailDomain = noop) => {
  const url = endpointGenerator.genPath('espTenant.domain.instance', {
    domainID,
  });

  return APIcall.patch({
    data: changes,
    error(error) {
      onUpdateEmailDomain(error);
    },
    success(response) {
      const emailDomain = response.body;
      onUpdateEmailDomain(null, emailDomain);
    },
    token: true,
    url,
  });
};

const emailPoliciesThunks = {};

emailPoliciesThunks.getTestEmailEnabled = () => (dispatch) => {
  dispatch(emailPoliciesActions.getTestEmailStart());
  const url = endpointGenerator.genPath('espConfig.testEmailEnabled');
  return APIcall.get({
    token: true,
    url,
  })
    .then(({ body: { value } }) => {
      dispatch(emailPoliciesActions.getTestEmailEnabledSuccess(value));
    })
    .catch(() => {
      dispatch(emailPoliciesActions.getTestEmailFail());
    });
};

emailPoliciesThunks.getTestEmail = () => (dispatch) => {
  dispatch(emailPoliciesActions.getTestEmailStart());
  const url = endpointGenerator.genPath('espConfig.testEmailAddressVal');
  return APIcall.get({
    token: true,
    url,
  })
    .then(({ body: { value } }) => {
      // BE default value is null
      dispatch(emailPoliciesActions.getTestEmailSuccess(value || ''));
    })
    .catch(() => {
      dispatch(emailPoliciesActions.getTestEmailFail());
    });
};

emailPoliciesThunks.setTestEmailEnabled = (newValue) => (dispatch) => {
  dispatch(emailPoliciesActions.getTestEmailStart());
  const url = endpointGenerator.genPath('espConfig.testEmailEnabled');
  return APIcall.put({
    data: { value: newValue },
    token: true,
    url,
  })
    .then(({ body: { value } }) => {
      dispatch(emailPoliciesActions.getTestEmailEnabledSuccess(value));
    })
    .catch(() => {
      dispatch(emailPoliciesActions.getTestEmailFail());
    });
};

emailPoliciesThunks.setTestEmail = (newValue) => (dispatch) => {
  dispatch(emailPoliciesActions.getTestEmailStart());
  const url = endpointGenerator.genPath('espConfig.testEmailAddressVal');
  return APIcall.put({
    data: { value: newValue },
    token: true,
    url,
  })
    .then(({ body: { value } }) => {
      // BE default value is null
      dispatch(emailPoliciesActions.getTestEmailSuccess(value || ''));
    })
    .catch(() => {
      dispatch(emailPoliciesActions.getTestEmailFail());
    });
};

emailPoliciesThunks.getPolicy = () => (dispatch) => {
  dispatch(emailPoliciesActions.getAddressPoliciesStart());

  async.waterfall(
    [
      // 1. Get policy list
      (next) => {
        getAddressPolicyList(next);
      },

      // 2. Use existing policy or create a new one if none is available
      (addressPolicyList, next) => {
        if (isEmpty(addressPolicyList)) {
          addAddressPolicy(next);
        } else {
          const addressPolicy = head(addressPolicyList);
          next(null, addressPolicy);
        }
      },
    ],
    (error, addressPolicy) => {
      if (error) {
        dispatch(emailPoliciesActions.getAddressPoliciesFail());
      } else {
        dispatch(
          emailPoliciesActions.getAddressPoliciesSuccess(
            addressPolicy.id,
            addressPolicy.address_rules
          )
        );
      }
    }
  );
};

emailPoliciesThunks.updatePolicy = (addressRules) => (dispatch, getState) => {
  const state = getState();
  const emailPolicyState = state.get('emailPolicies');
  const policyID = emailPolicyState.getIn(['addressPolicies', 'id']);

  const url = endpointGenerator.genPath(
    'espUser.emailAddressPolicies.instance',
    {
      policyID,
    }
  );

  dispatch(emailPoliciesActions.updateAddressPolicyStart());

  APIcall.put({
    data: {
      address_rules: addressRules,
    },
    error() {
      dispatch(emailPoliciesActions.updateAddressPolicyFail());
    },
    success(response) {
      const updatedAddressRules = response.body.address_rules;
      dispatch(
        emailPoliciesActions.updateAddressPolicySuccess(updatedAddressRules)
      );
    },
    token: true,
    url,
  });
};

emailPoliciesThunks.getDomains = () => (dispatch) => {
  dispatch(emailPoliciesActions.getEmailDomainsStart());

  getEmailDomains((error, domains) => {
    if (error) {
      dispatch(emailPoliciesActions.getEmailDomainsFail());
    } else {
      dispatch(emailPoliciesActions.getEmailDomainsSuccess(domains));
    }
  });
};

emailPoliciesThunks.selectDomain = (newSelectedEmailDomainID) => (dispatch) => {
  // DEV-6825 We no longer have to PATCH all the other domains

  // const state = getState();
  // const selectedEmailDomain = getSelectedEmailDomain(state);
  // const selectedEmailDomainID = selectedEmailDomain.get('id');

  dispatch(emailPoliciesActions.selectEmailDomainStart());
  async.waterfall(
    [
      // 1. Update selection
      (next) => {
        const changes = {
          is_primary_for_email: true,
        };
        updateEmailDomain(newSelectedEmailDomainID, changes, next);
      },

      // 2. Pull email domains
      (updatedDomain, next) => {
        getEmailDomains(next);
      },
    ],
    (error, emailDomains) => {
      if (error) {
        dispatch(emailPoliciesActions.selectEmailDomainFail());
      } else {
        dispatch(emailPoliciesActions.selectEmailDomainSuccess(emailDomains));
      }
    }
  );
};

emailPoliciesThunks.addDomain = (domain) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const tenant = getTenant(state);
    const tenantUrl = tenant.get('url');

    const url = endpointGenerator.genPath('espTenant.domain');

    const payload = {
      domain,
      is_primary: true,
      is_primary_for_email: true,
      tenant: tenantUrl,
    };

    dispatch(emailPoliciesActions.addEmailDomainStart());

    APIcall.post({
      data: payload,
      error(error) {
        dispatch(emailPoliciesActions.addEmailDomainFail());
        reject(error);
      },
      success(response) {
        const emailDomain = response.body;

        dispatch(emailPoliciesActions.addEmailDomainSuccess());
        resolve(emailDomain);
      },
      token: true,
      url,
    });
  });

export default emailPoliciesThunks;
