import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCommunicationMicrophones extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M36.694 27.39c0-6.2 5.042-11.23 11.236-11.23 6.206 0 11.238 5.027 11.238 11.23v23.09c0 6.203-5.044 11.23-11.238 11.23-6.206 0-11.237-5.026-11.237-11.23V27.39z' />
          <g transform='translate(36 16.16)'>
            <mask id='b'>
              <use xlinkHref='#a' />
            </mask>
            <path d='M-1202 578H135.157V-759.157H-1202' mask='url(#b)' />
            <path d='M-5.836 52.08h35.533V-6.528H-5.836' mask='url(#b)' />
          </g>
          <path
            d='M67.607 50.078V40.17h-.018c-.06-.988-.87-1.776-1.878-1.776-1.04 0-1.882.844-1.882 1.884 0 .078.014.154.024.23v9.57c0 8.79-7.145 15.94-15.926 15.94-8.78 0-15.925-7.15-15.925-15.94V40.17H32c.004-.056.016-.112.016-.17 0-1.04-.843-1.884-1.883-1.884S28.25 38.96 28.25 40c0 .058.012.114.017.17h-.007v9.908c0 10.224 7.828 18.652 17.8 19.597v6.833H34.21c-1.04.004-1.88.844-1.88 1.883 0 1.046.843 1.888 1.883 1.888.073 0 .142-.013.212-.02h27.228v-.004c1.01-.03 1.818-.852 1.818-1.867 0-1.017-.81-1.84-1.814-1.868v-.007H49.81V69.68c9.97-.95 17.797-9.374 17.797-19.598'
            fill='#F00'
          />
        </g>
      </svg>
    );
  }
}

export default CatalogCommunicationMicrophones;
