import { Divider, Grid } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { noop } from 'lodash';

// Controllers
import EditorPageController from '../../../../../app/js/v1/components/controllers/EditorPageController';

// Pages
import Page from '../../../../../app/js/v1/components/Page';
import WorkflowBrowserPage from '../../../../../app/js/v1/components/pages/editor/WorkflowBrowserPage';
import WorkflowEditorPage from '../../../../../app/js/v1/components/pages/editor/WorkflowEditorPage';
import WorkflowTaskEditorPage from '../../../../../app/js/v1/components/pages/editor/WorkflowTaskEditorPage';

// Molecules
import AdminAppHeader from '../../../../../app/js/v1/components/molecules/AdminAppHeader';
import EspRoute from '../../../../../app/js/v1/components/molecules/EspRoute';
import EspSwitch from '../../../../../app/js/v1/components/molecules/EspSwitch';
import WorkflowBreadcrumbs from '../../../../../app/js/v1/components/molecules/WorkflowBreadcrumbs';

// Utils
import uiPathGenerator from '../../../../../app/js/v1/utils/uiPathGenerator';

const propTypes = {
  currentUser: PropTypes.shape({
    first_name: PropTypes.string,
  }),
  doEditorGoodbyeActions: PropTypes.func,
  doEditorHelloActions: PropTypes.func,
  tasks: ImmutablePropTypes.list,
  workflowName: PropTypes.string,
};

const defaultProps = {
  currentUser: null,
  doEditorGoodbyeActions: noop,
  doEditorHelloActions: noop,
  tasks: Immutable.List(),
  workflowName: '',
};

class EditorPage extends Component {
  componentDidMount() {
    const { doEditorHelloActions } = this.props;
    doEditorHelloActions();
  }

  componentWillUnmount() {
    const { doEditorGoodbyeActions } = this.props;
    doEditorGoodbyeActions();
  }

  render() {
    const { workflowName, currentUser, tasks } = this.props;

    if (!currentUser) {
      return null;
    }

    return (
      <Page
        bodyClassName='editor-page'
        header={<AdminAppHeader currentUser={currentUser} />}
        title='Blobifier'
      >
        <Grid.Column width={16}>
          <Divider hidden />
          <Divider hidden />

          <WorkflowBreadcrumbs tasks={tasks} workflowName={workflowName} />
        </Grid.Column>

        <EspSwitch>
          <EspRoute
            component={WorkflowEditorPage}
            exact
            path={uiPathGenerator.genFullPattern('editor.workflow')}
          />
          <EspRoute
            component={WorkflowTaskEditorPage}
            exact
            path={uiPathGenerator.genFullPattern('editor.workflowTask')}
          />

          <EspRoute component={WorkflowBrowserPage} />
        </EspSwitch>
      </Page>
    );
  }
}

EditorPage.propTypes = propTypes;

EditorPage.defaultProps = defaultProps;
// eslint-disable-next-line no-class-assign -- DEV-1526
EditorPage = EditorPageController(EditorPage);

export default EditorPage;
