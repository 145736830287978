import async from 'async';
import Immutable from 'immutable';
import _ from 'lodash';

import APIcall from '../utils/APIcall';
import { BundleSelectValues } from '../globals/BundleSelectOptions';
import endpointGenerator from '../utils/endpointGenerator';
import SelectMyGearStepTypes from '../globals/SelectMyGearStepTypes';
import selectMyGearActions from './selectMyGearActions';
import workflowActions from './workflowActions';
import workflowThunks from './workflowThunks';
import catalogThunks from './catalogThunks';

/** Pulls EspBundle */
const getBundle = (bundleID, onBundle = _.noop) => {
  const url = endpointGenerator.genPath('espCatalog.bundles.instance', {
    bundleID,
  });

  APIcall.get({
    error(error) {
      onBundle(error);
    },
    success(response) {
      const bundle = response.body;
      onBundle(null, bundle);
    },
    token: true,
    url,
  });
};

/** Pulls EspProduct */
const getProduct = (productID, onProduct = _.noop) => {
  const url = endpointGenerator.genPath('espCatalog.products.instance', {
    productID,
  });

  APIcall.get({
    // we are handling the error, so we don't want the default reporting
    error(error) {
      onProduct(error);
    },

    preventShowError: true,

    success(response) {
      const product = response.body;
      onProduct(null, product);
    },
    token: true,
    url,
  });
};

/** Pulls EspProductFamily */
const getProductFamily = (productFamilyID, onProductFamily = _.noop) => {
  const url = endpointGenerator.genPath('espCatalog.productFamilies.instance', {
    pfamilyId: productFamilyID,
  });

  APIcall.get({
    error(error) {
      onProductFamily(error);
    },
    success(response) {
      const productFamily = response.body;
      onProductFamily(null, productFamily);
    },
    token: true,
    url,
  });
};

/** Pulls fulfillment questions for an EspProduct */
const getFulfillmentQuestions = (
  productID,
  onFulfillmentQuestions = _.noop
) => {
  const url = endpointGenerator.genPath('espCatalog.fulfillmentOptions');

  // TODO thunk from catalogThunks should be used here instead
  const espFilters = encodeURI(`products__IN=${productID}`);
  const query = {
    esp_filters: espFilters,
  };

  APIcall.get({
    error(error) {
      onFulfillmentQuestions(error);
    },
    query,
    success(response) {
      const fulfillmentQuestions = response.body.results;
      onFulfillmentQuestions(null, fulfillmentQuestions);
    },
    token: true,
    url,
  });
};

/**
 *
 * @param product An EspProduct instance returned by 'getProduct' function. 'product.product_family' should be null
 */
const getProductRecord = (product, onProductRecord = _.noop) => {
  const record = {
    entity: product,

    hasFulfillmentQuestions: false,

    type: SelectMyGearStepTypes.PRODUCT,
  };

  // User doesn't have permission to view the product
  if (!product) {
    onProductRecord(null, record);
    return;
  }

  // does the product have fulfillment options?
  getFulfillmentQuestions(product.id, (error, fulfillmentQuestions) => {
    if (error) {
      onProductRecord(error);
    } else {
      record.hasFulfillmentQuestions = !_.isEmpty(fulfillmentQuestions);

      onProductRecord(null, record);
    }
  });
};

/**
 * @param {number} productFamilyID
 */
const getProductFamilyRecord = (productFamilyID, onProductFamilyRecord) => {
  async.waterfall(
    [
      // 1. Pull the product family
      (next) => {
        getProductFamily(productFamilyID, next);
      },

      // 2. Map the products of the family to items of the record
      (productFamily, next) => {
        const { products } = productFamily;

        const items = products.map((product) => ({
          entity: product,
        }));

        next(null, items);
      },

      // 3. Pull a representative product (if any is available)
      (items, next) => {
        // There's no representative product
        if (_.isEmpty(items)) {
          next(null, items, null);
          return;
        }

        const firstItem = _.head(items);
        const firstProduct = firstItem.entity;
        const representativeProductID = firstProduct.id;

        getProduct(representativeProductID, (error, representativeProduct) => {
          if (error) {
            next(error);
          } else {
            next(null, items, representativeProduct);
          }
        });
      },
      // 4. For each of available choices from the family, tell if they have fulfillment options
      (items, representativeEntity, next) => {
        async.each(
          items,
          (item, eachDone) => {
            const product = item.entity;

            getFulfillmentQuestions(
              product.id,
              (error, fulfillmentQuestions) => {
                if (error) {
                  eachDone(error);
                } else {
                  item.hasFulfillmentQuestions = !_.isEmpty(
                    fulfillmentQuestions
                  );
                  eachDone(null);
                }
              }
            );
          },
          (error) => {
            if (error) {
              next(error);
            } else {
              next(null, items, representativeEntity);
            }
          }
        );
      },
    ],
    (error, items, representativeEntity) => {
      if (error) {
        onProductFamilyRecord(error);
      } else {
        const record = {
          items,
          representativeEntity,
          type: SelectMyGearStepTypes.PRODUCT_FAMILY,
        };

        onProductFamilyRecord(null, record);
      }
    }
  );
};

const mapBundleItemsToRecords = (bundleItems, mapper, onBundleRecords) => {
  // filter out BundleItems that shouldn't be displayed
  bundleItems = bundleItems.filter((item) => item.display);

  async.map(bundleItems, mapper, onBundleRecords);
};

/**
 * @param bundle
 */
const getProductBundleRecord = (bundle, mapper, onProductBundleRecord) => {
  const record = {
    selects: bundle.selects,

    type: SelectMyGearStepTypes.PRODUCT_BUNDLE,
  };

  mapBundleItemsToRecords(bundle.items, mapper, (error, bundleItemRecords) => {
    if (error) {
      onProductBundleRecord(error);
    } else {
      record.items = bundleItemRecords;

      onProductBundleRecord(null, record);
    }
  });
};

/**
 * Pulls and classifies entity pointed by BundleItem, it can be a Product, Product Family or Product Bundle.
 * Returns wrapped entity to include it's type.
 */
const getBundleItemRecord = (bundleItem, onRecord) => {
  if (bundleItem.product) {
    // it points to a Product
    getProduct(bundleItem.product, (error, product) => {
      if (error) {
        // let's handle the error by passing a null product
        getProductRecord(null, onRecord);
      } else {
        // report that bundle item type is wrong and should be fixed
        if (product.product_family) {
          const msg =
            `Bundle item ${bundleItem.id} has type ${bundleItem.type} but belongs to a product family. ` +
            `Bundle item type should be ${SelectMyGearStepTypes.PRODUCT_FAMILY}. This is a data issue.`;

          throw new Error(msg);
        }

        getProductRecord(product, onRecord);
      }
    });
  } else if (bundleItem.product_family) {
    // it points to a Product Family
    getProductFamilyRecord(bundleItem.product_family, onRecord);
  } else {
    // it points to a Bundle
    getBundle(bundleItem.bundle, (error, bundle) => {
      if (error) {
        onRecord(error);
      } else {
        // pass itself as mapper
        getProductBundleRecord(bundle, getBundleItemRecord, onRecord);
      }
    });
  }
};

/**
 * Decides which elements from 'items' should stay based on permissions.
 */
const filterRecords = (items) =>
  items.filter((item) => {
    if (item.type === SelectMyGearStepTypes.PRODUCT) {
      // item.entity is null for products user doesn't have permission to view
      return Boolean(item.entity);
    } else if (item.type === SelectMyGearStepTypes.PRODUCT_FAMILY) {
      // item.representativeEntity is null for product families that contain no products user can view
      return Boolean(item.representativeEntity);
    } else {
      // product bundles stay if they are not empty
      return !_.isEmpty(item.items);
    }
  });

const cleanUpJobRoleBundleRecords = (jobRoleBundleRecords) => {
  // Filter out products user doesn't have permission to view, and products families
  // that contain no products user can view, from product bundles
  jobRoleBundleRecords = jobRoleBundleRecords.map((record) => {
    if (record.type === SelectMyGearStepTypes.PRODUCT_BUNDLE) {
      record.items = filterRecords(record.items);
    }

    return record;
  });

  // Filter out products user doesn't have permission to view, product families that contain no products user
  // can view, and product bundles that contain no items user can view, from root role bundle
  return filterRecords(jobRoleBundleRecords);
};

/**
 * Check prefill fulfillment response for a product on a step
 * and update the step
 * @param currentStep
 * @returns {Promise}
 */
const checkFulfillment = (currentStep, dispatch) =>
  new Promise((resolve) => {
    if (currentStep.hasFulfillmentQuestions) {
      dispatch(
        catalogThunks.getFulfillmentQuestionsByProduct(currentStep.entity.id)
      ).then((optionsResults) => {
        if (optionsResults.length < 1) {
          // No fulfillment options to display
          currentStep.hasFulfillmentQuestions = false;
        }
        resolve(currentStep);
      });
    } else {
      resolve(currentStep);
    }
  });

const selectMyGearThunks = {};

selectMyGearThunks.prefillProductsSelection = () => (dispatch, getState) => {
  const state = getState();
  const selectMyGearState = state.get('selectMyGear');
  const steps = selectMyGearState.getIn(['steps', 'values']);

  const selectProduct = (stepIndex, productStep, relativeWeight) => {
    const product = productStep.get('entity');
    dispatch(
      selectMyGearActions.preAddProduct(
        stepIndex,
        product.get('id'),
        relativeWeight
      )
    );
  };

  // pre select first product in product family if it's the only one
  const selectProductFamily = (
    stepIndex,
    productFamilyStep,
    relativeWeight
  ) => {
    const hasOnlyOneItem = productFamilyStep.get('items').size === 1;

    if (hasOnlyOneItem) {
      const representativeProduct = productFamilyStep.get(
        'representativeEntity'
      );
      const representativeProductID = representativeProduct.get('id');
      dispatch(
        selectMyGearActions.preAddProduct(
          stepIndex,
          representativeProductID,
          relativeWeight
        )
      );
    }
  };

  steps.forEach((step, stepIndex) => {
    if (step.get('type') === SelectMyGearStepTypes.PRODUCT) {
      // individual products are always selected
      selectProduct(stepIndex, step, 0);
    }

    if (step.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
      selectProductFamily(stepIndex, step, 0);
    }

    if (step.get('type') === SelectMyGearStepTypes.PRODUCT_BUNDLE) {
      const selects = step.get('selects');

      if (selects === BundleSelectValues.SELECT_ALL) {
        // can be products or product families
        const bundleChildren = step.get('items');

        bundleChildren.forEach((bundleChild, bundleChildIndex) => {
          if (bundleChild.get('type') === SelectMyGearStepTypes.PRODUCT) {
            selectProduct(stepIndex, bundleChild, bundleChildIndex);
          } else {
            // it's a product family
            selectProductFamily(stepIndex, bundleChild, bundleChildIndex);
          }
        });
      }
    }
  });

  // initialize currentStep to 0
  dispatch(selectMyGearActions.moveFirstStep());
};

/**
 * Resumes previous progress from frontendScratch if available, or pre fills products selection.
 */
selectMyGearThunks.initializeState = () => (dispatch, getState) => {
  let state = getState();
  const workflowState = state.get('workflowState');
  const frontendScratch = workflowState.get('frontendScratch');

  if (frontendScratch.has('selectMyGear')) {
    // cool, we can resume previous progress
    const selectMyGearScratch = frontendScratch.get('selectMyGear');

    dispatch(selectMyGearActions.resumeProgress(selectMyGearScratch.toJS()));
  } else {
    // starting from first step
    dispatch(selectMyGearThunks.prefillProductsSelection());
  }

  // initialized state
  state = getState();
  const selectMyGearState = state.get('selectMyGear');

  const steps = selectMyGearState.getIn(['steps', 'values']);
  const stepsCount = steps.size;

  // since currentStep is 0-based index, it's the same than stepsCompleted, which is expected by workflowActions.reportTaskProgress
  const currentStep = selectMyGearState.get('currentStep');

  dispatch(workflowActions.reportTaskProgress(currentStep, stepsCount));
};

/**
 * Starts from a Job Role Bundle, then traverses it's way down recursively to return
 * a linear representation of the tree.
 *
 * Each node in the tree can be a Bundle, Product or Product Family.
 *
 * Products may have fulfillment options.
 *
 * @param {number} Numeric ID of Job Role Bundle.
 */
selectMyGearThunks.getSelectMyGearSteps = (jobRoleBundleID) => (dispatch) => {
  // enter workflow loading state, so user can't click on Back or Next
  dispatch(workflowActions.loading());

  // clear any previous progress (if any), this prevents progress from different role bundles to mess up
  dispatch(selectMyGearActions.resetProgress());

  dispatch(selectMyGearActions.getSelectMyGearStepsStart());

  async.waterfall(
    [
      // 1. Get the role bundle
      (next) => {
        getBundle(jobRoleBundleID, next);
      },

      // 2. Build the steps (records) of gear selection
      (jobRoleBundle, next) => {
        mapBundleItemsToRecords(jobRoleBundle.items, getBundleItemRecord, next);
      },

      // 3. Clean up steps (records) based on access permissions
      (jobRoleBundleRecords, next) => {
        jobRoleBundleRecords = cleanUpJobRoleBundleRecords(
          jobRoleBundleRecords
        );

        next(null, jobRoleBundleRecords);
      },

      // 4. Squash adjacent PRODUCT type records together in an artificial SELECT_ALL type bundle
      (jobRoleBundleRecords, next) => {
        const selectMyGearSteps = [];

        let isArtificialBundleOpen = false;

        jobRoleBundleRecords.forEach((step, i) => {
          if (step.type === SelectMyGearStepTypes.PRODUCT) {
            if (isArtificialBundleOpen) {
              const artificialBundleStep = _.last(selectMyGearSteps);
              artificialBundleStep.items.push(step);
            } else {
              // Should we open an artificial bundle?
              if (i === _.size(jobRoleBundleRecords) - 1) {
                // Is this the last step ?
                selectMyGearSteps.push(step); // there is no point in opening an artificial bundle with a single product
              } else {
                // This is not the last step
                const nextStep = jobRoleBundleRecords[i + 1];
                if (nextStep.type === SelectMyGearStepTypes.PRODUCT) {
                  isArtificialBundleOpen = true; // we can open an artificial bundle!

                  selectMyGearSteps.push({
                    items: [step],
                    selects: BundleSelectValues.SELECT_ALL,
                    type: SelectMyGearStepTypes.PRODUCT_BUNDLE,
                  });
                } else {
                  // there is no point in opening an artificial bundle with a single product
                  selectMyGearSteps.push(step);
                }
              }
            }
          } else {
            // close artificial bundle if any
            isArtificialBundleOpen = false;
            selectMyGearSteps.push(step);
          }
        });

        // Check for fulfillment prefill response
        async.eachOfSeries(
          selectMyGearSteps,
          (step, i, done) => {
            if (step.items) {
              // This is an artificial step with sub items
              async.eachOfSeries(
                step.items,
                (subStep, ii, doneSubStep) => {
                  checkFulfillment(subStep, dispatch).then((newStep) => {
                    selectMyGearSteps[i].items[ii] = newStep;
                    doneSubStep();
                  });
                },
                () => {
                  done();
                }
              );
            } else if (step.entity) {
              // Single product
              checkFulfillment(step, dispatch).then((newStep) => {
                selectMyGearSteps[i] = newStep;
                done();
              });
            } else {
              // Impossible state - But we do not want to break here if the case appears.
              // eslint-disable-next-line no-console -- debugging
              console.warn('Error - This step have a data issue', step);
              done();
            }
          },
          () => {
            next(null, selectMyGearSteps);
          }
        );
      },
    ],
    (error, selectMyGearSteps) => {
      // exit workflow loading state, now user can go Back or Next
      dispatch(workflowActions.exitLoading());

      if (error) {
        dispatch(selectMyGearActions.getSelectMyGearStepsFail());
      } else {
        dispatch(
          selectMyGearActions.getSelectMyGearStepsSuccess(selectMyGearSteps)
        );

        dispatch(selectMyGearThunks.initializeState());
      }
    }
  );
};

/**
 * Saves current selectMyGear state into frontendScratch of workflow request.
 */
selectMyGearThunks.saveToFrontendScratch = () => (dispatch, getState) => {
  const state = getState();

  const workflowState = state.get('workflowState');
  let frontendScratch = workflowState.get('frontendScratch') || Immutable.Map();

  const selectMyGearState = state.get('selectMyGear');

  const selectMyGearScratch = Immutable.Map()
    .set('currentStep', selectMyGearState.get('currentStep'))
    .set('selectedProductIDs', selectMyGearState.get('selectedProductIDs'))
    .set('fulfillmentAnswers', selectMyGearState.get('fulfillmentAnswers'))
    .set('weights', selectMyGearState.get('weights'));

  frontendScratch = frontendScratch.set('selectMyGear', selectMyGearScratch);

  return dispatch(workflowThunks.saveToFrontEndScratch(frontendScratch.toJS()));
};

selectMyGearThunks.movePrevStep = (moveWorkflowPrevTask) => (
  dispatch,
  getState
) => {
  const state = getState();
  const selectMyGearState = state.get('selectMyGear');

  const steps = selectMyGearState.getIn(['steps', 'values']);
  const stepsCount = steps && steps.size;

  const currentStep = selectMyGearState.get('currentStep');
  const isFirstStep = currentStep === 0 || !currentStep;

  if (!isFirstStep) {
    dispatch(selectMyGearActions.movePrevStep());

    dispatch(workflowActions.reportTaskProgress(currentStep - 1, stepsCount));
  }

  dispatch(workflowActions.loading());

  dispatch(selectMyGearThunks.saveToFrontendScratch())
    .then(() => {
      dispatch(workflowActions.exitLoading());

      if (isFirstStep) {
        moveWorkflowPrevTask();
      }
    })
    .catch(() => {
      // just exit loading state if there's an error
      dispatch(workflowActions.exitLoading());
    });
};

selectMyGearThunks.moveNextStep = (moveWorkflowNextTask) => (
  dispatch,
  getState
) => {
  const state = getState();
  const selectMyGearState = state.get('selectMyGear');

  const steps = selectMyGearState.getIn(['steps', 'values']);
  const stepsCount = steps.size;

  const currentStep = selectMyGearState.get('currentStep');
  const isLastStep = currentStep === stepsCount - 1;

  if (!isLastStep) {
    dispatch(selectMyGearActions.moveNextStep());

    dispatch(workflowActions.reportTaskProgress(currentStep + 1, stepsCount));
  }

  dispatch(workflowActions.loading());

  dispatch(selectMyGearThunks.saveToFrontendScratch())
    .then(() => {
      dispatch(workflowActions.exitLoading());

      if (isLastStep) {
        moveWorkflowNextTask();
      }
    })
    .catch(() => {
      // just exit loading state if there's an error
      dispatch(workflowActions.exitLoading());
    });
};

export default selectMyGearThunks;
