import React from 'react';
import pt from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Divider, List, Message } from 'semantic-ui-react';
import { debounce } from 'lodash';
import injectIntlToController from '../../../utils/injectIntlToController';
import FormActivityTemplateDetail from './OffboardingActivityTemplateDetailForm';
import uiPathGenerator from '../../../utils/uiPathGenerator';
import { useChangeTemplateStatus } from './utils/hooks/useCreateTemplate';
import useLoadTemplate from './utils/hooks/useLoadTemplate';
import { fromJS } from 'immutable';

const propTypes = {
  changeCategory: pt.func,
  changeStatus: pt.func,
  copyTemplate: pt.func,
  createTemplate: pt.func,
  duplicateTemplate: pt.func,
  isLoadingCreating: pt.bool,
  template: pt.shape({
    schedule_with_interval: pt.arrayOf(pt.shape({})),
  }),
  templateID: pt.string,
  updateTemplate: pt.func,
  updateTemplateList: pt.func,
};
const SAVE_DELAY = 100;

const OffboardingActivityTemplateDetail = ({
  changeCategory,
  changeStatus,
  copyTemplate,
  createTemplate,
  duplicateTemplate,
  isLoadingCreating,
  templateID,
  updateTemplate,
  updateTemplateList,
}) => {
  const {
    isChangeTemplateLoading,
    changeTemplateStatus,
    newStatus,
  } = useChangeTemplateStatus();
  const { template } = useLoadTemplate(templateID);

  const saveForm = debounce((values) => {
    if (templateID === 'new') {
      createTemplate(values);
    } else {
      updateTemplate(templateID, values);
    }
  }, SAVE_DELAY);

  const templateMap = fromJS(template);
  const schedules = templateMap.get('schedule_with_interval') || fromJS([]);
  const filteredSchedules = schedules
    .groupBy((s) => s.get('id'))
    .map((x) => x.first()); // TODO the api returns duplicated schedules BE task T4275

  const schedulesList = filteredSchedules.map((schedules) => ({
    as: NavLink,
    description: schedules.get('schedule_description'),
    header: schedules.get('schedule_name'),
    key: `${templateID}-${schedules.get('schedule_id')}`,
    to: uiPathGenerator.genPath('admin.adminActivities.offboarding.schedule', {
      scheduleID: schedules.get('schedule_id'),
    }),
  }));

  return (
    <>
      <FormActivityTemplateDetail
        changeCategory={changeCategory}
        changeStatus={changeStatus}
        changeTemplateStatus={changeTemplateStatus}
        copyTemplate={copyTemplate}
        duplicateTemplate={duplicateTemplate}
        isLoading={isLoadingCreating || isChangeTemplateLoading}
        newStatus={newStatus}
        saveForm={saveForm}
        template={template}
        templateID={templateID}
        updateTemplateList={updateTemplateList}
      />
      <Divider horizontal>{`Schedules (${schedulesList.size})`}</Divider>
      {schedulesList ? (
        <List divided items={schedulesList.toArray()} />
      ) : (
        <Message
          content='This template is not currently used in any activity schedules.'
          info
        />
      )}
    </>
  );
};

OffboardingActivityTemplateDetail.propTypes = propTypes;

export default injectIntlToController(OffboardingActivityTemplateDetail);
