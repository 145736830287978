import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import DepartmentSelect from './DepartmentSelect';

class DepartmentSelectField extends PureComponent {
  static propTypes = {
    /** Redux From input prop */
    input: PropTypes.shape({
      onChange: PropTypes.func,
    }).isRequired,
    //
    // Transforms department into value for Redux Form. Called with non null selectedDepartment as argument.
    //
    mapDepartmentToValue: PropTypes.func,
    //
    // Called when user picks a non null department,
    // 'selectedDepartment' and 'value' are passed as arguments,
    // where 'selectedDepartment' is an Immutable.Map and 'value' equals mapDepartmentToValue(selectedDepartment).
    //
    // (selectedDepartment, value)=> {}
    //
    onSelectDepartment: PropTypes.func,
    trigger: PropTypes.node,
  };

  static defaultProps = {
    mapDepartmentToValue(department) {
      return department.get('id');
    },
    onSelectDepartment: _.noop,
    trigger: null,
  };

  handleSelectDepartment = (selectedDepartment) => {
    const { input, mapDepartmentToValue, onSelectDepartment } = this.props;

    // value reported to Redux Form
    let value = null;

    if (selectedDepartment) {
      value = mapDepartmentToValue(selectedDepartment);
    }

    // report selection to Redux Form
    input.onChange(value);

    // report selection to parent component
    onSelectDepartment(selectedDepartment, value);
  };

  render() {
    // explicitly listing omitted props since 'propTypes' is removed for production builds
    const nonOwnProps = _.omit(this.props, [
      'input',
      'mapDepartmentToValue',
      'onSelectDepartment',
    ]);

    return (
      <DepartmentSelect
        onChange={this.handleSelectDepartment}
        {...nonOwnProps}
      />
    );
  }
}

export default DepartmentSelectField;
