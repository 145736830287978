import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';
import { isEmpty } from 'lodash';
import { intl } from 'esp-util-intl';

import ModalEsp from './ModalEsp';
import AddTaskForm from '../../../../../app/js/v1/components/molecules/AddTaskForm';

const propTypes = {
  addTask: PropTypes.func.isRequired,
  addTaskForm: ImmutablePropTypes.mapContains({
    syncErrors: PropTypes.shape({}),
    values: ImmutablePropTypes.mapContains({
      name: PropTypes.string,
    }),
  }),
  newTask: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    error: PropTypes.any,

    isLoading: PropTypes.bool,
  }).isRequired,
  trigger: PropTypes.node.isRequired,
  workflow: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    post_condition_group: PropTypes.any,
    url: PropTypes.string,
  }).isRequired,
};

const defaultProps = {
  addTaskForm: immutable.Map(),
};

class AddTaskModal extends React.Component {
  handleAddTask = (close) => {
    const { workflow, addTaskForm, addTask } = this.props;
    const name = addTaskForm.getIn(['values', 'name'], '').trim();

    if (isEmpty(name)) {
      return;
    }

    addTask(name, workflow, (error) => {
      if (!error) {
        close();
      }
    });
  };

  handleAddTaskFormSubmit = (values, close) => {
    this.addTask(close);
  };

  render() {
    const { newTask, addTaskForm, trigger } = this.props;

    let addTaskFormHasErrors = false;

    if (addTaskForm) {
      addTaskFormHasErrors = addTaskForm.has('syncErrors');
    }

    return (
      <ModalEsp
        acceptContent={intl.formatMessage({
          id: 'label.add',
        })}
        headerContent={intl.formatMessage({
          id: 'label.add_workflow_task',
        })}
        headerIcon='tasks'
        isAcceptDisabled={addTaskFormHasErrors}
        isLoading={newTask.isLoading}
        onAccept={this.handleAddTask}
        trigger={trigger}
      >
        <ModalEsp.Form onSubmit={this.handleAddTaskFormSubmit}>
          <AddTaskForm apiError={newTask.error} />
        </ModalEsp.Form>
      </ModalEsp>
    );
  }
}

AddTaskModal.propTypes = propTypes;
AddTaskModal.defaultProps = defaultProps;

export default AddTaskModal;
