import buildCompositeController from '../../../../../js/v1/utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const allowExpandableChatMessagesFeature = state.getIn([
    'tenant',
    'entity',
    'value',
    'ui_config',
    'messages',
    'allow_expandable_chatMessages_feature',
  ]);

  return {
    allowExpandableChatMessagesFeature,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
