import async from 'async';
import { fromJS } from 'immutable';
import { head, isEmpty, isNumber, noop, times } from 'lodash';

// Util
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
// Selector
import getCurrentUser from '../selectors/getCurrentUser';
import getSelectedBundle from '../selectors/getSelectedBundle';
import getSimpleWFRequestedFor from '../selectors/getSimpleWFRequestedFor';
// Global
import CartStatuses from '../globals/CartStatuses';

// Action
import cartActions from './cartActions';
import catalogThunks from './catalogThunks';
import catalogActions from './catalogActions';
import toastNotificationsActions from './toastNotificationsActions';

const productIsListed = (state, id) => {
  const productsAvailable = state.getIn(['entities', 'products']);

  return productsAvailable.find((product) => product.get('id') === id);
};

// Recursive func to check all products in a bundle
const addBundleProduct = (bundleSelected, state, rootArray) => {
  let root = rootArray || [];

  const products = bundleSelected.get('products');
  const childBundles = bundleSelected.get('child_bundles');
  const selectedHardwareBundleProductId = state.getIn([
    'bundles',
    'selectedHardwareBundleProductId',
  ]);
  const hardwareBundle = bundleSelected.get('tags') === 'hardware';

  // Add each product
  products.forEach((productId) => {
    if (
      !hardwareBundle ||
      (hardwareBundle && productId === selectedHardwareBundleProductId)
    ) {
      root.push(productId);
    }
  });

  // If children product, add htem recursively
  childBundles.forEach((childBundleID) => {
    // Get The bundle
    const childBundle = state.getIn(['entities', 'bundles', childBundleID]);
    if (childBundle) {
      root = addBundleProduct(childBundle, state, root);
    }
  });

  return root;
};

/**
 * Gets the only user's cart in CREATED status or null if it doesn't exist.
 */
const getShoppingCart = (userID, onShoppingCart = noop) => {
  const url = endpointGenerator.genPath('espCatalog.carts');

  APIcall.get({
    error(error) {
      onShoppingCart(error.response.body);
    },
    query: {
      esp_filters: encodeURI(
        `status__EQ=${CartStatuses.CREATED}&requested_for__EQ=${userID}`
      ),
    },
    success(response) {
      const carts = response.body.results;
      let shoppingCart = null;

      if (!isEmpty(carts)) {
        shoppingCart = head(carts);
      }

      onShoppingCart(null, shoppingCart);
    },
    token: true,
    url,
  });
};

/**
 * Create a shopping cart in CREATED status, so the user can add products into it.
 */
const addShoppingCart = (userID, onShoppingCart = noop) => {
  const url = endpointGenerator.genPath('espCatalog.carts');

  const cartSpec = {
    assigned_to: userID,
    requested_for: userID,
  };

  APIcall.post({
    data: cartSpec,
    error(error) {
      onShoppingCart(error.response.body);
    },
    success(response) {
      const shoppingCart = response.body;
      onShoppingCart(null, shoppingCart);
    },
    token: true,
    url,
  });
};

/**
 * Removes user's cart in CREARED status. Also removes all cart items inside it if any.
 */
const removeShoppingCart = (cartID, onRemoveShoppingCart = noop) => {
  const url = endpointGenerator.genPath('espCatalog.carts.instance', {
    cartID,
  });

  APIcall.delete({
    error(error) {
      onRemoveShoppingCart(error.response.body);
    },
    success() {
      onRemoveShoppingCart(null);
    },
    token: true,
    url,
  });
};

/**
 * Removes existing cart in CREATED status (if any) and create a brand new empty one.
 */
const removeAndAddShoppingCart = (
  userID,
  onRemoveAndAddShoppingCart = noop
) => {
  async.waterfall(
    [
      // 1. Check if user already has a shopping cart
      (next) => {
        getShoppingCart(userID, next);
      },

      // 2. Delete shopping cart if one exists
      (shoppingCart, next) => {
        if (shoppingCart) {
          const cartID = shoppingCart.id;
          removeShoppingCart(cartID, next);
        } else {
          next(null);
        }
      },

      // 3. Create new shopping cart to add the products the user selected
      (next) => {
        addShoppingCart(userID, next);
      },
    ],
    onRemoveAndAddShoppingCart
  );
};

const getPriceFormat = (price) =>
  price
    .toFixed(2)
    .replace(/./g, (c, i, a) =>
      i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c
    );

const cartThunks = {};

/** Fake loadUser to delete CREATED cart  of this user*/
// cartThunks.loadUserCart = (idProduct, quantity, bundle) => (dispatch, getState) => {
//   dispatch(cartActions.loadCartStart());
//
//   const state = getState();
//   const usrUrl = getCurrentUser(state).get('id');
//
//   const endpoint = endpointGenerator.genPath('espCatalog.carts');
//
//   return APIcall.get({
//     url     : endpoint+'?offset=0', //Change here the offset to clean
//     token   : true,
//     success : (res) => {
//
//       const userCart = res.body.results.filter((v) => v && v.assigned_to == usrUrl && v.status === 'CREATED');
//
//       if (userCart) {
//         const del = (idCart) => {
//           const endpoint = endpointGenerator.genPath('espCatalog.carts.instance', {cartID: idCart});
//
//           APIcall.delete({
//             url     : endpoint,
//             token   : true,
//             success : () => {
//               dispatch(cartActions.resetCartSuccess());
//             },
//             error: (err) => {
//               dispatch(cartActions.loadCartFailure(err));
//             },
//           });
//         };
//         userCart.forEach( (ct) => {
//           console.log('DELETE ',ct);
//           del(ct.id);
//         });
//
//       }
//     },
//     error: (err) => {
//       dispatch(cartActions.loadCartFailure(err));
//     },
//   });
// };

cartThunks.loadUserCart = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.loadCartStart());

    async.waterfall(
      [
        // 1. Load Cart
        (next) => {
          const state = getState();
          const userID = getCurrentUser(state).get('id');

          getShoppingCart(userID, (error, shoppingCart) => {
            if (error) {
              dispatch(cartActions.loadCartFailure(error));
              next(error);
            } else {
              next(null, shoppingCart);
            }
          });
        },

        // 2. Set next action
        (shoppingCart, next) => {
          if (shoppingCart) {
            dispatch(cartActions.loadUserCartSuccess(shoppingCart));
          } else {
            // No cart are available for now - Reset the loading state of the cart
            dispatch(cartActions.loadUserCartLoadingEnd());
          }
          next(null, shoppingCart);
        },

        // 3. Load Cart items
        (shoppingCart, next) => {
          if (shoppingCart) {
            dispatch(cartThunks.loadItemsCart())
              .then(() => next())
              .catch((err) => next(err));
          } else {
            next();
          }
        },
      ],
      (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      }
    );
  });

cartThunks.loadTaskCart = (eid) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.loadTaskCartStart());
    const url = endpointGenerator.genPath('espCatalog.carts');
    const query = {
      esp_filters: `eid__EQ=${eid}`,
      limit: 1,
    };
    APIcall.get({
      error(err) {
        dispatch(cartActions.loadTaskCartFail());
        reject(err);
      },
      query,
      success({ body }) {
        dispatch(cartActions.loadTaskCartSuccess());
        resolve(body.results[0]);
      },
      token: true,
      url,
    });
  });

cartThunks.loadTaskCartItems = (taskID, cart) => (dispatch) =>
  async.waterfall(
    [
      // Load cart items
      (next) => {
        if (cart) {
          const items = cart
            .get('items')
            .map((item) => ({
              itemId: item.get('product'),
              quantity: item.get('quantity'),
            }))
            .toJS();
          async.map(
            items,
            ({ itemId, quantity }, callback) => {
              dispatch(cartThunks.getSingleProduct(itemId))
                .then((res) =>
                  callback(null, {
                    ...res,
                    priceTotal: getPriceFormat(
                      Number(res.price.replace(',', '')) * (quantity || 1)
                    ),
                    quantity,
                  })
                )
                .catch((err) => callback(err));
            },
            (err, res) => {
              if (err) {
                next(err);
              } else if (res) {
                next(null, {
                  items: res,
                  status: cart.status,
                  total: getPriceFormat(
                    res.reduce(
                      (total, item) =>
                        (total += Number(item.priceTotal.replace(',', ''))),
                      0
                    )
                  ),
                });
              }
            }
          );
        } else {
          next(new Error('Cart is emtpy'));
        }
      },
    ],
    (err, res) => {
      if (err) {
        dispatch(cartActions.loadTaskCartItemsFail());
      } else if (res) {
        dispatch(cartActions.loadTaskCartItemsSuccess(taskID, res));
      }
    }
  );

cartThunks.getSingleProduct = (productId) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCatalog.products.instance', {
      productID: productId,
    });

    APIcall.get({
      error(err) {
        reject(err);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url,
    });
  });

cartThunks.loadItemsCart = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.loadCartStart());

    const state = getState();
    const idCart = state.getIn(['cart', 'id']);
    let query = {};

    if (idCart) {
      query = {
        esp_filters: encodeURI(`cart__EQ=${idCart}`),
      };
    }

    async.waterfall(
      [
        // 1. Get the CartItems
        (next) => {
          const url = endpointGenerator.genPath('espCatalog.cartItems');

          APIcall.get({
            error(error) {
              next(error);
            },
            query,
            success(response) {
              const cartItems = response.body.results;
              next(null, cartItems);
            },
            token: true,
            url,
          });
        },

        // 2. Figure out what are the missing products
        (cartItems, next) => {
          const missingProductIDs = cartItems.reduce(
            (missingProductIDs, item) => {
              const productID = item.product;
              const product = productIsListed(state, productID);

              if (!product || !product.get('id')) {
                missingProductIDs.push(productID);
              }

              return missingProductIDs;
            },
            []
          );

          next(null, cartItems, missingProductIDs);
        },

        // 3. Pull products we don't have
        (cartItems, missingProductIDs, next) => {
          if (isEmpty(missingProductIDs)) {
            // nothing to do, lets move
            next(null, cartItems);
          } else {
            const url = endpointGenerator.genPath('espCatalog.products');
            const commaSeparatedProductIDs = missingProductIDs.join(',');
            const espFilters = `id__IN=${commaSeparatedProductIDs}`;

            APIcall.get({
              error(error) {
                next(error);
              },
              query: {
                esp_filters: espFilters,
              },
              success(response) {
                // add the missing products to the Redux state
                const missingProducts = response.body.results;
                dispatch(catalogActions.loadProductsSuccess(missingProducts));
                next(null, cartItems);
              },
              token: true,
              url,
            });
          }
        },

        // 4. Add the cart items into the Redux state
        (cartItems, next) => {
          async.eachOf(
            cartItems,
            (item, key, eachOfDone) => {
              dispatch(
                cartThunks.getProduct(item.product, item.id, item.quantity)
              )
                .then(() => {
                  eachOfDone();
                })
                .catch((err) => eachOfDone(err));
            },
            (err) => {
              if (err) {
                next(err);
              } else {
                next();
              }
            }
          );
        },
      ],
      (error) => {
        dispatch(cartActions.stopLoadCartWF());
        if (error) {
          dispatch(cartActions.loadCartFailure(error));
          reject(error);
        } else {
          dispatch(cartActions.loadCartSuccess());
          resolve();
        }
      }
    );
  });

cartThunks.getProduct = (productId, citemId, quantity) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const findProduct = productIsListed(state, productId);
    // Product already in store
    if (findProduct && findProduct.get('id')) {
      dispatch(
        cartActions.fillCartSuccess({
          citemId: citemId,
          id: findProduct.get('id'),
          qty: quantity,
        })
      ); // Fill the cart item
      resolve();
      return;
    }
    const endPoint = endpointGenerator.genPath('espCatalog.products.instance', {
      productID: productId,
    });

    APIcall.get({
      error(err) {
        dispatch(cartActions.loadCartFailure(err));
        reject(err);
      },
      success(res) {
        res.body.citemId = citemId; // Need to populate a citemId to get it if usr want to delete an item in the cart

        const qty = quantity || res.body.quantity;
        dispatch(
          cartActions.fillCartSuccess({
            citemId: citemId,
            id: res.body.id,
            qty: qty,
          })
        ); // Fill the cart item

        dispatch(catalogActions.loadProductsSuccess([fromJS(res.body)])); // Fill the cart entities
        resolve();
      },
      token: true,
      url: endPoint,
    });
  });

cartThunks.addProduct = (
  idProduct,
  quantity,
  ignoreOtherCartItems = false,
  weight
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // dispatch(cartActions.cartIsloading());
    dispatch(cartActions.addCatalogItemStart(idProduct));
    const state = getState();
    const cartStatus = state.getIn(['cart', 'status']);

    const onUpdate = (citemId, qty, next) => {
      dispatch(cartThunks.updateProduct(idProduct, citemId, qty, weight, next));
    };

    const onAddNewProduct = (newItem, next) => {
      const endpoint = endpointGenerator.genPath('espCatalog.cartItems');
      APIcall.post({
        data: newItem,
        error(err) {
          dispatch(cartActions.loadCartFailure());
          next(err);
        },
        success(res) {
          dispatch(cartActions.addCatalogItemSuccess());
          const response = res.body;
          next(null, response);
        },
        token: true,
        url: endpoint,
      });
    };

    async.waterfall(
      [
        // 1. Check Cart status
        (next) => {
          // No cart available, create a new one
          if (!cartStatus) {
            dispatch(cartThunks.createCart())
              .then(() => next())
              .catch((err) => next(err));
          } else {
            next();
          }
        },

        // 2. Add product in the cart
        (next) => {
          // Check if we already have this product in cart
          const state = getState();
          const idCart = state.getIn(['cart', 'id']);
          const cartItems = state.getIn(['cart', 'cartItems']);
          let citemId = null,
            numItem = 0;

          if (!ignoreOtherCartItems) {
            cartItems.forEach((item) => {
              if (item.get('id') === idProduct) {
                citemId = item.get('citemId');
                numItem = item.get('qty');
              }
            });
          }

          if (citemId) {
            const qty = numItem + quantity;
            onUpdate(citemId, qty, next);
          } else {
            const newItem = {
              bundle: null,
              cart: idCart,
              product: idProduct,
              quantity: quantity,
              status: 'CREATED',
              sys_custom_fields: null,
            };

            // Should we pass the weight ?
            if (isNumber(weight)) {
              newItem.weight = weight;
            }
            onAddNewProduct(newItem, next);
          }
        },
      ],
      (error, response) => {
        if (error) {
          // ToDo maybe leave this error to the APICall to take care of?
          dispatch(
            toastNotificationsActions.error({
              message: error.message,
              title: 'Error adding cart item',
            })
          );
          reject(error);

          throw new Error('Error adding cart item');
        } else {
          resolve(response);
        }
      }
    );
  });

cartThunks.removeProduct = (citemId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.removeItemStart(citemId));

    const endpoint = endpointGenerator.genPath(
      'espCatalog.cartItems.instance',
      {
        cartItemID: citemId,
      }
    );

    return APIcall.delete({
      error(err) {
        dispatch(cartActions.loadCartFailure(err));
        reject();
      },
      success() {
        dispatch(cartActions.removeItem(citemId));
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

cartThunks.clearCartItems = (citemId, cb = noop) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.cartIsloading());

    const endpoint = endpointGenerator.genPath(
      'espCatalog.cartItems.instance',
      {
        cartItemID: citemId,
      }
    );

    return APIcall.delete({
      error(err) {
        dispatch(cartActions.loadCartFailure(err));
        reject();
      },
      success() {
        dispatch(cartActions.removeItem(citemId));
        resolve();
        cb();
      },
      token: true,
      url: endpoint,
    });
  });

cartThunks.clearAllCartItems = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const idCart = state.getIn(['cart', 'id']);

    if (!isNumber(idCart)) {
      return false;
    }

    const endpoint = endpointGenerator.genPath(
      'espCatalog.carts.instance.items',
      {
        cartID: idCart,
      }
    );

    return APIcall.delete({
      error(err) {
        dispatch(cartActions.loadCartFailure(err));
        reject();
      },
      success() {
        dispatch(cartActions.clearCart());
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

cartThunks.updateProduct = (idProduct, citemId, newQty, weight, cb = noop) => (
  dispatch,
  getState
) => {
  dispatch(cartActions.cartIsloading());

  const state = getState();
  const idCart = state.getIn(['cart', 'id']);

  const url = endpointGenerator.genPath('espCatalog.cartItems.instance', {
    cartItemID: citemId,
  });

  const item = {
    bundle: null,
    cart: idCart,
    id: citemId,
    product: idProduct,
    quantity: newQty,
    status: 'CREATED',
    sys_custom_fields: null,
    url,
  };

  if (weight) {
    item.weight = weight;
  }

  return APIcall.put({
    data: item,
    error(err) {
      dispatch(cartActions.loadCartFailure(err));
      cb(err);
    },
    success(res) {
      dispatch(cartActions.updateCartSuccess(res.body));
      cb();
    },
    token: true,
    url,
  });
};

/**
 * SelectMyGear block takes care of guiding the user thru equipment selection
 * and storing all choices into frontendScratch.
 *
 * This does the following:
 *
 * 1. Remove existing shopping cart (if any)
 * 2. Create a new shopping cart
 * 3. Add selected products to the cart
 * 4. Submit fulfillment answers for the products that require them
 */
cartThunks.fillFromSelectMyGear = (requestedFor) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.startLoadCartWF()); // Set isLoading flag for the entire process

    const state = getState();
    const workflowState = state.get('workflowState');
    const frontendScratch = workflowState.get('frontendScratch');
    const selectMyGearScratch = frontendScratch.get('selectMyGear');

    // Immutable.List
    const selectedProductIDsList = selectMyGearScratch.get(
      'selectedProductIDs'
    );

    // Immutable.List
    const weightsList = selectMyGearScratch.get('weights');

    // Immutable.List
    const fulfillmentAnswersList = selectMyGearScratch.get(
      'fulfillmentAnswers'
    );

    const stepsCount = selectedProductIDsList.size;
    // each operation encapsulates 'productID', 'weight', and (maybe null) 'fulfillmentAnswers',
    // as plain JS data
    const operations = [];

    times(stepsCount, (stepIndex) => {
      // Immutable.List
      const selectedProductIDs = selectedProductIDsList.get(stepIndex);
      // Immutable.Map
      const weights = weightsList.get(stepIndex);
      // Immutable.Map
      const fulfillmentAnswersByProduct = fulfillmentAnswersList.get(stepIndex);
      selectedProductIDs.forEach((productID) => {
        const operation = {};
        operation.productID = productID;

        const weight = weights.get(String(productID));
        operation.weight = weight;
        let fulfillmentAnswers =
          fulfillmentAnswersByProduct.get(String(productID)) || null;

        if (fulfillmentAnswers) {
          fulfillmentAnswers = fulfillmentAnswers.toJS();
        }
        operation.fulfillmentAnswers = fulfillmentAnswers;

        operations.push(operation);
      });
    });

    const currentUser = requestedFor
      ? getSimpleWFRequestedFor(state)
      : getCurrentUser(state);

    const userID = currentUser.get('id');

    const addProductsAndFulfillmentAnswers = (done = noop) => {
      async.each(
        operations,
        (operation, eachDone) => {
          const { productID, weight, fulfillmentAnswers } = operation;

          async.waterfall(
            [
              // 1. Create the cart item
              (next) => {
                dispatch(cartThunks.addProduct(productID, 1, false, weight))
                  .then((cartItem) => next(null, cartItem))
                  .catch((error) => next(error));
              },

              // 2. Submit fulfillment answers (if any)
              (cartItem, next) => {
                if (fulfillmentAnswers) {
                  const cartItemID = cartItem.id;

                  async.eachOf(
                    fulfillmentAnswers,
                    (answer, questionID, eachOfDone) => {
                      dispatch(
                        cartThunks.saveCartItemAnswer(
                          cartItemID,
                          questionID,
                          answer
                        )
                      ).then(() => eachOfDone());
                    },
                    next
                  );
                } else {
                  next(null);
                }
              },
            ],
            eachDone
          );
        },
        done
      );
    };

    // clear cart and cart items in the Redux state, we are going to set it up again below
    dispatch(cartActions.loadCartStart());

    async.series(
      [
        (next) => {
          removeAndAddShoppingCart(userID, (error, shoppingCart) => {
            if (error) {
              next(error);
            } else {
              // this sets up the cart in Redux state and is required for the next step to work
              dispatch(cartActions.loadUserCartSuccess(shoppingCart, true));
              next(null);
            }
          });
        },

        (next) => {
          addProductsAndFulfillmentAnswers((error) => {
            if (error) {
              next(error);
            } else {
              next(null);
            }
          });
        },
      ],
      (error) => {
        if (error) {
          dispatch(cartActions.stopLoadCartWF());
          reject(error);
        } else {
          resolve();
        }
      }
    );
  });

//
// This guys needs refactor, for now it's working fine and without any race conditions =)
// Ozzy
//
// 08/07/2017 From now, this is no longer going to be used in WF3
// Ozzy
//
cartThunks.fillFromBundle = (productsToAdd, cb = noop) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    const userID = currentUser.get('id');

    const bundleSelected = getSelectedBundle(state);
    const productsBundleToAdd =
      productsToAdd || addBundleProduct(bundleSelected, state);
    const workflowState = state.get('workflowState');
    const frontendScratch = workflowState.get('frontendScratch');
    const fulfillmentAnswersByProduct = frontendScratch.get(
      'fulfillmentAnswers',
      fromJS({})
    );

    const addProductsAndFulfillmentAnswers = (done = noop) => {
      async.each(
        productsBundleToAdd,
        (product, eachDone) => {
          const productID = product.id;

          async.waterfall(
            [
              // 1. Create the cart item
              (next) => {
                dispatch(
                  cartThunks.addProduct(productID, 1, false, product.weight)
                )
                  .then((cartItem) => next(null, cartItem))
                  .catch((error) => next(error));
              },

              // 2. Submit fulfillment answers (if any)
              (cartItem, next) => {
                const fulfillmentAnswers = fulfillmentAnswersByProduct.get(
                  String(productID)
                );

                if (fulfillmentAnswers) {
                  const cartItemID = cartItem.id;

                  async.eachOf(
                    fulfillmentAnswers.toJS(),
                    (answer, questionID, eachOfDone) => {
                      dispatch(
                        cartThunks.saveCartItemAnswer(
                          cartItemID,
                          questionID,
                          answer
                        )
                      ).then(() => eachOfDone());
                    },
                    next
                  );
                } else {
                  next(null);
                }
              },
            ],
            eachDone
          );
        },
        done
      );
    };

    async.series(
      [
        (next) => {
          removeAndAddShoppingCart(userID, (error, shoppingCart) => {
            if (error) {
              next(error);
            } else {
              // this sets up the cart in Redux state and is required for the next step to work
              dispatch(cartActions.loadUserCartSuccess(shoppingCart));
              next(null);
            }
          });
        },

        (next) => {
          addProductsAndFulfillmentAnswers((error) => {
            if (error) {
              next(error);
            } else {
              dispatch(cartThunks.loadItemsCart());
              next(null);
            }
          });
        },
      ],
      (error) => {
        if (error) {
          reject(error);
        } else {
          cb();
          resolve();
        }
      }
    );
  });

cartThunks.deleteCart = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.loadCartStart());

    const state = getState();
    const idCart = state.getIn(['cart', 'id']);

    const endpoint = endpointGenerator.genPath('espCatalog.carts.instance', {
      cartID: idCart,
    });

    return APIcall.delete({
      error(err) {
        dispatch(cartActions.loadCartFailure(err));
        reject(err);
      },
      success() {
        dispatch(cartActions.resetCartSuccess());
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

cartThunks.createCart = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(cartActions.loadCartStart());

    const state = getState();
    const currentUserId = getCurrentUser(state).get('id');

    const cartData = {
      assigned_to: currentUserId,
      requested_for: currentUserId,
      requester: currentUserId,
      sys_custom_fields: null,
    };

    const endpoint = endpointGenerator.genPath('espCatalog.carts');

    async.waterfall(
      [
        // 1. Create a new Cart
        (next) => {
          APIcall.post({
            data: cartData,
            error(err) {
              next(err);
            },
            success(res) {
              next(null, res);
            },
            token: true,
            url: endpoint,
          });
        },

        // 2. Load the new Cart created
        (res, next) => {
          dispatch(cartThunks.loadUserCart()).then(() => next());
        },
      ],
      (error) => {
        if (error) {
          dispatch(cartActions.loadCartFailure(error));
          reject(error);
        } else {
          resolve();
        }
      }
    );
  });

/**
 * Makes API call to change cart status to SUBMITTED
 * Additionally, every cart item will have its status changed too
 */
cartThunks.orderCart = (parentId, ownerId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const cartID = state.getIn(['cart', 'id']);
    const cartItems = state.getIn(['cart', 'cartItems']);

    dispatch(cartActions.cartIsloading());

    if (cartItems.isEmpty()) {
      resolve();
      return;
    }
    async.waterfall(
      [
        // 1. Change status of all cart items
        (next) => {
          async.each(
            cartItems.toJS(),
            (cartItem, eachDone) => {
              dispatch(cartThunks.submitCartItems(cartItem.citemId, eachDone));
            },
            next
          );
        },

        // 2. Change the status of the cart itself
        (next) => {
          const url = endpointGenerator.genPath(
            'espCatalog.carts.instance.changeStatus',
            {
              cartID,
            }
          );

          const change = {
            ...(isNumber(ownerId) && { owner_id: ownerId }),
            ...(isNumber(parentId) && { parent_task_id: parentId }),
            status: 'SUBMITTED',
          };

          APIcall.post({
            data: change,
            error(error) {
              next(error);
            },
            success({ body }) {
              next(null, body);
            },
            token: true,
            url,
          });
        },
      ],
      (error, cart) => {
        if (error) {
          dispatch(cartActions.loadCartFailure(error));
          reject(error);
        } else {
          dispatch(cartActions.resetCartSuccess());
          resolve(cart);
        }
      }
    );
  });

cartThunks.submitCartItems = (cartItemId, cb = noop) => (dispatch) => {
  const endpoint = endpointGenerator.genPath(
    'espCatalog.cartItems.instance.changeStatus',
    {
      cartItemID: cartItemId,
    }
  );
  const cartData = {
    item_status: 'SUBMITTED',
  };

  return APIcall.post({
    data: cartData,
    error(err) {
      dispatch(cartActions.loadCartFailure(err));
      cb(err);
    },
    success() {
      cb(null);
    },
    token: true,
    url: endpoint,
  });
};

cartThunks.markPendingQuestionsForProduct = (productId, quantity) => (
  dispatch
) => {
  dispatch(cartActions.fulfillmentQuestionsStarted(productId, quantity));
};

cartThunks.saveCartItemAnswer = (cartItemId, optionId, value) => (dispatch) =>
  new Promise((resolve, reject) => {
    const endpoint = endpointGenerator.genPath(
      'espCatalog.cartItems.instance.answers',
      {
        cartItemID: cartItemId,
      }
    );

    // Fix for boolean values, as the expected values for those are these strings
    if (typeof value === 'boolean') {
      value = value ? 'True' : 'False';
    }

    const requestData = {
      cart_item: cartItemId,
      option: optionId,
      value: value,
    };

    return APIcall.post({
      data: requestData,
      error(err) {
        dispatch(cartActions.loadCartFailure(err));
        reject(err);
      },
      success() {
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

cartThunks.saveFulfillmentAnswers = (fieldValues, productId, cb = noop) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const quantity =
      state.getIn(['cart', 'productsToBeFulfilled', productId]) || 1;

    let newCartItem;
    async.series(
      [
        (next) => {
          // 1. Add Product to the cart
          const callback = (response) => {
            newCartItem = response;
            cb();
            next();
          };

          dispatch(cartThunks.addProduct(productId, quantity, true, null)).then(
            (response) => {
              callback(response);
            }
          );
        },
        (next) => {
          const cartItemId = newCartItem.id;
          // 2. Save all the answers
          async.eachOf(
            fieldValues.toJS(),
            // 2.1 Submit all the ansers one by one
            (value, key, done) => {
              dispatch(
                cartThunks.saveCartItemAnswer(cartItemId, key, value)
              ).then(() => done());
            },
            // 2.2 Then submit the cart status
            (err) => {
              if (!err) {
                next();
              }
            }
          );
        },
        // 3. Mark off the item answer as completed
        (next) => {
          dispatch(cartActions.fulfillmentQuestionsFinished(productId));
          dispatch(cartThunks.loadItemsCart());
          next();
        },
      ],
      (error) => {
        if (error) {
          dispatch(cartActions.loadCartFailure(error));
          reject(error);
        } else {
          resolve();
        }
      }
    );
  });

// Check if there's fulfilment questions. If so it doesn't add product to the cart
cartThunks.addCartProductCheckingFulfillment = (
  productId,
  quantity,
  cb = noop
) => (dispatch) => {
  // load answers
  dispatch(catalogThunks.getFulfillmentQuestionsByProduct(productId)).then(
    (optionsResults) => {
      if (optionsResults.length < 1) {
        // only add product if no fulfilment options
        dispatch(cartThunks.addProduct(productId, quantity, null, null))
          .then((res) => cb(res))
          .catch((err) => cb(err));
      } else {
        dispatch(cartActions.loadUserCartLoadingEnd()); // Stop the loading state since there is question(s)
        dispatch(
          cartThunks.markPendingQuestionsForProduct(productId, quantity)
        );
      }
    }
  );
};

// Adds product to the cart, checking for fullfilment questions first
cartThunks.addCartProduct = (productId, quantity) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(cartActions.cartIsloading());
    dispatch(
      cartThunks.addCartProductCheckingFulfillment(productId, quantity, () => {
        dispatch(cartThunks.loadItemsCart()); // Reload the cart
        resolve();
      })
    );
  });

export default cartThunks;
