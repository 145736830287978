import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { curry, isNumber, memoize, noop } from 'lodash';

import SelectMyGearProductFamilyItem from '../atoms/SelectMyGearProductFamilyItem';
import SelectMyGearProductItem from '../atoms/SelectMyGearProductItem';
import SelectMyGearList from './SelectMyGearList';
import SelectMyGearStepTypes from '../../globals/SelectMyGearStepTypes';

class SelectMyGearBundleAllStep extends PureComponent {
  static propTypes = {
    onAddProduct: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    step: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    onAddProduct: noop,
    onRemoveProduct: noop,
  };

  handleSelectProductFromFamily = memoize(
    curry((childIndex, selectedProductID, newSelectedProductID) => {
      const { onAddProduct, onRemoveProduct } = this.props;

      if (isNumber(selectedProductID)) {
        // remove the old product from selection
        onRemoveProduct(selectedProductID);
      }

      if (isNumber(newSelectedProductID)) {
        // use position in bundle as relative weight
        onAddProduct(newSelectedProductID, childIndex);
      }
    })
  );

  render() {
    const { step } = this.props;

    const items = step.get('items');

    return (
      <SelectMyGearList>
        {items.map((item, childIndex) => {
          if (item.get('type') === SelectMyGearStepTypes.PRODUCT) {
            const product = item.get('entity');

            return (
              <SelectMyGearProductItem key={product.get('id')} step={item} />
            );
          } else {
            // has to be a product family
            const product = item.get('representativeEntity');

            return (
              <SelectMyGearProductFamilyItem
                key={product.get('id')}
                onSelect={this.handleSelectProductFromFamily(childIndex)}
                step={item}
              />
            );
          }
        })}
      </SelectMyGearList>
    );
  }
}

export default SelectMyGearBundleAllStep;
