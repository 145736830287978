import React from 'react';
import pt from 'prop-types';
import { Button, Header, Table } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import uiPathGenerator from '../../../../../utils/uiPathGenerator';
import dateFormat from '../../../../../utils/dateFormat';
import useOffboardingSchedule from '../hooks/useOffboardingSchedule';

const propTypes = {
  loadOffboardingSchedules: pt.func,
  tableList: pt.arrayOf(pt.shape({})),
};

const tableHeader = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>{'Title'}</Table.HeaderCell>
      <Table.HeaderCell>{'Updated'}</Table.HeaderCell>
      <Table.HeaderCell>{'Status'}</Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  </Table.Header>
);

const tableRow = ({ list, handleDelete }) => {
  const { id, name, sys_date_updated, description, status } = list;

  const linkTo = uiPathGenerator.genPath(
    'admin.adminActivities.offboarding.schedule',
    {
      scheduleID: id,
    }
  );
  const linkToCopy = uiPathGenerator.genPath(
    'admin.adminActivities.offboarding.schedule',
    {
      action: 'copy',
      scheduleID: id,
    }
  );
  // const deletedLoading = loadingDeleteID === id;

  return (
    <Table.Row key={id}>
      <Table.Cell selectable width={7}>
        <Header as={NavLink} to={linkTo}>
          <h3>{name}</h3>
          <Header.Subheader>{description}</Header.Subheader>
        </Header>
      </Table.Cell>
      <Table.Cell width={3}>{dateFormat(sys_date_updated, true)}</Table.Cell>
      <Table.Cell width={3}>{status}</Table.Cell>
      <Table.Cell textAlign='right' width={3}>
        <Button.Group basic>
          <Button as={Link} content='View' to={linkTo} />
          <Button as={Link} content='Copy' to={linkToCopy} />
          <Button
            content='Delete'
            // loading={deletedLoading}
            onClick={handleDelete(id, name)}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

const ScheduleTable = ({ tableList, loadOffboardingSchedules }) => {
  const { deleteScheduleActivity } = useOffboardingSchedule();

  const handleDelete = (scheduleID, name) => () => {
    const msg = {
      header: 'Success',
      message: `The ${name} activity has been deleted`,
    };
    deleteScheduleActivity({ msg, scheduleID }).then(() => {
      loadOffboardingSchedules({});
    });
  };
  return (
    <Table>
      {tableHeader()}
      <Table.Body>
        {tableList?.map((list) => tableRow({ handleDelete, list }))}
      </Table.Body>
    </Table>
  );
};

ScheduleTable.propTypes = propTypes;

export default ScheduleTable;
