import React, { useCallback } from 'react';
import { DEFAULT_FORM_ACTIONS, PROP_TYPES } from './__globals';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { HTTP_METHODS, useAPIGet, useAPISet } from 'esp-auth';
import { ActionStack, Form } from '@espressive/cascara';

import { BackButton, PageTitle } from '../../components';
import { actionNewRecordLabel } from '../../hooks';

import styles from './SectionDetail.module.scss';

import castModuleValues from '../castModuleValues';

const propTypes = PROP_TYPES;

// This will get more complicated in the future when we match the work we are doing to connect each section. We can move all boilerplate here.
const SectionDetail = ({
  actions,
  dataDisplay,
  endpointUrl,
  formActions = DEFAULT_FORM_ACTIONS,
  pageTitleSub,
  titleParam,
  uniqueIdAttribute = 'eid',
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { id, '*': apiPath } = useParams();

  const url = id ? `${endpointUrl}${id}/` : endpointUrl;

  // fetch data from the endpoint
  const { data = { data: {} }, refetch } = useAPIGet({
    url,
  });
  const saveHTTPMethod = id ? HTTP_METHODS.PATCH : HTTP_METHODS.POST;
  // create the mutation function
  const { save } = useAPISet({
    method: saveHTTPMethod,
    url,
  });

  const onAction = useCallback(
    async (action, record = {}) => {
      const tempSafeRecord = castModuleValues(record, dataDisplay);

      // const recordId = record[uniqueIdAttribute];
      switch (action?.name) {
        case 'edit.start':
          break;

        case 'edit.cancel':
          // WIP
          break;
        case 'edit.save':
          if (id) {
            await save({
              data: tempSafeRecord,
              params: { id },
            });
          } else {
            const newRecord = await save({ data: tempSafeRecord });
            if (newRecord && newRecord.data) {
              const newIdCreated = newRecord.data[uniqueIdAttribute];
              const basePath = location.pathname
                .replace('/new', '')
                .replace('/control-center', '');
              navigate(`/${basePath}/${newIdCreated}`);
            } else {
              // eslint-disable-next-line no-console -- alert in case the record was not saved
              console.log('something went wrong');
            }
          }

          refetch();
          break;

        default:
        // skip
      }
    },
    [
      dataDisplay,
      id,
      location.pathname,
      refetch,
      navigate,
      save,
      uniqueIdAttribute,
    ]
  );

  // The apiPath parameter is how we identify our API Explorer sections
  const pageTitle =
    id || apiPath ? data?.data[titleParam] : actionNewRecordLabel;

  return (
    <>
      <PageTitle
        post={<ActionStack actions={actions} />}
        pre={<BackButton />}
        sub={pageTitleSub}
        title={pageTitle}
      />

      <div className={styles._}>
        <Form
          actions={formActions}
          data={data?.data}
          dataDisplay={dataDisplay}
          isInitialEditing
          onAction={onAction}
          resolveRecordActions={formActions?.resolveRecordActions}
          uniqueIdAttribute={uniqueIdAttribute}
        />
      </div>
    </>
  );
};

SectionDetail.propTypes = propTypes;

export default SectionDetail;
