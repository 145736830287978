import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftware extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M83 71.5c0 2.757-2.243 5-5 5H18c-2.757 0-5-2.243-5-5V31.597h70V71.5zM17.69 21.654c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69c-1.485 0-2.69-1.204-2.69-2.69s1.205-2.69 2.69-2.69zm10.303 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm10.302 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69c-1.485 0-2.69-1.204-2.69-2.69s1.205-2.69 2.69-2.69zM78 17H18c-4.418 0-8 3.582-8 8v46.5c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V25c0-4.418-3.582-8-8-8z' />
          <path d='M42.638 57.567c.44-.365 1.112-.325 1.5.09l2.373 2.525V48.594c0-.55.48-.998 1.07-.998s1.066.447 1.066.998v11.61l2.39-2.548c.388-.414 1.06-.455 1.5-.09.44.364.484.996.096 1.41l-4.14 4.408c-.183.3-.515.513-.91.513-.437 0-.81-.248-.975-.6l-4.056-4.322c-.39-.413-.346-1.045.094-1.408m-9.44 11.41c.437 0 .812 0 1.237-.014l28.916-.046c-.002.006 2.412-.012 2.415-.047 3.71-.458 6.59-3.585 6.59-7.42 0-4.148-3.364-7.51-7.51-7.51-.43 0-.84.056-1.25.125.02-.296.045-.59.045-.89 0-8.055-6.53-14.586-14.585-14.586-7.09 0-12.99 5.05-14.308 11.75-.522-.09-1.05-.16-1.596-.16-5.22 0-9.454 4.23-9.454 9.45 0 5.22 4.27 9.34 9.492 9.34' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftware;
