const api_espuser_v0_1_department = {
  results: [
    {
      code: 'global',
      id: 54679,
      location_type: 8,
      location_type_name: 'zone',
      name: 'global',
      note: 'This is a note',
      parent: null,
      url: 'https://release.qa.espressive.com/api/places/v0.1/locations/54679/',
    },
    {
      code: 'everylocation',
      id: 34455,
      location_type: 8,
      location_type_name: 'zone',
      name: 'Every Location',
      note: 'nothing5',
      parent: null,
      sys_custom_fields: null,
      sys_date_created: '2019-01-24T18:46:09.173975Z',
      sys_date_updated: '2019-09-25T17:38:17.751899Z',
      url: 'https://release.qa.espressive.com/api/places/v0.1/locations/34455/',
    },
    {
      code: '(empty)',
      id: 54440,
      location_type: 8,
      location_type_name: 'zone',
      name: '(empty)',
      note: null,
      parent: null,
      sys_custom_fields: null,
      sys_date_created: '2019-08-27T22:12:37.677433Z',
      sys_date_updated: '2019-08-27T22:12:37.733408Z',
      url: 'https://release.qa.espressive.com/api/places/v0.1/locations/54440/',
    },
  ],
};

export default api_espuser_v0_1_department;
