/* eslint-disable sort-keys -- backend shape */
const data = {
  results: [
    { archetype: 'Finance How do I enroll archetype', total: 2, label: null },
    {
      archetype: 'Finance View my statement archetype',
      total: 1,
      label: 'View my statement for a finance service',
    },
  ],
  count: 2,
};
/* eslint-enable sort-keys */

export default data;
