import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import faqThunks from '../../actions/faqThunks';
// Action
import workflowActions from '../../actions/workflowActions';

const mapDispatchToProps = (dispatch) => ({
  setValues(kbTitle) {
    dispatch(faqThunks.setValueFAQFromKB(kbTitle)).then(() => {
      dispatch(workflowActions.updateAttributesFromBEScratch());
    });
  },
});

export default buildCompositeController(null, mapDispatchToProps);
