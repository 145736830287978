import { createSelector } from 'reselect';
import getUserDataFromEntities from './getUserDataFromEntities';

const getUser = (state, id) => getUserDataFromEntities(state, id);

const getUserEid = (user) => {
  if (user) {
    return user.get('eid');
  }
  return null;
};
const selector = createSelector([getUser], getUserEid);

export default selector;
