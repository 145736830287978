/**
 * Enum for 'hierarchyType' prop of HierarchySelect component.
 */
const HierarchyTypes = {
  DEPARTMENTS: 'DEPARTMENTS',

  LOCATIONS: 'LOCATIONS',
};

export default HierarchyTypes;
