import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Step } from 'semantic-ui-react';

// Utils
import uiPathGenerator from '../../../../../utils/uiPathGenerator';

class IntegrationCSVMappingAsset extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;

    const assetMappingSteps = [
      {
        activeClassName: 'active',
        as: NavLink,
        description: 'Choose your CSV',
        exact: true,
        key: 'asset',
        title: 'File',
        to: uiPathGenerator.genPath('admin.integrations.csv.mapping.asset'),
      },
      {
        activeClassName: 'active',
        as: NavLink,
        description: 'Map CSV fields',
        key: 'fields',
        title: 'Fields',
        to: uiPathGenerator.genPath(
          'admin.integrations.csv.mapping.asset.mapFields'
        ),
      },
      {
        activeClassName: 'active',
        as: NavLink,
        description: 'Mapping preview',
        key: 'preview',
        title: 'Preview',
        to: uiPathGenerator.genPath(
          'admin.integrations.csv.mapping.asset.mapPreview'
        ),
      },
    ];

    return (
      <div>
        <Step.Group fluid items={assetMappingSteps} ordered size='mini' />

        {children}
      </div>
    );
  }
}

export default IntegrationCSVMappingAsset;
