import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';

// Globals
import TypeRadio, { TypeDefaults } from '../../../globals/blocks/TypeRadio';

// Controller
import Radio02Controller from '../../controllers/Radio02Controller';

class Radio01 extends Component {
  static propTypes = {
    ...TypeRadio,
    onChangeValue: PropTypes.func,
  };
  static defaultProps = {
    ...TypeDefaults,
    onChangeValue: noop,
  };

  constructor(props) {
    super(props);

    // Marks default selected option
    const defaultSelectedValue =
      props.formValues && props.formValues.get(props.id);
    const optionsWithDefaultValue =
      props.options &&
      props.options.map((opt) => {
        if (defaultSelectedValue) {
          if (opt.id === defaultSelectedValue) {
            opt.checked = true;
            return opt;
          }
        }
        return opt; // otherwise don't do anything
      });

    this.state = {
      options: optionsWithDefaultValue,
    };
  }

  handleClick = memoize(
    curry((optionId, e) => {
      // Marking only selected option as checked
      const { onChangeValue, options, id } = this.props;
      const newOptions = options.map((option) => {
        option.checked = option.id === optionId;
        if (option.checked) {
          onChangeValue(option.id, id);
        }
        return option;
      });

      this.setState({
        options: newOptions,
      });
    })
  );

  render() {
    const { template, type, labelText, id } = this.props;
    const { options } = this.state;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {labelText ? (
          <Form.Field label={labelText} name={id.toString()} />
        ) : null}

        {options.map((option) => (
          <Form.Field key={option.id}>
            <Checkbox
              as={option.as}
              checked={option.checked}
              disabled={option.disabled}
              fitted={option.fitted}
              id={option.id}
              label={option.label}
              onClick={this.handleClick(option.id)}
              radio
              slider={option.slider}
              value={option.id}
            />
          </Form.Field>
        ))}
      </div>
    );
  }
}

export const Radio01Test = Radio01;

export default Radio02Controller(Radio01);
