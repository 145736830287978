const api_case_mgmt_v0_1_service_department = {
  count: 1,
  results: [
    {
      auth_group_id: 29,
      auth_group_name: 'CASE_TECHNICAL_CONTACT_HR',
      description: 'wefwefwef',
      display_name: 'fwef',
      eid: '3d46bda8-3ee9-46d3-b5e1-6646d66ddf86',
      id: 25,
      service_department: 'HR',
      sys_created_by: 'john@acme.com',
      sys_date_created: '2017-10-06T21:02:39.795750Z',
      sys_date_updated: '2017-10-06T21:02:39.795689Z',
      sys_updated_by: 'john@acme.com',
      technical_contact: 3,
      url: 'http://tenant1.esp/api/case_mgmt/v0.1/service_department/25/',
    },
  ],
};

export default api_case_mgmt_v0_1_service_department;
