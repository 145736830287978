import { FETCH_METHODS, MUTATE_METHODS } from '../constants/httpMethods';

const getMethodInfo = (method) => {
  const isFetching = FETCH_METHODS.includes(method);
  const isMutating = MUTATE_METHODS.includes(method);
  const isUnknownMethod = !isFetching && !isMutating;

  return {
    isFetching,
    isMutating,
    isUnknownMethod,
  };
};

export default getMethodInfo;
