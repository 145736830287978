import { curry } from 'lodash';
import { withFormik } from 'formik';

const EspForm = curry((setup, Form) => {
  if (typeof setup !== 'object') {
    throw new Error('Invalid setup provided');
  }

  if (typeof Form !== 'function') {
    throw new Error('Invalid Form, must be a component');
  }

  return withFormik(setup)(Form);
});

export default EspForm;
