import React, { PureComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Menu, Message, Modal } from 'semantic-ui-react';
import { noop } from 'lodash';
// Controllers
import ChatBaristaController from '../controllers/ChatBaristaController';
// Organisms
import ChatRoom from '../organisms/ChatRoom';
// Utils
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
import BodyClassName from '../../../globals/BodyClassName';
import WindowDimensionContext from './WindowDimensionProvider/WindowDimensionContext';
import { intl } from 'esp-util-intl';
// Globals
// import ChannelTypes           from '../../globals/ChannelTypes';

// unlistener for browserhistory;
let unlisten;

// This component listens for messages from parent window to close the chat
const CloseChatOnExternalMessage = ({ exitBaristaSupportChannel }) => {
  useEffect(() => {
    const endLiveChat = (event) => {
      if (event.data === 'endLiveChat') {
        exitBaristaSupportChannel();
      }
    };
    window.addEventListener('message', endLiveChat);
    return () => {
      window.removeEventListener('message', endLiveChat);
    };
  }, [exitBaristaSupportChannel]);

  return null;
};

class ChatBarista extends PureComponent {
  static propTypes = {
    enterBaristaSupportChannel: PropTypes.func,
    errorMessage: PropTypes.string,
    exitBaristaSupportChannel: PropTypes.func,
    intentEid: PropTypes.string,
    isBaristaOpened: PropTypes.bool,
    isChannelIdGivenByUrl: PropTypes.bool,
    isLargeBaristaWindowEnabled: PropTypes.bool,
    isPortal: PropTypes.bool,
    qrPhrase: PropTypes.string,
    selectedSupportChannel: PropTypes.string,
    shouldStartWithQrParams: PropTypes.bool,
    shouldSubmitQrPhrase: PropTypes.bool,
    suspendBarista: PropTypes.bool,
    trigger: PropTypes.node.isRequired,
  };

  static defaultProps = {
    enterBaristaSupportChannel: noop,
    errorMessage: null,
    exitBaristaSupportChannel: noop,
    intentEid: '',
    isBaristaOpened: false,
    isChannelIdGivenByUrl: false,
    isLargeBaristaWindowEnabled: false,
    isPortal: false,
    qrPhrase: '',
    selectedSupportChannel: null,
    shouldStartWithQrParams: false,
    shouldSubmitQrPhrase: false,
    suspendBarista: false,
  };

  static contextType = WindowDimensionContext;

  checkBrowserBack = (location, action) => {
    // When barista is opened and the user hits the browser’s back button we want to simply close the modal
    // and take them back to whatever location they were (thus preventing the step back in history)
    const { exitBaristaSupportChannel, isBaristaOpened } = this.props;

    if (isBaristaOpened) {
      if (action === 'POP') {
        // Force to unlisten history events
        unlisten && unlisten();

        // Can't really abort a back button
        // so just close and navigate to the url where we were
        exitBaristaSupportChannel(this.locationWhenOpened);
      } else if (action === 'PUSH') {
        // if there was navigation while barista was opened
        // (i.e. opening an internal link in chat)
        // then we save the location navigated in the background
        // as the current location, so we preserve it when we close by hitting back
        this.locationWhenOpened = location.pathname;
      }
    }
  };

  handleModalOpen = () => {
    const { enterBaristaSupportChannel } = this.props;
    enterBaristaSupportChannel();

    this.locationWhenOpened = browserHistory.location.pathname;
    unlisten = browserHistory.listen(this.checkBrowserBack);
  };

  handleModalClose = () => {
    const { exitBaristaSupportChannel, isPortal } = this.props;

    // Force to unlisten history events
    unlisten && unlisten();

    let urlToNavigateTo = '';
    const baristaAppUrl = uiPathGenerator.genPath('app.barista');

    const currentUrl = browserHistory.location.pathname;
    // redirect to toDo only when closed in the barista standalone url
    if (currentUrl && currentUrl.indexOf(baristaAppUrl) === 0) {
      urlToNavigateTo = uiPathGenerator.genPath('app.toDo');
    } else if (isPortal) {
      urlToNavigateTo = uiPathGenerator.genPath('portalBarista');
    }

    exitBaristaSupportChannel(urlToNavigateTo);
  };

  renderBarista = () => {
    const {
      errorMessage,
      exitBaristaSupportChannel,
      intentEid,
      isChannelIdGivenByUrl,
      // isOpenFromUrl, not used for now, but it's part of redux state. I think we might want to use it strategically in the future - Erick from the past.
      qrPhrase,
      selectedSupportChannel,
      shouldStartWithQrParams,
      shouldSubmitQrPhrase,
      suspendBarista,
    } = this.props;

    let intent; // undefined otherwise
    if (intentEid) {
      intent = {
        intent: intentEid,
      };
    }
    return (
      <>
        <CloseChatOnExternalMessage
          exitBaristaSupportChannel={exitBaristaSupportChannel}
        />
        {errorMessage ? <Header as='h4' content={'Barista error'} /> : null}
        {errorMessage ? (
          <Modal.Content>
            <Message content={errorMessage} negative />
          </Modal.Content>
        ) : !suspendBarista ? (
          <ChatRoom
            channelId={isChannelIdGivenByUrl ? selectedSupportChannel : void 0}
            defaultMessage={
              shouldStartWithQrParams && !shouldSubmitQrPhrase ? qrPhrase : ''
            }
            inModal
            noAvatarLinks
            onHandleModalClose={this.handleModalClose}
            shouldStartWithQrParams={shouldStartWithQrParams}
            supportChannelIntent={intent}
            supportChannelStart={isChannelIdGivenByUrl ? void 0 : true}
          />
        ) : null}
      </>
    );
  };
  render() {
    const { isBaristaOpened, isLargeBaristaWindowEnabled, trigger, isPortal } =
      this.props;

    const modalCloseButton = (
      <Menu.Item
        aria-label='Close'
        className='close'
        role='button'
        tabIndex='0'
      >
        {intl.formatMessage({
          id: 'label.exit',
        })}
      </Menu.Item>
    );

    const baristaTrigger = React.cloneElement(trigger);

    const shouldDisplayLargeBaristaWindow =
      isLargeBaristaWindowEnabled && !isPortal;

    return (
      <Modal
        aria-modal='true'
        className='scrolling'
        closeIcon={modalCloseButton}
        closeOnDimmerClick={false}
        onClose={this.handleModalClose}
        onOpen={this.handleModalOpen}
        open={isBaristaOpened}
        role='dialog'
        size={shouldDisplayLargeBaristaWindow ? 'large' : 'small'}
        trigger={baristaTrigger}
      >
        <BodyClassName className='barista-chat'>
          {this.renderBarista()}
        </BodyClassName>
      </Modal>
    );
  }
}

const ChatBaristaTest = ChatBarista;

// eslint-disable-next-line no-class-assign -- DEV-1526
ChatBarista = ChatBaristaController(ChatBarista);

export { ChatBaristaTest };
export default ChatBarista;
