import { createSelector } from 'reselect';
import Immutable, { fromJS } from 'immutable';

import customLabels from '../globals/customLabels';

const getTenantEntityValue = (state) =>
  state.getIn(['tenant', 'entity', 'value']) || fromJS({});

const getTenantLocalizationValue = (state) =>
  state.getIn(['tenant', 'localization', 'list']);

/**
 * Returns an immutable Map which contain all localization / meta value
 */
const getMetaTexts = (
  entityValue = Immutable.Map(),
  localizationList = Immutable.List()
) => {
  const tenantName = entityValue.get('name');
  const tenantPhoneNumber = entityValue.get('phone_number');

  const tenant = {
    name: tenantName,
    phone_number: tenantPhoneNumber,
  };

  customLabels.forEach((item) => {
    const indexList = localizationList.findIndex(
      (lt) => lt.get('key') === item.get('key')
    );

    tenant[item.get('name')] =
      indexList > -1
        ? localizationList.getIn([indexList, 'text'])
        : item.get('name');
  });

  return fromJS({
    tenant,
  });
};

const selector = createSelector(
  [getTenantEntityValue, getTenantLocalizationValue],
  getMetaTexts
);

export default selector;
