import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Isvg from 'react-inlinesvg';
import _ from 'lodash';

// Molecules
import UserImageContainer from './UserImageContainer';
import EspListItem from './EspListItem';

// Atoms
import TextHighlight from '../atoms/TextHighlight';

// Controller
import ChannelResultController from '../controllers/ChannelResultController';
// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';
// Globals
import { ImageDefaults } from 'esp-assets';

const MAX_MESSAGE_CONTENT_LEN = 45;

class ScopedChatChannelResult extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    baristaID: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    result: ImmutablePropTypes.map.isRequired,
    routeToResult: PropTypes.string.isRequired,
    routeToResultIncludesQ: PropTypes.bool.isRequired,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    active: false,
    baristaID: null,
  };

  render() {
    const {
      active,
      baristaID,
      onClick,
      result,
      routeToResult,
      routeToResultIncludesQ,
      userInput,
    } = this.props;

    const channelName = result.get('display_value');

    const nameEl = <TextHighlight term={userInput} text={channelName} />;

    // no. of messages in the channel that matched
    const totalMessages = result.get('total_messages') || 0;

    let messageToShowContent;
    let messageToShowAuthorID;

    if (totalMessages > 0) {
      const messages = result.get('messages');
      if (messages) {
        const mostRecentMessage = messages.first();

        messageToShowContent = mostRecentMessage.get('display_value');
        messageToShowAuthorID = mostRecentMessage.get('user_id');
      }
    } else {
      // scoped channel should have at least one message,
      // 'last_message' should be present when there are no matching messages
      const lastMessage = result.get('last_message');
      if (lastMessage) {
        messageToShowContent = lastMessage.get('text');
        messageToShowAuthorID = lastMessage.get('user_id');
      }

      // temporaly, if no last message, use the first user in the participant list (see T8445, this might get fixed in the future by the BE)
      if (!messageToShowAuthorID) {
        messageToShowAuthorID = result.get('participants').first().get('id');
      }
    }

    const isBarista = messageToShowAuthorID === baristaID;

    const imageEl = isBarista ? (
      <div className='avatar'>
        <Isvg
          cacheGetRequests
          src={ImageDefaults.BARISTA_AVATAR}
          uniquifyIDs={false}
        >
          {/* NOTE: This is a fallback for browsers not allowing XHR/inline SVG */}
          <img alt='avatar' src='/images/barista/large_barista_01.svg' />
        </Isvg>
      </div>
    ) : messageToShowAuthorID ? (
      <UserImageContainer avatar userId={messageToShowAuthorID} />
    ) : null;
    messageToShowContent = _.truncate(
      messageToShowContent,
      MAX_MESSAGE_CONTENT_LEN
    );

    const contentEl = (
      <TextHighlight term={userInput} text={messageToShowContent} />
    );

    let descriptionEl = null;

    if (totalMessages > 0) {
      descriptionEl = intl.formatMessage({
        id: 'message.no_of_messages_that_matched',
      });
    }

    const channelID = result.get('pk');

    const urlToResult = uiPathGenerator.genPath(routeToResult, {
      channelID,
    });
    let toResult = urlToResult;

    if (routeToResultIncludesQ) {
      toResult = {
        pathname: urlToResult,
        search: `?q=${userInput}`,
      };
    }

    return (
      <EspListItem
        active={active}
        as={Link}
        content={contentEl}
        description={descriptionEl}
        image={imageEl}
        isSearchResult
        name={nameEl}
        onClick={onClick}
        to={toResult}
      />
    );
  }
}

const ScopedChatChannelResultTest = ScopedChatChannelResult;
export { ScopedChatChannelResultTest };

export default ChannelResultController(ScopedChatChannelResult);
