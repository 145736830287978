import { fromJS, Map } from 'immutable';
import { CASE_TYPE } from '../../v2/components/functional/CaseCard/caseConstants';
import actionTypes from '../actions/actionTypes';
import cardState from '../globals/CardState';

const INITIAL_STATE = fromJS({});

const addCards = (cards, newCards, reset) => {
  if (reset) {
    cards = Map(newCards.map((card) => [card.get('id'), card]));
  } else {
    newCards.forEach((newCard) => {
      const cardId = newCard.get('id');
      if (cards.has(cardId)) {
        // merge only if something already exists
        // the result of v.merge will be v itself if the merge with newCard didn't bring anything new
        // ferras.gif
        cards = cards.update(cardId, (v) => v.merge(newCard));
      } else {
        cards = cards.set(cardId, newCard);
      }
    });
  }

  return cards;
};

const addSingleCard = (cards, newCard) => {
  const cardId = newCard.get('id');
  return cards.set(cardId, newCard);
};

const removeSingleCard = (cards, cardId) => cards.remove(cardId);

const cardsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_CARDS_SUCCESS: {
      const newState = state;
      return addCards(newState, fromJS(action.cards), action.reset);
    }

    case actionTypes.UPDATE_AUTOMATION_TASK_TYPE_CARD_FROM_CHANNEL: {
      const doesContainsSomeMessage =
        action.channel && action.channel.message_count;
      if (doesContainsSomeMessage) {
        const updatedCard = state.filter(
          (card) =>
            card.get('channel_id') === action.channel.id &&
            (card.getIn(['card_meta', 'task_type']) === CASE_TYPE.AUTOMATION ||
              card.getIn(['card_meta', 'task_type']) ===
                CASE_TYPE.APPROVAL_MANAGER)
        );

        if (updatedCard && !updatedCard.isEmpty()) {
          const approvalCard = updatedCard.first();
          const unreadMessages =
            action.channel.message_count - action.channel.read_message_count;
          if (approvalCard) {
            return state.set(
              approvalCard.get('id'),
              approvalCard.set('unread_message_count', unreadMessages)
            );
          }
        }
      }

      return state;
    }
    case actionTypes.CANCEL_CARD_SUCCESS:
    case actionTypes.FOLLOW_CARD_SUCCESS:
    case actionTypes.LOAD_CARD_BY_ID_SUCCESS:
    case actionTypes.SINGLE_CARD_UPDATE:
    case actionTypes.SNOOZE_CARD_SUCCESS:
      return action.card.state !== cardState.DELETED
        ? addSingleCard(state, fromJS(action.card))
        : removeSingleCard(state, action.card.id);

    case actionTypes.DISMISS_CARD_SUCCESS: {
      return removeSingleCard(state, action.cardId);
    }

    case actionTypes.NUDGE_CARD_SUCCESS: {
      return addSingleCard(state, fromJS(action.card));
    }

    case actionTypes.DELETE_CARD_SUCCESS: {
      return removeSingleCard(state, action.cardId);
    }
    case actionTypes.UNSUBSCRIBE_CARD_SUCCESS: {
      return removeSingleCard(state, action.cardId);
    }

    case actionTypes.CANCEL_REQUEST_SUCCESS: {
      return addSingleCard(state, fromJS(action.card));
    }

    default:
      return state;
  }
};

export default cardsReducer;
