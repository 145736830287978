import Immutable from 'immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import {
  getFormSyncErrors,
  getFormValues,
  isPristine,
  isValid,
} from 'redux-form/immutable';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const FORM = 'ServiceTeamSettings';
const mapStateProps = (state) => {
  const serviceTeamList = state.hasIn(['caseMgmt', 'serviceTeam', 'list'])
    ? state.getIn(['caseMgmt', 'serviceTeam', 'list'])
    : Immutable.List();

  const serviceTeam = serviceTeamList.first() || Immutable.Map();

  const isLoading = state.hasIn(['caseMgmt', 'serviceTeam', 'isLoading'])
    ? state.getIn(['caseMgmt', 'serviceTeam', 'isLoading'])
    : false;

  return {
    formErrors: getFormSyncErrors(FORM)(state),
    formValues: getFormValues(FORM)(state),
    isLoading,
    isPristine: isPristine(FORM)(state),
    isValid: isValid(FORM)(state),
    serviceTeam,
  };
};

const mapDispatchProps = (dispatch) => ({
  loadServiceTeam: (teamID) => dispatch(caseMgmtThunks.loadServiceTeam(teamID)),
  saveServiceTeam: (teamID, formValues) => {
    dispatch(caseMgmtThunks.saveServiceTeam(teamID, formValues)).then(() =>
      dispatch(
        toastNotificationsActions.success({
          message: `Service Team ${formValues.get('name')} updated`,
          title: 'Success',
        })
      )
    );
  },
  validate: (formatMessage) => (values) => {
    const name = values.get('name') || '';
    if (name.trim() === '') {
      return {
        name: formatMessage({
          id: 'label.required_field',
        }),
      };
    }
    const description = values.get('description') || '';
    if (description.trim() === '') {
      return {
        description: formatMessage({
          id: 'label.required_field',
        }),
      };
    }
    return {};
  },
});

export default buildCompositeController(mapStateProps, mapDispatchProps);
