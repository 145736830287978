import { connect } from 'react-redux';

import cartThunks from '../../actions/cartThunks';
import workflowThunks from '../../actions/workflowThunks';

const mapStateToProps = (state) => ({
  isLoading: state.getIn(['cart', 'isLoading']),
  isLoadingCart: state.getIn(['cart', 'isLoadingWFCart']),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  cartSubmitOrder(onCartSubmit) {
    dispatch(cartThunks.orderCart()).then(() => onCartSubmit());
  },

  clearCart(cb) {
    dispatch(cartThunks.clearAllCartItems()).then(() => cb());
  },

  fillFromSelectMyGear() {
    return dispatch(
      cartThunks.fillFromSelectMyGear(ownProps.managerSelect)
    ).then(() => dispatch(cartThunks.loadItemsCart()));
  },

  resetValue(value = 'noskip') {
    // Force to reset the temp_data value to the invalid value
    // usefull if there is another component with his own setAlternateSubmit
    return dispatch(
      workflowThunks.updateTempDataBackEndScratch({
        goBack: value,
      })
    );
  },
});

const CartBlockCOntroller = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(component);

export default CartBlockCOntroller;
