//
// index.js
//
// This file is autogenerated from 'gulpSVG' gulp file.
// Template for this is in '/templates/index.handlebars'
//
// Please don't modify it by hand.
//
// If you need to add a new SVG, add the svg file in the images folder
// then run the gulp script: gulp --gulpfile ./gulpSvg.js
//
//
import React, { PureComponent } from 'react';
import AddedEmail from './AddedEmail';
import BenefitsEnrollment from './BenefitsEnrollment';
import Birthday from './Birthday';
import CalendarEvent from './CalendarEvent';
import CatalogLink from './CatalogLink';
import EtaNormal from './EtaNormal';
import EtaUrgent from './EtaUrgent';
import ItIncident from './ItIncident';
import LeftArrow from './LeftArrow';
import Mentor from './Mentor';
import NewEmployee from './NewEmployee';
import NewFeature from './NewFeature';
import OnboardingComplete from './OnboardingComplete';
import Onboarding from './Onboarding';
import Overdue from './Overdue';
import PasswordReset from './PasswordReset';
import PurchaseOrderComplete from './PurchaseOrderComplete';
import PurchaseOrder from './PurchaseOrder';
import Ratings from './Ratings';
import RightArrow from './RightArrow';
import ShoppingCart from './ShoppingCart';
import Survey from './Survey';
import TodoEta from './TodoEta';
import Todo from './Todo';
import Tutorial from './Tutorial';
import UrlLink from './UrlLink';

class Cards extends PureComponent {
  render() {
    const styleError = { color: '#a00' };
    return <p style={styleError}>{'ERROR: Please import a specific image.'}</p>;
  }
}

Cards.AddedEmail = AddedEmail;
Cards.BenefitsEnrollment = BenefitsEnrollment;
Cards.Birthday = Birthday;
Cards.CalendarEvent = CalendarEvent;
Cards.CatalogLink = CatalogLink;
Cards.EtaNormal = EtaNormal;
Cards.EtaUrgent = EtaUrgent;
Cards.ItIncident = ItIncident;
Cards.LeftArrow = LeftArrow;
Cards.Mentor = Mentor;
Cards.NewEmployee = NewEmployee;
Cards.NewFeature = NewFeature;
Cards.OnboardingComplete = OnboardingComplete;
Cards.Onboarding = Onboarding;
Cards.Overdue = Overdue;
Cards.PasswordReset = PasswordReset;
Cards.PurchaseOrderComplete = PurchaseOrderComplete;
Cards.PurchaseOrder = PurchaseOrder;
Cards.Ratings = Ratings;
Cards.RightArrow = RightArrow;
Cards.ShoppingCart = ShoppingCart;
Cards.Survey = Survey;
Cards.TodoEta = TodoEta;
Cards.Todo = Todo;
Cards.Tutorial = Tutorial;
Cards.UrlLink = UrlLink;

export default Cards;
