import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  connecting: false,
  errored: null,
  open: false,
  reconnection_attempts: 0,
  reconnection_interval: 0,
  times_opened: 0,
});

const wsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.WS_CONNECT:
      return state;

    case actionTypes.WS_CONNECTING:
      return state.set('open', false).set('connecting', true);

    case actionTypes.WS_CONNECTED:
      return state
        .set('connecting', false)
        .set('open', true)
        .set('times_opened', state.get('times_opened') + 1)
        .set('errored', null)
        .set('reconnection_attempts', 0)
        .set('reconnection_interval', 0);

    case actionTypes.WS_DISCONNECTED:
      return state
        .set('open', false)
        .set('reconnection_attempts', action.details.attempts)
        .set('reconnection_interval', action.details.interval);

    case actionTypes.WS_ERRORED:
      return state
        .set('open', false)
        .set('errored', action.evt)
        .set('connecting', false);

    case actionTypes.WS_REFUSED:
      return state
        .set('open', false)
        .set('connecting', false)
        .set('reconnection_attempts', action.details.attempts)
        .set('reconnection_interval', action.details.interval);

    case actionTypes.WS_SEND:
      return state;

    default:
      return state;
  }
};

export default wsReducer;
