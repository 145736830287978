import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';

// Atoms
import ProductDescription from '../atoms/ProductDescription';

// Molecules
import EspProductHeader from './EspProductHeader';

// Organisms
import BlockModal from '../organisms/BlockModal';
import DetailedProductDescription from '../organisms/DetailedProductDescription';
import ProductImages from '../organisms/ProductImages';

class SelectMyGearProductStep extends PureComponent {
  static propTypes = {
    step: ImmutablePropTypes.map.isRequired,
  };

  state = {
    activeImageIndex: 0,
  };

  setActiveImageIndex = (activeImageIndex) => {
    this.setState({
      activeImageIndex,
    });
  };

  render() {
    const { step } = this.props;

    const { activeImageIndex } = this.state;

    const product = step.get('entity');

    return (
      <div className='product-step'>
        <EspProductHeader title={product.get('name')} />

        <ProductImages
          activeImage={activeImageIndex}
          images={product.get('images')}
          isLightbox={false}
          productID={-1} // TODO this guy is required but not used if isLightbox is false, we need to tune that
          setActiveImage={this.setActiveImageIndex}
        />

        <BlockModal
          trigger={
            <ProductDescription
              description={product.get('short_description')}
            />
          }
        >
          <div className='esp-blocks'>
            <DetailedProductDescription
              className='block'
              description={product.get('description')}
              images={product.get('images')}
              name={product.get('name')}
            />
          </div>
        </BlockModal>
      </div>
    );
  }
}

export default SelectMyGearProductStep;
