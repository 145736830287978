import React, { Component } from 'react';
import FormInputCheckbox from '../../molecules/FormInputCheckbox';
import { Field } from 'redux-form/immutable';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Globals
import TypeCheckbox, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCheckbox';

class Checkbox01 extends Component {
  static propTypes = {
    ...TypeCheckbox,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
  };

  handleChange = () => {
    const { handleChange } = this.props;
    // change event is being handle by reduxform, this is a handler for customized stuff
    if (handleChange) {
      handleChange();
    }
  };

  render() {
    const {
      errors,
      errorsApi,
      hidden,
      id,
      inputType,
      labelText,
      placeholder,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          autoComplete='off'
          autoCorrect='off'
          component={FormInputCheckbox}
          formError={finalError}
          hidden={hidden}
          id={id}
          label={labelText}
          name={id.toString()}
          onChange={this.handleChange}
          placeholder={placeholder}
          type={inputType}
        />
      </div>
    );
  }
}

export default Checkbox01;
