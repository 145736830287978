import buildCompositeController from '../../utils/buildCompositeController';
import adminBundleThunks from '../../actions/adminBundleThunks';
import getBundleImages from '../../selectors/getBundleImages';
import getBundleItems from '../../selectors/getBundleItems';

const mapStateToProps = (state, { bundle }) => ({
  bundleItems: getBundleItems(state, {
    bundle,
  }),
  images: getBundleImages(state, {
    bundle,
  }),
});

const mapDispatchToProps = (dispatch) => ({
  loadBundleImages({ bundle }) {
    dispatch(adminBundleThunks.loadBundleImages(bundle.get('items')));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
