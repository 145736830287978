import async from 'async';
import _ from 'lodash';
import Immutable, { fromJS } from 'immutable';
import { getFormValues } from 'redux-form/immutable';
import { imagesResizeTools } from 'esp-globals';

// Util
import browserHistory from '../utils/browserHistory';
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import uiPathGenerator from '../utils/uiPathGenerator';
// Global
import BundleType from '../globals/BundleType';
import { SortFieldNames } from '../globals/SortProductStateOptions';
// Selector
import getBundleJobRolePermissions from '../selectors/getBundleJobRolePermissions';

// Action
import bundleActions from './bundleActions';
import jobRoleThunks from './jobRoleThunks';
import productImagesActions from './productImagesActions';
import userRulesThunks from './userRulesThunks';
import catalogActions from './catalogActions';
import userRulesActions from './userRulesActions';

// Globals
import SelectMyGearStepTypes from '../globals/SelectMyGearStepTypes';

const adminBundleThunks = {};
const loadProductImage = (productID) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCatalog.productsImages');
    const espFilters = encodeURI(`esp_catalog__EQ=${productID}`);

    APIcall.get({
      error(error) {
        reject(error);
      },
      query: {
        esp_filters: espFilters,
        limit: 1,
      },
      success(response) {
        const images = response.body.results;
        if (!_.isEmpty(images)) {
          const firstImage = _.first(images);
          resolve(firstImage);
        } else {
          resolve(null);
        }
      },
      token: true,
      url,
    });
  });

const loadProductFamilyImage = (productFamilyID) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCatalog.productsImages');
    const espFilters = encodeURI(
      `esp_catalog.product_family__EQ=${productFamilyID}`
    );

    APIcall.get({
      error(error) {
        reject(error);
      },
      query: {
        esp_filters: espFilters,
        limit: 1,
      },
      success(response) {
        const images = response.body.results;
        if (!_.isEmpty(images)) {
          const firstImage = _.first(images);
          resolve(firstImage);
        } else {
          resolve(null);
        }
      },
      token: true,
      url,
    });
  });

const loadProductBundleImage = (bundleID) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCatalog.bundles.instance', {
      bundleID,
    });

    APIcall.get({
      error(error) {
        reject(error);
      },
      success(response) {
        const bundle = response.body;
        const { items } = bundle;

        if (_.isEmpty(items)) {
          resolve(null);
          return;
        }

        // can be product or product family
        const firstItem = _.first(items);

        if (firstItem.type === SelectMyGearStepTypes.PRODUCT) {
          const productID = firstItem.product;
          resolve(loadProductImage(productID));
        } else {
          // it has to be a product family
          const productFamilyID = firstItem.product_family;
          resolve(loadProductFamilyImage(productFamilyID));
        }
      },
      token: true,
      url,
    });
  });

const createBundle = (data = Immutable.Map(), cb = _.noop) => {
  const endpoint = endpointGenerator.genPath('espCatalog.bundles');
  APIcall.post({
    data: data.toJS(),
    error(err) {
      cb(err);
    },
    success({ body }) {
      cb(null, body);
    },
    token: true,
    url: endpoint,
  });
};

const uploadBundleImage = (bundleID, images, cb = _.noop) => {
  // create image upload callbacks
  const imagesCallbacks = images.map((tmpImg) => {
    const image = new window.Image();
    image.src = tmpImg.get('image_480');
    const img = {
      id: bundleID,
      picture_960: imagesResizeTools.createImg(image, 480),
    };
    const finalData = imagesResizeTools.convertFormDataBundle(img);
    return adminBundleThunks._addProductImage(finalData);
  });

  // upload all images via parallel
  async.parallel(imagesCallbacks.toJS(), () => {
    cb();
  });
};

const createNewBundleItem = (
  bundleID,
  newItemID,
  position,
  bundleType,
  product_family = null,
  cb
) => {
  const endpoint = endpointGenerator.genPath('espCatalog.bundleItem');

  const product = bundleType === 'PRODUCT' ? newItemID : null;
  const bundle = bundleType === 'BUNDLE' ? newItemID : null;

  APIcall.post({
    data: {
      bundle,
      display: true,
      parent: bundleID,
      product: product_family ? null : product,
      product_family,
      type: product_family ? 'PRODUCT_FAMILY' : bundleType,
      weight: position,
    },
    error(err) {
      cb(err);
    },
    success() {
      cb();
    },
    token: true,
    url: endpoint,
  });
};

const removeBundleItem = (bundleID, bundleItemID, cb = _.noop) => {
  const endpoint = endpointGenerator.genPath('espCatalog.bundleItem.instance', {
    bitemID: bundleItemID,
  });

  APIcall.delete({
    error(err) {
      cb(err);
    },
    success() {
      cb();
    },
    token: true,
    url: endpoint,
  });
};

const createBundleItems = (bundleID, bundleItems, getState, cb = _.noop) => {
  async.parallel(
    bundleItems
      .map((bundle, key) => (next) => {
        const bundleType = bundle.get('bundle') ? 'BUNDLE' : 'PRODUCT';
        const itemId = bundle.get('bundle') || bundle.get('product');
        const products = getState().getIn(['entities', 'products']);
        const product_family =
          bundleType === 'PRODUCT'
            ? products.getIn([itemId, 'product_family'])
            : null;
        createNewBundleItem(
          bundleID,
          itemId,
          key,
          bundleType,
          product_family,
          next
        );
      })
      .toArray(),
    () => cb()
  );
};

const setTemporaryPermissions = (
  bundleID,
  newValues,
  jobRoles,
  userRules,
  job_role_permissions,
  cb = _.noop
) => {
  if (newValues.size > 0) {
    const regExp = (name) => new RegExp(`user.job_role.name.*"${name}"`);
    const findUserRule = (name, userRules) =>
      userRules.find((userRule) => userRule.get('rule').match(regExp(name)));

    const callbacks = newValues
      .filterNot((v) => job_role_permissions.find((jrp) => jrp.get('id') === v))
      .map((newJobRoleID) => (next) => {
        const jobRole = jobRoles.find((jrp) => jrp.get('id') === newJobRoleID);
        const rule = findUserRule(jobRole.get('name'), userRules);

        APIcall.post({
          data: {
            entity_app_label: 'catalog',
            entity_filter: {
              id: bundleID,
            },
            entity_model: 'espbundle',
            obj_permissions: ['catalog.view_espbundle'],
            rule: rule.get('url'),
          },
          success() {
            next();
          },
          token: true,
          url: endpointGenerator.genPath('espRbac.entityPermissions'),
        });
      });

    async.waterfall(callbacks.toArray(), () => cb());
  } else {
    cb();
  }
};

const createPermissionRule = (
  bundleID,
  newValues,
  jobRoles,
  userRules,
  job_role_permissions,
  dispatch,
  cb = _.noop
) => {
  if (newValues.size > 0) {
    const regExp = (name) => new RegExp(`user.job_role.name.*"${name}"`);
    const findUserRule = (name, userRules) =>
      userRules.find((userRule) => userRule.get('rule').match(regExp(name)));

    const callbacks = newValues
      .filterNot((v) => job_role_permissions.find((jrp) => jrp.get('id') === v))
      .map((newJobRoleID) => (next) => {
        const jobRole = jobRoles.find((jrp) => jrp.get('id') === newJobRoleID);
        const rule = findUserRule(jobRole.get('name'), userRules);
        if (!rule) {
          APIcall.post({
            data: {
              rule: `user.job_role.name == "${jobRole.get('name')}"`,
            },
            success({ body }) {
              dispatch(userRulesActions.setUserRules([body]));
              next();
            },
            token: true,
            url: endpointGenerator.genPath('espRbac.userRules'),
          });
        } else {
          next();
        }
      });

    async.waterfall(callbacks.toArray(), () => cb());
  } else {
    cb();
  }
};

const getProductFamily = (productFamilyID) => (next) => {
  const endpoint = endpointGenerator.genPath('espCatalog.products');
  APIcall.get({
    error(err) {
      next(err);
    },
    query: {
      esp_filters: `product_family__EQ=${productFamilyID}`,
    },
    success({ body: { results = [] } }) {
      next(null, results);
    },
    token: true,
    url: endpoint,
  });
};

const getProductItems = (id) => (next) => {
  const endpoint = endpointGenerator.genPath('espCatalog.products');
  APIcall.get({
    error(err) {
      next(err);
    },
    query: {
      esp_filters: `id__EQ=${id}`,
    },
    success({ body: { results = [] } }) {
      next(null, results);
    },
    token: true,
    url: endpoint,
  });
};

adminBundleThunks.loadBundleItemsProductFamilies = (
  bundle = Immutable.Map()
) => (dispatch) =>
  new Promise((resolve, reject) => {
    const items = bundle.get('items') || Immutable.Map();
    const productFamilyCB = (i) => i.get('product_family');
    const families = items.filter(productFamilyCB).map(productFamilyCB);
    const callbacks = families.map((id) => getProductFamily(id)).toJS();

    async.parallel(callbacks, (error, results) => {
      if (error) {
        return reject(error);
      }

      results.forEach((p) => dispatch(catalogActions.loadProductsSuccess(p)));
      return resolve(bundle);
    });
  });

adminBundleThunks.loadBundleItemsProducts = (bundle) => (dispatch) =>
  new Promise((resolve, reject) => {
    const items = bundle.get('items') || Immutable.Map();
    const productCB = (i) => i.get('product');
    const families = items.filter(productCB).map(productCB);
    const callbacks = families.map((id) => getProductItems(id)).toJS();

    async.parallel(callbacks, (error, results) => {
      if (error) {
        return reject(error);
      }

      results.forEach((p) => dispatch(catalogActions.loadProductsSuccess(p)));
      return resolve(bundle);
    });
  });
/**
 * loads bundle and child bundles (if it's a role bundle)
 *
 * @param bundleID {int}
 * @return {Promise}
 */
adminBundleThunks.loadBundleDetailAdmin = (_bundleID) => (dispatch) =>
  new Promise((resolve, reject) => {
    // load bundle
    const loadBundleInstance = (bundleID, isRoot = false) => (next) => {
      dispatch(bundleActions.loadBundleAdminDetailStart());
      const endpoint = endpointGenerator.genPath(
        'espCatalog.bundles.instance',
        {
          bundleID: bundleID,
        }
      );
      APIcall.get({
        error(err) {
          dispatch(bundleActions.loadBundleAdminDetailError());
          next(err, null);
        },
        success({ body }) {
          if (isRoot) {
            dispatch(bundleActions.loadBundleAdminDetailSuccess(body));
            next(null, fromJS(body));
          } else {
            dispatch(bundleActions.loadBundlesSuccess([body]));
            next();
          }
        },
        token: true,
        url: endpoint,
      });
    };

    const loadProductInstance = (productID) => (next) => {
      const endpoint = endpointGenerator.genPath(
        'espCatalog.products.instance',
        {
          productID,
        }
      );
      APIcall.get({
        error(err) {
          next(err, null);
        },
        success({ body }) {
          dispatch(catalogActions.loadProductsSuccess([body]));
          next();
        },
        token: true,
        url: endpoint,
      });
    };

    const loadFamilyInstance = (familyID) => (next) => {
      const endpoint = endpointGenerator.genPath('espCatalog.products');
      APIcall.get({
        error(err) {
          next(err, null);
        },
        query: {
          esp_filters: `product_family__EQ=${familyID}`,
        },
        success({ body }) {
          dispatch(catalogActions.loadProductsSuccess(body.results));
          next();
        },
        token: true,
        url: endpoint,
      });
    };

    const loadBundleBundles = (bundle, next) => {
      if (bundle === null) {
        next('bundle not found');
      }

      const items = bundle.get('items') || Immutable.List();
      const callbacks = items
        .filter((item) => item.get('bundle'))
        .map((item) => loadBundleInstance(item.get('bundle')))
        .toJS();

      async.waterfall(callbacks, () => next(null, bundle));
    };

    const loadProducts = (bundle, next) => {
      if (bundle === null) {
        next('bundle not found');
      }

      const items = bundle.get('items') || Immutable.List();
      const callbacks = items
        .filter((item) => item.get('product'))
        .map((item) => loadProductInstance(item.get('product')))
        .toJS();

      async.waterfall(callbacks, () => next(null, bundle));
    };

    const loadFamilies = (bundle, next) => {
      if (bundle === null) {
        next('bundle not found');
      }

      const items = bundle.get('items') || Immutable.List();
      const callbacks = items
        .filter((item) => item.get('product_family'))
        .map((item) => loadFamilyInstance(item.get('product_family')))
        .toJS();

      async.waterfall(callbacks, () => next(null, bundle));
    };

    // done callback
    const done = (err, bundle) => {
      if (err || bundle === null) {
        reject();
      } else {
        resolve(bundle);
      }
    };

    async.waterfall(
      [
        loadBundleInstance(_bundleID, true),
        loadBundleBundles,
        loadFamilies,
        loadProducts,
      ],
      done
    );
  });

adminBundleThunks.getBundleItemImage = (bundleItem) => (dispatch) =>
  new Promise((resolve, reject) => {
    const bundleItemID = bundleItem.get('id');
    const type = bundleItem.get('type');

    const handleProductImage = (productImage) => {
      if (productImage) {
        dispatch(
          productImagesActions.addProductImage(bundleItemID, productImage)
        );
        resolve(productImage);
      } else {
        // console.warn(`There is no available image to display for bundle item ${bundleItemID}. ${bundleItem.get('url')}`);
        resolve(null);
      }
    };

    let promise;

    if (type === SelectMyGearStepTypes.PRODUCT) {
      const productID = bundleItem.get('product');
      promise = loadProductImage(productID).then(handleProductImage);
    } else if (type === SelectMyGearStepTypes.PRODUCT_FAMILY) {
      const productFamilyID = bundleItem.get('product_family');
      if (!productFamilyID) {
        window.Rollbar.error(
          'This product is a family but product family ID is not set',
          bundleItem.toJS()
        );
      }
      promise = loadProductFamilyImage(productFamilyID).then(
        handleProductImage
      );
    } else {
      // bundleItem it's a product bundle

      const bundleID = bundleItem.get('bundle');
      promise = loadProductBundleImage(bundleID).then(handleProductImage);
    }

    promise.catch((error) => reject(error));
  });

adminBundleThunks.loadBundleImages = (items = Immutable.List()) => (dispatch) =>
  new Promise((resolve, reject) => {
    async.map(
      items,
      (bundleItem, next) => {
        dispatch(adminBundleThunks.getBundleItemImage(bundleItem))
          .then((productImage) => next(null, productImage))
          .catch(next);
      },
      (error, productImages) => {
        if (error) {
          reject(error);
        } else {
          productImages = productImages.filter(_.identity);
          resolve(productImages);
        }
      }
    );
  });

adminBundleThunks.updateBundle = (bundleID, DETAIL_FORM) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const data = getFormValues(DETAIL_FORM)(getState());

    dispatch(bundleActions.loadBundleAdminDetailStart());
    const endpoint = endpointGenerator.genPath('espCatalog.bundles.instance', {
      bundleID: bundleID,
    });

    APIcall.patch({
      data: data.toJS(),
      error() {
        dispatch(bundleActions.loadBundleAdminDetailError());
        reject();
      },
      success({ body }) {
        dispatch(bundleActions.loadBundleAdminDetailSuccess(body));
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

adminBundleThunks.changeBundleStatus = (bundleID, status) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(bundleActions.loadBundleAdminDetailStart());
    const endpoint = endpointGenerator.genPath('espCatalog.bundles.instance', {
      bundleID: bundleID,
    });
    APIcall.patch({
      data: {
        status,
      },
      error() {
        dispatch(bundleActions.loadBundleAdminDetailError());
        reject();
      },
      success({ body }) {
        dispatch(bundleActions.loadBundleAdminDetailSuccess(body));
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

adminBundleThunks.replaceBundleItem = (
  bundleID,
  newProductID,
  removeBundleItemID,
  position,
  bundleType
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();

    let product_family = null;
    if (bundleType === 'PRODUCT_FAMILY') {
      const product = state.getIn(['entities', 'products', newProductID]);
      product_family = product.get('product_family');
    }

    async.waterfall(
      [
        (next) => removeBundleItem(bundleID, removeBundleItemID, next),
        (next) =>
          createNewBundleItem(
            bundleID,
            newProductID,
            position,
            bundleType,
            product_family,
            next
          ),
      ],
      (err) => {
        if (!err) {
          resolve();
        } else {
          reject();
        }
      }
    );
  });

adminBundleThunks.addBundleItem = (
  bundleID,
  newProductID,
  position,
  bundleType
) => (dispatch, getState) =>
  new Promise((resolve) => {
    const state = getState();

    let product_family = null;

    if (bundleType === 'PRODUCT') {
      const product = state.getIn(['entities', 'products', newProductID]);
      product_family = product.get('product_family');
    }

    createNewBundleItem(
      bundleID,
      newProductID,
      position,
      bundleType,
      product_family,
      () => {
        dispatch(bundleActions.setAddNewProductState(false));
        dispatch(bundleActions.setAddNewBundleState(false));
        resolve();
      }
    );
  });

adminBundleThunks.deleteBundleItem = (bundleID, bundleItemID) => () =>
  new Promise((resolve) => removeBundleItem(bundleID, bundleItemID, resolve));

adminBundleThunks.reOrderBundleItems = (bundleID, newOrder) => () =>
  new Promise((resolve, reject) => {
    async.waterfall(
      newOrder
        .map((item) => (next) => {
          const endpoint = endpointGenerator.genPath(
            'espCatalog.bundleItem.instance',
            {
              bitemID: item.id,
            }
          );
          APIcall.patch({
            data: {
              weight: item.weight,
            },
            error(err) {
              next(err);
            },
            success() {
              next();
            },
            token: true,
            url: endpoint,
          });
        })
        .toArray(),
      (err) => {
        if (!err) {
          resolve();
        } else {
          reject();
        }
      }
    );
  });

adminBundleThunks.loadBundleJobRoles = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(bundleActions.loadBundleJobRolePermissionsStart());
    async.waterfall(
      [
        (next) => dispatch(jobRoleThunks.getJobRoles(() => next(null))),

        (next) =>
          dispatch(
            userRulesThunks.getBundleJobRolePermissions(() => next(null))
          ),

        (next) => dispatch(userRulesThunks.loadUserRules(() => next(null))),

        (next) =>
          dispatch(userRulesThunks.loadPermissionEntities(() => next(null))),
      ],
      (error) => {
        if (error) {
          dispatch(bundleActions.loadBundleJobRolePermissionsSuccess());
          reject();
        } else {
          dispatch(bundleActions.loadBundleJobRolePermissionsError());
          resolve();
        }
      }
    );
  });

adminBundleThunks.searchBundles = (
  page = 1,
  searchTerm = '',
  query = Immutable.Map(),
  limit = 24,
  bundleType = null
) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (bundleType === BundleType.ROLE_BUNDLE) {
      dispatch(bundleActions.loadStartSearchRoleBundles());
    } else if (bundleType === BundleType.BUNDLE) {
      dispatch(bundleActions.loadStartSearchBundles());
    }

    const endpoint = endpointGenerator.genPath('espCatalog.bundles');

    // role bundle or product bundle
    let esp_filters =
      bundleType === BundleType.ROLE_BUNDLE
        ? 'role_bundle__EQ=1'
        : 'role_bundle__EQ=0';
    if (searchTerm) {
      esp_filters += `&name__IC=${searchTerm}`;
    }

    // set query filters
    if (query.size > 0) {
      // set status filter
      if (query.get('status')) {
        esp_filters += `&status__EQ=${query.get('status')}`;
      }
    }

    const order_by =
      query && query.get('order')
        ? query.get('order')
        : SortFieldNames.NEGATIVE_SYS_DATE_CREATED;

    const offset = (page - 1) * limit;

    APIcall.get({
      error(err) {
        dispatch(bundleActions.loadBundlesFail(err));
        reject();
      },
      query: {
        esp_filters,
        limit,
        offset,
        order_by,
      },
      success({ body }) {
        if (bundleType === BundleType.ROLE_BUNDLE) {
          dispatch(bundleActions.setSearchResultsRoleBundles(body));
        } else if (bundleType === BundleType.BUNDLE) {
          dispatch(bundleActions.setSearchResultsBundles(body));
        }
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

adminBundleThunks.searchProductBundles = (searchTerm) => (dispatch) => {
  // dispatch(bundleActions.loadBundlesStart());

  // role bundle or product bundle
  let esp_filters = 'role_bundle__EQ=0';

  if (searchTerm) {
    esp_filters += `&name__IC=${searchTerm}`;
  }

  const endpoint = endpointGenerator.genPath('espCatalog.bundles');

  APIcall.get({
    error() {
      // dispatch(bundleActions.loadBundlesFail(err, tag));
    },
    query: {
      esp_filters,
    },
    success({ body }) {
      dispatch(bundleActions.loadBundlesSuccess(body.results));
    },
    token: true,
    url: endpoint,
  });
};

adminBundleThunks.createNewBundle = (data = Immutable.Map()) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(bundleActions.createNewBundleStart());

    async.waterfall(
      [
        (next) => createBundle(data, next), // create bundle

        // upload images
        (bundle, next) => {
          const state = getState();
          const images = state.getIn(['bundles', 'temporaryImages']);
          if (images.size > 0) {
            uploadBundleImage(bundle.id, images, () => next(null, bundle));
          } else {
            next(null, bundle);
          }
        },

        // create bundle items
        (bundle, next) => {
          const bundleItems = getState().getIn(['bundles', 'tmpBundleItems']);
          if (bundleItems.size > 0) {
            createBundleItems(bundle.id, bundleItems, getState, () =>
              next(null, bundle)
            );
          } else {
            next(null, bundle);
          }
        },

        (bundle, next) => {
          const newValues = getState().getIn([
            'bundles',
            'temporaryPermissions',
          ]);
          const jobRoles = getState().getIn(['entities', 'jobRoles']);
          const userRules = getState().getIn(['entities', 'userRules']);
          const job_role_permissions = getBundleJobRolePermissions(
            getState(),
            bundle.id
          );
          if (newValues.size > 0) {
            createPermissionRule(
              bundle.id,
              newValues,
              jobRoles,
              userRules,
              job_role_permissions,
              dispatch,
              () => next(null, bundle)
            );
          } else {
            next(null, bundle);
          }
        },

        // create job role permissions
        (bundle, next) => {
          const newValues = getState().getIn([
            'bundles',
            'temporaryPermissions',
          ]);
          const jobRoles = getState().getIn(['entities', 'jobRoles']);
          const userRules = getState().getIn(['entities', 'userRules']);
          const job_role_permissions = getBundleJobRolePermissions(
            getState(),
            bundle.id
          );
          if (newValues.size > 0) {
            setTemporaryPermissions(
              bundle.id,
              newValues,
              jobRoles,
              userRules,
              job_role_permissions,
              () => next(null, bundle)
            );
          } else {
            next(null, bundle);
          }
        },

        // clear tmp images from state
        (bundle, next) => {
          dispatch(bundleActions.clearTmpImage());
          next(null, bundle);
        },

        // clear tmp bundle items
        (bundle, next) => {
          dispatch(bundleActions.clearTemporaryBundleItem());
          next(null, bundle);
        },
      ],
      (err, bundle) => {
        if (!err) {
          dispatch(bundleActions.createNewBundleSuccess(bundle));
          const redirectPath = bundle.role_bundle
            ? 'admin.roleBundle.detail'
            : 'admin.bundle.detail';
          const url = uiPathGenerator.genPath(redirectPath, {
            bundleID: bundle.id,
          });
          browserHistory.push(url);
          resolve();
        } else {
          dispatch(bundleActions.createNewBundleError());
          reject();
        }
      }
    );
  });

adminBundleThunks.addBundleImage = (data) => () =>
  new Promise((resolve) => {
    const endpoint = endpointGenerator.genPath('espCatalog.bundleImage');

    APIcall.post({
      data,
      success() {
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

adminBundleThunks.deleteBundleImage = (imageID) => () =>
  new Promise((resolve) => {
    const endpoint = endpointGenerator.genPath(
      'espCatalog.bundleImage.instance',
      {
        imageID: imageID,
      }
    );

    APIcall.delete({
      success() {
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

export default adminBundleThunks;
