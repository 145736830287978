import actionTypes from './actionTypes';

const selectMyGearActions = {};

selectMyGearActions.getSelectMyGearStepsStart = () => ({
  type: actionTypes.GET_SELECT_MY_GEAR_STEPS_START,
});

selectMyGearActions.getSelectMyGearStepsSuccess = (steps) => ({
  steps,
  type: actionTypes.GET_SELECT_MY_GEAR_STEPS_SUCCESS,
});

selectMyGearActions.getSelectMyGearStepsFail = () => ({
  type: actionTypes.GET_SELECT_MY_GEAR_STEPS_FAIL,
});

selectMyGearActions.preAddProduct = (stepIndex, productID, relativeWeight) => ({
  productID,
  relativeWeight,
  stepIndex,
  type: actionTypes.SELECT_MY_GEAR_PRE_ADD_PRODUCT,
});

selectMyGearActions.addProduct = (productID, relativeWeight) => ({
  productID,
  relativeWeight,
  type: actionTypes.SELECT_MY_GEAR_ADD_PRODUCT,
});

selectMyGearActions.removeProduct = (productID) => ({
  productID,
  type: actionTypes.SELECT_MY_GEAR_REMOVE_PRODUCT,
});

selectMyGearActions.answerFulfillmentQuestions = (answersByProduct) => ({
  answersByProduct,
  type: actionTypes.SELECT_MY_GEAR_ANSWER_FULFILLMENT_QUESTIONS,
});

selectMyGearActions.resumeProgress = (selectMyGearScratch) => ({
  selectMyGearScratch,
  type: actionTypes.SELECT_MY_GEAR_RESUME_PROGRESS,
});

selectMyGearActions.resetProgress = () => ({
  type: actionTypes.SELECT_MY_GEAR_RESET_PROGRESS,
});

selectMyGearActions.moveNextStep = () => ({
  type: actionTypes.SELECT_MY_GEAR_MOVE_NEXT_STEP,
});

selectMyGearActions.movePrevStep = () => ({
  type: actionTypes.SELECT_MY_GEAR_MOVE_PREV_STEP,
});

selectMyGearActions.moveFirstStep = () => ({
  type: actionTypes.SELECT_MY_GEAR_MOVE_FIRST_STEP,
});

export default selectMyGearActions;
