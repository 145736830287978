import { change } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
import workflowThunks from '../../actions/workflowThunks';
import userEntitiesThunks from '../../actions/userEntitiesThunks';

import getWorkflowRequestedFor from '../../selectors/getWorkflowRequestedFor';

const mapStateToProps = (state) => {
  const departmentSelected = state.hasIn([
    'workflowState',
    'frontendScratch',
    'departmentSelected',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'departmentSelected'])
    : null;

  const requestedFor = getWorkflowRequestedFor(state);
  const requestedForID = requestedFor ? requestedFor.get('id') : null;
  const requestedForDepartment = requestedFor
    ? requestedFor.get('departmentId')
    : null;

  return {
    departmentSelected,
    requestedForDepartment,
    requestedForID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  change(id, value, name, updateScratch, updatedUserID) {
    dispatch(change('Form01', id, value));

    if (updateScratch) {
      dispatch(
        workflowThunks.updateFrontEndScratch({
          departmentSelected: {
            id: value,
            name: name,
          },
        })
      ).then(() => {
        if (updatedUserID) {
          // Update the user entity now if this user exist
          // Be sure to pass value = null when resetting the department
          dispatch(
            userEntitiesThunks.changeDepartmentUser(
              updatedUserID,
              value || null
            )
          );
        }
      });
    }
  },
  loadDepartment(requestedForID) {
    dispatch(userEntitiesThunks.getDepartmentID(requestedForID));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
