import _ from 'lodash';
import buildCompositeController from '../../utils/buildCompositeController';
import getSelectMyGearStepsWithSelections from '../../selectors/getSelectMyGearStepsWithSelections';
import selectMyGearActions from '../../actions/selectMyGearActions';
import selectMyGearThunks from '../../actions/selectMyGearThunks';

const mapStateToProps = (state) => {
  const stepsWithSelections = getSelectMyGearStepsWithSelections(state);

  const selectMyGearState = state.get('selectMyGear');
  const isLoadingSteps = selectMyGearState.getIn(['steps', 'isLoading']);
  const currentStepIndex = selectMyGearState.get('currentStep');

  let currentStep = null;

  // TODO this can be a selector
  if (stepsWithSelections && _.isNumber(currentStepIndex)) {
    currentStep = stepsWithSelections.get(currentStepIndex);
  }

  return {
    currentStep,
    isLoadingSteps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSteps(roleBundleID) {
    dispatch(selectMyGearThunks.getSelectMyGearSteps(roleBundleID));
  },

  onAddProduct(productID, relativeWeight) {
    dispatch(selectMyGearActions.addProduct(productID, relativeWeight));
  },

  onRemoveProduct(productID) {
    dispatch(selectMyGearActions.removeProduct(productID));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
