import React, { Component } from 'react';
// Globals
import TypeSkills, { TypeDefaults } from '../../../globals/blocks/TypeSkills';

class Skills01 extends Component {
  static propTypes = TypeSkills;
  static defaultProps = TypeDefaults;

  render() {
    const { type, template, options } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <h2>{'Skills page'}</h2>

        {options.map((option) => (
          <div key={option.id}>
            <span>{option.count}</span>
            <span>{option.label}</span>
          </div>
        ))}
      </div>
    );
  }
}

export default Skills01;
