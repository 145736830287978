import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import inboxesThunks from '../../actions/inboxesThunks';
import getServiceDepartmentList from '../../selectors/getServiceDepartmentList';
import inboxesActions from '../../actions/inboxesActions';

const mapStateToProps = (state) => {
  const baristaInboxes = state.getIn(['inboxes', 'baristaInboxes']);
  const isLoadingBaristaInboxes = state.getIn([
    'inboxes',
    'isLoadingBaristaInboxes',
  ]);
  const inboxes = state.get('inboxes');
  const isLoadingBaristaTestConnection = inboxes.get(
    'isLoadingBaristaTestConnection'
  );
  const departmentList = getServiceDepartmentList(state);
  const baristaTestConnectionSuccess = inboxes.get(
    'baristaTestConnectionSuccess'
  );
  const primaryCredential = state.getIn([
    'integration',
    'emailIntegrationPrimaryCredential',
  ]);
  const baristaTestConnectionSent = inboxes.get('baristaTestConnectionSent');
  const testedBox = inboxes.get('testedCaseBox');
  const isFormInboxError = inboxes.get('isEmailFormErrors');
  const showEmailFormErrors = inboxes.get('showEmailFormErrors');
  const isEditSettingsEmailForm = inboxes.get('isEditSettingsEmailForm');
  const temporalEmailInboxForm = inboxes.get('temporalEmailInboxForm');
  const isNewFormInbox = inboxes.get('isNewFormInbox');
  const isOnlyTestingConnection = inboxes.get('isOnlyTestingConnection');

  return {
    baristaInboxes,
    baristaTestConnectionSent,
    baristaTestConnectionSuccess,
    departmentList,
    isEditSettingsEmailForm,
    isFormInboxError,
    isLoadingBaristaInboxes,
    isLoadingBaristaTestConnection,
    isNewFormInbox,
    isOnlyTestingConnection,
    primaryCredential,
    showEmailFormErrors,
    temporalEmailInboxForm,
    testedBox,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelEmailInboxErrors() {
    dispatch(inboxesActions.cancelEmailInboxErrors());
  },

  closeConfirmEmailInboxErrors() {
    dispatch(inboxesActions.closeConfirmEmailInboxErrors());
  },

  confirmEmailInboxErrors() {
    dispatch(inboxesActions.confirmEmailInboxErrors());
  },

  deleteBaristaInbox(caseInboxId, credentialId, isPrimary, isGmailAccount) {
    return dispatch(
      inboxesThunks.deleteInbox(
        caseInboxId,
        credentialId,
        isPrimary,
        isGmailAccount
      )
    );
  },

  getBaristaInboxes() {
    dispatch(inboxesThunks.fetchBoxes());
  },

  getPrimaryEmailCredentials() {
    dispatch(inboxesThunks.fetchPrimaryCredential());
  },

  loadDepartmentList() {
    return dispatch(caseMgmtThunks.loadEspServiceDepartmentList());
  },

  terminateEmailTestConnection() {
    dispatch(inboxesActions.emailTestConnectionClose());
  },

  testEmailConnection(emailData, isOnlyTestingConnection) {
    dispatch(
      inboxesThunks.emailTestConnection({
        emailData,
        isOnlyTestingConnection,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
