import React, { useCallback, useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import useTemplateInterval from '../hooks/useTemplateInterval';
import useOffboardingSchedule from '../hooks/useOffboardingSchedule';
import { isEmpty } from 'lodash';
import useTemplateListSettings from '../../../../molecules/Offboarding/utils/hooks/useTemplateListSettings';
import offboardingScheduleContext from './Context/OffboardingScheduleContext';

import TemplateIntervalOptions from './TemplateIntervalOptions';

const propTypes = {
  action: pt.string,
  intervalID: pt.string,
  scheduleID: pt.string,
  selectedTemplates: pt.arrayOf(pt.shape({})),
  templateID: pt.number,
  title: pt.string,
  trigger: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
};

const OffboardingTemplateIntervalModal = ({
  action,
  intervalID,
  scheduleID,
  selectedTemplates,
  templateID,
  title,
  trigger,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);
  const {
    loadSchedule,
    loadOneScheduleActivity,
    templateSelected,
    schedule,
  } = useOffboardingSchedule();
  const { templates } = useTemplateListSettings();
  const {
    createActivityInterval,
    updateActivityInterval,
  } = useTemplateInterval();
  const { setIsScheduleUpdated } = useContext(offboardingScheduleContext);

  useEffect(() => {
    if (isEmpty(schedule) && scheduleID !== 'new') {
      loadSchedule({ scheduleID });
    }
    if (isEmpty(templateSelected) && !isEmpty(schedule)) {
      loadOneScheduleActivity(scheduleID, schedule, 'offboarding');
    }
  }, [
    loadOneScheduleActivity,
    loadSchedule,
    schedule,
    scheduleID,
    templateSelected,
  ]);
  // const handleChangeSelect = useCallback(() => {}, []);
  const onSubmitForm = useCallback(
    (values) => {
      const valueKeys = Object.keys(values);
      const formattedValues = valueKeys
        .map((key) => {
          if (
            values[key] === true &&
            typeof values[`${key.split('_selected')[0]}`] === 'string'
          ) {
            return {
              [`${key}`]: values[`${key.split('_selected')[0]}`],
            };
          } else if (values[key] === true) {
            return {
              [`${key}`]: values[key],
            };
          } else {
            return false;
          }
        })
        .filter((val) => val);
      if (intervalID === 'new') {
        createActivityInterval({
          action,
          allTemplates: templates,
          intervalID,
          scheduleID,
          selectedTemplates,
          templateID,
          templateSelected,
          valueToPass: {
            key: Object.keys(formattedValues[0])[0].split('_selected')[0],
            value: Object.values(formattedValues[0])[0],
          },
          values,
        }).then(() => {
          setIsScheduleUpdated(true);
          handleOpen();
        });
      } else {
        updateActivityInterval({
          action,
          allTemplates: templates,
          intervalID,
          scheduleID,
          selectedTemplates,
          templateID,
          templateSelected,
          valueToPass: {
            key: Object.keys(formattedValues[0])[0].split('_selected')[0],
            value: Object.values(formattedValues[0])[0],
          },
          values,
        }).then(() => {
          setIsScheduleUpdated(true);
          handleOpen();
        });
      }
    },
    [
      action,
      createActivityInterval,
      handleOpen,
      intervalID,
      scheduleID,
      selectedTemplates,
      setIsScheduleUpdated,
      templateID,
      templateSelected,
      templates,
      updateActivityInterval,
    ]
  );

  const initialValues = {
    day_of_departure_selected: false,
    days_after_departure_day: '',
    days_after_departure_day_selected: false,
    days_before_departure_day: '',
    days_before_departure_day_selected: false,
  };
  const msg =
    intervalID === 'new'
      ? 'Select the timing for your new offboarding activity.'
      : 'Update the timing for your offboarding activity.';
  const buttonAdd = intervalID === 'new' ? 'Add' : 'Update';
  const header = title ? `Activity: ${title}` : 'Activity Details';

  return (
    <Modal
      className={'scrolling'}
      onClose={handleOpen}
      onOpen={handleOpen}
      open={modalOpen}
      size='small'
      trigger={trigger}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmitForm}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <Modal.Header content={header} />
              <Modal.Content className='withFooter' style={{ padding: '1em' }}>
                <Message content={msg} info />
                <TemplateIntervalOptions
                  formikHandleChange={formikProps.handleChange}
                  setFieldValue={formikProps.setFieldValue}
                  values={formikProps.values}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button basic content='Cancel' onClick={handleOpen} />

                <Button content={buttonAdd} primary type='submit' />
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

OffboardingTemplateIntervalModal.propTypes = propTypes;

export default OffboardingTemplateIntervalModal;
