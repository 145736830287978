import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';

const rule = (props) => {
  const { expanded, isMe, theme } = props;

  // const meColor = theme.brandTenant.primaryColor;
  const meColorText = theme.brandTenant.textColorPrimary;
  // const themeColor = theme.colors.gray;
  const themeColorText = theme.colors.text;

  const arrowBase = {
    backgroundColor: isMe ? meColorText : themeColorText,
    content: '" "',
    display: 'block',
    height: '2px',
    marginTop: '-1px',
    position: 'absolute',
    top: '50%',
    transitionDuration: `${theme.globals.transitionDuration * 2}ms`,
    transitionProperty: 'transform',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: '38%',
    zIndex: 1,
  };

  return {
    ':after': {
      extend: arrowBase,
      right: '44%',
      transform: expanded ? 'rotate(-45deg)' : 'rotate(45deg)',
    },
    ':before': {
      extend: arrowBase,
      left: '44%',
      transform: expanded ? 'rotate(45deg)' : 'rotate(-45deg)',
    },
    ':focus': {
      outlineStyle: 'none',
    },
    ':hover': {
      outlineStyle: 'none',
      transform: 'scale(1.1)',
      willChange: 'transform',
    },
    backgroundColor: 'transparent',
    border: 'none',
    bottom: 0,
    cursor: 'pointer',
    fontSize: '.75em',
    height: '2.5em',
    padding: theme.bubblePadding,
    position: 'absolute',
    right: 0,
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'transform',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: '3.5em',
  };
};

class ChatMessageExpandBtn extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    // expanded  : PropTypes.bool,
    // isMe      : PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // expanded : false,
    // isMe     : false,
  };

  render() {
    const { className, onClick } = this.props;

    return (
      <button
        className={className}
        data-component='ChatMessageExpandBtn'
        onClick={onClick}
        type='button'
      />
    );
  }
}

export default createComponent(rule, ChatMessageExpandBtn, [
  'expanded',
  'isMe',
  'onClick',
]);
