import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import classNames from 'classnames';
import TypeBackground, { TypeDefaults } from './TypeBackground';

const rule = () => ({
  '> .bg': {
    height: '100%',
    left: '50%',
    maxWidth: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    width: 'auto',
    zIndex: '-1',
  },
  '> .scrim': {
    '&.black': {
      backgroundColor: '#000',
    },
    '&.white': {
      backgroundColor: '#fff',
    },
    height: '100%',
    position: 'absolute',
    transition: 'opacity 500ms ease-in-out',
    width: '100%',
  },
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  right: 0,
  top: 0,

  zIndex: '-1',
});

class Background01 extends PureComponent {
  static propTypes = TypeBackground;
  static defaultProps = TypeDefaults;

  render() {
    const {
      scrimColor,
      scrimOpacity,
      scrim,
      image,
      type,
      template,
      className,
    } = this.props;

    return (
      <div
        className={classNames(className, template)}
        data-blocktype={type}
        data-template={template}
      >
        {scrim && (
          <div
            className={`scrim ${scrimColor}`}
            style={{
              opacity: scrimOpacity,
            }}
          />
        )}
        <img alt='' className='bg' src={image} />
      </div>
    );
  }
}

export default createComponent(rule, Background01, [
  'scrimColor',
  'scrimOpacity',
  'scrim',
  'image',
  'template',
  'type',
  'id',
  'trigger',
]);
