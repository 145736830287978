import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { DayPicker } from 'react-dates';
import moment from 'moment';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';
import { hasIn, noop } from 'lodash';

// atom
import ErrorLabel from '../atoms/ErrorLabel';

// Molecule
import DatePickerModal from './DatePickerModal';
// Utils
import datePickerModifiers, {
  CLASS_DATE,
} from '../../utils/datePickerModifiers';
import dateFormat from '../../utils/dateFormat';
import LocaleSingleton from '../../utils/LocaleSingleton';

import DateTools from '../../utils/DateTools';

/** Auto generate the timer options */
const optionTime = [];
for (let hour = 12, i = 0; i < 24; i++) {
  const halfday = i > 11 ? 'PM' : 'AM';
  hour = hour === 13 ? 1 : hour;
  const timeGenerated = hour + halfday;
  optionTime.push({
    key: timeGenerated,
    text: timeGenerated,
    value: timeGenerated,
  });
  hour++;
}

const buildMomentHour = (value) => {
  // Build the hour by converting PM / AM format to a 24h format readable by moment
  let timePicked = value.includes('PM')
    ? `${Number(value.split('PM')[0]) + 12}:00`
    : `${Number(value.split('AM')[0])}:00`;

  if (value === '12AM') {
    // Exception for midnight
    timePicked = '00:00';
  } else {
    // Exception for midday
    timePicked = timePicked === '24:00' ? '12:00' : timePicked;
  }

  return timePicked;
};

class DatePicker extends PureComponent {
  static propTypes = {
    /** Any kind of node/string that will be render after the DatePicker */
    afterElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** Any kind of node/string that will be render before the DatePicker */
    beforeElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** Rend the Daypicker in a blockModal or a Modal */
    blockModal: PropTypes.bool,
    /** Force to pass the new value back to the parent via setStartingDate on every change */
    changebackValue: PropTypes.bool,
    /** Disable input */
    disabled: PropTypes.bool,
    /** Rend day outside the current month in view */
    enableOutsideDays: PropTypes.bool,
    /** Allow to select a date before today or initialDate */
    enablePastDate: PropTypes.bool,
    /** Error form */
    formError: PropTypes.string,
    /** Pass dropdown for hour select */
    hours: PropTypes.bool,
    /** Icon for the hour dropdown trigger */
    iconHour: PropTypes.string,
    /** Icon position in the input {left or right} */
    iconPosition: PropTypes.string,
    /** initialDate to use */
    initialDate: PropTypes.string,
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }),

    /** isValid is being set on ProfileEditStartDate */
    isValid: PropTypes.bool,

    /** Label for the DayPicker */
    label: PropTypes.string,

    meta: PropTypes.shape({
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      submitFailed: PropTypes.bool,
    }),

    mountNodeSelector: PropTypes.string,

    /** Number of months to show in the calendar view */
    numberOfMonths: PropTypes.number,

    /** Control close modal */
    onClose: PropTypes.func,

    /** Control Modal */
    open: PropTypes.bool,

    /** Placeholder for the hour dropdown */
    placeholderHour: PropTypes.string,

    /** Make the field read only */
    readOnly: PropTypes.bool,

    /** Callback to call in order to set a start date in frontend scratch */
    setStartingDate: PropTypes.func,

    /** Title of the Modal - Label is use if no title */
    title: PropTypes.string,

    /** Rend DayPicker in a Portal */
    usePortal: PropTypes.bool,
  };

  static defaultProps = {
    afterElement: null,
    beforeElement: null,
    blockModal: false,
    changebackValue: false,
    disabled: false,
    enableOutsideDays: false,
    enablePastDate: true,
    formError: '',
    hours: false,
    iconHour: null,
    iconPosition: null,
    initialDate: null,
    input: {},
    isValid: true,
    label: null,
    meta: {},
    mountNodeSelector: '',
    numberOfMonths: 1,
    onClose: noop,
    open: false,
    placeholderHour: null,
    readOnly: false,
    setStartingDate: null,
    title: '',
    usePortal: false,
  };

  constructor(props) {
    super(props);

    // Get initialDate
    let { initialDate } = props;
    const initInputDate =
      props.input && hasIn(props.input, 'value') ? props.input.value : null;

    const initDte = moment();
    const latestDte = moment(initInputDate);

    if (latestDte.diff(initDte) < 0) {
      initialDate = latestDte.format('YYYY-MM-DD');
    } else {
      initialDate = initDte.format('YYYY-MM-DD');
    }

    const selectedMonth = initInputDate
      ? Number(moment(initInputDate).format('MM'))
      : Number(moment().format('MM'));

    // Build a custom modifier react-dates form the initialDate
    this.modifier = datePickerModifiers(
      initialDate,
      initInputDate,
      props.enablePastDate
    );

    const presentData = new DateTools();
    this.state = {
      currentTime: initInputDate
        ? moment(initInputDate).format('hA')
        : presentData &&
          presentData.getOptionsMap() &&
          presentData.getOptionsMap()[0].value,
      // Force a value to force controlled dropdown component
      initialDateState: initialDate,
      open: false,
      originalDate: initialDate,
      originalMonth: selectedMonth,
      selectedDate: null,
      selectedMonth: selectedMonth,
      submitted: false,
      visualDay: this.modifier ? this.modifier.getModifier() : null,
    };
  }

  componentDidMount() {
    const {
      // enablePastDate,
      blockModal,
      initialDate,
      input,
      readOnly,
      setStartingDate,
    } = this.props;
    const { initialDateState } = this.state;

    if (
      !initialDate &&
      blockModal &&
      setStartingDate &&
      input.value &&
      !readOnly
    ) {
      // Set to the frontend scratch the very first selected starting date
      const noCheck = true; // No check form error at this step
      setStartingDate(input.value, noop, noCheck);
    }

    if (!initialDate && !input.value && initialDateState) {
      // Set the default value preselect with redux-form

      input.onChange(moment(initialDateState, 'YYYY-MM-DD HH:mm').format());
    } else if (initialDate && !input.value) {
      // Set the default value preselect with redux-form

      input.onChange(moment(initialDate, 'YYYY-MM-DD HH:mm').format());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { enablePastDate, input, initialDate } = nextProps;
    const { currentTime, selectedDate } = this.state;

    if (
      selectedDate &&
      input.value &&
      moment(input.value).format('hA') !== currentTime
    ) {
      this.setState({
        currentTime: moment(input.value).format('hA'),
      });
    } else if ((input.value || initialDate) && !selectedDate) {
      // Change the modifier
      const newDate = input.value || initialDate; // Priority to the input value
      const initDte = moment();
      const latestDte = moment(newDate);
      const selectedMonth = Number(moment(newDate).format('MM'));

      let newInitialDate;
      if (latestDte.diff(initDte) < 0) {
        newInitialDate = latestDte.format('YYYY-MM-DD');
      } else {
        newInitialDate = initDte.format('YYYY-MM-DD');
      }

      this.modifier = datePickerModifiers(
        newInitialDate,
        newDate,
        enablePastDate
      );

      // Set the default value preselect with redux-form after set the state
      this.setState(
        {
          originalDate: newDate,
          originalMonth: selectedMonth,
          selectedDate: newDate,
          selectedMonth: selectedMonth,
          visualDay: this.modifier.getModifier(),
        },
        () => {
          input.onChange(moment(newDate, 'YYYY-MM-DD HH:mm').format());
        }
      );
    }
  }

  componentDidUpdate() {
    if (this.inputElement) {
      // Reset focus to clear the placeholder in the input element (Safari issue)
      this.inputElement.blur();
    }
  }

  handleOpenModal = (e) => {
    const { readOnly, blockModal } = this.props;
    if (!readOnly) {
      this.setInputRef(e.target); // Save ref of the Date input dom element

      if (blockModal) {
        this.setState(
          {
            submitted: false,
          },
          () => {
            this.dateModal.open(); // open block modal
          }
        );
      } else {
        this.setState({
          open: true,
          submitted: false,
        }); // open modal
      }
    }

    // Fix iOS to avoid focus event on the input by forcing a blur event
    e.target.blur();
  };

  onHandleOpenModal = (e) => {
    this.handleOpenModal(e); // Make EsLint Happy
  };

  handleCloseModal = (hasBeenSubmit) => {
    const { enablePastDate, input, initialDate, blockModal, onClose } =
      this.props;
    const { submitted, originalDate, originalMonth } = this.state;

    if (!hasBeenSubmit && !submitted) {
      // Reset the main state and modifier since the new selected date has not been sent
      const initDte = moment();
      const latestDte = moment(initialDate);
      let originDate;

      // Take the minimal date possible
      if (latestDte.diff(initDte) < 0) {
        originDate = latestDte.format('YYYY-MM-DD');
      } else {
        originDate = initDte.format('YYYY-MM-DD');
      }

      // Reset the custom modifier react-dates form the initialDate
      this.modifier = datePickerModifiers(
        originDate,

        originalDate,
        enablePastDate
      );

      this.setState(
        {
          selectedDate: originalDate,
          selectedMonth: originalMonth,
          visualDay: this.modifier.getModifier(),
        },
        () => {
          input.onChange(originalDate);
        }
      );
    }

    if (blockModal && hasBeenSubmit) {
      this.dateModal.close(); // close block modal
    } else {
      this.setState({
        open: false,
      }); // close modal
    }

    onClose();
  };

  onHandleCloseModal = () => {
    this.handleCloseModal(); // Make EsLint Happy
  };

  handleNextMonth = () => {
    const { enablePastDate } = this.props;
    const { selectedDate, selectedMonth } = this.state;

    const selectedDateFormat = moment(selectedDate, 'YYYY-MM-DD');

    const nextMonth = selectedMonth + 1 > 12 ? 1 : selectedMonth + 1;
    const visualDay = this.modifier.setModifier(
      nextMonth,
      true,
      selectedDateFormat,
      enablePastDate
    );

    this.setState({
      selectedMonth: nextMonth,
      visualDay: visualDay,
    });
  };

  handlePrevMonth = () => {
    const { enablePastDate } = this.props;
    const { selectedDate, selectedMonth } = this.state;

    const selectedDateFormat = moment(selectedDate, 'YYYY-MM-DD');

    const prevMonth = selectedMonth - 1 < 1 ? 12 : selectedMonth - 1;
    const visualDay = this.modifier.setModifier(
      prevMonth,
      false,
      selectedDateFormat,
      enablePastDate
    );

    this.setState({
      selectedMonth: prevMonth,
      visualDay: visualDay,
    });
  };

  setInputRef = (ref) => {
    this.inputElement = ref;
  };

  setDateModal = (modal) => {
    this.dateModal = modal;
  };

  handleSelectDay = (value) => {
    const { visualDay, selectedDate, currentTime } = this.state;
    const { enablePastDate, blockModal } = this.props;

    const onlyMonthSelected = Number(value.format('MM'));
    const monthSelected = value.format('YYYY-MM');
    const daySelected = value.format('YYYY-MM-DD');
    const hourSelected = value.format('hA');

    const differentHour = hourSelected !== currentTime;
    const dayChecked = !enablePastDate
      ? visualDay[monthSelected][daySelected]
      : null;

    if (
      enablePastDate ||
      (!enablePastDate &&
        !dayChecked.has(CLASS_DATE.BLOCKED) &&
        !dayChecked.has(CLASS_DATE.BLOCKED_OUT_OF_RANGE))
    ) {
      const { currentTime } = this.state;
      let finalValue = value;

      // Get the Date
      const datePicker = value.format('YYYY MM DD');

      if (blockModal) {
        finalValue = value.format();
      } else if (differentHour) {
        // Build the hour by converting PM / AM format to a 24h format readable by moment
        const timePicked = buildMomentHour(currentTime);

        // Build the final moment date with the hour
        finalValue = moment(`${datePicker} ${timePicked}`, 'YYYY-MM-DD HH:mm');
      }

      const sameMonth =
        monthSelected === moment(selectedDate, 'YYYY-MM').format('YYYY-MM');
      const yearSelected = moment(finalValue, 'YYYY-MM').format('YYYY');

      const visualDay = this.modifier.setModifier(
        onlyMonthSelected,
        false,
        finalValue,
        enablePastDate,
        sameMonth,
        yearSelected
      );

      this.setState({
        selectedDate: finalValue,
        selectedMonth: onlyMonthSelected,
        visualDay: visualDay,
      });
    }
  };

  setOriginalValueOnSubmit = (finalValue, onChange) => {
    this.setState(
      {
        originalDate: finalValue, // Pass the new selected date has the orignal
        originalMonth: moment(finalValue).format('MM'), // Pass the new selected month has the orignal,
        submitted: true,
      },
      () => {
        onChange(finalValue);
      }
    );

    const noResetNewSelectedValue = true; // Set true to avoid to reset the new selected date
    this.handleCloseModal(noResetNewSelectedValue);
  };

  handleSelectedDate = (e) => {
    e.preventDefault(); // Avoid Redux-form to submit the form - specifically in WF

    const {
      changebackValue,
      setStartingDate,
      input: { onChange },
    } = this.props;
    const { selectedDate } = this.state;

    const finalValue = selectedDate;
    if (finalValue) {
      if (changebackValue) {
        setStartingDate(finalValue, (resultOk) => {
          if (resultOk) {
            this.setOriginalValueOnSubmit(finalValue, onChange);
          }
        });
      } else {
        this.setOriginalValueOnSubmit(finalValue, onChange);
      }
    }
  };

  handleChangeTime = (e, data) => {
    const { selectedDate } = this.state;
    const { input } = this.props;
    const currentValue = selectedDate || input.value;

    if (currentValue && currentValue._d) {
      const datePicker = currentValue.format('YYYY MM DD');
      // Build the hour by converting PM / AM format to a 24h format readable by moment
      const timePicked = buildMomentHour(data.value);

      // Build the final moment date with the hour
      const value = moment(`${datePicker} ${timePicked}`, 'YYYY-MM-DD HH:mm');

      input.onChange(value);
    } else {
      const timePicked = buildMomentHour(data.value);
      const datePicked = moment(currentValue).format('YYYY MM DD');
      const value = moment(`${datePicked} ${timePicked}`, 'YYYY-MM-DD HH:mm');

      input.onChange(value);
    }
    this.setState({
      currentTime: data.value,
    });
  };

  handleNoFocus = (e) => {
    // Fix iOS to avoid focus event on the DropDown input trigger by forcing a blur event
    e.target.blur();
  };

  /**  Trigger for the DropDown */
  renderInputTrigger = (icon, iconPosition, dataValue) => {
    return (
      <Input
        icon={icon}
        iconPosition={iconPosition}
        onFocus={this.handleNoFocus}
        placeholder={intl.formatMessage({
          id: 'label.select_date',
        })}
        readOnly
        value={dataValue}
      />
    );
  };

  render() {
    const {
      afterElement,
      beforeElement,
      blockModal,
      disabled,
      enableOutsideDays,
      formError,
      iconHour,
      iconPosition,
      input,

      isValid,
      label,
      meta: { error, submitFailed },
      mountNodeSelector,
      numberOfMonths,
      open,
      placeholderHour,
      readOnly,
      title,
      usePortal,
    } = this.props;

    const { currentTime, initialDateState, selectedDate, visualDay } =
      this.state;

    const localizedFormat = new DateTools().getLocalizedLongDateFormat();

    //  Convert input value moment date
    let dataValue = '';
    let displayDate = '';
    if (input.value) {
      dataValue = input.value.format
        ? input.value.format(localizedFormat)
        : dateFormat(input.value, null, LocaleSingleton.get());
      displayDate = input.value.format
        ? input.value.format(localizedFormat)
        : dateFormat(input.value);
    } else {
      dataValue = initialDateState
        ? dateFormat(initialDateState, null, LocaleSingleton.get())
        : '';
      displayDate = initialDateState ? dateFormat(initialDateState) : '';
    }

    const iconDropDown = iconHour || 'clock';

    const closeTrigger = (
      <div
        className='item close'
        onClick={this.handleCloseModal}
        onKeyPress={this.handleCloseModal}
      >
        {intl.formatMessage({
          id: 'label.close',
        })}
      </div>
    );

    // Force the datepicker to use the input value as starting month/year
    const startingDate = () => {
      if (selectedDate) {
        return moment(selectedDate, 'YYYY-MM-DD');
      }
      if (dataValue) {
        return moment(dataValue, localizedFormat);
      }
      if (initialDateState) {
        return moment(initialDateState, 'YYYY-MM-DD');
      }
      return moment();
    };

    const datePickerProps = {
      daySize: 42,

      enableOutsideDays: enableOutsideDays,
      hideKeyboardShortcutsPanel: true,
      initialVisibleMonth: startingDate,

      key: selectedDate,
      numberOfMonths: numberOfMonths || 1,
      onDayClick: this.handleSelectDay,
      onNextMonthClick: this.handleNextMonth,
      onPrevMonthClick: this.handlePrevMonth,
    };

    const modalActions = (
      <Button
        disabled={!selectedDate || !isValid}
        onClick={this.handleSelectedDate}
        primary
      >
        {intl.formatMessage({
          id: 'label.select',
        })}
      </Button>
    );

    datePickerProps.modifiers = visualDay;

    if (readOnly) {
      return (
        <Form.Field>
          {label && <label>{label}</label>}

          <Input readOnly transparent value={displayDate} />
        </Form.Field>
      );
    }

    const dateTools = new DateTools(dataValue, localizedFormat);

    const { hours } = this.props;

    return (
      <Form.Group widths='equal'>
        {!readOnly && (
          <Form.Field>
            {label && <label>{label}</label>}

            <Input
              disabled={disabled}
              icon='calendar'
              iconPosition={iconPosition}
              onChange={this.handleNoFocus}
              onClick={this.handleOpenModal}
              placeholder={intl.formatMessage({
                id: 'label.select_date',
              })}
              readOnly
              value={dataValue}
            />

            {submitFailed && error ? (
              <ErrorLabel
                error={error}
                inputName={input.name}
                pointing='above'
              />
            ) : null}
            {formError && (
              <ErrorLabel
                error={formError}
                inputName={input.name}
                pointing='above'
              />
            )}

            <DatePickerModal
              blockModal={blockModal}
              closeTrigger={closeTrigger}
              handleCloseModal={this.onHandleCloseModal}
              handleOpenModal={this.onHandleOpenModal}
              modalActions={modalActions}
              mountNodeSelector={mountNodeSelector}
              // eslint-disable-next-line react/destructuring-assignment -- risky to modify because prop and state share the same name
              open={open || this.state.open}
              setDateModal={this.setDateModal}
              title={title || label}
              usePortal={usePortal}
            >
              {beforeElement}
              <DayPicker {...datePickerProps} />
              {formError && (
                <ErrorLabel
                  error={formError}
                  inputName={input.name}
                  pointing='above'
                />
              )}
              {afterElement}
            </DatePickerModal>
          </Form.Field>
        )}

        {hours && (
          <Form.Field>
            <Dropdown
              className='hourPicker'
              disabled={disabled}
              floating
              fluid
              icon={null}
              labeled
              onChange={this.handleChangeTime}
              options={
                dateTools.isToday() && !dateTools.isBefore()
                  ? dateTools.getOptionsMap()
                  : optionTime
              }
              placeholder={placeholderHour || 'Time'}
              scrolling
              trigger={this.renderInputTrigger(
                iconDropDown,
                iconPosition,
                currentTime
              )}
              value={currentTime}
            />
          </Form.Field>
        )}
      </Form.Group>
    );
  }
}

export const DatePickerTest = DatePicker;

export default DatePicker;
