import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Image } from 'semantic-ui-react';
import { noop, times } from 'lodash';

// utils
import uiPathGenerator from '../../utils/uiPathGenerator';
// Molecules
import EspListItem from './EspListItem';
import CatalogListItemController from '../controllers/CatalogListItemController';

class CatalogListItem extends PureComponent {
  static propTypes = {
    addCartProduct: PropTypes.func,
    addingCatalogItemID: PropTypes.number,
    cartItem: PropTypes.bool,
    cartRemoveProduct: PropTypes.func,
    cartUpdateProduct: PropTypes.func,
    handleClose: PropTypes.func,
    isLink: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingCart: PropTypes.bool,
    isModal: PropTypes.bool,
    isUpdating: PropTypes.bool,
    loadProduct: PropTypes.func,
    openBaristaChat: PropTypes.func.isRequired,
    params: PropTypes.shape({
      catalogID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    product: ImmutablePropTypes.mapContains({
      barista_url: PropTypes.string,
      category: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      images: ImmutablePropTypes.listOf(
        ImmutablePropTypes.mapContains({
          image_480: PropTypes.string,
          image_960: PropTypes.string,
          image_custom: PropTypes.string,
          ordering: PropTypes.number,
          thumbnail: PropTypes.string,
        })
      ),
      name: PropTypes.string.isRequired,
      notification: PropTypes.string,
      path: PropTypes.string,
      price: PropTypes.string,
      review_summary: PropTypes.object,
      short_description: PropTypes.string,
      target_intent: PropTypes.string,
    }).isRequired,
    removeCatalogItemID: PropTypes.number,
    upwardDropdown: PropTypes.bool,
  };

  static defaultProps = {
    addCartProduct: noop,
    addingCatalogItemID: 0,
    cartItem: false,
    cartRemoveProduct: noop,
    cartUpdateProduct: noop,
    handleClose: noop,
    isLink: true,
    isLoading: false,
    isLoadingCart: false,
    isModal: false,
    isUpdating: false,
    loadProduct: noop,
    params: {},
    removeCatalogItemID: 0,
    upwardDropdown: false,
  };

  state = {
    addToCart: false,
  };

  handleAddCartProduct = (isBaristaChatItem) => () => {
    const {
      addCartProduct,
      isLoadingCart,
      openBaristaChat,
      product,
    } = this.props;

    if (isBaristaChatItem) {
      openBaristaChat(product);
    } else {
      // Make sure we add item to cart only when the cart is not in a loading state
      if (!isLoadingCart) {
        const stopAddtoCart = () =>
          this.setState({
            addToCart: false,
          });
        this.setState({
          addToCart: true,
        });
        addCartProduct(product.get('id'), 1, stopAddtoCart);
      }
    }
  };

  handleRemoveItem = () => {
    const { product, cartRemoveProduct } = this.props;

    cartRemoveProduct(
      product.get('citemId'),
      product.get('id'),
      product.getIn(['category', 0])
    );
  };

  handleClickItem = (e) => {
    this.handleClose(e);
  };

  handleClose = (e) => {
    const { handleClose } = this.props;
    if (handleClose) {
      handleClose(e);
    }
  };

  handleChange = (e, data) => {
    const { cartUpdateProduct, product } = this.props;
    cartUpdateProduct(product.get('id'), product.get('citemId'), data.value);
  };

  handleImageError = () => {
    const { loadProduct, product } = this.props;

    loadProduct(product.get('id'));
  };

  render() {
    const {
      addingCatalogItemID,
      cartItem,
      isLink,
      isLoading,
      isLoadingCart,
      isModal,
      isUpdating,
      params,
      product,
      removeCatalogItemID,
      upwardDropdown,
    } = this.props;

    // Build dynamically the dropdown options
    let qty;
    if (cartItem) {
      const nbrItems = product.get('qty');

      // If more than 10 items have been added, set the max options to the current qty
      const limit = nbrItems > 10 ? nbrItems : 10;

      qty = times(limit, (i) => ({
        key: i + 1,
        text: i + 1,
        value: i + 1,
      }));
    }

    const subcategoryID = product.getIn(['category', 0]);
    const categoryID =
      product.get('category_all').find((c) => c !== subcategoryID) || 0;

    const linkTo =
      isLink && !isLoading
        ? uiPathGenerator.genPath('app.catalog.detail', {
            catalogID: product.get('id'),
            categoryID: categoryID,
            subcategoryID: subcategoryID,
          })
        : null;

    // Temp fix of the length of desc whe nwe didnt get a short desc
    let descProduct = product.get('description');
    if (descProduct && descProduct.length > 80) {
      descProduct = `${descProduct.substring(0, 80)}...`;
    }

    const price = product.get('price') ? `$${product.get('price')}` : null;
    const notification = product.get('notification');
    let priceAndNotification = null;
    if (notification && price) {
      priceAndNotification = `${price} (${notification})`;
    } else if (notification && !price) {
      priceAndNotification = notification;
    } else if (!notification && price) {
      priceAndNotification = price;
    }

    // Pass empty div as node if no price
    priceAndNotification = priceAndNotification || <div />;

    const isBaristaChatItem = Boolean(
      product && (product.get('target_intent') || product.get('barista_url'))
    );

    const catalogActions = () => {
      const { addToCart } = this.state;
      // Don't pass a button ADD when the state is in loading
      // Being used as a listing item in the shop
      if (!isLoading && !cartItem && isLink) {
        return (
          <Button
            basic
            className='add-to-cart'
            content={
              isBaristaChatItem
                ? intl.formatMessage({ id: 'label.request_item' })
                : intl.formatMessage({ id: 'label.add' })
            }
            disabled={isUpdating || isLoadingCart}
            loading={
              isUpdating ||
              (addToCart && addingCatalogItemID === product.get('id'))
            }
            onClick={this.handleAddCartProduct(isBaristaChatItem)}
            primary
            size='tiny'
          />
        );
      }

      // it's being used as a cart item (shop checkout modal)
      if (!isLoading && cartItem) {
        return (
          <div className='quantity'>
            <Button
              basic
              content={intl.formatMessage({
                id: 'label.delete',
              })}
              disabled={isUpdating || isLoadingCart}
              loading={
                (isUpdating &&
                  product.get('citemId') === removeCatalogItemID) ||
                (isLoadingCart && addingCatalogItemID === product.get('id'))
              }
              onClick={this.handleRemoveItem}
              size='tiny'
            />
          </div>
        );
      }

      return null;
    };

    const dropDownStyle = { minWidth: '3.5em' }; // make it wider so it's not overlapped by its own scrollbar
    const headerAction =
      !isLoading && cartItem ? (
        <Dropdown
          compact
          defaultValue={product.get('qty')}
          onChange={this.handleChange}
          options={qty}
          placeholder='Select choice'
          selection
          style={dropDownStyle}
          upward={upwardDropdown}
        />
      ) : null;

    return (
      <EspListItem
        action={catalogActions()}
        active={params && Number(params.catalogID) === product.get('id')}
        as={isLink && !isModal ? Link : null}
        description={product.get('short_description') || descProduct}
        header={product.get('name')}
        headerAction={headerAction}
        image={
          <Image
            as={isLink && !isLoading ? Link : null}
            onError={this.handleImageError}
            src={
              product.getIn(['images', 0, 'image_custom']) ||
              product.getIn(['images', 0, 'thumbnail'])
            }
            to={linkTo}
          />
        }
        isCartItem={cartItem}
        isLoading={isLoading}
        onClick={this.handleClickItem}
        price={priceAndNotification}
        quantity={product.get('quantity')}
        to={linkTo}
      />
    );
  }
}

const CatalogListItemTest = CatalogListItem;

// eslint-disable-next-line no-class-assign -- DEV-1526
CatalogListItem = CatalogListItemController(CatalogListItem);
export { CatalogListItemTest };
export default CatalogListItem;
