import { connect } from 'react-redux';

import AddAttributeModal from '../../../../../admin/js/v1/components/organisms/AddAttributeModal';
import taskThunks from '../../../../../admin/js/v1/actions/taskThunks';

const mapStateToProps = (state) => ({
  addAttributeForm: state.getIn(['form', 'AddAttributeForm']),
  objectMappingList: state.getIn(['editor', 'objectMappingList']),
});

const mapDispatchToProps = (dispatch) => ({
  addAttribute: () => {
    dispatch(taskThunks.addAttributeToTask());
  },

  loadObjectMapping: () => {
    dispatch(taskThunks.loadObjectMapping());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAttributeModal);
