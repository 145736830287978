import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { FormSection } from 'redux-form/immutable';
import immutable from 'immutable';
import { Button, Checkbox, Form, Message } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import ValidatedField from '../molecules/ValidatedField';
import FormInputText from '../molecules/FormInputText';
import ValidatedSelect from '../molecules/ValidatedSelect';
import BaristaIntentLookup from '../molecules/BaristaIntentLookup';
import FormSectionContainer from './FormSectionContainer';
import { noop } from 'lodash';

import {
  ACTION_TYPES,
  MAX_ACTION_TEXT_LENGTH,
  PREVIEW_TEXT,
} from '../../globals/announcementCustomActionConstants';

class AnnouncementCustomAction extends Component {
  static propTypes = {
    announcementId: PropTypes.string.isRequired,
    changeFormFieldValue: PropTypes.func.isRequired,
    formValues: ImmutablePropTypes.map,
    isActionEnabled: PropTypes.bool,
    onToggleActionCheckbox: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    formValues: immutable.Map(),
    isActionEnabled: false,
    onToggleActionCheckbox: noop,
    readOnly: false,
  };

  handleActionCheckBoxClick = () => {
    const {
      announcementId,
      changeFormFieldValue,
      isActionEnabled,
      onToggleActionCheckbox,
    } = this.props;

    // if we are unchecking, let's reset values
    if (isActionEnabled) {
      changeFormFieldValue('action.0.action_url', null, announcementId);
      changeFormFieldValue('action.0.intent_eid', null, announcementId);
      changeFormFieldValue('action.0.type', null, announcementId);
      changeFormFieldValue('action.0.button_text', null, announcementId);
    } else {
      // set default values
      changeFormFieldValue('action.0.type', ACTION_TYPES.URL, announcementId);
      changeFormFieldValue(
        'action.0.button_text',
        PREVIEW_TEXT,
        announcementId
      );
    }

    // this is required for validation to work
    onToggleActionCheckbox(!isActionEnabled);
  };

  handleActionTypeChange = () => {
    const { announcementId, changeFormFieldValue } = this.props;
    changeFormFieldValue('action.0.action_url', '', announcementId);
    changeFormFieldValue('action.0.intent_eid', '', announcementId);
  };

  generateLabel = (labelText) => (
    <label
      style={{
        display: 'block',
        fontSize: '.9375em',
        fontWeight: 700,
      }}
    >
      {labelText}
    </label>
  );

  render() {
    const { formValues, isActionEnabled, readOnly } = this.props;

    const customActionIndex = 0;

    const hasCustomAction = formValues.hasIn(['action', customActionIndex]);
    const actionType = formValues.getIn(['action', customActionIndex, 'type']);
    const isTargetBarista =
      hasCustomAction && actionType === ACTION_TYPES.INTENT;

    const isActionURLRequired = hasCustomAction && !isTargetBarista;
    const isActionIntentRequired = hasCustomAction && isTargetBarista;

    const actionTypeOptions = [
      {
        key: ACTION_TYPES.URL,
        text: intl.formatMessage({ id: 'label.external_url' }),
        value: ACTION_TYPES.URL,
      },
      {
        key: ACTION_TYPES.INTENT,
        text: intl.formatMessage({ id: 'label.open_barista_chat' }),
        value: ACTION_TYPES.INTENT,
      },
    ];

    const previewText = formValues.getIn(
      ['action', customActionIndex, 'button_text'],
      PREVIEW_TEXT
    );
    const isActionTextTooLarge = previewText
      ? previewText.length >= MAX_ACTION_TEXT_LENGTH
      : false;

    return (
      <FormSection name='action'>
        <Checkbox
          checked={isActionEnabled}
          disabled={readOnly}
          id='custom_action_checkbox'
          label={
            <label
              style={{
                fontWeight: '700',
                marginBottom: '5px',
              }}
            >
              {intl.formatMessage({ id: 'label.customize_action_button' })}
            </label>
          }
          onClick={readOnly ? void 0 : this.handleActionCheckBoxClick}
          readOnly={readOnly}
          style={{
            fontWeight: '700',
            marginBottom: '5px',
          }}
        />
        <div>
          {intl.formatMessage({ id: 'label.custom_action_description' })}
        </div>

        <FormSectionContainer>
          <Form.Field>
            <ValidatedField
              component={FormInputText}
              disabled={!isActionEnabled || readOnly}
              label={intl.formatMessage({ id: 'label.text' })}
              maxLength={MAX_ACTION_TEXT_LENGTH}
              name={`${customActionIndex}.button_text`}
              readOnly={readOnly}
              required={isActionEnabled}
            />

            {isActionTextTooLarge && (
              <Message
                content={intl.formatMessage({
                  id: 'message.action_text_too_large',
                  values: {
                    max_len: MAX_ACTION_TEXT_LENGTH,
                  },
                })}
                error
                size={'small'}
              />
            )}
            {this.generateLabel(intl.formatMessage({ id: 'label.preview' }))}
            <Button
              content={previewText}
              disabled={!isActionEnabled || readOnly}
              style={{
                maxWidth: '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              type='button'
            />
          </Form.Field>
          <Form.Field>
            <ValidatedSelect
              disabled={!isActionEnabled || readOnly}
              fluid
              label={intl.formatMessage({ id: 'label.action' })}
              name={`${customActionIndex}.type`}
              onChange={this.handleActionTypeChange}
              options={actionTypeOptions}
              placeholder={intl.formatMessage({ id: 'label.choose_an_option' })}
              readOnly={!readOnly}
              value={formValues.getIn(['action', customActionIndex, 'type'])}
            />
          </Form.Field>
          {isTargetBarista && (
            <BaristaIntentLookup
              disabled={!isActionEnabled || readOnly}
              label={intl.formatMessage({ id: 'label.target_intent' })}
              name={`${customActionIndex}.intent_eid`}
              placeholder={intl.formatMessage({
                id: 'label.choose_target_intent',
              })}
              readOnly={!readOnly}
              required={isActionEnabled && isActionIntentRequired}
              value={formValues.getIn([
                'action',
                customActionIndex,
                'intent_eid',
              ])}
            />
          )}
          {!isTargetBarista && (
            <Form.Field>
              <ValidatedField
                component={FormInputText}
                disabled={!isActionEnabled || readOnly}
                label={intl.formatMessage({ id: 'label.URL' })}
                name={`${customActionIndex}.action_url`}
                readOnly={readOnly}
                required={isActionEnabled && isActionURLRequired}
              />
            </Form.Field>
          )}
        </FormSectionContainer>
      </FormSection>
    );
  }
}

export default AnnouncementCustomAction;
