import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsAddedEmail extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0007',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M42.07 48.62c-.325 0-.64-.105-.9-.3l-29.2-22 1.8-2.4 28.31 21.33 28.37-20.87 1.78 2.42-29.27 21.53c-.258.19-.57.29-.89.29z' />
          <path d='M16 65.1c-1.925-.01-3.48-1.575-3.48-3.5V29c0-1.933 1.567-3.5 3.5-3.5h51.92c1.933 0 3.5 1.567 3.5 3.5v16.27c.997-.1 2.003-.1 3 0V29c-.006-3.588-2.912-6.494-6.5-6.5H16c-3.58.016-6.475 2.92-6.48 6.5v32.6c.006 3.588 2.912 6.494 6.5 6.5h44.8c-.63-.937-1.15-1.943-1.55-3H16z' />
          <path d='M79.52 58.36h-5.08v-5.07c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v5.08h-5.08c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h5.08v5.08c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-5.09h5.08c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5z' />
          <path d='M72.94 47.07c-7.064 0-12.79 5.726-12.79 12.79 0 7.064 5.726 12.79 12.79 12.79 7.064 0 12.79-5.726 12.79-12.79-.01-7.06-5.73-12.78-12.79-12.79zm0 22.89c-5.578 0-10.1-4.522-10.1-10.1 0-5.578 4.522-10.1 10.1-10.1 5.578 0 10.1 4.522 10.1 10.1 0 5.578-4.522 10.1-10.1 10.1z' />
        </g>
      </svg>
    );
  }
}

export default CardsAddedEmail;
