import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureSitStandDesks extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(19.5 15)'>
          <path d='M56.21 29.35v-1.7H21.38v1.71h16.14v26.16c-4 .55-7.123 3.73-7.6 7.74h17.61c-.478-4.03-3.628-7.22-7.65-7.75V29.35h16.33z' />
          <rect
            height='2.23'
            rx='.79'
            transform='rotate(-76.54 48.698 12.89)'
            width='22.06'
            x='37.668'
            y='11.774'
          />
          <path d='M37.72 26.83l8.4-.07c2.23.16 3.22-3 3.27-3.12l2.23-9.84c.057-.2 0-.414-.15-.558-.15-.144-.365-.194-.562-.13-.198.065-.343.234-.378.438l-2.22 9.8s-.79 2.41-2.16 2.29l-8.46.07c-.278.037-.485.275-.485.555s.207.518.485.555l.03.01zm-11.16-1.02c1.77 0 3.205-1.435 3.205-3.205S28.33 19.4 26.56 19.4h-7.64L7.37 13.98c3.183-.318 5.668-2.895 5.87-6.087.2-3.193-1.942-6.062-5.06-6.776C5.062.403 1.885 2.053.676 5.015-.534 7.977.582 11.378 3.31 13.05c-1.886.418-3.244 2.07-3.29 4v18.3c-.013.24-.013.48 0 .72v26.91c-.056.827.354 1.616 1.062 2.046.71.43 1.597.43 2.306 0 .708-.43 1.118-1.22 1.062-2.046.004-.077.004-.153 0-.23V41.57c.23.08.463.146.7.2V61.3c0 2.21 1.79 4 4 4s4-1.79 4-4V24.5l2.79 1.3 10.62.01z' />
        </g>
      </svg>
    );
  }
}

export default CatalogFurnitureSitStandDesks;
