import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import { Checkbox, Divider, Form } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { noop } from 'lodash';
import moment from 'moment';

import { intl } from 'esp-util-intl';

import ValidatedField from '../molecules/ValidatedField';
import FormInputText from '../molecules/FormInputText';
import FormSectionContainer from './FormSectionContainer';
import AnnouncementRecurring from './AnnouncementRecurring';

const INPUT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

const initialRecurringValues = {
  auto_expire_days: 0,
  end: null,
  end_date: null,
  end_type: 'on',
  every: 1,
  on_day: null,
  on_ordinal_number: null,
  on_week_day: null,
  repeat: 'daily',
};

const propTypes = {
  announcementId: PropTypes.string,
  formValues: ImmutablePropTypes.map,
  handleToggleScheduleCheckbox: PropTypes.func,
  handlerChangeFormFieldValue: PropTypes.func,
  isDraft: PropTypes.bool,
  isETAEnabled: PropTypes.bool,
  isRecurringEnabled: PropTypes.bool,
  isScheduleEnabled: PropTypes.bool,
  userLanguage: PropTypes.string,
};

const AnnouncementScheduling = ({
  announcementId = void 0,
  formValues = Immutable.Map(),
  handleToggleScheduleCheckbox = noop,
  handlerChangeFormFieldValue = noop,
  isDraft = false,
  isETAEnabled = false,
  isRecurringEnabled = false,
  isScheduleEnabled = false,
  userLanguage = '',
}) => {
  const [recurringValues, setRecurringValues] = useState(
    initialRecurringValues
  );

  const MIN_DATE = moment().add(1, 'day').format(INPUT_DATE_FORMAT);
  const MIN_END_DATE = formValues.getIn(['schedule', 'start_date'], MIN_DATE);

  const handleFormChange = useCallback(
    (name, value) => {
      setRecurringValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      if (name === 'on_weekdays') {
        handlerChangeFormFieldValue(
          `schedule.${name}`,
          new Immutable.List(value),
          announcementId
        );
      } else {
        handlerChangeFormFieldValue(`schedule.${name}`, value, announcementId);
      }
    },
    [announcementId, handlerChangeFormFieldValue]
  );

  const handleStartDateChange = useCallback(
    (e, startDate) => {
      handlerChangeFormFieldValue(
        'schedule.start_date',
        startDate,
        announcementId
      );
      handlerChangeFormFieldValue(
        'schedule.end_date',
        startDate,
        announcementId
      );
    },
    [announcementId, handlerChangeFormFieldValue]
  );

  const resetRecurringValues = useCallback(() => {
    setRecurringValues(initialRecurringValues);
  }, []);

  const handleSchedulingCheckbox = useCallback(
    (e, { checked }) => {
      resetRecurringValues();

      if (checked) {
        handlerChangeFormFieldValue(
          'schedule.start_date',
          MIN_DATE,
          announcementId
        );
      } else {
        handlerChangeFormFieldValue('schedule.start_date', '', announcementId);
      }

      handleToggleScheduleCheckbox({ isScheduleEnabled: checked });
    },
    [
      MIN_DATE,
      announcementId,
      handleToggleScheduleCheckbox,
      handlerChangeFormFieldValue,
      resetRecurringValues,
    ]
  );

  const handleRecurringCheckbox = useCallback(
    (e, { checked }) => {
      resetRecurringValues();

      if (checked) {
        Object.keys(initialRecurringValues).forEach((key) => {
          handlerChangeFormFieldValue(
            `schedule.${key}`,
            initialRecurringValues[key],
            announcementId
          );
        });
      }

      handleToggleScheduleCheckbox({ isRecurringEnabled: checked });
    },
    [
      announcementId,
      handleToggleScheduleCheckbox,
      handlerChangeFormFieldValue,
      resetRecurringValues,
    ]
  );

  return (
    <FormSection name='schedule'>
      <Checkbox
        checked={isScheduleEnabled}
        disabled={!isDraft || isETAEnabled}
        id='schedule_checkbox'
        label={
          <label
            style={{
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage({ id: 'label.scheduling' })}
          </label>
        }
        onChange={handleSchedulingCheckbox}
        readOnly={!isDraft || isETAEnabled}
        value={'isScheduleEnabled'}
      />

      <Divider />
      <p>{intl.formatMessage({ id: 'label.scheduling_description' })}</p>
      <Divider hidden />

      <FormSectionContainer style={{ paddingLeft: '0' }}>
        <Form.Group>
          <ValidatedField
            component={FormInputText}
            disabled={!isDraft || !isScheduleEnabled}
            label={intl.formatMessage({ id: 'label.start_date' })}
            min={MIN_DATE}
            name='start_date'
            onChange={handleStartDateChange}
            readOnly={!isDraft}
            required={isScheduleEnabled}
            type='datetime-local'
          />
        </Form.Group>
      </FormSectionContainer>

      <Checkbox
        checked={isRecurringEnabled}
        disabled={!isDraft || !isScheduleEnabled}
        id='recurring_checkbox'
        label={
          <label style={{ fontSize: '1.125em' }}>
            {intl.formatMessage({ id: 'label.recurring' })}
          </label>
        }
        onClick={handleRecurringCheckbox}
        readOnly={!isDraft}
        value={'isRecurringEnabled'}
      />

      <FormSectionContainer isDisabled={!isRecurringEnabled}>
        <Form.Field>
          <em>
            <small>
              {intl.formatMessage({ id: 'label.recurring_description' })}
            </small>
          </em>
        </Form.Field>
      </FormSectionContainer>

      <AnnouncementRecurring
        formValues={recurringValues}
        isDisabled={!isRecurringEnabled}
        minDate={MIN_END_DATE}
        onFormChange={handleFormChange}
        userLanguage={userLanguage}
      />
    </FormSection>
  );
};

AnnouncementScheduling.propTypes = propTypes;

export default AnnouncementScheduling;
