import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import pt from 'prop-types';

import createAxiosInstance from '../../requestInstance';

import Page from '../../components/Page';
import LoginForm from '../../components/LoginForm';
import ExternalLogin from '../../components/ExternalLogin';

import { useEncryption } from '../../hooks/encryption';
import { useLocalStorage } from '../../hooks/storage';
import localStorageConfig from '../../hooks/storage/localStorageConfig';
import TenantContext from '../tenant/tenantContext';
import AuthContext from '../auth/authContext';

import authReducer from './authReducer';
import { LOGIN_FAILURE, LOGIN_SUCCESS } from './authActions';
import {
  AUTH_STATUS,
  AUTH_TYPE,
  EXTERNAL_SESSION_KEYS,
  SESSION_KEYS,
} from '../../constants';

const propTypes = {
  children: pt.oneOfType([pt.node, pt.arrayOf(pt.node)]),
};

const { STORAGE_NAMESPACES, LOGOUT } = localStorageConfig;

const isEmpty = (obj) => !Object.keys(obj).length;

// attempts to destructure attributes which we know
// are present in external sessions.
//
// isLegacy will be set to true if at least one of
// the attributes is present.
const detectExternalSession = (session) => {
  if (session) {
    const sessionKeys = Object.keys(session);

    for (const key of sessionKeys) {
      if (EXTERNAL_SESSION_KEYS.includes(key)) {
        return [true, session];
      }
    }
  }

  return [false];
};

// Espressive Auth Provider
//
// This provider secures the app by not rendering but the login form
// if no session is active, it also hosts the logic for:
//
// - Credentials exhange -> Token  (login)
// - Token refresh
// - Session termination (logout)
// - Profile fetching (when a session is active)
//
// @param {JSX.Element} children The content to be displayed when there is an active
// @param {Boolean} config.isTenantSelectable specifies if the User can manualy select
const AuthProvider = ({ children }) => {
  const { authorizationEndpoint, baseURL } = useContext(TenantContext);
  const { encrypt, decrypt } = useEncryption();

  const [sessionFromLocalStorage, saveSessionToLocalStorage] = useLocalStorage({
    dataKey: STORAGE_NAMESPACES.SESSION,
  });

  let accessToken;

  const [isExternalSession, externalSession] = detectExternalSession(
    sessionFromLocalStorage
  );

  if (isExternalSession) {
    // DEV-15758: fallback to external token name
    // TODO: make sure we handle extra attributes in this type of sessions
    if (externalSession?.accessToken) {
      accessToken = decrypt(externalSession?.accessToken); // external token is always encrypted
    }
  } else {
    accessToken =
      sessionFromLocalStorage &&
      decrypt(sessionFromLocalStorage[SESSION_KEYS.TOKEN]);
  }

  const authType =
    sessionFromLocalStorage && sessionFromLocalStorage[SESSION_KEYS.AUTH_TYPE];
  const sessionIndex =
    sessionFromLocalStorage && sessionFromLocalStorage[SESSION_KEYS.INDEX];

  const [state, dispatch] = useReducer(authReducer, {
    message: '',
    profile: {},
    session: {
      accessToken,
      authType:
        authType || Boolean(authorizationEndpoint)
          ? AUTH_TYPE.OAUTH
          : AUTH_TYPE.INTERNAL,
      sessionIndex,
    },
    status: accessToken
      ? AUTH_STATUS.AUTHENTICATED
      : AUTH_STATUS.UNAUTHENTICATED,
  });

  const encryptAndSaveSession = useCallback(
    ({ accessToken, authType, sessionIndex }) => {
      const cipherToken = encrypt(accessToken);

      const newSession = {
        [SESSION_KEYS.TOKEN]: cipherToken,
        [SESSION_KEYS.AUTH_TYPE]: authType,
      };

      if (sessionIndex) {
        newSession[SESSION_KEYS.INDEX] = sessionIndex;
      }

      saveSessionToLocalStorage(newSession);
    },
    [encrypt, saveSessionToLocalStorage]
  );

  const axiosInstance = useMemo(
    () =>
      // This is the GLOBAL configuration for Axios.
      createAxiosInstance({
        accessToken: state.session.accessToken,
        baseURL: baseURL,
      }),
    [baseURL, state.session.accessToken]
  );

  const fetchProfile = useCallback(
    (accessToken) => {
      return axiosInstance({
        headers: {
          Authorization: `Token ${accessToken}`,
        },
        method: 'get',
        url: '/api/bootstrap/v0.1/start/',
      });
    },
    [axiosInstance]
  );

  const login = useCallback(
    async (username, password) => {
      const data = new FormData();
      data.append('username', username);
      data.append('password', password);

      try {
        const {
          data: { key: accessToken },
        } = await axiosInstance({
          data,
          method: 'post',
          url: '/api/authentication/v0.1/auth/login/',
        });

        const { data: profile } = await fetchProfile(accessToken);

        const newSessionObject = {
          accessToken,
          authType: AUTH_TYPE.INTERNAL,
        };
        dispatch({
          payload: {
            profile,
            session: newSessionObject,
          },
          type: LOGIN_SUCCESS,
        });
      } catch (err) {
        dispatch({
          payload: 'Wrong username or password',
          type: LOGIN_FAILURE,
        });
      }
    },
    [axiosInstance, fetchProfile]
  );

  const logout = useCallback(async () => {
    try {
      await axiosInstance({
        method: 'post',
        url: '/api/authentication/v0.1/auth/logout/',
        // headers: {
        //   Authorization: `Token ${state.accessToken}`,
        // },
      });
    } catch (logoutException) {
      // eslint-disable-next-line no-console -- we need this as a developer message
      console.warn(logoutException);
    } finally {
      dispatch({ type: LOGOUT });
    }
  }, [axiosInstance, dispatch /**state.accessToken */]);

  /**
   * The SSO impl. uses this function to communicate a successful login.
   *
   * @param {Object} config an object containing session data
   * @param {Object} accessToken a JWT
   */
  const onSuccessfulSSO = useCallback(
    async ({
      accessToken: incomingToken,
      sessionIndex,
      isOAuth,
      isSAML,
      queryParams,
    }) => {
      if (!isSAML) {
        let codeExchangeResponse;

        try {
          // try to exchange auth code for an accessToken
          codeExchangeResponse = await axiosInstance({
            method: 'get',
            url: `/api/authentication/v0.1/auth/login/azure_get_token?${queryParams}`,
          });
        } catch (err) {
          dispatch({
            payload: 'Wrong username or password',
            type: LOGIN_FAILURE,
          });
        }

        const accessToken = codeExchangeResponse.data.key || incomingToken;
        const { data: profile } = await fetchProfile(accessToken);

        const newSessionObject = {
          accessToken,
          authType: isOAuth
            ? isSAML
              ? AUTH_TYPE.SAML
              : AUTH_TYPE.OAUTH
            : AUTH_TYPE.INTERNAL,
        };

        if (sessionIndex) {
          newSessionObject.sessionIndex = sessionIndex;
        }

        dispatch({
          payload: {
            profile,
            session: newSessionObject,
          },
          type: LOGIN_SUCCESS,
        });
      }
    },
    [axiosInstance, fetchProfile]
  );

  const onFailedSSO = useCallback(
    (err) => {
      dispatch({
        payload: 'Wrong username or password',
        type: LOGIN_FAILURE,
      });
    },
    [dispatch]
  );

  //
  // Fetch profile if an authenticated session exists,
  // but profile has not been fetched.
  //
  // This tipically happens when the user comes back and
  // there is a session stored in localStorage.
  //
  // Since profile is not persisted, we need to re-fetch it.
  useEffect(() => {
    async function fetchProfileProxy(accessToken) {
      try {
        const { data: userProfile } = await fetchProfile(accessToken);

        dispatch({
          payload: userProfile,
          type: 'FETCH_PROFILE_SUCCESS',
        });
      } catch (fetchProfileException) {
        dispatch({
          payload: fetchProfileException,
          type: 'FETCH_PROFILE_FAILURE',
        });
      }
    }

    if (state.session.accessToken && isEmpty(state.profile)) {
      fetchProfileProxy(state.session.accessToken);
    }
  }, [dispatch, fetchProfile, state.profile, state.session.accessToken]);

  useEffect(() => {
    if (state.session.accessToken && !sessionFromLocalStorage) {
      encryptAndSaveSession(state.session);
    }
  });

  // mute what we won't expose
  const { session, ...safeDisclosureState } = state;
  const disclosureObject = {
    UNSAFE_accessToken: session.accessToken,
    requestInstance: axiosInstance,
    ...safeDisclosureState,
  };

  // only disclose what makes sense given the state
  if (state.status === AUTH_STATUS.UNAUTHENTICATED) {
    disclosureObject.login = login;
    disclosureObject.onSuccessfulSSO = onSuccessfulSSO;
    disclosureObject.onFailedSSO = onFailedSSO;
  } else if (state.status === AUTH_STATUS.AUTHENTICATED) {
    disclosureObject.logout = logout;
  }

  return (
    <AuthContext.Provider value={disclosureObject}>
      {state.status === AUTH_STATUS.AUTHENTICATED ? (
        children
      ) : authorizationEndpoint ? (
        <Page>
          <ExternalLogin />
        </Page>
      ) : (
        <Page>
          <LoginForm message={state.message} onSubmit={login} />
        </Page>
      )}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = propTypes;

export default AuthProvider;
