import actionTypes from './actionTypes';

const wfIntlActions = {};

wfIntlActions.loadTaskStart = () => ({
  type: actionTypes.WF_INTL_LOAD_TASK_START,
});

wfIntlActions.loadTaskSuccess = (taskId, task) => ({
  task,
  taskId,
  type: actionTypes.WF_INTL_LOAD_TASK_SUCCESS,
});

wfIntlActions.loadTaskError = (taskId, error) => ({
  error,
  taskId,
  type: actionTypes.WF_INTL_LOAD_TASK_ERROR,
});

wfIntlActions.selectEditorTask = (taskId) => ({
  taskId,
  type: actionTypes.WF_INTL_SELECT_TASK,
});

wfIntlActions.launchIntlKeyEditor = (intlKey, blobKeyPath) => ({
  blobKeyPath,
  intlKey,
  type: actionTypes.WF_INTL_LAUNCH_INTL_KEY_EDITOR,
});

wfIntlActions.closeIntlKeyEditor = () => ({
  type: actionTypes.WF_INTL_CLOSE_INTL_KEY_EDITOR,
});

wfIntlActions.loadLocalizationsForKeyStart = (intlKey) => ({
  intlKey,
  type: actionTypes.WF_INTL_LOAD_KEY_START,
});

wfIntlActions.loadLocalizationsForKeySuccess = (
  intlKey,
  keyLocalizations = []
) => ({
  intlKey,
  keyLocalizations,
  type: actionTypes.WF_INTL_LOAD_KEY_SUCCESS,
});

wfIntlActions.loadLocalizationsForKeyError = (intlKey, error) => ({
  error,
  intlKey,
  type: actionTypes.WF_INTL_LOAD_KEY_ERROR,
});

wfIntlActions.proposeNewKey = (intlKey) => ({
  intlKey,
  type: actionTypes.WF_INTL_NEW_KEY_PROSPECT,
});

wfIntlActions.saveTranslationStart = (intlKey) => ({
  intlKey,
  type: actionTypes.WF_INTL_SAVE_TRANS_START,
});

wfIntlActions.saveTranslationSuccess = (intlKey, translationBody) => ({
  intlKey,
  translationBody,
  type: actionTypes.WF_INTL_SAVE_TRANS_SUCCESS,
});

wfIntlActions.saveTranslationError = (intlKey, error) => ({
  error,
  intlKey,
  type: actionTypes.WF_INTL_SAVE_TRANS_ERROR,
});

wfIntlActions.changeTranslation = (lang, str) => ({
  lang,
  str,
  type: actionTypes.WF_INTL_TRANSLATION_CHANGE,
});
export default wfIntlActions;
