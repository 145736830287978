import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import _ from 'lodash';
import { intl } from 'esp-util-intl';
// v2 Components
import ChatMessage from '../../display/ChatMessage';
import ChatInputTypes from '../../display/ChatMessage/ChatInputTypes';
// Controllers
import BaristaWidgetController from './BaristaWidgetController';

const feedbackWidgets = ['Support Feedback', 'Live Agent Feedback'];
class PseudoBaristaWidget extends PureComponent {
  static propTypes = {
    comment: ImmutablePropTypes.map,
    isCaseMgmt: PropTypes.bool, // passed directly. If passed then it's assumed this chat belongs to a case
    onUserSelection: PropTypes.func,
  };

  static defaultProps = {
    comment: Immutable.Map(),
    isCaseMgmt: false,
    onUserSelection: _.noop,
  };

  handleOnOptionSelection = (selectedOption) => {
    const { comment, onUserSelection } = this.props;

    const channelID = comment.get('channel');
    const messageID = comment.get('id');
    const widgetID = comment.getIn(['metadata', 'widget', 'eid']);
    onUserSelection(channelID, messageID, widgetID, selectedOption);
  };
  render() {
    const { comment, isCaseMgmt } = this.props;

    const fakedInput = {};
    // determining the type
    const widgetName = comment.getIn(['metadata', 'widget', 'widget']);
    const widgetOptions = comment.getIn([
      'metadata',
      'widget',
      'body',
      '0',
      'children',
      '0',
      'children',
      '2',
      'children',
    ]);
    const isReadOnly = comment.getIn(['metadata', 'read_only']);
    if (feedbackWidgets.includes(widgetName)) {
      const responseOptions = comment.getIn([
        'metadata',
        'widget',
        'body',
        '0',
        'children',
        '0',
        'children',
        '4',
        'text',
      ]);
      fakedInput.type = ChatInputTypes.FEEDBACK;
      fakedInput.responseOptions = responseOptions
        ? responseOptions
        : intl.formatMessage({
            id: 'message.Thank_you_for_your_feedback',
          });
    } else if (widgetName === 'Ticket Resolved Survey') {
      fakedInput.type = ChatInputTypes.RADIO;
      // If defined as an object, the key of the object should correspond to the answer
      fakedInput.responseOptions = {
        1: 'Sorry to hear that. Let me know if I can help you in the future.',
        2: 'No problem, I have reopened the request.',
      };
    } else {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        `Message ${comment.get(
          'eid'
        )} widget has no widget name recognizible in metadata.widget.widget`
      );
    }

    if (widgetOptions && Immutable.List.isList(widgetOptions)) {
      fakedInput.options = widgetOptions
        .map((button) => ({
          action: button.get('action'),
          id: button.get('eid'),
          label: button.get('label'),
          value: button.get('value'),
        }))
        .toJS();
    } else {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        `Message ${comment.get('eid')} has no options in metadata.widget.body `
      );
    }

    // if options were found...
    if (fakedInput.options) {
      const selectedOption = widgetOptions.find((button) =>
        button.get('selected')
      );
      const selectedOptionKey = widgetOptions.findIndex((button) =>
        button.get('selected')
      );
      if (selectedOption) {
        fakedInput.value = selectedOption.get('value');

        if (typeof fakedInput.responseOptions === 'string') {
          fakedInput.inputResponse = fakedInput.responseOptions;
        } else if (typeof fakedInput.responseOptions === 'object') {
          fakedInput.inputResponse =
            fakedInput.responseOptions[selectedOptionKey];
        } else {
          throw new Error('responseOptions can only be a string or an object.');
        }
      }

      fakedInput.handleOptionSelection = this.handleOnOptionSelection;
    }

    if (isReadOnly) {
      // DEV-10244 adds a way to disable widget
      fakedInput.readOnly = true;
    }

    // inputResponse={fakedInput.value ? fakedInput.inputResponse : null}

    return (
      <ChatMessage
        input={fakedInput}
        inputResponse={fakedInput.inputResponse}
        isCaseMgmt={isCaseMgmt}
        isFirstMessage
        isLastMessage
        isMe
        messageText={comment.getIn([
          'metadata',
          'widget',
          'body',
          '0',
          'children',
          '0',
          'children',
          '0',
          'children',
          '1',
          'text',
        ])}
        timeStamp='A few moments ago'
      />
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
PseudoBaristaWidget = BaristaWidgetController(PseudoBaristaWidget);

export default PseudoBaristaWidget;
