import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  options: {
    arrayShape: {
      count: {
        requred: true,
        type: 'number',
      },
      id: {
        required: true,
        type: 'string',
      },
      label: {
        required: true,
        type: 'string',
      },
    },
    default: [],
    type: 'arrayOf',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'skills_1',
  },
});

// Object Definition used by blobifier
const SkillsBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Skills',
  templates: ['skills_1'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, SkillsBlockDefinition, TypeDefaults };
