import actionTypes from './actionTypes';

const bootstrapActions = {};

bootstrapActions.appBootstrapStart = () => ({
  type: actionTypes.APP_BOOTSTRAP_START,
});

bootstrapActions.appBootstrapSuccess = (bootstrapData, me) => ({
  bootstrapData,
  me,
  type: actionTypes.APP_BOOTSTRAP_SUCCESS,
});

bootstrapActions.appBootstrapFail = (error) => ({
  error,
  type: actionTypes.APP_BOOTSTRAP_FAIL,
});

bootstrapActions.helloActionsStart = () => ({
  type: actionTypes.APP_HELLO_ACTIONS_START,
});

bootstrapActions.helloActionsEnd = () => ({
  type: actionTypes.APP_HELLO_ACTIONS_END,
});

export default bootstrapActions;
