import { isFeatureFlag } from 'esp-ui-flag';
import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import baristaActions from '../../actions/baristaActions';
import baristaThunks from '../../actions/baristaThunks';
// Utils
import browserHistory from '../../utils/browserHistory';

/**
 * This controlled is shared between ChatBarista and ChatBaristaInput
 */
const mapStateToProps = (state) => ({
  errorMessage: state.getIn(['barista', 'channelState', 'errorMsg']),
  intentEid: state.getIn(['barista', 'selectedIntentEid']),
  isBaristaOpened: state.getIn(['appUI', 'isBaristaOpened']),
  isChannelIdGivenByUrl: state.getIn(['barista', 'isChannelIdGivenByUrl']),
  isLargeBaristaWindowEnabled: isFeatureFlag('largeBaristaWindow', state),
  isOpenFromUrl: state.getIn(['barista', 'isOpenFromUrl']),
  qrPhrase: state.getIn(['barista', 'qrParams', 'phrase']),
  selectedSupportChannel: state.getIn(['barista', 'selectedSupportChannel']),
  shouldStartWithQrParams: state.getIn(['barista', 'shouldStartWithQrParams']),
  shouldSubmitQrPhrase: state.getIn(['barista', 'qrParams', 'isSubmit']),
  suspendBarista: state.getIn(['barista', 'suspendBarista']),
});

const mapDispatchToProps = (dispatch) => ({
  enterBaristaSupportChannel: () => {
    dispatch(baristaActions.openSupportChannel());
  },
  exitBaristaSupportChannel: (navigateToURL = '') => {
    const EXIT_METHOD = 'click_exit'; // possibly move as a constant once we want to track different methods
    dispatch(baristaThunks.setExitMethod(EXIT_METHOD)).finally(() => {
      // DEV-17824 - Portal View
      // this keep the modal redux state as closed
      // making this after async function because otherwise it won't close
      dispatch(baristaActions.closeSupportChannel());
    });

    // navigate only when there's an explicit url to go
    if (navigateToURL) {
      // Both ChatBarista (barista in modal) and ChatInput will redirect to home
      browserHistory.push(navigateToURL);
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
