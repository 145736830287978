import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';
import EspFilters from 'esp-util-filters';
import { samlURLS } from '../azure/utils/constants';

const getAuthSAML_SP = async () => {
  const url = endpointGenerator.genPath('authentication.samlSP');

  const espFilters = new EspFilters();
  espFilters.equalTo('acs_url', samlURLS.replyURL);
  espFilters.equalTo('slo_url', samlURLS.logoutURL);

  return _APIcall.get({
    query: {
      esp_filters: espFilters.asQueryString(),
      limit: 1,
    },
    token: true,
    url: url,
  });
};

export default getAuthSAML_SP;
