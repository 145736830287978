import _ from 'lodash';
import APIcall from '../utils/APIcall';
import chatActions from './chatActions';
import endpointGenerator from '../utils/endpointGenerator';
import ChannelTypes from '../globals/ChannelTypes';

const chatThunks = {};

chatThunks.loadMessages = (
  channelID,
  channelType,
  loadPaginatedResults = false,
  isAdminChannel = false
) => (dispatch, getState) => {
  const state = getState();
  const pagination = state.getIn(['chatState', 'pagination', channelID]);

  // determinating right endpoint
  let endpoint;
  if (channelType === ChannelTypes.DIRECT_CHANNEL) {
    endpoint = endpointGenerator.genPath('espChat.directChannels');
  } else if (
    channelType === ChannelTypes.SUPPORT_CHANNEL ||
    channelType === ChannelTypes.SCOPED_CHANNEL
  ) {
    if (isAdminChannel) {
      endpoint = endpointGenerator.genPath('espChat.adminChannels');
    } else {
      endpoint = endpointGenerator.genPath('espChat.channels');
    }
  } else {
    throw new Error(`Channel type not found: ${channelType}`);
  }

  const constructedURL = `${endpoint}${channelID}/messages/`;

  // deciding whether to use pagination url or self contructed endpont
  const url =
    pagination && loadPaginatedResults && pagination.get('next')
      ? pagination.get('next')
      : constructedURL;

  dispatch(chatActions.loadMessagesStart(channelID));
  return APIcall.get({
    error(err) {
      dispatch(chatActions.loadMessagesFailure(err, channelID));
    },
    success(res) {
      const { body } = res;
      const messages = body.results;

      // If we have already paginated results, then we don't replace it
      // with a new result set from non-paginated results
      let resPagination;
      if (!loadPaginatedResults && pagination) {
        resPagination = pagination.toJS();
      } else {
        resPagination = {
          next: body.next,
          prev: body.previous,
        };
      }

      dispatch(
        chatActions.loadMessagesSuccess(messages, channelID, resPagination)
      );
    },
    token: true,
    url: url,
  });
};

chatThunks.loadOneMessage = (messageID) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.get({
      error(err) {
        reject(err);
      },
      success({ body }) {
        const message = body;
        const channelId = message.channel;
        dispatch(chatActions.loadOneMessageSuccess(message, channelId));
        resolve(message);
      },
      token: true,
      url: endpointGenerator.genPath('espChat.messages.instance', {
        messageID,
      }),
    });
  });

chatThunks.sendFile = (data, channelID, fileName, isImage = false) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    const finalData = new FormData();
    const imgName = isImage ? fileName : void 0;

    finalData.append('file', data, imgName);
    finalData.append('channel_id', channelID);

    dispatch(chatActions.loadAttachmentStart());

    APIcall.post({
      data: finalData,
      error(err) {
        dispatch(chatActions.loadAttachmentFail());
        reject(err);
      },
      success(res) {
        const { body } = res;
        dispatch(chatActions.loadAttachmentSuccess());
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espChat.messages'),
    });
  });

chatThunks.fetchApiMetaResults = (apiMeta, searchValue) => (dispatch) =>
  new Promise((resolve, reject) => {
    // the apiMeta should tell us what endpoint to hit
    // so we only need to append the tenant
    let url = endpointGenerator.getApiServer();
    url += apiMeta.url;

    // Deciding what method to use
    // yes, this metadata will tell us what HTTP method to use
    const method = apiMeta.url_type ? apiMeta.url_type.toLowerCase() : 'post';

    // We have to replace the url_parameters object
    // with whatever path is required in user_input
    const finalData = _.setWith(apiMeta, apiMeta.user_input, searchValue);
    dispatch(chatActions.loadApiMetaResultsStart());
    APIcall[method]({
      data: finalData.url_parameters,
      error(err) {
        dispatch(chatActions.clarApiMetaResults());
        reject(err);
      },
      success(res) {
        const { body } = res;
        dispatch(chatActions.addApiMetaResults(body));
        resolve(body);
      },
      token: true,
      url: url,
    });
  });

chatThunks.sendApiActionResponse = (selection, message) => () =>
  new Promise((resolve, reject) => {
    // the message tell us the endpoint and method
    const endpoint = message.getIn([
      'metadata',
      'user_input',
      'api_action_button',
      'url',
    ]);
    const finalEndpoint = endpointGenerator.getApiServer() + endpoint;
    const method = message.getIn(
      ['metadata', 'user_input', 'api_action_button', 'url_type'],
      'post'
    );

    const customRequestBody = message.getIn([
      'metadata',
      'user_input',
      'api_action_button',
      'request_body',
    ]);

    let requestData;
    if (customRequestBody) {
      // DEV-7828 BE might send custom request
      requestData = customRequestBody.toJS();
    } else {
      requestData = selection;
    }

    APIcall[method]({
      data: requestData,
      error(err) {
        reject(err);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: finalEndpoint,
    });
  });

chatThunks.confirmClick = (messageID) => () =>
  new Promise((resolve, reject) => {
    // BE needs to know when this has been clicked
    APIcall.post({
      data: {},
      error(err) {
        reject(err);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espChat.messages.instance.click', {
        messageID,
      }),
    });
  });

export default chatThunks;
