const api_chat_v0_1_channels_0f63206f = {
  admin_participants: [11232],
  app_model_context: 'task.Task',
  app_model_details: {
    remote_model: 'incident',
  },
  id: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
  last_message: {
    channel: '0f63206f-0f27-4e38-a229-d67bee02dbc6',
    id: 'b468e255-cabf-4a05-963e-c9a66e1d82e2',
    metadata: {
      user_input: {
        confirm: [
          {
            actual_text: 'Yes',
            bring_always: false,
            default_value: null,
            eid: '8ba865bc-944a-4e04-8d58-f3c452d3d486',
            entity: 'Sys.Confirmation',
            instance: null,
            is_default: false,
            is_global: false,
            is_primary: false,
            is_sub_match: false,
            label: 'Yes',
            label_lemma: null,
            match_origin: null,
            ml_probability: null,
            ml_score: null,
            ml_threshold: null,
            order: null,
            original: 'Yes',
            param_eid: null,
            phrase: null,
            pk: null,
            required: null,
            score: null,
            sub_label: null,
            type: 'custom',
          },
          {
            actual_text: 'No',
            bring_always: false,
            default_value: null,
            eid: '84386fe8-fcd4-4a79-a7ee-9d0b4b724785',
            entity: 'Sys.Confirmation',
            instance: null,
            is_default: false,
            is_global: false,
            is_primary: false,
            is_sub_match: false,
            label: 'No',
            label_lemma: null,
            match_origin: null,
            ml_probability: null,
            ml_score: null,
            ml_threshold: null,
            order: null,
            original: 'No',
            param_eid: null,
            phrase: null,
            pk: null,
            required: null,
            score: null,
            sub_label: null,
            type: 'custom',
          },
        ],
      },
    },
    sys_date_created: '2020-04-15T21:08:48.620290Z',
    text: 'Would you like me to connect you with the right expert to help you?',
    type: 'message',
    user_id: 1,
  },
  last_message_date: '2020-04-15T21:08:48.620290Z',
  message_count: 5,
  name: 'INC0016886: att6',
  participant_count: 12,
  participants: [11232, 1, 63, 85, 4, 1741, 8, 27, 213300, 11393, 1579, 11],
  read_message_count: 5,
  show_to_user: true,
  subscribe_events: true,
  sys_custom_fields: null,
  sys_date_created: '2020-04-15T21:08:36.189377Z',
  type: 'scoped',
};

export default api_chat_v0_1_channels_0f63206f;
