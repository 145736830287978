import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Button, Dimmer, Divider, Image, Loader } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field, reduxForm } from 'redux-form/immutable';
import _ from 'lodash';
import { fromJS } from 'immutable';

// Controller
import ServiceTeamSettingsImageController from '../controllers/ServiceTeamSettingsImageController';

// Molecule
import FormInputFileRef from './FormInputFileRef';

// globals
import { ImageDefaults } from 'esp-assets';

class ServiceTeamSettingsImage extends PureComponent {
  static propTypes = {
    allowDelete: PropTypes.bool,
    deleteTeamImage: PropTypes.func.isRequired,
    images: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        id: PropTypes.number,
        image_480: PropTypes.string,
      })
    ),
    isLoading: PropTypes.bool,
    onAttachmentChanged: PropTypes.func,
    teamID: PropTypes.number,
  };

  static defaultProps = {
    allowDelete: false,
    images: fromJS([
      {
        id: 0,
        image_480: '',
      },
    ]),
    isLoading: false,
    onAttachmentChanged: _.noop,
    teamID: null,
  };

  state = {
    activeDimmer: false,
  };

  handleShow = () => {
    this.setState({
      activeDimmer: true,
    });
  };

  handleHide = () => {
    this.setState({
      activeDimmer: false,
    });
  };

  onAttachmentChange = (e) => {
    const { onAttachmentChanged, teamID } = this.props;

    onAttachmentChanged(teamID, e);
  };

  setInputRef = (ref) => {
    this.inputElement = ref;
  };

  handleTriggerInput = () => {
    this.inputElement.click();
  };

  handleDeleteImage = () => {
    const { images, teamID, deleteTeamImage } = this.props;

    const imageID = images.get('id');

    deleteTeamImage(imageID, teamID);
  };

  render() {
    const { images, allowDelete, isLoading } = this.props;

    const { activeDimmer } = this.state;

    const dimmerContent = allowDelete ? (
      <div>
        <Button content='Delete' onClick={this.handleDeleteImage} />

        <Divider hidden />

        <Button content='Update' onClick={this.handleTriggerInput} />
      </div>
    ) : (
      <div>
        <Button content='Upload' onClick={this.handleTriggerInput} />
      </div>
    );

    const imageDisplay =
      images && images.size > 0
        ? images.getIn([0, 'image_480'])
        : ImageDefaults.PRODUCT_IMAGE;

    return (
      <div className='esp-service-team-images'>
        <div className='image-container'>
          {!allowDelete && images.size > 0 && <Image src={imageDisplay} />}

          {images.size === 0 && <Image src={ImageDefaults.PRODUCT_IMAGE} />}

          {isLoading && (
            <Dimmer active>
              <Loader>{'Loading...'}</Loader>
            </Dimmer>
          )}

          {!isLoading && (
            <Dimmer.Dimmable
              as={Image}
              dimmed={activeDimmer}
              dimmer={{
                active: activeDimmer,
                content: dimmerContent,
              }}
              onMouseEnter={this.handleShow}
              onMouseLeave={this.handleHide}
              size='large'
              src={imageDisplay}
            />
          )}
        </div>
        <Field
          component={FormInputFileRef}
          handleChange={this.onAttachmentChange}
          id='1001'
          name='1001'
          setRef={this.setInputRef}
        />
      </div>
    );
  }
}

export const ServiceTeamSettingsImageTest = ServiceTeamSettingsImage;

export default reduxForm({
  form: 'teamImage',
})(ServiceTeamSettingsImageController(ServiceTeamSettingsImage));
