import buildCompositeController from '../../utils/buildCompositeController';
import getTenant from '../../selectors/getTenant';
// Thunks
import tenantThunks from '../../actions/tenantThunks';
// Util
import TenantImageTypes from '../../utils/TenantImageTypes';

const mapStateToProps = (state) => {
  const tenant = getTenant(state);
  const images = tenant ? tenant.get('images') : void 0;

  let alternateLogo = null;

  if (tenant && tenant.has('images')) {
    alternateLogo =
      tenant
        .get('images')
        .find((img) => img.get('type') === TenantImageTypes.ALTERNATE_LOGO) ||
      void 0;
  }

  return {
    alternateLogo,
    images,
    isLoading: (tenant && tenant.getIn(['images, isLoading'])) || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTenantImages: (type, force) => {
    if (type) {
      dispatch(tenantThunks.getOneTenantImage(type, force))
        .then((res = {}) => {
          if (res.noTenantImagesLoaded) {
            dispatch(tenantThunks.getTenantImages()); // Need to load all tenant images
          }
        })
        .catch(() => {
          // on errors try loading all images again
          dispatch(tenantThunks.getTenantImages());
        });
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
