import React from 'react';
import { debounce, isEmpty, noop, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Form, Grid, Modal, Search } from 'semantic-ui-react';
import { FormikWithSemanticUI } from 'esp-ui-form';
import CaseManagementConditionActions from '../../../../../../globals/CaseManagementConditionActions';
import serviceDepartmentListShape, {
  taskTypeListShape,
} from '../../../../../../v1/components/pages/admin/serviceDepartment/DetailsCaseManagement/PropTypesShapes';
import controller from './Controller';

const optional = (label) => `${label} (optional)`;

const mapValues = (
  immutableElement,
  { key = 'eid', value = 'eid', text = 'name' }
) => ({
  key: immutableElement.get(key),
  text: immutableElement.get(text),
  value: immutableElement.get(value),
});

const mapTargetIntentValues = (
  immutableElement,
  { key = 'eid', value = 'eid', title = 'name' }
) => ({
  key: immutableElement.get(key),
  title: immutableElement.get(title),
  value: immutableElement.get(value),
});

const normalizeOptions = (options = Immutable.List()) =>
  orderBy(options.toArray(), 'text');
const resultRenderer = ({ title }) => <span>{title}</span>;
resultRenderer.propTypes = { title: PropTypes.string.isRequired };

const MIN_OPTIONS_TO_SEARCH = 10;
const TIMEOUT = 1000;

class CreateOrUpdateConditionForm extends React.Component {
  static propTypes = {
    createCondition: PropTypes.func.isRequired, // prop from dispatch
    formInstance: ImmutablePropTypes.map,
    handleChange: PropTypes.func, // prop from Formik
    intentsList: serviceDepartmentListShape,
    isLoadingCategoriesList: PropTypes.bool,
    isLoadingIntentsList: PropTypes.bool,
    isLoadingTaskTypes: PropTypes.bool,
    isLoadingTeamList: PropTypes.bool,
    isSubmitting: PropTypes.bool, // prop from Formik
    loadIntentData: PropTypes.func,
    nextOrderOfExecution: PropTypes.number,
    onClose: PropTypes.func,
    onIntentSearch: PropTypes.func,
    serviceDepartmentCategoriesList: serviceDepartmentListShape,
    serviceDepartmentEID: PropTypes.string.isRequired,
    serviceDepartmentTeamList: serviceDepartmentListShape,
    setFieldValue: PropTypes.func,
    setSubmitting: PropTypes.func, // prop from Formik
    taskTypesList: taskTypeListShape,
    updateCondition: PropTypes.func.isRequired, // prop from dispatch
    values: PropTypes.shape({
      action: PropTypes.string,
      order_at_bottom: PropTypes.bool,
      service_team: PropTypes.string,
      task_category: PropTypes.string,
      task_intent: PropTypes.string,
      task_type: PropTypes.string,
    }).isRequired, // prop from Formik
  };

  static defaultProps = {
    formInstance: null,
    handleChange: noop,
    intentsList: Immutable.List(),
    isLoadingCategoriesList: false,
    isLoadingIntentsList: false,
    isLoadingTaskTypes: false,
    isLoadingTeamList: false,
    isSubmitting: false,
    loadIntentData: noop,
    nextOrderOfExecution: null,
    onClose: noop,
    onIntentSearch: noop,
    serviceDepartmentCategoriesList: Immutable.List(),
    serviceDepartmentTeamList: Immutable.List(),
    setFieldValue: noop,
    setSubmitting: noop,
    taskTypesList: Immutable.List(),
  };

  state = {
    isSearchingIntent: false,
    taskIntentValue: '',
  };

  componentDidMount() {
    const { formInstance, loadIntentData } = this.props;

    if (formInstance && formInstance.get('task_intent')) {
      loadIntentData(formInstance.get('task_intent')).then((data) => {
        const intent = data.find(
          (intent) => intent.eid === formInstance.get('task_intent')
        );
        if (intent) {
          this.setTaskIntentValue(intent.name);
        }
      });
    }
  }

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleCheckBoxChange = (evt, { name, checked }) => {
    const { handleChange: formikOnChange } = this.props;
    formikOnChange(evt, { name, value: checked });
  };

  handleSubmit = () => {
    const {
      createCondition,
      formInstance,
      nextOrderOfExecution,
      onClose,
      serviceDepartmentEID,
      setSubmitting,
      updateCondition,
      values,
    } = this.props;

    setSubmitting(true);

    // since we do a PUT , we need to make sure that all original values go to the server when editing

    const orderOfExecution = values.order_at_bottom ? nextOrderOfExecution : 0;

    const initialValues = this.isNew()
      ? {
          order_of_execution: orderOfExecution, // only new conditions should send this value
          service_department: serviceDepartmentEID,
        }
      : formInstance.toJS();
    const dispatchFunction = this.isNew() ? createCondition : updateCondition;

    // it's really important the order on the object merge below, we need to override the initial values(I)
    // with the new values(N) {...I, ...N}
    dispatchFunction({
      ...initialValues,
      ...values,
    });

    setSubmitting(false);
    onClose();
  };

  debounceOnIntentSearch = debounce(
    (value) => {
      const { onIntentSearch } = this.props;
      onIntentSearch(value);
      this.setState({ isSearchingIntent: true });
    },
    TIMEOUT,
    { trailing: true }
  );

  handleTargetIntentChange = (e, { value }) => {
    this.setTaskIntentValue(value);
    if (value.trim()) {
      this.debounceOnIntentSearch(value);
    }
    this.setState({ isSearchingIntent: false });
  };

  handleTargetIntentSelect = (e, { result }) => {
    const { setFieldValue } = this.props;
    this.setState({
      isSearchingIntent: false,
      taskIntentValue: result.title,
    });
    setFieldValue('task_intent', result.value, false);
  };

  isNew = () => {
    const { formInstance } = this.props;
    return !formInstance || isEmpty(formInstance.get('eid'));
  };

  setTaskIntentValue = (value) => this.setState({ taskIntentValue: value });

  render() {
    const {
      handleChange,
      intentsList,
      isLoadingCategoriesList,
      isLoadingIntentsList,
      isLoadingTaskTypes,
      isLoadingTeamList,
      isSubmitting,
      serviceDepartmentCategoriesList,
      serviceDepartmentTeamList,
      taskTypesList,
      values,
    } = this.props;

    const { isSearchingIntent, taskIntentValue } = this.state;

    const actions = [
      {
        key: 'change_service_team',
        text: 'Change Service Team',
        value: CaseManagementConditionActions.REASSIGN_SERVICE_TEAM,
      },
      {
        key: 'resolve_task',
        text: 'Resolve Task',
        value: CaseManagementConditionActions.RESOLVE_TASK,
      },
    ];

    const serviceDepartmentCategoriesOptions = normalizeOptions(
      serviceDepartmentCategoriesList.map(mapValues)
    );
    const serviceDepartmentTeamsOptions = normalizeOptions(
      serviceDepartmentTeamList.map(mapValues)
    );
    const targetIntentOptions = normalizeOptions(
      intentsList.map(mapTargetIntentValues)
    );
    const taskTypesOptions = normalizeOptions(
      taskTypesList.map((taskType) => mapValues(taskType, { text: 'label' }))
    );

    return (
      <>
        <Modal.Content className='withFooter'>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form.Dropdown
                  fluid
                  label='Action'
                  name='action'
                  onChange={handleChange}
                  options={actions}
                  search={actions.length > MIN_OPTIONS_TO_SEARCH}
                  selection
                  value={values.action}
                />
                <Form.Dropdown
                  disabled={!serviceDepartmentTeamsOptions.length}
                  fluid
                  label={optional('Service Team')}
                  loading={isLoadingTeamList}
                  name='service_team'
                  onChange={handleChange}
                  options={serviceDepartmentTeamsOptions}
                  placeholder='Choose a service team'
                  search={
                    serviceDepartmentTeamsOptions.length > MIN_OPTIONS_TO_SEARCH
                  }
                  selection
                  value={values.service_team}
                />
                <Form.Field>
                  <label>{optional('Target Intent')}</label>
                  <Search
                    loading={isLoadingIntentsList}
                    onResultSelect={this.handleTargetIntentSelect}
                    onSearchChange={this.handleTargetIntentChange}
                    placeholder='Search a target intent'
                    resultRenderer={resultRenderer}
                    results={targetIntentOptions}
                    showNoResults={
                      !isLoadingIntentsList &&
                      isSearchingIntent &&
                      Boolean(taskIntentValue.trim())
                    }
                    value={taskIntentValue}
                  />
                </Form.Field>
                {this.isNew() && (
                  <Form.Checkbox
                    checked={values.order_at_bottom}
                    label='Set order at bottom (top is default)'
                    name='order_at_bottom'
                    onChange={this.handleCheckBoxChange}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Dropdown
                  disabled={!taskTypesOptions.length}
                  fluid
                  label={optional('Affects Task Type')}
                  loading={isLoadingTaskTypes}
                  name='task_type'
                  onChange={handleChange}
                  options={taskTypesOptions}
                  placeholder='Choose a task type'
                  search={taskTypesOptions.length > MIN_OPTIONS_TO_SEARCH}
                  selection
                  value={values.task_type}
                />
                <Form.Dropdown
                  disabled={!serviceDepartmentCategoriesOptions.length}
                  fluid
                  label={optional('Category')}
                  loading={isLoadingCategoriesList}
                  name='task_category'
                  onChange={handleChange}
                  options={serviceDepartmentCategoriesOptions}
                  placeholder='Choose a category'
                  search={
                    serviceDepartmentCategoriesOptions.length >
                    MIN_OPTIONS_TO_SEARCH
                  }
                  selection
                  value={values.task_category}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'right' }}>
          <Button
            basic
            content={'Cancel'}
            onClick={this.handleCancel}
            type='button'
          />
          <Button
            content={`${this.isNew() ? 'Add' : 'Update'} condition`}
            disabled={isSubmitting}
            onClick={this.handleSubmit}
            primary
            type='button'
          />
        </Modal.Actions>
      </>
    );
  }
}

const immutableValueOrDefault = (immutableMap, key, defaultValue = null) => {
  const getFn = typeof key === 'object' ? 'getIn' : 'get';

  return (immutableMap && immutableMap[getFn](key)) || defaultValue;
};
const FormikComponent = FormikWithSemanticUI({
  enableReinitialize: true,
  mapPropsToValues: ({ formInstance }) => ({
    action: immutableValueOrDefault(
      formInstance,
      'action',
      CaseManagementConditionActions.REASSIGN_SERVICE_TEAM
    ),
    order_at_bottom: immutableValueOrDefault(
      formInstance,
      'order_at_bottom',
      false
    ),
    service_team: immutableValueOrDefault(formInstance, [
      'service_team',
      'eid',
    ]),
    task_category: immutableValueOrDefault(formInstance, [
      'task_category',
      'eid',
    ]),
    task_intent: immutableValueOrDefault(formInstance, 'task_intent'),
    task_type: immutableValueOrDefault(formInstance, ['task_type', 'eid']),
  }),
})(CreateOrUpdateConditionForm);
export default controller(FormikComponent);
