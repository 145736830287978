import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  askToReviseButtonText: {
    default: '',
    required: false,
    type: 'string',
  },
  editCommentButtonText: {
    default: '',
    required: false,
    type: 'string',
  },
  inputValue: {
    arrayShape: [
      {
        comment: 'Ask to Revise intro',
        map: {
          required: false,
          type: 'string',
        },
        value: {
          required: false,
          type: 'string',
        },
      },
      {
        comment: 'Edit user intro',
        map: {
          required: true,
          type: 'string',
        },
        value: {
          required: false,
          type: 'string',
        },
      },
      {
        comment: 'Message approved',
        map: {
          required: true,
          type: 'string',
        },
        value: {
          required: false,
          type: 'string',
        },
      },
    ],
    default: [],
    type: 'arrayOf',
  },
  modalButtonText: {
    required: true,
    type: 'string',
  },
  modalChatImage: {
    default: '',
    required: true,
    type: 'string',
  },
  modalChatText: {
    default: '',
    required: true,
    type: 'string',
  },
  modalTitle: {
    required: true,
    type: 'string',
  },
  placeholderAskEditTextModal: {
    default: '',
    required: false,
    type: 'string',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'editComment01',
  },
});

// Object Definition used by blobifier
const EditCommentBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Edit Comment',
  templates: ['editComment01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, EditCommentBlockDefinition, TypeDefaults };
