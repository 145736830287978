import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import { ImageDefaults } from 'esp-assets';
import uiPathGenerator from '../../utils/uiPathGenerator';
import TextHighlight from '../atoms/TextHighlight';

import EspListItem from './EspListItem';

const MAX_DESCRIPTION_LEN = 45;

class EquipmentSearchResult extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    result: ImmutablePropTypes.map.isRequired,
    routeToResult: PropTypes.string.isRequired,
    routeToResultIncludesQ: PropTypes.bool.isRequired,
    userInput: PropTypes.string.isRequired,
  };

  static defaultProps = {
    active: false,
  };

  render() {
    const {
      active,
      onClick,
      result,
      routeToResult,
      routeToResultIncludesQ,
      userInput,
    } = this.props;

    const name = result.get('display_value');

    const nameEl = <TextHighlight term={userInput} text={name} />;

    let description = result.get('description') || '';
    description = _.truncate(description, MAX_DESCRIPTION_LEN);

    const descriptionEl = <TextHighlight term={userInput} text={description} />;

    const image = result.get('image') || ImageDefaults.PRODUCT_IMAGE;

    const urlToResult = uiPathGenerator.genPath(routeToResult, {
      equipmentID: result.get('pk'),
    });
    let toResult = urlToResult;

    if (routeToResultIncludesQ) {
      toResult = {
        pathname: urlToResult,
        search: `?q=${userInput}`,
      };
    }

    return (
      <EspListItem
        active={active}
        as={Link}
        content={descriptionEl}
        image={image}
        isSearchResult
        name={nameEl}
        onClick={onClick}
        to={toResult}
      />
    );
  }
}

export default EquipmentSearchResult;
