import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Transition } from 'semantic-ui-react';

import ChatMessageBubble from './ChatMessageBubble';
import FileExtensionIcon from '../FileExtensionIcon';

import convertBytes from '../../../utils/convertBytes';

const rule = (props) => {
  const { isMe } = props;

  return {
    display: 'block',
    height: 'auto',
    lineHeight: 0,
    marginLeft: isMe ? 'auto' : 0,
    marginRight: !isMe ? 'auto' : 0,
    maxWidth: '100%',
  };
};

class ChatMessageFile extends PureComponent {
  static propTypes = {
    attachment: PropTypes.string.isRequired,
    attachmentSize: PropTypes.number,
    className: PropTypes.string.isRequired,
    hideAvatar: PropTypes.bool,
    isDownloadable: PropTypes.bool,
    isFirstMessage: PropTypes.bool.isRequired,
    isLastMessage: PropTypes.bool.isRequired,
    isMe: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onDownload: PropTypes.func,
  };

  static defaultProps = {
    attachmentSize: 0,
    hideAvatar: false,
    isDownloadable: true,
    onClick: void 0,
    onDownload: void 0,
  };

  render() {
    const {
      attachment,
      attachmentSize,
      className,
      hideAvatar,
      isDownloadable,
      isFirstMessage,
      isLastMessage,
      isMe,
      onClick,
      onDownload,
    } = this.props;

    const placement = {
      isFirstMessage,
      isLastMessage,
      isMe,
    };

    const urlNoParams = attachment
      ? attachment.substring(0, attachment.indexOf('?')) || attachment
      : 'no attachment';
    const fileName = urlNoParams.split('/').pop();
    let fileExtension = attachment
      ? (/[^./\\]*$/.exec(urlNoParams) || [''])[0]
      : '?'; // Detect the extension - https://github.com/silverwind/file-extension
    fileExtension = fileExtension.length > 4 ? '' : fileExtension;
    const decodedFileName = decodeURI(fileName);

    return (
      <Transition animation={'scale'} transitionOnMount>
        <div
          className={className}
          data-component='ChatMessageFile'
          data-testid='chat-message-file'
        >
          <ChatMessageBubble
            {...placement}
            download={isDownloadable ? attachment : void 0}
            fileName={decodedFileName}
            hideAvatar={hideAvatar}
            link
            onClick={onClick}
            onDownload={onDownload}
          >
            <>
              <FileExtensionIcon extension={fileExtension} />
              {decodedFileName}
              {attachmentSize ? (
                <span
                  style={{
                    fontSize: '.75em',
                  }}
                >
                  {` (${convertBytes(attachmentSize)})`}
                </span>
              ) : null}
            </>
          </ChatMessageBubble>
        </div>
      </Transition>
    );
  }
}

export default createComponent(rule, ChatMessageFile, [
  'attachment',
  'attachmentSize',
  'fileSize',
  'hideAvatar',
  'isDownloadable',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'onClick',
  'onDownload',
]);
