import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  addPermissionGroup: {
    error: null,
    isLoading: false,
  },

  permissionGroups: {
    error: null,
    isLoading: false,
    values: null,
  },

  removePermissionGroup: {
    error: null,
    isLoading: false,
    productPermissionGroupID: null, // what product permission group are we removing?
  },

  savePermissionGroup: {
    error: null,
    isLoading: false,
    productPermissionGroupID: null, // what product permission group are we saving?
  },
});

const onAddProductPermissionGroupSuccess = (state, productPermissionGroup) => {
  state = state.setIn(['addPermissionGroup', 'isLoading'], false);

  let values = state.getIn(['permissionGroups', 'values']);
  values = values.unshift(productPermissionGroup);

  state = state.setIn(['permissionGroups', 'values'], values);

  return state;
};

const onToggleProductFromPermissionGroup = (
  state,
  productPermissionGroupID,
  productID
) => {
  const permissionGroups = state.getIn(['permissionGroups', 'values']);

  const permissionGroupIndex = permissionGroups.findIndex(
    (g) => g.get('id') === productPermissionGroupID
  );
  const permissionGroup = permissionGroups.get(permissionGroupIndex);

  let productIDs = permissionGroup.get('productIDs');

  if (productIDs.includes(productID)) {
    productIDs = productIDs.filterNot((item) => item === productID);
  } else {
    productIDs = productIDs.push(productID);
  }

  state = state.setIn(
    ['permissionGroups', 'values', permissionGroupIndex, 'productIDs'],
    productIDs
  );

  return state;
};

const onRemoveProductPermissionGroupSuccess = (
  state,
  productPermissionGroupID
) => {
  state = state.set(
    'removePermissionGroup',
    INITIAL_STATE.get('removePermissionGroup')
  );

  let permissionGroups = state.getIn(['permissionGroups', 'values']);

  permissionGroups = permissionGroups.filterNot(
    (g) => g.get('id') === productPermissionGroupID
  );

  state = state.setIn(['permissionGroups', 'values'], permissionGroups);

  return state;
};

/**
 * Updates the id of saved product permission group
 * @param state The Redux state
 * @param productPermissionGroupID ID before the save
 * @param newProductPermissionGroupID ID after the save
 */
const onSaveProductPermissionGroupSuccess = (
  state,
  oldProductPermissionGroupID,
  productPermissionGroup
) => {
  state = state.set(
    'savePermissionGroup',
    INITIAL_STATE.get('savePermissionGroup')
  );

  const permissionGroups = state.getIn(['permissionGroups', 'values']);

  const permissionGroupIndex = permissionGroups.findIndex(
    (g) => g.get('id') === oldProductPermissionGroupID
  );

  state = state.setIn(
    ['permissionGroups', 'values', permissionGroupIndex],
    productPermissionGroup
  );

  return state;
};

const productPermissionsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_PRODUCT_PERMISSION_GROUPS_START:
      return state.setIn(['permissionGroups', 'isLoading'], true);

    case actionTypes.GET_PRODUCT_PERMISSION_GROUPS_SUCCESS:
      return state
        .setIn(['permissionGroups', 'isLoading'], false)
        .setIn(
          ['permissionGroups', 'values'],
          fromJS(action.wiredPermissionGroups)
        );

    case actionTypes.RESET_PRODUCT_PERMISSION_GROUPS:
      return state.setIn(['permissionGroups', 'values'], fromJS(null));

    case actionTypes.GET_PRODUCT_PERMISSION_GROUPS_FAIL:
      return state
        .setIn(['permissionGroups', 'isLoading'], false)
        .setIn(['permissionGroups', 'error'], fromJS(action.error));

    case actionTypes.ADD_PRODUCT_PERMISSION_GROUP_START:
      return state.setIn(['addPermissionGroup', 'isLoading'], true);

    case actionTypes.ADD_PRODUCT_PERMISSION_GROUP_SUCCESS:
      return onAddProductPermissionGroupSuccess(
        state,
        fromJS(action.productPermissionGroup)
      );

    case actionTypes.ADD_PRODUCT_PERMISSION_GROUP_FAIL:
      return state
        .setIn(['addPermissionGroup', 'isLoading'], false)
        .setIn(['addPermissionGroup', 'error'], fromJS(action.error));

    case actionTypes.TOGGLE_PRODUCT_FROM_PERMISSION_GROUP:
      return onToggleProductFromPermissionGroup(
        state,
        action.productPermissionGroupID,
        action.productID
      );

    case actionTypes.REMOVE_PRODUCT_PERMISSION_GROUP_START:
      return state
        .set(
          'removePermissionGroup',
          INITIAL_STATE.get('removePermissionGroup')
        )
        .setIn(['removePermissionGroup', 'isLoading'], true)
        .setIn(
          ['removePermissionGroup', 'productPermissionGroupID'],
          action.productPermissionGroupID
        );

    case actionTypes.REMOVE_PRODUCT_PERMISSION_GROUP_SUCCESS:
      return onRemoveProductPermissionGroupSuccess(
        state,
        action.productPermissionGroupID
      );

    case actionTypes.REMOVE_PRODUCT_PERMISSION_GROUP_FAIL:
      return state
        .set(
          'removePermissionGroup',
          INITIAL_STATE.get('removePermissionGroup')
        )
        .setIn(['removePermissionGroup', 'error'], fromJS(action.error));

    case actionTypes.SAVE_PRODUCT_PERMISSION_GROUP_START:
      return state
        .set('savePermissionGroup', INITIAL_STATE.get('savePermissionGroup'))
        .setIn(['savePermissionGroup', 'isLoading'], true)
        .setIn(
          ['savePermissionGroup', 'productPermissionGroupID'],
          action.productPermissionGroupID
        );

    case actionTypes.SAVE_PRODUCT_PERMISSION_GROUP_SUCCESS:
      return onSaveProductPermissionGroupSuccess(
        state,
        action.oldProductPermissionGroupID,
        fromJS(action.productPermissionGroup)
      );

    case actionTypes.SAVE_PRODUCT_PERMISSION_GROUP_FAIL:
      return state
        .set('savePermissionGroup', INITIAL_STATE.get('savePermissionGroup'))
        .setIn(['savePermissionGroup', 'error'], fromJS(action.error));

    default:
      return state;
  }
};

export default productPermissionsReducer;
