const modalConfig = {
  change: {
    BUTTON_CANCEL: 'Cancel',
    BUTTON_OK: 'Ok',
    CONTENT:
      'Changing your email will disconnect Gmail and will disable all email notifications until the new email is configured.',
    TITLE: 'Change Primary Email',
  },
  unauthorize: {
    BUTTON_CANCEL: 'Cancel',
    BUTTON_OK: 'Disconnect',
    CONTENT:
      'Unauthorizing Gmail will disable all email notifications from Barista.',
    TITLE: 'Unauthorize Gmail',
  },
};

const TYPE_CONFIRM = {
  CHANGE_ACCOUNT: 'change',
  UNAUTHORIZE_ACCOUNT: 'unauthorize',
};

const AUTHORIZE_LEGEND =
  'To authorize or change your Gmail account you must save first by clicking on the button below.';

export default modalConfig;
export { TYPE_CONFIRM, AUTHORIZE_LEGEND };
