import React, { Component } from 'react';
import { Button, Form, Grid, Message, Modal } from 'semantic-ui-react';
import { isNil, noop } from 'lodash';
import Immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import { FormikWithSemanticUI } from 'esp-ui-form';
import { Boundaries } from 'cascara-middleware';
import PropTypes from 'prop-types';
import AdminSubstateController from '../../controllers/AdminSubstatesController';
import { CaseTypes, SubtaskTypes } from '../../../globals/CaseTypeOptions';
import caseFilterOptions from '../../../globals/caseFilterOptions';

class AdminSubstates extends Component {
  static propTypes = {
    addSubstate: PropTypes.func,
    editSubstate: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isLoadingTaskStatuses: PropTypes.bool,
    isLoadingTaskTypes: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    limit: PropTypes.number,
    loadTaskStatuses: PropTypes.func,
    loadTaskTypes: PropTypes.func,
    message: PropTypes.string,
    offset: PropTypes.number,
    open: PropTypes.bool,
    rowIndex: PropTypes.number,
    serviceTeamEID: PropTypes.string,
    setModalState: PropTypes.func.isRequired,
    substateID: PropTypes.number,
    taskStatuses: ImmutableProps.listOf(
      ImmutableProps.shape({
        eid: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
    taskTypes: ImmutableProps.listOf(
      ImmutableProps.shape({
        eid: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    touched: PropTypes.objectOf(PropTypes.bool).isRequired,
    values: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    addSubstate: noop,
    editSubstate: noop,
    isLoading: false,
    isLoadingTaskStatuses: false,
    isLoadingTaskTypes: false,
    limit: 10,
    loadTaskStatuses: noop,
    loadTaskTypes: noop,
    message: '',
    offset: 1,
    open: false,
    rowIndex: null,
    serviceTeamEID: null,
    substateID: null,
    taskStatuses: Immutable.List(),
    taskTypes: Immutable.List(),
  };

  componentDidMount() {
    const { loadTaskStatuses, loadTaskTypes, taskStatuses, taskTypes } =
      this.props;

    if (taskStatuses && taskStatuses.isEmpty()) {
      loadTaskStatuses();
    }

    if (taskTypes && taskTypes.isEmpty()) {
      loadTaskTypes();
    }
  }

  handleBlur = (e) => {
    const { handleBlur } = this.props;
    handleBlur(e, e.target);
  };

  handleCancel = () => {
    const { handleReset } = this.props;

    handleReset();
    this.handleClose();
  };

  handleClose = () => {
    const { setModalState } = this.props;
    setModalState(false);
  };

  handleOpen = () => {
    const { setModalState, handleReset } = this.props;
    handleReset();
    setModalState(true);
  };

  handleSubmit = (e) => {
    const {
      addSubstate,
      editSubstate,
      rowIndex,
      serviceTeamEID,
      substateID,
      values,
      limit,
      offset,
    } = this.props;

    e.preventDefault();

    if (!isNil(substateID) && !isNil(rowIndex) && serviceTeamEID) {
      editSubstate(substateID, rowIndex, {
        ...values,
        label: values.sub_status,
        service_team: serviceTeamEID,
      }).then(() => {
        this.handleClose();
      });
    } else if (serviceTeamEID) {
      addSubstate(
        {
          ...values,
          active: true,
          label: values.sub_status,
          service_team: serviceTeamEID,
        },
        limit,
        offset
      ).then(() => {
        this.handleClose();
      });
    }
  };

  render() {
    const {
      errors,
      handleChange,
      handleReset,
      isLoading,
      isLoadingTaskStatuses,
      isLoadingTaskTypes,
      isValid,
      message,
      open,
      substateID,
      taskStatuses,
      taskTypes,
      touched,
      values,
    } = this.props;

    const content = 'Add Substate';

    const formatLabel = (status, label) => {
      // espressive service request (created in barista)
      if (SubtaskTypes.SERVICE_REQUEST === status) {
        return `(ESP) ${label}`;
      }
      // snow service request (created in snow or external)
      if (CaseTypes.SNOW_SERVICE_REQUEST === status) {
        return `(SNOW) ${label}`;
      }
      return label;
    };

    const taskStatusesOptions = !taskStatuses.isEmpty()
      ? caseFilterOptions.reduce((options, option) => {
          const taskStatus = taskStatuses.find(
            (taskStatus) => taskStatus.get('status') === option.value
          );
          if (taskStatus && !taskStatus.isEmpty()) {
            return options.concat({
              key: taskStatus.get('eid'),
              text: taskStatus.get('label'),
              value: taskStatus.get('eid'),
            });
          }
          return options;
        }, [])
      : [];

    const taskTypesOptions = taskTypes
      .sort((a, b) => {
        if (a.get('label') > b.get('label')) {
          return 1;
        }
        if (a.get('label') < b.get('label')) {
          return -1;
        }
        return 0;
      })
      .map((task) => ({
        key: task.get('eid'),
        text: formatLabel(task.get('type'), task.get('label')),
        value: task.get('eid'),
      }));

    const submitButtonLabel = !substateID ? 'Add' : 'Update';

    return (
      <Boundaries>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              {message && <Message content={message} info />}
            </Grid.Column>
            <Grid.Column textAlign='right' width={4}>
              <Modal
                as={Form}
                className='scrolling'
                closeIcon={
                  <div
                    className='item close'
                    onClick={handleReset}
                    onKeyPress={handleReset}
                  >
                    {'Close'}
                  </div>
                }
                closeOnDimmerClick={false}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                open={open}
                size='small'
                trigger={
                  <Button basic content={content} onClick={this.handleOpen} />
                }
              >
                <Modal.Header content={content} style={{ fontSize: '1em' }} />
                <Modal.Content className='withFooter'>
                  <Form.Dropdown
                    fluid
                    label='Task Type'
                    loading={isLoadingTaskTypes}
                    name='task_type'
                    onChange={handleChange}
                    options={taskTypesOptions.toArray()}
                    placeholder='Select'
                    required
                    selection
                    value={values.task_type}
                  />
                  <Form.Dropdown
                    fluid
                    label='State'
                    loading={isLoadingTaskStatuses}
                    name='parent'
                    onChange={handleChange}
                    options={taskStatusesOptions}
                    placeholder='Select'
                    required
                    selection
                    value={values.parent}
                  />
                  <Form.Input
                    error={errors && touched.substate && errors.substate}
                    label='Substate'
                    name='sub_status'
                    onBlur={this.handleBlur}
                    onChange={handleChange}
                    placeholder='Substate'
                    required
                    value={values.sub_status}
                  />
                  <Form.Input
                    error={errors && touched.order && errors.order}
                    label='Order'
                    max={1000000}
                    min={1}
                    name='order'
                    onBlur={this.handleBlur}
                    onChange={handleChange}
                    placeholder='Example: 10'
                    required
                    type='number'
                    value={values.order}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    basic
                    content='Cancel'
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={this.handleCancel}
                    type='button'
                  />
                  <Button
                    content={submitButtonLabel}
                    disabled={!isValid || isLoading}
                    loading={isLoading}
                    onClick={this.handleSubmit}
                    primary
                    type='button'
                  />
                </Modal.Actions>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Boundaries>
    );
  }
}

const AdminSubstateTest = AdminSubstates;
export { AdminSubstateTest };

export default AdminSubstateController(
  FormikWithSemanticUI({
    enableReinitialize: true,
    mapPropsToValues: ({ values }) => ({
      order:
        (values &&
          Object.prototype.hasOwnProperty.call(values, 'order') &&
          values.order) ||
        '',

      parent:
        (values &&
          Object.prototype.hasOwnProperty.call(values, 'parent') &&
          values.parent) ||
        '',
      sub_status:
        (values &&
          Object.prototype.hasOwnProperty.call(values, 'sub_status') &&
          values.sub_status) ||
        '',
      task_type:
        (values &&
          Object.prototype.hasOwnProperty.call(values, 'task_type') &&
          values.task_type) ||
        '',
    }),
    validate: (values) => {
      const errors = {};
      if (!values.order) {
        errors.order = 'Cannot be empty';
      } else if (isNaN(values.order)) {
        errors.order = 'Order should be a number';
      } else if (!Number.isInteger(Number(values.order))) {
        errors.order = 'Order should be a integer';
      } else if (Number(values.order) < 0) {
        errors.order = 'Order should be greater or equal to 0';
      } else if (Number(values.order) > 1000000) {
        errors.order = 'Order should be lower than 1000000';
      }
      if (!values.sub_status) {
        errors.sub_status = 'Cannot be empty';
      }
      return errors;
    },
  })(AdminSubstates)
);
