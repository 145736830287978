import immutable from 'immutable';

import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import TenantImageTypes from '../../../../v1/utils/TenantImageTypes';

import getTenant from '../../../../v1/selectors/getTenant';

const mapStateToProps = (state) => {
  const tenant = getTenant(state);
  const tenantImages =
    tenant && tenant.get('images') ? tenant.get('images') : immutable.List();

  // Filter for alternate and background img type
  let background = null;

  if (tenant && tenant.has('images')) {
    background =
      tenantImages.find(
        (img) => img.get('type') === TenantImageTypes.BACKGROUND
      ) || void 0;
  }

  return {
    background,
  };
};

export default buildCompositeController(mapStateToProps);
