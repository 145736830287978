import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// molecules
import DelegateDropdown from './DelegateDropdown';
import UserImageContainer from './UserImageContainer';
// Controller
import HeaderDelegateSelectController from '../controllers/HeaderDelegateSelectController';

class HeaderDelegateSelect extends PureComponent {
  static propTypes = {
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: null,
  };

  render() {
    const { userId } = this.props;

    if (!userId) {
      return null;
    }

    const styles = {
      trigger: {
        display: 'inline-block',
        textAlign: 'right',
        width: '2.25em',
      },
    };

    const dropdownTrigger = (
      <UserImageContainer
        className={'circular'}
        shape='circular'
        style={styles.trigger}
        userId={userId}
      />
    );

    return (
      <div className='header-delegate-select'>
        <DelegateDropdown floating trigger={dropdownTrigger} />
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
HeaderDelegateSelect = HeaderDelegateSelectController(HeaderDelegateSelect);

export default HeaderDelegateSelect;
