const WebSocketEventTypes = {
  CARDUPDATE: 'cardfeed.EspCard',
  CHANNEL: 'chat.Channel',
  CHANNEL_READ_COUNT: 'chat.ChannelReadCount',
  CHATMESSAGE: 'chat.Message',
  DELETE_CHAT_CHANNEL: 'delete.chat.Channel',
  DELETE_CHAT_MESSAGE: 'delete.chat.Message',
  DESKTOP_NOTIFICATION: 'notification.desktop',
  DPC_PRODUCT_UPDATE_COMPLETE: 'DpcProductUpdateComplete',
  REFRESH: 'RbacRefresh',
  RESTART_SUPPORT_CHANNEL: 'barista.GoToChannel',
  RTE: 'rte',
  TASKUPDATE: 'task.Task',
  TENANTUPDATE: 'tenant.update',
  TYPING: 'rte.Typing',
};

export default WebSocketEventTypes;
