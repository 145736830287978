import { useReducer } from 'react';
import LinkedList from './LinkedList';

const actions = {
  HIDE_WIZARD_MODAL: 'HIDE_WIZARD_MODAL',
  SHOW_WIZARD_MODAL: 'SHOW_WIZARD_MODAL',
  UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_CURRENT_STEP: {
      return {
        ...state,
        list: action.list,
      };
    }
    case actions.HIDE_WIZARD_MODAL: {
      return {
        ...state,
        isOpen: false,
        list: new LinkedList(),
      };
    }
    case actions.SHOW_WIZARD_MODAL: {
      return {
        ...state,
        isOpen: true,
        list: action.list,
      };
    }
    default:
      return state;
  }
};

const useIntegrationSetupWizard = (steps) => {
  const initialState = {
    isOpen: false,
    list: new LinkedList(),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleBackOnClick = () => {
    state.list.setPreviousNodeAsCurrent();
    dispatch({
      list: state.list,
      type: actions.UPDATE_CURRENT_STEP,
    });
  };
  const handleNextOnClick = (values) => {
    state.list.setNextNodeAsCurrent(values);
    dispatch({
      list: state.list,
      type: actions.UPDATE_CURRENT_STEP,
    });
  };

  const handleCancelOnClick = () => {
    dispatch({
      type: actions.HIDE_WIZARD_MODAL,
    });
  };

  const handleOnOpenOnClick = () => {
    const list = new LinkedList();
    list.addMultipleNodes(steps);
    list.setCurrentNode(list.getHeadNode());

    dispatch({
      list,
      type: actions.SHOW_WIZARD_MODAL,
    });
  };

  const handleFinishOnClick = (values, onClose) => {
    state.list.setTailNodeFormValues(values);

    dispatch({
      type: actions.HIDE_WIZARD_MODAL,
    });

    onClose(state.list.getNodesArray());
  };

  return {
    handleBackOnClick,
    handleCancelOnClick,
    handleFinishOnClick,
    handleNextOnClick,
    handleOnOpenOnClick,
    state,
  };
};

export default useIntegrationSetupWizard;
