import { useCallback, useState } from 'react';
import fetchPerformanceList, {
  archivePerformanceSummary,
  escalateProgressByUser,
  loadPerformanceDetail,
} from './performanceAPICalls/fetchPerformanceList';
import OnboardingActivityStates from '../../../../../globals/OnboardingActivityStates';
import DepartingEmployeeStatus from '../../../../../globals/DepartingEmployeeStatus';

const limitByPage = 24;
const ERROR_STATUS = 400;
const usePerformanceList = (
  limit = limitByPage,
  priority = '-user__start_date',
  FILTER_BY = DepartingEmployeeStatus
) => {
  const [listCount, setListCount] = useState(0);
  const [performanceList, setPerformanceList] = useState([]);
  const [performanceDetail, setPerformanceDetail] = useState({});
  const [url, setUrl] = useState({
    next: '',
    previous: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetail, setLoadingDetail] = useState(false);
  const [isUserIDEscalating, setUserIDEscalating] = useState(null);

  const loadPerformanceList = useCallback(
    async (
      page = 1,
      searchTerm = '',
      filterBy = FILTER_BY,
      orderBy = priority
    ) => {
      setLoading(true);
      const offset = (page - 1) * limit;
      const response = await fetchPerformanceList({
        filterBy,
        limit,
        offset,
        orderBy,
        page,
        searchTerm,
      });

      if (response) {
        setPerformanceList(response?.results);
        setUrl({
          next: response?.next,
          previous: response?.previous,
        });
        setListCount(response?.count);
      }

      setLoading(false);
      setLoadingDetail(false);
      // isFetchingData.current = false;
    },
    [limit, priority, FILTER_BY, setLoading, setLoadingDetail]
  );

  const archivePerformance = useCallback(
    async (progressSummaryID) => {
      setLoading(true);
      const response = await archivePerformanceSummary({
        progressSummaryID,
      });

      if (response) {
        const listUpdated = performanceList.filter(
          (item) => item.id !== progressSummaryID
        );
        setPerformanceList(listUpdated);
      }

      setLoading(false);
    },
    [performanceList]
  );

  const loadPerformance = useCallback(async (userID, type) => {
    setLoadingDetail(true);
    const response = await loadPerformanceDetail(userID, type);

    if (response) {
      const { progress, templates, progressSummary } = response;
      const newProgressList = progress.map((item) => {
        let template = templates;
        if (Array.isArray(templates)) {
          template = templates.find((temp) => temp.id === item.todo);
        }
        if (template) {
          item.template = template;
        } else {
          item.template = null;
        }
        return item;
      });
      newProgressList.push({
        count: progressSummary?.scheduled_state_count || 0,
        id: 'scheduled',
        state: OnboardingActivityStates.SCHEDULED,
      });
      response.progress = newProgressList;
      setPerformanceDetail(response);
    }
    setLoading(false);
    setLoadingDetail(false);
  }, []);

  const escalateProgress = async (userID) => {
    setUserIDEscalating(userID);
    const response = await escalateProgressByUser({
      userID,
    });

    if (response?.status === ERROR_STATUS) {
      // TODO: TBD what should happen in case of an error
    }

    setUserIDEscalating(null);
  };

  return {
    archivePerformance,
    escalateProgress,
    isLoading,
    isLoadingDetail,
    isTemplatesLoading: isLoading,
    isUserIDEscalating,
    limitByPage,
    listCount,
    loadPerformance,
    loadPerformanceList,
    performanceDetail,
    performanceList,
    url,
  };
};

export default usePerformanceList;
