import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

const blockAttrsDefinition = _.merge({}, BlockBase, {
  addFromMessage: {
    default: false,
    type: 'bool',
  },
  addNewWithModal: {
    default: false,
    type: 'bool',
  },
  buttonAddResponseText: {
    default: '',
    required: true,
    type: 'string',
  },
  buttonShowBottomAfter: {
    default: 0,
    type: 'number',
  },
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputValue: {
    objectShape: {
      map: {
        requred: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  isEditable: {
    default: false,
    type: 'bool',
  },
  nonEmptyLineRequired: {
    default: false,
    type: 'bool',
  },
  readOnly: {
    default: false,
    type: 'bool',
  },
  showAddResponse: {
    default: false,
    type: 'bool',
  },
  template: {
    default: 'addResponses01',
  },
});

const AddResponsesBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Add Responses',
  templates: ['addResponses01'],
};

const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

export { propTypes as default, AddResponsesBlockDefinition, TypeDefaults };
