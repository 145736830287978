const api_catalog_v0_1_pdpc_category_top = {
  count: 2,
  next: null,
  previous: null,
  results: [
    {
      children: [5, 4],
      description: 'Software',
      eid: '350e38f8-5300-49e2-9eab-5eae4e9b4d45',
      id: 3,
      images: [],
      name: 'Software',
      num_products: 24,
      parent: null,
      parent_id: null,
      path: 'software',
      slug: 'software',
      status: 'ACTIVE',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2017-06-13T16:54:08.584837Z',
      sys_date_updated: '2017-06-13T16:54:08.597203Z',
      sys_dpc_parent: null,
      sys_updated_by: null,
      url: 'http://dpc.esp:8000/api/catalog/v0.1/category/3/',
    },
    {
      children: [6, 2],
      description: 'Hardware',
      eid: 'a7ca3dc6-4381-4757-88f1-5d6503712824',
      id: 1,
      images: [],
      name: 'Hardware',
      num_products: 109,
      parent: null,
      parent_id: null,
      path: 'hardware',
      slug: 'hardware',
      status: 'ACTIVE',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2017-06-13T16:53:28.029320Z',
      sys_date_updated: '2017-06-13T16:53:28.040548Z',
      sys_dpc_parent: null,
      sys_updated_by: null,
      url: 'http://dpc.esp:8000/api/catalog/v0.1/category/1/',
    },
  ],
};

export default api_catalog_v0_1_pdpc_category_top;
