import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, Icon } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
// V2
import EspModal from '../../../../../v2/components/display/EspModal';
// Utils
import injectIntlToController from '../../../../utils/injectIntlToController';
// Controller
import IntlKeyEditorController from './IntlKeyEditorController';
// Components
import IntlKeyForm from '../IntlKeyForm';

class IntlKeyEditor extends PureComponent {
  static propTypes = {
    // intl                     : intlShape.isRequired,

    blobKeyPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),

    closeIntlKeyEditor: PropTypes.func.isRequired,

    currentSelectedTaskId: PropTypes.string,

    formTranslations: ImmutableProps.map,

    isLoadingIntlKeys: PropTypes.bool,

    isOpen: PropTypes.bool,

    isSavingTranslations: PropTypes.bool,

    loadLocalizationsForKey: PropTypes.func.isRequired,

    loadSupportedLanguages: PropTypes.func.isRequired,

    proposedTranslationKey: PropTypes.string,

    saveExistingTranslations: PropTypes.func.isRequired,

    saveNewTranslation: PropTypes.func.isRequired,
    supportedLanguages: ImmutableProps.list,
    translationKey: PropTypes.string,
    translationKeyResults: ImmutableProps.list,
  };

  static defaultProps = {
    blobKeyPath: [],
    currentSelectedTaskId: '',
    formTranslations: Immutable.Map(),
    isLoadingIntlKeys: false,
    isOpen: false,
    isSavingTranslations: false,
    proposedTranslationKey: '',
    supportedLanguages: Immutable.List(),
    translationKey: null,
    translationKeyResults: Immutable.List(),
  };

  state = {
    hasBeenTranslated: false,
  };

  static getDerivedStateFromProps = (props) => {
    const { translationKey, isLoadingIntlKeys, translationKeyResults } = props;

    const hasBeenTranslated = Boolean(
      translationKey && !isLoadingIntlKeys && translationKeyResults.size
    );

    return {
      hasBeenTranslated: hasBeenTranslated,
    };
  };

  componentDidMount() {
    const { supportedLanguages, loadSupportedLanguages } = this.props;

    if (!supportedLanguages.size) {
      loadSupportedLanguages();
    }
  }

  componentDidUpdate(prevProps) {
    const { translationKey, loadLocalizationsForKey } = this.props;

    if (prevProps.translationKey !== translationKey && translationKey) {
      loadLocalizationsForKey(translationKey);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = true;

  handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { closeIntlKeyEditor } = this.props;
    closeIntlKeyEditor();
  };

  handleSave = () => {
    const { hasBeenTranslated } = this.state;
    const {
      blobKeyPath,
      currentSelectedTaskId,
      formTranslations,
      proposedTranslationKey,
      saveExistingTranslations,
      saveNewTranslation,
      supportedLanguages,
      translationKey,
      translationKeyResults,
    } = this.props;

    // save new English translation first (API requirement)
    if (!hasBeenTranslated) {
      // I know about Immutable.Map.get(<key>, <default>) but i like the legibility of this better
      const translationText = formTranslations.get('en')
        ? formTranslations.get('en')
        : translationKey;
      saveNewTranslation(
        'en',
        proposedTranslationKey,
        translationText,
        blobKeyPath,
        currentSelectedTaskId
      );
    } else {
      // save all the translations
      saveExistingTranslations(
        translationKey,
        supportedLanguages,
        translationKeyResults,
        formTranslations,
        blobKeyPath
      );
    }
  };

  setRef = (ref) => {
    this.refModal = ref;
  };

  render() {
    const {
      // intl,
      isLoadingIntlKeys,
      isOpen,
      isSavingTranslations,
      translationKey,
    } = this.props;

    // intl;

    const actions = (
      <Button
        basic
        content={'Save'}
        disabled={!translationKey || isLoadingIntlKeys || isSavingTranslations}
        loading={isLoadingIntlKeys || isSavingTranslations}
        onClick={this.handleSave}
        size={'large'}
      />
    );

    return (
      <EspModal
        actions={actions}
        className='esp-workflow'
        closeIcon={<Icon name='close' />}
        content={<IntlKeyForm />}
        contentPadding='1em'
        header='Edit Translation Key'
        onClose={this.handleClose}
        open={isOpen}
        ref={this.setRef}
      />
    );
  }
}

const IntlKeyEditorTest = IntlKeyEditor;

// eslint-disable-next-line no-class-assign -- DEV-1526
IntlKeyEditor = IntlKeyEditorController(IntlKeyEditor);
export { IntlKeyEditorTest };
export default injectIntlToController(IntlKeyEditor);
