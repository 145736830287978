import { createSelector } from 'reselect';

const getFormValues = (formValues) => formValues;

const getTotalAnnouncementRecipents = createSelector(
  [getFormValues],
  (announcementFormValues) => {
    let total = 0;

    // we have to count the user_count for each group
    if (announcementFormValues && announcementFormValues.get('groups')) {
      announcementFormValues.get('groups').forEach((group) => {
        total += group.get('user_count');
      });
    }

    return total;
  }
);

export default getTotalAnnouncementRecipents;
