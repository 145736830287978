import toastNotificationsActions from '../../actions/toastNotificationsActions';
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const toasts = state.get('toastNotifications');

  return {
    toasts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeToast(toastID) {
    dispatch(toastNotificationsActions.removeToast(toastID));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
