import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Grid,
  Loader,
  Menu,
  Search,
  Segment,
} from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import { debounce } from 'lodash';
// Molecule
import ServiceDetailTeamsTableList from '../../../molecules/ServiceDetailTeamsTableList';
import MenuPagination from '../../../molecules/MenuPagination';
import ServiceDepartmentCreateTeamModal from '../../../molecules/ServiceDepartmentCreateTeamModal';
// utils
import browserHistory from '../../../../utils/browserHistory';
import uiPathGenerator from '../../../../utils/uiPathGenerator';
// Controller
import ServiceDetailTeamController from '../../../controllers/ServiceDetailTeamController';

const SEARCH_DELAY = 300;
const MIN_CHARACTERS = 2;

class AdminServiceDetailTeams extends Component {
  static propTypes = {
    /** Delete one Team */
    deleteOneTeam: PropTypes.func.isRequired,
    /** Department name */
    departmentSelected: PropTypes.string,
    /** Loading state */
    isLoadingTeamList: PropTypes.bool,
    /** Searching state */
    isSearching: PropTypes.bool,
    /** Limit item per page */
    limitByPage: PropTypes.number.isRequired,
    /** max count of Team in the list*/
    listCount: PropTypes.number,

    /** List Loader */

    loadTeamList: PropTypes.func.isRequired,

    /** Team id in process to be deleted */
    loadingDeleteID: PropTypes.number,
    /** Router data */
    location: PropTypes.shape({
      query: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
        orderBy: PropTypes.any,
        page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }).isRequired,
    /** URL Parameters */
    params: PropTypes.shape({
      departmentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    resetDepartmentTeamListSearch: PropTypes.func.isRequired,
    /** Launch search API call */
    searchTeam: PropTypes.func.isRequired,
    /** searchTerm */
    searchTerm: PropTypes.string,
    /** Callback to set the search term */
    setSearchTerm: PropTypes.func.isRequired,
    /** Team list */
    teamList: ImmutableProps.list,
  };

  static defaultProps = {
    departmentSelected: '',
    isLoadingTeamList: false,
    isSearching: false,
    listCount: 0,
    loadingDeleteID: null,
    params: {},
    searchTerm: '',
    teamList: immutable.List(),
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    orderBy: this.props.location.query.orderBy,
    searchValue: '',
  };

  componentDidMount() {
    const {
      limitByPage,
      loadTeamList,
      location: { query },
      params: { departmentID },
      searchTerm,
    } = this.props;

    const page = query.page || 1;
    const { orderBy } = query;

    if (departmentID && !searchTerm) {
      loadTeamList(departmentID, page, limitByPage, orderBy);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      limitByPage,
      loadTeamList,
      location: { query },
      params: { departmentID },
    } = this.props;
    const { orderBy } = this.state;

    const nextPage = nextProps.location.query.page;
    const nextPageOrderBy = nextProps.location.query.orderBy;

    if (
      !nextProps.searchTerm &&
      (nextPage !== query.page || nextPageOrderBy !== orderBy)
    ) {
      this.setState({
        orderBy: nextPageOrderBy,
      });
      loadTeamList(departmentID, nextPage, limitByPage, nextPageOrderBy);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Note : Complete that
    const {
      params: { departmentID },
      teamList,
      departmentSelected,
      isLoadingTeamList,
      loadingDeleteID,
      location: { query },
      searchTerm,
    } = this.props;

    const { searchValue } = this.state;

    const page = query.page || 1;
    const nextPage = nextProps.location.query.page;

    if (departmentID !== nextProps.params.departmentID) {
      return true;
    }

    if (departmentSelected !== nextProps.departmentSelected) {
      return true;
    }

    if (
      (nextProps.teamList && !nextProps.teamList.equals(teamList)) ||
      (!nextProps.teamList && teamList)
    ) {
      return true;
    }

    if (nextProps.isLoadingTeamList !== isLoadingTeamList) {
      return true;
    }

    if (nextProps.searchTerm !== searchTerm) {
      return true;
    }

    if (nextProps.loadingDeleteID !== loadingDeleteID) {
      return true;
    }

    if (nextPage !== page) {
      return true;
    }

    if (searchValue !== nextState.searchValue) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      limitByPage,
      searchTerm,
      searchTeam,
      params: { departmentID },
      location: { query },
      loadTeamList,
    } = this.props;

    const page = query.page || 1;
    const nextPage = nextProps.location.query.page;
    const nextPageOrderBy = nextProps.location.query.orderBy;

    if (
      nextProps.searchTerm !== searchTerm ||
      (nextProps.searchTerm !== '' && nextPage !== page)
    ) {
      let newPage = nextPage;
      if (searchTerm === '') {
        // New search - redirect to page 1 which will perform the search on page 1
        newPage = 1;
        browserHistory.push({
          pathname: uiPathGenerator.genPath(
            'admin.serviceDepartment.detail.teams',
            {
              departmentID,
            }
          ),
          search: `?${queryString.stringify(
            Object.assign({}, window.location.query, {
              page: 1,
            })
          )}`,
        });
      }

      if (
        nextProps.searchTerm !== searchTerm &&
        nextProps.searchTerm.length >= MIN_CHARACTERS
      ) {
        searchTeam(departmentID, newPage, limitByPage, nextPageOrderBy);
      } else if (nextProps.searchTerm === '') {
        // Search has been reseted
        loadTeamList(departmentID, nextPage, limitByPage, nextPageOrderBy);
      }
    }
  }

  componentWillUnmount() {
    const { resetDepartmentTeamListSearch } = this.props;
    resetDepartmentTeamListSearch();
  }

  debouncedSearch = debounce(
    (value) => {
      const { setSearchTerm } = this.props;
      setSearchTerm(value);
    },
    SEARCH_DELAY,
    { trailing: true }
  );

  handleStartSearch = (e, { value }) => {
    this.setSearchValue(value);
    this.debouncedSearch(value);
  };

  setSearchValue = (searchValue) => this.setState({ searchValue });

  render() {
    const {
      deleteOneTeam,
      departmentSelected,
      isLoadingTeamList,
      isSearching,
      limitByPage,
      listCount,
      loadingDeleteID,
      location: { query },
      params: { departmentID },
      teamList,
    } = this.props;

    const { searchValue } = this.state;

    const triggerModal = <Button basic content='Create new' />;

    const maxPage = Math.ceil(listCount / limitByPage) || 1;

    return (
      <Segment attached='bottom'>
        <Menu borderless secondary>
          <Menu.Item fitted>
            <Search
              loading={isSearching}
              onSearchChange={this.handleStartSearch}
              placeholder='Search...'
              showNoResults={false}
              value={searchValue}
            />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <ServiceDepartmentCreateTeamModal
                departmentId={departmentID}
                departmentSelected={departmentSelected}
                trigger={triggerModal}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {isLoadingTeamList && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}

        <ServiceDetailTeamsTableList
          deleteOneTeam={deleteOneTeam}
          departmentId={departmentID}
          departmentSelected={departmentSelected}
          loadingDeleteID={loadingDeleteID}
          tableList={teamList}
        />

        {maxPage > 1 && (
          <Grid padded textAlign='center'>
            <Grid.Column>
              <MenuPagination
                currentPage={Number(query.page) || 1}
                numberPages={maxPage}
                pageByQuery
                pathname='admin.serviceDepartment.detail.teams'
                query={query}
                urlParameter={{
                  departmentID,
                }}
              />
            </Grid.Column>
          </Grid>
        )}

        <Divider hidden />
      </Segment>
    );
  }
}

const AdminServiceDetailTeamsTest = AdminServiceDetailTeams;

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminServiceDetailTeams = ServiceDetailTeamController(AdminServiceDetailTeams);
export { AdminServiceDetailTeamsTest };
export default AdminServiceDetailTeams;
