const api_workflow_v0_1_workflow_38 = {
  allowed_time: 0,
  assigned_notification_policy: null,
  cancel_action: 'NOOP',
  card_template: null,
  eid: '6aad6456-b24a-4cd3-90a8-86a26e4ed01e',
  experience_link_type: 'AUTHENTICATED',
  experience_start: false,
  id: 38,
  max_depth: 2,
  name: 'Parental leave approval',
  notification_content: null,
  owner_notification_policy: null,
  post_condition_group: {
    condition_entry: [
      {
        conditions: null,
        description: 'Condition f489f045-7360-445a-926d-a75bd70da94d',
        eid: '4f7b36c2-a7a9-43ff-9779-9220e05353d2',
        id: 198,
        object_instances: null,
        ordering: 0,
        success_workflow: null,
        success_workflow_task:
          'http://tenant1.esp/api/workflow/v0.1/workflowtask/173/',
        sys_created_by: null,
        sys_custom_fields: null,
        sys_updated_by: 'kharma@release.qa.espressive.com',
        url:
          'https://release.qa.espressive.com/api/workflow/v0.1/condition/198/',
      },
    ],
    eid: '0471bb67-e289-4a86-b462-cb24a8a55051',
    failure_workflow: null,
    failure_workflow_task: null,
    id: 201,
    name: 'Condition Group 3145a91e-8ece-47f6-ba69-7fec90ff05d9',
    sys_created_by: null,
    sys_custom_fields: null,
    sys_updated_by: null,
    url:
      'https://release.qa.espressive.com/api/workflow/v0.1/workflowconditionset/201/',
  },
  pre_condition_group: null,
  request_assigned_to: 'self',
  request_owner: null,
  request_requested_for: null,
  requested_for_notification_policy: null,
  sla_threshold: 0,
  sys_created_by: null,
  sys_custom_fields: null,
  sys_date_created: '2019-07-26T18:26:39.531101Z',
  sys_date_updated: '2019-07-26T18:26:39.531072Z',
  sys_updated_by: null,
  tags: '',
  trigger: null,
  url: 'https://release.qa.espressive.com/api/workflow/v0.1/workflow/38/',
};

export default api_workflow_v0_1_workflow_38;
