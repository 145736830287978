import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { noop } from 'lodash';

// Pages
import WorkflowPage from '../pages/flow/WorkflowPage';

// Controllers
import OnboardWorkflowContextController from '../controllers/OnboardWorkflowContextController';

import uiPathGenerator from '../../utils/uiPathGenerator';
import { WorkflowProvider } from '../../utils/WorkflowContext';
import BodyClassName from '../../../globals/BodyClassName';

class OnboardWorkflowContext extends PureComponent {
  /**
   * @param {Object} location Router's parameters
   * @param {Object} params URL parameters: requestID
   */
  static propTypes = {
    closeWorkflow: PropTypes.func,
    currentUser: ImmutablePropTypes.map,
    doHelloActions: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }).isRequired,
    onWorkflowTaskChange: PropTypes.func,
    params: PropTypes.shape({
      requestID: PropTypes.string,
    }).isRequired,
    workflowName: PropTypes.string,
  };

  static defaultProps = {
    closeWorkflow: noop,
    currentUser: null,
    doHelloActions: noop,
    onWorkflowTaskChange: noop,
    workflowName: '',
  };

  componentDidMount() {
    const { doHelloActions } = this.props;
    doHelloActions();
  }

  handleOnWorkflowTaskChange = (error, workflowRequest) => {
    const { location, onWorkflowTaskChange } = this.props;

    if (error) {
      return;
    }

    const isComplete = workflowRequest.current_workflowtask === null;
    let nextPath;

    if (!isComplete) {
      const workflowID = workflowRequest.current_workflow;
      const taskID = workflowRequest.current_workflowtask.id;
      const requestId = workflowRequest.id;
      const path = location.pathname;

      nextPath = uiPathGenerator.replacePath('onboardStep', path, {
        requestId,
        taskID,
        workflowID,
      });
    }

    onWorkflowTaskChange(isComplete, nextPath);
  };

  render() {
    const {
      closeWorkflow,
      currentUser,
      location,
      params,
      workflowName,
    } = this.props;

    // wait until currentUser is available
    if (!currentUser) {
      return null;
    }

    const workflowRequestID = Number(params.requestID);

    // this will cause WorkflowPage to re mount every time url changes,
    // this is intentional
    // const key = `${workflowID}/${workflowTaskID}/${workflowRequestID}`;
    const { query } = location;
    return (
      <BodyClassName className='onboard-workflow'>
        <WorkflowProvider value={query}>
          <WorkflowPage
            // key={key}
            onCloseWorkflow={closeWorkflow}
            onMoveNextWorkflowTask={this.handleOnWorkflowTaskChange}
            onMovePrevWorkflowTask={this.handleOnWorkflowTaskChange}
            workflowRequestID={workflowRequestID}
          >
            <Helmet title={workflowName} />
          </WorkflowPage>
        </WorkflowProvider>
      </BodyClassName>
    );
  }
}

const OnboardWorkflowContextTest = OnboardWorkflowContext;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardWorkflowContext = OnboardWorkflowContextController(
  OnboardWorkflowContext
);
export { OnboardWorkflowContextTest };
export default OnboardWorkflowContext;
