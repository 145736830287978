import {
  APPROVAL_MANAGER_VALUE,
  CASE_TYPE,
  EXTERNAL_APPROVAL_VALUE,
} from '../../v2/components/functional/CaseCard/caseConstants';
import { first, last } from 'lodash';
import {
  getApprovalSelectedServiceTeamId,
  getCasesSelectedServiceTeamId,
  getMyTeams,
} from './getSelectedServiceTeam';
// Filters
import getCurrentUser, { getCurrentUserId } from './getCurrentUser';
import Immutable, { fromJS } from 'immutable';

import CaseStates from '../globals/CaseStates';
import { createSelector } from 'reselect';
import FilterTitles from '../globals/FilterTitles';
import FilterTypes from '../globals/FilterTypes';
// Globals
import { optionValues } from '../globals/caseFilterOptions';
import { ALL_SERVICE_TEAMS } from '../../v2/components/functional/CaseFeedTeamPicker/utils';

const FILTER = {
  APPROVALS: 'approvalsFilter',
  CASES: 'casesFilter',
};

const getTaskEntities = (state) => state.getIn(['entities', 'tasks']);

const getCasesFilters = (state) => state.get(FILTER.CASES);

const getFilterOptions = (filter, filterSection, filterType) => (state) =>
  state
    .get(filter)
    .find((section) => section.get('title') === filterSection)
    .get('filters')
    .find((filter) => filter.get('key') === filterType)
    .get('options');

const getCheckedOption = (filter, filterSection, filterType) => (state) =>
  state
    .get(filter)
    .find((section) => section.get('title') === filterSection)
    .get('filters')
    .find((filter) => filter.get('key') === filterType)
    .get('options')
    .find((op) => op.get('checked'));

const getCheckedOptions = (filter, filterSection, filterType) => (state) =>
  state
    .get(filter)
    .find((section) => section.get('title') === filterSection)
    .get('filters')
    .find((filter) => filter.get('key') === filterType)
    .get('options')
    .filter((op) => op.get('checked'));

const getSubstates = (state) =>
  state.getIn(['caseMgmt', 'taskSubStatuses', 'list']);

const getCaseFeed = createSelector(
  [
    getCurrentUserId,
    getTaskEntities,
    getCasesSelectedServiceTeamId,
    getCasesFilters,
    getSubstates,
    getMyTeams,
  ],
  (
    currentUserId = null,
    taskEntities = Immutable.Map(),
    selectedServiceTeam = null,
    selectedFilters = Immutable.Map(),
    substatesArray,
    myTeams = Immutable.Map()
  ) => {
    let casesList = taskEntities.toList();

    const getSubStatesEid = (findSubState) =>
      substatesArray.reduce((list, subState) => {
        if (subState.get('label') === findSubState) {
          list.push(subState.get('eid'));
        }
        return list;
      }, []);

    const filterByServiceTeam = (task) =>
      task?.getIn(['service_team', 'id']) === selectedServiceTeam;

    const filterByStatus = (cases) => {
      const vanillaCases = cases.toJS();
      let filteredCasesList = [];

      const taskState = selectedFilters?.get('state');

      let uncheckedStateCounter = 0;

      if (!taskState) {
        // default state
        filteredCasesList = vanillaCases.filter(
          (task) => task?.status === CaseStates.OPEN
        );
      } else {
        if (taskState) {
          for (const [
            key,
            { value, substateRadio, substates },
          ] of taskState.entries()) {
            if (value) {
              const isSpecificSelected =
                substateRadio === optionValues.SPECIFIC_SUBSTATE;
              const isAllSelected = substateRadio === optionValues.ALL;
              const isWithoutSubStateSelected =
                substateRadio === optionValues.WITHOUT_SUBSTATE;
              const isEmptySubstates = substates?.length === 0 || !substates;

              if (isAllSelected || !substateRadio) {
                // default state
                filteredCasesList.push(
                  ...vanillaCases.filter((task) => task?.status === key)
                );
              } else {
                if (isWithoutSubStateSelected) {
                  filteredCasesList.push(
                    ...vanillaCases.filter(
                      (task) =>
                        task?.sub_status === null && task?.status === key
                    )
                  );
                }

                if (
                  (isSpecificSelected && isEmptySubstates) ||
                  (isSpecificSelected && !substates)
                ) {
                  filteredCasesList.push(
                    ...vanillaCases.filter(
                      (task) => task?.sub_status && task?.status === key
                    )
                  );
                }

                if (isSpecificSelected && !isEmptySubstates) {
                  const isAllSubstateSelected = substates?.find(
                    (substate) => substate === optionValues.ALL_SUBSTATES
                  );

                  if (isAllSubstateSelected) {
                    filteredCasesList.push(
                      ...vanillaCases.filter(
                        (task) => task?.sub_status && task?.status === key
                      )
                    );
                  } else {
                    const matchedSubStateEids = [];
                    // some times substates names are duplicated, so we need to get all eids that matched the name
                    substates.forEach((substateName) => {
                      const foundSubstates = getSubStatesEid(substateName);

                      if (foundSubstates?.length > 0) {
                        matchedSubStateEids.push(...foundSubstates);
                      }
                    });

                    vanillaCases.forEach((task) => {
                      const wasTaskSubStateFound =
                        matchedSubStateEids.findIndex(
                          (matchedSubStateEid) =>
                            // task?.sub_status is substate eid, that's why we need to compare eids
                            task?.sub_status === matchedSubStateEid &&
                            task?.status === key
                        );

                      if (wasTaskSubStateFound > -1) {
                        filteredCasesList.push(task);
                      }
                    });
                  }
                }
              }
            } else {
              uncheckedStateCounter++;
            }
          }
        }
      }

      const allStatesAreUnchecked = uncheckedStateCounter === taskState?.size;

      if (allStatesAreUnchecked) {
        // return unfiltered cases by state
        return cases;
      }

      return fromJS(filteredCasesList);
    };

    const filterByType = (cases) => {
      const taskType = selectedFilters?.get('taskType');

      const isSpecificTypeSelected =
        taskType &&
        taskType !== optionValues.ALL &&
        taskType !== optionValues.SPECIFIC_TYPE;

      if (isSpecificTypeSelected) {
        return cases.filter((task) =>
          taskType.find((type) => type === task.get('type'))
        );
      }

      return cases;
    };

    const filterByUserType = (cases, type) => {
      const filterValue = selectedFilters?.get(
        type === 'owner' ? 'assignedTo' : 'requestedBy'
      );

      if (filterValue && Array.isArray(filterValue)) {
        const vanillaCases = cases.toJS();
        const newCases = [];
        filterValue.forEach(({ userID }) => {
          newCases.push(
            ...vanillaCases.filter((task) => task[type] === userID)
          );
        });

        return fromJS(newCases);
      } else {
        switch (filterValue) {
          case optionValues.ME:
            return cases.filter((task) => task?.get(type) === currentUserId);

          case optionValues.UNASSIGNED:
            return cases.filter((task) => !task?.get(type));

          default:
            break;
        }
      }

      return cases;
    };

    const filterByLocation = (cases) => {
      const locationValue = selectedFilters?.get('location');

      const isLocationSelected =
        locationValue &&
        Array.isArray(
          selectedFilters.get('location')?.ancestry?.ancestry_id_list
        );

      if (isLocationSelected) {
        if (
          selectedFilters.get('location')?.ancestry?.ancestry_id_list?.length >
          0
        ) {
          cases = cases.filter((task) => {
            return task
              ?.getIn(['location_ancestry', 'ancestry_id_list'])
              ?.some((location) =>
                selectedFilters
                  .get('location')
                  ?.ancestry?.ancestry_id_list.includes(location)
              );
          });

          return cases;
        }
      }

      return cases;
    };

    const filterByTimePeriod = (cases) => {
      const timePeriod = selectedFilters?.get('timePeriod');

      if (timePeriod && timePeriod !== optionValues.ALL) {
        return cases.filter((task) => {
          const caseTime = new Date(task.get(optionValues.LAST_UPDATED));
          const threshHoldDate = new Date(Date.now() - timePeriod);
          return caseTime > threshHoldDate;
        });
      }

      return cases;
    };

    const filterByServiceTeamIfMyAllCasesIsNotSelected =
      selectedServiceTeam !== ALL_SERVICE_TEAMS;

    if (filterByServiceTeamIfMyAllCasesIsNotSelected) {
      casesList = casesList.filter(filterByServiceTeam);
    } else {
      casesList = casesList.filter((task) => {
        const currentTaskTeamID = task.getIn(['service_team', 'id']);
        let found = false;

        myTeams.forEach((team) => {
          if (team.get('id') === currentTaskTeamID) {
            found = true;
          }
        });

        return found;
      });
    }

    casesList = filterByStatus(casesList);
    casesList = filterByType(casesList);
    casesList = filterByUserType(casesList, 'owner'); // assigntedTo
    casesList = filterByUserType(casesList, 'author'); // requestedBy
    casesList = filterByLocation(casesList);

    casesList = filterByTimePeriod(casesList);

    return casesList;
  }
);

const getApprovalFeed = createSelector(
  [
    getCurrentUser,
    getTaskEntities,
    getFilterOptions(FILTER.APPROVALS, FilterTitles.SHOW, FilterTypes.STATE),
    getCheckedOption(
      FILTER.APPROVALS,
      FilterTitles.TIME_PERIOD,
      FilterTypes.TIME_PERIOD
    ),
    getApprovalSelectedServiceTeamId,
    getMyTeams,
  ],
  (
    currentUser = Immutable.Map(),
    taskEntities = Immutable.Map(),
    statusFiltersOptions = Immutable.List(),
    timeFilterOptionChecked = Immutable.Map(),
    selectedServiceTeam = null,
    myTeams = Immutable.List()
  ) => {
    let res = taskEntities.toList();

    /**
     * Filtering by selected service team
     */

    const getApprovalsByTeam = (approvals) => {
      if (selectedServiceTeam && selectedServiceTeam > APPROVAL_MANAGER_VALUE) {
        return approvals.reduce((accum, task) => {
          // approvers should not be able to approve their approval requests, so we need to remove their approval tasks
          if (
            task.getIn(['service_team', 'id']) === selectedServiceTeam &&
            task.get('type') === CASE_TYPE.APPROVAL_TEAM &&
            Boolean(task.get('author') !== currentUser.get('id'))
          ) {
            return accum.push(task);
          }
          return accum;
        }, Immutable.List());
      }

      return approvals;
    };

    const getApprovalsForManager = (approvals) => {
      if (selectedServiceTeam === APPROVAL_MANAGER_VALUE) {
        // approvers should not be able to approve their approval requests, so we need to remove their approval tasks
        return approvals.reduce((accum, task) => {
          if (
            task.get('type') === CASE_TYPE.APPROVAL_MANAGER &&
            Boolean(task.get('author') !== currentUser.get('id'))
          ) {
            return accum.push(task);
          }
          return accum;
        }, Immutable.List());
      }
      return approvals;
    };

    const getExternalApprovals = (approvals) => {
      if (selectedServiceTeam === EXTERNAL_APPROVAL_VALUE) {
        // approvers should not be able to approve their approval requests, so we need to remove their approval tasks
        return approvals.reduce((accum, task) => {
          if (
            task.get('type') === CASE_TYPE.EXTERNAL_APPROVALS &&
            Boolean(task.get('author') !== currentUser.get('id')) &&
            Boolean(task.getIn(['owner_info', 'id']) === currentUser.get('id'))
          ) {
            return accum.push(task);
          }
          return accum;
        }, Immutable.List());
      }
      return approvals;
    };

    const isValidTeam = (currentTeamID) => {
      if (!currentTeamID) {
        return false;
      }
      let found = false;
      myTeams.forEach((team) => {
        if (team.get('id') === currentTeamID) {
          found = true;
        }
      });

      return found;
    };

    const getAllApprovals = (approvals) => {
      if (!selectedServiceTeam) {
        // approvers should not be able to approve their approval requests, so we need to remove their approval tasks
        return approvals.reduce((accum, task) => {
          const currentTeamID = task.getIn(['service_team', 'id']);

          const isManager = currentUser.get('is_manager');

          const isValidApproval = () => {
            const isNotMyApprovalRequest = Boolean(
              task.get('author') !== currentUser.get('id')
            );

            const isAssignedToMe = Boolean(
              task.getIn(['owner_info', 'id']) === currentUser.get('id')
            );

            switch (task.get('type')) {
              case CASE_TYPE.APPROVAL_MANAGER:
                return (
                  isNotMyApprovalRequest &&
                  task.get('type') === CASE_TYPE.APPROVAL_MANAGER &&
                  isManager
                );
              case CASE_TYPE.APPROVAL_TEAM:
                return (
                  isNotMyApprovalRequest &&
                  isValidTeam(currentTeamID) &&
                  task.get('type') === CASE_TYPE.APPROVAL_TEAM
                );
              case CASE_TYPE.EXTERNAL_APPROVALS:
                return isAssignedToMe;

              default:
                return false;
            }
          };

          if (isValidApproval()) {
            return accum.push(task);
          }

          return accum;
        }, Immutable.List());
      }
      return approvals;
    };

    res = getApprovalsByTeam(res);
    res = getApprovalsForManager(res);
    res = getAllApprovals(res);
    res = getExternalApprovals(res);

    /**
     * Filtering by status in the list filter
     */
    const filterStatusValues = {
      [CaseStates.PENDING_APPROVAL]: statusFiltersOptions.getIn([0, 'checked']),
      [CaseStates.APPROVED]: statusFiltersOptions.getIn([1, 'checked']),
      [CaseStates.REJECTED]: statusFiltersOptions.getIn([2, 'checked']),
    };

    res = res.filter((task) => filterStatusValues[task.get('status')]);

    /**
     * Filtering time
     */
    const tpValueChecked =
      timeFilterOptionChecked && timeFilterOptionChecked.get('value');
    if (tpValueChecked !== 'all') {
      res = res.filter((task) => {
        const caseTime = new Date(task.get('sys_date_updated'));
        const threshHoldDate = new Date(Date.now() - tpValueChecked);
        return caseTime > threshHoldDate;
      });
    }

    return res;
  }
);

const getSortedCaseFeed = createSelector(
  [getCaseFeed, getCasesFilters],
  (casesList = Immutable.List(), selectedFilters = Immutable.Map()) => {
    const sortCasesAlphabetically = (unsortedList, sortAttr, ascending) => {
      const softByBreadcrumbs = [];
      const refinementMap = {
        [optionValues.AUTHOR]: 'author_info',
        [optionValues.ASSIGNED]: 'owner_info',
      };

      let sortByAttribute =
        sortAttr === optionValues.LOCATION_NAME ? 'location_name' : sortAttr;
      let sortByRefinement;
      if (Object.prototype.hasOwnProperty.call(refinementMap, sortAttr)) {
        sortByAttribute = refinementMap[sortAttr];
        sortByRefinement = sortAttr.split('__').pop();

        softByBreadcrumbs.push(sortByAttribute, sortByRefinement);
      } else {
        softByBreadcrumbs.push(sortByAttribute);
      }

      const nullsList = unsortedList.filterNot((item) =>
        item.get(sortByAttribute)
      );
      const notNullsList = unsortedList.filter((item) =>
        item.get(sortByAttribute)
      );

      const sortedList = nullsList.concat(
        notNullsList.sortBy((item) => item.getIn(softByBreadcrumbs))
      );

      return ascending ? sortedList : sortedList.reverse();
    };

    const sortCasesByTime = (
      unsortedList = Immutable.List(),
      sortAttr,
      ascending
    ) => {
      const sortedList = unsortedList.sort((itemA, itemB) => {
        const aDate = new Date(itemA.get(sortAttr));
        const bDate = new Date(itemB.get(sortAttr));

        if (aDate < bDate) {
          return -1;
        }
        if (aDate > bDate) {
          return 1;
        }
        if (aDate === bDate) {
          return 0;
        }

        return 0;
      });

      return ascending ? sortedList : sortedList.reverse();
    };

    const filterBySortBy = (cases) => {
      const sortBy = selectedFilters?.get('sortBy');

      const isAscending = true;

      if (!sortBy) {
        return sortCasesByTime(cases, optionValues.LAST_UPDATED);
      }

      if (!sortBy?.type && sortBy?.order === optionValues.DESCENDING) {
        // when ortBy?.order is set but not the type
        return sortCasesByTime(cases, optionValues.LAST_UPDATED);
      }
      if (!sortBy?.type && sortBy?.order === optionValues.ASCENDING) {
        return sortCasesByTime(cases, optionValues.LAST_UPDATED, isAscending);
      }

      if (
        sortBy?.type === optionValues.LAST_UPDATED &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        return sortCasesByTime(cases, optionValues.LAST_UPDATED, isAscending);
      }
      if (
        sortBy?.type === optionValues.LAST_UPDATED &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        return sortCasesByTime(cases, optionValues.LAST_UPDATED);
      }

      if (
        sortBy?.type === optionValues.DATE_CREATED &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        return sortCasesByTime(cases, optionValues.DATE_CREATED, isAscending);
      }
      if (
        sortBy?.type === optionValues.DATE_CREATED &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        return sortCasesByTime(cases, optionValues.DATE_CREATED);
      }

      if (
        sortBy?.type === optionValues.ASSIGNED &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        return sortCasesAlphabetically(
          cases,
          optionValues.ASSIGNED,
          isAscending
        );
      }
      if (
        sortBy?.type === optionValues.ASSIGNED &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        return sortCasesAlphabetically(cases, optionValues.ASSIGNED);
      }
      if (
        sortBy?.type === optionValues.AUTHOR &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        return sortCasesAlphabetically(cases, optionValues.AUTHOR, isAscending);
      }
      if (
        sortBy?.type === optionValues.AUTHOR &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        return sortCasesAlphabetically(cases, optionValues.AUTHOR);
      }
      if (
        sortBy?.type === optionValues.LOCATION_NAME &&
        sortBy?.order === optionValues.ASCENDING
      ) {
        return sortCasesAlphabetically(
          cases,
          optionValues.LOCATION_NAME,
          isAscending
        );
      }
      if (
        sortBy?.type === optionValues.LOCATION_NAME &&
        sortBy?.order === optionValues.DESCENDING
      ) {
        return sortCasesAlphabetically(cases, optionValues.LOCATION_NAME);
      }

      return cases;
    };

    casesList = filterBySortBy(casesList);

    return casesList;
  }
);

const getSortedApprovalFeed = createSelector(
  [
    getApprovalFeed,
    getCheckedOption(
      FILTER.APPROVALS,
      FilterTitles.SORT_BY,
      FilterTypes.SORT_BY
    ),
  ],
  (casesList = Immutable.List(), sortOptionChecked = Immutable.Map()) => {
    let res = casesList;

    const sortValueChecked =
      sortOptionChecked && sortOptionChecked.get('value');

    const attrSplit = sortValueChecked.split(optionValues.DESCENDING); // will break this to either ['', 'sys_date_updated'] or simply ['sys_date_updated']
    const sortAttr = last(attrSplit); // whether is last it's the attribute we want
    const isReverse = first(attrSplit) === ''; // if the first one is empty, means is negative

    res = res.sort((itemA, itemB) => {
      const aDate = new Date(itemA.get(sortAttr));
      const bDate = new Date(itemB.get(sortAttr));

      if (aDate > bDate) {
        return 1;
      }
      if (aDate < bDate) {
        return -1;
      }

      return 0;
    });

    if (isReverse) {
      res = res.reverse();
    }

    return res;
  }
);

// DEV-24960: CaseStates fake selector to obtain ONLY pending approvals for my user
// it basically ignores the filter UI
const getApprovalsPendingFeed = createSelector(
  [getTaskEntities, getCurrentUser],
  (taskEntities = Immutable.Map(), currentUser = Immutable.Map()) => {
    const res = taskEntities.toList();

    return res.reduce((accum, task) => {
      if (
        (task.get('status') === CaseStates.PENDING_APPROVAL &&
          task.get('author') !== currentUser.get('id') &&
          Boolean(
            task.getIn(['owner_info', 'id']) === currentUser.get('id')
          )) ||
        (task.get('status') === CaseStates.PENDING_APPROVAL &&
          task.get('type') === CASE_TYPE.APPROVAL_TEAM)
      ) {
        return accum.push(task);
      }
      return accum;
    }, Immutable.List());
  }
);

export {
  getApprovalsPendingFeed,
  getCheckedOption,
  getCheckedOptions,
  getFilterOptions,
  getSortedApprovalFeed,
  getSortedCaseFeed,
};

export default getCaseFeed;
