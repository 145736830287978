import { Button, Form, Modal, Select } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import HierarchySelect from './HierarchySelect';
import HierarchyTypes from '../../globals/HierarchyTypes';
import DepartmentSelectController from '../controllers/DepartmentSelectController';
import ScrollArea from '../atoms/ScrollArea';

const DepartmentHierarchySelect = DepartmentSelectController(HierarchySelect);

class DepartmentSelect extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    noRootPreselect: PropTypes.bool,
    placeholder: PropTypes.string,
    renderEmptySelectionTrigger: PropTypes.func,
    renderSelectionTrigger: PropTypes.func,
    selectOptionRequired: PropTypes.bool,
    trigger: PropTypes.node,
  };

  static defaultProps = {
    disabled: false,
    noRootPreselect: false,
    placeholder: '',
    renderEmptySelectionTrigger: null,
    renderSelectionTrigger: null,
    selectOptionRequired: false,
    trigger: null,
  };

  state = {
    isModalOpen: false,
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  renderEmptySelectionTrigger = (isLoadingDefaultSelectedDepartment) => {
    const { disabled, placeholder } = this.props;

    return (
      <Select
        disabled={disabled || isLoadingDefaultSelectedDepartment}
        loading={isLoadingDefaultSelectedDepartment}
        options={[]}
        placeholder={placeholder}
      />
    );
  };

  renderSelectionTrigger = (selectedDepartment) => {
    const { disabled } = this.props;

    const options = [
      {
        text: selectedDepartment.get('name'),
        value: selectedDepartment.get('id'),
      },
    ];

    return (
      <Select
        disabled={disabled}
        options={options}
        value={selectedDepartment.get('id')}
      />
    );
  };

  renderSelectNodeButton = () => (
    <Button>
      {intl.formatMessage({
        id: 'label.select',
      })}
    </Button>
  );

  renderModal = (triggerFromParam, dropdowns, selectNodeButton) => {
    const { isModalOpen } = this.state;
    const { trigger } = this.props;

    return (
      <Modal
        className='scrolling'
        closeIcon={
          <div className='item close'>
            {intl.formatMessage({
              id: 'label.close',
            })}
          </div>
        }
        onClose={this.handleCloseModal}
        onOpen={this.handleOpenModal}
        open={isModalOpen}
        size='small'
        trigger={trigger || triggerFromParam}
      >
        <div className='ui form'>
          <Modal.Header>
            {intl.formatMessage({
              id: 'label.pick_a_department',
            })}
          </Modal.Header>
          <Modal.Content className='withFooter'>
            <ScrollArea>
              <Form>{dropdowns}</Form>
            </ScrollArea>
          </Modal.Content>
          <Modal.Actions>{selectNodeButton}</Modal.Actions>
        </div>
      </Modal>
    );
  };

  render() {
    // explicitly listing omitted props since 'propTypes' is removed for production builds
    const nonOwnProps = _.omit(this.props, [
      'disabled',
      'placeholder',
      'renderEmptySelectionTrigger',
      'renderSelectionTrigger',
    ]);
    const {
      noRootPreselect,
      renderEmptySelectionTrigger,
      selectOptionRequired,
      renderSelectionTrigger,
    } = this.props;

    const newRenderEmptySelectionTrigger =
      renderEmptySelectionTrigger || this.renderEmptySelectionTrigger;

    const newRenderSelectionTrigger =
      renderSelectionTrigger || this.renderSelectionTrigger;

    return (
      <DepartmentHierarchySelect
        hierarchyType={HierarchyTypes.DEPARTMENTS}
        noRootPreselect={noRootPreselect}
        onCloseModal={this.handleCloseModal}
        renderEmptySelectionTrigger={newRenderEmptySelectionTrigger}
        renderModal={this.renderModal}
        renderSelectNodeButton={this.renderSelectNodeButton}
        renderSelectionTrigger={newRenderSelectionTrigger}
        required={selectOptionRequired}
        typeNameAttr='department_type_name'
        {...nonOwnProps}
      />
    );
  }
}

export default DepartmentSelect;
