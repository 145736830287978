import React from 'react';
import { Form, Select } from 'semantic-ui-react';
import EmailServiceTypes from '../../../../../globals/EmailServiceTypes';

const exclusiveExchangeOAuthFields = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleOnBlurClientSecret,
  handleOnFocusClientSecret,
}) => {
  return (
    <>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={values?.app_id !== '' && errors?.app_id}
          fluid
          id='form-input-control-email-primary-app_id'
          label={{
            children: 'App ID',
            htmlFor: 'form-input-control-email-primary-app_id',
          }}
          name='app_id'
          onBlur={handleBlur}
          onChange={handleChange}
          type='text'
          value={values?.app_id}
          width={8}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={values?.tenant_id !== '' && errors?.tenant_id}
          fluid
          id='form-input-control-email-primary-tenant_id'
          label={{
            children: 'Tenant ID',
            htmlFor: 'form-input-control-email-primary-tenant_id',
          }}
          name='tenant_id'
          onBlur={handleBlur}
          onChange={handleChange}
          type='text'
          value={values?.tenant_id}
          width={8}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={values?.client_secret !== '' && errors?.client_secret}
          fluid
          id='form-input-control-email-primary-client_secret'
          label={{
            children: 'Client Secret',
            htmlFor: 'form-input-control-email-primary-client_secret',
          }}
          name='client_secret'
          onBlur={handleOnBlurClientSecret}
          onChange={handleChange}
          onFocus={handleOnFocusClientSecret}
          type='password'
          value={values?.client_secret}
          width={8}
        />
      </Form.Group>
    </>
  );
};
const nameField = ({
  values,
  errors,
  handleBlur,
  handleChange,
  width = void 0,
}) => (
  <Form.Input
    error={values?.name !== '' && errors?.name}
    fluid
    id='form-input-control-email-primary-name'
    label={{
      children: 'Name',
      htmlFor: 'form-input-control-email-primary-name',
    }}
    name='name'
    onBlur={handleBlur}
    onChange={handleChange}
    placeholder='Ex. Human Resources'
    required
    type='text'
    value={values?.name}
    width={width}
  />
);

const emailField = ({
  values,
  errors,
  handleBlur,
  handleChange,
  width = void 0,
}) => (
  <Form.Group widths={'equal'}>
    <Form.Input
      error={values?.email_address !== '' && errors?.email_address}
      id='form-input-control-email-primary-email_address'
      label={{
        children: 'Email',
        htmlFor: 'form-input-control-email-primary-email_address',
      }}
      name='email_address'
      onBlur={handleBlur}
      onChange={handleChange}
      required
      type='email'
      value={values?.email_address}
      width={width}
    />
  </Form.Group>
);

const generalAuthenticationFields = (propsToFields) => {
  return (
    <>
      <Form.Group widths={'equal'}>
        {nameField({ ...propsToFields })}
      </Form.Group>
      {emailField({ ...propsToFields })}
    </>
  );
};
const exclusiveExchangeAndGmailFields = ({
  values,
  errors,
  handleChange,
  handleBlur,
}) => {
  return (
    <>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={values?.password !== '' && errors?.password}
          fluid
          id='form-input-control-email-primary-password'
          label={{
            children: 'Password',
            htmlFor: 'form-input-control-email-primary-password',
          }}
          name='password'
          onBlur={handleBlur}
          onChange={handleChange}
          required
          type='password'
          value={values?.password}
          width={8}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={values?.domain !== '' && errors?.domain}
          fluid
          id='form-input-control-email-primary-domain'
          label={{
            children: 'Domain',
            htmlFor: 'form-input-control-email-primary-domain',
          }}
          name='domain'
          onBlur={handleBlur}
          onChange={handleChange}
          required
          type='text'
          value={values?.domain}
          width={8}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          error={values?.server_name !== '' && errors?.server_name}
          fluid
          id='form-input-control-email-primary-server_name'
          label={{
            children: 'Server Name',
            htmlFor: 'form-input-control-email-primary-server_name',
          }}
          name='server_name'
          onBlur={handleBlur}
          onChange={handleChange}
          type='text'
          value={values?.server_name}
          width={8}
        />
      </Form.Group>
    </>
  );
};
const modalCredentialsExclusiveExchangeOauthFields = (propsToFields) => (
  <>
    {exclusiveExchangeOAuthFields(propsToFields)}
    {emailField({ ...propsToFields })}
  </>
);
const modalCredentialsExclusiveExchangeGmailFields = (propsToFields) => (
  <>
    {exclusiveExchangeAndGmailFields(propsToFields)}
    {emailField({ ...propsToFields })}
  </>
);
const emailServiceDepartmentOptionsField = ({
  values,
  errors,
  serviceDepartmentOptions,
  handleChangeSelect,
}) => (
  <Form.Field
    control={Select}
    error={errors.esp_service_department}
    label='Service Department'
    name='esp_service_department'
    onChange={handleChangeSelect}
    options={serviceDepartmentOptions}
    placeholder='Service Department'
    value={values?.esp_service_department}
  />
);
const modalDetailsInboxForm = (propsToFields) => {
  return (
    <Form.Group widths='equal'>
      {nameField(propsToFields)}
      {emailServiceDepartmentOptionsField(propsToFields)}
    </Form.Group>
  );
};

const updatedFields = ({ values, currentCredential, emailType }) => {
  let updatedFieldsTemp = {};
  if (values.name !== currentCredential?.name) {
    updatedFieldsTemp = {
      ...updatedFieldsTemp,
      name: values.name,
    };
  }
  if (values.email_address !== currentCredential?.email_address) {
    updatedFieldsTemp = {
      ...updatedFieldsTemp,
      email_address: values.email_address,
    };
  }
  if (
    values.primary !== undefined &&
    values.primary !== currentCredential?.primary
  ) {
    updatedFieldsTemp = {
      ...updatedFieldsTemp,
      is_for_case: values.primary,
      primary: values.primary,
    };
  }
  if (
    (values.esp_service_department || values.esp_service_department === null) &&
    values.esp_service_department !== currentCredential?.esp_service_department
  ) {
    updatedFieldsTemp = {
      ...updatedFieldsTemp,
      esp_service_department: values.esp_service_department,
    };
  }
  if (emailType === EmailServiceTypes.EXCHANGE_OAUTH) {
    const textCreds = ['app_id', 'tenant_id'];

    textCreds.forEach((fieldName) => {
      if (
        currentCredential?.text_creds &&
        values[fieldName] !== currentCredential?.text_creds[fieldName]
      ) {
        updatedFieldsTemp = {
          ...updatedFieldsTemp,
          text_creds: {
            ...currentCredential?.text_creds,
            ...updatedFieldsTemp.text_creds,
            [fieldName]: values[fieldName],
          },
        };
      }
    });
  }
  if (emailType === EmailServiceTypes.EXCHANGE) {
    const exchangeFields = ['password', 'server_name', 'domain'];
    if (currentCredential) {
      exchangeFields.forEach((fieldName) => {
        if (values[fieldName] !== currentCredential[fieldName]) {
          updatedFieldsTemp = {
            ...updatedFieldsTemp,
            [fieldName]: values[fieldName],
          };
        }
      });
    }
  }
  return updatedFieldsTemp;
};

export {
  exclusiveExchangeOAuthFields,
  generalAuthenticationFields,
  exclusiveExchangeAndGmailFields,
  modalDetailsInboxForm,
  emailServiceDepartmentOptionsField,
  modalCredentialsExclusiveExchangeOauthFields,
  modalCredentialsExclusiveExchangeGmailFields,
  updatedFields,
};
