import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import {
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import { assign, curry, debounce, forOwn, memoize, noop } from 'lodash';
import { Waypoint } from 'react-waypoint';

// Globals
import BrowserPrompt from '../../../../../globals/BrowserPrompt';
// Controller
import IntegrationServicenowProductsController from '../../../../controllers/IntegrationServicenowProductsController';

let IntegrationServicenowProducts = class IntegrationServicenowProducts extends PureComponent {
  static propTypes = {
    /** All categories (in fact subcategories) that can be mapped against */
    allCategories: ImmutablePropTypes.list,
    /** flag to tell if the subcategories have been loaded */
    allSubcategoriesLoaded: PropTypes.bool,
    isLoadingSnCategories: PropTypes.bool,
    isLoadingSubCategories: PropTypes.bool,
    isLoadingWorkflowMap: PropTypes.bool,
    /** Functions to perform loading of things */
    loadAllSubCategories: PropTypes.func,
    loadServicenowWorkflowMaps: PropTypes.func,
    loadSnowCategories: PropTypes.func,
    loadSnowCategoryBySysId: PropTypes.func,
    loadSnowProductById: PropTypes.func,
    loadSnowProducts: PropTypes.func,
    pagination: ImmutablePropTypes.map,
    saveServicenowWorkflowMap: PropTypes.func,
    /** A list of serviceow catrgories */
    snCategories: ImmutablePropTypes.list,

    /** A list of integration Workflow Map Instances */

    snWorkflowMap: ImmutablePropTypes.list,

    /** A list of salactable products that will appear in the dropdown */
    snowProducts: ImmutablePropTypes.map,
  };

  static defaultProps = {
    allCategories: Immutable.List(),
    allSubcategoriesLoaded: false,
    isLoadingSnCategories: false,
    isLoadingSubCategories: false,
    isLoadingWorkflowMap: false,
    loadAllSubCategories: noop,
    loadServicenowWorkflowMaps: noop,
    loadSnowCategories: noop,
    loadSnowCategoryBySysId: noop,
    loadSnowProductById: noop,
    loadSnowProducts: noop,
    pagination: Immutable.Map(),
    saveServicenowWorkflowMap: noop,
    snCategories: Immutable.List(),

    snWorkflowMap: Immutable.List(),

    snowProducts: Immutable.Map(),
  };

  state = {
    defaultWorkflowMap: {},
    unsavedMaps: {},
  };

  componentDidMount() {
    const {
      allSubcategoriesLoaded,
      loadAllSubCategories,
      loadSnowCategories,
      loadSnowProducts,
      loadServicenowWorkflowMaps,
      snWorkflowMap,
    } = this.props;
    if (!allSubcategoriesLoaded) {
      loadAllSubCategories(); // Categories present on the left side of the screen
      loadSnowCategories(); // ServiceNow categories
      loadSnowProducts(); // This will by default load the first 25 products in the catalog
      loadServicenowWorkflowMaps(); // The actual mapping saved in the API
    } else {
      this.setStateProductCategoryMap(snWorkflowMap);
    }
  }

  /**
   *  we load whatever product came selected in a mapping but that it might not be loaded
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultWorkflowMap } = this.state;
    const { snWorkflowMap } = this.props;

    // Sets category map

    if (nextProps.snWorkflowMap !== snWorkflowMap) {
      this.setStateProductCategoryMap(nextProps.snWorkflowMap);
    }

    // Laod any category that wasn't initially loaded
    if (!nextProps.snCategories.isEmpty() && !nextProps.isLoadingSnCategories) {
      forOwn(defaultWorkflowMap, (value) => {
        const sys_id = value.snow_category_id;
        const found = nextProps.snCategories.find(
          (cat) => cat.get('sys_id') === sys_id
        );
        if (!found) {
          nextProps.loadSnowCategoryBySysId(sys_id); // Load by sys_id
        }
      });
    }

    // Laod any product that wasn't initially loaded
    if (!nextProps.snowProducts.isEmpty()) {
      forOwn(defaultWorkflowMap, (value) => {
        const productId = value.default_product;
        const found = nextProps.snowProducts.get(productId);
        if (!found) {
          nextProps.loadSnowProductById(productId); // Load by sys_id
        }
      });
    }
  }

  // Maps the object defaultWorkflowMap in state with the data coming from Workflow Map

  setStateProductCategoryMap(snWorkflowMap) {
    const { defaultWorkflowMap } = this.state;

    const newDefaultWorkflowMap = assign({}, defaultWorkflowMap);

    // Populating state...
    snWorkflowMap.forEach((map) => {
      newDefaultWorkflowMap[map.get('catalog_category')] = {
        default_product: map.get('default_product'),
        snow_category_id: map.get('snow_category_id'),
      };
    });

    this.setState({
      defaultWorkflowMap: newDefaultWorkflowMap,
    });
  }

  /**
   * Assigns the product to a category. Only in the state (in memory)
   */
  setProductToEspCategory = memoize(
    curry((categoryID, event, data) => {
      const { defaultWorkflowMap, unsavedMaps } = this.state;

      const productId = data.value;

      const newDefaultWorkflowMap = assign({}, defaultWorkflowMap);

      if (newDefaultWorkflowMap[categoryID]) {
        newDefaultWorkflowMap[categoryID].default_product = productId;
      } else {
        newDefaultWorkflowMap[categoryID] = {
          default_product: productId,
        };
      }

      // Marking this category as unsaved, so the button gets active

      const newUnsavedMaps = assign({}, unsavedMaps);
      newUnsavedMaps[categoryID] = true;

      this.setState({
        defaultWorkflowMap: newDefaultWorkflowMap,
        unsavedMaps: newUnsavedMaps,
      });
    })
  );

  setSnowCategoryToEspCategory = memoize(
    curry((categoryID, event, data) => {
      const snowCatId = data.value;
      const snowCatName = 'This wont matter';

      const { defaultWorkflowMap, unsavedMaps } = this.state;

      const newDefaultWorkflowMap = assign({}, defaultWorkflowMap);

      if (newDefaultWorkflowMap[categoryID]) {
        newDefaultWorkflowMap[categoryID].snow_category_id = snowCatId;
        newDefaultWorkflowMap[categoryID].snow_category_name = snowCatName;
      } else {
        newDefaultWorkflowMap[categoryID] = {
          snow_category_id: snowCatId,
          snow_category_name: snowCatName,
        };
      }

      // Marking this category as unsaved, so the button gets active

      const newUnsavedMaps = assign({}, unsavedMaps);
      newUnsavedMaps[categoryID] = true;

      this.setState({
        defaultWorkflowMap: newDefaultWorkflowMap,
        unsavedMaps: newUnsavedMaps,
      });
    })
  );

  /**
   * Calls the API to save the mapping
   */

  saveServicenowWorkflowMap = memoize(
    curry((categoryID, e) => {
      const { defaultWorkflowMap, unsavedMaps } = this.state;
      const { saveServicenowWorkflowMap } = this.props;

      const workflowMap = defaultWorkflowMap[categoryID];

      saveServicenowWorkflowMap(categoryID, workflowMap);

      // Marking this category as unsaved, so the button gets active

      const newUnsavedMaps = assign({}, unsavedMaps);
      newUnsavedMaps[categoryID] = false;

      this.setState({
        unsavedMaps: newUnsavedMaps,
      });
    })
  );

  saveDeleteMap = memoize(
    curry((categoryID, e) => {
      const { defaultWorkflowMap, unsavedMaps } = this.state;
      const { saveServicenowWorkflowMap } = this.props;

      const confirmed = BrowserPrompt.confirm(
        'This mapping relationship will be deleted. Do you want to proceed?',
        {
          buttons: ['cancel', 'OK'],
          content:
            'This mapping relationship will be deleted. Do you want to proceed?',
          title: 'Delete mapping',
        }
      );

      if (confirmed) {
        const newDefaultWorkflowMap = assign({}, defaultWorkflowMap);
        delete newDefaultWorkflowMap[categoryID];

        saveServicenowWorkflowMap(categoryID, {});

        // Marking this category as saved, so the button gets inactive

        const newUnsavedMaps = assign({}, unsavedMaps);
        newUnsavedMaps[categoryID] = false;

        this.setState({
          defaultWorkflowMap: newDefaultWorkflowMap,
          unsavedMaps: newUnsavedMaps,
        });
      }
    })
  );
  /**
   * Make an API call to async load products in search dropdown
   */
  handleOnSearchProduct = debounce(
    (e, value) => {
      const { loadSnowProducts } = this.props;
      loadSnowProducts(value);
    },
    1000,
    {
      leading: true,
    }
  );

  handleOnSearchCategory = debounce(
    (e, { searchQuery }) => {
      if (searchQuery) {
        const { loadSnowCategories } = this.props;
        loadSnowCategories(searchQuery);
      }
    },
    1000,
    {
      leading: true,
    }
  );

  /**
   * We're not really using this as a form so we do nothing here
   */
  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleBottomWaypointEnter = () => {
    const { pagination, loadAllSubCategories } = this.props;

    if (pagination.get('next')) {
      loadAllSubCategories();
    }
  };

  render() {
    const {
      allCategories,
      allSubcategoriesLoaded,
      isLoadingSnCategories,
      isLoadingSubCategories,
      isLoadingWorkflowMap,
      pagination,
      snCategories,
      snowProducts,
    } = this.props;

    const { defaultWorkflowMap, unsavedMaps } = this.state;

    const snowWorkflowsOptions = snowProducts
      .toList()
      .filter((product) => product.get('snow_url')) // Filter to only the ones containing snow_url
      .sortBy((product) => product.get('name'))
      .map((product) => ({
        text: product.get('name'),
        value: product.get('id'),
      }))
      .toJS();

    const snowCategories = snCategories
      .sortBy((snowCat) => snowCat.getIn(['title']))
      .map((snowCat) => ({
        text: snowCat.getIn(['title']),
        value: snowCat.getIn(['sys_id']),
      }))
      .toJS();

    return (
      <Segment attached='bottom'>
        <Helmet title='Users' />

        <Message
          content='Select the default ServiceNow category on the right for each Espressive category on the left. Espressive will use the variables and workflow for those items as the default.'
          info
        />
        {isLoadingWorkflowMap || !allSubcategoriesLoaded ? (
          <Loader active inline='centered' />
        ) : (
          <Grid padded>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as='h5' content='Espressive Category' />
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as='h5' content='ServiceNow Category' />
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as='h5' content='Default Product Workflow' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <Form onSubmit={this.handleSubmit}>
          {allCategories.map((cat) => (
            <Form.Group key={cat.get('id')}>
              <Form.Field width={4}>
                <Input
                  className='labeled'
                  labelPosition='right'
                  readOnly
                  transparent
                  value={cat.get('path').replace(':', ' > ')}
                />
              </Form.Field>
              <Form.Field width={1}>
                <Icon name='arrow right' />
              </Form.Field>
              <Form.Select
                loading={Boolean(
                  isLoadingSnCategories &&
                    defaultWorkflowMap[cat.get('id')] &&
                    defaultWorkflowMap[cat.get('id')].snow_category_id
                )}
                onChange={this.setSnowCategoryToEspCategory(cat.get('id'))}
                onSearchChange={this.handleOnSearchCategory}
                options={snowCategories}
                placeholder={'Search Categories...'}
                search
                selectOnBlur={false}
                value={
                  defaultWorkflowMap[cat.get('id')] &&
                  defaultWorkflowMap[cat.get('id')].snow_category_id
                }
                width={4}
              />
              <Form.Select
                onChange={this.setProductToEspCategory(cat.get('id'))}
                onSearchChange={this.handleOnSearchProduct}
                options={snowWorkflowsOptions}
                placeholder={'Search Products...'}
                search
                selectOnBlur={false}
                value={
                  defaultWorkflowMap[cat.get('id')] &&
                  defaultWorkflowMap[cat.get('id')].default_product
                }
                width={4}
              />
              {/* Show this button if unsaved changes. Enable it if both columns are filled */}
              {unsavedMaps[cat.get('id')] ? (
                <Form.Button
                  color='green'
                  content='Save'
                  disabled={
                    !defaultWorkflowMap[cat.get('id')].snow_category_id ||
                    !defaultWorkflowMap[cat.get('id')].default_product
                  }
                  fluid
                  onClick={this.saveServicenowWorkflowMap(cat.get('id'))}
                  width={2}
                />
              ) : null}

              {defaultWorkflowMap[cat.get('id')] ? (
                <Form.Button
                  basic
                  icon='delete'
                  onClick={this.saveDeleteMap(cat.get('id'))}
                />
              ) : null}
            </Form.Group>
          ))}

          {pagination.get('next') ? (
            <Waypoint
              key={pagination.get('next')}
              onEnter={this.handleBottomWaypointEnter}
            />
          ) : null}

          {isLoadingSubCategories && pagination.get('next') ? (
            <Loader active inline='centered' />
          ) : null}
        </Form>
      </Segment>
    );
  }
};

IntegrationServicenowProducts = IntegrationServicenowProductsController(
  IntegrationServicenowProducts
);

export default IntegrationServicenowProducts;
