import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureFileCabinets extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M42 39.12h12.4c.552 0 1-.448 1-1s-.448-1-1-1H42c-.552 0-1 .448-1 1s.448 1 1 1zM54.23 64.5H42.15c-.552 0-1 .448-1 1s.448 1 1 1h12.08c.552 0 1-.448 1-1s-.448-1-1-1z' />
          <path d='M23.93 73.86c0 .552.448 1 1 1h46.39c.552 0 1-.448 1-1V49.59H23.93v24.27zM39.16 57.5h17.92v11H39.16v-11z' />
          <path d='M19.31 15.56v64h57.5v-64h-57.5zm55 58.3c0 1.657-1.343 3-3 3H24.93c-1.657 0-3-1.343-3-3V21.28c0-1.657 1.343-3 3-3h46.39c1.657 0 3 1.343 3 3l-.01 52.58z' />
          <path d='M71.32 20.28H24.93c-.552 0-1 .448-1 1v26.31h48.39V21.28c0-.552-.448-1-1-1zm-14.2 21h-18V30.11h18v11.17z' />
        </g>
      </svg>
    );
  }
}

export default CatalogFurnitureFileCabinets;
