import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import _ from 'lodash';

// Atom
import ScrollArea from '../atoms/ScrollArea';
// molecules
import ValidatedForm from '../molecules/ValidatedForm';
import ValidatedPassword from '../molecules/ValidatedPassword';

// Controller
import ChangePasswordFormController from '../controllers/ChangePasswordFormController';

class ChangePasswordForm extends PureComponent {
  static propTypes = {
    /** //by default the change password after user receive OTP token is the only mode we implement */
    // changeViaOPT            : PropTypes.bool,
    closeResetPasswordModal: PropTypes.func.isRequired,
    error: PropTypes.arrayOf(PropTypes.string),
    formValidation: PropTypes.func,
    handleSubmit: PropTypes.func,

    isLoading: PropTypes.bool,
    passwordChanged: PropTypes.bool,
    syncErrors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
  };

  static defaultProps = {
    // changeViaOPT   : true,
    error: null,
    formValidation: _.noop,
    handleSubmit: _.noop,

    isLoading: false,
    passwordChanged: false,
    syncErrors: null,
  };

  render() {
    const {
      closeResetPasswordModal,
      // changeViaOPT,
      error,
      formValidation,
      handleSubmit,

      isLoading,
      passwordChanged,
      syncErrors,
    } = this.props;

    // We only support changing password after user has submitted a token via password reset.
    // Its possible that in the future we conditionally change the UI based on this prop
    // changeViaOPT;

    return (
      <ValidatedForm
        form='ChangePasswordForm'
        onSubmit={handleSubmit}
        validate={formValidation}
      >
        <Modal.Header>
          {intl.formatMessage({
            id: 'label.change_your_password',
          })}
        </Modal.Header>
        <Modal.Content className={passwordChanged ? 'withFooter' : null}>
          <ScrollArea>
            {!passwordChanged ? (
              <Message info size='tiny'>
                {intl.formatMessage({
                  id: 'message.password_reset_after_annon_login_text',
                })}
              </Message>
            ) : (
              <Message
                content={intl.formatMessage({
                  id: 'message.password_changed_confirmation_text',
                })}
                header={intl.formatMessage({
                  id: 'message.password_changed',
                })}
                positive
              />
            )}

            {!passwordChanged && (
              <ValidatedPassword
                icon='lock'
                label={intl.formatMessage({
                  id: 'label.new_password',
                })}
                labelConfirm={intl.formatMessage({
                  id: 'label.confirm_password',
                })}
                name='password1'
                placeholder={intl.formatMessage({
                  id: 'label.password',
                })}
              />
            )}

            {!passwordChanged && (
              <Form.Field widths='equal'>
                <Button
                  disabled={Boolean(syncErrors) || isLoading}
                  fluid
                  loading={isLoading}
                  primary
                  type='submit'
                >
                  {intl.formatMessage({
                    id: 'label.change_my_password',
                  })}
                </Button>
              </Form.Field>
            )}

            {error && !passwordChanged && (
              <Message
                content={intl.formatMessage({
                  id: 'message.login_error_help',
                })}
                error
                header={intl.formatMessage({
                  id: 'message.login_error',
                })}
              />
            )}
          </ScrollArea>
        </Modal.Content>
        {passwordChanged && (
          <Modal.Actions>
            <Button
              content={intl.formatMessage({
                id: 'label.close',
              })}
              onClick={closeResetPasswordModal}
            />
          </Modal.Actions>
        )}
      </ValidatedForm>
    );
  }
}

const ChangePasswordFormTest = ChangePasswordForm;

// eslint-disable-next-line no-class-assign -- DEV-1526
ChangePasswordForm = ChangePasswordFormController(ChangePasswordForm);
export { ChangePasswordFormTest };
export default ChangePasswordForm;
