import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsNewFeature extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0005',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M47.93 58.952c.31 0 .62.096.882.287l11.98 8.704-4.575-14.086c-.2-.617.02-1.295.545-1.677l11.982-8.704h-14.81c-.65 0-1.227-.42-1.428-1.037L47.93 28.353 43.354 42.44c-.2.617-.778 1.036-1.427 1.036h-14.81L39.1 52.18c.524.383.744 1.06.544 1.678l-4.577 14.086 11.98-8.705c.264-.192.573-.288.883-.288m15.717 14.42c-.31 0-.62-.097-.882-.287L47.93 62.305l-14.835 10.78c-.525.38-1.238.38-1.764 0-.524-.383-.744-1.06-.544-1.678l5.667-17.44L21.618 43.19c-.525-.383-.745-1.06-.544-1.677.2-.62.777-1.037 1.426-1.037h18.337l5.666-17.44c.2-.618.778-1.036 1.427-1.036.65 0 1.226.418 1.427 1.036l5.666 17.44H73.36c.65 0 1.226.418 1.427 1.037.2.617-.02 1.294-.545 1.676L59.408 53.967l5.666 17.44c.2.617-.02 1.295-.545 1.677-.264.19-.574.286-.883.286'
          fill={color}
          fillRule='evenodd'
        />
      </svg>
    );
  }
}

export default CardsNewFeature;
