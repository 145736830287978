import { UserAgentUtils } from 'esp-globals';

import store from '../stores/store';
import systemVersionThunks from '../actions/systemVersionThunks';

import CordovaUtils from './CordovaUtils';
import ElectronUtils from './ElectronUtils';

export default function getVersions() {
  const { dispatch } = store;
  const isEncodeForced = true;

  if (UserAgentUtils.isCordova()) {
    CordovaUtils.retrieveAppVersion();
  } else if (UserAgentUtils.isElectron()) {
    ElectronUtils.retrieveAppVersion();
  }

  dispatch(systemVersionThunks.getClientVersion());
  dispatch(systemVersionThunks.getVersion(isEncodeForced));
}
