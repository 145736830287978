import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwareDesktop extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(32.5 16)'>
          <path d='M25.9 63H4.04v-2.2c-.34-.058-.676-.15-1-.27V64h23.87v-3.36c-.326.097-.66.164-1 .2L25.9 63z' />
          <rect height='4.03' rx='.5' width='18.15' x='6.16' y='12.79' />
          <path d='M25.42 0H5.04c-2.76 0-5 2.24-5 5v49.83c0 2.76 2.24 5 5 5h20.38c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5zm-2.44 51.7H7.8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h15.18c.276 0 .5.224.5.5s-.224.5-.5.5zm0-4.8H7.8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h15.18c.276 0 .5.224.5.5s-.224.5-.5.5zm0-4.8H7.8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h15.18c.276 0 .5.224.5.5s-.224.5-.5.5zm2.34-25.77c0 .834-.676 1.51-1.51 1.51H6.66c-.834 0-1.51-.676-1.51-1.51v-3c0-.834.676-1.51 1.51-1.51H23.8c.834 0 1.51.676 1.51 1.51l.01 3z' />
        </g>
      </svg>
    );
  }
}

export default CatalogHardwareDesktop;
