import Immutable from 'immutable';
import buildCompositeController from '../../utils/buildCompositeController';

import integrationThunks from '../../actions/integrationThunks';

const mapStateToProps = (state, ownProps) => {
  const { tableName } = ownProps;
  const { field } = ownProps;

  const composedTableName = field ? `${tableName}.${field}` : tableName;

  let records;
  if (state.hasIn(['integration', 'genericTableResults', composedTableName])) {
    records = state.getIn([
      'integration',
      'genericTableResults',
      composedTableName,
    ]);
  } else {
    records = Immutable.List();
  }

  let tableData;
  if (state.hasIn(['integration', 'genericTableData', composedTableName])) {
    tableData = state.getIn([
      'integration',
      'genericTableData',
      composedTableName,
    ]);
  } else {
    tableData = Immutable.Map();
  }

  return {
    displayValue: tableData.get('displayValue'),
    isLoading: tableData.get('isLoading'),
    realValue: tableData.get('realValue'),
    records: records,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTableRecords(tableName, searchQuery, field, refQualCondition) {
    dispatch(
      integrationThunks.loadGenericTable(
        tableName,
        searchQuery,
        field,
        refQualCondition
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
