import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  description: PropTypes.string.isRequired,
};

const InputDescription = ({ description }) => {
  return (
    <div>
      <em>
        <small>{description}</small>
      </em>
    </div>
  );
};

InputDescription.propTypes = propTypes;

export default InputDescription;
