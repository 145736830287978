import React, { useContext, useEffect } from 'react';
import pt from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import ScheduleActivityForm from './ScheduleActivityForm';
import useOffboardingSchedule from '../hooks/useOffboardingSchedule';

import ScheduleActivityTemplates from './ScheduleActivityTemplates';
import { isEmpty } from 'lodash';
import offboardingScheduleContext from './Context/OffboardingScheduleContext';

const propTypes = {
  params: pt.shape({}),
  scheduleID: pt.string,
};

const ScheduleDetail = ({ scheduleID, params }) => {
  const {
    loadSchedule,
    schedule,
    selectedTemplates,
    loadTemplatesPerSchedule,
  } = useOffboardingSchedule();
  const { isScheduleUpdated, setIsScheduleUpdated } = useContext(
    offboardingScheduleContext
  );
  useEffect(() => {
    if (
      scheduleID !== 'new' &&
      (!Number.isNaN(parseInt(scheduleID)) || isScheduleUpdated)
    ) {
      loadSchedule({ params, scheduleID });
    }
  }, [isScheduleUpdated, loadSchedule, params, scheduleID]);

  useEffect(() => {
    if (
      scheduleID !== 'new' &&
      !isEmpty(schedule) &&
      isEmpty(selectedTemplates) &&
      !isEmpty(schedule?.included_todos)
    ) {
      loadTemplatesPerSchedule({ schedule, scheduleID });
    }
    if (isScheduleUpdated) {
      loadTemplatesPerSchedule({ schedule, scheduleID });
      setIsScheduleUpdated(false);
    }
  }, [
    isScheduleUpdated,
    loadTemplatesPerSchedule,
    params,
    schedule,
    scheduleID,
    selectedTemplates,
    setIsScheduleUpdated,
  ]);
  return (
    <Segment as={Grid} attached clearing divided>
      <Grid.Column width={scheduleID !== 'new' ? 10 : 16}>
        <ScheduleActivityForm
          params={params}
          schedule={schedule}
          selectedTemplates={selectedTemplates}
        />
      </Grid.Column>
      {scheduleID !== 'new' ? (
        <Grid.Column width={6}>
          <ScheduleActivityTemplates
            schedule={schedule}
            scheduleID={scheduleID}
            selectedTemplates={selectedTemplates}
          />
        </Grid.Column>
      ) : null}
    </Segment>
  );
};

ScheduleDetail.propTypes = propTypes;

export default ScheduleDetail;
