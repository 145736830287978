import { curry, memoize } from 'lodash';
import DeviceType, { DeviceOption } from '../../globals/DeviceType';
import { Divider, Form, Label, Select } from 'semantic-ui-react';
import React, { PureComponent } from 'react';

import DepartmentField from './DepartmentField';
import { Field } from 'redux-form';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
// controllers
import LocationDeptRolePickerController from '../controllers/LocationDeptRolePickerController';
import LocationField from './LocationField';
import PropTypes from 'prop-types';
// Global
import TypeStateGroup from '../../globals/TypeStateGroup';
// molecules
import ValidatedCheckbox from './ValidatedCheckbox';

const checkGroup = (groups, listToUpdate, nodesByID) => {
  let listUpdated = listToUpdate;
  groups.forEach((group) => {
    const node = nodesByID.get(group.get('id'));
    if (node) {
      listUpdated = listUpdated.push(node);
    }
  });
  return listUpdated;
};

const loadGroup = (groups, nodeById, action, alreadyExist) => {
  if (!groups.isEmpty()) {
    groups.forEach((group, i) => {
      if (!alreadyExist && !nodeById.get(group.get('id'))) {
        // Only load location not found
        action(group.get('id'));
      } else if (alreadyExist && nodeById.get(group.get('id'))) {
        const end = groups.size === i + 1;
        action(nodeById.get(group.get('id')), end);
      }
    });
  }
};

class LocationDeptRolePicker extends PureComponent {
  static propTypes = {
    /** Change value a redux form reducer for a group field */

    changeField: PropTypes.func.isRequired,

    clearValues: PropTypes.bool,
    /** Custom condition not editable */
    customCondition: PropTypes.arrayOf(PropTypes.string),
    /** Department node */
    departmentNodesByID: ImmutablePropTypes.map.isRequired,
    disableRemoteAndNewHires: PropTypes.bool, // in order to prevent DEV-7125
    formValues: ImmutablePropTypes.map, // values of the form where this is being used
    /** Load Department data */
    getDefaultSelectedDepartment: PropTypes.func.isRequired,
    /** Load location data */
    getDefaultSelectedLocation: PropTypes.func.isRequired,
    /** Group Computer type from redux form */
    groupComputerType: ImmutablePropTypes.list,
    /** Group deprtments from redux form */
    groupDepartments: ImmutablePropTypes.list.isRequired,
    groupDeviceType: ImmutablePropTypes.list,
    /** Group job_roles from redux form */
    groupJobRoles: ImmutablePropTypes.list.isRequired,
    /** Group locations from redux form */
    groupLocations: ImmutablePropTypes.list.isRequired,
    /** Group Mobile type from redux form */
    groupMobileType: ImmutablePropTypes.list,

    isLoadingGroup: PropTypes.bool,
    jobRoles: ImmutablePropTypes.list,
    /** Load job role */
    loadJobRoles: PropTypes.func.isRequired,
    /** Location node */
    locationNodesByID: ImmutablePropTypes.map.isRequired,
    name: PropTypes.string,
    noRootPreselect: PropTypes.bool,
    /** Display or not the computer type */

    pickComputer: PropTypes.bool,

    pickDept: PropTypes.bool,
    pickLocation: PropTypes.bool,
    /** Display or not the mobile type */
    pickMobile: PropTypes.bool,
    pickOS: PropTypes.bool,
    pickRole: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    clearValues: false,
    customCondition: [],
    disableRemoteAndNewHires: false,
    formValues: Immutable.Map(),
    groupComputerType: Immutable.List(),
    groupDeviceType: Immutable.List(),
    groupMobileType: Immutable.List(),
    isLoadingGroup: false,
    jobRoles: null,
    name: '',
    noRootPreselect: false,
    pickComputer: false,
    pickDept: false,
    pickLocation: false,
    pickMobile: false,
    pickOS: false,
    pickRole: false,
    readOnly: false,
  };

  constructor(props) {
    super(props);

    const locations =
      !props.groupLocations.isEmpty() && !props.locationNodesByID.isEmpty()
        ? checkGroup(
            props.groupLocations,
            Immutable.List(),
            props.locationNodesByID
          )
        : Immutable.List();

    const departments =
      !props.groupDepartments.isEmpty() && !props.departmentNodesByID.isEmpty()
        ? checkGroup(
            props.groupDepartments,
            Immutable.List(),
            props.departmentNodesByID
          )
        : Immutable.List();

    const jobRoles =
      !props.groupJobRoles.isEmpty() && !props.jobRoles.isEmpty()
        ? props.groupJobRoles
        : Immutable.List();

    const newState = {};
    newState[TypeStateGroup.departments] = departments;
    newState[TypeStateGroup.locations] = locations;
    newState[TypeStateGroup.selectedJobRoles] = jobRoles;

    if (props.pickComputer) {
      newState[TypeStateGroup.computer_type] = props.groupComputerType;
    }

    if (props.pickMobile) {
      newState[TypeStateGroup.mobile_type] = props.groupMobileType;
    }
    if (props.pickOS) {
      newState[TypeStateGroup.deviceType] = props.groupDeviceType;
    }

    this.state = newState;
  }

  componentDidMount() {
    const { jobRoles, loadJobRoles } = this.props;

    if (!jobRoles || jobRoles.isEmpty()) {
      loadJobRoles();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clearValues, locationNodesByID } = this.props;
    const { selectedJobRoles } = this.state;

    if (nextProps.clearValues !== clearValues) {
      const newState = {};
      newState[TypeStateGroup.departments] = Immutable.List();
      newState[TypeStateGroup.locations] = Immutable.List();
      newState[TypeStateGroup.selectedJobRoles] = Immutable.List();
      this.setState({
        [[TypeStateGroup.computer_type]]: Immutable.List(),
      });
      this.setState({
        [[TypeStateGroup.mobile_type]]: Immutable.List(),
      });
      this.setState({
        [[TypeStateGroup.deviceType]]: Immutable.List(),
      });
      this.setState(newState);
    }

    if (
      !nextProps.isLoadingGroup &&
      nextProps.departmentNodesByID.isEmpty() &&
      nextProps.locationNodesByID.isEmpty()
    ) {
      // Don't load department and location if we are loading them

      loadGroup(
        nextProps.groupLocations,
        nextProps.locationNodesByID,
        nextProps.getDefaultSelectedLocation
      );

      loadGroup(
        nextProps.groupDepartments,
        nextProps.departmentNodesByID,
        nextProps.getDefaultSelectedDepartment
      );
    } else if (
      !nextProps.locationNodesByID.isEmpty() &&
      locationNodesByID.isEmpty()
    ) {
      const locations =
        !nextProps.groupLocations.isEmpty() &&
        !nextProps.locationNodesByID.isEmpty()
          ? checkGroup(
              nextProps.groupLocations,
              Immutable.List(),
              nextProps.locationNodesByID
            )
          : Immutable.List();
      const departments =
        !nextProps.groupDepartments.isEmpty() &&
        !nextProps.departmentNodesByID.isEmpty()
          ? checkGroup(
              nextProps.groupDepartments,
              Immutable.List(),
              nextProps.departmentNodesByID
            )
          : Immutable.List();

      const newJobRoles =
        !nextProps.groupJobRoles.isEmpty() && !nextProps.jobRoles.isEmpty()
          ? nextProps.groupJobRoles
          : Immutable.List();

      const newState = {};
      newState[TypeStateGroup.departments] = departments;
      newState[TypeStateGroup.locations] = locations;
      newState[TypeStateGroup.selectedJobRoles] = newJobRoles;

      this.setState(newState);
    } else {
      // Check if we already get the data needed for location and department
      let { departments } = this.state;
      let { locations } = this.state;
      const updateDepartment = (department, end) => {
        const alreadySelected = departments.some(
          (d) => d.get('id') === department.get('id')
        );
        if (alreadySelected) {
          return;
        }
        departments = departments.push(department);

        // ON end, update the state
        if (end) {
          this.setState({
            departments,
          });
        }
      };
      const updateLocation = (location, end) => {
        const alreadySelected = locations.some(
          (d) => d.get('id') === location.get('id')
        );
        if (alreadySelected) {
          return;
        }
        locations = locations.push(location);

        // ON end, update the state
        if (end) {
          this.setState({
            locations,
          });
        }
      };

      loadGroup(
        nextProps.groupLocations,
        nextProps.locationNodesByID,
        updateLocation,
        true
      );
      loadGroup(
        nextProps.groupDepartments,
        nextProps.departmentNodesByID,
        updateDepartment,
        true
      );
    }

    if (
      nextProps.jobRoles &&
      !nextProps.groupJobRoles.isEmpty() &&
      selectedJobRoles.isEmpty()
    ) {
      const newSelectedJobRoles = nextProps.groupJobRoles.filter((job) =>
        nextProps.jobRoles.find((j) => j.get('value') === job)
      );
      this.setState({
        selectedJobRoles: newSelectedJobRoles,
      });
    }

    if (
      nextProps.pickComputer &&
      !nextProps.groupComputerType.isEmpty() &&
      // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix this line
      this.state[TypeStateGroup.computer_type].isEmpty()
    ) {
      const selectedComputerType = nextProps.groupComputerType.filter((comp) =>
        DeviceType.computer.find((j) => j === comp.get('name'))
      );
      this.setState({
        [[TypeStateGroup.computer_type]]: selectedComputerType,
      });
    }

    if (
      nextProps.pickMobile &&
      !nextProps.groupMobileType.isEmpty() &&
      // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix this line
      this.state[TypeStateGroup.mobile_type].isEmpty()
    ) {
      const selectedMobileType = nextProps.groupMobileType.filter((mob) =>
        DeviceType.mobile.find((j) => j === mob.get('name'))
      );
      this.setState({
        [[TypeStateGroup.mobile_type]]: selectedMobileType,
      });
    }
    if (
      nextProps.pickOS &&
      !nextProps.groupDeviceType.isEmpty() &&
      // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix this line
      this.state[TypeStateGroup.deviceType].isEmpty()
    ) {
      const selectedDeviceType = nextProps.groupDeviceType.filter((device) =>
        DeviceType.devices.find((os) => os === device.get('name'))
      );
      this.setState({
        [[TypeStateGroup.deviceType]]: selectedDeviceType,
      });
    }
  }

  handleChangeInclude = memoize(
    curry((type, e, val) => {
      const { changeField } = this.props;
      changeField(val, type);
    })
  );

  handleChangeJobRoles = (e, data) => {
    const { changeField } = this.props;

    const newValueID = data && data.value ? Immutable.fromJS(data.value) : null;
    const { options } = data;

    // Build the new value array wit h a list of object with the name and id
    const newValue = newValueID
      ? newValueID.map((value) =>
          Immutable.fromJS({
            id: value,
            name:
              options.find((o) => o.value === value) &&
              options.find((o) => o.value === value).text,
          })
        )
      : null;

    changeField(newValue, 'job_roles');
    this.setState({
      selectedJobRoles: newValue,
    });
  };

  handleChangeComputerType = (e, data) => {
    const { changeField } = this.props;

    const newValueID = data && data.value ? Immutable.fromJS(data.value) : null;
    const { options } = data;

    // Build the new value array wit h a list of object with the name and id
    const newValue = newValueID
      ? newValueID.map((value) =>
          Immutable.fromJS({
            id: value,
            name: options.find((o) => o.value === value).text,
          })
        )
      : null;

    changeField(newValue, 'computerType');
    this.setState({
      [TypeStateGroup.computer_type]: newValue,
    });
  };

  handleChangeMobileType = (e, data) => {
    const { changeField } = this.props;

    const newValueID = data && data.value ? Immutable.fromJS(data.value) : null;
    const { options } = data;

    // Build the new value array wit h a list of object with the name and id
    const newValue = newValueID
      ? newValueID.map((value) =>
          Immutable.fromJS({
            id: value,
            name: options.find((o) => o.value === value).text,
          })
        )
      : null;

    changeField(newValue, 'mobileType');
    this.setState({
      [TypeStateGroup.mobile_type]: newValue,
    });
  };

  handleChangeDeviceType = (e, data) => {
    const { changeField } = this.props;

    const newValueID = data && data.value ? Immutable.fromJS(data.value) : null;

    // Build the new value array wit h a list of object with the name and id
    const newValue = newValueID
      ? newValueID.map((value) => {
          const [foundOption] = data.options.filter(
            (option) => option.text === value
          );

          return Immutable.fromJS({
            id: foundOption.key,
            name: value,
          });
        })
      : null;

    changeField(newValue, 'deviceType');
    this.setState({
      [TypeStateGroup.deviceType]: newValue,
    });
  };

  handleRemoveLocationsDepartments = memoize(
    curry((type, e, data) => {
      const { options } = data;
      const { value } = data;

      if (value.length < options.length) {
        // It's a remove event - searhc for the ID removed
        let idToRemove = null;
        options.forEach((option) => {
          if (!value.find((v) => v === option.value)) {
            idToRemove = option.value;
          }
        });

        this.onHandleRemove(idToRemove, type);
      }
    })
  );

  onHandleRemove = (id, type) => {
    if (!TypeStateGroup[type]) {
      return; // Error with the type send - should be 'departments' or 'locations'
    }

    // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix this
    let selectedType = this.state[type];

    selectedType = selectedType.filterNot((select) => select.get('id') === id);

    this.updateState(selectedType, type);
  };

  onHandleSelect = (data, type) => {
    if (!TypeStateGroup[type]) {
      return; // Error with te type send - should be 'departments' or 'locations'
    }

    let newData;
    if (!data && type) {
      // if no data is selected, then reset it to empty
      newData = Immutable.List();
    } else {
      // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix this
      const selectedType = this.state[type];
      const alreadySelected = selectedType.some(
        (l) => l.get('id') === data.get('id')
      );

      if (alreadySelected) {
        return;
      }

      newData = selectedType.push(data);
    }
    this.updateState(newData, type);
  };

  updateState = (selectedType, type) => {
    const { changeField } = this.props;

    const selectedArray = [];

    selectedType.forEach((dep) => {
      selectedArray.push({
        code: dep?.get('code') ? dep?.get('code') : void 0,
        id: dep.get('id'),
        name: dep.get('name'),
      });
    });

    changeField(Immutable.fromJS(selectedArray), type);

    const newState = {};
    newState[type] = selectedType;
    this.setState(newState);
  };

  handleIgnoreClick = (e) => {
    e.preventDefault();
  };

  createJobRoleField = ({ disabled, options, defaultValue }) => {
    const { formValues, jobRoles } = this.props;

    // const {selectedJobRoles} = this.state;
    if (disabled) {
      const jobRolesSelected = formValues.get('job_roles');
      return (
        <Form.Field>
          <label>{'Job Roles'}</label>
          <Label.Group className='multiple search selection dropdown'>
            {jobRolesSelected &&
            options.length > 0 &&
            !jobRolesSelected.isEmpty() ? (
              jobRolesSelected.map((job) => {
                const jobIndex = jobRoles.findIndex(
                  (jobrole) => jobrole.get('value') === job
                );
                const contentLabel =
                  jobIndex && jobIndex > -1
                    ? jobRoles.getIn([jobIndex, 'text'])
                    : '';

                return <Label content={contentLabel} key={job} />;
              })
            ) : (
              <Label content='All' />
            )}
          </Label.Group>
        </Form.Field>
      );
    }

    return (
      <Form.Input
        control={Select}
        label='Job Roles'
        multiple
        onChange={this.handleChangeJobRoles}
        onClick={this.handleIgnoreClick}
        options={jobRoles.toJS()}
        placeholder='All'
        search
        value={defaultValue}
      />
    );
  };

  departmentsSelectedRender = (departments) => {
    const { formValues } = this.props;

    const options = [];
    const selected = [];
    if (departments && !departments.isEmpty()) {
      departments.forEach((department) => {
        options.push({
          id: department.get('id'),
          text: department.get('name'),
          value: department.get('id'),
        });
        selected.push(department.get('id'));
      });
    } else if (formValues.get('department')) {
      options.push({
        id: formValues.get('department'),
        text: formValues.get('department'),
        value: formValues.get('department'),
      });

      selected.push(formValues.get('department'));
    } else if (formValues.get('departments')) {
      formValues.get('departments').forEach((dep) => {
        options.push({
          id: dep.get('id'),
          text: dep.get('name'),
          value: dep.get('id'),
        });
        selected.push(dep.get('id'));
      });
    }

    return (
      <Form.Input
        control={Select}
        label='Departments'
        multiple
        onChange={this.handleRemoveLocationsDepartments(
          TypeStateGroup.departments
        )}
        onClick={this.handleIgnoreClick}
        options={options}
        placeholder='All'
        search
        value={selected}
      />
    );
  };

  locationsSelectedRender = (locations) => {
    const { formValues } = this.props;

    const options = [];
    const selected = [];

    if (locations && !locations.isEmpty()) {
      locations.forEach((location) => {
        options.push({
          id: location.get('id'),
          text: location.get('name'),
          value: location.get('id'),
        });
        selected.push(location.get('id'));
      });
    } else if (formValues.get('locations')) {
      formValues.get('locations').forEach((loc) => {
        options.push({
          id: loc.get('id'),
          text: loc.get('name'),
          value: loc.get('id'),
        });
        selected.push(loc.get('id'));
      });
    }

    return (
      <Form.Input
        control={Select}
        label='Locations'
        multiple
        onChange={this.handleRemoveLocationsDepartments(
          TypeStateGroup.locations
        )}
        onClick={this.handleIgnoreClick}
        options={options}
        placeholder='All'
        search
        value={selected}
      />
    );
  };

  createComputerType = ({ disabled, options, defaultValue }) => {
    const { formValues } = this.props;

    // const {selectedJobRoles} = this.state;
    if (disabled) {
      const computerSelected = formValues.get('computerType');

      return (
        <Form.Field>
          <label>{'Computer Type'}</label>
          <Label.Group className='multiple search selection dropdown'>
            {computerSelected &&
            options.length > 0 &&
            !computerSelected.isEmpty() ? (
              computerSelected.map((selection) => {
                const compIndex = DeviceType.computer.findIndex(
                  (comp) => comp === selection
                );
                const contentLabel =
                  compIndex && compIndex > -1
                    ? DeviceType.computer[compIndex]
                    : '';

                return <Label content={contentLabel} key={selection} />;
              })
            ) : (
              <Label content='All' />
            )}
          </Label.Group>
        </Form.Field>
      );
    }

    return (
      <Form.Input
        control={Select}
        label='Computer Type'
        multiple
        onChange={this.handleChangeComputerType}
        onClick={this.handleIgnoreClick}
        options={options}
        placeholder='All'
        search
        value={defaultValue}
      />
    );
  };

  createMobileType = ({ disabled, options, defaultValue }) => {
    const { formValues } = this.props;

    // const {selectedJobRoles} = this.state;
    if (disabled) {
      const mobileSelected = formValues.get('mobileType');

      return (
        <Form.Field>
          <label>{'Mobile Type'}</label>
          <Label.Group className='multiple search selection dropdown'>
            {mobileSelected &&
            options.length > 0 &&
            !mobileSelected.isEmpty() ? (
              mobileSelected.map((selection) => {
                const compIndex = DeviceType.mobile.findIndex(
                  (comp) => comp === selection
                );
                const contentLabel =
                  compIndex && compIndex > -1
                    ? DeviceType.mobile[compIndex]
                    : '';

                return <Label content={contentLabel} key={selection} />;
              })
            ) : (
              <Label content='All' />
            )}
          </Label.Group>
        </Form.Field>
      );
    }

    return (
      <Form.Input
        control={Select}
        label='Mobile Type'
        multiple
        onChange={this.handleChangeMobileType}
        onClick={this.handleIgnoreClick}
        options={options}
        placeholder='All'
        search
        value={defaultValue}
      />
    );
  };

  createDeviceTypeField = ({ disabled, options, defaultValue }) => {
    const { formValues } = this.props;

    if (disabled) {
      const selectedDevices = formValues.get('deviceType');
      return (
        <Form.Field>
          <label>{'Devices'}</label>
          <Label.Group className='multiple search selection dropdown'>
            {selectedDevices &&
            options.length > 0 &&
            !selectedDevices.isEmpty() ? (
              selectedDevices.map((selection) => {
                const compIndex = DeviceType.devices.findIndex(
                  (comp) => comp === selection
                );
                const contentLabel =
                  compIndex && compIndex > -1
                    ? DeviceType.devices[compIndex]
                    : '';

                return <Label content={contentLabel} key={selection} />;
              })
            ) : (
              <Label content='All' />
            )}
          </Label.Group>
        </Form.Field>
      );
    }

    return (
      <Form.Input
        control={Select}
        label='Device Type'
        multiple
        onChange={this.handleChangeDeviceType}
        onClick={this.handleIgnoreClick}
        options={options}
        placeholder='All'
        search
        value={defaultValue}
      />
    );
  };

  render() {
    const {
      customCondition,
      disableRemoteAndNewHires,
      formValues,
      jobRoles,
      name,
      noRootPreselect,
      pickComputer,
      pickDept,
      pickLocation,
      pickMobile,
      pickOS,
      pickRole,
      readOnly,
    } = this.props;

    const { departments, locations, selectedJobRoles } = this.state;

    const defaultSelectedJobRole =
      selectedJobRoles && !selectedJobRoles.isEmpty()
        ? selectedJobRoles.map((role) => role.get('id')).toJS()
        : formValues.has('job_roles')
        ? formValues
            .get('job_roles')
            .map((role) => role.get('id'))
            .toJS()
        : [];

    const defaultSelectedNodeID = formValues.get('location')
      ? formValues.get('location').map((loc) => loc.get('id'))
      : null;

    // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix it
    const defaultSelectedComputer = this.state[TypeStateGroup.computer_type]
      ? // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix it
        this.state[TypeStateGroup.computer_type]
          .map((entry) => entry.get('name'))
          .toJS()
      : [];
    // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix it
    const defaultSelectedMobile = this.state[TypeStateGroup.mobile_type]
      ? // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix it
        this.state[TypeStateGroup.mobile_type]
          .map((entry) => entry.get('name'))
          .toJS()
      : [];

    // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix it
    const defaultSelectedDevice = this.state[TypeStateGroup.deviceType]
      ? // eslint-disable-next-line react/destructuring-assignment -- not easy way to fix it
        this.state[TypeStateGroup.deviceType]
          .map((entry) => entry.get('name'))
          .toJS()
      : [];

    const readOnlyRegularCondition = Boolean(
      readOnly || customCondition.length
    );
    return (
      <>
        {customCondition.length ? (
          <div className='field'>
            <label>{'Custom Condition(s)'}</label>
            {customCondition.map((cond) => (
              <React.Fragment key={cond}>
                <br />
                {cond}
              </React.Fragment>
            ))}
            <Divider />
          </div>
        ) : null}
        {pickLocation && (
          <>
            <Field
              component={LocationField}
              defaultSelectedNodeID={defaultSelectedNodeID}
              handleRemoveLocation={this.onHandleRemove}
              handleSelectLocation={this.onHandleSelect}
              locations={locations}
              locationsSelectedRender={this.locationsSelectedRender}
              name='locationDepRole.locations'
              noRootPreselect={noRootPreselect}
              placeholder='All'
              readOnly={readOnlyRegularCondition}
            />

            {!disableRemoteAndNewHires ? (
              <ValidatedCheckbox
                disabled={readOnlyRegularCondition}
                label='Include remote workers from target location'
                name={`${name}.include_remote`}
                onChange={this.handleChangeInclude('include_remote')}
              />
            ) : null}
          </>
        )}

        {pickDept && (
          <Field
            component={DepartmentField}
            defaultSelectedNodeID={formValues.get('department')}
            departments={departments}
            departmentsSelectedRender={this.departmentsSelectedRender}
            handleRemoveDepartment={this.onHandleRemove}
            handleSelectDepartment={this.onHandleSelect}
            name='locationDepRole.departments'
            noRootPreselect={noRootPreselect}
            placeholder='All'
            readOnly={readOnlyRegularCondition}
          />
        )}

        {jobRoles && pickRole && (
          <>
            <Field
              component={this.createJobRoleField}
              defaultValue={defaultSelectedJobRole}
              disabled={readOnlyRegularCondition}
              label='Job Roles'
              name='locationDepRole.job_roles'
              onChange={this.handleChangeJobRoles}
              options={jobRoles.toJS()}
              search
            />
            {!disableRemoteAndNewHires ? (
              <ValidatedCheckbox
                disabled={readOnlyRegularCondition}
                label='Available to new hires'
                name={`${name}.include_new_hire`}
                onChange={this.handleChangeInclude('include_new_hire')}
              />
            ) : null}
          </>
        )}

        {pickComputer && (
          <Field
            component={this.createComputerType}
            defaultValue={defaultSelectedComputer}
            disabled={readOnlyRegularCondition}
            label='Computer Type'
            name='locationDepRole.computerType'
            onChange={this.handleChangeComputerType}
            options={DeviceOption.computer}
            search
          />
        )}

        {pickMobile && (
          <Field
            component={this.createMobileType}
            defaultValue={defaultSelectedMobile}
            disabled={readOnlyRegularCondition}
            label='Mobile Type'
            name='locationDepRole.mobileType'
            onChange={this.handleChangeMobileType}
            options={DeviceOption.mobile}
            search
          />
        )}
        {pickOS && (
          <Field
            component={this.createDeviceTypeField}
            defaultValue={defaultSelectedDevice}
            disabled={readOnlyRegularCondition}
            label='Device Type'
            name='locationDepRole.deviceType'
            onChange={this.handleChangeDeviceType}
            options={DeviceOption.devices}
            search
          />
        )}
      </>
    );
  }
}

export const LocationDeptRolePickerTest = LocationDeptRolePicker;

export default LocationDeptRolePickerController(LocationDeptRolePicker);
