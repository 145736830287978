import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogServicesDataRecovery extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M44.33 70.27c-.53-3.407-2.126-6.558-4.56-9l-9.67-10c-.878-1.175-2.352-1.742-3.79-1.457-1.44.284-2.588 1.37-2.953 2.79-.364 1.42.12 2.925 1.243 3.867l4.62 4.35c-.05.08.62.88-.21 1.52s-1.68-.31-1.68-.31l-5.55-5.81c-.452-.785-.77-1.64-.94-2.53l.55-14.26v-.25c-.104-2.03-1.78-3.625-3.815-3.625-2.034 0-3.71 1.594-3.815 3.625L13 58.23c.007 1.18.432 2.322 1.2 3.22l2.8 3.11 4.56 4.56 6.14 6.14v7.63H45v-7.64l-.67-4.98zm37.94-31.12c-.104-2.03-1.78-3.625-3.815-3.625-2.034 0-3.71 1.594-3.815 3.625v.25l.55 14.26c-.17.89-.488 1.745-.94 2.53L68.7 62s-.85 1-1.68.31-.15-1.45-.21-1.52l4.62-4.35c1.124-.942 1.607-2.446 1.243-3.867-.365-1.42-1.513-2.506-2.952-2.79-1.434-.285-2.91.282-3.79 1.457l-9.67 10c-2.43 2.442-4.03 5.593-4.56 9l-.64 5v7.65h17.22v-7.64l6.14-6.14L79 64.56l2.83-3.11c.757-.902 1.17-2.042 1.17-3.22l-.73-19.08zm-31.18-11h-2.96v2.02H46.1v2.97h2.03v2.02h2.96v-2.02h2.02v-2.97h-2.02' />
          <path d='M67.62 31.35c.01-9.023-6.72-16.633-15.676-17.727-8.956-1.095-17.32 4.67-19.483 13.43-2.16 8.76 2.558 17.755 10.99 20.957l-1.07.67c-1.364.714-2.122 2.222-1.88 3.746s1.43 2.722 2.953 2.977c1.52.255 3.036-.49 3.76-1.853l3.45-4.4c9.49-.474 16.946-8.298 16.96-17.8zM51.49 48.1l3.63-4.63c.317-.245.525-.604.58-1-.01-.4-.226-.764-.57-.967-.342-.203-.765-.215-1.12-.033l-.1.06h-.05l-9.33 5.83c-8.166-2.71-12.97-11.146-11.134-19.552 1.835-8.406 9.72-14.072 18.27-13.13 8.554.94 15.018 8.183 14.982 16.787C66.613 40.07 60.09 47.26 51.53 48.13l-.04-.03z' />
          <path d='M49.59 25.12c-2.607-.012-4.965 1.55-5.97 3.955-1.007 2.405-.464 5.18 1.376 7.028 1.84 1.848 4.61 2.405 7.022 1.41C54.428 36.518 56 34.167 56 31.56c0-3.545-2.865-6.423-6.41-6.44zm0 11.88c-2.203.012-4.196-1.306-5.047-3.337-.852-2.032-.394-4.377 1.16-5.94 1.553-1.56 3.895-2.032 5.932-1.192C53.67 27.377 55 29.36 55 31.56c0 2.993-2.417 5.423-5.41 5.44z' />
        </g>
      </svg>
    );
  }
}

export default CatalogServicesDataRecovery;
