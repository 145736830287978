import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Header,
  Icon,
  List,
  Message,
  Segment,
} from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Waypoint } from 'react-waypoint';
import { intl } from 'esp-util-intl';

// utils
import uiPathGenerator from '../../utils/uiPathGenerator';
import getDueDate from '../../utils/getDueDate';
import OnboardActivitiesCategory from '../../globals/OnboardActivitiesCategory';
// Atom
import ScrollArea from '../atoms/ScrollArea';
// molecules
import EspListItem from './EspListItem';
import OnboardTemplateIntervalModal from './OnboardTemplateIntervalModal';

class OnboardingActivityScheduleTemplate extends PureComponent {
  static propTypes = {
    /** Action on a schedule ID (copy) */
    action: PropTypes.string,
    /** Loading state */
    isLoading: PropTypes.bool,
    /** Load next page */
    loadTemplates: PropTypes.func.isRequired,
    /** Next template page */
    next: PropTypes.string,
    /** Schedule ID */
    scheduleID: PropTypes.string.isRequired,
    /** Array of items to display in the list */
    templateList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    action: null,
    isLoading: false,
    next: null,
    templateList: Immutable.List(),
  };

  handleBottomWaypointEnter = () => {
    const { loadTemplates } = this.props;
    loadTemplates();
  };

  render() {
    const { action, scheduleID, templateList, isLoading, next } = this.props;

    const modalTrigger = (
      <Button basic circular floated='left' icon='plus' primary />
    );

    return (
      <div
        className='onboarding-activity-schedule-list'
        style={{
          height: '100%',
          margin: '-1em',
          minHeight: '70vh',
        }}
      >
        {/* NOTE: We will probably need to add filtering tools here at some point. */}
        <Header as='h4' content='Templates' dividing />
        <ScrollArea height='calc(100% - 52px)'>
          {/* TODO: Add styles so that these buttons show up correctly. */}
          {templateList && !templateList.isEmpty() ? (
            templateList.valueSeq().map((item) => {
              const ImgElement = OnboardActivitiesCategory.getIn([
                item.get('category'),
                'image',
              ])
                ? OnboardActivitiesCategory.getIn([
                    item.get('category'),
                    'image',
                  ])
                : null;

              return (
                <div className='segment-card' key={item.get('id')}>
                  <OnboardTemplateIntervalModal
                    action={action}
                    intervalID='new'
                    scheduleID={scheduleID}
                    templateID={item.get('id')}
                    trigger={modalTrigger}
                  />

                  <Segment as={List} compact>
                    {/* NOTE2: item.internalDescription is still not supported by the API, I use private_notes for now */}
                    <EspListItem
                      action={
                        <Button
                          as={Link}
                          basic
                          content='view'
                          to={uiPathGenerator.genPath(
                            'admin.adminActivities.onboardingActivities.templates',
                            {
                              templateID: item.get('id'),
                            }
                          )}
                        />
                      }
                      attachedHeader={item.get('private_notes')}
                      description={item.get('description')}
                      header={item.get('title')}
                      image={ImgElement && <ImgElement />}
                      meta={getDueDate(item)}
                    />
                  </Segment>
                </div>
              );
            })
          ) : (
            <Message
              content='Click the button above to add your first onboard task template.'
              info
            />
          )}

          {isLoading && templateList && !templateList.isEmpty() && (
            <Message icon>
              <Icon loading name='circle notched' />
              <Message.Content>
                {intl.formatMessage({
                  id: 'label.loading_more',
                })}
              </Message.Content>
            </Message>
          )}

          {next && !isLoading && (
            <Waypoint key={next} onEnter={this.handleBottomWaypointEnter} />
          )}

          <Divider hidden />
        </ScrollArea>
      </div>
    );
  }
}

export default OnboardingActivityScheduleTemplate;
