import React, { useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Boundaries } from 'cascara-middleware';
import ScheduleButtonsStyle from '../../../../../../globals/ScheduleButtonsStyle/ScheduleButtonsStyle.module.scss';
import useScheduleWeekDay from '../hooks/useScheduleWeekDay';
// import ScheduleButtonsStyle from '../../../../../../globals/ScheduleButtonsStyle/ScheduleButtonsStyle.module.scss';

const propTypes = {
  buttonsSize: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.string),
};

const ScheduleWeekDayButtons = ({
  selectedDays = [],
  disabled,
  buttonsSize = 'medium',
  onClick,
}) => {
  const { weekDayMap, toggleWeekDay } = useScheduleWeekDay(selectedDays);
  const handleClick = useCallback(
    (_, { content }) => {
      onClick(toggleWeekDay(content));
    },
    [onClick, toggleWeekDay]
  );

  return (
    <Boundaries>
      <div className={ScheduleButtonsStyle._}>
        {Array.from(weekDayMap.keys()).map((day) => {
          const activeColor = weekDayMap.get(day) ? 'grey' : void 0;
          return (
            <Button
              basic={!activeColor}
              color={activeColor}
              content={day}
              disabled={disabled}
              key={day}
              onClick={handleClick}
              size={buttonsSize}
              type='button'
            />
          );
        })}
      </div>
    </Boundaries>
  );
};

ScheduleWeekDayButtons.propTypes = propTypes;

export default ScheduleWeekDayButtons;
