import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  Table,
} from 'semantic-ui-react';
import React, { useCallback } from 'react';

import AdminHelpContext from './AdminHelpContext';
import EspConfirmationModal from '../../../../../../v2/components/display/EspConfirmationModal';
import { Flex } from 'cascara-middleware';
import { Helmet } from 'react-helmet';
import { intl } from 'esp-util-intl';
import { LABELS } from './utils';
import TipModal from './TipModal';
import useAdminHelp from './hook/useAdminHelp';

const AdminHelp = () => {
  const {
    activeAccordionIndex,
    closeModal,
    dispatch,
    handleAccordionClick,
    handleClose,
    handleColorChange,
    handleDelete,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleOpen,
    handleReset,
    handleSubmit,
    handleToggleChange,
    state,
    tenantInfo,
    mutateTenant,
    handleEdit,
  } = useAdminHelp();

  const providerValues = {
    activeAccordionIndex,
    closeModal,
    dispatch,
    handleAccordionClick,
    handleClose,
    handleColorChange,
    handleDelete,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleOpen,
    handleReset,
    handleSubmit,
    handleToggleChange,
    mutateTenant,
    state,
    tenantInfo,
  };

  const headerRow = [LABELS.ACTIVE, LABELS.TIP_CONTENT, ''];

  const renderBodyRow = useCallback(
    ({ content, isActive, texts }, rowIndex) => ({
      cells: [
        <Form.Group key={`toggle-${rowIndex}`}>
          <Form.Checkbox
            checked={isActive}
            data-row={rowIndex}
            data-testid={`toggleBtn-${rowIndex}`}
            onChange={handleToggleChange}
            toggle
          />
        </Form.Group>,
        <Form.Group key={`content-${rowIndex}`}>
          <Form.Field data-testid={`content-${rowIndex}`}>{content}</Form.Field>
        </Form.Group>,
        <Form.Group key={`edit-${rowIndex}`}>
          {texts && (
            <Flex>
              <Button
                basic
                data-rowindex={rowIndex}
                data-testid={`edit-${rowIndex}`}
                disabled={state.isLoading}
                icon='pencil'
                loading={state.isLoading}
                onClick={handleEdit}
                type='button'
              />
              <Button
                basic
                data-rowindex={rowIndex}
                data-testid={`delete-${rowIndex}`}
                disabled={state.isLoading}
                icon='trash'
                loading={state.isLoading}
                negative
                onClick={handleDelete}
                type='button'
              />
            </Flex>
          )}
        </Form.Group>,
      ],
      key: content || `row-${rowIndex}`,
    }),
    [handleDelete, handleEdit, handleToggleChange, state.isLoading]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Segment attached style={{ borderTop: 'none' }}>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_global_settings',
          })}
        />

        <p>{LABELS.PAGE_DESCRIPTION}</p>

        <Form as='div'>
          <div>
            <Form.Input
              id='bubbleColor'
              label={LABELS.COLOR_FIELD}
              name='bubbleColor'
              onChange={handleColorChange}
              pattern={'^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'}
              required
              title={LABELS.COLOR_ERROR_MESSAGE}
              value={state.form.color}
              width={3}
            />

            <p>
              <em>{LABELS.COLOR_FIELD_DESCRIPTION}</em>
            </p>
          </div>
          <div>
            <Divider hidden />
            <Flex>
              <div>
                <Header as={'h4'}>{LABELS.TIP_CONTENT}</Header>
                <p>{LABELS.TABLE_DESCRIPTION}</p>
              </div>
              <AdminHelpContext.Provider value={providerValues}>
                <TipModal
                  isNew={!state.modalForm.currentMessage}
                  trigger={
                    <Button
                      basic
                      content={LABELS.ADD_TIP}
                      disabled={state.isLoading}
                      loading={state.isLoading}
                      type='button'
                    />
                  }
                />
              </AdminHelpContext.Provider>
            </Flex>
          </div>
        </Form>
        {state.form.messages.length > 0 && (
          <Table
            compact
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            size='small'
            tableData={state.form.messages}
          />
        )}
      </Segment>
      <Segment attached='bottom' clearing secondary>
        <Button
          aria-label={LABELS.SAVE}
          basic
          content={LABELS.SAVE}
          disabled={!state.form.dirty || state.isLoading}
          floated='right'
          loading={state.isLoading}
          primary
          type='submit'
        />
        <Button
          aria-label={LABELS.CANCEL}
          basic
          content={LABELS.CANCEL}
          disabled={!state.form.dirty || state.isLoading}
          floated='right'
          loading={state.isLoading}
          onClick={handleReset}
          type='button'
        />
      </Segment>

      <EspConfirmationModal
        cancelButton={LABELS.CANCEL}
        confirmButton={
          <Button content={LABELS.CONFIRM} negative type='button' />
        }
        content={LABELS.DELETE_CONFIRM_CONTENT}
        header={LABELS.DELETE_CONFIRM_HEADER}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        open={state.isDeleteConfirmOpen}
      />
    </form>
  );
};

export default AdminHelp;
