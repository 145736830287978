// utils
import RollbarConfig from '../utils/RollbarConfig';

// Selectors
import getCurrentUser from '../selectors/getCurrentUser';
import getTenantMenuConfiguration from '../selectors/getTenantMenuConfiguration';

const startGoogleAnalytics = (...rest) => {
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    // eslint-disable-next-line no-unused-expressions -- Not sure if this is used by the google script
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(rest);
        // eslint-disable-next-line no-sequences -- Not sure if this is used by the google script
      }),
      (i[r].l = Number(new Date()));
    // eslint-disable-next-line prefer-destructuring,  no-unused-expressions, no-sequences -- DEV-15308,  Not sure if this is used by the google script
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga'
  );

  const messurementId = 'G-TWFKYS15GY';
  window.ga('create', messurementId, 'auto');
  window.ga('send', 'pageview');
};

const analyticsThunks = {
  endTracking: () => () => {},

  startTracking: () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const currentUser = getCurrentUser(state);
      const rollbarEnabled = state.getIn(['appUI', 'rollbarEnabled']);
      const rollbarEnvironment = state.getIn(['appUI', 'rollbarEnvironment']);

      // Google Analytics enablement
      const tenantConfig = getTenantMenuConfiguration(state);
      if (!tenantConfig.get('disable_ga')) {
        // this comes from tenant ui_config, so it's opt-out per tenant
        startGoogleAnalytics();
      }

      if (currentUser) {
        RollbarConfig.start(
          rollbarEnabled,
          rollbarEnvironment,
          currentUser.get('eid')
        );

        resolve();
      } else {
        reject(new Error('Analytics initialized without currentUser'));
      }
    }),
};

export default analyticsThunks;
