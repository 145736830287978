const api_workflow_v0_1_workflowconditionset = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      condition_entry: [
        {
          conditions: null,
          description: 'Condition 19427dfc-5949-44b9-8fcd-5016a6f41c09',
          eid: '32dc78c4-c539-43a3-af82-5a0d8ae265cf',
          id: 126,
          object_instances: null,
          ordering: 0,
          success_workflow: null,
          success_workflow_task:
            'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/117/',
          sys_created_by: null,
          sys_custom_fields: null,
          sys_updated_by: null,
          url:
            'https://release.qa.espressive.com/api/workflow/v0.1/condition/126/',
        },
      ],
      failure_workflow: null,
      failure_workflow_task: null,
      id: 125,
      name: 'Condition Group 3260b133-f992-4669-a4ae-8103d052da6d',
      url:
        'https://release.qa.espressive.com/api/workflow/v0.1/workflowconditionset/125/',
    },
  ],
};

export default api_workflow_v0_1_workflowconditionset;
