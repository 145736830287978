import { fromJS } from 'immutable';
import integrationThunks from '../../../../v1/actions/integrationThunks';
import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import toastNotificationsActions from '../../../../v1/actions/toastNotificationsActions';

const TITLE = {
  ERROR: 'Error',
  SUCCESS: 'Success',
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  createCSVSchedule(formValues) {
    return new Promise((resolve, reject) => {
      dispatch(integrationThunks.createCSVSchedule(formValues))
        .then((schedule) => {
          dispatch(
            toastNotificationsActions.success({
              message: 'CSV schedule has being created',
              title: TITLE.SUCCESS,
            })
          );
          dispatch(integrationThunks.newTestCSVSchedule(schedule.id));
          resolve(fromJS(schedule));
        })
        .catch((e) => {
          dispatch(
            toastNotificationsActions.error({
              message: e.message,
              title: TITLE.ERROR,
            })
          );
          reject();
        })
        .finally(() => ownProps.onClose());
    });
  },

  updateCSVSchedule(scheduleID, formValues) {
    return new Promise((resolve, reject) => {
      dispatch(integrationThunks.updateCSVSchedule(scheduleID, formValues))
        .then((schedule) => {
          dispatch(
            toastNotificationsActions.success({
              message: 'CSV schedule has being updated',
              title: TITLE.SUCCESS,
            })
          );
          resolve(fromJS(schedule));
        })
        .catch((e) => {
          dispatch(
            toastNotificationsActions.error({
              message: e.message,
              title: TITLE.ERROR,
            })
          );
          reject();
        })
        .finally(() => ownProps.onClose());
    });
  },
});

const mapStateToProps = (state) => ({
  businessObjects: state.getIn(['integration', 'businessObjects']),
  isLoadingBusinessObject: state.getIn(['integration', 'isLoading']),
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
