import {
  getCustomMappedMessages,
  getDefaultMappedMessages,
  sortByActive,
} from '../utils';
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import deleteTranslation from '../api/deleteTranslation';
import getTranslations from '../api/getTranslations';
import GlobalContext from '../../../../../../../globals/GlobalContext';
import { intl } from 'esp-util-intl';
import reducer from '../reducer';
import types from '../types';
import updateTranslation from '../api/updateTranslation';
import { useMutateTenant } from '../../../../../../../customHooks';

const initialFormValue = {
  color: '#d3f4ef',
  dirty: false,
  messages: [],
};

const initialState = {
  customMessages: [],
  deleteItemIndex: null,
  form: initialFormValue,
  isDeleteConfirmOpen: false,
  isLoading: false,
  isTipModalOpen: false,
  modalForm: {
    currentMessage: null,
    dirty: false,
    messages: [],
    newMessage: '',
  },
};

const useAdminHelp = () => {
  const {
    tenant: { tenantInfo },
  } = useContext(GlobalContext);

  const mutateTenant = useMutateTenant();

  const tenantInfoRef = useRef({});

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleEdit = (e, element) => {
    dispatch({
      message: state.form.messages[element['data-rowindex']],
      type: types.SHOW_EDIT_MODAL,
    });
  };

  const handleOpen = () => {
    dispatch({ type: types.SHOW_MODAL });
  };
  const handleClose = () => {
    dispatch({ type: types.HIDE_MODAL });
  };

  const closeModal = () => {
    dispatch({ type: types.HIDE_MODAL });
  };

  useEffect(() => {
    const geCustomMessages = async () => {
      const translationMessages = await getTranslations();

      const customeMessages = getCustomMappedMessages(
        translationMessages?.body?.results
      );

      dispatch({
        custom_messages: customeMessages,
        custom_messages_raw: translationMessages?.body?.results,
        type: types.MERGE_CUSTOM_MESSAGES,
      });
    };

    geCustomMessages();
  }, []);

  useEffect(() => {
    if (JSON.stringify(tenantInfo) !== JSON.stringify(tenantInfoRef.current)) {
      tenantInfoRef.current = tenantInfo;

      dispatch({
        color: tenantInfo?.branding_obj?.help_message_color,
        custom_messages: [],
        messages: getDefaultMappedMessages({
          intl,
          tenantInfo,
        }),
        type: types.SET_FORM,
      });
    }
  }, [tenantInfo]);

  const [activeAccordionIndex, setAccordionIndex] = useState(0);

  const handleAccordionClick = useCallback(
    (e, titleProps) => {
      const { index } = titleProps;
      const newIndex = activeAccordionIndex === index ? -1 : index;
      setAccordionIndex(newIndex);
    },
    [activeAccordionIndex]
  );

  const handleReset = useCallback(() => {
    dispatch({
      color: tenantInfo?.branding_obj.help_message_color,
      messages: getCustomMappedMessages(state.customMessagesRaw).concat(
        getDefaultMappedMessages({
          intl,
          tenantInfo,
        })
      ),
      type: types.RESET_FORM,
    });
  }, [dispatch, state.customMessagesRaw, tenantInfo]);

  const handleColorChange = useCallback(
    (e, { value }) => {
      dispatch({
        color: value,
        type: types.SET_COLOR,
      });
    },
    [dispatch]
  );

  const handleToggleChange = useCallback(
    (e, values) => {
      const newMessages = [...state.form.messages];
      newMessages[values['data-row']].isActive = values?.checked;

      dispatch({
        messages: newMessages.sort(sortByActive),
        type: types.SET_MESSAGES,
      });
    },
    [dispatch, state.form.messages]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const update = async () => {
        const newData = {
          ...tenantInfo,
        };
        newData.branding_obj.help_message_color = state.form.color;
        const customMessages = [];
        newData.ui_config.helpSettings = state.form.messages.reduce(
          (helpMessages, toggleButton) => {
            const isNotCustomMessage = !toggleButton?.texts;
            if (isNotCustomMessage) {
              helpMessages[toggleButton.messageKey] = toggleButton.isActive;
            } else {
              toggleButton.rawData.text = JSON.stringify({
                isActive: toggleButton.isActive,
                texts: toggleButton.texts,
              });
              customMessages.push(toggleButton);
            }
            return helpMessages;
          },
          {}
        );

        try {
          const mutationActions = async () => {
            for (const customMessage of customMessages) {
              await updateTranslation({
                id: customMessage.rawData.id,
                text: customMessage.rawData.text,
              });
            }

            dispatch({
              custom_messages: customMessages,
              custom_messages_raw: customMessages.map(
                (message) => message.rawData
              ),
              type: types.UPDATE_FORM,
            });
          };

          mutateTenant.mutate({
            data: newData,
            mutationActions,
            tenantID: tenantInfo.id,
          });
        } catch (error) {
          // eslint-disable-next-line no-console -- For debugging purposes
          console.error(error);
        }
      };

      dispatch({ type: types.TOGGLE_LOADING });

      try {
        update();
      } catch (error) {
        // eslint-disable-next-line no-console -- debugging purposes
        console.error(error);
      }
    },
    [dispatch, state.form.color, state.form.messages, tenantInfo, mutateTenant]
  );

  const handleDelete = (e, element) => {
    dispatch({
      index: element['data-rowindex'],
      type: types.SHOW_CONFIRM,
    });
  };

  const handleDeleteCancel = () => {
    dispatch({ type: types.HIDE_CONFIRM });
  };

  const handleDeleteConfirm = () => {
    const message = state.form.messages[state.deleteItemIndex];

    const newCustomRawMessages = state.customMessagesRaw.filter(
      (rawMessage) => rawMessage.id !== message.rawData.id
    );
    const deleteItem = async () => {
      try {
        await deleteTranslation(message.rawData.id);
      } catch (error) {
        // eslint-disable-next-line no-console -- debugging purposes
        console.error(error);
      } finally {
        dispatch({
          custom_messages_raw: newCustomRawMessages,
          index: state.deleteItemIndex,
          type: types.DELETE_TIP_GROUP,
        });
      }
    };

    deleteItem();
  };

  return {
    activeAccordionIndex,
    closeModal,
    dispatch,
    handleAccordionClick,
    handleClose,
    handleColorChange,
    handleDelete,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleEdit,
    handleOpen,
    handleReset,
    handleSubmit,
    handleToggleChange,
    mutateTenant,
    state,
    tenantInfo,
  };
};

export { initialState };

export default useAdminHelp;
