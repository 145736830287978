import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { fromJS } from 'immutable';
// Molecules
import ValidatedField from '../../molecules/ValidatedField';
import FormInputText from '../../molecules/FormInputText';
// Controller
import SkipTask01Controller from '../../controllers/SkipTask01Controller';

import TypeSkipTask, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSkipTask';

class SkipTask01 extends Component {
  static propTypes = {
    ...TypeSkipTask,
    handleSkip: PropTypes.func,
    resetValue: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    handleSkip: _.noop,
    resetValue: _.nop,
  };

  componentDidMount() {
    const {
      forceReset,
      tempDataName,
      inValidValue,
      resetValue,
      setAlternateSkip,
      setAlternateSubmit,
    } = this.props;

    setAlternateSkip(this.handleSkipped);
    setAlternateSubmit(this.handleSubmit);

    if (forceReset) {
      resetValue(tempDataName, inValidValue); // Reset BE scratch value of the temp_data attribute
    }
  }

  skipped = false;

  handleSubmit = (cb) => {
    const { inValidValue, validValue, id } = this.props;

    const obj = {};

    obj[id] = inValidValue;

    if (this.skipped) {
      obj[id] = validValue;
    }

    cb(fromJS(obj));
  };

  handleSkipped = () => {
    const { id, handleSkip, validValue } = this.props;

    this.skipped = true;
    handleSkip(id, validValue);
  };

  render() {
    const { template, type, id } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <ValidatedField component={FormInputText} hidden name={id} />
      </div>
    );
  }
}

export const SkipTask01Test = SkipTask01;

export default SkipTask01Controller(SkipTask01);
