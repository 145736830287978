import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { intl } from 'esp-util-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';
import Immutable from 'immutable';
import { Button, Form, List, Message } from 'semantic-ui-react';
import EmailRegExp from '../../globals/EmailRegExp';
import { FormikWithSemanticUI } from 'esp-ui-form';
import EspModal from '../../../v2/components/display/EspModal';
import AnnouncementTestController from '../controllers/AnnouncementTestController';

let AnnouncementTest = class AnnouncementTest extends Component {
  static propTypes = {
    announcement: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
    emailStatus: PropTypes.number,
    errors: PropTypes.objectOf(PropTypes.string),
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    invalidEmails: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
    isNew: PropTypes.bool,
    isValid: PropTypes.bool,
    resetErrors: PropTypes.func,
    shouldDisplaySlackNote: PropTypes.bool,
    testAnnouncement: PropTypes.func,
    values: PropTypes.shape({
      emails: PropTypes.string,
    }),
  };

  static defaultProps = {
    disabled: false,
    emailStatus: 0,
    errors: {},
    invalidEmails: Immutable.List(),
    isLoading: false,
    isNew: false,
    isValid: false,
    resetErrors: noop,
    shouldDisplaySlackNote: false,
    testAnnouncement: noop,
    values: {},
  };

  state = {
    isModalOpen: false,
  };

  componentWillUnmount() {
    const { resetErrors } = this.props;
    resetErrors();
  }

  handleToggleModalState = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleSubmit = () => {
    const {
      announcement,
      testAnnouncement,
      values: { emails },
    } = this.props;
    testAnnouncement(emails, announcement.get('id'));
  };

  renderFormContent = () => {
    const {
      emailStatus,
      errors,
      handleBlur,
      handleChange,
      invalidEmails,
      values,
      shouldDisplaySlackNote,
    } = this.props;

    const label = (
      <p>
        <strong>
          {intl.formatMessage({
            id: 'label.test_recipients_emails',
          })}
        </strong>
      </p>
    );
    const successMessage = (
      <Message positive>
        {intl.formatMessage({ id: 'label.announcement_test_success' })}
      </Message>
    );
    const EMAIL_CHAR_LIMIT = 35;
    const errorMessage = (
      <Message negative>
        {
          // by now we don't have the copy for multiple fail errors
          invalidEmails.size === 1
            ? intl.formatMessage({
                id: 'label.announcement_test_fail',
              })
            : `${intl.formatMessage({
                id: 'label.announcement_test_fail',
              })}:`
        }
        <List
          bulleted
          items={invalidEmails
            .map((email) => {
              return email.length > EMAIL_CHAR_LIMIT
                ? `${email.slice(0, EMAIL_CHAR_LIMIT)}...`
                : email;
            })
            .toArray()}
        />
      </Message>
    );

    const successStatus = 200;
    const showSuccessMessage = emailStatus === successStatus;
    const showFailMessage = emailStatus !== successStatus && emailStatus !== 0;

    return (
      <Form>
        {showSuccessMessage && successMessage}
        {showFailMessage && errorMessage}
        <Form.TextArea
          data-testid='emails-textarea'
          error={errors.emails}
          id='emails-textarea'
          label={label}
          name='emails'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Ex. email@example.com, email2@example.com, email3@example.com'
          value={values.emails}
        />
        <Form.Field>
          <ul>
            <li>
              {intl.formatMessage({
                id: 'message.test_announcement_emails_instructions_1',
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: 'message.test_announcement_emails_instructions_2',
              })}
            </li>
            {shouldDisplaySlackNote && (
              <li>
                {intl.formatMessage({
                  id: 'message.test_announcement_emails_instructions_3',
                })}
              </li>
            )}
          </ul>
        </Form.Field>
      </Form>
    );
  };

  render() {
    const { disabled, isLoading, isValid, emailStatus, isNew } = this.props;
    const { isModalOpen } = this.state;

    const modalTrigger = (
      <Button
        basic
        content={intl.formatMessage({ id: 'label.test' })}
        disabled={disabled || isLoading || isNew}
        loading={isLoading}
        primary
      />
    );

    const cloneTrigger = cloneElement(modalTrigger, {
      onClick: this.handleToggleModalState,
    });

    const sendLabel = isLoading
      ? intl.formatMessage({ id: 'label.sending_test' })
      : emailStatus === 0
      ? intl.formatMessage({ id: 'label.send_test' })
      : intl.formatMessage({ id: 'label.send_another_test' });

    const SendButton = () => (
      <Button
        basic
        content={sendLabel}
        disabled={isLoading || !isValid}
        loading={isLoading}
        onClick={this.handleSubmit}
        positive
        type={'button'}
      />
    );
    return (
      <EspModal
        actions={<SendButton />}
        content={this.renderFormContent()}
        contentPadding='1em'
        header={intl.formatMessage({ id: 'label.test_announcement' })}
        onClose={this.handleToggleModalState}
        open={isModalOpen}
        preScrollContent={void 0}
        preScrollContentHeight={73}
        trigger={cloneTrigger}
      />
    );
  }
};
AnnouncementTest = FormikWithSemanticUI({
  enableReinitialize: true,
  mapPropsToValues: (/* props*/) => ({
    emails: '',
  }),
  validate: (values) => {
    const errors = {};
    const emails = values.emails.split(',');
    if (emails.length === 0) {
      errors.emails = intl.formatMessage({
        id: 'message.invalid_email',
      });
    }
    emails.forEach((email) => {
      if (!EmailRegExp.test(email.trim())) {
        errors.emails = intl.formatMessage({
          id: 'message.invalid_email',
        });
      }
    });
    return errors;
  },
})(AnnouncementTest);
export default AnnouncementTestController(AnnouncementTest);
