import buildCompositeController from '../../utils/buildCompositeController';

// Selections
import getCurrentUser from '../../selectors/getCurrentUser';
import { getChannelMessages } from '../../selectors/getMessages';
import getBaristaId from '../../selectors/getBaristaId';
// Actions
import chatThunks from '../../actions/chatThunks';
import casesThunks from '../../actions/casesThunks';
// Globals
import ChannelTypes from '../../globals/ChannelTypes';

const mapStateToProps = (state, ownProps) => {
  let channelType;

  const withUserId = ownProps && ownProps.withUserId;
  // case where the entry point is a user id
  // and we need to find the direct channel with that user
  // 'b0e2eb1a-a020-470a-8ace-917dd52003f6'

  const channel = state.getIn([
    'entities',
    'tasks',
    ownProps.taskId,
    'channel',
  ]);
  const messages = getChannelMessages(state, channel);

  let selectedChannelId;

  // channel state
  const isLoadedChannel =
    channelType === ChannelTypes.SUPPORT_CHANNEL
      ? state.getIn(['barista', 'supportChannelState', 'isLoadedChannel'])
      : channelType === ChannelTypes.DIRECT_CHANNEL
      ? state.getIn([
          'chatState',
          'directChannelState',
          withUserId,
          'isLoadedChannel',
        ])
      : state.getIn([
          'chatState',
          'scopedChannelState',
          selectedChannelId,
          'isLoadedChannel',
        ]);
  const isLoadingChannel =
    channelType === ChannelTypes.SUPPORT_CHANNEL
      ? state.getIn(['barista', 'supportChannelState', 'isLoadingChannel'])
      : channelType === ChannelTypes.DIRECT_CHANNEL
      ? state.getIn([
          'chatState',
          'directChannelState',
          withUserId,
          'isLoadingChannel',
        ])
      : state.getIn([
          'chatState',
          'scopedChannelState',
          selectedChannelId,
          'isLoadingChannel',
        ]);
  const errorMsg =
    channelType === ChannelTypes.SUPPORT_CHANNEL
      ? state.getIn(['barista', 'supportChannelState', 'errorMsg'])
      : channelType === ChannelTypes.DIRECT_CHANNEL
      ? state.getIn(['chatState', 'directChannelState', withUserId, 'errorMsg'])
      : state.getIn([
          'chatState',
          'scopedChannelState',
          selectedChannelId,
          'errorMsg',
        ]);
  const caseAuthor = state.getIn([
    'entities',
    'tasks',
    ownProps.taskId,
    'author',
  ]);

  return {
    baristaID: getBaristaId(state),
    caseAuthor,
    channel,
    currentUser: getCurrentUser(state),
    errorMessage: errorMsg,
    isLoadedChannel: isLoadedChannel,
    isLoadingChannel: isLoadingChannel,
    messages: messages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadMessages: (channelType, loadPaginatedResults = false, taskId) => {
    dispatch(casesThunks.loadCaseTask(taskId)).then((task) => {
      dispatch(
        chatThunks.loadMessages(task.channel, channelType, loadPaginatedResults)
      );
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
