import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { debounce } from 'lodash';
// molecules
import FormInputSelect from './FormInputSelect';

// controller
import FormInputSelectIntegrationTableController from '../controllers/FormInputSelectIntegrationTableController';

const SNOW_SYS_ID = 'sys_id';
const SEARCH_TIMEOUT = 300;

class FormInputSelectIntegrationTable extends PureComponent {
  static propTypes = {
    displayValue: PropTypes.string,
    field: PropTypes.string, // if used, it will use a different API to get the choices for a column
    isLoading: PropTypes.bool,
    loadTableRecords: PropTypes.func.isRequired,
    realValue: PropTypes.string,
    records: ImmutablePropTypes.list,
    refQualCondition: PropTypes.string, // reference qualitfiers
    tableName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    displayValue: SNOW_SYS_ID, // if we don't have a display name we're gonna assume ServiceNow sys_id
    field: '',
    isLoading: false,
    realValue: SNOW_SYS_ID, // same with this
    records: Immutable.List(),
    refQualCondition: '',
  };

  componentDidMount() {
    const { field, loadTableRecords, refQualCondition, tableName } = this.props;

    loadTableRecords(tableName, '', field, refQualCondition);
  }

  handleOnSearch = debounce((e, { searchQuery }) => {
    const { tableName, field, loadTableRecords, refQualCondition } = this.props;

    if (searchQuery) {
      loadTableRecords(tableName, searchQuery, field, refQualCondition);
    }
  }, SEARCH_TIMEOUT);

  render() {
    const {
      displayValue,
      isLoading,
      realValue,
      records,
      refQualCondition,
      ...otherProps
    } = this.props;

    const options = records
      .map((res) => ({
        key: res.get(SNOW_SYS_ID),
        text: res.get(displayValue),
        value: res.get(realValue),
      }))
      .toJS();

    return (
      <FormInputSelect
        {...otherProps}
        loading={isLoading}
        onSearchChange={this.handleOnSearch}
        options={options}
        search
        selectOnBlur={false}
      />
    );
  }
}

const FormInputSelectIntegrationTableTest = FormInputSelectIntegrationTable;

// eslint-disable-next-line no-class-assign -- DEV-1526
FormInputSelectIntegrationTable = FormInputSelectIntegrationTableController(
  FormInputSelectIntegrationTable
);
export { FormInputSelectIntegrationTableTest };
export default FormInputSelectIntegrationTable;
