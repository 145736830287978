import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import _ from 'lodash';
// Molecule
import ValidatedSelect from '../../molecules/ValidatedSelect';
// Controller
import SelectServiceTeam01Controller from '../../controllers/SelectServiceTeam01Controller';
// Globals
import TypeSelectServiceTeam, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectServiceTeam';
// Util
import injectIntlToController from '../../../utils/injectIntlToController';

class SelectServiceTeam01 extends Component {
  static propTypes = {
    ...TypeSelectServiceTeam,
    departmentId: PropTypes.number,
    isLoadingTeam: PropTypes.bool,
    loadServiceTeamList: PropTypes.func,
    setScratch: PropTypes.func,
    teamList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    ...TypeDefaults,
    isLoadingTeam: false,
    loadServiceTeamList: _.noop,
    setScratch: _.noop,
    teamList: Immutable.List(),
  };

  componentDidMount() {
    const { departmentId, loadServiceTeamList } = this.props;

    loadServiceTeamList(departmentId);
  }

  handleSearch = (e, query) => {
    const { departmentId, loadServiceTeamList } = this.props;

    loadServiceTeamList(departmentId, query.searchQuery);
  };

  handleChange = (e, value) => {
    const { setScratch } = this.props;
    setScratch(value);
  };

  render() {
    const {
      departmentId,
      id,
      isLoadingTeam,
      labelText,
      teamList,
      template,
      type,
    } = this.props;

    const options = [];
    teamList.forEach((team) => {
      options.push({
        id: team.get('id').toString(),
        text: team.get('name'),
        value: team.get('eid'),
      });
    });

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {!departmentId ? (
          <Message
            content={
              'No department ID has been passed. This Workflow may not have be launched on the Admin FAQ'
            }
            header='Error'
            negative
          />
        ) : (
          <ValidatedSelect
            label={labelText}
            loading={isLoadingTeam}
            name={id.toString()}
            onChange={this.handleChange}
            onSearchChange={this.handleSearch}
            options={options}
            placeholder='Search...'
            search
          />
        )}
      </div>
    );
  }
}
const SelectServiceTeam01Test = SelectServiceTeam01;

// eslint-disable-next-line no-class-assign -- DEV-1526
SelectServiceTeam01 = SelectServiceTeam01Controller(SelectServiceTeam01);
export { SelectServiceTeam01Test };
export default injectIntlToController(SelectServiceTeam01);
