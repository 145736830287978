import { change, getFormSyncErrors } from 'redux-form/immutable';
import Immutable from 'immutable';
// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Selector
import getLocationDeptJobroleFAQ from '../../selectors/getLocationDeptJobroleFAQ';
// Action
import baristaActions from '../../actions/baristaActions';

const mapStateToProps = (state, ownProps) => {
  const idForm = ownProps.id;
  const responsesMap = state.hasIn(['form', 'Form01', 'values', idForm])
    ? state.getIn(['form', 'Form01', 'values', idForm])
    : Immutable.Map();

  // Should we create a new reponse ?
  const indexResponse = responsesMap.size;
  const isNewResponse = Boolean(
    indexResponse &&
      state.getIn(['workflowState', 'frontendScratch', 'createNewResponse'])
  );

  const responsesFaq = state.getIn([
    'form',
    'Form01',
    'values',
    'responsesFaq',
  ]);
  const imageNewFaq = state.getIn(['adminFaqs', 'imageNewFaq']);
  const charLength = responsesFaq ? responsesFaq.length : 0;
  const conditionHeader = getLocationDeptJobroleFAQ(state);

  return {
    charLength,
    conditionHeader,
    formErrors: getFormSyncErrors('Form01')(state),
    imageNewFaq,
    isNewResponse,
    responsesFaq,
    responsesMap,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFile(files) {
    dispatch(baristaActions.addFiletoFAQ(files));
  },
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
