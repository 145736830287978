import workflowBrowserPageThunks from '../../actions/workflowBrowserPageThunks';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const getWorkflowOptions = (state) =>
    state
      .getIn(['editor', 'workflows', 'workflows'])
      .map((w) => ({
        id: w.get('id'),
        text: w.get('name'),
        value: w.get('id'),
      }))
      .toJS();

  return {
    isLoading: state.getIn(['editor', 'workflows', 'isLoading']),
    modalOpen: state.getIn(['editor', 'workflows', 'modalOpen']),
    workflowsOptions: getWorkflowOptions(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToWorkflowPage: (param, workflowID) => {
    dispatch(workflowBrowserPageThunks.goToWorkflowPage(workflowID));
  },
  loadWorkflows: () => {
    dispatch(workflowBrowserPageThunks.loadWorkflows());
  },
  removeFormErrorHandler: () => {
    dispatch(workflowBrowserPageThunks.removeNewWorkflowFromError());
  },
});

const WorkflowBrowserPageController = (component) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
export default WorkflowBrowserPageController;
