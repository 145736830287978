import { Button, Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { curry, memoize, noop } from 'lodash';

import ValidatedField from '../molecules/ValidatedField';

class EmailAddressRulesFieldArray extends PureComponent {
  static propTypes = {
    fields: PropTypes.shape({
      getAll: PropTypes.func,
      name: PropTypes.string,
      push: PropTypes.func,
      remove: PropTypes.func,
    }).isRequired,
    isLoading: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    isSaveButtonDisabled: false,
    onSubmit: noop,
  };

  handleAddNewRuleButtonClick = () => {
    const { fields } = this.props;

    fields.push('');
  };

  handleRemoveRuleButtonClick = memoize(
    curry((index, e) => {
      const { fields } = this.props;

      fields.remove(index);
    })
  );

  render() {
    const { fields, isLoading, isSaveButtonDisabled, onSubmit } = this.props;

    const addressRules = fields.getAll();

    return (
      <Form onSubmit={onSubmit}>
        {addressRules.map((addressRule, i) => {
          const key = i;

          return (
            <Form.Field key={key}>
              <ValidatedField
                disabled={isLoading}
                icon={
                  <Icon
                    link
                    name='delete'
                    onClick={this.handleRemoveRuleButtonClick(i)}
                  />
                }
                label={{
                  basic: true,
                  content: i + 1,
                }}
                name={`${fields.name}[${i}]`}
              />
            </Form.Field>
          );
        })}
        <Form.Field>
          <Button
            content='Save'
            disabled={isSaveButtonDisabled || isLoading}
            floated='right'
            loading={isLoading}
            positive
          />
          <Button
            basic
            content='Add New Rule'
            disabled={isLoading}
            floated='right'
            onClick={this.handleAddNewRuleButtonClick}
            type='button'
          />
        </Form.Field>
      </Form>
    );
  }
}

export default EmailAddressRulesFieldArray;
