import React from 'react';
import pt from 'prop-types';
import { Image } from 'semantic-ui-react';

const imagePropTypes = {
  alt: pt.string,
  src: pt.string,
};
const CustomKbImage = ({ alt, src }) => {
  return <Image alt={alt} centered src={src} />;
};

CustomKbImage.propTypes = imagePropTypes;

export default CustomKbImage;
