import React from 'react';
import pt from 'prop-types';
import { Helmet } from 'react-helmet';
import { Boundaries, Flex } from 'cascara-middleware';
import styles from './PageTitle.module.scss';

const propTypes = {
  // Content can show before the title
  post: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  // Content can show after the title
  pre: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  // A title can have sub content
  sub: pt.string,
  // The title string
  title: pt.string,
};

const PageTitle = ({ post, pre, sub, title }) => {
  const titleTag = title && (
    <h2 className={styles.Title}>
      {title} {sub && <div className={styles.Sub}>{sub}</div>}
    </h2>
  );

  const isOnlyTitle = (pre || post) === undefined;

  const documentTitle = sub ? `${title} - ${sub}` : title;

  return (
    <Boundaries>
      {title && (
        <Helmet>
          <title itemProp='name' lang='en'>
            {documentTitle}
          </title>
        </Helmet>
      )}
      {isOnlyTitle ? ( // Do not render any extra markup if we do not need to.
        titleTag
      ) : (
        <Flex className={styles._} space='start'>
          {pre}
          {titleTag}
          {post && (
            <Flex.Item push>
              <div>{post}</div>
            </Flex.Item>
          )}
        </Flex>
      )}
    </Boundaries>
  );
};

PageTitle.propTypes = propTypes;

export default PageTitle;
