import moment from 'moment';

moment.locale('en');

export const SupportedLocales = {
  en: {
    country: 'United States',
    flag: 'us',
  },
  // 'en-ca': {
  //   flag    : 'ca',
  //   country : 'Canada (english)',
  // },
  // 'en-au': {
  //   flag    : 'au',
  //   country : 'Australia',
  // },
  // 'uk': {
  //   flag    : 'gb',
  //   country : 'United Kingdom',
  // },
  es: {
    country: 'Mexico',
    flag: 'mx',
  },
  fr: {
    country: 'France',
    flag: 'fr',
  },
  // 'fr-ca': {
  //   flag    : 'ca',
  //   country : 'Canada (french)',
  // },
  // 'hi': {
  //   flag    : 'in',
  //   country : 'India',
  // },
  // 'ja': {
  //   flag    : 'jp',
  //   country : 'Japan',
  // },
  // 'ko': {
  //   flag    : 'kr',
  //   country : 'South Korea',
  // },
  // 'my': {
  //   flag    : 'my',
  //   country : 'Malaysia',
  // },
};

export default SupportedLocales;
