import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';
import { Button, Form, Select } from 'semantic-ui-react';
import classNames from 'classnames';
import ErrorLabel from '../atoms/ErrorLabel';
import UserImageContainer from './UserImageContainer';
// Controller
import FromSelectUserInputController from '../controllers/FromSelectUserInputController';

class FormSelectUserInput extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    formError: PropTypes.string,
    icon: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }).isRequired,
    label: PropTypes.string,
    loading: PropTypes.bool,
    meta: PropTypes.shape({
      active: PropTypes.bool,
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
    }),
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onResetAction: PropTypes.func,
    onSearchChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    removeErrorHandler: PropTypes.func,
    required: PropTypes.bool,
    search: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    searchResetResults: PropTypes.func,

    selectOnBlur: PropTypes.bool,

    selectedUser: ImmutablePropTypes.map,
    showErrorsWithoutSubmitting: PropTypes.bool,
    synchroError: PropTypes.bool,
    upward: PropTypes.bool,
    usersList: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.element,
        key: PropTypes.number,
        text: PropTypes.string,
        value: PropTypes.number,
      })
    ),
    width: PropTypes.number,
    widths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    defaultValue: null,
    disabled: false,
    fluid: false,
    formError: '',
    icon: void 0,
    inline: false,
    label: '',
    loading: false,
    meta: {
      active: false,
      error: '',
      submitFailed: false,
    },
    multiple: false,
    onChange: null,
    onClick: null,
    onResetAction: null,
    onSearchChange: null,
    placeholder: '',
    readOnly: false,
    removeErrorHandler: null,
    required: false,
    search: false,
    searchResetResults: null,

    selectOnBlur: true,

    selectedUser: immutable.Map(),
    showErrorsWithoutSubmitting: false,
    synchroError: false,
    upward: false,
    usersList: [],
    width: null,
    widths: null,
  };

  state = {
    usrSelected: null,
  };

  // It can use a customized change event or default
  handleChange = (event, data) => {
    const { input, onChange, removeErrorHandler, usersList } = this.props;

    removeErrorHandler && removeErrorHandler();

    const usr = usersList.find((usr) => usr.value === data.value);
    if (usr) {
      this.setState({
        usrSelected: {
          content: usr.content,
          image: <UserImageContainer avatar thumb userId={Number(usr.value)} />,
          key: 0,
          text: usr.text,
          value: usr.value,
        },
      });
    }

    if (onChange) {
      return onChange(event, data);
    }
    return input.onChange(data.value);
  };

  handleBlur = (event, data) => {
    const { input } = this.props;
    if (input.onBlur) {
      return input.onBlur(data.value);
    }
    // Avoid to reset the selected value by returning null
    // https://github.com/Semantic-Org/Semantic-UI-React/issues/1019
    return null;
  };

  // Prevents Enter event from propagating and causing weird behavior
  handleKeystroke = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  handleReset = (e) => {
    const { onResetAction } = this.props;
    const { usrSelected } = this.state;
    if (usrSelected) {
      this.setState({
        usrSelected: null,
      });
    }
    onResetAction(e);
  };

  render() {
    const {
      defaultValue,
      disabled,
      fluid,
      formError,
      icon,
      inline,
      input,
      label,
      loading,
      multiple,
      onClick,
      onSearchChange,
      onResetAction,
      placeholder,
      readOnly,
      required,
      search,
      searchResetResults,
      selectedUser,
      selectOnBlur,
      showErrorsWithoutSubmitting,
      synchroError,
      upward,
      usersList,
      width,
      widths,
      meta: { active, error, submitFailed },
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    const userName = selectedUser
      ? `${selectedUser.get('nickname')} ${selectedUser.get('last_name')}`
      : '';

    const optionUsers = [...usersList];
    const { usrSelected } = this.state;

    if (selectedUser && !selectedUser.isEmpty()) {
      optionUsers.push({
        key: 0,
        text: userName,
        value: selectedUser.get('id'),
      });
    } else if (usrSelected) {
      optionUsers.push(usrSelected);
    }

    const styleSelect =
      onResetAction &&
      ((selectedUser && !selectedUser.isEmpty()) || usrSelected)
        ? {
            marginRight: '1rem',
            maxWidth: 'calc(100% - 39px - 1rem)',
          }
        : null;

    return (
      <Form.Field
        className={classNames({
          'esp-select-user': true,
          required: required,
        })}
        inline={inline}
        width={width}
      >
        {label && <label htmlFor={input.name}>{label}</label>}
        <Select
          {...input}
          disabled={disabled}
          fluid={fluid}
          icon={icon}
          loading={loading}
          multiple={multiple}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={onClick || searchResetResults}
          onKeyDown={this.handleKeystroke}
          onKeyPress={this.handleKeystroke}
          onKeyUp={this.handleKeystroke}
          onSearchChange={onSearchChange}
          options={optionUsers}
          placeholder={placeholder}
          readOnly={readOnly}
          search={search}
          selectOnBlur={selectOnBlur}
          selection
          style={styleSelect}
          upward={upward}
          value={input.value}
          widths={widths}
        />
        {onResetAction &&
          ((selectedUser && !selectedUser.isEmpty()) || usrSelected) && (
            <Button
              icon='close'
              onClick={this.handleReset}
              style={{
                marginRight: 0,
              }}
            />
          )}
        {(formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )) ||
          (showErrorsWithoutSubmitting && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (!active && submitFailed && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          )) ||
          (synchroError && error && (
            <ErrorLabel error={error} inputName={input.name} pointing='above' />
          ))}
      </Form.Field>
    );
  }
}

export const FormSelectUserInputTest = FormSelectUserInput;

export default FromSelectUserInputController(FormSelectUserInput);
