import PropTypes from 'prop-types';

import React, { Component } from 'react';
import EspSoftwareSelectController from '../../controllers/EspSoftwareSelectController';
import Immutableproptypes from 'react-immutable-proptypes';
import { List } from 'semantic-ui-react';
import Immutable from 'immutable';
import injectIntlToController from '../../../utils/injectIntlToController';

// molecules
import CatalogListItem from '../../molecules/CatalogListItem';

// Globals
import TypeSoftwareSelect, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSoftwareSelect';
import BodyClassName from '../../../../globals/BodyClassName';

class SoftwareSelect01 extends Component {
  static propTypes = {
    ...TypeSoftwareSelect,
    // mapDispatchToProps
    getChildBundleProductsSoftware: PropTypes.func.isRequired,
    // mapStateToProps
    products: Immutableproptypes.listOf(
      Immutableproptypes.mapContains({
        category: Immutableproptypes.listOf(PropTypes.number).isRequired,
        description: PropTypes.string,
        id: PropTypes.number.isRequired,
        images: Immutableproptypes.listOf(
          Immutableproptypes.mapContains({
            image_480: PropTypes.string,
            image_960: PropTypes.string,
            image_custom: PropTypes.string,
            ordering: PropTypes.number,
            thumbnail: PropTypes.string,
          })
        ),
        name: PropTypes.string.isRequired,
        notification: PropTypes.string,
        path: PropTypes.string,
        price: PropTypes.string,
        review_summary: PropTypes.object,
        short_description: PropTypes.string,
      })
    ),
    softwareBundle: Immutableproptypes.map,
  };

  static defaultProps = {
    ...TypeDefaults,
    products: new Immutable.List(),
    softwareBundle: new Immutable.Map(),
  };

  componentDidMount() {
    const { getChildBundleProductsSoftware } = this.props;
    getChildBundleProductsSoftware();
  }

  render() {
    const { products, softwareBundle, type, template } = this.props;

    return (
      <BodyClassName className='blocks-top'>
        <div className='block' data-blocktype={type} data-template={template}>
          <List divided relaxed='very' verticalAlign='middle'>
            {products &&
              products.map((product) => (
                <CatalogListItem
                  categoryID={softwareBundle.get('category').first()}
                  isLink={false}
                  key={product.get('id')}
                  product={product}
                />
              ))}
          </List>
        </div>
      </BodyClassName>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SoftwareSelect01 = injectIntlToController(SoftwareSelect01);
// eslint-disable-next-line no-class-assign -- DEV-1526
SoftwareSelect01 = EspSoftwareSelectController(SoftwareSelect01);

export default SoftwareSelect01;
