/**
 * Build data's to display in the header of each response
 * @param locationdeptrole
 * @returns {{locationDepartment: (string|string), locationDeptRoleName: (string|string)}}
 */
const buildConditionHeader = (locationdeptrole) => {
  const listOfTypes = [
    'locations',
    'departments',
    'job_roles',
    'computerType',
    'mobileType',
    'deviceType',
  ];
  let locationDeptRoleName = ''; // Use to compare final condition
  let locationDepartment = ''; // name in use to be displayed

  listOfTypes.forEach((type) => {
    if (
      locationdeptrole &&
      locationdeptrole.has(type) &&
      !locationdeptrole.get(type).isEmpty()
    ) {
      const item = locationdeptrole.get(type);
      locationDeptRoleName += item
        .map((loc) => loc.get('id'))
        .toJS()
        .join();

      let name = type
        .split(/(?=[A-Z])/)
        .map((str) => str.toLowerCase())
        .join(' ');
      name = name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ');

      locationDepartment += locationDepartment ? `; ${name} :` : `${name} :`;

      item.forEach((loc) => {
        locationDepartment += ` ${loc.get('name')},`;
      });

      // remove trailing comma
      locationDepartment = locationDepartment.slice(0, -1); // last character
    }
  });

  // Check for unknow condition
  let customConditionName = '';
  locationdeptrole.forEach((value, key) => {
    const existingCondition = listOfTypes.indexOf(key);
    if (existingCondition === -1) {
      customConditionName = customConditionName
        ? `${customConditionName}; ${key}`
        : `Custom Condition(s): ${key}`;
      locationDeptRoleName += key;
    }
  });
  customConditionName =
    locationDepartment && customConditionName
      ? `; ${customConditionName}`
      : customConditionName;

  locationDeptRoleName = locationDeptRoleName || 'empty';
  locationDepartment = locationDepartment + customConditionName || 'All';

  return {
    locationDepartment,
    locationDeptRoleName,
    realLocationdeptrole: locationdeptrole,
  };
};

export default buildConditionHeader;
