import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form/immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';

import FormInputSelect from './FormInputSelect';
import FormInputRadio from './FormInputRadio';
import FormInputText from './FormInputText';
import FormInputCheckbox from './FormInputCheckbox';

class AddAttributeForm extends PureComponent {
  static propTypes = {
    existingAttribute: ImmutablePropTypes.map,
    formValues: ImmutablePropTypes.map,
    handleSubmit: PropTypes.func,
    objectMappingList: ImmutablePropTypes.list,
    taskAttributes: ImmutablePropTypes.map,
  };

  static defaultProps = {
    existingAttribute: Immutable.Map(),
    formValues: Immutable.Map(),
    handleSubmit: _.noop,
    objectMappingList: Immutable.List(),
    taskAttributes: Immutable.Map(),
  };

  render() {
    const {
      handleSubmit,
      formValues,
      objectMappingList,
      existingAttribute,
      taskAttributes,
    } = this.props;

    const objectModelsAsOptions = objectMappingList
      .filter((obj) => !obj.get('attributes').isEmpty())
      .map((obj, i) => ({
        key: i,
        text: obj.get('app_label'),
        value: obj.get('app_label'),
      }))
      .toJS();

    // Pass the temp_data object as option
    objectModelsAsOptions.push({
      key: objectModelsAsOptions.length,
      text: 'temp_data',
      value: 'temp_data',
    });

    const currentObjectMapping = objectMappingList.find(
      (obj) => obj.get('app_label') === formValues.get('model')
    );
    const isTempDataActive = formValues.get('model') === 'temp_data';

    let mappedAttributesList = [];
    if (formValues.get('model') && !isTempDataActive) {
      mappedAttributesList = currentObjectMapping
        .get('attributes')
        // Filtering out those attributes already used in the task
        .filter((permission, attr) => {
          const used = taskAttributes.some((attrInTask) => {
            // if it's the attribute being edit it, we show it
            if (
              existingAttribute.get('app_label') ===
                attrInTask.get('app_label') &&
              existingAttribute.get('name') === attrInTask.get('name')
            ) {
              return false;
            }

            // otherwise we check that is not being used in the task
            return (
              attrInTask.get('app_label') === formValues.get('model') &&
              attrInTask.get('name') === attr &&
              attrInTask.get('object') === formValues.get('object') &&
              attrInTask.get('storage') === formValues.get('storage')
            );
          });
          return !used;
        })
        .map((permission, attr) => ({
          text: attr,
          value: attr,
        }))
        .toList()
        .toJS();
    }

    let objectsAsOptions = [
      {
        text: 'self',
        value: 'self',
      },
      {
        text: 'workflowrequest.requested_for',
        value: 'workflowrequest.requested_for',
      },
    ];

    if (isTempDataActive) {
      objectsAsOptions = [
        {
          text: 'temp_data',
          value: 'temp_data',
        },
      ];
    }

    const storageAsOptions = [
      {
        text: 'scratch',
        value: 'scratch',
      },
      {
        text: 'null',
        value: null,
      },
    ];

    return (
      <Form inverted onSubmit={handleSubmit}>
        <Field
          component={FormInputSelect}
          fluid
          label='Model'
          name='model'
          options={objectModelsAsOptions}
          placeholder='Model'
          required
          search
          width={16}
        />

        <Form.Group inline>
          <Field
            component={FormInputSelect}
            label='Object'
            name='object'
            options={objectsAsOptions}
            placeholder='Object'
            required
            search
          />

          <Field
            component={FormInputSelect}
            label='Backend Storage'
            name='storage'
            options={storageAsOptions}
            placeholder='null'
            search
          />
        </Form.Group>

        {currentObjectMapping || isTempDataActive ? (
          <>
            {isTempDataActive ? (
              <Field
                component={FormInputText}
                label='Attribute'
                name='name'
                placeholder='Attribute'
                required
                search
              />
            ) : (
              <Field
                component={FormInputSelect}
                label='Attribute'
                name='name'
                options={mappedAttributesList}
                placeholder='Attribute'
                required
                search
              />
            )}

            <Form.Group inline>
              <label>{'Action Type'}</label>
              <Field
                component={FormInputRadio}
                disabled={!formValues.get('model')}
                label='Read'
                name='action'
                placeholder='Read'
                type='radio'
                value='readonly'
              />
              {(currentObjectMapping &&
                currentObjectMapping.getIn([
                  'attributes',
                  formValues.get('name'),
                ]) === 'write') ||
              isTempDataActive ? (
                <Field
                  component={FormInputRadio}
                  disabled={!formValues.get('model')}
                  label='Write'
                  name='action'
                  placeholder='Write'
                  type='radio'
                  value='write'
                />
              ) : null}

              <Field
                component={FormInputRadio}
                disabled={!formValues.get('model')}
                label='Validate'
                name='action'
                placeholder='Validate'
                type='radio'
                value='validate'
              />
            </Form.Group>
            <Field
              component={FormInputCheckbox}
              label='Should this attribute value be an object?'
              name='is_object'
              placeholder='Attribute'
              search
            />
          </>
        ) : null}
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  formValues: state.getIn(['form', 'AddAttributeForm', 'values']),
  initialValues: {
    action:
      (ownProps.existingAttribute &&
        ownProps.existingAttribute.get('action')) ||
      'readonly',
    attrKey: ownProps.existingKey,
    is_object: Boolean(
      ownProps.existingAttribute &&
        _.isObject(ownProps.existingAttribute.get('attribute_value'))
    ),
    model:
      (ownProps.existingAttribute &&
        ownProps.existingAttribute.get('app_label')) ||
      '',
    name:
      (ownProps.existingAttribute && ownProps.existingAttribute.get('name')) ||
      '',
    object:
      (ownProps.existingAttribute &&
        ownProps.existingAttribute.get('object')) ||
      'self',
    storage:
      (ownProps.existingAttribute &&
        ownProps.existingAttribute.get('storage')) ||
      null,
  },
});

// eslint-disable-next-line no-class-assign -- DEV-1526
AddAttributeForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'AddAttributeForm',

    validate(values) {
      const errors = {};

      if (_.isEmpty(values.get('name'))) {
        errors.name = 'Cannot be empty';
      }

      if (_.isEmpty(values.get('action'))) {
        errors.action = 'Cannot be empty';
      }

      if (_.isEmpty(values.get('model'))) {
        errors.model = 'Cannot be empty';
      }

      if (_.isEmpty(values.get('object'))) {
        errors.model = 'Cannot be empty';
      }

      return errors;
    },
  })
)(AddAttributeForm);

export default AddAttributeForm;
