import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

import FormInputCheckboxContent from './FormInputCheckboxContent';

const propTypes = {
  equipment: PropTypes.shape({
    description: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    value: PropTypes.any.isRequired,
  }).isRequired,
};
const EquipmentCheckItem = ({ equipment, input }) => (
  <List.Item>
    <FormInputCheckboxContent
      description={equipment.description}
      input={input}
      label={equipment.title}
    />
  </List.Item>
);

EquipmentCheckItem.propTypes = propTypes;
export default EquipmentCheckItem;
