import React, { Component } from 'react';
import BodyClassName from '../../../../globals/BodyClassName';
import TypeLogin, { TypeDefaults } from '../../../globals/blocks/TypeLogin';
import ValidatedField from '../../molecules/ValidatedField';

class Login01 extends Component {
  static propTypes = {
    ...TypeLogin,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  render() {
    const { template, type } = this.props;
    return (
      <BodyClassName className={template}>
        <div className='block' data-blocktype={type} data-template={template}>
          <ValidatedField fluid icon='user' name='email' placeholder='Email' />

          <ValidatedField
            fluid
            icon='lock'
            name='password'
            placeholder='Password'
            type='password'
          />
        </div>
      </BodyClassName>
    );
  }
}

export default Login01;
