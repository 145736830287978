import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Button,
  Header,
  Menu,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';

// Controller
import IntegrationCsvHistoryController from '../../../../controllers/IntegrationCSVHistoryController';
// Molecules
import MenuPagination from '../../../../molecules/MenuPagination';

import CSVTableBody from './CSVTableBody';

class IntegrationCSVHistory extends PureComponent {
  static propTypes = {
    cancelCSVParsing: PropTypes.func.isRequired,
    errorAtParsing: PropTypes.bool,
    importHistoryPerPage: PropTypes.number,
    importHistoryResults: ImmutablePropTypes.list,
    importHistoryResultsCount: PropTypes.number,
    isLoadingCancelParsing: PropTypes.bool,
    isLoadingImportHistory: PropTypes.bool,
    isSendingInvites: PropTypes.bool,
    loadHistory: PropTypes.func.isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }).isRequired,
    page: PropTypes.number,
    sendInvites: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorAtParsing: false,
    importHistoryPerPage: 24,
    importHistoryResults: [],
    importHistoryResultsCount: 0,
    isLoadingCancelParsing: false,
    isLoadingImportHistory: false,
    isSendingInvites: false,
    page: 1,
  };

  componentDidMount() {
    const { importHistoryPerPage, loadHistory, page } = this.props;
    loadHistory(page, importHistoryPerPage);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      importHistoryPerPage,
      isLoadingCancelParsing,
      loadHistory,
      page,
    } = this.props;

    const nextPage = nextProps.page;
    if (
      nextPage !== page ||
      (isLoadingCancelParsing &&
        !nextProps.isLoadingCancelParsing &&
        !nextProps.errorAtParsing)
    ) {
      loadHistory(nextPage, importHistoryPerPage);
    }
  }

  cancelParcing = (id) => {
    const { cancelCSVParsing } = this.props;
    cancelCSVParsing(id);
  };

  render() {
    const {
      importHistoryPerPage,
      importHistoryResults,
      importHistoryResultsCount,
      isLoadingImportHistory,
      isLoadingCancelParsing,
      isSendingInvites,
      page,
      sendInvites,
      location,
    } = this.props;

    return (
      <Segment attached='bottom' clearing>
        <Menu borderless secondary>
          <Menu.Item fitted>
            <Header content='' />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <Button
                basic
                content='Send invites'
                disabled={isSendingInvites}
                icon='send'
                loading={isSendingInvites}
                onClick={sendInvites}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {!isLoadingImportHistory && !importHistoryResultsCount ? (
          <Message content='You have no CSV import history' info />
        ) : (
          <Table celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{'File'}</Table.HeaderCell>
                <Table.HeaderCell>{'Status'}</Table.HeaderCell>
                <Table.HeaderCell>{'Created by'}</Table.HeaderCell>
                <Table.HeaderCell>{'Is Scheduled'}</Table.HeaderCell>
                <Table.HeaderCell>{'Date'}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <CSVTableBody
              cancelParcing={this.cancelParcing}
              importHistoryResults={importHistoryResults}
              importHistoryResultsCount={importHistoryResultsCount}
              isLoadingCancelParsing={isLoadingCancelParsing}
              isLoadingImportHistory={isLoadingImportHistory}
            />
          </Table>
        )}

        <MenuPagination
          currentPage={page}
          loading={isLoadingImportHistory}
          numberPages={
            Math.ceil(importHistoryResultsCount / importHistoryPerPage) || 1
          }
          pathname={'admin.integrations.csv.history'}
          query={location.query}
        />
      </Segment>
    );
  }
}

export default IntegrationCsvHistoryController(IntegrationCSVHistory);
