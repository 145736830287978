import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesPowerStrips extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0000',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path d='M80.16 50.74c-.144-4.334-3.664-7.793-8-7.86H54.4c-1.498-.008-2.93-.61-3.983-1.677-1.052-1.065-1.638-2.505-1.627-4.003v-3.3h3.47c1.373-.005 2.485-1.117 2.49-2.49v-5c-.005-1.373-1.117-2.485-2.49-2.49h-.37v-4c.042-.47-.185-.926-.588-1.175-.403-.25-.91-.25-1.314 0-.403.25-.63.704-.588 1.175v4h-3.83v-3.75c-.058-.644-.598-1.138-1.245-1.138-.647 0-1.187.494-1.245 1.138v3.78H43c-1.373.005-2.485 1.117-2.49 2.49v5c.022 1.36 1.13 2.455 2.49 2.46h3.3v3.3c-.028 4.48 3.58 8.137 8.06 8.17h17.75c3.07.027 5.55 2.52 5.56 5.59v7.14c.013 1.487-.567 2.917-1.613 3.974-1.046 1.057-2.47 1.653-3.957 1.656h-7.86v-4.3c0-1.54-.612-3.02-1.702-4.108-1.09-1.09-2.567-1.702-4.108-1.702H21.67c-1.54 0-3.02.612-4.108 1.702-1.09 1.09-1.702 2.567-1.702 4.108v11.89c0 3.21 2.6 5.81 5.81 5.81h36.76c3.21 0 5.81-2.6 5.81-5.81v-5.11H72c4.444.006 8.07-3.557 8.14-8l.02-7.47zM30.24 73.36c-4.25-.028-7.678-3.492-7.66-7.743.018-4.252 3.475-7.686 7.727-7.677 4.25.01 7.693 3.458 7.693 7.71-.003 2.053-.823 4.02-2.28 5.467-1.455 1.446-3.427 2.254-5.48 2.243zm19.93 0c-4.258 0-7.71-3.452-7.71-7.71s3.452-7.71 7.71-7.71 7.71 3.452 7.71 7.71c-.005 4.252-3.448 7.7-7.7 7.71h-.01z' />
          <path d='M50.18 59.6c-3.34 0-6.05 2.71-6.05 6.05s2.71 6.05 6.05 6.05 6.05-2.71 6.05-6.05c-.006-3.34-2.71-6.044-6.05-6.05zm1.22 9.55H49c-.316.035-.625-.114-.794-.384-.17-.27-.17-.612 0-.882s.478-.42.794-.384h2.4c.458 0 .83.372.83.83 0 .458-.372.83-.83.83v-.01zm0-5H49c-.316.035-.625-.114-.794-.384-.17-.27-.17-.612 0-.882s.478-.42.794-.384h2.4c.458 0 .83.372.83.83 0 .458-.372.83-.83.83v-.01zM30.24 59.6c-3.34 0-6.05 2.71-6.05 6.05s2.71 6.05 6.05 6.05 6.05-2.71 6.05-6.05c-.006-3.34-2.71-6.044-6.05-6.05zm1.22 9.55H29c-.316.035-.625-.114-.794-.384-.17-.27-.17-.612 0-.882s.478-.42.794-.384h2.44c.458 0 .83.372.83.83 0 .458-.372.83-.83.83l.02-.01zm0-5H29c-.316.035-.625-.114-.794-.384-.17-.27-.17-.612 0-.882s.478-.42.794-.384h2.44c.458 0 .83.372.83.83 0 .458-.372.83-.83.83l.02-.01z' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesPowerStrips;
