const api_case_mgmt_v0_1_service_department_route = {
  count: 1,
  results: [
    {
      eid: '08b30bd9-fe1e-44d6-97af-b924b982d123',
      id: 4,
      route_to: 'BOTH',
      service_department: 4,
      sys_created_by: 'martin@espressive.com',
      sys_date_created: '2018-04-26T21:50:03.191627Z',
      sys_date_updated: '2018-04-26T21:50:03.191591Z',
      sys_updated_by: 'martin@espressive.com',
      url:
        'https://release.qa.espressive.com/api/case_mgmt/v0.1/service_department_route/4/',
    },
  ],
};

export default api_case_mgmt_v0_1_service_department_route;
