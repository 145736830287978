import actionTypes from './actionTypes';

const userEntitiesActions = {};

userEntitiesActions.addUserEntityStart = (userId) => ({
  type: actionTypes.ADD_USER_ENTITY_START,
  userId,
});
userEntitiesActions.updateUserEntityStart = (user, timestamp = Date.now()) => ({
  timestamp,
  type: actionTypes.UPDATE_USER_ENTITY_SUCCESS,
  user,
});
userEntitiesActions.addUserEntitySuccess = (
  userId,
  user,
  timestamp = Date.now()
) => ({
  timestamp,
  type: actionTypes.ADD_USER_ENTITY_SUCCESS,
  user,
  userId,
});
userEntitiesActions.addUserEntityFail = (userId) => ({
  type: actionTypes.ADD_USER_ENTITY_FAIL,
  userId,
});

userEntitiesActions.removeUserEntityStart = () => ({
  type: actionTypes.REMOVE_USER_ENTITY_START,
});

userEntitiesActions.removeUserEntity = (userId) => ({
  type: actionTypes.REMOVE_USER_ENTITY,
  userId,
});

userEntitiesActions.addUsersEntitiesStart = () => ({
  type: actionTypes.ADD_USERS_ENTITIES_START,
});

userEntitiesActions.addUsersEntitiesSuccess = ({
  users,
  count,
  next = null,
  resetUsersList = false,
  concate = false,
}) => ({
  concate,
  count,
  next,
  resetUsersList,
  type: actionTypes.ADD_USERS_ENTITIES_SUCCESS,
  users,
});

userEntitiesActions.addUsersEntitiesFail = (userId) => ({
  type: actionTypes.ADD_USERS_ENTITIES_FAIL,
  userId,
});

// ToDO: all these actions below are not really user entity related. We should ove them from here
userEntitiesActions.isSearchingUser = () => ({
  type: actionTypes.IS_SEARCHING_USER_START,
});

userEntitiesActions.resetUsersList = () => ({
  type: actionTypes.RESET_USER_LIST,
});

userEntitiesActions.setSearchUsersTerm = (searchTerm) => ({
  searchTerm,
  type: actionTypes.SET_USER_SEARCH_TERM,
});

userEntitiesActions.addUserEntityFail = (userId) => ({
  type: actionTypes.ADD_USER_ENTITY_FAIL,
  userId,
});

userEntitiesActions.createNewUserStart = () => ({
  type: actionTypes.CREATE_NEW_USER_START,
});

userEntitiesActions.createNewUserSuccess = () => ({
  type: actionTypes.CREATE_NEW_USER_SUCCESS,
});

userEntitiesActions.createNewUserFail = () => ({
  type: actionTypes.CREATE_NEW_USER_FAIL,
});

userEntitiesActions.loadUserAssignmentStart = (userID) => ({
  type: actionTypes.LOAD_USER_ASSIGNMENT_START,
  userID,
});

userEntitiesActions.loadUserAssignmentSuccess = (userID, groups = {}) => ({
  groups,
  type: actionTypes.LOAD_USER_ASSIGNMENT_SUCCESS,
  userID,
});

userEntitiesActions.loadUserAssignmentError = (userID, error) => ({
  error,
  type: actionTypes.LOAD_USER_ASSIGNMENT_ERROR,
  userID,
});

userEntitiesActions.changeStartDateForUserStart = (userID) => ({
  type: actionTypes.CHANGE_START_DATE_USER_START,
  userID,
});

userEntitiesActions.changeStartDateForUserSuccess = (
  userID,
  startDate,
  reason
) => ({
  reason,
  startDate,
  type: actionTypes.CHANGE_START_DATE_USER_SUCCESS,
  userID,
});

userEntitiesActions.changeStartDateForUserFail = (userID) => ({
  type: actionTypes.CHANGE_START_DATE_USER_FAIL,
  userID,
});

userEntitiesActions.changeDepartmentUserStart = (userID) => ({
  type: actionTypes.CHANGE_DEPARTMENT_USER_START,
  userID,
});

userEntitiesActions.changeDepartmentUserFail = (userID) => ({
  type: actionTypes.CHANGE_DEPARTMENT_USER_FAIL,
  userID,
});

userEntitiesActions.changeDepartmentUserSuccess = (data, userID) => ({
  data,
  type: actionTypes.CHANGE_DEPARTMENT_USER_SUCCESS,
  userID,
});

userEntitiesActions.getDepartmentIdStart = () => ({
  type: actionTypes.GET_USER_DEPARTMENT_ID_START,
});

userEntitiesActions.getDepartmentIdFail = () => ({
  type: actionTypes.GET_USER_DEPARTMENT_ID_FAIL,
});

userEntitiesActions.getDepartmentIdSuccess = (userID, department) => ({
  department,
  type: actionTypes.GET_USER_DEPARTMENT_ID_SUCCESS,
  userID,
});

userEntitiesActions.loadUserOptionsSuccess = (options) => ({
  options,
  type: actionTypes.LOAD_USER_OPTIONS_SUCCESS,
});
userEntitiesActions.loadUserOptionsFail = () => ({
  type: actionTypes.LOAD_USER_OPTIONS_FAIL,
});

userEntitiesActions.loadUserStart = () => ({
  type: actionTypes.LOAD_USER_START,
});
userEntitiesActions.loadUserSuccess = (users) => ({
  type: actionTypes.LOAD_USER_SUCCESS,
  users,
});
userEntitiesActions.loadUserFail = () => ({
  type: actionTypes.LOAD_USER_FAIL,
});
userEntitiesActions.addUserStart = () => ({
  type: actionTypes.ADD_USER_START,
});
userEntitiesActions.addUserSuccess = (users) => ({
  type: actionTypes.ADD_USER_SUCCESS,
  users,
});
userEntitiesActions.addUserFail = () => ({
  type: actionTypes.ADD_USER_FAIL,
});
userEntitiesActions.addUserSessionSuccess = (users, sessions) => ({
  sessions,
  type: actionTypes.ADD_USER_SESSION_SUCCESS,
  users,
});
userEntitiesActions.addUserSessionFail = () => ({
  type: actionTypes.ADD_USER_SESSION_FAIL,
});
userEntitiesActions.loadUserRolesStart = () => ({
  type: actionTypes.LOAD_USER_ROLES_START,
});
userEntitiesActions.loadUserRolesSuccess = (roles) => ({
  roles,
  type: actionTypes.LOAD_USER_ROLES_SUCCESS,
});
userEntitiesActions.loadUserRolesFails = () => ({
  type: actionTypes.LOAD_USER_ROLES_FAIL,
});

export default userEntitiesActions;
