// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import HelloActions from '../../utils/HelloActions';

// Actions
import appUIActions from '../../actions/appUIActions';

// Selectors
import getCurrentUser from '../../selectors/getCurrentUser';
import getTenant from '../../selectors/getTenant';
import getTenantMenuConfiguration from '../../selectors/getTenantMenuConfiguration';
import shouldLaunchExistingEmployeeWF, {
  getExistingEmployeeWFeid,
} from '../../selectors/shouldLaunchExistingEmployeeWF';

import workflowThunks from '../../actions/workflowThunks';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const tenant = getTenant(state);
  const uiConfig = getTenantMenuConfiguration(state);
  const offlineMessage = uiConfig.get('offlineMessage') || false;
  const isMoreMenuOpen = state.getIn(['appUI', 'isMoreMenuOpen']);

  // Check for the existing employee flag and if we need to launch it
  const shouldLaunchExistingWF = shouldLaunchExistingEmployeeWF(state);

  return {
    currentUser: currentUser,
    existingEmployeeWFeid: getExistingEmployeeWFeid(state),
    isMoreMenuOpen,
    offlineMessage: offlineMessage,
    shouldLaunchExistingWF,
    tenant: tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeMoreMenu() {
    dispatch(appUIActions.closeMoreMenu());
  },

  /**
   * Actions that must be peformed when homepage unmounts (if ever)
   */
  doGoodbyeActions: () => {
    // these are called on componentWillUnmount
    HelloActions.homeGoodbye();
  },

  /**
   * Actions that must be performaed when HomePage mounts
   */
  doHelloActions: () => {
    HelloActions.homeHello();
  },

  launchExistingEmployeeWF(eid) {
    // So, it is possible that for some reason the user is creating multiple WF requests (DEV-4704)
    // even when we read employee_onboarded from the bootstrap API every time we load
    // because of that, the suggested solution from the BE is to check if a request for the existing workflow has been made
    // because of that, this needs to be done:
    // 1. Query the API to get the id of the workflow, since we only know the eid
    // 2. Query the API to see if there are existing woriflow requested for the current user
    // 3. Finally, if no workflow requests have been created, proceed to create it.
    // ¯\_(ツ)_/¯
    dispatch(workflowThunks.mapEIDToID(eid))
      .then((workflowID) =>
        dispatch(
          workflowThunks.getWorkflowRequestByIdForCurrentUser(workflowID)
        )
      )
      .then(({ body }) => {
        if (!body.count) {
          dispatch(appUIActions.openWorkflowExistingEmployee());
          dispatch(workflowThunks.createWorkflowRequestID(eid));
        }
      })
      .catch((error) => {
        // So we know what happens if errors
        // eslint-disable-next-line no-console -- debugging
        console.error(error);
      });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
