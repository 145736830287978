import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';
import noop from 'lodash/noop';
import './UserSelectSearch.scss';
class UserSelectSearch extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    isLoading: false,
    label: null,
    onSearch: noop,
    placeholder: null,
  };

  ref = null;

  setRef = (ref) => {
    this.ref = ref;
  };

  handleMouseDown = (e) => {
    // prevent bubbling from SecondaryAppPanel
    e.stopPropagation();
  };

  render() {
    const { isLoading, label, onSearch, placeholder } = this.props;

    const withLabel = label ? <label>{label}</label> : null;
    return (
      <div className={'_UserSelectSearchModule'}>
        {withLabel}
        <Search
          fluid
          loading={isLoading}
          onMouseDown={this.handleMouseDown}
          onSearchChange={onSearch}
          placeholder={placeholder} // Hide no result msg
          ref={this.setRef}
          showNoResults={false}
        />
      </div>
    );
  }
}

export default UserSelectSearch;
