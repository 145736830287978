import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Container } from 'semantic-ui-react';

import { IntlBlocks, IntlNav } from '../IntBlob';
// Controller
import BlockPresenterController from './BlockPresenterController';

class BlockPresenter extends PureComponent {
  static propTypes = {
    // currentSelectedTaskId: PropTypes.oneOfType([
    //   PropTypes.number,
    //   PropTypes.string,
    // ]),
    isLoadingTask: PropTypes.bool,
    task: ImmutablePropTypes.map,
  };

  static defaultProps = {
    // currentSelectedTaskId : null,
    isLoadingTask: false,
    task: null,
  };

  render() {
    const {
      task,
      isLoadingTask,
      // currentSelectedTaskId,
    } = this.props;

    // currentSelectedTaskId;

    const blocks = task && task.getIn(['frontend_blob', 'blocks']);

    // Rendering only the footer here as it's the only one allowed to be translated
    const footerPath = ['nav', 'footer'];
    const navFooter = task && task.getIn(['frontend_blob', ...footerPath]);
    return (
      <Container>
        {!isLoadingTask && navFooter ? (
          <IntlNav
            blobKeyPath={footerPath}
            navBlock={navFooter.toJS()}
            type={'Footer'}
          />
        ) : null}
        {!isLoadingTask &&
          blocks &&
          blocks.map((immutableBlock, index) => {
            const block = immutableBlock.toJS();

            const { id, type, template } = block;

            // This is very important!
            // we're gonna track the location of the edited attribute
            // in the frontend_blob
            // using this array
            // Thanks Pascal for the idea ❤️🇫🇷
            const blobKeyPath = ['blocks'];
            blobKeyPath.push(index);

            return (
              <IntlBlocks
                blobKeyPath={blobKeyPath}
                block={block}
                key={id}
                template={template}
                type={type}
              />
            );
          })}

        {!isLoadingTask && !blocks ? (
          <div
            style={{
              margin: '4em 0',
              textAlign: 'center',
            }}
          >
            <em>{'No task loaded...'}</em>
          </div>
        ) : null}
      </Container>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
BlockPresenter = BlockPresenterController(BlockPresenter);

export default BlockPresenter;
