import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// Organism
import SelectMyGear from './SelectMyGear';

class ValidatedSelectMyGear extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    roleBundleID: PropTypes.number.isRequired,
  };

  validate(isValid) {
    if (isValid) {
      // fancy way to return 'undefined'
      let noError;

      return noError;
    }

    return 'Some selections are missing';
  }

  render() {
    const { name, roleBundleID } = this.props;

    return (
      <Field
        component={SelectMyGear}
        name={name}
        roleBundleID={roleBundleID}
        validate={this.validate}
      />
    );
  }
}

export default ValidatedSelectMyGear;
