const api_notification_v0_1_policy_features = [
  {
    audience: 'Employee',
    description:
      'Notifications related to any announcements.  Typically a new announcement and updates to existing announcements.',
    feature: 'Announcement',
    policy: {
      default_method: 'CARD',
      eid: 'cc182ee8-9ee4-404e-a8e3-a1cc9880b550',
      id: 2,
      method_order_and_condition: [
        {
          condition: 'ALWAYS',
          type: 'CARD',
        },
        {
          condition: 'IN_APP,DESKTOP',
          type: 'EMAIL',
        },
        {
          condition: 'ALWAYS',
          type: 'IN_APP',
        },
        {
          condition: 'ALWAYS',
          type: 'DESKTOP',
        },
      ],
      name: 'Default policy for announcement',
      single_method: false,
      sys_created_by: null,
      sys_date_created: '2020-06-04T08:12:51.124419Z',
      sys_date_updated: '2020-06-04T08:19:33.790397Z',
      sys_updated_by: null,
      url: 'http://tenant1.esp/api/notification/v0.1/policy/2/',
    },
    title: 'Default Announcement Notifications',
  },
  {
    audience: 'Employee',
    description:
      'Notification to all the team members (employee with the same manager) that a fellow team member has a birthday that day.',
    feature: 'Social',
    policy: {
      default_method: 'CARD',
      eid: '60d51e48-64a6-4869-ab59-d3d50b4e3f0c',
      id: 10,
      method_order_and_condition: [
        {
          condition: 'ALWAYS',
          type: 'CARD',
        },
        {
          condition: 'ALWAYS',
          type: 'IN_APP',
        },
        {
          condition: 'IN_APP',
          type: 'EMAIL',
        },
      ],
      name: 'Default policy for birthday notification',
      single_method: false,
      sys_created_by: null,
      sys_date_created: '2020-06-04T08:13:27.366892Z',
      sys_date_updated: '2020-06-04T08:13:47.322268Z',
      sys_updated_by: null,
      url: 'http://tenant1.esp/api/notification/v0.1/policy/10/',
    },
    title: 'Birthday Notification',
  },
];

export default api_notification_v0_1_policy_features;
