import React, { useEffect, useRef } from 'react';

import ChatBotIconController from './ChatBotIconController';
import { InlineIcon } from '@iconify/react';
import PropTypes from 'prop-types';
import styles from './ChatBotIcon.module.scss';
import { useChatBotIcon } from '../../customHooks';

const propTypes = {
  chatBotSvgURL: PropTypes.string,
  className: PropTypes.string,
  isCustomBrandingEnabled: PropTypes.bool,
  loadTenantImages: PropTypes.func,
  size: PropTypes.string,
  tenantImageListSize: PropTypes.number,
};

const ChatBotIcon = ({
  chatBotSvgURL,
  className,
  isCustomBrandingEnabled = false,
  size,
  tenantImageListSize,
  loadTenantImages,
}) => {
  const { chatBotIconSVG, isLoadingChatBotIcon } = useChatBotIcon(
    isCustomBrandingEnabled,
    chatBotSvgURL
  );

  const wasAlreadyCalledRef = useRef();

  useEffect(() => {
    if (tenantImageListSize === 0 && !wasAlreadyCalledRef.current) {
      wasAlreadyCalledRef.current = true;
      loadTenantImages();
    }
  }, [loadTenantImages, tenantImageListSize]);

  if (chatBotIconSVG && !isLoadingChatBotIcon && tenantImageListSize) {
    return (
      <InlineIcon
        className={`primary icon ${className ? styles[className] : ''} ${
          size ? styles[size] : ''
        }`}
        icon={chatBotIconSVG}
      />
    );
  }

  return null;
};

ChatBotIcon.propTypes = propTypes;

export default ChatBotIconController(ChatBotIcon);
