import buildCompositeController from '../../../../v1/utils/buildCompositeController';

// Actions
import casesActions from '../../../../v1/actions/casesActions';
import chatThunks from '../../../../v1/actions/chatThunks';
import wsActions from '../../../../v1/actions/wsActions';
import toastNotificationsActions from '../../../../v1/actions/toastNotificationsActions';
import baristaActions from '../../../../v1/actions/baristaActions';
import baristaThunks from '../../../../v1/actions/baristaThunks';
// Selectors
import { getCurrentUserId } from '../../../../v1/selectors/getCurrentUser';
import getAllowedExtensions from '../../../../v1/selectors/getAllowedExtensions';

// Utils
import endpointGenerator from '../../../../v1/utils/endpointGenerator';
import BrowserPrompt from '../../../../v1/globals/BrowserPrompt';
import URLUtil from '../../../../v1/utils/URLUtil';
// Globals
import ChannelTypes from '../../../../v1/globals/ChannelTypes';
import WebSocketEventTypes from '../../../../v1/globals/WebSocketEventTypes';

const mapStateToProps = (state, ownProps) => ({
  allowedExtensions: getAllowedExtensions(state),
  apiMetaResults: state.getIn(['chatState', 'apiMetaResults']),
  currentUserID: getCurrentUserId(state),
  isLoadingAttachment: state.getIn(['chatState', 'isLoadingAttachment']),
  isLoadingOptions: state.getIn(['chatState', 'isLoadingOptions']),
  userTyping: state.getIn(['chatState', 'userTyping', ownProps.channelId]),
  usersEntities: state.getIn(['entities', 'users']),
  wsStatus: state.get('ws'),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getApiMetaResults: (apiMeta, searchValue) => {
    dispatch(chatThunks.fetchApiMetaResults(apiMeta, searchValue));
  },

  onOpenSubscribers: (caseTaskId) => {
    dispatch(casesActions.openAddSubscribers(caseTaskId));
  },

  /**
   * Proccesses the text (that whould be a url with parameters)
   * And reiniliza barista which these parsed params
   */
  proccessQRCode: (text) => {
    const alertQRError = () => {
      // this should be translated
      // but i think this is going to be easier to translate post-nin
      // as we're having a new API to translate stuff there
      const errorMessage =
        'This QR code is not compatible with the Barista app'; // this copy comes from our liege lord Pat
      BrowserPrompt.alert(errorMessage, {
        buttons: ['OK'],
        content: errorMessage,
        title: 'Barista',
      });
    };

    try {
      const url = new URL(text);
      const qRCodeParams = URLUtil.getQRParamsFromQueryString(url.search);

      // must have at least a phrase
      if (qRCodeParams.phrase) {
        // lastly, open a new support channel sending the query as parameter
        // Similar to what BaristaQRController does to do the same from a url visit
        dispatch(baristaActions.openSupportChannel(qRCodeParams));
        dispatch(baristaThunks.loadSupportChannel(null, true));
      } else {
        alertQRError();
      }
    } catch (e) {
      alertQRError();
    }
  },

  sendAttachment: (data, fileName, isImage = false) => {
    const { channelId } = ownProps;

    dispatch(chatThunks.sendFile(data, channelId, fileName, isImage));
  },

  sendMessage: ({
    message = '',
    isFirstMessage = false,
    isPicker = false,
    messageType = '',
  }) => {
    const { caseTaskId, channelId, channelType, isCaseMgmt } = ownProps;

    // Chat in Cases have a different endpoint to post messages
    // (note that caseTaskId may exist in the homefeed, in which case we use the regular endpoint)
    const endPoint =
      caseTaskId && isCaseMgmt
        ? `${endpointGenerator.genPath('task.tasks.instance.comment', {
            taskPK: caseTaskId,
          })}?show_resolved=true&show_discarded=true&show_cancelled=true` // including all this parameters to be able to chat on any case no matter its state. It's silly but Martin hates me
        : endpointGenerator.genPath('espChat.messages');

    const messageBody = {
      body: {
        channel_id: channelId,
        channel_type: channelType,
        text: message,
      },
      request: endPoint,
    };

    if (isPicker) {
      messageBody.body.metadata = {
        bot_metadata: {
          input_message: messageType,
        },
      };
    }
    dispatch(wsActions.send(messageBody));
  },

  sendMessageError(msg) {
    dispatch(
      toastNotificationsActions.error({
        message: msg,
        title: 'Error',
      })
    );
  },

  sendUserTyping: ({
    channelID = '',
    channelType = '',
    currentUserID = null,
  }) => {
    if (channelID) {
      let endPoint;
      if (channelType === ChannelTypes.DIRECT_CHANNEL) {
        endPoint = endpointGenerator.genPath('espChat.directChannels');
      } else {
        endPoint = endpointGenerator.genPath('espChat.channels');
      }
      const rteUrl = `${endPoint + channelID}/rte/`;
      const messageBody = {
        body: {
          channel_id: channelID,
          type: 'typing',
          userId: currentUserID,
        },
        request: rteUrl,
        rttype: WebSocketEventTypes.TYPING,
      };

      dispatch(wsActions.send(messageBody));
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
