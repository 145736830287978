import async from 'async';
import _ from 'lodash';

import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import jobRoleActions from './jobRoleActions';
import BundleStatus from '../globals/BundleStatus';

/**
 * @param {function} onJobRoles
 */
//
// const getJobRoles = (onJobRoles = _.noop) => {
// const url = endpointGenerator.genPath('espUser.jobRoles');
//
// APIcall.get({
//     url,
//     token: true,
//     error(error) {
//       onJobRoles(error.response.body);
//     },
//     success(response) {
//       const jobRoles = response.body.results;
//       onJobRoles(null, jobRoles);
//     },
// });
// };
//

/**
 * @param {string} application
 * @param {string} entityType
 * @param {string} permission
 * @param {function} onJobRolesPermissions
 */
const getJobRolesPermissions = (
  application,
  entityType,
  permission,
  onJobRolesPermissions = _.noop
) => {
  const url = endpointGenerator.genPath('espUser.jobRoles.permissions');

  APIcall.get({
    error(error) {
      onJobRolesPermissions(error.response.body);
    },
    query: {
      application,
      entity_type: entityType,
      permission,
    },
    success(response) {
      const jobRolesPermissions = response.body;
      onJobRolesPermissions(null, jobRolesPermissions);
    },
    token: true,
    url,
  });
};

const jobRoleThunks = {};

jobRoleThunks.getJobRolesWithBundles = () => (dispatch) =>
  new Promise((resolve, reject) => {
    async.waterfall(
      [
        // 1. Get all bundles ids available per role
        (next) => {
          getJobRolesPermissions(
            'catalog',
            'espbundle',
            'view',
            (error, permissionsResponse) => {
              //
              // This guy has following structure
              // {
              // "Job Role Name": {
              // bundles: [1, 5, 9], //bundle IDs
              // id: 32, //job role id
              // },
              // }
              //
              const bundlesIdsByJobRoleName =
                permissionsResponse.job_role_permissions;

              const jobRoles = [];

              // ES6 Set
              const allBundlesIDsSet = new Set();

              _.each(bundlesIdsByJobRoleName, (record, jobRoleName) => {
                const jobRole = {};
                jobRole.id = record.id;
                jobRole.url = endpointGenerator.genPath(
                  'espUser.jobRoles.instance',
                  {
                    jobRoleID: jobRole.id,
                  }
                );
                jobRole.name = jobRoleName;
                jobRole.availableBundles = record.bundles;

                jobRoles.push(jobRole);

                jobRole.availableBundles.forEach((bundleID) =>
                  allBundlesIDsSet.add(bundleID)
                );
              });

              const allBundlesIDs = Array.from(allBundlesIDsSet);

              next(null, jobRoles, allBundlesIDs);
            }
          );
        },

        // 2. Get all bundles
        (jobRoles, allBundlesIDs, next) => {
          // report early that there are no role bundles at all
          if (_.isEmpty(allBundlesIDs)) {
            next(null, jobRoles, []);
            return;
          }

          const url = endpointGenerator.genPath('espCatalog.bundles');

          const commaSeparatedIDs = allBundlesIDs.join(',');
          const espFilters = encodeURI(
            `status__EQ=${BundleStatus.ACTIVE}&id__IN=${commaSeparatedIDs}`
          );

          APIcall.get({
            error(error) {
              next(error.response.body);
            },
            query: {
              esp_filters: espFilters,

              // required to be passed since we know beforehand how many results we'll get (at most)
              limit: _.size(allBundlesIDs),
            },
            success(response) {
              const allBundles = response.body.results;
              next(null, jobRoles, allBundles);
            },
            token: true,
            url,
          });
        },
      ],
      (error, jobRoles, allBundles) => {
        if (error) {
          // TODO handle error
          reject(error);
        } else {
          // console.log(jobRoles);
          // console.log(allBundles);

          dispatch(
            jobRoleActions.getJobRolesWithBundlesSuccess(jobRoles, allBundles)
          );
          resolve({
            allBundles,
            jobRoles,
          });
        }
      }
    );
  });

jobRoleThunks.getJobRoles = (cb = _.noop, id) => (dispatch) =>
  new Promise((resolve) => {
    const url = endpointGenerator.genPath('espUser.jobRoles');

    const query = {
      limit: 1000,
    }; // TODO we need to get rid of this

    if (id) {
      query.esp_filters = encodeURI(`id__EQ=${id}`);
    }

    APIcall.get({
      query,
      success({ body: { results } }) {
        dispatch(jobRoleActions.getJobRolesSuccess(results));
        cb();
        resolve(results);
      },
      token: true,
      url: url,
    });
  });

jobRoleThunks.getUserJobRole = (job_role_url) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (job_role_url) {
      APIcall.get({
        error(err) {
          reject(err);
        },
        success({ body }) {
          dispatch(jobRoleActions.getJobRolesSuccess([body]));
          resolve(body);
        },
        token: true,
        url: job_role_url,
      });
    } else {
      reject('no job_role_url provided');
    }
  });

export default jobRoleThunks;
