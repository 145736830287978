// possible props to receive from  Offboarding Template Interval Modal value, intervalID, templateID, scheduleID, action, msg, cb
import async from 'async';
import { useCallback } from 'react';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import APIcall from '../../../../../utils/APIcall';
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';

const checkOrCreateNewInterval = (fieldName, valueToPass) =>
  new Promise((resolve, reject) => {
    // When creating an interval these are the rules:
    // days_before_departure_day = -valueToPass equal to days < 0
    // day_of_departure = 0 equal to days == 0
    // day_after_departure = +valueToPass or days > 0
    const DAY_OF_DEPARTURE_VALUE = 0;
    const days =
      fieldName !== 'days_before_departure_day'
        ? fieldName === 'day_of_departure'
          ? DAY_OF_DEPARTURE_VALUE
          : valueToPass
        : -valueToPass;
    async.waterfall(
      [
        // 1 Check if we can found an activity interval with the same days set
        (next) => {
          const esp_filters = `days__EQ=${days}`;
          APIcall.get({
            error(err) {
              next(err);
            },
            query: {
              esp_filters,
            },
            success(res) {
              const resultInterval = res.body.results;
              next(null, resultInterval);
            },
            token: true,
            url: endpointGenerator.genPath('espTodo.scheduleInterval'),
          });
        },
        // 2 If not exist, create a new interval activity OR go next
        (resultInterval, next) => {
          if (resultInterval && resultInterval.length > 0) {
            // The result should return only one entry in the result array since we want to avoid duplicate interval activity
            next(null, resultInterval[0]);
          } else {
            // Create a new one
            APIcall.post({
              data: {
                days: Number(days),
                name: days,
              },
              error(err) {
                next(err);
              },
              success(res) {
                const result = res.body;
                next(null, result);
              },
              token: true,
              url: endpointGenerator.genPath('espTodo.scheduleInterval'),
            });
          }
        },
      ],
      (error, resultInterval) => {
        if (error) {
          reject(error);
        } else {
          resolve(resultInterval);
        }
      }
    );
  });

const useTemplateInterval = () => {
  const createActivityInterval = useCallback(
    ({
      allTemplates,
      action,
      scheduleID,
      templateID,
      templateSelected,
      valueToPass,
    }) => {
      const { key, value } = valueToPass;
      const valueTopass = value === true ? 1 : Number(value);
      const fieldName = key;

      return new Promise((resolve, reject) => {
        async.waterfall(
          [
            // 1 Check if we can found an activity interval with the same days set or create a new one
            (next) => {
              checkOrCreateNewInterval(fieldName, valueTopass)
                .then((resultInterval, err) => {
                  next(err, resultInterval);
                })
                .catch((error) => {
                  next(error);
                });
            },
            // 2 Create new schedule_to_template
            (resultInterval, next) => {
              // Check if this template with this interval is not already set with this schedule
              // const intervalID = resultInterval.id;
              let alreadyExist = false;
              const intervalID = resultInterval.id;

              fromJS(templateSelected).forEach((temp) => {
                // Check intervalID
                if (
                  Number(temp.getIn(['interval', 'id'])) ===
                    Number(intervalID) &&
                  Number(temp.getIn(['template', 'id'])) === Number(templateID)
                ) {
                  alreadyExist = true;
                }
              });

              if (alreadyExist) {
                next(null, {
                  alreadyExist: true,
                });
              } else {
                const data = {
                  interval: intervalID,
                  schedule: scheduleID,
                  template: templateID,
                };
                if (scheduleID === 'new' || action) {
                  // Fake templateSelected for a new schedule task
                  data.id = `new_${templateSelected.size + 1}`;
                  next(null, resultInterval, data);
                } else {
                  const url = endpointGenerator.genPath(
                    'espTodo.scheduleToTemplate'
                  );
                  // Add real template selected
                  APIcall.post({
                    data,
                    error(err) {
                      next(err);
                    },
                    success(res) {
                      const scheduleToTemplate = res.body;
                      next(null, resultInterval, scheduleToTemplate);
                    },
                    token: true,
                    url,
                  });
                }
              }
            },
          ],
          (error, resultInterval, scheduleToTemplate) => {
            if (error) {
              reject(error);
            } else {
              // Get the template selected

              const items = allTemplates;
              const getTemplate = items.filter(
                (item) => item.id === Number(templateID)
              );
              let alreadyExist = false;
              if (getTemplate && !isEmpty(getTemplate)) {
                const templateSelected = getTemplate['0'];
                alreadyExist = resultInterval.alreadyExist
                  ? {
                      alreadyExist: true,
                    }
                  : null;
                if (resultInterval.alreadyExist) {
                  // create activity interval fail
                } else {
                  // Create new templateSelect with interval, schetoToTemplate and template
                  const templateSelect = {
                    interval: resultInterval,
                    scheduleToTemplate: scheduleToTemplate,
                    template: templateSelected,
                  };
                  resolve(templateSelect);
                }
                resolve(alreadyExist);
              } else {
                reject(error);
              }
            }
          }
        );
      });
    },
    []
  );
  const updateActivityInterval = useCallback(
    ({
      allTemplates,
      action,
      scheduleID,
      templateID,
      templateSelected,
      valueToPass,
    }) => {
      const { key, value } = valueToPass;
      const valueTopass = value === true ? 1 : Number(value);
      const fieldName = key;

      return new Promise((resolve, reject) => {
        async.waterfall(
          [
            // 1 Check if we can found an activity interval with the same days set or create a new one
            (next) => {
              checkOrCreateNewInterval(fieldName, valueTopass)
                .then((resultInterval, err) => {
                  next(err, resultInterval);
                })
                .catch((error) => {
                  next(error);
                });
            },
            // 2 Create new schedule_to_template
            (resultInterval, next) => {
              // Check if this template with this interval is not already set with this schedule
              // const intervalID = resultInterval.id;
              let alreadyExist = false;
              const intervalID = resultInterval.id;

              fromJS(templateSelected).forEach((temp) => {
                // Check intervalID
                if (
                  Number(temp.getIn(['interval', 'id'])) ===
                    Number(intervalID) &&
                  Number(temp.getIn(['template', 'id'])) === Number(templateID)
                ) {
                  alreadyExist = true;
                }
              });

              if (alreadyExist) {
                next(null, {
                  alreadyExist: true,
                });
              } else {
                const data = {
                  interval: intervalID,
                  schedule: scheduleID,
                  template: templateID,
                };

                if (scheduleID === 'new' || action) {
                  // Fake templateSelected for a new schedule task
                  data.id = `new_${templateSelected.size + 1}`;
                  next(null, resultInterval, data);
                } else {
                  const url = endpointGenerator.genPath(
                    'espTodo.scheduleToTemplate.instance',
                    {
                      scheduleToTemplatelID:
                        templateSelected.scheduleToTemplate.id,
                    }
                  );

                  // Add real template selected
                  APIcall.patch({
                    data,
                    error(err) {
                      next(err);
                    },
                    success(res) {
                      const scheduleToTemplate = res.body;
                      next(null, resultInterval, scheduleToTemplate);
                    },
                    token: true,
                    url,
                  });
                }
              }
            },
          ],
          (error, resultInterval, scheduleToTemplate) => {
            if (error) {
              reject(error);
            } else {
              // Get the template selected

              const items = allTemplates;
              const getTemplate = items.filter(
                (item) => item.id === Number(templateID)
              );
              let alreadyExist = false;
              if (getTemplate && !isEmpty(getTemplate)) {
                const templateSelected = getTemplate['0'];
                alreadyExist = resultInterval.alreadyExist
                  ? {
                      alreadyExist: true,
                    }
                  : null;
                if (resultInterval.alreadyExist) {
                  // create activity interval fail
                } else {
                  // Create new templateSelect with interval, schetoToTemplate and template
                  const templateSelect = {
                    interval: resultInterval,
                    scheduleToTemplate: scheduleToTemplate,
                    template: templateSelected,
                  };
                  resolve(templateSelect);
                }
                resolve(alreadyExist);
              } else {
                reject(error);
              }
            }
          }
        );
      });
    },
    []
  );
  return {
    createActivityInterval,
    updateActivityInterval,
  };
};

export default useTemplateInterval;
