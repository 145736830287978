import buildCompositeController from '../../utils/buildCompositeController';
import integrationThunks from '../../actions/integrationThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

const mapStateToProps = (state) => ({
  integrationSettings:
    state.getIn(['integration', 'baristabot_integrations']).toJS()?.TEAMS
      ?.integration_settings || {},
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Dispatch action to get bot integration settings
   * @return {Promise<*>}
   */
  async getBaristaBotIntegrationSettings() {
    return dispatch(integrationThunks.getBaristaBotIntegration('TEAMS'));
  },

  /**
   * Dispatched action to update bot integration settings
   * @param integrationData - integration data as defined in the state. integration.baristabot_integrations.TEAMS
   * @param data
   * @param {boolean} data.displayAnnouncements
   * @param {boolean} data.displayApprovals
   * @return {Promise<void>}
   */
  async updateBaristaBotIntegrationSettings(integrationData, data) {
    const settings = {
      ...integrationData,
      display_announcements_on_app_home: data.displayAnnouncements,
      display_approvals_on_app_home: data.displayApprovals,
    };
    const newData = { integration_settings: settings };
    await dispatch(
      integrationThunks.updateBaristaBotIntegration('TEAMS', newData)
    );
    dispatch(
      toastNotificationsActions.success({
        message: 'MS Teams settings have been updated',
        title: 'Success',
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
