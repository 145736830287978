import async from 'async';

// actions
import onboardProgressManagerViewActions from './onboardProgressManagerViewActions';
import userEntitiesThunks from './userEntitiesThunks';

// utils
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

/**
 * Takes as arguments the states before and after an API request for
 * onboard progress completes to tell if we are still interested in the response.
 */
const shouldAcceptOnboardProgress = (stateBefore, stateAfter) =>
  stateBefore.getIn(['onboardProgressManagerView', 'filterBy']) ===
    stateAfter.getIn(['onboardProgressManagerView', 'filterBy']) &&
  stateBefore.getIn(['onboardProgressManagerView', 'sortBy']) ===
    stateAfter.getIn(['onboardProgressManagerView', 'sortBy']);

const onboardProgressManagerViewThunks = {};

onboardProgressManagerViewThunks.getOnboardProgressManagerView = () => (
  dispatch,
  getState
) => {
  const state = getState();
  const onboardProgressManagerViewState = state.get(
    'onboardProgressManagerView'
  );

  // TODO Currently not in use because only one option is supported. Keep it here for later extension.
  // const filterBy = onboardProgressManagerViewState.get('filterBy');

  const sortBy = onboardProgressManagerViewState.get('sortBy');

  dispatch(
    onboardProgressManagerViewActions.getOnboardProgressManagerViewStart()
  );

  async.waterfall(
    [
      // 1. Get onboard progress
      (next) => {
        const url = endpointGenerator.genPath(
          'espTodo.progressSummary.managerView'
        );
        const payload = {
          activity_recipients: ['NEW_HIRE'],
        };

        const query = {
          order_by: sortBy,
        };

        APIcall.post({
          data: payload,
          error(error) {
            next(error.response.body);
          },
          query,
          success(response) {
            const { body } = response;

            const onboardProgress = body.results;

            const pagination = {
              next: body.next,
              prev: body.previous,
            };

            next(null, onboardProgress, pagination);
          },
          token: true,
          url,
        });
      },

      // 2. Pull users pointed by the onboardProgress Array
      (onboardProgress, pagination, next) => {
        const userIDs = onboardProgress.map(
          (progressEntry) => progressEntry.user
        );
        const checkHiddenUsers = true; // Force to check for hidden user
        dispatch(userEntitiesThunks.getListUsersByID(userIDs, checkHiddenUsers))
          .then(() => next(null, onboardProgress, pagination))
          .catch(next);
      },
    ],
    (error, onboardProgress, pagination) => {
      // state after async operations complete
      const afterState = getState();

      // there is more recent request going on, so we ignore this
      if (!shouldAcceptOnboardProgress(state, afterState)) {
        return;
      }

      if (error) {
        dispatch(
          onboardProgressManagerViewActions.getOnboardProgressManagerViewFail()
        );
      } else {
        dispatch(
          onboardProgressManagerViewActions.getOnboardProgressManagerViewSuccess(
            onboardProgress,
            pagination
          )
        );
      }
    }
  );
};

onboardProgressManagerViewThunks.getNextOnboardProgressManagerView = () => (
  dispatch,
  getState
) => {
  const state = getState();
  const onboardProgressManagerViewState = state.get(
    'onboardProgressManagerView'
  );

  const isLoadingNext = onboardProgressManagerViewState.getIn([
    'getOnboardProgressManagerView',
    'isLoadingNext',
  ]);

  if (isLoadingNext) {
    return;
  }

  const nextOnboardProgressUrl = onboardProgressManagerViewState.getIn([
    'getOnboardProgressManagerView',
    'pagination',
    'next',
  ]);

  dispatch(
    onboardProgressManagerViewActions.getNextOnboardProgressManagerViewStart()
  );

  async.waterfall(
    [
      // 1. Get next onboard progress
      (next) => {
        const payload = {
          activity_recipients: ['NEW_HIRE'],
        };

        APIcall.post({
          data: payload,
          error(error) {
            next(error.response.body);
          },
          success(response) {
            const { body } = response;

            const nextOnboardProgress = body.results;

            const pagination = {
              next: body.next,
              prev: body.previous,
            };

            next(null, nextOnboardProgress, pagination);
          },
          token: true,
          url: nextOnboardProgressUrl,
        });
      },

      // 2. Pull users pointed by the nextOnboardProgress Array
      (nextOnboardProgress, pagination, next) => {
        const userIDs = nextOnboardProgress.map(
          (progressEntry) => progressEntry.user
        );
        const checkHiddenUsers = true; // Force to check for hidden user
        dispatch(userEntitiesThunks.getListUsersByID(userIDs, checkHiddenUsers))
          .then(() => next(null, nextOnboardProgress, pagination))
          .catch(next);
      },
    ],
    (error, nextOnboardProgress, pagination) => {
      // state after async operations complete
      const afterState = getState();

      // there is more recent request going on, so we ignore this
      if (!shouldAcceptOnboardProgress(state, afterState)) {
        return;
      }

      if (error) {
        dispatch(
          onboardProgressManagerViewActions.getNextOnboardProgressManagerViewFail()
        );
      } else {
        dispatch(
          onboardProgressManagerViewActions.getNextOnboardProgressManagerViewSuccess(
            nextOnboardProgress,
            pagination
          )
        );
      }
    }
  );
};

export default onboardProgressManagerViewThunks;
