import actionTypes from './actionTypes';

const cartActions = {};

cartActions.loadCartStart = () => ({
  type: actionTypes.LOAD_CART_START,
});

cartActions.cartIsloading = () => ({
  type: actionTypes.LOAD_CART_ISLOADING,
});

cartActions.loadCartSuccess = () => ({
  type: actionTypes.LOAD_CART_SUCCESS,
});

cartActions.updateCartSuccess = (product) => ({
  product,
  type: actionTypes.UPDATE_CART_SUCCESS,
});

cartActions.removeItemStart = (removeCatalogItemID) => ({
  removeCatalogItemID,
  type: actionTypes.REMOVE_CATALOG_ITEM_START,
});

cartActions.removeItem = (citemId) => ({
  citemId,
  type: actionTypes.REMOVE_CART_ITEM,
});

cartActions.fillCartSuccess = (cartItem) => ({
  cartItem,
  type: actionTypes.FILL_CART_SUCCESS,
});

cartActions.loadNoCartSuccess = () => ({
  type: actionTypes.LOAD_NO_CART_SUCCESS,
});

cartActions.resetCartSuccess = () => ({
  type: actionTypes.RESET_CART_SUCCESS,
});

cartActions.clearCart = () => ({
  type: actionTypes.CLEAR_CART_ITEM,
});

cartActions.loadTaskCartStart = () => ({
  type: actionTypes.LOAD_TASK_CART_START,
});

cartActions.loadTaskCartSuccess = () => ({
  type: actionTypes.LOAD_TASK_CART_SUCCESS,
});

cartActions.loadTaskCartFail = () => ({
  type: actionTypes.LOAD_TASK_CART_FAIL,
});

cartActions.loadTaskCartItemsStart = () => ({
  type: actionTypes.LOAD_TASK_CART_ITEMS_START,
});

cartActions.loadTaskCartItemsSuccess = (id, cart) => ({
  cart,
  id,
  type: actionTypes.LOAD_TASK_CART_ITEMS_SUCCESS,
});

cartActions.loadTaskCartItemsFail = () => ({
  type: actionTypes.LOAD_TASK_CART_ITEMS_FAIL,
});

cartActions.loadUserCartSuccess = (userCart, stillLoading) => ({
  stillLoading,
  type: actionTypes.LOAD_CART_USER_SUCCESS,
  userCart,
});

cartActions.loadUserCartLoadingEnd = () => ({
  type: actionTypes.LOAD_CART_ISLOADING_END,
});

cartActions.loadCartFailure = (error) => ({
  error,
  type: actionTypes.LOAD_CART_FAIL,
});

cartActions.updateTaskCartStatus = (id, status) => ({
  id,
  status,
  type: actionTypes.UPDATE_TASK_CART_STATUS,
});

cartActions.fulfillmentQuestionsStarted = (productId, quantity) => ({
  productId,
  quantity,
  type: actionTypes.FULFILLMENT_QUESTIONS_STARTED,
});

cartActions.fulfillmentQuestionsFinished = (productId) => ({
  productId,
  type: actionTypes.FULFILLMENT_QUESTIONS_ANSWERED,
});

cartActions.cartOpenModal = () => ({
  type: actionTypes.CART_OPEN_MODAL,
});

cartActions.cartCloseModal = () => ({
  type: actionTypes.CART_CLOSE_MODAL,
});

cartActions.addCatalogItemStart = (addingCatalogItemID) => ({
  addingCatalogItemID,
  type: actionTypes.ADD_CATALOG_ITEM_START,
});

cartActions.addCatalogItemSuccess = () => ({
  type: actionTypes.ADD_CATALOG_ITEM_SUCCESS,
});

cartActions.addCatalogItemFailure = () => ({
  type: actionTypes.ADD_CATALOG_ITEM_FAILURE,
});

cartActions.startLoadCartWF = () => ({
  type: actionTypes.LOAD_CART_WF_START,
});

cartActions.stopLoadCartWF = () => ({
  type: actionTypes.LOAD_CART_WF_STOP,
});

export default cartActions;
