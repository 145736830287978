import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';

// Admin Controller
import WorkflowEditorPageController from '../../../../../../admin/js/v1/components/controllers/WorkflowEditorPageController';
// Atoms
// import DragDrop                     from '../../../../../../admin/js/v1/components/atoms/DragDrop';
import SaveButton from '../../../../../../admin/js/v1/components/atoms/SaveButton';
// Organisms
import RemoveTaskModal from '../../../../../../admin/js/v1/components/organisms/RemoveTaskModal';
import WorkflowModal from '../../organisms/WorkflowModal';
// Controllers
import AddTaskModalController from '../../controllers/AddTaskModalController';

// Utils
import uiPathGenerator from '../../../utils/uiPathGenerator';
import { intl } from 'esp-util-intl';

class WorkflowEditorPage extends PureComponent {
  static propTypes = {
    addTask: PropTypes.func.isRequired,
    getTasks: PropTypes.func.isRequired,

    newTask: PropTypes.oneOfType([PropTypes.object]).isRequired,
    params: PropTypes.shape({
      workflowID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    rearrangeTasks: PropTypes.func.isRequired,
    removeTask: PropTypes.func.isRequired,
    removingTask: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    saveStatus: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      workflowFormIsChanged: PropTypes.bool,
      workflowFormSaveInProgress: PropTypes.bool,
    }).isRequired,
    saveTasksRearrangement: PropTypes.func.isRequired,
    tasks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    const { params, getTasks } = this.props;
    const workflowId = Number(params.workflowID);
    getTasks(workflowId);
  }

  handleSave = () => {
    const { saveTasksRearrangement } = this.props;
    saveTasksRearrangement();
  };

  handleOrderChange = (ordering) => {
    const { rearrangeTasks } = this.props;
    rearrangeTasks(ordering);
  };

  render() {
    const {
      addTask,

      newTask,
      removeTask,
      removingTask,
      saveStatus,
      tasks,
    } = this.props;

    const { workflowFormIsChanged, workflowFormSaveInProgress } = saveStatus;

    const addTaskButton = (
      <Button content='Add Workflow Task' fluid icon='tasks' size='large' />
    );

    const removeTaskButton = <Button icon='close' />;

    const editWorkflowbutton = <Icon link name='write' />;

    return (
      <Grid.Row>
        <Grid.Column width={11}>
          <Menu secondary>
            <Menu.Item fitted='horizontally'>
              {tasks.workflow && tasks.workflow.name}
            </Menu.Item>
            <Menu.Item fitted='horizontally'>
              <WorkflowModal
                existingWorkflow={tasks.workflow}
                trigger={editWorkflowbutton}
              />
            </Menu.Item>
          </Menu>
        </Grid.Column>

        <Grid.Column width={4}>
          <Menu secondary>
            <Menu.Item fitted='horizontally' position='right'>
              <SaveButton
                loading={workflowFormSaveInProgress}
                onSaveFunc={this.handleSave}
                saved={!workflowFormIsChanged}
              />
            </Menu.Item>
          </Menu>
        </Grid.Column>

        <Grid.Column width={16}>
          <Loader active={tasks.isLoading} inline='centered' />
        </Grid.Column>

        <Grid.Column width={16}>
          {tasks.items &&
            tasks.items.map((task) => (
              <Segment
                clearing
                key={task.id}
                style={{
                  width: 'calc(100% - 2em)',
                }}
              >
                <Grid>
                  <Grid.Column width={15}>
                    <Header>{task.name}</Header>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Button.Group floated='right' size='tiny' vertical>
                      <Button
                        as={Link}
                        icon='pencil'
                        to={uiPathGenerator.genPath('editor.workflowTask', {
                          workflowID: tasks.workflow.id,
                          workflowTaskID: task.id,
                        })}
                      />
                      <RemoveTaskModal
                        removeTask={removeTask}
                        removingTask={removingTask}
                        taskId={task.id}
                        trigger={removeTaskButton}
                      />
                    </Button.Group>
                  </Grid.Column>
                </Grid>
              </Segment>
            ))}
          <Grid centered>
            <Grid.Column computer={5} mobile={16} tablet={8}>
              {tasks.workflow ? (
                <AddTaskModalController
                  addTask={addTask}
                  intl={intl}
                  newTask={newTask}
                  trigger={addTaskButton}
                  workflow={tasks.workflow}
                />
              ) : null}
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
WorkflowEditorPage = WorkflowEditorPageController(WorkflowEditorPage);

export default WorkflowEditorPage;
