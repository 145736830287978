import { Button, Form, Modal, Select } from 'semantic-ui-react';
import { noop, omit, startCase } from 'lodash';
import React, { PureComponent } from 'react';

import { intl } from 'esp-util-intl';
import HierarchySelect from './HierarchySelect';
import HierarchyTypes from '../../globals/HierarchyTypes';
import LocationSelectController from '../controllers/LocationSelectController';
import PropTypes from 'prop-types';
import ScrollArea from '../atoms/ScrollArea';

const LocationHierarchySelect = LocationSelectController(HierarchySelect);

class LocationSelect extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    noRootPreselect: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    placeholder: PropTypes.string,
    renderEmptySelectionTrigger: PropTypes.func,
    renderSelectionTrigger: PropTypes.func,
    selectOptionRequired: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.string),
    trigger: PropTypes.node,
  };

  static defaultProps = {
    disabled: false,
    fluid: false,
    noRootPreselect: false,
    onClose: noop,
    onOpen: noop,
    placeholder: '',
    renderEmptySelectionTrigger: null,
    renderSelectionTrigger: null,
    selectOptionRequired: false,
    style: null,
    trigger: null,
  };

  state = {
    isModalOpen: false,
  };

  handleCloseModal =
    (resetState = noop) =>
    () => {
      resetState();
      const { onClose } = this.props;
      onClose();
      this.setState({ isModalOpen: false });
    };

  handleOpenModal = () => {
    const { onOpen } = this.props;

    onOpen();
    this.setState({ isModalOpen: true });
  };

  renderEmptySelectionTrigger = (isLoadingDefaultSelectedLocation) => {
    const { disabled, fluid, placeholder, style } = this.props;

    return (
      <Select
        disabled={disabled || isLoadingDefaultSelectedLocation}
        fluid={fluid}
        loading={isLoadingDefaultSelectedLocation}
        options={[]}
        placeholder={placeholder}
        style={style}
      />
    );
  };

  renderSelectionTrigger = (selectedLocation) => {
    const { disabled, fluid, style } = this.props;

    let location_type_name = selectedLocation.get('location_type_name');
    location_type_name = startCase(location_type_name);
    const name = `${location_type_name}: ${selectedLocation.get('name')}`;

    const options = [
      {
        code: selectedLocation.get('code'),
        text: name,
        value: selectedLocation.get('id'),
      },
    ];

    return (
      <Select
        disabled={disabled}
        fluid={fluid}
        options={options}
        style={style}
        value={selectedLocation.get('id')}
      />
    );
  };

  renderSelectNodeButton = () => (
    <Button>
      {intl.formatMessage({
        id: 'label.select',
      })}
    </Button>
  );

  renderModal = (
    triggerFromParam,
    dropdowns,
    selectNodeButton,
    nodeToDisplay,
    resetState
  ) => {
    const { isModalOpen } = this.state;
    const { trigger } = this.props;
    // nodeToDisplay;

    return (
      <Modal
        className='scrolling'
        closeIcon={
          <div className='item close'>
            {intl.formatMessage({
              id: 'label.close',
            })}
          </div>
        }
        onClose={this.handleCloseModal(resetState)}
        onOpen={this.handleOpenModal}
        open={isModalOpen}
        size='small'
        trigger={trigger || triggerFromParam}
      >
        <div className='ui form pick-location'>
          <Modal.Header>
            {intl.formatMessage({
              id: 'label.pick_a_location',
            })}
          </Modal.Header>
          <Modal.Content className='withFooter'>
            <ScrollArea>
              <Form>{dropdowns}</Form>
            </ScrollArea>
          </Modal.Content>
          <Modal.Actions>{selectNodeButton}</Modal.Actions>
        </div>
      </Modal>
    );
  };

  render() {
    // explicitly listing omitted props since 'propTypes' is removed for production builds
    const nonOwnProps = omit(this.props, [
      'disabled',
      'placeholder',
      'renderEmptySelectionTrigger',
      'renderSelectionTrigger',
    ]);

    const {
      noRootPreselect,
      renderEmptySelectionTrigger,
      renderSelectionTrigger,
      selectOptionRequired,
    } = this.props;

    const newRenderEmptySelectionTrigger =
      renderEmptySelectionTrigger || this.renderEmptySelectionTrigger;

    const newRenderSelectionTrigger =
      renderSelectionTrigger || this.renderSelectionTrigger;

    return (
      <LocationHierarchySelect
        hierarchyType={HierarchyTypes.LOCATIONS}
        noRootPreselect={noRootPreselect}
        onCloseModal={this.handleCloseModal()}
        renderEmptySelectionTrigger={newRenderEmptySelectionTrigger}
        renderModal={this.renderModal}
        renderSelectNodeButton={this.renderSelectNodeButton}
        renderSelectionTrigger={newRenderSelectionTrigger}
        required={selectOptionRequired}
        typeNameAttr='location_type_name'
        {...nonOwnProps}
      />
    );
  }
}

export default LocationSelect;
