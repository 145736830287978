const CaseStates = {
  APPROVED: 'approved',
  ARCHIVED: 'archived',
  ASSIGNED: 'assigned', // currently not used. We should know if it's assigned by seeinf if the attribute 'owner' exist. Martin did it.
  CANCELLED_BY_AGENT: 'cancelled_by_agent',
  CANCELLED_BY_USER: 'cancelled_by_user',
  CANNOT_REPRODUCE: 'cannot_reproduce',
  CLOSED: 'closed',
  DISCARDED: 'discarded',
  DUPLICATE: 'duplicate',
  LEARNED: 'learned',
  OPEN: 'open',
  PENDING_APPROVAL: 'pending_approval',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  SUBMITTED: 'submitted',
  VALIDATED: 'validated',
};

export default CaseStates;
