import Immutable, { fromJS, Map, Set } from 'immutable';
import actionTypes from '../actions/actionTypes';
import _ from 'lodash';

const INITIAL_STATE = fromJS({
  KBTitle: null,
  KBid: null,
  approveAllLoading: false,
  areFeedsRead: {},
  baristaSearchResults: [],
  cardsState: {},
  childrenTasks: Immutable.List(),
  count: 0,
  currentCaseServiceDepartment: {},
  errorMsg: '',
  isCreatingNewCase: false,
  isListLoading: false,
  isLoadingBaristaSearch: false,
  isLoadingCreateCase: false,
  isLoadingServiceDepartment: false,
  isLoadingSetApprovalQuestions: false,
  isLoadingTaskCategory: false,
  loaded: false,
  pagination: {},
  parentTask: Immutable.Map(),
  subscribersSelect: {
    forTaskId: null,
    isLoading: false,
    isModalOpen: false,
    subscriptionScope: {},
    tmpSelectedLocationId: null,
    tmpSelectedLocationName: '',
  },
  taskCategoryResults: [],
  taskLocation: {
    isLoading: false,
    location: Immutable.Map(),
    locationDetail: Immutable.Map(),
    userLocations: Immutable.List(),
  },
  teamSelect: {
    count: 0,
    isLoading: false,
    isSearching: false,
    next: null,
    searchTerm: '',
    summaryList: [],
    teamsList: [],
  },
  usersListServiceTeam: [],
});

/**
 * case feed reducer
 * @param state
 * @param action
 * @returns {*}
 */
const casesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SET_APPROVAL_TASK_QUESTIONS_START: {
      return state.set('isLoadingSetApprovalQuestions', true);
    }
    case actionTypes.SET_APPROVAL_TASK_QUESTIONS_SUCCESS: {
      return state.set('isLoadingSetApprovalQuestions', false);
    }
    case actionTypes.SET_APPROVAL_TASK_QUESTIONS_FAIL: {
      return state.set('isLoadingSetApprovalQuestions', false);
    }
    case actionTypes.CASE_SET_KB_WF: {
      return state.set('KBid', action.KBid).set('KBTitle', action.KBTitle);
    }
    case actionTypes.CASE_LOAD_TEAMS_LIST_START: {
      return state
        .setIn(['teamSelect', 'teamsList'], fromJS([]))
        .setIn(['teamSelect', 'isLoading'], true);
    }

    case actionTypes.CASE_LOAD_TEAMS_LIST_FAIL: {
      return state
        .setIn(['teamSelect', 'isSearching'], false)
        .setIn(['teamSelect', 'isLoading'], false);
    }

    case actionTypes.CASE_LOAD_TEAMS_LIST_SUCCESS: {
      return state
        .setIn(['teamSelect', 'teamsList'], fromJS(action.teams))
        .setIn(['teamSelect', 'isSearching'], false)
        .setIn(['teamSelect', 'isLoading'], false);
    }

    case actionTypes.CASE_LOAD_SUMMARY_START: {
      return state.setIn(['teamSelect', 'isLoading'], true);
    }

    case actionTypes.CASE_LOAD_SUMMARY_FAIL: {
      return state.setIn(['teamSelect', 'isLoading'], false);
    }

    case actionTypes.CASE_LOAD_SUMMARY_SUCCESS: {
      if (action.resetTeamList) {
        // Reset the current users list to replace it by a new one
        state = state.setIn(['teamSelect', 'summaryList'], fromJS([]));
      }

      const teamToload = fromJS(action.teams);
      let newTeamList = state.getIn(['teamSelect', 'summaryList']);
      newTeamList = action.concate
        ? newTeamList.concat(teamToload)
        : teamToload;

      return state
        .setIn(['teamSelect', 'count'], action.count)
        .setIn(['teamSelect', 'summaryList'], newTeamList)
        .setIn(['teamSelect', 'isLoading'], false)
        .setIn(['teamSelect', 'next'], action.next)
        .setIn(['teamSelect', 'isSearching'], false)
        .setIn(['teamSelect', 'isLoading'], false);
    }

    case actionTypes.CASE_IS_SEARCHING_TEAM: {
      return state.setIn(['teamSelect', 'isSearching'], true);
    }

    case actionTypes.CASE_SET_USER_SEARCH_TERM: {
      return state.setIn(['teamSelect', 'searchTerm'], action.searchTerm);
    }

    case actionTypes.CASE_RESET_TEAMS_LIST: {
      return state.set('teamSelect', INITIAL_STATE.get('teamSelect'));
    }

    case actionTypes.CASE_TASK_UPDATE: {
      const { task } = action;
      const teamId = _.hasIn(task, 'service_team.id')
        ? task.service_team.id
        : null;

      let newState = state;
      if (teamId) {
        const isFeedRead = task.is_feed_read;
        newState = newState.setIn(['areFeedsRead', teamId], isFeedRead);
      }

      return newState;
    }

    case actionTypes.SET_REJECTION_REASON_CONFIG: {
      return state
        .set('isRejectionReasonConfigEnabled', fromJS(action.value))
        .set('isRejectionReasonConfigSet', fromJS(true));
    }

    case actionTypes.CASE_TASK_SUCCESS: {
      return state.set('usersListServiceTeam', fromJS(action.teamMembers));
    }

    case actionTypes.CASE_FEED_START: {
      return state
        .set('loaded', false)
        .set('errorMsg', '')
        .set('isListLoading', true)
        .set('pagination', fromJS({}))
        .set('count', 0);
    }

    case actionTypes.CASE_FEED_SUCCESS: {
      return state
        .set('loaded', true)
        .set('isListLoading', false)
        .set('errorMsg', '')
        .set('pagination', fromJS(action.pagination))
        .set('count', action.count || 0);
    }

    case actionTypes.CASE_FEED_FAIL: {
      return state
        .set('loaded', false)
        .set('isListLoading', false)
        .set('errorMsg', action.errorMsg)
        .set('pagination', fromJS({}))
        .set('count', 0);
    }

    case actionTypes.APPROVAL_FEED_START: {
      return state
        .set('loaded', false)
        .set('errorMsg', '')
        .set('isListLoading', true);
    }

    case actionTypes.APPROVAL_FEED_SUCCESS: {
      return state
        .set('loaded', true)
        .set('isListLoading', false)
        .set('errorMsg', '')
        .set('pagination', fromJS(action.pagination));
    }

    case actionTypes.APPROVAL_FEED_FAIL: {
      return state
        .set('loaded', false)
        .set('isListLoading', false)
        .set('errorMsg', action.errorMsg);
    }

    case actionTypes.CASE_TOGGLE_CARD_OPEN: {
      const { taskId } = action;
      const isOpen = Boolean(state.getIn(['cardsState', taskId, 'isOpen']));

      // Inverting the value
      return state.setIn(['cardsState', taskId, 'isOpen'], !isOpen);
    }

    case actionTypes.APPROVE_ALL_ACTION_START: {
      return state.set('approveAllLoading', true);
    }

    case actionTypes.APPROVE_ALL_ACTION_END: {
      const hasError = Boolean(action.error);
      return state
        .set('errorMsg', hasError ? action.error : '')
        .set('approveAllLoading', false);
    }

    case actionTypes.CASE_ACTION_START: {
      const { taskId } = action;

      // Flagging that is doing something
      return state.setIn(['cardsState', taskId, 'isDoingAction'], true);
    }

    case actionTypes.CASE_ACTION_END: {
      const { taskId } = action;
      const hasError = Boolean(action.error);

      return state
        .setIn(['cardsState', taskId, 'isDoingAction'], false)
        .setIn(['cardsState', taskId, 'hasError'], hasError)
        .setIn(['cardsState', taskId, 'isOpen'], false);
    }

    case actionTypes.LOAD_CASE_CATEGORIES_START: {
      return state.set('isLoadingTaskCategory', true);
    }

    case actionTypes.LOAD_CASE_CATEGORIES_SUCCESS: {
      let taskCategoryResults = state.get('taskCategoryResults');
      // Update existing announcements with what came from action
      taskCategoryResults = taskCategoryResults.concat(
        fromJS(action.categories)
      );

      // Smart way to get rid of duplicates, in case this announcements are being re-hidratated
      taskCategoryResults = taskCategoryResults
        .groupBy((m) => m.get('id'))
        .map((x) => x.last())
        .sortBy((x) => x.get('id'))
        .toList();

      return state
        .set('taskCategoryResults', taskCategoryResults)
        .set('isLoadingTaskCategory', false);
    }

    case actionTypes.LOAD_CASE_CATEGORIES_FAIL: {
      return state.set('isLoadingTaskCategory', false);
    }

    case actionTypes.LOAD_CASE_LOCATION_START: {
      return state.setIn(['taskLocation', 'isLoading'], true);
    }

    case actionTypes.LOAD_CASE_LOCATION_FAIL: {
      return state.setIn(['taskLocation', 'isLoading'], false);
    }

    case actionTypes.LOAD_CASE_LOCATION_SUCCESS: {
      return state
        .setIn(['taskLocation', 'isLoading'], false)
        .setIn(['taskLocation', 'location'], fromJS(action.location));
    }

    case actionTypes.LOAD_CASE_SERVICE_DEPARTMENT_START: {
      return state.setIn(['isLoadingServiceDepartment'], true);
    }
    case actionTypes.LOAD_CASE_SERVICE_DEPARTMENT_SUCCESS: {
      return state
        .set('service_department', fromJS(action.department))
        .setIn(['isLoadingServiceDepartment'], false);
    }

    case actionTypes.LOAD_CASE_SERVICE_DEPARTMENT_FAIL: {
      return state.setIn(['isLoadingServiceDepartment'], false);
    }

    case actionTypes.SET_CURRENT_CASE_SERVICE_DEPARTMENT_SUCCESS: {
      return state
        .set('currentCaseServiceDepartment', fromJS(action.department))
        .setIn(['isLoadingServiceDepartment'], false);
    }
    case actionTypes.LOAD_USER_LOCATION_START: {
      return state.setIn(['taskLocation', 'isLoading'], true);
    }

    case actionTypes.LOAD_USER_LOCATION_FAIL: {
      return state.setIn(['taskLocation', 'isLoading'], false);
    }

    case actionTypes.LOAD_USER_LOCATION_SUCCESS: {
      return state
        .setIn(['taskLocation', 'isLoading'], false)
        .setIn(['taskLocation', 'userLocations'], fromJS(action.userLocations));
    }

    case actionTypes.LOAD_USER_LOCATION_DETAIL_START: {
      return state.setIn(['taskLocation', 'isLoading'], true);
    }

    case actionTypes.LOAD_USER_LOCATION_DETAIL_FAIL: {
      return state.setIn(['taskLocation', 'isLoading'], false);
    }

    case actionTypes.LOAD_USER_LOCATION_DETAIL_SUCCESS: {
      return state
        .setIn(['taskLocation', 'isLoading'], false)
        .setIn(
          ['taskLocation', 'locationDetail'],
          fromJS(action.locationDetail)
        );
    }

    case actionTypes.CREATE_CASE_START: {
      return state.set('isLoadingCreateCase', true);
    }

    case actionTypes.CREATE_CASE_FAIL: {
      return state.set('isLoadingCreateCase', false);
    }

    case actionTypes.CREATE_CASE_SUCCESS: {
      return state.set('isLoadingCreateCase', false);
    }

    case actionTypes.SET_ARE_FEEDS_READ: {
      let newState = state;
      _.forOwn(action.areFeedsRead, (value, key) => {
        // here key is the id of the task
        // but it's returned as a string by the BE
        // and being a PK, everybody else uses it as integer
        newState = newState.setIn(['areFeedsRead', Number(key)], value);
      });

      return newState;
    }

    case actionTypes.CASE_CREATING_NEW_START: {
      return state.set('isCreatingNewCase', true);
    }

    case actionTypes.CASE_CREATING_NEW_END: {
      return state.set('isCreatingNewCase', false);
    }

    case actionTypes.CASE_OPEN_ADD_SUBSCRIBERS: {
      return state
        .setIn(['subscribersSelect', 'isModalOpen'], true)
        .setIn(['subscribersSelect', 'forTaskId'], action.taskId);
    }

    case actionTypes.CASE_CLOSE_ADD_SUBSCRIBERS: {
      return state
        .setIn(['subscribersSelect', 'isModalOpen'], false)
        .setIn(['subscribersSelect', 'forTaskId'], null);
    }

    case actionTypes.CASE_SET_DEFAULT_SUBSCRIBERS: {
      return state.setIn(
        ['subscribersSelect', 'defaultSubscribers'],
        Set(fromJS(action.defaultSubscribers))
      );
    }
    case actionTypes.CASE_SUBSCRIBERS_START: {
      return state.setIn(['subscribersSelect', 'isLoading'], true);
    }
    case actionTypes.CASE_SUBSCRIBERS_END: {
      return state.setIn(['subscribersSelect', 'isLoading'], false);
    }

    case actionTypes.SET_TMP_SUBSCRIBER_LOCATION_SELECTION: {
      return state
        .setIn(
          ['subscribersSelect', 'tmpSelectedLocationId'],
          action.locationId
        )
        .setIn(
          ['subscribersSelect', 'tmpSelectedLocationName'],
          action.locationName
        );
    }

    case actionTypes.CASE_SUBS_SCOPE_SUCCESS: {
      let newScope = state;
      if (action.scope) {
        newScope = newScope.setIn(
          ['subscribersSelect', 'subscriptionScope'],
          fromJS(action.scope)
        );
      }

      newScope = newScope.setIn(
        ['subscribersSelect', 'tmpSelectedLocationId'],
        null
      );
      newScope = newScope.setIn(
        ['subscribersSelect', 'tmpSelectedLocationName'],
        ''
      );

      return newScope;
    }

    case actionTypes.CASE_SUBS_SCOPE_START: {
      return state.setIn(
        ['subscribersSelect', 'subscriptionScope'],
        fromJS({})
      );
    }

    case actionTypes.SET_PARENT_TASK: {
      return state.set(
        'parentTask',
        Map.isMap(action.parentTask)
          ? action.parentTask
          : fromJS(action.parentTask)
      );
    }

    case actionTypes.SET_CHILDREN_TASKS: {
      return state.set('childrenTasks', fromJS(action.childrenTasks));
    }

    case actionTypes.FAQ_CASES_SEARCH_START: {
      return state
        .set('isLoadingBaristaSearch', true)
        .set('baristaSearchResults', fromJS([]));
    }

    case actionTypes.FAQ_CASES_SEARCH_SUCCESS: {
      return state
        .set('isLoadingBaristaSearch', false)
        .set('baristaSearchResults', fromJS(action.results));
    }

    default: {
      return state;
    }
  }
};

export default casesReducer;
