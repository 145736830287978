const api_barista_v0_1_faqs_general_a59c9e3e = {
  attachment: '61d1e189-7390-40cd-9e50-e80cea139e60',
  attachment_url:
    'http://tenant1.esp/media/tenant1/base_attachments/e7460f80-7ce9-49d9-b00d-e94b78db794c/txt.txt',
  eid: '82244c11-99b4-4831-8dbb-27fe80341119',
  order: 7,
  phrase: '',
  response: '66c70912-3e99-45a0-88e0-7c3f3ad30b45',
  sys_created_by: 'john@acme.com',
  sys_date_created: '2018-07-09T18:43:57.553681Z',
  sys_date_updated: '2018-07-09T18:43:57.553607Z',
  sys_updated_by: 'john@acme.com',
  widget: null,
};

export default api_barista_v0_1_faqs_general_a59c9e3e;
