import React from 'react';
import { Dropdown, Grid, Header, Message } from 'semantic-ui-react';
import useUpdateHomefeedDefaultSorting from '../../../customHooks/tenantCustomization/useUpdateHomefeedDefaultSorting';

const propTypes = {};

const homefeedSortingOptions = [
  {
    key: 'filter.none',
    text: 'None',
    value: 'filter.none',
  },
  {
    key: 'filter.created_new_old',
    text: 'Created: New to Old',
    value: 'filter.created_new_old',
  },
  {
    key: 'filter.created_old_new',
    text: 'Created: Old to New',
    value: 'filter.created_old_new',
  },
  {
    key: 'filter.updated_new_old',
    text: 'Updated: New to Old',
    value: 'filter.updated_new_old',
  },
  {
    key: 'filter.updated_old_new',
    text: 'Updated: Old to New',
    value: 'filter.updated_old_new',
  },
];
const TenantConfigSorterDefault = () => {
  const { handleToggleHomefeedSorting, sortType } =
    useUpdateHomefeedDefaultSorting();

  return (
    <Grid centered>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as='h4' dividing>
            Card Feed Sorting
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={6}>
          <label>{'Enable Homefeed default sorting'}</label>
          <Dropdown
            name='homefeedSorting'
            onChange={handleToggleHomefeedSorting}
            options={homefeedSortingOptions}
            placeholder={'Select filter'}
            search
            selection
            style={{ marginLeft: '1rem' }}
            value={sortType || ''}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Message info>
            <p>Allow tenant level default sorting</p>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

TenantConfigSorterDefault.propTypes = propTypes;
export default TenantConfigSorterDefault;
