import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash';

// Utils
import uiPathGenerator from '../../utils/uiPathGenerator';

// Controller
import BaristaQRController from '../controllers/BaristaQRController';

/**
 * This component does not render anything
 * but rather acts as flight control to whatever QR parameters are passed down to 'app/barista'
 * then just calls thunks/actions to initializa barista modal
 * with the appropiate set up
 * Really the core of this thing is in componentDidMount
 */
class BaristaQR extends PureComponent {
  static propTypes = {
    channelID: PropTypes.string,
    intentEid: PropTypes.string,
    isPortal: PropTypes.bool,
    // these will be passed by router
    location: PropTypes.shape({
      query: PropTypes.shape({}),
      search: PropTypes.string,
    }),
    openBarista: PropTypes.func.isRequired, // From controller
  };

  static defaultProps = {
    channelID: '',
    intentEid: '',
    isPortal: false,
    location: {
      query: {},
      search: '',
    },
  };

  componentDidMount() {
    const { channelID, intentEid, location, openBarista } = this.props;
    openBarista(location.search, channelID, intentEid);
  }

  componentDidUpdate(prevProps) {
    const { channelID, intentEid, location, openBarista } = this.props;

    const prevLocation = prevProps.location;
    const isSameQuery = prevLocation.search === location.search;

    // Reopens barista even if the QR query is the same after resetting the channel (DEV-6275)
    const isSameButRefreshed =
      isSameQuery && get(location, 'key') !== get(prevLocation, 'key');
    if (!isSameQuery || isSameButRefreshed) {
      openBarista(location.search, channelID, intentEid);
    }
  }

  render() {
    const { isPortal } = this.props;
    if (isPortal) {
      return <Redirect to={uiPathGenerator.genPath('portalBarista')} />;
    } else {
      return <Redirect to={uiPathGenerator.genPath('app.toDo')} />;
    }
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
BaristaQR = BaristaQRController(BaristaQR);

export default BaristaQR;
