import { any, numericOnly, optional, uuidOnly } from './PathParamsValidators';
import { createCompositePathPattern as $ } from './CompositePathPattern';
import { param } from './PathParam';

const uiPaths = {
  children: {
    admin: {
      children: {
        actions: {
          children: {},
          pattern: 'actions/:sub?',
        },
        adminActivities: {
          children: {
            offboarding: {
              children: {
                performance: {
                  children: {},
                  pattern: 'performance/:userID?',
                },
                schedule: {
                  children: {},
                  pattern: 'schedule/:scheduleID?/:action?',
                },
                templates: {
                  children: {},
                  pattern: 'templates/:templateID?',
                },
              },
              pattern: 'offboarding',
            },
            onboardingActivities: {
              children: {
                performance: {
                  children: {},
                  pattern: 'performance/:userID?',
                },
                schedule: {
                  children: {},
                  pattern: 'schedule/:scheduleID?/:action?',
                },
                templates: {
                  children: {},
                  pattern: 'templates/:templateID?',
                },
              },
              pattern: 'onboarding-activities',
            },
          },
          pattern: 'admin-activities',
        },
        adminInteractions: {
          children: {
            interactionChannel: {
              children: {},
              pattern: 'channel/:channelEid',
            },
          },
          pattern: 'interactions',
        },
        analytics: {
          children: {},
          pattern: 'analytics/:sub?',
        },
        announcements: {
          children: {},
          pattern: 'announcements/:sub?',
        },
        approvalRules: {
          children: {},
          pattern: 'approval-rules',
        },
        authentication: {
          children: {},
          pattern: 'authentication',
        },
        bundle: {
          children: {
            detail: {
              children: {},
              pattern: 'detail/:bundleID',
            },
            list: {
              children: {},
              pattern: 'list/:page',
            },
            new: {
              children: {},
              pattern: 'new',
            },
          },
          pattern: 'bundle',
        },
        dashboard: {
          children: {},
          pattern: 'dashboard',
        },
        dpc: {
          children: {
            detail: {
              children: {
                family: {
                  children: {},
                  pattern: 'family',
                },
                specs: {
                  children: {},
                  pattern: '',
                },
              },
              pattern: 'detail/:productID',
            },
            list: {
              children: {},
              pattern: 'list/:page?/:categoryID?/:subcategoryID?',
            },
          },
          pattern: 'brc', // This was changed for Barista Reference Catalog
        },
        faq: {
          children: {
            list: {
              children: {},
              pattern: 'list/:page/:departmentID?/:categoryID?',
            },
          },
          pattern: 'faq',
        },

        faqsV2: {
          children: {
            detail: {
              children: {
                info: {
                  children: {},
                  pattern: 'info',
                },
                matching: {
                  children: {},
                  pattern: 'matching',
                },
                responses: {
                  children: {},
                  pattern: 'responses',
                },
              },
              pattern:
                'faqEID/:faqEID?/:departmentID?/:categoryID?/:intentSearch?/:applicationEID?',
            },
            // list: {
            //   children : {},
            //   pattern  : 'list/:page/:departmentID?/:categoryID?',
            // },
            list: {
              children: {},
              pattern: 'list/:page/:departmentID?/:applicationEID?',
            },
            new: {
              children: {},
              pattern:
                'new/:departmentID?/:categoryID?/:intentSearch?/:applicationEID?',
            },
            topics: {
              children: {},
              pattern: 'topics/:page?/:departmentID?/:applicationEID?',
            },
          },
          pattern: 'faqsV2',
        },

        fulfillment: {
          children: {},
          pattern: 'fulfillment/:sub?',
        },
        globalSettings: {
          children: {
            branding: {
              children: {},
              pattern: 'tenant-branding',
            },
            elc: {
              children: {},
              pattern: 'elc',
            },
            emailPolicy: {
              children: {},
              pattern: 'email-policy',
            },
            files: {
              children: {},
              pattern: 'files',
            },
            help: {
              children: {},
              pattern: 'help',
            },
            linkMetadata: {
              children: {},
              pattern: 'link-metadata',
            },
            menu: {
              children: {},
              pattern: 'menu',
            },
            messages: {
              children: {},
              pattern: 'messages',
            },
            notifications: {
              children: {},
              pattern: 'notifications',
            },
          },
          pattern: 'global-settings',
        },
        integrations: {
          children: {
            azure: {
              children: {
                account: {
                  children: {},
                  pattern: 'account',
                },
                basicSSO: {
                  children: {},
                  pattern: 'basic_sso',
                },
                ssoWithSAML: {
                  children: {},
                  pattern: 'sso_with_saml',
                },
              },
              pattern: 'azure',
            },
            confluence: {
              children: {
                connectionSettings: {
                  children: {},
                  pattern: 'connection_settings',
                },
                contentSync: {
                  children: {},
                  pattern: 'content_sync',
                },
              },
              pattern: 'confluence',
            },
            csv: {
              children: {
                history: {
                  children: {},
                  pattern: 'history/:page?',
                },
                mapping: {
                  children: {
                    asset: {
                      children: {
                        mapFields: {
                          children: {},
                          pattern: 'mapping',
                        },
                        mapPreview: {
                          children: {},
                          pattern: 'preview',
                        },
                      },
                      pattern: 'asset',
                    },
                    people: {
                      children: {
                        hiearchyLocation: {
                          children: {},
                          pattern: 'location',
                        },
                        hierarchyDepartment: {
                          children: {},
                          pattern: 'department',
                        },
                        mapFields: {
                          children: {},
                          pattern: 'mapping',
                        },
                        mapPreview: {
                          children: {},
                          pattern: 'preview',
                        },
                      },
                      pattern: 'people',
                    },
                  },
                  pattern: 'mapping',
                },
                schedule: {
                  children: {},
                  pattern: 'schedule/:scheduleID?',
                },
                upload: {
                  children: {},
                  pattern: 'upload',
                },
              },
              pattern: 'csv',
            },
            email: {
              children: {
                case: {
                  children: {},
                  pattern: 'case',
                },
                primary: {
                  children: {},
                  pattern: 'primary',
                },
              },
              pattern: 'email',
            },
            okta: {
              children: {
                account: {
                  children: {},
                  pattern: 'account',
                },
                sso: {
                  children: {},
                  pattern: 'sso',
                },
              },
              pattern: 'okta',
            },
            service_now: {
              children: {
                connection: {
                  children: {},
                  pattern: 'connection',
                },
                contentSync: {
                  children: {},
                  pattern: 'content_sync',
                },
                incidents: {
                  children: {},
                  pattern: 'incident-categories',
                },
                products: {
                  children: {},
                  pattern: 'product-categories',
                },
                updates: {
                  children: {},
                  pattern: 'updates',
                },
                userSync: {
                  children: {},
                  pattern: 'user_sync',
                },
              },
              pattern: 'servicenow',
            },
            sharepoint: {
              children: {
                connectionSettings: {
                  children: {},
                  pattern: 'connection_settings',
                },
                contentSync: {
                  children: {},
                  pattern: 'content_sync',
                },
              },
              pattern: 'sharepoint',
            },
            slack: {
              children: {
                connectionSettings: {
                  children: {},
                  pattern: 'connection_settings',
                },
                homeTab: {
                  children: {},
                  pattern: 'home_tab',
                },
                publicChannelSettings: {
                  children: {},
                  pattern: 'public_channel_settings',
                },
              },
              pattern: 'slack',
            },
            teams: {
              children: {
                connectionSettings: {
                  children: {},
                  pattern: 'connection_settings',
                },
                homeTab: {
                  children: {},
                  pattern: 'home_tab',
                },
                sdkConnection: {
                  children: {},
                  pattern: 'sdk_connection',
                },
                sdkSettings: {
                  children: {},
                  pattern: 'sdk_settings',
                },
              },
              pattern: 'teams',
            },
          },
          pattern: 'integrations',
        },
        interactionsDashboard: {
          children: {
            drillDown: {
              children: {
                details: {
                  children: {},
                  pattern: 'details',
                },
              },
              pattern: 'drilldown',
            },
          },
          pattern: 'interactions-dashboard',
        },
        job_roles: {
          children: {},
          pattern: 'job_roles/:sub?',
        },
        knowledgeArticles: {
          children: {
            detail: {
              children: {},
              pattern: 'detail/:id',
            },
            table: {
              pattern: 'table',
            },
          },
          pattern: 'knowledge-articles',
        },
        knowledgeArticlesV2: {
          children: {
            detail: {
              children: {},
              pattern: 'detail/:id',
            },
            table: {
              pattern: 'table',
            },
          },
          pattern: 'knowledge-articles-v2',
        },
        locations: {
          children: {
            edit: {
              children: {},
              pattern: 'edit/:location',
            },
            list: {
              children: {},
              pattern: 'list/:page',
            },
            view: {
              children: {},
              pattern: 'view/:location',
            },
          },
          pattern: 'locations',
        },
        reports: {
          children: {
            custom: {
              children: {
                detail: {
                  children: {},
                  pattern: 'detail/:phrase/:page',
                },
                entity: {
                  children: {},
                  pattern: ':page/:phrase?',
                },
              },
              pattern: 'custom/:report',
            },
            grid: {
              children: {},
              pattern: 'grid/:report/:page',
            },
            list: {
              children: {},
              pattern: 'list/:page',
            },
            survey: {
              children: {},
              pattern: 'survey',
            },
            view: {
              children: {},
              pattern: 'view/:report',
            },
          },
          pattern: 'reports',
        },
        requests: {
          children: {},
          pattern: 'requests/:sub?',
        },
        roleBundle: {
          children: {
            detail: {
              children: {},
              pattern: 'detail/:bundleID',
            },
            list: {
              children: {},
              pattern: 'list/:page',
            },
            new: {
              children: {},
              pattern: 'new',
            },
          },
          pattern: 'role-bundle',
        },
        roles: {
          children: {},
          pattern: 'roles/:sub?',
        },
        serviceDepartment: {
          children: {
            detail: {
              children: {
                caseManagement: {
                  children: {},
                  pattern: 'case-management',
                },
                categories: {
                  children: {},
                  pattern: 'categories',
                },
                teams: {
                  children: {},
                  pattern: 'teams',
                },
              },
              pattern: 'detail/:departmentID?',
            },
            list: {
              children: {},
              pattern: 'list',
            },
          },
          pattern: 'service-department',
        },
        serviceTeams: {
          children: {
            detail: {
              children: {
                location: {
                  children: {},
                  pattern: 'location',
                },
                members: {
                  children: {},
                  pattern: 'members',
                },
                settings: {
                  children: {},
                  pattern: 'settings',
                },
                substates: {
                  children: {},
                  pattern: 'substates/:page',
                },
              },
              pattern: 'detail/:teamID?',
            },
            import: {
              children: {},
              pattern: 'import',
            },
            list: {
              children: {},
              pattern: 'list',
            },
          },
          pattern: 'service-teams',
        },
        sites: {
          children: {},
          pattern: 'sites/:sub?',
        },
        storefrontDesigner: {
          children: {
            detail: {
              children: {
                family: {
                  children: {},
                  pattern: 'family',
                },
                fulfillment: {
                  children: {},
                  pattern: 'fulfillment',
                },
                permissions: {
                  children: {},
                  pattern: 'permissions',
                },
                specs: {
                  children: {},
                  pattern: '',
                },
              },
              pattern: 'detail/:productID',
            },
            list: {
              children: {},
              pattern: 'list/:page/:categoryID?/:subcategoryID?',
            },
            new: {
              children: {},
              pattern: 'new',
            },
          },
          pattern: 'storefront-designer',
        },
        tickets: {
          children: {
            dashboard: {
              children: {},
              pattern: 'dashboard',
            },
            edit: {
              children: {},
              pattern: ':ticketId',
            },
            list: {
              children: {},
              pattern: 'list',
            },
          },
          pattern: 'tickets',
        },
        user_groups: {
          children: {
            // Set here the add, edit, clone, delete, view ui paths for user_groups
          },
          pattern: 'user_groups',
        },
        users: {
          children: {
            list: {
              children: {},
              pattern: 'list/:page',
            },
          },
          pattern: 'users',
        },
        users_v2: {
          children: {
            addUser: {
              children: {},
              pattern: 'user',
            },
            editUser: {
              children: {},
              pattern: 'user/:userId',
            },
          },
          pattern: 'users',
        },
        workflow_editor: {
          children: {},
          pattern: 'workflow-editor/:sub?',
        },
      },
      pattern: 'admin',
    },

    app: {
      children: {
        aboutBarista: {
          children: {
            feedbackAndHelp: {
              pattern: 'feedback-and-help',
            },
          },
          pattern: 'about-barista',
        },

        announcements: {
          children: {
            manage: {
              children: {},
              pattern: ':announcementID',
            },
          },
          pattern: 'announcements',
        },

        approvalsFeed: {
          children: {
            detail: {
              children: {
                conversation: {
                  children: {},
                  pattern: 'conversation',
                },
                questions: {
                  children: {},
                  pattern: 'questions',
                },
                request: {
                  children: {},
                  pattern: 'request',
                },
              },
              pattern: param('caseID', numericOnly),
            },
          },
          noFullPermissionForAdmin: true,
          pattern: 'approvals',
        },

        barista: {
          children: {
            channel: {
              children: {},
              pattern: param('channelID', uuidOnly),
            },
            intent: {
              children: {},
              pattern: 'intent/:intentEid',
            },
          },
          pattern: 'barista',
        },

        casesFeed: {
          children: {
            detail: {
              children: {
                info: {
                  children: {
                    location: {
                      children: {},
                      pattern: param('locationID', any),
                    },
                  },
                  pattern: 'info',
                },
              },
              pattern: param('caseID', any),
            },
            // team: {
            //   pattern  : ':teamID?',
            //   children : {},
            // },
          },
          noFullPermissionForAdmin: true,
          pattern: 'cases',
        },

        catalog: {
          children: {
            detail: {
              children: {},
              pattern: ':catalogID',
            },
          },

          pattern: 'shop/:categoryID?/:subcategoryID?',
        },

        directory: {
          children: {
            detail: {
              children: {
                accolades: {
                  children: {},
                  pattern: 'accolades',
                },

                skills: {
                  children: {},
                  pattern: 'skills',
                },
              },

              pattern: param('userID', numericOnly),
            },
          },

          pattern: 'contacts',
        },

        equipment: {
          children: {
            detail: {
              children: {},
              pattern: ':equipmentID',
            },

            pendingOrderDetail: {
              children: {},
              pattern: $('orders', param('orderID', numericOnly)),
            },
          },

          pattern: 'my-equipment',
        },

        feedback: {
          children: {},
          pattern: 'feedback/',
        },

        messages: {
          children: {
            direct: {
              children: {
                user: {
                  children: {},
                  pattern: ':userID',
                },
              },
              pattern: 'd',
            },

            navigation: {
              children: {
                direct: {
                  children: {
                    user: {
                      children: {},
                      pattern: ':userID',
                    },
                  },
                  pattern: 'd',
                },

                scoped: {
                  children: {
                    channel: {
                      children: {},
                      pattern: ':channelID',
                    },
                  },
                  pattern: 's',
                },
              },
              pattern: 'n',
            },

            scoped: {
              children: {
                channel: {
                  children: {},
                  pattern: ':channelID',
                },
              },
              pattern: 's',
            },
          },
          pattern: 'messages',
        },

        newHireProgress: {
          children: {
            detail: {
              children: {},
              pattern: ':userID?',
            },
          },
          pattern: 'new-hire-progress',
        },

        requests: {
          children: {
            detail: {
              children: {},
              pattern: param('requestID', numericOnly),
            },
          },
          pattern: 'requests',
        },

        search: {
          children: {
            cases: {
              children: {
                result: {
                  children: {},
                  pattern: param('caseID', numericOnly),
                },
              },

              pattern: 'cases',
            },

            catalog: {
              children: {
                result: {
                  children: {},
                  pattern: param('catalogID', numericOnly),
                },
              },

              pattern: 'shop',
            },

            directChats: {
              children: {
                result: {
                  children: {},
                  pattern: param('userID', numericOnly),
                },
              },

              pattern: 'messages/d',
            },

            directory: {
              children: {
                result: {
                  children: {},
                  pattern: param('userID', numericOnly),
                },
              },

              pattern: 'contacts',
            },

            equipment: {
              children: {
                result: {
                  children: {},
                  pattern: param('equipmentID', numericOnly),
                },
              },

              pattern: 'my-equipment',
            },

            result: {
              children: {},
              pattern: $(
                param('section'),
                param('subSection', any, optional),
                param('resultID')
              ),
            },

            scopedChats: {
              children: {
                result: {
                  children: {},
                  pattern: param('channelID', uuidOnly),
                },
              },

              pattern: 'messages/s',
            },
          },

          pattern: 'search',
        },

        standaloneProduct: {
          children: {
            detail: {
              children: {},
              pattern: param('catalogID', any),
            },
          },

          pattern: 'products',
        },

        test: {
          children: {},
          pattern: 'test',
        },

        toDo: {
          children: {
            cardDetail: {
              children: {},
              pattern: ':type/:announcementId',
            },

            chat: {
              children: {},
              pattern: ':cardID/chat/:channelID',
            },

            chatCardAction: {
              children: {},
              pattern: ':cardID/chat/:channelID/:action',
            },

            chatConversationCard: {
              children: {},
              pattern: ':cardID/chatWith/:userID',
            },

            onboardingActivitiesEscalate: {
              children: {},
              // ':cardID/escalate/:userID(\\d+)'
              pattern: $(
                param('cardID', uuidOnly),
                'escalate',
                param('userID', numericOnly)
              ),
            },
          },

          pattern: 'home',
        },
      },

      pattern: 'app',
    },

    auth: {
      children: {
        inactive: {
          children: {},
          pattern: 'inactive',
        },
        oauth: {
          children: {},
          pattern: 'oauth',
        },

        saml: {
          children: {},
          pattern: 'saml',
        },
      },
      pattern: 'auth',
    },

    caffeineApp: {
      children: {
        barista: {
          children: {
            interactions: {
              children: {},
              pattern: 'interactions',
            },
          },
          pattern: 'barista',
        },
      },
      pattern: 'caffeine',
    },

    chatV2App: {
      children: {
        chat: {
          children: {},
          pattern: 'chat/',
        },
      },
      pattern: 'v2',
    },

    editor: {
      children: {
        workflow: {
          children: {},
          pattern: ':workflowID',
        },

        workflowTask: {
          children: {},
          pattern: ':workflowID/:workflowTaskID',
        },
      },
      pattern: 'editor',
    },

    intlApp: {
      children: {},
      pattern: 'intl',
    },

    login: {
      children: {},
      pattern: 'login',
    },

    logout: {
      children: {},
      pattern: 'logout',
    },

    noTenant: {
      children: {},
      pattern: 'notenant',
    },

    onboardAuthentication: {
      children: {},
      pattern: 'a/:workflowID/:onboardID?/:ref?/:code?',
    },

    onboardRedirect: {
      children: {},
      pattern: 'onboard-redirect/:workflowID',
    },

    onboardStep: {
      children: {},
      pattern: 'ob/:workflowID/:taskID/:requestID',
    },

    portalBarista: {
      children: {
        channel: {
          pattern: 'channel/:channelID',
        },
        intent: {
          pattern: 'intent/:intentEid',
        },
      },
      pattern: 'portal',
    },

    proxy: {
      children: {
        bquery: {
          pattern: 'bquery',
        },
      },
      pattern: 'proxy',
    },

    resetPassword: {
      children: {},
      pattern: 'reset-password',
    },

    slateApp: {
      children: {},
      pattern: 'slate',
    },

    standAloneKb: {
      children: {},
      pattern: 'kbview',
    },

    teamsApp: {
      children: {},
      pattern: 'teams',
    },

    widgetAuthRequired: {
      children: {},
      pattern: 'widget-auth-required',
    },

    widgetAuthSuccess: {
      children: {},
      pattern: 'widget-auth-success',
    },
    workflow: {
      children: {},
      pattern: 'flow/:workflowID?/:workflowTaskID?',
    },
  },

  pattern: '',
};

export default uiPaths;
