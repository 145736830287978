import { visit } from 'unist-util-visit';
import { isUndefined } from 'lodash';
import getPropertyRecursively from '../utils/getPropertyRecursively';

const defaultSupportedNodesTypes = [
  'break',
  'breakline',
  'root',
  'text',
  'paragraph',
  'code',
  'inlineCode',
  'newline',
];

const { splice } = [];

const visitor = (node, index, parent) => {
  const siblings = parent.children;
  splice.apply(siblings, [index, 1].concat(node.children || []));
  return index;
};

/**
 *
 * @param {import('../EspMarkdown').EspMarkdownProps} config
 * @returns
 */
const allowList =
  ({
    isBarista,
    isScoped,
    noMarkdownEmphasis,
    noMarkdownLinks,
    supportedNodes,
  }) =>
  (tree) => {
    const disableEmphasis = isUndefined(isBarista)
      ? noMarkdownEmphasis
      : !isBarista;

    const disableLinks = isScoped
      ? false
      : isUndefined(isBarista)
      ? noMarkdownLinks
      : !isBarista;

    if (disableEmphasis) {
      supportedNodes = supportedNodes.filter((node) => node !== 'strong');
    }

    if (disableLinks) {
      supportedNodes = supportedNodes.filter((node) => node !== 'link');
    }

    const astNodeTypes = getPropertyRecursively(tree, 'type');
    const supportedNodeTypes =
      defaultSupportedNodesTypes.concat(supportedNodes);
    const typesToIgnore = astNodeTypes.filter(
      (type) => !supportedNodeTypes.includes(type)
    );
    visit(tree, typesToIgnore, visitor);
  };

export default allowList;
