import { useCallback, useContext, useEffect, useState } from 'react';

import { patchTenantConfig } from '../../globals/commonAPICalls';
import GlobalContext from '../../globals/GlobalContext';

const useUpdateHomeefeedDefaultSorting = () => {
  const {
    tenant: { tenantInfo },
  } = useContext(GlobalContext);

  const [sortType, setSortType] = useState('filter.none');

  useEffect(() => {
    setSortType(
      tenantInfo?.ui_config?.default_sorting?.default_sort_by || 'filter.none'
    );
  }, [tenantInfo?.ui_config?.default_sorting?.default_sort_by]);

  const handleToggleHomefeedSorting = useCallback(
    (e, eventData) => {
      const data = {
        ...tenantInfo,
        ui_config: {
          ...tenantInfo?.ui_config,
          default_sorting: {
            default_sort_by:
              eventData.value === 'filter.none' ? '' : eventData.value,
            homefeed: eventData.value !== 'filter.none',
          },
        },
      };

      patchTenantConfig(tenantInfo?.id, data).then(() => {
        setSortType(eventData.value);
        window.location.reload();
      });
    },
    [tenantInfo]
  );
  return { handleToggleHomefeedSorting, sortType };
};

export default useUpdateHomeefeedDefaultSorting;
