import api_assets_v0_1_asset_12 from './apiEndpoints/api_assets_v0_1_asset_12';
import api_assets_v0_1_asset_13 from './apiEndpoints/api_assets_v0_1_asset_13';
import api_authentication_v0_1_active_sessions from './apiEndpoints/api_authentication_v0_1_active_sessions';
import api_authentication_v0_1_auth_backend from './apiEndpoints/api_authentication_v0_1_auth_backend';
import api_authentication_v0_1_auth_backend_12 from './apiEndpoints/api_authentication_v0_1_auth_backend_12';
import api_authentication_v0_1_auth_login from './apiEndpoints/api_authentication_v0_1_auth_login';
import api_authentication_v0_1_auth_logout from './apiEndpoints/api_authentication_v0_1_auth_logout';
import api_authentication_v0_1_okta_tenant from './apiEndpoints/api_authentication_v0_1_okta_tenant';
import api_authentication_v0_1_okta_tenant_post from './apiEndpoints/api_authentication_v0_1_okta_tenant_post';
import api_barista_v0_1_application from './apiEndpoints/api_barista_v0_1_application';
import api_barista_v0_1_application_individual from './apiEndpoints/api_barista_v0_1_application_individual';
import api_barista_v0_1_conversation_get_support_channel from './apiEndpoints/api_barista_v0_1_conversation_get_support_channel';
import api_barista_v0_1_entities from './apiEndpoints/api_barista_v0_1_entities';
import api_barista_v0_1_faqs_general from './apiEndpoints/api_barista_v0_1_faqs_general';
import api_barista_v0_1_faqs_general_35eab19e_44c2_40af_9af1_e5b191af3c0f from './apiEndpoints/api_barista_v0_1_faqs_general_35eab19e_44c2_40af_9af1_e5b191af3c0f';
import api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966 from './apiEndpoints/api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966';
import api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966_additional_text from './apiEndpoints/api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966_additional_text';
import api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966_get_archetype_condition_elements from './apiEndpoints/api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966_get_archetype_condition_elements';
import api_barista_v0_1_faqs_general_db714f81 from './apiEndpoints/api_barista_v0_1_faqs_general_db714f81';
import api_barista_v0_1_faqs_general_db__answer_brief_no from './apiEndpoints/api_barista_v0_1_faqs_general_db__answer_brief_no';
import api_barista_v0_1_faqs_general_EID from './apiEndpoints/api_barista_v0_1_faqs_general_EID';
import api_barista_v0_1_faqs_general_faqs_and_templates_lookup from './apiEndpoints/api_barista_v0_1_faqs_general_faqs_and_templates_lookup';
import api_barista_v0_1_faqs_general_intent_lookup from './apiEndpoints/api_barista_v0_1_faqs_general_intent_lookup';
import api_barista_v0_1_intents from './apiEndpoints/api_barista_v0_1_intents';
import api_barista_v0_1_intents_32132_annotation from './apiEndpoints/api_barista_v0_1_intents_32132_annotation';
import api_barista_v0_1_intents_32132_annotation_c9d0743acbfd from './apiEndpoints/api_barista_v0_1_intents_32132_annotation_c9d0743acbfd';
import api_barista_v0_1_intents_75dc12b2d7fd575966_annotation from './apiEndpoints/api_barista_v0_1_intents_75dc12b2d7fd575966_annotation';
import api_barista_v0_1_intents_annotation_detail from './apiEndpoints/api_barista_v0_1_intents_annotation_detail';
import api_barista_v0_1_intents_response_asnwers_addFile_faq from './apiEndpoints/api_barista_v0_1_faqs_general_a59c9e3e';
import api_barista_v0_1_intents_responses_answers_addFile from './apiEndpoints/api_barista_v0_1_intents_db714f81-7870-4231-a321-4154919e71ff_responses_66c70912-3e99-45a0-88e0-7c3f3ad30b45_phrases';
import api_barista_v0_1_kb_articles_counters from './apiEndpoints/api_barista_v0_1_kb_articles_counters';
import api_barista_v0_1_kb_articles_get_kbs_added_updated_overtime from './apiEndpoints/api_barista_v0_1_kb_articles_get_kbs_added_updated_overtime';
import api_barista_v0_1_kb_articles_get_kbs_by_language from './apiEndpoints/api_barista_v0_1_kb_articles_get_kbs_by_language';
import api_barista_v0_1_kb_articles_get_kbs_by_source from './apiEndpoints/api_barista_v0_1_kb_articles_get_kbs_by_source';
import api_barista_v0_1_kb_articles_get_kbs_suggested_overtime from './apiEndpoints/api_barista_v0_1_kb_articles_get_kbs_suggested_overtime';
import api_barista_v0_1_kb_articles_get_least_20_conversation_areas from './apiEndpoints/api_barista_v0_1_kb_articles_get_least_20_conversation_areas';
import api_barista_v0_1_kb_articles_get_top_20_conversation_areas from './apiEndpoints/api_barista_v0_1_kb_articles_get_top_20_conversation_areas';
import api_barista_v0_1_kb_support_123456789_annotation from './apiEndpoints/api_barista_v0_1_kb_support_123456789_annotation';
import api_barista_v0_1_kb_support_get_kb_views from './apiEndpoints/api_barista_v0_1_kb_support_get_kb_views';
import api_barista_v0_1_kb_support_list_completed_kb from './apiEndpoints/api_barista_v0_1_kb_support_list_completed_kb';
import api_barista_v0_1_kb_support_origins from './apiEndpoints/api_barista_v0_1_kb_support_origins';
import api_barista_v0_1_report9_data_automation_stats from './apiEndpoints/api_barista_v0_1_report9_data_automation_stats';
import api_barista_v0_1_report9_data_counters from './apiEndpoints/api_barista_v0_1_report9_data_counters';
import api_barista_v0_1_report9_data_csv from './apiEndpoints/api_barista_v0_1_report9_data_csv';
import api_barista_v0_1_report9_data_get_automation_reports_by_status from './apiEndpoints/api_barista_v0_1_report9_data_get_automation_reports_by_status';
import api_barista_v0_1_report9_data_get_content_feedback from './apiEndpoints/api_barista_v0_1_report9_data_get_content_feedback';
import api_barista_v0_1_report9_data_get_deflected_data from './apiEndpoints/api_barista_v0_1_report9_data_get_deflected_data';
import api_barista_v0_1_report9_data_get_geo_distribution from './apiEndpoints/api_barista_v0_1_report9_data_get_geo_distribution';
import api_barista_v0_1_report9_data_get_interactions_by_channel from './apiEndpoints/api_barista_v0_1_report9_data_get_interactions_by_channel';
import api_barista_v0_1_report9_data_get_interactions_by_matched_archetype from './apiEndpoints/api_barista_v0_1_report9_data_get_interactions_by_matched_archetype';
import api_barista_v0_1_report9_data_get_interactions_by_os_client from './apiEndpoints/api_barista_v0_1_report9_data_get_interactions_by_os_client';
import api_barista_v0_1_report9_data_get_interactions_by_topic from './apiEndpoints/api_barista_v0_1_report9_data_get_interactions_by_topic';
import api_barista_v0_1_report9_data_get_top_intents_driving_automation from './apiEndpoints/api_barista_v0_1_report9_data_get_top_intents_driving_automation';
import api_barista_v0_1_report9_data_get_top_matched_intents from './apiEndpoints/api_barista_v0_1_report9_data_get_top_matched_intents';
import api_barista_v0_1_report9_data_get_top_matched_intents_driving_deflection from './apiEndpoints/api_barista_v0_1_report9_data_get_top_matched_intents_driving_deflection';
import api_barista_v0_1_report9_data_get_top_unanswered_questions from './apiEndpoints/api_barista_v0_1_report9_data_get_top_unanswered_questions';
import api_barista_v0_1_report9_data_get_top_unanswered_questions_by_keywords_group from './apiEndpoints/api_barista_v0_1_report9_data_get_top_unanswered_questions_by_keywords_group';
import api_barista_v0_1_report9_data_get_top_unanswered_questions_with_and_without_kb from './apiEndpoints/api_barista_v0_1_report9_data_get_top_unanswered_questions_with_and_without_kb';
import api_barista_v0_1_reports_get_report11 from './apiEndpoints/api_barista_v0_1_reports_get_report11';
import api_barista_v0_1_reports_get_report9 from './apiEndpoints/api_barista_v0_1_reports_get_report9';
import api_barista_v0_1_reports_report11 from './apiEndpoints/api_barista_v0_1_reports_report11';
import api_barista_v0_1_reports_report9_v2 from './apiEndpoints/api_barista_v0_1_reports_report9_v2';
import api_barista_v0_1_scheduled_elc_sync from './apiEndpoints/api_barista_v0_1_scheduled_elc_sync';
import api_baristabot_v0_1_add from './apiEndpoints/api_baristabot_v0_1_add';
import api_baristabot_v0_1_deeplink from './apiEndpoints/api_baristabot_v0_1_deeplink';
import api_baristabot_v0_1_genToken from './apiEndpoints/api_baristabot_v0_1_genToken';
import api_baristabot_v0_1_integrations_slack_channels from './apiEndpoints/api_baristabot_v0_1_integrations_slack_channels';
import api_baristabot_v0_1_remove from './apiEndpoints/api_baristabot_v0_1_remove';
import api_bootstrap_v0_1_start from './apiEndpoints/api_bootstrap_v0_1_start';
import api_cards_v0_1_card from './apiEndpoints/api_cards_v0_1_card';
import api_cards_v0_1_card_25 from './apiEndpoints/api_cards_v0_1_card_25';
import api_cards_v0_1_card_external_approval from './apiEndpoints/api_cards_v0_1_card_external_approval';
import api_case_mgmt_v0_1_conditions from './apiEndpoints/api_case_mgmt_v0_1_conditions';
import api_case_mgmt_v0_1_conditions_1 from './apiEndpoints/api_case_mgmt_v0_1_conditions_1';
import api_case_mgmt_v0_1_esp_service_department from './apiEndpoints/api_case_mgmt_v0_1_esp_service_department';
import api_case_mgmt_v0_1_service_department from './apiEndpoints/api_case_mgmt_v0_1_service_department';
import api_case_mgmt_v0_1_service_department_12 from './apiEndpoints/api_case_mgmt_v0_1_service_department_12';
import api_case_mgmt_v0_1_service_department_13 from './apiEndpoints/api_case_mgmt_v0_1_service_department_13';
import api_case_mgmt_v0_1_service_department_25_add_user_to_group from './apiEndpoints/api_case_mgmt_v0_1_service_department_25_add_user_to_group';
import api_case_mgmt_v0_1_service_department_3 from './apiEndpoints/api_case_mgmt_v0_1_service_department_3';
import api_case_mgmt_v0_1_service_department_4 from './apiEndpoints/api_case_mgmt_v0_1_service_department_4';
import api_case_mgmt_v0_1_service_department_post from './apiEndpoints/api_case_mgmt_v0_1_service_department_post';
import api_case_mgmt_v0_1_service_department_route from './apiEndpoints/api_case_mgmt_v0_1_service_department_route';
import api_case_mgmt_v0_1_service_team from './apiEndpoints/api_case_mgmt_v0_1_service_team';
import api_case_mgmt_v0_1_service_team_1 from './apiEndpoints/api_case_mgmt_v0_1_service_team_1';
import api_case_mgmt_v0_1_service_team_11_create_membership from './apiEndpoints/api_case_mgmt_v0_1_service_team_11_create_membership';
import api_case_mgmt_v0_1_service_team_11_send_invitation from './apiEndpoints/api_case_mgmt_v0_1_service_team_11_send_invitation';
import api_case_mgmt_v0_1_service_team_12 from './apiEndpoints/api_case_mgmt_v0_1_service_team_12';
import api_case_mgmt_v0_1_service_team_2 from './apiEndpoints/api_case_mgmt_v0_1_service_team_2';
import api_case_mgmt_v0_1_service_team_20 from './apiEndpoints/api_case_mgmt_v0_1_service_team_20';
import api_case_mgmt_v0_1_service_team_2_change_direct_linked_team_lead from './apiEndpoints/api_case_mgmt_v0_1_service_team_2_change_direct_linked_team_lead';
import api_case_mgmt_v0_1_service_team_2_send_invitation from './apiEndpoints/api_case_mgmt_v0_1_service_team_2_send_invitation';
import api_case_mgmt_v0_1_service_team_3 from './apiEndpoints/api_case_mgmt_v0_1_service_team_3';
import api_case_mgmt_v0_1_service_team_4 from './apiEndpoints/api_case_mgmt_v0_1_service_team_4';
import api_case_mgmt_v0_1_service_team_49_send_invitation from './apiEndpoints/api_case_mgmt_v0_1_service_team_49_send_invitation';
import api_case_mgmt_v0_1_service_team_4_remove_membership from './apiEndpoints/api_case_mgmt_v0_1_service_team_4_remove_membership';
import api_case_mgmt_v0_1_service_team_classification from './apiEndpoints/api_case_mgmt_v0_1_service_team_classification';
import api_case_mgmt_v0_1_service_team_default_team from './apiEndpoints/api_case_mgmt_v0_1_service_team_default_team';
import api_case_mgmt_v0_1_service_team_image from './apiEndpoints/api_case_mgmt_v0_1_service_team_image';
import api_case_mgmt_v0_1_service_team_my_teams from './apiEndpoints/api_case_mgmt_v0_1_service_team_my_teams';
import api_case_mgmt_v0_1_service_team_post from './apiEndpoints/api_case_mgmt_v0_1_service_team_post';
import api_case_mgmt_v0_1_service_team_query from './apiEndpoints/api_case_mgmt_v0_1_service_team_query';
import api_catalog_v0_1_bitem from './apiEndpoints/api_catalog_v0_1_bitem';
import api_catalog_v0_1_bitem_3 from './apiEndpoints/api_catalog_v0_1_bitem_3';
import api_catalog_v0_1_bitem_5 from './apiEndpoints/api_catalog_v0_1_bitem_5';
import api_catalog_v0_1_bundle from './apiEndpoints/api_catalog_v0_1_bundle';
import api_catalog_v0_1_bundle_1 from './apiEndpoints/api_catalog_v0_1_bundle_1';
import api_catalog_v0_1_bundle_5 from './apiEndpoints/api_catalog_v0_1_bundle_5';
import api_catalog_v0_1_cart from './apiEndpoints/api_catalog_v0_1_cart';
import api_catalog_v0_1_citem from './apiEndpoints/api_catalog_v0_1_citem';
import api_catalog_v0_1_dpci from './apiEndpoints/api_catalog_v0_1_dpci';
import api_catalog_v0_1_fulfillment_option from './apiEndpoints/api_catalog_v0_1_fulfillment_option';
import api_catalog_v0_1_pdpc_category_top from './apiEndpoints/api_catalog_v0_1_pdpc_category_top';
import api_catalog_v0_1_pdpc_product from './apiEndpoints/api_catalog_v0_1_pdpc_product';
import api_catalog_v0_1_pdpc_product_1 from './apiEndpoints/api_catalog_v0_1_pdpc_product_1';
import api_catalog_v0_1_product from './apiEndpoints/api_catalog_v0_1_product';
import api_catalog_v0_1_product_1 from './apiEndpoints/api_catalog_v0_1_product_1';
import api_catalog_v0_1_product_1_change_status from './apiEndpoints/api_catalog_v0_1_product_1_change_status';
import api_catalog_v0_1_product_999 from './apiEndpoints/api_catalog_v0_1_product_999';
import api_catalog_v0_1_product_image_20 from './apiEndpoints/api_catalog_v0_1_product_image_20';
import api_chat_v0_1_channels_0f63206f from './apiEndpoints/api_chat_v0_1_channels_0f63206f';
import api_chat_v0_1_channels_0f63206f_messages from './apiEndpoints/api_chat_v0_1_channels_0f63206f_messages';
import api_chat_v0_1_direct_channels_aaa_bbb_ccc from './apiEndpoints/api_chat_v0_1_direct_channels_aaa_bbb_ccc';
import api_chat_v0_1_direct_channels_with_users from './apiEndpoints/api_chat_v0_1_direct_channels_with_users';
import api_common_v0_1_job_status_1 from './apiEndpoints/api_common_v0_1_job_status_1';
import api_common_v0_1_job_status_1234_cancel from './apiEndpoints/api_common_v0_1_job_status_1234_cancel';
import api_common_v0_1_job_status_18_cancel from './apiEndpoints/api_common_v0_1_job_status_18_cancel';
import api_common_v0_1_localization from './apiEndpoints/api_common_v0_1_localization';
import api_common_v0_1_localization_40 from './apiEndpoints/api_common_v0_1_localization_40';
import api_common_v0_1_object_mapping from './apiEndpoints/api_common_v0_1_object_mapping';
import api_common_v0_1_supported_languages from './apiEndpoints/api_common_v0_1_supported_languages';
import api_config_v0_1_configuration from './apiEndpoints/api_config_v0_1_configuration';
import api_config_v0_1_configuration_barista_subprod_url from './apiEndpoints/api_config_v0_1_configuration_barista_subprod_url';
import api_config_v0_1_configuration_dashboard_first_deployment_time from './apiEndpoints/api_config_v0_1_configuration_dashboard_first_deployment_time';
import api_config_v0_1_configuration_dashboard_ui_config from './apiEndpoints/api_config_v0_1_configuration_dashboard_ui_config';
import api_config_v0_1_configuration_elc_enable_scheduled_sync from './apiEndpoints/api_config_v0_1_configuration_elc_enable_scheduled_sync';
import api_config_v0_1_configuration_notification_email_disable from './apiEndpoints/api_config_v0_1_configuration_notification_email_disable';
import api_config_v0_1_configuration_notification_sms_disable from './apiEndpoints/api_config_v0_1_configuration_notification_sms_disable';
import api_config_v0_1_configuration_remote_user_create_flag from './apiEndpoints/api_config_v0_1_configuration_remote_user_create_flag';
import api_csv_v0_1_remote_server_info from './apiEndpoints/api_csv_v0_1_remote_server_info';
import api_csv_v0_1_remote_server_info_1 from './apiEndpoints/api_csv_v0_1_remote_server_info_1';
import api_csv_v0_1_remote_server_info_test_connection from './apiEndpoints/api_csv_v0_1_remote_server_info_test_connection';
import api_elc from './apiEndpoints/api_elc';
import api_email_sensor_v0_1_case_generators from './apiEndpoints/api_email_sensor_v0_1_case_generators';
import api_email_sensor_v0_1_case_generators_1 from './apiEndpoints/api_email_sensor_v0_1_case_generators_1';
import api_email_sensor_v0_1_case_generators_2 from './apiEndpoints/api_email_sensor_v0_1_case_generators_2';
import api_email_sensor_v0_1_case_generators_666 from './apiEndpoints/api_email_sensor_v0_1_case_generators_666';
import api_emails_v0_1_credentials from './apiEndpoints/api_emails_v0_1_credentials';
import api_emails_v0_1_credentials_1 from './apiEndpoints/api_emails_v0_1_credentials_1';
import api_emails_v0_1_credentials_123 from './apiEndpoints/api_emails_v0_1_credentials_123';
import api_emails_v0_1_credentials_1_oauth_disconnect from './apiEndpoints/api_emails_v0_1_credentials_1_oauth_disconnect';
import api_espuser_v0_1_department from './apiEndpoints/api_espuser_v0_1_department';
import api_espuser_v0_1_job_role from './apiEndpoints/api_espuser_v0_1_job_role';
import api_espuser_v0_1_job_role_permissions from './apiEndpoints/api_espuser_v0_1_job_role_permissions';
import api_espuser_v0_1_users from './apiEndpoints/api_espuser_v0_1_users';
import api_espuser_v0_1_users_1 from './apiEndpoints/api_espuser_v0_1_users_1';
import api_espuser_v0_1_users_123 from './apiEndpoints/api_espuser_v0_1_users_123';
import api_espuser_v0_1_users_15 from './apiEndpoints/api_espuser_v0_1_users_15';
import api_espuser_v0_1_users_1_remove_assignment_from_group from './apiEndpoints/api_espuser_v0_1_users_1_remove_assignment_from_group';
import api_espuser_v0_1_users_218862 from './apiEndpoints/api_espuser_v0_1_users_218862';
import api_espuser_v0_1_users_2625 from './apiEndpoints/api_espuser_v0_1_users_2625';
import api_espuser_v0_1_users_2_take_assignment_for_group from './apiEndpoints/api_espuser_v0_1_users_2_take_assignment_for_group';
import api_espuser_v0_1_users_3_change_department from './apiEndpoints/api_espuser_v0_1_users_3_change_department';
import api_espuser_v0_1_users_3_get_contact_for_all_groups from './apiEndpoints/api_espuser_v0_1_users_3_get_contact_for_all_groups';
import api_espuser_v0_1_users_8_user_settings from './apiEndpoints/api_espuser_v0_1_users_8_user_settings';
import api_espuser_v0_1_users_95_activate_user from './apiEndpoints/api_espuser_v0_1_users_95_activate_user';
import api_espuser_v0_1_users_service_agents from './apiEndpoints/api_espuser_v0_1_users_service_agents';
import api_espuser_v0_1_users_user_count from './apiEndpoints/api_espuser_v0_1_users_user_count';
import api_espuser_v0_1_whoami from './apiEndpoints/api_espuser_v0_1_whoami';
import api_images_v0_1_tenant_images from './apiEndpoints/api_images_v0_1_tenant_images';
import api_integration_servicenow_v0_1_custom_search from './apiEndpoints/api_integration_servicenow_v0_1_custom_search';
import api_integration_servicenow_v0_1_custom_search_for_choices from './apiEndpoints/api_integration_servicenow_v0_1_custom_search_for_choices';
import api_integration_servicenow_v0_1_default_workflow_map from './apiEndpoints/api_integration_servicenow_v0_1_default_workflow_map';
import api_integration_servicenow_v0_1_proxy_get_key from './apiEndpoints/api_integration_servicenow_v0_1_proxy_get_key';
import api_integration_v0_1_confluence_kb_last_sync from './apiEndpoints/api_integration_v0_1_confluence_kb_last_sync';
import api_integration_v0_1_confluence_kb_run_sync from './apiEndpoints/api_integration_v0_1_confluence_kb_run_sync';
import api_integration_v0_1_confluence_kb_set from './apiEndpoints/api_integration_v0_1_confluence_kb_set';
import api_integration_v0_1_integrations_6ffd0f03_13ba_4d46_a7e8_fa92d2ef6b49_is_active from './apiEndpoints/api_integration_v0_1_integrations_6ffd0f03_13ba_4d46_a7e8_fa92d2ef6b49_is_active';
import api_integration_v0_1_integrations_confluence_set_credentials from './apiEndpoints/api_integration_v0_1_integrations_confluence_set_credentials';
import api_integration_v0_1_sharepoint_kb_set from './apiEndpoints/api_integration_v0_1_sharepoint_kb_set';
import api_notification_v0_1_notification from './apiEndpoints/api_notification_v0_1_notification';
import api_notification_v0_1_notification_1 from './apiEndpoints/api_notification_v0_1_notification_1';
import api_notification_v0_1_notification_111 from './apiEndpoints/api_notification_v0_1_notification_111';
import api_notification_v0_1_notification_21_recall from './apiEndpoints/api_notification_v0_1_notification_21_recall';
import api_notification_v0_1_notification_announcements_proocessing_time from './apiEndpoints/api_notification_v0_1_notification_announcements_proocessing_time';
import api_notification_v0_1_policy_features from './apiEndpoints/api_notification_v0_1_policy_features';
import api_notification_v0_1_support_email from './apiEndpoints/api_notification_v0_1_support_email';
import api_places_v0_1_locations from './apiEndpoints/api_places_v0_1_locations';
import api_rbac_v0_1_user_rule from './apiEndpoints/api_rbac_v0_1_user_rule';
import api_rbac_v0_1_entity_permissions from './apiEndpoints/api_rbac_v0_1_entity_permissions';
import api_rbac_v0_1_group from './apiEndpoints/api_rbac_v0_1_group';
import api_reporting_v0_1_templates_keyword_interactions_report from './api_reporting_v0_1_templates_keyword_interactions_report';
import api_reporting_v0_1_templates_surveys_report from './apiEndpoints/api_reporting_v0_1_templates_surveys_report';
import api_reporting_v0_1_templates_barista_case_report from './apiEndpoints/api_reporting_v0_1_templates_barista_case_report';
import api_reporting_v0_1_templates_top_intents_report from './apiEndpoints/api_reporting_v0_1_templates_top_intents_report';
import api_search_v0_1_typeahead_espuser_Espuser from './apiEndpoints/api_search_v0_1_typeahead_espuser_Espuser';
import api_surveys_v0_1_surveys from './apiEndpoints/api_surveys_v0_1_surveys';
import api_task_v0_1_categories from './apiEndpoints/api_task_v0_1_categories';
import api_task_v0_1_categories_4 from './apiEndpoints/api_task_v0_1_categories_4';
import api_task_v0_1_task_classification from './apiEndpoints/api_task_v0_1_task_classification';
import api_task_v0_1_task_sub_status from './apiEndpoints/api_task_v0_1_task_sub_status';
import api_task_v0_1_task_sub_status_1 from './apiEndpoints/api_task_v0_1_task_sub_status_1';
import api_task_v0_1_tasks from './apiEndpoints/api_task_v0_1_tasks';
import api_task_v0_1_tasks_1 from './apiEndpoints/api_task_v0_1_tasks_1';
import api_task_v0_1_tasks_111 from './apiEndpoints/api_task_v0_1_tasks_111';
import api_task_v0_1_tasks_111_set_approval_questions from './apiEndpoints/api_task_v0_1_tasks_111_set_approval_questions';
import api_task_v0_1_tasks_13928 from './apiEndpoints/api_task_v0_1_tasks_13928';
import api_task_v0_1_tasks_1_barista_proposal from './apiEndpoints/api_task_v0_1_tasks_1_barista_proposal';
import api_task_v0_1_tasks_1_change_owner from './apiEndpoints/api_task_v0_1_tasks_1_change_owner';
import api_task_v0_1_tasks_1_message_copy from './apiEndpoints/api_task_v0_1_tasks_1_message_copy';
import api_task_v0_1_tasks_1_related_responses from './apiEndpoints/api_task_v0_1_tasks_1_related_responses';
import api_task_v0_1_tasks_6 from './apiEndpoints/api_task_v0_1_tasks_6';
import api_task_v0_1_tasks_6_change_owner from './apiEndpoints/api_task_v0_1_tasks_6_change_owner';
import api_task_v0_1_tasks_6_change_status from './apiEndpoints/api_task_v0_1_tasks_6_change_status';
import api_task_v0_1_tasks_approval from './apiEndpoints/api_task_v0_1_tasks_approval';
import api_tenant_files_v0_1 from './apiEndpoints/api_tenant_files_v0_1';
import api_tenants_v0_1_tenant_3 from './apiEndpoints/api_tenants_v0_1_tenant_3';
import api_todo_v0_1_progress from './apiEndpoints/api_todo_v0_1_progress';
import api_todo_v0_1_progress_escalate from './apiEndpoints/api_todo_v0_1_progress_escalate';
import api_todo_v0_1_progress_summary from './apiEndpoints/api_todo_v0_1_progress_summary';
import api_todo_v0_1_progress_summary_15_change_status from './apiEndpoints/api_todo_v0_1_progress_summary_15_change_status';
import api_todo_v0_1_template from './apiEndpoints/api_todo_v0_1_template';
import api_todo_v0_1_schedule from './apiEndpoints/api_todo_v0_1_schedule';
import api_todo_v0_1_template_1 from './apiEndpoints/api_todo_v0_1_template_1';
import api_todo_v0_1_template_1_change_status from './apiEndpoints/api_todo_v0_1_template_1_change_status';
import api_workflow_v0_1_condition from './apiEndpoints/api_workflow_v0_1_condition';
import api_workflow_v0_1_condition_147 from './apiEndpoints/api_workflow_v0_1_condition_147';
import api_workflow_v0_1_object_mapping from './apiEndpoints/api_workflow_v0_1_object_mapping';
import api_workflow_v0_1_workflow_38 from './apiEndpoints/api_workflow_v0_1_workflow_38';
import api_workflow_v0_1_workflow_39 from './apiEndpoints/api_workflow_v0_1_workflow_39';
import api_workflow_v0_1_workflowconditionset from './apiEndpoints/api_workflow_v0_1_workflowconditionset';
import api_workflow_v0_1_workflowrequest_34 from './apiEndpoints/api_workflow_v0_1_workflowrequest_34';
import api_workflow_v0_1_workflowrequest_35 from './apiEndpoints/api_workflow_v0_1_workflowrequest_35';
import api_workflow_v0_1_workflowtask from './apiEndpoints/api_workflow_v0_1_workflowtask';
import api_workflow_v0_1_workflowtask_173 from './apiEndpoints/api_workflow_v0_1_workflowtask_173';
import api_workflow_v0_1_workflowtask_666 from './apiEndpoints/api_workflow_v0_1_workflowtask_666';
import test_test from './apiEndpoints/test_test';
import api_groups_v0_1_groups from './apiEndpoints/api_groups_v0_1_groups';
import api_barista_v0_1_report9_data_get_interactions_by_source from './apiEndpoints/api_barista_v0_1_report9_data_get_interactions_by_source';
import api_places_v0_1_locations_54681 from './apiEndpoints/api_places_v0_1_locations_54681';
import api_places_v0_1_locations_54715 from './apiEndpoints/api_places_v0_1_locations_54715';
import api_espuser_v0_1_users_8 from './apiEndpoints/api_espuser_v0_1_users_8';
import api_config_v0_1_configuration_branding_allowed_extensions from './apiEndpoints/api_config_v0_1_configuration_branding_allowed_extensions';

const apiMocks = {
  api_assets_v0_1_asset_12,
  api_assets_v0_1_asset_13,
  api_authentication_v0_1_active_sessions,
  api_authentication_v0_1_auth_backend,
  api_authentication_v0_1_auth_backend_12,
  api_authentication_v0_1_auth_login,
  api_authentication_v0_1_auth_logout,
  api_authentication_v0_1_okta_tenant,
  api_authentication_v0_1_okta_tenant_post,
  api_barista_v0_1_application,
  'api_barista_v0_1_application_6a9f0e6b-5402-4f81-8551-0f8dfba2a174':
    api_barista_v0_1_application_individual,
  'api_barista_v0_1_application_6d3ed365-16a7-43a1-8012-cfa66b885250':
    api_barista_v0_1_application_individual,
  api_barista_v0_1_conversation_get_support_channel,
  api_barista_v0_1_entities,
  api_barista_v0_1_faqs_general,

  'api_barista_v0_1_faqs_general_04e9e7de-6415-48db-9685-cbe1eb137710':
    api_barista_v0_1_faqs_general_EID,
  'api_barista_v0_1_faqs_general_04e9e7de-6415-48db-9685-cbe1eb137710_answers':
    api_barista_v0_1_faqs_general_EID,
  'api_barista_v0_1_faqs_general_04e9e7de-6415-48db-9685-cbe1eb137710_answers_04e9e7de-6415-48db-9685-cbe1eb137710':
    api_barista_v0_1_faqs_general_EID,
  'api_barista_v0_1_faqs_general_04e9e7de-6415-48db-9685-cbe1eb137710_answers_1b585a90-06fa-48dc-b1d6-c941267a1873':
    api_barista_v0_1_faqs_general_EID,
  'api_barista_v0_1_faqs_general_04e9e7de-6415-48db-9685-cbe1eb137710_answers_4219fa40-b915-420a-8c4b-7488dfee4443':
    api_barista_v0_1_faqs_general_EID,
  'api_barista_v0_1_faqs_general_35eab19e-44c2-40af-9af1-e5b191af3c0f':
    api_barista_v0_1_faqs_general_35eab19e_44c2_40af_9af1_e5b191af3c0f,
  api_barista_v0_1_faqs_general_75dc12b2d7fd575966:
    api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966,
  api_barista_v0_1_faqs_general_75dc12b2d7fd575966_additional_text:
    api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966_additional_text,
  'api_barista_v0_1_faqs_general_75dc12b2d7fd575966_answers_492a1dc1-cca8-46da-9689-92244f2ee0ec':
    api_barista_v0_1_faqs_general_EID,
  api_barista_v0_1_faqs_general_75dc12b2d7fd575966_get_archetype_condition_elements:
    api_barista_v0_1_faqs_general_75dc12bc_1fe7_48c7_ab81_72d7fd575966_get_archetype_condition_elements,
  'api_barista_v0_1_faqs_general_a59c9e3e-7e96-46ed-8d72-a50afa7ad87c_answers_99602f66-87e9-413a-a9a8-e189187cdea8_lines_1f2ffc46-8a36-4bad-968a-f349480815c0':
    api_barista_v0_1_intents_response_asnwers_addFile_faq,
  'api_barista_v0_1_faqs_general_db714f81-7870-4231-a321-4154919e71ff':
    api_barista_v0_1_faqs_general_db714f81,
  'api_barista_v0_1_faqs_general_db714f81-7870-4231-a321-4154919e71ff_?answer_brief=false':
    api_barista_v0_1_faqs_general_db__answer_brief_no,
  api_barista_v0_1_faqs_general_faqs_and_templates_lookup,
  api_barista_v0_1_faqs_general_intent_lookup,
  api_barista_v0_1_intents,
  api_barista_v0_1_intents_32132_annotation,
  api_barista_v0_1_intents_32132_annotation_c9d0743acbfd,
  api_barista_v0_1_intents_75dc12b2d7fd575966_annotation,
  api_barista_v0_1_intents_annotation_detail,
  'api_barista_v0_1_intents_db714f81-7870-4231-a321-4154919e71ff_responses_66c70912-3e99-45a0-88e0-7c3f3ad30b45_phrases':
    api_barista_v0_1_intents_responses_answers_addFile,
  api_barista_v0_1_kb_articles_counters,
  api_barista_v0_1_kb_articles_get_kbs_added_updated_overtime,
  api_barista_v0_1_kb_articles_get_kbs_by_language,
  api_barista_v0_1_kb_articles_get_kbs_by_source,
  api_barista_v0_1_kb_articles_get_kbs_suggested_overtime,
  api_barista_v0_1_kb_articles_get_least_20_conversation_areas,
  api_barista_v0_1_kb_articles_get_top_20_conversation_areas,
  api_barista_v0_1_kb_support_123456789_annotation,
  api_barista_v0_1_kb_support_get_kb_views,
  api_barista_v0_1_kb_support_list_completed_kb,
  api_barista_v0_1_kb_support_origins,
  api_barista_v0_1_report9_data_automation_stats,
  api_barista_v0_1_report9_data_counters,
  api_barista_v0_1_report9_data_csv,
  api_barista_v0_1_report9_data_get_automation_reports_by_status,
  api_barista_v0_1_report9_data_get_content_feedback,
  api_barista_v0_1_report9_data_get_deflected_data,
  api_barista_v0_1_report9_data_get_geo_distribution,
  api_barista_v0_1_report9_data_get_interactions_by_channel,
  api_barista_v0_1_report9_data_get_interactions_by_matched_archetype,
  api_barista_v0_1_report9_data_get_interactions_by_os_client,
  api_barista_v0_1_report9_data_get_interactions_by_source,
  api_barista_v0_1_report9_data_get_interactions_by_topic,
  api_barista_v0_1_report9_data_get_top_intents_driving_automation,
  api_barista_v0_1_report9_data_get_top_matched_intents,
  api_barista_v0_1_report9_data_get_top_matched_intents_driving_deflection,
  api_barista_v0_1_report9_data_get_top_unanswered_questions,
  api_barista_v0_1_report9_data_get_top_unanswered_questions_by_keywords_group,
  api_barista_v0_1_report9_data_get_top_unanswered_questions_with_and_without_kb,
  api_barista_v0_1_reports_get_report11,
  api_barista_v0_1_reports_get_report9,

  api_barista_v0_1_reports_report11,

  api_barista_v0_1_reports_report9_v2,
  api_barista_v0_1_scheduled_elc_sync,
  api_baristabot_v0_1_add,
  api_baristabot_v0_1_deeplink,
  api_baristabot_v0_1_genToken,
  api_baristabot_v0_1_integrations_slack_channels,
  api_baristabot_v0_1_remove,
  api_bootstrap_v0_1_start,
  api_cards_v0_1_card,
  api_cards_v0_1_card_25,
  'api_cards_v0_1_card_?esp_filters=object_id__EQ%3D111':
    api_cards_v0_1_card_external_approval,
  'api_cards_v0_1_card_?esp_filters=object_id__EQ%3D13931':
    api_cards_v0_1_card_external_approval,
  api_case_mgmt_v0_1_conditions,
  api_case_mgmt_v0_1_conditions_1,
  'api_case_mgmt_v0_1_conditions_?order_by=order_of_execution':
    api_case_mgmt_v0_1_conditions,
  api_case_mgmt_v0_1_esp_service_department,
  api_case_mgmt_v0_1_service_department,
  api_case_mgmt_v0_1_service_department_12,
  api_case_mgmt_v0_1_service_department_13,
  api_case_mgmt_v0_1_service_department_25_add_user_to_group,

  api_case_mgmt_v0_1_service_department_3,

  api_case_mgmt_v0_1_service_department_4:
    api_case_mgmt_v0_1_service_department_4,
  api_case_mgmt_v0_1_service_department_post,
  api_case_mgmt_v0_1_service_department_route,
  api_case_mgmt_v0_1_service_team,

  api_case_mgmt_v0_1_service_team_1,
  api_case_mgmt_v0_1_service_team_11_create_membership,

  api_case_mgmt_v0_1_service_team_11_send_invitation,
  api_case_mgmt_v0_1_service_team_12,
  api_case_mgmt_v0_1_service_team_2,

  api_case_mgmt_v0_1_service_team_20: api_case_mgmt_v0_1_service_team_20,

  api_case_mgmt_v0_1_service_team_2_change_direct_linked_team_lead,

  api_case_mgmt_v0_1_service_team_2_send_invitation,

  api_case_mgmt_v0_1_service_team_3,

  api_case_mgmt_v0_1_service_team_4,

  api_case_mgmt_v0_1_service_team_49_send_invitation,

  api_case_mgmt_v0_1_service_team_4_remove_membership,

  api_case_mgmt_v0_1_service_team_classification,
  api_case_mgmt_v0_1_service_team_default_team,
  api_case_mgmt_v0_1_service_team_image,
  api_case_mgmt_v0_1_service_team_my_teams,
  api_case_mgmt_v0_1_service_team_post,
  api_case_mgmt_v0_1_service_team_query,
  api_catalog_v0_1_bitem,
  api_catalog_v0_1_bitem_3,
  api_catalog_v0_1_bitem_5,
  api_catalog_v0_1_bundle,
  api_catalog_v0_1_bundle_1,
  api_catalog_v0_1_bundle_5,
  api_catalog_v0_1_cart,
  api_catalog_v0_1_citem,
  api_catalog_v0_1_dpci,
  api_catalog_v0_1_fulfillment_option,
  api_catalog_v0_1_pdpc_category_top,
  api_catalog_v0_1_pdpc_product,
  api_catalog_v0_1_pdpc_product_1,
  api_catalog_v0_1_product,
  api_catalog_v0_1_product_1,
  api_catalog_v0_1_product_1_change_status,
  api_catalog_v0_1_product_999,
  api_catalog_v0_1_product_image_20,

  'api_chat_v0_1_channels_0f63206f-0f27-4e38-a229-d67bee02dbc6':
    api_chat_v0_1_channels_0f63206f,
  'api_chat_v0_1_channels_0f63206f-0f27-4e38-a229-d67bee02dbc6_messages':
    api_chat_v0_1_channels_0f63206f_messages,
  api_chat_v0_1_direct_channels_aaa_bbb_ccc,
  api_chat_v0_1_direct_channels_with_users,

  api_common_v0_1_job_status_1,
  api_common_v0_1_job_status_1234_cancel,
  api_common_v0_1_job_status_18_cancel,
  'api_common_v0_1_job_status_?offset=0&limit=1&order_by=-id&only_user_jobs=false&esp_filters=api_path__IC=_elc':
    api_elc,

  api_common_v0_1_localization,
  api_common_v0_1_localization_40,
  api_common_v0_1_object_mapping,
  api_common_v0_1_supported_languages,

  api_config_v0_1_configuration,
  api_config_v0_1_configuration_barista_subprod_url,
  api_config_v0_1_configuration_branding_allowed_extensions,
  api_config_v0_1_configuration_dashboard_first_deployment_time,
  api_config_v0_1_configuration_dashboard_ui_config,
  api_config_v0_1_configuration_elc_enable_scheduled_sync,

  api_config_v0_1_configuration_notification_email_disable,
  api_config_v0_1_configuration_notification_sms_disable,

  api_config_v0_1_configuration_remote_user_create_flag,

  api_csv_v0_1_remote_server_info,
  api_csv_v0_1_remote_server_info_1,
  api_csv_v0_1_remote_server_info_test_connection,
  api_email_sensor_v0_1_case_generators,
  api_email_sensor_v0_1_case_generators_1,
  api_email_sensor_v0_1_case_generators_2,
  api_email_sensor_v0_1_case_generators_666,
  api_emails_v0_1_credentials,
  api_emails_v0_1_credentials_1,
  api_emails_v0_1_credentials_123,
  api_emails_v0_1_credentials_1_oauth_disconnect,
  api_espuser_v0_1_department,
  api_espuser_v0_1_job_role,
  api_espuser_v0_1_job_role_permissions: api_espuser_v0_1_job_role_permissions,

  api_espuser_v0_1_users,

  api_espuser_v0_1_users_1,
  api_espuser_v0_1_users_123,

  api_espuser_v0_1_users_15,

  api_espuser_v0_1_users_1_remove_assignment_from_group,

  api_espuser_v0_1_users_218862,
  api_espuser_v0_1_users_219070: api_espuser_v0_1_users_218862,

  api_espuser_v0_1_users_2625,

  api_espuser_v0_1_users_2_take_assignment_for_group,

  api_espuser_v0_1_users_3_change_department,

  api_espuser_v0_1_users_3_get_contact_for_all_groups,

  api_espuser_v0_1_users_753: api_espuser_v0_1_users_218862,
  api_espuser_v0_1_users_8,

  api_espuser_v0_1_users_8_get_contact_for_all_groups:
    api_espuser_v0_1_users_3_get_contact_for_all_groups,

  api_espuser_v0_1_users_8_user_settings,

  api_espuser_v0_1_users_95_activate_user,

  api_espuser_v0_1_users_service_agents,

  api_espuser_v0_1_users_user_count,
  api_espuser_v0_1_whoami,
  api_groups_v0_1_groups: api_groups_v0_1_groups,
  api_images_v0_1_tenant_images,
  api_integration_servicenow_v0_1_custom_search,
  api_integration_servicenow_v0_1_custom_search_for_choices,
  api_integration_servicenow_v0_1_default_workflow_map,
  api_integration_servicenow_v0_1_proxy_get_key,
  api_integration_v0_1_confluence_kb_last_sync,
  api_integration_v0_1_confluence_kb_run_sync,
  api_integration_v0_1_confluence_kb_set,
  'api_integration_v0_1_integrations_6ffd0f03-13ba-4d46-a7e8-fa92d2ef6b49_is_active':
    api_integration_v0_1_integrations_6ffd0f03_13ba_4d46_a7e8_fa92d2ef6b49_is_active,
  'api_integration_v0_1_integrations_6ffd0f03-13ba-4d46-a7e8-fa92d2ef6b49_set_credential':
    api_integration_v0_1_integrations_confluence_set_credentials,
  'api_integration_v0_1_integrations_f6ff385e-da8d-42d8-a302-349895a9438f_is_active':
    api_integration_v0_1_integrations_6ffd0f03_13ba_4d46_a7e8_fa92d2ef6b49_is_active,
  'api_integration_v0_1_integrations_f6ff385e-da8d-42d8-a302-349895a9438f_set_credential':
    api_integration_v0_1_integrations_confluence_set_credentials,
  api_integration_v0_1_sharepoint_kb_set,

  api_notification_v0_1_notification,
  api_notification_v0_1_notification_1,
  api_notification_v0_1_notification_111,

  api_notification_v0_1_notification_21_recall,
  api_notification_v0_1_notification_announcements_proocessing_time,
  api_notification_v0_1_policy_features,
  api_notification_v0_1_support_email,
  api_places_v0_1_locations,
  api_places_v0_1_locations_54681,
  api_places_v0_1_locations_54715,
  api_rbac_v0_1_entity_permissions,
  'api_rbac_v0_1_entity_permissions_?rule=undefined&entity_model=espbundle&entity_app_label=catalog&entity_filter=%7B%22id%22:1234%7D':
    api_rbac_v0_1_entity_permissions,
  api_rbac_v0_1_group,
  api_rbac_v0_1_user_rule,
  api_reporting_v0_1_templates_barista_case_report,
  api_reporting_v0_1_templates_keyword_interactions_report,
  api_reporting_v0_1_templates_surveys_report,
  api_reporting_v0_1_templates_top_intents_report,
  api_search_v0_1_typeahead_espuser_Espuser,
  api_surveys_v0_1_surveys,
  api_task_v0_1_categories,
  api_task_v0_1_categories_4,
  api_task_v0_1_task_classification,
  api_task_v0_1_task_sub_status,
  api_task_v0_1_task_sub_status_1,

  api_task_v0_1_tasks,

  api_task_v0_1_tasks_1,
  api_task_v0_1_tasks_111,

  api_task_v0_1_tasks_111_set_approval_questions,
  api_task_v0_1_tasks_13928,

  api_task_v0_1_tasks_13931: api_task_v0_1_tasks_approval,

  api_task_v0_1_tasks_1_barista_proposal,

  api_task_v0_1_tasks_1_change_owner,
  api_task_v0_1_tasks_1_message_copy,
  api_task_v0_1_tasks_1_related_responses,
  api_task_v0_1_tasks_6,

  api_task_v0_1_tasks_6_change_owner,

  api_task_v0_1_tasks_6_change_status,

  'api_task_v0_1_tasks_?esp_filters=service_team__EQ%3Dnull%26status_status__IN%3Dopen%26owner__ISNULL%3DTrue%26ORowner__EQ%3Dnull&limit=10':
    api_task_v0_1_tasks,
  'api_task_v0_1_tasks_?esp_filters=type__EQ%3Dapproval_manager%26status_status__IN%3Dpending_approval&show_pending_approval=true&order_by=-sys_date_created&limit=10&type__EQ=approval':
    api_task_v0_1_tasks,
  'api_task_v0_1_tasks_?show_purchase_reqs&esp_filters=service_team__EQ%3Dnull%26status_status__IN%3Dopen%26owner__ISNULL%3DTrue%26ORowner__EQ%3Dnull&limit=10&type__!EQ=approval':
    api_task_v0_1_tasks,

  'api_task_v0_1_tasks_?show_purchase_reqs&show_related_barista_responses=true&esp_filters=service_team__EQ%3Dnull%26status_status__IN%3Dopen%26owner__ISNULL%3DTrue%26ORowner__EQ%3Dnull&limit=10&type__!EQ=approval':
    api_task_v0_1_tasks,

  'api_task_v0_1_tasks_?show_purchase_reqs&show_related_barista_responses=true&esp_filters=service_team__EQ%3Dnull%26status_status__IN%3Dopen%26sub_status_sub_status__ISNULL%3DTrue&limit=10&type__!EQ=approval':
    api_task_v0_1_tasks,

  'api_task_v0_1_tasks_?show_purchase_reqs&show_related_barista_responses=true&esp_filters=service_team__EQ%3Dnull%26status_status__IN%3Dopen&limit=10&type__!EQ=approval':
    api_task_v0_1_tasks,
  'api_task_v0_1_tasks_?show_purchase_reqs&show_related_barista_responses=true&limit=10&esp_filters=service_team__EQ%3Dnull%26type__!IN%3Dapproval%2Cservice_request%26status_status__IN%3Dopen&order_by=-sys_date_updated':
    api_task_v0_1_tasks,
  'api_task_v0_1_tasks_?show_purchase_reqs&show_related_barista_responses=true&limit=10&esp_filters=service_team__EQ%3Dnull%26type__!IN%3Dapproval%2Cservice_request%26status_status__IN%3Dopen&order_by=sys_date_updated':
    api_task_v0_1_tasks,
  'api_task_v0_1_tasks_?show_purchase_reqs&show_related_barista_responses=true&type__!EQ=approval&limit=10&esp_filters=service_team__EQ%3Dnull%26status_status__IN%3Dopen&order_by=-sys_date_updated':
    api_task_v0_1_tasks,
  api_tenant_files_v0_1,
  api_tenants_v0_1_tenant_3,
  api_todo_v0_1_progress,
  api_todo_v0_1_progress_escalate,
  api_todo_v0_1_progress_summary,
  api_todo_v0_1_progress_summary_15_change_status,
  api_todo_v0_1_schedule,
  api_todo_v0_1_template,
  api_todo_v0_1_template_1,
  api_todo_v0_1_template_1_change_status,
  api_workflow_v0_1_condition,
  api_workflow_v0_1_condition_147,
  api_workflow_v0_1_object_mapping,
  api_workflow_v0_1_workflow_38,
  api_workflow_v0_1_workflow_39,
  api_workflow_v0_1_workflowconditionset,
  api_workflow_v0_1_workflowrequest_34,
  api_workflow_v0_1_workflowrequest_35,
  api_workflow_v0_1_workflowtask,
  api_workflow_v0_1_workflowtask_173,
  api_workflow_v0_1_workflowtask_666,
  'https:__release_qa_espressive_com_api_workflow_v0_1_workflowtask_173':
    api_workflow_v0_1_workflowtask_173,
  test_test,
};

export default apiMocks;
