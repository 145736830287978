import React, { Component } from 'react';
import { curry, memoize, omit } from 'lodash';
import { intl } from 'esp-util-intl';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { Boundaries } from 'cascara-middleware';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { FormikWithSemanticUI } from 'esp-ui-form';
import PropTypes from 'prop-types';
import GetDefaultPlaceholderByInputType from '../../../../globals/GetDefaultPlaceholderByInputType';
import controller from './CSVCasesReportModalFormController';
import { INPUT_DATE_FORMAT } from '../../../../globals/DefaultInputFormats';

const MAX_DURATION = 400;
const END_DATE_MIN_LIMIT = moment().format(INPUT_DATE_FORMAT);
const START_DATE_MAX_LIMIT = moment().format(INPUT_DATE_FORMAT);
const DEFAULT_OPTION_SERVICE_TEAM = 'all_service_team';

class CSVCasesReportModalForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    downloadReport: PropTypes.func.isRequired,
    downloadURL: PropTypes.string,
    isLoading: PropTypes.bool,
    isValid: PropTypes.bool.isRequired,
    message: PropTypes.string,
    myTeams: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    resetDownloadURL: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    title: PropTypes.string,
    values: PropTypes.shape({
      end_date: PropTypes.string,
      service_team: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      show_custom_case_fields: PropTypes.bool,
      show_discarded_cases: PropTypes.bool,
      show_order_items: PropTypes.bool,
      start_date: PropTypes.string,
    }),
  };

  static defaultProps = {
    downloadURL: '',
    isLoading: false,
    message: '',
    myTeams: List(),
    title: '',
    values: {},
  };

  state = {
    isDateValid: false,
  };

  handleOnChangeDate = memoize(
    curry((type, e) => {
      let duration = 0;
      const { setValues, values, resetDownloadURL } = this.props;
      const { end_date, start_date } = values;

      resetDownloadURL();

      this.setState({
        isDateValid: moment(e.target.value, INPUT_DATE_FORMAT, true).isValid(),
      });

      if (type === 'start') {
        if (end_date) {
          duration = moment
            .duration(moment(end_date).diff(moment(e.target.value)))
            .as('days');
        }
        if (duration >= 0) {
          if (duration >= MAX_DURATION) {
            setValues({
              ...values,
              end_date: moment(e.target.value)
                .add(MAX_DURATION, 'days')
                .format(INPUT_DATE_FORMAT),
              start_date: e.target.value,
            });
          } else {
            setValues({
              ...values,
              start_date: e.target.value,
            });
          }
        }
      } else {
        duration = moment
          .duration(moment(e.target.value).diff(moment(start_date)))
          .as('days');
        if (duration >= 0 || !start_date) {
          if (duration > MAX_DURATION) {
            setValues({
              ...values,
              end_date: e.target.value,
              start_date: moment(e.target.value)
                .subtract(MAX_DURATION, 'days')
                .format(INPUT_DATE_FORMAT),
            });
          } else {
            setValues({
              ...values,
              end_date: e.target.value,
            });
          }
        } else {
          setValues({
            ...values,
            end_date: e.target.value,
          });
        }
      }
    })
  );

  handleCheckboxChange = (e, { name, checked }) => {
    const { setFieldValue, resetDownloadURL } = this.props;

    resetDownloadURL();
    setFieldValue(name, checked);
  };

  handleDownload = () => {
    const { values, downloadReport } = this.props;
    const formValues = {
      ...values,
      end_date: moment(values.end_date).add(1, 'day').format(INPUT_DATE_FORMAT),
    };

    if (values.service_team === DEFAULT_OPTION_SERVICE_TEAM) {
      downloadReport(omit(formValues, 'service_team'));
    } else {
      downloadReport(formValues);
    }
  };

  handleServiceTeamChange = (event, { value }) => {
    const { resetDownloadURL, setValues, values } = this.props;
    resetDownloadURL();
    setValues({ ...values, service_team: value });
  };

  render() {
    const {
      dirty: isDirty,
      downloadURL,
      isLoading,
      isValid,
      message,
      myTeams,
      title,
      values,
    } = this.props;

    const { isDateValid } = this.state;

    const modalFormButtonLabel = isLoading
      ? intl.formatMessage({ id: 'label.generating_report' })
      : intl.formatMessage({ id: 'label.generate_report' });

    const surveyOptions = [
      {
        text: intl.formatMessage({ id: 'label.all_service_teams' }),
        value: DEFAULT_OPTION_SERVICE_TEAM,
      },
    ];

    const downloadLabel = intl.formatMessage({ id: 'label.download' });

    const noDataText = 'no data is found';
    const shouldDisplayMessage = message.includes(noDataText) && !isLoading;

    myTeams.forEach((team) =>
      surveyOptions.push({
        text: team.get('name'),
        value: team.get('id'),
      })
    );

    return (
      <Boundaries>
        <>
          <Modal.Header
            style={{
              borderTopLeftRadius: '.75em',
              borderTopRightRadius: '.75em',
            }}
          >
            {title}
          </Modal.Header>
          <Modal.Content>
            <Message
              content={intl.formatMessage({ id: 'label.not_data_found' })}
              visible={shouldDisplayMessage}
              warning
            />
            <Form.Dropdown
              label={intl.formatMessage({ id: 'label.service_team' })}
              name='service_team'
              onChange={this.handleServiceTeamChange}
              options={surveyOptions}
              required
              selection
              value={values.service_team}
            />
            <Form.Group widths='equal'>
              <Form.Input
                label={intl.formatMessage({ id: 'label.start_date' })}
                max={START_DATE_MAX_LIMIT}
                name='start_date'
                onChange={this.handleOnChangeDate('start')}
                placeholder={
                  values.start_date
                    ? values.start_date
                    : GetDefaultPlaceholderByInputType('date')
                }
                required
                type='date'
                value={values.start_date}
              />
              <Form.Input
                label={intl.formatMessage({ id: 'label.end_date' })}
                max={END_DATE_MIN_LIMIT}
                min={values.start_date}
                name='end_date'
                onChange={this.handleOnChangeDate('end')}
                placeholder={GetDefaultPlaceholderByInputType('date')}
                required
                type='date'
                value={values.end_date}
              />
            </Form.Group>
            <h4>{intl.formatMessage({ id: 'label.export_options' })}</h4>
            <Form.Checkbox
              checked={values.show_order_items}
              label={intl.formatMessage({
                id: 'label.separate_purchase_request_items',
              })}
              name='show_order_items'
              onChange={this.handleCheckboxChange}
            />
            <Form.Checkbox
              checked={values.show_discarded_cases}
              label={intl.formatMessage({
                id: 'label.display_discarded_cases',
              })}
              name='show_discarded_cases'
              onChange={this.handleCheckboxChange}
            />

            <Form.Checkbox
              checked={values.show_custom_case_fields}
              label={intl.formatMessage({
                id: 'label.display_custom_case_fields',
              })}
              name='show_custom_case_fields'
              onChange={this.handleCheckboxChange}
            />
          </Modal.Content>
          <Modal.Actions>
            {
              <Button
                content={modalFormButtonLabel}
                disabled={!isDirty || !isValid || isLoading || !isDateValid}
                id='generate_button'
                loading={isLoading}
                onClick={this.handleDownload}
                positive
              />
            }
            {downloadURL && !isLoading && (
              <Button
                as='a'
                basic
                content={downloadLabel}
                disabled={isLoading}
                href={downloadURL}
                id='download_button'
                loading={isLoading}
              />
            )}
          </Modal.Actions>
        </>
      </Boundaries>
    );
  }
}

export const CSVCasesReportModalFormTest = CSVCasesReportModalForm;

export default controller(
  FormikWithSemanticUI({
    enableReinitialize: true,
    mapPropsToValues: ({ values }) => {
      const getValue = (key, defaultValue) =>
        (values &&
          Object.prototype.hasOwnProperty.call(values, key) &&
          values[key]) ||
        defaultValue;
      return {
        end_date: getValue('end_date', ''),
        service_team: getValue('service_team', DEFAULT_OPTION_SERVICE_TEAM),
        show_custom_case_fields: getValue('show_custom_case_fields', false),
        show_discarded_cases: getValue('show_discarded_cases', false),
        show_order_items: getValue('show_order_items', false),
        start_date: getValue('start_date', ''),
      };
    },
    validate: (values) => {
      const errors = {};

      if (!values.end_date) {
        errors.end_date = true;
      }

      if (!values.start_date) {
        errors.start_date = true;
      }

      return errors;
    },
  })(CSVCasesReportModalForm)
);
