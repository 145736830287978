import dateFormat from './dateFormat';

const isUTCDateFormat =
  /[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{0,9}[+|-][0-9]{0,2}:[0-9]{0,2}/gim;

/**
 * Replaces {{label/entity}}
 * @param {string} text - The text that should contain {{key}}
 * @return {string} string with localized dates
 */
const replaceDateWithLocalDateFormat = (text) =>
  text.replace(isUTCDateFormat, (match) => dateFormat(match));

export default replaceDateWithLocalDateFormat;
