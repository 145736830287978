import React, { PureComponent } from 'react';

import { Editor } from 'slate-react';
import { Block, Value } from 'slate';

import styles from './Slate.module.css';

const schema = {
  blocks: {
    image: {
      isVoid: true,
    },
  },
  document: {
    last: {
      type: 'paragraph',
    },
    normalize: (editor, { code, node }) => {
      switch (code) {
        case 'last_child_type_invalid': {
          const paragraph = Block.create('paragraph');
          return editor.insertNodeByKey(node.key, node.nodes.size, paragraph);
        }
        default:
          return editor;
      }
    },
  },
};

const initialValue = {
  document: {
    nodes: [
      {
        nodes: [
          {
            leaves: [
              {
                text: 'A line of text in our new editor.',
              },
            ],
            object: 'text',
          },
        ],
        object: 'block',
        type: 'paragraph',
      },
    ],
  },
};

const existingValue = JSON.parse(localStorage.getItem('content'));
const editorValue = Value.fromJSON(existingValue || initialValue);

const MarkHotkey = (options) => {
  const { type, key } = options;

  // Return our "plugin" object, containing the `onKeyDown` handler.
  return {
    onKeyDown(event, editor, next) {
      // If it doesn't match our `key`, let other plugins handle it.
      if (!event.metaKey || event.key !== key) {
        return next();
      }

      // Prevent the default characters from being inserted.
      event.preventDefault();

      // Toggle the mark `type`.
      return editor.toggleMark(type);
    },
  };
};

const NodeHotkey = (options) =>
  // Return our "plugin" object, containing the `onKeyDown` handler.
  ({
    onKeyDown(event, editor, next) {
      // If it doesn't match our `key`, let other plugins handle it.
      if (!event.metaKey || event.key !== options.key) {
        return next();
      }

      // Prevent the default characters from being inserted.
      event.preventDefault();

      // Toggle the mark `type`.
      return editor.insertBlock({
        data: {
          alt: 'Kittens',
          className: 'img-responsive',
          src: 'http://placekitten.com/200/300',
        },
        object: 'block',
        type: 'image',
      });
    },
  });
const plugins = [
  MarkHotkey({
    key: 'b',
    type: 'bold',
  }),
  MarkHotkey({
    key: 'i',
    type: 'italic',
  }),
  MarkHotkey({
    key: '/',
    type: 'strikethrough',
  }),
  MarkHotkey({
    key: 'u',
    type: 'underline',
  }),
  MarkHotkey({
    key: '.',
    type: 'tag',
  }),
  NodeHotkey({
    key: ',',
    type: 'image',
  }),
];

class Slate extends PureComponent {
  state = {
    value: editorValue,
  };

  handleChange = (editor) => {
    // Check to see if our value has changed before we set a new value to local storage
    const { value } = this.state;
    if (editor?.value.document !== value.document) {
      // We may want to persist our data as JSON just so it is easy to work with
      // and consistent. This way we don't have to worry about changing
      // serializers everywhere. If we have our own serializer it should only be
      // used getting data in or out of the editor. Once inside the editor we
      // should stick with JSON.
      const content = JSON.stringify(editor?.value.toJSON());
      localStorage.setItem('content', content);
    }

    this.setState({
      value,
    });
  };

  renderMark = (props, editor, next) => {
    switch (props.mark.type) {
      case 'bold':
        return <strong>{props.children}</strong>;
      case 'italic':
        return <em>{props.children}</em>;
      case 'strikethrough':
        return <del>{props.children}</del>;
      case 'underline':
        return <u>{props.children}</u>;
      case 'tag':
        return <span className={styles.Tag}>{props.children}</span>;
      default:
        return next();
    }
  };

  renderNode = (props, editor, next) => {
    const { node, attributes } = props;

    switch (node.type) {
      case 'tag': {
        const content = node.data.get('content');

        const href = node.data.get('href');
        return (
          <a {...attributes} className={styles.Tag} href={href}>
            {content}
          </a>
        );
      }
      case 'image': {
        const src = node.data.get('src');
        return <img alt='slate-item' {...attributes} src={src} />;
      }
      default:
        return next();
    }
  };

  render() {
    const { value } = this.state;

    return (
      <main
        style={{
          margin: 'auto',
          maxWidth: '600px',
          padding: '1em',
        }}
      >
        <h2>{'TextArea'}</h2>

        <div className='ui form'>
          <div className='field'>
            <textarea
              cols='30'
              defaultValue='A line of text in a textarea.'
              id=''
              name=''
              rows='2'
            />
          </div>
        </div>

        <h2>{'New Editor'}</h2>

        <Editor
          className={styles.Editor}
          onChange={this.handleChange}
          plugins={plugins}
          renderMark={this.renderMark}
          renderNode={this.renderNode}
          schema={schema}
          value={value}
        />
      </main>
    );
  }
}

export default Slate;
