import buildCompositeController from '../../utils/buildCompositeController';
import anonResetPasswordActions from '../../actions/anonResetPasswordActions';
import anonResetPasswordThunks from '../../actions/anonResetPasswordThunks';
import getTenant from '../../selectors/getTenant';

const mapStateToProps = (state) => {
  const tenant = getTenant(state);

  const anonResetPassword = state.get('anonResetPassword');
  const sendRecoveryCodeState = anonResetPassword.get('sendCode');
  const validateRecoveryCodeState = anonResetPassword.get('validateCode');

  return {
    sendRecoveryCodeState,
    tenant,
    validateRecoveryCodeState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPasswordInit() {
    dispatch(anonResetPasswordActions.resetPasswordInit());
  },

  sendRecoveryCode(email, recoveryMethod) {
    dispatch(anonResetPasswordThunks.sendCode(email, recoveryMethod));
  },

  validateRecoveryCode(recoveryCode, userEmail) {
    dispatch(anonResetPasswordThunks.validateCode(recoveryCode, userEmail));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
