import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';

const saveAuthSAMLIDP = async (data) => {
  const url = endpointGenerator.genPath('authentication.samlIdp');

  return _APIcall.post({
    data,
    token: true,
    url: url,
  });
};

export default saveAuthSAMLIDP;
