import immutable from 'immutable';
import { getFormSyncErrors } from 'redux-form/immutable';
import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';

// Thunks
import workflowThunks from '../../actions/workflowThunks';
import sessionThunks from '../../actions/sessionThunks';
import tenantThunks from '../../actions/tenantThunks';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import BlobAttributesParser from '../../utils/BlobAttributesParser';
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
// Selectors
import getCurrentUser from '../../selectors/getCurrentUser';
import getLoginBlock from '../../selectors/getLoginBlock';
import { getFEBlobLocaleKey } from '../../selectors/getIntlMessages';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);

  const workflowState = state.get('workflowState');

  const isLoading = workflowState.get('loading');

  const progress = workflowState.get('progress');
  const errors = workflowState.get('errors');
  const attributes = workflowState.get('attributes');
  const locale = getFEBlobLocaleKey(state);

  // Block set up, attributr parsing, and translation
  const attributesParser = new BlobAttributesParser(attributes);
  const blob = attributesParser.setupBlob(workflowState, locale);
  const initialValues = attributesParser.getInitialValues(blob.get('blocks'));
  let errorsApi = state.getIn(['errorsApi', 'errors']) || immutable.List();
  errorsApi = attributesParser.setErrorLabelName(blob.get('blocks'), errorsApi);

  const loginBlock = getLoginBlock(state);

  const formSyncErrors = getFormSyncErrors('Form01')(state);

  return {
    blob,
    currentUser,
    errors,
    errorsApi,
    formSyncErrors,
    initialValues,
    isLoading,
    loginBlock,
    progress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  alternateSubmit(data) {
    dispatch(workflowThunks.onboardAlternateLogin(data));
  },

  getOnboardLoginActivity(workflowID, ref, code) {
    const getOnboardLoginActivity = () => {
      dispatch(
        workflowThunks.getOnboardLoginActivity(workflowID, ref, code)
      ).catch(() => {
        // DEV-7216 for now let's redirect to login
        // We don't have a case such as automatically login users
        // when they go back to the same onboarding link
        browserHistory.push(uiPathGenerator.genPath('login'));
      });
    };

    const sessionExists = persistentStorage.has(PersistentStorageKeys.SESSION);

    // if no ref and code then it means is k19 onboarding
    // plus we need to see if there is a session
    if (!ref && !code && sessionExists) {
      // First wait until loading tenant is finished
      // Otherwise there will be a race condition
      // when we do sessionThunks.redirectToLandingPath()
      dispatch(tenantThunks.getTenant()).then(() => {
        // For k19 onboarding we're gonna check if a workflow task is archived bfore
        dispatch(workflowThunks.getArchivedWorkflowRequests(workflowID))
          .then((res) => {
            if (res.results && res.results === 0) {
              // If we don't have any existing Archived response
              // We continue with the onboarding
              getOnboardLoginActivity();
            } else {
              // Go to landing Path
              dispatch(sessionThunks.redirectToLandingPath());
            }
          })
          .catch(() => {
            // In any error
            // We continue with the onboarding
            getOnboardLoginActivity();
          });
      });
    } else {
      getOnboardLoginActivity();
    }
  },

  onboardLogin(values) {
    dispatch(workflowThunks.onboardLogin(values));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
