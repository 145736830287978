const api_barista_v0_1_kb_articles_get_kbs_by_language = {
  count: 3,
  results: [
    {
      kb_language: 'ja',
      total: 16,
    },
    {
      kb_language: 'en',
      total: 71,
    },
    {
      kb_language: 'fr',
      total: 231,
    },
  ],
};

export default api_barista_v0_1_kb_articles_get_kbs_by_language;
