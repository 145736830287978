import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';

// import FormInputCheckbox   from '../../../../../../app/js/v1/components/molecules/FormInputCheckbox';
import FormInputText from '../../../../../../app/js/v1/components/molecules/FormInputText';
// import FormInputSelect     from '../../../../../../app/js/v1/components/molecules/FormInputSelect';

const propTypes = {
  addOptionFn: PropTypes.func,
  fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  removeOptionFn: PropTypes.func,
};

const defaultProps = {
  addOptionFn: noop,
  fields: null,
  removeOptionFn: noop,
};

class ObjectOfInstances extends Component {
  handleAddOption = memoize(
    curry((fields, e) => {
      fields.push({});
      const { addOptionFn } = this.props;
      addOptionFn(fields.name, fields);
    })
  );

  handleRemoveOption = memoize(
    curry((fields, index, e) => {
      fields.remove(index);
      const { removeOptionFn } = this.props;
      removeOptionFn(fields.name, index, fields);
    })
  );

  handleBlur(e) {
    e.preventDefault();
  }

  render() {
    const { fields } = this.props;

    return (
      <>
        <Grid>
          <Grid.Column>
            <Button.Group floated='right' size='tiny' vertical>
              <Button
                content={'Add new Object Instance'}
                onClick={this.handleAddOption(fields)}
                size='tiny'
              />
            </Button.Group>
          </Grid.Column>
        </Grid>

        {fields.map((optionFieldName, index) => (
          <Segment className='editorConditions' key={optionFieldName}>
            <Grid>
              <Grid.Column width={15}>
                <Header as='h5'>{`Object [${index}]`}</Header>

                <Field
                  component={FormInputText}
                  fluid
                  label='Intance Name'
                  name={`${optionFieldName}.instance_name`}
                />

                <Field
                  component={FormInputText}
                  fluid
                  label='Object'
                  name={`${optionFieldName}.object`}
                />

                <Field
                  component={FormInputText}
                  fluid
                  label='App Label'
                  name={`${optionFieldName}.app_label`}
                />

                <Header as='h4'>{'Object Filter'}</Header>

                <Field
                  component={FormInputText}
                  fluid
                  label='Assigned to'
                  name={`${optionFieldName}.object_filter.assigned_to`}
                />

                <Field
                  component={FormInputText}
                  fluid
                  label='Requester'
                  name={`${optionFieldName}.object_filter.requester`}
                />

                <Field
                  component={FormInputText}
                  fluid
                  label='Requested for'
                  name={`${optionFieldName}.object_filter.requested_for`}
                />

                <Field
                  component={FormInputText}
                  fluid
                  label='Status'
                  name={`${optionFieldName}.object_filter.status`}
                />
              </Grid.Column>
              <Grid.Column width={1}>
                <Button.Group floated='right' size='tiny' vertical>
                  <Button
                    icon={<Icon name='trash' />}
                    onClick={this.handleRemoveOption(fields, index)}
                  />
                </Button.Group>
              </Grid.Column>
            </Grid>
          </Segment>
        ))}
      </>
    );
  }

  // {"0":{"instance_name":"cart","object":"espcart","object_filter":{"status":"CREATED","requester":"self"},"app_label":"catalog"}}
}

ObjectOfInstances.propTypes = propTypes;

ObjectOfInstances.defaultProps = defaultProps;

export default ObjectOfInstances;
