import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreOther extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(16 16)'>
          <circle cx='31.75' cy='32.19' r='4.15' />
          <circle cx='20.33' cy='32.19' r='4.15' />
          <circle cx='43.17' cy='32.19' r='4.15' />
          <path d='M31.72 0C14.19.017-.01 14.24 0 31.77.01 49.3 14.23 63.506 31.76 63.5c17.53-.006 31.74-14.22 31.74-31.75C63.478 14.212 49.258.006 31.72 0zm0 58.5C16.95 58.483 4.99 46.5 5 31.73 5.01 16.96 16.99 4.995 31.76 5c14.77.006 26.74 11.98 26.74 26.75-.017 14.778-12.002 26.75-26.78 26.75z' />
        </g>
      </svg>
    );
  }
}

export default CatalogCompanyStoreOther;
