const data = {
  results: [
    { intent: 'Holiday Transition to PTO Request', total: 3 },
    { intent: 'test detection entities multi', total: 1 },
  ],
  // eslint-disable-next-line sort-keys -- be shape
  count: 2,
};

export default data;
