import _ from 'lodash';
import async from 'async';

// Util
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

// Action
import assetActions from './assetActions';
import workflowActions from './workflowActions';

// Selector
import getCurrentUser from '../selectors/getCurrentUser';

import CartStatuses from '../globals/CartStatuses';

/**
 * Remove One asset by changing his lifecyle to USER_DISOWNED and remove it from the store
 * @param assetID {Number} Asset ID
 */
const removeOneAsset = (assetID, state) =>
  new Promise((resolve, reject) => {
    let assetToDelete = null;
    state.getIn(['asset', 'assetItems']).forEach((asset) => {
      if (asset.get('id') === assetID) {
        assetToDelete = asset.toJS();
      }
    });
    assetToDelete.lifecycle = 'USER_DISOWNED'; // Pass lifecyle attribute to USER_DISOWNED
    const endpoint = endpointGenerator.genPath('espAssets.instance', {
      assetID,
    });

    return APIcall.put({
      data: assetToDelete,
      error(err) {
        reject(err);
      },
      success() {
        resolve();
      },
      token: true,
      url: endpoint,
    });
  });

const assetThunks = {};

/**
 * Load active user assets
 * @param type {String} Optional - Can be 'Hardware', 'Software' etc...
 * @param cb {Function} Callback
 */
assetThunks.loadAssets = (type, cb = _.noop) => (dispatch, getState) => {
  dispatch(assetActions.loadAssetsStart());
  const state = getState();
  const userID = getCurrentUser(state).get('id');

  let filter = `assigned_user__EQ=${userID}&lifecycle__EQ=ACTIVE`;

  if (type) {
    filter = `product.category_all.name__EQ=${type}&ORproduct_info.category__EQ=${type}&${filter}`;
  }

  const endPoint = endpointGenerator.genPath('espAssets');

  return APIcall.get({
    error(err) {
      dispatch(assetActions.loadAssetsFailure(err));
    },
    query: {
      esp_filters: encodeURI(filter),
    },
    success(res) {
      if (res.body.results) {
        const { results } = res.body;
        dispatch(assetActions.loadAssetsSuccess(results));
        cb(results);
      }
    },
    token: true,
    url: endPoint,
  });
};

/**
 * Load one asset
 */
assetThunks.loadOneAsset = (assetId) => (dispatch, getState) => {
  const state = getState();
  // Only load if there's not a loading attempt being made for that assetId
  // Note that this thunk can still be user to re-load the asset to bust cache,
  // This check only prevents two concurrent AJAX calls to be made at the same time
  if (!state.getIn(['asset', 'isLoadingAssetStatus', assetId])) {
    dispatch(assetActions.loadOneAssetStart(assetId));
    const endpoint = endpointGenerator.genPath('espAssets.instance', {
      assetID: assetId,
    });
    APIcall.get({
      error(err) {
        dispatch(assetActions.loadOneAssetFail(assetId, err.message));
      },
      success(res) {
        dispatch(assetActions.loadOneAssetSuccess(assetId, res.body));
      },
      token: true,
      url: endpoint,
    });
  }
};

/**
 * Remove assets from an array of ID
 * @param assetIDArray {Array}
 */
assetThunks.removeArrayOfAssets = (assetIDArray) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (!_.isArray(assetIDArray)) {
      reject(new Error('assetIDArray must be an Array'));
    } else if (!assetIDArray.length) {
      reject(new Error('assetIDArray Array is empty'));
    }

    dispatch(workflowActions.loading());

    const state = getState();

    async.eachOf(
      assetIDArray,
      (assetID, key, done) => {
        removeOneAsset(assetID, state)
          .then(() => {
            done();
          })
          .catch((err) => {
            done(err);
          });
      },
      (err) => {
        dispatch(workflowActions.exitLoading());
        if (!err) {
          dispatch(assetActions.removeItemSuccess(assetIDArray));
          resolve();
        } else {
          dispatch(assetActions.removeItemFail());
          reject();
        }
      }
    );
  });

/**
 * Change asset usage frequency
 * @param assetID {Number} Asset ID
 */
assetThunks.changeAssetFrequency = (assetID, usageFrequencyValue) => (
  dispatch,
  getState
) => {
  dispatch(assetActions.loadOneAssetStart(assetID));

  const state = getState();
  let assetToModify = null;
  state.getIn(['asset', 'assetItems']).forEach((asset) => {
    if (asset.get('id') === assetID) {
      assetToModify = asset.toJS();
    }
  });
  assetToModify.usage_frequency = usageFrequencyValue;
  const endpoint = endpointGenerator.genPath('espAssets.instance', {
    assetID,
  });

  return APIcall.put({
    data: assetToModify,
    error() {
      // dispatch(assetActions.loadCartFailure(err));
    },
    success(res) {
      dispatch(assetActions.updateOneAssetSuccess(assetID, res.body));
    },
    token: true,
    url: endpoint,
  });
};

//
// Updates values in an asset
// @param assetID {Number} Asset ID
// @param newValues {object} Map with attributes and values of the asset to update
//
//
assetThunks.updateAssetValues = (assetID, newValues) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(assetActions.loadOneAssetStart(assetID));

    const state = getState();
    let assetToModify = null;
    state.getIn(['asset', 'assetItems']).forEach((asset) => {
      if (asset.get('id') === assetID) {
        assetToModify = asset.toJS();
      }
    });
    assetToModify = _.merge({}, assetToModify, newValues);
    const endpoint = endpointGenerator.genPath('espAssets.instance', {
      assetID,
    });

    APIcall.put({
      data: assetToModify,
      error(err) {
        // dispatch(assetActions.loadCartFailure(err));
        reject(err.response.body.errors);
      },
      preventShowError: true,
      success(res) {
        dispatch(assetActions.updateOneAssetSuccess(assetID, res.body));
        resolve(res.body);
      },
      token: true,
      url: endpoint,
    });
  });

/**
 * Gets Carts requested for current user that are un SUBMITTED status.
 */
assetThunks.getPendingOrders = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    const userID = currentUser.get('id');

    const url = endpointGenerator.genPath('espCatalog.carts');

    dispatch(assetActions.getPendingOrdersStart());

    APIcall.get({
      error() {
        const errorMsg = 'Unable to get orders.';

        dispatch(assetActions.getPendingOrdersFail(errorMsg));
        reject(errorMsg);
      },
      query: {
        esp_filters: encodeURI(
          `status__EQ=${CartStatuses.SUBMITTED}&requested_for__EQ=${userID}`
        ),
      },
      success(response) {
        const orders = response.body.results;

        dispatch(assetActions.getPendingOrdersSuccess(orders)); // Pass the order list in the reducer
        resolve(orders);
      },
      token: true,
      url,
    });
  });

/**
 * Selects a pending order to view it's items.
 */
assetThunks.selectPendingOrder = (cartID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(assetActions.getPendingOrderItemsStart(cartID));

    const url = endpointGenerator.genPath('espCatalog.carts.instance.items', {
      cartID,
    });

    APIcall.get({
      error() {
        const errorMsg = 'Unable to get order items.';

        dispatch(assetActions.getPendingOrderItemsFail(errorMsg));
        reject(new Error(errorMsg));
      },
      query: {
        detail: 'yes',
      },
      success(response) {
        const cartItems = response.body.results;
        dispatch(assetActions.getPendingOrderItemsSuccess(cartItems));
        resolve(cartItems);
      },
      token: true,
      url,
    });
  });

export default assetThunks;
