import { connect } from 'react-redux';

// Thunks
import bundleThunks from '../../../actions/bundleThunks';
import bundleAction from '../../../actions/bundleActions';

// Selector
import getRoleBundles from '../../../selectors/getRoleBundles';
import getSelectedBundle from '../../../selectors/getSelectedBundle';
import getCurrentUser from '../../../selectors/getCurrentUser';
import getSimpleWFRequestedFor from '../../../selectors/getSimpleWFRequestedFor';

const mapStateToProps = (state, ownProps) => {
  const currentUser = ownProps.managerSelect
    ? getSimpleWFRequestedFor(state)
    : getCurrentUser(state);

  return {
    bundles: getRoleBundles(state),
    bundlesByTagLoaded: state.getIn(['bundles', 'bundlesByTagLoaded']),
    currentUser,
    errorMessage: state.getIn(['bundles', 'errorMessage']),
    isLoadingBundle: state.getIn(['bundles', 'isLoading']),
    selectedBundle: getSelectedBundle(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadBundles(tag, requestedFor) {
    if (requestedFor) {
      dispatch(bundleAction.resetBundlesList());
    }
    dispatch(bundleThunks.loadBundles(tag, requestedFor));
  },

  selectBundle(bundleId) {
    dispatch(bundleThunks.selectBundle(bundleId));
  },
});

const BundleSelectorController = (component) => () =>
  connect(mapStateToProps, mapDispatchToProps)(component);
export default BundleSelectorController;
