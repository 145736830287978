/**
 * Workflow EID should be unique even across tenants.
 * Use workflowThunks.mapEIDToID to get ID for any of listed below.
 * Most workflow API endpoints expect you to provide workflow ID.
 */
const WorkflowEIDs = {
  CONTACT_ME: '70e9d819-e0ac-404a-94f8-788091468d36',
  CREATE_FAQ: '1a1597c6-0022-491c-a749-21ca71a3f33b',
  CREATE_NEW_EMPLOYEE: '1753db17-e36d-41ac-9185-8cc9981c7dc6',
  DOWNLOAD_APP: 'a564df66-939f-4b1a-98f0-0dfb234f407d',
  EDIT_FAQ: 'aea680ac-b81a-498e-a084-778c3f830626',
  EXISTNG_EMPLOYEE: 'e3974bb8-c3bc-46a9-91ed-39db76afd4ac',
  TEACH_BARISTA: '94257332-a04c-4a88-a00b-0a083961a2a1',
};

export default WorkflowEIDs;
