import actionTypes from './actionTypes';

const anonResetPasswordActions = {};

anonResetPasswordActions.resetPasswordInit = () => ({
  type: actionTypes.ANON_RESET_PASSWORD_INIT,
});

anonResetPasswordActions.sendCodeStart = () => ({
  type: actionTypes.ANON_RESET_PASSWORD_SEND_CODE_START,
});

anonResetPasswordActions.changePasswordStart = () => ({
  type: actionTypes.ANON_RESET_PASSWORD_SEND_NEW_PASSWORD_START,
});

anonResetPasswordActions.sendCodeSuccess = (email) => ({
  email,
  type: actionTypes.ANON_RESET_PASSWORD_SEND_CODE_SUCCESS,
});

anonResetPasswordActions.sendCodeFail = (error) => ({
  error,
  type: actionTypes.ANON_RESET_PASSWORD_SEND_CODE_FAIL,
});

anonResetPasswordActions.validateCodeStart = () => ({
  type: actionTypes.ANON_RESET_PASSWORD_VALIDATE_CODE_START,
});

anonResetPasswordActions.validateCodeSuccess = (accessToken) => ({
  accessToken,
  type: actionTypes.ANON_RESET_PASSWORD_VALIDATE_CODE_SUCCESS,
});

anonResetPasswordActions.validateCodeFail = (error) => ({
  error,
  type: actionTypes.ANON_RESET_PASSWORD_VALIDATE_CODE_FAIL,
});

anonResetPasswordActions.promptResetPassword = () => ({
  type: actionTypes.PROMPT_RESET_PASSWORD,
});

anonResetPasswordActions.dimissPromptResetPassword = () => ({
  type: actionTypes.PROMPT_RESET_PASSWORD_DISMISS,
});

anonResetPasswordActions.changePasswordFail = (error) => ({
  error,
  type: actionTypes.ANON_CHANGE_PASSWORD_FAIL,
});

anonResetPasswordActions.changePasswordSuccess = () => ({
  type: actionTypes.ANON_CHANGE_PASSWORD_SUCCESS,
});

export default anonResetPasswordActions;
