import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form, List } from 'semantic-ui-react';
import classNames from 'classnames';

class FormInputCheckboxContent extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    description: PropTypes.arrayOf(PropTypes.string),
    hidden: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      value: PropTypes.any,
    }),
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: void 0,
    description: null,
    hidden: false,
    input: {},
    required: false,
  };

  handleOnChange = () => {
    const { input } = this.props;
    input.onChange(!input.value);
  };

  render() {
    const {
      defaultValue,
      description,
      hidden,
      input,
      label,
      required,
    } = this.props;

    if (defaultValue) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'Warning: defaultValue is not available on controlled Redux Form components. Use initialValue on the parent form instead.'
      );
    }

    return (
      <Form.Field
        className={classNames({
          hidden: hidden,
          required: required,
        })}
      >
        <Checkbox
          checked={Boolean(input.value)}
          label={label}
          onChange={this.handleOnChange}
        />
        <div className='content'>
          {description && (
            <List onClick={this.handleOnChange}>
              {description.map((desc) => (
                <List.Item content={desc} key={desc} />
              ))}
            </List>
          )}
        </div>
      </Form.Field>
    );
  }
}

export default FormInputCheckboxContent;
