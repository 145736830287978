const api_case_mgmt_v0_1_service_team_4 = {
  classification: 1,
  description: 'fwef wef',
  eid: '20c21e51-1df1-4594-9f11-4a6f753a25da',
  groups: [],
  id: 49,
  name: 'IT - Support',
  service_department: 17,
  sys_created_by: 'john@acme.com',
  sys_date_created: '2017-10-31T01:05:23.105067Z',
  sys_date_updated: '2017-10-31T01:05:23.105016Z',
  sys_updated_by: 'john@acme.com',
  team_lead: 2014,
  team_members: [{ id: 2014 }],
  url: 'http://tenant1.esp/api/case_mgmt/v0.1/service_team/49/',
};

export default api_case_mgmt_v0_1_service_team_4;
