const api_barista_v0_1_kb_support_get_kb_views = {
  count: 8,
  response: [
    {
      count_suggested: 10,
      origin: 'lumapps',
      record: '6675461079826432',
      short_description: 'Business unit news 3',
      status: 'RETIRED',
    },
    {
      count_suggested: 10,
      origin: 'lumapps',
      record: '5440746633035776',
      short_description: 'Business unit news 4',
      status: 'RETIRED',
    },
    {
      count_suggested: 10,
      origin: 'lumapps',
      record: '5079172932173824',
      short_description: 'Breaking news 1',
      status: 'RETIRED',
    },
  ],
};
export default api_barista_v0_1_kb_support_get_kb_views;
