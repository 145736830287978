import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Globals
import TypeBuildExistingEmployee, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBuildExistingEmployee';
import BuildExistingEmployeeController from '../../controllers/BuildExistingEmployeeController';

/**
 * This block is an empty component which allow
 * to build a BE scratch object for this FAQ
 */

class BuildExistingEmployeeData01 extends PureComponent {
  static propTypes = {
    ...TypeBuildExistingEmployee,
    isMultiLanguageTenant: PropTypes.bool,
    setBuildEmployeeBEScratch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    isMultiLanguageTenant: false,
  };

  componentDidMount() {
    const { isMultiLanguageTenant, setBuildEmployeeBEScratch } = this.props;

    setBuildEmployeeBEScratch(isMultiLanguageTenant);
  }

  render() {
    const { template, type } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template} />
    );
  }
}

export const BuildExistingEmployeeData01Test = BuildExistingEmployeeData01;

export default BuildExistingEmployeeController(BuildExistingEmployeeData01);
