import _APIcall from '../../../../../utils/APIcall';

const deleteAuthSAMLIDP = async (url) => {
  return _APIcall.delete({
    token: true,
    url,
  });
};

export default deleteAuthSAMLIDP;
