import React from 'react';
import pt from 'prop-types';
import { intl } from 'esp-util-intl';
import CommonPropsShape from './CommonPropsShape';
import SelectCategoryModal from './SelectCategoryModal';
import AnnouncementCategories from '../../../../../v1/globals/AnnouncementCategories';
import ValidatedField from '../../../../../v1/components/molecules/ValidatedField';
import styles from './SelectCategoryModal.module.scss';

const propTypes = {
  ...CommonPropsShape,
  category: pt.string.isRequired,
};

const Categories = ({
  announcementId,
  category,
  changeFormFieldValue,
  isDraft,
}) => {
  const ImgType = AnnouncementCategories.getIn([category, 'element']);

  return (
    <div className={styles.container} data-test-id='category'>
      <span className={`ui fluid image ${styles.image}`}>
        <ImgType />
      </span>
      <p className={styles.categoryName}>
        {intl.formatMessage({
          id: `category.${intl.prepareKey(
            AnnouncementCategories.getIn([category, 'name'])
          )}`,
        })}
      </p>
      <ValidatedField
        hidden
        name='categoryName'
        placeholder='None'
        readOnly
        transparent
      />
      {isDraft && (
        <SelectCategoryModal
          announcementId={announcementId}
          changeFormFieldValue={changeFormFieldValue}
          isDraft={isDraft}
        />
      )}
    </div>
  );
};

Categories.propTypes = propTypes;

export default Categories;
