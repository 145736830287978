// import PropTypes                from 'prop-types';
import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';

class IntegrationCSVMappingHierarchyDepartment extends PureComponent {
  render() {
    return (
      <div>
        <Header content='IntegrationCSVMappingHierarchyDepartment' />
      </div>
    );
  }
}

export default IntegrationCSVMappingHierarchyDepartment;
