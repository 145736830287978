const api_cards_v0_1_card = {
  results: [
    {
      allowed_time: 0,
      asset_id: null,
      asset_model: null,
      assigned_user: null,
      behavior_type: 'STANDARD',
      business_obj_owner: null,
      card_meta: {
        announcement_category: 'Building or Facility',
      },
      channel_id: null,
      channel_type: null,
      chat_enabled: false,
      content: [
        {
          text: 'Published: 2019-03-26',
        },
        {
          text: 'Hello Eva, how are u?',
        },
      ],
      content_type: 139,
      default_message: null,
      display_attributes: null,
      due_date: null,
      eid: 'e55f641a-4870-4c1c-910e-c21dd39cc52e',
      external_link_url: null,
      frontend_blob: {
        card_image: {
          url: '',
        },
        content: null,
        cta_button: [
          {
            action_type: 'link',
            condition: null,
            text: 'THIS WILL NOT SHOW',
          },
        ],
        header: {
          text: 'Task Title',
        },
        menu: [],
      },
      header: {
        text: 'Announcement: Hello World from ESP',
      },
      id: 25,
      name: 'Default template for announcement',
      natural_expiration: 0,
      nudged_time: null,
      object_id: 1,
      operation_state: 'ACTIVE',
      owner: 3,
      priority: 1000,
      sla_threshold: 0,
      snooze_time: 0,
      state: 'ACTIVE',
      sys_date_created: '2019-03-26T22:31:44.199974Z',
      sys_date_updated: '2019-03-26T22:31:44.219835Z',
      template: 4,
      type: 'ANNOUNCEMENT',
      unread_message_count: null,
      updates_available: false,
    },
  ],
};

export default api_cards_v0_1_card;
