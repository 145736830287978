import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogSoftwareOther extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(10 16.5)'>
          <circle cx='38.25' cy='37.66' r='2.09' />
          <circle cx='32.49' cy='37.66' r='2.09' />
          <circle cx='44.01' cy='37.66' r='2.09' />
          <path d='M38.25 53.69c8.975 0 16.25-7.275 16.25-16.25s-7.275-16.25-16.25-16.25S22 28.465 22 37.44c.01 8.97 7.28 16.24 16.25 16.25zm0-29.5c7.318 0 13.25 5.932 13.25 13.25s-5.932 13.25-13.25 13.25S25 44.758 25 37.44c.01-7.313 5.937-13.24 13.25-13.25zM19.58 52.54v.02-.02z' />
          <path d='M68 0H8C3.582 0 0 3.582 0 8v46.5c0 4.418 3.582 8 8 8h60c4.418 0 8-3.582 8-8V8c0-4.418-3.582-8-8-8zM28.3 4.66c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69 1.204-2.69 2.69-2.69zm-10.3 0c1.486 0 2.69 1.204 2.69 2.69s-1.204 2.69-2.69 2.69-2.69-1.204-2.69-2.69c0-.713.283-1.398.788-1.902.504-.505 1.19-.788 1.902-.788zm-10.3 0c1.484.006 2.684 1.212 2.68 2.697-.004 1.484-1.21 2.685-2.693 2.683C6.202 10.038 5 8.834 5 7.35c0-1.486 1.204-2.69 2.69-2.69h.01zM73 54.5c0 2.76-2.24 5-5 5H8c-2.76 0-5-2.24-5-5V14.6h70v39.9z' />
        </g>
      </svg>
    );
  }
}

export default CatalogSoftwareOther;
