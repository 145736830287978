import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Dropdown, Image } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';

// molecules
import UserImageContainer from './UserImageContainer';
import UserNameContainer from './UserNameContainer';
// Controller
import DelegateDropdownController from '../controllers/DelegateDropdownController';
// Package
import { intl } from 'esp-util-intl';

class DelegateDropdown extends PureComponent {
  static propTypes = {
    button: PropTypes.bool,
    className: PropTypes.string,
    delegators: ImmutablePropTypes.list,
    floating: PropTypes.bool,
    impersonateUser: PropTypes.func,
    trigger: PropTypes.node,
  };

  static defaultProps = {
    button: false,
    className: '',
    delegators: Immutable.List(),
    floating: false,
    impersonateUser: noop,
    trigger: null,
  };

  handleUserClick = memoize(
    curry((id, e) => {
      const { impersonateUser } = this.props;

      impersonateUser(id);
    })
  );

  render() {
    const { button, className, delegators, floating, trigger } = this.props;

    const styles = {
      dropdown: {
        marginRight: '.25em',
        textAlign: 'right',
        width: '5em',
      },
      menu: {
        left: 'auto',
        right: 0,
      },
    };

    return !delegators.isEmpty() ? (
      <Dropdown
        button={button}
        className={className}
        floating={floating}
        style={styles.dropdown}
        trigger={trigger}
      >
        <Dropdown.Menu style={styles.menu}>
          <Dropdown.Header
            content={intl.formatMessage({
              id: 'label.delegate_select',
            })}
          />
          {delegators.map((delegatorID) => (
            <Dropdown.Item
              content={<UserNameContainer userId={delegatorID} />}
              image={
                <Image avatar>
                  <UserImageContainer
                    height='32'
                    userId={delegatorID}
                    width='32'
                  />
                </Image>
              }
              key={delegatorID}
              onClick={this.handleUserClick(delegatorID)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : null;
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
DelegateDropdown = DelegateDropdownController(DelegateDropdown);

export default DelegateDropdown;
