const api_workflow_v0_1_workflow_39 = {
  allowed_time: 0,
  assigned_notification_policy: null,
  cancel_action: 'NOOP',
  card_template:
    'https://release.qa.espressive.com/api/cards/v0.1/card_template/27/',
  eid: 'bbdf887c-fb74-4ebd-a47b-e9c9df123997',
  experience_link_type: 'AUTHENTICATED',
  experience_start: false,
  id: 39,
  max_depth: 3,
  name: 'Parental Leave Request for {{nickname}} {{last_name}}',
  notification_content: null,
  owner_notification_policy: null,
  post_condition_group: {
    condition_entry: [
      {
        conditions: null,
        description: 'Condition d3414a8b-d76d-48dd-b719-8c7dabc2ba75',
        eid: 'f367d7f9-3d19-427b-9a76-5d3160ed0997',
        id: 200,
        object_instances: null,
        ordering: 0,
        success_workflow: null,
        success_workflow_task:
          'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/173/',
        url:
          'https://release.qa.espressive.com/api/workflow/v0.1/condition/200/',
      },
    ],
    eid: 'e1ce115b-9d2f-411a-90b5-b292d9b1eab6',
    failure_workflow: null,
    failure_workflow_task: null,
    id: 210,
    name: 'Condition Group b0a71cec-6f2f-4288-936b-f8e18fb3a470',
    sys_created_by: null,
    sys_custom_fields: null,
    sys_updated_by: null,
    url:
      'https://release.qa.espressive.com/api/workflow/v0.1/workflowconditionset/210/',
  },
  pre_condition_group: null,
  request_assigned_to: null,
  request_owner: null,
  request_requested_for: null,
  requested_for_notification_policy: null,
  sla_threshold: 0,
  tags: '',
  trigger: null,
  url: 'https://release.qa.espressive.com/api/workflow/v0.1/workflow/39/',
};

export default api_workflow_v0_1_workflow_39;
