import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form/immutable';
import Immutable, { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Accordion, Header } from 'semantic-ui-react';
// Molecule
import FormInputText from '../../molecules/FormInputText';
import ResponsesFAQGroup from '../../molecules/ResponsesFAQGroup';
// Controller
import AddResponseTextArea01Controller from '../../controllers/AddResponseTextArea01Controller';
// Globals
import TypeTextArea, {
  TypeDefaults,
} from '../../../globals/blocks/TypeAddResponseText';
// Block
import LocationDeptRolePicker01 from '../locationDeptRolePicker/LocationDeptRolePicker01';

import buildConditionHeader from '../../../utils/buildConditionHeader';
import BodyClassName from '../../../../globals/BodyClassName';

class AddResponseTextArea01 extends Component {
  static propTypes = {
    ...TypeTextArea,
    addFile: PropTypes.func, // Pass the our redux the files that we want to send at the very end
    change: PropTypes.func,
    conditionHeader: PropTypes.shape({
      locationDepartment: PropTypes.string,
      locationDeptRoleName: PropTypes.string,
      realLocationdeptrole: ImmutablePropTypes.map,
    }),
    emptyState: PropTypes.shape({
      readOnly: PropTypes.bool,
      value: PropTypes.string,
    }),
    forceFocus: PropTypes.bool,
    imageNewFaq: ImmutablePropTypes.map,
    isNewResponse: PropTypes.bool,
    query: PropTypes.shape({
      taskId: PropTypes.string,
      title: PropTypes.string,
    }),
    responsesFaq: ImmutablePropTypes.list,
    responsesMap: ImmutablePropTypes.map,
    setAlternateSubmit: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    addFile: _.noop,
    change: _.noop,
    conditionHeader: {
      locationDepartment: 'All',
      realLocationdeptrole: Immutable.Map(),
    },
    emptyState: {
      readOnly: false,
      value: null,
    },
    forceFocus: false,
    imageNewFaq: Immutable.Map(),
    isNewResponse: false,
    responsesFaq: null,
    responsesMap: null,
    setAlternateSubmit: _.noop,
  };

  constructor(props) {
    super(props);
    let indexesToPreserve = [];

    /**
     * In case of new response, we have to preverse the previous response from update
     * to avoid issue if we select the same conditions than existing responses
     */
    if (props.isNewResponse) {
      indexesToPreserve = props.responsesMap.reduce((c, v, k) => {
        c.push(k);
        return c;
      }, []);
    }

    this.state = {
      editLocationDeptRole: false,
      error: {},

      indexesToPreserve,
      // eslint-disable-next-line  react/destructuring-assignment -- TODO fix it
      isNewResponse: this.props.isNewResponse, // List of indexes to do not update
    };
  }

  componentDidMount() {
    const { change, imageNewFaq, responsesMap } = this.props;
    const { isNewResponse } = this.state;

    /**
     * The goal here is to detect if it's a new response or an edit of an existing
     * We have to populate the different form like locaiton Department Role and every line for the response
     */
    change('responsesFaq', fromJS([''])); // reset response
    const latestResponse = responsesMap.findLastEntry((entry) => entry);

    if (responsesMap.size && !isNewResponse && latestResponse) {
      /**
       * Load the latest responses in the textarea
       */
      const getCondition = latestResponse[1].get('locationdeptrole');
      const condition = buildConditionHeader(getCondition);

      /**
       * Filter all responses with the same condition
       * @type {Immutable.List<V> | *}
       */
      const listResponses = responsesMap
        .filter(
          (entry) =>
            buildConditionHeader(entry.get('locationdeptrole'))
              .locationDepartment === condition.locationDepartment
        )
        .map((entry) => {
          const newEntry = entry.get('response');
          if (Immutable.Map.isMap(newEntry) && newEntry.has('name')) {
            return imageNewFaq.get(newEntry.get('name')); // This is a file - We have to grab the blob file from imageNewFaq
          }
          return newEntry;
        })
        .toList();

      change('responsesFaq', listResponses); // Pass existing responses
      change(
        'editLocDeptRole.0.locationdeptrole',
        condition.realLocationdeptrole
      ); // Pass existing conditions
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { conditionHeader, responsesFaq } = this.props;
    const nextResponses = nextProps.responsesFaq.get(0);
    if (
      (nextProps.responsesFaq &&
        nextResponses &&
        nextProps.responsesFaq !== responsesFaq) ||
      nextProps.conditionHeader.realLocationdeptrole !==
        conditionHeader.realLocationdeptrole
    ) {
      this.reBuildField(
        nextProps.responsesFaq,
        nextProps.conditionHeader.realLocationdeptrole
      );
    }
  }

  handleUpdateCharLength = (i, event, newValue) => {
    const { charLimit, nonEmptyLineRequired } = this.props;

    const count = newValue.length;

    if (_.isNumber(charLimit) && count > charLimit) {
      // Maximum exceeded
      event.preventDefault();
    }

    if (nonEmptyLineRequired) {
      // Check if we have to set an error for the character minimum value
      this.checkMinCharacters(i, newValue.length);
    }
  };

  checkMinCharacters = (i, charLength) => {
    const { error } = this.state;

    const errors = error;

    if (charLength < 1 && !error[i]) {
      errors[i] = intl.formatMessage({
        id: 'label.field_can_not_empty',
      });
      this.setState({
        error: errors,
      });
    } else {
      errors[i] = '';
      this.setState({
        error: errors,
      });
    }
  };

  validate = (value) => {
    let errorFound;
    const { nonEmptyLineRequired } = this.props;

    if (value) {
      value.forEach((val, i) => {
        if (nonEmptyLineRequired && (!val || (val.length && val.length < 1))) {
          if (!errorFound) {
            errorFound = {};
          }
          errorFound[i] = intl.formatMessage({
            id: 'label.field_can_not_empty',
          });
        }
      });
    }
    return errorFound;
  };

  reBuildField = (responsesFaq, realLocationdeptrole) => {
    const { change, id, responsesMap } = this.props;
    const { indexesToPreserve } = this.state;

    if (responsesFaq) {
      /**
       * Filter responsesMap that we want to preserve
       */
      const keepedResponse = responsesMap.filter(
        (resp, k) => indexesToPreserve.indexOf(k) > -1
      );

      /**
       * Build the new responses to pass as an attribute
       */
      let newResponse =
        keepedResponse && !keepedResponse.isEmpty()
          ? keepedResponse
          : fromJS({});
      let lastIndex = keepedResponse.findLastEntry((entry) => entry);
      lastIndex = lastIndex ? Number(lastIndex[0]) + 1 : 0;
      responsesFaq.forEach((v, index) => {
        const indexToPass = lastIndex + index; // and new entry
        const value =
          _.isObject(v) && v.name
            ? {
                name: v.name,
              }
            : v;
        newResponse = newResponse.set(
          indexToPass,
          fromJS({
            response: value,
          })
        );
        if (realLocationdeptrole) {
          newResponse = newResponse.setIn(
            [indexToPass, 'locationdeptrole'],
            realLocationdeptrole
          );
        }
      });

      /**
       * Pass the new response attribute to redux form
       */
      change(id, newResponse);
    }
  };

  overrideAddFile = (fields, file) => {
    const { addFile, change, imageNewFaq, responsesFaq } = this.props;

    const reader = new FileReader();

    /**
     * Load the base 64 of the file
     */
    reader.onloadend = (fileResult) => {
      const fileGot = file;
      fileGot.src = fileResult.target.result;

      /**
       * Check if the first response is not empty
       */
      const first = responsesFaq.first();
      if (!first) {
        change('responsesFaq.0', fileGot); // Change the first entry to this new file value
      } else {
        fields.push(fileGot); // Push a new entry
      }

      /**
       * Pass the blob data of this file in our reducer
       */
      addFile(imageNewFaq.set(file.name, file));
    };

    reader.readAsDataURL(file);
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState((previousState) => ({
      editLocationDeptRole: !previousState.editLocationDeptRole,
    }));
  };

  render() {
    const {
      checkMessage,
      conditionHeader,
      emptyState,
      id,

      labelText,
      query,
      readOnly,
      responsesFaq,
      template,
      type,
    } = this.props;
    const { editLocationDeptRole, error } = this.state;

    const emptyStateValue = {
      input: {
        value: emptyState.value,
      },
    }; // Basic empty state value

    return (
      <BodyClassName className='blocks-top'>
        <>
          <div
            className='block field'
            data-blocktype={type}
            data-template={template}
          >
            <label>
              {intl.formatMessage({
                id: 'label.answer_applies_to',
              })}
            </label>
            <Accordion exclusive={false} fluid>
              <Accordion.Title
                active={editLocationDeptRole}
                onClick={this.handleClick}
              >
                <Header
                  as='h4'
                  content={conditionHeader.locationDepartment}
                  dividing
                  icon='dropdown'
                />
              </Accordion.Title>

              <Accordion.Content active={editLocationDeptRole}>
                <LocationDeptRolePicker01
                  disableRemoteAndNewHires
                  id='editLocDeptRole'
                  noBlock
                  pickComputer
                  pickDept
                  pickLocation
                  pickMobile
                  pickRole
                  template='locationDeptRolePicker01'
                  type='locationDeptRolePicker'
                />
              </Accordion.Content>
            </Accordion>
          </div>
          <div
            className='block answer field'
            data-blocktype={type}
            data-template={template}
          >
            <label>
              {intl.formatMessage({
                id: 'label.faq_answer',
              })}
            </label>
            {!responsesFaq &&
            readOnly &&
            emptyState.readOnly &&
            emptyState.value ? (
              <Field
                {...emptyStateValue}
                component={FormInputText}
                label={labelText}
                name={`${id.toString()}_empty`}
                readOnly
                transparent
              />
            ) : (
              <FieldArray
                checkMessage={checkMessage}
                component={ResponsesFAQGroup}
                errors={error}
                isFromKB={Boolean(query.title)}
                moveField={this.moveField}
                name='responsesFaq'
                onChange={this.handleUpdateCharLength}
                overrideAddFile={this.overrideAddFile}
                readOnly={readOnly}
                removeField={this.removeField}
                taskId={query.taskId ? Number(query.taskId) : null}
                validate={this.validate}
              />
            )}

            <Field component={FormInputText} hidden name={id.toString()} />
          </div>
        </>
      </BodyClassName>
    );
  }
}
const AddResponseTextArea01Test = AddResponseTextArea01;

// eslint-disable-next-line no-class-assign -- DEV-1526
AddResponseTextArea01 = AddResponseTextArea01Controller(AddResponseTextArea01);
export { AddResponseTextArea01Test };
export default AddResponseTextArea01;
