import buildCompositeController from '../../utils/buildCompositeController';
import tenantThunks from '../../actions/tenantThunks';
import { getTenantUIConfig } from '../../selectors/getTenant';

const mapStateToProps = (state) => {
  const isSaving = state.getIn(['tenant', 'updateTenant', 'isLoading']);
  const soundsEnabled = getTenantUIConfig(state).getIn(
    ['notifications_config', 'sounds_enabled'],
    true
  );
  return {
    isSaving,
    soundsEnabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTenantNotificationsConfiguration(values) {
    dispatch(tenantThunks.setTenantNotificationsConfiguration(values));
  },
});
export default buildCompositeController(mapStateToProps, mapDispatchToProps);
