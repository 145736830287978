import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'logo_1',
  },

  text1: {
    default: '',
    type: 'string',
  },

  text2: {
    default: '',
    type: 'string',
  },
});

// Block definition used by blobifier
const LogoBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Logo',
  templates: ['logo_1', 'logo02'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, LogoBlockDefinition, TypeDefaults };
