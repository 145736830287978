import _ from 'lodash';

import BlockBase from '../../utils/BlockBase';
import { processPropDefaults, processPropTypes } from '../../utils/typesUtils';

// Definition of the block attributes
const frontEndBlobAttributes = _.merge({}, BlockBase, {
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'spacer01',
  },
});

// Object Definition used by blobifier
const SpacerBlockDefinition = {
  blobAttributes: frontEndBlobAttributes,
  label: 'Spacer',
  templates: ['spacer01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(frontEndBlobAttributes);
const TypeDefaults = processPropDefaults(frontEndBlobAttributes);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, SpacerBlockDefinition, TypeDefaults };
