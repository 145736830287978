import React, { useCallback, useContext, useState } from 'react';
import { deleteType, labels } from '../utils/constants';
import AzureContext from '../AzureContext';
import { Button, Form, Segment } from 'semantic-ui-react';
import AzureBasicSSOFormikWrapper from './AzureBasicSSOFormikWrapper';
import AzureBasicSSOForm from './AzureBasicSSOForm';
import AzureBasicSSOEditActionButtons from './AzureBasicSSOEditActionButtons';
import EspConfirmationModal from '../../../../../../../v2/components/display/EspConfirmationModal/EspConfirmationModal';

const propTypes = {};

const AzureBasicSSO = () => {
  const { state, toggleEditMode, handleSubmitBasicSSO, handleDelete } =
    useContext(AzureContext);

  const { isBasicSSOEditable, basicSSO, isEmployeePasswordResetEnabled } =
    state;

  const [isDeleteModalOpen, setDeleteModal] = useState();

  const submit = useCallback(
    (values) => {
      handleSubmitBasicSSO(values);
    },
    [handleSubmitBasicSSO]
  );

  const initialValues = {
    azure_client_id: basicSSO?.azure_client_id || '',
    azure_client_secret: '',
    azure_tenant_id: basicSSO?.azure_tenant_id || '',
    is_password_reset_enabled: isEmployeePasswordResetEnabled || false,
    name: basicSSO?.name || '',
  };

  const handleConfirm = useCallback(() => {
    setDeleteModal(!isDeleteModalOpen);
    handleDelete(deleteType.BASIC_SSO);
  }, [handleDelete, isDeleteModalOpen]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModal(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  return (
    <AzureBasicSSOFormikWrapper initialValues={initialValues} onSubmit={submit}>
      <>
        <Segment
          attached
          loading={state?.isLoading}
          style={{ borderTop: 'none' }}
        >
          <Form as={'div'}>
            <Form.Group>
              <Form.Field width={8}>
                <AzureBasicSSOForm isEditable={isBasicSSOEditable} />
              </Form.Field>

              <Form.Field width={8}>
                <div style={{ textAlign: 'right' }}>
                  {!isBasicSSOEditable && (
                    <Button
                      basic
                      content={labels.EDIT}
                      onClick={toggleEditMode}
                      type='button'
                    />
                  )}
                  {basicSSO?.name && (
                    <Button
                      color='red'
                      content={labels.REMOVE}
                      onClick={toggleDeleteModal}
                      type='button'
                    />
                  )}

                  <EspConfirmationModal
                    cancelButton={labels.CANCEL}
                    confirmButton={
                      <Button
                        color='red'
                        content={labels.REMOVE}
                        type='button'
                      />
                    }
                    content={labels.DELETE_BODY}
                    header={labels.DELETE_TITLE}
                    onCancel={toggleDeleteModal}
                    onConfirm={handleConfirm}
                    open={isDeleteModalOpen}
                  />
                </div>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
        {isBasicSSOEditable && <AzureBasicSSOEditActionButtons />}
      </>
    </AzureBasicSSOFormikWrapper>
  );
};

AzureBasicSSO.propTypes = propTypes;

export default AzureBasicSSO;
