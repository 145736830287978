const api_task_v0_1_tasks_111 = {
  author: 123,
  category: 'lol',
  id: '111',
  metadata: {
    approval_details: {
      approval_questions: [
        {
          answer: 'Lorem ipsum',
          eid: 'dasda',
          isEditing: false,
          question: 'Question 1',
        },
        {
          answer: 'Lorem ipsum',
          eid: 'dasrrewda',
          isEditing: false,
          question: 'Question 2',
        },
        {
          answer: 'Lorem ipsum',
          eid: 'da53453sda',
          isEditing: false,
          question: 'Question 3',
        },
      ],
    },
  },
  owner: 2334,
  resolve_deadline: '2020-01-01',
  response_deadline: '2020-01-01',
  service_team: {
    id: '111',
  },
  status: 'open',
  sys_date_created: '',
  sys_date_updated: '2020-01-01',
  title: 'lol',
  type: 'approval_manager',
};

export default api_task_v0_1_tasks_111;
