import actionTypes from './actionTypes';

const locationsActions = {};

locationsActions.getRootLocationsStart = () => ({
  type: actionTypes.GET_ROOT_LOCATIONS_START,
});

locationsActions.getRootLocationsSuccess = (rootLocations) => ({
  rootLocations,
  type: actionTypes.GET_ROOT_LOCATIONS_SUCCESS,
});

locationsActions.getRootLocationsFail = () => ({
  type: actionTypes.GET_ROOT_LOCATIONS_FAIL,
});

locationsActions.getChildrenLocationsStart = () => ({
  type: actionTypes.GET_CHILDREN_LOCATIONS_START,
});

locationsActions.getChildrenLocationsSuccess = (
  childrenLocations,
  next = null
) => ({
  childrenLocations,
  next,
  type: actionTypes.GET_CHILDREN_LOCATIONS_SUCCESS,
});

locationsActions.getChildrenLocationsFail = () => ({
  type: actionTypes.GET_CHILDREN_LOCATIONS_FAIL,
});

locationsActions.getDefaultSelectedLocationStart = () => ({
  type: actionTypes.GET_DEFAULT_SELECTED_LOCATION_START,
});

locationsActions.getDefaultSelectedLocationSuccess = (locations) => ({
  locations,
  type: actionTypes.GET_DEFAULT_SELECTED_LOCATION_SUCCESS,
});

locationsActions.getDefaultSelectedLocationFail = () => ({
  type: actionTypes.GET_DEFAULT_SELECTED_LOCATION_FAIL,
});

export default locationsActions;
