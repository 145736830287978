import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsPasswordReset extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M70 58.4c1.105 0 2-.895 2-2v-8.19c0-1.105-.895-2-2-2H41.07v-1.44c0-4.322-3.503-7.825-7.825-7.825s-7.825 3.503-7.825 7.825v6.95c0 4.322 3.503 7.825 7.825 7.825s7.825-3.503 7.825-7.825v-1.51h16.44v6.19c0 1.105.895 2 2 2s2-.895 2-2v-6.19H68v6.19c0 1.105.895 2 2 2zm-32.93-6.68c0 1.367-.73 2.63-1.913 3.313-1.183.683-2.64.683-3.824 0-1.184-.684-1.913-1.946-1.913-3.313v-6.95c0-2.112 1.713-3.825 3.825-3.825s3.825 1.713 3.825 3.825v6.95zM17.31 31.21c5.585-10.07 15.723-16.792 27.173-18.016 11.45-1.224 22.78 3.203 30.367 11.866l-3.72-.34c-.828-.075-1.56.537-1.635 1.365-.075.828.537 1.56 1.365 1.635l7.74.7h.14c.414 0 .81-.17 1.094-.47.284-.302.43-.707.406-1.12l-.47-8.21c-.047-.828-.757-1.462-1.585-1.415-.828.047-1.462.757-1.415 1.585l.24 4.17c-8.25-9.34-20.524-14.09-32.913-12.74-12.39 1.35-23.352 8.632-29.397 19.53-.274.47-.274 1.05.002 1.518.275.47.783.75 1.326.74.543-.013 1.038-.318 1.292-.798h-.01zM80.86 65.11c-.714-.376-1.598-.12-2 .58-5.586 10.067-15.72 16.785-27.17 18.01-11.446 1.223-22.773-3.2-30.36-11.86l3.73.34c.828.075 1.56-.537 1.635-1.365.075-.828-.537-1.56-1.365-1.635l-7.74-.7c-.434-.038-.864.114-1.176.417-.313.303-.48.728-.454 1.163l.47 8.21c.048.793.705 1.41 1.5 1.41h.09c.825-.048 1.455-.755 1.41-1.58l-.3-4.16c8.25 9.34 20.524 14.09 32.913 12.74 12.39-1.35 23.352-8.632 29.397-19.53.403-.724.143-1.637-.58-2.04z' />
        </g>
      </svg>
    );
  }
}

export default CardsPasswordReset;
