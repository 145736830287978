import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { intl } from 'esp-util-intl';
import { Container, Menu } from 'semantic-ui-react';
import uiPathGenerator from '../../utils/uiPathGenerator';

class AdminAppHeader extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.shape({
      first_name: PropTypes.string,
    }).isRequired,
    linkTitle: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    linkTitle: '/editor',
    title: 'Blobifier',
  };

  userFirstName() {
    const { currentUser } = this.props;
    return currentUser ? currentUser?.first_name : '';
  }

  render() {
    const { currentUser, linkTitle, title } = this.props;

    return (
      <Menu borderless fixed='top' inverted>
        <Container>
          <Menu.Item as={NavLink} header to={linkTitle}>
            {title}
          </Menu.Item>
          <Menu.Menu position='right'>
            {currentUser && (
              <Menu.Item>
                {intl.formatMessage({
                  id: 'label.welcome',
                })}

                {`, ${this.userFirstName()}!`}
              </Menu.Item>
            )}
            <Menu.Item as={NavLink} to={uiPathGenerator.genPath('app.toDo')}>
              {intl.formatMessage({
                id: 'label.app',
              })}
            </Menu.Item>

            {currentUser && (
              <Menu.Item as={NavLink} to={uiPathGenerator.genPath('logout')}>
                {intl.formatMessage({
                  id: 'label.Logout',
                })}
              </Menu.Item>
            )}
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

export default AdminAppHeader;
