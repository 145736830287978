import React from 'react';
import { Segment } from 'semantic-ui-react';

import useOktaSAML from './useOktaSAML';
import OktaSSOWithSAMLModal from './OktaSSOWithSAMLModal';
import { labels } from '../utils/constants';

import PropTypes from 'prop-types';
import OktaSAMLContext from './OktaSAMLContext';
import OktaSAMLConfigured from './OktaSAMLConfigured';
import { Boundaries } from 'cascara-middleware';

const propTypes = {
  testData: PropTypes.shape({
    saml_idp_metadata_url: PropTypes.string,
  }),
};

const OktaSAML = ({ testData = null }) => {
  const { state, ...rest } = useOktaSAML();

  const providerValues = {
    ...rest,
    state,
  };

  const currentConfig = state?.config?.name ? state?.config : testData;

  return (
    <Boundaries>
      <Segment attached loading={state?.isLoading}>
        <OktaSAMLContext.Provider value={providerValues}>
          <div>
            {!currentConfig && (
              <div style={{ textAlign: 'center' }}>
                <p>{labels.NO_SSO_DESCRIPTION}</p>
                <OktaSSOWithSAMLModal />
              </div>
            )}
            {currentConfig && <OktaSAMLConfigured />}
          </div>
        </OktaSAMLContext.Provider>
      </Segment>
    </Boundaries>
  );
};

OktaSAML.propTypes = propTypes;

export default OktaSAML;
