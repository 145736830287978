const TypeStateGroup = {
  computer_type: 'computer_type',
  departments: 'departments',
  deviceType: 'deviceType',
  job_roles: 'job_roles',
  locations: 'locations',
  mobile_type: 'mobile_type',
  selectedJobRoles: 'selectedJobRoles',
};

export const TypeStateGroupProps = {
  computerType: 'computer_type',
  departments: 'department.ancestry.ancestry_id_list',
  deviceType: '$IT_OperatingSystems',
  include_new_hire: 'user_state',
  include_remote: 'remote_worker',
  job_roles: 'job_role.id',
  locations: 'location.ancestry.ancestry_id_list',
  mobileType: 'mobile_type',
};

export const TypeStateGroupCondition = {
  computerType: '__EQ=',
  departments: '__INTC=',
  deviceType: '__EQ=',
  include_new_hire: '__EQ=',
  include_remote: '__EQ=',
  job_roles: '__EQ=',
  locations: '__INTC=',
  mobileType: '__EQ=',
};

export default TypeStateGroup;
