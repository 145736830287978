import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Message } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import { noop } from 'lodash';
import { intl } from 'esp-util-intl';
// Atoms
import ScrollArea from '../atoms/ScrollArea';

// Molecule
import FormInputTextarea from './FormInputTextarea';
import FormInputSelect from './FormInputSelect';

// Global
import typeSupport from '../../globals/TypeSupport';
import fetchConfiguration from '../../../globals/commonAPICalls/fetchConfiguration';
const limiCharacters = 1000;

const DEFAULT_ESPRESSIVE_NAME = 'Espressive';
class SupportFormContent extends PureComponent {
  static propTypes = {
    handleFormError: PropTypes.func,
  };

  static defaultProps = {
    handleFormError: noop,
  };

  state = {
    error: '',
    espressiveBrandingName: '',
  };

  componentDidMount() {
    fetchConfiguration({ key: 'var.espressive_rebrand_name' })
      .then((res) => {
        this.setState({
          espressiveBrandingName: res.value,
        });
      })
      .catch(() => {
        // this is empty string while it loads;
        // errors or defaults to "Espressive"
        this.setState({
          espressiveBrandingName: DEFAULT_ESPRESSIVE_NAME,
        });
      });
  }

  handleTextareaChange = (event, newValue) => {
    const { handleFormError } = this.props;
    const count = newValue.trim().length;

    if (count > limiCharacters) {
      // Maximum exceeded
      event.preventDefault();
    }

    if (!newValue || count === 0) {
      handleFormError(
        intl.formatMessage({
          id: 'label.bundle_description_error',
        })
      );
      this.setState({
        error: intl.formatMessage({
          id: 'label.bundle_description_error',
        }),
      });
    } else {
      handleFormError('');
      this.setState({
        error: '',
      });
    }
  };

  render() {
    const { error, espressiveBrandingName } = this.state;

    const dropdownOption = [
      {
        key: 1,
        text: intl.formatMessage({
          id: 'label.help_with_espressive',
          values: {
            espressive_branding_name: espressiveBrandingName,
          },
        }),
        value: typeSupport.HELP,
      },
      {
        key: 2,
        text: intl.formatMessage({
          id: 'label.provide_feedback',
          values: {
            espressive_branding_name: espressiveBrandingName,
          },
        }),
        value: typeSupport.BUG,
      },
    ];

    return (
      <ScrollArea>
        <Field
          component={FormInputSelect}
          label={intl.formatMessage({
            id: 'label.how_can_we_help',
          })}
          name='typeFeedback'
          options={dropdownOption}
          placeholder={intl.formatMessage({
            id: 'label.seelct_one',
          })}
          selectOnBlur={false}
          selection
        />
        <Field
          charLimit={limiCharacters}
          component={FormInputTextarea}
          formError={error}
          label={intl.formatMessage({
            id: 'label.issue_feedback',
          })}
          name='msgFeedback'
          onChange={this.handleTextareaChange}
        />

        <Message size='tiny'>
          {intl.formatMessage({
            id: 'message.support_feedback_note',
            values: { espressive_branding_name: espressiveBrandingName },
          })}
        </Message>
      </ScrollArea>
    );
  }
}

export const SupportFormContentTest = SupportFormContent;

export default SupportFormContent;
