import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addJobRoles = (state, newJobRoles) => {
  newJobRoles.forEach((jobRole) => {
    const jobRoleID = jobRole.get('id');
    state = state.set(jobRoleID, jobRole);
  });

  return state;
};

const jobRolesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_JOB_ROLES_WITH_BUNDLES_SUCCESS:
      return addJobRoles(state, fromJS(action.jobRoles));

    case actionTypes.GET_JOB_ROLES_SUCCESS:
      return addJobRoles(state, fromJS(action.jobRoles));

    default:
      return state;
  }
};

export default jobRolesReducer;
