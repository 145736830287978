import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import adminReportMetrics from './AdminReportMetrics';
import adminReportFilterOptions, {
  showKeywordsOptions,
  SortKeywordsByOptions,
  SortPhrasesByOptions,
  timeOptions,
} from './AdminReportFilterOptions';

const reportDefinitions = {};

const REPORT_ROI_NAMES = {
  PERCENT: 'percent',
  SAVED: 'saved',
  TOTAL: 'total',
};

const TOTAL_INTERACTION = [
  adminReportMetrics.AUTOMATED_INCIDENTS,
  adminReportMetrics.AUTOMATED_RESOLUTIONS,
  adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
];

const surveyRenderTick = (frequency, barsCount) => ({ payload, y }) => (
  <g>
    {frequency && (
      <text
        fill='#4A4A4A'
        style={{ fontWeight: 'bold' }}
        textAnchor='start'
        y={y}
      >
        {`${frequency} ${barsCount > 1 ? payload.index + 1 : ''}`}
      </text>
    )}
    <text fill='#5d6571' textAnchor='start' y={frequency ? y + 20 : y}>
      {payload.value}
    </text>
  </g>
);

surveyRenderTick.propTypes = {
  payload: PropTypes.shape({
    index: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  y: PropTypes.number.isRequired,
};

reportDefinitions.interactions = {
  bars: [
    {
      dataKey: adminReportMetrics.NCC_INTERACTIONS,
      fill: '#1b487c',
      name: 'Regular Interactions',
      stackId: 'cc',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.CC_INTERACTIONS,
      fill: '#ffb44e',
      name: adminReportMetrics.CC_INTERACTIONS,
      stackId: 'cc',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.TOTAL_INTERACTIONS,
      fill: '#82ca9d',
      name: adminReportMetrics.TOTAL_INTERACTIONS,
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      label: adminReportMetrics.TOTAL_INTERACTIONS,
      name: adminReportMetrics.TOTAL_INTERACTIONS,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.CC_INTERACTIONS,
      name: adminReportMetrics.CC_INTERACTIONS,
    },
    {
      defaultChecked: true,
      disabled: true,
      label: 'Regular Interactions',
      name: adminReportMetrics.NCC_INTERACTIONS,
    },
    {
      defaultChecked: false,
      label: '% of Barista Regular Interactions',
      name: 'percent',
    },
  ],
  custom_url: 'barista_interactions_report',
  eid: 'e31e4429-0ad9-45e6-8a68-12b9a5b87af4',
  header: {
    description:
      'This is a count of regular interactions that employees have had.',
    label: 'Regular Interactions',
    metric: adminReportMetrics.NCC_INTERACTIONS,
    title: 'Interactions over time',
  },
  lines: [
    {
      dataKey: 'percent',
      name: '% of Regular Interactions',
      stroke: '#ff7300',
      yAxisId: 'percent',
    },
  ],
  percent: {
    proportion: adminReportMetrics.NCC_INTERACTIONS,
    total: adminReportMetrics.TOTAL_INTERACTIONS,
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      domain: [0, 100],
      orientation: 'right',
      yAxisId: 'percent',
    },
    {
      orientation: 'left',
      yAxisId: 'left',
    },
  ],
};
reportDefinitions.returnInvestment = {
  bars: [
    {
      dataKey: adminReportMetrics.AUTOMATED_RESOLUTIONS,
      fill: '#925cda',
      name: adminReportMetrics.AUTOMATED_RESOLUTIONS,
      stackId: 'ar',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.AUTOMATED_INCIDENTS,
      fill: '#ffd700',
      name: adminReportMetrics.AUTOMATED_INCIDENTS,
      stackId: 'ai',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
      fill: '#1b487c',
      name: adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
      stackId: 'asr',
      yAxisId: 'left',
    },
    {
      dataKey: 'saved',
      fill: '#82ca9d',
      name: '$ Saved',
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      label: '$ Saved',
      name: REPORT_ROI_NAMES.SAVED,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.PERCENTAGE_AUTOMATED_RESOLUTIONS,
      name: REPORT_ROI_NAMES.PERCENT,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.AUTOMATED_RESOLUTIONS,
      name: adminReportMetrics.AUTOMATED_RESOLUTIONS,
    },
    {
      defaultChecked: false,
      label: adminReportMetrics.AUTOMATED_INCIDENTS,
      name: adminReportMetrics.AUTOMATED_INCIDENTS,
    },
    {
      defaultChecked: false,
      label: adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
      name: adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
    },
  ],
  custom_metrics: [
    {
      dataKey: 'saved',
    },
  ],
  custom_url: 'call_deflection_report',
  export_url: 'call_deflection_csv',
  header: {
    description:
      'Report of your return on investment, calculated as number of calls deflected multiplied by your average cost per request.',
    format: 'currency',
    label: '$ Saved based on average cost per call:',
    labelCoefficient: 'callCost',
    metric: 'saved',
    title: 'Return on Investment',
  },
  lines: [
    {
      dataKey: 'percent',
      name: adminReportMetrics.PERCENTAGE_AUTOMATED_RESOLUTIONS,
      stroke: '#0083d9',
      yAxisId: 'percent',
    },
  ],
  percent: {
    proportion: adminReportMetrics.AUTOMATED_RESOLUTIONS,
    total: [
      adminReportMetrics.AUTOMATED_INCIDENTS,
      adminReportMetrics.AUTOMATED_RESOLUTIONS,
      adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
    ],
  },
  tooltip: {
    order: [
      REPORT_ROI_NAMES.SAVED,
      REPORT_ROI_NAMES.PERCENT,
      adminReportMetrics.AUTOMATED_RESOLUTIONS,
      adminReportMetrics.AUTOMATED_INCIDENTS,
      adminReportMetrics.AUTOMATED_SERVICE_REQUESTS,
      REPORT_ROI_NAMES.TOTAL,
    ],
    payload: [
      {
        dataKey: REPORT_ROI_NAMES.TOTAL,
        fill: '#28a99c',
        name: 'Total interactions',
        value: (payload) =>
          TOTAL_INTERACTION.reduce(
            (acc, entry) =>
              isNumber(payload && payload[entry]) ? acc + payload[entry] : acc,
            0
          ),
      },
    ],
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      domain: [0, 100],
      orientation: 'right',
      yAxisId: 'percent',
    },
    {
      orientation: 'left',
      yAxisId: 'left',
    },
  ],
};

reportDefinitions.helpfulInteractions = {
  bars: [
    {
      dataKey: adminReportMetrics.HELPFUL_FAQS,
      fill: '#82ca9d',
      name: adminReportMetrics.HELPFUL_FAQS,
      stackId: 'faqs',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.NOT_HELPFUL_FAQS,
      fill: '#925cda',
      name: adminReportMetrics.NOT_HELPFUL_FAQS,
      stackId: 'faqs',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.NO_FEEDBACK_FAQS,
      fill: '#ccc',
      name: adminReportMetrics.NO_FEEDBACK_FAQS,
      stackId: 'faqs',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.NEEDS_CONTENT_NO_MATCH,
      fill: '#ef5350',
      name: adminReportMetrics.NEEDS_CONTENT_NO_MATCH,
      stackId: 'faqs',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.NEEDS_CONTENT_NO_RESPONSE,
      fill: '#ffde03',
      name: adminReportMetrics.NEEDS_CONTENT_NO_RESPONSE,
      stackId: 'faqs',
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.ACCURATE_CONTENT_BUT_ASKING_FOR_HELP,
      fill: '#80deea',
      name: adminReportMetrics.ACCURATE_CONTENT_BUT_ASKING_FOR_HELP,
      stackId: 'faqs',
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      label: adminReportMetrics.HELPFUL_FAQS,
      name: adminReportMetrics.HELPFUL_FAQS,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.NOT_HELPFUL_FAQS,
      name: adminReportMetrics.NOT_HELPFUL_FAQS,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.NO_FEEDBACK_FAQS,
      name: adminReportMetrics.NO_FEEDBACK_FAQS,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.NEEDS_CONTENT_NO_MATCH,
      name: adminReportMetrics.NEEDS_CONTENT_NO_MATCH,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.NEEDS_CONTENT_NO_RESPONSE,
      name: adminReportMetrics.NEEDS_CONTENT_NO_RESPONSE,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.ACCURATE_CONTENT_BUT_ASKING_FOR_HELP,
      name: adminReportMetrics.ACCURATE_CONTENT_BUT_ASKING_FOR_HELP,
    },
    {
      defaultChecked: true,
      disabled: true,
      label: '% of Helpful FAQs',
      name: 'percent',
    },
  ],
  custom_url: 'helpful_barista_report',
  header: {
    description:
      'Report of all deflected calls that were confirmed by your employees as being helpful.',
    label: '% of Confirmed helpful FAQs',
    metric: 'percent',
    title: 'Helpful Interactions',
  },
  lines: [
    {
      dataKey: 'percent',
      name: '% of Helpful FAQs',
      stroke: '#0083d9',
      yAxisId: 'percent',
    },
  ],
  percent: {
    proportion: adminReportMetrics.HELPFUL_FAQS,
    total: [
      adminReportMetrics.HELPFUL_FAQS,
      adminReportMetrics.NOT_HELPFUL_FAQS,
      adminReportMetrics.NO_FEEDBACK_FAQS,
      adminReportMetrics.NEEDS_CONTENT_NO_MATCH,
      adminReportMetrics.NEEDS_CONTENT_NO_RESPONSE,
      adminReportMetrics.ACCURATE_CONTENT_BUT_ASKING_FOR_HELP,
    ],
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      domain: [0, 100],
      orientation: 'right',
      yAxisId: 'percent',
    },
    {
      orientation: 'left',
      yAxisId: 'left',
    },
  ],
};

reportDefinitions.newTopics = {
  bars: [
    {
      dataKey: adminReportMetrics.NEW_INTENTS,
      fill: '#82ca9d',
      name: 'New topics created',
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      disabled: true,
      label: 'New topics created',
      name: adminReportMetrics.NEW_INTENTS,
    },
  ],
  eid: 'c9f68726-bd0d-4ff1-a60d-868399c4c703',
  header: {
    description:
      'This report provides information on new topics created by your team to further increase knowledge.',
    label: 'Number of new intents created',
    metric: adminReportMetrics.NEW_INTENTS,
    title: 'New Topics',
  },
  lines: [],
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      orientation: 'left',
      yAxisId: 'left',
    },
  ],
};

reportDefinitions.requestTypes = {
  bars: [
    {
      dataKey: adminReportMetrics.SERVICE_REQUESTS,
      fill: '#82ca9d',
      name: adminReportMetrics.SERVICE_REQUESTS,
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.INCIDENTS,
      fill: '#1b487c',
      name: adminReportMetrics.INCIDENTS,
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      disabled: true,
      label: adminReportMetrics.SERVICE_REQUESTS,
      name: adminReportMetrics.SERVICE_REQUESTS,
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.INCIDENTS,
      name: adminReportMetrics.INCIDENTS,
    },
    {
      defaultChecked: true,
      label: '% of service requests',
      name: 'percent',
    },
  ],
  eid: 'd4040154-4212-4fc0-91cb-278c267dfb80',
  header: {
    description:
      'Report showing the total count of service requests and incidents created on behalf your employees.',
    label: 'Service Requests',
    metric: adminReportMetrics.SERVICE_REQUESTS,
    title: 'Employee Request Types',
  },
  lines: [
    {
      dataKey: 'percent',
      name: '% of service requests',
      stroke: '#925cda',
      yAxisId: 'percent',
    },
  ],
  percent: {
    proportion: adminReportMetrics.SERVICE_REQUESTS,
    total: adminReportMetrics.INCIDENTS,
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      domain: [0, 100],
      orientation: 'right',
      yAxisId: 'percent',
    },
    {
      orientation: 'left',
      yAxisId: 'left',
    },
  ],
};

reportDefinitions.returningEmployees = {
  bars: [
    {
      dataKey: adminReportMetrics.NEW_EMPLOYEES,
      fill: '#82ca9d',
      name: adminReportMetrics.NEW_EMPLOYEES,
      yAxisId: 'left',
    },
    {
      dataKey: adminReportMetrics.RETURNING_EMPLOYEES,
      fill: '#1b487c',
      name: adminReportMetrics.RETURNING_EMPLOYEES,
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      label: adminReportMetrics.NEW_EMPLOYEES,
      name: adminReportMetrics.NEW_EMPLOYEES,
    },
    {
      defaultChecked: true,
      disabled: true,
      label: adminReportMetrics.RETURNING_EMPLOYEES,
      name: adminReportMetrics.RETURNING_EMPLOYEES,
    },
    {
      defaultChecked: true,
      label: '% of returning employees',
      name: 'percent',
    },
  ],
  eid: '20649608-bba2-4536-a593-0488319d8420',
  header: {
    description:
      'The percentage of your employees returning to interact in chat.',
    label: '% of returning employees',
    metric: 'percent',
    title: 'Returning Employees',
  },
  lines: [
    {
      dataKey: 'percent',
      name: '% of returning employees',
      stroke: '#925cda',
      yAxisId: 'percent',
    },
  ],
  percent: {
    proportion: adminReportMetrics.RETURNING_EMPLOYEES,
    total: adminReportMetrics.TOTAL_EMPLOYEES,
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      domain: [0, 100],
      orientation: 'right',
      yAxisId: 'percent',
    },
    {
      orientation: 'left',
      yAxisId: 'left',
    },
  ],
};

reportDefinitions.unmatchedPhrases = {
  bars: [
    {
      dataKey: 'percent',
      fill: '#82ca9d',
      name: '% of unknown phrases',
      yAxisId: 'percent',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      disabled: true,
      label: '% of unknown phrases',
      name: 'percent',
    },
  ],
  eid: '3d57aa0d-0c72-43be-b0b7-2d60cb63d070',
  header: {
    description:
      'This report provides information on the number of interactions that required service desk assistance.',
    label: '% of unknown phrases',
    metric: 'percent',
    title: 'Unknown Employee Phrases',
  },
  lines: [],
  percent: {
    proportion: adminReportMetrics.UNKNOWN_EMPLOYEE_PHRASES,
    total: adminReportMetrics.TOTAL_INTERACTIONS,
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      domain: [0, 100],
      yAxisId: 'percent',
    },
  ],
};

reportDefinitions.employeeAdoption = {
  areas: [
    {
      dataKey: adminReportMetrics.TOTAL_EMPLOYEES,
      fill: '#be99f7',
      name: adminReportMetrics.TOTAL_EMPLOYEES,
      stroke: '#be99f7',
      type: 'monotone',
      yAxisId: 'total',
    },
  ],
  bars: [
    {
      dataKey: adminReportMetrics.UNIQUE_EMPLOYEES,
      fill: '#341456',
      name: adminReportMetrics.UNIQUE_EMPLOYEES,
      yAxisId: 'left',
    },
  ],
  checkboxes: [
    {
      defaultChecked: true,
      label: adminReportMetrics.UNIQUE_EMPLOYEES,
      name: adminReportMetrics.UNIQUE_EMPLOYEES,
    },
    {
      defaultChecked: true,
      label: '% of unique employees',
      name: 'percent',
    },
    {
      defaultChecked: true,
      label: adminReportMetrics.TOTAL_EMPLOYEES,
      name: adminReportMetrics.TOTAL_EMPLOYEES,
    },
  ],
  eid: 'd6cf364e-a898-42c1-85ed-24d823913a0b',
  header: {
    description: 'Report showing the number of unique employees interacting.',
    label: 'Employee adoption',
    metric: 'percent',
    title: 'Employee Adoption',
  },
  lines: [
    {
      dataKey: 'percent',
      name: '% of unique employees',
      stroke: '#2153af',
      yAxisId: 'percent',
    },
  ],
  percent: {
    proportion: adminReportMetrics.UNIQUE_EMPLOYEES,
    total: adminReportMetrics.TOTAL_EMPLOYEES,
  },
  xAxis: [
    {
      dataKey: 'name',
    },
  ],
  yAxis: [
    {
      orientation: 'left',
      yAxisId: 'left',
    },
    {
      orientation: 'left',
      yAxisId: 'total',
    },
    {
      orientation: 'right',
      yAxisId: 'percent',
    },
  ],
};

reportDefinitions.survey = {
  customSummary: true,
  customUiPath: true,
  custom_url: 'surveys_report',
  header: {
    description:
      'Show the results of specific surveys for a given time period. Choose your survey, time period and download the CSV for results.',
    title: 'Survey Reports',
  },
  xAxis: [
    {
      height: 40,
      interval: 'preserveEnd',
      label: {
        fill: '#4A4A4A',
        offset: 0,
        position: 'insideTop',
        value: 'Number of Responses',
      },
      orientation: 'top',
      type: 'number',
    },
  ],
  yAxis: (frequency, barsCount = 0) => [
    {
      dataKey: 'name',
      tick: surveyRenderTick(frequency, barsCount),
      tickSize: 0,
      type: 'category',
      width: 220,
    },
  ],
};

reportDefinitions.topTopics = {
  custom_url: 'top_intents_report',
  grid: true,
  header: {
    description:
      'This report provides the list of top topics requested by your employees.',
    label: 'Top most topic of the week',
    title: 'Top Topics',
  },
};

reportDefinitions.keywords = {
  custom: true,
  custom_url: 'keyword_interactions_report',
  detailView: {
    colHeaders: ['Phrases', 'Number of employees said this'],
    filters: [
      {
        key: 'searchbar',
        placeholder: 'Search keyword',
        type: 'SEARCHBAR',
        value: '',
      },
      {
        key: 'sort_by',
        label: 'Sort by: ',
        options: SortPhrasesByOptions,
        type: 'DROPDOWN',
        value: adminReportFilterOptions.DESCENDING,
      },
    ],
  },
  groupView: {
    colHeaders: ['Group Keyword Name', 'Number of phrases'],
    filters: [
      {
        key: 'searchbar',
        placeholder: 'Search keyword',
        type: 'SEARCHBAR',
        value: '',
      },
      {
        key: 'num_top_keywords',
        label: 'Show: ',
        options: showKeywordsOptions,
        type: 'DROPDOWN',
        value: adminReportFilterOptions.VALUE_25,
      },
      {
        key: 'time_period',
        label: 'Time Period: ',
        options: timeOptions,
        type: 'DROPDOWN',
        value: adminReportFilterOptions.LAST_30_DAYS,
      },
      {
        key: 'sort_by',
        label: 'Sort by: ',
        options: SortKeywordsByOptions,
        type: 'DROPDOWN',
        value: adminReportFilterOptions.DESCENDING,
      },
    ],
  },
  header: {
    description:
      'This is a list of keywords and related phrases which employees are asking recently.',
    title: 'Employee phrases grouped by Keywords',
  },
};

export default reportDefinitions;
