import EspFilters from 'esp-util-filters';
import APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

/**
 *
 * @param {string} key value for "key" in the Configuration List backend
 * @returns {Promise}  returns api promise result. the single config object is rsolved if found
 */
const fetchConfiguration = ({ key = '' } = {}) =>
  new Promise((resolve, reject) => {
    if (key) {
      const espFilters = new EspFilters().equalTo('key', key).asQueryString();

      APIcall.get({
        query: {
          esp_filters: espFilters,
        },
        token: true,
        url: endpointGenerator.genPath('espConfig'),
      })
        .then(({ body: { results = [] } }) => {
          // no results equals reject the promise
          if (results.length === 0) {
            reject();
          } else {
            // if results extract the config and return it
            resolve(results[0]);
          }
        })
        .catch(() => {
          reject();
        });
    } else {
      reject();
    }
  });

export default fetchConfiguration;
