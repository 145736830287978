import EmailServiceTypes from '../../../../../globals/EmailServiceTypes';

const initialValues = {
  app_id: '',
  client_secret: '',
  domain: '',
  email_address: '',
  email_type: EmailServiceTypes.EXCHANGE_OAUTH,
  name: '',
  password: '',
  primary: false,
  server_name: '',
  tenant_id: '',
  use_autodiscover: false,
};
const gridStyle = {
  borderRadius: '0',
  borderTop: 'none',
  marginBottom: '0',
};
const emailServiceOptions = [
  {
    key: 'exchange_oauth_option',
    text: 'Microsoft Exchange OAuth',
    value: EmailServiceTypes.EXCHANGE_OAUTH,
  },
  {
    key: 'exchange_option',
    text: 'Microsoft Exchange Legacy Authentication',
    value: EmailServiceTypes.EXCHANGE,
  },
  {
    key: 'gmail',
    text: 'Gmail',
    value: EmailServiceTypes.GMAIL,
  },
];
export { initialValues, gridStyle, emailServiceOptions };
