import APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';

const createTemplateAPI = (values) =>
  APIcall.post({
    data: values,
    token: true,
    url: endpointGenerator.genPath('espTodo.template'),
  });

const changeTemplateStatusAPI = (templateID, status) =>
  APIcall.post({
    data: {
      status,
    },
    token: true,
    url: endpointGenerator.genPath('espTodo.template.instance.changeStatus', {
      templateID: templateID,
    }),
  });

const loadTemplate = (templateID) => {
  const url = endpointGenerator.genPath('espTodo.template.instance', {
    templateID: templateID,
  });
  return APIcall.get({
    token: true,
    url: url,
  });
};

const updateTemplateAPI = (templateID, values) =>
  APIcall.put({
    data: values,
    token: true,
    url: endpointGenerator.genPath('espTodo.template.instance', {
      templateID: templateID,
    }),
  });

export default createTemplateAPI;
export { changeTemplateStatusAPI, loadTemplate, updateTemplateAPI };
