const api_barista_v0_1_intents_annotation_detail = {
  count: 21,
  results: [
    {
      description: 'Intent to send items to someone',
      eid: '2b715721-a0fb-4aea-b1ea-84cabb740d23',
      name: 'Send catalog product',
    },
    {
      description: null,
      eid: '0cbfda42-170d-4307-996d-4ccc45ff009c',
      name: 'it_how_do_i_save_a_file Default',
    },
    {
      description: null,
      eid: '28eb30d7-aeb5-4f5c-bd19-ee3ad99a5242',
      name: 'security_where_do_i_enable_badge Default',
    },
    {
      description: 'Archetype that covers saving files',
      eid: '790ebd91-88db-49ca-8820-5acee645de1f',
      name: 'IT How do I save a file archetype',
    },
    {
      description: 'finance_do_we_have Default',
      eid: '1a37ece9-adde-4e22-8fff-294b5b64f31c',
      name: 'finance_do_we_have Default',
    },
    {
      description: null,
      eid: 'e4354039-db03-4d0c-8d1e-3b6d91a89781',
      name: 'finance_expense_issues Default',
    },
    {
      description: 'Does company provide <service>',
      eid: '0c093407-ae9f-4dc1-a0c2-2e016477028f',
      name: 'Finance do we have Archetype',
    },
    {
      description: null,
      eid: 'bcd4a4e8-042e-40bd-85a2-107989ea863b',
      name: 'facilities_gym_equipment_issue Default',
    },
    {
      description: 'Expense is encountering <expense issue>',
      eid: 'ea1bcfa9-7c37-48a4-9b5e-f8df0aeefdd9',
      name: 'Finance Expense issues archetype',
    },
    {
      description: 'hr_when_are_claims_due Default',
      eid: '13d6cff2-7aa6-43cb-aa5c-c619c878e26b',
      name: 'hr_when_are_claims_due Default',
    },
    {
      description: 'Employee is reporting an issue with fitness equipment',
      eid: 'd4932356-c59a-43f7-b92d-fc1f3121889b',
      name: 'There is a problem with fitness equipment archetype',
    },
    {
      description: 'IT_Device_broken Default',
      eid: 'a1309aa0-004a-4fec-b391-9eff05da6b85',
      name: 'IT_Device_broken Default',
    },
    {
      description: null,
      eid: '8203c71b-a97b-4a6d-ae2f-9f860e4da8e1',
      name: 'it_email_how_do_send_or_receive Default',
    },
    {
      description:
        'Employee wants to know the submission deadline for <benefit>',
      eid: 'c67e7b89-5839-4396-a137-24cf6a76a12b',
      name: 'HR What is the deadline for submitting claims archetype',
    },
    {
      description: null,
      eid: 'd3b1aac9-f18b-4c67-9a4b-d6c1bcd1ecdc',
      name: 'IT_software_remove Default',
    },
    {
      description: 'Some <device> is broken',
      eid: 'c70c158e-dd64-4b8a-b8ed-e5fe84c16926',
      name: 'IT Device broken archetype',
    },
    {
      description: 'finance_something_is_incorrect Default',
      eid: '0c90a442-e171-4004-92f4-f2f5d42faf2d',
      name: 'finance_something_is_incorrect Default',
    },
    {
      description:
        'Employee is looking for assistance in how to send or receive an email',
      eid: '48014d57-d468-41ae-880c-a8125df72a8b',
      name: 'How do I use email archetype',
    },
    {
      description: 'Employee needs to uninstall <application>',
      eid: 'c642f735-f8fb-4c79-bfd7-2676e55e5776',
      name: 'IT Software Remove Archetype',
    },
    {
      description: 'Finance <service> is incorrect or has a problem',
      eid: 'c0134aa2-4ae6-4659-8240-b0458129123e',
      name: 'Finance something is incorrect archetype',
    },
    {
      description: null,
      eid: '55ec845a-5f64-436b-9a5a-116ccbe3c8b9',
      name: 'finance_what_is_per_diem Default',
    },
  ],
};
export default api_barista_v0_1_intents_annotation_detail;
