const api_barista_v0_1_reports_report11 = {
  job: {
    api_path: '/api/barista/v0.1/reports/report11/',
    app_job_eid: null,
    counter: 0,
    eid: '3c38e56b-dbf5-425d-87c7-f8281da94691',
    id: 14556,
    result_details: {},
    status: 'SCHEDULED',
    sys_created_by: 'fake@gmail.com',
    sys_custom_fields: null,
    sys_date_created: '2020-11-24T20:27:40.274605+00:00',
    sys_date_updated: '2020-11-24T20:27:40.274564+00:00',
    sys_updated_by: 'fake@gmail.com',
    total_count: 1,
  },
};
export default api_barista_v0_1_reports_report11;
