import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Dimmer, Grid, Header, List, Loader } from 'semantic-ui-react';
import queryString from 'query-string';
import moment from 'moment';
import { intl } from 'esp-util-intl';
import SurveyInfoController from './SurveyInfoController';
import { NavLink } from 'react-router-dom';
import AdminReportFilterOptions from '../../../../v1/globals/AdminReportFilterOptions';
import { UserAgentUtils } from 'esp-globals';
import { INPUT_DATE_FORMAT } from '../../../../globals/DefaultInputFormats';

class SurveyInfo extends PureComponent {
  static propTypes = {
    intentSurvey: PropTypes.string.isRequired,
    isCurrentUserAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    isTest: PropTypes.bool,
    reportDisplayChart: PropTypes.func.isRequired,
    survey: ImmutablePropTypes.mapContains({
      eid: PropTypes.string,
      is_scheduled: PropTypes.bool,
    }),
    surveys: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      questions: PropTypes.array,
      total_users_response_count: PropTypes.number,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      users_surveyed_count: PropTypes.any,
    }),
  };

  static defaultProps = {
    isCurrentUserAdmin: false,
    isLoading: false,
    isTest: false,
    survey: Immutable.Map(),
    surveys: {},
  };

  componentDidMount() {
    const { reportDisplayChart, intentSurvey } = this.props;
    reportDisplayChart(intentSurvey);
  }
  // internationalization
  latest_ocurrence_results = intl.formatMessage({
    id: 'label.latest_ocurrence_results',
  });
  sent = intl.formatMessage({
    id: 'label.sent',
  });
  responses = intl.formatMessage({
    id: 'label.responses',
  });
  full_report = intl.formatMessage({
    id: 'label.full_report',
  });
  render() {
    const {
      isCurrentUserAdmin,
      isLoading,
      survey,
      surveys,
      isTest,
    } = this.props;

    const isWeb =
      (UserAgentUtils.isMacOS() || UserAgentUtils.isWindows()) &&
      !UserAgentUtils.isElectron();

    const questionStyle = {
      margin: '1em 0 0 0',
      wordBreak: 'break-word',
    };

    const endDate = moment.utc().format(INPUT_DATE_FORMAT);
    const startDate = moment
      .utc()
      .subtract(30, 'days')
      .format(INPUT_DATE_FORMAT);
    const shouldDisplayButton =
      (isCurrentUserAdmin && isWeb && survey && !survey.isEmpty()) ||
      (isTest && isCurrentUserAdmin && survey && !survey.isEmpty());

    return (
      <>
        {isLoading && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}
        <Header as='h5' content={this.latest_ocurrence_results} />

        <Grid verticalAlign='top'>
          <Grid.Row>
            <Grid.Column width='10'>
              <div>
                <span>{`${this.sent}: `}</span>
                <b>{surveys.users_surveyed_count}</b>
              </div>
              <div>
                <div>
                  <span>{`${this.responses}: `}</span>
                  <b>{surveys.total_users_response_count}</b>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width='6'>
              {shouldDisplayButton && (
                <Button
                  as={NavLink}
                  basic
                  content={this.full_report}
                  to={`/admin/reports/survey?${queryString.stringify({
                    eid: survey.get('eid'),
                    end_date: endDate,
                    freq: survey.get('is_scheduled')
                      ? AdminReportFilterOptions.WEEKLY
                      : AdminReportFilterOptions.ALL_TIME,
                    isSurveyScheduled: survey.get('is_scheduled'),
                    start_date: startDate,
                  })}`}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {surveys.questions &&
          surveys.questions.map((survey, i) => (
            <Fragment key={i}>
              <div>
                <div style={questionStyle}>
                  <b>{`Q${i + 1}. `}</b>
                  {survey.question}
                </div>
              </div>
              <div>
                {survey.responses
                  .filter((response) => response.label !== 'response_count')
                  .map((question, j) => (
                    <List key={j}>
                      <List.Item>
                        <List.Content>
                          {question.label}
                          <List.Header>
                            {`(${
                              isNaN(question.percentage)
                                ? 0
                                : question.percentage
                            }%)`}{' '}
                            {question.value}
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    </List>
                  ))}
              </div>
            </Fragment>
          ))}
      </>
    );
  }
}

export const SurveyInfoTest = SurveyInfo;

export default SurveyInfoController(SurveyInfo);
