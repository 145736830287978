import actionTypes from '../actions/actionTypes';
import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  activeImage: {},
  error: null,
  isLoading: false,
  isLoadingProduct: false,
  results: null,
  selectedProduct: null,
});

const shopModalReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_ONE_PRODUCT_FAIL: {
      return state.set('error', true).set('isLoadingProduct', false);
    }

    case actionTypes.LOAD_ONE_PRODUCT_RESET: {
      return state
        .set('error', false)
        .set('isLoadingProduct', false)
        .set('selectedProduct', null);
    }

    case actionTypes.LOAD_ONE_PRODUCT_START: {
      return state.set('error', false).set('isLoadingProduct', true);
    }

    case actionTypes.LOAD_ONE_PRODUCT_SUCCESS: {
      return state
        .set('error', false)
        .set('isLoadingProduct', false)
        .set('selectedProduct', fromJS(action.product));
    }

    case actionTypes.SHOP_MODAL_ACTIVE_IMAGE: {
      return state.setIn(['activeImage', action.productID], action.position);
    }

    case actionTypes.SHOP_MODAL_SEARCH_FAIL: {
      return state.set('error', true).set('isLoading', false);
    }

    case actionTypes.SHOP_MODAL_SEARCH_START: {
      return state.set('error', false).set('isLoading', true);
    }

    case actionTypes.SHOP_MODAL_SEARCH_SUCCESS: {
      return state
        .set('error', false)
        .set('isLoading', false)
        .set('results', fromJS(action.results));
    }

    case actionTypes.SHOP_MODAL_SEARCH_RESET: {
      return state
        .set('error', false)
        .set('isLoading', false)
        .set('results', null);
    }

    default: {
      return state;
    }
  }
};

export default shopModalReducer;
