import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const requestsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_REQUESTS_START:
      return state.set('isLoading', true);
    case actionTypes.GET_REQUESTS_SUCCESS:
      return state.set('isLoading', false);
    case actionTypes.GET_REQUESTS_ERROR:
      return state.set('isLoading', false);
    case actionTypes.GET_REQUEST_DETAIL_START:
      return state.set('isLoadingDetail', true);
    case actionTypes.GET_REQUEST_DETAIL_SUCCESS:
      return state
        .set('isLoadingDetail', false)
        .set('card', fromJS(action.card))
        .set('request', fromJS(action.request))
        .set('approvalInfo', fromJS(action.approvalInfo));
    case actionTypes.GET_REQUEST_DETAIL_ERROR:
      return state.set('isLoadingDetail', false);
    case actionTypes.NUDGE_REQUEST_START:
      return state.set('isNudging', true);
    case actionTypes.NUDGE_REQUEST_SUCCESS:
      return state.set('isNudging', false).set('card', fromJS(action.card));
    case actionTypes.NUDGE_REQUEST_ERROR:
      return state.set('isNudging', false);
    case actionTypes.SET_APPROVAL_INFO:
      return state.set('approvalInfo', fromJS(action.approvalInfo));
    default:
      return state;
  }
};

export default requestsReducer;
