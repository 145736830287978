import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dimmer, List, Loader } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';

// Block own controller
import BundleSelectorController from './BundleSelectorController';

// atoms
import SelectBundleListItem from '../../atoms/SelectBundleListItem';
// Molecules
import MessageEmptyState from '../../molecules/MessageEmptyState';

// Globals
import TypeBundleSelect, {
  TypeDefaults,
} from '../../../globals/blocks/TypeBundleSelect';
import JobRoleError from '../../../globals/JobRoleError';
// Util
import injectIntlToController from '../../../utils/injectIntlToController';
import BodyClassName from '../../../../globals/BodyClassName';

const bundleShape = ImmutablePropTypes.mapContains({
  id: PropTypes.number,
  images: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      caption: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      ordering: PropTypes.number,
      thumbnail: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  tags: PropTypes.string,
});

class BundleSelect02 extends Component {
  static propTypes = {
    ...TypeBundleSelect,
    bundles: ImmutablePropTypes.listOf(bundleShape),
    bundlesByTagLoaded: ImmutablePropTypes.map,
    currentUser: ImmutablePropTypes.map.isRequired,
    errorMessage: PropTypes.string,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired, // injectIntlToController opt out intlShape, explicit definition instead
    isLoadingBundle: PropTypes.bool,
    loadBundles: PropTypes.func.isRequired,
    managerSelect: PropTypes.bool,
    selectBundle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
    bundles: null,
    bundlesByTagLoaded: Immutable.Map(),
    errorMessage: null,
    isLoadingBundle: false,
    managerSelect: false,
  };

  componentDidMount() {
    const { managerSelect, loadBundles } = this.props;

    const errors = this.getErrorsBundle();

    if (!errors.job_role && !errors.location) {
      loadBundles('parent', managerSelect);
    }
  }

  didSelectBundle = false;

  handleSelectBundle = (bundleID) => {
    // we don't want this.props.selectBundle to be called more than once
    if (this.didSelectBundle) {
      return;
    }

    this.didSelectBundle = true;

    const { selectBundle } = this.props;
    selectBundle(bundleID);
  };

  getErrorsBundle = () => {
    const {
      bundles,
      bundlesByTagLoaded,
      currentUser,
      errorMessage,
      isLoadingBundle,
    } = this.props;

    const curentUserJobRole = currentUser.get('job_role');
    const curentUserJobRoleID = currentUser.get('job_role_id');
    const curentUserLocation = currentUser.get('location');
    const curentUserLocationID = currentUser.get('location_id');

    return {
      bundle: Boolean(
        !isLoadingBundle &&
          (!bundles || bundles.isEmpty()) &&
          bundlesByTagLoaded.get('parent')
      ),
      job_role: !curentUserJobRole && !curentUserJobRoleID,
      location: !curentUserLocation && !curentUserLocationID,
      noBundleAssociate:
        errorMessage && errorMessage === JobRoleError.bundle_missing,
    };
  };

  renderErrorMsg = () => {
    const { intl } = this.props;

    const errors = this.getErrorsBundle();

    if (
      errors.job_role ||
      errors.location ||
      errors.bundle ||
      errors.noBundleAssociate
    ) {
      // no_job_role_assign
      const headerMessageError = errors.job_role
        ? intl.formatMessage({
            id: 'message.no_job_role_assign',
          })
        : errors.noBundleAssociate
        ? intl.formatMessage({
            id: 'message.no_bundle_with_job_role',
          })
        : errors.location
        ? intl.formatMessage({
            id: 'message.no_location_assign',
          })
        : intl.formatMessage({
            id: 'message.no_available_bundles',
          });

      return (
        <MessageEmptyState
          content={headerMessageError}
          header='Please contact your manager'
          negative
        />
      );
    }

    return null;
  };

  render() {
    const { bundles, isLoadingBundle, template, type } = this.props;

    const errors = this.getErrorsBundle();

    const loader = isLoadingBundle ? (
      <Dimmer active inverted>
        <Loader size='large' />
      </Dimmer>
    ) : null;

    return (
      <BodyClassName className='blocks-top'>
        <div
          className='block esp-catalog-detail'
          data-blocktype={type}
          data-template={template}
        >
          {loader}

          {this.renderErrorMsg()}

          {bundles &&
            !bundles.isEmpty() &&
            !errors.job_role &&
            !errors.location && (
              <List divided relaxed='very'>
                {bundles.map((bundle) => (
                  <SelectBundleListItem
                    bundle={bundle}
                    key={bundle.get('id')}
                    onClick={this.handleSelectBundle}
                  />
                ))}
              </List>
            )}
        </div>
      </BodyClassName>
    );
  }
}
const BundleSelect02Test = BundleSelect02;

// eslint-disable-next-line no-class-assign -- DEV-1526
BundleSelect02 = BundleSelectorController(BundleSelect02)();
export { BundleSelect02Test };

export default injectIntlToController(BundleSelect02);
