const _SortFieldNames = {
  NAME: 'name',
  NEGATIVE_NAME: '-name',
  NEGATIVE_STATUS: '-status',
  NEGATIVE_SYS_DATE_UPDATED: '-user__start_date',
  PRIORITY: '-priority',
  STATUS: 'status',
  SYS_DATE_UPDATED: 'user__start_date',
};

const SortPerformanceStateOptions = (intl) => [
  {
    text: intl.formatMessage({
      id: 'label.date_start_newest',
    }),
    value: _SortFieldNames.NEGATIVE_SYS_DATE_UPDATED,
  },
  {
    text: intl.formatMessage({
      id: 'label.date_start_oldest',
    }),
    value: _SortFieldNames.SYS_DATE_UPDATED,
  },
  {
    text: intl.formatMessage({
      id: 'label.priority',
    }),
    value: _SortFieldNames.PRIORITY,
  },
  // {
  //   text  : intl.formatMessage({id: 'label.status_A_Z'}),
  //   value : _SortFieldNames.STATUS,
  // },
  // {
  //   text  : intl.formatMessage({id: 'label.status_Z_A'}),
  //   value : _SortFieldNames.NEGATIVE_STATUS,
  // },
  // {
  //   text  : intl.formatMessage({id: 'label.username_A_Z'}),
  //   value : _SortFieldNames.NAME,
  // },
  // {
  //   text  : intl.formatMessage({id: 'label.username_Z_A'}),
  //   value : _SortFieldNames.NEGATIVE_NAME,
  // },
];

export const SortPerformanceFieldNames = _SortFieldNames;

export default SortPerformanceStateOptions;
