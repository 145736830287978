import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FieldArray } from 'redux-form/immutable';
import { intl } from 'esp-util-intl';
import { Accordion, Button, Dimmer, Header, Loader } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { hasIn, isEmpty, isObject, noop } from 'lodash';

// Molecule
// import FormInputTextarea         from './FormInputTextarea';
import ResponsesFAQGroup from './ResponsesFAQGroup';
// Organism
import BlockModal from '../organisms/BlockModal';
// Blocks
import Conversation01 from '../blocks/conversation/Conversation01';
import LocationDeptRolePicker01 from '../blocks/locationDeptRolePicker/LocationDeptRolePicker01';
// Controller
import EditFAQResponseController from '../controllers/EditFAQResponseController';

import buildConditionHeader from '../../utils/buildConditionHeader';

let EditFAQResponseModal = class EditFAQResponseModal extends PureComponent {
  static propTypes = {
    addFile: PropTypes.func,
    addFromMessage: PropTypes.bool,
    addNewWithModal: PropTypes.bool,
    addResponses: PropTypes.oneOfType([
      ImmutablePropTypes.list,
      ImmutablePropTypes.map,
    ]),
    answerEID: PropTypes.string,
    change: PropTypes.func,
    editLocDeptRole: ImmutablePropTypes.map,
    group: PropTypes.shape({
      activeResponsesLines: PropTypes.arrayOf(PropTypes.bool),
      isProtectedResponsesLines: PropTypes.arrayOf(PropTypes.bool),
      locationdeptrole: PropTypes.string,
      mainAnswerisProtected: PropTypes.bool,
      realLocationdeptrole: ImmutablePropTypes.list,
      responses: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, ImmutablePropTypes.map])
      ),
    }),
    imageNewFaq: ImmutablePropTypes.map,

    isLoadingImage: PropTypes.bool,
    mountNodeSelector: PropTypes.string,
    nonEmptyLineRequired: PropTypes.bool,
    onEdit: PropTypes.func,
    query: PropTypes.shape({
      taskId: PropTypes.string,
      title: PropTypes.string,
    }),
    responsesFaq: ImmutablePropTypes.list,
    showAddResponse: PropTypes.bool,
    trigger: PropTypes.node,
  };

  static defaultProps = {
    addFile: noop,
    addFromMessage: false,
    addNewWithModal: false,
    addResponses: Immutable.List(),
    answerEID: null,
    change: noop,
    editLocDeptRole: null,
    group: {},
    imageNewFaq: Immutable.Map,
    isLoadingImage: false,
    mountNodeSelector: null,
    nonEmptyLineRequired: false,
    onEdit: noop,
    query: {},
    responsesFaq: Immutable.List(),
    showAddResponse: false,
    trigger: null,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix bad implementation
    activeResponsesLines: this.props.group.activeResponsesLines,

    editLocationDeptRole: false,

    error: {},

    isDirty: false,

    isOpen: false,
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { change, group } = this.props;
    const { isOpen } = this.state;

    if (nextState.isOpen && !isOpen) {
      // Pass to the locdeptrole to the real location obj
      const locationdeptrole = group.realLocationdeptrole.map((loc) => {
        if (loc.size) {
          return loc.map((l) =>
            l.get('id') && !isNaN(Number(l.get('id')))
              ? l.set('id', Number(l.get('id')))
              : l
          );
        }
        return loc;
      });
      change(
        'editLocDeptRole',
        Immutable.fromJS({
          0: {
            locationdeptrole,
          },
        })
      );
    }
  }

  handleOnToggleActive = (index) => {
    const { activeResponsesLines } = this.state;
    const newActiveResponsesLines = Object.assign([], activeResponsesLines);
    newActiveResponsesLines[index] = !newActiveResponsesLines[index];
    this.setState(
      {
        activeResponsesLines: newActiveResponsesLines,
      },
      () => {
        this.setFormAsDirty();
      }
    );
  };

  handleUpdateCharLength = (i, event, newValue) => {
    const { nonEmptyLineRequired } = this.props;
    this.setFormAsDirty();
    if (nonEmptyLineRequired) {
      this.setFormAsDirty();
      // Check if we have to set an error for the character minimum value
      this.checknonEmptyLineRequired(i, newValue.length);
    }
  };

  checknonEmptyLineRequired = (i, charLength) => {
    const { error } = this.state;

    const errors = error;

    if (charLength < 1 && !error[i]) {
      errors[i] = intl.formatMessage({
        id: 'label.field_can_not_empty',
      });
      this.setState({
        error: errors,
      });
    } else {
      delete errors[i];
      this.setState({
        error: errors,
      });
    }
  };

  checkErrorField = (fields) => {
    const { nonEmptyLineRequired } = this.props;

    if (nonEmptyLineRequired) {
      const allFields = fields.getAll();
      const newStateError = {};
      allFields.forEach((field, i) => {
        if (!field) {
          newStateError[i] = intl.formatMessage({
            id: 'label.field_can_not_empty',
          });
        }
      });

      this.setState({
        error: newStateError,
      });
    }
  };

  setBlockModal = (block) => {
    this.refModal = block;
  };

  handleOnEdit = (e) => {
    e.preventDefault();
    const { group } = this.props;
    this.setState({
      activeResponsesLines: group.activeResponsesLines,
      isOpen: true,
    });
  };

  handleOnChange = () => {};

  handleClick = (e) => {
    e.preventDefault();
    this.setState((previousState) => ({
      editLocationDeptRole: !previousState.editLocationDeptRole,
    }));
  };

  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isDirty: false,
      isOpen: false,
    });
  };

  createNewEntry = (response, eid) => {
    const { editLocDeptRole } = this.props;

    let isImage = false,
      resp = response;

    if (
      (isObject(resp) && hasIn(resp, 'url')) ||
      (isObject(resp) && hasIn(resp, 'name')) ||
      (!isObject(resp) &&
        Boolean(
          !resp.match(/[(]http/) &&
            (resp.match('http://') || resp.match('https://'))
        ))
    ) {
      isImage = true;

      if (isObject(resp) && resp.url) {
        resp = resp.url;
      }
    }

    const finalResponse =
      Immutable.Map.isMap(resp) && resp.has('name') ? resp.get('name') : resp;

    return Immutable.fromJS({
      eid,
      locationdeptrole:
        editLocDeptRole && editLocDeptRole.getIn(['0', 'locationdeptrole']),
      response: isImage && finalResponse.url ? '' : finalResponse,
      url: isImage ? finalResponse : '',
    });
  };

  handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      addResponses,
      addNewWithModal,
      editLocDeptRole,
      group,
      onEdit,
      responsesFaq,
    } = this.props;
    const { activeResponsesLines } = this.state;

    const newResponsesFaq = responsesFaq;

    let newResp = newResponsesFaq.map((resp, index) => {
      // Check if exists in addResponses;
      let isImage = false,
        response = resp;

      if (
        (isObject(resp) && hasIn(resp, 'url')) ||
        (isObject(resp) && hasIn(resp, 'src')) ||
        (!isObject(resp) &&
          Boolean(resp.match('http://') || resp.match('https://')))
      ) {
        isImage = true;
        response = Immutable.Map.isMap(resp) ? resp.get('url') : resp;
      }

      let addRespFound;
      if (isImage) {
        addRespFound = addResponses.find((res) => res.get('url') === response);
      } else {
        addRespFound = addResponses.find(
          (res) =>
            res.get('response') === response &&
            !res.get('main_answer_is_protected')
        ); // we can't add responses to active now, so let's discard this
      }

      if (addRespFound && !addRespFound.isEmpty()) {
        return addRespFound
          .set(isImage ? 'url' : 'response', response)

          .set('active', activeResponsesLines[index])
          .set('is_protected', group.isProtectedResponsesLines[index])
          .set(isImage ? 'response' : 'url', null)
          .set(
            'locationdeptrole',
            editLocDeptRole && editLocDeptRole.getIn(['0', 'locationdeptrole'])
          );
      }

      const eidOfLine = isObject(resp) ? resp.eid : null;

      return this.createNewEntry(response, eidOfLine);
    });

    // console.log('newResp before appending others', newResp.toJS());
    // console.log('addResponses', addResponses.toJS());

    // If adding a *new* group, merge the responses of any possible existing group
    // with the same condition
    // Checking same location
    if (group.realLocationdeptrole && addNewWithModal) {
      addResponses.forEach((res) => {
        const cond1 = buildConditionHeader(
          res.get('locationdeptrole')
        ).locationDepartment;
        const cond2 = buildConditionHeader(
          group.realLocationdeptrole
        ).locationDepartment;
        const sameCondition = cond1 === cond2;
        const sameProtectedness =
          Boolean(group.mainAnswerisProtected) ===
          Boolean(res.get('main_answer_is_protected'));

        // We should be adding the new answer into an existing group,
        // so this return yes to actually use the same addRespFound
        if (sameCondition && sameProtectedness) {
          newResp = newResp.push(res); // apends the value at the beggining
        }
      });

      newResp = newResp.reverse(); // should put it in natural order
    }

    // console.log('newResp after', newResp.toJS());

    onEdit(newResp.toMap(), group.locationdeptrole, addNewWithModal); // Edit the value
    this.refModal && this.refModal.close(); // Close the modal
  };

  validate = (value) => {
    let errorFound;
    const { nonEmptyLineRequired } = this.props;

    if (value) {
      value.forEach((val, i) => {
        if (nonEmptyLineRequired && (!val || (val.length && val.length < 1))) {
          if (!errorFound) {
            errorFound = {};
          }
          errorFound[i] = intl.formatMessage({
            id: 'label.field_can_not_empty',
          });
        }
      });
    }
    return errorFound;
  };

  overrideAddFile = (fields, file) => {
    const { addFile, change, imageNewFaq, responsesFaq } = this.props;

    const reader = new FileReader();

    /**
     * Load the base 64 of the file
     */
    reader.onloadend = (fileResult) => {
      const fileGot = {};
      fileGot.name = file.name;
      fileGot.src = fileResult.target.result;
      file.src = fileResult.target.result;
      /**
       * Check if the first response is not empty
       */
      const first = responsesFaq.first();
      if (!first) {
        change('responsesFaq.0', fileGot); // Change the first entry to this new file value
      } else {
        fields.push(fileGot); // Push a new entry
      }

      /**
       * Pass the blob data of this file in our reducer
       */
      addFile(imageNewFaq.set(file.name, file));
    };

    reader.readAsDataURL(file);
  };

  setFormAsDirty = () => {
    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        isDirty: true,
      });
    }
  };

  render() {
    const {
      addFromMessage,
      answerEID,
      group,

      isLoadingImage,
      mountNodeSelector,
      query,
      showAddResponse,
      trigger,
      responsesFaq,
    } = this.props;

    const { isDirty, activeResponsesLines, error } = this.state;

    const style = {
      border: 'none',
      fontSize: '16px',
    };

    const modalActionsEdit = (
      <Button
        content='Save'
        disabled={
          isLoadingImage ||
          !isEmpty(error) ||
          !isDirty ||
          responsesFaq.size === 0
        }
        onClick={this.handleSave}
        primary
        size='large'
      />
    );

    const conversationAskReviseBlock = {
      id: '2429d4msg',
      messages: [
        {
          id: '000',
          text: "Enter a concise response and make sure it's something that users can understand",
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    const locDeptRoleBlock = {
      disableRemoteAndNewHires: true,
      id: 'editLocDeptRole',
      onChange: this.setFormAsDirty,
      pickComputer: true,
      pickDept: true,
      pickLocation: true,
      pickMobile: true,
      pickRole: true,
      template: 'locationDeptRolePicker01',
      type: 'locationDeptRolePicker',
    };

    const { editLocationDeptRole, isOpen } = this.state;

    let modalTitle = 'Edit: ';
    modalTitle +=
      group.locationdeptrole.length > 30
        ? `${group.locationdeptrole.slice(0, 30)}...`
        : group.locationdeptrole;

    const override = showAddResponse ? this.overrideAddFile : void 0;

    return (
      <BlockModal
        modalActions={modalActionsEdit}
        modalTitle={modalTitle}
        mountNodeSelector={mountNodeSelector || void 0}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        ref={this.setBlockModal}
        trigger={
          trigger || (
            <Button
              basic
              floated='right'
              icon='pencil'
              onClick={this.handleOnEdit}
              style={style}
            />
          )
        }
      >
        <div className='esp-blocks'>
          {isLoadingImage ? (
            <Dimmer active inverted>
              <Loader size='large' />
            </Dimmer>
          ) : null}
          <div className='block'>
            {isOpen ? <Conversation01 {...conversationAskReviseBlock} /> : null}
          </div>
          <div className='block field'>
            <label>
              {intl.formatMessage({
                id: 'label.answer_applies_to',
              })}
            </label>
            <Accordion exclusive={false} fluid>
              <Accordion.Title
                active={editLocationDeptRole}
                onClick={this.handleClick}
              >
                <Header
                  as='h5'
                  content={group.locationdeptrole}
                  dividing
                  icon='dropdown'
                />
              </Accordion.Title>

              <Accordion.Content active={editLocationDeptRole}>
                <LocationDeptRolePicker01 {...locDeptRoleBlock} />
              </Accordion.Content>
            </Accordion>
          </div>
          <div className='block field'>
            <label>
              {intl.formatMessage({
                id: 'label.faq_answer',
              })}
            </label>
            <FieldArray
              activeResponsesLines={activeResponsesLines}
              answerEID={answerEID}
              checkErrorField={this.checkErrorField}
              checkMessage={addFromMessage}
              component={ResponsesFAQGroup}
              errors={error}
              isFromKB={Boolean(query.title)}
              isProtectedResponsesLines={group.isProtectedResponsesLines}
              name='responsesFaq'
              onChange={this.handleUpdateCharLength}
              onToggleActive={this.handleOnToggleActive}
              overrideAddFile={override}
              responses={group.responses}
              setFormAsDirty={this.setFormAsDirty}
              taskId={query.taskId ? Number(query.taskId) : null}
              validate={this.validate}
            />
          </div>
        </div>
      </BlockModal>
    );
  }
};

const EditFAQResponseModalTest = EditFAQResponseModal;

EditFAQResponseModal = EditFAQResponseController(EditFAQResponseModal);
export { EditFAQResponseModalTest };
export default EditFAQResponseModal;
