import moment from 'moment';
import {
  INPUT_DATE_FORMAT,
  INPUT_DATE_TIME_FORMAT,
  INPUT_TIME_FORMAT,
} from './defaultInputFormats';

const BROWSER_LANGUAGE = navigator.language;
const dateParser = (date) => date.replace(/[-]/g, '/', '/');
class EspDateUtil {
  /**
   * @param date {UTC string}
   */
  constructor(date, format = null, locale = BROWSER_LANGUAGE) {
    this.setNow(locale);
    this.setDate(date, format, locale);
  }

  getDate() {
    return this.date.toISOString();
  }
  setDate(date, format = null, locale = BROWSER_LANGUAGE) {
    // when date and format are not given, we need to create a new date by using current date
    if (!date && !format) {
      this.date = moment().clone().locale(locale);
    }
    // when date is given but format is null, we will interpret it as an ISO date or valid date value accepted by moment
    else if (date && !format) {
      // if date value is valid, moment will recognize it and create date
      if (moment(date).isValid()) {
        this.date = moment(date).locale(locale);
      } else {
        throw new Error('EspDateUtil requires a valid date');
      }
    } else {
      // Need to replace all - by / to make the date in safari / iOS working
      this.datePassed = date
        ? dateParser(date)
        : moment().clone().locale(locale);
      this.date = moment(this.datePassed, format).locale(locale);
    }
    if (!this.date.isValid()) {
      throw new Error('invalid date');
    }
  }
  getShortDate() {
    return this.date.format(INPUT_DATE_FORMAT);
  }
  getNow() {
    return this.now.toISOString();
  }

  getMaxUtcDateTime() {
    return moment().endOf('day').utc().format(INPUT_DATE_TIME_FORMAT);
  }

  getMaxUtcDate() {
    return moment().endOf('day').utc().format(INPUT_DATE_FORMAT);
  }

  getShortNow() {
    return this.now.format(INPUT_DATE_FORMAT);
  }
  setNow(locale = BROWSER_LANGUAGE) {
    this.now = moment().clone().locale(locale);
  }
  getDatePassed() {
    return this.datePassed;
  }

  getStandardDateFormat() {
    return this.date.toDate().toLocaleDateString();
  }

  getStandardDateFormatWithHours(customHoursFormat) {
    return `${this.date.toDate().toLocaleDateString()} ${this.date.format(
      customHoursFormat ? customHoursFormat : 'LT'
    )}`;
  }

  isToday(dateToCompare = null, format = INPUT_DATE_FORMAT) {
    return moment(this.getShortDate()).isSame(
      EspDateUtil.getShortDate(dateToCompare, format)
    );
  }

  isSame(dateToCompare = null, format = INPUT_DATE_FORMAT) {
    return this.date.isSame(moment(dateToCompare, format));
  }

  isAfter(dateToCompare = null, format = null) {
    return moment(this.getShortDate()).isAfter(
      EspDateUtil.getShortDate(dateToCompare, format)
    );
  }

  isBefore(dateToCompare = null, format = null) {
    return moment(this.getShortDate()).isBefore(
      EspDateUtil.getShortDate(dateToCompare, format)
    );
  }

  addDays(days = 0, format = INPUT_DATE_FORMAT) {
    if (days) {
      return (this.date = this.date.add(days, 'days').format(format));
    }
    return this.date.format(format);
  }

  subtractDays(days = 0, format = INPUT_DATE_FORMAT) {
    if (days) {
      return (this.date = this.date.subtract(days, 'days')).format(format);
    }
    return this.date.format(format);
  }

  generateHoursArray(_hour = null) {
    const hours = [];
    const currentHour = _hour || this._getTimeZoneParsed();
    // we add plus one hour because we want to exclude current hour
    for (let hour = Number(currentHour) + 1; hour <= 23; hour++) {
      let time = null;
      if (hour > 12) {
        time = `${hour - 12} PM`;
      } else {
        time = `${hour} AM`;
      }
      if (hour === 0 || hour === 24) {
        time = '12 AM';
      }
      if (hour === 12) {
        time = '12 PM';
      }
      hours.push(time);
    }
    return hours;
  }

  getOptionsMap() {
    return this.generateHoursArray().map((i) => ({
      key: i,
      text: i,
      value: i,
    }));
  }

  getLocalizedLongDateFormat() {
    if (moment.localeData(BROWSER_LANGUAGE)) {
      return moment
        .localeData(BROWSER_LANGUAGE)
        ._longDateFormat.L.split('/')
        .join('-');
    } else {
      return moment.localeData()._longDateFormat.L.split('/').join('-');
    }
  }
}

EspDateUtil.INPUT_DATE_FORMAT = INPUT_DATE_FORMAT;

EspDateUtil.isValid = (date) => {
  const newDate = moment(date);
  if (!newDate.isValid()) {
    throw new Error('invalid date');
  }

  return true;
};

EspDateUtil.getShortDate = (date, format) => {
  const finalDate = date ? moment(date, format) : moment();
  if (!finalDate.isValid()) {
    throw new Error('invalid date');
  }
  return finalDate.format(INPUT_DATE_FORMAT);
};

// compare two dates to see if they are on the same month
EspDateUtil.isSameMonth = (
  date1,
  date2,
  format1 = INPUT_DATE_FORMAT,
  format2 = INPUT_DATE_FORMAT
) => moment(date1, format1).isSame(moment(date2, format2), 'month');

// This should be allowed to be used as a static method
EspDateUtil.getDateNowAsISOString = () => moment().clone().toISOString();

// returns something like ["mon","tue",...]
EspDateUtil.getWeekDayStrings = (locale = BROWSER_LANGUAGE) =>
  [1, 2, 3, 4, 5, 6, 0].map((weekDay) =>
    moment().clone().locale(locale).weekday(weekDay).format('ddd')
  );

// 06/06/06 at 12:00PM or 06/06/06 at 12:00 (it depends on user preferences)
EspDateUtil.getDateWithHours = (
  date,
  format = null,
  locale = BROWSER_LANGUAGE
) =>
  `${moment(date, format).locale(locale).format('L')} at ${moment(date, format)
    .locale(locale)
    .format('LT')}`;

EspDateUtil.getUTCDateWithHours = (utcDate, locale = BROWSER_LANGUAGE) =>
  `${moment.utc(utcDate).toDate().toLocaleDateString()} ${moment
    .utc(utcDate)
    .local()
    .locale(locale)
    .format('LT')}`;

EspDateUtil.getCurrentStandardTime = (utcDate, locale = BROWSER_LANGUAGE) =>
  moment().format(INPUT_TIME_FORMAT);

EspDateUtil.getLocalizedDate = (date, locale = BROWSER_LANGUAGE) =>
  moment(date).locale(locale);

// Interface for https://momentjs.com/docs/#/displaying/from/
// I need to use it as a static function, as I want to have control over when it's "now"
EspDateUtil.getTimeAgo = (start, end) =>
  EspDateUtil.getLocalizedDate(end).from(start);

EspDateUtil.subtractDays = (date, value, unit) =>
  moment(date, INPUT_DATE_FORMAT)
    .subtract(value, unit)
    .format(INPUT_DATE_FORMAT);

EspDateUtil.sumDays = (date, value, unit, format = INPUT_DATE_FORMAT) =>
  moment(date, format).add(value, unit).format(INPUT_DATE_FORMAT);

EspDateUtil.humanizeSeconds = (seconds = 0) =>
  moment.duration(seconds, 'seconds').locale(BROWSER_LANGUAGE).humanize();

EspDateUtil.checkIsAfter = (date1, date2) =>
  EspDateUtil.getLocalizedDate(date1).isAfter(date2);
EspDateUtil.getDiffInSeconds = (date1, date2) =>
  EspDateUtil.getLocalizedDate(date1).diff(date2, 'seconds');
EspDateUtil.getDiffInDays = (date1, date2) =>
  EspDateUtil.getLocalizedDate(date1).diff(
    EspDateUtil.getLocalizedDate(date2),
    'days'
  );

export default EspDateUtil;
