const DeviceType = {
  computer: ['Macintosh', 'Windows 10', 'Windows 11', 'Other'],
  devices: [
    'Mac Computer',
    'Windows Computer',
    'Android Phone',
    'Apple Phone',
    'Chromebook',
  ],
  mobile: ['iOS', 'Android', 'Other'],
};

const DeviceOptionList = {
  computer: [],
  mobile: [],
};

const devicesEID = [
  '7cde217e-3dda-4130-be64-127115e3577f',
  'b92b78ac-e2e4-4f4a-9cb0-751ea79e2ffd',
  '17acb99c-16c8-4f25-9402-1a03134a4cf2',
  '198da043-1439-461b-b206-98e6459a9b99',
  '72a28d58-a7c8-4572-8989-e7bfa48e5b38',
];

DeviceOptionList.computer = DeviceType.computer.map((comp) => ({
  key: comp,
  text: comp,
  value: comp,
}));

DeviceOptionList.mobile = DeviceType.mobile.map((mob) => ({
  key: mob,
  text: mob,
  value: mob,
}));
DeviceOptionList.devices = DeviceType.devices.map((os, i) => {
  return {
    key: devicesEID[i],
    text: os,
    value: os,
  };
});

export const DeviceOption = DeviceOptionList;

export const devicesCollection = DeviceOptionList.devices;

export default DeviceType;
