import React from 'react';
import useNavBackCB from '../../hooks/useNavBackCB';

const BackButton = () => {
  const handleBack = useNavBackCB();
  return (
    <button
      aria-label='Back'
      className='ui basic button'
      onClick={handleBack}
      type='button'
    >
      ‹
    </button>
  );
};

export default BackButton;
