import React from 'react';
import KbLink from './components/KbLink';

/**
 * @typedef {Record<string, React.ReactElement | ((props) => React.ReactElement)>} CustomComponents - Custom components to be used in the markdown rendering.
 */

/**
 * @type {CustomComponents}
 */
const customComponents = {
  a: KbLink,
  code: ({ children }) => <code className='hljs'>{children}</code>,
  li: ({ children }) => <li className='mkdown-list-disc'>{children}</li>,
};

export default customComponents;
