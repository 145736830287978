import { fromJS } from 'immutable';
// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import caseMgmtThunks from '../../actions/caseMgmtThunks';
// Actions
import caseMgmtActions from '../../actions/caseMgmtActions';
import toastNotificationsActions from '../../actions/toastNotificationsActions';
// Selector
import getServiceDepartmentTeamList from '../../selectors/getServiceDepartmentTeamList';

// Limit of Team item per page
const limitByPage = 24;

const mapStateToProps = (state) => {
  const serviceDepartmentTeam = state.hasIn([
    'caseMgmt',
    'serviceDepartmentTeam',
  ])
    ? state.getIn(['caseMgmt', 'serviceDepartmentTeam'])
    : fromJS({});

  const teamList = getServiceDepartmentTeamList(state);

  return {
    isLoadingTeamList: serviceDepartmentTeam.get('isLoading'),
    isSearching: serviceDepartmentTeam.get('isSearching'),
    limitByPage,
    listCount: serviceDepartmentTeam.get('count'),
    loadingDeleteID: serviceDepartmentTeam.get('loadingDeleteID'),
    searchTerm: serviceDepartmentTeam.get('searchTerm'),
    teamList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteOneTeam(teamID, msg) {
    dispatch(caseMgmtThunks.deleteOneTeam(teamID)).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: msg.message,
          title: msg.header,
        })
      );
    });
  },

  loadTeamList(
    serviceDepartmentID,
    page = 1,
    limit = limitByPage,
    orderBy = '-sys_date_created'
  ) {
    const offset = (page - 1) * limit;
    dispatch(
      caseMgmtThunks.LoadTeamByDepartment({
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        serviceDepartmentID,
      })
    );
  },

  resetDepartmentTeamListSearch() {
    dispatch(caseMgmtActions.resetDepartmentTeamListSearch());
  },

  searchTeam(
    serviceDepartmentID,
    page = 1,
    limit = limitByPage,
    orderBy = '-sys_date_updated'
  ) {
    // Offset logic
    const offset = (page - 1) * limit;

    dispatch(
      caseMgmtThunks.searchTeamTerm({
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        serviceDepartmentID,
      })
    );
  },

  setSearchTerm(term) {
    dispatch(caseMgmtActions.setSearchTeamTerm(term));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
