import { change } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import workflowThunks from '../../actions/workflowThunks';
import baristaActions from '../../actions/baristaActions';

const mapStateToProps = (state) => ({
  addResponses: state.getIn(
    ['form', 'Form01', 'values', 'addResponses'],
    void 0
  ),
  editLocDeptRole: state.getIn(
    ['form', 'Form01', 'values', 'editLocDeptRole'],
    void 0
  ),
  imageNewFaq: state.getIn(['adminFaqs', 'imageNewFaq']),
  isLoadingImage: state.getIn(['adminFaqs', 'isLoadingImage']),
  responsesFaq: state.getIn(
    ['form', 'Form01', 'values', 'responsesFaq'],
    void 0
  ),
});

const mapDispatchToProps = (dispatch) => ({
  addFile(files) {
    dispatch(baristaActions.addFiletoFAQ(files));
  },
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
  setScratch(value = false) {
    dispatch(
      workflowThunks.updateFrontEndScratch({
        createNewResponse: value,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
