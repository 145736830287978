import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  changePassword: {
    /** @type {boolean} */
    isLoading: false,
  },

  /** @type {string} */
  email: null,

  passwordChangeError: '',

  passwordChanged: false,

  promptResetPassword: false,

  sendCode: {
    /** @type {boolean} */
    done: false,

    /** @type {string} */
    error: null,

    /** @type {boolean} */
    isLoading: false,
  },

  validateCode: {
    /** @type {boolean} */
    done: false,

    /** @type {string} */
    error: null,

    /** @type {boolean} */
    isLoading: false,
  },
});

const anonResetPasswordReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ANON_RESET_PASSWORD_INIT:
      return INITIAL_STATE;

    case actionTypes.ANON_RESET_PASSWORD_SEND_CODE_START:
      return state
        .set('sendCode', INITIAL_STATE.get('sendCode'))
        .setIn(['sendCode', 'isLoading'], true);

    case actionTypes.ANON_RESET_PASSWORD_SEND_CODE_SUCCESS:
      return state
        .set('email', action.email)
        .set('sendCode', INITIAL_STATE.get('sendCode'))
        .setIn(['sendCode', 'done'], true);

    case actionTypes.ANON_RESET_PASSWORD_SEND_CODE_FAIL:
      return state
        .set('sendCode', INITIAL_STATE.get('sendCode'))
        .setIn(['sendCode', 'error'], action.error);

    case actionTypes.ANON_RESET_PASSWORD_VALIDATE_CODE_START:
      return state
        .set('validateCode', INITIAL_STATE.get('validateCode'))
        .setIn(['validateCode', 'isLoading'], true);

    case actionTypes.ANON_RESET_PASSWORD_VALIDATE_CODE_SUCCESS:
      return state
        .set('validateCode', INITIAL_STATE.get('validateCode'))
        .setIn(['validateCode', 'done'], true);

    case actionTypes.ANON_RESET_PASSWORD_VALIDATE_CODE_FAIL:
      return state
        .set('validateCode', INITIAL_STATE.get('validateCode'))
        .setIn(['validateCode', 'error'], action.error);

    case actionTypes.PROMPT_RESET_PASSWORD:
      return state.set('promptResetPassword', true);

    case actionTypes.PROMPT_RESET_PASSWORD_DISMISS:
      return state.set('promptResetPassword', false);

    case actionTypes.ANON_CHANGE_PASSWORD_FAIL:
      return state
        .setIn(['changePassword', 'isLoading'], false)
        .set('passwordChangeError', action.error);

    case actionTypes.ANON_CHANGE_PASSWORD_SUCCESS:
      return state
        .setIn(['changePassword', 'isLoading'], false)
        .set('passwordChanged', true);

    case actionTypes.ANON_RESET_PASSWORD_SEND_NEW_PASSWORD_START:
      return state.setIn(['changePassword', 'isLoading'], true);

    default:
      return state;
  }
};

export default anonResetPasswordReducer;
