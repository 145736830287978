import { GraphQLClient } from 'graphql-request';
import { TokenUtil } from 'esp-util-auth';

const endpoint = 'http://tenant1.esp/api/graph/';

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    'Content-Type': 'application/json',
    authorization: `Token ${TokenUtil.getToken()}`,
  },
});

export default graphQLClient;
