import { formValueSelector } from 'redux-form/immutable';

// Actions
import workflowActions from '../../actions/workflowActions';

// Thunks
import smsThunks from '../../actions/smsThunks';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import getMetaTexts from '../../selectors/getMetaTexts';

const mapStateToProps = (state) => {
  // Get the selected OS from Redux Form workflow slice
  const workflowFormSelector = formValueSelector('Form01');
  const selectedOS = workflowFormSelector(state, 'selectedOS');

  return {
    globals: getMetaTexts(state),
    selectedOS,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSendDownloadAppSMS(content, onMoveWorkflowNextTask) {
    dispatch(workflowActions.loading());

    dispatch(smsThunks.sendSMS('', content))
      .then(() => onMoveWorkflowNextTask())
      .catch(() => dispatch(workflowActions.exitLoading()));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
