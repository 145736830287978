import React, { useCallback, useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Dropdown, Form, Segment } from 'semantic-ui-react';
import { labels, status } from './constants';
import useSnowQueries from './useSnowQueries';
import SyncWarningModal from './SyncWarningModal';
import SyncErrorMessage from './SyncErrorMessage';
import SyncInProgress from './SyncInProgress';
import SyncCompleted from './SyncCompleted';
import postBulkImportUsers from '../endpoints/postBulkImportUsers';
import checkSyncUsersStatus from '../endpoints/checkSyncUsersStatus';
import GlobalContext from '../../../../../../../globals/GlobalContext';

const IntegrationServicenowUserSync = () => {
  const { usersSettings } = useContext(GlobalContext);

  const [snowQueries, setSnowQueries, isLoadingUserSettings] =
    useSnowQueries(usersSettings);

  const [selectedValue, setSelectedValue] = useState('');
  const [query, setQuery] = useState('');
  const [isModalOpen, setModal] = useState(false);
  const [isLoadingSync, setLoadingSync] = useState(false);
  const recordNumberRef = useRef();
  const intervalRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');

  const options = Array.from(snowQueries).map((item) => ({
    key: item,
    text: item,
    value: item,
  }));

  const buttonStyle = {
    margin: 'auto 0',
    position: 'relative',
    top: 28,
  };

  const title = 'Service Now - Integrations - User Sync';

  const getRecordNumberFromMessage = (message) => {
    const regex = /\d+/;
    const [number] = message?.match(regex);
    return number || 0;
  };

  const handleAddition = useCallback((e, { searchQuery }) => {
    setQuery(searchQuery);
    setSelectedValue('');
  }, []);

  const handleDropdownChange = useCallback((e, { value }) => {
    setSelectedValue(value);
    setQuery(value);
  }, []);

  const handleRun = useCallback(() => {
    setUpdatedDate('');
    const fetchAffectedRecords = async (filterQuery, maxUserCount) => {
      recordNumberRef.current = null;
      setLoadingSync(true);
      const { body } = await postBulkImportUsers(filterQuery, maxUserCount);
      setLoadingSync(false);

      const zeroUsersAffected = body?.status_url;

      if (zeroUsersAffected) {
        setErrorMessage(
          <Segment attached>
            <p>{labels.zero_users_message_p1}</p>
            <p>{labels.zero_users_message_p2}</p>
          </Segment>
        );
      } else {
        recordNumberRef.current = getRecordNumberFromMessage(body?.message);

        if (recordNumberRef.current) {
          setModal(true);
        } else {
          setErrorMessage(body?.message);
        }
      }
    };

    const addQueryEntry = () => {
      const updatedItems = new Set(snowQueries);
      updatedItems.add(query);
      setSnowQueries(updatedItems);
    };

    const isNewQuery = !selectedValue && query;

    if (isNewQuery) {
      addQueryEntry();
      fetchAffectedRecords(query);
    } else {
      if (selectedValue) {
        fetchAffectedRecords(selectedValue);
      }
    }
  }, [query, selectedValue, setSnowQueries, snowQueries]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleRun();
      }
    },
    [handleRun]
  );

  const handleRemove = useCallback(() => {
    const removeQueryEntry = () => {
      const updatedItems = new Set(snowQueries);
      updatedItems.delete(selectedValue);

      setQuery('');
      setSnowQueries(updatedItems);
      setSelectedValue('');
    };
    removeQueryEntry();
  }, [selectedValue, setSnowQueries, snowQueries]);

  const handleCancel = useCallback(() => {
    setModal(false);
  }, []);
  const handleConfirm = useCallback(() => {
    const checkStatus = async (statusURL) => {
      const { body } = await checkSyncUsersStatus(statusURL);

      if (
        body.status === status.SCHEDULED ||
        body.status === status.IN_PROGRESS
      ) {
        setCreatedDate(body?.sys_date_created);
      }
      if (body.status === status.COMPLETED) {
        setCreatedDate();
        setUpdatedDate(body?.sys_date_updated);
        clearInterval(intervalRef.current);
        setLoadingSync(false);
      }
    };

    const syncUsers = async (filterQuery) => {
      setLoadingSync(true);
      const { body } = await postBulkImportUsers(
        filterQuery,
        recordNumberRef.current
      );

      const statusURL = body?.status_url;

      checkStatus(statusURL);

      const oneMinute = 60000; // 60 * 1000 milsec
      const tenSeconds = 10000; // 10 * 1000 milsec
      const USER_COUNT = 100;

      intervalRef.current = setInterval(
        () => {
          checkStatus(statusURL);
        },
        recordNumberRef.current > USER_COUNT ? oneMinute : tenSeconds
      );
    };

    const isNewQuery = !selectedValue && query;
    if (isNewQuery) {
      syncUsers(query);
    } else {
      if (selectedValue) {
        syncUsers(selectedValue);
      }
    }

    setModal(false);
  }, [query, selectedValue]);

  const resetMessage = useCallback(() => {
    setErrorMessage('');
  }, []);

  const isLoading = isLoadingSync || isLoadingUserSettings;

  return (
    <>
      <Helmet title={title} />
      <Segment attached>
        <SyncWarningModal
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          isModalOpen={isModalOpen}
          recordNumber={recordNumberRef.current}
        />
        <SyncErrorMessage message={errorMessage} onClose={resetMessage} />
        <Form as={'div'}>
          <Form.Group>
            <Form.Field width={14}>
              <label>{labels.filter}</label>
              <Form.Group widths={14}>
                {selectedValue && (
                  <Button
                    basic
                    disabled={isLoading}
                    icon={{ name: 'remove' }}
                    onClick={handleRemove}
                    type='button'
                    width={2}
                  />
                )}

                <Dropdown
                  clearable
                  disabled={isLoading}
                  fluid
                  onChange={handleDropdownChange}
                  onKeyDown={handleKeyDown}
                  onSearchChange={handleAddition}
                  options={options}
                  placeholder={labels.placeholder}
                  search
                  searchQuery={query}
                  selection
                  tabIndex='0'
                  text={selectedValue}
                  value={selectedValue}
                />
              </Form.Group>
            </Form.Field>

            <Form.Button
              basic
              content={labels.run}
              loading={isLoading}
              onClick={handleRun}
              positive
              style={buttonStyle}
              type='button'
              width={2}
            />
          </Form.Group>
        </Form>
        {createdDate && <SyncInProgress createdDate={createdDate} />}
        {updatedDate && (
          <SyncCompleted
            countRecords={recordNumberRef.current}
            updatedDate={updatedDate}
          />
        )}
      </Segment>
    </>
  );
};

export default IntegrationServicenowUserSync;
