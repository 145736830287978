import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsTodo extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M47.5 35.8h18c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5h-18c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5zm18 7.39h-18c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h18c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm0 11.1h-18c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h18c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm-25.82-23.7l-3.87 3.86-1.9-1.89c-.59-.55-1.512-.534-2.083.037-.57.57-.588 1.492-.037 2.083l3 3c.586.585 1.534.585 2.12 0l4.93-4.93c.55-.59.534-1.512-.037-2.083-.57-.57-1.492-.588-2.083-.037l-.04-.04zm0 20.46l-3.87 3.87L33.92 53c-.59-.55-1.512-.534-2.083.037-.57.57-.588 1.492-.037 2.083l3 3c.586.585 1.534.585 2.12 0l4.93-4.93c.55-.59.534-1.512-.037-2.083-.57-.57-1.492-.588-2.083-.037l-.05-.02zm0-10.37l-3.87 3.87-1.9-1.9c-.59-.55-1.512-.534-2.083.037-.57.57-.588 1.492-.037 2.083l3 3c.586.585 1.534.585 2.12 0l4.89-4.97c.55-.59.534-1.512-.037-2.083-.57-.57-1.492-.588-2.083-.037z' />
          <path d='M47.79 18.06c-17.76 0-32.21 12-32.21 26.85 0 9.7 6.16 15.37 11.91 18.94-1.286 2.578-3.07 4.876-5.25 6.76-1.348 1.063-1.867 2.87-1.29 4.486.578 1.617 2.123 2.685 3.84 2.654 7.46 0 14.87-4.38 18.08-6.55 1.606.414 3.262.606 4.92.57 17.76 0 32.21-12 32.21-26.85 0-14.85-14.45-26.86-32.21-26.86zm0 49.68c-1.266.034-2.53-.1-3.76-.4-1.21-.02-2.41.205-3.53.66-2.88 1.93-9.38 5.77-15.69 5.77 0 0 5.29-4.35 7-10-.254-1.233-.93-2.34-1.91-3.13-6.25-3.9-10.29-8.64-10.29-15.72 0-12.61 12.62-22.83 28.18-22.83s28.18 10.22 28.18 22.83c0 12.61-12.61 22.82-28.18 22.82z' />
        </g>
      </svg>
    );
  }
}

export default CardsTodo;
