import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import sessionThunks from '../../actions/sessionThunks';

const mapStateToProps = (state, ownprops) => {
  const { location } = ownprops;
  const { pathname } = location;
  let query = '';
  let isOAuth = false;
  let isSAML = false;

  // getting query param
  if (location) {
    query = location.search || '';
    // Removing starting ?
    query = query.substr(1);
  }

  // veryfing what kind of path is using
  if (pathname === '/auth/oauth') {
    isOAuth = true;
  }
  // Don't move this logic, as this is not a binary option (there's still /auth/inactive and possibly others)
  else if (pathname === '/auth/saml') {
    isSAML = true;
  }

  return {
    error: state.getIn(['session', 'auth_error_msg']),
    isOAuth: isOAuth,
    isSAML: isSAML,
    queryParams: query,
    tokenRetrievalStarted: state.getIn(['session', 'tokenRetrievalStarted']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateWithOAuth: (queryParams) => {
    dispatch(sessionThunks.authenticateWithOAuth(queryParams));
  },

  authenticateWithSAML: () => {
    dispatch(sessionThunks.authenticateWithSAML());
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
