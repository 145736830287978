import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { Container, Header, Message } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { Boundaries } from 'cascara-middleware';

// Internal
import { fetchKb } from './apiCalls';
import InternalFaqKbRender from './InternalFaqKbRender';
import KbTenantHeader from './KBTenantHeader';

import IFrameModal from '../../../../v2/components/display/IFrameModal';
import baristaThunks from '../../../actions/baristaThunks';
import store from '../../../stores/store';
const propTypes = {
  location: pt.shape({
    query: pt.shape({
      // eid in the url query parameter, e.g. http://<tenant_id>.espressive.com/kbview?eid=<faq_kb_eid>
      eid: pt.string,
    }),
  }).isRequired,
};

/**
 *  Standalone Kb Article viewer Page
 *  This works as a page so it depends on URL query parameters
 * @param {*} props
 * @returns
 */
const KbViewPage = ({ location }) => {
  const [kbArticleContent, setKbArticleContent] = useState('');
  const [faqTitle, setFaqTitle] = useState('');
  const [errorMsg, setErrorMessage] = useState('');

  useEffect(() => {
    // Case by ESP KB eid (responses) in query params
    if (location?.query?.eid) {
      fetchKb(location.query.eid)
        .then(({ body }) => {
          const { plain_text, title } = body;
          setKbArticleContent(plain_text);
          setFaqTitle(title);
          setErrorMessage('');
        })
        .catch((e) => {
          setErrorMessage(e?.message);
        });
    }

    // Case by specific type of article ids
    const getUrlParameter = (name) => {
      const urlParams = new URLSearchParams(location.query);
      return urlParams.get(name);
    };

    // List of supported parameters
    // See baristaThunks.openKbArticle
    const parameterNames = [
      'sys_id',
      'number',
      'confluenceId',
      'responseEid',
      'zendeskId',
      'sharepointId',
      'ivantiId',
    ];
    const isArticleIdSupported = parameterNames
      .map(getUrlParameter)
      .find((value) => value !== null);

    if (isArticleIdSupported) {
      // ok yeah this is kinda silly to use redux
      // but the logic in the thunk is complex and touches many places
      // just to make iFrameModal work
      store.dispatch(baristaThunks.openKbArticle(location.query));
    }
  }, [location.query]);

  // Legacy KB
  if (location?.query?.eid) {
    return (
      <Boundaries>
        <Helmet title={faqTitle} />
        <KbTenantHeader />
        <main style={{ padding: '1rem 0' }}>
          <Container>
            {errorMsg ? (
              <Message content={errorMsg} header='Error' negative />
            ) : null}
            <Header as='h2' content={faqTitle} />
            <InternalFaqKbRender content={kbArticleContent} />
          </Container>
        </main>
      </Boundaries>
    );
  } else {
    return <IFrameModal location={location} />;
  }
};

KbViewPage.propTypes = propTypes;

export default KbViewPage;
