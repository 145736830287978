const api_surveys_v0_1_surveys = {
  results: [
    {
      description: null,
      eid: '4838054c-d543-4ed1-8b26-8286e521dbf0',
      identifier: 'support-rating',
      name: 'Support Rating',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2018-06-25T20:49:36.130231Z',
      sys_date_updated: '2018-06-25T20:49:36.130190Z',
      sys_updated_by: null,
    },
    {
      description: 'Keeps track of the answer on FAQs feedback forms',
      eid: '6b67ef44-7335-4ae4-83de-64577cb12891',
      identifier: 'faq-feedback',
      name: 'FAQ Feedback',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2019-02-06T21:35:06.597980Z',
      sys_date_updated: '2019-02-06T21:35:06.597944Z',
      sys_updated_by: null,
    },
    {
      description:
        "Tracks wether a task solved a user's need when it was closed.",
      eid: 'dc03f081-6b57-4d3b-a47a-c77ff5474f67',
      identifier: 'task_resolved',
      name: 'Task Resolved',
      sys_created_by: null,
      sys_custom_fields: null,
      sys_date_created: '2019-02-06T21:35:07.445060Z',
      sys_date_updated: '2019-02-06T21:35:07.445025Z',
      sys_updated_by: null,
    },
  ],
};

export default api_surveys_v0_1_surveys;
