import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogOtherCounseling extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M75.15 74.03l-.66-15.9c-.208-1.804-1.734-3.167-3.55-3.17h-8.79l-2.9-10.74c-.21-1.728-1.404-3.176-3.06-3.71l-.04-.46c1.924-1.27 2.71-3.703 1.89-5.856-.82-2.154-3.024-3.45-5.304-3.12-2.28.33-4.027 2.2-4.2 4.497-.175 2.3 1.27 4.41 3.474 5.08l.11.67c-1.072.84-1.7 2.13-1.7 3.49v.43l.08.37c0 .09 0 .17.06.26l3 13.34c.3 2.25 2.214 3.93 4.48 3.93h.18l11.38.1.08 11.12v.23c.06 1.01.67 1.9 1.587 2.32.916.42 1.99.3 2.792-.31.8-.61 1.2-1.61 1.04-2.61l.05.04z' />
          <path d='M65.27 63.84h-8.65c-1.05-.182-2-.74-2.67-1.57-.77-.81-1.28-2.29-2.07-5.51l-.94-4.64-.54-2.73c-.378-1.293-1.5-2.23-2.84-2.37-.467 0-.92.156-1.29.44l-.12.04-.17.15c-.07.07-.133.148-.19.23l-.07.09c-.037.06-.07.125-.1.19-.038.07-.07.145-.1.22-.09.233-.136.48-.14.73v.06c0 .277.036.553.11.82l.34 2.1.13.88 3 18.59c.012.148.036.295.07.44.32 1.404 1.08 2.67 2.17 3.61.657.652 1.48 1.112 2.38 1.33h11.69c1.505-.005 2.725-1.225 2.73-2.73v-7.63c0-1.51-1.22-2.734-2.73-2.74zM42.15 30.56v-.21c-.288-2.418-2.335-4.242-4.77-4.25H33.2v-.25c2.365-.934 3.745-3.404 3.302-5.907-.442-2.504-2.586-4.35-5.127-4.417-2.542-.067-4.78 1.665-5.353 4.142-.573 2.477.676 5.015 2.988 6.072v.32h-4.1c-2.54-.048-4.67 1.906-4.84 4.44v1.17l-.06 18.37h.05c.07.956.867 1.695 1.825 1.695s1.754-.74 1.825-1.695L24 35.5h1.3l-.29 13.56V77.5h.05c.24 1.19 1.285 2.048 2.5 2.048s2.26-.857 2.5-2.048h.09V50.05h1.6v26.69h.05v.21c-.047.98.47 1.902 1.332 2.37.862.47 1.916.407 2.714-.164.8-.57 1.2-1.55 1.034-2.516V46.87l.09-11.24h1.28l.06 13.78v.09c.07.96.87 1.704 1.835 1.704.964 0 1.764-.743 1.835-1.704v-.27l.17-18.3v-.37z' />
        </g>
      </svg>
    );
  }
}

export default CatalogOtherCounseling;
