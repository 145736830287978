import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';
import ChannelTypes from '../globals/ChannelTypes';

const INITIAL_STATE = fromJS({
  channelsStatus: {
    [ChannelTypes.DIRECT_CHANNEL]: {
      error: null,
      isLoading: false,
      isLoadingDone: false,
    },
    [ChannelTypes.SCOPED_CHANNEL]: {
      error: null,
      isLoading: false,
      isLoadingDone: false,
    },
  },
  feedbackIsSending: false,
  feedbackSendError: null,
  pagination: {
    [ChannelTypes.DIRECT_CHANNEL]: {},
    [ChannelTypes.SCOPED_CHANNEL]: {},
  },
  selectedConversationWithUser: null,
  unreadCountDirect: null,
  unreadCountScoped: null,
});

const socializeReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_CHANNELS_START:
      return state
        .setIn(['channelsStatus', action.channelType, 'isLoadingDone'], false)
        .setIn(['channelsStatus', action.channelType, 'isLoading'], true);

    case actionTypes.GET_CHANNELS_SUCCESS:
      if (action.reset) {
        // We don't want to reset everything here
        // only status and pagination data
        // Otherwise we lose badge count
        // and all the other stuff on filter reset
        state = state
          .set('channelsStatus', INITIAL_STATE.get('channelsStatus'))
          .set('pagination', INITIAL_STATE.get('pagination'));
      }
      return state
        .setIn(['channelsStatus', action.channelType, 'isLoading'], false)
        .setIn(['channelsStatus', action.channelType, 'isLoadingDone'], true)
        .setIn(['pagination', action.channelType], fromJS(action.pagination));

    case actionTypes.GET_CHANNELS_FAIL:
      return state
        .setIn(['channelsStatus', action.channelType, 'isLoading'], false)
        .setIn(['channelsStatus', action.channelType, 'isLoadingDone'], true)
        .setIn(['channelsStatus', action.channelType, 'error'], action.error);

    case actionTypes.UPDATE_UNREAD_COUNT_SCOPED_CHANNELS:
      return state.set('unreadCountScoped', action.count);

    case actionTypes.UPDATE_UNREAD_COUNT_DIRECT_CHANNELS:
      return state.set('unreadCountDirect', action.count);

    case actionTypes.USER_SEND_FEEDBACK_START:
      return state
        .set('feedbackIsSending', true)
        .set('feedbackSendError', null);

    case actionTypes.USER_SEND_FEEDBACK_SUCCESS:
      return state
        .set('feedbackIsSending', false)
        .set('feedbackSendError', null);

    case actionTypes.USER_SEND_FEEDBACK_FAIL:
      return state
        .set('feedbackIsSending', false)
        .set('feedbackSendError', action.error);

    default:
      return state;
  }
};

export default socializeReducer;
