import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  charLimit: {
    default: 0,
    type: 'number', // 0 means no limit
  },
  disableInitializeInput: {
    default: false,
    type: 'bool',
  },
  inputPattern: {
    default: '',
    type: 'string',
  },
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputType: {
    required: true,
    restrictToOptions: ['date', 'email', 'number', 'password', 'tel', 'text'],
    type: 'string',
  },
  inputValue: {
    objectShape: {
      map: {
        required: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  labelText: {
    required: true,
    type: 'string',
  },
  placeholder: {
    default: '',
    required: false,
    type: 'string',
  },
  readOnly: {
    default: false,
    type: 'bool',
  },
});

// Object Definition used by blobifier
const FieldBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Field',
  templates: ['field_1', 'copyField01', 'field02', 'field03'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, FieldBlockDefinition, TypeDefaults };
