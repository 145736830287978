import { change, getFormValues } from 'redux-form/immutable';

// Util
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => ({
  formValues: getFormValues('Form01')(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeValue: (value, id) => {
    let updatedValue = value;
    if (value === 'true') {
      updatedValue = Boolean(value);
    } else if (value === 'false') {
      updatedValue = !value;
    }
    dispatch(change('Form01', id, updatedValue));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
