import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  avatarImage: {
    default: false,
    type: 'bool',
  },
  headline: {
    required: true,
    type: 'string',
  },
  image: {
    default: '',
    type: 'string',
  },
  imageSize: {
    default: 'medium',
    restrictToOptions: [
      'mini',
      'tiny',
      'small',
      'medium',
      'large',
      'big',
      'huge',
      'massive',
    ],
    type: 'string',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  template: {
    default: 'hero_1',
  },
});

// Object Definition used by blobifier
const HeroBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Hero',
  templates: ['hero_1', 'hero_2'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, HeroBlockDefinition, TypeDefaults };
