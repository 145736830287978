const ConnectionOptions = [
  {
    key: 'SFTP',
    text: 'SFTP',
    value: 'SFTP',
  },
  {
    key: 'SCP',
    text: 'SCP',
    value: 'SCP',
  },
];

export default ConnectionOptions;
