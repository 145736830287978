import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';
import { fromJS } from 'immutable';

import BundleBaristaPhraseController from '../controllers/BundleBaristaPhraseController';
// Globals
import W3BaristaPhrasing from '../../globals/W3BaristaPhrasing';
// Molecule
import ConversationFeed from './ConversationFeed';

class BundleBaristaPhrase extends PureComponent {
  static propTypes = {
    categoryIDToNameMapping: ImmutablePropTypes.map,
    isLoading: PropTypes.bool,
    item: ImmutablePropTypes.map,
    loadCategories: PropTypes.func,
  };

  static defaultProps = {
    categoryIDToNameMapping: null,
    isLoading: false,
    item: null,
    loadCategories: _.noop,
  };

  componentDidMount() {
    const { loadCategories } = this.props;

    loadCategories();
  }

  render() {
    const { categoryIDToNameMapping, isLoading, item } = this.props;
    const text =
      isLoading || !categoryIDToNameMapping
        ? 'Your selections are loading'
        : W3BaristaPhrasing.getPhrase(item, categoryIDToNameMapping);

    const conversationAskReviseBlock = {
      id: '2429d4msg',
      messages: [
        {
          id: '000',
          text: text,
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          imageUrl: '',
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    return (
      <ConversationFeed
        animated
        blockConvo
        isExpandable={false}
        messages={fromJS(conversationAskReviseBlock.messages)}
        users={fromJS(conversationAskReviseBlock.users)}
      />
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
BundleBaristaPhrase = BundleBaristaPhraseController(BundleBaristaPhrase);

export default BundleBaristaPhrase;
