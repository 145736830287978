import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsFood extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M48 9c21.54 0 39 17.46 39 39S69.54 87 48 87 9 69.54 9 48 26.46 9 48 9zm0-5C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4z' />
          <path d='M55.09 39.81c-.236.368-.656.575-1.092.54-.435-.038-.815-.312-.987-.714-.17-.402-.102-.866.17-1.206l11.88-15.32c.25-.347.66-.535 1.086-.495.423.04.793.303.97.69.176.387.134.838-.113 1.185L55.09 39.81zM50 59.65c.497-.403 1.224-.336 1.64.15l10.28 11.48c.445.563 1.097.923 1.81 1 .62.065 1.24-.123 1.72-.52.516-.425.833-1.044.876-1.71.044-.667-.19-1.322-.646-1.81L54.18 56c-.32-.3-.45-.752-.333-1.176.117-.423.458-.746.887-.84.43-.093.874.06 1.156.396l11.5 12.19c.9.96 1.366 2.247 1.29 3.56-.062 1.315-.67 2.544-1.68 3.39-.712.594-1.587.96-2.51 1.05-.332.034-.668.034-1 0-1.335-.125-2.563-.78-3.41-1.82L49.81 61.29c-.393-.507-.31-1.236.19-1.64z' />
          <path d='M48.54 43.27c.053-.096.053-.214 0-.31-1.008-2.374-.69-5.103.84-7.18L61.17 20.5c.235-.378.662-.592 1.105-.553.443.038.827.322.992.735.166.413.086.884-.207 1.218L51.28 37.19c-1.017 1.394-1.233 3.217-.57 4.81.39.894.26 1.93-.34 2.7l-18.8 24.87c-.56.645-.71 1.548-.39 2.34.32.79 1.058 1.335 1.908 1.41.85.073 1.67-.336 2.122-1.06l18.92-25.2c.557-.81 1.513-1.247 2.49-1.14 1.742.174 3.447-.586 4.48-2l11.76-15.21c.235-.378.662-.592 1.105-.553.443.038.827.322.992.735.166.413.086.884-.207 1.218L63 45.39c-1.543 2.07-4.064 3.168-6.63 2.89-.112-.02-.227.022-.3.11l-18.92 25.2c-.696 1.026-1.78 1.726-3 1.94-.278.045-.56.065-.84.06-1.76-.03-3.35-1.06-4.098-2.652-.75-1.592-.53-3.472.568-4.848l18.76-24.82z' />
          <path d='M24.19 21.5c2.22-1.53 5.2-1.6 8.39-.19 3.286 1.554 6.086 3.974 8.1 7 2.91 4.22 2.59 5.79 1.88 9.62-.16.89 3.22 3.7 3.5 4.56.125.398.03.833-.25 1.143-.278.31-.7.45-1.11.367-.47-.23-.877-.573-1.18-1-1.35-1.56-3.63-3.31-3.46-4.81.58-3.18 1.21-4.89-1.32-8.55-1.757-2.674-4.207-4.82-7.09-6.21-2.42-1.06-4.58-1.07-6.11 0-1.53 1.07-2.29 3.08-2.15 5.72.27 3.193 1.404 6.252 3.28 8.85 2.7 3.35 5.67 2.8 8.85 3.38 1.24.23 2.32 1.21 3.94 3.12.77.87 2 2.25 2.24 2.5.43.455.43 1.165 0 1.62-.446.472-1.177.53-1.69.13-.303-.217-.567-.484-.78-.79-1.57-1.84-3.5-3.86-4.3-4.06-3.83-.7-7.25-.35-10.17-4.56-2.147-2.948-3.447-6.427-3.76-10.06-.14-3.48 1-6.28 3.19-7.78zM59 42.71c-.236.368-.656.575-1.092.54-.435-.038-.815-.312-.987-.714-.17-.402-.102-.866.17-1.206L69 26c.236-.368.656-.575 1.092-.54.435.038.815.312.987.714.17.402.102.866-.17 1.206L59 42.71z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsFood;
