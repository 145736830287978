import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

/**
 * @param performances The current reducer's state.
 * @param newPerformance An Immutable Map.
 * @return nextState The next reducer's state.
 */
const addPerformance = (performances, newPerformance, userId) => {
  const userID = Number(userId);
  const exists = performances.has(userID);

  if (exists) {
    // update an existing performance by merging instead replacing
    let existingUser = performances.get(userID);
    existingUser = existingUser.merge(newPerformance);

    performances = performances.set(userID, existingUser);
  } else {
    // add performances from scratch
    performances = performances.set(userID, newPerformance);
  }

  return performances;
};

const performancesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_SUCCESS:
      return addPerformance(state, fromJS(action.performances), action.userID);

    default:
      return state;
  }
};

export default performancesReducer;
