import {
  FieldNames,
  MobileOptions,
  SupportedOsDataTemplate,
} from '../components/pages/admin/InteractionsDashboard/global/dashboardOptions';

import actionTypes from '../actions/actionTypes';
import { fromJS } from 'immutable';
import getPercent from '../components/pages/admin/InteractionsDashboard/utils/getPercent';
import { v4 as uuidv4 } from 'uuid';
import WidgetTypes from '../components/pages/admin/InteractionsDashboard/global/WidgetTypes';

const INITIAL_STATE = fromJS({
  abandonment: {
    totalPossibleAbandoned: {
      label: 'Possibly Abandoned',
      value: 0,
      widget: WidgetTypes.POSSIBLE_ABANDONED,
    },
    totalPossibleAbandonedRate: {
      label: 'Possibly Abandoned Rate',
      value: 0,
      widget: WidgetTypes.POSSIBLE_ABANDONED_RATE,
    },
  },
  contentFeedback: {
    loading: false,
    results: [],
  },
  deflectedNoDeflectedData: {
    frequency: void 0,
    loading: false,
    results: void 0,
  },
  deflections: {
    deflectionPercent: {
      label: 'Deflection Rate',
      value: 0,
      widget: WidgetTypes.DEFLECTION_RATE,
    },
    loading: false,
    totalDeflections: {
      label: 'Total Deflections',
      value: 0,
      widget: WidgetTypes.TOTAL_DEFLECTIONS,
    },
    transferredToAgent: {
      label: 'Transferred to agent',
      value: 0,
      widget: WidgetTypes.TRANSFERRED_TO_AGENT,
    },
  },
  errors: [],
  feedbackDetails: {
    isLoading: false,
    results: [],
  },
  fieldsInfo: {
    isLoading: false,
    results: [],
  },
  geoDistribution: {
    loading: false,
    results: [],
  },
  interactionChannels: {
    loading: false,
    results: [],
  },
  interactions: {
    espFilters: '',
    loading: false,
    onMobile: {
      drillDownIdentifier: {
        [FieldNames.OS]: MobileOptions,
      },
      label: 'Mobile Interactions',
      sub: `Interactions via Virtual Agent`,
      value: 0,
      widget: WidgetTypes.MOBILE_INTERACTIONS,
    },
    totalInteractions: {
      label: 'Total Interactions',
      value: 0,
      widget: WidgetTypes.TOTAL_INTERACTIONS,
    },
    uniqueUsers: {
      label: 'Unique Users',
      value: 0,
      widget: WidgetTypes.UNIQUE_USERS,
    },
  },
  interactionsByMatchedArchetype: {
    loading: false,
    results: [],
  },
  interactionsByOsClient: {
    loading: false,
    results: SupportedOsDataTemplate,
  },
  interactionsByTopic: {
    loading: false,
    results: [],
  },
  isUpdatingInteractions: false,
  liveChatFeedbackKeys: {
    isLoading: false,
    results: [],
  },
  locations: {
    isLoading: false,
    results: [],
  },
  redactConversation: {
    loading: false,
  },
  report: {
    data: {},
    errors: [],
    interactionUserDetails: {},
    isLoading: false,
  },
  topMatchedIntents: {
    loading: false,
    results: [],
  },
  topMatchedIntentsDrivingDeflection: {
    loading: false,
    results: [],
  },
  topUnansweredQuestionsByKeywordsGroup: {
    isLoading: false,
    results: [],
  },
  topUnansweredWithKB: {
    isLoading: false,
    results: [],
  },
  topUnansweredWithoutKB: {
    isLoading: false,
    results: [],
  },
  unansweredQuestions: {
    loading: false,
    results: [],
  },
  unansweredQuestionsList: {
    results: [],
  },
});

const interactionsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }
  if (!action.type) {
    return state;
  }
  switch (action.type) {
    case actionTypes.GET_REPORT_DATA_FAIL: {
      return state
        .setIn(['report', 'errors'], fromJS(action.errors))
        .setIn(['report', 'isLoading'], false);
    }

    case actionTypes.GET_REPORT_DATA_START: {
      return state.setIn(['report', 'isLoading'], true);
    }

    case actionTypes.GET_REPORT_DATA_SUCCESS: {
      return state
        .setIn(['report', 'data'], fromJS(action.payload))
        .setIn(['report', 'isLoading'], false);
    }

    case actionTypes.DASHBOARD_GET_COUNTERS_START: {
      return state
        .setIn(['interactions', 'loading'], true)
        .setIn(['deflections', 'loading'], true);
    }

    case actionTypes.DASHBOARD_GET_COUNTERS_SUCCESS: {
      const counters = action.payload;
      return state
        .setIn(['interactions', 'loading'], false)
        .setIn(['deflections', 'loading'], false)
        .setIn(['interactions', 'kbDeflections'], counters.kbDeflections)
        .setIn(['interactions', 'deflectionByKbs'], counters.deflectionByKbs)
        .setIn(
          ['interactions', 'totalInteractions', 'value'],
          counters.totalInteractions
        )
        .setIn(['interactions', 'uniqueUsers', 'value'], counters.uniqueUsers)
        .setIn(['interactions', 'onMobile', 'value'], counters.onMobile)
        .setIn(
          ['deflections', 'totalDeflections', 'value'],
          counters.totalDeflections
        )
        .setIn(
          ['deflections', 'deflectionPercent', 'value'],
          counters.deflectionPercent === '-'
            ? counters.deflectionPercent
            : counters.deflectionPercent.toString().concat('%')
        )
        .setIn(
          ['deflections', 'transferredToAgent', 'value'],
          counters.transferredToAgent
        )
        .setIn(
          ['abandonment', 'totalPossibleAbandoned', 'value'],
          counters.totalPossibleAbandoned
        )
        .setIn(
          ['abandonment', 'totalPossibleAbandonedRate', 'value'],
          counters.totalPossibleAbandonedRate
        );
    }

    case actionTypes.DASHBOARD_GET_COUNTERS_FAIL: {
      return state
        .setIn(['interactions', 'loading'], false)
        .setIn(['deflections', 'loading'], false)
        .set('errors', action.errors);
    }

    case actionTypes.GET_LOCATIONS_ERROR: {
      return state.setIn(['locations', 'isLoading'], false);
    }

    case actionTypes.GET_LOCATIONS_START: {
      return state.setIn(['locations', 'isLoading'], true);
    }

    case actionTypes.GET_LOCATIONS_SUCCESS: {
      return state
        .setIn(['locations', 'isLoading'], false)
        .setIn(['locations', 'results'], fromJS(action.payload));
    }

    case actionTypes.SET_REPORT_DATA_ESP_FILTER: {
      return state.setIn(['interactions', 'espFilters'], action.filters);
    }

    case actionTypes.DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_START: {
      return state.setIn(['unansweredQuestions', 'loading'], true);
    }

    case actionTypes.DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_SUCCESS: {
      const totalInteractions = state.getIn([
        'interactions',
        'totalInteractions',
        'value',
      ]);
      const unansweredQuestionsResults = action.payload;
      const maxItemsBarWidget = 10;
      const formattedToBarWidgetResults = unansweredQuestionsResults.results
        .filter((result) => result.intent !== '')
        .map((results) => ({
          drillDownIdentifier: {
            [FieldNames.ACTUAL_MATCHED_INTENT]: results.intent,
            [FieldNames.NO_RESPONSE]: true,
          },
          label: results.intent,
          value: results.total,
        }))
        .slice(0, maxItemsBarWidget)
        .reverse();

      const formattedToListWidgetResults = unansweredQuestionsResults.results
        .filter((result) => result.intent !== '')
        .map(({ total, intent }) =>
          getPercent({
            entry: {
              drillDownIdentifier: {
                [FieldNames.ACTUAL_MATCHED_INTENT]: intent,
                [FieldNames.NO_RESPONSE]: true,
              },
              key: uuidv4(),
              title: intent,
              total: total,
            },
            totalInteractions,
          })
        );

      return state
        .setIn(['unansweredQuestions', 'loading'], false)
        .setIn(['unansweredQuestions', 'results'], formattedToBarWidgetResults)
        .setIn(
          ['unansweredQuestionsList', 'results'],

          formattedToListWidgetResults
        );
    }
    case actionTypes.DASHBOARD_GET_TOP_UNANSWERED_QUESTIONS_FAIL: {
      return state
        .setIn(['unansweredQuestions', 'loading'], false)
        .set('errors', action.errors);
    }

    case actionTypes.DASHBOARD_GET_TOP_MATCHED_INTENTS_START: {
      return state.setIn(['topMatchedIntents', 'loading'], true);
    }
    case actionTypes.DASHBOARD_GET_TOP_MATCHED_INTENTS_SUCCESS: {
      const topMatchedIntentsResults = action.payload;
      const formattedToWidgetResults = topMatchedIntentsResults.results.reduce(
        (acc, { intent, total }) => {
          if (intent) {
            return acc.concat({
              drillDownIdentifier: {
                [FieldNames.ACTUAL_MATCHED_INTENT]: intent,
              },
              id: intent,
              key: uuidv4(),
              label: intent,
              value: total,
            });
          }

          return acc;
        },
        []
      );
      return state
        .setIn(['topMatchedIntents', 'loading'], false)
        .setIn(['topMatchedIntents', 'results'], formattedToWidgetResults);
    }
    case actionTypes.DASHBOARD_GET_TOP_MATCHED_INTENTS_FAIL: {
      return state
        .setIn(['topMatchedIntents', 'loading'], false)
        .set('errors', action.errors);
    }

    case actionTypes.DASHBOARD_GET_CONTENT_FEEDBACK_START: {
      return state.setIn(['contentFeedback', 'loading'], true);
    }
    case actionTypes.DASHBOARD_GET_CONTENT_FEEDBACK_SUCCESS: {
      // userFeedback chart data
      const userFeedback = Object.values(action.payload.userFeedback).map(
        (entry) => entry.userFeedback
      );

      return state
        .setIn(['contentFeedback', 'loading'], false)
        .setIn(['contentFeedback', 'results'], fromJS(userFeedback))
        .setIn(['feedbackDetails', 'loading'], false)
        .setIn(
          ['feedbackDetails', 'results'],
          fromJS(action.payload.feedbackDetails)
        );
    }
    case actionTypes.DASHBOARD_GET_CONTENT_FEEDBACK_FAIL: {
      return state
        .setIn(['contentFeedback', 'loading'], false)
        .set('errors', action.errors);
    }
    // HERE!
    case actionTypes.DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_START: {
      return state.setIn(['interactionsByOsClient', 'loading'], true);
    }
    case actionTypes.DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_SUCCESS: {
      return state
        .setIn(['interactionsByOsClient', 'loading'], false)
        .setIn(['interactionsByOsClient', 'results'], fromJS(action.results));
    }
    case actionTypes.DASHBOARD_GET_INTERACTION_BY_OS_CLIENT_FAIL: {
      return state
        .setIn(['interactionsByOsClient', 'loading'], false)
        .set('errors', action.errors);
    }
    case actionTypes.DASHBOARD_GET_INTERACTION_BY_CHANNEL_START: {
      return state.setIn(['interactionChannels', 'loading'], true);
    }
    case actionTypes.DASHBOARD_GET_INTERACTION_BY_CHANNEL_SUCCESS: {
      //DEV-23623 provisional data for GQL overwrite
      // const channelsGQL = action.payload;
      return state
        .setIn(['interactionChannels', 'loading'], false)
        .setIn(['interactionChannels', 'results'], fromJS(action.payload));
    }
    case actionTypes.DASHBOARD_GET_INTERACTION_BY_CHANNEL_FAIL: {
      return state
        .setIn(['interactionChannels', 'loading'], false)
        .set('errors', action.errors);
    }
    // Here!
    case actionTypes.DASHBOARD_GET_GEO_DISTRIBUTION_START: {
      return state.setIn(['geoDistribution', 'loading'], true);
    }
    case actionTypes.DASHBOARD_GET_GEO_DISTRIBUTION_SUCCESS: {
      const geoDistributionResults = action.payload;
      const formattedToWidgetResults = geoDistributionResults.results.map(
        (results) => ({
          drillDownIdentifier: {
            [FieldNames.ISO_COUNTRY_CODE]: results.country,
          },
          id: results.country,
          value: results.total,
        })
      );
      return state
        .setIn(['geoDistribution', 'loading'], false)
        .setIn(['geoDistribution', 'results'], formattedToWidgetResults);
    }
    case actionTypes.DASHBOARD_GET_GEO_DISTRIBUTION_FAIL: {
      return state
        .setIn(['geoDistribution', 'loading'], false)
        .set('errors', action.errors);
    }

    case actionTypes.DASHBOARD_GET_DEFLECTED_DATA_START: {
      return state.setIn(['deflectedNoDeflectedData', 'loading'], true);
    }
    case actionTypes.DASHBOARD_GET_DEFLECTED_DATA_SUCCESS: {
      return state
        .setIn(['deflectedNoDeflectedData', 'loading'], false)
        .setIn(['deflectedNoDeflectedData', 'results'], action.payload.results)
        .setIn(
          ['deflectedNoDeflectedData', 'frequency'],
          action.payload.frequency
        );
    }
    case actionTypes.DASHBOARD_GET_DEFLECTED_DATA_FAIL: {
      return state
        .setIn(['deflectedNoDeflectedData', 'loading'], false)
        .set('errors', action.errors);
    }

    case actionTypes.GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_START: {
      return state.setIn(['interactionsByTopic', 'loading'], true);
    }
    case actionTypes.GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_SUCCESS: {
      return state
        .setIn(['interactionsByTopic', 'loading'], false)
        .setIn(['interactionsByTopic', 'results'], action.payload);
    }
    case actionTypes.GET_DASHBOARD_TOP_INTERACTIONS_BY_TOPIC_FAIL: {
      return state.setIn(['interactionsByTopic', 'loading'], false);
    }
    // GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE
    case actionTypes.GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_START: {
      return state.setIn(['interactionsByMatchedArchetype', 'loading'], true);
    }
    case actionTypes.GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_SUCCESS: {
      return state
        .setIn(['interactionsByMatchedArchetype', 'loading'], false)
        .setIn(['interactionsByMatchedArchetype', 'results'], action.payload);
    }
    case actionTypes.GET_DASHBOARD_INTERACTIONS_BY_MATCHED_ARCHETYPE_FAIL: {
      return state.setIn(['interactionsByMatchedArchetype', 'loading'], false);
    }
    // Reducer for top matched intents driving deflection
    case actionTypes.GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_START: {
      return state.setIn(
        ['topMatchedIntentsDrivingDeflection', 'loading'],
        true
      );
    }
    case actionTypes.GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_SUCCESS: {
      return state
        .setIn(['topMatchedIntentsDrivingDeflection', 'loading'], false)
        .setIn(
          ['topMatchedIntentsDrivingDeflection', 'results'],
          action.payload
        );
    }
    case actionTypes.GET_DASHBOARD_TOP_MATCHED_INTENTS_DRIVING_DEFLECTION_FAIL: {
      return state.setIn(
        ['topMatchedIntentsDrivingDeflection', 'loading'],
        false
      );
    }

    // end of streamline process 2/2 block

    case actionTypes.TABLE_DETAIL_GET_INTERACTION_START: {
      return state.setIn(['report', 'loading'], true);
    }
    case actionTypes.TABLE_DETAIL_GET_INTERACTION_SUCCESS: {
      // modify this to push the action.payload in reports data results
      let newResults;
      const dataResults = state.getIn(['report', 'data', 'results']);

      if (!dataResults) {
        newResults = fromJS([action.payload]);
      } else {
        newResults = dataResults.push(fromJS(action.payload));
      }
      return state
        .setIn(['report', 'data', 'results'], newResults)
        .setIn(['report', 'loading'], false);
    }
    case actionTypes.TABLE_DETAIL_GET_INTERACTION_FAILURE: {
      return state
        .setIn(['report', 'error'], action.errors)
        .setIn(['report', 'loading'], false);
    }

    case actionTypes.RESET_REPORT_DATA: {
      return state.set('report', INITIAL_STATE.get('report'));
    }

    case actionTypes.SET_INTERACTION_EID_TO_SHOW_DETAILS: {
      return state.setIn(
        ['report', 'detailedInteractionEID'],
        action.interaction_eid
      );
    }

    case actionTypes.UPDATE_INTERACTION_ERROR: {
      return state.set('isUpdatingInteractions', false);
    }

    case actionTypes.UPDATE_INTERACTION_START: {
      return state.set('isUpdatingInteractions', true);
    }

    case actionTypes.UPDATE_INTERACTION_SUCCESS: {
      const interactionsPath = ['report', 'data', 'results'];
      const interactionsList = state.getIn(interactionsPath);
      const updatedInteractionsList = interactionsList.update(
        interactionsList.findIndex(
          (interaction) =>
            interaction.get('interaction_eid') ===
            action.payload.interaction_eid
        ),
        () => fromJS(action.payload)
      );

      return state
        .set('isUpdatingInteractions', false)
        .setIn(interactionsPath, updatedInteractionsList);
    }
    case actionTypes.GET_INTERACTION_USER_DETAILS_SUCCESS: {
      return state.setIn(['report', 'interactionUserDetails'], action.payload);
    }

    case actionTypes.LOAD_INTERACTIONS_FIELD_OPTIONS_START:
      return state
        .setIn(['fieldsInfo', 'isLoading'], true)
        .setIn(['fieldsInfo', 'results'], fromJS([]));

    case actionTypes.LOAD_INTERACTIONS_FIELD_OPTIONS_SUCCESS:
      return state
        .setIn(['fieldsInfo', 'isLoading'], false)
        .setIn(['fieldsInfo', 'results'], fromJS(action.payload));

    case actionTypes.LOAD_INTERACTIONS_FIELD_OPTIONS_ERROR:
      return state.setIn(
        ['topUnansweredQuestionsByKeywordsGroup', 'isLoading'],
        false
      );

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_START:
      return state.setIn(
        ['topUnansweredQuestionsByKeywordsGroup', 'isLoading'],
        true
      );

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_BY_KEYWORDS_GROUP_SUCCESS:
      return state
        .setIn(['topUnansweredQuestionsByKeywordsGroup', 'isLoading'], false)
        .setIn(
          ['topUnansweredQuestionsByKeywordsGroup', 'results'],
          fromJS(action.payload)
        );
    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_START:
      return state.setIn(['topUnansweredWithoutKB', 'isLoading'], true);

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_FAIL:
      return state.setIn(['topUnansweredWithoutKB', 'isLoading'], false);

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_WITHOUT_KB_SUCCESS:
      return state
        .setIn(['topUnansweredWithoutKB', 'isLoading'], false)
        .setIn(['topUnansweredWithoutKB', 'results'], fromJS(action.payload));

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_START:
      return state.setIn(['topUnansweredWithKB', 'isLoading'], true);

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_FAIL:
      return state.setIn(['topUnansweredWithKB', 'isLoading'], false);

    case actionTypes.GET_TOP_UNANSWERED_QUESTIONS_WITH_KB_SUCCESS:
      return state
        .setIn(['topUnansweredWithKB', 'isLoading'], false)
        .setIn(['topUnansweredWithKB', 'results'], fromJS(action.payload));
    case actionTypes.GET_LIVE_CHAT_FEEDBACK_KEYS_SUCCESS:
      return state
        .setIn(['liveChatFeedbackKeys', 'isLoading'], false)
        .setIn(['liveChatFeedbackKeys', 'results'], fromJS(action.payload));

    case actionTypes.GET_REACT_CONVERSATION_FAIL:
    case actionTypes.GET_REACT_CONVERSATION_SUCCESS:
      return state.setIn(['redactConversation', 'isLoading'], false);
    case actionTypes.GET_REACT_CONVERSATION_START:
      return state.setIn(['redactConversation', 'isLoading'], true);
    default:
      return state;
  }
};

export default interactionsReducer;
