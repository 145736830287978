import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { memoize, noop } from 'lodash';
// Molecule
import ValidatedSelect from '../../molecules/ValidatedSelect';
// Controller
import SelectDepartmentCategory01Controller from '../../controllers/SelectDepartmentCategory01Controller';
// Globals
import TypeSelectDepartmentCategory, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectDepartmentCategory';
// Util
import injectIntlToController from '../../../utils/injectIntlToController';

class SelectDepartmentCategory01 extends Component {
  static propTypes = {
    ...TypeSelectDepartmentCategory,
    categoriesList: ImmutablePropTypes.list,
    change: PropTypes.func,
    departmentId: PropTypes.number,
    isLoadingCategory: PropTypes.bool,
    loadCategoryList: PropTypes.func,
    myServiceDeparment: ImmutablePropTypes.list,
    selectServiceDepartment: PropTypes.func,
    valueSelected: ImmutablePropTypes.map,
  };

  static defaultProps = {
    ...TypeDefaults,
    categoriesList: Immutable.List(),
    change: noop,
    isLoadingCategory: false,
    loadCategoryList: noop,
    myServiceDeparment: Immutable.List(),
    selectServiceDepartment: noop,
    valueSelected: null,
  };

  componentDidMount() {
    const { change, departmentId, loadCategoryList } = this.props;

    loadCategoryList(departmentId);

    change('service_department', departmentId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change } = this.props;

    if (
      nextProps.valueSelected &&
      !nextProps.valueSelected.isEmpty() &&
      !nextProps.isLoadingCategory &&
      !nextProps.categoriesList.isEmpty()
    ) {
      const eidSelected = nextProps.valueSelected.has('eid')
        ? nextProps.valueSelected.get('eid')
        : '';
      change('categorySelect', eidSelected);
    }

    change('service_department', nextProps.departmentId);
  }

  handleSearch = (e, query) => {
    const { departmentId, loadCategoryList } = this.props;

    loadCategoryList(departmentId, query.searchQuery);
  };

  handleChange = (e, value) => {
    const { categoriesList, change, id } = this.props;

    e.preventDefault(); // Do not pass the value from the option selected

    const selectedCategory = categoriesList.find(
      (cat) => cat.get('eid') === value
    );

    if (selectedCategory) {
      const newValue = {
        eid: selectedCategory.get('eid'),
        name: selectedCategory.get('name'),
        serviceDepartment: selectedCategory.get('service_department'),
      };

      change(id, Immutable.Map(newValue));
      change('categorySelect', value);
    }
  };

  handleOnBlur = () => {
    const { categoriesList, departmentId, loadCategoryList } = this.props;
    if (categoriesList.isEmpty()) {
      loadCategoryList(departmentId);
    }
  };

  handleSelectServiceDepartment = (e, value) => {
    const {
      id,
      change,
      loadCategoryList,
      myServiceDeparment,
      selectServiceDepartment,
    } = this.props;

    const optionsDepartment = this.buildServiceDepartmentOptions(
      myServiceDeparment
    );
    const selectedDepartment = optionsDepartment.filter((v) => v.id === value);

    if (selectedDepartment && selectedDepartment.length) {
      const departmentId = selectedDepartment[0].id;
      selectServiceDepartment(departmentId, selectedDepartment[0].type);
      // makes list load categories for newly selected department
      loadCategoryList(departmentId);
      // And reset value of selected category
      change('categorySelect', '');
      change(id, Immutable.Map()); // also the whole field of this component must be reset so form validation doesn't pass until a category is selected
    }
  };

  buildServiceDepartmentOptions = memoize((myServiceDeparment) =>
    myServiceDeparment
      .map((dep) => ({
        id: dep.get('id'),
        key: dep.get('id'),
        text: dep.get('name'),
        type: dep.get('type'),
        value: dep.get('id'),
      }))
      .toJS()
  );

  render() {
    const {
      categoriesList,
      departmentId,
      id,
      isLoadingCategory,
      labelText,
      myServiceDeparment,
      template,
      type,
      valueSelected,
    } = this.props;

    const options = [];
    categoriesList.forEach((cat) => {
      options.push({
        id: cat.get('eid'),
        text: cat.get('name'),
        value: cat.get('eid'),
      });
    });

    const value = (valueSelected && valueSelected.get('eid')) || '';
    const optionsDepartment = this.buildServiceDepartmentOptions(
      myServiceDeparment
    );

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <input
          name={id.toString()}
          style={{
            display: 'none',
          }}
        />

        <ValidatedSelect
          label='Service department'
          name='service_department'
          onChange={this.handleSelectServiceDepartment}
          options={optionsDepartment}
        />

        {departmentId ? (
          <ValidatedSelect
            label={labelText}
            loading={isLoadingCategory}
            name='categorySelect'
            onBlur={this.handleOnBlur}
            onChange={this.handleChange}
            onSearchChange={this.handleSearch}
            options={options}
            placeholder='Search...'
            search
            value={value}
          />
        ) : null}
      </div>
    );
  }
}
const SelectDepartmentCategory01Test = SelectDepartmentCategory01;

// eslint-disable-next-line no-class-assign -- DEV-1526
SelectDepartmentCategory01 = SelectDepartmentCategory01Controller(
  SelectDepartmentCategory01
);

export { SelectDepartmentCategory01Test };
export default injectIntlToController(SelectDepartmentCategory01);
