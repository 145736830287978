const api_case_mgmt_v0_1_service_department_25_add_user_to_group = {
  auth_group_id: 17,
  auth_group_name: 'CASE_TECHNICAL_CONTACT_IT',
  description: 'Francousss',
  display_name: 'IT Department',
  eid: 'b5552a59-fda9-497b-a526-f12ff7c4c6b1',
  id: 16,
  service_department: 'IT',
  sys_created_by: 'john@acme.com',
  sys_date_created: '2017-10-30T23:56:17.269897Z',
  sys_date_updated: '2017-10-30T23:56:17.269842Z',
  sys_updated_by: 'john@acme.com',
  technical_contact: {
    id: 3,
  },
};

export default api_case_mgmt_v0_1_service_department_25_add_user_to_group;
