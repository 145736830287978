import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCommunicationPhoneAccessories extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = String(className ? ` ${className}` : '');

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <path d='M12.857 25.998V.86H.034V26h12.823z' id='a' />
          <path d='M48.205 61H0V.75h48.205z' id='c' />
        </defs>
        <g fill={color} fillRule='evenodd'>
          <path
            d='M28.482 26.623h-5.18c-1.008-.002-1.823-.817-1.825-1.824.002-1.01.817-1.83 1.824-1.83h5.19c1.01 0 1.82.81 1.828 1.82-.002 1.003-.82 1.82-1.824 1.82m0-5.18h-5.18c-1.855 0-3.357 1.502-3.36 3.356 0 1.854 1.505 3.356 3.36 3.356h5.18c1.854 0 3.356-1.504 3.356-3.358 0-1.858-1.5-3.36-3.356-3.36m11.9 12.442c-1.006-.003-1.82-.818-1.823-1.825.005-1.006.82-1.82 1.827-1.823 1.01.003 1.82.82 1.825 1.825 0 1.007-.813 1.822-1.82 1.824m0-5.18c-1.854 0-3.356 1.5-3.357 3.354 0 1.855 1.503 3.358 3.357 3.358s3.355-1.504 3.355-3.357c0-1.853-1.5-3.356-3.355-3.356'
            fill='#FF000D'
          />
          <g transform='translate(66.5 52.25)'>
            <mask fill='#fff' id='b'>
              <use xlinkHref='#a' />
            </mask>
            <path
              d='M6.815 11.7c-.93 0-1.842-.245-2.636-.755L3 10.19v13.56c0 .394-.32.716-.716.716-.396 0-.715-.32-.716-.715V10.62c-.002-2.826.52-4.8 1.32-6.07.803-1.262 1.844-1.89 3.16-2.117.202-.02.4-.032.593-.032 1.94.01 3.49.96 4.69 2.44v4.39c-1.01 1.6-2.77 2.48-4.51 2.48v-.01zM12.7 4.096C11.298 2.23 9.22.856 6.64.86c-.253.002-.51.015-.772.04l-.02.003-.023.004c-1.7.266-3.226 1.195-4.236 2.82C.57 5.35.03 7.607.03 10.614V23.75c0 1.242 1.007 2.248 2.25 2.248 1.24 0 2.247-1.006 2.248-2.248V12.81c.738.274 1.51.422 2.283.422 2.32 0 4.65-1.193 5.94-3.39l.11-.18v-5.36l-.152-.204z'
              fill='#FF000D'
              mask='url(#b)'
            />
          </g>
          <g transform='translate(16.5 17.25)'>
            <mask fill='#fff' id='d'>
              <use xlinkHref='#c' />
            </mask>
            <path
              d='M46.672 58.75c0 .394-.32.714-.716.715-.395 0-.716-.32-.716-.716V45.19l-1.18.755c-.794.508-1.708.754-2.636.755-1.74 0-3.495-.88-4.51-2.48v-4.386c1.198-1.484 2.747-2.436 4.684-2.44.193 0 .39.01.593.032 1.32.227 2.36.855 3.16 2.116.8 1.27 1.33 3.244 1.32 6.07V58.75h.01zm-5.524.716H21.2c-1.432-.003-2.585-1.158-2.588-2.578v-44.77c.003-1.42 1.156-2.576 2.588-2.578h19.948c1.432.002 2.585 1.158 2.587 2.578v24.15c-.423-.166-.865-.29-1.32-.36l-.024-.005-.02-.002c-.26-.02-.52-.04-.77-.04-2.58 0-4.65 1.37-6.06 3.24l-.15.21v5.366l.11.18c1.29 2.197 3.62 3.39 5.937 3.39.773 0 1.545-.15 2.283-.424v9.41c-.157 1.257-1.248 2.248-2.56 2.246zm-38.72-.896c-.554-.554-.895-1.314-.895-2.158V5.337c0-.844.34-1.603.895-2.16.556-.553 1.316-.894 2.16-.894h22.765c.844 0 1.604.34 2.16.895.553.556.894 1.315.894 2.16v2.67H21.2c-2.277 0-4.12 1.84-4.12 4.11v44.77c0 .982.36 1.87.934 2.58H4.588c-.844-.003-1.604-.343-2.16-.898zm42.84-21.413v-25.04c0-2.268-1.843-4.11-4.12-4.11H31.94v-2.67c0-2.532-2.055-4.586-4.588-4.588H4.588C2.055.75 0 2.8 0 5.33v51.08C0 58.945 2.055 61 4.588 61h36.56c1.11-.002 2.11-.448 2.852-1.162.385.688 1.11 1.16 1.956 1.16 1.243 0 2.25-1.006 2.25-2.25V45.613c-.002-3.006-.54-5.262-1.555-6.884-.39-.63-.85-1.15-1.38-1.57z'
              fill='#FF000D'
              mask='url(#d)'
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default CatalogCommunicationPhoneAccessories;
