import React from 'react';
import pt from 'prop-types';
import { Icon } from 'semantic-ui-react';
import style from './FileExtensionIcon.module.scss';

const propTypes = {
  extension: pt.string,
};
const FileExtensionIcon = ({ extension = null }) => {
  return (
    <div className={style._} data-component='FileExtensionIcon'>
      <Icon fitted name='file outline' />
      <div className='ext'>{extension}</div>
    </div>
  );
};

FileExtensionIcon.propTypes = propTypes;

export default FileExtensionIcon;
