import _APIcall from '../../v1/utils/APIcall';
import buildCompositeController from '../../v1/utils/buildCompositeController';
import endpointGenerator from '../../v1/utils/endpointGenerator';
import { getTenantEntity } from '../../v1/selectors/getTenant';
import getTenantMenuConfiguration from '../../v1/selectors/getTenantMenuConfiguration';
import Immutable from 'immutable';
import tenantActions from '../../v1/actions/tenantActions';
import TenantImagesTypes from '../../v1/utils/TenantImageTypes';

const mapStateToProps = (state) => {
  const imagesList =
    state?.getIn(['tenant', 'images', 'values']) ?? Immutable.List();
  const [chatBotSvgImage] = imagesList.filter(
    (image) => TenantImagesTypes.BOT === image?.get('type')
  );

  return {
    chatBotSvgURL: chatBotSvgImage && chatBotSvgImage?.get('svg'),
    isCustomBrandingEnabled:
      getTenantMenuConfiguration(state)?.get('customBranding'),
    primaryColor: getTenantEntity(state)?.getIn([
      'branding_obj',
      'color_primary',
    ]),
    tenantImageListSize: imagesList?.size,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTenantImages() {
    const url = endpointGenerator.genPath('espImage.tenantImages');

    _APIcall.get({
      error(error) {
        dispatch(tenantActions.getTenantImagesFail(error));
      },
      query: {
        limit: 1000,
      },
      success(response) {
        const tenantImages = response.body.results;
        dispatch(tenantActions.getTenantImagesSuccess(tenantImages));
      },
      url,
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
