import _ from 'lodash';
import Immutable from 'immutable';
import async from 'async';

// Actions
import userEntitiesActions from './userEntitiesActions';
import workflowActions from './workflowActions';

// thunk
import workflowThunks from './workflowThunks';

// Utils
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import EntitiesUtils from '../utils/EntitiesUtils';
// Packages
import EspFilters from 'esp-util-filters';

// Selector
import groupPermissions from '../globals/groupPermissions';
import {
  getDefaultTeam,
  getSelectedDepartmentId,
  getSelectedServiceTeam,
} from '../selectors/getSelectedServiceTeam';

const searchByUserFirstAndLastNameAndTitle = (filters, userInput) => {
  const nameTokens = userInput.split(' ');
  const lastNameIndex = 1;

  if (nameTokens.length > 1 && nameTokens[lastNameIndex]) {
    if (nameTokens.length === 2) {
      const [userName, lastName] = nameTokens;

      filters = filters
        .contains('first_name', userName)
        .contains('last_name', lastName)
        .or()
        .contains('title', userInput);
    } else {
      nameTokens.forEach((token, index) => {
        if (index === 0) {
          filters = filters
            .contains('first_name', token)
            .or()
            .contains('last_name', token)
            .or()
            .contains('title', token);
        } else {
          filters = filters
            .or()
            .contains('first_name', token)
            .or()
            .contains('last_name', token)
            .or()
            .contains('title', token);
        }
      });
    }
  } else {
    filters = filters
      .contains('first_name', userInput)
      .or()
      .contains('title', userInput);
  }
  return filters;
};

const shouldAccept = (searchTerm, stateAfter) => {
  const usersAdmin = stateAfter.get('usersAdmin');

  const stillTheSame = searchTerm === usersAdmin.get('searchTerm');

  return stillTheSame;
};

const getErrorAsArray = (errors) => {
  let output = [];
  for (const iterator of errors) {
    output = [...output, iterator.message];
  }
  return output;
};

const getErrorAsString = (errors) => {
  return getErrorAsArray(errors)
    .join(', ')
    .replace(/[[\]&]+/g, '');
};

const userEntitiesThunks = {};
/**
 *
 * @param {string} url - dynamic url to check the status of the export job
 */
userEntitiesThunks.checkExportUserJobStatus = (url) => () => {
  return APIcall.get({
    token: true,
    url,
  });
};
/**
 *
 * @param {string} esp_filters - esp filters that will be used to export the users
 */
userEntitiesThunks.exportUsers =
  ({ espFilters }) =>
  () => {
    const url = endpointGenerator.genPath('espUser.users.exportList');

    return APIcall.get({
      query: {
        esp_filters: espFilters,
      },
      token: true,
      url,
    });
  };

/**
 * Optimistically retrieve user from api and add it under state.entities.users and that's it
 * @param {number} userID
 * @param {boolean} force - Force to reload the user
 */
userEntitiesThunks.addUserEntity =
  (userID, force = false) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!userID) {
        reject(new Error('userID not provided'));
      }

      const state = getState();
      const userEntity = state.getIn(['entities', 'users', userID]);
      const userExistsInContainer = state.hasIn([
        'containersStatus',
        'users',
        userID,
      ]);

      let hasErrors = false;
      let isStaled = false;
      const isLoading = state.getIn([
        'containersStatus',
        'users',
        userID,
        'loading',
      ]);
      // If the user data have been already loaded before
      // we need to check for the image expiration time
      if (userExistsInContainer) {
        // This two conditions may cancel loading
        hasErrors = state.getIn(['containersStatus', 'users', userID, 'error']);
        const timestamp = state.getIn([
          'containersStatus',
          'users',
          userID,
          'timestamp',
        ]);

        // i have to pass the state to make the unit test work (the ones that will eventually call this thunk)
        // but it's not really needed
        isStaled = EntitiesUtils.isTimestampExpired(timestamp, state);
      }

      const shouldLoadUser =
        (!userEntity || isStaled) && !hasErrors && !isLoading;
      // console.warn(`userID=${userID}, loaded=${Boolean(userEntity)}, isLoading=${isLoading}, isStaled=${isStaled}, hasErrors=${hasErrors}`);
      if (shouldLoadUser || force) {
        dispatch(userEntitiesActions.addUserEntityStart(userID));

        const url = endpointGenerator.genPath('espUser.users.instance', {
          userID,
        });
        APIcall.get({
          error(err) {
            dispatch(userEntitiesActions.addUserEntityFail(userID));
            reject(err);
          },
          preventShowError: true,
          // DO NOT REMOVE THIS AGAIN!!! We don't want to splash the user screen with errors for 404'ed users
          success(res) {
            const user = res.body;
            dispatch(userEntitiesActions.addUserEntitySuccess(userID, user));
            resolve(user);
          },

          token: true,

          url,
        });
      }

      if (userEntity) {
        resolve(userEntity.toJS());
      }

      if (hasErrors) {
        reject(
          new Error(
            `User ${userID} prevented from loading since has API errors: ${hasErrors}`
          )
        );
      }
    });

/**
 * Get Full List of User
 */
userEntitiesThunks.getUsersBy =
  ({
    limit = 24,
    offset,
    orderBy = 'last_name',
    getHideFromDirectory = false,
    getInactiveUsers = false,
  }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(userEntitiesActions.addUsersEntitiesStart());

      const query = {};
      query.esp_filters = encodeURI('is_active__EQ=True');
      // Offset
      if (_.isNumber(offset)) {
        query.offset = offset;
      }

      if (getHideFromDirectory) {
        query.hidden_user = true;
        query.esp_filters += encodeURI(
          '&esp_dict.confidential_hire__IBOOL=True'
        );
      } else if (getInactiveUsers) {
        query.hidden_user = true;
        query.esp_filters = encodeURI('is_active__EQ=False');
      }

      // Limit
      query.limit = limit;

      // Order by
      query.order_by = orderBy;

      const url = endpointGenerator.genPath('espUser.users');

      APIcall.get({
        error() {
          dispatch(userEntitiesActions.addUsersEntitiesFail());
          reject();
        },
        query,
        success(res) {
          const users = res.body;
          dispatch(
            userEntitiesActions.addUsersEntitiesSuccess({
              count: users.count,
              users: users.results,
            })
          );
          resolve();
        },
        token: true,
        url,
      });
    });

/**
 * Get Full List of User
 */
userEntitiesThunks.getUsersBy_v2 =
  (query, orderBy = 'last_name') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(userEntitiesActions.loadUserStart());

      // Order by
      query.order_by = orderBy;

      const url = endpointGenerator.genPath('espUser.users');

      APIcall.get({
        error() {
          dispatch(userEntitiesActions.loadUserFail());
          reject();
        },
        query,
        success({ body }) {
          resolve(body);
        },
        token: true,
        url,
      });
    });

/**
 * Load a list of users from an array of IDs
 * @param ids {array}
 * @params checkHiddenUsers {boolean} Allows to get hidden users in the API response
 */
userEntitiesThunks.getListUsersByID = (ids, checkHiddenUsers) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(userEntitiesActions.addUsersEntitiesStart());

    const query = {};
    query.esp_filters = encodeURI(`id__IN=${ids.join(',')}`);
    if (checkHiddenUsers) {
      query.hidden_user = encodeURI('True');
    }
    const url = endpointGenerator.genPath('espUser.users');

    APIcall.get({
      error(err) {
        dispatch(userEntitiesActions.addUsersEntitiesFail(err));
        reject(err);
      },
      query,
      success(res) {
        const users = res.body;
        dispatch(
          userEntitiesActions.addUsersEntitiesSuccess({
            count: users.count,
            users: users.results,
          })
        );
        resolve(users);
      },
      token: true,
      url,
    });
  });

/**
 * Search users from an array of IDs and a name
 * @param ids {array}
 * @param name {string}
 * @params checkHiddenUsers {boolean} Allows to get hidden users in the API response
 */
userEntitiesThunks.searchTermForCases =
  ({
    limit = 24,
    offset,
    orderBy,
    resetUsersList = false,
    concate = false,
    userID,
    typeSearch,
    isOnlyActiveUsers = true,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (resetUsersList) {
        dispatch(userEntitiesActions.resetUsersList()); // Force to reset the user list from there in order to avoidto see previous full user lists
      }

      dispatch(userEntitiesActions.addUsersEntitiesStart());

      const state = getState();
      const userInput = state.getIn(['usersAdmin', 'searchTerm']);

      const query = {};

      let url = endpointGenerator.genPath('espUser.users.serviceAgents');
      let serviceTeam = getSelectedServiceTeam(state);

      if (!serviceTeam || serviceTeam?.isEmpty()) {
        serviceTeam = getDefaultTeam(state);
      }

      let applyFilter = true;
      let espFilter = new EspFilters();

      switch (typeSearch) {
        case 'forSubscriberAllEmployees': {
          if (userInput) {
            espFilter = searchByUserFirstAndLastNameAndTitle(
              espFilter,
              userInput
            );
            espFilter = espFilter.or().contains('email', userInput);
          }

          espFilter = espFilter.isTrue('is_active');
          // we should not be able to subscribe ourselves
          espFilter = espFilter.differentTo('id', userID);
          applyFilter = false;
          url = endpointGenerator.genPath('espUser.users');
          break;
        }

        case 'forCasesTeam': {
          if (serviceTeam) {
            if (userInput) {
              espFilter = searchByUserFirstAndLastNameAndTitle(
                espFilter,
                userInput
              );
              espFilter = espFilter.or().contains('email', userInput);
            }
            espFilter = espFilter.equalTo(
              'join_service_teams.name',
              serviceTeam.get('name')
            );
          }

          if (isOnlyActiveUsers) {
            espFilter = espFilter.isTrue('is_active');
          }
          url = endpointGenerator.genPath('espUser.users.serviceAgents');
          break;
        }

        case 'forCasesDepartment': {
          if (userInput) {
            espFilter = searchByUserFirstAndLastNameAndTitle(
              espFilter,
              userInput
            );
            espFilter = espFilter.or().contains('email', userInput);
          }
          const serviceDepartmentID = getSelectedDepartmentId(state);
          url = endpointGenerator.genPath(
            'espCaseMgmt.serviceDepartment.instance.members',
            {
              serviceDepartmentID,
            }
          );
          break;
        }

        case 'forAllAgent': {
          if (userInput) {
            espFilter = searchByUserFirstAndLastNameAndTitle(
              espFilter,
              userInput
            );
            espFilter = espFilter.or().contains('email', userInput);
          }
          url = endpointGenerator.genPath('espUser.users.serviceAgents');
          if (isOnlyActiveUsers) {
            espFilter = espFilter.isTrue('is_active');
          }
          break;
        }

        default: {
          if (userInput) {
            espFilter = searchByUserFirstAndLastNameAndTitle(
              espFilter,
              userInput
            );
            espFilter = espFilter.or().contains('email', userInput);
          }
          if (serviceTeam) {
            espFilter = espFilter.equalTo(
              'join_service_teams.name',
              serviceTeam.get('name')
            );
          }

          url = endpointGenerator.genPath('espUser.users.serviceAgents');
          break;
        }
      }

      query.esp_filters = espFilter.asQueryString();
      query.order_by = orderBy;

      if (offset) {
        query.offset = offset;
      }

      if (limit) {
        query.limit = limit;
      }

      const onSuccess = (response) => {
        // are we still interested in this response?
        if (!shouldAccept(userInput, getState())) {
          resolve();
          return;
        }

        const users = response.body;
        let { results } = users;

        // Filters users that doesn't really belong to a service team
        // This can happen if we set by hand the group SERVICE_LEAD or SERVICE_AGENT to the user
        if (applyFilter) {
          results = users.results.filter(
            (usr) => usr.service_departments && usr.service_departments.length
          );
        }

        dispatch(
          userEntitiesActions.addUsersEntitiesSuccess({
            concate,
            count: users.count,
            next: users.next,
            resetUsersList,
            users: results,
          })
        );
        resolve();
      };

      APIcall.get({
        error(err) {
          dispatch(userEntitiesActions.addUsersEntitiesFail(err));
          reject(err);
        },
        query,
        success(res) {
          onSuccess(res);
        },
        token: true,
        url,
      });
    });

/**
 * Load userList with filter
 * @param limit {number}
 * @param offset {number}
 * @param orderBy {string}
 * @param resetList {boolean} Force to reset the userList before we pass the new one in the Reducer
 */
userEntitiesThunks.searchTermWithFilters =
  ({
    limit = 24,
    offset,
    orderBy,
    resetUsersList = false,
    getHideFromDirectory = false,
    concate = false,
    userID,
    filterByTeam,
    filterByFavorites,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(userEntitiesActions.addUsersEntitiesStart());
      const state = getState();

      const userInput = state.getIn(['usersAdmin', 'searchTerm']);

      const endpoint = filterByTeam
        ? endpointGenerator.genPath('espUser.users.instance.team', {
            userID: userID,
          })
        : filterByFavorites
        ? endpointGenerator.genPath('espUser.users.favorites', {
            userID: userID,
          })
        : endpointGenerator.genPath('espUser.users');

      const query = {};
      const espFilter = searchByUserFirstAndLastNameAndTitle(
        new EspFilters(),
        userInput
      )
        .or()
        .contains('email', userInput);

      query.order_by = orderBy;

      if (getHideFromDirectory) {
        query.hidden_user = true;
        espFilter.isTrue('hidden_user');
      }

      query.esp_filters = espFilter.asQueryString();

      if (offset) {
        query.offset = offset;
      }

      if (limit) {
        query.limit = limit;
      }

      return APIcall.get({
        error(err) {
          dispatch(userEntitiesActions.addUsersEntitiesFail(err));
          reject();
        },
        query,
        success(res) {
          const users = res.body;
          dispatch(
            userEntitiesActions.addUsersEntitiesSuccess({
              concate,
              count: users.count,
              next: users.next,
              resetUsersList,
              users: users.results,
            })
          );
          resolve();
        },
        token: true,
        url: endpoint,
      });
    });

/**
 * Load userList
 * @param limit {number}
 * @param offset {number}
 * @param orderBy {string}
 * @param resetList {boolean} Force to reset the userList before we pass the new one in the Reducer
 */
userEntitiesThunks.searchTerm =
  ({
    limit = 24,
    offset,
    orderBy,
    resetUsersList = false,
    getHideFromDirectory = false,
    concate = false,
    getInactiveUsers = false,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const userInput = state.getIn(['usersAdmin', 'searchTerm']);

      const endpoint = endpointGenerator.genPath('espUser.users');

      const onSuccess = (response) => {
        // are we still interested in this response?
        if (!shouldAccept(userInput, getState())) {
          resolve();
          return;
        }

        const users = response.body;

        dispatch(
          userEntitiesActions.addUsersEntitiesSuccess({
            concate,
            count: users.count,
            next: users.next,
            resetUsersList,
            users: users.results,
          })
        );
        resolve();
      };

      const query = {};

      let espFilter = new EspFilters()
        .contains('title', userInput)
        .or()
        .contains('username', userInput)
        .or()
        .contains('email', userInput)
        .isTrue('is_active');

      query.order_by = orderBy;

      if (getHideFromDirectory) {
        query.hidden_user = true;
        espFilter = espFilter.isTrue('esp_dict.confidential_hire');
      } else if (getInactiveUsers) {
        query.hidden_user = true;
        espFilter = espFilter.isFalse('is_active');
      }

      query.esp_filters = espFilter.asQueryString();

      if (offset) {
        query.offset = offset;
      }

      if (limit) {
        query.limit = limit;
      }

      return APIcall.get({
        error(err) {
          dispatch(userEntitiesActions.addUsersEntitiesFail(err));
          reject();
        },
        query,
        success(res) {
          onSuccess(res);
        },
        token: true,
        url: endpoint,
      });
    });

userEntitiesThunks.addOrRemoveRoleToUser = (userID, role, add) => (dispatch) =>
  new Promise((resolve, reject) => {
    let endpoint = endpointGenerator.genPath(
      'espUser.users.instance.addToGroup',
      {
        userID,
      }
    );

    if (!add) {
      endpoint = endpointGenerator.genPath(
        'espUser.users.instance.removeFromGroup',
        {
          userID,
        }
      );
    }

    dispatch(userEntitiesActions.addUserEntityStart(userID));

    const onSuccess = (response) => {
      const user = response.body;
      dispatch(userEntitiesActions.addUserEntitySuccess(userID, user));
    };

    APIcall.post({
      data: {
        name: role,
      },
      error(err) {
        dispatch(userEntitiesActions.addUserEntityFail(userID));
        reject(err);
      },
      // data  : role,
      success(res) {
        onSuccess(res);
        resolve(res);
      },

      token: true,

      url: endpoint,
    });
  });

/**
 * Create a new user base on the Backend scratch data
 */
userEntitiesThunks.createNewUserFromBEScratch = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // Load data from BE scratch in workflowState
    const state = getState();
    const backendScratch = state.hasIn([
      'workflowState',
      'backendScratch',
      'espuser.espuser',
    ])
      ? state.getIn(['workflowState', 'backendScratch', 'espuser.espuser'])
      : null;
    const frontEndScratchContact = state.hasIn([
      'workflowState',
      'frontendScratch',
      'user_contacts',
    ])
      ? state.getIn(['workflowState', 'frontendScratch', 'user_contacts'])
      : Immutable.Map();

    const frontEndScratchDepartment = state.hasIn([
      'workflowState',
      'frontendScratch',
      'departmentSelected',
    ])
      ? state.getIn(['workflowState', 'frontendScratch', 'departmentSelected'])
      : null;

    const isManager = state.hasIn([
      'workflowState',
      'frontendScratch',
      'is_manager',
    ])
      ? state.getIn(['workflowState', 'frontendScratch', 'is_manager'])
      : null;

    const locationURL = endpointGenerator.genPath(
      'espPlaces.locations.instance',
      {
        locationID: Number(backendScratch.get('location_id')),
      }
    );

    const managerURL = endpointGenerator.genPath('espUser.users.instance', {
      userID: Number(backendScratch.get('manager_id')),
    });
    const mobileNumber = backendScratch.get('mobile_number_object').toJS();
    mobileNumber.public = true;

    const userObj = {
      email: backendScratch.get('secondary_email'),

      esp_dict: {
        existing_user: false,
      },

      // the WF should be launch by reading this flag
      first_name: backendScratch.get('first_name'),

      home_address: {
        postal_code: backendScratch.get('postal_code'),
      },

      is_active: true,

      last_name: backendScratch.get('last_name'),

      location: locationURL,
      manager: managerURL,
      phone_numbers: {
        mobile: mobileNumber,
      },
      // See with Rohit, we have to pass a value here
      remote_worker: backendScratch.get('remote_worker'),

      secondary_email: backendScratch.get('secondary_email'),

      sms_subscription: true,

      start_date: backendScratch.get('start_date'),

      terms_accepted: true,

      title: backendScratch.get('title'),
      user_type: 'EMPLOYEE',
    };

    if (!backendScratch) {
      reject('Error backendScratch is missing');
    } else {
      dispatch(userEntitiesActions.createNewUserStart());

      async.waterfall(
        [
          // 1 Create the new user
          (next) => {
            const url = endpointGenerator.genPath('espUser.users');
            APIcall.post({
              data: userObj,
              error(err) {
                next(err);
              },
              success(res) {
                const user = res.body;
                next(null, user);
              },
              token: true,
              url,
            });
          },

          // 2 Set the user in the Manager Group if isManager === true
          (user, next) => {
            if (isManager) {
              dispatch(
                userEntitiesThunks.addOrRemoveRoleToUser(
                  user.id,
                  groupPermissions.MANAGER,
                  true
                )
              )
                .then(() => {
                  next(null, user);
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              next(null, user);
            }
          },

          // 3 Set all assignment contact
          (user, next) => {
            const arrayGroup = [];
            frontEndScratchContact.forEach((value, key) => {
              arrayGroup.push({
                id: Number(value),
                name: key,
              });
            });

            if (arrayGroup.length > 0) {
              dispatch(
                userEntitiesThunks.takeAssignmentForGroupAdmin(
                  user.id,
                  arrayGroup
                )
              )
                .then(() => next(null, user))
                .catch((err) => next(err));
            } else {
              next(null, user);
            }
          },

          // 4 Set this new user member of the selected Department / team
          (user, next) => {
            if (
              !frontEndScratchDepartment ||
              frontEndScratchDepartment.isEmpty()
            ) {
              next(null, user);
            } else {
              dispatch(
                userEntitiesThunks.changeDepartmentUser(
                  user.id,
                  frontEndScratchDepartment.get('id')
                )
              )
                .then(() => {
                  next(null, user);
                })
                .catch((err) => {
                  next(err);
                });
            }
          },
        ],
        (err, user) => {
          if (err) {
            dispatch(userEntitiesActions.createNewUserFail());
            reject(err);
          } else {
            dispatch(userEntitiesActions.createNewUserSuccess());
            resolve(user);
          }
        }
      );
    }
  });

userEntitiesThunks.takeAssignmentForGroupAdmin =
  (userID, group_name) => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(userEntitiesActions.loadUserAssignmentStart(userID));

      const callbacks = group_name.map((value) => (next) => {
        APIcall.post({
          data: {
            group_name: value.name,
            user: value.id,
          },
          error(error) {
            dispatch(
              userEntitiesActions.loadUserAssignmentError(userID, error)
            );
            next(error);
          },
          success({ body }) {
            dispatch(
              userEntitiesActions.loadUserAssignmentSuccess(userID, body)
            );
            next();
          },
          token: true,
          url: endpointGenerator.genPath(
            'espUser.users.instance.takeAssignmentForGroupAdmin',
            {
              userID,
            }
          ),
        });
      });

      async.waterfall(callbacks, (error) => {
        if (error) {
          dispatch(userEntitiesActions.loadUserAssignmentError(userID, error));
          reject(error);
        } else {
          dispatch(userEntitiesActions.loadUserAssignmentSuccess(userID));
          resolve();
        }
      });
    });

//
// @param userID <Number>
// @param group_name <String>
// @return <Promise>
//
userEntitiesThunks.getUserAssignments =
  (userID, reload = false) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!userID) {
        throw new Error('userID can not be undefined or null');
      }

      const state = getState();
      const userAssignment = state.getIn(['usersAdmin', 'userAssignment']);
      const currentUserAssigment =
        userAssignment.get(userID) || Immutable.Map();

      if (!currentUserAssigment.isEmpty() && reload === false) {
        resolve();
      }

      dispatch(userEntitiesActions.loadUserAssignmentStart(userID));

      APIcall.get({
        error(error) {
          dispatch(userEntitiesActions.loadUserAssignmentError(userID, error));
          reject(error);
        },
        success({ body }) {
          dispatch(userEntitiesActions.loadUserAssignmentSuccess(userID, body));
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath(
          'espUser.users.instance.getAssignmentForGroup',
          {
            userID,
          }
        ),
      });
    });

//
// @param userID <Number>
// @param group_name <String>
// @return <Promise>
//
userEntitiesThunks.takeAssignmentForGroup =
  (userID, group_names = Immutable.List(), groups = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!userID) {
        throw new Error('userID can not be undefined or null');
      }
      if (!group_names || group_names.size === 0) {
        reject();
      }

      dispatch(userEntitiesActions.loadUserAssignmentStart(userID));

      const callbacks = group_names
        .map((group) => (next) => {
          APIcall.post({
            data: {
              group_name: group,
            },
            error(error) {
              dispatch(
                userEntitiesActions.loadUserAssignmentError(userID, error)
              );
              reject(error);
              next(error);
            },
            success({ body }) {
              resolve(body);
              next();
            },
            token: true,
            url: endpointGenerator.genPath(
              'espUser.users.instance.takeAssignmentForGroup',
              {
                userID,
              }
            ),
          });
        })
        .toJS();

      async.waterfall(callbacks, (error) => {
        if (error) {
          dispatch(userEntitiesActions.loadUserAssignmentError(userID, error));
        } else {
          dispatch(
            userEntitiesActions.loadUserAssignmentSuccess(userID, groups)
          );
        }
      });
    });

//
// @param userID <Number>
// @param group_name <String>
// @return <Promise>
//
userEntitiesThunks.removeAssignmentFromGroup =
  (userID, all_groups = Immutable.List(), groups = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!userID) {
        throw new Error('userID can not be undefined or null');
      }
      if (!all_groups || all_groups.size === 0) {
        reject();
      }

      dispatch(userEntitiesActions.loadUserAssignmentStart(userID));

      APIcall.post({
        data: {
          all: true,
          group_name: all_groups.first(),
        },
        error(error) {
          dispatch(userEntitiesActions.loadUserAssignmentError(userID, error));
          reject(error);
        },
        success() {
          dispatch(
            userEntitiesActions.loadUserAssignmentSuccess(userID, groups)
          );
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath(
          'espUser.users.instance.removeAssignmentFromGroup',
          {
            userID,
          }
        ),
      });
    });

//
// @param userID <Number>
// @param group_name <String>
// @return <Promise>
//
userEntitiesThunks.terminateUser = (userID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!userID) {
      throw new Error('userID can not be undefined or null');
    }
    dispatch(userEntitiesActions.removeUserEntityStart());
    APIcall.post({
      error(error) {
        dispatch(userEntitiesActions.addUserFail());
        reject(error);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espUser.users.instance.deactivateUser', {
        userID,
      }),
    });
  });

//
// @param userID <Number>
// @return <Promise>
//
userEntitiesThunks.activateUser = (userID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!userID) {
      throw new Error('userID can not be undefined or null');
    }
    dispatch(userEntitiesActions.addUserEntityStart(userID));
    dispatch(userEntitiesActions.addUsersEntitiesStart());

    APIcall.post({
      error(error) {
        reject(error);
      },
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espUser.users.instance.activateUser', {
        userID,
      }),
    });
  });

//
// Change Start Date for a User ID
// @param userID <Number>
// @param group_name <String>
// @return <Promise>
//
userEntitiesThunks.changeStartDateUser =
  (userID, startDate, reason, noScratchData) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!userID || !startDate || !reason) {
        throw new Error(
          'One of this parameter userID or startDate or reason is undefined'
        );
      }
      dispatch(userEntitiesActions.changeStartDateForUserStart(userID));

      async.waterfall(
        [
          // 1 Update user start date
          (next) => {
            const data = {
              reason: reason,
              start_date: startDate,
            };

            APIcall.post({
              data,
              error(error) {
                next(error);
              },
              success() {
                next();
              },
              token: true,
              url: endpointGenerator.genPath(
                'espUser.users.instance.changeStartDate',
                {
                  userID,
                }
              ),
            });
          },

          // 2 Update Frontend scratch
          (next) => {
            if (noScratchData) {
              next();
            } else {
              const newScratch = {
                changeDateReason: reason,
                initialStartingDate: startDate,
              };

              dispatch(
                workflowThunks.saveToFrontEndScratch(
                  newScratch,
                  (body, error) => {
                    next(error);
                  }
                )
              );
            }
          },
        ],
        (error) => {
          if (error) {
            dispatch(
              userEntitiesActions.changeStartDateForUserFail(userID, error)
            );
            reject(error);
          } else {
            dispatch(
              userEntitiesActions.changeStartDateForUserSuccess(
                userID,
                startDate,
                reason
              )
            );
            resolve();
          }
        }
      );
    });

userEntitiesThunks.changeDepartmentUser =
  (userID, newDepartment) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!userID) {
        reject('Error - no userID passed');
      }

      dispatch(userEntitiesActions.changeDepartmentUserStart(userID));
      APIcall.post({
        data: {
          department: newDepartment,
        },
        error(error) {
          dispatch(userEntitiesActions.changeDepartmentUserFail());
          reject(error);
        },
        success({ body }) {
          dispatch(
            userEntitiesActions.changeDepartmentUserSuccess(body, userID)
          );
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath(
          'espUser.users.instance.changeDepartment',
          {
            userID,
          }
        ),
      });
    });

userEntitiesThunks.getDepartmentID = (userID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const userDepartment = state.hasIn(['entities', 'users', userID])
      ? state.getIn(['entities', 'users', userID, 'department'])
      : null;

    if (!state.hasIn(['entities', 'users', userID])) {
      reject("Error - this user doesn't exist");
    }

    if (userDepartment) {
      dispatch(userEntitiesActions.getDepartmentIdStart());
      APIcall.get({
        error(error) {
          dispatch(userEntitiesActions.getDepartmentIdFail());
          reject(error);
        },
        query: {
          esp_filters: encodeURI(`name__EQ=${userDepartment}`),
        },
        success({ body }) {
          if (body.results && body.results.length > 0) {
            dispatch(
              userEntitiesActions.getDepartmentIdSuccess(
                userID,
                body.results[0]
              )
            );
          } else {
            dispatch(workflowActions.exitLoading());
          }

          resolve();
        },
        token: true,
        url: endpointGenerator.genPath('espUser.department'),
      });
    } else {
      // No department has been set for this user yet
      resolve();
    }
  });

userEntitiesThunks.loadUserOptions = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const query = {
      fields_info: true,
      model: 'espuser',
    };

    APIcall.get({
      error(error) {
        dispatch(userEntitiesActions.loadUserOptionsFail(error));
        reject();
      },
      query,
      success({ body }) {
        dispatch(
          userEntitiesActions.loadUserOptionsSuccess(
            body.results[0].fields_info
          )
        );
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('commons.object_mapping'),
    });
  });

userEntitiesThunks.loadUserElasticSearch = (query) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(userEntitiesActions.loadUserStart());
    APIcall.get({
      error() {
        dispatch(userEntitiesActions.loadUserFail());
        reject();
      },
      query: {
        q: query.searchValue,
      },
      success({ body: payload }) {
        const parseResponse = {
          count: payload.length,
          results: payload,
        };
        resolve(parseResponse);
      },
      token: true,
      url: endpointGenerator.genPath('espSearch.users'),
    });
  });

userEntitiesThunks.createUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(userEntitiesActions.addUserStart());
    APIcall.post({
      data,
      error({
        response: {
          body: { errors },
        },
      }) {
        const messageError = getErrorAsString(errors);
        dispatch(userEntitiesActions.addUserFail(messageError));
        reject();
      },
      success({ body }) {
        dispatch(userEntitiesActions.addUserSuccess());
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espUser.users'),
    });
  });

userEntitiesThunks.loadActiveSessions =
  (users = {}, userNames = []) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const espFilter = new EspFilters();
      const filterByUserIds = espFilter
        .in('user.username', userNames)
        .asQueryString();
      APIcall.get({
        error: ({ response: { body } }) => reject(body.errors),
        query: {
          detail: 'yes',
          esp_filters: filterByUserIds,
        },
        success: ({ body }) => {
          const usersWithSessions = body.results.reduce((acc, session) => {
            if (
              session.username &&
              Array.isArray(session.details) &&
              session.details.length > 0
            ) {
              acc[session.username] =
                session.details[session.details.length - 1].last_logged_in;
            }

            return acc;
          }, {});
          dispatch(
            userEntitiesActions.addUserSessionSuccess(users, usersWithSessions)
          );
          resolve();
        },
        token: true,
        url: endpointGenerator.genPath('authentication.activeSessions'),
      });
    });

userEntitiesThunks.loadUserRoles = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(userEntitiesActions.loadUserRolesStart());
    APIcall.get({
      error() {
        dispatch(userEntitiesActions.loadUserRolesFails());
        reject();
      },
      query: {
        all: 'no',
        brief: 'yes',
        limit: '1000',
      },
      success({ body }) {
        dispatch(userEntitiesActions.loadUserRolesSuccess(body?.results || []));
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espRbac.group'),
    });
  });

export default userEntitiesThunks;
