import {
  PLATFORM,
  rgxAuthSAML,
  rgxRedirectURI,
  rgxSimpleAuth,
} from '../../constants';

//
// Handle SSO in Electron
//
// Given the nature of Electron's twin-process architecture,
// the best option we have is to use the Invoke method of the
// ipcRenderer to cross the process boundary.
//
// This approach expects the ipcRenderer class to exist in window.electron.
//
// Invoke returns a Promise with the response from ipcMain once the login process
// is completed by the User on the other side. Hopefully, not the dark one...
//
// @param {String} authorizationEndpoint The URL to open in a new Electron window
// @return {Promise} result A promise that resolves with the information regarding the newly opened session
const handleElectronSSO = (authorizationEndpoint) => {
  // console.log('handleElectronSSO');
  return window.electronAPI.ipcInvoke('ssoRequest', authorizationEndpoint);
};

//
// Handle SSO in Cordova
//
// Cordova is running on the same process as this is, this allows us to handle
// the process SSO on the spot.
//
// @param {String} authorizationEndpoint The URL to open in a new Cordova inAppBroswer window
// @returns {Promise} result A promise that resolves with the information regarding the newly opened session
const handleCordovaSSO = (authorizationEndpoint) =>
  new Promise((successfulSSO, failedSSO) => {
    const cordovaInAppBrowserWindowRef = window.cordova.InAppBrowser.open(
      authorizationEndpoint,
      '_blank',
      'location=no,clearsessioncache=yes,clearcache=yes,hardwareback=no,cleardata=yes,footer=no,usewkwebview=yes'
    );

    // promisify for better readability
    const executeInLoginPopupContext = (code) =>
      new Promise((success, failure) => {
        try {
          cordovaInAppBrowserWindowRef.executeScript({ code }, success);
        } catch (cordovaCodeExecutionException) {
          failure(cordovaCodeExecutionException);
        }
      });

    //
    // Attach an event handler for the page load, this allows us to:
    //
    // 1) perform a SSO flow type check
    // 2) setup a recurrent routine that checks for token prescence in localStorage
    cordovaInAppBrowserWindowRef.addEventListener(
      'loadstart',
      async (event) => {
        const currentURL = event.url;

        try {
          await executeInLoginPopupContext(
            'localStorage.setItem("isInAppBrowser", "true")'
          );

          // if the current URL matches a SAML-type URL, OAuth + SAML SSO is infered
          if (rgxAuthSAML.match(currentURL)) {
            const refreshIntervalId = setInterval(async () => {
              try {
                const [accessToken] = await executeInLoginPopupContext(
                  'localStorage.getItem("authToken")'
                );

                if (accessToken) {
                  clearInterval(refreshIntervalId);

                  // now get the session id (it might not exist)
                  const [sessionIndex] = await executeInLoginPopupContext(
                    'localStorage.getItem("session_index")'
                  );

                  successfulSSO({
                    accessToken,
                    isOAuth: true,
                    isSAML: true,
                    sessionIndex,
                  });
                }
              } catch (exception) {
                failedSSO({
                  isOAuth: true,
                  isSAML: true,
                  message: exception,
                });
              }
            }, 500);

            // otherwise, Simple OAuth SSO is infered
          } else if (
            rgxSimpleAuth.match(currentURL) &&
            !rgxRedirectURI.match(currentURL)
          ) {
            const [, queryParams] = currentURL.split('#');

            successfulSSO({
              isOAuth: true,
              isSAML: false,
              queryParams,
            });
          }
        } catch (err) {
          failedSSO({
            isOAuth: true,
            isSAML: true,
            message: err,
          });
        }
      }
    );
  });

//
// Platform adapter
//
// This class exposes methods to handle different feature aspects that,
// given the multiple platforms our Apps run on top of, and the inherent
// differences betweeb them, need to be addressed specifically.
//
// This class doesn't eliminate the on-off implementations, it just abstracts them
// out so the Apps can care less about the platform and just invoke the functionality.
class PlatformAdapter {
  constructor() {
    this._type = PLATFORM.WEB;

    if (window.cordova) {
      this._type = PLATFORM.CORDOVA;
    } else if (window.electronAPI) {
      this._type = PLATFORM.ELECTRON;
    } else if (window.teams) {
      this._type = PLATFORM.TEAMS;
    }
  }

  get type() {
    return this._type;
  }

  handleSingleSignOn(authorizationEndpoint) {
    switch (this._type) {
      case PLATFORM.CORDOVA:
        return handleCordovaSSO(authorizationEndpoint);

      case PLATFORM.ELECTRON:
        // console.log('Handling SSO in Electron', authorizationEndpoint);
        return handleElectronSSO(authorizationEndpoint);

      case PLATFORM.TEAMS:
        return '';

      default:
        return '';
    }
  }
}

export default PlatformAdapter;
