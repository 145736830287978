import actionTypes from './actionTypes';

const reduxFormActions = {};

/**
 * Allow to reset any sync error from Redux-form
 * Because Redux-form doesn't provide any action creator for that .... :(
 * @param form
 * @returns {{type: string, meta: {form: *}, payload: {syncErrors: {}}}}
 */
reduxFormActions.forceResetSyncErrors = (form) => ({
  meta: {
    form,
  },
  payload: {
    syncErrors: {},
  },
  type: actionTypes.REDUX_FORM_RESET_SYNC_ERROR,
});

export default reduxFormActions;
