import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// molecules
import OnboardingActivityScheduleTemplate from './OnboardingActivityScheduleTemplate';
import OnboardingActivityScheduleForm from './OnboardingActivityScheduleForm';
// Global
import ScheduleState from '../../globals/ScheduleState';
// Controller
import OnboardActivityScheduleDetailController from '../controllers/OnboardActivityScheduleDetailController';

class OnboardingActivityScheduleDetail extends PureComponent {
  static propTypes = {
    /** Action ona schedule ID (copy) */
    action: PropTypes.string,

    /** Loading state */
    isLoading: PropTypes.bool,
    /** Loading template state */
    isLoadingTemplate: PropTypes.bool,
    /** Load a scheduled activity */
    loadOneScheduleActivity: PropTypes.func.isRequired,
    /** Template loader */
    loadTemplateActivityList: PropTypes.func.isRequired,
    /** next template page */
    nextTemplate: PropTypes.string,
    /** schedule data*/
    schedule: ImmutablePropTypes.map,
    /** Schedule ID */
    scheduleID: PropTypes.string,
    /** Template List*/
    templateList: ImmutablePropTypes.list,
    /** tempalte Selected with interval */
    templateSelected: ImmutablePropTypes.list,
  };

  static defaultProps = {
    action: null,
    isLoading: false,
    isLoadingTemplate: false,
    nextTemplate: null,
    schedule: Immutable.Map(),
    scheduleID: null,
    templateList: Immutable.List(),
    templateSelected: Immutable.List(),
  };

  componentDidMount() {
    const {
      loadTemplateActivityList,
      loadOneScheduleActivity,
      schedule,
      scheduleID,
      templateSelected,
    } = this.props;

    if (
      scheduleID !== 'new' &&
      (schedule.isEmpty() || templateSelected.isEmpty())
    ) {
      loadOneScheduleActivity(scheduleID);
    } else {
      loadTemplateActivityList();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { action, scheduleID, loadOneScheduleActivity } = this.props;

    const nextScheduleID = nextProps.scheduleID;

    if (
      (scheduleID === 'new' && nextScheduleID !== scheduleID) ||
      (action === 'copy' && !nextProps.action)
    ) {
      loadOneScheduleActivity(nextScheduleID);
    }
  }

  render() {
    const {
      action,
      isLoading,
      schedule,
      scheduleID,
      templateList,
      templateSelected,
      loadTemplateActivityList,
      isLoadingTemplate,
      nextTemplate,
    } = this.props;

    const scheduleStatus = schedule.get('status');
    const displayDraft =
      scheduleID === 'new' ||
      schedule.get('status') === ScheduleState.STATUS_DRAFT ||
      action === 'copy';
    const displayTemplate =
      displayDraft || schedule.get('status') === ScheduleState.STATUS_PUBLISHED;
    const widthLeft = displayTemplate ? 10 : 16;
    return (
      <Segment as={Grid} attached clearing divided>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_onboarding_activities_schedule',
          })}
        />

        <Grid.Column width={widthLeft}>
          {isLoading && (
            <Dimmer active inverted>
              <Loader inverted />
            </Dimmer>
          )}
          <OnboardingActivityScheduleForm
            action={action}
            schedule={schedule}
            scheduleID={scheduleID}
            templateSelected={templateSelected}
          />
        </Grid.Column>
        {displayTemplate && (
          <Grid.Column width={6}>
            <OnboardingActivityScheduleTemplate
              action={action}
              isLoading={isLoadingTemplate}
              loadTemplates={loadTemplateActivityList}
              next={nextTemplate}
              scheduleID={scheduleID}
              scheduleStatus={scheduleStatus}
              templateList={templateList}
            />
          </Grid.Column>
        )}
      </Segment>
    );
  }
}

const OnboardingActivityScheduleDetailTest = OnboardingActivityScheduleDetail;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardingActivityScheduleDetail = OnboardActivityScheduleDetailController(
  OnboardingActivityScheduleDetail
);

export { OnboardingActivityScheduleDetailTest };
export default OnboardingActivityScheduleDetail;
