import buildFAQFromBEScratch, {
  buildConditionTest,
} from '../utils/buildFAQFromBEScratch';
import { forEach, forIn, isEmpty, isNumber, size } from 'lodash';
import { getBaristaIntent, getBaristaIntents } from './faqAPI';
import {
  getMyTeams,
  getSelectedServiceTeamId,
} from '../selectors/getSelectedServiceTeam';
import getResponsesFromFAQ, {
  getFAQByEID,
} from '../selectors/getResponsesFromFAQ';
import Immutable, { fromJS } from 'immutable';

// Utils
import APIcall from '../utils/APIcall';
import async from 'async';
import baristaActions from './baristaActions';
// Utils
import browserHistory from '../utils/browserHistory';
import buildFAQobj from '../utils/buildFAQobj';
import BuildKBLink from '../utils/BuildKBLink';
// Thunks
import caseMgmtThunks from './caseMgmtThunks';
import casesThunks from './casesThunks';
import departmentsThunks from './departmentsThunks';
import endpointGenerator from '../utils/endpointGenerator';
import EspFilters from 'esp-util-filters';
// Actions
import faqActions from './faqActions';
// Selector
import getCurrentUser from '../selectors/getCurrentUser';
import { getFormValues } from 'redux-form/immutable';
import getTenant from '../selectors/getTenant';
import jobRoleThunks from './jobRoleThunks';
import locationThunks from './locationsThunks';
import matchingWizardActions from './matchingWizardActions';
import mergeFAQUpdate from '../utils/mergeFAQUpdate';
// Global
import NoPermissionForWFBlob from '../globals/NoPermissionForWFBlob';
import rebuildFAQAnswers from '../utils/rebuildFAQAnswers';
import UserUtils from '../utils/UserUtils';
import workflowActions from './workflowActions';
import workflowThunks from './workflowThunks';

const fetchCandidateFaqsLookup = ({
  input_text = '',
  limit = 24,
  offset,
  order_by = '-sys_date_updated',
  resp_type = 'all',
  service_department_type,
}) =>
  new Promise((resolve, reject) => {
    const query = {};
    query.limit = limit;
    query.order_by = order_by;

    if (isNumber(offset)) {
      query.offset = offset;
    }

    if (service_department_type) {
      query.attributes = query.attributes
        ? `${query.attributes}&service_department_type=${service_department_type}`
        : `service_department_type=${service_department_type}`;
    }

    if (resp_type) {
      query.resp_type = resp_type;
    }

    if (input_text) {
      query.input_text = input_text;
    }

    setTimeout(() => {
      // mock real api delay to test loading state
      APIcall.get({
        error(error) {
          reject(error);
        },
        query,
        success({ body }) {
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath(
          'espBarista.faqs.general.faqs_and_templates_lookup'
        ),
      });
    }, 500);
  });

const faqThunks = {};

faqThunks.getCurrentFaq = (faqEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!faqEID) {
      reject(new Error('The FAQ eid is missing'));
      return false;
    }

    return APIcall.get({
      error: reject,
      success({ body }) {
        dispatch(faqActions.getFAQSuccess(body));
        resolve();
      },
      token: true,
      url: endpointGenerator.genPath('espBarista.faqs.general.instance', {
        faqID: faqEID,
      }),
    });
  });
faqThunks.saveFaqAdditionalInformationToKBArticle =
  (additionalText, faqEID) => (dispatch) =>
    new Promise((resolve, reject) => {
      if (!faqEID) {
        reject(new Error('The FAQ eid is missing'));
        return false;
      }
      // POST /api/barista/v0.1/faqs/general/<faq_eid>/additional_text will return
      const url = endpointGenerator.genPath(
        'espBarista.faqs.general.instance.kbArticle',
        {
          faqID: faqEID,
        }
      );
      const data = {
        additional_text: additionalText,
      };
      return APIcall.post({
        data,
        error: reject,
        success({ body }) {
          //
          // response:
          // {
          //   "eid": "3c3bfd95-0e63-4fa0-8779-952f4d9f0fbb",
          //   "additional_text": additionalText,
          //   "match_validation": Boolean *
          // }
          // * match_validation depends on the length of the KB article additional infromation, if you want to test it use a
          // lorem ipsum extract like this as the text for additional_text param:
          // Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
          //
          dispatch(faqActions.setFaqAdditionalInformation(body));
          resolve();
        },
        token: true,
        url,
      });
    });

faqThunks.getFaqAdditionalInformationToKbArticle = (faqEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.get({
      error: reject,
      success({ body }) {
        dispatch(faqActions.setFaqAdditionalInformation(body));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath(
        'espBarista.faqs.general.instance.kbArticle',
        {
          faqID: faqEID,
        }
      ),
    });
  });

faqThunks.convertFaqToKbArticle = (status, faqEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!faqEID) {
      reject(new Error('The FAQ eid is missing'));
      return false;
    }
    const url = endpointGenerator.genPath('espBarista.faqs.general.instance', {
      faqID: faqEID,
    });
    const data = { switch_to_kb_matching: status };

    return APIcall.put({
      data,
      token: true,
      url,
    })
      .then(({ body }) => {
        if (!body.is_kb) {
          dispatch(faqActions.openFAQasKBisDisabledModal());
        }
        dispatch(matchingWizardActions.faqResetWizard());
        dispatch(faqActions.getFAQSuccess(body));
        resolve(body);
      })
      .catch((err) => {
        reject(err);
      });
  });

faqThunks.getFAQToEditResponse = (faqEID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(faqActions.getFAQStart());
    let responses;

    const typeCondition = {
      DEPARTMENTS: 'departments',
      JOBROLES: 'job_roles',
      LOCATIONS: 'locations',
    };

    const methodByType = {
      departments: (id) => (dispatch) =>
        dispatch(departmentsThunks.getByID(id)),
      job_roles: (id) => (dispatch) =>
        dispatch(jobRoleThunks.getJobRoles(void 0, id)),
      locations: (id) => (dispatch) => dispatch(locationThunks.getByID(id)),
    };

    // Main function to get the name for a location, a department or a job role
    const checkForName = (type, next) => {
      async.eachOf(
        responses,
        (value, key, done) => {
          // If any condition set for this type, we need to get the name for each one
          if (
            value.locationdeptrole[type] &&
            value.locationdeptrole[type].length
          ) {
            async.eachOf(
              value.locationdeptrole[type],
              (entry, index, cb) => {
                // Load the name here via methodByType[type]
                dispatch(methodByType[type](entry.id))
                  .then((result) => {
                    // Mutate the object by passing the name
                    value.locationdeptrole[type][index].name = result[0].name;
                    cb();
                  })
                  .catch((err) => {
                    cb(err);
                  });
              },
              (err) => {
                if (!err) {
                  done(null);
                } else {
                  done(err);
                }
              }
            );
          } else {
            done(null);
          }
        },
        (err) => {
          if (!err) {
            next(null);
          } else {
            next(err);
          }
        }
      );
    };

    // If we need a location / department / job role name
    async.waterfall(
      [
        // 0 . Refresh the FAQ data
        (next) => {
          const keepLoadingFAQ = true; // We want to keep loading FAQ until the very end of this waterfall
          dispatch(faqThunks.refreshFAQbyEID(faqEID, keepLoadingFAQ)).then(
            () => {
              responses = getResponsesFromFAQ(getState(), faqEID);
              next(null);
            }
          );
        },
        // 1 . load Each Locations name
        (next) => {
          checkForName(typeCondition.LOCATIONS, next);
        },

        // 2 . Load Each Departments Name
        (next) => {
          checkForName(typeCondition.DEPARTMENTS, next);
        },

        // 3 . Load Each Job Roles Name
        (next) => {
          checkForName(typeCondition.JOBROLES, next);
        },
      ],
      (err) => {
        if (err) {
          dispatch(faqActions.getFAQFail());
          reject(err);
        } else {
          // Here we return the mutated responses object with the name for each condition
          dispatch(faqActions.getFAQSuccess(responses));
          resolve(responses);
        }
      }
    );
  });

faqThunks.setFaqBEScratch =
  (forceReBuild, taskSelected, kbTitle) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      let newDataScratch, newFAQ, newLocDeptJobToLoad;

      // Get the selected template if exists - We will need to repass this property to keep track of
      // this selected template in teach barista
      const selectedTemplate = getState().getIn([
        'workflowState',
        'backendScratch',
        'scratch.temp_data',
        'selected_template',
      ]);
      const entityLabel = getState().getIn([
        'workflowState',
        'backendScratch',
        'scratch.temp_data',
        'entityLabel',
      ]);

      async.waterfall(
        [
          // 1 . Refresh data from this FAQ and build the newFAQ obj
          (next) => {
            const selectedEID = getState().getIn([
              'adminFaqs',
              'selectedFAQtoEdit',
            ]);

            if (!selectedEID) {
              next(
                new Error(
                  'There is no FAQ EID passed to this WF, maybe you launched this WF outside the Admin area'
                )
              );
            } else {
              dispatch(faqThunks.refreshFAQbyEID(selectedEID))
                // Set the service department selected if we are using the WF from KNOWLEDGE in Teach Barista
                .then(
                  (faq) =>
                    new Promise((resolve, reject) => {
                      if (kbTitle) {
                        // We want to load the service department selected here
                        const { attributes } = faq;
                        // Search for service departement
                        let departmentEID, departmentTypeEID;
                        attributes.forEach((attribute) => {
                          if (attribute.name === 'service_department') {
                            departmentEID = attribute.value;
                          } else if (
                            attribute.name === 'esp.service_department_type'
                          ) {
                            departmentTypeEID = attribute.value;
                          }
                        });

                        if (departmentEID) {
                          // We found a department, we need to get the ID and the type
                          const query = {
                            esp_filters: `eid__EQ=${departmentEID}`,
                          };
                          APIcall.get({
                            query,
                            token: true,
                            url: endpointGenerator.genPath(
                              'espCaseMgmt.serviceDepartment'
                            ),
                          })
                            .then(({ body }) => {
                              let departmentID, departmentType;
                              if (body.results && body.results.length) {
                                departmentID = body.results[0].id;
                                departmentType =
                                  body.results[0].service_department;
                              }

                              if (!departmentID) {
                                reject(
                                  new Error(
                                    `No service department match the eid:${departmentEID}`
                                  )
                                );
                              } else {
                                dispatch(
                                  baristaActions.faqSelectDepartment(
                                    departmentID,
                                    departmentType
                                  )
                                );
                                resolve(faq);
                              }
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        } else {
                          dispatch(
                            baristaActions.faqSelectDepartment(
                              null,
                              departmentTypeEID
                            )
                          );
                          resolve(faq);
                        }
                      } else {
                        resolve(faq);
                      }
                    })
                )
                .then(() => {
                  newFAQ = buildFAQobj(getState()); // Build the obj to pass
                  if (newFAQ.error) {
                    // eslint-disable-next-line no-console -- debugging
                    console.error(newFAQ.error); // Keep this to know why an FAQ is failing
                    next(newFAQ.error);
                  } else {
                    newDataScratch = newFAQ.dataScratch;
                    newLocDeptJobToLoad = newFAQ.locDeptJobToLoad;
                  }
                  next();
                })
                .catch((err) => {
                  next(err);
                });
            }
          },

          // 2 . Load the category ID selected
          (next) => {
            if (!newFAQ.category) {
              dispatch(
                casesThunks.loadTaskCategories(
                  newDataScratch['scratch.temp_data'].category_faq.name,
                  null,
                  newDataScratch['scratch.temp_data'].category_faq
                    .serviceDepartment
                )
              )
                .then((results) => {
                  if (results && results[0]) {
                    newDataScratch['scratch.temp_data'].category_faq.id =
                      results[0].id;
                    next(null);
                  } else {
                    next(
                      new Error(
                        `No category match the name ${newDataScratch['scratch.temp_data'].category_faq.name}`
                      )
                    );
                  }
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              newDataScratch['scratch.temp_data'].category_faq.id =
                newFAQ.category.get('id');
              next(null);
            }
          },

          // 3 . Load Each location Name
          (next) => {
            if (newLocDeptJobToLoad.locations.length) {
              async.eachOf(
                newLocDeptJobToLoad.locations,
                (id, key, done) => {
                  dispatch(locationThunks.getByID(id))
                    .then((result) => {
                      forIn(
                        newDataScratch['scratch.temp_data'].faq_response,
                        (value) => {
                          if (value.locationdeptrole.locations) {
                            value.locationdeptrole.locations.forEach((c) => {
                              if (c.id === id) {
                                c.name = result[0].name;
                              }
                            });
                          }
                        }
                      );
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },

          // 4 . Load Each Departments Name
          (next) => {
            if (newLocDeptJobToLoad.departments.length) {
              async.eachOf(
                newLocDeptJobToLoad.departments,
                (id, key, done) => {
                  dispatch(departmentsThunks.getByID(id))
                    .then((result) => {
                      forIn(
                        newDataScratch['scratch.temp_data'].faq_response,
                        (value) => {
                          if (value.locationdeptrole.departments) {
                            value.locationdeptrole.departments.forEach((c) => {
                              if (c.id === id) {
                                c.name = result[0].name;
                              }
                            });
                          }
                        }
                      );
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },

          // 5 . Load Each Job Role Name
          (next) => {
            if (newLocDeptJobToLoad.job_roles.length) {
              async.eachOf(
                newLocDeptJobToLoad.job_roles,
                (id, key, done) => {
                  dispatch(jobRoleThunks.getJobRoles(void 0, id))
                    .then((result) => {
                      forIn(
                        newDataScratch['scratch.temp_data'].faq_response,
                        (value) => {
                          if (value.locationdeptrole.job_roles) {
                            value.locationdeptrole.job_roles.forEach((c) => {
                              if (c.id === id) {
                                c.name = result[0].name;
                              }
                            });
                          }
                        }
                      );
                      done();
                    })
                    .catch((err) => {
                      done(err);
                    });
                },
                (err) => {
                  if (!err) {
                    next(null);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null);
            }
          },

          // 6 . Post the new Created BE Scratch
          (next) => {
            // When we force, we want to update the existing BE scratch and not override it
            const method = forceReBuild
              ? 'updateTempDataBackEndScratch'
              : 'saveToBackEndScratch';
            if (forceReBuild) {
              newDataScratch = newDataScratch['scratch.temp_data'];
              // Get the new trigger variation to add it if a taskSelected has been passed or a a knowledge
              // but only if it's not an ELC (protected content)
              if (
                !newDataScratch.is_faq_protected &&
                (taskSelected || kbTitle)
              ) {
                // First check if this trigger doesn't already exist to avoid duplicate entries
                let alreadyExist = false;
                const newTrigger = kbTitle || taskSelected.get('title');
                forEach(newDataScratch.phrases_faq, (value) => {
                  if (value.phrase === newTrigger) {
                    alreadyExist = true;
                  }
                });

                // The value doesn't exist, we can add it as new trigger
                if (!alreadyExist) {
                  const sizeOf = size(newDataScratch.phrases_faq);
                  if (!newDataScratch.phrases_faq[sizeOf]) {
                    newDataScratch.phrases_faq[sizeOf] = {
                      phrase: newTrigger,
                    };
                  } else {
                    newDataScratch.phrases_faq[sizeOf + 1] = {
                      phrase: newTrigger,
                    };
                  }
                }
              } else if (kbTitle) {
                // Pass the title of this KB link as trigger
                const sizeOf = size(newDataScratch.phrases_faq);
                if (!newDataScratch.phrases_faq[sizeOf]) {
                  newDataScratch.phrases_faq[sizeOf] = {
                    phrase: kbTitle,
                  };
                } else {
                  newDataScratch.phrases_faq[sizeOf + 1] = {
                    phrase: kbTitle,
                  };
                }
              }
            }

            // Pass the selected template and entity label into our BE scratch
            if (selectedTemplate && !selectedTemplate.isEmpty()) {
              newDataScratch['scratch.temp_data'].selected_template =
                selectedTemplate.toJS();
              newDataScratch['scratch.temp_data'].entityLabel = entityLabel;
            }
            dispatch(workflowThunks[method](newDataScratch))
              .then((workflowRequest) => {
                next(null, workflowRequest.backend_scratch);
              })
              .catch((err) => {
                next(err);
              });
          },
        ],
        (err, backendScratch) => {
          if (err) {
            reject(err);
          } else {
            dispatch(workflowActions.updateAttributesFromBEScratch()); // Update the attributes
            resolve(Immutable.fromJS(backendScratch));
          }
        }
      );
    });

/**
 * Refresh the data of an FAQ
 * @param faqEID {string} - Current EID of the FAQ that we want to refresh
 * @param keepLoadingFAQ {boolean} - We will force to keep the isLoadingFAQ state to true at the end of this call
 * @returns {function(*=): Promise}
 */
faqThunks.refreshFAQbyEID =
  (faqEID, keepLoadingFAQ = false, answer_brief = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!faqEID) {
        reject(new Error('The FAQ eid is missing'));
        return false;
      }

      dispatch(baristaActions.faqUpdateStart(faqEID));
      return APIcall.get({
        error(err) {
          dispatch(baristaActions.faqUpdateFail(faqEID));
          reject(err);
        },
        query: {
          answer_brief: answer_brief,
        },
        success({ body }) {
          dispatch(baristaActions.faqUpdate(body, null, keepLoadingFAQ));
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('espBarista.faqs.general.instance', {
          faqID: faqEID,
        }),
      });
    });

faqThunks.setTeachBaristaBEScratch =
  (taskId, kbTitle) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      // First - Check if the user has the correct permission to launch this WF (admi nor faq_admin only)
      const user = getCurrentUser(state);
      const isAdminOrFAQAdmin =
        UserUtils.isAdmin(user) || UserUtils.isFaqAdmin(user);

      if (!isAdminOrFAQAdmin || (!taskId && !kbTitle)) {
        // The user doesn't have access to teach barista
        dispatch(workflowActions.resumeWorkflowFail(NoPermissionForWFBlob));
        reject();
      } else {
        const task = state.getIn(['entities', 'tasks', taskId]);

        const dataScratch = {
          'scratch.temp_data': {
            faq_initial_question: kbTitle || task.get('title'),
            faq_question: kbTitle || task.get('title'),
            faq_trigger: kbTitle || task.get('title'),
          },
        };

        dispatch(workflowThunks.saveToBackEndScratch(dataScratch))
          .then(() => {
            resolve(task);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });

faqThunks.setValueFAQFromKB = (kbTitle) => (dispatch) =>
  new Promise((resolve, reject) => {
    const kbLink = BuildKBLink();
    // We pass here the title as description, first question and trigger
    // We also pass the the KB link as first responses with emtpy condition
    const dataScratch = {
      faq_desc: kbTitle,
      faq_question: kbTitle,
      faq_response: {
        0: {
          active: true,
          locationdeptrole: {},
          order: 1,
          //
          response: kbLink,
        },
      },
      faq_trigger: kbTitle,
    };
    // Pass the link and title to the BE scratch
    dispatch(workflowThunks.updateTempDataBackEndScratch(dataScratch)) // Update BE scratch to buidl the condition
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

faqThunks.getFAQMatchByTrigger =
  (task = Immutable.Map(), kbTitle) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.loadFAQMatchStart());
      dispatch(workflowActions.loading());

      const trigger = kbTitle || task.get('title'); // The title of the task is in fact the main trigger
      const departmentID = task.getIn([
        'service_team',
        'service_department_id',
      ]); // Get my default service department
      const departmentTypeID = task.getIn([
        'service_department',
        'service_department_type_id',
      ]);

      // Set the selected FAQ
      dispatch(
        baristaActions.faqSelectDepartment(departmentID, departmentTypeID)
      );

      const query = {
        attribute_name:
          'category&service_department&OResp.task_category_type&esp.service_department_type',
      };
      if (!kbTitle && !task.isEmpty()) {
        query.attributes = `service_department_type=${departmentTypeID}`; // Filter by service department Type
      }
      query.is_archetype = 'False'; // Filter archetype type
      query.best_match = true;
      query.input_text = trigger; // now this goes as GET query params

      APIcall.get({
        query,
        token: true,
        url: endpointGenerator.genPath('espBarista.faqs.general.intentLookup'),
      })
        .then(
          ({ body }) =>
            new Promise((res, rej) => {
              const faq = body.results ? body.results[0] : null;
              if (faq) {
                dispatch(workflowActions.loading()); // Set back the WF in loading state
                dispatch(baristaActions.faqSelectFAQtoEdit(faq.eid)); // Preselect the FAQ

                const dataScratch = {
                  case_trigger: faq.name,
                };
                dispatch(
                  workflowThunks.updateTempDataBackEndScratch(dataScratch)
                ) // Update BE scratch to buidl the condition
                  .then(() => {
                    res(faq);
                  })
                  .catch((err) => {
                    rej(err);
                  });
              } else {
                res(); // no faq matched the trigger
              }
            })
        )
        .then(
          (faq) =>
            new Promise((res, rej) => {
              if (faq) {
                // Build the FAQ obj BE scratch
                const forceReBuild = true;

                dispatch(faqThunks.setFaqBEScratch(forceReBuild, task, kbTitle))
                  .then(() => {
                    res(faq);
                  })
                  .catch((err) => {
                    rej(err);
                  });
              } else {
                res();
              }
            })
        )
        .then((faq) => {
          if (faq) {
            dispatch(faqActions.loadFAQMatchSuccess(faq));
          }
          dispatch(workflowActions.exitLoading());
          resolve(faq);
        })
        .catch((err) => {
          dispatch(faqActions.loadFAQMatchFail());
          dispatch(workflowActions.exitLoading());
          reject(err);
        });
    });

faqThunks.sendFAQReport = (taskId, checkFromKB) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(faqActions.sendFAQReportStart());

    const state = getState();
    const tenant =
      state.getIn(['tenant', 'name', 'value']) || getTenant(state).get('name');
    const currentUser = getCurrentUser(state);
    const usrEmail = currentUser ? getCurrentUser(state).get('email') : null;
    const faqSelected = state.getIn([
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
      'faq_selected',
      'faq_selected',
    ]);
    const taskSelectedURL = endpointGenerator.genPath('task.tasks.instance', {
      taskPK: taskId,
    });

    const body = `Msg: FAQ Report for ELC<br> Tenant: ${tenant}<br> User email: ${usrEmail}`;
    const kbLink = BuildKBLink();
    const subject =
      checkFromKB && kbLink
        ? `Knowledge link: ${kbLink}`
        : `Case Selected: ${taskSelectedURL}, ELC Selected: ${faqSelected}`;

    const feedbackMessage = {
      body,
      receiver_category: 'ELC_SUPPORT',
      subject,
    };

    const endPts = endpointGenerator.genPath('espNotification.supportEmail');

    APIcall.post({
      data: feedbackMessage,
      token: true,
      url: endPts,
    })
      .then((res) => {
        dispatch(faqActions.sendFAQReportSuccess());
        resolve(res);
      })
      .catch((err) => {
        dispatch(faqActions.sendFAQReportFail());
        reject(err);
      });
  });

faqThunks.editTriggers = (newTriggers, faqEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(faqActions.updateFaqStart());

    const data = {
      triggers: [],
    };
    newTriggers.forEach((trigger) => {
      if (typeof trigger === 'string') {
        if (trigger) {
          // avoid inserting empty
          data.triggers.push({
            phrase: trigger,
          });
        }
      } else {
        if (trigger.get('phrase')) {
          // avoid inserting empty
          data.triggers.push(trigger.toJS());
        }
      }
    });
    const url = endpointGenerator.genPath('espBarista.faqs.general.instance', {
      faqID: faqEID,
    });
    APIcall.put({
      data,
      token: true,
      url,
    })
      .then(({ body }) => {
        dispatch(faqActions.updateFaqSuccess(body));
        resolve();
      })
      .catch((err) => {
        dispatch(faqActions.updateFaqFail(err));
        reject();
      });
  });

faqThunks.editResponsesFAQ = (newResponses, faqEID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(faqActions.updateFaqStart());

    const faqList = getState().getIn(['adminFaqs', 'faqsList']);
    const imageNewFaq = getState().getIn(['adminFaqs', 'imageNewFaq']);
    const selectedFAQ = faqList.find((entry) => entry.get('eid') === faqEID);

    const newResponsesBuild = rebuildFAQAnswers(
      newResponses,
      {
        answers: [],
      },
      imageNewFaq
    );

    const newSelectedFAQ = mergeFAQUpdate(
      newResponsesBuild.newObj,
      selectedFAQ
    );

    const url = endpointGenerator.genPath('espBarista.faqs.general.instance', {
      faqID: newSelectedFAQ.get('eid'),
    });

    // Fix for DEV-7163 (don't send out the attributes as some may be corrupted)
    const reqNewSelectedFAQ = newSelectedFAQ.toJS();
    reqNewSelectedFAQ.attributes = reqNewSelectedFAQ.attributes.map(
      (attribute) =>
        // we should only save the eid
        ({
          eid: attribute.eid,
        })
    );

    async.waterfall(
      [
        (next) => {
          APIcall.put({
            data: reqNewSelectedFAQ,
            token: true,
            url,
          })
            .then(({ body }) => {
              next(null, body);
            })
            .catch((err) => {
              next(err);
            });
        },

        // 2 . Upload file if needed
        (newFaq, next) => {
          if (newResponsesBuild.fileToUpload.length) {
            const intentID = newFaq.eid;
            const emptyLineChecked = [];

            const upLoadFile = (file, done) => {
              // Get index for this answer EID condition
              let answerIndex;
              newFaq.answers.forEach((ans, i) => {
                // Search for the index position of the answer
                if (ans.condition === file.conditions) {
                  answerIndex = i;
                }
              });

              // Get the answer eid
              const responseID = newFaq.answers[answerIndex].eid;

              // Search for the line where to pass the attachment by looking for empty phrase with a space
              let lineSelected;
              const existingLines = newFaq.answers[answerIndex].lines;
              for (let i = 0; i < existingLines.length; i++) {
                if (
                  !existingLines[i].phrase &&
                  !existingLines[i].url &&
                  emptyLineChecked.indexOf(existingLines[i].eid) === -1
                ) {
                  lineSelected = existingLines[i];
                  emptyLineChecked.push(existingLines[i].eid);
                  break;
                }
              }

              // Build the form data with the file for this line
              const finalData = new FormData();
              finalData.append('file', file.file);
              finalData.append('order', file.lineOrder);

              const url = endpointGenerator.genPath(
                'espBarista.faqs.general.instance.answers.instance.lines.instance',
                {
                  answerID: responseID,
                  faqID: intentID,
                  lineID: lineSelected.eid,
                }
              );

              APIcall.put({
                data: finalData,
                error(err) {
                  done(err);
                },
                success({ body }) {
                  // Pass this new Attachment as a new line in the selected FAQ
                  newFaq.answers[answerIndex].lines = newFaq.answers[
                    answerIndex
                  ].lines.map((l) => {
                    if (Number(l.order) === Number(body.order)) {
                      return body;
                    }
                    return l;
                  });
                  done();
                },
                token: true,
                url,
              });
            };

            async.eachOf(
              newResponsesBuild.fileToUpload,
              (file, key, done) => {
                upLoadFile(file, done);
              },
              (err) => {
                if (!err) {
                  next(null, newFaq);
                } else {
                  next(err);
                }
              }
            );
          } else {
            next(null, newFaq);
          }
        },
      ],
      (err, response) => {
        if (err) {
          dispatch(faqActions.updateFaqFail());
          reject();
        } else {
          dispatch(faqActions.updateFaqSuccess(response));
          resolve();
        }
      }
    );
  });

faqThunks.createNewFaqFromBEScratch =
  (updateFAQ = false, fromEntity = false) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(workflowActions.loading());
      const state = getState();

      let selectedFAQ = null;

      /**
       * Get the current FAQ to update
       */
      if (updateFAQ) {
        // Load the selected FAQ then build the BE scratch object
        const selectedEID = state.getIn(['adminFaqs', 'selectedFAQtoEdit']);

        if (!selectedEID) {
          reject(
            new Error(
              'There is no FAQ EID passed to this WF, maybe you launched this WF outside the Admin area'
            )
          );
        }

        selectedFAQ = state
          .getIn(['adminFaqs', 'faqsList'])
          .find((f) => f.get('eid') === selectedEID);
      }

      const newFAQ = buildFAQFromBEScratch(state, fromEntity);

      if (newFAQ.error) {
        reject(newFAQ.error);
      }

      async.waterfall(
        [
          // 1. Check the service department name
          (next) => {
            if (!newFAQ.serviceDepartmentId) {
              next(null, '');
            } else {
              const serviceDepartmentSelected = state
                .getIn(['caseMgmt', 'serviceDepartment', 'list'])
                .find(
                  (dept) =>
                    Number(dept.get('id')) ===
                    Number(newFAQ.serviceDepartmentId)
                );

              const serviceDepartmentEID =
                serviceDepartmentSelected &&
                !serviceDepartmentSelected.isEmpty()
                  ? serviceDepartmentSelected.get('eid')
                  : null;

              if (serviceDepartmentEID) {
                next(null, serviceDepartmentEID);
              } else {
                dispatch(
                  caseMgmtThunks.loadServiceDepartmentById(
                    newFAQ.serviceDepartmentId
                  )
                )
                  .then((department) => {
                    next(null, department.eid);
                  })
                  .catch((err) => {
                    next(err);
                  });
              }
            }
          },

          // 2. Create the new FAQs
          (serviceDepartmentEID, next) => {
            if (serviceDepartmentEID) {
              newFAQ.objectFaq.attributes[1].value = serviceDepartmentEID;
            }
            newFAQ.objectFaq.application_name = newFAQ.faqName; // Pass this param to force to create a new FAQ App or add a new intent to an existing one
            let newSelectedFAQ = null;

            if (updateFAQ) {
              newSelectedFAQ = mergeFAQUpdate(newFAQ.objectFaq, selectedFAQ);
            }

            const url = updateFAQ
              ? endpointGenerator.genPath('espBarista.faqs.general.instance', {
                  faqID: newSelectedFAQ.get('eid'),
                })
              : endpointGenerator.genPath('espBarista.faqs.general');

            const method = updateFAQ ? 'put' : 'post';

            if (fromEntity) {
              // Remove triggers when it's from Entity (Teach Barista WF).
              delete newFAQ.objectFaq.triggers;
            } else if (newFAQ.faqIsProtected) {
              // or Remove triggers when the FAQ is protected (ELC)
              newSelectedFAQ = newSelectedFAQ.delete('triggers');
            }

            APIcall[method]({
              data: newSelectedFAQ ? newSelectedFAQ.toJS() : newFAQ.objectFaq,
              error(err) {
                next(err);
              },
              success({ body }) {
                next(null, body);
              },
              token: true,
              url,
            });
          },

          // 3. Upload files if needed
          (newFaq, next) => {
            if (newFAQ.fileToUpload.length) {
              const intentID = newFaq.eid;
              const emptyLineChecked = [];

              const upLoadFile = (file, done) => {
                // Get index for this answer EID condition
                let answerIndex;
                newFaq.answers.forEach((ans, i) => {
                  // Search for the index position of the answer
                  if (ans.condition === file.conditions) {
                    answerIndex = i;
                  }
                });

                // Get the answer eid
                const responseID = newFaq.answers[answerIndex].eid;

                // Search for the line where to pass the attachment by looking for empty phrase with a space
                let lineSelected;
                const existingLines = newFaq.answers[answerIndex].lines;
                for (let i = 0; i < existingLines.length; i++) {
                  if (
                    !existingLines[i].phrase &&
                    !existingLines[i].url &&
                    emptyLineChecked.indexOf(existingLines[i].eid) === -1
                  ) {
                    lineSelected = existingLines[i];
                    emptyLineChecked.push(existingLines[i].eid);
                    break;
                  }
                }

                // Build the form data with the file for this line
                const finalData = new FormData();
                finalData.append('file', file.file);
                finalData.append('order', file.lineOrder);

                const url = endpointGenerator.genPath(
                  'espBarista.faqs.general.instance.answers.instance.lines.instance',
                  {
                    answerID: responseID,
                    faqID: intentID,
                    lineID: lineSelected.eid,
                  }
                );

                APIcall.put({
                  data: finalData,
                  error(err) {
                    done(err);
                  },
                  success({ body }) {
                    // Pass this new Attachment as a new line in the selected FAQ
                    newFaq.answers[answerIndex].lines = newFaq.answers[
                      answerIndex
                    ].lines.map((l) => {
                      if (Number(l.order) === Number(body.order)) {
                        return body;
                      }
                      return l;
                    });
                    done();
                  },
                  token: true,
                  url,
                });
              };

              async.eachOf(
                newFAQ.fileToUpload,
                (file, key, done) => {
                  upLoadFile(file, done);
                },
                (err) => {
                  if (!err) {
                    next(null, newFaq);
                  } else {
                    next(err);
                  }
                }
              );
            } else {
              next(null, newFaq);
            }
          },
        ],
        (err, response) => {
          if (err) {
            dispatch(workflowActions.exitLoading());
            reject(err);
          } else {
            if (updateFAQ) {
              dispatch(baristaActions.faqUpdate(response));
            } else {
              // Force to insert the new added at the top
              dispatch(baristaActions.faqAdd(response));
              // Reload the current page (will not force to reload the list if the queries didn't change)
              browserHistory.push({
                pathname: browserHistory.location.pathname,
              });
            }
            dispatch(workflowActions.exitLoading());
            resolve();
          }
        }
      );
    });

faqThunks.updateEntityValueInstance = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(workflowActions.loading());

    const state = getState();
    const beScratch = state.getIn([
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
    ]);
    const synonym = beScratch.get('synonym');
    const entityEID = beScratch.getIn(['selected_template', 'template']);
    const valueEID = beScratch.getIn(['selected_template', 'word']);

    if (!synonym || (Immutable.Map.isMap(synonym) && synonym.isEmpty())) {
      // No need to send an empty synonym, just resolve the promise here
      resolve();
    } else if (!entityEID || !valueEID) {
      // Data error here... one value is missing ofr some reason
      reject(
        new Error(
          `Data error - either entityEID or valueEID is missing. entityEID: ${entityEID} - valueEID:${valueEID}`
        )
      );
    } else {
      // We now have to add new synonym(s) to the entity value instance
      // If it's a string, we want to add only one synoym. An object means that we want to add multiple synonyms
      let data = {
        active: true,
        entity: entityEID,
        is_protected: false,
        label: synonym,
        normalized_label: null,
      };

      if (Immutable.Map.isMap(synonym)) {
        // These are multiple synonyms
        data = {
          instances: [],
        };
        synonym.forEach((entry) => {
          data.instances.push({
            label: entry.get('phrase'),
          });
        });
      }

      APIcall.post({
        data,
        token: true,
        url: endpointGenerator.genPath(
          'espBarista.entities.instance.values.instance.instances',
          {
            entityEID,
            valueEID,
          }
        ),
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    }
  });

faqThunks.addFileToFaq = (answerEID, file) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(baristaActions.faqLoadImgStart());
    const state = getState();

    // Load the selected FAQ then build the BE scratch object
    const selectedEID = state.getIn(['adminFaqs', 'selectedFAQtoEdit']);

    if (!selectedEID) {
      dispatch(baristaActions.faqLoadImgFail());
      reject(
        new Error(
          'There is no FAQ EID passed to this WF, maybe you launched this WF outside the Admin area'
        )
      );
    }

    const selectedFAQ = state
      .getIn(['adminFaqs', 'faqsList'])
      .find((f) => f.get('eid') === selectedEID);

    if (!selectedFAQ || selectedFAQ.isEmpty()) {
      dispatch(baristaActions.faqLoadImgFail());
      reject(new Error(`The FAQ with the EID: ${selectedEID} does not exist.`));
    }

    const answers = selectedFAQ
      .get('answers')
      .find((ans) => ans.get('eid') === answerEID);

    if (!answers || answers.isEmpty()) {
      dispatch(baristaActions.faqLoadImgFail());
      reject(
        new Error(
          `There is an issue with the answers of the FAQ ${selectedEID}`
        )
      );
    }

    const responseID = answers.get('eid');
    const intentID = selectedFAQ.get('eid');

    const finalData = new FormData();
    finalData.append('file', file);

    APIcall.post({
      data: finalData,
      error(err) {
        dispatch(baristaActions.faqLoadImgFail());
        reject(err);
      },
      success({ body }) {
        // Pass this new Attachment as a new line in the selected FAQ
        const { eid, response, url } = body;

        dispatch(
          baristaActions.faqLoadImgSuccess(eid, response, url, selectedEID)
        );
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath(
        'espBarista.intents.instance.responses.instance.phrases',
        {
          intentID,
          responseID,
        }
      ),
    });
  });

faqThunks.toggleFAQ =
  (faqID, newStatus, displayFilter) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!faqID) {
        reject(new Error('The FAQ eid is missing'));
        return false;
      }

      let newFAQ = getState()
        .getIn(['adminFaqs', 'faqsList'])
        .find((f) => f.get('eid') === faqID);

      if (!newFAQ || newFAQ.isEmpty()) {
        reject(new Error(`The FAQ eid ${faqID} does not exist`));
        return false;
      }

      dispatch(baristaActions.faqUpdateStart(faqID));

      newFAQ = newFAQ.set('active', newStatus);

      return APIcall.put({
        data: newFAQ.toJS(),
        error(err) {
          dispatch(baristaActions.faqUpdateFail(faqID));
          reject(err);
        },
        query: { answer_brief: false },
        success({ body }) {
          dispatch(baristaActions.faqUpdate(body, displayFilter));
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('espBarista.faqs.general.instance', {
          faqID,
        }),
      });
    });

faqThunks.toggleReviewFAQ =
  (faqID, newStatus, displayFilter) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!faqID) {
        reject(new Error('The FAQ eid is missing'));
        return false;
      }

      let newFAQ = getState()
        .getIn(['adminFaqs', 'faqsList'])
        .find((f) => f.get('eid') === faqID);

      if (!newFAQ || newFAQ.isEmpty()) {
        reject(new Error(`The FAQ eid ${faqID} does not exist`));
        return false;
      }

      dispatch(baristaActions.faqUpdateStart(faqID));

      newFAQ = newFAQ.set('is_reviewed', newStatus);

      return APIcall.put({
        data: newFAQ.toJS(),
        error(err) {
          dispatch(baristaActions.faqUpdateFail(faqID));
          reject(err);
        },
        query: { answer_brief: false },
        success({ body }) {
          dispatch(baristaActions.faqUpdate(body, displayFilter));
          resolve(body);
        },
        token: true,
        url: endpointGenerator.genPath('espBarista.faqs.general.instance', {
          faqID,
        }),
      });
    });

faqThunks.saveFaqDetails = (faqEID, formValues) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const faq = getFAQByEID(getState(), faqEID);

    // update attributes
    // we need to update if they exist
    // or push a new element in the array if they don't
    const formCategoryValue = formValues.get('category');
    const formServiceTeamValue = formValues.get('service_team');
    const formServiceDepartmentalue = formValues.get('service_department');
    const formAcknowledgement = formValues.get('acknowledgment');
    const formAcknowledgementEid = formValues.get('acknowledgmentEid');

    const attributes = faq && faq.get('attributes', fromJS([])).toJS();

    // Part 1: update category
    if (formCategoryValue) {
      const categoryAttr = attributes.find((attr) => attr.name === 'category');
      if (categoryAttr) {
        categoryAttr.value = formCategoryValue;
      } else {
        // if doesn't exist, create a new attribute
        attributes.push({
          name: 'category',
          value: formCategoryValue,
        });
      }
    }

    // Part 2: update service team
    if (formServiceTeamValue) {
      const serviceTeamAttr = attributes.find(
        (attr) => attr.name === 'service_team'
      );
      if (serviceTeamAttr) {
        serviceTeamAttr.value = formServiceTeamValue;
      } else {
        // if doesn't exist, create a new attribute
        attributes.push({
          name: 'service_team',
          value: formServiceTeamValue,
        });
      }
    }

    // Part 3: update service department
    if (formServiceDepartmentalue) {
      const serviceTeamAttr = attributes.find(
        (attr) => attr.name === 'service_department'
      );
      if (serviceTeamAttr) {
        serviceTeamAttr.value = formServiceDepartmentalue;
      } else {
        // if doesn't exist, create a new attribute
        attributes.push({
          name: 'service_department',
          value: formServiceDepartmentalue,
        });
      }
    }

    // Part 4: Acknowledgement (single one for now
    let acknowledgments;
    // Only update if eid exist or it has contents for new
    if (formAcknowledgementEid || formAcknowledgement) {
      acknowledgments = [];
      if (formAcknowledgementEid && !formAcknowledgement) {
        // if there's an eid but the phrase is empty
        // it means that the user wants to delete it acknowledgement
        // in such case just save an empty array as acknowledgement
        // so do nothing in this case
      } else {
        // For now this feature (DEV-8422) implies saving only 1 acknowledgement
        acknowledgments.push({
          eid: formAcknowledgementEid,
          phrase: formAcknowledgement,
        });
      }
    }

    dispatch(baristaActions.faqUpdateStart(faqEID));
    return APIcall.put({
      data: {
        acknowledgments: acknowledgments ? acknowledgments : void 0,
        attributes: attributes ? attributes : void 0,
        description: formValues.get('description'),
        name: formValues.get('name') ? formValues.get('name') : void 0,
      },
      error(err) {
        dispatch(baristaActions.faqUpdateFail(faqEID));
        reject(err);
      },
      query: { answer_brief: false },
      success({ body }) {
        dispatch(baristaActions.faqUpdate(body));
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espBarista.faqs.general.instance', {
        faqID: faqEID,
      }),
    });
  });

faqThunks.getFaqServiceDepartmentByEid = (departmentEid) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCaseMgmt.serviceDepartment');
    const query = {
      esp_filters: `eid__EQ=${departmentEid}`,
    };

    APIcall.get({
      error(err) {
        reject(err);
      },
      query,
      success({ body }) {
        const [department] = body.results;
        dispatch(
          faqActions.setFaqServiceDepartmentSuccess(department, departmentEid)
        );

        resolve(department);
      },
      token: true,
      url,
    });
  });

faqThunks.getFaqServiceTeamByEid = (teamEid) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espCaseMgmt.serviceTeam');
    const query = {
      esp_filters: `eid__EQ=${teamEid}`,
    };

    APIcall.get({
      error(err) {
        reject(err);
      },
      query,
      success({ body }) {
        const [team] = body.results;
        dispatch(faqActions.setFaqServiceTeamSuccess(team, teamEid));
        resolve(team);
      },
      token: true,
      url,
    });
  });

faqThunks.getFaqTaskCategoryByEid = (categoryEid) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('task.categories');
    const query = {
      esp_filters: `eid__EQ=${categoryEid}`,
    };

    APIcall.get({
      error(err) {
        reject(err);
      },
      query,
      success({ body }) {
        const [category] = body.results;
        dispatch(faqActions.setFaqTaskCategorySuccess(category, categoryEid));
        resolve(category);
      },
      token: true,
      url,
    });
  });

faqThunks.deleteFaqAnswer =
  (faqID, answerID, answer_brief = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        'espBarista.faqs.general.instance.answers.instance',
        {
          answerID,
          faqID,
        }
      );

      dispatch(faqActions.updateFaqGroupStart());
      APIcall.delete({
        error() {
          dispatch(faqActions.updateFaqGroupFail());
          reject();
        },
        query: {
          answer_brief: answer_brief,
        },
        success({ body }) {
          dispatch(faqActions.updateFaqGroupSuccess(body, faqID));
          resolve(body);
        },
        token: true,
        url,
      });
    });

faqThunks.toggleFaqAnswer = (faqID, answerID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath(
      'espBarista.faqs.general.instance.answers.instance',
      {
        answerID,
        faqID,
      }
    );
    const faq = getState()
      .getIn(['adminFaqs', 'faqsList'])
      .find((intent) => intent.get('eid') === faqID);
    const answer = faq
      .get('answers')
      .find((answer) => answer.get('eid') === answerID);

    APIcall.post({
      data: {
        active: !answer.get('active'),
      },
      error() {
        dispatch(faqActions.updateFaqGroupFail());
        reject();
      },
      success({ body }) {
        dispatch(faqActions.updateFaqAnswerSuccess(body, faqID, answerID));
        resolve();
      },
      token: true,
      url,
    });
  });

const getFAQConditions = (getState, answerID) => {
  const editedConditionsByAnswer = getState().getIn([
    'adminFaqs',
    'editedConditionsByAnswer',
  ]);

  const editedCondition = !answerID
    ? editedConditionsByAnswer.get('')
    : editedConditionsByAnswer.get(answerID);
  let newCondition;
  if (editedCondition) {
    const locationdeptrole = Immutable.Map().set(
      'locationdeptrole',
      editedCondition
    );
    newCondition = buildConditionTest(locationdeptrole);
  }
  return newCondition;
};

const getFAQDates = (getState, answerID = '') => {
  // Start / End date
  const dateFormValues = getFormValues(`FaqDatesSelect${answerID}`)(getState());
  let startDate;
  let endDate;
  if (dateFormValues) {
    startDate = dateFormValues.get('start_date');
    endDate = dateFormValues.get('end_date');
  }

  return {
    end_date: endDate ? endDate : void 0,
    start_date: startDate ? startDate : void 0,
  };
};

faqThunks.addFaqAnswer =
  (
    faqID,
    data = {
      lines: [
        {
          phrase: '',
        },
      ],
    }
  ) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        'espBarista.faqs.general.instance.answers',
        {
          faqID,
        }
      );
      dispatch(faqActions.updateFaqGroupStart());
      APIcall.post({
        data,
        error() {
          dispatch(faqActions.updateFaqGroupFail());
          reject();
        },
        success({ body }) {
          dispatch(faqActions.resetEditCondition(''));
          dispatch(faqActions.setConditionLocDepJobNames(body.eid, null, true));
          dispatch(faqActions.updateFaqAnswerSuccess(body, faqID));
          resolve(body);
        },
        token: true,
        url,
      });
    });

faqThunks.copyFaqAnswer = (faqID, answerID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const faq = getState()
      .getIn(['adminFaqs', 'faqsList'])
      .find((intent) => intent.get('eid') === faqID);
    const answer = faq
      .get('answers')
      .find((answer) => answer.get('eid') === answerID)
      .toJS();
    const copiedAnswer = { ...answer };
    delete copiedAnswer.eid;
    delete copiedAnswer.order;
    // added this newOrder default variable (100 seems to be the default values that is being assigned on the BE)
    let newOrder = 100;
    const newLines = copiedAnswer.lines.map((line) => {
      const l = { ...line };
      delete l.eid;
      //
      // we need to increase order value by one so we can see answers being correctly ordered on the FAQ group.
      // so we avoid having a different order when they are copied, because BE won't know how to order the answers.
      // This usually occurs when an image or file is being copied because they take more time to be saved.
      //
      l.order = newOrder;
      newOrder++;
      return l;
    });
    copiedAnswer.lines = [];
    copiedAnswer.condition = '';

    const done = (err) => {
      if (err) {
        dispatch(faqActions.updateFaqGroupFail());
        reject(err);
      }
      resolve();
    };

    async.waterfall(
      [
        // insert new response with data
        (next) => {
          dispatch(faqThunks.addFaqAnswer(faqID, copiedAnswer))
            .then((newAnswer) => {
              next(null, newAnswer);
            })
            .catch((err) => {
              next(err);
            });
        },
        // add response answer lines
        (newAnswer, next) => {
          dispatch(faqThunks.updateFaqAnswer(faqID, newLines, newAnswer.eid))
            .then(() => {
              next();
            })
            .catch((err) => {
              next(err);
            });
        },
      ],
      done
    );
  });

faqThunks.updateFaqAnswer =
  (
    faqID,
    lines,
    answerID,
    isNewResponse = false,
    isAlternateResponseChecked = false
  ) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.updateFaqGroupStart(answerID));
      const attachments = [];
      const saveAttachments = (answer, cb) => {
        const newLines = [...answer.lines];
        const updateLine = (line, seriesCB) => {
          // Build the form data with the file for this line
          const url = endpointGenerator.genPath(
            'espBarista.faqs.general.instance.answers.instance.lines.instance',
            {
              answerID,
              faqID,
              lineID: answer.lines[line.index].eid,
            }
          );

          // separate out the mime component
          const mimeString = (dataURI) =>
            dataURI.split(',')[0].split(':')[1].split(';')[0];
          // convert base64/URLEncoded data component to raw binary data held in a string
          const dataURItoBlob = (dataURI) => {
            let byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
              byteString = atob(dataURI.split(',')[1]);
            } else {
              byteString = unescape(dataURI.split(',')[1]);
            }
            // write the bytes of the string to a typed array
            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], { type: mimeString(dataURI) });
          };

          const putRequest = (finalFormData) =>
            new Promise((resolve, reject) => {
              APIcall.put({
                data: finalFormData,
                error(error) {
                  reject(error);
                },
                success({ body }) {
                  resolve(body);
                },
                token: true,
                url,
              });
            });

          // download attachment, it's a fallback function in case the server returns a 404 error
          const downloadAttachment = () =>
            new Promise((resolve, reject) => {
              APIcall.get({
                error(error) {
                  reject(error);
                },
                responseType: 'blob',
                success(result) {
                  if (result.type === 'application/json') {
                    reject(new Error('The attachment was not found'));
                  } else {
                    const fileName = lines[line.index].fileName
                      ? lines[line.index].fileName
                      : `${answer.lines[line.index].eid}.${result.body.type}`;
                    const blob = new Blob([result.body], {
                      type: result.body.type,
                    });
                    // Build the form data with the file for this line
                    const finalFormData = new FormData();
                    finalFormData.append('file', blob, fileName);
                    putRequest(finalFormData)
                      .then((body) => {
                        resolve(body);
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }
                },
                token: true,
                url: lines[line.index].url,
              });
            });

          const shouldUploadFile =
            String(lines[line.index].url).indexOf('https:') < 0;
          const fileName = lines[line.index].fileName
            ? lines[line.index].fileName
            : `${answer.lines[line.index].eid}.${
                mimeString(lines[line.index].url).split('/')[1]
              }`;

          let finalData = {
            url: lines[line.index].url,
          };

          // validate if the url is a base64 string or a regular url
          if (shouldUploadFile) {
            // get blob data from base64 string
            const blob = dataURItoBlob(lines[line.index].url);
            // Build the form data with the file for this line
            finalData = new FormData();
            finalData.append('file', blob, fileName);
          }

          putRequest(finalData)
            .then((body) => {
              if (body.url) {
                newLines[line.index] = body;
                answer.lines = newLines;
                dispatch(
                  faqActions.updateFaqAnswerSuccess(answer, faqID, answerID)
                );
                seriesCB();
              } else {
                // if url was not returned try fallback
                downloadAttachment()
                  .then((body) => {
                    newLines[line.index] = body;
                    answer.lines = newLines;
                    dispatch(
                      faqActions.updateFaqAnswerSuccess(answer, faqID, answerID)
                    );
                    seriesCB();
                  })
                  .catch((error) => {
                    seriesCB(error);
                  });
              }
            })
            .catch((/* error*/) => {
              // if error try fallback
              downloadAttachment()
                .then(() => {
                  seriesCB();
                })
                .catch((error) => {
                  seriesCB(error);
                });
            });
        };

        if (attachments.length === 0) {
          dispatch(faqActions.updateFaqAnswerSuccess(answer, faqID, answerID));
          cb();
        } else {
          async.eachSeries(attachments, updateLine, (err) => {
            if (err) {
              cb(err);
              dispatch(faqActions.updateFaqGroupFail());
            }

            cb();
          });
        }
      };

      const saveTextLines = (cb) => {
        const answerURL = endpointGenerator.genPath(
          'espBarista.faqs.general.instance.answers.instance',
          {
            answerID,
            faqID,
          }
        );
        // save lines that contains an attachment and to be uploaded
        lines.forEach((line, index) => {
          if (line && line.url) {
            attachments.push({
              index,
              line,
            });
          }
        });

        let finalData = {
          lines: lines.map((line) => ({ ...line, url: '' })),
        };

        if (!isNewResponse) {
          finalData.condition = !isAlternateResponseChecked
            ? getFAQConditions(getState, answerID)
            : '';
          finalData.else_condition = Boolean(isAlternateResponseChecked);

          finalData = {
            ...finalData,
            ...getFAQDates(getState, answerID),
          };
        }

        APIcall.post({
          data: finalData,
          error(error) {
            cb(error);
          },
          preventShowError: true,
          success({ body }) {
            cb(null, body);
          },
          token: true,
          url: answerURL,
        });
      };

      const done = (err) => {
        if (err) {
          dispatch(faqActions.updateFaqGroupFail());
          if (isNewResponse) {
            dispatch(faqThunks.deleteFaqAnswer(faqID, answerID));
          }
          reject(err);
        }
        resolve();
      };

      dispatch(faqActions.updateFaqGroupStart());

      async.waterfall([saveTextLines, saveAttachments], done);
    });

faqThunks.saveFaqAnswer =
  (faqID, lines, isAlternateResponseChecked) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const condition = getFAQConditions(getState);
      const dates = getFAQDates(getState);
      const finalData = {
        condition: isAlternateResponseChecked ? '' : condition,
        else_condition: Boolean(isAlternateResponseChecked),
        lines: [
          {
            phrase: '',
          },
        ],
        ...dates,
      };

      async.waterfall(
        [
          // 1. Adds a blank answer
          (next) => {
            dispatch(faqThunks.addFaqAnswer(faqID, finalData))
              .then((answer) => next(null, answer))
              .catch((err) => next(err));
          },
          // 2. Updates new answer with the responses
          (answer, next) => {
            const isNewResponse = true;
            dispatch(
              faqThunks.updateFaqAnswer(faqID, lines, answer.eid, isNewResponse)
            )
              .then(() => next(null, answer))
              .catch((err) => next(err));
          },
          // 3. Refreshes the FAQ to get splitted phrase/attachment
          (answer, next) => {
            // Only updates the FAQ if at least a line has an attachment
            if (
              lines.find(
                (line) =>
                  Object.prototype.hasOwnProperty.call(line, 'file') &&
                  Object.prototype.hasOwnProperty.call(line, 'fileName')
              )
            ) {
              dispatch(faqThunks.refreshFAQbyEID(faqID))
                .then(() => next(null, answer.eid))
                .catch((err) => next(err));
            } else {
              next(null, answer.eid);
            }
          },
        ],
        (err, answerEid) => {
          if (err) {
            reject(err);
          } else {
            resolve(answerEid);
          }
        }
      );
    });

faqThunks.newFaq =
  (
    faqName,
    { departmentEid = '', categoryEid = '', applicationName = 'FAQs' }
  ) =>
  (dispatch /* , getState*/) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.createFaqStart());
      const url = endpointGenerator.genPath('espBarista.faqs.general');
      const attributes = [];
      // Adds these attributes only when passed
      if (departmentEid) {
        attributes.push({
          name: 'service_department',
          value: departmentEid,
        });
      }
      if (categoryEid) {
        attributes.push({
          name: 'category',
          value: categoryEid,
        });
      }

      APIcall.post({
        data: {
          answers: [
            {
              condition: '',
              lines: [
                {
                  phrase: 'Response sample',
                },
              ],
            },
          ],
          application_name: applicationName,
          attributes: attributes,
          description: '',
          name: faqName,
        },
        error(error) {
          dispatch(faqActions.createFaqEnd());
          reject(error);
        },
        success({ body }) {
          dispatch(faqActions.createFaqEnd());
          resolve(body.eid);
        },
        token: true,
        url,
      });
    });

faqThunks.loadFaqLine =
  ({ faqEid, answerEid, lineEid }) =>
  (/* dispatch , getState*/) =>
    new Promise((resolve, reject) => {
      const url = endpointGenerator.genPath(
        'espBarista.faqs.general.instance.answers.instance.lines.instance',
        {
          answerID: answerEid,
          faqID: faqEid,
          lineID: lineEid,
        }
      );
      APIcall.get({
        error(error) {
          reject(error);
        },
        success({ body }) {
          resolve(body);
        },
        token: true,
        url,
      });
    });

const fetchIntentLookup = ({
  active,
  applicationEID,
  display = 'all',
  expiration = 'all',
  input_text = '',
  limit = 24,
  offset,
  orderBy = '-sys_date_updated',
  resp_type = 'all',
  reviewed = 'all',
  service_department_type,
  source = 'all',
  task_category_type,
}) =>
  new Promise((resolve, reject) => {
    const query = {};

    // Limit
    query.limit = limit;
    query.order_by = orderBy;

    if (active) {
      query.active = active;
    }

    // Offset
    if (isNumber(offset)) {
      query.offset = offset;
    }

    if (task_category_type) {
      query.attributes = `task_category_type=${task_category_type}`;
    }

    if (service_department_type) {
      query.attributes = query.attributes
        ? `${query.attributes}&service_department_type=${service_department_type}`
        : `service_department_type=${service_department_type}`;
    }

    if (resp_type) {
      query.resp_type = resp_type;
    }

    if (expiration) {
      switch (expiration) {
        case 'Expiring in 7 days':
          query.expires_in = 7;
          break;
        case 'Expiring in 30 days':
          query.expires_in = 30;
          break;
        case 'Expired':
          query.expired = true;
          break;
        default:
          delete query.expired;
          delete query.expires_in;
      }
    }

    if (input_text) {
      query.input_text = input_text;
    }

    query.is_archetype = 'False'; // Filter archetype type

    // Build the esp filter
    const filter = new EspFilters();
    if (display) {
      switch (display) {
        case 'active': {
          filter.isTrue('active');
          break;
        }

        case 'inactive': {
          filter.isFalse('active');
          break;
        }
        default:
          break;
      }
    }

    if (reviewed) {
      switch (reviewed) {
        case 'true': {
          filter.isTrue('is_reviewed');
          break;
        }

        case 'false': {
          filter.isFalse('is_reviewed');
          break;
        }
        default:
          break;
      }
    }

    if (source) {
      switch (source) {
        case 'elc': {
          filter.isTrue('is_protected');
          break;
        }

        case 'customer': {
          filter.isFalse('is_protected');
          break;
        }
        default:
          break;
      }
    }

    if (applicationEID) {
      filter.equalTo('application', applicationEID);
    }

    if (filter.getQuerySize()) {
      query.esp_filters = filter.asQueryString();
    }

    APIcall.get({
      error(error) {
        reject(error);
      },
      query,
      success({ body }) {
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espBarista.faqs.general.intentLookup'),
    });
  });

faqThunks.loadFaqsGeneralList =
  (
    {
      applicationEID,
      categoryID,
      departmentID,
      display = 'all',
      expiration = 'all',
      limit = 24,
      offset,
      orderBy = '-sys_date_updated',
      resp_type = 'all',
      reviewed = 'all',
      searchTerm = '',
      source = 'all',
    },
    isTopics = false
  ) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      /** DEV-11953: section scope */
      const startAction = isTopics
        ? baristaActions.faqsTopicListStart
        : baristaActions.faqsGeneralListStart;
      const successAction = isTopics
        ? baristaActions.faqsTopicListSuccess
        : baristaActions.faqsGeneralListSuccess;
      const failAction = isTopics
        ? baristaActions.faqsTopicListFail
        : baristaActions.faqsGeneralListFail;

      dispatch(startAction());

      let task_category_type;
      if (categoryID && categoryID !== '0') {
        const categoryList = getState().getIn(['cases', 'taskCategoryResults']);
        // Find the category selected
        const categorySelected = categoryList.filter(
          (cat) => cat.get('eid') === categoryID
        );
        if (!categorySelected.isEmpty()) {
          task_category_type = categorySelected.getIn([
            0,
            'classification_name',
          ]);
        }
      }

      let service_department_type;
      if (departmentID && !isNaN(departmentID)) {
        // Get the service department name
        const myServiceTeams = getState().getIn(['caseMgmt', 'myServiceTeams']);

        if (!myServiceTeams || myServiceTeams.isEmpty()) {
          dispatch(failAction());
          reject(new Error('No Service Teams'));
          return false;
        } else {
          const myDepartment = myServiceTeams
            .find(
              (team) =>
                Number(team.getIn(['service_department', 'id'])) ===
                Number(departmentID)
            )
            .get('service_department');

          const myDepartmentName = myDepartment
            ? myDepartment.get('service_department')
            : '';
          service_department_type = myDepartmentName;
        }
      }

      // Check if it's a search
      let input_text;
      if (searchTerm) {
        input_text = searchTerm;
      }

      return fetchIntentLookup({
        applicationEID,
        display,
        expiration,
        input_text,
        limit,
        offset,
        orderBy,
        resp_type,
        reviewed,
        service_department_type,
        source,
        task_category_type,
      })
        .then((body) => {
          dispatch(successAction(body.results, body.count));
          resolve(body);
        })
        .catch((err) => {
          dispatch(failAction());
          reject(err);
        });
    });

faqThunks.loadTopCandidateFaqsMatches =
  ({ limit = 5, input_text = '' }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.loadTopCandidateFaqsMatchesStart());
      fetchCandidateFaqsLookup({
        input_text,
        limit,
      })
        .then((body) => {
          dispatch(
            faqActions.loadTopCandidateFaqsMatchesSuccess(body, input_text)
          );
          resolve(body);
        })
        .catch((err) => {
          dispatch(faqActions.loadTopCandidateFaqsMatchesFail());
          reject(err);
        });
    });

faqThunks.loadTopFaqMatches =
  ({ limit = 5, query = '' }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.casesSearchStart());
      fetchIntentLookup({
        active: 'True',
        input_text: query,
        limit,
      })
        .then((body) => {
          dispatch(faqActions.casesSearchSuccess(body.results));
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });

faqThunks.getFAQListByTrigger = (trigger, kbTitle) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(baristaActions.loadFAQTriggerStart());

    const state = getState();
    const serviceDepartmentTypeID = state.getIn([
      'barista',
      'selectedDepartmentType',
    ]);
    // Checking that a value is set in the Dropdown as reported in DEV-7940
    const formServiceDepartment = state.getIn([
      'form',
      'Form01',
      'values',
      'service_department',
    ]);

    const url = endpointGenerator.genPath(
      'espBarista.faqs.general.intentLookup'
    );

    const query = {
      attribute_name:
        'category&service_department&OResp.task_category_type&esp.service_department_type',
    };
    if (serviceDepartmentTypeID && formServiceDepartment) {
      query.attributes = `service_department_type=${serviceDepartmentTypeID}`; // Filter by service department Type
    }
    query.is_archetype = 'False'; // Filter archetype type
    query.input_text = trigger; // now this goes as GET query params

    const searchText = trigger || kbTitle;

    if (searchText) {
      APIcall.get({
        error(err) {
          dispatch(baristaActions.loadFAQTriggerFail());

          reject(err);
        },
        query,
        success({ body }) {
          dispatch(baristaActions.loadFAQTriggerSuccess(body.results));
          resolve(body.results);
        },
        token: true,
        url,
      });
    } else {
      // We should clear the results
      const noResults = [];
      dispatch(baristaActions.loadFAQTriggerSuccess(noResults));
      resolve(noResults);
    }
  });

/**
 * Search Barista Archetype by term
 * @param term
 * @param searchBy
 * @param departmentID
 * @param getFullResult {boolean} force to get the full result no matter if we pass a term
 * @returns {function(*=): Promise}
 */
faqThunks.searchArchetype =
  (term, searchBy, departmentID, getFullResult) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(baristaActions.loadFAQArchetypeStart());
      // Get Service Department name selected in CasesList
      const state = getState();
      let teamSelected; // User Team that belongs to this department

      // Get user teams list
      const myTeams = getMyTeams(state);

      if (departmentID) {
        // This WF has been launched from ADMIN/FAQ - We need to retreive the Department name with the departmentID passed
        teamSelected = myTeams.find(
          (team) =>
            team.getIn(['service_department', 'id']) === Number(departmentID)
        );
      } else {
        // Get the selected team id
        const selectedTeamId = getSelectedServiceTeamId(state);
        // Get the team selected
        teamSelected = myTeams.find(
          (team) => team.get('id') === selectedTeamId
        );
      }

      // Build the query for attributes
      const query = {};

      if (teamSelected) {
        // Get the service department classification name
        const serviceDeparmentClassificationName = teamSelected.getIn([
          'service_department',
          'service_department',
        ]);
        query.attributes = `service_department_type=${serviceDeparmentClassificationName}`;
      }

      query.limit = 200;
      if (term) {
        const filter = new EspFilters();
        query.esp_filters = searchBy
          ? filter.contains(searchBy, term).asQueryString()
          : filter
              .contains('name', term)
              .or()
              .contains('description', term)
              .asQueryString();
      }

      const options = {
        query,
        token: true,
        url: endpointGenerator.genPath('espBarista.archetypes.listForFaq'),
      };

      async.waterfall(
        [
          // 1 . Load
          (next) => {
            APIcall.get(options)
              .then(({ body }) => {
                dispatch(baristaActions.loadFAQArchetypeSuccess(body.results));
                next();
              })
              .catch((err) => {
                next(err);
                dispatch(baristaActions.loadFAQArchetypeFail(err));
              });
          },

          // 2. Load full data if needed
          (next) => {
            if (getFullResult) {
              delete options.query.esp_filters;

              APIcall.get(options)
                .then(({ body }) => {
                  dispatch(baristaActions.addFAQArchetypeSuccess(body.results));
                  next();
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              next();
            }
          },
        ],
        (err) => {
          if (err) {
            dispatch(baristaActions.loadFAQArchetypeFail(err));
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });

faqThunks.getPrimaryEntityFromArchetype = (archetypeEID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(baristaActions.getPrimaryEntityFromArchetypeStart());

    const url = endpointGenerator.genPath(
      'espBarista.archetypes.template.primaryEntity',
      {
        templateEid: archetypeEID,
      }
    );

    APIcall.get({
      error(err) {
        dispatch(baristaActions.getPrimaryEntityFromArchetypeFail(err));
        reject(err);
      },
      success({ body }) {
        if (!body.entity || !body.entity.length) {
          dispatch(baristaActions.getPrimaryEntityFromArchetypeFail());
          resolve();
        } else {
          dispatch(
            baristaActions.getPrimaryEntityFromArchetypeSuccess(
              archetypeEID,
              body.entity[0]
            )
          );
          resolve(body.entity[0]);
        }
      },
      token: true,
      url: url,
    });
  });

/**
 * Search Synonym (values) of an Entity by Archetype EID
 * The BE scratch data needs to know about the entitylabel selected
 * @param archetypeEID {String}
 * @returns {function(*=): Promise}
 */
faqThunks.searchEntitySynonymByArchetype =
  (archetypeEID) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!archetypeEID) {
        reject(new Error('Archetype EID is missing'));
      }

      dispatch(baristaActions.loadArchetypeEntitySynonymStart());

      const normalizedValue = getState().getIn([
        'workflowState',
        'backendScratch',
        'scratch.temp_data',
        'entityLabel',
      ]);
      if (!normalizedValue) {
        reject(new Error('No entity has been selected at the previous step'));
      }

      async.waterfall(
        [
          // 1. Get the primary entity of this Archetype
          (next) => {
            const state = getState();
            const primaryEntity = state.getIn([
              'adminFaqs',
              'primaryEntities',
              archetypeEID,
            ]);
            if (!primaryEntity) {
              // We have to load this primary entity to get the EID
              dispatch(faqThunks.getPrimaryEntityFromArchetype(archetypeEID))
                .then((entity) => {
                  next(null, entity && entity.eid);
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              next(null, primaryEntity.get('eid'));
            }
          },

          // 2. Get all values from this entity
          (entityEID, next) => {
            if (entityEID) {
              const query = {
                esp_filters: new EspFilters()
                  .equalTo('label', normalizedValue)
                  .asQueryString(),
              };

              APIcall.get({
                query,
                token: true,
                url: endpointGenerator.genPath(
                  'espBarista.entities.instance.values',
                  {
                    entityEID,
                  }
                ),
              })
                .then(({ body }) => {
                  next(null, entityEID, body.results);
                })
                .catch((err) => {
                  next(err);
                });
            } else {
              next(null, []);
            }
          },

          // 3. Get all values (synonym) for this entity
          (entityEID, results, next) => {
            const valueEID = results[0].eid;
            APIcall.get({
              token: true,
              url: endpointGenerator.genPath(
                'espBarista.entities.instance.values.instance.instances',
                {
                  entityEID,
                  valueEID,
                }
              ),
            })
              .then(({ body }) => {
                next(null, body.results);
              })
              .catch((err) => {
                next(err);
              });
          },
        ],
        (err, results) => {
          if (err) {
            dispatch(baristaActions.loadArchetypeEntitySynonymFail(err));
            reject(err);
          } else {
            dispatch(baristaActions.loadArchetypeEntitySynonymSuccess(results));
            resolve(results);
          }
        }
      );
    });

faqThunks.baristaIntentLookupByEID =
  (eid = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (!eid) {
        reject(new Error('Eid is required'));
      }
      const query = {
        esp_filters: new EspFilters().equalTo('eid', eid).asQueryString(),
      };

      dispatch(faqActions.baristaIntentLookupStart());

      APIcall.get({
        query,
        token: true,
        url: endpointGenerator.genPath('espBarista.intents', {
          intentID: eid,
        }),
      })
        .then(({ body }) => {
          dispatch(faqActions.baristaIntentLookupSuccess(body.results));
          resolve(body);
        })
        .catch((err) => {
          dispatch(faqActions.baristaIntentLookupFail());
          reject(err);
        });
    });

faqThunks.baristaIntentLookup =
  ({ limit = 10, search = '' }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.baristaIntentLookupStart());

      const espFilters = new EspFilters();
      espFilters.isFalse('is_archetype');
      espFilters.isTrue('active');
      espFilters.isTrue('application.active');
      espFilters.contains('name', search);

      APIcall.get({
        query: {
          esp_filters: espFilters.asQueryString(),
          limit,
          orderBy: '-sys_date_updated',
        },
        token: true,
        url: endpointGenerator.genPath('espBarista.intents'),
      })
        .then(({ body }) => {
          dispatch(faqActions.baristaIntentLookupSuccess(body.results));
          resolve(body);
        })
        .catch((err) => {
          dispatch(faqActions.baristaIntentLookupFail());
          reject(err);
        });
    });

faqThunks.baristaScopedIntentLookup =
  ({ intentEid, scope, limit = 10, search = '' }) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(faqActions.baristaScopedIntentLookupStart(scope));

      const fetchFunction = () =>
        isEmpty(intentEid)
          ? getBaristaIntents({
              limit,
              search,
            })
          : getBaristaIntent({ intentID: intentEid });

      fetchFunction()
        .then(({ body }) => {
          dispatch(
            faqActions.baristaScopedIntentLookupSuccess(scope, body.results)
          );
          resolve(body);
        })
        .catch((err) => {
          dispatch(faqActions.baristaScopedIntentLookupFail(scope));
          reject(err);
        });
    });

export default faqThunks;
