// Selector
// Actions
import baristaActions from '../../../../v1/actions/baristaActions';
import baristaThunks from '../../../../v1/actions/baristaThunks';
import browserHistory from '../../../../v1/utils/browserHistory';
// Globals
import BrowserPrompt from '../../../../v1/globals/BrowserPrompt';
// Utils
import buildCompositeController from '../../../../v1/utils/buildCompositeController';
import endpointGenerator from '../../../../v1/utils/endpointGenerator';
import { intl } from 'esp-util-intl';
// V2 utility
import { openExternalUrlInNative } from '../../../utils/externalDownloadHandler';
import { saveAs } from 'file-saver';
import { UserAgentUtils } from 'esp-globals';

const mapStateToProps = (state) => ({
  clickContext: state.getIn(['barista', 'clickContext']),
  contentHtml: state.getIn(['barista', 'baristaKBArticleHTML']),
  contentText: state.getIn(['barista', 'baristaEspKbArticle']),
  contentTitle: state.getIn(['barista', 'baristaKbTitle']),
  error: state.getIn(['barista', 'baristaKBArticleError']),
  isLoading: state.getIn(['barista', 'baristaKBArticleIsLoading']),
  kbSource: state.getIn(['barista', 'baristaKbSource']),
  open: state.getIn(['barista', 'baristaKBArticleIsOpen']),
  sharepointArticleId: state.getIn(['barista', 'baristaKbArticleId']),
});

const mapDispatchToProps = (dispatch) => ({
  displayDownloadDialog: (body, isMobile) => {
    const { type } = body;

    const fileName = `barista_PDF_download.${type.split('/')[1]}`;
    const blob = new Blob([body], {
      type,
    });

    const downloadFile = () => {
      const storageLocation = window.cordova.file.dataDirectory;
      const androidDirectory = 'Download';

      const errorCallback = function (e) {
        // eslint-disable-next-line no-console -- debug purposes
        console.log(`Error: ${e}`);
      };
      // write pdf file on android
      window.resolveLocalFileSystemURL(
        storageLocation,
        (fileSystem) => {
          fileSystem.getDirectory(
            androidDirectory,
            {
              create: true,
              exclusive: false,
            },
            (directory) => {
              directory.getFile(
                fileName,
                {
                  create: true,
                  exclusive: false,
                },
                (fileEntry) => {
                  fileEntry.createWriter((writer) => {
                    writer.onwriteend = function () {
                      // opens written pdf file
                      window.cordova.plugins.fileOpener2.open(
                        fileEntry.nativeURL,
                        type,
                        {
                          error: function (e) {
                            // eslint-disable-next-line no-console -- testing purposes
                            console.error(e);
                          },
                          success: function () {},
                        }
                      );
                    };

                    writer.seek(0);
                    // blob data goes here
                    writer.write(body);
                  }, errorCallback);
                },
                errorCallback
              );
            },
            errorCallback
          );
        },
        errorCallback
      );
    };

    if (isMobile) {
      downloadFile();
    } else {
      saveAs(blob, fileName);
    }
  },
  downloadKbAttachment: ({ sys_id, zendeskUrl }) => {
    let windowReference;
    if (!window.cordova && UserAgentUtils.isIOS()) {
      windowReference = window.open();
    }

    if (sys_id) {
      // Get API key
      dispatch(baristaThunks.getProxyKey())
        // then open a new tab with a newly generated URL
        .then(({ key, user_id }) => {
          // DEV-26529 fixed order of params as seen with Raj
          const endpoint = endpointGenerator.genPath(
            'serviceNowIntegration.proxy.serve'
          );
          const url =
            `${endpoint}` +
            // 1. key parameter must come first
            `?key=${key}` +
            // 2. user_id should be a separated parameter also
            `&user_id=${user_id}` +
            // 3.after attachment.do?sys_id should be the second parameter
            `&url=sys_attachment.do%3Fsys_id=${sys_id}`;

          openExternalUrlInNative(url, windowReference);
        });
    }
    if (zendeskUrl) {
      let url = endpointGenerator.genPath('espIntegration.zendesk.serve');
      url = `${url}?url=${zendeskUrl}`;

      openExternalUrlInNative(url, windowReference);
    }
  },
  downloadSharepointPDF: (sharepointArticleId, clickContext) =>
    new Promise((resolve) => {
      dispatch(
        baristaThunks.downloadSharepointPDF(sharepointArticleId, clickContext)
      ).then((pdfBlob) => {
        resolve(pdfBlob);
      });
    }),

  handleClose: () => {
    dispatch(baristaActions.closeKBArticleModal());
  },

  loadKbArticle: (params) => {
    dispatch(baristaThunks.openKbArticle(params));
  },

  openInternaLinkFromBarista: (event, avoidPromptingDialog = false) => {
    // don't show warning dialog
    // just close barista and go to the link
    if (avoidPromptingDialog) {
      let originalHref = event.target.getAttribute('href'); // save original link
      originalHref = originalHref.replace('#', ''); // removes history tag added by native apps
      event.preventDefault(); // cancel link click event

      dispatch(baristaActions.closeSupportChannel()); // close current channel
      setTimeout(() => {
        // push url after a little bit
        browserHistory.push(originalHref);
      }, 500);
    } else {
      // Ask first
      const closeBaristaAndNavigate = () => {
        dispatch(baristaActions.closeSupportChannel());
      };
      const confirmed = BrowserPrompt.confirm(
        intl.formatMessage({
          id: 'message.youre_about_to_follow_a_link_do_you_want_to_close_barista_as_well',
        }),
        {
          buttons: [
            intl.formatMessage({
              id: 'label.keep_barista_open_link_in_background',
            }),
            intl.formatMessage({
              id: 'label.yes_close_barista',
            }), // Confirmation
          ],
          callback: (action) => {
            if (action === 2) {
              // Confirmation from the second button
              closeBaristaAndNavigate();
            }
          },
          content: intl.formatMessage({
            id: 'message.youre_about_to_follow_a_link_do_you_want_to_close_barista_as_well',
          }),
          title: intl.formatMessage({
            id: 'label.barista_navigation_alert',
          }),
        }
      );

      // Cancel
      if (confirmed) {
        closeBaristaAndNavigate();
      } else {
        // <- don't critique my empty else, i just want to make clear in a comment what happens otherwise ;)
        // do nothing, this will navigate normally using click on NavLink while keeping barista modal in the front
      }
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
