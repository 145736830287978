import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

const blockAttrsDefinition = _.merge({}, BlockBase, {
  inputRequired: {
    default: true,
    type: 'bool',
  },
  inputValue: {
    objectShape: {
      map: {
        requred: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  labelText: {
    required: true,
    type: 'string',
  },
  placeholder: {
    default: '',
    required: false,
    type: 'string',
  },
});

// Object Definition used by blobifier
const birthdayDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Birthday',
  templates: ['birthday01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, birthdayDefinition, TypeDefaults };
