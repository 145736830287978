import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import startWeb from './app/js/startupWeb';
import startElectron from './app/js/startupElectron';
import startCordova from './app/js/startupCordova';

import { register } from './serviceWorkerRegistration';

const isCordova = window.cordova;
// This object is exposed through preload.js
const isElectron = window.electronAPI;
// Different platforms require different ways to bootstrap the js application
// Both Cordova and Electron also read from persistentStorage at this point
// in order to know under what tenant they're running
// Since the application is running from a localhost domain in native apps.
if (isCordova) {
  // window.cordova is defined independetly by cordova
  // making native APIs available in this global
  // Do not overwite window.cordova
  startCordova();
} else if (isElectron) {
  // Code base relies on this global variable to detect app on runtime
  window.electron = true;
  startElectron();
} else {
  startWeb();
}

register();
