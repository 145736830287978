import React from 'react';
import { EspEntities } from 'esp-globals';
import KbEntities from '../globals/KbEntities';

const kbEntityToIdType = {
  [EspEntities.CONFLUENCE_KB]: 'confluenceId',
  [EspEntities.ESP_KB]: 'responseEid',
  [EspEntities.IVANTI_KB]: 'ivantiId',
  [EspEntities.KB_ARTICLE]: 'sys_id',
  [EspEntities.KB_NUMBER]: 'number',
  [EspEntities.SHAREPOINT_KB]: 'sharepointId',
  [EspEntities.ZENDESK_KB]: 'zendeskId',
};

/**
 * The props for the `KbLink` component.
 * @typedef {Object} KbLinkProps
 * @property {React.ReactNode} [children] - The children of the component.
 * @property {import ('../EspMarkdown').EspMarkdownProps} [extendedProps] - The extended props for the component.
 * @property {string} href - The href for the component.
 */

/**
 * The `KbLink` component.
 * @param {KbLinkProps} props - The props for the component.
 * @returns {React.ReactElement} - The rendered component.
 */
const KbLink = ({ children = null, href, extendedProps, ...restProps }) => {
  const [, espEntity, espId] =
    new RegExp(`(${KbEntities.join('|')})~~(\\S+)`).exec(href) || [];

  const isRegularLink =
    !Object.values(EspEntities).includes(espEntity) || !espId;

  const handleOpenKbArticle = (event) => {
    const { avoidFollowLinks, loadKbArticle } = extendedProps;

    event.preventDefault(); // block link click

    if (!avoidFollowLinks) {
      loadKbArticle({
        [kbEntityToIdType[espEntity]]: espId,
      });
    }
  };

  return (
    <a
      {...restProps}
      href={isRegularLink ? href : '#'}
      {...(!isRegularLink && { onClick: handleOpenKbArticle })}
    >
      {children}
    </a>
  );
};

export default KbLink;
