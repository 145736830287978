import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';

// Globals
import TypeSocialMedia, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSocialMedia';
// Molecules
import ValidatedSocialMedia from '../../molecules/ValidatedSocialMedia';
// Controllers
import SocialMediaWorkflowController from '../../controllers/SocialMediaWorkflowController';
import BodyClassName from '../../../../globals/BodyClassName';

let SocialMedia01 = class SocialMedia01 extends Component {
  static propTypes = {
    ...TypeSocialMedia,
    formValue: ImmutablePropTypes.list,
    updateRequestedForSocialCoordinates: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    formValue: null,
    updateRequestedForSocialCoordinates: noop,
  };

  componentDidMount() {
    const { defaultNetwork, id, loadUserProfile } = this.props;

    // Force to reload the user profile from the API
    loadUserProfile(id, defaultNetwork);
  }

  componentWillUnmount() {
    // Saving whatever was put in the form into the user of Workflow Requested For
    const { formValue, updateRequestedForSocialCoordinates } = this.props;
    updateRequestedForSocialCoordinates(formValue);
  }

  /**
   * We check for the value if empty or not
   * And then set the errors for this ValidatedSocialMedia Field using redux-form field validation
   */
  fieldValidation = (value, allValues) => {
    const { id, inputRequired } = this.props;
    const valueTyped = allValues ? allValues.getIn([id, 0, 'username']) : '';

    if (!inputRequired || valueTyped) {
      return null;
    }
    return 'Validation errors';
  };

  render() {
    const {
      disabledOptions,
      template,
      type,
      defaultNetwork,
      labelText,
      mediaType,
      id,
      placeholder,
    } = this.props;

    // Removing whitespaces and creating an array, like ["facebook", "instagram"]
    const disabledOptionsAsArray = disabledOptions
      .replace(/\s/g, '')
      .split(',');

    return (
      <BodyClassName className={template}>
        <div className='block' data-blocktype={type} data-template={template}>
          <ValidatedSocialMedia
            defaultNetwork={defaultNetwork}
            disabledOptions={disabledOptionsAsArray}
            fieldValidation={this.fieldValidation}
            label={labelText}
            mediaType={mediaType}
            name={id}
            placeholder={placeholder}
            showOneByDefault
          />
        </div>
      </BodyClassName>
    );
  }
};

SocialMedia01 = SocialMediaWorkflowController(SocialMedia01);

export default SocialMedia01;
