import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import Immutable from 'immutable';

// Atom
import ScrollArea from '../atoms/ScrollArea';
// Global
import AnnouncementCategories from '../../globals/AnnouncementCategories';
// Molecule
import ValidatedForm from './ValidatedForm';
// V2
import AnnouncementCategorySelectList from '../../../v2/components/display/AnnouncementCategorySelectList';
// Packages
import { intl } from 'esp-util-intl';

class AnnouncementCategorySelect extends PureComponent {
  static propTypes = {
    /**  Announcement ID */
    announcementId: PropTypes.string.isRequired,
    /**  Change category form value */
    changeFormFieldValue: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.arrayPreset = Immutable.List();
    AnnouncementCategories.forEach((cat) => {
      const tp = cat.set('handler', () => {
        const categoryId = cat.get('name');
        this.handlerChangeFormFieldValue(categoryId);
      });
      this.arrayPreset = this.arrayPreset.push(tp);
    });
    this.state = {
      categories: this.arrayPreset,
    };
  }

  handlerChangeFormFieldValue = (categoryId) => {
    const { announcementId, changeFormFieldValue } = this.props;

    changeFormFieldValue('category', categoryId, announcementId);
  };

  render() {
    const { categories } = this.state;
    return (
      <ValidatedForm form='SelectCategory'>
        <Modal.Header>
          {intl.formatMessage({
            id: 'label.select_category',
          })}
        </Modal.Header>
        <Modal.Content>
          <ScrollArea padding={0}>
            <AnnouncementCategorySelectList categories={categories} />
          </ScrollArea>
        </Modal.Content>
      </ValidatedForm>
    );
  }
}

export default AnnouncementCategorySelect;
