import _ from 'lodash';

const locationCheckRegex = /user\.is_in_location\((\d+)\)/g;

const departmentCheckRegex = /user\.is_in_department\((\d+)\)/g;

const jobRoleCheckRegex = /user\.job_role_id in \[(\d+(,\d+)*)\]/;

const ProductPermissionsParser = {};

// A rule that always evaluates to 'false'
ProductPermissionsParser.FALSY_RULE = '1==2';

/**
 * Decodes locations, departments and job roles from Python-like expression generated
 * by 'toExpression'.
 *
 * @param {string} expression
 * @param {Object} Parsed expression containing 'locations', 'departments' and 'jobRoles' Arrays.
 */
ProductPermissionsParser.fromExpression = (expression) => {
  let locations = [];

  const locationCheckMatches = expression.match(locationCheckRegex);

  if (locationCheckMatches) {
    locations = locationCheckMatches.map((locationCheckMatch) => {
      const location = locationCheckMatch.replace(locationCheckRegex, '$1');
      return Number(location);
    });
  }

  let departments = [];

  const departmentCheckMatches = expression.match(departmentCheckRegex);

  if (departmentCheckMatches) {
    departments = departmentCheckMatches.map((departmentCheckMatch) => {
      const department = departmentCheckMatch.replace(
        departmentCheckRegex,
        '$1'
      );
      return Number(department);
    });
  }

  let jobRoles = [];

  const jobRoleCheckMatch = expression.match(jobRoleCheckRegex);

  if (jobRoleCheckMatch) {
    const [, commaSeparedJobRoles] = jobRoleCheckMatch;

    jobRoles = commaSeparedJobRoles.split(',').map(Number);
  }

  const parsedExpression = {
    departments,
    jobRoles,
    locations,
  };

  return parsedExpression;
};

/**
 * Encodes locations, departments and job roles into Python-like expression.
 * Returned String can be decoded back by 'fromExpression'.
 *
 * @param {Array.<number>} locations
 * @param {Array.<number>} departments
 * @param {Array.<number>} jobRoles
 * @return {string}
 */
ProductPermissionsParser.toExpression = (locations, departments, jobRoles) => {
  // sort asc so any permutation of the same set of values returns the same expression String
  locations = _.sortBy(locations);
  departments = _.sortBy(departments);
  jobRoles = _.sortBy(jobRoles);

  let oredLocationChecks = '';

  if (!_.isEmpty(locations)) {
    const locationChecks = locations.map(
      (location) => `user.is_in_location(${location})`
    );

    oredLocationChecks = locationChecks.join(' or ');

    oredLocationChecks = `(${oredLocationChecks})`;
  }

  let oredDepartmentChecks = '';

  if (!_.isEmpty(departments)) {
    const departmentChecks = departments.map(
      (department) => `user.is_in_department(${department})`
    );

    oredDepartmentChecks = departmentChecks.join(' or ');

    oredDepartmentChecks = `(${oredDepartmentChecks})`;
  }

  let jobRoleCheck = '';

  if (!_.isEmpty(jobRoles)) {
    const commaSeparedJobRoles = jobRoles.join(',');

    jobRoleCheck = `user.job_role_id in [${commaSeparedJobRoles}]`;
  }

  let checkExpressions = [
    oredLocationChecks,
    oredDepartmentChecks,
    jobRoleCheck,
  ];

  checkExpressions = _.reject(checkExpressions, _.isEmpty);

  const andedCheckExpressions = checkExpressions.join(' and ');

  if (!_.isEmpty(andedCheckExpressions)) {
    return andedCheckExpressions;
  }

  // no one has access to the product
  return ProductPermissionsParser.FALSY_RULE;
};

export default ProductPermissionsParser;
