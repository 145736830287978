import actionTypes from './actionTypes';

const socializeActions = {};

socializeActions.getChannelsStart = (channelType) => ({
  channelType,
  type: actionTypes.GET_CHANNELS_START,
});

socializeActions.getChannelsSuccess = (
  channels,
  pagination,
  channelType,
  reset
) => ({
  channelType,
  channels,
  pagination,
  reset,
  type: actionTypes.GET_CHANNELS_SUCCESS,
});

socializeActions.getChannelsFail = (error, channelType) => ({
  channelType,
  error,
  type: actionTypes.GET_CHANNELS_FAIL,
});

socializeActions.setMapUserToChannels = (map) => ({
  map,
  type: actionTypes.SET_USER_TO_CHANNELS,
});

socializeActions.setMapChannelToUser = (map) => ({
  map,
  type: actionTypes.SET_CHANNEL_TO_USER,
});

socializeActions.getDirectChannelForUserIdStart = (userID) => ({
  type: actionTypes.GET_DIRECT_CHANNEL_FOR_USER_START,
  userID,
});

socializeActions.getDirectChannelForUserIdSuccess = (
  directChannel,
  channelID,
  userID
) => ({
  channelID,
  directChannel,
  type: actionTypes.GET_DIRECT_CHANNEL_FOR_USER_SUCCESS,
  userID,
});

socializeActions.getDirectChannelForUserIdFail = (errorMsg, userID) => ({
  errorMsg,
  type: actionTypes.GET_DIRECT_CHANNEL_FOR_USER_FAIL,
  userID,
});

socializeActions.loadChannelStart = (channelID) => ({
  channelID,
  type: actionTypes.LOAD_CHANNEL_START,
});

socializeActions.loadChannelSuccess = (channel, channelID) => ({
  channel,
  channelID,
  type: actionTypes.LOAD_CHANNEL_SUCCESS,
});
socializeActions.updateAutomationTaskTypeCard = (channel, channelID) => ({
  channel,
  channelID,
  type: actionTypes.UPDATE_AUTOMATION_TASK_TYPE_CARD_FROM_CHANNEL,
});

socializeActions.updateChannelReadCount = (channel, channelID) => ({
  channel,
  channelID,
  type: actionTypes.UPDATE_CHANNEL_READ_COUNT,
});

socializeActions.loadChannelFail = (errorMsg, channelID) => ({
  channelID,
  errorMsg,
  type: actionTypes.LOAD_CHANNEL_FAIL,
});

socializeActions.updateChannelsFilter = (channelsFilters) => ({
  channelsFilters,
  type: actionTypes.CHANNELS_FILTER_UPDATE,
});

socializeActions.updateChannelTypeFilter = (channelType, reset = false) => ({
  channelType,
  reset,
  type: actionTypes.CHANNELS_FILTER_UPDATE_TYPE,
});

socializeActions.updateUnreadCountScopedChannels = (count) => ({
  count,
  type: actionTypes.UPDATE_UNREAD_COUNT_SCOPED_CHANNELS,
});

socializeActions.updateUnreadCountDirectChannels = (count) => ({
  count,
  type: actionTypes.UPDATE_UNREAD_COUNT_DIRECT_CHANNELS,
});

socializeActions.sendFeedbackStart = () => ({
  type: actionTypes.USER_SEND_FEEDBACK_START,
});

socializeActions.sendFeedbackSuccess = () => ({
  type: actionTypes.USER_SEND_FEEDBACK_SUCCESS,
});

socializeActions.sendFeedbackFail = () => ({
  type: actionTypes.USER_SEND_FEEDBACK_FAIL,
});

socializeActions.deleteChannels = () => ({
  type: actionTypes.DELETE_CHANNELS,
});

export default socializeActions;
