import { createSelector } from 'reselect';
import getSoftwareBundle from './getSoftwareBundle';

const getChildBundleProductIDs = (bundle) => {
  if (bundle) {
    return bundle.get('products');
  }
  return null;
};

const selector = createSelector([getSoftwareBundle], getChildBundleProductIDs);

export default selector;
