/**
 * Add params to URL
 *
 * @param {String} url The URL we are adding params to
 * @param {Object} params An object in which the key is the param name and the value is the replacement for that param
 * @returns {String} The URL that includes the added params
 */
const addParamsToURL = (url, params = {}) =>
  url
    ?.split('/')
    ?.map((urlSection) => {
      let sectionKey = urlSection;

      if (urlSection?.startsWith(':')) {
        const paramKey = urlSection?.substring(1);
        sectionKey = params[paramKey] || `${paramKey}_NOT_FOUND`;
      }

      return sectionKey;
    })
    ?.join('/');

export default addParamsToURL;
