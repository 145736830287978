import Immutable from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
  addEmailDomain: {
    failed: false,
    isLoading: false,
  },
  addressPolicies: {
    failed: false,
    id: null,
    isLoading: false,
    rules: null,
  },
  emailDomains: {
    failed: false,
    isLoading: false,
    values: null,
  },
  selectEmailDomain: {
    failed: false,
    isLoading: false,
  },
  testEmail: {
    isLoading: false,
    isTestEmailEnabled: false,
    testEmail: '',
  },
  updateAddressPolicy: {
    failed: false,
    isLoading: false,
  },
});

const emailPoliciesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_TEST_EMAIL_START:
      return state.setIn(['testEmail', 'isLoading'], true);

    case actionTypes.GET_TEST_EMAIL_SUCCESS:
      return state
        .setIn(['testEmail', 'testEmail'], action.result)
        .setIn(['testEmail', 'isLoading'], false);

    case actionTypes.GET_TEST_EMAIL_ENABLED_SUCCESS:
      return state
        .setIn(['testEmail', 'isTestEmailEnabled'], action.result)
        .setIn(['testEmail', 'isLoading'], false);

    case actionTypes.GET_TEST_EMAIL_FAIL:
      return state.setIn(['testEmail', 'isLoading'], false);

    case actionTypes.GET_EMAIL_ADDRESS_POLICIES_START:
      return state
        .set('addressPolicies', INITIAL_STATE.get('addressPolicies'))
        .setIn(['addressPolicies', 'isLoading'], true);

    case actionTypes.GET_EMAIL_ADDRESS_POLICIES_SUCCESS:
      return state
        .set('addressPolicies', INITIAL_STATE.get('addressPolicies'))
        .setIn(['addressPolicies', 'id'], action.id)
        .setIn(
          ['addressPolicies', 'rules'],
          Immutable.fromJS(action.addressRules)
        );

    case actionTypes.GET_EMAIL_ADDRESS_POLICIES_FAIL:
      return state
        .set('addressPolicies', INITIAL_STATE.get('addressPolicies'))
        .setIn(['addressPolicies', 'failed'], true);

    case actionTypes.UPDATE_EMAIL_ADDRESS_POLICY_START:
      return state
        .set('updateAddressPolicy', INITIAL_STATE.get('updateAddressPolicy'))
        .setIn(['updateAddressPolicy', 'isLoading'], true);

    case actionTypes.UPDATE_EMAIL_ADDRESS_POLICY_SUCCESS:
      return state
        .set('updateAddressPolicy', INITIAL_STATE.get('updateAddressPolicy'))
        .setIn(
          ['addressPolicies', 'rules'],
          Immutable.fromJS(action.addressRules)
        );

    case actionTypes.UPDATE_EMAIL_ADDRESS_POLICY_FAIL:
      return state
        .set('updateAddressPolicy', INITIAL_STATE.get('updateAddressPolicy'))
        .setIn(['updateAddressPolicy', 'failed'], true);

    case actionTypes.GET_EMAIL_DOMAINS_START:
      return state
        .set('emailDomains', INITIAL_STATE.get('emailDomains'))
        .setIn(['emailDomains', 'isLoading'], true);

    case actionTypes.GET_EMAIL_DOMAINS_SUCCESS:
      return state
        .set('emailDomains', INITIAL_STATE.get('emailDomains'))
        .setIn(
          ['emailDomains', 'values'],
          Immutable.fromJS(action.emailDomains)
        );

    case actionTypes.GET_EMAIL_DOMAINS_FAIL:
      return state
        .set('emailDomains', INITIAL_STATE.get('emailDomains'))
        .setIn(['emailDomains', 'failed'], true);

    case actionTypes.SELECT_EMAIL_DOMAIN_START:
      return state
        .set('selectEmailDomain', INITIAL_STATE.get('selectEmailDomain'))
        .setIn(['selectEmailDomain', 'isLoading'], true);

    case actionTypes.SELECT_EMAIL_DOMAIN_SUCCESS:
      return state
        .set('selectEmailDomain', INITIAL_STATE.get('selectEmailDomain'))
        .setIn(
          ['emailDomains', 'values'],
          Immutable.fromJS(action.emailDomains)
        );

    case actionTypes.SELECT_EMAIL_DOMAIN_FAIL:
      return state
        .set('selectEmailDomain', INITIAL_STATE.get('selectEmailDomain'))
        .setIn(['selectEmailDomain', 'failed'], true);

    case actionTypes.ADD_EMAIL_DOMAIN_START:
      return state
        .set('addEmailDomain', INITIAL_STATE.get('addEmailDomain'))
        .setIn(['addEmailDomain', 'isLoading'], true);

    case actionTypes.ADD_EMAIL_DOMAIN_SUCCESS:
      return state.set('addEmailDomain', INITIAL_STATE.get('addEmailDomain'));

    case actionTypes.ADD_EMAIL_DOMAIN_FAIL:
      return state
        .set('addEmailDomain', INITIAL_STATE.get('addEmailDomain'))
        .setIn(['addEmailDomain', 'failed'], true);

    default:
      return state;
  }
};

export default emailPoliciesReducer;
