/* eslint-disable sort-keys -- backend shape */
const data = {
  results: [
    { interaction_channel: 'Web', total: 11 },
    { interaction_channel: 'Slack', total: 1 },
  ],
  count: 2,
};
/* eslint-enable sort-keys */

export default data;
