import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreKeychains extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M79.74 43c-1.208-3.434-4.365-5.802-8-6 1.987-5.788.1-12.2-4.708-15.986-4.806-3.788-11.482-4.123-16.644-.837-5.162 3.285-7.684 9.475-6.288 15.433l-.18.19c-.71.78-1.313 1.65-1.79 2.59-7.81-2.17-16.75.92-22.05 8.32-6.66 9.31-5 21.88 3.66 28.09 8.66 6.21 21.11 3.69 27.76-5.61 4.176-5.696 5.21-13.11 2.75-19.73.974-.604 1.854-1.35 2.61-2.21.14-.16.27-.33.39-.49h.8c1.437 0 2.867-.215 4.24-.64.082 4.273 3.173 7.89 7.38 8.64l1.87 9.52 2 .44-1.55 1.71.67 3.57 2.06.45-1.59 1.75.38 2 2.93 2.26 1.89-.3 1.54-3.49-3.88-19c3.608-2.23 5.17-6.674 3.75-10.67zm-21.5 2.34c2.26-3.95 1.88-8.7-1.19-11.4-3.07-2.7-8.09-2.45-11.74.5-.86-5.376 1.74-10.715 6.503-13.353 4.762-2.638 10.667-2.01 14.77 1.57 4.1 3.582 5.52 9.348 3.547 14.423-3.94.523-7.068 3.567-7.7 7.49-1.345.48-2.76.735-4.19.75v.02zm8.54-1.44c1.56-1.186 2.863-2.676 3.83-4.38 1.605.31 2.756 1.726 2.73 3.36-.023 1.634-1.217 3.015-2.83 3.276-1.613.26-3.18-.673-3.72-2.216l-.01-.04z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogCompanyStoreKeychains;
