import actionTypes from './actionTypes';

const errorsActions = {};

errorsActions.setErrors = (errors) => ({
  errors,
  type: actionTypes.SET_ERRORS,
});

errorsActions.resetErrors = (errorId) => ({
  errorId,
  type: actionTypes.RESET_ERRORS,
});

errorsActions.forceResetErrors = () => ({
  type: actionTypes.FORCE_RESET_ERRORS,
});

export default errorsActions;
