// import PropTypes                from 'prop-types';
import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';

class IntegrationCSVMappingFields extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <div>
        <Header content='IntegrationCSVMappingFields' />
      </div>
    );
  }
}

export default IntegrationCSVMappingFields;
