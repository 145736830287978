import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import {
  Dimmer,
  Dropdown,
  Loader,
  Menu,
  Search,
  Segment,
} from 'semantic-ui-react';
import immutable from 'immutable';
import ImmutableProps from 'react-immutable-proptypes';
import _ from 'lodash';

// Molecule
import OnboardingActivityPerformanceTableList from './OnboardingActivityPerformanceTableList';
import MenuPagination from './MenuPagination';
// Controller
import OnboardActivityPerformanceListController from '../controllers/OnboardActivityPerformanceListController';
// utils
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';

// globals
import SortPerformanceStateOptions, {
  SortPerformanceFieldNames,
} from '../../globals/SortPerformanceStateOptions';
import ShowPerformanceGroupOptions from '../../globals/ShowPerformanceGroupOptions';
import NewHireStatus from '../../globals/NewHireStatus';

/**
 * @param {function} archiveOnePerformance Deletes a progress summary
 * @param {function} escalateProgress Escalates a user
 * @param {Object} intl i18n module for message formatting
 * @param {Number} limitByPage Number of items per page
 * @param {function} loadPerformanceList Loads a perfomance list
 * @param {Object} location Router's parameters
 * @param {function} searchPerformace Searches performance for a user (API)
 * @param {function} setSeachTerm Setter (callback) for the search's term
 * @param {Boolean} isLoadingPerformanceList Component's loading state
 * @param {Boolean} isSearching Component's searching state
 * @param {Number} listCount Max count of schedules in list
 * @param {Number} loadingDeleteID Id for the schedule currently being deleted
 * @param {Number} loadingEscalateID Progress for the user's id escalation
 * @param {Object} params URL parameters: userID
 * @param {immutable.List} performanceList Performance list immutable
 * @param {String} searchTerm Term currently being search
 */

const SEARCH_DELAY = 300;
const MIN_CHARACTERS = 3;

class OnboardingActivityPerformanceList extends PureComponent {
  static propTypes = {
    archiveOnePerformance: PropTypes.func.isRequired,
    escalateProgress: PropTypes.func.isRequired,
    groupPermissions: PropTypes.objectOf(PropTypes.string).isRequired,
    isLoadingPerformanceList: PropTypes.bool,
    isSearching: PropTypes.bool,
    limitByPage: PropTypes.number.isRequired,
    listCount: PropTypes.number,

    loadPerformanceList: PropTypes.func.isRequired,

    loadingDeleteID: PropTypes.number,

    loadingEscalateID: PropTypes.number,
    location: PropTypes.shape({
      query: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
        orderBy: PropTypes.any,
        page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
        show: PropTypes.any,
      }),
    }).isRequired,
    params: PropTypes.shape({
      userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    performanceList: ImmutableProps.list,
    searchPerformance: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    setSeachTerm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoadingPerformanceList: false,
    isSearching: false,
    listCount: 0,
    loadingDeleteID: null,
    loadingEscalateID: null,
    params: {},
    performanceList: immutable.List(),
    searchTerm: '',
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    orderBy: this.props.location.query.orderBy,
  };

  componentDidMount() {
    const {
      limitByPage,
      loadPerformanceList,
      location: { query },
    } = this.props;

    const page = query.page || 1;
    const { orderBy } = query;

    if (!query.show) {
      browserHistory.push({
        pathname: uiPathGenerator.genPath(
          'admin.adminActivities.onboardingActivities.performance'
        ),
        search: `?${queryString.stringify(
          Object.assign({}, query, {
            show: NewHireStatus,
          })
        )}`,
      });
    } else {
      loadPerformanceList(page, limitByPage, orderBy, query.show);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      limitByPage,
      loadPerformanceList,
      location: { query },
    } = this.props;
    const { orderBy } = this.state;

    const nextPage = nextProps.location.query.page;
    const nextPageOrderBy = nextProps.location.query.orderBy;
    const nextShow = nextProps.location.query.show;

    if (
      !nextProps.searchTerm &&
      (nextPage !== query.page || nextPageOrderBy !== orderBy)
    ) {
      this.setState({
        orderBy: nextPageOrderBy,
      });
      loadPerformanceList(nextPage, limitByPage, nextPageOrderBy, nextShow);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      limitByPage,
      searchTerm,
      searchPerformance,
      params: { userID },
      location: { query },
      loadPerformanceList,
      performanceList,
    } = this.props;

    const page = query.page || 1;
    const nextPage = nextProps.location.query.page || 1;
    const nextPageOrderBy = nextProps.location.query.orderBy;
    const nextShow = nextProps.location.query.show;

    if (
      nextProps.searchTerm !== searchTerm ||
      (nextProps.searchTerm !== '' && nextPage !== page)
    ) {
      let newPage = nextPage;
      if (searchTerm === '') {
        // New search - redirect to page 1 which will perform the search on page 1
        newPage = 1;
        browserHistory.push({
          pathname: uiPathGenerator.genPath(
            'admin.adminActivities.onboardingActivities.performance',
            {
              userID,
            }
          ),
          search: `?${queryString.stringify(
            Object.assign({}, query, {
              page: 1,
            })
          )}`,
        });
      }

      if (
        nextProps.searchTerm !== '' &&
        nextProps.searchTerm.length >= MIN_CHARACTERS
      ) {
        searchPerformance(newPage, limitByPage, nextPageOrderBy);
      } else if (nextProps.searchTerm === '') {
        // Search has been reseted
        loadPerformanceList(nextPage, limitByPage, nextPageOrderBy, nextShow);
      }
    } else if (
      nextProps.performanceList.isEmpty() &&
      performanceList.isEmpty() &&
      nextPage > 1
    ) {
      // The page is too high - back to 1
      browserHistory.push({
        pathname: uiPathGenerator.genPath(
          'admin.adminActivities.onboardingActivities.performance',
          {
            userID,
          }
        ),
        search: `?${queryString.stringify(
          Object.assign({}, query, {
            page: 1,
          })
        )}`,
      });
    } else if (nextShow && nextShow !== query.show) {
      loadPerformanceList(nextPage, limitByPage, nextPageOrderBy, nextShow);
    }
  }

  handleStartSearch = _.debounce((e, { value }) => {
    const { setSeachTerm } = this.props;
    setSeachTerm(value);
  }, SEARCH_DELAY);

  handleFilterSortChange = (e, { value }) => {
    const { location } = this.props;

    browserHistory.push({
      pathname: uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.performance'
      ),
      search: `?${queryString.stringify(
        Object.assign({}, location.query, {
          orderBy: value === '' ? null : value,
        })
      )}`,
    });
  };

  handleFilterShowChange = (e, { value }) => {
    const { location } = this.props;

    browserHistory.push({
      pathname: uiPathGenerator.genPath(
        'admin.adminActivities.onboardingActivities.performance'
      ),
      search: `?${queryString.stringify(
        Object.assign({}, location.query, {
          show: value === '' ? null : value,
        })
      )}`,
    });
  };

  render() {
    const {
      archiveOnePerformance,
      escalateProgress,
      groupPermissions,

      isLoadingPerformanceList,
      isSearching,
      limitByPage,
      listCount,
      loadingDeleteID,
      loadingEscalateID,
      performanceList,
      location: { query },
    } = this.props;

    const SortPerformanceOption = SortPerformanceStateOptions(intl);
    const showPerformanceGroupOptions = ShowPerformanceGroupOptions(
      intl,
      groupPermissions
    );

    return (
      <Segment attached className='esp-admin-onboarding-activities' clearing>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_onboarding_activities_performance',
          })}
        />

        <Menu borderless secondary>
          <Menu.Item fitted>
            <Search
              loading={isSearching}
              onSearchChange={this.handleStartSearch}
              placeholder='Search...'
              showNoResults={false}
            />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item fitted>
              <label>{'Show: '}</label>
              <Dropdown
                onChange={this.handleFilterShowChange}
                options={showPerformanceGroupOptions}
                selectOnBlur={false}
                value={query.show || ''}
              />
            </Menu.Item>
            <Menu.Item fitted>
              <label>{'Sort by: '}</label>
              <Dropdown
                onChange={this.handleFilterSortChange}
                options={SortPerformanceOption}
                selectOnBlur={false}
                value={
                  query.orderBy ||
                  SortPerformanceFieldNames.NEGATIVE_SYS_DATE_UPDATED
                }
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {isLoadingPerformanceList && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}

        <OnboardingActivityPerformanceTableList
          archiveOnePerformance={archiveOnePerformance}
          escalateProgress={escalateProgress}
          isLoading={isLoadingPerformanceList}
          loadingDeleteID={loadingDeleteID}
          loadingEscalateID={loadingEscalateID}
          query={query}
          tableList={performanceList}
        />

        <MenuPagination
          currentPage={Number(query.page) || 1}
          numberPages={Math.ceil(listCount / limitByPage) || 1}
          pageByQuery
          pathname={'admin.adminActivities.onboardingActivities.performance'}
          query={query}
        />
      </Segment>
    );
  }
}

const OnboardingActivityPerformanceListTest = OnboardingActivityPerformanceList;

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardingActivityPerformanceList = OnboardActivityPerformanceListController(
  OnboardingActivityPerformanceList
);
export { OnboardingActivityPerformanceListTest };
export default OnboardingActivityPerformanceList;
