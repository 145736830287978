import { TokenUtil } from 'esp-util-auth';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Divider, Loader, Menu } from 'semantic-ui-react';
import ChatBotIcon from '../../../globals/ChatBotIcon';
import APIcall from '../../utils/APIcall';
import browserHistory from '../../utils/browserHistory';
import endpointGenerator from '../../utils/endpointGenerator';
import sendMessageToParentFrame from '../../utils/sendMessageToParentFrame';
import { EVENT_WIDGET_AUTH_REDIRECT } from '../../globals/clientFrameKeys';
import uiPathGenerator from '../../utils/uiPathGenerator';
// import uiPathGenerator from '../../utils/uiPathGenerator';

const handleLogin = () => {
  const isIframeWidget = window.self !== window.top;

  if (isIframeWidget) {
    // const url = new URL(window.location.href);
    // const chatVersion = url.searchParams.get('chat_version');
    // const loginUrl = `${window.origin}/login?client=widget&chat_version=${chatVersion}`;
    const loginUrl = `${window.origin}/login?client=widget`;
    sendMessageToParentFrame(EVENT_WIDGET_AUTH_REDIRECT, { url: loginUrl });
  } else {
    // eslint-disable-next-line no-console -- debug
    console.warn('[WidgetAuthRequired} not in iframe');
  }
};

// Page that used by embed widget to redirect host domain to login
// See WidgetAuthSuccess for the page that is used to redirect back to the widget after
// See apps/embed/src/index.js for the code that handles the message and redirect code
// eslint-disable-next-line react/prop-types -- props are passed by react-router
const WidgetAuthRequired = ({ location }) => {
  const [loading, setLoading] = React.useState(true);
  // eslint-disable-next-line react/prop-types -- props are passed by react-router
  const otc = new URLSearchParams(location?.search).get('otc');

  useEffect(() => {
    if (!otc) {
      setLoading(false);
      return;
    }

    // Tries to exchange the otc for a token, if successful it will redirect to the portal
    const exchangeOtc = async () => {
      try {
        const response = await APIcall.post({
          data: { code: otc },
          token: true,
          url: endpointGenerator.genPath(
            'authentication.auth.login.exchangeOtc'
          ),
        });

        if (!response.body?.key) {
          // eslint-disable-next-line no-console -- debug
          console.warn('failed to exchange otc');
          return;
        }
        const { key } = response.body;
        TokenUtil.setToken(key);
        const url = new URL(window.location.href);
        const chatVersion = url.searchParams.get('chat_version');
        if (chatVersion === '2') {
          const chatAppPath = uiPathGenerator.genPath('chatV2App');
          window.location.replace(`${chatAppPath}/`);
          return;
        }
        browserHistory.replace('/portal?client=widget');
      } catch (e) {
        // eslint-disable-next-line no-console -- debug
        console.warn('failed to exchange otc', e);
        setLoading(false);
      }
    };

    exchangeOtc();
  }, [otc]);

  return (
    <div>
      {loading && (
        <div>
          <Loader size='large' />
        </div>
      )}
      {!loading && (
        <section>
          <Helmet title='Widget Auth Required' />

          <div style={{ textAlign: 'center' }}>
            <Menu borderless>
              <Menu.Item content={'Get Help'} header />
            </Menu>
            <Divider hidden />
            <div>
              <ChatBotIcon isCustomBrandingEnabled size='large' />
            </div>
            <div>
              <div className='barista_404_bubble'>
                <p>
                  {
                    'Hello there! We need to log you in to your organization. Click the button below to login.'
                  }
                  <br />
                  {'After that we can get to chatting!'}
                </p>
                <Button onClick={handleLogin} primary>
                  Login
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default WidgetAuthRequired;
