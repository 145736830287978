import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { curry, last, memoize, noop } from 'lodash';
import { Button, Divider, Form, Grid, Modal, Table } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import immutable from 'immutable';

// Utils
import CRUDTable from '../../../v2/components/functional/CRUDTable';

import CSVImportScheduleForm from '../../../v2/components/functional/CSVImportSchedule/CSVImportScheduleForm';

class NewCSVImportScheduleList extends PureComponent {
  static propTypes = {
    businessObjects: ImmutablePropTypes.list,
    deleteSchedule: PropTypes.func,
    isLoading: PropTypes.bool,
    listData: ImmutablePropTypes.list,
    scheduleConnectionSuccess: PropTypes.bool,
    testSchedule: PropTypes.func,
  };

  static defaultProps = {
    businessObjects: immutable.List(),
    deleteSchedule: noop,
    isLoading: false,
    listData: immutable.List(),
    scheduleConnectionSuccess: false,
    testSchedule: noop,
  };

  state = {
    isScheduleModalOpen: false,
    isTestModalOpen: false,
    rowData: {},
  };

  getBusinessObject(business_object) {
    const { businessObjects } = this.props;
    const businessObject = businessObjects.find(
      (b) => b.get('id') === business_object
    );
    return businessObject && businessObject.size > 0
      ? businessObject.get('name')
      : '';
  }

  handleCloseTestModal = () => this.setState({ isTestModalOpen: false });

  handleDelete = memoize(
    curry((scheduleToDelete, e) => {
      e.preventDefault();
      const { deleteSchedule } = this.props;

      deleteSchedule(scheduleToDelete);
    })
  );

  handleScheduleModalClose = () =>
    this.setState(() => ({
      isScheduleModalOpen: false,
      rowData: {},
    }));

  handleScheduleModalOpen = (rowData = immutable.Map()) => () =>
    this.setState(() => ({
      isScheduleModalOpen: true,
      rowData: {
        business_object: rowData.get('business_object'),
        id: rowData.get('id'),
        import_frequency: {
          interval: rowData.get('interval'),
          periodicity: rowData.get('periodicity'),
          start_time: rowData.get('start_time', ''),
        },
        import_object_upon_save: rowData.get('import_object_upon_save'),
        parse_now: rowData.get('parse_now'),
        port_number: rowData.get('port_number'),
        protocol: rowData.get('protocol'),
        remote_file_path: rowData.get('remote_file_path'),
        server: rowData.get('ip_address') || rowData.get('dns_name'),
        username: rowData.get('username'),
      },
    }));

  handleTest = memoize(
    curry((scheduleToTest, e) => {
      e.preventDefault();
      const { testSchedule } = this.props;

      this.setState({ isTestModalOpen: true }, () =>
        testSchedule(scheduleToTest)
      );
    })
  );

  render() {
    const { isLoading, listData, scheduleConnectionSuccess } = this.props;

    const LABELS = {
      CANCEL: 'Cancel',
      EDIT: 'Edit',
      EMPTY_STATE_BODY:
        'Set up an crucial business object for importing at regular intervals',
      EMPTY_STATE_TITLE: 'No Imports Scheduled',
      FILE_INFO: 'File info',
      LAST_IMPORT: 'Last import',
      MAPPING: 'Mapping',
      OK: 'OK',
      TEST: 'Test',
      TEST_MODAL_DESCRIPTION_FAIL: 'Schedule settings are incorrect',
      TEST_MODAL_DESCRIPTION_LOADING: 'Testing schedule settings...',
      TEST_MODAL_DESCRIPTION_SUCCESS: 'Schedule settings are correct',
      UNSCHEDULE: 'Unschedule',
      UNSCHEDULE_MODAL_DESCRIPTION:
        'Unscheduling will disable import and remove the record of the schedule.',
      UNSCHEDULING: 'Unscheduling',
    };

    const TEST_CONTENT = isLoading
      ? LABELS.TEST_MODAL_DESCRIPTION_LOADING
      : scheduleConnectionSuccess
      ? LABELS.TEST_MODAL_DESCRIPTION_SUCCESS
      : LABELS.TEST_MODAL_DESCRIPTION_FAIL;

    const modalStyles = {
      modal: {
        height: 'auto',
        transform: 'translateX(0) translateY(-50%)',
      },
    };

    const NewScheduleButton = (props) => (
      <Button
        {...props}
        basic
        content='Schedule an object for import'
        disabled={isLoading}
        onClick={this.handleScheduleModalOpen()}
      />
    );

    // style prop is used to override semantic ui confirm primary style
    const UnscheduleButton = (props) => (
      <Button
        {...props}
        content={isLoading ? LABELS.UNSCHEDULING : LABELS.UNSCHEDULE}
        disabled={isLoading}
        negative
        style={{ backgroundColor: 'red' }}
      />
    );

    const renderCreateScheduleModal = () => {
      const { rowData, isScheduleModalOpen } = this.state;
      const { import_frequency = {}, ...values } = rowData;

      return (
        <Modal
          as={Form}
          className='scrolling'
          content={
            <CSVImportScheduleForm
              importFrequencyValues={import_frequency}
              onClose={this.handleScheduleModalClose}
              onTest={this.handleTest}
              values={values}
            />
          }
          header={'Schedule an Object for Import'}
          open={isScheduleModalOpen}
          size='large'
        />
      );
    };

    const EmptyState = (
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan='4' textAlign='center'>
            <h1>{LABELS.EMPTY_STATE_TITLE}</h1>
            <p>{LABELS.EMPTY_STATE_BODY}</p>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    );

    const fileStyle = {
      display: 'block',
      fontWeight: 'bold',
      textTransform: 'capitalize',
    };

    const headers = (
      <>
        <Table.HeaderCell>{LABELS.FILE_INFO}</Table.HeaderCell>
        <Table.HeaderCell>{LABELS.MAPPING}</Table.HeaderCell>
        <Table.HeaderCell>{LABELS.LAST_IMPORT}</Table.HeaderCell>
        <Table.HeaderCell>{''}</Table.HeaderCell>
      </>
    );

    const DeleteButtonModal = (props) => (
      <Modal
        actions={[
          'Cancel',
          {
            content: LABELS.UNSCHEDULE,
            disabled: isLoading,
            key: 'unschedule',
            negative: true,
            onClick: this.handleDelete(props.row),
          },
        ]}
        centered={false}
        closeOnDimmerClick={false}
        content={LABELS.UNSCHEDULE_MODAL_DESCRIPTION}
        header={LABELS.UNSCHEDULE}
        size={'mini'}
        style={modalStyles}
        trigger={<UnscheduleButton basic />}
      />
    );

    const TestButtonModal = (props) => {
      const { isTestModalOpen } = this.state;
      return (
        <Modal
          actions={[
            {
              content: LABELS.OK,
              disabled: isLoading,
              key: 'ok',
              onClick: this.handleCloseTestModal,
            },
          ]}
          centered={false}
          closeOnDimmerClick={false}
          content={TEST_CONTENT}
          header={LABELS.TEST}
          open={isTestModalOpen}
          size={'mini'}
          style={modalStyles}
          trigger={
            <Button
              basic
              content={LABELS.TEST}
              disabled={isLoading}
              onClick={this.handleTest(props.row)}
              positive
            />
          }
        />
      );
    };

    const scheduleRows = listData.map((row) => (
      <Table.Row key={row.get('id')}>
        {
          <>
            <Table.Cell>
              <span style={fileStyle}>
                {last(row.get('remote_file_path').split('/'))}
              </span>
              {row.get('remote_file_path')}
            </Table.Cell>
            <Table.Cell>
              {this.getBusinessObject(row.get('business_object'))}
            </Table.Cell>
            <Table.Cell>
              {moment(row.get('sys_date_updated')).format('LLLL')}
            </Table.Cell>
          </>
        }
        {
          <Table.Cell>
            <Grid
              columns='equal'
              style={{
                paddingBottom: '.25em',
                paddingTop: '.25em',
              }}
            >
              <Grid.Row>
                <Grid.Column textAlign='right'>
                  <TestButtonModal row={row} />

                  <Button
                    basic
                    content={LABELS.EDIT}
                    disabled={isLoading}
                    icon
                    onClick={this.handleScheduleModalOpen(row)}
                    positive
                  />

                  <DeleteButtonModal row={row} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
        }
      </Table.Row>
    ));

    return (
      <div className='schedule-list'>
        {renderCreateScheduleModal()}
        <NewScheduleButton floated='right' />
        <Divider clearing hidden />
        <CRUDTable
          emptyState={EmptyState}
          headers={headers}
          isLoading={isLoading}
          totalRows={listData.size}
        >
          {scheduleRows}
        </CRUDTable>
      </div>
    );
  }
}

export default NewCSVImportScheduleList;
