import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Divider, Header, Segment } from 'semantic-ui-react';

// Molecule
import FileUploadForm from '../../../molecules/FileUploadForm';

// Controller
import AdminFilesController from '../../../controllers/AdminFilesController';

class AdminFiles extends Component {
  static propTypes = {
    /** Saving / Loading file state */
    isLoading: PropTypes.bool,

    /** Submit Image handler */
    submitFile: PropTypes.func.isRequired,

    /** Array of image type accepted in the tenant upload image form */
    typeFilesAccepted: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isLoading: false,
  };

  shouldComponentUpdate(nextProps) {
    const { isLoading } = this.props;

    if (nextProps.isLoading !== isLoading) {
      return true;
    }

    return true;
  }

  handleAddFileSubmit = (tenantFile) => {
    const { submitFile } = this.props;

    submitFile(tenantFile, intl);
  };

  render() {
    const { isLoading, typeFilesAccepted } = this.props;

    return (
      <Segment attached>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_global_files',
          })}
        />

        <Header as='h4' content='Upload a File' dividing />

        <Divider hidden />

        <FileUploadForm
          accept={typeFilesAccepted}
          isLoading={isLoading}
          onFileSubmit={this.handleAddFileSubmit}
          uploadFieldId='meta_image'
        />
      </Segment>
    );
  }
}

const AdminFilestest = AdminFiles;

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminFiles = AdminFilesController(AdminFiles);
export { AdminFilestest };
export default AdminFiles;
