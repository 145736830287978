import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  disableCaseCheck: {
    default: false,
    type: 'bool',
  },
  disableConfirmation: {
    default: false,
    type: 'bool',
  },
  disableNumberCheck: {
    default: false,
    type: 'bool',
  },
  disableSpecialCheck: {
    default: false,
    type: 'bool',
  },
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputValue: {
    objectShape: {
      map: {
        requred: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  label: {
    required: true,
    type: 'string',
  },
  labelConfirm: {
    default: 'Confirm Password',
    required: false,
    type: 'string',
  },
  passwordLengthMinimum: {
    default: 8,
    type: 'number',
  },
});

// Object Definition used by blobifier
const ChangePasswordDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Change Password',
  templates: ['changePassword01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, ChangePasswordDefinition, TypeDefaults };
