const api_task_v0_1_categories = {
  count: 1,
  results: [
    {
      classification: 148,
      description: null,
      eid: 'd14923c5-3512-42d0-bbc0-041a2be8075b',
      id: 60,
      keywords: null,
      name: 'test',
      resolve_sla: 4,
      response_sla: 3,
      service_department: 4,
      sys_created_by: 'pascal@espressive.com',
      sys_date_created: '2018-04-18T23:11:55.712438Z',
      sys_date_updated: '2018-04-18T23:11:55.712411Z',
      sys_updated_by: 'pascal@espressive.com',
      system: false,
      teams: [20],
      url: 'https://release.qa.espressive.com/api/task/v0.1/categories/60/',
    },
  ],
};

export default api_task_v0_1_categories;
