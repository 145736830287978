import actionTypes from './actionTypes';

const csvCasesReportActions = {};

csvCasesReportActions.getCsvCasesReportJobSuccess = (jobID) => ({
  jobID,
  type: actionTypes.GET_CSV_CASES_REPORT_JOB_SUCCESS,
});

csvCasesReportActions.loadCsvCasesReportFail = () => ({
  type: actionTypes.LOAD_CSV_CASES_REPORT_FAIL,
});

csvCasesReportActions.loadCsvCasesReportStart = () => ({
  type: actionTypes.LOAD_CSV_CASES_REPORT_START,
});

csvCasesReportActions.resetDownloadURL = () => ({
  type: actionTypes.RESET_CSV_CASES_REPORT,
});

csvCasesReportActions.loadCsvCasesReportSuccess = (results) => ({
  results,
  type: actionTypes.LOAD_CSV_CASES_REPORT_SUCCESS,
});

export default csvCasesReportActions;
