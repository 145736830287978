import buildCompositeController from '../../utils/buildCompositeController';

import { initialize } from 'redux-form';

// Thunk
import assetThunk from '../../actions/assetThunks';

// Store
import Store from '../../stores/store';

// Utilization rate to be sent as props
import utilizationFrequencies from '../../globals/utilizationFrequencies';

import CatalogCategoryNames from '../../globals/CatalogCategoryNames';

const mapStateToProps = (state) => {
  const assetsItems = state.getIn(['asset', 'assetItems']);
  const softwares = assetsItems.filter((asset) =>
    asset.get('categories_all').includes(CatalogCategoryNames.SOFTWARE)
  );

  return {
    isLoading: state.getIn(['asset', 'isLoading']),
    softwares: softwares,
    utilizationFrequencies: utilizationFrequencies,
  };
};

const mapDispatchToProps = (dispatch) => ({
  initialize: () => {
    dispatch(
      assetThunk.loadAssets(CatalogCategoryNames.SOFTWARE, (assets) => {
        const initialValues = {};

        assets.forEach((asset) => {
          initialValues[asset.id] = asset.usage_frequency;
        });

        dispatch(initialize('Form01', initialValues));
      })
    );
  },
  submitEquipment: (softwares) => {
    softwares.forEach((sw) => {
      const store = Store.getState();
      const swId = sw.get('id');
      const valueUsage = store.getIn([
        'form',
        'Form01',
        'values',
        String(swId),
      ]);
      const swCurrentUsage = sw.get('usage_frequency');
      if (valueUsage !== swCurrentUsage) {
        dispatch(assetThunk.changeAssetFrequency(swId, valueUsage));
      }
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
