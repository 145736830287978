import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class RangeCell extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    label: '',
    value: 'N/A',
  };

  render() {
    const { label, value } = this.props;
    return (
      <div
        style={{
          borderRight: '1px solid #d4d4d5',
        }}
      >
        <label>{label}</label>
        <br />
        <b>{value}</b>
      </div>
    );
  }
}

export default RangeCell;
