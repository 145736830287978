import React, { PureComponent } from 'react';
import LanguageSelect from '../../../../v2/components/form/LanguageSelect';
import TypeSelectLanguage, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectLanguage';

class SelectLanguage01 extends PureComponent {
  static propTypes = {
    ...TypeSelectLanguage,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  render() {
    const { id, labelText, placeholder, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <LanguageSelect
          labelText={labelText}
          name={id.toString()}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export const SelectLanguage01Test = SelectLanguage01;

export default SelectLanguage01;
