/**
 * BreakPoints for Fela Theme
 * @type {{small: string, ip5: string, medium: string, large: string, xlarge: string, xxlarge: string}}
 */
const breakpoints = {
  ip5: '321px',
  iphonex:
    'only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)',
  large: '1024px',
  medium: '640px',
  small: '0',
  xlarge: '1200px',
  xxlarge: '1368px',
};

export default breakpoints;
