import React, { Component } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isBoolean, isNil, noop } from 'lodash';
import ScrollArea from '../../../../v1/components/atoms/ScrollArea';
import EspModalCloseButton from './EspModalCloseButton';

const styles = {
  modal: {
    height: '100%',
    maxHeight: '736px',
  },
};

class EspModal extends Component {
  static propTypes = {
    actions: PropTypes.element,
    actionsHeight: PropTypes.number,
    className: PropTypes.string,
    closeIcon: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object,
      PropTypes.string,
    ]),
    closeOnDimmerClick: PropTypes.bool,
    content: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.string,
    ]),
    contentOnly: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.string,
    ]),
    contentPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    defaultOpen: PropTypes.bool,
    disableContentScroll: PropTypes.bool,
    form: PropTypes.element,
    header: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.string,
    ]),
    headerHeight: PropTypes.number,
    innerRef: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
    preScrollContent: PropTypes.element,
    preScrollContentHeight: PropTypes.number,
    size: PropTypes.string,
    trigger: PropTypes.element,
  };

  static defaultProps = {
    actions: void 0,
    actionsHeight: 69,
    className: void 0,
    closeIcon: <EspModalCloseButton />,
    closeOnDimmerClick: void 0,
    content: void 0,
    contentOnly: void 0,
    contentPadding: 0,
    defaultOpen: void 0,
    disableContentScroll: false,
    form: void 0,
    header: void 0,
    headerHeight: 48,
    innerRef: void 0,
    onClose: noop,
    onOpen: noop,
    open: void 0,
    preScrollContent: void 0,
    preScrollContentHeight: void 0,
    size: 'small',
    trigger: null,
  };

  state = {
    isOpen: false,
  };

  componentDidMount() {
    const { preScrollContent, preScrollContentHeight } = this.props;

    if (Boolean(preScrollContent) && isNil(preScrollContentHeight)) {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'EspModal: You must define preScrollContentHeight when defining preScrollContent.'
      );
    }
  }

  /**
   * Public method to be called to open modal programatically by using ref.
   * I'm taking this pattern from react-portal
   * https://github.com/tajo/react-portal#open-modal-programmatically
   */
  open = () => {
    this.handleOpenModal();
  };

  /**
   * Same as this.open
   */
  close = () => {
    this.handleCloseModal();
  };

  handleOpenModal = () => {
    const { onOpen } = this.props;
    this.setState(
      {
        isOpen: true,
      },
      () => {
        onOpen();
      }
    );
  };

  handleCloseModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { onClose } = this.props;
    onClose();
    this.setState({
      isOpen: false,
    });
  };

  renderGuts() {
    const {
      actions,
      actionsHeight,
      content,
      contentPadding,
      disableContentScroll,
      header,
      headerHeight,
      preScrollContent,
      preScrollContentHeight,
    } = this.props;

    // If no header or actions are defined, do not calculate their height
    const actualHeaderHeight = header && headerHeight ? headerHeight : 0;
    const actualActionsHeight = actions && actionsHeight ? actionsHeight : 0;
    const actualpreScrollHeight =
      preScrollContent && preScrollContentHeight ? preScrollContentHeight : 0;
    const headerPlusActionsHeight =
      Number(actualHeaderHeight) +
      Number(actualActionsHeight) +
      Number(actualpreScrollHeight);

    const contentHeight = headerPlusActionsHeight
      ? `calc(100% - ${headerPlusActionsHeight}px)`
      : '100%';

    return (
      <>
        {header ? <Modal.Header>{header}</Modal.Header> : null}

        {preScrollContent}

        {content ? (
          disableContentScroll ? (
            <Modal.Content
              style={{
                height: contentHeight,
                padding: contentPadding,
              }}
            >
              {content}
            </Modal.Content>
          ) : (
            <ScrollArea height={contentHeight}>
              <Modal.Content
                style={{
                  padding: contentPadding,
                }}
              >
                {content}
              </Modal.Content>
            </ScrollArea>
          )
        ) : null}

        {actions ? <Modal.Actions>{actions}</Modal.Actions> : null}
      </>
    );
  }

  render() {
    const {
      className,
      closeIcon,
      closeOnDimmerClick,
      contentOnly,
      defaultOpen,
      form,
      innerRef,
      open,
      size,
      trigger,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <Modal
        className={`${className} scrolling`}
        closeIcon={closeIcon}
        closeOnDimmerClick={closeOnDimmerClick}
        data-component='EspModal'
        defaultOpen={defaultOpen}
        onClose={this.handleCloseModal}
        onOpen={this.handleOpenModal}
        open={isBoolean(open) ? open : isOpen}
        ref={innerRef}
        size={size}
        style={styles.modal}
        trigger={trigger}
      >
        {contentOnly ? (
          contentOnly
        ) : form ? (
          <Form>{this.renderGuts()}</Form>
        ) : (
          <div className='ui form'>{this.renderGuts()}</div>
        )}
      </Modal>
    );
  }
}

export default EspModal;
