import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Icon } from 'semantic-ui-react';
import { darken } from 'polished';

// import EspIcon             from '../../../../v1/components/atoms/EspIcon';

const rule = (props) => {
  const { isLoading, theme } = props;

  const focusHoverStyle = {
    backgroundColor: theme.colors.white,
    boxShadow: `0 0 0 2px ${darken(0.04, theme.colors.red)} inset`,
    color: darken(0.04, theme.colors.red),
    outlineStyle: 'none',
  };

  return {
    ':active': {
      backgroundColor: darken(0.16, theme.colors.white),
    },
    ':focus': focusHoverStyle,
    ':hover': focusHoverStyle,
    '> i.icon': {
      width: '100%',
    },
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '100px',
    boxShadow: `0 0 0 2px ${theme.colors.red} inset`,
    color: theme.colors.red,
    cursor: 'pointer',
    display: 'inline-block',
    height: '1.75em',
    marginLeft: '.25em',
    overflow: 'hidden',
    padding: 0,
    pointerEvents: isLoading ? 'none' : 'auto',
    position: 'relative',
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'background',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    verticalAlign: 'middle',
    width: '1.75em',
  };
};

class ChatFormAttachmentDelete extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: void 0,
  };

  render() {
    const { className, onClick } = this.props;

    return (
      <button
        className={className}
        data-component='ChatFormAttachmentDelete'
        onClick={onClick}
        type='button'
      >
        <Icon fitted name='close' />
      </button>
    );
  }
}

export default createComponent(rule, ChatFormAttachmentDelete, [
  'isLoading',
  'onClick',
]);
