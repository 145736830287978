import { change } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import baristaActions from '../../actions/baristaActions';
// Selector
import getFAQServiceDeptIDSelected from '../../selectors/getFAQServiceDeptIDSelected';
import { getMyServiceDepartments } from '../../selectors/getSelectedServiceTeam';

const mapStateToProps = (state, ownProps) => ({
  categoriesList: state.getIn([
    'caseMgmt',
    'serviceDepartmentCategories',
    'list',
  ]),
  departmentId: getFAQServiceDeptIDSelected(state),
  isLoadingCategory: state.getIn([
    'caseMgmt',
    'serviceDepartmentCategories',
    'isLoading',
  ]),
  myServiceDeparment: getMyServiceDepartments(state),
  valueSelected: state.getIn(['form', 'Form01', 'values', ownProps.id]),
});

const mapDispatchToProps = (dispatch) => ({
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
  loadCategoryList(departmentID, searchTerm) {
    const orderBy = '-sys_date_updated';
    dispatch(
      caseMgmtThunks.loadTaskCategories({
        departmentID,
        orderBy,
        searchTerm,
      })
    );
  },

  selectServiceDepartment(departmentID, departmentTypeID) {
    dispatch(
      baristaActions.faqSelectDepartment(departmentID, departmentTypeID)
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
