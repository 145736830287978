import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header } from 'semantic-ui-react';
import { Boundaries } from 'cascara-middleware';
import moment from 'moment';
import {
  compact,
  curry,
  isEmpty,
  isFunction,
  memoize,
  noop,
  reduce,
} from 'lodash';

const validateValues = (values, viewOptionsFilter) => {
  if (!isEmpty(values) && !isEmpty(viewOptionsFilter)) {
    const validatedValues = reduce(
      values,
      (acc, value, key) => {
        const field = viewOptionsFilter.form.find(
          (field) => field.name === key
        );
        const options =
          field && isFunction(field.options) ? field.options() : field.options;
        if (!isEmpty(options)) {
          const option = options.find((option) => option.value === value);
          return option
            ? Object.assign(acc, { [key]: value })
            : Object.assign(acc, { [key]: '' });
        } else {
          return Object.assign(acc, { [key]: value });
        }
      },
      {}
    );

    return {
      values: validatedValues,
    };
  }

  return null;
};

class ReportViewOptions extends PureComponent {
  static propTypes = {
    INPUT_DATE_FORMAT: PropTypes.string,
    onChange: PropTypes.func,
    onClickViewReport: PropTypes.func,
    reportDisplayChart: PropTypes.func.isRequired,
    values: PropTypes.objectOf(PropTypes.string),
    viewOptionsFilter: PropTypes.shape({
      buttonName: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      form: PropTypes.array,
      title: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      validation: PropTypes.array,
    }),
  };

  static defaultProps = {
    INPUT_DATE_FORMAT: '',
    onChange: noop,
    onClickViewReport: noop,
    values: {},
    viewOptionsFilter: {},
  };
  state = {
    isDateValid: false,
    values: {},
  };

  componentDidMount() {
    const { values, viewOptionsFilter } = this.props;

    if (!isEmpty(values)) {
      const validatedValues = validateValues(values, viewOptionsFilter);
      if (
        compact(Object.values(validatedValues.values)).length ===
        viewOptionsFilter.form.length
      ) {
        this.setValues({
          ...validatedValues,
          isDateValid: true,
        });
        this.handleViewReport(validatedValues.values)();
      } else {
        this.setValues(validatedValues);
      }
    }
  }

  // eslint-disable-next-line react/destructuring-assignment -- risky to fix
  startDateDateMaxLimit = moment().format(this.props.INPUT_DATE_FORMAT);

  endDateDateLimit = moment()
    .add(1, 'days')
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    .format(this.props.INPUT_DATE_FORMAT);

  handleChange = (e, data) => {
    const { name, value } = data;
    const { onChange } = this.props;

    const { values } = this.state;
    const newValues = { ...values };
    newValues[name] = value;

    onChange(data, { [name]: data.value });
    this.setState({ values: newValues });
    this.formatValidation();
  };

  handleOnChangeDate = memoize(
    curry((type, e) => {
      let duration = 0;
      const { INPUT_DATE_FORMAT, onChange } = this.props;
      const isDateValid = moment(
        e.target.value,
        INPUT_DATE_FORMAT,
        true
      ).isValid();
      const {
        values: { startDate, endDate },
      } = this.state;

      const { values } = this.state;
      const newValues = { ...values };

      if (type === 'startDate') {
        if (endDate) {
          duration = moment
            .duration(moment(endDate).diff(moment(e.target.value)))
            .as('days');
        }
        if (duration >= 0) {
          if (duration >= 30) {
            newValues.endDate = moment(e.target.value)
              .add(30, 'days')

              .format(INPUT_DATE_FORMAT);
            newValues.startDate = e.target.value;

            onChange(null, {
              endDate: newValues.endDate,
              startDate: e.target.value,
            });
            this.setState({
              isDateValid,
              values: newValues,
            });
          } else {
            onChange(null, {
              endDate: newValues.endDate,
              startDate: e.target.value,
            });
            newValues.startDate = e.target.value;
            this.setState({
              isDateValid,
              values: newValues,
            });
          }
        }
      } else {
        duration = moment
          .duration(moment(e.target.value).diff(moment(startDate)))
          .as('days');
        if (duration >= 0 || !startDate) {
          if (duration > 30) {
            values.startDate = moment(e.target.value)
              .subtract(30, 'days')

              .format(INPUT_DATE_FORMAT);
            values.endDate = e.target.value;

            onChange(null, {
              endDate: e.target.value,
              startDate: values.startDate,
            });
            this.setState({
              isDateValid,
              values,
            });
          } else {
            values.endDate = e.target.value;

            onChange(null, {
              endDate: e.target.value,
              startDate: values.startDate,
            });
            this.setState({
              isDateValid,
              values,
            });
          }
        } else {
          values.endDate = e.target.value;

          onChange(null, {
            endDate: e.target.value,
            startDate: values.startDate,
          });
          this.setState({
            isDateValid,
            values,
          });
        }
      }
      this.formatValidation();
    })
  );

  handleViewReport = (valuesFromParameter) => () => {
    const { onClickViewReport, reportDisplayChart } = this.props;
    const { values } = this.state;

    this.formatValidation();

    reportDisplayChart(valuesFromParameter || values);

    if (!valuesFromParameter) {
      onClickViewReport(values);
    }
  };

  formatValidation = () => {
    const { viewOptionsFilter } = this.props;
    const { validation } = viewOptionsFilter;
    const { values } = this.state;

    const newValues = { ...values };
    return validation.every((val) => Boolean(newValues[val]));
  };

  setValues = (values) => this.setState(values);

  render() {
    const { viewOptionsFilter } = this.props;

    const { isDateValid, values } = this.state;
    const disableButton = this.formatValidation() && isDateValid;

    return (
      <Boundaries>
        <section>
          <Header content={viewOptionsFilter.title} />
          <Form>
            {viewOptionsFilter.form.map((viewOption) => {
              const CustomComponent = viewOption.component;
              const options = isFunction(viewOption.options)
                ? viewOption.options()
                : viewOption.options;

              return (
                <CustomComponent
                  disabled={
                    viewOption.component === Form.Dropdown && isEmpty(options)
                  }
                  key={viewOption.id}
                  label={viewOption.label}
                  max={
                    viewOption.name === 'startDate'
                      ? this.startDateDateMaxLimit
                      : this.endDateDateLimit
                  }
                  min={viewOption.name === 'endDate' ? values.startDate : ''}
                  name={viewOption.name}
                  onChange={
                    viewOption.name === 'startDate' ||
                    viewOption.name === 'endDate'
                      ? this.handleOnChangeDate(viewOption.name)
                      : this.handleChange
                  }
                  options={options}
                  placeholder={viewOption.placeholder}
                  selection={viewOption.selection}
                  type={viewOption.type}
                  value={values[viewOption.name] || ''}
                />
              );
            })}
            <Button
              content={viewOptionsFilter.buttonName}
              disabled={!disableButton}
              onClick={this.handleViewReport()}
              positive
            />
          </Form>
        </section>
      </Boundaries>
    );
  }
}

export default ReportViewOptions;
