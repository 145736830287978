import React from 'react';
import PropTypes from 'prop-types';
import FormInputSelect from './FormInputSelect';
import { Field } from 'redux-form/immutable';

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      key: PropTypes.any,
      text: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-15260
      value: PropTypes.any,
    })
  ).isRequired,
  placeholder: PropTypes.string,
};

const ValidatedSelect = ({
  name = '',
  label = null,
  placeholder = null,
  options,
  ...rest
}) => (
  <Field
    {...rest}
    component={FormInputSelect}
    label={label}
    name={name}
    options={options}
    placeholder={placeholder}
  />
);

ValidatedSelect.propTypes = propTypes;
export default ValidatedSelect;
