import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Menu, Modal } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';

// Molecule
import SupportForm from './SupportForm';

class SupportMessageModal extends PureComponent {
  static propTypes = {
    trigger: PropTypes.node.isRequired,
  };

  static defaultProps = {};

  state = {
    modalOpen: false,
  };

  handleModalOpen = () =>
    this.setState({
      modalOpen: true,
    });

  handleModalClose = () =>
    this.setState({
      modalOpen: false,
    });

  render() {
    const { trigger } = this.props;
    const { modalOpen } = this.state;

    const modalCloseButton = (
      <Menu.Item className='close'>
        {intl.formatMessage({
          id: 'label.close',
        })}
      </Menu.Item>
    );

    const supportTrigger = React.cloneElement(trigger, {
      onClick: this.handleModalOpen,
    });

    return (
      <Modal
        className='scrolling'
        closeIcon={modalCloseButton}
        closeOnDimmerClick={false}
        onClose={this.handleModalClose}
        open={modalOpen}
        size='small'
        trigger={supportTrigger}
      >
        <SupportForm onClose={this.handleModalClose} />
      </Modal>
    );
  }
}

export default SupportMessageModal;
