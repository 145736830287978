import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsShoppingCart extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M83 10H65c-1.38.012-2.59.913-3 2.23L47.84 57.58H23.09l-6.83-29.27c-.257-1.104-1.084-1.987-2.17-2.316-1.083-.33-2.26-.055-3.09.72-.826.775-1.177 1.932-.92 3.036l7.4 31.72c.335 1.436 1.616 2.45 3.09 2.45h29.6c1.38-.012 2.59-.913 3-2.23l14.15-45.35H83c1.75 0 3.17-1.42 3.17-3.17S84.75 10 83 10zM22.16 69.21c-4.38 0-7.93 3.55-7.93 7.93s3.55 7.93 7.93 7.93 7.93-3.55 7.93-7.93-3.55-7.93-7.93-7.93zm0 12.69c-2.63 0-4.76-2.13-4.76-4.76s2.13-4.76 4.76-4.76 4.76 2.13 4.76 4.76c0 1.262-.5 2.473-1.394 3.366-.893.893-2.104 1.394-3.366 1.394zM47.53 69.21c-4.38 0-7.93 3.55-7.93 7.93s3.55 7.93 7.93 7.93 7.93-3.55 7.93-7.93-3.55-7.93-7.93-7.93zm0 12.69c-1.925 0-3.66-1.16-4.398-2.938-.736-1.78-.33-3.826 1.032-5.188 1.362-1.36 3.41-1.768 5.188-1.032 1.778.737 2.938 2.473 2.938 4.398 0 1.262-.5 2.473-1.394 3.366-.893.893-2.104 1.394-3.366 1.394z' />
        </g>
      </svg>
    );
  }
}

export default CardsShoppingCart;
