import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';

// Atoms
import ImageGallery from '../atoms/ImageGallery';
import EspSlider from '../atoms/EspSlider';

// Controller
import ProductImageController from '../controllers/ProductImageController';

class ProductImages extends PureComponent {
  static propTypes = {
    activeImage: PropTypes.number.isRequired,
    images: ImmutablePropTypes.list.isRequired,
    isLightbox: PropTypes.bool,
    loadProduct: PropTypes.func,
    productID: PropTypes.number.isRequired,
    setActiveImage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLightbox: true,
    loadProduct: noop,
  };

  handleImageError = () => {
    const { loadProduct, productID } = this.props;

    loadProduct(productID);
  };

  render() {
    const {
      images,
      activeImage,
      setActiveImage,
      productID,
      isLightbox,
    } = this.props;

    return (
      <EspSlider>
        {images.map((image) => {
          const imageEl = (
            <img
              alt=''
              src={image.get('image_custom') || image.get('image_480')}
            />
          );

          if (isLightbox) {
            return (
              <Modal
                basic
                className='scrolling'
                closeOnDocumentClick
                key={image.get('id')}
                trigger={imageEl}
              >
                <Modal.Content>
                  <ImageGallery
                    activeImage={activeImage}
                    images={images}
                    onError={this.handleImageError}
                    productID={productID}
                    setActiveImage={setActiveImage}
                  />
                </Modal.Content>
              </Modal>
            );
          } else {
            return React.cloneElement(imageEl, {
              key: image.get('id'),
            });
          }
        })}
      </EspSlider>
    );
  }
}

export const ProductImagesTest = ProductImages;

export default ProductImageController(ProductImages);
