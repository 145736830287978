import Marks from '../../../Marks';
import Nodes from '../../../Nodes';
import ObjectTypes from '../../../ObjectTypes';
import Regexes from '../../../Regexes';
import isMarkdownList from '../../../../isMarkdownList';

const nodeRules = {
  deserialize(el) {
    const isImage = (url) => {
      const imgExt = Regexes[Nodes.image];
      return imgExt.test(url);
    };

    const isEmbedVideo = (url) => {
      const videoRegex = Regexes[Nodes.embedVideo];
      return videoRegex.test(url);
    };

    const isNativeVideo = (url) => {
      const videoRegex = Regexes[Nodes.nativeVideo];
      return videoRegex.test(url);
    };

    const emailNode = (content, email) => ({
      data: {
        content,
        email,
      },
      object: ObjectTypes.inline,
      type: Nodes.email,
    });

    const imageNode = (url) => ({
      data: {
        alt: url,
        src: url,
      },
      object: ObjectTypes.block,
      type: Nodes.image,
    });

    const embedVideoNode = (url) => ({
      data: {
        src: url,
      },
      object: ObjectTypes.block,
      type: Nodes.embedVideo,
    });

    const nativeVideoNode = (url) => ({
      data: {
        src: url,
      },
      object: ObjectTypes.block,
      type: Nodes.nativeVideo,
    });

    const fileNode = (content, fileName, src) => ({
      data: {
        content,
        fileName,
        src,
      },
      object: ObjectTypes.block,
      type: Nodes.file,
    });

    const textNode = (text) => ({
      leaves: [
        {
          text,
        },
      ],
      object: ObjectTypes.text,
    });

    const linkNode = (content, href, openInNewWindow) => ({
      data: {
        content,
        href,
        openInNewWindow,
      },
      object: ObjectTypes.inline,
      type: Nodes.link,
    });

    const tagNode = (tag) => ({
      data: {
        tag,
      },
      object: ObjectTypes.inline,
      type: Nodes.tag,
    });

    const boldNode = (text) => ({
      leaves: [
        {
          marks: [
            {
              data: {
                content: text,
              },
              type: Marks.bold,
            },
          ],
          object: ObjectTypes.leaf,
          text: text,
        },
      ],
      object: ObjectTypes.text,
    });

    const paragraphNode = (nodes) => ({
      nodes,
      object: ObjectTypes.block,
      type: Nodes.paragraph,
    });

    // Image
    if (el && el?.url) {
      if (isImage(el?.url)) {
        return paragraphNode([
          imageNode(el?.url),
          paragraphNode([textNode(' ')]),
        ]);
      }

      if (isNativeVideo(el?.url)) {
        return paragraphNode([
          paragraphNode([textNode(' ')]),
          nativeVideoNode(el?.url),
          paragraphNode([textNode(' ')]),
        ]);
      }

      const fileNameIndex = 0;
      const fileName = el?.url.split('/').pop().split('?')[fileNameIndex];
      return paragraphNode([
        fileNode(el?.phrase || fileName, fileName, el?.url),
        paragraphNode([textNode(' ')]),
      ]);
    }

    if (el && el?.phrase) {
      if (isMarkdownList(el?.phrase)) {
        return paragraphNode([
          paragraphNode([textNode(`${el?.phrase} `)]),
          paragraphNode([textNode(' ')]),
        ]);
      } else if (isEmbedVideo(el?.phrase)) {
        return paragraphNode([
          paragraphNode([textNode(' ')]),
          embedVideoNode(el?.phrase),
          paragraphNode([textNode(' ')]),
        ]);
      }

      const lines = el?.phrase.split(/\n/);

      const blockNodes = [];

      lines.forEach((line) => {
        const inlineNodes = [];

        const splitBoldWords = line.split('**');
        // Bold
        if (splitBoldWords.length > 1) {
          const boldedWords = line
            .match(/\*\*([^*]*)\*\*/g)
            .map((word) => word.replace(/\*\*/g, ''));

          splitBoldWords.forEach((word) => {
            if (boldedWords.includes(word)) {
              inlineNodes.push(boldNode(word));
            } else {
              inlineNodes.push(textNode(word));
            }
          });

          return blockNodes.push(paragraphNode(inlineNodes));
        }

        const textArray = line.split(/\s+(?![^[]*\])/);

        textArray.forEach((text) => {
          if (text.includes('mailto:')) {
            text = text.replace('mailto:', '');
          }

          // Email
          if (Regexes[Nodes.email].test(text)) {
            const before = Regexes[Nodes.email].exec(text);
            const content = before[1].slice(1, -1);
            const email = before[2].slice(1, -1);
            inlineNodes.push(emailNode(content, email));
            inlineNodes.push(textNode(' '));
          }

          // Link
          else if (Regexes[Nodes.link].test(text)) {
            const before = Regexes[Nodes.link].exec(text);
            const content = before[2].slice(1, -1);
            const href = before[3].slice(1, -1);
            const openInNewWindow = Regexes.linkWithTarget.test(text);
            inlineNodes.push(linkNode(content, href, openInNewWindow));
            inlineNodes.push(textNode(' '));
          }

          // Tag
          else if (Regexes[Nodes.tag].test(text)) {
            inlineNodes.push(tagNode(text));
            inlineNodes.push(textNode(' '));
          }

          // Text
          else {
            inlineNodes.push(textNode(`${text} `));
          }
        });

        if (inlineNodes.length > 0) {
          blockNodes.push(paragraphNode(inlineNodes));
        }

        return null;
      });

      return paragraphNode(blockNodes);
    }

    return paragraphNode([paragraphNode([textNode(' ')])]);
  },
};

export default nodeRules;
