import React from 'react';
import { VisuallyHidden } from 'reakit';
import pt from 'prop-types';

import TenantImage from '../../molecules/TenantImage';
import TenantImageTypes from '../../../utils/TenantImageTypes';
// Controller
import KBTenantHeaderController from './KBTenantHeaderController';
const propTypes = {
  tenantHeaderColor: pt.string,

  tenantName: pt.string,
};

const logoStyles = {
  maxHeight: '100%',
  width: 'auto',
};

const KbTenantHeader = ({ tenantName, tenantHeaderColor }) => {
  // This styles depend on tenant data (from redux), that's why i'm putting them inside the component
  const headerStyles = {
    background: tenantHeaderColor,
    borderBottom: '1px solid rgba(0,0,0,.08)',
    height: '4rem',
    padding: '0.5rem 1rem',
  };

  return (
    <header style={headerStyles}>
      <VisuallyHidden>
        <h1>{tenantName}</h1>
      </VisuallyHidden>
      <TenantImage style={logoStyles} type={TenantImageTypes.LOGO} />
    </header>
  );
};

KbTenantHeader.propTypes = propTypes;

export default KBTenantHeaderController(KbTenantHeader);
