import React from 'react';
import PropTypes from 'prop-types';
import variables from 'esp-style-vars';

const theme = variables();

const stylesFactory = ({ hidden, open }) => ({
  backgroundColor: theme.colors.lightGray,
  boxShadow: '0 1em 2em -1em rgba(0,0,0,.75)',
  display: hidden ? 'none' : 'block',
  opacity: open ? 1 : 0,
  paddingBottom: theme.globals.padding,
  paddingLeft: theme.globals.padding,
  paddingRight: theme.globals.padding,
  position: 'absolute',
  transform: open ? 'translateY(0)scaleY(1)' : 'translateY(-1em)scaleY(.5)',
  transformOrigin: 'top',
  transitionDuration: `${theme.globals.transitionDuration / 2}ms`,
  transitionProperty: 'all',
  transitionTimingFunction: theme.globals.transitionTimingFunction,
  width: '100%',
  zIndex: theme.globals.zIndexDropdown,
});

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const defaultProps = {
  children: null,
};
const ListFilterMenu = ({ children, ...rest }) => {
  return (
    <div data-component='ListFilterMenu' style={stylesFactory(rest)}>
      {children}
    </div>
  );
};
ListFilterMenu.propTypes = propTypes;
ListFilterMenu.defaultProps = defaultProps;

export default ListFilterMenu;
