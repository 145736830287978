// Actions
import announcementActions from '../../actions/announcementActions';
import announcementThunks from '../../actions/announcementThunks';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  return {
    emailStatus: state.getIn(['announcement', 'testAnnouncement', 'status']),
    invalidEmails: state.getIn([
      'announcement',
      'testAnnouncement',
      'invalidEmails',
    ]),
    isLoading: state.getIn(['announcement', 'testAnnouncement', 'isLoading']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetErrors() {
    dispatch(announcementActions.testAnnouncementsCleanErrors());
  },
  testAnnouncement(emails, id) {
    dispatch(
      announcementThunks.testAnnouncement({
        emails,
        id,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
