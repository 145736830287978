import React from 'react';
import { Button, Form, Header, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LABELS from './utils/labels';

const propTypes = {
  added: PropTypes.number,
  archived: PropTypes.number,
  deleted: PropTypes.number,
  found: PropTypes.number,
  isSyncLoading: PropTypes.bool,
  lastSyncDate: PropTypes.string,
  setSync: PropTypes.func,
  shouldDisplayAdded: PropTypes.bool,
  shouldDisplayArchived: PropTypes.bool,
  shouldDisplayDeleted: PropTypes.bool,
  shouldDisplayEmptySyncMessage: PropTypes.bool,
  shouldDisplayFound: PropTypes.bool,
  shouldDisplayUpdated: PropTypes.bool,
  updated: PropTypes.number,
};

const LastSync = ({
  lastSyncDate,
  found = 0,
  added = 0,
  updated = 0,
  archived = 0,
  deleted = 0,
  shouldDisplayEmptySyncMessage = false,
  isSyncLoading = false,
  setSync,
  shouldDisplayFound = false,
  shouldDisplayAdded = false,
  shouldDisplayUpdated = false,
  shouldDisplayArchived = false,
  shouldDisplayDeleted = false,
}) => {
  return (
    <>
      <Header as='h4' content={LABELS.SYNC_RESULTS} />

      <Form as='div'>
        <Form.Group>
          {lastSyncDate && (
            <Form.Field>
              <label aria-label={LABELS.LAST_SYNC}>{LABELS.LAST_SYNC}</label>
              <p>{lastSyncDate}</p>
            </Form.Field>
          )}
          {lastSyncDate && (
            <>
              {shouldDisplayFound && (
                <Form.Field>
                  <label aria-label={LABELS.FOUND}>{LABELS.FOUND}</label>
                  <p>{found}</p>
                </Form.Field>
              )}
              {shouldDisplayAdded && (
                <Form.Field>
                  <label aria-label={LABELS.FOUND}>{LABELS.ADDED}</label>
                  <p>{added}</p>
                </Form.Field>
              )}
              {shouldDisplayUpdated && (
                <Form.Field>
                  <label aria-label={LABELS.FOUND}>{LABELS.UPDATED}</label>
                  <p>{updated}</p>
                </Form.Field>
              )}
              {shouldDisplayArchived && (
                <Form.Field>
                  <label aria-label={LABELS.FOUND}>{LABELS.ARCHIVED}</label>
                  <p>{archived}</p>
                </Form.Field>
              )}
              {shouldDisplayDeleted && (
                <Form.Field>
                  <label aria-label={LABELS.FOUND}>{LABELS.DELETED}</label>
                  <p>{deleted}</p>
                </Form.Field>
              )}
            </>
          )}
          {shouldDisplayEmptySyncMessage && (
            <Message color='red'>{LABELS.EMPTY_SYNC}</Message>
          )}
          <Form.Field>
            <Button
              basic
              content={LABELS.SYNC_NOW}
              disabled={isSyncLoading}
              loading={isSyncLoading}
              onClick={setSync}
              type='button'
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );
};

LastSync.propTypes = propTypes;

export default LastSync;
