import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class OnboardActivitiesEmployeePortrait extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 48 48'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8,0 L40,0 C44.418278,-8.11624501e-16 48,3.581722 48,8 L48,40 C48,44.418278 44.418278,48 40,48 L8,48 C3.581722,48 5.41083001e-16,44.418278 0,40 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z M8,2.5 C4.96243388,2.5 2.5,4.96243388 2.5,8 L2.5,40 C2.5,43.0375661 4.96243388,45.5 8,45.5 L40,45.5 C43.0375661,45.5 45.5,43.0375661 45.5,40 L45.5,8 C45.5,4.96243388 43.0375661,2.5 40,2.5 L8,2.5 Z M36.8528643,36.7203315 L36.8516676,36.8495728 C36.6596006,36.992576 36.4555668,37.1200223 36.2587131,37.2564438 C33.0504161,40.142834 28.7806647,41.922296 24.0831004,41.922296 C19.2329596,41.922296 14.8339668,40.0321412 11.5939578,36.9782158 C11.5778026,36.9668474 11.5604508,36.957274 11.5448939,36.9453072 L11.5448939,36.9351354 C8.181627,33.7417969 6.08325,29.2865602 6.08325,24.3574385 C6.08325,14.6715169 14.1584413,6.79198265 24.0831004,6.79198265 C34.0083578,6.79198265 42.0829508,14.6715169 42.0829508,24.3574385 C42.0829508,29.175269 40.0827015,33.5443448 36.8528643,36.7203315 Z M9.63858395,24.3574385 C9.63858395,26.8896117 10.345223,29.2602333 11.5616474,31.3125381 L11.5616474,30.6262426 C11.7034539,27.7811377 14.1033941,25.582838 17.0591918,25.582838 L21.7639361,25.582838 L21.7639361,25.2435795 C19.6482072,24.2988012 18.1709068,22.2315379 18.1709068,19.8190326 C18.1709068,16.5161979 20.9208756,13.8392265 24.3134612,13.8392265 C27.7060467,13.8392265 30.4566139,16.5161979 30.4566139,19.8190326 C30.4566139,22.342829 28.8434903,24.4902699 26.5715948,25.3668374 L26.5715948,25.6606222 L31.3738685,25.6606222 C34.1962365,25.6606222 36.5165975,27.7703676 36.8456842,30.4329789 L36.887568,30.6747081 L36.8953464,30.8015561 C37.932269,28.8701158 38.5276169,26.6813895 38.5276169,24.3574385 C38.5276169,16.6316775 32.0481973,10.3473166 24.0831004,10.3473166 C16.1180036,10.3473166 9.63858395,16.6316775 9.63858395,24.3574385 Z'
          fill={color}
          fillRule='evenodd'
        />
      </svg>
    );
  }
}

export default OnboardActivitiesEmployeePortrait;
