import { useEffect, useState } from 'react';

const SNOW_QUERIES_KEY = 'snowQueries';

const useSnowQueries = (settings) => {
  const { getUserSetting, updateUsersSettings, isLoadingUserSettings } =
    settings;

  const [value, setValue] = useState(() => {
    const storedValue = getUserSetting(SNOW_QUERIES_KEY);
    return storedValue ? new Set(storedValue) : new Set();
  });

  useEffect(() => {
    if (getUserSetting) {
      const storedValue = getUserSetting(SNOW_QUERIES_KEY);
      if (storedValue) {
        setValue(new Set(storedValue));
      } else {
        setValue(new Set());
      }
    }
  }, [getUserSetting, isLoadingUserSettings]);

  const setSnowQueries = (newValue) => {
    setValue(newValue);
    updateUsersSettings(Array.from(newValue), SNOW_QUERIES_KEY);
  };

  return [value, setSnowQueries, isLoadingUserSettings];
};

export default useSnowQueries;
