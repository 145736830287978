// Util
import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import onboardActivityThunks from '../../actions/onboardActivityThunks';
import onboardActivityActions from '../../actions/onboardActivityActions';

// Limit of schedule item per page
const limitByPage = 24;

const mapStateToProps = (state) => {
  const schedule = state.getIn(['onboardActivity', 'schedule']);
  return {
    isLoadingScheduleList: schedule.get('isLoading'),
    isSearching: schedule.get('isSearching'),
    limitByPage,
    listCount: schedule.get('count'),
    loadingDeleteID: schedule.get('loadingDeleteID'),
    scheduleList: schedule.get('list'),
    searchTerm: schedule.get('searchTerm'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteOneScheduleActivity(scheduleID, msg) {
    dispatch(onboardActivityThunks.deleteOneScheduleActivity(scheduleID, msg));
  },

  loadScheduleActivityList(
    page = 1,
    limit = limitByPage,
    orderBy = '-sys_date_updated'
  ) {
    // Reset Template Interval
    dispatch(onboardActivityActions.resetTemplateInterval());

    // Offset logic
    const offset = (page - 1) * limit;
    dispatch(
      onboardActivityThunks.loadScheduleActivityList({
        limit: limit,
        offset: offset,
        orderBy: orderBy,
      })
    );
  },

  searchSchedule(page = 1, limit = limitByPage, orderBy = '-sys_date_updated') {
    // Reset Template Interval
    dispatch(onboardActivityActions.resetTemplateInterval());

    // Offset logic
    const offset = (page - 1) * limit;

    dispatch(
      onboardActivityThunks.searchTerm({
        limit: limit,
        offset: offset,
        orderBy: orderBy,
      })
    );
  },

  setSeachTerm(term) {
    dispatch(onboardActivityActions.setSearchScheduleTerm(term));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
