import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwareProjector extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 64 33.78'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          className='cls-1'
          d='M59 5.93h-4.82a14.18 14.18 0 0 0-23.06 0H5a5 5 0 0 0-5 5v17.85a5 5 0 0 0 5 5h54a5 5 0 0 0 5-5V10.93a5 5 0 0 0-5-5zM6.31 14.7A2.66 2.66 0 1 1 9 12.04a2.66 2.66 0 0 1-2.69 2.66zm36.35 8.72a9.21 9.21 0 1 1 9.21-9.21 9.22 9.22 0 0 1-9.22 9.21z'
          fill={color}
        />
      </svg>
    );
  }
}

export default CatalogHardwareProjector;
