import React from 'react';
import ContentSync from '../../ContentSync';
import { Helmet } from 'react-helmet';
import useScheduleSync from '../../ContentSync/hooks/useScheduleSync';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import LastSync from '../../ContentSync/LastSync';
import useLastSync from '../../ContentSync/hooks/useLastSync';
const propTypes = {};

const IntegrationServicenowContentSync = () => {
  const scheduleSyncURL = endpointGenerator.genPath(
    'espIntegration.servicenowKB.set'
  );
  const lastSyncURL = endpointGenerator.genPath(
    'espIntegration.confluence.lastSync'
  );
  const runSyncURL = endpointGenerator.genPath(
    'espIntegration.confluence.runSync'
  );

  const { lastSync, isEmptySyncMessage, loading, setSync } = useLastSync({
    lastSyncURL,
    runSyncURL,
  });
  const { loadingSchedule, scheduleSync, setSchedule } = useScheduleSync({
    scheduleSyncURL,
  });

  const isLoading = loading || loadingSchedule;

  return (
    <>
      <Helmet title={'Service Now - Integrations - Content Sync'} />
      <ContentSync
        LastSyncComponent={
          <LastSync
            {...lastSync}
            isSyncLoading={isLoading}
            lastSyncDate={lastSync?.date}
            setSync={setSync}
            shouldDisplayEmptySyncMessage={isEmptySyncMessage}
            shouldDisplayFound
          />
        }
        isAlwaysEnabled
        isLoading={isLoading}
        selectedDays={scheduleSync?.selectedDays}
        timeOfDay={scheduleSync?.timeOfDay}
        updateSchedule={setSchedule}
      />
    </>
  );
};

IntegrationServicenowContentSync.propTypes = propTypes;

export default IntegrationServicenowContentSync;
