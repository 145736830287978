import React from 'react';
import EspDateUtil from 'esp-date-util';
import { every, isEmpty, map } from 'lodash';
import Immutable from 'immutable';
import pt from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Divider, Form } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import SectionHeader from '../../form/SectionHeader';
import {
  getLocalizedMonths,
  getLocalizedWeekdays,
  getOrdinalNumbersOptions,
} from '../../../../v1/components/organisms/AnnouncementRecurring';

const getDateTime = (startDate, endDate) =>
  startDate && endDate
    ? new EspDateUtil(
        `${startDate} ${endDate}`,
        'YYYY-MM-DD HH:mm'
      ).getStandardDateFormatWithHours()
    : '';

const propTypes = {
  recurrence_schedule: pt.shape({
    daily: pt.shape({
      every: pt.number,
    }),
    monthly: pt.shape({
      every: pt.number,
      on_date: pt.number,
      on_nth: pt.number,
      on_nth_type: pt.string,
    }),
    weekly: pt.shape({
      every: pt.number,
      on: pt.arrayOf(pt.number),
    }),
    yearly: pt.shape({
      on_date: pt.number,
      on_month: pt.number,
      on_nth: pt.number,
      on_nth_type: pt.string,
    }),
  }),
  schedule: ImmutablePropTypes.contains({
    auto_expire_days: pt.number,
    end_date: pt.string,
    end_time: pt.string,
    recurrence: pt.oneOfType([pt.array, pt.object]),
    start_date: pt.string,
    start_time: pt.string,
  }),
  userLanguage: pt.string,
};

const defaultProps = {
  schedule: Immutable.Map({
    auto_expire_days: 0,
    end_date: '',
    end_time: '',
    recurrence: [],
    start_date: '',
    start_time: '',
  }),
  userLanguage: '',
};

// Normalizes the isEmpty function to work with both Immutable and non Immutable objects
const isFieldEmpty = (object) =>
  object && object.isEmpty ? object.isEmpty() : isEmpty(object);

const AnnouncementSchedulingReadOnly = ({ schedule, userLanguage }) => {
  const isFieldPresent = (fieldName) => !isFieldEmpty(schedule.get(fieldName));
  const isEndDateTimePresent = every(
    map(['end_date', 'end_time'], isFieldPresent)
  );
  const isAutoExpireDaysSet = schedule.get('auto_expire_days')
    ? schedule.get('auto_expire_days') > 0
    : false;

  // const isRecurrencePresent = isFieldPresent('recurrence');
  const isStartDateTimePresent = every(
    map(['start_date', 'start_time'], isFieldPresent)
  );

  const parsedEndDateTime =
    isEndDateTimePresent &&
    getDateTime(schedule.get('end_date'), schedule.get('end_time'));
  const parsedStartDateTime =
    isStartDateTimePresent &&
    getDateTime(schedule.get('start_date'), schedule.get('start_time'));

  const renderEndDateContent = () => {
    return (
      <>
        {isAutoExpireDaysSet && (
          <Form.Field>
            <label>
              {`${intl.formatMessage({
                id: 'label.occurrences',
              })}`}
            </label>
            <p>{schedule.get('auto_expire_days')}</p>
          </Form.Field>
        )}

        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.end_date' })}</label>
          <p>{parsedEndDateTime}</p>
        </Form.Field>
      </>
    );
  };

  const renderDailyContent = () => {
    if (!schedule.getIn(['recurrence_schedule', 'daily', 'every'])) {
      return null;
    }

    return (
      <>
        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.every' })}</label>
          <p>
            {intl.formatMessage({
              id: 'label.day',
              values: {
                days: schedule.getIn(['recurrence_schedule', 'daily', 'every']),
              },
            })}
          </p>
        </Form.Field>

        {renderEndDateContent()}
      </>
    );
  };

  const renderSelectedWeekDays = () => {
    const weekDayOptions = getLocalizedWeekdays(userLanguage, 'ddd');

    const selectedWeekDays = schedule.getIn([
      'recurrence_schedule',
      'weekly',
      'on',
    ]);

    const selectedLabelWeekDays = selectedWeekDays
      .map((weekDay) => {
        return weekDayOptions.find((weekOption) => weekOption.value === weekDay)
          .text;
      })
      .toArray();

    return (
      <Form.Field>
        <label>{intl.formatMessage({ id: 'label.on' })}</label>
        <p>{selectedLabelWeekDays.join(', ')}</p>
      </Form.Field>
    );
  };

  const renderWeeklyContent = () => {
    if (!schedule.getIn(['recurrence_schedule', 'weekly', 'every'])) {
      return null;
    }

    return (
      <>
        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.every' })}</label>
          <p>
            {intl.formatMessage({
              id: 'label.week',
              values: {
                weeks: schedule.getIn([
                  'recurrence_schedule',
                  'weekly',
                  'every',
                ]),
              },
            })}
          </p>
        </Form.Field>

        {renderSelectedWeekDays()}

        {renderEndDateContent()}
      </>
    );
  };

  const renderOnContent = (type) => {
    const ordinalNumbersOptions = getOrdinalNumbersOptions(userLanguage);
    const weekDayOptions = getLocalizedWeekdays(userLanguage, 'ddd');

    return (
      <>
        {schedule.getIn(['recurrence_schedule', type, 'on_date']) > 0 && (
          <Form.Field>
            <label>{intl.formatMessage({ id: 'label.on' })}</label>
            <p>
              {intl
                .formatMessage({
                  id: 'label.day',
                  values: {
                    days: 1,
                  },
                })
                .replace('1', '')}{' '}
              {schedule.getIn(['recurrence_schedule', type, 'on_date'])}
            </p>
          </Form.Field>
        )}
        {schedule.getIn(['recurrence_schedule', type, 'on_nth']) > 0 && (
          <Form.Field>
            <label>{intl.formatMessage({ id: 'label.on' })}</label>
            <p>
              <span
                style={{
                  paddingRight: '5px',
                }}
              >
                {
                  ordinalNumbersOptions.find(
                    (ordinalNumberOption) =>
                      ordinalNumberOption.value ===
                      schedule.getIn(['recurrence_schedule', type, 'on_nth'])
                  ).text
                }
              </span>
              <span>
                {
                  weekDayOptions.find(
                    (weekDayOption) =>
                      weekDayOption.value ===
                      schedule.getIn([
                        'recurrence_schedule',
                        type,
                        'on_nth_type',
                      ])
                  ).text
                }
              </span>
            </p>
          </Form.Field>
        )}
      </>
    );
  };

  const renderMonthlyContent = () => {
    if (!schedule.getIn(['recurrence_schedule', 'monthly', 'every'])) {
      return null;
    }

    return (
      <>
        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.every' })}</label>
          <p>
            {intl.formatMessage({
              id: 'label.month',
              values: {
                months: schedule.getIn([
                  'recurrence_schedule',
                  'monthly',
                  'every',
                ]),
              },
            })}
          </p>
        </Form.Field>

        {renderOnContent('monthly')}
        {renderEndDateContent()}
      </>
    );
  };

  const renderYearlyEveryContent = () => {
    const monthOptions = getLocalizedMonths(userLanguage);

    const selectedMonth = monthOptions.find(
      (monthOption) =>
        monthOption.value ===
        schedule.getIn(['recurrence_schedule', 'yearly', 'on_month'])
    ).text;

    return (
      <Form.Field>
        <label>{intl.formatMessage({ id: 'label.every' })}</label>
        <p>{selectedMonth}</p>
      </Form.Field>
    );
  };

  const renderYearlyContent = () => {
    if (!schedule.getIn(['recurrence_schedule', 'yearly', 'on_month'])) {
      return null;
    }

    return (
      <>
        {renderYearlyEveryContent()}
        {renderOnContent('yearly')}
        {renderEndDateContent()}
      </>
    );
  };

  return (
    <div>
      <SectionHeader content={intl.formatMessage({ id: 'label.scheduling' })} />
      <Form.Field>
        <label>{intl.formatMessage({ id: 'label.scheduled' })}</label>
        <p>{intl.formatMessage({ id: 'message.yes' })}</p>
      </Form.Field>

      <Form.Field>
        <label>{intl.formatMessage({ id: 'label.start_date' })}</label>
        <p>{parsedStartDateTime}</p>
      </Form.Field>

      {renderDailyContent()}
      {renderWeeklyContent()}
      {renderMonthlyContent()}
      {renderYearlyContent()}

      {!isEndDateTimePresent && (
        <Form.Field>
          <label>{intl.formatMessage({ id: 'label.end_date' })}</label>
          <p>{schedule.get('start_date')}</p>
        </Form.Field>
      )}

      <Divider hidden />
    </div>
  );
};

AnnouncementSchedulingReadOnly.propTypes = propTypes;
AnnouncementSchedulingReadOnly.defaultProps = defaultProps;

export default AnnouncementSchedulingReadOnly;
