import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';

const ComposedField = (Field) => {
  class NewField extends PureComponent {
    static propTypes = {
      setFieldTouched: PropTypes.func.isRequired,

      setFieldValue: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    };

    onHandleBlur = (e, data) => {
      const { setFieldValue, setFieldTouched } = this.props;
      if (data && data.name) {
        setFieldValue(data.name, data.value);

        setFieldTouched(data.name);
      }
    };

    onHandleChange = (e, data) => {
      if (data && data.name) {
        const { setFieldValue } = this.props;
        setFieldValue(data.name, data.value);
      }
    };

    render() {
      const { value } = this.props;
      const newValue = value && isString(value) ? value.trim() : value;

      return (
        <Field
          handleBlur={this.onHandleBlur}
          handleChange={this.onHandleChange}
          value={newValue}
          {...this.props}
        />
      );
    }
  }

  return NewField;
};

export default ComposedField;
