import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Dimmer, Icon, List, Loader, Message } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';
import { Waypoint } from 'react-waypoint';
import Immmutable from 'immutable';
import ScrollArea from '../../../../v1/components/atoms/ScrollArea';
// Controller
import LeafLocationSelectListController from './LeafLocationSelectListController';
// V2
// Molecules
import EspListItem from '../../../../v1/components/molecules/EspListItem';

class LeafLocationSelectList extends PureComponent {
  static propTypes = {
    getNextDirectoryUsers: PropTypes.func,
    getNextLeafNodes: PropTypes.func,
    isLoading: PropTypes.bool,
    leafLocations: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    loadNextMessage: PropTypes.string,
    locationType: PropTypes.string, // Can be restricted, for instance only for "building" leaf nodes
    pagination: PropTypes.string,

    savedSelectedLocationId: PropTypes.number,

    setTmpLocationSelection: PropTypes.func,

    tmpSelectLocationedId: PropTypes.number,
  };

  static defaultProps = {
    getNextDirectoryUsers: noop,
    getNextLeafNodes: noop,
    isLoading: false,
    leafLocations: Immmutable.List(),
    loadNextMessage: 'Loading more...',
    locationType: '',
    pagination: null,
    savedSelectedLocationId: null,
    setTmpLocationSelection: noop,
    tmpSelectLocationedId: null,
  };

  componentDidMount() {
    const { locationType, getNextLeafNodes } = this.props;

    getNextLeafNodes({
      initial: true,
      locationType: locationType,
    });
  }

  handleBottomWaypointEnter = () => {
    const { locationType, getNextLeafNodes } = this.props;

    getNextLeafNodes({
      initial: false,
      locationType: locationType,
    });
  };

  handleClickLocation = memoize(
    curry((locationId, locationName, e) => {
      const { setTmpLocationSelection } = this.props;
      setTmpLocationSelection(locationId, locationName);
    })
  );

  render() {
    const {
      isLoading,
      loadNextMessage,
      pagination,
      leafLocations,
      tmpSelectLocationedId,
      savedSelectedLocationId,
    } = this.props;

    const isSavedAndSelected = Boolean(savedSelectedLocationId);

    const locationsListItems = leafLocations
      ? leafLocations.map((location) => {
          const content = `${location.getIn([
            'ancestry',
            'city',
          ])}, ${location.getIn(['ancestry', 'state'])}`;
          const espProps = {
            as: 'a',
            content,
            disabled: isSavedAndSelected,
            id: location.get('id'),
            isGreyedOut: isSavedAndSelected,
            isSelected:
              tmpSelectLocationedId === location.get('id') ||
              savedSelectedLocationId === location.get('id'),
            name: location.get('name'),
            onClick: this.handleClickLocation(location.get('id'), content),
          };
          return <EspListItem {...espProps} key={location.get('id')} />;
        })
      : null;

    const setWayPoint =
      pagination && !leafLocations.isEmpty() ? (
        <Waypoint key={pagination} onEnter={this.handleBottomWaypointEnter} />
      ) : null;

    const paginationMessage =
      isLoading && !leafLocations.isEmpty() ? (
        <Message icon>
          <Icon loading name='circle notched' />
          <Message.Content>{loadNextMessage}</Message.Content>
        </Message>
      ) : null;

    const userLoader =
      isLoading && leafLocations.isEmpty() ? (
        <Dimmer active inverted>
          <Loader size='large' />
        </Dimmer>
      ) : null;

    return (
      <ScrollArea height='100%' padding='1rem 0'>
        <List divided>
          {userLoader}

          {locationsListItems}
        </List>

        {setWayPoint}

        {paginationMessage}
      </ScrollArea>
    );
  }
}

export default LeafLocationSelectListController(LeafLocationSelectList);
