import React, { PureComponent } from 'react';
import { Field } from 'formik';
import { TextArea as SUIRTextArea } from 'semantic-ui-react';
import ComposedField from '../utils/ComposedField';
import PropTypes from 'prop-types';

class TextArea extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: null,
  };

  render() {
    const { name, placeholder, ...props } = this.props;

    const style = {
      resize: 'none',
    };

    return (
      <Field
        {...props}
        component={SUIRTextArea}
        name={name}
        placeholder={placeholder}
        style={style}
      />
    );
  }
}

export default ComposedField(TextArea);
