import {
  formatDateMessage,
  formatTimeMessage,
  formatTimeResolution,
} from '../../../utils/pickerHelpers';
import { lighten, stripUnit } from 'polished';
import React, { PureComponent } from 'react';

import bubbleRadiusHelper from './bubbleRadiusHelper';
import ChannelTypes from '../../../../v1/globals/ChannelTypes';
import ChatDate from './ChatDate';
import ChatInputTypes from '../../../../v2/components/display/ChatMessage/ChatInputTypes';
import ChatMessageExpandBtn from './ChatMessageExpandBtn';
import ChatMessageExpandWrapper from './ChatMessageExpandWrapper';
import ChatMessageMetadataViewer from './ChatMessageMetadataViewer';
import ChatMessageTyping from './ChatMessageTyping';
import ChatTime from './ChatTime';
import { createComponent } from 'react-fela';
import { debounce } from 'lodash';
import EspMetaVariables from '../../../../v1/components/molecules/EspMetaVariables';
import { getMetadataContentType } from 'esp-globals';
import ImmutablePropTypes from 'react-immutable-proptypes';
// import { makeDebugger } from '../../../utils/debug';
import PropTypes from 'prop-types';
import { Transition } from 'semantic-ui-react';
import ChatMessageController from './ChatMessageController';

// const debug = makeDebugger('ChatMessageBubble');

const config = {
  textCollapseHeight: 200,
};

const rule = (props, state) => {
  const {
    download,
    isFirstMessage,
    hideAvatar,
    isMe,
    isLastMessage,
    link,
    theme,
    typing,
  } = props;

  const { expanded } = state;

  const meColor = theme.brandTenant.primaryColor;
  const meColorText = theme.brandTenant.textColorPrimary;
  const themeColor = theme.colors.gray;
  const themeColorText = theme.colors.text;

  const pulseInit = {
    backgroundColor: themeColor,
  };

  const pulse = () => ({
    '20%': {
      backgroundColor: lighten(0.04, themeColor),
    },

    '40%': pulseInit,

    from: pulseInit,

    to: pulseInit,
  });

  const pulseMoveInit = {
    borderRightColor: themeColor,
    transform: 'translate3D(0,-50%,0)',
  };

  const pulseMove = {
    '20%': {
      borderRightColor: lighten(0.04, themeColor),
      transform: 'translate3D(50%,-50%,0)',
    },

    '40%': pulseMoveInit,

    from: pulseMoveInit,

    to: pulseMoveInit,
  };

  const hoverActiveStyle =
    link || download
      ? {
          backgroundColor: isMe
            ? lighten(0.06, meColor)
            : lighten(0.06, themeColor),
          boxShadow: '0 6px 12px -8px rgba(0,0,0,.75)',
          color: isMe ? meColorText : themeColorText,
        }
      : {};

  const listStyle = {
    '& li': {
      lineHeight: '1.5em',
    },
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  };

  return {
    '& a': {
      // NOTE: Keep the space after the & so we get all children links
      color: isMe ? meColorText : themeColorText,
      textDecoration: 'underline',
    },
    '& ol': listStyle,
    '& ul': listStyle,
    ':active': hoverActiveStyle,
    ':before':
      isFirstMessage && !hideAvatar
        ? {
            animationDuration: '2000ms',
            animationIterationCount: 'infinite',
            animationName: typing ? pulseMove : 'none',
            borderBottom: '.375em solid transparent',
            borderLeft: isMe ? `.5em solid ${meColor}` : 'none',
            borderRight: !isMe ? `.5em solid ${themeColor}` : 'none',
            borderTop: '.375em solid transparent',
            content: '" "',
            display: 'block',
            height: 0,
            left: isMe ? '100%' : 'auto',
            position: 'absolute',
            right: !isMe ? '100%' : 'auto',
            top: `${stripUnit(theme.minHeight) / 2}px`,
            transform: 'translateY(-50%)',
            width: 0,
          }
        : {},
    ':hover': hoverActiveStyle,
    ...bubbleRadiusHelper(isFirstMessage, isLastMessage, isMe, theme),

    '> span': {
      wordWrap: 'break-word',
    },
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationName: typing ? state.renderKeyframe(pulse) : 'none',
    backgroundColor: isMe ? meColor : themeColor,
    color: isMe ? meColorText : themeColorText,
    cursor: link || download ? 'pointer' : 'auto',
    display: 'inline-block',
    fontSize: expanded ? '2em' : '1em',
    lineHeight: 1.25,
    maxWidth: '100%',
    minHeight: theme.minHeight,
    overflow: 'hidden',
    padding: theme.bubblePadding,
    position: 'relative',
    textAlign: 'left',
    transition:
      link || download ? 'all 200ms ease-in-out' : 'height 300ms ease-in-out',
    wordWrap: 'break-word',
  };
};

class ChatMessageBubble extends PureComponent {
  static propTypes = {
    allowExpandableChatMessagesFeature: PropTypes.bool,
    channelId: PropTypes.string,
    channelType: PropTypes.oneOf([
      ChannelTypes.DIRECT_CHANNEL,
      ChannelTypes.SCOPED_CHANNEL,
      ChannelTypes.SUPPORT_CHANNEL,
    ]),
    children: PropTypes.element,
    className: PropTypes.string.isRequired,
    dateTimeFormat: PropTypes.shape({
      isDateTimeToLocalize: PropTypes.bool,
      type: PropTypes.string,
    }),
    download: PropTypes.string,
    fileName: PropTypes.string,
    isBarista: PropTypes.bool,
    isMe: PropTypes.bool,
    isScoped: PropTypes.bool,
    link: PropTypes.bool,
    onClick: PropTypes.func,
    onDownload: PropTypes.func,
    paramValues: ImmutablePropTypes.map,
    text: PropTypes.string,
    typing: PropTypes.bool,
    userId: PropTypes.number,
  };

  static defaultProps = {
    children: void 0,
    download: void 0,
    isBarista: void 0,
    isMe: false,
    isScoped: false,
    link: false,
    onClick: void 0,
    onDownload: void 0,
    paramValues: void 0,
    text: void 0,
    typing: false,
    userId: void 0,
  };

  state = {
    bubbleHeight: 'auto',
    expanded: true,
    isExpandable: false,
  };

  componentDidMount() {
    this.initHeight();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // debug('UNSAFE_componentWillUpdate()');
    const { isExpandable } = this.state;
    if (nextProps !== this.props) {
      this.handleResize();
      if (isExpandable) {
        window.addEventListener('resize', this.handleResize);
      }
    }
  }

  componentWillUnmount() {
    // debug('componentWillUnmount()');

    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = debounce(() => {
    // debug('handleResize()');
    const { expanded } = this.state;

    if (expanded) {
      this.setState(
        {
          bubbleHeight: 'auto',
        },
        () =>
          this.setState({
            bubbleHeight: this.node?.clientHeight,
          })
      );
    }
  }, 1000);

  initHeight = () => {
    // debug('initHeight()');
    const { expanded } = this.state;
    const { allowExpandableChatMessagesFeature } = this.props;

    const isExpandingPropEnabled = allowExpandableChatMessagesFeature;
    const clientHeight = this.node?.clientHeight;

    if (expanded && isExpandingPropEnabled) {
      // debug('IT IS EXPANDED');
      this.setState(
        {
          bubbleHeight: 'auto',
        },
        () =>
          this.setState(
            {
              bubbleHeight: clientHeight,
            },
            () =>
              this.setState({
                expanded: false,
              })
          )
      );

      if (clientHeight > config.textCollapseHeight) {
        this.setState({
          isExpandable: true,
        });
        window.addEventListener('resize', this.handleResize);
      } else {
        this.setState({
          isExpandable: false,
        });
        window.removeEventListener('resize', this.handleResize);
      }
    }
  };

  handleToggleExpand = () => {
    // debug('handleToggleExpand()');
    const { expanded } = this.state;

    this.setState(
      {
        expanded: !expanded,
      },
      () => {
        if (expanded) {
          this.setState(
            {
              bubbleHeight: 'auto',
            },
            () =>
              this.setState({
                bubbleHeight: this.node.clientHeight,
              })
          );
        }
      }
    );
  };

  handleBubbleRef = (node) => {
    this.node = node;
  };

  renderChildren = (text) => {
    const { children, isBarista, isScoped, paramValues, typing, userId } =
      this.props;

    return children ? (
      children
    ) : typing ? (
      <ChatMessageTyping />
    ) : (
      <EspMetaVariables
        isBarista={isBarista}
        isScoped={isScoped}
        noExternalLinks={false}
        paramValues={paramValues}
        text={text}
        userId={userId}
      />
    );
  };

  getMsgFromPicker = (text) => {
    const { dateTimeFormat } = this.props;
    if (dateTimeFormat.isDateTimeToLocalize) {
      const { type } = dateTimeFormat;
      if (type === ChatInputTypes.TIME) {
        return formatTimeMessage(text);
      }
      if (type === ChatInputTypes.DATE) {
        return formatDateMessage(text);
      }
      if (type === ChatInputTypes.SYS_ISO_TIME) {
        return formatTimeResolution(text);
      }
    }
    return text;
  };

  handleClick = (e) => {
    const { onDownload, onClick } = this.props;

    onDownload && onDownload(e);
    onClick && onClick(e);
  };

  render() {
    const {
      channelId,
      channelType,
      className,
      dateTimeFormat,
      download,
      fileName,
      isMe,
      link,
      text,
      typing,
      userId,
      allowExpandableChatMessagesFeature,
    } = this.props;

    const { bubbleHeight, expanded, isExpandable } = this.state;

    const isExpandingPropEnabled = allowExpandableChatMessagesFeature;

    const messageText = text || '';

    const metadataContentType =
      getMetadataContentType(text) || getMetadataContentType(download);

    const processRegularText = (regularText) => {
      const { isDateTimeToLocalize, type } = dateTimeFormat || {};
      const currentText = isDateTimeToLocalize
        ? this.getMsgFromPicker(regularText)
        : regularText;
      return (
        <div data-component='ChatMessageBubble' data-uid={userId}>
          {link || download ? (
            <a
              className={className}
              download={fileName || Boolean(download)}
              href={download}
              onClick={this.handleClick}
              ref={this.handleBubbleRef}
              rel='noreferrer'
              target='_blank'
            >
              {this.renderChildren(currentText)}
            </a>
          ) : (
            <span
              className={className}
              ref={this.handleBubbleRef}
              style={
                (isExpandable && isExpandingPropEnabled
                  ? {
                      height: expanded
                        ? bubbleHeight
                        : config.textCollapseHeight - 40,
                    }
                  : {},
                type === ChatInputTypes.INPUT_DATE ||
                type === ChatInputTypes.INPUT_TIME
                  ? {
                      display: 'flex',
                    }
                  : {})
              }
            >
              {isExpandable && isExpandingPropEnabled ? (
                <ChatMessageExpandWrapper expanded={expanded} isMe={isMe}>
                  {this.renderChildren(currentText)}
                </ChatMessageExpandWrapper>
              ) : type === ChatInputTypes.INPUT_DATE ||
                type === ChatInputTypes.INPUT_TIME ? (
                <div style={{ margin: '0.3em' }}>
                  {this.renderChildren(currentText)}
                </div>
              ) : (
                this.renderChildren(currentText)
              )}
              {!typing && isExpandable && isExpandingPropEnabled ? (
                <ChatMessageExpandBtn
                  expanded={expanded}
                  isMe={isMe}
                  onClick={this.handleToggleExpand}
                />
              ) : null}
              {type === ChatInputTypes.INPUT_DATE ? (
                <ChatDate channelId={channelId} channelType={channelType} />
              ) : null}

              {type === ChatInputTypes.INPUT_TIME ? (
                <ChatTime channelId={channelId} channelType={channelType} />
              ) : null}
            </span>
          )}
        </div>
      );
    };

    const processTextWithMetadata = (currentText = '') => {
      return (
        <ChatMessageMetadataViewer
          text={currentText}
          type={metadataContentType}
        />
      );
    };
    const metaDataContent = text ? text : download;
    return (
      <Transition
        animation='fade'
        duration={500}
        transitionOnMount={messageText.length < 200 ? true : void 0}
        visible={messageText.length >= 200 ? true : void 0}
      >
        {metadataContentType
          ? processTextWithMetadata(metaDataContent)
          : processRegularText(text)}
      </Transition>
    );
  }
}

const ControlledChatMessageBubble = ChatMessageController(ChatMessageBubble);

export default createComponent(rule, ControlledChatMessageBubble, [
  'channelId',
  'channelType',
  'dateTimeFormat',
  'download',
  'hideAvatar',
  'isBarista',
  'isExpandable',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'isScoped',
  'link',
  'onClick',
  'onDownload',
  'paramValues',
  'text',
  'typing',
  'userId',
]);
