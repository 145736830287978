import { createSelector } from 'reselect';
import Immutable from 'immutable';
/**
 * Return the option ValueTeam Classification for a Specific Service Department
 */

const getClassificationPerName = (state, departmentName) => {
  if (state.hasIn(['caseMgmt', 'teamClassification', departmentName])) {
    return state.getIn([
      'caseMgmt',
      'teamClassification',
      departmentName,
      'results',
    ]);
  }
  return Immutable.List();
};

const getTeamClassification = (classificationList) => {
  if (!classificationList || classificationList.isEmpty()) {
    return [];
  }

  const teamClassification = classificationList.map((item) =>
    Immutable.fromJS({
      key: item.get('id'),
      text: item.get('name'),
      value: item.get('id'),
    })
  );

  return teamClassification.isEmpty() ? [] : teamClassification.toJS();
};

const selector = createSelector(
  [getClassificationPerName],
  getTeamClassification
);

const getTeamClassificationById = createSelector(
  [
    (state, departmentName) => {
      const allTeamsClassifications = state.getIn(
        ['caseMgmt', 'teamClassification', 'all', 'results'],
        Immutable.List()
      );

      return allTeamsClassifications.filter(
        (team) => team.getIn(['service_department']) === departmentName
      );
    },
  ],
  getTeamClassification
);

export { getTeamClassificationById };
export default selector;
