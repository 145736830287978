import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import ContentSync from '../../ContentSync';
import useLastSync from '../../ContentSync/hooks/useLastSync';
import useScheduleSync from '../../ContentSync/hooks/useScheduleSync';
import LastSync from '../../ContentSync/LastSync';

const propTypes = {};

const ConfluenceContentSync = () => {
  const spaceFieldName = 'spaces';

  const scheduleSyncURL = endpointGenerator.genPath(
    'espIntegration.confluence.set'
  );
  const lastSyncURL = endpointGenerator.genPath(
    'espIntegration.confluence.lastSync'
  );
  const runSyncURL = endpointGenerator.genPath(
    'espIntegration.confluence.runSync'
  );
  const resetSyncURL = endpointGenerator.genPath(
    'espIntegration.confluence.reset'
  );
  const {
    lastSync = {},
    isEmptySyncMessage,
    loading,
    setSync,
  } = useLastSync({
    lastSyncURL,
    runSyncURL,
  });
  const { loadingSchedule, resetSchedule, scheduleSync, setSchedule } =
    useScheduleSync({
      scheduleSyncURL,
      spaceFieldName,
    });

  const SPACES_LABEL = 'Exposed Spaces';
  const SPACES_DESCRIPTION =
    'List of spaces that should be exposed to Virtual Agent chat. Use the space key and separate multiple spaces with a comma. (Ex. EN, HR, IT) All published articles within these spaces will be made available to users through Virtual Agent chat.';

  const spaceConfig = {
    description: SPACES_DESCRIPTION,
    fieldName: spaceFieldName,
    label: SPACES_LABEL,
    value: scheduleSync?.spaces,
  };

  const handleUpdate = useCallback(
    (values) => {
      setSchedule({
        ...values,
        spaces: values?.spaces ? values?.spaces.toUpperCase() : values?.spaces,
      });
    },
    [setSchedule]
  );

  const resetScheduleSync = useCallback(() => {
    resetSchedule(resetSyncURL);
  }, [resetSchedule, resetSyncURL]);

  const isLoading = loadingSchedule || loading;

  return (
    <>
      <Helmet title={'Confluence - Integrations - Content Sync'} />
      <ContentSync
        LastSyncComponent={
          <LastSync
            {...lastSync}
            isSyncLoading={isLoading}
            lastSyncDate={lastSync?.date}
            setSync={setSync}
            shouldDisplayAdded
            shouldDisplayArchived
            shouldDisplayDeleted
            shouldDisplayEmptySyncMessage={isEmptySyncMessage}
            shouldDisplayFound
            shouldDisplayUpdated
          />
        }
        isAlwaysEnabled
        isLoading={isLoading}
        resetScheduleSync={resetScheduleSync}
        selectedDays={scheduleSync?.selectedDays}
        shouldDisplaySpaces
        spaces={spaceConfig}
        timeOfDay={scheduleSync?.timeOfDay}
        updateSchedule={handleUpdate}
      />
    </>
  );
};

ConfluenceContentSync.propTypes = propTypes;

export default ConfluenceContentSync;
