import Immutable from 'immutable';
import { createSelector } from 'reselect';
import _ from 'lodash';

import getSelectMyGearStepsWithSelections from './getSelectMyGearStepsWithSelections';
import SelectMyGearStepTypes from '../globals/SelectMyGearStepTypes';

const getCurrentStepIndex = (state) =>
  state.getIn(['selectMyGear', 'currentStep']);

const getSelectMyGearSelectedProductsWithFulfillmentQuestions = (
  steps,
  currentStepIndex
) => {
  if (
    !steps ||
    steps.isEmpty() ||
    !_.isNumber(currentStepIndex) ||
    !steps.has(currentStepIndex)
  ) {
    return Immutable.List();
  }

  let selectedProductsWithFulfillmentQuestions = Immutable.List();

  const currentStep = steps.get(currentStepIndex);

  if (
    currentStep.get('type') === SelectMyGearStepTypes.PRODUCT &&
    currentStep.get('isSelected') &&
    currentStep.get('hasFulfillmentQuestions')
  ) {
    const product = currentStep.get('entity');
    selectedProductsWithFulfillmentQuestions = selectedProductsWithFulfillmentQuestions.push(
      product.get('id')
    );
  }

  if (currentStep.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
    const items = currentStep.get('items');
    const selectedItemWithFulfillmentQuestions = items.find(
      (item) => item.get('isSelected') && item.get('hasFulfillmentQuestions')
    );

    if (selectedItemWithFulfillmentQuestions) {
      const product = selectedItemWithFulfillmentQuestions.get('entity');
      selectedProductsWithFulfillmentQuestions = selectedProductsWithFulfillmentQuestions.push(
        product.get('id')
      );
    }
  }

  // look for selected products with fulfillment questions inside bundle
  if (currentStep.get('type') === SelectMyGearStepTypes.PRODUCT_BUNDLE) {
    const bundleChildren = currentStep.get('items');

    bundleChildren.forEach((bundleChild) => {
      if (
        bundleChild.get('type') === SelectMyGearStepTypes.PRODUCT &&
        bundleChild.get('isSelected') &&
        bundleChild.get('hasFulfillmentQuestions')
      ) {
        const product = bundleChild.get('entity');
        selectedProductsWithFulfillmentQuestions = selectedProductsWithFulfillmentQuestions.push(
          product.get('id')
        );
      }

      if (bundleChild.get('type') === SelectMyGearStepTypes.PRODUCT_FAMILY) {
        const items = bundleChild.get('items');
        const selectedItemWithFulfillmentQuestions = items.find(
          (item) =>
            item.get('isSelected') && item.get('hasFulfillmentQuestions')
        );

        if (selectedItemWithFulfillmentQuestions) {
          const product = selectedItemWithFulfillmentQuestions.get('entity');
          selectedProductsWithFulfillmentQuestions = selectedProductsWithFulfillmentQuestions.push(
            product.get('id')
          );
        }
      }
    });
  }

  return selectedProductsWithFulfillmentQuestions;
};

const selector = createSelector(
  [getSelectMyGearStepsWithSelections, getCurrentStepIndex],
  getSelectMyGearSelectedProductsWithFulfillmentQuestions
);

export default selector;
