import buildCompositeController from '../../../../v1/utils/buildCompositeController';
// Utils
import ElectronUtils from '../../../../v1/utils/ElectronUtils';

const mapStateToProps = (state) => ({
  // check used in electron
  electronUpdateNotAvailable: state.getIn([
    'compatibilityCheck',
    'electronUpdateNotAvailable',
  ]),
});

const mapDispatchToProps = () => ({
  checkDesktopAppUpdate: () => {
    if (window.electron) {
      ElectronUtils.forceCheckUpdate();
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
