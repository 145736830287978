import actionTypes from './actionTypes';

const electronActions = {};

electronActions.updateNotAvailable = () => ({
  type: actionTypes.ELECTRON_UPDATE_NOT_AVAILABLE,
});

electronActions.updateAvailable = () => ({
  type: actionTypes.ELECTRON_UPDATE_AVAILABLE,
});

electronActions.desktopNotification = (payload) => ({
  payload,
  type: actionTypes.ELECTRON_NOTIFICATION,
});

export default electronActions;
