import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesBackpacks extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M68.58 35.02l2-15.44c-.447-.572-1.023-1.03-1.68-1.34l-5.82-.67s-1.57-.45-1.9.9l-1.77 7.27s4.02.33 9.17 9.28zm-32.89-9.28l-1.79-7.28c-.34-1.34-1.9-.9-1.9-.9l-5.82.67c-.658.312-1.234.774-1.68 1.35l2 15.44c5.16-8.95 9.19-9.28 9.19-9.28z' />
          <path d='M68.86 45.65c.01-9.27-5.884-17.52-14.66-20.51-.81-3.146-3.647-5.344-6.895-5.344-3.248 0-6.085 2.198-6.895 5.344-8.85 2.927-14.83 11.19-14.85 20.51v30.81c.006 1.482 1.208 2.68 2.69 2.68H66.3c1.478-.005 2.675-1.202 2.68-2.68V48.14c0-.36-.05-1.23-.05-1.23s-.07-.77-.07-1.26zM47.29 21.14c2.348.004 4.463 1.42 5.36 3.59-3.51-.926-7.2-.926-10.71 0 .898-2.165 3.007-3.58 5.35-3.59zm18.43 36.6h-2.48v4.48c0 .552-.448 1-1 1s-1-.448-1-1v-4.49h-32c-.552 0-1-.448-1-1s.448-1 1-1h36.49c.552 0 1 .448 1 1s-.448 1-1 1l-.01.01z' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesBackpacks;
