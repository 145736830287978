import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import faqThunks from '../../actions/faqThunks';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import errorsActions from '../../actions/errorsActions';

const mapDispatchToProps = (dispatch) => ({
  sendReport(taskId, checkFromKB, cb) {
    dispatch(faqThunks.sendFAQReport(taskId, checkFromKB))
      .then(() => {
        dispatch(
          toastNotificationsActions.success({
            message: ' A report has been sent',
            title: 'Success',
          })
        );
        cb(); // Set Next task
      })
      .catch(() => {
        cb(); // Set Next task
        dispatch(errorsActions.forceResetErrors());
      });
  },
});

export default buildCompositeController(null, mapDispatchToProps);
