const workflowBrowserPageActions = {};

workflowBrowserPageActions.LOAD_WORKFLOWS_START = 'LOAD_WORKFLOWS_START';
workflowBrowserPageActions.LOAD_WORKFLOWS_ERROR = 'LOAD_WORKFLOWS_ERROR';
workflowBrowserPageActions.LOAD_WORKFLOWS_SUCCESS = 'LOAD_WORKFLOWS_SUCCESS';
workflowBrowserPageActions.WORKFLOW_CREATE_START = 'WORKFLOW_CREATE_START';
workflowBrowserPageActions.WORKFLOW_CREATE_ERROR = 'WORKFLOW_CREATE_ERROR';
workflowBrowserPageActions.WORKFLOW_CREATE_CLEAR_ERROR =
  'WORKFLOW_CREATE_CLEAR_ERROR';
workflowBrowserPageActions.WORKFLOW_CREATE_SUCESS = 'WORKFLOW_CREATE_SUCESS';

workflowBrowserPageActions.WORKFLOW_EDIT_START = 'WORKFLOW_EDIT_START';
workflowBrowserPageActions.WORKFLOW_EDIT_ERROR = 'WORKFLOW_EDIT_ERROR';
workflowBrowserPageActions.WORKFLOW_EDIT_SUCCESS = 'WORKFLOW_EDIT_SUCCESS';

workflowBrowserPageActions.loadWorkflowsStart = () => ({
  type: workflowBrowserPageActions.LOAD_WORKFLOWS_START,
});

workflowBrowserPageActions.loadWorkflowsError = (error) => ({
  error,
  type: workflowBrowserPageActions.LOAD_WORKFLOWS_ERROR,
});

workflowBrowserPageActions.loadWorkflowsSuccess = (workflows) => ({
  type: workflowBrowserPageActions.LOAD_WORKFLOWS_SUCCESS,
  workflows,
});

workflowBrowserPageActions.createNewWorkflowStart = () => ({
  type: workflowBrowserPageActions.WORKFLOW_CREATE_START,
});

workflowBrowserPageActions.createNewWorkflowSucess = () => ({
  type: workflowBrowserPageActions.WORKFLOW_CREATE_SUCESS,
});

workflowBrowserPageActions.createNewWorkflowError = (formError) => ({
  formError,
  type: workflowBrowserPageActions.WORKFLOW_CREATE_ERROR,
});

workflowBrowserPageActions.editWorkflowStart = () => ({
  type: workflowBrowserPageActions.WORKFLOW_EDIT_START,
});

workflowBrowserPageActions.editWorkflowSucess = (workflow) => ({
  type: workflowBrowserPageActions.WORKFLOW_EDIT_SUCCESS,
  workflow,
});

workflowBrowserPageActions.editWorkflowError = (formError) => ({
  formError,
  type: workflowBrowserPageActions.WORKFLOW_EDIT_ERROR,
});

export default workflowBrowserPageActions;
