import PropTypes from 'prop-types';
import React from 'react';
import { useSwipeable } from 'react-swipeable';

const propTypes = {
  children: PropTypes.element,
};

const SwipeableWrapper = ({ children, ...props }) => {
  const handlers = useSwipeable({ ...props });
  return (
    <div style={{ height: '100%' }} {...handlers}>
      {children}
    </div>
  );
};

SwipeableWrapper.propTypes = propTypes;

export default SwipeableWrapper;
