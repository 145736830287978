import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS, List, Map } from 'immutable';
import { Field } from 'redux-form/immutable';
import { Button, Divider, Input } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { curry, hasIn, memoize, noop } from 'lodash';

// Atoms
import ErrorLabel from '../atoms/ErrorLabel';

// Molecule
import EntriesInput from './EntriesInput';

// Controller
import EntriesGroupController from '../controllers/EntriesGroupController';

class EntriesGroup extends PureComponent {
  static propTypes = {
    /** Initial values */
    /** Text to display in the ADD ENTRY button */
    buttonAddEntryText: PropTypes.string,
    change: PropTypes.func,
    /** ReduxForm 'field' prop */
    fields: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    /** Form error */
    formError: PropTypes.string,
    initialInputValue: PropTypes.oneOfType([
      ImmutablePropTypes.map,
      ImmutablePropTypes.list,
    ]),
    /** Coming from Redux Form  */
    input: PropTypes.shape({
      name: PropTypes.string,
      value: ImmutablePropTypes.listOf(PropTypes.string),
    }),
    inputName: PropTypes.string,

    loading: PropTypes.bool,

    /** Coming from Redux Form  */
    meta: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      dispatch: PropTypes.any,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
      form: PropTypes.any,
      submitFailed: PropTypes.bool,
    }),

    /** If you want phrases appear in natural array order (0 on top, 1 below, etc) */

    naturalOrder: PropTypes.bool,

    /** Use custom placeholder. If not used, will be grabbed from CountryCodes if available */
    placeholder: PropTypes.string,

    /** Will process the entire trigger object */
    processFullTriggers: PropTypes.bool,
  };

  static defaultProps = {
    buttonAddEntryText: 'Add new',
    change: noop,
    formError: '',
    initialInputValue: null,
    input: fromJS({
      value: [],
    }),
    inputName: '',
    loading: false,
    meta: {},
    naturalOrder: false,
    placeholder: '',
    processFullTriggers: false,
  };

  componentDidMount() {
    const { initialInputValue, fields, processFullTriggers } = this.props;

    fields.removeAll(); // Clean fields

    if (initialInputValue && initialInputValue.size) {
      const initValue = (value) => {
        if (Map.isMap(value)) {
          value.forEach((v) => {
            initValue(v);
          });
        } else if (List.isList(value) && processFullTriggers) {
          value.forEach((v) => {
            fields.push(v);
          });
        } else if (value) {
          fields.push(value);
        }
      };

      initValue(initialInputValue);
    } else {
      // If no group, create one
      this.handleAddEntries();
    }
  }

  handleAddEntries = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { fields } = this.props;

    fields.push('');
  };

  onRemove = memoize(
    curry((index, e) => {
      if (e) {
        e.preventDefault();
      }
      const { change, fields, initialInputValue, inputName } = this.props;

      fields.remove(index); // Remove field form fieldarray
      const newValue = initialInputValue.filter(
        (val, k) => Number(k) !== Number(index)
      );
      change(`${inputName}`, newValue); // clean value in the real input
    })
  );

  onChange = memoize(
    curry((index, e, fromFulltrigger) => {
      const { change, fields, inputName } = this.props;

      if (e && hasIn(e, 'target')) {
        const value = e.target.value || '';
        if (fromFulltrigger) {
          // Check for immutable
          const entry = fields.getAll().get(index);
          if (Map.isMap(entry)) {
            change(`${inputName}[${index}]`, entry.set('phrase', value));
          } else {
            change(`${inputName}[${index}]`, value);
          }
        } else {
          change(`${inputName}.${index}.phrase`, value);
        }
      }
    })
  );

  /**
   * Reorder fields to display the new empty at the very top
   * @returns {*}
   */
  renderFields = () => {
    const {
      fields,
      loading,
      placeholder,
      processFullTriggers,
      naturalOrder,
    } = this.props;

    let res = fields.map((entry, i) => (
      <Field
        component={EntriesInput}
        fieldsLength={fields.length}
        index={i}
        key={entry}
        loading={loading}
        name={entry}
        onRemove={this.onRemove(i)}
        placeholder={placeholder}
        processFullTriggers={processFullTriggers}
        valueChange={this.onChange(i)}
      />
    ));

    if (!naturalOrder) {
      res = res.reverse(); // reverse the entries list to always get the new entries at the top
    }

    return res;
  };

  render() {
    const {
      buttonAddEntryText,
      formError,
      inputName,
      input,
      meta: { submitFailed, error },
    } = this.props;

    return (
      <>
        <Button
          basic
          content={buttonAddEntryText}
          floated='right'
          onClick={this.handleAddEntries}
        />

        <Divider clearing fitted hidden />

        <Field
          component={Input}
          name={inputName}
          style={{
            visibility: 'hidden',
          }}
        />

        {this.renderFields()}

        {submitFailed && error ? (
          <ErrorLabel error={error} inputName={input.name} pointing='above' />
        ) : null}
        {formError && (
          <ErrorLabel
            error={formError}
            inputName={input.name}
            pointing='above'
          />
        )}
      </>
    );
  }
}

export const EntriesGroupTest = EntriesGroup;

export default EntriesGroupController(EntriesGroup);
