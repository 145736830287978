import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const idForm = ownProps.id;
  const textAreaValue = state.getIn(['form', 'Form01', 'values', idForm]);
  const charLength = textAreaValue ? textAreaValue.length : 0;
  const isProtected = state.getIn(
    [
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
      'is_faq_protected',
    ],
    false
  );

  return {
    charLength,
    isProtected,
    textAreaValue,
  };
};

export default buildCompositeController(mapStateToProps, null);
