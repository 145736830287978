import { change } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
import workflowThunks from '../../actions/workflowThunks';

const mapDispatchToProps = (dispatch) => ({
  handleSkip(id, validValue) {
    dispatch(change('Form01', id, validValue));
  },
  resetValue(tempDataName, inValidValue) {
    // Force to reset the temp_data value to the invalid value
    // usefull if there is another component with his own setAlternateSubmit
    dispatch(
      workflowThunks.updateTempDataBackEndScratch({
        [tempDataName]: inValidValue,
      })
    );
  },
});

export default buildCompositeController(null, mapDispatchToProps);
