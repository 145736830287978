export const requestInterceptor = (config) => {
  /** Simulate request progress events */
  if (process.env.NODE_ENV !== 'production') {
    const { onUploadProgress, onDownloadProgress } = config;
    const onProgressHandler = onUploadProgress || onDownloadProgress;

    if (onProgressHandler) {
      [25, 50, 75, 99].forEach(async (loaded) =>
        /* Simulate request progress events */
        onProgressHandler.call(null, {
          lengthComputable: true,
          loaded,
          total: 100,
        })
      );
    }
  }

  return config;
};

// Do something with response error
export const requestErrorHandler = (error) => Promise.reject(error);
