import { createSelector } from 'reselect';

const getEmailDomains = (state) =>
  state.getIn(['emailPolicies', 'emailDomains', 'values']);

const getSelectedEmailDomain = (emailDomains) => {
  if (!emailDomains) {
    return null;
  }

  const selectedEmailDomain = emailDomains.find((emailDomain) =>
    emailDomain.get('is_primary_for_email')
  );

  return selectedEmailDomain;
};

const selector = createSelector([getEmailDomains], getSelectedEmailDomain);

export default selector;
