import React, { useCallback, useEffect, useState } from 'react';
import pt from 'prop-types';
import { debounce } from 'lodash';
import { Form, Search } from 'semantic-ui-react';
import { intl } from 'esp-util-intl';
import useIntentLookup from '../controllers/useIntentLookup';

// const SEARCH_TIMEOUT = 300;

const propTypes = {
  disabled: pt.bool,
  label: pt.string,
  name: pt.string,
  placeholder: pt.string,
  required: pt.bool,
  setFieldValue: pt.func,
  setValues: pt.func,
  values: pt.shape({
    intent: pt.string,
    intentName: pt.string,
  }),
};

const BaristaIntentLookupV2 = ({
  disabled,
  label,
  name,
  placeholder,
  required,
  setFieldValue,
  setValues,
  values,
}) => {
  const [searchQuery] = useState('');
  const { intent } = values;
  const {
    intentLookup,
    intentLookupById,
    isIntentLoading,
    intents,
  } = useIntentLookup();

  useEffect(() => {
    async function fetchIntents() {
      const currentIntent = await intentLookupById(intent);
      if (currentIntent.id) {
        setValues({
          ...values,
          intent: currentIntent.id,
          intentName: currentIntent.title,
        });
      }
    }
    if (intent) {
      setFieldValue('intent', '');
      fetchIntents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- fetch intents once is mounted
  }, []);

  // re-using noResultMessage to give Users better feedback
  let noResultsMessage = placeholder;
  if (isIntentLoading) {
    noResultsMessage = intl.formatMessage({ id: 'label.searching_intents' });
  } else if (searchQuery && intents.length === 0) {
    noResultsMessage = intl.formatMessage({
      id: 'label.no_intents_found',
    });
  }

  const handleResultSelect = useCallback(
    (e, { result }) => {
      setValues({
        ...values,
        intent: result.id,
        intentName: result.title,
      });
    },
    [setValues, values]
  );

  const handleSearchByManager = useCallback(
    (e, { value }) => {
      setFieldValue('intentName', value);
      if (value) {
        intentLookup({ limit: 10, search: value });
      }
    },
    [intentLookup, setFieldValue]
  );

  return (
    <Form.Field required={required}>
      <label>{label}</label>
      <Search
        disabled={disabled}
        loading={isIntentLoading}
        name={name}
        noResultsMessage={noResultsMessage}
        onResultSelect={handleResultSelect}
        onSearchChange={debounce(handleSearchByManager, 500, {
          leading: true,
        })}
        placeholder={placeholder}
        results={intents}
        showNoResults={intents.length > 0}
        value={values.intentName}
      />
    </Form.Field>
  );
};

BaristaIntentLookupV2.propTypes = propTypes;

export default BaristaIntentLookupV2;
