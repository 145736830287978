import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Header,
  // Icon,
  Modal,
} from 'semantic-ui-react';
import { UserAgentUtils } from 'esp-globals';

// Utils
// Globals
import AppUrls from '../../../../v1/globals/AppUrls';
// Controller
import IncompatibleModalController from './IncompatibleModalController';

class IncompatibleModal extends PureComponent {
  static propTypes = {
    checkDesktopAppUpdate: PropTypes.func.isRequired, // From Controller
    electronUpdateNotAvailable: PropTypes.bool, // From Controller
  };

  static defaultProps = {
    electronUpdateNotAvailable: false,
  };

  handleRefresh = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  render() {
    const { checkDesktopAppUpdate, electronUpdateNotAvailable } = this.props;

    const isiOS = UserAgentUtils.isIOS();
    const isAndroid = UserAgentUtils.isAndroidWeb();
    const isElectron = UserAgentUtils.isElectron();
    const isWeb =
      (UserAgentUtils.isMacOS() || UserAgentUtils.isWindows()) && !isElectron;

    return (
      <Modal basic className='scrolling' open size='tiny'>
        <Header color='yellow' content='Out Of Date' icon='warning sign' />
        <Modal.Content>
          {
            'You are currently running an incompatible version of the application.'
          }
        </Modal.Content>
        <Modal.Actions>
          {isWeb ? (
            <Button
              color='yellow'
              content='Click To Refresh'
              inverted
              onClick={this.handleRefresh}
            />
          ) : null}
          {isiOS ? (
            <Button
              as='a'
              color='yellow'
              content='Download The Latest'
              href={AppUrls.iosUrl}
              icon='apple'
              inverted
            />
          ) : null}
          {isAndroid ? (
            <Button
              as='a'
              color='yellow'
              content='Download The Latest'
              href={AppUrls.androidUrl}
              icon='android'
              inverted
            />
          ) : null}
          {isElectron ? (
            electronUpdateNotAvailable ? (
              <Button
                color='yellow'
                content='Updates not available. Contact your system administrator.'
                disabled
                inverted
                onClick={checkDesktopAppUpdate}
              />
            ) : (
              <Button
                color='yellow'
                content='Download The Latest'
                inverted
                onClick={checkDesktopAppUpdate}
              />
            )
          ) : null}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default IncompatibleModalController(IncompatibleModal);
