import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
/**
 * Return the current selected performance for onboard activity mapped with Users
 */

const getUsersList = (state) => state.getIn(['entities', 'users']);

const getPerformanceWithTemplateList = (state, userID) => {
  if (!userID || !state.hasIn(['entities', 'performances', Number(userID)])) {
    return fromJS([]);
  }

  const performance = state.getIn(['entities', 'performances', Number(userID)]);

  return performance.map((perf, index) => {
    const newPerf = perf;
    const idtemplate = perf.get('todo');

    // Check for the template associate
    const templatesList = state.getIn(['onboardActivity', 'template', 'items']);
    const template = templatesList.filter(
      (item) => item.get('id') === Number(idtemplate)
    );

    if (idtemplate && template && !template.isEmpty()) {
      return newPerf.set('template', template.get(0)).set('key', index + 1);
    }
    return newPerf.set('key', index + 1);
  });
};

const getPerformanceDetailList = (performance, usersList) =>
  performance.map((perf) => {
    if (!perf) {
      return fromJS([]);
    }

    const usr = usersList.has(perf.get('requested_for'))
      ? usersList.get(perf.get('requested_for'))
      : null;
    if (usr) {
      return perf.set('user_requested', usr);
    }

    return perf;
  });

const selector = createSelector(
  [getPerformanceWithTemplateList, getUsersList],
  getPerformanceDetailList
);

export default selector;
