const api_espuser_v0_1_whoami = {
  about_me: 'Hey cholos :(thhgvvvvv',
  age: 0,
  alias: 'erick@espressive.com',
  delegate_of: [2094],
  delegates: [2094],
  department: null,
  eid: '00c462e1-8ced-4226-bab1-6b75ce782978',
  email: 'erick.dolorosa@acme.com',
  first_name: 'Erick',
  full_name: 'Erick Mendoza  Dolorosa',
  gender: '',
  groups: ['ADMIN', 'CATALOG_MANAGER', 'SITE_MANAGER', 'ANNOUNCER'],
  home_address: {
    address: 'null',
  },
  id: 1008,
  images: [],
  is_active: true,
  last_name: 'Mendoza  Dolorosa',
  location: 'https://qa.dev.espressive.com/api/places/v0.1/locations/7/',
  middle_name: '',
  nickname: 'Erick',
  office_location: '',
  phone_numbers: {
    work: {
      number: '+11222322232',
      public: true,
    },
  },
  remote_worker: false,
  secondary_email: 'erick@espressive.com',
  social_coordinates: [
    {
      network: 'linkedin',
      username: '1212212121',
    },
    {
      network: 'twitter',
      username: '',
    },
  ],
  start_date: '2017-06-01T00:00:00Z',
  url: 'https://qa.dev.espressive.com/api/espuser/v0.1/users/1008/',
  user_state: 'SIGNUP_NOTIFICATION_SENT',
};

export default api_espuser_v0_1_whoami;
