import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesPowerAdaptors extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='nonzero'>
          <path d='M66.12 34.51h-9.55v-7.2h9.55c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5h-9.55V18c0-1.105-.895-2-2-2H29.5c-1.105 0-2 .895-2 2v33c0 1.105.895 2 2 2h25.07c1.105 0 2-.895 2-2V37.51h9.55c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zM35.38 67.4c0 .707.573 1.28 1.28 1.28h4.11v10.1c0 .552.448 1 1 1s1-.448 1-1V68.67h4.11c.687-.02 1.235-.582 1.24-1.27V55.23H35.38V67.4z' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesPowerAdaptors;
