import Immutable from 'immutable';

import { each, identity, isEmpty, isString, isUndefined, some } from 'lodash';
import CompositePathPattern from './CompositePathPattern';
import getPathParamTypeCastFn from './getPathParamTypeCastFn';
import { PathGenerator } from 'esp-util-path';
import PathParam from './PathParam';
import uiPaths from './uiPaths';

const INITIAL_IS_RELAXED = false;

class UIPathGenerator extends PathGenerator {
  constructor(paths) {
    super(paths);

    this.isRelaxed = INITIAL_IS_RELAXED;
  }

  /** @public */
  genPath(dotExpression, params = {}, options = {}) {
    this.isRelaxed = true;

    // leverage PathGenerator implementation
    const generatedPath = super.genPath(dotExpression, params, options);

    this.isRelaxed = INITIAL_IS_RELAXED;
    return generatedPath;
  }

  //
  // Augments 'pattern' with 'pathNode'.
  // @private
  //
  augmentPattern(pattern, pathNode) {
    const nodePattern = pathNode.pattern;

    if (isString(nodePattern)) {
      // Re use PathGenerator implementation
      return super.augmentPattern(pattern, pathNode);
    }

    if (
      nodePattern instanceof PathParam ||
      nodePattern instanceof CompositePathPattern
    ) {
      let evaluatedNodePattern;

      if (this.isRelaxed) {
        evaluatedNodePattern = nodePattern.relaxedEvaluate();
      } else {
        evaluatedNodePattern = nodePattern.evaluate();
      }

      if (isEmpty(pattern)) {
        return evaluatedNodePattern;
      }

      return `${pattern}/${evaluatedNodePattern}`;
    }

    // TODO better error reporting
    throw new Error('Invalid pattern type.');
  }

  /**
   * Returns a type casted copy of React Router params.
   *
   * Explores the uiPaths looking for a node such that 'genPath' with given 'params' would evaluate to 'pathname'.
   * This part of the algorithm is some kind of reverse 'genPath' based on a brute force approach.
   *
   * @param pathname The pathname provided by React Router location.pathname.
   * @param params The params provided by React Router.
   */
  typeCastParams(pathname, params) {
    // path of nodes from uiPaths root down to the node that produced 'pathname' when evaluated with 'params'
    const pathToNode = [];

    this.findPathToNode(pathname, params, pathToNode, '', this.pathsTree);

    // all PathParam in the path from uiPaths root down to node that produced 'pathname' (inclusive)
    const pathParams = this.extractPathParams(pathToNode);

    // ES6 Map
    const typeCastFnByParamName = new Map();

    pathParams.forEach((pathParam) => {
      const name = pathParam.getParamName();
      const typeCastFn = getPathParamTypeCastFn(pathParam);
      typeCastFnByParamName.set(name, typeCastFn);
    });

    const typeCastedParams = {};

    each(params, (value, name) => {
      // ignore optional param that is not available
      if (isUndefined(value)) {
        typeCastedParams[name] = void 0;
        return;
      }

      let typeCastFn = typeCastFnByParamName.get(name);

      if (!typeCastFn) {
        //
        // if (process.env.NODE_ENV === 'development'){
        // // TODO include a link to documentation on that
        // console.warn(`Param '${name}' is not properly defined in 'uiPaths' file. Use 'PathParam' or 'CompositePathPattern' syntax instead.`);
        // }
        //

        // fallback to identity transform (does nothing)
        typeCastFn = identity;
      }

      typeCastedParams[name] = typeCastFn(value);
    });

    return typeCastedParams;
  }

  /**
   * @private
   */
  findPathToNode(pathname, params, pathToNode, dotExpression, node) {
    const nodePathname = this.genPath(dotExpression, params, {
      disableLogError: true,
    });
    const nodeEvaluatesToPathname = nodePathname === pathname;

    if (nodeEvaluatesToPathname) {
      pathToNode.push(node);
      return true;
    }

    if (isEmpty(node.children)) {
      return false;
    }

    // maybe some child evaluates to pathname
    pathToNode.push(node);

    // recursively try with all children
    const hasChildThatEvaluatesToPathname = some(
      node.children,
      (child, childName) => {
        let nextDotExpression;

        if (isEmpty(dotExpression)) {
          nextDotExpression = childName;
        } else {
          nextDotExpression = `${dotExpression}.${childName}`;
        }

        return this.findPathToNode(
          pathname,
          params,
          pathToNode,
          nextDotExpression,
          child
        );
      }
    );

    if (hasChildThatEvaluatesToPathname) {
      return true;
    }

    // backtrack
    pathToNode.pop();

    return false;
  }

  /**
   * @private
   */
  extractPathParams(nodes) {
    let pathParams = [];

    nodes.forEach((node) => {
      const nodePattern = node.pattern;

      if (nodePattern instanceof PathParam) {
        pathParams.push(nodePattern);
      }

      if (nodePattern instanceof CompositePathPattern) {
        const pathParamPatterns = nodePattern.getPathParamPatterns();
        pathParams = pathParams.concat(pathParamPatterns);
      }
    });

    return pathParams;
  }

  // This generates the path to redirect immediatly after login
  // /app/home by default
  genLandingPath(state = null) {
    let landingPath;
    const customLandingPath =
      Immutable.Map.isMap(state) &&
      state.getIn([
        'tenant',
        'entity',
        'value',
        'ui_config',
        'newFeature',
        'customLandingPath',
      ]);

    if (
      customLandingPath &&
      isString(customLandingPath) &&
      !isEmpty(customLandingPath)
    ) {
      // sets whatever was received from tenant config
      landingPath = customLandingPath;
    } else {
      // default (remember that toDO generates 'home')
      landingPath = super.genPath('app.toDo');
    }

    return landingPath;
  }
}

const uiPathGenerator = new UIPathGenerator(uiPaths);

export default uiPathGenerator;
