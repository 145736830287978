import moment from 'moment';
import _ from 'lodash';

const CLASS_DATE = {
  BLOCKED: 'blocked',
  BLOCKED_OUT_OF_RANGE: 'blocked-out-of-range',
  HIGHLIGHTED_CALENDAR: 'highlighted-calendar',
  TODAY: 'today',
  VALID: 'valid',
};

/**
 * Builde a new modifier
 * @param prev {string} 'YYYY-MM'
 * @param current {string} 'YYYY-MM'
 * @param next {string} 'YYYY-MM'
 * return {oject}
 */
const buildNewModifier = (prev, current, next) => {
  const modifiers = {};
  modifiers[prev] = {};
  modifiers[current] = {};
  modifiers[next] = {};

  return modifiers;
};

/**
 * Get numbers of months between 2 dates
 * @param monthMax
 * @param monthMin
 * @param yearMax
 * @param yearMin
 * @returns {number}
 */
const getNumberMonths = (monthMax, monthMin, yearMax, yearMin) => {
  monthMax = Number(monthMax);
  monthMin = Number(monthMin);

  yearMax = Number(yearMax);
  yearMin = Number(yearMin);

  // get numbers of months between both dates
  let numberMonth = monthMax - monthMin;

  if (monthMin > monthMax || yearMax > yearMin) {
    numberMonth = 12 - monthMin + monthMax;
  }
  return numberMonth;
};

/**
 * Add to a modifiers object a range of date
 * @param modifiers
 * @param numberMonth
 * @param monthModifier
 * @param yearModifier
 * @returns {*}
 */
const buildRangeModifier = (
  modifiers,
  numberMonth,
  monthModifier,
  yearModifier
) => {
  // For each month, add a new entry in the modifier object
  _.times(numberMonth, () => {
    monthModifier -= 1;
    yearModifier = monthModifier < 1 ? yearModifier - 1 : yearModifier;
    monthModifier = monthModifier < 1 ? 12 : monthModifier;

    monthModifier = monthModifier < 10 ? `0${monthModifier}` : monthModifier;

    // Add new modifier for this month/year
    modifiers[`${yearModifier}-${monthModifier}`] = {};
  });

  return modifiers;
};

//
// Convert a month to a compatible "modifier" for react-dates
// @param month {number} Between 1-12
// @param initialDate {string} - 'YYYY-MM-DD' Origin date of the valid dates
// @params currentDate {string} - 'YYYY-MM-DD' Current Date of the input
// @returns modifiers {set}
//
const buildModifier = (initialDate, currentDate, enablePastDate) => {
  // No initial date will set the minimum date to today
  const initialDates = initialDate
    ? moment(initialDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  const maxDateSelected = currentDate
    ? moment(currentDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  // Get initial Month
  const monthString = moment(initialDates).format('MM');

  // Get current Month
  const monthMaxString = moment(maxDateSelected).format('MM');

  // Get initial Year
  const yearString = moment(initialDates).format('YYYY');

  // Get current Year
  const maxYearString = moment(maxDateSelected).format('YYYY');

  // Current selected year
  let currentYearSelected = Number(maxYearString);

  const limitDateMin = initialDates; // minimal limit date

  const currentMonth = `${yearString}-${monthString}`;
  const nextMonth = moment(currentMonth).add(1, 'month').format('YYYY-MM'),
    prevMonth = moment(currentMonth).subtract(1, 'month').format('YYYY-MM');

  const currentMonthMax = `${maxYearString}-${monthMaxString}`,
    nextMonthMax = moment(currentMonthMax).add(1, 'month').format('YYYY-MM');

  // Create a new modifier
  const modifiers = buildNewModifier(prevMonth, currentMonth, nextMonth);

  // If we passed a currentDate, check the range time between the initial and the current
  if (currentMonthMax) {
    modifiers[currentMonthMax] = {};
    modifiers[nextMonthMax] = {};

    const monthMax = Number(monthMaxString);
    const yearMax = Number(maxYearString);
    const numberMonths = getNumberMonths(
      monthMaxString,
      monthString,
      maxYearString,
      yearString
    );

    // For each month, add a new entry in the modifier object
    buildRangeModifier(modifiers, numberMonths, monthMax, yearMax);
  }

  modifiers[nextMonthMax] = {};

  let listMonth = []; // Array to pass month to add

  // For Each modifiers push new entry in listMonth
  _.forIn(modifiers, (v, m) => {
    listMonth.push(m);
  });

  // Set the modifier from the listMonth array
  const setListMonth = (selectedDate, pastDateEnabled) => {
    // Clean previous selected Date
    if (selectedDate) {
      _.mapKeys(modifiers, (value) => {
        _.mapKeys(value, (val, key) => {
          if (
            val.has(CLASS_DATE.HIGHLIGHTED_CALENDAR) &&
            key !== selectedDate.format('YYYY-MM-DD')
          ) {
            val.delete(CLASS_DATE.HIGHLIGHTED_CALENDAR);
          }
        });
      });
    }

    listMonth.forEach((month) => {
      // Set a modifier for each day in the month
      _.times(moment(month).daysInMonth(), (day) => {
        // Make sure we have a two digits number
        day = day + 1 < 10 ? `0${day + 1}` : (day + 1).toString();
        const selectedDay = `${month}-${day}`;

        modifiers[month][selectedDay] = new Set();

        if (
          selectedDate &&
          selectedDate._isAMomentObject &&
          selectedDate.format('YYYY-MM-DD') === selectedDay
        ) {
          modifiers[month][selectedDay].add(CLASS_DATE.VALID);
          modifiers[month][selectedDay].add(CLASS_DATE.HIGHLIGHTED_CALENDAR);
        } else if (limitDateMin !== selectedDay) {
          if (moment(selectedDay).isBefore(initialDates) && !pastDateEnabled) {
            // It's a past date
            modifiers[month][selectedDay].add(CLASS_DATE.BLOCKED);
            modifiers[month][selectedDay].add(CLASS_DATE.BLOCKED_OUT_OF_RANGE);
          } else {
            // It's a future date or pastDateEnabled is active
            modifiers[month][selectedDay].add(CLASS_DATE.VALID);
          }
        } else {
          // It's today
          modifiers[month][selectedDay].add(CLASS_DATE.VALID);
          modifiers[month][selectedDay].add(CLASS_DATE.TODAY);
        }
      });
    });

    return modifiers;
  };

  const setModifier = (
    month,
    add,
    selectedDate,
    pastDateEnabled,
    sameMonth,
    yearSelected
  ) => {
    const monthString = month < 10 ? `0${month}` : month.toString();

    if (!sameMonth) {
      // Update selected year
      if (yearSelected) {
        currentYearSelected = Number(yearSelected); // Force the year from a select day
      } else if (add && month === 1) {
        currentYearSelected = Number(currentYearSelected) + 1;
      } else if (!add && month === 12) {
        currentYearSelected = Number(currentYearSelected) - 1;
      }

      const newCurrentMonth = `${currentYearSelected}-${monthString}`,
        nextMonth = moment(newCurrentMonth).add(1, 'month').format('YYYY-MM'),
        prevMonth = moment(newCurrentMonth)
          .subtract(1, 'month')
          .format('YYYY-MM');

      listMonth = [];
      listMonth.push(newCurrentMonth);

      // Update current modifiers
      if (add) {
        modifiers[nextMonth] = {};
        listMonth.push(nextMonth);
      } else {
        modifiers[prevMonth] = {};
        listMonth.push(prevMonth);
      }
    }
    let dateSelected;

    if (selectedDate) {
      dateSelected =
        selectedDate && selectedDate._isAMomentObject
          ? selectedDate
          : moment(selectedDate, 'YYYY-MM-DD');
    }

    return setListMonth(dateSelected, pastDateEnabled);
  };

  // If not currentDate passed, today is the selected date
  const selectedDate = currentDate ? moment(currentDate) : moment();

  // First initial set
  setListMonth(selectedDate, enablePastDate);

  return {
    getModifier: () => modifiers,
    setModifier,
  };
};

export { CLASS_DATE };

export default buildModifier;
