import { fromJS } from 'immutable';

import profileActions from '../actions/profileActions';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

/**
 * @param users The current reducer's state.
 * @param newUser An Immutable Map.
 * @return nextState The next reducer's state.
 */
const addUser = (users, newUser) => {
  const userId = newUser?.get('id');
  const exists = users?.has(userId);

  if (exists) {
    // the result of v.merge will be v itself if the merge with newCard didn't bring anything new
    // ferras.gif
    users = users.update(userId, (u) => u.merge(newUser));
  } else {
    // add user from scratch
    users = users.set(userId, newUser);
  }

  return users;
};

/**
 * @param users The current reducer's state.
 * @param newUsers An Immutable List of users.
 * @return nextState The next reducer's state.
 */
const addUsers = (users, newUsers) => {
  if (!newUsers) {
    return users;
  }

  newUsers.forEach((newUser) => {
    users = addUser(users, newUser);
  });

  return users;
};

const removeUser = (state, userID) =>
  state.filterNot((u) => u.get('id') === userID);

/**
 * Adds selected user and his manager (if available) under state.entities.users
 */
const onSelectDirectoryUserSuccess = (state, user, manager) => {
  let newUsers = fromJS([]);

  newUsers = newUsers.push(user);

  if (manager) {
    newUsers = newUsers.push(manager);
  }

  return addUsers(state, newUsers);
};

const usersReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  let userSelected;

  switch (action.type) {
    case actionTypes.ADD_USER_ENTITY_SUCCESS:
      return addUser(state, fromJS(action.user));

    case actionTypes.REMOVE_USER_ENTITY: {
      return removeUser(state, action.userId);
    }

    case actionTypes.ADD_USERS_ENTITIES_SUCCESS:
      action.users.forEach((user) => {
        state = addUser(state, fromJS(user));
      });
      return state;

    case actionTypes.UPDATE_USER_ENTITY_SUCCESS:
      return addUser(state, action.user);

    case actionTypes.USER_LOGIN_SUCCESS:
      return addUser(state, fromJS(action.currentUser));

    case actionTypes.APP_BOOTSTRAP_SUCCESS:
    case actionTypes.WHO_AM_I:
      return addUser(state, fromJS(action.me));

    case actionTypes.GET_DIRECTORY_USERS_SUCCESS:
      return addUsers(state, fromJS(action.users));

    case actionTypes.GET_SOCIALIZING_USERS_SUCCESS:
      return addUsers(state, fromJS(action.users));

    case actionTypes.SELECT_DIRECTORY_USER_SUCCESS:
      return onSelectDirectoryUserSuccess(
        state,
        fromJS(action.user),
        fromJS(action.manager)
      );

    case actionTypes.NO_SELECT_DIRECTORY_USER_SUCCESS:
      return onSelectDirectoryUserSuccess(
        state,
        fromJS(action.user),
        fromJS(action.manager)
      );
    case actionTypes.GET_PREV_DIRECTORY_USERS_SUCCESS:
      return addUsers(state, fromJS(action.users));

    case actionTypes.GET_NEXT_DIRECTORY_USERS_SUCCESS:
      return addUsers(state, fromJS(action.users));

    // TODO this should be using actionTypes
    case profileActions.GET_USERS_PROFILE_SUCCESS:
      return addUsers(state, action.users);

    case actionTypes.RESUME_WORKFLOW_SUCCESS:
      if (action.requestedForUserData) {
        return addUser(state, fromJS(action.requestedForUserData));
      } else {
        return state;
      }

    case actionTypes.CHANGE_START_DATE_USER_SUCCESS:
      userSelected = state
        .get(action.userID)
        .set('start_date', action.startDate);
      return addUsers(state, [userSelected]);

    case actionTypes.UPDATE_WORKFLOW_REQUESTED_FOR_USER_SUCCESS:
      return addUser(state, fromJS(action.requestedForUser));

    case actionTypes.CHANGE_DEPARTMENT_USER_SUCCESS:
      return addUser(state, fromJS([action.data]));

    case actionTypes.GET_USER_DEPARTMENT_ID_SUCCESS:
      userSelected = state
        .get(action.userID)
        .set('departmentId', action.department.id);
      return state.setIn([action.userID], userSelected);

    default:
      return state;
  }
};

export default usersReducer;
