import React, { PureComponent } from 'react';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import _ from 'lodash';

// Atoms
import ChatBotIcon from '../../../globals/ChatBotIcon';

/**
 * It's required to have this as a separate component so it can both
 * close the More Menu and open Barista modal on click.
 */
class BaristaTrigger extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    onCloseMoreMenu: PropTypes.func,
    text: PropTypes.string,
  };

  static defaultProps = {
    onClick: _.noop,
    onCloseMoreMenu: _.noop,
    text: 'Get Help',
  };

  handleClick = () => {
    const { onCloseMoreMenu, onClick } = this.props;

    onCloseMoreMenu();

    // injected as a prop by Modal component, calling it opens the modal
    onClick();
  };

  render() {
    const { text } = this.props;
    return (
      <List.Item className='nav-get-help' onClick={this.handleClick}>
        <ChatBotIcon />
        <List.Content>{text}</List.Content>
      </List.Item>
    );
  }
}

export default BaristaTrigger;
