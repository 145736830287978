import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Loader } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { stripUnit } from 'polished';

const rule = (props) => {
  const {
    hasToggleButton,
    isAttachmentMenuOpen,
    isLoading,
    isTextareaFocused,
    theme,
  } = props;

  const inputWidthDifference = isTextareaFocused
    ? hasToggleButton
      ? stripUnit(theme.formHeight) * 2
      : stripUnit(theme.formHeight)
    : stripUnit(theme.formHeight) * 2;
  return {
    '> .ui.loader.small.active': {
      ':after': {
        borderColor: '#767676 transparent transparent',
      },
      ':before': {
        borderColor: '#e7e7e7',
      },
      left: 'auto',
      right: '0.25em',
    },
    '> textarea': {
      '::placeholder': {
        opacity: isAttachmentMenuOpen ? 0 : 0.8,
        transitionDuration: `${theme.globals.transitionDuration * 2}ms`,
        transitionProperty: 'opacity',
        transitionTimingFunction: theme.globals.transitionTimingFunction,
      },
      ':focus': {
        borderColor: theme.colors.darkGray,
        boxShadow: 'none',
        outlineStyle: 'none',
        // '::placeholder' : {opacity: 0}, <-- Disable for this release
      },
      borderColor: theme.colors.gray,
      borderRadius: '20px',
      color: theme.colors.text,
      display: 'block',
      minHeight: '36px',
      opacity: isAttachmentMenuOpen ? 0 : 1,
      padding: '.5em .75em',
      paddingRight: isLoading ? '2.25em' : '.75em',
      transform: isAttachmentMenuOpen ? 'scale(.8)' : 'scale(1)',
      transformOrigin: 'left',
      transitionDuration: `${theme.globals.transitionDuration}ms`,
      transitionProperty: 'all',
      transitionTimingFunction: theme.globals.transitionTimingFunction,
      width: '100%',
    },
    display: 'inline-block',
    minHeight: theme.formHeight || '0', // Avoid Fela error with Storybook
    paddingBottom: '.25em',
    paddingLeft: isTextareaFocused ? '.25em' : 0,
    paddingRight: '.25em',
    paddingTop: '.25em',
    position: 'relative',
    transform: 'translateZ(0)',
    transitionDuration: isTextareaFocused
      ? theme.expandDuration
      : `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'width, padding',
    transitionTimingFunction: isTextareaFocused
      ? theme.expandTimingFunction
      : theme.globals.transitionTimingFunction,
    verticalAlign: 'top',
    width: `calc(100% - ${inputWidthDifference}px)`,
  };
};

class ChatFormTextArea extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    // hasToggleButton   : PropTypes.bool,
    isLoading: PropTypes.bool,
    // isTextareaFocused : PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func.isRequired,
    onTextAreaFocus: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    textAreaRef: PropTypes.shape({
      current: PropTypes.shape({
        focus: PropTypes.func,
      }),
    }),
    value: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    // hasToggleButton : false,
    isLoading: false,
    onChange: void 0,
    placeholder: null,
    readOnly: false,
    textAreaRef: void 0,
    value: '',
  };

  componentWillUnmount() {
    document.documentElement.removeAttribute('data-keyboard-open');
  }

  handleTextAreaBlur = (e) => {
    document.documentElement.setAttribute('data-keyboard-open', false);
    document.getElementById('ChatForm').removeAttribute('style');
  };

  handleUpdateCharLength = (e) => {
    const { onTextAreaFocus } = this.props;

    const count = e.target.value.length;
    const limit = 2500; // Max Character allowed in the Chat Input

    if (count > limit) {
      // Maximum exceeded
      e.target.value = e.target.value.substring(0, limit);
    }

    onTextAreaFocus(e);
  };

  handleChange = (e) => {
    const { onChange } = this.props;

    this.handleUpdateCharLength(e);
    onChange(e);
  };

  isElementVisible = (el) => {
    const rect = el?.getBoundingClientRect();
    if (!rect) {
      return true;
    }
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  scrolldowwnConversationFeed = () => {
    const elements = document.getElementsByClassName('ConversationFeedBottom');
    for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      if (!this.isElementVisible(elements[elementIndex])) {
        elements[elementIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  };

  handleTextAreaClick = (e) => {
    this.scrolldowwnConversationFeed();
  };

  handleOnFocus = (e) => {
    const { onTextAreaFocus } = this.props;
    this.scrolldowwnConversationFeed();
    onTextAreaFocus(e);
  };

  render() {
    const {
      autoFocus,
      className,
      disabled,
      isLoading,
      placeholder,
      readOnly,
      textAreaRef,
      value,
      onKeyDown,
    } = this.props;

    return (
      <div className={`${className} inline`} data-component='ChatFormTextArea'>
        <TextareaAutosize
          aria-multiline='false' // as Enter or Return will submit the form
          // eslint-disable-next-line jsx-a11y/no-autofocus -- legacy feature expecting autofocus
          autoFocus={autoFocus}
          contentEditable='true'
          disabled={disabled}
          onBlur={this.handleTextAreaBlur}
          onChange={this.handleChange}
          onClick={this.handleTextAreaClick}
          onFocus={this.handleOnFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={textAreaRef}
          role='textbox'
          rows={1}
          style={{
            overflow: 'hidden',
            resize: 'none',
          }}
          value={value}
        />
        {isLoading && <Loader active size='small' />}
      </div>
    );
  }
}

export default createComponent(rule, ChatFormTextArea, [
  'autoFocus',
  'disabled',
  'hasToggleButton',
  'isAttachmentMenuOpen',
  'isLoading',
  'isTextareaFocused',
  'onChange',
  'onKeyDown',
  'onTextAreaFocus',
  'placeholder',
  'readOnly',
  'textAreaRef',
  'value',
]);
