import React, { useCallback, useState } from 'react';
import { intl } from 'esp-util-intl';
import { Button, Modal } from 'semantic-ui-react';
import CommonPropsShape from './CommonPropsShape';
import AnnouncementCategorySelect from '../../../../../v1/components/molecules/AnnouncementCategorySelect';

const propTypes = { ...CommonPropsShape };

const SelectCategoryModal = ({
  announcementId,
  changeFormFieldValue,
  isDraft,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => setIsModalOpen(false), []);

  const handleModalOpen = useCallback(() => setIsModalOpen(true), []);

  const handlerChangeFormFieldValue = useCallback(
    (fieldName, value, announcementId) => {
      changeFormFieldValue(fieldName, value, announcementId);
      handleModalClose();
    },
    [changeFormFieldValue, handleModalClose]
  );

  return (
    <Modal
      className='scrolling'
      closeIcon={
        <div
          className='item close'
          onClick={handleModalClose}
          onKeyPress={handleModalClose}
        >
          {intl.formatMessage({ id: 'label.exit' })}
        </div>
      }
      onClose={handleModalClose}
      open={isDraft && isModalOpen}
      size='small'
      trigger={
        <Button
          basic
          content={intl.formatMessage({ id: 'label.edit' })}
          onClick={handleModalOpen}
        />
      }
    >
      <AnnouncementCategorySelect
        announcementId={announcementId}
        changeFormFieldValue={handlerChangeFormFieldValue}
      />
    </Modal>
  );
};

SelectCategoryModal.propTypes = propTypes;

export default SelectCategoryModal;
