const userAgent = window && window.navigator ? window.navigator.userAgent : '';

// device uuids
// @see cordova-plugin-device plugin
const uuids = {
  nexus1: '84dc3fb166bd6fed',
  nexus6: 'af6dca7804ed05ca',
};

const device = window.device || {};
const uuid = device.uuid || 'no-found';

const UserAgentUtils = {
  isAndroidApp: () =>
    Boolean(window.device && window.device.platform === 'Android'),
  isAndroidWeb: (fakeAgent = null) => {
    const agent = fakeAgent || userAgent;
    const iosRegExp = /Android/g;
    const res = agent.match(iosRegExp);
    return res !== null;
  },

  isChrome: () => {
    if ((window && !window.navigator) || !window) {
      return false;
    }
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  },

  isCordova: (fakeWindow = null) => {
    const _window = fakeWindow || window;
    return Boolean(_window.cordova);
  },

  isElectron: (fakeWindow = null) => {
    const _window = fakeWindow || window;
    return Boolean(_window.electron);
  },

  isFirefox: () => {
    const ua = window.navigator.userAgent;

    const ff = ua.indexOf('Firefox/');

    if (ff > -1) {
      return ff;
    } else {
      // other browser
      return false;
    }
  },

  isIE: () => {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  },

  isIOS: (fakeAgent = null) => {
    const agent = fakeAgent || userAgent;
    const iosRegExp = /iPad|iPhone|iPod/g;
    const res = agent.match(iosRegExp);
    return res !== null;
  },
  isIOSApp: () => Boolean(window.device && window.device.platform === 'iOS'),
  isIpad: (fakeAgent = null) => {
    const agent = fakeAgent || userAgent;
    return agent.indexOf('iPad') > 0;
  },
  isMSTeams: () => {
    if (
      window.self !== window.top ||
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      return true;
    }
    return false;
  },

  isMacElectron: () => {
    if (window.electron) {
      if (window.electronAPI?.getOSPlatform() === 'darwin') {
        return true;
      } else {
        return false;
      }
    }
    return false;
  },

  isMacOS: (fakeAgent = null) => {
    const agent = fakeAgent || userAgent;
    const macRegExp = /Macintosh/i;
    const res = agent.match(macRegExp);
    return res !== null;
  },
  isNexus1: () => Boolean(uuid === uuids.nexus1),
  isNexus6: () => Boolean(uuid === uuids.nexus6),
  isWinElectron: () => {
    if (window.electron) {
      if (window.electronAPI?.getOSPlatform() !== 'darwin') {
        return true;
      } else {
        return false;
      }
    }
    return false;
  },
  isWindows: (fakeAgent = null) => {
    const agent = fakeAgent || userAgent;
    const winRegExp = /Windows/i;
    const res = agent.match(winRegExp);
    return res !== null;
  },
};

export default UserAgentUtils;
