const results = [
  {
    active: true,
    id: '1',
    label: 'label X',
    order: 'order X',
    parent_name: 'state X',
    service_team: '1',
    task_type_name: 'Task X',
  },
];

const api_task_v0_1_task_sub_status = {
  count: results.length,
  results,
};

export default api_task_v0_1_task_sub_status;
