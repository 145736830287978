import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getMemberList = (state) => state.getIn(['caseMgmt', 'teamRelationShip']);

const getUserList = (state) => state.getIn(['entities', 'users']);

const getServiceMemberList = (memberList, userList) => {
  if (!memberList || memberList.isEmpty() || !userList || userList.isEmpty()) {
    return Immutable.List();
  }

  const newMemberList = memberList.map((member) => {
    const userID = member.get('user');
    if (userID) {
      return member.set('member', userList.get(userID));
    }

    return member;
  });

  return newMemberList;
};

const selector = createSelector(
  [getMemberList, getUserList],
  getServiceMemberList
);

export default selector;
