let sharedCalls = [];

/**
 * Utility FIFO queue for JEST API call mockup
 * Allow to pass our own response by bypassing the api_mock and passing the response like with SinonJS
 */
const emptyCalls = function () {
  /**
   * Should be call inside the beforeEach() in order to reset possible call
   */
  this.resetCall = () => {
    sharedCalls = [];
  };

  /**
   * Set a new response mockup in the order (First in First out)
   * @param response
   */
  this.setOrderedResponse = (response) => {
    if (!response) {
      throw new Error('The response cannot be null');
    }
    sharedCalls.push(response);
  };

  /**
   * Set response in any order
   * The getter will keep the FIFO queue
   */
  this.setResponse = (position, response) => {
    if (isNaN(position)) {
      throw new Error('Wrong position passed');
    }
    sharedCalls[position] = response;
  };

  /**
   * Set a new Error response mockup in the order (First in First out)
   * This will force .catch to be throw instead of .then
   * @param response
   */
  this.setOrderedErrorResponse = (response) => {
    sharedCalls.push({
      throwError: response,
    });
  };

  /**
   * Return the first response set
   * @returns {*}
   */
  this.getResponse = () => {
    if (!sharedCalls.length) {
      return null;
    }
    return sharedCalls.shift();
  };
};

const emptyCall = new emptyCalls();

export default emptyCall;
