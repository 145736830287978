import tinycolor from 'tinycolor2';

const EspBranding = (tenantColorPrimary, tenantColorHeader) => {
  // These values need to come from tenant config.
  // TODO Move fallback values to getTenantBrandingSelector maybe
  tenantColorPrimary = tenantColorPrimary || '#ccc';
  tenantColorHeader = tenantColorHeader || tenantColorPrimary;

  // text colors depending on light/darkness
  const textColorPrimary = tinycolor.isReadable(tenantColorPrimary, '#fff', {
    level: 'AA',
    size: 'large', // shift to 3:1 from 4.5:1
  })
    ? '#fff'
    : '#000';
  const textColorHeader = tinycolor.isReadable(tenantColorHeader, '#fff', {
    level: 'AA',
    size: 'large', // shift to 3:1 from 4.5:1
  })
    ? '#fff'
    : '#000';

  // These colors are darkened to increase contrast on light backgrounds
  const isReadablePrimary = tinycolor.isReadable(
    tenantColorPrimary,
    '#fff',
    {}
  ); // boolean
  const textColorPrimaryReadable = tinycolor(tenantColorPrimary)
    .darken(8)
    .toString();
  // const textColorHeaderReadable = tinycolor(tenantColorHeader).darken(8).toString();

  // console.warn('P: ' + textColorPrimary);
  // console.warn('H: ' + textColorHeader);
  // console.warn('TEST: ' + tinycolor(tenantColorHeader).darken(5).toString());

  const brand = [
    {
      object: {
        backgroundColor: tenantColorPrimary,
      },
      selector:
        'body.esp.login-page, body.esp.workflow, body.esp.reset-password-page, .esp-app-header, .esp-tenant-preview, #esp-admin-header',
    },
    {
      object: {
        backgroundColor: tenantColorHeader,
      },
      selector: '#esp-admin-header, .esp-app-header',
    },
    {
      object: {
        color: `${textColorHeader}!important`,
      },
      selector:
        'body:not(.dpc) #esp-admin-header .ui.container > .item, body:not(.dpc) #esp-admin-header .dropdown.icon',
    },
    {
      object: {
        boxShadow: `inset 0 -1px 0 0 ${tinycolor(textColorHeader)
          .setAlpha(0.15)
          .toString()}`,
      },
      selector: '#esp-admin-header',
    },
    {
      object: {
        backgroundColor: tinycolor(tenantColorHeader).darken(5).toString(),
        borderColor: `${tinycolor(tenantColorHeader)
          .darken(10)
          .toString()}!important`,
        color: textColorHeader,
      },
      selector: '#esp-search .ui.input input',
    },
    {
      object: {
        color: textColorHeader,
      },
      selector: '#esp-search i.search.icon',
    },
    {
      object: {
        color: tinycolor(textColorHeader).setAlpha(0.4).toString(),
      },
      selector: '#esp-search .ui.input input::placeholder',
    },
    {
      object: {
        color: textColorHeader,
      },
      selector: '#esp-search .ui.input .icon, #esp-search .ui.input .ui.button',
    },
    {
      object: {
        backgroundColor: `${tenantColorPrimary} !important`,
        color: `${textColorPrimary} !important`,
      },
      selector: '.ui.primary.label',
    },
    {
      object: {
        backgroundColor: tenantColorPrimary,
        borderColor: tenantColorPrimary,
      },
      selector:
        'body.esp .ui.checkbox input:checked~.box:before, body.esp .ui.checkbox input:checked~label:before',
    },
    {
      object: {
        color: isReadablePrimary
          ? tenantColorPrimary
          : textColorPrimaryReadable,
      },
      selector:
        'a, .ui.button.text, .ui.breadcrumb a, .ui.list .list>.item>.content a.content, .ui.list .list>.item>.content a.description, .ui.list .list>.item>.content a.header, .ui.list>.item>.content a.content, .ui.list>.item>.content a.description, .ui.list>.item>.content a.header, .esp-workflow .ui.menu a.item',
    },
    {
      object: {
        color: tinycolor(tenantColorPrimary).darken(15).toString(),
      },
      selector:
        'a:hover, .ui.button.text:hover, .ui.breadcrumb a:hover, .ui.list .list>.item>.content a.content:hover, .ui.list .list>.item>.content a.description:hover, .ui.list .list>.item>.content a.header:hover, .ui.list>.item>.content a.content:hover, .ui.list>.item>.content a.description:hover, .ui.list>.item>.content a.header:hover, a:focus, .ui.button.text:focus, .ui.breadcrumb a:focus, .ui.list .list>.item>.content a.content:focus, .ui.list .list>.item>.content a.description:focus, .ui.list .list>.item>.content a.header:focus, .ui.list>.item>.content a.content:focus, .ui.list>.item>.content a.description:focus, .ui.list>.item>.content a.header:focus',
    },
    {
      object: {
        color: isReadablePrimary
          ? tenantColorPrimary
          : textColorPrimaryReadable,
      },
      selector:
        '#esp-nav .ui.list > .item.nav-get-help .esp-icon.barista, i.icon.primary',
    },
    {
      object: {
        color: `${tenantColorPrimary} !important`,
      },
      selector: '.ui.list .list>.item a.header, .ui.list>.item a.header',
    },
    {
      object: {
        background: `${tenantColorPrimary} !important`,
      },
      selector: '.esp-card:not(.announcement) .back',
    },
    {
      object: {
        fill: isReadablePrimary
          ? `${tenantColorPrimary}!important`
          : `${textColorPrimaryReadable}!important`,
      },
      selector: 'svg.primary.icon > *',
    },
    {
      object: {
        color: `${tinycolor(tenantColorPrimary)
          .darken(15)
          .toString()}!important`,
      },
      selector:
        '.ui.list .list>.item a.header:hover, .ui.list .list>.item a.header:focus, .ui.list>.item a.header:hover, .ui.list>.item a.header:focus',
    },
    {
      object: {
        backgroundColor: tenantColorPrimary,
      },
      selector: '.ui.list .list>.item.link:before, .ui.list>.item.link:before',
    },
    {
      object: {
        backgroundColor: tenantColorPrimary,
        color: `${textColorPrimary} !important`,
      },
      selector: '.ui.label.primary',
    },
    {
      object: {
        borderRightColor: tinycolor(tenantColorPrimary).darken(15).toString(),
      },
      selector: '.ui.ribbon.primary.label:after',
    },
    {
      object: {
        borderRightColor: 'transparent',
        borderTopColor: tinycolor(tenantColorPrimary).darken(15).toString(),
      },
      selector: '.ui[class*="right ribbon"].primary.label:after',
    },
    {
      object: {
        backgroundColor: tenantColorPrimary,
        color: `${textColorPrimary} !important`,
      },
      selector: '.ui.button.primary',
    },
    {
      object: {
        backgroundColor: tinycolor(tenantColorPrimary).darken(15).toString(),
      },
      selector: '.ui.button.primary:hover, .ui.button.primary:focus',
    },
    {
      object: {
        backgroundColor: tinycolor(tenantColorPrimary).darken(30).toString(),
      },
      selector: '.ui.button.primary:active, .ui.button.primary.active',
    },
    {
      object: {
        boxShadow: `0 0 0 2px ${tenantColorPrimary} inset !important`,
        color: isReadablePrimary
          ? `${tenantColorPrimary}!important`
          : `${textColorPrimaryReadable}!important`,
      },
      selector: '.ui.basic.primary.button',
    },
    {
      object: {
        boxShadow: `0 0 0 2px ${tinycolor(tenantColorPrimary)
          .darken(15)
          .toString()} inset !important`,
        color: `${tinycolor(tenantColorPrimary)
          .darken(15)
          .toString()}!important`,
      },
      selector:
        '.ui.basic.primary.button:hover, .ui.basic.primary.button:focus',
    },
    {
      object: {
        boxShadow: `0 0 0 2px ${tinycolor(tenantColorPrimary)
          .darken(30)
          .toString()} inset !important`,
        color: `${tinycolor(tenantColorPrimary)
          .darken(30)
          .toString()}!important`,
      },
      selector:
        '.ui.basic.primary.button:active, .ui.basic.primary.button.active',
    },
    {
      object: {
        borderColor: tinycolor(tenantColorPrimary).darken(15).toString(),
      },
      selector:
        '.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus, .ui.input input:focus',
    },
    {
      object: {
        background: `${tenantColorPrimary}!important`,
      },
      selector: '.esp-progress.bar .progress',
    },
    {
      object: {
        background: `linear-gradient(to right, ${tenantColorPrimary} 10%, ${tinycolor(
          tenantColorPrimary
        )
          .setAlpha(0)
          .toString()} 90%)`,
        color: textColorPrimary,
      },
      selector: '#esp-directory .ui.horizontal.divider',
    },
    {
      object: {
        color: `${textColorHeader}!important`,
      },
      selector:
        '.esp-app-header .ui.button.basic.icon.transparent, .esp-app-header .ui.floating.label',
    },
    {
      object: {
        boxShadow: `0 0 0 2px rgba(${textColorHeader},.15) inset !important`,
      },
      selector:
        '.esp-app-header .ui.button.basic.icon.transparent:hover, .esp-app-header .ui.button.basic.icon.transparent:focus',
    },
  ];

  return brand;
};

export default EspBranding;
