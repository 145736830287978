import buildCompositeController from '../../utils/buildCompositeController';

import bundleAction from '../../actions/bundleActions';
import bundleThunks from '../../actions/bundleThunks';

import getHardwareBundle from '../../selectors/getHardwareBundle';
import getHardwareBundleProducts from '../../selectors/getHardwareBundleProducts';

const mapStateToProps = (state) => {
  const bundlesState = state.get('bundles');

  const activeHardwareBundleProductImageIdx = bundlesState.get(
    'activeHardwareBundleProductImageIdx'
  );

  const hardwareBundle = getHardwareBundle(state);
  const hardwareBundleProducts = getHardwareBundleProducts(state);

  // if (hardwareBundle) {
  //  console.log('Hardware Bundle Prop', hardwareBundle.toJS());
  // }

  // if (hardwareBundleProducts) {
  //  console.log('Hardware Bundle Products Prop', hardwareBundleProducts.toJS());
  // }

  const selectedHardwareBundleProductId = bundlesState.get(
    'selectedHardwareBundleProductId'
  );

  return {
    activeHardwareBundleProductImageIdx,
    hardwareBundle,
    hardwareBundleProducts,
    selectedHardwareBundleProductId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHardwareBundleProducts() {
    dispatch(bundleThunks.getChildBundleProducts('hardware'));
  },

  selectHardwareBundleProduct(productId) {
    dispatch(bundleAction.selectHardwareBundleProduct(productId));
  },

  setActiveHardwareBundleProductImageIdx(index) {
    dispatch(bundleAction.setActiveHardwareBundleProductImageIdx(index));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
