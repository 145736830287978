const api_espuser_v0_1_users_8 = {
  about_me: 'hhgjhghgjhhvvhvhbm',
  alias: '',
  computer_type: 'Macintosh',
  contacts: {
    ENABLEMENT: null,
    HR_CONTACT: null,
    HR_RECRUITER: null,
    IT_CONTACT: null,
    MANAGER: null,
    MENTOR: null,
    OPERATIONS: null,
  },
  cost_center: '110',
  custom_map: null,
  date_of_birth: '1904-12-31',
  delegate_of: [],
  delegates: [],
  eid: '111',
  email: 'espbeta02@gmail.com',
  employee_number: 'thujan1018141pm',
  esp_dict: {
    existing_user: true,
    last_supportemail_send_ts: 1566860776.325589,
  },
  favorites: [
    55, 936, 5244, 218928, 10491, 211797, 10445, 11579, 129127, 11634, 8,
  ],
  first_name: 'Samuel',
  full_name: 'Samuel Samuel',
  gender: 'MALE',
  groups: [
    'ADMIN',
    'MANAGER',
    'SERVICE_LEAD',
    'SERVICE_AGENT',
    'KIOSK',
    'FAQ_ADMIN',
    'BRC_MANAGER',
  ],
  home_address: {
    address: '84 Larsen St',
    city: 'Castro Valley',
    country: 'USA',
    postal_code: '95054',
    state: 'CA',
  },
  id: 8,
  images: [
    {
      caption: "''",
      eid: 'fff8c199-49b0-452e-b8d0-60c786faa12a',
      height: 280,
      id: 70,
      ordering: 0,
      picture:
        'https://lol/media/release/profile_images/25bb9d62-ef94-4664-a610-8a2edd9f0e0a.jpeg?Expires=1604094129&Signature=MEQCIEcVoj5FdbuPABk7Yc1T~8lgKP9odT4rWIckVkLPbGd1AiBmB2BEvgUbq1muxp1LpNyKtuormwpvO0T7-t7DmwCcjw__&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      sys_created_by: 'espbeta02@gmail.com',
      sys_custom_fields: null,
      sys_date_created: '2017-11-30T19:06:41.255433Z',
      sys_date_updated: '2019-05-22T22:58:51.589315Z',
      sys_updated_by: 'eddie.valverde@espressive.com',
      thumbnail:
        'https://lol/media/release/profile_images/bbe1be20-c4a2-46af-b12c-d5f124367f38.jpeg?Expires=1604094129&Signature=MEUCIFiFUOHrBae-ZCFwxoU5p2iJr78EHaSP97kZsWrDjBs2AiEA2diPoFAIpSMwSda81dlqtDOsxjA9lfbGVyn6kq-ABkk_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      type: 'BACKGROUND',
      url: 'https://lol.com/api/images/v0.1/profile_image/70/',
      user: 'https://lol.com/api/espuser/v0.1/users/8/',
      width: 540,
    },
    {
      caption: "''",
      eid: '522b2075-d2bc-4e78-a2ce-8fd76a7ed719',
      height: 400,
      id: 352,
      ordering: 0,
      picture:
        'https://lol/media/release/profile_images/7313d94c-7d5c-4f37-8f74-2b184f70e141.jpeg?Expires=1604094129&Signature=MEUCIQDLc0QoHQpXCEuVFP14AqU2YpGlWaKY86Y7ycJQVejX-wIgeYtqdUL2Y4lAphAWZWEqo80PNjxv0EpVQ3K27raM~QY_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      sys_created_by: 'espbeta02@gmail.com',
      sys_custom_fields: null,
      sys_date_created: '2019-05-07T23:46:35.976972Z',
      sys_date_updated: '2020-09-11T16:35:19.634304Z',
      sys_updated_by: 'espbeta02@gmail.com',
      thumbnail:
        'https://lol/media/release/profile_images/0cae56e4-d09b-4a4f-a1c8-9b70dd203171.jpeg?Expires=1604094129&Signature=MEUCIQCuIdmps0GWnib24pT7E-epUEksPwRTvJYUniPpyyBWjwIgVl8CcnTI5bFi~DDfli4vZPWod8fN71v-l0GVCQTcX8o_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      type: 'PROFILE',
      url: 'https://lol.com/api/images/v0.1/profile_image/352/',
      user: 'https://lol.com/api/espuser/v0.1/users/8/',
      width: 400,
    },
    {
      caption: '',
      eid: '0ec8558d-1444-45e3-9e67-de3882421390',
      height: 338,
      id: 400,
      ordering: 0,
      picture:
        'https://lol/media/release/profile_images/af829a3d-f741-4115-abb7-0bc1ca06554b.jpg?Expires=1604094129&Signature=MEQCICEr676Fj661xVIy2H7psMTcWUApQXvtaL5Kl4P9Kq8UAiAjMyK0iV9PnBzQtcOOZ4ctdlQ759A-h~KlKjlEXCRThQ__&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      sys_created_by: 'erick@example.com',
      sys_custom_fields: null,
      sys_date_created: '2020-10-22T20:30:25.534677Z',
      sys_date_updated: '2020-10-22T20:30:25.766734Z',
      sys_updated_by: 'erick@example.com',
      thumbnail:
        'https://lol/media/release/profile_images/51c720ae-a837-4b16-b1ec-cd65b842a02c.jpg?Expires=1604094129&Signature=MEQCICWlpfz23r91mzSBFw~zddBNq~C8aNOXq2~Ngl5-hzG6AiBAp3~D4uqXC6sQDNrEzZxe-YgqoygEFab0T33LtqIBMA__&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      type: 'DEF_BACKGROUND',
      url: 'https://lol.com/api/images/v0.1/profile_image/400/',
      user: null,
      width: 600,
    },
  ],
  is_active: true,
  is_admin: true,
  is_manager: true,
  job_role: 'https://lol.com/api/espuser/v0.1/job_role/14/',
  last_name: 'Samuel',
  last_sms_timestamp: null,
  location: 'https://lol.com/api/places/v0.1/locations/54681/',
  manager: null,
  mentor: null,
  middle_name: '',
  mobile_type: 'iOS',
  nickname: 'Samuel',
  office_address: 'state: Jalisco, country: announcement_country, zone: global',
  office_location: '1100',
  phone_numbers: {
    home: {
      country_code: '',
      country_iso: '',
      number: '',
      public: true,
    },
    mobile: {
      country_code: '',
      country_iso: '',
      number: '',
      public: true,
    },
    work: {
      country_code: '',
      country_iso: '',
      number: '',
      public: true,
    },
  },
  preferred_language_id: 'en',
  remote_worker: false,
  scim_dict: null,
  secondary_email: 'espbeta02@gmail.comm',
  shadow_block: {
    date_of_birth: null,
    employee_number: '',
    first_name: '',
    job_role: null,
    last_name: '',
    location: null,
    nickname: '',
    phone_numbers: null,
    secondary_email: '',
    social_coordinates: null,
    start_date: '1986-10-10T00:00:00Z',
    title: 'FE Engineer',
  },
  sms_subscription: false,
  social_coordinates: [
    {
      network: 'linkedin',
      username: 'celis',
    },
    {
      network: 'twitter',
      username: 'celiss',
    },
  ],
  social_profile_picture: '',
  start_date: '1986-10-10T00:00:00Z',
  sys_custom_fields: null,
  termination_date: null,
  terms_accepted: false,
  title: '',
  url: 'https://lol.com/api/espuser/v0.1/users/8/',
  user_state: 'MANAGER_CONFIRMED',
  user_type: '',
  username: 'espbeta02@gmail.com',
  work_collaboration: null,
};
export default api_espuser_v0_1_users_8;
