import pt from 'prop-types';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Header,
  Icon,
  List,
  Message,
  Segment,
} from 'semantic-ui-react';
import { Waypoint } from 'react-waypoint';
import { intl } from 'esp-util-intl';
import useTemplateListSettings from '../../../../molecules/Offboarding/utils/hooks/useTemplateListSettings';

// utils
import uiPathGenerator from '../../../../../utils/uiPathGenerator';
import getDueDate from '../../../../../utils/getDueDate';
import OnboardActivitiesCategory from '../../../../../globals/OnboardActivitiesCategory';
// Atom
import ScrollArea from '../../../../atoms/ScrollArea';
// molecules
import EspListItem from '../../../../molecules/EspListItem';
import OffboardingTemplateIntervalModal from './OffboardingTemplateIntervalModal';
import { fromJS } from 'immutable';
// import OffboardingActivityTemplateListItem from '../../../../molecules/Offboarding/OffboardingActivityTemplateListItem';

const propTypes = {
  action: pt.string,
  // /** Action on a schedule ID (copy) */
  // /** Loading state */
  isLoading: pt.bool,
  // /** Load next page */
  // /** Next template page */
  next: pt.string,
  // /** Schedule ID */
  scheduleID: pt.string.isRequired,
  selectedTemplates: pt.arrayOf(pt.shape({})),
  templateID: pt.string,
  templateList: pt.arrayOf(pt.shape({})),
  // /** Array of items to display in the list */
};

const OffboardingActivityScheduleTemplate = ({
  templateID,
  action,
  scheduleID,
  selectedTemplates,
  templateList,
  isLoading,
  next,
}) => {
  const { loadMoreTemplates } = useTemplateListSettings();

  const handleBottomWaypointEnter = useCallback(() => {
    if (loadMoreTemplates) {
      loadMoreTemplates();
    }
  }, [loadMoreTemplates]);

  const modalTrigger = (
    <Button basic circular floated='left' icon='plus' primary />
  );
  return (
    <div
      className='offboarding-activity-schedule-list'
      style={{
        height: '100%',
        margin: '-1em',
        minHeight: '70vh',
      }}
    >
      {/* NOTE: We will probably need to add filtering tools here at some point. */}
      <Header as='h4' content='Templates' dividing />
      <ScrollArea height='calc(100% - 52px)'>
        {/* TODO: Add styles so that these buttons show up correctly. */}
        {templateList && templateList.length > 0 ? (
          templateList.map((item) => {
            const ImgElement = OnboardActivitiesCategory.getIn([
              item.category,
              'image',
            ])
              ? OnboardActivitiesCategory.getIn([item.category, 'image'])
              : null;

            return (
              <div className='segment-card' key={item.id}>
                {/* UPDATE TO ONE SPECIFICaLY TO OFFBOARDING */}
                <OffboardingTemplateIntervalModal
                  action={action}
                  intervalID='new'
                  scheduleID={scheduleID}
                  selectedTemplates={selectedTemplates}
                  templateID={item.id}
                  trigger={modalTrigger}
                />

                <Segment as={List} compact>
                  {/* NOTE2: item.internalDescription is still not supported by the API, I use private_notes for now */}
                  <EspListItem
                    action={
                      <Button
                        as={Link}
                        basic
                        content='view'
                        to={uiPathGenerator.genPath(
                          'admin.adminActivities.onboardingActivities.templates',
                          {
                            templateID: item.id,
                          }
                        )}
                      />
                    }
                    attachedHeader={item.private_notes}
                    description={item.description}
                    header={item.title}
                    image={ImgElement && <ImgElement />}
                    meta={getDueDate(fromJS(item))}
                  />
                </Segment>
              </div>
            );
          })
        ) : (
          <Message
            content='Click the button above to add your first onboard task template.'
            info
          />
        )}

        {isLoading && (
          <Message icon>
            <Icon loading name='circle notched' />
            <Message.Content>
              {intl.formatMessage({
                id: 'label.loading_more',
              })}
            </Message.Content>
          </Message>
        )}

        {next && !isLoading && (
          <Waypoint key={next} onEnter={handleBottomWaypointEnter} />
        )}

        <Divider hidden />
      </ScrollArea>
    </div>
  );
};

OffboardingActivityScheduleTemplate.propTypes = propTypes;

export default OffboardingActivityScheduleTemplate;
