import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

// Controllers
import FeedbackReceivedModalController from '../controllers/FeedbackReceivedModalController';
// Molecules
import ConversationFeed from '../molecules/ConversationFeed';
// V2
import EspModal from '../../../v2/components/display/EspModal';
// Package
import { intl } from 'esp-util-intl';

class FeedbackReceivedModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,

    companyName: PropTypes.string,

    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    companyName: '',
    isOpen: false,
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  renderContent = () => {
    const { companyName } = this.props;

    const conversationFeeback = {
      id: '2429d4msg',
      messages: [
        {
          id: '000',
          text: intl.formatMessage({
            id: 'message.feedback_you_are_awesome',
          }),
          type: 'message',
          user_id: '0',
        },
        {
          id: '111',
          text: intl.formatMessage({
            id: 'message.barista_thanks_bubble',
            values: {
              companyName: companyName,
            },
          }),
          type: 'message',
          user_id: '0',
        },
      ],
      template: 'conversation01',
      type: 'conversation',
      users: [
        {
          first_name: 'barista',
          id: '0',
          imageUrl: '',
          isBarista: true,
          last_name: 'barista',
          nickname: 'barista',
        },
      ],
    };

    return (
      <ConversationFeed
        animated
        blockConvo
        isExpandable={false}
        messages={fromJS(conversationFeeback.messages)}
        users={fromJS(conversationFeeback.users)}
      />
    );
  };

  render() {
    const { isOpen } = this.props;

    const actions = (
      <Button
        content={'Ok'}
        onClick={this.handleClose}
        primary
        size={'large'}
      />
    );

    return (
      <EspModal
        actions={actions}
        content={this.renderContent()}
        contentPadding='0 1em 2em'
        header={intl.formatMessage({
          id: 'message.Thank_you_for_your_feedback',
        })}
        onClose={this.handleClose}
        open={isOpen}
      />
    );
  }
}

export default FeedbackReceivedModalController(FeedbackReceivedModal);
