const api_workflow_v0_1_workflowrequest_35 = {
  backend_scratch: {
    scratch: {
      temp_data: {
        category_faq: {
          id: 12,
          name: 'antivirus IT',
          serviceDepartment: 3,
        },
        faq_desc: 'testeuuu',
        faq_question: 'antivirus IT',
        faq_response: {
          '0': {
            locationdeptrole: {
              locations: [
                {
                  id: '1',
                  name: 'USA',
                },
              ],
            },
            response: 'response num 1',
          },
          '1': {
            locationdeptrole: {
              locations: [
                {
                  id: '1',
                  name: 'USA',
                },
              ],
            },
            response: 'repsonse num 2',
          },
          '2': {
            locationdeptrole: {},
            response: 'reposnse num 3',
          },
        },
        phrases_faq: {
          '0': {
            phrase: {
              eid: '7df61451-22d7-4c96-9606-53411d107fd1',
              phrase: 'testeuu',
            },
          },
        },
        serviceTeam: 4,
      },
    },
  },
};

export default api_workflow_v0_1_workflowrequest_35;
