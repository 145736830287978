import { addLocaleData, IntlProvider } from 'react-intl';

import zh from 'react-intl/locale-data/zh';
import cs from 'react-intl/locale-data/cs';
import nl from 'react-intl/locale-data/nl';
import en from 'react-intl/locale-data/en';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import hu from 'react-intl/locale-data/hu';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ru from 'react-intl/locale-data/ru';
import sl from 'react-intl/locale-data/sl';
import es from 'react-intl/locale-data/es';
import sv from 'react-intl/locale-data/sv';
import ta from 'react-intl/locale-data/ta';
import th from 'react-intl/locale-data/th';
import uk from 'react-intl/locale-data/uk';
import vi from 'react-intl/locale-data/vi';

import getIntlMessages from '../../selectors/getIntlMessages';
import buildCompositeController from '../../utils/buildCompositeController';

// Registering the supported locales
// The locale data files are provide per language, e.g. en.js,
// but they contain data for all locales in that language, e.g.: en-US, en-GB, es-MX, etc.
addLocaleData([
  ...zh,
  ...cs,
  ...nl,
  ...en,
  ...fi,
  ...fr,
  ...de,
  ...hu,
  ...it,
  ...ja,
  ...ko,
  ...pl,
  ...pt,
  ...ru,
  ...sl,
  ...es,
  ...sv,
  ...ta,
  ...th,
  ...uk,
  ...vi,
]);

/**
 * the format ReactIntl expects for the messages is different from what POEditor gives us
 * So this controller formats the messages the way is expected
 */
const mapStateToProps = (state) => {
  // as per React Intl 2 messsages object must be a flat structure
  const messages = getIntlMessages(state); // selector will return always the same messages object

  return {
    key: state.getIn(['intl', 'locale']),
    locale: state.getIn(['intl', 'locale']),
    messages,
  };
};

export default buildCompositeController(mapStateToProps)(IntlProvider);
