import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { Dimmer, Loader } from 'semantic-ui-react';

// Molecules
import MessageEmptyState from '../../molecules/MessageEmptyState';
// Organism
import SelectEquipmentFulfillmentQuestionsModal from '../../organisms/SelectEquipmentFulfillmentQuestionsModal';
// Controller
import SelectMyGearBlockController from '../../controllers/SelectMyGearBlockController';

import TypeSelelectMyGear, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectMyGear';

import ValidatedSelectMyGear from '../../organisms/ValidatedSelectMyGear';
import BodyClassName from '../../../../globals/BodyClassName';

class SelectMyGear01 extends Component {
  static propTypes = {
    ...TypeSelelectMyGear,
    answerFulfillmentQuestions: PropTypes.func,
    isEquipFulfillmentFormsValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    missingBundle: PropTypes.bool,
    onMoveNextStep: PropTypes.func,
    onMovePrevStep: PropTypes.func,
    roleBundleID: PropTypes.number,
    selectedProductsWithFulfillmentQuestions: ImmutablePropTypes.listOf(
      PropTypes.number
    ).isRequired,
    /** Should be required but to avoid bad behavior with data error - set it as optional and handle case when it's missing */
    submitProductFulfillmentForms: PropTypes.func,
  };

  static defaultProps = {
    ...TypeDefaults,
    answerFulfillmentQuestions: _.noop,
    isEquipFulfillmentFormsValid: false,
    isLoading: false,
    missingBundle: false,
    onMoveNextStep: _.noop,
    onMovePrevStep: _.noop,
    submitProductFulfillmentForms: _.noop,
  };

  componentDidMount() {
    const { setAlternateBack, setAlternateSubmit } = this.props;

    setAlternateBack(this.movePrevStep);
    setAlternateSubmit(this.moveNextStep);
  }

  setSelectEquipmentFulfillmentQuestionsModal = (
    selectEquipmentFulfillmentQuestionsModal
  ) => {
    this.selectEquipmentFulfillmentQuestionsModal = selectEquipmentFulfillmentQuestionsModal;
  };

  onSubmitProductFulfillmentForms = () => {
    const {
      selectedProductsWithFulfillmentQuestions,
      submitProductFulfillmentForms,
    } = this.props;

    submitProductFulfillmentForms(selectedProductsWithFulfillmentQuestions);
  };

  handleFulfillmentAnswers = (answersByProduct) => {
    const { answerFulfillmentQuestions, onMoveNextStep } = this.props;

    // action creator expects a regular JS Object
    answerFulfillmentQuestions(answersByProduct.toJS());

    this.selectEquipmentFulfillmentQuestionsModal.close();

    onMoveNextStep(this.moveWorkflowNextTask);

    // clean up the mess
    this.moveWorkflowNextTask = null;
  };

  movePrevStep = (moveWorkflowPrevTask) => {
    const { onMovePrevStep } = this.props;
    onMovePrevStep(moveWorkflowPrevTask);
  };

  moveNextStep = (moveWorkflowNextTask) => {
    const {
      selectedProductsWithFulfillmentQuestions,
      onMoveNextStep,
    } = this.props;

    if (selectedProductsWithFulfillmentQuestions.isEmpty()) {
      onMoveNextStep(moveWorkflowNextTask);
    } else {
      // keep reference to it so we can use it later, after fulfillment answers were provided
      this.moveWorkflowNextTask = moveWorkflowNextTask;

      this.selectEquipmentFulfillmentQuestionsModal.open();
    }
  };

  render() {
    const {
      isEquipFulfillmentFormsValid,
      isLoading,
      missingBundle,
      resetBlockLoadingState,
      roleBundleID,
      selectedProductsWithFulfillmentQuestions,
      template,
      type,
    } = this.props;

    // console.log(selectedProductsWithFulfillmentQuestions.toJS());
    const roleBundleSet = Boolean(roleBundleID);

    const errorMsg = missingBundle
      ? 'Please go back on the previous step'
      : 'No Role bundle has been assigned to you';

    const errorTitle = missingBundle
      ? 'No bundle has been selected'
      : 'Please contact your manager';

    const displayError =
      (!roleBundleSet || missingBundle) && !isLoading ? (
        <MessageEmptyState content={errorMsg} header={errorTitle} negative />
      ) : null;

    const isLoadingDisplay = isLoading ? (
      <Dimmer active inverted>
        <Loader size='large' />
      </Dimmer>
    ) : null;

    return (
      <BodyClassName className='blocks-justify'>
        <div className='block' data-blocktype={type} data-template={template}>
          {displayError}

          {isLoadingDisplay}

          {roleBundleSet && (
            <ValidatedSelectMyGear
              name='selectMyGear'
              roleBundleID={roleBundleID}
            />
          )}

          {roleBundleSet && (
            <SelectEquipmentFulfillmentQuestionsModal
              isEquipFulfillmentFormsValid={isEquipFulfillmentFormsValid}
              onFulfillmentAnswers={this.handleFulfillmentAnswers}
              productIDs={selectedProductsWithFulfillmentQuestions.toJS()}
              ref={this.setSelectEquipmentFulfillmentQuestionsModal}
              resetBlockLoadingState={resetBlockLoadingState}
              submitProductFulfillmentForms={
                this.onSubmitProductFulfillmentForms
              }
            />
          )}
        </div>
      </BodyClassName>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SelectMyGear01 = SelectMyGearBlockController(SelectMyGear01);

export default SelectMyGear01;
