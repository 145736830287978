import buildCompositeController from '../../utils/buildCompositeController';
import catalogThunks from '../../actions/catalogThunks';
import {
  getFormSyncErrors,
  getFormValues,
  isDirty,
  isValid,
} from 'redux-form/immutable';

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('CatalogDetailFormSpecs')(state),
  formValues: getFormValues('CatalogDetailFormSpecs')(state),
  isDirty: isDirty('CatalogDetailFormSpecs')(state),
  isValid: isValid('CatalogDetailFormSpecs')(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveForm({ isDirty, isValid, formValues, product }) {
    if (isDirty && isValid) {
      const allowedAttributes = ['specifications'];
      const filteredForm = formValues.filter((value, key) =>
        allowedAttributes.find((attr) => attr === key)
      );
      dispatch(
        catalogThunks.updateProductAttributes(
          product.get('id'),
          filteredForm.toJS()
        )
      );
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
