import React from 'react';
import pt from 'prop-types';
import EspMarkdown, { DefaultPropsValues } from 'esp-markdown';
import CustomKbImage from './CustomKbImage';

const defaultProps = {
  content: '',
};
const propTypes = {
  content: pt.string,
};
const InternalFaqKbRender = ({ content }) => {
  return (
    <EspMarkdown
      customComponents={{
        img: CustomKbImage,
      }}
      supportedNodes={[...DefaultPropsValues.supportedNodes, 'image']}
      text={content}
    />
  );
};

InternalFaqKbRender.propTypes = propTypes;
InternalFaqKbRender.defaultProps = defaultProps;

export default InternalFaqKbRender;
