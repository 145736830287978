import { createSelector } from 'reselect';
import Immutable from 'immutable';
import isImpersonating from './isImpersonating';
import getCurrentUser from './getCurrentUser';

const getSession = (state) => state.getIn(['session']);

const getDelegators = (isImpersona, currentUser, session) => {
  if (isImpersona) {
    const impersonatingUser = session.getIn([
      'impersonation',
      'impersonatingUser',
    ]);
    const originalUser = session.getIn(['impersonation', 'originalUser']);

    // if its impersonating, remove current user and add original one
    const delegators = session
      .getIn(['impersonation', 'original_delegate_of'])
      .filter((userId) => userId !== impersonatingUser);

    // Add the original user at the beggining of the list
    return delegators.unshift(originalUser);
  }

  if (currentUser) {
    // Otherwise return user profile's delegate_of
    return currentUser.get('delegate_of');
  }

  // or empty list
  return new Immutable.List();
};

const selector = createSelector(
  [isImpersonating, getCurrentUser, getSession],
  getDelegators
);

export default selector;
