import { fromJS } from 'immutable';
import { forOwn } from 'lodash';
import actionTypes from '../actions/actionTypes';
import ChannelTypes from '../globals/ChannelTypes';

const INITIAL_STATE = fromJS([
  {
    filters: [
      {
        key: 'typeIs',
        label: 'label.show',
        options: [
          {
            checked: true,
            text: 'label.help_requests',
            value: ChannelTypes.SCOPED_CHANNEL,
          },
          {
            text: 'label.my_messages',
            value: ChannelTypes.DIRECT_CHANNEL,
          },
        ],
        type: 'radio',
      },
    ],
    title: 'label.show',
  },
  {
    filters: [
      {
        key: 'sort',
        options: [
          {
            checked: true,
            text: 'filter.new_old',
            value: '-last_message_date',
          },
          {
            text: 'filter.old_new',
            value: 'last_message_date',
          },
        ],
        type: 'radio',
      },
    ],
    title: 'label.sort_by',
  },
  {
    filters: [
      {
        key: 'time',
        options: [
          {
            checked: true,
            text: 'label.all',
            value: 'all',
          },
          {
            text: 'filter.last_24_hours',
            value: 1000 * 60 * 60 * 24,
          },
          {
            text: 'filter.last_7_days',
            value: 1000 * 60 * 60 * 24 * 7,
          },
          {
            text: 'filter.last_30_days',
            value: 1000 * 60 * 60 * 24 * 30,
          },
        ],
        type: 'radio',
      },
    ],
    title: 'label.time_period',
  },
]);

const channelsFilterReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CHANNELS_FILTER_UPDATE: {
      let currentFilters = state;

      // merges state of current filters with the filter options received in the action
      forOwn(action.channelsFilters, (sectionFilters, sectionTitle) => {
        const sectionIndex = currentFilters.findIndex(
          (section) => section.get('title') === sectionTitle
        );
        if (sectionIndex > -1) {
          // finding current section
          // for each filter in the section, update its options
          forOwn(sectionFilters, (options, filterKey) => {
            const filterIndex = currentFilters
              .getIn([sectionIndex, 'filters'])
              .findIndex((filter) => filter.get('key') === filterKey);
            if (filterIndex > -1) {
              currentFilters = currentFilters.updateIn(
                [sectionIndex, 'filters', filterIndex, 'options'],
                (currentOptions) => currentOptions.mergeDeep(fromJS(options))
              );
            }
          });
        }
      });

      return currentFilters;
    }

    case actionTypes.CHANNELS_FILTER_UPDATE_TYPE: {
      let currentFilters = action.reset ? INITIAL_STATE : state;
      let options = currentFilters
        .getIn([0, 'filters', 0, 'options'])
        .map((option) => option.set('checked', false));
      const optionIndex = options.findIndex(
        (option) => option.get('value') === action.channelType
      );
      options = options.setIn([optionIndex, 'checked'], true);
      currentFilters = currentFilters.updateIn(
        [0, 'filters', 0, 'options'],
        (currentOptions) => currentOptions.mergeDeep(options)
      );

      return currentFilters;
    }

    default: {
      return state;
    }
  }
};

export default channelsFilterReducer;
