import actionTypes from './actionTypes';

const cordovaActions = {
  keyBoardHidden: () => ({
    type: actionTypes.CORDOVA_KEYBOARD_HIDDEN,
  }),
  keyBoardVisible: () => ({
    type: actionTypes.CORDOVA_KEYBOARD_VISIBLE,
  }),

  registerNotificationsSuccess: (registrationId) => ({
    registrationId,
    type: actionTypes.CORDOVA_REGISTER_NOTIFICATIONS_SUCCESS,
  }),
};

export default cordovaActions;
