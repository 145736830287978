import { List } from 'immutable';

const getAllUsers = (state) => {
  const users = state.getIn(['entities', 'users']);
  const usersAdmin = state.getIn(['usersAdmin', 'usersList']);
  if (users?.isEmpty() || usersAdmin?.isEmpty()) {
    return List();
  }

  let userList = List();

  usersAdmin?.forEach((u) => {
    const usrFound = users.find((usr) => usr.get('id') === u);
    userList = userList.push(usrFound);
  });

  return userList;
};

export default getAllUsers;
