import buildCompositeController from '../../utils/buildCompositeController';
import { change } from 'redux-form/immutable';
import Immutable from 'immutable';

const mapStateToProps = (state, ownProps) => {
  const idForm = ownProps.id;
  const responsesMap = state.hasIn(['form', 'Form01', 'values', idForm])
    ? state.getIn(['form', 'Form01', 'values', idForm])
    : Immutable.Map();

  // Should we create a new reponse ?
  let indexResponse = responsesMap.size - 1;
  indexResponse = indexResponse < 0 ? 0 : indexResponse;
  const isNewResponse = !responsesMap.getIn([
    indexResponse - 1,
    'locationdeptrole',
  ]);

  return {
    formValues: responsesMap.getIn([
      indexResponse.toString(),
      'locationdeptrole',
    ]),
    indexResponse,
    isNewResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  change(input, value) {
    dispatch(change('Form01', input, value));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
