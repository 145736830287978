import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import { Transition } from 'semantic-ui-react';
import bubbleRadiusHelper from './bubbleRadiusHelper';
import { mix } from 'polished';

const rule = (props) => {
  const { theme } = props;

  const isFirstMessage = true;
  const isLastMessage = true;
  const isMe = false;

  return {
    ...bubbleRadiusHelper(isFirstMessage, isLastMessage, isMe, theme),
    backgroundColor: mix(
      0.02,
      theme.brandTenant.primaryColor,
      theme.colors.white
    ),
    borderColor: theme.brandTenant.primaryColor,
    borderStyle: theme.dashedBorderStyle,
    borderWidth: theme.dashedBorderWidth,
    maxWidth: '100%',
    minHeight: '80px',
    padding: theme.bubblePadding,
    textAlign: 'left',
  };
};

class ChatMessageBubbleDashed extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    className: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: void 0,
  };

  state = {};

  render() {
    const { children, className } = this.props;

    return (
      <Transition animation='fade' duration={500} transitionOnMount>
        <div className={className} data-component='ChatMessageBubbleDashed'>
          {children}
        </div>
      </Transition>
    );
  }
}

export default createComponent(rule, ChatMessageBubbleDashed, [
  'download',
  'hideAvatar',
  'isExpandable',
  'isFirstMessage',
  'isLastMessage',
  'isMe',
  'link',
  'onClick',
  'onDownload',
  'paramValues',
  'text',
  'typing',
  'userId',
]);
