import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Icon } from 'semantic-ui-react';
import { curry, memoize, noop } from 'lodash';

// Utils
import FilePickerUtils from '../../../../v1/utils/FilePickerUtils';

const rule = (props) => {
  const { theme, open } = props;

  return {
    ':before': {},
    '> label': {
      ':focus': {
        backgroundColor: theme.colors.white,
        boxShadow: 'none',
        outlineStyle: 'none',
      },
      ':hover': {
        backgroundColor: theme.colors.white,
        boxShadow: 'none',
        outlineStyle: 'none',
      },
      backgroundColor: 'transparent',
      borderColor: theme.colors.darkGray,
      borderStyle: 'solid',
      borderWidth: '0 0 0 1px',
      color: theme.colors.text,
      cursor: 'pointer',
      flexGrow: 1,
      height: theme.formHeight,
      padding: 0,
      textAlign: 'center',
      transitionDuration: `${theme.globals.transitionDuration}ms`,
      transitionProperty: 'background-color',
      transitionTimingFunction: theme.globals.transitionTimingFunction,
      width: '100%',
    },
    '> label > i:after': {
      content: '" "',
      display: 'inline-block',
      height: theme.formHeight,
      verticalAlign: 'middle',
    },
    backgroundColor: theme.colors.gray,
    display: 'flex',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: open ? 'translateX(0)' : 'translateX(100%)',
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'transform',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: `calc(100% - ${theme.formHeight})`,
    zIndex: 10,
  };
};

class ChatFormAttachments extends PureComponent {
  static propTypes = {
    allowedExtensions: PropTypes.string,
    caseTaskId: PropTypes.number, // passed directly. If passed then it's assumed this chat belongs to a case
    channelId: PropTypes.string,
    className: PropTypes.string.isRequired,
    enableOnlyQRScanner: PropTypes.bool,
    enableSubscribe: PropTypes.bool,
    handleQRScannned: PropTypes.func,
    onFileSelect: PropTypes.func,
    onOpenSubscribers: PropTypes.func,
    open: PropTypes.bool,
  };

  static defaultProps = {
    allowedExtensions: null,
    caseTaskId: void 0,
    channelId: '',
    enableOnlyQRScanner: false,
    enableSubscribe: false,
    handleQRScannned: noop,
    onFileSelect: noop,
    onOpenSubscribers: noop,
    open: false,
  };

  handleAttachFile = (e) => {
    const { onFileSelect } = this.props;
    const fileIndex = 0;
    const file = e.target.files[fileIndex];
    const regularFile = true; // Allow to know that we sent this image from a regular file picker
    onFileSelect(file, regularFile);
  };

  handleAttachImage = (e) => {
    const fileIndex = 0;
    const { onFileSelect } = this.props;
    const file = e.target.files[fileIndex];
    onFileSelect(file);
  };

  handleOpenSubscribers = (e) => {
    const { onOpenSubscribers, caseTaskId } = this.props;

    e.preventDefault();

    onOpenSubscribers(caseTaskId);
  };
  handleTakePhoto = (e) => {
    const { onFileSelect } = this.props;
    const fileIndex = 0;
    const file = e.target.files[fileIndex];
    onFileSelect(file);
  };

  handleClick(e) {
    e.target.value = null; // Reset input value
  }

  handleQRClick = () => {
    const { handleQRScannned } = this.props;

    if (window.cordova && window.cordova.plugins.barcodeScanner) {
      window.cordova.plugins.barcodeScanner.scan(
        (result) => {
          if (result.text) {
            handleQRScannned(result.text);
          }
        },
        (error) => {
          // eslint-disable-next-line no-console -- debugging
          console.error(error);
        },
        {
          // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
          disableAnimations: true,
          // iOS
          disableSuccessBeep: false, // iOS and Android
          preferFrontCamera: false,
          // Android, save scan history (default false)
          prompt: 'Place a barcode inside the scan area',
          // Android
          resultDisplayDuration: 500,
          // Android, launch with the torch switched on (if available)
          saveHistory: true,
          // iOS and Android
          showFlipCameraButton: true,
          // iOS and Android
          showTorchButton: true,
          // iOS and Android
          torchOn: false,
        }
      );
    }
  };

  onAttachmentChangeCordova = (imageData) => {
    const { onFileSelect } = this.props;

    window.resolveLocalFileSystemURL(
      imageData,
      (fileEntry) => {
        fileEntry.file((file) => {
          if (file) {
            onFileSelect(file);
          }
        });
      },
      () => {
        // error
        // console.warn('ERROR FILE',err);
      }
    );
  };

  handleTriggerInput = memoize(
    curry((type, e) => {
      e.preventDefault();
      FilePickerUtils.selectCameraOrImage(type, this.onAttachmentChangeCordova);
    })
  );

  render() {
    const {
      allowedExtensions,
      caseTaskId,
      channelId,
      className,
      enableOnlyQRScanner,
      enableSubscribe,
      open,
    } = this.props;

    const iconStyle = {
      marginRight: 0,
    };

    let displayFileAttachmentinIos = false;
    if (window.cordova && window.device.platform === 'iOS') {
      const deviceVersion = window.device.version;
      displayFileAttachmentinIos =
        deviceVersion && Number(deviceVersion.split('.')[0]) > 9;
    }

    return open ? (
      <div
        aria-label='Select attachment'
        className={className}
        data-component='ChatFormAttachments'
        data-open={open}
        role='menu'
      >
        {displayFileAttachmentinIos || !window.cordova ? (
          <label
            aria-label='Attachment'
            htmlFor={`uploadFieldId${channelId}`}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- this is used as interactive
            role='menuitem'
            tabIndex={0}
          >
            <Icon
              name='attach'
              size='large'
              style={{
                ...iconStyle,
                pointerEvents: 'none',
              }}
            />
            <input
              accept={allowedExtensions}
              id={`uploadFieldId${channelId}`}
              onChange={this.handleAttachFile}
              onClick={this.handleClick}
              ref={this.setRef}
              style={{
                display: 'none',
              }}
              type='file'
            />
          </label>
        ) : null}

        {window.cordova ? (
          !enableOnlyQRScanner ? (
            <>
              <label
                aria-label='Upload image'
                htmlFor={`uploadImage${channelId}`}
                onClick={this.handleTriggerInput('Image')}
                onKeyPress={this.handleTriggerInput('Image')}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- this is used as interactive
                role='menuitem'
                tabIndex={0}
              >
                <Icon
                  name='file image outline'
                  size='large'
                  style={iconStyle}
                />
                <input
                  id={`uploadImage${channelId}`}
                  onChange={this.handleAttachImage}
                  onClick={this.handleClick}
                  style={{
                    display: 'none',
                  }}
                  type='file'
                />
              </label>
              <label
                aria-label='Camera'
                htmlFor={`uploadTakePhoto${channelId}`}
                onClick={this.handleTriggerInput('Camera')}
                onKeyPress={this.handleTriggerInput('Camera')}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- this is used as interactive
                role='menuitem'
                tabIndex={0}
              >
                <Icon name='camera' size='large' style={iconStyle} />
                <input
                  id={`uploadTakePhoto${channelId}`}
                  onChange={this.handleTakePhoto}
                  onClick={this.handleClick}
                  style={{
                    display: 'none',
                  }}
                  type='file'
                />
              </label>
            </>
          ) : (
            <label
              aria-label='QR Code Scanner'
              htmlFor={`scanQrCode${channelId}`}
              onClick={this.handleQRClick}
              onKeyPress={this.handleQRClick}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- this is used as interactive
              role='menuitem'
              tabIndex={0}
            >
              <Icon name='qrcode' size='large' style={iconStyle} />
            </label>
          )
        ) : null}

        {enableSubscribe ? (
          <label
            aria-label='Subscribers'
            htmlFor={`openSubscribers${caseTaskId}`}
            onClick={this.handleOpenSubscribers}
            onKeyPress={this.handleOpenSubscribers}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- this is used as interactive
            role='menuitem'
            tabIndex={0}
          >
            <Icon name='user plus' size='large' style={iconStyle} />
          </label>
        ) : null}
      </div>
    ) : null;
  }
}

export default createComponent(rule, ChatFormAttachments, [
  'allowedExtensions',
  'caseTaskId',
  'channelId',
  'enableOnlyQRScanner',
  'enableSubscribe',
  'handleQRScannned',
  'onOpenSubscribers',
  'onFileSelect',
  'open',
]);
