import _ from 'lodash';

// Utils
import uiPathGenerator from './uiPathGenerator';
import browserHistory from './browserHistory';

/**
 * In cordova, this is the strategy:
 * 1. Open the login url an in-app browser window
 * 2. Wait until the sso challenge is passed, and our BE will redirect the url back to us (/auth/saml|oauth)
 * (this redirection will happen still in the in-app browser)
 * 3. Once the in-app browser window lands in those FE pages, we try to get the token information
 * 4. Bumb the token (or query string) into the cordova app scope and redirect the cordova app to (/auth/saml|oauth)
 * 5. The AuthPage component for  (/auth/saml|oauth) will know how to finish authenticating the user with Espressive
 * @param {string} externalLoginUri - sso provider
 */
const cordovaSSOLogin = (externalLoginUri) => {
  if (window.cordova.InAppBrowser) {
    let authToken = '';
    let sessionIndex = '';

    let browserRef = null;
    let queryParams = '';
    // Open an in-app nrowser window for the external login url
    const login = new Promise((resolve, reject) => {
      // added hardwareback=no:
      // "you must set it to no if you want the back button to simply close the InAppBrowser."
      browserRef = window.cordova.InAppBrowser.open(
        externalLoginUri,
        '_blank',
        'location=no,clearsessioncache=yes,clearcache=yes,hardwareback=no,cleardata=yes,footer=no,usewkwebview=yes'
      );

      // This will set a flag inside the web view opened in the in-app web browser
      // Will later be read by sesstionThunks to know whether this has been opened
      // from the native app
      browserRef.addEventListener('loadstart', () => {
        browserRef.executeScript({
          code: 'localStorage.setItem("isInAppBrowser", "true")',
        });
      });

      // waits until the external window lands on our auth route
      browserRef.addEventListener('loadstart', (event) => {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Load start');

        const currentInAppBrowserUrl = event.url;
        // By design, the route auth.saml will come from the backend with a localSotrage token conceled
        // eslint-disable-next-line no-console -- debugging
        console.warn('Current external window url', currentInAppBrowserUrl);
        if (
          _.includes(
            currentInAppBrowserUrl,
            uiPathGenerator.genPath('auth.saml')
          )
        ) {
          // SAML landing
          // eslint-disable-next-line no-console -- debugging
          console.warn('SAML landing');
          const refreshIntervalId = setInterval(() => {
            browserRef.executeScript(
              {
                code: 'localStorage.getItem("authToken")',
              },
              (res) => {
                [authToken] = res; // result of the evaluation of executeScript
                if (authToken !== null) {
                  // eslint-disable-next-line no-console -- debugging
                  console.warn('Got the token!');

                  clearInterval(refreshIntervalId); // we don't need to do this anymore at this point

                  // now get the session id (it might not exist)
                  browserRef.executeScript(
                    {
                      code: 'localStorage.getItem("session_index")',
                    },
                    (res2) => {
                      [sessionIndex] = res2;

                      if (authToken !== '') {
                        resolve('saml');
                      } else {
                        reject();
                      }
                    }
                  );
                } else {
                  // eslint-disable-next-line no-console -- debugging
                  console.warn('Failed to get attempt to get token');
                }
              }
            );
          }, 5); // had to repeat this constantly until the js in this loaded page executes the code to set up the token
        }
        // currentInAppBrowserUrl contains auth/oauth
        // but it doesn't contain redirect_uri as parameter
        else if (
          _.includes(
            currentInAppBrowserUrl,
            uiPathGenerator.genPath('auth.oauth')
          ) &&
          !_.includes(currentInAppBrowserUrl, 'redirect_uri')
        ) {
          // OAuth landing
          // eslint-disable-next-line no-console -- debugging
          console.warn('OAuth landing');
          queryParams = currentInAppBrowserUrl.substr(
            currentInAppBrowserUrl.indexOf('?') + 1
          );
          resolve('oauth');
        }
      });
    });

    login
      .then((method) => {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Login resolved with method', method);
        browserRef.close(); // Once we login, closing the in-app browser

        if (method === 'saml') {
          // Write down auth token to location storage first
          localStorage.setItem('authToken', authToken);
          localStorage.setItem('session_index', sessionIndex);

          // and move to saml authentication page
          browserHistory.replace(uiPathGenerator.genPath('auth.saml'));
        } else if (method === 'oauth') {
          // move to oauth authentication page appending the url query params
          browserHistory.replace(
            `${uiPathGenerator.genPath('auth.oauth')}?${queryParams}`
          );
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console -- debugging
        console.warn('Login promise failed - token do not exist');
        // The login promise only fail if token does not exist
        // the only thing we can assume for now is that the user is ainactive
        browserHistory.replace(uiPathGenerator.genPath('auth.inactive'));
      });
  }
};

const cordovaSSOLogout = (logoutUrl) => {
  const browserRef = window.cordova.InAppBrowser.open(
    logoutUrl,
    '_blank',
    'location=no, clearsessioncache=yes, clearcache=yes, hardwareback=no'
  );

  browserRef.addEventListener('loadstart', (event) => {
    // can be loadstart as we don't need it to finish loading
    const currentInAppBrowserUrl = event.url;
    if (currentInAppBrowserUrl.indexOf('logout?isLocalOnly') > -1) {
      browserRef.close(); // Once we login, closing the in-app browser
    }
  });
};

const SSOUtils = {
  loginExternally: (externalLoginUri) => {
    if (window.cordova) {
      // Cordova will open a in-app browser window with the exteral url
      cordovaSSOLogin(externalLoginUri);
    } else if (window.electron) {
      // electron will do its own thing opening a new window in the main node process
      // comunicates the renderer (this web app with electron main process via messages
      // eslint-disable-next-line no-console -- debugging
      console.log('Electron trying to login via sso to ', externalLoginUri);
      window.electronAPI.ipcSend('request-sso-login', externalLoginUri);
    } else {
      // Web version: simply navigate to the external url
      window.location = externalLoginUri;
    }
  },

  logoutExternallyWithHTML: (externalLogoutHtmlDoc) => {
    if (window.cordova) {
      const pageContentUrl = `data:text/html;base64,${btoa(
        externalLogoutHtmlDoc
      )}`;
      window.cordova.InAppBrowser.open(
        pageContentUrl,
        '_blank',
        'hidden=no,location=no'
      );
    } else if (window.electron) {
      window.electronAPI.ipcSend(
        'request-sso-logout-with-html',
        externalLogoutHtmlDoc
      );
    } else {
      // web version
      const newBrowserWindow = window.open('about:blank', '', 'esp_logout');
      newBrowserWindow.document.write(externalLogoutHtmlDoc);
      newBrowserWindow.document.close();
    }
  },

  logoutExternallyWithURL: (logoutUrl) => {
    if (window.cordova) {
      // Cordova will open a in-app browser window with the exteral url
      cordovaSSOLogout(logoutUrl);
    } else if (window.electron) {
      // electron will do its own thing opening a new window in the main node process
      // comunicates the renderer (this web app with electron main process via messages
      window.electronAPI.ipcSend('request-sso-logout-with-url', logoutUrl);
    } else {
      // Web version: simply navigate to the external url
      window.location = logoutUrl;
    }
  },
};

export default SSOUtils;
