import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form/immutable';
import _ from 'lodash';
import { intl } from 'esp-util-intl';

import FormInputText from './FormInputText';

class EditorWorkflowForm extends PureComponent {
  static propTypes = {
    formError: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formError: '',
  };

  render() {
    const { handleSubmit, formError } = this.props;

    return (
      <Form inverted onSubmit={handleSubmit}>
        <Field
          component={FormInputText}
          formError={formError}
          label={intl.formatMessage({
            id: 'label.workflow_name',
          })}
          name='name'
          placeholder={intl.formatMessage({
            id: 'label.my_workflow',
          })}
          showErrorsWithoutSubmitting
        />
      </Form>
    );
  }
}

const validateFormSync = (values) => {
  const errors = {};
  if (!values.get('name')) {
    errors.name = '';
  } else if (_.isEmpty(values.get('name', '').trim())) {
    errors.name = 'Please add a value';
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = {};
  if (ownProps.existingWorkflow) {
    initialValues.name = ownProps.existingWorkflow.name;
  }

  return {
    form: 'WorkflowForm',
    initialValues,
  };
};

// eslint-disable-next-line no-class-assign -- DEV-1526
EditorWorkflowForm = compose(
  connect(mapStateToProps),
  reduxForm({
    validate: validateFormSync,
  })
)(EditorWorkflowForm);

export default EditorWorkflowForm;
