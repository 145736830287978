import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { find } from 'lodash';

import TypeLogin, { TypeDefaults } from '../../../globals/blocks/TypePhone';
import CountryCodes from '../../../globals/CountryCodes';
import ValidatedPhone from '../../molecules/ValidatedPhone';
import BodyClassName from '../../../../globals/BodyClassName';

class PhoneNumber01 extends Component {
  static propTypes = {
    ...TypeLogin,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
  };

  static defaultProps = {
    ...TypeDefaults,
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
  };

  validate = (value) => {
    const { inputRequired } = this.props;

    if (!value) {
      return !inputRequired ? '' : 'error';
    }

    const countryIsoValue = value.get('country_iso');
    const phoneNumberValue = value.get('number');

    // If the input is not required and the phone value is empty,, don;t return an validation error

    if (!phoneNumberValue && !inputRequired) {
      return '';
    }

    let selectedCountry = find(
      CountryCodes,
      (country) => country.key === countryIsoValue
    );
    if (!selectedCountry) {
      const [country] = CountryCodes;
      selectedCountry = country;
    }

    // Check if the phone typed fit the selected mask length
    const maskSelected = selectedCountry.mask
      ? selectedCountry.mask.split('-').join('')
      : '';

    const phoneNumber = phoneNumberValue
      ? phoneNumberValue.split('-').join('')
      : '';
    const phoneVerified =
      countryIsoValue === 'US'
        ? phoneNumber && phoneNumber.length === maskSelected.length
        : phoneNumber.length > 0;

    return phoneVerified ? '' : 'error';
  };

  render() {
    const {
      errors,
      errorsApi,
      id,
      labelText,
      placeholder,
      readOnly,
      template,
      type,
    } = this.props;

    const apiError = errorsApi.find(
      (error) => error.get('parameterName') === id
    ); // Error API

    const formError = errors.get(`${id}`) || ''; // Error redux form validation
    const finalError =
      apiError && !apiError.isEmpty() ? apiError.get('message') : formError;

    return (
      <BodyClassName className={template}>
        <div className='block' data-blocktype={type} data-template={template}>
          <ValidatedPhone
            formError={finalError}
            label={labelText}
            name={id}
            placeholder={placeholder}
            readOnly={readOnly}
            validate={this.validate}
          />
        </div>
      </BodyClassName>
    );
  }
}

export default PhoneNumber01;
