// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import HelloActions from '../../utils/HelloActions';
// Selectors
import getCurrentUser from '../../selectors/getCurrentUser';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);

  let undefinedValue;
  const workflowName = state.hasIn(['editor', 'tasks', 'workflow', 'name'])
    ? state.getIn(['editor', 'tasks', 'workflow', 'name'])
    : undefinedValue;

  const tasks = state.hasIn(['editor', 'tasks', 'items'])
    ? state.getIn(['editor', 'tasks', 'items'])
    : undefinedValue;

  return {
    currentUser: currentUser && currentUser.toJS(),
    tasks,
    workflowName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  /**
   * Actions to be performed when editor unmounts
   */
  doEditorGoodbyeActions() {
    HelloActions.editorGoodbye(dispatch);
  },
  /**
   * Actions to be performed when editor loads
   */
  doEditorHelloActions() {
    HelloActions.editorHello(dispatch);
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
