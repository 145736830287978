import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getJobRolesByID = (state) => state.getIn(['entities', 'jobRoles']);

const getBundlesByID = (state) => state.getIn(['entities', 'bundles']);

const getJobRolesWithAvailableBundles = (jobRolesByID, bundlesByID) => {
  const jobRoleList = jobRolesByID
    .filter((jobRole) => {
      const availableBundlesIDs = jobRole.get('availableBundles');

      if (!availableBundlesIDs || availableBundlesIDs.isEmpty()) {
        return false; // Filter Job role with available Bundles)
      }

      let bundleAvailable = false;
      availableBundlesIDs.forEach((bundleID) => {
        if (bundlesByID.has(bundleID)) {
          bundleAvailable = true;
        }
      });

      return bundleAvailable; // Filter Job role with with Bundles)
    })
    .map((jobRole) => {
      const availableBundlesIDs = jobRole.get('availableBundles');
      let availableBundles = Immutable.List();

      availableBundlesIDs.forEach((bundleID) => {
        if (bundlesByID.has(bundleID)) {
          const bundle = bundlesByID.get(bundleID);
          availableBundles = availableBundles.push(bundle);
        }
      });

      return jobRole.set('availableBundles', availableBundles);
    })
    .toList();

  return jobRoleList ? jobRoleList : Immutable.List();
};

const selector = createSelector(
  [getJobRolesByID, getBundlesByID],
  getJobRolesWithAvailableBundles
);

export default selector;
