import actionTypes from './actionTypes';

const todoActions = {};

todoActions.loadCardByIdFailure = (error) => ({
  error,
  type: actionTypes.LOAD_CARD_BY_ID_FAIL,
});

todoActions.loadCardByIdStart = () => ({
  type: actionTypes.LOAD_CARD_BY_ID_START,
});

todoActions.loadCardByIdSuccess = (card) => ({
  card,
  type: actionTypes.LOAD_CARD_BY_ID_SUCCESS,
});

todoActions.loadCardByTypeAndAnnouncementIdFailure = (error) => ({
  error,
  type: actionTypes.LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_FAIL,
});

todoActions.loadCardByTypeAndAnnouncementIdStart = () => ({
  type: actionTypes.LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_START,
});

todoActions.loadCardByTypeAndAnnouncementIdSuccess = (card) => ({
  card,
  type: actionTypes.LOAD_CARD_BY_TYPE_AND_ANNOUNCEMENT_ID_SUCCESS,
});

todoActions.loadCardsStart = () => ({
  type: actionTypes.LOAD_CARDS,
});

todoActions.loadCardsSuccess = (cards, pagination, reset = false) => ({
  cards,
  pagination,
  reset,
  type: actionTypes.LOAD_CARDS_SUCCESS,
});

todoActions.loadCardsFailure = (error) => ({
  error,
  type: actionTypes.LOAD_CARDS_FAIL,
});

todoActions.dismissCardSuccess = (cardId) => ({
  cardId,
  type: actionTypes.DISMISS_CARD_SUCCESS,
});

todoActions.nudgeCardSuccess = (card) => ({
  card,
  type: actionTypes.NUDGE_CARD_SUCCESS,
});

todoActions.followCardSuccess = (card) => ({
  card,
  type: actionTypes.FOLLOW_CARD_SUCCESS,
});

todoActions.snoozeCardSuccess = (card) => ({
  card,
  type: actionTypes.SNOOZE_CARD_SUCCESS,
});

todoActions.unsubscribeCardSuccess = (cardId) => ({
  cardId,
  type: actionTypes.UNSUBSCRIBE_CARD_SUCCESS,
});

todoActions.cancelCardSuccess = (card) => ({
  card,
  type: actionTypes.CANCEL_CARD_SUCCESS,
});

todoActions.deleteCardSuccess = (cardId) => ({
  cardId,
  type: actionTypes.DELETE_CARD_SUCCESS,
});

todoActions.confirmCardSuccess = (cardId) => ({
  cardId,
  type: actionTypes.CONFIRM_CARD_SUCCESS,
});

todoActions.cardUpdate = (card) => ({
  card,
  type: actionTypes.SINGLE_CARD_UPDATE,
});

todoActions.cancelRequestCardSuccess = (cardId) => ({
  cardId,
  type: actionTypes.CANCEL_REQUEST_SUCCESS,
});

todoActions.toggleCardIsOpen = (cardId) => ({
  cardId,
  type: actionTypes.CARD_TOGGLE_CARD_OPEN,
});

todoActions.actionStart = (cardId) => ({
  cardId,
  type: actionTypes.CARD_ACTION_START,
});

todoActions.actionEnd = (cardId, error = null) => ({
  cardId,
  error,
  type: actionTypes.CARD_ACTION_END,
});

todoActions.updateHomeFilter = (homeFilters) => ({
  homeFilters,
  type: actionTypes.HOME_FILTER_UPDATE,
});

todoActions.resetCardsState = () => ({
  type: actionTypes.RESET_CARDS_STATE,
});

export default todoActions;
