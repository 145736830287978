import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import Immutable, { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';

import TenantBackgroundImage from '../../../../v2/components/functional/TenantBackgroundImage';
import WorkflowForm from '../../organisms/WorkflowForm';
import OnboardAuthPageController from '../../controllers/OnboardAuthPageController';
import { WorkflowProvider } from '../../../utils/WorkflowContext';
import BodyClassName from '../../../../globals/BodyClassName';

class OnboardAuthPage extends PureComponent {
  static propTypes = {
    // from React Router
    alternateSubmit: PropTypes.func.isRequired,
    blob: ImmutablePropTypes.map.isRequired,
    errors: ImmutablePropTypes.map,
    errorsApi: ImmutablePropTypes.list,
    formSyncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    getOnboardLoginActivity: PropTypes.func,
    initialValues: ImmutablePropTypes.map,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({}),
    }),
    loginBlock: ImmutablePropTypes.map,
    onboardLogin: PropTypes.func,
    params: PropTypes.shape({
      code: PropTypes.string,
      ref: PropTypes.string,
      taskID: PropTypes.string,
      workflowID: PropTypes.string,
    }).isRequired,
    progress: ImmutablePropTypes.map,
    workflowName: PropTypes.string,
  };

  static defaultProps = {
    errors: Immutable.Map(),
    errorsApi: Immutable.List(),
    formSyncErrors: null,
    getOnboardLoginActivity: noop,
    initialValues: Immutable.Map(),
    location: null,
    loginBlock: null,
    onboardLogin: noop,
    progress: noop,
    workflowName: '',
  };

  componentDidMount() {
    if (this.isRedirectPending) {
      return;
    }

    const { params, getOnboardLoginActivity } = this.props;

    const workflowID = Number(params.workflowID);

    const { ref, code } = params;

    getOnboardLoginActivity(workflowID, ref, code);
  }

  isRedirectPending = false;

  handleSubmit = (values) => {
    const { loginBlock, alternateSubmit, onboardLogin } = this.props;
    if (loginBlock) {
      alternateSubmit(values);
    } else {
      onboardLogin(values);
    }
  };

  getInitialValues = () => {
    const { loginBlock, initialValues } = this.props;

    if (loginBlock) {
      return fromJS({
        email: '',
        password: '',
      });
    }

    return initialValues;
  };

  render() {
    if (this.isRedirectPending) {
      return null;
    }

    const {
      blob,
      errors,
      errorsApi,
      formSyncErrors,
      isLoading,
      location,
      progress,
      params,
      workflowName,
    } = this.props;

    const nav = blob.get('nav');
    const blocks = blob.get('blocks');
    const workflowID = Number(params.workflowID);
    const workflowTaskID = Number(params.taskID);
    const { query } = location;

    return (
      <BodyClassName className='main-active workflow onboard'>
        <div className='esp-workflow'>
          <Helmet title={workflowName} />

          <TenantBackgroundImage />
          <WorkflowProvider value={query}>
            <WorkflowForm
              blocks={blocks.toJS()}
              errors={errors}
              errorsApi={errorsApi}
              formSyncErrors={formSyncErrors}
              initialValues={this.getInitialValues()}
              isLoading={isLoading}
              nav={nav.toJS()}
              onSubmit={this.handleSubmit}
              progress={progress}
              step={1}
              workflowID={workflowID}
              workflowTaskID={workflowTaskID}
            />
          </WorkflowProvider>
        </div>
      </BodyClassName>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
OnboardAuthPage = OnboardAuthPageController(OnboardAuthPage);

export default OnboardAuthPage;
