import tinycolor from 'tinycolor2';
import { UserAgentUtils } from 'esp-globals';

// Utils
import APIcall from './APIcall';
import endpointGenerator from './endpointGenerator';
import browserHistory from './browserHistory';

// Global store
import store from '../stores/store';
// Actions
import cordovaActions from '../actions/cordovaActions';
import appVersionActions from '../actions/appVersionActions';

// Selectors
import getTenantBranding from '../selectors/getTenantBranding';

import { first, has, max, min } from 'lodash';
import CardTypes from '../globals/CardTypes';

const ANDROID_SENDER_ID = 77856014229;
const CordovaUtils = {};

const getCardDetailPath = (path = '') => {
  const exec = RegExp(
    `\\/app\\/home\\/(${Object.values(CardTypes)
      .join('|')
      .toLowerCase()})\\/\\d+`
  ).exec(path);
  const isValid = Boolean(exec && exec.length);
  return {
    isValid,
    path: isValid ? first(exec) : null,
  };
};

let registered = false;
CordovaUtils.initPushNotifications = () => {
  if (window.cordova) {
    try {
      const push = window.PushNotification.init({
        android: {
          icon: 'statusbar',
          senderID: ANDROID_SENDER_ID,
        },
        ios: {
          alert: 'true',
          badge: 'true',
          sound: 'true',
        },
      });

      const { permissions } = window.cordova.plugins;

      // eslint-disable-next-line no-console -- debugging
      console.log(
        'platform',
        window.device.platform,
        'version',
        window.device.version
      );
      // if device and version is android 13 or higher, ask for permission
      if (
        window.device.platform === 'Android' &&
        window.device.version >= '13'
      ) {
        permissions.checkPermission(
          permissions.POST_NOTIFICATIONS,
          (status) => {
            // eslint-disable-next-line no-console -- debugging
            console.log(
              'hasPermission POST_NOTIFICATIONS',
              status.hasPermission
            );
            if (!status.hasPermission) {
              permissions.requestPermission(
                permissions.POST_NOTIFICATIONS,
                (status) => {
                  if (!status.hasPermission) {
                    // eslint-disable-next-line no-console -- debugging
                    console.warn('Permission not granted');
                  }
                },
                () => {
                  // eslint-disable-next-line no-console -- debugging
                  console.warn('Permission not granted');
                }
              );
            }
          }
        );
      }

      push.on('registration', (data) => {
        if (!registered) {
          // Fixed issue with registration even being triggered twice
          CordovaUtils.registerAPNS(data.registrationId);
          registered = true;
        }
      });

      push.on('notification', (data) => {
        // eslint-disable-next-line no-console -- debugging
        console.warn('notification data:', JSON.stringify(data, null, 4));

        let cardDetailPath;

        if (
          UserAgentUtils.isIOSApp() &&
          has(data, ['additionalData', 'data', 'url'])
        ) {
          cardDetailPath = getCardDetailPath(data.additionalData.data.url);
        } else {
          cardDetailPath = getCardDetailPath(data.additionalData.url);
        }

        if (!data.additionalData.foreground && cardDetailPath.isValid) {
          browserHistory.push({ pathname: cardDetailPath.path });
        }
        // data.message,
        // data.title,
        // data.count,
        // data.sound,
        // data.image,
        // data.additionalData
      });

      push.on('error', (e) => {
        // eslint-disable-next-line no-console -- debugging
        console.error(e.message);
      });
    } catch (e) {
      // eslint-disable-next-line no-console -- debugging
      console.error('Error initializing push notifications', e);
    }
  }
};
/**
 * Registers the device in the Apns Device Authorized List
 */
CordovaUtils.registerAPNS = (registrationId) => {
  // then register the information to be sent to the API
  const data = {
    active: true,
    name: 'whatever',
    registration_id: registrationId,
  };

  // different endpoints for iOS and Android
  let endpoint;
  if (window.device.platform === 'iOS') {
    // using cordova device plugin to detect platform
    endpoint = endpointGenerator.genPath('espUser.device.apns');
    data.device_id = null; // only use this one in APNS
  } else {
    // Android
    endpoint = endpointGenerator.genPath('espUser.device.gcm');
    data.cloud_message_type = 'FCM';
  }

  APIcall.post({
    data: data,
    preventShowError: true,
    success() {
      store.dispatch(
        cordovaActions.registerNotificationsSuccess(registrationId)
      );
    },
    token: true,
    url: endpoint,
  });
};

CordovaUtils.deRegisterAPNS = () =>
  new Promise((resolve, reject) => {
    const registrationId = store
      .getState()
      .getIn(['cordova', 'registrationId']);
    if (registrationId) {
      let endpoint;
      if (window.device.platform === 'iOS') {
        // using cordova device plugin to detect platform
        endpoint = endpointGenerator.genPath('espUser.device.apns.instance', {
          registrationID: registrationId,
        });
      } else {
        // Android
        endpoint = endpointGenerator.genPath('espUser.device.gcm.instance', {
          registrationID: registrationId,
        });
      }

      APIcall.delete({
        error(e) {
          reject(e);
        },
        preventShowError: true,
        success() {
          registered = false;
          resolve();
        },
        token: true,
        url: endpoint,
      });
    } else {
      reject(new Error('No registration id'));
    }
  });

CordovaUtils.lockScreenRotation = () => {
  if (window.cordova) {
    window.plugins.screensize.get(({ height, width }) => {
      const minSize = min([height, width]);
      const maxSize = max([height, width]);
      const aspectRatio = minSize / maxSize;

      // this is what we determined to be a phone vs tablet
      const deviceAspectCoeficient = 0.68;
      const isSmallScreen = aspectRatio < deviceAspectCoeficient;

      // Only for iOS we know for sure if something is a table
      let isTablet;
      if (UserAgentUtils.isIOSApp()) {
        isTablet = UserAgentUtils.isIpad();
      } else {
        // Androids we'll use the coeficient
        isTablet = isSmallScreen;
      }

      // DEV-11092: enable landscape in iPad
      // DEV-11273: disable landscape in iPhone
      // DEV-12396 Adding tablet
      if (!isTablet) {
        window.screen.orientation.lock('portrait');
      }
    });
  }
};

CordovaUtils.setStatusBarColor = () => {
  if (window.cordova && window.StatusBar) {
    const tenantBranding = getTenantBranding(store.getState());
    const tenantHeaderColor = tenantBranding.get('tenantHeaderColor');

    if (tenantHeaderColor) {
      const areBlackIconsReadable = tinycolor.isReadable(
        '#000',
        tenantHeaderColor
      );

      if (!areBlackIconsReadable) {
        // if it's not readable
        if (tinycolor.isLight(tenantHeaderColor)) {
          // Use the default statusbar (dark text, for light backgrounds).
          window.StatusBar.styleDefault();
        } else {
          // Use the blackTranslucent statusbar (light text, for dark backgrounds).
          window.StatusBar.styleBlackTranslucent();
        }
      } else {
        window.StatusBar.styleLightContent();
      }
    } else {
      window.StatusBar.styleDefault();
    }
  }
};

CordovaUtils.retrieveAppVersion = () => {
  if (window.cordova && window.cordova.getAppVersion) {
    window.cordova.getAppVersion.getVersionNumber().then((version) => {
      store.dispatch(appVersionActions.setNativeMobileVersion(version));
    });
  }
};

CordovaUtils.setDeepLinkListener = () => {
  // eslint-disable-next-line no-console -- debugging
  console.warn('Setting deeplinks');

  if (window.cordova && window.universalLinks) {
    // The event name 'launchedAppFromDeepLink' is as declared in the config.xml <universal-links>
    window.universalLinks.subscribe('launchedAppFromDeepLink', (eventData) => {
      // eventData contents are documented in https://github.com/e-imaxina/cordova-plugin-deeplinks#application-launch-handling
      const { url } = eventData;

      // eslint-disable-next-line no-console -- debugging
      console.warn(`Did launch application from the link: ${url}`);

      // Because eventData gives us the path and params separately
      // this seems to be the best way to get all the contents
      // e.g. /app/cases?table=kb_knowledge&number=KB0000136&sys…6db30035312120047a2da86a11c083b&title=Beneficiary

      const [, fullPath] = url.split('espressive.com');

      // eslint-disable-next-line no-console -- debugging
      console.warn(`browserHistory.replace: ${fullPath}`);

      // Moving the native app into the right place
      browserHistory.replace(fullPath);
    });
  }
};
export { getCardDetailPath };
export default CordovaUtils;
