import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';

// Controllers
import SendDownloadAppSMSBlockController from '../../controllers/SendDownloadAppSMSBlockController';

// Molecules
import SendDownloadAppSMS from '../../molecules/SendDownloadAppSMS';

// Globals
import AppOS from '../../../globals/AppOS';
import AppUrls from '../../../globals/AppUrls';
import TypeSendDownloadAppSMS, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSendDownloadAppSMS';

class SendDownloadAppSMS01 extends Component {
  static propTypes = {
    ...TypeSendDownloadAppSMS,
    globals: ImmutablePropTypes.map,
    onSendDownloadAppSMS: PropTypes.func,
    selectedOS: PropTypes.string,
  };

  static defaultProps = {
    ...TypeDefaults,
    globals: Immutable.Map(),
    onSendDownloadAppSMS: _.noop,
    selectedOS: null,
  };

  componentDidMount() {
    const { setAlternateSubmit } = this.props;
    setAlternateSubmit(this.sendDownloadAppSMS);
  }

  validate(selectedOS) {
    if (!selectedOS) {
      return 'No OS selected.';
    }

    return void 0;
  }

  sendDownloadAppSMS = (onMoveWorkflowNextTask) => {
    const { selectedOS, onSendDownloadAppSMS, globals } = this.props;

    const appUrl =
      selectedOS === AppOS.ANDROID ? AppUrls.androidUrl : AppUrls.iosUrl;
    const content = `You may download the Espressive Barista app to your device, which ${globals.getIn(
      ['tenant', 'name']
    )} uses as our employee app. Click below to download. ${appUrl}`;

    onSendDownloadAppSMS(content.trim(), onMoveWorkflowNextTask);
  };

  render() {
    const { template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Field
          component={SendDownloadAppSMS}
          name='selectedOS'
          validate={this.validate}
        />
      </div>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
SendDownloadAppSMS01 = SendDownloadAppSMSBlockController(SendDownloadAppSMS01);

export default SendDownloadAppSMS01;
