const api_workflow_v0_1_condition_147 = {
  conditions: null,
  description: 'Condition d3414a8b-d76d-48dd-b719-8c7dabc2ba75',
  eid: 'f367d7f9-3d19-427b-9a76-5d3160ed0997',
  id: 200,
  object_instances: null,
  ordering: 0,
  success_workflow: null,
  success_workflow_task:
    'https://release.qa.espressive.com/api/workflow/v0.1/workflowtask/173/',
  url: 'https://release.qa.espressive.com/api/workflow/v0.1/condition/147/',
  workflow_condition_set: '',
};

export default api_workflow_v0_1_condition_147;
