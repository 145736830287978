import { getOptionsObjects, messageEval } from '../utils';

const selectEval = (msg) => Boolean(msg?.metadata?.select);
const optionsEval = (msg) => Boolean(msg?.metadata?.user_input?.select);
const selectSpecialEval = (msg) =>
  Boolean(msg?.metadata?.user_input?.select_special);

const select = {
  evaluate: (msg) =>
    messageEval(msg) &&
    (optionsEval(msg) || selectEval(msg) || selectSpecialEval(msg)),
  transform: (msg) =>
    getOptionsObjects(
      msg?.metadata?.select ||
        msg?.metadata?.user_input?.select ||
        msg?.metadata?.user_input?.select_special
    ),
};

const selectEvals = {
  options: optionsEval,
  select: selectEval,
  selectSpecial: selectSpecialEval,
};

export { selectEvals };
export default select;
