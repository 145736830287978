// These numbers returns the milliseconds per time period
// 15 minutes  =       900 000 ms
// 30 minutes  =     1 800 000 ms
// 4 hours     =    14 400 000 ms
// 24 hours    =    86 400 000 ms
// 7 days      =   604 800 000 ms
// 30 days     = 2 592 000 000 ms

const TimePeriods = {
  LAST_15_MINUTES: 900000,
  LAST_24_HOURS: 86400000,
  LAST_30_DAYS: 2592000000,
  LAST_30_MINUTES: 1800000,
  LAST_4_HOURS: 14400000,
  LAST_7_DAYS: 604800000,
};
export default TimePeriods;
