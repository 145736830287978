import { createSelector } from 'reselect';
import buildFAQResponses from '../utils/buildFAQResponses';

const getFAQList = (state) => state.getIn(['adminFaqs', 'faqsList']);

const getFAQEID = (state, faqEID) => faqEID;

const getFAQByEID = createSelector(
  [getFAQList, getFAQEID],
  (faqList, faqEID) => {
    const faqSelected = faqList.find((entry) => entry.get('eid') === faqEID);
    return faqSelected;
  }
);

/**
 * Get only trigger from a selected FAQ
 */
const getTriggersFAQByEID = createSelector(
  [getFAQByEID],
  (faqSelected) => faqSelected && faqSelected.get('triggers')
);

export { getTriggersFAQByEID };

/**
 * Get the map of response with the condition from an FAQ
 */
export default createSelector([getFAQByEID], (faqSelected) => {
  const responses = buildFAQResponses(faqSelected);

  return responses.faq_response;
});

export { getFAQByEID };
