import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@iconify/react';

const propTypes = {
  /** Fitted, without space to left or right of Icon. (SUIR prop) */
  fitted: PropTypes.bool,
  /** Name of the icon. */
  name: PropTypes.string.isRequired,
  /** Size of the icon. (SUIR prop) */
  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'large',
    'big',
    'huge',
    'massive',
  ]),
  /** React style object. */ style: PropTypes.shape({}),
};

const EspReactIcon = ({
  name,
  fitted = void 0,
  size = void 0,
  style = void 0,
}) => {
  return <Icon fitted={fitted} icon={name} size={size} style={style} />;
};

EspReactIcon.propTypes = propTypes;

export default EspReactIcon;
