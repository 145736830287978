import buildCompositeController from '../../utils/buildCompositeController';
import sessionThunks from '../../actions/sessionThunks';
import getCurrentUser from '../../selectors/getCurrentUser';
import getDelegators from '../../selectors/getDelegators';
// Util
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';

const mapStateToProps = (state) => {
  const selectedUser = getCurrentUser(state);

  return {
    delegators: getDelegators(state),
    user: selectedUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  impersonateUser(userId) {
    dispatch(sessionThunks.impersonateUser(userId)).then(() => {
      const url = uiPathGenerator.genPath('app');
      browserHistory.push(url);
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
