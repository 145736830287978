import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { noop } from 'lodash';
import { intl } from 'esp-util-intl';
// Molecules
import UserImageContainer from './UserImageContainer';

// Controllers
import UserDataController from '../controllers/UserDataController';

// globals
import EspUserPropShape from '../../globals/EspUserPropShape';

class EspAboutMe extends PureComponent {
  static propTypes = {
    loadUserData: PropTypes.func, // from the controller
    userData: EspUserPropShape, // object containing the user data,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired, // prop used to initialize this component
  };

  static defaultProps = {
    loadUserData: noop,
    userData: null,
  };

  componentDidMount() {
    const { userId, loadUserData, userData } = this.props;
    if (userData === null) {
      loadUserData(userId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userId, loadUserData } = this.props;
    // If user ID changes, we need to load new data
    if (nextProps.userId !== userId) {
      loadUserData(nextProps.userId);
    }
  }

  render() {
    const { userData, userId } = this.props;

    const aboutMeContent =
      userData && userData.get('about_me')
        ? userData.get('about_me')
        : intl.formatMessage({
            id: 'message.no_intro_msg',
          });

    return (
      <div className='esp-about-me clearfix'>
        {userData ? <UserImageContainer avatar thumb userId={userId} /> : null}
        {userData ? <div className='content'>{aboutMeContent}</div> : null}
      </div>
    );
  }
}

const EspAboutMeTest = EspAboutMe;

// eslint-disable-next-line no-class-assign -- DEV-1526
EspAboutMe = UserDataController(EspAboutMe);
export { EspAboutMeTest };
export default EspAboutMe;
