import taskActions from './taskActions';
import APIcall from '../../../../app/js/v1/utils/APIcall';
import endpointGenerator from '../../../../app/js/v1/utils/endpointGenerator';
import _ from 'lodash';
import async from 'async';
import defaultFrontendBlob from '../utils/defaultFrontendBlob';
import { v4 as uuidv4 } from 'uuid';
import { fromJS } from 'immutable';
import { setSubmitSucceeded } from 'redux-form/immutable';

/**
 * Create a new condition group resource in the server.
 *
 *
 * @param name Name for the condition group.
 * @param taskUrl Url of the task to point to.
 * @param cb Callback function(error, conditionGroup, condition), where conditionGroup and condition
 * are immutable.
 */
const createConditionGroupToTask = () => (taskUrl, cb) => {
  async.waterfall(
    [
      // 1. Create the condition group
      (next) => {
        const name = `Condition Group ${uuidv4()}`;

        APIcall.post({
          data: {
            name,
          },
          error: (err) => {
            next(err.response.body);
          },
          success: (res) => {
            const conditionGroup = fromJS(res.body);
            next(null, conditionGroup);
          },
          token: true,
          url: endpointGenerator.genPath('workflow.conditionSets'),
        });
      },

      // 2. Create the condition
      (conditionGroup, next) => {
        const description = `Condition ${uuidv4()}`;
        APIcall.post({
          data: {
            description,
            success_workflow_task: taskUrl,
            workflow_condition_set: conditionGroup.get('url'),
          },
          error(err) {
            next(err.response.body);
          },
          success(res) {
            const condition = fromJS(res.body);
            next(null, conditionGroup, condition);
          },
          token: true,
          url: endpointGenerator.genPath('workflow.conditions'),
        });
      },
    ],
    cb
  );
};

/**
 * Delete conditions List from URL array
 * @param conditionURLArray
 */
const deleteConditions = (conditionURLArray) =>
  new Promise((resolve, reject) => {
    async.eachOf(
      conditionURLArray,
      (url, key, done) => {
        APIcall.delete({
          error(err) {
            done(err);
          },
          success() {
            done();
          },
          token: true,
          url: url,
        });
      },
      (err) => {
        if (!err) {
          resolve();
        } else {
          reject(err);
        }
      }
    );
  });

/**
 * Add new condition from array
 * @param conditionToAdd
 */
const addConditions = (conditionToAdd, conditionSetURL) =>
  new Promise((resolve, reject) => {
    async.eachOf(
      conditionToAdd,
      (condition, key, done) => {
        const description = `Condition ${uuidv4()}`;

        // Build the Object Instances
        const object_instances =
          condition.object_instances && condition.object_instances.length > 0
            ? {}
            : null;
        if (object_instances) {
          condition.object_instances.forEach((obj, i) => {
            object_instances[i] = obj;
            if (!object_instances[i].object_filter) {
              object_instances[i].object_filter = {};
            }
            if (!object_instances[i].app_label) {
              object_instances[i].app_label = null;
            }
          });
        }

        APIcall.post({
          data: {
            conditions: condition.conditions,
            description: condition.description || description,
            object_instances,
            ordering: condition.ordering,
            success_workflow: condition.success_workflow,
            success_workflow_task: condition.success_workflow_task,
            workflow_condition_set: conditionSetURL,
          },
          error(err) {
            done(err);
          },
          success() {
            done();
          },
          token: true,
          url: endpointGenerator.genPath('workflow.conditions'),
        });
      },
      (err) => {
        if (!err) {
          resolve();
        } else {
          reject(err);
        }
      }
    );
  });

/**
 * Update existing conditions from array
 * @param conditionToAdd
 */
const updateConditions = (conditionArray, conditionSetURL) =>
  new Promise((resolve, reject) => {
    async.eachOf(
      conditionArray,
      (condition, key, done) => {
        // Build the Object Instances
        const object_instances =
          condition.object_instances && condition.object_instances.length > 0
            ? {}
            : null;
        if (object_instances) {
          condition.object_instances.forEach((obj, i) => {
            object_instances[i] = obj;
            if (!object_instances[i].object_filter) {
              object_instances[i].object_filter = {};
            }
            if (!object_instances[i].app_label) {
              object_instances[i].app_label = null;
            }
          });
        }

        APIcall.patch({
          data: {
            conditions: condition.conditions,
            description: condition.description || '',
            object_instances,
            ordering: condition.ordering,
            success_workflow: condition.success_workflow,
            success_workflow_task: condition.success_workflow_task,
            workflow_condition_set: conditionSetURL,
          },
          error(err) {
            done(err);
          },
          success() {
            done();
          },
          token: true,
          url: condition.url,
        });
      },
      (err) => {
        if (!err) {
          resolve();
        } else {
          reject(err);
        }
      }
    );
  });

/**
 * Set a condition group as post_condition_group for a workflow or task.
 *
 * @param traversable an Immutable workflow or task.
 * @param cb Callback function(error)
 */
const setConditionGroup = () => (traversable, conditionGroup, cb) => {
  APIcall.patch({
    data: {
      post_condition: conditionGroup.get('url'),
    },
    error(err) {
      cb(err.response.body);
    },
    success() {
      cb(null);
    },
    token: true,
    url: traversable.get('url'),
  });
};

/**
 * Deletes single condition resource in the server.
 *
 * @param conditionUrl URL string of the condition to delete.
 * @param cb Callback function(error).
 */
const removeConditionByUrl = () => (conditionUrl, cb) => {
  APIcall.del({
    error(error) {
      cb(error.response.body);
    },
    success() {
      cb(null);
    },
    token: true,
    url: conditionUrl,
  });
};

/**
 * Deletes single condition group resource in the server.
 *
 * @param conditionGroupUrl URL string of the condition group to delete.
 * @param cb Callback function(error).
 */
const removeConditionGroupByUrl = () => (conditionGroupUrl, cb) => {
  APIcall.del({
    error(error) {
      cb(error.response.body);
    },
    success() {
      cb(null);
    },
    token: true,
    url: conditionGroupUrl,
  });
};

/**
 * Deletes single task resource in the server.
 *
 * @param taskUrl URL string of the task to delete.
 * @param cb Callback function(error).
 */
const removeTaskByUrl = () => (taskUrl, cb) => {
  APIcall.del({
    error(err) {
      cb(err.response.body);
    },
    success() {
      cb(null);
    },
    token: true,
    url: taskUrl,
  });
};

/**
 * Deletes condition group and all conditions attached to it in the server.
 *
 * @param conditionGroup The condition group to delete.
 * @param cb Callback function(error).
 */
const deepRemoveConditionGroup = () => (conditionGroup, cb) => {
  const conditions = conditionGroup.get('condition_entry');

  async.each(
    conditions,
    (condition, done) => {
      const conditionUrl = condition.get('url');
      removeConditionByUrl()(conditionUrl, done);
    },
    (error) => {
      if (error) {
        cb(error);
      } else {
        const conditionGroupUrl = conditionGroup.get('url');
        removeConditionGroupByUrl()(conditionGroupUrl, cb);
      }
    }
  );
};

/**
 * Deletes task, related condition group (if any) and all conditions attached to it (if any) in the server.
 *
 * @param The task to delete.
 * @param cb Callback function(error).
 */
const deepRemoveTask = () => (task, cb) => {
  const taskUrl = task.get('url');
  const conditionGroup = task.get('post_condition_group');

  removeTaskByUrl()(taskUrl, (error) => {
    if (error) {
      cb(error);
    } else {
      if (conditionGroup) {
        deepRemoveConditionGroup()(conditionGroup, cb);
      } else {
        cb(null);
      }
    }
  });
};

/**
 * Make task specified by nextTaskUrl be the next after traversable.
 *
 *
 * @param traversable A workflow or a task resource
 * @param nextTaskUrl URL of a single task resource or null
 * @param cb Callback function(error).
 */
const setNextTask = () => (traversable, nextTaskUrl, cb) => {
  const conditionGroup = traversable.get('post_condition_group');
  if (conditionGroup && nextTaskUrl) {
    // there is an existing condition group, so just need to patch
    // the first condition in it to set the next task
    const firstCondition = conditionGroup.get('condition_entry').first();
    const conditionUrl = firstCondition.get('url');

    APIcall.patch({
      data: {
        success_workflow_task: nextTaskUrl,
      },
      error(error) {
        cb(error.response.body);
      },
      success() {
        cb(null);
      },
      token: true,
      url: conditionUrl,
    });
  } else if (conditionGroup && !nextTaskUrl) {
    // remove reference to condition group and then delete it
    const traversableUrl = traversable.get('url');

    APIcall.patch({
      data: {
        post_condition: null,
      },
      error(error) {
        cb(error.response.body);
      },
      success() {
        deepRemoveConditionGroup()(conditionGroup, cb);
      },
      token: true,
      url: traversableUrl,
    });
  } else if (!conditionGroup && nextTaskUrl) {
    // create a new condition group with a condition pointing to the next task
    // and set it to the traversable
    createConditionGroupToTask()(nextTaskUrl, (error, conditionGroup) => {
      if (error) {
        cb(error);
      } else {
        setConditionGroup()(traversable, conditionGroup, cb);
      }
    });
  } else {
    // there is no condition group but also no task to set as next,
    // so we are done
    cb(null);
  }
};

const taskThunks = {};
taskThunks.getTasks = (workflowID, callback) => (dispatch) => {
  // trigger loading state
  dispatch(taskActions.getTasksStart());

  async.waterfall(
    [
      // 1. Get individual workflow as its the entry point to the graph
      (next) => {
        const url = endpointGenerator.genPath('workflow.workflows.instance', {
          workflowID,
        });

        APIcall.get({
          error(err) {
            next(err.response.body);
          },
          success(res) {
            const workflow = res.body;
            next(null, workflow);
          },
          token: true,
          url,
        });
      },

      // 2. Recursively traverse tasks to get a flatten list of them
      // workflow|task -> post_condition_group -> condition_entry -> success_workflow_task
      // recursion should end when we reach post_condition_group === null
      (workflow, next) => {
        const tasks = [];

        const traverse = (traversable) => {
          const post_condition_group =
            (traversable && traversable.post_condition_group) || null;

          if (
            !post_condition_group ||
            (post_condition_group.condition_entry &&
              !post_condition_group.condition_entry.length)
          ) {
            // that's it! we reached the end!
            next(null, workflow, tasks);
          } else {
            const { condition_entry } = post_condition_group;

            // TODO we are oversimplifing here and assuming the graph has a linked
            // list like structure

            const [condition] = condition_entry;
            const nextTaskUrl = condition.success_workflow_task;

            APIcall.get({
              error(err) {
                next(err.response.body);
              },
              success(res) {
                const task = res.body;
                tasks.push(task);
                traverse(task);
              },
              token: true,
              url: nextTaskUrl,
            });
          }
        };

        traverse(workflow);
      },
    ],
    (error, workflow, tasks) => {
      if (error) {
        dispatch(taskActions.getTasksFailure(error));
      } else {
        // Normalize the task object inputValue if multiple input or not
        tasks.forEach((task) => {
          const frontend_blob = (task && task.frontend_blob) || {
            blocks: [],
          };
          frontend_blob.blocks.forEach((block) => {
            if (_.isArray(block.inputValue)) {
              const inputValue = {};
              block.inputValue.forEach((block, i) => {
                inputValue[`map${i}`] = block.map;
                inputValue[`value${i}`] = block.value;
                if (block.mapDefault) {
                  inputValue[`mapDefault${i}`] = block.mapDefault;
                }
              });
              block.inputValue = inputValue;
            }
          });
        });
        const newTask = tasks.filter((t) => t);

        dispatch(taskActions.getTasksSuccess(workflow, newTask));
        if (typeof callback === 'function') {
          callback();
        }
      }
    }
  );
  // handle all the logic related
};

taskThunks.loadConditionSet = (taskId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (!taskId) {
      reject('Error - no task Id has been passed');
    }

    const state = getState();
    let conditionSetURL, currentEditingTask;

    if (state.hasIn(['editor', 'tasks', 'items'])) {
      currentEditingTask = state
        .getIn(['editor', 'tasks', 'items'])
        .find((task) => task.get('id') === taskId);
      conditionSetURL = currentEditingTask
        ? currentEditingTask.get('related_workflow_condition') ||
          currentEditingTask.getIn(['post_condition_group', 'url'])
        : null;
    }

    if (conditionSetURL) {
      dispatch(taskActions.loadConditionsSetStart());

      APIcall.get({
        error(error) {
          dispatch(taskActions.loadConditionsSetFail());
          reject(error);
        },
        success(res) {
          dispatch(taskActions.loadConditionsSetSuccess(res.body));
          resolve();
        },
        token: true,
        url: conditionSetURL,
      });
    }
  });

taskThunks.newTaskArrangement = () => (dispatch, getState) => {
  const state = getState();
  const items = state.getIn(['editor', 'tasks', 'items']).toJS();
  const newItem = items.shift();
  items.push(newItem);
  dispatch(taskActions.saveTasks(items));
};

taskThunks.saveTasksRearrangement = (callback) => (dispatch, getState) => {
  const state = getState();

  const workflow = state.getIn(['editor', 'tasks', 'workflow']);
  const items = state.getIn(['editor', 'tasks', 'items']);

  dispatch(taskActions.startSavingTasks());

  async.series(
    [
      // 1. Update next pointer of each task in parallel
      (next) => {
        async.eachOf(
          items,
          (task, i, done) => {
            let nextTaskUrl = null;

            if (i < items.size - 1) {
              const nextTask = items.get(i + 1);
              nextTaskUrl = nextTask.get('url');
              setNextTask()(task, nextTaskUrl, done);
            }
          },
          next
        );
      },

      // 2. Make workflow point to what is now the first task
      (next) => {
        // const conditionGroup = workflow.get('post_condition_group');
        // const firstCondition = conditionGroup.get('condition_entry').first();
        const firstTask = items.first();
        const firstTaskUrl = firstTask.get('url');

        setNextTask()(workflow, firstTaskUrl, next);
      },
    ],
    (error) => {
      if (error) {
        // TODO not sure how to handle this error yet!
        // console.log(error);
      } else {
        // retrieve tasks of current workflow to get updated data from server

        dispatch(taskThunks.getTasks(workflow.get('id'), callback));
      }
    }
  );
};

/**
 * Saves the task mark as current in the state
 *
 */
taskThunks.saveTask = (taskId) => (dispatch, getState) => {
  const state = getState();

  // the object we'll send to the server
  const task = state.getIn(['editor', 'currentEditingTask']);
  if (task.get('id') === taskId) {
    const frontendBlob = task.get('frontend_blob').toJS();

    const scratch = state.getIn(['form', 'TaskEditor']);

    // Convert final blocks to support multiple input with array on
    // simple input with object
    // And set BE scratch condition name if exists
    frontendBlob.blocks.forEach((block) => {
      if (block.inputValue) {
        const inputValue = [];
        Object.keys(block.inputValue).forEach((key) => {
          if (key !== 'map' && key[0] !== 'v' && !key.match('mapDefault')) {
            const position = key[key.length - 1];
            inputValue[position] = {
              map: block.inputValue[key],
              value: block.inputValue[`value${position}`] || '',
            };

            if (block.inputValue[`mapDefault${position}`]) {
              inputValue[position].mapDefault =
                block.inputValue[`mapDefault${position}`];
            }
          }
        });
        block.inputValue =
          inputValue.length > 0 ? inputValue : block.inputValue;
      }

      const scratchData = scratch.getIn([block.id, 'values', 'scratch']);
      if (
        scratchData &&
        !scratchData.isEmpty() &&
        scratchData.get('isScratch')
      ) {
        // Use BE Scratch for WF condition
        block.scratchCondition = {
          name: scratchData.get('conditionName'),
        };
      }
    });

    dispatch(taskActions.startSaveTask(task));
    APIcall.patch({
      data: {
        frontend_blob: frontendBlob,
      },
      error(err) {
        dispatch(taskActions.getSaveTaskFailure(err.body));
      },
      success(res) {
        const task = res.body;
        dispatch(taskActions.getSaveTaskSuccess(task));
      },
      token: true,
      url: task.get('url'),
    });
  } else {
    const errorMsg = `Cannot save, taskId ${taskId} not found`;
    dispatch(taskActions.getSaveTaskFailure(errorMsg));
  }
};

/**
 * Save / Update Condition for Task and ConditionSet
 * @param taskID
 */
taskThunks.saveConditions = (taskID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();

    const task = state.getIn(['editor', 'currentEditingTask']);
    const conditionSetURL = state.getIn([
      'editor',
      'tasks',
      'conditionSet',
      'url',
    ]);
    const conditions = state.getIn([
      'editor',
      'tasks',
      'conditionSet',
      'condition_entry',
    ]);

    const failure_workflow_task = state.hasIn([
      'form',
      'ConditionForm',
      'values',
      'failure_workflow_task',
    ])
      ? state.getIn([
          'form',
          'ConditionForm',
          'values',
          'failure_workflow_task',
        ])
      : null;

    const failure_workflow = state.hasIn([
      'form',
      'ConditionForm',
      'values',
      'failure_workflow',
    ])
      ? state.getIn(['form', 'ConditionForm', 'values', 'failure_workflow'])
      : null;

    const conditionName = state.hasIn([
      'form',
      'ConditionForm',
      'values',
      'name',
    ])
      ? state.getIn(['form', 'ConditionForm', 'values', 'name'])
      : null;

    let newConditions = state.hasIn([
      'form',
      'ConditionForm',
      'values',
      'condition_entry',
    ])
      ? state.getIn(['form', 'ConditionForm', 'values', 'condition_entry'])
      : null;

    const conditionToDelete = [];
    const conditionToAdd = [];

    if (!newConditions || task.get('id') !== taskID) {
      reject('Error - This task ID doesnt exist or there is no condition set');
      return;
    }

    if (conditions.size > newConditions.size) {
      // Some conditions have been deleted
      conditions.forEach((cond) => {
        if (
          newConditions.findIndex(
            (newCond) => newCond.get('eid') === cond.get('eid')
          ) === -1
        ) {
          conditionToDelete.push(cond.get('url'));
        }
      });
    } else if (conditions.size < newConditions.size) {
      // Some new conditions have been added
      newConditions = newConditions.filter((newCond) => {
        if (
          conditions.findIndex(
            (cond) => cond.get('eid') === newCond.get('eid')
          ) === -1
        ) {
          conditionToAdd.push(newCond.toJS());
          return false;
        }
        return true;
      });
    }

    dispatch(taskActions.loadConditionsSetStart());

    async.waterfall(
      [
        // 1 . Delete condition which need to be deleted
        (next) => {
          if (conditionToDelete.length > 0) {
            deleteConditions(conditionToDelete)
              .then(() => next())
              .catch((err) => next(err));
          } else {
            // Nothing to delete
            next();
          }
        },

        // 2 . Add new Conditions
        (next) => {
          if (conditionToAdd.length > 0) {
            addConditions(conditionToAdd, conditionSetURL)
              .then(() => next())
              .catch((err) => next(err));
          } else {
            // Nothing to add
            next();
          }
        },

        // 3 . Update the current Conditions
        (next) => {
          if (conditions.isEmpty()) {
            next(); // this should NOT happen
          } else {
            updateConditions(newConditions.toJS())
              .then(() => next())
              .catch((err) => next(err));
          }
        },

        // 4 . Update the Final Condition Set
        (next) => {
          const data = {
            failure_workflow: failure_workflow,
            failure_workflow_task: failure_workflow_task,
            name: conditionName,
          };

          APIcall.patch({
            data,
            error(err) {
              next(err);
            },
            success(res) {
              next(null, res.body);
            },
            token: true,
            url: conditionSetURL,
          });
        },
      ],
      (error, conditionSetUpdated) => {
        if (error) {
          dispatch(taskActions.loadConditionsSetFail());
          reject(error);
        } else {
          dispatch(taskActions.loadConditionsSetSuccess(conditionSetUpdated));
          dispatch(setSubmitSucceeded('ConditionForm'));
          resolve();
        }
      }
    );

    resolve();
  });

taskThunks.createFinalCondition = (taskID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(taskActions.loadConditionsSetStart());

    async.waterfall(
      [
        // 1 - Create new condition Set
        (next) => {
          const name = `Condition Group ${uuidv4()}`;
          APIcall.post({
            data: {
              name,
            },
            error: (err) => {
              next(err.response.body);
            },
            success: (res) => {
              next(null, res.body);
            },
            token: true,
            url: endpointGenerator.genPath('workflow.conditionSets'),
          });
        },

        // 2 - Set this new condition to the current task
        (conditionSet, next) => {
          const url = endpointGenerator.genPath('workflow.tasks.instance', {
            taskID,
          });

          APIcall.patch({
            data: {
              post_condition: conditionSet.url,
            },
            error(err) {
              next(err.response.body);
            },
            success(res) {
              next(null, res.body, conditionSet);
            },
            token: true,
            url,
          });
        },
      ],
      (err, newTask, conditionSet) => {
        if (err) {
          reject(err);
        } else {
          dispatch(taskActions.getSaveTaskSuccess(newTask));
          dispatch(taskActions.loadConditionsSetSuccess(conditionSet));
          resolve();
        }
      }
    );
  });

/**
 * Add a task with the given name to the specified workflow.
 * @param name
 * @param workflow
 */
taskThunks.addTask = (name, workflow, onAddTask = _.noop) => (dispatch) => {
  // const state = getState();

  let firstTaskUrl = null;

  const workflowConditionGroup = workflow.get('post_condition_group');

  if (workflowConditionGroup) {
    const firstCondition = workflowConditionGroup
      .get('condition_entry')
      .first();
    firstTaskUrl = firstCondition.get('success_workflow_task');
  }

  dispatch(taskActions.addTaskStart());

  async.waterfall(
    [
      // 1. Create the new task
      (next) => {
        const frontendBlob = defaultFrontendBlob.toJS();

        const url = endpointGenerator.genPath('workflow.tasks');

        APIcall.post({
          data: {
            frontend_blob: frontendBlob,
            name,
          },
          error(err) {
            next(err.response.body);
          },
          success(res) {
            const newTask = fromJS(res.body);
            next(null, newTask);
          },
          token: true,
          url,
        });
      },

      // 2. If the workflow is not empty, make the new task point to the current first task
      (newTask, next) => {
        if (firstTaskUrl) {
          // TODO Set a name to the condition group
          createConditionGroupToTask()(
            firstTaskUrl,
            (error, conditionGroup) => {
              if (error) {
                next(error);
              } else {
                setConditionGroup()(newTask, conditionGroup, (error) => {
                  if (error) {
                    next(error);
                  } else {
                    next(null, newTask);
                  }
                });
              }
            }
          );
        } else {
          // nothing to do if the workflow is empty
          next(null, newTask);
        }
      },

      // 3. Make the workflow point to the newly created task as the first one
      (newTask, next) => {
        if (workflowConditionGroup) {
          // ok, we already have a link to a task, so lets just update it
          const firstCondition = workflowConditionGroup
            .get('condition_entry')
            .first();

          APIcall.patch({
            data: {
              success_workflow_task: newTask.get('url'),
            },
            error(err) {
              next(err.response.body);
            },
            success() {
              next(null, newTask);
            },
            token: true,
            url: firstCondition.get('url'),
          });
        } else {
          // lets create a link to the newly created task
          createConditionGroupToTask()(
            newTask.get('url'),
            (error, conditionGroup) => {
              if (error) {
                next(error);
              } else {
                setConditionGroup()(workflow, conditionGroup, (error) => {
                  if (error) {
                    next(error);
                  } else {
                    next(null, newTask);
                  }
                });
              }
            }
          );
        }
      },
      (newTask, next) => {
        onAddTask(null, newTask.toJS());

        dispatch(taskActions.addTaskSuccess());

        // retrieve tasks of current workflow to get updated data from server
        dispatch(
          taskThunks.getTasks(workflow.get('id'), () => {
            next(null, newTask);
          })
        );
      },
      (newTask, next) => {
        dispatch(taskThunks.newTaskArrangement());
        next(null, newTask);
      },
      (newTask, next) => {
        dispatch(taskThunks.saveTasksRearrangement(() => next(null, newTask)));
      },
    ],
    (error) => {
      if (error) {
        onAddTask(error);

        dispatch(taskActions.addTaskFailure(error));
      }
    }
  );
};

taskThunks.removeTask = (taskId, onRemoveTask = _.noop) => (
  dispatch,
  getState
) => {
  const state = getState();

  const tasksState = state.get('editor').get('tasks');

  const tasksList = tasksState.get('items');

  const taskIndex = tasksList.findIndex((task) => task.get('id') === taskId);
  const taskToDelete = tasksList.get(taskIndex);

  const workflow = tasksState.get('workflow');

  // who goes before the task we want to delete?
  let prevTraversable = workflow;

  if (taskIndex > 0) {
    // the previous traversable is a task instead a workflow
    prevTraversable = tasksList.get(taskIndex - 1);
  }

  // who goes after the task we want to delete?
  let nextTaskUrl = null;

  if (taskIndex < tasksList.size - 1) {
    const nextTask = tasksList.get(taskIndex + 1);
    nextTaskUrl = nextTask.get('url');
  }

  // let the Redux store know the async stuff began
  dispatch(taskActions.removeTaskStart());

  async.series(
    [
      // 1. "Jump" the task to delete, make the previous traversable (workflow or task)
      // point to the one after the one we want to delete
      (next) => {
        setNextTask()(prevTraversable, nextTaskUrl, next);
      },

      // 2. "Garbage collect" the deleted task
      (next) => {
        deepRemoveTask()(taskToDelete, next);
      },
    ],
    (error) => {
      if (error) {
        // let the caller know there was an error
        onRemoveTask(error);

        // let the Redux store know there was an error
        dispatch(taskActions.removeTaskFailure(error));
      } else {
        onRemoveTask(null);

        dispatch(taskActions.removeTaskSuccess());

        // retrieve tasks of current workflow to get updated data from server
        dispatch(taskThunks.getTasks(workflow.get('id')));
      }
    }
  );
};

taskThunks.addAttributeToTask = () => (dispatch, getState) => {
  const state = getState();

  const attributesValues = state.getIn(['form', 'AddAttributeForm', 'values']);

  const attrAppLabel = attributesValues.get('model');
  const attrName = attributesValues.get('name');
  const attrAction = attributesValues.get('action');
  const attrObject = attributesValues.get('object');
  const attrKey = attributesValues.get('attrKey');
  const attrStorage = attributesValues.get('storage');
  const attrIsObject = attributesValues.get('is_object');

  const task = state.getIn(['editor', 'currentEditingTask']);
  const taskAttributes = task.get('attributes') || fromJS({});

  let taskAttributesModified;

  // find if existing task with current attribute
  const existingAttr = taskAttributes.find((attr, id) => attrKey === id);
  if (existingAttr) {
    taskAttributesModified = taskAttributes
      .setIn([attrKey, 'name'], attrName)
      .setIn([attrKey, 'label'], attrName)
      .setIn([attrKey, 'app_label'], attrAppLabel)
      .setIn([attrKey, 'action'], attrAction)
      .setIn([attrKey, 'object'], attrObject)
      .setIn([attrKey, 'attribute_value'], attrIsObject ? {} : '')
      .setIn([attrKey, 'storage'], attrStorage);
  } else {
    // Getting the last ID
    const maxIndex =
      taskAttributes
        .keySeq()
        .toList()
        .maxBy((key) => Number(key)) || 0;
    const maxIndexPlusOne = Number(maxIndex) + 1;
    taskAttributesModified = taskAttributes.set(
      maxIndexPlusOne,
      fromJS({
        action: attrAction,
        app_label: attrAppLabel,
        attribute_value: attrIsObject ? {} : '',
        label: attrName,
        mandatory: true,
        name: attrName,
        object: attrObject,
        object_filter: {}, // Pass an empty object if this attribute has been set to an object
        storage: attrStorage,
      })
    );
  }

  const dataToSend = {
    attributes: taskAttributesModified.toJS(),
  };

  dispatch(taskActions.startSaveTask(task));
  APIcall.patch({
    data: dataToSend,
    error: (err) => {
      dispatch(taskActions.getSaveTaskFailure(err.body));
    },
    success: (res) => {
      const task = res.body;
      dispatch(taskActions.getSaveTaskSuccess(task));
    },
    token: true,
    url: task.get('url'),
  });
};

/**
 * Remove one attribute from the current editing Task
 * @param attributeName {string} Name of the attribute
 */
taskThunks.removeAttributeToTask = (attributeName, attributeObject) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    if (!attributeName || !attributeObject) {
      reject('Error - No attribute name or Object passed');
    }

    const state = getState();
    const task = state.getIn(['editor', 'currentEditingTask']);

    const attributes = task
      .get('attributes')
      .filter(
        (att) =>
          att.get('name') !== attributeName ||
          att.get('object') !== attributeObject
      );

    const dataToSend = {
      attributes: attributes.toJS(),
    };

    dispatch(taskActions.startSaveTask(task));

    APIcall.patch({
      data: dataToSend,
      error: (err) => {
        dispatch(taskActions.getSaveTaskFailure(err.body));
        reject();
      },
      success: (res) => {
        const task = res.body;
        dispatch(taskActions.getSaveTaskSuccess(task));
        resolve();
      },
      token: true,
      url: task.get('url'),
    });
  });

taskThunks.loadObjectMapping = () => (dispatch) => {
  const url = endpointGenerator.genPath('workflow.objectMappings');

  APIcall.get({
    error(err) {
      dispatch(taskActions.loadObjectMappingFail(err.body));
    },
    success(res) {
      const objmap = res.body.results;
      dispatch(taskActions.loadObjectMappingSuccess(objmap));
    },
    token: true,
    url,
  });
};

taskThunks.updateTaskName = (task, name) => (dispatch) => {
  dispatch(taskActions.updateTaskName(name));
  dispatch(taskActions.startSaveTask(task));
  APIcall.patch({
    data: {
      name: name,
    },
    error(err) {
      dispatch(taskActions.getSaveTaskFailure(err.body));
    },
    success(res) {
      const task = res.body;
      dispatch(taskActions.getSaveTaskSuccess(task));
    },
    token: true,
    url: task.get('url'),
  });
};

export default taskThunks;
