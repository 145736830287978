import Immutable, { fromJS } from 'immutable';

/**
 * Merge two immutable Map / List
 * @param a
 * @param b
 * @returns {*}
 */
const deepMerge = (a, b) => {
  if (a && (Immutable.List.isList(a) || Immutable.Map.isMap(a))) {
    return a.map((aa, k) => {
      if (
        b &&
        b.has(k) &&
        (Immutable.List.isList(b.get(k)) || Immutable.Map.isMap(b.get(k)))
      ) {
        return deepMerge(aa, b.get(k));
      } else if (b && b.has(k)) {
        return b.get(k);
      }
      return aa;
    });
  }
  return b || a;
};

/**
 * Merge method to merge the new obj created
 * to edit an FAQ with a selected FAQ
 * @param objectFaq {object}
 * @param selectedFAQ {Immutable Map}
 * @returns {Immutable Map}
 */
const mergeFAQUpdate = (objectFaq, selectedFAQ) => {
  let newSelectedFAQ = null;

  // Need to deep merge objectFAQ in selectedFAQ
  const newObj = fromJS(objectFaq); // Need to delete that since it' not the smae format anymore
  newSelectedFAQ = deepMerge(selectedFAQ, newObj);
  const checkFor = ['attributes', 'triggers', 'answers'];

  // Add new entries not added by by deep merging (the one with missing eid)
  newSelectedFAQ = newSelectedFAQ.map((faq, key) => {
    if (checkFor.find((k) => k === key)) {
      const obj = newObj.get(key);
      if (!obj) {
        return faq;
      }
      return obj.map((ob, ke) => {
        if (key === 'answers') {
          // Check for new lines
          return ob.map((o, k) => {
            if (k === 'lines') {
              return o.map((line, num) => {
                if (!faq.hasIn([ke, k, num])) {
                  return line;
                }
                if (faq.getIn([ke, k, num, 'url'])) {
                  return faq.getIn([ke, k, num]).delete('phrase').delete('url');
                }
                return faq.getIn([ke, k, num]);
              });
            } else if (!faq.hasIn([ke, k])) {
              return o;
            }
            return faq.getIn([ke, k]);
          });
        } else if (!faq.has(ke)) {
          return ob;
        }
        return faq.get(ke);
      });
    }
    return faq;
  });

  // Filter empty attribute with no value set
  let newAttribute = newSelectedFAQ
    .get('attributes')
    .filter((att) => att.get('value'));

  // Set correct eid for each attribute
  const oldAttributes = selectedFAQ.get('attributes');
  newAttribute = newAttribute.map((att) => {
    const oldAtr = oldAttributes.find(
      (atr) => atr.get('name') === att.get('name')
    );
    if (oldAtr && !oldAtr.isEmpty()) {
      return att.set('eid', oldAtr.get('eid'));
    } else {
      return att.delete('eid');
    }
  });
  newSelectedFAQ = newSelectedFAQ.set('attributes', newAttribute);

  // Remove URL on text response
  const newAnswers = newSelectedFAQ
    .get('answers')
    .map((answer) => {
      if (!answer.get('eid')) {
        return answer.delete('eid');
      }
      return answer;
    })
    .map((answer, i) => {
      const newLines = newObj
        .get('answers')
        .getIn([i, 'lines'])
        .map((line) => {
          if (line.get('url')) {
            // It's an attachment, we have to delete the phrase and url property
            return line.delete('phrase').delete('url');
          }
          if (!line.get('eid')) {
            return line.delete('eid');
          }
          return line.delete('url');
        });
      return answer.set('lines', newLines);
    });

  newSelectedFAQ = newSelectedFAQ.set('answers', newAnswers);

  return newSelectedFAQ
    .delete('application_name') // Remove the application name when we update an FAQ
    .delete('name'); // Remove the intent name when we update an FAQ
};

export default mergeFAQUpdate;
