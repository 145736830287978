import APIcall from '../../../../../utils/APIcall';

const getLastSync = (lastSyncURL) => {
  return APIcall.get({
    token: true,
    url: lastSyncURL,
  });
};

export default getLastSync;
