import { INPUT_DATE_FORMAT, INPUT_TIME_FORMAT } from './DefaultInputFormats';
import doesBrowserSupportsDateField from '../v2/utils/doesBrowserSupportsDateFields';

const GetDefaultPlaceholderByInputType = (type) => {
  const finalPlaceholder = void 0;

  switch (type) {
    case 'date':
      if (!doesBrowserSupportsDateField()) {
        return INPUT_DATE_FORMAT;
      }
      break;
    case 'time':
      if (!doesBrowserSupportsDateField()) {
        return INPUT_TIME_FORMAT;
      }
      break;

    default:
      return finalPlaceholder;
  }
  return finalPlaceholder;
};

export default GetDefaultPlaceholderByInputType;
