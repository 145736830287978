import PropTypes from 'prop-types';

import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from 'semantic-ui-react';
import BodyClassName from '../../globals/BodyClassName';

const propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  header: PropTypes.node,
  helmet: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    base: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    bodyAttributes: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    htmlAttributes: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    link: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    meta: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    noscript: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    script: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    style: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    title: PropTypes.object,
  }),
  title: PropTypes.string,
};

const defaultProps = {
  bodyClassName: '',
  header: null,
  helmet: {},
  title: '',
};

const Page = ({ bodyClassName, title, helmet, header, children }) => {
  return (
    // BodyClassName expect to have one child only,
    // make sure don't break this invariant
    <BodyClassName className={bodyClassName}>
      <Grid container>
        <Helmet title={title} {...helmet} />
        {header}
        {children}
      </Grid>
    </BodyClassName>
  );
};
Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
