import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { Dropdown, Grid, Header, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import Immutable from 'immutable';

// Molecules
import FileUploadForm from '../../../../molecules/FileUploadForm';
// Controllers
import AdminUsersController from '../../../../controllers/AdminUsersController';
// Utils
import IntegrationCSVTools from '../../../../../utils/IntegrationCSVTools';

class IntegrationCSVUpload extends PureComponent {
  static propTypes = {
    businessList: ImmutablePropTypes.list,
    handleUploadCSV: PropTypes.func,
    isLoadingFile: PropTypes.bool,
    loadBusinessObjectList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    businessList: Immutable.List(),
    handleUploadCSV: _.noop,
    isLoadingFile: false,
  };

  state = {
    error: false,
    typeSelected: null,
  };

  componentDidMount() {
    const { loadBusinessObjectList } = this.props;

    loadBusinessObjectList();
  }

  handleChangeType = (e, v) => {
    e.preventDefault();
    const { typeSelected } = this.state;

    if (typeSelected !== v.value) {
      this.setState({
        error: false,
        typeSelected: v.value,
      });
    }
  };

  handleSubmit = (file) => {
    const { typeSelected } = this.state;
    const { handleUploadCSV } = this.props;

    if (typeSelected) {
      this.setState({
        error: false,
      });

      handleUploadCSV(file, typeSelected);
    } else {
      this.setState({
        error: true,
      });
    }
  };

  render() {
    const { businessList, isLoadingFile } = this.props;
    const { typeSelected, error } = this.state;

    const optionTypes = IntegrationCSVTools.buildOption(businessList);

    return (
      <Grid as={Segment} attached='bottom' padded>
        <Grid.Row columns='equal'>
          <Grid.Column>
            <Header as='h5' content='Select a business object type' />
            <Dropdown
              error={error}
              fluid
              onChange={this.handleChangeType}
              options={optionTypes}
              placeholder='Select a type'
              selection
            />
          </Grid.Column>
          <Grid.Column>
            <Header as='h5' content='Select a CSV file' />
            <FileUploadForm
              disabled={Boolean(!typeSelected)}
              isLoading={isLoadingFile}
              onFileSubmit={this.handleSubmit}
              uploadFieldId='users_csv'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export const IntegrationCSVUploadTest = IntegrationCSVUpload;

export default AdminUsersController(IntegrationCSVUpload);
