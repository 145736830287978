import Immutable from 'immutable';
import { createSelector } from 'reselect';

const idSelected = (state, idList) => idList;

const getAllUsers = (state) => state.getIn(['entities', 'users']);

const getSearchUser = (idSelected, users) => {
  if (!users || users.isEmpty()) {
    return Immutable.List();
  }

  let newList = Immutable.fromJS([]);

  idSelected.forEach((id) => {
    const usr = users.get(Number(id));
    if (usr && !usr.isEmpty()) {
      newList = newList.push(
        usr
          .set(
            'display_value',
            `${usr.get('nickname')} ${usr.get('last_name')}`
          )
          .set('id', usr.get('id'))
      );
    }
  });

  return newList;
};

const selector = createSelector([idSelected, getAllUsers], getSearchUser);

export default selector;
