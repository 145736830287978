import APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const removeTenantImage = (tenantImageID) => {
  return APIcall.del({
    token: true,
    url: endpointGenerator.genPath('espImage.tenantImages.instance', {
      tenantImageID,
    }),
  });
};

export default removeTenantImage;
