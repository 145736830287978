import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

const propTypes = {
  content: PropTypes.string.isRequired,
  size: PropTypes.string,
  style: PropTypes.shape({}),
};
const defaultProps = {
  size: 'medium',
  style: {},
};

const SectionHeader = ({ content, size, style }) => (
  <Segment
    style={{
      margin: '24px 0px 12px 0px',
      padding: '0px',
      ...style,
    }}
    vertical
  >
    <Header size={size} style={{ marginBottom: '3px' }}>
      {content}
    </Header>
  </Segment>
);

SectionHeader.propTypes = propTypes;

SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
