import React from 'react';
import { Helmet } from 'react-helmet';
import TaskPicker from './TaskPicker';
import BlockPresenter from './BlockPresenter';
import IntlKeyEditor from './IntlKeyEditor';
import BodyClassName from '../../../../globals/BodyClassName';

class IntlPage extends React.PureComponent {
  render() {
    return (
      <BodyClassName className='intl-page'>
        <>
          <Helmet
            style={[
              {
                cssText:
                  'html {height: auto !important;} body {background-color: #eaeaea;} .intl-page {overflow: auto !important; position: initial !important;}',
                type: 'text/css',
              },
            ]}
            title='WF Intl'
          />

          <TaskPicker />

          <BlockPresenter />

          <IntlKeyEditor />
        </>
      </BodyClassName>
    );
  }
}

export default IntlPage;
