import buildCompositeController from '../../utils/buildCompositeController';
import tenantThunks from '../../actions/tenantThunks';
import toastNotificationsActions from '../../actions/toastNotificationsActions';

/** Array of file type accepted in the tenant upload file form */
const typeFilesAccepted = [
  '.csv',
  '.xls',
  '.xlsx',
  '.jpeg',
  '.png',
  '.jpg',
  '.xml',
];

const mapStateToProps = (state) => ({
  isLoading: state.getIn(['tenant', 'files', 'isLoading']),
  typeFilesAccepted: typeFilesAccepted.join(', '),
});

const mapDispatchToProps = (dispatch) => ({
  submitFile(file, intl) {
    const { type } = file;
    const nameFile = file.name;
    let fileAccepted = false;

    typeFilesAccepted.forEach((img) => {
      if (type.match(img) || nameFile.match(img)) {
        fileAccepted = true;
      }
    });

    if (fileAccepted) {
      dispatch(tenantThunks.uploadTenantFile(file)).then(() => {
        const title = intl.formatMessage({
          id: 'label.success',
        });
        const message = intl.formatMessage({
          id: 'message.file_uploaded',
        });

        dispatch(
          toastNotificationsActions.success({
            message,
            title,
          })
        );
      });
    } else {
      const title = intl.formatMessage({
        id: 'label.error',
      });
      const message = intl.formatMessage({
        id: 'message.file_not_supported',
      });

      dispatch(
        toastNotificationsActions.error({
          message,
          title,
        })
      );
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
