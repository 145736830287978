import React from 'react';
import { Form } from 'semantic-ui-react';
import pt from 'prop-types';

const propTypes = {
  label: pt.string,
  url: pt.string,
};

const AzureSSOWithSAML = ({ label, url }) => {
  return (
    <Form.Field>
      <label>{label}</label>
      <p>{url}</p>
    </Form.Field>
  );
};

AzureSSOWithSAML.propTypes = propTypes;

export default AzureSSOWithSAML;
