import plurals from 'make-plural';

// Utils
import utils from './utils';
import intlShapes from './intlShapes';

// Globals
import { language } from './intlProvider';

const intl = {};

intl.formatMessage = (args) => {
  if (intlShapes.isPluralMessage(args)) {
    const { id, value } = args;
    const pluralType = plurals[language](value);
    const pluralID = `${id}.${pluralType}`;
    const message = utils.getMessage(pluralID);

    if (intlShapes.isCompoundMessage(args)) {
      const intlMessage = utils.getCompoundMessage(message, args.values);
      return intlMessage ? intlMessage : args.id;
    }

    return message ? message : args.id;
  } else if (intlShapes.isCompoundMessage(args)) {
    const { id, values } = args;
    const message = utils.getMessage(id);
    const intlMessage = utils.getCompoundMessage(message, values);
    return intlMessage ? intlMessage : args.id;
  } else if (intlShapes.isRegularMessage(args)) {
    const { id } = args;
    const message = utils.getMessage(id);
    return message ? message : args.id;
  }

  throw new TypeError('A message must be provided');
};

intl.prepareKey = (key = '') => {
  if (typeof key !== 'string') {
    throw new TypeError('The "key" must be a string');
  }
  return key.toLowerCase().toLowerCase().replace(/ /g, '_');
};

export default intl;
