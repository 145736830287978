import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, throttle } from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { imagesResizeTools } from 'esp-globals';

// Globals
import ChannelTypes from '../../../../v1/globals/ChannelTypes';
// Display
import ChatForm from '../../display/ChatForm';
// Controllers
import ChatInputController from './ChatInputController';

class ChatInput extends PureComponent {
  static propTypes = {
    /** Allowed Extentions for attachment */
    allowedExtensions: PropTypes.string,

    /** Results fetched from APi for selector. From Controller */

    apiMetaResults: ImmutablePropTypes.list,

    /** If this should be focused by default.  Passed down by component */
    autoFocus: PropTypes.bool,

    /** Message sent by barista if this is from a metadata object */
    baristaMessage: ImmutablePropTypes.map,

    /** passed directly. If passed then it's assumed this chat belongs to a case **/
    caseTaskId: PropTypes.number,

    /** Channel ID. Passed down by componen */
    channelId: PropTypes.string,

    /** Type of channel. Passed down by component */
    channelType: PropTypes.oneOf([
      ChannelTypes.DIRECT_CHANNEL,
      ChannelTypes.SCOPED_CHANNEL,
      ChannelTypes.SUPPORT_CHANNEL,
    ]).isRequired,

    /** Current user. From controller */
    currentUserID: PropTypes.number.isRequired,

    /** Any message tha tmay appear in the text area by default.  Passed down by component */
    defaultMessage: PropTypes.string,

    /** If it should be read only.  Passed down by component */
    disabled: PropTypes.bool,

    /** Function to pull more selection results.  Passed by Controller */
    getApiMetaResults: PropTypes.func,

    isLoadingAttachment: PropTypes.bool,

    /** Whether we're currently fetching options or typing ahead. From Controller */
    isLoadingOptions: PropTypes.bool,

    // if the card type is not equals to TASK, we need to hide the add subscriber button

    isTask: PropTypes.bool,

    /** Sends mark as read chat operation. Passed directly */

    markAsRead: PropTypes.func,

    /** Opens modal to pick case subscribers. From controller */

    onOpenSubscribers: PropTypes.func.isRequired,

    /** Callback when a message is sent. Passed down by component */
    onSendMessage: PropTypes.func,

    /** Sends dropdown option selection. Passed directly */

    onUserSelection: PropTypes.func,

    // Custom placeholder for the input area

    placeholder: PropTypes.string,

    /** Callback when QR code is scanned. Passed down by Controller */
    proccessQRCode: PropTypes.func,

    /** Sends attachment file data. From controller */
    sendAttachment: PropTypes.func.isRequired,

    /** Sends message. From controller */
    sendMessage: PropTypes.func.isRequired,

    /** Send toast error msg */
    sendMessageError: PropTypes.func,

    /** Sends actions about user typing. From controller */
    sendUserTyping: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowedExtensions: null,
    apiMetaResults: null,
    autoFocus: true,
    baristaMessage: null,
    caseTaskId: null,
    channelId: null,
    defaultMessage: '',
    disabled: false,
    getApiMetaResults: noop,
    isLoadingAttachment: false,
    isLoadingOptions: false,
    isTask: false,
    markAsRead: noop,
    onSendMessage: noop,
    onUserSelection: noop,
    placeholder: '',
    proccessQRCode: noop,
    sendMessageError: noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      attachmentFileName: '',
      attachmentImgSrc: '',
      currentSelectOption: null,
      file: null,
      fileName: null,
      haveISentAMessage: false,
      loadFile: false,
      regularFile: false,
      searchTerm: '',
    };

    // This function will be called at most once every 400 ms
    this.triggerTyping = throttle(this.triggerTyping.bind(this), 400);
  }

  onHandleChange = () => {
    this.triggerTyping(); // throttled function
  };

  triggerTyping() {
    const { channelId, channelType, currentUserID, sendUserTyping } =
      this.props;

    sendUserTyping({
      channelID: channelId,
      channelType: channelType,
      currentUserID: currentUserID,
    });
  }

  handleOptionSend = (option) => {
    const { onUserSelection, markAsRead } = this.props;
    onUserSelection(option, 'select');
    // Leave this so QA can debug and see what's happening
    // eslint-disable-next-line no-console -- debugging
    console.log('Sending selection', option);
    // mark as reads when this happens
    this.setState({
      currentSelectOption: null, // reset this
    });

    markAsRead();
  };
  handleKeyDown(e) {
    const { disabled } = this.props;

    if (!disabled) {
      // if (e.keyCode === 13 && e.ctrlKey &&) { //send on Enter+ctrl
      if (e.keyCode === 13 && !e.shiftKey) {
        // send on Enter (but not  on enter+shift)
        this.handleSend(e);
      }
    }
  }

  onSendFunction = (inputText, selectedOptionId = null) => {
    const { baristaMessage, onSendMessage, sendAttachment, sendMessage } =
      this.props;

    const {
      file,
      fileName,
      attachmentFileName,
      attachmentImgSrc,
      regularFile,
      currentSelectOption,
      haveISentAMessage,
    } = this.state;

    if (file && (attachmentImgSrc || attachmentFileName)) {
      const isImage = !attachmentFileName && attachmentImgSrc && !regularFile;

      sendAttachment(file, fileName, isImage);

      this.setState({
        attachmentFileName: '',
        attachmentImgSrc: '',
        file: null,
        fileName: null,
        regularFile: false,
      });
    }
    // send is being done on a barista input dropdown
    else if (
      (selectedOptionId || selectedOptionId === 0) &&
      baristaMessage &&
      baristaMessage.hasIn(['metadata', 'default_options'])
    ) {
      const selectedOption = currentSelectOption;

      if (selectedOption) {
        this.handleOptionSend(selectedOption);
      }
    } else {
      sendMessage({
        isFirstMessage: !haveISentAMessage,
        message: inputText,
      });

      onSendMessage(inputText);
    }
  };

  handleFileSelect = (file, regularFile) => {
    const { sendMessageError } = this.props;
    // Set Limitation to 25mb
    if (file?.size / 1000000 > 25) {
      sendMessageError('The file cannot be larger than 25MB');
      return;
    }
    if (file) {
      this.setState(
        {
          loadFile: true,
        },
        () => {
          this.selectFile(file, regularFile);
        }
      );
    }
  };

  selectFile = (file, regularFile) => {
    const reader = new FileReader();

    // Load image
    reader.onloadend = (e) => {
      // Check if it's a file or an image
      let name =
        file.type && file.type.match('image/') && !file.type.match('image/tif')
          ? null
          : file.name;

      // Truncate file name
      if (name) {
        const splitName = name.split('.');
        if (name.split('.')[0].length > 20) {
          name = `${splitName[0].substring(0, 20)}...${splitName[1]}`;
        }
      }

      /** Process the Img */
      if (!name) {
        const that = this;
        const image = new window.Image();
        image.onload = function () {
          that.setState({
            attachmentFileName: null,
            attachmentImgSrc: reader.result,
            file: regularFile ? file : imagesResizeTools.createImg(this, 1024),
            fileName: file.name,
            // Do not resize if the img is form a regualr file picker
            loadFile: false,
            regularFile: Boolean(regularFile),
          });
        };

        image.src = e.target.result;
      } else {
        this.setState({
          attachmentFileName: name,
          attachmentImgSrc: name ? null : reader.result,
          file: file,
          loadFile: false,
          regularFile: Boolean(regularFile),
        });
      }
    };

    reader.readAsDataURL(file);
  };

  onHandleDeleteAttachment = () => {
    this.setState({
      attachmentFileName: '',
      attachmentImgSrc: '',
    });
  };

  onHandleQRCodeScanned = (text) => {
    const { proccessQRCode } = this.props;
    proccessQRCode(text);
  };

  handleSearchChange = (value) => {
    const { baristaMessage, getApiMetaResults } = this.props;
    // if we're displaying options, we'd need to grab more options
    if (
      baristaMessage &&
      baristaMessage.hasIn(['metadata', 'default_options'])
    ) {
      getApiMetaResults(
        baristaMessage.getIn(['metadata', 'api_meta']).toJS(),
        value
      );
    }
    // We call this in order to have a fresher render cycle
    // and not wait untilt the API is resolved to see an UI update
    this.setState({
      searchTerm: value,
    });
  };

  setCurrentSelectOption = (option) => {
    this.setState({
      currentSelectOption: option,
    });
  };

  render() {
    const {
      allowedExtensions,
      apiMetaResults,
      autoFocus,
      baristaMessage,
      caseTaskId,
      channelId,
      channelType,
      defaultMessage,
      disabled,
      isLoadingAttachment,
      isLoadingOptions,
      isTask,
      onOpenSubscribers,
      placeholder,
    } = this.props;

    const isBarista = channelType === ChannelTypes.SUPPORT_CHANNEL;

    const { attachmentFileName, attachmentImgSrc, loadFile, searchTerm } =
      this.state;

    let selectOptions;

    if (
      baristaMessage &&
      baristaMessage.hasIn(['metadata', 'default_options'])
    ) {
      const defaultOptions = baristaMessage.getIn([
        'metadata',
        'default_options',
      ]);

      // we should see the defaultOptions until the APi call with results is resolved
      // and the user is typing something
      const shouldUseApiResults =
        searchTerm && Immutable.List.isList(apiMetaResults);
      const finalOptions = shouldUseApiResults
        ? apiMetaResults
        : defaultOptions;

      // console.log(`Using ${shouldUseApiResults ? 'apiMetaResults' : 'defaultOptions'}`);

      selectOptions = finalOptions.toJS().map((option) => {
        const id = option.pk || option.eid;
        return {
          id: id,
          key: id,
          label: option.label,
          onOptionClick: () => {
            this.setCurrentSelectOption(option);
          },
          secondarylabel: option.sub_label || null,
        };
      });
    }

    // console.log(selectOptions);
    return channelId ? (
      <ChatForm
        allowedExtensions={allowedExtensions}
        attachmentFileName={attachmentFileName}
        attachmentImgSrc={attachmentImgSrc}
        // eslint-disable-next-line jsx-a11y/no-autofocus -- legacy feature expecting autofocus
        autoFocus={autoFocus}
        caseTaskId={caseTaskId}
        channelId={channelId}
        defaultMessage={defaultMessage}
        disabled={disabled}
        enableOnlyQRScanner={isBarista && window.cordova}
        enableSubscribe={isTask}
        handleChange={this.onHandleChange}
        handleDeleteAttachment={this.onHandleDeleteAttachment}
        handleQRScannned={this.onHandleQRCodeScanned}
        isLoadingAttachment={isLoadingAttachment || loadFile}
        isLoadingOptions={isLoadingOptions}
        onFileSelect={this.handleFileSelect}
        onOpenSubscribers={onOpenSubscribers}
        onSearchChange={this.handleSearchChange}
        options={selectOptions}
        placeholder={placeholder}
        sendMessage={this.onSendFunction}
      />
    ) : null;
  }
}
const ChatInputTest = ChatInput;

// eslint-disable-next-line no-class-assign -- DEV-1526
ChatInput = ChatInputController(ChatInput);
export { ChatInputTest };
export default ChatInput;
