import { change } from 'redux-form/immutable';
import { fromJS } from 'immutable';
// Actions - Thunks
import baristaThunks from '../../actions/baristaThunks';
import workflowThunks from '../../actions/workflowThunks';
// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import baristaActions from '../../actions/baristaActions';
import faqThunks from '../../actions/faqThunks';
import errorsActions from '../../actions/errorsActions';

const mapStateToProps = (state) =>
  // const formValues = getFormValues('Form01')(state);
  ({
    faqFromArchetypeSelected: state.getIn([
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
      'faqFromArchetypeSelected',
    ]),
    faqList: state.getIn(['adminFaqs', 'faqsListResult']),
    isLoadingFAQ: state.getIn(['adminFaqs', 'isLoadingArchetypeList']),
    templateSelected: state.getIn([
      'workflowState',
      'backendScratch',
      'scratch.temp_data',
      'selected_template',
    ]),
  });
// const contextDirectory = 'DIRECTORY';

const mapDispatchToProps = (dispatch) => ({
  changeValue(id, data) {
    dispatch(change('Form01', id, data));
  },

  forceResetError(cb) {
    dispatch(errorsActions.forceResetErrors());
    cb(); // Set prev task
  },

  saveBEscratch(data) {
    return dispatch(workflowThunks.updateTempDataBackEndScratch(data));
  },
  /**
   * If we pass the idForCondition arg, we want to skip or not the current task
   * @param archetypeEID {string}
   * @param entityEID {string}
   * @param idForCondition {boolean}
   */
  searchFAQByArchetypeAndEntity(archetypeEID, entityValueEID, idForCondition) {
    if (archetypeEID && entityValueEID) {
      dispatch(
        baristaThunks.searchFAQByArchetypeAndEntity(
          archetypeEID,
          entityValueEID
        )
      ).then((res) => {
        if (idForCondition) {
          const data = res.body.results.length
            ? fromJS({
                skip: 'noskip',
              })
            : fromJS({
                skip: 'skip',
              });
          dispatch(change('Form01', idForCondition, data));
        }
      });
    }
  },
  selectFAQ(faqEID, updateEntity = false) {
    return new Promise((resolve) => {
      // We need to pass the selected FAQ eid in our store in orderto be able to find the FAQ on the next script
      dispatch(baristaActions.faqSelectFAQtoEdit(faqEID));

      if (updateEntity) {
        // We need to add new entity values from the previous screen
        dispatch(faqThunks.updateEntityValueInstance()).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  },
  submitNoMatch(id) {
    dispatch(
      change(
        'Form01',
        id,
        fromJS({
          skip: 'skip',
        })
      )
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
