import React, { createElement, PureComponent } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import _ from 'lodash';

import checkPermissions from '../../utils/checkPermissions';
import uiPathGenerator from '../../utils/uiPathGenerator';
import validateAnonsOnly from '../../utils/validateAnonsOnly';
import validateLoginRequired from '../../utils/validateLoginRequired';

class EspRoute extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    component: PropTypes.oneOfType([
      Route.propTypes.component,
      PropTypes.object,
    ]).isRequired,
    // computedMatch is passed down from <Switch /> component from React Router,
    // and should be passed down to <Route />.
    computedMatch: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
      params: PropTypes.objectOf(PropTypes.string).isRequired,
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    exact: PropTypes.bool,
    isAnonsOnly: PropTypes.bool,
    isLoginRequired: PropTypes.bool,
    isPermissionsProtected: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    path: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    computedMatch: null,
    exact: false,
    isAnonsOnly: false,
    isLoginRequired: false,
    isPermissionsProtected: false,
    location: null,
    path: null,
  };

  state = {
    isComponentMounted: false,
  };

  componentDidMount() {
    const { isAnonsOnly, isLoginRequired, isPermissionsProtected } = this.props;

    if (isLoginRequired) {
      this.isRedirectPending = validateLoginRequired();
    }
    if (isAnonsOnly) {
      this.isRedirectPending = validateAnonsOnly();
    }

    if (!this.isRedirectPending && isPermissionsProtected) {
      this.isRedirectPending = checkPermissions();
    }

    // need to do set state so this component re-renders
    // thus going from null to the actual component
    this.setState({
      isComponentMounted: true,
    });
  }

  // 'location' prop as provided by React Router
  lastReactRouterReportedLocation = null;

  // last 'routeProps' prop passed down to 'component'
  lastExtendedRouteProps = null;

  // last 'location' prop passed down to 'component'
  lastExtendedLocation = null;

  isRedirectPending = false;

  /** Renders this.props.component in compatibility mode with RR 3 */
  compatRender = (routeProps) => {
    const { children, component, ...rest } = this.props;

    // explicitly listing omitted props since 'propTypes' is removed for production builds
    const nonOwnProps = _.omit(rest, [
      'computedMatch',
      'exact',
      'isAnonsOnly',
      'isLoginRequired',
      'isPermissionsProtected',
      'location',
      'path',
    ]);

    let extendedRouteProps;
    let extendedLocation;

    const didLocationChange =
      this.lastReactRouterReportedLocation !== routeProps.location;

    if (didLocationChange) {
      // Compat for RR v3 params prop
      const { params } = routeProps.match;

      // Compat for RR v3 location.query prop
      const query = queryString.parse(routeProps.location.search);

      // Extend location with parsed search
      extendedLocation = _.clone(routeProps.location);
      extendedLocation.query = query;

      extendedRouteProps = _.clone(routeProps);

      const typedParams = uiPathGenerator.typeCastParams(
        extendedLocation.pathname,
        params
      );
      extendedRouteProps.params = typedParams;

      this.lastExtendedRouteProps = extendedRouteProps;
      this.lastExtendedLocation = extendedLocation;
    } else {
      // use cached values from last render!
      extendedRouteProps = this.lastExtendedRouteProps;
      extendedLocation = this.lastExtendedLocation;
    }

    this.lastReactRouterReportedLocation = routeProps.location;

    // Props passed to EspRoute take precedence over props passed by Route, however
    // 'location' takes the highest priority
    return createElement(
      component,
      {
        ...extendedRouteProps,
        ...nonOwnProps,
        location: extendedLocation,
      },
      children
    );
  };

  render() {
    const { exact, computedMatch, location, path } = this.props;

    // DEV-16167
    // Problem: Pages that required isLoginRequired where being mounted once
    // because logic to check permissions was moved to ComponentDidMount
    // So we don't have to render anything until the login has been checked
    // Additionally we have a root address that has no location, so we should let that one pass
    const { isComponentMounted } = this.state;
    const isNonRootRouteMounted = !isComponentMounted && location;

    if (this.isRedirectPending || isNonRootRouteMounted) {
      return null;
    }

    return (
      <Route
        computedMatch={computedMatch}
        exact={exact}
        location={location}
        path={path}
        render={this.compatRender}
      />
    );
  }
}

export default EspRoute;
