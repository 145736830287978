import { createSelector } from 'reselect';
import getTenant from './getTenant';

const getEmployeeOnboarded = (state) =>
  state.getIn(['appUI', 'employeeOnboarded']);

const workflowModalStateIsLaunching = (state) =>
  state.getIn(['appUI', 'workflowModal', 'isLaunching']);

const getExistingEmployeeWFeid = (state) =>
  state.getIn(['appUI', 'employeeOnboarded', 'workflowEID']);

const shouldLaunchExistingEmployeeWF = (
  tenantEntity,
  employeeOnboarded,
  workflowEID,
  isLaunchingWF
) => {
  if (!tenantEntity) {
    return false;
  }

  const launchExistingEmployeeWF = tenantEntity.getIn([
    'ui_config',
    'newFeature',
    'launchExistingEmployeeWF',
  ]);

  if (
    !isLaunchingWF &&
    launchExistingEmployeeWF &&
    !employeeOnboarded.get('isEmployeeWFOpen') &&
    !employeeOnboarded.get('started') &&
    workflowEID
  ) {
    return true;
  } else {
    return false;
  }
};

const selector = createSelector(
  [
    getTenant,
    getEmployeeOnboarded,
    getExistingEmployeeWFeid,
    workflowModalStateIsLaunching,
  ],
  shouldLaunchExistingEmployeeWF
);

export { getExistingEmployeeWFeid };

export default selector;
