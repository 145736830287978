import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  performance: {
    count: 0,
    isLoading: false,
    isSearching: false,
    list: [], // Summary List
    loadingDeleteID: null,
    loadingEscalateID: null,
    searchTerm: '',
  },
  schedule: {
    count: 0,
    isLoading: false,
    isLoadingCreating: false,
    isLoadingDepartment: false,
    isLoadingLocation: false,
    isSearching: false,
    list: [],
    loadingDeleteID: null,
    searchTerm: '',
    templateSelected: [],
  },
  template: {
    category: 'Watch a Video', // TODO this is the default category for now
    isLoading: false,
    items: [],
    next: null,
  },
});

const addElements = (state, templates) => {
  const newState = state.setIn(['template', 'isLoading'], false);
  let newList = state.getIn(['template', 'items']);
  const finalTemplate = [];

  // Check if not already exist
  newList = newList.map((item) => {
    let setIt = null;
    templates.forEach((temp) => {
      if (item.get('id') === temp.id) {
        setIt = fromJS(temp);
      }
    });
    return setIt || item;
  });

  // Clean the template array from data received
  templates.forEach((temp) => {
    if (
      newList.isEmpty() ||
      newList.filter((item) => item.get('id') === temp.id).isEmpty()
    ) {
      finalTemplate.push(temp);
    }
  });

  // Concat current array and current List
  newList = fromJS(finalTemplate).concat(newList);
  return newState.setIn(['template', 'items'], newList);
};

const onboardActivitysReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ESCALATE_PROGRESS_START: {
      return state
        .setIn(['performance', 'isLoading'], true)
        .setIn(['performance', 'loadingEscalateID'], action.userID);
    }

    case actionTypes.ESCALATE_PROGRESS_SUCCESS: {
      return state
        .setIn(['performance', 'isLoading'], false)
        .setIn(['performance', 'loadingEscalateID'], null);
    }

    case actionTypes.ESCALATE_PROGRESS_FAIL: {
      return state
        .setIn(['performance', 'isLoading'], false)
        .setIn(['performance', 'loadingEscalateID'], null);
    }

    case actionTypes.LOAD_ONBOARD_PERFORMANCE_LIST_START: {
      return state.setIn(['performance', 'isLoading'], true);
    }

    case actionTypes.LOAD_ONBOARD_PERFORMANCE_LIST_SUCCESS: {
      return state
        .setIn(['performance', 'isLoading'], false)
        .setIn(['performance', 'count'], action.count)
        .setIn(['performance', 'isSearching'], false)
        .setIn(['performance', 'list'], fromJS(action.performances));
    }

    case actionTypes.LOAD_ONBOARD_PERFORMANCE_LIST_FAIL: {
      return state.setIn(['performance', 'isLoading'], false);
    }

    case actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_START: {
      return state.setIn(['performance', 'isLoading'], true);
    }

    case actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_SUCCESS: {
      return state.setIn(['performance', 'isLoading'], false);
    }

    case actionTypes.LOAD_ONBOARD_PERFORMANCE_DETAIL_FAIL: {
      return state.setIn(['performance', 'isLoading'], false);
    }

    case actionTypes.SET_TERM_SEARCH_PERFORMANCE_ACTIVITY: {
      return state.setIn(['performance', 'searchTerm'], action.term);
    }

    case actionTypes.SET_TERM_SEARCH_SCHEDULE_ACTIVITY: {
      return state.setIn(['schedule', 'searchTerm'], action.term);
    }

    case actionTypes.DELETE_ONBOARD_PERFORMANCE_SUMMARY_START: {
      return state.setIn(
        ['performance', 'loadingDeleteID'],
        action.performanceSummaryID
      );
    }

    case actionTypes.DELETE_ONBOARD_PERFORMANCE_SUMMARY_FAIL: {
      return state.setIn(['performance', 'loadingDeleteID'], null);
    }

    case actionTypes.DELETE_ONBOARD_PERFORMANCE_SUMMARY_SUCCESS: {
      const newScheduleList = state
        .getIn(['performance', 'list'])
        .filter((list) => list.get('id') !== action.performanceSummaryID);

      return state
        .setIn(['performance', 'list'], newScheduleList)
        .setIn(['performance', 'loadingDeleteID'], null);
    }

    case actionTypes.SET_IS_SEARCHING_SCHEDULE: {
      return state.setIn(['schedule', 'isSearching'], true);
    }

    case actionTypes.GET_DEFAULT_SELECTED_DEPARTMENT_START: {
      return state.setIn(['schedule', 'isLoadingDepartment'], true);
    }

    case actionTypes.GET_DEFAULT_SELECTED_LOCATION_START: {
      return state.setIn(['schedule', 'isLoadingLocation'], true);
    }

    case actionTypes.GET_DEFAULT_SELECTED_DEPARTMENT_SUCCESS: {
      return state.setIn(['schedule', 'isLoadingDepartment'], false);
    }

    case actionTypes.GET_DEFAULT_SELECTED_LOCATION_SUCCESS: {
      return state.setIn(['schedule', 'isLoadingLocation'], false);
    }

    case actionTypes.LOAD_TEMPLATE_INTERVAL_SELECTED: {
      return state.setIn(
        ['schedule', 'templateSelected'],
        fromJS(action.templateSelected)
      );
    }

    case actionTypes.UPDATE_TEMPLATE_INTERVAL: {
      let templateSelected = state.getIn(['schedule', 'templateSelected']);
      const getScheduleIndex = templateSelected.findIndex(
        (temp) =>
          Number(temp.getIn(['interval', 'id'])) ===
            Number(action.intervalID) &&
          Number(temp.getIn(['template', 'id'])) === Number(action.templateID)
      );

      if (getScheduleIndex > -1) {
        templateSelected = templateSelected.setIn(
          [getScheduleIndex, 'interval'],
          fromJS(action.interval)
        );
      }

      return state.setIn(['schedule', 'templateSelected'], templateSelected);
    }

    case actionTypes.UPDATE_TEMPLATE_SCHEDULE_TO_TEMPLATE: {
      let templateSelected = state.getIn(['schedule', 'templateSelected']);
      const getScheduleIndex = templateSelected.findIndex(
        (temp) =>
          Number(temp.getIn(['interval', 'id'])) === Number(action.intervalID)
      );

      if (getScheduleIndex > -1) {
        templateSelected = templateSelected.setIn(
          [getScheduleIndex, 'scheduleToTemplate'],
          fromJS(action.scheduleToTemplate)
        );
      }

      return state.setIn(['schedule', 'templateSelected'], templateSelected);
    }

    case actionTypes.UPDATE_TEMPLATE_SCHEDULE: {
      let templateSelected = state.getIn(['schedule', 'templateSelected']);
      const getScheduleIndex = templateSelected.findIndex(
        (temp) =>
          Number(temp.getIn(['interval', 'id'])) === Number(action.intervalID)
      );

      if (getScheduleIndex > -1) {
        templateSelected = templateSelected.setIn(
          [getScheduleIndex, 'scheduleToTemplate', 'interval'],
          action.id
        );
      }

      return state.setIn(['schedule', 'templateSelected'], templateSelected);
    }

    case actionTypes.DELETE_TEMPLATE_INTERVAL_SELECTED: {
      let templateSelected = state.getIn(['schedule', 'templateSelected']);
      templateSelected = templateSelected.filter(
        (temp) =>
          temp.getIn(['interval', 'id']) !== action.intervalID ||
          temp.getIn(['template', 'id']) !== action.templateID
      );

      return state
        .setIn(['schedule', 'templateSelected'], templateSelected)
        .setIn(['schedule', 'isLoading'], false);
    }

    case actionTypes.ADD_NEW_TEMPLATE_INTERVAL_START: {
      return state.setIn(['schedule', 'isLoadingCreating'], true);
    }

    case actionTypes.ADD_NEW_TEMPLATE_INTERVAL_FAIL: {
      return state.setIn(['schedule', 'isLoadingCreating'], false);
    }

    case actionTypes.ADD_NEW_TEMPLATE_INTERVAL_SUCCESS: {
      const templateSelected = state
        .getIn(['schedule', 'templateSelected'])
        .push(fromJS(action.templateSelected));

      return state
        .setIn(['schedule', 'templateSelected'], templateSelected)
        .setIn(['schedule', 'isLoadingCreating'], false);
    }

    case actionTypes.RESET_TEMPLATE_INTERVAL_SELECTED: {
      return state.setIn(
        ['schedule', 'templateSelected'],
        INITIAL_STATE.getIn(['schedule', 'templateSelected'])
      );
    }

    case actionTypes.LOAD_ONBOARD_SCHEDULE_LIST_START: {
      return state.setIn(['schedule', 'isLoading'], true);
    }

    case actionTypes.LOAD_ONBOARD_SCHEDULE_START: {
      return state.setIn(['schedule', 'isLoading'], true);
    }

    case actionTypes.LOAD_ONBOARD_SCHEDULE_END: {
      return state.setIn(['schedule', 'isLoading'], false);
    }

    case actionTypes.LOAD_ONBOARD_SCHEDULE_LIST_SUCCESS: {
      return state
        .setIn(['schedule', 'isLoading'], false)
        .setIn(['schedule', 'count'], action.count)
        .setIn(['schedule', 'isSearching'], false)
        .setIn(['schedule', 'list'], fromJS(action.schedules));
    }

    case actionTypes.LOAD_ONBOARD_ONE_SCHEDULE_SUCCESS: {
      let scheduleList = state.getIn(['schedule', 'list']);
      scheduleList = scheduleList.push(fromJS(action.schedule));

      const count = state.getIn(['schedule', 'count']);

      return state
        .setIn(['schedule', 'isLoading'], false)
        .setIn(['schedule', 'count'], count + 1)
        .setIn(['schedule', 'list'], scheduleList);
    }

    case actionTypes.LOAD_ONBOARD_SCHEDULE_LIST_FAIL: {
      return state.setIn(['schedule', 'isLoading'], false);
    }

    case actionTypes.LOAD_ONBOARD_TEMPLATE_LIST_START: {
      return state.setIn(['template', 'isLoading'], true);
    }

    case actionTypes.LOAD_ONBOARD_TEMPLATE_LIST_SUCCESS: {
      let newState = state;
      if (!action.keepCurrent) {
        newState = newState.setIn(['template', 'items'], fromJS([]));
      }
      return addElements(newState, action.templates);
    }

    case actionTypes.ADD_ONBOARD_TEMPLATE_LIST_SUCCESS: {
      const newTemplateList = state
        .getIn(['template', 'items'])
        .filter((tmp) => {
          let exist = false;
          action.templates.forEach((act) => {
            if (act.eid === tmp.get('eid')) {
              exist = true;
            }
          });
          return !exist;
        })
        .concat(fromJS(action.templates));

      return state
        .setIn(['template', 'isLoading'], false)
        .setIn(['template', 'next'], action.next)
        .setIn(['template', 'items'], newTemplateList);
    }

    case actionTypes.LOAD_ONBOARD_TEMPLATE_LIST_FAIL: {
      return state.setIn(['template', 'isLoading'], false);
    }

    case actionTypes.CHANGE_STATUS_ONBOARD_TEMPLATE: {
      const templateIndex = state
        .getIn(['template', 'items'])
        .findIndex((itm) => itm.get('id') === Number(action.templateID));
      return state
        .setIn(['template', 'items', templateIndex, 'status'], action.status)
        .setIn(['template', 'isLoading'], false);
    }

    case actionTypes.SAVE_ONBOARD_SCHEDULE_START: {
      return state.setIn(['schedule', 'isLoading'], true);
    }

    case actionTypes.DELETE_ONBOARD_SCHEDULE_START: {
      return state.setIn(['schedule', 'loadingDeleteID'], action.deletedID);
    }

    case actionTypes.DELETE_ONBOARD_SCHEDULE_FAIL: {
      return state.setIn(['schedule', 'loadingDeleteID'], null);
    }

    case actionTypes.DELETE_ONBOARD_SCHEDULE_SUCCESS: {
      const newScheduleList = state
        .getIn(['schedule', 'list'])
        .filter((list) => list.get('id') !== action.scheduleID);

      return state
        .setIn(['schedule', 'list'], newScheduleList)
        .setIn(['schedule', 'loadingDeleteID'], null);
    }

    case actionTypes.SAVE_ONBOARD_SCHEDULE_SUCCESS: {
      let scheduleList = state.getIn(['schedule', 'list']);
      const newSchedule = fromJS(action.schedule);
      const index = scheduleList.findIndex(
        (sc) => sc.get('id') === newSchedule.get('id')
      );

      if (index > -1) {
        // update the schedule
        const selectedSchedule = scheduleList
          .get(index)
          .set('name', action.schedule.name)
          .set('description', action.schedule.description);

        scheduleList = scheduleList.set(index, selectedSchedule);
      } else {
        scheduleList = scheduleList.push(newSchedule);
      }

      const isLoading = Boolean(action.stillLoading);

      return state
        .setIn(['schedule', 'isLoading'], isLoading)
        .setIn(['schedule', 'list'], scheduleList);
    }

    case actionTypes.SAVE_ONBOARD_SCHEDULE_FAIL: {
      return state.setIn(['schedule', 'isLoading'], false);
    }

    case actionTypes.SET_TODO_TEMPLATE_CATEGORY: {
      return state.setIn(['template', 'category'], action.category);
    }

    case actionTypes.SCHEDULE_SAVE_GROUP_SUCCESS: {
      const scheduleIndex = state
        .getIn(['schedule', 'list'])
        .findIndex((ann) => ann.get('id') === action.group.todo_schedule);
      const groupIndex = state
        .getIn(['schedule', 'list', scheduleIndex, 'groups'])
        .findIndex((group) => group.get('id') === action.group.id);

      return state.setIn(
        ['schedule', 'list', scheduleIndex, 'groups', groupIndex],
        fromJS(action.group)
      );
    }

    case actionTypes.SCHEDULE_ADDED_GROUP_SUCCESS: {
      const scheduleIndex = state
        .getIn(['schedule', 'list'])
        .findIndex((ann) => ann.get('id') === action.group.todo_schedule);
      return state.updateIn(
        ['schedule', 'list', scheduleIndex, 'groups'],
        (groups) => groups.push(fromJS(action.group))
      );
    }

    case actionTypes.SCHEDULE_DELETED_GROUP_SUCCESS: {
      const scheduleIndex = state
        .getIn(['schedule', 'list'])
        .findIndex((ann) => ann.get('id') === action.scheduleId);
      const groupIndex = state
        .getIn(['schedule', 'list', scheduleIndex, 'groups'])
        .findIndex((group) => group.get('id') === action.groupId);

      return state.deleteIn([
        'schedule',
        'list',
        scheduleIndex,
        'groups',
        groupIndex,
      ]);
    }

    case actionTypes.SAVE_SCHEDULE_STATUS_SUCCESS: {
      const scheduleIndex = state
        .getIn(['schedule', 'list'])
        .findIndex((ann) => ann.get('id') === action.scheduleId);

      return state.setIn(
        ['schedule', 'list', scheduleIndex, 'status'],
        action.status
      );
    }

    default: {
      return state;
    }
  }
};

export default onboardActivitysReducer;
