import actionTypes from './actionTypes';

const hierarchySelectActions = {};

hierarchySelectActions.cacheRootNodes = (hierarchyType) => ({
  hierarchyType,
  type: actionTypes.HIERARCHY_SELECT_CACHE_ROOT_NODES,
});

hierarchySelectActions.cacheChildrenNodes = (hierarchyType, parentID) => ({
  hierarchyType,
  parentID,
  type: actionTypes.HIERARCHY_SELECT_CACHE_CHILDREN_NODES,
});

hierarchySelectActions.cacheDefaultSelectedNode = (hierarchyType, nodeID) => ({
  hierarchyType,
  nodeID,
  type: actionTypes.HIERARCHY_SELECT_CACHE_DEFAULT_SELECTED_NODE,
});

export default hierarchySelectActions;
