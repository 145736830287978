import endpointGenerator from '../../../../../utils/endpointGenerator';
import _APIcall from '../../../../../utils/APIcall';

const deleteAuthSAML_SP = async (id) => {
  const url = endpointGenerator.genPath('authentication.samlSP.instance', {
    id,
  });

  return _APIcall.delete({
    token: true,
    url: url,
  });
};

export default deleteAuthSAML_SP;
