import React, { PureComponent } from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
// Type
import TypeSelectMobileOS, {
  TypeDefaults,
} from '../../../globals/blocks/TypeSelectMobileOS';
// Molecule
import FormInputSelect from '../../molecules/FormInputSelect';

class SelectMobileType01 extends PureComponent {
  static propTypes = {
    ...TypeSelectMobileOS,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  optionMobile = [
    {
      key: 1,
      text: 'Android',
      value: 'Android',
    },
    {
      key: 2,
      text: 'iOS',
      value: 'iOS',
    },
    {
      key: 3,
      text: 'Other',
      value: 'Other',
    },
  ];

  render() {
    const { id, label, placeholder, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Form.Field>
          <Field
            component={FormInputSelect}
            fluid
            label={label}
            name={id}
            options={this.optionMobile}
            placeholder={placeholder}
            search
          />
        </Form.Field>
      </div>
    );
  }
}

export default SelectMobileType01;
