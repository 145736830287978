import React, { PureComponent } from 'react';
import {
  first,
  isEmpty,
  isNumber,
  noop,
  omit,
  size,
  some,
  uniqueId,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Boundaries } from 'cascara-middleware';
import { FormikWithSemanticUI } from 'esp-ui-form';
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import ConnectionOptions from '../../../../v1/globals/ConnectionOptions';
import ImportFrequency from './ImportFrequency';
import CSVImportScheduleFormController from './CSVImportScheduleFormController';

const syncLength = (forms) => {
  const keys = Object.keys(forms);
  const { length } = keys;

  if (isEmpty(keys)) {
    return forms;
  }

  keys.forEach((key, i) => {
    forms[key] = {
      ...forms[key],
      props: {
        ...forms[key].props,
        length,
        order: i,
      },
    };
  });

  return forms;
};

class CSVImportScheduleForm extends PureComponent {
  static propTypes = {
    businessObjects: ImmutablePropTypes.list,
    createCSVSchedule: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.bool),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    importFrequencyValues: PropTypes.shape({
      interval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      periodicity: PropTypes.string,
      start_time: PropTypes.string,
    }),
    isLoadingBusinessObject: PropTypes.bool,
    isValid: PropTypes.bool,
    isValidating: PropTypes.bool,
    onClose: PropTypes.func,
    onTest: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
    updateCSVSchedule: PropTypes.func,
    values: PropTypes.shape({
      business_object: PropTypes.number,
      dirty: PropTypes.bool,
      id: PropTypes.number,
      import_frequency: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
      ]),
      parse_now: PropTypes.bool,
      password: PropTypes.string,
      port_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      protocol: PropTypes.string,
      remote_file_path: PropTypes.string,
      server: PropTypes.string,
      username: PropTypes.string,
    }),
  };

  static defaultProps = {
    businessObjects: Immutable.List(),
    createCSVSchedule: noop,
    errors: {},
    handleBlur: noop,
    handleChange: noop,
    importFrequencyValues: {},
    isLoadingBusinessObject: false,
    isValid: false,
    isValidating: false,
    onClose: noop,
    onTest: noop,
    setFieldValue: noop,
    touched: {},
    updateCSVSchedule: noop,
    values: {},
  };

  state = {
    importFrequencyForms: {},
    isImportFrequencyFormValid: false,
  };

  componentDidMount() {
    this.handleAddImportFrequency();
  }

  handleAddImportFrequency = () => {
    const { importFrequencyForms } = this.state;

    const {
      importFrequencyValues,
      values: { id: formID },
    } = this.props;

    const id = uniqueId();

    let propValues = {};

    // Sets BE values for frequency values
    if (isNumber(formID) && isEmpty(importFrequencyForms)) {
      propValues = {
        interval: importFrequencyValues.interval,
        periodicity: importFrequencyValues.periodicity,
        ...(importFrequencyValues.start_time && {
          start_date: moment(importFrequencyValues.start_time).format(
            'YYYY-MM-DD'
          ),
          start_time: moment(importFrequencyValues.start_time).format('HH:mm'),
        }),
      };
    }

    this.setState({
      importFrequencyForms: syncLength(
        Object.assign(
          { ...importFrequencyForms },
          {
            [id]: (
              <ImportFrequency
                id={id}
                key={id}
                onAdd={this.handleAddImportFrequency}
                onError={this.handleErrorImportFrequency}
                onRemove={this.handleRemoveImportFrequency}
                onUpdate={this.handleUpdateImportFrequency}
                onValid={this.handleValidImportFrequency}
                values={propValues}
              />
            ),
          }
        )
      ),
    });
  };

  handleBlur = (e) => {
    const { handleBlur } = this.props;
    handleBlur(e, e.target);
  };

  handleChangeMapping = (e, { name, value }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  handleCheckBoxChange = (e, { name, checked }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, checked, false);
  };

  handleClickCancel = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    onClose();
  };

  handleErrorImportFrequency = () =>
    this.setState({ isImportFrequencyFormValid: false });

  handleRemoveImportFrequency = (id) => {
    const {
      setFieldValue,
      values: { import_frequency },
    } = this.props;

    const { importFrequencyForms } = this.state;

    setFieldValue('import_frequency', omit(import_frequency, id));
    this.setState({
      importFrequencyForms: syncLength(omit(importFrequencyForms, id)),
    });
  };

  handleSaveSchedule = (e) => {
    const {
      createCSVSchedule,
      onTest,
      updateCSVSchedule,
      values: { ...values },
    } = this.props;

    const { preventDefault } = e; // Prevents asynchronous synthetic event warning  => https://reactjs.org/docs/events.html#event-pooling

    if (isNumber(values.id)) {
      if (size(values.import_frequency) > 1) {
        const keys = Object.keys(values.import_frequency);
        const updateKey = first(keys.splice(0, 1));
        updateCSVSchedule({
          ...values,
          import_frequency: {
            [updateKey]: { ...values.import_frequency[updateKey] },
          },
        }).then(() => {
          createCSVSchedule(
            omit(
              {
                ...values,
                import_frequency: keys.reduce(
                  (acc, key) =>
                    Object.assign(acc, { [key]: values.import_frequency[key] }),
                  {}
                ),
              },
              'id'
            )
          ).then((schedule) => onTest(schedule)({ preventDefault }));
        });
      } else {
        updateCSVSchedule(
          values.password ? values : omit(values, 'password')
        ).then((schedule) => onTest(schedule)({ preventDefault }));
      }
    } else {
      createCSVSchedule(values, omit(values, 'id')).then((schedule) =>
        onTest(schedule)({ preventDefault })
      );
    }
  };

  handleUpdateImportFrequency = (id, values) => {
    const {
      setFieldValue,
      values: { import_frequency },
    } = this.props;
    setFieldValue(
      'import_frequency',
      Object.assign(import_frequency, { [id]: { ...values } }),
      false
    );
    setFieldValue('dirty', true);
  };

  handleValidImportFrequency = () =>
    this.setState({ isImportFrequencyFormValid: true });

  render() {
    const {
      businessObjects,
      errors,
      handleChange,
      isLoadingBusinessObject,
      isValid,
      isValidating,
      touched,
      values,
    } = this.props;

    const { importFrequencyForms, isImportFrequencyFormValid } = this.state;

    const isNewSchedule = !isNumber(values.id);

    const mappingOptions = businessObjects
      .map((businessObject) => ({
        key: businessObject.get('id'),
        text: businessObject.get('name'),
        value: businessObject.get('id'),
      }))
      .toArray();

    return (
      <Boundaries>
        <>
          <Modal.Content className='withFooter'>
            {/* Mapping */}
            <Form.Dropdown
              disabled={isLoadingBusinessObject}
              label='Mapping'
              loading={isLoadingBusinessObject}
              name='business_object'
              onChange={this.handleChangeMapping}
              options={mappingOptions}
              placeholder='Select a type'
              selection
              value={values.business_object}
              width={10}
            />

            {/* Import Frequency */}
            <Header as='h4' content='Import Frequency' />

            <Form.Group>
              <Form.Field width={3}>{'Start Date'}</Form.Field>

              <Form.Field width={3}>{'Start Time'}</Form.Field>

              <Form.Field width={3}>{'Frequency'}</Form.Field>
            </Form.Group>

            {!isEmpty(importFrequencyForms) &&
              Object.values(importFrequencyForms).map(
                (ImportFrequency) => ImportFrequency
              )}

            {/* Retrieval Method */}
            <Header as='h4' content='Retrieval Method' />
            <Form.Dropdown
              label='Connection Type'
              name='protocol'
              onChange={handleChange}
              options={ConnectionOptions}
              placeholder='Select a type'
              selection
              value={values.protocol}
              width={10}
            />
            <Form.Input
              error={touched.remote_file_path && errors.remote_file_path}
              label='Filepath'
              name='remote_file_path'
              onBlur={this.handleBlur}
              onChange={handleChange}
              value={values.remote_file_path}
            />
            <Form.Group>
              <Form.Input
                error={touched.server && errors.server}
                label='Server'
                name='server'
                onBlur={this.handleBlur}
                onChange={handleChange}
                value={values.server}
                width={12}
              />
              <Form.Input
                error={touched.port_number && errors.port_number}
                label='Port'
                name='port_number'
                onBlur={this.handleBlur}
                onChange={handleChange}
                type='number'
                value={values.port_number}
                width={4}
              />
            </Form.Group>

            {/* Credentials */}
            <Header as='h4' content='Credentials' />
            <Form.Group>
              <Form.Input
                error={touched.username && errors.username}
                label='Username'
                name='username'
                onBlur={this.handleBlur}
                onChange={handleChange}
                value={values.username}
                width={8}
              />
              <Form.Input
                error={isNewSchedule && touched.password && errors.password}
                label='Password'
                name='password'
                onBlur={this.handleBlur}
                onChange={handleChange}
                type='password'
                width={8}
              />
            </Form.Group>

            {/* Import object upon save */}
            <Form.Checkbox
              checked={values.parse_now}
              label={
                <label>
                  <p style={{ marginBottom: 0 }}>{'Import object upon save'}</p>
                  <em>
                    {
                      'If not checked import will not happen until next scheduled time'
                    }
                  </em>
                </label>
              }
              name='parse_now'
              onChange={this.handleCheckBoxChange}
            />
          </Modal.Content>
          <Modal.Actions style={{ textAlign: 'right' }}>
            <Button basic content='Cancel' onClick={this.handleClickCancel} />

            <Button
              content={isNewSchedule ? 'Save and Test' : 'Save and Edit'}
              disabled={some([
                isValidating,
                !isValid,
                !isImportFrequencyFormValid,
              ])}
              onClick={this.handleSaveSchedule}
              primary
            />
          </Modal.Actions>
        </>
      </Boundaries>
    );
  }
}

const CSVImportScheduleFormTest = CSVImportScheduleForm;
export { CSVImportScheduleFormTest, syncLength };

export default CSVImportScheduleFormController(
  FormikWithSemanticUI({
    enableReinitialize: true,
    mapPropsToValues: ({ values }) => {
      const getValue = (key, defaultValue) =>
        (values &&
          Object.prototype.hasOwnProperty.call(values, key) &&
          values[key]) ||
        defaultValue;
      return {
        business_object: getValue('business_object', null),
        id: getValue('id', null),
        import_frequency: getValue('import_frequency', {}),
        parse_now: getValue('parse_now', false),
        password: getValue('password', ''),
        port_number: getValue('port_number', ''),
        protocol: getValue('protocol', null),
        remote_file_path: getValue('remote_file_path', ''),
        server: getValue('server', ''),
        username: getValue('username', ''),
      };
    },
    validate: (values) => {
      const errors = {};

      if (!values.business_object) {
        errors.business_object = true;
      }

      if (
        (!isNumber(values.id) || size(values.import_frequency) > 1) &&
        !values.password.trim()
      ) {
        errors.password = true;
      }

      if (!values.port_number) {
        errors.port_number = true;
      }

      if (!values.remote_file_path.trim()) {
        errors.remote_file_path = true;
      }

      if (!values.server.trim()) {
        errors.server = true;
      }

      if (!values.username.trim()) {
        errors.username = true;
      }

      return errors;
    },
  })(CSVImportScheduleForm)
);
