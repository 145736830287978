import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsFacilities extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M73 19v58.27H58.76V64.32h-6.48v12.95H38V19h35zm-3.88 3.88h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.48v-6.47zm-11.65 7.77v46.62H22.5V30.65h14.25zm32.37 2.59h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.48v-6.47zm-15.54 1.3h-6.47V41h6.47v-6.46zm36.26 9.06h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.48V43.6zm-15.54 1.3h-6.47v6.47h6.47V44.9zM69.12 54h-6.48v6.47h6.47l.01-6.47zm-10.36 0h-6.48v6.47h6.47l.01-6.47zM48.4 54h-6.48v6.47h6.48V54zm-15.54 1.25h-6.47v6.47h6.47v-6.47zm0 10.36h-6.47v6.47h6.47v-6.47z' />
          <path d='M48 9c21.54 0 39 17.46 39 39S69.54 87 48 87 9 69.54 9 48 26.46 9 48 9zm0-5C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsFacilities;
