import APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const patchTenantConfig = (tenantID, data) => {
  return APIcall.patch({
    data,
    token: true,
    url: endpointGenerator.genPath('espTenant.tenants.instance', {
      tenantID,
    }),
  });
};

export default patchTenantConfig;
