const api_barista_v0_1_kb_articles_get_kbs_by_source = {
  count: 3,
  results: [
    {
      article_record: 'rwerwe',
      title: 'This is TESTKB 1',
      total: 0,
    },
    {
      article_record: 'rwe43rwe',
      title: 'This is TESTKB 2',
      total: 0,
    },
    {
      article_record: 'rw$@#$#!@erwe',
      title: 'This is TESTKB 3',
      total: 9,
    },
    {
      article_record: 'rw%&%erwe',
      title: 'This is TESTKB 4',
      total: 19,
    },
  ],
};

export default api_barista_v0_1_kb_articles_get_kbs_by_source;
