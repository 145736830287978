import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsPurchaseOrder extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd'>
          <path
            d='M60.4 20.1l-6.88 22.89'
            stroke='#FFF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='6'
          />
          <path
            d='M22.38 69.58c-4.346 0-7.87 3.524-7.87 7.87s3.524 7.87 7.87 7.87 7.87-3.524 7.87-7.87c0-2.087-.83-4.09-2.305-5.565-1.476-1.476-3.478-2.305-5.565-2.305zm0 12.73c-2.69 0-4.87-2.18-4.87-4.87s2.18-4.87 4.87-4.87 4.87 2.18 4.87 4.87-2.18 4.87-4.87 4.87zm25.2-12.73c-4.346 0-7.87 3.524-7.87 7.87s3.524 7.87 7.87 7.87 7.87-3.524 7.87-7.87c0-2.087-.83-4.09-2.305-5.565-1.476-1.476-3.478-2.305-5.565-2.305zm0 12.73c-1.97 0-3.746-1.187-4.5-3.006-.753-1.82-.336-3.915 1.056-5.308 1.393-1.392 3.488-1.81 5.308-1.055 1.82.756 3.006 2.53 3.006 4.5 0 2.69-2.18 4.87-4.87 4.87zm35.13-71.33H64.88c-1.312.002-2.47.857-2.86 2.11L49.68 52.78V38.09c0-1.105-.895-2-2-2H24.93c-1.105 0-2 .895-2 2v18.89l-6.55-27.93c-.21-1.077-.993-1.952-2.04-2.283-1.045-.33-2.188-.065-2.98.694-.792.76-1.106 1.89-.82 2.95l7.34 31.46c.316 1.36 1.526 2.32 2.92 2.32h29.4c1.312 0 2.47-.855 2.86-2.11l14.03-45.1h15.62c1.072 0 2.062-.57 2.598-1.5s.536-2.07 0-3c-.536-.926-1.526-1.5-2.598-1.5z'
            fill='#F00'
            fillRule='nonzero'
          />
        </g>
      </svg>
    );
  }
}

export default CardsPurchaseOrder;
