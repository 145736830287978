import { Grid, Segment } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';

// Organisms
import ResetPasswordSendCodeForm from '../organisms/ResetPasswordSendCodeForm';
import ResetPasswordValidateCodeForm from '../organisms/ResetPasswordValidateCodeForm';
// Controller
import ResetPasswordPageController from '../controllers/ResetPasswordPageController';
// Molecule
import TenantImage from '../molecules/TenantImage';
import TenantBackgroundImage from '../../../v2/components/functional/TenantBackgroundImage';
// Util
import TenantImageTypes from '../../utils/TenantImageTypes';
import BodyClassName from '../../../globals/BodyClassName';

class ResetPasswordPage extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        email: PropTypes.string,
        fromlink: PropTypes.string,
      }),
    }).isRequired,
    resetPasswordInit: PropTypes.func,
    sendRecoveryCode: PropTypes.func,
    sendRecoveryCodeState: ImmutablePropTypes.map.isRequired,
    tenant: ImmutablePropTypes.map,
    validateRecoveryCode: PropTypes.func,
    validateRecoveryCodeState: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    resetPasswordInit: _.noop,
    sendRecoveryCode: _.noop,
    tenant: null,
    validateRecoveryCode: _.noop,
  };

  componentDidMount() {
    const { resetPasswordInit } = this.props;
    resetPasswordInit();
  }

  handleSendCodeFormSubmit = (values) => {
    const { sendRecoveryCode } = this.props;

    /** @type {string} */
    const email = values.get('email');

    /** @type {string} */
    const recoveryMethod = values.get('recoveryMethod');

    sendRecoveryCode(email, recoveryMethod);
  };

  handleValidateRecoveryCodeFormSubmit = (values) => {
    const { validateRecoveryCode, location } = this.props;

    const fromLink = Boolean(location.query.fromlink);
    const email =
      fromLink && location.query.email ? location.query.email : null;

    const recoveryCode = values.get('recoveryCode').trim();
    validateRecoveryCode(recoveryCode, email);
  };

  render() {
    const {
      location,
      sendRecoveryCodeState,
      tenant,
      validateRecoveryCodeState,
    } = this.props;

    const email = location.query.email || '';
    const fromLink = Boolean(location.query.fromlink);

    return tenant ? (
      <BodyClassName className='reset-password-page'>
        <Grid centered verticalAlign='middle'>
          <Helmet title={tenant.get('name')} />
          <Grid.Column>
            <TenantBackgroundImage />
            <TenantImage
              className='logo'
              type={TenantImageTypes.ALTERNATE_LOGO}
            />
            <Segment>
              {!sendRecoveryCodeState.get('done') && !fromLink ? (
                <ResetPasswordSendCodeForm
                  email={email}
                  errorMsg={sendRecoveryCodeState.get('error')}
                  form='ResetPasswordSendCode'
                  isLoading={sendRecoveryCodeState.get('isLoading')}
                  onSubmit={this.handleSendCodeFormSubmit}
                />
              ) : (
                <ResetPasswordValidateCodeForm
                  errorMsg={validateRecoveryCodeState.get('error')}
                  form='ResetPasswordValidateCode'
                  fromLink={fromLink}
                  isLoading={validateRecoveryCodeState.get('isLoading')}
                  onSubmit={this.handleValidateRecoveryCodeFormSubmit}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </BodyClassName>
    ) : null;
  }
}

const ResetPasswordPageTest = ResetPasswordPage;

// eslint-disable-next-line no-class-assign -- DEV-1526
ResetPasswordPage = ResetPasswordPageController(ResetPasswordPage);
export { ResetPasswordPageTest };
export default ResetPasswordPage;
