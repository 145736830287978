/**
 * List of all
 * group permission
 */

const groupPermissions = {
  ACTIVITY_CREATOR: 'ACTIVITY_CREATOR',
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
  ANNOUNCER: 'ANNOUNCER',
  BARISTA_ADMIN: 'BARISTA_ADMIN',
  CASE_TECHNICAL_CONTACT_COMPLIANCE: 'CASE_TECHNICAL_CONTACT_COMPLIANCE',
  CASE_TECHNICAL_CONTACT_CUSTOMER_SERVICE:
    'CASE_TECHNICAL_CONTACT_CUSTOMER_SERVICE',
  CASE_TECHNICAL_CONTACT_FACILITIES: 'CASE_TECHNICAL_CONTACT_FACILITIES',
  CASE_TECHNICAL_CONTACT_FINANCE: 'CASE_TECHNICAL_CONTACT_FINANCE',
  CASE_TECHNICAL_CONTACT_HR: 'CASE_TECHNICAL_CONTACT_HR',
  CASE_TECHNICAL_CONTACT_IT: 'CASE_TECHNICAL_CONTACT_IT',
  CASE_TECHNICAL_CONTACT_LEGAL: 'CASE_TECHNICAL_CONTACT_LEGAL',
  CASE_TECHNICAL_CONTACT_MARKETING: 'CASE_TECHNICAL_CONTACT_MARKETING',
  CASE_TECHNICAL_CONTACT_OTHER: 'CASE_TECHNICAL_CONTACT_OTHER',
  CASE_TECHNICAL_CONTACT_PAYROLL: 'CASE_TECHNICAL_CONTACT_PAYROLL',
  CASE_TECHNICAL_CONTACT_PROCUREMENT: 'CASE_TECHNICAL_CONTACT_PROCUREMENT',
  CASE_TECHNICAL_CONTACT_SALES: 'CASE_TECHNICAL_CONTACT_SALES',
  CASE_TECHNICAL_CONTACT_SECURITY: 'CASE_TECHNICAL_CONTACT_SECURITY',
  CATALOG_MANAGER: 'CATALOG_MANAGER',
  CUSTOMER_SERVICE_ADMIN: 'CUSTOMER_SERVICE_ADMIN',
  DEPARTING_EMPLOYEE: 'DEPARTING_EMPLOYEE',
  ENABLEMENT: 'ENABLEMENT',
  ESPRESSIVE_ADMIN: 'ESPRESSIVE_ADMIN',
  FACILITIES_ADMIN: 'FACILITIES_ADMIN',
  FAQ_ADMIN: 'FAQ_ADMIN',
  FAQ_EDITOR: 'FAQ_EDITOR',
  FINANCE_ADMIN: 'FINANCE_ADMIN',
  HR_ADMIN: 'HR_ADMIN',
  HR_CONTACT: 'HR_CONTACT',
  HR_RECRUITER: 'HR_RECRUITER',
  IT_ADMIN: 'IT_ADMIN',
  IT_CONTACT: 'IT_CONTACT',
  KIOSK: 'KIOSK',
  MANAGER: 'MANAGER',
  MARKETING_ADMIN: 'MARKETING_ADMIN',
  ONBOARD_SCHEDULER: 'ONBOARD_SCHEDULER',
  OPERATIONS: 'OPERATIONS',
  PAYROLL_ADMIN: 'PAYROLL_ADMIN',
  SALES_ADMIN: 'SALES_ADMIN',
  SECURITY_ADMIN: 'SECURITY_ADMIN',
  SERVICE_AGENT: 'SERVICE_AGENT',
  SERVICE_LEAD: 'SERVICE_LEAD',
  SITE_MANAGER: 'SITE_MANAGER',
};

export default groupPermissions;
