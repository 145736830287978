// Selectors
import getTheme from '../../selectors/getTheme';

// Utils
import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state) => {
  const theme = getTheme(state);
  return {
    theme,
  };
};

export default buildCompositeController(mapStateToProps);
