import _ from 'lodash';

import PathGenerator from './PathGenerator';

class EndpointPathGenerator extends PathGenerator {
  constructor(pathsTree, origin) {
    if (!origin) {
      // Fix for IE10 since it doesn't support window.location.orgin
      if (!window.location.origin) {
        window.location.origin = `${window.location.protocol}//${
          window.location.hostname
        }${window.location.port ? `:${window.location.port}` : ''}`;
      }

      origin = window.location.origin || '';
    }

    super(pathsTree);

    this.setDomainResolution(origin);
  }

  isLocalRemoteDomain() {
    return this.TLD === 'dev' || this.TLD === 'local';
  }

  setDomainResolution(url) {
    url = this.forceDevDomainResolution() || url; // Might be useful sometimes

    const pathArray = url.split('://'); // Divide url in two
    const protocolPart = pathArray[0] || '';
    const hostnamePart = pathArray[1].split('/')[0] || '';

    this.protocol = protocolPart;
    const firstItemIndex = 0;
    this.hostname = hostnamePart.split(':')[firstItemIndex];
    this.TLD = _.last(this.hostname.split('.'));

    this.domain = this.getDomain();
    this.apiServer = this.getApiServer();
    this.wsServer = this.getWSServer();
  }

  /**
   * Returns full domain name of the external endpoint address
   * @return {string} domain
   */
  getDomain() {
    const domain = this.hostname;

    // tenant.esp
    if (this.TLD === 'esp') {
      // Running all-local client and tenant
      return domain;
    } else if (this.isLocalRemoteDomain()) {
      // substitutes .dev with .com at the end
      return `${domain.replace(/.dev$|.local$/, '.com')}`;
    } else {
      // external tenant
      return domain;
    }
  }

  /**
   * @return {string} Api server base url
   */
  getApiServer() {
    const httpProtocol = this.isLocalRemoteDomain() ? 'https' : this.protocol;

    return `${httpProtocol}://${this.getDomain()}`;
  }

  getWSServer() {
    // external tenant ws interface is always secured (wss)
    const wsProtocol =
      this.protocol === 'https' || this.isLocalRemoteDomain() ? 'wss' : 'ws';

    return `${wsProtocol}://${this.getDomain()}/ws`;
  }
  /**
   * Overrides genPath to prepend api server and append '/'
   */
  genPath(dotExpression, params = {}) {
    const path = super.genPath(dotExpression, params);

    return `${this.apiServer}${path}/`;
  }

  /**
   * The purpose of this is so devs can manually set a domain
   * If they're accessing, for instance, only an internal IP
   */
  forceDevDomainResolution() {
    const ACTIVATED = false; // Change to true for dev debugging. Do not commit changes
    const FORCED_DOMAIN = 'https://release.qa.espressive.com/'; // The domain you want to use

    if (ACTIVATED) {
      return FORCED_DOMAIN;
    }
    return null;
  }
}

export default EndpointPathGenerator;
