import React, { memo, useCallback } from 'react';
import classNames from 'classnames/bind';
import FilterCondition from './FilterCondition';
import FilterOperator from './FilterOperator';
import styles from './Filter.module.scss';
import { FILTER_STATE_SHAPE } from './__globals';
import { Flex } from '@espressive/cascara';
import { useInputStateCB, useToggleCB } from '../../hooks';

const cx = classNames.bind(styles);
const MemoizedCondition = memo(FilterCondition);
const MemoizedOperator = memo(FilterOperator);

const propTypes = {
  // The state of the Filter component from the useFilterState hook
  state: FILTER_STATE_SHAPE,
};

const FilterBase = ({ state }) => {
  const {
    basicSearchParams,
    isBasicCompatible,
    setBasicValue,
    setOperatorValue,
    removeOperator,
    setType,
    value,
  } = state;

  // We will need a more robust solution for setting the default value
  // here if we know we are in a compatible basic mode
  const [searchState, handleSetSearchState] = useInputStateCB(
    value?.[0]?.value
  );
  // We start with advanced filtering off unless the filter is not compatible with basic
  const [isAdvanced, handleToggleIsAdvanced] = useToggleCB(!isBasicCompatible);

  const isSearching = searchState.length > 0;

  const handleSubmitSearch = useCallback(
    (e) => {
      e.preventDefault();
      setBasicValue(searchState);
    },
    [setBasicValue, searchState]
  );

  const tempDisabled = false;

  return (
    <Flex className={styles._}>
      <div style={{ width: '100%' }}>
        {!isAdvanced && (
          <form
            className={`${styles.Search} ui fluid icon input`}
            onSubmit={handleSubmitSearch}
          >
            <input
              defaultValue={searchState}
              onChange={handleSetSearchState}
              placeholder={`Search by: ${basicSearchParams.join(', ')}`}
              style={{ borderRadius: '10rem' }}
              type='text'
            />
            <i className='search icon' />
          </form>
        )}

        <div
          className={cx('Tags', {
            advanced: isAdvanced,
            searching: isSearching,
          })}
        >
          {value?.map(({ isCondition, ...obj }, i) => {
            const tagKey = obj.parameter || i;

            const tagProps = {
              ...obj,
              isAdvanced,
            };

            return isCondition ? (
              <MemoizedCondition
                {...tagProps}
                index={i}
                key={tagKey}
                setType={setType}
              />
            ) : (
              <MemoizedOperator
                {...tagProps}
                index={i}
                isSearching={isSearching}
                key={tagKey}
                removeOperator={removeOperator}
                setOperatorValue={setOperatorValue}
                setType={setType}
              />
            );
          })}
        </div>
      </div>

      {isAdvanced && tempDisabled && (
        <button
          aria-label='Add Parameter'
          className='ui basic icon button'
          type='button'
        >
          <i aria-hidden='true' className='plus icon' />
        </button>
      )}

      <button
        aria-label={isAdvanced ? 'Search' : 'Filter'}
        className={classNames('ui basic icon button', { active: isAdvanced })}
        onClick={handleToggleIsAdvanced}
        type='button'
      >
        <i
          aria-hidden='true'
          className={cx('icon', {
            filter: !isAdvanced,
            search: isAdvanced,
          })}
        />
      </button>
    </Flex>
  );
};

FilterBase.propTypes = propTypes;

export default FilterBase;
