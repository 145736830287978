import Immutable from 'immutable';
import { has } from 'lodash';
import { intl } from 'esp-util-intl';
import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';
import BrowserPrompt from '../../globals/BrowserPrompt';

const mapStateProps = (state, ownProps) => {
  const teamID = has(ownProps, 'params.teamID') ? ownProps.params.teamID : null;
  const serviceTeamList = state.hasIn(['caseMgmt', 'serviceTeam', 'list'])
    ? state.getIn(['caseMgmt', 'serviceTeam', 'list'])
    : Immutable.List();
  const serviceTeamSelected = teamID
    ? serviceTeamList.find((s) => s.get('id') === Number(teamID))
    : null;
  const serviceTeam =
    teamID &&
    state
      .getIn(['caseMgmt', 'serviceTeam', 'list'])
      .find((team) => String(team.get('id')) === teamID);

  return {
    isLoading: state.getIn(['caseMgmt', 'serviceTeam', 'isLoading']),
    rows: state.getIn(['caseMgmt', 'serviceTeam', 'substates', 'list']),
    serviceTeamEID:
      serviceTeam && !serviceTeam.isEmpty() && serviceTeam.get('eid'),
    serviceTeamSelected: serviceTeamSelected,
    totalRows: state.getIn(['caseMgmt', 'serviceTeam', 'substates', 'count']),
  };
};

const mapDispatchProps = (dispatch, ownProps) => ({
  loadServiceDepartment() {
    const teamID = has(ownProps, 'params.teamID')
      ? ownProps.params.teamID
      : null;
    return dispatch(caseMgmtThunks.loadServiceTeam(teamID));
  },
  loadSubstates(teamEID, limit, offset) {
    dispatch(
      caseMgmtThunks.loadSubstates({
        limit,
        offset,
        serviceTeamEID: teamEID,
      })
    );
  },
  onRemove(row, rowIndex, limit, offset, page) {
    const shouldRemoveRow = BrowserPrompt.confirm(
      'Are you sure you want to delete the Substate?',
      {
        title: intl.formatMessage({
          id: 'label.barista_navigation_alert',
        }),
      }
    );
    if (shouldRemoveRow) {
      dispatch(
        caseMgmtThunks.removeSubstate(row, rowIndex, limit, offset, page)
      );
    }
  },
  onToggleActive(row, rowIndex) {
    dispatch(caseMgmtThunks.toggleActiveSubstate(row, rowIndex));
  },
});

export default buildCompositeController(mapStateProps, mapDispatchProps);
