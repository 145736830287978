import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';

export default class SearchExampleStandard extends Component {
  static propTypes = {
    handleItemSelected: PropTypes.func.isRequired,
  };

  state = { isLoading: false };

  handleSearchChange = (e, { value }) => {
    const { handleItemSelected } = this.props;

    this.setState({ isLoading: true });
    this.setState(
      {
        isLoading: false,
      },
      () => {
        handleItemSelected(value);
      }
    );
    return false;
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Search
        fluid
        loading={isLoading}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        showNoResults={false}
        style={{
          display: 'grid',
          marginBottom: '1em',
        }}
      />
    );
  }
}
