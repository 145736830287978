import buildCompositeController from '../../utils/buildCompositeController';

import workflowActions from '../../actions/workflowActions';

const mapDispatchToProps = (dispatch) => ({
  onBlurField(id) {
    dispatch(workflowActions.onBlurField(id));
  },
});

export default buildCompositeController(null, mapDispatchToProps);
