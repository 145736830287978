import { createBrowserHistory, createHashHistory } from 'history';

/**
 * Import this module to pass 'history' prop to React Router 4 and
 * to navigate programmatically thru the application.
 *
 * For reference on available methods check https://github.com/ReactTraining/history
 */

let browserHistory;

if (window.cordova || window.electron) {
  browserHistory = createHashHistory();
} else {
  browserHistory = createBrowserHistory();
}

export default browserHistory;
