import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

import WorkflowMenu from './WorkflowMenu';

class WorkflowHeader extends PureComponent {
  static propTypes = {
    areElementsVisible: PropTypes.bool,
    header: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    isCloseIconVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    isProgressActive: PropTypes.bool,
    nextUrl: PropTypes.string.isRequired,
    onBackButtonClick: PropTypes.func,
    onCloseButtonClick: PropTypes.func,
    progress: ImmutablePropTypes.mapContains({
      max_steps: PropTypes.number.isRequired,
      steps_completed: PropTypes.number.isRequired,
    }).isRequired,
    step: PropTypes.number.isRequired,
    workflowID: PropTypes.number,
    workflowTaskID: PropTypes.number,
  };

  static defaultProps = {
    areElementsVisible: false,
    isCloseIconVisible: false,
    isLoading: false,
    isProgressActive: false,
    onBackButtonClick: _.noop,
    onCloseButtonClick: _.noop,
    workflowID: null,
    workflowTaskID: null,
  };

  render() {
    const {
      areElementsVisible,
      header,
      isCloseIconVisible,
      isLoading,
      isProgressActive,
      nextUrl,
      progress,
      step,
      workflowID,
      workflowTaskID,
      onBackButtonClick,
      onCloseButtonClick,
    } = this.props;

    return (
      <WorkflowMenu
        areHeaderElementsVisible={areElementsVisible}
        backButton={header.prev}
        isCloseIconVisible={isCloseIconVisible}
        isLoading={isLoading}
        nextButton={header.next}
        nextUrl={nextUrl}
        onBackButtonClick={onBackButtonClick}
        onCloseButtonClick={onCloseButtonClick}
        placement='top'
        progress={progress}
        progressActive={isProgressActive}
        step={step}
        title={header.title}
        workflowID={workflowID}
        workflowTaskID={workflowTaskID}
      />
    );
  }
}

export default WorkflowHeader;
