import React, { useContext } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { Field as FormikField } from 'formik';
import IntegrationSetupWizardFormikContext from '../../IntegrationSetupWizard/IntegrationSetupWizardFormikContext';

const AppFederationMetadataURLField = () => {
  const { values, handleChange } = useContext(
    IntegrationSetupWizardFormikContext
  );

  return (
    <Form.Field>
      <label htmlFor='app_federation_metadata_url'>
        {'App Federation Metadata URL'}
      </label>
      <FormikField
        component={Input}
        id='app_federation_metadata_url'
        name='app_federation_metadata_url'
        onChange={handleChange}
        type='text'
        value={values?.app_federation_metadata_url || ''}
      />
    </Form.Field>
  );
};

export default AppFederationMetadataURLField;
