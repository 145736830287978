import React, { PureComponent } from 'react';

// import {Button}           from 'semantic-ui-react';
import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import SliderControls from './SliderControls';
import SliderItem from './SliderItem';
import SliderStrip from './SliderStrip';
import SwipeableWrapper from '../../../../globals/SwipeableWrapper';

// There are other attributes in this object but they are not needed in Slider
const tabShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
});

class Slider extends PureComponent {
  static propTypes = {
    activeSlide: PropTypes.number,
    carouselSwipe: PropTypes.bool, // Whether swipe will act circularly as a carousel
    children: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list])
      .isRequired,
    navType: PropTypes.oneOf(['default', 'numbers', 'tabs']),
    setActiveSlide: PropTypes.func,
    sliderHeight: PropTypes.string, // Optional height
    sliderItemHeight: PropTypes.string, // Optional Height for item
    swipeable: PropTypes.bool,
    tabs: PropTypes.arrayOf(tabShape),
  };

  static defaultProps = {
    activeSlide: 0,
    carouselSwipe: true,
    navType: 'default',
    setActiveSlide: null,
    sliderHeight: void 0,
    sliderItemHeight: void 0,
    swipeable: true,
    tabs: void 0,
  };

  state = {
    active: 0,
  };

  handleSetActive = (clickedActive) => {
    const { setActiveSlide } = this.props;

    if (setActiveSlide) {
      // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
      setActiveSlide(clickedActive);
    } else {
      this.setState({
        active: clickedActive,
      });
    }
  };

  handleGetActive() {
    const { activeSlide, setActiveSlide } = this.props;
    const { active } = this.state;

    if (setActiveSlide) {
      // Use internal state when no external handler
      return activeSlide;
    } else {
      return active;
    }
  }

  handleGetSize() {
    const { children } = this.props;

    return children.length || children.size; // wheter immutable or array
  }

  // NOTE: This needs to replace the left/right move funtions to reduce boilerplate
  handleMove = (dir) => {
    const { carouselSwipe, setActiveSlide } = this.props;

    if (_.isEmpty(dir)) {
      // eslint-disable-next-line no-console -- debugging
      console.warn('ERROR: Direction must be defined in handleMove()');
    } else if (dir !== 'left' || dir !== 'right') {
      // eslint-disable-next-line no-console -- debugging
      console.warn(
        'ERROR: Direction can only be "left" or "right" in handleMove()'
      );
    }

    const active = this.handleGetActive();
    const childrenSize = this.handleGetSize();

    let moveToActive = active + 1;

    if (carouselSwipe) {
      moveToActive = moveToActive > childrenSize - 1 ? 0 : moveToActive;
    } else {
      moveToActive =
        moveToActive > childrenSize - 1 ? childrenSize - 1 : moveToActive;
    }

    if (setActiveSlide) {
      // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
      setActiveSlide(moveToActive);
    } else {
      this.setState({
        active: moveToActive,
      });
    }
  };

  // TODO: Kill this and move to handleMove('left')
  handleMoveLeft = () => {
    const { carouselSwipe, setActiveSlide } = this.props;

    const active = this.handleGetActive();
    const childrenSize = this.handleGetSize();

    let moveToActive = active + 1;

    if (carouselSwipe) {
      // Making sure we don't exceed the limits of available children
      moveToActive = moveToActive > childrenSize - 1 ? 0 : moveToActive;
    } else {
      moveToActive =
        moveToActive > childrenSize - 1 ? childrenSize - 1 : moveToActive;
    }
    // console.log('handleMoveLeft', moveToActive);

    if (setActiveSlide) {
      // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
      setActiveSlide(moveToActive);
    } else {
      this.setState({
        active: moveToActive,
      });
    }
  };

  // TODO: Kill this and move to handleMove('right')
  handleMoveRight = () => {
    const { carouselSwipe, setActiveSlide } = this.props;

    const active = this.handleGetActive();
    const childrenSize = this.handleGetSize();

    let moveToActive = active - 1;

    if (carouselSwipe) {
      // /making sure we dont pass down to 0 and instead go to the last child
      moveToActive = moveToActive < 0 ? childrenSize - 1 : moveToActive;
    } else {
      moveToActive = moveToActive < 0 ? 0 : moveToActive;
    }
    // console.log('handleMoveRight', moveToActive);

    if (setActiveSlide) {
      // TODO: Need to integrate to return the clickedActive value to a defined setActiveSlide function
      setActiveSlide(moveToActive);
    } else {
      this.setState({
        active: moveToActive,
      });
    }
  };

  render() {
    const {
      children,
      navType,
      // pageNumbers,
      sliderHeight,
      sliderItemHeight,
      swipeable,
      tabs,
    } = this.props;

    const childrenSize = this.handleGetSize();
    const activeSlide = this.handleGetActive();

    return (
      <div
        data-component='Slider'
        style={{
          height: sliderHeight,
        }}
      >
        {tabs ? (
          <SliderControls
            activeSlide={activeSlide}
            navType='tabs'
            onSetActive={this.handleSetActive}
            size={childrenSize}
            tabs={tabs}
          />
        ) : null}

        <SwipeableWrapper
          onSwipedLeft={swipeable ? this.handleMoveLeft : void 0}
          onSwipedRight={swipeable ? this.handleMoveRight : void 0}
          trackMouse
        >
          <div
            data-component='Swipeable'
            style={{
              height: sliderItemHeight || 'auto',
              overflow: 'hidden',
            }}
          >
            <SliderStrip
              activePosition={activeSlide}
              count={childrenSize}
              height={sliderHeight}
            >
              {children.map((sliderContent, i) => (
                <SliderItem
                  count={childrenSize}
                  height={sliderItemHeight ? '100%' : void 0}
                  key={i}
                >
                  {sliderContent}
                </SliderItem>
              ))}
            </SliderStrip>
          </div>
        </SwipeableWrapper>

        {!tabs && (navType === 'numbers' || navType === 'default') ? (
          <SliderControls
            activeSlide={activeSlide}
            navType={navType}
            onSetActive={this.handleSetActive}
            size={childrenSize}
          />
        ) : null}
      </div>
    );
  }
}

export default Slider;
