import APIcall from '../../../../../../utils/APIcall';
import endpointGenerator from '../../../../../../utils/endpointGenerator';

const updateTranslation = async ({ id, text }) => {
  return APIcall.patch({
    data: {
      text,
    },
    token: true,
    url: endpointGenerator.genPath('commons.localization.instance', {
      localizationID: id,
    }),
  });
};

export default updateTranslation;
