import { submit } from 'redux-form/immutable';
import buildCompositeController from '../../utils/buildCompositeController';
import getSelectMyGearSelectedProductsWithFulfillmentQuestions from '../../selectors/getSelectMyGearSelectedProductsWithFulfillmentQuestions';
import selectMyGearActions from '../../actions/selectMyGearActions';
import selectMyGearThunks from '../../actions/selectMyGearThunks';

const mapStateToProps = (state) => {
  const workflowState = state.get('workflowState');
  const frontendScratch = workflowState.get('frontendScratch');
  const isLoading = workflowState.get('loading');

  let missingBundle = false;
  let roleBundleID;
  if (
    !frontendScratch ||
    frontendScratch.isEmpty() ||
    !frontendScratch.get('bundle')
  ) {
    missingBundle = true;
  } else {
    roleBundleID = frontendScratch.get('bundle');
  }

  // fulfillment options stuff
  const productFulfillmentForms = state.getIn(['form', 'ProductFulfillment']);

  let isEquipFulfillmentFormsValid = false;

  if (productFulfillmentForms) {
    isEquipFulfillmentFormsValid = productFulfillmentForms.every(
      (fulfillmentForm) => !fulfillmentForm.has('syncErrors')
    );
  }

  const selectedProductsWithFulfillmentQuestions = getSelectMyGearSelectedProductsWithFulfillmentQuestions(
    state
  );

  return {
    isEquipFulfillmentFormsValid,
    isLoading,
    missingBundle,
    roleBundleID,
    selectedProductsWithFulfillmentQuestions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  answerFulfillmentQuestions(answersByProduct) {
    dispatch(selectMyGearActions.answerFulfillmentQuestions(answersByProduct));
  },

  onMoveNextStep(moveWorkflowNextTask) {
    dispatch(selectMyGearThunks.moveNextStep(moveWorkflowNextTask));
  },

  onMovePrevStep(moveWorkflowPrevTask) {
    dispatch(selectMyGearThunks.movePrevStep(moveWorkflowPrevTask));
  },

  // fulfillment options stuff
  submitProductFulfillmentForms(selectedProductsWithFulfillmentQuestions) {
    selectedProductsWithFulfillmentQuestions.forEach((productID) =>
      dispatch(submit(`ProductFulfillment.${productID}`))
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
