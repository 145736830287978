import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsBenefitsEnrollment extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0007',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M68.54 27.71V22.5c-.006-3.588-2.912-6.494-6.5-6.5h-38c-3.588.006-6.494 2.912-6.5 6.5v50.8c.006 3.588 2.912 6.494 6.5 6.5h38c3.588-.006 6.494-2.912 6.5-6.5V41.36l-3 3V73.3c.008.933-.357 1.83-1.014 2.494-.658.663-1.553 1.036-2.486 1.036h-38c-1.933 0-3.5-1.567-3.5-3.5V22.5c0-1.933 1.567-3.5 3.5-3.5h38c1.933 0 3.5 1.567 3.5 3.5v8.13l3-3' />
          <path d='M73.57 32.96l-14.1 14.1c-.043-.01-.087-.01-.13 0-.37.05-.7-.46-.87-.81l14.19-14.21-.37-.37-14.17 14.16c-.61.07-.81-.56-.86-.78l14.19-14.22-.37-.37-14.21 14.21v.05c-.45-.15-.81-.84-.94-1.16l12.6-12.63 1.5-1.5-.74-.74-.76.76v.05L54.9 43.07c-.06.06-.104.13-.13.21l-2.11 6.39c-.056.187-.005.39.133.527.138.138.34.19.527.133l6.42-2.11c.08-.026.15-.07.21-.13L74.31 33.7l-.74-.74zM56.03 48.33l-1.37-1.37.93-2.81c.222.505.636.9 1.15 1.1.11.6.604 1.053 1.21 1.11.16.42.433.787.79 1.06l-2.71.91zm21.58-20.19l-2.77-2.77c-.617-.614-1.613-.614-2.23 0l-2.14 2.14-.39.39 5 5 .39-.39c.027-.03.05-.064.07-.1l2.1-2.1c.567-.617.554-1.57-.03-2.17zm-50.74 1.11h29.56c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5H26.87c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5zm0 10.1h22.92c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5H26.87c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5zm0 10.06h20.57c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5H26.87c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5zm32.16 7.06H26.87c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h32.16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm0 10.06H26.87c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h32.16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5z' />
        </g>
      </svg>
    );
  }
}

export default CardsBenefitsEnrollment;
