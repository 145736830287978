import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Segment,
} from 'semantic-ui-react';
import { reduxForm } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { curry, memoize } from 'lodash';

// Molecule
import ValidatedField from '../../../molecules/ValidatedField';
import FormInputTextarea from '../../../molecules/FormInputTextarea';
import FileUploadForm from '../../../molecules/FileUploadForm';
import TenantImage from '../../../molecules/TenantImage';

import TenantImageTypes from '../../../../utils/TenantImageTypes';
// Controller
import LinkMetadataController from '../../../controllers/LinkMetadataController';

class LinkMetadata extends Component {
  static propTypes = {
    /** Redux form dirty state */
    dirty: PropTypes.bool,
    /** Redux form submit handler */
    handleSubmit: PropTypes.func.isRequired,

    /** Saving Image state */
    isLoadingImage: PropTypes.bool,
    /** Saving detail state */
    isLoadingTenant: PropTypes.bool,
    /** Meta Image of the tenant */
    metaImage: ImmutablePropTypes.map,
    /** Remove image handler */
    removeImage: PropTypes.func.isRequired,
    /** Submit Image handler */
    submitImage: PropTypes.func.isRequired,
    /** tenantID */
    // tenantID           : PropTypes.number,
    /** Array of image type accepted in the tenant upload image form */
    typeImagesAccepted: PropTypes.string.isRequired,
  };

  static defaultProps = {
    dirty: false,
    isLoadingImage: false,
    isLoadingTenant: false,
    metaImage: Immutable.Map(),
    // tenantID        : null,
  };

  shouldComponentUpdate(nextProps) {
    const { metaImage, isLoadingImage, isLoadingTenant } = this.props;

    if (nextProps.metaImage !== metaImage) {
      return true;
    }

    if (nextProps.isLoadingImage !== isLoadingImage) {
      return true;
    }

    if (nextProps.isLoadingTenant !== isLoadingTenant) {
      return true;
    }

    return true;
  }

  handleTenantImageSubmit = memoize(
    curry((type, tenantImage) => {
      const { submitImage } = this.props;

      submitImage(type, tenantImage, intl);
    })
  );

  handleRemoveMetaImage = (e) => {
    const { metaImage, removeImage } = this.props;
    e.preventDefault();

    if (metaImage && !metaImage.isEmpty()) {
      removeImage(metaImage.get('id'));
    }
  };

  render() {
    const {
      dirty,
      isLoadingTenant,
      isLoadingImage,
      handleSubmit,
      typeImagesAccepted,
      metaImage,
      // tenantID,
    } = this.props;

    // tenantID;

    let classMeta = 'meta ';
    if (metaImage) {
      const width = metaImage.get('width');
      const height = metaImage.get('height');

      if (width && height) {
        classMeta += width > height ? 'landscape' : 'portrait';
      }
    }

    return (
      <Segment as={Grid} attached columns={2} divided>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_global_settings',
          })}
        />

        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h4' content='Link Details' dividing />
            <Form onSubmit={handleSubmit}>
              <ValidatedField
                component={FormInputTextarea}
                label='Description'
                name='description'
                toggle
              />

              {dirty && (
                <Form.Button
                  color='green'
                  content='Save'
                  disabled={isLoadingTenant}
                  loading={isLoadingTenant}
                />
              )}
            </Form>

            <Header as='h4' content='Link Image' dividing />
            {'You can set a custom Meta Image for your link'}

            <Divider hidden />

            <FileUploadForm
              accept={typeImagesAccepted}
              isLoading={isLoadingImage}
              onFileSubmit={this.handleTenantImageSubmit(TenantImageTypes.META)}
              uploadFieldId='meta_image'
            />
            {metaImage && !metaImage.isEmpty() && (
              <Button content='Remove' onClick={this.handleRemoveMetaImage} />
            )}
          </Grid.Column>

          <Grid.Column width={6}>
            <Header as='h4' content='Link Image Preview' dividing />

            {metaImage && !metaImage.isEmpty() && (
              <TenantImage className={classMeta} type={TenantImageTypes.META} />
            )}
          </Grid.Column>
        </Grid.Row>

        <Divider hidden />
      </Segment>
    );
  }
}

const LinkMetadataTest = LinkMetadata;

// eslint-disable-next-line no-class-assign -- DEV-1526
LinkMetadata = reduxForm({
  enableReinitialize: true,
  form: 'TenantLinksConfiguration',
})(LinkMetadata);

// eslint-disable-next-line no-class-assign -- DEV-1526
LinkMetadata = LinkMetadataController(LinkMetadata);
export { LinkMetadataTest };
export default LinkMetadata;
