const api_baristabot_v0_1_integrations_slack_channels = {
  slack_channels: [
    {
      channels: [
        {
          id: 'C02DZE0LAUA',
          name: 'newchanneltest',
        },
      ],
      id: 'TD02UH8ER',
      name: 'Espressive Slack Testing',
    },
    {
      channels: [
        {
          id: 'C02DZF3KFFV',
          name: 'justatest',
        },
        {
          id: 'C02BNL04HFC',
          name: 'test',
        },
        {
          id: 'C02EP41B516',
          name: 'channelsupport',
        },
      ],
      id: 'E01KLS9KNGK',
      name: 'els878b',
    },
  ],
};

export default api_baristabot_v0_1_integrations_slack_channels;
