import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Accordion, Header, Message } from 'semantic-ui-react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FieldArray } from 'redux-form/immutable';
import { Prompt } from 'react-router-dom';

// molecules
import OnboardingActivityScheduleGroup from './OnboardingActivityScheduleGroup';
import ValidatedForm from './ValidatedForm';
import ValidatedField from './ValidatedField';
import FormInputTextarea from './FormInputTextarea';
import ScheduleGroups from './ScheduleGroups';
import OnboardingActivityScheduleFormButtons from './OnboardingActivityScheduleFormButtons';
// Global
import ScheduleState from '../../globals/ScheduleState';
// Controller
import onboardActivityFormController from '../controllers/onboardActivityFormController';

let OnboardingActivityScheduleForm = class OnboardingActivityScheduleForm extends PureComponent {
  static propTypes = {
    /** Action on a schedule ID (copy) */
    action: PropTypes.string,
    /** Add group recipient **/
    addNewGroup: PropTypes.func.isRequired,
    /** Delete template schedule */
    deleteOneTemplate: PropTypes.func.isRequired,
    /** Form error from redux form */
    formError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    /** values coming from redux form */
    formValues: ImmutablePropTypes.map,
    /** Initialize redux form */
    initForm: PropTypes.func.isRequired,
    /** Loading state */
    isLoading: PropTypes.bool,
    /** Detect pristine for the form */
    isPristine: PropTypes.bool,
    /** Save Schedule */
    saveSchedule: PropTypes.func.isRequired,
    /** schedule data*/
    schedule: ImmutablePropTypes.map,
    /** ID of the template OR 'new' for a new template. Please update type checking for this. */
    scheduleID: PropTypes.string,
    /** Template selected with interval */
    templateSelected: ImmutablePropTypes.list,
  };

  static defaultProps = {
    /** If templateID is null, we should display the first template in the list. Use a selector. */
    action: null,
    formError: null,
    formValues: Immutable.Map(),
    isLoading: false,
    isPristine: false,
    schedule: Immutable.Map(),
    scheduleID: null,
    templateSelected: Immutable.List(),
  };

  state = {
    activitiesAccordionIsActive: true,
    fieldChanged: false,
    isSaving: false,
    recipientsAccordionIsActive: true,

    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    scheduleID: this.props.scheduleID,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check if we reload the page form an updated or a new created schedule
    const { scheduleID, initForm } = this.props;
    if (nextProps.scheduleID !== scheduleID) {
      const initialValues = {
        description: nextProps.schedule.get('description'),
        groups: nextProps.schedule.get('groups'),
        name: nextProps.schedule.get('name'),
        status: nextProps.schedule.get('status'),
      };

      // Init the new form with the new name

      initForm(nextProps.scheduleID, initialValues);

      this.setState({
        isSaving: false,
        scheduleID: nextProps.scheduleID,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = true;

  onHandleSaveForm = (status) => {
    const { action, formValues, saveSchedule } = this.props;
    const { scheduleID } = this.state;

    const scheduleName = formValues.get('name');

    // Todo - Use react intl
    const message =
      scheduleID === 'new' || action === 'copy'
        ? `The draft ${scheduleName} activity has been created`
        : `The draft ${scheduleName} activity has been updated`;

    const msg = {
      header: 'Success',
      message,
    };

    const newScheduleID = action || scheduleID;

    const copyID = action ? scheduleID : null;

    saveSchedule(newScheduleID, copyID, status, msg, () => {
      if (this._isMounted) {
        this.setState({
          isSaving: true,
        });
      }
    });
  };

  handeDeleteTemplateinterval = (intervalID, templateID, msg) => {
    const { deleteOneTemplate, scheduleID, action } = this.props;
    const isANewSchedule = action || scheduleID === 'new';
    deleteOneTemplate(intervalID, templateID, isANewSchedule, msg);
  };

  handleSubmit = () => {
    // Do nothing
  };

  formValidation = (values, form) => {
    if (form.pristine && !form.invalid) {
      return {};
    }
    const error = {};

    if (!values.get('name')) {
      error.name = 'Please add a name';
    }

    if (!values.get('description')) {
      error.description = 'Please add a description'; // this message is not visible, we don't need translations for now
    }

    return error;
  };

  handleFieldChange = () => {
    const { fieldChanged } = this.state;

    if (!fieldChanged) {
      this.setState({
        fieldChanged: true,
      }); // Allow to pass formError to Name and Description
    }
  };

  handleToggleActivitiesAccordion = () => {
    this.setState((previousState) => ({
      activitiesAccordionIsActive: !previousState.activitiesAccordionIsActive,
    }));
  };

  handleToggleRecipientsAccordion = () => {
    this.setState((previousState) => ({
      recipientsAccordionIsActive: !previousState.recipientsAccordionIsActive,
    }));
  };

  render() {
    const {
      action,
      addNewGroup,
      formError,
      formValues,
      isLoading,
      isPristine,
      schedule,
      templateSelected,
    } = this.props;

    const addInitName = action
      ? `${schedule.get('name')} (COPY)`
      : schedule.get('name');
    const initialValues = schedule.get('description')
      ? {
          description: schedule.get('description'),
          groups: schedule.get('groups'),
          name: addInitName,
          status: schedule.get('status'),
        }
      : null;
    const {
      activitiesAccordionIsActive,
      recipientsAccordionIsActive,
      isSaving,
      scheduleID,
      fieldChanged,
    } = this.state;

    /** Check if a custom Group has been added by check all value of first group */
    let isInitialGroups = true;
    if (
      formValues &&
      formValues.get('groups') &&
      formValues.get('groups').size === 1
    ) {
      formValues.getIn(['groups', 0]).forEach((value, key) => {
        if (key !== 'user_count' && value) {
          isInitialGroups = false;
        }
      });
    }

    const name = formValues.get('name');
    const description = formValues.get('description');

    let allowPrompt = Boolean(
      !isSaving && (!isInitialGroups || name || description)
    );

    if (scheduleID !== 'new') {
      allowPrompt = Boolean(!isSaving && !isInitialGroups && !isPristine);
    }
    const allowRepublish = !isPristine;
    const displayDraft =
      scheduleID === 'new' ||
      schedule.get('status') === ScheduleState.STATUS_DRAFT ||
      action === 'copy';
    const displayEditMode =
      displayDraft || schedule.get('status') === ScheduleState.STATUS_PUBLISHED;
    const disableButtons = Boolean(formError);

    const displayErrorsValidation = !isPristine && fieldChanged;
    const formValueGroup = formValues.get('groups') || Immutable.List();

    return (
      <ValidatedForm
        className='onboarding-activity-schedule-detail'
        enableReinitialize
        form={`ScheduleActivityForm_${scheduleID}`}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validate={this.formValidation}
      >
        <Prompt
          message={intl.formatMessage({
            id: 'message.unsaved_changes_content',
          })}
          when={allowPrompt}
        />

        <OnboardingActivityScheduleFormButtons
          action={action}
          allowRepublish={allowRepublish}
          disableButtons={disableButtons}
          emptyTemplate={templateSelected.isEmpty()}
          isLoading={isLoading}
          saveSchedule={this.onHandleSaveForm}
          scheduleID={scheduleID}
          status={schedule.get('status')}
        />

        {/* NOTE: We should probably limit the following two fields to 120 chars. */}
        <ValidatedField
          formError={
            (displayErrorsValidation && formError && formError.name) || null
          }
          label='Schedule Name'
          name='name'
          onChange={this.handleFieldChange}
          placeholder='Title that will display on the task card.'
          readOnly={!displayEditMode}
          transparent={!displayEditMode}
        />

        <ValidatedField
          component={FormInputTextarea}
          formError={
            (displayErrorsValidation && formError && formError.description) ||
            null
          }
          label='Schedule Description'
          name='description'
          onChange={this.handleFieldChange}
          placeholder='Enter a description'
          readOnly={!displayEditMode}
          rows={3}
        />

        <Accordion exclusive={false} fluid>
          <Accordion.Title
            active={recipientsAccordionIsActive}
            onClick={this.handleToggleRecipientsAccordion}
          >
            <Header as='h4' content={'Recipients'} dividing icon='dropdown' />
          </Accordion.Title>

          <Accordion.Content active={recipientsAccordionIsActive}>
            <FieldArray
              addNewGroup={addNewGroup}
              component={ScheduleGroups}
              formValues={formValueGroup}
              name='groups'
              readOnly={!displayEditMode}
              schedule={schedule}
            />
          </Accordion.Content>
        </Accordion>

        <Accordion exclusive={false} fluid>
          <Accordion.Title
            active={activitiesAccordionIsActive}
            onClick={this.handleToggleActivitiesAccordion}
          >
            <Header as='h4' content={'Activities'} dividing icon='dropdown' />
          </Accordion.Title>

          <Accordion.Content active={activitiesAccordionIsActive}>
            {(templateSelected && !templateSelected.isEmpty()) || isLoading ? (
              templateSelected.map((group) => (
                <OnboardingActivityScheduleGroup
                  action={action}
                  activities={group.get('activities')}
                  deleteOneTemplate={this.handeDeleteTemplateinterval}
                  key={group.get('id')}
                  readOnly={!displayEditMode}
                  scheduleID={scheduleID}
                  scheduleStatus={schedule.get('status')}
                  title={group.get('title')}
                />
              ))
            ) : (
              <Message
                content='This schedule does not have any onboarding activity templates added yet. Please click the + button on the right to add one.'
                info
              />
            )}
          </Accordion.Content>
        </Accordion>
      </ValidatedForm>
    );
  }
};

const OnboardingActivityScheduleFormTest = OnboardingActivityScheduleForm;

OnboardingActivityScheduleForm = onboardActivityFormController(
  OnboardingActivityScheduleForm
);
export { OnboardingActivityScheduleFormTest };
export default OnboardingActivityScheduleForm;
