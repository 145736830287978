import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  forceReset: {
    default: false,
    type: 'bool',
  },

  inValidValue: {
    default: 'noskip',
    required: true,
    type: 'string',
  },
  // Using defaults in 'template' (initially defined in Blockbase) will  make
  // the propType non required
  inputValue: {
    objectShape: {
      map: {
        required: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  tempDataName: {
    default: '',
    required: true,
    type: 'string',
  },
  template: {
    default: 'skipTask01',
  },
  validValue: {
    default: 'skip',
    required: true,
    type: 'string',
  },
});

// Object Definition used by blobifier
const skipTaskDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Skip Task',
  templates: ['skipTask01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, skipTaskDefinition, TypeDefaults };
