const profileActions = {};

profileActions.GET_USERS_PROFILE_START = 'GET_USERS_PROFILE_START';
profileActions.GET_USERS_PROFILE_SUCCESS = 'GET_USERS_PROFILE_SUCCESS';
profileActions.GET_USERS_PROFILE_ERROR = 'GET_USERS_PROFILE_ERROR';

profileActions.getUsersProfileStart = () => ({
  type: profileActions.GET_USERS_PROFILE_START,
});

profileActions.getUsersSucess = (users) => ({
  type: profileActions.GET_USERS_PROFILE_SUCCESS,
  users,
});

profileActions.getUsersProfileError = () => ({
  type: profileActions.GET_USERS_PROFILE_ERROR,
});

export default profileActions;
