import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({
  callCost: null,
  data: null,
  deploymentDate: {
    date: '',
    isAlreadyLoaded: false,
    isLoading: false,
  },
  intervalIds: [],
  isLoadingData: false,
  isLoadingReport: false,
  isLoadingStatusUrl: false,
  jobStatusUrl: null,
  reportId: null,
  summary: null,
  survey: null,
});

const adminReportsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_DEPLOYMENT_DATE_START: {
      return state.setIn(['deploymentDate', 'isLoading'], true);
    }
    case actionTypes.GET_DEPLOYMENT_DATE_SUCCESS: {
      return state
        .setIn(['deploymentDate', 'isAlreadyLoaded'], true)
        .setIn(['deploymentDate', 'isLoading'], false)
        .setIn(['deploymentDate', 'date'], action.date);
    }
    case actionTypes.GET_DEPLOYMENT_DATE_FAIL: {
      return state.setIn(['deploymentDate', 'isLoading'], false);
    }

    case actionTypes.ADD_INTERVAL_ID: {
      const intervalIds = state.get('intervalIds');
      if (!intervalIds.contains(action.intervalId)) {
        return state.update('intervalIds', (original) =>
          original.push(action.intervalId)
        );
      }
      return state;
    }
    case actionTypes.CLEAR_INTERVAL_IDS:
      return state.set('intervalIds', fromJS([]));

    case actionTypes.RESET_REPORTS_DATA:
      return INITIAL_STATE;

    case actionTypes.REMOVE_INTERVAL_ID:
      return state.update('intervalIds', (original) =>
        original.splice(original.indexOf(action.intervalId), 1)
      );

    case actionTypes.GET_STATUS_URL_ERROR:
      return state
        .set('error', fromJS(action.error))
        .set('isLoadingStatusUrl', false)
        .set('jobStatusUrl', null);

    case actionTypes.GET_STATUS_URL_START:
      return state
        .set('error', null)
        .set('isLoadingStatusUrl', true)
        .set('jobStatusUrl', null);

    case actionTypes.GET_STATUS_URL_SUCCESS:
      return state
        .set('error', null)
        .set('isLoadingStatusUrl', false)
        .set('jobStatusUrl', action.jobStatusUrl);

    case actionTypes.REPORTS_SET_DISPLAY_OPTIONS:
      return state.set('displayOptions', fromJS(action.displayOptions));

    case actionTypes.REPORTS_LOAD_REPORT_START:
      return state.set('isLoadingReport', true);

    case actionTypes.REPORTS_LOAD_REPORT_SUCCESS:
      return state.set('isLoadingReport', false);

    case actionTypes.REPORTS_LOAD_REPORT_ERROR:
      return state.set('isLoadingReport', false);

    case actionTypes.REPORTS_LOAD_DATA_END:
      return state.set('isLoadingData', false);

    case actionTypes.REPORTS_LOAD_DATA_START:
      return state.set('isLoadingData', true);

    case actionTypes.REPORTS_LOAD_DATA_SUCCESS:
      return state
        .set('data', fromJS(action.data))
        .set('error', null)
        .set('isLoadingData', false);

    case actionTypes.REPORTS_LOAD_DATA_ERROR:
      return state.set('isLoadingData', false).set('error', action.error);

    case actionTypes.REPORTS_LOAD_SUMMARY_DATA_SUCCESS:
      return state
        .set('isLoadingData', false)
        .set('summary', fromJS(action.data));

    case actionTypes.REPORTS_LOAD_SURVEY:
      return state.set('survey', fromJS(action.survey));

    case actionTypes.REPORTS_GET_ID_SUCCESS:
      return state
        .set('isLoadingData', false)
        .set('reportId', fromJS(action.reportId));

    case actionTypes.REPORTS_CALL_COST_SUCCESS:
      return state
        .set('isLoadingData', false)
        .set('callCost', fromJS(action.callCost));

    default:
      return state;
  }
};

export default adminReportsReducer;
