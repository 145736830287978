import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { noop } from 'lodash';
// Controller
import TenantImageController from '../../controllers/TenantImageController';
// Util
import TenantImageTypes from '../../../utils/TenantImageTypes';
// Globals
import TypeLogo, { TypeDefaults } from '../../../globals/blocks/TypeLogo';
import { ImageDefaults } from 'esp-assets';

// ES6 Map
const imageDefaultsByType = new Map();
imageDefaultsByType.set(TenantImageTypes.LOGO, ImageDefaults.COMPANY_LOGO);

class Logo02 extends Component {
  static propTypes = {
    ...TypeLogo,
    alternateLogo: ImmutablePropTypes.map,
    images: ImmutablePropTypes.list,
    loadTenantImages: PropTypes.func,
  };
  static defaultProps = {
    ...TypeDefaults,
    alternateLogo: null,
    images: List(),
    loadTenantImages: noop,
  };

  componentDidMount() {
    const { loadTenantImages, type } = this.props;
    // ToDo: this will load all the images, has to be fixed in T8714
    loadTenantImages(type);
  }
  render() {
    const { alternateLogo, type, images } = this.props;

    const img = images
      .reverse()
      .filter((img) => img.get('type') === type)
      .minBy((img) => img.get('ordering'));

    let src;

    const { template, text1, text2 } = this.props;

    if (
      alternateLogo &&
      (alternateLogo.get('image') || alternateLogo.get('svg'))
    ) {
      // tries to use the svg version first
      src = alternateLogo.get('svg') || alternateLogo.get('image');
    } else if (img) {
      // tries to use the svg version first
      src = img.get('svg') || img.get('image');
    }

    src = src ? src : imageDefaultsByType.get(TenantImageTypes.LOGO);

    return (
      <div
        className={classNames('block padding', {})}
        data-blocktype={type}
        data-template={template}
      >
        {text1 && <div className='text'>{text1}</div>}
        <img alt='' className='logo' src={src} />

        {text2 && <div className='text'>{text2}</div>}
      </div>
    );
  }
}

export const Logo02Test = Logo02;

export default TenantImageController(Logo02);
