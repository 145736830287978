import { Icon, List } from 'semantic-ui-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import UserImage from '../atoms/UserImage';
import UserName from '../atoms/UserName';

class SelectableUser extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    user: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    isSelected: false,
    onClick: _.noop,
  };

  handleClick = (e) => {
    e.preventDefault();

    const { user, onClick } = this.props;

    onClick(user);
  };

  render() {
    const { user, isLoading, isSelected } = this.props;

    return (
      <List.Item
        as={NavLink}
        className={classNames({
          loading: isLoading,
        })}
        onClick={this.handleClick}
        to='#'
      >
        {isSelected && (
          <Icon
            className='right floated'
            color='green'
            name='check'
            size='large'
          />
        )}

        <div className='image-container'>
          <UserImage avatar user={user} />
        </div>

        <List.Content>
          <UserName as={List.Header} user={user} />
          <List.Content>{user.get('title')}</List.Content>
          <List.Description>{user.get('department')}</List.Description>
        </List.Content>
      </List.Item>
    );
  }
}

export default SelectableUser;
