import buildCompositeController from '../../utils/buildCompositeController';
import caseMgmtThunks from '../../actions/caseMgmtThunks';

const mapStateProps = (state) => {
  const serviceTeam = state.getIn(['caseMgmt', 'serviceTeam']);
  return {
    isLoading: serviceTeam.get('isLoading'),
    locations: serviceTeam.get('locations'),
  };
};

const mapDispatchProps = (dispatch) => ({
  addServiceTeamLocation: (teamID, location) => {
    dispatch(caseMgmtThunks.addServiceTeamLocation(teamID, location)).then(
      () => {
        dispatch(caseMgmtThunks.getServiceTeamLocations(teamID));
      }
    );
  },
  getServiceTeamLocations: (teamID) => {
    dispatch(caseMgmtThunks.getServiceTeamLocations(teamID));
  },

  removeServiceTeamLocation: (teamID, locationTeamID) => {
    dispatch(caseMgmtThunks.removeServiceTeamLocation(locationTeamID)).then(
      () => {
        dispatch(caseMgmtThunks.getServiceTeamLocations(teamID));
      }
    );
  },
});

export default buildCompositeController(mapStateProps, mapDispatchProps);
