import endpointGenerator from '../../../../../../utils/endpointGenerator';
import APIcall from '../../../../../../utils/APIcall';
import async from 'async';

const authorizeGmailCall = (emailID) => {
  const LANDING_URI = `${window.location.origin}${window.location.pathname}?success=true`;
  return APIcall.post({
    data: {
      landing_uri: `${LANDING_URI}&credentialId=${emailID}`,
      no_redirect: 1,
    },
    token: true,
    url: endpointGenerator.genPath(
      'emails.credentials.instance.oauth_connect',
      { emailID }
    ),
  });
};

const unauthorize = (emailID) =>
  APIcall.post({
    data: {},
    token: true,
    url: endpointGenerator.genPath(
      'emails.credentials.instance.oauth_disconnect',
      { emailID }
    ),
  });

const changeGmailAccount = (emailID) => {
  return new Promise((resolve, reject) => {
    const unauthorize = (next) => {
      APIcall.post({
        data: {},
        error(error) {
          next(error);
        },
        success() {
          next(null);
        },
        token: true,
        url: endpointGenerator.genPath(
          'emails.credentials.instance.oauth_disconnect',
          { emailID }
        ),
      });
    };
    const deleteCredential = (next) => {
      const url = endpointGenerator.genPath('emails.credentials.instance', {
        emailID,
      });
      APIcall.delete({
        error(err) {
          next(err);
        },
        success() {
          next();
        },
        token: true,
        url,
      });
    };
    const finallyHook = (err, body) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    };
    async.waterfall([unauthorize, deleteCredential], finallyHook);
  });
};

const testConnection = (emailID, data) => {
  return APIcall.post({
    data,
    preventShowError: true,
    token: true,
    url: endpointGenerator.genPath(
      'emails.credentials.instance.test_connection',
      { emailID }
    ),
  });
};

export default authorizeGmailCall;
export { unauthorize, changeGmailAccount, testConnection };
