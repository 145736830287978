import buildCompositeController from '../../utils/buildCompositeController';
// Thunks
import tenantThunks from '../../actions/tenantThunks';

const mapStateToProps = (state) => {
  const tenantState = state.getIn(['tenant']);
  const isLoading = tenantState.getIn(['updateTenant', 'isLoading']);
  const locale = tenantState.getIn(['entity', 'value', 'ui_config', 'locale']);

  return {
    isLoading,
    locale,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveTenantLocale(locale) {
    dispatch(tenantThunks.saveTenantLocale(locale));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
