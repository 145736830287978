import React, { lazy, PureComponent, Suspense } from 'react';
import { intl } from 'esp-util-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { noop } from 'lodash';
import { Offline } from 'react-detect-offline';
import { LoadingList, LoadingMain } from '../../../suspense';
import OfflineMessage from '../../../v2/components/display/OfflineMessage';

// Controllers
import HomePageController from '../controllers/HomePageController';
// Molecules
import EspAppHeader from '../molecules/EspAppHeader';
import EspNav from '../molecules/EspNav';
import EspRoute from '../molecules/EspRoute';
import EspSectionTitle from '../molecules/EspSectionTitle';
// Organisms
import OnAppWorkflowModal from '../organisms/OnAppWorkflowModal';
import FeedbackReceivedModal from '../organisms/FeedbackReceivedModal';

import SearchContextProvider from '../organisms/SearchContextProvider';
// globals
import EspUserPropShape from '../../globals/EspUserPropShape';
// V2 Component
import { Boundaries } from 'cascara-middleware';

import BodyClassName from '../../../globals/BodyClassName';

// Chunks
const MainAppPanelChunk = lazy(() =>
  import(
    /* webpackChunkName: "MainAppPanelChunk" */ '../organisms/MainAppPanel'
  )
);
const SecondaryAppPanelChunk = lazy(() =>
  import(
    /* webpackChunkName: "SecondaryAppPanelChunk" */ '../organisms/SecondaryAppPanel'
  )
);

class HomePage extends PureComponent {
  static propTypes = {
    closeMoreMenu: PropTypes.func,
    currentUser: EspUserPropShape,
    doGoodbyeActions: PropTypes.func.isRequired,
    doHelloActions: PropTypes.func.isRequired,
    existingEmployeeWFeid: PropTypes.string,

    isCardDetailEnabled: PropTypes.bool,

    isMoreMenuOpen: PropTypes.bool,
    launchExistingEmployeeWF: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    offlineMessage: PropTypes.bool,
    shouldLaunchExistingWF: PropTypes.bool,
  };

  static defaultProps = {
    closeMoreMenu: noop,
    currentUser: null,
    existingEmployeeWFeid: null,
    isCardDetailEnabled: false,
    isMoreMenuOpen: false,
    offlineMessage: false,
    shouldLaunchExistingWF: false,
  };

  componentDidMount() {
    const { doHelloActions } = this.props;

    doHelloActions();
    // close more menu if we left it open!
    this.handleMenuClose();
  }

  componentDidUpdate() {
    const {
      existingEmployeeWFeid,
      launchExistingEmployeeWF,
      shouldLaunchExistingWF,
    } = this.props;

    // DEV-9953 don't do it this unless props change
    // otherwise this may get executed twice in a rapid update
    if (
      shouldLaunchExistingWF &&
      existingEmployeeWFeid &&
      !this._launchedOnboarding
    ) {
      this._launchedOnboarding = true;
      launchExistingEmployeeWF(existingEmployeeWFeid); // Launch the Existing Employee WF
    }
  }

  componentWillUnmount() {
    const { currentUser, doGoodbyeActions } = this.props;

    if (currentUser) {
      doGoodbyeActions();
    }
  }

  _launchedOnboarding = false; // makes sure this only gets called once in the lifecycle

  handleMenuClose() {
    const { closeMoreMenu } = this.props;
    closeMoreMenu();
  }

  render() {
    const {
      currentUser,

      isMoreMenuOpen,
      location,
      isCardDetailEnabled,
      offlineMessage,
    } = this.props;

    const { pathname } = location;
    /* Using this to pass the 4th segment of the path as a unique key. We only want to css animate the main panel, not any tabs inside of it. -bje */
    const pathSegments = pathname.split('/');
    const secondaryKey = pathSegments[2] || '';

    const mainKey = `${secondaryKey}.${pathSegments[3]}`;

    return (
      <SearchContextProvider pathname={pathname}>
        <BodyClassName
          className={classNames('app-user', {
            'menu-open': isMoreMenuOpen,
          })}
        >
          <div className='esp-page'>
            {/* OnAppWorkflowModal needs access to the React Router context */}
            <EspRoute component={OnAppWorkflowModal} />
            <EspRoute component={FeedbackReceivedModal} />

            <Helmet
              title={intl.formatMessage({
                id: 'label.navigation_home',
              })}
            />

            {currentUser ? (
              <EspAppHeader location={location} secondaryName={secondaryKey} />
            ) : null}

            {currentUser ? <EspNav currentUser={currentUser} /> : null}

            {}
            <div
              className='menu-scrim'
              onClick={this.handleMenuClose}
              onKeyPress={this.handleMenuClose}
            />

            <div className='esp-content'>
              {offlineMessage ? (
                <Offline>
                  <OfflineMessage />
                </Offline>
              ) : null}

              <EspSectionTitle
                location={location}
                secondaryName={secondaryKey}
              />
              <div className='esp-panels'>
                <div
                  className='esp-secondary'
                  component='div'
                  key={secondaryKey}
                >
                  <Boundaries>
                    <Suspense fallback={<LoadingList />}>
                      {currentUser ? (
                        <SecondaryAppPanelChunk location={location} />
                      ) : null}
                    </Suspense>
                  </Boundaries>
                </div>

                <div className='esp-main' component='div' key={mainKey}>
                  <Boundaries>
                    <Suspense fallback={<LoadingMain />}>
                      {currentUser ? (
                        <MainAppPanelChunk
                          isCardDetailEnabled={isCardDetailEnabled}
                          location={location}
                        />
                      ) : null}
                    </Suspense>
                  </Boundaries>
                </div>
              </div>
            </div>
          </div>
        </BodyClassName>
      </SearchContextProvider>
    );
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
HomePage = HomePageController(HomePage);

export default HomePage;
