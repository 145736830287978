import buildCompositeController from '../../utils/buildCompositeController';

import workflowThunk from '../../actions/workflowThunks';

const mapStateToProps = (state) => {
  // In use to set the minimum date selectable in the DayPicker
  const initialDate = state.hasIn([
    'workflowState',
    'frontendScratch',
    'initialStartingDate',
  ])
    ? state.getIn(['workflowState', 'frontendScratch', 'initialStartingDate'])
    : null;
  return {
    initialDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStartingDate(value) {
    dispatch(
      workflowThunk.updateFrontEndScratch({
        initialStartingDate: value,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
