import actionTypes from './actionTypes';

const userRulesActions = {};

userRulesActions.setUserRules = (userRules) => ({
  type: actionTypes.USER_RULES_SUCCESS,
  userRules,
});

export default userRulesActions;
