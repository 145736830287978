import PropTypes from 'prop-types';
import React from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Grid, Segment } from 'semantic-ui-react';

// molecules
import OffboardingActivityTemplateList from '../../../molecules/Offboarding/OffboardingActivityTemplateList';
import OffboardingActivityTemplateDetail from '../../../molecules/Offboarding/OffboardingActivityTemplateDetail';
import useTemplateListSettings from '../../../molecules/Offboarding/utils/hooks/useTemplateListSettings';

const propTypes = {
  params: PropTypes.shape({
    templateID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const OffboardingTemplates = (props) => {
  const {
    copyTemplate,
    createTemplate,
    isLoadingCreating,
    isTemplatesLoading,
    duplicateTemplate,
    loadMoreTemplates,
    nextTemplate,
    updateTemplate,
    updateTemplateList,
    templates,
  } = useTemplateListSettings();
  const {
    params: { templateID },
  } = props;

  return (
    <Segment as={Grid} attached clearing divided>
      <Helmet
        title={intl.formatMessage({
          id: 'label.admin_title_onboarding_activities_templates',
        })}
      />

      <Grid.Column width={6}>
        <OffboardingActivityTemplateList
          isTemplatesLoading={isTemplatesLoading}
          loadMoreTemplates={loadMoreTemplates}
          nextTemplate={nextTemplate}
          templateID={templateID}
          templates={templates}
        />
      </Grid.Column>

      <Grid.Column width={10}>
        {templateID && (
          <OffboardingActivityTemplateDetail
            copyTemplate={copyTemplate}
            createTemplate={createTemplate}
            duplicateTemplate={duplicateTemplate}
            isLoadingCreating={isLoadingCreating}
            templateID={templateID}
            updateTemplate={updateTemplate}
            updateTemplateList={updateTemplateList}
          />
        )}
      </Grid.Column>
    </Segment>
  );
};

OffboardingTemplates.propTypes = propTypes;
export default OffboardingTemplates;
