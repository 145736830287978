import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsTodoEta extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0005',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M45.94 55.73c0 .828.672 1.5 1.5 1.5h8.51c1.194-1.252 2.612-2.27 4.18-3H47.42c-.82.01-1.48.68-1.48 1.5zm-4.22-25.2c-.586-.585-1.534-.585-2.12 0l-3.87 3.86-1.9-1.89c-.59-.55-1.512-.534-2.083.037-.57.57-.588 1.492-.037 2.083l3 3c.586.585 1.534.585 2.12 0l4.93-4.93c.288-.288.447-.68.44-1.09-.008-.406-.18-.793-.48-1.07z' />
          <path d='M47.71 67.68c-1.266.034-2.53-.1-3.76-.4-1.21-.02-2.41.205-3.53.66-2.88 1.93-9.38 5.77-15.69 5.77 0 0 5.29-4.35 7-10-.254-1.233-.93-2.34-1.91-3.13-6.25-3.9-10.29-8.64-10.29-15.72 0-12.61 12.62-22.83 28.18-22.83s28.18 10.22 28.18 22.83c-.018 3.533-1.008 6.992-2.86 10 1.16.69 2.216 1.546 3.13 2.54 2.43-3.733 3.734-8.086 3.76-12.54 0-14.81-14.45-26.85-32.21-26.85s-32.21 12-32.21 26.85c0 9.7 6.16 15.37 11.91 18.94-1.286 2.578-3.07 4.876-5.25 6.76-1.348 1.063-1.867 2.87-1.29 4.486.578 1.617 2.123 2.685 3.84 2.654 7.46 0 14.87-4.38 18.08-6.55 1.606.414 3.262.606 4.92.57 1.733 0 3.463-.117 5.18-.35-.446-1.29-.7-2.637-.75-4-1.47.197-2.948.3-4.43.31z' />
          <path d='M66.93 44.63c0-.828-.672-1.5-1.5-1.5H47.42c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h18c.4.003.784-.154 1.067-.436.284-.28.443-.664.443-1.064zm-19.51-8.89h18c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5h-18c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5zM39.6 50.99l-3.87 3.87-1.89-1.92c-.59-.55-1.512-.534-2.083.037-.57.57-.588 1.492-.037 2.083l3 3c.586.585 1.534.585 2.12 0l4.93-4.93c.55-.59.534-1.512-.037-2.083-.57-.57-1.492-.588-2.083-.037l-.05-.02zm2.12-10.37c-.586-.585-1.534-.585-2.12 0l-3.87 3.87-1.9-1.9c-.59-.55-1.512-.534-2.083.037-.57.57-.588 1.492-.037 2.083l3 3c.586.585 1.534.585 2.12 0l4.89-4.97c.585-.586.585-1.534 0-2.12zm24.92 13.87v-1.23h1.93c.276 0 .5-.224.5-.5v-2.42c0-.276-.224-.5-.5-.5h-4.86c-.276 0-.5.224-.5.5v2.43c0 .276.224.5.5.5h1.93v1.2c-6.784.272-12.104 5.922-11.967 12.71.136 6.788 5.677 12.22 12.467 12.22 6.79 0 12.33-5.432 12.467-12.22.137-6.788-5.183-12.438-11.967-12.71v.02zm-2.43-2.22v-1.43h3.86v1.43h-3.86zm1.72 24.92c-5.7 0-10.32-4.62-10.32-10.32s4.62-10.32 10.32-10.32 10.32 4.62 10.32 10.32-4.62 10.32-10.32 10.32z' />
          <path d='M67.14 65.19v-4.66c0-.552-.448-1-1-1s-1 .448-1 1v4.65c-.747.45-1.102 1.34-.87 2.18.234.84.998 1.422 1.87 1.422.872 0 1.636-.58 1.87-1.42.232-.84-.123-1.733-.87-2.182v.01z' />
        </g>
      </svg>
    );
  }
}

export default CardsTodoEta;
