const isWeb = window.location.protocol !== 'file:';

// This could really be anything, as long as it's not a string in our code base
// I'm using this as this is really a good constant per tenant that is available before we
// load anything
export const defaultSecretPhraseWord = isWeb
  ? window.location.host
  : window.location.pathname;

// See https://gist.github.com/yetanotherchris/99f107d6ead03d467ea61b00456d41a7
export const encryptionOptions = {
  // don't ever change these or we won't be able to decrypt stored tokens
  cipher: 'aes', // cipher
  ks: 128, // key size in bits
  mode: 'ccm', // mode
  ts: 64, // authentication strength
  v: 1, // version
};
