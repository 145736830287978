import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@iconify/react';

const propTypes = {
  icon: PropTypes.string,
  style: PropTypes.shape({}),
};

const IconifyNavIcon = ({ icon }) => {
  return <Icon className='icon esp-icon' icon={icon} />;
};

IconifyNavIcon.propTypes = propTypes;

export default IconifyNavIcon;
