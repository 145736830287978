import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { Transition } from 'semantic-ui-react';

const rule = (props) => {
  const { disabled, isAttachmentMenuOpen, isTextareaFocused, theme } = props;

  const crossPieces = {
    backgroundColor: theme.colors.text,
    content: '" "',
    display: 'block',
    height: isAttachmentMenuOpen ? '70%' : '50%',
    left: '50%',
    marginLeft: '-1px',
    position: 'absolute',
    top: isAttachmentMenuOpen ? '15%' : '25%',
    transitionDuration: `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'all',
    transitionTimingFunction: theme.globals.transitionTimingFunction,
    width: '2px',
    zIndex: 10,
  };

  const activeFocusStyle = {
    ':after': {
      backgroundColor: theme.colors.black,
    },
    ':before': {
      backgroundColor: theme.colors.black,
    },
    boxShadow: 'none',
    outlineStyle: 'none',
  };

  return {
    ':after': {
      ...crossPieces,
      transform: isAttachmentMenuOpen ? 'rotate(135deg)' : 'rotate(0deg)',
    },
    ':before': {
      ...crossPieces,
      transform: isAttachmentMenuOpen ? 'rotate(225deg)' : 'rotate(90deg)',
    },
    ':focus': activeFocusStyle,
    ':hover': activeFocusStyle,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    height: theme.formHeight,
    marginLeft: isTextareaFocused ? `-${theme.formHeight}` : '0',
    pointerEvents: disabled ? 'none' : 'auto',
    position: 'relative',
    transform: 'translateZ(0)',
    transitionDuration: isTextareaFocused
      ? theme.expandDuration
      : `${theme.globals.transitionDuration}ms`,
    transitionProperty: 'margin, background-color',
    transitionTimingFunction: isTextareaFocused
      ? theme.expandTimingFunction
      : theme.globals.transitionTimingFunction,
    verticalAlign: 'top',
    width: theme.formHeight,
  };
};

class ChatFormToggleAttach extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,

    disabled: PropTypes.bool,
    // isAttachmentMenuOpen : PropTypes.bool.isRequired,
    // isTextareaFocused    : PropTypes.bool.isRequired,
    onToggleAttach: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  handleAttachementMenuOpen = (e, data) => {
    const { onToggleAttach } = this.props;

    onToggleAttach(e, data);
  };

  render() {
    const { disabled, className } = this.props;

    return (
      <Transition transitionOnMount>
        <button
          aria-label='Close'
          className={`${className} inline`}
          data-component='ChatFormToggleAttach'
          disabled={disabled}
          onClick={this.handleAttachementMenuOpen}
          tabIndex={0}
          type='button'
        />
      </Transition>
    );
  }
}

export default createComponent(rule, ChatFormToggleAttach, [
  'disabled',
  'isAttachmentMenuOpen',
  'isTextareaFocused',
  'onToggleAttach',
]);
