import { isValid } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import tenantThunks from '../../actions/tenantThunks';
import sessionThunks from '../../actions/sessionThunks';

// Utils
import browserHistory from '../../utils/browserHistory';
import uiPathGenerator from '../../utils/uiPathGenerator';
import endpointGenerator from '../../utils/endpointGenerator';
import ElectronUtils from '../../utils/ElectronUtils';

const mapStateToProps = (state) => ({
  errorMsg: state.getIn(['tenant', 'formErrorMsg']),
  isLoadingTenant: state.getIn(['tenant', 'entity', 'isLoading']),
  isValid: isValid('TenantSelectForm')(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkPreExistingCreds: () => {
    // For electron only: read the tenant name from local storage if exists
    const tenantName = localStorage.getItem('tenantName');
    const authToken = localStorage.getItem('authToken');

    if (window.electron && tenantName) {
      // start just with changing the tenant
      dispatch(tenantThunks.changeTenant(null, tenantName)).then(() => {
        localStorage.removeItem('tenantName'); // clear tenant

        if (authToken) {
          // if credentials provuded, let them in
          dispatch(sessionThunks.startSessionWithToken(authToken)).then(() => {
            localStorage.removeItem('authToken'); // Clean the token from here, so it's never used again

            // take the user to main app
            browserHistory.replace(uiPathGenerator.genPath('app.toDo'));
          });
        } else {
          // selecting only the tenant will take the user to login
          browserHistory.replace(uiPathGenerator.genPath('login'));
        }
      });
    }
  },

  clearTenant: () => {
    dispatch(tenantThunks.clearTenant());
  },

  formValidation: (values) => {
    const errors = {};
    const tenantName = values.get('tenantName') || '';
    if (!tenantName) {
      errors.tenantName = 'This cannot be empty';
    }

    return errors;
  },

  handleSubmit: (data) => {
    dispatch(tenantThunks.changeTenant(data)).then((tenant) => {
      if (window.electron) {
        ElectronUtils.setTenantUpdateServer(tenant);
      }

      // Native apps should redirect to login
      if (window.cordova || window.electron) {
        browserHistory.push(uiPathGenerator.genPath('login'));
      } else {
        // Otherwise redirect the whole browser to the tenant root
        window.location = endpointGenerator.getApiServer();
      }
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
