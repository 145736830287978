import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS([]);

const toastNotificationsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ADD_TOAST_NOTIFICATION:
      return state.push(fromJS(action.toast));

    case actionTypes.REMOVE_TOAST_NOTIFICATION:
      return state.filterNot((toast) => toast.get('key') === action.toastID);

    default:
      return state;
  }
};

export default toastNotificationsReducer;
