import Immutable, { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';
import ImmutableUtils from '../utils/ImmutableUtils';
import { partial } from 'lodash';

const INITIAL_STATE = fromJS({
  baristaSearchConditions: {
    isAddingNew: false,
    isLoading: false,
    isUpdating: false,
    list: [],
    serviceDepartmentEID: null,
  },
  defaultServiceTeam: {},
  defaultServiceTeamError: '',
  myServiceTeams: [],
  queryParams: {},
  selectedApprovalServiceTeamId: null,
  selectedServiceTeamId: null,
  serviceDepartment: {
    ServiceDepartmentEdited: null,
    count: 0,
    isLoading: false,
    isSearching: false,
    list: [],
    loadingDeleteID: null,
    searchTerm: '',
  },
  serviceDepartmentCategories: {
    count: 0,
    isLoading: false,
    list: [],
    loadingDeleteID: null,
  },
  serviceDepartmentRoute: {
    isLoadingDepartmentRoute: false,
    list: [],
  },
  serviceDepartmentTeam: {
    count: 0,
    isLoading: false,
    isSearching: false,
    list: [],
    loadingDeleteID: null,
    searchTerm: '',
  },
  serviceTeam: {
    count: 0,
    isLoading: false,
    isSearching: false,
    list: [],
    loadingDeleteID: null,
    locations: Immutable.List(),
    searchTerm: '',
    settings: {},
    substates: {
      count: 0,
      list: Immutable.List(),
    },
  },
  settingConfigDefaultServiceTeam: false,
  settingConfigDefaultServiceTeamError: '',
  taskCategories: [],
  taskClassifications: [],
  taskStatuses: {
    isLoading: false,
    list: [],
  },
  taskSubStatuses: {
    dictionary: {},
    isLoading: false,
    list: [],
  },
  taskTypes: {
    isLoading: false,
    list: [],
  },
  teamClassification: {},
  teamRelationShip: [],
  teamRole: {},
});

const caseMgmtReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CREATE_BARISTA_SEARCH_CONDITION_ERROR: {
      return state.setIn(['baristaSearchConditions', 'isAddingNew'], false);
    }

    case actionTypes.CREATE_BARISTA_SEARCH_CONDITION_START: {
      return state.setIn(['baristaSearchConditions', 'isAddingNew'], true);
    }

    case actionTypes.CREATE_BARISTA_SEARCH_CONDITION_SUCCESS: {
      const conditionsList = state.getIn(['baristaSearchConditions', 'list']);

      if (action.prepend) {
        // push down indexes since the new one is at index 0
        const newConditionsList = conditionsList.map((condition) =>
          condition.set(
            'order_of_execution',
            condition.get('order_of_execution') + 1
          )
        );
        return state
          .setIn(['baristaSearchConditions', 'isAddingNew'], false)
          .setIn(
            ['baristaSearchConditions', 'list'],
            newConditionsList.unshift(fromJS(action.condition))
          );
      } else {
        return state
          .setIn(['baristaSearchConditions', 'isAddingNew'], false)
          .setIn(
            ['baristaSearchConditions', 'list'],
            conditionsList.push(fromJS(action.condition))
          );
      }
    }

    case actionTypes.LOAD_BARISTA_SEARCH_CONDITIONS_ERROR: {
      return state.setIn(['baristaSearchConditions', 'isLoading'], false);
    }

    case actionTypes.LOAD_BARISTA_SEARCH_CONDITIONS_START: {
      return state.setIn(['baristaSearchConditions', 'isLoading'], true);
    }

    case actionTypes.LOAD_BARISTA_SEARCH_CONDITIONS_SUCCESS: {
      return state
        .setIn(['baristaSearchConditions', 'isLoading'], false)
        .setIn(['baristaSearchConditions', 'list'], fromJS(action.results))
        .setIn(
          ['baristaSearchConditions', 'serviceDepartmentEID'],
          action.serviceDepartmentEID
        );
    }

    case actionTypes.LOAD_SERVICE_DEPARTMENT_START: {
      return state.setIn(['serviceDepartment', 'isLoading'], true);
    }

    case actionTypes.LOAD_SERVICE_DEPARTMENT_FAIL: {
      return state.setIn(['serviceDepartment', 'isLoading'], false);
    }

    case actionTypes.LOAD_SERVICE_DEPARTMENT_SUCCESS: {
      return state
        .setIn(['serviceDepartment', 'count'], action.count)
        .setIn(['serviceDepartment', 'list'], fromJS(action.results))
        .setIn(['serviceDepartment', 'isLoading'], false);
    }

    case actionTypes.LOAD_SERVICE_DEPARTMENT_ROUTE_START: {
      return state.setIn(
        ['serviceDepartmentRoute', 'isLoadingDepartmentRoute'],
        true
      );
    }

    case actionTypes.LOAD_SERVICE_DEPARTMENT_ROUTE_FAIL: {
      return state.setIn(
        ['serviceDepartmentRoute', 'isLoadingDepartmentRoute'],
        false
      );
    }

    case actionTypes.LOAD_SERVICE_DEPARTMENT_ROUTE_SUCCESS: {
      return state
        .setIn(['serviceDepartmentRoute', 'list'], fromJS(action.results))
        .setIn(['serviceDepartmentRoute', 'isLoadingDepartmentRoute'], false);
    }

    case actionTypes.ADD_SERVICE_DEPARTMENT_SUCCESS: {
      let newList = state.getIn(['serviceDepartment', 'list']);
      newList = newList.push(fromJS(action.newDepartment));
      return state
        .setIn(['serviceDepartment', 'count'], action.count)
        .setIn(['serviceDepartment', 'list'], newList)
        .setIn(['serviceDepartment', 'isLoading'], false);
    }

    case actionTypes.EDIT_SERVICE_DEPARTMENT_SUCCESS: {
      const list = state.getIn(['serviceDepartment', 'list']);
      // Update department data
      const index = list.findIndex(
        (department) => department.get('id') === action.editedDepartment.id
      );
      const updatedList = list.set(index, fromJS(action.editedDepartment));
      return state
        .setIn(['serviceDepartment', 'isLoading'], false)
        .setIn(
          ['serviceDepartment', 'ServiceDepartmentEdited'],
          action.editedDepartment.id
        )
        .setIn(['serviceDepartment', 'list'], updatedList);
    }

    case actionTypes.SERVICE_DEPARTMENT_RELOADED: {
      return state.setIn(
        ['serviceDepartment', 'ServiceDepartmentEdited'],
        null
      );
    }

    case actionTypes.DELETE_SERVICE_DEPARTMENT_START: {
      return state.setIn(
        ['serviceDepartment', 'loadingDeleteID'],
        action.serviceDepartmentID
      );
    }

    case actionTypes.DELETE_SERVICE_DEPARTMENT_FAIL: {
      return state.setIn(['serviceDepartment', 'loadingDeleteID'], null);
    }

    case actionTypes.DELETE_SERVICE_DEPARTMENT_SUCCESS: {
      let newList = state.getIn(['serviceDepartment', 'list']);
      newList = newList.filter(
        (list) => list.get('id') !== action.serviceDepartmentID
      );
      const newCount = state.getIn(['serviceDepartment', 'count']) - 1;
      return state
        .setIn(['serviceDepartment', 'count'], newCount > -1 ? newCount : 0)
        .setIn(['serviceDepartment', 'list'], newList)
        .setIn(['serviceDepartment', 'loadingDeleteID'], null);
    }

    case actionTypes.SET_SEARCH_SERVICE_DEPARTMENT: {
      return state.setIn(['serviceDepartment', 'searchTerm'], action.term);
    }

    case actionTypes.CREATE_TEAM_SERVICE_DEPARTMENT_START: {
      return state.setIn(['serviceDepartmentTeam', 'isLoading'], true);
    }

    case actionTypes.CREATE_TEAM_SERVICE_DEPARTMENT_FAIL: {
      return state.setIn(['serviceDepartmentTeam', 'isLoading'], false);
    }

    case actionTypes.CREATE_TEAM_SERVICE_DEPARTMENT_SUCCESS: {
      const currentList = state.getIn(['serviceDepartmentTeam', 'list']);
      const newList = currentList.insert(0, fromJS(action.team));

      return state
        .setIn(['serviceDepartmentTeam', 'list'], newList)
        .setIn(['serviceDepartmentTeam', 'count'], newList.size)
        .setIn(['serviceDepartmentTeam', 'isLoading'], false);
    }

    case actionTypes.LOAD_TEAM_SERVICE_DEPARTMENT_START: {
      return state.setIn(['serviceDepartmentTeam', 'isLoading'], true);
    }

    case actionTypes.LOAD_TEAM_SERVICE_DEPARTMENT_FAIL: {
      return state
        .setIn(['serviceDepartmentTeam', 'isSearching'], false)
        .setIn(['serviceDepartmentTeam', 'isLoading'], false);
    }

    case actionTypes.LOAD_TEAM_SERVICE_DEPARTMENT_SUCCESS: {
      return state
        .setIn(['serviceDepartmentTeam', 'count'], action.count)
        .setIn(['serviceDepartmentTeam', 'list'], fromJS(action.results))
        .setIn(['serviceDepartmentTeam', 'isSearching'], false)
        .setIn(['serviceDepartmentTeam', 'isLoading'], false);
    }

    case actionTypes.RESET_DEPARTMENT_TEAM_LIST_SEARCH: {
      return state
        .setIn(
          ['serviceDepartmentTeam', 'count'],
          INITIAL_STATE.getIn(['serviceDepartmentTeam', 'count'])
        )
        .setIn(
          ['serviceDepartmentTeam', 'isLoading'],
          INITIAL_STATE.getIn(['serviceDepartmentTeam', 'isLoading'])
        )
        .setIn(
          ['serviceDepartmentTeam', 'isSearching'],
          INITIAL_STATE.getIn(['serviceDepartmentTeam', 'isSearching'])
        )
        .setIn(
          ['serviceDepartmentTeam', 'list'],
          INITIAL_STATE.getIn(['serviceDepartmentTeam', 'list'])
        )
        .setIn(
          ['serviceDepartmentTeam', 'searchTerm'],
          INITIAL_STATE.getIn(['serviceDepartmentTeam', 'searchTerm'])
        );
    }

    case actionTypes.DELETE_TEAM_SERVICE_DEPARTMENT_START: {
      return state.setIn(
        ['serviceDepartmentTeam', 'loadingDeleteID'],
        action.teamID
      );
    }

    case actionTypes.DELETE_TEAM_SERVICE_DEPARTMENT_FAIL: {
      return state.setIn(['serviceDepartmentTeam', 'loadingDeleteID'], null);
    }

    case actionTypes.DELETE_TEAM_SERVICE_DEPARTMENT_SUCCESS: {
      const teamList = state.getIn(['serviceDepartmentTeam', 'list']);
      const newList = teamList.filter(
        (team) => team.get('id') !== action.teamID
      );

      return state
        .setIn(['serviceDepartmentTeam', 'list'], newList)
        .setIn(['serviceDepartmentTeam', 'count'], newList.size)
        .setIn(['serviceDepartmentTeam', 'loadingDeleteID'], null);
    }

    case actionTypes.UPDATE_TEAM_SERVICE_DEPARTMENT_START: {
      return state.setIn(['serviceDepartmentTeam', 'isLoading'], true);
    }

    case actionTypes.UPDATE_TEAM_SERVICE_DEPARTMENT_FAIL: {
      return state.setIn(['serviceDepartmentTeam', 'isLoading'], false);
    }

    case actionTypes.UPDATE_TEAM_SERVICE_DEPARTMENT_SUCCESS: {
      const currentList = state.getIn(['serviceDepartmentTeam', 'list']);

      const newList = currentList.map((team) => {
        if (team.get('id') === action.team.id) {
          return fromJS(action.team);
        }
        return team;
      });

      return state
        .setIn(['serviceDepartmentTeam', 'list'], newList)
        .setIn(['serviceDepartmentTeam', 'isLoading'], false);
    }

    case actionTypes.SET_SEARCH_TEAM_SERVICE_DEPARTMENT: {
      return state.setIn(['serviceDepartmentTeam', 'searchTerm'], action.term);
    }

    case actionTypes.IS_SEARCHING_TEAM_SERVICE_DEPARTMENT: {
      return state.setIn(['serviceDepartmentTeam', 'isSearching'], true);
    }

    case actionTypes.LOAD_TEAM_CLASSIFICATION_DEPARTMENT: {
      return state
        .setIn(
          ['teamClassification', action.departmentName],
          fromJS(action.results)
        )
        .set('isLoadingteamClassification', false);
    }

    case actionTypes.LOAD_DEPARTMENT_CLASSIFICATION_SUCCESS: {
      return state
        .set('departmentClassification', fromJS(action.results))
        .set('isLoadingteamClassification', false);
    }

    case actionTypes.LOAD_DEPARTMENT_CLASSIFICATION_START: {
      return state.setIn('isLoadingteamClassification', true);
    }

    case actionTypes.LOAD_DEPARTMENT_CLASSIFICATION_FAIL: {
      return state.setIn('isLoadingteamClassification', false);
    }

    case actionTypes.LOAD_TEAM_ROLE: {
      let newTeamRole = fromJS({});
      action.teamRole.forEach((role) => {
        newTeamRole = newTeamRole.set(role.name, fromJS(role));
      });
      return state.set('teamRole', newTeamRole);
    }

    case actionTypes.LOAD_SUBSTATES_START: {
      return state.setIn(['taskSubStatuses', 'isLoading'], true);
    }

    case actionTypes.LOAD_SUBSTATES_SUCCESS: {
      return state
        .setIn(['taskSubStatuses', 'isLoading'], false)
        .setIn(['taskSubStatuses', 'list'], fromJS(action.list))
        .setIn(['taskSubStatuses', 'dictionary'], fromJS(action.dictionary))
        .setIn(['taskSubStatuses', 'count'], action.count);
    }

    case actionTypes.LOAD_SUBSTATES_FAIL: {
      return state.setIn(['taskSubStatuses', 'isLoading'], false);
    }

    case actionTypes.LOAD_SERVICE_TEAM_SUBSTATES_START: {
      return state.setIn(['serviceTeam', 'isLoading'], true);
    }

    case actionTypes.LOAD_SERVICE_TEAM_SUBSTATES_SUCCESS: {
      return state
        .setIn(['serviceTeam', 'isLoading'], false)
        .setIn(['serviceTeam', 'substates', 'list'], fromJS(action.results))
        .setIn(['serviceTeam', 'substates', 'count'], action.count);
    }

    case actionTypes.UPDATE_SERVICE_TEAM_SUBSTATES_SUCCESS: {
      const currentList = state.getIn(['serviceTeam', 'substates', 'list']);
      const newList = currentList.map((substate) => {
        if (substate.get('id') === action.substate.id) {
          return fromJS(action.substate);
        }
        return substate;
      });

      return state
        .setIn(['serviceTeam', 'isLoading'], false)
        .setIn(['serviceTeam', 'substates', 'list'], newList);
    }

    case actionTypes.REMOVE_SERVICE_TEAM_SUBSTATES_SUCCESS: {
      const newCount = state.getIn(['serviceTeam', 'substates', 'count']) - 1;
      const newList = state
        .getIn(['serviceTeam', 'substates', 'list'])
        .deleteIn(String(action.index));
      return state
        .setIn(['serviceTeam', 'isLoading'], false)
        .setIn(['serviceTeam', 'substates', 'list'], newList)
        .setIn(['serviceTeam', 'substates', 'count'], newCount);
    }

    case actionTypes.LOAD_SERVICE_TEAM_SUBSTATES_FAIL: {
      return state.setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.LOAD_TEAM_MEMBER_START: {
      return state.setIn(['serviceTeam', 'isLoading'], true);
    }

    case actionTypes.LOAD_TEAM_MEMBER_FAIL: {
      return state.setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.LOAD_TEAM_MEMBER_SUCCESS: {
      const memberList = action.results;
      return state
        .setIn(['teamRelationShip'], fromJS(memberList))
        .setIn(['serviceTeam', 'count'], action.count)
        .setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.START_ADD_TEAM_MEMBER: {
      // Create Fake new entry in the teamRelationShip
      const currentList = state.get('teamRelationShip');
      const fakeEntry = fromJS({
        id: 'newMember',
      });
      return state.set('teamRelationShip', currentList.insert(0, fakeEntry));
    }

    case actionTypes.CLEAN_ADD_TEAM_MEMBER: {
      const currentList = state.get('teamRelationShip');
      const newList = currentList.filter(
        (member) => member.get('id') !== 'newMember'
      );
      return state.set('teamRelationShip', newList);
    }

    case actionTypes.ADD_TEAM_MEMBER_START: {
      return state.setIn(['serviceTeam', 'isLoading'], true);
    }

    case actionTypes.ADD_TEAM_MEMBER_FAIL: {
      return state.setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.ADD_TEAM_MEMBER_SUCCESS: {
      const memberList = state.get('teamRelationShip');

      let newMemberList;
      // Check if this user already exist
      const memberExists = memberList.findIndex(
        (member) => member.get('user') === action.member.user
      );

      if (memberExists !== -1) {
        newMemberList = memberList.map((member, index) => {
          if (index === memberExists) {
            return fromJS(action.member);
          }
          return member;
        });
      } else {
        newMemberList = memberList.insert(0, fromJS(action.member));
      }

      return state
        .set('teamRelationShip', newMemberList)
        .setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.DELETE_TEAM_MEMBER_START: {
      return state
        .setIn(['serviceTeam', 'isLoading'], true)
        .setIn(['serviceTeam', 'loadingDeleteID'], action.relationshipID);
    }

    case actionTypes.DELETE_TEAM_MEMBER_FAIL: {
      return state
        .setIn(['serviceTeam', 'isLoading'], false)
        .setIn(['serviceTeam', 'loadingDeleteID'], null);
    }

    case actionTypes.DELETE_TEAM_MEMBER_SUCCESS: {
      const memberList = state.get('teamRelationShip');
      const newMemberList = memberList.filter(
        (member) => member.get('id') !== action.relationshipID
      );
      return state
        .setIn(['serviceTeam', 'isLoading'], false)
        .set('teamRelationShip', newMemberList)
        .setIn(['serviceTeam', 'loadingDeleteID'], null);
    }

    case actionTypes.LOAD_TEAMS_LIST_START: {
      return state.setIn(['serviceTeam', 'isLoading'], true);
    }

    case actionTypes.LOAD_TEAMS_LIST_FAIL: {
      return state
        .setIn(['serviceTeam', 'isSearching'], false)
        .setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.LOAD_TEAMS_LIST_SUCCESS: {
      return state
        .setIn(['serviceTeam', 'count'], action.count)
        .setIn(['serviceTeam', 'list'], fromJS(action.results))
        .setIn(['serviceTeam', 'isSearching'], false)
        .setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.LOAD_TASK_CLASSFICATIONS: {
      return state.set(
        'taskClassifications',
        fromJS(action.taskClassifications)
      );
    }

    case actionTypes.SET_MY_TEAMS: {
      return state
        .set('myServiceTeams', fromJS(action.serviceTeams))
        .set('defaultServiceTeamError', '');
    }

    case actionTypes.SET_MY_TEAMS_ERROR: {
      return state.set('defaultServiceTeamError', action.errorMsg);
    }

    case actionTypes.SET_DEFAULT_SERVICE_TEAM: {
      return state
        .set('defaultServiceTeam', fromJS(action.serviceTeam))
        .set('defaultServiceTeamError', '');
    }

    case actionTypes.DEFAULT_SERVICE_TEAM_ERROR: {
      return state.set('defaultServiceTeamError', action.errorMsg);
    }

    case actionTypes.SELECT_APPROVAL_SERVICE_TEAM: {
      return state.set('selectedApprovalServiceTeamId', action.serviceTeamId);
    }

    case actionTypes.SELECT_SERVICE_TEAM: {
      return state.set('selectedServiceTeamId', action.serviceTeamId);
    }

    case actionTypes.LOAD_TASK_CATEGORIES_START: {
      return state.setIn(['serviceDepartmentCategories', 'isLoading'], true);
    }

    case actionTypes.LOAD_TASK_CATEGORIES_FAIL: {
      return state.setIn(['serviceDepartmentCategories', 'isLoading'], false);
    }

    case actionTypes.LOAD_TASK_CATEGORIES_SUCCESS: {
      return state
        .setIn(['serviceDepartmentCategories', 'count'], action.count)
        .setIn(['serviceDepartmentCategories', 'isLoading'], false)
        .setIn(
          ['serviceDepartmentCategories', 'list'],
          fromJS(action.categories)
        );
    }

    case actionTypes.LOAD_TASK_TYPES_FAIL: {
      return state.setIn(['taskTypes', 'isLoading'], false);
    }

    case actionTypes.LOAD_TASK_TYPES_START: {
      return state.setIn(['taskTypes', 'isLoading'], true);
    }

    case actionTypes.LOAD_TASK_TYPES_SUCCESS: {
      return state
        .setIn(['taskTypes', 'isLoading'], false)
        .setIn(['taskTypes', 'list'], fromJS(action.list));
    }

    case actionTypes.LOAD_TASK_STATUSES_FAIL: {
      return state.setIn(['taskStatuses', 'isLoading'], false);
    }

    case actionTypes.LOAD_TASK_STATUSES_START: {
      return state.setIn(['taskStatuses', 'isLoading'], true);
    }

    case actionTypes.LOAD_TASK_STATUSES_SUCCESS: {
      return state
        .setIn(['taskStatuses', 'isLoading'], false)
        .setIn(['taskStatuses', 'list'], fromJS(action.list));
    }

    case actionTypes.DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_START: {
      return state.setIn(
        ['serviceDepartmentCategories', 'loadingDeleteID'],
        action.categoryID
      );
    }

    case actionTypes.DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_FAIL: {
      return state.setIn(
        ['serviceDepartmentCategories', 'loadingDeleteID'],
        null
      );
    }

    case actionTypes.DELETE_CATEGORY_SERVICE_DEPARTMENT_TEAM_SUCCESS: {
      const categoryList = state.getIn(['serviceDepartmentCategories', 'list']);
      const newList = categoryList.filter(
        (cat) => cat.get('id') !== action.categoryID
      );
      return state
        .setIn(['serviceDepartmentCategories', 'list'], newList)
        .setIn(['serviceDepartmentCategories', 'count'], newList.size)
        .setIn(['serviceDepartmentCategories', 'loadingDeleteID'], null);
    }

    case actionTypes.SET_CONFIG_DEFAULT_SERVICE_TEAM_START: {
      return state.setIn(['settingConfigDefaultServiceTeam'], true);
    }

    case actionTypes.SET_CONFIG_DEFAULT_SERVICE_TEAM_SUCCESS: {
      return state
        .setIn(['settingConfigDefaultServiceTeam'], false)
        .setIn(['settingConfigDefaultServiceTeamError'], '');
    }

    case actionTypes.SET_CONFIG_DEFAULT_SERVICE_TEAM_ERROR: {
      return state
        .setIn(['settingConfigDefaultServiceTeam'], false)
        .setIn(['settingConfigDefaultServiceTeamError'], action.errorMsg);
    }

    case actionTypes.CASE_SET_QUERY: {
      return state.setIn(['queryParams'], fromJS(action.query));
    }

    case actionTypes.GET_SERVICE_TEAM_LOCATIONS_START: {
      return state.setIn(['serviceTeam', 'isLoading'], true);
    }

    case actionTypes.GET_SERVICE_TEAM_LOCATIONS_SUCCESS: {
      return state
        .setIn(['serviceTeam', 'isLoading'], false)
        .setIn(['serviceTeam', 'locations'], fromJS(action.locations));
    }

    case actionTypes.SWAP_BARISTA_SEARCH_CONDITIONS_SUCCESS: {
      const conditionsList = state.getIn(['baristaSearchConditions', 'list']);

      const indexerReducer = (eid1, eid2, acc, condition, currentIndex) => {
        const eid = condition.get('eid');
        return eid === eid1
          ? {
              condition1: condition,
              index1: currentIndex,
              orderOfExecution1: condition.get('order_of_execution'),
              ...acc,
            }
          : eid === eid2
          ? {
              condition2: condition,
              index2: currentIndex,
              orderOfExecution2: condition.get('order_of_execution'),
              ...acc,
            }
          : acc;
      };
      const actualReducer = partial(indexerReducer, action.eid1, action.eid2);
      const {
        index1,
        condition1,
        orderOfExecution1,
        index2,
        condition2,
        orderOfExecution2,
      } = conditionsList.reduce(actualReducer, {});
      const newList = conditionsList
        .set(index1, condition2.set('order_of_execution', orderOfExecution1))
        .set(index2, condition1.set('order_of_execution', orderOfExecution2));

      return state.setIn(['baristaSearchConditions', 'list'], newList);
    }
    case actionTypes.GET_SERVICE_TEAM_LOCATIONS_FAIL: {
      return state.setIn(['serviceTeam', 'isLoading'], false);
    }

    case actionTypes.TOGGLE_BARISTA_SEARCH_CONDITION_SUCCESS: {
      const conditionsList = state.getIn(['baristaSearchConditions', 'list']);
      const updated = fromJS(action.condition);
      const newList = ImmutableUtils.replace(
        conditionsList,
        updated,
        (condition) => condition.get('eid') === updated.get('eid')
      );

      return state
        .setIn(['baristaSearchConditions', 'list', 'isUpdating'], false)
        .setIn(['baristaSearchConditions', 'list'], newList);
    }

    case actionTypes.UPDATE_BARISTA_SEARCH_CONDITION_ERROR: {
      return state.setIn(['baristaSearchConditions', 'isUpdating'], false);
    }

    case actionTypes.UPDATE_BARISTA_SEARCH_CONDITION_START: {
      return state.setIn(['baristaSearchConditions', 'isUpdating'], true);
    }

    case actionTypes.UPDATE_BARISTA_SEARCH_CONDITION_SUCCESS: {
      const conditionsList = state.getIn(['baristaSearchConditions', 'list']);
      const updated = fromJS(action.condition);
      const newList = ImmutableUtils.replace(
        conditionsList,
        updated,
        (condition) => condition.get('eid') === updated.get('eid')
      );

      return state
        .setIn(['baristaSearchConditions', 'isUpdating'], false)
        .setIn(['baristaSearchConditions', 'list'], newList);
    }

    default: {
      return state;
    }
  }
};

export default caseMgmtReducer;
