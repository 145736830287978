import React, { useCallback, useContext, useState } from 'react';
import { deleteType, labels, samlURLS } from '../utils/constants';
import { Button, Form, Segment } from 'semantic-ui-react';
import AzureContext from '../AzureContext';
import URLField from './URLField';
import EspConfirmationModal from '../../../../../../../v2/components/display/EspConfirmationModal/EspConfirmationModal';
import styles from './AzureSSOWithSAML.module.scss';
import AzureSSOWithSAMLModal from './AzureSSOWithSAMLModal';

const propTypes = {};

const AzureSSOWithSAML = () => {
  const { state, handleDelete, handleUpdateSSOWithSAML, toggleEnableModal } =
    useContext(AzureContext);

  const { samlSSO, isEnableModalOpen, isLoading } = state;

  const [isDeleteModalOpen, setDeleteModal] = useState();

  const handleDeleteConfirm = useCallback(() => {
    setDeleteModal(!isDeleteModalOpen);
    handleDelete(deleteType.SSO_SAML);
  }, [handleDelete, isDeleteModalOpen]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModal(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleEnableConfirm = useCallback(() => {
    toggleEnableModal();
    handleUpdateSSOWithSAML(samlSSO?.enabled);
  }, [samlSSO?.enabled, handleUpdateSSOWithSAML, toggleEnableModal]);

  const enableModalTitle = samlSSO?.enabled
    ? labels.DISABLE_SSO
    : labels.ENABLE_SSO;
  const enableModalBody = samlSSO?.enabled
    ? labels.DISABLE_SSO_BODY
    : labels.ENABLE_SSO_BODY;

  if (!samlSSO?.url) {
    return (
      <div>
        <Segment
          attached
          loading={isLoading}
          style={{ borderTop: 'none' }}
          textAlign='center'
        >
          <p>{labels.WELCOME_DESCRIPTION}</p>
          <div>
            <AzureSSOWithSAMLModal />
          </div>
        </Segment>
      </div>
    );
  }

  return (
    <Segment attached loading={isLoading}>
      <Form as={'div'}>
        <Form.Group>
          <Form.Field width={12}>
            <div className={styles.checkbox}>
              <label htmlFor='enabled'>
                <strong>{labels.STATUS}</strong>
              </label>
              <Form.Checkbox
                checked={samlSSO?.enabled}
                disabled={isLoading}
                id='enabled'
                label={samlSSO?.enabled ? labels.ENABLED : labels.DISABLED}
                name='enabled'
                onClick={toggleEnableModal}
                toggle
              />
            </div>

            <div className={styles._}>
              <URLField
                label={labels.IDENTIFIER}
                url={samlURLS.identifierURL}
              />
              <URLField label={labels.REPLY_URL} url={samlURLS.replyURL} />
              <URLField label={labels.SIGN_ON_URL} url={samlURLS.singOnURL} />
              <URLField label={labels.LOG_OUT_URL} url={samlURLS.logoutURL} />
            </div>
          </Form.Field>

          <Form.Field width={4}>
            <div style={{ textAlign: 'right' }}>
              <Button
                color='red'
                content={labels.REMOVE}
                disabled={isLoading}
                onClick={toggleDeleteModal}
                type='button'
              />

              <EspConfirmationModal
                cancelButton={labels.NO}
                confirmButton={
                  <Button content={labels.YES} positive type='button' />
                }
                content={enableModalBody}
                header={enableModalTitle}
                onCancel={toggleEnableModal}
                onConfirm={handleEnableConfirm}
                open={isEnableModalOpen}
              />

              <EspConfirmationModal
                cancelButton={labels.CANCEL}
                confirmButton={
                  <Button color='red' content={labels.REMOVE} type='button' />
                }
                content={labels.DELETE_BODY}
                header={labels.DELETE_TITLE}
                onCancel={toggleDeleteModal}
                onConfirm={handleDeleteConfirm}
                open={isDeleteModalOpen}
              />
            </div>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
};

AzureSSOWithSAML.propTypes = propTypes;

export default AzureSSOWithSAML;
