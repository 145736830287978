import React from 'react';
import PropTypes from 'prop-types';
import { intl } from 'esp-util-intl';
import { Message } from 'semantic-ui-react';

import ModalEsp from './ModalEsp';

const propTypes = {
  removeTask: PropTypes.func.isRequired,
  removingTask: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- TODO apply correct proptype DEV-1526
    error: PropTypes.any,

    isLoading: PropTypes.bool,
  }).isRequired,
  taskId: PropTypes.number.isRequired, // Id of the task to delete
  trigger: PropTypes.node.isRequired,
};

class RemoveTaskModal extends React.Component {
  handleRemoveTask = (close) => {
    const { removeTask, taskId } = this.props;
    removeTask(taskId, (error) => {
      if (!error) {
        close();
      }
    });
  };

  render() {
    const { removingTask, trigger } = this.props;

    return (
      <ModalEsp
        acceptContent={intl.formatMessage({
          id: 'label.delete',
        })}
        headerContent={intl.formatMessage({
          id: 'message.delete_this_workflow_task',
        })}
        headerIcon='trash'
        isLoading={removingTask.isLoading}
        onAccept={this.handleRemoveTask}
        trigger={trigger}
      >
        {intl.formatMessage({
          id: 'message.are_you_sure_you_want_to_delete_this_task',
        })}

        {removingTask.error ? (
          <Message negative>{'Unable to delete workflow task.'}</Message>
        ) : null}
      </ModalEsp>
    );
  }
}

RemoveTaskModal.propTypes = propTypes;

export default RemoveTaskModal;
