import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogAccessoriesKeyboard extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(15.5 19.5)'>
          <path d='M56.13 18.58h-3.47c.198-1.678-.256-3.368-1.27-4.72-1.344-1.456-3.285-2.213-5.26-2.05h-9.19c-.94.02-1.843-.366-2.48-1.06-.634-.692-.94-1.625-.84-2.56-.036-.83.227-1.646.74-2.3.606-.6 1.44-.912 2.29-.86h7.48c1.64 0 4.74-.84 4.74-4 0-.552-.448-1-1-1s-1 .448-1 1c0 1.95-2.46 2-2.74 2h-7.5c-1.407-.047-2.768.5-3.75 1.51-.868 1.044-1.318 2.373-1.26 3.73-.09 1.457.434 2.884 1.445 3.937 1.01 1.052 2.416 1.634 3.875 1.603h9.19c1.367-.13 2.72.357 3.69 1.33.702 1.003.99 2.24.8 3.45H8c-4.418 0-8 3.582-8 8v21.73c0 4.418 3.582 8 8 8h48.13c4.418 0 8-3.582 8-8V26.58c0-4.418-3.582-8-8-8zm6 29.74c0 3.314-2.686 6-6 6H8c-3.314 0-6-2.686-6-6V26.58c0-3.314 2.686-6 6-6h48.13c3.314 0 6 2.686 6 6v21.74z' />
          <circle cx='7.89' cy='25.86' r='2.8' />
          <circle cx='16.09' cy='25.88' r='2.8' />
          <circle cx='24.3' cy='25.92' r='2.8' />
          <circle cx='32.5' cy='25.9' r='2.8' />
          <circle cx='40.7' cy='25.84' r='2.8' />
          <circle cx='48.9' cy='25.94' r='2.8' />
          <circle cx='57.11' cy='25.96' r='2.8' />
          <path d='M43.46 46.49h-24c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h24c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5zm-32.04-.17H7.69c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h3.72c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h.01zm45.01 0h-4.09c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h4.09c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5z' />
          <circle cx='19.6' cy='33.77' r='2.8' />
          <circle cx='28.35' cy='33.82' r='2.8' />
          <circle cx='36.62' cy='33.77' r='2.8' />
          <circle cx='44.9' cy='33.82' r='2.8' />
          <path d='M49.84 33.77c0 1.38 1.12 2.5 2.5 2.5h4.79c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-4.79c-1.38 0-2.5 1.12-2.5 2.5zM7 36.27h4.83c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5H7c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5z' />
          <circle cx='7.89' cy='41.49' r='2.8' />
          <circle cx='16.09' cy='41.51' r='2.8' />
          <circle cx='24.3' cy='41.55' r='2.8' />
          <circle cx='32.5' cy='41.53' r='2.8' />
          <circle cx='40.7' cy='41.47' r='2.8' />
          <circle cx='48.9' cy='41.57' r='2.8' />
          <circle cx='57.11' cy='41.59' r='2.8' />
        </g>
      </svg>
    );
  }
}

export default CatalogAccessoriesKeyboard;
