import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImmutableProps from 'react-immutable-proptypes';
import { Button, Modal, Table } from 'semantic-ui-react';
import EspConfirmationModal from '../../../v2/components/display/EspConfirmationModal';
import EditCaseInboxButton from './EditCaseInboxButton';
import EmailServiceTypes from '../../globals/EmailServiceTypes';

const caseInboxType = PropTypes.shape({
  credentials: PropTypes.shape({
    credential: PropTypes.shape({}),
    email_address: PropTypes.string,
    email_type: PropTypes.string,
    id: PropTypes.number,
    is_primary: PropTypes.bool,
    name: PropTypes.string,
  }),
  eid: PropTypes.string,
  id: PropTypes.number,
  inbox: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  lastEmailScanned: PropTypes.string,
  serviceDepartment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
});

class BaristaInboxRow extends PureComponent {
  static propTypes = {
    baristaTestConnectionSent: PropTypes.bool,

    baristaTestConnectionSuccess: PropTypes.bool,

    caseInbox: caseInboxType.isRequired,

    connectingGmailAccount: PropTypes.string,

    deleteBaristaInbox: PropTypes.func.isRequired,

    departmentList: ImmutableProps.list,

    isNewFormInbox: PropTypes.bool,

    isOnlyTestingConnection: PropTypes.bool,
    reopenNewInboxModal: PropTypes.func,

    terminateEmailTestConnection: PropTypes.func.isRequired,

    testConnection: PropTypes.func.isRequired,

    testedBox: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  static defaultProps = {
    baristaTestConnectionSent: false,
    baristaTestConnectionSuccess: false,
    departmentList: ImmutableProps.list,
    isNewFormInbox: false,
    isOnlyTestingConnection: false,
  };

  state = {
    isConfirmDeleteModalOpen: false,
  };

  handleDeleteBaristaInbox = () => {
    const { caseInbox, deleteBaristaInbox } = this.props;

    deleteBaristaInbox(
      caseInbox.id,
      caseInbox.credentials.id,
      caseInbox.credentials.is_primary,
      caseInbox.credentials.email_type === EmailServiceTypes.GMAIL
    );
  };

  handleTestConnection = () => {
    const { caseInbox, testConnection } = this.props;
    if (caseInbox?.credentials) {
      const emailData = {
        email_address: caseInbox.credentials.email_address,
        id: caseInbox.credentials.id,
      };
      const isOnlyTestingConnection = true;
      testConnection(emailData, isOnlyTestingConnection);
    }
  };

  handleToggleConfirmationModal = () =>
    this.setState((prevState) => ({
      isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen,
    }));

  handleCloseTestModal = () => {
    const { terminateEmailTestConnection } = this.props;
    terminateEmailTestConnection();
  };

  actions = (
    <Button
      basic
      content='Close'
      onClick={this.handleToggleConfirmationModal}
    />
  );

  contentFailureTestConnection = () => {
    const { isNewFormInbox, isOnlyTestingConnection } = this.props;
    const baseMsg =
      'test failed. This could be because email address does not exist or your credentials are incorrect.';
    if (isOnlyTestingConnection) {
      return `Inbox ${baseMsg}`;
    } else {
      return isNewFormInbox
        ? `The Barista inbox was created and saved, but the inbox ${baseMsg}`
        : `The Barista inbox was updated, but the inbox ${baseMsg}`;
    }
  };

  contentSuccessTestConnection = () => {
    const { isNewFormInbox, isOnlyTestingConnection, caseInbox } = this.props;
    if (isOnlyTestingConnection) {
      return 'Inbox settings test passed.';
    } else {
      return isNewFormInbox`${caseInbox?.credentials?.name} has successfully been created.``${caseInbox?.credentials?.name} has successfully been updated.`;
    }
  };

  render() {
    const {
      caseInbox,
      connectingGmailAccount,
      departmentList,
      baristaTestConnectionSuccess,
      baristaTestConnectionSent,
      reopenNewInboxModal,
      testedBox,
    } = this.props;
    const { isConfirmDeleteModalOpen } = this.state;
    const caseInboxCredentials =
      caseInbox?.credentials || caseInbox.credentials?.credential;

    const datePart = moment(caseInbox?.lastEmailScanned).format('MMMM D, YYYY');
    const timePart = moment(caseInbox?.lastEmailScanned).format('h:mm a');

    return (
      <Table.Row>
        <Table.Cell>
          <p>
            <b>{caseInboxCredentials?.name}</b>
          </p>
          <p>{caseInboxCredentials?.email_address}</p>
        </Table.Cell>
        <Table.Cell>
          <div>
            <p>{datePart}</p>
            <p>{timePart}</p>
          </div>
        </Table.Cell>
        <Table.Cell>{caseInbox?.serviceDepartment?.name}</Table.Cell>
        <Table.Cell style={{ whiteSpace: 'nowrap' }}>
          <Button basic onClick={this.handleTestConnection} size='tiny'>
            {'Test inbox'}
          </Button>
          <EditCaseInboxButton
            caseInbox={caseInbox}
            connectingGmailAccount={connectingGmailAccount}
            departmentList={departmentList}
            reopenNewInboxModal={reopenNewInboxModal}
          />
          <Button
            basic
            negative
            onClick={this.handleToggleConfirmationModal}
            size='tiny'
          >
            {'Delete'}
          </Button>
        </Table.Cell>
        <EspConfirmationModal
          cancelButton='Cancel'
          confirmButton={<Button content='Delete' negative />}
          content="Deleting this inbox will disable Barista's ability to deflect the incoming requests associated with this inbox."
          header={`Delete ${caseInbox?.credentials?.name}`}
          onCancel={this.handleToggleConfirmationModal}
          onConfirm={this.handleDeleteBaristaInbox}
          open={isConfirmDeleteModalOpen}
        />
        {testedBox?.id === caseInboxCredentials?.id &&
          baristaTestConnectionSuccess && (
            <Modal
              actions={[
                {
                  content: 'Ok',
                  key: 'ok',
                  onClick: this.handleCloseTestModal,
                },
              ]}
              content={
                <div className='content' style={{ padding: '1.5rem' }}>
                  {this.contentSuccessTestConnection()}
                </div>
              }
              header={`Testing ${caseInbox?.credentials?.name} Inbox`}
              open={baristaTestConnectionSent}
              size='mini'
            />
          )}
        {testedBox?.id === caseInboxCredentials?.id &&
          !baristaTestConnectionSuccess && (
            <Modal
              actions={[
                {
                  content: 'Ok',
                  key: 'ok',
                  onClick: this.handleCloseTestModal,
                },
              ]}
              content={
                <div className='content' style={{ padding: '1.5rem' }}>
                  {this.contentFailureTestConnection()}
                </div>
              }
              header='Barista Inbox Test Failed'
              open={baristaTestConnectionSent}
              size='mini'
            />
          )}
      </Table.Row>
    );
  }
}

export { caseInboxType };
export default BaristaInboxRow;
