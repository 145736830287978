import Immutable, { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = Immutable.Map({
  childrenNodesCache: Immutable.Set(),

  defaultSelectedNodeCache: Immutable.Set(),

  leaftNodesPagination: fromJS({
    isLoading: false,
    next: null,
  }),

  rootNodesCache: Immutable.Set(),
});

const onHierarchySelectCacheRootNodes = (state, hierarchyType) => {
  let rootNodesCache = state.get('rootNodesCache');
  rootNodesCache = rootNodesCache.add(hierarchyType);
  state = state.set('rootNodesCache', rootNodesCache);

  return state;
};

const onHierarchySelectCacheChildrenNodes = (
  state,
  hierarchyType,
  parentID
) => {
  const cacheEntry = `${hierarchyType}:${parentID}`;
  let childrenNodesCache = state.get('childrenNodesCache');
  childrenNodesCache = childrenNodesCache.add(cacheEntry);
  state = state.set('childrenNodesCache', childrenNodesCache);

  return state;
};

const handleCacheDefaultSelectedNode = (state, hierarchyType, nodeID) => {
  const cacheEntry = `${hierarchyType}:${nodeID}`;

  let defaultSelectedNodeCache = state.get('defaultSelectedNodeCache');
  defaultSelectedNodeCache = defaultSelectedNodeCache.add(cacheEntry);

  state = state.set('defaultSelectedNodeCache', defaultSelectedNodeCache);

  return state;
};

const hierarchySelectReducer = (state, action) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  switch (action.type) {
    case actionTypes.HIERARCHY_SELECT_CACHE_ROOT_NODES:
      return onHierarchySelectCacheRootNodes(state, action.hierarchyType);

    case actionTypes.HIERARCHY_SELECT_CACHE_CHILDREN_NODES:
      return onHierarchySelectCacheChildrenNodes(
        state,
        action.hierarchyType,
        action.parentID
      );

    case actionTypes.HIERARCHY_SELECT_CACHE_DEFAULT_SELECTED_NODE:
      return handleCacheDefaultSelectedNode(
        state,
        action.hierarchyType,
        action.nodeID
      );

    case actionTypes.GET_CHILDREN_LOCATIONS_START: {
      return state.setIn(['leaftNodesPagination', 'isLoading'], true);
    }

    case actionTypes.GET_CHILDREN_LOCATIONS_SUCCESS: {
      return state
        .setIn(['leaftNodesPagination', 'next'], action.next)
        .setIn(['leaftNodesPagination', 'isLoading'], false);
    }

    default:
      return state;
  }
};

export default hierarchySelectReducer;
