import React, { PureComponent } from 'react';
import { createComponent } from 'react-fela';
import PropTypes from 'prop-types';
import localeMoment from '../../../../v1/utils/localeMoment';

const rule = () => ({
  fontSize: '.75em',
  lineHeight: 1.75,
});

class ChatMessageTimestamp extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    timeStamp: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  render() {
    const { className, timeStamp } = this.props;

    return (
      <div className={className} data-component='ChatMessageTimestamp'>
        {localeMoment(timeStamp).calendar()}
      </div>
    );
  }
}

export default createComponent(rule, ChatMessageTimestamp, ['timeStamp']);
