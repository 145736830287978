import { useCallback, useState } from 'react';

import APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import EspFilters from 'esp-util-filters';
import { isNull } from 'lodash';
import toastNotificationsActions from '../../../../../actions/toastNotificationsActions';

const LIMIT = 10;

const useGroups = () => {
  const [paginationData, setPaginationData] = useState({
    count: null,
    limit: LIMIT,
    next: null,
    page: 1,
    prev: null,
  });
  const [deleteError, setDeleteError] = useState({});
  const [deleting, setDeleting] = useState(false);

  const [listUpdated, setListUpdated] = useState(false);

  const fetchGroup = (text) => {
    const url = endpointGenerator.genPath('espUserGroups.groups');
    const filter = new EspFilters().equalTo('active', 'True');

    filter.contains('name', text);

    return APIcall.get({
      query: {
        esp_filters: filter.asQueryString(),
        limit: LIMIT,
        order_by: '-sys_date_created',
      },
      token: true,
      url,
    });
  };

  const searchGroup = async (text) => {
    try {
      const { body } = await fetchGroup(text);
      return body.results;
    } catch (error) {
      return [];
    }
  };

  const loadAllUserGroupsWithParams = useCallback(
    (paramUrl, { limit, page }) => {
      const url = !isNull(paramUrl)
        ? paramUrl
        : endpointGenerator.genPath('espUserGroups.groups');
      const filter = new EspFilters().equalTo('active', 'True');
      return new Promise((resolve, reject) =>
        APIcall.get({
          query: {
            esp_filters: filter.asQueryString(),
            limit,
            order_by: '-sys_date_created',
          },
          token: true,
          url,
        })
          .then(({ body }) => {
            setPaginationData({
              count: body?.count,
              limit: limit,
              next: body?.next,
              page: page ?? 1,

              prev: body?.previous,
            });
            setListUpdated(false);
            resolve(body);
          })
          .catch((error) => {
            reject();
          })
      );
    },
    []
  );

  const getAnnouncements = useCallback((announcements) => {
    const promises = announcements.map(({ id }) => {
      const url = endpointGenerator.genPath('espNotification.notification', {
        notificationID: id,
      });
      return new Promise((resolve) =>
        APIcall.get({
          success({ body }) {
            resolve(body);
          },
          token: true,
          url,
        })
      );
    });
    return Promise.all(promises).then((values) => {
      return values;
    });
  }, []);
  const deleteGroup = useCallback(
    ({ id }) => {
      const url = endpointGenerator.genPath('espUserGroups.groups.instance', {
        group_id: id,
      });
      setDeleting(true);
      return new Promise((resolve) =>
        APIcall.patch({
          data: {
            active: false,
          },
          error({ response }) {
            const { errors } = response.body;
            const errorMessage = errors[0].message;
            const usedIn = {
              // add the usedIn cases here as we add more implementations of groups, example faqs
              announcements: errorMessage.match('announcements') !== -1,
            };

            if (usedIn.announcements) {
              getAnnouncements(errors[0].reason.EspAnnouncement).then(
                (values) => {
                  setDeleting(false);
                  setDeleteError({ ...errors[0], reasons: values, usedIn });
                }
              );
            }
            // if usedIn announcements call endpoint api/notification/v0.1/notification/2034/ to get the announcement details and pass them through
          },
          preventShowError: true,
          success() {
            setDeleting(false);
            setListUpdated(true);
          },

          token: true,
          url,
        }).then(({ body }) => {
          resolve(body);
        })
      );
    },
    [getAnnouncements]
  );

  const showToast = useCallback((dispatcher, { type, meta }) => {
    switch (type) {
      case 'deleted_group_success':
        dispatcher(
          toastNotificationsActions.success({
            message: `The user group  ${meta?.deletedUserGroupName} has been Successfully deleted`,
          })
        );
        break;
      default:
        break;
    }
  }, []);
  const getAllActiveGroups = useCallback(() => {
    const filter = new EspFilters().equalTo('active', 'True');
    const url = endpointGenerator.genPath('espUserGroups.groups');
    return new Promise((resolve, reject) =>
      APIcall.get({
        query: {
          esp_filters: filter.asQueryString(),
          // TODO added workaround, however real fix will be added on DEC 2022 https://espressive.atlassian.net/browse/DEV-21029
          limit: 100,
        },
        success({ body }) {
          resolve(body.results);
        },
        token: true,
        url,
      })
    );
  }, []);

  return {
    deleteError,
    deleteGroup,
    deleting,
    getAllActiveGroups,
    listUpdated,
    loadAllUserGroupsWithParams,
    paginationData,
    searchGroup,
    setDeleteError,
    showToast,
  };
};

export default useGroups;
