import { applyMiddleware, compose, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form/immutable';

// Middlewares
import { createEpicMiddleware } from 'redux-observable';
import webSocketMiddleware from '../middleware/webSocketMiddleware';
import electronMiddleware from '../middleware/electronMiddleware';
import pwaMiddleware from '../middleware/pwaMiddleware';

// Reducers
import adminFaqsReducer from '../reducers/adminFaqsReducer';
import adminReportsReducer from '../reducers/adminReportsReducer';
import adminSearchReducer from '../reducers/adminSearchReducer';
import announcementReducer from '../reducers/announcementReducer';
import anonResetPasswordReducer from '../reducers/anonResetPasswordReducer';
import appUIReducer from '../reducers/appUIReducer';
import appVersionReducer from '../reducers/appVersionReducer';
import assetReducer from '../reducers/assetReducer';
import baristaReducer from '../reducers/baristaReducer';
import bundleReducer from '../reducers/bundleReducer';
import cartReducer from '../reducers/cartReducer';
import csvCasesReportReducer from '../reducers/csvCasesReportReducer';
import adminLocationsReducer from '../reducers/adminLocationsReducer';
import caseMgmtReducer from '../reducers/caseMgmtReducer';
import approvalsFilterReducer from '../reducers/approvalsFilterReducer';
import casesFilterReducer from '../reducers/casesFilterReducer';
import casesReducer from '../reducers/casesReducer';
import catalogReducer from '../reducers/catalogReducer';
import chatReducer from '../reducers/chatReducer';
import compatibilityCheckReducer from '../reducers/compatibilityCheckReducer';
import containersStatus from '../reducers/containersStatusReducer';
import cordovaReducer from '../reducers/cordovaReducer';
import dashboardReducer from '../reducers/interactionsReducer';
import directoryReducer from '../reducers/directoryReducer';
import dpcReducer from '../reducers/dpcReducer';
import editorReducer from '../../../../admin/js/v1/reducers/editorReducer';
import emailPoliciesReducer from '../reducers/emailPoliciesReducer';
import entitiesReducer from '../reducers/entitiesReducer';
import errorsReducer from '../reducers/errorsReducer';
import hierarchySelectReducer from '../reducers/hierarchySelectReducer';
import homeFilterReducer from '../reducers/homeFilterReducer';
import inboxesReducer from '../reducers/inboxesReducer';
import integrationReducer from '../reducers/integrationReducer';
import intlReducer from '../reducers/intlReducer';
import onboardActivityReducer from '../reducers/onboardActivityReducer';
import onboardProgressManagerViewReducer from '../reducers/onboardProgressManagerViewReducer';
import productPermissionsReducer from '../reducers/productPermissionsReducer';
import profileReducer from '../reducers/profileReducer';
import reactRouterReducer from '../reducers/reactRouterReducer';
import requestsReducer from '../reducers/requestsReducer';
import searchReducer from '../reducers/searchReducer';
import selectMyGearReducer from '../reducers/selectMyGearReducer';
import sessionReducer from '../reducers/sessionReducer';
import shopModalReducer from '../reducers/shopModalReducer';
import socializeReducer from '../reducers/socializeReducer';
import surveysReducer from '../reducers/surveysReducer';
import tenantReducer from '../reducers/tenantReducer';
import toastNotificationsReducer from '../reducers/toastNotificationsReducer';
import todoReducer from '../reducers/todoReducer';
import usersAdminReducer from '../reducers/usersAdminReducer';
import wfIntlReducer from '../reducers/wfIntlReducer';
import channelsFilterReducer from '../reducers/channelsFilterReducer';
import workflowReducer from '../reducers/workflowReducer';
import wsReducer from '../reducers/wsReducer';
import matchingWizardReducer from '../reducers/matchingWizardReducer';
import actionTypes from '../actions/actionTypes';
import topicFaqsReducer from '../reducers/topicFaqsReducer';

// Epics
import rootEpic from '../epics/rootEpic';

// Utils
import APIcall from '../utils/APIcall';

// Redux DevTools Extension
// See https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
// for more details of this
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'Espressive',
        trace: true,
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const finalCreateStore = (rootReducer) => {
  // Inject epic dependencies, key for testing
  const epicMiddleware = createEpicMiddleware({
    dependencies: { APIcall },
  });

  // Middleware are a chain
  const rootMiddleware = [
    thunk,
    webSocketMiddleware,
    electronMiddleware,
    pwaMiddleware,
    epicMiddleware,
  ];

  const store = composeEnhancers(applyMiddleware(...rootMiddleware))(
    createStore
  )(rootReducer);

  // run the epic middleware
  epicMiddleware.run(rootEpic);

  return store;
};

const appReducer = combineReducers({
  adminFaqs: adminFaqsReducer,
  adminLocations: adminLocationsReducer,
  adminReports: adminReportsReducer,
  adminSearch: adminSearchReducer,
  announcement: announcementReducer,
  anonResetPassword: anonResetPasswordReducer,
  appUI: appUIReducer,
  appVersion: appVersionReducer,
  approvalsFilter: approvalsFilterReducer,
  asset: assetReducer,
  barista: baristaReducer,
  bundles: bundleReducer,
  cart: cartReducer,
  caseMgmt: caseMgmtReducer,
  cases: casesReducer,
  casesFilter: casesFilterReducer,
  catalog: catalogReducer,
  channelsFilter: channelsFilterReducer,
  chatState: chatReducer,
  compatibilityCheck: compatibilityCheckReducer,
  containersStatus: containersStatus,
  cordova: cordovaReducer,
  csvCasesReport: csvCasesReportReducer,
  dashboard: dashboardReducer,
  directory: directoryReducer,
  dpc: dpcReducer,
  editor: editorReducer,
  emailPolicies: emailPoliciesReducer,
  entities: entitiesReducer,
  errorsApi: errorsReducer,
  form: form,
  hierarchySelect: hierarchySelectReducer,
  homeFilter: homeFilterReducer,
  inboxes: inboxesReducer,
  integration: integrationReducer,
  intl: intlReducer,
  matchingWizard: matchingWizardReducer,
  onboardActivity: onboardActivityReducer,
  onboardProgressManagerView: onboardProgressManagerViewReducer,
  productPermissions: productPermissionsReducer,
  profile: profileReducer,
  reactRouter: reactRouterReducer,
  requests: requestsReducer,
  search: searchReducer,
  selectMyGear: selectMyGearReducer,
  session: sessionReducer,
  shopModal: shopModalReducer,
  socialize: socializeReducer,
  surveys: surveysReducer,
  tenant: tenantReducer,
  toastNotifications: toastNotificationsReducer,
  todo: todoReducer,
  topicFaqs: topicFaqsReducer,
  usersAdmin: usersAdminReducer,
  wfIntl: wfIntlReducer,
  workflowState: workflowReducer,
  ws: wsReducer,
});

/**
 * Wraps the app reducer
 * Used to reset the state on logaout
 */
const resetReducers = (state, action) => {
  // reset all to initial state but the tenant information
  const tenantState = state.get('tenant');
  const baristaState = state.get('barista');
  // DEV-14878 previous url must be preserved
  const previousUrl = state.getIn(['session', 'previousUrl']);

  const undefinedState = void 0; // returns undefined

  return appReducer(undefinedState, action)
    .set('tenant', tenantState)
    .set('barista', baristaState)
    .setIn(['session', 'previousUrl'], previousUrl);
};
const rootReducer = (state, action) => {
  if (
    action.type === actionTypes.USER_LOGOUT ||
    action.type === actionTypes.IMPERSONATING_START
  ) {
    return resetReducers(state, action);
  }
  return appReducer(state, action);
};

const createTestStore = function () {
  return finalCreateStore(rootReducer);
};

const store = finalCreateStore(rootReducer);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept(() => {
    store.replaceReducer(rootReducer);
  });
}
export { createTestStore };
export default store;
