import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Select,
} from 'semantic-ui-react';
import { object, string } from 'yup';
import ImmutableProps from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import ctrl from './PrimaryEmailAccountController';
import EmailAccountTypes from '../../../../../globals/EmailAccountTypes';
import EmailServiceTypes from '../../../../../globals/EmailServiceTypes';
import {
  exclusiveExchangeAndGmailFields,
  exclusiveExchangeOAuthFields,
  generalAuthenticationFields,
  updatedFields,
} from './PrimaryEmailAccountFormFields';
import { isEmpty } from 'lodash';
import { List } from 'immutable';
import {
  emailServiceOptions,
  gridStyle,
  initialValues,
} from './emailConstsAndUtils';
import GmailPrimaryEmailAccount from './gmail/GmailPrimaryEmailAccount';
import GmailPrimaryEmailLegend from './gmail/GmailPrimaryEmailLegend';

const PrimaryEmailAccount = (props) => {
  const {
    handleReset,
    setFieldValue,
    testEmailConnection,
    values,
    errors,
    getCredentials,
    emailCredentials,
    saveEmailCredentials,
    updateEmailCredentials,
    setCurrentCredentialOnState,
    getPrimaryEmailCredentials,
    emailTestConnectionSuccess,
    emailAccountTestConnection,
    isLoadingEmailTestConnection,
    testConnectionSent,
    isValid,
    touched,
    handleBlur,
  } = props;

  const emailType = values?.email_type;

  const [currentCredential, setCurrentCredential] = useState();
  const [isActuallyEditing, setActuallyEditing] = useState(false);
  const [testedEmail, setTestedEmail] = useState();
  const [isGmailConnected, setIsGmailConnected] = useState(false);

  useEffect(() => {
    getCredentials();
    getPrimaryEmailCredentials();
  }, [emailType, getCredentials, getPrimaryEmailCredentials]);

  useEffect(() => {
    if (emailCredentials.size >= 1) {
      const [primaryCredential] = emailCredentials
        .toJS()
        .filter((credential) => {
          return credential.primary && credential.email_type === emailType;
        });
      if (primaryCredential) {
        setCurrentCredential(primaryCredential);
      } else {
        const emailTypeOldestCredential = emailCredentials
          ?.toJS()
          .sort((c1, c2) => c1.id - c2.id)
          .filter((cred) => cred.email_type === emailType)[0];
        if (emailTypeOldestCredential) {
          setCurrentCredential(emailTypeOldestCredential);
        } else {
          setCurrentCredential({ ...initialValues, email_type: emailType });
        }
      }
    }
  }, [emailCredentials, emailType]);

  useEffect(() => {
    if (currentCredential && emailType === currentCredential?.email_type) {
      const generalFields = ['name', 'primary', 'email_address', 'email_type'];
      const oauthFields = ['app_id', 'tenant_id', 'client_secret'];
      const exchangeGmailFields = [
        'password',
        'domain',
        'server_name',
        'primary',
      ];
      let fields = [];

      if (emailType === EmailServiceTypes.EXCHANGE_OAUTH) {
        fields = [...generalFields, ...oauthFields];
      } else {
        fields = [...generalFields, ...exchangeGmailFields];
      }
      setCurrentCredentialOnState(fields, currentCredential);
    }
  }, [
    currentCredential,
    emailType,
    setFieldValue,
    setCurrentCredentialOnState,
  ]);

  const resetGmailForm = useCallback(() => {
    setCurrentCredential({
      ...initialValues,
      email_type: EmailServiceTypes.GMAIL,
    });
  }, []);

  const handleChangeSelect = useCallback(
    (e, { value, name }) => {
      setFieldValue(name, value);
      setActuallyEditing(false);
    },
    [setFieldValue]
  );
  const handleChangeCheckbox = useCallback(
    (e, { checked: value, name }) => {
      setFieldValue(name, value);
      setActuallyEditing(true);
    },
    [setFieldValue]
  );
  const handleChangeCustom = useCallback(
    (e, { value, name }) => {
      setActuallyEditing(true);
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleTestEmailConnection = useCallback(() => {
    const testEmailConnectionProps = {
      currentCredential,
      emailAccountType: EmailAccountTypes.PRIMARY,
    };
    testEmailConnection(testEmailConnectionProps);
    setTestedEmail(currentCredential.email_address);
  }, [testEmailConnection, currentCredential]);

  const editingSpecialCases = useCallback(
    (currentCredential, values) => {
      let specialCases = {};
      if (
        emailType === EmailServiceTypes.EXCHANGE_OAUTH &&
        values?.client_secret !== currentCredential?.secret_creds?.client_secret
      ) {
        specialCases = {
          ...specialCases,
          secret_creds: {
            client_secret: values.client_secret,
          },
        };
      }
      return specialCases;
    },
    [emailType]
  );
  // useCallback
  const handleSend = useCallback(
    (e) => {
      e.preventDefault();
      const modifiedFields =
        !isEmpty(currentCredential) &&
        updatedFields({ currentCredential, emailType, values });

      const specialCases = editingSpecialCases(currentCredential, values);

      const isUpdating =
        currentCredential &&
        currentCredential.id &&
        emailType === currentCredential.email_type &&
        (!isEmpty(modifiedFields) || !isEmpty(specialCases));

      if (isUpdating) {
        updateEmailCredentials(
          { ...modifiedFields, ...specialCases },
          currentCredential
        );
        setActuallyEditing(false);
      } else if (!currentCredential?.id) {
        let fieldsByEmailType = {};
        const {
          app_id,
          client_secret,
          tenant_id,
          password,
          domain,
          server_name,
          name,
          email_address,
          email_type,
        } = values;
        switch (email_type) {
          case EmailServiceTypes.EXCHANGE_OAUTH:
            fieldsByEmailType = {
              email_address,
              email_type,
              name,
              secret_creds: {
                client_secret,
              },
              text_creds: {
                app_id,
                tenant_id,
              },
            };
            break;
          case EmailServiceTypes.EXCHANGE:
            fieldsByEmailType = {
              domain,
              email_address,
              email_type,
              name,
              password,
              server_name,
              // Per DEV-16679 this should be removed, no customer uses this, but backend defaults to true if
              // property is missing, so explicitly set as false.
              use_autodiscover: false,
            };
            break;
          case EmailServiceTypes.GMAIL:
            fieldsByEmailType = {
              email_address,
              email_type,
              name,
            };
            break;
          default:
            break;
        }
        saveEmailCredentials({ ...fieldsByEmailType, primary: true });
        setActuallyEditing(false);
      }
    },
    [
      currentCredential,
      emailType,
      saveEmailCredentials,
      updateEmailCredentials,
      values,
      editingSpecialCases,
    ]
  );

  const formProps = {
    errors,
    handleBlur,
    handleChange: handleChangeCustom,
    handleChangeCheckbox,
    touched,
    values,
  };

  const isGmailForm = emailType === EmailServiceTypes.GMAIL;
  const modifiedFields =
    !isEmpty(currentCredential) &&
    updatedFields({ currentCredential, emailType, values });
  const isTestDisabled =
    !isEmpty(modifiedFields) || (!isValid && !currentCredential?.id);

  return (
    <Form onReset={handleReset} onSubmit={handleSend} style={gridStyle}>
      <Grid as={Segment} attached='bottom' style={gridStyle}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h4'>{'Connection'}</Header>
            <Form.Field
              control={Select}
              fluid
              name='email_type'
              onChange={handleChangeSelect}
              options={emailServiceOptions}
              value={values?.email_type}
            />
            <Divider hidden />
            {!isGmailForm && generalAuthenticationFields(formProps)}
            {emailType === EmailServiceTypes.EXCHANGE_OAUTH &&
              exclusiveExchangeOAuthFields(formProps)}
            {emailType === EmailServiceTypes.EXCHANGE &&
              exclusiveExchangeAndGmailFields(formProps)}
            {isGmailForm && (
              <GmailPrimaryEmailAccount
                {...formProps}
                currentCredential={currentCredential}
                getPrimaryEmailCredentials={getPrimaryEmailCredentials}
                isValid={isValid}
                resetGmailForm={resetGmailForm}
                setIsGmailConnected={setIsGmailConnected}
              />
            )}
          </Grid.Column>
          <Grid.Column width={8}>
            {testedEmail === currentCredential?.email_address && (
              <div>
                {emailAccountTestConnection === EmailAccountTypes.PRIMARY &&
                  testConnectionSent &&
                  emailTestConnectionSuccess &&
                  currentCredential?.email_address && (
                    <Message positive>
                      <Message.Header>{'Connection Test'}</Message.Header>
                      <Message.Content>
                        <p>{`Email was sent to: ${currentCredential.email_address}`}</p>
                        <p>{'Please verify that it was received.'}</p>
                      </Message.Content>
                    </Message>
                  )}

                {emailAccountTestConnection === EmailAccountTypes.PRIMARY &&
                  testConnectionSent &&
                  !emailTestConnectionSuccess &&
                  currentCredential?.email_address && (
                    <Message negative>
                      <Message.Header>{'Connection Failed'}</Message.Header>
                    </Message>
                  )}
              </div>
            )}
            <Divider hidden />
            {currentCredential?.id && (
              <Button
                basic
                content='Test'
                disabled={isGmailForm ? !isGmailConnected : isTestDisabled}
                loading={isLoadingEmailTestConnection}
                onClick={handleTestEmailConnection}
                primary
                size='large'
                type='button'
              />
            )}
          </Grid.Column>
          <Grid.Column width={10}>
            <Form.Group widths={'equal'}>
              <Form.Checkbox
                checked={values.primary}
                label='Enable Virtual Agent to read and respond to all new email messages'
                name='primary'
                onChange={handleChangeCheckbox}
              />
            </Form.Group>
          </Grid.Column>
          <GmailPrimaryEmailLegend />
        </Grid.Row>
      </Grid>
      <Segment attached='bottom' secondary textAlign='right'>
        <Button
          content='Save Changes'
          disabled={!isActuallyEditing || !isValid}
          id='save_button'
          primary
          type='submit'
        />
      </Segment>
    </Form>
  );
};

PrimaryEmailAccount.propTypes = {
  emailAccountTestConnection: PropTypes.string,

  emailCredentials: ImmutableProps.list,

  emailTestConnectionSuccess: PropTypes.bool,

  errors: PropTypes.shape({}),

  getCredentials: PropTypes.func.isRequired,

  getPrimaryEmailCredentials: PropTypes.func.isRequired,

  handleBlur: PropTypes.func.isRequired,

  handleChange: PropTypes.func.isRequired,

  handleReset: PropTypes.func.isRequired,

  isLoadingEmailTestConnection: PropTypes.bool,

  isValid: PropTypes.bool.isRequired,

  saveEmailCredentials: PropTypes.func.isRequired,

  setCurrentCredentialOnState: PropTypes.func.isRequired,

  setFieldValue: PropTypes.func.isRequired,

  testConnectionSent: PropTypes.bool,

  testEmailConnection: PropTypes.func.isRequired,

  touched: PropTypes.bool.isRequired,

  updateEmailCredentials: PropTypes.func.isRequired,

  values: PropTypes.shape({
    app_id: PropTypes.string,
    client_secret: PropTypes.string,
    domain: PropTypes.string,
    email_address: PropTypes.string,
    email_type: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    primary: PropTypes.bool,
    server_name: PropTypes.string,
    tenant_id: PropTypes.string,
  }),
};
PrimaryEmailAccount.defaultProps = {
  emailCredentials: List(),

  errors: {},

  isLoadingEmailTestConnection: false,

  testConnectionSent: false,

  values: {
    app_id: '',
    client_secret: '',
    domain: '',
    email_address: '',
    email_type: '',
    name: '',
    password: '',
    primary: false,
    server_name: '',
    tenant_id: '',
  },
};
const PrimaryEmailAccountWithFormik = withFormik({
  displayName: 'PrimaryEmailAccountForm',
  enableReinitialize: true,
  mapPropsToValues: ({ firstEmailCredential, currentEmailCredential }) => ({
    app_id: currentEmailCredential?.app_id || '',
    client_secret: currentEmailCredential?.client_secret || '',
    domain: currentEmailCredential?.domain || '',
    email_address: currentEmailCredential?.email_address || '',
    email_type:
      currentEmailCredential?.email_type ||
      firstEmailCredential?.email_type ||
      EmailServiceTypes.EXCHANGE_OAUTH,
    name: currentEmailCredential?.name || '',
    password: currentEmailCredential?.password || '',
    primary: currentEmailCredential?.primary || false,
    server_name: currentEmailCredential?.server_name || '',
    tenant_id: currentEmailCredential?.tenant_id || '',
  }),
  validationSchema: object().shape({
    email_address: string().email('Invalid email').required('Required'),
    name: string().required('Required'),
  }),
})(PrimaryEmailAccount);

const PrimaryEmailAccountWithFormikTest = PrimaryEmailAccountWithFormik;

export { PrimaryEmailAccountWithFormikTest };

export default ctrl(PrimaryEmailAccountWithFormik);
