import { useState } from 'react';
import endpointGenerator from '../../../v1/utils/endpointGenerator';
import EspFilters from 'esp-util-filters';
import APIcall from '../../utils/APIcall';

const ERROR_STATUS = 400;

const baristaIntentLookup = ({ limit = 10, search = '' }) => {
  const espFilters = new EspFilters();
  espFilters.isFalse('is_archetype');
  espFilters.isTrue('active');
  espFilters.isTrue('application.active');
  espFilters.contains('name', search);

  return APIcall.get({
    query: {
      esp_filters: espFilters.asQueryString(),
      limit,
      orderBy: '-sys_date_updated',
    },
    token: true,
    url: endpointGenerator.genPath('espBarista.intents'),
  });
};

const baristaIntentLookupByEID = (eid = '') => {
  const query = {
    esp_filters: new EspFilters().equalTo('eid', eid).asQueryString(),
  };

  return APIcall.get({
    query,
    token: true,
    url: endpointGenerator.genPath('espBarista.intents', {
      intentID: eid,
    }),
  });
};

const useIntentLookup = () => {
  const [isLoading, setLoading] = useState(false);
  const [intents, setIntents] = useState([]);

  const intentLookup = async (search) => {
    const response = await baristaIntentLookup(search);
    if (response?.status !== ERROR_STATUS) {
      const results = response?.body?.results || [];
      const intents = results.map((res) => ({
        id: res.eid,
        title: res.name,
      }));
      setIntents(intents);
    }
    setLoading(false);
    return {};
  };

  const intentLookupById = async (id) => {
    const response = await baristaIntentLookupByEID(id);
    if (response?.status !== ERROR_STATUS) {
      const results = response?.body?.results || [];
      const intents = results.map((res) => ({
        id: res.eid,
        title: res.name,
      }));
      setIntents(intents);
      return intents;
    }
    setLoading(false);
    return {};
  };

  return {
    intentLookup,
    intentLookupById,
    intents,
    isIntentLoading: isLoading,
  };
};

export default useIntentLookup;
