import '@espressive/legacy-css';

import 'inter-ui';

// eslint-disable-next-line no-unused-vars -- leaving this here, since I don't know if it's required
import React from 'react';

// Main App renderer
import renderApp from './renderApp';
import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';
import browserHistory from './v1/utils/browserHistory';
import uiPathGenerator from './v1/utils/uiPathGenerator';

// Make sure we get all of the assets from our lib directory
require.context('../../lib/images/', true, /^.*/);

// DEV-3617  Disabling drag and drop of files into electron app
document.addEventListener('dragover', (event) => event.preventDefault());
document.addEventListener('drop', (event) => event.preventDefault());

const startUpApp = () => {
  // Redirecting if there's a session
  if (window.location.hash.indexOf('#/auth') > -1) {
    // console.log('Waiting for a reload....');
    // nothing should happen there
    // electron will do the redirect
    // but don't remove this condition because the other case will happen
  } else if (
    persistentStorage.has(PersistentStorageKeys.SESSION) &&
    persistentStorage.has(PersistentStorageKeys.TENANT_URL)
  ) {
    // If the session is not valid, they will be kicked out and taken to login
    browserHistory.push(uiPathGenerator.genPath('app.toDo'));
  } else {
    // otherwise the default landing page for mobile is the select tenant screen
    browserHistory.push(uiPathGenerator.genPath('noTenant'));
  }

  // informs electron main process that we came online
  // Also, this "online" event is not a well-supported API, but in electron works fine
  window.addEventListener('online', () => {
    if (window.electron) {
      window.electronAPI?.ipcSend('window-online');
    }
  });

  // Has to happen after redirect
  renderApp();
};

export default startUpApp;
