import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';

const smsThunks = {};

/** Send an SMS to current user's mobile number */
smsThunks.sendSMS = (subject, content) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espNotification.sendSMS');

    const payload = {
      message_content: content,
      subject,
    };

    APIcall.post({
      data: payload,
      error(error) {
        reject(error);
      },
      success() {
        resolve();
      },
      token: true,
      url,
    });
  });

export default smsThunks;
