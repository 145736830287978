import actionTypes from './actionTypes';

const catalogActions = {};

catalogActions.loadCategoriesStart = () => ({
  type: actionTypes.LOAD_CATEGORIES_START,
});

catalogActions.loadCategoriesSuccess = (categories) => ({
  categories,
  type: actionTypes.LOAD_CATEGORIES_SUCCESS,
});

catalogActions.setSelectedCategory = (categoryId) => ({
  categoryId,
  type: actionTypes.SET_CATALOG_SELECTED_CATEGORY,
});

catalogActions.setSelectedSubCategory = (subCategoryId) => ({
  subCategoryId,
  type: actionTypes.SET_CATALOG_SELECTED_SUBCATEGORY,
});

catalogActions.loadCategoriesFailure = (error) => ({
  error,
  type: actionTypes.LOAD_CATEGORIES_FAIL,
});

catalogActions.loadSubCategoriesStart = (categoryId) => ({
  categoryId,
  type: actionTypes.LOAD_SUBCATEGORIES_START,
});

catalogActions.loadSubCategoriesSuccess = (categoryId, subcategories) => ({
  categoryId,
  subcategories,
  type: actionTypes.LOAD_SUBCATEGORIES_SUCCESS,
});

catalogActions.loadSubCategoriesFailure = (categoryId, error) => ({
  categoryId,
  error,
  type: actionTypes.LOAD_SUBCATEGORIES_FAIL,
});

catalogActions.loadAllSubCategoriesStart = (categoryId) => ({
  categoryId,
  type: actionTypes.LOAD_ALL_SUBCATEGORIES_START,
});

catalogActions.loadAllSubCategoriesSuccess = (subcategories, pagination) => ({
  pagination,
  subcategories,
  type: actionTypes.LOAD_ALL_SUBCATEGORIES_SUCCESS,
});

catalogActions.loadAllSubCategoriesFailure = (errorMsg) => ({
  errorMsg,
  type: actionTypes.LOAD_ALL_SUBCATEGORIES_FAIL,
});

catalogActions.loadProductsStart = (categoryId) => ({
  categoryId,
  type: actionTypes.LOAD_PRODUCTS_START,
});

catalogActions.loadProductsSuccess = (
  products,
  count,
  categoryPath,
  resetProductsList,
  searchResultsOrder
) => ({
  categoryPath,
  count,
  products,
  resetProductsList,
  searchResultsOrder,
  type: actionTypes.LOAD_PRODUCTS_SUCCESS,
});

catalogActions.noMoreProducts = () => ({
  type: actionTypes.NO_MORE_PRODUCTS,
});

catalogActions.loadOneProductStart = (productId) => ({
  productId,
  type: actionTypes.LOAD_ONE_PRODUCT_START,
});

catalogActions.loadOneProductSuccess = (productId, product) => ({
  product,
  productId,
  type: actionTypes.LOAD_ONE_PRODUCT_SUCCESS,
});

catalogActions.loadOneProductFail = (productId, errorMsg) => ({
  errorMsg,
  productId,
  type: actionTypes.LOAD_ONE_PRODUCT_FAIL,
});

catalogActions.resetProductList = () => ({
  type: actionTypes.RESET_PRODUCTS_SUCCESS,
});

catalogActions.updateProductImagesSuccess = (products) => ({
  products,
  type: actionTypes.UPDATE_PRODUCTS_IMAGES_SUCCESS,
});

catalogActions.updateProductImagesStart = () => ({
  type: actionTypes.UPDATE_PRODUCTS_IMAGES_START,
});

catalogActions.updateProductImagesError = (error) => ({
  error,
  type: actionTypes.UPDATE_PRODUCTS_IMAGES_ERROR,
});

catalogActions.loadProductsFailure = (error) => ({
  error,
  type: actionTypes.LOAD_PRODUCTS_FAIL,
});

catalogActions.setSelectedCatalogRoute = (
  categoryId = '',
  subCategoryId = ''
) => ({
  categoryId,
  subCategoryId,
  type: actionTypes.CATALOG_BROWSE,
});

catalogActions.setActiveImage = (productID, position) => ({
  position,
  productID,
  type: actionTypes.SET_CATALOG_ACTIVE_RPODUCT_GALLERY_IMAGE,
});

catalogActions.setSearchUsersTerm = (searchTerm) => ({
  searchTerm,
  type: actionTypes.SET_CATALOG_SEARCH_PRODUCTS_TERM,
});

catalogActions.setActiveProduct = (productID) => ({
  productID,
  type: actionTypes.SET_ACTIVE_PRODUCT,
});

catalogActions.isSearchingStart = () => ({
  type: actionTypes.IS_SEARCHING_START,
});

catalogActions.loadProductFamilyStart = (id) => ({
  id,
  type: actionTypes.LOAD_PFAMILY_START,
});

catalogActions.loadProductFamilySuccess = (id, productFamily) => ({
  id,
  productFamily,
  type: actionTypes.LOAD_PFAMILY_SUCCESS,
});

catalogActions.loadProductFamilyFail = (id, errorMsg) => ({
  errorMsg,
  id,
  type: actionTypes.LOAD_PFAMILY_FAIL,
});

catalogActions.loadFulfillmentOptionsStart = (productId) => ({
  productId,
  type: actionTypes.LOAD_FUL_OPTS_START,
});

catalogActions.loadFulfillmentOptionsSuccess = (
  productId,
  fulfillmentOptions
) => ({
  fulfillmentOptions,
  productId,
  type: actionTypes.LOAD_FUL_OPTS_SUCCESS,
});

catalogActions.loadFulfillmentOptionsFail = (productId, errorMsg) => ({
  errorMsg,
  productId,
  type: actionTypes.LOAD_FUL_OPTS_FAIL,
});

catalogActions.createNewProductStart = () => ({
  type: actionTypes.CREATE_NEW_PRODUCT_START,
});

catalogActions.createNewProductError = () => ({
  type: actionTypes.CREATE_NEW_PRODUCT_ERROR,
});

catalogActions.createNewProductSuccess = () => ({
  type: actionTypes.CREATE_NEW_PRODUCT_SUCCESS,
});

catalogActions.saveTmpImage = (img) => ({
  img,
  type: actionTypes.SAVE_TEMPORARY_PRODUCT_IMAGE,
});

catalogActions.clearTmpImage = () => ({
  type: actionTypes.CLEAR_TEMPORARY_PRODUCT_IMAGE,
});

catalogActions.deleteTmpImage = (imageID) => ({
  imageID,
  type: actionTypes.DELETE_TEMPORARY_PRODUCT_IMAGE,
});

catalogActions.replaceTmpImage = (images) => ({
  images,
  type: actionTypes.REPLACE_TEMPORARY_PRODUCT_IMAGE,
});

catalogActions.adminAllowLeave = (flag) => ({
  flag,
  type: actionTypes.ADMIN_ALLOW_LEAVE,
});

catalogActions.setProductImages = (images) => ({
  images,
  type: actionTypes.SET_PRODUCT_IMAGES,
});

catalogActions.loadFulfillmentAnswersSuccess = (
  productId,
  fulfillmentAnswers
) => ({
  fulfillmentAnswers,
  productId,
  type: actionTypes.LOAD_FUL_ANSWERS_SUCCESS,
});

catalogActions.saveFulfillmentAnswersStart = (productId) => ({
  productId,
  type: actionTypes.SAVE_FUL_ANSWERS_START,
});

catalogActions.saveFulfillmentAnswersSuccess = (productId) => ({
  productId,
  type: actionTypes.SAVE_FUL_ANSWERS_SUCCESS,
});

catalogActions.saveFulfillmentAnswersFail = (productId) => ({
  productId,
  type: actionTypes.SAVE_FUL_ANSWERS_FAIL,
});
export default catalogActions;
