const any = (paramName) => `:${paramName}`;

const numericOnly = (paramName) => `:${paramName}(\\d+)`;

// https://stackoverflow.com/questions/136505/searching-for-uuids-in-text-with-regex
const uuidOnly = (paramName) =>
  `:${paramName}([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`;

const optional = (param) => `${param}?`;

const PathParamsValidators = {
  any,
  numericOnly,
  optional,
};

export { optional, uuidOnly, numericOnly, any };

export default PathParamsValidators;
