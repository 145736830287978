import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';
import { CASE_TYPE } from '../../v2/components/functional/CaseCard/caseConstants';

const INITIAL_STATE = fromJS([]);

const requestsEntitiesReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_REQUESTS_SUCCESS:
      return fromJS(action.requests);

    case actionTypes.CASE_TASK_UPDATE:
      if (
        action.task &&
        (action.task.type === CASE_TYPE.SERVICE_REQUEST ||
          action.task.type === CASE_TYPE.SNOW_SERVICE_REQUEST)
      ) {
        // must be a task update of this type
        const taskId = action.task.id;
        const indexOfRequest = state.findIndex((r) => r.get('id') === taskId);
        if (indexOfRequest > -1) {
          // if found
          return state.set(indexOfRequest, fromJS(action.task));
        } else {
          // or just push it if it doesn't exist
          return (state = state.push(fromJS(action.task)));
        }
      }
      return state;
    default:
      return state;
  }
};

export default requestsEntitiesReducer;
