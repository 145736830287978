import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';
import defaultSystemID from '../globals/defaultSystemID';
import defaultClientID from '../globals/defaultClientID';

const INITIAL_STATE = fromJS({
  clientId: btoa(defaultClientID),
  error: null,
  isLoading: false,
  loaded: false,
  // cordova app version
  nativeDesktopVersion: null,

  nativeMobileVersion: null,
  systemId: btoa(defaultSystemID), // electron app version
});

const getInfo = (id, isClient, isEncodeForced, environment) => {
  const sID = id
    ? id.replace(/release\/|release_/gi, '')
    : isClient
    ? defaultClientID
    : defaultSystemID;
  return !environment || environment !== 'devserver' || isEncodeForced
    ? btoa(sID)
    : sID;
};

const appVersionReducer = (state, action = {}) => {
  if (!state) {
    return INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.SYSTEM_VERSION_FAIL:
      return state
        .set('error', action.error)
        .set('isLoading', false)
        .set('loaded', false);

    case actionTypes.SYSTEM_VERSION_START:
      return state.set('isLoading', true).set('loaded', false);

    case actionTypes.SYSTEM_VERSION_SUCCESS:
      return state
        .set('isLoading', false)
        .set('loaded', true)
        .set(
          'systemId',
          getInfo(
            action.systemId,
            false,
            action.isEncodeForced,
            action.environment
          )
        );

    case actionTypes.CLIENT_VERSION:
      return state.set(
        'clientId',
        getInfo(
          action.clientId,
          true,
          action.isEncodeForced,
          action.environment
        )
      );

    case actionTypes.NATIVE_MOBILE_VERSION:
      return state.set('nativeMobileVersion', action.version);

    case actionTypes.NATIVE_DESKTOP_VERSION:
      return state.set('nativeDesktopVersion', action.version);

    default:
      return state;
  }
};

export default appVersionReducer;
