import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsRecognition extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#FF0007',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 48 48'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0,24 C0,10.7449091 10.7449091,0 24,0 C37.2550909,0 48,10.7449091 48,24 C48,37.2550909 37.2550909,48 24,48 C10.7449091,48 0,37.2550909 0,24 Z M24,45.2727273 C35.73,45.2727273 45.2727273,35.73 45.2727273,24 C45.2727273,12.27 35.73,2.72727273 24,2.72727273 C12.27,2.72727273 2.72727273,12.27 2.72727273,24 C2.72727273,35.73 12.27,45.2727273 24,45.2727273 Z M24.4997336,7 C29.7463482,7 34,11.3720031 34,16.7645966 C34,20.4795673 31.9805142,23.7088816 29.0096167,25.3591279 L29.0096167,39.8291716 L24.5673872,36.5664582 L19.9866543,40 L19.9866543,25.3574854 C17.017355,23.7066914 15,20.4779247 15,16.7645966 C15,11.3720031 19.2531191,7 24.4997336,7 Z M26.9975888,17.8519852 L29.4949113,15.3497868 L26.0435136,14.8340164 L24.4997336,11.6189378 L22.9559537,14.8340164 L19.504556,15.3497868 L22.0018785,17.8519852 L21.4127064,21.3857245 L24.4997336,19.7174097 L27.5867609,21.3857245 L26.9975888,17.8519852 Z'
          fill={color}
          fillRule='evenodd'
        />
      </svg>
    );
  }
}

export default AnnouncementsRecognition;
