import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreMugs extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M76.61 36.13c-2.487-2.69-5.967-4.244-9.63-4.3-1.786 0-3.55.4-5.16 1.17V20.34h-.07C61.58 17.94 51.32 16 38.67 16c-12.65 0-22.91 1.94-23.08 4.34h-.08V75.6c.04 2.4 10.4 4.4 23.17 4.4 12.77 0 23.14-2 23.14-4.4V62.37c6.61-2.51 17.44-6.7 18.5-15.07.504-4.092-.858-8.193-3.71-11.17zm-38.07-11.9c-10.89 0-19.72-1.23-19.72-2.65s8.83-2.65 19.72-2.65c10.89 0 19.72 1.19 19.72 2.65s-8.84 2.65-19.72 2.65zm35.57 22.38C73.57 50.88 67.02 54 61.82 56V40.7c1.134-1.78 3.062-2.9 5.17-3 1.915.06 3.723.902 5 2.33 1.657 1.765 2.435 4.18 2.12 6.58z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogCompanyStoreMugs;
