import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import EspFilters from 'esp-util-filters';

export const getBaristaIntent = ({ intentID }) => {
  const query = new EspFilters().equalTo('eid', intentID).asQueryString();
  const routeParams = { intentID };

  return APIcall.get({
    query: {
      esp_filters: query,
      orderBy: '-sys_date_created',
    },
    token: true,
    url: endpointGenerator.genPath('espBarista.intents', routeParams),
  });
};

export const getBaristaIntents = ({ search, limit = 10 }) => {
  const query = new EspFilters()
    .contains('name', search)
    .isFalse('is_archetype')
    .isTrue('active')
    .isTrue('application.active')
    .asQueryString();

  return APIcall.get({
    query: {
      esp_filters: query,
      limit,
      orderBy: '-sys_date_created',
    },
    token: true,
    url: endpointGenerator.genPath('espBarista.intents'),
  });
};
