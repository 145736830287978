import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogHardwarePrinter extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} transform='translate(20.5 18)'>
          <path d='M54.89 45.22c.006-.08.006-.16 0-.24l-2.35-14.39c-.076-4.023-3.357-7.245-7.38-7.25h-.79V0H11.05v23.34h-.32c-4.023.005-7.304 3.227-7.38 7.25L1 44.98c-.007.08-.007.16 0 .24.004 3.988 3.174 7.25 7.16 7.37l-1.48 7h41.8l-1-7c1.96.005 3.842-.768 5.23-2.15 1.39-1.382 2.175-3.26 2.18-5.22zM14.05 3h27.32v20.32H14.05V3zM45 56.56H10.38l2.91-14h29.83l1.88 14zm.7-16.74H10.85l-2.05 9.7h-.41c-2.35.002-4.283-1.844-4.39-4.19l2.33-14.37c.007-.08.007-.16 0-.24.005-2.417 1.963-4.375 4.38-4.38h34.45c2.417.005 4.375 1.963 4.38 4.38-.006.08-.006.16 0 .24l2.33 14.38c-.065 2.374-2.005 4.265-4.38 4.27h-.44l-1.35-9.79z' />
          <circle cx='41.56' cy='33.46' r='2.68' />
        </g>
      </svg>
    );
  }
}

export default CatalogHardwarePrinter;
