import { connectAdvanced } from 'react-redux';

import hierarchySelectActions from '../../actions/hierarchySelectActions';

const selectorFactory = (dispatch) => (nextState, nextOwnProps) => ({
  ...nextOwnProps,

  getChildrenNodes(parentID) {
    const hierarchySelectState = nextState.get('hierarchySelect');
    const childrenNodesCache = hierarchySelectState.get('childrenNodesCache');

    const { hierarchyType } = nextOwnProps;

    const cacheEntry = `${hierarchyType}:${parentID}`;

    // did we already make an API request to get children of this node of this hierarchy type?
    const hasChildrenNodesInCache = childrenNodesCache.has(cacheEntry);

    if (!hasChildrenNodesInCache) {
      // send API request to get children of this node of this hierarchy type
      nextOwnProps.getChildrenNodes(parentID);

      // make sure next time 'hasChildrenNodesInCache' evaluates to true
      dispatch(
        hierarchySelectActions.cacheChildrenNodes(hierarchyType, parentID)
      );
    }
  },

  getDefaultSelectedNode(nodeID) {
    const hierarchySelectState = nextState.get('hierarchySelect');
    const defaultSelectedNodeCache = hierarchySelectState.get(
      'defaultSelectedNodeCache'
    );

    const { hierarchyType } = nextOwnProps;

    const cacheEntry = `${hierarchyType}:${nodeID}`;

    // did we already make an API request to get this defaultSelectedNode of this hierarchyType?
    const hasDefaultSelectedNodeInCache = defaultSelectedNodeCache.has(
      cacheEntry
    );

    // 'getDefaultSelectedNode' is expected to return a Promise
    let promise;

    if (hasDefaultSelectedNodeInCache) {
      // resolve automatically without API request
      promise = new Promise((resolve) => resolve());
    } else {
      // send API request to get default selected node of this hierarchy type
      promise = nextOwnProps.getDefaultSelectedNode(nodeID).then(() => {
        // make sure next time 'hasDefaultSelectedNodeInCache' evaluates to true
        dispatch(
          hierarchySelectActions.cacheDefaultSelectedNode(hierarchyType, nodeID)
        );
      });
    }

    return promise;
  },

  getRootNodes() {
    const hierarchySelectState = nextState.get('hierarchySelect');
    const rootNodesCache = hierarchySelectState?.get('rootNodesCache');

    const { hierarchyType } = nextOwnProps;

    // did we already make an API request to get the root nodes of this hierarchy type?
    const hasRootNodesInCache = rootNodesCache?.has(hierarchyType);

    if (!hasRootNodesInCache) {
      // send API request to get root nodes for this hierarchy type
      nextOwnProps.getRootNodes();

      // make sure next time 'hasRootNodesInCache' evaluates to true
      dispatch(hierarchySelectActions.cacheRootNodes(hierarchyType));
    }
  },
});

const HierarchySelectController = (component) =>
  connectAdvanced(selectorFactory)(component);
export default HierarchySelectController;
