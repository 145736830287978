// Utils
import buildCompositeController from '../../utils/buildCompositeController';
import CordovaUtils from '../../utils/CordovaUtils';
import ElectronUtils from '../../utils/ElectronUtils';
import SSOUtils from '../../utils/SSOUtils';
import sessionThunks from '../../actions/sessionThunks';

const preLogoutActions = () =>
  new Promise((resolve) => {
    // Logout after deregistering APNs (whether success or not)
    if (window.cordova) {
      CordovaUtils.deRegisterAPNS().finally(() => {
        // always resolve after CordovaUtils.deRegisterAPNS() is done regardless of result
        resolve();
      });
    } else if (window.electron) {
      ElectronUtils.deRegisterClientNotification().finally(() => {
        // always resolve after CordovaUtils.deRegisterAPNS() is done regardless of result
        // Logout after deregistering APNs (whether success or not)
        resolve();
      });
    } else {
      resolve(); // resolve immediatly
    }
  });

const mapStateToProps = (state, ownprops) => ({
  externalLogoutHtmlDoc: state.getIn(['session', 'externalLogoutHtmlDoc']),
  location: ownprops.location,
});

const mapDispatchToProps = (dispatch) => ({
  logoutSession(isLocalOnly) {
    preLogoutActions().then(() => {
      dispatch(sessionThunks.userLogout(isLocalOnly)).then((res) => {
        if (res) {
          if (res.type === 'text/html') {
            const externalLogoutHtmlDoc = res.text;
            SSOUtils.logoutExternallyWithHTML(externalLogoutHtmlDoc);
          } else if (res.body && res.body.logout_url) {
            const logouturl = res.body.logout_url;
            SSOUtils.logoutExternallyWithURL(logouturl);
          }
        }
      });
    });
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
