import buildCompositeController from '../../utils/buildCompositeController';
import onboardActivityThunks from '../../actions/onboardActivityThunks';

const mapStateToProps = (state) => ({
  isLoading: state.getIn(['onboardActivity', 'template', 'isLoading']),
  nextTemplate: state.getIn(['onboardActivity', 'template', 'next']),
  templates: state.getIn(['onboardActivity', 'template', 'items']),
});

const mapDispatchToProps = (dispatch) => ({
  loadTemplates(resetTemplateList) {
    dispatch(onboardActivityThunks.loadTemplatesList(resetTemplateList));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
