import { every, isEmpty, map } from 'lodash';
import {
  ACTION_TYPES,
  MAX_ACTION_TEXT_LENGTH,
} from '../../../../v1/globals/announcementCustomActionConstants';

const isActionValid = (actionType, button) => {
  const mapIsPresent = (fields) =>
    map(fields, (field) => !isEmpty(button[field]));
  switch (actionType) {
    case ACTION_TYPES.REPORT_RESPONSE_ONLY:
      return (
        !isEmpty(button.button_text) &&
        button.button_text.length < MAX_ACTION_TEXT_LENGTH
      );
    case ACTION_TYPES.URL:
      return every(mapIsPresent(['button_text', 'action_url']));
    case ACTION_TYPES.INTENT:
      return every(mapIsPresent(['button_text', 'intent_eid']));
    default:
      return true;
  }
};

const surveyTypes = {
  APPROVE_DISAPPROVE: {
    buttons: [
      {
        button_text: 'label.approve',
        icon: 'thumbs up outline',
        is_button_positive: true,
      },
      {
        button_text: 'label.disapprove',
        icon: 'thumbs down outline',
        is_button_positive: false,
      },
    ],
    dropdownOption: 'Approve/Disapprove',
    value: 'APPROVE_DISAPPROVE',
  },

  GOOD_BAD: {
    buttons: [
      {
        button_text: 'label.good',
        icon: 'feedback-2',
        isTextModifiable: true,
        is_button_positive: true,
      },
      {
        button_text: 'label.bad',
        icon: 'feedback-0',
        isTextModifiable: true,
        is_button_positive: false,
      },
    ],
    dropdownOption: 'Good/Bad',
    value: 'GOOD_BAD',
  },

  TEXT_ONLY: {
    buttons: [
      {
        button_text: 'label.preview',
        icon: '',
        is_button_positive: true,
      },
      {
        button_text: 'label.preview',
        icon: '',
        is_button_positive: false,
      },
    ],
    dropdownOption: 'Text Only',
    value: 'TEXT_ONLY',
  },
};

export { isActionValid };
export default surveyTypes;
