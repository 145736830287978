import Immutable, { fromJS } from 'immutable';
import { get, isNumber, size } from 'lodash';
import actionTypes from '../actions/actionTypes';

const CANDIDATE_TYPES = {
  FAQS: 'faqs',
  TEMPLATES: 'templates',
};

const INITIAL_STATE = fromJS({
  activeEditFaqEid: null,
  archetypeList: [],
  conditionsNamesMap: {},
  count: null,
  currentFaq: Immutable.Map(),
  editedConditionsByAnswer: {},
  entityList: [],
  entitySynonymList: [],
  faqGroupEid: null,
  faqMatch: {},
  faqToEdit: {},
  // Faq matched found in BuildAndSearchFAQTeachBarista block
  faqsList: [],
  faqsListResult: [],
  imageNewFaq: {},
  isEmptyResults: false,
  isLoading: false,
  isLoadingArchetypeList: false,
  isLoadingEntityList: false,
  isLoadingEntitySynonymList: false,
  isLoadingFAQ: false,
  isLoadingFAQGroup: false,
  isLoadingFAQMatch: false,
  isLoadingFAQWorkflow: false,
  isLoadingImage: false,
  isLoadingTriggerList: false,
  isSendingFAQReport: false,
  isTemporaryResponse: false,
  kbSupport: {
    count: null,
    isLoading: false,
    results: null,
  },
  primaryEntities: {},
  selectedFAQtoEdit: null,
  serviceDepartmentMap: {},
  serviceTeamMap: {},
  taskCategorytMap: {},
  topCandidateFaqsMatches: {
    faqs: {},
    isLoading: false,
    name: '',
    templates: {},
  },
  triggerList: [],
});

const adminFaqsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.FAQ_AS_KB_ADDITIONAL_INFORMATION: {
      return state.setIn(
        ['currentFaq', 'additionalInformation'],
        fromJS(action.additionalInformation)
      );
    }
    case actionTypes.FAQ_AS_KB_IS_DISABLED_MODAL_OPEN: {
      return state.setIn(['faqAsKbIsDisabledModal', 'isOpen'], true);
    }
    case actionTypes.FAQ_AS_KB_IS_DISABLED_MODAL_CLOSE: {
      return state.setIn(['faqAsKbIsDisabledModal', 'isOpen'], false);
    }

    case actionTypes.ADD_FAQ_ANSWER: {
      const faqIndex = state
        .get('faqsList')
        .findIndex((faq) => faq.get('eid') === action.eid);
      const answers = state
        .getIn(['faqsList', faqIndex])
        .get('answers')
        .update((answers) =>
          answers.unshift(
            fromJS({
              active: true,
              is_protected: false,
              lines: [
                {
                  active: true,
                  is_protected: false,
                  phrase: '',
                },
              ],
            })
          )
        );

      return state
        .set('isTemporaryResponse', true)
        .setIn(['faqsList', faqIndex, 'answers'], answers);
    }

    case actionTypes.FAQ_WIZ_GET_DATA_START: {
      return state.set('isTemporaryResponse', false);
    }

    case actionTypes.REMOVE_NEW_FAQ_ANSWER: {
      const faqIndex = state
        .get('faqsList')
        .findIndex((faq) => faq.get('eid') === action.eid);
      const faqAnswers = state
        .getIn(['faqsList', faqIndex, 'answers'])
        .filter((faq) => faq.has('eid'));
      return state
        .set('isTemporaryResponse', false)
        .setIn(['faqsList', faqIndex, 'answers'], faqAnswers);
    }

    case actionTypes.SEND_FAQ_REPORT_START: {
      return state.set('isSendingFAQReport', true);
    }

    case actionTypes.SEND_FAQ_REPORT_FAIL: {
      return state.set('isSendingFAQReport', false);
    }

    case actionTypes.SEND_FAQ_REPORT_SUCCESS: {
      return state.set('isSendingFAQReport', false);
    }

    case actionTypes.LOAD_TOP_CANDIDATE_MATCHES_FAQS_FAIL: {
      return state.setIn(['topCandidateFaqsMatches', 'isLoading'], false);
    }

    case actionTypes.LOAD_TOP_CANDIDATE_MATCHES_FAQS_START: {
      return state.setIn(['topCandidateFaqsMatches', 'isLoading'], true);
    }

    case actionTypes.LOAD_TOP_CANDIDATE_MATCHES_FAQS_SUCCESS: {
      return state
        .setIn(
          ['topCandidateFaqsMatches', 'faqs'],
          fromJS(
            get(
              action.data,
              CANDIDATE_TYPES.FAQS,
              INITIAL_STATE.getIn(['topCandidateFaqsMatches', 'faqs'])
            )
          )
        )
        .setIn(
          ['topCandidateFaqsMatches', 'templates'],
          fromJS(
            get(
              action.data,
              CANDIDATE_TYPES.TEMPLATES,
              INITIAL_STATE.getIn(['topCandidateFaqsMatches', 'templates'])
            )
          )
        )
        .setIn(['topCandidateFaqsMatches', 'name'], action.name)
        .setIn(['topCandidateFaqsMatches', 'isLoading'], false);
    }

    case actionTypes.LOAD_FAQ_MATCH_START: {
      return state.set('isLoadingFAQMatch', true);
    }

    case actionTypes.LOAD_FAQ_MATCH_SUCCESS: {
      return state
        .set('faqMatch', fromJS(action.faq))
        .set('isLoadingFAQMatch', false);
    }

    case actionTypes.CREATE_FAQ_START: {
      return state.set('isLoading', true);
    }
    case actionTypes.CREATE_FAQ_END: {
      return state.set('isLoading', false);
    }

    case actionTypes.GET_FAQ_TO_EDIT_START: {
      return state.set('faqToEdit', fromJS({})).set('isLoadingFAQ', true);
    }

    case actionTypes.GET_FAQ_TO_EDIT_FAIL: {
      return state.set('isLoadingFAQ', false);
    }

    case actionTypes.GET_CURRENT_FAQ_SUCCESS: {
      return state
        .set('faqToEdit', action.faq)
        .set('isLoadingFAQ', false)
        .set('currentFaq', fromJS(action.faq));
    }

    case actionTypes.BARISTA_FAQ_LOAD_PRIMARY_ENTITY_START: {
      return state.set('isLoadingEntityList', true);
    }

    case actionTypes.BARISTA_FAQ_LOAD_PRIMARY_ENTITY_FAIL: {
      return state.set('isLoadingEntityList', false);
    }

    case actionTypes.BARISTA_FAQ_LOAD_PRIMARY_ENTITY_SUCCESS: {
      return state
        .setIn(
          ['primaryEntities', action.archetypeEID],
          fromJS(action.primaryEntity)
        )
        .set('isLoadingEntityList', false);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ENTITY_START: {
      return state.set('isLoadingEntityList', true);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ENTITY_FAIL: {
      return state.set('isLoadingEntityList', false);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ENTITY_SUCCESS: {
      return state
        .set('isLoadingEntityList', false)
        .set('entityList', fromJS(action.results));
    }

    case actionTypes.BARISTA_FAQ_LOAD_ENTITY_SYNONYM_START: {
      return state.set('isLoadingEntitySynonymList', true);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ENTITY_SYNONYM_FAIL: {
      return state.set('isLoadingEntitySynonymList', false);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ENTITY_SYNONYM_SUCCESS: {
      return state
        .set('isLoadingEntitySynonymList', false)
        .set('entitySynonymList', fromJS(action.results));
    }

    case actionTypes.BARISTA_FAQ_LOAD_ARCHETYPE_START: {
      return state.set('isLoadingArchetypeList', true);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ARCHETYPE_FAIL: {
      return state.set('isLoadingArchetypeList', false);
    }

    case actionTypes.BARISTA_FAQ_LOAD_ARCHETYPE_SUCCESS: {
      return state
        .set('isLoadingArchetypeList', false)
        .set('archetypeList', fromJS(action.results));
    }

    case actionTypes.BARISTA_FAQ_ADD_ARCHETYPE_SUCCESS: {
      const archetypeList = state
        .get('archetypeList')
        .concat(fromJS(action.results));

      return state
        .set('isLoadingArchetypeList', false)
        .set('archetypeList', archetypeList);
    }

    case actionTypes.BARISTA_FAQ_LOAD_TRIGGER_START: {
      return state.set('isLoadingTriggerList', true);
    }

    case actionTypes.BARISTA_FAQ_LOAD_TRIGGER_FAIL: {
      return state.set('isLoadingTriggerList', false);
    }

    case actionTypes.BARISTA_FAQ_LOAD_TRIGGER_SUCCESS: {
      return state
        .set('triggerList', fromJS(action.results))
        .set('isLoadingTriggerList', false);
    }

    case actionTypes.BARISTA_FAQ_ADD_FILE: {
      return state.set('imageNewFaq', action.files);
    }

    case actionTypes.BARISTA_FAQ_UPDATE_START: {
      const newListFAQ = state.get('faqsList').map((faq) => {
        if (faq.get('eid') === action.eid) {
          return faq.set('isLoading', true);
        }
        return faq;
      });

      return state.set('faqsList', newListFAQ);
    }

    case actionTypes.BARISTA_FAQ_UPDATE_STOP:
    case actionTypes.BARISTA_FAQ_UPDATE_FAIL: {
      const newListFAQ = state.get('faqsList').map((faq) => {
        if (faq.get('eid') === action.eid) {
          return faq.set('isLoading', false);
        }
        return faq;
      });

      return state.set('faqsList', newListFAQ);
    }

    case actionTypes.BARISTA_FAQ_EDIT_FAQ_ID: {
      return state.set('selectedFAQtoEdit', action.faqID);
    }

    case actionTypes.BARISTA_FAQS_GENERAL_START: {
      return state.set('isLoading', true).set('isEmptyResults', false);
    }

    case actionTypes.BARISTA_FAQS_GENERAL_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('faqsList', fromJS(action.results))
        .set('isSearching', false)
        .set('count', action.count)
        .set('isEmptyResults', action.count === 0);
    }

    case actionTypes.BARISTA_FAQS_GENERAL_FAIL: {
      return state
        .set('isSearching', false)
        .set('isLoading', false)
        .set('isEmptyResults', false);
    }

    case actionTypes.LOAD_FAQ_LIST: {
      return state.set('isLoading', true).set('isEmptyResults', false);
    }

    case actionTypes.LOAD_FAQ_LIST_SUCCESS: {
      return state
        .set('isLoading', false)
        .set('faqsList', fromJS(action.results))
        .set('isSearching', false)
        .set('count', action.count)
        .set('isEmptyResults', action.count === 0);
    }

    case actionTypes.LOAD_FAQ_LIST_FAILURE: {
      return state
        .set('isSearching', false)
        .set('isLoading', false)
        .set('isEmptyResults', false);
    }

    case actionTypes.UPDATE_FAQ_SUCCESS:
    case actionTypes.BARISTA_FAQS_UPDATE_SUCCESS: {
      const newFaq = action.faq;
      const oldFaqsList = state.get('faqsList');

      let isFaqToUpdateExist = false;
      let newList = fromJS(
        oldFaqsList.reduce((faqs, currentFaq) => {
          if (currentFaq.get('eid') !== newFaq.eid) {
            faqs.push(currentFaq);
          }
          if (
            currentFaq.get('eid') === newFaq.eid &&
            (action.displayFilter === 'all' || !action.displayFilter)
          ) {
            isFaqToUpdateExist = true;
            faqs.push(fromJS(newFaq));
          }
          return faqs;
        }, [])
      );

      if (!isFaqToUpdateExist) {
        // Add this FAQ
        newList = newList.push(fromJS(newFaq));
      }
      return state
        .set('isLoading', false)
        .set('faqsList', newList)
        .set('isLoadingFAQ', Boolean(action.keepLoadingFAQ));
    }

    case actionTypes.BARISTA_FAQS_ADD_RESULT_SUCCESS: {
      const newList = fromJS(action.faq);

      return state.set('isLoading', false).set('faqsListResult', newList);
    }

    case actionTypes.BARISTA_FAQS_ADD_SUCCESS: {
      const newFaq = action.faq;
      const oldFaqsList = state.get('faqsList');

      const newList = oldFaqsList.insert(0, fromJS(newFaq));

      return state
        .set('isLoading', false)
        .set('faqsList', newList)
        .set('count', action.count + 1);
    }

    case actionTypes.BARISTA_FAQS_LOAD_IMG_START: {
      return state.set('isLoadingImage', true);
    }

    case actionTypes.BARISTA_FAQS_LOAD_IMG_FAIL: {
      return state.set('isLoadingImage', false);
    }

    case actionTypes.BARISTA_FAQS_LOAD_IMG_SUCCESS: {
      const { eid, responseEid, url, selectedFaqEid } = action;

      let indexAnswer, indexFAQ;

      let newLines = state
        .get('faqsList')
        .find((faq, i) => {
          if (faq.get('eid') === selectedFaqEid) {
            indexFAQ = i;
            return true;
          }
          return false;
        })
        .get('answers')
        .find((answer, i) => {
          if (answer.get('eid') === responseEid) {
            indexAnswer = i;
            return true;
          }
          return false;
        })
        .get('lines');

      newLines = newLines.push(
        fromJS({
          eid,
          phrase: '',
          url,
        })
      );

      return state
        .setIn(
          ['faqsList', indexFAQ, 'answers', indexAnswer, 'lines'],
          newLines
        )
        .set('isLoadingImage', false);
    }

    case actionTypes.BARISTA_FAQ_WF_REQUEST_START: {
      return state.set('isLoadingFAQWorkflow', true);
    }

    case actionTypes.BARISTA_FAQ_WF_REQUEST_SUCCEED: {
      return state.set('isLoadingFAQWorkflow', false);
    }

    case actionTypes.BARISTA_FAQ_WF_REQUEST_FAIL: {
      return state.set('isLoadingFAQWorkflow', false);
    }

    case actionTypes.BARISTA_FAQ_SEARCH_TERM: {
      return state.set('searchTerm', action.searchTerm);
    }

    case actionTypes.BARISTA_FAQ_IS_SEARCHING_TERM: {
      return state.set('isSearching', true);
    }

    case actionTypes.UPDATE_FAQ_START: {
      return state.set('isLoadingFAQ', true);
    }

    case actionTypes.UPDATE_FAQ_FAIL: {
      return state.set('isLoadingFAQ', false);
    }

    case actionTypes.FAQ_ADMIN_ACTIVE_EDIT_FAQ: {
      return state.set('activeEditFaqEid', action.eid);
    }

    case actionTypes.SET_FAQ_SERVICE_DEPARTMENT_SUCCESS: {
      return state.setIn(
        ['serviceDepartmentMap', action.eid],
        fromJS(action.department)
      );
    }

    case actionTypes.SET_FAQ_SERVICE_TEAM_SUCCESS: {
      return state.setIn(['serviceTeamMap', action.eid], fromJS(action.team));
    }

    case actionTypes.SET_FAQ_TASK_CATEGORY_SUCCESS: {
      return state.setIn(
        ['taskCategorytMap', action.eid],
        fromJS(action.category)
      );
    }

    case actionTypes.UPDATE_FAQ_ANSWER_SUCCESS: {
      let newState;
      const index = state
        .get('faqsList')
        .findIndex((faq) => faq.get('eid') === action.faqEid);

      if (action.answerEid) {
        // Update a property of the answer
        const answerIndex = state
          .getIn(['faqsList', index, 'answers'])
          .findIndex((answer) => answer.get('eid') === action.answerEid);
        newState = state.setIn(
          ['faqsList', index, 'answers', answerIndex],
          fromJS(action.data)
        );
      } else {
        // Add an answer
        const answers = state.getIn(['faqsList', index, 'answers']);
        newState = state.setIn(
          ['faqsList', index, 'answers'],
          answers.update((answer) => answer.push(fromJS(action.data)))
        );
      }

      return newState.set('isLoadingFAQGroup', false);
    }

    case actionTypes.UPDATE_FAQ_GROUP_FAIL: {
      return state.set('faqGroupEid', null).set('isLoadingFAQGroup', false);
    }

    case actionTypes.UPDATE_FAQ_GROUP_START: {
      if (action.eid) {
        return state
          .set('faqGroupEid', action.eid)
          .set('isLoadingFAQGroup', true);
      }
      return state.set('isLoadingFAQGroup', true);
    }
    case actionTypes.UPDATE_FAQ_GROUP_SUCCESS: {
      let newState;
      const index = state
        .get('faqsList')
        .findIndex((faq) => faq.get('eid') === action.faqEid);

      if (size(action.data) === 1) {
        // Update a property of the group

        const [key] = Object.keys(action.data);
        newState = state.setIn(
          ['faqsList', index, key],
          fromJS(action.data[key])
        );
      } else {
        // Update the group
        newState = state.setIn(['faqsList', index], fromJS(action.data));
      }

      return newState.set('faqGroupEid', null).set('isLoadingFAQGroup', false);
    }

    case actionTypes.SET_FAQ_CONDITION_LOCDEPJOB_NAMES: {
      const { answerEid } = action;
      const { updatdeNew } = action;

      if (updatdeNew) {
        // if updating a *new* group, delete the existing temporal (empty string key)
        // and replace it with the new answerEid
        const tmpNames = state.getIn(['conditionsNamesMap', '']);
        return state
          .deleteIn(['conditionsNamesMap', ''])
          .setIn(['conditionsNamesMap', answerEid], tmpNames);
      }
      return state.setIn(
        ['conditionsNamesMap', answerEid],
        fromJS(action.loadedNames)
      );
    }

    case actionTypes.SET_FAQ_EDIT_CONDITION: {
      const answer = action.answerEid;
      return state.setIn(
        ['editedConditionsByAnswer', answer],
        fromJS(action.condition)
      );
    }

    case actionTypes.SET_FAQ_RESET_EDIT_CONDITION: {
      const answer = action.answerEid;
      return state.setIn(['editedConditionsByAnswer', answer], void 0);
    }

    case actionTypes.BARISTA_LOAD_KB_SUPPORT_ERROR:
    case actionTypes.BARISTA_UPDATE_KNOWLEDGE_ARTICLE_ERROR:
    case actionTypes.BARISTA_LOAD_KNOWLEDGE_ARTICLE_ERROR:
      return state.setIn(['kbSupport', 'isLoading'], false);

    case actionTypes.BARISTA_LOAD_KB_SUPPORT_START:
      return state
        .setIn(['kbSupport', 'isLoading'], true)
        .setIn(['kbSupport', 'results'], null);

    case actionTypes.BARISTA_LOAD_KB_SUPPORT_SUCCESS:
      return state
        .setIn(['kbSupport', 'count'], action.count)
        .setIn(['kbSupport', 'isLoading'], false)
        .setIn(['kbSupport', 'results'], fromJS(action.results));

    case actionTypes.BARISTA_UPDATE_KNOWLEDGE_ARTICLE_START:
    case actionTypes.BARISTA_LOAD_KNOWLEDGE_ARTICLE_START:
      return state.setIn(['kbSupport', 'isLoading'], true);

    case actionTypes.BARISTA_LOAD_KNOWLEDGE_ARTICLE_SUCCESS:
      return state
        .setIn(['kbSupport', 'isLoading'], false)
        .setIn(['kbSupport', 'results'], fromJS([action.data]));

    case actionTypes.BARISTA_UPDATE_KNOWLEDGE_ARTICLE_SUCCESS: {
      const index =
        action?.data?.article_id &&
        state
          .getIn(['kbSupport', 'results'])
          .findIndex(
            (knowledgeArticle) =>
              knowledgeArticle.get('article_id') === action?.data?.article_id
          );

      return isNumber(index) && index !== -1
        ? state
            .setIn(['kbSupport', 'isLoading'], false)
            .setIn(['kbSupport', 'results', index], fromJS(action.data))
        : state.setIn(['kbSupport', 'isLoading'], false);
    }

    default: {
      return state;
    }
  }
};

export default adminFaqsReducer;
