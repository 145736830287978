import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

const frontEndBlobAttributes = _.merge({}, BlockBase, {
  template: {
    default: 'resetPasswordCode01',
  },
});

const ResetPasswordCodeDefinition = {
  blobAttributes: frontEndBlobAttributes,
  label: 'Reset Password Code Verification',
  templates: ['resetPasswordCode01'],
};

const propTypes = processPropTypes(frontEndBlobAttributes);
const TypeDefaults = processPropDefaults(frontEndBlobAttributes);

export { propTypes as default, ResetPasswordCodeDefinition, TypeDefaults };
