//
// The keys belonging to session.
//
// the shape of external session
//
// the following object describes the structure of the session created by apps
// by means other than esp-auth.
//
// eslint-disable-next-line no-unused-vars -- we need this to document session shape
const externalSessionShape = {
  data: {
    // string - the session access token
    accessToken: null,
    // string - id of the user
    currentUser: null,
    // object - values related to the impersonation feature
    impersonation: {
      // string - the id of the impersonator
      impersonatingUser: null,
      // string - the eid of the impersonator
      impersonatingUserEid: null,
      // string - the user id of the real user
      originalUser: null,
      // string - the id of the user's original delegate
      original_delegate_of: null,
    },
    // DEPRECATED: boolean - specifies if the accessToken is encrypted
    isEncrypted: null,
    // string - the URL the user was trying to navigate to before being redirected to login page
    previousUrl: null,
    // number - the index of the session, it's a value given by the BE
    sessionIndex: null,
    // number - the token creation data as a numerical timestamp
    tokenCreation: null,
  },
};

export const AUTH_STATUS = {
  AUTHENTICATED: 'AUTHENTICATED',
  AUTH_ERROR: 'AUTH_ERROR',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

//
// These are just hex values for the words basic, oauth and saml.
// What we don't want is to use meaningful values that reveal
// implementation details.
export const AUTH_TYPE = {
  INTERNAL: '4241534943',
  OAUTH: '4f41555448',
  SAML: '0053414d4c',
};

// TOKEN_KEY is the property name for the token in the session namespace.
export const SESSION_KEYS = {
  AUTH_TYPE: 'AUTH',
  INDEX: 'INDEX',
  TOKEN: 'TOKEN',
};

export const EXTERNAL_SESSION_KEYS = [
  'accessToken',
  'currentUser',
  'impersonation',
  'isEncrypted',
  'previousUrl',
  'sessionIndex',
  'tokenCreation',
];

export const rgxAuthSAML = /\bauth\/saml\b/;
export const rgxSimpleAuth = /\bauth\/oauth\b/;
export const rgxRedirectURI = /\bredirect_uri\b/;
