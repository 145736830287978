const api_case_mgmt_v0_1_service_team_my_teams = {
  count: 2,
  next: null,
  previous: null,
  results: [
    {
      eid: 'a60ad6f9-1a9a-4c99-9e28-6d6f3f11bcc7',
      groups: [],
      id: 19,
      images: [],
      name: 'Default service team',
      service_department: {
        display_name: 'Default service department',
        id: 5,
        service_department: 'OTHER',
      },
      team_members: [
        {
          auth_group: 'SERVICE_LEAD',
          id: 80,
          user: 44,
        },
        {
          auth_group: 'SERVICE_LEAD',
          id: 34,
          user: 85,
        },
        {
          auth_group: 'SERVICE_AGENT',
          id: 35,
          user: 1283,
        },
        {
          auth_group: 'SERVICE_AGENT',
          id: 38,
          user: 45,
        },
      ],
    },
    {
      eid: 'a30cda4e-3f8d-42ac-8e8b-5df361074856',
      groups: [],
      id: 20,
      images: [],
      name: 'QA',
      service_department: {
        display_name: 'IT test',
        id: 4,
        service_department: 'IT',
      },
      team_lead: 1989,
      team_members: [
        {
          auth_group: 'SERVICE_AGENT',
          id: 69,
          user: 30,
        },
        {
          auth_group: 'SERVICE_AGENT',
          id: 70,
          user: 5262,
        },
      ],
    },
  ],
};

export default api_case_mgmt_v0_1_service_team_my_teams;
