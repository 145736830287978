import React, { Component } from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { Button, Message } from 'semantic-ui-react';
import { get, isEmpty, isFunction, isNumber, isObject, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Boundaries } from 'cascara-middleware';
import { Layout } from '../globals/index';

const TOOLTIP_OFFSET = 20;

const DEFAULT_STATE = Object.freeze({
  cursorPosition: {
    x: null,
    y: null,
  },
  isDrillDownActive: false,
  label: null,
  payload: null,
});

class EspChart extends Component {
  static propTypes = {
    CustomTooltip: PropTypes.func,
    areas: PropTypes.arrayOf(PropTypes.object),
    bars: PropTypes.arrayOf(PropTypes.object),
    cartesianGrid: PropTypes.objectOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object),
    height: PropTypes.number,
    interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isDrillDown: PropTypes.bool,
    isLightbox: PropTypes.bool,
    layout: PropTypes.string,
    legend: PropTypes.bool,
    lines: PropTypes.arrayOf(PropTypes.object),
    onBarClick: PropTypes.func,
    onDrillDownClick: PropTypes.func,
    tooltip: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        order: PropTypes.arrayOf(PropTypes.string),
        payload: PropTypes.arrayOf(
          PropTypes.shape({
            fill: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.func.isRequired,
          })
        ),
      }),
    ]),
    width: PropTypes.string,
    xAxis: PropTypes.arrayOf(PropTypes.object),
    yAxis: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    CustomTooltip: null,
    areas: [],
    bars: [],
    cartesianGrid: {},
    data: [],
    height: 1,
    interval: '',
    isDrillDown: false,
    isLightbox: false,
    layout: Layout.HORIZONTAL,
    legend: false,
    lines: [],
    onBarClick: noop,
    onDrillDownClick: null,
    tooltip: false,
    width: '',
    xAxis: [],
    yAxis: [],
  };

  state = DEFAULT_STATE;

  handleClick = (data) => {
    this.setState({
      cursorPosition: {
        x: get(data, 'chartX', null),
        y: get(data, 'chartY', null),
      },
      isDrillDownActive: true,
      payload: data && !isEmpty(data.activePayload) ? data.activePayload : {},
    });
  };

  handleExportReportClick = (dates) => (e) => {
    e.stopPropagation();
    const { onDrillDownClick } = this.props;
    onDrillDownClick(dates);
    this.setState(DEFAULT_STATE);
  };

  handleMouseLeave = () => this.setState(DEFAULT_STATE);

  tooltipContent = (tooltip) => (props) => {
    const { CustomTooltip, isDrillDown, onDrillDownClick } = this.props;
    const { isDrillDownActive, payload } = this.state;
    const newProps = { ...props };
    let dates = null;
    let newPayload = isDrillDownActive ? [].concat(payload) : newProps.payload;

    if (!isEmpty(newPayload)) {
      // Format date
      const dateEntryIndex = newPayload.findIndex(
        (entry) => entry && entry.name === 'Date'
      );
      if (isNumber(dateEntryIndex) && dateEntryIndex !== -1) {
        dates = get(newPayload, [dateEntryIndex, 'payload', 'dates'], null);
        newProps.label = get(newPayload, [dateEntryIndex, 'value']);
        newPayload.splice(dateEntryIndex, 1);
      }

      if (isObject(tooltip)) {
        const { payload: customPayload, order } = tooltip;

        // Adds custom tooltip data
        if (!isEmpty(customPayload) && !isEmpty(newPayload)) {
          newPayload = newPayload.concat(
            customPayload.map((entry) => ({
              ...entry,
              value: entry.value(get(newPayload, [0, 'payload']), {}),
            }))
          );
        }

        // Sets  order
        if (!isEmpty(order)) {
          newPayload = order
            .reduce((acc, name) => {
              const entry = newPayload.find(
                (entry) =>
                  entry && (entry.dataKey === name || entry.name === name)
              );
              return isEmpty(entry) ? acc : acc.concat(entry);
            }, [])
            .reverse();
        }
      }
    }
    const TooltipComponent = () => {
      if (CustomTooltip) {
        return (
          <CustomTooltip
            contentStyle={{ border: 'none' }}
            payload={newPayload}
          />
        );
      }

      return (
        <DefaultTooltipContent
          {...newProps}
          contentStyle={{ border: 'none' }}
          payload={newPayload}
        />
      );
    };

    return isDrillDown ? (
      <div
        onMouseLeave={this.handleMouseLeave}
        role='button'
        style={{ backgroundColor: '#fff' }}
        tabIndex={0}
      >
        {isFunction(onDrillDownClick) && (
          <div style={{ textAlign: 'center' }}>
            <Button
              as='a'
              basic
              content='Export Report CSV'
              onClick={this.handleExportReportClick(dates)}
            />
          </div>
        )}
        <TooltipComponent />
      </div>
    ) : (
      <TooltipComponent />
    );
  };

  tooltipMessage = (props) => {
    const { label, payload } = props;

    const date = !isEmpty(payload)
      ? payload.find((entry) => entry.name === 'Date')
      : void 0;

    if (isObject(date) || label) {
      return (
        <Message size={'tiny'} style={{ padding: '0.5em' }}>
          <p>{`Click to see drill down for ${get(date, 'value', label)}`}</p>
        </Message>
      );
    }

    return null;
  };

  render() {
    const {
      areas,
      bars,
      cartesianGrid,
      data,
      height,
      interval,
      isDrillDown,
      isLightbox,
      layout,
      legend,
      lines,
      onBarClick,
      tooltip,
      width,
      xAxis,
      yAxis,
    } = this.props;

    const { cursorPosition, isDrillDownActive } = this.state;

    const tooltipProps = isDrillDown
      ? {
          content: isDrillDownActive
            ? this.tooltipContent(tooltip)
            : this.tooltipMessage,
          cursor: false,
          ...(isDrillDownActive && {
            position: {
              x: cursorPosition.x - TOOLTIP_OFFSET,
              y: cursorPosition.y - TOOLTIP_OFFSET,
            },
            wrapperStyle: {
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              padding: '0.2em',
              pointerEvents: 'auto',
              zIndex: '1',
            },
          }),
        }
      : {
          content: this.tooltipContent(tooltip),
        };

    return (
      <Boundaries>
        <div
          style={{
            backgroundColor: isLightbox ? '#fff' : '',
            height: '100%',
          }}
        >
          <ResponsiveContainer
            height={isLightbox ? '100%' : height}
            width={isLightbox ? '100%' : width}
          >
            <ComposedChart
              data={data}
              layout={layout}
              margin={{
                bottom: 20,
                left: 0,
                right: 0,
                top: 20,
              }}
              onClick={this.handleClick}
              onMouseLeave={this.handleMouseLeave}
            >
              <CartesianGrid {...cartesianGrid} style={{ cursor: 'pointer' }} />
              {tooltip && <Tooltip {...tooltipProps} />}
              {legend && <Legend />}
              {xAxis &&
                xAxis.map((axis, index) => (
                  <XAxis
                    interval={interval}
                    key={axis.dataKey || index}
                    {...axis}
                  />
                ))}
              {yAxis &&
                yAxis.map((axis) => (
                  <YAxis key={axis.yAxisId || axis.dataKey} {...axis} />
                ))}

              {areas &&
                areas.map((area) => <Area key={area.dataKey} {...area} />)}

              {bars &&
                bars.map((bar) => (
                  <Bar
                    {...bar}
                    className={bar.dataKey}
                    key={bar.dataKey}
                    onClick={onBarClick}
                  />
                ))}

              {lines &&
                lines.map((line) => <Line key={line.dataKey} {...line} />)}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Boundaries>
    );
  }
}

export default EspChart;
