const api_espuser_v0_1_users_218862 = {
  about_me: null,
  alias: 'ruben@example.com',
  computer_type: '',
  contacts: {
    ENABLEMENT: null,
    HR_CONTACT: null,
    HR_RECRUITER: null,
    IT_CONTACT: null,
    MANAGER: 218875,
    MENTOR: null,
    OPERATIONS: null,
  },
  cost_center: '',
  custom_map: null,
  date_of_birth: null,
  delegate_of: [218875],
  delegates: [],
  eid: '4a410674-2493-485b-a1cc-a8e89ad16014',
  email: 'ruben@example.com',
  employee_number: 'fri2jul19',
  esp_dict: {
    existing_user: true,
    last_supportemail_send_ts: 1571944836.704567,
    manager_found: true,
  },
  favorites: [218875, 2, 8],
  first_name: 'ruben',
  full_name: 'ruben ortega',
  gender: 'MALE',
  groups: [
    'ADMIN',
    'CATALOG_MANAGER',
    'SITE_MANAGER',
    'ANNOUNCER',
    '__rule_grp__65a7b5c1-f695-4ca3-adcf-95d612768160',
    '__rule_grp__27240395-aec3-43d1-ae4b-4b19fe7c7fa6',
    'CASE_TECHNICAL_CONTACT_LEGAL',
    'HR_CONTACT',
    'HR_RECRUITER',
    'ENABLEMENT',
    'IT_CONTACT',
    'OPERATIONS',
    'ONBOARD_SCHEDULER',
    'ACTIVITY_CREATOR',
    '__rule_grp__b97c8595-cd99-4c7f-83cb-5b14dc579dc6',
    '__rule_grp__d1e59b88-0e90-4cf7-a660-4d8a26a5077a',
    'MANAGER',
    '__rule_grp__2ab83e5a-1c58-464d-b55b-78cd542b9913',
    'SERVICE_LEAD',
    'SERVICE_AGENT',
    'KIOSK',
    '__rule_grp__eff5070b-5fd1-4ca2-a9cc-8aa81cebd506',
    '__rule_grp__403fa2ca-f918-4c39-a33d-01c1f5266bf5',
    '__rule_grp__fb9afb4f-907a-427a-ba82-4b4e730a50bc',
    '__rule_grp__9505ccc6-f8b4-4870-8673-3534a0f3feff',
    'FAQ_ADMIN',
    '__rule_grp__a71017bc-5f2b-4f50-8863-569db445fbe0',
    '__rule_grp__a7e671e9-02d8-4618-be09-0a797334c803',
    '__rule_grp__9ce74dc0-19c1-492d-9aac-cda59675a9ee',
    'BRC_MANAGER',
    '__rule_grp__d41a4e79-2766-4861-8b9d-f173320fa321',
    '__rule_grp__94dac771-d6ef-4897-8570-caa230f912dd',
    '__rule_grp__6bec8e9f-86ac-4e9f-a049-f4b6b529a4cc',
    '__rule_grp__69681143-859f-42c9-b2b2-02d02d40a70d',
    '__svc_team_auth_grp_61a570ff-a00a-47b1-bfb1-6234d16a8b9e',
    '__svc_team_auth_grp_ceeca89c-8806-4cde-a5d5-5496dedbf0f0',
    '__svc_team_auth_grp_513e1225-39a8-44f8-aaaa-f2f806aff646',
  ],
  home_address: {
    postal_code: 'ruben',
  },
  id: 218862,
  images: [
    {
      caption: '',
      eid: '0ec8558d-1444-45e3-9e67-de3882421390',
      height: 338,
      id: 400,
      ordering: 0,
      picture:
        'https://q.evisserp.se/media/release/profile_images/af829a3d-f741-4115-abb7-0bc1ca06554b.jpg?Expires=1603813816&Signature=MEYCIQCn~p7TQGCMkrtT7BkyOLTPViAKXPKZC9~nXAzcK53~gAIhALb9whSUw8b2zJFq2PaLpHUHXHWXP6loQyCJ6K~mwzDF&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      sys_created_by: 'erick@example.com',
      sys_custom_fields: null,
      sys_date_created: '2020-10-22T20:30:25.534677Z',
      sys_date_updated: '2020-10-22T20:30:25.766734Z',
      sys_updated_by: 'erick@example.com',
      thumbnail:
        'https://q.evisserp.se/media/release/profile_images/51c720ae-a837-4b16-b1ec-cd65b842a02c.jpg?Expires=1603813816&Signature=MEUCIQCYlQRO4Djb4~gEjIxnzNIffk9sHU~enYCBnHjRVYqzHwIgSI-f77zNATX~DkMuWYK27u3Qm3CRkLiMnM9XkPfic3Y_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      type: 'DEF_BACKGROUND',
      url:
        'https://release.qa.espressive.com/api/images/v0.1/profile_image/400/',
      user: null,
      width: 600,
    },
  ],
  is_active: true,
  is_admin: true,
  is_manager: true,
  job_role: null,
  last_name: 'ortega',
  last_sms_timestamp: null,
  location: 'https://release.qa.espressive.com/api/places/v0.1/locations/34/',
  manager: 'https://release.qa.espressive.com/api/espuser/v0.1/users/218875/',
  manager_username: 'rortcisneros@gmail.com',
  mentor: null,
  middle_name: '',
  mobile_type: '',
  nickname: 'ruben',
  office_address: 'country: United States, zone: Every Location',
  office_location: null,
  phone_numbers: {
    home: {
      country_code: '+1',
      country_iso: null,
      number: '123456789',
      public: true,
    },
    mobile: {
      country_code: '+1',
      country_iso: null,
      number: '5555555555',
      public: true,
    },
    work: {
      country_code: '+1',
      country_iso: null,
      number: '123456789',
      public: true,
    },
  },
  preferred_language_id: 'en',
  remote_worker: false,
  scim_dict: null,
  secondary_email: 'ruben@example.com',
  shadow_block: {
    employee_number: '',
    gender: '',
    location: 34455,
    manager: 8,
    phone_numbers: {
      mobile: {
        country_code: '+1',
        country_iso: 'US',
        number: '5555555555',
        public: true,
      },
    },
    social_coordinates: null,
  },
  sms_subscription: true,
  social_coordinates: [
    {
      network: 'linkedin',
      username: '',
    },
    {
      network: 'twitter',
      username: '',
    },
  ],
  social_profile_picture: '',
  start_date: '2019-07-25T05:00:00Z',
  sys_custom_fields: null,
  termination_date: null,
  terms_accepted: true,
  title: 'admin',
  url: 'https://release.qa.espressive.com/api/espuser/v0.1/users/218862/',
  user_state: 'MANAGER_CONFIRMED',
  user_type: 'EMPLOYEE',
  username: 'ruben@example.com',
  work_collaboration: null,
};

export default api_espuser_v0_1_users_218862;
