import actionTypes from '../actionTypes';

const baristaSearchConditionsActions = {
  createConditionError: () => ({
    type: actionTypes.CREATE_BARISTA_SEARCH_CONDITION_ERROR,
  }),
  createConditionStart: () => ({
    type: actionTypes.CREATE_BARISTA_SEARCH_CONDITION_START,
  }),
  createConditionSuccess: (condition, prepend) => ({
    condition,
    prepend,
    type: actionTypes.CREATE_BARISTA_SEARCH_CONDITION_SUCCESS,
  }),
  loadConditionsError: () => ({
    type: actionTypes.LOAD_BARISTA_SEARCH_CONDITIONS_ERROR,
  }),
  loadConditionsStart: () => ({
    type: actionTypes.LOAD_BARISTA_SEARCH_CONDITIONS_START,
  }),
  loadConditionsSuccess: (results, serviceDepartmentEID) => ({
    results,
    serviceDepartmentEID,
    type: actionTypes.LOAD_BARISTA_SEARCH_CONDITIONS_SUCCESS,
  }),
  swapConditionsSuccess: (eid1, eid2) => ({
    eid1,
    eid2,
    type: actionTypes.SWAP_BARISTA_SEARCH_CONDITIONS_SUCCESS,
  }),
  toggleConditionSuccess: (condition) => ({
    condition,
    type: actionTypes.TOGGLE_BARISTA_SEARCH_CONDITION_SUCCESS,
  }),
  updateConditionError: () => ({
    type: actionTypes.UPDATE_BARISTA_SEARCH_CONDITION_ERROR,
  }),
  updateConditionStart: () => ({
    type: actionTypes.UPDATE_BARISTA_SEARCH_CONDITION_START,
  }),
  updateConditionSuccess: (condition) => ({
    condition,
    type: actionTypes.UPDATE_BARISTA_SEARCH_CONDITION_SUCCESS,
  }),
};
export default baristaSearchConditionsActions;
