import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

// Globals
import TypeCreateNewUser, {
  TypeDefaults,
} from '../../../globals/blocks/TypeCreateNewUser';
// Controller
import CreateNewUserController from '../../controllers/CreateNewUserController';

/**
 * This block is an empty component which allow to set an alternate submit and
 * create a enw user from the Backend scratch data
 */

class CreateNewUser01 extends PureComponent {
  static propTypes = {
    ...TypeCreateNewUser,
    createNewUserFromBEScratch: PropTypes.func.isRequired,
    forceResetError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...TypeDefaults,
  };

  componentDidMount() {
    const { setAlternateSubmit, setAlternateBack } = this.props;

    setAlternateSubmit(this.handleClick);
    setAlternateBack(this.handleClickBack);
  }

  handleClick = (cb = _.noop) => {
    const { createNewUserFromBEScratch } = this.props;

    createNewUserFromBEScratch(cb);
  };

  handleClickBack = (cb = _.noop) => {
    const { forceResetError } = this.props;

    forceResetError(cb);
  };

  render() {
    const { errorsApi, template, type } = this.props;

    return (
      <div className='block' data-blocktype={type} data-template={template}>
        {errorsApi &&
          !errorsApi.isEmpty() &&
          errorsApi.getIn([0, 'parameterName']) === 'email' && (
            <Message
              content={errorsApi.getIn([0, 'message'])}
              header='Error'
              negative
            />
          )}
      </div>
    );
  }
}

export const CreateNewUser01Test = CreateNewUser01;

export default CreateNewUserController(CreateNewUser01);
