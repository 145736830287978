import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Form, Select } from 'semantic-ui-react';
import { debounce, get, isEmpty, noop } from 'lodash';

import { intl } from 'esp-util-intl';
import controller from '../../controllers/BaristaScopedIntentLookupController';

const SEARCH_TIMEOUT = 300;

class BaristaIntentLookup extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    lookupResults: ImmutablePropTypes.list.isRequired,
    onHandleChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    scope: PropTypes.string,
    searchIntentByEID: PropTypes.func,
    searchIntents: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    isLoading: false,
    label: '',
    onHandleChange: noop,
    placeholder: '',
    readOnly: false,
    required: false,
    scope: '1',
    searchIntentByEID: noop,
    searchIntents: noop,
    value: null,
  };

  static state = {
    searchQuery: '',
  };

  componentDidMount() {
    const { scope, searchIntents, value } = this.props;

    if (value) {
      searchIntents({
        intentEid: value,
        scope,
      });
    }
  }

  handleOnSearchChange = debounce((__, { searchQuery }) => {
    const { scope, searchIntents } = this.props;

    if (searchQuery) {
      this.setState({ searchQuery }, () => {
        searchIntents({
          scope,
          search: searchQuery,
        });
      });
    }
  }, SEARCH_TIMEOUT);

  render() {
    const searchQuery = get(this.state, 'searchQuery');

    const {
      onHandleChange,
      isLoading,
      label,
      lookupResults,
      placeholder,
      value,
    } = this.props;

    const options = lookupResults
      .map((intent) => ({
        key: intent.get('eid'),
        text: intent.get('name'),
        value: intent.get('eid'),
      }))
      .toArray();

    const emptyQuery = searchQuery && isEmpty(options);

    const noResultsMessage = isLoading
      ? intl.formatMessage({ id: 'label.searching_intents' })
      : emptyQuery
      ? intl.formatMessage({ id: 'label.no_intents_found' })
      : placeholder;

    return (
      <Form.Field>
        <label>{label}</label>
        <Select
          fluid
          label={label}
          loading={isLoading}
          noResultsMessage={noResultsMessage}
          onChange={onHandleChange}
          onSearchChange={this.handleOnSearchChange}
          options={options}
          placeholder={placeholder}
          search
          {...get(this.props, ['disabled', 'readOnly', 'required', 'value'])}
          value={value}
        />
      </Form.Field>
    );
  }
}

export const BaristaIntentLookupTest = BaristaIntentLookup;

export default controller(BaristaIntentLookup);
