import buildCompositeController from '../../utils/buildCompositeController';
// Thunk
import faqThunks from '../../actions/faqThunks';
// Action
import toastNotificationsActions from '../../actions/toastNotificationsActions';
import errorsActions from '../../actions/errorsActions';

const mapDispatchToProps = (dispatch) => ({
  forceResetError(cb) {
    dispatch(errorsActions.forceResetErrors());
    cb(); // Set prev task
  },

  updateEntityValueInstance(cb) {
    dispatch(faqThunks.updateEntityValueInstance()).then(() => {
      dispatch(
        toastNotificationsActions.success({
          message: 'The Entity has been updated',
          title: 'Success',
        })
      );
      cb(); // Set Next task
    });
  },
});

export default buildCompositeController(null, mapDispatchToProps);
