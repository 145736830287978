const LABELS = {
  ADDED: 'Added',
  ARCHIVED: 'Archived',
  DELETED: 'Deleted',
  EMPTY_SYNC: 'Content has never been synced.',
  FOUND: 'Found',
  LAST_SYNC: 'Last Sync',
  SAVE: 'Save',
  SCHEDULE_CHECKBOX: 'Enable Schedule Sync',
  SCHEDULE_SYNC: 'Schedule Sync',
  STATUS: 'Status',
  SYNC_NOW: 'Sync Now',
  SYNC_RESULTS: 'Sync Results',
  SYNC_SETTINGS: 'Sync Settings',
  TIME_DAY: 'Time of day',
  UPDATED: 'Updated',
};

export default LABELS;
