import React, { PureComponent } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImmutableProps from 'react-immutable-proptypes';
import Immutable from 'immutable';

class EntriesInput extends PureComponent {
  static propTypes = {
    /** fields length */
    fieldsLength: PropTypes.number,
    /** current idnex in the field array */
    index: PropTypes.number,
    /** Coming from Redux Form  */
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, ImmutableProps.map]),
    }),

    loading: PropTypes.bool,

    onRemove: PropTypes.func,

    placeholder: PropTypes.string,

    processFullTriggers: PropTypes.bool,

    valueChange: PropTypes.func,
  };

  static defaultProps = {
    fieldsLength: 0,
    index: 0,
    input: '',
    loading: false,
    onRemove: noop,
    placeholder: '',
    processFullTriggers: false,
    valueChange: noop,
  };

  handleOnChange = (value) => {
    const { input, valueChange, processFullTriggers } = this.props;
    if (!processFullTriggers) {
      input.onChange(value);
    }
    valueChange(value, processFullTriggers);
  };

  handleBlur = (e) => {
    const { processFullTriggers } = this.props;
    if (processFullTriggers) {
      e.preventDefault(); // Avoid to pass full string
    }
  };

  render() {
    const {
      fieldsLength,
      index,
      input,
      onRemove,
      placeholder,
      loading,
      processFullTriggers,
    } = this.props;

    const canDelete = index !== 0 || (fieldsLength > 1 && input.value);

    const styleField = canDelete
      ? {
          float: 'left',
          marginRight: '1rem',
          maxWidth: 'calc(100% - 39px - 1rem)',
        }
      : {};
    const value =
      processFullTriggers &&
      Immutable.Map.isMap(input.value) &&
      typeof input.value.get('phrase') === 'string'
        ? input.value.get('phrase')
        : input.value;

    return (
      <Form.Field inline width={16}>
        <Form.Field style={styleField} width={16}>
          <Input
            {...input}
            loading={loading}
            onBlur={this.handleBlur}
            onChange={this.handleOnChange}
            placeholder={placeholder}
            type='text'
            value={value}
          />
        </Form.Field>
        {canDelete ? (
          <Button
            icon='close'
            onClick={onRemove}
            style={{
              marginRight: 0,
            }}
          />
        ) : null}
      </Form.Field>
    );
  }
}

export default EntriesInput;
