import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import actionTypes from './actionTypes';

const DEFAULT_DISMISS_AFTER = 3 * 1000;

// const TOAST_ACTION = 'Dismiss';

const ToastNotificationTypes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARN: 'WARN',
};

const toastNotificationsActions = {};

/**
 * @param {string} title The title of the toast
 * @param {string} message The message of the toast
 * @param {string} toastType Type of the toast, to be used for styling. One from ToastNotificationTypes.
 * @param {number} dismissAfter Timeout to dismiss the toast - Can be a boolean where false will never dismiss automatically
 * @return {Object}
 */

const addToast = (
  title,
  message,
  toastType,
  clickHandler,
  dismissAfter = DEFAULT_DISMISS_AFTER
) => {
  // generate random unique ID for the toast
  const key = uuidv4();

  // for styling by toastType
  const className = classNames({
    error: toastType === ToastNotificationTypes.ERROR,
    info: toastType === ToastNotificationTypes.INFO,
    success: toastType === ToastNotificationTypes.SUCCESS,
    warn: toastType === ToastNotificationTypes.WARN,
  });

  // disable the default styles for toasts
  const style = false;

  /** For now, we don't pass an ACTION text to the notification */
  // const action = TOAST_ACTION;

  const toast = {
    className,
    clickHandler,
    dismissAfter,
    key,
    message,
    style,
    title,
    // action,
  };

  return {
    toast,
    type: actionTypes.ADD_TOAST_NOTIFICATION,
  };
};

/** Convenience methods */
toastNotificationsActions.error = ({
  title,
  message,
  clickHandler,
  dismissAfter,
}) =>
  addToast(
    title,
    message,
    ToastNotificationTypes.ERROR,
    clickHandler,
    dismissAfter
  );
toastNotificationsActions.info = ({
  title,
  message,
  clickHandler,
  dismissAfter,
}) =>
  addToast(
    title,
    message,
    ToastNotificationTypes.INFO,
    clickHandler,
    dismissAfter
  );
toastNotificationsActions.success = ({
  title,
  message,
  clickHandler,
  dismissAfter,
}) =>
  addToast(
    title,
    message,
    ToastNotificationTypes.SUCCESS,
    clickHandler,
    dismissAfter
  );
toastNotificationsActions.warn = ({
  title,
  message,
  clickHandler,
  dismissAfter,
}) =>
  addToast(
    title,
    message,
    ToastNotificationTypes.WARN,
    clickHandler,
    dismissAfter
  );

toastNotificationsActions.removeToast = (toastID) => ({
  toastID,
  type: actionTypes.REMOVE_TOAST_NOTIFICATION,
});

export default toastNotificationsActions;
