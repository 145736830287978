import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCompanyStoreShortSleeve extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M64.21 74.5l-32-.15c-.552 0-1-.448-1-1l-.15-26.41-1.07-.55-4.22 2.11c-.404.207-.896.117-1.2-.22l-8.31-9.1c-.368-.386-.368-.994 0-1.38l14.8-14.86c.185-.188.436-.296.7-.3l5.06-.07c.372-.003.715.2.89.53 0 .07 3.9 7.06 10.43 7.06h.09c.27 0 7.38-.37 10-7 .15-.377.514-.626.92-.63l5.2-.07c.272.005.533.112.73.3l14.67 15c.368.386.368.994 0 1.38l-8.5 9.18c-.306.334-.8.42-1.2.21l-3.99-2.03-.88.72v26.09c.006.063.006.127 0 .19 0 .54-.43.984-.97 1zm-31-2.15l30.01.15V46.72c0-.303.135-.59.37-.78l1.72-1.44c.31-.254.745-.298 1.1-.11l3.94 2.11 7.33-8-13.7-14-4.12.06c-3.28 7.21-11.19 7.58-11.54 7.59h-.12c-6.68 0-10.77-5.76-11.9-7.58l-4.07.06-13.85 13.95 7.15 7.83 4-2c.286-.146.624-.146.91 0l2.07 1.06c.33.17.537.51.54.88l.16 26z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogCompanyStoreShortSleeve;
