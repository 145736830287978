import axios from 'axios';

import {
  requestErrorHandler as internalRequestErrorHandler,
  requestInterceptor as internalRequestInterceptor,
  responseErrorHandler as internalResponseErrorHandler,
  responseInterceptor as internalResponseInterceptor,
} from './interceptors';

const DEFAULT_XSRF_COOKIE_NAME = 'XSRF-TOKEN';
const DEFAULT_XSRF_HEADER_NAME = 'X-XSRF-TOKEN';

/**
 * Axios, Promise based HTTP client for the browser and node.js
 * @external Axios
 * @see https://github.com/axios/axios.git
 */

/**
 * Espressive API, an Axios factory that leverages the full power of Axios by handling key `events` of the request life cycle
 * @param {Object} userConfig The main configuration object
 * @param {String} userConfig.accessToken The access token to attach to every request
 * @param {String} userConfig.url the server URL that will be used for the request
 * @param {String} userConfig.mmethod the request method to be used when making the request
 * @param {String} userConfig.baseURL the base URL that will be prepended to `url` unless `url` is absolute
 * @param {Object} userConfig.headers custom headers to attach to every request
 * @param {Object|URLSearchParams} userConfig.params the URL parameters to be sent with the request
 * @param {Function} userConfig.paramsSerializer an optional function in charge of serializing `params`
 * @param {FormData|File|Blob} userConfig.data the data to be sent as the request body - Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
 * @param {Number} userConfig.timeout specifies the number of milliseconds before the request times out
 * @param {String} userConfig.cancelToken specifies a cancel token that can be used to cancel the request
 * @param {Array[Function]} userConfig.requestInterceptor An Array of functions to handle requests, interceptor at index 0 and error handler at index 1
 * @param {Array[Function]} userConfig.responseInterceptor An Array of functions to handle responses, interceptor at index 0 and error handler at index 1
 * @param {String} xsrfCookieName the name of the cookie to use as a value for xsrf token
 * @param {String} xsrfHeaderName the name of the http header that carries the xsrf token value
 * @returns {Axios} axiosInstance a customized Axios instance
 * @see /README.md
 */
const createAxiosInstance = (userConfig = {}) => {
  const xsrfCookieName =
    process.env.XSRF_COOKIE_NAME || DEFAULT_XSRF_COOKIE_NAME;
  const xsrfHeaderName =
    process.env.XSRF_HEADER_NAME || DEFAULT_XSRF_HEADER_NAME;

  const {
    accessToken,
    requestInterceptor,
    requestErrorHandler = null,
    responseInterceptor,
    responseErrorHandler = null,
    ...rest
  } = userConfig;

  const finalConfig = {
    ...rest,
    xsrfCookieName,
    xsrfHeaderName,
  };

  const axiosInstance = axios.create(finalConfig);

  // attach our own interceptors
  axiosInstance.interceptors.request.use(
    internalRequestInterceptor,
    internalRequestErrorHandler,
    { synchronous: true }
  );
  axiosInstance.interceptors.response.use(
    internalResponseInterceptor,
    internalResponseErrorHandler,
    { synchronous: true }
  );

  // attach any user-defined interceptors
  if (requestInterceptor) {
    axiosInstance.interceptors.request.use(
      requestInterceptor,
      requestErrorHandler,
      { synchronous: true }
    );
  }

  if (responseInterceptor) {
    axiosInstance.interceptors.request.use(
      responseInterceptor,
      responseErrorHandler,
      { synchronous: true }
    );
  }

  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Token ${accessToken}`;
  }

  return axiosInstance;
};

export default createAxiosInstance;
