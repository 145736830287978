import { change, getFormValues } from 'redux-form/immutable';

import buildCompositeController from '../../utils/buildCompositeController';

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues('Form01')(state);

  const valuePrexisting = formValues && formValues.get(ownProps.id.toString());
  return {
    valuePrexisting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue: (id, value) => {
    dispatch(change('Form01', id, value));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
