import Immutable from 'immutable';

// actions
import actionTypes from '../actions/actionTypes';

// globals
import OnboardProgressManagerViewFilters from '../globals/OnboardProgressManagerViewFilters';
import OnboardProgressManagerViewSortCriteria from '../globals/OnboardProgressManagerViewSortCriteria';

const INITIAL_STATE = Immutable.fromJS({
  filterBy: OnboardProgressManagerViewFilters.MY_DIRECT_REPORTS,

  getOnboardProgressManagerView: {
    failed: false,

    isLoading: false,

    isLoadingNext: false,

    pagination: {
      next: null,
      prev: null,
    },

    values: null,
  },

  selectedUserID: null,

  sortBy: OnboardProgressManagerViewSortCriteria.AT_RISK_FIRST,
});

const handleGetNextOnboardProgressManagerViewSuccess = (
  state,
  nextOnboardProgress,
  pagination
) => {
  state = state.setIn(
    ['getOnboardProgressManagerView', 'isLoadingNext'],
    false
  );

  let onboardProgress = state.getIn([
    'getOnboardProgressManagerView',
    'values',
  ]);

  onboardProgress = onboardProgress.concat(nextOnboardProgress);

  state = state.setIn(
    ['getOnboardProgressManagerView', 'values'],
    onboardProgress
  );

  state = state.setIn(
    ['getOnboardProgressManagerView', 'pagination'],
    pagination
  );

  return state;
};

const onboardProgressManagerViewReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.ONBOARD_PROGRESS_MANAGER_VIEW_SET_FILTER_BY:
      return state.set('filterBy', action.filterBy);

    case actionTypes.ONBOARD_PROGRESS_MANAGER_VIEW_SET_SORT_BY:
      return state.set('sortBy', action.sortBy);

    case actionTypes.RESET_ONBOARD_PROGRESS_MANAGER_VIEW_OPTIONS:
      return state
        .set('filterBy', INITIAL_STATE.get('filterBy'))
        .set('sortBy', INITIAL_STATE.get('sortBy'));

    case actionTypes.GET_ONBOARD_PROGRESS_MANAGER_VIEW_START:
      return state.setIn(['getOnboardProgressManagerView', 'isLoading'], true);

    case actionTypes.GET_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS:
      return state
        .set(
          'getOnboardProgressManagerView',
          INITIAL_STATE.get('getOnboardProgressManagerView')
        )
        .setIn(
          ['getOnboardProgressManagerView', 'values'],
          Immutable.fromJS(action.onboardProgress)
        )
        .setIn(
          ['getOnboardProgressManagerView', 'pagination'],
          Immutable.fromJS(action.pagination)
        );

    case actionTypes.GET_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL:
      return state
        .set(
          'getOnboardProgressManagerView',
          INITIAL_STATE.get('getOnboardProgressManagerView')
        )
        .setIn(['getOnboardProgressManagerView', 'failed'], true);

    case actionTypes.GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_START:
      return state.setIn(
        ['getOnboardProgressManagerView', 'isLoadingNext'],
        true
      );

    case actionTypes.GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_SUCCESS:
      return handleGetNextOnboardProgressManagerViewSuccess(
        state,
        Immutable.fromJS(action.nextOnboardProgress),
        Immutable.fromJS(action.pagination)
      );

    case actionTypes.GET_NEXT_ONBOARD_PROGRESS_MANAGER_VIEW_FAIL:
      return state.setIn(
        ['getOnboardProgressManagerView', 'isLoadingNext'],
        false
      );

    case actionTypes.ONBOARD_PROGRESS_MANAGER_VIEW_SELECT_USER:
      return state.set('selectedUserID', action.userID);

    default:
      return state;
  }
};

export default onboardProgressManagerViewReducer;
