import React, { PureComponent } from 'react';
import { Button, Grid, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Boundaries } from 'cascara-middleware';
import MenuPagination from '../../../../v1/components/molecules/MenuPagination';
import { Link } from 'react-router-dom';
import uiPathGenerator from '../../../../v1/utils/uiPathGenerator';

class CRUDTable extends PureComponent {
  static propTypes = {
    celled: PropTypes.bool,
    children: PropTypes.node,
    emptyState: PropTypes.node,
    headers: PropTypes.node,
    isLoading: PropTypes.bool,
    limit: PropTypes.number,
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paginationURL: PropTypes.string,
    pathOptions: PropTypes.shape({}),
    search: PropTypes.shape({}),
    totalRows: PropTypes.number,
  };

  static defaultProps = {
    celled: false,
    children: null,
    emptyState: null,
    headers: null,
    isLoading: false,
    limit: 10,
    page: 1,
    paginationURL: '',
    pathOptions: {},
    search: {},
    totalRows: 0,
  };

  render() {
    const {
      celled,
      children,
      emptyState,
      headers,
      isLoading,
      limit,
      page,
      paginationURL,
      pathOptions,
      search,
      totalRows,
    } = this.props;

    const pages = paginationURL ? Math.ceil(totalRows / limit) : false;

    return (
      <Boundaries>
        <>
          <Table celled={celled} compact style={{ width: '100%' }}>
            <Table.Header>
              <Table.Row>{headers}</Table.Row>
            </Table.Header>

            <Table.Body id='crud_table_body'>{children}</Table.Body>

            {totalRows === 0 && emptyState}
          </Table>
          {totalRows > 0 && pages < 2 && page !== 1 && (
            <Button
              as={Link}
              basic
              to={uiPathGenerator.genPath(paginationURL, {
                ...pathOptions,
                page: page - 1,
              })}
            >
              {'Go back to previous page'}
            </Button>
          )}
          {pages ? (
            <Grid padded textAlign='center'>
              <Grid.Column>
                <MenuPagination
                  currentPage={Number(page)}
                  loading={isLoading}
                  numberPages={pages}
                  pathOptions={pathOptions}
                  pathname={paginationURL}
                  search={search}
                />
              </Grid.Column>
            </Grid>
          ) : null}
        </>
      </Boundaries>
    );
  }
}

export default CRUDTable;
