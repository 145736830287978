import { useCallback, useState } from 'react';
import APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import EspFilters from 'esp-util-filters';

const useScheduleList = (activeIndex, setActiveIndex) => {
  const [schedulesListData, setSchedulesListData] = useState([]);

  const loadOffboardingSchedules = useCallback(
    ({ limit, page = 1, searchTerm }) => {
      // this needs to be replaced by the offboarding schedule url
      const url = endpointGenerator.genPath('espTodo.schedule');
      // fake flag in use until the BE support this type of filter, remove once BE is ready I need this to keep working on the schedule offboarding feature
      const filters = new EspFilters();
      filters.equalTo('schedule_type', 'offboarding');
      if (searchTerm) {
        filters.contains('name', searchTerm).or();
      }
      const query = {};
      return new Promise((resolve, reject) => {
        return APIcall.get({
          query: {
            ...query,
            esp_filters: encodeURI(filters.asQueryString()),
            limit: limit ? limit : 24,
            offset: (page - 1) * limit,
            orderBy: '-sys_date_updated',
          },
          token: true,
          url,
        })
          .then(({ body }) => {
            setSchedulesListData(body);
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    []
  );

  return {
    loadOffboardingSchedules,
    nextPag: schedulesListData.next,
    prevPag: schedulesListData.prev,
    records: schedulesListData.results,
    schedulesListData,
  };
};
export default useScheduleList;
