import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogCommunicationMobilePhones extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M61.31 16H34c-3.314 0-6 2.686-6 6v52c0 3.314 2.686 6 6 6h27.31c3.314 0 6-2.686 6-6V22c0-3.314-2.686-6-6-6zm-16.24 3.07h5.26c.464-.042.912.182 1.157.578.245.397.245.897 0 1.294-.245.396-.693.62-1.157.578h-5.26c-.464.042-.912-.182-1.157-.578-.245-.397-.245-.897 0-1.294.245-.396.693-.62 1.157-.578zm2.59 57.58c-1.254 0-2.27-1.016-2.27-2.27s1.016-2.27 2.27-2.27 2.27 1.016 2.27 2.27-1.016 2.27-2.27 2.27zm17.1-8.29h-34V24.07h34v44.29z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    );
  }
}

export default CatalogCommunicationMobilePhones;
