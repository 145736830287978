function bubbleRadiusHelper(firstChild, lastChild, isMe, theme) {
  return {
    borderBottomLeftRadius:
      lastChild || isMe ? theme.messageRadius : theme.globals.borderRadius,
    borderBottomRightRadius:
      lastChild || !isMe ? theme.messageRadius : theme.globals.borderRadius,
    borderTopLeftRadius:
      firstChild || isMe ? theme.messageRadius : theme.globals.borderRadius,
    borderTopRightRadius:
      firstChild || !isMe ? theme.messageRadius : theme.globals.borderRadius,
    marginBottom: lastChild ? 0 : '.15em',
  };
}

export default bubbleRadiusHelper;
