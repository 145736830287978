const api_places_v0_1_locations_54715 = {
  ancestry: {
    ancestry_id_list: [54715],
    zone: 'zone1',
    zone_espid: 54715,
  },
  children: [
    {
      ancestry: {
        ancestry_id_list: [54716, 54715],
        country: 'USA',
        country_espid: 54716,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'usa_zone1',
      id: 54716,
      iso_country_code: 'USA',
      location_type: 1,
      name: 'USA',
      note: 'USA USA',
      sys_date_created: '2020-11-18T23:53:57.209888Z',
      sys_date_updated: '2022-11-17T18:54:05.826148Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54729, 54715],
        country: 'Japan',
        country_espid: 54729,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'japan_zone1',
      id: 54729,
      iso_country_code: 'JPN',
      location_type: 1,
      name: 'Japan',
      note: null,
      sys_date_created: '2021-03-22T16:50:08.119121Z',
      sys_date_updated: '2022-11-17T18:54:05.848806Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54827, 54715],
        country: 'Czech Republic',
        country_espid: 54827,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'czechrepublic_zone1',
      id: 54827,
      iso_country_code: null,
      location_type: 1,
      name: 'Czech Republic',
      note: '123456',
      sys_date_created: '2021-03-22T16:50:30.779422Z',
      sys_date_updated: '2022-11-17T18:54:05.897414Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54736, 54715],
        country: 'United Kingdom',
        country_espid: 54736,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'unitedkingdom_zone1',
      id: 54736,
      iso_country_code: null,
      location_type: 1,
      name: 'United Kingdom',
      note: null,
      sys_date_created: '2021-03-22T16:50:09.431489Z',
      sys_date_updated: '2022-11-17T18:54:05.871845Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54839, 54715],
        country: 'China',
        country_espid: 54839,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'china_zone1',
      id: 54839,
      iso_country_code: 'CHN',
      location_type: 1,
      name: 'China',
      note: null,
      sys_date_created: '2021-03-22T16:50:32.930252Z',
      sys_date_updated: '2022-11-17T18:54:05.920965Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54762, 54715],
        country: 'France',
        country_espid: 54762,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'france_zone1',
      id: 54762,
      iso_country_code: 'FRA',
      location_type: 1,
      name: 'France',
      note: null,
      sys_date_created: '2021-03-22T16:50:15.203878Z',
      sys_date_updated: '2022-11-17T18:54:05.969550Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54769, 54715],
        country: 'Germany',
        country_espid: 54769,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'germany_zone1',
      id: 54769,
      iso_country_code: 'DEU',
      location_type: 1,
      name: 'Germany',
      note: null,
      sys_date_created: '2021-03-22T16:50:16.401813Z',
      sys_date_updated: '2022-11-17T18:54:05.990506Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54776, 54715],
        country: 'Italy',
        country_espid: 54776,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'italy_zone1',
      id: 54776,
      iso_country_code: 'ITA',
      location_type: 1,
      name: 'Italy',
      note: null,
      sys_date_created: '2021-03-22T16:50:17.761838Z',
      sys_date_updated: '2022-11-17T18:54:06.016982Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54755, 54715],
        country: 'Australia',
        country_espid: 54755,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'australia_zone1',
      id: 54755,
      iso_country_code: 'AUS',
      location_type: 1,
      name: 'Australia',
      note: null,
      sys_date_created: '2021-03-22T16:50:13.961577Z',
      sys_date_updated: '2022-11-17T18:54:05.945799Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54813, 54715],
        country: 'Colombia',
        country_espid: 54813,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'colombia_zone1',
      id: 54813,
      iso_country_code: 'COL',
      location_type: 1,
      name: 'Colombia',
      note: null,
      sys_date_created: '2021-03-22T16:50:28.661332Z',
      sys_date_updated: '2022-11-17T18:54:06.057853Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54820, 54715],
        country: 'Brasil',
        country_espid: 54820,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'brasil_zone1',
      id: 54820,
      iso_country_code: null,
      location_type: 1,
      name: 'Brasil',
      note: null,
      sys_date_created: '2021-03-22T16:50:29.464098Z',
      sys_date_updated: '2022-11-17T18:54:06.083352Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54924, 54715],
        country: 'UK',
        country_espid: 54924,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'uk_zone1',
      id: 54924,
      iso_country_code: null,
      location_type: 1,
      name: 'UK',
      note: null,
      sys_date_created: '2021-03-22T16:50:50.499371Z',
      sys_date_updated: '2022-11-17T18:54:06.110850Z',
    },
    {
      ancestry: {
        ancestry_id_list: [54991, 54715],
        country: 'Mexico',
        country_espid: 54991,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: 'mexico_zone1',
      id: 54991,
      iso_country_code: 'MEX',
      location_type: 1,
      name: 'Mexico',
      note: null,
      sys_date_created: '2021-03-22T16:51:08.948532Z',
      sys_date_updated: '2022-11-17T18:54:06.145012Z',
    },
  ],
  code: 'zone1',
  id: 54715,
  iso_country_code: null,
  location_type: 8,
  location_type_name: 'zone',
  name: 'zone1',
  note: '1234567',
  parent: null,
  sys_custom_fields: {
    __stale_check: {
      save_time: '2022-11-17 18:54:05.802885+00:00',
      version: 2,
    },
  },
  sys_date_created: '2020-11-18T23:53:57.142048Z',
  sys_date_updated: '2022-11-17T18:54:05.803412Z',
  url: 'https://release.qa.espressive.com/api/places/v0.1/locations/54715/',
};
export default api_places_v0_1_locations_54715;
