import { fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes';
import { get, isEmpty, isNumber, pick } from 'lodash';

const INITIAL_STATE = fromJS({
  // topics
  applications: {
    count: 0,
    errorMsg: '',
    isLoading: false,
    isLoadingOverride: false,
    list: [],
    selectedApplication: {},
  },

  /**
   * ID of the virtual assistant EspUser
   * @type {number}
   */
  baristaEspKbArticle: '',

  baristaID: null,

  baristaKBArticleError: null,

  baristaKBArticleHTML: '',

  baristaKBArticleIsLoading: true,

  baristaKBArticleIsOpen: false,

  baristaKbArticleId: '',

  baristaKbSource: '',

  baristaKbTitle: '',
  clickContext: '',

  intentLookup: {
    isLoading: false,
    list: [],
  },

  intents: {
    isLoading: false,
    list: [],
  },

  isChannelIdGivenByUrl: false,

  isOpenFromUrl: false,

  qrParams: {},

  scopedIntentLookup: {
    default: {
      isLoading: false,
      list: [],
    },
  },

  selectedConversationId: null,

  selectedDepartmentFAQ: null,

  selectedDepartmentType: null,

  selectedIntentEid: null,

  selectedSupportChannel: null,

  shouldStartWithQrParams: false,

  subProductionConfiguration: {
    data: {
      key: '',
      type: '',
      value: '',
    },
    loading: false,
  },

  supportChannelState: {
    errorMsg: null,
    isLoadedChannel: false,
    isLoadingChannel: false,
  },
  suspendBarista: false,
});

const addIntentsToState = (state, newIntents) => {
  newIntents.forEach((intent) => {
    const intentID = intent.eid;
    const intentsList = state.getIn(['intents', 'list']);
    let intentIndex;

    if (intentsList && !intentsList.isEmpty()) {
      intentIndex = intentsList.findIndex(
        (stateIntent) => stateIntent.get('eid') === intentID
      );
    }

    // Update intent
    if (isNumber(intentIndex) && intentIndex !== -1) {
      state = state.setIn(['intents', 'list', intentIndex], fromJS(intent));
    } else {
      // Push new intent
      state = state.setIn(
        ['intents', 'list'],
        intentsList.push(fromJS(intent))
      );
    }
  });

  return state;
};

/**
 * Chat Reducer which fire action thought the dispatch switch
 * @param state
 * @param action
 * @returns {*}
 */
const baristaReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_TENANT_SUB_PRODUCTION_CONFIGURATION_START: {
      return state.setIn(['subProductionConfiguration', 'loading'], true);
    }
    case actionTypes.GET_TENANT_SUB_PRODUCTION_CONFIGURATION_FAIL: {
      return state.setIn(['subProductionConfiguration', 'loading'], false);
    }
    case actionTypes.GET_TENANT_SUB_PRODUCTION_CONFIGURATION_SUCCESS: {
      return state.setIn(
        ['subProductionConfiguration', 'data'],
        action.payload
      );
    }
    case actionTypes.BARISTA_FAQ_SELECT_DEPT: {
      return state
        .set('selectedDepartmentFAQ', action.departmentID || null)
        .set('selectedDepartmentType', action.departmentTypeID || null);
    }

    case actionTypes.BARISTA_OVERRIDE_APPLICATION_START: {
      return state.setIn(['applications', 'isLoadingOverride'], true);
    }

    case actionTypes.BARISTA_OVERRIDE_APPLICATION_SUCCESS: {
      return state
        .setIn(['applications', 'isLoadingOverride'], false)
        .setIn(
          ['applications', 'selectedApplication'],
          fromJS(action.application)
        );
    }

    case actionTypes.BARISTA_OVERRIDE_APPLICATION_FAILURE: {
      return state.setIn(['applications', 'isLoadingOverride'], false);
    }

    case actionTypes.BARISTA_SINGLE_APPLICATION_START: {
      return state.setIn(['applications', 'isLoading'], true);
    }

    case actionTypes.BARISTA_SINGLE_APPLICATION_SUCCESS: {
      return state
        .setIn(['applications', 'isLoading'], false)
        .setIn(
          ['applications', 'selectedApplication'],
          fromJS(action.application)
        );
    }

    case actionTypes.BARISTA_SINGLE_APPLICATION_FAILURE: {
      return state
        .setIn(['applications', 'isLoading'], false)
        .setIn(['applications', 'selectedApplication'], fromJS({}));
    }

    case actionTypes.BARISTA_LOAD_INTENT_ERROR: {
      return state.setIn(['intents', 'isLoading'], false);
    }

    case actionTypes.BARISTA_LOAD_INTENT_START: {
      return state.setIn(['intents', 'isLoading'], true);
    }

    case actionTypes.BARISTA_LOAD_INTENT_SUCCESS: {
      return addIntentsToState(state, action.intent).setIn(
        ['intents', 'isLoading'],
        false
      );
    }

    case actionTypes.BARISTA_LOAD_INTENTS_ERROR: {
      return state.setIn(['intents', 'isLoading'], false);
    }

    case actionTypes.BARISTA_LOAD_INTENTS_START: {
      return state.setIn(['intents', 'isLoading'], true);
    }

    case actionTypes.BARISTA_LOAD_INTENTS_SUCCESS: {
      return state
        .setIn(['intents', 'isLoading'], false)
        .setIn(['intents', 'list'], fromJS(action.intents));
    }

    case actionTypes.SELECT_SUPPORT_CHANNEL: {
      return state
        .setIn(['supportChannelState', 'errorMsg'], null)
        .setIn(['supportChannelState', 'isLoadedChannel'], true)
        .setIn(['supportChannelState', 'isLoadingChannel'], false)
        .set('selectedSupportChannel', action.channelID)
        .set('selectedConversationId', action.conversationId);
    }

    case actionTypes.SUPPORT_CHANNEL_START: {
      return state
        .setIn(['supportChannelState', 'errorMsg'], null)
        .setIn(['supportChannelState', 'isLoadingChannel'], true)
        .setIn(['supportChannelState', 'isLoadedChannel'], false)
        .setIn(['selectedSupportChannel'], null)
        .set('suspendBarista', false);
    }

    case actionTypes.CLOSE_SUPPORT_CHANNEL: {
      return (
        state
          .setIn(['supportChannelState', 'errorMsg'], null)
          .set('selectedSupportChannel', null)
          .set('selectedIntentEid', null)
          .set('selectedConversationId', null)
          // resets everything from QR code
          .setIn(['shouldStartWithQrParams'], false)
          .setIn(['qrParams'], fromJS({}))
          .setIn(['isOpenFromUrl'], false)
          .setIn(['isChannelIdGivenByUrl'], false)
      );
    }

    case actionTypes.REINITIALIZE_SUPPORT_CHANNEL: {
      return state
        .set('shouldStartWithQrParams', false)
        .set('isChannelIdGivenByUrl', false)
        .set('qrParams', fromJS({}))
        .set('suspendBarista', true)
        .set('selectedIntentEid', null);
    }

    case actionTypes.WS_SEND: {
      return state.setIn(['qrParams', 'phrase'], '');
    }

    case actionTypes.SUPPORT_CHANNEL_ERROR: {
      return state
        .setIn(['supportChannelState', 'errorMsg'], action.errorMsg)
        .setIn(['supportChannelState', 'isLoadingChannel'], false)
        .setIn(['supportChannelState', 'isLoadedChannel'], false);
    }

    case actionTypes.APP_BOOTSTRAP_SUCCESS:
      return state.set('baristaID', action.bootstrapData.virtual_assistant);

    case actionTypes.OPEN_SUPPORT_CHANNEL: {
      const { qRCodeParams, channelID, intentEid, isOpenFromUrl } = action;

      let nextState = state;
      if (qRCodeParams) {
        nextState = nextState.setIn(['shouldStartWithQrParams'], true);

        if (qRCodeParams.phrase) {
          nextState = nextState.setIn(
            ['qrParams', 'phrase'],
            qRCodeParams.phrase
          );
        }

        // will mark qrParams.isSubmit as true if the submit query parameter is either 'y' or 'Y'
        if (qRCodeParams.submit) {
          nextState = nextState.setIn(
            ['qrParams', 'isSubmit'],
            qRCodeParams.submit.toUpperCase() === 'Y'
          );
        }

        // this will set parameters, they should come in array form
        const otherParams = qRCodeParams.params;
        if (!isEmpty(otherParams)) {
          nextState = nextState.setIn(
            ['qrParams', 'otherParams'],
            fromJS(otherParams)
          );
        }
      }

      if (channelID) {
        nextState = nextState.setIn(['selectedSupportChannel'], channelID);
      }

      if (intentEid) {
        nextState = nextState.setIn(['selectedIntentEid'], intentEid);
      }

      if (isOpenFromUrl) {
        nextState = nextState
          .set('isOpenFromUrl', Boolean(isOpenFromUrl))
          .set('isChannelIdGivenByUrl', Boolean(isOpenFromUrl && channelID));
      }

      return nextState;
    }

    case actionTypes.BARISTA_KB_LOAD_START: {
      return state
        .set('baristaKBArticleIsLoading', true)
        .set('baristaKBArticleError', null);
    }

    case actionTypes.BARISTA_KB_LOAD_SUCCESS: {
      return state
        .set('baristaKBArticleHTML', action.htmlBody)
        .set('baristaEspKbArticle', action.plainText)
        .set('baristaKbSource', action.source)
        .set('baristaKbTitle', action.title)
        .set('baristaKbArticleId', action.baristaKbArticleId)
        .set('clickContext', action?.clickContext)
        .set('baristaKBArticleIsLoading', false)
        .set('baristaKBArticleError', null);
    }

    case actionTypes.BARISTA_KB_LOAD_ERROR: {
      let newState = state;
      const bodyErrors = // seems like APi errors can come in those two shapes
        get(action, [
          'error',
          'response',
          'body',
          'errors',
          '0',
          'message',
          'error',
        ]) ||
        get(action, ['error', 'response', 'body', 'errors', '0', 'message']);

      if (bodyErrors) {
        // if the APi is being specific about what's wrong
        newState = newState.set('baristaKBArticleError', bodyErrors);
      } else {
        newState = newState.set(
          'baristaKBArticleError',
          get(action, ['error', 'message'])
        );
      }

      newState = newState.set('baristaKBArticleIsLoading', false);

      return newState;
    }

    case actionTypes.BARISTA_KB_MODAL_OPEN: {
      return state.set('baristaKBArticleIsOpen', true);
    }

    case actionTypes.BARISTA_KB_MODAL_CLOSE: {
      return state
        .set('baristaKBArticleIsOpen', false)
        .set('baristaKBArticleHTML', '')
        .set('baristaEspKbArticle', '')
        .set('baristaKbSource', '')
        .set('baristaKbTitle', '')
        .set('baristaKbArticleId', '');
    }

    case actionTypes.BARISTA_INTENT_LOOKUP_START:
      return state
        .setIn(['intentLookup', 'isLoading'], true)
        .setIn(['intentLookup', 'list'], fromJS([]));

    case actionTypes.BARISTA_INTENT_LOOKUP_SUCCESS:
      return state.setIn(['intentLookup', 'isLoading'], false).setIn(
        ['intentLookup', 'list'],
        fromJS(
          action.results.map((intent) => ({
            eid: intent.eid,
            name: intent.name,
          }))
        )
      );

    case actionTypes.BARISTA_INTENT_LOOKUP_FAIL:
      return state
        .setIn(['intentLookup', 'isLoading'], false)
        .setIn(['intentLookup', 'list'], fromJS([]));

    case actionTypes.BARISTA_SCOPED_INTENT_LOOKUP_START:
      return state.setIn(['scopedIntentLookup', String(action.id)], {
        isLoading: true,
        list: fromJS([]),
      });

    case actionTypes.BARISTA_SCOPED_INTENT_LOOKUP_SUCCESS: {
      const pluck = (result) => pick(result, 'name', 'eid');

      return state.setIn(['scopedIntentLookup', String(action.id)], {
        isLoading: false,
        list: fromJS(action.results.map(pluck)),
      });
    }
    case actionTypes.BARISTA_SCOPED_INTENT_LOOKUP_FAIL:
      return state.setIn(
        ['scopedIntentLookup', String(action.id), 'isLoading'],
        false
      );

    case actionTypes.LOAD_BARISTA_APPLICATIONS_WORKING:
      return state
        .setIn(['applications', 'errorMsg'], '')
        .setIn(['applications', 'isLoading'], true)
        .setIn(['applications', 'count'], 0)
        .setIn(['applications', 'list'], fromJS([]));

    case actionTypes.LOAD_BARISTA_APPLICATIONS_SUCCESS:
      return state
        .setIn(['applications', 'errorMsg'], '')
        .setIn(['applications', 'isLoading'], false)
        .setIn(['applications', 'count'], action.payload.count)
        .setIn(['applications', 'list'], fromJS(action.payload.results));

    case actionTypes.LOAD_BARISTA_APPLICATIONS_FAILURE:
      return state
        .setIn(['applications', 'errorMsg'], action.payload)
        .setIn(['applications', 'count'], 0)
        .setIn(['applications', 'isLoading'], false)
        .setIn(['applications', 'list'], fromJS([]));

    default: {
      return state;
    }
  }
};

export default baristaReducer;
