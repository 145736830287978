import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
// molecules
import EspListItem from '../molecules/EspListItem';
// utils and globals
import getDueDate from '../../utils/getDueDate';
import OnboardActivitiesCategory from '../../globals/OnboardActivitiesCategory';
import uiPathGenerator from '../../utils/uiPathGenerator';

const propTypes = {
  item: ImmutablePropTypes.map,
  templateSelected: PropTypes.number,
};

const OnboardingActivityTemplateListItem = ({
  item = Immutable.Map(),
  templateSelected = 0,
}) => {
  const categoryImage = OnboardActivitiesCategory?.hasIn([
    item?.get('category'),
    'image',
  ])
    ? OnboardActivitiesCategory?.getIn([item.get('category'), 'image'])
    : null;

  if (!categoryImage) {
    // throw new Error(
    //   'Error Data - The category has not been set for this Template Activity'
    // );
  }

  const ribbon = item?.get('status') === 'ARCHIVED' ? 'ARCHIVED' : null;

  const ImgElement = OnboardActivitiesCategory?.getIn([
    item?.get('category'),
    'image',
  ])
    ? OnboardActivitiesCategory.getIn([item.get('category'), 'image'])
    : null;
  return (
    <EspListItem
      action={
        <Button
          as={Link}
          basic
          content='view'
          to={uiPathGenerator.genPath(
            'admin.adminActivities.onboardingActivities.templates',
            {
              templateID: item.get('id'),
            }
          )}
        />
      }
      active={item.get('id') === templateSelected}
      attachedHeader={item.get('private_notes')}
      description={item.get('description')}
      header={item.get('title')}
      image={ImgElement && <ImgElement />}
      key={item.get('eid') + item.get('id')}
      meta={getDueDate(item)}
      ribbon={ribbon}
    />
  );
};

OnboardingActivityTemplateListItem.propTypes = propTypes;

export default OnboardingActivityTemplateListItem;
