import { debounce, isUndefined, noop } from 'lodash';
import React, { Component } from 'react';

import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
import LeafLocationSelectList from '../LeafLocationSelectList';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// Display
import Slider from '../../display/Slider';
// Controller
import UserPickerController from './UserPickerController';
// Functional
import UserSelect from './UserSelect';
import UserSelected from '../../display/UserSelect/UserSelected';
import UserSelectList from '../../display/UserSelect/UserSelectList';

const SEARCH_DELAY = 500;
class UserPicker extends Component {
  static propTypes = {
    /** display all users tab only */

    allUsers: PropTypes.bool,

    /** Current User Map */
    currentUser: ImmutablePropTypes.map.isRequired,

    /** For the semantic modal prop */
    defaultOpen: PropTypes.bool,

    /** Default selected User ID */
    defaultSelectedUser: PropTypes.number,

    defaultSelectedUsers: ImmutablePropTypes.set,

    /** Filter user by favorites */
    filterByFavorites: PropTypes.bool,

    /** Filters user by service team */
    filterByServiceTeam: PropTypes.bool,

    /** Filter user by the team */
    filterByTeam: PropTypes.bool,

    /** Passes thru the callback (onUserSelect) the name of the selected user */
    getSelectedName: PropTypes.bool,

    /** Main header to appear at the top of the Esp Modal. */
    header: PropTypes.string,

    /** Search user for Cases */
    isCaseSearch: PropTypes.bool,

    /** Userlist loading */
    isLoading: PropTypes.bool,

    /** Search loading state */
    isLoadingSearch: PropTypes.bool,

    /** Allow to search an user */
    isSearchable: PropTypes.bool,

    /** Set search loading */
    isSearching: PropTypes.func.isRequired,

    /** A label to render in top of the User Select Dropdown */
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

    /**
     * Called to load the list of user.
     *
     * @param {string} page - Current page.
     * @param {object} filters - Pass all differents filters.
     */
    loadListUsers: PropTypes.func.isRequired,

    /**
     * Called when the user attempts to change the user selected.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and proposed value.
     */
    onChange: PropTypes.func,

    /**
     * Called when a close event happens in Esp Modal.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClose: PropTypes.func,

    /** On Select an user */
    onUserSelect: PropTypes.func,

    /** If modal is open */
    open: PropTypes.bool,

    /** Current Pagination */
    pagination: PropTypes.string,

    /** placeholder inside the DropDown search */
    placeholder: PropTypes.string,

    /** Reset List user */
    resetUsersList: PropTypes.func.isRequired,

    /** String in search */
    searchTerm: PropTypes.string,

    selectButtonEnabled: PropTypes.bool,

    /**
     * Called when a search change event happens in the Search SUIR.
     *
     * @param {string} data.
     */
    setSearchTerm: PropTypes.func.isRequired,

    subscriberType: PropTypes.bool,

    /** Trigger element which will open the Esp Modal. */
    trigger: PropTypes.element,

    /** Immutable List of users form the search */
    usersList: ImmutablePropTypes.list,
  };

  static defaultProps = {
    allUsers: false,
    defaultOpen: void 0,
    defaultSelectedUser: null,
    defaultSelectedUsers: Immutable.Set(),
    filterByFavorites: false,
    filterByServiceTeam: false,
    filterByTeam: false,
    getSelectedName: false,
    header: '',
    isCaseSearch: false,
    isLoading: false,
    isLoadingSearch: false,
    isSearchable: false,
    label: '',
    onChange: noop,
    onClose: noop,
    onUserSelect: noop,
    open: void 0,
    pagination: null,
    placeholder: '',
    searchTerm: null,
    selectButtonEnabled: void 0,
    subscriberType: false,
    trigger: null,
    usersList: void 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSlide: void 0,

      // eslint-disable-next-line react/destructuring-assignment -- risky to fix
      open: Boolean(this.props.defaultOpen),
      page: 1,
      pickTeam: null,
      selectedName: '',
      selectedUser: null,
      teamSelected: null,
    };

    this.refUserSelect = null;
  }

  componentDidMount() {
    // We need to do this as semantic Modal onOpen function does not get called
    // if you use the props open or defaultOpen
    const { open, defaultOpen, resetUsersList } = this.props;
    if (defaultOpen || open) {
      this.handleLoadUsers();
    }

    resetUsersList();
    const { isCaseSearch } = this.props;
    if (isCaseSearch) {
      this.setActiveSlide(UserPicker.SLIDES.IS_CASE_SEARCH.FOR_CASE_TEAMS);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultSelectedUser } = this.props;
    if (nextProps.defaultSelectedUser !== defaultSelectedUser) {
      this.setState({
        selectedUser: nextProps.defaultSelectedUser,
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      currentUser,
      filterByTeam,
      filterByFavorites,
      isLoadingSearch,
      isSearching,
      loadListUsers,
      searchTerm,
      open,
    } = this.props;

    const userID = currentUser.get('id');
    const loadHiddenUser = false;

    if (nextProps.open !== open && nextProps.open) {
      loadListUsers(
        nextState.page,
        userID,
        filterByTeam,
        filterByFavorites,
        loadHiddenUser,
        false,
        this.getCasesTypeSearch()
      );
    }

    if (
      // eslint-disable-next-line react/destructuring-assignment -- state and prop share the same name :(
      (open || this.state.open) &&
      nextProps.searchTerm !== searchTerm &&
      !isLoadingSearch
    ) {
      isSearching(); // Set isLoadingSearch to true
      const resetTheUsersList = true;
      loadListUsers(
        1,
        userID,
        filterByTeam,
        filterByFavorites,
        loadHiddenUser,
        resetTheUsersList,
        this.getCasesTypeSearch()
      );
    } else if (
      // eslint-disable-next-line react/destructuring-assignment -- state and prop share the same name :(
      (open || this.state.open) &&
      // eslint-disable-next-line react/destructuring-assignment -- state and prop share the same name :(
      nextState.page !== this.state.page
    ) {
      const resetTheUsersList = false;
      loadListUsers(
        nextState.page,
        userID,
        filterByTeam,
        filterByFavorites,
        loadHiddenUser,
        resetTheUsersList,
        this.getCasesTypeSearch()
      );
    }
  }

  static SLIDES = {
    IS_CASE_SEARCH: {
      FOR_CASE_TEAMS: 0,
    },
  };

  getCasesTypeSearch = () => {
    const { allUsers, filterByServiceTeam, isCaseSearch, subscriberType } =
      this.props;
    const { activeSlide } = this.state;

    if (allUsers) {
      switch (activeSlide) {
        case 0: {
          return 'forSubscriberAllEmployees';
        }

        default: {
          return 'forSubscriberAllEmployees';
        }
      }
    }

    if (subscriberType) {
      switch (activeSlide) {
        case 0: {
          return 'forCasesTeam';
        }

        case 1: {
          return 'forSubscriberAllEmployees';
        }

        case 2: {
          return 'forLocations';
        }

        default: {
          return 'forCasesTeam';
        }
      }
    }

    if (isCaseSearch) {
      switch (activeSlide) {
        case 0: {
          return 'forCasesTeam';
        }
        case 1: {
          return 'forCasesDepartment';
        }
        case 2: {
          return 'forAllAgent';
        }

        default: {
          return 'forCasesTeam';
        }
      }
    }

    if (filterByServiceTeam) {
      return 'forCasesTeam';
    }

    return null;
  };

  handleOnSearch = debounce((e, term) => {
    const { setSearchTerm } = this.props;
    setSearchTerm(term.value);
  }, SEARCH_DELAY);

  getNextDirectoryUsers = () => {
    const { pagination } = this.props;
    const { page } = this.state;

    if (pagination) {
      this.setState({
        page: page + 1,
      });
    }
  };

  handleOpen = () => {
    const { defaultSelectedUser, resetUsersList } = this.props;

    this.setState(
      {
        activeSlide: 0,
        open: true,
        page: 1,
        pickTeam: false,
        selectedName: null,
        selectedUser: defaultSelectedUser || null,
        teamSelected: null,
      },
      () => {
        resetUsersList();
        this.handleLoadUsers();
      }
    );
  };

  handleClose = () => {
    const { onClose, resetUsersList } = this.props;
    this.setState(
      {
        activeSlide: void 0,
        open: false,
        page: 1,
        selectedName: null,
        teamSelected: null,
      },
      () => {
        resetUsersList();
        this.handleLoadUsers();
        onClose();
      }
    );
  };

  handleLoadUsers = () => {
    const {
      currentUser,
      filterByTeam,
      filterByFavorites,
      loadListUsers,
      setSearchTerm,
    } = this.props;
    const userID = currentUser.get('id');
    const loadHiddenUser = false;
    const resetTheUsersList = false;

    setSearchTerm('');

    loadListUsers(
      1,
      userID,
      filterByTeam,
      filterByFavorites,
      loadHiddenUser,
      resetTheUsersList,
      this.getCasesTypeSearch()
    );
  };

  onHandleClickUser = (userID, selectedName, location) => {
    const { onChange } = this.props;

    this.setState(
      {
        location,
        selectedName,
        selectedUser: userID,
      },
      () => {
        onChange(userID);
      }
    );
  };

  onSelectTeam = (teamID) => {
    this.setState({
      teamSelected: teamID,
    });
  };

  handleOnUserSubmit = () => {
    const {
      allUsers,
      getSelectedName,
      isCaseSearch,
      onUserSelect,
      subscriberType,
      usersList,
    } = this.props;

    const { pickTeam, selectedUser, selectedName, teamSelected, location } =
      this.state;

    const locationId = location ? location.split('/').slice(-2)[0] : null;

    if (subscriberType || allUsers || getSelectedName) {
      onUserSelect(selectedUser, selectedName);
      this.handleClose(); // Close the modal
    } else {
      // Regular user Search Picker
      if (!isCaseSearch) {
        onUserSelect(selectedUser, null, locationId); // Submit the value of the user selected
        this.handleClose(); // Close the modal
      }
      // Case reassign picker with a multiple team for the user selected
      else if (pickTeam && teamSelected) {
        onUserSelect(selectedUser, teamSelected, locationId); // Submit the value of the user selected with the new team ID

        // Reset the state
        this.setState({
          location: null,
          pickTeam: false,
          selectedUser: null,
          teamSelected: null,
        });

        this.handleClose(); // Close the modal
      } else {
        // Cases User Select - Need to check if he belongs to several teams - if not, just update the task
        // Check if the User belongs to several teams

        // Select the user selected
        const usr = usersList.find((u) => u.get('id') === selectedUser);

        // Check for multiple departments
        const hasMultipleDepartments = usr.get('service_departments').size > 1;

        // Check for multiple teams
        const hasMultipleServiceTeams = hasMultipleDepartments
          ? true // No nee to recheck since there is several departments
          : usr.getIn(['service_departments', 0]).get('service_teams').size > 1;

        if (hasMultipleDepartments || hasMultipleServiceTeams) {
          this.setState({
            pickTeam: true,
          });
        } else {
          // No multiple teams, update the users
          onUserSelect(selectedUser, teamSelected); // Submit the value of the user selected with the team
          this.handleClose(); // Close the modal
        }
      }
    }
  };

  handleBackButtonClick = () => {
    this.setState({
      pickTeam: false,
      teamSelected: null,
    });
  };

  setActiveSlide = (slideIndex) => {
    const {
      currentUser,
      filterByTeam,
      filterByFavorites,
      loadListUsers,
      resetUsersList,
      subscriberType,
    } = this.props;

    const userID = currentUser.get('id');
    const loadHiddenUser = false;
    // reset tab if all employee tab is selected and the modal comes from subscriber
    const resetTheUsersList = subscriberType && slideIndex === 1;

    this.setState(
      {
        activeSlide: slideIndex,
        selectedUser: null, // Reset preselected user
      },
      () => {
        resetUsersList();
        // avoid loading users for location tab when subscriberType is equals to true
        if ((subscriberType && slideIndex !== 2) || !subscriberType) {
          loadListUsers(
            1,
            userID,
            filterByTeam,
            filterByFavorites,
            loadHiddenUser,
            resetTheUsersList,
            this.getCasesTypeSearch()
          );
        }
      }
    );
  };

  renderContent = () => {
    const {
      isCaseSearch,
      isLoading,
      allUsers,
      isLoadingSearch,
      pagination,
      usersList,
      subscriberType,
      defaultSelectedUsers,
    } = this.props;

    const { activeSlide, selectedUser, pickTeam } = this.state;

    let tabs = !subscriberType
      ? ['label.my_team', 'label.my_department', 'label.agents']
      : ['label.my_team', 'label.all_employees', 'label.location'];

    if (allUsers) {
      tabs = ['label.all_employees'];
    }

    const userSelectList = (tab = {}) => {
      return (
        <UserSelectList
          defaultSelectedUsers={defaultSelectedUsers}
          getNextDirectoryUsers={this.getNextDirectoryUsers}
          isCaseSearch
          isLoading={isLoading || isLoadingSearch}
          isLoadingSearch={isLoadingSearch}
          key={tab.title}
          loadNextMessa
          pagination={pagination}
          selectUser={this.onHandleClickUser}
          selectedUser={selectedUser}
          subscriberType
          usersList={usersList}
        />
      );
    };

    if (allUsers) {
      return userSelectList();
    }

    const userSelectTabs =
      isCaseSearch || allUsers
        ? tabs.map((label) => ({
            title: intl.formatMessage({
              id: label,
            }),
          }))
        : null;

    let content;
    if (userSelectTabs && !pickTeam) {
      content = (
        <Slider
          activeSlide={activeSlide}
          setActiveSlide={this.setActiveSlide}
          sliderHeight='100%'
          sliderItemHeight='calc(100% - 18px)'
          tabs={userSelectTabs}
        >
          {userSelectTabs.map((tab, index) => {
            //
            if (subscriberType && index === 2 && activeSlide === 2) {
              // Index for Location select
              return (
                <LeafLocationSelectList
                  key={tab.title}
                  locationType='building'
                />
              );
            } else {
              return userSelectList(tab);
            }
          })}
        </Slider>
      );
    } else if (pickTeam) {
      content = (
        <UserSelected
          selectTeam={this.onSelectTeam}
          user={usersList.find((u) => u.get('id') === selectedUser)}
        />
      );
    } else {
      content = (
        <UserSelectList
          getNextDirectoryUsers={this.getNextDirectoryUsers}
          isLoading={isLoading}
          isLoadingSearch={isLoadingSearch}
          loadNextMessage='Loading more...'
          pagination={pagination}
          selectUser={this.onHandleClickUser}
          selectedUser={selectedUser}
          usersList={usersList}
        />
      );
    }

    return content;
  };

  render() {
    const {
      defaultOpen,
      header,
      isLoading,
      isLoadingSearch,
      isSearchable,
      label,
      onChange,
      open,
      placeholder,
      trigger,
      selectButtonEnabled,
      subscriberType,
    } = this.props;

    const { selectedUser, pickTeam, activeSlide } = this.state;
    const LOCATION_TAB = 2;
    const shouldDisplaySearch =
      activeSlide === LOCATION_TAB && subscriberType ? false : isSearchable;

    const styles = {
      border: 'none',
      left: 0,
      position: 'absolute',
      top: 0,
    };
    const modalHeader = pickTeam ? (
      <Menu borderless fluid style={styles}>
        <Menu.Item
          className='menu-back'
          disabled={isLoading}
          icon='angle left'
          name={intl.formatMessage({ id: 'label.back' })}
          onClick={this.handleBackButtonClick}
        />
        <Menu.Item className='title'>{header}</Menu.Item>
      </Menu>
    ) : (
      header
    );

    // use external prop if set
    const enableButton = isUndefined(selectButtonEnabled)
      ? Boolean(selectedUser)
      : selectButtonEnabled;

    return (
      <UserSelect
        content={this.renderContent()}
        defaultOpen={defaultOpen}
        disableContentScroll
        header={modalHeader}
        isLoadingSearch={isLoadingSearch}
        isSearchable={shouldDisplaySearch}
        label={label}
        onChange={onChange}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        onSearch={this.handleOnSearch}
        onUserSelect={this.handleOnUserSubmit}
        open={open}
        pickTeam={pickTeam}
        placeholder={placeholder}
        selectButtonEnabled={enableButton}
        selectedUser={selectedUser}
        trigger={trigger}
      />
    );
  }
}
const UserPickerTest = UserPicker;

export { UserPickerTest };

export default UserPickerController(UserPicker);
