export const DEFAULT_BRANDING = {
  color_primary: '#2db59e',
  header_color: '#e59925',
};

export const DEFAULT_TENANT = {
  isDefault: true,
  tenant: {
    branding_obj: DEFAULT_BRANDING,
  },
};

export const TENANT_STATUS = {
  ERROR: 'ERROR',
  NO_BASE_URL: 'NO_BASE_URL',
  NO_TENANT: 'NO_TENANT',
  READY: 'READY',
  WORKING: 'WORKING',
};
