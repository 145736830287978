const api_common_v0_1_localization = {
  results: [
    {
      application: 'label',
      id: 40,
      key: 'label.custom.mentor',
      language: 'en',
      text: `{"isActive":true,"texts":[{"id":"8e1d454f-4bb1-4152-a0f6-0e1aa794bf42","text":"rwerwqrweq"},{"id":"931a3f48-83e6-4d94-9ba0-4cad35f35a21","text":"rwqrwqerrqe dsfdsadasfds"},{"id":"a08124fa-36f0-4d0e-acea-00851ea554f9","text":"rreqqerqewrreqw dfsaafsdfds"}]}`,
    },
  ],
};

export default api_common_v0_1_localization;
