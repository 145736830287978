import { useCallback, useState } from 'react';
import { useSealedState } from 'reakit-utils/useSealedState';

const useInputStateCB = (initialState) => {
  const sealed = useSealedState(initialState);
  const [inputState, setInputState] = useState(sealed);

  const handleSetInputState = useCallback(
    (event) => setInputState(event.target.value.trim()),
    [setInputState]
  );

  return [inputState, handleSetInputState];
};

export default useInputStateCB;
