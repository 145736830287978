import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { darken, stripUnit } from 'polished';
import _ from 'lodash';

const rule = (props) => {
  const { disabled, selected, theme } = props;

  const selectedStyle = {
    backgroundColor: darken(0.05, theme.colors.gray),
    color: theme.colors.black,
  };

  const paddingVertical = `${stripUnit(theme.globals.padding) * 0.75}em`;

  return {
    ':focus': {
      outlineStyle: 'none',
    },
    ':hover': disabled
      ? {}
      : {
          backgroundColor: darken(0.1, theme.colors.gray),
          color: selectedStyle.color,
        },
    '> *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '> .sub': {
      fontSize: '.875em',
      opacity: 0.75,
    },
    backgroundColor: selected ? selectedStyle.backgroundColor : 'transparent',
    color: selected ? selectedStyle.color : theme.colors.text,
    cursor: disabled ? 'default' : 'pointer',
    display: 'block',
    lineHeight: 1,
    opacity: disabled ? '.8' : 1,
    paddingBottom: paddingVertical,
    paddingLeft: theme.globals.padding,
    paddingRight: `${stripUnit(theme.globals.padding) * 1.5}em`,
    paddingTop: paddingVertical,
  };
};

class ChatFormSelectOption extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string.isRequired,
    // disabled       : PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onOptionChange: PropTypes.func,
    onOptionClick: PropTypes.func,
    secondarylabel: PropTypes.string,
    selected: PropTypes.bool,
  };

  static defaultProps = {
    children: void 0,
    // disabled       : false,
    id: '',
    label: '',
    onOptionChange: _.noop,
    onOptionClick: _.noop,
    secondarylabel: null,
    selected: false,
  };

  handleClick = _.memoize(() => (e) => {
    e.preventDefault();
    const { onOptionClick, onOptionChange, id } = this.props;
    onOptionClick();
    onOptionChange(id);
  });

  render() {
    const { children, className, label, secondarylabel, selected } = this.props;
    return (
      <li
        aria-selected={selected ? selected : void 0}
        className={className}
        data-component='ChatFormSelectOption'
        onClick={this.handleClick()}
        onKeyPress={this.handleClick()}
        role='option'
        tabIndex={-1}
      >
        {children ? (
          children
        ) : (
          <>
            <div>{label}</div>
            {secondarylabel ? (
              <div className='sub'>{secondarylabel}</div>
            ) : null}
          </>
        )}
      </li>
    );
  }
}

export default createComponent(rule, ChatFormSelectOption, [
  'disabled',
  'id',
  'label',
  'onOptionChange',
  'onOptionClick',
  'option',
  'secondarylabel',
  'selected',
]);
