import buildCompositeController from '../../../utils/buildCompositeController';
import inboxesActions from '../../../actions/inboxesActions';
import inboxesThunks from '../../../actions/inboxesThunks';
import EmailServiceTypes from '../../../globals/EmailServiceTypes';

const mapStateToProps = (state) => {
  const integration = state.getIn(['integration']);
  const emailTestConnectionSuccess = integration.getIn([
    'emailTestConnectionSuccess',
  ]);
  const emailAccountTestConnection = integration.getIn([
    'emailAccountTestConnection',
  ]);
  const isloadingAddEmailCredentials = integration.getIn([
    'isloadingAddEmailCredentials',
  ]);
  const isLoadingEmailTestConnection = integration.getIn([
    'isLoadingEmailTestConnection',
  ]);
  const testConnectionSent = integration.getIn(['testConnectionSent']);

  return {
    emailAccountTestConnection,
    emailTestConnectionSuccess,
    isGmailAccountSaved: false,
    isLoadingEmailTestConnection,
    isloadingAddEmailCredentials,
    testConnectionSent,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  addNewEmailAccount(emailData) {
    return dispatch(inboxesThunks.addInbox(emailData))
      .then(({ credential }) => {
        dispatch(inboxesThunks.fetchBoxes()).then(() => {
          const { email_type } = emailData;
          if (email_type !== EmailServiceTypes.GMAIL) {
            props.onClose();
          } else {
            let credentialId;
            // eslint-disable-next-line no-prototype-builtins -- BE return different formats
            if (credential.hasOwnProperty('credential')) {
              credentialId = credential.credential.id;
            } else {
              credentialId = credential.id;
            }
            props.setIsNewFormSaved(credentialId);
          }
        });
      })
      .catch((err) => {
        if (err.response && err.response.body.errors) {
          dispatch(
            inboxesActions.inboxFormErrors(err.response.body.errors, emailData)
          );
        } else {
          // Yesterday I saw an endpoint error that had a different response than the case above.
          // since I can not replicate it anymore, I handle this error manually here.
          dispatch(
            inboxesActions.inboxFormErrors(
              ['Unexpected API error. Check network console.'],
              emailData
            )
          );
        }
      });
  },

  closeConfirmEmailInboxErrors() {
    dispatch(inboxesActions.closeConfirmEmailInboxErrors());
  },

  editEmailCredentials({ emailData, emailType }) {
    return dispatch(inboxesThunks.editEmailCredentials(emailData))
      .then(() => {
        dispatch(inboxesThunks.fetchBoxes()).then(() => {
          if (emailType !== EmailServiceTypes.GMAIL) {
            props.onClose();
          }
        });
      })
      .catch(({ body = {} }) => {
        if (body.errors && body.errors[0]) {
          emailData.isTemporal = true;
          dispatch(inboxesActions.inboxFormErrors(body.errors, emailData));
        }
      });
  },

  editEmailCredentialsAndServiceDepartement({ emailData, emailType }) {
    return dispatch(
      inboxesThunks.editEmailCredentialsAndServiceDepartement(emailData)
    )
      .then(() => {
        dispatch(inboxesThunks.fetchBoxes()).then(() => {
          if (emailType !== EmailServiceTypes.GMAIL) {
            props.onClose();
          }
        });
      })
      .catch(({ body = {} }) => {
        if (body.errors && body.errors[0]) {
          dispatch(inboxesActions.inboxFormErrors(body.errors, emailData));
        }
      });
  },
  testEmailConnection(emailData, emailAccountType) {
    // emailData = SavedEmailData , emailAccountType is CASE
    return dispatch(
      inboxesThunks.emailTestConnection({
        emailAccountType,
        emailData,
        isOnlyTestingConnection: true,
      })
    );
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
