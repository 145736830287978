import React, { useCallback, useContext, useEffect, useState } from 'react';
// import pt from 'prop-types';
import { Button } from 'reakit';

import authContext from '../../context/auth/authContext';
import platformContext from '../../context/platform/platformContext';
import tenantContext from '../../context/tenant/tenantContext';

const propTypes = {};

const ExternalLogin = () => {
  const { handleSingleSignOn } = useContext(platformContext);
  const { authorizationEndpoint, clearTenant } = useContext(tenantContext);
  const { onFailedSSO, onSuccessfulSSO } = useContext(authContext);
  const [state, setState] = useState({
    message: 'Hi',
    status: 'idle',
  });

  const { status, message } = state;
  // console.log('external login', {
  //   handleSingleSignOn,
  //   authorizationEndpoint,
  //   onFailedSSO,
  //   onSuccessfulSSO,
  //   state,
  // });

  const handleClearTenant = useCallback(() => {
    clearTenant();
  }, [clearTenant]);

  const multiPlatformSSOLogin = useCallback(async () => {
    try {
      const session = await handleSingleSignOn(authorizationEndpoint);

      onSuccessfulSSO(session);
    } catch (ssoInPlatformExcception) {
      onFailedSSO(ssoInPlatformExcception);
    }
  }, [authorizationEndpoint, onFailedSSO, onSuccessfulSSO, handleSingleSignOn]);

  useEffect(() => {
    if (status === 'idle') {
      multiPlatformSSOLogin();
      setState({
        message: 'Connecting to your SSO provider..',
        status: 'loading',
      });
    }
  }, [multiPlatformSSOLogin, status]);

  return (
    <>
      <p>{message}</p>
      <Button className='ui basic button' onClick={handleClearTenant}>
        Change tenant
      </Button>
    </>
  );
};

ExternalLogin.propTypes = propTypes;

export default ExternalLogin;
