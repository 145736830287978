import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const propTypes = {
  /** Color message */
  color: PropTypes.string,
  /** Content text/node to rend inside the <p> */
  content: PropTypes.node,
  /** Text to rend inside the header */
  header: PropTypes.string,
  /** Optional Icon */
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
  ]),
  /** rend as negative message */
  negative: PropTypes.bool,
  /** Message size */
  size: PropTypes.string,
};

const MessageEmptyState = ({
  color = void 0,
  content = '',
  header = '',
  icon = void 0,
  negative = false,
  size = null,
  ...rest
}) => (
  <Message
    color={color}
    content={content}
    header={header}
    icon={icon}
    negative={negative}
    size={size}
    {...rest}
  />
);

MessageEmptyState.propTypes = propTypes;
export default MessageEmptyState;
