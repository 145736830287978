import React, { PureComponent } from 'react';
import { intl } from 'esp-util-intl';
import { Helmet } from 'react-helmet';
import { Header, Segment } from 'semantic-ui-react';

// Molecule
import TenantConfigMenuForm from '../../../molecules/TenantConfigMenuForm';
import TenantConfigLabelForm from '../../../molecules/TenantConfigLabelForm';
import TenantConfigLocaleForm from '../../../molecules/TenantConfigLocaleForm';
import TenantConfigNotificationsForm from '../../../molecules/TenantConfigNotificationsForm';
import TenantConfigPrivacyLinks from '../../../molecules/TenantConfigPrivacyLinks';
import TenantConfigSorterDefault from '../../../molecules/TenantConfigSorterDefault';
import TenantConfigChatVersion from '../../../molecules/TenantConfigChatVersion';

class SettingsCustomization extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <Segment attached clearing>
        <Helmet
          title={intl.formatMessage({
            id: 'label.admin_title_global_settings',
          })}
        />

        <Header as='h4' content='Menu' dividing />

        <TenantConfigMenuForm
          info={intl.formatMessage({ id: 'message.settings_menu_info' })}
        />

        <Header as='h4' content='Label Customization' dividing />

        <TenantConfigLabelForm />

        <Header as='h4' content='Locale' dividing />

        <TenantConfigLocaleForm />

        <Header as='h4' content='Privacy Statement Link' dividing />

        <TenantConfigPrivacyLinks />

        <Header as='h4' content='Notification Sounds' dividing />
        <TenantConfigNotificationsForm
          info={'Sound controls affect all individual users in your tenant'}
        />

        <TenantConfigSorterDefault />

        <TenantConfigChatVersion />
      </Segment>
    );
  }
}

export const SettingsCustomizationTest = SettingsCustomization;

export default SettingsCustomization;
