import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import _ from 'lodash';

import BlockModal from '../organisms/BlockModal';
import DetailedProductDescription from '../organisms/DetailedProductDescription';
import EspProductHeader from './EspProductHeader';
import ProductDescription from '../atoms/ProductDescription';
import ProductImages from '../organisms/ProductImages';

class SelectMyGearProductFamilyStep extends PureComponent {
  static propTypes = {
    onAddProduct: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    step: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    onAddProduct: _.noop,
    onRemoveProduct: _.noop,
  };

  state = {
    activeImageIndex: 0,
  };

  setActiveImageIndex = (activeImageIndex) => {
    this.setState({
      activeImageIndex,
    });
  };

  handleSelectProduct = (e, data) => {
    const { step, onAddProduct, onRemoveProduct } = this.props;

    let selectedProductID = null;

    const items = step.get('items');
    const selectedItem = items.find((item) => item.get('isSelected'));

    if (selectedItem) {
      const selectedProduct = selectedItem.get('entity');
      selectedProductID = selectedProduct.get('id');
    }

    const newSelectedProductID = data.value;

    if (selectedProductID === newSelectedProductID) {
      return;
    }

    // remove previously selected product
    onRemoveProduct(selectedProductID);

    // add selected product
    // relative weight of product family step is always 0
    onAddProduct(newSelectedProductID, 0);
  };

  render() {
    const { step } = this.props;

    const { activeImageIndex } = this.state;

    /* TODO using the representative product of the family, is this correct? */

    const representativeProduct = step.get('representativeEntity');

    const items = step.get('items');

    let selectedConfigurationOption;

    const selectedItem = items.find((item) => item.get('isSelected'));

    if (selectedItem) {
      const selectedProduct = selectedItem.get('entity');
      selectedConfigurationOption = selectedProduct.get('id');
    }

    const configurationOptions = items
      .map((item) => {
        const product = item.get('entity');
        const productFamilyOptions = product.get('pf_options');

        const text = productFamilyOptions.reduce(
          (text, configurationOption, i) => {
            if (i > 0) {
              text += ', ';
            }

            return `${text}${configurationOption.get(
              'option'
            )}: ${configurationOption.get('value')}`;
          },
          ''
        );

        return {
          text,
          value: product.get('id'),
        };
      })
      .toJS();

    return (
      <div className='product-family-step'>
        <EspProductHeader title={representativeProduct.get('name')} />

        <ProductImages
          activeImage={activeImageIndex}
          images={representativeProduct.get('images')}
          isLightbox={false}
          productID={-1} // TODO this guy is required but not used if isLightbox is false, we need to tune that
          setActiveImage={this.setActiveImageIndex}
        />

        <BlockModal
          trigger={
            <ProductDescription
              description={representativeProduct.get('short_description')}
            />
          }
        >
          <div className='esp-blocks'>
            <DetailedProductDescription
              className='block'
              description={representativeProduct.get('description')}
              images={representativeProduct.get('images')}
              name={representativeProduct.get('name')}
            />
          </div>
        </BlockModal>

        <Select
          fluid
          onChange={this.handleSelectProduct}
          options={configurationOptions}
          placeholder='Select one...'
          selectOnBlur={false}
          upward
          value={selectedConfigurationOption}
        />
      </div>
    );
  }
}

export default SelectMyGearProductFamilyStep;
