const api_places_v0_1_locations_54681 = {
  ancestry: {
    ancestry_id_list: [54820, 54715],
    country: 'Brasil',
    country_espid: 54820,
    zone: 'zone1',
    zone_espid: 54715,
  },
  children: [
    {
      ancestry: {
        ancestry_id_list: [54821, 54820, 54715],
        country: 'Brasil',
        country_espid: 54820,
        state: '',
        state_espid: 54821,
        zone: 'zone1',
        zone_espid: 54715,
      },
      code: '_brasil_zone1',
      id: 54821,
      iso_country_code: null,
      location_type: 2,
      name: '',
      note: null,
      sys_date_created: '2021-03-22T16:50:29.528339Z',
      sys_date_updated: '2022-11-17T18:54:06.779558Z',
    },
  ],
  code: 'brasil_zone1',
  id: 54820,
  iso_country_code: null,
  location_type: 1,
  location_type_name: 'country',
  name: 'Brasil',
  note: null,
  parent: 54715,
  sys_custom_fields: {
    __stale_check: {
      save_time: '2022-11-17 18:54:06.082690+00:00',
      version: 1,
    },
  },
  sys_date_created: '2021-03-22T16:50:29.464098Z',
  sys_date_updated: '2022-11-17T18:54:06.083352Z',
  url: 'https://release.qa.espressive.com/api/places/v0.1/locations/54820/',
};
export default api_places_v0_1_locations_54681;
