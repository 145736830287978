import { EspEntities } from 'esp-globals';

const KbEntities = [
  EspEntities.CONFLUENCE_KB,
  EspEntities.ESP_KB,
  EspEntities.IVANTI_KB,
  EspEntities.KB_ARTICLE,
  EspEntities.KB_NUMBER,
  EspEntities.SHAREPOINT_KB,
  EspEntities.ZENDESK_KB,
];

export default KbEntities;
