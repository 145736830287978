import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';

// Globals
import TypeCard, { TypeDefaults } from '../../../globals/blocks/TypeCard';

class EspCard01 extends Component {
  static propTypes = {
    ...TypeCard,
    centered: PropTypes.bool,
    color: PropTypes.string,
    description: PropTypes.string,
    extra: PropTypes.string,
    fluid: PropTypes.bool,
    header: PropTypes.string,
    image: PropTypes.string,
    meta: PropTypes.string,
    onClick: PropTypes.func,
    raised: PropTypes.bool,
  };

  static defaultProps = {
    ...TypeDefaults,
    centered: false,
    color: 'red',
    description: null,
    extra: null,
    fluid: true,
    header: null,
    image: null,
    meta: null,
    onClick: () => {},
    raised: false,
  };

  render() {
    const {
      centered,
      color,
      description,
      email,
      extra,
      fluid,
      fullName,
      header,
      image,
      meta,
      mobilePhone,
      onClick,
      raised,
      template,
      title,
      type,
    } = this.props;
    return (
      <div className='block' data-blocktype={type} data-template={template}>
        <Card
          centered={centered}
          className='card'
          color={color}
          description={description}
          extra={extra}
          fluid={fluid}
          header={header}
          image={image}
          meta={meta}
          onClick={onClick}
          raised={raised}
        >
          <div className='mock name'>{fullName}</div>

          <div className='mock title'>{title}</div>

          <div className='mock phone'>{mobilePhone}</div>

          <div className='mock email'>{email}</div>
          <img alt='' src='/images/demo/cardTemp_blank.jpg' />
        </Card>
      </div>
    );
  }
}

export default EspCard01;
