const api_espuser_v0_1_users_2625 = {
  about_me: 'hhgjhghgjhhvvhvhbm',
  alias: '',
  computer_type: 'Macintosh',
  contacts: {
    ENABLEMENT: null,
    HR_CONTACT: null,
    HR_RECRUITER: null,
    IT_CONTACT: null,
    MANAGER: null,
    MENTOR: null,
    OPERATIONS: null,
  },
  cost_center: '110',
  custom_map: null,
  date_of_birth: '1904-12-31',
  delegate_of: [2625],
  delegates: [2625],
  eid: '1eb6eda2-8943-474a-aab1-b42cf5d9480b',
  email: 'espbeta0253453453543@gmail.com',
  employee_number: 'thujan1018141pm',
  esp_dict: {
    existing_user: true,
    last_supportemail_send_ts: 1566860776.325589,
    primary_interaction_interface: 'barista',
  },
  favorites: [
    8,
    11634,
    218928,
    10445,
    211797,
    10491,
    5244,
    936,
    55,
    129127,
    11579,
  ],
  first_name: 'Samuel',
  full_name: 'Samuel Samuel',
  gender: 'MALE',
  groups: [
    'ADMIN',
    'CATALOG_MANAGER',
    'SITE_MANAGER',
    'ANNOUNCER',
    '__rule_grp__65a7b5c1-f695-4ca3-adcf-95d612768160',
    '__rule_grp__27240395-aec3-43d1-ae4b-4b19fe7c7fa6',
    '__rule_grp__935cf766-d1b5-4f0e-b3dc-e6b8f8080daf',
    '__rule_grp__3fc4f283-15f8-4814-b85e-d20d7ef0f40f',
    'CASE_TECHNICAL_CONTACT_IT',
    'CASE_TECHNICAL_CONTACT_LEGAL',
    'HR_CONTACT',
    'HR_RECRUITER',
    'ENABLEMENT',
    'IT_CONTACT',
    'OPERATIONS',
    '__rule_grp__ccf086ef-4387-4b7f-a8e5-03722b75b042',
    'ONBOARD_SCHEDULER',
    'ACTIVITY_CREATOR',
    '__rule_grp__b97c8595-cd99-4c7f-83cb-5b14dc579dc6',
    '__rule_grp__d1e59b88-0e90-4cf7-a660-4d8a26a5077a',
    'MANAGER',
    '__rule_grp__2ab83e5a-1c58-464d-b55b-78cd542b9913',
    'SERVICE_LEAD',
    'SERVICE_AGENT',
    'KIOSK',
    '__rule_grp__9b4af3ee-17dd-43c4-8214-42abb9aef49d',
    '__rule_grp__eff5070b-5fd1-4ca2-a9cc-8aa81cebd506',
    '__rule_grp__9505ccc6-f8b4-4870-8673-3534a0f3feff',
    'FAQ_ADMIN',
    '__rule_grp__a71017bc-5f2b-4f50-8863-569db445fbe0',
    '__rule_grp__a7e671e9-02d8-4618-be09-0a797334c803',
    '__rule_grp__9ce74dc0-19c1-492d-9aac-cda59675a9ee',
    'BRC_MANAGER',
    '__rule_grp__d41a4e79-2766-4861-8b9d-f173320fa321',
    '__rule_grp__94dac771-d6ef-4897-8570-caa230f912dd',
    '__rule_grp__6bec8e9f-86ac-4e9f-a049-f4b6b529a4cc',
    '__rule_grp__69681143-859f-42c9-b2b2-02d02d40a70d',
    '__svc_team_auth_grp_e9434bfc-e717-4226-9400-8755c74702a0',
    '__svc_team_auth_grp_afd7a466-626c-4871-8943-d0067e501de5',
    '__svc_team_auth_grp_5aca158f-f530-4e97-9b47-92b10c204c00',
    '__svc_team_auth_grp_f3a64282-8198-4ede-bb71-d6550459efa3',
    '__svc_team_auth_grp_3d3b0840-9d43-4b56-89aa-933bf1c01a56',
    '__svc_team_auth_grp_b2635840-d7d1-43d0-9b2a-10c05d2c07c7',
    '__svc_team_auth_grp_28ef130e-0c1c-4e05-8380-2d605b21b1c8',
    '__svc_team_auth_grp_00c308c5-7afd-40c8-9c21-303a5639e935',
    '__svc_team_auth_grp_0543cd23-7d2d-4a5e-baf5-36254873667b',
    '__svc_team_auth_grp_a30cda4e-3f8d-42ac-8e8b-5df361074856',
    '__svc_team_auth_grp_c0cd96d9-5acb-4206-8587-34712e4c8db3',
    '__svc_team_auth_grp_39c7498c-4a44-4d48-9d1e-bd7dd0c56d83',
    '__svc_team_auth_grp_885af263-2404-499a-a787-6e4d1298c70b',
    '__svc_team_auth_grp_20993850-a7a5-42f1-881d-06a249c02768',
    '__svc_team_auth_grp_eb745619-f283-4bc2-8866-0bd40aa3e14c',
    '__svc_team_auth_grp_eadb98b2-1592-4165-b0f1-238b749dac30',
    '__svc_team_auth_grp_71433ec7-f117-4b2d-8ff8-20e1112d8a7e',
    '__svc_team_auth_grp_60af7463-1c7e-428e-9114-a7a7ece18cbe',
    '__svc_team_auth_grp_9da08dfb-a337-4b53-8c78-49210b8d1f3d',
    '__svc_team_auth_grp_7adb07b0-d4cb-4a86-971f-7c7e3b7dd87e',
    '__svc_team_auth_grp_1753f107-971f-4da1-8daa-976969ec6a6b',
    '__svc_team_auth_grp_da775a15-1ca8-48d2-9311-085ba4e05b37',
    '__svc_team_auth_grp_175884e9-89bc-4c69-9896-e46701b3269f',
    '__svc_team_auth_grp_6013f665-0ae1-4fdf-a479-5a5dfd725267',
    '__svc_team_auth_grp_73039fd6-4686-4525-b175-eee1bb49409b',
    '__svc_team_auth_grp_dd07ec48-adf5-4386-8856-f42a12933de3',
    '__svc_team_auth_grp_b48ad748-874d-4d34-8594-2c5a806b05e3',
    '__svc_team_auth_grp_9a0f8385-20d5-40f8-a5b5-5f515074d8b9',
    '__svc_team_auth_grp_3c4b536e-d868-4976-80fc-cfb870a96d77',
  ],
  home_address: {
    address: '84 Larsen St',
    city: 'Castro Valley',
    country: 'USA',
    postal_code: '95054',
    state: 'CA',
  },
  id: 8,
  images: [
    {
      caption: "''",
      eid: 'fff8c199-49b0-452e-b8d0-60c786faa12a',
      height: 280,
      id: 70,
      ordering: 0,
      picture:
        'https://q.evisserp.se/media/release/profile_images/25bb9d62-ef94-4664-a610-8a2edd9f0e0a.jpeg?Expires=1616451480&Signature=MEYCIQCJLzVvVrZd99bb7N2pzdXeZ62HEHuJZmabLPxTT28MHQIhAJ4mQRcYL7OWihzfIiSOlO82yAHPqiNvrSBLstp7ETsn&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      sys_created_by: 'espbeta0253453453543@gmail.com',
      sys_custom_fields: null,
      sys_date_created: '2017-11-30T19:06:41.255433Z',
      sys_date_updated: '2019-05-22T22:58:51.589315Z',
      sys_updated_by: 'eddie.valverde@espressive.com',
      thumbnail:
        'https://q.evisserp.se/media/release/profile_images/bbe1be20-c4a2-46af-b12c-d5f124367f38.jpeg?Expires=1616451480&Signature=MEUCIEUdS39XuxaIZvbOhVoe9OVzZyZHrTu6GoHvrrD8N-0zAiEA75I4oDXGhmNNNJwNE~tc6glL0-lfoCUMcc167A0wVF0_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      type: 'BACKGROUND',
      url:
        'https://release.qa.espressive.com/api/images/v0.1/profile_image/70/',
      user: 'https://release.qa.espressive.com/api/espuser/v0.1/users/8/',
      width: 540,
    },
    {
      caption: "''",
      eid: 'dc51a283-4282-4acf-8309-678ca80e124b',
      height: 400,
      id: 402,
      ordering: 0,
      picture:
        'https://q.evisserp.se/media/release/profile_images/77e996e2-0673-44d4-a983-965a6efc23d6.jpeg?Expires=1616451480&Signature=MEYCIQCwzyjERbp9TtJUoOHfZrXbnTaF7CdSk37KTLNYNTrkigIhAIf7FrXAmANYxVZE2GH6JBfT4GE0m2-PUIMYD~6doHUi&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      sys_created_by: 'espbeta0253453453543@gmail.com',
      sys_custom_fields: null,
      sys_date_created: '2020-12-30T21:16:01.346602Z',
      sys_date_updated: '2020-12-30T21:16:01.577434Z',
      sys_updated_by: 'espbeta0253453453543@gmail.com',
      thumbnail:
        'https://q.evisserp.se/media/release/profile_images/fd5f1a35-859c-41ee-9d8c-97d00301be2f.jpeg?Expires=1616451480&Signature=MEUCIFfILuCt-CL4Y1Gji3Sr-NgDvPNthhHmJIKFH54m6V-uAiEAzCeQf8fKi5qAIjS1EmU2xKKhja-GaHsfQ6I-CLoAuXM_&Key-Pair-Id=APKAIOQ7Z3WLZHFWAQAQ',
      type: 'PROFILE',
      url:
        'https://release.qa.espressive.com/api/images/v0.1/profile_image/402/',
      user: 'https://release.qa.espressive.com/api/espuser/v0.1/users/8/',
      width: 400,
    },
  ],
  is_active: true,
  is_admin: true,
  is_manager: true,
  job_role: 'https://release.qa.espressive.com/api/espuser/v0.1/job_role/14/',
  last_name: 'Samuel',
  last_sms_timestamp: null,
  location: 'https://release.qa.espressive.com/api/places/v0.1/locations/2/',
  manager: null,
  mentor: null,
  middle_name: '',
  mobile_type: 'iOS',
  nickname: 'Samuel',
  office_address: 'country: MX, zone: Every Location',
  office_location: '1100',
  phone_numbers: {
    home: {
      country_code: '',
      country_iso: '',
      number: '',
      public: true,
    },
    mobile: {
      country_code: '',
      country_iso: '',
      number: '',
      public: true,
    },
    work: {
      country_code: '',
      country_iso: '',
      number: '',
      public: true,
    },
  },
  preferred_language_id: 'en',
  remote_worker: true,
  scim_dict: null,
  secondary_email: 'espbeta0253453453543@gmail.comm',
  shadow_block: {
    date_of_birth: null,
    employee_number: '',
    first_name: '',
    job_role: null,
    last_name: '',
    location: null,
    nickname: '',
    phone_numbers: null,
    remote_worker: false,
    secondary_email: '',
    social_coordinates: null,
    start_date: null,
    title: 'FE Engineer',
  },
  sms_subscription: false,
  social_coordinates: [
    {
      network: 'linkedin',
      username: 'celis',
    },
    {
      network: 'twitter',
      username: 'celiss',
    },
  ],
  social_profile_picture: '',
  start_date: '1986-10-10T00:00:00Z',
  sys_custom_fields: null,
  termination_date: null,
  terms_accepted: false,
  title: '',
  url: 'https://release.qa.espressive.com/api/espuser/v0.1/users/842423423423/',
  user_state: 'MANAGER_CONFIRMED',
  user_type: '',
  username: 'espbeta0253453453543@gmail.com',
  work_collaboration: null,
};

export default api_espuser_v0_1_users_2625;
