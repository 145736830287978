import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import controller from '../../../../controllers/integrationTeamsHomeTabController';

const displayAnnouncements = 'displayAnnouncements';
const displayApprovalsField = 'displayApprovals';

const labels = {
  [displayAnnouncements]: 'Announcements',
  [displayApprovalsField]: 'Approvals',
};

const propTypes = {
  getBaristaBotIntegrationSettings: PropTypes.func.isRequired,
  integrationSettings: PropTypes.shape({
    display_announcements_on_app_home: PropTypes.string,
    display_approvals_on_app_home: PropTypes.string,
    public_channel_response_mode: PropTypes.string,
  }).isRequired,
  updateBaristaBotIntegrationSettings: PropTypes.func.isRequired,
};

const TeamsIntegrationHomeTab = ({
  integrationSettings,
  getBaristaBotIntegrationSettings,
  updateBaristaBotIntegrationSettings,
}) => {
  useEffect(() => {
    getBaristaBotIntegrationSettings();
  }, [getBaristaBotIntegrationSettings]);

  const handleReset = useCallback(() => {}, []);
  const handleSubmit = useCallback(
    async (/* object */ values, /* FormikActions<object> */ actions) => {
      actions.setSubmitting(true);
      await updateBaristaBotIntegrationSettings(integrationSettings, values);
      actions.resetForm();
      actions.setSubmitting(false);
    },
    [updateBaristaBotIntegrationSettings, integrationSettings]
  );

  if (isEmpty(integrationSettings)) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{
        [displayAnnouncements]:
          integrationSettings?.display_announcements_on_app_home,
        [displayApprovalsField]:
          integrationSettings?.display_approvals_on_app_home,
      }}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Grid as={Segment} attached='bottom'>
          <Grid.Row columns='equal' style={{ paddingLeft: '1em' }}>
            <Grid.Column>
              <Form>
                <Form.Field
                  style={{ flexDirection: 'column', marginTop: '1em' }}
                >
                  <label>{labels[displayAnnouncements]}</label>
                  <Checkbox
                    checked={formikProps.values[displayAnnouncements]}
                    label={'Display'}
                    name={displayAnnouncements}
                    // eslint-disable-next-line react/jsx-no-bind -- don't want to break code, so I leave this nobind issue here
                    onChange={() =>
                      formikProps.setFieldValue(
                        displayAnnouncements,
                        !formikProps.values[displayAnnouncements]
                      )
                    }
                  />
                </Form.Field>
                <Form.Field
                  style={{ flexDirection: 'column', marginTop: '1em' }}
                >
                  <label>{labels[displayApprovalsField]}</label>
                  <Checkbox
                    checked={formikProps.values[displayApprovalsField]}
                    label={'Display'}
                    name={displayApprovalsField}
                    // eslint-disable-next-line react/jsx-no-bind -- don't want to break code, so I leave this nobind issue here
                    onChange={() =>
                      formikProps.setFieldValue(
                        displayApprovalsField,
                        !formikProps.values[displayApprovalsField]
                      )
                    }
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Segment attached='bottom' secondary textAlign='right'>
            <Button
              content='Cancel'
              disabled={!formikProps.dirty}
              id='cancel_button'
              onClick={formikProps.handleReset}
              type='button'
            />
            <Button
              content='Save'
              disabled={formikProps.isSubmitting || !formikProps.dirty}
              id='save_button'
              onClick={formikProps.handleSubmit}
              primary
              type='button'
            />
          </Segment>
        </Grid>
      )}
    </Formik>
  );
};

TeamsIntegrationHomeTab.propTypes = propTypes;

export const TeamsIntegrationHomeTabTest = TeamsIntegrationHomeTab;
export default controller(TeamsIntegrationHomeTab);
