import React, { useCallback } from 'react';
import pt from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';

const propTypes = {
  formikHandleChange: pt.func,
  setFieldValue: pt.func,
  values: pt.shape({
    day_of_departure: pt.oneOfType([pt.string]),
    day_of_departure_selected: pt.bool,
    days_after_departure_day: pt.oneOfType([pt.string]),
    days_after_departure_day_selected: pt.bool,
    days_before_departure_day: pt.oneOfType([pt.string]),
    days_before_departure_day_selected: pt.bool,
  }),
};
const TemplateIntervalOptions = ({
  values,
  setFieldValue,
  formikHandleChange,
}) => {
  const handleChange = useCallback(
    (e, { name, value }) => {
      const fieldNames = [
        'day_of_departure_selected',
        'days_after_departure_day_selected',
        'days_after_departure_day',
        'days_before_departure_day',
        'days_before_departure_day_selected',
      ];
      const inputs = ['days_after_departure_day', 'days_before_departure_day'];
      fieldNames.forEach((fieldName) => {
        if (fieldName === name) {
          setFieldValue(name, !value);
        } else {
          if (inputs.includes(fieldName)) {
            setFieldValue(fieldName, '');
          } else {
            setFieldValue(fieldName, false);
          }
        }
      });
    },
    [setFieldValue]
  );

  const {
    days_before_departure_day_selected,
    day_of_departure_selected,
    days_before_departure_day,
    days_after_departure_day,
    days_after_departure_day_selected,
  } = values;

  return (
    <>
      <Form.Group>
        <Form.Field width={12}>
          <Checkbox
            checked={days_before_departure_day_selected}
            label='Days before departure day'
            name='days_before_departure_day_selected'
            onChange={handleChange}
            value={days_before_departure_day_selected}
          />
        </Form.Field>
        <Form.Input
          disabled={!days_before_departure_day_selected}
          name='days_before_departure_day'
          onChange={formikHandleChange}
          value={days_before_departure_day}
          width={2}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={12}>
          <Checkbox
            checked={day_of_departure_selected}
            label='Day of Departure'
            name='day_of_departure_selected'
            onChange={handleChange}
            value={day_of_departure_selected}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={12}>
          <Checkbox
            checked={days_after_departure_day_selected}
            label='Days after Departure'
            name='days_after_departure_day_selected'
            onChange={handleChange}
            value={days_after_departure_day_selected}
            width={12}
          />
        </Form.Field>
        <Form.Input
          disabled={!days_after_departure_day_selected}
          name='days_after_departure_day'
          onChange={formikHandleChange}
          value={days_after_departure_day}
          width={2}
        />
      </Form.Group>
    </>
  );
};

TemplateIntervalOptions.propTypes = propTypes;
export default TemplateIntervalOptions;
