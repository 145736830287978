import { fromJS } from 'immutable';

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = fromJS({});

const addDepartments = (state, newDepartments) => {
  newDepartments.forEach((department) => {
    const departmentID = department.get('id');
    state = state.set(departmentID, department);
  });

  return state;
};

const departmentsReducer = (state, action = {}) => {
  if (!state) {
    state = INITIAL_STATE;
  }

  if (!action.type) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_ROOT_DEPARTMENTS_SUCCESS:
      return addDepartments(state, fromJS(action.rootDepartments));

    case actionTypes.GET_CHILDREN_DEPARTMENTS_SUCCESS:
      return addDepartments(state, fromJS(action.childrenDepartments));

    case actionTypes.GET_DEFAULT_SELECTED_DEPARTMENT_SUCCESS:
      return addDepartments(state, fromJS(action.departments));

    case actionTypes.GET_PRODUCT_PERMISSION_GROUPS_SUCCESS:
      return addDepartments(state, fromJS(action.departments));

    default:
      return state;
  }
};

export default departmentsReducer;
