import _ from 'lodash';

import BlockBase from './BlockBase';
import { processPropDefaults, processPropTypes } from './typesUtils';

// Definition of the block attributes
const blockAttrsDefinition = _.merge({}, BlockBase, {
  hidden: {
    default: false,
    type: 'bool',
  },
  inputRequired: {
    default: false,
    type: 'bool',
  },
  inputType: {
    required: true,
    restrictToOptions: ['checkbox'],
    type: 'string',
  },
  inputValue: {
    objectShape: {
      map: {
        requred: false,
        type: 'string',
      },
      value: {
        required: false,
        type: 'string',
      },
    },
    required: true,
    type: 'object',
  },
  labelText: {
    required: true,
    type: 'string',
  },
  readOnly: {
    default: false,
    type: 'bool',
  },
});

// Object Definition used by blobifier
const CheckboxBlockDefinition = {
  blobAttributes: blockAttrsDefinition,
  label: 'Checkbox',
  templates: ['checkbox01', 'checkUserAsManager01'],
};

// Converting attributes to React prop type functions
const propTypes = processPropTypes(blockAttrsDefinition);
const TypeDefaults = processPropDefaults(blockAttrsDefinition);

// proptypes is used by the Block components
//* BlockDefinition is used by blobifier internally
export { propTypes as default, CheckboxBlockDefinition, TypeDefaults };
