import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dimmer,
  Form,
  Header,
  Icon,
  Loader,
  Menu,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { isEqual } from 'lodash';
import {
  useGetCredentials,
  useSaveNewCredentials,
  useTestConnection,
} from './hooks/useThunks';
import ContentTestConnectionModal from './ContentTestConnectionaModal';
import browserHistory from '../../../../../utils/browserHistory';
import uiPathGenerator from '../../../../../utils/uiPathGenerator';

const modalStyle = {
  borderRadius: 'initial',
  top: '30%',
};

const AUTHENTICATION_FAILED = 'Authentication failed. Check your credentials.';
const AUTHENTICATION_SUCCESS = 'Settings test passed';
const ConnectionSettings = () => {
  const [values, setValues] = useState({
    client_id: '',
    secret_id: '',
    tenant_id: '',
    url: '',
  });
  const [errors, setErrors] = useState({});
  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    message: '',
  });
  const [messageConnection, setMessageConnection] = useState({
    isConnectionValid: false,
    message: '',
  });
  const {
    data: { body: credentials = null } = {},
    isLoading: isLoadingGettingCredentials,
  } = useGetCredentials();

  const {
    mutateAsync: testConnection,
    isLoading,
    isSuccess,
  } = useTestConnection();

  const {
    mutateAsync: saveCredentials,
    isSuccess: isSuccessSavingCredentials,
  } = useSaveNewCredentials();

  useEffect(() => {
    if (credentials) {
      setValues({ ...values, ...credentials });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO refactor
  }, [credentials]);

  useEffect(() => {
    if (isSuccessSavingCredentials) {
      browserHistory.push({
        pathname: uiPathGenerator.genPath(
          'admin.integrations.sharepoint.contentSync'
        ),
      });
    }
  }, [isSuccessSavingCredentials]);

  const handlerTestConnection = useCallback(
    (event) => {
      event.persist();
      const isCrendentialsSame = isEqual(credentials, values);
      const params = isCrendentialsSame ? null : values;
      testConnection(params)
        .then(({ body }) => {
          if (body.CONNECTION_IS_VALID) {
            setMessageConnection({
              isConnectionValid: body.CONNECTION_IS_VALID,
              message: AUTHENTICATION_SUCCESS,
            });
          } else {
            setMessageConnection({
              isConnectionValid: false,
              message: AUTHENTICATION_FAILED,
            });
          }
        })
        .catch(() =>
          setMessageConnection({
            isConnectionValid: false,
            message: AUTHENTICATION_FAILED,
          })
        );
    },
    [testConnection, values, credentials]
  );

  const handleChange = useCallback(
    (e, { name, value }) => {
      setValues({ ...values, [name]: value });
    },
    [values]
  );

  const handleBlur = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (!value) {
        setErrors({ ...errors, [name]: 'Required' });
      } else {
        delete errors[name];
        setErrors({ ...errors });
      }
    },
    [errors]
  );

  const onHandleSubmit = useCallback(() => {
    const areAllFieldFilled = () => {
      const fillErrors = { ...errors };
      for (const key in values) {
        if (!values[key]) {
          fillErrors[key] = 'Required';
        }
      }
      setErrors(fillErrors);
      return Object.keys(fillErrors).length === 0;
    };

    if (areAllFieldFilled()) {
      const isNewCredentials = isEqual(credentials, values);
      const params = isNewCredentials ? null : values;
      testConnection(params).then(({ body }) => {
        if (body.CONNECTION_IS_VALID) {
          saveCredentials(params);
        } else {
          setModalStatus({
            isOpen: true,
            message: body.message,
          });
        }
      });
    }
  }, [testConnection, credentials, saveCredentials, values, errors]);

  const handleSaveAfterSync = () => {
    saveCredentials(values);
  };

  const actionButtonForModal = () => {
    let button = {};
    if (isLoading || !isSuccess || !messageConnection.isConnectionValid) {
      button = {
        basic: true,
        content: 'Close',
        key: 'done',
      };
    }
    if (isSuccess && messageConnection.isConnectionValid) {
      button = {
        content: 'save and configure sync',
        key: 'sync',
        onClick: handleSaveAfterSync,
        primary: true,
      };
    }
    return [button];
  };

  const handleCloseModal = () => {
    setModalStatus({
      isOpen: false,
      message: '',
    });
  };

  return (
    <>
      <Dimmer active={isLoadingGettingCredentials} inverted>
        <Loader />
      </Dimmer>
      <Segment as={Form} attached style={{ borderTop: 'none' }}>
        <Form.Input
          error={errors.url ? errors.url : null}
          label='SharePoint URL'
          name='url'
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.url || ''}
          width={11}
        />
        <Form.Input
          error={errors.tenant_id ? errors.tenant_id : null}
          label='Tenant Id'
          name='tenant_id'
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.tenant_id || ''}
          width={7}
        />
        <Form.Field>
          <Header as='h4' content='Authentication' />
        </Form.Field>
        <Form.Group widths='equal'>
          <Form.Input
            error={errors.client_id ? errors.client_id : null}
            label='Client ID'
            name='client_id'
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.client_id || ''}
          />
          <Form.Input
            error={errors.secret_id ? errors.secret_id : null}
            label='Client ID Secret'
            name='secret_id'
            onBlur={handleBlur}
            onChange={handleChange}
            required
            type='password'
            value={values.secret_id || ''}
          />

          <Modal
            actions={actionButtonForModal()}
            closeIcon={<Menu.Item className='close'>{'Exit'}</Menu.Item>}
            content={
              <ContentTestConnectionModal
                isLoading={isLoading}
                isSuccess={isSuccess}
                messageConnection={messageConnection}
              />
            }
            header='Testing Authentication'
            size='mini'
            style={modalStyle}
            trigger={
              <Form.Button
                content='TEST CONNECTION'
                onClick={handlerTestConnection}
                positive
                style={{ marginTop: '1.5em' }}
              />
            }
          />
        </Form.Group>
      </Segment>
      <Segment attached='bottom' secondary textAlign='right'>
        <Button content='Save' onClick={onHandleSubmit} positive />
      </Segment>
      <Modal
        actions={[
          {
            content: 'Done',
            key: 'done',
            onClick: handleCloseModal,
            positive: true,
          },
        ]}
        content={
          <Header
            as='h5'
            color='red'
            content={modalStatus.message}
            icon={<Icon circular color='red' inverted name='exclamation' />}
          />
        }
        header='Saving Authentication'
        open={modalStatus.isOpen}
        size='mini'
        style={modalStyle}
      />
    </>
  );
};

export default ConnectionSettings;
