import '@espressive/legacy-css';
import 'inter-ui';

// eslint-disable-next-line no-unused-vars -- leaving this here, since I don't know if it's required
import React from 'react';
// Main App renderer
import renderApp from './renderApp';
import FastClick from 'fastclick';
import _ from 'lodash';
import {
  getStorageKey,
  persistentStorage,
  PersistentStorageKeys,
} from 'esp-util-auth';
import browserHistory from './v1/utils/browserHistory';
import uiPathGenerator from './v1/utils/uiPathGenerator';

// Make sure we get all of the assets from our lib directory
require.context('../../lib/images/', true, /^.*/);

// This rehydrate the native storage into into the web local storage
const reHydrateLocalStorage = () => {
  const { NativeStorage } = window;
  if (NativeStorage) {
    _.forOwn(PersistentStorageKeys, (storageKey) => {
      NativeStorage.getItem(
        getStorageKey(storageKey),
        (value) => {
          persistentStorage.put(storageKey, value);
        },
        _.noop, // success callback
        _.noop // error callback
      );
    });
  }
};

const app = {
  initialize: function () {
    document.addEventListener(
      'deviceready',
      this.onDeviceReady.bind(this),
      false
    );
  },

  onDeviceReady: function () {
    // Avoiding the 300ms Click Delay
    FastClick.attach(document.body);

    // hack for keyboard not being brought up instantly in iOS
    // see https://github.com/ftlabs/fastclick/issues/548
    FastClick.prototype.focus = function (targetElement) {
      targetElement.focus();
    };

    reHydrateLocalStorage();

    // DEV-15510
    // As long as there's a tenant url (domain)
    // we should be redirecting to toDo - that page checks for credentials
    if (persistentStorage.has(PersistentStorageKeys.TENANT_URL)) {
      // If the session is not valid, they will be kicked out and taken to logout page
      browserHistory.push(uiPathGenerator.genPath('app.toDo'));
    } else {
      // otherwise the default landing page for mobile is the select tenant screen
      browserHistory.push(uiPathGenerator.genPath('noTenant'));
    }

    // DEV-14397
    // This has to render after redirection
    // otherwise HomePage is rendered first instead of noTenant
    renderApp();
  },
};

const startUpApp = () => {
  app.initialize();
};

export default startUpApp;
