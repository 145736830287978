// tools
import APIcall from '../utils/APIcall';
import endpointGenerator from '../utils/endpointGenerator';
import _ from 'lodash';
import immutable from 'immutable';

// selectors
import getJobRolesWithNoUserRules from '../selectors/getJobRolesWithNoUserRules';
import getBundleJobRolePermissions from '../selectors/getBundleJobRolePermissions';

// actions
import userRulesActions from './userRulesActions';
import adminBundleThunks from './adminBundleThunks';
import bundleAction from './bundleActions';
import jobRuleMatcher from '../globals/jobRuleMatcher';

const userRulesThunks = {};

//
// loads bundle permissions
//
userRulesThunks.getBundleJobRolePermissions = (cb = _.noop) => (dispatch) => {
  const url = endpointGenerator.genPath('espUser.jobRoles.permissions');

  APIcall.get({
    query: {
      application: 'catalog',
      entity_type: 'espbundle',
      limit: 1000, // TODO we need to get rid of this
    },
    success({ body: { job_role_permissions } }) {
      dispatch(bundleAction.setBundleJobRolePermissions(job_role_permissions));
      cb();
    },
    token: true,
    url: url,
  });
};

//
// removes bundle permission entity from a given bundle
//
userRulesThunks.removeBundlePermission = ({
  job_role_permissions,
  newValues,
  userRules,
  bundleID,
}) => (dispatch) => {
  const regExp = (name) => new RegExp(`user.job_role.name.*"${name}"`);

  const removedJobRole = job_role_permissions.filterNot((jobRolePermission) =>
    newValues.find(
      (jobRolePermissionID) =>
        jobRolePermission.get('id') === jobRolePermissionID
    )
  );
  const userRule =
    userRules.find((userRule) =>
      userRule.get('rule').match(regExp(removedJobRole.keys().next().value))
    ) || immutable.Map();

  dispatch(bundleAction.loadBundleJobRolePermissionsStart());

  dispatch(
    userRulesThunks.loadPermissionEntity(userRule.get('id'), bundleID)
  ).then((entity) => {
    APIcall.delete({
      success() {
        dispatch(bundleAction.loadBundleJobRolePermissionsSuccess());
        dispatch(adminBundleThunks.loadBundleJobRoles());
      },
      token: true,
      url: entity.url,
    });
  });
};

// re-usable function
const createPermissionEntity = ({ bundleID, url, resolve, dispatch }) => {
  dispatch(
    userRulesThunks.createPermissionEntity({
      entity_app_label: 'catalog',
      entity_filter: {
        id: bundleID,
      },
      entity_model: 'espbundle',
      obj_permissions: ['catalog.view_espbundle'],
      rule: url,
    })
  ).then(() => {
    resolve();
  });
};

//
// adds permissions for bundles
// @param o.job_role_permissions <Immutable.Map>
// @param o.newValues <Immutable.List>
// @param o.userRules <Immutable.Map>
// @param o.bundleID <Number>
// @param o.jobRoles <Immutable.Map>
//
userRulesThunks.addBundlePermission = ({
  newValues,
  userRules,
  bundleID,
  jobRoles,
}) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(bundleAction.loadBundleJobRolePermissionsStart());
    const job_role_permissions = getBundleJobRolePermissions(
      getState(),
      bundleID
    );
    const diff = newValues.filterNot((v) =>
      job_role_permissions.find((jrp) => jrp.get('id') === v)
    );

    if (diff.size !== 1) {
      reject('One role at the time, cannot create more than 1');
    }

    const jobRole = jobRoles.find((jrp) => jrp.get('id') === diff.first());
    const rule = jobRuleMatcher(jobRole.get('name'), userRules);

    if (!rule) {
      const missingjobRoles = getJobRolesWithNoUserRules(getState());
      const missingjobRole = missingjobRoles.filter(
        (jrp) => jrp.get('id') === diff.first()
      );
      dispatch(
        userRulesThunks.createJobRoleRule(missingjobRole.first().get('name'))
      ).then((newRule) => {
        createPermissionEntity({
          bundleID,
          dispatch,
          resolve,
          url: newRule[0].url,
        });
      });
    } else {
      createPermissionEntity({
        bundleID,
        dispatch,
        resolve,
        url: rule.get('url'),
      });
    }
  });

userRulesThunks.loadUserRules = (cb = _.noop) => (dispatch) => {
  APIcall.get({
    query: {
      limit: 1000,
    },
    // TODO we need to get rid of this
    success({ body: { results } }) {
      dispatch(userRulesActions.setUserRules(results));
      cb();
    },

    token: true,
    url: endpointGenerator.genPath('espRbac.userRules'),
  });
};

userRulesThunks.createJobRoleRule = (name) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.post({
      data: {
        rule: `user.job_role.name == "${name}"`,
      },
      error() {
        reject();
      },
      success({ body }) {
        dispatch(userRulesActions.setUserRules([body]));
        resolve([body]);
      },
      token: true,
      url: endpointGenerator.genPath('espRbac.userRules'),
    });
  });

//
// load permissions entities
//
userRulesThunks.loadPermissionEntities = (cb = _.noop) => () => {
  APIcall.get({
    query: {
      entity_model: 'espbundle',
      limit: 1000,
    },
    success({ body }) {
      cb(body);
    },
    token: true,
    url: endpointGenerator.genPath('espRbac.entityPermissions'),
  });
};

userRulesThunks.loadPermissionEntity = (ruleID, bundleID) => () =>
  new Promise((resolve, reject) => {
    const url = endpointGenerator.genPath('espRbac.entityPermissions');
    const query = `?rule=${ruleID}&entity_model=espbundle&entity_app_label=catalog&entity_filter=%7B%22id%22:${bundleID}%7D`;
    APIcall.get({
      error(err) {
        reject(err);
      },
      success({ body }) {
        if (!body || !body.results || body.results.length !== 1) {
          window.Rollbar.error('permission removal failed', {
            bundleID,
            query: {
              entity_app_label: 'catalog',
              entity_filter: `{id:${bundleID}}`,
              entity_model: 'espbundle',
              rule: ruleID,
            },
            results: body.results,
            ruleID,
          });
        }
        resolve(body.results[0]);
      },
      token: true,
      url: url + query,
    });
  });

// TODO make sure we update this everywhere
userRulesThunks.createPermissionEntity = (options = {}) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIcall.post({
      data: options,
      error() {
        reject();
      },
      success({ body }) {
        dispatch(adminBundleThunks.loadBundleJobRoles());
        resolve(body);
      },
      token: true,
      url: endpointGenerator.genPath('espRbac.entityPermissions'),
    });
  });

export default userRulesThunks;
