import APIcall from '../../../../../../utils/APIcall';
import endpointGenerator from '../../../../../../utils/endpointGenerator';

const postBulkImportUsers = (filterQuery, maxUserCount = 0) => {
  const url = endpointGenerator.genPath('espUser.users.bulkImportUsers');
  return APIcall.post({
    data: {
      filter_query: filterQuery,
      integration: 'SNOW',
      max_user_count: Number(maxUserCount),
    },
    token: true,
    url,
  });
};

export default postBulkImportUsers;
