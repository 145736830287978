import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardsCatalogLink extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: 'none',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color} fillRule='evenodd' transform='translate(10 9)'>
          <path
            d='M68 0H8C3.582 0 0 3.582 0 8v46.5c0 4.418 3.582 8 8 8h34.27v-3H8c-2.76 0-5-2.24-5-5V14.59h70v39.9c0 2.76-2.24 5-5 5h-2v3h2c4.418 0 8-3.582 8-8V8c0-4.418-3.582-8-8-8zM7.69 10C6.204 10 5 8.796 5 7.31s1.204-2.69 2.69-2.69 2.69 1.204 2.69 2.69c.008.72-.272 1.41-.777 1.922-.506.51-1.194.798-1.913.798V10zm10.3 0c-1.486 0-2.69-1.204-2.69-2.69s1.204-2.69 2.69-2.69 2.69 1.204 2.69 2.69c.008.717-.27 1.407-.774 1.918-.503.51-1.19.8-1.906.802l-.01-.03zm10.3 0c-1.09-.008-2.065-.672-2.474-1.68-.408-1.01-.167-2.167.61-2.93.777-.762 1.937-.98 2.94-.555 1 .427 1.646 1.416 1.634 2.505 0 .715-.285 1.4-.79 1.906-.508.504-1.195.787-1.91.784l-.01-.03z'
            fill='#F00'
            fillRule='nonzero'
          />
          <path
            d='M59.39 64.22l4.34-2.5c.578-.335.95-.937.993-1.604.042-.667-.252-1.31-.783-1.716L45.28 44.15c-.632-.48-1.488-.54-2.18-.154-.694.385-1.094 1.144-1.02 1.934l2.33 23.92c.066.687.482 1.292 1.1 1.6.62.307 1.352.273 1.94-.09l4.2-2.61 3.16 7.21c.24.554.718.97 1.3 1.13.173.047.35.07.53.07.416 0 .82-.13 1.16-.37l6.08-4.33c.473-.333.778-.854.838-1.43.06-.575-.133-1.147-.528-1.57l-4.8-5.24zm-2.74 10.93l-4.08-9.33-6.17 3.83-2.34-23.92 18.67 14.25-6.5 3.75 6.5 7.08-6.08 4.34z'
            fill='#F00'
            fillRule='nonzero'
          />
          <path
            d='M30.75 26.69l-2.42 8.04'
            stroke='#FFF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='6'
          />
          <path
            d='M27.17 42.22H16.86c-.514.004-.963-.35-1.08-.85l-2.58-11c-.138-.594.23-1.187.825-1.325.594-.138 1.187.23 1.325.825l2.38 10.19h8.62l4.91-15.79c.14-.462.567-.78 1.05-.78h6.26c.61 0 1.105.495 1.105 1.105S39.18 25.7 38.57 25.7h-5.43l-4.92 15.75c-.144.458-.57.77-1.05.77zm-9.76 2.41c.972 0 1.76.788 1.76 1.76s-.788 1.76-1.76 1.76-1.76-.788-1.76-1.76.788-1.76 1.76-1.76zm0-1c-1.524 0-2.76 1.236-2.76 2.76 0 1.524 1.236 2.76 2.76 2.76 1.524 0 2.76-1.236 2.76-2.76 0-1.524-1.236-2.76-2.76-2.76zm8.84 1c.712 0 1.354.43 1.626 1.086.272.658.122 1.415-.38 1.92-.505.502-1.262.652-1.92.38-.657-.272-1.086-.914-1.086-1.626 0-.972.788-1.76 1.76-1.76zm0-1c-1.117-.004-2.127.666-2.557 1.697-.43 1.03-.197 2.22.592 3.01.788.792 1.976 1.03 3.01.604 1.03-.424 1.705-1.43 1.705-2.55 0-1.523-1.236-2.76-2.76-2.76h.01z'
            fill='#F00'
            fillRule='nonzero'
          />
          <rect
            fill='#F00'
            fillRule='nonzero'
            height='8.13'
            rx='3.34'
            width='7.46'
            x='18.4'
            y='30.56'
          />
          <path
            d='M24.31 38.99h-4.36c-1.02 0-1.85-.82-1.86-1.84v-5c0-1.027.833-1.86 1.86-1.86h4.36c1.027 0 1.86.833 1.86 1.86v5c-.01 1.02-.84 1.84-1.86 1.84z'
            fill='#F00'
            fillRule='nonzero'
          />
        </g>
      </svg>
    );
  }
}

export default CardsCatalogLink;
