import _ from 'lodash';
import BrowserPrompt from '../globals/BrowserPrompt';

const FilePickerUtils = {};

FilePickerUtils.promptPicturePicker = function ({
  inputRef,
  title = '',
  message = '',
  promptCallback = _.noop,
}) {
  // Use cordova file picker only for android
  if (window.cordova) {
    const uploadPhotoOptions = {
      cameraDirection: 1,
      correctOrientation: true,
      destinationType: window.Camera.DestinationType.FILE_URI,
      encodingType: window.Camera.EncodingType.JPEG,
      mediaType: window.Camera.MediaType.PICTURE,
      quality: 50,
    };

    BrowserPrompt.confirm(message || 'Select source', {
      buttons: ['Camera', 'Library'],
      callback: function (action) {
        if (action === 1) {
          // buttonIndex 1, i.e. Camera
          uploadPhotoOptions.sourceType =
            window.Camera.PictureSourceType.CAMERA;
        } else {
          // or Library otherwise
          uploadPhotoOptions.sourceType =
            window.Camera.PictureSourceType.PHOTOLIBRARY;
        }

        // Then use the cordova plugin to get the god damn picture
        const onFail = () => {
          // message;
          // Commented for now - we need to find a wait to check if the user granted permission to the app for asset and camera
          // See DEV-3909
          // BrowserPrompt.alert('Failed because: ' + message);
        };

        navigator.camera.getPicture(promptCallback, onFail, uploadPhotoOptions);
      },
      content: message || 'Select source',
      title: title || 'Image',
    });
  } else {
    // Uses the browser file picker
    inputRef.click();
  }
};

FilePickerUtils.selectCameraOrImage = (type, promptCallback) => {
  if (window.cordova) {
    const uploadPhotoOptions = {
      cameraDirection: 1,
      correctOrientation: true,
      destinationType: window.Camera.DestinationType.FILE_URI,
      encodingType: window.Camera.EncodingType.JPEG,
      mediaType: window.Camera.MediaType.PICTURE,
      quality: 50,
      sourceType:
        window.Camera.PictureSourceType[
          type === 'Image' ? 'PHOTOLIBRARY' : 'CAMERA'
        ],
    };

    // Then use the cordova plugin to get the god damn picture
    const onFail = () => {
      // message;
      // Commented for now - we need to find a wait to check if the user granted permission to the app for asset and camera
      // See DEV-3909
      // BrowserPrompt.alert('Failed because: ' + message);
    };

    navigator.camera.getPicture(promptCallback, onFail, uploadPhotoOptions);
  }
};

export default FilePickerUtils;
