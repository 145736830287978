const OnboardingActivityStates = {
  COMPLETE: 'COMPLETE',

  DELINQUENT: 'DELINQUENT',

  NORMAL: 'NORMAL',

  OVERDUE: 'OVERDUE',

  SCHEDULED: 'SCHEDULED',

  WARNING: 'WARNING',
};

export default OnboardingActivityStates;
