import Immutable from 'immutable';
import { createSelector } from 'reselect';

const getUsers = (state) => state.getIn(['entities', 'users']);

const getId = (state, id) => id;

const getUserDataFromEntities = (users = Immutable.Map(), id) => {
  if (users && !users.isEmpty()) {
    return users.find((user) => user.get('id') === id);
  }
  return null;
};
const selector = createSelector([getUsers, getId], getUserDataFromEntities);

export default selector;
