import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CatalogFurnitureWhiteboards extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M73.86 25H38.71c-3.39 0-6.14 2.75-6.14 6.14v6.09c0 .552.448 1 1 1s1-.448 1-1v-6.09c0-2.286 1.854-4.14 4.14-4.14h35.15c2.286 0 4.14 1.854 4.14 4.14V57c0 2.286-1.854 4.14-4.14 4.14H45c-.552 0-1 .448-1 1s.448 1 1 1h28.86c1.628 0 3.19-.647 4.342-1.798C79.352 60.19 80 58.628 80 57V31.14c0-1.628-.647-3.19-1.798-4.342C77.05 25.648 75.488 25 73.86 25z' />
          <path d='M49.79 49.66l-16.13 6.1c-.91-1.18-2.87-2.12-5.34-2.55v-.7c3.137-1.5 4.81-4.978 4.022-8.364-.788-3.387-3.824-5.77-7.3-5.73-3.477.038-6.46 2.49-7.17 5.892-.712 3.403 1.038 6.843 4.208 8.272v.65c-3.33.62-5.74 2.07-5.75 3.88v.1L16 61.74v10.07l18.46.19v-9l17-9.31s.98-3.46-1.67-4.03z' />
        </g>
      </svg>
    );
  }
}

export default CatalogFurnitureWhiteboards;
