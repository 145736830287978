import { partial } from 'lodash';
import EspFilters from 'esp-util-filters';
import baristaSearchConditionsThunks from '../../../../../../v1/actions/caseMgmtThunks/baristaSearchConditionsThunks';
import baristaThunks from '../../../../../../v1/actions/baristaThunks';
import buildCompositeController from '../../../../../../v1/utils/buildCompositeController';
import toastNotificationsActions from '../../../../../../v1/actions/toastNotificationsActions';

const mapStateToProps = (state) => ({
  baristaSearchCondition: null,
  isCreatingCondition: false,
  isLoadingCategoriesList: state.getIn([
    'caseMgmt',
    'serviceDepartmentCategories',
    'isLoading',
  ]),
  isLoadingIntentsList:
    state.getIn(['cases', 'isLoadingBaristaSearch']) ||
    state.getIn(['barista', 'intents', 'isLoading']),
  isLoadingTaskTypes: state.getIn(['caseMgmt', 'taskTypes', 'isLoading']),
  isLoadingTeamList: state.getIn([
    'caseMgmt',
    'serviceDepartmentTeam',
    'isLoading',
  ]),
});

const defaultErrorToast = (dispatch, error) =>
  dispatch(
    toastNotificationsActions.error({
      message: error.message,
      title: 'Error',
    })
  );

const mapDispatchToProps = (dispatch) => {
  // if we receive new specs about how to report errors we can dismiss the default handler
  const errorHandler = partial(defaultErrorToast, dispatch);

  return {
    createCondition(conditionData) {
      const prepend = !conditionData.order_at_bottom;
      dispatch(
        baristaSearchConditionsThunks.createCondition(conditionData, prepend)
      ).then(
        () =>
          dispatch(
            toastNotificationsActions.success({
              message: 'Condition created',
              title: 'Success',
            })
          ),
        errorHandler
      );
    },

    loadIntentData(intentEID) {
      const query = new EspFilters().equalTo('eid', intentEID).asQueryString();
      return dispatch(baristaThunks.loadIntents({ esp_filters: query }));
    },

    updateCondition(conditionData) {
      dispatch(
        baristaSearchConditionsThunks.updateCondition(conditionData)
      ).then(
        () =>
          dispatch(
            toastNotificationsActions.success({
              message: 'Condition updated',
              title: 'Success',
            })
          ),
        errorHandler
      );
    },
  };
};

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
