/**
 * Looks for text links e.g. https://www.espressive.com and convert them into a markdown link [https://www.espressive.com](https://www.espressive.com)
 * @param {string} text - text which should container a Markdown link with an entity and an ID
 * @param {Pick<import('../EspMarkdown').EspMarkdownProps, 'noExternalLinks'>} config - EspMarkdown props
 * @returns {string} string with plain text/Markdown link/s
 */

const replaceExternalLink = (text, { noExternalLinks = false }) => {
  if (noExternalLinks) {
    return text;
  }

  // This pattern matches URLs that are not already enclosed in markdown link syntax.
  // The negative lookbehind and lookahead assertions ensure we don't match URLs
  // within existing markdown links.
  // eslint-disable-next-line no-useless-escape -- this double slash is needed
  const standaloneUrlPattern = /(?<!\]\()https?:\/\/[^\s\[\]]+(?!\))/g;

  // Function to check if a URL is within a markdown link or brackets
  const isWithinMarkdownLinkOrBrackets = (urlIndex, text) => {
    const stack = [];
    for (let i = 0; i < urlIndex; i++) {
      if (text[i] === '[') {
        stack.push('[');
      } else if (text[i] === ']') {
        if (stack.length > 0 && stack[stack.length - 1] === '[') {
          stack.pop();
        }
      } else if (text[i] === '(') {
        if (stack.length > 0 && stack[stack.length - 1] === '[') {
          // Change the top of the stack to represent an open markdown link
          stack[stack.length - 1] = '(';
        }
      } else if (text[i] === ')') {
        if (stack.length > 0 && stack[stack.length - 1] === '(') {
          stack.pop(); // Close the markdown link
        }
      }
    }
    // If the stack is empty, the URL is not within brackets or a markdown link
    return stack.length > 0;
  };

  return text.replace(standaloneUrlPattern, (match, offset) => {
    if (isWithinMarkdownLinkOrBrackets(offset, text)) {
      // URL is within markdown link or brackets, return it unchanged
      return match;
    } else {
      // URL is standalone, convert to markdown link
      return `[${match}](${match})`;
    }
  });
};
export default replaceExternalLink;
