import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

// Atoms
import UserName from '../atoms/UserName';

// Controllers
import UserDataController from '../controllers/UserDataController';

// globals
import EspUserPropShape from '../../globals/EspUserPropShape';

/**
 * Component Container that produces a UserName component based off given id
 * If the user exist and entities, it uses that data
 * Otherwise loads it asynchrounously before passing user data and props to UserName
 */

class UserNameContainer extends PureComponent {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    error: PropTypes.bool, // from the controller
    firstNameOnly: PropTypes.bool,
    lastNameOnly: PropTypes.bool,
    loadUserData: PropTypes.func.isRequired, // from the controller
    userData: EspUserPropShape, // object containing the user data
    userId: PropTypes.number, // prop used to initialize this component
  };

  static defaultProps = {
    as: 'span',
    error: false,
    firstNameOnly: false,
    lastNameOnly: false,
    userData: null,
    userId: null,
  };

  componentDidMount() {
    const {
      userId,
      loadUserData,
      // userData
    } = this.props;

    // Will always invoke the loadUserData function. It already knows how to economize

    // if (userData === null) {
    //   loadUserData(userId);
    // }

    loadUserData(userId);
  }

  render() {
    const {
      // loadUserData,
      userData,
      // userId,
      error,
      ...props
    } = this.props;
    // We're using this variables as statements to avoid lint complains
    // we need to declare them so we can deconstruct ...props without those own props

    // Ignacio says: why not pick only the keys we need in the deconstruct? this is very risky. like _.omit({ 'name': 'fred', 'age': 40 }, 'age');
    // loadUserData;
    // userId;

    // This hardly should ever happen as the API should have inactive states
    // Still I've seen cases where the API returns 500 for certain users to we're using this
    if (error) {
      return <this.props.as>{'User Error'}</this.props.as>;
    }

    if (userData) {
      return (
        <UserName {...omit(props, 'loadUserData', 'userId')} user={userData} />
      );
    }
    return null;
  }
}

const UserNameContainerTest = UserNameContainer;

// eslint-disable-next-line no-class-assign -- DEV-1526
UserNameContainer = UserDataController(UserNameContainer);
export { UserNameContainerTest };
export default UserNameContainer;
