import ImmutablePropTypes from 'react-immutable-proptypes';
import { Checkbox, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import EspListItem from '../molecules/EspListItem';

class SelectMyGearProductFamilyItem extends Component {
  static propTypes = {
    onSelect: PropTypes.func,

    step: ImmutablePropTypes.map.isRequired,

    /** if onSelect prop is passed, do we want to have the checkbox? */
    withCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    onSelect: null,

    withCheckbox: false,
  };

  constructor(props) {
    super(props);

    const { step } = props;
    const items = step.get('items');
    const isOnlyOneItem = items.size === 1;
    const selectedItem = items.find((item) => item.get('isSelected'));

    let lastSelectedConfigurationOption = null;

    if (selectedItem) {
      const selectedProduct = selectedItem.get('entity');
      lastSelectedConfigurationOption = selectedProduct.get('id');
    } else if (isOnlyOneItem) {
      // pre select the first product if it's the only one
      const representativeProduct = step.get('representativeEntity');
      lastSelectedConfigurationOption = representativeProduct.get('id');
    }

    this.state = {
      lastSelectedConfigurationOption,
    };
  }

  handleSelectConfigurationOption = (e, data) => {
    const { step, onSelect } = this.props;

    let selectedProductID = null;

    const items = step.get('items');
    const selectedItem = items.find((item) => item.get('isSelected'));

    if (selectedItem) {
      const selectedProduct = selectedItem.get('entity');
      selectedProductID = selectedProduct.get('id');
    }

    const newSelectedProductID = data.value;

    if (selectedProductID === newSelectedProductID) {
      return;
    }

    onSelect(selectedProductID, newSelectedProductID);

    // remember the last user's choice
    this.setState({
      lastSelectedConfigurationOption: newSelectedProductID,
    });
  };

  handleToggleSelect = (e, data) => {
    const { step, onSelect } = this.props;

    let selectedProductID = null;

    const items = step.get('items');
    const selectedItem = items.find((item) => item.get('isSelected'));

    if (selectedItem) {
      const selectedProduct = selectedItem.get('entity');
      selectedProductID = selectedProduct.get('id');
    }

    const { checked } = data;

    if (checked) {
      // user did a choice previously, so we use that
      const { lastSelectedConfigurationOption } = this.state;

      if (_.isNumber(lastSelectedConfigurationOption)) {
        onSelect(selectedProductID, lastSelectedConfigurationOption);
      } else {
        // if no choice yet, it's the same as clicking on the Select
        const representativeProduct = step.get('representativeEntity');
        const dropdownEl = document.getElementById(
          `select-my-gear-product-family-dropdown-${representativeProduct.get(
            'id'
          )}`
        );

        dropdownEl.click();
      }
    } else {
      onSelect(selectedProductID, null);
    }
  };

  render() {
    const { step, onSelect, withCheckbox } = this.props;

    const representativeProduct = step.get('representativeEntity');

    const items = step.get('items');
    const isOnlyOneItem = items.size === 1;

    let selectedConfigurationOption;

    const selectedItem = items.find((item) => item.get('isSelected'));

    if (selectedItem) {
      const selectedProduct = selectedItem.get('entity');
      selectedConfigurationOption = selectedProduct.get('id');
    } else if (isOnlyOneItem) {
      selectedConfigurationOption = representativeProduct.get('id');
    }

    const configurationOptions = items
      .map((item) => {
        const product = item.get('entity');
        const productFamilyOptions = product.get('pf_options');

        const text = productFamilyOptions.reduce(
          (text, configurationOption, i) => {
            if (i > 0) {
              text += ', ';
            }

            return `${text}${configurationOption.get(
              'option'
            )}: ${configurationOption.get('value')}`;
          },
          ''
        );

        return {
          text,
          value: product.get('id'),
        };
      })
      .toJS();

    let checkbox;

    if (onSelect && withCheckbox) {
      checkbox = (
        <Checkbox
          checked={Boolean(selectedItem)}
          onChange={this.handleToggleSelect}
        />
      );
    }

    return (
      <EspListItem
        content={
          <Select
            defaultValue={selectedConfigurationOption}
            fluid
            id={`select-my-gear-product-family-dropdown-${representativeProduct.get(
              'id'
            )}`} // allows to target it's DOM node
            onChange={this.handleSelectConfigurationOption}
            options={configurationOptions}
            placeholder='Select one...'
            selectOnBlur={false}
          />
        }
        image={representativeProduct.getIn(['images', 0, 'thumbnail'])}
        name={representativeProduct.get('name')}
        selectable={checkbox}
      />
    );
  }
}

export default SelectMyGearProductFamilyItem;
