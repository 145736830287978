import Immutable from 'immutable';
import { createSelector } from 'reselect';
import getCurrentUser from './getCurrentUser';
import getDirectoryUser from './getDirectoryUser';
// import ImmutableUtils   from '../utils/ImmutableUtils';

const getUsersById = (state) => state.getIn(['entities', 'users']);

const getDirectoryUsersIds = (state) =>
  state.getIn(['directory', 'users', 'ids']);

const getDirectoryUsers = (usersById, directoryUsersIds, currentUser) => {
  if (!directoryUsersIds) {
    return null;
  }

  const myFavoriteIds = currentUser.get('favorites', Immutable.List());

  const myFavoriteIdsSet = myFavoriteIds.toSet();

  const directoryUsers = directoryUsersIds.map((id) => {
    const user = usersById.get(id);
    const isFavorited = myFavoriteIdsSet.has(id);

    const directoryUser = getDirectoryUser(user, isFavorited);

    return directoryUser;
  });

  return directoryUsers.filter((user) => user.get('is_active'));
};

const selector = createSelector(
  [getUsersById, getDirectoryUsersIds, getCurrentUser],
  getDirectoryUsers
);

export default selector;
