import { EspEntities } from 'esp-globals';
import { isEmpty } from 'lodash';
import dateFormat from './dateFormat';
import genPath from './getPath';

/**
 * Replaces {{label/entity}}
 * @param {string} text - The text that should contain {{key}}
 * @param {Pick<import('../EspMarkdown').EspMarkdownProps, 'paramValues' | 'noInternalLinks'>} config - EspMarkdown props
 */
const replaceParamVariables = (text, { paramValues, noInternalLinks }) =>
  // @ts-ignore
  text.replace(/\{\{([^}]+)\}\}/gim, (match, key) => {
    if (
      !isEmpty(paramValues) &&
      Object.prototype.hasOwnProperty.call(paramValues, key)
    ) {
      const { entity, label, pk } = paramValues[key];

      if (entity && !noInternalLinks) {
        if (entity === EspEntities.DATE) {
          return dateFormat(label);
        } else if (
          pk &&
          label &&
          (entity === EspEntities.ASSET || entity === EspEntities.USER)
        ) {
          // @ts-ignore
          return `[${label}](${genPath(entity, pk)})`;
        }
        return match;
      } else {
        return label ? label : match;
      }
    }
    return match;
  });

export default replaceParamVariables;
