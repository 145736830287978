import React, { useCallback, useContext } from 'react';
import { labels, samlURLS } from '../utils/constants';
import IntegrationSetupWizard from '../../IntegrationSetupWizard/IntegrationSetupWizard';
import { Form, List, Table } from 'semantic-ui-react';
import styles from './AzureSSOWithSAMLModal.module.scss';
import CopyField from '../../IntegrationSetupWizard/utils/CopyField';
import { object, string } from 'yup';
import AppFederationMetadataURLField from './AppFederationMetadataURLField';
import AzureContext from '../AzureContext';

const propTypes = {};

const AzureSSOWithSAMLModal = () => {
  const { postSSOWithSAML } = useContext(AzureContext);

  const steps = [
    {
      content: (
        <List ordered>
          <List.Item>
            Log in with an admin role and go to your Azure Active Directory
            admin center.
          </List.Item>
          <List.Item>
            Click on <strong>Enterprise Application</strong> on the left sidebar
          </List.Item>
          <List.Item>
            Click on <strong>{'+ New Application'}</strong> on the bar beneath
            the title
          </List.Item>
          <List.Item>
            Under the title click{' '}
            <strong>{'+ Create your own application'}</strong>
          </List.Item>
          <List.Item>
            In the creation screen name your application and select{' '}
            <strong>{'Non-gallery type'}</strong>
          </List.Item>
        </List>
      ),
      stepName: 'Create Azure App',
    },
    {
      content: (
        <div className={styles._}>
          <p>After the application is created:</p>
          <List ordered>
            <List.Item>
              In the left application navigation go to{' '}
              <strong>{'Manage > Single sign-on'}</strong>
            </List.Item>
            <List.Item>
              Choose <strong>{'sign-on method SAML'}</strong>
            </List.Item>
            <List.Item>
              Under <strong>{'Basic SAML Configuration'}</strong> paste the
              following URLS:
            </List.Item>
          </List>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Field</Table.HeaderCell>
                <Table.HeaderCell width={12}>Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Identifier</Table.Cell>
                <Table.Cell>
                  <CopyField text={samlURLS.identifierURL} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Reply URL</Table.Cell>
                <Table.Cell>
                  <CopyField text={samlURLS.replyURL} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Sign on URL</Table.Cell>
                <Table.Cell>
                  <CopyField text={samlURLS.singOnURL} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Log out URL</Table.Cell>
                <Table.Cell>
                  <CopyField text={samlURLS.logoutURL} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      ),
      stepName: 'Config URLs',
    },
    {
      content: (
        <div className={styles._}>
          <p>
            Under <strong>{'Manage > Single sign-on'}</strong>
          </p>

          <List ordered>
            <List.Item>
              Go to <strong>{'Attributes & Claims section'}</strong>
            </List.Item>
            <List.Item>
              Click on <strong>{'Edit'}</strong>
            </List.Item>
            <List.Item>
              Click on <strong>{'+ Add new claim'}</strong> and add each of the
              following as a separate claim:
            </List.Item>
          </List>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Source ATTR</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <CopyField text={'FirstName'} />
                </Table.Cell>
                <Table.Cell>user.givenname</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <CopyField text={'LastName'} />
                </Table.Cell>
                <Table.Cell>user.surname</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <CopyField text={'Email'} />
                </Table.Cell>
                <Table.Cell>user.mail</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      ),
      stepName: 'Attributes & Claims',
    },
    {
      content: (
        <div className={styles._}>
          <p>
            Under <strong>{'Manage > Single sign-on'}</strong>
          </p>

          <List ordered>
            <List.Item>
              Go to <strong>{'SAML Certificates'}</strong> section
            </List.Item>
            <List.Item>
              Copy the URL in <strong>{'App Federation Metadata URL'}</strong>
            </List.Item>
            <List.Item>Paste the value in the box below</List.Item>
            <List.Item>
              Click <strong>{'Next'}</strong> to finish setup
            </List.Item>
          </List>

          <Form as={'div'} style={{ marginTop: '15px' }}>
            <AppFederationMetadataURLField />
          </Form>
        </div>
      ),
      initialValues: {
        app_federation_metadata_url: '',
      },
      stepName: 'Finish',
      validationSchema: object().shape({
        app_federation_metadata_url: string().required(
          'App Federation Metadata URL is required'
        ),
      }),
    },
  ];

  const handleOnClose = useCallback(
    (list) => {
      if (list) {
        const { formValues } = list[list.length - 1];

        postSSOWithSAML({
          hostname: samlURLS.hostname,
          logoutURL: samlURLS.logoutURL,
          replyURL: samlURLS.replyURL,
          samlIdpMetadataUrl: formValues.app_federation_metadata_url,
        });
      }
    },
    [postSSOWithSAML]
  );

  return (
    <IntegrationSetupWizard
      onClose={handleOnClose}
      steps={steps}
      title={labels.SSO_WITH_SAML_MODAL_TITLE}
      triggerButtonTitle={labels.TRIGGER_BUTTON_TITLE}
    />
  );
};

AzureSSOWithSAMLModal.propTypes = propTypes;

export default AzureSSOWithSAMLModal;
