const api_catalog_v0_1_product_999 = {
  bundle: null,
  cart: 753,
  eid: '6d9e34be-c7a2-47fb-938e-bbc76412d8f9',
  id: 1212,
  item_status: 'CREATED',
  product: 2203,
  product_name: 'AAA',
  quantity: 1,
  url: 'https://release.qa.espressive.com/api/catalog/v0.1/citem/1212/',
  weight: 1,
};

export default api_catalog_v0_1_product_999;
