const utilizationFrequencies = [
  {
    key: 1,
    text: 'I use it frequenly',
    value: 'frequenly',
  },
  {
    key: 2,
    text: 'Occasionally (a few times a month)',
    value: 'occasionally',
  },
  {
    key: 3,
    text: 'I rarely use it (a few times a year)',
    value: 'rarely',
  },
  {
    key: 4,
    text: 'I never use this',
    value: 'never',
  },
];

export default utilizationFrequencies;
