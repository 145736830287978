import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Molecules
import OnboardingActivityPerformanceList from '../../../molecules/OnboardingActivityPerformanceList';
import OnboardingActivityPerformanceDetail from '../../../molecules/OnboardingActivityPerformanceDetail';
// utils
import injectIntlToController from '../../../../utils/injectIntlToController';

const propTypes = {
  /** URl Parameters */
  params: PropTypes.shape({
    userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const defaultProps = {
  params: {},
};

class OnboardingActivitiesPerformance extends Component {
  render() {
    const {
      params: { userID },
    } = this.props;

    if (userID) {
      return <OnboardingActivityPerformanceDetail {...this.props} />;
    }

    return <OnboardingActivityPerformanceList {...this.props} />;
  }
}

OnboardingActivitiesPerformance.propTypes = propTypes;
OnboardingActivitiesPerformance.defaultProps = defaultProps;

export default injectIntlToController(OnboardingActivitiesPerformance);
