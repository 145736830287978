import { connect } from 'react-redux';

/**
 * Connect function which pass defaultProps from the component to the
 * * new connected component created by connect
 * @param mapStateToProps
 * @param mapDispatchToProps
 * @return  {buildCompositeController~function} return a new function to pass the component to wrap
 */
const buildCompositeController = (mapStateToProps, mapDispatchToProps) => (
  component
) => {
  /**
   * Makes sure:
   * 1. All controllers know when React Router state changes.
   * 2. Controller's children re render when React Router state changes.
   */
  const extendedMapStateToProps = (state, ownProps) => {
    const locationKey = state.getIn(['reactRouter', 'locationKey']);
    const props = mapStateToProps ? mapStateToProps(state, ownProps) : {};

    // force children to re render, even if it's PureComponent,
    // prop name is DOM attribute friendly in case it makes it to a DOM node,
    // ie by props spread
    props['react-router-location-key'] = locationKey;

    return props;
  };

  /**  Connect component */
  const componentConnected = connect(
    extendedMapStateToProps,
    mapDispatchToProps
  )(component);

  if (component.defaultProps) {
    componentConnected.defaultProps = component.defaultProps;
  }

  /** Returned function where we pass defaultProps */
  return componentConnected;
};

export default buildCompositeController;
