import Rollbar from 'rollbar';
import endpointGenerator from './endpointGenerator';
import store from '../stores/store';

const TOKEN_DEVELOPMENT = '9f839794df2d464eae720fe9150ed051';
const TOKEN_PRODUCTION = '544687ed78d74caca4399d1d1415966e';

const DISABLE_IN_LOCAL = true; // Change to false if you want to test it in your localhost. Don't commit changes

const isDev = process.env.NODE_ENV === 'development';

const decode = (str) => {
  try {
    return atob(str);
  } catch (e) {
    return str;
  }
};

const RollbarConfig = {
  start: (rollbarEnabled = false, rollbarEnvironment = '', eid = null) => {
    const locallyDisabled = DISABLE_IN_LOCAL ? isDev : false;
    if (rollbarEnabled && !locallyDisabled) {
      const appVersion = store.getState().get('appVersion');

      const environment =
        rollbarEnvironment === 'devserver' ? 'development' : 'production';
      const server = endpointGenerator.getApiServer();
      const isNative = window.cordova || window.electron;
      let nativeInfo = {};
      if (isNative) {
        if (window.cordova) {
          nativeInfo = {
            installDate: window.BuildInfo.installDate,
            packageName: window.BuildInfo.packageName,
            platform: window.device.platform,
            version: window.BuildInfo.version,
          };
        }
        if (window.electron) {
          nativeInfo = {
            name: window.electronAPI?.getAppName(),
            platform: window.electronAPI?.getOSPlatform(),
            version: window.electronAPI?.getAppVersion(),
          };
        }
      }

      const rollbarConfig = {
        accessToken:
          environment === 'development' ? TOKEN_DEVELOPMENT : TOKEN_PRODUCTION,
        captureUncaught: true,
        captureUnhandledRejections: false,
        payload: {
          client: {
            javascript: {
              CLIENT_ID: decode(appVersion.get('clientId')) || environment,
              guess_uncaught_frames: true,
              is_cordova: Boolean(window.cordova),
              is_electron: Boolean(window.electron),
              is_native: isNative,
              native_info: nativeInfo,
              source_map_enabled: true,
              version: decode(appVersion.get('clientId')),
            },
          },
          environment,
          person: {
            id: eid,
          },
          server: {
            host: server,
          },
          system: {
            SYSTEM_ID: decode(appVersion.get('systemId')),
          },
        },
        verbose: false, // Turn this on if you want to see what's being sent to rollbar
      };
      Rollbar.init(rollbarConfig);
      window.Rollbar = Rollbar;
    }
  },
};

export default RollbarConfig;
