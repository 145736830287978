import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import _ from 'lodash';

// globals
import SocialMedias, { MediaTypes } from '../../globals/SocialMedias';

// Molecules
import SocialMediaDropDown from './SocialMediaDropDown';
import SocialMediaUsernameInput from './SocialMediaUsernameInput';

class SocialMediaInput extends PureComponent {
  static propTypes = {
    defaultOption: PropTypes.string,
    disabledOptions: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string,
    mediaType: PropTypes.oneOf([MediaTypes.SOCIAL, MediaTypes.COLLAB]),
    name: PropTypes.string,
    noNetworkElegibility: PropTypes.bool,
    placeholder: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    defaultOption: SocialMedias[MediaTypes.COLLAB][0].value,
    disabledOptions: [],
    label: '',
    mediaType: MediaTypes.COLLAB,
    name: '',
    noNetworkElegibility: false,
    placeholder: 'username',
    width: null,
  };

  constructor(props) {
    super(props);

    const { mediaType, defaultOption } = this.props;
    const fieldOption = _.find(
      SocialMedias[mediaType],
      (option) => option.value === defaultOption
    );

    this.state = {
      isUserNameParsed: false,
      selectedMedia: fieldOption,
    };
  }

  handleDropDownClick = (input, e, d) => {
    const { mediaType } = this.props;

    const selectedMedia = _.find(
      SocialMedias[mediaType],
      (option) => option.value === d.value
    );

    this.setState({
      selectedMedia: selectedMedia,
    });

    input.onChange(d.value);
  };

  handleInputChange = (input, e) => {
    let inputValue = e.target.value;

    inputValue = this.parseOutUserName(inputValue);

    // Make sure we pass null in case of empty value
    // if not redux-form will delete the field entry from the array entry...
    // Trim the value in order to avoid just spaces in the the username or weird username like "pasca l.delavega"
    const finalInput = inputValue ? inputValue.split(' ').join('') : null;

    input.onChange(finalInput);
  };

  parseOutUserName = (value) => {
    const { selectedMedia } = this.state;

    let isUserNameParsed = false; // Whether we used a regex to extract the usernamne out of a url

    if (selectedMedia.regex) {
      const regex = new RegExp(selectedMedia.regex);
      let groups;
      if ((groups = regex.exec(value))) {
        const fourItemIndex = 3;
        value = groups[fourItemIndex];

        isUserNameParsed = true;
      }
    }

    this.setState({
      isUserNameParsed: isUserNameParsed,
    });

    return value;
  };

  render() {
    const {
      disabledOptions,
      label,
      mediaType,
      name,
      noNetworkElegibility,
      placeholder,
      width,
    } = this.props;

    const { isUserNameParsed, selectedMedia } = this.state;

    const socialMedia = _.filter(
      SocialMedias[mediaType],
      (option) => !(disabledOptions.indexOf(option.value) > -1)
    );

    return (
      <Form.Field width={width}>
        <label htmlFor=''>{label}</label>
        <Input className='labeled'>
          <Field
            component={SocialMediaDropDown}
            name={name ? `${name}.network` : 'network'}
            noNetworkElegibility={noNetworkElegibility}
            onOptionClick={this.handleDropDownClick}
            selectedMedia={selectedMedia}
            socialOptions={socialMedia}
          />

          <Field
            component={SocialMediaUsernameInput}
            icon={
              isUserNameParsed
                ? {
                    name: 'checkmark',
                  }
                : null
            }
            name={name ? `${name}.username` : 'username'}
            onInputChange={this.handleInputChange}
            placeholder={selectedMedia.placeholder || placeholder}
          />
        </Input>
      </Form.Field>
    );
  }
}

export default SocialMediaInput;
