import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import tenantThunks from '../../actions/tenantThunks';

const mapStateToProps = (state) => {
  const tenantState = state.get('tenant');
  const settings = tenantState.getIn([
    'entity',
    'value',
    'ui_config',
    'privacystatementsettings',
  ]);
  return {
    privacyLinks: settings ? settings.toJS() : void 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveTenantPrivacyStatement(PrivacyStatementSettings) {
    dispatch(tenantThunks.saveTenantPrivacyStatement(PrivacyStatementSettings));
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
