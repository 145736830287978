import React, { PureComponent } from 'react';
import { map, noop, orderBy, partialRight } from 'lodash';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Form, Select } from 'semantic-ui-react';
import FormSectionContainer from '../../../../../v1/components/organisms/FormSectionContainer';
import { intl } from 'esp-util-intl';

import ActionButtonFormSection from '../SurveyAnnouncementActionButtonSection';
import surveyTypes, { isActionValid } from '../SurveyTypes';
import { ACTION_TYPES } from '../../../../../v1/globals/announcementCustomActionConstants';
import AnnouncementSection from '../../../../../v1/components/organisms/AnnouncementSection';

const valuesForActionsBySurveyType = (surveyType, params) => {
  const settings = {
    button_type: surveyType.value,
    ...params,
  };

  return map(surveyType.buttons, (button, index) => {
    // default action type
    const actionType = settings.button_type;

    return {
      ...button,
      action_url: null,
      button_text: intl.formatMessage({ id: button.button_text }),
      button_type: actionType,
      index: index,
      isValid: isActionValid(actionType, button),
      // TODO: This is the same when initializing values from BE. Maybe there is a way to segment parts and
      // build all this as a unit
      key: `${surveyType.value}/${button.button_text}/${index}`,
      type: ACTION_TYPES.REPORT_RESPONSE_ONLY,
    };
  });
};

const initialFormValuesForActions = valuesForActionsBySurveyType(
  surveyTypes.GOOD_BAD
);

class SurveyAnnouncementContentSection extends PureComponent {
  static propTypes = {
    announcementId: PropTypes.string.isRequired,
    changeFormFieldValue: PropTypes.func,
    formValues: ImmutablePropTypes.map,
    isDraft: PropTypes.bool,
  };

  static defaultProps = {
    changeFormFieldValue: noop,
    formValues: Immutable.Map(),
    isDraft: true,
  };

  getTranslatedText = (type) => {
    const firstElement = 0,
      secondElement = 1;
    switch (type) {
      case surveyTypes.GOOD_BAD.value:
        return `${intl.formatMessage({
          id: surveyTypes.GOOD_BAD.buttons[firstElement].button_text,
        })}/${intl.formatMessage({
          id: surveyTypes.GOOD_BAD.buttons[secondElement].button_text,
        })}`;
      case surveyTypes.APPROVE_DISAPPROVE.value:
        return `${intl.formatMessage({
          id: surveyTypes.APPROVE_DISAPPROVE.buttons[firstElement].button_text,
        })}/${intl.formatMessage({
          id: surveyTypes.APPROVE_DISAPPROVE.buttons[secondElement].button_text,
        })}`;
      case surveyTypes.TEXT_ONLY.value:
        return intl.formatMessage({ id: 'label.text_only' });
      default:
        return '';
    }
  };

  // lodash's map is capable of iterating through an object's keys.
  // This is not attained with Array.map
  surveyTypesOptions = map(surveyTypes, (surveyType) => ({
    key: surveyType.dropdownOption,
    text: this.getTranslatedText(surveyType.value),
    value: surveyType.value,
  }));

  setSelectedTypeFormValues = (selectedType, params = {}) => {
    const { announcementId, changeFormFieldValue } = this.props;

    // IMPORTANT: Be aware that selecting a different survey type resets all fields!
    const newReduxFormValues = valuesForActionsBySurveyType(
      surveyTypes[selectedType],
      params
    );

    changeFormFieldValue(
      'actions[0]',
      Immutable.Map(newReduxFormValues[0]),
      announcementId
    );
    changeFormFieldValue(
      'actions[1]',
      Immutable.Map(newReduxFormValues[1]),
      announcementId
    );
  };

  handleSurveyTypesChange = (evt, { value }) => {
    // BE decided to send my survey type within the action as the button_type property
    // (so, it's a repeated property for actiions[0] and actions[1])
    this.setSelectedTypeFormValues(value, { button_type: value });
  };

  handleBodyChange = (evt, { value }) => {
    const { announcementId, changeFormFieldValue } = this.props;
    changeFormFieldValue('body', value, announcementId);
  };

  getButtonTypeValue = () => {
    // BE decided to send my survey type within the action as the button_type property
    // (so, it's a repeated property for actiions[0] and actions[1])
    const { formValues } = this.props;
    return formValues.getIn(['actions', '0', 'button_type']);
  };

  render() {
    const {
      announcementId,
      changeFormFieldValue,
      formValues,
      isDraft,
    } = this.props;

    const changeFormFieldValueWithAnnouncementId = partialRight(
      changeFormFieldValue,
      announcementId
    );

    const surveyType = this.getButtonTypeValue();
    const actions = formValues.get('actions')
      ? formValues.get('actions').toJS()
      : [];

    return (
      <FormSectionContainer
        style={{
          marginBottom: '24px',
          padding: '0px',
        }}
      >
        {isDraft && (
          <AnnouncementSection
            title={intl.formatMessage({
              id: 'label.survey_action_buttons',
            })}
          >
            <Form.Field>
              <label>
                {intl.formatMessage({
                  id: 'label.button_type',
                })}
              </label>
              <Select
                onChange={this.handleSurveyTypesChange}
                options={this.surveyTypesOptions}
                selection
                value={surveyType}
              />
            </Form.Field>

            {orderBy(actions, 'is_button_positive', ['desc']).map(
              (action, index) => (
                <ActionButtonFormSection
                  action={action}
                  changeFormFieldValueWithAnnouncementId={
                    changeFormFieldValueWithAnnouncementId
                  }
                  index={index}
                  key={action.key}
                  readOnly={!isDraft}
                />
              )
            )}
          </AnnouncementSection>
        )}
      </FormSectionContainer>
    );
  }
}

export { valuesForActionsBySurveyType, initialFormValuesForActions };

export default SurveyAnnouncementContentSection;
