import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AnnouncementsNews extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    color: '#F00',
  };

  render() {
    const { className, color } = this.props;

    const classname = `primary icon${className ? ` ${className}` : ''}`;

    return (
      <svg
        className={classname}
        fillRule='evenodd'
        viewBox='0 0 96 96'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill={color}>
          <path d='M65.12 65.33H51c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h14.12c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zM44.36 41.6h-12.6c-.592-.01-1.163.22-1.582.638-.42.42-.65.99-.638 1.582v10.39c-.01.592.22 1.163.638 1.582.42.42.99.65 1.582.638h12.6c.592.01 1.163-.22 1.582-.638.42-.42.65-.99.638-1.582V43.83c.014-.594-.215-1.167-.634-1.588-.42-.42-.992-.653-1.586-.642zm-.74 11.86H32.5v-8.9h11.12v8.9zm1.49 11.87H31c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h14.11c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm0-5.93H31c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h14.11c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm20.01-11.86H51c-.536 0-1.03.286-1.3.75-.267.464-.267 1.036 0 1.5.27.464.764.75 1.3.75h14.12c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm0 11.86H51c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h14.12c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm0-30.4H31.76c-.592-.01-1.163.22-1.582.638-.42.42-.65.99-.638 1.582v4.45c-.01.592.22 1.163.638 1.582.42.42.99.65 1.582.638h33.36c.592.01 1.163-.22 1.582-.638.42-.42.65-.99.638-1.582v-4.45c.01-.592-.22-1.163-.638-1.582-.42-.42-.99-.65-1.582-.638zm-.74 5.93H32.5V32h31.88v2.93zm.74 6.67H51c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h14.12c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5zm0 11.87H51c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5h14.12c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5z' />
          <path d='M22.56 75.66h51.25V20.77H22.56v54.89zm3-51.89h45.25v48.89H25.56V23.77z' />
          <path d='M48 4C23.7 4 4 23.7 4 48s19.7 44 44 44 44-19.7 44-44c0-11.67-4.636-22.86-12.887-31.113C70.86 8.637 59.67 4 48 4zm0 83C26.46 87 9 69.54 9 48S26.46 9 48 9s39 17.46 39 39c0 10.343-4.11 20.263-11.423 27.577C68.263 82.89 58.343 87 48 87z' />
        </g>
      </svg>
    );
  }
}

export default AnnouncementsNews;
