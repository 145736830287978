import { curry, defer, identity, memoize, noop } from 'lodash';
import { Dropdown, Form, Icon } from 'semantic-ui-react';
import React, { cloneElement, PureComponent } from 'react';

// Utils
import getStringLocationsByIDs from '../../utils/getStringLocationsByIDs';
import HierarchySelectController from '../controllers/HierarchySelectController';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intl } from 'esp-util-intl';
import PropTypes from 'prop-types';

const initialState = {
  defaultPathNodesIDs: null,
  defaultSelectedNode: 'empty',
  selectedNode: null,
  selectionPathNodesIDs: Immutable.List(),
};

/**
 * It's important to don't extend from Component.
 * There are use cases where we may need this component to
 * re render even if props or state didn't change.
 * Usage in existing employee onboard workflow is an example of above.
 */
class HierarchySelect extends PureComponent {
  static propTypes = {
    /** Reset after a selection is made */
    clearAfterSelection: PropTypes.bool,
    /** Numeric ID of the default selected node */
    defaultSelectedNodeID: PropTypes.number,
    /** Display the full address inside the trigger */
    displayFullAddress: PropTypes.bool,
    /** (parentID: Number)=> {} */
    getChildrenNodes: PropTypes.func,
    /** (defaultSelectedNodeID: Number)=> {} */
    getDefaultSelectedNode: PropTypes.func,
    /** Thunk to get all root nodes, those where parent = null */
    getRootNodes: PropTypes.func,

    /** User will not see the root node preselected by default */

    noRootPreselect: PropTypes.bool,

    /** Wired nodes indexed by ID */
    nodesByID: ImmutablePropTypes.map.isRequired,

    /** Is user allowed to pick a non leaf node? */
    nonLeafNodeSelectEnabled: PropTypes.bool,
    /**
     * Called each time user selects a node, Immutable.Map 'selectedNode' is passed as argument.
     *
     * (selectedNode) => {}
     */
    onChange: PropTypes.func,
    /** Callback to close the modal rendered by renderModal */
    onCloseModal: PropTypes.func,
    //
    // Callback that receives 'dropdown' element as argument, must return a React element.
    //
    // Usage example
    //
    // (dropdown) => <div className='block'>{dropdown}</div>
    //
    // renders each dropdown inside a div.block
    //
    renderDecoratedDropdown: PropTypes.func,
    //
    // Callback to render Modal's trigger when no node is selected,
    // boolean 'isLoadingDefaultSelectedNode' argument is passed.
    // Must return a single React element.
    //
    // (isLoadingDefaultSelectedNode) => (
    //    <Button loading={isLoadingDefaultSelectedNode}>
    //      Pick a Node
    //    </Button>
    // )
    //
    renderEmptySelectionTrigger: PropTypes.func.isRequired,
    //
    // Callback that receives 'trigger', 'dropdowns' and 'selectNodeButton' as arguments.
    // Must return some sort of React element that behaves as a modal.
    //
    // Allows you to decide the layout of your modal. Returned element by this function
    // is going to be the result of this component's render method.
    //
    // (trigger, dropdowns, selectNodeButton) => (
    //    <Modal className='scrolling' trigger={trigger}>
    //      <Modal.Header>
    //        Choose a Node
    //      </Modal.Header>
    //      <Modal.Content>
    //        {dropdowns}
    //      </Modal.Content>
    //      <Modal.Actions>
    //        {selectNodeButton}
    //      </Modal.Actions>
    //    </Modal>
    // )
    //
    renderModal: PropTypes.func.isRequired,

    //
    // Callback to render button to select a node from the hierarchy.
    // Must return a single React element. 'disabled' and 'onClick' props are injected to returned element,
    // make sure to pass them down if you return nested content.
    //
    // () => (
    //    <Button
    //      red
    //      class='button'
    //    >
    //      Pick Node
    //    </Button>
    // )
    //

    renderSelectNodeButton: PropTypes.func.isRequired,

    //
    // Callback to render Modal's trigger when a node is selected,
    // Immutable.Map 'selectedNode' argument is passed.
    // Must return a single React element.
    //
    // (selectedNode) => (
    //    <div>
    //      {`You selected ${selectedNode.get('name'}!`}
    //    </div>
    // )
    //
    renderSelectionTrigger: PropTypes.func.isRequired,
    /**
     * If false, allows to click selectNodeButton without any selected node.
     * In such case this.props.onChange will be called with null as argument.
     */
    required: PropTypes.bool,
    /** List of top level nodes */
    rootNodes: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    /** Name of the attribute of the nodes that indicates the node type */
    typeNameAttr: PropTypes.string.isRequired,
  };

  static defaultProps = {
    clearAfterSelection: false,
    defaultSelectedNodeID: null,
    displayFullAddress: false,
    getChildrenNodes: noop,
    getDefaultSelectedNode: noop,
    getRootNodes: noop,
    // By default, user can select non leaf node

    noRootPreselect: false,

    nonLeafNodeSelectEnabled: true,
    onChange: noop,
    onCloseModal: noop,
    renderDecoratedDropdown: identity,
    required: false,
  };

  state = {
    ...initialState,
  };

  componentDidMount() {
    const { defaultSelectedNodeID, getDefaultSelectedNode, getRootNodes } =
      this.props;

    // tells if the component is mounted or not
    // as suggested at https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
    this._isMounted = true;

    getRootNodes();

    if (defaultSelectedNodeID) {
      getDefaultSelectedNode(defaultSelectedNodeID).then(
        this.setDefaultSelectedNode
      );
    } else {
      this.smartSelectNode();
    }
  }

  componentDidUpdate(prevProps) {
    this.smartSelectNode();

    // updates the default selected location on change of prop defaultSelectedNodeID
    const { defaultSelectedNodeID, getDefaultSelectedNode } = this.props;
    if (
      prevProps.defaultSelectedNodeID !== defaultSelectedNodeID &&
      defaultSelectedNodeID
    ) {
      getDefaultSelectedNode(defaultSelectedNodeID).then(
        this.setDefaultSelectedNode
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  didSmartSelectRootNode = false;

  setDefaultSelectedNode = () => {
    // prevents calling setState if the component unmounted before getDefaultSelectedNode Promise resolves
    if (!this._isMounted) {
      return;
    }

    const { getChildrenNodes, defaultSelectedNodeID, nodesByID } = this.props;

    let selectionPathNodesIDs = Immutable.List();
    const defaultSelectedNode = nodesByID.get(defaultSelectedNodeID);

    let node = defaultSelectedNode;

    do {
      const nodeID = node?.get('id');

      selectionPathNodesIDs = selectionPathNodesIDs.push(nodeID);

      if (!node?.get('isLeaf')) {
        getChildrenNodes(nodeID);
      }

      const parentID = node.get('parent');
      node = nodesByID.get(parentID, null);
    } while (node);

    selectionPathNodesIDs = selectionPathNodesIDs.reverse();

    const selectedNode = defaultSelectedNode;

    this.setState({
      defaultPathNodesIDs: selectionPathNodesIDs,
      defaultSelectedNode: selectedNode,
      selectedNode,
      selectionPathNodesIDs,
    });
  };

  handleSelectNode = (e, data) => {
    const { getChildrenNodes, nodesByID, typeNameAttr } = this.props;

    let { selectionPathNodesIDs } = this.state;

    const nodeID = data.value;

    const isAlreadySelected = selectionPathNodesIDs.includes(nodeID);

    if (isAlreadySelected) {
      return;
    }

    getChildrenNodes(nodeID);

    const node = nodesByID.get(nodeID);
    const type = node.get(typeNameAttr);

    // if there is a selected node of same type, we need to clear it and all selections made after it

    const sameTypeSelectionIndex = selectionPathNodesIDs.findIndex(
      (selectedNodeID) => {
        const selectedNode = nodesByID.get(selectedNodeID);
        const isSameType = selectedNode.get(typeNameAttr) === type;
        return isSameType;
      }
    );

    if (sameTypeSelectionIndex !== -1) {
      selectionPathNodesIDs = selectionPathNodesIDs.slice(
        0,
        sameTypeSelectionIndex
      );
    }

    selectionPathNodesIDs = selectionPathNodesIDs.push(nodeID);

    this.setState({
      selectionPathNodesIDs,
    });
  };

  handleSelectNodeButtonClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { clearAfterSelection, nodesByID, onChange, onCloseModal } =
      this.props;

    const { selectionPathNodesIDs } = this.state;

    let lastSelectedNode = null;

    const changeValue = (selectedNode) => {
      onChange(selectedNode, e);
      if (e) {
        // This is a click from the OK button which should close the Modal
        defer(onCloseModal); // Defer to be sure that we run the close only at the end and avoid a weird Portal Semantic Error
      }
    };

    if (!selectionPathNodesIDs.isEmpty()) {
      const lastSelectedNodeID = selectionPathNodesIDs.last();
      lastSelectedNode = nodesByID.get(lastSelectedNodeID);
    }

    this.setState(
      (prevState) => {
        if (clearAfterSelection) {
          return initialState;
        } else {
          const { defaultPathNodesIDs, defaultSelectedNode } = prevState;

          return {
            defaultPathNodesIDs: e ? null : defaultPathNodesIDs,
            defaultSelectedNode: e ? null : defaultSelectedNode,
            selectedNode: lastSelectedNode,
          };
        }
      },
      () => changeValue(lastSelectedNode)
    );
  };

  handleClearSelectionButtonClick = memoize(
    curry((selectedNodeID, e) => {
      // this prevents to open/close the Dropdown on clear button click
      e.stopPropagation();

      let { selectionPathNodesIDs } = this.state;

      const index = selectionPathNodesIDs.indexOf(selectedNodeID);

      selectionPathNodesIDs = selectionPathNodesIDs.slice(0, index);

      this.setState({
        selectionPathNodesIDs,
      });
    })
  );

  smartSelectNode = () => {
    const {
      defaultSelectedNodeID,
      getChildrenNodes,
      nodesByID,
      rootNodes,
      nonLeafNodeSelectEnabled,
      noRootPreselect,
    } = this.props;

    if (!rootNodes) {
      return;
    }

    let { selectionPathNodesIDs } = this.state;

    const { selectedNode } = this.state;

    const isLoadingDefaultSelectedNode =
      Boolean(defaultSelectedNodeID) && !selectedNode;

    // stop if there's a default selected node and we are loading it
    if (isLoadingDefaultSelectedNode) {
      return;
    }

    if (
      selectionPathNodesIDs.isEmpty() &&
      rootNodes.size === 1 &&
      !noRootPreselect
    ) {
      // user doesn't have a selection for the topmost dropdown, but there's only one option
      if (this.didSmartSelectRootNode) {
        return;
      }

      this.didSmartSelectRootNode = true;

      const uniqueRoot = rootNodes.first();
      const uniqueRootID = uniqueRoot.get('id');

      getChildrenNodes(uniqueRootID);

      selectionPathNodesIDs = selectionPathNodesIDs.push(uniqueRootID);

      this.setState(
        {
          selectionPathNodesIDs,
        },
        () => {
          this.handleSelectNodeButtonClick(); // Set the value selected
        }
      );
    }

    if (!selectionPathNodesIDs.isEmpty() && !nonLeafNodeSelectEnabled) {
      const lastSelectedNodeID = selectionPathNodesIDs.last();

      if (!nodesByID.has(lastSelectedNodeID)) {
        // we don't have the needed data yet
        return;
      }

      const lastSelectedNode = nodesByID.get(lastSelectedNodeID);
      const lastSelectedNodeChildren = lastSelectedNode.get('children');

      if (lastSelectedNodeChildren.size === 1) {
        // there is only one choice to pick from
        const uniqueChild = lastSelectedNodeChildren.first();
        const uniqueChildID = uniqueChild.get('id');

        getChildrenNodes(uniqueChildID);

        selectionPathNodesIDs = selectionPathNodesIDs.push(uniqueChildID);

        this.setState(
          {
            selectionPathNodesIDs,
          },
          () => {
            this.handleSelectNodeButtonClick(); // Set the value selected
          }
        );
      }
    }
  };

  resetState = () => {
    this.setState((prevState) => {
      const {
        defaultPathNodesIDs,
        defaultSelectedNode,
        selectedNode,
        selectionPathNodesIDs,
      } = prevState;

      if (defaultSelectedNode) {
        return {
          defaultPathNodesIDs,
          defaultSelectedNode,
          selectedNode:
            defaultSelectedNode === 'empty' ? null : defaultSelectedNode,
          selectionPathNodesIDs:
            defaultSelectedNode === 'empty'
              ? Immutable.List()
              : defaultPathNodesIDs,
        };
      }

      return {
        defaultPathNodesIDs,
        defaultSelectedNode,
        selectedNode,
        selectionPathNodesIDs,
      };
    });
  };

  setDefault = () => {
    const { defaultSelectedNode } = this.state;

    if (!defaultSelectedNode) {
      this.setDefaultSelectedNode();
    }
  };

  renderNodesDropdowns(nodes, selectionPathNodesIDs, isTopmostDropdown) {
    let dropdowns = Immutable.List();

    let nextSelectedNodeID;

    if (!selectionPathNodesIDs.isEmpty()) {
      nextSelectedNodeID = selectionPathNodesIDs.first();
    }

    // Dropdown rendered by this function call
    const thisDropdown = this.renderNodesDropdown(
      nodes,
      nextSelectedNodeID,
      isTopmostDropdown
    );

    dropdowns = dropdowns.push(thisDropdown);

    if (!selectionPathNodesIDs.isEmpty()) {
      const nextSelectedNode = nodes.find(
        (node) => node.get('id') === nextSelectedNodeID
      );
      const nextSelectedNodeChildren = nextSelectedNode.get('children');

      // Dropdown's rendered recursively
      const nextDropdowns = this.renderNodesDropdowns(
        nextSelectedNodeChildren,
        selectionPathNodesIDs.rest(),
        false
      );

      dropdowns = dropdowns.concat(nextDropdowns);
    }

    return dropdowns;
  }

  renderNodesDropdown = (nodes, selectedNodeID, isTopmostDropdown) => {
    const { nonLeafNodeSelectEnabled } = this.props;

    if (nodes.isEmpty()) {
      return null;
    }

    const { typeNameAttr, renderDecoratedDropdown } = this.props;

    const firstNode = nodes.first();
    let typeName = firstNode.get(typeNameAttr);

    const translatedTypeName = intl.formatMessage({
      id: `label.${typeName}`,
    });

    // Prevent display label.XXXXX if translation is not found
    if (!translatedTypeName.includes('label.')) {
      typeName = translatedTypeName;
    } else {
      typeName = typeName?.replace('_', ' ');
    }

    typeName = typeName?.toUpperCase();

    const options = nodes
      .map((node) => ({
        key: node.get('id'),
        text: node.get('name'),
        value: node.get('id'),
      }))
      .sort((optionA, optionB) => optionA.text.localeCompare(optionB.text))
      .toJS();

    let canClearSelection = false;

    if (selectedNodeID) {
      if (isTopmostDropdown) {
        canClearSelection = true;
      } else {
        const wasSmartSelected = !nonLeafNodeSelectEnabled && nodes.size === 1;

        canClearSelection = !wasSmartSelected;
      }
    }

    let clearSelectionButton = null;

    if (canClearSelection) {
      clearSelectionButton = (
        <Icon
          className='right floated'
          name='close'
          onClick={this.handleClearSelectionButtonClick(selectedNodeID)}
        />
      );
    }

    let dropdown = (
      <Form.Field>
        <label>{typeName}</label>
        <Dropdown
          disabled={false} // TODO don't know about this yet
          fluid
          icon={clearSelectionButton}
          onChange={this.handleSelectNode}
          options={options}
          placeholder={intl.formatMessage({
            id: 'label.select_one',
          })}
          selectOnBlur={false}
          selection
          value={selectedNodeID ? selectedNodeID : null}
        />
      </Form.Field>
    );

    dropdown = renderDecoratedDropdown(dropdown);

    dropdown = cloneElement(dropdown, {
      key: typeName,
    });

    return dropdown;
  };

  render() {
    const {
      nodesByID,
      rootNodes,
      defaultSelectedNodeID,
      displayFullAddress,
      nonLeafNodeSelectEnabled,

      renderEmptySelectionTrigger,
      renderModal,
      renderSelectNodeButton,
      renderSelectionTrigger,

      required,
    } = this.props;

    const { selectionPathNodesIDs, selectedNode } = this.state;

    const isLoadingDefaultSelectedNode =
      Boolean(defaultSelectedNodeID) && !selectedNode;

    let isLastSelectedNodeLeaf = false;

    if (!selectionPathNodesIDs.isEmpty()) {
      const lastSelectedNodeID = selectionPathNodesIDs.last();
      const lastSelectedNode = nodesByID.get(lastSelectedNodeID);
      isLastSelectedNodeLeaf = lastSelectedNode.get('isLeaf');
    }

    let trigger;

    let nodeToDisplay = selectedNode;

    if (displayFullAddress && !selectionPathNodesIDs.isEmpty()) {
      nodeToDisplay = getStringLocationsByIDs(selectionPathNodesIDs, nodesByID); // Display the full address
    }

    if (nodeToDisplay) {
      trigger = renderSelectionTrigger(nodeToDisplay);
    } else {
      trigger = renderEmptySelectionTrigger(isLoadingDefaultSelectedNode);
    }

    const dropdowns = this.renderNodesDropdowns(
      rootNodes,
      selectionPathNodesIDs,
      true
    );

    let selectNodeButton = null;

    const isSelectNodeButtonVisible =
      isLastSelectedNodeLeaf ||
      (nonLeafNodeSelectEnabled && !selectionPathNodesIDs.isEmpty()) ||
      !required;

    selectNodeButton = renderSelectNodeButton();

    selectNodeButton = cloneElement(selectNodeButton, {
      basic: !isSelectNodeButtonVisible,
      disabled: required && selectionPathNodesIDs.isEmpty(),
      onClick: this.handleSelectNodeButtonClick,
      primary: isSelectNodeButtonVisible,
    });

    const view = renderModal(
      trigger,
      dropdowns,
      selectNodeButton,
      nodeToDisplay,
      this.resetState,
      this.setDefault
    );

    return view;
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
HierarchySelect = HierarchySelectController(HierarchySelect);

export default HierarchySelect;
