import immurable from 'immutable';
import { createSelector } from 'reselect';

const getTenantLabelsList = (state) =>
  state.getIn(['tenant', 'localization', 'list']);

const getTenantMenuConfiguration = (tenantLabelsList) => {
  if (!tenantLabelsList || tenantLabelsList.isEmpty()) {
    return immurable.List();
  }

  return tenantLabelsList;
};

const selector = createSelector(
  [getTenantLabelsList],
  getTenantMenuConfiguration
);

export default selector;
